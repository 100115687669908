import { Anchor,Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledAnchor = styled(Anchor)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const HorizontalLine = styled(Box)`
  height: 1px;
  background: ${({ theme }) => theme.colors.border1};
`;

export const IconWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.border1};
  padding: ${({ theme }) => theme.spacings.spacing8};
  border-radius: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const WrapperWithBottomShadow = styled(Box)`
  position: relative;
  max-height: 154px;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 50px;
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
  }
`;
