import { useCallback, useEffect } from 'react';
import { getDuration } from '@common-fe/common-fe';
import { datadogRum } from '@datadog/browser-rum';
import _ from 'lodash';


const INTERVAL_TIME = getDuration({ minutes: 5});
const LINK = `${window.location.origin}/version.json`;

const LOCAL_STORAGE_KEY= 'CURRENT_VERSION';
export const handleCheckVersion = async () => {
  try {
    datadogRum.addAction('Compare UI versions'); 
    const response = await fetch(LINK, {cache: 'no-cache'});
    if (response.ok) {
      const json = await response.json();
      const version = _.get(json, 'version');
      const currentHash = localStorage.getItem(LOCAL_STORAGE_KEY);

      if(currentHash) {
        if(currentHash !== version) {
          datadogRum.addAction(`Update version from ${currentHash} to ${version}`);
          location.href = location.href + (location.href.includes('?') ? '&' : '?') + 'reload=' + new Date().getTime();
          location.reload();
          localStorage.setItem(LOCAL_STORAGE_KEY, version);
        }
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEY, version);
      }
      
      
      
    } 
  } catch{ /* empty */ }
};

export default () => {
  const handleCheck = useCallback(handleCheckVersion, []);

  useEffect(() => {
    handleCheck();
    window.addEventListener('focus', handleCheck);
    const interval = setInterval(() => {
      handleCheck();


    }, INTERVAL_TIME);
    return () => {
      window.removeEventListener('focus', handleCheck);
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};