import { baseStoreCreator } from '@/utils/store';

export interface PlanYearPayload {
  planYear: string;
  planStartDate: string;
  planEndDate: string;
  payoutDefinition: string;
  copayGroup: string | null;
  priorPlanYearId: string;
}

export const DEFAULT_VALUES: PlanYearPayload = {
  planYear: '1',
  planStartDate: '',
  planEndDate: '',
  payoutDefinition: '',
  copayGroup: '',
  priorPlanYearId: '',
};

export const useStore = baseStoreCreator<PlanYearPayload>(DEFAULT_VALUES);

export default useStore;
