import { EmployeeStatus } from '@/modules/employee/employee.constants';

import { TaxType } from '../Contributions/contributions.types';
import { NewContributionType } from '../Contributions/contributions.types';

interface PlanIdLimitAmount {
  [planId: string]: number; 
}
export interface LimitsIRS {
  [employeeId: string]: PlanIdLimitAmount;
}
export interface TaxYearsLimitsIRS {
  [taxYear: string]: LimitsIRS;
}

export interface ContributionAccount {
  isDisabled?: boolean;
  isPlanDisabled?: boolean;
  planCode?: string;
  planId?: string;
  planName?: string;
  accountType?: string;
  taxType?: TaxType;
  taxYear?: string;
  memo?: string;
  employeeContributionAmount?: string;
  employerContributionAmount?: string;
  contributionDateOn?: string;
  contributionDateFrom?: string;
  contributionDateTo?: string;
  contributionAmountMax?: string;
  perPayEmployee?: string;
  perPayEmployer?: string;
}

export interface Recipient {
  firstName: string;
  lastName: string;
  employeeFullName?: string;
  isNewEmployee?: boolean;
  employeeTerminationDate?: string;
  employeeId?: string;
  isSubsidiary?: boolean;
  employeeStatus?: EmployeeStatus;
  contributionAccounts?: ContributionAccount[];
}

export interface Segment {
  contributionRequestId?: string;
  segmentSubsidiaryIds?: string[];
  segmentPlanCodes?: string[];
  segmentPayrollGroupCodes?: string[];
  segmentEmployeeIds?: string[];
  taxType?: TaxType;
  taxYear?: string;
  contributionSegmentType?: NewContributionType;
  memo?: string;
}

interface PlanCodeCoverageDates {
  contributionDateFrom: string;
  contributionDateTo: string;
}
interface PlanCodeCoverage {
  [planCode: string]: PlanCodeCoverageDates;
}
export interface EmployeePlanCodeCoverages {
  [employeeId: string]: PlanCodeCoverage;
}

export enum ContributionBackendErrors {
  VALIDATION_NOT_EXIST = 'VALIDATION_NOT_EXIST',
  INCORRECT_VALUE = 'INCORRECT_VALUE',
  VALUE_IS_EMPTY = 'VALUE_IS_EMPTY',
}

export interface CreateManualContributionErrors {
  employee_id: number;
  plan_code: string;
  field_name: string;
  validation_error_type: ContributionBackendErrors;
}

export interface CreateManualContributionResponse {
  errors?: CreateManualContributionErrors[];
}