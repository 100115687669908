import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

interface CopayLabelWithNumberProps {
  copayNumber: number;
}

const CopayLabelWithNumber: React.FC<CopayLabelWithNumberProps> = ({ copayNumber }) => (
  <Box margin={{ top: 'xs' }} direction="row">
    <Inscription>{`Copay ${copayNumber}`}</Inscription>
  </Box>
);

export default CopayLabelWithNumber;