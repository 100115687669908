import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AppButton, Box, FlexControlledForm, Preloader } from '@common-fe/common-fe';
import { PendingModal } from '@common-fe/common-fe';
import _ from 'lodash';

import { useHealthPlanNameStore } from '@/modules/HealthPlan/HealthPlanSetup/BasicProperties/HealthPlanName/stores';
import { useDeleteService } from '@/modules/HealthPlan/HealthPlanSetup/ComplexConfiguration/EnrollmentMatrix/Tiers/queries';
import { useProcessingRules } from '@/modules/ProcessingDefinition/ProcessingRule/hooks';
import theme from '@/styles/theme';

import { useSubmitFormStore } from '../../stores';

import { HEALTH_PLAN_KEY, MANUAL_CLAIM_KEY } from './hooks/useEnrollmentMatrixFields';
import { EnrollmentMatrixPayload, useStore } from './stores/useEnrollmentMatrix.store';
import { useTiers } from './Tiers/hooks';
import { useServiceConfigs } from './Tiers/queries';
import TierItem from './Tiers/TierItem';
import { useEnrollmentMatrixFields } from './hooks';
interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const EnrollmentMatrix: React.FC<Props> = ({ isEditMode, isTemplateMode, onDirty }) => {
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const currentState = useStore((state) => state.state);
  const healthPlanTypes = useHealthPlanNameStore((state) => state.state.healthPlanTypes);
  const healthPlanStatus = useHealthPlanNameStore((state) => state.state.healthPlanStatus);
  const [deductibleWarnModalVisible, setDeductibleWarnModalVisible] = useState(false);
  const { handleDelete, isLoading } = useDeleteService();

  const { data, list: processingRulesList } = useProcessingRules({
    perPage: 100,
    page: 0,
  });

  const { data: serviceConfigs } = useServiceConfigs();

  const fields = useEnrollmentMatrixFields(
    activateValidate,
    isTemplateMode,
    isEditMode,
    processingRulesList,
  );

  const {
    fieldsValues: tiersValues, getFieldValuesById,
  } = useTiers(data, currentState.processingRuleName, serviceConfigs);

  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const handleChangeValues = useCallback((values: Partial<EnrollmentMatrixPayload>) => {
    handleSetState(values);
    if (!!serviceConfigs?.length && !isTemplateMode && values?.deductibleService && currentState.deductibleService && currentState.deductibleService !== values?.deductibleService) {
      setDeductibleWarnModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceConfigs?.length, currentState.deductibleService, isTemplateMode]);

  const handleDeleteAllConfigs = useCallback(async () => {
    try {
      await Promise.all(serviceConfigs.map(async (item) => {
        await handleDelete(item?.id);
      }));
    } finally {
      setDeductibleWarnModalVisible(false);
    }
  }, [serviceConfigs, handleDelete]);

  const key = useMemo(() => _.get(fields, '[2].defaultValue', ''), [fields]) as string;
  return (
    <>
      <Box
        background="canvas"
        round="container1Round"
      >
        <FlexControlledForm
          key={key}
          testId="enrollment-matrix-definition"
          fields={fields}
          showError={
            showErrors
            || saveValidate
            || activateValidate
          }
          editMode={isEditMode}
          onChangeValues={handleChangeValues}
          onDirty={onDirty}
          isThinMode
        />
      </Box>
      {!!tiersValues?.length && (
        <Box
          background="canvas"
          round="container1Round"
          pad="l"
          margin={{ top: 'l' }}
          border={{ color: 'border2', size: 'small' }}
        >
          {
            tiersValues.map((item, index) => (
              <TierItem
                id={item.id}
                isRemoveMode={tiersValues.length > 1}
                index={index}
                isLastItem={_.last(tiersValues) === item}
                key={item.id}
                defaultValues={getFieldValuesById(item.id)}
                isTemplateMode={isTemplateMode}
                isEditMode={isEditMode}
                payoutDefinitionId={item.payoutDefinition}
                serviceConfigs={item.serviceConfigs}
                deductibleType={currentState?.deductibleService}
                healthPlanTypes={healthPlanTypes}
                healthPlanStatus={healthPlanStatus}
              />
            ))
          }
        </Box>
      )}
      {!!serviceConfigs?.length && (
        <PendingModal
          visible={deductibleWarnModalVisible}
          warningIcon
          onSetVisible={() => {
            handleSetState({
              ...currentState,
              deductibleService: currentState?.deductibleService === HEALTH_PLAN_KEY ? MANUAL_CLAIM_KEY : HEALTH_PLAN_KEY, 
            });
            setDeductibleWarnModalVisible(false);
          }}
          header={`Update Deductible Tracking to ${currentState?.deductibleService === MANUAL_CLAIM_KEY ? 'Manual Claims' : 'Carrier Claims'}?`}
          helptext="By updating the type of claim used for tracking deductibles, any previously entered Service Payment rules will be removed from the tiers. Once updated, verify each tier to setup the new Service Payment rules."
        >
          <Box direction="row">
            <Box margin={{ right: 'spacing12' }}>
              <AppButton
                onClick={() => {
                  handleSetState({
                    ...currentState,
                    deductibleService: currentState?.deductibleService === HEALTH_PLAN_KEY ? MANUAL_CLAIM_KEY : HEALTH_PLAN_KEY, 
                  });
                  setDeductibleWarnModalVisible(false);
                }}
                width="140px"
                buttonType="secondary"
              >
                Cancel
              </AppButton>
            </Box>
            <AppButton
              disabled={isLoading}
              onClick={() => {
                handleDeleteAllConfigs();
              }}
              width="133px"
              style={{ background: isLoading ? theme.colors.border2 : theme.colors.danger }}
            >
              {isLoading ? <Preloader color="white" /> : 'Yes, Change'}
            </AppButton>
          </Box>
        </PendingModal>
      )}
    </>
  );
};
export default EnrollmentMatrix;
