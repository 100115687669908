import { useMemo } from 'react';

import {
  LockboxApproveStatusSubOption,
  LockboxDeclineStatusSubOption,
  LockboxStatus,
  LockboxType,
} from '@/modules/employer/components/Lockbox/lockbox.types';

interface Props {
  lockboxType?: LockboxType;
}

const useLockboxProcessingOptions = ({ lockboxType }: Props) => {
  const options = useMemo(() => (
    [
      {
        key: LockboxStatus.APPROVED,
        title: 'Approve',
        optionsTitle: 'Select payment method',
        ...lockboxType === LockboxType.OUTBOUND_TOA 
          ? {
            subOptions: [
              {
                key: LockboxApproveStatusSubOption.CHECK,
                title: 'Check',
              },
              {
                key: LockboxApproveStatusSubOption.WIRE,
                title: 'Wire',
              }
            ],
          }
          : {},
      },
      {
        key: LockboxStatus.DECLINED,
        title: 'Decline',
        optionsTitle: 'Select the reason',
        noteTitle: 'Note',
        subOptions: [
          {
            key: LockboxDeclineStatusSubOption.PARTICIPANT_NOT_FOUND,
            title: 'Participant cant be found',
          },
          {
            key: LockboxDeclineStatusSubOption.ACCOUNT_NOT_FOUND,
            title: 'No account found',
          },
          {
            key: LockboxDeclineStatusSubOption.INVESTMENTS_LIQUIDATION_REQUIRED,
            title: 'Investments must be liquidated',
          },
          {
            key: LockboxDeclineStatusSubOption.ACCOUNT_CLOSED,
            title: 'Account closed',
          },
          {
            key: LockboxDeclineStatusSubOption.INSUFFICIENT_FUNDS,
            title: 'Insufficient funds',
          },
          {
            key: LockboxDeclineStatusSubOption.OTHER,
            title: 'Other',
          }
        ],
      },
      {
        key: LockboxStatus.PENDING,
        title: 'Pend',
        optionsTitle: 'Select the reason',
        noteTitle: 'Note',
        subOptions: [
          {
            key: LockboxDeclineStatusSubOption.PARTICIPANT_NOT_FOUND,
            title: 'Participant cant be found',
          },
          {
            key: LockboxDeclineStatusSubOption.ACCOUNT_NOT_FOUND,
            title: 'No account found',
          },
          {
            key: LockboxDeclineStatusSubOption.INVESTMENTS_LIQUIDATION_REQUIRED,
            title: 'Investments must be liquidated',
          },
          {
            key: LockboxDeclineStatusSubOption.ACCOUNT_CLOSED,
            title: 'Account closed',
          },
          {
            key: LockboxDeclineStatusSubOption.INSUFFICIENT_FUNDS,
            title: 'Insufficient funds',
          },
          {
            key: LockboxDeclineStatusSubOption.OTHER,
            title: 'Other',
          }
        ],
      },
    ]
  ), [lockboxType]);

  return {
    options
  };
};

export default useLockboxProcessingOptions;
