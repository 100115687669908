import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaymentMethodDtoPayload } from '@/modules/employee/employee.types';
import { formatPaymentMethod } from '@/modules/ReimburseMe/queries/useAddPaymentMethod.query';
import { PaymentMethod } from '@/modules/ReimburseMe/types/payment.types';

export const useGetPaymentMethods = (
  employeeId?: string,
  onSuccess?: (paymentMethods: PaymentMethod[]) => void,
) => {
  const {
    data, isSuccess, isLoading, refetch,
  } = useQuery(
    [PATHS.PAYMENT_METHODS, employeeId],
    () => api.get<PaymentMethodDtoPayload[]>(
      PATHS.PAYMENT_METHODS,
      {
        params: { employee_id: employeeId },
      },
    ),
    {
      enabled: false,
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess((response?.data || [])
            .map((paymentMethod) => formatPaymentMethod(paymentMethod)));
        }
      },
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedData = useMemo(
    () => (data?.data || [])
      .map((paymentMethod) => formatPaymentMethod(paymentMethod)),
    [data],
  );

  return {
    isLoading,
    isSuccess,
    data: data?.data || [],
    formattedData,
    refetch,
  };
};
