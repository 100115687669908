import React, { useMemo } from 'react';
import {
  Box, getFullnameAbbr, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import Link from '@/modules/core/components/Link/Link';
import currentTheme from '@/styles/theme';

interface Props {
  fullName: string;
  isMe?: boolean;
  employeeId?: string;
  id: string;
  index?: number;
  onHover?: () => void;
  onBlur?: ()=> void;
  disabled?: boolean;

}

const Wrapper = styled(Box)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentBorder} ;
  }
`;

export const DEPENDERS_COLORS = [currentTheme.colors.subgroup, currentTheme.colors.employee,
  currentTheme.colors.subsidiary, currentTheme.colors.distributor,
  currentTheme.colors.partner, currentTheme.colors.dependent, currentTheme.colors.noAssignee];
// this component is not the same as EmployerLogo from  the flex list component.
const SpendTrackerUser: React.FC<Props> = ({
  id, fullName, isMe, employeeId, index, onBlur, onHover, disabled,
}) => {
  const abbr = useMemo(() => getFullnameAbbr(fullName), [fullName]);
  return (
    <Link disabled={disabled} to={ROUTES.SPENDS_BY_ID(employeeId, id)}>

      <Wrapper
        onMouseEnter={onHover}
        onMouseLeave={onBlur}
        data-testid={`spend-tracker_user-${id}`}
        pad={{ vertical: 'xxs', horizontal: 'xs' }}
        direction="row"
        round="l"
        align="center"
      >
        <Box
          width="32px"
          height="32px"
          align="center"
          round="50%"
          justify="center"
          background={_.get(DEPENDERS_COLORS, `[${index}]`) || 'iconAccent'}
        >
          <Text color="iconOnColor" weight={700}>
            {abbr}
          </Text>
        </Box>
        <Text
          margin={{ left: 'xs' }}
          size="medium"
          weight={700}
        >
          {fullName}
          {isMe && (
            <Text color="textDisabled">
              (Me)
            </Text>
          )}
        </Text>
      </Wrapper>
    </Link>
  );
};

export default SpendTrackerUser;
