import React from 'react';
import { Box, Field, FieldTypes,Inscription } from '@common-fe/common-fe';

import { TaxType } from '../../Contributions/contributions.types';
import { ContributionAccount, Recipient } from '../types';

interface Props {
  recipient?: Recipient;
  contributionAccount?: ContributionAccount;
  taxYears?: string[];
}

const useGetEditFormFields = (props?: Props) => ([
  ...props?.recipient?.employeeFullName ? [{
    name: 'employeeFullName',
    type: FieldTypes.Text,
    label: 'Employee',
    // @ts-ignore
    isNarrowLabelMode: true,
    defaultValue: props?.recipient?.employeeFullName,
    disabled: true,
  } as Field,] : [],
  ...props?.contributionAccount?.planCode ? [{
    name: 'planCode',
    type: FieldTypes.Text,
    label: 'Account',
    // @ts-ignore
    isNarrowLabelMode: true,
    defaultValue: props?.contributionAccount?.planCode,
    disabled: true,
  } as Field,] : [],
  {
    placeholder: 'Enter amount',
    name: 'employeeContributionAmount',
    type: FieldTypes.Currency,
    label: 'Employee amount',
    // @ts-ignore
    isNarrowLabelMode: true,
    defaultValue: props?.contributionAccount?.employeeContributionAmount,
  },
  {
    placeholder: 'Enter amount',
    name: 'employerContributionAmount',
    type: FieldTypes.Currency,
    label: 'Employer amount',
    // @ts-ignore
    isNarrowLabelMode: true,
    defaultValue: props?.contributionAccount?.employerContributionAmount,
  },
  {
    placeholder: 'Select date',
    name: 'contributionDateOn',
    type: FieldTypes.Date,
    label: 'Date',
    // @ts-ignore
    isNarrowLabelMode: true,
    isManualDateInput: true,
    defaultValue: props?.contributionAccount?.contributionDateOn,
  },
  {
    placeholder: 'Select tax year',
    name: 'taxYear',
    type: FieldTypes.Dropdown,
    label: 'Tax year',
    // @ts-ignore
    isNarrowLabelMode: true,
    options: props?.taxYears?.map((taxYear) => ({
      key: taxYear,
      value: taxYear,
      title: taxYear,
    })),
    singleMode: true,
    defaultValue: props?.contributionAccount?.taxYear,
  },
  {
    placeholder: 'Select tax type',
    name: 'taxType',
    type: FieldTypes.Dropdown,
    label: 'Tax type',
    // @ts-ignore
    isNarrowLabelMode: true,
    options: [
      {
        key: TaxType.PRE_TAX,
        value: TaxType.PRE_TAX,
        title: 'Pre-tax',
      },
      {
        key: TaxType.POST_TAX,
        value: TaxType.POST_TAX,
        title: 'Post-tax',
      },
    ],
    singleMode: true,
    defaultValue: props?.contributionAccount?.taxType,
  },
  {
    placeholder: 'Enter memo',
    name: 'memo',
    type: FieldTypes.Text,
    label: (
      <Box>
        <Inscription>Memo</Inscription>
        <Inscription size="small" weight="normal" lineHeight="18px" color="textSecondary">
          Optional. If not entered, the contribution date and type will be displayed on the members account.
        </Inscription>
      </Box>
    ),
    // @ts-ignore
    isNarrowLabelMode: true,
    defaultValue: props?.contributionAccount?.memo,
  },
] as Field[]);

export default useGetEditFormFields;
