import React, { useMemo } from 'react';
import { Box, FinalSetupIcon } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { FlatBlockWrapper } from '@/components/wrappers/BlockWrapper';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { useSetupEmployerStore } from '@/modules/employer/store/useSetupEmployer.store';

import { useSaveTypeStore } from '../stores';

import ActivateEmployerCard from './ActivateEmployerCard';
import EditModeSwitcher from './EditModeSwitcher';
import FinalSetUpModal from './FinalSetUpModal';
import {
  useHandleEmployerSetUp,
  useSaveValidate,
} from './hooks';
import SaveEmployerCard from './SaveEmployerCard';

interface Props {
  status?: EmployerStatus;
  employerName?: string;
  isActivationStarted?: boolean;
  isSubsidiaryPage?: boolean;
  isPartnerPage?: boolean;
}

const FinalSetUp: React.FC<Props> = ({
  status, employerName, isActivationStarted, isSubsidiaryPage, isPartnerPage,
}) => {
  const isEditRestricted = useMemo(() => {
    return status === EmployerStatus.Terminated || status === EmployerStatus.TerminationInProgress;
  }, [status]);
  const {
    toEdit, editMode, viewMode,
    mode,
  } = useSetupEmployerMode();
  const { loading, employer } = useSetupEmployerStore();
  const {
    handleSavePending,
    handleActivate,
    handleDiscard,
  } = useHandleEmployerSetUp();
  const {
    basicProperties,
    linkManagement,
    mobileAppCustomization,
    contactsSecurity,
    financialDetails,
    features,
    customReports,
    cards,
    fileManagerSettings,
    formatedPendingErrors,
    formatedActivateErrors,
  } = useSaveValidate();
  const {
    pendingSubmitted,
    activateSubmitted,
  } = useSaveTypeStore();
  const submitted = useMemo(
    () => (activateSubmitted || status === EmployerStatus.Active || isActivationStarted),
    [activateSubmitted, status, isActivationStarted],
  );

  const year = dayjs().year();
  const month = dayjs().month();
  const day = dayjs().date();
  const currentDate = `${month >= 10 ? month + 1 : `0${month + 1}`}/${day >= 10 ? day : `0${day}`}/${year}`;
  const pendingDisabled = useMemo(() => {
    if (viewMode) {
      return true;
    }
    if (pendingSubmitted) {
      return !_.isEmpty(formatedPendingErrors);
    }
    return false;
  }, [formatedPendingErrors, pendingSubmitted, viewMode]);
  const activateDisabled = useMemo(() => {
    if (viewMode) {
      return true;
    }
    if (activateSubmitted) {
      return !_.isEmpty(formatedActivateErrors);
    }
    return false;
  }, [activateSubmitted, formatedActivateErrors, viewMode]);

  if (mode === EmployerSetupModes.system || loading) return null;

  return (
    <FlatBlockWrapper
      title="Final Setup"
      moduleAnchorId="final"
      icon={<FinalSetupIcon color="iconAccent" />}
    >
      <Box direction="row" align="stretch" flex="grow">
        <FinalSetUpModal
          mode={mode}
          organization={employer?.employerName}
          handleSave={handleSavePending}
          handleActivate={handleActivate}
        />
        {(status === EmployerStatus.Pending || status !== EmployerStatus.Active)
          && !isActivationStarted && (
          <>
            <SaveEmployerCard
              requiredFieldProperties={[
                basicProperties,
                linkManagement,
              ]}
              disabled={pendingDisabled || isEditRestricted}
              submitted={pendingSubmitted}
              onSubmit={handleSavePending}
              isEditMode={editMode}
            >
              {viewMode && !isEditRestricted && <EditModeSwitcher onClick={toEdit} /> }
            </SaveEmployerCard>
            <Box pad="spacing8" />
          </>
        )}
        <ActivateEmployerCard
          isEditMode={editMode}
          requiredFieldProperties={[
            basicProperties,
            linkManagement,
            ...isPartnerPage && !!mobileAppCustomization ? [mobileAppCustomization] : [],
            contactsSecurity,

            financialDetails,
            ...isSubsidiaryPage ? [] : [features, cards],
            customReports,
            ...fileManagerSettings.groups[0].fields.length
              && !isSubsidiaryPage ? [fileManagerSettings] : [],
          ]}
          viewMode={viewMode}
          editMode={editMode}
          employerName={employerName}
          activeMode={status === EmployerStatus.Active || isActivationStarted}
          disabled={activateDisabled || isEditRestricted}
          submitted={submitted}
          currentDate={currentDate}
          onSubmit={handleActivate}
          onDiscard={handleDiscard}
        >
          {viewMode && !isEditRestricted && <EditModeSwitcher onClick={toEdit} /> }
        </ActivateEmployerCard>
      </Box>
    </FlatBlockWrapper>
  );
};

export default React.memo(FinalSetUp);
