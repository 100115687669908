import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { PermissionKey } from '@/common/constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import theme from '@/styles/theme';

import useLinkManagementStore from './useLinkManagementStore.store.ts';

const useLinkManagementFields = () => {
  const { formattedData: { overridableProperties } } = useGetEmployer();
  const domainAddressPermProps = useGetPermissionToEditOverridableField(PermissionKey.EMAIL_DOMAIN);
  const privacyPolicyLinkPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PRIVACY_POLICY_LINK,
  );
  const termAndConditionsLinkPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.TERMS_AND_CONDITIONS_LINK,
  );
  const aboutUsLinkPermProps = useGetPermissionToEditOverridableField(PermissionKey.ABOUT_US_LINK);
  const sourceState = useLinkManagementStore((state) => state.sourceState);
  const fields = useMemo(() => {
    return [
      {
        name: 'domainAddress',
        type: FieldTypes.Text,
        testId: 'welcome-communications-id',
        label: 'Domain address',
        defaultValue: sourceState?.domainAddress,
        placeholder: 'Domain address',
        validator: VALIDATORS.STRING,
        fieldWrapperStyles: {
          marginTop: theme.spacings.spacing8,
        },
        ...overridableProperties?.linkManagement?.domainAddress ? {
          defaultValue: overridableProperties?.linkManagement?.domainAddress,
        } : {},
        ...domainAddressPermProps,
      },
      {
        name: 'privacyPolicyLink',
        label: 'Privacy policy',
        placeholder: 'Privacy policy',
        showRequireIcon: true,
        type: FieldTypes.Text,
        validator: VALIDATORS.URL_REQUIRED,
        ...overridableProperties?.linkManagement?.privacyPolicyLink || sourceState?.privacyPolicyLink ? {
          defaultValue: overridableProperties?.linkManagement?.privacyPolicyLink || sourceState?.privacyPolicyLink,
        } : {},
        ...privacyPolicyLinkPermProps,
      },
      {
        name: 'termAndConditionsLink',
        label: 'Terms and conditions',
        placeholder: 'Terms and conditions',
        showRequireIcon: true,
        validator: VALIDATORS.URL_REQUIRED,
        type: FieldTypes.Text,
        ...overridableProperties?.linkManagement?.termAndConditionsLink || sourceState?.termAndConditionsLink ? {
          defaultValue: overridableProperties?.linkManagement?.termAndConditionsLink || sourceState?.termAndConditionsLink,
        } : {},
        ...termAndConditionsLinkPermProps,
      },
      {
        name: 'pricingConsent',
        label: 'Pricing consent',
        placeholder: 'Pricing consent',
        validator: VALIDATORS.URL,
        type: FieldTypes.Text,
        ...overridableProperties?.linkManagement?.pricingConsent || sourceState?.pricingConsent ? {
          defaultValue: overridableProperties?.linkManagement?.pricingConsent || sourceState?.pricingConsent,
        } : {},
      },
      {
        name: 'aboutUsLink',
        placeholder: 'About us',
        label: 'About us',
        validator: VALIDATORS.URL,
        type: FieldTypes.Text,
        ...overridableProperties?.linkManagement?.aboutUsLink || sourceState?.aboutUsLink ? {
          defaultValue: overridableProperties?.linkManagement?.aboutUsLink || sourceState?.aboutUsLink,
        } : {},
        ...aboutUsLinkPermProps,
      },
    ] as Field[];
  }, [
    domainAddressPermProps,
    aboutUsLinkPermProps,
    termAndConditionsLinkPermProps,
    privacyPolicyLinkPermProps,
    sourceState,
    overridableProperties,
  ]);

  return {
    fields,
  };
};

export default useLinkManagementFields;
