import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

export interface InterestTier {
  id: string;
  balanceFrom: number;
  balanceTo: number;
  interestRate: number;
  lowAPY: number;
  highAPY: number;
}

interface BalanceTierPayload {
  id: number;
  apy_high_pct: number;
  apy_low_pct: number;
  balance_from: number;
  balance_to: number;
  interest_rate_pct: number;
  version_id: number;
}
interface InterestRateVersionPayload {
  id: number;
  natural_id: string;
  balance_tier: BalanceTierPayload[];
}
interface InterestTierPayload {
  id: number;
  versions: InterestRateVersionPayload[];
}

export default (selectedInterestId?: string) => {
  // const { data: plan } = useCurrentPlan();

  const {
    isLoading, data, isSuccess,
  } = useQuery(
    [PATHS.INTEREST_RATE_LATEST_VERSION(''), selectedInterestId],
    () => api.get<InterestTierPayload[]>(
      PATHS.INTEREST_RATE_LATEST_VERSION(selectedInterestId || ''),
    ),
    {
      enabled: !!selectedInterestId,
    },
  );
  const list = useMemo(() => {
    const version = _.get(data, 'data.versions[0]') as unknown as InterestRateVersionPayload;
    if (version) {
      return version.balance_tier
        .sort((a, b) => a.balance_from - b.balance_from)
        .map((item) => ({
          id: _.toString(item.id),
          balanceFrom: item.balance_from,
          balanceTo: item.balance_to,
          interestRate: item.interest_rate_pct,
          lowAPY: item.apy_low_pct,
          highAPY: item.apy_high_pct,
        })) as InterestTier[];
    }

    return [];
  }, [data]);
  return {
    data: list,
    isSuccess,
    isLoading,
  };
};
