import React from 'react';
import { Box, FlexListItem, Inscription, ListItem, ListItemBase } from '@common-fe/common-fe';

import TransactionsListHeader from '@/modules/transaction/TransactionsList/TransactionsListHeader/TransactionsListHeader';

const headers: ListItemBase[] = [
  {
    key: 'planName',
    title: 'Plans',
    flex: 1.8,
  },
  {
    key: 'endOfCoverage',
    title: 'End of coverage',
    flex: 0.8,
  },
  {
    key: 'lastDayToSubmitClaim',
    title: 'Last day to submit claim',
    flex: 1,
  },
];

interface Props {
  planList: ListItem[];
}

export const EmployerTerminationPlansCoverageDatesList: React.FC<Props> = ({ planList }) => {
  return (
    <Box
      data-testid="EmployerTerminationPlansCoverageDatesList_container"
      round="container1Round"
      background="canvas"
      pad="spacing24"
      border={{ color: 'border2' }}
    >
      <Box
        justify="center"
        height={{ min: '40px', max: '40px'}}
        margin={{ bottom: 'spacing16' }}
      >
        <Inscription size="20px">
          Termination Coverage Dates
        </Inscription>
      </Box>

      <Box data-testid="EmployerTerminationPlansCoverageDatesList_table">
        <TransactionsListHeader
          items={headers}
          style={{
            paddingInline: '12px',
            paddingBlock: '6px',
            marginBottom: 0,
            borderRadius: '8px',
          }}
        />

        {planList.map((item) => (
          <FlexListItem
            key={item.id}
            fields={item.fields}
            id={item.id}
            rowWrapperStyle={{
              marginTop: '2px',
            }}
            rowStyle={{
              border: 'none',
              paddingInline: '12px',
              paddingBlock: 0,
              minHeight: '40px',
              boxShadow: 'none',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
