import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Inscription,
  RawButton,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import useAccordionStore from '@/components/elements/Accordion/useAccordion.store';
  
const StyledIcon = styled(Box)<{ expanded?: boolean }>`
    svg {
    ${({ expanded, theme }) => `fill: ${expanded ? theme.colors.button : theme.colors.iconPrimary};`}
    }
  `;
const StyledIconWrapper = styled(Box)`
    i:before {
      color: ${({ theme }) => theme.colors.iconPrimary};
      font-weight: bold;
      font-size: 20px;
    }
  `;
  
export interface Props {
    title?: string;
    nodeTitle?: React.ReactNode;
    icon?: React.ReactNode;
    children: React.ReactNode;
    expandless?: boolean;
    moduleAnchorId?: string;
  }

const SelfBlockWrapper: React.FC<Props> = ({
  title,
  nodeTitle,
  icon,
  children,
  moduleAnchorId,
  expandless,
}) => {
  const { handleAdd, list } = useAccordionStore();
  const [expanded, setExpanded] = useState(true);
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
    handleAdd({
      id: moduleAnchorId || '',
      isExpanded: !expanded,
    });
  }, [expanded, handleAdd, moduleAnchorId]);
  
  useEffect(() => {
    handleAdd({
      id: moduleAnchorId || '',
      isExpanded: expanded,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const ok = list.find(({ id }) => id === moduleAnchorId) || null;
    if (ok) {
      setExpanded(ok.isExpanded);
    }
  }, [list, moduleAnchorId]);
  return (
    <div id={moduleAnchorId}>
      <Box
        direction="column"
        background="module"
        pad="spacing24"
        round="moduleRound"
      >
        <Box
          height={{ max: '40px', min: '40px' }}
          justify="center"
          margin={{ bottom: expanded ? 'spacing16' : undefined }}
        >
          <RawButton onClick={handleToggle}>
            <Box
              direction="row"
              align="center"
              justify="start"
            >
              <Box
                align="center"
                justify="center"
                direction="row"
              >
                {!expandless && (
                  <StyledIconWrapper width="24px" align="center" justify="center">
                    {expanded ? (
                      <ChevronUpIcon data-testid="BlockWrapper" size="20px" color="iconPrimary" />
                    ) : (
                      <ChevronDownIcon data-testid="BlockWrapper" size="20px" color="iconPrimary" />
                    )}
                      
                  </StyledIconWrapper>
                )}
                {icon
                  ? (
                    <StyledIcon margin={{ left: 'spacing18' }} expanded={expanded}>
                      {icon}
                    </StyledIcon>
                  )
                  : null}
              </Box>

              <Box>
                {nodeTitle
                  ? (nodeTitle)
                  : (
                    <Inscription
                      size="large"
                      margin={{ left: 'spacing8' }}
                      color="textTitle"
                      weight={700}
                    >
                      {title}
                    </Inscription>
                  )}
              </Box>

            </Box>
          </RawButton>

        </Box>
        {expanded && children}
      </Box>
    </div>
  );
};
  
export default SelfBlockWrapper;
  