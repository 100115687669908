import { useCallback, useMemo, useState } from 'react';
import { CIPStatus } from '@common-fe/common-fe';

// import { useAuthStore } from '@/modules/user';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { ScanStatuses } from '../cip.constants';
import {
  useCIPIdentificationQuery,
  useCIPInfoQuery,
} from '../queries';

interface Params {
  employeeId: string;

}
export default ({ employeeId }: Params) => {
  const [isShowErrorModal, setShowErrorModal] = useState(false);
  const { data } = useCIPInfoQuery(employeeId);
  const isVerificationRequired = useMemo(
    () => data?.status === CIPStatus.Fail || data.status === CIPStatus?.Question, [data.status],
  );
  const [isVerificationLoading, setVerificationLoading] = useState(false);
  const {
    handleRefetch, data: identificationData,
  } = useCIPIdentificationQuery({
    enabled: false,
    employeeId,
    onError: () => {
      setShowErrorModal(true);
    },
  });
  const history = useHistory();

  const handleVerify = useCallback(async () => {
    try {
      setVerificationLoading(true);
      const {  idScanUrl } = await handleRefetch();

      if ( idScanUrl) {
        history.push(ROUTES.ID_SCAN(employeeId));
      } else {
        setShowErrorModal(true);
      }
    } catch {
      setShowErrorModal(true);
    } finally {
      setVerificationLoading(false);
    }
  }, [employeeId, handleRefetch, history]);

  const isShowedBanner = useMemo(
    () => identificationData.idScan === ScanStatuses.Yes
    && isVerificationRequired, [identificationData.idScan, isVerificationRequired],
  );
  return {
    data,
    isShowErrorModal,
    setShowErrorModal,
    isShowedBanner,
    isVerificationRequired,
    isVerificationLoading,
    handleVerify,

  };
};
