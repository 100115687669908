import React, { useMemo } from 'react';
import { capitalizeFirstLetter,Inscription, ListItem, OptionKey } from '@common-fe/common-fe';

import { REGEXPS } from '@/common';
import { ListItemType, PaginationParams } from '@/common/types';

import { useGetEmailTemplatesQuery } from '../queries/useGetEmailTemplates.query';

const HSA_KEY = 'HSA';

interface CurrentPaginationParams extends PaginationParams {
  templatesIds: number[];
  categories?: OptionKey[];
  userRole?: string;
}

export const useEmailTemplatesList = (
  {
    templatesIds,
    page,
    perPage,
    searchString,
    categories,
    userRole,
  }: CurrentPaginationParams
) => {
  const {
    formattedData,
    totalElements,
    totalPages,
    isLoading,
    data,
    refetch,
  } = useGetEmailTemplatesQuery({
    templatesIds,
    categories,
    userRole,
    searchString,
    page,
    perPage,
  });
  const formattedList = useMemo<ListItem[]>(() => formattedData ? formattedData.map((item) => ({
    id: item?.id.toString() || '',
    fields: [
      {
        key: 'notificationName',
        type: ListItemType.Node,
        value: item?.notification_description || '-',
        title: item?.notification_description || '-',
        node: <Inscription weight={700}>{item?.notification_description || ''}</Inscription>,
        fieldStyle: { alignItems: 'flex-start', flex: '1.1' },
      },
      {
        key: 'templateName',
        type: ListItemType.Text,
        value: item?.template_description || '-',
        title: item?.template_description || '-',
        fieldStyle: {
          alignItems: 'flex-start',
          flex: '1.2',
          marginRight: '16px',
        },
        ellipsisMode: true,
        style: {
          maxWidth: '100%',
        },
      },
      {
        key: 'category',
        type: ListItemType.Text,
        value: item?.category_type || '-',
        title: item?.category_type && item?.category_type !== HSA_KEY
          ? capitalizeFirstLetter(item?.category_type).replace(REGEXPS.DASH_SYMBOL, ' ')
          : item?.category_type || '-',
        fieldStyle: {
          alignItems: 'flex-start',
          flex: '0.8',
        },
      },
      {
        key: 'destination',
        type: ListItemType.Text,
        value: item?.user_type || '-',
        title: item?.user_type ? capitalizeFirstLetter(item?.user_type) : '-',
        fieldStyle: { alignItems: 'flex-start', flex: '0.8' },
      },
    ],
  })) : [], [formattedData]);

  const isEmpty = useMemo(() => totalElements === 0, [totalElements]);
  return {
    isLoading,
    isEmpty,
    formattedList,
    totalPages,
    totalElements,
    data,
    refetch,
  };
};
