import React from 'react';
import { Anchor, Box, Text } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import { useEditModeStore, usePreviewStore } from '../../stores';

interface Props {
  isTemplateMode?: boolean;
}
const SwitchToEditMode: React.FC<Props> = ({ isTemplateMode }) => {
  const previewMode = usePreviewStore((state) => state.previewMode);
  const setEditMode = useEditModeStore((state) => state.setEditMode);
  if (previewMode) {
    return null;
  }
  return (
    <Access
      accessRequirements={[
        {
          permission: isTemplateMode
            ? Permissions.PLAN_TEMPLATE_SETUP
            : Permissions.EDIT_DRAFT_PLAN,
        },
      ]}
    >
      <Box direction="column" align="center" margin={{ top: 'spacing24', bottom: 'spacing8' }}>
        <Text size="medium" color="textSecondary">
          To apply any changes, please switch to <Anchor onClick={() => setEditMode(true)} weight="normal" label="Edit Mode" />.
        </Text>
      </Box>
    </Access>
  );
};

export default SwitchToEditMode;
