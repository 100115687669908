import React, { useCallback } from 'react';
import {
  AppButton, Box, Preloader, WarnModal,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { useSnackbar } from '@/modules/core/hooks';

import { useUnlinkTemplateQuery } from './queries';

const SERVER_ERROR_CODE = 400;

interface Props {
  isVisible: boolean;
  templateId?: string;
  templateName?: string;
  handleOpenWarnModal: () => void;
  handleOpenModal: (value: boolean) => void;
}
const PlanTemplateDeleteModal: React.FC<Props> = ({
  templateId,
  templateName,
  handleOpenWarnModal,
  handleOpenModal,
  isVisible,
}) => {
  const { handleDelete, isLoading } = useUnlinkTemplateQuery(() => handleOpenModal(false));
  const { handleAddPermanentSnackbar } = useSnackbar();
  const handleConfirm = useCallback(async () => {
    try {
      if (templateId) {
        await handleDelete(templateId);
        handleAddPermanentSnackbar({
          text: `${templateName} is unassigned`,
          closeIcon: true,
        });
      }
     
    } catch (e) {
      const code = _.get(e, 'response.status', 0) as number;
      if (code === SERVER_ERROR_CODE) {
        handleOpenModal(false);
        handleOpenWarnModal();
      }
    }
  }, [handleDelete, templateId, handleOpenWarnModal,
    handleOpenModal, handleAddPermanentSnackbar, templateName]);

  return (
    <WarnModal
      testId="plan-template-unlink"
      visible={isVisible}
      header={`Remove ${templateName}?`}
      onSetVisible={handleOpenModal}
      cancelButtonNode={(
        <Box direction="row">
          <Box width="150px" margin={{ right: 'spacing6' }}>
            <AppButton
              testId="plan-template-delete_cancel"
              buttonType="secondary"
              onClick={() => handleOpenModal(false)}
              width="100%"
            >
              Cancel
            </AppButton>
          </Box>
          <Box width="150px" margin={{ left: 'spacing6' }}>
            <AppButton
              testId="plan-template-delete_apply"
              color="red"
              onClick={handleConfirm}
              width="100%"
            >
              {isLoading
                ? <Preloader color="white" />
                : 'Yes, Remove'}
            </AppButton>
          </Box>
        </Box>

      )}
    />
  );
};

export default PlanTemplateDeleteModal;
