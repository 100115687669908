import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface BasePersonDto {
  id: number;
  preferred_name: string;
  first_name: string;
  last_name: string;
}
export interface DependentInfoDto {
  id?: string;
  first_name: string;
  last_name: string;
  birth_on: Date;
  person: BasePersonDto;
  relationship_type: string;
}

export interface DependentFormValue {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  relationship: string;
  dependentId?: string;
}

const CREATE_DEPENDENT_KEY = 'update_card_holder';

export const useAddCardDependentMutation = (onSuccess?: (dependent: DependentInfoDto) => void) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, 'true');

    return queryGenerator;
  }, []);

  const { mutateAsync, isLoading, isError } = useMutation(
    (data: DependentInfoDto) => api.post(`${PATHS.PERSON_DEPENDENT_WORKFLOWS}?${query}`, data),
    {
      onSuccess: (_, value) => {
        if (onSuccess) onSuccess(value);
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
    isError,
  };
};

export const useUpdateCardDependentMutation = () => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, 'true');

    return queryGenerator;
  }, []);

  const { mutateAsync, isLoading, isError } = useMutation(
    (data: DependentInfoDto) => api.put(`${PATHS.PERSON_DEPENDENT_WORKFLOWS}/${data.id}?${query}`, data),
  );

  return {
    mutateAsync,
    isLoading,
    isError,
  };
};
