import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, RawButton, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useHistory } from '@/modules/core/hooks';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textAccent};
  text-decoration: none;
`;

interface Props {
  children: React.ReactNode;
  to: string;
  linkText: string;
  affix?: string;
  readMode?: boolean;
}
const RedirectSection: React.FC<Props> = ({
  children, to, linkText, affix, readMode,
}) => {
  const history = useHistory();
  const handleRedirect = useCallback(() => {
    history.push(to);
  }, [history, to]);
  return (
    <Box align="center" justify="center" pad="spacing8">
      <Box direction="row">
        <Text size="medium" color="textSecondary">
          {children} {' '}
          {readMode ? linkText : (
            <RawButton target="_blank" onClick={handleRedirect} >
              <Text color="button">
                {linkText}
              </Text>
            </RawButton>
          )}

          {' '}
          {affix}
        </Text>

      </Box>
    </Box>
  );};

export default RedirectSection;
