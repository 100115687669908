import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useGracePeriodFieldsStateStore, { GracePeriodFieldsStatePayload } from '../GracePeriod/stores/useGracePeriodFieldsState.store';
import usePayClaimsStore, { PayClaimsFieldsStatePayload } from '../PayClaims/stores/usePayClaimsFieldsState.store';

export type CoverageFieldsStatePayload = GracePeriodFieldsStatePayload
& PayClaimsFieldsStatePayload;

export default () => {
  const setGracePeriodStore = useGracePeriodFieldsStateStore((state) => state.setState);
  const setPayClaimsStore = usePayClaimsStore((state) => state.setState);

  const setSourceGracePeriodStore = useGracePeriodFieldsStateStore((state) => state.setSourceState);
  const setSourcePayClaimsStore = usePayClaimsStore((state) => state.setSourceState);

  const showErrorsGracePeriodStore = useGracePeriodFieldsStateStore((state) => state.setShowErrors);
  const showErrorsPayClaimsStore = usePayClaimsStore((state) => state.setShowErrors);

  const handleResetGracePeriodStore = useGracePeriodFieldsStateStore((state) => state.handleReset);
  const handleResetPayClaimsStore = usePayClaimsStore((state) => state.handleReset);

  const setGracePeriodErrors = useGracePeriodFieldsStateStore((state) => state.setErrors);
  const setPayClaimsErrors = usePayClaimsStore((state) => state.setErrors);

  const setGracePeriodErrorFields = useGracePeriodFieldsStateStore((state) => state.setErrorFields);
  const setPayClaimsErrorFields = usePayClaimsStore((state) => state.setErrorFields);

  const handleSetState = useCallback((values: Partial<CoverageFieldsStatePayload>) => {
    setGracePeriodStore(values);
    setPayClaimsStore(values);
  }, [setGracePeriodStore, setPayClaimsStore]);

  const handleSetSourceState = useCallback((values: Partial<CoverageFieldsStatePayload>) => {
    setSourceGracePeriodStore(values);
    setSourcePayClaimsStore(values);
  }, [setSourceGracePeriodStore, setSourcePayClaimsStore]);

  const handleReset = useCallback(() => {
    handleResetGracePeriodStore();
    handleResetPayClaimsStore();
  }, [handleResetGracePeriodStore, handleResetPayClaimsStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsGracePeriodStore(value);
    showErrorsPayClaimsStore(value);
  }, [showErrorsGracePeriodStore, showErrorsPayClaimsStore]);
  const handleSetErrors = useCallback((values: Partial<CoverageFieldsStatePayload>) => {
    setGracePeriodErrors(values);
    setPayClaimsErrors(values);
  }, [setGracePeriodErrors, setPayClaimsErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<CoverageFieldsStatePayload>>) => {
      setGracePeriodErrorFields(values);
      setPayClaimsErrorFields(values);
    }, [setGracePeriodErrorFields, setPayClaimsErrorFields],
  );
  return {
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
  };
};
