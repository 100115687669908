import React, { useEffect, useMemo } from 'react';
import { Box, CrossIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ReportTypes } from '@/modules/employer/components/Reports/report.types';

import { useReportFilterPopupStore } from './store/useReportFilterPopup.store';
import { AccountActivityReportFilters } from './AccountActivityReportFilters';
import { CardReportFilters } from './CardReportFilters';
import { ClaimReimbursementActivityReportFilters } from './ClaimReimbursementActivityReportFilters';
import { ContributionInvoiceReportFilters } from './ContributionInvoiceReportFilters';
import { ReplenishmentReportFilters } from './ReplenishmentInvoiceReportFilters';

export enum GroupBy {
  NotAll = 'NotAll',
  Null = 'Null',
}

export const CATEGORIES_OPTIONS = [
  {
    key: GroupBy.Null,
    value: 'All',
    title: 'All',
  },
  {
    key: GroupBy.NotAll,
    value: GroupBy.NotAll,
    title: GroupBy.NotAll,
  },
];

const StyledBox = styled(Box)<{ visible?: boolean }>`
  position: absolute;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  top: 16px;
  left: 0;
  right: 0;
  ${({ theme }) => theme.border.grey6};
  ${({ theme }) => theme.shadows.active};
  z-index: 2;
  animation: myAnim .3s ease 0s 1 normal forwards;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
}
`;

interface Props {
  onVisible: () => void;
  reportType?: ReportTypes;
}

export const ReportFilterPopup: React.FC<Props> = ({
  onVisible,
  reportType,
}) => {
  const currentPopup = useReportFilterPopupStore((state) => state.currentPopup);
  const onClearVisible = useReportFilterPopupStore((state) => state.handleClear);
  const currentFilter = useMemo(() => {
    switch (reportType) {
    case ReportTypes.Replenishment:
      return <ReplenishmentReportFilters />;
    case ReportTypes.Contribution:
      return <ContributionInvoiceReportFilters />;
    case ReportTypes.Card:
      return <CardReportFilters />;
    case ReportTypes.ParticipantAccountActivity:
      return <AccountActivityReportFilters />;
    case ReportTypes.Claims:
      return <ClaimReimbursementActivityReportFilters />;

    default:
      return null;
    }
  }, [reportType]);

  const isVisible = useMemo(() => currentPopup === reportType, [currentPopup, reportType]);

  useEffect(() => {
    if (!isVisible) {
      onVisible();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <StyledBox
      width="100%"
      visible={isVisible}
      direction="row"
      background={{ color: 'canvas' }}
      pad={{ vertical: '11px', horizontal: 'spacing16' }}
      round="spacing12"
      data-testid="report_filter_popup"
      gap="spacing12"
    >

      {currentFilter}

      <Box
        width="1px"
        height="40px"
        background={{ color: 'border1' }}
      />

      <Box
        data-testid="report_filter_popup-close_button"
        justify="center"
        align="center"
        round="xs"
        width={{ min: '40px' }}
        height={{ min: '40px' }}
        background={{ color: 'legend' }}
        onClick={() => {
          onClearVisible();
          onVisible();
        }}
      >
        <CrossIcon size="14px" color="iconPrimary" />
      </Box>
    </StyledBox>
  );
};
