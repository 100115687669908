import React, { useMemo } from 'react';
import {
  Box, combineClassNames, Preloader,
  Table, TableBody, TableCell, TableHeader, TableRow,   Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { INPUT_DATE_FORMAT } from '@/common/constants';
import {
  ReportItemNestedDataGroup,
  ReportItemNestedTableData,
  ReportTableItem,
} from '@/modules/employer/components/Reports/report.types';
import spacings from '@/styles/spacings';

const CUT_ROWS = 4;

interface Props {
  data: ReportItemNestedTableData;
  header: ReportTableItem[];
  expand?: boolean;
  isFullWidth?: boolean;
  isEmpty?: boolean;
  isSubLoading?: boolean;
  hasPages?: boolean;
  isLastPage?: boolean;
}

const StyledTable = styled(Table)`
  thead tr {
    background-color: ${({ theme }) => theme.colors.canvas};
    position: sticky;
    top: -1px;
    z-index: 100;
    border-top: solid 1px ${({ theme }) => theme.colors.border1};
    border-bottom: solid 1px ${({ theme }) => theme.colors.border1};
  }
  tr {
    cursor: pointer;
    &.regular-hover {
      &:hover {
        background-color: ${({ theme }) => theme.colors.border2};
      }
    }
    &.total-hover {
      &:hover {
        background-color: ${({ theme }) => theme.colors.warningBorder};
      }
    }
    transition: background-color .2s ease-in-out;
  }
  td {
    padding: 0;
    max-width: 200px;
    &:first-child {
      border-radius: ${({ theme }) => `${theme.spacings.spacing4} 0 0 ${theme.spacings.spacing4}`};
    }
    &:last-child {
      border-radius: ${({ theme }) => `0 ${theme.spacings.spacing4} ${theme.spacings.spacing4} 0`};
    }
  }
  .no-pad {
    padding: 0;
  }
  .deep-nested-title {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.border};
      left: -10px;
      top: 14px;
    }
  }
  .deep-nested-row {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.border};
      left: 0;
      top: 0;
    }
  }
  .last-nested-row-field {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.border};
      left: -12px;
      top: -14px;
    }
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.border};
      left: -12px;
      top: 10px;
    }
  }
`;

interface CellProps {
  field: ReportTableItem;
  cellStyle?: React.CSSProperties;
  lastRow?: boolean;
  header?: boolean;
}

const ReportItemNestedTableCell: React.FC<CellProps> = ({
  field,
  cellStyle,
  lastRow,
  header,
}) => {
  const fieldValue = useMemo(() => (field.key === 'invoice_date' && !field.isHeader
    ? dayjs(field.value).format(INPUT_DATE_FORMAT)
    : field.value), [field]);
  return (
    <TableCell
      key={field.key}
      style={{
        padding: '0px',
        ...field.flex
          ? { flex: field.flex }
          : {},
      }}
    >
      <Box
        style={{
          ...field.wrapStyle,
          ...cellStyle,
        }}
      >
        <Box
          className={`
        ${lastRow ? ' last-nested-row-field ' : ''}
        ${field.className}
        `}
          pad={header ? 'spacing8' : 'spacing4'}
          style={{
            ...field.style,
          }}
        >
          <Text
            style={{
              position: 'relative',
              zIndex: 10,
              whiteSpace: 'nowrap',
              ...field.textStyle,
            }}
            size="12px"
            weight={500}
            color={field.isHeader ? 'textSecondary' : 'textBody'}
          >
            {fieldValue}
          </Text>
        </Box>
      </Box>
    </TableCell>
  );
};

interface RowProps {
  header?: ReportTableItem[]
  group?: ReportItemNestedDataGroup;
  isFullWidth?: boolean;
  nestLevel?: number;
  isCutRows?: boolean;
}

export const ReportItemNestedTableRow: React.FC<RowProps> = ({
  group,
  header,
  isFullWidth,
  nestLevel = 0,
  isCutRows,
}) => {
  const headersFields = useMemo(() => (header || []), [header]);
  const contentRows = useMemo(
    () => (group?.rows || [])
      .slice(...isCutRows ? [0, CUT_ROWS] : []),
    [group, isCutRows],
  );
  const isDeepNestedLevel = useMemo(() => (nestLevel > 1), [nestLevel]);

  if (headersFields.length) {
    return (
      <TableRow>
        {
          headersFields.map(
            (field) => (<ReportItemNestedTableCell header key={`${field.key}-header`} field={field} />),
          )
        }
      </TableRow>
    );
  }
  return (
    <>
      {
        group?.title && (
          <TableRow
            className={combineClassNames([
              ...isDeepNestedLevel ? ['deep-nested-row'] : [],
            ])}
          >
            <TableCell>
              <Box
                className={combineClassNames([
                  ...isDeepNestedLevel ? ['deep-nested-title'] : [],
                ])}
                style={{
                  marginLeft: `${nestLevel * 4}px`,
                  padding: `${spacings.spacing4} 0`,
                }}
              >
                <Text
                  weight="bold"
                  size="medium"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {group?.title}
                </Text>
              </Box>
            </TableCell>
          </TableRow>
        )
      }
      {
        group?.children?.map((child) => (
          <ReportItemNestedTableRow
            key={`${child.key}-${nestLevel + 2}-${child.id}}`}
            group={child}
            nestLevel={nestLevel + 2}
          />
        ))
      }
      {
        contentRows.map((row, index) => (
          <TableRow
            className={combineClassNames([
              ...!row.lastRow && isDeepNestedLevel ? ['deep-nested-row'] : [],
              ...row.classNames || [],
            ])}
            key={row.key}
            style={row.style}
          >
            {
              row.fields.map(
                (field, fieldIndex) => (
                  <ReportItemNestedTableCell
                    lastRow={row.lastRow && !fieldIndex}
                    key={`${field.key}-${field.value}`}
                    field={field}
                    cellStyle={{
                      ...row.fields.length === fieldIndex + 1
                        ? {}
                        : {
                          marginLeft: isDeepNestedLevel ? field.wrapStyle?.marginLeft || `${nestLevel * 4}px` : field.wrapStyle?.marginLeft || spacings.spacing4,
                        },
                    }}
                  />
                ),
              )
            }
          </TableRow>
        ))
      }
    </>
  );
};

export const ReportItemNestedTableContent: React.FC<Props> = ({
  data,
  header,
  isFullWidth,
  isEmpty,
  isSubLoading,
  hasPages,
  isLastPage,
  expand,
}) => (
  <>
    {isEmpty ? (
      <Box>
        <Text size="12px" weight={500}>
          No results have been found.
        </Text>
      </Box>
    ) : (
      <StyledTable>
        <TableHeader>
          <ReportItemNestedTableRow
            isFullWidth={isFullWidth}
            header={header.map((field) => ({
              ...field,
              isHeader: true,
            }))}
          />
        </TableHeader>
        <TableBody>
          {
            data.groups.slice(...expand ? [] : [0, CUT_ROWS]).map((group) => (
              <ReportItemNestedTableRow
                isCutRows={!expand}
                key={group.id || group.title}
                group={group}
              />
            ))
          }
        </TableBody>
      </StyledTable>
    )}
    {hasPages && (
      <>
        {isSubLoading ? (
          <Box
            direction="row"
            justify="center"
            align="center"
            pad={{ vertical: 'spacing12' }}
          >
            <Preloader color="iconAccent" />
          </Box>
        ) : (
          <>
            {!isLastPage && (
              <Box pad="spacing12" justify="center" align="center">
                <Text size="12px" color="textDisabled">
                  Scroll to load more
                </Text>
              </Box>
            )}
          </>
        )}
      </>
    )}
  </>
);
