import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollGroup, PayrollGroupResponse } from '../payrollGroup.types';
// mock.onGet(PATHS.GET_PAYROLL_GROUPS('783505')).reply(200, [
//   {
//     id: 12232,
//     name: 'name',
//     code: 'code',
//   },
//   {
//     id: 122322,
//     name: 'name2',
//     code: 'code2',
//   },
// ]);

export const QUERY_KEY = 'useGetPayrollGroupsQueryKey';

export const useGetPayrollGroupsQuery = (orgId?: string) => {
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [QUERY_KEY, orgId],
    () => api.get<PayrollGroupResponse[]>(PATHS.GET_PAYROLL_GROUPS(orgId)),
    {
      enabled: !!orgId,
    },
  );

  const total = useMemo(() => data?.data?.length || 0, [data]);
  const updatedData = useMemo(() => data?.data, [data]);
  const formattedData = useMemo(() => data?.data?.map((item) => ({
    id: toString(item.id),
    name: item.name,
    code: item.code,
    createdAt: item?.created_at,
    organizationId: toString(item?.organization_id),
    organizationPath: item?.organization_path,
  } as PayrollGroup)) || [], [data]);
  return {
    formattedData,
    data: updatedData,
    total,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
