import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useSidebarOptions } from '@/modules/core/components/Sidebar/hooks/useSidebarOptions';
import { useSidebarStore } from '@/modules/core/components/Sidebar/store/useSidebarStore';
import { useHistory } from '@/modules/core/hooks';
import { useShowLockBanner } from '@/modules/core/hooks';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import { QueryKeys } from '@/modules/core/hooks/useHistory';

import {
  BackgroundOverlay, SidebarBody, SidebarContent, Wrapper,
} from './Sidebar.styles';
import SidebarHeader from './SidebarHeader';
import SidebarItem from './SidebarItem';

export interface Props {
  defaultMinify: boolean;
}

const SIDEBAR_PINNED_WIDTH = '272px';
const SIDEBAR_MINIFY_WIDTH = '88px';

const Sidebar: React.FC<Props> = ({ defaultMinify }) => {
  const {
    minify, setMinify, setPinned, pinned,
  } = useSidebarStore(defaultMinify);
  const isLockBannerShowed = useShowLockBanner();

  const optionList = useSidebarOptions();
  const history = useHistory();
  const { pathname } = useLocation();

  const { bannerHeight } = useEmployerTerminationBanner();

  const [withOverflow, setWithOverflow] = useState(false);

   
  useEffect(() => {
    if (minify) {
      setWithOverflow(false);
    } else {
      const timerId = setTimeout(() => {
        setWithOverflow(true);
      }, 500);

      return () => clearTimeout(timerId);
    }
  }, [minify]);

  const handleSetTab = useCallback((value: string, url?: string) => {
    if (pathname !== url && url) history.push(url);
  }, [history, pathname]);

  const resetReconciliationReportParameters = useCallback((url: string) => {
    if (!url.includes(ROUTES.FINOPS)) {
      history.removeQueryParameter([
        QueryKeys.RECONCILIATION_REPORTS_DATE,
        QueryKeys.RECONCILIATION_REPORTS_ACTIVITY_DATE,
      ]);
    }
  }, [history]);

  return (
    <>
      {!pinned && !minify && <BackgroundOverlay />}
      <Box
        gridArea="sidebar"
        margin={{ left: pinned ? '272px' : '88px' }}
      >
        <Wrapper
          data-testid="hover-container"
          overflow={withOverflow ? 'visible' : 'hidden'}
          onMouseEnter={() => setMinify(false)}
          onMouseLeave={() => !pinned && setMinify(true)}
          width={minify && !pinned ? SIDEBAR_MINIFY_WIDTH : SIDEBAR_PINNED_WIDTH}
          isLockBannerMode={isLockBannerShowed}
          customTopValue={bannerHeight}
        >
          <SidebarContent width={SIDEBAR_PINNED_WIDTH}>
            <SidebarHeader minify={minify} onPin={setPinned} onMinify={setMinify} pinned={pinned} />
            <Box height="1px" margin={{ bottom: 'spacing12' }} background={{ color: 'navigationBorder' }} />
            <SidebarBody>
              {optionList.map(({
                title, key,
                icon, delimiter, url,
                isLogOut, isDotsIcon,
                isLink, disabled, withTip,
                tipText, options, subUrls,
              }) => (
                <SidebarItem
                  path={pathname}
                  key={key}
                  title={title}
                  icon={icon}
                  currentKey={key}
                  isLink={isLink}
                  isDotsIcon={isDotsIcon}
                  delimiter={delimiter}
                  minify={minify}
                  disabled={disabled}
                  withTip={withTip}
                  tipText={tipText}
                  url={url}
                  subUrls={subUrls}
                  options={options}
                  onClick={() => {
                    if (url && !isLogOut) {
                      resetReconciliationReportParameters(url as string);
                    }
                  }}
                  onPick={handleSetTab}
                />
              ))}
            </SidebarBody>
          </SidebarContent>
        </Wrapper>
      </Box>
    </>
  );
};

export default Sidebar;
