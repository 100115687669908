import React, { useCallback,useState } from 'react';
import { Notification, NotificationCenter } from '@common-fe/common-fe';
import _ from 'lodash';

import NotificationModal from '@/modules/core/components/NotificationDetailsModal/NotificationModal';
import {
  useNotifications,
} from '@/modules/core/components/NotificationsList/hooks';
import { useNotificationCenterStore } from '@/modules/core/store';

const Notifications = () => {
  const [activeNotification, setActiveNotification] = useState<Notification>();
  const { isOpenNotificationCenter, setNotificationCenterState } = useNotificationCenterStore();
  const [
    notificationsData,
    setFilteredNotifications,
  ] = useState(useNotifications());

  const dismissNotification = useCallback((id?: string) => {
    setFilteredNotifications(_.reject(notificationsData, (item) => item.id === id));
  }, [notificationsData]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <>
      <NotificationCenter
        notificationsArray={notificationsData}
        setNotificationCenterState={setNotificationCenterState}
        setActiveNotification={setActiveNotification}
        setOpenModal={setOpenModal}
        dismissNotification={dismissNotification}
        isModalOpened={openModal}
        isOpenNotificationCenter={!!isOpenNotificationCenter}
      />
      <NotificationModal
        notification={activeNotification}
        setOpenModal={setOpenModal}
        openModal={openModal}
        dismissNotification={dismissNotification}
      />
    </>
  );
};

export default Notifications;
