import React, { useMemo, useState } from 'react';
import {
  Box, ChevronDownIcon,
  ChevronUpIcon,
  Inscription,
  NewTabIcon, 
  Status} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _, { capitalize } from 'lodash';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { goToContributionActivities } from '@/pages/ContributionActivities.page';

import { ContributionListItemType, ContributionRequestStatus } from '../contributions.types';

import { ContributionInvoiceList } from './ContributionInvoiceList/ContributionInvoiceList';
import { ContributionListItemButton } from './ContributionListItemButton';
import ContributionListItemSource from './ContributionListItemSource';
import { CONTRIBUTIONS_TABLE_HEADERS } from './ContributionsList';

const EllipsisText = styled(Inscription)<{ hasUppercase?: boolean }>`
text-overflow: ellipsis;
max-width: 100%;
overflow: hidden;
white-space: nowrap;
&:first-letter {
  ${({ hasUppercase = true }) => {
    if (hasUppercase) return 'text-transform: uppercase;';
    return '';
  }};
}
`;

const Wrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border2};
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.03);
`;

interface Props {
  data?: ContributionListItemType;
}

const ContributionListItem: React.FC<Props> = ({ data }) => {
  const [isExpanded, setExpanded] = useState(false);
  const isApprovalNeededStatus = useMemo(() => data?.status ===  ContributionRequestStatus.APPROVAL_NEEDED, [data]);
  const formattedAccounts = useMemo(() => {
   
    const list = data?.accounts || [];
  
    const uniqueList = _.uniq(list);
    return uniqueList.length?  uniqueList.join(', ') : '-';
    
  }, [data?.accounts]);
  return (
    <Wrapper
      data-testid="ContributionListItem_container"
      background="canvas"
      pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
      round="fieldRound"
      width="100%"
    >
      <Box
        direction="row"
        align="center"
        height={{ min: '40px' }}
      >
        {CONTRIBUTIONS_TABLE_HEADERS.map((tableHeader) => (
          <Box key={tableHeader.title} width={tableHeader.width} direction="row" align="center">
            {tableHeader.title === 'Submit date' ? (
              <Box direction="row" align="center" justify="center">
                <Box
                  data-testid="ContributionListItem_expandButton"
                  justify="center"
                  align="center"
                  direction="row"
                  onClick={() => setExpanded(!isExpanded)}
                  margin={{ right: 'spacing12' }}
                >
                  { isExpanded ? (
                    <ChevronUpIcon color="textTitle" />
                  ) : (
                    <ChevronDownIcon color="textTitle" />
                  )}
                </Box>
                <Inscription lineHeight="22px" size="16px" color="textBody">
                  {data?.submitDate ? dayjs(data?.submitDate).format(DEFAULT_DATE_FORMAT) : '-'}
                </Inscription>
              </Box>
            ) : null}

            {tableHeader.title === 'Source' ? (
              <ContributionListItemSource value={data?.contributionSource} />
            ) : null}

            {tableHeader.title === 'Accounts' ? (
              <EllipsisText color="textBody" lineHeight="20px" title={data?.accounts?.length ? data?.accounts.join(', ') : '-'} style={{ maxWidth: '170px' }}>
                {formattedAccounts}
              </EllipsisText>
            ) : null}

            {tableHeader.title === 'Status' ? <Status testId="contributionStatus" ellipsisMode status={capitalize(data?.status)} /> : null}

            {tableHeader.title === 'Contribution activity' ? (
              <Box direction="row" align="center" onClick={() => goToContributionActivities(data, isApprovalNeededStatus)}>
                <NewTabIcon color="textAccent" />
                <Inscription lineHeight="20px" color="textAccent" margin={{ left: 'spacing4' }}>
                  {isApprovalNeededStatus ? 'Review and Approve' : 'Show records'} ({data?.recordCount || 0})
                </Inscription>
              </Box>
            ) : null}
          </Box>
        ))}

        {data?.contributionId ? (
          <Box width="2%" align="end">
            <ContributionListItemButton contributionId={data.contributionId} />
          </Box>
        ) : null}
      </Box>

      {isExpanded ? <ContributionInvoiceList data={data?.recordsInfo} /> : null}
    </Wrapper>
  );
};

export default ContributionListItem;
