import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { Roles } from '@/common/constants';
import useContactStore from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

export default (isMainContactMode?: boolean) => {
  const defaultFields = useContactStore((state) => state.sourceState);
  const fields = useMemo((): Field[] => [
    {
      name: 'role',
      type: FieldTypes.Radio,
      label: 'Role',
      showRequireIcon: true,
      disabled: isMainContactMode,
      options: [
        {
          label: 'Full Access',
          value: Roles.admin1,
          hintText: 'Contact will have full access to all tools on the employer dashboard.',
        },
        {
          label: 'No Access',
          value: Roles.noAccess,
          hintText: 'Contact will not be grated access but will receive notifications and alerts regarding activity for the plan.',
        },
      ],
      value: isMainContactMode ? Roles.admin1 : '',
      validator: yup.string().required(REQUIRED_TEXT),
    },
  ], [isMainContactMode]);

  const formatedFields = useFieldsWithDefaultValues(fields, defaultFields);
  return formatedFields;
};
