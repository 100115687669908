import React from 'react';
import { Box } from '@common-fe/common-fe';

import { CategoryType,PayoutDefinition } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import ServiceItem from './ServiceItem';

interface Props {
  data?: PayoutDefinition;
  onDirty?: (isDirty: boolean) => void;
}

const Services: React.FC<Props> = ({ data, onDirty }) => (
  <>
    {
      data?.categoryTypes?.map((item: CategoryType, index: number) => (
        <Box
          key={item.categoryType}
          background="canvas"
          round="container1Round"
          elevation="default"
          id="basic_properties"
          data-testId={`${item.categoryType}_service-item`}
          pad="l"
          margin={{
            bottom: (data?.categoryTypes?.length && data?.categoryTypes.length - 1) !== index
              ? 'l' : '0',
          }}
        >
          <ServiceItem
            data={item}
            onDirty={onDirty}
          />
        </Box>
      ))
    }
  </>
);

export default Services;
