import { useCallback } from 'react';

import { useBasicPropertiesSetter } from '../BasicProperties/hooks';
import { useCoverageLevelsSetter } from '../CoverageLevels/hooks';
import { useSubmitFormStore } from '../stores';

export default () => {
  const setActivateValidate = useSubmitFormStore((state) => state.setActivateValidate);
  const setSaveValidate = useSubmitFormStore((state) => state.setSaveValidate);
  // const setSaveValidate = useSubmitFormStore((state) => state.setSaveValidate);

  const { handleShowErrors: handleShowDefinitionErrors } = useBasicPropertiesSetter();
  const { handleShowErrors: handleShowCoverageLevelsErrors } = useCoverageLevelsSetter();
  const handleSetActivateValidate = useCallback(
    (value: boolean) => {
      setActivateValidate(value);
      handleShowDefinitionErrors(true);
      handleShowCoverageLevelsErrors(true);
    },
    [handleShowCoverageLevelsErrors, handleShowDefinitionErrors, setActivateValidate],
  );
  const handleSetSaveValidate = useCallback(
    (value: boolean) => {
      setSaveValidate(value);
      handleShowDefinitionErrors(true);
      handleShowCoverageLevelsErrors(true);
    },
    [handleShowCoverageLevelsErrors, handleShowDefinitionErrors, setSaveValidate],
  );
  return {
    setActivateValidate: handleSetActivateValidate,
    setSaveValidate: handleSetSaveValidate,
  };
};
