import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export const useDeactivatePayrollCalendarQuery = () => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (id: string) => api.delete(PATHS.UPDATE_OR_DELETE_PAYROLL_CALENDAR(id)),
  );
  return {
    deactivateCalendar: mutateAsync,
    isLoading,
    isSuccess,
  };
};
