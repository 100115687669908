import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';

import { TOCSidebarProps } from '@/modules/core/components/TOCSidebar/TOCSidebar.types';

import { useScrollStore } from '../stores';

export const TOC_CONTAINER_UI_ID = 'toc-sidebar-id';
export const SETUP_CONTAINER_UI_ID = 'setup-form-id';

export const TOC_MODULE_UI_ID = (moduleId: string) => `${moduleId}-toc-module`;

const DEBOUNCE_DELAY = 200;
const START_HEADER_ANIMATION_OFFSET = 50;
const NEXT_SECTION_SELECT_OFFSET = 150;

interface ISectionList {
  sectionId?: string,
  ref: () => HTMLElement | null,
  moduleId: string,
  tocPosition: () => DOMRect | undefined;
}

export const useProgressScroll = (config: TOCSidebarProps['stepsMap']) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const setActiveSection = useScrollStore((state) => state.setActiveSection);
  const setActiveModule = useScrollStore((state) => state.setActiveModule);
  const setHideHeader = useScrollStore((state) => state.setHideHeader);

  const sectionList = useMemo(() => Object.keys(config).reduce((result, moduleId) => {
    const { sections } = config[moduleId];
    return [
      ...result,
      ...sections
        ? Object.keys(sections).map((sectionId) => ({
          sectionId,
          ref: () => document.getElementById(sectionId),
          tocPosition:
          () => document.getElementById(TOC_MODULE_UI_ID(moduleId))?.getBoundingClientRect(),
          moduleId,
        }))
        : [{
          moduleId,
          ref: () => document.getElementById(moduleId),
          tocPosition:
          () => document.getElementById(TOC_MODULE_UI_ID(moduleId))?.getBoundingClientRect(),
        }],
    ];
  }, [] as ISectionList[]), [config]);
  useEffect(() => {
    setActiveModule(sectionList[0]?.moduleId);
  }, [sectionList, setActiveModule]);

  const onScrollHandler = useCallback((e:  React.UIEvent<HTMLElement>) => {
    if (e.currentTarget.scrollTop > START_HEADER_ANIMATION_OFFSET) {
      setHideHeader(true);
    } else if (e.currentTarget.scrollTop < START_HEADER_ANIMATION_OFFSET) {
      setHideHeader(false);
    }
    if (timer.current) return;

    timer.current = setTimeout(() => {
      const { scrollTop } = (e.target as HTMLElement);
      const setupWrapElem = document.getElementById(SETUP_CONTAINER_UI_ID);
      if (!setupWrapElem) return;

      const localActiveItem = scrollTop === 0
        ? sectionList[0]
        : sectionList.find((item) => {
          const moduleRef = item.ref();
          if (!moduleRef) return false;
          return (
            moduleRef.getBoundingClientRect().y
            + moduleRef.getBoundingClientRect().height
            - NEXT_SECTION_SELECT_OFFSET
          ) > 0;
        });
      if (localActiveItem) {
        const { sectionId, moduleId } = localActiveItem;
        const moduleTOCPosition = localActiveItem.tocPosition();
        const tocWrapElem = document.getElementById(TOC_CONTAINER_UI_ID);
        setActiveSection(sectionId);
        setActiveModule(moduleId);
        if (moduleTOCPosition && tocWrapElem
          && (moduleTOCPosition.y > tocWrapElem.offsetHeight
          || moduleTOCPosition.y < 0)
        ) {
          const tocItemPos = moduleTOCPosition.y - tocWrapElem.offsetTop;
          tocWrapElem.scrollTo({ top: tocItemPos, behavior: 'smooth' });
        }
      }
      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
    }, DEBOUNCE_DELAY);
  }, [setHideHeader, sectionList, setActiveSection, setActiveModule]);

  const scrollToSection = useCallback((data: { module?: string, section?: string }) => {
    setActiveSection(data.section);
    setActiveModule(data.module);
    if (!data.module) return;

    const setupWrapElem = document.getElementById(SETUP_CONTAINER_UI_ID);
    if (!setupWrapElem) return;

    if (data.module && !data.section) {
      const elem = document.getElementById(data.module);
      if (elem) {
        const tocItemPos = elem.offsetTop - setupWrapElem.offsetTop;
        setupWrapElem.scrollTo({ top: tocItemPos, behavior: 'auto' });
      }
    }

    if (data.module && data.section) {
      const elem = document.getElementById(data.section);
      if (elem) {
        const tocItemPos = elem.offsetTop - setupWrapElem.offsetTop;
        setupWrapElem.scrollTo({ top: tocItemPos, behavior: 'auto' });
      }
    }
  }, [setActiveModule, setActiveSection]);

  return {
    onScrollHandler,
    scrollToSection,
  };
};
