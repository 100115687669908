import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { useCardReportStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useCardReport.store';
import { useCardReportFilterStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useCardReportFilter.store';
import { generateGroupCardsReport } from '../hooks/useGenerateGroupCardsReport';
import { GroupCardReportResponse, Report, ReportTypes } from '../report.types';

const GET_CARD_REPORT_QUERY_KEY = 'getCardReportQueryKey';
const PAGE_SIZE = 30;

export const useGetCardReportQuery = () => {
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();
  const { currentType: type } = useGetCurrentOrganizationType();
  const {
    groupBy: groupByValues,
    search: searchValues,
  } = useCardReportFilterStore();

  const groups = useCardReportStore((state) => state.groups);
  const setGroups = useCardReportStore((state) => state.setGroups);

  const pages = useCardReportStore((state) => state.pages);
  const setPages = useCardReportStore((state) => state.setPages);

  const reportDates = useCardReportStore((state) => state.reportDates);
  const setReportDates = useCardReportStore((state) => state.setReportDates);

  const totalPages = useCardReportStore((state) => state.totalPages);
  const setTotalPages = useCardReportStore((state) => state.setTotalPages);

  const currentPage = useCardReportStore((state) => state.currentPage);
  const setCurrentPage = useCardReportStore((state) => state.setCurrentPage);

  const isCurrentLoading = useCardReportStore((state) => state.isCurrentLoading);
  const setIsCurrentLoading = useCardReportStore((state) => state.setIsCurrentLoading);

  const handleClear = useCardReportStore((state) => state.handleClear);

  const {
    data, isLoading, refetch, remove, isSuccess,
  } = useQuery(
    [
      `${GET_CARD_REPORT_QUERY_KEY}_${type}`,
      id,
      ...groupByValues,
      searchValues,
      currentPage,
    ],
    type
      ? () => api.get<GroupCardReportResponse>(PATHS.CARD_REPORT(id),
        {
          params: {
            'group-type': groupByValues[1],
            'org-name-filter': searchValues || '',
            size: PAGE_SIZE,
            page: currentPage,
          },
        })
      : () => null,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    handleClear();
    setIsCurrentLoading(true);
    const timeout = setTimeout(() => {
      refetch();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupByValues,
    searchValues,
    type,
    id,
  ]);

  useEffect(() => {
    if (currentPage) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
  ]);

  useEffect(() => {
    if (type) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (data && !pages.includes(data.data?.page_number)) {
      const preparedGroups = generateGroupCardsReport({
        response: data?.data?.groups || [],
        groupByType: groupByValues[1],
      });
      setGroups(preparedGroups);
      setReportDates([
        data?.data?.start_report_date,
        data?.data?.end_report_date,
      ]);
      setPages(data.data?.page_number);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groupByValues]);

  useEffect(() => {
    if (data?.data?.total_pages) {
      setTotalPages(data.data?.total_pages || 0);
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    if (isLoading) {
      setIsCurrentLoading(true);
    }
    const timeout = setTimeout(() => {
      if (!isLoading) {
        setIsCurrentLoading(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const formattedCardReport: Report = useMemo(() => ({
    title: 'Card Status Report',
    type: 'Card',
    reportType: ReportTypes.Card,
    generateReportURL: '',
    id: 'card_report_id',
    startReportDate: reportDates[0],
    endReportDate: reportDates[1],
    currentPage,
    totalPages,
    setCurrentPage,
    setIsCurrentLoading,
    nestedData: {
      groups,
    },
  }),
  [
    currentPage,
    groups,
    setCurrentPage,
    totalPages,
    reportDates,
    setIsCurrentLoading,
  ]);

  return useMemo(() => ({
    refetch,
    remove,
    isLoading: isCurrentLoading && !formattedCardReport?.nestedData?.groups?.length,
    isSubLoading: isCurrentLoading && !!groups.length,
    isSuccess,
    formattedCardReport: formattedCardReport || [],
    isEmpty: !formattedCardReport?.nestedData?.groups?.length,
  }),
  [refetch, remove, isCurrentLoading, groups, isSuccess, formattedCardReport]);
};
