import { Option } from '@/common/types';
import { ContactResponseModel } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurity.types';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { AllowedFileTypes, PersonThemeNamesEnum } from '@/modules/employer/types';
import { ContactEntity } from '@/modules/employer/types/Contact.types';

export interface GeneralInformationPayload {
  dba: string;
  taxID: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  businessType: string;
  broker: string;
  accountManagerId: string;
  implementationManagerId: string;
  hsaCustodianId: string;
  themeId: PersonThemeNamesEnum;
  url: string;
  ssoOnly: boolean;
  allowEditAddress?: string;
}

export interface AddressPayload {
  street: string;
  street_2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface EmployerPayload {
  employerName: string;
  employerId?: string;
  tpa: string;
  distributor?: string;
}

export interface BankInfo {
  id: string;
  type: string;
  description: string;
  contactId?: string;
  accountCategory: string;
  accountType: string;
  availableBalance: number;
  createdAt: string;
  employeeId: number | null;
  leafNode: boolean;
  liveBalance: number;
  modifiedAt: string;
  number: string;
  onDepositBalance: number;
  organizationId: number;
  parentAccountId: number;
  postings: unknown[];
  routingTransitNumber: string;
  statusType: string;
}

export interface SubsidiaryInfo {
  id?: string;
  subsidiaryName: string;
  dba?: string;
  taxID: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  tpa?: string;
  businessType: string;
  broker?: string;
  accountManagerId?: string;
  implementationManagerId?: string;
  payrollGroupCode?: string;
  createDate?: number;
}

export interface EmployerFeatures {
  selectPlans?: string[];
  options: Option[];
}
export interface EmployerInformation {
  status?: EmployerStatus;
  generalInfo: GeneralInformationPayload | null;
  address: AddressPayload | null;
  employer: EmployerPayload | null;
  primaryContactId: string | number | null;
  cardsSetup: CardSetupPayload | null;
  employeeId: EmployeeIdPayload | null;
  helpInfo: HelpInfoPayload | null;
  contactList?: ContactResponseModel[];
  banksList?: Partial<BankInfo>[];
  subsidiaryList?: SubsidiaryInfo[];
  features: Partial<EmployerFeatures> | null;
  fundingModel: FundingModelPayload;
}
export interface PartnerInformation {
  status?: EmployerStatus;
  generalInfo: GeneralInformationPayload | null;
  address: AddressPayload | null;
  employer: EmployerPayload | null;
  primaryContactId: string | number | null;
  cardsSetup: CardSetupPayload | [];
  employeeId: EmployeeIdPayload | null;
  contactList?: ContactEntity[];
  banksList?: Partial<BankInfo>[];
  subsidiaryList?: SubsidiaryInfo[];
  features: Partial<EmployerFeatures> | null;
}

export interface CardSetupPayload {
  cardOffered?: boolean;
  allowDependentCard?: boolean;
  types?: string[] | string;
  numberOfDaysBeforePlanStart?: string;
  cardPhoneNumber?: string;
  cardURL?: string;
  cardEmployerName?: string;
  bin?: string;
  prin?: string;
  fourthLine?: string;
  cardProcessingStartDate?: string | null;
}

export enum BooleanRadioButton {
  yes = 'yes',
  no = 'no',
}
export interface EmployeeIdPayload {
  eeid?: string;
}

export interface HelpInfoPayload {
  customerServiceNumber?: string;
  customerServiceUrlName?: string;
  customerServiceUrlLink?: string;
  customerServiceEmail?: string;
  customerServiceIsEnabled?: boolean;
}

export interface FundingModelPayload {
  checkWriting?: string;
  electronicFundingReplenishment?: string;
}

export interface TechnicalSettings {
  sessionTimeoutLogout?: number;
  sessionTimeoutWarn?: number;
}

export interface ClaimsSettings {
  claimsAssignmentNumber?: number;
}

export interface FileManagerSettings {
  isFileManagerAccessEnabled?: boolean;
  isFileAutoPostEnabled?: boolean | string;
  allowedFileTypes?: AllowedFileTypes[];
  isSendFiles?: boolean;
  fileLocation?: string;
  isSameErrorReportLocation?: boolean;
  errorReportLocation?: string;
}

export interface AIAdjudication {
  aiAdjudication?: boolean;
  aiConfidenceLevel?: number;
}
export interface OrphanConfigurationPayload {
  isEmployerOrphan?: boolean | string;
  startOrphanProcessDays?: string | null;
  orphanEmployer?: string;
}
export interface ExpressSetupLinksPayload {
  isExternalLink?: boolean | string;
  externalLink?: string;
}

export enum AuthenticationTypeId {
  NO_MFA = 'NO_MFA',
  DURING_LOGIN = 'DURING_LOGIN',
}

export interface MultiFactorAuthenticationValues {
  authenticationTypeId?: AuthenticationTypeId;
  accountLockoutTime?: number;
}
