import React from 'react';
import { Box } from '@common-fe/common-fe';

import { LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { UserLetterIcon } from '@/components/elements/LetterIcon/UserLetterIcon';

import { InfoTileIconWrapper } from '../styles/ExpressSetupBanner.styles';

interface Props {
  title?: string;
  isActivateMode?: boolean;
}

const ContactInfoIcon: React.FC<Props> = ({ title, isActivateMode }) => (
  <InfoTileIconWrapper round="50%" hasBorder width={{ min: '40px' }} style={{ cursor: isActivateMode ? 'default' : 'pointer' }}>
    <Box title={title}>
      <UserLetterIcon
        testId='contact-info-icon'
        type={OrganizationTypes.partner}
        size={LetterIconSizeEnum.LOGO}
        text={title}
      />
    </Box>
  </InfoTileIconWrapper>
);

export default ContactInfoIcon;
