import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { generateCIPReport } from '../hooks/useGenerateCIPReport';
import { CIPReportResponse, ReportTypes } from '../report.types';

const GET_CIP_REPORT_QUERY_KEY = 'getCIPReportQueryKey';

export const useGetCIPReportQuery = () => {
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();
  const { currentType: type } = useGetCurrentOrganizationType();
  const {
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    [
      `${GET_CIP_REPORT_QUERY_KEY}_${type}`,
      id,
    ],
    type
      ? () => api.get<CIPReportResponse>(PATHS.CIP_REPORT(id))
      : () => null,
    {
      enabled: false,
      retry: 0,
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    type,
    id,
  ]);

  const formattedCIPReport = useMemo(() => ({
    title: 'CIP Status Report',
    type: 'HSA',
    reportType: ReportTypes.CIP,
    generateReportURL: '',
    id: 'cip_id',
    startReportDate: data?.data.start_report_date,
    endReportDate: data?.data.end_report_date,
    data: generateCIPReport(data?.data),
  }),
  [data]);

  return {
    data,
    formattedCIPReport,
    refetch,
    isLoading,
    isSuccess,
    isEmpty: isLoading || !data?.data,
  };
};
