import React from 'react';
import { Box, capitalizeFirstLetter, Text } from '@common-fe/common-fe';

import { PaymentMethod, PaymentMethodType } from '@/modules/employee/employee.types';
import spacings from '@/styles/spacings';
import { getFullAddress } from '@/utils';
import whiteLabelingConfig, { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import PaymentFreeTimeLabel from '../PaymentFreeTimeLabel';

const isRmrTheme = THEMES.rmr === whiteLabelingConfig.title;

interface Props {
  paymentMethod?: Partial<PaymentMethod>;
  simplifyDesc?: boolean;
  titleFontSize?: string;
  isVerifiedDateVisible?: boolean;
  hasPaymentFreeTimeLabel?: boolean;
}

const PaymentMethodDescription: React.FC<Props> = ({
  hasPaymentFreeTimeLabel = true,
  paymentMethod,
  simplifyDesc,
  titleFontSize,
  isVerifiedDateVisible = true,
}) => {
  const { type, accountNumberLast4, accountDescription, isVerified } = paymentMethod || {};
  const currentTitleFontSize = titleFontSize || spacings.m;

  if (!paymentMethod) {
    return (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        <Text size={currentTitleFontSize} color="textTitle">
          No payment method added
        </Text>
      </Box>
    );
  }

  switch (type) {
  case PaymentMethodType.PAYPAL:
    return (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        {paymentMethod.requisites?.email && (
          <Text size={currentTitleFontSize} color="textTitle" wordBreak="break-word">
            {paymentMethod.requisites?.email}
          </Text>
        )}
        {paymentMethod.requisites?.phoneNumber && (
          <Text
            size={paymentMethod.requisites?.email ? spacings.s : spacings.m}
            color={paymentMethod.requisites?.email ? 'textSecondary' : 'textTitle'}
            weight={500}
          >
            {paymentMethod.requisites?.phoneNumber}
          </Text>
        )}
        {isRmrTheme && hasPaymentFreeTimeLabel ? (
          <Box margin={{ top: 'spacing2' }} width="fit-content">
            <PaymentFreeTimeLabel />
          </Box>
        ) : null}
      </Box>
    );
  case PaymentMethodType.VENMO:
    return paymentMethod.requisites?.phoneNumber && (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        <Text size={currentTitleFontSize} color="textTitle">
          {paymentMethod.requisites?.phoneNumber}
        </Text>
        {isRmrTheme && hasPaymentFreeTimeLabel ? (
          <Box margin={{ top: 'spacing2' }} width="fit-content">
            <PaymentFreeTimeLabel />
          </Box>
        ) : null}
      </Box>
    );
  case PaymentMethodType.DEBIT:
    return (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        <Text size={currentTitleFontSize} color="textTitle">
          {capitalizeFirstLetter(paymentMethod.requisites?.cardType || '')} (•••• {paymentMethod.requisites?.cardLast4})
        </Text>
        {isRmrTheme && hasPaymentFreeTimeLabel ? (
          <Box margin={{ top: 'spacing2' }} width="fit-content">
            <PaymentFreeTimeLabel />
          </Box>
        ) : null}
      </Box>
    );
  case PaymentMethodType.DIRECT_DEPOSIT:
    return (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        <Text size={currentTitleFontSize} color="textTitle">
          {`${accountDescription} ${simplifyDesc ? '(ends ****)' : `(Bank a/c number ends ${accountNumberLast4 || '****'})`}`}
        </Text>
        {isVerified && isVerifiedDateVisible ? (
          <Box direction="row" gap={spacings.xxxs}>
            <Text size={spacings.s} color="textSecondary" weight={500}>
              Verified on
            </Text>
            <Text size={spacings.s} color="success" weight={500}>
              06/28/21
            </Text>
          </Box>
        ) : null}
      </Box>
    );
  case PaymentMethodType.CHECK:
    return (
      <Box data-testid={`PaymentMethodLabel_content_${type}`}>
        <Text size={currentTitleFontSize} color="textTitle">
          {getFullAddress(paymentMethod?.address)}
        </Text>
      </Box>
    );

  default:
    return null;
  }
};

export default PaymentMethodDescription;
