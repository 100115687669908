import { useMemo } from 'react';

import { useGetContributionInvoiceReport } from '@/modules/employer/components/Reports/queries/useGetContributionInvoiceReport.query';
import { useGetReplenishmentInvoiceReport } from '@/modules/employer/components/Reports/queries/useGetReplanishmentInvoiceReport.query';

const useInvoiceReports = () => {
  const {
    formattedReplenishmentInvoiceReport,
    isLoading,
    isSubLoading,
    isEmpty,
  } = useGetReplenishmentInvoiceReport();
  const {
    formattedContributionInvoiceReport,
    isLoading: invoiceContributionReportLoading,
    isSubLoading: isSubContributionInvoiceReportLoading,
    isEmpty: isEmptyContributionInvoiceReport,
  } = useGetContributionInvoiceReport();

  const contributionInvoiceReport = useMemo(() => ({
    ...formattedContributionInvoiceReport,
    isWithFilter: true,
    showHideButtonCenter: true,
    isLoading: invoiceContributionReportLoading,
    isSubLoading: isSubContributionInvoiceReportLoading,
    isEmpty: isEmptyContributionInvoiceReport,
  }),
  [
    formattedContributionInvoiceReport,
    invoiceContributionReportLoading,
    isSubContributionInvoiceReportLoading,
    isEmptyContributionInvoiceReport,
  ]);

  const reports = useMemo(() => ([
    {
      ...formattedReplenishmentInvoiceReport,
      isWithFilter: true,
      showHideButtonCenter: true,
      isLoading,
      isSubLoading,
      isEmpty,
    },
    contributionInvoiceReport,
  ]),
  [
    formattedReplenishmentInvoiceReport,
    isLoading,
    isSubLoading,
    isEmpty,
    contributionInvoiceReport,
  ]);

  return reports;
};

export default useInvoiceReports;
