import { storeCreator } from '@/utils/store/store.utils';

export interface PlanCode {
  id: string;
  planCode: string;
}

interface State {
  plans?: PlanCode[];
  planCodes?: string[];
  subsidiaries?: string[];
  employees?: string[];
  payrolls?: string[];
  handleSetPlans: (value: PlanCode[]) => void;
  handleSetPlanCodes: (value: string[]) => void;
  handleSetSubsidiaries: (value: string[]) => void;
  handleSetPayrolls: (value: string[]) => void;
  handleSetEmployees: (value: string[]) => void;
  handleReset: () => void;
}

export const useAllValuesForManualContributionsStore = storeCreator<State>((set) => ({
  plans: undefined,
  planCodes: undefined,
  subsidiaries: undefined,
  payrolls: undefined,
  employees: undefined,

  handleSetPlans: (value) => set((state) => ({
    ...state,
    plans: value,
  })),

  handleSetPlanCodes: (value) => set((state) => ({
    ...state,
    planCodes: value,
  })),

  handleSetSubsidiaries: (value) => set((state) => ({
    ...state,
    subsidiaries: value,
  })),

  handleSetPayrolls: (value) => set((state) => ({
    ...state,
    payrolls: value,
  })),

  handleSetEmployees: (value) => set((state) => ({
    ...state,
    employees: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    plans: undefined,
    planCodes: undefined,
    subsidiaries: undefined,
    employees: undefined,
    payrolls: undefined,
  })),
}));
