import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ExpressSetupWizardStepTitle,ExpressSetupWizardStepType } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import ExpressSetupWizard from '@/modules/ExpressSetup/ExpressSetupWizard';

const ExpressSetupWizardPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { hash } = useHistory();

  const title = useMemo(() => {
    switch(hash) {
    case ExpressSetupWizardStepType.MainContact:
      return ExpressSetupWizardStepTitle.main_contact;
    case ExpressSetupWizardStepType.BankAccount:
      return ExpressSetupWizardStepTitle.bank_account;
    case ExpressSetupWizardStepType.Plans:
      return ExpressSetupWizardStepTitle.plans;
    default:
      return ExpressSetupWizardStepTitle.main_contact;
    }
  }, [hash]);

  useBreadcrumbs([
    {
      title: 'Home',
      redirect: () => history.push(ROUTES.EXPRESS_SETUP_BY_ID(id)),
    },
    {
      route: ROUTES.EXPRESS_SETUPS_WIZARD_BY_ID(id),
      title: title,
    },
  ], [title], undefined, true);

  return (
    <ExpressSetupWizard />
  );
};

export default ExpressSetupWizardPage;
