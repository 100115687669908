import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { OptionKey, Pagination, PaginationParams } from '@/common/types';
import { LockboxListItem, LockboxStatus, LockboxType } from '@/modules/employer/components/Lockbox/lockbox.types';
import { joinArrayWithCommas } from '@/utils';

interface LockboxPayload {
  id?: number,
  employee_name?: string,
  amount?: number,
  account_number?: number,
  request_date?: string,
  lockbox_type?: LockboxType,
  lockbox_status?: LockboxStatus,
}

interface LockboxesQueryParams extends Partial<PaginationParams> {
  types?: OptionKey[];
  statuses?: OptionKey[];
}

interface LockboxesQueryProps extends LockboxesQueryParams {
  onSuccess?: (data?: LockboxListItem[]) => void;
  enabled: boolean;
}

const DEFAULT_TYPES = [
  LockboxType.INBOUND_TOA,
  LockboxType.OUTBOUND_TOA,
  LockboxType.RETURNED_CHECK,
  LockboxType.UNKNOWN,
];

const DEFAULT_STATUSES = [
  LockboxStatus.NEW,
  LockboxStatus.PENDING,
  LockboxStatus.DECLINED,
  LockboxStatus.APPROVED,
  LockboxStatus.PROCESSING,
  LockboxStatus.COMPLETED,
];

const useLockboxesQuery = ({
  page, perPage, searchString, types, statuses, enabled, onSuccess
}: LockboxesQueryProps) => {
  const params = {
    page: page && page >= 0 ? page : 0,
    size: perPage || 50,
    types: types?.length ? joinArrayWithCommas(types) : joinArrayWithCommas(DEFAULT_TYPES),
    statuses: statuses?.length ? joinArrayWithCommas(statuses) : joinArrayWithCommas(DEFAULT_STATUSES),
    ...searchString ? { search_value: searchString } : {},
  };

  const {
    data, isLoading, isError
  } = useQuery([
    PATHS.LOCKBOXES,
    page,
    perPage,
    searchString,
    types,
    statuses,
  ],
  () => api.get<Pagination<LockboxPayload>>(PATHS.LOCKBOXES, { params }),
  {
    enabled: enabled,
  }
  );

  const formattedData = useMemo((): LockboxListItem[] => {
    const lockboxes = data?.data?.content || [];

    return lockboxes?.map((lockbox) => ({
      id: toString(lockbox?.id),
      accountNumber: lockbox?.account_number,
      amount: lockbox?.amount,
      employeeName: lockbox?.employee_name,
      requestDate: lockbox?.request_date,
      lockboxStatus: lockbox?.lockbox_status,
      lockboxType: lockbox?.lockbox_type,
    }));
  }, [data]);
  
  useEffect(() => {
    if (onSuccess && enabled && formattedData?.length) {
      onSuccess(formattedData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData]);

  return {
    total: data?.data?.totalElements || 0,
    formattedData,
    isLoading,
    isError,
  };
};

export default useLockboxesQuery;
