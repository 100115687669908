import { useEffect,useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';

import { useGetExpressSetupByIdQuery } from '../queries';
import { getFormattedExpressSetupData } from '../utils';

import useExpressSetupSetter from './useExpressSetupSetter';

const useCurrentExpressSetup = (serviceCategories?: ServiceCategoryTypes[] ) => {
  const { handleSetState, handleSetSourceState } = useExpressSetupSetter();

  const { params: { id } } = useRouteMatch<{ id: string }>();
  const {
    data, isSuccess, refetch, isLoading,
  } = useGetExpressSetupByIdQuery(id);

  const expressSetup = useMemo(() => getFormattedExpressSetupData(data, serviceCategories), [data, serviceCategories]);

  useEffect(() => {
    if (expressSetup) {
      handleSetSourceState(expressSetup);
      handleSetState(expressSetup);
    }
  }, [isLoading, expressSetup, handleSetSourceState, handleSetState]);

  return {
    data: expressSetup,
    isSuccess,
    isLoading,
    refetch,
  };
};

export default useCurrentExpressSetup;
