import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { toNumber } from 'lodash';

import { api } from '@/api';
import { RAW_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { useGetEnrollments } from '@/modules/employee/Employee/hooks/useGetEnrollment';
import { QUERY_KEY as POLICIES_STATUS_QUERY_KEY } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';
import { GET_ORGANIZATION_LOCKS_KEY } from '@/modules/employer/queries/useGetOrgLocks.query';

import { AddEnrollmentForm, LimitedUses } from '../enrollmentForms.types';

export interface NewEnrollmentPayload {
  employee_id: number;
  plan_id: number;
  enrollment_status: string;
  effective_date: string;
  election_amount: number;
  deductible_met?: boolean;
  external_deductible_met_date?: string | null;
}

const POST_ENROLLMENT = 'createEnrollment';

export const useCreateEnrollment = () => {
  const queryClient = useQueryClient();
  const { params: { id: employeeId, enrollmentId: accountId } } = useRouteMatch<{
    id: string,
    enrollmentId: string,
  }>();
  const { refetch } = useGetEnrollments({
    employeeId,
    accountId,
  });
  const { isLoading, mutateAsync } = useMutation(
    POST_ENROLLMENT,
    (newEnrollment: NewEnrollmentPayload) => api
      .post(PATHS.CREATE_ENROLLMENT, newEnrollment),
  );

  const preparedNewEnrollment = useCallback((enrollmentData: AddEnrollmentForm) => {
    return ({
      employee_id: Number(employeeId),
      plan_id: Number(enrollmentData.plan),
      enrollment_status: enrollmentData.enrollment_status,
      effective_date: dayjs(enrollmentData.enrollment_date)
        .format(RAW_DATE_FORMAT),
      deductible_met_date: enrollmentData.limited_use === LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET
        ? dayjs(enrollmentData.deductibleMetDate).format(RAW_DATE_FORMAT)
        : null,
      election_amount: Number(enrollmentData.election_amount),
      ...enrollmentData?.per_pay_employee ? { per_pay_employee: toNumber(enrollmentData?.per_pay_employee) } : {},
      ...enrollmentData?.per_pay_employer ? { per_pay_employer: toNumber(enrollmentData?.per_pay_employer) } : {},
      ...enrollmentData?.limited_use && enrollmentData.limited_use === LimitedUses.LIMITED_USE ? { limited_use: true } : {},
      ...enrollmentData?.limited_use && enrollmentData.limited_use === LimitedUses.GENERALE_PURPOSE ? { limited_use: false } : {},
      ...enrollmentData?.limited_use && enrollmentData.limited_use === LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET ? { external_deductible_met: true } : {},
    });
  }, [employeeId]);

  const handleSave = async (employeeData: AddEnrollmentForm) => {
    const preparedDataPayload = preparedNewEnrollment(employeeData);
    await mutateAsync(preparedDataPayload);
    setTimeout(() => {
      queryClient.invalidateQueries(POLICIES_STATUS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ORGANIZATION_LOCKS_KEY);
      refetch();
    }, 3000);
  };

  return {
    onSave: handleSave,
    isLoading,
  };
};
