import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import {
  Option,
} from '@/common/types';
import {
  Plan,
} from '@/modules/HealthPlan/HealthPlan.types';

// import { useIsOrganizationType } from '@/modules/user/hooks';

export const getPlansList = (
  id?: string,
) => () => api.get(PATHS.PLANS_BY_PLAN_YEAR(id));

export const PLANS_BY_PLAN_YEAR_QUERY_KEY = 'getHealthTemplatesList';

type ExpandedOption = Option & {
  id: string;
  name: string;
};

interface Params {
  id?: string;
}
export const usePlansByPlanYearQuery = (
  params: Params,
) => {
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [PLANS_BY_PLAN_YEAR_QUERY_KEY, params.id],
    getPlansList(params?.id),
    {
      enabled: !!params.id,
    },
  );

  const formatedPlans: Plan[] = useMemo(
    () => {
      const list = data?.data || [];

      return list.map((item: Plan) => ({
        id: `${item.id}`,
        name: item.name,
      }));
    }, [data],
  );

  const formatedOptions = useMemo<ExpandedOption[]>(() => {
    const list = data?.data || [];

    return list.map((item: Plan) => ({
      key: `${item.id}`,
      value: item.name,
      title: item.name,
    }));
  }, [data]);

  return {
    data: formatedPlans,
    error,
    isError,
    isLoading,
    isSuccess,
    formatedOptions,
    refetch,
  };
};
export default usePlansByPlanYearQuery;
