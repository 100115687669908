import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.module};
`;

interface WrapperProps {
  imageSrc?: string;
}

export const BackgroundWrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.module};
  background-image: url("${({ imageSrc}) => imageSrc}");
`;

interface FormWrapperProps {
  flat?: boolean;
  isBackButton?: boolean;
}
export const FormWrapper = styled.div<FormWrapperProps>`
  display: flex;
  width: auto;
  max-width: ${({ isBackButton }) => isBackButton ? '548' : '566'}px;
  width: ${({ isBackButton }) => isBackButton ? '548' : '566'}px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.canvas};
  border-radius:  ${({ theme }) => theme.rounds.moduleRound};
  ${({ theme }) => ({

    ...theme.shadows.default,
  })};
  padding: ${({ theme }) => `${theme.spacings.spacing48} ${theme.spacings.spacing72}`} ;
  position: relative;

  ${({ flat, theme }) => {
    if (flat) {
      return {
        padding: theme.spacings.spacing24,
      };
    }
    return {};
  }}

`;

export const BackButtonWrapper = styled(Box)`
  position: absolute;
  left: -64px;
`;
