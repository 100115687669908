import { useMemo } from 'react';

import { useAccountFundingFieldsSourceState } from '../AccountFunding/hooks';
import { useCoverageFieldsSourceState } from '../Coverage/hooks';
import { usePlanDefinitionFieldsSourceState } from '../PlanDefinition/hooks';

export default () => {
  const planDefinitionState = usePlanDefinitionFieldsSourceState();
  const coverageState = useCoverageFieldsSourceState();
  const accountFundingState = useAccountFundingFieldsSourceState();
  const state = useMemo(() => ({
    ...coverageState,
    ...planDefinitionState,
    ...accountFundingState,
  }), [accountFundingState, coverageState, planDefinitionState]);
  return state;
};
