import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Preloader } from '@common-fe/common-fe';

import { useSnackbar } from '@/modules/core/hooks';
import { CardActivationModalType } from '@/modules/transaction/Cards/Cards.types';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import useCardActivationModalStore from '@/modules/transaction/Cards/store/useCardActivationModal.store';

import { useCardActivationQuery } from '../queries/useCardActivation.query';

import { useRequestedActivationCards } from './useRequestedActivationCards';

export const useCardActivationModal = () => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { refetch } = useGetCardsQuery(employeeId);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const [modalType, setModalType] = useState<CardActivationModalType>(
    CardActivationModalType.Activation,
  );
  const {
    modalVisible,
    last4Numbers,
    alternateAccountId,
    memberSequenceIdentifier,
    handleChangeVisibility,
    cardID,
  } = useCardActivationModalStore();

  const { setRequestedActivationCards } = useRequestedActivationCards();

  const { handleActivate, isLoading } = useCardActivationQuery({
    memberSequenceIdentifier,
    alternateAccountId,
  });

  const handleSubmitActivation = useCallback(async () => {
    try {
      await handleActivate();
    } catch {
      setModalType(CardActivationModalType.Error);
      return;
    }

    if (!isLoading) {
      setRequestedActivationCards(cardID);
      setModalType(CardActivationModalType.Processing);
    }
  }, [
    cardID,
    isLoading,
    setRequestedActivationCards,
    handleActivate,
  ]);

  const handleSubmitClose = useCallback(() => {
    refetch();
    handleChangeVisibility(false);
    setModalType(CardActivationModalType.Activation);
    if (modalType === CardActivationModalType.Processing) {
      handleAddPermanentSnackbar({
        text: `Card ${last4Numbers} is activated.`,
        closeIcon: true,
      });
    }
  }, [handleAddPermanentSnackbar, handleChangeVisibility, last4Numbers, modalType, refetch]);

  const handleSubmitTryAgain = useCallback(() => {
    handleSubmitActivation();
  }, [handleSubmitActivation]);

  const currentModalHeader = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return `Activate the Card Ending In ${last4Numbers}?`;
    }
    if (modalType === CardActivationModalType.Processing) {
      return 'This Card Has Been Activated!';
    }
    if (modalType === CardActivationModalType.Error) {
      return 'We Encountered an Unexpected Error and Couldn’t Activate the Card';
    }
    return '';
  }, [last4Numbers, modalType]);

  const currentSubmitButtonText = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return isLoading ? (<Preloader />) : 'Yes, Activate';
    }
    if (modalType === CardActivationModalType.Processing) {
      return 'Close';
    }
    if (modalType === CardActivationModalType.Error) {
      return isLoading ? (<Preloader />) : 'Try Again';
    }
    return '';
  }, [modalType, isLoading]);

  const currentSubmitButtonHandler = useMemo(() => {
    if (modalType === CardActivationModalType.Activation) {
      return handleSubmitActivation;
    }
    if (modalType === CardActivationModalType.Processing) {
      return handleSubmitClose;
    }
    if (modalType === CardActivationModalType.Error) {
      return handleSubmitTryAgain;
    }
    return () => null;
  }, [handleSubmitActivation, handleSubmitClose, handleSubmitTryAgain, modalType]);

  return {
    modalVisible,
    modalType,
    currentModalHeader,
    currentSubmitButtonText,
    currentSubmitButtonHandler,
    handleSubmitClose,
  };
};
