import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { useGetPayrollGroupsQuery } from '@/modules/employer/components/SetupEmployer/Features/PayrollGroups/queries/useGetPayrollGroups.query';
import { getIdsFromPath } from '@/utils';

import { useEmployerInfoStore } from '../stores';

interface Params {
  isDisabled?: boolean;
}

export const usePayrollGroupCodeField = ({ isDisabled }: Params) => {
  const defaultFields = useEmployerInfoStore((state) => state.sourceState);

  const { employerId, subsidiaryId } = getIdsFromPath(defaultFields?.organizationPath);

  const { data } = useGetPayrollGroupsQuery(subsidiaryId || employerId);

  const payrollGroupCodeOptions = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        key: item?.code,
        value: item?.code,
        title: item?.code,
      }));
    }
    return [];
  }, [data]);

  const payrollGroupField = useMemo(() => {
    const field = defaultFields?.payrollGroupCode || payrollGroupCodeOptions.length ? {
      name: 'payrollGroupCode',
      showRequireIcon: true,
      type: FieldTypes.Dropdown,
      label: 'Payroll Group Code',
      options: payrollGroupCodeOptions,
      placeholder: 'Select Payroll Group Code',
      singleMode: true,
      disabled: isDisabled,
      value: defaultFields?.payrollGroupCode,
      validator: VALIDATORS.REQUIRED_STRING,
    } as Field : undefined;

    return field;
  }, [defaultFields?.payrollGroupCode, isDisabled, payrollGroupCodeOptions]);

  return {
    payrollGroupField,
  };
};
