import { useMemo } from 'react';

import { useAuthStore } from '@/modules/user/stores';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  observingEmployerId: string | null;
  observingPartnerId: string | null;
  observingDistributorId: string | null;
  observingSubsidiaryId: string | null;
  handleSetObservingEmployerId: (id: string | null) => void;
  handleSetObservingPartnerId: (id: string | null) => void;
  handleSetObservingDistributorId: (id: string | null) => void;
  handleSetObservingSubsidiaryId: (id: string | null) => void;
}

const useStore = storeCreator<State>((set) => ({
  observingEmployerId: null,
  observingPartnerId: null,
  observingDistributorId: null,
  observingSubsidiaryId: null,
  handleSetObservingPartnerId: (observingPartnerId) => set(() => ({
    observingPartnerId,
  })),
  handleSetObservingDistributorId: (observingDistributorId) => set(() => ({
    observingDistributorId,
  })),
  handleSetObservingEmployerId: (observingEmployerId) => set(() => ({
    observingEmployerId,
  })),
  handleSetObservingSubsidiaryId: (observingSubsidiaryId) => set(() => ({
    observingSubsidiaryId,
  })),
}));

export default () => {
  const store = useStore();
  const { user } = useAuthStore();
  const defaultOrganizationId = useMemo(() => (user ? user?.organizationId : null),
    [user]);
  const observingOrganizationId = useMemo(() => store.observingSubsidiaryId
  || store.observingEmployerId
  || store.observingDistributorId
  || store.observingPartnerId
  || defaultOrganizationId,
  [
    defaultOrganizationId,
    store.observingSubsidiaryId,
    store.observingEmployerId,
    store.observingDistributorId,
    store.observingPartnerId,
  ]);

  return useMemo(() => ({
    ...store,
    organizationId: observingOrganizationId || defaultOrganizationId,
    observingOrganizationId,
    defaultOrganizationId,
    observingMode: !!store.observingEmployerId || !!store.observingPartnerId
      || !!store.observingDistributorId || !!store.observingSubsidiaryId,
  }
  ), [
    store,
    observingOrganizationId,
    defaultOrganizationId,
  ]);
};
