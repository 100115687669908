import { useEffect, useMemo, useState } from 'react';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';

export const useGetCurrentOrganizationType = () => {
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();

  const [currentType, setCurrentType] = useState<OrganizationTypes | null>(null);
  const memoType = useMemo(() => type, [type]);
  useEffect(() => {
    switch (memoType) {
    case OrganizationTypes.company:
      return setCurrentType(OrganizationTypes.employer);
    case OrganizationTypes.partner:
      return setCurrentType(OrganizationTypes.partner);
    case OrganizationTypes.distributor:
      return setCurrentType(OrganizationTypes.distributor);
    case OrganizationTypes.system:
      return setCurrentType(OrganizationTypes.system);
    case OrganizationTypes.subsidiary:
      return setCurrentType(OrganizationTypes.subsidiary);

    default:
      return setCurrentType(null);
    }
  }, [memoType]);

  return {
    currentType,
    setCurrentType,
  };
};
