import React, {
  useCallback, useEffect, useMemo, useRef,
  useState, } from 'react';
import {
  AppButton,
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  DropContent,
  Text,
  useClickOutside,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import theme from '@/styles/theme';

import { PlanStatuses } from '../../plan.types';
import {
  usePreviewStore,
} from '../stores';

interface Props {
  viewMode?: boolean;
  isTemplateMode?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  activateButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  reviewButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  planStatus?: PlanStatuses;
  handleToggleUpdatePlanPopup?: () => void;
}
const PlanTopbarActions: React.FC<Props> = ({
  viewMode,
  isTemplateMode,
  saveButtonRef,
  activateButtonRef,
  planStatus,
  handleToggleUpdatePlanPopup,
}) => {
  const handleSetPreviewMode = usePreviewStore((state) => state.setPreviewMode);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const isPlanActive = useMemo(() => planStatus === PlanStatuses.ACTIVE || planStatus === PlanStatuses.PENDING_ACTIVE, [planStatus]);
  useEffect(() => () => {
    handleSetPreviewMode(false);
  }, [handleSetPreviewMode]);

  const [visiblePopup, setVisiblePopup] = useState(false);

  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const handleToggleVisiblePopup = useCallback(() => {
    setShowActivatePopup(false);
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);
  const handleSaveDraft = useCallback(() => {
    setVisiblePopup(false);
    if (saveButtonRef?.current) {
      saveButtonRef?.current.click();
    }
  }, [saveButtonRef]);

  const handleActive = useCallback(() => {
    handleToggleVisiblePopup();
    if (activateButtonRef?.current) {
      activateButtonRef?.current.click();
    }
  }, [activateButtonRef, handleToggleVisiblePopup]);

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    setVisiblePopup(false);
  });
  const contentWidth = useMemo(() => {
    if (showActivatePopup) {
      return 'medium';
    }
    if (isTemplateMode) {
      return 'regular';
    }
    return '100%';
  }, [isTemplateMode, showActivatePopup]);
  return (
    <Box direction="row">

      <Access
        accessRequirements={[
          {
            permission: Permissions.ACTIVATE_PLAN,
          },
          {
            permission: Permissions.EDIT_DRAFT_PLAN,
          },
        ]}
      >
        {isTemplateMode && (
          previewMode ? (
            <AppButton
              buttonType="secondary"
              onClick={() => handleSetPreviewMode(false)}
            >
              Exit Preview Mode
            </AppButton>
          )

            : (
              <AppButton
                buttonType="secondary"
                onClick={() => handleSetPreviewMode(true)}
                containerStyle={{ marginRight: theme.spacings.spacing12 }}
              >
                Preview Plan
              </AppButton>
            )

        )}
        { !previewMode && !isPlanActive && (
          <Box pad={{ left: 'spacing12' }}>
            <DropContent
              bottomMode
              contentWidth={contentWidth}
              visible={visiblePopup}
              content={
                showActivatePopup ? (
                  <Box direction="column" ref={popupRef}>
                    <Text size="medium">Pay attention, that plan cannot be edited after the activation!</Text>
                    <Text size="medium" weight="bold">
                      Would you like to continue?
                    </Text>
                    <Box direction="row" margin={{ top: 'spacing12' }}>
                      <AppButton
                        buttonType="secondary"
                        onClick={handleToggleVisiblePopup}
                        containerStyle={{ marginRight: theme.spacings.spacing12 }}
                        width="138px"
                      >
                        Cancel
                      </AppButton>
                      <AppButton
                        onClick={handleActive}
                        containerStyle={{ marginRight: theme.spacings.spacing12 }}
                        width="138px"
                      >
                        Continue
                      </AppButton>
                    </Box>
                  </Box>
                )
                  : (
                    <Box direction="column" ref={popupRef}>
                      <Access
                        accessRequirements={[
                          {
                            permission: Permissions.EDIT_DRAFT_PLAN,
                          },
                        ]}
                      >

                        <Button
                          type="button"
                          onClick={handleSaveDraft}
                        >
                          <Text size="medium" weight={400}>
                            Save to Draft
                          </Text>
                        </Button>
                      </Access>

                      <Access
                        accessRequirements={[
                          {
                            permission: Permissions.ACTIVATE_PLAN,
                          },
                          {
                            permission: Permissions.EDIT_DRAFT_PLAN,
                          },
                        ]}
                      >
                        <Box fill background="border1" style={{ height: 1 }} />
                        <Button
                          type="button"
                          onClick={() => setShowActivatePopup(!showActivatePopup)}
                        >
                          <Text size="medium" weight={400}>
                            {isTemplateMode ? 'Activate the Plan Template' : 'Activate the Plan'}

                          </Text>
                        </Button>
                      </Access>
                    </Box>
                  )
              }
            >
              <Box width="170px">
                <AppButton
                  disabled={viewMode}
                  onClick={handleToggleVisiblePopup}
                  containerStyle={{ marginRight: theme.spacings.spacing12 }}
                  rightIcon={visiblePopup ? (
                    <ChevronUpIcon
                      color="iconOnColor"
                    />
                  ) : <ChevronDownIcon color="canvas" />}
                >
                  Save/Activate
                </AppButton>
              </Box>
            </DropContent>
          </Box>
        )}
        {isPlanActive && !isTemplateMode && (
          <AppButton
            onClick={handleToggleUpdatePlanPopup} 
          >
            Save
          </AppButton>
        )}
      </Access>
    </Box>
  );
};

export default PlanTopbarActions;
