import { storeCreator } from '@/utils/store/store.utils';

interface State {
  errors: string[];
  setErrors: (values: string[]) => void;
}

const useStore = storeCreator<State>((set) => ({
  errors: [],
  setErrors: (values: string[]) => set(() => ({
    errors: [...values],
  })),
}));
const useGeneralInfoErrors = () => {
  const store = useStore();

  return {
    store,
    errors: store.errors,
    handleSetErrors: store.setErrors,
  };
};
export default useGeneralInfoErrors;
