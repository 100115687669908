import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { GET_ORGANIZATIONS_KEY } from '@/modules/employer/components/SetupEmployer/queries/useGetOrganizations.query';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { EmployerDtoPayload } from '@/modules/employer/types';
import { getIdsFromPath } from '@/utils';

export interface NewEmployer {
  EmployeeIdField?: string,
  employerName?: string,
  partner?: string | number,
  street?: string,
  street_2?: string,
  city?: string,
  state?: string,
  zipCode?: string,
}

const POST_EMPLOYER = 'createEmployer';
const getDirection = (type: OrganizationTypes, organizationPath: string) => {
  const { partnerId, distributorId, employerId, subsidiaryId } = getIdsFromPath(organizationPath);
  switch (type) {
  case OrganizationTypes.partner: {
    return {
      path: ROUTES.PARTNERS_EDIT(partnerId),
      additionalQueries: { observingPartnerId: partnerId },
    };
  }
  case OrganizationTypes.distributor: {
    return {
      path: ROUTES.DISTRIBUTORS_EDIT(distributorId),
      additionalQueries: {
        observingPartnerId: partnerId,
        observingDistributorId: distributorId,
      },
    };
  }
  case OrganizationTypes.company: {
    return {
      path: ROUTES.EMPLOYERS_EDIT(employerId),
      additionalQueries: {
        observingPartnerId: partnerId,
        observingDistributorId: distributorId,
        observingEmployerId: employerId,
      },
    };
  }
  case OrganizationTypes.subsidiary: {
    return {
      path: ROUTES.SUBSIDIARY_EDIT(subsidiaryId),
      additionalQueries: {
        observingPartnerId: partnerId,
        observingDistributorId: distributorId,
        observingEmployerId: employerId,
        observingSubsidiaryId: subsidiaryId,
      },
    };
  }
  default: {
    return {};
  }
  }
};

const useCreateEmployerQuery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    POST_EMPLOYER,
    (newValue: EmployerDtoPayload) => api.post(PATHS.ORGANIZATIONS, {
      ...newValue,
      overridable_properties: {
        ...newValue?.overridable_properties,
        communication_channels: newValue?.overridable_properties?.communication_channels || null,
        organization_communication_categories: newValue?.overridable_properties?.organization_communication_categories || null,
      },
    }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_ORGANIZATIONS_KEY);
        const { path, additionalQueries } = getDirection(data.data.type, data.data.organization_path);
        history.push(path, additionalQueries);
      },
    },
  );

  const getType = (mode: EmployerSetupModes) => {
    if (mode === EmployerSetupModes.partner) {
      return OrganizationTypes.partner;
    }

    if (mode === EmployerSetupModes.distributor) {
      return OrganizationTypes.distributor;
    }

    if (mode === EmployerSetupModes.subsidiary) {
      return OrganizationTypes.subsidiary;
    }

    return OrganizationTypes.company;
  };

  const handleSave = async ({
    EmployeeIdField,
    employerName,
    partner,
    street,
    street_2,
    city,
    state,
    zipCode,
  }: NewEmployer, mode: EmployerSetupModes) => {
    await mutation.mutateAsync({
      external_identifier: EmployeeIdField,
      name: employerName,
      type: getType(mode),
      parent_id: mode === EmployerSetupModes.partner ? 0 : partner,
      addresses: [
        {
          line1: street,
          ...street_2 ? { line2: street_2 } : {},
          city,
          state,
          zipcode: zipCode,
          preferred: true,
          country_id: 1,
          address_type: 'PO_BOX',
        },
      ],
    });
  };

  return {
    onSave: handleSave,
  };
};

export default useCreateEmployerQuery;
