import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { BankAccountReport } from '@/modules/employer/queries/useReconciliationBankAccounts.query';

interface Params {
  accountsInfo: BankAccountReport[];
  selectedAccount?: string;
  selectedDate?: string;
  minCloseDate?: Date;
}

export const useDownloadReconciliationReportForm = (data?: Params) =>
{
  
  const dropdownOptions = useMemo(( ) => {
    const options =  data?.accountsInfo || []; 
    return options.reduce(
      (allAccounts, nextAccountInfo) => [...allAccounts,
        {
          
          key: nextAccountInfo?.account_description || '',
          value: nextAccountInfo?.account_description || '',
          title: nextAccountInfo?.account_description || '',
        },
      ],
      [{
        key: 'All',
        value: 'All',
        title: 'All',
      }],
    ); 
  }, [data?.accountsInfo]);
  const fields =   useMemo((): Field[] => [
    {
      name: 'selectAccounts',
      type: FieldTypes.CheckboxDropdown,
      label: 'Select accounts',
      placeholder: 'Select accounts',
      allMode: true,
      validator: yup.string().required(REQUIRED_TEXT),
      value: [data?.selectedAccount || 'All'],
      options:dropdownOptions
    },
    {
      name: 'dateForReports',
      type: FieldTypes.Date,
      isManualDateInput: true,
      label: 'Select date for reports',
      placeholder: 'Select date for reports',
      validator: VALIDATORS.DATE_FORMAT_MAX_TODAY.required(REQUIRED_TEXT),
      defaultValue: data?.selectedDate || dayjs().format(DEFAULT_DATE_FORMAT),
      value: '',
      maxDate: dayjs(),
      minDate: data?.minCloseDate && dayjs(data.minCloseDate),
    },
    {
      name: 'selectDownloadFormats',
      type: FieldTypes.CheckBoxGroup,
      label: 'Select download formats',
      defaultValue: ['Excel / CSV'],
      options: [
        {
          key: 'Excel / CSV',
          value: 'Excel / CSV',
        },
        {
          key: 'PDF',
          value: 'PDF',
        },
      ],
    },
  ], [data, dropdownOptions]);
  
  return fields;
};
