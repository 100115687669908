import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CIPStatus,Enrollment, onlyDateFromServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';

interface CoveragePeriods {
  coverage_date_start: string | null;
  coverage_date_end: string | null;
}

interface RawEnrollment {
  per_pay_employer?: number;
  per_pay_employee?: number;
  account_type: string;
  account_id: number;
  enrollment_title: string;
  available_amount: number;
  election_amount: number;
  cip_status: CIPStatus;
  allow_investments: true;
  account_status: string;
  enrollment_status?: string;
  investment_threshold: number;
  investment_option_types: string[];

  minimum_investment_amount: number;
  account_number?: string;
  plan_id?: number;
  policy_id?: number;
  coverage_periods: CoveragePeriods[];
  submit_claims_date_end: string;
  deductible_met: boolean;
  cip_required?: boolean;
  display_name?: string;
  contribution_monitoring_required?: boolean;
}

interface Params {
  employeeId?: string | number | null;
  accountId?: string | number | null;
  status?: string | null;
}

export const useGetEnrollments = (params: Params) => {
  const {
    isLoading, isSuccess, error, data, refetch,
  } = useQuery(
    [`${PATHS.ENROLLMENTS}/${params.employeeId}/${params.accountId}`, params.status],
    () => api.get<RawEnrollment[]>(
      PATHS.ENROLLMENTS,
      {
        params: {
          employee_id: params.employeeId,
          account_id: params.accountId,
          status: params.status,
        },
      },
    ),
  );
  const currentMyAccount = useMemo(() => {
    const list = data?.data || [];
    const foundItem = list.find((item: RawEnrollment) => `${item.account_id}` === `${params.accountId}`);
    if (foundItem) {
      return {
        ...typeof foundItem?.per_pay_employee === 'number' ? {
          perPayEmployee: foundItem.per_pay_employee,
        } : {},
        ...typeof foundItem?.per_pay_employer === 'number' ? {
          perPayEmployer: foundItem.per_pay_employer,
        } : {},
        id: `${foundItem.account_id || ''}`,
        name: foundItem.enrollment_title,
        available: foundItem.available_amount,
        election: foundItem.election_amount,
        CIPStatus: foundItem.cip_status,
        isCIPRequired: foundItem.cip_required,
        enrollmentStatus: foundItem.enrollment_status,
        accountType: foundItem.account_type,
        allowInvestments: foundItem.allow_investments,
        accountStatus: foundItem.account_status,
        investmentThreshold: foundItem.investment_threshold,
        investmentOptionTypes: foundItem.investment_option_types,
        displayName: foundItem.display_name,
        minimumInvestmentAmount: foundItem.minimum_investment_amount,
        accountNumber: foundItem.account_number,
        planId: `${foundItem.plan_id || ''}`,
        policyId: `${foundItem.policy_id || ''}`,
        coveragePeriods: foundItem?.coverage_periods?.map((item) => ({
          startDate: item?.coverage_date_start
            ? dayjs(onlyDateFromServer(item?.coverage_date_start)).format(DEFAULT_DATE_FORMAT)
            : 'No deadline',
          endDate: item?.coverage_date_end
            ? dayjs(onlyDateFromServer(item?.coverage_date_end)).format(DEFAULT_DATE_FORMAT)
            : 'No deadline',
        })),
        coverageDateStart: foundItem?.coverage_periods?.[foundItem?.coverage_periods?.length - 1]?.coverage_date_start
          ? dayjs(onlyDateFromServer(foundItem?.coverage_periods?.[foundItem?.coverage_periods?.length - 1]?.coverage_date_start || ''))
            .format(DEFAULT_DATE_FORMAT) : '',
        coverageDateEnd: foundItem?.coverage_periods?.[0]?.coverage_date_end
          ? dayjs(onlyDateFromServer(foundItem?.coverage_periods?.[0]?.coverage_date_end))
            .format(DEFAULT_DATE_FORMAT) : '',
        submitClaimsDateEnd: foundItem?.submit_claims_date_end
          ? dayjs(onlyDateFromServer(foundItem.submit_claims_date_end)).format(DEFAULT_DATE_FORMAT)
          : '',
        deductibleMet: foundItem?.deductible_met,
        contributionMonitoringRequired: foundItem?.contribution_monitoring_required,
      };
    }
    return null;
  }, [data, params]) as Enrollment;
  const HSA_TYPE_KEY = 'HSA';
  // const currentMyAccount: RawEnrollment | null | undefined = useMemo(
  //   () => {
  //     const list = data?.data || [];
  //     const item = list.find((item) => `${item.account_id}` === id);
  //     if(item) {
  //       // return {
  //       //   id: item.account_id,
  //       //   name: item.enrollment_title,
  //       //   available: item.available_amount,
  //       //   election: item.election_amount,
  //       //   accountType: item.account_type,
  //       // }
  //     }
  //     return null;
  //   }), [data, id],
  // );

  const isHSAExists = useMemo(() => {
    const list = data?.data || [];
    return list.find((item: RawEnrollment) => item.account_type === HSA_TYPE_KEY);
  }, [data]);
  const isCurrentHSA = useMemo(() => {
    if (currentMyAccount) {
      return currentMyAccount.accountType === HSA_TYPE_KEY;
    }
    return false;
  }, [currentMyAccount]);
  
  const hasEnrollmentWithDisplayName = useMemo(() => {
    const list = data?.data || [];
    return list.some((item: RawEnrollment) => item.display_name);
  }, [data]);

  const formatedData = useMemo(() => data?.data?.map((item: RawEnrollment) => ({
    ...typeof item?.per_pay_employer === 'number' ? {
      perPayEmployer: item.per_pay_employer,
    } : {},
    ...typeof item?.per_pay_employee === 'number' ? {
      perPayEmployee: item.per_pay_employee,
    } : {},
    id: `${item.account_id || ''}`,
    name: item.enrollment_title,
    available: item.available_amount,
    election: item.election_amount,
    CIPStatus: item.cip_status,
    isCIPRequired: item.cip_required,
    accountType: item.account_type,
    allowInvestments: item.allow_investments,
    accountStatus: item.account_status,
    enrollmentStatus: item.enrollment_status,
    investmentThreshold: item.investment_threshold,
    investmentOptionTypes: item.investment_option_types,
    displayName: item.display_name,
    accountNumber: item.account_number,
    enrollmentTitle: item.enrollment_title,
    planId: `${item.plan_id || ''}`,
    policyId: `${item.policy_id || ''}`,
    deductibleMet: item.deductible_met,
    coveragePeriods: item?.coverage_periods?.map((item) => ({
      startDate: item?.coverage_date_start
        ? dayjs(onlyDateFromServer(item?.coverage_date_start)).format(DEFAULT_DATE_FORMAT) 
        : 'No deadline',
      endDate: item?.coverage_date_end
        ? dayjs(onlyDateFromServer(item?.coverage_date_end)).format(DEFAULT_DATE_FORMAT)
        : 'No deadline',
    })),
    coverageDateStart: item?.coverage_periods?.[item?.coverage_periods?.length - 1]?.coverage_date_start
      ? dayjs(onlyDateFromServer(item.coverage_periods?.[item?.coverage_periods?.length - 1]?.coverage_date_start || ''))
        .format(DEFAULT_DATE_FORMAT) : '',
    coverageDateEnd: item?.coverage_periods?.[0]?.coverage_date_end
      ? dayjs(onlyDateFromServer(item.coverage_periods?.[0]?.coverage_date_end))
        .format(DEFAULT_DATE_FORMAT) : '',
    submitClaimsDateEnd: item?.submit_claims_date_end
      ? dayjs(onlyDateFromServer(item.submit_claims_date_end)).format(DEFAULT_DATE_FORMAT)
      : '',
  })), [data]) as Enrollment[];

  return {
    data: formatedData || [],
    isHSAExists,
    currentMyAccount,
    error,
    isCurrentHSA,
    hasEnrollmentWithDisplayName,
    isLoading,
    isSuccess,
    refetch,
  };
};
