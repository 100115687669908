import {
  useCallback, useMemo, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { FieldError, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import { useCurrentOrganization } from '@/modules/core/hooks';
import { QUERY_KEY } from '@/modules/ProcessingDefinition/EnrollmentMatrix/queries/useEnrollmentMatrixes.queries';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useEnrollmentMatrixFields } from '../EnrollmentMatrixEntity/hooks';
import { useEnrollmentMatrixEntityStore } from '../EnrollmentMatrixEntity/stores';
import { useCreateEnrollmentMatrixQuery } from '../queries';

interface Params {
  onClose?: () => void;
  perPage?: number;
}

export default ({ onClose, perPage }: Params) => {
  const queryClient = useQueryClient();
  const {
    observingOrganization,
  } = useCurrentOrganization();

  const [enrollmentMatrixCreationError, setEnrollmentMatrixCreationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getValidateFields, getErrors } = useValidationUtils();
  const [submitted, setSubmitted] = useState(false);

  const saveEnrollmentMatrix = useCreateEnrollmentMatrixQuery(
    perPage,
  );

  const enrollmentMatrixFields = useEnrollmentMatrixFields();
  const enrollmentMatrixEntity = useEnrollmentMatrixEntityStore((state) => state.state);
  const handlePlanTemplateShowError = useEnrollmentMatrixEntityStore((state) => state
    .setShowErrors);

  const enrollmentMatrixValidation = useMemo<FieldValues>(
    () => getValidateFields(enrollmentMatrixFields),
    [getValidateFields, enrollmentMatrixFields],
  );
  const enrollmentMatrixErrors: FieldError = useMemo(
    () => getErrors(enrollmentMatrixEntity, enrollmentMatrixValidation),
    [getErrors, enrollmentMatrixValidation, enrollmentMatrixEntity],
  );

  const handlePreSubmit = useCallback(() => {
    setEnrollmentMatrixCreationError(false);
    handlePlanTemplateShowError(true);
    if (_.isEmpty(enrollmentMatrixErrors)) {
      setEnrollmentMatrixCreationError(false);
      return true;
    }

    return false;
  }, [handlePlanTemplateShowError, enrollmentMatrixErrors]);
  const handleSave = useCallback(async () => {
    setSubmitted(true);
    setEnrollmentMatrixCreationError(false);
    setLoading(true);
    handlePlanTemplateShowError(true);
    try {
      if (_.isEmpty(enrollmentMatrixErrors)) {
        await saveEnrollmentMatrix({
          name: enrollmentMatrixEntity.name,
          priority: _.toNumber(enrollmentMatrixEntity.priority),
          valid_from: enrollmentMatrixEntity.validFrom,
          valid_to: enrollmentMatrixEntity.validTo || undefined,
          organization_id: _.toNumber(observingOrganization.id),
          processing_rule_id: _.toNumber(enrollmentMatrixEntity.processingRule),
          plan_id: _.toNumber(enrollmentMatrixEntity.planTemplate),
          plan_year_id: enrollmentMatrixEntity.planYear
            ? _.toNumber(enrollmentMatrixEntity.planYear) : undefined,
        });
        if (onClose) {
          onClose();
        }
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      }
    } catch (e) {
      const message = _.get(e, 'response.data.elevate_error_message', '') as string;
      if (message.includes('Details: EnrollmentMatrix with name')) {
        setEnrollmentMatrixCreationError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [
    handlePlanTemplateShowError,
    enrollmentMatrixErrors,
    saveEnrollmentMatrix,
    enrollmentMatrixEntity,
    observingOrganization,
    onClose,
    queryClient,
  ]);

  return {
    setEnrollmentMatrixCreationError,
    enrollmentMatrixCreationError,
    loading,
    setLoading,
    submitted,
    handleSave,
    handlePreSubmit,
  };
};
