export const SUB_MODULES = {
  SPEND_TRACKER: 'spend_tracker',
};

export const employeeSubModules = {
  [SUB_MODULES.SPEND_TRACKER]: 'Spend Tracker',
};

interface Args {
  exluce?: string[];
}

export const useEmployeeSubModules = (args?: Args) => Object
  .keys(employeeSubModules)
  .reduce((map, anchorId) => {
    if (args?.exluce && args.exluce.includes(anchorId)) {
      return map;
    }
    return ({
      ...map,
      [anchorId]: {
        title: employeeSubModules[anchorId],
      },
    });
  }, {});

export default useEmployeeSubModules;
