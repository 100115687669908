import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AbsenceFilesPlaceholderIcon,
  AppButton,
  Box,
  DownloadIcon,
  FlexList,
  Inscription,
  NoSearchItemsPlaceholder,
  PaginationBar,
  Preloader,
  SearchPlaceholderIcon,
  SelectDropdown,
  WarnModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toNumber } from 'lodash';
import styled from 'styled-components';

import { ALL_OPTION, BOOLEAN_STRINGS, DATE_DOTS_FORMAT } from '@/common/constants';
import routes from '@/common/routes';
import { DefaultSortTypesEnum,ListItemBase,OptionKey } from '@/common/types';
import { SnackbarState } from '@/modules/core/core.types';
import { useCurrentOrganization, useHistory, useSnackbar } from '@/modules/core/hooks';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';
import { useListPagination } from '@/modules/employer/hooks';
import globalTheme from '@/styles/theme';

import { ContributionRequestStatus } from '../Contributions/contributions.types';

import useContributionActivitiesList from './hooks/useContributionActivitiesList';
import useChangeContributionStatusQuery from './queries/useChangeContributionStatus.query';
import useExportContributionActivityReportQuery from './queries/useExportContributionActivityReport.query';
import { CONTRIBUTION_ACTIVITY_STATUS_OPTIONS,ContributionActivityStatus } from './types';

const ButtonsList = styled(Box)`
  > div:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacings.spacing12};
  }
`;

const ACTIVE_PLAN_STATUS = 'ACTIVE';

const HEADERS: ListItemBase[] = [
  {
    key: 'employeeFullName',
    title: 'Name',
    flex: 1.2,
  },
  {
    key: 'employeeContributionAmount',
    title: 'EE amount',
    flex: 0.8,
  },
  {
    key: 'employerContributionAmount',
    title: 'ER amount',
    flex: 0.8,
  },
  {
    key: 'planName',
    title: 'Account',
    flex: 1.2,
  },
  {
    key: 'taxYear',
    title: 'Tax year',
  },
  {
    key: 'taxType',
    title: 'Tax type',
  },
  {
    key: 'contributionDateOn',
    title: 'Contribution date',
  },
  {
    key: 'processingStatus',
    title: 'Status',
    style: {
      marginRight: '20px',
      marginLeft: '-20px',
      flexDirection: 'row-reverse',
    },
    flex: 1.4,
  },
  {
    key: 'memo',
    title: 'Memo',
  },
];

const allValuesToggling = (values: OptionKey[], allLength: number) => {
  const isAllSelected = values[values.length - 1] === ALL_OPTION.value
    || (!values?.some((value) => value === ALL_OPTION.value) && values.length === allLength - 1);

  if (values.length === 0 || isAllSelected) {
    return [ALL_OPTION.value];
  }
  
  const isAllChangedToAnother = values.length === 2 && values[0] === ALL_OPTION.value;

  if (isAllChangedToAnother) {
    return values.filter((val) => val !== ALL_OPTION.value);
  }

  return values;
};

const ContributionActivitiesList = () => {
  const { contributionId, submitDate, approveRejectButtons } = useParams<{ contributionId: string, submitDate: string, approveRejectButtons: string }>();
  const [selectedAccounts, setSelectedAccounts] = useState<OptionKey[]>([ALL_OPTION.value]);
  const {observingOrganization: { id: organizationId, name }} = useCurrentOrganization();
  const { formattedData: plans } = useGetOrganizationPlansQuery(organizationId, ACTIVE_PLAN_STATUS);
  const accountsOptions = useMemo(() => {
    const options = plans?.sort((a, b) => toNumber(a.id) - toNumber(b.id)).map((item) => ({
      key: item.planCode,
      value: item.planCode,
      title: item.planCode,
    }));

    return [ALL_OPTION, ...options];
  }, [plans]);
  const accountsValues = useMemo(() => allValuesToggling(selectedAccounts, accountsOptions.length), [accountsOptions, selectedAccounts]);
  const hasAccountSelected = useMemo(
    () => accountsValues.length > 0
      && !accountsValues.some((val) => val === ALL_OPTION.value),
    [accountsValues],
  );

  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const {
    page,
    perPage,
    setPerPage,
    setPage,
  } = useListPagination();

  const [statuses, setStatuses] = useState<OptionKey[]>([ALL_OPTION.value]);
  const statusValues = useMemo(() => allValuesToggling(statuses, CONTRIBUTION_ACTIVITY_STATUS_OPTIONS.length), [statuses]);
  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((val) => val === ALL_OPTION.value),
    [statusValues],
  );

  const [taxYears, setTaxYears] = useState<OptionKey[]>([ALL_OPTION.value]);
  const taxYearValues = useMemo(() => {
    if (!taxYears.length) return [ALL_OPTION.value];
    if (taxYears[taxYears.length - 1] === ALL_OPTION.value) return [ALL_OPTION.value as OptionKey];
    return taxYears.filter((val) => val !== ALL_OPTION.value);
  }, [taxYears]);
  const hasTaxYear = useMemo(
    () => taxYearValues.length > 0
      && !taxYearValues.some((val) => val === ALL_OPTION.value),
    [taxYearValues],
  );

  const areFiltersUsed = useMemo(() => hasStatus || hasTaxYear || hasAccountSelected, [hasStatus, hasTaxYear, hasAccountSelected]);

  const { rows, totalElements, isLoading, formattedData } = useContributionActivitiesList({
    contributionId,
    page,
    perPage,
    ...hasStatus ? { statuses: statusValues as ContributionActivityStatus[] } : {},
    ...hasTaxYear ? { taxYears: taxYearValues as string[] } : {},
    ...(sortBy ? {
      sortBy: sortBy as DefaultSortTypesEnum,
    } : {}),
    ...hasAccountSelected ? { planCodes: accountsValues as string[] } : {},
  });

  const taxYearOptions = useMemo(() => {
    const uniqueTaxYears = [...new Set(formattedData?.map((item) => item?.taxYear))];

    return [
      ALL_OPTION,
      ...uniqueTaxYears.map((value) => ({ key: value, value, title: value })),
    ];
  }, [formattedData]);

  const [isExporting, setIsExporting] = useState(false);
  const exportReportCSV = useExportContributionActivityReportQuery();
  const exportReport = useCallback(async () => {
    setIsExporting(true);
    const response = await exportReportCSV({
      contributionId,
      ...hasStatus ? { statuses: statusValues as ContributionActivityStatus[] } : {},
      ...hasTaxYear ? { taxYears: taxYearValues as string[] } : {},
      ...hasAccountSelected ? { planCodes: accountsValues as string[] } : {},
    });
    if (response?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}_Contribution_Report_${dayjs(submitDate).format(DATE_DOTS_FORMAT)}.csv`);
      document.body.appendChild(link);
      link.click();
    }
    setIsExporting(false);
  }, [
    submitDate,
    name,
    accountsValues,
    exportReportCSV,
    statusValues,
    taxYearValues,
    setIsExporting,
    hasTaxYear, 
    hasStatus,
    hasAccountSelected,
    contributionId,
  ]);

  const { handleAddPermanentSnackbar } = useSnackbar();
  const [contributionModalAcction, setContributionModalAcction] = useState<boolean | undefined>(undefined);
  const history = useHistory();
  const goToContributionsPage = useCallback(() => history.push(routes.CONTRIBUTIONS), [history]);
  const onSuccess = useCallback(() => {
    goToContributionsPage();
    if (contributionModalAcction === true) {
      handleAddPermanentSnackbar({
        text: 'Contribution was approved',
        state: SnackbarState.positive,
        closeIcon: true,
      });
    }
    if (contributionModalAcction === false) {
      handleAddPermanentSnackbar({
        text: 'Contribution was declined',
        state: SnackbarState.negative,
        closeIcon: true,
      });
    }
  }, [goToContributionsPage, contributionModalAcction, handleAddPermanentSnackbar]);
  const { changeContributionStatus } = useChangeContributionStatusQuery(contributionId, onSuccess);
  const onChangeStatus = useCallback((newStatus: ContributionRequestStatus) => {
    changeContributionStatus(newStatus);
  }, [changeContributionStatus]);

  return (
    <>
      {typeof contributionModalAcction === 'boolean' ? (
        <WarnModal
          testId='contributionModalAcction'
          visible
          header={contributionModalAcction ? 'Approve contributions?' : 'Reject contribution?'}
          onSetVisible={() => setContributionModalAcction(undefined)}
          onCancel={() => setContributionModalAcction(undefined)}
          submitButtonText={contributionModalAcction ? 'Yes, Approve' : 'Yes, Reject'}
          buttonText="Cancel"
          isPendingIcon
          {...contributionModalAcction ? {
            onSubmit: () => onChangeStatus(ContributionRequestStatus.PROCESSING),
          } : {
            submitButtonMode: 'alert',
            onSubmit: () => onChangeStatus(ContributionRequestStatus.REJECTED),
          }}
        />
      ) : null}
      <Box
        margin={{ bottom: 'spacing16', top: 'spacing24' }}
        direction="row"
        justify="between"
        align="center"
        data-testid="Contribution_top-section"
      >
        <Inscription
          size="3xl"
          weight="bold"
          color="textTitle"
          lineHeight="40px"
        >Contribution activity
        </Inscription>
        <ButtonsList direction="row">
          <Box onClick={exportReport} width={{ max: '110px', min: '110px' }}>
            <AppButton
              buttonType="secondary"
              rightIcon={isExporting ? <Preloader size={16} thinSize={2} /> : <DownloadIcon />}
              disabled={isExporting || !totalElements}
              width="110px"
            >
              Export
            </AppButton>
          </Box>
          {approveRejectButtons === BOOLEAN_STRINGS.TRUE ? (
            <>
              <Box onClick={() => setContributionModalAcction(false)} width={{ max: '160px', min: '160px' }}>
                <AppButton
                  buttonType="secondary"
                  width="160px"
                  color="red"
                >
                  Reject
                </AppButton>
              </Box>
              <Box onClick={() => setContributionModalAcction(true)} width={{ max: '160px', min: '160px' }}>
                <AppButton
                  width="160px"
                >
                  Approve
                </AppButton>
              </Box>
            </>
          ) : null}
        </ButtonsList>
      </Box>
      <Box
        flex
        direction="column"
        width={globalTheme.defaultContentWidth}
      >
        <Box
          background={{ color: 'module' }}
          round="moduleRound"
        >
          <Box align="center" pad={{ bottom: 'spacing16' }}>
            <Box
              width="100%"
              direction="row"
              justify="between"
              align="center"
              pad={{ horizontal: 'spacing24', top: 'spacing24' }}
            >
              <Inscription
                weight="bold"
                color="textBody"
                style={{ whiteSpace: 'nowrap' }}
              >
                Activity records: {totalElements || 0}
              </Inscription>
              <Box direction="row" justify="end">
                {plans?.length ? (
                  <Box margin={{ left: 'spacing12' }} width={{ min: '160px', max: '160px' }}>
                    <SelectDropdown
                      id="accounts"
                      testId="accounts"
                      activeTitle
                      ellipsisMode
                      name="Accounts"
                      prefix="Accounts"
                      disabled={!totalElements && !hasAccountSelected}
                      options={accountsOptions}
                      values={accountsValues}
                      onChangeValues={(val: OptionKey[]) => {
                        setSelectedAccounts(val);
                        setPage(1);
                      }}
                    />
                  </Box>
                ) : null}
                <Box margin={{ left: 'spacing12' }} width={{ min: '150px', max: '150px' }}>
                  <SelectDropdown
                    disabled={!totalElements && !hasTaxYear}
                    id="taxYear"
                    allMode
                    activeTitle
                    ellipsisMode
                    name="Tax year"
                    prefix="Tax year"
                    options={taxYearOptions}
                    values={taxYearValues}
                    onChangeValues={(val: OptionKey[]) => {
                      setTaxYears(val);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: '150px', max: '150px' }}>
                  <SelectDropdown
                    disabled={!totalElements && !hasStatus}
                    id="status"
                    allMode
                    activeTitle
                    ellipsisMode
                    name="Activity status"
                    prefix="Status"
                    options={CONTRIBUTION_ACTIVITY_STATUS_OPTIONS}
                    values={statusValues}
                    onChangeValues={(status: OptionKey[]) => {
                      setStatuses(status);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: '165px', max: '165px' }}>
                  <SelectDropdown
                    id="sort_by_firstName"
                    testId="ContributionActivities_sortByFilter"
                    disabled={!totalElements}
                    singleMode
                    ellipsisMode={false}
                    activeTitle
                    name="First name"
                    prefix="First name"
                    options={[
                      {
                        key: DefaultSortTypesEnum.ASC,
                        value: DefaultSortTypesEnum.ASC,
                        title: 'A-Z',
                      },
                      {
                        key: DefaultSortTypesEnum.DESC,
                        value: DefaultSortTypesEnum.DESC,
                        title: 'Z-A',
                      },
                    ]}
                    value={sortBy}
                    onChange={setSortBy}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <FlexList
            headers={HEADERS}
            rows={rows}
            total={totalElements}
            pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
            moreCount={perPage}
            loading={isLoading}
            placeholder={(
              <NoSearchItemsPlaceholder
                placeholderTitle={areFiltersUsed ? 'No results have been found' : 'There are no activity records yet'}
                node={areFiltersUsed ? (
                  <SearchPlaceholderIcon size="xxlarge" color="iconSecondary" />
                ) : (
                  <AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />
                )}
                description={areFiltersUsed ? 'Try to adjust your search or filter to find what you\'re looking for.' : ''}
              />
            )}
            footer={(
              <PaginationBar
                page={page}
                total={totalElements || 0}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default ContributionActivitiesList;
