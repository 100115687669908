import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ListItem } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, MINIMIZE_DATE_FORMAT } from '@/common/constants';
import {
  ListItemType, Pagination, PaginationParams,
} from '@/common/types';
import { formattingReportAccountListItemFields } from '@/modules/employer/queries/useAccountsActivity.query';
import { TransactionType } from '@/modules/transaction/transaction.types';

export interface AccountLedgerResponse {
  elevate_memo: string;
  partner_memo: string;
  account: string;
  sub_account: string;
  other_account: string;
  other_sub_account: string;
  date: string;
  transaction_type: TransactionType;
  our_amount: number,
  bank_amount: number,
  difference: number,
}

export interface AccountCloseDetailsResponse {
  account: string,
  account_number: string,
  type: string,
  close_date: string,
  our_balance: number,
  bank_balance: number,
  difference: number,
  prior: number,
  delta: number,
  credit: number,
  debit: number,
  ledgers: Pagination<AccountLedgerResponse>,
}

const BOLD_STYLE: React.CSSProperties = {
  fontWeight: 700,
};

export const formattingCloseAccountDetailsListItemFields = (
  item?: AccountLedgerResponse,
  onPick?: () => void,
): ListItem => ({
  id: _.uniqueId(),
  onPick,
  fields: [
    {
      key: 'elevate_memo',
      type: ListItemType.Text,
      title: item && item.elevate_memo ? item.elevate_memo : '-',
      style: {
        ...BOLD_STYLE,
      },
      ellipsisMode: true,
      sticky: true,
      width: 30,
    },
    {
      key: 'partner_memo',
      type: ListItemType.Text,
      title: item && item.partner_memo ? item.partner_memo : '-',
      ellipsisMode: true,
      width: 30,
    },
    {
      key: 'account',
      type: ListItemType.Text,
      title: item && item.account ? item.account : '-',
      ellipsisMode: true,
    },
    {
      key: 'subAccount',
      type: ListItemType.Text,
      title: item && item.sub_account ? item.sub_account : '-',
      ellipsisMode: true,
    },
    {
      key: 'otherAccount',
      type: ListItemType.Text,
      title: item && item.other_account ? item.other_account : '-',
      ellipsisMode: true,
    },
    // {
    //   key: 'otherSubaccount',
    //   type: ListItemType.Text,
    //   title: item && item.other_sub_account ? item.other_sub_account : '-',
    //   ellipsisMode: true,
    // },
    {
      key: 'date',
      type: ListItemType.Text,
      title: item && item.date ? dayjs(item.date).format(MINIMIZE_DATE_FORMAT) : '-',
    },
    {
      key: 'transactionType',
      type: ListItemType.Text,
      title: item && item.transaction_type ? item.transaction_type : '-',
      ellipsisMode: true,
    },
    {
      key: 'ourAmount',
      type: ListItemType.Text,
      title: item && item.our_amount ? `${item.our_amount}` : '-',
    },
    {
      key: 'bankAmount',
      type: ListItemType.Text,
      title: item && item.bank_amount ? `${item.bank_amount}` : '-',
    },
    {
      key: 'difference',
      type: ListItemType.Text,
      title: item && item.difference ? `${item.difference}` : '-',
    },
  ],
});

export const getAccountCloseDetails = (
  accountId: string,
  closeDate: string | null,
  params: PaginationParams,
) => api
  .get<AccountCloseDetailsResponse>(
    PATHS.RECONCILIATION_ACCOUNT_CLOSE_DETAILS,
    {
      params: {
        account_id: accountId,
        close_date: closeDate,
        page: params.page,
        size: params.perPage,
      },
    },
  );

export const useActivityTransactionList = (
  id: string,
  params: PaginationParams & {
    closeDate: string | null,
  },
  enabled = true,
) => {
  const formattedCloseDate = useMemo(
    () => (
      params.closeDate
        ? dayjs(params.closeDate).format(DEFAULT_DATE_FORMAT)
        : null
    ),
    [params.closeDate],
  );
  const {
    isLoading, error, data, isSuccess, refetch,
  } = useQuery(
    ['ReconciliationBankAccounts', id, formattedCloseDate, params],
    () => getAccountCloseDetails(
      id,
      formattedCloseDate,
      params,
    ),
    {
      enabled,
    },
  );
  const response = useMemo(() => data?.data, [data]);

  useEffect(() => {
    if (params.closeDate && id) refetch();
  }, [params.closeDate, id, refetch]);
  const formattedList = useMemo(() => (response?.ledgers.content || [])
    .map(
      (item: AccountLedgerResponse) => formattingCloseAccountDetailsListItemFields(item),
    ),
  [response]);
  return {
    formattedList,
    response,
    total: response?.ledgers.total || 0,
    accountInfo: response
      ? [formattingReportAccountListItemFields({
        our_balance: response.our_balance,
        bank_balance: response.bank_balance,
        difference: response.difference,
        prior: response.prior,
        delta: response.delta,
        credit: response.credit,
        debit: response.debit,
        close_date: response.close_date,
      })]
      : [],
    error,
    isLoading,
    isSuccess,
    refetch,
  };
};
