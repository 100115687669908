import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeWrapper as CommonThemeWrapper } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import App, { ThemeWrapper } from './modules/core/components';
import TokenRefreshWrapper from './modules/core/components/TokenRefreshWrapper';
import MaintenanceWrapper from './modules/user/MaintenanceWrapper';
import ScrollToTop from './ScrollToTop';

// import '@/styles/global.scss'
import './styles/global.scss';
import '@common-fe/common-fe/dist/style.css';
dayjs.extend(relativeTime);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
window.global ||= window;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <CommonThemeWrapper>
        <ThemeWrapper>
          <TokenRefreshWrapper>
            <QueryClientProvider client={queryClient}>
              <ScrollToTop />
              <MaintenanceWrapper>
                <App />
              </MaintenanceWrapper>
            </QueryClientProvider>
          </TokenRefreshWrapper>
        </ThemeWrapper>
      </CommonThemeWrapper>
    </BrowserRouter>
  </React.StrictMode>
);
