import { OrganizationTypes } from '@/common/constants';
import { EmployerStatus } from '@/modules/employer/employer.constants';

import { AllowAssumedContribution } from '../components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';

export interface AddressesDto {
  id?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  preferred?: boolean;
  countryId?: string | number;
  addressType?: string;
}

export enum PersonThemeNamesEnum {
  CLARITY = 'CLARITY',
  ELEVATE = 'ELEVATE',
}

export interface CardProductionDate {
  cardProductionDate?: string;
  cardProductionDateState?: string;
}

export interface Bin {
  bin?: string;
  binState?: string;
}
export enum AllowedPaymentMethods {
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  CHECK = 'CHECK',
  PAYPAL = 'PAYPAL',
  DEBIT = 'DEBIT',
  VENMO = 'VENMO',
}

export enum AllowedFileTypes {
  CARRIER = 'CARRIER',
  CENSUS = 'CENSUS',
  CONTRIBUTION = 'CONTRIBUTION',
  ENROLLMENT = 'ENROLLMENT',
  DEPENDENT = 'DEPENDENT',
}

export interface LogoInfo {
  faviconUrl?: string;
  largeLogoUrl?: string;
  darkLargeLogoUrl?: string;
  minimizedLogoUrl?: string;
  darkMinimizedLogoUrl?: string;
}

export enum WaitingForFundsContinuePayment {
  EMPLOYEE_CHOICE = 'EMPLOYEE_CHOICE',
  AUTOPAY = 'AUTO_PAY',
}
export interface OverridablePropertiesDto {
  customerServiceNumber?: string;
  ssoOnly?: boolean;
  allowEmployeesEditAddress?: boolean;
  url?: string;
  accountManagerId?: string;
  themeId?: PersonThemeNamesEnum;
  hsaCustodianId?: string;
  electronicFundingModeId?: string | null;
  checkFundingModel?: string | null;
  cardOffered?: boolean;
  allowDependentCard?: boolean;
  allowAdminContribution?: boolean;
  allowAssumedContribution?: AllowAssumedContribution;
  externalReplenishmentAccountMethod?: string;
  externalPayrollContributionsAccountMethod?: string;
  externalAdministratorFeesAccountMethod?: string;
  payrollContributionsHoldPeriod?: string;
  trueSsn?: boolean;
  idSourceId?: string;
  communicationChannels?: string[];
  communicationCategory?: string[];
  emailTemplate?: string;
  isReimbursementAllowed?: boolean;
  allowedPaymentMethods?: AllowedPaymentMethods[];
  isBillPayAllowed?: boolean;
  isClaimOffsetsAllowed?: boolean;
  waitingForFundsContinuePayment?: WaitingForFundsContinuePayment;
  waitingForFundsExpiration?: string;
  // cardProductionDate?: CardProductionDate,
  // bin?: Bin,
  prin?: string | boolean;
  isExternalLink?: boolean | string;
  replenishmentFrequencyType?: string;
  replenishmentDayNumber?: number;
  sessionTimeoutWarn?: number;
  sessionTimeoutLogout?: number;
  isFileManagerAccessEnabled?: boolean;
  isFileAutoPostEnabled?: boolean | string;
  allowedFileTypes?: AllowedFileTypes[];
  logoInfo?: LogoInfo;
  replenishmentMinValue?: number;
  replenishmentPercent?: number;
  replenishmentCreepMin?: number;
  replenishmentCreepPercent?: number;
  eeContributionsHoldPeriod?: string;
  minimumPaymentReimbursementAmount?: string;
  statementType?: string;
  statementFrequencyType?: string;
  aiAdjudication?: boolean;
  aiConfidenceLevel?: number;
  prefundInitialDelayDate?: string | null;
  cardProcessingStartDate?: string | null;
  authenticationTypeId?: string;
  accountLockoutTime?: number;
  orphanEmployer?: string;

  fileLocation?: string;
  isSameErrorReportLocation?: boolean;
  errorReportLocation?: string;
  participantPortalLink?: string | null;
  adminPortalLink?: string | null;


  customReportingField1Name?: string;
  customReportingField2Name?: string;
  customReportingField3Name?: string;
  customReportingField4Name?: string;
  customReportingField5Name?: string;

  isCustomReportingField1Enabled?: boolean;
  isCustomReportingField2Enabled?: boolean;
  isCustomReportingField3Enabled?: boolean;
  isCustomReportingField4Enabled?: boolean;
  isCustomReportingField5Enabled?: boolean;
}

export interface NormalPropertiesDto {
  id?: string;
  organizationId?: number;
  taxId?: string;
  incomingClearingCccount?: number;
  notionalClearingAccount?: number;
  hsaClearingAccount?: number;
  administratorFeesClearingAccount?: number;
  notionalAccount?: number;
  hsaAccount?: number;
  replenishmentAccount?: number;
  cardProductionDate?: string;
  externalReplenishmentAccount?: string;
  externalPayrollContributionsAccount?: string;
  externalAdministratorFeesAccount?: string;
  accountManagerId?: string;
  implementationManagerId?: string;
  externalAccountEnable?: boolean;
}

export interface OrganizationPhoneDto {
  number?: string;
  phoneExtension?: string;
}

export interface EmployerDto {
  id?: string;
  externalIdentifier?: string;
  name?: string;
  type?: OrganizationTypes;
  parentId?: string | number;
  grandparentId?: string | number;
  addresses?: AddressesDto[];
  overridableProperties?: OverridablePropertiesDto;
  normalProperties?: NormalPropertiesDto;
  shortName?: string;
  businessEntityType?: string | number;
  organizationPhone?: OrganizationPhoneDto;
  organizationStatusType?: EmployerStatus;
  isTerminated?: boolean;
  createdAt?: string;
  themeId?: PersonThemeNamesEnum;
}
