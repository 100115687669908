import React, { useMemo, useState } from 'react';
import {
  AppButton,
  Box,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import PlanCard from '@/components/elements/PlanCard';
import {
  HealthPlan,
  HealthPlanStatuses,
} from '@/modules/HealthPlan/HealthPlan.types';
import {
  PlanCardAction,
  PlanCardMode,
} from '@/modules/plan/plan.types';

import PlanTypeWrapper from './PlanTypeWrapper';

const StyledButton = styled(AppButton)`
  width: calc(33.333% - 16px);
  min-width: 290px;
  &:not(:last-child) {
    margin: 0;
  }
`;
interface Props {
  title: string;
  status: HealthPlanStatuses;
  plans: HealthPlan[];
  placeholder: string;
  isTemplateMode?: boolean;
  isPlansHidden?: boolean;
  onSelectAction?: (value: PlanCardAction, id: string) => void;

}

const PER_PAGE_ITEMS_COUNT = 15;
const HealthPlansList: React.FC<Props> = ({
  title, status, plans, placeholder, isTemplateMode,
  isPlansHidden, onSelectAction,
}) => {
  const [shoMore, setShowMore] = useState(false);

  const moreCount = useMemo(() => {
    if (plans.length > PER_PAGE_ITEMS_COUNT) {
      return plans.length - PER_PAGE_ITEMS_COUNT;
    }
    return 0;
  }, [plans.length]);

  return (
    <PlanTypeWrapper
      title={`${title} (${plans.length})`}
      anchorId={status}
      testId={`Plans_block_${status}`}
      status={status}
    >
      {plans.length ? (
        <>
          <Box direction="row" wrap>
            {
              plans
                .slice(0, shoMore ? (plans.length || 0) : PER_PAGE_ITEMS_COUNT)
                .map((plan) => (
                  <PlanCard
                    testId={`HealthPlanCard_${plan.id}`}
                    isTemplateMode={isTemplateMode}
                    isPlansHidden={isPlansHidden}
                    onSelectAction={onSelectAction}
                    key={plan.id}
                    plan={plan}
                    mode={PlanCardMode.HealthPlan}
                  />
                ))
            }
          </Box>
        </>
      ) : (
        <Text
          color="textSecondary"
          textAlign="center"
          margin="spacing24"
          data-testId="HealthPlans_placeholder"
        >
          {placeholder}
        </Text>
      ) }
      {
        (Boolean(moreCount) && !shoMore)
      && (

        <Box direction="row" margin={{ top: 'spacing8' }} justify="center">
          <div style={{ flex: 1 }} />
          <StyledButton
            testId={`Plans_block_${status}_show-more`}
            onClick={() => setShowMore(true)}
          >
            {`Show More (${moreCount})`}
          </StyledButton>
          <div style={{ flex: 1 }} />
        </Box>

      )
      }
    </PlanTypeWrapper>
  );
};

export default HealthPlansList;
