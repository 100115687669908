import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

const QUERY_KEY = 'useGetEmployerTerminationPlansListQueryKey';
const HSA_ACCOUNT_TYPE = 'HSA';

const planListFormatter = (data: EmployerTerminationPlanPayload[]) => data.map((item) => ({
  id: `${item.id}`,
  name: item.name,
  startDate: item.start_date,
  endDate: item.end_date,
  gracePeriodDaysAmount: item.grace_period_days_amount,
  claimLastDate: item.claim_last_date
    ? dayjs(new Date(item.claim_last_date)).format(DEFAULT_DATE_FORMAT)
    : null,
}));

interface EmployerTerminationPlanPayload {
  id: number;
  name: string;
  start_date: string;
  end_date: string | null;
  grace_period_days_amount: number;
  account_type: string;
  claim_last_date : string;
}

export interface EmployerTerminationPlan {
  id: string;
  name: string;
  startDate: string;
  endDate: string | null;
  gracePeriodDaysAmount: number;
  claimLastDate: string | null;
}

export const useGetEmployerTerminationPlansListQuery = (orgId?: string, terminationDate?: string) => {
  const {
    isLoading, isSuccess, data, refetch,
  } = useQuery(
    [orgId, terminationDate, QUERY_KEY],
    orgId && terminationDate
      ? () => api.get<EmployerTerminationPlanPayload[]>(PATHS.EMPLOYER_TERMINATION_PLANS(orgId), { 
        params:
        { termination_date: terminationDate }
      })
      : () => null,
  );

  const formattedData = useMemo(() => {
    if (!data?.data) return [];

    return planListFormatter(data.data);
  }, [data]);

  const isHsaAccountExist = useMemo(() => data?.data.some((item) => item.account_type === HSA_ACCOUNT_TYPE), [data]);
  const hsaStartDate = useMemo(() => {
    if (!data?.data) return undefined;

    const hsaPlans = data.data
      .filter((item) => item.account_type === HSA_ACCOUNT_TYPE)
      .sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)));
    
    const hsaPlan = hsaPlans[0];

    if (hsaPlan?.start_date
      && (dayjs(hsaPlan.start_date).isBefore(dayjs())
     || dayjs(hsaPlan.start_date).isSame(dayjs()))) {
      return hsaPlan?.start_date;
    }
    return undefined;
  
  }, [data]);

  return ({
    data: data?.data,
    isHsaAccountExist,
    hsaStartDate,
    formattedData,
    isLoading,
    isSuccess,
    refetch,
  });
};
