import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option, Pagination } from '@/common/types';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';

const ALLOWED_ACCOUNT_TYPE = 'EXTERNAL';
const last4length = 4;

interface BankInfoResponse {
  id: string;
  type: string;
  description: string;
  contactId?: string;
  account_category: string;
  account_type: string;
  available_balance: number;
  created_at: string;
  employee_id: number | null
  leaf_node: boolean;
  live_balance: number;
  modified_at: string;
  number: string;
  number_length: number;
  on_deposit_balance: number;
  organization_id: number;
  parent_account_id: number;
  postings: unknown[]
  routing_transit_number: string;
  status_type: string;
  account_number_last4: string;
}

export const formatBankDetails = (bankDetails: BankInfoResponse): BankInfo => ({
  id: `${bankDetails.id}`,
  type: bankDetails.type,
  description: bankDetails.description,
  contactId: bankDetails.contactId,
  accountCategory: bankDetails.account_category,
  accountType: bankDetails.account_type,
  availableBalance: bankDetails.available_balance,
  createdAt: bankDetails.created_at,
  employeeId: bankDetails.employee_id,
  leafNode: bankDetails.leaf_node,
  liveBalance: bankDetails.live_balance,
  modifiedAt: bankDetails.modified_at,
  number: bankDetails.number
    ? bankDetails.number
    : `${Array(bankDetails.number_length - last4length).fill('*').join('')}${bankDetails.account_number_last4}`,
  onDepositBalance: bankDetails.on_deposit_balance,
  organizationId: bankDetails.organization_id,
  parentAccountId: bankDetails.parent_account_id,
  postings: bankDetails.postings,
  routingTransitNumber: bankDetails.routing_transit_number,
  statusType: bankDetails.status_type,
});

export default (organizationId: string) => {
  const {
    isLoading, isSuccess, isError, data, isFetched,
  } = useQuery(
    PATHS.BANK_INFORMATION_BY_ID(organizationId),
    () => api.get<Pagination<BankInfoResponse>>(PATHS.BANK_INFORMATION_BY_ID(organizationId)),
    {},
  );
  const formatedBanks = useMemo(() => (data?.data?.content || [])
    .filter((bank) => bank?.account_type === ALLOWED_ACCOUNT_TYPE)
    .map((bank) => formatBankDetails(bank)), [data]);

  const options = useMemo<Option[]>(() => formatedBanks.map((item) => ({
    key: item.id,
    value: item.description,
    title: item.description,
  })), [formatedBanks]);
  return ({
    banks: formatedBanks,
    data: data?.data || [],
    isError,
    options,
    isLoading,
    isSuccess,
    isFetched,
  });
};
