import { capitalizeFirstLetter, Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { useGetRelationshipListQuery } from '@/modules/transaction/CardDetails/AddCardDependentModal/queries/useGetRelationshipList.query';

const TODAY = dayjs().toDate();

enum PatientFields {
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'birthOn',
  relationship = 'relationshipType',
}

export default () => {
  const { relationshipList } = useGetRelationshipListQuery();
  return [
    {
      name: PatientFields.firstName,
      type: FieldTypes.Text,
      label: 'First name',
      placeholder: 'Enter first name',
      validator: yup.string().required(REQUIRED_TEXT),
      isWide: true,
    },
    {
      name: PatientFields.lastName,
      type: FieldTypes.Text,
      label: 'Last name',
      placeholder: 'Enter last name',
      validator: yup.string().required(REQUIRED_TEXT),
      isWide: true,
    },
    {
      name: PatientFields.dateOfBirth,
      type: FieldTypes.Date,
      label: 'Date of birth',
      placeholder: 'Select birth date',
      isManualDateInput: true,
      maxDate: TODAY,
      validator: VALIDATORS.DATE_FORMAT_MAX_TODAY.required(REQUIRED_TEXT),
      isCalendar: true,
    },
    {
      name: PatientFields.relationship,
      type: FieldTypes.Dropdown,
      label: 'Relationship',
      singleMode: true,
      placeholder: 'Select relationship', // need get from API
      options: relationshipList.map((relationship) => ({
        key: `${relationship.value}`,
        value: `${relationship.value}`,
        title: capitalizeFirstLetter(relationship.name),
      })),
      validator: yup.string().required(REQUIRED_TEXT),
    },
  ] as Field[];
};
