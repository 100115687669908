import { ALL_OPTION } from '@/common/constants';

export interface InputFile extends MediaSource {
  name: string;
  lastModified?: number;
  errorsCount?: number;

}

export enum FileRecordType {
  Null = 'Null',
  Census = 'Census',
  Enrollment = 'Enrollment',
  Contribution = 'Contribution',
  Dependent= 'Dependent',
  Carrier = 'Carrier',
  Mixed = 'Mixed',
  Error = 'Error',
  Md = 'Md',
  Ed = 'Ed',
  Bai2 = 'Bai2',
  Br = 'Br',
  Unknown = 'Unknown',
}

export enum FileStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Error = 'Error',
  Processing = 'Processing',
  Completed = 'Completed',
  Unknown = 'Unknown',
}

export interface FileDto {
  id: string;
  name: string;
  partnersNames?: string[];
  employersNames?: string[];
  uploadedBy?: string;
  uploadWay: string;
  path: string;
  errorsCount?: number;
  originPath: string;
  approvalType: string;
  extensionType: string;
  type: string;
  processingType: string;
  status: FileStatus;
  createdAt: string;
  processedAt: string;
}

export interface FileDtoWithMediaSource extends FileDto {
  file: InputFile;
  temporalId?: string;
  backlighting?: FileStatus;
  backlightingTime?: number;
  hasReuploading?: boolean;
}

export const FILE_RECORD_TYPES_OPTIONS = [
  ALL_OPTION,
  {
    key: FileRecordType.Census,
    value: FileRecordType.Census,
    title: FileRecordType.Census,
  },
  {
    key: FileRecordType.Enrollment,
    value: FileRecordType.Enrollment,
    title: FileRecordType.Enrollment,
  },
  {
    key: FileRecordType.Contribution,
    value: FileRecordType.Contribution,
    title: FileRecordType.Contribution,
  },
  {
    key: FileRecordType.Carrier,
    value: FileRecordType.Carrier,
    title: FileRecordType.Carrier,
  },
  {
    key: FileRecordType.Dependent,
    value: FileRecordType.Dependent,
    title: FileRecordType.Dependent,
  },
];

export const FILE_STATUSE_OPTIONS = [
  ALL_OPTION,
  {
    key: FileStatus.Uploading,
    value: FileStatus.Uploading,
    title: FileStatus.Uploading,
  },
  {
    key: FileStatus.Uploaded,
    value: FileStatus.Uploaded,
    title: FileStatus.Uploaded,
  },
  {
    key: FileStatus.Error,
    value: FileStatus.Error,
    title: FileStatus.Error,
  },
  {
    key: FileStatus.Processing,
    value: FileStatus.Processing,
    title: FileStatus.Processing,
  },
  {
    key: FileStatus.Completed,
    value: FileStatus.Completed,
    title: FileStatus.Completed,
  },
];
