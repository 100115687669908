import { storeCreator } from '@/utils/store/store.utils';

interface State {
  previewMode: boolean
  setPreviewMode: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  previewMode: false,
  setPreviewMode: (value: boolean) => set(() => ({
    previewMode: value,
  })),
}));

export default useStore;
