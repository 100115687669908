import React, { useMemo } from 'react';
import {
  Box,
  Field, FieldTypes, LabelWithSubfield, Text,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { EMPTY_LABEL_SIGN, REQUIRED_TEXT, YES_KEY } from '@/common/constants';
// import { REQUIRED_TEXT } from '@/common/constants';
// import theme from '@/styles/theme';
import { CONTRIBUTION_TYPES_KEY,ContributionState } from '@/modules/plan/plan.types';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import { useOnlyForDev } from '@/utils';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import { useContributionStore } from '../stores';

const ALLOW_CONTRIBUTIONS_DESCRIPTION = 'The type of contributions that will be accepted when sent through API, File, or the Admin Portal. Any other contribution types will not be processed.';
const ALLOW_INDIVIDUAL_CONTRIBUTIONS_DESCRIPTION = 'Employee can select to contribute post-tax contributions from their personal bank account.';
const ALLOW_INDIVIDUAL_CONTRIBUTIONS_KEY = 'allowIndividualContributions';

const CONTRIBUTIONS_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const stringToBoolean = (value?: string) => value === 'true';

const getSubfield = (value: string, key: string, isEditMode?: boolean) => [
  {
    name: `${key}Label`,
    type: FieldTypes.Node,
    inputStyle: {
      margin: 0,
    },

    label: EMPTY_LABEL_SIGN,
    value: (
      <Text weight={700} size="medium">
        {value === ContributionState.EMPLOYER ? 'Employer' : 'Employee' }
      </Text>
    ),
  },
  {
    name: `${key}PreTax`,
    type: FieldTypes.Checkbox,
    label: EMPTY_LABEL_SIGN,
    helpText: 'Pre-tax',
    inputStyle: {
      marginBottom: 0,
      // paddingLeft: '100px',
    },
    validator: yup.string().test({
      test: (testValue, context) => {
        const anotherValue: string = context.parent[`${key}PostTax`];
        return stringToBoolean(testValue) || stringToBoolean(anotherValue);
      },
      message: 'At least one option should be selected',
    }).nullable(),
    subFields: [
      {
        name: `${key}PreTaxValue`,
        type: FieldTypes.Text,
        label: EMPTY_LABEL_SIGN,
        preLabelNode: (
          <Box width="50px" />
        ),
        placeholder: 'Enter custom memo',
        parentValue: true,
        validator: yup.string().nullable(),

      },

    ],

  }, {
    name: `${key}PostTax`,
    type: FieldTypes.Checkbox,

    label: EMPTY_LABEL_SIGN,
    helpText: 'Post-tax',
    inputStyle: {
      marginBottom: 0,
    },

    validator: yup.string().test({
      test: (testValue, context) => {
        const anotherValue: string = context.parent[`${key}PreTax`];
        return stringToBoolean(testValue) || stringToBoolean(anotherValue);
      },
      message: 'At least one option should be selected',
    }).nullable(),
    subFields: [
      {
        name: `${key}PostTaxValue`,
        type: FieldTypes.Text,
        label: EMPTY_LABEL_SIGN,
        preLabelNode: (
          <Box width="50px" />
        ),
        placeholder: 'Enter custom memo',
        parentValue: true,
        validator: yup.string().nullable(),
        // regexp: REGEXPS.ONLY_NUMBERS,
        // // helpText: ROLLOVER_TIME_LIMIT_HELP_TEXT,
        // validator: yup.string().when(ROLLOVER_KEYS.ROLLOVER_CLAIMS, {
        //   is: (val: string) => val === 'true',
        //   then: REQUIRED_VALIDATOR,
        //   otherwise: VALIDATOR,
        // }),
      },
    ],
  }] as Field[];
export default (isActivationMode?: boolean, isTemplateMode?: boolean, isEditMode?: boolean) => {
  const isOnlyForDev = useOnlyForDev();
  const sourceState = useContributionStore((state) => state.sourceState);
  const currentState = useContributionStore((state) => state.state);

  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const allowContributors = useMemo(
    () => currentState?.allowContributors,
    [currentState],
  );
  const subFields = useMemo(() => {
    const additionalFields: Field[] = [{
      name: 'allowContributionOverMaximum',
      type: FieldTypes.Checkbox,
      label:
      (
        <LabelWithSubfield
          title="Allow contributions to go over maximum election amount"
        />
      )
      ,

    }, {
      name: 'pendContribution',
      type: FieldTypes.Checkbox,
      label:
      (
        <LabelWithSubfield
          title="Pend contributions over the IRS max"
        />
      ),

    }];
    if (allowContributors === ContributionState.EMPLOYER_AND_EMPLOYEE) {
      return [
        ...getSubfield(ContributionState.EMPLOYER,
          CONTRIBUTION_TYPES_KEY[ContributionState.EMPLOYER], isEditMode),
        ...getSubfield(ContributionState.EMPLOYEE,
          CONTRIBUTION_TYPES_KEY[ContributionState.EMPLOYEE], isEditMode),
        ...additionalFields,
      ];
    }
    if (allowContributors === ContributionState.EMPLOYER) {
      return [
        ...getSubfield(ContributionState.EMPLOYER,
          CONTRIBUTION_TYPES_KEY[ContributionState.EMPLOYER], isEditMode),
        ...additionalFields,
      ];
    }
    if (allowContributors === ContributionState.EMPLOYEE) {
      return [
        ...getSubfield(ContributionState.EMPLOYEE,
          CONTRIBUTION_TYPES_KEY[ContributionState.EMPLOYEE], isEditMode),
        ...additionalFields,
      ];
    }
    return [];
  }, [allowContributors, isEditMode]);

  const fields: Field[] = useMemo(() => [
    ...isOnlyForDev ? [{
      name: 'allowContributors',
      type: FieldTypes.Dropdown,
      singleMode: true,
      defaultValue: ContributionState.NONE,
      label: (
        <LabelWithSubfield
          title="Payroll contributions accepted"
          requiredIcon
          desc={ALLOW_CONTRIBUTIONS_DESCRIPTION}
        />),

      validator: isActivationMode
        ? yup.string().trim().nullable().required(REQUIRED_TEXT)
        : yup.string().nullable(),
      options: [{
        key: ContributionState.NONE,
        value: 'None',
      }, {
        key: ContributionState.EMPLOYEE,
        value: 'Employee',
      },
      {
        key: ContributionState.EMPLOYER,
        value: 'Employer',
      }, {
        key: ContributionState.EMPLOYER_AND_EMPLOYEE,
        value: 'Employer and Employee',
      }],
      subFields,
    }] as Field[] : [] as Field[],
    {
      name: 'allowIndividualContributions',
      type: FieldTypes.Radio,
      showRequireIcon: true,
      label: (
        <LabelWithSubfield
          title="Allow individual contributions"
          desc={ALLOW_INDIVIDUAL_CONTRIBUTIONS_DESCRIPTION}
        />),

      options: CONTRIBUTIONS_OPTIONS,
      validator: isActivationMode
        ? yup.string().trim().nullable().required(REQUIRED_TEXT)
        : yup.string().nullable(),
      subFields: [
        {
          name: 'minimumContributionAmount',
          showRequireIcon: true,
          parentValue: YES_KEY,
          type: FieldTypes.Currency,
          label: (
            <LabelWithSubfield
              title="Minimum contribution amount"
            />
          ),
          placeholder: 'Enter $ amount',
          validator: yup.string().trim().when(ALLOW_INDIVIDUAL_CONTRIBUTIONS_KEY, {
            is: (val: string) => (val === YES_KEY) && isActivationMode,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: VALIDATORS.STRING,
          }),
        },
      ],
    },
    {
      name: 'сontributionMonitoringRequired',
      type: FieldTypes.Radio,
      showRequireIcon: true,
      label: (
        <LabelWithSubfield
          title="Contribution monitoring required"
        />
      ),
      options: CONTRIBUTIONS_OPTIONS,
      validator: isActivationMode
        ? yup.string().trim().nullable().required(REQUIRED_TEXT)
        : yup.string().nullable(),
    },
  ], [isActivationMode, isOnlyForDev, subFields]);
  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);

  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
