import { ErrorsField } from '@/utils/store/baseStoreCreator';
import { storeCreator } from '@/utils/store/store.utils';

import { CoverageLevelPayload } from '../CoverageLevels.constants';

export const baseStoreCreator = <T>(defaultValues:T) => storeCreator<{
  state: T;
  sourceState: T;
  setState:(values: T) => void;
  setSourceState: (values: T) => void;
  errors: Partial<T>;
  errorFields: Partial<ErrorsField<T>>;

  showErrors: boolean;
  setShowErrors: (value: boolean) => void;
  setErrors: (values: Partial<T>) => void;
  setErrorFields: (values: Partial<ErrorsField<T>>) => void;
  handleReset:() => void;
    }>((set) => ({
      state: defaultValues,
      sourceState: defaultValues,
      errors: {},
      errorFields: {},
      showErrors: false,
      handleReset: () => set(() => ({
        state: defaultValues,
        sourceState: defaultValues,
        errors: {},
        showErrors: false,
      })),
      setShowErrors: (value: boolean) => set(() => ({
        showErrors: value,
      })),
      setErrors: (values: Partial<T>) => set(() => ({
        errors: values,
      })),
      setErrorFields: (values: Partial<ErrorsField<T>>) => set(() => ({
        errorFields: values,
      })),
      setState: (values: T) => set((currentStore) => ({
        state: values,
      })),
      setSourceState: (values: T) => set((currentStore) => ({
        sourceState: values,
      })),

    }));

export const DEFAULT_VALUES: CoverageLevelPayload = {
  id: '',
  name: '',
  coverageId: '',
  title: '',
  coverageCode: '',
  planDeductible: '',
  memberDeductible: '',
  embeddedAmount: '',
  accountFundingConfigs: [],
};

export const useStore = baseStoreCreator<CoverageLevelPayload[]>([]);

export default useStore;
