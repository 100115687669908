import React from 'react';
import {
  AppButton,   Box, Preloader, Text, WarningIcon, WarnModal,
} from '@common-fe/common-fe';

import { useCIPInfo } from './hooks';

interface WrapperProps {
  children: React.ReactNode;
  isStandaloneMode?: boolean;
}
const Wrapper: React.FC<WrapperProps> = ({ isStandaloneMode, children }) => {
  if (isStandaloneMode) {
    return (
      <Box margin={{ bottom: 'l' }}>
        {children}
      </Box>
    );
  }
  return (
    <Box
      elevation="default"
      data-testid="employee-cip-verification-banner"
      round="container1Round"
      background="canvas"
      pad={{ vertical: 'l', horizontal: 'xl' }}
      margin={{ bottom: 'l' }}
    >{
        children
      }
    </Box>
  );
};
interface Props {
  employeeId: string;
  isStandaloneMode?: boolean;
}
const EmployeeCIPVerificationBanner: React.FC<Props> = ({ employeeId, isStandaloneMode }) => {
  const {
    isVerificationRequired, handleVerify,
    isVerificationLoading, isShowErrorModal, setShowErrorModal,
  } = useCIPInfo({ employeeId });
  if (!isVerificationRequired) {
    return null;
  }

  return (
    <div>
      <WarnModal
        visible={isShowErrorModal}
        testId="CIP-banner_server_error"
        onSetVisible={setShowErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot verify your identity to establish your HSA.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleVerify}
        submitButtonText="Try Again"
      />
      <Wrapper isStandaloneMode={isStandaloneMode}>
        <Box
          pad={{ vertical: 'l', horizontal: 'xl' }}
          data-testid="cip-banner"
          round="container2Round"
          direction="column"
          justify="center"
          background="warningContainer"
          border={{ color: 'warningBorder' }}
        >
          <Box direction="row" align="center">
            <Box fill>
              <Box
                direction="row"
                round="container1Round"
                align="center"
              >
                <WarningIcon size="24px" />
                <Text
                  textAlign="center"
                  color="textBody"
                  margin={{ left: 'xs' }}
                  size="large"
                  weight="bold"
                >
                  We need to verify your identity to establish your HSA
                </Text>
              </Box>

              <Text color="textBody" size="medium">
                With just a few extra details we can activate your account.
              </Text>
            </Box>
            <AppButton
              testId="cip-banner_verify"
              onClick={handleVerify}
              disabled={isVerificationLoading}
              width="xregular"
            >
              {isVerificationLoading ? <Preloader /> : 'Verify Identity'}

            </AppButton>
          </Box>
        </Box>

      </Wrapper>
    </div>

  );
};

export default EmployeeCIPVerificationBanner;
