import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';

import {
  DeductibleType,
  MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
  PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
} from '@/modules/deductibles/deductibles.types';
import { BASED_ON_NONE_KEY,ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import { MainInfo } from '../CreateProcessingRule.types';

const NA_KEY = 'null';

export interface TierField {
  id?: string;
  name: string;
  payoutDefinition: string;
  payoutDefinitionName: string;
  familyDeductible?: string;
  individualDeductible?: string;
  triggers: string[];
  isSendTriggerNotification?: boolean;
  displayName?: boolean;
  nameToDisplay?: string;
  planId?: string;
}

const DEFAULT_TIER_FIELD: TierField = {
  id: '',
  name: '',
  payoutDefinition: '',
  payoutDefinitionName: '',
  triggers: [],
  displayName: false,
  nameToDisplay: '',
  planId: '',
};
interface Params {
  visible?: boolean;
  data: ProcessingRuleItem[],
  state: MainInfo,
  allowedResetTriggers?: boolean;
  updatedElement?: ProcessingRuleItem;
}

export default (
  {
    visible,
    data,
    state,
    allowedResetTriggers,
    updatedElement,
  }: Params,
  // setShowWarning: (value: boolean) => void,
) => {
  const [fieldsValues, setFieldsValues] = useState<TierField[]>([
    {
      ...DEFAULT_TIER_FIELD,
      id: _.uniqueId(),
    },
  ]);

  const basedOnTiers = useMemo(() => {
    if (updatedElement?.tiers) {
      return updatedElement.tiers;
    }
    const rule = data?.find((el) => el.id === state.basedOn);
    return rule?.tiers;
  }, [state.basedOn, data, updatedElement]);

  useEffect(() => {
    // if (state.basedOn !== BASED_ON_NONE_KEY
    //   && !allowedResetTriggers
    //   && visible
    // ) {
    //   setShowWarning(true);
    // } else
    if (state.basedOn !== BASED_ON_NONE_KEY || updatedElement
    // && allowedResetTriggers
    ) {
      const copiedTiers = [] as TierField[];
      basedOnTiers?.map((el) => {
        let formattedTriggers: string[] = [];
        if (el.triggers?.includes(DeductibleType.Embedded)
          && el.triggers?.includes(DeductibleType.Plan)) {
          formattedTriggers.push(PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE);
        } else if (el.triggers?.includes(DeductibleType.Embedded)
          && el.triggers?.includes(DeductibleType.Member)) {
          formattedTriggers.push(MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE);
        } else if (el.triggers) {
          formattedTriggers = [...el.triggers];
        }

        return copiedTiers.push({
          id: el.id,
          name: el.name,
          payoutDefinition: el.payoutDefinition.id,
          payoutDefinitionName: el.payoutDefinition.name,
          displayName: !!el.displayName,
          nameToDisplay: el.displayName,
          planId: el.planId,
          triggers: formattedTriggers,
        });
      });
      setFieldsValues(copiedTiers);
    } else if (state.basedOn === BASED_ON_NONE_KEY
    // && (allowedResetTriggers === undefined || allowedResetTriggers === true)
    ) {
      setFieldsValues([
        {
          ...DEFAULT_TIER_FIELD,
          id: _.uniqueId(),
        },
      ]);
    } else {
      setFieldsValues([
        ...fieldsValues,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, state.basedOn, allowedResetTriggers, updatedElement]);

  const getFieldValuesById = useCallback(
    (id?: string) => {
      const defaultValues = fieldsValues.find((field) => field.id === id);

      return defaultValues;
    },
    [fieldsValues],
  );
  const handleChangeValues = useCallback(
    (id?: string) => (values: Partial<TierField>) => {
      const newList = fieldsValues.map((item) => {
        if (item.id === id) {
          const newFiltered = values.triggers?.filter((currentItem) => currentItem !== NA_KEY);
          const isNACome = values.triggers?.includes(NA_KEY);
          const isNAExists = item.triggers?.includes(NA_KEY);
           
          const newTriggers = isNAExists && newFiltered?.length
            ? newFiltered : isNACome
              ? [NA_KEY] : values.triggers || [];
          const isPayoutDefinitionChanged = (item.payoutDefinition !== values.payoutDefinition)
            || values.payoutDefinition === '';

          const result = {
            ...item,
            ...values,
            ...isPayoutDefinitionChanged ? { planId: '' } : {},
            triggers: newTriggers,

          };

          return result;
        }
        return item;
      });
      if (!_.isEqual(newList, fieldsValues)) {
        setFieldsValues(newList);
      }
    },
    [fieldsValues],
  );
  const handleRemoveById = useCallback((id: string) => {
    setFieldsValues(fieldsValues.filter((item) => item.id !== id));
  }, [fieldsValues]);

  const handleClearValues = useCallback((id: string) => {
    const newList = fieldsValues.map((item) => {
      if (id === item.payoutDefinition) {
        return {
          ...item,
          payoutDefinition: '',
          payoutDefinitionName: '',
        };
      }
      return {
        ...item,
      };
    });
    setFieldsValues(newList);
  }, [fieldsValues]);

  const handleAddNewTier = useCallback(() => {
    setFieldsValues([
      ...fieldsValues,
      {
        ...DEFAULT_TIER_FIELD,
        id: _.uniqueId(),
      },
    ]);
  }, [fieldsValues]);
  return {
    handleChangeValues,
    handleRemoveById,
    getFieldValuesById,
    fieldsValues,
    handleAddNewTier,
    handleClearValues,
  };
};
