import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  FlexList,
  ListItem,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
  WarnModal,
} from '@common-fe/common-fe';

import { DefaultSortTypesEnum, ListItemBase,OptionKey } from '@/common/types';
import useCarrierConfigListQuery, { CarrierConfig } from '@/modules/CarrierConfig/queries/useCarrierConfigList.query';
import useCarrierConfigStore from '@/modules/CarrierConfig/useCarrierConfig.store';
import { useCurrentOrganization,useSnackbar } from '@/modules/core/hooks';

import useDisable from './hooks/useDisable';
import { ConfigStatus } from './types/carrier.types';
import ViewCarrierConfigModal from './ViewCarrierConfigModal/ViewCarrierConfigModal';
import { CARRIER_CONFIG_PURPOSES,CarrierConfigPurpose } from './CarrierConfig.constants';
import { ListWrapper } from './CarrierConfig.styles';
import { ListPlaceholder } from './components';
import { useCarrierConfigList, useListPagination } from './hooks';

interface Props {
  title: string,
  hideListTitle?: boolean,
  statusType: ConfigStatus,
  node?: React.ReactNode;
}

const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'A-Z',
  },
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Z-A',
  },
];

export const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'carrierName',
    title: 'Carrier name',
    flex: 1,
  },
  {
    key: 'carrierId',
    title: 'Carrier ID',
    flex: 1,
  },
  {
    key: 'submitterId',
    title: 'Submitter ID',
    flex: 1,
  },
  {
    key: 'organization',
    title: 'Organization',
    flex: 1,
  },
  {
    key: 'purpose',
    title: 'Purpose',
    flex: 1,
  },
  {
    key: 'description',
    title: 'Description',
    flex: 2,
  },
];

const purposeAllToggling = (purposes: OptionKey[]) => {
  const isPurposeAllSelected = purposes[purposes.length - 1] === CarrierConfigPurpose.Null;

  if (purposes.length === 0 || isPurposeAllSelected) {
    return [CarrierConfigPurpose.Null];
  }

  const isPurposeAllChangedToAnother = purposes.length > 1
    && purposes[0] === CarrierConfigPurpose.Null;

  if (isPurposeAllChangedToAnother) {
    return purposes.filter((purpose) => purpose !== CarrierConfigPurpose.Null);
  }

  return purposes;
};

const CarrierConfigList: React.FC<Props> = ({
  title,
  hideListTitle,
  statusType,
  node,
}) => {
  const { handleAddPermanentSnackbar } = useSnackbar();
  const timer = useRef<NodeJS.Timeout>();
  const [hasBacklighting, setHasBacklighting] = useState(false);
  const [
    onlyActualJustCreatedCarrierConfigs, setOnlyActualJustCreatedCarrierConfigs,
  ] = useState<CarrierConfig []>([]);
  const {
    carrierConfigs: justCreatedCarrierConfigs,
    resetCarrierConfigs: resetJustCreatedCarrierConfigs,
  } = useCarrierConfigStore();
  const lastCreatedCarrierConfig = justCreatedCarrierConfigs?.[0];
  useEffect(() => {
    if (lastCreatedCarrierConfig?.statusType === statusType) {
      handleAddPermanentSnackbar({
        text: `The ${lastCreatedCarrierConfig?.carrierName} carrier config has been created!`,
        closeIcon: true,
      });
      setHasBacklighting(true);
      timer.current = setTimeout(() => setHasBacklighting(false), 2000);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lastCreatedCarrierConfig]);
  useEffect(() => () => {
    if (timer.current) clearTimeout(timer.current);
    resetJustCreatedCarrierConfigs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const [viewCarrierId, setViewCarrierId] = useState('');

  const {
    page, setPage, perPage, setPerPage, currentSearch,
    searchString, setSearchString, purposes, setPurposes,
    setSortBy, sortBy,
  } = useListPagination();
  const purposesValues = useMemo(() => purposeAllToggling(purposes), [purposes]);
  const hasPurposes = useMemo(
    () => purposesValues.length > 0
      && !purposesValues.some((purpose) => purpose === CarrierConfigPurpose.Null),
    [purposesValues],
  );

  const { isLoaded, observingMode } = useCurrentOrganization();

  const resetCreatedConfigs = useCallback(() => {
    if (onlyActualJustCreatedCarrierConfigs.length) {
      setOnlyActualJustCreatedCarrierConfigs([]);
      resetJustCreatedCarrierConfigs();
    }
  }, [resetJustCreatedCarrierConfigs, onlyActualJustCreatedCarrierConfigs]);

  const { formattedData, total, isLoading } = useCarrierConfigListQuery({
    searchString: currentSearch,
    ...(hasPurposes ? { purposes: purposesValues as CarrierConfigPurpose[] } : {}),
    statusType,
    page: page - 1,
    perPage,
    sortBy: sortBy as DefaultSortTypesEnum,
    enabled: isLoaded || !observingMode,
    onSettled: resetCreatedConfigs,
  });

  const onlyCreatedCarrierConfigs = useMemo(() => (justCreatedCarrierConfigs
    .filter((createdCarrierConfig) => createdCarrierConfig.statusType === statusType)),
  [justCreatedCarrierConfigs, statusType]);

  useEffect(() => {
    setOnlyActualJustCreatedCarrierConfigs(onlyCreatedCarrierConfigs);
  }, [onlyCreatedCarrierConfigs]);

  const configs = useMemo(() => {
    if (onlyActualJustCreatedCarrierConfigs.length) {
      return [
        ...onlyActualJustCreatedCarrierConfigs,
        ...formattedData
          .filter(
            (config) => onlyActualJustCreatedCarrierConfigs
              .every((createdConfig) => config.carrierConfigId !== createdConfig.carrierConfigId),
          ),
      ].slice(0, perPage);
    }

    return formattedData;
  }, [formattedData, onlyActualJustCreatedCarrierConfigs, perPage]);

  const handleOpenViewModal = useCallback((id: string) => {
    setViewCarrierId(id);
  }, []);

  const { fields } = useCarrierConfigList({
    carrierConfigs: configs,
    setOpenModal: handleOpenViewModal,
    ...onlyActualJustCreatedCarrierConfigs.length ? { hasBacklighting } : {},
  });
  const {
    disableItem,
    disableOption,
    disableItemTitle,
    handleDisableItem,
    resetDisabling,
  } = useDisable(fields, () => {
    resetJustCreatedCarrierConfigs();
  });

  const listHasData = useMemo(() => configs?.length, [configs]);
  const isActiveList = useMemo(() => statusType === ConfigStatus.active, [statusType]);

  const options = useMemo(() => [...[
    {
      name: 'View',
      onClick: handleOpenViewModal,
    },
  ], ...disableOption && isActiveList ? [disableOption] : []],
  [disableOption, isActiveList, handleOpenViewModal]);

  const countOfCarriers = useMemo(
    () => onlyActualJustCreatedCarrierConfigs.length + total,
    [onlyActualJustCreatedCarrierConfigs, total],
  );

  return (
    <>
      <ListWrapper>
        <Box>
          {!hideListTitle && (
            <Box margin={{ bottom: 'spacing18' }}>
              <Text size="xxlarge">{title}</Text>
            </Box>
          )}
          <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
            <Box>
              <Text weight="bold" color="textBody" size="large" style={{ whiteSpace: 'nowrap' }}>
                Configs: {countOfCarriers}
              </Text>
            </Box>
            <Box direction="row" justify="end">
              <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                <SearchInput
                  disabled={false}
                  value={searchString as string}
                  placeholder="Search"
                  onChange={setSearchString}
                  hasSearchingHistory
                />
              </Box>
              <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                <SelectDropdown
                  id="purpose"
                  name="Purpose"
                  disabled={false}
                  prefix="Purpose"
                  values={purposesValues}
                  activeTitle
                  options={CARRIER_CONFIG_PURPOSES}
                  onChangeValues={setPurposes}
                />
              </Box>

              <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                <SelectDropdown
                  id="sort_by"
                  disabled={false}
                  name="Sort by"
                  prefix="Sort by"
                  singleMode
                  value={sortBy}
                  onChange={setSortBy}
                  activeTitle
                  options={SORTING_OPTIONS}
                />
              </Box>
              {node}
            </Box>
          </Box>
        </Box>
        <FlexList
          total={countOfCarriers}
          headers={LIST_HEADERS}
       
          rows={fields as ListItem[]}
          loading={isLoading}
          moreCount={perPage}
          pad={{ horizontal: '0px', vertical: '0px' }}
          options={options}
          placeholder={<ListPlaceholder>There are no carrier configs</ListPlaceholder>}
          footer={
            listHasData ? (
              <PaginationBar
                page={page}
                total={countOfCarriers}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            ) : undefined
          }
        />
        {
          !!viewCarrierId && (
            <ViewCarrierConfigModal
              carrierId={viewCarrierId}
              setViewCarrierId={setViewCarrierId}
            />
          )
        }

      </ListWrapper>
      <WarnModal
        testId="carrier-config-list-disable"
        visible={!!disableItem}
        onSetVisible={resetDisabling}
        header={`Disable ${disableItemTitle}?`}
        buttonText="Cancel"
        submitButtonText="Yes, Disable"
        submitButtonMode="alert"
        onSubmit={() => handleDisableItem(disableItem)}
      />
    </>
  );
};

export default CarrierConfigList;
