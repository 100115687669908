import React from 'react';
import { AbsenceFilesPlaceholderIcon, Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledSearchPlaceholderIcon = styled(AbsenceFilesPlaceholderIcon)`
  width: ${({ theme }) => theme.spacings.spacing72};
  height: ${({ theme }) => theme.spacings.spacing72};
  fill: ${({ theme }) => theme.colors.border};
`;

const ContributionsListEmptyPlaceholder = () => (
  <Box
    data-testid="ContributionsListEmptyPlaceholder_container"
    direction="column"
    justify="center"
    align="center"
    margin={{ top: 'spacing12' }}
  >
    <Box margin={{ bottom: 's' }}>
      <StyledSearchPlaceholderIcon />
    </Box>
    <Inscription size="medium" weight="bold" color="textBody">
      There are no Contribution records yet
    </Inscription>
  </Box>
);

export default ContributionsListEmptyPlaceholder;
