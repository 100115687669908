import { Box, Button,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledText = styled(Text)`
`;

export const ItemWrapper = styled(Box)`
  &:last-child {
    padding-bottom: 0 ;
  }
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  height: auto;

  min-height: 21px !important;
  height: 21px !important;

  ${StyledText} {
    color: ${({ active, theme }) => (active ? theme.colors.textAccent : theme.colors.textBody)};
  }
  &:hover {
    ${StyledText} {
    color: ${({ theme }) => theme.colors.textAccentHover}
    }
  }
`;

export const ListContainer = styled(Box)`
  a {
    &:last-child {
      ${StyledButton} {
        margin-bottom: 0;
      }
    }  
  }
`;
