import React, { useState } from 'react';
import { LinkButton, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { FileDtoWithMediaSource } from '../FileManager.constants';

const StyledLinkButton = styled(LinkButton)`
  line-height: 20px;
`;

interface Props {
  file: FileDtoWithMediaSource;
  onFileProcess: (file: FileDtoWithMediaSource) => void;
  title: string;
}

const ProcessLink: React.FC<Props> = ({ file, onFileProcess, title }) => {
  const [isClicked, setIsClicked] = useState(false);

  if (isClicked) {
    return <Preloader />;
  }

  return (
    <StyledLinkButton
      testId={`${title}_${file.id || file.temporalId}`}
      onClick={() => {
        onFileProcess(file);
        setIsClicked(true);
      }}
      linkInlineMode
    >
      {title}
    </StyledLinkButton>
  );
};

export default ProcessLink;
