import { useMemo } from 'react';

import { useStore } from '../stores/useEnrollmentMatrixFieldsState.store';

export default () => {
  const enrollmentMatrixStore = useStore((state) => state.state);

  const state = useMemo(() => ({
    ...enrollmentMatrixStore,
  }), [enrollmentMatrixStore]);
  return state;
};
