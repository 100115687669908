import { useMemo } from 'react';

import { useAccountFundingFields, useAccountFundingSourceState,useAccountFundingState } from '../../AccountFunding/hooks';

import { useChangesList } from './useChangesList';
import { useFieldsWithSubfields } from './useFieldsWithSubfields';
import { useMatchFields } from './useMatchFields';

const useAccountFundingSummary = () => {
  const state = useAccountFundingState();
  const sourceState = useAccountFundingSourceState();

  const fields = useAccountFundingFields();
  const formatedFields = useMemo(() => fields.map((item) => {
    if (item.name === 'rolloverTimeLimit') {
      return {
        ...item,
        label: 'Rollover time limit',
      };
    }
    return item;
  }), [fields]);
  const { allFields: stateFields } = useFieldsWithSubfields(state, formatedFields);

  const { allFields } = useFieldsWithSubfields(sourceState, formatedFields);
  const list = useMatchFields(state, stateFields);
  const formatedBaseList = useMatchFields(sourceState, allFields);
  const changes = useChangesList(formatedBaseList, list);
  return {
    changes,
  };
};
export default useAccountFundingSummary;
