import { MultiFactorAuthenticationValues } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { storeCreator } from '@/utils/store/store.utils';

const INIT_STATE = {};

interface State {
  state: MultiFactorAuthenticationValues;
  setMultiFactorAuthenticationValues: (value: MultiFactorAuthenticationValues) => void;
}

const useStore = storeCreator<State>((set) => ({
  state: INIT_STATE,

  setMultiFactorAuthenticationValues: (newState: MultiFactorAuthenticationValues) => set((oldState) => ({
    state: {
      ...oldState.state,
      ...newState,
    },
  })),
}));
const useMultiFactorAuthenticationStore = () => ({ ...useStore() });

export default useMultiFactorAuthenticationStore;
