import React, { useMemo } from 'react';
import { Box, ClickToCopy, ListItem } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { ListItemType } from '@/common/types';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import Link from '@/modules/core/components/Link';
import { Employee } from '@/modules/employee/employee.types';
import theme from '@/styles/theme';

interface OrganizationNamesProps {
  employerName?: string,
  subsidiaryName?: string,
}

const OrganizationNames: React.FC<OrganizationNamesProps> = ({ employerName, subsidiaryName }) => (
  <Box>
    {
      employerName && (
        <TextWithPointer
          title={employerName}
          disableHovering
          ellipsisMode
        >
          {employerName}
        </TextWithPointer>
      )
    }
    {subsidiaryName && (
      <TextWithPointer
        title={subsidiaryName}
        margin={{ top: 'spacing2' }}
        weight={500}
        size="12px"
        color="textSecondary"
        disableHovering
        ellipsisMode
      >
        {subsidiaryName}
      </TextWithPointer>
    )}
  </Box>
);

const useEmployeesList = (data: Employee[]) => {
  const formatedList = useMemo<ListItem[]>(() => data?.map((item: Employee) => ({
    id: item.id,
    fields: [
      {
        key: 'firstName',
        type: ListItemType.Info,
        title: item.firstName || '',
        link: ROUTES.EMPLOYEE_VIEW(item.id),
        flex: 1.2,
        node: (
          <Link to={ROUTES.EMPLOYEE_VIEW(item.id)}>
            <TextWithPointer
              title={item.firstName}
              size="medium"
              weight="bold"
              color="textBody"
              ellipsisMode
            >
              {item.firstName || ''}
            </TextWithPointer>
          </Link>
        ),
      },
      {
        key: 'lastName',
        type: ListItemType.Info,
        title: item.lastName || '',
        link: ROUTES.EMPLOYEE_VIEW(item.id),
        flex: 1.5,
        ellipsisMode: true,
        node: (
          <Link to={ROUTES.EMPLOYEE_VIEW(item.id)}>
            <TextWithPointer
              title={item.lastName}
              size="medium"
              weight="bold"
              color="textBody"
              ellipsisMode
            >
              {item.lastName || ''}
            </TextWithPointer>
          </Link>
        ),
      },
      {
        key: 'ssn',
        type: ListItemType.Text,
        title: item.ssn || '',
        flex: 1,
        style: { marginRight: theme.spacings.spacing16 },
      },
      {
        key: 'employerEmployeeId',
        type: ListItemType.Node,
        title: item.employerEmployeeId,
        node: (
          <Box margin={{ right: 'spacing4' }}>
            <ClickToCopy
              title={item.employerEmployeeId || ''}
              testId={`employerEmployeeId_${item.id}`}
            />
          </Box>
        ),
        flex: 1,
        ellipsisMode: true,
        style: { marginRight: theme.spacings.spacing16 },
      },
      {
        key: 'partnerEmployeeId',
        type: ListItemType.Node,
        title: item.partnerEmployeeId,
        node: (
          <Box margin={{ right: 'spacing4' }}>
            <ClickToCopy
              title={item.partnerEmployeeId}
              testId={`partnerEmployeeId_${item.id}`}
            />
          </Box>
        ),
        flex: 1,
        ellipsisMode: true,
        style: { marginRight: theme.spacings.spacing16 },
      },
      {
        key: 'employer',
        type: ListItemType.Node,
        title: item.employerName || '',
        node: (
          <OrganizationNames
            employerName={item.employerName}
            subsidiaryName={item.subsidiaryName}
          />
        ),
        flex: 1.8,
        ellipsisMode: true,
        style: { marginRight: theme.spacings.spacing16 },
      },
      {
        key: 'status',
        type: ListItemType.Status,
        title: item.status || '',
        flex: 1.24,
        isReverse: true,
        style: {
          marginRight: theme.spacings.spacing24,
        },
      },
    ],
  })),
  [data]);

  return formatedList;
};

export default useEmployeesList;
