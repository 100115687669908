import { useCallback } from 'react';

import { getErrorsMap } from '@/utils/handlers';

import { useStore } from '../stores/useServerErrors.store';

export default () => {
  const handleSetErrors = useStore((state) => state.setErrors);
  const handleResetErrors = useCallback(() => {
    handleSetErrors({});
  }, [handleSetErrors]);
  const handleServerErrors = useCallback((errors: object) => {
    const result = getErrorsMap(errors);
    handleSetErrors(result);
  }, [handleSetErrors]);
  return { handleServerErrors, handleResetErrors };
};
