import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';

enum OrganizationQueryParams {
  searchValue = 'search_value',
  type = 'type',
  parentId = 'parent_id',
  statuses = 'statuses',
  page = 'page',
  size = 'size',
}

export const GET_ORGANIZATIONS_KEY = 'GET_ORGANIZATIONS_KEY';

const SIZE_PER_PAGE = 50;
const PAGE = 0;

export interface OrganizationsResponse {
  id: string;
  name?: string;
  short_name?: string;
  external_identifier?: string;
  organization_status_type?: string;
  valid_from?: string | number,
  parent_id?: string | number;
}

const useGetOrganizationsQuery = (
  type: OrganizationTypes,
  searchValue?: string,
  enabled?: boolean,
) => {
  const { observingOrganization, observingMode } = useCurrentOrganization();
  const parentId = useMemo(() => {
    if (observingMode) return toString(observingOrganization.id);
    return '';
  }, [observingMode, observingOrganization]);
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(OrganizationQueryParams.type, type);
    queryGenerator.set(OrganizationQueryParams.statuses, 'ACTIVE');
    queryGenerator.set(OrganizationQueryParams.page, `${PAGE}`);
    queryGenerator.set(OrganizationQueryParams.size, `${SIZE_PER_PAGE}`);
    queryGenerator.set(OrganizationQueryParams.searchValue, searchValue || '');
    if (parentId) {
      queryGenerator.set(OrganizationQueryParams.parentId, parentId);
    }

    return queryGenerator;
  }, [parentId, searchValue, type]);
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [
      `${GET_ORGANIZATIONS_KEY}-${type}-${parentId}-${searchValue}`,
      searchValue,
      type,
      parentId,
      enabled,
    ],
    () => api.get(PATHS.ORGANIZATIONS, { params }),
    {
      cacheTime: 0,
      enabled,
    },
  );

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data.content || [];
    return list.map((item: OrganizationsResponse) => ({
      key: String(item.id),
      value: item.name,
      title: item.name,
    }));
  }, [data]);

  return {
    data: formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useGetOrganizationsQuery;
