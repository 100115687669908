import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const CardItemWrapper = styled(Box)`
  width: 480px;
  position: relative;
`;

export const CardLoggedTooltip = styled(Box)`
  position: absolute;
  width: 100%;
  z-index: 100;
  top: -19px;
`;

export const CardStatusActionWrap = styled(Box)`
flex: 1;
`;
