import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { MINIMIZE_DATE_FORMAT } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import AccountActivityDetails from '@/modules/employer/components/AccountActivityDetails/AccountActivityDetails';
import { useReconciliationReportDetails } from '@/modules/employer/queries/useReconciliationBankAccounts.query';
import theme from '@/styles/theme';

import Topbar from '../modules/core/components/Topbar';

export const AccountActivityDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const closeDate = useMemo(
    () => new URLSearchParams(search).get(QueryKeys.TRANSACTIONS_CLOSE_DATE),
    [search],
  );
  const { formatedData } = useReconciliationReportDetails(id);

  useBreadcrumbs([
    {
      route: ROUTES.FINOPS,
      title: '...',
      tooltipText: 'FinOps',
    },
    {
      route: ROUTES.RECONCILIATION_REPORT_DETAILS(id),
      title: formatedData?.account_description || '',
    },
    ...closeDate
      ? [
        {
          route: ROUTES.ACCOUNT_ACTIVITY_TRANSACTIONS(id),
          params: {
            [QueryKeys.TRANSACTIONS_CLOSE_DATE]: dayjs(closeDate).format(MINIMIZE_DATE_FORMAT),
          },
          title: closeDate,
        },
      ]
      : [],
  ], [formatedData, closeDate],
  {
    backBtn: true,
    removeParamsByRedirecting: [QueryKeys.TRANSACTIONS_CLOSE_DATE],
  });

  return (
    <Box width="100%">
      <Topbar />
      <Box align="center" margin={{ top: 'medium' }}>
        <Box width={theme.defaultContentWidth}>
          <AccountActivityDetails />
        </Box>
      </Box>
    </Box>
  );
};
