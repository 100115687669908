import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter,ListItem } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { MINIMIZE_DATE_FORMAT } from '@/common/constants';
import regexp from '@/common/regexp';
import routes from '@/common/routes';
import {
  ListItemType,
} from '@/common/types';
import { useHistory } from '@/modules/core/hooks';

export interface BankAccountReport {
  account_description: string;
  account_id: string;
  account_number: string;
  type: string;
  our_balance: number;
  bank_balance: number;
  difference: number;
  prior: number;
  delta: number;
  credit: number;
  debit: number;
  close_date: string;
}

export interface BankAccountClose {
  id: number;
  started_on: string;
  ended_on: string;
  period_type: string;
  previous_close_id: number;
  status: string;
}

export interface ListOfBankAccountsDto {
  report: BankAccountReport[],
  close: BankAccountClose,
}

const BOLD_STYLE: React.CSSProperties = {
  fontWeight: 700,
};

export enum ReconciliationReportsTableFieldKeyEnum {
  account = 'account',
  accountID = 'accountID',
  type = 'type',
  ourBalance = 'ourBalance',
  bankBalance = 'bankBalance',
  difference = 'difference',
  prior = 'prior',
  delta = 'delta',
  credit = 'credit',
  debit = 'debit',
  closeDate = 'closeDate',
}

export const formattingReconciliationReportsListItemFields = (
  item: BankAccountReport,
  onPick?: () => void,
): ListItem => {
  const preparedListOfTypes = {
    id: item.account_id,
    onPick,
    fields: [
      {
        key: ReconciliationReportsTableFieldKeyEnum.account,
        type: ListItemType.Text,
        title: item.account_description || '-',
        sticky: true,
        style: {
          ...BOLD_STYLE,
          width: '268px',
        },
        ellipsisMode: true,
        maxWidth: '268px',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.accountID,
        type: ListItemType.Text,
        title: item.account_id || '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.type,
        type: ListItemType.Text,
        title: item.type ? capitalizeFirstLetter(item.type).replace(regexp.DASH_SYMBOL, ' ') : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.ourBalance,
        type: ListItemType.Text,
        title: item?.our_balance ? item?.our_balance?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.bankBalance,
        type: ListItemType.Text,
        title: item?.bank_balance ? item?.bank_balance?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.difference,
        type: ListItemType.Text,
        title: item?.difference ? item?.difference?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.prior,
        type: ListItemType.Text,
        title: item?.prior ? item?.prior?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.delta,
        type: ListItemType.Text,
        title: item?.delta ? item?.delta?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.credit,
        type: ListItemType.Text,
        title: item?.credit ? item?.credit?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.debit,
        type: ListItemType.Text,
        title: item?.debit ? item?.debit?.toFixed(2) : '-',
      },
      {
        key: ReconciliationReportsTableFieldKeyEnum.closeDate,
        type: ListItemType.Text,
        title: item.close_date ? dayjs(item.close_date).format(MINIMIZE_DATE_FORMAT) : '-',
      },
    ],
  };

  return preparedListOfTypes;
};

const GET_BANK_ACCOUNT_LIST_BY_CLOSE_DAY = 'close_day';

export const useReconciliationBankAccountList = (close_day?: string, enabled = true) => {
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();
    if (close_day) {
      queryGenerator.set(GET_BANK_ACCOUNT_LIST_BY_CLOSE_DAY, `${close_day}`);
    }

    return queryGenerator;
  }, [close_day]);

  const {
    isLoading, error, data, isSuccess, refetch,
  } = useQuery(
    ['ReconciliationBankAccounts'],
    () => api
      .get<ListOfBankAccountsDto>(PATHS.RECONCILIATION_LIST_OF_BANK_ACCOUNTS, {
        params,
      }),
    {
      enabled,
      retry: false,
    },
  );
  const history = useHistory();
  const handleOpenReportDetailsPage = useCallback((id: string) => {
    history.push(routes.RECONCILIATION_REPORT_DETAILS(id));
  }, [history]);
  const formattedList = useMemo(() => data?.data.report
    .map(
      (item: BankAccountReport) => formattingReconciliationReportsListItemFields(
        item,
        () => handleOpenReportDetailsPage(item.account_id),
      ),
    ),
  [data, handleOpenReportDetailsPage]);

  return {
    data: (!error && formattedList) ? formattedList as ListItem[] : [],
    response: data?.data.report || [],
    total: (!error && formattedList) ? formattedList?.length : 0,
    error,
    isLoading,
    isSuccess,
    refetch,
  };
};

export const useReconciliationReportDetails = (id: string, close_day?: string) => {
  const {
    isLoading, error, response, isSuccess, refetch,
  } = useReconciliationBankAccountList(close_day);

  const currentReport = response.find((item) => `${item.account_id}` === id);

  return {
    isLoading,
    error,
    isSuccess,
    refetch,
    formatedData: currentReport,
    listFormat: currentReport
      ? [formattingReconciliationReportsListItemFields(currentReport)]
      : [] as ListItem[],
  };
};
