import React, { useCallback, useState } from 'react';
import { AppButton, Box, ErrorModal, Field, FlexControlledForm, Preloader } from '@common-fe/common-fe';
import * as yup from 'yup';

import { SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE } from '@/common/constants';
import { SnackbarState } from '@/modules/core/core.types';
import { useCurrentOrganization, useSnackbar } from '@/modules/core/hooks';

import { useAddPayrollGroupFields } from './hooks/useAddPayrollGroupFields';
import useCreatePayrollGroupQuery from './queries/useCreatePayrollGroup.query';
import useUpdatePayrollGroupQuery from './queries/useUpdatePayrollGroup.query';
import { PayrollGroupFormFields, PayrollGroupFormValues } from './payrollGroup.types';
import { useCurrentPayrollGroupsStore, usePayrollGroupModalStore } from './stores';

const BUTTON_WIDTH = '160px';

interface Props {
  onCancel?: () => void;
}

export const AddPayrollGroupForm: React.FC<Props> = ({ onCancel }) => {
  const [formValues, setFormValues] = useState<PayrollGroupFormValues>({});
  const [infoSetup, setInfoSetup] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const editablePayrollGroup = usePayrollGroupModalStore((state) => state.editablePayrollGroup);
  const additionalAction = usePayrollGroupModalStore((state) => state.additionalAction);
  const calendarsAdditionalAction = useCurrentPayrollGroupsStore((state) => state.additionalAction);


  const { observingOrganization } = useCurrentOrganization();
  const { handleAddPermanentSnackbar } = useSnackbar();

  const fields = useAddPayrollGroupFields({
    [PayrollGroupFormFields.GROUP_NAME]: editablePayrollGroup?.name,
    [PayrollGroupFormFields.GROUP_CODE]: editablePayrollGroup?.code,
  });

  const { save, isLoading } = useCreatePayrollGroupQuery();
  const { update, isLoading: isUpdateLoading } = useUpdatePayrollGroupQuery();

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((prev: any) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  }, []);
  
  const handleChangeContributionValues = useCallback((values: object) => {
    changeValues(values, fields, setInfoSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    if (!infoSetup) return;
    try {
      if (editablePayrollGroup) {
        const updatePayload = {
          id: editablePayrollGroup?.id,
          name: formValues.groupName,
        };
        await update(updatePayload);
        if (onCancel) onCancel();
        handleAddPermanentSnackbar({
          text: `${formValues[PayrollGroupFormFields.GROUP_NAME]} was successfully updated!`,
          closeIcon: true,
          state: SnackbarState.positive,
        });
        if (additionalAction) additionalAction();
        if (calendarsAdditionalAction) calendarsAdditionalAction();
        return;
      }
      const payload = {
        name: formValues.groupName,
        code: formValues.groupCode,
        organization_id: observingOrganization.id,
        organization_path: observingOrganization.path,
      };
      await save(payload);
      if (onCancel) onCancel();
      handleAddPermanentSnackbar({
        text: `${formValues[PayrollGroupFormFields.GROUP_NAME]} was successfully created!`,
        closeIcon: true,
        state: SnackbarState.positive,
      });
      if (additionalAction) additionalAction();
    } catch {
      setIsServerError(true);
    }
  }, [
    formValues,
    infoSetup,
    observingOrganization,
    save,
    additionalAction,
    onCancel,
    handleAddPermanentSnackbar,
    update,
    editablePayrollGroup,
    calendarsAdditionalAction,
  ]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <>
      <ErrorModal
        testId="add_payroll_group_server-error"
        visible={isServerError}
        header={SERVER_ERROR_MODAL_TITLE}
        helpText={SERVER_ERROR_MODAL_TEXT}
        buttonText="Close"
        onSetVisible={setIsServerError}
      />
      <Box
        pad="spacing24"
        round="moduleRound"
        background={{ color: 'module' }}
      >
        <Box data-testid="AddPayrollGroupForm_container" round="container1Round" background="canvas">
          <FlexControlledForm
            fields={fields}
            editMode
            showError={isSubmit}
            onChangeValues={handleChangeContributionValues}
            isModalType
          />
        </Box>
      </Box>

      <Box
        direction="row"
        margin={{ top: 'spacing24' }}
        gap="spacing12"
        alignSelf="end"
      >
        <Box width={BUTTON_WIDTH}>
          <AppButton
            buttonType="secondary"
            onClick={handleCancel}
            width="100%"
          >
            Cancel
          </AppButton>
        </Box>
        <Box width={BUTTON_WIDTH}>
          <AppButton
            onClick={handleSubmit}
            width="100%"
            disabled={isLoading}
          >
            {isLoading || isUpdateLoading ? (
              <Preloader />
            ) : 'Submit'}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};
