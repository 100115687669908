 
import { useCallback, useMemo } from 'react';
import { capitalizeFirstLetter,Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { PermissionKey,REQUIRED_TEXT } from '@/common/constants';
import { AccountUsageValues, daysOfWeek,FrequencyOptions } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { BankMethod } from '@/modules/employer/hooks/useBankMethods.query';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

const CUSTOM_DAY_FIELD_HINT = 'If a month has fewer days than the entered number, replenishment will be processed on the last day of this month.';
const CUSTOM_DAY_ERROR_TEXT = 'This number does not match the range of available numbers (1-31)';

const placeholder = 'Select bank account';
const weeklyOptions = Object.keys(daysOfWeek);

const useFundingReplenishmentFields = (
  banks: BankInfo[],
  methods: BankMethod[],
  formattedData: EmployerDto,
  currentValues?: AccountUsageValues,
  activateMode?: boolean,
  isLoading?: boolean,
): Field[] => {
  const replenishmentDayNumberPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.REPLENISHMENT_DAY_NUMBER,
  );
  const replenishmentFrequencyTypePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.REPLENISHMENT_FREQUENCY_TYPE,
  );
  const externalReplenishmentAccountMethodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EXTERNAL_REPLENISHMENT_ACCOUNT_METHOD,
  );
  const { viewMode } = useSetupEmployerMode();

  const getDefaultValue = useCallback((value?: string) => {
    const bankId = banks.find((bank) => bank.id === value)?.id;

    if (bankId) return bankId;

    // if (formattedData?.normalProperties?.externalAccountEnable) return NON_ASSIGNABLE_VALUE;

    return '';
  }, [banks]);

  const getDayOfWeek = useCallback((numberOfDay?: number) => (numberOfDay ? weeklyOptions[numberOfDay - 1] : ''), []);
  const replenishmentDayNumber = formattedData?.overridableProperties?.replenishmentDayNumber;

  const externalFundingReplenishment = useMemo(
    () => currentValues?.fundingReplenishmentBank,
    [currentValues],
  );

  const frequencyType = useMemo(
    () => currentValues?.replenishmentFrequencyType,
    [currentValues],
  );

  const frequencyTypeFromResponse = useMemo(
    () => formattedData?.overridableProperties?.replenishmentFrequencyType,
    [formattedData],
  );

  const renderFrequencyDayField = useMemo(() => (
    frequencyType === FrequencyOptions.WEEKLY ? ({
      name: 'replenishmentDayOfWeek',
      label: 'Day of the week',
      type: FieldTypes.Dropdown,
      singleMode: true,
      showRequireIcon: true,
      hasHorizontalLine: true,
      placeholder: 'Select a day',
      defaultValue: frequencyTypeFromResponse === FrequencyOptions.WEEKLY
        ? getDayOfWeek(replenishmentDayNumber) : weeklyOptions[3],
      value: frequencyTypeFromResponse === FrequencyOptions.WEEKLY
        ? getDayOfWeek(replenishmentDayNumber) : weeklyOptions[3],
      options: weeklyOptions.map((option: string) => ({
        key: option,
        value: capitalizeFirstLetter(option),
      })),
      ...replenishmentDayNumberPermProps,
    }) : ({
      name: 'replenishmentCustomDay',
      label: 'Custom',
      type: FieldTypes.Number,
      lowerHelpText: CUSTOM_DAY_FIELD_HINT,
      hasHorizontalLine: true,
      placeholder: 'Enter custom day number',
      defaultValue: frequencyTypeFromResponse === FrequencyOptions.MONTHLY
        ? replenishmentDayNumber : '',
      value: frequencyTypeFromResponse === FrequencyOptions.MONTHLY
        ? replenishmentDayNumber : '',
      validator: yup.string().test('range', CUSTOM_DAY_ERROR_TEXT, (val) => {
        const customDayNumber = _.toNumber(val);
        return customDayNumber > 0 && customDayNumber <= 31;
      }).required(REQUIRED_TEXT),
      ...replenishmentDayNumberPermProps,
    })
  ), [
    frequencyType,
    getDayOfWeek,
    frequencyTypeFromResponse,
    replenishmentDayNumber,
    replenishmentDayNumberPermProps,
  ]);

  const isNeedToShowFrequencyDayField = useMemo(() => frequencyType
  && frequencyType !== FrequencyOptions.DAILY
  && externalFundingReplenishment,
  [frequencyType, externalFundingReplenishment]);

  return useMemo(() => {
    if (isLoading) return [];

    const options = banks.map((bank) => ({
      key: bank.id,
      value: bank.description,
    }));

    return [
      {
        name: 'fundingReplenishmentBank',
        type: FieldTypes.Dropdown,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        placeholder,
        singleMode: true,
        label: 'Bank account',
        options,
        subFields: [
          {
            name: 'fundingReplenishmentMethod',
            type: FieldTypes.Radio,
            disabled: viewMode,
            parentValueFn: (val) => !!val,
            label: 'Method',
            validator: yup.string().test({
              test: (val) => !!(val || formattedData?.overridableProperties?.externalReplenishmentAccountMethod),
              message: REQUIRED_TEXT,
            }),
            options: methods.map((option) => ({
              label: option.description,
              value: option.name,
            })),
            value: formattedData?.overridableProperties?.externalReplenishmentAccountMethod,
            showRequireIcon: true,
            ...externalReplenishmentAccountMethodPermProps,
          },
          {
            name: 'replenishmentFrequencyType',
            label: 'Frequency',
            type: FieldTypes.Dropdown,
            showRequireIcon: true,
            singleMode: true,
            placeholder: 'Select option',
            defaultValue: frequencyTypeFromResponse || '',
            value: frequencyTypeFromResponse || '',
            hasHorizontalLine: frequencyType === FrequencyOptions.DAILY,
            options: Object.values(FrequencyOptions).map((option: string) => ({
              key: option,
              value: capitalizeFirstLetter(option),
            })),
            ...replenishmentFrequencyTypePermProps,
          },
        ],
        areSubFieldsIncluded: true,
        defaultValue: getDefaultValue(formattedData?.normalProperties?.externalReplenishmentAccount),
        value: getDefaultValue(formattedData?.normalProperties?.externalReplenishmentAccount),
      },
      ...(isNeedToShowFrequencyDayField ? [renderFrequencyDayField] : []),
    ] as Field[];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banks, viewMode, methods, formattedData, currentValues,
    getDefaultValue, isNeedToShowFrequencyDayField, renderFrequencyDayField, activateMode, isLoading]);
};

export default useFundingReplenishmentFields;
