import React from 'react';
import { Box, Image } from '@common-fe/common-fe';

import CardPlaceholder from '@/assets/placeholders/card.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';


const CardPlaceholderBackground = () => {
  if (whiteLabelingConfig?.images?.cardBackground) {
    return (
      <Box margin={{ right: 's' }}>
        <Image src={whiteLabelingConfig?.images?.cardBackground} alt="card" />
      </Box>
    );
  }
  return (
    <Box >
      <Image style={{minWidth: 480}} src={CardPlaceholder as unknown as string} />
    
    </Box>
  );
};

export default CardPlaceholderBackground;
