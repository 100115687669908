import React, { useEffect, useMemo, useState } from 'react';
import { Box,FlexControlledForm, useDebounce } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import { Option } from '@/common/types';
import ToEditButton from '@/modules/employer/components/SetupEmployer/components/ToEditButton';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerDto } from '@/modules/employer/types';

import { GeneralInformationPayload } from '../SetupEmployer.types';
import { useSaveTypeStore } from '../stores';

import useGeneralInformationFields from './useGeneralInformationFields';

interface Props {
  managers?: Option[];
  businessTypes?: Option[];
  onSubmit?: (value: GeneralInformationPayload) => void;
  onChangeErrors?: (values: object) => void;
  data?: EmployerDto,
  hsaCustodians: Option[];
  onDirty?: (isDirty: boolean) => void;
  isLoading?: boolean;
}
const GeneralInformationForm: React.FC<Props> = ({
  onSubmit,
  managers = [],
  businessTypes = [],
  data,
  hsaCustodians = [],
  onDirty,
  isLoading,
}) => {
  const [formKey, setFormKey] = useState('');
  const { viewMode } = useSetupEmployerMode();
  const isEditRestricted = useMemo(() => {
    return data?.isTerminated || data?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [data?.isTerminated, data?.organizationStatusType]);
  const { pendingStatus, activeStatus, handleResetType } = useSaveTypeStore();
  useEffect(() => {
    if (data?.id) setFormKey(uniqueId());
  }, [data]);
  const fields = useGeneralInformationFields(
    managers,
    businessTypes,
    hsaCustodians,
    !pendingStatus,
    data,
    isLoading,
    formKey,
  );
  const [currentValues, setCurrentValues] = useState<object>({});
  const debouncedValue = useDebounce(currentValues);

  useEffect(() => {
    if (onSubmit) {
      onSubmit(debouncedValue as GeneralInformationPayload);
    }
  }, [debouncedValue, onSubmit]);

  useEffect(() => () => {
    handleResetType();
  }, [handleResetType]);

  if (isLoading) return <FormLoader testId="GeneralInformationForm" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      margin={{ bottom: 'spacing16' }}
      id="general_information"
    >
      <FlexControlledForm
        key={formKey}
        fields={fields}
        showError={pendingStatus || activeStatus}
        editMode={!viewMode && !isEditRestricted}
        formTitle="General information"
        onChangeValues={setCurrentValues}
        onDirty={onDirty}
        isThinMode
      >
        {isEditRestricted ? null : <ToEditButton />}
      </FlexControlledForm>
    </Box>
  );
};
export default GeneralInformationForm;
