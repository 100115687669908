import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { combineClassNames } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useHistory } from '@/modules/core/hooks';

const StylessLink = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  display: inline;
  max-width: 100%;
  &.disabledLink {
    cursor: not-allowed;
    pointer-events: none;
  }
  &:focus {
  outline: none;
  }
`;
interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  to: string;
  testId?: string;
  disabled?: boolean;
}
const Link: React.FC<Props> = ({
  testId, children, to, onClick, disabled,
}) => {
  const { getPath } = useHistory();

  return (
    <StylessLink
      data-testid={testId}
      to={disabled ? '' : getPath(to)}
      className={combineClassNames([
        disabled ? 'disabledLink' : ''])}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </StylessLink>
  );
};
export default Link;
