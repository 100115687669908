import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Box, ExpressSetupDoneIcon } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { ExpressSetup } from '../../ExpressSetup.types';
import { ProgressBarTitle } from '../../styles/ExpressSetupWizard.styles';

interface Props {
  title?: string;
  isEntityExist?: boolean;
  isLastItem?: boolean;
  url?: string;
  isActive?: boolean;
  expressSetupData?: ExpressSetup;
  isDisabled?: boolean;
}

const ProgressBarItem: React.FC<Props> = ({
  title,
  isEntityExist,
  isLastItem,
  url,
  isActive,
  expressSetupData,
  isDisabled,
}) => {
  const { searchQuery } = useHistory();
  return (
    <Box
      data-testId="express-setup-progerss-bar-item"
      margin={{ bottom: isLastItem ? '0' : 's' }}
      direction="row"
    >
      {
        isEntityExist
          ? <ExpressSetupDoneIcon size="24px" color={isDisabled ? 'successBorder' : 'success'} />
          : (
            <Box
              round="50%"
              background="background3"
              style={{ opacity: 0.1 }}
              height="24px"
              width="24px"
            />
          )
      }
      <Box
        style={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        <HashLink
          to={`${ROUTES.EXPRESS_SETUPS_WIZARD_BY_ID(expressSetupData?.orgId)}${searchQuery || ''}${url}`}
          style={{
            textDecoration: 'none',
            pointerEvents: isDisabled ? 'none' : 'auto',
            outline: 'none',
            boxShadow: 'none',
          }}
        >
          <ProgressBarTitle
            color={isEntityExist ? isDisabled ? 'successBorder' : 'success' : isDisabled ? 'textDisabled' : 'textBody'}
            size="16px"
            weight={isActive ? 700 : 400}
          >
            {title}
          </ProgressBarTitle>
        </HashLink>
      </Box>
    </Box>
  );
};

export default ProgressBarItem;
