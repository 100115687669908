import React, { useCallback, useEffect } from 'react';
import { Box, Inscription, Preloader, SearchInput, usePaginationParams } from '@common-fe/common-fe';

import { useEmployeeCardsList } from '@/modules/employer/components/Lockbox/LockboxDetails/hooks';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import useGetMatchedEmployeesQuery from '@/modules/employer/components/Lockbox/queries/useGetMatchedEmployees.query';
import usePagination from '@/utils/hooks/usePagination';

import EmployeesList from './components/EmployeesList';
import LoadNextEmployeesButton from './components/LoadNextEmployeesButton';
import LockboxEmployeesEmptyState from './components/LockboxEmployeesEmptyState';
import TooManyEmployeesState from './components/TooManyEmployeesState';

const MIN_SEARCH_QUERY_LENGTH = 3;

const EmployeesSearch = () => {
  const { currentSearch, searchString, setSearchString } = usePaginationParams();
  const { editableLockbox, setEditableLockbox } = useLockboxDetailsStore();
  const { matchedEmployees, isTooMany, isLoading } = useGetMatchedEmployeesQuery({ searchValue: currentSearch });
  const { fields } = useEmployeeCardsList({ matchedEmployees });
  const { slicedItems, hasNextItems, handleNext, reset } = usePagination({ items: fields, stepSize: 5 });

  const handleSelectEmployeeId = useCallback((employeeId?: string) => {
    const matchedEmployee = matchedEmployees
      ?.find((matchedEmployee) => matchedEmployee?.employeeId === employeeId);

    if (matchedEmployee) {
      setEditableLockbox({
        employeeId,
        accountId: matchedEmployee?.accountId,
        employeeAccountStatus: matchedEmployee?.accountStatus,
      });
    }
  }, [setEditableLockbox, matchedEmployees]);

  useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch]);

  return (
    <Box data-testId="employees-search">
      <Box data-testId="employees-search_header" direction="row" justify="between" align="center">
        <Inscription color="textSecondary" whiteSpace="nowrap">
          Manual search:
        </Inscription>

        <Box margin={{ left: 'spacing12' }} width={{ max: '364px', width: '100%' }}>
          <SearchInput
            testId="employees-search"
            value={searchString}
            onChange={setSearchString}
            placeholder="Search for name, SSN, account number"
          />
        </Box>
      </Box>

      <EmployeesList
        fields={slicedItems}
        selectedEmployeeId={editableLockbox?.employeeId}
        onSelectEmployee={handleSelectEmployeeId}
        {...currentSearch.length >= MIN_SEARCH_QUERY_LENGTH ? {
          emptyState: (
            <Box margin={{ top: 'spacing8' }}>
              {isLoading ? (
                <Preloader testId="employees-search_preloader" />
              ) : (
                <LockboxEmployeesEmptyState title="No Search Results" />
              )}
            </Box>
          )
        }: {}}
      />

      {!!slicedItems?.length && (
        <Box margin={{ top: 'spacing8' }} align="center">
          <LoadNextEmployeesButton
            hasNext={hasNextItems}
            onClick={handleNext}
          />
        </Box>
      )}

      {isTooMany && (
        <Box margin={{ top: 'spacing8' }}>
          <TooManyEmployeesState />
        </Box>
      )}
    </Box>
  );
};

export default EmployeesSearch;
