import { baseStoreCreator } from '@/utils/store';

export interface EnrollmentMatrixPayload {
  enrollmentMatrixName: string;
  processingRuleName: string;
  deductibleService: string;
}

export const DEFAULT_VALUES: EnrollmentMatrixPayload = {
  enrollmentMatrixName: '',
  processingRuleName: '',
  deductibleService: '',
};

export const useStore = baseStoreCreator<EnrollmentMatrixPayload>(DEFAULT_VALUES);

export default useStore;
