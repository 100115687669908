import { EnrollmentStatus } from '@common-fe/common-fe';

import { OrganizationStatus } from '@/modules/employee/Employee/Employee.types';
import currentTheme from '@/styles/theme';

export const getTitle = (type?: OrganizationStatus) => {
  switch (type) {
  case OrganizationStatus.active: {
    return 'Active';
  }
  case OrganizationStatus.terminated: {
    return 'Terminated';
  }
  case OrganizationStatus.retired: {
    return 'Retired';
  }
  case OrganizationStatus.leaveOfAbsence: {
    return 'Leave of absence';
  }
  case OrganizationStatus.future: {
    return 'Future';
  }
  case OrganizationStatus.cobra: {
    return 'COBRA';
  }
  default: {
    return 'Unknown';
  }
  }
};
export const getColor = (type?: OrganizationStatus) => {
  switch (type) {
  case OrganizationStatus.active: {
    return currentTheme.colors.statusColors.paidActiveComplete;
  }
  case OrganizationStatus.terminated: {
    return currentTheme.colors.statusColors.deniedErrorExpired;
  }
  case OrganizationStatus.retired: {
    return currentTheme.colors.statusColors.processingPending;
  }
  case OrganizationStatus.leaveOfAbsence: {
    return currentTheme.colors.iconAccent;
  }
  case OrganizationStatus.future: {
    return currentTheme.colors.button;
  }
  case OrganizationStatus.cobra: {
    return currentTheme.colors.statusColors.cobra;
  }
  default: {
    return currentTheme.colors.statusColors.lockedNull;
  }
  }
};

export const getBorderColor = (type?: OrganizationStatus) => {
  switch (type) {
  case OrganizationStatus.active: {
    return currentTheme.colors.successBorder;
  }
  case OrganizationStatus.terminated: {
    return currentTheme.colors.dangerBorder;
  }
  case OrganizationStatus.retired: {
    return currentTheme.colors.warningBorder;
  }
  case OrganizationStatus.leaveOfAbsence: {
    return currentTheme.colors.infoBorder;
  }
  case OrganizationStatus.future: {
    return currentTheme.colors.infoBorder;
  }
  case OrganizationStatus.cobra: {
    return currentTheme.colors.infoBorder;
  }
  default: {
    return currentTheme.colors.statusColors.lockedNull;
  }
  }
};

export const getEnrollmentStatusColor = (status?: EnrollmentStatus) => {
  switch (status) {
  case EnrollmentStatus.Active: {
    return currentTheme.colors.statusColors.paidActiveComplete;
  }
  case EnrollmentStatus.Canceled: {
    return currentTheme.colors.statusColors.deniedErrorExpired;
  }
  default: {
    return currentTheme.colors.statusColors.lockedNull;
  }
  }
};
