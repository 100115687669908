import { EmploymentStatus, OrganizationStatus } from '@/modules/employee/Employee/Employee.types';
import { baseStoreCreator } from '@/utils/store';

export interface GeneralInfoPayload {
  firstName: string;
  middleName: string | null;
  lastName: string;
  suffix: string | null;
  employeeEmail: string;
  employeeEmailPrefer: boolean;
  employerEmail: string;
  employerEmailPrefer: boolean;

  fullName: string;
  birthDate: string;
  gender: string;

  firstPhoneType: string;
  firstPhone: string;
  secondPhoneType: string;
  secondPhone: string;

  homePhoneNumber: string;
  personId?: string;

  employmentStatuses?: EmploymentStatus[];
  status?: OrganizationStatus;
  customFieldDisplayed1?: boolean;
  customFieldDisplayed2?: boolean;
  customFieldDisplayed3?: boolean;
  customFieldDisplayed4?: boolean;
  customFieldDisplayed5?: boolean;

  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  terminationDate: string;
}

export const DEFAULT_VALUES: GeneralInfoPayload = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employeeEmail: '',
  employeeEmailPrefer: true,
  employerEmail: '',
  employerEmailPrefer: false,
  fullName: '',
  birthDate: '',
  gender: '',
  firstPhoneType: '',
  firstPhone: '',
  secondPhoneType: '',
  secondPhone: '',
  homePhoneNumber: '',
  personId: '',
  employmentStatuses: [],
  status: undefined,
  customField1: '',
  customField2: '',
  customField3: '',
  customField4: '',
  customField5: '',
  terminationDate: '',
};

export const useStore = baseStoreCreator<GeneralInfoPayload>(DEFAULT_VALUES);

export default useStore;
