import React, { useEffect, useState } from 'react';
import {
  Box, FlexControlledForm, useDebounceState,
} from '@common-fe/common-fe';

import { PreferredPhysicalAddressPayload,useStore } from './stores/usePreferredPhysicalAddress.store';
import { usePreferredPhysicalAddressFields } from './hooks';
import { usePreferredPhysicalAdressStore } from './stores';

interface Props {
  isEditMode?: boolean;
  isShowErrors?: boolean;
}
const PreferredPhysicalAddress: React.FC<Props> = ({ isEditMode, isShowErrors }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleSetState = useStore((state) => state.setState);
  const sourceState = useStore((state) => state.sourceState);

  // const currentState = useStore((state) => state.state);
  const fields = usePreferredPhysicalAddressFields(isEditMode);
  const { setValues, values } = useDebounceState<Partial<PreferredPhysicalAddressPayload>>();
  const isSameAsMailing = usePreferredPhysicalAdressStore((state) => state.state.sameAsMailing);
  useEffect(() => {
    if (Boolean(isSameAsMailing) !== isChecked) {
      setIsChecked(Boolean(isSameAsMailing));
    }
  }, [isChecked, setIsChecked, isSameAsMailing]);
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, sourceState, values]);
  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        key={`${isEditMode} ${isChecked}`}
        formTitle="Preferred Physical Address"
        testId="preferred-physical-address"
        fields={fields}
        showError={isShowErrors}
        editMode={isEditMode}
        onChangeValues={setValues}
        // onDirty={onDirty}
        isDisabledMode={!isEditMode}
      />

    </Box>

  );
};

export default PreferredPhysicalAddress;
