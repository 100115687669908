import { OrphanConfigurationPayload } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { baseStoreCreator } from '@/utils/store';



export const DEFAULT_VALUES: OrphanConfigurationPayload = {
  isEmployerOrphan: '',
  startOrphanProcessDays: null,
  orphanEmployer: ''
};

export const useStore = baseStoreCreator<OrphanConfigurationPayload>(DEFAULT_VALUES);

export default useStore;


