import React, { useCallback, useEffect, useState } from 'react';
import { AppButton, Box, LinkButton, Preloader, Text, WarnModal } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import AddEditContactForm from '@/modules/ExpressSetup/AddEditContactForm/AddEditContactForm';
import useContactStore, { ContactPayload } from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import theme from '@/styles/theme';

import { useHandleExpressSetupSetUp } from '../../hooks';
import { EXPRESS_SETUP_WIZARD_CONTENT_WIDTH } from '../ExpressSetupWizard';

interface Props {
  title?: string;
  contact?: ContactPayload;
  isEditMode?: boolean;
  orgId?: string;
  onEditToggle: (value: boolean) => void;
}

const MainContactWizard: React.FC<Props> = ({
  title,
  contact,
  isEditMode,
  orgId,
  onEditToggle,
}) => {
  const history = useHistory();
  // const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const { onSaveContactsHandler, isLoading, isContactServerError } = useHandleExpressSetupSetUp(onEditToggle);
  const [formKey, setFormKey] = useState('');
  const handleSetShowErrors = useContactStore((state) => state.setShowErrors); 
  const handleSetSourceState = useContactStore((state) => state.setSourceState); 
  const contactState = useContactStore((state) => state.state); 

  const handleOpenContactModal = useCallback((path: string) => {
    history.push(path, {
      shouldOpenModal: true,
      ...Object.fromEntries(new URLSearchParams(history.searchQuery)),
    });
  }, [history]);

  const handleSaveContact = useCallback(() => {
    if (contact) {
      onSaveContactsHandler(true);
    } else {
      onSaveContactsHandler(false, true);
    }
  }, [contact, onSaveContactsHandler]);

  // We need this logic, to set values from state when we change wizzard steps
  useEffect(() => {
    if (contactState && !contact) {
      handleSetSourceState(contactState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => () => handleSetShowErrors(false), [handleSetShowErrors]);
  useEffect(() => {
    if (isContactServerError) setErrorModal(true);
  }, [isContactServerError]);

  return (
    <>
      <Box
        style={{ maxWidth: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH }}
        data-testId="main-contact-wizard" 
      >
        <Text weight={700} size="32px" color="textTitle" margin={{ bottom: 'xs' }}>{title}</Text>
        <Text color="textBody" margin={{ bottom: 'l' }}>Main contact is the employer representer, responsible person for employer creation and activation. They have access to all Express Setup steps. They can create additional contacts if needed.</Text>
        <Box background="module" pad="medium" round="container1Round" data-testid="Contact-form-wrapper">
          <AddEditContactForm key={formKey} isMainContactMode currentContact={contact} isEditMode={isEditMode} />
        </Box>
        {
          contact ? (
            <Box direction="row" justify="between" margin={{ top: 'l' }}>
              {
                isEditMode ? (
                  <>
                    <AppButton
                      buttonType="secondary"
                      color="blue"
                      onClick={() => {
                        onEditToggle(false);
                        if (contact) handleSetSourceState(contact);
                        setFormKey(uniqueId());
                      }}
                      data-testid="cancel-button"
                      width="312px"
                      style={{ marginRight: theme.spacings.s, height: '54px' }}
                    >
                      Cancel
                    </AppButton>
                    <AppButton
                      onClick={handleSaveContact}
                      testId="edit"
                      disabled={isLoading}
                      style={{ width: '312px', height: '54px' }}
                    >
                      {isLoading ? <Preloader color="white" /> : 'Save'}
                    </AppButton>
                  </>
                ) : (
                  <AppButton
                    onClick={() => onEditToggle(true)}
                    testId="edit"
                    disabled={isLoading}
                    style={{ width: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH, height: '54px' }}
                  >
                    Edit Main Contact
                  </AppButton>
                )
              }
            </Box>
          ) : (
            <AppButton
              onClick={handleSaveContact}
              testId="submit"
              disabled={isLoading}
              style={{ marginTop: theme.spacings.l, width: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH, height: '54px' }}
            >
              {isLoading ? <Preloader color="white" /> : 'Submit'}
            </AppButton>
          )
        }
        {
          contact && (
            <Box direction="row" margin={{ top: 'l' }} align="center">
              <Text color="textBody" margin={{ right: 'xxxs'}}>
                Main contact has been added. Would you like to add any other contacts now?
              </Text>
              <LinkButton onClick={() => handleOpenContactModal(ROUTES.EXPRESS_SETUP_BY_ID(orgId))} linkInlineMode>
                Add Contact
              </LinkButton>
            </Box>
          )
        }
      </Box>
      {/* <WarnModal
        testId="main-contact-wizard-delete-modal"
        visible={isDeleteModalVisible}
        header={`Remove ${contact?.contactName || ''} (main contact)?`}
        helptext="Please note that the organization cannot be activated without a main contact."
        onSetVisible={setIsDeleteModalVisible}
        cancelButtonNode={(
          <Box direction="row">
            <Box width="150px" margin={{ right: 'spacing6' }}>
              <AppButton
                buttonType="secondary"
                onClick={() => setIsDeleteModalVisible(false)}
                width="100%"
              >
                Cancel
              </AppButton>
            </Box>
            <Box width="150px" margin={{ left: 'spacing6' }}>
              <AppButton
                color="red"
                onClick={handleRemoveContact}
                width="100%"
                disabled={isLoading}
              >
                {isLoading
                  ? <Preloader color="white" />
                  : 'Yes, Remove'}
              </AppButton>
            </Box>
          </Box>
        )}
      /> */}
      <WarnModal
        testId="main-contact-wizzard-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot ${contact ? 'update' : 'create'} the Main Contact with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSaveContact}
        submitButtonText="Try again"
      />
    </>
  );
};

export default MainContactWizard;
