import { emailValidation,validatePhoneNumber,validateUrl } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import {
  DeductibleType,
  MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
  PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
} from '@/modules/deductibles/deductibles.types';
import { TierField } from '@/modules/ProcessingDefinition/ProcessingRule/CreateProcessingRuleModal/hooks/useTiers';

import { DEFAULT_DATE_FORMAT, MIN_DATE, PHONE_NUMBER_INVALID_TEXT,REQUIRED_TEXT } from './constants';
import REGEXPS from './regexp';

export const DATE_SEPARATOR = '/';
export const SEPARATOR_COUNT = 3;
export const MIN_YEAR_COUNT = 4;
export const WRONG_DATE_TEXT = 'Date format should be MM/DD/YYYY';
const MIN_DATE_ERROR_TEXT = `The date can't be earlier than ${MIN_DATE}`;
const FUTURE_DATE_TEXT = 'Date cannot be in the future';
const NA_KEY = 'null';
export const FORMAT_VALIDATOR = (val: string) => {
  if (!val) {
    return true;
  }

  const count = val.split(DATE_SEPARATOR).length;
  const isYearExist = val.split(DATE_SEPARATOR).reverse()[0].length >= MIN_YEAR_COUNT;
  if (
    count === SEPARATOR_COUNT
        && isYearExist
        && dayjs(val, DEFAULT_DATE_FORMAT, true).isValid()
  ) {
    return true;
  }
  return false;
};
const TODAY = dayjs().toDate();
const DATE_FORMAT = yup.string().trim().test({
  test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
  message: WRONG_DATE_TEXT,
});
const VALIDATORS = {
  PHONE_NUMBER_VALIDATE: yup
    .string()
    .test(validatePhoneNumber(PHONE_NUMBER_INVALID_TEXT)),
  REQUIRED_STRING: yup.string().required(REQUIRED_TEXT),
  STRING: yup.string().nullable(),
  URL: yup.string().test(validateUrl()),
  URL_REQUIRED: yup.string().test(validateUrl()).required(REQUIRED_TEXT),
  EMAIL: emailValidation,
  ARRAY_REQUIRED: yup.array().required(REQUIRED_TEXT),
  EMAIL_REQUIRED: emailValidation.required(REQUIRED_TEXT),
  DATE_FORMAT,
  DATE_FORMAT_BEFORE_OTHER_DATE: (
    otherKey: string,
    text: string,
    isNotParentDate?: boolean,
    notIncludeTodayDate?: boolean,
  ) => yup
    .string()
    .trim()
    .test({
      exclusive: false,
      test(val) {
        const startDate = isNotParentDate ? otherKey : this.parent[otherKey];
        const endDate = val;
        if (startDate && endDate) {
          if (notIncludeTodayDate) {
            return dayjs(startDate).valueOf() >= dayjs(endDate).valueOf();
          }
          return dayjs(startDate).valueOf() > dayjs(endDate).valueOf();
        }
        return true;
      },
      message: text,
    })

    .test({
      test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
      message: WRONG_DATE_TEXT,
    }),
  DATE_FORMAT_AFTER_OTHER_DATE: (
    otherKey: string,
    text: string,
    isNotParentDate?: boolean,
    notIncludeTodayDate?: boolean,
  ) => yup
    .string()
    .trim()
    .test({
      exclusive: false,
      test(val) {
        if (!val) {
          return true;
        }
        const startDate = isNotParentDate ? otherKey : _.get(this, `parent[${otherKey}]`);

        const endDate = val;
        if (startDate && endDate) {
          if (notIncludeTodayDate) {
            return dayjs(startDate).valueOf() <= dayjs(endDate).valueOf();
          }
          return dayjs(startDate).valueOf() < dayjs(endDate).valueOf();
        }
        return true;
      },
      message: text,
    })

    .test({
      test: FORMAT_VALIDATOR as yup.TestFunction<string | undefined>,
      message: WRONG_DATE_TEXT,
    }),
  DATE_FORMAT_MAX_TODAY: yup.string().trim().test({
    test(val) {
      if (!val) {
        return true;
      }

      const currentDate = dayjs(val).toDate();
      if (currentDate <= TODAY) {
        return true;
      }
      return false;
    },
    message: FUTURE_DATE_TEXT,
  }).test({
    test(val) {
      if (!val) {
        return true;
      }

      const currentDate = dayjs(val).valueOf();
      const minDate = dayjs(MIN_DATE).valueOf();
      if (currentDate <= minDate) {
        return false;
      }
      return true;
    },
    message: MIN_DATE_ERROR_TEXT,
  }).test({
    test(val) {
      if (!val) {
        return true;
      }

      const count = val.split(DATE_SEPARATOR).length;
      const isYearExist = val.split(DATE_SEPARATOR).reverse()[0].length >= MIN_YEAR_COUNT;
      if (
        count === SEPARATOR_COUNT
        && isYearExist
        && dayjs(val, DEFAULT_DATE_FORMAT, true).isValid()
      ) {
        return true;
      }
      return false;
    },
    message: WRONG_DATE_TEXT,
  }),
  REQUIRED_POSITIVE_NUMBER_ONLY: yup.string().required(REQUIRED_TEXT).test({
    test(val) {
      if (!val || +val > 0) {
        return true;
      }
      return false;
    },
    message: 'Should be a positive number',
  }),
  ONLY_LATIN: yup.string().matches(
    REGEXPS.ONLY_LATIN_AND_NUMBER,
    'Can only contain Latin letters',
  ),
  REQUIRED_ARRAY: yup.array().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
  ARRAY: yup.array().nullable(),
  TRIGGER_NOT_LAST_ITEM: (tiers: TierField[]) => yup
    .array().test({
      test: (value) => {
        if (value) {
          if (value.includes(NA_KEY)) {
            return false;
          }
        }
        return true;
      },
      message: 'Trigger must be set if there is next tier specified.',
    }).test({
      test: (value) => {
        const allTriggers = tiers
          ?.map((item) => item?.triggers || []).flat();
        const updatedValues = allTriggers.map((key) => {
          if (key === MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
            return [DeductibleType.Member, DeductibleType.Embedded];
          }
          if (key === PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
            return [DeductibleType.Plan, DeductibleType.Embedded];
          }
          return key;
        }).flat();

        if (value && value[0]) {
          let selectedValues: string[];
          if (value[0] === MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
            selectedValues = [DeductibleType.Member, DeductibleType.Embedded];
          } else if (value[0] === PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
            selectedValues = [DeductibleType.Plan, DeductibleType.Embedded];
          } else {
            selectedValues = [value[0]];
          }
          let foundMultipleMatches = false;

          updatedValues.forEach((item) => {
            if (item !== NA_KEY && selectedValues.includes(item)) {
              const count = updatedValues.filter((key) => key === item).length;
              if (count > 1) {
                foundMultipleMatches = true;
              }
            }
          });

          if (foundMultipleMatches) {
            return false;
          }
        }
        return true;
      },
      message: 'This trigger is already used',
    })
    .min(1, REQUIRED_TEXT)
    .required(REQUIRED_TEXT),
};

export default VALIDATORS;
