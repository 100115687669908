/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { Field, FieldTypes, validatePhoneNumber, validateUrl } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import { NO_KEY, OrganizationTypes, PermissionKey, YES_KEY } from '@/common/constants';
import { Option } from '@/common/types';
import { useStore as useErrorStore } from '@/modules/employer/components/SetupEmployer/stores/useServerErrors.store';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import { booleanToYesOrNo } from '@/utils/handlers';

import { useSetupEmployerMode } from '../hooks';

export const FIELD_KEY = {
  DBA: 'dba',
  TAX_ID: 'taxID',
  PHONE_NUMBER: 'phoneNumber',
  PHONE_NUMBER_EXTENSION: 'phoneNumberExtension',
  BUSINESS_TYPE: 'businessType',
  ACCOUNT_MANAGER_ID: 'accountManagerId',
  IMPLEMENTATION_MANAGER_ID: 'implementationManagerId',
  THEME_ID: 'themeId',
  HSA_CUSTODIAN_ID: 'hsaCustodianId',
  URL: 'url',
  SSO_ONLY: 'ssoOnly',
  TPA: 'tpa',
  ALLOW_EDIT_ADDRESS: 'allowEditAddress'
  // BROKER: 'broker',
};

const DBA_MAX_LENGTH = 26;
const DBA_LENGTH_MESSAGE = `DBA must have ${DBA_MAX_LENGTH} characters or less`;

export const SAVE_VALIDATORS = {
  [FIELD_KEY.DBA]: yup.string().trim().max(DBA_MAX_LENGTH, DBA_LENGTH_MESSAGE),
  [FIELD_KEY.TAX_ID]: yup
    .string()
    .test('len', 'Must be exactly 9 characters', (val) => !val || val.length === 9),
  [FIELD_KEY.PHONE_NUMBER]: yup.string().test(validatePhoneNumber()),
  [FIELD_KEY.PHONE_NUMBER_EXTENSION]: yup.string(),
  [FIELD_KEY.BUSINESS_TYPE]: yup.string().nullable(),
  // [FIELDS.BROKER]: yup.string(),
  [FIELD_KEY.ACCOUNT_MANAGER_ID]: yup
    .string()
    .trim()
    .test(
      'managerId',
      'One contact cannot be assigned as both account manager and implementation manager.',
      (val, { parent }) => !val || parent[FIELD_KEY.IMPLEMENTATION_MANAGER_ID] !== val
    ),
  [FIELD_KEY.IMPLEMENTATION_MANAGER_ID]: yup.string().when(FIELD_KEY.ACCOUNT_MANAGER_ID, {
    is: (val: string) => !!val,
    then: yup
      .string()
      .trim()
      .test(
        'managerId',
        'One contact cannot be assigned as both account manager and implementation manager.',
        (val, { parent }) => parent[FIELD_KEY.ACCOUNT_MANAGER_ID] !== val
      ),
  }),
  [FIELD_KEY.HSA_CUSTODIAN_ID]: yup.string(),
  [FIELD_KEY.URL]: yup.string().test(validateUrl()),
  [FIELD_KEY.SSO_ONLY]: yup.boolean(),
};

const getDefaultHsaCustodiansValue = (hsaCustodians: Option[], value?: string) => {
  if (!value) {
    return '';
  }

  return (
    hsaCustodians.find((hsaCustodianId) => `${hsaCustodianId.value}`.toLowerCase() === value.toLowerCase())
      ?.key || ''
  );
};

const useGeneralInformationFields = (
  managers: Option[],
  businessTypes: Option[],
  hsaCustodians: Option[],
  activateMode?: boolean,
  data?: EmployerDto,
  isLoading?: boolean,
  triggeredId?: string
) => {
  const urlPermProps = useGetPermissionToEditOverridableField(PermissionKey.URL);
  const hsaCustodianIdPermProps = useGetPermissionToEditOverridableField(PermissionKey.HSA_CUSTODIAN_TYPE);
  const ssoOnlyPermProps = useGetPermissionToEditOverridableField(PermissionKey.SSO_ONLY);

  const isSubsidiary = useMemo(() => data?.type === OrganizationTypes.subsidiary, [data]);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState('');

  const generalInfo = useStore((state) => state.generalInfo);
  const errorsState = useErrorStore((state) => state.state);
  const phoneError = useMemo(() => {
    const formatedError = _.get(errorsState, 'organization_phone.number', '');
    return formatedError;
  }, [errorsState]);

  useEffect(() => {
    if (phoneError) {
      setInvalidPhoneNumber(generalInfo?.phoneNumber || '');
    }
  }, [phoneError]);
  const { employerID } = useSetupEmployerMode();
  const fields = useMemo(() => {
    if (isLoading) return [];

    const phoneNumber = (data?.organizationPhone?.number || '').replace(REGEXPS.EXCLUDE_NUMBERS, '');
    const phoneExtension = data?.organizationPhone?.phoneExtension || '';
    return [
      {
        name: FIELD_KEY.DBA,
        type: FieldTypes.Text,
        label: 'Legal name',
        placeholder: 'Legal name',
        validator: yup.string().trim().max(DBA_MAX_LENGTH, DBA_LENGTH_MESSAGE),
        value: data?.shortName || '', // generalInfo?.dba,
        defaultValue: data?.shortName || '', // generalInfo?.dba,
      },
      {
        name: FIELD_KEY.TAX_ID,
        type: FieldTypes.Mask,
        mask: '999999999',
        label: 'Tax ID (EIN)',
        placeholder: 'Enter Tax ID (EIN)',
        validator: yup
          .string()
          .test('len', 'Must be exactly 9 characters', (val) => !val || val.length === 9),
        // showRequireIcon: true,
        // @ts-ignore: Unreachable code error
        value: data?.normalProperties?.taxId || '', // generalInfo?.taxID,
        defaultValue: data?.normalProperties?.taxId || '', // generalInfo?.taxID,
      },
      {
        name: FIELD_KEY.PHONE_NUMBER,
        type: FieldTypes.PhoneNumber,
        label: 'Company contact phone number',
        placeholder: '+1 (555) 555-1234',
        validator: yup.string().test(validatePhoneNumber()),
        value: phoneNumber,
        defaultValue: phoneNumber,
        subFields: [
          {
            name: FIELD_KEY.PHONE_NUMBER_EXTENSION,
            type: FieldTypes.Text,
            parentValueFn: (val: string) => !!val && validatePhoneNumber().test(`${val || ''}`),
            label: 'Phone extension',
            placeholder: 'Enter phone extension',
            validator: yup.string().trim().nullable(),
            value: phoneExtension,
            defaultValue: phoneExtension,
          },
        ],
      },
      {
        name: FIELD_KEY.BUSINESS_TYPE,
        type: FieldTypes.AutocompleteDropdown,
        label: 'Business entity types',
        placeholder: 'Select Business entity types',
        singleMode: true,

        validator: yup.string().nullable(),
        options: businessTypes.map((businessType) => ({
          ...businessType,
          title: businessType?.value?.toString(),
        })),
        value: data?.businessEntityType, // generalInfo?.businessType,
        defaultValue: data?.businessEntityType, // generalInfo?.businessType,
      },
      // // {
      // //   name: FIELDS.BROKER,
      // //   type: FieldTypes.Text,
      // //   label: 'Broker',
      // //   placeholder: 'Enter a broker',
      // //   validator: yup.string(),
      // //   value: generalInfo?.broker,
      // //   defaultValue: generalInfo?.broker,
      // // },
      {
        name: FIELD_KEY.ACCOUNT_MANAGER_ID,
        type: FieldTypes.AutocompleteDropdown,
        label: 'Account manager',
        placeholder: 'Select an account manager',
        validator: SAVE_VALIDATORS[FIELD_KEY.ACCOUNT_MANAGER_ID],
        // optional: true,
        options: managers.map((manager) => ({
          ...manager,
          title: manager?.value?.toString(),
        })),

        value: data?.normalProperties?.accountManagerId || '',
        defaultValue: data?.normalProperties?.accountManagerId || '',
      },
      {
        name: FIELD_KEY.IMPLEMENTATION_MANAGER_ID,
        type: FieldTypes.AutocompleteDropdown,
        label: 'Implementation manager',
        placeholder: 'Select implementation manager',
        validator: SAVE_VALIDATORS[FIELD_KEY.IMPLEMENTATION_MANAGER_ID],
        // optional: true,
        options: managers.map((manager) => ({
          ...manager,
          title: manager?.value?.toString(),
        })),

        value: data?.normalProperties?.implementationManagerId || '',
        defaultValue: data?.normalProperties?.implementationManagerId || '',
      },
      {
        name: FIELD_KEY.HSA_CUSTODIAN_ID,
        type: FieldTypes.AutocompleteDropdown,
        label: 'HSA custodian',
        placeholder: 'Select HSA custodian',

        validator: yup.string(),
        optional: true,
        options: hsaCustodians.map((hsaCustodian) => ({
          ...hsaCustodian,
          title: hsaCustodian?.value?.toString(),
        })),
        value: getDefaultHsaCustodiansValue(hsaCustodians, data?.overridableProperties?.hsaCustodianId),
        defaultValue: getDefaultHsaCustodiansValue(
          hsaCustodians,
          data?.overridableProperties?.hsaCustodianId
        ),
        ...hsaCustodianIdPermProps,
      },
      {
        name: FIELD_KEY.URL,
        type: FieldTypes.Text,
        label: 'URL',
        placeholder: 'URL',
        validator: VALIDATORS.STRING,
        value: data?.overridableProperties?.url || '',
        defaultValue: data?.overridableProperties?.url || '',
        ...urlPermProps,
      },
      {
        name: FIELD_KEY.SSO_ONLY,
        type: FieldTypes.Checkbox,
        testId: 'sso-only',
        label: 'SSO only',
        disabled: isSubsidiary,
        validator: yup.boolean().nullable(),
        value: data?.overridableProperties?.ssoOnly || false,
        defaultValue: data?.overridableProperties?.ssoOnly || false,
        ...ssoOnlyPermProps,
      },
      {
        name: FIELD_KEY.ALLOW_EDIT_ADDRESS,
        type: FieldTypes.Radio,
        testId: 'allowEditAddress',
        label: 'Allow employees to edit address',
        validator: VALIDATORS.STRING,
        options: [
          {
            label: 'Yes',
            value: YES_KEY,
          },
          {
            label: 'No',
            value: NO_KEY,
          },
        ],
        value: booleanToYesOrNo(data?.overridableProperties?.allowEmployeesEditAddress) || NO_KEY,
        defaultValue: booleanToYesOrNo(data?.overridableProperties?.allowEmployeesEditAddress) || NO_KEY,
      },
    ] as Field[];
  }, [
    invalidPhoneNumber,
    managers,
    businessTypes,
    isLoading,
    employerID,
    generalInfo?.hsaCustodianId,
    isSubsidiary,
    triggeredId,
  ]);
  const formatedFields = useMemo(() => {
    const list = [...fields];
    if (!activateMode) {
      return list.map((field) => {
        const validator = SAVE_VALIDATORS[field.name];
        return {
          ...field,
          validator,
        };
      });
    }
    return list;
  }, [activateMode, fields, triggeredId]);
  return formatedFields;
};

export default useGeneralInformationFields;
