import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useAccountFundingFieldsStateSetter, { AccountFundingFieldsStatePayload } from '../AccountFunding/hooks/useAccountFundingFieldsStateSetter';
import useCoverageFieldsStateSetter, { CoverageFieldsStatePayload } from '../Coverage/hooks/useCoverageFieldsStateSetter';
import usePlanDefinitionFieldsStateSetter, { PlanDefinitionFieldsStatePayload } from '../PlanDefinition/hooks/usePlanDefinitionFieldsStateSetter';

export type PlanPayload = PlanDefinitionFieldsStatePayload
& AccountFundingFieldsStatePayload & CoverageFieldsStatePayload;

const usePlanSetupFieldsSetter = () => {
  const {
    handleSetState: handleSetPlanDefinition,
    handleSetSourceState: handleSetSourcePlanDefinition,
    handleReset: handleResetPlanDefinition,
    handleSetErrors: handleSetPlanDefinitionErorrs,
    handleSetErrorFields: handleSetPlanDefinitionErrorFields,
  } = usePlanDefinitionFieldsStateSetter();

  const {
    handleSetState: handleSetCoverage,
    handleSetSourceState: handleSetSourceCoverage,
    handleReset: handleResetCoverage,
    handleSetErrors: handleSetCoverageErorrs,
    handleSetErrorFields: handleSetCoverageErrorFields,
  } = useCoverageFieldsStateSetter();

  const {
    handleSetState: handleSetAccountFundingState,
    handleSetSourceState: handleSetSourceAccountFundingState,
    handleReset: handleResetAccountFunding,
    handleSetErrors: handleSetAccountFundingErrors,
    handleSetErrorFields: handleSetAccountFundingErrorFields,
  } = useAccountFundingFieldsStateSetter();

  const handleReset = useCallback(() => {
    handleResetPlanDefinition();
    handleResetCoverage();
    handleResetAccountFunding();
  }, [handleResetAccountFunding, handleResetCoverage, handleResetPlanDefinition]);

  const handleSetState = useCallback((values: Partial<PlanPayload>) => {
    handleSetCoverage(values);
    handleSetPlanDefinition(values);
    handleSetAccountFundingState(values);
  }, [handleSetAccountFundingState, handleSetCoverage, handleSetPlanDefinition]);

  const handleSetSourceState = useCallback((values: Partial<PlanPayload>) => {
    handleSetSourceCoverage(values);
    handleSetSourcePlanDefinition(values);
    handleSetSourceAccountFundingState(values);
  }, [handleSetSourceAccountFundingState, handleSetSourceCoverage, handleSetSourcePlanDefinition]);

  const handleSetErrors = useCallback((values: Partial<PlanPayload>) => {
    handleSetPlanDefinitionErorrs(values);
    handleSetCoverageErorrs(values);
    handleSetAccountFundingErrors(values);
  }, [
    handleSetAccountFundingErrors,
    handleSetCoverageErorrs,
    handleSetPlanDefinitionErorrs,
  ]);
  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<PlanPayload>>) => {
    handleSetPlanDefinitionErrorFields(values);
    handleSetCoverageErrorFields(values);
    handleSetAccountFundingErrorFields(values);
  }, [
    handleSetAccountFundingErrorFields,
    handleSetCoverageErrorFields,
    handleSetPlanDefinitionErrorFields,
  ]);
  return {
    handleSetSourceState,
    handleSetErrorFields,
    handleSetErrors,
    handleReset,
    handleSetState,
  };
};

export default usePlanSetupFieldsSetter;
