import React, { useCallback, useMemo,useState } from 'react';
import {
  AppButton,
  Box,   FlexControlledForm,
  Preloader,
  SiblingBoxesWithSpacing, } from '@common-fe/common-fe';
import _ from 'lodash';

import { PlanStatuses } from '@/modules/plan/plan.types';
import useUpdatePlanNameById from '@/modules/plan/PlanSetup/hooks/useUpdatePlanNameById';
import usePlanDefinitionSetter from '@/modules/plan/PlanSetup/PlanDefinition/hooks/usePlanDefinitionSetter';

import usePlanEditModalFields from './hooks/usePlanEditModalFields';

interface Props {
  defaultValues?: {
    planType: string;
    template: string;
  };
  toCancel?: () => void;
  onRefetchList?: () => void;
  loading?: boolean;
  selectedPlan?: {
    id: string;
    planYearId?: string;
    planStatus?: PlanStatuses;
    planTemplate?: string;
  };
  onDirty: (isDirty: boolean) => void;
}

interface ValuesPayload {
  planName?: string;
  planTemplate?: string;
  planYearId?: string;
}

const PlanEditFormModal: React.FC<Props> = ({
  toCancel, onRefetchList, selectedPlan, loading, onDirty,
}) => {
  const [currentValues, setCurrentValues] = useState<ValuesPayload>({});
  const { handleSetState, handleReset } = usePlanDefinitionSetter();
  const fields = usePlanEditModalFields(selectedPlan);

  const handleSavePlan = useUpdatePlanNameById(selectedPlan?.id || '');

  const isFillOut = useMemo(
    () => !!currentValues.planName,
    [currentValues.planName],
  );

  const handleChangeValues = useCallback(

    (values: ValuesPayload) => {
      setCurrentValues(values);
      handleSetState(values);
    },
    [handleSetState],
  );

  const handleCancel = useCallback(() => {
    handleReset();
    if (toCancel) {
      toCancel();
    }
  }, [handleReset, toCancel]);

  const handleSubmit = useCallback(async () => {
    if (isFillOut && selectedPlan) {
      await handleSavePlan(
        currentValues.planName || '',
        selectedPlan.planStatus,
        _.toNumber(selectedPlan.planYearId),
        currentValues.planTemplate,
      );
      if (onRefetchList) {
        await onRefetchList();
      }
    }
    if (toCancel && isFillOut) {
      toCancel();
    }
  }, [isFillOut, selectedPlan, toCancel, handleSavePlan,
    currentValues.planName,
    currentValues.planTemplate,
    onRefetchList]);

  return (
    <Box>
      <Box direction="column">
        <Box round="moduleRound" pad="spacing24" background="module">
          <Box round="container1Round" background="canvas">
            <FlexControlledForm
              fields={fields}
              editMode
              onChangeValues={handleChangeValues}
              showError={!isFillOut}
              onDirty={onDirty}
            />
          </Box>
        </Box>
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
      >
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="cancel"
            buttonType="secondary"
            width="100%"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
        </SiblingBoxesWithSpacing>
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="submit"
            width="100%"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? <Preloader color="white" /> : 'Save Edits'}
          </AppButton>
        </SiblingBoxesWithSpacing>
      </Box>
    </Box>
  );
};

export default PlanEditFormModal;
