import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import {
  DEFAULT_DATE_FORMAT,
  EMAIL_INVALID_TEXT,
  REQUIRED_TEXT,
} from '@/common/constants';
import regexp from '@/common/regexp';
import { Option } from '@/common/types';
import { EmployeePhoneNumber } from '@/modules/employee/Employee/Employee.types';
import theme from '@/styles/theme';
import { useFieldsWithDefaultValues } from '@/utils';

import PreferEmailPicker from '../PreferEmailPicker';
import { useGeneralInfoStore } from '../stores';



const REQUIRED_VALIDATOR = yup.string().trim().required(REQUIRED_TEXT);
const VALIDATOR = yup.string().trim().nullable();
const EMAIL_VALIDATION = yup.string().test({
  test: (val) => {
    if (!val) return true;

    return regexp.EMAIL.test(val);
  },
  message: EMAIL_INVALID_TEXT,
});

const PHONE_TYPES: Option[] = [{
  key: EmployeePhoneNumber.Work,
  value: 'Work',
}, {
  key: EmployeePhoneNumber.Home,
  value: 'Home',
}, {
  key: EmployeePhoneNumber.Mobile,
  value: 'Mobile',
}, {
  key: EmployeePhoneNumber.Unkknown,
  value: 'Unknown',
}];

const EMPLOYEE_PREFER_MAIL_KEY = 'employeeEmailPrefer';
const EMPLOYER_PREFER_MAIL_KEY = 'employerEmailPrefer';
const useGeneralInfoFields = (
  isEditMode?: boolean,
  isShowErrors?: boolean,
) => {
  const defaultFields = useGeneralInfoStore((state) => state.sourceState);

  const employeeEmailPrefer = useGeneralInfoStore((state) => state.state.employeeEmailPrefer);
  const employerEmailPrefer = useGeneralInfoStore((state) => state.state.employerEmailPrefer);
  const employerEmail = useGeneralInfoStore((state) => state.state.employerEmail);
  const employeeEmail = useGeneralInfoStore((state) => state.state.employeeEmail);
  const firstPhone = useGeneralInfoStore((state) => state.state.firstPhone);
  const firstPhoneType = useGeneralInfoStore((state) => state.state.firstPhoneType);
  const secondPhone = useGeneralInfoStore((state) => state.state.secondPhone);
  const secondPhoneType = useGeneralInfoStore((state) => state.state.secondPhoneType);
  const handleSetState = useGeneralInfoStore((state) => state.setState);

  const handleChangeEmailPrefer = useCallback((key: string) => (value: boolean) => {
    const isEmployee = key === EMPLOYEE_PREFER_MAIL_KEY;
    if (isEmployee) {
      handleSetState({
        employeeEmailPrefer: value,
        employerEmailPrefer: !value,
      });
    } else {
      handleSetState({
        employeeEmailPrefer: !value,
        employerEmailPrefer: value,
      });
    }
  }, [handleSetState]);
  const commonFields = useMemo<Field[]>(() => [{
    name: 'gender',
    type: FieldTypes.Radio,
    label: 'Gender',
    disabled: !isEditMode,
    options: [{
      value: 'M',
      label: 'Male',
    }, {
      value: 'F',
      label: 'Female',
    }, {
      value: 'O',
      label: 'Other',
    }],
    placeholder: 'Select gender',
  },
  {
    name: 'employeeEmail',
    type: FieldTypes.Text,
    label: 'Employee provided email',
    disabled: !isEditMode,
    placeholder: 'Enter email',
    // fieldWrapperStyles: {
    //   background: 'red',
    // },
    fieldWrapperStyles: {
      borderTop: `1px solid ${theme.colors.border1}`,
      paddingTop: theme.spacings.m,
    },
    validator: isEditMode ? EMAIL_VALIDATION : VALIDATOR,
  },
  {
    name: EMPLOYEE_PREFER_MAIL_KEY,
    type: FieldTypes.Node,
    fieldWrapperStyles: {
      marginTop: '-12px',
    },
    label: '',
    style: { margin: 0 },
    value: (
      <PreferEmailPicker
        disabled={!isEditMode || !employeeEmail}
        value={!!employeeEmailPrefer && !!employeeEmail && !employerEmailPrefer}
        onChangeValue={handleChangeEmailPrefer(EMPLOYEE_PREFER_MAIL_KEY)}
      />
    ),
  },

  {
    name: 'employerEmail',
    type: FieldTypes.Text,
    label: 'Employer provided email',
    placeholder: 'Enter email',
    disabled: !isEditMode,
    validator: isEditMode ? EMAIL_VALIDATION : VALIDATOR,
  },
  {
    name: EMPLOYER_PREFER_MAIL_KEY,
    type: FieldTypes.Node,
    label: '',
    style: { margin: 0 },
    fieldWrapperStyles: {
      borderBottom: `1px solid ${theme.colors.border1}`,
      paddingBottom: theme.spacings.m,
      marginTop: '-12px',
    },
    value: (
      <PreferEmailPicker
        disabled={!isEditMode || !employerEmail}
        value={(!!employerEmailPrefer && !!employerEmail)
          || (!employeeEmailPrefer && !!employerEmail)}
        onChangeValue={handleChangeEmailPrefer(EMPLOYER_PREFER_MAIL_KEY)}
      />
    ),
  }], [employeeEmailPrefer, employerEmail, employeeEmail,
    employerEmailPrefer, handleChangeEmailPrefer, isEditMode]);



  const fields = useMemo<Field[]>(() => [
    {
      name: 'firstName',
      type: FieldTypes.Text,
      label: 'First name',
      disabled: !isEditMode,
      placeholder: 'Enter first name',
      showRequireIcon: true,
      validator: REQUIRED_VALIDATOR,
    },
    {
      name: 'middleName',
      type: FieldTypes.Text,
      label: 'Middle name',
      disabled: !isEditMode,
      placeholder: 'Enter middle name',
      validator: VALIDATOR,
    },
    {
      name: 'lastName',
      type: FieldTypes.Text,
      label: 'Last name',
      disabled: !isEditMode,
      placeholder: 'Enter last name',
      showRequireIcon: true,
      validator: REQUIRED_VALIDATOR,
    },
    {
      name: 'suffix',
      type: FieldTypes.Text,
      label: 'Suffix',
      disabled: !isEditMode,
      inputWrapCssClass: 'small-input',
      placeholder: 'Enter suffix',
      validator: VALIDATOR,
    },
    {
      name: 'birthDate',
      type: FieldTypes.DateInput,
      inputWrapCssClass: 'small-input',
      isManualDateInput: true,
      label: 'Date of birth',
      showRequireIcon: true,
      disabled: !isEditMode,
      placeholder: DEFAULT_DATE_FORMAT,
      validator: VALIDATORS.DATE_FORMAT_MAX_TODAY.required(REQUIRED_TEXT),
    },
    ...commonFields,
    {
      name: 'firstPhoneType',
      type: FieldTypes.Dropdown,
      label: (
        <Box flex={{ grow: 1 }}>
          <Box direction="row">
            {firstPhone && !firstPhoneType && isShowErrors ? (
              <Inscription
                color="danger"
                margin={{ right: '3px', top: '-3px' }}
              >
                *
              </Inscription>
            ) : null}
            <Inscription weight="bold" color="textBody" lineHeight="20px">
              Preferred phone
            </Inscription>
          </Box>
          <Box>
            <Inscription
              weight="normal"
              color="textSecondary"
              size="small"
              lineHeight="18px"
            >
              This number used to verify your account.
            </Inscription>
          </Box>
        </Box>
      ),
      ...firstPhone ? {
        validator: yup.string().required(REQUIRED_TEXT)
      } : {},
      placeholder: 'Select Preferred phone type',
      singleMode: true,
      disabled: !isEditMode,
      options: PHONE_TYPES,
      ...defaultFields?.firstPhone ? {
        defaultValue: PHONE_TYPES[0].key,
      } : {},
      style: { marginBottom: '-4px' },
    },
    {
      name: 'firstPhone',
      type: FieldTypes.PhoneNumber,
      label: <Box />,
      disabled: !isEditMode,
      placeholder: '+1 (555) 555-1234',
      validator: VALIDATORS.PHONE_NUMBER_VALIDATE,
    },
    {
      name: 'secondPhoneType',
      type: FieldTypes.Dropdown,
      label: (
        <Box flex={{ grow: 1 }} direction="row">
          {secondPhone && !secondPhoneType && isShowErrors ? (
            <Inscription
              color="danger"
              margin={{ right: '3px', top: '-3px' }}
            >
              *
            </Inscription>
          ) : null}
          <Inscription weight="bold" color="textBody" lineHeight="20px">
            Additional phone
          </Inscription>
        </Box>
      ),
      placeholder: 'Select Additional phone type',
      options: PHONE_TYPES,
      singleMode: true,
      disabled: !isEditMode,
      ...defaultFields?.secondPhone ? {
        defaultValue: PHONE_TYPES[1].key,
      } : {},
      ...secondPhone ? {
        validator: yup.string().required(REQUIRED_TEXT)
      } : { VALIDATOR },
      style: { marginBottom: '-4px' },
    },
    {
      name: 'secondPhone',
      type: FieldTypes.PhoneNumber,
      label: <Box />,
      disabled: !isEditMode,
      placeholder: '+1 (555) 555-1234',
      validator: VALIDATORS.PHONE_NUMBER_VALIDATE.nullable(),
    },
  ],

  [isEditMode, commonFields, firstPhone,
    firstPhoneType, isShowErrors, defaultFields?.firstPhone, defaultFields?.secondPhone, secondPhone, secondPhoneType]);

  const viewFields = useMemo<Field[]>(() => {
    const years = dayjs(defaultFields?.birthDate, DEFAULT_DATE_FORMAT).diff(dayjs(), 'years');
    return [
      {
        name: 'fullName',
        type: FieldTypes.Text,
        label: 'Full name',
        disabled: true,
        placeholder: 'Enter full name',

      },

      {
        name: 'birthDateFormated',
        type: FieldTypes.Text,

        label: 'Date of birth',
        showRequireIcon: true,
        disabled: true,
        value: defaultFields?.birthDate ? `${defaultFields?.birthDate} (${Math.abs(years || 0)} years)` : '',
        placeholder: DEFAULT_DATE_FORMAT,

      },
      ...commonFields,
      {
        name: 'firstPhoneFormated',
        type: FieldTypes.PhoneNumber,
        label: defaultFields?.firstPhoneType ? `Preferred phone (${_.capitalize(defaultFields?.firstPhoneType)})` : 'Preferred phone',
        disabled: !isEditMode,
        placeholder: '+1 (555) 555-1234',
        value: defaultFields?.firstPhone,

      },
      {
        name: 'secondPhoneFormated',
        type: FieldTypes.PhoneNumber,
        label: defaultFields?.secondPhoneType ? `Additional phone (${_.capitalize(defaultFields?.secondPhoneType)})` : 'Additional phone',
        disabled: true,
        placeholder: '+1 (555) 555-1234',
        value: defaultFields?.secondPhone,
      },
    ];
  },
  [commonFields, defaultFields, isEditMode]);
  const formatedFields = useFieldsWithDefaultValues(isEditMode ? fields
    : viewFields, defaultFields);

  return formatedFields;
};

export default useGeneralInfoFields;
