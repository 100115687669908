import React, { useMemo } from 'react';
import { AppButton, Box, Inscription, NavigationToggler } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';

import { FinOpsTabs } from '../../FinOps.types';
import useReconciliationReportDownloadModal from '../ReconciliationReportDownloadModal/hooks/useReconciliationReportDownloadModal';

interface Props {
  currentTab: FinOpsTabs;
  tabs: FinOpsTabs[];
  onSetCurrentTab: (value: FinOpsTabs) => void;
}
export const FinOpsHeader: React.FC<Props> = ({ currentTab, tabs, onSetCurrentTab }) => {
  const { setIsModalVisible } = useReconciliationReportDownloadModal();

  const isHasAccessToDownload = useHasAccess([
    {
      permission: Permissions.REPORTS,
    },
  ]);

  const showDownloadReportButton = useMemo(() => currentTab === FinOpsTabs.RECON, [currentTab]);

  return (
    <Box margin={{ bottom: 'spacing24' }} data-testid="ReconciliationReportsHeader">
      <Box direction="row" justify="between" align="center">
        <Box>
          <Inscription size="3xl" weight="bold" color="textBody" lineHeight="40px">
            {currentTab === FinOpsTabs.RECON && 'Recon'}
            {currentTab === FinOpsTabs.INVOICING && 'Invoicing'}
            {currentTab === FinOpsTabs.CHECKS && 'Checks'}
            {currentTab === FinOpsTabs.LOCKBOX && 'Lockbox'}
          </Inscription>
        </Box>
        <Box flex direction="row" justify="end" align="center" height="40px">
          {showDownloadReportButton && isHasAccessToDownload && (
            <Box margin={{ left: 'spacing24' }}>
              <AppButton
                buttonType="quaternary"
                disabled
                width="166px"
                onClick={() => setIsModalVisible(true)}
              >
                Download Report
              </AppButton>
            </Box>
          )}
          <Box>
            <NavigationToggler
              value={currentTab}
              options={tabs}
              onChange={(value: string) => onSetCurrentTab(value as FinOpsTabs)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
