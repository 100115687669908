import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter, costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import theme from '@/styles/theme';

import { InvoiceStatus, InvoiceType } from '../../FinOpsInvoicing/types/invoicing.constants';
import { Invoice, InvoiceDto } from '../../FinOpsInvoicing/types/invoicing.types';
import { InfoTableValue, PrefundStatus } from '../finops.types';

const COMMA_SEPARATOR = ', ';
const TIME_SEPARATOR = 'T';

export const currentStatus = (status?: InvoiceStatus) => {
  if (status === InvoiceStatus.REOPEN) {
    return PrefundStatus.Reopen;
  }
  if (status === InvoiceStatus.PAYMENT_REQUESTED) {
    return PrefundStatus.PaymentRequested;
  }
  if (status === InvoiceStatus.PAYMENT_REJECTED) {
    return PrefundStatus.PaymentRejected;
  }
  if (status === InvoiceStatus.PAYMENT_INITIATED) {
    return PrefundStatus.PaymentInitiated;
  }
  if (status === InvoiceStatus.PAID) {
    return PrefundStatus.Paid;
  }
  if (status === InvoiceStatus.PAYMENT_FAILED) {
    return PrefundStatus.PaymentFailed;
  }

  return undefined;
};

const GET_PREFUND_INVOICE_QUERY_KEY = 'GET_PREFUND_INVOICE_QUERY_KEY';

export const useGetPrefundInvoiceInfo = (invoiceId: string) => {
  const {
    isLoading, isSuccess, data, refetch,
  } = useQuery(
    [GET_PREFUND_INVOICE_QUERY_KEY, invoiceId],
    () => api.get<InvoiceDto>(PATHS.GET_PREFUND_INVOICE(invoiceId)),
    {
      retry: 0,
    },
  );

  const formattedData = useMemo(() => ({
    amount: data?.data.amount,
    endedAt: data?.data.ended_at?.split(TIME_SEPARATOR)[0],
    createdAt: data?.data.created_at?.split(TIME_SEPARATOR)[0],
    invoiceId: data?.data.invoice_id,
    invoiceStatusType: data?.data.invoice_status_type,
    invoiceType: data?.data.invoice_type,
    status: currentStatus(data?.data.invoice_status_type),
    largeOrganizationLogo: data?.data.large_organization_logo,
    organizationName: data?.data.organization_name,
    organizationId: data?.data.organization_id ? `${data?.data.organization_id}` : undefined,
    rejectReasons: data?.data.reason_codes?.map((reason) => reason.description),
    description: data?.data.description,
    repullManuallyAllowed: data?.data.repull_manually_allowed,
    accountNumberLast4: data?.data.account_number_last4,
    initialFundsRequest: data?.data.initial_funds_request,
  } as Invoice), [data]);

  const formattedInvoiceTable: InfoTableValue[] = useMemo(() => {
    const {
      amount,
      invoiceId: iId,
      invoiceType,
      status,
      initialFundsRequest,
      rejectReasons,
      description,
      accountNumberLast4,
    } = formattedData;

    return [
      {
        title: 'Invoice number',
        value: iId || invoiceId || '-',
      },
      {
        title: 'Invoice type',
        value: invoiceType ? capitalizeFirstLetter(invoiceType.toString().split('_').join(' ').toLowerCase()) : '-',
      },
      {
        title: 'Amount',
        value: _.isNumber(amount) ? costFormater(amount, true) : '-',
      },
      {
        title: 'Status',
        value: status,
        isStatus: true,
      },
      ...status === PrefundStatus.PaymentFailed
      || status === PrefundStatus.PaymentRejected ? [
          {
            title: 'Reject Reason',
            value: rejectReasons?.join(COMMA_SEPARATOR) || '-',
            styles: { color: theme.colors.statusColors.deniedErrorExpired },
          }] : [],
      {
        title: 'Initial funds request',
        value: initialFundsRequest ? dayjs(initialFundsRequest).format(DEFAULT_DATE_FORMAT) : '-',
      },
      {
        title: 'Bank account',
        value: description ? `${description} (${accountNumberLast4 || '-'})` : '-',
      },
    ];
  }, [formattedData, invoiceId]);

  const isRejected = useMemo(
    () => formattedData.status === PrefundStatus.PaymentRejected
    || formattedData.status === PrefundStatus.PaymentFailed,
    [formattedData],
  );

  const currentInvoiceType = useMemo(() => {
    switch (formattedData.invoiceType) {
    case InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT:
    case InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT:
      return 'Prefund';
    case InvoiceType.REPLENISHMENT:
    case InvoiceType.PLAN_LEVEL_REPLENISHMENT:
      return 'Replenishment';
    case InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS:
      return 'Contribution';
    default:
      return '-';
    }
  }, [formattedData]);

  return ({
    data,
    formattedData,
    formattedInvoiceTable,
    isRejected,
    isLoading,
    isSuccess,
    currentInvoiceType,
    refetch,
  });
};
