import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';

import { FileRecordErrorDto,FileRecordErrorDtoPayload } from '../types/fileReport.constants';

export const QUERY_KEY = 'useFileRecordErrors';

export interface FileErrorsParams {
  fileId: string,
  page?: number,
  perPage?: number,
  sort?: string,
  enabled?: boolean,
}

export interface ResponseData {
  content: FileRecordErrorDtoPayload[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

const formatData = (data: FileRecordErrorDtoPayload[]): FileRecordErrorDto[] => data.map(
  (fileError: FileRecordErrorDtoPayload) => ({
    id: toString(fileError.id),
    employeeId: toString(fileError.employee_id),
    employerId: toString(fileError.employer_id),
    errorDetails: fileError.error_details,
    fieldName: fileError.field_name,
    fileRecordErrorType: fileError.file_record_error_type,
    fileRecordId: toString(fileError.file_record_id),
    lineNumber: fileError.line_number,
    partnerId: toString(fileError.partner_id),
    lastName: toString(fileError.last_name),
    firstName: toString(fileError.first_name),
    errorCode: toString(fileError.error_code),
  }),
);

const useFileRecordErrorsQuery = ({
  fileId, page, perPage, sort, enabled,
}: FileErrorsParams) => {
  const params = useMemo(() => ({
    page,
    size: perPage,
    sort,
  }), [page, perPage, sort]);

  const {
    data, isLoading, isSuccess, isError,
  } = useQuery(
    [QUERY_KEY, page, perPage],
    () => api.get<Pagination<FileRecordErrorDtoPayload>>(
      PATHS.GET_FILE_RECORD_ERRORS(fileId),
      { params },
    ),
    { enabled },
  );

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);
  return ({
    formattedData: formatData(data?.data.content || []),
    data: data?.data?.content as FileRecordErrorDtoPayload[] || [],
    total,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useFileRecordErrorsQuery;
