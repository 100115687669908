import React, { useCallback, useRef,useState } from 'react';
import {
  Box, Button, DropContent,
  FilledDownArrow, LargeCloseIcon,
  OrderIcon, Text,
  useClickOutside,
} from '@common-fe/common-fe';
import styled from 'styled-components';

interface StyledIconProps {
  open?: boolean;
}
const StyledIcon = styled(FilledDownArrow)<StyledIconProps>`
  transition: 0.3s all;
  ${({ open }) => {
    if (!open) {
      return {};
    }
    return {
      transform: 'rotate(180deg)',
    };
  }}
`;

const StyledButton = styled(Button)`
  min-height: auto !important;
  height: auto !important;
`;
const StyleOptionItem = styled(Button)`
  min-height: auto !important;
  height: auto !important;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  text-align: left;
 border-radius: ${({ theme }) => theme.rounds.button2Round} ;
  padding: ${({ theme }) => `${theme.spacings.spacing6} ${theme.spacings.spacing6} ${theme.spacings.spacing6} ${theme.spacings.xs}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
    span {
      color: ${({ theme }) => theme.colors.textAccent};
      ${({ active }) => (active ? 'font-weight: bold' : '')};
    }
  }
`;

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  options?: string[];
  onChangeOption?:(value: string) => void;
  onDelete?: () => void;
}

const DragAndDropItem: React.FC<Props> = ({
  children, disabled, options = [], onDelete, onChangeOption,

}) => {
  const currentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = useCallback(() => {
    if (disabled || !options.length) {
      return;
    }
    setOpen(!open);
  }, [disabled, open, options.length]);
  const handleChangeOption = useCallback((newValue: string) => {
    if (onChangeOption) {
      setOpen(false);
      onChangeOption(newValue);
    }
  }, [onChangeOption]);
  useClickOutside(currentRef, () => {
    setOpen(false);
  });
  return (
    <Box
      direction="row"
      background={disabled ? 'canvas' : 'module'}
      align="center"
      round="button2Round"
      pad={{
        vertical: 'xxs', horizontal: 'xs',
      }}
      border={{ size: '1px', color: 'border1' }}
    >
      <Box margin={{ right: 'xs' }}>
        <OrderIcon color="iconSecondary" />
      </Box>
      <DropContent
        bottomMode
        visible={open}
        contentWidth="100px"
        content={(
          <Box ref={currentRef}>
            {options.map((item) => (
              <StyleOptionItem
                key={item}
                onClick={() => handleChangeOption(item)}
              >
                <Text>
                  {item}
                </Text>
              </StyleOptionItem>
            ))}
          </Box>
        )}
      >
        <StyledButton onClick={handleToggle}>
          <Box align="center" margin={{ left: 'xs' }} direction="row">
            <Text
              size="large"
              color={disabled ? 'textDisabled' : 'textBody'}
              weight={400}
            >{children}
            </Text>
            {!!options.length && (
              <Box margin={{ left: 'xs' }}>
                <StyledIcon
                  open={open}
                  color={disabled ? 'textDisabled' : 'textBody'}
                />
              </Box>
            )}
            {onDelete && !disabled && (
              <StyledButton
                margin={{ left: 's', top: 'xxs' }}
                onClick={onDelete}

              >
                <LargeCloseIcon color="iconSecondary" />
              </StyledButton>
            )}

          </Box>
        </StyledButton>
      </DropContent>
    </Box>
  );
};

export default DragAndDropItem;
