import React from 'react';
import {
  AddIcon,   Box, RawButton,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledButton = styled(RawButton)`
  color: ${({ theme }) => theme.colors.button};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
  border-radius: 4px;
`;

interface Props {
  onClick: () => void;
}
const AddPaymentRule: React.FC<Props> = ({ onClick }) => (
  <Box
    pad="l"
    background="canvas"
    elevation="default"
    round="container1Round"
    border={{ color: 'border2', size: 'small' }}
    height="80px"
    justify="center"
  >
    <StyledButton onClick={onClick}>
      <Box
        direction="row"
        align="center"
        data-testid="add-payment-rule"
        pad={{ horizontal: 'xs', vertical: 'spacing6' }}
        round="button2Round"
      >
        <AddIcon size="16px" color="button" />
        <Text size="medium" margin={{ left: 'xxs' }}>
          Add Payment Rule
        </Text>
      </Box>
    </StyledButton>

  </Box>
);

export default AddPaymentRule;
