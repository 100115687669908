import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { HorizontalLine } from '@/components/elements';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { BrandingSection } from './BrandingSection';
import { PreviewBrandingSection } from './PreviewBrandingSection';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const Branding: React.FC<Props> = ({ onDirty }) => {
  const { isLoading, formattedData } = useGetEmployer();
  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || formattedData?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, formattedData?.organizationStatusType]);

  return (
    <Box
      direction="column"
      round="container1Round"
      background="canvas"
      pad={{ vertical: 'spacing24', horizontal: 'spacing32' }}
      border={{ color: 'border2', size: 'small' }}
      id="branding"
    >
      <BrandingSection isLoading={isLoading} onDirty={onDirty} isTerminated={isEditRestricted} />
      <HorizontalLine />
      <PreviewBrandingSection isLoading={isLoading} />
    </Box>
  );
};
