import { useEffect } from 'react';

import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const useWhiteLabelingFavicon = () => {
  useEffect(() => {
    const linkIco = document.getElementById('favicon') as HTMLAnchorElement;
    const linkAppleTouch = document.getElementById('favicon-apple-touch') as HTMLAnchorElement;
    const link16 = document.getElementById('favicon-32') as HTMLAnchorElement;
    const link32 = document.getElementById('favicon-16') as HTMLAnchorElement;

    if (linkIco && !!whiteLabelingConfig?.favicon) {
      linkIco.href = whiteLabelingConfig?.favicon;
    }
    if (linkAppleTouch && !!whiteLabelingConfig?.faviconAppleTouch) {
      linkAppleTouch.href = whiteLabelingConfig?.faviconAppleTouch;
    }
    if (link16 && !!whiteLabelingConfig?.favicon16) {
      link16.href = whiteLabelingConfig?.favicon16;
    }
    if (link32 && !!whiteLabelingConfig?.favicon32) {
      link32.href = whiteLabelingConfig?.favicon32;
    }
  }, []);
};

export default useWhiteLabelingFavicon;
