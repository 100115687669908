import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { capitalize,toNumber, toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import useCarrierConfigStore from '@/modules/CarrierConfig/useCarrierConfig.store';
import { ClaimAutopayType } from '@/modules/employee/employee.types';

const DATE_OVERLAPPED_MESSAGE = 'Service date periods should not overlap';
const SUBMITTER_ID_MAX_LENGTH = 40;

export enum PorposeType {
  claimCreation = 'CLAIM_CREATION',
  adjudication = 'ADJUDICATION',
}
export enum ClaimPayeeType {
  participant = 'PARTICIPANT',
  provider = 'PROVIDER',
  employeeChoice = 'EMPLOYEE_CHOICE',
}
export enum AmountTypes {
  coinsurance = 'COINSURANCE',
  copay = 'COPAY',
  patientResponsibility = 'PATIENT_RESPONSIBILITY',
  deductible = 'DEDUCTIBLE',
}

interface CarrierConfigPayload {
  carrier_external_id: string;
  organization_id: number;
  organization_name: string;
  organization_path?: string;
  submitter_id: string;
  description: string;
  service_date_from: string;
  purpose_type: PorposeType;
  amount_types: AmountTypes[];
  status_type: 'ACTIVE';

  service_date_to?: string;
  claim_autopay_type?: ClaimAutopayType;
  is_dependent_deidentified?: boolean;
  is_dependent_autocreated?: boolean;
  claim_payee_type?: ClaimPayeeType;
}
export interface CarrierConfig {
  carrierExternalId: string;
  organizationId: string;
  organizationPath: string;
  organizationName: string;
  submitterId: string;
  description: string;
  serviceDateFrom: string;
  serviceDateTo: string;
  purposeType: PorposeType;
  claimAutopayType: ClaimAutopayType;
  isDependentDeidentified: boolean;
  isDependentAutocreated: boolean;
  claimPayeeType: ClaimPayeeType;
  amountTypes: AmountTypes[];
}

const formatDataToPayload = (carrierConfig: CarrierConfig): CarrierConfigPayload => ({
  carrier_external_id: carrierConfig.carrierExternalId,
  organization_id: toNumber(carrierConfig.organizationId),
  organization_name: carrierConfig.organizationName,
  // organization_path: carrierConfig.organizationPath,
  submitter_id: carrierConfig.submitterId.slice(0, SUBMITTER_ID_MAX_LENGTH),
  description: carrierConfig.description,
  service_date_from: carrierConfig.serviceDateFrom,
  purpose_type: carrierConfig.purposeType,
  amount_types: carrierConfig.amountTypes,
  status_type: 'ACTIVE',

  ...carrierConfig.serviceDateTo ? {
    service_date_to: carrierConfig.serviceDateTo,
  } : {},

  ...carrierConfig.purposeType === PorposeType.adjudication ? {} : {
    ...carrierConfig.claimAutopayType ? {
      claim_autopay_type: carrierConfig.claimAutopayType,
    } : {},
    is_dependent_deidentified: carrierConfig.isDependentDeidentified || false,
    is_dependent_autocreated: carrierConfig.isDependentDeidentified
      ? false : carrierConfig.isDependentAutocreated || false,
    ...carrierConfig.claimPayeeType ? {
      claim_payee_type: carrierConfig.claimPayeeType,
    } : {},
  },
});

const useCreateCarrierConfigQuery = (
  onDateOverlapped: () => void,
  onSuccess: () => void,
) => {
  const {
    carrierConfigs, setCarrierConfigs,
  } = useCarrierConfigStore();
  const { mutateAsync, isLoading } = useMutation(
    (value: CarrierConfig) => api.post(
      PATHS.ADD_CARRIER_CONFIG,
      formatDataToPayload(value),
    ),
    {
      onSuccess: (response, payload) => {
        const { data } = response;
        const { organizationName } = payload;

        const createdCarrierConfig = {
          carrierName: data.carrier.name,
          carrierExternalId: toString(data.carrier.external_id),
          carrierConfigId: toString(data.id),
          submitterId: data.submitter_id,
          organization: organizationName,
          purposeType: capitalize(data.purpose_type).replace(/_/g, ' '),
          description: data.description,
          statusType: data.status_type,
        };

        setCarrierConfigs([createdCarrierConfig, ...carrierConfigs]);
        onSuccess();
      },
      onError: ({ response: { data: { elevate_error_message } } }) => {
        if (elevate_error_message.includes(DATE_OVERLAPPED_MESSAGE)) {
          onDateOverlapped();
        }
      },
    },
  );

  const handleSave = useCallback(async (value: Partial<CarrierConfig>) => {
    await mutateAsync(value as CarrierConfig);
  }, [mutateAsync]);

  return {
    isLoading,
    create: handleSave,
  };
};

export default useCreateCarrierConfigQuery;
