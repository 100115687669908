import { useCallback } from 'react';
import _ from 'lodash';

import { useContactStore } from '../AddEditContactForm/stores';
import { usePlanYearStore } from '../ExpressSetupWizard/PlansWizard/PlanYear/stores';

import useExpressSetupSetter from './useExpressSetupSetter';

export const ERROR_MESSAGES = {
  USER_NAME_ALREADY_USED: 'Details: Application user with',
  VALID_TO_MORE_THAN_YEAR: 'Details: Wrong validity range',
};

const ERROR_FIELDS_NAME = {
  USER_NAME_FIELD_NAME: 'userName',
  CONTACT_EMAIL_FIELD_NAME: 'email',
  PHONE_FIELD_NAME: 'phone',
};

export default () => {
  const { handleSetErrorFields } = useExpressSetupSetter();
  const contactState = useContactStore((state) => state.state);
  const planYearState = usePlanYearStore((state) => state.state);

  const handleParseError = useCallback((errors: unknown) => {
    const message = _.get(errors, 'response.data.elevate_error_message', '') as string;
    const invalidFields = _.get(errors, 'response.data.elevate_error_meta_info.invalid_fields', []) as object[];
    if (message.includes(ERROR_MESSAGES.USER_NAME_ALREADY_USED)) {
      handleSetErrorFields({
        userName: {
          value: contactState.userName,
          message: 'This username is already used',
        },
      });
    }
    if (message.includes(ERROR_MESSAGES.VALID_TO_MORE_THAN_YEAR)) {
      handleSetErrorFields({
        planEndDate: {
          value: planYearState.planEndDate,
          message: 'Last date is more than year from first date of plan year',
        },
      });
    }
    if (invalidFields && invalidFields.length) {
      invalidFields.forEach((field) => {
        const fieldName = _.get(field, 'field_name');
        const fieldMessage = _.get(field, 'elevate_error_message', '');
        if (fieldName === ERROR_FIELDS_NAME.USER_NAME_FIELD_NAME) {
          handleSetErrorFields({
            userName: {
              value: contactState.userName,
              message: 'Username format is invalid',
            },
          });
        }
        if (fieldName === ERROR_FIELDS_NAME.CONTACT_EMAIL_FIELD_NAME) {
          handleSetErrorFields({
            email: {
              value: contactState.email,
              message: fieldMessage,
            },
          });
        }
      });
    }

    if (invalidFields && invalidFields.length) {
      invalidFields.forEach((field) => {
        const fieldName = _.get(field, 'field_name');
        const fieldMessage = _.get(field, 'elevate_error_message', '');
        if (fieldName === ERROR_FIELDS_NAME.USER_NAME_FIELD_NAME) {
          handleSetErrorFields({
            userName: {
              value: contactState.userName,
              message: 'Username format is invalid',
            },
          });
        }
        if (fieldName === ERROR_FIELDS_NAME.CONTACT_EMAIL_FIELD_NAME) {
          handleSetErrorFields({
            email: {
              value: contactState.email,
              message: fieldMessage,
            },
          });
        }
        if (fieldName === ERROR_FIELDS_NAME.PHONE_FIELD_NAME) {
          handleSetErrorFields({
            phoneNumber: {
              value: contactState.phoneNumber,
              message: fieldMessage,
            },
          });
        }
      });
    }
    return message;
  }, [contactState, handleSetErrorFields, planYearState]);
  return handleParseError;
};
