import React, { useCallback, useEffect,useMemo } from 'react';
import {
  Box,
  FieldTypes,
  NumberRangeInput,
  Text,
} from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionState from '@/modules/user/hooks/useGetPermissionState';
import { getStringFromBoolean } from '@/utils/modifiers';

import useAIAdjudicationStore from '../stores/useAIAdjudication.store';

const RangeValueTitle = ({ title }: { title: string }) => (
  <Box width={{ max: '32px' }}>
    <Text
      color="textSecondary"
      textAlign="center"
      weight={500}
    >
      {title}
    </Text>
  </Box>
);

const useAIAdjudicationFields = (disabled: boolean, data?: EmployerDto) => {
  const { setAIAdjudication } = useAIAdjudicationStore();
  const isAiAdjudicationState = useGetPermissionState(PermissionKey.IS_AI_ADJUDICATION);
  const isAiConfidenceLevelState = useGetPermissionState(PermissionKey.AI_CONFIDENCE_LEVEL);

  const handleChangeRange = useCallback((value: number) => setAIAdjudication({
    aiConfidenceLevel: value,
  }), [setAIAdjudication]);

  const aiAdjudication = useMemo(
    () => getStringFromBoolean(data?.overridableProperties?.aiAdjudication),
    [data],
  );
  const aiConfidenceLevel = useMemo(
    () => data?.overridableProperties?.aiConfidenceLevel,
    [data],
  );

  useEffect(() => {
    if (aiConfidenceLevel) {
      setAIAdjudication({
        aiConfidenceLevel,
      });
    }
  }, [aiConfidenceLevel, setAIAdjudication]);

  const fields = useMemo(() => [
    ...isAiAdjudicationState.isHide ? [] : [{
      name: 'aiAdjudication',
      type: FieldTypes.Radio,
      label: 'AI Adjudication',
      defaultValue: aiAdjudication,
      options: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      disabled: isAiAdjudicationState.isView,
    }],
    ...isAiConfidenceLevelState.isHide ? [] : [{
      name: 'aiConfidenceLevel',
      type: FieldTypes.Node,
      label: 'Claims Processing Confidence Level',
      value: (
        <NumberRangeInput
          onChange={handleChangeRange}
          value={aiConfidenceLevel || 1}
          step={1}
          min={1}
          max={10}
          disabled={disabled || isAiConfidenceLevelState.isView}
          minValueTitle={(
            <RangeValueTitle title="1 Low" />
          )}
          maxValueTitle={(
            <RangeValueTitle title="10 High" />
          )}
        />
      ),
    }],
  ], [handleChangeRange, aiAdjudication, aiConfidenceLevel,
    isAiAdjudicationState, isAiConfidenceLevelState, disabled]);

  return fields;
};

export default useAIAdjudicationFields;
