import React, { useCallback,useState } from 'react';
import { AppButton,Box, Inscription } from '@common-fe/common-fe';

import { EmailTemplate } from './components/EmailTemplate';

const PreviewSection = () => {
  const [isFullPreview, setIsFullPreview] = useState(false);

  const togglePreview = useCallback(() => {
    setIsFullPreview(!isFullPreview);
  }, [isFullPreview]);

  return (
    <Box pad={{ horizontal: 'spacing32', vertical: 'spacing24' }} data-testId="preview-section">
      <Box direction="row" justify="between" align="center">
        <Inscription size="20px" weight="normal" lineHeight="28px">
          Preview
        </Inscription>
        <Box>
          <AppButton
            testId="toggle-preview"
            onClick={togglePreview}
            buttonType="secondary"
          >
            {isFullPreview ? 'Hide Full Preview' : 'Show Full Preview'}
          </AppButton>
        </Box>
      </Box>

      <Box margin={{ top: 'spacing16' }}>
        <EmailTemplate
          isFullPreview={isFullPreview}
          onSetFullPreview={togglePreview}
        />
      </Box>
    </Box>
  );
};

export default PreviewSection;
