import { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { EmployeeStatus } from '@/modules/employee/employee.constants';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { useAuthStore } from '@/modules/user/stores';

import useGetOrganizationById from './useGetOrganizationById';

export interface ObservingOrganizationProperties {
  name?: string;
  id?: string;
  type?: OrganizationTypes;
  status?: EmployeeStatus | EmployerStatus;
  path?: string;
  externalIdentifier?: string;
  terminationDate?: string;
}

const ELEVATE_ID = '0';
interface Params {
  isLoadDefaultOrgData?: boolean;
}
export const useCurrentOrganization = (params?: Params) => {
  const { user, setObservedOrganizationPath } = useAuthStore();
  const defaultOrganizationData = useMemo(() => ({
    id: user?.organizationId,
    name: user?.organizationName,
    type: user?.organizationType,
    status: user?.organizationType === OrganizationTypes.system
      ? EmployeeStatus.Active : user?.status,
    path: user?.organizationPath,
  }), [user]);
  const {
    observingOrganizationId,
    defaultOrganizationId,
    observingEmployerId,
    observingPartnerId,
    observingDistributorId,
    observingSubsidiaryId,
    observingMode,
  } = useCurrentOrganisationStore();

  const {
    data: observingOrganizationData,
    isFetched: observingOrganizationIsFetched,
  } = useGetOrganizationById(observingOrganizationId
    && user
    && user?.organizationId !== observingOrganizationId
    ? observingOrganizationId
    : undefined);

  const {
    data: loadedDefaultData,
  } = useGetOrganizationById(params?.isLoadDefaultOrgData ? user?.organizationId : undefined);
  const {
    data: observingDefaultData,
    isFetched: observingDefaultIsFetched,
  } = useGetOrganizationById(
    defaultOrganizationId
    && user
    && user?.organizationId !== defaultOrganizationId
      ? defaultOrganizationId
      : undefined);

  const {
    data: observingEmployerData,
    isFetched: observingEmployerIsFetched,
  } = useGetOrganizationById(user && observingEmployerId ? observingEmployerId : undefined);

  const {
    data: observingPartnerData,
    isFetched: observingPartnerIsFetched,
  } = useGetOrganizationById(user && observingPartnerId ? observingPartnerId : undefined);

  const {
    data: observingDistributorData,
    isFetched: observingDistributorIsFetched,
  } = useGetOrganizationById(user && observingDistributorId ? observingDistributorId : undefined);

  const {
    data: observingSubsidiaryData,
    isFetched: observingSubsidiaryIsFetched,
  } = useGetOrganizationById(user && observingSubsidiaryId ? observingSubsidiaryId : undefined);

  const isLoaded = useMemo(() => observingOrganizationIsFetched
  || observingDefaultIsFetched
  || observingEmployerIsFetched
  || observingPartnerIsFetched
  || observingDistributorIsFetched
  || observingSubsidiaryIsFetched,
  [
    observingOrganizationIsFetched,
    observingDefaultIsFetched,
    observingEmployerIsFetched,
    observingPartnerIsFetched,
    observingDistributorIsFetched,
    observingSubsidiaryIsFetched,
  ]);

  const getFormattedData = useCallback((data?: object) => {
    const name = _.get(data, 'data.name', '') as string;
    const id = _.get(data, 'data.id', '');
    const type = _.get(data, 'data.type', '') as OrganizationTypes;
    const status = _.get(data, 'data.organization_status_type', '') as EmployeeStatus;
    const path = _.get(data, 'data.organization_path', '');
    const externalIdentifier = _.get(data, 'data.external_identifier', '');
    const terminationDate = _.get(data, 'data.termination_date', '') as string;

    const formattedData: ObservingOrganizationProperties = {
      ...(name ? { name } : {}),
      ...(id ? { id } : {}),
      ...(type ? { type } : {}),
      ...(status ? { status } : {}),
      ...(path ? { path } : {}),
      ...(externalIdentifier ? { externalIdentifier } : {}),
      ...(terminationDate ? { terminationDate } : {}),
    };

    if (Object.keys(formattedData).length) {
      return formattedData;
    }

    return null;
  }, []);

  const observingOrganization = useMemo(() => getFormattedData(observingOrganizationData)
    || defaultOrganizationData,
  [getFormattedData, observingOrganizationData, defaultOrganizationData]);

  useEffect(() => {
    setObservedOrganizationPath(observingOrganization.path);
  }, [observingOrganization.path, setObservedOrganizationPath]);

  const observingDefault = useMemo(() => (
    getFormattedData(observingDefaultData)
      || defaultOrganizationData),
  [getFormattedData, observingDefaultData, defaultOrganizationData]);

  const observingEmployer = useMemo(() => (observingEmployerData
    ? getFormattedData(observingEmployerData)
    : undefined),
  [getFormattedData, observingEmployerData]);

  const observingPartner = useMemo(() => (observingPartnerData
    ? getFormattedData(observingPartnerData)
    : undefined),
  [getFormattedData, observingPartnerData]);

  const observingDistributor = useMemo(() => (observingDistributorData
    ? getFormattedData(observingDistributorData)
    : undefined),
  [getFormattedData, observingDistributorData]);

  const observingSubsidiary = useMemo(() => (observingSubsidiaryData
    ? getFormattedData(observingSubsidiaryData)
    : undefined),
  [getFormattedData, observingSubsidiaryData]);

  
  const isElevateOrganization = useMemo(() => {
    return defaultOrganizationId === ELEVATE_ID;
  }, [defaultOrganizationId]);

  const observingOrCurrentOrganizationData = useMemo(() => {
    if(observingOrganizationData) {
      return observingOrganizationData?.data;
    }
    if(loadedDefaultData) {
      return loadedDefaultData.data;
    }
    return  undefined;
  }, [loadedDefaultData, observingOrganizationData]);

  return {
    observingOrCurrentOrganizationData,
    observingDefault,
    observingEmployer,
    observingPartner,
    observingDistributor,
    observingSubsidiary,
    observingOrganization,
    observingOrganizationData: observingOrganizationData?.data,
    isLoaded,
    observingMode,
    isElevateOrganization,
    defaultOrganizationData,
  };
};
export default useCurrentOrganization;
