const PER_PAGE_OPTIONS = [
  {
    key: '2',
    value: '2',
    title: '2',
  },
  {
    key: '5',
    value: '5',
    title: '5',
  },
  {
    key: '10',
    value: '10',
    title: '10',
  },
  {
    key: '15',
    value: '15',
    title: '15',
  },
  {
    key: '20',
    value: '20',
    title: '20',
  },
  {
    key: '50',
    value: '50',
    title: '50',
  },
];

export default PER_PAGE_OPTIONS;
