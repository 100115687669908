import React, {
  useMemo,
} from 'react';
import {
  Box, FlatButton, PlusIcon, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Option } from '@/common/types';
import { ModalWrapper } from '@/components';

import { useCoverageLevels, useCoverageLevelsBindToPlan } from '../hooks';

import SelectCoverageLevel from './SelectCoverageLevel';

const StyledButton = styled(FlatButton)`
  &:hover {
    opacity: 0.8
  }
`;

interface Props {
  excludeIds?: string[];
  selectModalVisible: boolean;
  onToggleModalVisible: () => void;
}
const AddCoverageLevel: React.FC<Props> = ({
  excludeIds = [],
  selectModalVisible, onToggleModalVisible,
}) => {
  const { data } = useCoverageLevels();
  const options: Option[] = useMemo(() => data.map((item) => ({
    key: item.id,
    value: item.name,
  })), [data]);
  const filteredOptions = useMemo(
    () => options.filter((item) => !excludeIds.includes(item.key as string)),
    [excludeIds, options],
  );
  const handleBindCoverageLevels = useCoverageLevelsBindToPlan();

  return (
    <>
      <ModalWrapper
        visible={selectModalVisible}
        title="Add Coverage Level"
        onSetVisible={onToggleModalVisible}
      >
        <SelectCoverageLevel
          options={filteredOptions}
          onClose={() => onToggleModalVisible()}
          onBindCoverageLevels={handleBindCoverageLevels}
        />
      </ModalWrapper>

      <StyledButton onClick={onToggleModalVisible}>
        <Box
          pad="l"
          border={{ size: 'small', color: 'border2' }}
          round="container1Round"
          elevation="default"
          direction="row"
          align="center"
          background="canvas"
        >
          <PlusIcon size="12px" color="button" />
          <Text
            margin={{ left: 'xxs' }}
            color="button"
            weight={700}
          >Add Coverage Level
          </Text>
        </Box>
      </StyledButton>
    </>
  );
};

export default AddCoverageLevel;
