import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isUnableToActivate: boolean;
  setIsUnableToActivate: (value: boolean) => void;
}

const useStore = storeCreator<State>((set) => ({
  isUnableToActivate: false,

  setIsUnableToActivate: (value: boolean) => set(() => ({
    isUnableToActivate: value,
  })),

}));
const useTOCSidebar = () => {
  const store = useStore();

  return {
    ...store,
  };
};
export default useTOCSidebar;
