import React, { useCallback,useMemo, useState } from 'react';
import {
  AppButton,
  Box,
  BoxProps,
  CardTypesEnum,
  DashesPlaceholder,
  Text,
} from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization,useHistory } from '@/modules/core/hooks';
import { HealthPlan } from '@/modules/HealthPlan/HealthPlan.types';
import { Plan, PlanCardAction, PlanCardMode, PlanStatuses } from '@/modules/plan/plan.types';
import PlanTypeIcon from '@/modules/plan/PlanSetup/Sidebar/PlanTypeIcon';
import colors from '@/styles/colors';
import theme from '@/styles/theme';

import {
  AnimatedContent,
  Content,
  ContentWrapper,
  IconBgBox,
  LabelContainer,
  PlaceholderWrapper,
  PlanName,
  StyledActionBox,
  StyledBox,
  Wrapper,
} from './PlanCard.styles';
import PlanCardTemplateActions from './PlanCardTemplateActions';


const MAX_ALLOWED_LENGTH_SYMBOL_TIP = 53;

const LabelWrap: React.FC<BoxProps & {
  children?: React.ReactNode;
  isTemplateMode?: boolean;
}> = (props) => (
  <Box height="22px" pad={{ horizontal: 'spacing6', vertical: 'xxxs' }} margin={{ left: props?.isTemplateMode ? 'xs' : '0' }} round="snackbarRound" {...props} />
);

interface Props {
  plan: Plan | HealthPlan;
  style?: React.CSSProperties;
  isFlat?: boolean;
  isTemplateMode?: boolean;
  testId?: string;
  isPlansHidden?: boolean;
  onSelectAction?: (value: PlanCardAction, id: string) => void;
  mode: PlanCardMode;
}

interface AnimatedWrapperProps {
  children: React.ReactNode;
  isFlat?: boolean;
}
const AnimatedWrapper :React.FC<AnimatedWrapperProps> = ({ children, isFlat}) => {
  if(isFlat) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <AnimatedContent flex="grow" justify="end">
      {children}
    </AnimatedContent>
  );
};

interface StatusLabelProps {
  testId: string;
  isTemplateMode?: boolean;
  background: string;
  text: string;
  children?: React.ReactNode;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ testId, isTemplateMode, background, text, children }) => (
  <Box direction="row" align="center" data-testId={`${testId}_wrapper`}>
    {children}
    <LabelWrap isTemplateMode={isTemplateMode} background={background}>
      <Text
        size="small"
        weight={500}
        style={{ lineHeight: '18px' }}
        color="textOnColor"
        data-testId={`${testId}_status_${text.toLowerCase().replace(/\s+/g, '_')}`}
      >
        {text}
      </Text>
    </LabelWrap>
  </Box>
);

const PlanCard: React.FC<Props> = ({
  plan, style, isFlat, isTemplateMode,
  onSelectAction, isPlansHidden, mode,
  testId = '', 
}) => {
  const planAsPlan = plan as Plan;
  const planAsHealthPlan = plan as HealthPlan;
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const { observingOrganization: { type } } = useCurrentOrganization();

  const handleToggleHovered = useCallback((value: boolean) => () => {
    setIsHovered(value);
  }, []);

  const goToPage = useCallback((path: string) => (event: React.SyntheticEvent) => {
    event.stopPropagation();
    if (isFlat) {
      return;
    }
    history.push(path);
  }, [history, isFlat]);

  const handleSelectAction = useCallback((value: PlanCardAction) => {
    if (onSelectAction) {
      onSelectAction(value, plan.id);
    }
  }, [onSelectAction, plan.id]);

  const onClickHandler = useMemo(() => {
    if (mode === PlanCardMode.Plan) {
      return isTemplateMode ? goToPage(ROUTES.TEMPLATE_BY_ID(plan.id)) : goToPage(ROUTES.PLAN_BY_ID(plan.id));
    } else {
      return isTemplateMode ? goToPage(ROUTES.HEALTH_PLAN_TEMPLATE_BY_ID(plan.id)) : goToPage(ROUTES.HEALTH_PLAN_BY_ID(plan.id));
    }
  }, [mode, isTemplateMode, goToPage, plan.id]);
  
  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  const statusLabel = useMemo(() => {
    switch (plan.status) {
    case PlanStatuses.DRAFT:
      return (
        <StatusLabel
          testId={testId}
          isTemplateMode={isTemplateMode}
          background="background3"
          text="Draft"
        >
          {plan.process && (
            <Text data-testId={`${testId}_percents`} color="textDisabled" weight={500}>
              {plan.process.passed}/{plan.process.total}
              ({Math.floor((plan.process.passed / plan.process.total) * 100) || 0}%)
            </Text>
          )}
        </StatusLabel>
      );
    case PlanStatuses.READY_FOR_SETUP:
      return (
        <StatusLabel
          testId={testId}
          isTemplateMode={isTemplateMode}
          background="success"
          text="Ready for Setup"
        />
      );
    default: return null;
    }
  }, [isTemplateMode, plan.process, plan.status, testId]);

  const topLabel = useMemo(() => {
    if (isTemplateMode) {
      return (
        <LabelContainer width="fit-content" direction="row" justify="end" round="snackbarRound" background="canvas">
          {isTemplateMode && (
            <Box>
              <Text
                weight={500}
                size="small"
                color="textDisabled"
                data-testId={`${testId}_template_label`}
                style={{ margin: `${theme.spacings.xxxs} 0 ${theme.spacings.xxxs} ${theme.spacings.xxxs}`, lineHeight: '18px' }}
              >
                Template
              </Text>
            </Box>
          )}
          {statusLabel}
        </LabelContainer>
      );
    } else {
      return (
        <LabelContainer width="fit-content" direction="row" round="snackbarRound" justify="end" >
          {statusLabel}
        </LabelContainer>
      );
    }
  }, [isTemplateMode, statusLabel, testId]);

  const planActions = useMemo(() => {
    const goToConfigurePage = isTemplateMode
      ? goToPage(ROUTES.TEMPLATE_SETUP_BY_ID(plan.id))
      : goToPage(ROUTES.PLAN_SETUP_BY_ID(plan.id));

    if ((plan.status === PlanStatuses.ACTIVE || plan.status === PlanStatuses.PENDING_ACTIVE) && isTemplateMode && !isSubsidiary) {
      return (
        <Box direction="row">
          <Box
            data-testId={`${testId}_templates_actions`}
            margin={{ right: 'spacing8' }}
          >
            <PlanCardTemplateActions
              isHovered={isHovered}
              isPlansHidden={isPlansHidden}
              onSelectAction={handleSelectAction}
              mode={PlanCardMode.Plan}
            />
          </Box>
        </Box>
      );
    }
    if (plan.status === PlanStatuses.READY_FOR_SETUP) {
      return (
        <AppButton
          testId={`${testId}_configure`}
          onClick={goToConfigurePage}
        >
          Configure
        </AppButton>
      );
    }
    return (
      <AppButton
        testId={`${testId}_edit_button`}
        onClick={goToConfigurePage}
      >
        Edit
      </AppButton>
    );
  }, [isTemplateMode, goToPage, plan.id, plan.status, isSubsidiary, testId, isHovered, isPlansHidden, handleSelectAction]);

  const healthPlanActions = useMemo(() => {
    const goToConfigurePage = isTemplateMode
      ? goToPage(ROUTES.HEALTH_PLAN_TEMPLATE_SETUP_BY_ID(plan.id))
      : goToPage(ROUTES.HEALTH_PLAN_SETUP_BY_ID(plan.id));
    if (plan.status === PlanStatuses.ACTIVE) {
      if (isTemplateMode) {
        return (
          <Box direction="row">
            <Box
              data-testId={`${testId}_templates_actions`}
              margin={{ right: 'spacing8' }}
            >
              <PlanCardTemplateActions
                isHovered={isHovered}
                isPlansHidden={isPlansHidden}
                onSelectAction={handleSelectAction}
                mode={PlanCardMode.HealthPlan}
              />
            </Box>

          </Box>
        );
      }

      return (
        <AppButton
          testId={`${testId}_view_button`}
        >
          View
        </AppButton>
      );
    }

    return (
      <AppButton
        testId={`${testId}_edit_button`}
        onClick={goToConfigurePage}
      >
        Edit
      </AppButton>
    );
  }, [isTemplateMode, goToPage, plan.id, plan.status, testId, isHovered, isPlansHidden, handleSelectAction]);

  const labelText = useMemo(() => {
    if (mode === PlanCardMode.Plan) {
      switch (planAsPlan.type) {
      case CardTypesEnum.HSA:
        return 'Health Savings Account';
      case CardTypesEnum.HCFSA:
        return 'Healthcare FSA (Limited Use)';
      case CardTypesEnum.DCAP:
        return 'Dependent Care FSA';
      case CardTypesEnum.HRA:
        return 'Health Reimbursement Arrangement(Limited Use)';
      case CardTypesEnum.TRANSIT:
        return 'Commuter - Transit';
      case CardTypesEnum.PARKING:
        return 'Commuter - Parking';
      default:
        return '';
      }
    } else {
      switch (planAsHealthPlan.types?.[0]) {
      case CardTypesEnum.DENTAL:
        return 'Dental';
      case CardTypesEnum.VISION:
        return 'Vision';
      case CardTypesEnum.RX:
        return 'RX';
      case CardTypesEnum.MEDICAL:
        return 'Medical';
      default:
        return '';
      }
    }
  }, [mode, planAsPlan.type, planAsHealthPlan.types]);

  return (
    <Wrapper
      isTemplate={isTemplateMode}
      isHovered={isHovered}
      data-testId={`${testId}_wrapper`}
      onClick={onClickHandler}
      onMouseEnter={handleToggleHovered(true)}
      onMouseLeave={handleToggleHovered(false)}
      background="canvas"
      round="container1Round"
      margin="spacing8"
      border={{ size: 'small', color: theme.colors.border2 }}
      style={style}
      isFlat={isFlat}
    >
      <StyledBox>
        <ContentWrapper round="container1Round" pad={{ right: 'spacing20', left: 'l', top: 'spacing20', bottom: isFlat ? 'spacing18' : '58px' }}>
          <Content>
            <IconBgBox justify="center" style={{ opacity: !isFlat && isHovered ? '0' : '0.12'}}>
              {
                mode === PlanCardMode.HealthPlan && planAsHealthPlan?.types?.length === 1 ? (
                  <PlanTypeIcon
                    type={planAsHealthPlan.types?.[0]}
                    data-testid={`${testId}_plan-type_icon_${planAsHealthPlan.types?.[0]}`}
                    color={colors.border}
                    size='180px'
                  />
                ) : mode === PlanCardMode.HealthPlan && planAsHealthPlan?.types?.length > 1 ? (
                  <></>
                ) : (
                  <PlanTypeIcon
                    type={planAsPlan.type}
                    data-testid={`${testId}_plan-type_icon_${planAsPlan.type}`}
                    color={colors.border}
                  />
                )
              }
            </IconBgBox>    
            {topLabel}        
            <AnimatedWrapper isFlat={isFlat}>
              <Box margin={{ bottom: 'spacing16' }} direction="row">
                {
                  mode === PlanCardMode.HealthPlan ? (
                    planAsHealthPlan?.types?.map((type) => (
                      <PlanTypeIcon
                        key={type}
                        type={type}
                        data-testid={`${testId}_plan-type_small_icon_${type}`}
                        size="large"
                        color={colors.cardTypes[type]}
                      />
                    ))
                  ) : (
                    <PlanTypeIcon
                      type={planAsPlan.type}
                      data-testid={`${testId}_plan-type_small_icon_${planAsPlan.type}`}
                      size="large"
                      color={colors.cardTypes[planAsPlan.type]}
                    />
                  )
                }
              </Box>
              <Box margin={{ bottom: 'small' }}>
                <PlanName
                  weight={700}
                  size="2xl"
                  color="textBody"
                  wordBreak="break-word"
                  data-testId={`${testId}_name`}
                  title={(plan.name || labelText)?.length > MAX_ALLOWED_LENGTH_SYMBOL_TIP ? plan.name || labelText : ''} 
                >
                  {plan.name || labelText}
                </PlanName>
              </Box>
            </AnimatedWrapper>
          </Content>
        </ContentWrapper>
        <Access
          accessRequirements={[
            {
              permission: Permissions.EDIT_DRAFT_PLAN,
            },
          ]}
        >
          <StyledActionBox
            direction="row"
            pad={{ left: 'l' }}
            data-testId={`${testId}_actions`}
          >
            {mode === PlanCardMode.Plan ? planActions : healthPlanActions}
          </StyledActionBox>
        </Access>
      </StyledBox>
      <PlaceholderWrapper
        height="7px"
        margin={{ horizontal: 'l'}}
      >
        {isTemplateMode && <DashesPlaceholder />}
      </PlaceholderWrapper>
    </Wrapper>
  );
};

export default PlanCard;
