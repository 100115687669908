import { OptionKey } from '@/common/types';
import { GroupByFilter, LAST_4_DIGIT_KEY, PlanYearReportFilterItems } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

export type ClaimReimbursementActivityGroupByType = [
  GroupByFilter | null,
  GroupByFilter | null,
];

export const CLAIM_REIMBURSEMENT_GROUP_BY_PLAN_YEAR: OptionKey[] = [PlanYearReportFilterItems.NULL];

export const CLAIM_REIMBURSEMENT_ACTIVITY_GROUP_BY_DEFAULT_VALUE
: ClaimReimbursementActivityGroupByType = [
  GroupByFilter.OrgField,
  GroupByFilter.Partner,
];

interface State {
  SSNOption: OptionKey;
  handleSetSSNOption: (value: OptionKey) => void;
  search: string;
  planYear: OptionKey[];
  groupBy: ClaimReimbursementActivityGroupByType;
  handleSetSearch: (value: string) => void;
  handleSetPlanYear: (value: OptionKey[]) => void;
  handleSetGroupBy: (groupBy: ClaimReimbursementActivityGroupByType) => void;
  handleClear: () => void;
}

export const useClaimReimbursementActivityReportFilterStore = storeCreator<State>((set) => ({
  search: '',
  SSNOption: LAST_4_DIGIT_KEY,
  planYear: CLAIM_REIMBURSEMENT_GROUP_BY_PLAN_YEAR,
  groupBy: CLAIM_REIMBURSEMENT_ACTIVITY_GROUP_BY_DEFAULT_VALUE,
  handleSetSSNOption: (value: OptionKey) => set(() => ({
    SSNOption: value,
  })),
  handleSetSearch: (value: string) => set(() => ({
    search: value,
  })),

  handleSetPlanYear: (value: OptionKey[]) => set(() => ({
    planYear: value,
  })),

  handleSetGroupBy: (groupBy: ClaimReimbursementActivityGroupByType) => set(() => ({
    groupBy,
  })),

  handleClear: () => set(() => ({
    SSNOption: LAST_4_DIGIT_KEY,
    search: '',
    planYear: CLAIM_REIMBURSEMENT_GROUP_BY_PLAN_YEAR,
    groupBy: CLAIM_REIMBURSEMENT_ACTIVITY_GROUP_BY_DEFAULT_VALUE,
  })),
}));
