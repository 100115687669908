import React, { useMemo } from 'react';

import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';

import useAccountManagersQuery from '../queries/useAccountManagers.query';
import useBusinessEntityTypesQuery from '../queries/useBusinessEntityTypes.query';
import useHsaCustodianQuery from '../queries/useHsaCustodian.query';

import GeneralInformationForm from './GeneralInformationForm';
import useGeneralInformationQuery from './useGeneralInformation.query';

interface Props {
  employerID: string;
  onDirty?: (isDirty: boolean) => void;
}

const GeneralInformation: React.FC<Props> = ({ employerID, onDirty }) => {
  const { formattedData, isLoading } = useGetEmployer();
  const { data: businessTypes, isLoading: areBusinessTypesLoading } = useBusinessEntityTypesQuery();
  const { saveGeneralInfo } = useGeneralInformationQuery(employerID);
  const { loading } = useSetupEmployerStore();
  const { data: managers, isLoading: areManagersLoading } = useAccountManagersQuery(employerID);
  const { data: hsaCustodians, isLoading: areHsaCustodiansLoading } = useHsaCustodianQuery();
  const isDataLoading = useMemo(() => isLoading
    || loading
    || areManagersLoading
    || areHsaCustodiansLoading
    || areBusinessTypesLoading
    || !formattedData.overridableProperties,
  [isLoading, loading, areBusinessTypesLoading, areHsaCustodiansLoading,
    areManagersLoading, formattedData.overridableProperties]);

  return (
    <GeneralInformationForm
      data={formattedData}
      managers={managers}
      businessTypes={businessTypes}
      hsaCustodians={hsaCustodians}
      onSubmit={saveGeneralInfo}
      onDirty={onDirty}
      isLoading={isDataLoading}
    />
  );
};

export default GeneralInformation;
