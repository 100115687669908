export interface LogoFile {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  preview?: string;
}

export enum LogoType {
  largeDark = 'DARK_LARGE',
  largeLight = 'LARGE',
  minimizedDark = 'DARK_MINIMIZED',
  minimizedLight = 'MINIMIZED',
  favicon = 'FAVICON',
}
