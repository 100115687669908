import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AchievementTracker,
  Box,
  costFormater,
  Enrollment,
  InfoOutlineIcon,
  Inscription,
  Preloader,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

import { useContributionAchievementsTracker } from './hooks/useContributionAchievementsTracker';
import { ContributionInfoBanner } from './ContributionInfoBanner';

const currentYear = dayjs().year();
const currentToday = dayjs().toDate();
export const CATCH_UP_TEXT = 'Once you reach age 55, you can contribute up to an extra $1,000.00 to your HSA';

interface Props {
  currentAccount?: Enrollment;
  isWithInfoBanner?: boolean;
}

export const ContributionAchievementsTracker: React.FC<Props> = ({
  currentAccount,
  isWithInfoBanner,
}) => {
  const { id: employeeId} = useParams<{ id: string }>();  
  const [taxYear, setTaxYear] = useState(`${currentYear}`);

  const accountId = useMemo(() => currentAccount?.id, [currentAccount]);
  const catchUpElection = useMemo(() => currentAccount?.catchUpElection, [currentAccount]);

  const {
    preparedContributionAchievements,
    lastDayForContribution,
    formattedData,
    contributionHistoryLoading,
    isWithCatchUp,
  } = useContributionAchievementsTracker({
    employeeId,
    taxYear,
    accountId,
    catchUpElection,
  });
  const isPrevYearAvailable = useMemo(() => {
    if (!lastDayForContribution) return false;

    const lastDay = dayjs(lastDayForContribution).format(DEFAULT_DATE_FORMAT);
    const today = dayjs(currentToday).format(DEFAULT_DATE_FORMAT);

    return today <= lastDay;
  }, [lastDayForContribution]);
  const taxYearOptions = useMemo(() => ([
    {
      key: `${currentYear}`,
      value: `${currentYear}`,
      title: `${currentYear}`,
    },
    ...isPrevYearAvailable ? [{
      key: `${currentYear - 1}`,
      value: `${currentYear - 1}`,
      title: `${currentYear - 1}`,
    }] : [],
  ]), [isPrevYearAvailable]);

  const currentValue = useMemo(
    () => costFormater(formattedData.currentValue, true),
    [formattedData],
  );

  return (
    <>
      <Box
        pad="spacing24"
        border={{ color: 'border2' }}
        round="s"
        background={{ color: 'canvas' }}
        height="fit-content"
        width="100%"
        data-testid="ContributionAchievementsTracker_wrapper"
        margin={{ bottom: isWithInfoBanner ? 'spacing16' : undefined }}
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          margin={{ bottom: 'spacing16' }}
          data-testid="ContributionAchievementsTracker_header"
        >
          <Text size="16px" weight={700}>
            {taxYear} Contributions: {currentValue}
          </Text>

          <Box width="control" margin={{ left: 'spacing8' }}>
            <SelectDropdown
              id="taxYear"
              testId="ContributionAchievementsTracker"
              name="taxYear"
              prefix="Year"
              singleMode
              activeTitle
              options={taxYearOptions}
              values={[taxYear]}
              onChangeValues={(values) => setTaxYear(values[0] as string)}
            />
          </Box>
        </Box>
        {contributionHistoryLoading
          ? (<Preloader />)
          : (
            <>
              <AchievementTracker
                items={preparedContributionAchievements}
                currentValue={formattedData.currentValue}
                testId="Contribution"
              />
              {isWithCatchUp && (
                <>
                  <Box
                    width="100%"
                    height="1px"
                    background={{ color: 'border1' }}
                    margin={{ vertical: 'spacing16' }}
                  />
                  <Box direction="row" align="center">
                    <Box margin={{ right: 'spacing8' }}>
                      <InfoOutlineIcon color="iconAccent" size="16px" />
                    </Box>
                    <Inscription lineHeight="18px" size="12px" weight={500}>
                      {CATCH_UP_TEXT}
                    </Inscription>
                  </Box>
                </>
              )}
            </>
          )}
      </Box>
      {isWithInfoBanner && (
        <ContributionInfoBanner
          limits={preparedContributionAchievements}
          isWithCatchUp={isWithCatchUp}
          taxYear={taxYear}
        />
      )}
    </>
  );
};
