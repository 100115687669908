import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  &:before {
    color: ${({ theme }) => theme.colors.danger};
    margin-right: 4px;
  }
  &.untouched:before {
    content: '*';
    font-weight: 600;
    font-size: 20px;
    height: 16px;
  }
  &.done:before {
    content: '\\e817';
    font-family: 'icons';
    color: ${({ theme }) => theme.colors.success};
    font-size: 14px;
  }
  &.not-filled:before {
    content: '\\e81f';
    font-family: 'icons';
    color: ${({ theme }) => theme.colors.danger};
    font-size: 12px;
    font-weight: 600;
  }
  &:not(:last-child) {
    padding-right: 2px;
    position: relative;
    &:after {
      content: '';
      width: 3px;
      height: 3px;
      background: black;
      border-radius: 50%;
      opacity: 0.15;
      margin-left: 4px;
    }
  }
  span:first-letter {
    text-transform: uppercase;
  }
`;

export const VALIDATION_STATUS_CLASSNAME = {
  DONE: 'done',
  NOT_FILLED: 'not-filled',
  UNTOUCHED: 'untouched',
};

interface Props {
  submitted?: boolean;
  testId?: string;
  valid: boolean;
  children?: React.ReactNode;
}

export const ValidatedRequiredField: React.FC<Props> = ({
  testId, submitted, valid, children,
}) => {
  const includeSubmitted = useMemo(() => !_.isUndefined(submitted), [submitted]);
  const validationStatusClassName = useMemo(() => {
    if (includeSubmitted) {
      if (submitted) {
        return valid
          ? VALIDATION_STATUS_CLASSNAME.DONE
          : VALIDATION_STATUS_CLASSNAME.NOT_FILLED;
      }
      return VALIDATION_STATUS_CLASSNAME.UNTOUCHED;
    } return valid
      ? VALIDATION_STATUS_CLASSNAME.DONE
      : VALIDATION_STATUS_CLASSNAME.NOT_FILLED;
  }, [includeSubmitted, submitted, valid]);
  return (
    <StyledBox
      className={validationStatusClassName}
      direction="row"
      height="20px"
      align="center"
      data-testid={testId || 'validated-required-field'}
    >
      {children}
    </StyledBox>
  );
};

export default ValidatedRequiredField;
