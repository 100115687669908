import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { EmployeeCardsListItem } from '@/modules/employer/components/Lockbox/lockbox.types';

import EmployeeMatchingMessage from './EmployeeMatchingMessage';
import RadioButtonCard from './RadioButtonCard';

interface Props {
  fields: EmployeeCardsListItem[];
  selectedEmployeeId?: string;
  onSelectEmployee: (employeeId?: string) => void;
  emptyState?: React.ReactNode;
}

const EmployeesList: React.FC<Props> = ({ fields, selectedEmployeeId, onSelectEmployee, emptyState }) => {
  const hasEmployees = useMemo(() => fields?.length, [fields]);

  return (
    <>
      {hasEmployees ? (
        fields?.map((field) => (
          <Box margin={{ top: 'spacing8' }} key={field?.id}>
            <RadioButtonCard
              testId={`employee-${field?.id}`}
              itemKey={field?.id}
              itemFields={field?.fields}
              isSelected={selectedEmployeeId === field?.id}
              onSelect={onSelectEmployee}
            >
              {field?.warnings?.map((warning) => (
                <Box key={warning} margin={{ top: 'spacing8' }}>
                  <EmployeeMatchingMessage matchingType={warning} />
                </Box>
              ))}
            </RadioButtonCard>
          </Box>
        ))
      ) : emptyState}
    </>
  );
};

export default EmployeesList;
