import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

interface WrapperProps {
  isDisabled?: string;
}

export const ProgressBarTitle = styled(Text)<WrapperProps>`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  user-select: none;
  margin-left: ${({ theme }) => theme.spacings.xs};
  line-height: 22px;
`;

interface NavButtonProps {
  isDisabled?: boolean;
}

export const NavButton = styled.button<NavButtonProps>`
  flex: 1;
  transition: all 0.15s;
  background-color: ${({ theme }) => theme.colors.canvas};
  border: none;
  border-radius: ${({ theme }) => theme.rounds.button1Round};
  height: 54px;
  padding: ${({ theme }) => theme.spacings.xxs}  ${({ theme }) => theme.spacings.s};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, isDisabled }) => (isDisabled ? 'none' : theme.colors.border1)};
  }
`;

export const Delimiter = styled(Box)`
  border: none;
  background-color: ${({ theme }) => theme.colors.border1};
  height: 1px;
`;
