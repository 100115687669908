import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Field, FieldTypes, useAutoLoadingData } from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { NON_ASSIGNABLE_VALUE, REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';
import { usePayoutDefinitionPlansQuery, usePayoutDefinitionQuery } from '@/modules/core/queries';
import { useFieldsWithDefaultValues } from '@/utils';

import { useTriggersQuery } from '../../queries';

import { TierField } from './useTiers';

interface Params {
  tiersValues?: TierField[];
  isLastItem?: boolean;
  defaultValues?: Partial<TierField>;
  handleClearValues?: (id: string) => void;
}
export const NA_KEY = 'null';
export const LAST_TRIGGER: Option = {
  key: NA_KEY,
  value: NON_ASSIGNABLE_VALUE,
  title: NON_ASSIGNABLE_VALUE,
  divide: true,
};

// const REQUIRED_VALIDATOR = yup.string().trim().nullable().required(REQUIRED_TEXT);

export const TRIGGERS_KEY = 'triggers';
export const LAST_ITEM_VALIDATOR = yup.array().test({
  test: (values) => {
    if (values) {
      if (!values.includes(NA_KEY)) {
        return false;
      }
    }
    return true;
  },
  message: 'Trigger N/A should be for last trigger',
});

export default (
  {
    tiersValues = [],
    defaultValues,
    isLastItem,
    handleClearValues,
  }: Params,
) => {
  const [activePage, setActivePage] = useState<number>(0);
  const [searchPayoutDefinition, setSearchPayoutDefinition] = useState<string>();
  const {
    fieldOptions: payoutDefinitions,
    isLoading,
    page,
    totalPages,
  } = usePayoutDefinitionQuery(
    undefined,
    activePage,
    searchPayoutDefinition || '',
    defaultValues?.payoutDefinition,
  );

  const { isLoading: isPlanLoading, planOptions } = usePayoutDefinitionPlansQuery(defaultValues?.payoutDefinition);

  const {
    pageNumber,
    searchString,
    formattedOptions,
    handleGetLoadingData,
    handleSearchString,
  } = useAutoLoadingData({
    options: payoutDefinitions,
    page,
    totalPages,
  });

  useEffect(() => {
    setActivePage(pageNumber);
   
  }, [pageNumber]);

  useEffect(() => {
    setSearchPayoutDefinition(searchString);
   
  }, [searchString]);

  const { fieldOptions } = useTriggersQuery();
  const formattedTriggers = useMemo(() => [LAST_TRIGGER, ...fieldOptions], [fieldOptions]);
  const fields = useMemo<Field[]>(() => {
    const currentFields: Field<string>[] = [{
      name: 'name',
      type: FieldTypes.Text,
      label: 'Name',
      placeholder: 'Enter name',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: defaultValues?.name || '',
    },
    {
      name: 'payoutDefinition',
      type: FieldTypes.AutocompleteDropdown,
      label: 'Payout definition',
      singleMode: true,
      options: formattedOptions,
      placeholder: 'Select payout definition',
      validator: yup.string().trim().required(REQUIRED_TEXT),
      isLoading,
      notLimitList: true,
      handleGetLoadingData,
      onSearch: handleSearchString,
      completeValue: defaultValues?.payoutDefinition || '',
      resetCompleteValue: () => handleClearValues,
      onClear: () => {
        if (handleClearValues && defaultValues?.payoutDefinition) {
          handleClearValues(defaultValues?.payoutDefinition);
        }
      },
    }, {
      name: TRIGGERS_KEY,
      type: FieldTypes.MultipleDropdown,
      singleMode: true,
      label: 'Trigger',
      options: formattedTriggers,
      placeholder: 'Select trigger',
      value: defaultValues?.triggers?.length ? defaultValues?.triggers : [`${NA_KEY}`],
      validator: isLastItem
        ? LAST_ITEM_VALIDATOR : VALIDATORS.TRIGGER_NOT_LAST_ITEM(tiersValues),
    },
    ...(!defaultValues?.triggers?.includes(NA_KEY) && defaultValues?.triggers?.length) ? [{
      name: 'isSendTriggerNotification',
      type: FieldTypes.Checkbox,
      label: 'Send trigger notification',
    }] as Field[] : [],
    {
      name: 'displayName',
      type: FieldTypes.Checkbox,
      label: 'Display name',
      value: defaultValues?.displayName || false,
      subFields: [
        {
          parentValue: true,
          name: 'nameToDisplay',
          type: FieldTypes.Text,
          label: 'Name to display',
          placeholder: 'Enter display name',
          validator: yup.string().required(REQUIRED_TEXT),
          defaultValue: defaultValues?.nameToDisplay || '',
        },
        {
          parentValue: true,
          name: 'planId',
          type: FieldTypes.AutocompleteDropdown,
          label: 'Plan',
          singleMode: true,
          options: planOptions,
          placeholder: 'Select plan',
          validator: yup.string().trim().required(REQUIRED_TEXT),
          isLoading: isPlanLoading,
          notLimitList: true,
          defaultValue: defaultValues?.planId || '',
          value: defaultValues?.planId || '',
          cleaningInitiator: defaultValues?.payoutDefinition,
        },
      ] as Field[]
    },
    
    ];
    const formatedFields = useFieldsWithDefaultValues(currentFields, defaultValues);
    return formatedFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, formattedTriggers, isLastItem, formattedOptions,
    handleGetLoadingData, handleSearchString, isLoading, tiersValues, isPlanLoading, planOptions]);

  return fields;
};
