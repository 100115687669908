import { OrganizationTypes } from '@/common/constants';
import { OptionKey, PaginationParams } from '@/common/types';

export const GET_COPAY_ITEM_POSTFIX = (prefix: string, index?: number | string) => `${prefix}_COPAY_ITEM_POSTFIX_${index !== 0 ? index || '' : 0}`;
export const COPAY_ITEM_KEY = {
  CATEGORY: 'category',
  AMOUNT: 'amount',
};

export enum CopayStatuses {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export interface CopayErrors {
  [key: string]: string;
}

export interface CopayItem {
  id: string;
  isSyntetic?: boolean;
  category: string;
  amount: string;
}
export interface CopayPayload {
  id?: string | number;
  category: string;
  amount: string;
}

export interface CopayGroupPayload {

  id?: string;
  basedOn?: string;
  name: string;
  validFrom: string | null;
  validTo: string | null;
  copays: CopayPayload[];
  isExpired?: boolean;
  organizationType?: OrganizationTypes;
}

export interface CopaysListPaginationParams extends PaginationParams{
  statuses?:OptionKey[];
  isExpired?: boolean;
}
