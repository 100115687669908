import React from 'react';

import { ReportSection } from '@/modules/employer/components/Reports/components/ReportSection/ReportSection';

import useInvoiceReports from './useInvoiceReports';

const Invoice = () => {
  const reports = useInvoiceReports();

  return (
    <ReportSection
      title="Invoice"
      reports={reports}
    />
  );
};

export default Invoice;
