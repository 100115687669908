import React, { useCallback, useEffect, useMemo,useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  FlexListHeader,
  FlexListItem,
  FlexListShowMore,
  ListItem,
  Text,
} from '@common-fe/common-fe';

import { usePayrollGroupTableItems } from './hooks/usePayrollGroupTableItems';
import { useCurrentPayrollGroupsStore, usePayrollGroupModalStore } from './stores';

const PER_PAGE = 10;
const PayrollGroupTable = () => {
  const { id } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(false);
  const {
    headers,
    formattedList,
    data,
    refetch,
    payrollGroupCodes,
    payrollGroupNames,
  } = usePayrollGroupTableItems(id);
  
  const handleChangeVisibility = usePayrollGroupModalStore((state) => state.handleChangeVisibility);
  const handleSetEditablePayrollGroup = usePayrollGroupModalStore((state) => state.handleSetEditablePayrollGroup);
  const handleSetAdditionalAction = usePayrollGroupModalStore((state) => state.handleSetAdditionalAction);
  const handleSetPayrollGroupCodes = usePayrollGroupModalStore((state) => state.handleSetPayrollGroupCodes);
  const handleSetPayrollGroupNames = usePayrollGroupModalStore((state) => state.handleSetPayrollGroupNames);
  const handleSetCurrentPayrollGroups = useCurrentPayrollGroupsStore((state) => state.handleSetCurrentPayrollGroups);

  const handleEditPayrollGroup = useCallback((id: string) => {
    const currentPayrollGroup = data?.find((item) => item.id === id);
    
    if (handleSetEditablePayrollGroup) handleSetEditablePayrollGroup({
      id: `${currentPayrollGroup?.id}`,
      name: currentPayrollGroup?.name,
      code: currentPayrollGroup?.code,
    });
    if (handleSetAdditionalAction) handleSetAdditionalAction(refetch);
    handleChangeVisibility(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const showedList = useMemo(() => {
    return showMore ? formattedList : formattedList.slice(0, PER_PAGE);
  }, [showMore, formattedList]);
  
  const moreItemsCount = useMemo(() => 
    formattedList.length - showedList.length, 
  [formattedList.length, showedList.length]);

  useEffect(() => {
    if (handleSetAdditionalAction) handleSetAdditionalAction(refetch);
    if (handleSetPayrollGroupNames) handleSetPayrollGroupNames(payrollGroupNames);
    if (handleSetPayrollGroupCodes) handleSetPayrollGroupCodes(payrollGroupCodes);
  }, [
    handleSetAdditionalAction,
    handleSetPayrollGroupCodes,
    handleSetPayrollGroupNames,
    payrollGroupCodes,
    payrollGroupNames,
    refetch,
  ]);

  useEffect(() => {
    if (data) handleSetCurrentPayrollGroups(data);
    return () => {
      handleSetCurrentPayrollGroups(undefined);
    };
  }, [
    data,
    handleSetCurrentPayrollGroups,
  ]);
  
  return (
    <>
      <Box
        background="canvas"
        round="container1Round"
        data-testid="PayrollGroupTable_container"
      >
        {!!formattedList.length && (
          <Box margin="small" pad="small" background="module" round="container1Round">
            <Text
              margin={{ top: 'spacing8', bottom: 'spacing12', left: 'spacing16' }}
              size="large"
              weight="bold"
              color="textBody"
            >
              {`Payroll Group: ${formattedList.length}`}
            </Text>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formattedList.map((row: ListItem) => (
                <FlexListItem
                  key={row.id}
                  id={row.id}
                  fields={row.fields}
                  shifted
                  options={[
                    {
                      name: 'Edit',
                      onClick: handleEditPayrollGroup,
                    }
                  ]}
                />
              ))}
              {(!!moreItemsCount) && (
                <FlexListShowMore
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show More ({moreItemsCount})
                </FlexListShowMore>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PayrollGroupTable;
