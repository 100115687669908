import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import { Document } from '@/common/types';
import useConvertTiff from '@/components/elements/DocumentViewer/hooks/useConvertTiff';

import ImageViewer from './ImageViewer';

interface Props {
  receipt?: Document;
  zoom: number;
  rotate: number;
}

const TiffViewer: React.FC<Props> = ({ receipt, zoom, rotate }) => {
  const url = receipt?.link;
  const { pngDocuments, isLoading } = useConvertTiff({ url });

  if (isLoading) {
    return (
      <Preloader testId="tiff-preloader" />
    );
  }

  return (
    <Box data-testId="tiff-viewer">
      {pngDocuments?.map((pngDocument, index) => (
        <Box key={index}>
          <ImageViewer
            rotate={rotate}
            zoom={zoom}
            receipt={pngDocument}
          />
        </Box>
      ))}
    </Box>
  );
};

export default TiffViewer;
