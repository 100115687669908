import { useCallback } from 'react';
// import { useAuth } from '@/modules/user/hooks';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';

import { COVERAGES_LIST_QUERY_KEY } from '../../queries/useCoverageLevels.query';

export const QUERY_KEY = 'CREATE_COVERAGE_LEVEL';

export interface CoverageRawPayload {
  name: string;
  code: string;
  organization_id?: string;
  organization_path?: string;
}
export interface CoveragePayload {
  name: string;
  code: string;
}

const useCreateCoverageLevelQuery = () => {
  const { observingOrganization: { id, path } } = useCurrentOrganization();

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation(
    QUERY_KEY,
    (newEnrollment: CoverageRawPayload) => api
      .post(PATHS.COVERAGES, newEnrollment),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(COVERAGES_LIST_QUERY_KEY);
      },
    },
  );

  const handleSave = useCallback(async (value: CoveragePayload) => {
    await mutateAsync({
      ...value,
      organization_id: id,
      organization_path: path,
      // organization_id: user?.user_organization?.organization_id,
      // organization_path: user?.user_organization?.organization_path,
    });
  }, [id, mutateAsync, path]);
  return {
    handleSave,
    isLoading,
  };
};

export default useCreateCoverageLevelQuery;
