import React, { useMemo } from 'react';
import { Box, Image, MastercardIcon, Stack, Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import VisaIcon from '@/assets/bank-icons/visa.svg';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { BankTypeEnum, CardDto } from '../Cards.types';
import useCardReplaceModalStore from '../store/useCardReplaceModal.store';

import CardPlaceholderBackground from './CardPlaceholderBackground';
import { CardStatusLabel } from './CardStatusLabel';

const Wrapper = styled(Box)`
  position: relative;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.03);
`;

const Container = styled(Box)`
  text-align: left;
`;

const StyledImage = styled(Image)`
  width: auto !important;
`;

const CARD_EXP_FORMAT = 'MM/YY';

interface Props {
  card: CardDto;
  logoUrl?: string;
  borderColor?: string;
  active?: boolean;
}

const SHADOW_STYLE = {
  boxShadow: '18px 8px 25px rgba(0, 38, 102, 0.2)',
};

export const CardItem: React.FC<Props> = ({ card, active, borderColor, logoUrl }) => {
  const {
    state: { cardID },
  } = useCardReplaceModalStore();

  const cardShadow = useMemo(
    () => (card.id === cardID || active ? SHADOW_STYLE : undefined),
    [card.id, cardID, active]
  );

  const cardLogo = useMemo(() => logoUrl || whiteLabelingConfig?.images?.cardLogo
  || whiteLabelingConfig?.logo , [logoUrl]);

  return (
    <Container>
      <Stack anchor="bottom-right" style={{ marginBottom: '22px' }}>
        <Wrapper
          background="canvas"
          pad="spacing24"
          round="container1Round"
          border={{ size: '2px', color: borderColor }}
          style={cardShadow}
        >
          <Box direction="row" justify="between" align="center" margin={{ bottom: 'spacing32' }}>
            {cardLogo ? (
              <StyledImage
                className="image"
                src={cardLogo}
                width={128}
                alt="logo"
                height={33}
              />
            ) : (
              <Box />
            )}

            <CardStatusLabel card={card} />
          </Box>
          <Box margin={{ bottom: 'spacing12' }}>
            <Text size="20px" weight="bold">
              {card.holder?.name}
            </Text>
          </Box>
          <Box direction="row" align="center" margin={{ bottom: 'spacing32' }}>
            <Text margin={{ right: 'spacing12' }} size="20px" weight="bold">
              ••••
            </Text>
            <Text margin={{ right: 'spacing12' }} size="20px" weight="bold">
              ••••
            </Text>
            <Text margin={{ right: 'spacing12' }} size="20px" weight="bold">
              ••••
            </Text>
            <Text size="20px" weight="bold">
              {card.last4Number}
            </Text>
          </Box>
          <Box direction="row" justify="between" align="center" style={{ zIndex: 1 }}>
            {card.bankType === BankTypeEnum.VISA ? (
              <StyledImage className="bank-logo" src={VisaIcon} width="30" height="20" />
            ) : (
              <MastercardIcon width="30" height="20" />
            )}
            <Text>{card.expDate ? dayjs(card.expDate).format(CARD_EXP_FORMAT) : '-'}</Text>
          </Box>
        </Wrapper>
        <Box pad={{  bottom: active ? 'spacing2' : undefined }} style={{ zIndex: -1 }}>
          <CardPlaceholderBackground />
        </Box>
      </Stack>
    </Container>
  );
};
