import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Inscription,
  Text,
  WarningIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ServiceConfig } from '../queries/useServiceConfigs.query';
import PaymentRuleItem from '../ServiceConfig/PaymentRuleItem';

const ScrollBox = styled(Box)`
  max-height: 605px;
  overflow-y: scroll;
`;

const GradientBox = styled(Box)`
  position: absolute;
  bottom: 0;
  height: 106px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
`;

const MAX_ALLOWED_RULES = 3;

export interface ExpandedItemLevel {
  title?: string;
  subTitle?: string;
  subSubTitle?: string;
}

interface Props extends ExpandedItemLevel {
  isFirstItem: boolean;
  testId?: string;
  value?: ServiceConfig;
}
const ExpandedItemFirstLevel: React.FC<Props> = ({
  isFirstItem,
  testId,
  value,
}) => {
  const [isEndOfContainer, setEndOfContainer] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const container = containerRef.current as any;

      if (container?.scrollTop + container?.clientHeight + 3 > container?.scrollHeight) {
        setEndOfContainer(true);
      } else {
        setEndOfContainer(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container = containerRef.current as any;
    container?.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box data-testid={testId} direction="column" pad={{ horizontal: 's', bottom: 'l' }}>
      <Box
        border={{ side: 'top', color: 'border1', size: '1px' }}
        direction="column"
        fill
        pad={{
          top: !value?.description ? 'xs' : '0',
        }}
      >
        {value?.description && (
          <Text
            size="medium"
            color="textBody"
            weight={400}
            margin={{
              top: 's',
            }}
          >
            {value?.description}
          </Text>
        )}
        {
          !value?.paymentRules?.length && (
            <Box
              background="warningContainer"
              pad={{ vertical: 'xs', horizontal: 'm' }}
              margin={{ top: value?.description ? 'm' : 'xs' }}
              round="container2Round"
              border={{ color: 'warningBorder', size: 'small' }}
            >
              <Box direction="row" align="center">
                <WarningIcon />
                <Inscription lineHeight="20px" margin={{ left: '10px' }}>
                  Payment rules are not specified for this service
                </Inscription>
              </Box>
            </Box>
          )
        }
        <ScrollBox ref={containerRef}>
          {
            value?.paymentRules?.map((item, index) => (
              <PaymentRuleItem
                key={item.planId}
                value={item}
                isFirstItem={isFirstItem}
                ruleNumber={index + 1}
              />
            ))
          }
          {value?.paymentRules && value?.paymentRules?.length > MAX_ALLOWED_RULES
            && !isEndOfContainer && <GradientBox />}
        </ScrollBox>
      </Box>
    </Box>
  );
};
export default ExpandedItemFirstLevel;
