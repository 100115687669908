import React, { useEffect } from 'react';
import { Box, Image } from '@common-fe/common-fe';

import LogoIcon from '@/assets/logos/login-logo.svg';
import { useOrganizationSetupLogo } from '@/modules/employer/components/SetupEmployer/Branding/hooks';
import { useEmailIllustrationsStore } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/stores';

const BrandingPreview = () => {
  const setEmailIllustrationsState = useEmailIllustrationsStore((state) => state.setState);
  const {
    largeLightLogo,
  } = useOrganizationSetupLogo();

  useEffect(() => {
    if (largeLightLogo) {
      setEmailIllustrationsState({ emailLogoLink: largeLightLogo });
    }
  }, [largeLightLogo, setEmailIllustrationsState]);

  return (
    <Box
      background="module"
      round="container2Round"
      width="176px"
      height="60px"
      data-testid="branding-preview"
      align="center"
      justify="center"
      margin={{ top: 's' }}
      pad={{ vertical: '10px', horizontal: 'm' }}
    >
      <Image data-testid="branding-preview_image" width={140} height={40} fit="contain" src={largeLightLogo || LogoIcon} alt="branding-logo" />
    </Box>
  );
};
export default BrandingPreview;
