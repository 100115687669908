import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/hooks';
import { useFieldsWithDefaultValues } from '@/utils';

import { usePreferredMailingAdressStore } from '../stores';

const REQUIRED_VALIDATOR = yup.string().trim().required(REQUIRED_TEXT);
const VALIDATOR = yup.string().trim();
export default (isEditMode?: boolean) => {
  const defaultFields = usePreferredMailingAdressStore((state) => state.sourceState);
  const { statesOptions } = useStatesQuery();
  const fields = useMemo<Field[]>(() => [

    {
      name: 'firstAddressLine',
      type: FieldTypes.Text,
      label: 'Address Line 1',
      // disabled: !isEditMode,
      placeholder: 'Address Line 1',
      disabled: !isEditMode,
      showRequireIcon: true,
      validator: REQUIRED_VALIDATOR,
    },
    {
      name: 'secondAddressLine',
      type: FieldTypes.Text,
      label: 'Address Line 2',
      disabled: !isEditMode,
      placeholder: 'Address Line 2',
      validator: VALIDATOR,
    },
    {
      name: 'city',
      type: FieldTypes.Text,
      label: 'City',
      disabled: !isEditMode,
      showRequireIcon: true,
      placeholder: 'Enter City',
      validator: REQUIRED_VALIDATOR,
      defaultValue: '',
    },
    {
      name: 'state',
      type: FieldTypes.AutocompleteDropdown,
      label: 'State',
      singleMode: true,
      showRequireIcon: true,
      disabled: !isEditMode,
      // disabled: !isEditMode,
      placeholder: 'Select state',
      validator: REQUIRED_VALIDATOR,
      options: statesOptions,
    },
    {
      name: 'zipCode',
      type: FieldTypes.ZipCode,
      label: 'ZIP code',
      placeholder: 'Enter ZIP code',
      disabled: !isEditMode,
      showRequireIcon: true,
      visible: false,
      validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
        (val) => (val && (val.length === 10 || val.length === 5))
            || (val === '')).required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: 'countryCode',
      type: FieldTypes.Text,
      label: 'Country code',
      disabled: true,
      showRequireIcon: true,
      placeholder: 'Enter Country code',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: 'US',
    },
  ], [isEditMode, statesOptions]);
  const formatedFields = useFieldsWithDefaultValues(fields, defaultFields);

  return formatedFields;
};
