import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Preloader,Text } from '@common-fe/common-fe';

import ExpensesBreakdown from '@/modules/employee/Employee/EnrollmentBalances/components/ExpensesBreakdown';

import { useExpensesStatisticsQuery } from '../hooks/useExpensesStatistics.query';

import { ExpenseDetailsHeader } from './ExpenseDetailsHeader';
import { ExpensesList } from './ExpensesList';

interface Props {
  hasSubmitClaimButton?: boolean;
}

export const ExpenseDetails: React.FC<Props> = ({ hasSubmitClaimButton }) => {
  const { id: employeeId } = useParams<{ id: string }>();
  const { formattedData, isLoading } = useExpensesStatisticsQuery({
    employee_id: employeeId,
  });
  return (
    <Box data-testId="ExpenseDetails-wrapper">
      <ExpenseDetailsHeader hasSubmitClaimButton={hasSubmitClaimButton} />
      <Box
        direction="column"
        background="module"
        pad="spacing24"
        round="container1Round"
      >
        <Box
          pad={{ vertical: 'spacing8' }}
          margin={{ bottom: 'spacing16' }}
          height={{ max: '40px' }}
        >
          <Text weight="bold" size="16px">Expense Breakdown</Text>
        </Box>
        {isLoading
          ? (
            <Preloader testId="loading-spinner" />
          )
          : (
            <>
              <Box>
                <ExpensesBreakdown
                  options={formattedData?.expenses || []}
                  totalExpense={formattedData?.totalExpenses}
                  inlineMode
                />
              </Box>
            </>
          )}
      </Box>
      <Box>
        <ExpensesList />
      </Box>
    </Box>
  );
};
