import React from 'react';
import {
  Box,   Button, Modal,
  Preloader, RoundPendingIcon, SiblingBoxesWithSpacing, Text,
} from '@common-fe/common-fe';

import { ConfirmButton, Header, Wrapper } from './CloseCardConfirmationModal.styles';

interface Props {
  header: string;
  helpText: string;
  buttonText?: string;
  children?: React.ReactNode;
  visible: boolean;
  onSetVisible?: (value: boolean) => void;
  notHideClickOutside?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitting?: boolean;
}

const CloseCardConfirmationModal: React.FC<Props> = ({
  onSetVisible,
  visible,
  header,
  children,
  helpText,
  buttonText,
  onSubmit,
  notHideClickOutside,
  onCancel,
  submitting,
}) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    if (onSetVisible) onSetVisible(false);
  };
  return (
    <Modal
      visible={visible}
      onSetVisible={onSetVisible}
      notHideClickOutside={notHideClickOutside}
    >
      <Wrapper>
        <Box 
          align="center"
          justify='center'
          width="72px"
          height="72px">
          <RoundPendingIcon size="48px" color="warning" />
        </Box>
        
        <Header>{header}</Header>

        <Text margin={{ bottom: 'spacing24' }}>{helpText}</Text>
        {children}
        <Box direction="row" align="center">
          {
            onCancel && (
              <SiblingBoxesWithSpacing width="140px">
                <Button
                  secondary
                  onClick={onCancel}
                  disabled={submitting}
                  style={{ height: '40px' }}
                  label={<Text weight="bold" color="textBody">Cancel</Text>}
                />
              </SiblingBoxesWithSpacing>
            )
          }
          {buttonText && (
            <SiblingBoxesWithSpacing>
              <ConfirmButton
                label={
                  submitting ? (
                    <Box width="100%" align="center">
                      <Preloader color="white" />
                    </Box>
                  ) : <Text weight="bold" color="textOnColor">{buttonText}</Text>
                }
                primary
                style={{
                  minWidth: '140px',
                  maxWidth: '140px',
                  paddingInline: '12px',
                  border: 'none',
                }}
                onClick={handleSubmit}
              />
            </SiblingBoxesWithSpacing>
          )}
        </Box>
      </Wrapper>
    </Modal>
  );
};

export default CloseCardConfirmationModal;
