import { storeCreator } from '@/utils/store/store.utils';

interface State {
  pendingStatusType: boolean;
  activeStatusType: boolean;
  setPendingStatusType: (value: boolean) => void;
  setActiveStatusType: (value: boolean) => void;
  resetTypes: () => void;
}

const useStore = storeCreator<State>((set) => ({
  validForm: false,
  pendingStatusType: false,
  activeStatusType: false,

  setPendingStatusType: (value: boolean) => set(() => ({
    pendingStatusType: value,
    activeStatusType: false,
  })),
  setActiveStatusType: (value: boolean) => set(() => ({
    activeStatusType: value,
    pendingStatusType: false,
  })),
  resetTypes: () => set(() => ({
    pendingStatusType: false,
    activeStatusType: false,
  })),
}));
const useSaveType = () => {
  const store = useStore();

  return {
    pendingStatus: store.pendingStatusType,

    activateSubmitted: !!store.activeStatusType,
    pendingSubmitted: !!store.pendingStatusType,
    activeStatus: store.activeStatusType,
    handleResetType: store.resetTypes,
    handleSetPendingStatusType: store.setPendingStatusType,
    handleSetActiveStatusType: store.setActiveStatusType,
  };
};
export default useSaveType;
