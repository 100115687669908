import React from 'react';
import { Field,FieldTypes, Inscription } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE,PermissionKey, PermissionState } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

const useGetPermissionToEditOverridableField = (key: PermissionKey) => {
  const { overridablePropertiesPermissions } = useAuthStore();

  if (!overridablePropertiesPermissions) return {};

  const permissionState = overridablePropertiesPermissions[key];

  if (permissionState === PermissionState.HIDE) {
    return {
      type: FieldTypes.Node,
      node: <Inscription color="textBody">{EMPTY_FIELD_VALUE}</Inscription>,
      disabled: true,
      showRequireIcon: false,
      validator: undefined,
      value: undefined,
      defaultValue: undefined,
    } as Partial<Field>;
  }

  if (permissionState === PermissionState.VIEW) {
    return {
      disabled: true,
      showRequireIcon: false,
      validator: undefined,
    } as Partial<Field>;
  }

  if (permissionState === PermissionState.EDIT) {
    return {};
  }

  return {};
};

export default useGetPermissionToEditOverridableField;
