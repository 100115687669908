import { useMemo } from 'react';

import { CardDto, CardStatusEnum } from '../Cards.types';

import { useGetCardsQuery } from './useGetCards.query';

interface Args {
  employeeId?: string | null;
  cardId?: string | null;
}

export default (args: Args) => {
  const { employeeId, cardId } = args;
  const {
    isLoading,
    refetch,
    cardMap,
  } = useGetCardsQuery(employeeId);

  const card = useMemo(() => Object.keys(cardMap)
    .reduce((cardList, holderName) => [
      ...cardList,
      ...Object.values(cardMap[holderName])
        .reduce(
          (arr, status) => [...arr, ...status],
          [] as CardDto[],
        ),
    ], [] as CardDto[])
    .find((item) => `${item.id}` === cardId),
  [cardMap, cardId]);

  const preparedCard = useMemo(() => ({
    status: card?.status as CardStatusEnum,
    last4Number: card?.last4Number || '',
    holder: card?.holder || {},
    id: `${card?.id}`,
    bankType: card?.bankType,
    primary: !!card?.primary,
    expDate: card?.expDate,
    cardRequestDate: card?.cardRequestDate,
    mailedDate: card?.mailedDate,
    activationDate: card?.activationDate,
  }), [card]);
  return {
    card: preparedCard as CardDto,
    isLoading,
    refetch,
  };
};
