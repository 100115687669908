import React from 'react';
import { Box, costFormater, Text } from '@common-fe/common-fe';

import { TaxType } from '../../../contributions.types';
import { RecordPayload } from '../../ScheduledContribution.types';

interface Props extends RecordPayload {
  isLastItem?: boolean;
  taxType?: string | null;
  
}
const ScheduledContributionRecordsItem: React.FC<Props> = ({ id, name,taxType, accounts, isLastItem }) => {
  return (
    <Box
      pad={isLastItem ?undefined : { bottom: 'xs' }}
      margin={isLastItem ?undefined :{ bottom: 'xs' }}
      border={isLastItem ? undefined : { side: 'bottom', size: 'small', color: 'border2' }}
    >
      {accounts.map((item, index) => (
        <Box direction="row" key={`${id} ${item.id}`}>
          {index === 0 ? (
            <Box style={{ flex: 3 }}>
              <Text weight={700}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow:'ellipsis'
                }}>{name}
              </Text>
            </Box>
          ) : (
            <Box style={{ flex: 3 }}></Box>
          )}

          <Box style={{ flex: 2 }}>
            <Text style={{
              textOverflow: 'ellipsis',
              overflow:'hidden',
              whiteSpace: 'nowrap',
            }}>{item.name}
            </Text>
          </Box>
          <Box style={{ flex: 2 }}>
            <Text>
              {costFormater(item.employeeAmount, {
                isPrecision: true,
              })}
            </Text>
          </Box>
          <Box style={{ flex: 2 }}>
            <Text>
              {costFormater(item.employerAmount, {
                isPrecision: true,
              })}
            </Text>
          </Box>
          <Box style={{ flex: 7 }}>
            <Text>
              {taxType === TaxType.POST_TAX ? 'Post Tax' : null}
              {taxType === TaxType.PRE_TAX ? 'Pre Tax' : null}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ScheduledContributionRecordsItem;
