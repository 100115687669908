import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { SERVICE_CONFIGS_QUERY_KEY } from '../../AddServicePaymentModal/queries/useCreateServicePayment.query';

export default () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (id: string) => api.delete(`${PATHS.SERVICE_CONFIGS}/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SERVICE_CONFIGS_QUERY_KEY);
      },
    },
  );
  return {
    isLoading,
    handleDelete: mutateAsync,
  };
};
