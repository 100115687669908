import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';

import { GracePeriodPayload,useStore } from './stores/useGracePeriod.store';
import { useGracePeriodFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
  isTemplateMode?: boolean;
}
const GracePeriod: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);

  const fields = useGracePeriodFields(false, isTemplateMode, isEditMode);
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<GracePeriodPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  if (!fields.length) {
    return null;
  }
  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        fields={fields}
        testId="plan_coverage"
        showError={showErrors || activateValidate || saveValidate}
        editMode={isEditMode}
        onChangeValues={setValues}
        onDirty={onDirty}
        // isDisabledMode
        isThinMode
      />
    </Box>
  );
};
export default GracePeriod;
