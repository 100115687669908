import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { OrganizationTypes, RequestHeaders } from '@/common/constants';
import PATHS from '@/common/paths';
import { Option, Pagination } from '@/common/types';

export const GET_ORGANIZATIONS_KEY = 'useGetOrganizationsQueryKey';

const SIZE_PER_PAGE = 50;
const PAGE = 0;
const ACTIVE_STATUS = 'ACTIVE';

export interface OrgResponse {
  id: string;
  name?: string;
  short_name?: string;
  is_organization_orphan?: boolean;
  external_identifier?: string;
  organization_status_type?: string;
  valid_from?: string;
  parent_id?: string;
  path?: string;
}

interface Props {
  searchValue?: string;
  type?: OrganizationTypes;
  parentId?: string;
  partnerId?: string | null;
  enable?: boolean;
  ids?: string[];
  orgPath?: string;
  perPage?: number;
  isStandAlone?: boolean;
}

export const useOrganizationsQuery = (props: Props = {}) => {
  const {
    searchValue,
    type,
    parentId,
    enable = true,
    ids,
    orgPath,
    perPage,
    isStandAlone,
    partnerId
  } = props;
  const params = useMemo(() => ({
    ...(searchValue
      ? { search_value: searchValue }
      : {}),
    ...(parentId
      ? { parent_id: parentId }
      : {}),
    ...partnerId ? {
      partner_id: partnerId
    }: {},

    ...(ids?.length
      ? { ids: ids.join(',') }
      : {}),
    size: perPage || SIZE_PER_PAGE,
    page: PAGE,
    type:  type || OrganizationTypes.partner,
  
    statuses: ACTIVE_STATUS,
  }), [searchValue, parentId, partnerId, ids, perPage, type]); 
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [GET_ORGANIZATIONS_KEY, params, enable, orgPath],
    enable
      ? () => api.get<Pagination<OrgResponse>>(PATHS.ORGANIZATIONS, {
        params,
        headers: {
          ...(orgPath
            ? { [RequestHeaders.observedOrganizationPath]: orgPath }
            : { }),

          ...(isStandAlone ?  { [RequestHeaders.observedOrganizationPath]: null }
            : {})
 
        },
      })
      : () => null,
  );

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data.content || [];
    return list.map((item) => ({
      key: String(item.id),
      value: item.name,
      title: item.name,
    }));
  }, [data]);

  const total = useMemo(() => data?.data?.content?.length || 0, [data]);

  return {
    data: formatedData,
    rawData: data?.data?.content || [],
    total,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
