import { useCallback,useMemo, useState } from 'react';
import { ListItem } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';

import useDisableCarrierConfigQuery from '../ViewCarrierConfigModal/queries/useDisableCarrierConfig.query';

const REQUIRED_PERMISSIONS = [{ permission: Permissions.DISABLE_CARRIER_CONFIG }];
const ITEM_TITLE_KEY = 'carrierName';
const DEFAULT_DISABLING_ITEM = '';

const useDisable = (fields: ListItem[], onSuccess?: () => void) => {
  const [disableItem, setDisableItem] = useState(DEFAULT_DISABLING_ITEM);
  const { disableCarrierConfig: handleDisableItem } = useDisableCarrierConfigQuery(onSuccess);

  const resetDisabling = useCallback(() => {
    setDisableItem(DEFAULT_DISABLING_ITEM);
  }, []);

  const disableItemTitle = useMemo(() => fields.find((row) => row.id === disableItem)
    ?.fields.find((field) => field.key === ITEM_TITLE_KEY)?.title,
  [disableItem, fields]);

  const hasAccess = useHasAccess(REQUIRED_PERMISSIONS);
  const disableOption = useMemo(() => {
    if (!hasAccess) {
      return null;
    }

    return {
      name: 'Disable',
      isWarning: true,
      onClick: (id: string) => setDisableItem(id),
    };
  }, [hasAccess]);

  return {
    disableItem,
    disableOption,
    disableItemTitle,
    handleDisableItem,
    resetDisabling,
  };
};

export default useDisable;
