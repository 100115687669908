import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  DropdownButton,
  Hint,
  PopupWrapper,
  RadioButton,
  SiblingBoxesWithSpacing,
  Text,
  Tip,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Option } from '@/common/types';
import theme from '@/styles/theme';

const LONG_NAME_COUNT = 16;

export const IconWrapper = styled(Box)`
  font-size: 20px;
  box-shadow: none;
`;

export interface NestedOption extends Option {
  subOptions: Option[];
}

export type NestedOptions = [string | null, string | null];

interface Props {
  testId: string;
  title: string;
  onChange?: (values: NestedOptions) => void;
  options: NestedOption[];
  values: NestedOptions;
  customWidth?: string;
  isDisabled?: boolean;
}

const defaultNestedOptions: NestedOptions = [null, null];

export const SortByDropdown: React.FC<Props> = ({
  testId,
  onChange,
  title,
  options,
  values,
  customWidth,
  isDisabled,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [currentValues, setCurrentValues] = useState<NestedOptions>(defaultNestedOptions);
  const [currentSubTitle, setCurrentSubTitle] = useState('');

  const isSubOptionChecked = useCallback((
    optionKey: string,
    subOptionKey: string,
  ) => {
    if (currentValues[0] === optionKey && currentValues[1] === subOptionKey) {
      return true;
    }

    return false;
  }, [currentValues]);

  const handleCheckOption = useCallback((optionKey: string, subOptionKey: string) => {
    setCurrentValues([optionKey, subOptionKey]);
  }, []);

  const handleApply = useCallback(() => {
    if (onChange) onChange(currentValues);
    setExpanded(false);
  }, [currentValues, onChange]);

  const handleCancel = useCallback(() => {
    if (values[0] && values[1]) {
      setCurrentValues([values[0], values[1]]);
    } else {
      setCurrentValues(defaultNestedOptions);
    }
    setExpanded(false);
  }, [values]);

  const handleToggleOpenClose = useCallback(() => {
    if (!expanded && !isDisabled) {
      setExpanded(true);
      return;
    }

    setExpanded(false);
    handleCancel();
  }, [expanded, isDisabled, handleCancel]);

  const subTitleMaker = useCallback(() => {
    const currentOption = options.find((option) => option.key === values[0]);
    const currentSubOption = currentOption?.subOptions
      .find((subOption) => subOption.key === values[1]);

    const firstPart = `${currentOption?.title}` || '';
    const secondPart = `${currentSubOption?.title}` || '';

    setCurrentSubTitle(`${firstPart} ${secondPart}`);
  }, [options, values]);

  const dropdownButton = useMemo(() => (
    <DropdownButton
      style={{
        width: customWidth || '220px',
        paddingBlock: 0,
      }}
      expanded={expanded}
      data-testid={`${testId}_SortBy_dropdown`}
      onClick={handleToggleOpenClose}
      disabled={isDisabled}
    >
      <Text
        color="textDisabled"
        margin={{ right: 'spacing4' }}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {title}:
      </Text>
      <Tip
        plain
        dropProps={{ align: { left: 'right' } }}
        content={currentSubTitle.length > LONG_NAME_COUNT && !expanded
          ? (
            <Box
              style={{
                display: 'inline-block',
                width: 'auto',
              }}
              background="background3"
              border={{ size: 'small', color: 'border' }}
              pad={{ horizontal: 'spacing6', bottom: 'spacing2' }}
            >
              <Text
                size="small"
                color="textOnColor"
              >
                {currentSubTitle}
              </Text>
            </Box>
          ) : null}
      >
        <Text
          color={isDisabled ? 'textDisabled' : 'textAccent'}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {currentSubTitle}
        </Text>
      </Tip>
      <IconWrapper>
        {expanded ? (
          <ChevronUpIcon
            data-testid={`${testId}_SortBy_ChevronDown`}
            color={isDisabled ? theme.colors.iconSecondary : theme.colors.iconPrimary}
          />
        ) : (
          <ChevronDownIcon
            data-testid={`${testId}_SortBy_ChevronUp`}
            color={isDisabled ? theme.colors.iconSecondary : theme.colors.iconPrimary}
          />
        )}
      </IconWrapper>
    </DropdownButton>
  ), [currentSubTitle, customWidth, expanded,
    handleToggleOpenClose, isDisabled, testId, title]);

  useEffect(() => {
    if (values[0] && values[1]) {
      setCurrentValues([values[0], values[1]]);
    }
    subTitleMaker();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <PopupWrapper
      onVisible={setExpanded}
      controlledVisible={expanded}
      isDisabled={isDisabled}
      flat
      flex
      style={{
        marginTop: '2px',
        left: 0,
        width: '300px',
      }}
      buttonStyle={{
        padding: 0,
      }}
      testId={`${testId}_SortBy_open-button`}
      content={(
        <Box
          width="300px"
          round={{ size: 'spacing8' }}
          background="canvas"
          data-testid={`${testId}_SortBy_wrapper`}
          elevation="active"
        >
          <Box pad={{ horizontal: 'spacing16', top: 'spacing16', bottom: 'spacing4' }}>
            {options.map((option) => (
              <Box key={option.key}>
                <Box margin={{ bottom: 'spacing12' }}>
                  <RadioButton
                    name={`${option.value}`}
                    checked={currentValues[0] === option.key}
                    label={(
                      <Text size="14px" weight={700} color="textBody">
                        {option.title}
                      </Text>
                    )}
                    onChange={() => handleCheckOption(`${option.key}`, `${option.subOptions[0].key}`)}
                  />
                </Box>
                <Box pad={{ left: '36px' }}>
                  {option.subOptions.map((subOption, i) => (
                    <Box key={subOption.key} margin={{ bottom: 'spacing12' }}>
                      <RadioButton
                        name={`${subOption.value}`}
                        checked={isSubOptionChecked(`${option.key}`, `${subOption.key}`)}
                        label={(
                          <Text size="14px" color="textBody">
                            {subOption.title}
                          </Text>
                        )}
                        onChange={() => {
                          setCurrentValues([`${option.key}`, `${subOption.key}`]);
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            width="100%"
            height="1px"
            background={{ color: 'border1' }}
          />

          <Box
            direction="row"
            align="center"
            pad="spacing16"
          >
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_SortBy_cancel`}
                buttonType="secondary"
                type="button"
                width="128px"
                onClick={handleCancel}
              >
                Cancel
              </AppButton>
            </SiblingBoxesWithSpacing>
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_SortBy_apply`}
                type="button"
                width="128px"
                onClick={handleApply}
              >
                Apply
              </AppButton>
            </SiblingBoxesWithSpacing>
          </Box>
        </Box>
      )}
    >
      {isDisabled
        ? (
          <Hint
            hintAlign="vertical"
            hintElement={dropdownButton}
            marginContent="m"
          >
            <Box direction="row" justify="center" align="center">
              <Text textAlign="center">
                When you're changing priorities,
                the list of entities can only be sorted by priority.
              </Text>
            </Box>
          </Hint>
        ) : (
          dropdownButton
        )}
    </PopupWrapper>
  );
};
