import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router';
import {
  AppButton,
  Box,
  Field,
  FieldValues,
  FlexControlledForm,
  NewTabIcon,
  Preloader,
  Text,
  WarningIcon,
  WarnModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import styled from 'styled-components';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import ROUTES from '@/common/routes';
import ModalWrapper from '@/components/wrappers/ModalWrapper';
import { useSnackbar } from '@/modules/core/hooks';
import { useEnrollmentFormFields } from '@/modules/employee/Employee/AddEnrollmentModal/hooks/useEnrollmentFormFields';
import { useIdentifiersFormFields } from '@/modules/employee/Employee/AddEnrollmentModal/hooks/useIdentifiersFormFields';
import { useGeneralInfoStore } from '@/modules/employee/Employee/PersonalInformation/GeneralInfo/stores';
import { PolicyStatus } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';
import theme from '@/styles/theme';

import { useGetEmployeeById } from '../hooks';

import { useGetEnrollmentSetupData } from './hooks/useGetEnrollmentSetupData';
import { useCreateEnrollment } from './queries/useCreateNewEnrollment.query';
import { AddEnrollmentForm } from './enrollmentForms.types';

const SSN_ERROR_TEXT = 'SSN is required to enroll in an HSA. Participant does not have an SSN. Add an SSN then reprocess the HSA enrollment.';
const WarnText1 = 'Most of the time we process enrollment data immediately, but there are cases that it may take 3-5 minutes or even more.';
const WarnText2 = 'Please wait for a moment and then refresh the page, your Enrollment should be already submitted.';
const AddSnackbarText = 'We have started processing enrollment details.';
const AddSnackbarSubtext = 'It may take 3-5 minutes, then refresh the page.';

const ENROLLMENT_BUTTON_WIDTH = '140px';

const StyledButtonsWrapp = styled(Box)`
  > div:not(:first-child) {
    margin-left: 12px;
  }
`;

const InfoBox = styled(Box)`
  position: relative;
  border-top: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% - 24px);
    height: 2px;
    background-color: ${({ theme: t }) => t.colors.warning};
    border-radius: ${({ theme: t }) => t.spacings.spacing12};
  }
`;

interface Props {
  isWithoutButton?: boolean;
  visible?: boolean;
  onSetVisible?: (value: boolean) => void;
  isEdit?: boolean;
  planId?: string;
  perPayEmployee?: number;
  perPayEmployer?: number;
  enrollmentStatus?: string;
  employeeName?: string;
  enrollmentElection?: number;
  hasSubmitClaimButton?: boolean;
  children?: React.ReactNode;
  statuses?: PolicyStatus[];
  lastCoverageEndDate?: string | null;
}

export const AddEnrollmentModal: React.FC<Props> = ({
  perPayEmployee,
  perPayEmployer,
  isWithoutButton,
  visible,
  onSetVisible,
  isEdit,
  planId,
  enrollmentStatus,
  employeeName,
  enrollmentElection,
  hasSubmitClaimButton,
  statuses,
  children,
  lastCoverageEndDate,
}) => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { data: employeeData } = useGetEmployeeById();
  const fullName = useGeneralInfoStore((state) => state.state.fullName);
  const employeeAge = useMemo(() => Math.abs(dayjs(employeeData?.birthDate, DEFAULT_DATE_FORMAT).diff(dayjs(), 'years') || 0), [employeeData?.birthDate]);

  const [errorModal, setErrorModal] = useState(false);
  const [ssnErrorValue, setSsnErrorValue] = useState<string>();
  const [employeeIdentifiersSetup, setEmployeeIdentifiersSetup] = useState(false);
  const [employeeEnrollmentSetup, setEmployeeEnrollmentSetup] = useState(false);

  const [isSubmit, setSubmit] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [formValues, setFormValues] = useState<Partial<AddEnrollmentForm>>({});

  const { handleAddPermanentSnackbar } = useSnackbar();
  const currentModalVisible = useMemo(() => (isWithoutButton && visible
    ? visible
    : modalVisible), [isWithoutButton, modalVisible, visible]);

  const currentSetModalVisible = useMemo(() => (isWithoutButton && onSetVisible
    ? onSetVisible
    : setModalVisible), [isWithoutButton, onSetVisible]);

  const { onSave, isLoading: onSaveLoading } = useCreateEnrollment();

  const {
    SSN,
    EEID,
    PEID,
    lastName,
    employerName,
    partnerName,
    isLoading,
    plansOptions,
    currentPlan,
    minElectionAmount,
    maxElectionAmount,
    planLoading,
    isAddingEnrollmentAvailable,
  } = useGetEnrollmentSetupData(formValues?.plan || planId, isEdit);

  const identifiersFormFields = useIdentifiersFormFields({
    SSN,
    EEID,
    PEID,
    lastName,
    employerName,
    partnerName,
  });

  const enrollmentFormFields = useEnrollmentFormFields({
    plans: plansOptions,
    currentPlan: formValues.plan,
    currentEnrollmentStatus: formValues.enrollment_status,
    planYear: currentPlan?.planYear,
    planStartDate: currentPlan?.planStartDate,
    planEndDate: currentPlan?.planEndDate,
    catchUpAge: currentPlan?.catchUpAge,
    catchUpElection: currentPlan?.catchUpElection,
    lastCoverageEndDate,
    minElectionAmount,
    maxElectionAmount,
    isEdit,
    enrollmentStatus,
    enrollmentElection,
    employeeAge,
    statuses,
    ssnErrorValue,
    perPayEmployee,
    perPayEmployer,
    limitedUse: formValues?.limited_use,
  });

  const editPlanName = useMemo(
    () => plansOptions?.find((item) => item.key === formValues.plan)?.title || '',
    [formValues.plan, plansOptions],
  );

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {
    setFormValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap: FieldValues = {};
      const getErrors = (currentFields: Field<string>[]) => {
        currentFields.forEach((field) => {
          if (field.subFields) {
            getErrors(field.subFields);
          }
          validatorMap[field.name] = field.validator;
        });
      };
      getErrors(fields);

      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  }, []);
 
  const handleChangeEnrollmentsValues = useCallback((values: Partial<AddEnrollmentForm>) => {
    changeValues(values, enrollmentFormFields, setEmployeeEnrollmentSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxElectionAmount, minElectionAmount, currentPlan]);

  const handleChangeIdentifiersValues = useCallback((values: Partial<AddEnrollmentForm>) => {
    changeValues(values, identifiersFormFields, setEmployeeIdentifiersSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAllFormsValid = useMemo(() => employeeEnrollmentSetup && employeeIdentifiersSetup,
    [employeeEnrollmentSetup, employeeIdentifiersSetup]);

  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    try {
      if (isAllFormsValid) {
        await onSave(formValues as AddEnrollmentForm);
        if (isEdit) {
          handleAddPermanentSnackbar({
            text: `${editPlanName} modified successfully.`,
            closeIcon: true,
          });
        } else {
          handleAddPermanentSnackbar({
            text: AddSnackbarText,
            subtext: AddSnackbarSubtext,
            closeIcon: true,
            width: '400px',
          });
        }
        currentSetModalVisible(false);
      }
    } catch (e) {
      const message = _.get(e, 'response.data.elevate_error_message', '') as string;

      if (message.includes(SSN_ERROR_TEXT)) {
        return setSsnErrorValue(formValues?.plan);
      }
      setErrorModal(true);
    }
  }, [
    currentSetModalVisible,
    formValues,
    handleAddPermanentSnackbar,
    isAllFormsValid,
    editPlanName,
    isEdit,
    onSave,
  ]);

  const currentTitle = useMemo(() => {
    const title = isEdit ? 'Modify Enrollment' : 'Add Enrollment';

    return `${title} for ${fullName || employeeName}`;
  }, [employeeName, fullName, isEdit]);

  useEffect(() => {
    if (!currentModalVisible) {
      setSubmit(false);
    }
  }, [currentModalVisible]);

  return (
    <>
      {isWithoutButton ? null : (
        <StyledButtonsWrapp direction="row">
          <AppButton
            buttonType="secondary"
            type="submit"
            testId="AddEnrollmentModal-open-modal"
            onClick={() => currentSetModalVisible(true)}
            disabled={!isAddingEnrollmentAvailable}
            style={{ paddingInline: theme.spacings.spacing12 }}
            width={ENROLLMENT_BUTTON_WIDTH}
          >
            Add Enrollment
          </AppButton>
          {children}
          {hasSubmitClaimButton ? (
            <AppButton
              testId="Redirect_to_reimburse_me_page"
              onClick={() => window.open(
                `${ROUTES.REIMBURSE_ME(employeeId)}${window.location.search}`,
                '_blank',
              )}
              rightIcon={<NewTabIcon />}
            >
              Submit Claim
            </AppButton>
          ) : null}
        </StyledButtonsWrapp>
      )}
      <ModalWrapper
        testId="add-enrollment"
        visible={currentModalVisible}
        onSetVisible={currentSetModalVisible}
        title={currentTitle}
      >
        <WarnModal
          testId="new-enrollment"
          visible={errorModal}
          onSetVisible={() => {
            currentSetModalVisible(false);
            setErrorModal(false);
          }}
          header="Oops enrollment not updated"
          helptext={`There was an unexpected error and we couldn't save the data.
        Please check your enrollment details and try again. If the issue persists, please reach out to your administrator.`}
          buttonText="Cancel"
          submitButtonNode={(
            <AppButton
              testId="new-enrollment_modal_submit"
              width="control"
              onClick={() => setErrorModal(false)}
            >
              Try again
            </AppButton>
          )}
          paddingless
        />
        <Box
          background="module"
          pad="medium"
          data-testid="AddEnrollmentModal-form-wrapper"
          round="container1Round"
        >
          {
            isLoading
              ? (
                <Preloader />
              )
              : (
                <>
                  <Box
                    width="100%"
                    height="fit-content"
                    background="canvas"
                    round="container1Round"
                    margin={{ bottom: 'spacing24' }}
                  >
                    <FlexControlledForm
                      fields={identifiersFormFields}
                      formTitle="Identifiers"
                      editMode
                      isModalType
                      showError={isSubmit}
                      onChangeValues={handleChangeIdentifiersValues}
                    />
                  </Box>
                  <Box
                    width="100%"
                    height="fit-content"
                    background="canvas"
                    round="container1Round"
                    margin={{ bottom: !isEdit ? 'spacing24' : undefined }}
                  >
                    {
                      planLoading
                        ? (
                          <Preloader />
                        )
                        : (
                          <FlexControlledForm
                            fields={enrollmentFormFields}
                            formTitle="Enrollment"
                            editMode
                            isModalType
                            showError={isSubmit}
                            onChangeValues={handleChangeEnrollmentsValues}
                          />
                        )
                    }
                  </Box>
                  {!isEdit && (
                    <InfoBox
                      width="100%"
                      height="fit-content"
                      background="canvas"
                      round="container1Round"
                      border={{ color: 'border2', size: 'small' }}
                      pad="spacing12"
                    >
                      <Box
                        pad={{ horizontal: 'spacing24', vertical: 'spacing16' }}
                        border={{ color: 'warningBorder', size: 'small' }}
                        background="warningContainer"
                        round="container2Round"
                      >
                        <Box direction="row" align="center" margin={{ bottom: 'spacing8' }}>
                          <WarningIcon />
                          <Text weight={700} color="textBody" margin={{ left: 'spacing8' }} style={{ lineHeight: '20px' }}>
                            Adding enrollment may take 3-5 minutes.
                          </Text>
                        </Box>
                        <Text style={{ lineHeight: '20px' }}>
                          {WarnText1}
                          <br />
                          {WarnText2}
                        </Text>
                      </Box>
                    </InfoBox>
                  )}
                </>
              )
          }

        </Box>
        <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
          <Box margin={{ right: 'spacing24' }}>
            <AppButton
              width="140px"
              buttonType="secondary"
              testId="AddEnrollmentModal-close-modal"
              onClick={() => currentSetModalVisible(false)}
            >
              Cancel
            </AppButton>
          </Box>
          <AppButton
            width="133px"
            onClick={handleSubmit}
          >
            {
              isLoading || onSaveLoading
                ? <Preloader color="white" />
                : 'Submit'
            }
          </AppButton>
        </Box>
      </ModalWrapper>
    </>
  );
};
