import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useOnlyForDev } from '@/utils';

import { usePlanNameStore } from '../PlanDefinition/PlanName/stores';
import { usePlanByIdQuery } from '../queries';
import { usePlanLoadingStore } from '../stores';
import { getFormattedPlan } from '../utils';

import usePlanSetupFieldsSetter from './usePlanSetupFieldsSetter';
import usePlanSetupPermissions, { getPlanFieldState } from './usePlanSetupPermissions';
import usePlanSetupSetter from './usePlanSetupSetter';

export const PLAN_YEAR_END_DATE_KEY = 'PLAN_YEAR_END_DATE';
export const COVERAGE_END_DATE_KEY = 'COVERAGE_END_DATE';

export const useCurrentPlan = () => {
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const setLoading = usePlanLoadingStore((state) => state.setLoading);
  const setPlanLoadedId = usePlanLoadingStore((state) => state.setPlanLoadedId);
  const planLoadedId = usePlanLoadingStore((state) => state.planLoadedId);
  const {
    handleSetState: handleSetFieldsState,
    handleSetSourceState: handleSetSourceFieldsState,
  } = usePlanSetupFieldsSetter();
  const isOnlyForDev = useOnlyForDev();
  const { data: plan, isLoading, isSuccess } = usePlanByIdQuery(id, (planResponse) => {
    const persmissions = getPlanFieldState(planResponse, isOnlyForDev);
    handleSetSourceFieldsState(persmissions);
    handleSetFieldsState(persmissions);
  });
  const { handleSetState } = usePlanSetupSetter();
  useEffect(() => {
    setLoading(true);
  }, [isSuccess, setLoading]);
  const planType = useMemo(() => plan?.account_type.account_type, [plan]);
  const priorPlanYear = useMemo(() => plan?.plan_year?.prior_plan_year, [plan]);
  const priorPlan = useMemo(() => plan?.prior_plan, [plan]);

  const planStatus = usePlanNameStore((state) => state.sourceState?.planStatus);

  const fieldsState = usePlanSetupPermissions(plan, isOnlyForDev);
  
  // TODO: bugfix/EL-16639 Verify if the bug is not reproduced
  useEffect(() => {
    if (plan && planLoadedId !== plan.id && setPlanLoadedId) {
      const formattedPlan = getFormattedPlan(plan);
      setPlanLoadedId(plan.id);
      handleSetState(formattedPlan);      
    }
    if(plan) {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);
  

  // useEffect(() => {
  //   if (plan) {
  //     const formatedPlan = getFormatedPlan(plan);
  //     handleSetState(formatedPlan);
  //     setLoading(false);
  //   }
  // }, [plan, handleSetState, setLoading]);

  return {
    fieldsState,
    isLoading: id ? isLoading : false,
    data: plan,
    planName: plan?.name?.name,
    planType,
    planStatus,
    priorPlanYear,
    priorPlan,
  };
};
