import React, { useCallback, useEffect, useMemo } from 'react';
import { Prompt, useParams, useRouteMatch } from 'react-router-dom';
import {
  BasicPropertiesIcon,
  Box,
  CardsIcon,
  ClockTickIcon,
  CommunicationIcon,
  ContactsSecurityIcon,
  FeaturesIcon,
  FilesImportsIcon,
  FinancialDetailsIcon,
  HandIcon,
  OrgLockIcon,
  PageWrapper,
  ReportsTOCIcon,
  SetupOutlinedIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
// import useHasSubsidiaryInEnv from '@/utils/hooks/useHasSubsidiaryInEnv';
import { BlockWrapper } from '@/components/wrappers';
import ActivityLog from '@/modules/ActivityLog';
import { Access } from '@/modules/core/components';
import { useBreadcrumbs, useHistory } from '@/modules/core/hooks';
import { useHasAccess } from '@/modules/core/hooks';
import { SETUP_CONTAINER_UI_ID } from '@/modules/core/hooks/useDetectActiveTOCModule';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import {
  useSetupEmployerStore,
  useStore,
} from '@/modules/employer/store/useSetupEmployer.store';

import { CancelEmployerTerminationModal } from '../EmployerTermination/CancelEmployerTerminationModal';
import { CancelEmployerTerminationSuccessModal } from '../EmployerTermination/CancelEmployerTerminationSuccessModal';
import { EmployerTerminationModal } from '../EmployerTermination/EmployerTerminationModal';
import { EmployerTerminationSuccessModal } from '../EmployerTermination/EmployerTerminationSuccessModal';

import ClaimsSettings from './ClaimsSettings/ClaimsSettings';
import { Communication } from './Communication/Communication';
import EmailConfiguration from './Communication/EmailConfiguration';
import EmailTemplateConfigurationList from './Communication/EmailConfiguration/EmailTemplateConfiguration/EmailTemplateConfigurationList';
import { MultiFactorAuthentication } from './ContactsSecurity/MultiFactorAuthentication';
import DangerZone from './DangerZone/DangerZone';
import { EmployeeId } from './EmployeeId/EmployeeId';
import { Features } from './Features/Features';
import FilesAndImports from './FilesAndImports/FilesAndImports';
import FinalSetUp from './FinalSetUp/FinalSetUp';
import { useSaveValidate } from './FinalSetUp/hooks';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import { MobileAppCustomization } from './MobileAppCustomization/MobileAppCustomization';
import OrganisationLock from './OrganisationLock/OrganisationLock';
import Reports from './Reports/Reports';
import TechnicalSettings from './TechnicalSettings/TechnicalSettings';
import Address from './Address';
import { Branding } from './Branding';
import { CardSetup } from './Cards';
import { ContactsSecurity } from './ContactsSecurity';
import Employer from './Employer';
import FinancialDetails from './FinancialDetails';
import { HelpSection } from './HelpSection';
import { useSetupEmployerMode } from './hooks';
import { LinkManagement } from './LinkManagement';
import { useSaveTypeStore } from './stores';

const REQUIRED_PERMISSIONS = [{ permission: Permissions.ORG_LOCK }];
const HorizontalLine = styled(Box)`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.border1};
  margin: ${({ theme }) => theme.spacings.spacing12} 0px;
`;

interface Props {
  isPartnerMode?: boolean;
  isLocked?: boolean;
  onScrollHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const EmployerSetupPage: React.FC<Props> = ({ onScrollHandler, isLocked }) => {
  // const hasSubsidiaryInEnv = useHasSubsidiaryInEnv();
  const hasAccess = useHasAccess(REQUIRED_PERMISSIONS);
  const hasAccessTerminateEmployer = useHasAccess([
    { organizationType: OrganizationTypes.partner },
    { organizationType: OrganizationTypes.system },
  ]);
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  const { path } = useRouteMatch();
  const isSystemPage = useMemo(() => path === ROUTES.SYSTEM_VIEW
    || path === ROUTES.SYSTEM_EDIT
    || path === ROUTES.SYSTEM, [path]);
  const isPartnerPage = useMemo(() => path === ROUTES.PARTNERS_CREATE
    || path === ROUTES.PARTNERS_EDIT()
    || path === ROUTES.PARTNERS_VIEW(), [path]);
  const isEmployerPage = useMemo(() => path === ROUTES.EMPLOYERS_VIEW
    || path === ROUTES.EMPLOYERS_CREATE
    || path === ROUTES.EMPLOYERS_EDIT(), [path]);
  const isDistributorPage = useMemo(() => path === ROUTES.DISTRIBUTORS_CREATE
    || path === ROUTES.DISTRIBUTORS_EDIT()
    || path === ROUTES.DISTRIBUTORS_VIEW(), [path]);
  const isSubsidiaryPage = useMemo(() => path === ROUTES.SUBSIDIARY_CREATE
    || path === ROUTES.SUBSIDIARY_EDIT()
    || path === ROUTES.SUBSIDIARY_VIEW(), [path]);
  const accessRequirements = useMemo(() => {
    if (isSystemPage) {
      return [
        {
          organizationType: OrganizationTypes.system,
          permission: Permissions.VIEW_ER,
        },
      ];
    }

    if (isPartnerPage || isDistributorPage) {
      return [
        {
          organizationType: OrganizationTypes.company,
          isException: true,
        },
      ];
    }

    return [];
  }, [isDistributorPage, isPartnerPage, isSystemPage]);
  const { id } = useParams<{ id: string }>();
  const { editMode } = useSetupEmployerMode();
  const {
    basicProperties,
    contactsSecurity,
    financialDetails,
    fileManagerSettings,
    cards,
  } = useSaveValidate();
  const { employer } = useStore((state) => state);

  const breadcrumbs = useMemo(() => ([{
    route: isSystemPage ? ROUTES.SYSTEM_VIEW : ROUTES.EMPLOYERS_VIEW,
    title: isSystemPage ? 'Set up System' : `Set up ${employer?.employerName || ''}`,
  }]), [isSystemPage, employer]);
  const {
    clearLists,
    setStatus,
  } = useSetupEmployerStore();
  const { formattedData, refetch } = useGetEmployer();

  const status = formattedData.organizationStatusType;
  const { activateSubmitted, pendingSubmitted } = useSaveTypeStore();
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = React.useState(false);

  const isTerminationAllowed = useMemo(
    () => hasAccessTerminateEmployer
      && isEmployerPage
      && (status === EmployerStatus.Active || status === EmployerStatus.PendingTermination),
    [hasAccessTerminateEmployer, isEmployerPage, status],
  );

  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || status === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, status]);

  useBreadcrumbs(breadcrumbs, [employer, isSystemPage], { backBtn: true });

  useEffect(() => {
    setStatus(status as EmployerStatus);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (editMode && !formattedData.isTerminated) {
      window.addEventListener('beforeunload', (e) => {
        const confirmationMessage = 'You have unsaved changes, are you sure you want to leave?';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      });
    }
  }, [editMode, formattedData]);

  useEffect(() => () => {
    clearLists();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // useEffect(() => {
  //   if (isSubsidiaryPage && !hasSubsidiaryInEnv) {
  //     goHome();
  //   }
  // }, [isSubsidiaryPage, hasSubsidiaryInEnv, goHome]);
  return (
    <Access
      accessRequirements={accessRequirements}
      accessDeniedAction={goHome}
    >
      <PageWrapper
        id={SETUP_CONTAINER_UI_ID}
        onScroll={onScrollHandler}
        style={{ paddingInline: 0 }}
      >
        <Prompt
          when={
            editMode && shouldConfirmOnClose
            && !formattedData.isTerminated && !(activateSubmitted || pendingSubmitted)
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <BlockWrapper
          flatMode
          title="Basic Properties"
          icon={<BasicPropertiesIcon color="iconAccent" />}
          moduleAnchorId="basic_properties"
          {...(editMode ? {
            description: [{
              note: 'Required to activate or save the organization:',
              requiredFields: basicProperties.groups[0].fields,
            }],
          } : {})}
          isFirstChild
        >
          <Employer id={id} onDirty={setShouldConfirmOnClose} />
          <Address employerID={id} onDirty={setShouldConfirmOnClose} />
          <GeneralInformation employerID={id} onDirty={setShouldConfirmOnClose} />
          <EmployeeId onDirty={setShouldConfirmOnClose} />
          <Branding onDirty={setShouldConfirmOnClose} />
          {
            isPartnerPage && (
              <EmailConfiguration />
            )
          }
          <EmailTemplateConfigurationList orgId={id} />
          {
            isPartnerPage && (
              <MobileAppCustomization onDirty={setShouldConfirmOnClose} />
            )
          }
          <LinkManagement onDirty={setShouldConfirmOnClose} />
          <HelpSection onDirty={setShouldConfirmOnClose} />
        </BlockWrapper>

        <HorizontalLine />

        <BlockWrapper
          title="Contacts/Security"
          icon={<ContactsSecurityIcon color="iconAccent" />}
          moduleAnchorId="contacts_security"
          {...(editMode && contactsSecurity.groups[0].fields.length > 0 ? {
            description: [{
              note: contactsSecurity.groups[0]!.description,
              requiredFields: contactsSecurity.groups[0]!.fields,
            }],
          } : {})}
          flatMode
        >
          <ContactsSecurity organizationId={formattedData?.id} disabled={isEditRestricted} />
          <MultiFactorAuthentication />
        </BlockWrapper>

        <HorizontalLine />

        <BlockWrapper
          flatMode
          title="Communication"
          icon={<CommunicationIcon color="iconAccent" />}
          moduleAnchorId="communication"
        >
          <Communication onDirty={setShouldConfirmOnClose} isPartnerPage={isPartnerPage} />
        </BlockWrapper>

        <HorizontalLine />

        <BlockWrapper
          flatMode
          title="Financial Details"
          icon={<FinancialDetailsIcon color="iconAccent" />}
          moduleAnchorId="financial_details"
          {...(editMode ? {
            description: [
              {
                note: 'To activate the organization select an option for:',
                requiredFields: financialDetails.groups[0].fields,
              },
              {
                note: 'Add at least 1 bank account and select a bank account for "Funding replenishment" to activate the organization:',
                requiredFields: financialDetails.groups[1].fields,
              },
            ],
          } : {})}
        >
          <FinancialDetails onDirty={setShouldConfirmOnClose} />
        </BlockWrapper>

        <HorizontalLine />

        <BlockWrapper
          flatMode
          title="Features"
          icon={<FeaturesIcon color="iconAccent" />}
          moduleAnchorId="features"
        >
          <Features disabled={isEditRestricted || isSubsidiaryPage} payrollGroupsDisabled={isEditRestricted} />
        </BlockWrapper>

        <HorizontalLine />

        <BlockWrapper
          title="Cards"
          icon={<CardsIcon color="iconAccent" />}
          moduleAnchorId="cards"
          {...(editMode ? {
            description: [{
              note: 'Required to activate the organization (applies only if the "Cards" is selected):',
              requiredFields: cards.groups[0].fields,
            }],
          } : {})}
        >
          <CardSetup
            disabled={isEditRestricted || isSubsidiaryPage}
            onDirty={setShouldConfirmOnClose}
          />
        </BlockWrapper>

        <HorizontalLine />
        <BlockWrapper
          flatMode
          title="Reports"
          icon={<ReportsTOCIcon color="iconAccent" />}
          moduleAnchorId="reports"
          optional="Configuring separately"
        >
          <Reports
            isPartnerMode={isPartnerPage || isDistributorPage}
            isDisabled={isEditRestricted}
          />
        </BlockWrapper>
        <HorizontalLine />
        {isSubsidiaryPage ? null : (
          <>
            <BlockWrapper
              flatMode
              title="Files/Imports"
              icon={<FilesImportsIcon color="iconAccent" />}
              moduleAnchorId="files_imports"
              {...(editMode && fileManagerSettings.groups[0].fields.length ? {
                description: [
                  {
                    note: 'Required to activate the organization:',
                    requiredFields: fileManagerSettings.groups[0].fields,
                  },
                ],
              } : {})}
            >
              <FilesAndImports isPartnerMode={isPartnerPage || isDistributorPage}/>
            </BlockWrapper>

            <HorizontalLine />

            <BlockWrapper
              flatMode
              title="Technical Settings"
              icon={<SetupOutlinedIcon />}
              moduleAnchorId="technical_settings"
            >
              <TechnicalSettings />
            </BlockWrapper>

            <HorizontalLine />

            <BlockWrapper
              flatMode
              title="Claims"
              icon={<HandIcon />}
              moduleAnchorId="claims_settings"
            >
              <ClaimsSettings />
            </BlockWrapper>

            <HorizontalLine />
          </>
        )}

        <BlockWrapper
          flatMode
          title="Activity Log"
          icon={<ClockTickIcon color="iconAccent" style={{ width: '24px' }} />}
          moduleAnchorId="activity_log"
          defaultExpandedValue={false}
        >
          <ActivityLog />
        </BlockWrapper>

        <HorizontalLine />

        <FinalSetUp
          isSubsidiaryPage={isSubsidiaryPage}
          isPartnerPage={isPartnerPage}
          employerName={employer?.employerName}
          status={status}
          isActivationStarted={formattedData?.isActivationStarted}
        />

        { hasAccess && isEmployerPage && (
          <>
            <HorizontalLine />
            <Box margin={{ bottom: isTerminationAllowed ? undefined : 'xxxl' }}>
              <BlockWrapper
                flatMode
                title="Organization Lock"
                icon={<OrgLockIcon color="iconAccent" />}
                moduleAnchorId="organisation_lock"
              >
                <OrganisationLock
                  isLocked={isLocked}
                  employerName={employer?.employerName}
                  employerId={id}
                />
              </BlockWrapper>
            </Box>
          </>
        )}
        
        {isTerminationAllowed && (
          <>
            <HorizontalLine />
            <DangerZone
              employerId={id}
              employerName={employer?.employerName}
              employerStatus={status}
              terminationDate={formattedData?.terminationDate}
              additionalAction={refetch}
            />

            <EmployerTerminationModal />
            <EmployerTerminationSuccessModal />
            <CancelEmployerTerminationModal />
            <CancelEmployerTerminationSuccessModal />
          </>
        )}
        <Box pad={{ bottom: 'xxxl' }} />
      </PageWrapper>
    </Access>
  );
};

export default React.memo(EmployerSetupPage);
