import React, { useMemo } from 'react';
import { ActivityType,Box, costFormater, Text } from '@common-fe/common-fe';

import ActivityStatus from '@/modules/transaction/ActivityStatus';
import { TransactionStatus } from '@/modules/transaction/transaction.types';

import TransactionTitle from './TransactionTitle';

const CLAIM_TRANSFER_TYPE = 'CLAIM_TRANSFER';
const CLAIM_REPAYMENT = 'CLAIM_REPAYMENT';

interface Props {
  category: string;
  type?: string;
  title: string;
  amount: number;
  status: string;
  date: string;
  onPick?: () => void;
}
const TransactionItem: React.FC<Props> = ({
  category, title, amount, onPick, date, status, type,
}) => {
  const isDeclined = useMemo(() => status === TransactionStatus.Declined, [status]);
  return (
    <Box pad={{ top: 'spacing8' }}>
      <Box
        direction="row"
        data-testid="transaction-list-item"
        align="center"
        height={{ min: 'smallItemHeight' }}
        background="canvas"
        elevation="default"
        round="container1Round"
        pad={{ horizontal: 'spacing16' }}
        onClick={onPick}
      >
        <Box style={{ flex: 5.3 }}>
          <TransactionTitle
            category={type?.startsWith(CLAIM_TRANSFER_TYPE) || type?.startsWith(CLAIM_REPAYMENT)
              ? ActivityType.CLAIM_TRANSFER_INTERNAL
              : category}
          >
            {title}
          </TransactionTitle>
        </Box>

        <Box style={{ flex: 3 }}>
          {
            status === TransactionStatus.Pending
            && <ActivityStatus status={status as TransactionStatus} />
          }
        </Box>

        <Box style={{ flex: 2 }}>
          <Text
            size="large"
            style={{ textDecoration: isDeclined ? 'line-through' : undefined }}
            color={isDeclined ? 'textSecondary' : undefined}
          >
            {costFormater(amount, true)}
          </Text>
        </Box>

        <Box style={{ flex: 0.7 }} align="end">
          <Text size="large" color="textSecondary">
            {date}
          </Text>
        </Box>

        <Box style={{ flex: 0.5 }} /> {/* empty box for spacing */}
      </Box>
    </Box>
  );
};

export default TransactionItem;
