import React from 'react';
import {
  Box,
} from '@common-fe/common-fe';

import { ProcessingRuleItem } from '../ProcessingDefinition.types';

import ProcessingRulesListItem from './ProcessingRulesListItem';
import { useProcessingRulesParamsStore } from './stores';

interface Props {
  searchString?: string;
  values: ProcessingRuleItem[];
  handleUpdatedElement?: (value?: ProcessingRuleItem) => void;
}

const ProcessingRulesList: React.FC<Props> = ({ values = [], handleUpdatedElement }) => {
  const newItemId = useProcessingRulesParamsStore((state) => state.newItemId);
  const handleResetNewItemId = useProcessingRulesParamsStore((state) => state.resetNewItemId);

  return (
    <Box direction="column" data-testid="processing-rules_wrapper">
      {values.map((item) => (
        <ProcessingRulesListItem
          newItemId={newItemId}
          onResetNewId={handleResetNewItemId}
          key={item.id}
          value={item}
          handleUpdatedElement={handleUpdatedElement}
        />
      ))}

    </Box>
  );
};

export default ProcessingRulesList;
