import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ROUTES from '@/common/routes';

const useRedirectToHome = (isNeedToRedirect: boolean) => {
  const history = useHistory();

  const homeRoute = `${ROUTES.HOME}${history.location.search}`;

  useEffect(() => {
    if (isNeedToRedirect) {
      history.push(homeRoute);
    }
  }, [history, isNeedToRedirect, homeRoute]);
};

export default useRedirectToHome;
