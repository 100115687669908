import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const useVoidCheckQuery = (onSuccess: () => void, id?: string) => {
  const { mutateAsync, isLoading } = useMutation(
    (reason?: string) => api.put(PATHS.VOID_CHECK(id), {
      ...reason ? { void_reason: reason } : {},
    }), { onSuccess },
  );
  return {
    mutateAsync,
    isLoading,
  };
};

export default useVoidCheckQuery;
