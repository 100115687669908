import React from 'react';
import { useRouteMatch } from 'react-router';
import { Box } from '@common-fe/common-fe';

import { CardActivationModal } from '../Cards/components/CardActivationModals/CardActivationModal';
import { CardReplaceModal } from '../Cards/components/CardReplaceModal/CardReplaceModal';
import { ChangeStatusCardModal } from '../Cards/components/ChangeStatusCardModal/ChangeStatusCardModal';
import useCardReplaceModalStore from '../Cards/store/useCardReplaceModal.store';

import { CardCarousel } from './CardCarousel/CardCarousel';
import { CardDetailsHeader } from './CardDetailsHeader/CardDetailsHeader';
import { CardTransactionsList } from './CardTransactionsList/CardTransactionsList';

export const CardDetails: React.FC = () => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const {
    state: { modalVisible, cardholderName },
    handleChangeVisibility,
  } = useCardReplaceModalStore();
  return (
    <Box data-testid="CardDetails-wrapper">
      <CardDetailsHeader />
      <CardCarousel employeeId={employeeId} />
      <CardTransactionsList />
      <CardReplaceModal
        visible={modalVisible}
        cardholderName={cardholderName}
        onSetVisible={handleChangeVisibility}
        employeeId={employeeId}
      />
      <ChangeStatusCardModal />
      <CardActivationModal />
    </Box>
  );
};
