import { useEffect } from 'react';

import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';

import { usePlanYearStore } from '../stores';

export default () => {
  const currentPlanYearState = usePlanYearStore((state) => state.state);
  const currentPlanYearSetter = usePlanYearStore((state) => state.setSourceState);

  const { data } = usePlanYearsQuery();

  useEffect(() => {
    if (currentPlanYearState.planYear) {
      const foundPlanYear = data.find((item) => item.id === currentPlanYearState.planYear);
      if (foundPlanYear) {
        currentPlanYearSetter({
          // planName: foundPlanYear.name,
          planEndDate: foundPlanYear.endDate,
          planStartDate: foundPlanYear.startDate,
          copayGroup: foundPlanYear.copayGroup || 'none',
          priorPlanYearId: foundPlanYear.priorPlanYearId,
        });
      }
    }
  }, [currentPlanYearSetter, currentPlanYearState.planYear, data]);
};
