import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import { Option, OptionKey } from '@/common/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

interface Args {
  isSubsidiary: boolean;
  viewMode: boolean;
  employerId?: OptionKey;
  options: Option[];
  isConfirmSSN?: boolean;
}

export const useEmployerIdFields = (args: Args) => {
  const idSourceIdPermProps = useGetPermissionToEditOverridableField(PermissionKey.ID_SOURCE_TYPE);

  return useMemo(() => {
    const {
      viewMode, options, employerId, isSubsidiary,
    } = args;
    return [
      {
        name: 'eeid',
        type: FieldTypes.Dropdown,
        testId: 'eeid',
        disabled: viewMode || isSubsidiary,
        singleMode: true,
        placeholder: 'Select',
        options,
        label: 'EEID source',
        value: employerId || '',
        defaultValue: employerId || '',
        ...idSourceIdPermProps,
      },
    ] as Field[];
  }, [args, idSourceIdPermProps]);
};
