import React, { useCallback, useMemo, useRef } from 'react';
import { Box, Hint, Inscription, PencilIcon, PlusIcon, RemoveIcon } from '@common-fe/common-fe';
import { toString } from 'lodash';

import theme from '@/styles/theme';

import formatTaxType from '../Contributions/formatTaxType';

import useDisableAccount from './hooks/useDisableAccount';
import AmountInput from './AmountInput';
import ContributionDateInput from './ContributionDateInput';
import { EllipsisText, HoveredIcon } from './ContributionEmployeesSettings.styles';
import { ContributionAmountSideKey, ContributionDateKey } from './ContributionEmployeesSettings.types';
import HEADERS, { HeaderTitle } from './HEADERS';
import { ContributionAccount, CreateManualContributionErrors, Recipient } from './types';

const DEFAULT_TEXT_PROPS = (areAllAccountsDisabled?: boolean) => ({
  size: '14px',
  color: areAllAccountsDisabled ? 'textSecondary' : 'textBody',
  lineHeight: '20px',
});

const getFieldBackendError = (field: string, backendErrors?: CreateManualContributionErrors[]) => {
  if (!backendErrors) {
    return;
  }
  const error = backendErrors.find((err) => err.field_name === field);
  return error?.validation_error_type;
};

interface Props {
  recipient: Recipient;
  account: ContributionAccount;
  hasName: boolean;
  setEditingAccount: (acc: ContributionAccount | null) => void;
  areErrorsShown?: boolean;
  areAllAccountsDisabled?: boolean;
  backendErrors?: CreateManualContributionErrors[];
}

const ContributionRecipientAccount: React.FC<Props> = ({
  areAllAccountsDisabled,
  recipient,
  account,
  hasName,
  setEditingAccount,
  areErrorsShown,
  backendErrors,
}) => {

  const employeeContributionAmountRef = useRef<HTMLInputElement>(null);
  const disable = useDisableAccount();
  const formattedTaxType = useMemo(() => formatTaxType(account?.taxType), [account]);
  const statusMessaga = useMemo(() => {
    if (recipient?.isNewEmployee) {
      return {
        message: 'Employee was created',
        color: theme.colors.statusColors.paidActiveComplete,
      };
    }
    if (recipient?.employeeTerminationDate) {
      return {
        message: 'Employee is terminated',
        color: theme.colors.statusColors.deniedErrorExpired,
      };
    }
    if (recipient?.contributionAccounts?.some((acc) => acc.isPlanDisabled)) {
      return {
        message: 'Employee is a part of this group, but didn\'t take a part in previous contribution',
        color: theme.colors.statusColors.needsHelp,
      };
    }
  }, [recipient]);

  const currentBackendErrors = useMemo(
    () => backendErrors?.filter(
      (error) => error?.plan_code === account?.planCode && `${error.employee_id}` === recipient.employeeId),
    [backendErrors, account, recipient],
  );

  const handleAdd = useCallback(() => {
    disable(recipient?.employeeId, account?.planId);
    if (employeeContributionAmountRef?.current) {
      setTimeout(() => {
        if (employeeContributionAmountRef?.current?.value
          && +employeeContributionAmountRef?.current?.value?.replace('$', '') === 0) {
          employeeContributionAmountRef?.current?.focus();
        }
      });
    }
  }, [account?.planId, disable, recipient?.employeeId]);

  return (
    <Box height="40px" justify="center">
      <Box direction="row" align="center">
        {HEADERS.map((column) => (
          <Box width={column.width} key={column.title}>
            {hasName && column.title === HeaderTitle.NAME ? (
              <Box direction="row" align="center">
                {statusMessaga ? (
                  <Box
                    data-testId={`employee_contribution_status_${recipient?.employeeId}`}
                    width={{ min: '10px' }}
                    height={{ min: '10px' }}
                    margin={{ right: 'spacing8' }}
                  >
                    <Hint
                      hintElement={(
                        <Box
                          data-testId={`employee_contribution_status_${recipient?.employeeId}`}
                          width={{ min: '10px' }}
                          height={{ min: '10px' }}
                          round
                          background={statusMessaga?.color}
                          margin={{ right: 'spacing8' }}
                        />
                      )}
                      contentStyles={{ maxWidth: '185px' }}
                    >
                      <Box width="100%" align="center" justify="center">
                        <Inscription size="12px" lineHeight="18px" weight={500} textAlign="center">
                          {statusMessaga?.message}
                        </Inscription>
                      </Box>
                    </Hint>
                  </Box>
                ) : null}

                <EllipsisText
                  weight="bold"
                  title={recipient?.employeeFullName}
                  {...DEFAULT_TEXT_PROPS(areAllAccountsDisabled)}
                >
                  {recipient?.employeeFullName}
                </EllipsisText>
              </Box>
            ) : null}
            {column.title === HeaderTitle.ACCOUNT ? (
              <EllipsisText title={account?.planCode} {...DEFAULT_TEXT_PROPS(areAllAccountsDisabled)}>
                {account?.planCode}
              </EllipsisText>
            ) : null}
            {column.title === HeaderTitle.EMPLOYEE_AMOUNT ? (
              <AmountInput
                amountKey={ContributionAmountSideKey.EmployeeContributionAmount}
                recipient={recipient}
                contributionAccount={account}
                refInput={employeeContributionAmountRef}
                areErrorsShown={areErrorsShown}
                backendError={getFieldBackendError(ContributionAmountSideKey.EmployeeContributionAmount, currentBackendErrors)}
              />
            ) : null}
            {column.title === HeaderTitle.EMPLOYER_AMOUNT ? (
              <AmountInput
                amountKey={ContributionAmountSideKey.EmployerContributionAmount}
                recipient={recipient}
                contributionAccount={account}
                areErrorsShown={areErrorsShown}
                backendError={getFieldBackendError(ContributionAmountSideKey.EmployerContributionAmount, currentBackendErrors)}
              />
            ) : null}
            {column.title === HeaderTitle.DATE ? (
              <ContributionDateInput
                recipient={recipient}
                contributionAccount={account}
                areErrorsShown={areErrorsShown}
                backendError={getFieldBackendError(ContributionDateKey, currentBackendErrors)}
              />
            ) : null}
            {column.title === HeaderTitle.TAX_YEAR ? (
              <EllipsisText title={toString(account?.taxYear)} {...DEFAULT_TEXT_PROPS(areAllAccountsDisabled)}>
                {toString(account?.taxYear)}
              </EllipsisText>
            ) : null}
            {column.title === HeaderTitle.TAX_TYPE ? (
              <EllipsisText title={formattedTaxType} {...DEFAULT_TEXT_PROPS(areAllAccountsDisabled)}>
                {formattedTaxType}
              </EllipsisText>
            ) : null}
            {column.title === HeaderTitle.MEMO ? (
              <Box direction="row" justify="between" align="center">
                <Box flex>
                  <EllipsisText title={toString(account?.memo)} {...DEFAULT_TEXT_PROPS(areAllAccountsDisabled)}>
                    {toString(account?.memo)}
                  </EllipsisText>
                </Box>
                <Box direction="row" margin={{ left: 'spacing24' }}>
                  {account?.isDisabled ? (
                    <Box
                      width="40px"
                      height="40px"
                      round="8px"
                      justify="center"
                      align="center"
                      background="canvas"
                      onClick={handleAdd}
                    >
                      <PlusIcon color="iconAccent" size="small" />
                    </Box>
                  ) : (
                    <>
                      <HoveredIcon onClick={() => setEditingAccount(account)}>
                        <PencilIcon color="iconPrimary" width="24px"  />
                      </HoveredIcon>
                      <HoveredIcon
                        margin={{ left: 'spacing16' }}
                        onClick={() => disable(recipient?.employeeId, account?.planId)}
                      >
                        <RemoveIcon color="iconPrimary" width="24px" />
                      </HoveredIcon>
                    </>
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ContributionRecipientAccount;
