import React from 'react';
import {
  Box, CloseIcon,
  IconsList, Layer, Table, TableBody,
  TableCell, TableHeader, TableRow,
  Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import { StyledTableRow } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/PermissionsTableModal/PermissionsTableModal.styles';
import theme from '@/styles/theme';

interface Props {
  visible: boolean;
  onSetVisible(val: boolean): void;
}
const DotIcon = () => <IconsList.StatusGoodSmall color={theme.colors.iconPrimary} size="14px" />;

const headers = [
  { title: 'Role' },
  { title: 'CSR' },
  { title: 'Admin I' },
  { title: 'Admin II' },
  { title: 'Operations' },
  { title: 'Claims Processor' },
  { title: 'FinOps' },
  { title: 'Super User' },
  { title: 'Executive' },
  { title: 'Referral Partner/ Broker' },
  { title: 'Finance' },
  { title: 'ER' },
  { title: 'ER Division/ Subdivision' },
];

const subHeaders = [
  { title: 'Access' },
  { title: 'Basic user 1' },
  { title: 'Basic user 2' },
  { title: 'Basic user 3' },
  { title: 'Basic user 4' },
  { title: 'Basic user 5' },
  { title: 'Basic user 6' },
  { title: 'Super user' },
  { title: 'View Only' },
  { title: 'Basic user 2' },
  { title: 'View Only' },
  { title: 'Basic user 2' },
  { title: 'View Only' },
];

const rows = [
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'View All' },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit EE data' },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit Dep data' },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit ER data' },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit ER bank account info' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit EE Bank account data' },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Delete ER Bank' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Delete EE data' },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Delete ER data' },
      { title: null },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Order card' },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Reissue card' },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'process claim' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'process payment' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'initiate payment adjustment' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Reports' },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Security/ Authentication' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Custom Permissions' },
      { title: null },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'View ER Division' },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'View ER subdivision' },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit ER Division' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Edit ER subdivision' },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
    ],
  },
  {
    id: _.uniqueId(),
    feilds: [
      { title: 'Other FinOps' },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: <DotIcon /> },
      { title: <DotIcon /> },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
      { title: null },
    ],
  },
];

const PermissionsTableModal: React.FC<Props> = ({
  visible,
  onSetVisible,
}) => (
  <>
    {
      visible
            && (
              <Layer
                full
                onEsc={() => onSetVisible(false)}
                onClickOutside={() => onSetVisible(false)}
              >
                <Box height="xxsmall" margin={{ bottom: 'small' }}>
                  <CloseIcon color="iconPrimary" onClick={() => onSetVisible(false)}>       
                  </CloseIcon>
                </Box>

                <Box overflow="hidden" pad="small" margin="small" round="container1Round" background="module">
                  <Box overflow="auto">
                    <Table>
                      <TableHeader style={{
                        position: 'sticky',
                        top: '0px',
                      }}
                      >
                        <TableRow>
                          {
                            headers.map((el) => (
                              <TableCell key={el.title} scope="col" border="bottom">
                                <Text weight="bold" color="textOnColor" style={{ whiteSpace: 'nowrap' }}>{el.title}</Text>
                              </TableCell>
                            ))
                          }
                        </TableRow>
                        <StyledTableRow>
                          {
                            subHeaders.map((el) => (
                              <TableCell key={el.title} scope="col" border="bottom">
                                <Text weight="bold" style={{ whiteSpace: 'nowrap' }}>{el.title}</Text>
                              </TableCell>
                            ))
                          }
                        </StyledTableRow>
                      </TableHeader>
                      <TableBody>
                        {
                          rows.map((row) => (
                            <TableRow key={row.id}>
                              {
                                row.feilds.map((el, index) => (
                                  <TableCell scope="col" align={index === 0 ? undefined : 'center'} key={_.uniqueId()}>
                                    {
                                      typeof el.title === 'string'
                                        ? (
                                          <Text style={{
                                            whiteSpace: 'nowrap',

                                          }}
                                          >
                                            {el.title}
                                          </Text>
                                        )
                                        : el.title
                                    }
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Layer>
            )
    }
  </>
);

export default PermissionsTableModal;
