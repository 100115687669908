import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, FlexForm, Inscription, Preloader, SuccessModal } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { PendingModal } from '@/components';
import { useHistory } from '@/modules/core/hooks';
import { PaymentMethod } from '@/modules/employee/employee.types';
import useBank from '@/modules/ReimburseMe/ConfirmationStep/Immediately/DirectDeposit/BankForm/useBank.store';
import { BankInfo } from '@/modules/ReimburseMe/ConfirmationStep/Immediately/DirectDeposit/BankForm/useBankFields';
import useAddPaymentMethodQuery from '@/modules/ReimburseMe/queries/useAddPaymentMethod.query';
import { useGetPaymentMethods } from '@/modules/ReimburseMe/queries/useGetPaymentMethods.query';
import { PaymentMethodOwnerType, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';
import spacings from '@/styles/spacings';

import { useGetExternalBankAccountsQuery } from './DirectDeposit/BankForm/useGetBankAccountsList.query';
import { AddPayPalAccountModal } from './AddPayPalAccountModal';
import ChoosePaymentMethod from './ChoosePaymentMethod';
import ConfirmCancelModal from './ConfirmCancelModal';
import { DebitCardModal } from './DebitCart';
import DirectDeposit from './DirectDeposit';
import { EditPaymentMethodModal } from './EditPaymentMethod';
import useDefaultPaymentForm from './useDefaultPaymentForm';
import { usePaymentMethodsStore } from './usePaymentMethods.store';
import { AddVenmoAccountModal } from './Venmo';

enum StepsStates {
  notInitiated = '',
  depositOrDebit = 'depositOrDebit',
  depositOptionsChoosing = 'depositOptionsChoosing',
  debitOptionsChoosing = 'debitOptionsChoosing',
  payPalOptionsChoosing = 'payPalOptionsChoosing',
  venmoOptionsChoosing = 'venmoOptionsChoosing',
  paymentMethodAdded = 'paymentMethodAdded',
}

enum EditStepsStates {
  notInitiated = '',
  removePaymentMethod = 'removePaymentMethod',
  editPaymentMethod = 'editPaymentMethod',
  paymentMethodRemoved = 'paymentMethodRemoved',
}

const PaymentMethodInfo = () => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const handleSetPaymentMethods = usePaymentMethodsStore((state) => state.handleSetPaymentMethods);
  const handleSetActivePaymentMethod = usePaymentMethodsStore((state) => state.handleSetActivePaymentMethod);
  const handleGettingPaymentMethods = useCallback((paymentMethods: PaymentMethod[]) => {
    handleSetPaymentMethods(
      paymentMethods
        .filter(
          (method) => method?.paymentMethodOwner?.employeeId === employeeId,
        )
    );
    handleSetActivePaymentMethod();
  }, [employeeId, handleSetPaymentMethods, handleSetActivePaymentMethod]);
  const { isLoading, refetch } = useGetPaymentMethods(employeeId, handleGettingPaymentMethods);

  const history = useHistory();

  const [step, setSteps] = useState(StepsStates.notInitiated);
  const [editStep, setEditSteps] = useState(EditStepsStates.notInitiated);
  const [message, setMessage] = useState<{ title?: string, desctiption?: string }>({});
  const [successVisible, setSuccessVisible] = useState(false);
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);

  const { save, isLoading: addCheckPaymentLoading } = useAddPaymentMethodQuery();
  const handleSetChecked = usePaymentMethodsStore((state) => state.handleSetChecked);
  const { setBankAccountData } = useBank();
  const { externalBankAccounts, isLoading: isBankAccountLoading } = useGetExternalBankAccountsQuery(employeeId);
  const fields = useDefaultPaymentForm(() => setSteps(StepsStates.depositOrDebit));

  useEffect(() => {
    setBankAccountData(externalBankAccounts[0] as BankInfo);
  }, [externalBankAccounts, setBankAccountData]);

  const handleRedirect = useCallback(() => history.push(ROUTES.HOME), [history]);

  const chooseCheckHandler = useCallback(async () => {
    const data = await save({
      paymentType: PaymentMethodType.CHECK,
      paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
    });
    handleSetChecked(`${data.id}`);
  }, [save, handleSetChecked]);

  const handleRemoveSuccess = useCallback(() => {
    refetch();
    setEditSteps(EditStepsStates.paymentMethodRemoved);
  }, [refetch]);

  const handleEditSuccess = useCallback(() => {
    refetch();
    setEditSteps(EditStepsStates.notInitiated);
  }, [refetch]);

  return (
    <>
      {step === StepsStates.depositOrDebit && (
        <ChoosePaymentMethod
          onClose={() => setSteps(StepsStates.notInitiated)}
          chooseDeposit={() => setSteps(StepsStates.depositOptionsChoosing)}
          chooseDebitCard={() => setSteps(StepsStates.debitOptionsChoosing)}
          choosePayPal={() => setSteps(StepsStates.payPalOptionsChoosing)}
          chooseVenmo={() => setSteps(StepsStates.venmoOptionsChoosing)}
          chooseCheck={chooseCheckHandler}
          editPaymentMethod={() => setEditSteps(EditStepsStates.editPaymentMethod)}
        />
      )}

      {step === StepsStates.depositOptionsChoosing && (
        <DirectDeposit
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.payPalOptionsChoosing && (
        <AddPayPalAccountModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.venmoOptionsChoosing && (
        <AddVenmoAccountModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {step === StepsStates.debitOptionsChoosing && (
        <DebitCardModal
          onClose={() => setSteps(StepsStates.notInitiated)}
          onBack={() => setSteps(StepsStates.depositOrDebit)}
          onSuccess={() => setSteps(StepsStates.paymentMethodAdded)}
        />
      )}

      {editStep === EditStepsStates.editPaymentMethod && (
        <EditPaymentMethodModal
          onClose={() => setEditSteps(EditStepsStates.notInitiated)}
          onBack={() => setEditSteps(EditStepsStates.notInitiated)}
          onSuccess={handleEditSuccess}
        />
      )}

      <SuccessModal
        header="Payment method added successfully!"
        buttonText="Got It!"
        visible={step === StepsStates.paymentMethodAdded}
        onSetVisible={() => setSteps(StepsStates.depositOrDebit)}
        buttonStyle={{ width: '160px' }}
      />

      <SuccessModal
        header="Your payment method has been removed successfully!"
        buttonText="Got It!"
        visible={editStep === EditStepsStates.paymentMethodRemoved}
        onSetVisible={() => setEditSteps(EditStepsStates.notInitiated)}
        buttonStyle={{ width: '200px' }}
      />

      <ConfirmCancelModal
        visible={confirmCancelVisible}
        onSetVisible={setConfirmCancelVisible}
        onSubmit={handleRedirect}
      />
      <SuccessModal
        header="Your request is approved"
        helptext="Your payment will be issued as soon as possible based on your preferred payment method."
        buttonText="Back to Account Details"
        visible={successVisible}
        onSetVisible={setSuccessVisible}
        onSubmit={handleRedirect}
      />
      <PendingModal
        header={message?.title || ''}
        helptext={message?.desctiption}
        buttonText="Close"
        visible={Boolean(Object.keys(message).length)}
        onSetVisible={() => setMessage({})}
      />

      <Box background="canvas" round="container1Round" pad="spacing24" border={{ size: 'small', color: 'border2' }}>
        <Box margin={{ bottom: 'spacing16' }}>
          <Inscription color="textTitle" lineHeight="28px" size="20px">Payment Method</Inscription>
        </Box>
        {isLoading || isBankAccountLoading || addCheckPaymentLoading ? <Preloader /> : (
          <>
            <FlexForm
              paddingless
              fields={fields}
              contentStyles={{ margin: 0 }}
              isThinMode
            />
            <Box height={spacings.l} />
          </>
        )}
      </Box>
    </>
  );
};

export default PaymentMethodInfo;
