import { baseStoreCreator } from '@common-fe/common-fe';

import { AllowedFileTypes } from '@/modules/employer/types';




export interface FileManagerSettingsState {
  isFileManagerAccessEnabled?: boolean;
  isFileAutoPostEnabled?: boolean | string;
  
  allowedFileTypes?: AllowedFileTypes[];
  isSendFiles: boolean;
  fileLocation?: string;
  isSameErrorReportLocation?: boolean;
  errorReportLocation?: string;
}

export const DEFAULT_VALUES: FileManagerSettingsState = {
  isFileManagerAccessEnabled: undefined,
  isFileAutoPostEnabled: undefined,
  allowedFileTypes: [],
  isSendFiles: false,
  fileLocation: '',
  isSameErrorReportLocation: undefined,
  errorReportLocation: '', 
};

export const useStore = baseStoreCreator<FileManagerSettingsState>(DEFAULT_VALUES);

export default useStore;

