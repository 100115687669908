import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

interface Props {
  title: string;
  desc: string;
  width?: string;
}

const Label: React.FC<Props> = ({ title, desc, width }) => (
  <Box flex={{ grow: 1 }} width={width}>
    <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
      {title}
    </Inscription>
    <Box>
      <Inscription weight={500} color="textSecondary" size="12px" lineHeight="18px">
        {desc}
      </Inscription>
    </Box>
  </Box>
);

export default Label;
