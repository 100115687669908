import React, { useMemo } from 'react';
import { Inscription, RoundDoneIcon, WarningCard, WarningIcon } from '@common-fe/common-fe';

import { EmployeeMatchingType } from '@/modules/employer/components/Lockbox/lockbox.types';

interface EmployeeMatchingMessageCard {
  title: string;
}

const WarningMessage: React.FC<EmployeeMatchingMessageCard> = ({ title }) => (
  <WarningCard
    testId="warning_card"
    icon={<WarningIcon color="warning" size="spacing24" />}
    title={<Inscription color="textBody" weight={700}>{title}</Inscription>}
    backgroundColor="warningContainer"
    borderColor="warningBorder"
    topBorderColor="transparent"
    border={false}
    containerProps={{
      pad: { vertical: 'spacing16', horizontal: 'spacing24' }
    }}
  />
);

const SuccessMessage: React.FC<EmployeeMatchingMessageCard> = ({ title }) => (
  <WarningCard
    testId="success_card"
    icon={<RoundDoneIcon color="success" size="spacing24"  />}
    title={<Inscription color="textBody" weight={700}>{title}</Inscription>}
    backgroundColor="successContainer"
    borderColor="successBorder"
    topBorderColor="transparent"
    border={false}
    containerProps={{
      pad: { vertical: 'spacing16', horizontal: 'spacing24' }
    }}
  />
);

interface Props {
  matchingType?: EmployeeMatchingType;
}

const EmployeeMatchingMessage: React.FC<Props> = ({ matchingType }) => {
  const message = useMemo(() => {
    switch (matchingType) {
    case EmployeeMatchingType.ACCOUNT_NOT_FOUND: {
      return <WarningMessage title="HSA Doesn’t Exist" />;
    }
    case EmployeeMatchingType.ACCOUNT_NOT_MATCHED: {
      return <WarningMessage title="HSA Account Number Doesn’t Match" />;
    }
    case EmployeeMatchingType.EMPLOYEE_NAME_NOT_MATCHED: {
      return <WarningMessage title="Employee Name Doesn’t Match" />;
    }
    case EmployeeMatchingType.FULL_MATCH: {
      return <SuccessMessage title="All Data Matched" />;
    }
    case EmployeeMatchingType.SSN_NOT_MATCHED: {
      return <WarningMessage title="SSN Doesn’t Match" />;
    }
    default: {
      return null;
    }
    }
  }, [matchingType]);

  return message;
};

export default EmployeeMatchingMessage;
