import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { Box, Inscription } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import { QueryKeys,useHistory } from '@/modules/core/hooks';
import EmployerSetupFormModal
  from '@/modules/employer/components/EmployerSetupModal/EmployerSetupFormModal';
import EmployerSetupModalWrapper
  from '@/modules/employer/components/EmployerSetupModal/EmployerSetupModalWrapper';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';

const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const getSetupFormMode = (mode: OrganizationTypes) => {
  switch (mode) {
  case OrganizationTypes.distributor: return EmployerSetupModes.distributor;
  case OrganizationTypes.subsidiary: return EmployerSetupModes.subsidiary;
  default: return EmployerSetupModes.partner;
  }
};
const getSingularTitle = (mode: OrganizationTypes) => {
  switch (mode) {
  case OrganizationTypes.distributor: return 'Distributor';
  case OrganizationTypes.subsidiary: return 'Subsidiary';
  default: return 'Partner';
  }
};

interface Props {
  title: string;
  mode: OrganizationTypes;
}

const TopSection: React.FC<Props> = ({ title, mode }) => {
  const buttonText = useMemo(
    () => {
      const singularTitle = getSingularTitle(mode);

      return `Set up New ${singularTitle}`;
    },
    [mode],
  );
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )?.get(QueryKeys.SHOULD_OPEN_MODAL);
  const setupFormMode = useMemo(() => getSetupFormMode(mode), [mode]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isBasicFormDirty, setIsBasicFormDirty] = useState(false);
  const [isAddressFormDirty, setIsAddressFormDirty] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(true);

  useEffect(() => {
    if (query) {
      setModalVisible(true);
      history.deleteQueryParameter([QueryKeys.SHOULD_OPEN_MODAL]);
    }
  }, [query, history]);

  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && (isAddressFormDirty || isBasicFormDirty)) {
       
      return window.confirm(CONFIRM_TEXT) && setModalVisible(value);
    }
    return setModalVisible(value);
  }, [isAddressFormDirty, isBasicFormDirty]);

  return (
    <>
      <Prompt
        when={(isAddressFormDirty || isBasicFormDirty) && modalVisible && showConfirmModal}
        message={CONFIRM_TEXT}
      />
      <Box
        margin={{ bottom: 'spacing16' }}
        direction="row"
        justify="between"
        align="center"
      >
        <Box>
          <Inscription
            size="3xl"
            weight="bold"
            color="textBody"
            lineHeight="40px"
          >{title}
          </Inscription>
        </Box>
        <Box>
          <Access accessRequirements={[
            {
              organizationType: OrganizationTypes.system,
              permission: Permissions.EDIT_ER_DATA,
            },
            {
              organizationType: OrganizationTypes.partner,
              permission: Permissions.EDIT_ER_DATA,
            },
            {
              organizationType: OrganizationTypes.distributor,
              permission: Permissions.EDIT_ER_DATA,
            },
            {
              organizationType: OrganizationTypes.company,
              permission: Permissions.EDIT_ER_DATA,
            },
            {
              organizationType: OrganizationTypes.subsidiary, isException: true,
            },
          ]}
          >
            <EmployerSetupModalWrapper
              visible={modalVisible}
              testId={`set_up_new_${title}`}
              onSetVisible={onSetVisibleHandler}
              buttonText={buttonText}
              modalTitle={buttonText}
            >
              <EmployerSetupFormModal
                setIsBasicFormDirty={setIsBasicFormDirty}
                setIsAddressFormDirty={setIsAddressFormDirty}
                mode={setupFormMode}
                showConfirmModal={setShowConfirmModal}
                onCancel={() => onSetVisibleHandler(false)}
              />
            </EmployerSetupModalWrapper>
          </Access>
        </Box>
      </Box>
    </>
  );
};

export default TopSection;
