import React, { useCallback,useMemo, useState } from 'react';
import {
  Box,
  FlexListHeader,
  FlexListItem,
  FlexListShowMore,
  ListItem,
  PlanItem,
  Text,
  WarnModal,
} from '@common-fe/common-fe';

import {
  ListItemBase,
  ListItemType,
} from '@/common/types';

import PlanTemplateDeleteModal from './PlanTemplateDeleteModal';

const headers: ListItemBase[] = [
  {
    key: 'planTemplateName',
    title: 'Plan template name',
    flex: 2,
  },
  {
    key: 'planTemplateType',
    title: 'Plan template type',
  },
  {
    key: 'options',
    title: '',
  },
];

interface Props {
  formatedLinkedTemplates: PlanItem[],
}

const PER_PAGE = 10;
const PlanTemplateTable: React.FC<Props> = ({ formatedLinkedTemplates }) => {
  const [showMore, setShowMore] = useState(false);
  const [isVisibleWarnModal, setVisibleWarnModal] = useState(false);
  const [isVisibleDeleteModal, setVisibleDeleteModal] = useState(false);

  const [currentTemplateId, setCurrentTemplateId] = useState('');
  const [currentTemplateName, setCurrentTemplateName] = useState('');

  const handleToggleRemoveModal = useCallback((id: string) => {
    const currentTemplate = formatedLinkedTemplates.find((item) => item.id === id);
    setCurrentTemplateId(id);
    if (currentTemplate) setCurrentTemplateName(currentTemplate.name);
  }, [formatedLinkedTemplates]);

  const handleOpenWarnModal = useCallback(() => {
    setVisibleWarnModal(true);
  }, []);

  const showedList = useMemo(() => (showMore
    ? formatedLinkedTemplates : formatedLinkedTemplates.slice(0, PER_PAGE)),
  [formatedLinkedTemplates, showMore]);
  const moreItemsCount = formatedLinkedTemplates.length - showedList.length;
  const formatedList = useMemo(() => showedList.map((item) => ({
    id: item.id,
    fields: [
      {
        key: 'planTemplateName',
        type: ListItemType.Contact,
        title: item.name,
        ellipsisMode: true,
        hasTip: true,
        flex: 2,
      },
      {
        key: 'planTemplateType',
        type: ListItemType.Text,
        title: item.type,
      },
      {
        key: 'options',
        type: ListItemType.Text,
        title: '',
      },
    ],
  })), [
    showedList,
  ]);

  return (
    <>
      <Box background="canvas" round="moduleRound">
        {Boolean(formatedLinkedTemplates.length) && (
          <Box margin={{ horizontal: 'xl', vertical: 'l' }} pad="small" background="module" round="container1Round">
            <Text
              margin={{ top: 'spacing8', bottom: 'spacing12' }}
              size="large"
              weight="bold"
              color="textBody"
            >{`Plan templates: ${formatedLinkedTemplates.length}`}
            </Text>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formatedList.map((row: ListItem, ) => (
                <FlexListItem
                  key={row.id}
                  id={row.id}
                  fields={row.fields}
                  options={[
                    {
                      name: 'Remove',
                      isWarning: true,
                      onClick: () => {
                        setVisibleDeleteModal(true);
                        handleToggleRemoveModal(row.id);
                      },
                    },
                  ]}
                />
              ))}
              {Boolean(moreItemsCount)
            && (
              <FlexListShowMore onClick={() => {
                setShowMore(true);
              }}
              >
                Show More ({moreItemsCount})
              </FlexListShowMore>
            )}
            </Box>
          </Box>
        )}
      </Box>
      <PlanTemplateDeleteModal
        isVisible={isVisibleDeleteModal}
        templateId={currentTemplateId}
        templateName={currentTemplateName}
        handleOpenWarnModal={handleOpenWarnModal}
        handleOpenModal={setVisibleDeleteModal}
      />
      <WarnModal
        visible={isVisibleWarnModal}
        isPendingIcon
        testId="Warn-Template-Modal"
        onSetVisible={() => setVisibleWarnModal(false)}
        header="Warning"
        helptext="Template cannot be removed because we have already plans created based on it"
        submitButtonText="Got It"
        paddingless
        onSubmit={() => setVisibleWarnModal(false)}
      />
    </>
  );
};

export default PlanTemplateTable;
