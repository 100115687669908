const spacings = {
  spacing2: '2px',
  spacing4: '4px',
  spacing8: '8px',
  spacing12: '12px',
  spacing14: '14px',
  spacing16: '16px',
  spacing18: '18px',
  spacing24: '24px',
  spacing32: '32px',
  spacing48: '48px',
  spacing72: '72px',
  spacing96: '96px',
  spacing128: '128px',
  spacing6: '6px',
  spacing20: '20px',
  spacing54: '54px',

  xxxs: '2px',
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '16px',
  l: '24px',
  xl: '32px',
  xxl: '48px',
  xxxl: '72px',
  '4xl': '96px',
  '5xl': '128px',
};
export default spacings;
