import { ALL_OPTION } from '@/common/constants';

export enum EmailTemplatesCategories {
  WELCOME = 'WELCOME',
  ENROLLMENTS = 'ENROLLMENTS',
  ENROLLMENT_UPDATES = 'ENROLLMENT_UPDATES',
  PROFILE_UPDATES = 'PROFILE_UPDATES',
  CARD_STATUS = 'CARD_STATUS',
  ACCOUNT_ACTIVITY = 'ACCOUNT_ACTIVITY',
  HSA = 'HSA',
  INVESTMENTS = 'INVESTMENTS',
  REMINDERS = 'REMINDERS',
  CAMPAIGNS = 'CAMPAIGNS',
  INVOICE = 'INVOICE',
}

export enum EmailTemplatesUserTypes {
  ADMIN = 'ADMIN',
  PARTICIPANT = 'PARTICIPANT',
}

export const emailTemplatesCategoriesOptions = [
  ALL_OPTION,
  {
    key: EmailTemplatesCategories.WELCOME,
    value: EmailTemplatesCategories.WELCOME,
    title: 'Welcome',
  },
  {
    key: EmailTemplatesCategories.ENROLLMENTS,
    value: EmailTemplatesCategories.ENROLLMENTS,
    title: 'Enrollments',
  },
  {
    key: EmailTemplatesCategories.ENROLLMENT_UPDATES,
    value: EmailTemplatesCategories.ENROLLMENT_UPDATES,
    title: 'Enrollment Updates',
  },
  {
    key: EmailTemplatesCategories.PROFILE_UPDATES,
    value: EmailTemplatesCategories.PROFILE_UPDATES,
    title: 'Profile Updates',
  },
  {
    key: EmailTemplatesCategories.CARD_STATUS,
    value: EmailTemplatesCategories.CARD_STATUS,
    title: 'Card Status',
  },
  {
    key: EmailTemplatesCategories.ACCOUNT_ACTIVITY,
    value: EmailTemplatesCategories.ACCOUNT_ACTIVITY,
    title: 'Account Activity',
  },
  {
    key: EmailTemplatesCategories.HSA,
    value: EmailTemplatesCategories.HSA,
    title: 'HSA',
  },
  {
    key: EmailTemplatesCategories.INVESTMENTS,
    value: EmailTemplatesCategories.INVESTMENTS,
    title: 'Investments',
  },
  {
    key: EmailTemplatesCategories.REMINDERS,
    value: EmailTemplatesCategories.REMINDERS,
    title: 'Reminders',
  },
  {
    key: EmailTemplatesCategories.CAMPAIGNS,
    value: EmailTemplatesCategories.CAMPAIGNS,
    title: 'Campaigns',
  },
  {
    key: EmailTemplatesCategories.INVOICE,
    value: EmailTemplatesCategories.INVOICE,
    title: 'Invoice',
  },
];

export const emailTemplatesUserTypesOptions = [
  ALL_OPTION,
  {
    key: EmailTemplatesUserTypes.ADMIN,
    value: EmailTemplatesUserTypes.ADMIN,
    title: 'Admin',
  },
  {
    key: EmailTemplatesUserTypes.PARTICIPANT,
    value: EmailTemplatesUserTypes.PARTICIPANT,
    title: 'Participant',
  },
];