import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface GracePeriodFieldsStatePayload {

  endOfCoverage: PlanFieldState;

  gracePeriod: PlanFieldState;

  claimsDeadline: PlanFieldState;

  runOut: PlanFieldState;
  runOutAmount: PlanFieldState;
}

export const DEFAULT_VALUES: GracePeriodFieldsStatePayload = {
  endOfCoverage: PlanFieldState.Modifiable,

  gracePeriod: PlanFieldState.Modifiable,

  claimsDeadline: PlanFieldState.Modifiable,

  runOut: PlanFieldState.Modifiable,
  runOutAmount: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<GracePeriodFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
