import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getHashValue } from '@/utils/modifiers/getHashValue';

export const useDetectUrlHash = (onDetectUrlHash: (hash?: string) => void) => {
  const location = useLocation();
  const modalHash = getHashValue(location.hash);

  useEffect(() => {
    if (modalHash) {
      window.history.replaceState('', document.title, window.location.pathname);
      onDetectUrlHash(modalHash);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
