import { useMemo } from 'react';

import { useGetCIPReportQuery } from '@/modules/employer/components/Reports/queries/useGetCIPReport.query';

const useHSAReports = () => {
  const {
    formattedCIPReport,
    isLoading,
    isEmpty,
  } = useGetCIPReportQuery();

  const reports = useMemo(() => ([
    {
      ...formattedCIPReport,
      isLoading,
      isEmpty,
    },
  ]),
  [
    formattedCIPReport,
    isLoading,
    isEmpty,
  ]);

  return reports;
};

export default useHSAReports;
