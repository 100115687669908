import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';
import _ from 'lodash';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useBasicPropertiesFields, useBasicPropertiesState } from '../../BasicProperties/hooks';
// import { useOnlyForDev } from '@/utils';
import { useComplexConfigurationFields, useComplexConfigurationState } from '../../ComplexConfiguration/hooks';
import {
  COMPLEX_CONFIGURATION_SETUP_FIELD,
  HEALTH_PLAN_SETUP_FIELD,
  PLAN_YEAR_SETUP_FIELD,
} from '../../HealthPlanSetup.constants';

import useCoverageLevelsValidate from './useCoverageLevelsValidate';

const CARRIER_ID_MESSAGE = 'Carrier ID';
const AUTOCREATE_CDH_ENROLLMENT_MESSAGE = 'Autocreate CDH enrollment';
const DEDUCTIBLE_SERVICE_MESSAGE = 'Add service configs for claims';

const VALIDATION_FIELDS = [
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TYPE,
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE,
  HEALTH_PLAN_SETUP_FIELD.PLAN_CODE,
  HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT,
  HEALTH_PLAN_SETUP_FIELD.CARRIER_NAME,
  HEALTH_PLAN_SETUP_FIELD.CARRIER_ID,
  COMPLEX_CONFIGURATION_SETUP_FIELD.ENROLLMENT_MATRIX_NAME,
  COMPLEX_CONFIGURATION_SETUP_FIELD.PROCESSING_RULE_NAME,
  COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE,
  PLAN_YEAR_SETUP_FIELD.PLAN_YEAR,
];

const TEMPLATE_VALIDATION_FIELDS = [
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TYPE,
  HEALTH_PLAN_SETUP_FIELD.CARRIER_NAME,
  HEALTH_PLAN_SETUP_FIELD.CARRIER_ID,
  HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT,
  COMPLEX_CONFIGURATION_SETUP_FIELD.ENROLLMENT_MATRIX_NAME,
  COMPLEX_CONFIGURATION_SETUP_FIELD.PROCESSING_RULE_NAME,
  COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE,
];

const IGNORE_FIELDS: string[] = [];

const VALIDATION_FIELDS_MAP = {};

const getFormatedString = (value: string) => _.capitalize(_.startCase(value));

export const useHealthPlanActivateValidate = (isTemplateMode?: boolean) => {
  const { getValidateFields, getErrors } = useValidationUtils();

  // const isOnlyForDev = useOnlyForDev();
  const planDefinitionState = useBasicPropertiesState();
  const planDefinitionFields = useBasicPropertiesFields(true, isTemplateMode);

  const complexConfigurationState = useComplexConfigurationState();
  const complexConfigurationFields = useComplexConfigurationFields(true, isTemplateMode);

  const coveragesErrors = useCoverageLevelsValidate({
    isTemplateMode,
    isActiveMode: true,
  });

  const planDefinitionErrors: FieldError = useMemo(
    () => {
      const errors = getErrors(planDefinitionState, getValidateFields(planDefinitionFields));
      return errors;
    },
    [getErrors, getValidateFields, planDefinitionFields, planDefinitionState],
  );

  const complexConfigurationErrors: FieldError = useMemo(
    () => getErrors(complexConfigurationState, getValidateFields(complexConfigurationFields)),
    [getErrors, getValidateFields, complexConfigurationFields, complexConfigurationState],
  );

  const filteredErrors = useMemo(() => {
    const allErrors: FieldError = {
      ...planDefinitionErrors,
      ...complexConfigurationErrors,
      ...coveragesErrors,
    };
    const filtered: FieldError = {};
    _.forEach(allErrors, (item, key) => {
      if (IGNORE_FIELDS.includes(key)) {
        return;
      }
      const newKey = _.get(VALIDATION_FIELDS_MAP, `[${key}]`) || key;
      filtered[newKey] = item;
    });
    return filtered;
  }, [planDefinitionErrors, coveragesErrors, complexConfigurationErrors]);
  const errorsKeys = useMemo(
    () => _.map(filteredErrors, (item, key) => key), [filteredErrors],
  );
  const successes = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    return _.difference(messages, errorsKeys);
  }, [errorsKeys, isTemplateMode]);
  const errorMessages = useMemo(() => {
    const list = errorsKeys.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.CARRIER_ID) {
        return CARRIER_ID_MESSAGE;
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT) {
        return AUTOCREATE_CDH_ENROLLMENT_MESSAGE;
      }
      if (item === COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE) {
        return DEDUCTIBLE_SERVICE_MESSAGE;
      }
      return getFormatedString(item);
    });

    return list;
  }, [errorsKeys, isTemplateMode]);
  const successMessages = useMemo(() => {
    const list = successes.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.CARRIER_ID) {
        return CARRIER_ID_MESSAGE;
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT) {
        return AUTOCREATE_CDH_ENROLLMENT_MESSAGE;
      }
      if (item === COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE) {
        return DEDUCTIBLE_SERVICE_MESSAGE;
      }
      return getFormatedString(item);
    });

    return list;
  }, [isTemplateMode, successes]);

  const pendingMessages = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    const list = messages.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.CARRIER_ID) {
        return CARRIER_ID_MESSAGE;
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT) {
        return AUTOCREATE_CDH_ENROLLMENT_MESSAGE;
      }
      if (item === COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE) {
        return DEDUCTIBLE_SERVICE_MESSAGE;
      }
      return getFormatedString(item);
    });
    return list;
  }, [isTemplateMode]);

  return {
    pendingMessages,
    errorMessages,
    successMessages,
  };
};
