import { useMemo } from 'react';

import { useEmployeeToolbarStore } from '@/modules/employee/Employee/EmployeeTopbar/stores';
import { useEmployerInfoStore } from '@/modules/employee/Employee/PersonalInformation/EmployerInfo/stores';
import { useOrganizationInfoStore } from '@/modules/employee/Employee/PersonalInformation/OrganizationInfo/stores';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';
import { useEmployeeEnrollmentsStore } from '@/modules/employee/Employee/stores/useEmployeeEnrollments.store';
import useGetOrgName from '@/modules/employer/queries/useGetOrgName.query';
import { usePlanByIdQuery } from '@/modules/plan/PlanSetup/queries';
import { getIdsFromPath } from '@/utils/modifiers';

const ACTIVE = 'ACTIVE';

export const useGetEnrollmentSetupData = (currentPlanId?: string, isEdit?: boolean) => {
  const lastName = useEmployeeToolbarStore((state) => state.sourceState?.lastName);
  const defaultFields = useEmployerInfoStore((state) => state.sourceState);
  const organizationInfo = useOrganizationInfoStore((state) => state.sourceState);
  const enrollments = useEmployeeEnrollmentsStore((state) => state.enrollments);
  const isEnrollmentsLoading = useEmployeeEnrollmentsStore((state) => state.isLoading);

  const employerOrganizationId = useMemo(
    () => {
      const { employerId } = getIdsFromPath(organizationInfo?.organizationPath);
      return employerId;
    },
    [organizationInfo],
  );

  const { formattedData, isFetching: orgPlansLoading } = useGetOrganizationPlansQuery(
    employerOrganizationId || undefined,
    ACTIVE,
  );

  const { data: plan, isFetching: planLoading } = usePlanByIdQuery(currentPlanId);

  const formattedPlan = useMemo(() => {
    if (plan) {
      return {
        id: `${plan.id}`,
        planYear: {
          id: `${plan.plan_year.id}`,
          name: plan.plan_year?.name,
          validFrom: plan.plan_year?.valid_from,
          validTo: plan.plan_year?.valid_to,
        },
        name: plan?.name.name,
        nameState: plan?.name.name_state,
        accountType: plan?.account_type.account_type,
        accountTypeState: plan?.account_type.account_type_state,
        planStatus: plan?.plan_status,
        organization_id: `${plan.organization_id}`,
        deletable: plan?.deletable,
      };
    }

    return null;
  }, [plan]);

  const availablePlans = useMemo(() => {
    const activePlans = enrollments?.map((item) => item.planId) || null;

    if (isEdit && formattedPlan) {
      return [formattedPlan];
    }

    if (activePlans) {
      return formattedData?.filter((plan) => !activePlans.includes(plan.id));
    }

    return formattedData;
  }, [enrollments, formattedData, isEdit, formattedPlan]);

  const isAddingEnrollmentAvailable = useMemo(() => {
    const activePlans = enrollments?.map((item) => item.planId) || null;

    if (activePlans) {
      return !formattedData?.every((plan) => activePlans.includes(plan.id));
    }

    return !!formattedData?.length;
  }, [enrollments, formattedData]);

  const plansOptions = useMemo(() => availablePlans?.map((plan) => ({
    key: plan.id,
    value: plan.name,
    title: plan.name,
  })), [availablePlans]);

  const minElectionAmount = useMemo(
    () => plan?.plan_primary_config?.min_election_amount_type?.min_election_amount,
    [plan],
  );
  const maxElectionAmount = useMemo(
    () => plan?.plan_primary_config?.max_election_amount_type?.max_election_amount,
    [plan],
  );

  const currentPlan = useMemo(() => {
    const currPlan = formattedData?.find((plan) => plan.id === currentPlanId);

    if (isEdit && formattedPlan) {
      return {
        planYear: formattedPlan?.planYear.name,
        planStartDate: plan?.plan_year.valid_from,
        planEndDate: plan?.plan_year.valid_to,
        catchUpAge: plan?.plan_primary_config?.catch_up_age?.catch_up_age,
        catchUpElection: plan?.plan_primary_config?.catch_up_election?.catch_up_election,
      };
    }

    return {
      planYear: currPlan?.planYear.name,
      planStartDate: plan?.plan_year.valid_from,
      planEndDate: plan?.plan_year.valid_to,
      catchUpAge: plan?.plan_primary_config?.catch_up_age?.catch_up_age,
      catchUpElection: plan?.plan_primary_config?.catch_up_election?.catch_up_election,
    };
  }, [
    currentPlanId,
    formattedData,
    plan,
    isEdit,
    formattedPlan,
  ]);

  const partnerId = organizationInfo?.organizationPath?.split('\\')[2];

  const {
    name: currentPartnerName, isLoading: orgNameLoading,
  } = useGetOrgName({ id: partnerId, enabled: true });

  const partnerName = useMemo(() => currentPartnerName || '', [currentPartnerName]);
  const employerName = useMemo(() => organizationInfo?.organizationName || '', [organizationInfo]);

  const isLoading = useMemo(() => orgPlansLoading || orgNameLoading || isEnrollmentsLoading,
    [orgNameLoading, orgPlansLoading, isEnrollmentsLoading]);

  const SSN = useMemo(() => defaultFields?.ssn, [defaultFields]);
  const EEID = useMemo(() => defaultFields?.employerEmployeeId, [defaultFields]);
  const PEID = useMemo(() => defaultFields?.partnerEmployeeId, [defaultFields]);
  return {
    lastName,
    SSN,
    EEID,
    PEID,
    partnerName,
    employerName,
    isLoading,
    planLoading,
    plansOptions,
    currentPlan,
    minElectionAmount,
    maxElectionAmount,
    isAddingEnrollmentAvailable,
  };
};
