import React from 'react';
import { Box } from '@common-fe/common-fe';
import { RoundType } from 'grommet/utils';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

const OnHoverWrap = styled(Box)<{ isActive?: boolean }>`
  position: relative;
  box-shadow: none;
  outline: 1px solid ${({ isActive, theme }) => (isActive ? theme.colors.iconAccent : theme.colors.border1)};
  border: 1px solid ${({ isActive, theme }) => (isActive ? theme.colors.iconAccent : 'transparent')};

  &:hover {
    cursor: pointer;
    outline-color: ${({ theme }) => theme.colors.iconAccent};
    border-color: ${({ theme }) => theme.colors.iconAccent};
  }
`;

interface Props {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  hasBoxShadow?: boolean;
  round?: RoundType;
}

const BorderWrapper: React.FC<Props> = ({
  children, isActive, onClick, hasBoxShadow, round,
}) => (
  <Box
    width={{ min: '40px', max: '40px' }}
    height={{ min: '40px', max: '40px' }}
    background="canvas"
    round={round}
    {...(hasBoxShadow ? { style: { boxShadow: globalTheme.shadows.active['box-shadow'] } } : {})}
  >
    <OnHoverWrap
      margin="xxsmall"
      overflow="hidden"
      round="checkboxRound"
      height="40px"
      justify="center"
      isActive={isActive}
      {...(onClick ? { onClick } : {})}
    >
      {children}
    </OnHoverWrap>
  </Box>
);

export default BorderWrapper;
