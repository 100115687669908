import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CardsItemIcon,
  EmployerIcon,
  HelpIcon,
  Hint,
  Inscription,
  ListItem,
  PayMyBillIcon,
  PlusIcon,
  ReimburseMeIcon,
  Tip,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import routes from '@/common/routes';
import { ListItemType } from '@/common/types';
import InfoTileIcon from '@/components/elements/InfoTile/InfoTileIcon';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import Link from '@/modules/core/components/Link';
import { ClaimDto } from '@/modules/employer/components/Claims/types/claims.constants';
import { PaymentType } from '@/modules/transaction/transaction.types';
import theme from '@/styles/theme';

import Age from '../Age';
import useClaimsListQuery, { ClaimsQueryParams } from '../queries/useClaimsList.query';

export const formatClaimId = (id?: string) => {
  if (!id) return '';
  if (id.length > 7) return id;

  const fullId = `0000000${id}`;

  return `${fullId.slice(-7).slice(0, 1)}-${fullId.slice(-6).slice(0, 3)}-${fullId.slice(-3)}`;
};

const INIT_INTERVAL = 1000;
const DEFAULT_INTERVAL = 10000;
const MAX_CALLS_FOR_INIT_INTERVAL = 10;
const HINT_CLASS_NAME = 'hint-claim-teams';

const StyledList = styled.ul`
  list-style: disc;
  margin-left: 12px;
  font-size: 12px;
  li {
    line-height: 20px;
  }
`;
const List: React.FC<{items?: string[]}> = ({ items }) => {
  useEffect(() => {
    const style = document?.querySelector(`ul[class*=${HINT_CLASS_NAME}]`)?.parentElement?.parentElement?.style;
    if (style) {
      style.zIndex = '2001';
    }
  }, []);
  return (
    <StyledList className={HINT_CLASS_NAME}>
      {items?.map((item) => <li key={item}><Inscription size="small">{item}</Inscription></li>)}
    </StyledList>
  );
};

const StyledHint = styled(TextWithPointer)`
  &:hover {
    color: ${({ theme }) => theme.colors.textBody};
  }
`;
const AssigneWrapper = styled(Box)<{ isSelected?: boolean, hasAccessToAssignClaim?: boolean }>`
  cursor: pointer;
  ${({isSelected, theme}) => {
    if (isSelected) {
      return {
        background: theme.colors.canvas,
        border: `2px solid ${theme.colors.accentActive}`,
        outline: `4px solid ${theme.colors.infoBorder}`,
      };
    }
    return { background: theme.colors.noAssignee};
  }}
  .select-assighe-icon {
    display: ${({ isSelected }) => isSelected ? 'flex' : 'none'};
  }
  .default-assighe-icon {
    display: ${({ isSelected }) => isSelected ? 'none' : 'flex'};
  }
  ${({ hasAccessToAssignClaim, theme }) => {
    if (!hasAccessToAssignClaim) return '';

    return `
      &:hover {
        background: ${theme.colors.canvas};
        border: 2px solid ${theme.colors.accentActive};
        outline: 4px solid ${theme.colors.infoBorder};
        .select-assighe-icon {
          display: flex;
        }
        .default-assighe-icon {
          display: none;
        }
      }
    `;
  }}
`;

export const getPaymentTypeIcon = (paymentType?: PaymentType) => {
  if (paymentType === PaymentType.REIMBURSEMENT) {
    return <Box data-testid="ReimburseMeIcon" title="Reimburse me"><ReimburseMeIcon color="textSecondary" /></Box>;
  }
  if (paymentType === PaymentType.BILL_PAY) {
    return <Box data-testid="PayMyBillIcon" title="Pay a Bill"><PayMyBillIcon color="textSecondary" /></Box>;
  }
  if (paymentType === PaymentType.CARD_SWIPE) {
    return <Box data-testid="CardsItemIcon" title="Card swipe"><CardsItemIcon color="textSecondary" /></Box>;
  }

  return <HelpIcon data-testid="HelpIcon" color="textSecondary" />;
};

interface OrganizationNamesProps {
  employerName?: string,
  subsidiaryName?: string,
}

const OrganizationNames: React.FC<OrganizationNamesProps> = ({ employerName, subsidiaryName }) => (
  <Box margin={{ right: 'spacing16' }}>
    {
      employerName && (
        <TextWithPointer
          title={employerName}
          weight={700}
          disableHovering
          ellipsisMode
        >
          {employerName}
        </TextWithPointer>
      )
    }
    {subsidiaryName && (
      <TextWithPointer
        title={subsidiaryName}
        margin={{ top: 'spacing2' }}
        weight={500}
        size="12px"
        color="textSecondary"
        disableHovering
        ellipsisMode
      >
        {subsidiaryName}
      </TextWithPointer>
    )}
  </Box>
);

interface Params extends ClaimsQueryParams {
  hasAccessToAssignClaim?: boolean;
  isClockIn?: boolean;
  hasAccessToClockIn?: boolean;
  selectedClaimsIds: string[];
  setSelectedClaimsIds: (val: string[]) => void;
  hasClaimsTeamColumn?: boolean;
}

const useClaimsList = (params: Params) => {
  const { formatedData: claims, isLoading, total, refetch, isRefetching } = useClaimsListQuery(params);
  const {
    hasAccessToAssignClaim,
    isShowOnlyEscalated,
    isClockIn,
    hasAccessToClockIn,
    selectedClaimsIds,
    setSelectedClaimsIds,
    hasClaimsTeamColumn,
  } = params;
  const [numberOfRefetch, setNumberOfRefetch] = useState(0);

  useEffect(() => {
    if (!isClockIn && hasAccessToClockIn) {
      setNumberOfRefetch(-MAX_CALLS_FOR_INIT_INTERVAL);
    }
  }, [setNumberOfRefetch, isClockIn, hasAccessToClockIn]);

  useEffect(() => {
    if (isClockIn && hasAccessToClockIn) {
      setNumberOfRefetch(0);
    }
  }, [setNumberOfRefetch, isClockIn, hasAccessToClockIn]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (hasAccessToClockIn && params.isOnlyMyAssignmets && isClockIn) {
      if (!total && numberOfRefetch < MAX_CALLS_FOR_INIT_INTERVAL && numberOfRefetch >= 0) {
        timer = setTimeout(() => {
          refetch();
          setNumberOfRefetch(numberOfRefetch + 1);
        }, INIT_INTERVAL);
      }

      if (total && numberOfRefetch < MAX_CALLS_FOR_INIT_INTERVAL && numberOfRefetch >= 0) {
        timer = setTimeout(() => {
          refetch();
          setNumberOfRefetch(numberOfRefetch + 1);
        }, INIT_INTERVAL);
      }

      if ((numberOfRefetch >= MAX_CALLS_FOR_INIT_INTERVAL) && numberOfRefetch >= 0) {
        timer = setTimeout(() => {
          refetch();
          setNumberOfRefetch(numberOfRefetch + 1);
        }, DEFAULT_INTERVAL);
      }
    }

    if (hasAccessToClockIn && params.isOnlyMyAssignmets && !isClockIn) {
      if (total && numberOfRefetch < 0) {
        timer = setTimeout(() => {
          refetch();
          setNumberOfRefetch(numberOfRefetch + 1);
        }, INIT_INTERVAL);
      }

      if (total && numberOfRefetch >= 0 && numberOfRefetch <= MAX_CALLS_FOR_INIT_INTERVAL) {
        timer = setTimeout(() => {
          refetch();
          setNumberOfRefetch(numberOfRefetch + 1);
        }, DEFAULT_INTERVAL);
      }
    }

    return () => clearTimeout(timer);
  }, [total, refetch, params, numberOfRefetch, setNumberOfRefetch, isClockIn, hasAccessToClockIn]);

  const formattedList = useMemo(() => claims
    .map((item: ClaimDto) => {
      const isSelected = selectedClaimsIds?.some((id) => item.id === id);
      return {
        id: item.id,
        fields: [
          {
            key: 'claimsAssignee',
            type: ListItemType.Avatar,
            node: (
              <AssigneWrapper
                hasAccessToAssignClaim={hasAccessToAssignClaim}
                width="40px"
                height="40px"
                align="center"
                justify="center"
                round
                isSelected={isSelected}
                {...hasAccessToAssignClaim ? {
                  ...isSelected ? {
                    onClick: () => setSelectedClaimsIds([...selectedClaimsIds.filter((id) => id !== item.id)])
                  } : { onClick: () => setSelectedClaimsIds([...selectedClaimsIds, item.id]) }
                } : {}}
              >
                {item?.assignee ? <InfoTileIcon title={item.assignee} /> : (
                  <>
                    <Box className="select-assighe-icon"><PlusIcon size="16px" color="lockedNull" /></Box>
                    <Box className="default-assighe-icon"><EmployerIcon color="canvas" /></Box>
                  </>
                )}
              </AssigneWrapper>
            ),
            flex: '0 1 64px',
          },
          {
            key: 'claimsEmployer',
            type: ListItemType.Node,
            value: item?.organizationName,
            title: item?.organizationName,
            node: (
              <OrganizationNames
                employerName={item?.employerName}
                subsidiaryName={item.subsidiaryName}
              />
            ),
            flex: 1.1,
          },
          {
            key: 'claimsEmployee',
            type: ListItemType.Info,
            title: item?.employeeName || '',
            ellipsisMode: true,
            flex: 1.2,
            node: (
              <Link to={`${routes.CLAIM_PROCESS}/${item.id}` || ''}>
                <TextWithPointer
                  title={item?.employeeName || ''}
                  size="medium"
                  weight="bold"
                  color="textBody"
                  ellipsisMode
                  style={{
                    fontWeight: 'normal',
                    color: theme.colors.textAccent,
                    cursor: 'pointer',
                  }}
                >
                  {item?.employeeName || ''}
                </TextWithPointer>
              </Link>
            ),
          },
          {
            key: 'claimsClaimID',
            type: ListItemType.Text,
            title: formatClaimId(item.id),
            flex: 0.8,
            style: {
              color: theme.colors.textSecondary,
            },
          },
          {
            key: 'claimsPaymentType',
            type: ListItemType.Node,
            node: getPaymentTypeIcon(item.paymentType),
            flex: 0.5,
            style: {
              marginRight: theme.spacings.spacing12,
            },
          },
          {
            key: 'claimsAge',
            type: ListItemType.Node,
            node: <Age createdAt={item.updatedAt} />,
            flex: 0.6,
          },
          ...isShowOnlyEscalated ? [
            {
              key: 'escalatedNote',
              type: ListItemType.Node,
              node: (
                <Hint
                  hintAlign="vertical"
                  hintElement={<StyledHint ellipsisMode color="textBody">{item.escalationNote}</StyledHint>}
                  marginContent="m"
                >
                  <TextWithPointer>{item.escalationNote}</TextWithPointer>
                </Hint>
              ),
              flex: 1.2,
            },
          ] : [],
          ...hasClaimsTeamColumn ? [{
            key: 'claimTeams',
            type: ListItemType.Node,
            node: (
              <Box direction="row">
                <Tip
                  plain
                  dropProps={{ align: { bottom: 'top' } }}
                  content={!!item?.claimsTeams?.length && (
                    <Box pad={{ left: 'spacing12' }} margin={{ bottom: 'spacing4' }}>
                      <Box
                        width="max-content"
                        height={{ max: 'l' }}
                        align="center"
                        justify="center"
                        background="background3"
                        border={{ size: 'small', color: 'popup' }}
                        pad={{ horizontal: 'spacing6' }}
                      >
                        <Inscription size="small" color="textOnColor">
                          {item?.claimsTeams ? item?.claimsTeams[0]?.name || '' : ''}
                        </Inscription>
                      </Box>
                    </Box>
                  )}
                >
                  <Box
                    margin={{ left: 'spacing2' }}
                    justify="center"
                    style={{ cursor: 'pointer' }}
                  >
                    <TextWithPointer
                      ellipsisMode
                      size="medium"
                      weight="bold"
                    >
                      {item?.claimsTeams?.slice(0, 1)?.map((team) => team.name || '')?.join(', ')}
                      {(item?.claimsTeams?.length || 0) > 1 ? ',' : ''}
                    </TextWithPointer>
                  </Box>
                </Tip>
                {(item?.claimsTeams?.length || 0) > 1 ? (
                  <Box margin={{ left: 'spacing2' }}>
                    <Hint
                      hintAlign="vertical"
                      hintElement={<TextWithPointer ellipsisMode color="info">+{(item?.claimsTeams?.length || 0) - 1}</TextWithPointer>}
                      marginContent="m"
                    >
                      <List items={item?.claimsTeams?.map((team) => team?.name || '')} />
                    </Hint>
                  </Box>
                ) : null}
              </Box>
            ),
            flex: 1.2,
          }] : [],
          ...isShowOnlyEscalated && hasClaimsTeamColumn ? [] : [{
            key: 'claimsStatus',
            type: ListItemType.Status,
            title: item.status,
            flex: 1.6,
            ellipsisMode: true,
            style: {
              flexDirection: 'row-reverse',
              gap: theme.spacings.spacing8,
              marginRight: '20px',
            },
          }],
        ],
      };
    }),
  [
    claims,
    hasAccessToAssignClaim,
    isShowOnlyEscalated,
    selectedClaimsIds,
    setSelectedClaimsIds,
    hasClaimsTeamColumn,
  ]);

  const isEmpty = useMemo(() => claims.length === 0, [claims.length]);
  return {
    isEmpty,
    list: claims,
    data: formattedList as ListItem[] || [],
    isLoading,
    isRefetching,
    total,
    refetch,
  };
};

export default useClaimsList;
