import React, { useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  CoveragePeriods,
  EnrollmentStatus,
  Inscription,
  Text,
} from '@common-fe/common-fe';

import { PolicyStatus } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';

import { Status } from './EnrollmentExpandedPeriodCard';

const NO_DEADLINE_KEY = 'No deadline';

interface Props {
  coverageDateStart: string;
  coverageDateEnd: string;
  submitClaimsDateEnd: string;
  statusColor?: string;
  isDetailsMode?: boolean;
  enrollmentStatus?: EnrollmentStatus;
  coveragePeriods: CoveragePeriods[];
  statuses?: PolicyStatus[];
  isExpanded?: boolean;
  employeeName?: string;
}

export const EnrollmentPeriodCard: React.FC<Props> = ({
  coverageDateStart,
  coverageDateEnd,
  submitClaimsDateEnd,
  statusColor,
  isDetailsMode,
  enrollmentStatus,
  coveragePeriods = [],
  statuses = [],
  isExpanded,
  employeeName,
}) => {
  const prefixText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods.length) {
      currentText = `${employeeName} can’t submit claims for this enrollment.`;
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = 'Submit expenses with dates of service between';
    } else if (coverageDateStart && coverageDateEnd && !submitClaimsDateEnd) {
      currentText = 'Submit expenses with dates of service between ';
    } else if (coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = 'Submit expenses with dates of service on or after';
    } else if (coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = 'Submit expenses with dates of service on or after';
    } else if (!coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = 'Submit expenses with any date of service';
    } else if (!coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = 'Submit expenses with any date of service until ';
    }
    return (
      <Text size="small" weight={500} color="textSecondary">{`${currentText} `}</Text>
    );
  }, [coverageDateEnd, coverageDateStart, submitClaimsDateEnd, coveragePeriods, employeeName]);
  const rangeText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods.length) {
      currentText = '';
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${coverageDateStart} – ${coverageDateEnd}`;
    } else if (coverageDateStart && coverageDateEnd && !submitClaimsDateEnd) {
      currentText = `${coverageDateStart} – ${coverageDateEnd}`;
    } else if (coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${coverageDateStart}`;
    } else if (coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = `${coverageDateStart}`;
    } else if (!coverageDateStart && !coverageDateEnd && !submitClaimsDateEnd) {
      currentText = '';
    } else if (!coverageDateStart && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    }
    return (
      <Text
        data-testid="EnrollmentDates_coverage-period"
        size="small"
        margin={{ top: '' }}
        weight={700}
        style={{ display: 'inline-block' }}
      >
        {`${currentText}`}
      </Text>
    );
  }, [coverageDateEnd, coverageDateStart, submitClaimsDateEnd, coveragePeriods]);

  const postfixText = useMemo(() => {
    let currentText = '';
    if (!coveragePeriods.length) {
      currentText = '';
    } else if (coverageDateStart && coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    } else if (coverageDateEnd && !coverageDateEnd && submitClaimsDateEnd) {
      currentText = `${submitClaimsDateEnd}`;
    }
    if (!currentText) {
      return null;
    }
    return (
      <>
        <Text data-testid="EnrollmentDates_last-date-to-submit" size="small" weight={500} color="textSecondary">{' until '}</Text>
        <Text size="small" weight={700}>{currentText}</Text>
      </>
    );
  }, [coverageDateEnd, coverageDateStart, submitClaimsDateEnd, coveragePeriods]);
  if (isDetailsMode) {
    return (
      <Box
        margin={{ left: 'xxs' }}
        direction="row"
        align="center"
        width="100%"
      >
        <Inscription
          color="textSecondary"
          size="small"
          style={{ fontWeight: 500 }}
        >
          Enrollment status:
        </Inscription>
        <Status lineHeight="24px" iconColor={statusColor}>
          {enrollmentStatus && capitalizeFirstLetter(enrollmentStatus)}
        </Status>
        <Box  align="center" justify="center" height="4px" width="4px" round="full" background="border" margin={{ horizontal: 'xs' }} />
        <Box direction="row">
          <Text size="medium" style={{ lineHeight: 1 }}>
            {prefixText}
            {rangeText}
            {postfixText}
          </Text>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      width="100%"
      margin={{ left: isExpanded === undefined ? '0' : 'm' }}
    >
      <Box direction="row">
        {
          !coveragePeriods.length ? (
            <Text size="medium">
              {prefixText}
            </Text>
          ) : (
            <>
              <Box data-testid="EnrollmentDates_coverage-period" direction="column" pad={{ right: 's' }} margin={{ right: 's' }} border={{ side: 'right', size: 'small', color: 'border1' }}>
                <Text weight={500} size="small" color="textSecondary" style={{ lineHeight: '18px' }}>
                  {`Main coverage period ${coveragePeriods?.length > 1 ? `(${coveragePeriods?.length})` : ''}`}
                </Text>
                <Text weight={700} margin={{ top: 'xxs' }} size="small" color="textBody" style={{ lineHeight: '18px' }}>
                  {!coverageDateStart && !coverageDateEnd 
                    ? NO_DEADLINE_KEY : `${coverageDateStart || NO_DEADLINE_KEY} - ${coverageDateEnd || NO_DEADLINE_KEY}`}
                </Text>
              </Box>
              <Box direction="column" data-testid="EnrollmentDates_last-date-to-submit">
                <Text weight={500} size="small" color="textSecondary" style={{ lineHeight: '18px' }}>
                  Claim submit deadline                  
                </Text>
                <Text weight={700} margin={{ top: 'xxs' }} size="small" color="textBody" style={{ lineHeight: '18px' }}>
                  {submitClaimsDateEnd || NO_DEADLINE_KEY}
                </Text>
              </Box>
            </>
          )
        }
      </Box>
      <Box height="1px" background="border1" margin={{ vertical: 'xs' }} />
      {
        !isExpanded && enrollmentStatus && (
          <Box
            direction="row"
            align="center"
          >
            <Inscription
              color="textSecondary"
              size="small"
              style={{ fontWeight: 500 }}
            >
              Enrollment status:
            </Inscription>
            <Status weight="bold" lineHeight="24px" iconColor={statusColor}>
              {enrollmentStatus && capitalizeFirstLetter(enrollmentStatus)}
            </Status>
          </Box>
        )
      }
    </Box>
  );
};
