import React from 'react';
import { Box, LargeCloseIcon, RawButton } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledButton = styled(RawButton)`
  background-color: ${({ theme }) => theme.colors.legend};
  display: flex ;
  align-items: center;
  justify-content: center ;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentActive} ;
  }

`;
interface Props {
  onClick?: () => void;
}
const ClearButton: React.FC<Props> = ({ onClick }) => (
  <Box width="xxxsmall" round="button1Round" height="xxxsmall" style={{ overflow: 'hidden' }}>
    <StyledButton data-testid="clear-button" fill onClick={onClick}>
      <LargeCloseIcon color="white" />
    </StyledButton>
  </Box>
);

export default ClearButton;
