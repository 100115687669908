import _ from 'lodash';

import masks from '@/common/masks';

export default (cardNumber: string, isMasked?: boolean) => {
  const list = cardNumber.split('');

  let formatedResult = '';
  let count = 1;
   
  for (let i = list.length; i >= 0; i--) {
    let currentItem = list[i];
    if (currentItem) {
      if (isMasked) {
        currentItem = masks.CARD_CHAR;
      }
      if (count > masks.CARD_COUNT - 1) {
        count = 1;
        currentItem = ` ${currentItem}`;
      } else {
        count += 1;
      }

      formatedResult = `${currentItem}${formatedResult}`;
    }
  }

  return _.trim(formatedResult);
};
