import { useMemo } from 'react';

import { PLAN_MODULES } from '@/modules/plan/plan.constants';

import { useAccountFundingFields } from '../AccountFunding/hooks';
import { useCoverageFields } from '../Coverage/hooks';
import { usePlanDefinitionFields } from '../PlanDefinition/hooks';

export default () => {
  const planDefinitionFields = usePlanDefinitionFields();
  const coverageFields = useCoverageFields();
  const accountFundingFields = useAccountFundingFields();
  const fields = useMemo(() => {
    const modules: string[] = [];
    if (!planDefinitionFields.length) {
      modules.push(PLAN_MODULES.PRIMARY_DEFINITION);
    }
    if (!coverageFields.length) {
      modules.push(PLAN_MODULES.COVERAGE);
    }
    if (!accountFundingFields.length) {
      modules.push(PLAN_MODULES.ACCOUNT_FOUNDING);
    }

    return modules;
  }, [accountFundingFields.length, coverageFields.length, planDefinitionFields.length]);
  return fields;
};
