 
import React, { useCallback,useState } from 'react';
import { Prompt } from 'react-router-dom';
import { AppButton } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { ModalWrapper } from '@/components/wrappers';
import { Access } from '@/modules/core/components';

import CreateCarrierConfigForm from './CreateCarrierConfigForm';

const testId = 'Create_Carrier_Config';
const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const CreateCarrierConfig = () => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);
  const closeForm = useCallback(
    (isSuccess?: boolean) => {
      let confirm = true;

      if (isFormDirty && !isSuccess) {
        confirm = window.confirm(CONFIRM_TEXT);
      }

      if (confirm) {
        setIsVisible(false);
      }
    },
    [isFormDirty, setIsVisible],
  );

  return (
    <Access
      accessRequirements={[
        { permission: Permissions.CREATE_CARRIER_CONFIG },
      ]}
    >
      <AppButton
        testId={testId}
        onClick={() => setIsVisible(true)}
      >
        Create Carrier Config
      </AppButton>
      <ModalWrapper
        testId={testId}
        visible={isVisible}
        title="Create Carrier Config"
        onSetVisible={
          (value: boolean) => {
            if (value === false) {
              closeForm();
            } else {
              setIsVisible(value);
            }
          }
        }
        {...isOverflowHidden ? { wrapperStyle: { overflow: 'hidden' } } : {}}
      >
        <Prompt
          when={isFormDirty}
          message={CONFIRM_TEXT}
        />
        <CreateCarrierConfigForm
          setIsOverflowHidden={setIsOverflowHidden}
          onClose={(isSuccess?: boolean) => closeForm(isSuccess)}
          onDirty={setIsFormDirty}
        />
      </ModalWrapper>
    </Access>
  );
};

export default CreateCarrierConfig;
