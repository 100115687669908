import { ReportTypes } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  currentPopup?: ReportTypes;
  handleSetCurrentPopup: (value?: ReportTypes) => void;
  handleClear: () => void;
}

export const useReportFilterPopupStore = storeCreator<State>((set) => ({
  currentPopup: undefined,

  handleSetCurrentPopup: (value?: ReportTypes) => set((state) => ({
    ...state,
    currentPopup: value,
  })),

  handleClear: () => set((state) => ({
    ...state,
    currentPopup: undefined,
  })),
}));
