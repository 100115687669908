import React, { useCallback,useMemo, useState } from 'react';
import {
  AbsenceFilesPlaceholderIcon,
  AutocompleteDropdown,
  Box,
  ClearAll,
  FlexList,
  FlexListPlaceholder,
  NoSearchItemsPlaceholder,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { DefaultSortTypesEnum,ListItemBase, OptionKey, TableRowOption } from '@/common/types';
import { useCurrentOrganization, useHistory } from '@/modules/core/hooks';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import useOrganizationQuery from '@/modules/core/queries/useOrganization.query';
import { EMPLOYEE_STATUSE_OPTIONS,EmployeeStatus } from '@/modules/employee/employee.constants';
import { useEmployeesList } from '@/modules/employee/Employees/EmployeesList/hooks';
import useEmployeesListQuery from '@/modules/employee/hooks/useEmployeesList.query';
import { useListPagination } from '@/modules/employer/hooks';
import globalTheme from '@/styles/theme';

const FIRST_PAGE = 0;
const ACTIVE_STATUS = 'ACTIVE';

const StyledAutocompleteDropdown = styled(AutocompleteDropdown)`
  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: ${({ theme }) => theme.spacings.spacing24};
  }
`;

const employerFilterRequirements = [
  { organizationType: OrganizationTypes.system },
  { organizationType: OrganizationTypes.partner },
  { organizationType: OrganizationTypes.distributor },

  { organizationType: OrganizationTypes.employer, isException: true },
  { organizationType: OrganizationTypes.subsidiary, isException: true },
  { organizationType: OrganizationTypes.subgroup, isException: true },
];
const subsidiaryFilterRequirements = [
  { organizationType: OrganizationTypes.system },
  { organizationType: OrganizationTypes.partner },
  { organizationType: OrganizationTypes.distributor },
  { organizationType: OrganizationTypes.company },

  { organizationType: OrganizationTypes.subsidiary, isException: true },
  { organizationType: OrganizationTypes.subgroup, isException: true },
];
const headers: ListItemBase[] = [
  {
    key: 'firstName',
    title: 'First Name',
    flex: 1.2,
  },
  {
    key: 'lastName',
    title: 'Last Name',
    flex: 1.5,
  },
  {
    key: 'SSN',
    title: 'SSN',
    flex: 1,
  },
  {
    key: 'employerEmployeeId',
    title: 'ER Employee ID',
    flex: 1,
  },
  {
    key: 'partnerEmployeeId',
    title: 'PT Employee ID',
    flex: 1,
  },
  {
    key: 'employer',
    title: 'Employer/Subsidiary',
    flex: 1.8,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 1.24,
    style: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingRight: globalTheme.spacings.spacing24,
    },
  },
];
const SORT_BY_FIELD_NAME = 'person.lastName';
const EmployeesList = () => {
  const {
    observingOrganization,
    observingMode,
    observingEmployer,
    observingPartner,
  } = useCurrentOrganization();
  const history = useHistory();
  const isEmployerObserved = useMemo(
    () => observingEmployer?.id !== undefined, [observingEmployer],
  );
  const isPartnerObserved = useMemo(
    () => observingPartner?.id !== undefined, [observingPartner],
  );
  const hasEmployerFilter = useHasAccess(employerFilterRequirements);
  const hasSubsidiaryFilter = useHasAccess(subsidiaryFilterRequirements);
  const hasAccessToCreateClaim = useHasAccess([{ permission: Permissions.CREATE_CLAIM }]);
  const {
    page,
    perPage,
    currentSearch,
    filterParam,
    setSearchString,
    setFilterParam,
    setPage,
    setPerPage,
  } = useListPagination();
  const hasFilterParam = useMemo(
    () => filterParam && filterParam !== EmployeeStatus.Null,
    [filterParam],
  );
  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const [searchEmployerId, setSearchEmployerId] = useState<OptionKey>('');
  const [searchSubsidiaryId, setSearchSubsidiaryId] = useState<OptionKey>('');
  const [currentSearchString, setCurrentSearchString] = useState<OptionKey>('');

  const [employerSearchText, setEmployerSearchText] = useState('');
  const [subsidiarySearchText, setSubsidiarySearchText] = useState('');

  const [cleaningInitiator, runSerchTextCleaningForAutocomplete] = useState(false);
  const params = useMemo(() => ({
    sortByField: SORT_BY_FIELD_NAME,
    page: page - 1 < 0 ? 0 : page - 1,
    perPage,
    searchString: currentSearch || '',
    ...(hasFilterParam ? { status: filterParam } : {}),
    ...(sortBy ? {
      sortBy: sortBy as DefaultSortTypesEnum,
    } : {}),
    ...(observingMode ? { observingOrganizationId: observingOrganization.id } : {}),
    ...(observingOrganization?.type
      ? { observingOrganizationType: observingOrganization?.type } : {}),
    ...(searchEmployerId ? { searchEmployerId: searchEmployerId as string } : {}),
    ...(searchSubsidiaryId ? { searchSubsidiaryId: searchSubsidiaryId as string } : {}),
  }), [
    currentSearch,
    filterParam,
    hasFilterParam,
    observingMode,
    observingOrganization,
    page,
    perPage,
    searchEmployerId,
    searchSubsidiaryId,
    sortBy,
  ]);
  const { data, total, isLoading } = useEmployeesListQuery(params);
  const formatedList = useEmployeesList(data);

  const isSubsidiary = useMemo(
    () => observingOrganization?.type === OrganizationTypes.subsidiary,
    [observingOrganization],
  );

  const hasClearAll = useMemo(
    () => Boolean(currentSearch || searchEmployerId
      || (searchSubsidiaryId && !isSubsidiary) || hasFilterParam),
    [currentSearch, searchEmployerId, searchSubsidiaryId, hasFilterParam, isSubsidiary],
  );
  const handleClearAll = useCallback(() => {
    setSearchString('');
    setSearchEmployerId('');
    setSearchSubsidiaryId('');
    setCurrentSearchString('');
    setFilterParam(EmployeeStatus.Null);
    runSerchTextCleaningForAutocomplete(!cleaningInitiator);
  }, [
    cleaningInitiator,
    setFilterParam,
    setSearchString,
  ]);
  const placeholder = hasClearAll
    ? <FlexListPlaceholder />
    : (
      <NoSearchItemsPlaceholder
        placeholderTitle="There are no employees yet"
        node={<AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />}
      />
    );
  const employerAdditionalFetchParams = useMemo(() => ({
    type: OrganizationTypes.company,
    // ...(observingOrganization?.id ? { parentId: observingOrganization.id } : {}),
  }), []);

  const subsidiaryAdditionalFetchParams = useMemo(() => ({
    type: OrganizationTypes.subsidiary,
    parentId: searchEmployerId || observingEmployer?.id,
    statuses: ACTIVE_STATUS,
  }), [searchEmployerId, observingEmployer]);

  const {
    data: employerOptions = [],
    isLoading: isEmployerLoading,
  } = useOrganizationQuery(employerSearchText, employerAdditionalFetchParams);

  const {
    data: subsidiaryOptions = [],
    isLoading: isSubsidiariesLoading,
  } = useOrganizationQuery(subsidiarySearchText, subsidiaryAdditionalFetchParams);

  const handleChangeSearch = useCallback((id: OptionKey) => {
    setSearchString(id as string);
    setCurrentSearchString(id);
  }, [setSearchString]);

  const handleChangeEmployer = useCallback(
    (id: OptionKey) => {
      setSearchEmployerId(id);
      setPage(FIRST_PAGE);
    }, [setPage],
  );

  const handleChangeSubsidiary = useCallback((id: OptionKey) => setSearchSubsidiaryId(id), []);
  const isHasEditAccess = useHasAccess([
    {
      permission: Permissions.EDIT_EE_DATA,
    },
    {
      permission: Permissions.EDIT_PROFILE,
    },

  ]);

  const options = useMemo<TableRowOption[]>(() => [
    ...isHasEditAccess ? [{
      name: 'Edit',
      onClick: (employeeId: string) => history.push(ROUTES.EMPLOYEE_EDIT_VIEW(employeeId)),
    }] : [],
    {
      name: 'Submit claim',
      onClick: (employeeId: string) => {
        window.open(`${ROUTES.REIMBURSE_ME(employeeId)}${window.location.search}`, '_blank');
      },
      hasNewBlankIcon: true,
      isAvailable: (fields: unknown, id: string) => {
        const isReimbursementAllowed = data
          .find((employee) => employee.id === id)?.isReimbursementAllowed;

        return Boolean(isReimbursementAllowed) && hasAccessToCreateClaim;
      },
    },
  ], [data, hasAccessToCreateClaim, history, isHasEditAccess]);

  return (
    <FlexList
      testId="EmployeesList"
      headers={headers}
      options={options}
      rows={formatedList}
      total={total}
      pad="spacing24"
      moreCount={perPage}
      loading={isLoading}
      placeholder={placeholder}
      footer={(
        <PaginationBar
          testId="EmployeesList"
          page={page}
          total={total}
          pageSize={perPage}
          onChangePage={setPage}
          onChangePageSize={setPerPage}
        />
      )}
    >
      <>
        <Box margin={{ top: '-50px', bottom: '20px' }}>
          <ClearAll onClick={handleClearAll} isVisible={hasClearAll} />
        </Box>
        <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing16' }}>
          <Text
            margin={{ top: 'spacing8', bottom: 'spacing12', right: 'spacing12' }}
            weight="bold"
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
          >Employees: {total || 0}
          </Text>
          <Box direction="row" justify="end">
            <Box width={{ min: 'control' }}>
              <SearchInput
                hasSearchingHistory
                testId="EmployeesList"
                disabled={formatedList.length === 0 && !currentSearch}
                placeholder="Search"
                contentWidth={320}
                value={currentSearchString as string}
                onChange={handleChangeSearch}
                resetValue={cleaningInitiator}
              />
            </Box>

            {hasEmployerFilter && !isEmployerObserved && (
              <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                <StyledAutocompleteDropdown
                  testId="EmployeesList"
                  disabled={formatedList.length === 0 && !searchEmployerId && !searchSubsidiaryId}
                  name="Employer"
                  placeholder="Employer"
                  cleaningInitiator={cleaningInitiator}
                  onSearch={setEmployerSearchText}
                  options={employerOptions}
                  isLoading={isEmployerLoading}
                  value={searchEmployerId}
                  onChange={handleChangeEmployer}
                  className="ellipsis"
                />
              </Box>
            )}

            {hasSubsidiaryFilter && (
              <Box
                margin={{ left: 'spacing12' }}
                width={{ min: 'control' }}
                title="Searching by Subsidiary available after selection of an Employer."
              >
                <StyledAutocompleteDropdown
                  testId="EmployeesList-subsidiaryFilter"
                  disabled={!searchEmployerId && ((formatedList.length === 0)
                    || (!isEmployerObserved && !isPartnerObserved) || isSubsidiary)}
                  onSearch={setSubsidiarySearchText}
                  name="Subsidiary"
                  placeholder="Subsidiary"
                  options={subsidiaryOptions}
                  cleaningInitiator={cleaningInitiator}
                  isLoading={isSubsidiariesLoading}
                  onChange={handleChangeSubsidiary}
                  value={searchSubsidiaryId}
                  {...isSubsidiary ? { defaultValue: `${observingOrganization.id}` } : {}}
                  // isSearchMode
                  className="ellipsis"
                />
              </Box>
            )}

            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <SelectDropdown
                id="status"
                testId="EmployeesList-statusFilter"
                disabled={formatedList.length === 0 && !hasFilterParam}
                singleMode
                ellipsisMode
                activeTitle
                name="Activity status"
                prefix="Status"
                options={EMPLOYEE_STATUSE_OPTIONS}
                value={filterParam || EmployeeStatus.Null}
                onChange={setFilterParam}
              />
            </Box>

            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <SelectDropdown
                id="sort_by"
                testId="EmployeesList_sortByFilter"
                disabled={formatedList.length === 0}
                singleMode
                ellipsisMode={false}
                activeTitle
                name="Sort By"
                prefix="Sort By"
                options={[
                  {
                    key: DefaultSortTypesEnum.ASC,
                    value: DefaultSortTypesEnum.ASC,
                    title: 'A-Z',
                  },
                  {
                    key: DefaultSortTypesEnum.DESC,
                    value: DefaultSortTypesEnum.DESC,
                    title: 'Z-A',
                  },
                ]}
                value={sortBy}
                onChange={setSortBy}
              />
            </Box>
          </Box>
        </Box>
      </>
    </FlexList>
  );
};

export default EmployeesList;
