import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { useContactStore } from '../AddEditContactForm/stores';
import { useAddressStore } from '../EditAddressModal/stores';
import { ExpressSetupProperty } from '../ExpressSetup.types';
import { useBankAccountStore } from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/stores';
import { useCopayStore } from '../ExpressSetupWizard/PlansWizard/Copays/stores';
import { usePlansStore } from '../ExpressSetupWizard/PlansWizard/Plans/stores';
import { usePlanYearStore } from '../ExpressSetupWizard/PlansWizard/PlanYear/stores';

import { useExpressSetupSaveValidate } from './useExpressSetupSaveValidate';
import useParseErrors from './useParseErrors';
import useUpdateExpressSetup from './useUpdateExpressSetup';

const ERRORS = {
  SERVER_ERROR: 500,
};

export const useHandleExpressSetupSetUp = (onEditToggle?: (value: boolean) => void) => {
  const [isPlanYearLoading, setPlanYearLoading] = useState(false);
  const [isPlansLoading, setPlansLoading] = useState(false);
  const [isBankAccountLoading, setBankAccountLoading] = useState(false);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const {
    contactErrors,
    planYearErrors,
    plansErrors,
    bankAccountErrors,
    copayErrors,
    addressErrors,
  } = useExpressSetupSaveValidate();
  
  const [isContactServerError, setContactServerError] = useState(false);
  const [isPlansServerError, setPlansServerError] = useState(false);
  const [isPlanYearServerError, setPlanYearServerError] = useState(false);
  const [isBankAccountServerError, setBankAccountServerError] = useState(false);
  const [isAddressServerError, setAddressServerError] = useState(false);

  const contact = useContactStore((state) => state.state);
  const contactName = useMemo(() => `${contact?.firstName || ''} ${contact?.lastName || ''}`.trim(), [contact]);

  const handleShowErrorsContact = useContactStore((state) => state.setShowErrors);
  const handleShowErrorsPlanYear = usePlanYearStore((state) => state.setShowErrors);
  const handleShowErrorsPlans = usePlansStore((state) => state.setShowErrors);
  const handleShowErrorsCopays = useCopayStore((state) => state.setShowErrors);
  const handleShowErrorsBankAccount = useBankAccountStore((state) => state.setShowErrors);
  const handleShowErrorsAddress = useAddressStore((state) => state.setShowErrors);

  const isReadyToSaveCopays = useCopayStore((state) => state.state.isReadyToSaveCopays);

  const handleParseErors = useParseErrors();
  const handleShowError = useCallback((error: unknown) => {
    handleParseErors(error);
  }, [handleParseErors]);

  const { handleSave: handleSaveExpressSetup, isLoading, isSuccess } = useUpdateExpressSetup(handleShowError);
  
  const onSaveContactsHandler = useCallback(async (isUpdateMode?: boolean, isMainContactMode?: boolean, successCallback?: () => void) => {
    handleShowErrorsContact(true);
    setContactServerError(false);
    if (_.isEmpty(contactErrors)) {
      try {
        await handleSaveExpressSetup(ExpressSetupProperty.Contacts);
        handleAddPermanentSnackbar({
          text: isUpdateMode ? `Your changes to ${contactName} have been saved.` : isMainContactMode ? `${contactName} has been added as a main contact.` : `${contactName} has been added.`,
          closeIcon: true,
          state: SnackbarState.positive,
        });
        if (onEditToggle) onEditToggle(false);
        if (successCallback) successCallback();
        handleShowErrorsContact(false);
      } catch (error) {
        const status = _.get(error, 'response.status') as unknown as number;
        if (status >= ERRORS.SERVER_ERROR) {
          setContactServerError(true);
        }
      }
    }
  }, [handleShowErrorsContact, contactErrors, handleSaveExpressSetup, handleAddPermanentSnackbar, contactName, onEditToggle]);

  const onSavePlanYearHandler = useCallback(async (successCallback?: () => void) => {
    handleShowErrorsPlanYear(true);
    setPlanYearLoading(true);
    setPlanYearServerError(false);
    try {
      if (_.isEmpty(planYearErrors)) {
        await handleSaveExpressSetup(ExpressSetupProperty.PlanYear);
        handleAddPermanentSnackbar({
          text: 'Your progress has been saved!',
          closeIcon: true,
          state: SnackbarState.positive,
        });
        if (successCallback) successCallback();
        handleShowErrorsPlanYear(false);
      }
    } catch (error) {
      const status = _.get(error, 'response.status') as unknown as number;
      if (status >= ERRORS.SERVER_ERROR) {
        setPlanYearServerError(true);
      }
      setPlanYearLoading(false);
    } finally {
      setPlanYearLoading(false);
    }
  }, [handleShowErrorsPlanYear, setPlanYearLoading, planYearErrors, handleSaveExpressSetup, handleAddPermanentSnackbar]);

  const onSavePlansHandler = useCallback(async (errorCallback?: () => void) => {
    handleShowErrorsPlans(true);
    handleShowErrorsCopays(true);
    setPlansServerError(false);
    if (!_.isEmpty(plansErrors) && errorCallback) {
      setPlansLoading(false);
      errorCallback();
    }
    if ((_.isEmpty(plansErrors) && _.isEmpty(copayErrors) && isReadyToSaveCopays) || _.isEmpty(plansErrors) && !isReadyToSaveCopays) {
      setPlansLoading(true);
      try {
        await handleSaveExpressSetup(ExpressSetupProperty.Plans);
        handleAddPermanentSnackbar({
          text: 'Your progress has been saved!',
          closeIcon: true,
          state: SnackbarState.positive,
        });
        handleShowErrorsPlans(false);
        handleShowErrorsCopays(false);
      } catch (error) {
        const status = _.get(error, 'response.status') as unknown as number;
        if (status >= ERRORS.SERVER_ERROR) {
          setPlansServerError(true);
        }
        setPlansLoading(false);
      } finally {
        setPlansLoading(false);
      }
    }
  }, [handleShowErrorsPlans, handleShowErrorsCopays, plansErrors, copayErrors, isReadyToSaveCopays, handleSaveExpressSetup, handleAddPermanentSnackbar]);

  const onSaveBankAccountHandler = useCallback(async (isUpdateMode?: boolean, successCallback?: () => void, isRemoveMode?: boolean) => {
    handleShowErrorsBankAccount(true);
    setBankAccountLoading(true);
    setBankAccountServerError(false);
    try {
      if (_.isEmpty(bankAccountErrors) || isRemoveMode) {
        await handleSaveExpressSetup(ExpressSetupProperty.BankAccount, isRemoveMode);
        handleAddPermanentSnackbar({
          text: isRemoveMode ? 'Bank account has been removed' : isUpdateMode ? 'Your changes to bank account has been saved' : 'Bank account has been added',
          closeIcon: true,
          state: SnackbarState.positive,
        });
        if (successCallback) successCallback();
        handleShowErrorsBankAccount(false);
      }
    } catch (error) {
      const status = _.get(error, 'response.status') as unknown as number;
      if (status >= ERRORS.SERVER_ERROR) {
        setBankAccountServerError(true);
      }
      setBankAccountLoading(false);
    } finally {
      setBankAccountLoading(false);
    }
  }, [handleShowErrorsBankAccount, bankAccountErrors, handleSaveExpressSetup, handleAddPermanentSnackbar]);

  const onSaveAddressHandler = useCallback(async (successCallback?: () => void) => {
    handleShowErrorsAddress(true);
    setAddressServerError(false);
    if (_.isEmpty(addressErrors)) {
      try {
        await handleSaveExpressSetup(ExpressSetupProperty.Address);
        handleAddPermanentSnackbar({
          text: 'Address has been changed.',
          closeIcon: true,
          state: SnackbarState.positive,
        });
        if (onEditToggle) onEditToggle(false);
        if (successCallback) successCallback();
        handleShowErrorsAddress(false);
      } catch (error) {
        const status = _.get(error, 'response.status') as unknown as number;
        if (status >= ERRORS.SERVER_ERROR) {
          setAddressServerError(true);
        }
      }
    }
  }, [handleShowErrorsAddress, addressErrors, handleSaveExpressSetup, handleAddPermanentSnackbar, onEditToggle]);

  return {
    isLoading,
    isPlanYearLoading,
    isPlansLoading,
    isBankAccountLoading,
    isSuccess,
    isContactServerError,
    isPlanYearServerError,
    isPlansServerError,
    isBankAccountServerError,
    isAddressServerError,
    onSaveContactsHandler,
    onSavePlanYearHandler,
    onSavePlansHandler,
    onSaveBankAccountHandler,
    onSaveAddressHandler,
  };
};
