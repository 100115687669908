import React, { useCallback, useEffect,useState } from 'react';
import {
  Box, FlatButton,
  FlexControlledForm, PlusIcon, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import theme from '@/styles/theme';

import AddServicePaymentModal from '../AddServicePaymentModal';

import useTierFields from './hooks/useTierFields';
import { TierField } from './hooks/useTiers';
import { ServiceConfig } from './queries/useServiceConfigs.query';
import ServiceConfigItem from './ServiceConfig/ServiceConfigItem';

const StyledButton = styled(FlatButton)`
  &:hover {
    opacity: 0.8
  }
`;

interface Props {
  isRemoveMode?: boolean;
  isLastItem?: boolean;
  id: string;
  index: number;
  defaultValues?: Partial<TierField>;
  isErrorShowed?: boolean;
  isTemplateMode?: boolean;
  isEditMode?: boolean;
  payoutDefinitionId: string;
  serviceConfigs?: ServiceConfig[];
  deductibleType?: string;
  healthPlanTypes?: string[];
  healthPlanStatus?: HealthPlanStatuses;
}
const TierItem: React.FC<Props> = ({
  id, isErrorShowed, index, defaultValues, serviceConfigs, healthPlanTypes, healthPlanStatus,
  isLastItem, isTemplateMode, isEditMode, payoutDefinitionId, deductibleType,
}) => {
  const [updatedElement, setUpdatedElement] = useState<ServiceConfig>();
  const [visible, setVisible] = useState(false);
  const handleToggleVision = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  useEffect(() => {
    if (updatedElement) setVisible(true);
  }, [updatedElement]);
  const fields = useTierFields(defaultValues);
  return (
    <Box
      key={id}
      pad="m"
      background="module"
      round="moduleRound"
      margin={{ bottom: isLastItem ? '0' : 'l' }}
    >
      <Box
        round="container1Round"
        background="canvas"
        pad={{ top: 'xl', horizontal: 'xs' }}
        border={{ side: 'all', size: 'small', color: theme.colors.border2 }}
      >
        <Box direction="row" align="center" justify="between" margin={{ horizontal: 'l' }}>
          <Text size="xxlarge" weight={400}>{`Tier ${index + 1}`}</Text>
          {!isTemplateMode && isEditMode && (
            <StyledButton onClick={handleToggleVision}>
              <Box direction="row" align="center" justify="center">
                <Text
                  margin={{ right: 's' }}
                  color="button"
                  weight={400}
                >
                  Add Service Payment
                </Text>
                <PlusIcon size="small" color="button" />
              </Box>
            </StyledButton>
          )}
          {!isTemplateMode && isEditMode && visible && (
            <AddServicePaymentModal
              visible={visible}
              setVisible={handleToggleVision}
              tierId={id}
              payoutDefinitionId={payoutDefinitionId}
              updatedElement={updatedElement}
              setUpdatedElement={setUpdatedElement}
              deductibleType={deductibleType}
              healthPlanTypes={healthPlanTypes}
            />
          )}
        </Box>
        <FlexControlledForm
          key={id}
          testId={`tier-item_${id}`}
          fields={fields}
          editMode={false}
          // onDirty={setShouldConfirmOnClose}
          onChangeValues={(values) => {}}
          showError={isErrorShowed}
          isThinMode
          wrapperStyle={{ border: 'none' }}
        />
      </Box>
      {
        serviceConfigs?.map((item, indexEl) => (
          <ServiceConfigItem
            key={item?.id}
            value={item}
            isFirstItem={indexEl === 0}
            setUpdatedElement={setUpdatedElement}
            healthPlanStatus={healthPlanStatus}
          />
        ))
      }
    </Box>
  );
};

export default TierItem;
