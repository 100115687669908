import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  Box, CalendarIcon, DateRangeDropdown, FlexList,
  PaginationBar, RANGE_DATE_KEYS, SearchInput, Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { ListItemBase } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import { useListPagination } from '@/modules/employer/hooks';
import { useReconciliationReportActivityList } from '@/modules/employer/queries/useAccountsActivity.query';
import { useReconciliationReportDetails } from '@/modules/employer/queries/useReconciliationBankAccounts.query';

const HEADERS: ListItemBase[] = [
  {
    key: 'closeDate',
    title: 'Close Date',
  },
  {
    key: 'ourBalance',
    title: 'Our Balance',
  },
  {
    key: 'bankBalance',
    title: 'Bank Balance',
  },
  {
    key: 'difference',
    title: 'Difference',
  },
  {
    key: 'prior',
    title: 'Prior',
  },
  {
    key: 'delta',
    title: 'Delta',
  },
  {
    key: 'credit',
    title: 'Credit',
  },
  {
    key: 'debit',
    title: 'Debit',
  },
];

export const ReconciliationReportActivityList = () => {
  const { push, location, queryMap } = useHistory();
  const todayDate = dayjs().format(DEFAULT_DATE_FORMAT);
  const dateRangeFromUrl = useMemo(() => (
    queryMap.reconciliationReportActivityDate
      ? queryMap.reconciliationReportActivityDate.split(',')
   
        .map((dateStr: string) => dayjs(dateStr, DEFAULT_DATE_FORMAT).toDate())
      : null
  ),
  [queryMap]);
  const defaultDateRange = useMemo(() => [
    dayjs().add(-10, 'day').toDate(),
    dayjs().toDate(),
  ], []);
  const [dateRange, setDateRange] = useState<Array<Date | null>>(
    dateRangeFromUrl || defaultDateRange,
  );
  const { id } = useParams<{ id: string }>();
  const { listFormat } = useReconciliationReportDetails(id);
  const {
    page,
    perPage,
    currentSearch,
    setPage,
    setPerPage,
    searchString,
    setSearchString,
  } = useListPagination();

  const {
    data,
    total,
    isLoading,
  } = useReconciliationReportActivityList(id, {
    page: page - 1,
    perPage,
    searchString: currentSearch || '',
    account_id: id,
    start_date: dateRange[0] ? dayjs(dateRange[0]).format(DEFAULT_DATE_FORMAT) : '',
    end_date: dateRange[1] ? dayjs(dateRange[1]).format(DEFAULT_DATE_FORMAT) : '',
  });

  useEffect(() => {
    setDateRange(dateRangeFromUrl || defaultDateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!listFormat.length) return null;

  return (
    <Box
      data-testid="ReconciliationListOfBankAccounts"
      background={{ color: 'module' }}
      round="container1Round"
    >
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ bottom: 'spacing12', top: 'spacing24', horizontal: 'spacing24' }}
      >
        <Text
          size="large"
          weight="bold"
          color="textBody"
        >
          Account Activities: {total}
        </Text>
        <Box direction="row" justify="end">
          <Box margin={{ left: 'spacing8' }} width={{ min: '180px', max: '180px' }}>
            <SearchInput
              className="search-input"
              value={searchString}
              placeholder="Search"
              onChange={setSearchString}
            />
          </Box>
          <Box margin={{ left: 'spacing8' }} width="220px">
            <DateRangeDropdown
              className="period-picker"
              ranges={
                [
                  RANGE_DATE_KEYS.LAST_10_DAYS,
                  RANGE_DATE_KEYS.LAST_MONTH,
                  RANGE_DATE_KEYS.LAST_3_MONTH,
                  RANGE_DATE_KEYS.LAST_6_MONTH,
                  RANGE_DATE_KEYS.START_YEAR_TO_TODAY,
                ]
              }
              onChange={(range) => {
                push(location.pathname, {
                  [QueryKeys.RECONCILIATION_REPORTS_ACTIVITY_DATE]: range
                    .filter((date) => Boolean(date))
                    .map((date) => dayjs(date as Date).format(DEFAULT_DATE_FORMAT))
                    .join(','),
                });
                setDateRange(range);
              }}
              maxDate={dayjs(todayDate)}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              icon={(
                <Text size="xlarge">
                  <CalendarIcon size="16px" color="iconPrimary" />
                </Text>
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box overflow={{ horizontal: 'auto' }}>
        <Box>
          <FlexList
            headers={HEADERS}
            rows={data}
            total={total}
            loading={isLoading}
            moreCount={perPage}
            footer={(
              <PaginationBar
                page={page}
                total={total}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
