import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AppButton, Box,   Heading, HotKeys,
  SearchInput,
  ThinSearchIcon,   useHotKeyPressIndicator, } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
  transition: .2s;
`;

interface Props {
  searchString: string;
  setSearchString: (value: string) => void;
}

const PlansSearch: React.FC<Props> = ({
  searchString, setSearchString,
}) => {
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchInputMode, setSearchInputMode] = useState(false);
  const handleCancel = useCallback(() => {
    setSearchInputMode(false);
  }, [setSearchInputMode]);

  useEffect(() => {
    if (searchInputMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchInputMode]);

  useHotKeyPressIndicator(
    ref,
    handleCancel,
    HotKeys.Esc,
  );
  return (
    <Box
      style={{
        textDecoration: 'none',
      }}
    >
      {
        searchInputMode
          ? (
            <Box width="240px" margin={{ right: 'l' }}>
              <SearchInput
                inputRef={inputRef}
                value={searchString}
                placeholder="Search"
                onChange={setSearchString}
              />
            </Box>
          ) : (
            <Box margin={{ right: 'l' }}>
              <AppButton
                onlyIcon
                style={{
                  width: '40px',
                }}
                color="grey"
                buttonType="secondary"
                onClick={() => setSearchInputMode(true)}
              >
                <ThinSearchIcon />
              </AppButton>
            </Box>
          )
      }
    </Box>
  );
};

export default PlansSearch;
