 
import { useCallback, useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { NON_ASSIGNABLE_VALUE, PermissionKey,REQUIRED_TEXT } from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { BankMethod } from '@/modules/employer/hooks/useBankMethods.query';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import { AccountUsageValues } from '../AccountUsage.types';

const placeholder = 'Select bank account';

const usePayrollContributionFields = (
  banks: BankInfo[],
  methods: BankMethod[],
  formattedData: EmployerDto,
  currentValue?: AccountUsageValues,
  activateMode?: boolean,
  isLoading?: boolean,
) => {
  const externalPayrollContributionsAccountMethodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EXTERNAL_PAYROLL_CONTRIBUTIONS_ACCOUNT_METHOD,
  );
  const payrollContributionsHoldPeriodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PAYROLL_CONTRIBUTIONS_HOLD_PERIOD,
  );
  const { viewMode } = useSetupEmployerMode();

  const getDefaultValue = useCallback((value?: string) => {
    const bankId = banks.find((bank) => bank.id === value)?.id;

    if (bankId) return bankId;

    if (formattedData?.normalProperties?.externalAccountEnable
      || value === NON_ASSIGNABLE_VALUE) return NON_ASSIGNABLE_VALUE;

    return '';
  }, [banks, formattedData]);

  const externalPayrollContributions = useMemo(
    () => currentValue?.payrollContributionBank,
    [currentValue],
  );

  return useMemo(() => {
    if (isLoading) return [];

    const options = banks.map((bank) => ({
      key: bank.id,
      value: bank.description,
    }));

    return [
      {
        name: 'payrollContributionBank',
        type: FieldTypes.Dropdown,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        areSubFieldsShouldHide: !externalPayrollContributions || externalPayrollContributions === NON_ASSIGNABLE_VALUE,
        placeholder,
        singleMode: true,
        label: 'Bank Account',
        options: [
          {
            key: NON_ASSIGNABLE_VALUE,
            value: NON_ASSIGNABLE_VALUE,
          },
          ...options,
        ],
        subFields: [
          {
            name: 'payrollContributionMethod',
            type: FieldTypes.Radio,
            disabled: viewMode,
            parentValueFn: (val) => !!val,
            label: 'Method',
            validator: yup.string().test({
              test: (val) => !!(val || formattedData?.overridableProperties?.externalPayrollContributionsAccountMethod),
              message: REQUIRED_TEXT,
            }),
            options: methods.map((option) => ({
              label: option.description,
              value: option.name,
            })),
            value: formattedData?.overridableProperties?.externalPayrollContributionsAccountMethod,
            showRequireIcon: true,
            ...externalPayrollContributionsAccountMethodPermProps,
          },
          {
            name: 'holdPeriod',
            type: FieldTypes.Mask,
            mask: '99',
            label: 'Hold Period',
            placeholder: 'Enter # of days',
            value: formattedData?.overridableProperties?.payrollContributionsHoldPeriod,
            validator: yup.string().trim(),
            disabled: viewMode,
            fill: false,
            ...payrollContributionsHoldPeriodPermProps,
          },
        ],
        areSubFieldsIncluded: true,
        defaultValue: getDefaultValue(
          formattedData?.normalProperties?.externalPayrollContributionsAccount,
        ),
        value: getDefaultValue(
          formattedData?.normalProperties?.externalPayrollContributionsAccount,
        ),
        hasHorizontalLine: true,
      },
    ] as Field[];
  }, [
    activateMode,
    banks,
    externalPayrollContributions,
    formattedData,
    getDefaultValue,
    isLoading,
    methods,
    viewMode,
    externalPayrollContributionsAccountMethodPermProps,
    payrollContributionsHoldPeriodPermProps,
  ]);
};

export default usePayrollContributionFields;
