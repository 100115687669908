import React, { useMemo } from 'react';
import { Box, FlatTextInput, Text } from '@common-fe/common-fe';

import { CLOSE_SYMBOL, OPEN_SYMBOL } from './FormulaDragAndDrop/FormulaDragAndDrop';
import FormulaSubfield from './FormulaSubfield';

interface Props {
  value: string;
  text?: string;
  placeholder?: string;
}

export const MAX_FORMULA_COUNT = 8;

const FundIDFOrmulaResult: React.FC<Props> = ({ value, text, placeholder }) => {
  const length = useMemo(() => value
    .replace(OPEN_SYMBOL, '')
    .replace(CLOSE_SYMBOL, '').length, [value]);
  const isError = useMemo(() => length > MAX_FORMULA_COUNT, [length]);
  const color = useMemo(() => {
    if (!length) {
      return 'textDisabled';
    }
    if (isError) {
      return 'error';
    }
    return 'success';
  }, [isError, length]);
  return (
    <FormulaSubfield
      label="Final fund ID formula"
      text={text || 'The Final Fund ID formula consists of the Prefix plus Expression. The total number of digits in the Final Fund ID formula must not exceed 8 digits.'}
    >
      <Box direction="row" align="center">
        <Box width="180px">
          <FlatTextInput
            disabled
            placeholder={placeholder || 'Prefix{}'}
            value={value}
          />
        </Box>
        <Text margin={{ left: 's' }} weight={400} data-testid={`counter-color-${color}`} color={color}>
          {`${length}/${MAX_FORMULA_COUNT} digits`}
        </Text>
      </Box>
      {isError && (
        <Text data-testid="fund-formula_error-mesage" color="danger" margin={{ top: 's' }} weight={400}>
          Please change the Prefix or Formula expression to not exceed {MAX_FORMULA_COUNT} digits.
        </Text>
      )}
    </FormulaSubfield>
  );
};

export default FundIDFOrmulaResult;
