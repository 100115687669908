export const MODULES = {
  PERSONAL_INFO: 'personal_info',
  ENROLLMENT_AND_BALANCES: 'Enrollment_balances',
  EXPENSE_DETAILS: 'expense_details',
  ACTIVITY_LOG: 'activity_log',
  CARDS: 'cards',
  DEPENDENTS: 'dependents',
  COMMUNICATIONS: 'Communications',
};
export const employeeConfigModules = {
  [MODULES.PERSONAL_INFO]: 'Personal Information',
  [MODULES.ENROLLMENT_AND_BALANCES]: 'Enrollment & Balances',
  [MODULES.EXPENSE_DETAILS]: 'Expense Details',
  [MODULES.CARDS]: 'Cards',
  [MODULES.DEPENDENTS]: 'Dependents',
  [MODULES.COMMUNICATIONS]: 'Communications',
  [MODULES.ACTIVITY_LOG]: 'Activity Log',
};

interface Args {
  exluce?: string[];
  hasCards?: boolean;
  isContainNotificationCenter?: boolean;
  isContainEmail?: boolean;
}

export const useEmployeeModules = (args?: Args) => Object
  .keys(employeeConfigModules)
  .filter((anchorId) => !((!args?.isContainNotificationCenter && !args?.isContainEmail)
    && anchorId === MODULES.COMMUNICATIONS))
  .filter((anchorId) => !(!args?.hasCards && anchorId === MODULES.CARDS))
  .reduce((map, anchorId) => {
    if (args?.exluce && args.exluce.includes(anchorId)) {
      return map;
    }
    return ({
      ...map,
      [anchorId]: {
        title: employeeConfigModules[anchorId],
      },
    });
  }, {});

export default useEmployeeModules;
