import React from 'react';

const HelpLink: React.FC<{
  children: React.ReactElement,
  url?: string,
}> = ({ children, url }) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'none' }}
        data-testid="help-url-link"
      >
        {children}
      </a>
    );
  }

  return children;
};

export default HelpLink;
