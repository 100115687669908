import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
  validatePhoneNumber,
  validateUrl,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { EMAIL_INVALID_TEXT, PermissionKey } from '@/common/constants';
import regexp from '@/common/regexp';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

interface Props {
  isDisabledMode: boolean;
  customerServiceNumber?: string;
  customerServiceUrlName?: string;
  customerServiceUrlLink?: string;
  customerServiceEmail?: string;
  customerServiceIsEnabled?: boolean;
}

export const useHelpSectionFields = (props: Props, triggeredId?: string) => {
  const customerServiceEmailPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CUSTOMER_SERVICE_EMAIL,
  );
  const customerServiceNumberPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CUSTOMER_SERVICE_NUMBER,
  );
  const customerServiceUrlLinkPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CUSTOMER_SERVICE_URL_LINK,
  );
  const customerServiceUrlNamePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CUSTOMER_SERVICE_URL_NAME,
  );
  const customerServiceIsEnabledPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_CUSTOMER_SERVICE_ENABLED,
  );

  return useMemo(() => ([
    {
      name: 'customerServiceNumber',
      type: FieldTypes.PhoneNumber,
      testId: 'customerServiceNumber',
      label: 'Help phone number',
      placeholder: '+1 (555) 555-1234',
      defaultValue: props?.customerServiceNumber,
      disabled: props.isDisabledMode,
      ...props.isDisabledMode || customerServiceNumberPermProps?.disabled ? {} : {
        validator: yup.string().test(validatePhoneNumber('Phone number must be valid')),
      },
      ...customerServiceNumberPermProps,
    },
    {
      name: 'customerServiceUrlName',
      type: FieldTypes.Text,
      testId: 'customerServiceUrlName',
      label: 'Help URL name',
      placeholder: 'URL name',
      defaultValue: props?.customerServiceUrlName,
      value: props?.customerServiceUrlName,
      disabled: props.isDisabledMode,
      ...customerServiceUrlNamePermProps,
    },
    {
      name: 'customerServiceUrlLink',
      type: FieldTypes.Text,
      testId: 'customerServiceUrlLink',
      label: 'Help URL',
      placeholder: 'URL',
      defaultValue: props?.customerServiceUrlLink,
      value: props?.customerServiceUrlLink,
      disabled: props.isDisabledMode,
      ...props.isDisabledMode || customerServiceUrlLinkPermProps.disabled ? {} : {
        validator: yup.string().test(validateUrl('URL must be valid')),
      },
      ...customerServiceUrlLinkPermProps,
    },
    {
      name: 'customerServiceEmail',
      type: FieldTypes.Text,
      testId: 'customerServiceEmail',
      label: 'Help email',
      placeholder: 'Help email',
      defaultValue: props?.customerServiceEmail,
      disabled: props.isDisabledMode,
      ...props.isDisabledMode || customerServiceEmailPermProps?.disabled ? {} : {
        validator: yup
          .string()
          .test({
            test: (val) => {
              if (val) return regexp.EMAIL.test(val);
              return true;
            },
            message: EMAIL_INVALID_TEXT,
          }),
      },
      ...customerServiceEmailPermProps,
    },
    {
      name: 'customerServiceIsEnabled',
      type: FieldTypes.Checkbox,
      testId: 'customerServiceIsEnabled',
      label: 'Enable help',
      ...typeof props?.customerServiceIsEnabled === 'boolean' ? {
        value: props?.customerServiceIsEnabled,
        defaultValue: props?.customerServiceIsEnabled,
      } : {},
      disabled: props.isDisabledMode,
      ...customerServiceIsEnabledPermProps,
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ]), [
    props,
    customerServiceEmailPermProps,
    customerServiceNumberPermProps,
    customerServiceUrlLinkPermProps,
    customerServiceUrlNamePermProps,
    customerServiceIsEnabledPermProps,
    triggeredId,
  ]) as Field[];
};
