import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/hooks';
import { CheckDetails } from '@/modules/employer/components/Lockbox/lockbox.types';

enum VendorAddressFields {
  vendorName = 'vendorName',
  forBenefitOf = 'forBenefitOf',
  line1 = 'line1',
  line2 = 'line2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
}

export interface FormValues {
  [VendorAddressFields.vendorName]: string;
  [VendorAddressFields.forBenefitOf]: string;
  [VendorAddressFields.line1]: string;
  [VendorAddressFields.line2]: string;
  [VendorAddressFields.city]: string;
  [VendorAddressFields.state]: string;
  [VendorAddressFields.zipCode]: string;
}

const MAX_FIELD_LENGTH = 40;

interface Props {
  checkDetails?: CheckDetails;
  employeeName?: string;
}

const useVendorAddressFields = ({ checkDetails, employeeName }: Props) => {
  const { states } = useStatesQuery();

  const vendorAddressFields: Field[] = useMemo(() => [
    {
      name: VendorAddressFields.vendorName,
      type: FieldTypes.Text,
      label: 'Name',
      placeholder: 'Enter name',
      showRequireIcon: true,
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: checkDetails?.vendorName || '',
    },
    {
      name: VendorAddressFields.forBenefitOf,
      type: FieldTypes.Text,
      label: 'For benefit of',
      placeholder: 'For benefit of',
      showRequireIcon: true,
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: checkDetails?.benefitOf || employeeName || '',
    },
    {
      name: VendorAddressFields.line1,
      type: FieldTypes.Text,
      label: 'Address line 1',
      placeholder: 'Enter Address line 1',
      showRequireIcon: true,
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: checkDetails?.addressLine1 || '',
    },
    {
      name: VendorAddressFields.line2,
      type: FieldTypes.Text,
      label: 'Address line 2',
      placeholder: 'Enter Address line 2',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
      defaultValue: checkDetails?.addressLine2 || '',
    },
    {
      name: VendorAddressFields.city,
      type: FieldTypes.Text,
      label: 'City',
      showRequireIcon: true,
      placeholder: 'Enter City',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: checkDetails?.city || '',
    },
    {
      name: VendorAddressFields.state,
      type: FieldTypes.AutocompleteDropdown,
      label: 'State',
      showRequireIcon: true,
      placeholder: 'Select state',
      validator: yup.string().required(REQUIRED_TEXT),
      options: states.map((state) => ({ key: state.code, title: state.name })),
      defaultValue: checkDetails?.state || '',
    },
    {
      name: VendorAddressFields.zipCode,
      type: FieldTypes.ZipCode,
      label: 'ZIP code',
      placeholder: 'Enter ZIP code',
      showRequireIcon: true,
      validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
        (val) => (val && (val.length === 10 || val.length === 5))
            || (val === '')).required(REQUIRED_TEXT),
      defaultValue: checkDetails?.zip || '',
    },
  ], [states, checkDetails, employeeName]);

  return {
    vendorAddressFields,
  };
};

export default useVendorAddressFields;
