import { useEffect, useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { OptionKey } from '@/common/types';
import { LockboxStatus } from '@/modules/employer/components/Lockbox/lockbox.types';

const useListPagination = (defaultPerPage?: number) => {
  const {
    page, setPage, setPerPage, perPage, searchString, setSearchString, currentSearch,
  } = usePaginationParams(defaultPerPage);

  const [statuses, setStatuses] = useState<OptionKey[]>([LockboxStatus.NEW]);
  const [types, setTypes] = useState<OptionKey[]>([ALL_OPTION.value]);

  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, statuses, types]);

  return {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    statuses,
    types,
    setSearchString,
    currentSearch,
    setStatuses,
    setTypes,
  };
};

export default useListPagination;
