import React, { useEffect, useMemo } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';

import { CardOfferedPayload,useStore } from './stores/useCardOffered.store';
import { useCardOfferedFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const CardOffered: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const sourceFundId = useStore((state) => state.sourceState?.fundID);

  const showErrors = useStore((state) => state.showErrors);

  const isErrorsShowed = useMemo(
    () => showErrors
     || activateValidate
     || saveValidate,
    [activateValidate, saveValidate, showErrors],
  );
  const fields = useCardOfferedFields(activateValidate, isTemplateMode, isEditMode, isErrorsShowed);
  const handleSetState = useStore((state) => state.setState);

  const { setValues, values } = useDebounceState<Partial<CardOfferedPayload>>();
  useEffect(() => {
    if (values) {
    
      delete values.fundIDFormula;
      handleSetState(values);
    }
  }, [handleSetState, values]);
  if (!fields.length) {
    return null;
  }
  return (
    // The key is needed for force reload, because subfields could have
    // side effects and they could be updated dynamically by BE. (in this case fundId is generated by BE)
    <Box key={`${sourceFundId}`} margin={{ top: 'spacing24' }}>
      <Box background="canvas" round="container1Round">
        <FlexControlledForm
          testId="plan_primary-definition"
          fields={fields}
          showError={isErrorsShowed}
          editMode={isEditMode}
          onChangeValues={setValues}
          onDirty={onDirty}
          // isDisabledMode
          isThinMode
        />

      </Box>
    </Box>
  );
};
export default CardOffered;
