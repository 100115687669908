import { useEffect } from 'react';
import { toString } from 'lodash';

import { ContactInfo } from '@/modules/contact/types/Contact.types';
import useGetOrganizationById, { Organization } from '@/modules/core/hooks/useGetOrganizationById';
import useGetOverridablePropertiesPermissions from '@/modules/core/hooks/useGetOverridablePropertiesPermissions';
import { UserModel } from '@/modules/user/stores/auth.types';
import Token from '@/utils/handlers/Token';

import { useContactInfoQuery, useGetPermissionsQuery } from '../queries';
import { useAuthStore } from '../stores';

export const getTokenData = () => {
  const decodedToken = Token.getDecodedToken();

  if (!decodedToken) return {};

  const role = decodedToken['custom:role'];
  const contactId = decodedToken['custom:contact_id'];
  const organizationId = decodedToken['custom:organization_id'];
  const organizationPath = decodedToken['custom:organization_path'];

  return {
    role,
    contactId,
    organizationId,
    organizationPath,
  };
};

const getUserWithTokenData = (
  organization?: Organization,
  contactInfo?: ContactInfo,
): UserModel | null => {
  if (!organization?.id || !contactInfo?.id) return null;
  const {
    role,
    contactId,
  } = getTokenData();

  if (!role || !contactId) return null;

  return {
    id: contactId,
    firstName: contactInfo?.firstName,
    lastName: contactInfo?.lastName,
    preferredName: contactInfo?.firstName,
    preferredEmail: contactInfo?.email,
    email: { address: contactInfo?.email },
    role,
    organizationId: toString(organization?.id),
    organizationName: organization?.name,
    organizationType: organization?.type,
    organizationPath: organization?.path,
    sessionTimeoutLogout: organization?.sessionTimeoutLogout,
    sessionTimeoutWarn: organization?.sessionTimeoutWarn,
    status: organization.status,
    contactId,
  };
};

export const useAuth = () => {
  const store = useAuthStore();
  const { formattedData: organization } = useGetOrganizationById(
    store?.organizationId,
    Infinity,
  );
  const { formattedData: contactInfo } = useContactInfoQuery(store.contactId);
  const userData = getUserWithTokenData(organization, contactInfo);
  const { permissions } = useGetPermissionsQuery(userData?.id);
  const { overridablePropertiesPermissions } = useGetOverridablePropertiesPermissions(store.auth, userData?.id);

  useEffect(() => {
    if (!store.auth && store.user) {
      store.setUser(null);
      store.setIds();
      store.setOverridablePropertiesPermissions(null);
    }
  }, [store]);

  useEffect(() => {
    if (permissions && userData && store.user?.id !== userData?.id && overridablePropertiesPermissions) {
      store.setUser({ ...userData, permissions });
      store.setOverridablePropertiesPermissions(overridablePropertiesPermissions);
    }
  }, [store, userData, overridablePropertiesPermissions, permissions]);

  useEffect(() => {
    if (!store.inited) {
      store.setAuth(Token.isAuthenticated());
    }
  }, [store]);

  useEffect(() => {
    if (store.auth
      && !store.organizationId
      && !store.contactId) {
      const { organizationId, contactId } = getTokenData();

      if (organizationId && contactId) store.setIds(organizationId, contactId);
    }
  }, [store]);
};

export default useAuth;
