import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@common-fe/common-fe';

import { FeatureFlagKeys, OrganizationTypes, Roles } from '@/common/constants';
import Permissions from '@/common/permissions';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';
import LockboxList from '@/modules/employer/components/Lockbox/LockboxList/LockboxList';
import { useDetectUrlHash } from '@/utils/hooks/useDetectUrlHash';
import { useReversedFeatureFlag } from '@/utils/hooks/useFeatureFlag';

import { FinOpsInvoicing } from './components/FinOpsInvoicing';
import { FinOpsHeader } from './components/ReconciliationReportsHeader';
import ChecksList from './ChecksList';
import { FinOpsTabs } from './FinOps.types';
import ReconciliationReports from './ReconciliationReports';

export const FinOps: React.FC = () => {
  const { isElevateOrganization, observingOrganization } = useCurrentOrganization();
  const isInvoicing = useHasAccess([{ permission: Permissions.VIEW_INVOICES }]);
  const isChecksAvailable = useHasAccess([{ permission: Permissions.VIEW_FINOPS_CHECKS }]);
  const isLockboxAvailable = useHasAccess([{ permission: Permissions.VIEW_LOCKBOX }]);
  const isReconShowed = useHasAccess([{ role: Roles.finOps }, { role: Roles.superUser }]);
  const isLockboxFeatureFlag = useReversedFeatureFlag(FeatureFlagKeys.LOCKBOX, {
    dev: true,
    uat: true,
  });

  const isSystemLevel = useMemo(
    () => observingOrganization?.type === OrganizationTypes.system,
    [observingOrganization]
  );

  const currentPages = useMemo(() => {
    return [
      ...(isReconShowed && isElevateOrganization ? [FinOpsTabs.RECON] : []),
      ...(isInvoicing ? [FinOpsTabs.INVOICING] : []),
      ...(isChecksAvailable ? [FinOpsTabs.CHECKS] : []),
      ...(isLockboxAvailable && isLockboxFeatureFlag && isSystemLevel ? [FinOpsTabs.LOCKBOX] : []),
    ];
  }, [
    isChecksAvailable,
    isElevateOrganization,
    isInvoicing,
    isLockboxAvailable,
    isLockboxFeatureFlag,
    isReconShowed,
    isSystemLevel,
  ]);

  const [currentTab, setCurrentTab] = useState<FinOpsTabs>(currentPages[0] || FinOpsTabs.RECON);

  useEffect(() => {
    if (currentPages.length) {
      setCurrentTab(currentPages[0]);
    }
  }, [currentPages]);

  useDetectUrlHash(() => setCurrentTab(FinOpsTabs.LOCKBOX));

  return (
    <Box data-testid="ReconciliationReports" pad={{ bottom: 'spacing24' }}>
      <FinOpsHeader currentTab={currentTab} tabs={currentPages} onSetCurrentTab={setCurrentTab} />
      {currentTab === FinOpsTabs.RECON && <ReconciliationReports />}
      {currentTab === FinOpsTabs.INVOICING && <FinOpsInvoicing />}
      {currentTab === FinOpsTabs.CHECKS && <ChecksList />}
      {currentTab === FinOpsTabs.LOCKBOX && <LockboxList />}
    </Box>
  );
};
