import React, {
  useCallback,
  useEffect, useMemo,
  useRef,
  useState,
} from 'react';
import { Document as PdfDocument, Page, pdfjs } from 'react-pdf';
import {
  Box, Image, Preloader, Text, useScrollByDrag,
} from '@common-fe/common-fe';
import _ from 'lodash';

import Download from '@/assets/pdfPreview/Download.svg';
import RotateLeft from '@/assets/pdfPreview/RotateLeft.svg';
import RotateRight from '@/assets/pdfPreview/RotateRight.svg';
import { Document, DocumentTypeEnum } from '@/common/types';
import { documentFiles, ROTATE_STEP_DEG, textFiles } from '@/components/elements/DocumentViewer/DocumentViewer.constants';
import {
  ActionButton,
  LeftActionsWrap,
  Minus,
  Plus,
  RightActionsWrap,
  SectionWrap,
  StyledFooter,
  StyledPreviewBox,
} from '@/components/elements/DocumentViewer/DocumentViewer.styles';
import colors from '@/styles/colors';

import { CsvViewer, ImageViewer, TiffViewer, TxtViewer } from './components';
import IconsPreview from './IconsPreview';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const MIN_ROTATE_VALUE = 0;
const MAX_ROTATE_VALUE = 270;
const INIT_ZOOM = 1;
const ZOOM_PADDING = 24;
const MINIMUM_ZOOM_SIZE = 0.2;
const MAXIMUM_ZOOM_SIZE = 2.0;

export interface Props {
  receipts: Document[];
  isLoading?: boolean;
}

const DocumentViewer: React.FC<Props> = ({ receipts, isLoading }) => {
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(MIN_ROTATE_VALUE);
  const [activePage, setActivePage] = useState(0);
  const scrollWrapRef = useRef<HTMLDivElement>(null);
  const {
    onMouseDown, onMouseUp, onMouseMove, onMouseLeave, isScrolling,
  } = useScrollByDrag(scrollWrapRef);
  const [, setNumPages] = useState(1);
  const activeReceiptId = useMemo(() => receipts[activePage]?.id, [receipts, activePage]);
  const activeReceipt = useMemo(() => receipts[activePage], [receipts, activePage]);
  const pdfReceipts = useMemo(
    () => receipts.filter((receipt) => receipt.type === DocumentTypeEnum.PDF),
    [receipts],
  );
  const activePdfReceipt = useMemo(() => pdfReceipts
    .find((receipt) => receipt.id === activeReceiptId), [pdfReceipts, activeReceiptId]);
  const imageReceipts = useMemo(
    () => receipts.filter((receipt) => !documentFiles.has(receipt.type) && !textFiles.has(receipt.type)),
    [receipts],
  );
  const activeImageReceipt = useMemo(() => imageReceipts
    .find((receipt) => receipt.id === activeReceiptId), [imageReceipts, activeReceiptId]);

  const tifReceipts = useMemo(
    () => receipts.filter((receipt) => receipt.type === DocumentTypeEnum.TIF),
    [receipts],
  );
  const activeTifReceipt = useMemo(() => tifReceipts
    .find((receipt) => receipt.id === activeReceiptId),
  [tifReceipts, activeReceiptId]);

  const textReceipts = useMemo(
    () => receipts.filter((receipt) => receipt.type === DocumentTypeEnum.TXT),
    [receipts],
  );
  const activeTextReceipt = useMemo(() => textReceipts
    .find((receipt) => receipt.id === activeReceiptId),
  [textReceipts, activeReceiptId]);

  const csvReceipts = useMemo(
    () => receipts.filter((receipt) => receipt.type === DocumentTypeEnum.CSV),
    [receipts],
  );
  const activeCsvReceipt = useMemo(() => csvReceipts
    .find((receipt) => receipt.id === activeReceiptId),
  [csvReceipts, activeReceiptId]);

  const [initialWidth, setInitialWidth] = useState(0);
  const pdfWrapper = useRef<HTMLDivElement>(null);
  const [pdfDocLoadedMap, setPdfDocLoadedMap] = useState(pdfReceipts.reduce((curr, doc) => ({
    ...curr,
    [doc.id]: false,
  }), {}));
  const allPdfReceiptsLoaded = useMemo(
    () => {
      const loadedValueArr = Object.values(pdfDocLoadedMap);
      return loadedValueArr.length && loadedValueArr.every((loaded) => loaded);
    },
    [pdfDocLoadedMap],
  );

  const isRotateAvailable = useMemo(() => !textFiles.has(activeReceipt?.type), [activeReceipt]);

  const setPdfSize = useCallback(() => {
    if (pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  }, [pdfWrapper]);

  const windowResizeHandler = useCallback(
    () => _.throttle(setPdfSize, 500),
    [setPdfSize],
  );
  useEffect(() => {
    setZoom(INIT_ZOOM);
  }, [activePage]);
  useEffect(() => {
    if (pdfWrapper.current && allPdfReceiptsLoaded) {
      window.addEventListener('resize', windowResizeHandler);
      setPdfSize();
    }
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfWrapper, allPdfReceiptsLoaded]);

  useEffect(() => {
    if (activePage > 0 && isLoading) {
      setActivePage(0);
    }
  }, [isLoading, activePage]);

  const [numPdfPages, setNumPdfPages] = useState(0);
  const onDocumentLoadSuccess = useCallback((data: { numPages: number }, id?: string) => {
    setNumPdfPages(data.numPages);
    setNumPages(data.numPages);
    setPdfDocLoadedMap((prevState) => ({
      ...prevState,
      ...id ? { [id]: true } : {},
    }));
  }, []);
  const renderPdfPages = useCallback(() => {
    if (!numPdfPages) return null;

    const pages: React.ReactNode[] = [];

    for (let pageNumber = 1; pageNumber <= numPdfPages; pageNumber++) {
      pages.push(
        <Page
          key={pageNumber}
          renderTextLayer={false}
          width={initialWidth - ZOOM_PADDING}
          rotate={rotate}
          scale={zoom}
          pageNumber={pageNumber}
          loading=""
        />,
      );
    }

    return pages;
  }, [numPdfPages, initialWidth, rotate, zoom]);

  return (
    <SectionWrap data-testId="document-viewer_wrapper" direction="column" background="module">
      <SectionWrap>
        <SectionWrap
          overflow="auto"
          direction="column"
          pad="spacing12"
          ref={scrollWrapRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          style={{ cursor: isScrolling ? 'grabbing' : 'grab' }}
        >
          <Box
            direction="column"
            height={{ min: 'auto' }}
            flex="grow"
            {...activePdfReceipt && zoom > INIT_ZOOM ? {
              width: { min: `${(initialWidth - ZOOM_PADDING) * zoom}px` },
            } : {}}
          >
            <Box direction="column" round="container1Round" flex="grow">
              {
                isLoading
                  ? (
                    <Box
                      background="canvas"
                      round="container1Round"
                      margin={{ right: 'spacing12' }}
                      height="440px"
                      align="center"
                      justify="center"
                    >
                      <Preloader testId="document-viewer-preloader" />
                    </Box>
                  )
                  : (
                    <>
                      <Box data-testId="pdf-preview_document-viewer" ref={pdfWrapper}>
                        {Boolean(activePdfReceipt?.id) && (
                          <StyledPreviewBox
                            key={activePdfReceipt?.id}
                            align="center"
                            margin={{ bottom: 'spacing6' }}
                          >
                            <PdfDocument
                              file={activePdfReceipt?.blob}
                              onLoadSuccess={(data) => onDocumentLoadSuccess(
                                data,
                                activePdfReceipt?.id,
                              )}
                              loading=""
                            >
                              {renderPdfPages()}
                            </PdfDocument>
                          </StyledPreviewBox>
                        )}
                      </Box>
                      {Boolean(activeImageReceipt?.id) && (
                        <Box
                          data-testId={`preview-image_${activeImageReceipt?.id}`}
                          align="center"
                          style={{ display: 'block' }}
                        >
                          <ImageViewer
                            zoom={zoom}
                            key={activeImageReceipt?.id}
                            rotate={rotate}
                            receipt={activeImageReceipt as Document}
                          />
                        </Box>
                      )}
                      {Boolean(activeTifReceipt?.id) && (
                        <Box
                          data-testId={`preview-tiff_${activeTifReceipt?.id}`}
                        >
                          <TiffViewer
                            receipt={activeTifReceipt}
                            zoom={zoom}
                            rotate={rotate}
                          />
                        </Box>
                      )}
                      {Boolean(activeTextReceipt?.id) && (
                        <Box data-testId={`preview-txt_${activeTextReceipt?.id}`}>
                          <TxtViewer
                            receipt={activeTextReceipt}
                            zoom={zoom}
                          />
                        </Box>
                      )}
                      {Boolean(activeCsvReceipt?.id) && (
                        <Box data-testId={`preview-csv_${activeCsvReceipt?.id}`}>
                          <CsvViewer
                            receipt={activeCsvReceipt}
                            zoom={zoom}
                          />
                        </Box>
                      )}
                    </>
                  )
              }
            </Box>
          </Box>
        </SectionWrap>
        <StyledFooter pad="spacing8" direction="row" align="center" justify="center" background={colors.background1}>
          {receipts.length > 0 && (
            <LeftActionsWrap
              isTop={receipts.length > 1}
              pad={{ horizontal: '7px', vertical: '2px' }}
              round="smallRound"
            >
              <Text color={receipts.length > 1 ? 'textOnColor' : 'textSecondary'} size="xsmall">
                {activePage + 1} / {receipts.length}
              </Text>
            </LeftActionsWrap>
          )}
          {receipts.length > 1 && (
            <IconsPreview
              activeDocumentId={activeReceiptId}
              documents={receipts}
              activateDocument={(index: number) => {
                setActivePage(index);
                setRotate(MIN_ROTATE_VALUE);
              }}
            />
          )}
          <Box direction="row">
            <ActionButton
              data-testId="zoom-out-button_document-viewer" 
              onClick={() => setZoom((currentZoom) => {
                const updatedZoom = _.round(currentZoom - 0.1, 2);
                return updatedZoom <= MINIMUM_ZOOM_SIZE ? MINIMUM_ZOOM_SIZE : updatedZoom;
              })}
              round="checkboxRound"
              width="40px"
              height="40px"
              align="center"
              justify="center"
              margin={{ right: 'spacing4' }}
            >
              <Minus />
            </ActionButton>
            <ActionButton
              data-testId="zoom-button_document-viewer" 
              onClick={() => setZoom((currentZoom) => {
                const updatedZoom = _.round(currentZoom + 0.1, 2);
                return updatedZoom >= MAXIMUM_ZOOM_SIZE ? MAXIMUM_ZOOM_SIZE : updatedZoom;
              })}
              round="checkboxRound"
              width="40px"
              height="40px"
              align="center"
              justify="center"
            >
              <Plus />
            </ActionButton>
          </Box>
          <RightActionsWrap direction="row">
            {
              isRotateAvailable && (
                <>
                  <ActionButton
                    data-testId="rotate-left-button_document-viewer" 
                    onClick={() => setRotate(rotate === MIN_ROTATE_VALUE ? MAX_ROTATE_VALUE : rotate - ROTATE_STEP_DEG)}
                    round="checkboxRound"
                    width="40px"
                    height="40px"
                    align="center"
                    justify="center"
                    margin={{ right: 'spacing4' }}
                  >
                    <Image height="24px" src={RotateLeft} alt="icon" opacity={isLoading && 'weak'} />
                  </ActionButton>
                  <ActionButton
                    data-testId="rotate-right-button_document-viewer" 
                    onClick={() => setRotate(rotate === MAX_ROTATE_VALUE ? MIN_ROTATE_VALUE : rotate + ROTATE_STEP_DEG)}
                    round="checkboxRound"
                    width="40px"
                    height="40px"
                    align="center"
                    justify="center"
                    margin={{ right: 'spacing8' }}
                  >
                    <Image height="24px" src={RotateRight} alt="icon" opacity={isLoading && 'weak'} />
                  </ActionButton>
                </>
              )
            }
            <Box height="40px" width="1px" margin={{ right: 'spacing8' }} />
            <ActionButton
              data-testId="download-button_document-viewer" 
              onClick={() => window.open(receipts[activePage].link)}
              round="checkboxRound"
              width="40px"
              height="40px"
              align="center"
              justify="center"
            >
              <Image height="24px" src={Download} alt="icon" opacity={isLoading && 'weak'} />
            </ActionButton>
          </RightActionsWrap>
        </StyledFooter>
      </SectionWrap>
    </SectionWrap>
  );
};

export default DocumentViewer;
