import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface BankMethod {
  id: string,
  name: string,
  description: string,
}

const getBankMethods = () => api.get<BankMethod[]>(
  PATHS.BANK_METHODS,
);
const BANK_METHODS_QUERY_NAME = 'getBankMethods';
const useBankMethodsQuery = () => {
  const { data, isLoading } = useQuery(
    BANK_METHODS_QUERY_NAME, getBankMethods,
  );
  const formatedData = useMemo(
    () => data?.data.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      description: item.description,
    })), [data],
  );

  return {
    methods: formatedData || [],
    isLoading,
  };
};

export default useBankMethodsQuery;
