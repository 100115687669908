import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const QUERY_KEY = 'getAvailableEmailNotificationTemplates';

interface EmailTemplateDto {
  category_type: string;
  id: number;
  notification_description: string;
  notification_type: string;
  template_description: string;
  user_type: string;
}

export const useGetAvailableEmailNotificationTemplatesQuery = (
  notificationType?: string,
  userRole?: string,
) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEY, userRole, notificationType],
    userRole && notificationType
      ? () => api.get<EmailTemplateDto[]>(PATHS.GET_AVAILABLE_EXTERNAL_EMAIL_TEMPLATES, {
        params: {
          ...userRole ? { user_type: userRole } : {},
          ...notificationType ? { notification_type: notificationType } : {},
        },
      })
      : () => null,
    {
      cacheTime: 0,
    },
  );

  const formattedOptions = useMemo(() => {
    return data?.data.map((item) => ({
      key: `${item.id}`,
      value: `${item.id}`,
      title: item.template_description,
    }));
  }, [data]);

  return {
    data: data?.data,
    formattedOptions,
    isLoading,
  };
};
