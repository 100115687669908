import React, { useEffect,useMemo,useState } from 'react';
import { Box, Field, FlexControlledForm, useDebounce } from '@common-fe/common-fe';

import { NO_KEY, YES_KEY } from '@/common/constants';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { OrphanConfigurationPayload } from '../../SetupEmployer.types';
import { useSaveTypeStore } from '../../stores';

import useOrphanConfigurationFields from './hooks/useOrphanConfigurationFields';
import useOrphanConfigurationStore from './stores/useOrphanConfiguration.store';



const OrphanConfiguration = () => {
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  const [value, setValue] = useState<Partial<OrphanConfigurationPayload>>();
  const debouncedValue = useDebounce(value);
  const { formattedData, isLoading } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const handleReset = useOrphanConfigurationStore((state)  => state.handleReset);
  const fields = useOrphanConfigurationFields(viewMode);
  const  handleSetState = useOrphanConfigurationStore(state => state.setState);
  const sourceOrphanEmployer = useOrphanConfigurationStore(state=> state.sourceState?.orphanEmployer);
  const key= useMemo(() => {
    const { orphanEmployer, isEmployerOrphan } = formattedData?.overridableProperties || {};
    return `${isEmployerOrphan} ${ orphanEmployer} ${sourceOrphanEmployer}`;
    
  }, [formattedData?.overridableProperties, sourceOrphanEmployer]);
  
  useEffect(() => {
    if(debouncedValue) {
      if(debouncedValue.isEmployerOrphan === YES_KEY) {
        debouncedValue.isEmployerOrphan = true;
      } 
      if(debouncedValue.isEmployerOrphan === NO_KEY) {
        debouncedValue.isEmployerOrphan = false;
      }
      handleSetState(debouncedValue);
    }
  }, [debouncedValue, handleSetState]);
  useEffect(() => {
    return () => {
      handleReset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) return <FormLoader testId="AIAdjudication" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      id="orphan_configuration"
      data-testId="orphan-configuration"
      key={key}
    >
      <FlexControlledForm
        shouldControlValues
        formTitle="Orphan Configuration"
        fields={fields as Field[]}
        onChangeValues={setValue}
        editMode={!viewMode}
        showError={pendingStatus ||activeStatus}
        isThinMode
      />
    </Box>
  );
};

export default OrphanConfiguration;
