import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { QUERY_KEY } from './useClaimsList.query';

interface Props {
  processorId?: string;
  selectedClaims?: number[];
}

const useAssignProcessorQuery = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (props: Props) => api.post(
      PATHS.ASSIGN_CLAIMS_TO_PROCESSOR,
      {
        contact_id: props?.processorId,
        claim_ids: props?.selectedClaims,
      },
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
        onSuccess();
      },
    },
  );

  return mutateAsync;
};

export default useAssignProcessorQuery;
