import { useCallback,useEffect, useState } from 'react';
import { useDebounce } from '@common-fe/common-fe';

import { CreateContactFormModel, EmailCommunication } from '@/modules/employer/types/Contact.types';

export default () => {
  const [showErrors, setShowErrors] = useState(false);
  const [tempValues, setTempValues] = useState<CreateContactFormModel | null>(null);
  const [values, setValues] = useState<CreateContactFormModel | null>(null);
  const [
    emailCommunicationsValues, setEmailCommunicationsValues,
  ] = useState<EmailCommunication | undefined>();
  const debouncedValue = useDebounce(tempValues);

  useEffect(() => {
    setValues(debouncedValue as CreateContactFormModel);
  }, [debouncedValue]);

  const handleChangeAllEmailCommunications = useCallback((value: boolean) => {
    setEmailCommunicationsValues({
      files: value,
      finance: value,
      planManagement: value,
    });
  }, []);

  return {
    values,
    setValues: setTempValues,
    emailCommunicationsValues,
    setEmailCommunicationsValues,
    handleChangeAllEmailCommunications,
    setShowErrors,
    showErrors,
  };
};
