import React from 'react';
import {
  Box, Button, Text, WarningIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

interface Props {
  visible: boolean;
  close: () => void;
}
export const testId = 'DuplicationErrorModal';
export const DuplicationErrorModal: React.FC<Props> = ({
  visible,
  close,
}) => (
  <ModalWrapper
    contentStyle={{ maxWidth: '672px' }}
    visible={visible}
    onSetVisible={close}
    testId={`${testId}_modal`}
  >
    <Box direction="column" align="center">
      <WarningIcon data-testid={`${testId}_modal_round_close`} width="48px" height="48px" />
      <Box pad={{ top: 'spacing16', bottom: 'spacing24' }}>
        <Text
          weight="bold"
          data-testid={`${testId}_modal_header`}
          textAlign="center"
          size="24px"
        >
          The account information provided already matches an account on file. You are all set!
        </Text>
      </Box>
      <Box direction="row" align="center" justify="center">
        <Button
          data-testid={`${testId}_modal_submit_button`}
          primary
          label="Ok"
          style={{ width: '200px' }}
          onClick={close}
        />
      </Box>
    </Box>
  </ModalWrapper>
);
