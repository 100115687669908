import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Inscription, Modal, Text } from '@common-fe/common-fe';

import { ThemeWrapper } from '@/modules/core/components';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  isHoveringSensitive?: boolean;
  title?: string;
  children: React.ReactNode;
  testId?: string;
  maxWidth?: string;
  contentStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  titleBoxStyle?: React.CSSProperties;
  notHideClickOutside?: boolean;
}
const node = document.createElement('div');
document.body.appendChild(node);
const ModalWrapper: React.FC<Props> = ({
  title,
  children,
  onSetVisible,
  isHoveringSensitive,
  testId,
  visible,
  maxWidth,
  contentStyle,
  wrapperStyle,
  notHideClickOutside,
  titleBoxStyle,
}) => ReactDOM.createPortal(
  <ThemeWrapper>
    <Modal
      isHoveringSensitive={isHoveringSensitive}
      testId={testId}
      visible={visible}
      onSetVisible={onSetVisible}
      maxWidth={maxWidth}
      contentStyle={contentStyle}
      wrapperStyle={wrapperStyle}
      notHideClickOutside={notHideClickOutside}
    >
      <Box direction="column">
        {title && (
          <Box
            direction="row"
            align="center"
            justify="center"
            pad={{ bottom: 'spacing24' }}
            style={titleBoxStyle}
          >
            <Inscription
              size="2xl"
              color="textTitle"
              weight="bold"
              lineHeight='32px'
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {title}
            </Inscription>
          </Box>
        )}
        <Box>
          {children}
        </Box>
      </Box>
    </Modal>
  </ThemeWrapper>,
  node,
);

export default ModalWrapper;
