import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@common-fe/common-fe';

import { ACCEPTED_LOGO_FILE_TYPES } from '@/common/constants';
import { FileUploadButton } from '@/modules/core/components/FileUploadButton';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';

import { RowDescription, RowTitle } from '../../Branding.styles';
import { LogoFile } from '../../branding.types';
import { DragNDropContainer } from '../../DragNDropContainer';

interface Props {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  logo: React.ReactNode;
  background: string;
  disabled?: boolean;
  onUploadLogo: (file: LogoFile) => void;
  onSetLogo: (file: LogoFile) => void;
  onFormatError: (value: boolean) => void;
  onSizeError: (value: boolean) => void;
  sizeError?: boolean;
  formatError?: boolean;
  securityError?: boolean;
  missingLogo?: boolean;
}

const BrandingLogo: React.FC<Props> = ({
  id,
  title,
  description,
  logo,
  disabled,
  background,
  onUploadLogo,
  onSetLogo,
  onFormatError,
  onSizeError,
  sizeError,
  formatError,
  securityError,
  missingLogo,
}) => {
  const { isDragActive } = useDropzone();
  const { viewMode } = useSetupEmployerMode();

  return (
    <Box data-testId={id} direction="column" align="start" width="152px">
      <RowTitle>{title}</RowTitle>
      {description ? (
        <RowDescription>{description}</RowDescription>
      ) : null}
      
      <Box margin={{ top: 'spacing12' }}>
        <DragNDropContainer
          onChange={onSetLogo}
          isDragStart={isDragActive}
          onFormatError={onFormatError}
          onSizeError={onSizeError}
          disabled={disabled}
        >
          <Box
            background={background}
            pad="spacing16"
            round="fieldRound"
            direction="row"
            align="center"
          >
            {logo}
          </Box>
        </DragNDropContainer>
      </Box>

      <Box margin={{ top: 'spacing12' }}>
        <FileUploadButton
          title="Upload"
          id={id}
          testId={id}
          onChange={onUploadLogo}
          disabled={viewMode || disabled}
          format={ACCEPTED_LOGO_FILE_TYPES}
        />
      </Box>

      {sizeError && (
        <RowDescription margin={{ top: 'spacing12' }} isError>
          Your image is larger than 4 MB. Please choose a smaller image.
        </RowDescription>
      )}
      {formatError && (
        <RowDescription margin={{ top: 'spacing12' }} isError>
          We do not support this type of file.
          Please select SVG, JPG, PNG, or GIF file.
        </RowDescription>
      )}
      {securityError && (
        <RowDescription margin={{ top: 'spacing12' }} isError>
          The uploaded file cannot be consumed due to security concerns.
          Please try to recreate the file
          or use a different file type (JPEG, PNG, JPG, BMP, or GIF).
        </RowDescription>
      )}
      {missingLogo && (
        <RowDescription margin={{ top: 'spacing12' }}>
          No logo? No problem! Elevate system will generate an image.
        </RowDescription>
      )}
    </Box>
  );
};

export default BrandingLogo;
