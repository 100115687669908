import React from 'react';

import GeneralInfo from './GeneralInfo';
import { useGeneralInfoFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  isShowErrors?: boolean;
}
const GeneralInfoWrapper: React.FC<Props> = ({ isEditMode, isShowErrors }) => {
  const fields = useGeneralInfoFields(isEditMode, isShowErrors);

  return (
    <GeneralInfo
      fields={fields}
      viewMode={!isEditMode}
      isShowErrors={isShowErrors}

    />
  );
};

export default GeneralInfoWrapper;
