import { useMemo } from 'react';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';


const useSourceId = (
  chosenEmployerId?: string,
) => {
  const { observingOrganization } = useCurrentOrganization();

  const isEmployer = useMemo(() => observingOrganization.type === OrganizationTypes.company,
    [observingOrganization.type]);
  const currentEmployerId = useMemo(
    () => {
      if (isEmployer) {
        return observingOrganization.id;
      }
      if (!isEmployer && chosenEmployerId) {
        return chosenEmployerId;
      }
      return undefined;
    },
    [chosenEmployerId, isEmployer, observingOrganization.id],
  );

  const {
    formattedData: employerData,
  } = useGetOrganizationById(currentEmployerId);

  return employerData?.overridableProperties?.idSourceId;
};

export default useSourceId;
