import { useMemo } from 'react';

import { useHealthPlanNameFields } from '../HealthPlanName/hooks';
import { usePlanYearFields } from '../PlanYear/hooks';

export default (isActiveMode?: boolean, isTemplateMode?: boolean) => {
  const { stateFields: healthPlanNameFields } = useHealthPlanNameFields(
    isActiveMode,
    isTemplateMode,
  );

  const { stateFields: additionalFields } = useHealthPlanNameFields(
    isActiveMode,
    isTemplateMode,
    true,
    true,
  );

  const planYearFields = usePlanYearFields(
    isActiveMode,
    isTemplateMode,
  );

  const fields = useMemo(() => ([
    ...healthPlanNameFields,
    ...planYearFields,
    ...additionalFields,
  ]), [healthPlanNameFields, planYearFields, additionalFields]);
  return fields;
};
