import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledPopupContent = styled(Box)`
  min-width: 264px;
  ${({ theme }) => theme.shadows.active};
`;

export const WrapperWithBottomBorder = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
`;

export const ListWrapper = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .backlighting {
    border: 2px solid #176AF6;
    box-shadow: 0px 6px 16px rgba(23, 106, 246, 0.5);
  }
`;
