import React from 'react';
import {
  Box,
  ExpoBanner,
  ExpoWrapper,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import EmptyImage from '@/assets/placeholders/empty.png';
import TownIcon from '@/assets/placeholders/town.svg';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { HomeReports } from '@/modules/employer/components/Reports/HomeReports';
import theme from '@/styles/theme';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';
// import { CardsList } from '@/modules/employer/components/CardsList';
// import { ActiveProgramEnrollments } from '@/modules/employer/components/ActiveProgramEnrollments';
// import { ClaimsTable, NumberOfClaimsTable } from '@/modules/employer/components/ClaimsTables';

const Wrapper = styled(Box)`
  display: block;
`;

const WELCOME_MESSAGE = 'Welcome to Your Dashboard!';

const Welcome = () => (
  <ExpoBanner
    title={WELCOME_MESSAGE}
    style={{ paddingLeft: '48px' }}
    // description="Here, you will find key performance information and quick access to important program administration activities."
    images={[
      {
        id: _.uniqueId(),
        src: whiteLabelingConfig.images?.bannerCloud || EmptyImage,
        alt: 'cloud',
      },
      {
        id: _.uniqueId(),
        src: whiteLabelingConfig.images?.banner || TownIcon,
        alt: 'happy town',
      },
    ]}
  />
);

export const Home: React.FC = () => {
  useBreadcrumbs([]);
  return (
    <Wrapper pad={{ top: '0' }} width="100%">
      <Topbar showToolbarButtons />
      <Box pad={{ horizontal: '40px', top: '40px' }} align="center">
        <Box width={theme.defaultContentWidth} gap="spacing24">
          <ExpoWrapper title={WELCOME_MESSAGE} hideIcon>
            <Welcome />
          </ExpoWrapper>
          {/* <CardsList />
          <ActiveProgramEnrollments />
          <ClaimsTable />
          <NumberOfClaimsTable /> */}
          <HomeReports />
        </Box>
      </Box>
    </Wrapper>
  );
};
