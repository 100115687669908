import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton, Box, formatSnakeCaseToGeneral,Inscription, Modal, Preloader, SearchInput, SiblingBoxesWithSpacing } from '@common-fe/common-fe';

import InfoTile from '@/components/elements/InfoTile/InfoTile';
import { useSnackbar } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user/stores';

import { ListItemsWrapper } from './ClaimsProcessors.styles';
import useAssignProcessorsToTeamQuery from './useAssignProcessorsToTeam.query';
import useAssignTeamsToProcessorQuery from './useAssignTeamsToProcessor.query';
import useClaimsProcessorQuery from './useClaimsProcessor.query';
import useClaimsProcessorsListQuery from './useClaimsProcessorsList.query';
import useTeamQuery from './useTeam.query';
import { ALL_ACCOUNT_TYPES_LENGTH } from './useTeamsList';
import useTeamsListQuery from './useTeamsList.query';

const MAX_PER_PAGE = 100;
const FIRST_PAGE = 0;
const PAGINATION = {
  page: FIRST_PAGE,
  perPage: MAX_PER_PAGE,
};

interface Props {
  isTeamMode?: boolean;
  editableId?: string;
  clearEditableId: (val?: string) => void;
}

const ManagePopUp: React.FC<Props> = ({ isTeamMode, editableId, clearEditableId }) => {
  const { contactId } = useAuthStore();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const assignTeamsToProcessorQuery: any = useAssignTeamsToProcessorQuery(() => setIsSubmitted(true));
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const assignProcessorsToTeamQuery: any = useAssignProcessorsToTeamQuery(() => setIsSubmitted(true));
  const [initiallyAssignedIds, setInitiallyAssignedIds] = useState<string[]>([]);
  const [assignTileIds, setAssignTileIds] = useState<string[]>([]);
  const [searchTextInittialySelectedItems, setSearchTextInittialySelectedItems] = useState('');
  const [searchTextInittialyNotSelectedItems, setSearchTextInittialyNotSelectedItems] = useState('');
  const searchString = useMemo(() => {
    const value = searchTextInittialyNotSelectedItems?.trim();
    if (value?.length >= 3) return value;
    return '';
  }, [searchTextInittialyNotSelectedItems]);
  const { team, isLoading: isTeamLoading } = useTeamQuery(editableId, isTeamMode);
  const { claimsProcessor, isLoading: isClaimsProcessorLoading } = useClaimsProcessorQuery(editableId, !isTeamMode);
  const { claimsProcessors: processors, isLoading: areClaimsProcessorsLoading} = useClaimsProcessorsListQuery({
    enabled: Boolean(isTeamMode && editableId),
    ...PAGINATION,
    searchString: searchString,
    ...isTeamMode ? { claimsTeamId: editableId } : {},
  });
  const claimsProcessors = useMemo(() => processors?.filter((processor) => !team?.teamMembers?.some((member) => member?.id === processor?.id)), [team, processors]);
  const { teams: claimsTeams, isLoading: areTeamsLoading } = useTeamsListQuery({
    enabled: Boolean(!isTeamMode && editableId),
    ...PAGINATION,
    ...searchString ? { searchString } : {},
    ...isTeamMode ? {} : { contactId: editableId },
  });
  const teams = useMemo(() => claimsTeams?.filter((team) => !claimsProcessor?.teams?.some((item) => item?.id === team?.id)), [claimsProcessor, claimsTeams]);
  const title = useMemo(() => {
    if (isTeamMode) return team?.name;

    return claimsProcessor?.fullName || '';
  }, [isTeamMode, team, claimsProcessor]);
  const isLoading = useMemo(() => areClaimsProcessorsLoading || areTeamsLoading || isTeamLoading || isClaimsProcessorLoading,
    [areClaimsProcessorsLoading, areTeamsLoading, isTeamLoading, isClaimsProcessorLoading]);
  const initiallySelectedTiles = useMemo(() => {
    if (isTeamMode) {
      return team?.teamMembers?.map((item) => ({
        title: item?.fullName,
        descriptions: item?.role ? [formatSnakeCaseToGeneral(item.role)] : [],
        id: item?.id,
        icon: item?.icon,
      }));
    }
    return claimsProcessor?.teams?.map((item) => ({
      title: item?.name,
      descriptions: item?.allowedAccountTypes?.length ? item.allowedAccountTypes : [],
      id: item?.id,
      icon: '',
    }));
  }, [isTeamMode, team, claimsProcessor]);
  const initiallyNotSelectedTiles = useMemo(() => {
    if (isTeamMode) {
      return claimsProcessors?.map((item) => ({
        title: item?.fullName,
        descriptions: item?.role ? [item.role] : [],
        id: item?.id,
        icon: item?.icon,
      }));
    }
    return teams?.map((item) => ({
      title: item?.name,
      descriptions: item?.allowedAccountTypes?.length ? item.allowedAccountTypes : [],
      id: item?.id,
      icon: '',
    }));
  }, [isTeamMode, teams, claimsProcessors]);

  useEffect(() => setInitiallyAssignedIds((claimsProcessor?.teams || team?.teamMembers || []).map((item) => item?.id || '')), [claimsProcessor, team]);
  useEffect(() => setAssignTileIds([]), [claimsProcessors, teams]);

  const unassignTileIds = useMemo(() => {
    const before = (claimsProcessor?.teams || team?.teamMembers || []).map((item) => item?.id || '');

    return before.filter((item) => !initiallyAssignedIds.some((id) => item === id));
  }, [claimsProcessor, team, initiallyAssignedIds]);

  const handleSubmit = useCallback(async () => {
    if (claimsProcessor) {
      setIsSubmitting(true);
      assignTeamsToProcessorQuery({
        memberId: claimsProcessor?.id,
        teamsIds: [
          ...assignTileIds,
          ...initiallySelectedTiles?.map((item) => item.id) || [],
        ].filter((item) => !unassignTileIds.some((id) => id === item)),
      });
    }
    if (team) {
      setIsSubmitting(true);
      assignProcessorsToTeamQuery({
        teamId: team?.id,
        memebersIds: [
          ...assignTileIds,
          ...initiallySelectedTiles?.map((item) => item.id) || [],
        ].filter((item) => !unassignTileIds.some((id) => id === item)),
      });
    }
  }, [
    team,
    claimsProcessor,
    assignTeamsToProcessorQuery,
    setIsSubmitting,
    assignProcessorsToTeamQuery,
    unassignTileIds,
    assignTileIds,
    initiallySelectedTiles,
  ]);

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitting(false);
      setIsSubmitted(false);
      clearEditableId();
      handleAddPermanentSnackbar({
        text: `${team?.name || claimsProcessor?.fullName} assignments updated`,
        closeIcon: true,
      });
    }
  }, [setIsSubmitted, setIsSubmitting, team, claimsProcessor, isSubmitted, clearEditableId, handleAddPermanentSnackbar]);

  return (
    <Modal
      visible={Boolean(editableId)}
      testId={`${isTeamMode ? 'team' : 'member'}_id_${editableId}_asign_popup`}
      onSetVisible={() => clearEditableId()}
      maxWidth="800px"
    >
      <Box direction="column">
        <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
          <Inscription size="2xl" color="textTitle" weight="bold">Manage {title}</Inscription>
        </Box>
        <Box direction="row">
          <Box width="50%" background="module" round="small" border={{ color: 'border2' }} margin={{ right: 'spacing8' }}>
            <Box pad="spacing16" border={{ side: 'bottom', color: 'border1' }}>
              <Box
                background="canvas"
                pad="spacing16"
                round="xsmall"
                border={{ color: 'border2' }}
                {...claimsProcessor?.role ? { style: { textTransform: 'capitalize' }} : {}}
              >
                <InfoTile
                  title={claimsProcessor?.fullName || team?.name}
                  icon={claimsProcessor?.icon}
                  descriptions={claimsProcessor?.role ? [formatSnakeCaseToGeneral(claimsProcessor.role)] : team?.allowedAccountTypes}
                  isAllMode={team?.allowedAccountTypes?.length === ALL_ACCOUNT_TYPES_LENGTH}
                  isIconHidden={isTeamMode}
                  {...!isTeamMode && contactId === claimsProcessor?.id ? { isMe: true } : {}}
                />
              </Box>
            </Box>
            <Box height={{ max: '500px' }} overflow={{ vertical: 'auto' }}>
              <Box flex="grow">
                <Box pad={{ horizontal: 'spacing16', top: 'spacing16' }}>
                  <Inscription>{isTeamMode ? 'Team members' : 'Members of Claims teams'}: {isTeamMode ? team?.teamMembers?.length : claimsProcessor?.teams?.length}</Inscription>
                </Box>
                <Box pad={{ horizontal: 'spacing16', top: 'spacing12' }}>
                  <SearchInput
                    value={searchTextInittialySelectedItems}
                    placeholder={`Search for claims ${isTeamMode ? 'processor' : 'teams'}`}
                    onChange={setSearchTextInittialySelectedItems}
                  />
                </Box>
                <ListItemsWrapper pad="spacing16">
                  {initiallySelectedTiles?.filter((item) => item?.title?.toLowerCase()?.includes(searchTextInittialySelectedItems.toLowerCase()))?.map((item) => (
                    <Box
                      key={item?.id}
                      pad="spacing16"
                      round="xsmall"
                      {...initiallyAssignedIds?.some((id) => item?.id === id) ? {
                        background: 'canvas',
                        border: { color: 'border2' },
                      } : {
                        background: 'dangerContainer',
                        border: { color: 'dangerBorder' },
                      }}
                      {...isTeamMode ? { style: { textTransform: 'capitalize' } } : {}}
                    >
                      <InfoTile
                        isIconHidden={!isTeamMode}
                        title={item?.title}
                        descriptions={item?.descriptions}
                        setCheckedIds={setInitiallyAssignedIds}
                        checkedIds={initiallyAssignedIds}
                        id={item?.id}
                        icon={item?.icon}
                        isAllMode={!isTeamMode && item?.descriptions?.length === ALL_ACCOUNT_TYPES_LENGTH}
                        {...isTeamMode && contactId === item?.id ? { isMe: true } : {}}
                      />
                    </Box>
                  ))}
                </ListItemsWrapper>
              </Box>
            </Box>
          </Box>
          <Box width="50%" background="module" round="small" border={{ color: 'border2' }} margin={{ left: 'spacing8' }}>
            <Box pad={{ horizontal: 'spacing16', top: 'spacing16' }}>
              <Inscription
                color="textTitle"
                size="large"
                weight="bold"
              >
                {isTeamMode ? 'Other claim processors' : 'Other claims teams'}: {isTeamMode ? claimsProcessors?.length : teams?.length}
              </Inscription>
            </Box>
            <Box pad={{ horizontal: 'spacing16', top: 'spacing12', bottom: 'spacing8' }}>
              <SearchInput
                testId="search_for_claim"
                value={searchTextInittialyNotSelectedItems}
                placeholder={`Search for claims ${isTeamMode ? 'processor' : 'teams'}`}
                onChange={setSearchTextInittialyNotSelectedItems}
              />
            </Box>
            <ListItemsWrapper pad={{ horizontal: 'spacing16', bottom: 'spacing16', top: 'spacing4' }} height="450px" overflow={{ vertical: 'auto' }}>
              {isLoading ? <Preloader /> : (
                <>
                  {initiallyNotSelectedTiles?.map((item) => (
                    <Box
                      key={item?.id}
                      pad="spacing16"
                      height={{ min: '64px' }}
                      round="xsmall"
                      {...assignTileIds?.some((id) => item?.id === id) ? {
                        background: 'successContainer',
                        border: { color: 'successBorder' },
                      } : {
                        background: 'canvas',
                        border: { color: 'border2' },
                      }}
                      {...isTeamMode ? { style: { textTransform: 'capitalize' } } : {}}
                    >
                      <InfoTile
                        isIconHidden={!isTeamMode}
                        title={item?.title}
                        descriptions={item?.descriptions?.map((item) => {
                          if (isTeamMode) return formatSnakeCaseToGeneral(item);
                          return item;
                        })}
                        isAllMode={!isTeamMode && item?.descriptions?.length === ALL_ACCOUNT_TYPES_LENGTH}
                        setCheckedIds={setAssignTileIds}
                        checkedIds={assignTileIds}
                        id={item?.id}
                        icon={item?.icon}
                        {...isTeamMode && contactId === item?.id ? { isMe: true } : {}}
                      />
                    </Box>
                  ))}
                  {!initiallyNotSelectedTiles?.length ? (
                    <Box align="center" justify="center" height="100%">
                      <Inscription>No results have been found</Inscription>
                    </Box>
                  ) : null}
                </>
              )}
            </ListItemsWrapper>
          </Box>
        </Box>
        <ListItemsWrapper margin={{ top: 'spacing16' }}>
          <Box align="end">
            {team && assignTileIds?.length && claimsProcessors?.length ? (
              <Box>
                <Inscription color="success">
                  <Inscription>Assign</Inscription>&nbsp;
                  <Inscription weight="bold">{assignTileIds.map((memberId) => claimsProcessors.find((member) => member?.id === memberId)?.fullName)?.join(', ')}</Inscription>&nbsp;
                  <Inscription>to</Inscription>&nbsp;
                  <Inscription weight="bold">{team?.name}</Inscription>
                  <Inscription>?</Inscription>
                </Inscription>
              </Box>
            ) : null}
            {team && unassignTileIds?.length && team?.teamMembers?.length ? (
              <Box>
                <Inscription color="danger">
                  <Inscription>Remove</Inscription>&nbsp;
                  <Inscription weight="bold">{unassignTileIds.map((memberId) => team?.teamMembers?.find((member) => member?.id === memberId)?.fullName)?.join(', ')}</Inscription>&nbsp;
                  <Inscription>from</Inscription>&nbsp;
                  <Inscription weight="bold">{team?.name}</Inscription>
                  <Inscription>?</Inscription>
                </Inscription>
              </Box>
            ) : null}

            {claimsProcessor && assignTileIds?.length && teams?.length ? (
              <Box>
                <Inscription color="success">
                  <Inscription>Assign</Inscription>&nbsp;
                  <Inscription weight="bold">{claimsProcessor?.fullName}</Inscription>&nbsp;
                  <Inscription>to</Inscription>&nbsp;
                  <Inscription weight="bold">{assignTileIds.map((memberId) => teams.find((item) => item?.id === memberId)?.name)?.join(', ')}</Inscription>
                  <Inscription>?</Inscription>
                </Inscription>
              </Box>
            ) : null}
            {claimsProcessor && unassignTileIds?.length && claimsProcessor?.teams?.length ? (
              <Box>
                <Inscription color="danger">
                  <Inscription>Remove</Inscription>&nbsp;
                  <Inscription weight="bold">{claimsProcessor?.fullName}</Inscription>&nbsp;
                  <Inscription>from</Inscription>&nbsp;
                  <Inscription weight="bold">{unassignTileIds.map((memberId) => claimsProcessor?.teams?.find((item) => item?.id === memberId)?.name)?.join(', ')}</Inscription>
                  <Inscription>?</Inscription>
                </Inscription>
              </Box>
            ) : null}
          </Box>
          <Box direction="row" justify="end">
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId="cancel"
                buttonType="secondary"
                width="100%"
                onClick={() => clearEditableId()}
              >
                Cancel
              </AppButton>
            </SiblingBoxesWithSpacing>
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId="confirm"
                width="100%"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Preloader color="white" /> : 'Confirm'}
              </AppButton>
            </SiblingBoxesWithSpacing>
          </Box>
        </ListItemsWrapper>
      </Box>
    </Modal>
  );
};

export default ManagePopUp;
