import { useMemo } from 'react';

import { useGracePeriodFields } from '../GracePeriod/hooks';
import { usePayClaimsFields } from '../PayClaims/hooks';

export default () => {
  const gracePeriodFields = useGracePeriodFields();
  const payClaimsFields = usePayClaimsFields();

  const fields = useMemo(() => ([
    ...gracePeriodFields,
    ...payClaimsFields,

  ]), [gracePeriodFields, payClaimsFields]);
  return fields;
};
