import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import useLinkManagementStore from '../../../LinkManagement/useLinkManagementStore.store';
import LinkField from '../LinkField';

const useLinkManagementFields = () => {
  const privacyPolicyLink = useLinkManagementStore((state) => state.state.privacyPolicyLink);

  const termAndConditionsLink = useLinkManagementStore((state) => state.state.termAndConditionsLink);
  const pricingConsent = useLinkManagementStore((state) => state.state.pricingConsent);

  const fields = useMemo(() => {
    return [
      {
        name: 'termAndConditionsLink',
        type: FieldTypes.Node,
        testId: 'termAndConditionsLink',
        label: 'Terms and conditions',
        fieldWrapperStyles: {
          marginTop: theme.spacings.spacing8,
        },
        value: <LinkField value={termAndConditionsLink} />,
      },
      {
        name: 'privacyPolicyLink',
        type: FieldTypes.Node,
        testId: 'privacyPolicyLink',
        label: 'Privacy policy',
        fieldWrapperStyles: {
          marginTop: theme.spacings.spacing8,
        },
        value: <LinkField value={privacyPolicyLink} />,
      },
      {
        name: 'pricingConsent',
        type: FieldTypes.Node,
        testId: 'pricing_consent',
        label: 'Pricing consent',
        fieldWrapperStyles: {
          marginTop: theme.spacings.spacing8,
        },
        value: <LinkField value={pricingConsent} />,
      },
     
    ] as Field[];
  }, [pricingConsent, privacyPolicyLink, termAndConditionsLink]);

  return {
    fields,
  };
};

export default useLinkManagementFields;
