import React, {
  useCallback,
  useMemo,
} from 'react';
import {
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { DeductibleType, DeductibleTypeLabel } from '@/modules/deductibles/deductibles.types';
import { useProcessingRules } from '@/modules/ProcessingDefinition/ProcessingRule/hooks';
import theme from '@/styles/theme';
import { useFieldsWithDefaultValues } from '@/utils';

import { useEnrollmentMatrixStore } from '../../ComplexConfiguration/EnrollmentMatrix/stores';
import { useHealthPlanFieldsState, useTemplateFieldState } from '../../hooks';
import { AccountFundingConfigPaylod, CoverageLevelPayload } from '../CoverageLevels.constants';
import { useCoverageLevelsStore } from '../stores';

interface FieldsConfig {
  isTemplateMode?: boolean;
  isEditMode?: boolean;
  isActiveMode?: boolean;
}

const AUTO_FUNDING = 'auto funding';
export const HINT_RIGHT_ALIGN_KEY = 'right';
export const CovarageCodeHint = () => (
  <Text textAlign="start" size="medium">
    Coverage code will be used in the enrollment
    file to define the coverage level for each participant.
  </Text>
);

export const getAccountFundingItems = (item: AccountFundingConfigPaylod) => ({
  name: `${item.planId || ''}`,
  showRequireIcon: true,
  type: FieldTypes.Currency,
  label: `${item.planName} ${AUTO_FUNDING}`,
  placeholder: '',
  value: item.autoEnrollmentAmount,
  validator: VALIDATORS.REQUIRED_STRING.test({
    test(val) {
      const numberVal = _.toNumber(val);
      if (numberVal && !_.isNaN(numberVal)) {
        if (numberVal > 0) {
          return true;
        }
      }
      return false;
    },
    message: 'Should be bigger than $0',
  }),
}) as Field;
export const getAccountFundingItemsValidation = (
  item: AccountFundingConfigPaylod,
) => ({
  name: `${item.planId || ''}`,
  type: FieldTypes.Currency,

  placeholder: '',
  value: item.autoEnrollmentAmount,
  validator: VALIDATORS.REQUIRED_STRING,
}) as Field;

export default (
  id: string,
  config?: FieldsConfig,
) => {
  const enrollmentMatrixState = useEnrollmentMatrixStore((state) => state.state);
  const sourceState = useCoverageLevelsStore((state) => state.sourceState?.find((
    item,
  ) => item && item.coverageId === id));
  const currentState = useCoverageLevelsStore((state) => state.state?.find((
    item,
  ) => item && item.coverageId === id));
  const { data: rules } = useProcessingRules({
    perPage: 100,
    page: 0,
  });
  const tiers = useMemo(() => {
    const selectedRule = rules?.find((el) => el.id === enrollmentMatrixState.processingRuleName);
    return selectedRule?.tiers || [];
  }, [rules, enrollmentMatrixState]);
  const allTriggers = useMemo(() => tiers.map((item) => item?.triggers || []).flat(), [tiers]);
  const lastActiveTriggers = useMemo(() => {
    let foundTriggers: string[] | undefined;
    tiers?.slice().reverse().some((item) => {
      const triggers = item?.triggers;
      if (triggers?.length && (
        triggers.includes(DeductibleType.Member)
          || triggers.includes(DeductibleType.Plan)
          || triggers.includes(DeductibleType.Embedded)
      )) {
        foundTriggers = triggers;
        return true;
      }
      return false;
    });

    if (foundTriggers) {
      return foundTriggers;
    }
    return undefined;
  }, [tiers]);
  const validationTriggers = useMemo(() => allTriggers.filter((item) => lastActiveTriggers
    && !lastActiveTriggers.includes(item)), [allTriggers, lastActiveTriggers]) as DeductibleType[];

  const validateTriggersMethod = useCallback((
    type: DeductibleType,
    amount?: string,
    coverage?: CoverageLevelPayload,
  ) => {
    const planDeductibleAmount = validationTriggers.includes(DeductibleType.Plan)
      ? _.toNumber(coverage?.planDeductible) : -1;
    const embeddedDeductibleAmount = validationTriggers.includes(DeductibleType.Embedded)
      ? _.toNumber(coverage?.embeddedAmount) : -1;
    const memberDeductibleAmount = validationTriggers.includes(DeductibleType.Member)
      ? _.toNumber(coverage?.memberDeductible) : -1;

    if (amount && lastActiveTriggers && lastActiveTriggers.includes(type)
      && (_.toNumber(amount) <= planDeductibleAmount
      || _.toNumber(amount) <= embeddedDeductibleAmount
      || _.toNumber(amount) <= memberDeductibleAmount)) {
      return false;
    }
    return true;
  }, [lastActiveTriggers, validationTriggers]);

  const formatDeductibleLabels = useCallback((labels: DeductibleType[]) => {
    const formattedLabels = labels.map((label) => DeductibleTypeLabel[label]);
    return formattedLabels.join(' and ');
  }, []);

  const fields: Field[] = useMemo(() => {
    const planFields: Field[] = currentState?.accountFundingConfigs
      ? currentState?.accountFundingConfigs?.map(getAccountFundingItems) : [];

    return [
      {
        name: 'coverageCode',
        type: FieldTypes.Text,
        label: 'Coverage code',
        placeholder: '',
        showRequireIcon: true,
        validator: VALIDATORS.REQUIRED_STRING,
        hintAlign: HINT_RIGHT_ALIGN_KEY,
        hint: <CovarageCodeHint />,
      },
      {
        name: 'planDeductible',
        type: FieldTypes.Currency,
        label: 'Plan deductible',
        placeholder: '',
        validator: config?.isActiveMode
          && allTriggers?.some((trigger) => trigger === DeductibleType.Plan)
          ? yup.string()
            .test({
              test: (val, coverage) => validateTriggersMethod(
                DeductibleType.Plan,
                val,
                coverage.parent,
              ),
              message: `Must be bigger than ${formatDeductibleLabels(validationTriggers)}`,
            }).required(REQUIRED_TEXT) : VALIDATORS.STRING,
      },
      {
        name: 'memberDeductible',
        type: FieldTypes.Currency,
        label: 'Member deductible',
        placeholder: '',
        validator: config?.isActiveMode
          && allTriggers?.some((trigger) => trigger === DeductibleType.Member)
          ? yup.string()
            .test({
              test: (val, coverage) => validateTriggersMethod(
                DeductibleType.Member,
                val,
                coverage.parent,
              ),
              message: `Must be bigger than ${formatDeductibleLabels(validationTriggers)}`,
            }).required(REQUIRED_TEXT) : VALIDATORS.STRING,
      },
      {
        name: 'embeddedAmount',
        type: FieldTypes.Currency,
        label: 'Embedded amount',
        placeholder: '',
        validator: config?.isActiveMode
          && allTriggers?.some((trigger) => trigger === DeductibleType.Embedded)
          ? yup.string()
            .test({
              test: (val, coverage) => validateTriggersMethod(
                DeductibleType.Embedded,
                val,
                coverage.parent,
              ),
              message: `Must be bigger than ${formatDeductibleLabels(validationTriggers)}`,
            }).required(REQUIRED_TEXT) : VALIDATORS.STRING,
        fieldWrapperStyles: planFields.length ? {
          borderBottom: `1px solid ${theme.colors.border1}`,
          paddingBottom: theme.spacings.m,
        } : {
          borderBottom: 'none',
        },
      },
      ...planFields,
    ];
  }, [
    currentState,
    allTriggers,
    config,
    validateTriggersMethod,
    validationTriggers,
    formatDeductibleLabels,
  ]);
  const filteredField = useHealthPlanFieldsState(fields, id);
  const formatedFields = useFieldsWithDefaultValues(filteredField, sourceState);
  const stateFields = useTemplateFieldState(
    formatedFields, config?.isTemplateMode, config?.isEditMode, id,
  );

  return stateFields;
};
