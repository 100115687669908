import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useAccountFundingParamsFieldsStateStore, { AccountFundingParamsFieldsStatePayload } from '../AccountFundingParams/stores/useAccountFundingParamsFieldsState.store';
import useContributionFieldsStateStore, { ContributionFieldsStatePayload } from '../Contribution/stores/useContributionFieldState.store';
// import { useInterestAccrualsState } from '../InterestAccruals';
import useInterestAccrualsStateStore, { InterestAccrualsStatePayload } from '../InterestAccruals/stores/useInterestAccrualsState.store';
import useRolloverFieldsStateStore, { RolloverFieldsStatePayload } from '../Rollover/stores/useRolloverFieldsState.store';

export type AccountFundingFieldsStatePayload = RolloverFieldsStatePayload
& AccountFundingParamsFieldsStatePayload & ContributionFieldsStatePayload
& InterestAccrualsStatePayload;

export default () => {
  const handleSetRolloverStore = useRolloverFieldsStateStore((state) => state.setState);
  const handleSetSourceRolloverStore = useRolloverFieldsStateStore((state) => state.setSourceState);
  const handleShowErrorsRolloverStore = useRolloverFieldsStateStore((state) => state.setShowErrors);

  const handleResetRolloverStore = useRolloverFieldsStateStore((state) => state.handleReset);
  const handleSetRolloverErrors = useRolloverFieldsStateStore((state) => state.setErrors);
  const handleSetRolloverErrorFields = useRolloverFieldsStateStore((state) => state.setErrorFields);

  const setAccountFundingParamsStore = useAccountFundingParamsFieldsStateStore(
    (state) => state.setState,
  );
  const handleSetSourceAccountFundingParamsStore = useAccountFundingParamsFieldsStateStore(
    (state) => state.setSourceState,
  );
  const handleShowErrorsAccountFundingParamsStore = useAccountFundingParamsFieldsStateStore(
    (state) => state.setShowErrors,
  );
  const handleResetAccountFundingParamsStore = useAccountFundingParamsFieldsStateStore(
    (state) => state.handleReset,
  );
  const handleSetAccountFundingParamsErrors = useAccountFundingParamsFieldsStateStore(
    (state) => state.setErrors,
  );
  const handleSetAccountFundingParamsErrorFields = useAccountFundingParamsFieldsStateStore(
    (state) => state.setErrorFields,
  );

  const handleContributionStore = useContributionFieldsStateStore(
    (state) => state.setState,
  );
  const handleSetSourceContributionStore = useContributionFieldsStateStore(
    (state) => state.setSourceState,
  );
  const handleResetContributionParamsStore = useContributionFieldsStateStore(
    (state) => state.handleReset,
  );
  const handleSetContributionErrorFields = useContributionFieldsStateStore(
    (state) => state.setErrorFields,
  );

  const handleSetInterestAccrualsStore = useInterestAccrualsStateStore((state) => state.setState);
  const handleSetSourceInterestAccrualsStore = useInterestAccrualsStateStore(
    (state) => state.setSourceState,
  );
  const handleShowErrorsInterestAccrualsStore = useInterestAccrualsStateStore(
    (state) => state.setShowErrors,
  );
  const handleResetInterestAccrualsStore = useInterestAccrualsStateStore(
    (state) => state.handleReset,
  );
  const handleSetInterestAccrualsErrors = useInterestAccrualsStateStore((state) => state.setErrors);
  const handleSetInterestAccrualsErrorFields = useInterestAccrualsStateStore(
    (state) => state.setErrorFields,
  );

  const handleSetState = useCallback((values: Partial<AccountFundingFieldsStatePayload>) => {
    handleSetRolloverStore(values);
    setAccountFundingParamsStore(values);
    handleSetInterestAccrualsStore(values);
    handleContributionStore(values);
  }, [handleSetRolloverStore, setAccountFundingParamsStore,
    handleSetInterestAccrualsStore, handleContributionStore]);
  const handleSetSourceState = useCallback((values: Partial<AccountFundingFieldsStatePayload>) => {
    handleSetSourceRolloverStore(values);
    handleSetSourceInterestAccrualsStore(values);
    handleSetSourceAccountFundingParamsStore(values);
    handleSetSourceContributionStore(values);
  }, [handleSetSourceAccountFundingParamsStore,
    handleSetSourceContributionStore,
    handleSetSourceInterestAccrualsStore,
    handleSetSourceRolloverStore]);

  const handleReset = useCallback(() => {
    handleResetRolloverStore();
    handleResetInterestAccrualsStore();
    handleResetAccountFundingParamsStore();
    handleResetContributionParamsStore();
  }, [handleResetAccountFundingParamsStore, handleResetContributionParamsStore,
    handleResetInterestAccrualsStore, handleResetRolloverStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    handleShowErrorsRolloverStore(value);
    handleShowErrorsInterestAccrualsStore(value);
    handleShowErrorsAccountFundingParamsStore(value);
  }, [handleShowErrorsAccountFundingParamsStore, handleShowErrorsInterestAccrualsStore,
    handleShowErrorsRolloverStore]);
  const handleSetErrors = useCallback((values: Partial<AccountFundingFieldsStatePayload>) => {
    handleSetRolloverErrors(values);
    handleSetInterestAccrualsErrors(values);
    handleSetAccountFundingParamsErrors(values);
  }, [handleSetAccountFundingParamsErrors, handleSetInterestAccrualsErrors,
    handleSetRolloverErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<AccountFundingFieldsStatePayload>>) => {
      handleSetRolloverErrorFields(values);
      handleSetInterestAccrualsErrorFields(values);
      handleSetAccountFundingParamsErrorFields(values);
      handleSetContributionErrorFields(values);
    }, [handleSetAccountFundingParamsErrorFields, handleSetContributionErrorFields,
      handleSetInterestAccrualsErrorFields, handleSetRolloverErrorFields],
  );
  return {
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
  };
};
