import React from 'react';
import {
  AppButton,
  Box, Preloader, } from '@common-fe/common-fe';

interface Props {
  onCancel?: () => void;
  disable?: boolean;
  onSubmit?: () => void;
  loading?: boolean;
  testId?: string;
  submitButtonTitle: string;
}
const Footer: React.FC<Props> = ({
  onCancel, onSubmit, loading, testId = '', submitButtonTitle,
}) => (

  <Box direction="column">
    <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
      <Box width="dateControl" margin={{ right: 'spacing6' }}>
        <AppButton
          testId={`${testId}_cancel`}
          buttonType="secondary"
          onClick={onCancel}
          width="100%"
        >
          Cancel
        </AppButton>
      </Box>
      <Box width="dateControl" margin={{ left: 'spacing6' }}>
        <AppButton
          testId={`${testId}_submit`}
          onClick={onSubmit}
          disabled={loading}
          width="100%"
        >
          {loading ? (
            <Box align="center" justify="center">
              <Preloader
                data-testid={`${testId}_loading`}
                color="white"
              />
            </Box>
          ) : submitButtonTitle}
        </AppButton>
      </Box>
    </Box>
  </Box>
);
export default Footer;
