import { useEffect,useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { useCurrentPlan } from '@/modules/plan/PlanSetup/hooks';

export const PRIOR_PLAN_KEY = 'getPriorPlansList';

export interface PriorPlanResponse {
  id?: number,
  name?: string,
}

export const getPriorPlans = (
  priorPlanYearId: number,
  planType?: string,
) => () => api.get<PriorPlanResponse[]>(PATHS.PLAN_PRIOR, {
  params: {
    plan_year_id: priorPlanYearId,
    account_type: planType,
  },
});

export const usePriorPlansQuery = () => {
  const { planType, priorPlanYear, priorPlan } = useCurrentPlan();
  const { data, isSuccess, refetch } = useQuery(
    [PRIOR_PLAN_KEY, planType, priorPlanYear?.id],
    getPriorPlans(_.toNumber(priorPlanYear?.id), planType),
    {
      enabled: !!planType && !!priorPlanYear?.id,
      // retry: 1,
    },
  );

  useEffect(() => {
    if (priorPlanYear?.id && planType) refetch();
  }, [planType, priorPlanYear, priorPlan, refetch]);

  const formatedPriorPlans = useMemo<Option[]>(() => {
    const list = data?.data ? [...data.data] : [];

    if (priorPlan) {
      list.unshift({
        id: priorPlan?.id,
        name: priorPlan?.name,
      });
    }
    return list.map((item) => ({
      key: `${item.id}`,
      value: item.name,
      title: item.name,
    }));
  }, [data, priorPlan]);

  return {
    data: formatedPriorPlans,
    isSuccess,
    refetch,
  };
};

export default usePriorPlansQuery;
