import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { useAuthStore } from '@/modules/user/stores';

const useClockInQuery = () => {
  const { contactId } = useAuthStore();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (clockIn: boolean) => api.put(PATHS.CLOCK_IN(contactId, clockIn)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.CONTACT_INFO(contactId));
      },
    },
  );

  return mutateAsync;
};

export default useClockInQuery;
