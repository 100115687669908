import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { QUERY_KEY } from './useClaimsList.query';

interface Props {
  processorId?: number;
  selectedClaims?: number[];
}

const useUnassignProcessorQuery = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (props: Props) => api.post(
      PATHS.UNASSIGN_CLAIMS_TO_PROCESSOR,
      {
        ...props?.processorId ? {
          contact_id: props?.processorId,
        } : {},
        claim_ids: props?.selectedClaims,
      },
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
        if (onSuccess ) onSuccess();
      },
    },
  );

  return mutateAsync;
};

export default useUnassignProcessorQuery;
