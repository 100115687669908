import React, { useMemo } from 'react';
import {
  Box,
  CellIcon,
  divideString,
  ExpensesDetailsIcon,
  HomeOutlineIcon,
  InfoOutlineIcon,
  Inscription,
  MailMessageIcon,
  NetworkIcon,
  NewTabIcon,
  validatePhoneNumber,
  validateUrl,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { LARGE_SIZE_LOGO, LetterIconSizeEnum, NOT_NUMBERS_REGEXP } from '@/common/constants';
import regexp from '@/common/regexp';
import { Icon } from '@/components/elements/Icon/Icon';
import { useOrganizationSetupLogo } from '@/modules/employer/components/SetupEmployer/Branding/hooks/useOrganizationSetupLogo';

import HelpLink from './HelpLink';

const WrapperWithBottomShadow = styled(Box)`
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 48px;
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 80%, #FFFFFF 100%);
  }
`;

interface Props {
  urlName?: string;
  url?: string;
  email?: string;
  phoneNumber?: string;
}

const HelpPreview: React.FC<Props> = ({
  urlName,
  url,
  email,
  phoneNumber,
}) => {
  const {
    type,
    name,
    largeLightLogo,
  } = useOrganizationSetupLogo();

  const phoneNumbersOnly = `+${phoneNumber?.replace(NOT_NUMBERS_REGEXP, '')}`;

  const isPhoneNumberValid = useMemo(
    () => validatePhoneNumber().test(phoneNumber),
    [phoneNumber],
  );
  const isUrlValid = useMemo(
    () => validateUrl().test(url),
    [url],
  );
  const isEmailValid = useMemo(
    () => regexp.EMAIL.test(email || ''),
    [email],
  );

  return (
    <Box>
      <WrapperWithBottomShadow
        width={{ max: '272px' }}
        height={{ max: '136px' }}
        background="module"
        pad={{ horizontal: '20px', top: 'spacing24', bottom: 'spacing12' }}
      >
        <Box height={{ min: '164px' }}>
          <Box
            direction="row"
            align="center"
            margin={{ right: 'spacing12', bottom: 'spacing16' }}
          >
            <Box style={{ maxWidth: LARGE_SIZE_LOGO.width }}>
              <Icon
                size={LetterIconSizeEnum.LOGO}
                type={type}
                text={name}
                height={LARGE_SIZE_LOGO.height}
                width="100%"
                src={largeLightLogo}
              />
            </Box>
            <Box
              margin={{ left: 'spacing12' }}
              height={{ min: '40px' }}
              width={{ max: '175px' }}
              justify="center"
            >
              {largeLightLogo
                ? (
                  <Box height="fit-content">
                    <InfoOutlineIcon color="iconSecondary" />
                  </Box>
                )
                : (
                  <Box
                    height={{ min: '40px' }}
                    width={{ max: '175px' }}
                    justify="center"
                  >
                    <Inscription weight="bold" size="14px">
                      {name}
                    </Inscription>
                  </Box>
                )}
            </Box>
          </Box>

          <Box direction="row" align="center">
            <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
              <HomeOutlineIcon color="iconPrimary" />
            </Box>
            <Inscription color="textBody">
              Home
            </Inscription>
          </Box>

          <Box direction="row" align="center">
            <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
              <ExpensesDetailsIcon color="iconPrimary" />
            </Box>
            <Inscription color="textBody">
              Expense Details
            </Inscription>
          </Box>
        </Box>
      </WrapperWithBottomShadow>

      {
        ((urlName && isUrlValid && url)
          || (email && isEmailValid)
          || (phoneNumber && isPhoneNumberValid)) && (
          <Box
            width={{ max: '272px' }}
            background="module"
            pad={{ horizontal: '20px', top: 'spacing24', bottom: 'spacing12' }}
            margin={{ top: 'spacing12' }}
          >
            {urlName && isUrlValid && url && (
              <Box direction="row" align="center" height="36px">
                <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                  <NetworkIcon size="22px" color="iconPrimary" />
                </Box>
                <HelpLink url={url}>
                  <Inscription
                    color="#171717"
                    lineHeight="20px"
                    style={{ display: 'flex' }}
                  >
                    {urlName}<NewTabIcon color="#C4C4C4" size="16px" />
                  </Inscription>
                </HelpLink>
              </Box>
            )}

            {email && isEmailValid && (
              <Box direction="row" align="center" height="36px">
                <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                  <MailMessageIcon size="medium" color="iconPrimary" />
                </Box>
                <Inscription color="#171717" lineHeight="20px">
                  {email}
                </Inscription>
              </Box>
            )}

            {phoneNumber && isPhoneNumberValid && (
              <Box direction="row" align="center" height="36px">
                <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                  <CellIcon style={{ width: '22px' }} color="iconPrimary" />
                </Box>
                <Inscription color="#171717" lineHeight="20px">
                  {divideString(phoneNumbersOnly, [2, 5, 8])}
                </Inscription>
              </Box>
            )}
          </Box>
        )
      }
    </Box>
  );
};

export default HelpPreview;
