import React from 'react';
import {
  AccordionItem,
  Box,
  Inscription,
  LinkButton,
  PlanItem,
  Preloader,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import PlanCard from '@/components/elements/PlanCard';
import {
  GroupedPlan,
  PlanCardAction,
  PlanCardMode,
  PlanStatuses,
  PlansType,
} from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import PlanTypeWrapper from './PlanTypeWrapper';

export const SETUP_CONTAINER_UI_ID = 'setup-form-id';

const AccordionItemWrapper = styled(Box)`
  min-height: 24px;
  a {
    svg[aria-label="Chevron Up"] {
      transform: rotate(0deg);
    }
    svg[aria-label="Chevron Down"] {
      transform: rotate(360deg);
    }
    svg[aria-label="Chevron Up"], svg[aria-label="Chevron Down"] {
      fill: ${theme.colors.iconPrimary};
      height: 16px;
      width: 16px;
    }
    margin-left: ${theme.spacings.xs};
  }
`;

interface Props {
  title: string;
  status: PlanStatuses;
  plans: GroupedPlan[];
  plansList: PlanItem[];
  placeholder: string;
  isTemplateMode?: boolean;
  isPlansHidden?: boolean;
  isLoading?: boolean;
  onSelectAction?: (value: PlanCardAction, id: string) => void;
  scrollToSection: (data: { module?: string, section?: string }) => void;
  isRestoreButtonShow?: boolean;
  clearSearchValue: (value: string) => void;
  searchValue: string;
  currentTab?: PlansType;
  isScrolled?: boolean;
}

const PlansList: React.FC<Props> = ({
  title, status, plans, plansList, placeholder, isTemplateMode, clearSearchValue,
  isPlansHidden, onSelectAction, scrollToSection, isLoading, isRestoreButtonShow,
  searchValue, currentTab, isScrolled,
}) => (
  <>
    <PlanTypeWrapper
      key={currentTab}
      title={`${title} (${plansList.length})`}
      anchorId={status}
      testId={`Plans_block_${status}`}
      status={status}
      plans={plans}
      scrollToSection={scrollToSection}
      searchValue={searchValue}
      isTemplateMode={isTemplateMode}
      isScrolled={isScrolled}
    >
      {
        isLoading && (
          <Box flex="grow" align="center" justify="center" data-testid={`${title}_pick-plan-spinner-wrap`}>
            <Preloader />
          </Box>
        )
      }
      <Box direction="row" align="center" justify="start">
        {!plans?.length && !isLoading && (
          <Text
            color="textSecondary"
            textAlign="center"
            margin={{ vertical: 'xs', left: 'xs', right: 'xxs' }}
            data-testId="Plans_placeholder"
          >
            {placeholder}
          </Text>
        )}
        {isRestoreButtonShow && !isLoading
          && (
            <LinkButton
              testId="restore-default"
              linkInlineMode
              onClick={() => clearSearchValue('')}
            >
              Clear Query
            </LinkButton>
          )}
      </Box>
      {plans?.map((item, index) => (
        <AccordionItemWrapper
          key={item.category}
          margin={{ bottom: plans?.length - 1 === index ? '0': 'xl' }}
          id={`${item.category}_${status}`}
        >
          <AccordionItem
            text={(
              <Box
                margin={{ left: 'xxxs' }}
                direction="row"
                align="center"
              >
                <Inscription color="textBody" weight="normal">
                  {item.category} ({item?.plans?.length || 0})
                </Inscription>
              </Box>
            )}
            isExpandedDefault
            isLeftArrow
          >
            <Box margin={{ top: 'xxs' }}>
              <Box direction="row" wrap>
                {
                  item.plans
                    .map((plan) => (
                      <PlanCard
                        testId={`PlanCard_${plan.id}`}
                        isTemplateMode={isTemplateMode}
                        isPlansHidden={isPlansHidden}
                        onSelectAction={onSelectAction}
                        key={plan.id}
                        plan={plan}
                        mode={PlanCardMode.Plan}
                      />
                    ))
                }
              </Box>
            </Box>
          </AccordionItem>
        </AccordionItemWrapper>
      ))}
    </PlanTypeWrapper>
    <Box height="1px" background="border1" margin={{ bottom: 'm' }} />
  </>
);

export default PlansList;
