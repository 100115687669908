import React from 'react';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import ExpressSetup from '@/modules/ExpressSetup';

const ExpressSetupPage = () => {
  useBreadcrumbs([{
    route: ROUTES.EXPRESS_SETUP_VIEW(),
    title: 'Home',
  }], undefined, undefined, true);

  return (
    <ExpressSetup />
  );
};

export default ExpressSetupPage;
