import { useMemo } from 'react';
import { Field } from '@common-fe/common-fe';

import { REQUIRED_TEXT } from '@/common/constants';
import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';
import { useAccountFundingParamsStore } from '@/modules/plan/PlanSetup/AccountFunding/AccountFundingParams/stores';
import { ROLLOVER_KEYS } from '@/modules/plan/PlanSetup/AccountFunding/Rollover/Rollover.constants';
import { GRACE_PERIOD_KEYS } from '@/modules/plan/PlanSetup/Coverage/GracePeriod/hooks/useGracePeriodFields';
import { useCurrentPlan } from '@/modules/plan/PlanSetup/hooks';
import { useAdditionalStore } from '@/modules/plan/PlanSetup/PlanDefinition/Additional/stores';
import {
  PLAN_SETUP_FIELD,
} from '@/modules/plan/PlanSetup/PlanSetup.constants';

import { PlanFieldState, PlanStatuses } from '../../plan.types';

import usePlanSetupFieldsState from './usePlanSetupFieldsState';

export interface StateFieldMap {
  [key: string]: PlanFieldState;
}

const CUSTOM_KEY = 'CUSTOM';
const activeUpdateFields = [
  PLAN_SETUP_FIELD.PLAN_NAME,
  PLAN_SETUP_FIELD.PLAN_CODE,
  PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT,
  PLAN_SETUP_FIELD.MINIMUM_ELECTION_AMOUNT,
  PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT_CUSTOM,
  PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT_IRS,
  PLAN_SETUP_FIELD.MINIMUM_ELECTION_AMOUNT_CUSTOM,
  PLAN_SETUP_FIELD.PROOF_OF_EXPENSE,
  PLAN_SETUP_FIELD.GENERATE_STATEMENTS,
  PLAN_SETUP_FIELD.PLAN_LEVEL_FUNDING,
  PLAN_SETUP_FIELD.REPLENISHMENT_BANK_ACCOUNT,
  PLAN_SETUP_FIELD.REPLENISHMENT_FREQUENCY,
  PLAN_SETUP_FIELD.REPLENISHMENT_METHOD,
  PLAN_SETUP_FIELD.REPLENISHMENT_DAY_NUMBER,
  PLAN_SETUP_FIELD.SPEND_PERIOD,
  PLAN_SETUP_FIELD.IS_PRE_FUND_CALCULATED,

  ...Object.values(ROLLOVER_KEYS),
  ...Object.values(GRACE_PERIOD_KEYS || {}),
];

const usePlanFieldsState =  (currentFields: Field[]) => {
  const fieldsState = usePlanSetupFieldsState();
  const { planStatus } = useCurrentPlan();
  const accountFundingSourceState = useAccountFundingParamsStore((state) => state.sourceState);
  const sourceState = useAdditionalStore((state) => state.sourceState);
  const memoizedActiveUpdateFields = useMemo(() => {
    const additionalFields = [];

    if(!accountFundingSourceState?.isPreFundCalculated) {
      additionalFields.push(
        PLAN_SETUP_FIELD.REPLENISHMENT_PERCENT,
        PLAN_SETUP_FIELD.REPLENISHMENT_MIN_VALUE,
        PLAN_SETUP_FIELD.REPLENISHMENT_CREEP_PERCENT,
        PLAN_SETUP_FIELD.REPLENISHMENT_CREEP_MIN,
      );
    }
    if (sourceState?.spendLimit !== CUSTOM_KEY) {
      additionalFields.push(
        PLAN_SETUP_FIELD.SPEND_LIMIT,
        PLAN_SETUP_FIELD.SPEND_LIMIT_IRS_AMOUNT,
        PLAN_SETUP_FIELD.SPEND_LIMIT_CUSTOM
      );
    }
    return [...activeUpdateFields, ...additionalFields];
  }, [sourceState?.spendLimit, accountFundingSourceState?.isPreFundCalculated]);

  const isViewHidden = useHasAccess([
    {
      permission: Permissions.VIEW_HIDDEN_PLAN_FIELDS,
    },
  ]);

  const states = fieldsState as StateFieldMap;
  const getFormatedFields = (
    fields: Field[], parentDisabled?: boolean,
  ) => fields.reduce((result: Field[], field): Field[] => {
    const fieldState = states[field.name];
    let currentField = {
      ...field,
    };
    if (!field.label && parentDisabled) {
      currentField = {
        ...currentField,
        disabled: true,
      };
    }
    const isDisabled = fieldState === PlanFieldState.Locked || fieldState === PlanFieldState.Hidden;
    if (field.subFields) {
      currentField = {
        ...field,
        subFields: getFormatedFields(field.subFields, isDisabled || field.disabled) as Field[],
      };
    }

    if (fieldState === PlanFieldState.Hidden) {
      if (isViewHidden) {
        return [...result, {
          ...currentField,
          disabled: true,
        }];
      }
      return result;
    }
    if (fieldState === PlanFieldState.Locked) {
      return [...result, {
        ...currentField,
        disabled: true,
      }];
    }
    if (fieldState === PlanFieldState.Modifiable) {
      if (field.disabled) {
        return [...result, currentField];
      } else {
        return [
          ...result,
          {
            ...currentField,
            disabled: (planStatus === PlanStatuses.ACTIVE || planStatus === PlanStatuses.PENDING_ACTIVE) && !memoizedActiveUpdateFields.includes(field.name) ? true : false,
          },
        ];
      }
    }
    if (
      fieldState === PlanFieldState.RequiredAtPlan
        || fieldState === PlanFieldState.RequiredAtAllLevels) {
      return [...result, {
        ...currentField,
        showRequireIcon: true,
        validator: currentField.validator?.required(REQUIRED_TEXT),
      }];
    }
    return [...result, currentField];
  }, []);
  return getFormatedFields(currentFields);
};

export default usePlanFieldsState;
