import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';


export const QUERY_KEY = 'GET_PAYROLL_CALENDARS';

export interface PayrollCalendarResponsePayload {
  id: string;
  deactivated: boolean;
  frequency_type: string;
  group_type: string;
  name: string;
  start_date: string;
}
interface ExpandedOption extends Option {
  startDate?: dayjs.Dayjs;
}


export default () => {
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, id],
    () => api.get<PayrollCalendarResponsePayload[]>(PATHS.ORGANIZATION_PAYROLL_CALENDAR(id)),
    {
      retry: 0,
      enabled: !!id,
    }
  );
  const options = useMemo<ExpandedOption[]>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      title: item.name,
      value: item.name,
      startDate: item.start_date ? dayjs(item.start_date).subtract(1, 'day'): undefined
    }));
  }, [data?.data]);



  return {
    options,
    data,
    isLoading,
    isSuccess,
  };
};
