import React, { useMemo,useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Box,
  FlexListHeader,
  FlexListItem,
  FlexListShowMore,
  ListItem,
  Text,
} from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import { ListItemBase, ListItemType } from '@/common/types';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { useHealthPlansListQuery } from '@/modules/HealthPlan/queries/useHealthPlansList.query.ts';
import { PlanStatuses, PlanTypes } from '@/modules/plan/plan.types';
import { usePlanListQuery } from '@/modules/plan/PlansList/queries';

import PlanEditViewModalWrapper from './PlanEditViewModal/PlanEditViewModalWrapper';

type ExtendedListItem = ListItem & {
  isActive?: boolean;
  planYearId?: string;
};

interface Props {
  isHealthPlanMode?: boolean;
}

const PER_PAGE = 10;
const PlanTable: React.FC<Props> = ({ isHealthPlanMode }) => {
  const [showMore, setShowMore] = useState(false);
  const { params } = useRouteMatch<{ id: string }>();
  const { formattedArrayPlans, refetch } = usePlanListQuery(params.id || null, PlanStatuses.READY_FOR_SETUP);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [viewPlanMode, setViewPlanMode] = useState<boolean>(false);
  const [editPlanMode, setEditPlanMode] = useState<boolean>(false);
  const [selectedPlan, setEditPlan] = useState<PlanTypes | undefined>();
  const plansList = useMemo(() => formattedArrayPlans.map((item) => ({
    id: item.id,
    planType: item.type,
    planName: item.name || '',
    planStatus: item.status,
    planYearId: item?.planYearId,
    planYearName: item?.planYearName,
  })), [formattedArrayPlans]);

  const {
    data: formattedArrayHealthPlans,
    refetch: healthPlansRefetch,
  } = useHealthPlansListQuery(params.id, HealthPlanStatuses.DRAFT);
  const healthPlansList = useMemo(() => formattedArrayHealthPlans.map((item) => ({
    id: item.id,
    planTypes: item.types,
    planName: item.name || '',
    planStatus: item.status,
    planYearId: item?.planYearId,
    planYearName: item?.planYearName,
  })), [formattedArrayHealthPlans]);

  const headers: ListItemBase[] = useMemo(() => [{
    key: 'planType',
    title: isHealthPlanMode ? 'Plan Types' : 'Plan Type',
  }, {
    key: 'planName',
    title: 'Plan Name',
  },
  {
    key: 'planYearName',
    title: 'Plan Year',
  }], [isHealthPlanMode]);

  const showedList = useMemo(() => {
    const list = isHealthPlanMode ? healthPlansList : plansList;
    return showMore ? list : list.slice(0, PER_PAGE);
  }, [plansList, healthPlansList, showMore, isHealthPlanMode]);
  
  const moreItemsCount = useMemo(() => 
    isHealthPlanMode ? healthPlansList.length - showedList.length : plansList.length - showedList.length, 
  [isHealthPlanMode, healthPlansList, plansList, showedList]);
  
  const formatedList = useMemo<ExtendedListItem[]>(() => 
    showedList.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isHealthPlanItem = (item: any): item is { planTypes: string[] } => 'planTypes' in item;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isRegularPlanItem = (item: any): item is { planType: string } => 'planType' in item;
    
      return {
        id: item.id || '',
        isActive: item.planStatus === PlanStatuses.ACTIVE,
        fields: [
          {
            key: 'planType',
            type: ListItemType.Contact,
            title: isHealthPlanMode && isHealthPlanItem(item) ? (item.planTypes?.join(', ') || '-') : isRegularPlanItem(item) ? item.planType : '',
            onClick: () => {
              setModalVisible(true);
              setViewPlanMode(true);
              setEditPlan(item);
            },
          },
          {
            key: 'planName',
            type: ListItemType.Text,
            title: item.planName,
          },
          {
            key: 'planYearName',
            type: ListItemType.Text,
            title: item.planYearName || '',
          },
        ],
      };
    }), [showedList, isHealthPlanMode]);
  
  return (
    <>
      <Box background="canvas" round="container1Round">
        {(!!plansList.length || !!healthPlansList.length) && (
          <Box margin="small" pad="small" background="module" round="container1Round">
            <Text
              margin={{ top: 'spacing8', bottom: 'spacing12' }}
              size="large"
              weight="bold"
              color="textBody"
            >
              {isHealthPlanMode ? `Health Plans: ${healthPlansList.length}` : `Plans: ${plansList.length}`}
            </Text>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formatedList.map((row: ExtendedListItem) => (
                <FlexListItem
                  key={uniqueId()}
                  id={row.id}
                  fields={row.fields}
                  shifted
                  options={[]}
                />
              ))}
              {(!!moreItemsCount) && (
                <FlexListShowMore
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show More ({moreItemsCount})
                </FlexListShowMore>
              )}
            </Box>
          </Box>
        )}
        <PlanEditViewModalWrapper
          visible={modalVisible}
          onSetVisible={() => {
            setModalVisible(!modalVisible);
            setViewPlanMode(false);
            setEditPlanMode(false);
          }}
          // onSetEditPlanMode={() => setEditPlanMode(true)}
          refetchList={() => isHealthPlanMode ? healthPlansRefetch() : refetch()}
          modalTitle={selectedPlan?.planName}
          viewMode={viewPlanMode}
          editMode={editPlanMode}
          data={selectedPlan}
          isHealthPlanMode={isHealthPlanMode}
        />
      </Box>
    </>
  );
};

export default PlanTable;
