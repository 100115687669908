import { baseStoreCreator } from '@/utils/store';

export interface ContributionPayload {
  allowContributors: string;

  employerPreTax?: boolean;
  employerPreTaxValue?: string;
  employerPostTax?: boolean;
  employerPostTaxValue?: string;
  employeePreTax?: boolean;
  employeePreTaxValue?: string;
  employeePostTax?: boolean;
  employeePostTaxValue?: string;

  allowContributionOverMaximum?: boolean;
  pendContribution?: boolean;

  allowIndividualContributions?: string;
  сontributionMonitoringRequired: string;
  minimumContributionAmount: string;
}

export const DEFAULT_VALUES: ContributionPayload = {
  allowContributors: '',
  employerPreTax: false,
  employerPreTaxValue: '',
  employerPostTax: false,
  employerPostTaxValue: '',

  employeePreTax: false,
  employeePreTaxValue: '',
  employeePostTax: false,
  employeePostTaxValue: '',

  allowContributionOverMaximum: false,
  pendContribution: false,

  allowIndividualContributions: undefined,
  сontributionMonitoringRequired: '',
  minimumContributionAmount: '',
};

export const useStore = baseStoreCreator<ContributionPayload>(DEFAULT_VALUES);

export default useStore;
