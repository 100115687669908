import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { usePlanByIdQuery } from '../queries';

interface RouterParams {
  id: string;
}
const useParentId = () => {
  const { params: { id } } = useRouteMatch<RouterParams>();
  const { data: plan } = usePlanByIdQuery(id);
  const parentId = useMemo(() => _.get(plan, 'parent_id'), [plan]);
  return parentId;
};

export default useParentId;
