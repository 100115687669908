import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton, Box, Preloader, WarnModal,
} from '@common-fe/common-fe';

import { useDeleteService } from '../queries';

interface Props {
  serviceId?: string;
  setDeleteServiceId: (id: string) => void;
}
const ServiceDeleteModal: React.FC<Props> = ({ serviceId, setDeleteServiceId }) => {
  const [isVisible, setVisible] = useState(false);
  const { handleDelete } = useDeleteService();
  useEffect(() => {
    if (serviceId) {
      setVisible(true);
    }
  }, [serviceId]);

  useEffect(() => {
    if (!isVisible) {
      setDeleteServiceId('');
    }
  }, [isVisible, setDeleteServiceId]);

  const handleClose = useCallback(() => {
    setVisible(true);
    setDeleteServiceId('');
  }, [setDeleteServiceId]);

  const handleConfirm = useCallback(async () => {
    try {
      if (serviceId) {
        await handleDelete(serviceId);
        setDeleteServiceId('');
      }
    // eslint-disable-next-line no-empty
    } catch {
    }
  }, [serviceId, handleDelete, setDeleteServiceId]);

  const loading = false;
  if (!serviceId) {
    return null;
  }
  return (
    <WarnModal
      testId="service-payment-delete"
      visible={isVisible}
      header="Delete Service Payment?"
      onSetVisible={setVisible}
      cancelButtonNode={(
        <Box direction="row">
          <Box width="150px" margin={{ right: 'spacing6' }}>
            <AppButton
              testId="service-payment-delete_cancel"
              buttonType="secondary"
              onClick={handleClose}
              width="100%"
            >
              Cancel
            </AppButton>
          </Box>
          <Box width="150px" margin={{ left: 'spacing6' }}>
            <AppButton
              testId="service-payment-delete_apply"
              color="red"
              onClick={handleConfirm}
              width="100%"
            >
              {loading
                ? <Preloader color="white" />
                : 'Yes, Delete'}
            </AppButton>
          </Box>
        </Box>

      )}
    />
  );
};

export default ServiceDeleteModal;
