import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface VendorPayload {
  id?: number;
  vendor_name?: string;
  address_line1?: string;
  address_line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  check_name?: string;
  check_memo?: string;
}

export interface Vendor {
  vendorId?: string;
  vendorName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  checkName?: string;
  checkMemo?: string;
}

const useGetVendorsQuery = () => {
  const {
    data,
    isLoading,
    isError
  } = useQuery([PATHS.GET_VENDORS], () => api.get<VendorPayload[]>(PATHS.GET_VENDORS));

  const formattedData: Vendor[] = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return data?.data?.map((vendor) => ({
      vendorId: toString(vendor?.id),
      vendorName: vendor?.vendor_name,
      addressLine1: vendor?.address_line1,
      addressLine2: vendor?.address_line2,
      city: vendor?.city,
      state: vendor?.state,
      zip: vendor?.zip,
      checkName: vendor?.check_name,
      checkMemo: vendor?.check_memo,
    }));
  }, [data]);

  return {
    vendors: formattedData,
    isLoading,
    isError,
  };
};

export default useGetVendorsQuery;
