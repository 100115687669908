import React from 'react';
import {
  AppButton,
  DownloadIcon,
  Text,
} from '@common-fe/common-fe';

import { PATHS, REGEXPS } from '@/common';
import { downloadFile } from '@/utils/handlers';

interface Props {
  fileId: string;
  fileName: string;
}
const ExportErrorsButton: React.FC<Props> = ({ fileId, fileName }) => {
  const handleDownload = downloadFile(
    PATHS.GET_FILE_ERRORS_CSV(fileId),
    fileName.replace(REGEXPS.CSV, '_error_report.csv'),
  );

  return (
    <AppButton
      buttonType="quaternary"
      onClick={handleDownload}
      testId={`download_${fileId}`}
    >
      <Text margin={{ right: 'xs' }}>
        Export CSV
      </Text>
      <DownloadIcon />
    </AppButton>
  );
};

export default ExportErrorsButton;
