import React, { useMemo } from 'react';
import { Box, CardTypesEnum, Enrollment } from '@common-fe/common-fe';

import { SelfBlockWrapper } from '@/components/wrappers/BlockWrapper';

import { Investors } from '../../Employee/queries/useGetInvestors.query';
import { useGetAccountNumberQuery } from '../queries/useGetAccountNumber.query';

import { EnrollmentDetailsAccountNumber } from './EnrollmentDetailsAccountNumber';
import { EnrollmentDetailsEnrollmentStatus } from './EnrollmentDetailsEnrollmentStatus';
import { EnrollmentDetailsEnrollmentStatusHistory } from './EnrollmentDetailsEnrollmentStatusHistory';
import { EnrollmentDetailsInvestmentStatus } from './EnrollmentDetailsInvestmentStatus';

interface Props {
  id: string;
  currentMyAccount: Enrollment;
  investorsData?: Investors;
}

export const EnrollmentDetailsAccountInfo: React.FC<Props> = ({
  id,
  currentMyAccount,
  investorsData,
}) => {
  const coveragePeriods = useMemo(() => currentMyAccount.coveragePeriods, [currentMyAccount]);
  const isHSA = useMemo(() => currentMyAccount.accountType === CardTypesEnum.HSA, [currentMyAccount]);
  const { formattedData } = useGetAccountNumberQuery(
    id,
    isHSA,
  );

  const accountNumber = useMemo(
    () => (formattedData.accountNumber ? formattedData.accountNumber : null),
    [formattedData],
  );
  const routingNumber = useMemo(
    () => (formattedData.routingNumber ? formattedData.routingNumber : null),
    [formattedData],
  );
  return (
    <Box gap="spacing24">
      {isHSA && (
        <Box gap="spacing24">
          <SelfBlockWrapper
            title="Account number"
            moduleAnchorId="account_number"
          >
            <EnrollmentDetailsAccountNumber
              accountNumber={accountNumber}
              routingNumber={routingNumber}
            />
          </SelfBlockWrapper>
          {investorsData?.investorId && (
            <SelfBlockWrapper
              title="Investments status"
              moduleAnchorId="investments_status"
            >
              <EnrollmentDetailsInvestmentStatus investorData={investorsData} />
            </SelfBlockWrapper>
          )}
        </Box>
      )}
      <SelfBlockWrapper
        title="Enrollment status"
        moduleAnchorId="enrollment_status"
      >
        <EnrollmentDetailsEnrollmentStatus
          coverageDateEnd={currentMyAccount.coverageDateEnd}
          coverageDateStart={currentMyAccount.coverageDateStart}
          submitClaimsDateEnd={currentMyAccount.submitClaimsDateEnd}
          enrollmentStatus={currentMyAccount.enrollmentStatus}
        />
        {coveragePeriods && coveragePeriods.length > 1 && (
          <Box margin={{ top: 'spacing16' }}>
            <EnrollmentDetailsEnrollmentStatusHistory
              coveragePeriods={currentMyAccount.coveragePeriods}
              policyId={currentMyAccount.policyId}
            />
          </Box>
        )}
      </SelfBlockWrapper> 
    </Box>
  );
};
