import React, { useCallback, useMemo, useState } from 'react';
import {
  AbsenceFilesPlaceholderIcon,
  Box,
  FlexList,
  NoSearchItemsPlaceholder,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import DashesPlaceholder from '@/assets/placeholders/dashes-placeholder.svg';
import Permissions from '@/common/permissions';
import { DefaultSortTypesEnum, ListItemBase, OptionKey } from '@/common/types';
import { useHasAccess } from '@/modules/core/hooks';

import { CopayStatuses } from '../CopayAdjudication.constant';

import CopayDeleteModal from './CopayDeleteModal';
import CopayStatusPicker from './CopayStatusPicker';
import CopayViewModal from './CopayViewModal';
import { useCopayGroupsList, useListPagination } from './hooks';

const Wrapper = styled(Box)`
  .highlight-item {
    animation-name: showHighlight;
    animation-duration: 3s;
    @keyframes showHighlight {
      from {
        border-color: ${({ theme }) => theme.colors.accentActive};
        ${({ theme }) => theme.shadows.highlighted};
      }
      to {
        border-color: transparent;
      }
    }
  }
  .is-expired {
    &::before {
      content: '';
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      height: 10px;
      overflow: hidden;
      content: ${`url(${DashesPlaceholder})`};
    }
  }
`;

interface Props {
  isShowSettings?: boolean;
  isShowTitle?: boolean;
  isExpired?: boolean;
  statuses: OptionKey[];
  onStatusChange: (values: OptionKey[]) => void;
}

const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'A-Z',
  },
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Z-A',
  },
];

export const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'Name',
    title: 'Name',
    flex: 2,
  },
  {
    key: 'Copay',
    title: 'Copay',
    flex: 6,
  },
  {
    key: 'validFrom',
    title: 'Valid from',
    flex: 1.25,
  },
  {
    key: 'validTo',
    title: 'Valid to',
    flex: 1.25,
  },
];

const CopaysList: React.FC<Props> = ({
  statuses, onStatusChange, isExpired, isShowTitle,
}) => {
  const [currentCopayId, setCurrentCopayId] = useState('');
  const [editCopayId, setEditCopayId] = useState('');
  const [deleteCopayId, setDeleteCopayId] = useState('');

  const {
    page,
    setPage,
    perPage,
    setPerPage,
    currentSearch,
    searchString,
    setSearchString,
    setSortBy,
    sortBy,
  } = useListPagination();
  const hasEditCopayAccess = useHasAccess([{ permission: Permissions.EDIT_COPAY }]);
  const hasViewCopayAccess = useHasAccess([{ permission: Permissions.VIEW_COPAY }]);
  const handleToggleDeleteModal = useCallback((id: string) => {
    setDeleteCopayId(id);
  }, []);
  const handleOpenModal = useCallback((id: string) => {
    setCurrentCopayId(id);
  }, []);
  const handleEditModal = useCallback((id: string) => {
    setEditCopayId(id);
  }, []);
  const { rows, total, isLoading } = useCopayGroupsList(
    {
      searchString: currentSearch,
      page: page - 1,
      perPage,
      statuses: isExpired ? [CopayStatuses.Expired] : [CopayStatuses.Active],
      sortBy: sortBy as DefaultSortTypesEnum,
    },
    handleOpenModal,
  );

  const titleNode = useMemo(() => {
    if (isShowTitle) {
      let text = 'Active';
      if (isExpired) {
        text = 'Expired';
      }
      return (
        <Box pad={{ horizontal: 'xxs' }}>
          <Text size="xxlarge" margin={{ bottom: 's' }}>
            {text}
          </Text>
        </Box>
      );
    }
    return null;
  }, [isExpired, isShowTitle]);
  return (
    <Wrapper
      data-testid="copays-list"
      round={{
         
        corner: isShowTitle ? isExpired ? 'bottom' : 'top' : undefined,
        size: 'container1Round',
      }}
      background="module"
      style={{ borderTop: isShowTitle && isExpired ? 'none' : undefined }}
      pad={{ top: isShowTitle && isExpired ? '' : 'l' }}
    >
      <CopayDeleteModal copayId={deleteCopayId} onSetCopayId={setDeleteCopayId} />
      <CopayViewModal copayId={currentCopayId} onSetCopayId={setCurrentCopayId} />
      <CopayViewModal isEditMode copayId={editCopayId} onSetCopayId={setEditCopayId} />
      <Box pad={{ left: 'l', right: 'm' }}>
        {titleNode}
        <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
          <Box>
            <Text weight="bold" color="textBody" size="large" style={{ whiteSpace: 'nowrap' }}>
              Copay groups: {total}
            </Text>
          </Box>
          <Box direction="row" justify="end">
            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <SearchInput
                disabled={false}
                testId="copays-list"
                value={searchString as string}
                placeholder="Search"
                onChange={setSearchString}
                hasSearchingHistory
              />
            </Box>

            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <SelectDropdown
                id="sort_by"
                disabled={false}
                testId="copays-list_sort"
                name="Sort by"
                prefix="Sort by"
                singleMode
                value={sortBy}
                onChange={setSortBy}
                activeTitle
                options={SORTING_OPTIONS}
              />
            </Box>
            <CopayStatusPicker optionKeys={statuses} onSetOptionsKeys={onStatusChange} />
          </Box>
        </Box>
      </Box>
      <FlexList
        total={total}
        headers={LIST_HEADERS}
        rows={rows}
        loading={isLoading}
        moreCount={perPage}
        options={[
          ...hasViewCopayAccess ? [{
            name: 'View',
            onClick: handleOpenModal,

          }] : [],
          ...hasEditCopayAccess ? [{
            name: 'Edit',
            isAvailable: () => !isExpired,
            onClick: handleEditModal,
          },
          {
            name: 'Delete',
            isWarning: true,
            isAvailable: () => !isExpired,
            onClick: handleToggleDeleteModal,
          }] : [],

        ]}
        placeholder={(
          <NoSearchItemsPlaceholder
            placeholderTitle="There are no copay groups"
            node={<AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />}
          />
        )}
        footer={(
          <PaginationBar
            testId="copays-list_pagination"
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
      />
    </Wrapper>
  );
};

export default CopaysList;
