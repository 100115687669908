import React from 'react';
import {
  Box,
  Heading,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import HeaderLink from '@/components/elements/HeaderLink';
import { useHistory } from '@/modules/core/hooks';
import { HealthPlansType } from '@/modules/HealthPlan/HealthPlan.types';

import SelectButton from './SelectButton';

export const StyledHeading = styled(Heading)`
  transition: .2s;
  line-height: 40px;
`;

interface Props {
  currentTab: HealthPlansType;
  onSetCurrentTab: (tab: HealthPlansType) => void;
  hideHeader: boolean;
  plansCount?: number;
  templatesCount?: number;
  hidePlans?: boolean;
  children?: React.ReactNode;
  link?: string;
  isPageMode?: boolean;
  isEmployer?: boolean;
}

const HealthPlansToggle: React.FC<Props> = ({
  hideHeader,
  link,
  plansCount = 0,
  templatesCount = 0,
  children,
  onSetCurrentTab,
  currentTab,
  hidePlans,
  isPageMode,
  isEmployer,
}) => {
  const history = useHistory();
  return (
    <>
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{
           
          top: hideHeader ? 'spacing4' : isPageMode ? 'l' : '0',
           
          bottom: hideHeader ? 'spacing4' : isPageMode ? 'l' : '0',
        }}
        {...hideHeader ? { hideHeader: '56px' } : {}}
      >
        {
          isPageMode
            ? (
              <StyledHeading
                data-testId="HealthPlans_title"
                level={4}
                color="textTitle"
                size="large"
              >
                {isEmployer ? 'Health Plans' : 'Health Plan Templates'}
              </StyledHeading>
            ) : (
              <Box margin={{ top: 'l', bottom: 'm' }}>
                <HeaderLink
                  path={
                    `${ROUTES.HEALTH_PLANS}${history.observingSearchQuery ? `?${history.observingSearchQuery}` : ''}${`#${currentTab === HealthPlansType.Plan
                      ? HealthPlansType.Plan
                      : HealthPlansType.Template}`}`
                  }
                  title={isEmployer ? 'Health Plans' : 'Health Plan Templates'}
                />
              </Box>
            )
        }
        <Box direction="row" align="center">
          {!hidePlans && (
            <Box
              direction="row"
              background="module"
              round="container1Round"
              pad="xsmall"
              margin={{ right: 'spacing16' }}
            >
              <SelectButton
                link={link}
                onClick={onSetCurrentTab}
                testId="HealthPlans_toggle_plans"
                currPlan={currentTab}
                name={HealthPlansType.Plan}
                text={`Health Plans (${plansCount})`}
              />
              <SelectButton
                link={link}
                onClick={onSetCurrentTab}
                currPlan={currentTab}
                testId="HealthPlans_toggle_plan-templates"
                name={HealthPlansType.Template}
                text={`Health Plan Templates (${templatesCount})`}
              />
            </Box>
          )}
          <Box>
            {children}
          </Box>
        </Box>
      </Box>
      {hideHeader && <Box width="100%" height="1px" background={{ color: 'border1' }} />}
    </>
  );
};

export default HealthPlansToggle;
