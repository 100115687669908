import { AIAdjudication } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { storeCreator } from '@/utils/store/store.utils';

const INIT_STATE = {};

interface State {
  state: AIAdjudication;
  setAIAdjudication: (value: AIAdjudication) => void;
}

const useStore = storeCreator<State>((set) => ({
  state: INIT_STATE,
  setAIAdjudication: (newState: AIAdjudication) => set((oldState) => ({
    state: {
      ...oldState.state,
      ...newState,
    },
  })),
}));
const useAIAdjudicationStore = () => ({ ...useStore() });

export default useAIAdjudicationStore;
