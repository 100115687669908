import { useEffect,useState } from 'react';

import { OrganizationTypes } from '@/common/constants';
import { Option } from '@/common/types';

import { useGetOrganizationsQuery } from '../../SetupEmployer/queries';

const useGetParentOrganizations = (type: OrganizationTypes, enabled: boolean) => {
  const [search, setSearch] = useState('');
  const [organizations, setOrganizations] = useState<Option[]>([]);

  const {
    data,
    isLoading,
  } = useGetOrganizationsQuery(
    type,
    search,
    enabled,
  );

  useEffect(() => {
    setOrganizations(data as Option[]);
  }, [data]);

  return {
    organizations,
    isLoading,
    ...enabled ? {
      handleSetSearch: setSearch,
    } : {},
  };
};

export default useGetParentOrganizations;
