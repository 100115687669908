import { useMemo } from 'react';

import { useCurrentOrganization } from '@/modules/core/hooks';
import useGetOrgLocksQuery from '@/modules/employer/queries/useGetOrgLocks.query';
import { getIdsFromPath } from '@/utils/modifiers';

const useShowLockBanner = (): boolean => {
  const { observingOrganization } = useCurrentOrganization();
  const { employerId } = useMemo(() => getIdsFromPath(observingOrganization.path), [observingOrganization.path]);
  const { formattedData, isError } = useGetOrgLocksQuery(employerId);

  return useMemo(() => !isError && !!formattedData?.isLocked && !!formattedData?.hasReplenishedAccounts, [formattedData, isError]);
};

export default useShowLockBanner;
