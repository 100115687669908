import React from 'react';
import { combinedTheme as commonTheme,Grommet as CommonGrommet } from '@common-fe/common-fe';
import { Grommet } from 'grommet';

import { useCombinedTheme } from '@/modules/core/hooks/useCombinedTheme';

// import { ThemeProvider } from 'styled-components';
interface Props {
  children: React.ReactNode;
}
const ThemeWrapper: React.FC<Props> = ({ children }) => {
  // const router = useRouter();

  // const availableThemes = {
  //   // elevate,
  //   grommet,
  // };

  // const queryTheme = availableThemes[router.query.theme as string] || elevate;
  const combinedTheme = useCombinedTheme();

  return (
    <CommonGrommet theme={combinedTheme}>
      <Grommet theme={combinedTheme}>

        {children}

      </Grommet>
    </CommonGrommet>

  );
};
export default ThemeWrapper;
