import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DefaultSortTypesEnum,OptionKey, PaginationParams } from '@/common/types';

import { InvoiceStatus,InvoiceType, InvoiceTypeView } from '../types/invoicing.constants';
import { Invoice, InvoiceDto } from '../types/invoicing.types';

export interface InvoicesQueryParams extends Partial<PaginationParams> {
  types?: OptionKey[],
  statuses?: OptionKey[],
  dateRange?: Array<string | null>,
  sortBy?: DefaultSortTypesEnum,
}

const DEFAULT_INVOICE_TYPES = [
  InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT,
  InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT,
  InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS,
  InvoiceType.PLAN_LEVEL_REPLENISHMENT,
  InvoiceType.REPLENISHMENT,
];

const DEFAULT_INVOICE_STATUSES = [
  InvoiceStatus.PAYMENT_INITIATED,
  InvoiceStatus.PAYMENT_REQUESTED,
  InvoiceStatus.PAYMENT_REJECTED,
  InvoiceStatus.PAID,
  InvoiceStatus.PAYMENT_FAILED,
  InvoiceStatus.REOPEN,
];

const useInvoicesQuery = ({
  page,
  perPage,
  searchString,
  dateRange,
  statuses,
  types,
  sortBy,
}: InvoicesQueryParams) => {
  const [endedFrom, endedTo] = dateRange || [];
  const invoiceTypes = types?.length ? types : DEFAULT_INVOICE_TYPES;
  const invoiceStatuses = statuses?.length ? statuses : DEFAULT_INVOICE_STATUSES;

  const params = {
    page,
    size: perPage || 50,
    ended_from: endedFrom,
    ended_to: endedTo,
    ...(sortBy ? { sort: `invoiceStatuses.validFrom,${sortBy}` } : {}),
    ...(searchString ? { search_value: searchString } : {}),
    invoice_status_types: invoiceStatuses.map((status) => `${status || ''}`?.toUpperCase()).join(','),
    invoice_types: invoiceTypes.map((type) => `${type || ''}`?.toUpperCase()).join(','),
  };

  const {
    data, isLoading,
  } = useQuery(
    [
      PATHS.GET_INVOICES_DASHBOARD,
      page,
      perPage,
      searchString,
      sortBy,
      endedFrom,
      endedTo,
      invoiceStatuses,
      invoiceTypes,
    ], () => api.get(PATHS.GET_INVOICES_DASHBOARD, { params }),
  );

  const formattedData = useMemo(() => data?.data?.content?.map(
    (invoice: InvoiceDto): Invoice => ({
      amount: invoice.amount,
      endedAt: invoice.ended_at,
      invoiceId: toString(invoice.invoice_id),
      invoiceStatusType: invoice.invoice_status_type,
      invoiceType: invoice?.invoice_type ? InvoiceTypeView[`${invoice?.invoice_type}`] : '-',
      largeOrganizationLogo: invoice.large_organization_logo,
      organizationName: invoice.organization_name,
    }),
  ), [data]);

  const total = data?.data?.totalElements || 0;

  return {
    formattedData: formattedData || [],
    isLoading,
    total,
  };
};

export default useInvoicesQuery;
