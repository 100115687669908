import React from 'react';

import { ReportSection } from '@/modules/employer/components/Reports/components/ReportSection/ReportSection';

import useClaimsReports from './useClaimsReports';

const Claims = () => {
  const reports = useClaimsReports();

  return (
    <ReportSection
      title="Claims"
      reports={reports}
    />
  );
};

export default Claims;
