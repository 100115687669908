import React, { useCallback,useMemo } from 'react';
import { Field } from '@common-fe/common-fe';
import _ from 'lodash';

type ExpandedField = Field & {
  parentKey?:string;
};
export const useFieldsWithSubfields = (
  value: object, fields?: Field[], duplicatedKeys?: string[],
) => {
  const getFormatedFields = useCallback((
    currentFields: ExpandedField[], parentLabel?: string | React.ReactNode, parentName?: string,
  ) => currentFields.reduce(
    (result: Field[], item) => {
      if (item.parentValue && parentName && _.get(value, parentName) !== item.parentValue) {
        return result;
      }
      if (item.subFields) {
        const name = item.name as string;
        if (_.get(value, name)) {
          const list = getFormatedFields(item.subFields, item.label, name) as Field[];

          return [...result, {
            ...item,
            parentKey: item.parentValue ? parentName : '',
          },
          ...list];
        }
      }

      if (!item.label && parentLabel) {
        return [...result, {
          ...item,
          parentKey: item.parentValue ? parentName : '',
          label: parentLabel,
          // parentValue: parentName,
        }];
      }

      return [...result, item];
    }, [],
  ), [value]);
  const allFields = useMemo(() => getFormatedFields(fields || []), [fields, getFormatedFields]);

  const uniqueFields = useMemo(() => _.uniqBy(allFields, (o) => {
    const label = _.get(o, 'label') as string;
    if (duplicatedKeys && duplicatedKeys.includes(label)) {
      return _.uniqueId();
    }
    return label;
  }), [allFields, duplicatedKeys]);

  return {

    allFields,
    uniqueFields,
  };
};

export default useFieldsWithSubfields;
