import React, { useCallback, useMemo, useState } from 'react';
import {
  AppButton,
  Box, Field, FlexControlledForm, Inscription, OptionKey, Preloader,
  SearchSelectDropdown,
  SiblingBoxesWithSpacing,
  WarnModal,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { OrganizationTypes, Roles } from '@/common/constants';
import { Option } from '@/common/types';
import { useAddContactMutation } from '@/modules/contact/queries/useContacts.queries';
import { ContactStatuses } from '@/modules/contact/types/Contact.types';

import useTeamsListQuery from '../useTeamsList.query';

import {
  useCreateClaimProcessorFormFields,
  useCreateClaimProcessorModalState,
  useCurrentUserFields,
} from './hooks';

const ERROR_MODAL_TITLE = 'The server encountered an error processing the request';
const ERROR_MODAL_TEXT = `It doesn't appear to have affected your data, but we cannot save the Claim Processor with the latest records.
                    Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`;
const BUTTON_WIDTH = '180px';
const MIN_SEARCH_LENGTH = 3;

const AVAILABLE_PERMISSIONS = [
  { label: 'Claim Supervisor', value: Roles.claimsSupervisor },
  { label: 'Claim Processor Edit', value: Roles.claimsProcessorEdit },
  { label: 'Claim Processor Review', value: Roles.claimsProcessorReview },
];

export interface Props {
  onCancel: () => void;
  onSuccess?: (processorId?: string) => void;
  onDirty?: (value: boolean) => void;
}

export const CreateClaimProcessorModalForm: React.FC<Props> = ({
  onCancel,
  onSuccess,
  onDirty,
}) => {
  const [claimTeams, setClaimTeams] = useState<OptionKey[]>([]);
  const [teamSearchValue, setTeamSearchValue] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const {
    values,
    setValues,
    setup,
    setSetup,
  } = useCreateClaimProcessorModalState();

  const { teams, isLoading } = useTeamsListQuery({
    ...teamSearchValue && teamSearchValue.length >= MIN_SEARCH_LENGTH ? { searchString: teamSearchValue } : {},
    page: 0,
    perPage: 20,
  });

  const teamsOptions: Option[] = useMemo(() => teams?.map((team) => ({
    key: team.id,
    value: team.id,
    title: team.name,
  })) || [], [teams]);

  const { mutateAsync, isLoading: createProcessorLoading } = useAddContactMutation();

  const fields = useCreateClaimProcessorFormFields({}, AVAILABLE_PERMISSIONS);
  const currentUserFields = useCurrentUserFields();

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {

    setValues((
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prev: any,
    ) => ({ ...prev, ...values }));

    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFormValues = useCallback((values: object) => {
    changeValues(values, fields, setSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup]);

  const handleCreateContact = useCallback(async () => {
    if (!values) {
      return;
    }
    setIsSubmit(true);

    if (setup) {
      try {
        const newClaimProcessor = await mutateAsync({
          username: values.userName,
          role: values.permissions,
          title: values.title || null,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phoneNumber,
          department: values.department || null,
          primary: Boolean(values.primary),
          external_identifier: values.externalIdentifier || null,
          contact_type: OrganizationTypes.employer,
          contact_status_type: ContactStatuses.ACTIVE,
          ...claimTeams.length ? { claims_teams_ids: claimTeams.map((team) => +(team as string)) } : {},
        });
        if (onSuccess && newClaimProcessor.data.id) onSuccess(newClaimProcessor.data.id.toString());
        onCancel();
      } catch {
        setErrorModal(true);
      }
    }
  }, [
    values,
    mutateAsync,
    claimTeams,
    onSuccess,
    onCancel,
    setup,
    setIsSubmit,
  ]);

  return (
    <>
      <Box background="module" round="moduleRound" pad="medium" data-testid="CreateClaimProcessorModal-form-wrapper">
        <WarnModal
          visible={errorModal}
          onSetVisible={setErrorModal}
          header={ERROR_MODAL_TITLE}
          helptext={ERROR_MODAL_TEXT}
          buttonText="Close"
          onSubmit={handleCreateContact}
          submitButtonText="Try again"
        />
        <Box
          background="canvas"
          round="container1Round"
          margin={{ bottom: 'spacing24' }}
        >
          <FlexControlledForm
            fields={currentUserFields}
            isThinMode
            isModalType
          />
        </Box>
        <Box
          background="canvas"
          round="container1Round"
          border={{ color: 'border2' }}
        >
          <FlexControlledForm
            fields={fields}
            showError={isSubmit}
            editMode
            onChangeValues={handleChangeFormValues}
            onDirty={onDirty}
            isThinMode
            isModalType
            wrapperStyle={{ border: 'none' }}
            formStyle={{ marginBottom: 0 }}
          />

          <Box
            direction="row"
            height="40px"
            justify="between"
            pad={{ horizontal: 'spacing24' }}
            margin={{ bottom: 'spacing24', top: '-8px' }}
          >
            <Box margin={{ top: 'spacing8' }}>
              <Inscription weight="bold">Assign to claims team</Inscription>
            </Box>
            <SearchSelectDropdown
              options={teamsOptions}
              onChangeValues={setClaimTeams}
              values={claimTeams}
              onSearch={setTeamSearchValue}
              searchValue={teamSearchValue}
              isLoading={isLoading}
              placeholder="Assign to claims team"
              searchPlaceholder="Search for claims teams"
            />
          </Box>
        </Box>
      </Box>
      <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }}>

        <SiblingBoxesWithSpacing width="BUTTON_WIDTH">
          <AppButton
            testId="CreateClaimProcessorModal-cancel-create-mode"
            type="button"
            buttonType="secondary"
            width={BUTTON_WIDTH}
            onClick={onCancel}
          >
            Cancel
          </AppButton>
        </SiblingBoxesWithSpacing>
        <SiblingBoxesWithSpacing>
          <AppButton
            testId="CreateClaimProcessorModal-save-create-mode"
            disabled={createProcessorLoading}
            type="button"
            width={BUTTON_WIDTH}
            onClick={handleCreateContact}
          >
            {createProcessorLoading ? <Preloader color="white" /> : 'Confirm'}
          </AppButton>
        </SiblingBoxesWithSpacing>

      </Box>

    </>
  );
};
