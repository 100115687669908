import React, { useMemo,useState } from 'react';
import {
  AppButton,
  Box,
  CalendarPickerIcon,
  DateRangeDropdown,
  FilterIcon,
  FlexList,
  FlexListPlaceholder,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';

import { DefaultSortTypesEnum,ListItemBase } from '@/common/types';
import { checkIsAllOptionSelected,stateAllToggling } from '@/utils/handlers/dropdownStateAllToggling';
import { formatDateRange } from '@/utils/modifiers';

import { INVOICES_STATUSES_OPTIONS, INVOICES_TYPES_OPTIONS, SORTING_OPTIONS } from './types/invoicing.constants';
import { useInvoicesList, useListPagination } from './hooks';
import { useInvoicesQuery } from './queries';

const HEADERS: ListItemBase[] = [
  {
    key: 'invoiceOrganization',
    title: 'Organization',
    flex: 1.5,
  },
  {
    key: 'invoiceNumber',
    title: 'Number',
    flex: 0.7,
  },
  {
    key: 'invoiceType',
    title: 'Type',
    flex: 1.2,
  },
  {
    key: 'invoiceDate',
    title: 'Date',
    flex: 0.8,
  },
  {
    key: 'invoiceAmount',
    title: 'Amount',
    flex: 0.7,
  },
  {
    key: 'invoiceStatus',
    title: 'Status',
    flex: 1.2,
    style: {
      marginRight: '20px',
      marginLeft: '-20px',
      flexDirection: 'row-reverse',
    },
  },
];

const FinOpsInvoicesList = () => {
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    setStatuses,
    dateRange,
    setDateRange,
    types,
    setTypes,
    sortBy,
    setSortBy,
    statuses,
  } = useListPagination();

  const statusesAfterToggling = useMemo(() => stateAllToggling(statuses), [statuses]);
  const typesAfterToggling = useMemo(() => stateAllToggling(types), [types]);
  const isOptionAllSelected = useMemo(() => checkIsAllOptionSelected(statuses), [statuses]);
  const isTypesAllSelected = useMemo(() => checkIsAllOptionSelected(types), [types]);

  const {
    formattedData, total, isLoading,
  } = useInvoicesQuery({
    page: page - 1 < 0 ? 0 : page - 1,
    perPage,
    searchString: currentSearch,
    dateRange: formatDateRange(dateRange),
    statuses: isOptionAllSelected ? [] : statusesAfterToggling,
    types: isTypesAllSelected ? [] : typesAfterToggling,
    sortBy: sortBy as DefaultSortTypesEnum,
  });
  const invoicesList = useInvoicesList({ invoices: formattedData });

  return (
    <Box data-testid="invoices-list" background={{ color: 'module' }} round="container1Round">
      <Box>
        <Box
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: 'spacing24', top: 'spacing24', bottom: 'spacing4' }}
        >
          <Text
            weight="bold"
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
          >
            Invoices: {total}
          </Text>
          <Box direction="row">
            <Box width="240px">
              <SearchInput
                value={searchString}
                onChange={setSearchString}
                placeholder="Employer, Partner, Number"
                hasSearchingHistory
              />
            </Box>
            <Box width="180px" margin={{ left: 'spacing12' }}>
              <SelectDropdown
                id="status"
                prefix="Status"
                name="Invoice status"
                options={INVOICES_STATUSES_OPTIONS}
                values={statusesAfterToggling}
                onChangeValues={setStatuses}
                allMode
                activeTitle
                ellipsisMode
              />
            </Box>
            <Box width="180px" margin={{ left: 'spacing12' }}>
              <DateRangeDropdown
                prefix="Period:"
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={setDateRange}
                icon={(
                  <CalendarPickerIcon color="iconPrimary" />
                )}
                ellipsisMode
              />
            </Box>
            <Box margin={{ left: 'spacing12' }}>
              <AppButton
                testId="toggle-filter"
                onlyIcon
                onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                color="grey"
                buttonType="secondary"
                active={isFiltersVisible}
                style={{ width: 40 }}
              >
                <FilterIcon />
              </AppButton>
            </Box>
          </Box>
        </Box>
        {isFiltersVisible && (
          <Box
            data-testid="invoices-filters"
            direction="row"
            justify="between"
            align="center"
            pad={{ horizontal: 'spacing24', top: 'spacing12' }}
          >
            <Text>
              Filters
            </Text>
            <Box direction="row">
              <Box width="180px">
                <SelectDropdown
                  id="type"
                  prefix="Type"
                  name="Invoice types"
                  options={INVOICES_TYPES_OPTIONS}
                  values={typesAfterToggling}
                  onChangeValues={setTypes}
                  allMode
                  activeTitle
                  ellipsisMode
                />
              </Box>
              <Box width="180px" margin={{ left: 'spacing12' }}>
                <SelectDropdown
                  id="sort_by"
                  disabled={false}
                  name="Sort by"
                  prefix="Sort by"
                  singleMode
                  value={sortBy}
                  onChange={setSortBy}
                  activeTitle
                  options={SORTING_OPTIONS}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <FlexList
        testId="fin-ops-invoices-list"
        headers={HEADERS}
   
        rows={invoicesList}
        loading={isLoading}
        total={total}
        moreCount={perPage}
        placeholder={(<FlexListPlaceholder />)}
        footer={(
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
      />
    </Box>
  );
};

export default FinOpsInvoicesList;
