import React, { useCallback, useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import ModalWrapper from '@/modules/employer/components/SetupEmployer/ModalWrapper';
import { PlanYear } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';
import { PlanYearFormPayload,useStore } from '@/modules/plan/PlanYearForm/stores/usePlanYearForm.store';

import PlanYearModal from './PlanYearModal';

const DISABLED_DEFAULT_PROPERTY = 'payoutDefinition';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  isEditMode?: boolean;
  selectedPlan?: PlanYear;
  isCreateMode?: boolean;
}
const CONFIRM_TEXT = 'Changes that you made may not be saved.';
const PlanYearModalWrapper: React.FC<Props> = ({
  modalTitle,
  onSetVisible,
  visible,
  isEditMode,
  isCreateMode,
  selectedPlan,
}) => {
  const formState = useStore((state) => state.state);
  const isFormDirty = useMemo(
    () => (Object.keys(formState) as Array<keyof PlanYearFormPayload>)
      .filter((key) => key !== DISABLED_DEFAULT_PROPERTY && formState[key])
      ?.length !== 0,
    [formState],
  );
  const onSetVisibleHandler = useCallback((value?: boolean) => {
    if (value === false && isEditMode && isFormDirty) {
      return window.confirm(CONFIRM_TEXT) && onSetVisible(value);
    }
    return onSetVisible(Boolean(value));
  }, [onSetVisible, isEditMode, isFormDirty]);

  return (
    <Box direction="row" align="center">
      <ModalWrapper
        visible={visible}
        title={modalTitle}
        onSetVisible={onSetVisibleHandler}
      >
        <PlanYearModal
          isCreateMode={isCreateMode}
          selectedPlan={selectedPlan}
          onCancel={(value?: boolean) => onSetVisibleHandler(value)}
          isEditMode={isEditMode}
          includeLogHeader
        />
      </ModalWrapper>
    </Box>
  );
};

export default PlanYearModalWrapper;
