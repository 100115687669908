import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Field,
  FieldTypes,
} from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import Permissions from '@/common/permissions';
import { DefaultFields } from '@/common/types';
import { useHasAccess } from '@/modules/core/hooks';
import { HealthPlanFieldState, HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { useHealthPlanTypes } from '@/modules/HealthPlan/hooks';
import { useHealthTemplatesListQuery } from '@/modules/HealthPlan/queries';

import usePlanTemplateStore, { PlanTemplatePayload } from '../stores/usePlanTemplate.store';

const TEMPLATE_NAME_KEY = 'templateName';
export default (defaultFields?: Partial<PlanTemplatePayload>) => {
  const [defaultValues, setDefaultValues] = useState<Partial<PlanTemplatePayload>>({});
  const parentTemplate = usePlanTemplateStore((state) => state.state.parentTemplate);
  const [searchValue, setSearchValue] = useState('');

  const handleSetState = usePlanTemplateStore((state) => state.setState);
  const planTypes = useHealthPlanTypes();

  const {
    options: healthPlanTemplateOptions,
    isLoading,
  } = useHealthTemplatesListQuery({
    refetchOnMount: true,
    status: HealthPlanStatuses.ACTIVE,
    id: parentTemplate,
    searchTemplateString: searchValue,
  });

  const isViewHidden = useHasAccess([
    {
      permission: Permissions.VIEW_HIDDEN_PLAN_FIELDS,
    },
  ]);

  const currentTemplate = useMemo(() => {
    if (parentTemplate) {
      return healthPlanTemplateOptions.find((item) => item.key === parentTemplate);
    }
    return null;
  }, [parentTemplate, healthPlanTemplateOptions]);

  useEffect(() => {
    if (currentTemplate) {
      handleSetState({
        parentTemplate,
        planTypes: currentTemplate?.accountTypes,
        templateName: currentTemplate?.name,
      });
      setDefaultValues({
        planTypes: currentTemplate?.accountTypes,
        templateName: currentTemplate?.name,
      });
    }
  }, [currentTemplate, handleSetState, parentTemplate]);

  const isNameHidden = useMemo(
    () => currentTemplate?.nameState === HealthPlanFieldState.Hidden && !isViewHidden,
    [currentTemplate, isViewHidden],
  );

  const PLAN_TYPE_KEY = 'planTypes';
  let fields = useMemo<Field[]>(() => [
    {
      name: 'parentTemplate',
      type: FieldTypes.AutocompleteDropdown,
      label: 'Parent template',
      onSearch: setSearchValue,
      options: healthPlanTemplateOptions,
      groupTitle: 'Categories',
      placeholder: 'Select a health plan template',
      isSingleMode: false,
      isNarrowLabelMode: true,
      maxOptionsCount: 1000,
      isLoading,
    },
    {
      name: TEMPLATE_NAME_KEY,
      type: FieldTypes.Text,
      label: 'Health plan template name',
      disabled: currentTemplate?.nameState === HealthPlanFieldState.Locked
      || (isViewHidden && currentTemplate?.nameState === HealthPlanFieldState.Hidden),
      placeholder: 'Enter health plan template name',
      defaultValue: defaultValues.templateName,
      showRequireIcon: true,
      validator: VALIDATORS.REQUIRED_STRING,
    },
    {
      name: PLAN_TYPE_KEY,
      type: FieldTypes.MultipleDropdown,
      label: 'Health plan type',
      value: parentTemplate ? defaultFields?.planTypes : '',
      disabled: currentTemplate?.accountTypeState === HealthPlanFieldState.Locked
      || currentTemplate?.accountTypeState === HealthPlanFieldState.Hidden,
      options: planTypes,
      singleMode: false,
      defaultValue: defaultValues.planTypes,
      placeholder: 'Select a health plan type',
      showRequireIcon: true,
      validator: VALIDATORS.REQUIRED_ARRAY,
    },

  ], [healthPlanTemplateOptions, isLoading, currentTemplate?.nameState, currentTemplate?.accountTypeState, isViewHidden, defaultValues.templateName, defaultValues.planTypes, parentTemplate, defaultFields?.planTypes, planTypes]);
  if (currentTemplate?.accountTypeState === HealthPlanFieldState.Hidden && !isViewHidden) {
    fields = fields.filter((field) => field.name !== PLAN_TYPE_KEY);
  }
  if (isNameHidden) {
    fields = fields.filter((field) => field.name !== TEMPLATE_NAME_KEY);
  }
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const currentDefaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: currentDefaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
