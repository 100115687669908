import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { QUERY_KEY } from '@/modules/employer/hooks/useEmployersList.query';

import { ExpressSetupStatuses } from '../ExpressSetup.types';

interface Params {
  id?: string
  status?: ExpressSetupStatuses,
}

export const useUpdateExpressSetupStatusByIdQuery = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation((params?: Params) => api.patch(`${PATHS.EXPRESS_SETUP_STATUS_BY_ID(params?.id || '')}?status=${params?.status}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
    },
  });

  const handleSave = async (params?: Params) => {
    await mutateAsync(params);
  };

  return {
    handleSave,
    isLoading,
    isSuccess,
    isError,
  };
};
