import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router';
import {
  AppButton,
  Box, CloseCircleIcon,
  ErrorModal,   LockIcon, Preloader, Text, UnlockIcon, } from '@common-fe/common-fe';

import ModalWrapper from '@/components/wrappers/ModalWrapper';
import { useSnackbar } from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import { CardStatusEnum } from '@/modules/transaction/Cards/Cards.types';
import useGetCard from '@/modules/transaction/Cards/hooks/useGetCard';
import { useChangeCardStatus, useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import { useSetCardStatusModal } from '@/modules/transaction/Cards/store/useSetCardStatusModal.store';

import { CardItem } from '../CardItem';
import CloseCardConfirmationModal from '../CloseCardConfirmationModal';

import {
  CardItemWrapper,
  CardLoggedTooltip,
  CardStatusActionWrap,
} from './ChangeStatusCardModal.styles';

export const ChangeStatusCardModal: React.FC = () => {
  const {
    state: { cardId, visible: visibilityModal }, handleReset,
  } = useSetCardStatusModal();
  const { observingOrganizationData } = useOrganizationOverridableProperties();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const [closeCardConfirmation, setCloseCardConfirmation] = useState(false);
  const [closeCardSuccess, setCloseCardSuccess] = useState(false);
  const { id: employeeId } = useParams<{ id?: string }>();
  const { card, isLoading, refetch } = useGetCard({ employeeId, cardId });
  const [cardStatus, setCardStatus] = useState(card?.status);
  const { refetch: refetchCardList } = useGetCardsQuery();
  const [hideVisibility, setHideVisibility] = useState(false);
  const visible = useMemo(() => (hideVisibility ? false : visibilityModal),
    [visibilityModal, hideVisibility]);

  const {
    mutateAsync: changeStatusMutation,
    isLoading: changeStatusMutationLoading,
  } = useChangeCardStatus();
  const lockBtnText = useMemo(() => (cardStatus === CardStatusEnum.FROZEN
    ? 'Unlock Card'
    : 'Lock Card'), [cardStatus]);
  const loadingButton = useMemo(() => changeStatusMutationLoading || isLoading,
    [
      changeStatusMutationLoading,
      isLoading,
    ]);

  const changeStatusMutationHandler = useCallback(async (status: CardStatusEnum) => {
    if (card) {
      await changeStatusMutation({ id: cardId, status });
      refetch();
      refetchCardList();
    }
  }, [card, cardId, changeStatusMutation, refetch, refetchCardList]);

  // temporary for mocked data
  // const changeStatusMutationHandler = (value: CardStatusEnum) => {
  //   setCardStatus(value);
  // };

  const handleCloseErrorModal = useCallback((value: boolean) => {
    handleAddPermanentSnackbar({
      text: `Card ends ${card?.last4Number} was closed!`,
      closeIcon: true,
    });
    setCloseCardSuccess(value);
    setHideVisibility(false);
    handleReset();
  }, [card.last4Number, handleAddPermanentSnackbar, handleReset]);

  useEffect(() => {
    setCardStatus(card?.status);
  }, [visible, card]);

  if (!card) {
    return null;
  }
  return (
    <>
      <ModalWrapper visible={visible} onSetVisible={handleReset}>
        <Box height={{ min: '42px' }} />
        <Box background="module" round="container1Round" align="center" justify="end">
          {card
            ? (
              <CardItemWrapper margin={{ top: '-135px', bottom: 'spacing24' }}>
                {cardStatus === CardStatusEnum.FROZEN
                  ? (
                    <CardLoggedTooltip direction="row" justify="center">
                      <Box
                        justify="center"
                        align="center"
                        direction="row"
                        background="danger"
                        round="checkboxRound"
                        pad={{ horizontal: 'spacing24', vertical: 'spacing8' }}
                      >
                        <LockIcon size="small" color="iconAccent" />
                        <Text size="16px" weight="bold" margin={{ left: 'spacing8' }} color="textOnColor">Your card is locked</Text>
                      </Box>
                    </CardLoggedTooltip>
                  ) : null}
                <CardItem
                  borderColor="accentActive"
                  logoUrl={observingOrganizationData?.logoInfo?.darkLargeLogoUrl}
                  active
                  card={card}
                />
              </CardItemWrapper>
            )
            : null}
          <Text weight="bold" size="xxlarge" margin={{ bottom: 'spacing24' }}>What would you like to do?</Text>
          <Box direction="row" align="start" pad={{ horizontal: 'spacing24', bottom: 'spacing24' }}>
            <CardStatusActionWrap
              background="canvas"
              round="container1Round"
              pad={{ horizontal: 'spacing24', top: 'spacing32', bottom: '28px' }}
              margin={{ right: 'spacing24' }}
              align="center"
              border={{ color: 'border2' }}
              width={{ max: '300px' }}
            >
              {
                cardStatus === CardStatusEnum.FROZEN
                  ? <UnlockIcon size="xlarge" color="iconAccent" />
                  : <LockIcon size="xlarge" color="iconAccent" />
              }
              <Text
                weight="bold"
                margin={{ top: 'spacing24', bottom: 'spacing8' }}
              >
                {cardStatus === CardStatusEnum.FROZEN
                  ? 'Unlock Card'
                  : 'Lock Card'}
              </Text>
              <Text
                textAlign="center"
                margin={{ bottom: 'spacing24' }}
              >
                You can unlock this card at any time.
              </Text>
              <AppButton
                buttonType="primary"
                type="submit"
                style={{ minWidth: '120px', maxWidth: '120px', paddingInline: '12px' }}
                disabled={loadingButton}
                onClick={() => changeStatusMutationHandler(
                  cardStatus === CardStatusEnum.FROZEN
                    ? CardStatusEnum.ACTIVE
                    : CardStatusEnum.FROZEN,
                )}
              >
                {
                  loadingButton
                    ? (
                      <Box width="100%" align="center">
                        <Preloader color="white" />
                      </Box>
                    )
                    : lockBtnText
                }
              </AppButton>
            </CardStatusActionWrap>
            <CardStatusActionWrap
              background="canvas"
              round="container1Round"
              pad={{ horizontal: 'spacing24', top: 'spacing32', bottom: '28px' }}
              align="center"
              border={{ color: 'border2' }}
              width={{ max: '300px' }}
            >
              <CloseCircleIcon />
              <Text
                weight="bold"
                margin={{ top: 'spacing24', bottom: 'spacing8' }}
              >
                Close Card
              </Text>
              <Text textAlign="center" margin={{ bottom: 'spacing24' }}>
                {card?.primary
                  ? 'You cannot close your primary account card, only replace it.'
                  : 'You cannot reopen this card after closing it.'}
              </Text>
              <AppButton
                buttonType="primary"
                color="red"
                style={{
                  minWidth: '120px',
                  maxWidth: '120px',
                  paddingInline: '12px',
                  border: 'none',
                }}
                disabled={loadingButton || card?.primary}
                onClick={() => {
                  setCloseCardConfirmation(true);
                  setHideVisibility(true);
                }}
              >
                {
                  loadingButton
                    ? (
                      <Box width="100%" align="center">
                        <Preloader color="white" />
                      </Box>
                    )
                    : 'Close Card'
                }
              </AppButton>
            </CardStatusActionWrap>
          </Box>
        </Box>
      </ModalWrapper>
      <CloseCardConfirmationModal
        header="Are You Sure That You Want to Close This Card?"
        helpText="You cannot reopen this card after closing it."
        buttonText="Confirm"
        visible={closeCardConfirmation}
        onSetVisible={() => {
          setCloseCardConfirmation(false);
          setHideVisibility(false);
        }}
        onCancel={() => {
          setCloseCardConfirmation(false);
          setHideVisibility(false);
        }}
        submitting={changeStatusMutationLoading}
        onSubmit={async () => {
          await changeStatusMutationHandler(CardStatusEnum.CLOSED);
          setCloseCardConfirmation(false);
          setCloseCardSuccess(true);
          setHideVisibility(true);
        }}
      />
      <ErrorModal
        visible={closeCardSuccess}
        onSetVisible={handleCloseErrorModal}
        header={`Card Ends ${card?.last4Number} Was Closed!`}
        buttonText="Got It!"
      />
    </>
  );
};
