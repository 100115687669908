import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { Topbar } from '@/modules/core/components';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { lockboxTypeTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import {
  LockboxDetailsHeader,
  LockboxDocumentPreview,
  LockboxInfo,
} from '@/modules/employer/components/Lockbox/LockboxDetails/components';
import LockboxInfoFooter from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoFooter/LockboxInfoFooter';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import useGetLockboxQuery from '@/modules/employer/components/Lockbox/queries/useGetLockbox.query';

interface Props {
  lockboxId: string;
}

const LockboxDetails: React.FC<Props> = ({ lockboxId }) => {
  const { formattedData: lockbox, isFetching, isLoading } = useGetLockboxQuery({ lockboxId });
  const { originalLockbox, setEditableLockbox, setOriginalLockbox, reset } = useLockboxDetailsStore();

  const lockboxType = lockbox?.lockboxType ? lockboxTypeTitle[lockbox?.lockboxType] : '';
  const lockboxName = `${lockbox?.employeeName || ''} ${lockboxType || ''}`;

  useBreadcrumbs([
    {
      route: ROUTES.FINOPS,
      title: 'FinOps',
    },
    {
      route: ROUTES.FINOPS_LOCKBOXES,
      title: 'Lockbox',
    },
    {
      title: lockboxName,
    }
  ], [lockboxName]);
  
  useLayoutEffect(() => {
    if (!isFetching) {
      reset();
      setEditableLockbox({
        lockboxId: lockbox?.id,
        lockboxType: lockbox?.lockboxType,
        amount: lockbox?.amount || lockbox?.ocr?.amount,
        employeeId: lockbox?.employeeId,
        employeeAccountStatus: lockbox?.matchEmployees
          ?.find((matchedEmployee) => matchedEmployee?.employeeId === lockbox?.employeeId)?.accountStatus,
        lockboxStatus: lockbox?.lockboxStatus,
        vendorId: lockbox?.checkDetails?.vendorId,
      });
      setOriginalLockbox(lockbox);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    return () => {
      reset();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLockboxLoading = useMemo(() => isFetching || lockboxId !== originalLockbox?.id,
    [isFetching, originalLockbox, lockboxId]);
  
  return (
    <Box height="inherit">
      <Topbar isFull />
      <Box height="inherit">
        <LockboxDetailsHeader
          lockboxId={lockboxId}
          lockboxName={lockboxName}
          lockboxStatus={lockbox?.lockboxStatus}
        />
        {
          isLoading ? (
            <Preloader testId="lockbox-details-preloader" />
          ) : (
            <Box
              data-testId="lockbox-details-content"
              direction="row"
              background="module"
              height="inherit"
            >
              <Box basis="1/2">
                <LockboxDocumentPreview documentId={lockbox?.documentId} />
              </Box>
              {isLockboxLoading ? (
                <Preloader testId="lockbox-details-preloader" />
              ) : (
                <Box
                  basis="1/2"
                  overflow={{ vertical: 'auto' }}
                  data-testId="lockbox-details-form-wrapper"
                >
                  <Box height={{ min: 'auto' }}>
                    <LockboxInfo lockbox={lockbox} />
                  </Box>
                  <LockboxInfoFooter />
                </Box>
              )}
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default LockboxDetails;
