import React, { useCallback, useEffect, useState } from 'react';
import { Box, FlexControlledForm, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';
import * as yup from 'yup';

import AppButton from '@/components/controls/AppButton';
import { PaymentMethodOwnerType, PaymentMethodType } from '@/modules/employee/employee.types';

import useAddPaymentMethodQuery from '../useAddPaymentMethod.query';
import { useUpdatePaymentMethodQuery } from '../useUpdatePaymentMethod.query';

import { PayPalInfo, usePayPalFields } from './hooks/usePayPalFields';

const BUTTON_WIDTH = '150px';

const StyledBox = styled(Box)`
  ${({ theme }) => theme.border.blueGray2}
  ${({ theme }) => theme.shadows.default}
`;

interface Props {
  onBack?: () => void;
  onSuccess?: () => void;
  editableMethodId?: string;
  allowSaveAsDefault?: boolean;
  onError?: () => void;
}

export const AddPayPalForm: React.FC<Props> = ({
  onBack,
  onSuccess,
  editableMethodId,
  allowSaveAsDefault,
  onError,
}) => {
  const { save, isLoading, isSuccess } = useAddPaymentMethodQuery(onSuccess);
  const {
    updatePaymentMethod,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdatePaymentMethodQuery(onSuccess);
  const [formValues, setFormValues] = useState<PayPalInfo>({});
  const fields = usePayPalFields(formValues);
  const [activeValues, setActiveValues] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChangeValues = useCallback((values: PayPalInfo) => {
    setFormValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setActiveValues(true);
    } catch {
      setActiveValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.phoneNumber, formValues.email]);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitted(true);
      if (activeValues && onSuccess) {
        if (editableMethodId) {
          await updatePaymentMethod({
            paymentMethodId: editableMethodId,
            paymentType: PaymentMethodType.PAYPAL,
            payPalData: formValues,
          });
        } else {
          await save({
            paymentType: PaymentMethodType.PAYPAL,
            paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
            payPalData: formValues,
            isDefault: true,
          });
        }
      }
    } catch (e) {
      // @ts-ignore
      if (onError && e.response.status === 409) {
        onError();
      }
    }
  }, [activeValues, editableMethodId, formValues, onSuccess, save, updatePaymentMethod, onError]);

  useEffect(() => {
    if (onSuccess && (isUpdateSuccess || isSuccess)) onSuccess();
  }, [isSuccess, isUpdateSuccess, onSuccess]);

  return (
    <>
      <Box
        pad="spacing24"
        round="moduleRound"
        background={{ color: 'module' }}
      >
        <StyledBox
          round="container1Round"
          background={{ color: 'canvas' }}
        >
          <FlexControlledForm
            fields={fields}
            showError={submitted}
            editMode
            onChangeValues={handleChangeValues}
          />
        </StyledBox>
      </Box>
      <Box pad={{ top: 'medium' }} direction="row" align="center">
        <Box direction="row" align="center" gap="spacing12" margin={{ left: 'auto' }}>
          <AppButton
            testId="PayPal_form_back"
            buttonType="secondary"
            type="button"
            width={BUTTON_WIDTH}
            onClick={onBack}
          >
            {editableMethodId ? 'Cancel' : 'Back'}
          </AppButton>
          <AppButton
            testId="PayPal_form_submit"
            type="button"
            width={BUTTON_WIDTH}
            onClick={handleSubmit}
          >
            {(isLoading || isUpdateLoading) ? <Preloader color="white" /> : 'Submit'}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};
