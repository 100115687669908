import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AccordionItem, Box, Checkbox, Inscription, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import BoldSubstring from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/components/BoldSubstring';
import useGetOrganizationQuery, { Organization } from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetOrganization.query';

import { ClaimTeamsHierarchyResponseDto } from '../../queries/useClaimTeamsHierarchy.query';
import { TeamOrganization } from '../ClaimsProcessors.types';

import { getTotalString, mergeOrgsArrays } from './MultipleOrganizationSelector.utils';

interface StyledItemProps {
  isChecked?: boolean;
}

export const StyledItem = styled(Box)<StyledItemProps>`
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
    span {
      color: ${({ theme }) => theme.colors.textAccent};
      ${({ isChecked }) => isChecked && 'font-weight: bold;'};
    }
  }
`;

export const AccordionItemWrapper = styled(Box)`
  min-height: 24px;

  svg[aria-label="Chevron Up"] {
    transform: rotate(180deg);
  }
  svg[aria-label="Chevron Down"] {
    transform: rotate(270deg);
  }
  svg[aria-label="Chevron Up"], svg[aria-label="Chevron Down"] {
    fill: ${({ theme }) => theme.colors.iconPrimary};
  }
`;

interface LoadingProps {
  testId?: string;
}

export const SmallPreloader: React.FC<LoadingProps> = ({ testId }) => (
  <Box margin={{ left: 'spacing4' }}>
    <div>
      <Preloader testId={testId} size={12} />
    </div>
  </Box>
);

export interface CheckedChildren {
  parentId: string;
  children: string[];
}

interface Props {
  orgLevels: OrganizationTypes[];
  onChange: (
    organization: TeamOrganization,
    childCheck?: (id: string) => void,
    parentId?: string,
    checkedChildren?: CheckedChildren[],
  ) => void;
  selectedOrgs: TeamOrganization[];
  currentHightLevel?: OrganizationTypes;
  searchedOrgs?: Organization[];
  parentId?: string;
  isSearchUsed?: boolean;
  searchValue?: string;
  isShown?: boolean;
  isLoading?: boolean;
  onExternalLoading?: (value: boolean) => void;
  isLevelUnpicked?: boolean;
  onChildChecked?: (id: string, parentId?: string) => void;
  checkedChildren?: CheckedChildren[];
  onFoldingItem?: () => void;
  pickedTreeIds?: string[];
  pickedParentOrgs?: ClaimTeamsHierarchyResponseDto[];
  pickedChildrenLevel?: OrganizationTypes;
}

export const NestedOrganizationSelectorLevel: React.FC<Props> = ({
  orgLevels,
  parentId,
  onChange,
  selectedOrgs,
  searchedOrgs,
  isSearchUsed,
  searchValue,
  isShown,
  isLoading,
  onExternalLoading,
  isLevelUnpicked,
  currentHightLevel,
  onChildChecked,
  checkedChildren,
  onFoldingItem,
  pickedTreeIds,
  pickedParentOrgs,
  pickedChildrenLevel,
}) => {
  const [mockedLoading, setMockedLoading] = useState(false);
  const currentLevel = useMemo(() => {
    switch (orgLevels[0]) {
    case OrganizationTypes.company:
      return 'Employers';
    case OrganizationTypes.distributor:
      return 'Distributors';
    case OrganizationTypes.partner:
      return 'Partners';
    default:
      return '';
    }
  
  }, [orgLevels]);

  const findOrganizationTree = (parentId: string, data?: Organization[]): Organization[] | undefined => {
    if (!data) return undefined;
    const findOrganization = (node: Organization): Organization | undefined => {
      if (+node.id === +parentId) {
        return node;
      } else if (node.children) {
        for (const child of node.children) {
          const result = findOrganization(child);
          if (result) {
            return result;
          }
        }
      }
      return undefined;
    };

    for (const org of data) {
      const parentOrganization = findOrganization(org);
      if (parentOrganization) {
        return parentOrganization.children;
      }
    }
    return undefined;
  };

  const data = useGetOrganizationQuery({
    type: orgLevels[0],
    ...isSearchUsed ? { searchValue } : {},
    enabled: !!isShown && !isLoading && !searchedOrgs,
    parentId,
  });

  const preparedData = useMemo(() => {

    if (searchedOrgs && searchedOrgs.length) return searchedOrgs;

    if (pickedParentOrgs
      && pickedParentOrgs.length
      && pickedChildrenLevel === orgLevels[0]
    ) return mergeOrgsArrays(data.organizations, pickedParentOrgs);
  
    return data.organizations;
  }, [
    searchedOrgs,
    data.organizations,
    pickedParentOrgs,
    pickedChildrenLevel,
    orgLevels,
  ]);

  const isOrgChecked = useCallback((id: string) => selectedOrgs.some((org) => org.id === id), [selectedOrgs]);
  const isLastItem = useMemo(() => orgLevels.length === 1, [orgLevels]);
  const isHightLevel = useMemo(() => currentHightLevel && orgLevels[0] === currentHightLevel, [orgLevels, currentHightLevel]);
  const isChildrenChecked = (parentId: string) => {
    if (!checkedChildren) return false;
  
    return checkedChildren.some(child => child.parentId === parentId);
  };

  const parentIndeterminateMapper = useCallback((childId: string, parentId?: string) => {
    if (!selectedOrgs) return;
    const isChildSelected = selectedOrgs.some(org => org.id === childId);
    const isParentIndeterminate = checkedChildren?.some(child => child.parentId === parentId);

    if (isChildSelected && !isParentIndeterminate) {
      onChildChecked && onChildChecked(childId, parentId);
    }
  }, [checkedChildren, onChildChecked, selectedOrgs]);

  const isOrgCheckedInTree = useCallback((id: string) => {
    const isOrgCheckedInTree = pickedTreeIds?.some((orgId) => orgId === id);

    return !!isOrgCheckedInTree;
  }, [pickedTreeIds]);

  useEffect(() => {
    if (onExternalLoading) {
      onExternalLoading(data.isLoading);
    }
  }, [data.isLoading, onExternalLoading]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isShown) {
      setMockedLoading(true);
      timeout = setTimeout(() => {
        setMockedLoading(false);
      }, 10);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isShown]);

  useEffect(() => {
    if (!isLevelUnpicked) {
      preparedData?.forEach((child) => {
        parentIndeterminateMapper(child.id, parentId);
      });
    }
  }, [isLevelUnpicked, parentId, parentIndeterminateMapper, preparedData]);

  return (
    <Box height={{ min: 'fit-content' }}>
      <Box margin={{ left: isHightLevel ? 'spacing2' : '34px', top: '9px' }}>
        <Inscription
          color="textSecondary"
          size="12px"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {currentLevel}: {
            data.isLoading 
              ? (
                <SmallPreloader testId={`${orgLevels[0]}_loading`}/>
              )
              : `(${isHightLevel
                ? 1
                : getTotalString(searchedOrgs?.length || data.total, !!isSearchUsed)
              })`
          }
        </Inscription>
      </Box>
      {isLastItem
        ? (
          <>
            {preparedData.map((item) => (
              <StyledItem
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isLevelUnpicked) {
                    onChange(item, onChildChecked, parentId, checkedChildren);
                  }
                }}
                pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
                margin={{ left: '54px', top: '9px' }}
                round="xxs"
                direction="row"
                isChecked={isOrgChecked(item.id)}
                id={`${item.id}_checked-${isOrgChecked(item.id)}`}
              >
                {isLevelUnpicked ? null : (
                  <Checkbox
                    name={`company-level-${item.id}`}
                    onChange={() => onChange(item, onChildChecked, parentId, checkedChildren)}
                    checked={isOrgChecked(item.id)}
                    manualIndeterminate={isChildrenChecked(item.id) && !isOrgChecked(item.id)}
                  />
                )}
                <Box>
                  <BoldSubstring
                    text={item?.name}
                    substring={searchValue}
                    title={item?.name}
                  />
                </Box>
              </StyledItem>
            ))}
          </>
        ) : (
          <>
            {!mockedLoading && preparedData.map((item) => (
              <AccordionItemWrapper
                key={item.id}
                pad={{ left: isHightLevel ? undefined : 'spacing32' }}
                margin={{ top: '9px' }}
              >
                <AccordionItem
                  testId={`${orgLevels[0]}-level-${item.id}`}
                  onChange={() => {
                    if (onFoldingItem) {
                      onFoldingItem();
                    }}}
                  text={(
                    <StyledItem
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isLevelUnpicked) {
                          onChange(item, onChildChecked, parentId, checkedChildren);
                        }
                      }}
                      pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
                      margin={{ left: 'spacing2' }}
                      round="xxs"
                      direction="row"
                      width="100%"
                      isChecked={isOrgChecked(item.id)}
                      id={`${item.id}_checked-${isOrgChecked(item.id)}`}
                    >
                      {isLevelUnpicked ? null : (
                        <Checkbox
                          name={`${orgLevels[0]}-level-${item.id}`}
                          onChange={() => onChange(item, onChildChecked, parentId, checkedChildren)}
                          checked={isOrgChecked(item.id)}
                          manualIndeterminate={isChildrenChecked(item.id) && !isOrgChecked(item.id)}
                        />
                      )}
                      <Box>
                        <BoldSubstring
                          text={item?.name}
                          substring={searchValue}
                          title={item?.name}
                        />
                      </Box>
                    </StyledItem>
                  )}
                  isLeftArrow
                  isExpandedDefault={isOrgCheckedInTree(item.id) || isHightLevel}
                >
                  <NestedOrganizationSelectorLevel
                    orgLevels={orgLevels.slice(1)}
                    parentId={item.id}
                    onChange={onChange}
                    selectedOrgs={selectedOrgs}
                    searchedOrgs={findOrganizationTree(item.id, searchedOrgs)}
                    isSearchUsed={isSearchUsed}
                    searchValue={searchValue}
                    isShown={isShown}
                    isLoading={isLoading}
                    onExternalLoading={onExternalLoading}
                    isLevelUnpicked={isOrgChecked(item.id) || isLevelUnpicked}
                    onChildChecked={onChildChecked}
                    checkedChildren={checkedChildren}
                    onFoldingItem={onFoldingItem}
                    pickedTreeIds={pickedTreeIds}
                    pickedParentOrgs={pickedParentOrgs}
                    pickedChildrenLevel={pickedChildrenLevel}
                  />
                </AccordionItem>
              </AccordionItemWrapper>
            ))}
          </>
        )}
    </Box>
  );
};
