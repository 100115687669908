import React, { useMemo } from 'react';
import {
  Box,
  BrowserNavigationLeftIcon,
  BrowserNavigationRightIcon,
  BrowserRefreshIcon,
  Inscription,
  LockFilledIcon
} from '@common-fe/common-fe';

import { REGEXPS } from '@/common';

import { AddressLine } from './FakeBrowserImage.styles';

const DEFAULT_ORGANIZATION_NAME = 'user';

interface Props {
  organizationName?: string;
}

const FakeBrowserAddressLine:React.FC<Props> = ({
  organizationName,
}) => {
  const domainExample = useMemo(() => {
    if (organizationName) {
      const domainPreparedName = organizationName.replace(REGEXPS.NON_ALPHANUMERIC, '');
      return domainPreparedName.split(' ').join('-').toLowerCase();
    }
    return DEFAULT_ORGANIZATION_NAME;
  }, [organizationName]);

  return (
    <Box
      background="canvas"
      direction="row"
      align="center"
      pad={{ vertical: 'spacing2' }}
    >
      <Box
        pad={{ left: 'spacing8', right: '14px' }}
        direction="row"
        align="center"
      >
        <BrowserNavigationLeftIcon color="iconSecondary" />
        <BrowserNavigationRightIcon color="iconSecondary" style={{ opacity: 0.25 }} />
        <Box margin={{ left: 'spacing12' }}>
          <BrowserRefreshIcon color="iconSecondary" size="18px" />
        </Box>
      </Box>

      <AddressLine
        background="border2"
        pad={{ horizontal: 'spacing8', vertical: '5px' }}
        direction="row"
        align="center"
        basis="1"
        flex
      >
        <LockFilledIcon size="20px" color="iconSecondary" />
        <Inscription
          margin={{ left: 'spacing6' }}
          color="textSecondary"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          lineHeight="20px"
        >
          {`${domainExample}-domain.com`}
        </Inscription>
      </AddressLine>
    </Box>
  );
};

export default FakeBrowserAddressLine;
