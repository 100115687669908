import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box, ChevronLeftIcon, ChevronRightIcon, Text } from '@common-fe/common-fe';
import _ from 'lodash';

import { useHistory as useCustomHistory } from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';

import { CardDto } from '../../Cards/Cards.types';
import { CardItem } from '../../Cards/components/CardItem';
import { useGetCardsQuery } from '../../Cards/hooks/useGetCards.query';
import { useSearchParams } from '../../Cards/hooks/useSearchParams';
import { CardActionsCarousel } from '../CardActionsCarousel/CardActionsCarousel';
import { CardDetailsTable } from '../CardDetailsTable/CardDetailsTable';

import {
  StyledCardItemWrapper,
  StyledCarousel,
  StyledCarouselButton,
  StyledCarouselWrapper,
} from './CardCarousel.styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface Props {
  employeeId: string;
}

export const CardCarousel: React.FC<Props> = ({ employeeId }) => {
  const { push } = useHistory();
  const { pathname, hash } = useLocation();
  const { observingSearchQuery } = useCustomHistory();
  const { observingOrganizationData } = useOrganizationOverridableProperties();

  const currentPath = useMemo(() => `${pathname}?${observingSearchQuery}`, [observingSearchQuery, pathname]);

  const { query: cardId } = useSearchParams('card_id');

  const {
    cardMap, isLoading,
  } = useGetCardsQuery(employeeId);
  const [activeCard, setActiveCard] = useState<CardDto | undefined | null>(null);

  const cards: CardDto[] = useMemo(() => {
    const fields: CardDto[] = [];
    Object.keys(cardMap).forEach((key) => {
      const field = cardMap[key];
      Object.keys(field).forEach((newKey) => {
        const list = _.get(field, `${newKey}`) as CardDto[];
        list.forEach((item) => {
          fields.push(item);
        });
      });
    });

    return fields;
  }, [cardMap]);

  const selectedItemIndex = useMemo(
    () => {
      const index = cards.findIndex((card) => card.id === cardId);
      return index > -1 ? index : 0;
    },
    [cardId, cards],
  );

  const goNextHandler = useCallback((type: 'prev' | 'next') => {
    if (!activeCard) return;
    if (type === 'next' && cards[selectedItemIndex + 1]) {
      push(`${currentPath}&dependent_id=${cards[selectedItemIndex + 1].holder.dependentId}&card_id=${cards[selectedItemIndex + 1].id}${hash}`);
    }

    if (type === 'prev' && cards[selectedItemIndex - 1]) {
      push(`${currentPath}&dependent_id=${cards[selectedItemIndex - 1].holder.dependentId}&card_id=${cards[selectedItemIndex - 1].id}${hash}`);
    }
  }, [activeCard, cards, selectedItemIndex, push, currentPath, hash]);

  useEffect(() => {
    if (cards.length && selectedItemIndex >= 0) {
      setActiveCard(cards[selectedItemIndex]);
      const params = new URLSearchParams({
        dependent_id: cards[selectedItemIndex].holder.dependentId,
        card_id: cards[selectedItemIndex].id,
      });
      push(`${currentPath}&${params.toString()}${hash}`);
    }
  }, [cardId, cards, currentPath, isLoading, push, selectedItemIndex, hash]);

  return (
    <Box background="module" round="moduleRound" pad="spacing24">
      <Box direction="row" justify="between">
        <Text size="20px" margin={{ bottom: 'spacing16' }} data-testid="CardCarousel-title">
          Cards: {cards.length}
        </Text>
        <Box direction="row">
          <StyledCarouselButton
            margin={{ right: 'spacing8' }}
            onClick={() => goNextHandler('prev')}
            disabled={!cards[selectedItemIndex - 1]}
          >
            <ChevronLeftIcon size="12px" className="arrow-icon" color="iconPrimary"  />
          </StyledCarouselButton>
          <StyledCarouselButton
            onClick={() => goNextHandler('next')}
            disabled={!cards[selectedItemIndex + 1]}
          >
            <ChevronRightIcon size="12px" className="arrow-icon" color="iconPrimary"/>
          </StyledCarouselButton>
        </Box>
      </Box>
      <StyledCarouselWrapper
        data-testid="CardCarousel-carousel-wrapper"
      >
        <StyledCarousel
          centerMode
          centerSlidePercentage={49}
          renderArrowNext={() => null}
          renderArrowPrev={() => null}
          showIndicators={false}
          showStatus={false}
          selectedItem={selectedItemIndex}
          showThumbs={false}
          className={selectedItemIndex > 0 ? 'passed' : undefined}
        >
          {
            [
              ...cards.map((card, index) => (
                <StyledCardItemWrapper
                  key={card.id}
                  margin="spacing8"
                  onClick={() => push(`${currentPath}?dependent_id=${card.holder.dependentId}&card_id=${card.id}${hash}`)}
                  round="container1Round"
                  width={{ max: '490px' }}
                >
                  <CardItem
                    borderColor={index === selectedItemIndex ? 'accentActive' : 'canvas'}
                    card={card}
                    active={index === selectedItemIndex}
                    logoUrl={observingOrganizationData?.logoInfo?.darkLargeLogoUrl}
                  />
                </StyledCardItemWrapper>
              )),
              <Box key="empty" />,
            ]
          }
        </StyledCarousel>
        <CardActionsCarousel
          cards={cards}
          selectedItemIndex={selectedItemIndex}
        />
      </StyledCarouselWrapper>
      {
        activeCard && (
          <CardDetailsTable
            card={activeCard}
          />
        )
      }
    </Box>
  );
};
