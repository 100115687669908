import React from 'react';

import { ReportSection } from '@/modules/employer/components/Reports/components/ReportSection/ReportSection';

import useHSAReports from './useHSAReports';

const HSA = () => {
  const reports = useHSAReports();

  if (reports[0].isEmpty) {
    return null;
  }

  return (
    <ReportSection
      title="HSA"
      reports={reports}
    />
  );
};

export default HSA;
