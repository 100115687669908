import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

const useDisableCarrierConfigQuery = (onSuccess?:() => void) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (carrierId: string) => api.patch(
      PATHS.DISABLE_CARRIER_CONFIG(carrierId),
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.GET_CARRIER_CONFIGS);
        if (onSuccess) onSuccess();
      },
    },
  );

  return {
    disableCarrierConfig: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useDisableCarrierConfigQuery;
