import React from 'react';
import { Box, ChevronRightIcon, Inscription } from '@common-fe/common-fe';

import { Changeset } from '@/modules/ActivityLog/LogList/LogItem/Changeset/common';

interface LogChangeProps {
  logChange: Changeset;
}

const LogChange: React.FC<LogChangeProps> = ({ logChange }) => (
  <Box key={logChange?.id} direction="row" pad={{ bottom: 'spacing8' }}>
    <Box width="206px">
      <Inscription size="14px" lineHeight="20px" color="textBody">
        {logChange?.attribute}:
      </Inscription>
    </Box>
    <Box>
      <Inscription color="textBody" weight="bold" lineHeight="20px">
        {logChange?.previous || <Inscription>–</Inscription>}
      </Inscription>
    </Box>
    <Box justify="center" margin={{ horizontal: 'spacing8' }}>
      <ChevronRightIcon size="8px" color="textSecondary" />
    </Box>
    <Box>
      <Inscription color="textBody" weight="bold" lineHeight="20px">
        {logChange?.updated}
      </Inscription>
    </Box>
  </Box>
);

export default LogChange;
