import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RAW_DATE_FORMAT } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import {
  generateGroupReplenishmentInvoiceReport,
} from '@/modules/employer/components/Reports/hooks/useGenerateGroupReplenishmentInvoiceReport';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { useReplenishmentInvoiceReportFilterStore } from '../components/ReportSection/ReportFilterPopup.tsx/store';
import { useReplenishmentInvoiceReportStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useReplenishmentInvoiceReport.store';
import {
  GroupInvoiceReportResponse,
  InvoiceReportTableHeaderEnum,
  Report,
  ReportTypes,
} from '../report.types';

const GET_REPLENISHMENT_INVOICE_REPORT_QUERY_KEY = 'getReplenishmentInvoiceReportQueryKey';
const PAGE_SIZE = 30;

export const useGetReplenishmentInvoiceReport = () => {
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();
  const { currentType: type } = useGetCurrentOrganizationType();
  const groups = useReplenishmentInvoiceReportStore((state) => state.groups);
  const setGroups = useReplenishmentInvoiceReportStore((state) => state.setGroups);

  const pages = useReplenishmentInvoiceReportStore((state) => state.pages);
  const setPages = useReplenishmentInvoiceReportStore((state) => state.setPages);

  const reportDates = useReplenishmentInvoiceReportStore((state) => state.reportDates);
  const setReportDates = useReplenishmentInvoiceReportStore((state) => state.setReportDates);

  const totalPages = useReplenishmentInvoiceReportStore((state) => state.totalPages);
  const setTotalPages = useReplenishmentInvoiceReportStore((state) => state.setTotalPages);

  const currentPage = useReplenishmentInvoiceReportStore((state) => state.currentPage);
  const setCurrentPage = useReplenishmentInvoiceReportStore((state) => state.setCurrentPage);

  const isCurrentLoading = useReplenishmentInvoiceReportStore((state) => state.isCurrentLoading);
  const setIsCurrentLoading = useReplenishmentInvoiceReportStore(
    (state) => state.setIsCurrentLoading,
  );

  const handleClear = useReplenishmentInvoiceReportStore((state) => state.handleClear);

  const {
    groupBy: groupByValues,
    dateRange: dateRangeValues,
    search: searchValues,
    invoiceId: invoiceIdValues,
  } = useReplenishmentInvoiceReportFilterStore();
  const {
    data,
    isLoading,
    refetch,
    remove,
  } = useQuery(
    [
      `${GET_REPLENISHMENT_INVOICE_REPORT_QUERY_KEY}_${type}`,
      id,
      ...groupByValues,
      ...dateRangeValues,
      searchValues,
      invoiceIdValues,
      currentPage,
    ],
    type
      ? () => api
        .get<GroupInvoiceReportResponse>(
          PATHS.REPLENISHMENT_INVOICE_REPORT(id),
          {
            params: {
              'group-type': groupByValues[1],
              'start-date': dateRangeValues[0] ? dayjs(dateRangeValues[0]).format(RAW_DATE_FORMAT) : '',
              'end-date': dateRangeValues[1] ? dayjs(dateRangeValues[1]).format(RAW_DATE_FORMAT) : '',
              'org-name-filter': searchValues || '',
              'invoice-id-filter': invoiceIdValues || '',
              size: PAGE_SIZE,
              page: currentPage,
            },
          },
        )
      : () => null,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    handleClear();
    setIsCurrentLoading(true);
    const timeout = setTimeout(() => {
      refetch();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupByValues,
    dateRangeValues,
    searchValues,
    invoiceIdValues,
    type,
    id,
  ]);

  useEffect(() => {
    if (currentPage) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
  ]);

  useEffect(() => {
    if (data && !pages.includes(data.data?.page_number)) {
      const preparedGroups = generateGroupReplenishmentInvoiceReport({
        response: data?.data?.groups || [],
        groupByType: groupByValues[1],
        dateRange: dateRangeValues,
      });
      setGroups(preparedGroups);
      setReportDates([
        data?.data?.start_report_date,
        data?.data?.end_report_date,
      ]);
      setPages(data.data?.page_number);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groupByValues]);

  useEffect(() => {
    if (data?.data?.total_pages) {
      setTotalPages(data.data?.total_pages || 0);
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    if (isLoading) {
      setIsCurrentLoading(true);
    }
    const timeout = setTimeout(() => {
      if (!isLoading) {
        setIsCurrentLoading(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const formattedReplenishmentInvoiceReport: Report = useMemo(() => ({
    title: 'Replenishment Invoice Report',
    type: 'Invoice',
    reportType: ReportTypes.Replenishment,
    generateReportURL: '',
    id: 'replenishment_invoice_report_id',
    startReportDate: reportDates[0],
    endReportDate: reportDates[1],
    currentPage,
    totalPages,
    setCurrentPage,
    setIsCurrentLoading,
    nestedTableDataHeader: [
      {
        key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
        value: InvoiceReportTableHeaderEnum.INVOICE_DATE,
      },
      {
        key: InvoiceReportTableHeaderEnum.INVOICE_ID,
        value: InvoiceReportTableHeaderEnum.INVOICE_ID,
        style: {
          alignItems: 'center',
        },
      },
      {
        key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
        value: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
        style: {
          alignItems: 'flex-end',
        },
      },
    ],
    nestedTableData: {
      groups,
    },
  }),
  [currentPage, groups, reportDates, setCurrentPage, setIsCurrentLoading, totalPages]);

  const isDataLoading = useMemo(() => isCurrentLoading
    && !formattedReplenishmentInvoiceReport?.nestedTableData?.groups.length,
  [isCurrentLoading, formattedReplenishmentInvoiceReport]);

  const isSubLoading = useMemo(() => isCurrentLoading && !!groups.length, [
    isCurrentLoading, groups.length,
  ]);
  const isEmpty = useMemo(() => !isLoading
  && formattedReplenishmentInvoiceReport.nestedTableData?.groups
  && formattedReplenishmentInvoiceReport.nestedTableData?.groups.length <= 1, [
    isLoading, formattedReplenishmentInvoiceReport,
  ]);

  return {
    formattedReplenishmentInvoiceReport,
    refetch,
    remove,
    isLoading: isDataLoading,
    isSubLoading,
    isEmpty,
  };
};
