import React from 'react';
import {
  Box,
  Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import HeaderLink from '@/components/elements/HeaderLink';

import CreateCopayAdjudication from './CreateCopayAdjudication';

interface Props {
  isPageMode?: boolean;
}

const CopayTopbar: React.FC<Props> = ({
  isPageMode,
}) => (
  <Box direction="row" justify="between" align="center" wrap margin={{ top: isPageMode ? 'l' : '0', bottom: isPageMode ? 'l' : '0' }}>
    {
      isPageMode
        ? (
          <Text data-testid="copay-topbar-header_page" size="3xl" weight="bold" color="textBody" style={{ lineHeight: '44px' }}>
            Copay Adjudication
          </Text>
        ) : (
          <Box data-testid="copay-topbar-header" margin={{ top: 'l', bottom: 'm' }}>
            <HeaderLink path={ROUTES.COPAY_ADJUDICATION} title="Copay Adjudication" />
          </Box>
        )
    }
    <CreateCopayAdjudication />
  </Box>
);

export default CopayTopbar;
