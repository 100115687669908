import React from 'react';
import { AppButton, Box, ChevronLeftIcon, ChevronRightIcon, Inscription, Text } from '@common-fe/common-fe';
import { Dayjs } from 'dayjs';

import { ToggleOptions } from '../payrollCalendar.types';
import { usePayrollCalendarCustomDateModalStore } from '../stores';

import { PayrollCalendarCustomDateModal } from './PayrollCalendarCustomDatesModal/PayrollCalendarCustomDateModal';

const toggleOptions: ToggleOptions[] = Object.values(ToggleOptions);

interface Props {
  selectedOption: ToggleOptions;
  onSelectedOption: (value: ToggleOptions) => void;
  currentMonthPage: number;
  onDatesPage: (value: number) => void;
  monthRange: [string, string];
  maxPage: number;
  minPage: number;
  minDate?: string;
  maxDate?: string;
  onPickDates?: (date: Dayjs[]) => void;
}

export const PayrollCalendarDatesHeader: React.FC<Props> = ({
  selectedOption,
  onSelectedOption,
  currentMonthPage,
  onDatesPage,
  monthRange,
  minDate,
  maxDate,
  onPickDates,
  maxPage,
  minPage,
}) => {
  const handleChangeVisibility = usePayrollCalendarCustomDateModalStore((state) => state.handleChangeVisibility);

  const isSelectedOption = (option: string) => option === selectedOption;
  return (
    <>
      <Box
        data-testid="PayrollCalendarDatesHeader_container"
        direction="row"
        height={{ min: '48px', max: '48px' }}
        justify="between"
        align="center"
        margin={{ bottom: 'spacing16' }}
      >
        <Box direction="row" align="center">
          {selectedOption === ToggleOptions.LIST ?
            (
              <Inscription lineHeight="20xp" weight="bold">
                Calendar Dates
              </Inscription>
            )
            : (
              <Box direction="row" align="center" gap="spacing12">
                <Inscription lineHeight="20px" weight="bold">
                  {monthRange[0]} - {monthRange[1]}
                </Inscription>
                <Box direction="row" gap="spacing8">  
                  <AppButton
                    data-testid="PayrollCalendarDatesHeader_left_button"
                    buttonType="secondary"
                    type="button"
                    disabled={currentMonthPage === minPage}
                    onClick={() => onDatesPage(currentMonthPage - 1)}
                  >
                    <ChevronLeftIcon />
                  </AppButton>

                  <AppButton
                    data-testid="PayrollCalendarDatesHeader_right_button"
                    buttonType="secondary"
                    type="button"
                    disabled={currentMonthPage === maxPage}
                    onClick={() => onDatesPage(currentMonthPage + 1)}
                  >
                    <ChevronRightIcon />
                  </AppButton>
                </Box>

              </Box>
            )}
        </Box>
        <Box
          direction="row"
          height="100%"
          align="center"
          gap="spacing24"
        >
          <Box
            data-testid="PayrollCalendarDatesHeader_toggle_container"
            round="container1Round"
            pad="spacing4"
            background="module"
            direction="row"
            width={{ min: 'fit-content', max: 'fit-content' }}
          >
            {toggleOptions.map((option) => (
              <Box
                data-testid="PayrollCalendarDatesHeaderToggler_item"
                key={option}
                width={{ min: '130px', max: 'fit-content' }}
                height={{ min: '40px', max: 'fit-content' }}
                align="center"
                justify="center"
                background={{ color: isSelectedOption(option) ? 'canvas' : 'transparent' }}
                border={{ color: isSelectedOption(option) ? 'border2' : 'transparent' }}
                round="container2Round"
                onClick={() => onSelectedOption(option)}
              >
                <Text
                  weight={isSelectedOption(option) ? 700 : 400}
                  color={isSelectedOption(option) ? 'textBody' : 'textAccent'}
                >
                  {option}
                </Text>
              </Box>
            ))}
          </Box>

          <AppButton
            onClick={() => handleChangeVisibility(true)}
          >
            Add Custom Date
          </AppButton>
        </Box>
      </Box>
      <PayrollCalendarCustomDateModal
        minDate={minDate}
        maxDate={maxDate}
        onPickDates={onPickDates}
      />
    </>
  );
};
