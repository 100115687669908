import { useCallback, useEffect, useMemo, useState } from 'react';

export const LIST_ITEMS_KEY = 'LIST_ITEMS_KEY';
export const QUERY_PARAMS_KEY = 'QUERY_PARAMS_KEY';
export const LOADED_PAGES_KEY = 'loadedPages';

interface ListItem {
  id?: string;
}

export const convertToIds = (list?: Partial<ListItem>[]) => {
  if (list?.length) {
    return list?.map((listItem) => `${listItem?.id}`);
  }

  return undefined;
};

interface Props {
  currentItem: string;
}

const useNavigationByList = ({
  currentItem,
}: Props) => {
  const listIdsFromStorage = sessionStorage.getItem(LIST_ITEMS_KEY);
  const listParamsFromStorage = sessionStorage.getItem(QUERY_PARAMS_KEY);
  const loadedPagesFromStore = sessionStorage.getItem(LOADED_PAGES_KEY);

  const parsedListIds = useMemo(() => listIdsFromStorage ? JSON.parse(listIdsFromStorage) : undefined, [listIdsFromStorage]);
  const parsedListParams = useMemo(() => listParamsFromStorage ? JSON.parse(listParamsFromStorage) : undefined, [listParamsFromStorage]);
  const parsedLoadedPages = useMemo(() => loadedPagesFromStore ? JSON.parse(loadedPagesFromStore) : undefined, [loadedPagesFromStore]);

  const [listIds, setListIds] = useState(parsedListIds);
  const [listParams, setListParams] = useState(parsedListParams);
  const [loadedPages, setLoadedPages] = useState(parsedLoadedPages);

  const [isItemFirstInList, setIsItemFirstInList] = useState(false);
  const [isItemLastInList, setIsItemLastInList] = useState(false);

  const handleSetList = useCallback((list?: string[]) => {
    if (list) {
      setListIds(list);
      sessionStorage.setItem(LIST_ITEMS_KEY, JSON.stringify(list));
    }
  }, []);

  const handleSetListParams = useCallback((params?: object) => {
    if (params) {
      setListParams(params);
      sessionStorage.setItem(QUERY_PARAMS_KEY, JSON.stringify(params));
    }
  }, []);

  const handleSetLoadedPages = useCallback((page?: string) => {
    if (page) {
      const updatedLoadedPages = [...new Set([...loadedPages, page])];
      setLoadedPages(updatedLoadedPages);
      sessionStorage.setItem(LOADED_PAGES_KEY, JSON.stringify(updatedLoadedPages));
    }
  }, [loadedPages]);

  useEffect(() => {
    if (!listIds?.length) {
      return;
    }

    if (currentItem === listIds?.[0]) {
      setIsItemFirstInList(true);
    } else {
      setIsItemFirstInList(false);
    }

    if (currentItem === listIds?.[listIds?.length - 1]) {
      setIsItemLastInList(true);
    } else {
      setIsItemLastInList(false);
    }
  }, [listIds, currentItem]);

  const prevItem = useMemo(() => {
    if (!listIds?.length) {
      return undefined;
    }

    const currentItemIndex = listIds?.indexOf(currentItem);
    return listIds?.[currentItemIndex - 1];
  }, [listIds, currentItem]);

  const nextItem = useMemo(() => {
    if (!listIds?.length) {
      return undefined;
    }

    const currentItemIndex = listIds?.indexOf(currentItem);
    return listIds?.[currentItemIndex + 1];
  }, [listIds, currentItem]);

  const params = useMemo(() => ({
    ...listParams,
    ...isItemFirstInList && !prevItem && loadedPages?.length 
      ? { page: Math.min(...loadedPages) - 1 } 
      : {},
    ...isItemLastInList && !nextItem && loadedPages?.length
      ? { page: Math.max(...loadedPages) + 1}
      : {},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [isItemFirstInList, prevItem, nextItem, isItemLastInList]);

  const isPageLoaded = useMemo(() => {
    if (loadedPages?.length) {
      return !!loadedPages?.find((page?: string) => page === `${params?.page}`);
    }

    return false;
  }, [loadedPages, params]);

  const handleUpdateLoadedPages = useCallback((list?: Partial<ListItem>[]) => {
    if (list?.length && params.page >= 0 && !isPageLoaded) {
      const newListItems = convertToIds(list);
      if (newListItems && listIds) {
        const updatedIdsList = isItemFirstInList 
          ? [...newListItems, ...listIds]
          : [...listIds, ...newListItems];
        handleSetList(updatedIdsList);
        handleSetListParams(params);
        handleSetLoadedPages(`${params.page}`);
      }
    }
  }, [listIds, params, handleSetList, handleSetListParams, handleSetLoadedPages, isItemFirstInList, isPageLoaded]);

  return {
    isItemFirstInList,
    isItemLastInList,
    prevItem,
    nextItem,
    listIds,
    params,
    loadedPages,
    isPageLoaded,
    handleUpdateLoadedPages,
  };
};

export default useNavigationByList;
