import { useMemo } from 'react';
import {
  useQuery,
} from 'react-query';
import { isNull, toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import { Expense, ExpensesDetailsParams, ExpensesStatisticsDto } from '@/modules/transaction/transaction.types';

const EXPENSES_STATISTICS_QUERY_KEY = 'getExpensesStatisticsQueryKey';
const OTHER_EXPENSE = {
  key: 'OTHER',
  title: 'Other',
};

export const useExpensesStatisticsQuery = (params?: ExpensesDetailsParams) => {
  const { list: serviceCategories } = useServiceCategoriesQuery();
  const accountId = params?.account_id;
  const {
    data, isLoading, isSuccess, refetch,
  } = useQuery(
    [EXPENSES_STATISTICS_QUERY_KEY, accountId],
    () => api.get(PATHS.EXPENSES_STATISTICS,
      { params }),
  );
  const formattedData = useMemo(() => {
    const receivedData: ExpensesStatisticsDto = data?.data || [];

    return {
      totalExpenses: Math.abs(receivedData?.total_expenses),
      expenses: receivedData?.expenses?.map((listItem: Expense) => {
        if (isNull(listItem.category_id)) {
          return {
            ...OTHER_EXPENSE,
            value: listItem?.percent,
            amount: listItem?.amount,
          };
        }
        const serviceCategory = (serviceCategories || [])
          .find((category) => category.id === toString(listItem.category_id));
        return {
          key: serviceCategory?.key || '',
          title: serviceCategory?.value || '',
          value: listItem?.percent,
          amount: Math.abs(listItem?.amount) || 0,
        };
      }),
    };
  }, [data, serviceCategories]);
  return ({
    formattedData,
    isLoading,
    isSuccess,
    data,
    refetch,
  });
};
