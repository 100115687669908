import React from 'react';
import { Box, Inscription, Status } from '@common-fe/common-fe';

interface Props {
    title: string;
    value?: string | number;
    width?: string;
    isStatus?: boolean;
  }
  
export const ContributionInvoiceInfoBox: React.FC<Props> = ({
  title,
  value,
  width,
  isStatus,
}) => {
  return (
    <Box
      width={width || '100%'}
      height={{ min: '42px' }}
      justify="center"
      data-testid="ContributionInvoiceInfoBox_container"
    >
      <Inscription
        size="12px"
        lineHeight="16px"
        weight={700}
        color="textBody"
        margin={{ bottom: 'spacing4' }}
      >
        {title}
      </Inscription>

      {isStatus ? (
        <Box direction="row" gap="spacing8" align="center">
          <Status status={value as string} />
        </Box>
      ) : (
        <Inscription
          size="14px"
          weight={400}
          color="textBody"
        >
          {value}
        </Inscription>
      )}
    </Box>
  );
};