import React, { useCallback, useState } from 'react';
import { Controller,useForm } from 'react-hook-form';
import {
  AppButton,
  Box,
  Button,
  FlatTextInput,
  Form,
  Heading,
  onKeyDownForCancellingAutoComplete,
  Text,
  useIsWindowsOS,
} from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';

import { REGEXPS } from '@/common';
import { DefaultValues } from '@/common/types';

import { useForgotPassword } from './hooks';

interface Props {
  username: string;
  children?: React.ReactNode;
  testId?: string;
  onNext: (value: object) => void;
}
const REQUIRED_FIELD = 'This field is required';

const CODE_ENTITY_NAME = 'verificationCode';
const NEW_PASSWORD_FIELD_KEY = 'newPassword';
const CONFIRM_PASSWORD_FIELD_KEY = 'confirmPassword';

const NewPassword: React.FC<Props> = ({ username, onNext }) => {
  const {
    handleResetError,
    handleSetNewCode,
    handleRequestCodeWithTimeout,
    disabled: disabledResend,
  } = useForgotPassword();

  const {
    control, formState: { errors }, handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      [CODE_ENTITY_NAME]: yup.string().required(REQUIRED_FIELD),
      [NEW_PASSWORD_FIELD_KEY]: yup.string().required(REQUIRED_FIELD).min(8, 'At least 8 characters')
        .matches(REGEXPS.LOWERCASE_AND_UPPERCASE, 'A mixture of both uppercase and lowercase letters')
        .matches(REGEXPS.NUMBERS_AND_LETTERS, 'A mixture of letters and numbers')
        .matches(
          REGEXPS.SPECIAL_CHARACTER_PASSWORD, 'Inclusion of at least one special character, e.g., !, @, #',
        ),
      [CONFIRM_PASSWORD_FIELD_KEY]: yup.string().required(REQUIRED_FIELD)
        .oneOf([yup.ref(NEW_PASSWORD_FIELD_KEY)], 'Passwords must match'),
    })),
  });

  const onSubmit = useCallback(async (data: DefaultValues) => {
    const code = _.get(data, CODE_ENTITY_NAME, '') as string;
    const password = _.get(data, NEW_PASSWORD_FIELD_KEY, '') as string;
    await handleSetNewCode(username, code, password, onNext);
  }, [handleSetNewCode, onNext, username]);
  const isWindowsOS = useIsWindowsOS();
  const [isCodeAvailable, setIsCodeAvailable] = useState(!isWindowsOS);
  const [isNewPassAvailable, setIsNewPassAvailable] = useState(!isWindowsOS);
  return (
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Box direction="column">
        <Box
          border={{ color: 'border2', size: 'medium' }}
          round="container1Round"
          pad={{
            horizontal: 'spacing48',
            vertical: 'spacing24',
          }}
          margin={{
            bottom: 'spacing12',
          }}
        >
          <Heading
            level={4}
            color="textBody"
            textAlign="center"
          >Enter verification code
          </Heading>
          <Text
            textAlign="center"
            size="medium"
            weight={500}
            margin={{ horizontal: 'spacing48' }}
          >
            Please enter the code you received in email to verify your account.
          </Text>
          <Controller
          // @ts-ignore
            name={CODE_ENTITY_NAME}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Box margin={{ top: 'spacing24' }}>
                <FlatTextInput
                  {...isWindowsOS && !isCodeAvailable ? {
                    value: isCodeAvailable ? value : '',
                    onKeyDown: (e) => onKeyDownForCancellingAutoComplete(
                      e,
                      onChange,
                      setIsCodeAvailable,
                      isCodeAvailable,
                    ),
                  } : {
                    value,
                  }}
                  onChange={(newValue: string) => {
                    onChange(newValue);
                    handleResetError();
                  }}
                  errorWrap
                  testId="input-verification-code"
                  name="code"
                  defaultAutoComplete="off"
                  placeholder="Enter verification code"
                  errorText={_.get(errors, `${CODE_ENTITY_NAME}.message`, '') as string}
                />
              </Box>
            )}
          />
          <Box direction="row" margin={{ top: 'spacing12' }}>
            <Button
              size="small"
              empty
              data-testid="button-resend-code"
              disabled={disabledResend}
              onClick={() => handleRequestCodeWithTimeout(username)}
            >
              Resend Code
            </Button>
          </Box>
        </Box>

        <Box
          border={{ color: 'border2', size: 'medium' }}
          round="container1Round"
          pad={{
            horizontal: 'spacing48',
            vertical: 'spacing24',
          }}
          margin={{
            bottom: 'spacing12',
          }}
        >
          <Heading
            level={4}
            color="textBody"
            textAlign="center"
          >Reset Password
          </Heading>

          <Text
            textAlign="center"
            size="medium"
            weight={500}
            margin={{ horizontal: 'spacing48' }}
          >
            Please choose your new password.
          </Text>
          <Controller
          // @ts-ignore
            name={NEW_PASSWORD_FIELD_KEY}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Box margin={{ top: 'spacing24' }}>
                <FlatTextInput
                  {...isWindowsOS && !isNewPassAvailable ? {
                    value: isNewPassAvailable ? value : '',
                    onKeyDown: (e) => onKeyDownForCancellingAutoComplete(
                      e, onChange, setIsNewPassAvailable, isNewPassAvailable,
                    ),
                  } : {
                    value,
                  }}
                  onChange={(newValue: string) => {
                    onChange(newValue);
                    handleResetError();
                  }}
                  testId="password-input"
                  passwordHelper
                  defaultAutoComplete="off"
                  inputType="password"
                  errorWrap
                  name={NEW_PASSWORD_FIELD_KEY}
                  placeholder="Choose a new password"
                  errorText={_.get(errors, `${NEW_PASSWORD_FIELD_KEY}.message`, '') as string}
                />
              </Box>
            )}
          />

          <Controller
          // @ts-ignore
            name={CONFIRM_PASSWORD_FIELD_KEY}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Box margin={{ top: 'spacing12' }}>
                <FlatTextInput
                  inputType="password"
                  defaultAutoComplete="off"
                  value={value}
                  onChange={(newValue: string) => {
                    onChange(newValue);
                    handleResetError();
                  }}
                  errorWrap
                  testId="confirm-password-input"
                  name={CONFIRM_PASSWORD_FIELD_KEY}
                  placeholder="Confirm new password"
                  errorText={_.get(errors, `${CONFIRM_PASSWORD_FIELD_KEY}.message`, '') as string}
                />
              </Box>
            )}
          />

        </Box>
        <Box margin={{
          top: 'spacing12',
          horizontal: 'spacing48',
        }}
        >
          <AppButton
            size="L"
            testId="submit"
            type="submit"
            width="100%"
          >
            Submit
          </AppButton>
        </Box>

      </Box>
    </Form>
  );
};

export default NewPassword;
