import { useCallback } from 'react';

import useCustomReportsStore, { CustomReportsPayload} from '../CustomReports/stores/useCustomReports.store';
import useEmployerInfoStore, { EmployerInfoPayload } from '../EmployerInfo/stores/useEmployerInfo.store';
import useGeneralInfoStore, { GeneralInfoPayload } from '../GeneralInfo/stores/useGeneralInfo.store';
import usePreferredMailStore, { PreferredMailingAddressPayload } from '../PreferredMailingAddress/stores/usePreferredMailingAddress.store';
import usePreferredPhysicalStore, { PreferredPhysicalAddressPayload } from '../PreferredPhysicalAddress/stores/usePreferredPhysicalAddress.store';
import useSecurityStore, { SecurityPayload } from '../Security/stores/useSecurity.store';

export type PersonalInformationPayload = GeneralInfoPayload & PreferredMailingAddressPayload
& PreferredPhysicalAddressPayload & EmployerInfoPayload & SecurityPayload & CustomReportsPayload;
export default () => {
  const handleSetGeneralInfoSourceState = useGeneralInfoStore((state) => state.setSourceState);
  const handleSetGeneralInfoState = useGeneralInfoStore((state) => state.setState);
  const handleResetGeneralInfoState = useGeneralInfoStore((state) => state.handleReset);
  const handleSetErrorsGeneralInfoState = useGeneralInfoStore((state) => state.setErrors);

  const handleSetCustomReportsSourceState = useCustomReportsStore((state) => state.setSourceState);
  const handleSetCustomReportsState = useCustomReportsStore((state) => state.setState);
  const handleResetCustomReportsState = useCustomReportsStore((state) => state.handleReset);
  const handleSetErrorsCustomReportsState = useCustomReportsStore((state) => state.setErrors);
  
  const handleSetSecuritySourceState = useSecurityStore((state) => state.setSourceState);
  const handleSetSecurityState = useSecurityStore((state) => state.setState);
  const handleResetSecurityState = useSecurityStore((state) => state.handleReset);
  const handleSetErrorsSecurityState = useSecurityStore((state) => state.setErrors);

  const handleSetPreferredMailSourceState = usePreferredMailStore((state) => state.setSourceState);
  const handleSetPreferredMailState = usePreferredMailStore((state) => state.setState);
  const handleResetPreferredMailState = usePreferredMailStore((state) => state.handleReset);
  const handleSetErrorsPreferredMailState = usePreferredMailStore((state) => state.setErrors);

  const handleSetPreferredPhysicalSourceState = usePreferredPhysicalStore(
    (state) => state.setSourceState,
  );
  const handleSetPreferredPhysicalState = usePreferredPhysicalStore((state) => state.setState);
  const handleResetPreferredPhysicalState = usePreferredPhysicalStore((state) => state.handleReset);
  const handleSetErrorsPreferredPhysicalState = usePreferredPhysicalStore(
    (state) => state.setErrors,
  );

  const handleSetEmployerInfoSourceState = useEmployerInfoStore((state) => state.setSourceState);
  const handleSetEmployerInfoState = useEmployerInfoStore((state) => state.setState);
  const handleResetEmployerInfoState = useEmployerInfoStore((state) => state.handleReset);
  const handleSetErrorsEmployerInfoState = useEmployerInfoStore((state) => state.setErrors);

  const handleSetSourceState = useCallback((values:Partial<PersonalInformationPayload>) => {
    handleSetGeneralInfoSourceState(values);
    handleSetGeneralInfoState(values);

    handleSetSecuritySourceState(values);
    handleSetSecurityState(values);

    handleSetCustomReportsSourceState(values);
    handleSetCustomReportsState(values);

    handleSetPreferredMailSourceState(values);
    handleSetPreferredMailState(values);

    handleSetPreferredPhysicalSourceState(values);
    handleSetPreferredPhysicalState(values);

    handleSetEmployerInfoSourceState(values);
    handleSetEmployerInfoState(values);
  }, [handleSetEmployerInfoSourceState, handleSetEmployerInfoState,
    handleSetCustomReportsSourceState,handleSetCustomReportsState,
    handleSetGeneralInfoSourceState, handleSetGeneralInfoState,
    handleSetPreferredMailSourceState, handleSetPreferredMailState,
    handleSetPreferredPhysicalSourceState, handleSetPreferredPhysicalState,
    handleSetSecuritySourceState, handleSetSecurityState]);

  const handleReset = useCallback(() => {
    handleResetGeneralInfoState();
    handleResetPreferredMailState();
    handleResetCustomReportsState();
    handleResetPreferredPhysicalState();
    handleResetEmployerInfoState();
    handleResetSecurityState();
  }, [handleResetEmployerInfoState, handleResetGeneralInfoState,
    handleResetSecurityState,handleResetCustomReportsState,
    handleResetPreferredMailState, handleResetPreferredPhysicalState]);

  const handleSetErrors = useCallback((values:Partial<PersonalInformationPayload>) => {
    handleSetErrorsGeneralInfoState(values);
    handleSetErrorsPreferredMailState(values);
    handleSetErrorsCustomReportsState(values);
    handleSetErrorsPreferredPhysicalState(values);
    handleSetErrorsEmployerInfoState(values);
    handleSetErrorsSecurityState(values);
  }, [handleSetErrorsEmployerInfoState, handleSetErrorsGeneralInfoState,
    handleSetErrorsSecurityState,handleSetErrorsCustomReportsState,
    handleSetErrorsPreferredMailState, handleSetErrorsPreferredPhysicalState]);
  return {
    handleReset,
    handleSetSourceState,
    handleSetErrors,
  };
};
