import { useMemo } from 'react';
import _ from 'lodash';

import { useCoverageLevelsStore } from '../stores';

export default () => {
  const coverageLevels = useCoverageLevelsStore((state) => state.state);
  const sortedLevels = useMemo(() => {
    const sortedValues = _.sortBy(
      coverageLevels,
      (item) => _.toLower(item.name),
    );
    return _.sortBy(sortedValues, (item) => item.isDeletable);
  }, [coverageLevels]);
  const excludeIds = useMemo(() => coverageLevels.map((item) => item.coverageId || ''), [coverageLevels]);

  return {
    levels: sortedLevels,
    excludeIds,
  };
};
