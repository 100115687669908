import React, { useCallback, useMemo } from 'react';
import { Box, InfoOutlineIcon, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { useAuthStore } from '@/modules/user/stores';

import { useEmployerTerminationModalStore, useViewEmployerTerminationModalStore } from './store';
import { ViewEmployerTerminationModal } from './ViewEmployerTerminationModal';

const StyledInfoIcon = styled(InfoOutlineIcon)`
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1 ;
    fill: ${({ theme }) => theme.colors.iconAccent};
  }
`;

export const EmployerTerminationBanner: React.FC = () => {
  const { auth } = useAuthStore();

  const handleChangeVisibility = useViewEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const handleSetEmployerName = useEmployerTerminationModalStore((state) => state.handleSetEmployerName);
  const handleSetEmployerId = useEmployerTerminationModalStore((state) => state.handleSetEmployerId);
  const handleSetTerminationDate = useEmployerTerminationModalStore((state) => state.handleSetTerminationDate);

  const {
    terminationDate,
    bannerHeight,
    showBanner,
    organizationName,
    organizationId,
    status,
  } = useEmployerTerminationBanner();

  const formattedTerminationDate = useMemo(() => {
    if (!terminationDate) {
      return undefined;
    }

    return dayjs(terminationDate).format(DEFAULT_DATE_FORMAT);
  }, [terminationDate]);

  const handleViewEmployerTermination = useCallback(() => {
    handleSetEmployerName(organizationName);
    handleSetEmployerId(organizationId ? `${organizationId}` : undefined);
    handleChangeVisibility(true);
    if (terminationDate) {
      handleSetTerminationDate(terminationDate);
    }
  }, [
    handleChangeVisibility,
    handleSetEmployerId,
    handleSetEmployerName,
    handleSetTerminationDate,
    organizationId,
    organizationName,
    terminationDate,
  ]);

  const isOngoingTermination = useMemo(() => {
    return status === EmployerStatus.TerminationInProgress;
  }, [status]);

  if (!showBanner || !auth) return null;

  return (
    <>
      <Box
        data-testid="EmployerTerminationBanner__container"
        width="100%"
        background="warningBorder"
        height={{ max: bannerHeight }}
        justify="center"
        align="center"
        pad={{ vertical: 'spacing16'}}
        style={{
          position: 'fixed',
          zIndex: 1003,
          top: 0,
        }}
      >
        <Box direction="row" align="center" height={{ max: '24px' }}>
          {isOngoingTermination ? (
            <Inscription size="large" lineHeight="24px">
              Organization has terminated
            </Inscription>
          ) : (
            <>
              <Inscription size="large" lineHeight="24px">
                Organization has planned termination on
              </Inscription>
              <Inscription size="large" weight="bold" lineHeight="24px" margin={{ left: 'spacing4' }}>
                {formattedTerminationDate}
              </Inscription>
            </>
          )}

          <Box
            margin={{ left: 'spacing8' }}
            onClick={handleViewEmployerTermination}
          >
            <StyledInfoIcon size="medium" />
          </Box>
        </Box>
      </Box>
      <ViewEmployerTerminationModal />
    </>
  );
};
