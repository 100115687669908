import React from 'react';
import { DirectionType } from 'grommet/utils';

import { Report } from '@/modules/employer/components/Reports/report.types';

import { ReportListItem } from './ReportListItem';
import { ReportSectionWrapper } from './ReportSectionWrapper';

export const reportTypes = ['Enrollments', 'Card', 'Claims', 'Invoice', 'HSA'];

interface Props {
  title: string;
  reports: Report[];
  direction?: DirectionType;
}
export const ReportSection: React.FC<Props> = ({
  title,
  reports = [],
  direction,
}) => (
  <ReportSectionWrapper
    title={title}
    anchorId={title}
    direction={direction}
  >
    {
      reports.map((report) => (
        <ReportListItem
          report={report}
          key={report.id}
        />
      ))
    }
  </ReportSectionWrapper>
);
