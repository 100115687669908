import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Box, EyeIcon, Field, FieldTypes, FlatTextInput, HideIcon, RawButton } from '@common-fe/common-fe';
import * as yup from 'yup';

import { SSN_INVALID_TEXT, SSN_MASK_WITH_DASHES, SSN_MASK_WITHOUT_DASHES } from '@/common/constants';
import { SourceIds } from '@/modules/employee/employee.types';

import { useHasSameSSNStore } from '../../../stores';
import { useGetEmployeeSSNQuery } from '../queries';
import { useEmployerInfoStore } from '../stores';

const SSN_DELIMITER_SIGN = '-';

interface Params {
  defaultSSN?: string;
  isDisabled?: boolean;
}

export const useEmployeeSSN = ({ defaultSSN, isDisabled }: Params) => {
  const { hasSameSSN, resetHasSameSSN } = useHasSameSSNStore();
  const [currentSsnMask, setCurrentSsnMask] = useState(SSN_MASK_WITH_DASHES);
  const employeeId = useEmployerInfoStore((state) => state.state.internalId);
  const sourceId = useEmployerInfoStore((state) => state.state.sourceId);
  const setState = useEmployerInfoStore((state) => state.setState);
  
  const isSSNRequired = useMemo(() => {
    return sourceId === SourceIds.SSN;
  }, [sourceId]);
  const { ssn } = useGetEmployeeSSNQuery(employeeId);
  useEffect(() => {
    setState({ssn});
  },[setState, ssn]);
  const ssnValidator = useMemo(() => {
    if (hasSameSSN) {
      return yup
        .string()
        .trim()
        .test({
          test() {
            return false;
          },
          message: 'SSN number must be unique.',
        })
        .nullable();
    }

    return yup.string().test({
      exclusive: false,
      test(val) {
        if (!val && !isSSNRequired) {
          return true;
        }
        if (!val) {
          return false;
        }
        if (val.length === 0 && isSSNRequired) {
          return false;
        }
        if (val.length === currentSsnMask.length) {
          return true;
        }

        return false;
      },
      message: SSN_INVALID_TEXT,
    });
  }, [isSSNRequired, hasSameSSN, currentSsnMask.length]);
  const [isSSNDisplayed, setSSNDisplayed] = useState(false);
  const handleToggleVisible = useCallback(() => {
    setSSNDisplayed(!isSSNDisplayed);
  }, [isSSNDisplayed]);
  const currentSSN = useMemo(() => {
    if (ssn && isSSNDisplayed) {
      return ssn;
    }
    return defaultSSN ? `***-**-${defaultSSN}` : '';
  }, [defaultSSN, isSSNDisplayed, ssn]);

  useEffect(() => {
    if (ssn && ssn.length >= 4 && ssn.charAt(3) !== SSN_DELIMITER_SIGN) {
      setCurrentSsnMask(SSN_MASK_WITHOUT_DASHES);
    }
    if (ssn && ssn.length <= 3) {
      setCurrentSsnMask(SSN_MASK_WITH_DASHES);
    }
  }, [ssn]);

  const ssnField = useMemo(() => {
    if (isDisabled) {
      return {
        name: 'ssn',
        type: FieldTypes.Node,
        label: 'SSN',
        showRequireIcon: isSSNRequired,
        placeholder: 'Enter SSN',
        disabled: true,
        value: (
          <Box fill style={{ position: 'relative' }}>
            <FlatTextInput disabled value={currentSSN} />
            <Box style={{ position: 'absolute', right: '16px', top: '8px' }}>
              <RawButton onClick={handleToggleVisible}>
                {isSSNDisplayed ? <EyeIcon /> : <HideIcon />}
              </RawButton>
            </Box>
          </Box>
        ),
      } as Field;
    }

    return {
      name: 'ssn',
      type: FieldTypes.Mask,
      showRequireIcon: isSSNRequired,
      label: 'SSN',
      mask: currentSsnMask,
      placeholder: 'Enter SSN',
      validator: ssnValidator,
      onChangeValues: resetHasSameSSN,
      value: ssn,
    } as Field;
  }, [
    isDisabled,
    isSSNRequired,
    currentSsnMask,
    ssnValidator,
    resetHasSameSSN,
    ssn,
    currentSSN,
    handleToggleVisible,
    isSSNDisplayed,
  ]);

  const key = useMemo(() => {
    if (!isDisabled && ssn) return ssn;

    return currentSSN;
  }, [currentSSN, ssn, isDisabled]);

  return {
    ssnField,
    key,
  };
};
