import React, { useCallback, useEffect } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useBreadcrumbs, useHistory } from '@/modules/core/hooks';
import { ClaimsProcessors } from '@/modules/employer/components/Claims/ClaimsProcessors';
import { useAuthStore } from '@/modules/user/stores';

export const useHasAccessForObservingOrganization = () => {
  const { observingOrganization } = useCurrentOrganization();
  if (observingOrganization.type) {
    return observingOrganization.type === OrganizationTypes.partner
     || observingOrganization.type === OrganizationTypes.system;
  }
  return true;
};

export const CLAIMS_PROCESSORS_MANAGEMENT_REQUIREMENTS = [
  { organizationType: OrganizationTypes.distributor, isException: true },
  { organizationType: OrganizationTypes.employer, isException: true },
  { organizationType: OrganizationTypes.subsidiary, isException: true },
  { organizationType: OrganizationTypes.subgroup, isException: true },
  { permission: Permissions.MANAGE_CLAIM_PROCESSORS },
];

const ManageClaimsProcessors = () => {
  const hasAccessForObservingOrganization = useHasAccessForObservingOrganization();
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  useEffect(() => {
    if (!hasAccessForObservingOrganization) {
      goHome();
    }
  }, [hasAccessForObservingOrganization, goHome]);
  const { user } = useAuthStore();

  useBreadcrumbs([
    {
      route: ROUTES.CLAIMS,
      title: 'Claims',
    },
    {
      route: ROUTES.MANAGE_CLAIMS_PROCESSORS,
      title: 'Settings',
    }
  ]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Access
      accessRequirements={CLAIMS_PROCESSORS_MANAGEMENT_REQUIREMENTS}
      accessDeniedAction={goHome}
    >
      <ClaimsProcessors />
    </Access>
  );
};

export default ManageClaimsProcessors;
