import React, { useEffect, useState } from 'react';

import { useHistory } from '@/modules/core/hooks';

export interface PageWithTOCTransferProps {
  setActiveModule: (data: { module?: string, section?: string }) => void;
}

export const pageWithTOC = <P extends PageWithTOCTransferProps>(
  Component: React.ComponentType<P>) => (props: object) => {
    const history = useHistory();
    // const {} = useCatchSectionByScroll();
    // const { section, module } = useHashedModule();
    const [activeModule, setActiveModule] = useState<{ module?: string, section?: string }>({
    // module,
    // section,
    });

    useEffect(() => {
      if (activeModule.module) {
      // history.push(`#${activeModule.module}`);
        const elem = document.getElementById(activeModule.module);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      }

      if (activeModule.module && activeModule.section) {
      // history.push(`#${activeModule.module}|${activeModule.section}`);
        const elem = document.getElementById(activeModule.section);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      }
    }, [activeModule, history]);

    return <Component {...props as P} setActiveModule={setActiveModule} />;
  };
