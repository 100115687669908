import React from 'react';
import { Box, Contact, ExpressSetupTile, ShimmerBox, Text } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import theme from '@/styles/theme';

import ExpressSetupActivityLogsBlock from './ActivityLogs/ExpressSetupActivityLogsBlock';
import AddEditContactModal from './AddEditContactModal';
import EditAddressModal from './EditAddressModal';
import { ExpressSetupWizardStepType } from './ExpressSetup.types';
import ExpressSetupBanner from './ExpressSetupBanner';
import ExpressSetupFooter from './ExpressSetupFooter';
import ExpressSetupTopbar from './ExpressSetupTopbar';
import { useExpressSetupActions } from './hooks';

const ExpressSetup: React.FC = () => {
  const {
    updatedContact,
    contactModalVisible,
    setContactModalVisible,
    addressModalVisible,
    expressSetupData,
    isLoading,
    contacts,
    handleUpdateContact,
    handleRedirectToWizardStep,
    handleOpenAddressModal,
    handleOpenContactModal,
  } = useExpressSetupActions();

  return (
    <Box
      width="100%"
      data-testid="express-setup-wrapper"
      justify="between"
      height="fit-content"
      margin={{ bottom: 'l' }}
    >
      <Box>
        <ExpressSetupTopbar
          logo={expressSetupData?.logo}
          orgId={expressSetupData?.orgId}
          isLoading={isLoading}
        />
        <Box width={theme.defaultMinContentWidth} alignSelf="center" margin="l">
          <ExpressSetupBanner
            expressSetupData={expressSetupData}
            isLoading={isLoading}
            onSetAddressModalVisible={handleOpenAddressModal}
            onClickMainContact={() => handleRedirectToWizardStep(ExpressSetupWizardStepType.MainContact)}
            onClickOpenContactModal={handleOpenContactModal}
            onClickUpdateContact={handleUpdateContact}
          />
          <Box margin={{ vertical: 'l' }}>
            {isLoading ? <ShimmerBox width="106px" height="32px" round="button2Round"  margin={{ bottom: 'l' }} /> : <Text weight={700} size="24px" color="textTitle" margin={{ bottom: 'l' }}>Required</Text>}
            <Box direction="row" gap="l" justify="between">
              {
                isLoading ? <ShimmerBox width="478px" height="200px" /> : (
                  <ExpressSetupTile
                    type="mainContact"
                    mainContact={expressSetupData?.mainContact as Contact}
                    onClick={() => handleRedirectToWizardStep(ExpressSetupWizardStepType.MainContact)}
                    onEdit={() => handleRedirectToWizardStep(ExpressSetupWizardStepType.MainContact, true)}
                    title="Main Contact"
                    actionButtonText="Add Main Contact"
                    description={expressSetupData?.mainContact?.role === Roles.admin1 ? 'Full Access' : expressSetupData?.mainContact ? 'No Access' : 'Responsible person for employer creation and activation.'}
                    isLoading={isLoading}
                  />
                )
              }
              {
                isLoading ? <ShimmerBox width="478px" height="200px" /> : (
                  <ExpressSetupTile
                    type="bankAccount"
                    accountNumber={expressSetupData?.accountNumber}
                    actionButtonText="Add Bank Account"
                    title="Bank Account"
                    onClick={() => handleRedirectToWizardStep(ExpressSetupWizardStepType.BankAccount)}
                    isLoading={isLoading}
                  />
                ) 
              }
            </Box>
            {
              isLoading ? <ShimmerBox width="100%" height="420px" margin={{ top: 'l' }} /> : (
                <Box margin={{ top: 'l' }}>
                  <ExpressSetupTile
                    minHeight='420px'
                    type="plans"
                    plans={expressSetupData?.plans}
                    planStartDate={expressSetupData?.planStartDate}
                    planEndDate={expressSetupData?.planEndDate}
                    onClick={() => handleRedirectToWizardStep(ExpressSetupWizardStepType.Plans)}
                    title="Plans"
                    actionButtonText="Configure"
                    isLoading={isLoading}
                  />
                </Box>
              )
            }
          </Box>
          <Box margin={{ top: 'l' }}>
            {isLoading ? <ShimmerBox width="106px" height="32px" round="button2Round"  margin={{ bottom: 'l' }} /> : <Text weight={700} size="24px" color="textTitle" margin={{ bottom: 'l' }}>Optional</Text>}
            <Box direction="row" gap="l" justify="between">
              {
                isLoading ? <ShimmerBox width="478px" height="200px" /> : (
                  <ExpressSetupTile
                    width='478px'
                    minHeight='420px'
                    type="contacts"
                    onClick={() => setContactModalVisible(true)}
                    onUpdateContact={handleUpdateContact}
                    contacts={contacts?.length ? contacts as Contact[] : undefined}
                    title="Contacts"
                    actionButtonText="Add Contact"
                    isLoading={isLoading}
                  />
                )
              }
              {
                isLoading ? <ShimmerBox width="478px" height="200px" /> : (
                  <ExpressSetupTile
                    type="copays"
                    title="Copays"
                    isLoading={isLoading}
                    copays={expressSetupData?.copays}
                  />
                ) 
              }
            </Box>
          </Box>
          {
            isLoading ? <ShimmerBox height="72px" margin={{ vertical: 'l' }} /> : <ExpressSetupActivityLogsBlock expressSetup={expressSetupData} />
          }
          <AddEditContactModal
            visible={contactModalVisible}
            onToggleVision={setContactModalVisible}
            onSetUpdatedContact={handleUpdateContact}
            updatedElement={updatedContact}
          />
          <EditAddressModal
            visible={addressModalVisible}
            onToggleVision={handleOpenAddressModal}
            address={expressSetupData?.address}
          />
        </Box>
        <ExpressSetupFooter expressSetupData={expressSetupData} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
export default ExpressSetup;
