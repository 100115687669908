import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import regexp from '@/common/regexp';
import { Relationship, RelationshipResponse } from '@/modules/transaction/transaction.types';

export const useGetRelationshipListQuery = () => {
  const { data, isLoading } = useQuery(
    [PATHS.RELATIONSHIP_LIST],
    () => api.get<RelationshipResponse[]>(PATHS.RELATIONSHIP_LIST),
  );

  const relationshipList: Relationship[] = (data?.data || []).map((relation) => ({
    id: `${relation.id}`,
    name: relation.name.replace(regexp.DASH_SYMBOL, ' '),
    value: relation.name,
  }));

  return {
    relationshipList,
    isLoading,
  };
};
