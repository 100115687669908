import React from 'react';
import { Box, FlexList, ListItemBase } from '@common-fe/common-fe';

import ListPlaceholder from '@/modules/ProcessingDefinition/ListPlaceholder';

import { useScheduledContributionCalendars } from '../hooks';

import ScheduledContributionCalendarItem from './ScheduledContributionCalendarItem';
const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'name',
    title: 'Name',
    flex: 2,
    style: {
      marginLeft: '24px'
    }
  },
  {
    key: 'frequency',
    title: 'Frequency',
    flex: 2,
  },
  {
    key: 'nextScheduledTime',
    title: 'Next scheduled date',
    flex: 2,
  },
  {
    key: 'recipients',
    title: 'Recipients',
    flex: 2,
  
  },
  {
    key: 'accounts',
    title: 'Accounts',
    flex: 6,
  
  },
];

const ScheduledContributionCalendars = () => {
  const { formattedData } = useScheduledContributionCalendars();
  const total = 10;
  return (
    <Box pad="l" round="moduleRound" background="module" margin={{ bottom: 'l' }}>
      
      
      <FlexList
        total={total}
        headers={LIST_HEADERS}
        rows={[]}
        loading={false}
        moreCount={10}
        pad={{ horizontal: '0px', vertical: '0px' }}
        // options={options}
        placeholder={<ListPlaceholder>There are no calendars</ListPlaceholder>}
        footer={(
          <>
            {formattedData.map((item) => (
              <ScheduledContributionCalendarItem 
                key={item.id}
                id={item.id}
                name={item.name}
                frequency={item.frequency}
                scheduledTime={item.scheduledTime}
                recipients={item.recipients}
                accounts={item.accounts}
                plans={item.plans}
      
              />
            ))}
            {/* {
              total ? (
                <PaginationBar
                  page={1}
                  total={total}
                  pageSize={10}
                  onChangePage={() => { }}
                  onChangePageSize={() => { }}
                />
              ) : undefined
            } */}
          </>
        )}
      />
    </Box>
  );
};
export default ScheduledContributionCalendars;
