import React, { useMemo } from 'react';
import { Box,ListItem, TextWithPointer } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE } from '@/common/constants';
import { ListItemType, PaginationParams } from '@/common/types';
import ManyItemsTooltip from '@/components/elements//ManyItemsTooltip/ManyItemsTooltip';
import InfoTile from '@/components/elements/InfoTile/InfoTile';
import { useAuthStore } from '@/modules/user/stores';
import theme from '@/styles/theme';

import useClaimsProcessorsListQuery from './useClaimsProcessorsList.query';

const highlightedRowStyle = {
  borderSize: '2px',
  borderColor: theme.colors.accentActive,
  boxShadow: theme.shadows.highlighted['box-shadow'],
};

interface Params extends PaginationParams {
  onTeamClickHandle: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useClaimsProcessorsList = (params: Params, justAddedClaimProcessorId?: string) => {
  const { claimsProcessors, isLoading, total, refetch } = useClaimsProcessorsListQuery(params);
  const { contactId } = useAuthStore();

  const formattedList = useMemo(() => claimsProcessors
    ?.map((item) => ({
      id: item.id,
      rowStyle: (justAddedClaimProcessorId && justAddedClaimProcessorId === item.id) ? highlightedRowStyle : undefined,
      fields: [
        {
          key: 'processor',
          type: ListItemType.Node,
          node: (
            <Box width="240px" margin={{ right: 'spacing24' }}>
              <InfoTile
                id={item?.id}
                title={item?.fullName}
                descriptions={item?.email ? [item.email] : []}
                icon={item?.icon}
                {...item.id === contactId ? { isMe: true } : {}}
              />
            </Box>
          ),
          flex: 'none',
        },
        {
          key: 'role',
          type: ListItemType.Node,
          node: (
            <Box width="160px" margin={{ right: 'spacing24' }} title={item.role} style={{ textTransform: 'capitalize' }}>
              <TextWithPointer color="textBody" ellipsisMode>{item.role?.replace(/_/g, ' ')?.toLowerCase() || EMPTY_FIELD_VALUE}</TextWithPointer>
            </Box>
          ),
          flex: 'none',
        },
        {
          key: 'teams',
          type: ListItemType.Node,
          node: (
            <Box margin={{ right: 'spacing24' }}>
              <ManyItemsTooltip
                showMoreLength={3}
                items={item?.teams?.map((team) => team.name || '') || []}
                clickableItems={item?.teams?.map((team) => ({
                  title: team?.name,
                  onClick: () => params.onTeamClickHandle(team.id),
                }))?.filter((clickableItem) => clickableItem?.title)}
                color={theme.colors.info}
                itemsTextStyles={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 400,
                }}
              />
            </Box>
          ),
        },
      ],
    })),
  [claimsProcessors, contactId, justAddedClaimProcessorId, params]);

  return {
    list: formattedList as ListItem[],
    isLoading,
    total,
    refetch,
  };
};

export default useClaimsProcessorsList;
