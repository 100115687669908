import { useCallback,useMemo } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RAW_DATE_FORMAT } from '@/common/constants';
import { Dependent } from '@/modules/employee/employee.types';

const CREATE_DEPENDENT_KEY = 'update_card_holder';

const formatDependentToServer = (dependent: Dependent, personId: number | string) => ({
  first_name: dependent.firstName,
  last_name: dependent.lastName,
  ...dependent.birthOn ? { local_date_birth_on: dayjs(dependent.birthOn).format(RAW_DATE_FORMAT) } : {},
  person: {
    id: personId,
  },
  relationship_type: dependent.relationshipType,
});

export const useCreateDependentQuery = (
  personId: string,
  onSuccess?: () => void,
) => {
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(CREATE_DEPENDENT_KEY, `${false}`);

    return queryGenerator;
  }, []);

  const { mutateAsync, isLoading } = useMutation(
    (value: Dependent) => api.post(
      PATHS.DEPENDENTS,
      formatDependentToServer(value, personId),
      {
        params,
      },
    ),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );

  const handleSave = useCallback(async (value: Dependent) => {
    await mutateAsync(value);
  }, [mutateAsync]);

  return {
    isLoading,
    save: handleSave,
  };
};
