import { useMemo } from 'react';

import { useStore as useAdditionalStore } from '../Additional/stores/useAdditional.store';
import { useStore as useCardOfferedStore } from '../CardOffered/stores/useCardOffered.store';
import usePlanNameStore from '../PlanName/stores/usePlanName.store';
import { useStore as usePlanYearStore } from '../PlanYear/stores/usePlanYear.store';

export default () => {
  const planNameStore = usePlanNameStore((state) => state.state);
  const planYearStore = usePlanYearStore((state) => state.state);
  const cardOfferedStore = useCardOfferedStore((state) => state.state);
  const additionalStore = useAdditionalStore((state) => state.state);
  const state = useMemo(() => ({
    ...planNameStore,
    ...planYearStore,
    ...cardOfferedStore,
    ...additionalStore,
  }), [
    additionalStore,
    cardOfferedStore,
    planNameStore,
    planYearStore,
  ]);
  return state;
};
