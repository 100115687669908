import React from 'react';
import {
  Box,
  Button,
  Inscription,
  Preloader,
  SiblingBoxesWithSpacing,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { CLAIMS_ESCALATION_REQUIREMENTS } from '@/modules/claims/ClaimsProcessor/ClaimEscalation/store';
import { useHasAccess } from '@/modules/core/hooks';
import baseTheme from '@/styles/theme';

export enum PageMode {
  ALL = 'ALL',
  MY = 'MY',
  ESCALATED = 'ESCALATED',
}

interface ButtonProps {
  isActive?: boolean;
  disabled?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
  background: ${({ isActive, theme }) => `${isActive ? theme.colors.canvas : 'transparent'}`};
  border: ${({ isActive, theme }) => `1px solid ${isActive ? theme.colors.border2 : 'transparent'}`};
  padding: 4px 23px;
  span {
    color: ${({ isActive, theme, disabled }) => {
    if (disabled) return;
    return isActive ? theme.colors.textBody : theme.colors.textAccent;
  }};
  }
`;

interface Props {
  totalAll?: number;
  totalMy?: number;
  totalEscalated?: number;
  setPageMode: (val: PageMode) => void;
  mode: PageMode;
  hasAccessToMyAssignments?: boolean;
}

const ClaimsFilterSection: React.FC<Props> = ({
  totalAll,
  totalMy,
  totalEscalated,
  setPageMode,
  mode,
  hasAccessToMyAssignments,
}) => {
  const isHasAccessToEscalatedClaims = useHasAccess(CLAIMS_ESCALATION_REQUIREMENTS);
  return (
    <SiblingBoxesWithSpacing
      space={baseTheme.spacings.spacing24}
      direction="row"
      background="module"
      pad="spacing4"
      round="container1Round"
      align="center"
    >
      {hasAccessToMyAssignments ? (
        <SiblingBoxesWithSpacing>
          <StyledButton
            label={typeof totalMy === 'undefined' ? (
              <Box direction="row" align="center">
                <Inscription weight={mode === PageMode.MY ? 'bold' : 'normal'}>My Assignments</Inscription>
                <Box margin={{ left: 'spacing8' }}><Preloader color="iconOnColor" size={12} thinSize={2} /></Box>
              </Box>
            ) : <Inscription weight={mode === PageMode.MY ? 'bold' : 'normal'}>{`My Assignments (${totalMy})`}</Inscription>}
            type="button"
            borderless
            isActive={mode === PageMode.MY}
            onClick={() => setPageMode(PageMode.MY)}
            // disabled={typeof totalMy === 'undefined'}
          />
        </SiblingBoxesWithSpacing>
      ) : null}

      <SiblingBoxesWithSpacing>
        <StyledButton
          label={typeof totalAll === 'undefined' ? (
            <Box direction="row" align="center">
              <Inscription weight={mode === PageMode.ALL ? 'bold' : 'normal'}>All Claims</Inscription>
              <Box margin={{ left: 'spacing8' }}><Preloader color="iconOnColor" size={12} thinSize={2} /></Box>
            </Box>
          ) : <Inscription weight={mode === PageMode.ALL ? 'bold' : 'normal'}>{`All Claims (${totalAll})`}</Inscription>}
          type="button"
          borderless
          isActive={mode === PageMode.ALL}
          onClick={() => setPageMode(PageMode.ALL)}
          // disabled={typeof totalAll === 'undefined'}
        />
      </SiblingBoxesWithSpacing>

      {isHasAccessToEscalatedClaims && (
        <SiblingBoxesWithSpacing>
          <StyledButton
            label={typeof totalEscalated === 'undefined' ? (
              <Box direction="row" align="center">
                <Inscription weight={mode === PageMode.ESCALATED ? 'bold' : 'normal'}>Escalated</Inscription>
                <Box margin={{ left: 'spacing8' }}><Preloader color="iconOnColor" size={12} thinSize={2} /></Box>
              </Box>
            ) : <Inscription weight={mode === PageMode.ESCALATED ? 'bold' : 'normal'}>{`Escalated (${totalEscalated})`}</Inscription>}
            type="button"
            borderless
            isActive={mode === PageMode.ESCALATED}
            onClick={() => setPageMode(PageMode.ESCALATED)}
            // disabled={typeof totalEscalated === 'undefined'}
          />
        </SiblingBoxesWithSpacing>
      )}
    </SiblingBoxesWithSpacing>
  );
};

export default ClaimsFilterSection;
