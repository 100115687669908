import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ContactInfoPayload, formatContactInfoData } from './formatData';

const useContactInfoQuery = (contsctId?: string) => {
  const { data, isLoading, isSuccess } = useQuery(
    [PATHS.CONTACT_INFO(contsctId)],
    () => api.get<ContactInfoPayload>(PATHS.CONTACT_INFO(contsctId)),
    {
      enabled: !!contsctId,
    }
  );

  const formattedData = useMemo(() => formatContactInfoData(data?.data), [data]);

  return {
    formattedData,
    isLoading,
    isSuccess,
  };
};

export default useContactInfoQuery;
