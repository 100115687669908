import React, { useMemo } from 'react';
import { AppButton,Box } from '@common-fe/common-fe';

interface Props {
  onApply: () => void;

  count: number;
  maxCount: number;
}
const FilterApply: React.FC<Props> = ({
  onApply, count, maxCount,
}) => {
  const isDisabled = useMemo(() => count !== maxCount, [count, maxCount]);
  return (
    <Box width="small" margin={{ left: 'auto' }}>

      <AppButton
        disabled={isDisabled}
        onClick={onApply}
        testId="enrollment-filter-apply"
        width="small"
        style={{ padding: 0 }}
      >
        {isDisabled ? `Select parameters (${count}/${maxCount})` : 'Search'}

      </AppButton>
    </Box>
  );
};
export default FilterApply;
