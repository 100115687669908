import React from 'react';
import {
  Anchor,
  Box,
  ClickToCopy,
  CopyIcon,
  RawButton,
  Text,
  useCopyToClipboard,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { EMPTY_FIELD_VALUE } from '@/common/constants';

interface Props {
  value?: string;
  isCopyMode?: boolean;
}

export const StyledAnchor = styled(Anchor)`
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;
`;
const LinkField: React.FC<Props> = ({ value, isCopyMode }) => {
  const copyToClipboard = useCopyToClipboard(value || '');
  if (!value) {
    return <Text size="medium">{EMPTY_FIELD_VALUE}</Text>;
  }
  return (
    <Box direction="row" align="center">
      {isCopyMode ? (
        <ClickToCopy isIconVisible  title={value} testId="copy_link">
          <StyledAnchor target="_blank" weight={400} size="medium">
            {value}
          </StyledAnchor>
        </ClickToCopy>
      ) : (
        <StyledAnchor target="_blank" weight={400} size="medium" href={value}>
          {value}
        </StyledAnchor>
      )}
    </Box>
  );
};

export default LinkField;
