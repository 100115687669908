import React, { useMemo } from 'react';
import {
  Box,
  Preloader, WarningIcon,   WarnModal, } from '@common-fe/common-fe';
import _ from 'lodash';

import { useModalType } from '../stores';

interface Props {
  mode?: string;
  organization?: string;
  handleSave?: () => void;
  handleActivate?: () => void;
}

const FinalSetUpModal: React.FC<Props> = ({
  mode, organization, handleSave, handleActivate,
}) => {
  const modeTitle = useMemo(() => _.startCase(mode), [mode]);

  const SERVER_ERROR_TITLE = 'The server encountered an error processing the request';
  const SERVER_ERROR_TEXT = `It doesn't appear to have affected your data, but we cannot save the ${modeTitle} with the latest records.
            Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`;
  const ACTIVATE_ERROR_TEXT = `Please correct the highlighted errors to activate the ${modeTitle}`;
  const SAVE_ERROR_TEXT = `Please correct the highlighted errors to save the ${modeTitle}`;
  const ACTIVATING_TEXT = `Activation of ${organization}`;
  const SUCCESSFULLY_ACTIVATED_TEXT = `${organization} is successfully activated`;
  const UNABLE_TO_ACTIVATE_TITLE = `${organization} is being activated…`;
  const UNABLE_TO_ACTIVATE_TEXT = 'We will try to complete the activation within the next hour. If the organization fails to activate, contact the Customer Support Center.';

  const {
    pendingModal,
    activateModal,
    activateSaveModal,
    isEmptySaveModal,
    successfullyActivatedModal,
    unableToActivateModal,
    isActivatingModal,
    setActivateModal,
    setActivateSaveModal,
    setPendingModal,
    setIsEmptySaveModal,
    setIsActivatingModal,
    setSuccessfullyActivatedModal,
    setUnableToActivateModal,
  } = useModalType();

  return (
    <>
      <WarnModal
        visible={pendingModal}
        onSetVisible={setPendingModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleSave}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={activateSaveModal}
        onSetVisible={setActivateSaveModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleActivate}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={isEmptySaveModal}
        onSetVisible={setIsEmptySaveModal}
        header={SAVE_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        visible={activateModal}
        onSetVisible={setActivateModal}
        header={ACTIVATE_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        visible={isActivatingModal}
        onSetVisible={setIsActivatingModal}
        header={ACTIVATING_TEXT}
        iconNode={(<Preloader />)}
        isCloseButtonHidden
        isButtonsHidden
      >
        <Box pad="s" />
      </WarnModal>
      <WarnModal
        visible={successfullyActivatedModal}
        onSetVisible={setSuccessfullyActivatedModal}
        header={SUCCESSFULLY_ACTIVATED_TEXT}
        submitButtonText="Close"
        isSuccessIcon
      />
      <WarnModal
        visible={unableToActivateModal}
        onSetVisible={setUnableToActivateModal}
        header={UNABLE_TO_ACTIVATE_TITLE}
        helptext={UNABLE_TO_ACTIVATE_TEXT}
        submitButtonText="Close"
        iconNode={(
          <WarningIcon size="58px" />
        )}
      />
    </>
  );
};

export default FinalSetUpModal;
