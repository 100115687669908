import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

import { PayrollCalendarFormFields, PayrollCalendarFrequency, PayrollCalendarFrequencyResponse, PayrollCalendarObject, PayrollCalendarObjectResponse } from '../payrollCalendar.types';

const calendarDataFormatter = (data?: PayrollCalendarObjectResponse) => {
  if (!data) return undefined;

  const formattedPlans = data?.calendar_plans?.map((plan) => ({
    id: `${plan.id}`,
    planCode: plan.plan_code,
  }));

  const formattedNonPlans = data?.non_calendar_plans?.map((nonPlan) => ({
    id: `${nonPlan.id}`,
    planCode: nonPlan.plan_code,
  }));

  const formattedConfig = {
    calendarId: `${data.id}`,
    [PayrollCalendarFormFields.CALENDAR_NAME]: data.name,
    [PayrollCalendarFormFields.FREQUENCY]: PayrollCalendarFrequency[data.config.frequency as keyof typeof PayrollCalendarFrequency],
    [PayrollCalendarFormFields.START_DATE]: dayjs(data.config.start_date).format(DEFAULT_DATE_FORMAT),
    ...data.config.day_of_month ? { [PayrollCalendarFormFields.DAY_OF_MONTH]: data.config.day_of_month } : {},
    ...data.config.frequency ===  PayrollCalendarFrequencyResponse.MONTHLY
      ? { [PayrollCalendarFormFields.DAY_OF_MONTH]: data.config.first_day_of_month }
      : {},
    ...data.config.day_of_week ? { [PayrollCalendarFormFields.DAY_OF_WEEK]: data.config.day_of_week } : {},
    ...data.config.frequency ===  PayrollCalendarFrequencyResponse.BI_WEEKLY
      ? { [PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK]: data.config.day_of_week }
      : {},
    ...data.config.first_day_of_month ? { [PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1]: data.config.first_day_of_month } : {},
    ...data.config.second_day_of_month ? { [PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2]: data.config.second_day_of_month } : {},
    ...data.config.month ? { [PayrollCalendarFormFields.DATE_OF_YEAR]: dayjs(data.schedule[0]).format(DEFAULT_DATE_FORMAT) } : {},
  };

  const formattedData: PayrollCalendarObject = {
    id: `${data.id}`,
    name: data.name,
    frequencyType: data.frequency_type,
    createdAt: data.created_at,
    startDate: data.start_date,
    endDate: data.end_date,
    status: data.status,
    organizationId: data.organization_id,
    organizationPath: data.organization_path,
    nextAutoprolongAt: data.next_autoprolong_at,
    config: formattedConfig,
    groupType: data.group_type,
    schedule: data.schedule,
    calendarPlans: formattedPlans,
    nonCalendarPlans: formattedNonPlans,
    calendarPayrollGroups: data.calendar_payroll_groups,
    nonCalendarPayrollGroups: data.non_calendar_payroll_groups,
  };

  return formattedData;
};

export const QUERY_KEY = 'useGetPayrollCalendarByIdQuery';

export const useGetPayrollCalendarByIdQuery = (calendarId?: string) => {
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [QUERY_KEY, calendarId],
    () => api.get<PayrollCalendarObjectResponse>(PATHS.GET_PAYROLL_CALENDAR_BY_ID(calendarId)),
    {
      enabled: !!calendarId,
      cacheTime: 0,
    },
  );

  const updatedData = useMemo(() => data?.data, [data]);
  const formattedData = useMemo(() => calendarDataFormatter(updatedData), [updatedData]);

  return {
    isLoading,
    error,
    isSuccess,
    isError,
    refetch,
    data: formattedData,
  };
};
