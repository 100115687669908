import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  Box, FlexControlledForm, Preloader, useDebounceState,
} from '@common-fe/common-fe';

import PlanYearForm from '@/modules/plan/PlanYearForm/PlanYearForm';
import theme from '@/styles/theme';

import { NEW_PLAN_YEAR_KEY } from '../CreatePlanModal.constants';

import { PlanYearPickerPayload,useStore } from './stores/usePlanYearPicker.store';
import { usePlanYearPickerFields, usePlanYears } from './hooks';

const DEBOUNCE_LOADER_DELAY = 1500;

interface Props {
  testId?: string;
}
const PlanYearPicker: React.FC<Props> = ({ testId = '' }) => {
  const {
    planYearState, currentPlanYear, loading, setLoading,
  } = usePlanYears();

  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const currentState = useStore((state) => state.state);
  const fields = usePlanYearPickerFields(currentState);
  const { setValues, values } = useDebounceState<Partial<PlanYearPickerPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  const handleSetDebounceValue = useCallback((newValues:Partial<PlanYearPickerPayload>) => {
    if (newValues.planYear && (newValues.planYear !== currentPlanYear)) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, DEBOUNCE_LOADER_DELAY);
    }

    setValues(newValues);
  }, [currentPlanYear, setLoading, setValues]);
  const isPlanPicked = useMemo(() => !!currentPlanYear, [currentPlanYear]);
  return (

    <Box round="container1Round" background="canvas" direction="column" margin={{ top: 'spacing24' }}>
      <FlexControlledForm
        fields={fields}
        testId={`${testId}_plan-year_picker`}
        formStyle={{ marginBottom: isPlanPicked ? theme.spacings.spacing8 : undefined }}
        showError={showErrors}
        editMode
        wrapperStyle={{
          border: 'none',
        }}
        onChangeValues={handleSetDebounceValue}
        isModalType
        footer={
          isPlanPicked && !loading && (
            <PlanYearForm
              testId={`${testId}`}
              isCreateMode={currentPlanYear === NEW_PLAN_YEAR_KEY}
              isViewMode={currentPlanYear !== NEW_PLAN_YEAR_KEY}
              hideName={currentPlanYear !== NEW_PLAN_YEAR_KEY}
              key={currentPlanYear}
              isModalType
              defaultValues={planYearState}
              disabled={currentPlanYear !== NEW_PLAN_YEAR_KEY}
              style={{ marginTop: 0 }}
              wrapperStyle={{
                border: 'none',
              }}
            />
          )
        }
      />
      {loading && (
        <Box align="center" justify="center" margin={{ bottom: 'spacing24' }}>
          <Preloader />
        </Box>
      )}

    </Box>
  );
};

export default PlanYearPicker;
