 
import React from 'react';

import PlanTemplateFormModal from './PlanTemplateFormModal';

interface Props {
  disabled?: boolean;
}

const PlanTemplates: React.FC<Props> = ({ disabled }) => (
  <PlanTemplateFormModal
    disabled={disabled}
  />
);

export default PlanTemplates;
