// import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { FieldStateTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { HealthPlanStatuses,Plan } from '@/modules/HealthPlan/HealthPlan.types';
import { HEALTH_PLAN_LIST_QUERY_NAME } from '@/modules/HealthPlan/queries/useHealthPlansList.query';
import { QUERY_KEY as PLAN_YEAR_QUERY_KEY } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';

interface Payload {
  planYearId: number;
  parentId: number;
  name?: string;
  planType?: string;
  organizationId?: string;
  status?: HealthPlanStatuses;
}
export const useCreateHealthPlanMutations = (redirectMode?: boolean) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { organizationId } = useCurrentOrganisationStore();
  const mutation = useMutation(
    (data: Partial<Payload>) => api.post<Plan>(PATHS.HEALTH_PLANS, {
      plan_year_id: data.planYearId,

      name: data.name ? {
        name: data.name,
        name_state: FieldStateTypes.Modifiable,
      } : undefined,

      parent_id: data.parentId,
      is_plan: true,
      plan_status: data.status,
      organization_id: data.organizationId || organizationId ? _.toNumber(data.organizationId || organizationId) : '',
    }), {
      onSuccess: (response) => {
        const id = _.get(response, 'data.id');
        queryClient.invalidateQueries(HEALTH_PLAN_LIST_QUERY_NAME);
        queryClient.invalidateQueries(PLAN_YEAR_QUERY_KEY);
        if (redirectMode) {
          history.push(ROUTES.HEALTH_PLAN_SETUP_BY_ID(id));
        }
      },
    },
  );
  return mutation.mutateAsync;
};

export default useCreateHealthPlanMutations;
