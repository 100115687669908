 
import React, { useCallback, useMemo } from 'react';
import {
  Box, Button,
} from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
// import { Access } from '@/modules/core/components';
import { useCurrentOrganization } from '@/modules/core/hooks';

import { useEditModeStore } from '../stores';

interface Props {
  isDraftMode?: boolean;
  isPlanActive?: boolean;
  isTemplateMode?: boolean;
}

const PlanTopbarEditModeButton: React.FC<Props> = ({ isDraftMode, isTemplateMode, isPlanActive }) => {
  const setEditMode = useEditModeStore((state) => state.setEditMode);
  const { observingOrganization: { type } } = useCurrentOrganization();

  const handleSetEditMode = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  return (
    <Box direction="row">
      <Box width="100px">
        {
          !isSubsidiary && (
            <Access
              accessRequirements={[
                {
                  permission: isTemplateMode
                    ? Permissions.PLAN_TEMPLATE_SETUP
                    : Permissions.EDIT_DRAFT_PLAN,
                },
              ]}
            >
              <Button
                secondary
                style={{ flex: 1, padding: 0 }}
                onClick={handleSetEditMode}
                type="button"
                size="medium"
                label={isDraftMode || isPlanActive ? 'Edit' : 'Configure'}
              />
            </Access>
          )
        }
      </Box>
    </Box>
  );
};

export default PlanTopbarEditModeButton;
