import React, { useEffect, useMemo } from 'react';
import { Box, Heading, Hint, Inscription } from '@common-fe/common-fe';

import {
  ACCEPTED_LOGO_FILE_TYPES,
  LetterIconSizeEnum,
  MAX_LOGO_FILE_SIZE,
  PermissionKey,
} from '@/common/constants';
import { HorizontalLine } from '@/components/elements';
import { Icon } from '@/components/elements/Icon/Icon';
import { useOrganizationSetupLogo } from '@/modules/employer/components/SetupEmployer/Branding/hooks/useOrganizationSetupLogo';
import { DEFAULT_ERROR_STATE, useStore } from '@/modules/employer/components/SetupEmployer/Branding/store/useOrganizationSetupLogo.store';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import ToEditButton from '../../components/ToEditButton';
import { RowDescription, RowTitle } from '../Branding.styles';
import { LogoType } from '../branding.types';

import BrandingLogo from './components/BrandingLogo';
import LargeLogo from './components/LargeLogo';
import { StyledInfoIcon } from './BrandingSection.styles';

interface Props {
  onDirty?: (isDirty: boolean) => void;
  isTerminated?: boolean;
  isLoading?: boolean;
}

export const BrandingSection: React.FC<Props> = ({ onDirty, isTerminated, isLoading }) => {
  const {
    type,
    name,
    largeDarkLogo,
    largeLightLogo,
    miniDarkLogo,
    miniLightLogo,
  } = useOrganizationSetupLogo();
  const largeLogoPathPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.LARGE_LOGO_PATH,
  );
  const minimizedLogoPathPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.MINIMIZED_LOGO_PATH,
  );

  const setLargeDarkLogo = useStore((store) => store.setLargeDarkLogo);
  const setLargeLightLogo = useStore((store) => store.setLargeLightLogo);
  const setMiniDarkLogo = useStore((store) => store.setMiniDarkLogo);
  const setMiniLightLogo = useStore((store) => store.setMiniLightLogo);
  const clearLogos = useStore((store) => store.handleClear);
  const setSizeError = useStore((store) => store.setSizeError);
  const setFormatError = useStore((store) => store.setFormatError);
  const setSecurityError = useStore((store) => store.setSecurityError);
  const handleResetErrors = useStore((store) => store.handleResetErrors);
  const sizeError = useStore((store) => store.sizeError);
  const formatError = useStore((store) => store.formatError);
  const securityError = useStore((store) => store.securityError);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadError = (uploadType: LogoType, file: any) => {
    const isAcceptanceSize = file.size <= MAX_LOGO_FILE_SIZE;
    const currentFileFormat = file.name.split('.').reverse()[0].toLowerCase();

    if (!isAcceptanceSize) {
      if (uploadType === LogoType.largeDark) {
        setSizeError({
          ...sizeError,
          largeDarkLogo: true,
        });
      } else if (uploadType === LogoType.largeLight) {
        setSizeError({
          ...sizeError,
          largeLightLogo: true,
        });
      } else if (uploadType === LogoType.minimizedDark) {
        setSizeError({
          ...sizeError,
          miniDarkLogo: true,
        });
      } else if (uploadType === LogoType.minimizedLight) {
        setSizeError({
          ...sizeError,
          miniLightLogo: true,
        });
      }
    } else {
      setSizeError(DEFAULT_ERROR_STATE);
    }

    if (!ACCEPTED_LOGO_FILE_TYPES.includes(currentFileFormat)) {
      if (uploadType === LogoType.largeDark) {
        setFormatError({
          ...formatError,
          largeDarkLogo: true,
        });
      } else if (uploadType === LogoType.largeLight) {
        setFormatError({
          ...formatError,
          largeLightLogo: true,
        });
      } else if (uploadType === LogoType.minimizedDark) {
        setFormatError({
          ...formatError,
          miniDarkLogo: true,
        });
      } else if (uploadType === LogoType.minimizedLight) {
        setFormatError({
          ...formatError,
          miniLightLogo: true,
        });
      }
    } else {
      setFormatError(DEFAULT_ERROR_STATE);
    }

    if (uploadType === LogoType.largeDark) {
      setSecurityError({
        ...securityError,
        largeDarkLogo: false,
      });
    } else if (uploadType === LogoType.largeLight) {
      setSecurityError({
        ...securityError,
        largeLightLogo: false,
      });
    } else if (uploadType === LogoType.minimizedDark) {
      setSecurityError({
        ...securityError,
        miniDarkLogo: false,
      });
    } else if (uploadType === LogoType.minimizedLight) {
      setSecurityError({
        ...securityError,
        miniLightLogo: false,
      });
    }

    if (!ACCEPTED_LOGO_FILE_TYPES.includes(currentFileFormat) || !isAcceptanceSize) return false;

    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUploadLogo = (uploadType: LogoType, event: any) => {
    event.preventDefault();

    const currentFile = event.target.files[0];

    if (!handleUploadError(uploadType, currentFile)) {
      return;
    }

    const currentPreparedFile = Object.assign(currentFile, {
      preview: URL.createObjectURL(currentFile),
    });


    if (uploadType === LogoType.largeDark) setLargeDarkLogo(currentPreparedFile);
    if (uploadType === LogoType.largeLight) setLargeLightLogo(currentPreparedFile);
    if (uploadType === LogoType.minimizedDark) setMiniDarkLogo(currentPreparedFile);
    if (uploadType === LogoType.minimizedLight) setMiniLightLogo(currentPreparedFile);
  };

  const isDirty = useMemo(() => !!largeDarkLogo || !!largeLightLogo || !miniDarkLogo || !miniLightLogo,
    [largeDarkLogo, largeLightLogo, miniDarkLogo, miniLightLogo]);

  useEffect(() => {
    if (onDirty) onDirty(isDirty);
  }, [isDirty, onDirty]);

  useEffect(() => () => {
    clearLogos();
  }, [clearLogos]);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => () => handleResetErrors(), []);

  if (isLoading) return null;

  return (
    <Box
      data-testid="BrandingSection_test-id"
      // {...getRootProps({ isDragActive })}
      style={{ cursor: 'default' }}
    >
      <Heading
        level={3}
        size="21px"
        style={{ lineHeight: '40px' }}
        margin={{ bottom: 'spacing16' }}
      >
        Logo
      </Heading>

      <Box direction="row">
        <Box width={{ width: '288px', min: '288px' }} margin={{ right: 'spacing8' }}>
          <RowTitle>
            Large logo
          </RowTitle>
          <RowDescription>
            The large logo prominently displays the company’s brand logo, reinforcing brand recognition for users.
            <br />
            Recommended size: 600×200 pixels.
            <br />
            We support: SVG, JPG, PNG, or GIF.
          </RowDescription>
        </Box>

        <Box direction="row">
          <Box margin={{ left: 'spacing24' }}>
            <BrandingLogo
              id="dark-large-logo"
              title={(
                <Box direction="row" align="center">
                  Dark logo
                  <Hint
                    hintWidth={250}
                    hintElement={(
                      <Box margin={{ left: 'spacing2' }}>
                        <StyledInfoIcon
                          color="iconSecondary"
                          size="small"
                          cursor="pointer"
                        />
                      </Box>
                    )}
                  >
                    <Inscription textAlign="center" size="small">
                      To ensure your logo always looks its best, we ask for both a light and dark version.
                      This allows your logo to stand out on different backgrounds, creating a smooth user experience.
                    </Inscription>
                  </Hint>
                </Box>
              )}
              description="Must be contrasted with light backgrounds."
              logo={<LargeLogo logoSrc={largeDarkLogo} />}
              background="module"
              disabled={largeLogoPathPermProps?.disabled}
              onSetLogo={setLargeDarkLogo}
              onUploadLogo={(event) => handleUploadLogo(LogoType.largeDark, event)}
              onFormatError={(value) => setFormatError({
                ...formatError,
                largeDarkLogo: value,
              })}
              onSizeError={(value) => setSizeError({
                ...sizeError,
                largeDarkLogo: value,
              })}
              sizeError={sizeError.largeDarkLogo}
              formatError={formatError.largeDarkLogo}
              securityError={securityError.largeDarkLogo}
              missingLogo={!largeDarkLogo}
            />
          </Box>
          
          <Box margin={{ left: 'spacing72' }}>
            <BrandingLogo
              id="light-large-logo"
              title="Light logo"
              description="Must be contrasted with dark backgrounds."
              logo={<LargeLogo logoSrc={largeLightLogo} />}
              background="navigationBackground"
              disabled={largeLogoPathPermProps?.disabled}
              onSetLogo={setLargeLightLogo}
              onUploadLogo={(event) => handleUploadLogo(LogoType.largeLight, event)}
              onFormatError={(value) => setFormatError({
                ...formatError,
                largeLightLogo: value,
              })}
              onSizeError={(value) => setSizeError({
                ...sizeError,
                largeLightLogo: value,
              })}
              sizeError={sizeError.largeLightLogo}
              formatError={formatError.largeLightLogo}
              securityError={securityError.largeLightLogo}
              missingLogo={!largeLightLogo}
            />
          </Box>
        </Box>
      </Box>

      <HorizontalLine />

      <Box direction="row">
        <Box width={{ width: '288px', min: '288px' }} margin={{ right: 'spacing8' }}>
          <RowTitle>
            Minimized logo
          </RowTitle>
          <RowDescription>
            For better visibility of important info, navigation can be minimized. The minimized navigation utilizes a minimized logo for optimal display.
            <br />
            Recommended size: 200×200 pixels.
            <br />
            We support: SVG, JPG, PNG, or GIF.
          </RowDescription>
        </Box>

        <Box direction="row">
          <Box margin={{ left: 'spacing24' }}>
            <BrandingLogo
              id="dark-mini-logo"
              title="Dark logo"
              logo={(
                <Icon
                  size={LetterIconSizeEnum.LOGO}
                  type={type}
                  text={name}
                  sizePx="40"
                  src={miniDarkLogo}
                />  
              )}
              background="module"
              disabled={minimizedLogoPathPermProps?.disabled}
              onSetLogo={setMiniDarkLogo}
              onUploadLogo={(event) => handleUploadLogo(LogoType.minimizedDark, event)}
              onFormatError={(value) => setFormatError({
                ...formatError,
                miniDarkLogo: value,
              })}
              onSizeError={(value) => setSizeError({
                ...sizeError,
                miniDarkLogo: value,
              })}
              sizeError={sizeError.miniDarkLogo}
              formatError={formatError.miniDarkLogo}
              securityError={securityError.miniDarkLogo}
              missingLogo={!miniDarkLogo}
            />
          </Box>

          <Box margin={{ left: 'spacing72' }}>
            <BrandingLogo
              id="light-mini-logo"
              title="Light logo"
              logo={(
                <Icon
                  size={LetterIconSizeEnum.LOGO}
                  type={type}
                  text={name}
                  sizePx="40"
                  src={miniLightLogo}
                />  
              )}
              background="navigationBackground"
              disabled={minimizedLogoPathPermProps?.disabled}
              onSetLogo={setMiniLightLogo}
              onUploadLogo={(event) => handleUploadLogo(LogoType.minimizedLight, event)}
              onFormatError={(value) => setFormatError({
                ...formatError,
                miniLightLogo: value,
              })}
              onSizeError={(value) => setSizeError({
                ...sizeError,
                miniLightLogo: value,
              })}
              sizeError={sizeError.miniLightLogo}
              formatError={formatError.miniLightLogo}
              securityError={securityError.miniLightLogo}
              missingLogo={!miniLightLogo}
            />
          </Box>

        </Box>
      </Box>

      <HorizontalLine />

      <Box direction="row">
        <Box width="288px" margin={{ right: 'spacing8' }}>
          <RowTitle>
            Favicon
          </RowTitle>
          <RowDescription>
            Your minimized logo is displayed as a favicon in browser tabs and breadcrumbs.
          </RowDescription>
        </Box>

        <Box margin={{ left: 'spacing24' }}>
          <Box
            background="module"
            pad="spacing16"
            round="fieldRound"
            direction="row"
            align="center"
          >
            <Icon
              size={LetterIconSizeEnum.LOGO}
              type={type}
              text={name}
              sizePx="16"
              src={miniLightLogo}
            />  
          </Box>
        </Box>
        

      </Box>
      {isTerminated ? null : <ToEditButton />}
    </Box>
  );
};
