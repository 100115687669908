import React from 'react';
import { Box, SelectDropdown } from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';
import { usePlanTemplatesQuery } from '@/modules/plan/queries';

interface Props {
  value: OptionKey;
  onChange: (value: OptionKey) => void;
}
const SelectPlanFilter: React.FC<Props> = ({ value, onChange }) => {
  const { formattedListTemplates } = usePlanTemplatesQuery();
  return (
    <Box
      width={{ max: '260px' }}
      fill
    >
      <SelectDropdown
        testId="enrolment-matrix-template-filter"
        id="purpose"
        name="Purpose"
        disabled={false}
        placeholder="Select plan template"
        value={value}
        onChange={onChange}
        singleMode
        activeTitle
        options={formattedListTemplates}
      />
    </Box>
  );
};

export default SelectPlanFilter;
