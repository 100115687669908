import { useEffect, useMemo, useState } from 'react';
import { ActivityType } from '@common-fe/common-fe';

import { Option,OptionKey } from '@/common/types';
import {
  TransactionStatus, TransactionStatusFilterOptions,
} from '@/modules/transaction/transaction.types';

import { usePaginationParams } from './usePaginationParams';

export default () => {
  const {
    page, setPage, setPerPage, perPage, providerSearchString, setSearchProvider, currentSearch,
  } = usePaginationParams();

  const [statuses, setStatuses] = useState<OptionKey[]>([]);
  const [categories, setCategories] = useState<OptionKey[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDates = (dates : [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const statusesOptions = useMemo<Option[]>(
    () => [
      {
        key: TransactionStatusFilterOptions.PROCESSING as string,
        value: TransactionStatus.Processing as string,
        title: TransactionStatus.Processing as string,
      },
      {
        key: TransactionStatusFilterOptions.NEEDS_HELP as string,
        value: TransactionStatus.NeedsHelp as string,
        title: TransactionStatus.NeedsHelp as string,
      },
      {
        key: TransactionStatusFilterOptions.PAID,
        value: TransactionStatus.Paid as string,
        title: TransactionStatus.Paid as string,
      },
      {
        key: TransactionStatusFilterOptions.PARTIALLY_PAID as string,
        value: TransactionStatus.PartiallyPaid as string,
        title: TransactionStatus.PartiallyPaid as string,
      },
      {
        key: TransactionStatusFilterOptions.WAITING_FOR_FUNDS as string,
        value: TransactionStatus.WaitingForFunds as string,
        title: TransactionStatus.WaitingForFunds as string,
      },
      {
        key: TransactionStatusFilterOptions.PENDING as string,
        value: TransactionStatus.Pending as string,
        title: TransactionStatus.Pending as string,
      },
      {
        key: TransactionStatusFilterOptions.REPAID as string,
        value: TransactionStatus.Repaid as string,
        title: TransactionStatus.Repaid as string,
      },
      {
        key: TransactionStatusFilterOptions.DENIED as string,
        value: TransactionStatus.Denied as string,
        title: TransactionStatus.Denied as string,
      },
      {
        key: TransactionStatusFilterOptions.EXPIRED as string,
        value: TransactionStatus.Expired as string,
        title: TransactionStatus.Expired as string,
      },
    ],
    [],
  );
  const categoriesOptions = useMemo<Option[]>(
    () => [
      {
        key: ActivityType.ADOPTION as string,
        value: ActivityType.ADOPTION as string,
        title: 'Adoption',
      },
      {
        key: ActivityType.DENTAL as string,
        value: ActivityType.DENTAL as string,
        title: 'Dental',
      },
      {
        key: ActivityType.DEPENDENT_CARE as string,
        value: ActivityType.DEPENDENT_CARE as string,
        title: 'Dependent care',
      },
      {
        key: ActivityType.MEDICAL as string,
        value: ActivityType.MEDICAL as string,
        title: 'Medical',
      },
      {
        key: ActivityType.PARKING as string,
        value: ActivityType.PARKING as string,
        title: 'Parking',
      },
      {
        key: ActivityType.PREMIUM as string,
        value: ActivityType.PREMIUM as string,
        title: 'Premium',
      },
      {
        key: ActivityType.TRANSIT as string,
        value: ActivityType.TRANSIT as string,
        title: 'Transit',
      },
      {
        key: ActivityType.VISION as string,
        value: ActivityType.VISION as string,
        title: 'Vision',
      },
      {
        key: ActivityType.OTHER as string,
        value: ActivityType.OTHER as string,
        title: 'Other',
      },
    ],
    [],
  );
  useEffect(() => {
    setPage(1);
  }, [setPage, providerSearchString, statuses, categories,
    startDate, endDate, currentSearch]);
  return {
    page,
    setPage,
    setPerPage,
    perPage,
    providerSearchString,
    setSearchProvider,
    statuses,
    setStatuses,
    categories,
    setCategories,
    onChangeDates,
    startDate,
    endDate,
    statusesOptions,
    categoriesOptions,
    currentSearch,
  };
};
