import { Option } from '@/common/types';
import { Employer } from '@/modules/employer/employer.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  resentResults: Option<Employer>[];
  handleSetResentResults: (results: Option<Employer>[]) => void;
  handleAdd: (newItem: Option<Employer>) => void;
}

export const useStore = storeCreator<State>((set) => ({
  resentResults: [],
  handleSetResentResults: (results) => set(() => ({
    resentResults: results,
  })),
  handleAdd: (newItem) => set((state) => ({
    resentResults: [
      newItem,
      ...state.resentResults.filter((item) => item.key !== newItem.key).slice(0, 9),
    ],
  })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
