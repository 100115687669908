import { useCallback } from 'react';

import { useAccountFundingSetter } from '../AccountFunding/hooks';
import { usePlanDefinitionSetter } from '../PlanDefinition/hooks';
import { useSubmitFormStore } from '../stores';

export default () => {
  const setActivateValidate = useSubmitFormStore((state) => state.setActivateValidate);
  const setSaveValidate = useSubmitFormStore((state) => state.setSaveValidate);
  // const setSaveValidate = useSubmitFormStore((state) => state.setSaveValidate);

  const { handleShowErrors: handleShowAccountFundingErrors } = useAccountFundingSetter();
  const { handleShowErrors: handleShowDefinitionErrors } = usePlanDefinitionSetter();
  const handleSetActivateValidate = useCallback(
    (value: boolean) => {
      setActivateValidate(value);
      handleShowDefinitionErrors(true);
      handleShowAccountFundingErrors(true);
    },
    [handleShowAccountFundingErrors, handleShowDefinitionErrors, setActivateValidate],
  );
  const handleSetSaveValidate = useCallback(
    (value: boolean) => {
      setSaveValidate(value);
      handleShowDefinitionErrors(true);
      handleShowAccountFundingErrors(true);
    },
    [handleShowAccountFundingErrors, handleShowDefinitionErrors, setSaveValidate],
  );
  return {
    setActivateValidate: handleSetActivateValidate,
    setSaveValidate: handleSetSaveValidate,
  };
};
