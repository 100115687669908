import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton, Box, Preloader, WarnModal,
} from '@common-fe/common-fe';

import { useCopayGroupByIdQuery, useDeleteCopayGroupQuery } from '../queries';

interface Props {
  copayId?: string;
  onSetCopayId: (id: string) => void;
}
const CopayDeleteModal: React.FC<Props> = ({ copayId, onSetCopayId }) => {
  const [isVisible, setVisible] = useState(false);
  const { data: foundItem } = useCopayGroupByIdQuery(copayId);
  const { handleDelete } = useDeleteCopayGroupQuery();
  useEffect(() => {
    if (copayId) {
      setVisible(true);
    }
  }, [copayId]);
  const handleClose = useCallback(() => {
    setVisible(true);
    onSetCopayId('');
  }, [onSetCopayId]);
  const handleConfirm = useCallback(async () => {
    try {
      if (foundItem) {
        await handleDelete(foundItem);
        onSetCopayId('');
      }
    // eslint-disable-next-line no-empty
    } catch {
    }
  }, [foundItem, handleDelete, onSetCopayId]);

  const loading = false;
  if (!foundItem) {
    return null;
  }
  return (
    <WarnModal
      testId="copay-group-delete"
      visible={isVisible}
      header={`Delete ${foundItem?.name}?`}
      isPendingIcon
      onSetVisible={setVisible}
      cancelButtonNode={(
        <Box direction="row">
          <Box width="150px" margin={{ right: 'spacing6' }}>
            <AppButton
              testId="copay-group-delete_cancel"
              buttonType="secondary"
              onClick={handleClose}
              width="100%"
            >
              Cancel
            </AppButton>
          </Box>
          <Box width="150px" margin={{ left: 'spacing6' }}>
            <AppButton
              testId="copay-group-delete_apply"
              color="red"
              onClick={handleConfirm}
              width="100%"
            >
              {loading
                ? <Preloader color="white" />
                : 'Yes, Delete'}
            </AppButton>
          </Box>
        </Box>

      )}
    />
  );
};

export default CopayDeleteModal;
