import { useMemo } from 'react';
import { Field, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { useCustomReportsStore } from '../stores';

import useCustomReportsFields from './useCustomReportsFields';

export default () => {
  const fields = useCustomReportsFields({});
  const state = useCustomReportsStore((state) => state.state);

  const validationShape = useMemo(() => {
    const value: FieldValues = {};
    const getFields = (currentFields: Field[]) => {
      currentFields.forEach((item) => {
        value[item.name] = item.validator as object;
        const currentValue = _.get(state, item.name, '');
        if(item.subFields && !!currentValue) {
          getFields(item.subFields);
        }
      });
    };
    getFields(fields);
    return value;
  }, [fields, state]);
  const isValid = useMemo(() => {
   
    const schema = yup.object().shape(validationShape);
    try {
      schema.validateSync(state);
      
      return true;
    } catch {
      
      return false;
    }
  }, [state, validationShape]);
  return isValid;
};

