import { storeCreator } from '@/utils/store/store.utils';

import { PayrollCalendarObject } from '../payrollCalendar.types';

interface State {
  editableCalendar?: PayrollCalendarObject;
  handleSetEditableCalendar: (value?: PayrollCalendarObject) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  editableCalendar: undefined,

  handleSetEditableCalendar: (value) => set((state) => ({
    ...state,
    editableCalendar: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    editableCalendar: undefined,
  })),
}));