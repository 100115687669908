import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
} from '@common-fe/common-fe';

import { useCurrentOrganization } from '@/modules/core/hooks';

import useOrgFullLabel from './useOrgFullLabel';

export default () => {
  const {
    observingOrganization,
  } = useCurrentOrganization();

  const currentOrgType = useMemo(() => useOrgFullLabel(observingOrganization.type || ''),
    [observingOrganization]);
  const currentOrgName = useMemo(() => observingOrganization.name, [observingOrganization]);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'organizationLevel',
      type: FieldTypes.Text,
      label: 'Organization level',
      value: currentOrgType,
    },
    {
      name: 'organizationName',
      type: FieldTypes.Text,
      label: 'Organization name',
      value: currentOrgName,
    },
  ], [currentOrgType, currentOrgName]);
  return fields;
};
