import { PermissionKey,PermissionState } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

const useGetPermissionState = (key: PermissionKey) => {
  const { overridablePropertiesPermissions } = useAuthStore();

  return {
    isEdit: !!overridablePropertiesPermissions
      && overridablePropertiesPermissions[key] === PermissionState.EDIT,
    isView: !!overridablePropertiesPermissions
      && overridablePropertiesPermissions[key] === PermissionState.VIEW,
    isHide: !!overridablePropertiesPermissions
      && overridablePropertiesPermissions[key] === PermissionState.HIDE,
  };
};

export default useGetPermissionState;
