import { storeCreator } from '@/utils/store/store.utils';

export enum PayrollCalendarModalSteps {
  SET_PAYROLL_CALENDAR_PARAMS = 'SET_PAYROLL_CALENDAR_PARAMS',
  SET_PAYROLL_CALENDAR_DATES = 'SET_PAYROLL_CALENDAR_DATES',
  SET_PAYROLL_CALENDAR_USE = 'SET_PAYROLL_CALENDAR_USE',
}

interface State {
  modalVisible: boolean;
  editMode?: boolean;
  editableId?: string;
  step: PayrollCalendarModalSteps;
  handleSetEditMode: (value?: boolean) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleSetEditableId: (value?: string) => void;
  handleSetStep: (value: PayrollCalendarModalSteps) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  step: PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_PARAMS,
  editMode: false,
  editableId: undefined,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetEditMode: (value) => set((state) => ({
    ...state,
    editMode: value,
  })),

  handleSetStep: (value) => set((state) => ({
    ...state,
    step: value,
  })),

  handleSetEditableId: (value) => set((state) => ({
    ...state,
    editableId: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    modalVisible: false,
    editMode: false,
    step: PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_PARAMS,
    editableId: undefined,
  })),
}));