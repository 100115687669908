import { useCallback, useEffect, useState } from 'react';

const useReadTextFromFile = (url?: string) => {
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const readFile = useCallback(async () => {
    if (url) {
      try {
        setIsLoading(true);
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const decoder = new TextDecoder('utf-8');
        const decodedArrayBuffer = decoder.decode(arrayBuffer);
        setText(decodedArrayBuffer);
        setIsLoading(false);
      } catch (e) {
        setText('');
        setIsLoading(false);
      }
    }
  }, [url]);

  useEffect(() => {
    readFile();
  }, [readFile]);

  return { text, isLoading };
};

export default useReadTextFromFile;
