import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks';
import ContributionsList from '@/modules/employer/components/Contributions/ContributionsList/ContributionsList';
import { ScheduledContributionList } from '@/modules/employer/components/Contributions/ScheduledContribution';
import TopSection from '@/modules/employer/components/Contributions/TopSection';
import useContributionsFeatureFlag from '@/modules/employer/useContributionsFeatureFlag';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 0;
`;

const ContributionsPage = () => {
  useBreadcrumbs([{
    route: ROUTES.CONTRIBUTIONS,
    title: 'Contributions',
  }]);
  const isContributionsFeatureFlag = useContributionsFeatureFlag();
  if(!isContributionsFeatureFlag) {
    return null;
  }
  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_CONTRIBUTIONS},
      ]}
      
    >
      <Wrapper>
        <Topbar />
        <Box align="center" pad={{ bottom: 'spacing72' }}>
          <Box width={theme.defaultContentWidth}>
            <TopSection />
            <ScheduledContributionList />
            <ContributionsList />
          </Box>
        </Box>
      </Wrapper>
    </Access>
  );
};

export default ContributionsPage;
