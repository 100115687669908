 
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  FlexList,
  Inscription,
  OptionKey,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text,
  usePaginationParams,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ALL_OPTION } from '@/common/constants';
import { ListItemBase } from '@/common/types';
import { ListPlaceholder } from '@/modules/CarrierConfig/components';
import { useAllOptionsToggle } from '@/modules/core/hooks';

import { EditEmailNotificationModal } from './EditEmailNotificationModal/EditEmailNotificationModal';
import { useEditEmailNotificationModalStore } from './EditEmailNotificationModal/store';
import { useEmailTemplatesList } from './hooks/useEmailTemplatesList';
import { emailTemplatesCategoriesOptions, emailTemplatesUserTypesOptions } from './emailTemplates.types';
import { useGetEmailTemplatesIdsQuery } from './queries';

const StyledIconWrapper = styled(Box)`
  i:before {
    color: ${({ theme }) => theme.colors.iconPrimary};
    font-weight: bold;
    font-size: 20px;
  }
`;


  
const headers: ListItemBase[] = [{
  key: 'notificationName',
  title: 'Notification name',
  flex: 1.1,
},
{
  key: 'templateName',
  title: 'Template name',
  flex: 1.2,
  style: {
    marginRight: '16px',
  }
},
{
  key: 'category',
  title: 'Category',
  flex: 0.8,
},
{
  key: 'destination',
  title: 'Destination',
  flex: 0.8,
}
];

interface Props {
  orgId?: string;
}
  
const EmailTemplateConfigurationList: React.FC<Props> = ({ orgId }) => {
  const [expanded, setExpanded] = useState(true);
  const { notificationTemplatesIds, notificationProperties, refetch } = useGetEmailTemplatesIdsQuery(orgId);
  const [destination, setDestination] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [categories, setCategories] = useState<OptionKey[]>([ALL_OPTION.value]);
  const categoriesValues =useAllOptionsToggle(categories);
  const destinationValues = useAllOptionsToggle(destination);

  const handleChangeVisibility = useEditEmailNotificationModalStore((state) => state.handleChangeVisibility);
  const handleSetEmailNotificationTemplate = useEditEmailNotificationModalStore((state) => state.handleSetEmailNotificationTemplate);
  const handleSetAdditionalAction = useEditEmailNotificationModalStore((state) => state.handleSetAdditionalAction);

  const {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams(10);

  const {
    isLoading,
    totalElements,
    formattedList,
    data: emailTemplates,
  } = useEmailTemplatesList(
    {
      templatesIds: notificationTemplatesIds,
      page: page - 1,
      perPage,
      searchString: currentSearch || '',
      categories: categoriesValues[0] === ALL_OPTION.value ? undefined : categories.filter((category) => category !== ALL_OPTION.value) as OptionKey[],
      userRole: destinationValues[0] === ALL_OPTION.value ? undefined : destination[0] as string,
    },
  );

  const handlePickTemplate = useCallback((id: string) => {
    const template = emailTemplates?.find((item) =>`${item.id}` === id);
    const propertyName = Object.entries(notificationProperties || {}).find((item) => `${item[1]}` === id);
    if (template && handleSetEmailNotificationTemplate) {
      handleSetEmailNotificationTemplate({
        id: `${template.id}`,
        notificationName: template.notification_description,
        templateName: template.template_description,
        category: template.category_type,
        destination: template.user_type,
        notificationType: template.notification_type,
        propertyName: propertyName ? propertyName[0] : undefined,
      });
      handleSetAdditionalAction(refetch);
    }
    handleChangeVisibility(true);
  }, [
    emailTemplates,
    handleChangeVisibility,
    handleSetEmailNotificationTemplate,
    notificationProperties,
    refetch,
    handleSetAdditionalAction,
  ]);

  return (
    <Box
      id="email_template_configuration_list"
      background="canvas"
      round="container1Round"
      margin={{ top: 'l' }}
      pad="spacing24"
      data-testid="EmailTemplateConfigurationList"
    >
      <Box
        direction="row"
        height="40px"
        margin={{ bottom: expanded ? 'spacing24' : undefined }}
        align="center"
        justify="between"
      >
        <Box
          data-testId="EmailTemplateConfigurationList_expand_button"
          style={{ cursor: 'pointer' }}
          onClick={() => setExpanded(!expanded)}
          direction="row"
          width="max-content"
        >
          <StyledIconWrapper width="24px" align="center" justify="center" margin={{ right: 'spacing8'}}>
            {expanded ? (
              <ChevronUpIcon data-testid="EmailTemplateConfigurationList_ChevronUpIcon" size="24px" color="iconPrimary" />
            ) : (
              <ChevronDownIcon data-testid="EmailTemplateConfigurationList_ChevronDownIcon" size="24px" color="iconPrimary" />
            )}
                    
          </StyledIconWrapper>
          <Inscription size="20px" color="textBody">
            Email Template Configuration
          </Inscription>
        </Box>

        {!expanded ? (
          <Box>
            <Inscription size="16px" color="textSecondary">
              Active templates: {totalElements || 0}
            </Inscription>
          </Box>
        ) : null}
      </Box>

      {expanded
        ? (
          <FlexList
            testId='EmailTemplateConfigurationList_flex_list'
            headers={headers}
            rows={formattedList}
            pad="spacing12"
            round="container1Round"
            placeholder={<ListPlaceholder>There are no email templates</ListPlaceholder>}
            total={totalElements || 0}
            options={[
              {
                name: 'Edit',
                onClick: handlePickTemplate,
                // onClick: () => handleChangeVisibility(true),
              },
            ]}
            loading={isLoading}
            footer={(
              <PaginationBar
                page={page}
                total={totalElements || 0}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          >
            <Box
              direction="row"
              height="40px"
              width="100%"
              align="center"
              margin={{ bottom: 'spacing12' }}
              justify="between"
            >
              <Text
                margin={{ left: 'spacing16' }}
                size="large"
                weight="bold"
                color="textBody"
              >
                Active email notifications: {totalElements || 0}
              </Text>

              <Box direction="row" justify="end">
                <SearchInput
                  data-testId="EmailTemplateConfigurationList_search_input"
                  className="search-input"
                  hasSearchingHistory
                  value={searchString}
                  placeholder="Search"
                  onChange={setSearchString}
                />
                <Box margin={{ left: 'spacing12' }} width={{ min: '190px', max: '190px' }}>
                  <SelectDropdown
                    id="email_destination"
                    testId="EmailTemplateConfigurationList_emailDestination"
                    singleMode
                    activeTitle
                    ellipsisMode
                    name="Destination"
                    prefix="Destination"
                    options={emailTemplatesUserTypesOptions}
                    values={destinationValues}
                    onChangeValues={(type: OptionKey[]) => {
                      setDestination(type);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: '190px', max: '190px' }}>
                  <SelectDropdown
                    id="email_category"
                    testId="EmailTemplateConfigurationList_emailCategory"
                    allMode
                    activeTitle
                    ellipsisMode
                    name="Category"
                    prefix="Category"
                    options={emailTemplatesCategoriesOptions}
                    values={categoriesValues}
                    onChangeValues={(category: OptionKey[]) => {
                      setCategories(category);
                      setPage(1);
                    }}
                    dropdownStyles={{
                      minWidth: '212px',
                      maxWidth: 'none',
                      left: 'auto',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </FlexList>
        ) : null}
      <EditEmailNotificationModal />
    </Box>
  );
};
export default EmailTemplateConfigurationList;
  