import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  AppButton,
  Box, Button, ChevronDownIcon,   ChevronUpIcon,   DropContent, Text, useClickOutside,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import { PlanCardAction, PlanCardMode } from '@/modules/plan/plan.types';

interface Props {
  mode: PlanCardMode;
  isHovered?: boolean;
  onSelectAction?: (value: PlanCardAction) => void;
  isPlansHidden?: boolean;
  isStandaloneMode?: boolean
}
const StyledButton = styled(Button)`
  padding-left: ${({ theme }) => theme.spacings.xs} ;
  height: ${({ theme }) => theme.spacings.xl};
  min-height: ${({ theme }) => theme.spacings.xl};
  &:hover{
     background: ${({ theme }) => theme.colors.accentContainer} ;
  }
`;

const PlanCardTemplateActions: React.FC<Props> = ({
  mode, onSelectAction, isPlansHidden, isHovered, isStandaloneMode,
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);

  const handleToggleVisiblePopup = useCallback(() => {
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    if (isStandaloneMode) {
      setVisiblePopup(false);
    }
  });
  useEffect(() => {
    if (!isHovered && !isStandaloneMode && visiblePopup) {
      setVisiblePopup(false);
    }
  }, [isHovered, isStandaloneMode, visiblePopup]);
  const handleSelectAction = useCallback((value: PlanCardAction) => {
    if (onSelectAction) {
      onSelectAction(value);
    }
  }, [onSelectAction]);

  return (
    <DropContent
      bottomMode
      tailless
      leftAlign
      contentWidth={mode === PlanCardMode.Plan ? 'medium' : '420px'}
      visible={visiblePopup}
      style={{ flex: '0 1 auto', zIndex: 100 }}
      content={(
        <Box
          direction="column"
          ref={popupRef}
        >
          {/* <Box fill background="border1" style={{ height: 1 }} /> */}
          {!isPlansHidden && (
            <StyledButton
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleToggleVisiblePopup();
                handleSelectAction(PlanCardAction.createPlanFromTemplate);
              }}
            >
              <Text size="medium" weight={400}>
                {mode === PlanCardMode.Plan ? 'New Plan from Plan Template' : 'New Health Plan from Health Plan Template'}
              </Text>
            </StyledButton>
          )}

          <StyledButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleToggleVisiblePopup();
              handleSelectAction(PlanCardAction.createTemplateFromTemplate);
            }}
          >
            <Text size="medium" weight={400}>
              {mode === PlanCardMode.Plan ? 'New Plan Template from Plan Template' : 'New Health Plan Template from Health Plan Template'}
            </Text>
          </StyledButton>
        </Box>
      )}
    >
      <Box width="214px">
        <Access
          accessRequirements={[
            {
              permission: Permissions.PLAN_TEMPLATE_SETUP,
            },
          ]}
        >
          <AppButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleToggleVisiblePopup();
            }}
            rightIcon={visiblePopup ? <ChevronUpIcon color="iconOnColor" /> : <ChevronDownIcon color="iconOnColor" />}
          >
            Create From Template
          </AppButton>
        </Access>

      </Box>
    </DropContent>
  );
};

export default PlanCardTemplateActions;
