import React, {
  useCallback,
  useEffect,
  useMemo, useRef,   useState, } from 'react';
import {
  AppButton, Box, ChevronDownIcon, ChevronUpIcon, Inscription, Preloader, SearchInput, useClickOutside,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import currentTheme from '@/styles/theme';

import { EllipsisText } from '../ActivityLog.styles';

import { formatAttribute } from './LogItem/Changeset/common';
import Entity, { EntityAttributes } from './Entity';
import { Attribute } from './useGetAttributesList.query';

const ENTITY_NAME_SEPARATOR = '.';


const Wrapper = styled.button<{ isActive: boolean }>`
max-width: 160px;
min-width: 160px;
height: 38.5px;
background: ${({ theme }) => theme.colors.canvas};
border-radius: 8px;
pagination: 0 16px;
cursor: pointer;
outline: 1px solid ${({ theme }) => theme.colors.border1};
border: 2px solid transparent;
&:hover {
  border: 2px solid ${({ theme }) => theme.colors.border1};
}
${({ theme, isActive }) => {
    if (isActive) {
      return `
        outline: 6px solid #DCE8FA;
        border: 2px solid ${theme.colors.accentActive};
        &:hover {
          border: 2px solid ${theme.colors.accentActive};
        }
      `;
    }

    return '';
  }}
`;

interface Props {
  applyAttributes: (attributes: string[]) => void;
  appliedAttributes: string[];
  attributesList: Attribute[];
  isLoading: boolean;
  isAllApplied: boolean;
}

const AttributesSelector: React.FC<Props> = ({
  applyAttributes,
  appliedAttributes,
  isLoading,
  isAllApplied,
  attributesList,
}) => {
  const [selectedAttributes, setSelectedAttributes] = useState<EntityAttributes>({});
  const [isActive, setIsActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const getSearchedAttribute = useCallback(
    (attribute: string) => formatAttribute(attribute).toLowerCase().includes(searchValue.toLowerCase()),
    [searchValue],
  );
  const searchedAttributes = useMemo(() => {
    if (!searchValue) return attributesList;

    return attributesList.filter(
      (entity) => entity.attributes?.some(getSearchedAttribute),
    ).map((entity) => ({
      ...entity,
      attributes: entity.attributes?.filter(getSearchedAttribute),
    }));
  }, [attributesList, searchValue, getSearchedAttribute]);
  const numberOfSearchedAttributes = useMemo(
    () => searchedAttributes.map((item) => item.attributes).flat().length,
    [searchedAttributes],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef && inputRef.current && isActive) {
      inputRef.current.focus();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inputRef.current, isActive]);

  const ref = useRef(null);
  useClickOutside(ref, () => {
    setIsActive(false);
  });

  const activateSelector = useCallback(
    () => {
      setIsActive(true);
      if (appliedAttributes.length) {
        const initValue = {} as EntityAttributes;
        const attributes = appliedAttributes.reduce((acc, val) => {
          const entityName = val.split(ENTITY_NAME_SEPARATOR)[0];
          const attribute = val.replace(`${entityName}${ENTITY_NAME_SEPARATOR}`, '');
          return {
            ...acc,
            [entityName]: [...(acc[entityName] || []), attribute],
          };
        }, initValue);
        setSelectedAttributes(attributes);
      } else {
        setSelectedAttributes({});
      }
    },
    [setIsActive, appliedAttributes, setSelectedAttributes],
  );

  return (
    <Box ref={ref}>
      <Wrapper isActive={isActive} onFocus={activateSelector}>
        {isLoading ? (
          <Preloader />
        ) : (
          <Box height="24px" direction="row">
            <Box direction="row" align="center" width="100%" pad={{ right: 'spacing4' }}>
              <EllipsisText color="textBody" lineHeight="20px">
                {isAllApplied ? 'Attribute: ' : formatAttribute(appliedAttributes[0].split('.')[1])}
              </EllipsisText>
              {appliedAttributes.length > 1 && !isAllApplied ? (
                <Inscription color="textAccent" lineHeight="20px">&nbsp;+{appliedAttributes.length - 1}</Inscription>
              ) : null}
              {isAllApplied ? (
                <Inscription color="textAccent" lineHeight="20px">&nbsp;All</Inscription>
              ) : null}
            </Box>
            <Box align="center">
              {isActive ? (
                <ChevronUpIcon color="textTitle" />
              ) : (
                <ChevronDownIcon color="textTitle" />
              )}
            </Box>
          </Box>
        )}
      </Wrapper>

      <Box
        width={{ min: '320px', max: '320px' }}
        pad={{
          bottom: 'spacing16',
          top: 'spacing12',
        }}
        background="canvas"
        justify="between"
        round="xs"
        margin={{ top: '42px' }}
        style={{
          zIndex: 1,
          position: 'absolute',
          boxShadow: currentTheme.shadows.active['box-shadow'],
          ...isActive ? {} : { display: 'none' },
        }}
      >
        <Box>
          <Box
            pad={{ bottom: 'spacing12' }}
            margin={{ bottom: 'spacing12' }}
            border={{
              color: 'border1',
              side: 'bottom',
            }}
            height={{ min: 'fit-content' }}
          >
            <Box pad={{ horizontal: 'spacing16' }}>
              <SearchInput
                inputRef={inputRef}
                placeholder="Search"
                testId="AttributesSelector"
                value={searchValue}
                onChange={setSearchValue}
              />
            </Box>
          </Box>

          <Box
            align="center"
            height={{ min: '24px' }}
            direction="row"
            margin={{ bottom: '5px' }}
            pad={{ horizontal: '16px' }}
          >
            <Box>
              <Inscription
                color="textSecondary"
                size="12px"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Attributes: ({numberOfSearchedAttributes})
              </Inscription>
            </Box>
          </Box>

          <Box
            pad={{ horizontal: 'spacing16' }}
            height={{ max: '500px' }}
            style={{ overflow: 'overlay', display: 'block' }}
          >
            {searchedAttributes.map(
              (entity) => (
                <Entity
                  key={entity.entityName}
                  data={entity}
                  selectedAttributes={selectedAttributes}
                  setSelectedAttributes={setSelectedAttributes}
                />
              ),
            )}
          </Box>
        </Box>

        <Box
          justify="between"
          align="center"
          direction="row"
          pad={{ horizontal: 'spacing16', top: 'spacing16' }}
          margin={{ top: 'spacing2' }}
          border={{
            color: 'border1',
            side: 'top',
          }}
        >
          <AppButton
            testId="cancel"
            buttonType="secondary"
            onClick={() => setIsActive(false)}
            width="135px"
          >
            Cancel
          </AppButton>
          <AppButton
            testId="apply"
            onClick={() => {
              applyAttributes(
                Object.keys(selectedAttributes)
                  .map((key) => selectedAttributes[key].map((attr) => `${key}.${attr}`))
                  .flat(),
              );
              setIsActive(false);
            }}
            width="135px"
          >
            Apply
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AttributesSelector;
