import { useCallback, useMemo } from 'react';
import { ListItem } from '@common-fe/common-fe';

import { ListItemType } from '@/common/types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';

import useBanksListQuery from './useBanksList.query';

const useBanksList = (disabled?: boolean) => {
  const { employerID } = useSetupEmployerMode();

  const { banks, isLoading, isFetched } = useBanksListQuery(employerID);
  const formatedList = useMemo<ListItem[]>(() => banks.map((item) => ({
    id: item.id,
    fields: [
      {
        key: 'description',
        type: ListItemType.Contact,
        title: item.description,
        value: '',
        hover: !disabled,
      },
      {
        key: 'type',
        type: ListItemType.Text,
        title: item.accountCategory,
        value: item.accountCategory,
        flex: 1,
      },
    ],
  })), [banks, disabled]);
  const findBankById = useCallback(
    (id: string) => banks.find((item) => item.id === id),
    [banks],
  );
  const isEmpty = useMemo(() => banks.length === 0, [banks]);
  return {
    isLoading,
    isFetched,
    isEmpty,
    list: banks,
    formatedList,
    findBankById,
  };
};

export default useBanksList;
