const SEPARATOR = '\\';

interface Props {
  systemId?: string;
  partnerId?: string;
  distributorId?: string;
  employerId?: string;
  subsidiaryId?: string;
  subgroupId?: string;
}
const createPathFromIds = (props: Props) => {
  const {
    systemId,
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
    subgroupId,
  } = props;

  if (!systemId) return undefined;

  let path = `${SEPARATOR}${systemId}${SEPARATOR}`;

  if (!partnerId) return path;

  path += `${partnerId}${SEPARATOR}`;

  if (!distributorId) return path;

  path += `${distributorId}${SEPARATOR}`;

  if (!employerId) return path;

  path += `${employerId}${SEPARATOR}`;

  if (!subsidiaryId) return path;

  path += `${subsidiaryId}${SEPARATOR}`;

  if (!subgroupId) return path;

  path += `${subgroupId}${SEPARATOR}`;

  return path;
};

export default createPathFromIds;
