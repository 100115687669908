import React from 'react';
import { AppButton, Box, FlexControlledForm, Modal, Preloader, Text, WarnModal } from '@common-fe/common-fe';

import { AddressPayload } from './stores/useAddress.store';
import { useEditAddressActions } from './hooks';

interface Props {
  address?: AddressPayload;
  visible?: boolean;
  onToggleVision: (value: boolean) => void;
}

const EditAddressModal: React.FC<Props> = ({
  address,
  visible,
  onToggleVision,
}) => {
  const {
    errorModal,
    setErrorModal,
    showErrors,
    fieldsAddress,
    isLoading,
    handleOpenModal,
    handleSaveAddress,
    handleChangeValues,
  } = useEditAddressActions(address, onToggleVision, visible);

  return (
    <>
      <Modal
        visible={!!visible}
        onSetVisible={() => handleOpenModal(false)}
        data-testid="edit-address-wizard-modal"
      >
        <Box direction="column">
          <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
            <Text size="2xl" color="textTitle" weight="bold">Edit Address</Text>
          </Box>
          <Box pad="l" background="module" round="moduleRound">
            <Box
              data-testId="contact-form"
              background="canvas"
              round="container1Round"
            >
              <FlexControlledForm
                testId="update-organization-address"
                fields={fieldsAddress}
                editMode
                onChangeValues={handleChangeValues}
                showError={showErrors}
                isModalType
              />
            </Box>
          </Box>
          <Box
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'l' }}
          >
            <AppButton buttonType="secondary" width="180px" onClick={() => handleOpenModal(false)}>Cancel</AppButton>
            <Box margin={{ left: 's' }}>
              <AppButton width="180px" onClick={handleSaveAddress} disabled={isLoading}>
                {
                  isLoading
                    ? <Preloader color="white" />
                    : <Text>Save</Text>
                }
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      <WarnModal
        testId="contact-wizard-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot update the Address with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSaveAddress}
        submitButtonText="Try again"
      />
    </>
  );
};

export default EditAddressModal;
