import { useEffect, useMemo, useState } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import {
  MAX_LENGTH_TEXT, MIN_DATE, REQUIRED_TEXT, SSN_INVALID_TEXT, SSN_MASK_WITH_DASHES,
  SSN_MASK_WITHOUT_DASHES, } from '@/common/constants';
import { SourceIds } from '@/modules/employee/employee.types';

const FIELDS = {
  FIRST_NAME: 'firstName',
  MIDDLE_NAME: 'middleName',
  LAST_NAME: 'lastName',
  SUFFIX: 'suffix',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  SSN: 'ssn_demographic',
};

export const GENDERS = [
  {
    key: 'M',
    value: 'Male',
    title: 'Male',
  },
  {
    key: 'F',
    value: 'Female',
    title: 'Female',
  },
  {
    key: 'O',
    value: 'Other',
    title: 'Other',
  },
];

const MAX_FIELD_LENGTH = 40;
const MAX_SUFFIX_FIELD_LENGTH = 10;

const TODAY = dayjs().toDate();

interface Props {
  ssn?: string;
  sourceId?: string;
  hasInternalErrorValidation?: boolean;
  clearInternalError?: () => void;
}

export const useEmployeeSetupModalDemographicsFields = ({
  ssn,
  sourceId,
  hasInternalErrorValidation,
  clearInternalError,
}: Props) => {
  const [currentSsnMask, setCurrentSsnMask] = useState(SSN_MASK_WITH_DASHES);

  useEffect(() => {
    if (ssn && ssn.length >= 4 && ssn.charAt(3) !== '-') {
      setCurrentSsnMask(SSN_MASK_WITHOUT_DASHES);
    }
    if (ssn && ssn.length <= 3) {
      setCurrentSsnMask(SSN_MASK_WITH_DASHES);
    }
  }, [ssn]);

  const ssnValidator = useMemo(() => {
    if (hasInternalErrorValidation) {
      return yup.string().trim().test({
        test() {
          return false;
        },
        message: 'SSN number must be unique.',
      });
    }
    return yup.string().test({
      exclusive: false,
      test(val) {
       
        if (!val) {
          return true;
        }
        
        if (val.length === currentSsnMask.length) {
          return true;
        }
        return false;
      },
      message: SSN_INVALID_TEXT,
    });
  }, [currentSsnMask.length, hasInternalErrorValidation]);

  const fields = useMemo(() => ([
    {
      name: FIELDS.FIRST_NAME,
      type: FieldTypes.Text,
      label: 'First name',
      showRequireIcon: true,
      placeholder: 'Enter first name',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.MIDDLE_NAME,
      type: FieldTypes.Text,
      label: 'Middle name',
      placeholder: 'Enter middle name (optional)',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
      defaultValue: '',
    },
    {
      name: FIELDS.LAST_NAME,
      type: FieldTypes.Text,
      showRequireIcon: true,
      label: 'Last name',
      placeholder: 'Enter last name',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.SUFFIX,
      type: FieldTypes.Text,
      label: 'Suffix',
      max: 10,
      placeholder: 'Enter suffix (optional)',
      validator: yup
        .string()
        .max(MAX_SUFFIX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_SUFFIX_FIELD_LENGTH)),
      defaultValue: '',
    },
    {
      name: FIELDS.DATE_OF_BIRTH,
      type: FieldTypes.Date,
      showRequireIcon: true,
      isManualDateInput: true,
      label: 'Date of birth',
      placeholder: 'Enter date of birth',
      maxDate: TODAY,
      minDate: MIN_DATE,
      onChangeValues: clearInternalError,
      validator: hasInternalErrorValidation
        ? yup.string().trim().test({
          test() {
            return false;
          },
          message: 'DOB must be unique.',
        })
        : VALIDATORS.DATE_FORMAT_MAX_TODAY.required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.GENDER,
      type: FieldTypes.AutocompleteDropdown,
      label: 'Gender',
      placeholder: 'Select gender',
      options: GENDERS,
      defaultValue: '',
    },
    ...sourceId !== SourceIds.SSN && typeof sourceId !== 'undefined'
      ? [{
        name: FIELDS.SSN,
        type: FieldTypes.Mask,
        label: 'SSN',
        formatChars: {
          8: REGEXPS.NUMBER_AND_DASH,
        },
        mask: currentSsnMask,
        placeholder: 'Enter SSN',
        validator: ssnValidator,
        onChangeValues: clearInternalError,
        defaultValue: '',
      }] : [],
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ]), [currentSsnMask, sourceId, ssn, hasInternalErrorValidation, ssnValidator, clearInternalError]);

  return fields as Field[];
};
