import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import { AdditionalPayload,useStore as useAdditionalStore } from '../Additional/stores/useAdditional.store';
import { CardOfferedPayload,useStore as useCardOfferedStore } from '../CardOffered/stores/useCardOffered.store';
import usePlanNameStore, { PlanNamePayload } from '../PlanName/stores/usePlanName.store';
import { PlanYearPayload,useStore as usePlanYearStore } from '../PlanYear/stores/usePlanYear.store';

export type PlanDefinitionPayload = PlanNamePayload
& PlanYearPayload & AdditionalPayload & CardOfferedPayload;
// & CardOfferedPayload
// & AdditionalPayload

export default () => {
  const setPlanNameStore = usePlanNameStore((state) => state.setState);
  const setPlanYearStore = usePlanYearStore((state) => state.setState);
  const setCardOfferedStore = useCardOfferedStore((state) => state.setState);
  const setAdditionalStore = useAdditionalStore((state) => state.setState);

  const setSourcePlanNameStore = usePlanNameStore((state) => state.setSourceState);
  const setSourcePlanYearStore = usePlanYearStore((state) => state.setSourceState);
  const setSourceCardOfferedStore = useCardOfferedStore((state) => state.setSourceState);
  const setSourceAdditionalStore = useAdditionalStore((state) => state.setSourceState);

  const showErrorsPlanNameStore = usePlanNameStore((state) => state.setShowErrors);
  const showErrorsAdditionalStore = useAdditionalStore((state) => state.setShowErrors);
  // const showErrorsPlanYearStore = usePlanYearStore((state) => state.setShowErrors);
  const showErrorsCardOffered = useCardOfferedStore((state) => state.setShowErrors);

  const handleResetPlanNameStore = usePlanNameStore((state) => state.handleReset);
  const handleResetPlanYearStore = usePlanYearStore((state) => state.handleReset);

  const handleResetAdditionalStore = useAdditionalStore((state) => state.handleReset);
  const handleResetCardOfferedStore = useCardOfferedStore((state) => state.handleReset);

  const setPlanNameErrors = usePlanNameStore((state) => state.setErrors);
  const setCardOfferedErrors = useCardOfferedStore((state) => state.setErrors);
  const setAdditionalErrors = useAdditionalStore((state) => state.setErrors);

  const setPlanNameErrorFields = usePlanNameStore((state) => state.setErrorFields);
  const setCardOfferedErrorFields = useCardOfferedStore((state) => state.setErrorFields);
  const setAdditionalErrorFields = useAdditionalStore((state) => state.setErrorFields);

  const handleSetSourceState = useCallback((values: Partial<PlanDefinitionPayload>) => {
    setSourcePlanNameStore(values);
    setSourcePlanYearStore(values);
    setSourceCardOfferedStore(values);
    setSourceAdditionalStore(values);
  }, [setSourceAdditionalStore,
    setSourceCardOfferedStore,
    setSourcePlanNameStore,
    setSourcePlanYearStore]);
  const handleSetState = useCallback((values: Partial<PlanDefinitionPayload>) => {
    setPlanNameStore(values);
    setPlanYearStore(values);

    setCardOfferedStore(values);
    setAdditionalStore(values);

    handleSetSourceState(values);
  }, [handleSetSourceState,
    setAdditionalStore,
    setCardOfferedStore,
    setPlanNameStore,
    setPlanYearStore]);

  const handleReset = useCallback(() => {
    handleResetPlanYearStore();
    handleResetPlanNameStore();
    handleResetAdditionalStore();
    handleResetCardOfferedStore();
  }, [
    handleResetAdditionalStore,
    handleResetCardOfferedStore,
    handleResetPlanNameStore,
    handleResetPlanYearStore,
  ]);

  const handleShowErrors = useCallback((value: boolean) => {
    // showErrorsPlanYearStore(value);
    showErrorsCardOffered(value);
    showErrorsAdditionalStore(value);
    showErrorsPlanNameStore(value);
  }, [showErrorsAdditionalStore, showErrorsCardOffered, showErrorsPlanNameStore]);
  const handleSetErrors = useCallback((values: Partial<PlanDefinitionPayload>) => {
    setPlanNameErrors(values);
    setCardOfferedErrors(values);
    setAdditionalErrors(values);
  }, [
    setAdditionalErrors,
    setCardOfferedErrors,
    setPlanNameErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<PlanDefinitionPayload>>) => {
      setPlanNameErrorFields(values);
      setCardOfferedErrorFields(values);
      setAdditionalErrorFields(values);
    }, [setAdditionalErrorFields, setCardOfferedErrorFields, setPlanNameErrorFields],
  );
  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
