import React, { useEffect, useMemo, useRef } from 'react';
import { Box } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { WrapperWithVerticalShadows } from '@/components/wrappers/WrapperWithVerticalShadows';
import {
  Report,
  ReportItemData,
} from '@/modules/employer/components/Reports/report.types';
import { useDetectVerticalScrollShadow } from '@/utils/hooks/useDetectVerticalScrollShadow';

import { ReportItemContent } from './ReportItemContent';
import { ReportItemNestedContent } from './ReportItemNestedContent';
import { ReportItemNestedTableContent } from './ReportItemNestedTableContent';
import { ReportItemNestedTablesContent } from './ReportItemNestedTablesContent';

const StyledShadowBox = styled(Box)<{ expand: boolean }>`
  &:after {
    display: ${({ expand }) => (expand ? 'none' : 'block')};
    content: '';
    position: absolute;
    width: 100%;
    height: 38px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
    bottom: 0;
    left: 0;
  }
`;

const StyledInfoTableWrap = styled(Box)<{ isReportTableData: boolean }>`
  position: relative;
  transition: all .3s;
  ${({ isReportTableData }) => (isReportTableData ? 'overflow-x: auto;' : '')}
`;

const MIN_REPORT_ITEMS = 3;
const MAX_REPORT_DATA_ITEM_WITHOUT_SCROLL = 11;

interface Props {
  expand: boolean;
  isFullWidth?: boolean;
  report?: Report;
}

export const ReportItemInfoTable: React.FC<Props> = ({ isFullWidth, expand, report }) => {
  const infoTableRef = useRef(null);

  const setCurrentPage = report?.setCurrentPage;
  const currentPage = report?.currentPage;
  const totalPages = report?.totalPages || 1;
  const setIsCurrentLoading = report?.setIsCurrentLoading;

  const preparedReportItems = useMemo(() => {
    const preparedData: ReportItemData[] = expand
      ? (report?.data || [])
      : (report?.data || []).slice(0, MIN_REPORT_ITEMS);
    if ((report?.data || []).length < MIN_REPORT_ITEMS) {
      preparedData.push({
        id: _.uniqueId(),
        title: '',
        value: '',
      });
    }
    return preparedData;
  }, [expand, report]);
  const preparedReportTableItems = useMemo(() => {
    const rows = report?.tableData ? (report?.tableData.rows || []) : [];

    const preparedRows = expand
      ? (rows || [])
      : (rows || []).slice(0, MIN_REPORT_ITEMS);
    if (rows.length < MIN_REPORT_ITEMS) {
      preparedRows.push({
        id: _.uniqueId(),
        fields: [],
      });
    }

    return preparedRows;
  }, [expand, report]);

  const {
    onScrollHandler,
    scrolledToTheEnd,
    startedScrolling,
  } = useDetectVerticalScrollShadow(infoTableRef.current);
  useEffect(() => {
    if (scrolledToTheEnd
      && setCurrentPage
      && setIsCurrentLoading
      && _.isNumber(currentPage)
      && (currentPage + 1) < totalPages
      && !report?.isSubLoading) {
      setIsCurrentLoading(true);
      setCurrentPage(currentPage + 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToTheEnd]);

  const isWideInfoTable = useMemo(
    () => expand && (report?.data || []).length > MAX_REPORT_DATA_ITEM_WITHOUT_SCROLL,
    [expand, report],
  );

  const isWideInfoVerticalTable = useMemo(
    () => expand && preparedReportTableItems.length > MAX_REPORT_DATA_ITEM_WITHOUT_SCROLL,
    [expand, preparedReportTableItems],
  );

  return (
    <WrapperWithVerticalShadows
      data-testid="report_item_info_table-container"
      startedScrolling={startedScrolling}
      scrolledToTheEnd={(isWideInfoTable || isWideInfoVerticalTable) ? scrolledToTheEnd : true}
      notAllowed
    >
      <Box
        ref={infoTableRef}
        onScroll={onScrollHandler}
        height={{ max: expand ? '360px' : '180px' }}
        width={{ min: (isWideInfoTable || isWideInfoVerticalTable) ? '496px' : 'auto' }}
        margin={{ bottom: 'spacing12' }}
        pad={{ right: (isWideInfoTable || isWideInfoVerticalTable) ? 'spacing8' : undefined }}
        overflow={{ vertical: expand ? 'auto' : 'hidden' }}
      >
        <StyledShadowBox
          expand={expand}
        >
          <StyledInfoTableWrap isReportTableData={!!report?.tableData}>
            {
              report?.data
                ? (
                  <ReportItemContent reportData={preparedReportItems} expand={expand} />
                )
                : null
            }
            {
              report?.nestedTableData
                ? (
                  <Box pad={{ right: expand ? 'spacing8' : undefined }}>
                    <ReportItemNestedTableContent
                      data={report?.nestedTableData}
                      header={report?.nestedTableDataHeader || []}
                      isFullWidth={isFullWidth}
                      expand={expand}
                      isEmpty={report?.isEmpty}
                      isSubLoading={report?.isSubLoading}
                      hasPages={!!report?.totalPages}
                      isLastPage={currentPage === (totalPages - 1)}
                    />
                  </Box>
                )
                : null
            }
            {
              report?.nestedData
                ? (
                  <Box pad={{ right: expand ? 'spacing8' : undefined }}>
                    <ReportItemNestedContent
                      data={report?.nestedData}
                      expand={expand}
                      isEmpty={report?.isEmpty}
                      isSubLoading={report?.isSubLoading}
                      hasPages={!!report?.totalPages}
                      isLastPage={currentPage === (totalPages - 1)}
                    />
                  </Box>
                )
                : null
            }
            {report?.tablesData
              ? (
                <Box pad={{ right: expand ? 'spacing8' : undefined }}>
                  <ReportItemNestedTablesContent
                    data={report.tablesData}
                    isEmpty={report?.isEmpty}
                    expand={expand}
                    isSubLoading={report?.isSubLoading}
                    hasPages={!!report?.totalPages}
                    isLastPage={currentPage === (totalPages - 1)}
                  />
                </Box>
              )
              : null}
          </StyledInfoTableWrap>

        </StyledShadowBox>
      </Box>
    </WrapperWithVerticalShadows>
  );
};
