import { HealthPlanFieldState } from '@/modules/HealthPlan/HealthPlan.types';
import { baseStoreCreator } from '@/utils/store';

export interface EnrollmentMatrixFieldsStatePayload {
  deductibleService: HealthPlanFieldState;
}

export const DEFAULT_VALUES: EnrollmentMatrixFieldsStatePayload = {
  deductibleService: HealthPlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<EnrollmentMatrixFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
