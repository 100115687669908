import React from 'react';
import {
  Box,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';

import { useEmployerTerminationModalStore } from '../store';

import { useEmployerTerminationPlanList } from './hooks/useEmployerTerminationPlanList';
import { useGetEmployerTerminationDataQuery } from './queries/useGetEmployerTerminationData.query';
import { EmployerTerminationDateForm } from './EmployerTerminationDateForm';
import { EmployerTerminationHsaTransferForm } from './EmployerTerminationHsaTransferForm';
import { EmployerTerminationParamsForm } from './EmployerTerminationParamsForm';
import { EmployerTerminationPlanList } from './EmployerTerminationPlanList';
import { EmployerTerminationPlansCoverageDatesList } from './EmployerTerminationPlansCoverageDatesList';
      
const ViewEmployerTerminationModalForm: React.FC= () => {    
  const employerId = useEmployerTerminationModalStore((state) => state.employerId);
    
  const { terminationData, isLoading: terminationDataLoading } = useGetEmployerTerminationDataQuery(employerId || '');
    
  const { 
    isLoading: planListLoading,
    data,
    coverageData,
    isHsaAccountExist,
    hsaStartDate,
  } = useEmployerTerminationPlanList(
    employerId,
    terminationData?.terminationDate,
    terminationData?.coverageEndDate,
    terminationData?.claimLastDate,
  );
      
  return (
    <>
      <Box margin={{ bottom: 'spacing24' }} align="center">
        <Inscription size="24px" weight={700} lineHeight="32px" textAlign="center">
          Organization has terminated
        </Inscription>
      </Box>
    
      <Box data-testid="ViewEmployerTerminationModalForm_container">
        <Box direction="column">
          <Box
            round="container1Round"
            pad="spacing24"
            background="module"
            gap='spacing24'
          >
            <EmployerTerminationDateForm
              terminationDate={terminationData?.terminationDate}
              minDate={hsaStartDate}
              viewMode
            />
            {planListLoading || terminationDataLoading ? (
              <Preloader />
            ) : (
              <Box gap="spacing24">
                <EmployerTerminationPlanList
                  planList={data}
                />
                {data.length ? (
                  <Box gap="spacing24">
                    <EmployerTerminationPlansCoverageDatesList
                      planList={coverageData}
                    />
                    <EmployerTerminationParamsForm
                      terminationDate={terminationData?.terminationDate}
                      defaultEndOfCoverage={terminationData?.coverageEndDate}
                      defaultLastDayToSubmitClaim={terminationData?.claimLastDate}
                      viewMode
                    />
                  </Box>
                ) : null}
    
                {isHsaAccountExist ? (
                  <EmployerTerminationHsaTransferForm
                    defaultHsaTransferOfAssets={terminationData?.bulkTransfer}
                    viewMode
                  />
                ) : null}
              </Box>
            )}  
          </Box>
        </Box>
      </Box>
    </>
  );
};
      
export default ViewEmployerTerminationModalForm;
      