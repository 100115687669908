import React, {
  useCallback,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  FlatTextInput,
  PopupWrapper,
  Preloader,
  PriorityIcon,
  SiblingBoxesWithSpacing,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { REGEXPS } from '@/common';
import theme from '@/styles/theme';

import { useUpdateEnrollmentMatrix } from '../hooks';

export const Content = styled(Box)`
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${theme.colors.canvas};
    border-radius:  ${theme.rounds.smallRound};
  }
  &.right::before {
    right: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
`;

interface Props {
  testId: string;
  id?: string;
  perPage?: number;
  onSelectRaw: (value?: string) => void;
  onClearSelectRow: (value?: string) => void;
}

export const ChangePriorityPopup: React.FC<Props> = ({
  testId,
  id,
  perPage,
  onSelectRaw,
  onClearSelectRow,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [movePriority, setMovePriority] = useState('');

  const handleCancel = useCallback(() => {
    setMovePriority('');
    onClearSelectRow();
    setVisible(false);
  }, [setVisible, onClearSelectRow]);

  const {
    handlePreSubmit,
    isLoading,
  } = useUpdateEnrollmentMatrix(handleCancel);

  const handleApply = useCallback(() => {
    handlePreSubmit(id, movePriority, perPage);
    setMovePriority('');
    setVisible(false);
    onClearSelectRow();
  }, [setVisible, handlePreSubmit, onClearSelectRow, movePriority, perPage, id]);

  return (
    <PopupWrapper
      onVisible={setVisible}
      controlledVisible={isVisible}
      onClear={() => {
        setMovePriority('');
        onClearSelectRow();
      }}
      flat
      flex
      style={{
        marginTop: '2px',
        right: 36,
        top: -112,
        width: '244px',
      }}
      buttonStyle={{
        padding: 0,
      }}
      testId={`${testId}_open-button`}
      content={(
        <Content
          round={{ size: 'spacing8' }}
          background="canvas"
          data-testid={`${testId}_wrapper`}
          elevation="active"
          className="right"
        >
          <Box pad="m">
            <Text size="medium" weight={700} color="textBody" margin={{ bottom: 's' }}>
              Move to
            </Text>
            <Text size="small" color="textBody" weight={500} margin={{ bottom: 's' }}>
              Enter a number to take an already selected place,
              but this will lower the priority of other entries.
            </Text>
            <FlatTextInput
              defaultAutoComplete="off"
              testId={`${testId}_input`}
              placeholder="Move to"
              onChange={(newValue: string) => {
                if (REGEXPS.ONLY_NUMBERS_WITHOUT_ZERO.test(newValue)) {
                  setMovePriority(newValue);
                }
              }}
              inputMode="numeric"
              value={movePriority}
            />
          </Box>
          <Box
            width="100%"
            height="1px"
            background={{ color: 'border1' }}
          />
          <Box
            direction="row"
            align="center"
            pad="m"
          >
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_cancel`}
                buttonType="secondary"
                type="button"
                width="100px"
                onClick={handleCancel}
              >
                Cancel
              </AppButton>
            </SiblingBoxesWithSpacing>
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_move`}
                type="button"
                width="100px"
                onClick={() => {
                  handleApply();
                }}
              >
                {isLoading ? <Preloader color="white" /> : 'Move'}
              </AppButton>
            </SiblingBoxesWithSpacing>
          </Box>
        </Content>
      )}
    >
      <Box data-testId={`${testId}_select_button`} pad={{ top: 'xxs' }} onClick={() => onSelectRaw()}>
        <PriorityIcon color={isVisible ? 'iconAccent' : 'iconSecondary'} />
      </Box>
    </PopupWrapper>
  );
};
