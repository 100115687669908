import React, { useRef } from 'react';
import {
  Box, Text, useCountHiddenElements,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Option } from '@/common/types';

interface Props {
  title?: string;
  options: Option[];

}

export const NoneTitle = styled(Text)`
  font-weight: 400 !important;

`;
const Title = styled(Text)``;

const OptionText = styled(Text)`
// The styles is forced because the text lose context of grommet
  font-family: ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.textSecondary};
`;
const CounterStyles = styled(Text)`
// The styles is forced because the text lose context of grommet
  font-family: ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme }) => theme.colors.textAccent};
`;

const Wrapper = styled(Box)`
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
    ${Title} {
      color: ${({ theme }) => theme.colors.textAccent};
    }
    ${OptionText} {
      color: ${({ theme }) => theme.colors.textBody};
    }
    
  }
`;

const CopayOption: React.FC<Props> = ({ title, options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hiddenCount, visibleCount } = useCountHiddenElements(ref);

  return (
    <Wrapper
      fill
      direction="column"
      align="start"
      justify="start"
      round="dropdownItemRound"
      margin={{ top: 'xxxs' }}
      style={{ flex: 1, position: 'relative' }}
      pad={{ vertical: 'spacing6', horizontal: 'xs' }}
    >
      {title && (
        <Title>
          {title}
        </Title>
      )}

      <Box direction="row">
        <Box
          ref={ref}
          direction="row"
          align="center"
          style={{ overflow: 'hidden', position: 'relative' }}
          pad={{ right: 'xl' }}
        >
          {options.map((item, index) => (
            <>
              <OptionText
                size="small"
                weight={500}
                key={item.name}
                style={{
                  flexShrink: 0,
                  visibility: index >= visibleCount ? 'hidden' : undefined,
                }}
                margin={{ right: 'xxxs' }}
              >
                {item.value as string}
                {options.length - 1 !== index ? ', ' : ''}
              </OptionText>
              {!!hiddenCount && visibleCount - 1 === index && (

                <CounterStyles size="small" color="textAccent">+{hiddenCount}</CounterStyles>

              )}
            </>
          ))}

        </Box>

      </Box>
    </Wrapper>
  );
};

export default CopayOption;
