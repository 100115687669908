import { storeCreator } from '@/utils/store/store.utils';

interface State {
  calendarNames?: string[];
  handleSetCalendarNames: (value?: string[]) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  calendarNames: undefined,

  handleSetCalendarNames: (value) => set((state) => ({
    ...state,
    calendarNames: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    calendarNames: undefined,
  })),
}));