import React from 'react';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
  WarningIcon,
} from '@common-fe/common-fe';

import Modal from '../Modal';

import { Header, HelpText,Wrapper } from './PendingModal.styles';

interface Props {
  header: string;
  helptext?: string;
  buttonText?: string;
  children?: React.ReactNode;
  visible: boolean;
  onSetVisible?: (value: boolean) => void;
  notHideClickOutside?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  cancelButtonText?: string;
  submitting?: boolean;
  icon?: React.ReactNode;
  warningIcon?: boolean;
}

const PendingModal: React.FC<Props> = ({
  onSetVisible,
  visible,
  header,
  children,
  helptext,
  buttonText,
  onSubmit,
  notHideClickOutside,
  onCancel,
  cancelButtonText,
  submitting,
  icon,
  warningIcon,
}) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    if (onSetVisible) onSetVisible(false);
  };
  return (
    <Modal
      visible={visible}
      onSetVisible={() => {
        if (onSetVisible) {
          onSetVisible(false);
        } else if (onSubmit) {
          onSubmit();
        }
      }}
      notHideClickOutside={notHideClickOutside}
    >
      <Wrapper>
        {icon ? icon :  warningIcon ?
          (<WarningIcon color="warning" size="48px" />)
          : (
            <RoundPendingIcon color="warning" size="48px" />
          )}
        
        <Header>{header}</Header>

        {helptext && <HelpText>{helptext}</HelpText>}
        {children}
        <Box direction="row" align="center">
          {
            onCancel && (
              <Box margin={{ right: 'spacing12' }}>
                <AppButton
                  onClick={onCancel}
                  disabled={submitting}
                  buttonType="secondary"
                >
                  <Text weight="bold" color="textBody">{cancelButtonText || 'Cancel'}</Text>
                </AppButton>
              </Box>
            )
          }
          {buttonText && (
            <AppButton
              disabled={submitting}
              onClick={handleSubmit}
              width="200px"
            >
              {
                submitting
                  ? <Preloader color="white" />
                  : buttonText
              }
            </AppButton>
          )}
        </Box>

      </Wrapper>
    </Modal>
  );
};

export default PendingModal;
