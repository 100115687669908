import React, { useEffect,useMemo, useState } from 'react';
import { Box } from '@common-fe/common-fe';

import { CLOSE_SYMBOL, FORMULA_ALL_VALUES, OPEN_SYMBOL } from './FormulaDragAndDrop/FormulaDragAndDrop';
import FormulaDragAndDrop from './FormulaDragAndDrop';
import FormulaSubfield from './FormulaSubfield';
import FundIDFOrmulaResult from './FundIDFormulaResult';
import PrefixSubfield from './PrefixSubfield';

interface Props {
  isErrorsShowed?: boolean;
  onChange?:(value: string) => void;
  value?: string;
  disabled?: boolean;
}

const FundFormula: React.FC<Props> = ({
  isErrorsShowed, value, onChange, disabled,
}) => {
  const [prefixValue, experssionValue] = useMemo(() => {
    if (value) {
      const firstPart = value.split(OPEN_SYMBOL)[0];
      if (firstPart) {
        const secondPart = value.split(OPEN_SYMBOL)[1];
        if (secondPart) {
          let formatedSecondPart = secondPart.replace(CLOSE_SYMBOL, '');
          const formulaList: string[] = [];
          FORMULA_ALL_VALUES.forEach((item) => {
            if (formatedSecondPart.includes(item)) {
              formulaList.push(item);
              formatedSecondPart = formatedSecondPart.replace(item, '');
            }
          });
          return [firstPart, formulaList];
        }
      }
    }
    return [];
  }, [value]);
  const [prefix, setPrefix] = useState(prefixValue || '');
  const [expression, setExpression] = useState<string[]>([]);
  const formatedPrefix = useMemo(() => {
    const expressions = expression.join('');
    if (!prefix && !expressions) {
      return '';
    }
    return `${prefix}{${expressions}}`;
  }, [expression, prefix]);
  useEffect(() => {
    if (onChange) {
      onChange(formatedPrefix);
    }
  }, [formatedPrefix, onChange]);
  return (
    <Box direction="column" style={{ flexShrink: 250 }}>
      <PrefixSubfield
        isPrefixMode
        value={prefix}
        disabled={disabled}
        isErrorsShowed={isErrorsShowed}
        onChange={setPrefix}
      />
      <FormulaSubfield
        label="Expression"
        text="Drag and drop the following components to set the expression."
      >
        <FormulaDragAndDrop
          disabled={disabled}
          defaultValue={experssionValue}
          onChange={setExpression}
        />
      </FormulaSubfield>
      <FundIDFOrmulaResult value={formatedPrefix} />
    </Box>
  );
};

export default FundFormula;
