import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import {
  COUNTRY_MAP,
  DEBIT_EXP_DATE_FORMAT,
  DEFAULT_CARD_EXP_DATE_FORMAT,
  FIRST_DAY_NUMBER,
  TWENTIETH_CENTURY,
} from '@/common/constants';
import paths from '@/common/paths';
import { PaymentMethodOwnerType, PaymentMethodType } from '@/modules/employee/employee.types';
import { useEncryptData } from '@/utils/hooks/useEncryptData';

import useAddPaymentMethodQuery from '../../useAddPaymentMethod.query';
import { useUpdatePaymentMethodQuery } from '../../useUpdatePaymentMethod.query';
import { DebitCardPayload } from '../hooks';

export enum EmployeePhoneNumber {
  Work = 'WORK',
  Home = 'HOME',
  Unkknown = 'UNKNOWN',
  Mobile = 'MOBILE',
}

export enum EmployeeAddressType {
  PO_BOX = 'PO_BOX',
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  UNKNOWN = 'UNKNOWN',
}

export interface EmployeeCommunicationConfigurationDto {
  communication_config_channels: string[];
  notification_priority: string;
}

export enum ClaimAutoPayType {
  Auto = 'AUTO',
  Click = 'CLICK',
}


export enum ClaimPayee {
  Participant = 'PARTICIPANT',
  Provider = 'PROVIDER',
  EmployeeChoice = 'EMPLOYEE_CHOICE',
}
export interface EmployeePayload {
  id?: string;
  person_id?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  suffix?: string;

  full_name?: string;
  birth_on?: string;
  ssn?: string;
  employer_provided_email?: string;
  employee_provided_email?: string;
  home_phone_number?: string;
  phone1?: {
    number?: string;
    phone_type?: EmployeePhoneNumber;
  };
  phone2?: {
    number?: string;
    phone_type?: EmployeePhoneNumber;
  };
  mailing_address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?: EmployeeAddressType;
  };
  physical_address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?: EmployeeAddressType;
  };
  use_same_address_for_mailing?: boolean;
  employer_name?: string;
  is_employee_email_preferred?: boolean;
  partner_employee_id?: string;
  employer_employee_id?: string;
  system_id?: string;
  status?: string;
  hire_on?: string;
  termination_on?: string;
  gender?: string;
  user_name?: string;

  user_communication_configuration?: EmployeeCommunicationConfigurationDto;

  organization_id?: number;
  organization_name?: string;
  organization_path?: string;

  claim_autopay_type?: ClaimAutoPayType;
  default_claim_payee_type?: ClaimPayee;
}

interface FiservResponse {
  tokenId: string;
  status: string;
  issuedOn: number;
  expiresInSeconds: number;
  publicKey: string;
  algorithm: string;
}

interface AddDebitCardNoEncryptedPayload {
  nameOnCard: string;
  fdCustomerId: string;
  cardType: string;
  address: {
    type: string;
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
    primary: boolean;
  }
}

interface AddDebitCardEncryptedPayload {
  cardNumber: string;
  expiryDateMonth: string;
  expiryDateYear: string;
}
type AddDebitCardPayload = AddDebitCardEncryptedPayload & AddDebitCardNoEncryptedPayload;
const ENCRYPT_PREFIX = 'ENC_';

interface AddDebitCardResponse {
  type: string;
  token: {
    tokenType: string;
    tokenProvider: string;
    tokenId: string;
  }
}

const getFiservToken = (fdCustomerId: string) => api.post<FiservResponse>(paths.FISERV_TOKEN, {
  token: {
    fdCustomerId,
  },
  publicKeyRequired: true,
});

const formatAddDebitCardBody = (payload: AddDebitCardPayload) => ({
  account: {
    type: 'CREDIT',
    credit: {
      cardNumber: payload.cardNumber,
      nameOnCard: payload.nameOnCard,
      cardType: payload.cardType,
      expiryDate: {
        month: payload.expiryDateMonth,
        year: payload.expiryDateYear,
      },
      billingAddress: payload.address,
    },
  },
  token: {
    tokenType: 'CLAIM_CHECK_NONCE',
  },
  fdCustomerId: payload.fdCustomerId,
});

export const useAddPaymentDebitCardQuery = (onSuccess?: (paymentMethodId?: string) => void) => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { save } = useAddPaymentMethodQuery();
  const { updatePaymentMethod } = useUpdatePaymentMethodQuery();
  const { encrypt } = useEncryptData();

  const { mutateAsync: saveDebitCard } = useMutation(
    (data: {
      tokenId: string, body: AddDebitCardPayload,
    }) => api.post<AddDebitCardResponse>(
      paths.ACCOUNT_TOKEN,
      formatAddDebitCardBody(data.body),
      {
        headers: { 'Token-Id': data.tokenId },
      },
    ),
  );
  const { mutateAsync: addVaultedAccountToRecipient } = useMutation(
    (data: { merchantCustomerId: string, tokenId: string, fiservTokenId: string }) => api.post(
      paths.ADD_VAULTED_ACCOUNT_TO_RECIPIENT(data.merchantCustomerId),
      {
        card_token_id: data.tokenId,
      }, {
        headers: {
          'Fiserv-Access-Token': data.fiservTokenId,
        },
      },
    ),
  );

  const addDebitCard = useCallback(async (debitCard: DebitCardPayload, isDefault?: boolean) => {
    if (!employeeId) return;
    const expMonth = parseInt((debitCard.expirationDate || '').split(' / ')[0] || '0', 10);
    const expYear = parseInt((debitCard.expirationDate || '').split(' / ')[1] || '0', 10);
    const fullExpDate = dayjs(`${expMonth}/${FIRST_DAY_NUMBER}/${TWENTIETH_CENTURY}${expYear}`, DEFAULT_CARD_EXP_DATE_FORMAT).format(DEBIT_EXP_DATE_FORMAT);
    const { data: personalInfo } = await api.get<EmployeePayload>(
      paths.EMPLOYEE_BY_ID(employeeId),
    );
    const { data: fiservResponse } = await getFiservToken(employeeId);
    const encryptedResponse = encrypt({
      pubkey: fiservResponse.publicKey,
      data: {
        cardNumber: debitCard.cardNumber || '',
        expiryDateMonth: debitCard.expirationDate?.split('/')[0] || '',
        expiryDateYear: debitCard.expirationDate?.split('/')[1] || '',
      },
    }) as AddDebitCardEncryptedPayload;
    const formatedEncryptedData: AddDebitCardEncryptedPayload = {
      cardNumber: `${ENCRYPT_PREFIX}[${encryptedResponse.cardNumber}]`,
      expiryDateMonth: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateMonth}]`,
      expiryDateYear: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateYear}]`,
    };
    const { data: addedDebitCardResponse } = await saveDebitCard({
      tokenId: fiservResponse.tokenId,
      body: {
        nameOnCard: debitCard.nameOnCard,
        fdCustomerId: employeeId,
        cardType: debitCard.cardType,
        ...formatedEncryptedData,
        address: {
          type: personalInfo?.physical_address?.address_type || '',
          streetAddress: `${personalInfo?.physical_address?.line1} ${personalInfo?.physical_address?.line2}`,
          locality: personalInfo?.physical_address?.state || '',
          region: personalInfo?.physical_address?.city || '',
          postalCode: personalInfo?.physical_address?.zipcode || '',
          country: personalInfo?.physical_address?.country_code || COUNTRY_MAP[1],
          primary: true,
        },
      },
    });
    const { data: accounts } = await addVaultedAccountToRecipient({
      tokenId: addedDebitCardResponse.token.tokenId,
      fiservTokenId: fiservResponse.tokenId,
      merchantCustomerId: employeeId,
    });
    const response = await save({
      paymentType: PaymentMethodType.DEBIT,
      paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
      isDefault,
      debitCard: {
        ...debitCard,
        expirationDate: fullExpDate,
      },
      cardToken: accounts?.accounts?.[0]?.token?.tokenId,
    });
    if (onSuccess) {
      const paymentMethodId = response?.id ? toString(response?.id) : undefined;
      onSuccess(paymentMethodId);
    }
  }, [encrypt, save, saveDebitCard, employeeId, addVaultedAccountToRecipient, onSuccess]);

  const updateDebitCard = useCallback(async (debitCard: DebitCardPayload, methodId: string) => {
    if (!employeeId) return;
    const expMonth = parseInt((debitCard.expirationDate || '').split(' / ')[0] || '0', 10);
    const expYear = parseInt((debitCard.expirationDate || '').split(' / ')[1] || '0', 10);
    const fullExpDate = dayjs(`${expMonth}/${FIRST_DAY_NUMBER}/${TWENTIETH_CENTURY}${expYear}`, DEFAULT_CARD_EXP_DATE_FORMAT).format(DEBIT_EXP_DATE_FORMAT);
    const { data: personalInfo } = await api.get<EmployeePayload>(
      paths.EMPLOYEE_BY_ID(employeeId),
    );

    const { data: fiservResponse } = await getFiservToken(employeeId);
    const encryptedResponse = encrypt({
      pubkey: fiservResponse.publicKey,
      data: {
        cardNumber: debitCard.cardNumber || '',
        expiryDateMonth: debitCard.expirationDate?.split('/')[0] || '',
        expiryDateYear: debitCard.expirationDate?.split('/')[1] || '',
      },
    }) as AddDebitCardEncryptedPayload;
    const formatedEncryptedData: AddDebitCardEncryptedPayload = {
      cardNumber: `${ENCRYPT_PREFIX}[${encryptedResponse.cardNumber}]`,
      expiryDateMonth: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateMonth}]`,
      expiryDateYear: `${ENCRYPT_PREFIX}[${encryptedResponse.expiryDateYear}]`,
    };
    const { data: addedDebitCardResponse } = await saveDebitCard({
      tokenId: fiservResponse.tokenId,
      body: {
        nameOnCard: debitCard.nameOnCard,
        fdCustomerId: employeeId,
        cardType: debitCard.cardType,
        ...formatedEncryptedData,
        address: {
          type: personalInfo.physical_address?.address_type || '',
          streetAddress: `${personalInfo.physical_address?.line1} ${personalInfo.physical_address?.line2}`,
          locality: personalInfo.physical_address?.state || '',
          region: personalInfo.physical_address?.city || '',
          postalCode: personalInfo.physical_address?.zipcode || '',
          country: personalInfo.physical_address?.country_code || COUNTRY_MAP[1],
          primary: true,
        },
      },
    });
    await updatePaymentMethod({
      paymentType: PaymentMethodType.DEBIT,
      paymentMethodId: methodId,
      fiservTokenId: fiservResponse.tokenId,
      debitCard: {
        ...debitCard,
        expirationDate: fullExpDate,
        cardToken: addedDebitCardResponse.token.tokenId,
      },
    });
    if (onSuccess) {
      onSuccess(methodId);
    }
  }, [employeeId, encrypt, saveDebitCard, updatePaymentMethod, onSuccess]);

  return {
    addDebitCard,
    updateDebitCard,
  };
};
