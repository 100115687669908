import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useBankAccountFields } from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/hooks';
import { useBankAccountStore } from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/stores';

const useBankAccountFormValidate = () => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const bankAccountState = useBankAccountStore((state) => state.state);
  const bankAccountFields = useBankAccountFields();

  const bankAccountErrors: FieldError = useMemo(() =>  getErrors(bankAccountState, getValidateFields(bankAccountFields)), [getErrors, bankAccountState, getValidateFields, bankAccountFields]);

  return bankAccountErrors;
};

export default useBankAccountFormValidate;
