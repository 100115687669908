import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { AppButton, Box } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { ModalWrapper } from '@/components';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';

import CreateCopayAdjudicationForm from '../CopayTopbar/CreateCopayAdjudication/CreateCopayAdjudicationForm';
import { useCopayGroupByIdQuery } from '../queries';

interface Props {
  isEditMode?: boolean;
  copayId?: string;
  onSetCopayId: (id: string) => void;
}
const CopayViewModal: React.FC<Props> = ({
  copayId, onSetCopayId, isEditMode: isEditModeApplied,
}) => {
  const hasEditCopayAccess = useHasAccess([{ permission: Permissions.EDIT_COPAY }]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(!!isEditModeApplied);

  const { data: foundItem } = useCopayGroupByIdQuery(copayId);
  const handleToggleEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);
  useEffect(() => {
    if (copayId) {
      setIsVisible(true);
    } else {
      setIsEditMode(false);
    }
  }, [copayId]);
  const handleToggle = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);
  useEffect(() => {
    if (!isVisible) {
      onSetCopayId('');
    }
  }, [onSetCopayId, isVisible]);
  const currentTitle = useMemo(() => {
    if (isEditMode) {
      return 'Edit a Copay Group';
    }
    return foundItem?.name || 'Add New Copay Group';
  }, [foundItem, isEditMode]);
  const {
    observingOrganization: {
      type,
    },

  } = useCurrentOrganization();
  const isEditBlocked = useMemo(() => {
    let isDisabled = false;
    if (!hasEditCopayAccess) {
      isDisabled = true;
    }
    if (type === OrganizationTypes.partner
       && foundItem?.organizationType === OrganizationTypes.system) {
      isDisabled = true;
    }
    if (type === OrganizationTypes.distributor
      && (foundItem?.organizationType === OrganizationTypes.system
        || foundItem?.organizationType === OrganizationTypes.partner)) {
      isDisabled = true;
    }
    return isDisabled;
  }, [foundItem, hasEditCopayAccess, type]);
  if (!foundItem) {
    return null;
  }

  return (
    <ModalWrapper
      testId="create-copay-adjudication"
      visible={isVisible}
      title={currentTitle}
      onSetVisible={handleToggle}
    >
      {/* <Prompt
    when={isFormDirty}
    message={CONFIRM_TEXT}
  /> */}
      <CreateCopayAdjudicationForm
        isViewMode
        isEditMode={isEditMode || isEditModeApplied}
        defaultValues={foundItem}
        onClose={handleToggle}
      >
        {!isEditMode && !isEditModeApplied && !isEditBlocked && (
          <Box margin={{ left: 'spacing12' }}>
            <AppButton
              testId="edit"
              type="button"
              width="160px"
              onClick={handleToggleEditMode}
            >
              Edit
            </AppButton>
          </Box>
        )}
        {(isEditMode || isEditModeApplied) && (
          <Box margin={{ left: 'spacing12' }}>
            <AppButton
              testId="edit"
              type="submit"
              width="160px"

            >
              Save
            </AppButton>
          </Box>
        )}
      </CreateCopayAdjudicationForm>
    </ModalWrapper>
  );
};

export default CopayViewModal;
