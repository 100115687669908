import React, { useCallback, useEffect,useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AbsenceFilesPlaceholderIcon,
  Box,
  CalendarIcon,
  CheckBox,
  ClearAll,
  DateRangeDropdown,
  FlexList,
  FlexListPlaceholder,
  NoSearchItemsPlaceholder,
  PaginationBar,
  Preloader,
  SearchInput,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ALL_OPTION } from '@/common/constants';
import routes from '@/common/routes';
import { ListItemBase, ListItemType,OptionKey } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { CLAIMS_STATUSE_OPTIONS, ClaimsStatus } from '@/modules/employer/components/Claims/types/claims.constants';
import { useListPagination } from '@/modules/employer/hooks';
import { PaymentType } from '@/modules/transaction/transaction.types';
import globalTheme from '@/styles/theme';
import theme from '@/styles/theme';
import { formatDateRange } from '@/utils/modifiers';

import useClaimsList from './hooks/useClaimsList';
import AssignClaimsProcessorsPopup from './AssignClaimsProcessorsPopup';
import { PageMode } from './ClaimsFilterSection';
import EmptyClaimsPlaceholder from './EmptyClaimsPlaceholder';
import { CHECKED_CLASS_NAME } from './MoovingWrapper';

const StyledAbsenceFilesPlaceholderIcon = styled(AbsenceFilesPlaceholderIcon)`
  fill: ${({ theme }) => theme.colors.border};
`;

const EXTENDED_HEADER_HEIGHT = 140;
const HEADER_HEIGHT = 144;
export const EXTENDED_HEADER_CLAIMS_SPACING = 24;
export const HEADER_CLAIMS_SPACING = 24;

const PAYMENT_TYPES = [
  { ...ALL_OPTION, id: '' },
  {id: '0', key: 'REIMBURSEMENT', value: 'Reimbursement'},
  {id: '1', key: 'BILL_PAY', value: 'Pay a Bill'},
  {id: '2', key: 'CARD_SWIPE', value: 'Card swipe'},
];

const CheckBoxWrapper = styled(Box)`
  input[type=checkbox] + div,
  input[type=checkbox]:not(:checked) + div,
  input[type=checkbox]:not(:checked):focus + div {
    background-color: ${({ theme }) => theme.colors.legend};
    border-color: ${({ theme }) => theme.colors.border} !important;
    width: ${({ theme }) => theme.spacings.spacing16};
    height: ${({ theme }) => theme.spacings.spacing16};
  }
`;

const initialStatuses = [
  ClaimsStatus.Processing,
  ClaimsStatus.Reprocessing,
];

const statusAllToggling = (statuses: OptionKey[]) => {
  const isStatusAllSelected = statuses[statuses.length - 1] === ClaimsStatus.Null;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ClaimsStatus.Null];
  }

  const isStatusAllChangedToAnother = statuses.length === 2 && statuses[0] === ClaimsStatus.Null;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ClaimsStatus.Null);
  }

  return statuses;
};

interface Props {
  setTotal: (val: number | undefined) => void;
  isOnlyMyAssignmets?: boolean;
  isShowOnlyEscalated?: boolean;
  hasAccessToAssignClaim?: boolean;
  isClockIn?: boolean;
  hasAccessToClockIn?: boolean;
  pageMode?: PageMode;
}

const ClaimsList: React.FC<Props> = ({
  isOnlyMyAssignmets,
  isShowOnlyEscalated,
  hasAccessToAssignClaim,
  isClockIn,
  hasAccessToClockIn,
  setTotal,
  pageMode,
}) => {
  const [initCleaning, setInitCleaning] = useState(false);
  const [statuses, setStatuses] = useState<OptionKey[]>(initialStatuses);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([]);
  const [paymentTypes, setPaymentTypes] = useState<OptionKey>(ALL_OPTION.value);
  const {
    page,
    perPage,
    currentSearch,
    searchString,
    setSearchString,
    setPerPage,
    setPage,
  } = useListPagination();
  const searchingPaymentType = useMemo(
    () => PAYMENT_TYPES
      .find((type) => type.key === paymentTypes)?.key,
    [paymentTypes],
  );

  const hasPaymentType = useMemo(() => paymentTypes !== ALL_OPTION.value, [paymentTypes]);
  const statusValues = useMemo(() => statusAllToggling(statuses), [statuses]);
  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ClaimsStatus.Null),
    [statusValues],
  );
  const hasDateRange = useMemo(() => Boolean(dateRange[0] && dateRange[1]), [dateRange]);

  const [selectedClaimsIds, setSelectedClaimsIds] = useState<string[]>([]);
  useEffect(() => setSelectedClaimsIds([]), [pageMode, setSelectedClaimsIds]);
  const hasClaimsTeamColumn = useMemo(() => !isOnlyMyAssignmets && Boolean(selectedClaimsIds.length),
    [isOnlyMyAssignmets, selectedClaimsIds]);
  const { data: claims, total, isLoading, list, isRefetching } = useClaimsList({
    page: page - 1,
    perPage,
    hasAccessToAssignClaim,
    ...(currentSearch ? { searchString: currentSearch } : {}),
    ...(hasPaymentType ? { paymentType: searchingPaymentType as PaymentType } : {}),
    ...(hasStatus ? { statuses: statusValues } : {}),
    ...(hasDateRange
      ? { dateRange: formatDateRange(dateRange) }
      : {}),
    isOnlyMyAssignmets,
    isShowOnlyEscalated,
    isClockIn,
    hasAccessToClockIn,
    selectedClaimsIds,
    setSelectedClaimsIds,
    hasClaimsTeamColumn,
  });
  useEffect(() => {
    setTotal(total);
  }, [total, setTotal]);
  const [areAllClaimsSelected, selectAllClaims] = useState(false);
  const selectedClaims = useMemo(() => list?.filter((claim) => selectedClaimsIds.some((id) => claim?.id === id)), [list, selectedClaimsIds]);
  useEffect(() => {
    if (claims?.length && claims.length === selectedClaimsIds.length) {
      selectAllClaims(true);
    } else if (isLoading && selectedClaimsIds.length) {
      selectAllClaims(false);
      setSelectedClaimsIds([]);
    } else if (selectedClaimsIds.length === 0) {
      selectAllClaims(false);
    }
  }, [claims, selectedClaimsIds, isLoading]);
  const actualClaims = useMemo(
    () => {
      if (hasAccessToAssignClaim) {
        return claims.map((claim) => {
          const isSelected = selectedClaimsIds.some((item) => item === claim.id);

          return ({
            ...claim,
            fields: [
              {
                key: 'selectClaim',
                title: 'select claim',
                type: ListItemType.Node,
                flex: '0 1 40px',
                node: (
                  <Box {...isSelected ? { className: CHECKED_CLASS_NAME } : {}}>
                    <CheckBox
                      onChange={() => {
                        if (isSelected) {
                          setSelectedClaimsIds([...selectedClaimsIds.filter((item) => item !== claim.id)]);
                          selectAllClaims(false);
                        } else {
                          setSelectedClaimsIds([...selectedClaimsIds, claim.id]);
                        }
                      }}
                      checked={isSelected}
                    />
                  </Box>
                ),
              },
              ...claim.fields,
            ],
            ...isSelected ? { rowStyle: { outline: `2px solid ${theme.colors.border}`, transition: 'none' } } : {},
          });
        });
      }

      return claims;
    },
    [hasAccessToAssignClaim, claims, selectedClaimsIds],
  );

  const hasClearAll = useMemo(
    () => Boolean(currentSearch || hasPaymentType || hasStatus || hasDateRange),
    [currentSearch, hasPaymentType, hasStatus, hasDateRange],
  );
  const clearAll = () => {
    setSearchString('');
    setPaymentTypes(ALL_OPTION.value);
    setStatuses([]);
    setDateRange([]);
    setInitCleaning(!initCleaning);
  };

  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])?.get('emptyClaimList');
  const searchQuery = useMemo(() => new URLSearchParams(location.search), [location.search])?.get('search');
  useEffect(() => {
    if (searchQuery) {
      setSearchString(searchQuery);
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('search');
      history.replace({
        search: queryParams.toString(),
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const headers = useMemo(
    () => ([
      ...hasAccessToAssignClaim ? [
        {
          key: 'selectAll',
          title: 'select all claims',
          flex: '0 1 24px',
          style: { justifyContent: 'center', alignItems: 'center' },
          node: (
            <CheckBoxWrapper>
              <CheckBox
                checked={areAllClaimsSelected}
                onChange={() => {
                  if (areAllClaimsSelected) {
                    selectAllClaims(false);
                    setSelectedClaimsIds([]);
                  } else {
                    selectAllClaims(true);
                    setSelectedClaimsIds(claims.map((claim) => claim.id));
                  }
                }}
              />
            </CheckBoxWrapper>
          ),
        },
      ] : [],
      {
        key: 'claimsAssignee',
        title: 'Assignee',
        flex: '0 1 72px',
        style: {
          alignItems: 'center',
          marginRight: '8px',
        },
      },
      {
        key: 'claimsEmployer',
        title: 'ER/SSD',
        flex: 1.1,
      },
      {
        key: 'claimsEmployee',
        title: 'Employee',
        flex: 1.2,
      },
      {
        key: 'claimsClaimID',
        title: 'Claim #',
        flex: 0.8,
      },
      {
        key: 'claimsPaymentType',
        title: 'Type',
        flex: 0.5,
      },
      {
        key: 'claimsAge',
        title: 'Age',
        flex: 0.6,
      },
      ...isShowOnlyEscalated ? [{
        key: 'claimsEscalationNote',
        title: 'Escalation note',
        flex: 1.2,
      }] : [],
      ...hasClaimsTeamColumn ? [{
        key: 'claimsTeam',
        title: 'Claims Team',
        flex: 1.2,
      }] : [],
      ...isShowOnlyEscalated && hasClaimsTeamColumn ? [] : [{
        key: 'claimsStatus',
        title: 'Status',
        flex: 1.6,
        style: {
          marginRight: '20px',
          marginLeft: '-20px',
          flexDirection: 'row-reverse',
        },
      }],
    ] as ListItemBase[]),
    [
      hasAccessToAssignClaim,
      areAllClaimsSelected,
      claims,
      isShowOnlyEscalated,
      hasClaimsTeamColumn,
    ],
  );

  const handlePickOption = (id: string) => history.push(`${routes.CLAIM_PROCESS}/${id}`);
  const handleSelect = useCallback((id: string) => {
    if (selectedClaimsIds.some((item) => item === id)) return;

    setSelectedClaimsIds([...selectedClaimsIds, id]);
  }, [setSelectedClaimsIds, selectedClaimsIds]);

  const placeholder = hasClearAll
    ? <FlexListPlaceholder />
    : (
      <NoSearchItemsPlaceholder
        placeholderTitle="There are no claims yet"
        node={<StyledAbsenceFilesPlaceholderIcon size="72px" />}
      />
    );

  return (
    <Box
      width={globalTheme.defaultContentWidth}
      flex
      direction="column"
      margin={{ top: `${(hasAccessToAssignClaim ? EXTENDED_HEADER_HEIGHT : HEADER_HEIGHT) + (hasAccessToAssignClaim ? EXTENDED_HEADER_CLAIMS_SPACING : HEADER_CLAIMS_SPACING)}px` }}
    >
      {hasAccessToAssignClaim && selectedClaims.length ? (
        <AssignClaimsProcessorsPopup
          selectedClaims={selectedClaims}
          onClose={() => setSelectedClaimsIds([])}
        />
      ) : null}

      <ClearAll onClick={clearAll} isVisible={hasClearAll} />

      <Box
        background={{ color: 'module' }}
        round="container1Round"
      >
        <Box align="center" pad={{ bottom: 'spacing16' }}>
          <Box
            width="100%"
            direction="row"
            justify="between"
            align="center"
            pad={{ horizontal: 'spacing24', top: 'spacing24' }}
          >
            <Text
              weight="bold"
              color="textBody"
              style={{ whiteSpace: 'nowrap' }}
            >Claims queue: {(query ? 0 : total) || 0}
            </Text>
            <Box direction="row" justify="end">
              <Box margin={{ left: 'spacing8' }} width={{ min: '340px' }}>
                <SearchInput
                  disabled={(!hasClearAll && !total) || (!currentSearch && !total)}
                  className="search-input"
                  value={searchString}
                  hasSearchingHistory
                  placeholder="Employer, employee, claim #"
                  onChange={setSearchString}
                  resetValue={searchString || initCleaning}
                />
              </Box>
              <Box margin={{ left: 'spacing12' }} width={{ min: '150px', max: '150px' }}>
                <SelectDropdown
                  disabled={(!hasClearAll && !total) || (!hasPaymentType && !total)}
                  id="paymentType"
                  testId="paymentType"
                  singleMode
                  activeTitle
                  ellipsisMode
                  name="Payment Types"
                  prefix="Type"
                  options={PAYMENT_TYPES}
                  value={paymentTypes}
                  onChange={setPaymentTypes}
                />
              </Box>
              <Box margin={{ left: 'spacing12' }} width={{ min: '150px', max: '150px' }}>
                <SelectDropdown
                  disabled={(!hasClearAll && !total) || (!hasStatus && !total)}
                  id="status"
                  testId="status"
                  allMode
                  activeTitle
                  ellipsisMode
                  name="Activity status"
                  prefix="Status"
                  options={CLAIMS_STATUSE_OPTIONS}
                  values={statusValues}
                  onChangeValues={(status: OptionKey[]) => {
                    setStatuses(status);
                    setPage(1);
                  }}
                  dropdownStyles={{
                    width: 'fit-content',
                    maxWidth: 'none',
                    left: 'auto',
                  }}
                />
              </Box>
              <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                <DateRangeDropdown
                  disabled={(!hasClearAll && !total) || (!hasDateRange && !total)}
                  prefix="Period:"
                  className="period-picker"
                  onChange={(range) => {
                    setDateRange(range);
                    setPage(1);
                  }}
                  ellipsisMode
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  icon={<CalendarIcon color="iconPrimary" />}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {!query ? (
          <Box style={{ position: 'relative' }}>
            {!isOnlyMyAssignmets && isRefetching && !isLoading ? (
              <Box
                style={{ position: 'absolute', zIndex: '1', opacity: '0.5' }}
                width="100%"
                height="100%"
                background="canvas"
                align="center"
                justify="center"
              >
                <Preloader />
              </Box>
            ) : null}

            <FlexList
              testId="claims_list"
              headers={headers}
              rows={actualClaims || []}
              total={query ? 0 : total}
              pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
              moreCount={perPage}
              loading={isLoading}
              placeholder={placeholder}
              options={[
                {
                  name: 'Process',
                  onClick: handlePickOption,
                },
                ...(hasAccessToAssignClaim ? [{
                  name: 'Assign',
                  onClick: handleSelect,
                }] : []),
              ]}
              footer={(
                <Box style={{ position: 'relative' }}>
                  {selectedClaims?.length ? (
                    <Box width="100%" height="100%" style={{ zIndex: '1', position: 'absolute', cursor: 'not-allowed' }}/>
                  ) : null}
                  <PaginationBar
                    page={page}
                    total={total}
                    pageSize={perPage}
                    onChangePage={setPage}
                    onChangePageSize={setPerPage}
                  />
                </Box>
              )}
            />
          </Box>
        ) : <EmptyClaimsPlaceholder />}
      </Box>
    </Box>
  );
};

export default ClaimsList;
