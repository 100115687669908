import React, { useMemo } from 'react';
import { Box, Field, FieldTypes, Inscription } from '@common-fe/common-fe';
import * as yup from 'yup';

import { NO_KEY, REQUIRED_TEXT, YES_KEY } from '@/common/constants';
import useContactStore from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

export default (isEditMode?: boolean, isMainContactMode?: boolean) => {
  const defaultFields = useContactStore((state) => state.sourceState);
  const fields = useMemo((): Field[] => [
    {
      name: 'isAccessToSetup',
      type: FieldTypes.Radio,
      label: (
        <Box flex={{ grow: 1 }}>
          <Box direction="row" height={{ max: '20px' }} margin={{ bottom: '4px' }}>
            <Inscription weight="bold" color="textBody" lineHeight="20px">
              Access to organization
            </Inscription>
          </Box>
          <Box width={{ max: '208px' }}>
            <Inscription weight={500} color="textSecondary" size="small" lineHeight="18px">
              Configure when contacts get access to the organization
            </Inscription>
          </Box>
        </Box>
      ),
      showRequireIcon: true,
      isWrappedMode: true,
      inputStyle: { width: '320px', alignItems: 'center' },
      options: [
        {
          label: 'Access to express setup, allowing configuration of the plans',
          value: YES_KEY,
        },
        {
          label: 'Access only to already activated organization',
          value: NO_KEY,
        },
      ],
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: isMainContactMode ? NO_KEY : undefined,
      disabled: !!isEditMode && defaultFields?.isAccessToSetup === YES_KEY,
    },
  ], [defaultFields?.isAccessToSetup, isEditMode, isMainContactMode]);
  const formatedFields = useFieldsWithDefaultValues(fields, defaultFields);
  return formatedFields;
};
