import React, { useMemo } from 'react';

import { useStatesQuery } from '@/modules/core/hooks';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';

import AddressForm from './AddressForm';
import useAddressQuery from './useAddress.query';

interface Props {
  employerID: string;
  onDirty?: (isDirty: boolean) => void;
}

const Address: React.FC<Props> = ({ employerID, onDirty }) => {
  const { isLoading } = useStatesQuery();
  const { formattedData } = useGetEmployer();
  const { saveAddressInfo } = useAddressQuery(employerID);
  const { loading: isEmployerLoading } = useSetupEmployerStore();
  const isDataLoading = useMemo(() => isLoading
    || isEmployerLoading
    || !formattedData.overridableProperties,
  [isLoading, isEmployerLoading, formattedData.overridableProperties]);

  return (
    <AddressForm
      data={formattedData}
      onSubmit={saveAddressInfo}
      onDirty={onDirty}
      isLoading={isDataLoading}
    />
  );
};

export default Address;
