import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';
import {
  ContactResponseModel,
  CreateContactDto,
} from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurity.types';

export enum ContactQueryNames {
  CONTACT_LIST = 'contactList',
}

export const DEFAULT_PAGE_SIZE = 10;

interface Props {
  search?: string;
  organizationId?: string;
  size?: number;
  permissionsIds?: Array<number | undefined>;
  triggerRefetching?: string;
  page?: number;
  enabled?: boolean;
}

export const useContactListQuery = (props?: Props) => {
  const query = useQuery(
    [
      ContactQueryNames.CONTACT_LIST,
      props?.search,
      props?.organizationId,
      props?.size,
      props?.permissionsIds,
      props?.triggerRefetching,
      props?.page,
      props?.enabled,
    ],
    () => api.get<Pagination<ContactResponseModel>>(PATHS.CONTACTS_LIST, {
      params: {
        ...props?.search ? { search_value: props?.search } : {},
        ...props?.size && props.size !== DEFAULT_PAGE_SIZE ? { size: props?.size } : {},
        ...props?.organizationId ? { organization_id: props?.organizationId } : {},
        ...props?.permissionsIds?.length ? { type: props?.permissionsIds?.join(',') } : {},
        ...props?.page ? { page: props?.page } : {},
      },
    }),
    { enabled: props?.enabled },
  );

  const formatedList = useMemo(() => {
    const list = (query.data?.data?.content || query.data?.data) as ContactResponseModel[];
    return list;
  }, [query.data]);
  return {
    ...query,
    contactList: formatedList || [],
    totalElements: query.data?.data?.totalElements,
  };
};

export const useAddContactMutation = () => useMutation(
  (value: CreateContactDto) => api.post(PATHS.CONTACTS, value),
);

export const useUpdateContactMutation = (contactId?: string) => useMutation(
  (value: ContactResponseModel) => api.put(PATHS.UPDATE_CONTACT(contactId), value),
);
