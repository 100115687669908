import { Anchor, Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledBox = styled(Box)<{ expand: boolean, isFullWidth: boolean }>`
  position: ${({ expand }) => (expand ? 'absolute' : 'static')};
  ${({ isFullWidth, theme }) => (
    isFullWidth ? 'width: 100%;' : `
  min-width: 512px;
  width: calc(33.333% - ${theme.spacings.spacing16});
  `
  )}
  // max-height: ${({ expand }) => (expand ? '512px' : '320px')};
  ${({ theme, expand }) => (expand ? theme.shadows.active : theme.shadows.default)};
  transition: box-shadow .3s;
  z-index: ${({ expand }) => (expand ? '2' : '1')};
};
`;

export const ExpandButton = styled(Box)`
  box-shadow: none;
`;

export const GenerateReportButton = styled(Box)`
  box-shadow: none;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
`;

interface FilterButtonProps {
  active?: boolean;
}

export const FilterButton = styled(Box)<FilterButtonProps>`
  min-width: 28px;
  min-height: 28px;
  background-color: ${({ theme, active }) => (active ? theme.colors.accentBorder : undefined)};
  transition: all .3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
  svg {
    fill: ${({ theme, active }) => (active && theme.colors.iconAccentActive)};
  }
`;

export const LoadingBox = styled(Box)`
  box-shadow: none;
  animation:spin 2s linear infinite;
  
  @keyframes spin {
    100% {
      transform:rotate(360deg);
    }
  }
`;

export const StyledAnchor = styled(Anchor)`
  box-shadow: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;
