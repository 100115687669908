import React, { useCallback, useMemo } from 'react';
import { Box, ClickToCopy, ListItem } from '@common-fe/common-fe';

import { LARGE_SIZE_LOGO, LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { ListItemType, PaginationParams } from '@/common/types';
import { Icon } from '@/components/elements/Icon/Icon';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import useHistory from '@/modules/core/hooks/useHistory';
import { Partner } from '@/modules/employer/employer.types';
import { getIdsFromPath } from '@/utils';

import { usePartnersListQuery } from '../queries';

interface Params extends PaginationParams {
  mode: OrganizationTypes;
  organizationType?: OrganizationTypes;
}
const usePartnersList = (params: Params) => {
  const { mode } = params;
  const { data, isLoading, total, error, refetch } = usePartnersListQuery(params);
  const { redirectToObservingMode } = useHistory();

  const isLogoInList = useMemo(() => data.some((item: Partner) => item?.darkLargeLogoUrl), [data]);
  const type = useMemo(() => {
    switch (mode) {
    case OrganizationTypes.distributor:
      return OrganizationTypes.distributor;
    case OrganizationTypes.subsidiary:
      return OrganizationTypes.subsidiary;
    default:
      return OrganizationTypes.partner;
    }
  }, [mode]);

  const handleGetClickAction = useCallback(
    (item: Partner) => () => {
      const { partnerId, distributorId, employerId, subsidiaryId, subgroupId } = getIdsFromPath(item.path);
      if (item.subgroupId) {
        redirectToObservingMode(OrganizationTypes.subgroup, subgroupId);
      } else if (subsidiaryId) {
        redirectToObservingMode(OrganizationTypes.subsidiary, subsidiaryId);
      } else if (employerId) {
        redirectToObservingMode(OrganizationTypes.company, employerId);
      } else if (distributorId) {
        redirectToObservingMode(OrganizationTypes.distributor, distributorId);
      } else if (partnerId) {
        redirectToObservingMode(OrganizationTypes.partner, partnerId);
      }
    },
    [redirectToObservingMode]
  );

  const formatedList = useMemo<ListItem[]>(
    () =>
      data.map((item: Partner) => ({
        id: item.id,
        fields: [
          {
            key: 'logo',
            type: ListItemType.ErLogo,
            value: item.name,
            organizationType: type,
            title: item.logo,
            logoUrl: item.darkLargeLogoUrl,
            hover: true,
            flex: 2,
            isLogoInList,
            logo: (
              <Icon
                type={type}
                text={item.name}
                src={item.darkLargeLogoUrl}
                size={LetterIconSizeEnum.LOGO}
                height={LARGE_SIZE_LOGO.height}
                width="auto"
              />
            ),
            node: (
              <TextWithPointer
                title={item.name}
                size="medium"
                weight="bold"
                color="textBody"
                margin={{
                  left: 'm',
                  right: 's'
                }}
                onClick={handleGetClickAction(item)}
              >
                {item.name}
              </TextWithPointer>
            ),
          },
          {
            key: 'id',
            type: ListItemType.Node,
            node: (
              <Box margin={{ right: 'spacing4' }}>
                <ClickToCopy title={item.externalIdentifier || ''} testId={item.id} />
              </Box>
            ),
          },
          {
            key: 'status',
            type: ListItemType.Status,
            title: item.status || '',
          },
          {
            key: 'statusDate',
            type: ListItemType.Text,
            title: item.date,
          },
        ],
      })),
    [data, type, isLogoInList, handleGetClickAction]
  );

  const findPartnerInfoById = useCallback(
    (id: string) => data.find((item: Partner) => item.id === id),
    [data]
  );

  const isEmpty = useMemo(() => data.length === 0, [data.length]);
  return {
    isEmpty,
    list: data,
    isLoading,
    total,
    formatedList,
    findPartnerInfoById,
    refetch,
    error,
  };
};

export default usePartnersList;
