import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { Pagination } from '@/common/types';
import { HealthPlanResponse } from '@/modules/HealthPlan/HealthPlan.types';

import { useSortTiers as sortTiers } from '../../hooks';
import { ROOT_ORGANIZATION } from '../../ProcessingDefinition.constants';
import { EnrollmentMatrixItem, SearchEnrollmentMatrixParams,TierPayload } from '../../ProcessingDefinition.types';
import { useDisplayEntitiesPopupStore } from '../DisplayEntitiesPopup/useDisplayEntitiesPopup.store';
import { useEnrollmentMatrixStore } from '../stores/useEnrollmentMatrix.store';

interface ErollementMatrixesPayload {
  id: number;
  name: string;
  organization_type: string;
  priority: string;
  valid_from: string;
  valid_to: string;
  health_plan_template?: HealthPlanResponse;
  plan_template?: {
    id: number;
    name: string;
  },
  plan_year?: {
    id: number;
    name: string;
  },
  processing_rule: {
    id: 0;
    name: string;
    tiers: TierPayload[];
  },
}

const formatedData = (data?: ErollementMatrixesPayload[]) => {
  const list = data || [];
  let isHealthPlanExists = false;
  const formated = list.reduce((result: EnrollmentMatrixItem[], item, index) => {
    if (item.health_plan_template) {
      isHealthPlanExists = true;
    } else if (isHealthPlanExists) {
      const previousItem = result[index - 1];
      if (previousItem) {
        previousItem.isHealthPlanDelimiter = true;
        isHealthPlanExists = false;
      }
    }
    const formatedItem: EnrollmentMatrixItem = {
      id: `${item.id}`,
      name: item.name,
      organizationType: item.organization_type,
       
      planTemplate: item?.plan_template ? {
        id: `${item.plan_template.id}`,
        name: item.plan_template.name,
      } : !item?.plan_template && item.health_plan_template ? {
        id: '',
        name: item.health_plan_template.name.name || '',
      } : undefined,
      planYear: item?.plan_year ? {
        id: `${item.plan_year.id}`,
        name: item.plan_year.name,
      } : undefined,
      processingRule: {
        id: `${item?.processing_rule?.id || ''}`,
        name: item?.processing_rule?.name,
        tiers: sortTiers(item?.processing_rule?.tiers)
          ?.map((tier) => ({
            id: `${tier.id}`,
            name: tier.name,
            payoutDefinition: {
              id: `${tier.payout_definition.id}`,
              name: tier.payout_definition.name,
            },
            isSendTriggerNotification: tier.is_send_trigger_notification,
            triggers: tier.triggers,
            nextTierId: tier.next_tier_id,
            displayName: tier?.display_name,
            planId: tier?.plan_id,
            planName: tier?.plan_name,
          })),
      },
      validFrom: item.valid_from ? dayjs(item.valid_from).format(DEFAULT_DATE_FORMAT) : '',
      validTo: item.valid_to ? dayjs(item.valid_to).format(DEFAULT_DATE_FORMAT) : '',
      priority: item.priority,
      isLinkToHealthPlan: !!item.health_plan_template,
    };
    return [...result, formatedItem];
  }, []);
  return formated;
};

export const QUERY_KEY = 'GET_ENROLLMENT_MATRIXES';
export const FILTER_QUERY_KEY = 'GET_FILTERED_ENROLLMENT_MATRIXES';
export const useEnrollmentMatrixesQuery = (
  { filterParams, ...searchParams }: SearchEnrollmentMatrixParams,
  organizationPath?: string,
) => {
  const isFilterApplied = useMemo(() => !_.isEmpty(filterParams), [filterParams]);
  const {
    activePage,
  } = useEnrollmentMatrixStore();
  const {
    data, isLoading, refetch,
  } = useQuery(
    [QUERY_KEY, searchParams, organizationPath],
    () => api.get<Pagination<ErollementMatrixesPayload>>(
      PATHS.ENROLLMENT_MATRIXES, {
        params: {
          ...searchParams,
          page: activePage ? activePage - 1 : searchParams.page,
          organization_path: organizationPath || ROOT_ORGANIZATION,
        },
      },

    ),
    { enabled: !!searchParams },
  );

  const {
    data: filteredData, isLoading: isFilteredLoading,
  } = useQuery(
    [FILTER_QUERY_KEY, filterParams, organizationPath],
    () => api.get<ErollementMatrixesPayload>(
      PATHS.ENROLLMENT_MATRIXES_CLOSEST, {
        params: {
          enrollment_date: filterParams?.enrollmentDate,
          template_id: filterParams?.planTemplate,
          plan_year_id: filterParams?.planYear,
          org_path: organizationPath || ROOT_ORGANIZATION,
        },
      },
    ),
    { enabled: !!(filterParams?.enrollmentDate && filterParams?.planTemplate) },
  );

  const currentData = useMemo(() => {
    if (isFilterApplied) {
      return formatedData(filteredData?.data ? [filteredData?.data] : []);
    }
    return formatedData(data?.data?.content);
  }, [data, filteredData, isFilterApplied]);

  const matrixesData = useMemo(() => currentData.filter((item) => !item.isLinkToHealthPlan),
    [currentData]);

  const matrixesHealthPlanData = useMemo(() => currentData
    .filter((item) => item.isLinkToHealthPlan),
  [currentData]);

  return {
    isLoading: isFilterApplied ? isFilteredLoading : isLoading,
    data: currentData,
    matrixes: matrixesData,
    matrixesHealthPlan: matrixesHealthPlanData,
    refetch,
    total: data?.data.totalElements || 0,
  };
};

export const EXPIRED_QUERY_KEY = 'GET_EXPIRED_ENROLLMENT_MATRIXES';
export const useExpiredEnrollmentMatrixesQuery = (
  searchParams?: SearchEnrollmentMatrixParams,
  organizationPath?: string,
) => {
  const currentValues = useDisplayEntitiesPopupStore((state) => state.currentValues);
  const {
    data, isLoading,
  } = useQuery(
    [EXPIRED_QUERY_KEY, searchParams, organizationPath],
    () => api.get<Pagination<ErollementMatrixesPayload>>(
      PATHS.EXPIRED_ENROLLMENT_MATRIXES, {
        params: {
          ...searchParams,
          organization_path: organizationPath || ROOT_ORGANIZATION,
        },
      },
    ),
    { enabled: !!searchParams && currentValues?.expired },
  );

  return {
    isLoading,
    data: formatedData(data?.data?.content),
    total: data?.data.totalElements || 0,
  };
};
