export enum PayrollGroupFormFields {
  GROUP_NAME = 'groupName',
  GROUP_CODE = 'groupCode',
}

export interface PayrollGroupFormValues {
  [PayrollGroupFormFields.GROUP_NAME]?: string;
  [PayrollGroupFormFields.GROUP_CODE]?: string;
}

export interface PayrollGroupResponse {
  id: string;
  name?: string;
  code?: string;
  created_at?: string;
  organization_id?: string;
  organization_path?: string;
}

export interface PayrollGroup {
  id: string;
  name?: string;
  code?: string;
  createdAt?: string;
  organizationId?: string;
  organizationPath?: string;
}

export interface PayrollGroupRequest {
  id?: string;
  name?: string;
  code?: string;
  organization_id?: string;
  organization_path?: string;
}
