import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Topbar } from '@/modules/core/components';
import ReportsWrap from '@/modules/employer/components/Reports/components/ReportSection/ReportsWrap';
import {
  REPORT_SECTIONS_WRAPPER_ID,
  useReportActiveSection,
} from '@/modules/employer/components/Reports/hooks/useReportActiveSection';
import theme from '@/styles/theme';

import { PickReportHeader } from './components/PickReportHeader/PickReportHeader';
import { reportTypes } from './components/ReportSection/ReportSection';
import { ReportTypeTopbar } from './components/ReportTypeTopbar/ReportTypeTopbar';

const FixedReportTypeWrap = styled(Box)<{ isScrolled?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: ${({ theme: t, isScrolled }) => (isScrolled ? `1px solid ${t.colors.border1}` : undefined)};
`;

const Reports: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const reportTypesRef = useRef<HTMLDivElement>(null);

  const fixedReportTypesTopbarHeight = useMemo(
    () => reportTypesRef.current?.offsetHeight || 0,
     
    [reportTypesRef],
  );
  const {
    activeSection,
    selectSection,
    detectActiveSection,
  } = useReportActiveSection(fixedReportTypesTopbarHeight, reportTypes);
 
  const onScrollHandler = useCallback((evt:   React.UIEvent<HTMLElement>) => {
    if (
      !isScrolled
      && evt.currentTarget.scrollTop >= 0
    ) {
      setIsScrolled(true);
    } else if (
      isScrolled
      && evt.currentTarget.scrollTop <= 0
    ) {
      setIsScrolled(false);
    }
    detectActiveSection();
  }, [isScrolled, detectActiveSection]);

  return (
    <Box
      data-testId="reports-wrapper"
      width="100%"
      overflow="auto"
      onScroll={onScrollHandler}
      id={REPORT_SECTIONS_WRAPPER_ID}
      style={{ transition: 'all .3s' }}
    >
      <FixedReportTypeWrap
        data-testId="fixed-report-type-wrap"
        background={{ color: 'canvas' }}
        height={{ min: 'max-content' }}
        isScrolled={isScrolled}
      >
        <Topbar hideHeader={isScrolled} />
        <Box alignSelf="center" width={theme.defaultContentWidth}>
          <PickReportHeader minimize={isScrolled} />
          <Box margin={{ bottom: 'spacing4' }} background="canvas">
            <ReportTypeTopbar
              types={reportTypes}
              ref={reportTypesRef}
              activeSection={activeSection}
              selectSection={selectSection}
            />
          </Box>
        </Box>
      </FixedReportTypeWrap>

      <Box pad={{ top: '0', bottom: '40px' }}>
        <Box align="center">
          <Box width={theme.defaultContentWidth}>
            <Box flex="grow" style={{ position: 'relative' }}>
              <Box data-testId="report-section" pad={{ bottom: 'spacing72', top: 'spacing12' }}>
                <ReportsWrap />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
