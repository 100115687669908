import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useHealthPlanNameFieldsStateStore, { HealthPlanNameFieldsStatePayload } from '../HealthPlanName/stores/useHealthPlanNameFieldsState.store';
import usePlanYearFieldsStateStore, { PlanYearPayload } from '../PlanYear/stores/usePlanYearFieldsState.store';

export type BasicPropertiesFieldsStatePayload = HealthPlanNameFieldsStatePayload & PlanYearPayload;

export default () => {
  const setHealthPlanNameStore = useHealthPlanNameFieldsStateStore((state) => state.setState);
  const setPlanYearStore = usePlanYearFieldsStateStore((state) => state.setState);

  const setSourceHealthPlanNameStore = useHealthPlanNameFieldsStateStore(
    (state) => state.setSourceState,
  );
  const setSourcePlanYearStore = usePlanYearFieldsStateStore(
    (state) => state.setSourceState,
  );

  const handleResetHealthPlanNameStore = useHealthPlanNameFieldsStateStore(
    (state) => state.handleReset,
  );
  const handleResetPlanYearStore = usePlanYearFieldsStateStore(
    (state) => state.handleReset,
  );

  const showErrorsHealthPlanNameStore = useHealthPlanNameFieldsStateStore(
    (state) => state.setShowErrors,
  );
  const showErrorsPlanYearStore = usePlanYearFieldsStateStore(
    (state) => state.setShowErrors,
  );

  const setHealthPlanNameErrors = useHealthPlanNameFieldsStateStore((state) => state.setErrors);
  const setPlanYearErrors = usePlanYearFieldsStateStore((state) => state.setErrors);

  const setHealthPlanNameErrorFields = useHealthPlanNameFieldsStateStore(
    (state) => state.setErrorFields,
  );
  const setPlanYearErrorFields = usePlanYearFieldsStateStore(
    (state) => state.setErrorFields,
  );

  const handleSetState = useCallback((values: Partial<BasicPropertiesFieldsStatePayload>) => {
    setHealthPlanNameStore(values);
    setPlanYearStore(values);
  }, [setHealthPlanNameStore, setPlanYearStore]);

  const handleSetSourceState = useCallback((values: Partial<BasicPropertiesFieldsStatePayload>) => {
    setSourceHealthPlanNameStore(values);
    setSourcePlanYearStore(values);
  }, [setSourceHealthPlanNameStore, setSourcePlanYearStore]);

  const handleReset = useCallback(() => {
    handleResetHealthPlanNameStore();
    handleResetPlanYearStore();
  }, [handleResetHealthPlanNameStore, handleResetPlanYearStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsHealthPlanNameStore(value);
    showErrorsPlanYearStore(value);
  }, [showErrorsHealthPlanNameStore, showErrorsPlanYearStore]);

  const handleSetErrors = useCallback((values: Partial<BasicPropertiesFieldsStatePayload>) => {
    setHealthPlanNameErrors(values);
    setPlanYearErrors(values);
  }, [
    setHealthPlanNameErrors,
    setPlanYearErrors,
  ]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<BasicPropertiesFieldsStatePayload>>) => {
      setHealthPlanNameErrorFields(values);
      setPlanYearErrorFields(values);
    }, [setHealthPlanNameErrorFields, setPlanYearErrorFields],
  );
  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
