import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';

import { formatTeamPayload } from './ClaimsProcessors.types';

const useTeamQuery = (id?: string, enabled?: boolean) => {
  const { isLoading, data } = useQuery([
    PATHS.CLAIMS_TEAM(id),
    enabled,
  ], () => api.get(PATHS.CLAIMS_TEAM(id)), { enabled: Boolean(enabled && id)});

  const team = useMemo(() => {
    if (!data?.data) return undefined;
    return formatTeamPayload(data.data);
  }, [data]);

  return ({
    team,
    isLoading,
  });
};

export default useTeamQuery;
