import { useMemo } from 'react';

export const useCalculateFillProgress = (...fieldsArr: (object | null)[]) => {
  const fields = useMemo(
    () => fieldsArr
      .filter((field) => !!field)
      .reduce((result, argItem) => ({ ...argItem, ...result }), {}) as object,
    [fieldsArr],
  );
  const total = useMemo(
    () => Object.keys(fields).length,
    [fields],
  );

  const passed = useMemo(
    () => Object.values(fields)
      .reduce((amount: number, fieldValue) => (
        fieldValue !== undefined && fieldValue !== null && fieldValue !== ''
          ? amount + 1
          : amount
      ), 0) || 0,
    [fields],
  );
  return useMemo(() => ({
    total, passed,
  }), [total, passed]);
};
