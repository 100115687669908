import React, { useMemo } from 'react';
import { Text } from '@common-fe/common-fe';

import { ActivateModalItem, ExpressSetup, ReplenishmentFrequencyType } from '../ExpressSetup.types';

const dayNames: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const getBoldText = (text: string) => (
  <Text color="textBody" style={{ lineHeight: '20px' }} weight={700}>
    {text}
  </Text>
);

const formatReplenishmentDayNumber = (dayNumber?: number) => {
  if (dayNumber === 1) return '1st';
  if (dayNumber === 2) return '2nd';
  if (dayNumber === 3) return '3rd';
  return `${dayNumber}th`;
};

const REPLENISHMENT_TITLE = 'Replenishment';
const PRE_FUNDING = 'Pre-funding';
const CONTRIBUTIONS_TITLE = 'Contributions';
const CARDS_TITLE = 'Cards';

export const useFundingItems = (expressSetup?: ExpressSetup) => {
  const replenishmentFrequencyText = useMemo(() => {
    switch (expressSetup?.replenishmentFrequencyType) {
    case ReplenishmentFrequencyType.DAILY:
      return `${ReplenishmentFrequencyType.DAILY.toLocaleLowerCase()}`;
    case ReplenishmentFrequencyType.MONTHLY:
      return `${ReplenishmentFrequencyType.MONTHLY.toLocaleLowerCase()}`;
    case ReplenishmentFrequencyType.WEEKLY:
      return `${ReplenishmentFrequencyType.WEEKLY.toLocaleLowerCase()}`;
    default:
      return '';
    }
  }, [expressSetup]);

  const replenishmentDayNumberText = useMemo(() => {
    switch (expressSetup?.replenishmentFrequencyType) {
    case ReplenishmentFrequencyType.DAILY:
      return '';
    case ReplenishmentFrequencyType.MONTHLY:
      return `${formatReplenishmentDayNumber(expressSetup?.replenishmentDayNumber)}`;
    case ReplenishmentFrequencyType.WEEKLY:
      return `${expressSetup?.replenishmentDayNumber ? dayNames[expressSetup?.replenishmentDayNumber] : ''}`;
    default:
      return '';
    }
  }, [expressSetup]);

  const fundingNextStepsItems = useMemo<ActivateModalItem[]>(() => [
    {
      title: 'Census',
      description: 'Load employee demographic information through the admin portal in file manager or manually.',
    },
    {
      title: 'Enrollment',
      description: 'Once census is loaded add each individuals election through the file manager or manual entry.',
    },
  ], []);

  const fundingAndCardsItems = useMemo(() => {
    const items: ActivateModalItem[] = [];

    const addOrUpdateItem = (title: string, accountName: string, description: JSX.Element) => {
      const existingItem = items.find((item) => item.title === title);
      if (existingItem) {
        existingItem.accounts?.push(accountName);
      } else {
        items.push({
          title,
          accounts: [accountName],
          description,
        });
      }
    };

    expressSetup?.plans?.forEach((plan) => {
      if (plan?.isCustomPrefundCalc) {
        addOrUpdateItem(
          PRE_FUNDING,
          plan.name,
          <Text color="textBody" style={{ lineHeight: '20px' }}>
            {getBoldText(expressSetup?.partnerName)} holds a portion of member enrollments to support quick claim payments and reimbursements for your employees. 15 days before the plan begins, we will be initiating a transaction to pull {expressSetup?.prefundReplenishmentPercentage}% from your account to support new enrollments into your plan(s).
          </Text>
        );
      }
      if (plan?.isReplenished && plan?.isPlanLevelFunding) {
        addOrUpdateItem(
          REPLENISHMENT_TITLE,
          plan.name,
          <Text color="textBody" style={{ lineHeight: '20px' }}>
            To support these accounts {getBoldText(expressSetup?.partnerName)} will pull funds {getBoldText(replenishmentFrequencyText)} {expressSetup?.replenishmentFrequencyType !== ReplenishmentFrequencyType.DAILY ? 'on ' : ''}{getBoldText(replenishmentDayNumberText)} to replenish your account for any spend that has occurred.
          </Text>
        );
      }
      if (!plan?.isReplenished && plan?.isPlanLevelFunding) {
        addOrUpdateItem(
          CONTRIBUTIONS_TITLE,
          plan.name,
          <Text color="textBody" style={{ lineHeight: '20px' }}>
            On each scheduled pay date, you must notify {getBoldText(expressSetup?.partnerName)} of the contributions being made to each employees HSA. {getBoldText(expressSetup.partnerName)} will pull the total contribution amounts.
          </Text>
        );
      }
    });

    if (expressSetup?.plans?.some((plan) => plan.isCarded)) {
      items.push({
        title: CARDS_TITLE,
        description: 'Cards will be mailed within 7-10 business days of enrollment being loaded. Employees will need their card to rec full access to their account.',
      });
    }

    const contributionsItem = items.find(item => item.title === CONTRIBUTIONS_TITLE);
    const contributionNextStepItem = fundingNextStepsItems.find(item => item.title === CONTRIBUTIONS_TITLE);
    
    if (contributionsItem && !contributionNextStepItem) {
      fundingNextStepsItems.push({
        title: CONTRIBUTIONS_TITLE,
        accounts: contributionsItem.accounts,
        description: 'Contributions must be loaded for these accounts on each pay cycle or funding frequency to increase the account balance. All other accounts are front loaded based on the member election.',
      });
    }

    return items;
  }, [expressSetup, replenishmentFrequencyText, replenishmentDayNumberText, fundingNextStepsItems]);

  return { fundingNextStepsItems, fundingAndCardsItems };
};
