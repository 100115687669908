import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton,
  Box,
  DownloadIcon,
  Heading,
  PendingModal,
  Preloader,
  SuccessModal,
  Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import { useCreateScheduledContributionQuery } from '../queries';
import useScheduledContributionStore from '../stores/useScheduledContribution.store';

import ScheduledContributionRecords from './ScheduledContributionRecords/ScheduledContributionRecords';
import { useScheduledContributionExportQuery } from './queries';
import ScheduledContributionCalendars from './ScheduledContributionCalendars';
import ScheduledContributionSummaryFooter from './ScheduledContributionSummaryFooter';

interface Props {
  isEditMode?: boolean;
  contributionId?: string;
}

const ScheduledContributionSummary: React.FC<Props> = ({ isEditMode, contributionId }) => {
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const state = useScheduledContributionStore((state) => state.state);
  const { handleSave, isLoading } = useCreateScheduledContributionQuery({
    isEditMode,
  });
  const handleSaveContribution = useCallback(async () => {
    setIsConfirmModal(false);
    await handleSave(state);
    setIsSuccessModal(true);
  }, [handleSave, state]);
  const history = useHistory();
  const handleBack = useCallback(() => {
    history.push(ROUTES.CONTRIBUTIONS);
  }, [history]);
  useEffect(() => {
    if (!state.calendarId) {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleSuccess = useCallback(() => {
    setIsSuccessModal(false);
    handleBack();
  }, [handleBack]);
  const { handleDownload, isLoading: isExportingLoading } = useScheduledContributionExportQuery();

  return (
    <>
      <Box align="center" pad={{ bottom: 'spacing72' }}>
        <Box width={theme.defaultContentWidth} style={{ minHeight: '78vh' }}>
          <Box direction="column" pad={{ top: 'l' }}>
            <Box direction="row" justify="between" align="center">
              <Box direction="column">
                <Heading color="textBody" level={2} size="small">
                  Scheduled Contribution Summary
                </Heading>
                <Text color="textBody">
                  Scheduled contributions will be applied on each payroll date based on the employees's per
                  pay contributions at that time.
                </Text>
              </Box>
              <AppButton disabled={isExportingLoading} onClick={handleDownload} buttonType="secondary">
                {isExportingLoading ? (
                  <Preloader></Preloader>
                ) : (
                  <>
                    Export
                    <Box margin={{ left: 'xs' }}>
                      <DownloadIcon />
                    </Box>
                  </>
                )}
              </AppButton>
            </Box>

            <Box pad={{ top: 'l' }}>
              <ScheduledContributionCalendars></ScheduledContributionCalendars>
              <ScheduledContributionRecords></ScheduledContributionRecords>
            </Box>
          </Box>
        </Box>
      </Box>
      <PendingModal
        visible={isConfirmModal}
        onSetVisible={setIsConfirmModal}
        header={isEditMode ? 'Update schedule contributions?' : 'Schedule contributions?'}
        helptext={
          'Scheduled contributions will be applied on each payroll date within the selected calendar based on the employees\'s per pay contributions at that time.'
        }
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton
              onClick={() => {
                setIsConfirmModal(false);
              }}
              width="240px"
              buttonType="secondary"
            >
              Cancel
            </AppButton>
          </Box>
          <AppButton disabled={isLoading} onClick={handleSaveContribution} width="240px">
            {isEditMode ? 'Update' : 'Yes, schedule contributions'}
          </AppButton>
        </Box>
      </PendingModal>
      <SuccessModal
        testId="created-scheduled-contribution_success"
        visible={isSuccessModal}
        onSetVisible={handleSuccess}
        header={isEditMode ? 'Contributions was successfully updated!' : 'Contributions have been scheduled!'}
        buttonText="Got It!"
        onSubmit={handleSuccess}
      />

      <ScheduledContributionSummaryFooter
        onCancel={handleBack}
        onSubmit={() => {
          setIsConfirmModal(true);
        }}
      />
    </>
  );
};

export default ScheduledContributionSummary;
