import { useEffect, useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/hooks';
import { useFieldsWithDefaultValues } from '@/utils';

import { usePreferredMailingAdressStore } from '../../PreferredMailingAddress/stores';
import { usePreferredPhysicalAdressStore } from '../stores';

const REQUIRED_VALIDATOR = yup.string().trim().required(REQUIRED_TEXT);
const VALIDATOR = yup.string().trim();
export default (isEditMode?: boolean) => {
  const defaultFields = usePreferredPhysicalAdressStore((state) => state.state);
  const mailingState = usePreferredMailingAdressStore((state) => state.state);
  const setState = usePreferredPhysicalAdressStore((state) => state.setState);

  const { statesOptions } = useStatesQuery();
  const isSameASMailing = usePreferredPhysicalAdressStore((state) => state.state.sameAsMailing);

  useEffect(() => {
    if (isSameASMailing) {
      setState({
        physicalFirstAddressLine: mailingState.firstAddressLine,
        physicalSecondAddressLine: mailingState.secondAddressLine,
        physicalCity: mailingState.city,
        physicalCountryCode: mailingState.countryCode,
        physicalZipCode: mailingState.zipCode,
        physicalState: mailingState.state,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameASMailing, mailingState]);

  const fields = useMemo<Field[]>(() => {
    const newFields = [
      {
        name: 'sameAsMailing',
        type: FieldTypes.Checkbox,
        label: 'Same as the Preferred Mailing Address',

        // defaultValue: isSameASMailing,

      },
      ...!isSameASMailing ? [
        {
          name: 'physicalFirstAddressLine',
          type: FieldTypes.Text,
          label: 'Address Line 1',

          placeholder: 'Address Line 1',
          showRequireIcon: true,

          validator: REQUIRED_VALIDATOR,
        },
        {
          name: 'physicalSecondAddressLine',
          type: FieldTypes.Text,
          label: 'Address Line 2',

          placeholder: 'Address Line 2',
          validator: VALIDATOR,
        },
        {
          name: 'physicalCity',
          type: FieldTypes.Text,
          label: 'City',
          showRequireIcon: true,

          placeholder: 'Enter City',
          validator: REQUIRED_VALIDATOR,
          defaultValue: '',
        },
        {
          name: 'physicalState',
          type: FieldTypes.AutocompleteDropdown,
          label: 'State',
          singleMode: true,
          showRequireIcon: true,

          placeholder: 'Select state',
          validator: REQUIRED_VALIDATOR,
          options: statesOptions,
        },
        {
          name: 'physicalZipCode',
          type: FieldTypes.ZipCode,
          label: 'ZIP code',

          placeholder: 'Enter ZIP code',
          showRequireIcon: true,
          visible: false,
          validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
            (val) => (val && (val.length === 10 || val.length === 5))
                  || (val === '')).required(REQUIRED_TEXT),
          defaultValue: '',
        },
        {
          name: 'physicalCountryCode',
          type: FieldTypes.Text,
          label: 'Country code',
          disabled: true,
          showRequireIcon: true,
          placeholder: 'Enter Country code',
          validator: yup.string().required(REQUIRED_TEXT),
          defaultValue: 'US',
        },
      ] as Field[] : [],
    ] as Field[];

    return useFieldsWithDefaultValues(newFields, defaultFields);
  }, [defaultFields, isSameASMailing, statesOptions]);

  return fields;
};
