import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PriceInput } from '@common-fe/common-fe';
import { toString } from 'lodash';

import useRecipientsStore from './stores/useRecipients.store';
import disableAccountIfZeroAmounts from './utils/disableAccountIfZeroAmounts';
import { getAmountInputErrorMessage } from './utils/getErrorMessage';
import { WrapToError } from './ContributionEmployeesSettings.styles';
import { ContributionAmountSideKey } from './ContributionEmployeesSettings.types';
import { ContributionAccount, ContributionBackendErrors, Recipient } from './types';
import WarnIconWithText from './WarnIconWithText';

interface Props {
  amountKey:ContributionAmountSideKey;
  recipient: Recipient;
  contributionAccount: ContributionAccount;
  refInput?: React.RefObject<HTMLInputElement>;
  areErrorsShown?: boolean;
  backendError?: ContributionBackendErrors;
}

const AmountInput: React.FC<Props> = ({ recipient, contributionAccount, amountKey, refInput, areErrorsShown, backendError }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { recipients, setRecipients } = useRecipientsStore();
  const errorMessage = useMemo(() => getAmountInputErrorMessage(amountKey, contributionAccount, areErrorsShown, backendError),
    [areErrorsShown, contributionAccount, amountKey, backendError]);

  useEffect(() => () => setIsFocused(false), []);

  const handleChange = useCallback((val: string) => {
    if (isFocused) {
      setRecipients(recipients?.map((item) => {
        if (item?.employeeId === recipient?.employeeId) {
          return {
            ...item,
            contributionAccounts: item?.contributionAccounts?.map((acc) => {
              if (acc?.planId === contributionAccount?.planId) {
                const updatedAcc = {
                  ...contributionAccount,
                  [amountKey]: val,
                };

                return disableAccountIfZeroAmounts(updatedAcc);
              }
              return acc;
            })
          };
        }
        return item;
      }));
    }
  }, [amountKey, contributionAccount, isFocused, recipient?.employeeId, recipients, setRecipients]);

  return (
    <WrapToError hasError={Boolean(errorMessage)} width={{ max: '140px' }} onBlur={() => setIsFocused(false)} onFocus={() => setIsFocused(true)}>
      <PriceInput
        disabled={contributionAccount?.isDisabled}
        name={amountKey}
        value={contributionAccount?.isDisabled ? '' : toString(contributionAccount?.[amountKey])}
        onChange={handleChange}
        placeholder="Set amount"
        refInput={refInput}
        {...errorMessage ? {
          rightIcon: (
            <WarnIconWithText message={errorMessage} />
          ),
        } : {}}
      />
    </WrapToError>
  );
};

export default AmountInput;
