import React from 'react';
import { AppButton,WarnModal } from '@common-fe/common-fe';

import { CardActivationModalType } from '@/modules/transaction/Cards/Cards.types';

import { useCardActivationModal } from './hooks/useCardActivationModal';

const SUBMIT_BUTTON_WIDTH = '160px';

export const CardActivationModal = () => {
  const {
    modalVisible,
    modalType,
    currentModalHeader,
    currentSubmitButtonText,
    currentSubmitButtonHandler,
    handleSubmitClose,
  } = useCardActivationModal();

  return (
    <WarnModal
      testId={`CardActivationModal-${modalType}`}
      header={currentModalHeader}
      visible={modalVisible}
      onSetVisible={handleSubmitClose}
      isSuccessIcon={modalType === CardActivationModalType.Processing}
      isPendingIcon={modalType === CardActivationModalType.Activation}
      paddingless
      buttonText={modalType === CardActivationModalType.Processing ? undefined : 'Cancel'}
      submitButtonNode={(
        <AppButton
          testId="CardActivationModal-submit-button"
          onClick={currentSubmitButtonHandler}
          width={SUBMIT_BUTTON_WIDTH}
        >
          {currentSubmitButtonText}
        </AppButton>
      )}
      onSubmit={currentSubmitButtonHandler}
    />
  );
};
