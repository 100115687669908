import React, { useEffect, useMemo } from 'react';
import { Box, FlexControlledForm, useDebounceState, WarningIcon,WarnModal } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';
import { useHasSameSSNStore } from '@/modules/employee/Employee/stores';

import { usePayrollGroupCodeField } from './hooks/usePayrollGroupCodeField';
import useSubsidiariesQuery from './queries/useSubsidiaries.query';
import { EmployerInfoPayload,useStore } from './stores/useEmployerInfo.store';
import { useEmployeeSSN, useEmployerInfoFields } from './hooks';
import { useEmployerInfoStore } from './stores';

const ACTIVE_STATUS = 'A';
const TERMINATED_STATUS = 'T';

interface Props {
  isEditMode?: boolean;
  isShowErrors?: boolean;
}
const EmployerInfo: React.FC<Props> = ({ isEditMode, isShowErrors }) => {
  const hasAccessToEditPrimaryId = useHasAccess([{ permission: Permissions.EDIT_EE_PRIMARY_ID }]);
  const { hasSameSSN, resetHasSameSSN } = useHasSameSSNStore();
  const { defaultOrganizationData: { type } } = useCurrentOrganization();
  const defaultFields = useEmployerInfoStore((state) => state.sourceState);
  const {ssnField, key: ssnKey} = useEmployeeSSN({
    defaultSSN: defaultFields?.ssn,
    isDisabled: !isEditMode || !hasAccessToEditPrimaryId,
  });
  const { payrollGroupField } = usePayrollGroupCodeField({
    isDisabled: !isEditMode || !hasAccessToEditPrimaryId,
  });
  
  const isSubsidiary = useMemo(() => type === OrganizationTypes.subsidiary, [type]);
  const { formattedData: subsidiaries } = useSubsidiariesQuery();
  const handleSetState = useStore((state) => state.setState);
  const employerStatus = useStore((state) => state.state.employerStatus);
  const fields = useEmployerInfoFields({
    isEditMode,
    subsidiaries,
    defaultFields,
    isSubsidiary,
    ssnField,
    hasAccessToEditPrimaryId,
    payrollGroupField,
  });
  useEffect(() => {
    if (hasSameSSN && employerStatus === ACTIVE_STATUS) {
      handleSetState({ employerStatus: TERMINATED_STATUS });
    }
  }, [employerStatus, hasSameSSN, handleSetState]);

  const { setValues, values } = useDebounceState<Partial<EmployerInfoPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <Box background="canvas" key={ssnKey} round="container1Round">
      <FlexControlledForm
        key={hasSameSSN ? 'hasSameSSN' : 'noHasSameSSN'}
        formTitle="Employment information"
        fields={fields}
        showError={isShowErrors}
        editMode
        onChangeValues={setValues}
        isDisabledMode={!isEditMode}
        isThinMode
      />
      <WarnModal
        visible={hasSameSSN || false}
        testId="employee-with-same-ssn-modal"
        header="Warning"
        helptext="An employee with the same SSN already exists in this organization in Active status, you cannot have two active accounts with the same SSN at the same time."
        onSetVisible={resetHasSameSSN}
        buttonText="Close"
        iconNode={<WarningIcon size="48px" />}
      />
    </Box>
  );
};
export default EmployerInfo;
