import React, { useMemo } from 'react';
// import * as yup from 'yup';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import { RadioOption } from '@/common/types';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

export const KEYS = {
  ELECTRONIC_FUNDING_REPLENISHMENT: 'electronicFundingReplenishment',
  CHECK_WRITING: 'checkWriting',
};

export const LABELS = {
  ELECTRONIC_FUNDING_REPLENISHMENT: 'Electronic funding replenishment',
  CHECK_WRITING: 'Check writing',
};
export const useFundingModelFields = (
  checkWritings?: RadioOption[],
  electronicFundingReplenishments?: RadioOption[],
  data?: EmployerDto,
) => {
  const checkFundingModelPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CHECK_FUNDING_MODEL,
  );
  const electronicFundingModeIdPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.ELECTRONIC_FUNDING_MODE,
  );

  const fundingModel = useStore((state) => state.fundingModel);
  const list: EmployerDto | undefined = data;
  const result = useMemo(() => [
    {
      name: 'electronicFundingReplenishment',
      type: FieldTypes.Radio,
      testId: `${KEYS.ELECTRONIC_FUNDING_REPLENISHMENT}-id`,
      label: (
        <Box flex={{ grow: 1 }}>
          <Inscription margin={{ bottom: '2px' }} weight="bold" color="textBody">
            {LABELS.ELECTRONIC_FUNDING_REPLENISHMENT}
          </Inscription>
          <Box>
            <Inscription weight="normal" color="textSecondary" size="small">
              This determines who is responsible for paying for claim spend
            </Inscription>
          </Box>
        </Box>
      ),
      // validator: yup.string().required(REQUIRED_TEXT).trim(),
      showRequireIcon: true,
      options: electronicFundingReplenishments ? [
        {
          value: '',
          label: 'None',
        },
        ...electronicFundingReplenishments,
      ] : [],
      defaultValue: list?.overridableProperties?.electronicFundingModeId
      || fundingModel.electronicFundingReplenishment || '',
      value: list?.overridableProperties?.electronicFundingModeId
      || fundingModel.electronicFundingReplenishment || '',
      ...electronicFundingModeIdPermProps,
    },
    {
      name: 'checkWriting',
      type: FieldTypes.Radio,
      testId: `${KEYS.CHECK_WRITING}-id`,
      label: (
        <Box flex={{ grow: 1 }}>
          <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
            {LABELS.CHECK_WRITING}
          </Inscription>
          <Box>
            <Inscription weight="normal" color="textSecondary" size="small">
              This determines which account checks are to be paid from.
              Nonstandard requires collaboration with Elevate to establish
            </Inscription>
          </Box>
        </Box>
      ),
      // validator: yup.string().trim().required(REQUIRED_TEXT),
      showRequireIcon: true,
      options: checkWritings ? [
        {
          value: '',
          label: 'None',
        },
        ...checkWritings,
      ] : [],
      defaultValue: list?.overridableProperties?.checkFundingModel
      || fundingModel.checkWriting || '',
      value: list?.overridableProperties?.checkFundingModel
      || fundingModel.checkWriting || '',
      ...checkFundingModelPermProps,
    },
  ], [fundingModel.checkWriting,
    fundingModel.electronicFundingReplenishment,
    checkWritings,
    electronicFundingReplenishments,
    list,
    checkFundingModelPermProps,
    electronicFundingModeIdPermProps,
  ]);
  return result as Field[];
};
