import React, { useCallback, useMemo } from 'react';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';

import AddModalWrapper from '../../AddModalWrapper';

import { AddPayrollGroupForm } from './AddPayrollGroupForm';
import { usePayrollGroupModalStore } from './stores';

interface Props {
  disabled?: boolean;
}

export const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const PayrollGroupFormModal: React.FC<Props> = ({ disabled }) => {
  const modalVisible = usePayrollGroupModalStore((state) => state.modalVisible);
  const handleChangeVisibility = usePayrollGroupModalStore((state) => state.handleChangeVisibility);
  const handleSetEditablePayrollGroup = usePayrollGroupModalStore((state) => state.handleSetEditablePayrollGroup);
  const editablePayrollGroup = usePayrollGroupModalStore((state) => state.editablePayrollGroup);

  const currentModalTitle = useMemo(() => {
    if (editablePayrollGroup) {
      return 'Modify Payroll Group';
    }
    return 'Add Payroll Group';
  }, [editablePayrollGroup]);

  const handleModalVisibility = useCallback((value: boolean) => {
    if (!value) {
      if (handleSetEditablePayrollGroup) handleSetEditablePayrollGroup(undefined);
      handleChangeVisibility(value);
      return;
    }
    return handleChangeVisibility(value);

  }, [handleChangeVisibility, handleSetEditablePayrollGroup]);

  const isPayrollGroupSetupAccessible = useHasAccess([
    {
      permission: Permissions.VIEW_ER,
    },
    {
      permission: Permissions.EDIT_ER_DATA,
    },
  ]);

  return (
    <AddModalWrapper
      title="Payroll Group"
      visible={modalVisible}
      disabled={disabled}
      onSetVisible={(value) => handleModalVisibility(value)}
      buttonText="Add Payroll Group"
      modalTitle={currentModalTitle}
      successText="Payroll Group added."
      isButtonHidden={!isPayrollGroupSetupAccessible}
    >
      <AddPayrollGroupForm onCancel={() => handleModalVisibility(false)}/>
    </AddModalWrapper>
  );
};

export default PayrollGroupFormModal;
