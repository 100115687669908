import React from 'react';
import {
  AppButton,   Box, CheckIcon,
  Image, Inscription, } from '@common-fe/common-fe';

import { useEmailIllustrationsStore,useEmailThemeStore } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/stores';

interface CheckListProps {
  items: string[];
  color?: string;
}

const CheckList: React.FC<CheckListProps> = ({ items, color }) => (
  <Box margin={{ top: 'spacing24' }}>
    {
      items.map((item, index) => (
        <Box
          direction="row"
          align="center"
          key={item}
          {...index ? { margin: { top: 'spacing8' } } : {}}
        >
          <CheckIcon color="success" />
          <Inscription size="small" weight={500} margin={{ left: 'spacing4' }} color={color}>{item}</Inscription>
        </Box>
      ))
    }
  </Box>
);

const EmailTemplateContent = () => {
  const emailTheme = useEmailThemeStore((state) => state.state);
  const { brandingLogoLink } = useEmailIllustrationsStore((state) => state.state);

  return (
    <Box margin={{ top: 'spacing48' }} data-testId="email-template-content">
      <Box pad={{ horizontal: 'spacing24' }}>
        <Inscription
          size="2xl"
          weight="bold"
          lineHeight="32px"
          color={emailTheme.subject}
        >
          Congratulations on your benefits enrollment sponsored by Stepan!
        </Inscription>

        <Inscription
          margin={{ top: 'spacing24' }}
          size="large"
          lineHeight="22px"
          color={emailTheme.text}
        >
          Take full advantage of your benefits with [Partner Name] powered by Elevate!
          Here you can access your [account names].
        </Inscription>

        <Box
          round="container2Round"
          background={emailTheme.codeBackground}
          margin={{ top: 'spacing24' }}
          pad={{ horizontal: '40px', vertical: 'spacing18' }}
          align="center"
        >
          <Inscription weight="bold" size="20px" color={emailTheme.codeText}>
            Tzm35KZr
          </Inscription>
        </Box>

        <Inscription
          margin={{ top: 'spacing24' }}
          size="large"
          lineHeight="22px"
          color={emailTheme.text}
        >
          Are you ready to get started, [first name]?
        </Inscription>
        <Inscription
          size="large"
          lineHeight="22px"
          color={emailTheme.text}
        >
          Register your account online at&nbsp;
          <Inscription
            size="large"
            lineHeight="22px"
            color={emailTheme.activeLink}
          >
            user.elevateaccounts.com.
          </Inscription>
        </Inscription>
        <Inscription
          size="large"
          lineHeight="22px"
          color={emailTheme.text}
        >
          All you need is your [employee ID source] and your date of birth.
        </Inscription>

        <Box margin={{ top: 'spacing24' }}>
          <AppButton
            width="100%"
            size="L"
            style={{ backgroundColor: emailTheme.buttonBackground }}
          >
            <Inscription color={emailTheme.buttonText}>Register</Inscription>
          </AppButton>
        </Box>

        <Inscription
          margin={{ top: 'spacing24' }}
          size="small"
          weight={500}
          color={emailTheme.text}
        >
          Registering will give you access to all the tools needed to manage your account.
        </Inscription>
        <Inscription
          size="small"
          weight={500}
          color={emailTheme.text}
        >
          You’ll be able to:
        </Inscription>

        <CheckList
          color={emailTheme.text}
          items={[
            'View of your account balance and manage expenses',
            'Request payments to be sent to your provider or reimburse yourself',
            'Activate and manage your payment card',
            'Access important notifications regarding your account',
            'Setup payment options for same day reimbursements',
          ]}
        />

        <Inscription
          margin={{ top: 'spacing24' }}
          size="small"
          weight={500}
          lineHeight="18px"
          color={emailTheme.text}
        >
          To make access to your account quicker and easier,
          we have mailed you a [partner name] card!
          Keep an eye out in the mail if you have not received it already.
        </Inscription>
        <Inscription
          size="small"
          weight={500}
          lineHeight="18px"
          color={emailTheme.text}
        >
          You can also view details about when the card
          is mailed in the participant portal.
        </Inscription>

        <Inscription
          margin={{ top: 'spacing24' }}
          size="small"
          weight={500}
          color="textSecondary"
        >
          The [partner name] Team
        </Inscription>
        <Inscription
          size="small"
          weight={500}
          color="textSecondary"
        >
          Powered by Elevate
        </Inscription>
      </Box>

      <Box margin={{ top: 'spacing48' }}>
        <Image
          src={brandingLogoLink}
          alt="preview"
          data-testid="branding-preview_image"
        />
      </Box>
    </Box>
  );
};

export default EmailTemplateContent;
