import React, { useEffect, useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  Field,
  FieldTypes,
  Inscription,
  OptionKey,
  Preloader,
  SelectDropdown,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { ALL_OPTION, DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useSubsidiariesQuery } from '@/modules/employee/Employee/PersonalInformation/EmployerInfo/queries';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';

import { useGetPayrollGroupsQuery } from '../../SetupEmployer/Features/PayrollGroups/queries/useGetPayrollGroups.query';
import { ContributionGroups, ManualContributionFormFields, ManualContributionFormValues, NewContributionType, TaxType, taxTypeOptions } from '../contributions.types';
import { useGetTaxYearFromIrsMaximumsQuery } from '../queries/useGetTaxYearFromIrsMaximums.query';
import { useAllValuesForManualContributionsStore } from '../store';
import { PlanCode } from '../store/useAllValuesForManualContributions.store';

import { useEmployeeSearch } from './useEmployeeSearch';

const NOT_AVAILABLE_SUB_VALUES = [{
  value: 'No available',
  style: {
    color: 'textDisabled',
    fontSize: '12px',
  }
}];

const ACTIVE_PLAN_STATUS = 'ACTIVE';

const currentYear = dayjs().year();
const currentToday = dayjs().toDate();

export const groupOptions = [
  {
    key: NewContributionType.SINGLE,
    value: NewContributionType.SINGLE,
    title: capitalizeFirstLetter(NewContributionType.SINGLE),
  },
  {
    key: NewContributionType.GROUP,
    value: NewContributionType.GROUP,
    title: capitalizeFirstLetter(NewContributionType.GROUP),
  },
];



const groupTypeOptions = (hasSubsidiaries: boolean, hasPayrolls: boolean) => [
  {
    key: ContributionGroups.ALL_EMPLOYEES,
    value: ContributionGroups.ALL_EMPLOYEES,
    title: capitalizeFirstLetter(ContributionGroups.ALL_EMPLOYEES).replace(regexp.DASH_SYMBOL, ' '),
  },
  {
    key: ContributionGroups.PLANS,
    value: ContributionGroups.PLANS,
    title: capitalizeFirstLetter(ContributionGroups.PLANS).replace(regexp.DASH_SYMBOL, ' '),
  },
  {
    key: ContributionGroups.SUBSIDIARY,
    value: ContributionGroups.SUBSIDIARY,
    title: capitalizeFirstLetter(ContributionGroups.SUBSIDIARY).replace(regexp.DASH_SYMBOL, ' '),
    ...hasSubsidiaries ? {} : {
      disabled: true,
      subValues: NOT_AVAILABLE_SUB_VALUES,
    },
  },
  {
    key: ContributionGroups.PAYROLL_GROUP,
    value: ContributionGroups.PAYROLL_GROUP,
    title: (
      <Inscription
        style={{ whiteSpace: 'nowrap' }}
      >
        {capitalizeFirstLetter(ContributionGroups.PAYROLL_GROUP).replace(regexp.DASH_SYMBOL, ' ')}
      </Inscription>
    ),
    ...hasPayrolls ? {} : {
      disabled: true,
      subValues: NOT_AVAILABLE_SUB_VALUES,
    },
  },
];

interface Props {
  formValues: ManualContributionFormValues;
  setOptionValues?: (fieldName: string, values: OptionKey[]) => void;
  plans?: OptionKey[];
  payrollGroups?: OptionKey[];
  subsidiaries?: OptionKey[];
  isSubmit?: boolean;
  contributionId?: string;
}

export const useManualContributionFields = (props: Props) => {
  const {
    formValues,
    setOptionValues,
    plans,
    payrollGroups,
    subsidiaries,
    isSubmit,
    contributionId,
  } = props;
  const {
    observingOrganization: { id: orgId },
  } = useCurrentOrganization();
  const currentType = useMemo(() => formValues?.contributionType, [formValues]);
  const currentGroupType = useMemo(
    () => currentType === NewContributionType.GROUP
      ? formValues?.groupType
      : undefined,
    [currentType, formValues?.groupType]);
  const { formattedData: activeOrgPlans, isLoading: isPlansLoading } = useGetOrganizationPlansQuery(
    currentGroupType === ContributionGroups.PLANS ? orgId : undefined,
    ACTIVE_PLAN_STATUS
  );
  
  const { formattedData: organizationPayrollGroups, isLoading: arePayrollGroupsLoading } = useGetPayrollGroupsQuery(
    currentType === NewContributionType.GROUP ? orgId : undefined,
  );

  const { formattedData: subsidiaryList, isLoading: isSubsidiaryLoading } = useSubsidiariesQuery(true);

  const { lastDayForContribution } = useGetTaxYearFromIrsMaximumsQuery();

  const {
    employees,
    isLoading: isEmployeesLoading,
    handleSetSearch: searchEmployees,
  } = useEmployeeSearch(true);

  const payrollGroupsOptions = useMemo(() => {
    const options = organizationPayrollGroups.map((item) => ({
      key: item?.code,
      value: item?.code,
      title: item?.name,
    }));
  
    return [ALL_OPTION, ...options];
  }, [organizationPayrollGroups]);

  const accountsOptions = useMemo(() => {
    const options = activeOrgPlans?.map((item) => ({
      key: item?.planCode,
      value: item?.planCode,
      title: item?.planCode,
    }));

    return [ALL_OPTION, ...options];
  }, [activeOrgPlans]);

  const subsidiariesOptions = useMemo(() => {
    if (!subsidiaryList) return [];
    const options = subsidiaryList?.map((item) => ({
      key: item?.id,
      value: item?.id,
      title: item?.name,
    }));

    return [ALL_OPTION, ...options];
  }, [subsidiaryList]);

  const isPrevYearAvailable = useMemo(() => {
    const lastDay = dayjs(lastDayForContribution).format(DEFAULT_DATE_FORMAT);
    const today = dayjs(currentToday).format(DEFAULT_DATE_FORMAT);
    if (!lastDayForContribution) return false;
    return today <= lastDay;
  }, [lastDayForContribution]);

  const taxYearOptions = useMemo(() => ([
    {
      key: `${currentYear}`,
      value: `${currentYear}`,
      title: `${currentYear}`,
    },
    ...isPrevYearAvailable ? [{
      key: `${currentYear - 1}`,
      value: `${currentYear - 1}`,
      title: `${currentYear - 1}`,
    }] : [],
  ]), [isPrevYearAvailable]);

  const storedPlanCodes = useAllValuesForManualContributionsStore((state) => state.planCodes);
  const storedPayrolls = useAllValuesForManualContributionsStore((state) => state.payrolls);
  const storedSubsidiaries = useAllValuesForManualContributionsStore((state) => state.subsidiaries);
  const handleSetEmployees = useAllValuesForManualContributionsStore((state) => state.handleSetEmployees);
  const handleSetPlans = useAllValuesForManualContributionsStore((state) => state.handleSetPlans);
  const handleSetPayrolls = useAllValuesForManualContributionsStore((state) => state.handleSetPayrolls);
  const handleSetSubsidiaries = useAllValuesForManualContributionsStore((state) => state.handleSetSubsidiaries);

  useEffect(() => {
    if (currentType === NewContributionType.GROUP && searchEmployees) {
      searchEmployees('');
    }
  }, [currentType, searchEmployees]);

  useEffect(() => {
    if (activeOrgPlans) {
      handleSetPlans(activeOrgPlans.map((item) => ({ id: item.id, planCode: item.planCode })) as PlanCode[]);
    }

    if (organizationPayrollGroups) {
      handleSetPayrolls(organizationPayrollGroups.map((item) => item.code) as string[]);
    }

    if (subsidiaryList && !contributionId) {
      handleSetSubsidiaries(subsidiaryList.map((item) => item.id) as string[]);
    }

    if (employees) {
      handleSetEmployees(employees.map((item) => item.key) as string[]);
    }
  }, [
    contributionId,
    organizationPayrollGroups,
    activeOrgPlans,
    subsidiaryList,
    employees,
    handleSetPayrolls,
    handleSetPlans,
    handleSetSubsidiaries,
    handleSetEmployees,
  ]);

  const fields = useMemo(() => [
    {
      name: ManualContributionFormFields.CONTRIBUTION_TYPE,
      type: FieldTypes.Dropdown,
      showRequireIcon: true,
      singleMode: true,
      label: 'Contribution type',
      placeholder: 'Select Contribution type',
      options: groupOptions,
      validator: yup.string().required(REQUIRED_TEXT),
      ...contributionId ? {
        disabled: true,
        defaultValue: formValues?.[ManualContributionFormFields.CONTRIBUTION_TYPE],
      } : {},
    },
    ...currentType === NewContributionType.SINGLE ? [
      {
        options: employees,
        name: ManualContributionFormFields.EMPLOYEE,
        type: FieldTypes.AutocompleteDropdown,
        label: 'Employee',
        placeholder: 'Insert name',
        showRequireIcon: true,
        validator: yup.string().required(REQUIRED_TEXT),
        onSearch: searchEmployees,
        isLoading: isEmployeesLoading,
        ...contributionId ? {
          defaultValue: formValues[ManualContributionFormFields.EMPLOYEE],
          disabled: true,
        } : {},
      }
    ] : [],
    ...currentType === NewContributionType.GROUP ? [
      {
        name: ManualContributionFormFields.GROUP_TYPE,
        type: FieldTypes.Dropdown,
        showRequireIcon: true,
        label: 'Group type',
        singleMode: true,
        placeholder: 'Select Group type',
        options: groupTypeOptions(Boolean(subsidiaryList?.length), Boolean(organizationPayrollGroups?.length)),
        validator: yup.string().required(REQUIRED_TEXT),
        ...contributionId ? {
          disabled: true,
          defaultValue: formValues[ManualContributionFormFields.GROUP_TYPE],
        } : {},
      },
    ] : [],
    ...currentGroupType === ContributionGroups.PLANS ? [
      {
        name: ManualContributionFormFields.PLANS,
        type: FieldTypes.Node,
        label:   (
          <Box margin={{ top: 'spacing8' }} direction="row">
            <Inscription
              color="danger"
              margin={{
                right: '3px',
                top: '1px',
              }}
            >
              *
            </Inscription>
            <Inscription>Plans</Inscription>
          </Box>
        ),
        placeholder: 'Select Plans',
        value: (
          <Box width="100%">
            {isPlansLoading ? (
              <Box height={{ max: '40px', min: '40px' }} justify="center">
                <Preloader size={24} />
              </Box>
            ) : (
              <SelectDropdown
                id="plans-dropdown"
                testId="plans-dropdown"
                ellipsisMode
                name="Plans"
                options={accountsOptions}
                onChangeValues={(values) => setOptionValues && setOptionValues(ManualContributionFormFields.PLANS, values)}
                placeholder='Select Plans'
                {...contributionId ? {
                  disabled: true,
                  values: storedPlanCodes,
                } : {
                  values: plans,
                  errorText: (!plans?.length && isSubmit) ? REQUIRED_TEXT : undefined,
                }}
              />
            )}
          </Box>
        ),
      },
    ] : [],
    ...currentGroupType === ContributionGroups.SUBSIDIARY ? [
      {
        name: ManualContributionFormFields.SUBSIDIARY,
        type: FieldTypes.Node,
        label:   (
          <Box margin={{ top: 'spacing8' }} direction="row">
            <Inscription
              color="danger"
              margin={{
                right: '3px',
                top: '1px',
              }}
            >
              *
            </Inscription>
            <Inscription>Subsidiary</Inscription>
          </Box>
        ),
        placeholder: 'Select Subsidiaries',
        value: (
          <Box width="100%">
            {isSubsidiaryLoading ? (
              <Box height={{ max: '40px', min: '40px' }} justify="center">
                <Preloader size={24} />
              </Box>
            ) : (
              <SelectDropdown
                id="subsidiary-dropdown"
                testId="subsidiary-dropdown"
                ellipsisMode
                name="Subsidiary"
                options={subsidiariesOptions}
                onChangeValues={(values) => setOptionValues && setOptionValues(ManualContributionFormFields.SUBSIDIARY, values)}
                placeholder='Select subsidiary'
                {...contributionId ? {
                  values: storedSubsidiaries,
                  disabled: true,
                } : {
                  values: subsidiaries,
                  errorText: (!subsidiaries?.length && isSubmit) ? REQUIRED_TEXT : undefined,
                }}
              />
            )}
          </Box>
        ),
      },
    ] : [],
    ...currentGroupType === ContributionGroups.PAYROLL_GROUP ? [
      {
        name: ManualContributionFormFields.PAYROLL_GROUP,
        type: FieldTypes.Node,
        label:   (
          <Box margin={{ top: 'spacing8' }} direction="row">
            <Inscription
              color="danger"
              margin={{
                right: '3px',
                top: '1px',
              }}
            >
              *
            </Inscription>
            <Inscription>Payroll group</Inscription>
          </Box>
        ),
        placeholder: 'Select Payroll group',
        value: (
          <Box width="100%">
            {arePayrollGroupsLoading ? (
              <Box height={{ max: '40px', min: '40px' }} justify="center">
                <Preloader size={24} />
              </Box>
            ) : (
              <SelectDropdown
                id="payroll-dropdown"
                testId="payroll-dropdown"
                ellipsisMode
                name="payrollGroup"
                options={payrollGroupsOptions}
                onChangeValues={(values) => setOptionValues && setOptionValues(ManualContributionFormFields.PAYROLL_GROUP, values)}
                placeholder='Select Payroll group'
                {...contributionId ? {
                  values: storedPayrolls,
                  disabled: true,
                } : {
                  values: payrollGroups,
                  errorText: (!payrollGroups?.length && isSubmit) ? REQUIRED_TEXT : undefined,
                }}
              />
            )}
          </Box>
        ),
      },
    ] : [],
    {
      name: ManualContributionFormFields.CONTRIBUTION_DATE,
      type: FieldTypes.Date,
      showRequireIcon: true,
      label: 'Contribution date',
      placeholder: 'Select Contribution date',
      validator: yup.string().required(REQUIRED_TEXT),
      ...contributionId ? {
        defaultValue: formValues[ManualContributionFormFields.CONTRIBUTION_DATE],
      } : {},
    },
    ...contributionId ? [] : [
      {
        name: ManualContributionFormFields.TAX_YEAR,
        type: FieldTypes.Dropdown,
        showRequireIcon: true,
        label: 'Tax year',
        placeholder: 'Select Tax year',
        validator: yup.string().required(REQUIRED_TEXT),
        singleMode: true,
        options: taxYearOptions,
      },
      {
        name: ManualContributionFormFields.TAX_TYPE,
        type: FieldTypes.Dropdown,
        showRequireIcon: true,
        label: 'Tax type',
        placeholder: 'Select Tax type',
        validator: yup.string().required(REQUIRED_TEXT),
        singleMode: true,
        options: taxTypeOptions,
      },
      {
        name: ManualContributionFormFields.MEMO,
        type: FieldTypes.Text,
        label: (
          <Box width={{ max: '245px' }}>
            <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody" lineHeight="20px">
              Memo
            </Inscription>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small">
                This field will be used as a name of your transaction later.
              </Inscription>
            </Box>
          </Box>
        ),
      },
    ],
  ] as Field[], [
    arePayrollGroupsLoading,
    payrollGroups,
    storedPayrolls,
    payrollGroupsOptions,
    taxYearOptions,
    currentType,
    currentGroupType,
    accountsOptions,
    isPlansLoading,
    setOptionValues,
    isEmployeesLoading,
    searchEmployees,
    employees,
    plans,
    subsidiaries,
    isSubsidiaryLoading,
    subsidiariesOptions,
    isSubmit,
    subsidiaryList,
    contributionId,
    formValues,
    storedPlanCodes,
    storedSubsidiaries,
    organizationPayrollGroups,
  ]);

  return fields;
};
