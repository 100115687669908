import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/hooks';

const FIELDS = {
  MAILING_ADDRESS: 'mailingAddress',
  MAILING_STREET: 'mailingLine1',
  MAILING_STREET_2: 'mailingLine2',
  MAILING_CITY: 'mailingCity',
  MAILING_STATE: 'mailingState',
  MAILING_ZIP_CODE: 'mailingZipCode',
  MAILING_COUNTRY_CODE: 'mailingCountryCode',
};

const MAX_FIELD_LENGTH = 40;

export const useEmployeeSetupModalMailingAddressFields = (isMailingAddress?: boolean) => {
  const { states } = useStatesQuery();

  const fields = useMemo(() => ([
    {
      name: FIELDS.MAILING_ADDRESS,
      type: FieldTypes.Checkbox,
      label: 'Same as the Home Address',
      validator: yup.boolean().required(REQUIRED_TEXT),
      defaultValue: isMailingAddress,
    },
    ...isMailingAddress ? []
      : [
        {
          name: FIELDS.MAILING_STREET,
          type: FieldTypes.Text,
          label: 'Address line 1',
          placeholder: 'Enter Address line 1',
          showRequireIcon: true,
          validator: yup
            .string()
            .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
            .required(REQUIRED_TEXT),
          defaultValue: '',
        },
        {
          name: FIELDS.MAILING_STREET_2,
          type: FieldTypes.Text,
          label: 'Address line 2',
          placeholder: 'Enter Address line 2',
          validator: yup
            .string()
            .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
          defaultValue: '',
        },
        {
          name: FIELDS.MAILING_CITY,
          type: FieldTypes.Text,
          label: 'City',
          showRequireIcon: true,
          placeholder: 'Enter City',
          validator: yup.string().required(REQUIRED_TEXT),
          defaultValue: '',
        },
        {
          name: FIELDS.MAILING_STATE,
          type: FieldTypes.AutocompleteDropdown,
          label: 'State',

          showRequireIcon: true,
          placeholder: 'Select state',
          validator: yup.string().required(REQUIRED_TEXT),
          options: states.map((state) => ({ key: state.code, title: state.name })),
          defaultValue: '',
        },
        {
          name: FIELDS.MAILING_ZIP_CODE,
          type: FieldTypes.ZipCode,
          label: 'ZIP code',
          placeholder: 'Enter ZIP code',
          showRequireIcon: true,
          visible: false,
          validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
            (val) => (val && (val.length === 10 || val.length === 5))
                || (val === '')).required(REQUIRED_TEXT),
          defaultValue: '',
        },
        {
          name: FIELDS.MAILING_COUNTRY_CODE,
          type: FieldTypes.Text,
          label: 'Country code',
          disabled: true,
          showRequireIcon: true,
          placeholder: 'Enter Country code',
          defaultValue: 'USA',
        },
      ],

  ]), [isMailingAddress, states]);

  return fields as Field[];
};
