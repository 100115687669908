import React, { useMemo } from 'react';
import { Box, Field, FieldTypes, Inscription } from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import {
  INTEGER_VALUE_TEXT,
  INVALID_RANGE_TEXT,
  PermissionKey,
  REQUIRED_TEXT,
} from '@/common/constants';
import {
  AllowedPaymentMethods,
  OverridablePropertiesDto,
  WaitingForFundsContinuePayment,
} from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

export const WAITING_FOR_FUNDS_EXPIRATION_RANGE = [1, 999999];
const WAITING_FOR_FUNDS_EXPIRATION_VALIDATE = VALIDATORS.REQUIRED_STRING.test({
  test(val) {
    if (val && (+val < WAITING_FOR_FUNDS_EXPIRATION_RANGE[0] || +val > WAITING_FOR_FUNDS_EXPIRATION_RANGE[1])) return false;
    return true;
  },
  message: `Should be in the range of ${WAITING_FOR_FUNDS_EXPIRATION_RANGE[0]} to ${WAITING_FOR_FUNDS_EXPIRATION_RANGE[1]}`,
});

const currencyValueValidate = yup
  .string()
  .test({
    test: (val) => {
      if (val) {
        const value = Number(val);
        return value >= 1 && value <= 999999;
      }
      return true;
    },
    message: INVALID_RANGE_TEXT(1, 999999),
  })
  .test({
    test: (val) => {
      if (val) {
        const value = Number(val);
        return Number.isInteger(value);
      }
      return true;
    },
    message: INTEGER_VALUE_TEXT,
  });

export enum BoolOptin {
  yes = 'yes',
  no = 'no',
}

const getBoolOption = (value?: boolean) => {
  if (value === true) return BoolOptin.yes;
  if (value === false) return BoolOptin.no;

  return undefined;
};

const useGetReimbursementFields = (
  areAllowedPaymentMethodsShown?: boolean,
  allowedPaymentMethods?: AllowedPaymentMethods[],
  overridableProperties?: OverridablePropertiesDto,
  isLoading?: boolean,
  disabled?: boolean,
) => {
  const minimumPaymentReimbursementAmountPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.MINIMUM_PAYMENT_REIMBURSEMENT_AMOUNT,
  );
  const waitingForFundsContinuePaymentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.WAITING_FOR_FUNDS_CONTINUE_PAYMENT,
  );
  const isReimbursementAllowedPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_REIMBURSEMENT_ALLOWED,
  );
  const isBillPayAllowedPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_BILL_PAY_ALLOWED,
  );
  const isClaimOffsetAllowedPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_CLAIM_OFFSETS_ALLOWED,
  );
  const hasPaymentMethods = useMemo(() => {
    if (isLoading) return false;

    return typeof areAllowedPaymentMethodsShown === 'boolean'
      ? areAllowedPaymentMethodsShown
      : overridableProperties?.isReimbursementAllowed;
  },
  [areAllowedPaymentMethodsShown, overridableProperties, isLoading]);
  const fields = useMemo(
    () => {
      if (isLoading) return [];

      return [
        {
          name: 'allowReimbursement',
          label: 'Allow Reimbursement',
          type: FieldTypes.Radio,
          showRequireIcon: true,
          disabled,
          validator: yup.string().required(REQUIRED_TEXT),
          value: getBoolOption(overridableProperties?.isReimbursementAllowed),
          options: [{
            label: 'Yes',
            value: BoolOptin.yes,
          }, {
            label: 'No',
            value: BoolOptin.no,
          }],
          ...isReimbursementAllowedPermProps,
        },
        ...hasPaymentMethods ? [
          {
            name: 'allowedPaymentMethods',
            label: (
              <Box flex={{ grow: 1 }}>
                <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                  Allowed payment methods
                </Inscription>
                <Box>
                  <Inscription weight="normal" color="textSecondary" size="12px" lineHeight="18px">
                    At least one option is required
                  </Inscription>
                </Box>
              </Box>
            ),
            type: FieldTypes.CheckBoxGroup,
            disabled,
            showRequireIcon: true,
            ...(!allowedPaymentMethods
              && !overridableProperties?.allowedPaymentMethods?.length)
              || allowedPaymentMethods ? {
                validator: yup.array().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
              } : {},
            value: overridableProperties?.allowedPaymentMethods,
            options: [{
              value: 'Direct Deposit',
              key: AllowedPaymentMethods.DIRECT_DEPOSIT,
            }, {
              value: 'My Personal Debit Card',
              key: AllowedPaymentMethods.DEBIT,
            }, {
              value: 'PayPal',
              key: AllowedPaymentMethods.PAYPAL,
            }, {
              value: 'Venmo',
              key: AllowedPaymentMethods.VENMO,
            }, {
              value: 'Check',
              key: AllowedPaymentMethods.CHECK,
            }],
            ...isReimbursementAllowedPermProps,
          },
        ] : [],
        {
          name: 'allowBillPay',
          label: 'Allow Bill Pay',
          type: FieldTypes.Radio,
          disabled,
          showRequireIcon: true,
          validator: yup.string().required(REQUIRED_TEXT),
          value: getBoolOption(overridableProperties?.isBillPayAllowed),
          options: [{
            label: 'Yes',
            value: BoolOptin.yes,
          }, {
            label: 'No',
            value: BoolOptin.no,
          }],
          ...isBillPayAllowedPermProps,
        },
        {
          name: 'allowClaimOffsets',
          label: 'Allow Claim Offsets',
          type: FieldTypes.Radio,
          disabled,
          showRequireIcon: true,
          validator: yup.string().required(REQUIRED_TEXT),
          value: getBoolOption(overridableProperties?.isClaimOffsetsAllowed),
          options: [{
            label: 'Yes',
            value: BoolOptin.yes,
          }, {
            label: 'No',
            value: BoolOptin.no,
          }],
          ...isClaimOffsetAllowedPermProps,
        },
        {
          name: 'minimumPaymentReimbursementAmount',
          type: FieldTypes.Currency,
          label: 'Minimum Payment/Reimbursement amount',
          showRequireIcon: true,
          placeholder: 'Enter $ amount',
          value: overridableProperties?.minimumPaymentReimbursementAmount,
          defaultValue: overridableProperties?.minimumPaymentReimbursementAmount,
          disabled,
          validator: currencyValueValidate,
          ...minimumPaymentReimbursementAmountPermProps,
        },
        {
          name: 'waitingForFundsContinuePayment',
          label: 'Waiting for Funds - Continue Payment',
          type: FieldTypes.Radio,
          disabled,
          value: overridableProperties?.waitingForFundsContinuePayment,
          options: [{
            label: 'Employee choice',
            value: WaitingForFundsContinuePayment.EMPLOYEE_CHOICE,
          }, {
            label: 'Autopay',
            value: WaitingForFundsContinuePayment.AUTOPAY,
          }],
          ...waitingForFundsContinuePaymentPermProps,
        },
        {
          name: 'waitingForFundsExpiration',
          label: (
            <Box flex={{ grow: 1 }}>
              <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody" lineHeight="20px">
                Days to process claims waiting for funds
              </Inscription>
              <Box>
                <Inscription weight={500} color="textSecondary" size="12px" lineHeight="18px">
                  The number of days the system will continue to look for funds that can pay claims in the 'waiting for funds' status
                </Inscription>
              </Box>
            </Box>
          ),
          showRequireIcon: true,
          validator: WAITING_FOR_FUNDS_EXPIRATION_VALIDATE,
          type: FieldTypes.Number,
          disabled,
          value: overridableProperties?.waitingForFundsExpiration,
        },
      ];
    },
    [
      overridableProperties,
      hasPaymentMethods,
      allowedPaymentMethods,
      isLoading,
      disabled,
      waitingForFundsContinuePaymentPermProps,
      isReimbursementAllowedPermProps,
      isBillPayAllowedPermProps,
      minimumPaymentReimbursementAmountPermProps,
      isClaimOffsetAllowedPermProps,
    ],
  );

  return fields as Field[];
};

export default useGetReimbursementFields;
