import React from 'react';
import { Box, costFormater, Hint, InfoOutlineIcon, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { CalendarPlanPayload } from '../../ScheduledContribution.types';
const StyledInfoIcon = styled(InfoOutlineIcon)`
  &:hover {
    fill: ${({ theme }) => theme.colors.iconAccentHover};
  }
`;
interface Props extends CalendarPlanPayload {}
const ScheduledContributionCalendarPlanItem: React.FC<Props> = ({ name, recipients, forecastedAmount }) => {
  
  return (
    <Box
      direction="row"
      margin={{ bottom: 'm' }}
      background="canvas"
      pad={{ vertical: 'xs', horizontal: 'm' }}
      round="dropdownItemRound"
      border={{ size: 'small', color: 'border2' }}
    >
      <Box direction="column" style={{ flex: 2 }}>
        <Text size="small" weight={700} margin={{ bottom: 'xs' }}>
          Plan
        </Text>
        <Text size="medium">{name}</Text>
      </Box>
      <Box direction="column" style={{ flex: 2 }}>
        <Text size="small" weight={700} margin={{ bottom: 'xs' }}>
          Recipients
        </Text>
        <Text size="medium">{recipients}</Text>
      </Box>
      <Box direction="column" style={{ flex: 6 }}>
        <Text size="small" weight={700} margin={{ bottom: 'xs' }}>
          Forecasted amount
        </Text>
        <Box direction="row" align="center">
          <Text size="medium">{costFormater(forecastedAmount, { isPrecision: true })}</Text>

          <Hint
            hintAlign="vertical"
            hintElement={(
              <Box margin={{ left: 'xxs', bottom: '1px' }}>
                <StyledInfoIcon size="16px" />
              </Box>
            )}
            marginContent="m"
          >
            <Text>The amount may vary depending on the contribution date</Text>
          </Hint>
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduledContributionCalendarPlanItem;
