import React, { useEffect } from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import useLockboxesQuery from '@/modules/employer/components/Lockbox/queries/useLockboxes.query';
import useNavigationByList from '@/utils/hooks/useNavigationByList';

import { NavigationButton } from './LockboxNavigationButtons.styles';

interface Props {
  lockboxId: string;
}

const LockboxNavigationButtons: React.FC<Props> = ({ lockboxId }) => {
  const history = useHistory();
  const { setNextLockboxId } = useLockboxDetailsStore();

  const { 
    isItemFirstInList,
    isItemLastInList,
    prevItem,
    nextItem,
    params,
    isPageLoaded,
    handleUpdateLoadedPages,
  } = useNavigationByList({ currentItem: lockboxId });

  const { isLoading } = useLockboxesQuery({
    ...params,
    enabled: !isPageLoaded && (isItemFirstInList || isItemLastInList),
    onSuccess: handleUpdateLoadedPages,
  });

  useEffect(() => {
    if (nextItem) {
      setNextLockboxId(nextItem);
    }
  }, [setNextLockboxId, nextItem]);

  return (
    <Box direction="row">
      <Box>
        <NavigationButton
          isBack
          {...(prevItem && !isLoading ? {
            onClick: () => history.push(ROUTES.LOCKBOX_DETAILS(prevItem)),
            isAvailable: true,
            color: 'iconPrimary',
          } : { color: 'iconSecondary' })}
        />
      </Box>
      <Box margin={{ left: 'spacing2' }}>
        <NavigationButton
          {...(nextItem && !isLoading ? {
            onClick: () => history.push(ROUTES.LOCKBOX_DETAILS(nextItem)),
            isAvailable: true,
            color: 'iconPrimary',
          } : { color: 'iconSecondary' })}
        />
      </Box>
    </Box>
  );
};

export default LockboxNavigationButtons;
