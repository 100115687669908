import React, { useMemo } from 'react';
import { Box, Inscription,ListItem, ListItemType } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize, toNumber } from 'lodash';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { DefaultSortTypesEnum } from '@/common/types';
import { formatCurrency } from '@/utils';

import formatTaxType from '../../Contributions/formatTaxType';
import useGetContributionActivitiesQuery from '../queries/useGetContributionActivities.query';
import { ContributionActivityStatus } from '../types';

const StyledText = styled(Inscription)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const EMPTY_FIELD_VALUE = '-';

interface Props {
  contributionId?: string;
  page?: number;
  perPage?: number;
  statuses?: ContributionActivityStatus[];
  sortBy?: DefaultSortTypesEnum;
  taxYears?: string[];
  planCodes?: string[];
}

const useContributionActivitiesList = (props: Props) => {
  const { contributionId, page, perPage, statuses, sortBy, taxYears, planCodes } = props;
  const { isLoading, formattedData, totalElements } = useGetContributionActivitiesQuery({
    contributionId,
    page,
    size: perPage,
    statuses,
    sortBy,
    taxYears,
    planCodes,
  });
  const rows = useMemo(() => {
    return formattedData?.map((item) => ({
      fields: [
        {
          key: 'employeeFullName',
          type: ListItemType.Node,
          title: item?.employeeFullName || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1.2,
          node: (
            <Box pad={{ right: 'spacing4' }}>
              <StyledText title={item?.employeeFullName} lineHeight="20px" color="textBody">{item?.employeeFullName || EMPTY_FIELD_VALUE}</StyledText>
              {item.subsidiaryName ? (
                <Box background="border1" round="4px" justify="center" align="center" margin={{ top: 'spacing2' }}>
                  <StyledText title={item.subsidiaryName} lineHeight="20px" color="textBody">{item.subsidiaryName}</StyledText>
                </Box>
              ) : null}
            </Box>
          ),
        },
        {
          key: 'employeeContributionAmount',
          type: ListItemType.Text,
          title: item?.employeeContributionAmount ? formatCurrency(toNumber(item?.employeeContributionAmount)) : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 0.8,
        },
        {
          key: 'employerContributionAmount',
          type: ListItemType.Text,
          title: item?.employerContributionAmount ? formatCurrency(toNumber(item?.employerContributionAmount)) : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 0.8,
        },
        {
          key: 'planCode',
          type: ListItemType.Text,
          title: item?.planCode || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1.2,
        },
        {
          key: 'taxYear',
          type: ListItemType.Text,
          title: item?.taxYear || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
        },
        {
          key: 'taxType',
          type: ListItemType.Text,
          title: item?.taxType ? formatTaxType(item?.taxType) : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
        },
        {
          key: 'contributionDateOn',
          type: ListItemType.Text,
          title: item?.contributionDateOn ? dayjs(item?.contributionDateOn).format(DEFAULT_DATE_FORMAT) : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
        },
        {
          key: 'processingStatus',
          type: ListItemType.Status,
          title: capitalize(item?.processingStatus),
          ellipsisMode: true,
          isReverse: true,
          style: {
            marginRight: '20px',
            marginLeft: '-20px',
          },
          flex: 1.4,
        },
        {
          key: 'memo',
          type: ListItemType.Text,
          title: item?.memo || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
        },
      ],
    })) as ListItem[];
  }, [formattedData]);
  return {
    rows,
    totalElements,
    isLoading,
    formattedData,
  };
};

export default useContributionActivitiesList;
