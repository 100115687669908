import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ACCEPTED_LOGO_FILE_TYPES, MAX_LOGO_FILE_SIZE } from '@/common/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getColor = (props: any) => {
  if (props.isDragActive) {
    return props.theme.colors.button;
  }
  if (props.isDragStart) {
    return props.theme.colors.button;
  }
  return '';
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBorderType = (props: any) => {
  if (props.isDragActive) {
    return 'solid';
  }
  if (props.isDragStart) {
    return 'dashed';
  }
  return '';
};

interface DnDWrapperProps {
  isDragStart?: boolean;
}

export const DropWrapper = styled(Box)<DnDWrapperProps>`
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) => getBorderType(props)};
  border-width: ${({ theme }) => theme.spacings.spacing2};
  border-radius: ${({ theme }) => theme.spacings.spacing8};
  transition: border 0.24s;
  cursor: default;
`;

interface DropProps {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (file: any) => void;
  onSizeError?: (value: boolean) => void;
  onFormatError?: (value: boolean) => void;
  isDragStart?: boolean;
  disabled?: boolean;
}

export const DragNDropContainer: React.FC<DropProps> = ({
  children,
  onChange,
  isDragStart,
  onSizeError,
  onFormatError,
  disabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAddFile = useCallback((files: any[]) => {
    const currentFile = files[0];

    const isAcceptanceSize = currentFile.size <= MAX_LOGO_FILE_SIZE;
    const currentFileFormat = currentFile.name.split('.').reverse()[0];

    if (!isAcceptanceSize && onSizeError) {
      onSizeError(true);
    } else if (onSizeError) onSizeError(false);

    if (!ACCEPTED_LOGO_FILE_TYPES.includes(currentFileFormat) && onFormatError) {
      onFormatError(true);
    } else if (onFormatError) onFormatError(false);

    if (!ACCEPTED_LOGO_FILE_TYPES.includes(currentFileFormat) || !isAcceptanceSize) return;

    const currentPreparedFile = Object.assign(currentFile, {
      preview: URL.createObjectURL(currentFile),
    });
    onChange(currentPreparedFile);
  }, [onChange, onFormatError, onSizeError]);

  const {
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    noClick: true,
    // accept: ACCEPTED_LOGO_FILE_TYPES,
    multiple: false,
    onDrop: handleAddFile,
    disabled,
  });

  return (
    <DropWrapper {...getRootProps({ isDragActive })} isDragStart={isDragStart && !disabled}>
      <input {...getInputProps()} />
      {children}
    </DropWrapper>
  );
};
