import React, { useCallback, useMemo } from 'react';
import {
  Box,  NavigationLeftIcon, NavigationRightIcon, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { useRedirectToPrevObserving } from '@/modules/core/components/Topbar/Breadcrumbs/hooks/useRedirectToPrevObserving';
import { useBreadcrumbsStore } from '@/modules/core/store/useBreadcrumbs.store';
import { useAuthStore } from '@/modules/user/stores';

import { BreadcrumbGroup } from './components/BreadcrumbGroup';
import { BreadcrumbsPopup } from './components/BreadcrumbsPopup';

const BackButtonWrap = styled(Box)`
  box-shadow: none;
  .icon {
    color: ${({ theme }) => theme.colors.iconAccent};
  }
`;

const BreadcrumbsWrapper = styled(Box)`
  & > div:last-child {
    display: none;
  }
`;

const NavigationRight = () => (
  <Box margin={{ horizontal: 'spacing2' }} align="center">
    <NavigationRightIcon />
  </Box>
);

const BREADCRUMBS_LIMIT = 3;

interface Props {
  expressSetupMode?: boolean;
}

export const Breadcrumbs: React.FC<Props> = ({ expressSetupMode }) => {
  const { user } = useAuthStore();
  const userOrganizationType = useMemo(() => user?.organizationType, [user]);
  const { breadcrumbsGroup, options } = useBreadcrumbsStore(expressSetupMode);
  const lastGroup = useMemo(
    () => breadcrumbsGroup[breadcrumbsGroup.length - 1],
    [breadcrumbsGroup],
  );
  const { redirectToPrevObserving } = useRedirectToPrevObserving(lastGroup?.iconType);

  const backButtonHandler = useCallback(() => {
    let lastLink = lastGroup?.breadcrumbs[lastGroup?.breadcrumbs.length - 2]?.route;
    if(!lastLink) {
      const previousGroup = breadcrumbsGroup[breadcrumbsGroup.length - 2];
      const lastBreadcrumb = _.last(previousGroup?.breadcrumbs);
      lastLink = lastBreadcrumb?.route || '';
      
      redirectToPrevObserving(lastLink, {
        ...options,
        orgType: previousGroup.iconType
      }); 
    } else {
      redirectToPrevObserving(lastLink, options);
    } 
  }, [lastGroup, breadcrumbsGroup, redirectToPrevObserving, options]);

  const renderedBreadcrumbs = useMemo(() => (
    breadcrumbsGroup.map((group, index) => (
      <React.Fragment key={group.id}>
        <BreadcrumbGroup
          userOrganizationType={userOrganizationType}
          groupIndex={index}
          groupId={group.id}
          type={group.iconType}
          breadcrumbs={group.breadcrumbs}
          options={options}
          hasMore={!!breadcrumbsGroup[index + 1]}
          hideFirstBreadcrumb={expressSetupMode}
          isEllipsisMode={(breadcrumbsGroup.length > BREADCRUMBS_LIMIT - 1) && index === 0}
        />
        {
          breadcrumbsGroup[index + 1]
            ? (
              <NavigationRight />
            )
            : null
        }
      </React.Fragment>
    ))
  ), [breadcrumbsGroup, options, userOrganizationType, expressSetupMode]);

  const isBreadcrumbsLimitReached = useMemo(
    () => breadcrumbsGroup.length > BREADCRUMBS_LIMIT,
    [breadcrumbsGroup],
  );

  const firstBreadcrumbsGroup = useMemo(
    () => renderedBreadcrumbs[0],
    [renderedBreadcrumbs],
  );

  const middleBreadcrumbsGroups = useMemo(
    () => (isBreadcrumbsLimitReached
      ? [...renderedBreadcrumbs].slice(1, renderedBreadcrumbs.length - 2)
      : [...renderedBreadcrumbs].slice(1, renderedBreadcrumbs.length)),
    [isBreadcrumbsLimitReached, renderedBreadcrumbs],
  );

  const lastBreadcrumbsGroups = useMemo(
    () => (isBreadcrumbsLimitReached
      ? [...renderedBreadcrumbs].slice(renderedBreadcrumbs.length - 2, renderedBreadcrumbs.length)
      : null),
    [renderedBreadcrumbs, isBreadcrumbsLimitReached],
  );
  const isBackIcon = useMemo(() => {
    return lastGroup?.breadcrumbs?.length > 2 || breadcrumbsGroup?.length > 1;
  }, [breadcrumbsGroup?.length, lastGroup?.breadcrumbs?.length]);
  return (
    <Box direction="row" align="center">
      {
        (!expressSetupMode && (isBackIcon || options?.backBtn))
          ? (
            <BackButtonWrap
              onClick={() => backButtonHandler()}
              direction="row"
              align="center"
              width={{ min: '62px' }}
              margin={{ right: 'spacing24' }}
            >
              <NavigationLeftIcon color="textAccent" size="18px" /> 
              <Text margin={{ left: 'spacing8' }} color="textAccent" weight="bold">Back</Text>
            </BackButtonWrap>
          )
          : null
      }
      <Box direction="row" align="center" wrap margin={{ right: 'spacing12' }}>
        {firstBreadcrumbsGroup}
        {
          isBreadcrumbsLimitReached ? (
            <>
              <BreadcrumbsPopup
                content={(
                  <BreadcrumbsWrapper align="start">
                    {middleBreadcrumbsGroups}
                  </BreadcrumbsWrapper>
                )}
                style={{ top: 50 }}
              >
                <Box
                  margin={{ vertical: 'spacing2' }}
                  round="snackbarRound"
                  pad={{ horizontal: 'spacing12', vertical: '5px' }}
                  direction="row"
                  justify="center"
                  border={{ size: 'xsmall', color: 'border2' }}
                  style={{ position: 'relative' }}
                >
                  <Text>...</Text>
                </Box>
              </BreadcrumbsPopup>
              <NavigationRight />
            </>
          ) : (
            middleBreadcrumbsGroups
          )
        }
        {lastBreadcrumbsGroups}
      </Box>
    </Box>
  );
};
