import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';
interface Props {
  value?: string;
}

const SOURCE_TYPES = {
  UI: 'UI',
  API: 'API',
  FILE: 'FPS',
  SCHEDULED: 'SCHEDULED'
};
const ContributionListItemSource: React.FC<Props> = ({ value = '' }) => {
  const text = useMemo(() => {
    if (value === SOURCE_TYPES.UI) {
      return 'Manual';
    }
    if (value === SOURCE_TYPES.SCHEDULED) {
      return 'Scheduled';
    }
    return '';
  }, [value]);
  const title = useMemo(() => {
    if (value === SOURCE_TYPES.UI) {
      return 'UI';
    }
    if (value === SOURCE_TYPES.API) {
      return 'API';
    }
    if(value === SOURCE_TYPES.SCHEDULED) {
      return 'UI';
    }
    if(value === SOURCE_TYPES.FILE) {
      return 'File';
    }
    return '';
  }, [value]);

  if (!value) {
    return null;
  }
  return (
    <Box direction="row" align="center">
      {title && (
        <Box
          background="border1"
          round="snackbarRound"
          width="25px"
          height="18px"
          margin={{ right: 'xs' }}
          pad={{ vertical: '0px' }}
          align="center"
          justify="center"
        >
          <Text size="xsmall">{title}</Text>
        </Box>
      )}
      
      <Text size="medium" weight={400}>
        {text}
      </Text>
    </Box>
  );
};
export default ContributionListItemSource;
