import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';
import { Pagination } from '@/common/types';

import { TaxType } from '../../contributions.types';
import {
  PayrollCalendarStatus,
  ScheduledContributionFrequency,
  ScheduledContributionPayload,
  ScheduledContributionStatus,
} from '../ScheduledContribution.types';

export const SCHEDULED_CONTRIBUTION_QUERY_KEY = 'GET_SCHEDULED_CONTRIBUTION';
export interface ScheduledContributionResponse {
  calendarId: number;
  scheduledContributionId: number;
  calendarName: string;
  calendarStatus: PayrollCalendarStatus
  frequencyType: ScheduledContributionFrequency;
  nextScheduledDate: string | null;
  startDate: string | null;
  planCodes: string[];
  taxType: TaxType;
  status: ScheduledContributionStatus;
}

const useScheduledContributionListQuery = () => {
  const { data, isLoading, isSuccess } = useQuery(
    [SCHEDULED_CONTRIBUTION_QUERY_KEY],
    () => api.get<Pagination<ScheduledContributionResponse>>(PATHS.SCHEDULED_CONTRIBUTION_ORGANIZATION),
    {
      retry: 0,
    }
  );

  const formattedData = useMemo<ScheduledContributionPayload[]>(() => {
    const list = data?.data.content || [];
    return list.map((item) => ({
      id: `${item.scheduledContributionId}`,
      calendarId: `${item.calendarId}`,
      name: item.calendarName,
      frequency: item.frequencyType,
      calendarStatus: item.calendarStatus,
      nextScheduledDate: item.nextScheduledDate,
      status: item.status,
      taxType: item.taxType,
      startDate: item.startDate ? dayjs(item.startDate, RAW_DATE_FORMAT).format(DEFAULT_DATE_FORMAT) : null,
      accounts: item.planCodes.map((account) => ({
        key: account,
        value: account,
        title: account,
      })),
    }));
  }, [data?.data]);
  return {
    formattedData,
    isLoading,
    isSuccess,
  };
};

export default useScheduledContributionListQuery;
