import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const REPULL_PREFUND_QUERY_KEY = 'repullPrefundQueryKey';

export const useRepullPrefund = () => {
  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    [REPULL_PREFUND_QUERY_KEY],
    (invoiceId: string) => api.post(PATHS.REPULL_PREFUND_BY_INVOICE_ID(invoiceId)),
  );

  const handleRepull = useCallback(async (orgId: string) => {
    await mutateAsync(orgId);
  }, [mutateAsync]);

  return {
    isLoading,
    isError,
    isSuccess,
    repull: handleRepull,
  };
};
