import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { LARGE_SIZE_LOGO, LetterIconSizeEnum } from '@/common/constants';
import { Icon } from '@/components/elements/Icon/Icon';

interface Props {
  logoSrc?: string;
}

const LargeLogo: React.FC<Props> = ({ logoSrc }) => {
  if (logoSrc) {
    return (
      <Icon
        size={LetterIconSizeEnum.LOGO}
        height={LARGE_SIZE_LOGO.height}
        width={LARGE_SIZE_LOGO.width}
        src={logoSrc}
      />
    );
  }

  return (
    <Box
      background="border1"
      align="center"
      justify="center"
      height={`${LARGE_SIZE_LOGO.height}px`}
      width={`${LARGE_SIZE_LOGO.width}px`}
    >
      <Text color="textDisabled">Your logo</Text>
    </Box>
  );
};

export default LargeLogo;
