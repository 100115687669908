import React from 'react';
import {
  Box,
  BrowserLeftRoundIcon,
  BrowserRightRoundIcon,
  CloseIcon,
  Inscription,
  PlusIcon
} from '@common-fe/common-fe';

import { LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { Icon } from '@/components/elements/Icon/Icon';

interface Props {
  logoSrc?: string;
  organizationType?: OrganizationTypes;
  organizationName?: string;
}

const FakeBrowserTab: React.FC<Props> = ({ logoSrc, organizationType, organizationName }) => {
  return (
    <Box direction="row" align="center">
      <Box
        background="canvas"
        round={{ corner: 'top', size: 'container2Round' }}
        pad={{ vertical: 'spacing8', horizontal: 'spacing12' }}
        margin={{ left: 'spacing8' }}
        direction="row"
        align="center"
        style={{ position: 'relative' }}
      >
        <Icon
          size={LetterIconSizeEnum.FAV}
          type={organizationType}
          text={organizationName}
          src={logoSrc}
        />
        <Box
          margin={{ left: 'spacing8' }}
          width={{ max: '175px', min: '175px' }}
          overflow="hidden"
        >
          <Inscription
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
            lineHeight="20px"
          >
            {organizationName}
          </Inscription>
        </Box>

        <Box margin={{ left: 'auto' }}>
          <CloseIcon size="small" color="iconSecondary" />
        </Box>

        <Box
          style={{
            position: 'absolute',
            bottom: -2,
            left: -8,
          }}
        >
          <BrowserLeftRoundIcon color="canvas" size="10px" />
        </Box>
        <Box
          style={{
            position: 'absolute',
            bottom: -2,
            right: -8,
          }}
        >
          <BrowserRightRoundIcon color="canvas" size="10px" />
        </Box>
      </Box>

      <Box margin={{ left: 'spacing12', right: 'spacing16' }}>
        <PlusIcon size="small" color="iconSecondary" />
      </Box>
    </Box>
  );
};

export default FakeBrowserTab;
