import React, { useCallback, useEffect, useState } from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';
import styled from 'styled-components';

import { EnrollmentMatrixItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import { EnrollmentMatrixEntityPayload, useStore } from './stores/useEnrollmentMatrixEntity.store';
import { useEnrollmentMatrixFields } from './hooks';

export const FormWrapper = styled(Box)`
  .small-input {
    width: 180px;
  }
`;

interface Props {
  enrollmentMatrixError?: boolean;
  testId?: string;
  updatedElement?: EnrollmentMatrixItem;
}
const MatrixEntity: React.FC<Props> = ({
  testId = '',
  enrollmentMatrixError,
  updatedElement,
}) => {
  const [errorName, setErrorName] = useState<string>();
  const setState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const currentState = useStore((state) => state.state);
  const fields = useEnrollmentMatrixFields(updatedElement, { name: errorName || '' });
  const handleMatrixEntityReset = useStore((state) => state
    .handleReset);
  const handleReset = useCallback(() => {
    handleMatrixEntityReset();
  }, [handleMatrixEntityReset]);

  const handleSetState = useCallback((values: Partial<EnrollmentMatrixEntityPayload>) => {
    setState(values);
  }, [setState]);

  useEffect(() => () => {
    handleReset();
  }, [handleReset]);

  useEffect(() => {
    if (enrollmentMatrixError) {
      setErrorName(currentState.name);
    } else {
      setErrorName('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentMatrixError]);

  return (
    <FormWrapper elevation="default" background="canvas" round="container1Round">
      <FlexControlledForm
        fields={fields}
        testId={`${testId}_enrollment-matrix-entity`}
        showError={showErrors}
        editMode
        onChangeValues={handleSetState}
      />
    </FormWrapper>
  );
};
export default MatrixEntity;
