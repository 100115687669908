import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Contact } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import useContactStore, { ContactPayload } from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';

import { ExpressSetupWizardStepType } from '../ExpressSetup.types';

import useCurrentExpressSetup from './useCurrentExpressSetup';
import useExpressSetupSetter from './useExpressSetupSetter';

export const useExpressSetupActions = () => {
  const history = useHistory();
  const location = useLocation();
  
  const [updatedContact, setUpdatedContact] = useState<ContactPayload>();
  const { handleReset } = useExpressSetupSetter();
  const handleSetState = useContactStore((state) => state.setSourceState);
  const { list: serviceCategories } = useServiceCategoriesQuery();
  const query = useMemo(() => new URLSearchParams(location.search)?.get(QueryKeys?.SHOULD_OPEN_MODAL),[location.search]);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const { data: expressSetupData, isLoading } = useCurrentExpressSetup(serviceCategories);
  
  const contacts = useMemo(() => expressSetupData?.contacts?.filter((contact) => !contact.isPrimary), [expressSetupData]);
  
  const handleUpdateContact = useCallback((value?: Contact | ContactPayload) => {
    setUpdatedContact(value as ContactPayload);
  },[setUpdatedContact]);

  const handleRedirectToWizardStep = useCallback((stepType: ExpressSetupWizardStepType, isEditMode?: boolean) => {
    const editModeQuery = isEditMode ? `&${QueryKeys.EDIT_MODE}=true` : '';
    const baseRoute = ROUTES.EXPRESS_SETUPS_WIZARD_BY_ID(expressSetupData?.orgId);
    const searchQuery = history.observingSearchQuery ? `?${history.observingSearchQuery}${editModeQuery}` : editModeQuery;
    const fullUrl = `${baseRoute}${searchQuery}#${stepType}`;

    history.originPush(fullUrl);
  }, [history, expressSetupData]);

  const handleOpenAddressModal = useCallback((value: boolean) => {
    setAddressModalVisible(value);
  }, [setAddressModalVisible]);

  const handleOpenContactModal = useCallback((value?: boolean) => {
    setContactModalVisible(!!value);
  }, [setContactModalVisible]);

  useEffect(() => {
    if (updatedContact) {
      setContactModalVisible(true);
      handleSetState(updatedContact);
    }
  }, [handleSetState, updatedContact]);

  useEffect(() => {
    if (query) {
      setContactModalVisible(true);
      history.removeQueryParameter([QueryKeys?.SHOULD_OPEN_MODAL]);
    }
  }, [query, history]);

  useEffect(() => () => {
    handleReset();
  }, [handleReset]);

  return {
    updatedContact,
    setUpdatedContact,
    contactModalVisible,
    setContactModalVisible,
    addressModalVisible,
    setAddressModalVisible,
    expressSetupData,
    isLoading,
    contacts,
    handleUpdateContact,
    handleRedirectToWizardStep,
    handleOpenAddressModal,
    handleOpenContactModal,
  };
};
