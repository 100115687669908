import { Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

export const EllipsisText = styled(Inscription)<{ hasUppercase?: boolean }>`
text-overflow: ellipsis;
max-width: 100%;
overflow: hidden;
white-space: nowrap;
&:first-letter {
  ${({ hasUppercase = true }) => {
    if (hasUppercase) return 'text-transform: uppercase;';
    return '';
  }};
}
`;
