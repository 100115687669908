import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useAccountFundingSetter, { AccountFundingPayload } from '../AccountFunding/hooks/useAccountFundingSetter';
import useCoverageSetter, { CoveragePayload } from '../Coverage/hooks/useCoverageSetter';
import usePlanDefinitionSetter, { PlanDefinitionPayload } from '../PlanDefinition/hooks/usePlanDefinitionSetter';

export type PlanPayload = PlanDefinitionPayload & AccountFundingPayload & CoveragePayload;

export default () => {
  const {
    handleSetState: handleSetPlanDefinition,
    handleReset: handleResetPlanDefinition,
    handleSetErrors: handleSetPlanDefinitionErorrs,
    handleSetErrorFields: handleSetPlanDefinitionErrorFields,
    handleSetSourceState: handleSetSourcePlanDefinition,
  } = usePlanDefinitionSetter();

  const {
    handleSetState: handleSetCoverage,
    handleReset: handleResetCoverage,
    handleSetErrors: handleSetCoverageErorrs,
    handleSetErrorFields: handleSetCoverageErrorFields,
    handleSetSourceState: handleSetSourceCoverage,
  } = useCoverageSetter();

  const {
    handleSetState: handleSetAccountFundingState,
    handleSetSourceState: handleSetSourceAccountFundingState,
    handleReset: handleResetAccountFunding,
    handleSetErrors: handleSetAccountFundingErrors,
    handleSetErrorFields: handleSetAccountFundingErrorFields,
  } = useAccountFundingSetter();

  const handleReset = useCallback(() => {
    handleResetPlanDefinition();
    handleResetCoverage();
    handleResetAccountFunding();
  }, [handleResetAccountFunding, handleResetCoverage, handleResetPlanDefinition]);

  const handleSetSourceState = useCallback((values: Partial<PlanPayload>) => {
    handleSetSourceCoverage(values);
    handleSetSourcePlanDefinition(values);
    handleSetSourceAccountFundingState(values);
  }, [handleSetSourceAccountFundingState, handleSetSourceCoverage, handleSetSourcePlanDefinition]);

  const handleSetState = useCallback((values: Partial<PlanPayload>) => {
    handleSetCoverage(values);
    handleSetPlanDefinition(values);
    handleSetAccountFundingState(values);
  }, [handleSetAccountFundingState, handleSetCoverage, handleSetPlanDefinition]);

  const handleSetErrors = useCallback((values: Partial<PlanPayload>) => {
    handleSetPlanDefinitionErorrs(values);
    handleSetCoverageErorrs(values);
    handleSetAccountFundingErrors(values);
  }, [
    handleSetAccountFundingErrors,
    handleSetCoverageErorrs,
    handleSetPlanDefinitionErorrs,
  ]);
  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<PlanPayload>>) => {
    handleSetPlanDefinitionErrorFields(values);
    handleSetCoverageErrorFields(values);
    handleSetAccountFundingErrorFields(values);
  }, [
    handleSetAccountFundingErrorFields,
    handleSetCoverageErrorFields,
    handleSetPlanDefinitionErrorFields,
  ]);
  return {
    handleSetErrorFields,
    handleSetErrors,
    handleReset,
    handleSetState,
    handleSetSourceState,
  };
};
