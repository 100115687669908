import React from 'react';
import { Box, CardTypesEnum,Text } from '@common-fe/common-fe';

import { useExpressSetupSaveValidate } from '@/modules/ExpressSetup/hooks/useExpressSetupSaveValidate.ts';
import { GroupedPlan } from '@/modules/plan/plan.types';
import { Plan } from '@/modules/plan/plan.types.ts';

import PlanRadioOptionCard from './PlanRadioOptionCard';
import PlanTypeCard from './PlanTypeCard';

export interface Props {
  activeTemplates?: GroupedPlan[];
  selectedPlanTypes: CardTypesEnum[];
  selectedParentTemplates: Plan[];
  isPlansErrorShows: boolean;
  onParentTemplateSelect: (type: CardTypesEnum, plan: Plan) => void;
  onRemoveTypeCard: (type: CardTypesEnum) => void;
}

const PlansForm: React.FC<Props> = ({ selectedPlanTypes, activeTemplates, selectedParentTemplates, isPlansErrorShows, onParentTemplateSelect, onRemoveTypeCard }) => {
  const { plansErrors } = useExpressSetupSaveValidate();

  return (
    <Box
      data-testId="plans-form"
      round="container1Round"
    >
      {
        selectedPlanTypes?.map((type, index) => {
          const activeTemplate = activeTemplates?.find((template: GroupedPlan) => template?.categoryEnum === type);
          const error = plansErrors?.find((error) => error.type === type);
          return (
            <Box key={type} background="module" round="container1Round" margin={{ bottom: index === selectedPlanTypes?.length - 1 ? '0' : 'l'}}>
              <PlanTypeCard
                type={type}
                isLastItem={index === selectedPlanTypes.length - 1}
                onRemove={onRemoveTypeCard}
                isPlansErrorShows={isPlansErrorShows}
                error={error}
              />
              <Box pad={{ horizontal: 'l', bottom: 'l', top: 'm' }}>
                <Text color="textSecondary" margin={{ bottom: 'l' }}>{`Plan options: ${activeTemplate?.plans?.length || ''}`}</Text>
                {
                  activeTemplate?.plans?.map((plan: Plan, index: number) => (
                    <PlanRadioOptionCard
                      key={plan.id}
                      plan={plan}
                      onClick={() => onParentTemplateSelect(activeTemplate?.categoryEnum, plan)}
                      isChecked={selectedParentTemplates?.some((selectedPlan: Plan) => selectedPlan?.id === plan.id)}
                      isLastItem={index === activeTemplate?.plans?.length - 1}
                    />
                  ))
                }
              </Box>
            </Box>
          );
        })
      }
    </Box>
  );
};

export default PlansForm;
