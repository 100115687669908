import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  AppButton,
  Box, FlexControlledForm, } from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import ToEditButton from '../../components/ToEditButton';
import { useSetupEmployerMode } from '../../hooks/useSetupEmployerMode';
import { useCommunicationCategoryForm } from '../hooks';
import { useCommunicationCategoryStore } from '../stores';

import { LabelWrap } from './CommunicationCategory.styles';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const CommunicationCategory: React.FC<Props> = ({ onDirty }) => {
  const {
    formattedData: {
      overridableProperties,
      isTerminated,
      organizationStatusType,
    },
    isLoading,
  } = useGetEmployer();
  const isEditRestricted = useMemo(() => {
    return isTerminated || organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [isTerminated, organizationStatusType]);
  const categories = overridableProperties?.communicationCategory;
  const { handleSetValues, state } = useCommunicationCategoryStore();
  const onChange = useCallback(
 
    (communicationCategory: string[]) => handleSetValues({ communicationCategory }),
    [handleSetValues],
  );
  useEffect(
    () => {
      if (Array.isArray(categories)) {
        handleSetValues({ communicationCategory: categories });
      }
    },
    [categories, handleSetValues],
  );
  const { viewMode } = useSetupEmployerMode();
  const communicationCategoryPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.COMMUNICATION_CATEGORIES,
  );
  const fields = useCommunicationCategoryForm(
    onChange,
    state.communicationCategory,
    communicationCategoryPermProps,
  );

  const areAllItemsSelected = useMemo(
    () => fields?.length === state?.communicationCategory?.length,
    [fields, state],
  );
  const toggleAllHandler = useCallback(
    () => {
      handleSetValues({
        communicationCategory: areAllItemsSelected
          ? []
          : fields.map((field) => field.name),
      });
    },
    [
      areAllItemsSelected,
      fields,
      handleSetValues,
    ],
  );

  if (isLoading) return <FormLoader testId="CommunicationCategory" />;

  return (
    <Box
      id="email_sms_communications"
      background="canvas"
      round="container1Round"
      data-testid="Communication-category-form-wrapper"
    >
      <Box>
        <FlexControlledForm
          editMode={!viewMode && !isEditRestricted}
          formTitle="Email/Text communications"
          fields={fields}
          shouldControlValues
          onDirty={onDirty}
          onChangeValues={() => {}}
          isThinMode
          subTitle={!viewMode && !communicationCategoryPermProps?.disabled && (
            <LabelWrap margin={{ top: 'spacing16', bottom: 'spacing24' }}>
              <AppButton
                testId="Communication-select-all"
                buttonType="quaternary"
                onClick={toggleAllHandler}
              >
                {areAllItemsSelected ? 'Opt-out all' : 'Opt-in all'}
              </AppButton>
            </LabelWrap>
          )}
        >
          {isEditRestricted ? null : <ToEditButton />}
        </FlexControlledForm>
      </Box>
    </Box>
  );
};
