import React, { useEffect, useMemo } from 'react';
import {
  Box,
  FlexControlledForm,
  Text,
  useDebounceState,
} from '@common-fe/common-fe';

import { CommunicationChannelsType } from '@/common/constants';
import { CommonNotificationsPayload, useStore } from '@/modules/employee/Employee/Communications/CommunicationChannels/stores/useCommonNotifications.store';

import useCommunicationChannelsFields from './hooks/useCommunicationChannelsFields';

interface Props {
  viewMode?: boolean;
  communicationConfigChannels?: string[];
  communicationChannels?: string[];
  onDirty?: (isDirty: boolean) => void;
}

export const COMMUNICATION_CHANNEL = [
  { value: 'Notification center', key: 'NOTIFICATION_CENTER' },
  { value: 'Email/Text message', key: 'EMAIL_SMS' },
  { value: 'Data export', key: 'DATA_EXPORT' },
];

const FORM_SUBTITLE_TITLE = 'Good news!';

const CommunicationChannels: React.FC<Props> = ({
  viewMode,
  communicationConfigChannels,
  communicationChannels,
  onDirty,
}) => {
  const FORM_SUBTITLE_TEXT = useMemo(() => {
    if (communicationChannels?.find((elem) => elem === CommunicationChannelsType.EMAIL_SMS)
    && communicationChannels?.find((elem) => elem === CommunicationChannelsType.NOTIFICATION_CENTER)) {
      return 'In addition to the Notification Center where you can manage all of your messages in one place, real-time notices about your account activity, requests, and more can be sent directly to your email or phone.';
    }
    return 'Real-time notices about your account activity, requests, and more can be sent directly to your email or phone.';
  }, [communicationChannels]);
  const fields = useCommunicationChannelsFields(communicationConfigChannels);
  const handleSetState = useStore((state) => state.setState);
  const { setValues, values } = useDebounceState<Partial<CommonNotificationsPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box
      id="communication_channels"
      background="canvas"
      round="container1Round"
      data-testid="Communication-channels-form-wrapper"
    >
      <FlexControlledForm
        formTitle="Communication methods"
        subTitle={(
          <Box margin={{ vertical: 'spacing24' }}>
            <Text size="medium" weight="bold">
              {FORM_SUBTITLE_TITLE}
            </Text>
            <Text size="medium" weight="normal">
              {FORM_SUBTITLE_TEXT}
            </Text>
          </Box>
        )}
        fields={fields}
        editMode={!viewMode}
        onChangeValues={setValues}
        onDirty={onDirty}
        isThinMode
      />
    </Box>
  );
};

export default CommunicationChannels;
