import React from 'react';
import { Box, BoxProps } from '@common-fe/common-fe';

interface Props extends BoxProps {
  children?: React.ReactNode;
}

const LockboxInfoContainer: React.FC<Props> = ({ children, ...props }) => (
  <Box
    background="canvas"
    round="container1Round"
    border={{ color: 'border2', size: 'small' }}
    pad={{ horizontal: 'spacing24', vertical: 'spacing16' }}
    {...props}
  >
    {children}
  </Box>
);

export default LockboxInfoContainer;
