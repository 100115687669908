import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const BoxWithFocus = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.border1};
  }
`;

interface Props {
  isPrimary?: boolean;
}

export const EmployerIdText = styled(Text)<Props>`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme, isPrimary }) => (isPrimary ? theme.colors.textAccent : theme.colors.textDisabled)};
  text-overflow: ellipsis;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledItem = styled(Box)`
  flex: 1;
`;
