import React, { useCallback, useMemo, useState } from 'react';

export const useDetectVerticalScrollShadow = (content: HTMLDivElement | null, offset = 1) => {
  const [scrolledToTheEnd, setScrolledToTheEnd] = useState(false);
  const [startedScrolling, setStartedScrolling] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fullWrapHeight = useMemo(() => content?.offsetHeight, [content, startedScrolling]);
  const onScrollHandler = useCallback((evt: React.UIEvent<HTMLDivElement>) => {
    if (!fullWrapHeight) return;

    if (
      !scrolledToTheEnd
      && evt.currentTarget.scrollHeight <= Math.round(
        fullWrapHeight + evt.currentTarget.scrollTop + offset,
      )
    ) setScrolledToTheEnd(true);

    if (
      scrolledToTheEnd
      && evt.currentTarget.scrollHeight !== Math.round(fullWrapHeight + evt.currentTarget.scrollTop)
    ) setScrolledToTheEnd(false);

    if (
      startedScrolling
      && !evt.currentTarget.scrollTop
    ) setStartedScrolling(false);

    if (
      !startedScrolling
      && evt.currentTarget.scrollTop
    ) setStartedScrolling(true);
  }, [fullWrapHeight, scrolledToTheEnd, startedScrolling, offset]);

  return {
    fullWrapHeight,
    onScrollHandler,
    scrolledToTheEnd,
    startedScrolling,
  };
};
