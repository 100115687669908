import { useMutation, useQueryClient } from 'react-query';
import { toNumber } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface Props {
  teamId?: string;
  memebersIds?: string[];
}

const useAssignProcessorsToTeamQuery = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (props: Props) => api.put(PATHS.ASSIGN_PROCESSORS_TO_TEAM(props.teamId), {
      contacts_ids: [...new Set(props.memebersIds?.map((item) => toNumber(item)) || [])],
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.CLAIMS_TEAMS);
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );

  return mutateAsync;
};

export default useAssignProcessorsToTeamQuery;
