export enum HeaderTitle {
  NAME = 'Name',
  ACCOUNT = 'Account',
  EMPLOYEE_AMOUNT = 'Employee amount',
  EMPLOYER_AMOUNT = 'Employer amount',
  DATE = 'Date',
  TAX_YEAR = 'Tax year',
  TAX_TYPE = 'Tax type',
  MEMO = 'Memo',
}

const HEADERS = [
  {
    title: HeaderTitle.NAME,
    width: '12.5%',
  },
  {
    title: HeaderTitle.ACCOUNT,
    width: '12.5%',
  },
  {
    title: HeaderTitle.EMPLOYEE_AMOUNT,
    width: '15%',
  },
  {
    title: HeaderTitle.EMPLOYER_AMOUNT,
    width: '15%',
  },
  {
    title: HeaderTitle.DATE,
    width: '15%',
  },
  {
    title: HeaderTitle.TAX_YEAR,
    width: '6.5%',
  },
  {
    title: HeaderTitle.TAX_TYPE,
    width: '6.5%',
  },
  {
    title: HeaderTitle.MEMO,
    width: '17%',
  },
];

export default HEADERS;
