import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { VALIDATORS } from '@/common';
import PATHS from '@/common/paths';
import { PriorPlanYear } from '@/modules/plan/plan.types';
import { useCurrentPlan } from '@/modules/plan/PlanSetup/hooks';

export const getPriorPlanYearList = (
  date?: string,
  orgId?: number,
) => () => api.get<PriorPlanYear[]>(
  PATHS.PRIOR_PLAN_YEAR,
  {
    params: {
      organization_id: orgId,
      next_valid_from: date,
    },
  },
);

export const QUERY_KEY = 'getPriorPlanYear';

export const usePriorPlanYearQuery = (
  date?: string,
  orgId?: number,
  isCreateMode?: boolean,
) => {
  const { priorPlanYear } = useCurrentPlan();

  const formatedDate = useMemo(() => {
    try {
      VALIDATORS.DATE_FORMAT.validateSync(date);
      return date;
    } catch {
      return '';
    }
  }, [date]);

  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [QUERY_KEY, formatedDate],
    getPriorPlanYearList(formatedDate, orgId),
    {
      enabled: !!orgId && !!isCreateMode,
    },
  );

  const formatedPriorPlanYearList = useMemo(() => {
   
    const list = data?.data ? [...data.data] : [];
    if (priorPlanYear) {
      list.unshift({
        id: priorPlanYear.id,
        name: priorPlanYear.name,
        valid_from: priorPlanYear.valid_from,
        valid_to: priorPlanYear.valid_to,
      });
    }
    return list.map((item) => ({
      key: `${item.id}`,
      value: item.id,
      title: item.name,
      validFrom: item.valid_from,
      validTo: item.valid_to,
    }));
  }, [data, priorPlanYear]);

  return {
    data: formatedPriorPlanYearList,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};

export default usePriorPlanYearQuery;
