import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
  validatePhoneNumber,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import {
  PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT,
} from '@/common/constants';

const FIELDS = {
  EMAIL: 'email',
  PHONE_NUMBER_1: 'phoneNumber1',
  PHONE_1_TYPE: 'phone1Type',
  PHONE_NUMBER_2: 'phoneNumber2',
  PHONE_2_TYPE: 'phone2Type',
};

const PHONE_TYPE = [
  {
    key: 'HOME',
    value: 'Home',
    title: 'Home',
  },
  {
    key: 'WORK',
    value: 'Work',
    title: 'Work',
  },
  {
    key: 'MOBILE',
    value: 'Mobile',
    title: 'Mobile',
  },
  {
    key: 'UNKNOWN',
    value: 'Unknown',
    title: 'Unknown',
  },
];

export const useEmployeeSetupModalContactInfoFields = (
  isPhone1Exist?: boolean,
  isPhone2Exist?: boolean,
  phoneType1?: string,
  phoneType2?: string,
  isSubmit?: boolean,
) => {
  const fields: Field[] = useMemo(() => ([
    {
      name: FIELDS.EMAIL,
      type: FieldTypes.Text,
      label: 'Employer provided email',
      placeholder: 'Enter Email',
      validator: VALIDATORS.EMAIL,
      defaultValue: '',
    },
    {
      name: FIELDS.PHONE_1_TYPE,
      type: FieldTypes.AutocompleteDropdown,
      label: (
        <Box flex={{ grow: 1 }}>
          <Box direction="row">
            {isPhone1Exist && !phoneType1 && isSubmit ? (
              <Inscription
                color="danger"
                margin={{ right: '3px', top: '-3px' }}
              >
                *
              </Inscription>
            ) : null}
            <Inscription weight="bold" color="textBody" lineHeight="20px">
              Preferred phone
            </Inscription>
          </Box>
          <Box>
            <Inscription
              weight="normal"
              color="textSecondary"
              size="small"
              lineHeight="18px"
              hasEllipsisMode
            >
              This number used to verify your account.
            </Inscription>
          </Box>
        </Box>
      ),
      placeholder: 'Select Preferred phone type',
      defaultValue: '',
      ...isPhone1Exist ? {
        validator: yup.string().required(REQUIRED_TEXT)
      } : {},
      options: phoneType2
        ? PHONE_TYPE.filter((type) => type.key !== phoneType2)
        : PHONE_TYPE,
      style: { marginBottom: '-4px' },
    },
    {
      name: FIELDS.PHONE_NUMBER_1,
      type: FieldTypes.PhoneNumber,
      label: <Box />,
      placeholder: '+1',
      validator: yup
        .string()
        .test(validatePhoneNumber(PHONE_NUMBER_INVALID_TEXT)),
      defaultValue: '',
    },
    {
      name: FIELDS.PHONE_2_TYPE,
      type: FieldTypes.AutocompleteDropdown,
      label: (
        <Box flex={{ grow: 1 }} direction="row">
          {isPhone2Exist && !phoneType2 && isSubmit ? (
            <Inscription
              color="danger"
              margin={{ right: '3px', top: '-3px' }}
            >
              *
            </Inscription>
          ) : null}
          <Inscription weight="bold" color="textBody" lineHeight="20px">
            Additional phone
          </Inscription>
        </Box>
      ),
      placeholder: 'Select Additional phone type',
      defaultValue: '',
      validator: isPhone2Exist
        ? yup.string().required(REQUIRED_TEXT)
        : undefined,
      options: phoneType1
        ? PHONE_TYPE.filter((type) => type.key !== phoneType1)
        : PHONE_TYPE,
      style: { marginBottom: '-4px' },
    },
    {
      name: FIELDS.PHONE_NUMBER_2,
      type: FieldTypes.PhoneNumber,
      label: <Box />,
      validator: isPhone2Exist
        ? yup
          .string()
          .test(validatePhoneNumber(PHONE_NUMBER_INVALID_TEXT))
        : undefined,
      placeholder: '+1',
      defaultValue: '',
    },
  ]), [
    isSubmit,
    isPhone1Exist,
    isPhone2Exist,
    phoneType1,
    phoneType2,
  ]);

  return fields;
};
