import { useMutation, useQueryClient } from 'react-query';
import { FieldStateTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { HEALTH_PLAN_LIST_QUERY_NAME } from '@/modules/HealthPlan/queries/useHealthPlansList.query';
import { Plan, PlanStatuses } from '@/modules/plan/plan.types';

interface Payload {
  planYearId: number;
  parentId: number;
  name: string;
  planTypes?: string[];
  organizationId?: string;
  status?: PlanStatuses;
}
export const useCreatePlanMutations = (redirectMode?: boolean, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { organizationId } = useCurrentOrganisationStore();
  const mutation = useMutation(
    (data: Partial<Payload>) => api.post<Plan>(PATHS.HEALTH_PLANS, {
      name: {
        name: data.name,
        name_state: FieldStateTypes.Modifiable,
      },

      health_plan_types: {
        health_plan_types: data.planTypes,
      },
      parent_id: data.parentId,
      is_plan: false,

      plan_status: data.status,
      organization_id: _.toNumber(data.organizationId || organizationId),
    }), {

      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess();
        }
        const id = _.get(response, 'data.id');
        queryClient.invalidateQueries(HEALTH_PLAN_LIST_QUERY_NAME);
        if (redirectMode) {
          history.push(ROUTES.HEALTH_PLAN_TEMPLATE_SETUP_BY_ID(id));
        }
      },
    },
  );
  return mutation.mutateAsync;
};

export default useCreatePlanMutations;
