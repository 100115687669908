import React, { useMemo } from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { REGEXPS } from '@/common';
import {
  FAV_SIZE,
  LetterIconSizeEnum,
  LOGO_SIZE,
  OrganizationTypes,
  Roles,
} from '@/common/constants';
import colors from '@/styles/colors';

const iconShadow = (color: string) => `0px 0px 0px 6px ${color}`;

export interface Props {
  role?: Roles,
  type?: OrganizationTypes,
  size: LetterIconSizeEnum,
  text?: string;
  sizePx?: string;
  testId?: string;
  isWithShadow?: boolean;
}

export const UserLetterIcon: React.FC<Props> = ({
  role,
  type,
  size,
  text,
  sizePx,
  testId,
  isWithShadow,
}) => {
  const preparedName = useMemo(() => {
    if (text && text.length > 2) {
      const splitText = text.split(' ');
      const firstName = splitText[0];
      const lastName = splitText[splitText.length - 1];

      return `${firstName} ${lastName}`;
    }

    return text;
  }, [text]);
  const firstLetter = useMemo(() => preparedName?.match(REGEXPS.FIRST_LETTERS)?.join('') || '', [preparedName]);

  const backgroundByType = useMemo(() => {
    switch (type) {
    case OrganizationTypes.dependent: {
      return colors.dependent;
    }
    case OrganizationTypes.employee: {
      return colors.employee;
    }
    case OrganizationTypes.partner: {
      return colors.partner;
    }
    case OrganizationTypes.employer: {
      return colors.claimsProcessor;
    }
    default: {
      return undefined;
    }
    }
  }, [type]);

  const backgroundByRole = useMemo(() => {
    switch (role) {
    case Roles.superUser:
    case Roles.admin1:
    case Roles.admin2:
    case Roles.admin3: {
      return colors.superAdmin;
    }
    case Roles.claimsProcessor1:
    case Roles.claimsProcessorReview:
    case Roles.claimsProcessor2:
    case Roles.claimsProcessorEdit: {
      return colors.claimsProcessor;
    }
    default: {
      return undefined;
    }
    }
  }, [role]);

  const background = useMemo(
    () => backgroundByType || backgroundByRole || colors.noAssignee,
    [backgroundByRole, backgroundByType],
  );

  const boxShadow = useMemo(() => {
    if (role
      && (role === Roles.superUser
      || role === Roles.admin1
      || role === Roles.admin2
      || role === Roles.admin3)) {
      return iconShadow(colors.superAdminShadow);
    }
    if (role
      && (role === Roles.claimsProcessor1
      || role === Roles.claimsProcessorReview
      || role === Roles.claimsProcessor2
      || role === Roles.claimsProcessorEdit)) {
      return iconShadow(colors.claimsProcessorShadow);
    }
    if (type
      && (type === OrganizationTypes.dependent)) {
      return iconShadow(colors.dependentShadow);
    }
    if (type
      && (type === OrganizationTypes.employee)) {
      return iconShadow(colors.employeeShadow);
    }
    return undefined;
  }, [role, type]);

  const iconSize = useMemo(() => {
    if (sizePx) return `${sizePx}px`;
    return size === LetterIconSizeEnum.LOGO ? LOGO_SIZE : FAV_SIZE;
  }, [size, sizePx]);

  return (
    <Box
      background={background}
      justify="center"
      align="center"
      width={iconSize}
      data-testid={testId || 'LetterIcon-generate'}
      height={iconSize}
      style={{
        minWidth: iconSize,
        boxShadow: isWithShadow ? boxShadow : undefined,
        transition: 'all 0.2s ease-in-out',
      }}
      round="50%"
    >
      {
        size === LetterIconSizeEnum.LOGO
          ? (
            <Text
              weight="bold"
              size={firstLetter.length > 1 ? 'regular' : '2xl'}
              data-testid="LetterIcon-generate-logo-size"
              color="textOnColor"
            >{firstLetter}
            </Text>
          )
          : (
            <Text
              weight="bold"
              size="small"
              data-testid="LetterIcon-generate-fav-size"
              color="textOnColor"
            >{firstLetter}
            </Text>
          )
      }
    </Box>
  );
};
