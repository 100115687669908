import React, {
  useCallback, useEffect,
  useMemo,   useState, } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import {
  Field, FieldValues,
  WarningLabel,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { useSnackbar } from '@/modules/core/hooks';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { useCreateHealthPlan } from '@/modules/HealthPlan/hooks';
import { HEALTH_PLAN_LIST_QUERY_NAME } from '@/modules/HealthPlan/queries/useHealthPlansList.query.ts';
import { useCreatePlan } from '@/modules/plan/hooks';
import { PlanStatuses } from '@/modules/plan/plan.types';
import { PLAN_LIST_QUERY_NAME } from '@/modules/plan/PlansList/queries/usePlansList.query';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';
import { useFieldsWithDefaultValues } from '@/utils/hooks';

// import { useCheckPaymentOrderQuery } from '@/modules/plan/queries';
import usePlanModalFields from './usePlanModalFields';

interface SourcePlan {
  planName?: string;
  planTemplate?: string[];
  planYear?: string;
  id?: string;
  searchValue?: string,
}
const DEFAULT_SOURCE_PLAN: SourcePlan = {
  planName: '',
  planTemplate: [],
  planYear: '',
  searchValue: '',
};
export default (isVisibleModal?: boolean, onClose?: (value: boolean) => void, isHealthPlanMode?: boolean) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [fieldsValues, setFieldsValues] = useState<SourcePlan[]>([{
    ...DEFAULT_SOURCE_PLAN,
    id: _.uniqueId(),
  }]);
  const [processIdsQueue, setProcessIdsQueue] = useState<string[]>([]);
  const [processModal, setProcessModal] = useState(false);

  const [isSubmit, setSubmit] = useState(false);
  const { handleAddPermanentSnackbar } = useSnackbar();

  useEffect(() => {
    if (isVisibleModal === false) {
      setFieldsValues([{
        ...DEFAULT_SOURCE_PLAN,
        id: _.uniqueId(),
      }]);
      setSubmit(false);
    }
  }, [isVisibleModal]);
  const {
    list: planYearList,
    // data: rawPlanYearsList
  } = usePlanYearsQuery();

  const handleSetSearchTemplateString = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const fields = usePlanModalFields(planYearList, handleSetSearchTemplateString, searchValue, isHealthPlanMode);
  const handleCreatePlan = useCreatePlan(true);
  const handleCreateHealthPlan = useCreateHealthPlan();

  const queryClient = useQueryClient();
  const { params } = useRouteMatch<{ id: string }>();

  const removeMode = useMemo(() => fieldsValues.length > 1, [fieldsValues]);
  const handleSavePlans = useCallback(async () => {
    try {
      await Promise.all(fieldsValues.map(async (item) => {
        if (isHealthPlanMode) { 
          await handleCreateHealthPlan(
            item.planTemplate,
            item.planYear,
            params.id,
            HealthPlanStatuses.DRAFT,
          );
        } else {
          await handleCreatePlan(
            item.planTemplate,
            item.planYear,
            params.id,
            PlanStatuses.READY_FOR_SETUP,
          );
        }
      }));
    } finally {
      if (isHealthPlanMode) {
        handleAddPermanentSnackbar({
          text: 'Health plans have been added.',
          closeIcon: true,
        });
        queryClient.invalidateQueries(HEALTH_PLAN_LIST_QUERY_NAME);
      } else {
        handleAddPermanentSnackbar({
          text: 'Plans have been added.',
          closeIcon: true,
        });
        queryClient.invalidateQueries(PLAN_LIST_QUERY_NAME);
      }

      if (onClose) {
        onClose(false);
      }
    }
  }, [fieldsValues, handleCreatePlan, onClose, params.id, queryClient,
    handleAddPermanentSnackbar, isHealthPlanMode, handleCreateHealthPlan]);

  const handleCancelProcess = useCallback(() => {
    setProcessIdsQueue([]);
    setProcessModal(false);
  }, []);

  const handleConfirmPlans = useCallback(() => {
    handleCancelProcess();
    handleSavePlans();
  }, [handleCancelProcess, handleSavePlans]);

  const handleChangeValues = useCallback((id?: string) => (values: SourcePlan) => {
    const index = fieldsValues.findIndex((item) => item.id === id);
    if (fieldsValues[index]) {
      fieldsValues[index] = {
        ...fieldsValues[index],
        ...values,
      };
    }
  }, [fieldsValues]);

  const handleAddPlan = useCallback(() => {
    setFieldsValues([...fieldsValues, {
      ...DEFAULT_SOURCE_PLAN,
      id: _.uniqueId(),
    }]);
  }, [fieldsValues]);

  const getFieldsById = useCallback((id?: string, isWarningMode?: boolean) => {
    const defaultValues = fieldsValues.find((field) => field.id === id);
     
    const newFields = useFieldsWithDefaultValues(fields, defaultValues);
    return newFields.map((field) => {
      if (field.name === 'planYear' && isWarningMode) {
        return {
          ...field,
          label: (
            <WarningLabel>
              Plan Year
            </WarningLabel>
          ),
        };
      }
      return {
        ...field,
      };
    });
  }, [fields, fieldsValues]);

  const validateFields = useMemo<FieldValues>(() => {
    const value: FieldValues = {};
    const getErrors = (currentFields: Field<string>[]) => {
      currentFields.forEach((field) => {
        if (field.subFields) {
          getErrors(field.subFields);
        }
        value[field.name] = field.validator;
      });
    };
    getErrors(fields);

    return value;
  }, [fields]);

  // const handleCheckPlans = useCallback(async () => {
  //   const idsList: string[] = [];
  //   // eslint-disable-next-line no-restricted-syntax
  //   for (const field of fieldsValues) {
  //     const currentPlanYear = rawPlanYearsList.find((planYear) => planYear.id === field.planYear);
  //     if (currentPlanYear) {
  //       try {
  //         // eslint-disable-next-line no-await-in-loop
  //         await handleCheck({
  //           templateId: field.planTemplate || '',
  //           definitionId: currentPlanYear.payoutDefinition,
  //         });
  //       } catch {
  //         if (field.id) {
  //           idsList.push(field.id);
  //         }
  //       }
  //     }
  //   }

  //   if (idsList.length === 0) {
  //     handleSavePlans();
  //     return;
  //   }

  //   setProcessIdsQueue([...idsList]);
  //   setProcessModal(true);
  // }, [fieldsValues, handleCheck, handleSavePlans, rawPlanYearsList]);

  const handlePresubmit = useCallback(async () => {
    setSubmit(true);

    try {
      await Promise.all(fieldsValues.map(async (field) => {
        const schema = yup.object()
          .shape(validateFields);
        await schema.validate(field, { abortEarly: true });
      }));
      handleSavePlans();
      // handleCheckPlans();

      // if (onClose) onClose(false);
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }, [fieldsValues, handleSavePlans, validateFields]);
  const handleRemoveById = useCallback((id?: string) => () => {
    const filteredList = fieldsValues.filter(
      (item) => item.id !== id,
    );

    setFieldsValues(filteredList);
  }, [fieldsValues]);

  return {
    handleRemoveById,
    handlePresubmit,
    handleAddPlan,
    getFieldsById,
    handleConfirmPlans,
    handleChangeValues,
    handleCancelProcess,
    processIdsQueue,
    processModal,
    removeMode,
    isSubmit,
    fieldsValues,
  };
};
