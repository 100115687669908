import React, { useMemo } from 'react';
import { Text } from '@common-fe/common-fe';

import { PayrollCalendarStatus } from './ScheduledContribution.types';

interface Props {
  value: string;
  calendarStatus?: PayrollCalendarStatus;
  isDeactivated?: boolean;
}
const ScheduledContributionName: React.FC<Props> = ({calendarStatus, value, isDeactivated }) => {
  
  const isCalendarDeactivated = useMemo(() => {
    return calendarStatus === PayrollCalendarStatus.Deactivated;
  }, [calendarStatus]);
  return (
    <Text
      data-testId={isDeactivated ? 'ScheduledContributionName_deactivated' :'ScheduledContributionName_active'}
      title={isCalendarDeactivated ? 'Calendar was deleted' : value}
      color={isDeactivated || isCalendarDeactivated ? 'danger' : 'textBody'}
      weight={700}
      style={{cursor: 'pointer', 
        whiteSpace: 'nowrap',
        overflow:'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {value}
    </Text>
  );
};

export default ScheduledContributionName;
