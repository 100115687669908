import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { employeeDomainType, organizationDomainType,planDomainType } from './LogList';

export interface Attribute {
  entityName: string;
  attributes: string[];
}

const LinkedTemplatesEntityName = 'LinkedTemplates';
const PlanEntityName = 'Plan';
const PlanAccountFundingConfigEntityName = 'PlanAccountFundingConfig';
const PlanCoverageConfigEntityName = 'PlanCoverageConfig';
const PlanPrimaryConfigEntityName = 'PlanPrimaryConfig';

interface AttributePayload {
  entity_name?: string;
  attributes?: string[];
}

const formatData = (data: AttributePayload[]) => data.map((item) => ({
  entityName: item.entity_name || '',
  attributes: item.attributes || [],
}));

const useGetAttributesListQuery = (domainType: string) => {
  const { data, isLoading, isSuccess } = useQuery(
    [PATHS.ATTRIBUTES_LIST, domainType],
    () => api.get<AttributePayload[]>(
      PATHS.ATTRIBUTES_LIST, { params: { domain_type: domainType } },
    ),
  );
  
  const filteredFormattedData = useMemo(() => {
    const list = data?.data || [];
    switch (domainType) {
    case employeeDomainType: {
      return list.filter((item) => 
        item.entity_name !== PlanAccountFundingConfigEntityName &&
        item.entity_name !== PlanCoverageConfigEntityName &&
        item.entity_name !== PlanPrimaryConfigEntityName
      );
    }
    case planDomainType: {
      return list.filter((item) => 
        item.entity_name !== LinkedTemplatesEntityName
      );
    }
    case organizationDomainType: {
      return list.filter((item) => 
        item.entity_name !== PlanAccountFundingConfigEntityName &&
        item.entity_name !== PlanCoverageConfigEntityName &&
        item.entity_name !== PlanPrimaryConfigEntityName
      );
    }
    default: {
      return list;
    }}
  }, [domainType, data]);

  return {
    attributesList: formatData(filteredFormattedData) as Attribute[],
    isLoading,
    isSuccess
  };
};

export default useGetAttributesListQuery;
