import React from 'react';
import {
  AppButton,
  Box, Preloader, Text, } from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  onCancel?: () => void;
  disable?: boolean;
  unconfirmed?: boolean;
  onSubmit?: () => void;
  loading?: boolean;
  testId?: string;

}
const Footer: React.FC<Props> = ({
  onCancel, disable, onSubmit, loading, unconfirmed, testId = '',
}) => (

  <Box direction="column">
    { unconfirmed && (
      <Box direction="row" data-testid={`${testId}_uncorfirmed_error`} justify="end">
        <Text color="danger">Please confirm that you have provided the right plan type and plan year.</Text>
      </Box>
    )}

    <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
      <Box width="dateControl" margin={{ right: 'spacing6' }}>
        <AppButton
          testId={`${testId}_cancel`}
          buttonType="secondary"
          onClick={onCancel}
          width="100%"
        >
          Cancel
        </AppButton>
      </Box>
      <Box width="dateControl" margin={{ left: 'spacing6' }}>
        <AppButton
          testId={`${testId}_submit`}
          onClick={onSubmit}
          disabled={loading}
          width="100%"
        >
          {loading ? (
            <Box align="center" justify="center">
              <Preloader
                data-testid={`${testId}_loading`}
                color={theme.colors.iconOnColor}
              />
            </Box>
          ) : 'Next'}
        </AppButton>
      </Box>
    </Box>
  </Box>
);
export default Footer;
