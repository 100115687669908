import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { IrsLimitTypesLabel } from '@/modules/plan/PlanSetup/PlanSetup.constants';

import { COVERAGE_LEVELS } from './useIRSMaximum.query';

export interface IRSPayload {
  type: string
  irs_coverage_level_type: string;
}

export const IRS_MAXIMUM_TEMPLATE_TYPES_QUERY_KEY = 'getIRSMaximumTemplateTypes';

export default (
  isTemplateMode?: boolean,
  isRolover?: boolean,
) => {
  const {
    isLoading, isSuccess, isError, data, error,
  } = useQuery([IRS_MAXIMUM_TEMPLATE_TYPES_QUERY_KEY, isRolover, isTemplateMode],
    () => api.get<IRSPayload[]>(PATHS.IRS_MAXIMUM_TYPES, {
      params: _.pickBy({
        coverage_level_type: isRolover ? COVERAGE_LEVELS.ROLLOVER : COVERAGE_LEVELS.FAMILY,
      }),
    }), {
      enabled: !!isTemplateMode,
      refetchOnMount: false,
    });

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list?.map((item) => ({
      key: item.type,
      value: IrsLimitTypesLabel[item.type as keyof typeof IrsLimitTypesLabel],
      additionalValue: 0,
    }));
  }, [data]);

  return ({
    data: formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
  });
};
