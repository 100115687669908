import React from 'react';
import { Box, InfoOutlineIcon, Inscription, Text } from '@common-fe/common-fe';

export const ContributionsLimitInfoBanner = () => (
  <Box
    data-testid="contribution-limit-info"
    round="container1Round"
    background="canvas"
    pad={{ horizontal: 'spacing12', bottom: 'spacing12' }}
    border={{ color: 'border2' }}
    margin={{ top: 'spacing24' }}
  >
    <Box
      round="small"
      height="2px"
      background="info"
    />
    <Box
      margin={{ top: 'spacing12' }}
      pad={{ horizontal: 'spacing24', vertical: 'spacing16', right: 'spacing48' }}
      border={{ color: 'infoBorder' }}
      round="fieldRound"
      background={{ color: 'infoContainer' }}
    >
      <Box
        direction="row"
        align="center"
      >
        <InfoOutlineIcon color="info" />
        <Inscription margin={{ left: 'spacing8' }} weight={700}>
          Here you can set up a contribution general setting for all employees.
        </Inscription>
      </Box>

      <Inscription margin={{ top: 'spacing8' }}>
        But you can change it for each specific employee in the next step.
      </Inscription>
    </Box>
  </Box>
);
