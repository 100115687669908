import React, { useMemo } from 'react';
import {
  Box,
  CoveragePeriods,
  FlexListHeader,
  Inscription,
} from '@common-fe/common-fe';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

import { ListItemBase } from '@/common/types';
import currentTheme from '@/styles/theme';

const Wrapper = styled(Box)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }
`;

const CoveragePeriodsTable: React.FC<{
  coveragePeriods: CoveragePeriods[],
}> = ({ coveragePeriods }) => {
  const headers: ListItemBase[] = useMemo(() => {
    const currentHeaders = [{
      key: 'period',
      title: 'Period',
    }];
    return currentHeaders;
  }, []);
  return (
    <Box
      data-testId="coverage-periods-wrapper"
      background="module"
      pad="xs"
      round="small"
      margin={{ vertical: 'xs' }}
    >
      <FlexListHeader items={headers} thinMode />
      {coveragePeriods.map((item, index) => (
        <Wrapper
          key={uniqueId()}
          height="42px"
          elevation="default"
          background="canvas"
          round={currentTheme.rounds.button1Round}
          margin={{ top: index === 0 ? 'xs' : '0px' }}
          pad={{ vertical: 's', horizontal: 'l' }}
          direction="row"
          align="center"
        >
          <Box height="24px" direction="row" align="center">
            <Inscription size="12px" lineHeight="18px" style={{ fontWeight: 500 }}>{item.startDate}</Inscription>
            &nbsp;-&nbsp;
            <Inscription size="12px" lineHeight="18px" style={{ fontWeight: 500 }}>{item.endDate}</Inscription>
          </Box>
        </Wrapper>
      ))}
    </Box>
  );
};

export default CoveragePeriodsTable;
