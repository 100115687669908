import { storeCreator } from '@/utils/store/store.utils';

interface State {
  loading: boolean
  setLoading: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  loading: true,
  setLoading: (value: boolean) => set(() => ({
    loading: value,
  })),
}));

export default useStore;
