import React, { useCallback } from 'react';

import ModalWrapper from '@/components/wrappers/ModalWrapper';

import { AddDependentForm } from './AddDependentForm';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
}

export const AddDependentModal: React.FC<Props> = ({ visible, onSetVisible }) => {
  const onCancel = useCallback(() => onSetVisible(false), [onSetVisible]);
  return (
    <ModalWrapper
      visible={visible}
      onSetVisible={onCancel}
      title="Add new dependent"
    >
      <AddDependentForm onCancel={onCancel} />
    </ModalWrapper>
  );
};
