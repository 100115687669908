import React from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';
import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { HEALTH_PLAN_MODULES } from '../../HealthPlan.constants';
import { HealthPlanStatuses } from '../../HealthPlan.types';

import ActivatePlan from './ActivateHealthPlan';
import FinalSetUpModal from './FinalSetupModal';
import { useHandleHealthPlanSetUp } from './hooks';
import SaveHealthPlan from './SaveHealthPlan';
import ValidationNode from './ValidationNode';

interface Props {
  isEditMode?: boolean;
  planStatus?: HealthPlanStatuses;
  isTemplateMode?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement>;
  activateButtonRef?: React.RefObject<HTMLButtonElement>;
  reviewButtonRef?: React.RefObject<HTMLButtonElement>;
}
const FinalSetup: React.FC<Props> = ({
  isEditMode, planStatus, isTemplateMode,
  saveButtonRef,
  activateButtonRef,
  reviewButtonRef,
}) => {
  const {
    onSaveDraftHandler,
    onSubmitForReviewHandler,
    onUpdateHandler,
    onActivateHandler,

    errorMessages,
    successMessages,
    pendingMessages,

    activateErrorMessages,
    activateSuccessMessages,
    activatePedingMessages,
  } = useHandleHealthPlanSetUp(isTemplateMode);
  const isHasAccess = useHasAccess([
    {
      permission: Permissions.EDIT_DRAFT_PLAN,
    },
    {

      permission: Permissions.ACTIVATE_PLAN,
    },
  ]);
  if (planStatus === HealthPlanStatuses.ACTIVE
    || planStatus === HealthPlanStatuses.PENDING_ACTIVE
    || !isHasAccess) {
    return null;
  }
  return (
    <ModuleWrapper anchorId={HEALTH_PLAN_MODULES.FINAL_SETUP} title="Final Setup" expandless={false}>
      <ValidationNode isTemplateMode={isTemplateMode} />
      <Box
        pad="spacing8"
        round="container1Round"
        direction="row"
        align="stretch"
        flex="grow"
      >
        <FinalSetUpModal
          mode={isTemplateMode ? 'Health Plan Template' : 'Health Plan'}
          handleSave={onSaveDraftHandler}
          handleSubmit={onSubmitForReviewHandler}
          handleUpdate={onUpdateHandler}
          handleActivate={onActivateHandler}
        />

        <SaveHealthPlan
          saveButtonRef={saveButtonRef}
          reviewButtonRef={reviewButtonRef}
          errorMessages={errorMessages}
          successMessages={successMessages}
          pendingMessages={pendingMessages}
          isTemplateMode={isTemplateMode}
          viewMode={!isEditMode}
          onSave={onSaveDraftHandler}
          onSubmit={onSubmitForReviewHandler}
        />
        <Box pad="spacing8" />

        <ActivatePlan
          activateButtonRef={activateButtonRef}
          errorMessages={activateErrorMessages}
          successMessages={activateSuccessMessages}
          pendingMessages={activatePedingMessages}
          isTemplateMode={isTemplateMode}
          viewMode={!isEditMode}
          onActivate={onActivateHandler}
        />
      </Box>
    </ModuleWrapper>
  );
};

export default FinalSetup;
