import React, { useEffect, useMemo } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useGetOrganizationById } from '@/modules/core/hooks';

import { useGetEmployeeById } from '../../hooks';

import useCustomReportsFields from './hooks/useCustomReportsFields';
import useStore, { CustomReportsPayload } from './stores/useCustomReports.store';

interface Props {
  isShowErrors?: boolean;
  isEditMode?: boolean;
}
const CustomReports: React.FC<Props> = ({ isEditMode, isShowErrors }) => {
  const { data: employeeData } = useGetEmployeeById();
  const { formattedData } = useGetOrganizationById(employeeData?.organizationId);

  const handleSetState = useStore((state) => state.setState);
  const fields = useCustomReportsFields({
    isEditMode,

    customReportingField1Name: formattedData?.overridableProperties?.customReportingField1Name,
    customReportingField2Name: formattedData?.overridableProperties?.customReportingField2Name,
    customReportingField3Name: formattedData?.overridableProperties?.customReportingField3Name,
    customReportingField4Name: formattedData?.overridableProperties?.customReportingField4Name,
    customReportingField5Name: formattedData?.overridableProperties?.customReportingField5Name,

    isCustomReportingField1Enabled: formattedData?.overridableProperties?.isCustomReportingField1Enabled,
    isCustomReportingField2Enabled: formattedData?.overridableProperties?.isCustomReportingField2Enabled,
    isCustomReportingField3Enabled: formattedData?.overridableProperties?.isCustomReportingField3Enabled,
    isCustomReportingField4Enabled: formattedData?.overridableProperties?.isCustomReportingField4Enabled,
    isCustomReportingField5Enabled: formattedData?.overridableProperties?.isCustomReportingField5Enabled,
  });
  const isHidden = useMemo(() => {
    const {
      isCustomReportingField1Enabled,
      isCustomReportingField2Enabled,
      isCustomReportingField3Enabled,
      isCustomReportingField4Enabled,
      isCustomReportingField5Enabled,
    } = formattedData?.overridableProperties || {};
    return (
      !isCustomReportingField1Enabled &&
      !isCustomReportingField2Enabled &&
      !isCustomReportingField3Enabled &&
      !isCustomReportingField4Enabled &&
      !isCustomReportingField5Enabled
    );
  }, [formattedData?.overridableProperties]);
  const { setValues, values } = useDebounceState<Partial<CustomReportsPayload>>();
  
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  if(isHidden) {
    return null;
  }
  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        formTitle="Custom Reporting"
        testId="custom-reporting"
        fields={fields}
        showError={isShowErrors}
        editMode
        onChangeValues={setValues}
        // onDirty={onDirty}
        isDisabledMode={!isEditMode}
        isThinMode
      />
    </Box>
  );
};

export default CustomReports;
