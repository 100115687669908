import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT, YES_KEY } from '@/common/constants';
import { Option } from '@/common/types';
import { useFieldsWithDefaultValues } from '@/utils';

import { MainInfo } from '../CreateProcessingRule.types';

const PAYMENT_VALUE_KEY = 'paymentValue';

export const AMOUNT_KEY = 'AMOUNT';
export const PERCENTAGE_KEY = 'PERCENTAGE';

export default (
  defaultValues?: Partial<MainInfo>,
  serviceTypeOptions?: Option[],
  errors?: Partial<MainInfo>,
) => {
  const fields = useMemo<Field[]>(() => [
    {
      name: 'serviceType',
      type: FieldTypes.Dropdown,
      label: 'Service type',
      singleMode: true,
      options: serviceTypeOptions,
      placeholder: 'Select option',
      showRequireIcon: true,
      isNarrowLabelMode: true,
      validator: yup.string().test({
        test: (val) => val !== errors?.serviceType || !errors?.serviceType,
        message: 'Configuration for this service type already exists',
      }).required(REQUIRED_TEXT),
    },
    {
      name: 'description',
      type: FieldTypes.Text,
      label: 'Description',
      placeholder: 'Enter description',
    },
    {
      name: PAYMENT_VALUE_KEY,
      type: FieldTypes.Radio,
      label: 'Specify payment value?',
      showRequireIcon: true,
      validator: VALIDATORS.REQUIRED_STRING,
      options: [
        {
          label: 'Yes',
          value: YES_KEY,
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      subFields: [
        {
          name: 'claimValue',
          parentValue: YES_KEY,
          showRequireIcon: true,
          type: FieldTypes.Currency,
          label: 'Matching claim value',
          placeholder: 'Enter $ amount',
          validator: yup.string().when(PAYMENT_VALUE_KEY, {
            is: (val: string) => val === YES_KEY,
            then: VALIDATORS.REQUIRED_STRING,
            otherwise: VALIDATORS.STRING,
          }),
        },
      ],
    },
    {
      name: 'applyToDeductible',
      type: FieldTypes.Radio,
      label: 'Apply to deductible?',
      showRequireIcon: true,
      validator: VALIDATORS.REQUIRED_STRING,
      options: [
        {
          label: 'Yes',
          value: YES_KEY,
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
    {
      name: 'paymentType',
      type: FieldTypes.Radio,
      label: 'Payment type',
      showRequireIcon: true,
      validator: VALIDATORS.REQUIRED_STRING,
      options: [
        {
          label: 'Amount',
          value: AMOUNT_KEY,
        },
        {
          label: 'Percentage',
          value: PERCENTAGE_KEY,
        },
      ],
    },
  ], [
    errors,
    serviceTypeOptions,
  ]);

  return useFieldsWithDefaultValues(fields, defaultValues);
};
