import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

interface Props {
  title: string;
  subTitle: string;
}

const FinancialHeader: React.FC<Props> = ({ title, subTitle }) => (
  <Box direction="column">
    <Text size="xxlarge" weight={400}>{title}</Text>
    <Text
      size="medium"
      weight={400}
      margin={{ top: 'xxs' }}
    > {subTitle}
    </Text>
  </Box>
);

export default FinancialHeader;
