import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown, FlexList,  PaginationBar,
  SearchInput, SelectDropdown, Text, usePaginationParams,
} from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import ROUTES from '@/common/routes';
import { ListItemBase, OptionKey } from '@/common/types';
import { useAllOptionsToggle } from '@/modules/core/hooks';
import { EmptyTransactionsListPlaceholder } from '@/modules/transaction/TransactionsList/EmptyTransactionsListPlaceholder';

import TransactionItem from '../TransactionItem';

import { ActivityRow } from './queries/useActivitiesList.query';
import { useActivitiesDatesRange, useActivitiesList } from './hooks';
import { ProcessingActivitiesList } from './ProcessingActivitiesList';

const DEFAULT_PAGE_SIZE = 10;

const HEADERS: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 5.3,
  },
  {
    key: 'status',
    title: '',
    flex: 3,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 2,
  },
  {
    key: 'statusDate',
    title: 'Date',
    flex: 0.7,
    endAlign: true,
  },
  {
    key: 'option',
    title: '',
    flex: 0.5,
  }];


interface Props {
  hideHeader?: boolean
  id: string;
  title?: string;
  disabled?: boolean;
}
const ActivitiesList: React.FC<Props> = ({
  id, title, hideHeader, disabled,
}) => {
  const [types, setTypes] = useState<OptionKey[]>([ALL_OPTION.value]);

  const { push } = useHistory();
  const [initialData, setInitialData] = useState(false);
  const { id: employeeId, enrollmentId } = useParams<{ id: string, enrollmentId: string }>();
  const { onChangeDates, formatedDates } = useActivitiesDatesRange();

  const typesValues = useAllOptionsToggle(types);

  const hasTypes = useMemo(
    () => typesValues.length > 0
      && !typesValues.some((type) => type === ALL_OPTION.value),
    [typesValues],
  );

  const {
    page, setPage,
    setPerPage, perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams(DEFAULT_PAGE_SIZE);
  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, types, formatedDates.length]);

  const {
    isLoading,
    total,
    data,
    availableTypes,
    dataOnlyHolds,
  } = useActivitiesList(
    {
      id,
      employeeId,
      page: page - 1,
      perPage,
      searchString: currentSearch || '',
      ...(hasTypes ? { types: typesValues } : {}),
      startDate: formatedDates[0],
      endDate: formatedDates[1],
    },
  );
  const typesOptions = useMemo(() => [ALL_OPTION, ...availableTypes], [availableTypes]);

  const isAnyInputFilled = useMemo(() => searchString.length
  || types.length
  || formatedDates.length,
  [
    formatedDates.length,
    types.length,
    searchString.length,
  ]);
  const isDataInclude = useMemo(() => !!data.length, [data]);
  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);

  const handlePickTransaction = useCallback((item: ActivityRow) => {
    push(
      item?.claimId
        ? ROUTES.TRANSACTION_ENROLLMENT_DETAILS_CLAIM(
          employeeId,
          enrollmentId,
          item?.claimId,
        )
        : ROUTES.TRANSACTION_ENROLLMENT_DETAILS_POSTING(
          employeeId,
          enrollmentId,
          item?.postingId,
        ),
    );
  }, [employeeId, enrollmentId, push]);

  useEffect(() => {
    isInitialData();
  }, [isInitialData]);

  return (
    <Box>
      {(!isLoading && !!dataOnlyHolds.length) && (
        <Box margin={{ bottom: 'spacing24' }}>
          <ProcessingActivitiesList id={id} data={dataOnlyHolds} employeeId={employeeId} />
        </Box>
      )}
      <Box
        direction="column"
        data-testid="TransactionsList"
        background="module"
        round="container1Round"
        pad={{ bottom: initialData ? 'spacing12' : 'spacing24' }}
      >
        <Box
          direction="row"
          data-testid={`activities-list-${initialData}`}
          align="center"
          margin={{ bottom: 'spacing4' }}
          pad={{ horizontal: 'spacing24', top: 'spacing24' }}
        >
          <Box direction="row" style={{ flex: 1 }}>
            <Text size="large" color="textTitle" weight="bold">{title || 'Expenses'}</Text>
          </Box>
          <Box width="largeControl" margin={{ left: 'spacing8' }}>
            <SearchInput
              disabled={!initialData || disabled}
              value={searchString}
              placeholder="Search"
              hasSearchingHistory
              onChange={setSearchString}
            />
          </Box>
          <Box width="control" margin={{ left: 'spacing8' }}>
            <SelectDropdown
              disabled={!initialData || disabled}
              id="type"
              allMode
              name="Activity type"
              prefix="Type"
              options={typesOptions}
              values={typesValues}
              onChangeValues={setTypes}
            />
          </Box>
          <Box width="control" margin={{ left: 'spacing8' }}>
            <DateRangeDropdown
              ellipsisMode
              disabled={!initialData || disabled}
              prefix="Period:"
              className="period-picker"
              onChange={onChangeDates}
              startDate={formatedDates[0]}
              endDate={formatedDates[1]}
              icon={(
                <Box
                  align="center"
                  justify="center"
                  margin={{ bottom: !initialData ? 'spacing4' : undefined }}>
                  {!initialData ? (
                    <CalendarPickerIcon />
                  ) : (
                    <CalendarIcon color="iconPrimary" />
                  )}
                </Box>
              )}
            />
          </Box>
        </Box>
        <FlexList
          headers={initialData && !hideHeader ? HEADERS : []}
          rows={[]}
          total={100}
          moreCount={perPage}
          loading={isLoading}
          footer={(
            <Box direction="column">
              {!isDataInclude && (
                <EmptyTransactionsListPlaceholder title="Activities" />
              )}
              {data.map((item) => (
                <TransactionItem
                  key={item?.postingId || item?.claimId}
                  category={item.category}
                  type={item?.transactionType}
                  title={item.title}
                  amount={item.amount}
                  status={item.status}
                  date={item.date}
                  onPick={() => handlePickTransaction(item)}
                />
              ))}
              {isDataInclude && (
                <PaginationBar
                  page={page}
                  total={total}
                  pageSize={perPage}
                  onChangePage={setPage}
                  onChangePageSize={setPerPage}
                />
              )}
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ActivitiesList;
