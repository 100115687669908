import React, { useEffect, useMemo } from 'react';
import {
  Box, FlexControlledForm, useDebounceState,
} from '@common-fe/common-fe';

import ToEditButton from '@/modules/employer/components/SetupEmployer/components/ToEditButton';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { useSetupEmployerMode } from '../../hooks/useSetupEmployerMode';
import { CommunicationChannelPayload } from '../Communication.types';
import { useCommunicationChannelForm } from '../hooks';
import { useCommunicationChannelStore } from '../stores';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const CommunicationChannel: React.FC<Props> = ({ onDirty }) => {
  const { formattedData, isLoading } = useGetEmployer();
  const handleSetState = useCommunicationChannelStore((state) => state.setState);
  const { viewMode } = useSetupEmployerMode();
  const fields = useCommunicationChannelForm(formattedData, isLoading);
  const { setValues, values } = useDebounceState<Partial<CommunicationChannelPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || formattedData?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, formattedData?.organizationStatusType]);

  if (isLoading) return <FormLoader testId="CommunicationChannel" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      margin={{ bottom: 'spacing24' }}
      data-testid="Communication-сhannel-form-wrapper"
      id="communication_channels"
    >
      <FlexControlledForm
        editMode={!viewMode && !isEditRestricted}
        formTitle="Communication methods"
        fields={fields}
        onChangeValues={setValues}
        onDirty={onDirty}
        isThinMode
      >
        {isEditRestricted ? null : <ToEditButton />}
      </FlexControlledForm>
    </Box>
  );
};
