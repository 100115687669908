import React, { useMemo } from 'react';
import {
  Box,
  Inscription,
  ListItem,
  // OutlinedCompleteIcon,
} from '@common-fe/common-fe';

import { ListItemType, PaginationParams } from '@/common/types';
import InfoTileIcon from '@/components/elements/InfoTile/InfoTileIcon';
import ManyItemsTooltip from '@/components/elements/ManyItemsTooltip/ManyItemsTooltip';
import Tip from '@/components/elements/Tip/Tip';
import theme from '@/styles/theme';

import { ACCOUNT_TYPES_OPTIONS } from './CreateClaimsTeamModal/hooks/useCreateClaimsTeamFields';
import { InfoTileIconWrapper } from './ClaimsProcessors.styles';
import useTeamsListQuery from './useTeamsList.query';

const MAX_TEAM_MEMBERS = 3;
export const ALL_ACCOUNT_TYPES_LENGTH = 10;

const preparedAccountTypes = (accountTypes: string[] = []) => {
  return accountTypes.map((type) => ACCOUNT_TYPES_OPTIONS.find((option) => option.value === type)?.title || type);
};

const highlightedRowStyle = {
  borderSize: '2px',
  borderColor: theme.colors.accentActive,
  boxShadow: theme.shadows.highlighted['box-shadow'],
};

const useTeamsList = (params: PaginationParams, justAddedTeamId?: string) => {
  const { teams, isLoading, total, refetch } = useTeamsListQuery(params);

  const formattedList = useMemo(() => teams
    ?.map((item) => ({
      id: item.id,
      rowStyle: (justAddedTeamId && justAddedTeamId === item.id) ? highlightedRowStyle : undefined,
      fields: [
        {
          key: 'team',
          type: ListItemType.Node,
          node: (
            <Box direction="row" align="center">
              <Inscription color="textTitle" weight="bold">{item?.name}</Inscription>
              {/* {item?.isDefault ? (
                <Box margin={{ left: 'spacing4' }} background="success" width="14px" height="14px" justify="center" align="center" round="4px">
                  <OutlinedCompleteIcon size="14px" color="canvas" />
                </Box>
              ) : null} */}
            </Box>
          ),
        },
        {
          key: 'accountType',
          type: ListItemType.Node,
          node: (
            <ManyItemsTooltip
              color="textBody"
              items={preparedAccountTypes(item?.allowedAccountTypes) || []}
              isAllMode={item?.allowedAccountTypes?.length === ALL_ACCOUNT_TYPES_LENGTH}
              isWithSpacingRight
              itemsTextStyles={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
              }}
            />
          )
        },
        {
          key: 'teamMembers',
          type: ListItemType.Node,
          node: (
            <Box direction="row">
              {item?.teamMembers?.slice(0, MAX_TEAM_MEMBERS).map((member, index) => (
                <Box key={member?.id} style={{ zIndex: 100 - index }} {...index ? { margin: { left: '-8px' } } : {}}>
                  <InfoTileIcon hasBorder icon={member?.icon} title={member?.fullName} />
                </Box>
              ))}
              {typeof item?.teamMembers?.length === 'number' && item?.teamMembers?.length > MAX_TEAM_MEMBERS ? (
                <Tip items={item.teamMembers.map((member) => member.fullName || '').slice(MAX_TEAM_MEMBERS)} space="42px">
                  <InfoTileIconWrapper
                    hasBorder
                    height="40px"
                    width={{ min: '40px' }}
                    margin={{ left: '-8px' }}
                    justify="center"
                    align="center"
                    background="border1"
                  >
                    <Inscription
                      size="small"
                      color="textBody"
                    >
                      +{item?.teamMembers?.length - MAX_TEAM_MEMBERS}
                    </Inscription>
                  </InfoTileIconWrapper>
                </Tip>
              ) : null}
              {item?.teamMembers?.length === 0 && '-'}
            </Box>
          ),
        },
      ],
    })),
  [justAddedTeamId, teams]);

  return {
    list: formattedList as ListItem[],
    teams,
    refetch,
    isLoading,
    total,
  };
};

export default useTeamsList;
