import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export enum TipSide {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}

interface CustomPosition {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

interface WrapperProps {
  side: TipSide;
  customPosition?: CustomPosition;
}

const Content = styled(Box)<WrapperProps>`
  min-width: 200px;
  max-width: initial;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    ${({ side }) => {
    if (side === TipSide.left) {
      return {
        left: 'calc(100% - 5px)',
        bottom: 'calc(50% - 5px)',
        'border-radius': '0 0 3px 0',
      };
    }
    if (side === TipSide.right) {
      return {
        right: 'calc(100% - 5px)',
        bottom: 'calc(50% - 5px)',
        'border-radius': '3px 0 0 0',
      };
    }
    if (side === TipSide.top) {
      return {
        right: 'calc(50% - 5px)',
        top: 'calc(100% - 5px)',
        'border-radius': '0 0 0 3px',
      };
    }
    if (side === TipSide.bottom) {
      return {
        right: 'calc(50% - 5px)',
        bottom: 'calc(100% - 5px)',
        'border-radius': '0 3px 0 0',
      };
    }
    return {};
  }
};
    background-color: ${({ theme }) => theme.colors.legend};
    transform: rotate(-45deg);
  } 
`;

const PopupWrapper = styled(Box)<WrapperProps>`
  position: absolute;
  ${({ side, customPosition }) => {
    if (side === TipSide.left) {
      return {
        right: customPosition?.right || 'calc(100% + 12px)',
        bottom: customPosition?.bottom || '-50%',
      };
    }
    if (side === TipSide.right) {
      return {
        left: customPosition?.left || 'calc(100% + 12px)',
        bottom: customPosition?.bottom || '-50%',
      };
    }
    if (side === TipSide.top) {
      return {
        bottom: customPosition?.bottom || 'calc(100% + 12px)',
        left: customPosition?.left || '-25%',
      };
    }
    if (side === TipSide.bottom) {
      return {
        top: customPosition?.top || 'calc(100% + 12px)',
        left: customPosition?.left || '-25%',
      };
    }
    return {};
  }
};
  z-index: 10000;
  display: none;
  max-width: fit-content;
  animation: myAnim .3s ease 0s 1 normal forwards;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

interface WrapProps {
  isActive?: boolean;
}

const Wrap = styled(Box)<WrapProps>`
  position: relative;
  &:hover {
    ${PopupWrapper} {
      display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    }
  }
`;

interface Props {
  description: string | React.ReactNode;
  side?: TipSide;
  isActive?: boolean;
  customPosition?: CustomPosition;
  children?: React.ReactNode;
}

export const TipWrapper: React.FC<Props> = ({
  children,
  description,
  side = TipSide.top,
  isActive = true,
  customPosition,
}) => (
  <Wrap isActive={isActive}>
    {children}
    <PopupWrapper side={side} customPosition={customPosition}>
      <Content
        side={side}
        pad={{ horizontal: 'spacing16', vertical: 'spacing12' }}
        round="container1Round"
        background="background3"
      >
        <Box>
          <Box justify="center" align="center">
            <Text weight={500} size="small" color="textOnColor" textAlign="center" style={{ lineHeight: '18px' }}>
              {description}
            </Text>
          </Box>
        </Box>
      </Content>
    </PopupWrapper>
  </Wrap>
);
