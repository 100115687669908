import React, { useCallback, useMemo } from 'react';
import { Box, FlexList, PaginationBar } from '@common-fe/common-fe';

import { LockboxListItem } from '@/modules/employer/components/Lockbox/lockbox.types';
import LockboxListHeader from '@/modules/employer/components/Lockbox/LockboxList/components/LockboxListHeader';
import useListPagination from '@/modules/employer/components/Lockbox/LockboxList/hooks/useListPagination';
import useLockboxList, { LIST_HEADERS } from '@/modules/employer/components/Lockbox/LockboxList/hooks/useLockboxList';
import useLockboxesQuery from '@/modules/employer/components/Lockbox/queries/useLockboxes.query';
import { checkIsAllOptionSelected, stateAllToggling } from '@/utils/handlers/dropdownStateAllToggling';
import { convertToIds, LIST_ITEMS_KEY, LOADED_PAGES_KEY, QUERY_PARAMS_KEY } from '@/utils/hooks/useNavigationByList';

const DEFAULT_PAGE_SIZE = 50;

const LockboxList = () => {
  const {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    statuses,
    setStatuses,
    types,
    setTypes,
  } = useListPagination(DEFAULT_PAGE_SIZE);

  const statusesAfterToggling = useMemo(() => stateAllToggling(statuses), [statuses]);
  const typesAfterToggling = useMemo(() => stateAllToggling(types), [types]);
  const isAllStatusesSelected = useMemo(() => checkIsAllOptionSelected(statuses), [statuses]);
  const isAllTypesSelected = useMemo(() => checkIsAllOptionSelected(types), [types]);

  const params = useMemo(() => ({
    page: page - 1,
    perPage,
    searchString: currentSearch,
    types: isAllTypesSelected ? [] : typesAfterToggling,
    statuses: isAllStatusesSelected ? [] : statusesAfterToggling,
  }), [page, perPage, currentSearch, isAllTypesSelected, typesAfterToggling, isAllStatusesSelected, statusesAfterToggling]);

  const handleSuccess = useCallback((lockboxList?: LockboxListItem[]) => {
    if (lockboxList) {
      const listIds = convertToIds(lockboxList);
      sessionStorage.setItem(LIST_ITEMS_KEY, JSON.stringify(listIds));
      sessionStorage.setItem(QUERY_PARAMS_KEY, JSON.stringify(params));
      sessionStorage.setItem(LOADED_PAGES_KEY, JSON.stringify([`${params.page}`]));
    }
  }, [params]);

  const {
    total,
    formattedData,
    isLoading
  } = useLockboxesQuery({
    ...params,
    enabled: true,
    onSuccess: handleSuccess,
  });

  const { fields } = useLockboxList({ lockboxes: formattedData });

  return (
    <Box
      data-testId="lockbox-list"
      background="module"
      round="container1Round"
      pad="spacing24"
    >
      <LockboxListHeader
        total={total}
        searchString={searchString}
        setSearchString={setSearchString}
        statuses={statusesAfterToggling}
        setStatuses={setStatuses}
        types={typesAfterToggling}
        setTypes={setTypes}
      />

      <Box margin={{ top: 'spacing24' }}>
        <FlexList
          total={total}
          headers={LIST_HEADERS}
          rows={fields}
          loading={isLoading}
          pad={{ horizontal: '0px', vertical: '0px' }}
          moreCount={perPage}
          footer={(
            <PaginationBar
              total={total}
              page={page}
              pageSize={perPage}
              onChangePage={setPage}
              onChangePageSize={setPerPage}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default LockboxList;
