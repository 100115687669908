import { baseStoreCreator } from '@/utils/store';

export interface PlanTemplatePayload {
  templateName: string;
  parentTemplate: string;
  planTypes: string[];

}

export const DEFAULT_VALUES: PlanTemplatePayload = {
  templateName: '',
  parentTemplate: '',
  planTypes: [],
};

export const useStore = baseStoreCreator<PlanTemplatePayload>(DEFAULT_VALUES);

export default useStore;
