import { ALL_OPTION } from '@/common/constants';

import { TaxType } from '../Contributions/contributions.types';

export enum ContributionActivityStatus {
  INITIATED = 'INITIATED',
  VALIDATED = 'VALIDATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  SKIPPED = 'SKIPPED',
}

export interface ContributionActivitiPayload {
  processing_status?: ContributionActivityStatus;
  plan_code?: string;
  plan_name?: string;
  tax_type?: TaxType;
  tax_year?: string;
  employee_id?: number;
  employee_full_name?: string;
  contribution_date_on?: string;
  ee_contribution_amount?: number;
  er_contribution_amount?: number;
  memo?: string;
  subsidiary_name?: string;
}

export interface ContributionActiviti {
  processingStatus?: ContributionActivityStatus;
  planCode?: string;
  planName?: string;
  taxType?: TaxType;
  taxYear?: string;
  employeeId?: string;
  employeeFullName?: string;
  contributionDateOn?: string;
  employeeContributionAmount?: string;
  employerContributionAmount?: string;
  memo?: string;
  subsidiaryName?: string;
}

export const CONTRIBUTION_ACTIVITY_STATUS_OPTIONS = [
  ALL_OPTION,
  {
    key: ContributionActivityStatus.INITIATED,
    value: ContributionActivityStatus.INITIATED,
    title: 'Initiated',
  },
  {
    key: ContributionActivityStatus.VALIDATED,
    value: ContributionActivityStatus.VALIDATED,
    title: 'Validated',
  },
  {
    key: ContributionActivityStatus.PROCESSING,
    value: ContributionActivityStatus.PROCESSING,
    title: 'Processing',
  },
  {
    key: ContributionActivityStatus.COMPLETED,
    value: ContributionActivityStatus.COMPLETED,
    title: 'Completed',
  },
  {
    key: ContributionActivityStatus.ERROR,
    value: ContributionActivityStatus.ERROR,
    title: 'Error',
  },
  {
    key: ContributionActivityStatus.SKIPPED,
    value: ContributionActivityStatus.SKIPPED,
    title: 'Skipped',
  },
];
