import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { removeEmptyObjects } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { REGEXPS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';
import Permissions from '@/common/permissions';
import {
  GlobalLoaderMessages,
  useGlobalLoaderStore,
} from '@/components/elements/FullLoader/store/useGlobalLoader.store';
import { useHasAccess } from '@/modules/core/hooks';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { EmployeeAddressType, EmployeePayload, EmployeePhoneNumber } from '../Employee.types';
import useUpdateEmployeeAutopayPropertiesQuery from '../PersonalInformation/HealthPlanClaims/queries';
import useEmployeeClaimAutopayStore from '../PersonalInformation/HealthPlanClaims/stores';
import { usePersonalInformationState } from '../PersonalInformation/hooks';
import { useUpdateEmployeeQuery } from '../queries';

import useEmployeeFields from './useEmployeeFields';

export const transformDateToRaw = (value?: string) => {
  if (value && value.trim()) {
    return dayjs(value, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT);
  }
  return '';
};

export const tranformPhoneNumber = (value?: string) => {
  if (!value) {
    return '';
  }
  // @ts-ignore
  return value.replaceAll(' ', '').replaceAll(REGEXPS.PARENTHESES, '').replaceAll('-', '');
};

const DEFAULT_NUMBER = '+1';
export default () => {
  const { id } = useParams<{ id: string }>();
  const healthPlanClaimsState = useEmployeeClaimAutopayStore((store) => store.state);
  const state = usePersonalInformationState();
  const { mutateAsync } = useUpdateEmployeeQuery();
  const { setLoader, removeLoading } = useGlobalLoaderStore();
  const { updateEmployeeAutopayProperties } = useUpdateEmployeeAutopayPropertiesQuery(id);
  const fields = useEmployeeFields();

  const { getValidateFields, getErrors } = useValidationUtils();
  const validations = getValidateFields(fields);
  const hasAccessToEditPrimaryId = useHasAccess([{ permission: Permissions.EDIT_EE_PRIMARY_ID }]);
  const isErrors = useMemo(() => {
    if (!hasAccessToEditPrimaryId) {
      delete validations.ssn;
    }
    return !_.isEmpty(getErrors(state, validations));
  }, [getErrors, hasAccessToEditPrimaryId, state, validations]);

  const handleSave = useCallback(async () => {
    const sameAddressForMailing = state.sameAsMailing
      ? _.pickBy({
        line1: state.firstAddressLine,
        line2: state.secondAddressLine,
        city: state.city,
        state: state.state,
        zipcode: state.zipCode,
        country_code: state.countryCode,
        address_type: EmployeeAddressType.UNKNOWN,
      })
      : _.pickBy({
        line1: state.physicalFirstAddressLine,
        line2: state.physicalSecondAddressLine,
        city: state.physicalCity,
        state: state.physicalState,
        zipcode: state.physicalZipCode,
        country_code: state.physicalCountryCode,
        address_type: EmployeeAddressType.UNKNOWN,
      });
    const transformedSecondPhone = tranformPhoneNumber(state.secondPhone);
    const payloadWithoutEmptyObjects: EmployeePayload = removeEmptyObjects({
      id,
      first_name: state.firstName,
      last_name: state.lastName,
      middle_name: state.middleName,
      suffix: state.suffix,
      ...(hasAccessToEditPrimaryId
        ? {
          ssn: state.ssn,
          employer_employee_id: state.employerEmployeeId,
          partner_employee_id: state.partnerEmployeeId,
        }
        : {}),
      gender: state.gender,
      birth_on: transformDateToRaw(state.birthDate),
      ...(state.firstPhone && state.firstPhoneType
        ? {
          phone1: _.pickBy({
            number: tranformPhoneNumber(state.firstPhone),
            phone_type: state.firstPhoneType as EmployeePhoneNumber,
          }),
        }
        : {}),
      ...(state.secondPhone && state.secondPhone
        ? {
          phone2: _.pickBy({
            number: transformedSecondPhone === DEFAULT_NUMBER ? null : transformedSecondPhone,
            phone_type:
                transformedSecondPhone && transformedSecondPhone !== DEFAULT_NUMBER
                  ? (state.secondPhoneType as EmployeePhoneNumber)
                  : null,
          }),
        }
        : {}),

      mailing_address: _.pickBy({
        line1: state.firstAddressLine,
        line2: state.secondAddressLine,
        city: state.city,
        state: state.state,
        zipcode: state.zipCode,
        country_code: state.countryCode,
        address_type: EmployeeAddressType.UNKNOWN,
      }),
      physical_address: sameAddressForMailing,
      use_same_address_for_mailing: state.sameAsMailing,
      is_employee_email_preferred: !!state.employeeEmailPrefer,
      status: state.employerStatus,
      hire_on: transformDateToRaw(state.hireDate),
      termination_on: transformDateToRaw(state.terminationDate),
      employment_status_effective_on: transformDateToRaw(state.statusEffectiveDate),
      organization_id: state.subsidiary || state.parentOrganizationId || state.organizationId,
    });

    const payload: EmployeePayload = {
      ...payloadWithoutEmptyObjects,
      ssn: hasAccessToEditPrimaryId ? payloadWithoutEmptyObjects.ssn || null : undefined,

      suffix: payloadWithoutEmptyObjects.suffix || null,
      middle_name: payloadWithoutEmptyObjects.middle_name || null,
      employee_provided_email: state.employeeEmail || null,
      employer_provided_email: state.employerEmail || null,
      custom_field1: state.customReportField1,
      custom_field2: state.customReportField2,
      custom_field3: state.customReportField3,
      custom_field4: state.customReportField4,
      custom_field5: state.customReportField5,

      ...(hasAccessToEditPrimaryId && !payloadWithoutEmptyObjects?.employer_employee_id
        ? { employer_employee_id: null }
        : {}),
      ...(hasAccessToEditPrimaryId && !payloadWithoutEmptyObjects?.partner_employee_id
        ? { partner_employee_id: null }
        : {}),
      ...(state.payrollGroupCode ? { payroll_group_code: state.payrollGroupCode } : {}),
    };
    if (!hasAccessToEditPrimaryId) {
      delete payload.ssn;
    }
    await updateEmployeeAutopayProperties(healthPlanClaimsState);
    await mutateAsync(payload);
  }, [
    id,
    mutateAsync,
    state,
    healthPlanClaimsState,
    updateEmployeeAutopayProperties,
    hasAccessToEditPrimaryId,
  ]);

  const handleValidate = useCallback(async () => {
    if (!isErrors) {
      setLoader({ message: GlobalLoaderMessages.EMPLOYEE_UPDATING });
      try {
        await handleSave();
        return true;
      } catch {
        return false;
      } finally {
        removeLoading();
      }
    }
  }, [handleSave, isErrors, removeLoading, setLoader]);
  return { handleValidate, isErrors };
};
