import React, { useEffect, useState } from 'react';
import { Box, FlexControlledForm, useDebounce } from '@common-fe/common-fe';
import { toNumber } from 'lodash';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { AllowedPaymentMethods, WaitingForFundsContinuePayment } from '@/modules/employer/types';

import useGetReimbursementFields, { BoolOptin } from './hooks/useGetReimbursementFields';
import useReimbursementStore from './stores/useReimbursement.store';

interface FormValue {
  allowBillPay?: BoolOptin;
  allowClaimOffsets?: BoolOptin;
  allowReimbursement?: BoolOptin;
  allowedPaymentMethods?: AllowedPaymentMethods[];
  minimumPaymentReimbursementAmount?: string;
  waitingForFundsContinuePayment?: WaitingForFundsContinuePayment;
  waitingForFundsExpiration?: string;
}

const formatFormValue = (value: FormValue) => ({
  ...value?.allowBillPay === BoolOptin.yes ? { isBillPayAllowed: true } : {},
  ...value?.allowBillPay === BoolOptin.no ? { isBillPayAllowed: false } : {},

  ...value?.allowClaimOffsets === BoolOptin.yes ? { isClaimOffsetsAllowed: true } : {},
  ...value?.allowClaimOffsets === BoolOptin.no ? { isClaimOffsetsAllowed: false } : {},

  ...value?.allowReimbursement === BoolOptin.yes ? { isReimbursementAllowed: true } : {},
  ...value?.allowReimbursement === BoolOptin.no ? { isReimbursementAllowed: false } : {},

  allowedPaymentMethods: value?.allowedPaymentMethods,
  ...value?.minimumPaymentReimbursementAmount ? {
    minimumPaymentReimbursementAmount: toNumber(value.minimumPaymentReimbursementAmount),
  } : {},
  ...value?.waitingForFundsContinuePayment ? {
    waitingForFundsContinuePayment: value.waitingForFundsContinuePayment,
  } : {},
  ...value?.waitingForFundsExpiration ? {
    waitingForFundsExpiration: value.waitingForFundsExpiration,
  } : {},
});

const Reimbursement: React.FC<{ disabled?:boolean }> = ({ disabled }) => {
  const { formattedData: { overridableProperties }, isLoading } = useGetEmployer();
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  const { viewMode } = useSetupEmployerMode();
  const [value, setValue] = useState<FormValue>();
  const debouncedValue = useDebounce(value);
  const {
    setReimbursement,
    state: { isReimbursementAllowed, allowedPaymentMethods },
  } = useReimbursementStore();
  const fields = useGetReimbursementFields(
    isReimbursementAllowed,
    allowedPaymentMethods,
    overridableProperties,
    isLoading,
    disabled,
  );

  useEffect(
    () => setReimbursement(formatFormValue(debouncedValue || {})),
    [debouncedValue, setReimbursement],
  );

  if (isLoading) return <FormLoader testId="Reimbursement" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      id="reimbursement_options"
    >
      <FlexControlledForm
        formTitle="Reimbursement options"
        fields={fields}
        showError={pendingStatus || activeStatus}
        editMode={!viewMode}
        onChangeValues={setValue}
        isThinMode
      />
    </Box>
  );
};

export default Reimbursement;
