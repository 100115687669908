import { PaymentMethod } from '@/modules/employee/employee.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  methods: PaymentMethod[];
  activeMethod?: PaymentMethod;
  selectedMethod?: PaymentMethod;
  editableMethod?: PaymentMethod;
  handleSetPaymentMethods: (data: PaymentMethod[]) => void;
  handleAddJustCreatedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  handleSetEditableMethodPaymentMethod: (paymentMethod: PaymentMethod) => void;
  handleClearEditableMethodPaymentMethod: () => void;
  handleSetActivePaymentMethod: () => void;
  handleSetChecked: (paymentId: string) => void;
  handleClear: () => void;
}

export const usePaymentMethodsStore = storeCreator<State>((set) => ({
  methods: [] as PaymentMethod[],
  activeMethod: undefined,
  selectedMethod: undefined,
  editableMethod: undefined,
  handleSetPaymentMethods: (methods: PaymentMethod[]) => set((state) => ({
    ...state,
    methods,
    selectedMethod: methods.find((method) => method.isChecked),
  })),

  handleSetChecked: (paymentId: string) => set((state) => ({
    ...state,
    selectedMethod: state.methods.find((method) => method.id === paymentId),
    methods: state.methods.map((method) => {
      if (paymentId && paymentId === method?.id) {
        return {
          ...method,
          isChecked: true,
        };
      }
      return {
        ...method,
        isChecked: false,
      };
    }),
  })),

  handleSetActivePaymentMethod: () => set((state) => ({
    ...state,
    activeMethod: state.methods.find((method) => method.isChecked),
  })),

  handleSetEditableMethodPaymentMethod: (paymentMethod) => set((state) => ({
    ...state,
    editableMethod: paymentMethod,
  })),

  handleClearEditableMethodPaymentMethod: () => set((state) => ({
    ...state,
    editableMethod: undefined,
  })),

  handleAddJustCreatedPaymentMethod: (data) => set((state) => ({
    ...state,
    methods: [
      { ...data, isChecked: true },
      ...state.methods.map((method) => ({ ...method, isChecked: false })),
    ],
  })),

  handleClear: () => set((state) => ({
    ...state,
    methods: [],
    activeMethod: undefined,
    selectedMethod: undefined,
    editableMethod: undefined,
  })),
}));
