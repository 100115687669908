import React, { useCallback, useState } from 'react';
import { Text } from '@common-fe/common-fe';

import { useHistory } from '@/modules/core/hooks';

import { useCIPIdentificationQuery,useCIPStatusUpdateQuery } from '../queries';

import QuestionaireWrapper from './QuestionnaireWrapper';
import ScanFrame from './ScanFrame';

interface Props {
  employeeId?: string;
}
const ScanDocument: React.FC<Props> = ({ employeeId }) => {
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const { data: { idScanUrl } } = useCIPIdentificationQuery({ enabled: true, employeeId });
  const { update } = useCIPStatusUpdateQuery({ employeeId });

  const history = useHistory();
  const handleSuccess = useCallback(() => {
    update();
    setSuccessModal(true);
  }, [update]);

  const handleError = useCallback(() => {
    setErrorModal(true);
  }, []);

  const handleRedirect = useCallback(() => {
    history.push('/');
    setSuccessModal(false);
    setErrorModal(false);
  }, [history]);
  return (
    <QuestionaireWrapper
      isSuccessModal={isSuccessModal}
      onCloseModal={handleRedirect}
      isErrorModal={isErrorModal}

    >
      <ScanFrame employeeId={employeeId} src={idScanUrl} onSuccess={handleSuccess} onError={handleError} />

      <Text data-testId="scan-document_text" size="small" color="textSecondary">
        To help the government fight the funding of terrorism and money laundering activities,
        federal law requires all financial institutions to obtain,
        verify, and record information that identifies each person who
        opens an account. What this means for you:
        When you open an account,
        we will use the information provided to us from your
        employer such as your name, address, date of birth,
        and other information that will allow us to identify you.
        We may also ask to see your driver's license and other identifying documents.

      </Text>
    </QuestionaireWrapper>
  );
};
export default ScanDocument;
