import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { PermissionKey, REQUIRED_TEXT } from '@/common/constants';
import { PartnerMobileApp } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import ThemeItem from '../Communication/EmailConfiguration/LinksManagement/ThemeItem';

import { ThemesListItem } from './queries/useMobileThemes.query';

const options = [
  {
    key: PartnerMobileApp.NONE,
    value:'None',
  },
  {
    key: PartnerMobileApp.ELEVATE_APP,
    value: 'Elevate',
  },
  {
    key: PartnerMobileApp.PARTNER_OWN_APP,
    value: 'Own application',
  },
];

interface Args {
  viewMode: boolean;
  isSubsidiary: boolean;
  appType?: PartnerMobileApp;
  googlePlayLink?: string;
  appStoreLink?: string;
  theme?: string;
  themesOptions?: ThemesListItem[];
  isThemesLoading?: boolean;
}

export const useMobileAppCustomizationFields = (args: Args) => {
  const themeTypeProps = useGetPermissionToEditOverridableField(PermissionKey.THEME_TYPE);

  return useMemo(() => {
    const {
      viewMode,
      isSubsidiary,
      appType,
      googlePlayLink,
      appStoreLink,
      theme,
      themesOptions,
      isThemesLoading,
    } = args;

    const isOwnApp = appType === PartnerMobileApp.PARTNER_OWN_APP;
    const isElevateApp = appType === PartnerMobileApp.ELEVATE_APP;

    return [
      {
        name: 'participantMobileApp',
        type: FieldTypes.Dropdown,
        testId: 'participantMobileApp',
        disabled: viewMode || isSubsidiary,
        singleMode: true,
        placeholder: 'Select mobile app',
        options,
        label: 'Participant mobile app',
        defaultValue: appType || '',
        subFields: [
          ...isElevateApp ? [
            {
              name: 'mobileAppTheme',
              label: 'Theme',
              type: FieldTypes.Dropdown,
              disabled: isThemesLoading,
              defaultValue: theme || '',
              value: theme || '',
              options: themesOptions?.map((item) => ({
                key: item.key,
                value: item.title,
                title: item.title,
                node: (
                  <ThemeItem color={item.color}>
                    {item.title}
                  </ThemeItem>),
              })) || [],
            },
          ] : [],
    
          ...isOwnApp ? [
            {
              name: 'googlePlayLink',
              type: FieldTypes.Text,
              testId: 'googlePlayLink',
              disabled: viewMode || isSubsidiary,
              showRequireIcon: true,
              placeholder: 'Enter Google Play link',
              label: 'Google Play link',
              validator: yup.string().required(REQUIRED_TEXT),
              value: googlePlayLink || '',
              defaultValue: googlePlayLink || '',
              ...themeTypeProps,
            },
            {
              name: 'appStoreLink',
              type: FieldTypes.Text,
              testId: 'appStoreLink',
              disabled: viewMode || isSubsidiary,
              showRequireIcon: true,
              placeholder: 'Enter App Store link',
              label: 'App Store link',
              validator: yup.string().required(REQUIRED_TEXT),
              value: appStoreLink || '',
              defaultValue: appStoreLink || '',
              ...themeTypeProps,
            },
          ] : [],
        ],
        ...themeTypeProps,
      },

      
    ] as Field[];
  }, [args, themeTypeProps]);
};
