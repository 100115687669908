import React from 'react';
import { Box } from '@common-fe/common-fe';

interface Props {
  zoom: number;
  children?: React.ReactNode;
}

const ZoomContainer: React.FC<Props> = ({ zoom, children }) => {
  return (
    <Box style={{ position: 'relative' }}>
      <Box
        data-testId="zoom-container"
        background="canvas"
        round="container1Round"
        border={{ color: 'border1', size: 'small' }}
        pad={{ horizontal: 'spacing24', vertical: 'spacing16' }}
        width={{ width: 'fit-content', max: 'fit-content', min: '100%' }}
        style={{
          position: 'absolute',
          transform: `scale(${zoom})`,
          transformOrigin: 'top left',
          transition: '.1s',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ZoomContainer;
