import { CardTypesEnum } from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';

export interface ExpandedItemLevel {
  title: string;
  subTitle: string;
  subSubTitle?: string;
}

export interface EnrollmentFilterParams {
  planTemplate?: OptionKey;
  planYear?: OptionKey;
  enrollmentDate?: string;
}

export interface ExpandedThirdLevelObject {
  id?: string;
  blockTitle?: string;
  title: string;
  subTitle?: string;
  subSubTitle?: string;
  triggers?: string[];
  items?: string[];
  header?: string[];
  values?: string[];
}

export interface TierItem {
  id: string;
  name: string;
  payoutDefinition: {
    id: string;
    name: string;
  }
  triggers?: string[];
  isSendTriggerNotification?: boolean;
  nextTierId: number | null;
  displayName?: string;
  planId?: string;
  planName?: string;
}
export interface ProcessingRuleItem {
  id: string;
  name: string;
  organizationType?: string;
  tiers: TierItem[];
  isNew?: boolean;
  editable?: boolean;
}
export interface EnrollmentMatrixItem {
  id: string;
  name: string;
  organizationType: string;
  isHealthPlanDelimiter?: boolean;
  planTemplate?: {
    id: string;
    name: string;
  }
  planYear?: {
    id: string;
    name: string;
  }
  processingRule?: ProcessingRuleItem;
  validFrom: string;
  validTo: string;
  priority: string;
  isLinkToHealthPlan: boolean;
}

export interface Triggertem {
  percentage: number;
  threshold_amount: number;
  type: TriggerEnum;
}

export interface TierPayload {
  id: number;
  name: string;
  payout_definition: {
    id: number;
    name: string;
  },
  triggers: string[];
  trigger: string;
  is_send_trigger_notification?: boolean;
  next_tier_id: number;
  display_name?: string;
  plan_id?: string;
  plan_name?: string;
}

export enum TriggerEnum {
  EXTERNAL_DEDUCTIBLE_MET = 'EXTERNAL_DEDUCTIBLE_MET',
}

export interface SearchEnrollmentMatrixParams {
  name?: string;
  page?: number;
  size?: number;
  sort?: string;
  filterParams?: EnrollmentFilterParams,
}

export interface EnrollmentMatrixResponse {
  id: number;
  priority: number;
}

export interface PayoutDefinitionResponse {
  id: number,
  name: string;
  categories: CategoryTypesResponse[];
}

export interface CategoryTypesResponse {
  category_type: string;
  services?: CategoryService[];
}

export interface PlanResponse {
  id: string;
  name: string;
  account_type?: string;
  order: number;
}

export interface PayoutDefinition {
  id: string,
  name: string;
  categoryTypes?: CategoryType[];
  categoryTypesStrings?: string[];
}

export interface CategoryService {
  id: string;
  name: string;
  plans?: Plan[];
  planNames?: string[];
}

export interface CategoryType {
  categoryType?: string;
  services?: CategoryService[];
}

export interface Plan {
  id: string;
  name: string;
  accountType: CardTypesEnum;
  order: number;
}

export const BASED_ON_NONE_KEY = 'basedOnNone';
