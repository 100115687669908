import React, { useMemo } from 'react';
import {
  Box, FlexList, Heading,
  PaginationBar, Preloader, SearchInput, SelectDropdown,
  Text, } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { ListItemBase } from '@/common/types';
import HeaderLink from '@/components/elements/HeaderLink';

import ListPlaceholder from '../ListPlaceholder';

import { usePayoutDefinitions, useSearchParams } from './hooks';

const headers: ListItemBase[] = [
  {
    key: 'Name',
    title: 'Name',
    flex: 1,
  },
  {
    key: 'Enabled service categories',
    title: 'Enabled service categories',
    flex: 3,
  },

];

interface Props {
  isPageMode?: boolean;
}

const PayoutDefinitions: React.FC<Props> = ({ isPageMode }) => {
  const {
    page, setPage, setPerPage, perPage,
    searchString, setSearchString,
    sortOptions,
    sortParams,
    setSortParams,
    params,
  } = useSearchParams();
  const { formatedList, isLoading, total } = usePayoutDefinitions(params);

  const currentNode = useMemo(() => {
    if (isLoading) {
      return (
        <Box pad={{ top: 'spacing24' }}>
          <Preloader testId="payout-definition_loading" />
        </Box>
      );
    }
    if (formatedList.length === 0) {
      return (
        <ListPlaceholder testId="payout-definition">
          There are no Payout Definitions
        </ListPlaceholder>
      );
    }
    return (
      <Box direction="column" style={{ position: 'relative' }}>
        {total === 0 ? null : (
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
      </Box>
    );
  }, [formatedList, isLoading, page, perPage, setPage, setPerPage, total]);
  return (
    <div>
      <Box
        direction="column"
        data-testid="payout-definition"
        pad={{ bottom: 'l' }}
        border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
      >
        <Box direction="row" align="center" justify="between">
          {
            isPageMode
              ? (
                <Box pad={{ vertical: 'l' }}>
                  <Heading level={4} size="large" color="textTitle">Payout Definitions</Heading>
                </Box>
              ) : (
                <Box margin={{ top: 'l', bottom: 'm' }}>
                  <HeaderLink path={ROUTES.PAYOUT_DEFINITIONS} title="Payout Definitions" />
                </Box>
              )
          }
        </Box>
        <Box>
          <FlexList
            testId="payout-definition-list"
            headers={headers}
        
            rows={formatedList}
            footer={currentNode}
    
          >
            <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
              <Text
                size="large"
                weight="bold"
                color="textBody"
              >Payout Definitions: {total || 0}
              </Text>
              <Box direction="row">
                <SearchInput
                  hasSearchingHistory
                  className="search-input"
                  testId="payout-definition-search"
                  value={searchString}
                  placeholder="Search"
                  onChange={setSearchString}
                />
                <Box direction="row" margin={{ left: 'spacing8' }}>
                  <Box width="small">
                    <SelectDropdown
                      id="status"
                      singleMode
                      testId="payout-definition-sort-by"
                      ellipsisMode
                      activeTitle
                      name="Sort by"
                      prefix="Sort by"
                      options={sortOptions}
                      value={sortParams}
                      onChange={setSortParams}
                    />
                  </Box>

                </Box>

              </Box>
            </Box>
          </FlexList>
        </Box>
      </Box>
    </div>
  );
};

export default PayoutDefinitions;
