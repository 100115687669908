import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ReconciliationReportDownloadModal } from '../ReconciliationReports/components/ReconciliationReportDownloadModal';
import { ReconciliationDownloadModal } from '../ReconciliationReports/components/ReconciliationReportDownloadModal/types';

import { AccountActivityDetailsHeader } from './components/AccountActivityDetailsHeader';
import { AccountActivityDetailsList } from './components/AccountActivityDetailsList';
import { CloseDateTransactionsList } from './components/CloseDateTransactionsList';

const StyledWrap = styled(Box)`
  display: block;
`;

const AccountActivityDetails: React.FC = () => (
  <StyledWrap pad={{ bottom: 'spacing72' }}>
    <AccountActivityDetailsHeader />
    <Box margin={{ bottom: 'spacing24' }}>
      <AccountActivityDetailsList />
    </Box>
    <CloseDateTransactionsList />
    <ReconciliationReportDownloadModal
      modalType={ReconciliationDownloadModal.AccountCloseDetails}
    />
  </StyledWrap>
);

export default AccountActivityDetails;
