export const CHECKBOXES_KEYS = {
  ROLLOVER: 'rollover',
};

export const ROLLOVER_KEYS = {
  MAXIMUM_ROLLOVER_AMOUNT: 'maximumRolloverAmount',
  MAXIMUM_ROLLOVER_AMOUNT_CUSTOM: 'maximumRolloverAmountCustom',
  MINIMUM_ROLLOVER_AMOUNT: 'minimumRolloverAmount',
  MINIMUM_ROLLOVER_AMOUNT_CUSTOM: 'minimumRolloverAmountCustom',
  ROLLOVER_CLAIMS: 'rolloverClaims',
  ROLLOVER_CLAIMS_YEARS_VALUE: 'claimsPriorYear',
  MAXIMUM_ROLLOVER_AMOUNT_IRS: 'maximumRolloverAmountIRS',
  AUTO_ENROLLMENT: 'autoEnrollment',
  ROLLOVER_CLAIMS_DAYS_AFTER_PLAN_END: 'daysAfterPlanEnd',
  ...CHECKBOXES_KEYS,
};
