import { CardTypesEnum } from '@common-fe/common-fe';

import { Plan } from '@/modules/plan/plan.types.ts';
import { baseStoreCreator } from '@/utils/store';

export interface PlansPayload {
  plans: Plan[];
  planTypes: CardTypesEnum[];

}

export const DEFAULT_VALUES: PlansPayload = {
  plans: [],
  planTypes: [],
};

export const usePlansStore = baseStoreCreator<PlansPayload>(DEFAULT_VALUES);

export default usePlansStore;
