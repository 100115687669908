import { CardTypesEnum } from '@common-fe/common-fe';

export enum HealthPlanStatuses {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
}

export enum HealthPlanFieldState {
  Locked = 'LOCKED',
  Hidden = 'HIDDEN',
  Modifiable = 'MODIFIABLE',
  RequiredAtPlan = 'REQUIRED_AT_PLAN',
  RequiredAtAllLevels = 'REQUIRED_AT_ALL_LEVELS',
  NA = 'NA',
}

export enum HealthPlansType {
  Plan = 'Plan',
  Template = 'Template',
}

export enum HealthPlanTypesLabel {
  PARKING = 'Parking',
  TRANSIT = 'Transit',
  SPECIALTY = 'Specialty',
  LIFESTYLE = 'Lifestyle',
  WELLNESS = 'Wellness',
  ADOPTION = 'Adoption',
  TRAVEL = 'Travel',
  HSA = 'HSA',
  HCFSA = 'HCFSA',
  HRA = 'HRA',
  DCAP = 'DCAP',
  MEDICAL = 'Medical',
  DENTAL = 'Dental',
  VISION = 'Vision',
  RX = 'Rx',
}


export interface HealthPlanListItemResponse {
  id: string;
  name: {
    name: string;
    name_state?: string;
  }

  health_plan_types: {
    health_plan_types: string[];
    health_plan_type_state: string;
  };
  deductibles_by_service_type: {
    deductibles_by_service_type: string;
    deductibles_by_service_type_state: string;
  }
  plan_status: string;
  plan_year?: {
    id: string;
    name: string;
  }
}

export interface Plan {
  id: string;
  name: string;
}

export type HealthPlan = {
  id: string;
  name?: string;
  typeState: HealthPlanFieldState;
  types: CardTypesEnum[];
  status: HealthPlanStatuses;
  process?: { total: number, passed: number }
  info?: Partial<HealthPlansType>
};

export interface HealthPlanGroupedListResponse {
  PARKING?: HealthPlanListItemResponse[];
  TRANSIT?: HealthPlanListItemResponse[];
  SPECIALTY?: HealthPlanListItemResponse[];
  LIFESTYLE?: HealthPlanListItemResponse[];
  WELLNESS?: HealthPlanListItemResponse[];
  ADOPTION?: HealthPlanListItemResponse[];
  TRAVEL?: HealthPlanListItemResponse[];
  HSA?: HealthPlanListItemResponse[];
  HCFSA?: HealthPlanListItemResponse[];
  HRA?: HealthPlanListItemResponse[];
  DCAP?: HealthPlanListItemResponse[];
  MEDICAL?: HealthPlanListItemResponse[];
  DENTAL?: HealthPlanListItemResponse[];
  VISION?: HealthPlanListItemResponse[];
  PHARMACY?: HealthPlanListItemResponse[];
}

export enum HealthPlanTypesEnum {
  HSA = 'HSA',
  DEPENDENT_CARE = 'DCAP',
  FSA = 'FSA',
  LIMITED_FSA = 'HCFSA',
  HRA = 'HRA',
  FITNESS = 'FITNESS',
  COMMUTER_PARKING = 'PARKING',
  COMMUTER_TRANSIT = 'TRANSIT',
  ADOPTION = 'ADOPTION',
  PARKING = 'PARKING',
  SPECIALTY = 'SPECIALTY',
  TRANSIT = 'TRANSIT',
  WAGEPARITY = 'WAGEPARITY',
  LIFESTYLE = 'LIFESTYLE',
  WELLNESS = 'WELLNESS',
  HCFSA = 'HCFSA',
  DCAP = 'DCAP',
}

export interface AccountFundingConfig {
  'id'?: number;
  'plan_id'?: number;
  'plan_name'?: string;
  'auto_enrollment_amount'?: number;
}

export interface Plans {
  'id': number;
  'name': string;
}

export interface HealthPlanDeductibleConfig {
  'id'?: number;
  created_at?: string;
  'parent_id'?:number;
  'coverage'?: {
    'id'?: number;
    'name'?: string;
    is_default?: boolean;
  },
  'code'?: {
    'code'?: string;
    'code_state'?: HealthPlanFieldState,
  },
  'plan_amount'?: {
    'plan_amount'?: number;
    'plan_amount_state'?: HealthPlanFieldState,
  },
  'member_amount'?: {
    'member_amount'?: number;
    'member_amount_state'?: HealthPlanFieldState;
  },
  'embedded_amount'?: {
    'embedded_amount'?: number;
    'embedded_amount_state'?: HealthPlanFieldState
  },
  'auto_enroll_account_funding_configs'?: AccountFundingConfig[];
}

export interface HealthPlanResponse {
  'id': number | string;
  'is_plan': boolean;
  summary?: string;
  'parent_id': number;
  'plan_year': Partial<{
    'id': number;
    'name': string;
    'valid_from': string;
    'valid_to': string;
    'prior_plan_year_id': number;
  }>;
  'plan_status': HealthPlanStatuses;
  'plan_code': string;
  'plan_year_id': number;
  'name': Partial<{
    'name': string;
    'name_state': HealthPlanFieldState;
  }>;
  'health_plan_types': Partial<{
    'health_plan_types': string[];
    'health_plan_type_state': HealthPlanFieldState;
  }>;
  'deductibles_by_service_type': Partial<{
    'deductibles_by_service_type': string;
    'deductibles_by_service_type_state': HealthPlanFieldState;
  }>;
  'carrier_id': Partial<{
    'carrier_id': string;
    'carrier_id_state': HealthPlanFieldState;
  }>;
  'is_auto_enrollment': Partial<{
    'is_auto_enrollment': boolean;
    'is_auto_enrollment_state': HealthPlanFieldState;
  }>;
  'health_plan_deductible_configs': HealthPlanDeductibleConfig[];
  'enrollment_matrix': Partial<{
    'name': string | null,
    'processing_rule_id': number,
  }>,
  'plans': Plans[],
}
