import { useCallback } from 'react';

import { useCoverageLevelsStore } from '../stores';

export default (id: string) => {
  const coveragesState = useCoverageLevelsStore((state) => state.state);
  const coveragesSetState = useCoverageLevelsStore((state) => state.setState);

  const handleRemoveCoverageLevel = useCallback(() => {
    const filteredList = coveragesState.filter((item) => item.coverageId !== id);
    coveragesSetState(filteredList);
  }, [coveragesSetState, coveragesState, id]);

  return handleRemoveCoverageLevel;
};
