import { baseStoreCreator } from '@/utils/store';

export interface SecurityPayload {

  username: string;
  password: string;
}

export const DEFAULT_VALUES: SecurityPayload = {
  username: '',
  password: '',
};

export const useStore = baseStoreCreator<SecurityPayload>(DEFAULT_VALUES);

export default useStore;
