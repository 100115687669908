import { useCallback } from 'react';
import _ from 'lodash';

import { useHealthPlanSetupSetter,useHealthPlanSetupState } from '../../hooks';

export const ERROR_MESSAGES = {
  INVALID_PROCESSING_RULE: 'Resource not found. Details: ProcessingRule with id',
  NAME_ALREADY_EXIST: 'Details: EnrollmentMatrix with name',
  PENDING_ORGANIZATION: 'Plan organization is not Active.',

};

export default () => {
  const { handleSetErrorFields } = useHealthPlanSetupSetter();
  const currentState = useHealthPlanSetupState();

  const handleParseError = useCallback((errors: unknown) => {
    const message = _.get(errors, 'response.data.elevate_error_message', '') as string;
    if (message.includes(ERROR_MESSAGES.NAME_ALREADY_EXIST)) {
      handleSetErrorFields({
        enrollmentMatrixName: {
          value: currentState.enrollmentMatrixName,
          message: 'This name is already used by another enrollment matrix',
        },
      });
    }
    return message;
  }, [
    currentState.enrollmentMatrixName, handleSetErrorFields]);
  return handleParseError;
};
