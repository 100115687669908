import React from 'react';
import { Box, Inscription, RoundCloseIcon } from '@common-fe/common-fe';

interface Props {
  title: string;
}

const LockboxEmployeesEmptyState: React.FC<Props> = ({ title }) => (
  <Box
    data-testId="lockbox-employees-empty-state"
    align="center"
    round="container2Round"
    pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
    border={{ size: '1px', color: 'border1' }}
  >
    <RoundCloseIcon color="iconSecondary" size="spacing24" />
    <Inscription 
      margin={{ top: 'spacing8' }}
      color="textDisabled"
      weight={700}
    >
      {title}
    </Inscription>
  </Box>
);

export default LockboxEmployeesEmptyState;
