import React, { useCallback, useMemo } from 'react';
import {
  AccordionPanel, Box, Heading, IconsList,
  List, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

/* tslint:disable */
import { TOC_MODULE_UI_ID } from '@/modules/core/hooks/useDetectActiveTOCModule';

import { StepImportantIcon } from './SetupProgressItem.styles';
import {
  ITOCDefaultStep,
  ITOCProcessStep,
  SetupProgressItemProps,
} from './SetupProgressItem.types';

const StyledBox = styled(Box)`
  box-shadow: none;
`;

export const SectionList: React.VFC<ITOCDefaultStep & { onSectionSelect: SetupProgressItemProps['onSectionSelect'], activeSection?: string }> = (props) => {
  const getList =  (data: { sectionId: string, title: string, children?: React.ReactNode }) => (
    <Box 
      key={`${data.sectionId}-TOC-item`}
      margin="spacing2">
      <Text weight={400} color={data.sectionId === props.activeSection ? 'textAccent' : 'textBody'}>{data.title as string}</Text>
    </Box>
  );
  return (
    <Box pad={{ bottom: 'small', left: 'small', right: 'small' }} border={false}>
      <List
        border={false}
        pad={{
          left: 'medium',
        }}
        onClickItem={((evt: {
          item?: { sectionId: string, title: string }; index?: number, stopPropagation: () => void;
        }) => {
          evt.stopPropagation();
          if (evt?.item) props.onSectionSelect(evt.item.sectionId);
        })}
        color="textBody"
        data={Object.keys(props.sections).map(
          (sectionId) => ({ sectionId, title: props.sections[sectionId] }),
        )}
      >
        {
      
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getList as any
        }
      </List>
    </Box>
  );
}; 

const SectionHeader: React.FC<ITOCProcessStep> = ({
  title,
  required,
  process,
  icon,
  expanded,
  withSections,
  expandCallback,
}) => {
  const percent = useMemo(() => (
    process ? Math.floor((process.passed / process.total) * 100) || 0 : 0
  ), [process]);
  
  const expandHandler = useCallback((e:MouseEvent) => {
    e.stopPropagation();
    if (expandCallback) expandCallback();
  }, [expandCallback]);

  return (
    <Box
      direction="row"
      pad="small"
      justify="between"
      flex={{ grow: 1 }}
    >
      <Box direction="row">
        <Box pad={{ right: 'xsmall' }}>
          {icon}
        </Box>
        <Box>
          <Heading
            level={5}
            color="textTitle"
            margin="none"
            style={{ whiteSpace: 'nowrap' }}
          >
            {title}
            {required && <StepImportantIcon data-testid={`${title}-required`}>*</StepImportantIcon>}
          </Heading>
          {process && <Text weight={500} size="small" color={percent === 100 ? 'textAccent' : 'textDisabled'}>{process.passed}/{process.total} ({percent}%)</Text>}
        </Box>
      </Box>

      {
        withSections
        && (
          // @ts-ignore
          <StyledBox onClick={expandHandler}>
            {
              expanded
                ? <IconsList.FormUp />
                : <IconsList.FormDown />
            }
          </StyledBox>
        )
      }
    </Box>
  );
};

export const SetupProgressItem: React.FC<SetupProgressItemProps> = ({
  details,
  active,
  onModuleSelect,
  onSectionSelect,
  moduleId,
  activeSection,
  activeModule,
  expandCallback,
  expanded,
}) => {
  const isItemWithSections = useMemo(() => (
    details.sections && (Object.keys(details.sections).length > 0)
  ), [details.sections]);

  if (isItemWithSections) {
    return (
      <Box
        round="container2Round"
        background={active ? 'border2' : 'transparent'}
        onClick={() => onModuleSelect()}
        hoverIndicator={{
          background: {
            color: 'border2',
          },
        }}
        // onClick={() => undefined}
        border={{
          color: 'border1',
        }}
        margin={{ bottom: 'xs' }}
        id={TOC_MODULE_UI_ID(moduleId)}
      >
        <AccordionPanel
          data-testid={details.title}
          label={(
            <SectionHeader
              withSections
              expandCallback={expandCallback}
              expanded={expanded}
              {...details}
            />
          )}
        >
          {details.sections
            ? (
              <SectionList
                {...details}
                onSectionSelect={onSectionSelect}
                activeSection={activeSection}
              />
            ) : null}
        </AccordionPanel>
      </Box>
    );
  }
  return (
    <Box
      round="container2Round"
      border={{
        color: 'border2',
      }}
      // hoverIndicator={{
      //   background: {
      //     color: 'border2',
      //   },
      // }}
      background={activeModule === moduleId ? 'border2' : 'transparent'}
      onClick={onModuleSelect}
      margin={{ bottom: 'xs' }}
      id={TOC_MODULE_UI_ID(moduleId)}
    >
      <SectionHeader {...details} />
    </Box>
  );
};
