import { storeCreator } from '@/utils/store/store.utils';

interface ErrorState {
  [key: string]: string;
}
interface State {
  state: ErrorState;
  setErrors: (values: ErrorState) => void;
}

export const useStore = storeCreator<State>((set) => ({
  state: {},
  setErrors: (values: ErrorState) => set(() => ({
    state: values,
  })),

}));
const useServerErrorsStore = () => {
  const store = useStore();

  return {
    ...store,
  };
};
export default useServerErrorsStore;
