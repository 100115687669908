import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const QUERY_KEY = 'getEmailTemplateVariablesQueryKey';

export const useGetEmailTemplateVariablesQuery = (emailTemplateId?: string, orgId?: string) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEY, emailTemplateId],
    emailTemplateId
      ? () => api.get(PATHS.GET_HTML_PREVIEW_VARIABLES_EMAIL_TEMPLATES(emailTemplateId), {
        params: {
          ...orgId && { organization_id: orgId },
        },
            
      })
      : () => null,
    {
      cacheTime: 0,
    },
  );

  return {
    data: data?.data,
    isLoading,
  };
};
