import React from 'react';
import { Box, Inscription, WarningIcon } from '@common-fe/common-fe';

const TooManyEmployeesState = () => (
  <Box
    data-testId="employees-search_too-many-employees-state"
    align="center"
    round="container2Round"
    pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
    border={{ size: '1px', color: 'border1' }}
  >
    <WarningIcon color="iconSecondary" size="spacing24" />

    <Inscription margin={{ top: 'spacing8' }} color="textDisabled" weight={700}>
      There are too many results.
    </Inscription>
    <Inscription color="textDisabled" weight={700}>
      Try to specify your search with more details.
    </Inscription>
  </Box>
);

export default TooManyEmployeesState;
