import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { CapitalizedInscription,SourceItemWrapper, SourceWrapper } from './Changeset.styles';

export interface Props {
  id: string;
  type: string;
  source: string;
  planName: string;
}

const Head: React.FC<Props> = ({ planName, source, type }) => (
  <Box>
    <SourceWrapper direction="row">
      {planName ? (
        <SourceItemWrapper>
          <Inscription lineHeight="20px" color="textSecondary">Plan name:</Inscription>
          <Inscription color="textBody" weight="bold" lineHeight="20px">{planName}</Inscription>
        </SourceItemWrapper>
      ) : null}
      {source ? (
        <SourceItemWrapper>
          <Inscription lineHeight="20px" color="textSecondary">Source of change:</Inscription>
          <Inscription lineHeight="20px" color="textBody">{source}</Inscription>
        </SourceItemWrapper>
      ) : null}
      {type ? (
        <SourceItemWrapper>
          <Inscription lineHeight="20px" color="textSecondary">Action:</Inscription>
          <CapitalizedInscription color="textBody" lineHeight="20px">{type}</CapitalizedInscription>
        </SourceItemWrapper>
      ) : null}
    </SourceWrapper>
  </Box>
);

export default Head;
