
import useAddressFormValidate from './useAddressFormValidate';
import useBankAccountFormValidate from './useBankAccountFormValidate';
import useContactFormValidate from './useContactFormValidate';
import useCopaysFormValidate from './useCopaysFormValidate';
import usePlansFormValidate from './usePlansFormValidate';
import usePlanYearFormValidate from './usePlanYearFormValidate';

export const useExpressSetupSaveValidate = () => {
  const contactErrors = useContactFormValidate();
  const planYearErrors = usePlanYearFormValidate();
  const plansErrors = usePlansFormValidate();
  const bankAccountErrors = useBankAccountFormValidate();
  const copayErrors = useCopaysFormValidate();
  const addressErrors = useAddressFormValidate();

  return {
    contactErrors,
    planYearErrors,
    plansErrors,
    bankAccountErrors,
    copayErrors,
    addressErrors,
  };
};
