import React, { useMemo } from 'react';
import {
  AppButton,
  Box,
  Inscription,
  RoundDoneIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';

import { useEmployerTerminationModalStore, useEmployerTerminationSuccessModalStore } from './store';

export const EmployerTerminationSuccessModal = () => {
  const modalVisible = useEmployerTerminationSuccessModalStore((state) => state.modalVisible);
  const handleChangeVisibility = useEmployerTerminationSuccessModalStore((state) => state.handleChangeVisibility);

  const employerName = useEmployerTerminationModalStore((state) => state.employerName);
  const terminationDate = useEmployerTerminationModalStore((state) => state.terminationDate);
  const handleReset = useEmployerTerminationModalStore((state) => state.handleReset);
  const additionalAction = useEmployerTerminationModalStore((state) => state.additionalAction);

  const headerText = useMemo(() => `${employerName} is scheduled for termination`,[employerName]);
  const description = useMemo(() => `Please note that you can edit or cancel termination configurations until ${terminationDate}.`, [terminationDate]);

  const onSubmit = () => {
    handleChangeVisibility(false);
    additionalAction?.();
    handleReset();
  };
  return (
    <ModalWrapper testId="EmployerTerminationSuccessModal" visible={modalVisible} onSetVisible={handleChangeVisibility}>
      <Box align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing24' }}>
        <RoundDoneIcon />
        <Inscription
          data-testid="EmployerTerminationSuccessModal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
          lineHeight="32px"
        >
          {headerText}
        </Inscription>

        <Text
          data-testid="EmployerTerminationSuccessModal_description"
          margin={{ bottom: 'spacing24' }}
          textAlign="center"
        >
          {description}
        </Text>

        <AppButton
          testId="EmployerTerminationSuccessModal"
          onClick={onSubmit}
          width="200px"
        >
          Got It!
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};