import { storeCreator } from '@/utils/store/store.utils';

interface State {
  skipConfirm: boolean;
  setSkipConfirm: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  skipConfirm: false,
  setSkipConfirm: (value: boolean) => set(() => ({
    skipConfirm: value,
  })),
}));
export default useStore;
