// import _ from 'lodash';
import masks from '@/common/masks';

export default (routingNumber: string, isMasked?: boolean) => {
  const firstRoutingPart = routingNumber.slice(0, 4);
  const lastRoutingPart = routingNumber.slice(4);
  let firstRoutingPartMasked = '';
  let lastRoutingPartMasked = '';

  if (isMasked) {
    firstRoutingPartMasked = firstRoutingPart.split('')
      .fill(masks.CARD_CHAR).join('');
    lastRoutingPartMasked = lastRoutingPart.split('')
      .fill(masks.CARD_CHAR).join('');
  }

  return isMasked ? `${firstRoutingPartMasked} ${lastRoutingPartMasked}` : `${firstRoutingPart} ${lastRoutingPart}`;
};
