import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';

import { ScheduledContributionStatus } from '../ScheduledContribution.types';
import { ScheduledContributionState } from '../stores/useScheduledContribution.store';

interface Payload extends ScheduledContributionState {}
interface Params {
  isEditMode?: boolean;
  contributionId?: string;
}
export default ({ isEditMode }: Params) => {
  const { mutateAsync, isLoading, isSuccess} = useMutation((payload: Partial<Payload>) =>
    isEditMode && payload.contributionId
      ? api.patch(`${PATHS.SCHEDULED_CONTRIBUTION}/${payload.contributionId}`, {
        calendar_id: _.toNumber(payload.calendarId),
        start_date: dayjs(payload.startDate, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT),
        tax_type: payload.taxType,
      })
      : api.post(PATHS.SCHEDULED_CONTRIBUTION, {
        calendar_id: _.toNumber(payload.calendarId),
        tax_type: payload.taxType,
        status: ScheduledContributionStatus.Active,
        start_date: dayjs(payload.startDate, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT),
      })
  );

  return {
    handleSave: mutateAsync,
    isLoading,
    isSuccess
  };
};
