 
import React from 'react';
import {
  Box, CloseIcon, RawButton, Text,
} from '@common-fe/common-fe';

interface Props {
  testId?: string;
  onDelete: () => void;
  title: string;
  isDeletable?: boolean;
  isEditMode?:boolean;
}
const CoverageLevelsItemHeader: React.FC<Props> = ({
  onDelete, title, isDeletable, testId, isEditMode,
}) => (
  <Box direction="row" justify="between" pad={{ bottom: 'l' }}>
    <Text size="xxlarge">{title}</Text>
    {isDeletable ? !isEditMode ? null : (
      <RawButton
        data-testid={`${testId}_delete-button`}
        onClick={onDelete}
      >
        <Box direction="row" align="center">

          <Text color="danger" size="medium" weight="normal" margin={{ right: 'spacing4' }}>
            Remove
          </Text>
          <CloseIcon size="16px" color="danger" />
        </Box>
      </RawButton>
    ) : (
      <Text color="textDisabled" weight={400}>Default</Text>
    ) }

  </Box>
);

export default CoverageLevelsItemHeader;
