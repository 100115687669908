import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  AppButton,
  Box,
  ErrorModal,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import { SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE } from '@/common/constants';
// import { LARGE_SIZE_LOGO, LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
// import { Icon } from '@/components/elements/Icon';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { ReportTypes } from '../../../Reports/report.types';
import { InvoiceType } from '../FinOpsInvoicing/types/invoicing.constants';

import { useDownloadInvoiceReport } from './hooks/useDownloadInvoiceReport';
import { useGetPrefundInfo } from './queries/useGetPrefundInfo.query';
import { useGetPrefundInvoiceInfo } from './queries/useGetPrefundInvoiceInfo.query';
import { useRepullPrefund } from './queries/useRepullPrefund.query';
import { useRepullReplenishment } from './queries/useRepullReplenishment.query';
import { InfoTable, InvoiceAlertAction } from './components';

export const FinOpsPrefund = () => {
  const { id } = useParams<{ id: string }>();
  const { handleAddPermanentSnackbar } = useSnackbar();

  const {
    formattedInvoiceTable,
    formattedData,
    isRejected,
    isLoading: invoiceLoading,
    refetch: refetchInvoice,
  } = useGetPrefundInvoiceInfo(id);

  const invoiceId = useMemo(() => formattedData.invoiceId, [formattedData]);
  const isReplenishment = useMemo(() => formattedData.invoiceType === InvoiceType.REPLENISHMENT
  || formattedData.invoiceType === InvoiceType.PLAN_LEVEL_REPLENISHMENT, [formattedData]);
  const isContribution = useMemo(() => formattedData.invoiceType === InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS, [formattedData]);
  const isPrefund = useMemo(() => formattedData.invoiceType === InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT
  || formattedData.invoiceType === InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT, [formattedData]);

  const {
    formattedPrefundTable,
    isLoading: prefundLoading,
    isRetriesExceeded,
    isPrefundExist,
    refetch: refetchPrefund,
    formattedData: prefundFormattedData,
  } = useGetPrefundInfo(id, isPrefund);

  const currentReportType = useMemo(() => {
    if (isReplenishment) {
      return ReportTypes.Replenishment;
    }
    if (isContribution) {
      return ReportTypes.Contribution;
    }
    if (isPrefund) {
      return ReportTypes.Prefund;
    }
    return null;
  }, [isContribution, isReplenishment, isPrefund]);

  const {
    setIsStartDownload,
    reportLoading,
    isServerError,
    setIsServerError,
  } = useDownloadInvoiceReport({ invoiceId, currentReportType });

  const {
    repull: repullPrefund,
    isLoading: repullPrefundLoading,
    isError: repullPrefundError,
    isSuccess: repullPrefundSuccess,
  } = useRepullPrefund();

  const {
    repull: repullReplenishment,
    isLoading: repullReplenishmentLoading,
    isError: repullReplenishmentError,
    isSuccess: repullReplenishmentSuccess,
  } = useRepullReplenishment();

  const handleRepullPrefund = useCallback(async () => {
    if (invoiceId) {
      await repullPrefund(invoiceId);
      refetchInvoice();
      if (isPrefundExist) {
        refetchPrefund();
      }
    }
  }, [invoiceId, isPrefundExist, refetchInvoice, refetchPrefund, repullPrefund]);
  const handleRepullReplenishment = useCallback(async () => {
    if (invoiceId) {
      await repullReplenishment(invoiceId);
      refetchInvoice();
      if (isPrefundExist) {
        refetchPrefund();
      }
    }
  }, [invoiceId, isPrefundExist, refetchInvoice, refetchPrefund, repullReplenishment]);

  const isLoading = useMemo(
    () => prefundLoading || invoiceLoading,
    [prefundLoading, invoiceLoading],
  );

  const isPrefundAlertVisible = useMemo(
    () => !!(isRejected && isRetriesExceeded && !repullPrefundSuccess),
    [isRejected, isRetriesExceeded, repullPrefundSuccess],
  );

  const isReplenishmentAlertVisible = useMemo(
    () => !!formattedData?.repullManuallyAllowed && !repullReplenishmentSuccess && isRejected && isReplenishment,
    [formattedData, repullReplenishmentSuccess, isRejected, isReplenishment],
  );

  const isExportAvailable = useMemo(
    () => {
      if (!currentReportType) return false;

      if (isPrefund) {
        return !!prefundFormattedData?.prefundHistoryId;
      }

      return true;
    }, [currentReportType, isPrefund, prefundFormattedData]);

  useEffect(() => {
    if (repullPrefundError || repullReplenishmentError) {
      handleAddPermanentSnackbar({
        text: 'Something went wrong. Please try again later.',
        state: SnackbarState.negative,
        closeIcon: true,
      });
    }
    if (repullPrefundSuccess || repullReplenishmentSuccess) {
      handleAddPermanentSnackbar({
        text: 'Successfully resumed',
        state: SnackbarState.positive,
        closeIcon: true,
      });
    }
  }, [
    repullPrefundError,
    handleAddPermanentSnackbar,
    repullPrefundSuccess,
    repullReplenishmentError,
    repullReplenishmentSuccess,
  ]);

  return (
    <>
      <ErrorModal
        testId="Claim_date_server-error"
        visible={isServerError}
        header={SERVER_ERROR_MODAL_TITLE}
        helpText={SERVER_ERROR_MODAL_TEXT}
        buttonText="Close"
        onSetVisible={setIsServerError}
      />
      <Box
        width="fit-content"
        direction="row"
        align="center"
        margin={{ bottom: 'spacing24' }}
      >
        {/* <Icon
          testId="sidebar-header-large-icon"
          size={LetterIconSizeEnum.LOGO}
          type={OrganizationTypes.partner}
          text={formattedData.organizationName}
          height={LARGE_SIZE_LOGO.height}
          width="100%"
          src={formattedData.largeOrganizationLogo}
        /> */}
        <Text
          size="32px"
          // margin={{ left: 'spacing24' }}
          weight={700}
          color="textTitle"
        >
          {formattedData.organizationName}
        </Text>
      </Box>
      <Box
        background="module"
        round="small"
        data-testid="FinOpsPrefund_main_wrapper"
      >
        <InfoTable
          values={formattedInvoiceTable}
          tableTitle="Invoice"
          isLoading={isLoading}
          containerStyles={{ paddingBottom: isPrefundExist ? 0 : '24px' }}
          testId="FinOpsPrefund"
          additionalContent={(
            <Box gap="spacing12">
              <InvoiceAlertAction
                isAlertVisible={isPrefundAlertVisible}
                buttonText="Resume"
                description="Please resume to re-start prefund workflow."
                onAction={handleRepullPrefund}
                actionLoading={repullPrefundLoading}
                testId="FinOpsPrefund"
              />

              <InvoiceAlertAction
                isAlertVisible={isReplenishmentAlertVisible}
                buttonText="Repull"
                description="This invoice will no longer attempt to pull funds automatically. To initiate an ACH, please select repull."
                onAction={handleRepullReplenishment}
                actionLoading={repullReplenishmentLoading}
                testId="FinOpsReplenishment"
              />
            </Box>
          )}
          exportButton={isExportAvailable && (
            <AppButton
              onClick={() => setIsStartDownload(true)}
              disabled={reportLoading}
              width="78px"
            >
              {reportLoading ?
                (<Preloader />) 
                : 'Export'}
            </AppButton>
          )}
        />
        {isPrefundExist ? (
          <InfoTable
            values={formattedPrefundTable}
            tableTitle="Prefund"
            isLoading={isLoading}
            testId="FinOpsPrefund"
          />
        ) : null}
      </Box>
    </>
  );
};
