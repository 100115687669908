import React, { useRef, useState } from 'react';
import { Box, InfoOutlineIcon,Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const MOUSE_SENSITIVITY = 3;

const Wrapper = styled(Box)<{ wrapperHeigh: number, space: string, leftMode: number }>`
  position: fixed;
  z-index: 2000;
  margin-left: ${({ space, leftMode }) => leftMode ? `-${leftMode + 10}px` : space};
  margin-top: ${({ wrapperHeigh }) => -wrapperHeigh/2}px;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    ${({ leftMode }) => leftMode ? 'right: -5px' : 'left: -5px'};
    background: ${({ theme }) => theme.colors.background3};
    width: 20px;
    height: 20px;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(45deg);
  }
`;

const ListItem = styled(Box)`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.canvas};
    left: 5px;
    top: 7px;
  }
`;

interface Props {
  children?: React.ReactNode;
  width?: string;
  hasIcon?: boolean;
  items?: string[];
  space?: string;
}

const Tip: React.FC<Props> = ({
  width = '250px',
  space = '32px',
  hasIcon,
  children,
  items,
}) => {
  const [leftMode, setLeftMode] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box style={{ position: 'relative' }}>
      <Box
        onMouseEnter={(event) => setPositionY(event.clientY)}
        onMouseLeave={() => setPositionY(0)}
        onMouseMove={(event) => {
          const widthValue = Number(width.replace('px', ''));
          if (window.screen.width - event.clientX < widthValue && !leftMode) {
            setLeftMode(widthValue);
          }
          if (Math.abs(positionY - event.clientY) >= MOUSE_SENSITIVITY) {
            setPositionY(event.clientY);
          }
        }}
      >
        {hasIcon ? <InfoOutlineIcon color="#C4C4C4" size="16px" height="16px" /> : null}
        {children ? children : null}
      </Box>
      <Wrapper
        ref={ref}
        wrapperHeigh={ref?.current?.offsetHeight || 0}
        leftMode={leftMode}
        width={{ min: width }}
        space={space}
        background="background3"
        pad="spacing12"
        round="small"
        color="textOnColor"
        justify="center"
        {...positionY ? { style: { opacity: 1, top: `${positionY}px` }} : { style: { opacity: 0, zIndex: -1 }}}
      >
        {items?.length ? (
          <Box>
            {items.map((item) => (<ListItem key={item} pad={{ left: 'spacing16' }}><Inscription size="12px" color="textOnColor">{item}</Inscription></ListItem>))}
          </Box>
        ) : null}
      </Wrapper>
    </Box>
  );
};

export default Tip;
