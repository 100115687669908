import { ScheduledContributionFrequency } from '../ScheduledContribution.types';

const getFrequencyTitle = (frequency: ScheduledContributionFrequency) => {

  if(frequency === ScheduledContributionFrequency.Annually) {
    return 'Annually';
  }
  if(frequency === ScheduledContributionFrequency.BiWeekly) {
    return 'Bi weekly';
  }
  if(frequency === ScheduledContributionFrequency.Monthly) {
    return 'Monthly';
  }
  if(frequency === ScheduledContributionFrequency.SemiMonthly) {
    return 'Semi monthly';
  }
  if(frequency === ScheduledContributionFrequency.Weekly) {
    return 'Weekly';
  }
  return '';

};


export default getFrequencyTitle;