import { baseStoreCreator } from '@/utils/store';

interface Theme {
  id: string;
  name: string;
  subject: string;
  text: string;
  activeLink: string;
  buttonBackground: string;
  buttonText: string;
  codeText: string;
  codeBackground: string;
}

export const DEFAULT_VALUES: Theme = {
  id: '',
  name: '',
  activeLink: '',
  buttonBackground: '',
  buttonText: '',
  codeBackground: '',
  codeText: '',
  subject: '',
  text: '',
};

export const useEmailThemeStore = baseStoreCreator<Theme>(
  DEFAULT_VALUES,
);

export default useEmailThemeStore;
