import { useEffect, useMemo, useState } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import usePlanYearStore, { PlanYearPayload } from '../stores/usePlanYear.store';

export default (defaultFields?: Partial<PlanYearPayload>, isEditMode?: boolean) => {
  const errorFields = usePlanYearStore((state) => state.errorFields);
  const [defaultValues, setDefaultValues] = useState<Partial<PlanYearPayload>>(defaultFields || {});
  const currentState = usePlanYearStore((state) => state.state);

  useEffect(() => {
    if (defaultFields) {
      setDefaultValues(defaultFields);
    }
  }, [defaultFields]);

  useEffect(() => {
    if (currentState?.planEndDate && currentState?.planStartDate) {
      setDefaultValues({planStartDate: currentState.planStartDate, planEndDate: currentState.planEndDate});
    }
  }, [currentState?.planEndDate, currentState?.planStartDate]);

  const fields = useMemo((): Field[] => [
    {
      name: 'planStartDate',
      type: FieldTypes.Date,
      isManualDateInput: true,
      controlStyles: isEditMode ? { width: '160px' } : undefined,
      showRequireIcon: true,
      maxDate: currentState?.planEndDate
        ? dayjs(currentState?.planEndDate, DEFAULT_DATE_FORMAT) : undefined,
      isWide: true,
      label: 'First date of plan year',
      placeholder: 'Select date',
      defaultValue: defaultValues?.planStartDate,
      validator: VALIDATORS.DATE_FORMAT.required(REQUIRED_TEXT),
    },
    {
      name: 'planEndDate',
      type: FieldTypes.Date,
      controlStyles: isEditMode ? { width: '160px' } : undefined,
      isManualDateInput: true,
      isWide: true,
      showRequireIcon: true,
      minDate: currentState?.planStartDate
        ? dayjs(currentState?.planStartDate, DEFAULT_DATE_FORMAT) : undefined,
      label: 'Last date of plan year',
      placeholder: 'Select date',
      validator: VALIDATORS.DATE_FORMAT.test({
        test: (val) => !errorFields?.planEndDate?.value || val !== errorFields?.planEndDate?.value,
        message: errorFields?.planEndDate?.message,
      }).required(REQUIRED_TEXT),
      defaultValue: defaultValues?.planEndDate,
    },
  ], [currentState, defaultValues, errorFields, isEditMode]);

  const formatedFields = useFieldsWithDefaultValues(fields, defaultValues);
  return formatedFields;
};
