import React, { useCallback, useEffect } from 'react';
import { Box, Field, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { MultiFactorAuthenticationValues } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import useMultiFactorAuthenticationFields from './hooks/useMultiFactorAuthenticationFields';
import useMultiFactorAuthenticationStore from './stores/useMultiFactorAuthentication.store';

const formatFormValue = (value: MultiFactorAuthenticationValues): Omit<MultiFactorAuthenticationValues, 'accountLockoutTime'> => ({
  authenticationTypeId: value?.authenticationTypeId,
});

const MultiFactorAuthentication = () => {
  const { viewMode } = useSetupEmployerMode();
  const { formattedData, isLoading } = useGetEmployer();
  const { values, setValues } = useDebounceState<MultiFactorAuthenticationValues>();
  const fields = useMultiFactorAuthenticationFields({ 
    disabled: viewMode,
    organizationData: formattedData,
    multiFactorAuthenticationValues: values,
    setValues,
  });
  const { setMultiFactorAuthenticationValues } = useMultiFactorAuthenticationStore();

  const handleChangeValues = useCallback((values: MultiFactorAuthenticationValues) => 
    setValues(formatFormValue(values || {})),
  [setValues]);

  useEffect(() => {
    setMultiFactorAuthenticationValues(values || {});
  }, [values, setMultiFactorAuthenticationValues]);

  if (isLoading) return <FormLoader testId="multi-factor-authentication" />;

  return (
    <Box
      id="multi_factor_authentication"
      data-testId="multi-factor-authentication"
      margin={{ top: 'spacing24' }}
      background="canvas"
      round="container1Round"
    >
      <FlexControlledForm
        formTitle="Multi-Factor Authentication"
        fields={fields as Field[]}
        onChangeValues={handleChangeValues}
        editMode={!viewMode}
        isThinMode
      />
    </Box>
  );
};

export default MultiFactorAuthentication;
