import React, { useEffect } from 'react';
import {
  Box, Field, FlexControlledForm, useDebounceState,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { GeneralInfoPayload,useStore } from './stores/useGeneralInfo.store';

// import { useSubmitFormStore } from '../../stores';
//

const Wrapper = styled(Box)`

  .small-input {
    max-width: 182px;
  }
`;

interface Props {
  viewMode?: boolean;
  isShowErrors?: boolean;
  fields: Field<string>[];

}
const GeneralInfo: React.FC<Props> = ({ viewMode, isShowErrors, fields }) => {
  const handleSetState = useStore((state) => state.setState);

  const { setValues, values } = useDebounceState<Partial<GeneralInfoPayload>>();
  useEffect(() => {
    if (values) {
      delete values.employeeEmailPrefer;
      delete values.employerEmailPrefer;
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <Wrapper background="canvas" round="container1Round">
      <FlexControlledForm
        key={`${viewMode}`}
        formTitle="General Information"
        fields={fields}
        showError={isShowErrors}
        editMode
        isDisabledMode={viewMode}
        onChangeValues={setValues}
        isThinMode
      />
    </Wrapper>
  );
};
export default GeneralInfo;
