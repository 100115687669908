import React, {
// useCallback,
// useEffect,
// useState,
} from 'react';
// import _ from 'lodash';
import {
  Box,
  // Button,
  // Image,
  // NotificationIcon,
  // Stack,
} from '@common-fe/common-fe';

// import styled from 'styled-components';
// import spacings from '@/styles/spacings';
// import { useNotificationCenterStore } from '@/modules/core/store';
import UserDropdown from './UserDropdown';

// const StyledButton = styled(Button)`
//   transition: .1s;
//   &:hover {
//     background-color: ${({ theme }) => theme.colors.border2};
//     border-radius: 3px;
//   }
// `;

// const NOTIFY_CLASS_NAME = 'notify';

// const StyledShowCount = styled(Box)<{ show: boolean }>`
//   transition: .1s;
//   transform: scale(${({ show }) => (show ? 1 : 0)});
// `;

// const StyledIconWrap = styled(Box)`
//   &.${NOTIFY_CLASS_NAME} {
//     animation: ring 1.5s ease;
//   }
//   @keyframes ring {
//     0% {
//       transform: rotate(35deg);
//     }
//     12.5% {
//       transform: rotate(-30deg);
//     }
//     25% {
//       transform: rotate(25deg);
//     }
//     37.5% {
//       transform: rotate(-20deg);
//     }
//     50% {
//       transform: rotate(15deg);
//     }
//     62.5% {
//       transform: rotate(-10deg);
//     }
//     75% {
//       transform: rotate(5deg);
//     }
//     100% {
//       transform: rotate(0deg);
//     }
//   }
// `;

const ToolbarBlock = () => (
  // const {
  //   setNotificationCenterState,
  //   totalNotifications,
  // } = useNotificationCenterStore();
  // const [notifyClass, setNotifyClass] = useState('');

  // const ring = useCallback(async () => {
  //   setNotifyClass('');
  //   await new Promise((resolve) => {
  //     setTimeout(() => {
  //       setNotifyClass(NOTIFY_CLASS_NAME);
  //       resolve(true);
  //     }, 1);
  //   });
  // }, []);

  // useEffect(() => {
  //   if (totalNotifications) {
  //     ring();
  //   }
  //   /* eslint-disable-next-line react-hooks/exhaustive-deps */
  // }, [totalNotifications]);

  <Box
    direction="row"
    align="center"
    pad={{ horizontal: 'spacing2' }}
  >
    {/* <Box margin={{ right: spacings.spacing18 }}>
      <StyledButton
        data-testid="notifications_button"
        onClick={() => {
          setNotificationCenterState(true);
        }}
      >
        <Box width="40px" height="40px">
          <Stack anchor="top-right">
            <StyledIconWrap className={notifyClass}>
              <NotificationIcon />
            </StyledIconWrap>
            <StyledShowCount
              show={!!totalNotifications}
              width="10px"
              height="10px"
              round="full"
              background="danger"
              margin={
                {
                  top: 'spacing2',
                  right: 'spacing2',
                }
              }
            />
          </Stack>
        </Box>
      </StyledButton>
    </Box> */}
    <UserDropdown />
  </Box>
);

export default ToolbarBlock;
