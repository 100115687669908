import React, { useMemo } from 'react';
import { Field, FieldTypes,Text } from '@common-fe/common-fe';

const DEFAULT_EMPTY_CHARACTER = '—';

export default (
  phoneNumber?: string,
  email?: string,
  viewMode?: boolean,
) => {
  const fields = useMemo<Field[]>(() => [
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number (Home)',
      defaultValue: phoneNumber,
    },
    {
      name: 'email',
      type: FieldTypes.Node,
      label: 'Email',
      value: (
        <Text color="textBody" data-testid="email_info">
          {email || DEFAULT_EMPTY_CHARACTER}
        </Text>
      ),
    },
  ], [email, phoneNumber]);
  return fields;
};
