import React from 'react';
import { Box } from '@common-fe/common-fe';

import Topbar from '@/modules/core/components/Topbar/Topbar';
import theme from '@/styles/theme';

import CarrierConfigListMainList from './CarrierConfigLists';
import { TopSection } from './components';

interface Props {
  isPageMode?: boolean;
}

const CarrierConfig: React.FC<Props> = ({ isPageMode }) => (
  <Box
    flex="grow"
    align="center"
    width="100%"
    height="fit-content"
    border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
  >
    <Box width="100%">
      {isPageMode && <Topbar />}
    </Box>
    <Box width={theme.defaultContentWidth}>
      <TopSection isPageMode={isPageMode} />
      <CarrierConfigListMainList />
    </Box>
  </Box>
);

export default CarrierConfig;
