import React, { useEffect,useState } from 'react';
import { Box, CONTENT_ID_SELECTOR } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import ContributionEmployeesSettings from '@/modules/employer/components/ContributionEmployeesSettings/ContributionEmployeesSettings';

const MIN_SCROLL = 50;

const SetUpContributionPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);
    const updateScrolling = () => setIsScrolled(Boolean(appContainer && appContainer.scrollTop > MIN_SCROLL));

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
  }, []);
  useBreadcrumbs([
    {
      route: ROUTES.CONTRIBUTIONS,
      title: 'Contributions',
    },
    {
      route: ROUTES.SET_UP_CONTRIBUTION,
      title: 'Set up Contribution',
    },
  ]);
  return (
    <Box align="center" width="100%">
      <ContributionEmployeesSettings isScrolled={isScrolled} />
    </Box>
  );
};

export default SetUpContributionPage;
