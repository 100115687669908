import { baseStoreCreator } from '@common-fe/common-fe';

export interface ScheduledContributionState {
  contributionId: string | null;
  calendarId: string | null;
  startDate: string | null;
  taxType: string | null;
}

export const DEFAULT_VALUES: ScheduledContributionState = {
  contributionId: null,
  calendarId: null,
  startDate: null,
  taxType: null,
};

export const useScheduledContributionStore = baseStoreCreator<ScheduledContributionState>(DEFAULT_VALUES);

export default useScheduledContributionStore;
