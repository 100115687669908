import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  CONTENT_ID_SELECTOR,
  ErrorModal,
  Preloader,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  OrganizationTypes,
  SERVER_ERROR_MODAL_TEXT,
  SERVER_ERROR_MODAL_TITLE,
} from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access, Topbar } from '@/modules/core/components';
import { useCurrentOrganization, useHasAccess, useHistory } from '@/modules/core/hooks';
import { HealthPlanStatuses, HealthPlansType } from '@/modules/HealthPlan/HealthPlan.types';
import { PlanCardAction } from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import CreateHealthPlanModal from './CreateHealthPlanModal';
import CreateHealthTemplateModal from './CreateHealthTemplateModal';
import HealthPlansBlock from './HealthPlansBlock';
import HealtPlansToggle from './HealtPlansToggle';
import { useHealthPlansList, useHealthPlanTemplatesList } from './hooks';
import { useForceOpenModalStore } from './stores';

export const Content = styled.div`
  position: relative;
`;

interface Props {
  isPageMode?: boolean;
}

interface WrapperProps {
  isPageMode?: boolean;
}

const FixedWrapper = styled(Box)<WrapperProps>`
  position: ${({ isPageMode }) => (isPageMode ? 'sticky' : 'relative')};
  top: 0;
  z-index: ${({ isPageMode }) => (isPageMode ? '1000' : '0')};
  height: max-content;
`;

const PickPlan: React.FC<Props> = ({ isPageMode }) => {
  const {
    isLoading: isLoadingPlans, count: plansCount, activePlans,
    allDraftPlans,
    isServerError: isPlansServerError,
    setIsServerError: setIsPlansServerError,
    currentRefetch: currentRefetchPlans,
  } = useHealthPlansList();
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();
  const isHasPermission = useHasAccess([{
    permission: Permissions.VIEW_HEALTH_PLAN,
  }, {
    permission: Permissions.SET_UP_HEALTH_PLAN,
  }]);

  const { searchQuery, hash } = useHistory();
  const isSystem = useMemo(
    () => type === OrganizationTypes.system,
    [type],
  );
  const isPartner = useMemo(
    () => type === OrganizationTypes.partner,
    [type],
  );

  const isEmployer = useMemo(
    () => type === OrganizationTypes.employer || type === OrganizationTypes.company,
    [type],
  );

  const [isScrolled, setIsScrolled] = useState(false);
  const isPlansHidden = useMemo(() => isSystem || isPartner, [isPartner, isSystem]);

  const planRedirectPath = useMemo(
    () => {
      if (isPlansHidden) {
        return HealthPlansType.Template;
      }

      return (hash as HealthPlansType
        || HealthPlansType.Plan);
    },
    [hash, isPlansHidden],
  );

  const [currentTab, setCurrentTab] = useState(planRedirectPath);

  useEffect(() => {
    if (isPlansHidden && currentTab === HealthPlansType.Plan) {
      setCurrentTab(HealthPlansType.Template);
    }
  }, [currentTab, isPlansHidden]);
  useEffect(() => {
    setCurrentTab(planRedirectPath);
  }, [planRedirectPath, hash]);
  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);
    const updateScrolling = () => setIsScrolled(!!appContainer
      && appContainer.scrollTop > 0);

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
   
  }, []);

  const {
    isLoading: isTemplatesLoading, count: templatesCount,
    activeTemplates, allDraftTemplates,
  } = useHealthPlanTemplatesList();

  const handleSetModalVisible = useForceOpenModalStore((state) => state.setState);
  const handleClearModalState = useForceOpenModalStore((state) => state.clear);
  useEffect(() => () => {
    handleClearModalState();
  }, [handleClearModalState]);
  const actionTypeState = useForceOpenModalStore((state) => state.state.actionType);
  useEffect(() => {
    if (actionTypeState) {
      if (
        actionTypeState === PlanCardAction.createPlanFromTemplate
        && currentTab !== HealthPlansType.Plan) {
        setCurrentTab(HealthPlansType.Plan);
      } else if (actionTypeState === PlanCardAction.createTemplateFromTemplate
        && currentTab !== HealthPlansType.Template) {
        setCurrentTab(HealthPlansType.Template);
      }
    }
  }, [actionTypeState, currentTab]);

  const handleSelectAction = useCallback((value: PlanCardAction, id: string) => {
    handleSetModalVisible(value, id);
  }, [handleSetModalVisible]);

  if (isLoadingPlans || isTemplatesLoading) {
    return (
      <Box flex="grow" align="center" justify="center" data-testid="pick-plan-spinner-wrap">
        <Preloader />
      </Box>
    );
  }
  if (!isHasPermission) {
    return null;
  }
  return (
    <Box
      flex="grow"
      border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
    >
      <FixedWrapper background="canvas" isPageMode={isPageMode}>
        {isPageMode && <Topbar hideHeader={isScrolled} />}
        <Box width={theme.defaultContentWidth} alignSelf="center">
          <HealtPlansToggle
            link={searchQuery}
            currentTab={currentTab}
            onSetCurrentTab={setCurrentTab}
            plansCount={plansCount}
            hidePlans={isPlansHidden}
            templatesCount={templatesCount}
            hideHeader={isScrolled && !!isPageMode}
            isPageMode={isPageMode}
            isEmployer={isEmployer}
          >
            {/* {(observingEmployer?.id || observingPartner?.id) && ( */}
            <Access
              accessRequirements={[
                {
                  permission: Permissions.SET_UP_HEALTH_PLAN,
                },
              ]}
            >
              <CreateHealthPlanModal hide={currentTab !== HealthPlansType.Plan} />

            </Access>
            <Access
              accessRequirements={[
                {
                  permission: Permissions.SET_UP_HEALTH_PLAN,
                },
              ]}
            >
              <CreateHealthTemplateModal hide={currentTab !== HealthPlansType.Template} />
            </Access>
          </HealtPlansToggle>
        </Box>
      </FixedWrapper>
      <Box>
        <Box
          data-testid="plans-list"
          style={{ position: 'relative' }}
        >
          <Box
            margin={{ top: 'spacing16' }}
            width={theme.defaultContentWidth}
            alignSelf="center"
          >
            <Content>
              {currentTab === HealthPlansType.Template ? (
                <>
                  <HealthPlansBlock
                    isTemplateMode
                    title="Draft"
                    plans={allDraftTemplates}
                    status={HealthPlanStatuses.DRAFT}
                    placeholder="No draft health plan templates. Create a new health plan template."
                  />
                  <HealthPlansBlock
                    isTemplateMode
                    title="Active"
                    onSelectAction={handleSelectAction}
                    plans={activeTemplates}
                    status={HealthPlanStatuses.ACTIVE}
                    isPlansHidden={isPlansHidden}
                    placeholder="No Active Health Plan Templates. Activate a health plan template."
                  />

                </>
              ) : (
                <>

                  <HealthPlansBlock
                    title="Drafts"
                    plans={allDraftPlans}
                    status={HealthPlanStatuses.DRAFT}
                    placeholder="No drafts. Save a draft."
                  />
                  <HealthPlansBlock
                    title="Active Plans"
                    plans={activePlans}
                    status={HealthPlanStatuses.ACTIVE}
                    placeholder="No active health plans. Activate a health plan."
                  />
                </>
              )}

            </Content>
          </Box>
        </Box>
        <ErrorModal
          visible={isPlansServerError}
          header={SERVER_ERROR_MODAL_TITLE}
          helpText={SERVER_ERROR_MODAL_TEXT}
          buttonText="Close"
          buttonTextTryAgain="Try again"
          onSetVisible={setIsPlansServerError}
          onSubmit={currentRefetchPlans}
        />
      </Box>
    </Box>
  );
};

export default PickPlan;
