import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { OptionKey, PaginationParams } from '@/common/types';

import { Check, CheckPayload } from '../constants';

export interface ChecksQueryParams extends Partial<PaginationParams> {
  statuses?: OptionKey[];
  dateRange?: Array<string | null>;
  organizationId?: string;
  enabled?: boolean;
}

const useGetChecks = ({
  page,
  perPage,
  searchString,
  dateRange,
  statuses,
  organizationId,
  enabled,
}: ChecksQueryParams) => {
  const [dateFrom, dateTo] = dateRange || [];

  const params = {
    size: perPage || 50,
    page,
    ...organizationId ? { organization_id: organizationId } : {},
    issued_date_from: dateFrom,
    issued_date_to: dateTo,
    ...searchString ? { search_value: searchString } : {},
    ...statuses?.length ? {
      check_status_types: statuses.map((status) => `${status}`.toUpperCase()).join(','),
    } : {},
  };

  const {
    data, isLoading,
  } = useQuery(
    [
      PATHS.GET_CHECKS_LIST,
      page,
      perPage,
      searchString,
      organizationId,
      dateFrom,
      dateTo,
      statuses,
    ], () => enabled === false ? null : api.get(PATHS.GET_CHECKS_LIST, { params }),
  );

  const total = data?.data?.totalElements || 0;

  const checks = useMemo<Check[]>(() => (data?.data?.content || []).map((check: CheckPayload) => ({
    ...check?.amount && typeof check.amount === 'number' ? { amount: check.amount } : {},
    ...check?.check_id ? { id: toString(check.check_id) } : {},
    ...check?.claim_ids?.length ? { claimIds: check.claim_ids.map((id) => toString(id)) } : {},
    ...check?.current_status ? { status: check.current_status } : {},
    ...check?.employee_id ? { employeeId: toString(check.employee_id) } : {},
    ...check?.employee_name ? { employeeName: check.employee_name } : {},
    ...check?.employer_name ? { employerName: check.employer_name } : {},
    ...check?.issued_date ? { issuedDate: check.issued_date } : {},
    ...check?.pay_to ? { payTo: check.pay_to } : {},
    ...check?.status_date ? { statusDate: check.status_date } : {},
    ...check?.check_address ? { address: check.check_address } : {},
    ...check?.void_reason ? { voidReason: check.void_reason } : {},
    ...check?.payment_method_id ? { paymentMethodId: toString(check.payment_method_id) } : {},
    ...check?.reissue_check_id ? { reissueCheckId: toString(check.reissue_check_id) } : {},
    ...check?.reissue_payment_method_type ? { reissuePaymentMethodType: check.reissue_payment_method_type } : {},
  })), [data]); 

  return {
    checks,
    isLoading,
    total,
  };
};

export default useGetChecks;
