import dayjs from 'dayjs';

const formatDateRange = ([from, to]: Array<Date | null>) => {
  return ([
    from ? dayjs(from).format() : null,
    to ? dayjs(dayjs(to).endOf('day')).format() : null,
  ]);
};

export default formatDateRange;
