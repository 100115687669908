import { useMemo } from 'react';

import { useBasicPropertiesState } from '../BasicProperties/hooks';
import { useComplexConfigurationState } from '../ComplexConfiguration/hooks';
import { useCoverageLevelsState } from '../CoverageLevels/hooks';

export default () => {
  const basicPropertiesState = useBasicPropertiesState();
  const coverageLevels = useCoverageLevelsState();
  const complexConfigurationState = useComplexConfigurationState();

  const state = useMemo(() => ({
    ...basicPropertiesState,
    ...coverageLevels,
    ...complexConfigurationState,
  }), [
    basicPropertiesState,
    coverageLevels,
    complexConfigurationState,
  ]);
  return state;
};
