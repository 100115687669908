import React, { useMemo } from 'react';
import { Box, Option, OptionKey, SelectDropdown, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { LAST_4_DIGIT_KEY } from '@/modules/employer/components/Reports/report.types';

interface Props {
  value: OptionKey;
  onChangeValue: (value: OptionKey) => void;
  width?: string;
}

const SmallText = styled(Text)``;
const ItemText = styled(Text)``;
const Wrapper = styled(Box)`
  .select-dropdown_title {
    ${ItemText} {
      /* it's subitem styles and can't be set inline */
      color: ${({ theme }) => theme.colors.textAccent};
      margin-left: 2px;
    }
    ${SmallText} {
      display: none;
    }
  }
`;

const SelectSSNDropdown: React.FC<Props> = ({ value, onChangeValue, width }) => {
  const currentOptions: Option[] = useMemo(
    () => [
      {
        key: LAST_4_DIGIT_KEY,
        value: 'Last 4 digits',
        title: 'Last 4 digits',
        node: (
          <Box direction="row" justify="between" align="center" fill>
            <ItemText>Last 4 digits</ItemText>
            <SmallText size="small" color="textDisabled">
              **** 1234
            </SmallText>
          </Box>
        ),
      },
      {
        key: 'fullCode',
        value: 'Full code',
        title: 'Full code',
      },
    ],
    []
  );
  return (
    <Wrapper width={width || '240px'}>
      <SelectDropdown
        testId="AccountActivityReport_ssn"
        id="AccountActivityReport_ssn"
        activeTitle
        ellipsisMode
        singleMode
        name="SSN"
        prefix="SSN"
        options={currentOptions}
        value={value}
        onChange={onChangeValue}
      />
    </Wrapper>
  );
};
export default SelectSSNDropdown;
