import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { BankAccountTypes } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import useBankAccountStore, { BankAccountPayload } from '../stores/useBankAccount.store';

export default (isBankExist?: boolean) => {
  const bankAccountSourceState = useBankAccountStore((state) => state.sourceState);
  // const secureMask = useMemo(() => {
  //   if (bankAccountSourceState?.accountNumber) {
  //     return `${Array(4).fill('*').join('')}9999`;
  //   }
  //   return '';
  // }, [bankAccountSourceState]);

  const fields: Field[] = useMemo((): Field[] => {
    const source: Field[] = [
      {
        name: 'bankAccountName',
        type: FieldTypes.Text,
        label: 'Name on account',
        placeholder: 'Name on account',
        showRequireIcon: true,
        validator: yup.string().trim().required(REQUIRED_TEXT),
        defaultValue: bankAccountSourceState?.bankAccountName || '',
      },
      {
        name: 'routingTransitNumber',
        type: FieldTypes.Mask,
        label: 'Routing number',
        mask: '999999999',
        placeholder: 'Routing number',
        showRequireIcon: true,
        defaultValue: bankAccountSourceState?.routingTransitNumber || '',
        validator: yup.string()
          .test('len', 'Must be exactly 9 characters', (val) => !val || val.length === 9)
          .required(REQUIRED_TEXT),
      },
      {
        name: 'accountNumber',
        type: FieldTypes.Mask,
        label: 'Account number',
        mask: '99999999999999999',
        formatChars: { '9': regexp.ALPHANUMERIC },
        secure: true,
        // secureMask,
        // isFullClear: isBankExist ? true : false,
        placeholder: 'Account number',
        showRequireIcon: true,
        validator: yup.string()
          .test('len', 'Must be between 4 and 17 characters', (val) => !val || (val.length >= 4 && val.length <= 17))
          .required(REQUIRED_TEXT),
        defaultValue: bankAccountSourceState?.accountNumber || '',
      } as unknown as Field<keyof BankAccountPayload>,
      {
        name: 'accountCategory',
        type: FieldTypes.Radio,
        label: 'Bank account type',
        validator: yup.string().required(REQUIRED_TEXT),
        defaultValue: bankAccountSourceState?.accountCategory || '',
        showRequireIcon: true,
        options: [
          {
            label: 'Checking',
            value: BankAccountTypes.CHECKING,
          },
          {
            label: 'Savings',
            value: BankAccountTypes.SAVING,
          },
        ],
      },
    ];
    return source;
  }, [bankAccountSourceState]);
  
  const formatedFields = useFieldsWithDefaultValues(fields, bankAccountSourceState);

  return formatedFields;
};
