import React from 'react';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { ScheduledContributionSummary } from '@/modules/employer/components/Contributions/ScheduledContribution';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 0;
`;

const ScheduledContributionEditPage = () => {
  useBreadcrumbs([
    {
      route: ROUTES.CONTRIBUTIONS,
      title: 'Contributions',
    },
    {
      route: ROUTES.SCHEDULED_CONTRIBUTION_SUMMARY,
      title: 'Scheduled Contributions',
    },
  ]);
  return (
    <Wrapper>
      <Topbar />

      <ScheduledContributionSummary isEditMode />
    </Wrapper>
  );
};

export default ScheduledContributionEditPage;
