import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledActionBox = styled(Box)`
  transition: ease-in-out .15s;
  position: absolute;
  bottom: 0px;
  z-index: 5;
`;

export const AnimatedContent = styled(Box)`
  transition: ease-in-out .15s;
  position: absolute;
  bottom: -40px;
`;

interface StyledBoxProps {
  isFlat?: boolean;
  isTemplate?: boolean;
  isHovered?: boolean;
}

export const Wrapper = styled(Box)<StyledBoxProps>`
  position: relative;
  cursor: pointer;
  min-width: 290px;
  min-height: 212px;
  box-shadow: none;
  transition: ease-in-out 0.15s;
  width: calc(33.333% - ${({ theme }) => theme.spacings.spacing16});
  ${({ isFlat }) => {
    if (isFlat) {
      return {
        height: '212px',
      };
    }
    return {};
  }}
  ${StyledActionBox} {
    opacity: 0;
    height: 40px;
    //transform: translateY(40px);
  }
  &:hover {
    ${({ isFlat, theme }) => {
    const border = `2px solid ${theme.colors.border1}`;
    if (!isFlat) {
      return {
        ...theme.shadows.hover,
        border,
      };
    }
    return {};
  }}
    ${StyledActionBox} {
      //transform: translateY(0px);
      // height: ${({ isFlat }) => (isFlat ? '' : '40px;')};
      opacity:${({ isFlat }) => (isFlat ? '0' : '1')}; 
    }

    ${AnimatedContent} {
      bottom: 0px;
    }
    ${StyledActionBox} {
      bottom: 24px;
    }
  }
`;

export const PlaceholderWrapper = styled(Box)`
  position: relative;
  transition: ease-in-out 0.15s;
  overflow: hidden;
  > svg {
    position: absolute;
    bottom: -1px;
    left: 6px;
  }
`;

export const StyledBox = styled(Box)`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled(Box)`
  position: relative;
  flex: 1;
  
  background-color: ${({ theme }) => theme.colors.canvas};
  /* z-index: 1; */

`;

export const Content = styled(Box)`
  position: relative;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.canvas};
  /* z-index: -1; */
`;

export const IconBgBox = styled(Box)`
  position: absolute;
  transition: ease-in-out 0.15s;
  width: 180px;
  height: 180px;
  top: -4px;
  /* z-index: 1; */
`;

export const LabelContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const PlanName = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
  max-width: 100%;
  margin-right: ${({ theme }) => theme.spacings.xxs};

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
