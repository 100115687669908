import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';

export const COVERAGES_LIST_QUERY_KEY = 'COVERAGES_LIST';
interface CoverageRawPaylod {
  id: number;
  name: string;
  code: string;
  organization_id:number;
  organization_path: string;
}

export interface FormatedCoverage {
  id: string;
  name: string;
  code: string;
}

export default () => {
  const { observingOrganization: { path } } = useCurrentOrganization();

  const {
    isLoading,
    data,
  } = useQuery([
    COVERAGES_LIST_QUERY_KEY, path,
  ], () => api.get<CoverageRawPaylod[]>(
    PATHS.COVERAGES,
    {
      params: {
        organization_path: path,
      },
    },
  ), {
    enabled: !!path,
  });
  const formatedData = useMemo(() => data?.data.map((item) => ({
    id: `${item.id}`,
    name: item.name,
    code: item.code,
  })), [data]);

  return {
    data: formatedData,
    isLoading,
  };
};
