import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollCalendarRequestParams } from '../payrollCalendar.types';

const getCalculatePayrollCalendarSchedule = (params: PayrollCalendarRequestParams) => {
  return api.get<string[]>(PATHS.CALCULATE_PAYROLL_CALENDAR_SCHEDULE, { params });
};

const useCalculatePayrollCalendarScheduleQuery = () => {
  const { mutateAsync, isLoading } = useMutation(
    (params: PayrollCalendarRequestParams) => getCalculatePayrollCalendarSchedule(params),
  );

  const calculateSchedule = useCallback(async (params: PayrollCalendarRequestParams) => {
    const response = await mutateAsync(params);
    return response?.data;
  }, [mutateAsync]);

  return {
    calculateSchedule,
    isLoading,
  };
};

export default useCalculatePayrollCalendarScheduleQuery;