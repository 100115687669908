import { Box, Button, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  flex-direction: column;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacings.spacing12};
  padding-bottom: ${({ theme }) => theme.spacings.spacing24};
  .icon {
    font-size: ${({ theme }) => theme.spacings.spacing72};
    color: ${({ theme }) => theme.colors.warning};
  }
`;
export const Header = styled(Text)`
  ${({ theme }) => theme.fonts.text24Bold};
  text-align: center;
`;
export const HelpText = styled(Text)`
  margin: ${({ theme }) => theme.spacings.spacing72};
  margin-top: ${({ theme }) => theme.spacings.spacing12};
  ${({ theme }) => theme.fonts.text14Regular};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
`;
export const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.danger};
  transition: all .3s;
  &:hover {
    opacity: 0.6;
  }
`;
