import { baseStoreCreator } from '@/utils/store';

export interface OrganizationInfo {
  organizationName: string;
  organizationId: string;
  organizationPath: string;
}

export const DEFAULT_VALUES: OrganizationInfo = {
  organizationName: '',
  organizationId: '',
  organizationPath: '',
};

export const useStore = baseStoreCreator<OrganizationInfo>(DEFAULT_VALUES);

export default useStore;
