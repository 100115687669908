import React, {
  useCallback,
  useState,
} from 'react';
import {
  ArrowDownIcon, ArrowUpIcon,
  Box, costFormater, FlatButton, Text,
  TextWithPointer, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ListItemType } from '@/common/types';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import theme from '@/styles/theme';

import ExpandedItem from '../ExpandedItem';
import ExpandedItemFirstLevel from '../ExpandedItem/ExpandedItemFirstLevel';
import { ServiceConfig } from '../queries/useServiceConfigs.query';

import ServiceDeleteModal from './ServiceDeleteModal';

export const StyledBox = styled(Box)<{ notClickable?: boolean }>`
  &:hover {
    cursor: ${({ notClickable }) => notClickable && 'not-allowed'};
    background-color: ${theme.colors.accentActive};
    svg {
      fill: ${theme.colors.iconOnColor};
    }
  }
  pointer-events: ${({ notClickable }) => notClickable && 'none'};
`;

interface Props {
  value: ServiceConfig;
  isFirstItem: boolean;
  setUpdatedElement: (value: ServiceConfig) => void;
  healthPlanStatus?: HealthPlanStatuses;
}
const ServiceConfigItem: React.FC<Props> = ({
  value,
  isFirstItem,
  setUpdatedElement,
  healthPlanStatus,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [deletedServiceId, setDeleteServiceId] = useState('');

  const handleToggleDeleteModal = useCallback((id: string) => {
    setDeleteServiceId(id);
  }, []);

  const handleToggleExpanded = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ExpandedItem
      key={value.id}
      testId={`service_config_item-${value?.id}`}
      isExpanded={isExpanded}
      options={healthPlanStatus !== HealthPlanStatuses.ACTIVE ? [
        {
          name: 'Edit',
          onClick: () => {
            if (setUpdatedElement) setUpdatedElement(value);
          },
        },
        {
          name: 'Delete',
          onClick: () => {
            handleToggleDeleteModal(value.id);
          },
        },
      ] : undefined}
      item={{
        id: value.id,
        fields: [{
          key: 'org-level-name',
          type: ListItemType.Node,
          title: '',
          flex: 2,
          node: (
            <Box data-testid={`service_config_item-${value.serviceId}_org-level-name`}>
              <FlatButton data-testid={`service_config_flat_button-${value.serviceId}_org-level-name`} onClick={handleToggleExpanded}>
                <Box direction="row" align="center">
                  <Box width={{ min: '24px' }} pad={{ right: 's' }}>
                    {
                      isExpanded ? (
                        <ArrowUpIcon color="iconPrimary" />
                      ) : (
                        <ArrowDownIcon color="iconPrimary" />
                      )
                    }
                  </Box>
                  <TextWithPointer
                    title={value.serviceId}
                    size="medium"
                    color="textBody"
                    ellipsisMode
                    weight="bold"
                    style={{ paddingRight: theme.spacings.xs }}
                  >
                    {value.serviceName}
                  </TextWithPointer>
                </Box>
              </FlatButton>
            </Box>
          ),
        }, {
          key: 'plan-template',
          type: ListItemType.Text,
          ellipsisMode: true,
          title: value.claimValue ? costFormater(value.claimValue, true) : 'Amount is not specfied',
          flex: 1,
        },
        {
          key: 'valid-from',
          type: ListItemType.Node,
          node: (
            <Box direction="row" align="center">
              <Box
                height="10px"
                width="10px"
                background={{ color: value.isAppliedDeductible ? 'success' : 'warning' }}
                round="50%"
              />
              <Text
                role="status"
                weight={400}
                size="medium"
                margin={{ left: 'xs' }}
              >
                {value.isAppliedDeductible ? 'Applied to deductible' : 'Not applied to deductible'}
              </Text>
            </Box>
          ),
          title: '',
        }],
      }}
    >
      {isExpanded && (
        <ExpandedItemFirstLevel
          testId={`service_config_item_${value.id}_first-level`}
          value={value}
          isFirstItem={isFirstItem}
        />
      )}
      <ServiceDeleteModal serviceId={deletedServiceId} setDeleteServiceId={setDeleteServiceId} />
    </ExpandedItem>
  );
};
export default ServiceConfigItem;
