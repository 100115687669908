import { useMemo } from 'react';
import { costFormater, Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { Option } from '@/common/types';

type ExpandedField = Field & {
  parentKey?:string;
};

interface ExpandedOption extends Option {
  parentKey?:string;
}
export interface Value {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: number[] | any;
}
interface FieldOption {
  [key: string]: ExpandedField;
}
export const useMatchFields = (values: Value, fields: ExpandedField[]) => {
  const fieldsMap = useMemo(
    () => fields.reduce(
      (result: FieldOption, item) => ({
        ...result,
        [item.name]: { ...item },
      }),
      {},
    ),
    [fields],
  );

  const formatedResult = useMemo(
    () => fields.reduce((result: ExpandedOption[], field) => {
      const key = field.name;
      const item = values?.[key];
      const currentField = fieldsMap[key];
      if (item) {
        const title = (currentField?.label || '') as string;

        if (!title) {
          return result;
        }
        const currentOptions = _.get(currentField, 'options', []) as Option[];

        if (_.isArray(item)) {
          if (currentOptions) {
            const formatedList = item.map((currentItem) => {
              const foundItem = _.find(
                currentOptions,
                (currentOption: Option) => currentOption.key === currentItem
                || currentOption.value === currentItem,
              ) as Option;
              if (foundItem) {
                return foundItem.value;
              }
              return currentItem;
            });

            if (!formatedList || formatedList.length === 0) {
              return result;
            }
            return [
              ...result,
              {
                key,
                parentKey: currentField.parentKey,
                title,
                value: formatedList.join(', '),
              },
            ];
          }
        }
        if (currentOptions) {
          const foundItem = _.find(
            currentOptions,
            (currentOption: Option) => currentOption.key === item || currentOption.value === item,
          ) as Option;
          if (foundItem) {
            const currentLabel = _.get(foundItem, 'label') || '' as string;
            return [
              ...result,
              {
                key,
                title,
                parentKey: currentField.parentKey,
                value: currentLabel || foundItem.value || foundItem.title || '',
              },
            ];
          }
        }
        if (currentField && currentField.type === FieldTypes.Checkbox) {
          return [
            ...result,
            {
              key,
              parentKey: currentField.parentKey,
              title,
              value: `${currentField.helpText || values[key]}` || '',
            },
          ];
        }
        if (currentField && currentField.type === FieldTypes.Currency) {
          return [
            ...result,
            {
              key,
              title,
              parentKey: currentField.parentKey,
              value: `${costFormater(values[key] || 0, true)}` || '',
            },
          ];
        }
        return [
          ...result,
          {
            key,
            title,
            parentKey: currentField.parentKey,
            value: values[key] || '',
          },
        ];
      }
      return result;
    }, []),
    [fields, fieldsMap, values],
  );

  const compactResult = useMemo(() => formatedResult.reduce((result: Option[], item) => {
    if (item.parentKey) {
      return result.map((resultItem) => {
        if (resultItem.key === item.parentKey) {
          return {
            ...resultItem,
            value: `${resultItem.value}: ${item.value}`,
          };
        }

        return resultItem;
      });
    }
    return [...result, item];
  }, []), [formatedResult]);
  return compactResult;
};
