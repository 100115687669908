import React, { lazy, Suspense, useMemo,useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { forceRefreshToken } from '@/api/api';
import ROUTES from '@/common/routes';
import { FullLoader } from '@/components/elements/FullLoader/FullLoader';
import { OrgLockBanner } from '@/modules/core/components/OrgLockBanner';
import Sidebar from '@/modules/core/components/Sidebar';
import { useOrganizationFavicon, useShowLockBanner } from '@/modules/core/hooks';
import useEmployerTerminationBanner from '@/modules/core/hooks/useEmployerTerminationBanner';
import useWhiteLabelingFavicon from '@/modules/core/hooks/useWhiteLabelingFavicon';
import { EmployerTerminationBanner } from '@/modules/employer/components/EmployerTermination/EmployerTerminationBanner';
import { useAuthStore } from '@/modules/user/stores';
import { useVersionChecker } from '@/utils';

import { ErrorBoundary } from '../ErrorBoundary';
import Notifications from '../Notifications';
import Snackbar from '../Snackbar';

import useAppRedirect from './useAppRedirect';

import './App.css';

const TimeoutModal = lazy(() => import('../TimeoutModal'));

const Content = styled(Box)`
  flex: 1;
`;
forceRefreshToken();
function App() {
  const { routes, minifiedQuery, errorMode } = useAppRedirect();
  const { inited, auth } = useAuthStore();
  const { pathname } = useLocation();
  const isLockBannerShowed = useShowLockBanner();
  const { bannerHeight } = useEmployerTerminationBanner();
  const isExpressSetupPage = useMemo(() => pathname.includes(ROUTES.EXPRESS_SETUPS), [pathname]);
  useOrganizationFavicon();
  useVersionChecker();
  useWhiteLabelingFavicon();
  const [minified] = useState(minifiedQuery);

  if (!inited) {
    return null;
  }
  if (auth) {
    return (
      <>
        <FullLoader />

        <Suspense fallback={null}>
          <TimeoutModal key={`${auth}`} />
        </Suspense>
        <OrgLockBanner />
        <EmployerTerminationBanner />
        <Grid
          fill
          rows={['flex']}
          columns={(isExpressSetupPage || minified) ? ['flex'] : ['auto', 'flex']}
          areas={[
            { name: 'sidebar', start: [0, 0], end: [0, 0] },
            { name: 'content', start: [1, 0], end: [1, 0] },
          ]}
          style={{
            height: '100vh',
          }}
          margin={{ top: isLockBannerShowed ? '62px' : bannerHeight || '0' }}
        >
          <ErrorBoundary>
            <Snackbar />
          </ErrorBoundary>
          <ErrorBoundary>{!minified && !errorMode && !isExpressSetupPage && <Sidebar defaultMinify={false} />}</ErrorBoundary>

          <Notifications />
          <Content id="content_wrapper" style={{ paddingLeft: '0', overflow: 'auto' }} direction="row">
            {/* <Box direction="row"> */}
            <ErrorBoundary>
              <Suspense fallback={<Preloader />}>
                <Switch>
                  {routes.map((route) => (
                    <Route key={`${route.path}`} {...route} />
                  ))}
                </Switch>
              </Suspense>
            </ErrorBoundary>
            {/* </Box> */}
          </Content>
        </Grid>
      </>
    );
  }
  return (
    <ErrorBoundary>
      <Snackbar />
      <Suspense fallback={<Preloader />}>
        <Switch>
          {routes.map((route) => (
            <Route exact key={`${route.path}`} path={route.path}>
              {route.component as  unknown as string}
            </Route>
          ))}
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
