import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppButton, Box, DateInput, SelectDropdown, Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, MINIMIZE_DATE_FORMAT } from '@/common/constants';
import Permissions from '@/common/permissions';
import routes from '@/common/routes';
import { useHasAccess, useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import {
  useReconciliationBankAccountList,
  useReconciliationReportDetails,
} from '@/modules/employer/queries/useReconciliationBankAccounts.query';

import useReconciliationReportDownloadModal from '../../../ReconciliationReports/components/ReconciliationReportDownloadModal/hooks/useReconciliationReportDownloadModal';

export const AccountActivityDetailsHeader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { queryMap } = useHistory();
  const todayDate = dayjs().format(DEFAULT_DATE_FORMAT);
  const closeDate = useMemo(
    () => queryMap.transactionsCloseDate,
    [queryMap],
  );
  const { formatedData } = useReconciliationReportDetails(id);
  const { response } = useReconciliationBankAccountList();
  const history = useHistory();
  const { setIsModalVisible } = useReconciliationReportDownloadModal();
  const isHasAccessToDownload = useHasAccess([{
    permission: Permissions.REPORTS
  }]);

  return (
    <Box margin={{ bottom: 'spacing16' }}>
      <Box
        direction="row"
        justify="between"
        align="center"
      >
        <Box flex="grow">
          <Text
            size="xxlarge"
            weight="bold"
            color="textBody"
          >
            {formatedData?.account_description || ''}
          </Text>
        </Box>
        <Box
          flex
          direction="row"
          justify="end"
          align="center"
          height="40px"
        >
          <Box margin={{ right: 'spacing24' }} width={{ min: '265px' }}>
            <SelectDropdown
              testId="account-select"
              id="category"
              singleMode
              // activeTitle
              ellipsisMode
              name="Reconciliation Report"
              options={response.map((item) => ({
                key: `${item.account_id}`,
                value: `${item.account_id}`,
                title: item.account_description,
              }))}
              value={id}
              onChange={(value) => (closeDate
                ? history.push(routes.ACCOUNT_ACTIVITY_TRANSACTIONS(`${value}`), {
                  [QueryKeys.TRANSACTIONS_CLOSE_DATE]: closeDate,
                })
                : null)}
            />
          </Box>
          <Box margin={{ right: 'spacing24' }} width={{ min: '160px' }}>
            <DateInput
              testId="account"
              isPicker
              className="period-picker"
              onChange={(date) => history.push(routes.ACCOUNT_ACTIVITY_TRANSACTIONS(id), {
                [QueryKeys.TRANSACTIONS_CLOSE_DATE]: dayjs(date, DEFAULT_DATE_FORMAT)
                  .format(MINIMIZE_DATE_FORMAT),
              })}
              value={closeDate || dayjs().format(MINIMIZE_DATE_FORMAT)}
              maxDate={dayjs(todayDate)}
              levelling={{
                top: '100%',
                left: '0',
              }}
            />
          </Box>
          {isHasAccessToDownload && (
            <AppButton
              testId="download-report"
              width="166px"
              onClick={() => setIsModalVisible(true)}
            >
              Download Report
            </AppButton>
          )}
          
        </Box>
      </Box>
    </Box>
  );
};
