import React from 'react';
import { Box } from '@common-fe/common-fe';

import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { HEALTH_PLAN_MODULES } from '../../HealthPlan.constants';

import EnrollmentMatrix from './EnrollmentMatrix';
// import { usePreviewStore } from '../stores';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}

const ComplexConfiguration: React.FC<Props> = ({ isEditMode, isTemplateMode, onDirty }) => (
// const previewMode = usePreviewStore((state) => state.previewMode);
  <ModuleWrapper
    testId="complex-configuration-wrapper"
    anchorId={HEALTH_PLAN_MODULES.COMPLEX_CONFIGURATION}
    title="Payment Rule Configuration"
    expandless
  >
    <EnrollmentMatrix
      isEditMode={isEditMode}
      isTemplateMode={isTemplateMode}
      onDirty={onDirty}
    />
  </ModuleWrapper>
);

export default ComplexConfiguration;
