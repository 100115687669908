import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
} from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { Option } from '@/common/types';
import { COMPLEX_CONFIGURATION_SETUP_FIELD } from '@/modules/HealthPlan/HealthPlanSetup/HealthPlanSetup.constants';
import {
  useHealthPlanFieldsState,
  useTemplateFieldState,
} from '@/modules/HealthPlan/HealthPlanSetup/hooks';
// import { usePreviewStore } from '@/modules/HealthPlan/HealthPlanSetup/stores';
import { useFieldsWithDefaultValues } from '@/utils';

import { useEnrollmentMatrixStore } from '../stores';
// import { EnrollmentMatrixPayload } from '../stores/useEnrollmentMatrix.store';

export const MANUAL_CLAIM_KEY = 'MANUAL_CLAIM';
export const HEALTH_PLAN_KEY = 'HEALTH_PLAN';

export default (
  isActivationMode?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
  processingRulesList?: Option[],
) => {
  const currentState = useEnrollmentMatrixStore((state) => state.state);
  const errorFields = useEnrollmentMatrixStore((state) => state.errorFields);
  //TODO: When we will implement preview mode for health plan, check task EL-16749 (logic not broken)
  // const sourceState = useEnrollmentMatrixStore((state) => state.sourceState);
  // const previewMode = usePreviewStore((state) => state.previewMode);
  // const appliedSourceState = useMemo(() => {
  //   if (previewMode) {
  //     return currentState;
  //   }
  //   return sourceState;
  // }, [currentState, previewMode, sourceState]);

  const onlyActivationRequired = useMemo(
    () => (isActivationMode ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING), [isActivationMode],
  );

  const fields = useMemo<Field[]>(() => [
    {
      name: COMPLEX_CONFIGURATION_SETUP_FIELD.ENROLLMENT_MATRIX_NAME,
      type: FieldTypes.Text,
      label: 'Enrollment matrix name',
      placeholder: 'Enter enrollment matrix name',
      disabled: !isTemplateMode,
      showRequireIcon: true,
      defaultValue: currentState?.enrollmentMatrixName,
      validator: !isActivationMode && errorFields?.enrollmentMatrixName
        ? VALIDATORS.STRING
          .test({
            test: (val) => !errorFields?.enrollmentMatrixName
              || val !== errorFields?.enrollmentMatrixName?.value,
            message: errorFields?.enrollmentMatrixName?.message,
          }) : onlyActivationRequired,
    },
    {
      name: COMPLEX_CONFIGURATION_SETUP_FIELD.PROCESSING_RULE_NAME,
      type: FieldTypes.Dropdown,
      label: 'Processing rule name',
      singleMode: true,
      disabled: !isTemplateMode,
      options: processingRulesList,
      defaultValue: currentState?.processingRuleName,
      placeholder: 'Select processing rule name',
      validator: onlyActivationRequired,
      showRequireIcon: true,
    },
    {
      name: COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE,
      type: FieldTypes.Radio,
      defaultValue: currentState?.deductibleService,
      label: 'Add service configs for claims',
      options: [
        { label: 'Manual claims', value: MANUAL_CLAIM_KEY },
        { label: 'Carrier claims', value: HEALTH_PLAN_KEY },
      ],
      showRequireIcon: true,
      validator: onlyActivationRequired,
    },
  ], [
    isTemplateMode,
    isActivationMode,
    onlyActivationRequired,
    processingRulesList,
    errorFields.enrollmentMatrixName,
    currentState,
  ]);

  const filteredField = useHealthPlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, currentState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode, undefined, COMPLEX_CONFIGURATION_SETUP_FIELD.DEDUCTIBLE_SERVICE);
  return stateFields;
};
