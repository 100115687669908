import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';
import _ from 'lodash';

import { PlanTemplatePayload, useStore } from './stores/usePlanTemplate.store';
import { usePlanTemplateFields } from './hooks';

interface Props {
  parentId?: string;
  testId?: string;
}
const PlanTemplate: React.FC<Props> = ({ parentId, testId = '' }) => {
  const setState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const currentState = useStore((state) => state.state);
  const fields = usePlanTemplateFields(currentState);
  useEffect(() => {
    if (parentId) {
      setState({
        parentTemplate: parentId,
      });
    }
  }, [setState, parentId]);
  const handleSetState = useCallback((values: Partial<PlanTemplatePayload>) => {
    setState(values);
  }, [setState]);
  const key = useMemo(() => _.get(fields, '[2].defaultValue', '') || _.get(fields, '[1].defaultValue', ''), [fields]) as string;
  return (
    <Box key={key} background="canvas" round="container1Round">
      <FlexControlledForm
        fields={fields}
        testId={`${testId}_plan-name`}
        showError={showErrors}
        editMode
        onChangeValues={handleSetState}
        isModalType
      />
    </Box>
  );
};
export default PlanTemplate;
