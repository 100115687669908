import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { CreateManualContributionResponse, Recipient, Segment } from '../types';
import formatDataToPayload from '../utils/formatDataToPayload';

interface Props {
  recipients: Recipient[],
  segment: Segment;
}

const useCreateManualContributionQuery = (onSuccess?: () => void) => {
  const { mutateAsync, isLoading } = useMutation(
    (props: Props) => {
      return  api.post<CreateManualContributionResponse>(
        PATHS.CREATE_MANUAL_CONTRIBUTION,
        formatDataToPayload(props.recipients, props.segment),
      );
    },
    { onSuccess },
  );
  
  const createManualContribution = useCallback(async (props: Props) => {
    const response = await mutateAsync(props);
    return response;
  }, [mutateAsync]);

  return {
    isLoading,
    createManualContribution,
  };
};

export default useCreateManualContributionQuery;
