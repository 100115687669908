import  {fetchAuthSession, signOut}  from '@aws-amplify/auth';
import { getDuration } from '@common-fe/common-fe';
import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { PATHS } from '@/common';
import { RequestHeaders,SERVER_ERRORS } from '@/common/constants';
import { getObservedOrganizationIdsFromURLSearchParams } from '@/modules/core/hooks/useHistory';
import CONFIG from '@/utils/config';
import Token from '@/utils/handlers/Token';

import { useAuthStore } from '../modules/user/stores/useAuth.store';

export interface Response<T> extends AxiosResponse<T> {}
export interface RefreshBody {
  accessToken: string;
  refreshToken: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ErrorResponse<T = any> extends AxiosResponse<T, AxiosHeaders> {
  response: {
    status: number;
    message: string;
  };
  config: {
    headers: AxiosHeaders;
  };
}

export const BEARER = 'Bearer';
export const GATEWAY_KEY = 'gateway';
export const API_KEY = 'api';


export const forceRefreshToken = async () => {
  const { setAuth } = useAuthStore.getState();
  try {
    
    const currentSession = await fetchAuthSession({ forceRefresh: true});
    const idToken = currentSession.tokens?.idToken?.toString() || '';
    await Token.storeToken(idToken);
  } catch {
    Token.clear();
    signOut();
    setAuth(false);
  }
};

setInterval(() => {
  forceRefreshToken();
}, getDuration({ minutes: 4, seconds: 30 }));

const axiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
});

export const errorHandler = async (error: ErrorResponse<unknown>) => {
  const { setAuth } = useAuthStore.getState();

  // if (error?.response?.status === SERVER_ERRORS.UNAUTHORIZED || !error?.response?.status) {  
  // TODO: Prevent kick off  
  if (error?.response?.status === SERVER_ERRORS.UNAUTHORIZED ) { 
    Token.clear();
    setAuth(false);
    location.reload();
  }

  return new Promise((resolve, reject) => {
    reject(error);
  });
};

axiosInstance.interceptors.request.use(async (request) => {
  request.headers.Authorization = Token.getToken()
    ? `${BEARER} ${Token.getToken() || ''}`
    : undefined;
  return request;
});


const isGettingOrganizationData = (url?: string) => {
  if (!url) return false;

  const orgPathRegexp = new RegExp(/^\/organizations\/[0-9]*$/);

  return orgPathRegexp.test(url);
};
const isGettingContactData = (url?: string) => {
  if (!url) return false;

  const contactRegexp = new RegExp(/^\/contacts\/[0-9]*$/);

  return contactRegexp.test(url);
};
const isGettingTeamsProcessors = (url?: string) => {
  if (!url) return false;

  return url.startsWith(`${PATHS.CONTACTS_TEAMS}?`);
};
const isException = (url?: string) => {
  if (!url) return false;

  if (isGettingOrganizationData(url)
    || url === PATHS.OVERRIDABLE_PROPERTIES_PERMISSIONS
    || url === PATHS.PERSON_ORGANIZATIONS_HIERARCHY_BY_ID
    || url === PATHS.ASSIGN_CLAIMS_TO_PROCESSOR
    || url.endsWith('/overridable-properties/employee')
    || isGettingTeamsProcessors(url)
    || isGettingContactData(url)) return true;

  return false;
};
axiosInstance.interceptors.request.use(async (request) => {
  if (isException(request.url) 
    || request.headers[RequestHeaders.observedOrganizationPath]
  || request.headers[RequestHeaders.observedOrganizationPath] === null) return request;
  const { observedOrganizationPath } = getObservedOrganizationIdsFromURLSearchParams();
  

  request.headers[RequestHeaders.observedOrganizationPath] = observedOrganizationPath;

  return request;
});

axiosInstance.interceptors.response.use((response) => response, errorHandler);

const mock = new MockAdapter(axiosInstance, { delayResponse: 250, onNoMatch: 'passthrough' });

export { axiosInstance, mock };