import React, { useState } from 'react';
import {
  AppButton, Box, FlexList, Preloader,
  Text, } from '@common-fe/common-fe';

import { ListItemBase } from '@/common/types';

import { useGeneralInfoStore } from '../PersonalInformation/GeneralInfo/stores';

import { AddDependentModal } from './AddDependentModal';
import { DependentsHeader } from './DependentsHeader';
import { useDependentsList } from './hooks';

const headers: ListItemBase[] = [
  {
    key: 'fullName',
    title: 'Full Name',
    flex: 1,
  },
  {
    key: 'relationship',
    title: 'Relationship',
    flex: 2.5,
  },
];
const Dependents = () => {
  const { personId } = useGeneralInfoStore((state) => state.state);
  const { data, isLoading } = useDependentsList(personId);
  const [visible, setVisible] = useState(false);
  return (
    <Box data-testId="Dependents-wrapper">
      <DependentsHeader addDependent={!data.length ? () => setVisible(true) : undefined} />
      <Box direction="column" background="module" pad={{ top: 'spacing12' }} round="container1Round">
        {isLoading
          ? (
            <Preloader testId="loading-spinner" />
          )
          : (
            <>
              {!data.length ? (
                <Box align="center" justify="center" pad={{ top: 'spacing12', bottom: 'spacing24' }}>
                  <Text color="textSecondary" style={{ lineHeight: '21px' }}>
                    The employee doesn't have any dependents yet.
                  </Text>
                </Box>
              ) : (
                <FlexList
                  headers={headers}
                  loading={isLoading}
                  // EL-9828
                  // options={[{ name: 'Edit' }]}
                  rows={data}
                >
                  <Box pad={{ bottom: 'spacing12' }} direction="row" align="center" justify="between">
                    <Text
                      size="large"
                      weight="bold"
                      color="textBody"
                    >Dependents: {data.length || 0}
                    </Text>
                    <AppButton
                      onClick={() => setVisible(true)}
                      width="150px"
                    >
                      Add Dependent
                    </AppButton>
                  </Box>
                </FlexList>
              )}
            </>
          )}
        <AddDependentModal visible={visible} onSetVisible={setVisible} />
      </Box>
    </Box>
  );
};
export default Dependents;
