import { Option } from '@/common/types';

export enum EmployerStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Terminated = 'TERMINATED',
  PendingTermination = 'PENDING_TERMINATION',
  TerminationInProgress = 'TERMINATION_IN_PROGRESS',
  Null = 'Null',
  ExpressSetup = 'EXPRESS_SETUP',
  Abandoned = 'ABANDONED',
}

export const EMPLOYER_STATUSE_OPTIONS: Option[] = [
  {
    key: EmployerStatus.Null,
    value: 'All',
    title: 'All',
  },
  {
    key: EmployerStatus.Pending,
    value: 'Pending',
    title: 'Pending',
  },
  {
    key: EmployerStatus.Active,
    value: 'Active',
    title: 'Active',
  },
  {
    key: EmployerStatus.Terminated,
    value: 'Terminated',
    title: 'Terminated',
  },
  {
    key: EmployerStatus.PendingTermination,
    value: 'Pending Termination',
    title: 'Pending Termination',
  },
  {
    key: EmployerStatus.TerminationInProgress,
    value: 'Termination In Progress',
    title: 'Termination In Progress',
  },
  {
    key: EmployerStatus.ExpressSetup,
    value: 'Express Setup',
    title: 'Express Setup',
  },
  {
    key: EmployerStatus.Abandoned,
    value: 'Abandoned',
    title: 'Abandoned',
  },
];
