import React from 'react';
import { Box } from '@common-fe/common-fe';

import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PLAN_MODULES } from '../../plan.constants';

import GracePeriod from './GracePeriod';
import { useCoverageFields } from './hooks';
import PayClaims from './PayClaims';

interface Props {
  isEditMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
  isTemplateMode?: boolean;
}
const Coverage: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const fields = useCoverageFields();
  if (fields.length === 0) {
    return null;
  }
  return (
    <ModuleWrapper
      anchorId={PLAN_MODULES.COVERAGE}
      title="Coverage"
      expandless
    >
      <Box>
        <GracePeriod
          isEditMode={isEditMode}
          isTemplateMode={isTemplateMode}
          onDirty={onDirty}
        />
      </Box>
      <Box margin={{ top: 'spacing24' }}>
        <PayClaims
          isTemplateMode={isTemplateMode}
          isEditMode={isEditMode}
          onDirty={onDirty}
        />
      </Box>
    </ModuleWrapper>
  );
};
export default React.memo(Coverage);
