import { useEffect, useMemo } from 'react';

import { storeCreator } from '@/utils/store/store.utils';

interface Store {
  minify: boolean;
  setMinify(val: boolean): void;
  setPinned(val: boolean): void;
  pinned: boolean;
  expandSidebar(expand: boolean): void;

}

const useStore = storeCreator<Store>((set) => ({
  minify: false,
  pinned: true,
  setMinify: (minify) => set({
    minify,
  }),
  setPinned: (pinned) => set({
    pinned,
  }),
  expandSidebar: (expand) => set({
    pinned: expand,
    minify: !expand,
  }),
}));

export const useSidebarStore = (defaultMinify?: boolean) => {
  const store = useStore();

  useEffect(() => {
    if (defaultMinify !== undefined) {
      store.setMinify(defaultMinify);
      store.setPinned(!defaultMinify);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({
    ...store,
  }), [store]);
};
