import React from 'react';

import { Document } from '@/common/types';
import useReadTextFromFile from '@/components/elements/DocumentViewer/hooks/useReadTextFromFile';

import ZoomContainer from './ZoomContainer';

interface Props {
  zoom: number;
  receipt?: Document;
}

const TxtViewer: React.FC<Props> = ({ zoom, receipt }) => {
  const { text } = useReadTextFromFile(receipt?.link);

  return (
    <ZoomContainer zoom={zoom}>
      <pre>
        {text}
      </pre>
    </ZoomContainer>
  );
};

export default TxtViewer;
