import React, { useCallback, useEffect } from 'react';
import { AppButton, Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { ModalWrapper } from '@/components/wrappers';
import { useHistory } from '@/modules/core/hooks';

import { ScheduledContributionState } from './stores/useScheduledContribution.store';
import { useScheduledContributionFields, useValidateScheduledContribution } from './hooks';
import { ScheduledContributionPayload } from './ScheduledContribution.types';
import { useScheduledContributionStore } from './stores';

const MODAL_TITLE = 'Set up Payroll Contribution Schedule';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  isEditMode?: boolean;
  defaultValues?: Partial<ScheduledContributionPayload>;
  onNext?: () => void;
}

export const ScheduledContributionCreateModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  modalTitle = MODAL_TITLE,
  defaultValues,
  onNext,
  isEditMode,
}) => {
  const history = useHistory();
  const calendarId = useScheduledContributionStore((state) => state.state.calendarId);
  const handleSetState = useScheduledContributionStore((state) => state.setState);
  const handleReset = useScheduledContributionStore((state) => state.handleReset);
  const { setValues, values } = useDebounceState<Partial<ScheduledContributionState>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  const handleSetVisible = useCallback(() => {
    if(isEditMode) {
      handleReset();
    }
    onSetVisible(false);
    
  }, [handleReset, isEditMode, onSetVisible]);
  const fields = useScheduledContributionFields({
    defaultValues,
    isEditMode
  });
  useEffect(() => {
    if(!isEditMode && visible) {
      handleReset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isEditMode, visible]);

  const { isSubmitted, handleValidate} = useValidateScheduledContribution({
    fields, 
    state: values,
    onSuccess: () => {
      if(onNext) {
        onNext();
      } else {
        history.push(ROUTES.SCHEDULED_CONTRIBUTION_SUMMARY);
      }
     
    }
  });
  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={visible}
      onSetVisible={handleSetVisible}
      title={modalTitle}
      titleBoxStyle={{ paddingBottom: '16px' }}
    >
      <Box key={calendarId} data-testid="ScheduledContributionForm_container" round="container1Round" background="canvas">
        <Box round="moduleRound" pad="l" background="module">
          <Box round="container1Round" background="canvas">
            <FlexControlledForm
              fields={fields}
              editMode
              shouldControlValues
              showError={isSubmitted}
              onChangeValues={setValues}
              isModalType
            />
          </Box>
        </Box>
        <Box direction="row"  justify="end" margin={{ top: 'l' }}>
          <AppButton
            testId="ScheduledContributionForm_cancel"
            buttonType="secondary"
            onClick={handleSetVisible}
            width="160px">
            Cancel
          </AppButton>
          <Box margin={{ left: 's' }}>
            <AppButton onClick={handleValidate} width="160px">Next (1/2)</AppButton>
          </Box>
        </Box>
      </Box>
    
    </ModalWrapper>
  );
};

export default ScheduledContributionCreateModal;
