import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { formattingNumberValue,ListItem } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { MINIMIZE_DATE_FORMAT } from '@/common/constants';
import routes from '@/common/routes';
import {
  ListItemType, Pagination, PaginationParams,
} from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';

interface BalanceHistoryCloseResponse {
  our_balance: number | null,
  bank_balance: number | null,
  difference: number | null,
  prior: number | null,
  delta: number | null;
  credit: number | null;
  debit: number | null;
  close_date: string;
}

export interface AccountActivityResponse {
  account_number: string;
  account_type: string;
  account_description: string;
  balance_history_closes: Pagination<BalanceHistoryCloseResponse>;
}

export interface AccountActivity {
  id: string;
  closeDate: Date;
  ourBalance: number;
  bankBalance: number;
  difference: number;
  prior: number;
  delta: number;
  credit: number;
  debits: number;
}

type ReportAccountActivityParameters = PaginationParams & {
  account_id: string,
  start_date: string,
  end_date: string,
};

const BOLD_STYLE: React.CSSProperties = {
  fontWeight: 700,
};

export const formattingReportAccountListItemFields = (
  item?: BalanceHistoryCloseResponse,
  onPick?: () => void,
): ListItem => ({
  id: _.uniqueId(),
  onPick,
  fields: [
    {
      key: 'closeDate',
      type: ListItemType.Text,
      title: item?.close_date ? dayjs(item.close_date).format(MINIMIZE_DATE_FORMAT) : '-',
      style: {
        ...BOLD_STYLE,
      },
      ellipsisMode: true,
    },
    {
      key: 'ourBalance',
      type: ListItemType.Text,
      title: item?.our_balance ? formattingNumberValue(item?.our_balance) : '-',
    },
    {
      key: 'bankBalance',
      type: ListItemType.Text,
      title: item?.bank_balance ? formattingNumberValue(item?.bank_balance) : '-',
    },
    {
      key: 'difference',
      type: ListItemType.Text,
      title: item?.difference ? formattingNumberValue(item?.difference) : '-',
    },
    {
      key: 'prior',
      type: ListItemType.Text,
      title: item?.prior ? formattingNumberValue(item?.prior) : '-',
    },
    {
      key: 'delta',
      type: ListItemType.Text,
      title: item?.delta ? formattingNumberValue(item?.delta) : '-',
    },
    {
      key: 'credit',
      type: ListItemType.Text,
      title: item?.credit ? formattingNumberValue(item?.credit) : '-',
    },
    {
      key: 'debits',
      type: ListItemType.Text,
      title: item?.debit ? formattingNumberValue(item?.debit) : '-',
    },
  ],
});

export const getReconciliationReportActivityList = (
  id: string,
  params: ReportAccountActivityParameters,
) => api
  .get<AccountActivityResponse>(
    PATHS.RECONCILIATION_CLOSES_BY_ACCOUNT,
    {
      params: {
        account_id: params.account_id,
        end_date: params.end_date,
        start_date: params.start_date,
        page: params.page,
        size: params.perPage,
      },
    },
  );

export const useReconciliationReportActivityList = (
  id: string,
  params: ReportAccountActivityParameters,
  enabled = true,
) => {
  const { push } = useHistory();
  const {
    isLoading, error, data, isSuccess, refetch,
  } = useQuery(
    ['ReconciliationBankAccounts', id, params],
    () => getReconciliationReportActivityList(
      id,
      params,
    ),
    {
      enabled,
    },
  );

  const formattedList = useMemo(() => data?.data.balance_history_closes.content
    .map(
      (item: BalanceHistoryCloseResponse) => formattingReportAccountListItemFields(
        item,
        () => push(routes.ACCOUNT_ACTIVITY_TRANSACTIONS(id), {
          [QueryKeys.TRANSACTIONS_CLOSE_DATE]: dayjs(item.close_date).format(MINIMIZE_DATE_FORMAT),
        }),
      ),
    ), [data, id, push]);

  const total = useMemo<number>(() => _.get(data, 'data.balance_history_closes.totalElements', 0) || 0, [data]);
  return {
    data: formattedList as ListItem[] || [],
    response: data?.data,
    total,
    error,
    isLoading,
    isSuccess,
    refetch,
  };
};
