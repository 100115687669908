import React, { useMemo } from 'react';
import {
  ActionCard,
  AppButton,
  Box,
  Inscription,
  SaveEmployerActiveIcon,
  SaveEmployerInactiveIcon,
  SavePartnerActiveIcon,
  SavePartnerInactiveIcon,
} from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { useTOCConfiguration } from '@/modules/employer/hooks/useTOCConfiguration';

import BoxOfRequiredFields, { RequiredFieldPropertie } from './BoxOfRequiredFields';

const ICON_HEIGHT = '72px';

interface Props {
  requiredFieldProperties?: RequiredFieldPropertie[];
  onSubmit: () => void;
  disabled?: boolean;
  submitted?: boolean;
  children?: React.ReactNode;
  isEditMode?: boolean;
}
const SaveEmployerCard: React.FC<Props> = ({
  onSubmit,
  disabled,
  children,
  submitted,
  isEditMode,
  requiredFieldProperties,
}) => {
  const { mode } = useSetupEmployerMode();
  const title = useMemo(() => capitalize(mode), [mode]);
  const config = useTOCConfiguration();

  const iconEmployer = useMemo(() => (
    disabled
      ? <SaveEmployerInactiveIcon height={ICON_HEIGHT} />
      : <SaveEmployerActiveIcon height={ICON_HEIGHT} />
  ), [disabled]);

  const iconPartner = useMemo(() => (
    disabled
      ? <SavePartnerInactiveIcon height={ICON_HEIGHT} />
      : <SavePartnerActiveIcon height={ICON_HEIGHT} />
  ), [disabled]);

  return (
    <ActionCard
      testId="SaveEmployerCard"
      title={`Save the ${title}`}
      submitted={submitted}
      icon={mode === EmployerSetupModes.employer
        ? iconEmployer
        : iconPartner}
      {...(isEditMode ? {
        text: `${mode === EmployerSetupModes.employer ? 'ER' : title} status is Pending until it is activated`,
        footer: requiredFieldProperties && requiredFieldProperties.length ? (
          <>
            <BoxOfRequiredFields
              tocConfig={config}
              requiredFieldProperties={requiredFieldProperties}
            />
            <Inscription
              lineHeight="18px"
              color="textSecondary"
              size="small"
              margin={{ top: 'spacing24' }}
              weight={500}
            >
              All other attributes for other modules are optional.
            </Inscription>
          </>
        ) : null,
      } : {})}
    >
      <Box direction="column" align="center" width="100%">
        <Box
          direction="row"
          justify="stretch"
          align="stretch"
          width="100%"
        >
          <AppButton
            disabled={disabled}
            onClick={onSubmit}
            size="L"
            width="100%"
          >
            {`Save the ${title}`}
          </AppButton>
        </Box>
        {children}
      </Box>
    </ActionCard>
  );
};
export default SaveEmployerCard;
