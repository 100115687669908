import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';

export const useSetupEmployerMode = () => {
  const {
    path,
    params,
    url,
  } = useRouteMatch<{ id: string }>();
  const id = useMemo(() => params.id, [params.id]);
  const mode: EmployerSetupModes = useMemo(
    () => {
      if (path.includes(ROUTES.PARTNERS)) {
        return EmployerSetupModes.partner;
      }

      if (path.includes(ROUTES.DISTRIBUTORS)) {
        return EmployerSetupModes.distributor;
      }

      if (path.includes(ROUTES.EMPLOYERS)) {
        return EmployerSetupModes.employer;
      }

      if (path.includes(ROUTES.SUBSIDIARIES)) {
        return EmployerSetupModes.subsidiary;
      }

      return EmployerSetupModes.system;
    }, [path],
  );

  const history = useHistory();
  const editMode = useMemo(
    () => (
      path === ROUTES.SYSTEM_EDIT
      || path === ROUTES.PARTNERS_EDIT()
      || path === ROUTES.DISTRIBUTORS_EDIT()
      || path === ROUTES.EMPLOYERS_EDIT()
      || path === ROUTES.SUBSIDIARY_EDIT()
    ) && Boolean(params.id),
    [path, params],
  );
  const viewMode = useMemo(
    () => (
      path === ROUTES.SYSTEM_VIEW
      || path === ROUTES.PARTNERS_VIEW()
      || path === ROUTES.DISTRIBUTORS_VIEW()
      || path === ROUTES.EMPLOYERS_VIEW
      || path === ROUTES.SUBSIDIARY_VIEW()
    )
      && Boolean(params.id),
    [params.id, path],
  );
  const createMode = useMemo(
    () => (
      path === ROUTES.PARTNERS_CREATE
      || path === ROUTES.DISTRIBUTORS_CREATE
      || path === ROUTES.EMPLOYERS_CREATE
      || path === ROUTES.DISTRIBUTORS_CREATE
    ) && !params.id,
    [params.id, path],
  );
  const toEdit = useCallback(() => {
    history.push(`${url}/edit`);
  }, [history, url]);

  const redirectToHome = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);
  return {
    redirectToHome,
    editMode,
    viewMode,
    createMode,
    employerID: id,
    toEdit,
    mode,
  };
};
