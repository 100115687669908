import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ClaimsTeamConfig, ClaimsTeamConfigPayload, TeamPayload } from '@/modules/employer/components/Claims/ClaimsProcessors/ClaimsProcessors.types';

const formatDataToPayload = (data: ClaimsTeamConfig): ClaimsTeamConfigPayload => ({
  name: data.name,
  allowed_account_types: data.allowedAccountTypes,
  assigned_organizations_ids: data?.assignedOrganizationIds ? data.assignedOrganizationIds : [],
});

const useCreateClaimsTeamQuery = (
  onSuccess?: () => void,
) => {

  const { mutateAsync, isLoading } = useMutation(
    (value: ClaimsTeamConfig) => api.post<TeamPayload>(
      PATHS.CLAIMS_TEAMS,
      formatDataToPayload(value),
    ),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
    },
  );

  const handleSave = useCallback(async (value: ClaimsTeamConfig) => {
    const data = await mutateAsync(value as ClaimsTeamConfig);
    const justCreatedTeam = data?.data;
    return justCreatedTeam;
  }, [mutateAsync]);

  return {
    isLoading,
    create: handleSave,
  };
};

export default useCreateClaimsTeamQuery;
