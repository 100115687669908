export enum CarrierConfigPurpose {
  ClaimCreation = 'Claim creation',
  Adjudication = 'Adjudication',
  Null = 'Null',
}

export const CARRIER_CONFIG_PURPOSES = [
  {
    key: CarrierConfigPurpose.Null,
    value: CarrierConfigPurpose.Null,
    title: 'All',
  },
  {
    key: CarrierConfigPurpose.ClaimCreation,
    value: CarrierConfigPurpose.ClaimCreation,
    title: CarrierConfigPurpose.ClaimCreation,
  },
  {
    key: CarrierConfigPurpose.Adjudication,
    value: CarrierConfigPurpose.Adjudication,
    title: CarrierConfigPurpose.Adjudication,
  },
];
