import React from 'react';
import {
  Button,
  Inscription,
  SiblingBoxesWithSpacing,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import baseTheme from '@/styles/theme';

interface ButtonProps {
  isActive?: boolean;
  disabled?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
  background: ${({ isActive, theme }) => `${isActive ? theme.colors.canvas : 'transparent'}`};
  border: ${({ isActive, theme }) => `1px solid ${isActive ? theme.colors.border2 : 'transparent'}`};
  padding: 4px 23px;
  span {
    color: ${({ isActive, theme, disabled }) => {
    if (disabled) return;
    return isActive ? theme.colors.textBody : theme.colors.textAccent;
  }};
  }
`;

interface Props {
  isTeamsList: boolean;
  setIsTeamsList: (isTeamsList: boolean) => void;
  totalProcessors: number;
  totalTeams: number;
}

const FilterSection: React.FC<Props> = ({
  totalProcessors,
  totalTeams,
  isTeamsList,
  setIsTeamsList,
}) => (
  <SiblingBoxesWithSpacing
    space={baseTheme.spacings.spacing24}
    direction="row"
    background="module"
    pad="spacing4"
    round="container1Round"
    align="center"
  >
    <SiblingBoxesWithSpacing>
      <StyledButton
        label={<Inscription weight={isTeamsList ? 'normal' : 'bold'}>{`Claims Processors (${totalProcessors})`}</Inscription>}
        type="button"
        borderless
        isActive={!isTeamsList}
        {...(totalProcessors === 0 ? {
          disabled: true,
        } : {
          onClick: () => setIsTeamsList(false),
        })}
      />
    </SiblingBoxesWithSpacing>

    <SiblingBoxesWithSpacing>
      <StyledButton
        label={<Inscription weight={isTeamsList ? 'bold' : 'normal'}>{`Claims Teams (${totalTeams})`}</Inscription>}
        type="button"
        borderless
        isActive={isTeamsList}
        {...(totalTeams === 0 ? {
          disabled: true,
        } : {
          onClick: () => setIsTeamsList(true),
        })}
      />
    </SiblingBoxesWithSpacing>
  </SiblingBoxesWithSpacing>
);

export default FilterSection;
