import { useCallback, useState } from 'react';
import { Field } from '@common-fe/common-fe';
import _ from 'lodash';

import useValidationUtils from '@/utils/hooks/useValidationUtils';

import { ScheduledContributionState } from '../stores/useScheduledContribution.store';
interface Params {
  fields: Field[];
  state: Partial<ScheduledContributionState> | null;
  onSuccess: () => void;
}
const useValidateScheduledContribution =  ({ fields, state, onSuccess }: Params) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { getValidateFields, getErrors } = useValidationUtils();
  const validations = getValidateFields(fields);
  const handleValidate = useCallback(() => {
    setSubmitted(true);
    const isError = !_.isEmpty(getErrors(state, validations));
    
    if(isError) {
      return;
    }
    onSuccess();
  }, [getErrors, onSuccess, state, validations]);


  return {
    handleValidate,
    isSubmitted,
    setSubmitted,
  };
};

export default useValidateScheduledContribution;