import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access, Topbar } from '@/modules/core/components';
import { useBreadcrumbs, useCurrentOrganization, useHistory } from '@/modules/core/hooks';
import { ProcessingRules } from '@/modules/ProcessingDefinition/ProcessingRule';
import theme from '@/styles/theme';

const ProcessingRulesPage = () => {
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();

  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  useBreadcrumbs([{
    route: ROUTES.PROCESSING_DEFINITION,
    title: 'Processing Definition',
  }, {
    route: ROUTES.PROCESSING_RULES,
    title: 'Processing Rules',
  }]);
  if (!type) {
    return null;
  }
  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_PROCESSING_DEFINITION },
      ]}
      accessDeniedAction={goHome}
    >
      <Box
        width="100%"
        margin={{ top: '0px' }}
      >
        <Box>

          <Topbar isSticky />

          <Box align="center">
            <Box
              width={theme.defaultContentWidth}
            >

              <ProcessingRules isPageMode />

            </Box>
          </Box>
        </Box>
      </Box>
    </Access>
  );
};
export default ProcessingRulesPage;
