import { onlyDateFromServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';

import { EmployeePayload, EmployerStatuses, OrganizationStatus } from '../Employee.types';
import { PersonalInformationPayload } from '../PersonalInformation/hooks/usePersonalInformationState';

const getFormattedDate = (value?: string) => {
  if (value) {
    return dayjs(value, RAW_DATE_FORMAT).format(DEFAULT_DATE_FORMAT);
  }
  return '';
};
export default (currentData?: EmployeePayload) => {
  if (!currentData) {
    return null;
  }
  const lastStatus = _.first(currentData.employment_statuses);
  const terminationDate =
    lastStatus && lastStatus.status === EmployerStatuses[1].key
      ? onlyDateFromServer(lastStatus.valid_from)
      : null;
  const payload: Partial<PersonalInformationPayload> = {
    personId: currentData.person_id,
    firstName: currentData.first_name,
    fullName: currentData.full_name,
    lastName: currentData.last_name,
    middleName: currentData.middle_name,
    suffix: currentData.suffix,
    ssn: currentData.ssn || '',
    gender: currentData.gender,
    employeeEmail: `${currentData.employee_provided_email || ''}`,
    employerEmail: `${currentData.employer_provided_email || ''}`,

    sourceId: currentData?.organization_id_source_type,

    employerEmployeeId: currentData.employer_employee_id || '',
    internalId: `${currentData.employee_id || ''}`,
    partnerEmployeeId: currentData.partner_employee_id || '',
    employerName: currentData.employer_name,
    birthDate: getFormattedDate(currentData.birth_on),
    firstPhone: currentData.phone1?.number,
    firstPhoneType: currentData.phone1?.phone_type,
    secondPhone: currentData.phone2?.number,
    secondPhoneType: currentData.phone2?.phone_type,

    firstAddressLine: currentData.mailing_address?.line1,
    secondAddressLine: currentData.mailing_address?.line2,
    city: currentData.mailing_address?.city,
    state: currentData.mailing_address?.state,
    zipCode: currentData.mailing_address?.zipcode,
    countryCode: currentData.mailing_address?.country_code,
    physicalFirstAddressLine: currentData.physical_address?.line1,
    physicalSecondAddressLine: currentData.physical_address?.line2,
    physicalCity: currentData.physical_address?.city,
    physicalZipCode: currentData.physical_address?.zipcode,
    physicalState: currentData.physical_address?.state,
    physicalCountryCode: currentData.physical_address?.country_code,
    sameAsMailing: currentData.use_same_address_for_mailing,

    employeeEmailPrefer: !!currentData.is_employee_email_preferred,
    employerEmailPrefer: !currentData.employee_provided_email,
    employerStatus: currentData.status,
    hireDate: getFormattedDate(currentData.hire_on),
    terminationDate: getFormattedDate(terminationDate || ''),
    statusEffectiveDate: getFormattedDate(currentData.employment_status_effective_on),
    username: currentData.user_name,

    communicationConfigChannels: currentData?.user_communication_configuration?.communication_config_channels,
    notificationPriority: currentData?.user_communication_configuration?.notification_priority,

    organizationName: currentData?.organization_name,
    organizationId: `${currentData?.organization_id}`,
    organizationPath: currentData?.organization_path,

    homePhoneNumber: currentData?.home_phone_number,

    claimAutopayType: currentData?.claim_autopay_type,
    defaultClaimPayeeType: currentData?.default_claim_payee_type,

    ...(currentData?.employment_statuses?.length
      ? {
        employmentStatuses: currentData.employment_statuses.map((status) => ({
          ...(typeof status.is_active === 'boolean'
            ? {
              isActive: status.is_active,
            }
            : {}),
          ...(status?.status ? { status: status.status } : {}),
          ...(status?.valid_from ? { validFrom: onlyDateFromServer(status.valid_from) } : {}),
          ...(status?.valid_to ? { validTo: onlyDateFromServer(status.valid_to) } : {}),
        })),
      }
      : {}),
    status: (currentData?.status as OrganizationStatus) || null,
    payrollGroupId: currentData?.payroll_group_id,
    payrollGroupCode: currentData?.payroll_group_code,
    validFrom: currentData?.valid_from,

    customReportField1: currentData?.custom_field1,
    customReportField2: currentData?.custom_field2,
    customReportField3: currentData?.custom_field3,
    customReportField4: currentData?.custom_field4,
    customReportField5: currentData?.custom_field5,
  };
  return payload;
};
