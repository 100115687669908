import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface CardActivationProps {
  alternateAccountId: string,
  memberSequenceIdentifier: number;
}

export const useCardActivationQuery = (props: CardActivationProps) => {
  const { alternateAccountId, memberSequenceIdentifier } = props;

  const { mutateAsync, isLoading, status } = useMutation(
    () => api.post(`${PATHS.FISERV_CARD_ACTIVATION}?alternate_account_id=${alternateAccountId}&member_sequence_identifier=${memberSequenceIdentifier}`),
  );

  const handleActivate = async () => {
    await mutateAsync();
  };

  return {
    handleActivate,
    isLoading,
    status,
  };
};
