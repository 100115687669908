import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

interface Props {
  label: string;
  subLabel: string;
}
const EmailConfigurationLabel: React.FC<Props> = ({ label, subLabel }) => (
  <Box direction="column" pad={{ top: 'l', bottom: 'xs' }}>
    <Text size="xxlarge" weight={400}>{label}</Text>
    <Text
      weight={400}
      margin={{ top: 'xxs' }}
    >{subLabel}
    </Text>
  </Box>
);
export default EmailConfigurationLabel;
