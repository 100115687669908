import { storeCreator } from '@/utils/store/store.utils';

export enum TerminationSteps {
  SET_TERMINATION_DATE = 'TERMINATION',
  SET_TERMINATION_PARAMS = 'TERMINATION_SUCCESS',
}

interface State {
  modalVisible: boolean;
  employerId?: string;
  employerName?: string;
  step: TerminationSteps;
  terminationDate?: string;
  editMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalAction?: (value?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSetAdditionalAction: (value?: (value?: any) => void) => void;
  handleSetEditMode: (value?: boolean) => void;
  handleSetTerminationDate: (value?: string) => void;
  handleSetEmployerName: (value?: string) => void;
  handleSetEmployerId: (value?: string) => void;
  handleSetStep: (value: TerminationSteps) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  step: TerminationSteps.SET_TERMINATION_DATE,
  terminationDate: undefined,
  employerId: undefined,
  employerName: undefined,
  additionalAction: undefined,
  editMode: false,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetStep: (value) => set((state) => ({
    ...state,
    step: value,
  })),

  handleSetTerminationDate: (value) => set((state) => ({
    ...state,
    terminationDate: value,
  })),

  handleSetEmployerId: (value) => set((state) => ({
    ...state,
    employerId: value,
  })),

  handleSetEmployerName: (value) => set((state) => ({
    ...state,
    employerName: value,
  })),

  handleSetAdditionalAction: (value) => set((state) => ({
    ...state,
    additionalAction: value,
  })),

  handleSetEditMode: (value) => set((state) => ({
    ...state,
    editMode: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    step: TerminationSteps.SET_TERMINATION_DATE,
    terminationDate: undefined,
    employerId: undefined,
    employerName: undefined,
    modalVisible: false,
    additionalAction: undefined,
    editMode: false,
  })),
}));