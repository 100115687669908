import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { onlyDateToServer } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { RAW_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { EmployeeFields } from '../employeeForm.types';

export enum SERVER_ERROR {
  INTERNAL_SERVER_ERROR = 500,
  CONFLICT_SERVER_ERROR = 409,
  EMPLOYEE_ID_ALREADY_EXISTS_ERROR = 'EMPLOYEE_ID_ALREADY_EXISTS_ERROR',
}

interface NewEmployeePhone {
  number: string,
  phone_type: string,
}

interface NewEmployeeAddress {
  line1: string,
  line2?: string,
  city: string,
  state: string,
  zipcode: string,
}
interface NewEmployeeEmploymentInfo {
  employer_employee_id?: string,
  partner_employee_id?: string,
  employment_status_effective_on: string,
  employment_status: string,
}

interface NewEmployeeDemographics {
  ssn?: string,
  first_name: string,
  middle_name: string,
  last_name: string,
  suffix?: string,
  local_date_birth_on: string,
  gender: string,
}

export interface NewEmployeePayload {
  organization_id: number;
  employment_info: NewEmployeeEmploymentInfo,
  demographics_info: NewEmployeeDemographics,
  physical_address: NewEmployeeAddress,
  mailing_address: NewEmployeeAddress,
  phone1: NewEmployeePhone,
  phone2?: Partial<NewEmployeePhone>,
  employer_provided_email: string,
}

export interface NewEmployeeResponse {
  employee_id: number;
}

const POST_EMPLOYEE = 'createEmployee';

export const phoneNumberFormatter = (value?: string) => {
  if (!value) return undefined;

  const redundantSymbols = ['(', ')', ' ', '-'];
  return value
    .split('')
    .filter((char) => !redundantSymbols.includes(char))
    .join('')
    .slice(2);
};

const DEFAULT_ADRESS_PROPS = {
  country_code: 'US',
  address_type: 'UNKNOWN',
};

export const useCreateEmployee = (
  onServerError: (errorCode: SERVER_ERROR) => void,
) => {
  const history = useHistory();
  const { isLoading, mutateAsync } = useMutation(
    POST_EMPLOYEE,
    (newEmployee: NewEmployeePayload) => api
      .post<NewEmployeeResponse>(PATHS.ADD_EMPLOYEE, {
        ...newEmployee,
        ...newEmployee.physical_address ? {
          physical_address: {
            ...newEmployee.physical_address,
            ...DEFAULT_ADRESS_PROPS,
          },
        } : {},
        ...newEmployee.mailing_address ? {
          mailing_address: {
            ...newEmployee.mailing_address,
            ...DEFAULT_ADRESS_PROPS,
          },
        } : {},
      }),
    {
      onSuccess: (data) => {
        history.push(`${ROUTES.EMPLOYEE}/${data.data.employee_id}`);
      },
      onError: ({ response }) => {
        if (response.status.toString().startsWith('5')) onServerError(SERVER_ERROR.INTERNAL_SERVER_ERROR);
        if (response.status === SERVER_ERROR.CONFLICT_SERVER_ERROR) onServerError(SERVER_ERROR.CONFLICT_SERVER_ERROR);
        if (response.status === 400
          && response.data.elevate_error_message.includes('_employee_id_uniq_per_organization')
          && response.data.elevate_error_message.includes('already exists')) {
          onServerError(SERVER_ERROR.EMPLOYEE_ID_ALREADY_EXISTS_ERROR);
        }
      },
    },
  );

  const preparedNewEmployee = useCallback((employeeData: Partial<EmployeeFields>) => {
    const phone1 = {
      ...employeeData.phoneNumber1 ? {
        number: phoneNumberFormatter(employeeData.phoneNumber1 as string),
      } : {},
      ...employeeData.phone1Type ? { phone_type: employeeData.phone1Type } : {},
    };

    const phone2 = employeeData?.phoneNumber2
      ? {
        number: phoneNumberFormatter(employeeData.phoneNumber2 as string),
        phone_type: employeeData.phone2Type,
      }
      : null;

    const address = {
      line1: employeeData.line1,
      ...employeeData?.line2 ? { line2: employeeData.line2 } : {},
      city: employeeData.city,
      state: employeeData.state,
      zipcode: employeeData.zipCode,
    };

    const mailingAddress = employeeData?.mailingAddress
      ? {
        line1: employeeData.line1,
        city: employeeData.city,
        state: employeeData.state,
        zipcode: employeeData.zipCode,
        ...employeeData?.line2 ? { line2: employeeData.line2 } : {},
      }
      : {
        line1: employeeData.mailingLine1,
        city: employeeData.mailingCity,
        state: employeeData.mailingState,
        zipcode: employeeData.mailingZipCode,
        ...employeeData?.mailingLine2 ? { line2: employeeData.mailingLine2 } : {},
      };

    const demographicsInfo = {
      ...employeeData.ssn || employeeData.ssn_demographic
        ? { ssn: employeeData.ssn || employeeData.ssn_demographic }
        : {},
      first_name: employeeData.firstName,
      ...employeeData?.middleName ? { middle_name: employeeData.middleName } : {},
      last_name: employeeData.lastName,
      ...employeeData?.suffix ? { suffix: employeeData.suffix } : {},
      local_date_birth_on: dayjs(employeeData.dateOfBirth).format(RAW_DATE_FORMAT),
      ...employeeData.gender ? { gender: employeeData.gender } : {},
    };

    const employmentInfo = {
      employment_status_effective_on: onlyDateToServer(employeeData.employmentStatusEffectiveDate),
      employment_status: employeeData.employmentStatus,
      ...employeeData?.employerEmployeeId || employeeData?.employmentEmployerEmployeeId
        ? { employer_employee_id: employeeData.employerEmployeeId || employeeData?.employmentEmployerEmployeeId }
        : {},
      ...employeeData?.partnerEmployeeId || employeeData?.employmentPartnerEmployeeId
        ? { partner_employee_id: employeeData?.partnerEmployeeId || employeeData?.employmentPartnerEmployeeId }
        : {},
    };

    const preparedEmployeeData = {
      organization_id: Number(employeeData.subsidiary
        || employeeData.employer || employeeData.distributor),
      employment_info: employmentInfo,
      demographics_info: demographicsInfo,
      physical_address: address,
      mailing_address: mailingAddress,
      ...Object.keys(phone1).length && phone1?.number ? { phone1 } : {},
      ...phone2 && phone2?.number ? { phone2 } : {},
      employer_provided_email: employeeData.email || null,
      ...employeeData?.payrollGroupCode ? { payroll_group_code: employeeData.payrollGroupCode } : {},
    };

    return preparedEmployeeData as NewEmployeePayload;
  }, []);

  const handleSave = async (employeeData: Partial<EmployeeFields>) => {
    const preparedDataPayload = preparedNewEmployee(employeeData);
    await mutateAsync(preparedDataPayload);
  };

  return {
    onSave: handleSave,
    isLoading,
  };
};
