import React from 'react';
import { useParams } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { useHistory } from '@/modules/core/hooks';
import ExpressSetupActivate from '@/modules/ExpressSetup/ExpressSetupActivate';

const ExpressSetupActivatePage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  useBreadcrumbs([
    {
      title: 'Home',
      redirect: () => history.push(ROUTES.EXPRESS_SETUP_BY_ID(id)),
    },
    {
      route: ROUTES.EXPRESS_SETUPS_WIZARD_BY_ID(id),
      title: 'Setting Up Your Plans',
    },
  ], undefined, undefined, true);

  return (
    <ExpressSetupActivate />
  );
};

export default ExpressSetupActivatePage;
