import { OptionKey } from '@common-fe/common-fe';

import { GroupByFilter, LAST_4_DIGIT_KEY, SortByFilter, SortByKeys } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

export type GroupByType = [
  GroupByFilter | null,
  GroupByFilter | null,
];

export const DATE_RANGE_DEFAULT_VALUE: [Date | null, Date | null] = [null, null];
export const GROUP_BY_DEFAULT_VALUE: GroupByType = [
  GroupByFilter.OrgField,
  GroupByFilter.Partner,
];
export const SORT_BY_DEFAULT_VALUE = {
  [SortByKeys.orgSortDirection]: SortByFilter.OrgAsc,
  [SortByKeys.dateSortDirection]: SortByFilter.DateDesc,
};

interface State {
  search: string;
  invoiceId: string;
  dateRange: [Date | null, Date | null];
  groupBy: GroupByType;
  SSNOption: OptionKey;
  handleSetSSNOption: (value: OptionKey) => void;
  handleSetSearch: (value: string) => void;
  handleSetInvoiceId: (value: string) => void;
  handleSetDateRange: (dateRange: [Date | null, Date | null]) => void;
  handleSetGroupBy: (groupBy: GroupByType) => void;
  handleClear: () => void;
}

export const useReplenishmentInvoiceReportFilterStore = storeCreator<State>((set) => ({
  search: '',
  invoiceId: '',
  dateRange: DATE_RANGE_DEFAULT_VALUE,
  groupBy: GROUP_BY_DEFAULT_VALUE,
  SSNOption: LAST_4_DIGIT_KEY,
  handleSetSSNOption: (value: OptionKey) => set(() => ({
    SSNOption: value,
  })),
  handleSetSearch: (value: string) => set(() => ({
    search: value,
  })),
  handleSetInvoiceId: (value: string) => set(() => ({
    invoiceId: value,
  })),

  handleSetDateRange: (dateRange: [Date | null, Date | null]) => set((state) => ({
    ...state,
    dateRange,
  })),

  handleSetGroupBy: (groupBy: GroupByType) => set((state) => ({
    ...state,
    groupBy,
  })),

  handleClear: () => set((state) => ({
    ...state,
    search: '',
    invoiceId: '',
    dateRange: DATE_RANGE_DEFAULT_VALUE,
    groupBy: GROUP_BY_DEFAULT_VALUE,
  })),
}));
