import React, { useCallback, useMemo, useState } from 'react';
import { AppButton,Box, FlexControlledForm, Inscription } from '@common-fe/common-fe';
import { compact } from 'lodash';

import { ModalWrapper } from '@/components';
import theme from '@/styles/theme';

import { TaxType } from '../Contributions/contributions.types';

import useGetEditFormFields from './hooks/useGetEditFormFields';
import useRecipientsStore from './stores/useRecipients.store';
import getUpdatedContributionAccount from './utils/getUpdatedContributionAccount';
import InfoBlock from './InfoBlock';
import { ContributionAccount, Recipient } from './types';

export interface FormValues {
  employeeFullName?: string;
  planCode?: string;
  employeeContributionAmount?: string;
  employerContributionAmount?: string;
  contributionDateOn?: string;
  taxYear?: string;
  taxType?: TaxType;
  memo?: string;
}

interface Props {
  recipient?: Recipient;
  contributionAccount?: ContributionAccount;
  isVisible: boolean;
  close: () => void;
  taxYears?: string[];
  onSave?: () => void;
}

const EditDataModalPopup: React.FC<Props> = ({
  recipient,
  contributionAccount,
  isVisible,
  close,
  taxYears,
  onSave,
}) => {
  const { recipients, setRecipients } = useRecipientsStore();
  const [formValues, setFormValues] = useState<FormValues>();
  const isAllRecordsUpdatingMode = useMemo(() => !recipient?.employeeFullName || !contributionAccount?.planCode, [recipient, contributionAccount]);
  const fields = useGetEditFormFields({
    recipient,
    contributionAccount,
    taxYears,
  });
  const isEmpty = useMemo(() => !compact(Object.values(formValues || {}))?.length, [formValues]);

  const onSubmit = useCallback(() => {
    if (isAllRecordsUpdatingMode) {
      setRecipients(recipients?.map((item) => ({
        ...item,
        contributionAccounts: item?.contributionAccounts?.map((acc) => getUpdatedContributionAccount(acc, formValues)),
      })));
    } else {
      setRecipients(recipients?.map((item) => {
        if (item?.employeeId === recipient?.employeeId) {
          return {
            ...item,
            contributionAccounts: item?.contributionAccounts?.map((acc) => {
              if (acc?.planCode === contributionAccount?.planCode) {
                return getUpdatedContributionAccount(acc, formValues);
              }
              return acc;
            }),
          };
        }
        return item;
      }));
    }
    close();
    if (onSave) onSave();
  }, [formValues, recipients, setRecipients, close, onSave, isAllRecordsUpdatingMode, recipient, contributionAccount]);

  return (
    <ModalWrapper
      testId={isAllRecordsUpdatingMode ? 'update_all_records' : 'edit_one_record'}
      visible={isVisible}
      title={isAllRecordsUpdatingMode ? 'Update All Records' : 'Edit Employee Record'}
      onSetVisible={close}
      titleBoxStyle={{ paddingBottom: theme.spacings.spacing12 }}
    >
      {isAllRecordsUpdatingMode ? (
        <Box align="center" pad={{ bottom: 'spacing12' }}>
          <Inscription lineHeight="20px" color="textTitle">Identify values to be applied to all records.</Inscription>
        </Box>
      ) : null}

      <Box background="module" pad="spacing24" round="medium">
        <Box
          background="canvas"
          round="container1Round"
          border={{ color: 'border2', size: 'small' }}
          pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
          elevation="default"
        >
          <FlexControlledForm
            testId="edit_records"
            editMode
            fields={fields}
            isThinMode
            onChangeValues={setFormValues}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ margin: 0 }}
          />
        </Box>

        <Box margin={{ top: 'spacing24' }} direction="row" justify="end" background="canvas" round="container1Round">
          {isAllRecordsUpdatingMode ? <InfoBlock /> : null}
        </Box>
      </Box>

      <Box margin={{ top: 'spacing24' }} direction="row" justify="end">
        <Box margin={{ right: 'spacing12' }}>
          <AppButton width="160px" buttonType="secondary" onClick={close}>Cancel</AppButton>
        </Box>
        <AppButton width="160px" buttonType="primary" onClick={onSubmit} disabled={isEmpty}>Save</AppButton>
      </Box>
    </ModalWrapper>
  );
};

export default EditDataModalPopup;
