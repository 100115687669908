import React from 'react';
import { AppButton, Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import ModalWrapper from '@/components/wrappers/ModalWrapper';

interface AnimatedTextProps {
  showed?: boolean;
}
const AnimatedText = styled(Text)<AnimatedTextProps>`
  opacity: ${({ showed }) => (showed ? 1 : 0)};
  transition: all 0.25s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.58, 1);
`;

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  children: React.ReactNode;
  buttonText: string;
  minButtonSize?: number;
  required?: boolean;
  disabled?: boolean;
  successTextShow?: boolean;
  successText?: string;
  hide?: boolean;
  testId?: string;
}

const DEFAULT_BUTTON_SIZE = 175;
const PlanModalWrapper: React.FC<Props> = ({
  modalTitle,
  successText,
  children,
  testId = '',
  buttonText,
  minButtonSize,
  required,
  onSetVisible,
  visible,
  successTextShow,
  disabled,
  hide,
}) => (
  <Box direction="row" align="center">
    <ModalWrapper
      testId={testId}
      visible={visible}
      title={modalTitle}
      onSetVisible={onSetVisible}
    >
      {children}
    </ModalWrapper>
    {!hide && (

      <AppButton
        disabled={disabled}
        testId={`${testId}_modal_open`}
        onClick={() => onSetVisible(true)}
        width={`${minButtonSize || DEFAULT_BUTTON_SIZE}px`}
        style={{
          padding: 0,
        }}
      >
        {buttonText}
      </AppButton>

    )}

    {successText
      && (
        <Box margin={{ left: 'spacing24' }}>
          <AnimatedText
            data-testId={`${testId}_success_text`}
            // data-testid={`animated-text-showed-${successTextShow}`}
            showed={successTextShow}
            size="medium"
            color="success"
          >{successText}
          </AnimatedText>
        </Box>
      )}

    {required && (
      <Box direction="row" justify="end" style={{ flex: 1 }}>
        <Text
          size="small"
          weight={500}
          color="danger"
          data-testId={`${testId}_required`}
        >Required
        </Text>
      </Box>
    )}
  </Box>
);

export default PlanModalWrapper;
