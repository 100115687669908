import React, { useEffect,useState } from 'react';
import {
  Box, Button, ChevronDownIcon, ChevronUpIcon, Heading,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  HealthPlan,
  HealthPlanStatuses,
} from '@/modules/HealthPlan/HealthPlan.types';

const Wrapper = styled(Box)`

  border-bottom: solid 0.5px ${({ theme }) => theme.colors.border1};
  &:last-child {
    border-bottom: none;
  }
`;
interface Props {
  title: string;
  children?: React.ReactNode;
  status: HealthPlanStatuses;
  anchorId: HealthPlan['status'];
  testId?: string;
}

const PlanTypeWrapper: React.FC<Props> = ({
  anchorId, title, children, status, testId = '',
}) => {
  const [expanded, setExpanded] = useState(localStorage.getItem(status) !== 'false');
  useEffect(() => {
    localStorage.setItem(status, `${expanded}`);
  }, [expanded, status]);
  return (
    <Wrapper
      id={anchorId}
      pad={{ bottom: 'small' }}
      margin={{ bottom: 'medium' }}
      data-testId={`${testId}_wrapper`}

    >
      <Button
        data-testId={`${testId}_wrapper_title`}
        onClick={() => setExpanded(!expanded)}
      >
        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box margin={{ right: 'spacing16' }}>
            {expanded ? (
              <ChevronUpIcon
                color="iconPrimary"
                data-testId={`${testId}_chevron-up`}
              />
            ) : (
              <ChevronDownIcon
                color="iconPrimary"
                data-testId={`${testId}_chevron-down`}
              />
            ) }
          </Box>
          <Heading
            level={4}
            color="textTitle"
            size="small"
            data-testId={`${testId}_title`}
          >{title}
          </Heading>
        </Box>
      </Button>
      {expanded && (
        <Box
          pad="spacing16"
          data-testId={`${testId}_content`}
          background="module"
          round="moduleRound"
        >
          {children}
        </Box>
      )}

    </Wrapper>
  );
};

export default PlanTypeWrapper;
