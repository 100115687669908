import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
// import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
  FALSE_KEY,
  NO_KEY,
  RAW_DATE_FORMAT,
  TRUE_KEY,
  YES_KEY,
} from '@/common/constants';
import { Plan } from '@/modules/plan/plan.types.ts';
import { getEncryptionByPubKey } from '@/utils/hooks/useEncryptData';

import { Copay, ExpressSetupProperty, ExpressSetupStatuses } from '../ExpressSetup.types';
import useBankAccountStore from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/stores/useBankAccount.store';
import { useCopayStore } from '../ExpressSetupWizard/PlansWizard/Copays/stores';
import { useUpdateExpressSetupByIdQuery } from '../queries';
import { EXPRESS_SETUP_QUERY_KEY } from '../queries/useGetExpressSetupById.query';

import useExpressSetupState from './useExpressSetupState';

const getBooleanFromString = (value?: string | boolean) => {
  if (value === true || value === YES_KEY || value === TRUE_KEY) {
    return true;
  }
  if (value === false || value === NO_KEY || value === FALSE_KEY) {
    return false;
  }
  return undefined;
};

export default (errorCallback:(error: unknown) => void,) => {
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const state = useExpressSetupState();
  const bankAccountSourceState = useBankAccountStore((state) => state.sourceState);
  const isReadyToSaveCopays = useCopayStore((state) => state.state.isReadyToSaveCopays);
  const queryClient = useQueryClient();
  const mutation = useUpdateExpressSetupByIdQuery(id, errorCallback);
  const getNumberWithZero = (value?: string | number) => (value === '' || value === undefined || value === null
    ? undefined : _.toNumber(value));

  const formatedContact = useMemo(() => {  
    return {
      id: state?.contact?.id ? _.toNumber(state.contact.id) : undefined,
      user_name: state?.contact?.userName,
      first_name: state?.contact?.firstName,
      last_name: state?.contact?.lastName,
      email: state?.contact?.email,
      phone: state?.contact?.phoneNumber,
      is_primary: getBooleanFromString(state?.contact?.isPrimary),
      role: state?.contact?.role,
      is_access_to_setup: getBooleanFromString(state?.contact?.isAccessToSetup),
    };
  }, [state]);

  const formatedPlans = useMemo(() => {  
    return state.plans?.map((plan: Plan) => {
      return {
        name: plan.name,
        parent_id: _.toNumber(plan.id),
        account_type: plan?.type,
      };
    });
  }, [state]);

  const formattedCopays = useMemo(() => {  
    return state.copays?.map((copay: Copay) => {
      return {
        amount: getNumberWithZero(copay.amount),
        category_type: copay?.categoryType,
      };
    });
  }, [state]);

  const formatBankAccount = async () => {
    let encryptData = {};
    if (state?.bankAccountState?.accountNumber && !state?.bankAccountState?.accountNumber.includes('****')) {
      encryptData = await getEncryptionByPubKey({
        encrypted_number: state?.bankAccountState?.accountNumber,
      });
    }
    return {
      name: state?.bankAccountState?.bankAccountName,
      account_category: state?.bankAccountState?.accountCategory,
      routing_transit_number: state?.bankAccountState?.routingTransitNumber,
      ...(
        state?.bankAccountState?.accountNumber && state?.bankAccountState?.accountNumber.includes('****')
          ? { encrypted_number: bankAccountSourceState?.encryptedNumber }
          : encryptData
      ),
    };
  };
  
  const formattedAddress = useMemo(() => {  
    return {
      city: state?.address?.city,
      id: state?.address?.id,
      line1: state?.address?.street,
      line2: state?.address?.street_2,
      state: state?.address?.state,
      zipcode: state?.address?.zipCode,
    };
  }, [state]);

  const formatedData = useCallback(async (type: ExpressSetupProperty, isRemoveMode?: boolean) => {
    let payload = {};
    switch (type) {
    case ExpressSetupProperty.Contacts:
      payload = {
        contacts: [formatedContact],
        json_record: {},
      };
      break;
    case ExpressSetupProperty.BankAccount: {
      if (isRemoveMode) {
        payload = {
          json_record: {
            account: {},
          },
        };
      } else {
        const formattedBankAccount = await formatBankAccount();
        payload = {
          json_record: {
            account: formattedBankAccount,
          },
        };
      }
      break;
    }
    case ExpressSetupProperty.PlanYear:
      payload = {
        json_record: {
          plan_year: {
            valid_from: dayjs(state?.planYear?.planStartDate).format(RAW_DATE_FORMAT),
            valid_to: dayjs(state?.planYear?.planEndDate).format(RAW_DATE_FORMAT),
          },
        },
      };
      break;
    case ExpressSetupProperty.Plans:
      payload = {
        json_record: {
          plans: formatedPlans,
          copays: isReadyToSaveCopays ? formattedCopays : [],
        },
      };
      break;
    case ExpressSetupProperty.Address:
      payload = {
        json_record: {
          address: formattedAddress,
        },
      };
      break;
    }

    return payload;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatedContact, formatedPlans, formattedCopays, isReadyToSaveCopays, state]);

  const handleSave = useCallback(async (type: ExpressSetupProperty, isRemoveMode?: boolean) => {
    const payload = await formatedData(type, isRemoveMode);
    await mutation.mutateAsync({
      dto: {
        ...payload,
        status_type: ExpressSetupStatuses.DRAFT,
      },
    });
   
    queryClient.invalidateQueries([EXPRESS_SETUP_QUERY_KEY, id]);
  }, [formatedData, id, mutation, queryClient]);

  return {
    handleSave,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
  };
};
