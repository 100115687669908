import React from 'react';
import {
  Box,
  Text,
} from '@common-fe/common-fe';

import { Delimiter } from '@/modules/ExpressSetup/styles/ExpressSetupWizard.styles.ts';

interface Props {
  title: string;
  accounts?: string[];
  description: React.ReactNode | string;
  isLastItem?: boolean;
  isPageMode?: boolean;
}

const ContainerItem: React.FC<Props> = ({
  title,
  accounts,
  description,
  isLastItem,
  isPageMode,
}) => (
  <Box gap="xxxs">
    <Text weight={700} color="textBody" margin={{ bottom: 'xxxs' }} style={{ lineHeight: '20px' }}>{title}</Text>
    {
      accounts && (
        <Box direction="row" margin={{ bottom: 'xxxs' }}>
          <Text style={{ lineHeight: '20px' }} color="textBody">
            Accounts: <Text weight={700} style={{ lineHeight: '20px' }} color="textBody">{accounts.join(', ')}</Text>
          </Text>
        </Box>
      )
    }
    {typeof description === 'string' ? <Text color="textBody" style={{ lineHeight: '20px' }}>{description}</Text> : description}
    {!isPageMode && !isLastItem && <Delimiter margin={{ vertical: 'm' }} />}
  </Box>
);

export default ContainerItem;
