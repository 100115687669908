export enum PayrollCalendarFormFields {
  CALENDAR_NAME = 'calendarName',
  FREQUENCY = 'frequency',
  START_DATE = 'startDate',
  DAY_OF_MONTH = 'dayOfMonth',
  DAY_OF_WEEK = 'dayOfWeek',
  BI_WEEKLY_DAY_OF_WEEK = 'biWeeklyDayOfWeek',
  SEMI_MONTHLY_DAY_OF_MONTH_1 = 'semiMonthlyDayOfMonth1',
  SEMI_MONTHLY_DAY_OF_MONTH_2 = 'semiMonthlyDayOfMonth2',
  DATE_OF_YEAR = 'dateOfYear',
}

export enum PayrollCalendarUseFormFields {
  CONNECT_TO = 'connectTo',
  PLANS = 'plans',
  PAYROLL_GROUPS = 'payrollGroups',
}
  
export interface PayrollCalendarFormValues {
  [PayrollCalendarFormFields.CALENDAR_NAME]?: string;
  [PayrollCalendarFormFields.FREQUENCY]?: string;
  [PayrollCalendarFormFields.START_DATE]?: string;
  [PayrollCalendarFormFields.DAY_OF_MONTH]?: string;
  [PayrollCalendarFormFields.DAY_OF_WEEK]?: string;
  [PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK]?: string;
  [PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1]?: string;
  [PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2]?: string;
  [PayrollCalendarFormFields.DATE_OF_YEAR]?: string;
}

export interface PayrollCalendarUseFormValues {
  [PayrollCalendarUseFormFields.CONNECT_TO]?: string;
  [PayrollCalendarUseFormFields.PLANS]?: string[];
  [PayrollCalendarUseFormFields.PAYROLL_GROUPS]?: string[];
}

export enum PayrollCalendarFrequency {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-weekly',
  SEMI_MONTHLY = 'Semi-monthly',
  ANNUALLY = 'Annual',
}

export enum PayrollCalendarFrequencyResponse {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  ANNUALLY = 'ANNUALLY',
}

export const PayrollCalendarFrequencyOptions = [
  {
    key: PayrollCalendarFrequency.MONTHLY,
    title: PayrollCalendarFrequency.MONTHLY,
    value: PayrollCalendarFrequency.MONTHLY,
  },
  {
    key: PayrollCalendarFrequency.WEEKLY,
    title: PayrollCalendarFrequency.WEEKLY,
    value: PayrollCalendarFrequency.WEEKLY,
  },
  {
    key: PayrollCalendarFrequency.BI_WEEKLY,
    title: PayrollCalendarFrequency.BI_WEEKLY,
    value: PayrollCalendarFrequency.BI_WEEKLY,
  },
  {
    key: PayrollCalendarFrequency.SEMI_MONTHLY,
    title: PayrollCalendarFrequency.SEMI_MONTHLY,
    value: PayrollCalendarFrequency.SEMI_MONTHLY,
  },
  {
    key: PayrollCalendarFrequency.ANNUALLY,
    title: PayrollCalendarFrequency.ANNUALLY,
    value: PayrollCalendarFrequency.ANNUALLY,
  }
];

export enum ToggleOptions {
  LIST = 'List',
  CALENDAR = 'Calendar',
}

interface PayrollCalendarPayrollGroupResponse {
  id: number;
  name: string;
  code: string;
}

interface PayrollCalendarPlanResponse {
  id: number;
  plan_code: string;
}

interface PayrollCalendarPlan {
  id: string;
  planCode: string;
}

export interface PayrollCalendarsResponse {
  id: number;
  name: string;
  frequency_type: string;
  start_date: string;
  group_type: string;
  payroll_groups: PayrollCalendarPayrollGroupResponse[];
  plans: PayrollCalendarPlanResponse[];
}
export enum ConnectToOptions {
  ALL = 'ALL',
  PLAN = 'PLAN',
  GROUP = 'GROUP',
}

export interface PlanCodeId {
  plan_code: string;
  plan_id: string;
}

export interface PayrollCalendarRequestParams {
  calendar_id?: number;
  start_date?: string;
  frequency?: string;
  day_of_week?: string;
  first_day_of_month?: string;
  second_day_of_month?: string;
  month?: string;
  day_of_month?: string;
}

export interface PayrollCalendarParams extends PayrollCalendarFormValues {
  calendarId: string;
}

export interface PayrollCalendarRequest {
  calendar_name?: string;
  frequency_type: string;
  group_type: string;
  calculate_schedule_request_dto: PayrollCalendarRequestParams;
  scheduled_dates: string[];
  plan_code_ids?: PlanCodeId[];
  payroll_group_ids?: string[];
  organization_id: string;
  organization_path: string;
}

export interface PayrollCalendarObjectResponse {
  id: number;
  name: string;
  frequency_type: string;
  created_at: string;
  start_date: string;
  end_date: string;
  status: string;
  organization_id: number;
  organization_path: string;
  next_autoprolong_at: string;
  config: PayrollCalendarRequestParams;
  group_type: string;
  schedule: string[];
  calendar_plans: PayrollCalendarPlanResponse[];
  non_calendar_plans: PayrollCalendarPlanResponse[];
  calendar_payroll_groups: PayrollCalendarPayrollGroupResponse[];
  non_calendar_payroll_groups: PayrollCalendarPayrollGroupResponse[];
}

export interface PayrollCalendarObject {
  id: string;
  name: string;
  frequencyType: string;
  createdAt: string;
  startDate: string;
  endDate: string;
  status: string;
  organizationId: number;
  organizationPath: string;
  nextAutoprolongAt: string;
  config: PayrollCalendarParams;
  groupType: string;
  schedule: string[];
  calendarPlans: PayrollCalendarPlan[];
  nonCalendarPlans: PayrollCalendarPlan[];
  calendarPayrollGroups: PayrollCalendarPayrollGroupResponse[];
  nonCalendarPayrollGroups: PayrollCalendarPayrollGroupResponse[];
}