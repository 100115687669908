import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';
import styled from 'styled-components';

import ConfigureButton from '../../ConfigureButton';
import { usePreviewStore, useSubmitFormStore } from '../../stores';

import { ContributionPayload,useStore } from './stores/useContribution.store';
import { useAccountFundingParamsFields } from './hooks';

const Wrapper = styled(Box)`
  &:last-child {
    margin-bottom: 0 ;
  }
`;
interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const AccountFunding: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const fields = useAccountFundingParamsFields(activateValidate, isTemplateMode, isEditMode);
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<ContributionPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  if (!fields.length) {
    return null;
  }
  return (
    <Wrapper background="canvas" round="container1Round">
      <FlexControlledForm
        formTitle="Contribution"
        testId="plan_account-funding"
        fields={fields}
        showError={showErrors}
        editMode={isEditMode}
        onChangeValues={setValues}
        isThinMode={showErrors}
        onDirty={onDirty}
        footer={!isEditMode && !previewMode && (
          <ConfigureButton isTemplateMode={isTemplateMode} />
        )}
      />
    </Wrapper>
  );
};

export default AccountFunding;
