import React from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';

import {
  PayoutDefinition,
} from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import usePayoutDefinitionNameFields from './usePayoutDefinitionNameFields';

interface Props {
  data?: PayoutDefinition;
  isEditMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const PayoutDefinitionNameForm: React.FC<Props> = ({ data, isEditMode, onDirty }) => {
  // const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  // const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const fields = usePayoutDefinitionNameFields(data?.name);
  // const handleSetState = useStore((state) => state.setState);
  // const showErrors = useStore((state) => state.showErrors);
  // const { setValues, values } = useDebounceState<Partial<PlanNamePayload>>();
  // useEffect(() => {
  //   if (values) {
  //     handleSetState(values);
  //   }
  // }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round" data-testId="payout-definition-form-wrapper">
      <FlexControlledForm
        testId="payout-definition-form"
        fields={fields}
        // showError={showErrors || saveValidate || activateValidate}
        editMode={isEditMode}
        onChangeValues={() => {}}
        onDirty={onDirty}
        isThinMode
      />
    </Box>
  );
};
export default PayoutDefinitionNameForm;
