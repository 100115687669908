import { useEffect,useMemo, useState } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { DefaultFields } from '@/common/types';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { useHealthTemplatesListQuery } from '@/modules/HealthPlan/queries';

import { useHealthPlanNameStore } from '../stores';
import { PlanNamePayload } from '../stores/useHealthPlanName.store';

export default (defaultFields?: Partial<PlanNamePayload>) => {
  // const planTypes = usePlanTypesQuery();
  const [defaultValues, setDefaultValues] = useState<Partial<PlanNamePayload>>({});
  const parentHealthPlanTemplate = useHealthPlanNameStore((state) => state.state.planTemplate);
  const handleSetState = useHealthPlanNameStore((state) => state.setState);
  const [searchValue, setSearchValue] = useState('');
  const {
    options: healthPlanTemplateOptions,
    isLoading,
  } = useHealthTemplatesListQuery({
    refetchOnMount: true,
    status: HealthPlanStatuses.ACTIVE,
    id: parentHealthPlanTemplate?.[0],
    searchTemplateString: searchValue,
  });

  const currentParentHealthPlanTemplate = useMemo(() => {
    if (parentHealthPlanTemplate) {
      return healthPlanTemplateOptions?.find((item) => item.key === parentHealthPlanTemplate);
    }
    return null;
  }, [parentHealthPlanTemplate, healthPlanTemplateOptions]);

  useEffect(() => {
    if (currentParentHealthPlanTemplate) {
      handleSetState({
        planName: currentParentHealthPlanTemplate.name,
        planTemplate: parentHealthPlanTemplate,
      });
      setDefaultValues({
        planName: currentParentHealthPlanTemplate.name,
        planTemplate: parentHealthPlanTemplate,
      });
    }
  }, [currentParentHealthPlanTemplate, handleSetState, parentHealthPlanTemplate]);

  const fields = useMemo<Field[]>(
    () => [
      {
        name: 'planTemplate',
        type: FieldTypes.AutocompleteDropdown,
        label: 'Health plan template',
        onSearch: setSearchValue,
        searchValue,
        options: healthPlanTemplateOptions,
        placeholder: 'Select health plan template',
        singleMode: true,
        isLoading,
        showRequireIcon: true, 
        defaultValue: defaultValues.planTemplate,
        validator: VALIDATORS.REQUIRED_STRING,
        maxOptionsCount: 1000,
      },
      {
        name: 'planName',
        type: FieldTypes.Text,
        label: 'Health plan name',
        placeholder: 'Enter health plan name',
        defaultValue: defaultValues.planName,
        showRequireIcon: true,
        validator: VALIDATORS.REQUIRED_STRING,
      },
    ],
    [searchValue, healthPlanTemplateOptions, isLoading, defaultValues.planTemplate, defaultValues.planName],
  );
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const currentDefaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: currentDefaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
