import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isOpenNotificationCenter: boolean;
  setNotificationCenterState: (value: boolean) => void;
  totalNotifications: number;
  setTotalNotifications: (value: number) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isOpenNotificationCenter: false,
  totalNotifications: 1,
  setNotificationCenterState: (value: boolean) => set(() => ({
    isOpenNotificationCenter: value,
  })),
  setTotalNotifications: (totalNotifications: number) => set(() => ({
    totalNotifications,
  })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
