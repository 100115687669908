 
import React from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';

import useYourContactsFields
  from './hooks/useYourContactsFields';

interface Props {
  viewMode?: boolean;
  phoneNumber?: string;
  email?: string;
  onDirty?: (isDirty: boolean) => void;
}

const YourContacts: React.FC<Props> = ({
  viewMode,
  phoneNumber,
  email,
  onDirty,
}) => {
  const fields = useYourContactsFields(phoneNumber, email, viewMode);

  return (
    <Box
      background="canvas"
      round="container1Round"
      data-testid="Your-contacts-form-wrapper"
      id="email_sms_communications"
    >
      <FlexControlledForm
        formTitle="Your Contacts"
        fields={fields}
        editMode={!viewMode}
        onChangeValues={() => {}}
        onDirty={onDirty}
        isThinMode
      />
    </Box>
  );
};

export default YourContacts;
