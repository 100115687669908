import React, { useMemo } from 'react';
import { Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { usePermissionsListQuery } from '../queries/usePermissionsList.query';

const StyledText = styled(Text)`
text-transform: capitalize;
`;

export const usePermissionOptions = (viewMode?: boolean) => {
  const permissionsListQuery = usePermissionsListQuery();
  return useMemo(() => (permissionsListQuery.data?.data || [])
    .map((role) => ({
      label: <StyledText weight={viewMode ? 'bold' : 'normal'}>{role.name.split('_').join(' ').toLowerCase()}</StyledText>,
      value: role.name,
      id: role.id,
    })), [permissionsListQuery.data, viewMode]);
};
