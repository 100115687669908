import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';
import styled from 'styled-components';

import { PlansType } from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import { HEIGHT_FIXED_HEADER } from '../PlansList';
import PlansSearch from '../PlansSearch';

import SelectPlanButton from './SelectButton';

export const StyledHeading = styled(Heading)`
  transition: .2s;
  line-height: 40px;
`;

interface Props {
  currentTab: PlansType;
  onSetCurrentTab: (tab: PlansType) => void;
  setSearchString: (value: string) => void;
  searchString: string;
  isScrolled: boolean;
  plansCount?: number;
  templatesCount?: number;
  hidePlans?: boolean;
  children?: React.ReactNode;
  link?: string;
  isEmployer?: boolean;
  clearSearchValue: (value: string) => void;
}

const PlansToggle: React.FC<Props> = ({
  isScrolled,
  link,
  plansCount = 0,
  templatesCount = 0,
  children,
  onSetCurrentTab,
  setSearchString,
  clearSearchValue,
  searchString,
  currentTab,
  hidePlans,
}) => (
  <>
    <Box
      width={theme.defaultContentWidth}
      direction="row"
      justify="between"
      align="center"
      pad={{ vertical: 'xxs' }}
      height={`${HEIGHT_FIXED_HEADER}px`}
    >
      <StyledHeading
        data-testId="Plans_title"
        level={4}
        color="textTitle"
        size={isScrolled ? 'medium' : 'large'}
      >
        Plans Hub
      </StyledHeading>
      <Box direction="row" align="center">
        <PlansSearch
          key={currentTab}
          searchString={searchString}
          setSearchString={setSearchString}
        />
        {!hidePlans && (
          <Box
            direction="row"
            background="module"
            round="container1Round"
            pad="xsmall"
            margin={{ right: 'l' }}
          >
            <SelectPlanButton
              link={link}
              onClick={onSetCurrentTab}
              clearSearchValue={clearSearchValue}
              testId="Plans_toggle_plans"
              currPlan={currentTab}
              name={PlansType.Plan}
              text={`Plans (${plansCount})`}
            />
            <SelectPlanButton
              link={link}
              onClick={onSetCurrentTab}
              clearSearchValue={clearSearchValue}
              currPlan={currentTab}
              testId="Plans_toggle_plan-templates"
              name={PlansType.Template}
              text={`Plan Templates (${templatesCount})`}
            />
          </Box>
        )}
        <Box>
          {children}
        </Box>
      </Box>
    </Box>
  </>
);

export default PlansToggle;
