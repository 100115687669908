import React from 'react';
import { Box } from '@common-fe/common-fe';

import FilesList from './FilesList';

const FileManager: React.FC = () => (
  <Box
    align="center"
    width="100%"
    height="fit-content"
    pad={{ bottom: 'spacing72' }}
  >

    <FilesList />

  </Box>
);

export default FileManager;
