

import useScheduledContributionStore from '../../stores/useScheduledContribution.store';
import { useScheduledContributionSummaryCalendarsQuery } from '../queries';

export default () => {
  const calendarId = useScheduledContributionStore(state=> state.state.calendarId);

  const startDate = useScheduledContributionStore(state=> state.state.startDate);
  const { formattedData } = useScheduledContributionSummaryCalendarsQuery({
    calendarId: calendarId || '',
    startDate,
  });

  return {
    formattedData,
  };
};
