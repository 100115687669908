// /payout-definitions
import { useCallback, useMemo, useState } from 'react';
import { useMutation,useQuery, useQueryClient } from 'react-query';
import { useParams, useRouteMatch } from 'react-router-dom';
import { AxiosHeaders } from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { OrganizationTypes,SERVER_ERRORS } from '@/common/constants';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { Option } from '@/common/types';
import { useCurrentOrganization, useSnackbar } from '@/modules/core/hooks';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { PriorPlanYear } from '@/modules/plan/plan.types';
import { getIdsFromPath } from '@/utils/modifiers';

import { useStore } from '../stores/usePlanYearForm.store';

interface PlanYearResponse {
  'id': number | string,
  'organization_id': number | string,
  'name': string,
  'valid_from': string,
  'valid_to': string,
  'copay_group_id'?: number | null;
  'copay_group'?: {
    id: string;
    name: string;
  },
  'prior_plan_year_id'?: string | number,
  'prior_plan_year'?: PriorPlanYear,
}

const START_DATE_OVERLAP_PRIOR_PLAN_YEAR_PERIOD = 'New PlanYear start date must be later than the prior plan year end date';
const PLAN_YEAR_END_DATE_CANNOT_BE_NULL = 'Plan Year End Date can not be updated to null if it is linked to any plan where is_rollover = true';
const PLAN_YEAR_SET_AS_ANOTHER_PRIOR_PLAN_YEAR = 'Plan Year can not be updated if it is set as prior plan year for another plan year';

export interface PlanYear {
  id: string;
  organizationId: string;
  name: string;
  startDate: string;
  endDate: string;
  copayGroup?: string | null;
  priorPlanYearId?: string;
  priorPlanYear?: PriorPlanYear;
}

export const QUERY_KEY = 'GET_PLAN_YEARS';

const useCreateUpdatePlanYearQuery = (
  setPending: (value: boolean) => void,
  onSuccess: () => void,
  includeLogHeader?: boolean,
  isEditMode?: boolean,
) => {
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { employerID } = useSetupEmployerMode();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (planYear: Partial<PlanYearResponse>) => {
      setPending(true);

      if (planYear?.id) {
        return api.put(PATHS.PLAN_YEAR(`${planYear.id || ''}`), planYear);
      }

      return api.post(
        PATHS.PLAN_YEAR(),
        planYear,
        {    headers: includeLogHeader ? {
          'Audit-Log-Creation': true,
        } as unknown as AxiosHeaders : undefined,
        },
      );
    },
    {
      onSuccess: (data) => {
        onSuccess();
        setPending(false);
        queryClient.invalidateQueries([QUERY_KEY, employerID]);
        handleAddPermanentSnackbar({
          text: isEditMode ? `${data.data.name} has been edited.` : `${data.data.name} has been added.`,
          closeIcon: true,
        });
      },
      onError: () => {
        setPending(false);
      },
    },
  );

  return mutation;
};

export const useSaveUpdatePlanYear = (
  setPending: (value: boolean) => void,
  onSuccess: () => void,
  id?: string,
  includeLogHeader?: boolean,
  isEditMode?: boolean,
  activeValues?: boolean,
  isEndDateFieldDisabled?: boolean,
) => {
  const [loading, setLoading] = useState(false);
  const [isPlanYearAlreadySetError, setPlanYearAlreadySetError] = useState(false);
  const mutation = useCreateUpdatePlanYearQuery(setPending, onSuccess, includeLogHeader, isEditMode);
  const { employerID } = useSetupEmployerMode();
  const { errors, state, setShowErrors, setErrors } = useStore();

  const areErrorsShowed = useMemo(() => {
    const { startDate, endDate, name } = state;

    return !startDate
      || !name
      || (endDate
        ? dayjs(endDate).isBefore(dayjs(startDate))
        : false);
  }, [state]);

  const handleSave = useCallback(async () => {
    if (areErrorsShowed) {
      setShowErrors(true);
    } else {
      const copayId = _.toNumber(state.copayGroup);
      setShowErrors(true);
      try {
        setLoading(true);
        if (isEditMode && !activeValues) {
          return;
        }
        await mutation.mutateAsync({
          id,
          organization_id: employerID,
          name: state.name,
          valid_from: state.startDate,
          ...state.endDate && !isEndDateFieldDisabled ? { valid_to: state.endDate } : {},
          prior_plan_year_id: state.priorPlanYearId
            ? _.toNumber(state.priorPlanYearId) : undefined,
          copay_group_id: copayId && !_.isNaN(copayId) ? copayId : null,
        });
      } catch (e) {
        const code = _.get(e, 'response.status', 0) as number;
        const message = _.get(e, 'response.data.elevate_error_message', '') as string;

        if (message.includes(START_DATE_OVERLAP_PRIOR_PLAN_YEAR_PERIOD)) {
          setErrors({
            startDate: state.startDate,
          });
        }
        if (message.includes(PLAN_YEAR_END_DATE_CANNOT_BE_NULL)) {
          setErrors({
            endDate: state.endDate,
          });
        }
        if (message.includes(PLAN_YEAR_SET_AS_ANOTHER_PRIOR_PLAN_YEAR)) {
          setPlanYearAlreadySetError(true);
        }
        if (code === SERVER_ERRORS.CONFLICT) {
          setErrors({
            name: state.name,
          });
        }
      } finally {
        setPending(false);
        setLoading(false);
      }
    }
  }, [
    activeValues,
    isEditMode,
    areErrorsShowed,
    employerID,
    id,
    mutation,
    setErrors,
    setPending,
    setShowErrors,
    state,
    isEndDateFieldDisabled,
  ]);
  return {
    handleSave,
    loading,
    isPlanYearAlreadySetError,
  };
};

export default () => {
  const { path } = useRouteMatch();
  const isEmployerPartnerPage = path === ROUTES.EMPLOYERS_VIEW
    || path === ROUTES.EMPLOYERS_EDIT()
    || path === ROUTES.PARTNERS_VIEW()
    || path === ROUTES.PARTNERS_EDIT();
  const { id } = useParams<{ id: string }>();
  const { observingOrganization } = useCurrentOrganization();

  const isSubsidiary = useMemo(
    () => observingOrganization?.type === OrganizationTypes.subsidiary,
    [observingOrganization],
  );

  const { employerId } = getIdsFromPath(observingOrganization.path);

  const organizationId = useMemo(
    () => (isSubsidiary ? employerId : observingOrganization?.id),
    [isSubsidiary, employerId, observingOrganization],
  );

  const { data, isLoading } = useQuery(
    [QUERY_KEY, isEmployerPartnerPage ? id : observingOrganization.id],
    () => api.get<PlanYearResponse[]>(
      PATHS.GET_PLAN_YEARS(
        isEmployerPartnerPage
          ? id || null
          : organizationId || null,
        // !isEmployerPartnerPage,
      ),
    ),
    {
      enabled: isEmployerPartnerPage
        ? Boolean(id)
        : !!_.toString(observingOrganization.id),
    },
  );

  const formatedData: PlanYear[] = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      id: `${item.id}`,
      organizationId: `${item.organization_id}`,
      name: item.name,
      startDate: item.valid_from,
      endDate: item.valid_to,
      copayGroup: `${item.copay_group?.id || ''}`,
      priorPlanYearId: `${item.prior_plan_year_id || ''}`,
      priorPlanYear: item.prior_plan_year,
    }));
  }, [data]);
  const formatedList = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      value: `${item.name}`,
      name: item.name,

    }));
  }, [data]);

  return {
    data: formatedData,
    list: formatedList,
    isLoading,
  };
};
