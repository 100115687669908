import { useCallback, useMemo, useState } from 'react';
import { FieldValues } from '@common-fe/common-fe';

import {
  CopayGroupPayload,
} from '@/modules//HealthPlan/CopayAdjudication/CopayAdjudication.constant';
import { useCopayGroupsQuery } from '@/modules//HealthPlan/CopayAdjudication/queries';

export default (defaultValues?: CopayGroupPayload) => {
  const { data } = useCopayGroupsQuery();
  const [currentDefaultValues, setCurrentDefaultValues] = useState<CopayGroupPayload | null>(
    defaultValues || null,
  );

  const handleChangeValues = useCallback((values: FieldValues) => {
    if (values.basedOn) {
      const found = data.find((item) => item.id === values.basedOn);

      if (found) {
        setCurrentDefaultValues({
          ...found,
          basedOn: values.basedOn || '',
          name: found.name || '',
        });
      }
    }
  }, [data]);

  const appliedDefaultValues = useMemo(
    () => currentDefaultValues || undefined, [currentDefaultValues],
  );
  return {
    defaultValues: appliedDefaultValues,
    handleChangeValues,
  };
};
