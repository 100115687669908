import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT, OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { LogoInfoPayload,PaginationParams } from '@/common/types';
import { Partner } from '@/modules/employer/employer.types';
import { getIdsFromPath } from '@/utils/modifiers';

export const QUERY_KEY = 'usePartnersList';

export interface PartnerDto {
  id: string;
  name?: string;
  short_name?: string;
  external_identifier?: string;
  organization_status_type?: string;
  valid_from?: string | number,
  parent_id?: string | number;
  logo_info?: LogoInfoPayload;
  path: string;
}

interface Params extends PaginationParams {
  mode: OrganizationTypes;
}

const usePartnersListQuery = ({
  page, perPage, searchString, status, sortBy, mode,
}: Params) => {
  const {
    isLoading, isSuccess, isError, data, error, refetch,
  } = useQuery(
    [
      QUERY_KEY,
      page,
      perPage,
      status,
      searchString,
      sortBy,
      mode,
    ],
    () => api.get(PATHS.ORGANIZATIONS, {
      params: {
        ...sortBy ? { sort: `name,${sortBy.toLowerCase()}` } : {},
        ...searchString ? { search_value: searchString } : {},
        ...status ? { statuses: status } : {},
        type: mode,
        page,
        size: perPage,
      },
    }), {
      cacheTime: 0,
    },
  );

  const formattedData = useMemo(() => data?.data.content?.map((item: PartnerDto): Partner => ({
    id: item.id,
    logo: item.short_name || item.name,
    logoUrl: item.logo_info?.large_logo_url,
    darkLargeLogoUrl: item.logo_info?.dark_large_logo_url,
    name: item.name,
    externalIdentifier: item.external_identifier,
    status: item.organization_status_type && capitalizeFirstLetter(item.organization_status_type),
    date: dayjs(item.valid_from).format(DEFAULT_DATE_FORMAT),
    parentId: _.toString(item.parent_id),
    path: item.path,
    ...getIdsFromPath(item.path),
  })), [data]);
  const total = useMemo<number>(() => _.get(data, 'data.totalElements', 0) || 0, [data]);
  return ({
    data: formattedData || [],
    isError,
    total,
    isLoading,
    isSuccess,
    refetch,
    error,
  });
};

export default usePartnersListQuery;
