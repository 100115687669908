import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';

import CarrierConfig from '@/modules/CarrierConfig/CarrierConfig';

import CopayAdjudication from './CopayAdjudication/CopayAdjudication';
import HealthPlansList from './HealthPlansList';

const HealthPlanHub: React.FC = () => (
  <Box pad={{ top: 'l' }} style={{ position: 'relative' }}>
    <Heading level={4} size="large" color="textTitle">Health Plan Hub</Heading>
    <HealthPlansList />
    <CarrierConfig />
    <CopayAdjudication />
  </Box>
);

export default HealthPlanHub;
