import { Box, Image } from '@common-fe/common-fe';
import styled from 'styled-components';

export const RoundImage = styled(Image)`
  border-radius: 50%;
`;

interface InfoTileIconWrapperProps {
  hasBorder?: boolean;
}

export const InfoTileIconWrapper = styled(Box)<InfoTileIconWrapperProps>`
  ${({ hasBorder, theme }) => {
    if (hasBorder) return `outline: 2px solid ${theme.colors.canvas};`;
    return '';
  }}
`;
