import React, { useMemo } from 'react';
import {
  Box,
  costFormater,
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import {
  DEFAULT_DATE_FORMAT,
  // NO_KEY,
  REQUIRED_TEXT,
  YES_KEY,
} from '@/common/constants';
import { Option } from '@/common/types';
import { PolicyStatus } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';

import { LimitedUses } from '../enrollmentForms.types';

const MIN_PER_PAY_AMOUNT = 1;
const ACTIVE_STATUS_KEY = 'A';
const CANCELLED_STATUS_KEY = 'C';

const ENROLLMENT_STATUSES = [
  {
    key: ACTIVE_STATUS_KEY,
    title: 'Active',
    value: 'Active',
  },
  {
    key: CANCELLED_STATUS_KEY,
    title: 'Cancel',
    value: 'Cancel',
  },
];

const LimitedUseOptions = [
  {
    key: LimitedUses.LIMITED_USE,
    title: LimitedUses.LIMITED_USE,
    value: LimitedUses.LIMITED_USE,
  },
  {
    key: LimitedUses.GENERALE_PURPOSE,
    title: LimitedUses.GENERALE_PURPOSE,
    value: LimitedUses.GENERALE_PURPOSE,
  },
  {
    key: LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET,
    title: LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET,
    value: LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET,
  },
];

interface Props {
  plans?: Option[];
  currentPlan?: string;
  currentEnrollmentStatus?: string;
  planYear?: string;
  planStartDate?: string;
  planEndDate?: string;
  catchUpAge?: number | null;
  catchUpElection?: number | null;
  minElectionAmount?: number;
  maxElectionAmount?: number;
  isEdit?: boolean;
  enrollmentStatus?: string;
  enrollmentElection?: number;
  perPayEmployee?: number;
  perPayEmployer?: number;
  employeeAge?: number;
  statuses?: PolicyStatus[];
  ssnErrorValue?: string;
  lastCoverageEndDate?: string | null;
  limitedUse?: LimitedUses;
}

const DATE_SHOULD_BE_WITHIN_PLAN_YEAR_MESSAGE = 'This date must be within plan year';

 
// const getStringFromBoolean = (value?: boolean) => (value === true ? 'yes' : value === false ? 'no' : undefined);
const DEDUCTIBLE_DATE_VALIDATOR = yup.string()
  .test({
    test(val) {
      const isDeductibleMetTrue = this.parent.deductible_met === YES_KEY;
      const startDate = dayjs(this.parent?.plan_start_date);
      const endDate = dayjs(this.parent?.plan_end_date);

      const deductibleMetDate = dayjs(val);
      if (isDeductibleMetTrue && this.parent?.plan_start_date && val) {
        return this.parent?.plan_end_date
          ? deductibleMetDate >= startDate && deductibleMetDate <= endDate : deductibleMetDate >= startDate;
      }
      return true;
    },
    message: DATE_SHOULD_BE_WITHIN_PLAN_YEAR_MESSAGE,
  })
  .test({
    test(val) {
      const isDeductibleMetTrue = this.parent.deductible_met === YES_KEY;
      if (isDeductibleMetTrue && !val) {
        return false;
      }
      return true;
    },
    message: REQUIRED_TEXT,
  });

export const useEnrollmentFormFields = (props?: Props) => {
  const defaultPlan = useMemo(() => (props?.plans ? props?.plans[0]?.key : ''), [props]);
  const minAmount = useMemo(
    () => costFormater(props?.minElectionAmount || 0, { isPrecision: true }),
    [props],
  );
  const maxAmount = useMemo(() => {
    if(props?.catchUpAge && props?.employeeAge && props?.employeeAge >= props?.catchUpAge) {
      return costFormater((_.toNumber(props?.maxElectionAmount) + _.toNumber(props?.catchUpElection)) || 0, { isPrecision: true });
    }
    return costFormater(props?.maxElectionAmount || 0, { isPrecision: true });
  }, [props]);

  const maxAmountValue = useMemo(() => {
    if(props?.catchUpAge && props?.employeeAge && props?.employeeAge >= props?.catchUpAge) {
      return _.toNumber(props?.maxElectionAmount) + _.toNumber(props?.catchUpElection);
    }

    return _.toNumber(props?.maxElectionAmount);
  }, [props]);

  const isCurrentPlanInPlans = useMemo(() => {
    const currentPlan = props?.currentPlan;
    const plans = props?.plans || [];

    if (!currentPlan) return false;

    return !!plans.find((plan) => plan.key === currentPlan);
  }, [props]);

  const dateErrorMessage = useMemo(() => {
    const startDate = props?.planStartDate;
    const endDate = props?.currentEnrollmentStatus === CANCELLED_STATUS_KEY ? props?.lastCoverageEndDate : props?.planEndDate;
  
    return endDate
      ? `Date must be in range from ${startDate} to ${endDate}`
      : `Date must not be earlier than ${startDate}`;
  }, [props]);

  const CHANGE_DATE_VALIDATOR = useMemo(() => {
    return VALIDATORS.DATE_FORMAT.test({
      test(val) {
        const enrollmentStatus = this.parent.enrollment_status;
        const startDate = dayjs(this.parent?.plan_start_date);
        const endDate = dayjs(this.parent?.plan_end_date);
        const coverageDateEnd = dayjs(props?.lastCoverageEndDate);
        if (enrollmentStatus === ACTIVE_STATUS_KEY) {
          if (this.parent?.plan_start_date && val && dayjs(val) < startDate) {
            return false;
          }
          if (this.parent?.plan_end_date && val && dayjs(val) > endDate) {
            return false;
          }
        }
        if (enrollmentStatus === CANCELLED_STATUS_KEY) {
          if (this.parent?.plan_start_date && val && dayjs(val) < startDate) {
            return false;
          }
          if (props?.lastCoverageEndDate && val && dayjs(val) > coverageDateEnd) {
            return false;
          }
        }
        return true;
      },
      message: dateErrorMessage,
    }).required(REQUIRED_TEXT);
  }, [props?.lastCoverageEndDate, dateErrorMessage]);

  const currentEnrollmentStatus = useMemo(() => props?.enrollmentStatus?.charAt(0) || '', [props]);

  const fields = useMemo(() => [
    {
      name: 'plan',
      type: FieldTypes.Dropdown,
      options: props?.plans || [],
      defaultValue: isCurrentPlanInPlans ? props?.currentPlan : defaultPlan,
      label: 'Plan',
      singleMode: true,
      placeholder: 'Plan',
      validator: yup.string().test({
        test: (val) => !props?.ssnErrorValue || val !== props?.ssnErrorValue,
        message: 'Add SSN for this participant to enroll in HSA',
      }).required(REQUIRED_TEXT),
      showRequireIcon: true,
      disabled: props?.isEdit,
    },
    {
      name: 'plan_year',
      type: FieldTypes.Dropdown,
      label: 'Plan year',
      placeholder: 'Plan year',
      options: props?.planYear
        ? [
          {
            key: props.planYear,
            value: props.planYear,
            title: props.planYear,
          },
        ]
        : [],
      defaultValue: props?.planYear || '',
      disabled: true,
      isManualDateInput: true,
    },
    {
      name: 'plan_start_date',
      type: FieldTypes.Date,
      label: 'Plan start date',
      placeholder: 'Plan start date',
      disabled: true,
      value: props?.planStartDate || '',
      isManualDateInput: true,
    },
    {
      name: 'plan_end_date',
      type: FieldTypes.Date,
      label: 'Plan end date',
      placeholder: 'Plan end date',
      disabled: true,
      value: props?.planEndDate || '',
      isManualDateInput: true,
    },
    {
      name: 'enrollment_status',
      type: FieldTypes.Dropdown,
      label: 'Enrollment status',
      placeholder: 'Enrollment status',
      showRequireIcon: true,
      singleMode: true,
      disabled: !props?.isEdit,
      options: ENROLLMENT_STATUSES,
      value: props?.isEdit ? currentEnrollmentStatus : ACTIVE_STATUS_KEY,
      validator: VALIDATORS.REQUIRED_STRING,
    },
    {
      name: 'election_amount',
      type: FieldTypes.Currency,
      defaultValue: `${props?.enrollmentElection === 0 ? 0 : props?.enrollmentElection || ''}`,
      showRequireIcon: true,
      label: (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Election amount</Text>
          <Box>
            <Text
              size="small"
              weight="normal"
              style={{ maxWidth: '220px' }}
              color="textSecondary"
            >
              According to the chosen plan, the amount must be between {minAmount} and {maxAmount}.
            </Text>
          </Box>
        </Box>
      ),
      placeholder: 'Election amount',
      validator: yup.string().test({
        test: (val) => {
          if (props?.minElectionAmount && _.toNumber(val) < props.minElectionAmount) return false;
          if (maxAmountValue && _.toNumber(val) > maxAmountValue) return false;
          return true;
        },
        message: `Should be in range from ${minAmount} to ${maxAmount}`,
      }).required(REQUIRED_TEXT),
    },
    {
      name: 'per_pay_employer',
      type: FieldTypes.Currency,
      defaultValue: `${props?.perPayEmployer === 0 ? 0 : props?.perPayEmployer || ''}`,
      label: 'Per pay employer',
      placeholder: 'Per pay employer',
      validator: yup.string().test({
        test: (val) => {
          if (val && _.toNumber(val) !== 0 && _.toNumber(val) < MIN_PER_PAY_AMOUNT) return false;
          return true;
        },
        message: `Should be more than $${MIN_PER_PAY_AMOUNT}.00`,
      }),
    },
    { 
      name: 'per_pay_employee',
      type: FieldTypes.Currency,
      defaultValue: `${props?.perPayEmployee === 0 ? 0 : props?.perPayEmployee || ''}`,
      label: 'Per pay employee',
      placeholder: 'Per pay employee',
      validator: yup.string().test({
        test: (val) => {
          if (val && _.toNumber(val) !== 0 && _.toNumber(val) < MIN_PER_PAY_AMOUNT) return false;
          return true;
        },
        message: `Should be more than $${MIN_PER_PAY_AMOUNT}.00`,
      }),
    },
    {
      name: 'limited_use',
      type: FieldTypes.Dropdown,
      label: 'Limited Use / General Purpose',
      placeholder: 'Limited Use / General Purpose',
      singleMode: true,
      options: LimitedUseOptions,
      value: props?.isEdit ? props?.limitedUse || LimitedUses.GENERALE_PURPOSE : LimitedUses.GENERALE_PURPOSE,
    },
    ...props?.limitedUse && props?.limitedUse === LimitedUses.GENERALE_PURPOSE_DEDUCTIBLE_MET ? [{
      name: 'deductibleMetDate',
      type: FieldTypes.Date,
      label: 'Deductible met date',
      showRequireIcon: true,
      placeholder: 'Change date',
      parentValue: YES_KEY,
      validator: DEDUCTIBLE_DATE_VALIDATOR,
    }] : [],
    {
      name: 'enrollment_date',
      type: FieldTypes.Date,
      label: (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Change date</Text>
          <Box>
            <Text
              size="small"
              weight="normal"
              style={{ maxWidth: '220px' }}
              color="textSecondary"
            >
              This is the date these changes become effective
            </Text>
          </Box>
        </Box>
      ),
      placeholder: 'Change date',
      validator: CHANGE_DATE_VALIDATOR,
      isManualDateInput: true,
      defaultValue: props?.statuses?.[0]?.validFrom ? dayjs(props?.statuses?.[0]?.validFrom).format(DEFAULT_DATE_FORMAT) : '',
      showRequireIcon: true,
    },
  ], [props, CHANGE_DATE_VALIDATOR, maxAmountValue, defaultPlan, minAmount, maxAmount, currentEnrollmentStatus, isCurrentPlanInPlans]);

  return fields as Field[];
};
