import React, { useMemo,useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Box,   CalendarPickerIcon, DateRangeDropdown,
  FlexListPlaceholder,
  Inscription, PaginationBar,
  Preloader, SearchInput,
  SelectDropdown, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { ALL_OPTION, RAW_DATE_FORMAT } from '@/common/constants';
import ROUTES from '@/common/routes';
import { Option,OptionKey } from '@/common/types';
import { useListPagination } from '@/modules/employer/hooks';

import AttributesSelector from './AttributesSelector';
import LogItem from './LogItem';
import useGetActivityLogsQuery, { Actions } from './useGetActivityLogs.query';
import useGetAttributesListQuery from './useGetAttributesList.query';

const initPage = 1;
const defaultPerPage = 10;
const SORT = 'id,DESC';
export const employeeDomainType = 'EMPLOYEE,ENROLLMENT';
export const planDomainType = 'PLAN,PLAN_YEAR,PLAN_CONFIGS';
export const organizationDomainType = 'ORGANIZATION,PLAN,PLAN_YEAR';
export const expressSetupDomainType = 'EXPRESS_SETUP';

interface CalendarWrapperProps {
  disabled?: boolean;
}

const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  background: ${({ theme }) => theme.colors.canvas};
  top: 8px;
  right: 13px;
  font-size: 18px;
  margin-bottom: ${({ disabled }) => (disabled ? undefined : '3px')};
  svg {
    fill: ${({ theme }) => theme.colors.background1};
  }
`;

const LogItemWrapper = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }
`;

const onChange = (
  val: OptionKey,
  state: OptionKey[],
  setState: (val: OptionKey[]) => void,
  options: Option[],
) => {
  if (val === ALL_OPTION.value) {
    setState([ALL_OPTION.value]);
    return;
  }
  const currentValue = [
    ...state
      .filter(
        (item) => item !== ALL_OPTION.value && item !== val,
      ),
    val,
  ];
  if (currentValue.length === options.length) {
    setState([ALL_OPTION.value]);
    return;
  }
  setState(currentValue);
};

const actions = [
  {
    key: Actions.create,
    value: Actions.create,
    title: capitalize(Actions.create),
  },
  {
    key: Actions.update,
    value: Actions.update,
    title: capitalize(Actions.update),
  },
];

const LogList = () => {
  const { path, params: { id } } = useRouteMatch<{ id: string }>();
  const isEmployeePage = useMemo(() => path === ROUTES.EMPLOYEE_VIEW()
    || path === ROUTES.EMPLOYEE_EDIT_VIEW(), [path]);
  const isPlansPage = useMemo(() => path === ROUTES.PLAN_SETUP
    || path === ROUTES.PLANS_VIEW, [path]);
  const isPlanTeplatesPage = useMemo(() => path === ROUTES.TEMPLATE_SETUP
    || path === ROUTES.TEMPLATE, [path]);
  const isExpressSetupPage = useMemo(() => path === ROUTES.EXPRESS_SETUP_ACTIVITY_LOGS, [path]);
  const domainType = useMemo(() => {
    if (isExpressSetupPage) {
      return expressSetupDomainType;
    }
    if (isEmployeePage) {
      return employeeDomainType;
    }
    if (isPlansPage || isPlanTeplatesPage) {
      return planDomainType;
    }
    return organizationDomainType;
  }, [isExpressSetupPage, isEmployeePage, isPlansPage, isPlanTeplatesPage]);
  
  const {
    attributesList, isLoading: areAttributesLoading,
  } = useGetAttributesListQuery(domainType);
  const [expandedLogId, setExpandedLogId] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
  const [selectedActions, setSelectedActions] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([]);
  const {
    page,
    perPage,
    setPerPage,
    setPage,
    currentSearch,
    searchString,
    setSearchString,
  } = useListPagination(defaultPerPage);
  const isAllApplied = useMemo(
    () => selectedAttributes.length === 0
      || selectedAttributes.length === Object.values(
        attributesList.map((item) => item.attributes),
      ).flat().length,
    [selectedAttributes, attributesList],
  );
  const { logs, total, isLoading } = useGetActivityLogsQuery({
    ...isEmployeePage ? { employeeId: id } : isPlansPage || isPlanTeplatesPage ? { planId: id } : { organizationId: id },
    search: currentSearch,
    actions: selectedActions.filter((item) => item !== ALL_OPTION.value) as string[],
    ...isAllApplied ? {} : { fields: selectedAttributes },
    ...dateRange[0] ? {
      dateFrom: dayjs(dateRange[0]).format(RAW_DATE_FORMAT),
    } : {},
    ...dateRange[1] ? {
      dateTo: dayjs(dateRange[1]).format(RAW_DATE_FORMAT),
    } : {},
    page,
    size: perPage,
    sort: SORT,
    domainType,
  });

  return (
    <Box
      background={{ color: 'module' }}
      round="container1Round"
    >
      <Box align="center">
        <Box
          width="100%"
          direction="row"
          justify="between"
          align="center"
        >
          <Inscription
            weight="bold"
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
          >
            Activity log: {total}
          </Inscription>
          <Box direction="row" align="center" height="40px">
            <Box margin={{ left: 'spacing8' }} width={{ min: '230px' }}>
              <SearchInput
                value={searchString}
                hasSearchingHistory
                placeholder="Search"
                onChange={setSearchString}
                resetValue={searchString}
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} style={{ position: 'relative' }}>
              <AttributesSelector
                applyAttributes={(attributes: string[]) => {
                  setSelectedAttributes(attributes);
                  setPage(initPage);
                }}
                appliedAttributes={selectedAttributes}
                isAllApplied={isAllApplied}
                isLoading={areAttributesLoading}
                attributesList={attributesList}
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} width={{ min: '160px', max: '160px' }}>
              <SelectDropdown
                id="action"
                testId="action"
                activeTitle
                ellipsisMode
                name="Action"
                prefix="Action"
                options={[ALL_OPTION, ...actions]}
                values={selectedActions}
                onChange={(val) => {
                  onChange(val, selectedActions, setSelectedActions, actions);
                  setPage(initPage);
                }}
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <DateRangeDropdown
                prefix="Period:"
                onChange={(range) => {
                  setDateRange(range);
                  setPage(initPage);
                }}
                ellipsisMode
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                icon={(
                  <CalendarWrapper>
                    <CalendarPickerIcon />
                  </CalendarWrapper>
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box
          margin={{ top: 'spacing12', bottom: 'spacing8' }}
          pad={{ horizontal: 'spacing24', vertical: '7px' }}
          background="legend"
          round="8px"
          width="100%"
          height="36px"
          direction="column"
          justify="center"
        >
          <Box
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              lineHeight: '18px',
              color: 'textOnColor',
            }}
            direction="row"
            height="18px"
          >
            <Box width="3%" />
            <Box width="22%" pad={{ left: 'spacing16' }}>Date of change</Box>
            <Box width="22%" pad={{ left: 'spacing16' }}>Entity</Box>
            <Box width="27%" pad={{ left: 'spacing16' }}>Author</Box>
            <Box width="18%" pad={{ left: 'spacing16' }}>Source of change</Box>
            <Box width="8%" pad={{ left: 'spacing16' }}>Changes</Box>
          </Box>
        </Box>
        {isLoading ? (<Box height="62px" justify="center"><Preloader /></Box>) : (
          <LogItemWrapper width="100%">
            {logs?.map((data) => (
              <Box key={data.id}>
                <LogItem
                  data={data}
                  isExpanded={data.id === expandedLogId}
                  setExpandedLogId={setExpandedLogId}
                />
              </Box>
            ))}
          </LogItemWrapper>
        )}
        {total || isLoading ? (
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        ) : (
          <FlexListPlaceholder />
        )}
      </Box>
    </Box>
  );
};

export default LogList;
