import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { InvestmentPathType } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'GET_INVESTORS';

export interface InvestorsDto {
  investor_id: string;
  account_id: string;
  type: string;
  automatic_investment_enabled: boolean;
  pending_sell_trades_amount?: number;
  investor_profile: {
    tolerance_model: string;
  },
  status: string;
}

export interface Investors {
  investorId: string;
  accountId: string;
  type: InvestmentPathType;
  automaticInvestmentEnabled: boolean;
  pendingTradesAmount?: number;
  toleranceModel: string;
  status: string;
}

const formatData = (data?: InvestorsDto) => ({
  investorId: `${data?.investor_id || ''}`,
  accountId: `${data?.account_id || ''}`,
  type: data?.type,
  automaticInvestmentEnabled: data?.automatic_investment_enabled,
  pendingTradesAmount: data?.pending_sell_trades_amount || 0,
  toleranceModel: data?.investor_profile?.tolerance_model || '',
  status: data?.status || '',
}) as Investors;
const useGetInvestorsQuery = (employeeId: string) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [QUERY_KEY, employeeId],
    () => api.get<InvestorsDto>(`${PATHS.GET_INVESTORS}?employee_id=${employeeId}`),
    {
      enabled: !!employeeId,
    },
  );

  const formattedData = useMemo(() => formatData(data?.data), [data]);

  return ({
    formattedData,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useGetInvestorsQuery;
