import React, { useMemo, useState } from 'react';
import {
  // AutoInvestmentOffIcon,
  // InvestmentIcon,
  // AutoInvestmentOnIcon,
  Box,
  CIPStatus,
  Enrollment,
  EnrollmentCard,
  EnrollmentStatus,
  // Hint,
  // InvestmentPathType,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import useGetPolicyStatusesQuery from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';
import colors from '@/styles/colors';

import { AddEnrollmentModal } from '../../AddEnrollmentModal/AddEnrollmentModal';
import { Investors } from '../../queries/useGetInvestors.query';

import { EnrollmentDates } from './EnrollmentDates';

export const DisplayName = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ENROLLMENT_ITEM_WIDTH_S = '336px';
export const ENROLLMENT_ITEM_WIDTH_L = '394px';

interface Props {
  enrollment: Enrollment;
  onSelect?: () => void;
  isDetailsMode?: boolean;
  statusColor?: string;
  investorsData?: Investors;
  employeeName?: string;
  clickMode?: boolean;
  hasEnrollmentWithDisplayName?: boolean;
  width?: string;
  isListMode?: boolean;
  wrapperStyle?: React.CSSProperties;
}


export const EnrollmentItem: React.FC<Props> = ({
  enrollment,
  onSelect,
  statusColor,
  isDetailsMode,
  employeeName,
  investorsData,
  clickMode,
  hasEnrollmentWithDisplayName,
  width,
  isListMode,
  wrapperStyle,
}) => {
  const { statuses } = useGetPolicyStatusesQuery(enrollment?.policyId);
  const isNotVerified = useMemo(
    () => (enrollment?.CIPStatus === CIPStatus.Fail
    || enrollment?.CIPStatus === CIPStatus.Question) && enrollment.isCIPRequired,
    [enrollment],
  );
  const isCanceled = useMemo(
    () => enrollment.enrollmentStatus === EnrollmentStatus.Canceled, [enrollment.enrollmentStatus],
  );
  const [editModalVisible, setEditModalVisible] = useState(false);
  
  // const investmentIcon = useMemo(() => {
  //   if (investorsData?.automaticInvestmentEnabled
  //     && investorsData?.type === InvestmentPathType.MANAGED
  //     && investorsData?.accountId === enrollment.id) {
  //     return (
  //       <Hint
  //         hintAlign="vertical"
  //         hintWidth={200}
  //         hintElement={<AutoInvestmentOnIcon color='iconSecondary' />}
  //         marginContent="m"
  //       >
  //         <Text textAlign='center'>Automatic Investments Activated</Text>
  //       </Hint>
  //     );
  //   }

  //   if (!investorsData?.automaticInvestmentEnabled
  //     && investorsData?.type === InvestmentPathType.MANAGED
  //     && investorsData?.accountId === enrollment.id) {
  //     return (
  //       <Hint
  //         hintAlign="vertical"
  //         hintWidth={200}
  //         hintElement={<AutoInvestmentOffIcon color='iconSecondary' />}
  //         marginContent="m"
  //       >
  //         <Text textAlign='center'>Investments Activated</Text>
  //       </Hint>
  //     );
  //   }
  //   return undefined;
  // }, [investorsData, enrollment]);

  const statusLabel = useMemo(() => {
    if (isNotVerified) {
      return (
        <Box
          justify="center"
          align="center"
          background="warning"
          pad={{ horizontal: 'xs' }}
          height="22px"
          round="dropdownItemRound"
        >
          <Text size="small" color="textOnColor">Not Verified</Text>
        </Box>
      );
    }
    if (isCanceled) {
      return (
        <Box direction="row" align="center" margin={{ right: 'xs' }}>
          <Text size="medium" textAlign="center" weight={400}>Canceled</Text>
          <Box background="danger" width="11px" height="11px" round="50%" margin={{ left: 'xs' }} />
        </Box>
      );
    }
    return undefined;
  }, [isCanceled, isNotVerified]);

  return (
    <Box gap="spacing12">
      <Box
        onClick={onSelect}
        style={wrapperStyle || undefined}
      >
        <EnrollmentCard
          enrollment={enrollment}
          width={width}
          clickMode={clickMode}
          backgroundColor={colors.cardTypes[enrollment.accountType]}
          aleraThemeTypeIconColor={colors.iconOnColor}
          statusLabel={statusLabel}
          hasDisplayName={isListMode ? hasEnrollmentWithDisplayName : !!enrollment.displayName}
          pendingTradesAmount={investorsData?.pendingTradesAmount}
          investorEnrollmentId={investorsData?.accountId}
        />

        {/* <Box margin={{ bottom: 'spacing12' }}> */}
        {/*   <EnrollmentDatesInfo enrollment={enrollment} /> */}
        {/* </Box> */}
        {/* { */}
        {/*   enrollment.spentItByDate  */}
        {/*     ? ( */}
        {/*       <Box  */}
        {/*         data-testid="enrollment-investment-message" */}
        {/*         direction="row" */}
        {/*         pad={{ vertical: 'spacing12', horizontal: 'spacing16' }}  */}
        {/*         border={{ color: 'border2', size: 'small' }}  */}
        {/*         background="canvas" */}
        {/*         round="container1Round" */}
        {/*         margin={{ bottom: 'spacing12' }}  */}
        {/*       > */}
        {/*         <AttentionIcon /> */}
        {/*         <Text */}
        {/*           color="textBody"  */}
        {/*           size="small"  */}
        {/*           margin={{ left: 'spacing12' }}  */}
        {/*           weight={500}  */}
        {/*         > */}
        {/*           The employee can spend {enrollment.type} dollars up to  */}
        {/*           [{dayjs(enrollment.spentItByDate).format(DEFAULT_DATE_FORMAT)}] */}
        {/*         </Text> */}
        {/*       </Box>  */}
        {/*     ) */}
        {/*     : null  */}
        {/* } */}
        <AddEnrollmentModal
          isWithoutButton
          visible={editModalVisible}
          onSetVisible={setEditModalVisible}
          isEdit
          planId={enrollment.planId}
          enrollmentStatus={enrollment.enrollmentStatus}
          // enrollmentDeductibleMet={enrollment.deductibleMet}
          enrollmentElection={enrollment.election}
          statuses={statuses}
        />
      </Box>

      {
        !isDetailsMode && !isNotVerified && (
          <EnrollmentDates
            coverageDateStart={enrollment.coverageDateStart}
            coverageDateEnd={enrollment.coverageDateEnd}
            submitClaimsDateEnd={enrollment.submitClaimsDateEnd}
            statusColor={statusColor}
            statuses={statuses}
            enrollmentStatus={enrollment.enrollmentStatus}
            coveragePeriods={enrollment.coveragePeriods}
            employeeName={employeeName}
          />
        )
      }
    </Box>
  );
};
