import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';

import { ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import { ServiceConfig } from '../queries/useServiceConfigs.query';

export interface TierField {
  id: string;
  name: string;
  payoutDefinition: string;
  payoutDefinitionName: string;
  familyDeductible?: string;
  individualDeductible?: string;
  isSendTriggerNotification?: boolean;
  triggers: string[];
  serviceConfigs?: ServiceConfig[];
  displayName?: string;
  planName?: string;
}

const DEFAULT_TIER_FIELD: TierField = {
  id: '',
  name: '',
  payoutDefinition: '',
  payoutDefinitionName: '',
  triggers: [],
};

export default (data: ProcessingRuleItem[], rule?: string, serviceConfigs?: ServiceConfig[]) => {
  const [fieldsValues, setFieldsValues] = useState<TierField[]>([
    {
      ...DEFAULT_TIER_FIELD,
      id: _.uniqueId(),
    },
  ]);

  const tiers = useMemo(() => {
    const selectedRule = data?.find((el) => el.id === rule);
    return selectedRule?.tiers || [];
  }, [data, rule]);
  useEffect(() => {
    const copiedTiers = [] as TierField[];
    tiers?.map((el) => copiedTiers.push({
      id: el.id,
      name: el.name,
      payoutDefinition: el.payoutDefinition.id,
      payoutDefinitionName: el.payoutDefinition.name,
      isSendTriggerNotification: el.isSendTriggerNotification,
      triggers: el?.triggers || [],
      serviceConfigs: serviceConfigs?.filter((item) => item.processingTierId === el.id) || [],
      displayName: el.displayName,
      planName: el.planName,
    }));
    setFieldsValues(copiedTiers);
  }, [tiers, serviceConfigs]);

  const getFieldValuesById = useCallback(
    (id?: string) => {
      const defaultValues = fieldsValues.find((field) => field.id === id);

      return defaultValues;
    },
    [fieldsValues],
  );

  return {
    getFieldValuesById,
    fieldsValues,
  };
};
