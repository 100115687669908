import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  AppButton,
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  DropContent,
  Inscription,
  Text,
  useClickOutside,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';

import ScheduledContributionCreateModal from './ScheduledContribution/ScheduledContributionCreateModal';
import { MakeContributionModal } from './ContributionModals';

const StyledButton = styled(Button)`
  padding-left: ${({ theme }) => theme.spacings.xs};
  height: ${({ theme }) => theme.spacings.xl};
  min-height: ${({ theme }) => theme.spacings.xl};

  &:hover {
    background: ${({ theme }) => theme.colors.accentContainer};
  }
`;

const DISABLED_KEY = 'DISABLED';
const TopSection = () => {
  const [visiblePopup, setVisiblePopup] = useState(false);

  const popupRef = useRef(null);
  const handleToggleVisiblePopup = useCallback(() => {
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);
  useClickOutside(popupRef, () => {
    setVisiblePopup(false);
  });
  const [isMakeContributionModalVisible, setIsMakeContributionModalVisible] = useState(false);
  const [isScheduledContributionModalVisible, setIsScheduledContributionModalVisible] = useState(false);
  const { observingOrCurrentOrganizationData } = useCurrentOrganization({
    isLoadDefaultOrgData: true
  });




  const isAllowedManualContribution = useMemo(() => {
    if (!observingOrCurrentOrganizationData?.overridable_properties?.allow_manual_contribution) return false;

    return !!observingOrCurrentOrganizationData.overridable_properties.allow_manual_contribution;
  }, [observingOrCurrentOrganizationData]);

  const isAllowedScheduledContribution = useMemo(() => {
    return observingOrCurrentOrganizationData?.overridable_properties?.allow_scheduled_contribution !== DISABLED_KEY;
  }, [observingOrCurrentOrganizationData]);
  const isManualContribution = useHasAccess([{ permission: Permissions.SUBMIT_MANUAL_CONTRIBUTIONS }]);
  const isScheduledContribution = useHasAccess([{ permission: Permissions.MANAGE_SCHEDULED_CONTRIBUTIONS }]);
  
  const isManualContributionShowed = useMemo(() => {
    return isAllowedManualContribution && isManualContribution;
  }, [isAllowedManualContribution, isManualContribution]);

  const isScheduledContributionShowed = useMemo(() => {
    return isScheduledContribution && isAllowedScheduledContribution;
  }, [isScheduledContribution, isAllowedScheduledContribution]);
  if (!isScheduledContributionShowed && !isManualContributionShowed) {
    return <Box height="xxl" />;
  }
  return (
    <Box
      margin={{ vertical: 'spacing24' }}
      direction="row"
      justify="between"
      align="center"
      data-testid="Contribution_top-section"
    >
      <Box>
        <Inscription size="3xl" weight="bold" color="textTitle" lineHeight="40px">
          Contributions
        </Inscription>
      </Box>
      <Box direction="row">
        <DropContent
          bottomMode
          tailless
          leftAlign
          contentWidth="240px"
          visible={visiblePopup}
          style={{ flex: '0 1 auto', zIndex: 100 }}
          content={(
            <Box direction="column" ref={popupRef}>
              {isManualContributionShowed && (
                <Box>
                  <StyledButton type="button" onClick={() => setIsMakeContributionModalVisible(true)}>
                    <Text size="medium" weight={400}>
                      Add Contribution
                    </Text>
                  </StyledButton>
                </Box>
              )}
              {isScheduledContributionShowed && (
                <Box>
                  <StyledButton
                    type="button"
                    onClick={() => {
                      setIsScheduledContributionModalVisible(true);
                    }}
                  >
                    <Text size="medium" weight={400}>
                      Schedule contribution
                    </Text>
                  </StyledButton>
                </Box>
              )}
            </Box>
          )}
        >
          <Box width="214px">
            <AppButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleToggleVisiblePopup();
              }}
              rightIcon={
                visiblePopup ? <ChevronUpIcon color="iconOnColor" /> : <ChevronDownIcon color="iconOnColor" />
              }
            >
              Add Contribution
            </AppButton>
          </Box>
        </DropContent>
      </Box>
      <ScheduledContributionCreateModal
        visible={isScheduledContributionModalVisible}
        onSetVisible={setIsScheduledContributionModalVisible}
      />
      <MakeContributionModal
        visible={isMakeContributionModalVisible}
        onSetVisible={setIsMakeContributionModalVisible}
      />
    </Box>
  );
};

export default TopSection;
