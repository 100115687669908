import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { MAX_LENGTH_TEXT } from '@/common/constants';
import { useFieldsWithDefaultValues } from '@/utils';

import { useCustomReportsStore } from '../stores';

interface Params {
  isEditMode?: boolean;
}
const EMPTY_PLACEHOLDER = '—';
const MAX_FIELD_LENGTH = 40;
const CUSTOM_FIELD_VALIDATOR = VALIDATORS.REQUIRED_STRING.max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH));
export default ({ isEditMode }: Params) => {
  const defaultFields = useCustomReportsStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(
    () => [
      {
        name: 'customReportDisplayedField1',
        type: FieldTypes.Checkbox,
        label: 'Custom reporting field 1',
        placeholder: EMPTY_PLACEHOLDER,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'customReportField1',
            type: FieldTypes.Text,
            parentValue: true,
            label: ' ',
            fieldWrapperStyles: {
              marginTop: -12,
            },
            placeholder: isEditMode ? 'Custom reporting field 1' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ],
      },

      {
        name: 'customReportDisplayedField2',
        type: FieldTypes.Checkbox,
        label: 'Custom reporting field 2',
        placeholder: EMPTY_PLACEHOLDER,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'customReportField2',
            type: FieldTypes.Text,
            label: ' ',
            parentValue: true,
            fieldWrapperStyles: {
              marginTop: -12,
            },
            placeholder: isEditMode ? 'Custom reporting field 2' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ],
      },

      {
        name: 'customReportDisplayedField3',
        type: FieldTypes.Checkbox,
        label: 'Custom reporting field 3',
        placeholder: EMPTY_PLACEHOLDER,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'customReportField3',
            type: FieldTypes.Text,
            parentValue: true,
            label: ' ',
            fieldWrapperStyles: {
              marginTop: -12,
            },
            placeholder: isEditMode ? 'Custom reporting field 3' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ],
      },
      {
        name: 'customReportDisplayedField4',
        type: FieldTypes.Checkbox,
        label: 'Custom reporting field 4',
        placeholder: EMPTY_PLACEHOLDER,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'customReportField4',
            type: FieldTypes.Text,
            parentValue: true,
            label: ' ',
            fieldWrapperStyles: {
              marginTop: -12,
            },
            placeholder: isEditMode ? 'Custom reporting field 4' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ],
      },
      {
        name: 'customReportDisplayedField5',
        type: FieldTypes.Checkbox,
        label: 'Custom reporting field 5',
        placeholder: EMPTY_PLACEHOLDER,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'customReportField5',
            type: FieldTypes.Text,
            label: ' ',
            parentValue: true,
            fieldWrapperStyles: {
              marginTop: -12,
            },
            placeholder: isEditMode ? 'Custom reporting field 5' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ],
      },
    ],
    [isEditMode, ]
  );
  const formattedFields = useFieldsWithDefaultValues(fields, defaultFields);
  return formattedFields;
};
