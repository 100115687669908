import React, { useMemo } from 'react';
import Handlebars from 'handlebars';
import parse from 'html-react-parser';

import globalTheme from '@/styles/theme';

import { useGetEmailTemplateSkeletonQuery, useGetEmailTemplateVariablesQuery } from '../queries';


Handlebars.registerHelper('equals', function(arg1, arg2) {
  return arg1 === arg2 ? '-' : '_';
});

Handlebars.registerHelper('or', function() {
  // eslint-disable-next-line prefer-rest-params
  const args = Array.prototype.slice.call(arguments);
  const options = args.pop();

  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      // @ts-ignore
      return options.fn(this);
    }
  }
  // @ts-ignore

  return options.inverse(this);
});

Handlebars.registerHelper('length', function (context) {
  if (Array.isArray(context) || typeof context === 'string') {
    return context.length;
  }
  return 0;
});

Handlebars.registerHelper('greaterThan', function (a, b, options) {
  // @ts-ignore
  return a > b ? options.fn(this) : options.inverse(this);
});

export const useParseEmailTemplate = (
  templateId?: string,
  orgId?: string,
) => {

  const {
    data: htmlTemplate,
    isLoading: isHtmlTemplateLoading,
  } = useGetEmailTemplateSkeletonQuery(templateId);
  const {
    data: htmlTemplateVariables,
    isLoading: isVariablesLoading,
  } = useGetEmailTemplateVariablesQuery(templateId, orgId);

  const isLoading = useMemo(
    () => isHtmlTemplateLoading || isVariablesLoading,
    [isHtmlTemplateLoading, isVariablesLoading]
  );

  const preparedTemplate = useMemo(
    () => {
      if (htmlTemplate) {
        const compiledTemplate = Handlebars.compile(htmlTemplate);
        const html = compiledTemplate(htmlTemplateVariables);
        const parsedHtml = parse(html);
        return (
          <div
            style={{
              paddingInline: '24px',
              backgroundColor: globalTheme.colors.module,
              borderRadius: globalTheme.rounds.moduleRound,
            }}
          >
            {parsedHtml}
          </div>
        );
      }
      return null;
    },
    [htmlTemplate, htmlTemplateVariables],
  );

  return {
    isLoading,
    preparedTemplate,
  };
};
