import { Field } from '@common-fe/common-fe';

import { REQUIRED_TEXT } from '@/common/constants';
import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';

import { HealthPlanFieldState } from '../../HealthPlan.types';

import useHealthPlanSetupFieldsState from './useHealthPlanSetupFieldsState';

export interface StateFieldMap {
  [key: string]: HealthPlanFieldState;
  // @ts-ignore
  coverageLevels: unknown;
}

const useHealthPlanFieldsState = (currentFields: Field[], coverageId?: string) => {
  const fieldsState = useHealthPlanSetupFieldsState();

  const isViewHidden = useHasAccess([
    {
      permission: Permissions.VIEW_HIDDEN_PLAN_FIELDS,
    },
  ]);

  let states = fieldsState as StateFieldMap;
  if (coverageId) {
    const coverageState = fieldsState.coverageLevels.find((item) => item.coverageId === coverageId);
    if (coverageState) {
      states = coverageState as unknown as StateFieldMap;
    }
  }
  const getFormatedFields = (
    fields: Field[], parentDisabled?: boolean,
  ) => fields.reduce((result: Field[], field): Field[] => {
    const fieldState = states[field.name];

    let currentField = {
      ...field,
    };
    if (!field.label && parentDisabled) {
      currentField = {
        ...currentField,
        disabled: true,
      };
    }
    const isDisabled = fieldState === HealthPlanFieldState.Locked
    || fieldState === HealthPlanFieldState.Hidden;
    if (field.subFields) {
      currentField = {
        ...field,
        subFields: getFormatedFields(field.subFields, isDisabled) as Field[],
      };
    }

    if (fieldState === HealthPlanFieldState.Hidden) {
      if (isViewHidden) {
        return [...result, {
          ...currentField,
          disabled: true,
        }];
      }
      return result;
    }
    if (fieldState === HealthPlanFieldState.Locked) {
      return [...result, {
        ...currentField,
        disabled: true,
      }];
    }
    if (fieldState === HealthPlanFieldState.Modifiable) {
      if (field.disabled) {
        return [...result, {
          ...currentField,
          disabled: false,
        }];
      }
    }
    if (
      fieldState === HealthPlanFieldState.RequiredAtPlan
        || fieldState === HealthPlanFieldState.RequiredAtAllLevels) {
      return [...result, {
        ...currentField,
        showRequireIcon: true,
        validator: currentField.validator?.required(REQUIRED_TEXT),
      }];
    }
    return [...result, currentField];
  }, []);
  return getFormatedFields(currentFields);
};

export default useHealthPlanFieldsState;
