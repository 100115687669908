import { baseStoreCreator } from '@/utils/store';

export interface EmailConfigurationPayload {
  emailLogoType?: string;
  customEmailLogo?: string;
  brandingIllustration?: string;
  emailTheme?: string;
}

export const DEFAULT_VALUES: EmailConfigurationPayload = {
  customEmailLogo: '',
  emailLogoType: '',
  brandingIllustration: '',
  emailTheme: '',
};

export const useEmailConfigurationStore = baseStoreCreator<EmailConfigurationPayload>(
  DEFAULT_VALUES,
);

export default useEmailConfigurationStore;
