import { Option } from '@/common/types';

export enum ScanStatuses {
  Yes = 'yes',
  No = 'no',
}

export interface QuestionPayloadOption {
  prompt: string;
  type: string;
  answer: string[ ],
}
export interface ResponsePayload {
  id_number: number;
  id_scan: string;
  id_scan_url?: string;

  // questions: {
  //   question: QuestionPayloadOption[]
  // }

}
export interface QuestionnaireField {
  key: string;
  label: string;
  options: Option[];
}

export interface QuestionnaireFormattedPayload {
  id: number;
  idScan: string;
  idScanUrl: string;
  questions: QuestionnaireField[]
}

export const AnswersStatus = {
  Challenge: 'challenge',
  Fail: 'fail',
  Pass: 'pass',
  Success: 'success',
};
