import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollGroupRequest } from '../payrollGroup.types';

const useCreatePayrollGroupQuery = (onSuccess?: () => void) => {
  const { mutateAsync, isLoading } = useMutation(
    (newGroup?: PayrollGroupRequest) => api.post(
      PATHS.CREATE_PAYROLL_GROUP,
      newGroup,
    ),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
    },
  );

  const save = useCallback(async (newGroup?: PayrollGroupRequest) => {
    await mutateAsync(newGroup);
  }, [mutateAsync]);

  return {
    save,
    isLoading,
  };
};

export default useCreatePayrollGroupQuery;