import React from 'react';
import {
  AdoptionIcon,
  CardTypesEnum,
  CommuterParkingIcon,
  CommuterTransitIcon,
  DentalIcon,
  DependentCareFSAIcon,
  HealthCareFCAIcon,
  HealthReimbursementArrangementIcon,
  HealthSavingAccountIcon,
  LifestyleIcon,
  MedicalIcon,
  NullPlanIcon,
  RXIcon,
  SpecialtyIcon,
  TravelIcon,
  VisionIcon,
  WellnessIcon,
} from '@common-fe/common-fe';

interface Props {
  type?: CardTypesEnum;
  opacity?: string;
  size?: string;
  height?: string;
  color?: string;
}
const PlanTypeIcon: React.FC<Props> = ({ type, ...props }) => {
  if (type === CardTypesEnum.HSA) {
    return (
      <HealthSavingAccountIcon {...props} />
    );
  }

  if (type === CardTypesEnum.MEDICAL) {
    return (
      <MedicalIcon {...props} />
    );
  }
  if (type === CardTypesEnum.DENTAL) {
    return (
      <DentalIcon {...props} />
    );
  }
  if (type === CardTypesEnum.VISION) {
    return (
      <VisionIcon {...props} />
    );
  }
  if (type === CardTypesEnum.RX) {
    return (
      <RXIcon {...props} />
    );
  }

  if (type === CardTypesEnum.HCFSA) {
    return (
      <HealthCareFCAIcon {...props} />
    );
  }
  if (type === CardTypesEnum.DCAP) {
    return (
      <DependentCareFSAIcon {...props} />
    );
  }
  if (type === CardTypesEnum.HRA) {
    return (
      <HealthReimbursementArrangementIcon {...props} />
    );
  }
  if (type === CardTypesEnum.TRANSIT) {
    return (
      <CommuterTransitIcon {...props} />
    );
  }
  if (type === CardTypesEnum.ADOPTION) {
    return (
      <AdoptionIcon {...props} />
    );
  }
  if (type === CardTypesEnum.PARKING) {
    return (
      <CommuterParkingIcon {...props} />
    );
  }
  if (type === CardTypesEnum.LIFESTYLE) {
    return (
      <LifestyleIcon {...props} />
    );
  }
  if (type === CardTypesEnum.WELLNESS) {
    return (
      <WellnessIcon {...props} />
    );
  }
  if (type === CardTypesEnum.SPECIALTY) {
    return (
      <SpecialtyIcon {...props} />
    );
  }
  if (type === CardTypesEnum.TRAVEL) {
    return (
      <TravelIcon {...props} />
    );
  }
  return (
    <NullPlanIcon {...props} />
  );
};
export default PlanTypeIcon;
