import { storeCreator } from '@/utils/store/store.utils';

import { PayrollCalendarFormValues } from '../payrollCalendar.types';

interface State {
  calendarParams?: PayrollCalendarFormValues;
  handleSetCalendarParams: (value: PayrollCalendarFormValues) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  calendarParams: undefined,

  handleSetCalendarParams: (value) => set((state) => ({
    ...state,
    calendarParams: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    calendarParams: undefined,
  })),
}));