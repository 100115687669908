import { useMemo } from 'react';
import _ from 'lodash';

import { useHealthPlanSetupState } from '../../hooks';

export const useHealthPlanProgress = () => {
  const state = useHealthPlanSetupState();
  const filledState = useMemo(() => {
    const emptynessState = _.pickBy(state, _.identity);
    const arraylessState = _.pickBy(emptynessState, (value) => {
      if (_.isArray(value) && value.length === 0) {
        return false;
      }
      return true;
    });
    return arraylessState;
  }, [state]);

  const progress = useMemo(() => {
    const total = Object.keys(state).length;
    const passed = Object.keys(filledState).length;
    return {
      total,
      passed,
    };
  }, [filledState, state]);
  const percent = useMemo(() => (
    progress
      ? Math.floor((progress.passed / progress.total) * 100)
      : 0) || 0,
  [progress]);
  return {
    ...progress,
    percent,

  };
};
