import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Box, FlexForm,
  Inscription, Preloader, } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { PaymentMethodOwnerType, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';
import theme from '@/styles/theme';

import useAddPaymentMethodQuery from '../../useAddPaymentMethod.query';
import { useUpdatePaymentMethodQuery } from '../../useUpdatePaymentMethod.query';

import useProviderFields, { BankInfo } from './useBankFields';
import { useGetBankAccountByIdQuery } from './useGetBankAccountsList.query';

interface Props {
  back?: () => void;
  onSuccess?: () => void;
  bankAccountId?: number | string;
  paymentMethodId?: number | string;
  onError?: () => void;
  onAddBankAccount?: (value: string) => void;
  backLabel?: string;
  isContribution?: boolean;
}

const BankForm: React.FC<Props> = ({
  back,
  onSuccess,
  bankAccountId,
  paymentMethodId,
  onError,
  backLabel,
  onAddBankAccount,
  isContribution,
}) => {
  const { handleAddPermanentSnackbar } = useSnackbar();
  const handleAddedPaymentMethodSruccessfully = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }
    handleAddPermanentSnackbar({
      text: 'Payment method added successfully!',
      closeIcon: true,
      state: SnackbarState.positive,
    });
  }, [onSuccess, handleAddPermanentSnackbar]);
  const { save, isLoading } = useAddPaymentMethodQuery(handleAddedPaymentMethodSruccessfully, onAddBankAccount, isContribution);
  const {
    updatePaymentMethod: updateDirectDeposit,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useUpdatePaymentMethodQuery(onSuccess);

  const {
    currentBankAccount,
    isLoading: isBankAccountLoading,
  } = useGetBankAccountByIdQuery(bankAccountId);

  const formRef = useRef<HTMLFormElement>(null);
  const fields = useProviderFields(currentBankAccount);
  const submit = useCallback(async (bankData: BankInfo) => {
    try {
      await save({
        paymentType: PaymentMethodType.DIRECT_DEPOSIT,
        paymentOwnerType: PaymentMethodOwnerType.EMPLOYEE,
        bankData,
        isDefault: true,
      });
    } catch (e) {
      // @ts-ignore
      if (onError && e.response.status === 409) {
        onError();
      }
    }
  }, [save, onError]);

  const update = useCallback(async (bankData: BankInfo) => updateDirectDeposit({
    paymentType: PaymentMethodType.DIRECT_DEPOSIT,
    paymentMethodId: paymentMethodId || '',
    bankData,
  }), [updateDirectDeposit, paymentMethodId]);

  const submitButtonLabel = useMemo(() => (bankAccountId
    ? 'Submit'
    : 'Next'), [bankAccountId]);

  const handleSubmit = useCallback(() => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    }
  }, [formRef]);

  useEffect(() => {
    if (onSuccess && (isUpdateSuccess)) onSuccess();
  }, [isUpdateSuccess, onSuccess]);

  return (
    <Box>
      {bankAccountId && isBankAccountLoading
        ? <Preloader />
        : (
          <FlexForm
            ref={formRef}
            paddingless
            contentStyles={theme.formFieldsEdging}
            formTitle={(
              <Inscription
                margin={{ bottom: '22px' }}
                lineHeight="28px"
                color="textTitle"
                size="xxlarge"
              >
                Banking information
              </Inscription>
            )}
            fields={fields}
            editMode
            onSubmit={bankAccountId ? update : submit}
          />
        )}
      <Box direction="row" margin={{ top: 'spacing24' }}>
        <Box gap="spacing12" direction="row" justify="end" margin={{ left: 'auto'}}>
          {back && (
            <AppButton
              onClick={back}
              buttonType="secondary"
              width="130px"
            >
              {backLabel || bankAccountId ? 'Cancel' : 'Back'}
            </AppButton>
          )}
          <AppButton onClick={handleSubmit} width="130px">
            {(isLoading || isUpdateLoading)
              ? <Preloader color="white" />
              : submitButtonLabel}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BankForm;
