import {
  useMutation,
  useQueryClient,
} from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { useSnackbar } from '@/modules/core/hooks';
import { ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { QUERY_KEY } from '@/modules/ProcessingDefinition/ProcessingRule/queries/useProcessingRules.query';

import { useProcessingRulesParamsStore } from '../../stores';

interface TierPayload {
  name: string;
  payout_definition_id: string;
  triggers?: string[];
}
interface ProcessingRulePayloadBody {
  name: string;
  organization_id?: string;
  tiers: TierPayload[];
}
const useCreateProcessingRuleQuery = (updatedElement?: ProcessingRuleItem) => {
  const { handleAddPermanentSnackbar } = useSnackbar();
  const setNewId = useProcessingRulesParamsStore((state) => state.setNewItemId);
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (payload: ProcessingRulePayloadBody) => updatedElement ? api.put(`${PATHS.PROCESSING_RULES}/${updatedElement?.id}`, payload) : api.post(PATHS.PROCESSING_RULES, payload),
    {
      onSuccess: (data) => {
        setNewId(`${data.data.id || ''}`);
        handleAddPermanentSnackbar({
          text: `${updatedElement ? 'Edited' : 'Created'} ${data?.data?.name} rule`,
          closeIcon: true,
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY],
        });
      },
    },
  );

  return { mutateAsync, isLoading };
};

export default useCreateProcessingRuleQuery;
