import React from 'react';
import { Box, Image } from '@common-fe/common-fe';

import LogoIcon from '@/assets/logos/login-logo.svg';
import { EmailPickerOption } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/EmailLogoPicker/EmailLogoPicker';
import { StyledAnchor } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/PreviewSection/PreviewSection.styles';
import { useEmailConfigurationStore, useEmailIllustrationsStore } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/stores';
import useLinkManagementStore from '@/modules/employer/components/SetupEmployer/LinkManagement/useLinkManagementStore.store';

interface EmailLogoProps {
  emailLogoType?: string;
  customEmailLogo?: string;
  brandingLogo?: string;
}

const EmailLogo: React.FC<EmailLogoProps> = ({ emailLogoType, customEmailLogo, brandingLogo }) => {
  switch (emailLogoType) {
  case EmailPickerOption.ORGANIZATION_BRANDING:
    return (
      <Image
        src={brandingLogo}
        fit="contain"
        height={40}
        alt="organization-branding"
      />
    );
  case EmailPickerOption.CUSTOM:
    return (
      <Image
        src={customEmailLogo}
        fit="contain"
        height={40}
        alt="custom-logo"
      />
    );
  default:
    return (
      <Image
        src={LogoIcon}
        width={144}
        height={40}
        alt="elevate-logo"
      />
    );
  }
};

const EmailTemplateHeader = () => {
  const emailConfigurationState = useEmailConfigurationStore((state) => state.state);
  const emailIllustrationsState = useEmailIllustrationsStore((state) => state.state);
  const linkManagementStore = useLinkManagementStore((state) => state.state);

  return (
    <Box
      justify="between"
      direction="row"
      align="center"
      pad={{ vertical: '20px', horizontal: '40px' }}
      height={{ min: '80px', max: '80px' }}
      border={{ color: 'border1', size: 'small', side: 'bottom' }}
      data-testId="email-template-header"
    >
      <Box>
        <EmailLogo
          emailLogoType={emailConfigurationState?.emailLogoType}
          customEmailLogo={emailConfigurationState?.customEmailLogo}
          brandingLogo={emailIllustrationsState?.emailLogoLink}
        />
      </Box>
      <StyledAnchor
        href={linkManagementStore?.aboutUsLink}
        target="_blank"
        color="textTitle"
        weight={400}
        size="14px"
      >
        About Us
      </StyledAnchor>
    </Box>
  );
};

export default EmailTemplateHeader;
