import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

const QUERY_KEY = 'useTerminateEmployerQueryKey';

interface EmployerTerminationPayload {
  termination_date: string;
  coverage_end_date?: string;
  claim_last_date?: string;
  bulk_transfer: boolean;
}

export const useTerminateEmployerQuery = (id: string) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    [QUERY_KEY, id],
    (data :EmployerTerminationPayload) => api.put(
      PATHS.TERMINATE_ORGANIZATION(id), data,
    ),
  );

  return {
    terminateEmployer: mutateAsync,
    isLoading,
    isSuccess,
  };
};
