import _ from 'lodash';

export interface Result {
  [key: string]: string;
}
export default (errors: object) => {
  const fields = _.get(errors, 'response.data.elevate_error_meta_info.invalid_fields', []) as object[];
  const result: Result = {};
  if (fields && fields.length > 0) {
    fields.forEach((field) => {
      const key = _.get(field, 'field_name', '');
      const value = _.get(field, 'elevate_error_message', '');
      if (key && value) {
        result[key] = value;
      }
    });
  }
  return result;
};
