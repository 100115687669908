import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppButton, Box, WarnModal } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { ExpressSetup } from '../ExpressSetup.types';
import { useFundingItems } from '../hooks';

import ModalContainer from './ModalContainer';

interface Props {
  isVisible: boolean;
  expressSetup?: ExpressSetup;
  onSetVisible: (value: boolean) => void;
}

export const activationKey = 'shouldActivateExpressSetup';

const ExpressSetupActivateModal: React.FC<Props> = ({
  isVisible,
  expressSetup,
  onSetVisible,
}) => {
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const { fundingNextStepsItems, fundingAndCardsItems } = useFundingItems(expressSetup);

  const handleConfirm = useCallback(() => {
    localStorage.setItem(activationKey, 'true');
    history.push(ROUTES.EXPRESS_SETUPS_ACTIVATE_BY_ID(id));
  }, [history, id]);


  return (
    <WarnModal
      header="Almost there..."
      data-testid="express-setup-activate-modal"
      helptext="We've laid out everything about your plan configurations here to review before you confirm – please take a moment to ensure it all looks right"
      visible={isVisible}
      onSetVisible={onSetVisible}
      onSubmit={handleConfirm}
      isPendingIcon
      cancelButtonNode={(
        <Box>
          <Box background="module" pad="l" margin={{ vertical: 'l' }} round="container1Round" data-testid="express-setup-modal-container" gap={fundingAndCardsItems.length ? 'l' : '0'}>
            <ModalContainer
              title="Funding & Cards"
              items={fundingAndCardsItems}
            />
            <ModalContainer
              title="Next Steps"
              items={fundingNextStepsItems}
            />
          </Box>
          <Box width="100%" direction="row" justify="between" gap="s">
            <AppButton
              onClick={() => onSetVisible(false)}
              buttonType="secondary"
              style={{ width: '330px', height: '54px' }}
            >
              Cancel
            </AppButton>
            <AppButton
              onClick={handleConfirm}
              style={{ width: '330px', height: '54px' }}
            >
              Confirm
            </AppButton>
          </Box>
        </Box>
      )}
    />
  );
};

export default ExpressSetupActivateModal;
