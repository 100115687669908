import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  FieldTypes,
  FlexControlledForm,
  Preloader,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';

import { Carrier } from '../OrgLevelSelector/useGetCarriers.query';

import useCreateCarrierQuery from './useCreateCarrier.query';

const MAX_CARRIER_NAME_LENGTH = 48;
const ALLOWABLE_EXTERNAL_ID_RANGE = [3, 40];
const isCarrierValid = (carrier: Carrier) => {
  if (!carrier.name || !carrier.externalId) {
    return false;
  }

  if (carrier.name.length > MAX_CARRIER_NAME_LENGTH) return false;

  if (carrier.externalId.length < ALLOWABLE_EXTERNAL_ID_RANGE[0]
    || carrier.externalId.length > ALLOWABLE_EXTERNAL_ID_RANGE[1]) return false;

  return true;
};

interface Props {
  onClose: () => void;
  onSuccess: (carrier: Carrier) => void;
}

const CreateCarrierForm: React.FC<Props> = ({ onClose, onSuccess }) => {
  const [existedExternalIds, setExistedExternalIds] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const [carrier, setCarrier] = useState<Carrier>({ id: '', name: '', externalId: '' });

  const addExistedExternalId = useCallback((externalId: string) => setExistedExternalIds([
    ...existedExternalIds.filter((id) => id !== externalId),
    externalId,
  ]), [existedExternalIds]);

  const doesCarrierExternalIdExist = useCallback((externalId: string) => existedExternalIds
    .some((id) => id === externalId), [existedExternalIds]);

  const onError = useCallback((externalId: string) => {
    if (externalId) {
      addExistedExternalId(externalId);
    }
  }, [addExistedExternalId]);
  const { create, isLoading } = useCreateCarrierQuery(onSuccess, onError);
  const isDisabled = useMemo(() => doesCarrierExternalIdExist(carrier.externalId)
    || isLoading,
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  [isLoading, existedExternalIds, carrier.externalId]);

  return (
    <Box>
      <Box background="module" pad="spacing24" round="medium">
        <Box
          background="canvas"
          round="container1Round"
          border={{ color: 'border2', size: 'small' }}
          pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
          elevation="default"
        >
          <FlexControlledForm
            testId="create_carrier"
            editMode
            showError={showError}
            fields={[
              {
                placeholder: 'Enter carrier name',
                showRequireIcon: true,
                name: 'name',
                type: FieldTypes.Text,
                label: 'Carrier name',
                // @ts-ignore
                isNarrowLabelMode: true,
                validator: yup.string().test({
                  test: (val = '') => val?.length <= MAX_CARRIER_NAME_LENGTH,
                  message: 'The value is too long.',
                }).required(REQUIRED_TEXT),
              },
              ...isLoading
                ? [{
                  placeholder: 'Enter carrier ID',
                  showRequireIcon: true,
                  name: 'externalId',
                  type: FieldTypes.Node,
                  label: 'Carrier ID',
                  // @ts-ignore
                  isNarrowLabelMode: true,
                  value: <Preloader />,
                }]
                : [{
                  placeholder: 'Enter carrier ID',
                  showRequireIcon: true,
                  name: 'externalId',
                  type: FieldTypes.Text,
                  label: 'Carrier ID',
                  isNarrowLabelMode: true,
                  validator: yup.string().test({
                    test: (val) => !doesCarrierExternalIdExist(val || ''),
                    message: 'This carrier ID already exists',
                  }).test({
                    test: (val = '') => val?.length <= ALLOWABLE_EXTERNAL_ID_RANGE[1],
                    message: 'The value is too long.',
                  }).test({
                    test: (val = '') => val?.length >= ALLOWABLE_EXTERNAL_ID_RANGE[0],
                    message: 'The value is short.',
                  })
                    .required(REQUIRED_TEXT),
                }],
            ]}
            onChangeValues={setCarrier}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ margin: 0 }}
            isThinMode
          />
        </Box>
      </Box>
      <Box margin={{ top: 'spacing24' }}>
        <Box
          justify="end"
          align="center"
          direction="row"
        >
          <AppButton
            buttonType="secondary"
            onClick={onClose}
            width="160px"
          >
            Cancel
          </AppButton>
          <Box margin={{ left: 'spacing12' }}>
            <AppButton
              onClick={() => {
                setShowError(true);
                if (isCarrierValid(carrier)) {
                  create(carrier);
                }
              }}
              width="160px"
              disabled={isDisabled}
            >
              Create
            </AppButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCarrierForm;
