import React from 'react';
import {
  Box, ChevronDownIcon,
  ChevronUpIcon, Heading, } from '@common-fe/common-fe';

interface Props {
  title: string;
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => void;
}

const Title: React.FC<Props> = ({ title, isExpanded, setIsExpanded }) => (
  <Box
    data-testId="ActivityLog_title"
    onClick={() => setIsExpanded(!isExpanded)}
    direction="row"
    align="center"
  >
    { isExpanded ? (
      <ChevronUpIcon color="textTitle" />
    ) : (
      <ChevronDownIcon color="textTitle" />
    )}
    <Heading
      fill
      level={4}
      size="21px"
      color="textTitle"
    >
      {title}
    </Heading>
  </Box>
);

export default Title;
