import React from 'react';
import { Box,Text } from '@common-fe/common-fe';

interface Props {
  color: string;
  children: React.ReactNode;
  id?: string;
}
const ThemeItem: React.FC<Props> = ({ color, children, id }) => (
  <Box direction="row" align="center" data-testid={`theme-item-${id || ''}`}>
    <Box height="16px" width="16px" round="50%" background={color} margin={{ right: 'xs' }} />
    <Text size="medium" color="textBody">
      {children}
    </Text>
  </Box>
);
export default ThemeItem;
