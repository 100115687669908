import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useBasicPropertiesFieldsStateSetter, { BasicPropertiesFieldsStatePayload } from '../BasicProperties/hooks/useBasicPropertiesFieldsStateSetter';
import useEnrollmentMatrixFieldsStateSetter, { ComplexConfigurationFieldsStatePayload } from '../ComplexConfiguration/EnrollmentMatrix/hooks/useEnrollmentMatrixFieldsStateSetter';
import useCoverageLevelsFieldsStateSetter, { CoverageLevelsFieldsStatePayload } from '../CoverageLevels/hooks/useCoverageLevelsFieldsStateSetter';

export type PlanPayload = BasicPropertiesFieldsStatePayload & CoverageLevelsFieldsStatePayload & ComplexConfigurationFieldsStatePayload & {
  coverageLevels: CoverageLevelsFieldsStatePayload[]
};

export default () => {
  const {
    handleSetState: handleSetBasicProperties,
    handleSetSourceState: handleSetSourceBasicProperties,
    handleReset: handleResetBasicProperties,
    handleSetErrors: handleSetBasicPropertiesErorrs,
    handleSetErrorFields: handleSetBasicPropertiesErrorFields,
  } = useBasicPropertiesFieldsStateSetter();

  const {
    handleSetState: handleSetCoverageLevelsFieldsState,
    handleSetSourceState: handleSetCoverageLevelsSourceState,
    handleReset: handleResetCoverageLevelsFieldsState,

    // handleSetErrorFields: handleSetCoverageLevelsFieldsStateErrorFields,
  } = useCoverageLevelsFieldsStateSetter();


  const {
    handleSetState: handleSetEnrollmentMatrixState,
    handleSetSourceState: handleSetEnrollmentMatrixSourceState,
    handleReset: handleResetEnrollmentMatrixFieldsState,
    handleSetErrors: handleSetEnrollmentMatrixErorrs,
    handleSetErrorFields: handleEnrollmentMatrixErrorFields,
  } = useEnrollmentMatrixFieldsStateSetter();

  const handleReset = useCallback(() => {
    handleResetBasicProperties();
    handleResetCoverageLevelsFieldsState();
    handleResetEnrollmentMatrixFieldsState();
  }, [handleResetBasicProperties, handleResetCoverageLevelsFieldsState, handleResetEnrollmentMatrixFieldsState]);

  const handleSetState = useCallback((values: Partial<PlanPayload>, coverageLevelId?: string) => {
    handleSetBasicProperties(values);
    handleSetEnrollmentMatrixState(values);
    if (coverageLevelId) {
      handleSetCoverageLevelsFieldsState(
        values as CoverageLevelsFieldsStatePayload,
        coverageLevelId,
      );
    }
  }, [handleSetBasicProperties, handleSetCoverageLevelsFieldsState, handleSetEnrollmentMatrixState]);

  const handleSetSourceState = useCallback(
    (values: Partial<PlanPayload>, coverageLevelId?: string) => {
      handleSetSourceBasicProperties(values);
      handleSetEnrollmentMatrixSourceState(values);

      if (values.coverageLevels) {
        handleSetCoverageLevelsSourceState(values.coverageLevels);
      }
    }, [handleSetCoverageLevelsSourceState, handleSetSourceBasicProperties, handleSetEnrollmentMatrixSourceState],
  );

  const handleSetErrors = useCallback((values: Partial<PlanPayload>) => {
    handleSetBasicPropertiesErorrs(values);
    handleSetEnrollmentMatrixErorrs(values);
  }, [handleSetBasicPropertiesErorrs, handleSetEnrollmentMatrixErorrs]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<PlanPayload>>) => {
      handleSetBasicPropertiesErrorFields(values);
      handleEnrollmentMatrixErrorFields(values);
    }, [handleSetBasicPropertiesErrorFields, handleEnrollmentMatrixErrorFields],
  );
  return {
    handleSetSourceState,
    handleSetErrorFields,
    handleSetErrors,
    handleReset,
    handleSetState,
  };
};
