import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum,GroupedPlans, PlanItem } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';
import {
  PlanGroupedListResponse, PlanListItemResponse, PlanTypesLabel,
} from '@/modules/plan/plan.types';

export const PLAN_TEMPLATES_KEY = 'getLinkedPlanTempaltes';

export const useLinkedPlanTemplatesQuery = (
  onlyLinked?: boolean,
  orgId?: string,
  isVisibleModal?: boolean,
  searchName?: string,
) => {
  const { data, isLoading } = useQuery([PLAN_TEMPLATES_KEY, onlyLinked, searchName, orgId],
    () => api.get<PlanGroupedListResponse>(PATHS.LINKED_TEMPLATES(orgId), {
      params: {
        only_linked: onlyLinked,
        name: searchName,
      },
    }),
    {
      enabled: !!orgId && isVisibleModal,
    });

  const formattedData = useMemo<GroupedPlans[]>(() => {
    const list = data?.data || {};
    const reorganizedOptions = Object.entries(list)?.map(([category, items]) => ({
      category: PlanTypesLabel[category as keyof typeof PlanTypesLabel],
      plans: items
        ?.filter((item: PlanListItemResponse) => (onlyLinked || !item.is_linked))
        ?.map((item: PlanListItemResponse) => ({
          id: item?.id?.toString(),
          type: item.account_type as CardTypesEnum,
          name: item?.name,
          isLinked: item?.is_linked,
          isRemovable: item?.is_removable,
        })),
    }))
      .filter((category) => category?.plans?.length > 0)
      .sort((a, b) => a?.category?.localeCompare(b?.category));
    return reorganizedOptions || [];
  }, [data, onlyLinked]);

  const formattedLinkedTemplates = useMemo(() => {
    const allTemplateItems: PlanItem[] = formattedData
      .flatMap((category) => category.plans || []);
    return allTemplateItems;
  }, [formattedData]);

  const linkedTemplateIds = useMemo(() => formattedLinkedTemplates
    .map((item) => parseInt(item.id, 10)), [formattedLinkedTemplates]);

  return {
    formattedData,
    formattedLinkedTemplates,
    linkedTemplateIds,
    isLoading,
  };
};
