import React, { useState } from 'react';
import { AccordionItem, Box, Inscription, ShimmerBox } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import ModalContainer from './ExpressSetupActivateModal/ModalContainer';
import ExpressSetupBanner from './ExpressSetupBanner';
import ExpressSetupTopbar from './ExpressSetupTopbar';
import { useCurrentExpressSetup } from './hooks';
import { useFundingItems } from './hooks';

const ExpressSetupActivate: React.FC = () => {
  const [isExpanded, setExpanded] = useState(false);
  const { data: expressSetupData, isLoading } = useCurrentExpressSetup();
  const { fundingNextStepsItems } = useFundingItems(expressSetupData);
  return (
    <Box
      width="100%"
      data-testid="express-setup-activate-wrapper"
      justify="between"
      height="fit-content"
      margin={{ bottom: 'l' }}
    >
      <Box>
        <ExpressSetupTopbar
          logo={expressSetupData?.logo}
          orgId={expressSetupData?.orgId}
          isLoading={isLoading}
        />
        <Box width={theme.defaultMinContentWidth} alignSelf="center" margin="l">
          <ExpressSetupBanner
            isActivateMode
            expressSetupData={expressSetupData}
            isLoading={isLoading}
          />
          {
            isLoading ? <ShimmerBox width="100%" height="60px" /> : (
              <Box
                pad={{ vertical: 'm', horizontal: 'l' }}
                border={{ color: 'border2', size: 'large' }}
                round="container1Round"
                background={isExpanded ? 'border2' : 'canvas'}
              >
                <AccordionItem
                  onChange={() => setExpanded(!isExpanded)}
                  iconSize='24px'
                  text={(
                    <Box
                      background={isExpanded ? 'border2' : 'canvas'}
                      margin={{ left: 'xxxs' }}
                      direction="row"
                      align="center"
                    >
                      <Inscription color="textBody" weight="normal" size='16px' lineHeight='22px'>
                        Next Steps
                      </Inscription>
                    </Box>
                  )}
                  isLeftArrow
                >    
                  <Box
                    background="border1"
                    margin={{ top: 'xs' }}
                  >
                    <ModalContainer
                      isPageMode
                      items={fundingNextStepsItems}
                    />
                  </Box>
                </AccordionItem>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};
export default ExpressSetupActivate;
