 
import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  AppButton,
  Box, CardTypesEnum, CheckBox, Preloader, Text, WarnModal, } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import PlanCard from '@/components/elements/PlanCard';
import { useHistory } from '@/modules/core/hooks';
import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PlanCardMode, PlanFieldState, PlanStatuses, PlansType } from '../../plan.types';
import { useCurrentPlan } from '../hooks';
import { usePreviewStore,useSkipConfirmMode } from '../stores';

import { useDeletePlanQuery } from './queries';

interface Props {
  viewMode?: boolean;
  isTemplateMode?: boolean;
}
const DELAY_TIME = 2000;
const DangerZone: React.FC<Props> = ({ isTemplateMode }) => {
  const previewMode = usePreviewStore((state) => state.previewMode);
  const setSkipConfirmMode = useSkipConfirmMode((state) => state.setSkipConfirm);
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: plan, planStatus } = useCurrentPlan();
  const disabled = useMemo(() => {
    if ((planStatus === PlanStatuses.ACTIVE
      || planStatus === PlanStatuses.PENDING_ACTIVE) || !plan?.deletable) {
      return true;
    }
    return false;
  }, [plan, planStatus]);
  const {
    handleDelete,
  } = useDeletePlanQuery(`${plan?.id}`);

  const handleClose = useCallback(() => {
    if (!loading) {
      setShowModal(false);
      setLoading(false);
      setConfirmed(false);
      setSubmitted(false);
    }
  }, [loading]);

  const handleConfirm = () => {
    setSubmitted(true);
    if (confirmed) {
      setLoading(true);

      setTimeout(async () => {
        try {
          setShowModal(false);
          await handleDelete();
          setSuccessModal(true);
          setSkipConfirmMode(true);
        } catch {
          setLoading(false);
        }
      }, DELAY_TIME);
    }
  };
  const handleOpenPlans = useCallback(() => {
    if (isTemplateMode) {
      history.originPush(`${history.getPath(ROUTES.PLANS)}#${PlansType.Template}`);
    } else {
      history.originPush(`${history.getPath(ROUTES.PLANS)}#${PlansType.Plan}`);
    }
  }, [history, isTemplateMode]);

  if (!plan?.deletable && !disabled && !isTemplateMode) {
    return null;
  }

  return (
    <>
      <WarnModal
        visible={successModal}
        isSuccessIcon
        onSetVisible={(value: boolean) => (value ? setSuccessModal(value) : handleOpenPlans())}
        header={isTemplateMode ? `${plan?.name?.name} plan template was deleted` : `${plan?.name?.name} was deleted`}
        buttonText=""
        cancelButtonNode={(
          <AppButton
            testId="go-to-plans"
            onClick={handleOpenPlans}
          >
            {isTemplateMode ? 'Go to Plan Templates' : 'Go to Plans'}
          </AppButton>
        )}
      />

      {showModal && (
        <WarnModal
          isPendingIcon
          visible
          paddingless
          onSetVisible={(value: boolean) => (value ? setShowModal(true) : handleClose())}
          onCancel={handleClose}
          header={` Delete ${plan?.name.name} ?`}
          buttonText="Cancel"
          onSubmit={handleConfirm}
          submitButtonMode="alert"
          cancelButtonNode={(

            <Box direction="row">
              <Box width="small" margin={{ right: 'spacing6' }}>
                <AppButton
                  buttonType="secondary"
                  onClick={handleClose}
                  width="100%"
                >
                  Cancel
                </AppButton>
              </Box>
              <Box width={{ min: 'small' }} margin={{ left: 'spacing6' }}>
                <AppButton
                  testId="accept-delete-plan"
                  color="red"
                  onClick={handleConfirm}
                  width="100%"
                >
                  {loading ? <Preloader color="white" /> : (
                    isTemplateMode
                      ? 'Yes, Delete This Plan Template'
                      : 'Yes, Delete This Plan'
                  )}
                </AppButton>
              </Box>
            </Box>

          )}

        >
          <Box fill direction="column">
            <Box
              background="module"
              fill
              justify="center"
              align="center"
              round="moduleRound"
              margin={{ vertical: 'spacing24' }}
              pad={{ vertical: 'spacing12' }}

            >
              <Box align='center' style={{position:'relative'}}>
                <PlanCard
                  plan={{
                    id: `${plan?.id}`,
                    name: plan?.name?.name || '',
                    typeState: PlanFieldState.Modifiable,
                    type: plan?.account_type.account_type as CardTypesEnum,
                    status: plan?.plan_status as PlanStatuses,
                    info: {},
                  }}
                  isFlat
                  isTemplateMode={isTemplateMode}
                  mode={PlanCardMode.Plan}
                  // style={{
                  //   width: theme.controls.formWidth.width,
                  //   minHeight: theme.controls.cardHeight,
                  // }}
                />
              </Box>
            </Box>
            <Box direction="row" align="center" justify="center">
              <CheckBox
                name="primary"
                role="checkbox"
                data-testid="delete-plan-checkbox"
                aria-label="primary"
                checked={confirmed}
                onChange={({ target }) => setConfirmed(target.checked)}
                label={'I understand that deleting is permanent, and can\'t be undone'}
              />
            </Box>
            {submitted && !confirmed && (
              <Box direction="row" data-testid="accept-delete-plan-error" align="center" justify="center">
                <Text color="danger">
                  Confirm you agree, that deleting is permanent
                </Text>
              </Box>
            )}
          </Box>
        </WarnModal>
      )}

      <ModuleWrapper
        anchorId="dangerZone"
        title="Danger Zone"
        expandless
      >
        <Box direction="row">

          <Box
            fill
            align="center"
            pad="spacing24"
            round="container1Round"
            direction="column"
            elevation="default"
            background="canvas"
            margin={{ right: 'spacing8' }}
          >
            <Text size="2xl" color="textBody" weight={700}>
              Delete
            </Text>
            <Box fill margin={{ top: 'spacing24' }} direction="column">

              <AppButton
                testId="delete-plan"
                color="red"
                size="L"
                disabled={disabled || previewMode}
                onClick={() => setShowModal(true)}
                width="100%"
              >
                {isTemplateMode ? 'Delete Plan Template' : 'Delete Plan'}
              </AppButton>
              {disabled
              && (
                <Box fill align="center" margin={{ top: 'spacing24' }} justify="center">
                  <Text
                    textAlign="center"
                    color="textSecondary"
                  >
                    {isTemplateMode
                      ? 'This plan template cannot be deleted because it is connected to another template, plan, or payment order.'
                      : 'This plan cannot be deleted because it is active.'}

                  </Text>
                </Box>
              )}
            </Box>
          </Box>
          <Box fill margin={{ left: 'spacing8' }} />
        </Box>

      </ModuleWrapper>

    </>
  );
};

export default DangerZone;
