import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { useCurrentOrganization } from '@/modules/core/hooks';
import { ObservingOrganizationProperties } from '@/modules/core/hooks/useCurrentOrganization';
interface Props {
  value?: string;
}
const FileLocationTitle: React.FC<Props> = ({ value }) => {
  const { observingOrganization } = useCurrentOrganization();
  const externalIdentifier = useMemo(() => {
    const currentOrganization = observingOrganization as ObservingOrganizationProperties;
    if (currentOrganization) {
      return currentOrganization?.externalIdentifier?.toLocaleLowerCase();
    }
    return undefined;
  }, [observingOrganization]);

  return (
    <Box width={{ max: '320px' }}>
      <Text
        style={{
          whiteSpace: value ? undefined : 'nowrap',
        }}
        margin={{ bottom: 'xxs' }}
        data-testId="file-location-title"
        size="medium"
        color="textSecondary"
      >
        sftp-{externalIdentifier || ''}-{import.meta.env.REACT_APP_ENV || ''}-incoming/incoming/{value || ''}
      </Text>
    </Box>
  );
};

export default React.memo(FileLocationTitle);
