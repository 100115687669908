import { useEffect, useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { OptionKey } from '@/common/types';

import { ClaimsStatus } from '../components/Claims/types/claims.constants';
import { EmployerStatus } from '../employer.constants';

const useListPagination = (defaultPerPage?: number) => {
  const {
    page, setPage, setPerPage, perPage, searchString, setSearchString, currentSearch,
  } = usePaginationParams(defaultPerPage);

  const [filterParam, setFilterParam] = useState<OptionKey>(EmployerStatus.Null);
  const [filterParams, setFilterParams] = useState<OptionKey[]>([EmployerStatus.Null]);
  const [statuses, setStatuses] = useState<OptionKey>(ClaimsStatus.Processing);
  const [categories, setCategories] = useState<OptionKey>(ALL_OPTION.value);

  useEffect(() => {
    setPage(1);
  }, [filterParam, setPage, currentSearch, statuses, categories]);
  return {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    filterParam,
    filterParams,
    statuses,
    categories,
    setSearchString,
    currentSearch,
    setFilterParam,
    setFilterParams,
    setStatuses,
    setCategories,
  };
};

export default useListPagination;
