import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { Pagination, PaginationParams } from '@/common/types';
import { EmployeeStatus } from '@/modules/employee/employee.constants';
import { Employee, StatusNames } from '@/modules/employee/employee.types';

export const QUERY_KEY = 'useEmployeesList';

const getFullStatusName = (firstLetter: keyof typeof StatusNames) => StatusNames[firstLetter];

export interface EmployeeDtoPayload {
  id: string;
  first_name?: string;
  last_name?: string;
  status?: string;
  last_4_SSN_digits?: string;
  employer?: string;
  employer_employee_id?: string;
  partner_employee_id?: string;
  is_reimbursement_allowed?: boolean;
  employer_name?: string;
  subsidiary_name?: string;
}

export interface UseEmployeesListParams extends PaginationParams {
  observingOrganizationId?: string;
  searchEmployerId?: string;
  searchSubsidiaryId?: string;
  observingOrganizationType?: OrganizationTypes;
  sortByField: string;
  options?: Partial<{
    enabled: boolean
  }>
}

const useEmployeesListQuery = ({
  page,
  perPage,
  searchString,
  status,
  options,
  sortBy,
  searchEmployerId,
  searchSubsidiaryId,
  observingOrganizationId,
  observingOrganizationType,
  sortByField,
}: UseEmployeesListParams) => {
  const sortByUpperCase = `${sortBy}`.toUpperCase();
  const {
    isLoading, data, refetch,
  } = useQuery({
    queryKey: [
      QUERY_KEY,
      page,
      perPage,
      status,
      searchString,
      sortBy,
      observingOrganizationId,
      searchEmployerId,
      searchSubsidiaryId,
      observingOrganizationType,
    ],
    enabled: false,
    queryFn: () => api.get<Pagination<EmployeeDtoPayload>>(PATHS.EMPLOYEES_SEARCH, {
      params: {
        ...status && status !== EmployeeStatus.Null ? { status: `${status}`[0].toUpperCase() } : {},
        page,
        size: perPage,
        sort: `${sortByField},${sortByUpperCase}`,
        ...searchString ? { search_value: searchString } : {},
        ...searchEmployerId || searchSubsidiaryId
          ? { organization_id: searchEmployerId || searchSubsidiaryId } : {},
      },
    }),
  });
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    perPage,
    status,
    searchString,
    sortBy,
    observingOrganizationId,
    searchEmployerId,
    searchSubsidiaryId,
    observingOrganizationType,
  ]);
  const formattedData = useMemo(() => data?.data.content?.map(
    (item: EmployeeDtoPayload): Employee => ({
      id: `${item.id}`,
      firstName: item.first_name || '',
      lastName: item.last_name || '',
      status: getFullStatusName(item.status as keyof typeof StatusNames),
      ssn: item.last_4_SSN_digits ? `***-**-${item.last_4_SSN_digits}` : '',
      last4Ssn: item.last_4_SSN_digits || '',
      employer: item.employer || '',
      employerEmployeeId: item.employer_employee_id || '',
      partnerEmployeeId: item.partner_employee_id || '',
      isReimbursementAllowed: item.is_reimbursement_allowed,
      employerName: item.employer_name || '',
      subsidiaryName: item.subsidiary_name || '',
    }),
  ), [data]);
  const total = useMemo<number>(() => _.get(data, 'data.totalElements', 0) || 0, [data]);

  useEffect(() => {
    if (options?.enabled) refetch();
  }, [options, refetch]);

  return ({
    data: formattedData || [],
    isLoading,
    total,
  });
};

export default useEmployeesListQuery;
