import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

import { CardDto } from '../../Cards/Cards.types';

interface Props {
  card: CardDto;
}

const EMPTY_DATE = '-';
export const CardDetailsTable: React.FC<Props> = ({ card }) => (
  <Box
    background="canvas"
    round="container1Round"
    direction="row"
    pad="spacing12"
    border={{ color: 'border2', size: 'small' }}
    elevation="default"
  >
    <Box flex="grow" direction="column" justify="between" pad="spacing12">
      <Box margin={{ bottom: 'spacing24' }} direction="row" justify="between">
        <Text color="textSecondary" size="small" weight={500}>Card Request Date:</Text>
        <Text weight="bold" data-testid="CardDetailsTable-cardRequestDate">
          {
            card.cardRequestDate
              ? dayjs(card.cardRequestDate).format(DEFAULT_DATE_FORMAT)
              : EMPTY_DATE
          }
        </Text>
      </Box>
      <Box direction="row" justify="between">
        <Text color="textSecondary" size="small" weight={500}>Mailed Date:</Text>
        <Text weight="bold" data-testid="CardDetailsTable-issuedDate">
          {
            card.mailedDate
              ? dayjs(card.mailedDate).format(DEFAULT_DATE_FORMAT)
              : EMPTY_DATE
          }
        </Text>
      </Box>
    </Box>
    <Box border={{ side: 'left' }} />
    <Box flex="grow" direction="column" justify="between" pad="spacing12">
      <Box margin={{ bottom: 'spacing24' }} direction="row" justify="between">
        <Text color="textSecondary" size="small" weight={500}>Activation Date:</Text>
        <Text weight="bold" data-testid="CardDetailsTable-activationDate">
          {
            card.activationDate
              ? dayjs(card.activationDate).format(DEFAULT_DATE_FORMAT)
              : EMPTY_DATE
          }
        </Text>
      </Box>
      <Box direction="row" justify="between">
        <Text color="textSecondary" size="small" weight={500}>Expiration Date:</Text>
        <Text weight="bold" data-testid="CardDetailsTable-expDate">
          {
            card.expDate
              ? dayjs(card.expDate).format(DEFAULT_DATE_FORMAT)
              : EMPTY_DATE
          }
        </Text>
      </Box>
    </Box>
  </Box>
);
