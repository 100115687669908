import React, { useEffect, useMemo, useState } from 'react';
import { Box, Inscription, TextWithPointer } from '@common-fe/common-fe';
import styled from 'styled-components';

import Tip from '@/components/elements/Tip/Tip';

const StyledList = styled(Box)`
  span:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacings.spacing4};
    &:after {
      content: ',';
    }
  }
`;

const countOverflowingTexts = (texts?: string[], symbolCount?: number) => {
  if (!texts) {
    return [];
  }
  const maxSymbolCount = symbolCount || 38;
  const filterArrayByCharacterLimit = (arr: string[], limit: number): string[] => {
    let totalLength = 0;
    let index = 0;
  
    while (index < arr.length && totalLength + arr[index].length <= limit) {
      totalLength += arr[index].length;
      index++;
    }

    if (index < arr.length && totalLength + arr[index].length > limit) {
      index++;
    }
  
    return arr.slice(0, index);
  };

  return filterArrayByCharacterLimit(texts,maxSymbolCount);
};

interface ClickableItem {
  title?: string;
  onClick: () => void;
}

interface Props {
  items: string[];
  clickableItems?: ClickableItem[];
  isAllMode?: boolean;
  allModeTitle?: string;
  showMoreLength?: number;
  color?: string;
  isWithSpacingRight?: boolean;
  itemsTextStyles?: React.CSSProperties;
  showMoreSymbolsLength?: number;
}

const ManyItemsTooltip: React.FC<Props> = ({
  items,
  clickableItems,
  isAllMode,
  allModeTitle = 'All types',
  showMoreLength,
  color,
  isWithSpacingRight,
  itemsTextStyles,
  showMoreSymbolsLength,
}) => {
  const [availableText, setAvailableText] = useState<string[]>([]);
  
  const currentShowMoreLength = useMemo(() => showMoreLength ||  availableText.length, [availableText, showMoreLength]);

  useEffect(() => {
    const showMoreCount = countOverflowingTexts(items, showMoreSymbolsLength);
    setAvailableText(showMoreCount);
  }, [items, showMoreSymbolsLength]);

  if (isAllMode) {
    return (
      <Box direction="row" align="center">
        <Inscription
          color="textTitle"
          {...itemsTextStyles ? { style: itemsTextStyles } : {}}
        >
          {`${allModeTitle} (${items.length})`}
        </Inscription>
        <Tip hasIcon items={items}></Tip>
      </Box>
    );
  }

  if (currentShowMoreLength && items.length > currentShowMoreLength) {
    return (
      <Box
        direction="row"
        align="center"
        pad={{ right: isWithSpacingRight ? 'spacing24' : undefined }}
      >
        {clickableItems?.length ? (
          <StyledList direction="row">{clickableItems.slice(0, currentShowMoreLength).map((clickableItem) => (
            <TextWithPointer
              key={clickableItem.title}
              onClick={clickableItem.onClick}
              color={color || 'textSecondary'}
              title={clickableItem.title}
              size="small"
              ellipsisMode
              weight={500}
              style={{ cursor: 'pointer', lineHeight: '18px', ...itemsTextStyles }}
            >
              {clickableItem.title}
            </TextWithPointer>
          ))}
          </StyledList>
        ) : (
          <TextWithPointer
            color={color || 'textSecondary'}
            title={items.slice(0, currentShowMoreLength).join(', ')}
            size="small"
            ellipsisMode
            weight={500}
            style={{ lineHeight: '18px', ...itemsTextStyles }}
          >
            {items.slice(0, currentShowMoreLength).join(', ')},
          </TextWithPointer>
        )}
        <Tip items={items.slice(currentShowMoreLength)}>
          <Inscription color="info" cursor="pointer">&nbsp;+{items.slice(currentShowMoreLength).length}</Inscription>
        </Tip>
      </Box>
    );
  }

  return (
    <Box pad={{ right: isWithSpacingRight ? 'spacing24' : undefined }}>
      {clickableItems?.length ? (
        <StyledList direction="row">{clickableItems.map((clickableItem) => (
          <TextWithPointer
            key={clickableItem.title}
            onClick={clickableItem.onClick}
            color={color || 'textSecondary'}
            title={clickableItem.title}
            size="small"
            ellipsisMode
            weight={500}
            style={{ cursor: 'pointer', lineHeight: '18px', ...itemsTextStyles }}
          >
            {clickableItem.title}
          </TextWithPointer>
        ))}
        </StyledList>
      ) : (
        <TextWithPointer
          color={color || 'textSecondary'}
          title={items.join(', ')}
          size="small"
          ellipsisMode
          weight={500}
          style={{ lineHeight: '18px', ...itemsTextStyles }}
        >
          {items.join(', ')}
        </TextWithPointer>
      )}
    </Box>
  );
};

export default ManyItemsTooltip;
