import React from 'react';

import ActivityLog from '@/modules/ActivityLog';
import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PLAN_MODULES } from '../../plan.constants';

const ActivityLogSection: React.FC = () => {
  return (
    <ModuleWrapper
      testId="activity-log"
      anchorId={PLAN_MODULES.ACTIVITY_LOG}
      title="Activity Log"
      expandless
      defaultExpandedValue={false}
    >
      <ActivityLog />
    </ModuleWrapper>
  );
};

export default React.memo(ActivityLogSection);
