import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

interface StyledButtonProps {
    isWarning?: boolean;
  }
  
export const StyledButton = styled(Box)<StyledButtonProps>`
  cursor: pointer;
  min-height: 32px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  ${({ theme }) => theme.fonts.text14Regular};
  padding-inline: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xxxs};
  color: ${({ theme, isWarning }) => (isWarning ? `${theme.colors.danger} !important` : '')};
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.danger : theme.colors.textAccent)};
    background-color: ${({ theme, isWarning }) => (isWarning ? theme.colors.statusContainerColor.deniedErrorExpiredContainer : theme.colors.accentContainer)};
    transition: all 0.2s ease-in-out;
  }
`;