import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import ROUTES, { EXPRESS_SETUPS } from '@/common/routes';
import useIsAllowToRedirect from '@/modules/core/hooks/useIsAllowToRedirect';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { useAuth } from '@/modules/user/hooks';
import { useAuthStore } from '@/modules/user/stores';
import { GLOBAL_ROUTES } from '@/pages';

const MINIFIED_QUERY_KEY = 'minified';
const ERROR_QUERY_KEY = 'error_description';

const useAppRedirect = () => {
  const [savedPath, setSavedPath] = useState<string>();

  useAuth();
  const { auth, inited, user, organizationId } = useAuthStore();

  const history = useHistory();

  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  const location = useLocation();

  const query = useMemo(() => new URLSearchParams(location?.search || ''), [location]);

  const pathname = useMemo(() => location.pathname, [location]);
  const routes = useMemo(() => {
    if (!auth && inited) {
      return GLOBAL_ROUTES.filter((item) => !item.authed);
    }
    return GLOBAL_ROUTES;
  }, [auth, inited]);

  // const error = location.hash.split('&').find((part) => (part.startsWith(ERROR_QUERY_KEY) || part.startsWith('access_token'))) || '';
  const errorQuery = useMemo(() => location.hash.includes(ERROR_QUERY_KEY), [location.hash]);
  const errorMode = useMemo(() => pathname === ROUTES.ERROR, [pathname]);

  useEffect(() => {
    if (!auth && pathname !== ROUTES.LOGIN) {
      setSavedPath(`${pathname}${location?.search || ''}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectByAuthCondition = useCallback(() => {
    if (errorQuery && pathname !== ROUTES.ERROR) {
      history.push(`${ROUTES.ERROR}`);
      return;
    }
    if (!auth && !routes.find((route) => route.path === pathname)) {
      history.push(ROUTES.LOGIN);
    }
    if (auth && pathname === ROUTES.LOGIN) {
      if (savedPath) {
        history.push(savedPath);
        setSavedPath(undefined);
      } else {
        history.push('/');
      }
    }
    if (auth && pathname === ROUTES.CHANGE_PASSWORD) {
      history.push('/');
    }
    if (auth && user?.status && user?.status === EmployerStatus.ExpressSetup
      && organizationId && !pathname.includes(EXPRESS_SETUPS)) {
      history.push(ROUTES.EXPRESS_SETUP_BY_ID(organizationId));
    }
  }, [auth, errorQuery, history, pathname, routes, savedPath, user, organizationId]);

  useEffect(() => {
    if (inited) redirectByAuthCondition();
  }, [redirectByAuthCondition, inited]);

  if (!useIsAllowToRedirect(pathname)) {
    goHome();
  }

  return {
    routes,
    minifiedQuery: query.get(MINIFIED_QUERY_KEY),
    errorMode,
  };
};

export default useAppRedirect;
