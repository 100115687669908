import React, { useCallback } from 'react';
import { AppButton, Box, ChevronRightIcon, Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { expressSetupDomainType } from '@/modules/ActivityLog/LogList/LogList.tsx';
import useGetActivityLogsQuery from '@/modules/ActivityLog/LogList/useGetActivityLogs.query';
import { useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import { ExpressSetup } from '../ExpressSetup.types';

interface Props {
  expressSetup?: ExpressSetup;
}

const ExpressSetupActivityLogsBlock: React.FC<Props> = ({
  expressSetup,
}) => {
  const history = useHistory();
  const { total } = useGetActivityLogsQuery({
    organizationId: expressSetup?.orgId,
    domainType: expressSetupDomainType,
  });

  const handleRedirect = useCallback(() => {
    history.push(ROUTES.EXPRESS_SETUPS_ACTIVITY_LOGS_BY_ID(expressSetup?.orgId));
  }, [history, expressSetup]);

  return (
    <Box
      direction="row"
      round="container1Round"
      background="border1"
      justify="between"
      align="center"
      pad={{ horizontal: 'l', vertical: 'm' }}
      margin={{ vertical: 'l' }}
    >
      <Text
        size="16px"
        color="textBody"
        weight={700}
        style={{ lineHeight: '22px' }}
      >
        Activity Log
      </Text>
      <AppButton
        onClick={handleRedirect}
        buttonType="secondary"
        width='auto'
        rightIcon={<ChevronRightIcon style={{ marginLeft: theme.spacings.xxs }}/>}
      >
        {`View Logs (${total})`}
      </AppButton>
    </Box>
  );
};

export default ExpressSetupActivityLogsBlock;
