import { OptionKey } from '@common-fe/common-fe';

import { GroupByFilter } from '@/modules/employer/components/Reports/report.types';
import { LAST_4_DIGIT_KEY } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

export type CardGroupByType = [
  GroupByFilter | null,
  GroupByFilter | null,
];

export const CARD_DATE_RANGE_DEFAULT_VALUE: [Date | null, Date | null] = [null, null];
export const CARD_GROUP_BY_DEFAULT_VALUE: CardGroupByType = [
  GroupByFilter.OrgField,
  GroupByFilter.Partner,
];

interface State {
  search: string;
  dateRange: [Date | null, Date | null];
  groupBy: CardGroupByType;
  SSNOption: OptionKey;
  handleSetSSNOption: (value: OptionKey) => void;

  handleSetSearch: (value: string) => void;
  handleSetDateRange: (dateRange: [Date | null, Date | null]) => void;
  handleSetGroupBy: (groupBy: CardGroupByType) => void;
  handleClear: () => void;
}

export const useCardReportFilterStore = storeCreator<State>((set) => ({
  search: '',
  SSNOption: LAST_4_DIGIT_KEY,
  dateRange: CARD_DATE_RANGE_DEFAULT_VALUE,
  groupBy: CARD_GROUP_BY_DEFAULT_VALUE,
  handleSetSSNOption: (value: OptionKey) => set(() => ({
    SSNOption: value,
  })),
  handleSetSearch: (value: string) => set(() => ({
    search: value,
  })),

  handleSetDateRange: (dateRange: [Date | null, Date | null]) => set(() => ({
    dateRange,
  })),

  handleSetGroupBy: (groupBy: CardGroupByType) => set(() => ({
    groupBy,
  })),

  handleClear: () => set(() => ({
    SSNOption: LAST_4_DIGIT_KEY,
    search: '',
    dateRange: CARD_DATE_RANGE_DEFAULT_VALUE,
    groupBy: CARD_GROUP_BY_DEFAULT_VALUE,
  })),
}));
