import React from 'react';
import { Box } from '@common-fe/common-fe';

import { useSaveTypeStore } from '../stores';

import { FileManagerSettings } from './FileManagerSettings';

interface Props {
  isPartnerMode?: boolean;
}
const FilesAndImports: React.FC<Props> = ({ isPartnerMode }) => {
  const {  activateSubmitted } = useSaveTypeStore();
  return (
    <Box>
      <FileManagerSettings 
        isPartnerMode={isPartnerMode}
        isSubmitted={activateSubmitted}
      />
    </Box>
  );
}; 

export default FilesAndImports;
