import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';

interface ResponseOption {
  id: number | string;
  name: string;
  description: string;
}

export const USE_HSA_CUSTODIAN_QUERY_KEY = 'useHsaCustodian';

export default () => {
  const {
    isLoading, error, data, isSuccess, isError, isFetching,
  } = useQuery(USE_HSA_CUSTODIAN_QUERY_KEY, () => api.get(`${PATHS.HSA_CUSTODIAN}`));

  const formatedData = useMemo<Option[]>(() => ([
    ...(data?.data || [])
      .map((item: ResponseOption) => ({
        key: `${item.id}`,
        value: item.description,
      })),
  ]), [data]);
  return useMemo(() => ({
    data: formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
    isFetching,
  }), [
    formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
    isFetching,
  ]);
};
