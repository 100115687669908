import { useEffect,useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { DefaultSortTypesEnum, OptionKey } from '@/common/types';
// import { InvoiceType } from '@/modules/employer/components/ReconciliationReports/components/FinOpsInvoicing/types/invoicing.constants';

const useListPagination = () => {
  const {
    page, setPage, setPerPage,
    perPage, searchString, setSearchString,
    currentSearch,
  } = usePaginationParams();

  const [statuses, setStatuses] = useState<OptionKey[]>([]);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([]);
  const [types, setTypes] = useState<OptionKey[]>([ALL_OPTION.key]);
  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.DESC);

  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, dateRange, statuses]);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    statuses,
    setStatuses,
    dateRange,
    setDateRange,
    types,
    setTypes,
    sortBy,
    setSortBy,
  };
};

export default useListPagination;
