import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Box,
  Text,
} from '@common-fe/common-fe';

import { ExpressSetupWizardStepTitle, ExpressSetupWizardStepType } from '../../ExpressSetup.types';
import { steps } from '../../ExpressSetupWizard/ExpressSetupWizard.tsx';
import { NavButton } from '../../styles/ExpressSetupWizard.styles';

interface Props {
  isNextButton?: boolean;
  searchQuery: string;
  currentStep: ExpressSetupWizardStepType;
  isDisabled?: boolean;
}

const NavigationButton: React.FC<Props> = ({
  isNextButton, searchQuery, currentStep, isDisabled,
}) => (
  <NavButton isDisabled={isDisabled}>
    <HashLink
      to={`${searchQuery || ''}#${isNextButton ? steps[steps.indexOf(currentStep) + 1] : steps[steps.indexOf(currentStep) - 1]}`}
      style={{
        textDecoration: 'none',
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
    >
      <Box justify="center">
        <Text style={{ lineHeight: '20px' }} textAlign={isNextButton ? 'end' : 'start'} color={isDisabled ? 'textDisabled' : 'textBody'}>{isNextButton ? 'Next ->' : '<- Back'}</Text>
        <Text style={{ lineHeight: '20px' }} textAlign={isNextButton ? 'end' : 'start'} color={isDisabled ? 'textDisabled' : 'textAccent'} weight="bold">{isNextButton ? ExpressSetupWizardStepTitle[steps[steps.indexOf(currentStep) + 1]] : ExpressSetupWizardStepTitle[steps[steps.indexOf(currentStep) - 1]]}</Text>
      </Box>
    </HashLink>
  </NavButton>
);

export default NavigationButton;
