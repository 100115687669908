import React from 'react';
import {
  Box,
  CellIcon,
  MailMessageIcon,
  NetworkIcon,
} from '@common-fe/common-fe';

import { HorizontalLine, IconWrapper, StyledAnchor } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/PreviewSection/PreviewSection.styles';
import useLinkManagementStore from '@/modules/employer/components/SetupEmployer/LinkManagement/useLinkManagementStore.store';

const EmailTemplateFooter = () => {
  const { state } = useLinkManagementStore();

  return (
    <Box pad={{ vertical: 'spacing24', horizontal: '40px' }} data-testId="email-template-footer">
      <Box
        margin={{ bottom: 'spacing24' }}
        direction="row"
        justify="center"
        gap="spacing24"
      >
        <IconWrapper>
          <NetworkIcon color="iconPrimary" />
        </IconWrapper>
        <IconWrapper>
          <MailMessageIcon color="iconPrimary" />
        </IconWrapper>
        <IconWrapper>
          <CellIcon color="iconPrimary" />
        </IconWrapper>
      </Box>

      <HorizontalLine />

      <Box direction="row" margin={{ top: 'spacing24' }}>
        <Box basis="1/2" align="center">
          <StyledAnchor
            href={state?.termAndConditionsLink}
            size="small"
            color="textSecondary"
            target="_blank"
          >
            Terms and Conditions
          </StyledAnchor>
        </Box>
        <Box basis="1/2" align="center">
          <StyledAnchor
            href={state?.privacyPolicyLink}
            size="small"
            color="textSecondary"
            target="_blank"
          >
            Privacy Policy
          </StyledAnchor>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailTemplateFooter;
