import { useEffect, useMemo, useState } from 'react';

import { ErrorResponse } from '@/api/api';

export const useIsServerError = (error: ErrorResponse | undefined | unknown) => {
  const currentError = error as ErrorResponse | undefined;
  const [isServerError, setIsServerError] = useState(false);
  const statusCode = useMemo(() => currentError?.response?.status, [currentError]);

  useEffect(() => {
    setIsServerError(statusCode ? statusCode >= 500 : false);
  }, [statusCode, currentError]);
  return {
    isServerError,
    setIsServerError,
  };
};
