import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';
import _ from 'lodash';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

// import { useOnlyForDev } from '@/utils';
import { useBasicPropertiesFields, useBasicPropertiesState } from '../../BasicProperties/hooks';
import { HEALTH_PLAN_SETUP_FIELD } from '../../HealthPlanSetup.constants';

import useCoverageLevelsValidate from './useCoverageLevelsValidate';

const VALIDATION_FIELDS = [
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TYPE,
  HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE,
];

const TEMPLATE_VALIDATION_FIELDS = [
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
  HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TYPE,
];
const IGNORE_FIELDS = [
  HEALTH_PLAN_SETUP_FIELD.CARRIER_NAME,
  HEALTH_PLAN_SETUP_FIELD.CARRIER_ID,
  HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT,
  HEALTH_PLAN_SETUP_FIELD.PLAN_CODE,
];

const VALIDATION_FIELDS_MAP = {};

const getFormatedString = (value: string) => _.capitalize(_.startCase(value));

export const useHealthPlanSaveValidate = (isTemplateMode?: boolean) => {
  // const isOnlyForDev = useOnlyForDev();

  const { getValidateFields, getErrors } = useValidationUtils();

  const basicPropertiesState = useBasicPropertiesState();
  const basicPropertiesFields = useBasicPropertiesFields(false, isTemplateMode);

  const coveragesErrors = useCoverageLevelsValidate({
    isTemplateMode,
    isActiveMode: false,
  });

  const basicPropertiesErrors: FieldError = useMemo(
    () => getErrors(basicPropertiesState, getValidateFields(basicPropertiesFields)),
    [getErrors, getValidateFields, basicPropertiesFields, basicPropertiesState],
  );

  const filteredErrors = useMemo(() => {
    const allErrors: FieldError = {
      ...basicPropertiesErrors,
      ...coveragesErrors,
    };

    const filtered: FieldError = {};
    _.forEach(allErrors, (item, key) => {
      if (IGNORE_FIELDS.includes(key)) {
        return;
      }
      const newKey = _.get(VALIDATION_FIELDS_MAP, `[${key}]`) || key;
      filtered[newKey] = item;
    });

    return filtered;
  }, [basicPropertiesErrors, coveragesErrors]);

  const errorsKeys = useMemo(
    () => _.map(filteredErrors, (item, key) => key), [filteredErrors],
  );

  const successes = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    return _.difference(messages, errorsKeys);
  }, [errorsKeys, isTemplateMode]);
  const errorMessages = useMemo(() => {
    const list = errorsKeys.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });

    return list;
  }, [errorsKeys, isTemplateMode]);

  const successMessages = useMemo(() => {
    const list = successes.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });

    return list;
  }, [isTemplateMode, successes]);

  const pendingMessages = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    const list = messages.map((item) => {
      if (item === HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME && isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE_NAME);
      }
      if (item === HEALTH_PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });
    return list;
  }, [isTemplateMode]);

  return {
    pendingMessages,
    errorMessages,
    successMessages,
  };
};
