import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface ReplacementCardData {
  reason: string,
  // address_line1: string,
  // address_line2: string,
  // city: string,
  // state: string,
  // zip: string,
}

interface ReplacementRequestCardData {
  cardID: string,
  data: ReplacementCardData;
}

export default () => {
  const { mutateAsync, isLoading } = useMutation(
    (value: ReplacementRequestCardData) => api.post(
      PATHS.REPLACEMENT_CARD_REQUEST(value.cardID),
      value.data,
    ),
  );

  const handleSave = async (data: ReplacementRequestCardData) => {
    await mutateAsync(data);
  };

  return {
    onSave: handleSave,
    isLoading,
  };
};
