import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { BlockWrapper } from '@/components/wrappers';
import LogChangesList from '@/modules/ActivityLog/LogList/LogChangesList/LogChangesList';
import useGetActivityLogChangesetsQuery from '@/modules/ActivityLog/LogList/LogItem/Changeset/useGetActivityLogChangesets.query';
import useGetActivityLogsQuery, { Actions, ActivityLog } from '@/modules/ActivityLog/LogList/useGetActivityLogs.query';
import LockboxInfoContainer from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoContainer';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

const lockboxLogFields = new Set([
  'lockboxType',
  'lockboxStatus',
  'reason',
  'employeeName',
  'liquidateAllFunds',
  'notes',
  'checkDetails/vendorInfo.vendorName',
  'checkDetails.benefitOf',
  'checkDetails/vendorInfo.addressLine1',
  'checkDetails/vendorInfo.addressLine2',
  'checkDetails/vendorInfo.city',
  'checkDetails/vendorInfo.state',
  'checkDetails/vendorInfo.zip',
  'amount',
  'checkDetails/vendorInfo.checkName',
  'employeeId',
]);

const sortLogs = (logs?: ActivityLog[]) => logs?.sort((prev, next) => new Date(next?.changedAt).getTime() - new Date(prev?.changedAt).getTime());

const LockboxLogChanges = () => {
  const { originalLockbox } = useLockboxDetailsStore();

  const { logs, logsIds, total, isFetching } = useGetActivityLogsQuery({
    lockboxId: originalLockbox?.id,
    page: 1,
    size: 100,
    actions: [Actions.update],
  });

  const {
    formattedData,
    isLoading: areChangesetsLoading,
  } = useGetActivityLogChangesetsQuery(logsIds);

  const sortedLogs = useMemo(() => sortLogs(logs), [logs]);

  const preparedChangesets = useMemo(() => formattedData
    ?.filter((changeLog) => changeLog?.fieldName && lockboxLogFields.has(changeLog?.fieldName)), [formattedData]);

  if (isFetching || !total || areChangesetsLoading) return null;

  return (
    <LockboxInfoContainer
      margin={{ top: 'spacing8'}}
      pad={{ horizontal: 'spacing24', vertical: 'spacing12' }}
      data-testId="lockbox-log-changes_wrapper"
    >
      <BlockWrapper
        title={(
          <Box>
            <Inscription
              weight="normal"
              color="textSecondary"
              size="14px"
              lineHeight="20px"
            >
              {`Log changes (${total})`}
            </Inscription>
          </Box>
        )}
        iconColor="textSecondary"
        defaultExpandedValue={false}
        isPureChild
      >
        <Box
          data-testId="lockbox-log-changes_list"
          margin={{ top: 'spacing8' }}
        >
          <LogChangesList logs={sortedLogs} changesets={preparedChangesets} />
        </Box>
      </BlockWrapper>
    </LockboxInfoContainer>
  );
};

export default LockboxLogChanges;
