 
import React, { useCallback, useMemo, useState } from 'react';
import {
  Box, ControlWrapper, Image, Preloader, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ACCEPTED_EMAIL_LOGO_FILE_TYPES, MAX_LOGO_FILE_SIZE, REQUIRED_TEXT } from '@/common/constants';
// import { FileUploadButton } from '@/employer/components/SetupEmployer/Branding/FileUploadButton';
import { FileUploadButton } from '@/modules/core/components/FileUploadButton';

import useUploadEmailLogoQuery from '../queries/useUploadEmailLogo.query';

interface Props {
  value: string;
  disabled?: boolean;
  showErrors?: boolean;
  onChangeValue: (value: string) => void;
}
const SIZE_ERROR_TEXT = 'Logo dimensions should be less or equal';

const SIZE_ERROR_MESSAGE = 'Logo dimensions should be less or equal to 300x50 pixels';
const FILE_SIZE_ERROR_MESSAGE = 'Logotype should be equal or less than 4Mb';

const CustomLogoUploader: React.FC<Props> = ({
  value, onChangeValue, showErrors, disabled,
}) => {
  const { handleSave, isLoading } = useUploadEmailLogoQuery();
  const [link, setLink] = useState(value || '');
  const [error, setError] = useState('');

  const handleUploadFile = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    // @ts-ignore
    const currentFile = event.target.files[0];
    const isAcceptanceSize = currentFile.size <= MAX_LOGO_FILE_SIZE;
    if (!isAcceptanceSize) {
      setError(FILE_SIZE_ERROR_MESSAGE);
      return;
    }
    try {
      const { data: { logo_url: logoUrl } } = await handleSave(currentFile);
      if (logoUrl) {
        setLink(logoUrl || '');
        onChangeValue(logoUrl);
      }
    } catch (e) {
      const message = _.get(e, 'response.data.elevate_error_message', '') as string;

      if (message.includes(SIZE_ERROR_TEXT)) {
        setError(SIZE_ERROR_MESSAGE);
      }
    }
  }, [handleSave, onChangeValue]);
  const currentError = useMemo(() => {
    if (showErrors && !link && !error) {
      return REQUIRED_TEXT;
    }
    return error;
  }, [error, link, showErrors]);
  return (
    <ControlWrapper errorText={currentError}>
      <Box
        data-testid="custom-email-logo-uploader"
        direction="row"
        align="center"
        margin={{ top: 's' }}
        style={{ flexShrink: 0 }}
      >
        <Box
          direction="row"
          width="200px"
          background="module"
          round="container2Round"
          justify="center"
          height="72px"
          align="center"
          pad={link ? 'xs' : 'm'}
          margin={{ right: 's' }}
        >
          {isLoading ? (
            <Preloader />
          ) : (

            link ? (
              <Image
                fit="contain"
                width={170}
                style={{ maxHeight: '70px', display: 'block' }}
                alt="logo"
                src={link}
              />
            ) : (
              <Box
                justify="center"
                width="100%"
                height="40px"
                background="border1"
                align="center"
              >
                <Text weight={400} size="medium" color="textDisabled">
                  Your logo
                </Text>
              </Box>
            )

          )}

        </Box>
        <Box width={link ? '155px' : '100px'}>
          <FileUploadButton
            id="upload"
            disabled={disabled}
            testId="email-logo"
            format={ACCEPTED_EMAIL_LOGO_FILE_TYPES}
            onChange={handleUploadFile}
            title={link ? 'Upload New Logo' : 'Upload'}
          />
        </Box>

      </Box>
    </ControlWrapper>
  );
};
export default CustomLogoUploader;
