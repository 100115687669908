import { useCallback, useEffect, useState } from 'react';
import { Field } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { VALIDATORS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import {
  DeductibleType,
  MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
  PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE,
} from '@/modules/deductibles/deductibles.types';
import { ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import { MainInfo } from '../CreateProcessingRule.types';
import useCreateProcessingRuleQuery from '../queries/useCreateProcessingRule.query';

import useProcessingRuleMainFields from './useProcessingRuleMainFields';
import useTierFields, {
  LAST_ITEM_VALIDATOR, NA_KEY, TRIGGERS_KEY,
} from './useTierFields';
import { TierField } from './useTiers';

export interface Params {
  mainValue: MainInfo;
  tiers: TierField[];
  visible?: boolean;
  onClose?: (value: boolean) => void;
  updatedElement?: ProcessingRuleItem;
}
const NAME_ERROR = 'Details: ProcessingRule with name';
export default ({
  mainValue, tiers, onClose, visible, updatedElement,
}: Params) => {
  const {
    observingOrganization,
  } = useCurrentOrganization();
  const [isProcessingRuleNameError, setProcessingRuleError] = useState(false);
  const [isServerError, setServerError] = useState(false);
  const { mutateAsync: handleSave, isLoading } = useCreateProcessingRuleQuery(updatedElement);
  const [isSubmitted, setSubmited] = useState(false);
  
  const mainFields = useProcessingRuleMainFields();
 
  useEffect(() => {
    if (!visible) {
      setSubmited(false);
    }
  }, [visible]);
  const tierFields = useTierFields({});
  const handleCheckValidity = useCallback(async () => {
    const mainShape = mainFields.reduce((result, item) => ({
      ...result,
      [item.name]: item.validator,
    }), {}) as ObjectShape;
    const mainShcema = yup.object({}).shape(mainShape);
    try {
     
    
      await mainShcema.validate(mainValue);
      await Promise.all(tiers.map(async (item) => {
        const allTierFields: Field[] = [];
        const getTierFieldValidators = (currentFields: Field[]) => {
          currentFields.forEach((field) => {
            allTierFields.push(field);
          
            if(field.subFields) {
              const fieldValue = _.get(item, field.name, false);
              if(fieldValue) {
                getTierFieldValidators(field.subFields); 
              }
              
            }
          });
        };
        getTierFieldValidators(tierFields);
      

     
        const tiersShape = allTierFields.reduce((result, currentField) => {
          if (currentField.name === TRIGGERS_KEY) {
            return ({
              ...result,
              [currentField.name]: _.last(tiers) === item
                ? LAST_ITEM_VALIDATOR : VALIDATORS.TRIGGER_NOT_LAST_ITEM(tiers),
            });
          }
          return ({
            ...result,
            [currentField.name]: currentField.validator,
          });
        }, {}) as ObjectShape;

        const tiersSchema = yup.object({}).shape(tiersShape);
        await tiersSchema.validate(item);
      }, {}));
      return true;
    } catch (e) {
      return false;
    }
  }, [mainFields, mainValue, tierFields, tiers]);

  const handleSubmit = useCallback(async () => {
    setSubmited(true);
    const isValid = await handleCheckValidity();
    if (isValid) {
      try {
        await handleSave({
          name: mainValue.name,
          organization_id: observingOrganization.id,
          tiers: tiers.map((item) => {
            let triggers: string[] | undefined;
            if (item?.triggers?.includes(NA_KEY)) {
              triggers = undefined;
            } else if (item?.triggers?.length
                && item.triggers[0] === MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
              triggers = [DeductibleType.Member, DeductibleType.Embedded];
            } else if (item?.triggers?.length
              && item.triggers[0] === PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE) {
              triggers = [DeductibleType.Plan, DeductibleType.Embedded];
            } else {
              // eslint-disable-next-line no-unsafe-optional-chaining
              triggers = [...item?.triggers];
            }
            return {
              name: item.name,
              payout_definition_id: item.payoutDefinition,
              triggers,
              is_send_trigger_notification: item.isSendTriggerNotification,
              ...item.nameToDisplay ? { display_name: item.nameToDisplay } : {},
              ...item.planId ? { plan_id: item.planId } : {},
            };
          }),
        });
        if (onClose) onClose(false);
      } catch (e) {
        const message = _.get(e, 'response.data.elevate_error_message', '') as string;
        if (message.includes(NAME_ERROR)) {
          setProcessingRuleError(true);
        }
        if (e) setServerError(true);
      }
    }
  }, [handleCheckValidity, handleSave, mainValue.name,
    observingOrganization.id, onClose, tiers]);

  return {
    isProcessingRuleNameError,
    isServerError,
    isSubmitted,
    isLoading,
    handleSubmit,
    setServerError,
  };
};
