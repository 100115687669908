import React from 'react';
import { AbsenceFilesPlaceholderIcon, Box, Text } from '@common-fe/common-fe';

interface Props {
  children: React.ReactNode;
  testId?: string;
}
const ListPlaceholder: React.FC<Props> = ({ children, testId }) => (
  <Box data-testid={`${testId}_placeholder`} direction="column" align="center" margin={{ top: 'l' }}>
    <AbsenceFilesPlaceholderIcon size="72px" color="iconSecondary" />
    <Text margin={{ top: 'xs' }} size="medium" weight={700}>{children}</Text>
  </Box>
);
export default ListPlaceholder;
