import { useMemo } from 'react';
import { Field,FieldTypes, validateTextInputLength } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { useStatesQuery } from '@/modules/core/hooks';
import { AddressPayload } from  '@/modules/ExpressSetup/EditAddressModal/stores/useAddress.store.ts';

const FIELDS = {
  STREET: 'street',
  STREET_2: 'street_2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
};

interface Props {
  defaultFields?: AddressPayload;
}
export default ({ defaultFields }: Props) => {
  const { states } = useStatesQuery();
  const fields: Field[] = useMemo(() => {
    const source: Field[] = [
      {
        name: FIELDS.STREET,
        type: FieldTypes.Text,
        label: 'Address line 1',
        placeholder: 'Enter Address line 1',
        validator: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_TEXT),
        defaultValue: '', // generalInfo?.street,
        showRequireIcon: true,
      },
      {
        name: FIELDS.STREET_2,
        type: FieldTypes.Text,
        label: 'Address line 2',
        placeholder: 'Enter Address line 2',
        validator: yup.string().trim().test(validateTextInputLength()),
        defaultValue: '', // generalInfo?.street,
      },
      {
        name: FIELDS.CITY,
        type: FieldTypes.Text,
        label: 'City',
        placeholder: 'Enter City',
        validator: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_TEXT),
        defaultValue: '', // generalInfo?.city,
        showRequireIcon: true,
      },
      {
        name: FIELDS.STATE,
        type: FieldTypes.AutocompleteDropdown,
        label: 'State',
        showRequireIcon: true,
        placeholder: 'Select state',
        validator: yup.string().required(REQUIRED_TEXT),
        options: states.map((state) => ({ key: state.code, title: state.name })),
      },
      {
        name: FIELDS.ZIP_CODE,
        type: FieldTypes.ZipCode,
        label: 'ZIP code',
        placeholder: 'Enter ZIP code',
        showRequireIcon: true,
        validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
          (val) => (val && (val.length === 10 || val.length === 5))
            || (val === '')).required(REQUIRED_TEXT),
        defaultValue: '', // generalInfo?.zipCode,
      },
    ];
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return source.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      }));
    }
    return source;
  }, [defaultFields, states]);

  return fields;
};
