import React, { useMemo } from 'react';
import {
  Box, capitalizeFirstLetter, Field, FieldTypes, RadioOption,
  Text, } from '@common-fe/common-fe';
import * as yup from 'yup';

import { Roles } from '@/common/constants';

import { ContactResponseModel } from '../ContactSecurity.types';

interface Args {
  data: Partial<ContactResponseModel>;
  firstContact?: boolean;
  hasPrimary: boolean;
  onlyOnePrimaryContact: boolean;
  onOpenPermissionTable(): void;
  viewMode?: boolean;
  errors?: {
    [key: string]: string
  }
}

const USER_NAME_REQUIRED = 'User Name is a required field';
const EMAIL_REQUIRED = 'Email is a required field';
const EMAIL_VALID = 'Email must be valid.';
const PHONE_REQUIRED = 'Phone number is required';
const useContactSecurityForm = (args: Args, permissionsList: RadioOption[]) => {
  const { errors = {} } = args;
  const temporaryPasswordField: Field[] = useMemo(() => (!args.viewMode
    ? ([{
      name: 'temporaryPassword',
      type: FieldTypes.Info,
      label: 'Temporary password',
      value: 'After adding a user, a temporary password will be generated automatically.',
    }]) : []), [args.viewMode]);
  const userNameValidation = useMemo(() => {
    if (errors.username) {
      return yup.string().test({
        test: (val) => val !== errors.username,
        message: 'Username format is invalid',
      }).required(USER_NAME_REQUIRED);
    }
    if (errors.usernameExists) {
      return yup.string().test({
        test: (val) => val !== errors.usernameExists,
        message: 'Username format is already used',
      }).required(USER_NAME_REQUIRED);
    }
    return yup.string().required(USER_NAME_REQUIRED);
  }, [errors]);
  const fields = useMemo((): Field[] => [
    {
      name: 'firstName',
      type: FieldTypes.Text,
      label: 'First name',
      showRequireIcon: true,
      placeholder: 'First name',
      validator: yup.string().required('First Name is a required field'),
      defaultValue: args.data.first_name || '',
    },
    {
      name: 'lastName',
      type: FieldTypes.Text,
      label: 'Last name',
      showRequireIcon: true,
      placeholder: 'Last name',
      validator: yup.string().required('Last Name is a required field'),
      defaultValue: args.data.last_name || '',
    },
    {
      name: 'email',
      type: FieldTypes.Text,
      label: 'Email',
      showRequireIcon: true,
      placeholder: 'Email',
      validator:
        errors.email ? yup.string().test({
          test: (val) => val !== errors.email,
          message: 'The email is invalid',
        }).required(EMAIL_REQUIRED)
          .email(EMAIL_VALID)
          : yup.string()
            .required(EMAIL_REQUIRED)
            .email(EMAIL_VALID),
      defaultValue: args.data.email || '',
    },
    {
      name: 'userName',
      type: FieldTypes.Text,
      label: !args.viewMode ? (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Username</Text>
          <Box>
            {!args.data.external_identifier
                && (
                  <Text
                    weight="normal"
                    style={{ width: '244px' }}
                    color="textSecondary"
                  >
                    Username field can be set up only once and cannot be updated later.
                  </Text>
                )}
          </Box>
        </Box>
      ) : 'Username',
      showRequireIcon: true,
      placeholder: 'Username',
      validator: userNameValidation,
      defaultValue: args.data.username || '',
      disabled: !!args.data.id,
      isLowerCaseMode: true,
    },
    {
      name: 'externalIdentifier',
      type: FieldTypes.Text,
      label: 'Unique ID',
      placeholder: 'Unique ID',
      validator:

        errors.uniqueId
          ? yup.string().test({
            test: (val) => val !== errors.uniqueId,
            message: 'Unique ID already exists in the system',
          }).nullable()
          : yup.string().nullable(),

      // .required('Unique ID is a required field')
      // .test('len', 'Must be exactly numbers', (val) => Boolean(val && REGEXPS.ONLY_NUMBERS.test(val))),
      defaultValue: args.data.external_identifier || '',
      // disabled: !!args.data.id,
    },
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      placeholder: '+1 (999) 999-9999',
      validator:
        errors.phone
          ? yup.string().test({
            test: (val) => val !== errors.phone,
            message: 'Phone number is invalid',
          }).required(PHONE_REQUIRED)
          : yup.string(),

      defaultValue: args.data.phone || '',
    },
    {
      name: 'title',
      type: FieldTypes.Text,
      label: 'Title/role',
      placeholder: 'Title/role',
      defaultValue: args.data.title || '',
    },
    {
      name: 'department',
      type: FieldTypes.Text,
      label: 'Department',
      placeholder: 'Department',
      defaultValue: args.data.department || '',
    },
    ...temporaryPasswordField,
    {
      name: 'primary',
      type: FieldTypes.Checkbox,
      label: 'Set as primary contact',
      placeholder: 'Doing Business As',
      validator: yup.boolean().required('Primary is a required field'),
      defaultValue: args.firstContact ? true : (args.data.primary || false),
      disabled: args.firstContact || Boolean(args.onlyOnePrimaryContact && args.data.primary),
    },
    (permissionsList.length > 7) ? {
      name: 'permissions',
      type: FieldTypes.Dropdown,
      isPositionSensitive: true,
      singleMode: true,
      options: permissionsList.map((item) => ({
        key: item.value,
        value: item.value
          ? capitalizeFirstLetter(item.value.toString().split('_').join(' ').toLowerCase())
          : item.value,
      })),
      label: (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Permissions</Text>
        </Box>
      ),
      showRequireIcon: true,
      placeholder: 'Select Permission',
      validator: yup.mixed<Roles>().oneOf(Object.values(Roles), 'Permissions is a required field').required('Permissions is a required field'),
      defaultValue: args.data.role || '',
      // ? Roles.elevateSuperUser
      // : (args.data.role || Roles.elevateSuperUser),
      // disabled: args.firstContact || args.data.primary,
    } : {
      name: 'permissions',
      type: FieldTypes.Radio,
      options: permissionsList,
      label: (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Permissions</Text>
        </Box>
      ),
      placeholder: 'Select Permission',
      validator: yup.mixed<Roles>().required('Permissions is a required field'),
      defaultValue: args.data.role || '',
      // ? Roles.elevateSuperUser
      // : (args.data.role || Roles.elevateSuperUser),
      // disabled: args.firstContact || args.data.primary,
    },
  ], [args, errors, permissionsList, userNameValidation, temporaryPasswordField]);
  return fields;
};

export default useContactSecurityForm;
