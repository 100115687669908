import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import { GracePeriodPayload,useStore as useGracePeriodStore } from '../GracePeriod/stores/useGracePeriod.store';
import { PayClaimsPayload,useStore as usePayClaimsStore } from '../PayClaims/stores/usePayClaims.store';

export type CoveragePayload = GracePeriodPayload & PayClaimsPayload;
// & CardOfferedPayload
// & AdditionalPayload

export default () => {
  const setGracePeriodStore = useGracePeriodStore((state) => state.setState);
  const setPayClaimsStore = usePayClaimsStore((state) => state.setState);

  const setSourceGracePeriodStore = useGracePeriodStore((state) => state.setSourceState);
  const setSourcePayClaimsStore = usePayClaimsStore((state) => state.setSourceState);

  const showErrorsGracePeriodStore = useGracePeriodStore((state) => state.setShowErrors);
  const showErrorsPayClaimsStore = usePayClaimsStore((state) => state.setShowErrors);

  const handleResetGracePeriodStore = useGracePeriodStore((state) => state.handleReset);
  const handleResetPayClaimsStore = usePayClaimsStore((state) => state.handleReset);

  const setGracePeriodErrors = useGracePeriodStore((state) => state.setErrors);
  const setPayClaimsErrors = usePayClaimsStore((state) => state.setErrors);

  const setGracePeriodErrorFields = useGracePeriodStore((state) => state.setErrorFields);
  const setPayClaimsErrorFields = usePayClaimsStore((state) => state.setErrorFields);
  const handleSetSourceState = useCallback((values: Partial<CoveragePayload>) => {
    setSourceGracePeriodStore(values);
    setSourcePayClaimsStore(values);
  }, [setSourceGracePeriodStore, setSourcePayClaimsStore]);

  const handleSetState = useCallback((values: Partial<CoveragePayload>) => {
    setGracePeriodStore(values);
    setPayClaimsStore(values);
    handleSetSourceState(values);
  }, [handleSetSourceState, setGracePeriodStore, setPayClaimsStore]);

  const handleReset = useCallback(() => {
    handleResetGracePeriodStore();
    handleResetPayClaimsStore();
  }, [handleResetGracePeriodStore, handleResetPayClaimsStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsGracePeriodStore(value);
    showErrorsPayClaimsStore(value);
  }, [showErrorsGracePeriodStore, showErrorsPayClaimsStore]);
  const handleSetErrors = useCallback((values: Partial<CoveragePayload>) => {
    setGracePeriodErrors(values);
    setPayClaimsErrors(values);
  }, [setGracePeriodErrors, setPayClaimsErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<CoveragePayload>>) => {
      setGracePeriodErrorFields(values);
      setPayClaimsErrorFields(values);
    }, [setGracePeriodErrorFields, setPayClaimsErrorFields],
  );
  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetSourceState,
    handleSetErrors,
    handleShowErrors,
  };
};
