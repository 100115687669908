import React, { useCallback, useEffect,useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import theme from '@/styles/theme';

import { BrandingPreview } from '../BradingIllustration';
import BrandingIllustrationLabel from '../BradingIllustration/BrandingIllustrationLabel';
import EmailConfigurationLabel from '../EmailConfigurationLabel';
import EmailLogoPicker from '../EmailLogoPicker/EmailLogoPicker';
import EmailLogoPickerLabel from '../EmailLogoPicker/EmailLogoPickerLabel';
import ThemeItem from '../LinksManagement/ThemeItem';
import { useEmailIllustrationsQuery, useEmailThemesQuery } from '../queries';
import { useEmailConfigurationStore, useEmailIllustrationsStore,useEmailThemeStore } from '../stores';

interface Params {
  disabled?: boolean;
  showErrors?: boolean;
  selectedThemeId?: string;
}
export default ({ disabled, showErrors, selectedThemeId }: Params) => {
  const {
    data: themesOptions,
    isLoading: isThemesLoading,
    formattedThemes,
  } = useEmailThemesQuery();
  const setEmailThemeState = useEmailThemeStore((state) => state.setState);
  const setEmailIllustrationsState = useEmailIllustrationsStore((state) => state.setState);
  const { data: illustrationOptions } = useEmailIllustrationsQuery();
  const sourceState = useEmailConfigurationStore((state) => state.sourceState);
  const emailLogoTypeState = useEmailConfigurationStore((state) => state.state.emailLogoType);

  const customLogo = useEmailConfigurationStore((state) => state.state.customEmailLogo);
  const brandingIllustration = useEmailConfigurationStore(
    (state) => state.state.brandingIllustration,
  );
  const setState = useEmailConfigurationStore((state) => state.setState);
  const brandingIllustrationPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EMAIL_ILLUSTRATION_ID,
  );
  const emailLogoTypePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EMAIL_LOGO_TYPE,
  );
  const currentBranding = useMemo(
    () => illustrationOptions.find(
      (illustration) => illustration.key === brandingIllustration,
    ), [brandingIllustration, illustrationOptions],
  );
  const emailThemePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EMAIL_THEME_ID,
  );
  const currentBrandingLink = currentBranding?.link;
  const handleSetEmailLogoType = useCallback((value: string) => {
    setState({
      emailLogoType: value,
    });
  }, [setState]);
  const handleSetCustomLogo = useCallback((value: string) => {
    setState({
      customEmailLogo: value,
    });
  }, [setState]);

  useEffect(() => {
    if (currentBrandingLink) {
      setEmailIllustrationsState({ brandingLogoLink: currentBrandingLink });
    }
  // eslint-disable-next-line
  }, [currentBrandingLink]);

  useEffect(() => {
    if (!isThemesLoading && selectedThemeId) {
      const selectedTheme = formattedThemes.find((emailTheme) => emailTheme.id === selectedThemeId);
      if (selectedTheme) setEmailThemeState(selectedTheme);
    }
  // eslint-disable-next-line
  }, [selectedThemeId, isThemesLoading]);

  const fields = [
    {
      name: 'emailLogoType',
      label: (
        <EmailLogoPickerLabel />
      ),
      type: FieldTypes.Node,
      defaultValue: sourceState?.emailLogoType,
      expandItems: true,
      fill: true,
      value: (
        <EmailLogoPicker
          showErrors={showErrors}
          value={emailLogoTypeState}
          disabled={disabled || emailLogoTypePermProps?.disabled}
          onChange={handleSetEmailLogoType}
          customLogo={customLogo || ''}
          onChangeCustomLogo={handleSetCustomLogo}
        />
      ),
      ...emailLogoTypePermProps,
    },
    {
      name: 'brandingIllustration',
      className: 'tomatoma',
      label: (
        <BrandingIllustrationLabel />
      ),
      type: FieldTypes.Dropdown,
      defaultValue: sourceState?.brandingIllustration,
      expandItems: true,
      fill: true,
      inputStyle: {
        maxWidth: '320px',
        position: 'relative',
      },
      helpText: (
        <BrandingPreview link={currentBranding?.link} />
      ),
      options: illustrationOptions || [],
      ...brandingIllustrationPermProps,
    },
    {
      name: 'themeLabel',
      type: FieldTypes.Node,
      fill: true,
      fieldWrapperStyles: {
        marginTop: '24px',
        borderTop: `1px solid ${theme.colors.border1}`,
      },
      value: (
        <EmailConfigurationLabel
          label="Email theme management"
          subLabel="Selected theme will be applied to all email templates."
        />
      ),
    },
    {
      name: 'emailTheme',
      label: 'Email theme',
      type: FieldTypes.Dropdown,
      disabled: isThemesLoading,
      defaultValue: sourceState?.emailTheme,
      options: themesOptions.map((item) => ({
        key: item.key,
        value: item.title,
        title: item.title,
        node: (
          <ThemeItem color={item.color}>
            {item.title}
          </ThemeItem>),
      })) || [],
      ...emailThemePermProps,
    },
  ];
  return fields as Field[];
};
