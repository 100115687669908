import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { StatusNames } from '@/modules/employee/employee.types';
import { MatchedEmployee, MatchedEmployeePayload } from '@/modules/employer/components/Lockbox/lockbox.types';

const getFullStatusName = (firstLetter: keyof typeof StatusNames) => StatusNames[firstLetter];

interface SearchEmployeesPayload {
  is_too_many?: boolean;
  matched_employees?: MatchedEmployeePayload[];
}

interface SearchEmployees {
  isTooMany?: boolean;
  matchedEmployees?: MatchedEmployee[];
}

interface Props {
  searchValue: string;
}

const useGetMatchedEmployeesQuery = ({ searchValue }: Props) => {
  const { data, isLoading } = useQuery(
    [PATHS.GET_MATCHED_EMPLOYEES, searchValue],
    () =>
      api.get<SearchEmployeesPayload>(PATHS.GET_MATCHED_EMPLOYEES, {
        params: { search_value: searchValue },
      }),
    {
      enabled: !!searchValue,
    }
  );

  const matchedEmployees: SearchEmployees = useMemo(() => ({
    isTooMany: data?.data?.is_too_many,
    matchedEmployees: data?.data?.matched_employees?.map((matchedEmployee) => ({
      employeeId: toString(matchedEmployee?.employee_id),
      employeeName: matchedEmployee?.employee_name,
      ssn: matchedEmployee?.ssn,
      birthDate: matchedEmployee?.birth_date,
      employmentStatus: getFullStatusName(matchedEmployee?.employment_status as keyof typeof StatusNames),
      organizationId: toString(matchedEmployee?.organization_id),
      organizationName: matchedEmployee?.organization_name,
      accountId: toString(matchedEmployee?.account_id),
      accountNumber: matchedEmployee?.account_number,
      availableAmount: matchedEmployee?.available_amount,
      accountStatus: matchedEmployee?.account_status,
      investmentStatus: matchedEmployee?.investment_status,
      labelTypes: matchedEmployee?.label_types,
    })),
  }), [data]);

  return {
    matchedEmployees: matchedEmployees?.matchedEmployees,
    isTooMany: matchedEmployees?.isTooMany,
    isLoading,
  };
};

export default useGetMatchedEmployeesQuery;
