import { Option } from '@common-fe/common-fe';

import { TaxType } from '../contributions.types';


export enum PayrollCalendarStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export enum ScheduledContributionFrequency {
  Weekly = 'WEEKLY', 
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Annually = 'ANNUALLY'
}
export enum ScheduledContributionStatus {
  Active = 'ACTIVE',
  Suspend = 'SUSPENDED',
  Deactivated = 'DEACTIVATED',
  Removed = 'REMOVED',
}
export interface CalendarPlanPayload {
  id: string;
  name: string;
  recipients: number;
  forecastedAmount: number;
}
export interface CalendarPayload {
  id: string;
  name: string;
  frequency: ScheduledContributionFrequency;
  scheduledTime: string;
  recipients: number;
  accounts: Option[];
  plans?: CalendarPlanPayload[];
}

export interface RecordAccountPayload {
  id: string;
  name: string;
  employerAmount: number;
  employeeAmount: number;
  taxType?: string;
}
export interface RecordPayload {
  id: string;
  name: string;
  accounts: RecordAccountPayload[];

}

export interface ScheduledContributionPayload {
  id: string;
  calendarId: string;
  name: string;
  frequency: ScheduledContributionFrequency;
  calendarStatus: PayrollCalendarStatus;
  startDate?: string | null;
  nextScheduledDate?: string | null;
  accounts: Option[];
  status:ScheduledContributionStatus ;
  taxType: TaxType;
}