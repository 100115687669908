import React from 'react';
import {
  AppButton,
  Box,
  Text,
  WarningIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

import { useStore } from '../store/useExceedsMaxLimitContributionModal.store';

const headerText = 'Number of employees exceeds the maximum limit';
const description = 'The total number of employees selected, exceeds the maximum limit.';
const description2 = 'To continue talk to your account administrator.';

export const ExceedsMaxLimitContributionModal = () => {
  const visible = useStore((store) => store.modalVisible);
  const onSetVisible = useStore((store) => store.handleChangeVisibility);
  return (
    <ModalWrapper testId="ExceedsMaxLimitContributionModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad="spacing24">
        <WarningIcon size="48px" />
        <Text
          data-testid="ExceedsMaxLimitContributionModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
          color="textBody"
        >
          {headerText}
        </Text>
        <Box pad={{ horizontal: 'spacing72' }}>
          <Text
            data-testid="ExceedsMaxLimitContributionModal_modal_help-text1"
            textAlign="center"
            color="textBody"
          >
            {description}
          </Text>
          <Text
            data-testid="ExceedsMaxLimitContributionModal_modal_help-text2"
            margin={{ bottom: 'spacing24' }}
            textAlign="center"
            color="textBody"
          >
            {description2}
          </Text>
        </Box>
        <AppButton
          testId="ExceedsMaxLimitContributionModal_modal"
          onClick={() => onSetVisible(false)}
          width="200px"
        >
          Got It!
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
