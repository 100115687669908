import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import Body from './Body';
import Head, { Props } from './Head';

const Changeset: React.FC<Props> = (props) => (
  <Box pad="spacing24" background="module" round="small">
    <Box>
      <Head {...props} />

      <Box margin={{ top: 'spacing12' }}>
        <Box
          height="32px"
          background="legend"
          round="8px"
          pad={{ vertical: '7px', horizontal: 'spacing12' }}
          direction="row"
          align="center"
        >
          <Box width="26%"><Inscription size="12px" weight="bold">Attribute</Inscription></Box>
          <Box width="37%" pad={{ left: 'spacing24' }}><Inscription size="12px" weight="bold">Previous</Inscription></Box>
          <Box width="37%" pad={{ left: '36px' }}><Inscription size="12px" weight="bold">Updated</Inscription></Box>
        </Box>
      </Box>

      <Body id={props.id} />
    </Box>
  </Box>
);

export default Changeset;
