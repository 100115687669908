 
import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api, mock } from '@/api';
import { PATHS } from '@/common';

import {
  BankTypeEnum,
  CardDto,
  CardHolderDto,
  CardHoldersResponse,
  CardResponse,
  CardStatusEnum,
  ChangeCardStatusMutationArgs,
} from '../Cards.types';

enum CardHolderType {
  PRIMARY = 'PRIMARY',
}

export const HOLDER_MOCK_LIST: CardHolderDto[] = [
  {
    name: 'Me (Linda Smith)',
    dependentId: '1',
    firstName: 'Me (Linda',
    lastName: 'Smith)',
    email: 'linda1smith@gmail.com',
    dateOfBirth: dayjs('10/10/1980').toISOString(),
    relationship: 'SPOUSE',
  },
  {
    name: 'Brian Smith',
    dependentId: '2',
    firstName: 'Brian',
    lastName: 'Smith',
    email: 'brian1smith@gmail.com',
    dateOfBirth: dayjs('01/10/1990').toISOString(),
    relationship: 'CHILD',
  },
];

export interface CardsMap {
  [holderName: string]: {
    [cardStatus in CardStatusEnum]: CardDto[]
  }
}

const GET_CARDS_QUERY_KEY = 'getCardsQueryKey';
const GET_CARDS_QUERY_PARAMS_KEYS = {
  EMPLOYEE_ID: 'employee_id',
  DEPENDENT_ID: 'dependent_id',
};
export const ROOT_DEPENDENT_ID = 'none';

const MOCKED_LINDAS_CARD = {
  description: 'some description',
  id: 1,
  opened_on: '2021-05-01T00:00:00Z',
  mailed_on: '2021-03-27T00:00:00Z',
  activated_on: '2021-05-01T00:00:00Z',
  expires_on: '2030-05-01T00:00:00Z',
  pin_set: true,
  created_at: '2021-05-01T00:00:00Z',
  modified_at: '2021-05-02T00:00:00Z',
  last4: '1243',
  card_status: CardStatusEnum.ACTIVE,
  card_type: BankTypeEnum.VISA,
  card_account_id: 1,
  account_ids: [12355],
  primary: true,
};

const MOCKED_BRENDONS_CARD = {
  description: 'some description',
  id: 2,
  last4: '1234',
  opened_on: '2021-05-02T00:00:00Z',
  mailed_on: '2021-03-28T00:00:00Z',
  activated_on: '2021-05-02T00:00:00Z',
  expires_on: '2030-05-02T00:00:00Z',
  pin_set: true,
  created_at: '2021-05-02T00:00:00Z',
  modified_at: '2021-05-03T00:00:00Z',
  card_status: CardStatusEnum.CLOSED,
  card_type: BankTypeEnum.VISA,
  card_account_id: 2,
  account_ids: [12355],
};

const MOCKED_ENTONYS_CARD = {
  description: 'some description',
  id: 3,
  opened_on: '2021-05-01T00:00:00Z',
  mailed_on: '2021-03-27T00:00:00Z',
  activated_on: '2021-05-01T00:00:00Z',
  expires_on: '2030-05-01T00:00:00Z',
  pin_set: true,
  created_at: '2021-05-01T00:00:00Z',
  modified_at: '2021-05-02T00:00:00Z',
  card_status: CardStatusEnum.FROZEN,
  card_type: BankTypeEnum.VISA,
  card_account_id: 3,
  last4: '4321',
  account_ids: [12355],
};

// mock.onGet(PATHS.CARD_HOLDERS('employee_id=1')).reply(200, MOCKED_HOLDER_LINDA);
// mock.onGet(PATHS.CARD_HOLDERS('employee_id=1&dependent_id=2')).reply(200, MOCKED_HOLDER_BRENDON);
// mock.onGet(PATHS.CARD_HOLDERS('employee_id=1&dependent_id=3')).reply(200, MOCKED_HOLDER_ENTONY);

export const useGetCardsQuery = (
  employeeId?: number | string | null,
  dependentId?: number | string | null,
) => {
  const query = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    if (employeeId) {
      queryGenerator.set(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID, `${employeeId}`);
    }

    if (dependentId && dependentId !== ROOT_DEPENDENT_ID) {
      queryGenerator.set(GET_CARDS_QUERY_PARAMS_KEYS.DEPENDENT_ID, `${dependentId || ROOT_DEPENDENT_ID}`);
    }

    return queryGenerator;
  }, [
    employeeId,
    dependentId,
  ]);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    PATHS.CARD_HOLDERS(query.toString()),
    () => (query.get(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID)
      ? api.get<CardHoldersResponse>(PATHS.CARD_HOLDERS(query.toString()))
      : null),
    {
      enabled: false,
      retry: false,
    },
  );

  const getHolderName = useCallback((firstName?: string, lastName?: string) => (firstName && lastName ? `${firstName} ${lastName}` : ''),
    []);

  useEffect(() => {
    if (query.get(GET_CARDS_QUERY_PARAMS_KEYS.EMPLOYEE_ID)) refetch();
  }, [query, refetch]);

  const formattingCard = useCallback((
    response: CardHoldersResponse,
    card: CardResponse,
    status: CardStatusEnum,
  ): CardDto => ({
    status,
    last4Number: card.last4,
    description: card.description,
    holder: {
      dependentId: `${response.dependent_id || ROOT_DEPENDENT_ID}`,
      name: `${response.first_name} ${response.last_name}`,
      firstName: response.first_name,
      lastName: response.last_name,
      dateOfBirth: response.date_of_birth,
      relationship: response.relationship,
    },
    id: `${card.id}`,
    bankType: card.card_type,
    primary: response.card_holder_type === CardHolderType.PRIMARY,
    accountIds: card.account_ids,
    cardAccountId: card.card_account_id,
    expDate: card.expires_on ? dayjs(card.expires_on).toDate() : null,
    cardRequestDate: card.created_at ? dayjs(card.created_at).toDate() : null,
    mailedDate: card.mailed_on ? dayjs(card.mailed_on).toDate() : null,
    activationDate: card.activated_on ? dayjs(card.activated_on).toDate() : null,
    memberSequenceIdentifier: card.member_sequence_identifier,
    alternateAccountId: card.alternate_account_id,
  }), []);

  const formattingCardsResponse = useCallback((response: CardHoldersResponse): CardsMap => Object
    .keys(response.grouped_cards || {}).reduce((map, status) => {
      const holderName = getHolderName(response.first_name, response.last_name);
      const cardHolderKey = `${holderName}-${response.id}`;
      const cards = response.grouped_cards
        ? response.grouped_cards[status as CardStatusEnum]
        : [];
      return {
        ...map,
        [cardHolderKey]: {
          ...map[cardHolderKey],
          [status]: cards.map((card) => formattingCard(response, card, status as CardStatusEnum)),
        },
      };
    }, {} as CardsMap),
  [formattingCard, getHolderName]);

  const formattingHolder = useCallback((response: CardHoldersResponse): CardHolderDto => ({
    dependentId: `${response.dependent_id || ROOT_DEPENDENT_ID}`,
    name: `${response.first_name} ${response.last_name}`,
    firstName: response.first_name,
    lastName: response.last_name,
    dateOfBirth: response.date_of_birth,
    relationship: response.relationship,
  }), []);

  const cardMap: CardsMap = useMemo(() => {
    const dependents = (data?.data?.dependents || []);
    const rootCardMap: CardsMap = data?.data ? formattingCardsResponse(data?.data) : { };
    const dependentsCardMap: CardsMap = dependents.reduce((map, dependent) => ({
      ...map,
      ...formattingCardsResponse(dependent),
    }), {});

    return {
      ...rootCardMap,
      ...dependentsCardMap,
    };
  }, [data, formattingCardsResponse]);
  const holderList: CardHolderDto[] = useMemo(() => [
    // ...data?.data ? [formattingHolder(data?.data)] : [],
    ...(data?.data.dependents || []).map(formattingHolder),
  ], [data, formattingHolder]);

  const cardList = useMemo(() => Object.keys(cardMap)
    .reduce((cardDtoList, holderName) => [
      ...cardDtoList,
      ...Object.values(cardMap[holderName])
        .reduce(
          (arr, status) => [...arr, ...status],
          [] as CardDto[],
        ),
    ], [] as CardDto[]),
  [cardMap]);

  const cardAccountId = useMemo(() => cardList[0]?.cardAccountId || null, [cardList]);
  const holderName = getHolderName(data?.data.first_name, data?.data.last_name) || '';
  const holderKey = `${holderName}-${data?.data.id}`;

  return {
    cardMap,
    response: data?.data,
    holderFirstName: data?.data.first_name,
    holderName,
    holderKey,
    holderList,
    refetch,
    isLoading,
    formattingCard,
    isFetching,
    hasCards: !!data?.data,
    cardAccountId,
  };
};

export const useGetCardListByHolderIdQuery = (id: string) => {
  const { data, isLoading } = useQuery(
    [GET_CARDS_QUERY_KEY, id],
    () => api.get<CardDto[]>(`/${PATHS.CARDS}?holder=${id}`),
  );
  return {
    cards: data?.data || [],
    isLoading,
  };
};

mock.onGet(`/${PATHS.CARDS}/${1}`).reply(200, MOCKED_LINDAS_CARD);
mock.onGet(`/${PATHS.CARDS}/${2}`).reply(200, MOCKED_BRENDONS_CARD);
mock.onGet(`/${PATHS.CARDS}/${3}`).reply(200, MOCKED_ENTONYS_CARD);

const GET_CARD_BY_ID_QUERY_KEY = 'getCardByIdQueryKey';
export const useGetCardByIdQuery = (id?: string | null) => {
  const { data, isLoading, refetch } = useQuery(
    [GET_CARD_BY_ID_QUERY_KEY, id],
    () => api.get(`/${PATHS.CARDS}/${id}`),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (id) refetch();
  }, [refetch, id]);

  return {
    card: data?.data,
    isLoading,
    refetch,
  };
};

mock.onGet(PATHS.CARD_HOLDERS_MOCK).reply(200, HOLDER_MOCK_LIST);
const GET_CARD_HOLDERS_QUERY_KEY = 'getCardHoldersQueryKey';
export const useGetCardHolderListQuery = () => {
  const { data, isLoading } = useQuery(
    [GET_CARD_HOLDERS_QUERY_KEY],
    () => api.get<CardHolderDto[]>(PATHS.CARD_HOLDERS_MOCK),
  );
  return {
    holders: data?.data || [],
    isLoading,
  };
};
export const useChangeCardStatus = () => {
  const { mutateAsync, isLoading } = useMutation(
    (data: ChangeCardStatusMutationArgs) => api.put(`${PATHS.CARDS}/${data.id}`, {
      status: data.status,
    }),
  );
  return {
    mutateAsync,
    isLoading,
  };
};
