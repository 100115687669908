import React from 'react';
import { Hint, Inscription, WarningIcon } from '@common-fe/common-fe';

const WarnIconWithText: React.FC<{ message: string }> = ({ message }) => (
  <Hint
    hintElement={(
      <WarningIcon color="danger" />
    )}
    contentStyles={{ maxWidth: '185px' }}
  >
    <Inscription size="12px" weight={500} lineHeight="18px">{message}</Inscription>
  </Hint>
);

export default WarnIconWithText;
