import { PlanCardAction } from '@/modules/plan/plan.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: {
    actionType: PlanCardAction | null;
    id: string;
  },
  setState: (actionType: PlanCardAction, id: string) => void;
  clear: () => void;
}

const DEFAUL_STATE = {
  actionType: null,
  id: '',
};
export const useStore = storeCreator<State>((set) => ({
  state: {
    ...DEFAUL_STATE,
  },
  setState: (value: PlanCardAction, id: string) => set(() => ({
    state: {
      actionType: value,
      id,
    },
  })),
  clear: () => set(() => ({
    state: {
      ...DEFAUL_STATE,
    },
  })),
}));

export default useStore;
