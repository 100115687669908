 
import React from 'react';

import PlanFormModal from './PlanFormModal';

interface Props {
  disabled?: boolean;
  isHealthPlanMode?: boolean;
}
const PlanModal: React.FC<Props> = ({ disabled, isHealthPlanMode }) => (
  <PlanFormModal
    disabled={disabled}
    isHealthPlanMode={isHealthPlanMode}
  />
);

export default PlanModal;
