import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import REGEXP from '@/common/regexp';
import { Address } from '@/common/types';
import {
  PaymentMethod,
  PaymentMethodDtoPayload,
  PaymentMethodOwnerType,
  PaymentMethodType,
} from '@/modules/employee/employee.types';
import { Provider } from '@/modules/ReimburseMe/ClaimInfo/useProviders.query';
import { useClaimInfoStore } from '@/modules/ReimburseMe/store/useClaimInfo.store';
import { getEncryptionByPubKey } from '@/utils/hooks/useEncryptData';
import { phoneNumberCreator } from '@/utils/modifiers/phoneNumberCreator';

import { DebitCardPayload } from './DebitCart/hooks';
import { BankInfo } from './DirectDeposit/BankForm/useBankFields';
import { PayPalInfo } from './PayPal/hooks';
import { VenmoInfo } from './Venmo/hooks';
import { usePaymentMethodsStore } from './usePaymentMethods.store';

export interface PaymentMethodRequestData {
  paymentType: PaymentMethodType;
  paymentOwnerType: PaymentMethodOwnerType;
  bankData?: BankInfo;
  payPalData?: PayPalInfo;
  venmoData?: VenmoInfo;
  provider?: Provider;
  debitCard?: DebitCardPayload;
  cardToken?: string;
  isDefault?: boolean;
}

const getCurrentPaymentData = (value: PaymentMethodRequestData) => {
  switch (value.paymentType) {
  case PaymentMethodType.DIRECT_DEPOSIT:
    return {
      direct_deposit: {
        account_description: value?.bankData?.associatedName,
        account_number: value?.bankData?.accountNumber,
        account_routing_transit_number: value?.bankData?.routingNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
        account_category: value?.bankData?.accountType,
      },
    };
  case PaymentMethodType.PAYPAL:
    return {
      requisites: {
        ...value?.payPalData?.email
          ? {
            email: value?.payPalData?.email,
          }
          : {},
        ...value?.payPalData?.phoneNumber
          ? {
            phone_number: `+${value?.payPalData?.phoneNumber?.replace(REGEXP.ALL_NON_NUMERIC, '')}`,
          }
          : {},
      },
    };
  case PaymentMethodType.VENMO:
    return {
      requisites: {
        phone_number: `+${value?.venmoData?.phoneNumber?.replace(REGEXP.ALL_NON_NUMERIC, '')}`,
      },
    };
  case PaymentMethodType.DEBIT:
    return {
      requisites: {
        card_holder_name: value.debitCard?.nameOnCard,
        card_expiration_date: value.debitCard?.expirationDate,
        card_type: value.debitCard?.cardType,
        card_last4: value.debitCard?.cardNumber
          ?.slice(value.debitCard?.cardNumber.length - 4, value.debitCard?.cardNumber.length),
        ...value?.cardToken ? {
          card_token: value.cardToken,
        } : {},
      },
    };

  default:
    return {};
  }
};

const formatDataToCreate = (
  value: PaymentMethodRequestData,
  employeeId: string,
  providerId?: string,
  address?: Address,
  skipNotificationSending?: boolean,
) => {
  const paymentMethodData = getCurrentPaymentData(value);
  return {
    ...skipNotificationSending ? { skip_notification_sending: true } : {},
    payment_method_type: value?.paymentType,
    is_default: value?.isDefault,
    payment_method_owner: {
      ...value?.paymentOwnerType === PaymentMethodOwnerType.EMPLOYEE && employeeId
        ? { employee_id: employeeId } : {},
      payment_method_owner_type: value?.paymentOwnerType,
      ...providerId && value?.paymentOwnerType === PaymentMethodOwnerType.PROVIDER
        ? { provider_id: +providerId } : {},
    },
    ...paymentMethodData,
    ...address ? {
      payment_address: {
        line1: address?.line1,
        line2: address?.line2,
        line3: address?.line3,
        line4: address?.line4,
        city: address?.city,
        state: address?.state?.code,
        zipcode: address?.zipcode,
        country_id: 0,
      },
    } : {},
  };
};

export const formatPaymentMethod = (paymentMethod: PaymentMethodDtoPayload): PaymentMethod => ({
  id: toString(paymentMethod?.id),
  type: paymentMethod?.payment_method_type,
  isDefault: paymentMethod?.is_default,
  ...paymentMethod?.is_default ? { isChecked: true } : {},
  accountId: toString(paymentMethod?.account_id),
  recipientId: toString(paymentMethod?.recipient_id),
  email: paymentMethod?.email,
  phoneNumber: paymentMethod?.phone_number,
  status: paymentMethod?.payment_method_status,
  accountNumber: paymentMethod?.account_number,
  accountDescription: paymentMethod?.account_description,
  accountNumberLast4: paymentMethod?.account_number_last4,
  address: {
    id: toString(paymentMethod?.payment_address?.id),
    line1: paymentMethod?.payment_address?.line1,
    line2: paymentMethod?.payment_address?.line2,
    line3: paymentMethod?.payment_address?.line3,
    line4: paymentMethod?.payment_address?.line4,
    city: paymentMethod?.payment_address?.city,
    state: {
      id: toString(paymentMethod?.payment_address?.state?.id),
      code: paymentMethod?.payment_address?.state?.code,
      name: paymentMethod?.payment_address?.state?.name,
    },
    zipcode: paymentMethod?.payment_address?.zipcode,
    validated: paymentMethod?.payment_address?.validated,
    country: {
      name: paymentMethod?.payment_address?.country?.name || '',
      alpha2: paymentMethod?.payment_address?.country?.alpha2 || '',
      alpha3: paymentMethod?.payment_address?.country?.alpha3 || '',
    },
  },
  paymentMethodOwner: {
    organizationId: toString(paymentMethod?.payment_method_owner?.organization_id),
    employeeId: toString(paymentMethod?.payment_method_owner?.employee_id),
    providerId: toString(paymentMethod?.payment_method_owner?.provider_id),
    ownerType: toString(paymentMethod?.payment_method_owner?.payment_method_owner_type) as PaymentMethodOwnerType,
  },
  requisites: {
    phoneNumber: paymentMethod?.requisites?.phone_number
      ? phoneNumberCreator(paymentMethod?.requisites?.phone_number)
      : '',
    email: paymentMethod?.requisites?.email,
    cardHolderName: paymentMethod?.requisites?.card_holder_name,
    cardExpirationDate: paymentMethod?.requisites?.card_expiration_date,
    cardLast4: paymentMethod?.requisites?.card_last4,
    cardType: paymentMethod?.requisites?.card_type,
  },
});

const useAddPaymentMethodQuery = (
  onSuccess?: (paymentMethodId?: string) => void,
  onAddBankAccount?: (value: string) => void,
  skipNotificationSending?: boolean,
) => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const queryClient = useQueryClient();
  const { values: { provider: providerId } } = useClaimInfoStore();
  const handleAddJustCreatedPaymentMethod = usePaymentMethodsStore(
    (state) => state.handleAddJustCreatedPaymentMethod,
  );

  const [isLoading, setLoading] = useState(false);
  const { mutateAsync, isSuccess } = useMutation(
    (value: PaymentMethodRequestData) => api.post(
      `${PATHS.PAYMENT_METHODS}?employee_id=${employeeId}`,
      formatDataToCreate(
        value,
        employeeId,
        value?.provider?.id || providerId,
        undefined,
        skipNotificationSending,
      ),
    ),
    {
      onSuccess: (value) => {
        const paymentMethodId = value?.data?.id ? toString(value?.data?.id) : undefined;
        queryClient.invalidateQueries(PATHS.PAYMENT_METHODS, { refetchInactive: true });
        setLoading(false);

        if (onSuccess) {
          onSuccess(paymentMethodId);
        }
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const save = useCallback(async (value: PaymentMethodRequestData) => {
    setLoading(true);
    let encryptData = {};
    if (value.paymentType === PaymentMethodType.DIRECT_DEPOSIT) {
      encryptData = await getEncryptionByPubKey({
        accountNumber: value.bankData?.accountNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
      });
    }
    const { data } = await mutateAsync({
      ...value,
      ...value.bankData
        ? {
          bankData: {
            ...value.bankData,
            ...encryptData,
          },
        }
        : {},
    });
    handleAddJustCreatedPaymentMethod(formatPaymentMethod(data));
    if (value.paymentType === PaymentMethodType.DIRECT_DEPOSIT && onAddBankAccount) {
      onAddBankAccount(toString(data?.id));
    }
    return data;
  }, [handleAddJustCreatedPaymentMethod, mutateAsync, onAddBankAccount]);

  return { isLoading, save, isSuccess };
};

export default useAddPaymentMethodQuery;
