import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface InterestAccrualsStatePayload {
  interestTemplate: PlanFieldState;

}

export const DEFAULT_VALUES: InterestAccrualsStatePayload = {
  interestTemplate: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<InterestAccrualsStatePayload>(DEFAULT_VALUES);

export default useStore;
