import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useGetPayoutDefinitionById from '../queries/useGetPayoutDefinition.query';

const useGetPayoutDefinition = (payoutDefinitionId?: string) => {
  const { id } = useParams<{ id: string }>();
  const fetchedOrgId = useMemo(() => (payoutDefinitionId || id), [payoutDefinitionId, id]);
  const {
    isLoading, isError, formattedData, refetch,
  } = useGetPayoutDefinitionById(fetchedOrgId);

  return {
    formattedData,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetPayoutDefinition;
