import React from 'react';

import { usePlanErrorsHandler } from './hooks';

interface Props {
  isTemplateMode?: boolean;
}
const ValidationNode: React.FC<Props> = ({ isTemplateMode }) => {
  usePlanErrorsHandler(isTemplateMode);
  return null;
};

export default ValidationNode;
