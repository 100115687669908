import { Box, Image } from '@common-fe/common-fe';
import styled from 'styled-components';

import { RotateStepEnum } from '@/components/elements/DocumentViewer/DocumentViewer.constants';

export const DownloadButtonWrapper = styled(Box)`
  z-index: 2;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.canvas};
  bottom: 0;
`;

export const ButtonWrapper = styled(Box)`
  z-index: 100;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.canvas};
  bottom: 0;
  right: 0;

`;

export const ActionButton = styled(Box)`
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

export const StyledImageWrap = styled(Box)<{
  rotate: number,
  zoom: number,
  rotateVertical: boolean,
  translateY: number,
  rotateStep: RotateStepEnum,
}>`
  display: block;
  max-width: initial;
  transition: .1s;
  img {
    width: 100%;
    transition: .1s;
    transform: rotate(${({ rotate }) => rotate}deg)
    translate(
            ${({
    translateY, rotateStep, rotateVertical,
  }) => {
    if (
      rotateStep === RotateStepEnum.left
      || rotateStep === RotateStepEnum.right
    ) return `${translateY}px, ${rotateVertical ? `${translateY}px` : ''}`;
    return 0;
  }}
    );
`;

export const StyledPreviewBox = styled(Box)`
 .react-pdf__Page__svg {
  border-radius: ${({ theme }) => theme.rounds.fieldRound};
   ${({ theme }) => theme.border.grey5}
   padding: ${({ theme }) => theme.spacings.spacing2};
    background-color: white;
 }
  .annotationLayer {
    min-height: ${({ theme }) => theme.spacings.spacing20};
  }
`;

export const RightActionsWrap = styled(Box)`
  position: absolute;
  right: ${({ theme }) => theme.spacings.spacing8};
`;

export const LeftActionsWrap = styled(Box)<{ isTop: boolean }>`
  position: absolute;
  ${({ theme, isTop }) => (isTop ? `
    top: -${theme.spacings.spacing24};
    left: 0;
    background-color: ${theme.colors.background1};
  ` : `left: ${theme.spacings.spacing8};`)}
`;

export const AnimatedImage = styled(Image)`
  animation:spin 2s linear infinite;
  
  @keyframes spin {
    100% {
      transform:rotate(360deg);
    }
  }
`;

export const Minus = styled.span`
  display: flex;
  align-items: center;
  width: 14px;
  height: 14px;
  &:before {
    content: '';
    width: 14px;
    height: 2px;
    position: absolute;
    background: #C4C4C4;
    border-radius: 1px;
  }
`;
export const Plus = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  &:before {
    content: '';
    width: 14px;
    height: 2px;
    position: absolute;
    background: #C4C4C4;
    border-radius: 1px;
  }
  &:after {
    content: '';
    width: 2px;
    height: 14px;
    position: absolute;
    background: #C4C4C4;
    border-radius: 1px;
  }
`;

export const SectionWrap = styled(Box)`
  flex: 1;
`;

export const StyledFooter = styled(Box)`
  position: relative;
  height: ${({ theme }) => theme.spacings.spacing54};
`;
