import React from 'react';
import { Field, FieldTypes, Inscription } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE } from '@/common/constants';

import { FileDetails } from '../queries/useGetFileDetails.query';

interface Params {
  fileDetails?: FileDetails;
  totalErrors?: number;
  isAvailable?: boolean;
  isProcessing?: boolean;
}
const useGetDetailsFields = ({ fileDetails, totalErrors, isAvailable, isProcessing }: Params) => {
  if (!fileDetails) return [];
  return [
    {
      name: 'countOfTotalRecords',
      label: 'Total Records',
      type: FieldTypes.Node,
      value: (
        <Inscription lineHeight="20px" {...(isAvailable ? { weight: 'bold' } : {})}>
          {isAvailable && (fileDetails.recordCounts || fileDetails.recordCounts === 0)
            ? fileDetails.recordCounts
            : EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },
    ...(isAvailable ? [{
      name: 'processingCount',
      label: 'Processing Records',
      type: FieldTypes.Node,
      value: (
        <Inscription
          lineHeight="20px"
          {...((fileDetails.processingRecordsCount || fileDetails.processingRecordsCount === 0) && isAvailable && isProcessing
            ? {
              weight: 'bold',
            }
            : {})}
        >
          {isAvailable && isProcessing && (fileDetails.processingRecordsCount || fileDetails.processingRecordsCount === 0)
            ? fileDetails.processingRecordsCount
            : EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },]: []),
    
    {
      name: 'countOfRecordsProcessedSuccessfully',
      label: 'Successful Records',
      type: FieldTypes.Node,
      value: (
        <Inscription lineHeight="20px" {...(isAvailable ? { weight: 'bold' } : {})}>
          {isAvailable && (fileDetails.successfulRecordsCount || fileDetails.successfulRecordsCount === 0)
            ? fileDetails.successfulRecordsCount
            : EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },



    ...(isAvailable
      ? [
        {
          name: 'countOfRecordsThatWereCreated',
          label: (
            <Inscription weight="normal" lineHeight="20px" margin={{ left: 'spacing24' }}>
              Created Records
            </Inscription>
          ),
          type: FieldTypes.Node,
          value: (
            <Inscription lineHeight="20px">
              {isAvailable && (fileDetails.createdRecordsCount || fileDetails.createdRecordsCount === 0)
                ? fileDetails.createdRecordsCount
                : EMPTY_FIELD_VALUE}
            </Inscription>
          ),
        },
        {
          name: 'countOfRecordsThatHadChange',
          label: (
            <Inscription weight="normal" lineHeight="20px" margin={{ left: 'spacing24' }}>
              Changed Records
            </Inscription>
          ),
          type: FieldTypes.Node,
          value: (
            <Inscription lineHeight="20px">
              {isAvailable && (fileDetails.updatedRecordsCount || fileDetails.updatedRecordsCount === 0)
                ? fileDetails.updatedRecordsCount
                : EMPTY_FIELD_VALUE}
            </Inscription>
          ),
        },
        {
          name: 'countOfRecordsThatDidNotChange',
          label: (
            <Inscription weight="normal" lineHeight="20px" margin={{ left: 'spacing24' }}>
              Records with no changes
            </Inscription>
          ),
          type: FieldTypes.Node,
          value: (
            <Inscription lineHeight="20px">
              {isAvailable && (fileDetails.noChangeRecordsCount || fileDetails.noChangeRecordsCount === 0)
                ? fileDetails.noChangeRecordsCount
                : EMPTY_FIELD_VALUE}
            </Inscription>
          ),
        },
      ]
      : []),
 
    {
      name: 'countOfRecordsErrored',
      label: 'Errored Records',
      type: FieldTypes.Node,
      value: (
        <Inscription
          lineHeight="20px"
          {...(fileDetails.errorRecordsCount && isAvailable
            ? {
              weight: 'bold',
              color: 'danger',
            }
            : {})}
        >
          {isAvailable && (fileDetails.errorRecordsCount || fileDetails.errorRecordsCount === 0)
            ? fileDetails.errorRecordsCount
            : EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },
    ...(isAvailable
      ? [
        {
          name: 'countOfFieldsErrors',
          label: (
            <Inscription weight="normal" lineHeight="20px" margin={{ left: 'spacing24' }}>
              Field Errors
            </Inscription>
          ),
          type: FieldTypes.Node,
          value: (
            <Inscription
              lineHeight="20px"
              {...(fileDetails.errorRecordsCount && isAvailable
                ? {
                  color: 'danger',
                }
                : {})}
            >
              {isAvailable && (totalErrors || totalErrors === 0) ? totalErrors : EMPTY_FIELD_VALUE}
            </Inscription>
          ),
        },
      ]
      : []),
  ] as Field[];
};

export default useGetDetailsFields;
