import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  ErrorModal,
  Field,
  OptionKey,
  Preloader,
  WarningIcon,
  WarnModal,
} from '@common-fe/common-fe';
import { toString } from 'lodash';
import * as yup from 'yup';

import { ALL_OPTION, SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE } from '@/common/constants';
import ROUTES from '@/common/routes';
import AppButton from '@/components/controls/AppButton';
import { useHistory } from '@/modules/core/hooks';
import { handleAllToggle } from '@/modules/core/hooks/useAllOptionsToggle';

import useRecipientsStore from '../../ContributionEmployeesSettings/stores/useRecipients.store';
import { Recipient, Segment } from '../../ContributionEmployeesSettings/types';
import { ContributionGroups, ContributionSegmentType, ManualContributionFormFields, NewContributionType } from '../contributions.types';
import { useManualContribution } from '../hooks/useManualContribution';
import useGetContributionSegmentByIdQuery from '../queries/useGetContributionSegmentById.query';
import { useGetEmployeesContributionViewQuery } from '../queries/useGetEmployeesContributionView.query';
import { useGetTotalEmployeesContributionViewQuery } from '../queries/useGetTotalEmployeesContributionView.query';
import { useAllValuesForManualContributionsStore } from '../store';
import { useStore } from '../store/useExceedsMaxLimitContributionModal.store';

import { ContributionsLimitInfoBanner } from './ContributionsLimitInfoBanner';
import { ExceedsMaxLimitContributionModal } from './ExceedsMaxLimitContributionModal';
import { ManualContributionForm } from './ManualContributionForm';

export const MAX_ALLOWED_SIZE = 500;

interface Props {
  contributionId?: string;
  onCancel?: (value: boolean) => void;
  setIsOverflowHidden?: (value: boolean) => void;
}

const MakeContributionModalForm: React.FC<Props> = ({
  contributionId,
  onCancel,
  setIsOverflowHidden,
}) => {
  const [isNoDataModalShown, setIsNoDataModalShown] = useState(false);
  const { setRecipients, setSegment } = useRecipientsStore();
  const history = useHistory();
  const {
    formValues,
    setFormValues,
    infoSetup,
    setInfoSetup,
  } = useManualContribution();

  const {
    formattedData: preselectedSegment,
    isLoading: isPreselectedSegmentLoading,
  } = useGetContributionSegmentByIdQuery({
    contributionId,
    setFormValues,
  });

  const [isSubmit, setSubmit] = useState(false);
  const [plans, setPlans] = useState<OptionKey[]>([]);
  const [payrollGroups, setPayrollGroups] = useState<OptionKey[]>([]);
  const [subsidiaries, setSubsidiaries] = useState<OptionKey[]>([]);
  const [groupsSetup, setGroupsSetup] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const isGroupPlans = useMemo(() => (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.PLANS
    && formValues[ManualContributionFormFields.CONTRIBUTION_TYPE] === NewContributionType.GROUP)
    || preselectedSegment?.contributionSegmentType === ContributionSegmentType.PLANS, [formValues, preselectedSegment]);
  const isGroupSubsidiary = useMemo(() => (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.SUBSIDIARY
    && formValues[ManualContributionFormFields.CONTRIBUTION_TYPE] === NewContributionType.GROUP)
    || preselectedSegment?.contributionSegmentType === ContributionSegmentType.SUBSIDIARY, [formValues, preselectedSegment]);
  const isGroupPayroll = useMemo(() => (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.PAYROLL_GROUP
  && formValues[ManualContributionFormFields.CONTRIBUTION_TYPE] === NewContributionType.GROUP)
  || preselectedSegment?.contributionSegmentType === ContributionSegmentType.PAYROLL_GROUP, [formValues, preselectedSegment]);
  const isGroupEmployees = useMemo(() => (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.ALL_EMPLOYEES
    && formValues[ManualContributionFormFields.CONTRIBUTION_TYPE] === NewContributionType.GROUP)
    || preselectedSegment?.contributionSegmentType === ContributionSegmentType.ALL_EMPLOYEES, [formValues, preselectedSegment]);

  const onSetVisible = useStore((store) => store.handleChangeVisibility);
  const visible = useStore((store) => store.modalVisible);

  const storedPlans = useAllValuesForManualContributionsStore((state) => state.plans);

  const allPlans = useMemo(() => storedPlans?.map((item) => item.planCode), [storedPlans]);
  const allSubsidiaries = useAllValuesForManualContributionsStore((state) => state.subsidiaries);
  const allPayrolls = useAllValuesForManualContributionsStore((state) => state.payrolls);

  const handleReset = useAllValuesForManualContributionsStore((state) => state.handleReset);  


  const currentPlansValues = useMemo(
    () => {
      if(contributionId) {
        return preselectedSegment?.segmentPlanCodes || [];
      }
    
      return (plans.length === 1 && plans[0] === ALL_OPTION.key) || plans.length === 0
        ? allPlans
        : plans?.map((plan) => plan as string);
    },
    [allPlans, contributionId, plans, preselectedSegment]);
  
  const currentPayrollsValues = useMemo(
    () => {
      if (contributionId) {
        return preselectedSegment?.segmentPayrollGroupCodes || [];
      }
      if ((payrollGroups.length === 1 && payrollGroups[0] === ALL_OPTION.key) || payrollGroups.length === 0) {
        return allPayrolls;
      }

      return payrollGroups.map((item) => item as string);
    },
    [allPayrolls, payrollGroups, contributionId, preselectedSegment]);
  const currentSubsidiariesValues = useMemo(() => {
    if (contributionId) {
      return preselectedSegment?.segmentSubsidiaryIds || [];
    }

    return subsidiaries.length === 1 && subsidiaries[0] === ALL_OPTION.key || subsidiaries.length === 0
      ? allSubsidiaries
      : subsidiaries.map((subsidiary) => subsidiary as string);
  },
  [allSubsidiaries, subsidiaries, contributionId, preselectedSegment]);

  const plansIds = useMemo(() => (contributionId ? preselectedSegment?.segmentPlanCodes : currentPlansValues)
    ?.map((planCode) => storedPlans
      ?.find((plan) => plan.planCode === planCode)?.id || '')
    .filter((item) => Boolean(item)),
  [currentPlansValues, storedPlans, contributionId, preselectedSegment]);
  const { isLoading, fetchDataManually } = useGetEmployeesContributionViewQuery({
    plansIds: isGroupPlans ? plansIds : undefined,
    payrolls: isGroupPayroll ? currentPayrollsValues : undefined,
    organizationIds: isGroupSubsidiary ? currentSubsidiariesValues : undefined,
    employeeIds: isGroupEmployees
      ? undefined
      : formValues[ManualContributionFormFields.EMPLOYEE] ? [formValues[ManualContributionFormFields.EMPLOYEE] as string] : undefined,
    enabled: false,
    size: MAX_ALLOWED_SIZE,
  });

  const { fetchTotalManually } = useGetTotalEmployeesContributionViewQuery({
    plansIds: isGroupPlans ? plansIds : undefined,
    payrolls: isGroupPayroll ? currentPayrollsValues : undefined,
    organizationIds: isGroupSubsidiary ? currentSubsidiariesValues : undefined,
    employeeIds: isGroupEmployees
      ? undefined
      : formValues[ManualContributionFormFields.EMPLOYEE] ? [formValues[ManualContributionFormFields.EMPLOYEE] as string] : undefined,
  });

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((prev: any) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextStep = useCallback(
    () => history.push(ROUTES.SET_UP_CONTRIBUTION), [history],
  );

  const contributionSegmentType = useMemo(() => {
    if (isGroupPlans) return ContributionGroups.PLANS;
    if (isGroupSubsidiary) return ContributionGroups.SUBSIDIARY;
    if (isGroupEmployees) return ContributionGroups.ALL_EMPLOYEES;
    if (isGroupPayroll) return ContributionGroups.PAYROLL_GROUP;
    return NewContributionType.SINGLE;
  }, [isGroupPlans, isGroupSubsidiary, isGroupEmployees, isGroupPayroll]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    if (!infoSetup || !groupsSetup) return;
    setIsSubmitting(true);
    try {
      const totalResponse = await fetchTotalManually();

      if (totalResponse.data === 0) {
        setIsNoDataModalShown(true);
        setIsSubmitting(false);
        return;
      }

      if (totalResponse.data && totalResponse.data > MAX_ALLOWED_SIZE) {
        onSetVisible(true);
        setIsSubmitting(false);
        return;
      }
      const response = await fetchDataManually(preselectedSegment?.contributionRequestId);
      if (response?.data?.content?.length) {
        setRecipients(response.data.content.map((item) => ({
          firstName: item?.first_name || '',
          lastName: item?.last_name || '',
          employeeFullName: `${item?.first_name} ${item?.last_name}`.trim(),
          isNewEmployee: item?.is_new_employee,
          employeeTerminationDate: item?.employee_termination_date,
          employeeId: toString(item.employee_id),
          isSubsidiary: item.is_subsidiary,
          ...item?.plans?.length ? {
            contributionAccounts: item.plans.map((plan) => ({
              isPlanDisabled: plan?.is_plan_disabled,
              isDisabled: plan?.is_plan_disabled || Boolean(item?.employee_termination_date),
              planCode: plan?.plan_code,
              planId: toString(plan?.plan_id),
              accountType: plan?.account_type,
              taxType: formValues[ManualContributionFormFields.TAX_TYPE],
              taxYear: formValues[ManualContributionFormFields.TAX_YEAR],
              memo: formValues[ManualContributionFormFields.MEMO],
              contributionDateOn: formValues[ManualContributionFormFields.CONTRIBUTION_DATE],
              perPayEmployee: toString(plan?.per_pay_employee),
              perPayEmployer: toString(plan?.per_pay_employer),
              employeeContributionAmount: toString(plan?.per_pay_employee),
              employerContributionAmount: toString(plan?.per_pay_employer),
            })),
          } : {},
        })) as Recipient[]);
        
        setSegment({
          contributionRequestId: preselectedSegment?.contributionRequestId,
          taxType: formValues[ManualContributionFormFields.TAX_TYPE],
          taxYear: formValues[ManualContributionFormFields.TAX_YEAR],
          contributionSegmentType,
          memo: formValues[ManualContributionFormFields.MEMO],
          ...isGroupEmployees ? {
            segmentEmployeeIds: response.data.content
              .filter((item) => !item.is_subsidiary)
              .map((item) => toString(item.employee_id)),
          } : {
            ...formValues[ManualContributionFormFields.EMPLOYEE] ? {
              segmentEmployeeIds: [formValues[ManualContributionFormFields.EMPLOYEE]],
            } : {},
          },
          ...currentSubsidiariesValues?.length ? { segmentSubsidiaryIds: [...currentSubsidiariesValues] } : {},
          ...currentPlansValues?.length ? { segmentPlanCodes: currentPlansValues } : {},
          ...currentPayrollsValues?.length ? { segmentPayrollGroupCodes: currentPayrollsValues } : {},
        } as Segment);
      }
      handleNextStep();
      handleReset();
      setIsSubmitting(false);
    } catch {
      setIsServerError(true);
      setIsSubmitting(false);
    }
  }, [
    isGroupEmployees,
    setIsNoDataModalShown,
    currentPayrollsValues,
    preselectedSegment,
    setIsSubmitting,
    fetchDataManually,
    groupsSetup,
    handleNextStep,
    infoSetup,
    onSetVisible,
    handleReset,
    setRecipients,
    formValues,
    currentSubsidiariesValues,
    currentPlansValues,
    setSegment,
    fetchTotalManually,
    contributionSegmentType,
  ]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
      handleReset();
      setFormValues({});
    }
  }, [onCancel, handleReset, setFormValues]);

  const setOptionValues = useCallback((fieldName: string, values: OptionKey[]) => {
    const preparedValues = handleAllToggle(values);
    switch (fieldName) {
    case ManualContributionFormFields.PAYROLL_GROUP:
      setPayrollGroups(preparedValues);
      break;
    case ManualContributionFormFields.PLANS:
      setPlans(preparedValues);
      break;
    case ManualContributionFormFields.SUBSIDIARY:
      setSubsidiaries(preparedValues);
      break;
    default:
      break;
    }
  }, []);

  useEffect(() => {
    if (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.PLANS && !plans.length) {
      setGroupsSetup(false);
    }

    if (formValues[ManualContributionFormFields.GROUP_TYPE] === ContributionGroups.SUBSIDIARY && !subsidiaries.length) {
      setGroupsSetup(false);
    }

    setGroupsSetup(true);
  }, [formValues, plans, subsidiaries]);

  useEffect(() => {
    if (setIsOverflowHidden) setIsOverflowHidden(isServerError || visible);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isServerError, visible]);

  return (
    <>
      {isNoDataModalShown ? (
        <WarnModal
          testId='noDataModal'
          iconNode={<WarningIcon size="48px" />}
          header="No employees under selected account"
          helptext="Select other accounts and try again."
          visible
          onSetVisible={setIsNoDataModalShown}
        />
      ) : null}
      <ErrorModal
        testId="Claim_date_server-error"
        visible={isServerError}
        header={SERVER_ERROR_MODAL_TITLE}
        helpText={SERVER_ERROR_MODAL_TEXT}
        buttonText="Close"
        onSetVisible={setIsServerError}
      />
      <Box data-testid="Make-contribution-modal-form">
        <Box direction="column">
          <Box round="container1Round" pad="spacing24" background="module">
            <ManualContributionForm
              handleChangeValues={changeValues}
              isSubmit={isSubmit}
              setInfoSetup={setInfoSetup}
              formValues={formValues}
              setOptionValues={setOptionValues}
              payrollGroups={payrollGroups}
              plans={plans}
              subsidiaries={subsidiaries}
              contributionId={contributionId}
              isLoading={isLoading || isPreselectedSegmentLoading}
            />

            <ContributionsLimitInfoBanner />

          </Box>
          <Box
            direction="row"
            fill="horizontal"
            margin={{ top: 'spacing24' }}
            align="end"
            justify="end"
            width="medium"
            gap="xs"
          >
            <AppButton
              testId="make-contribution-cancel"
              buttonType="secondary"
              width="control"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </AppButton>
            <AppButton
              testId="make-contribution-add"
              type="button"
              disabled={isLoading || isPreselectedSegmentLoading || isSubmitting}
              onClick={handleSubmit}
              width="control"
            >
              {isLoading || isPreselectedSegmentLoading || isSubmitting
                ? (<Preloader color="white" />)
                : 'Next (1/2)'}
            </AppButton>
          </Box>
        </Box>
      </Box>
      <ExceedsMaxLimitContributionModal />
    </>
  );
};

export default MakeContributionModalForm;
