import React, { useCallback, useMemo,useState } from 'react';
import {
  AppButton,   Box, CheckBox,
  PlusIcon, RawButton, Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import { Option, OptionKey } from '@/common/types';
import { ModalWrapper } from '@/components';

// import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import CreateCustomCoverageLevel from './CreateCustomCoverageLevel';

interface Props {
  options?: Option[];
  onClose:() => void;
  onBindCoverageLevels:(ids:number[]) => void;
}
const SelectCoverageLevel: React.FC<Props> = ({ options = [], onClose, onBindCoverageLevels }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<OptionKey[]>([]);
  const [visible, setModalVisible] = useState(false);
  const handlePick = useCallback((value: OptionKey) => {
    if (values.includes(value)) {
      setValues(values.filter((item) => item !== value));
    } else {
      setValues([...values, value]);
    }
  }, [values]);
  const handleToggleModal = useCallback(() => {
    setModalVisible(!visible);
  }, [visible]);
  const coveragesIds = useMemo(() => values.map((value) => _.toNumber(value)), [values]);
  const isEmptyValues = useMemo(() => _.isEmpty(values), [values]);

  // const handleUpdateHealthPlan = useUpdateHealthPlan({
  //   newCoverageIds: coveragesIds,
  //   isReloadlessMode: true,
  // });
  const handleAdd = useCallback(async () => {
    try {
      setLoading(true);
      onBindCoverageLevels(coveragesIds);
      // handleUpdateHealthPlan(HealthPlanStatuses.DRAFT);
      onClose();
    } finally {
      setLoading(false);
    }
  }, [coveragesIds, onBindCoverageLevels, onClose]);
  return (
    <>
      <ModalWrapper
        visible={visible}
        title="Create Custom Coverage Level"
        onSetVisible={handleToggleModal}
      >
        <CreateCustomCoverageLevel
          onClose={handleToggleModal}
        />
      </ModalWrapper>
      <Box
        direction="column"
        pad={{
          top: 'l',
          left: 'l',
          right: 'l',
          bottom: 'spacing20',
        }}
        data-testid="select-coverage-level"
        round="moduleRound"
        background="module"
      >

        <RawButton onClick={handleToggleModal}>

          <Box
            background="canvas"
            pad={{ vertical: 's', horizontal: 's' }}
            border={{ size: 'small', color: 'border2' }}
            // margin={{ top: 's' }}
            round="container1Round"
            elevation="default"
          >
            <Box direction="row" align="center" pad={{ horizontal: 'xs', vertical: 'xxs' }}>
              <PlusIcon size="12px" color="button" /> <Text margin={{ left: 'xxs' }} color="button">Create Custom Coverage Level</Text>
            </Box>
          </Box>
        </RawButton>
        <Box margin={{ top: 's', bottom: 's' }} border={{ size: 'small', color: 'border1' }} />
        {options.map((item) => (
          <Box
            key={item.key}
            background="canvas"
            pad={{ vertical: 's', horizontal: 'm' }}
            border={{ size: 'small', color: 'border2' }}
            margin={{ bottom: 'xxs' }}
            round="container1Round"
            elevation="default"
            onClick={() => handlePick(item.key)}
          >
            <Box pad={{ horizontal: 'xs', vertical: 'spacing6' }} direction="column">
              <CheckBox
                id={`${item.key}`}
                checked={values.includes(item.key)}
                name="Enable notifications"
                onChange={() => handlePick(item.key)}
                style={{ width: '100%' }}
                label={(
                  <Text size="medium" color="textBody" weight={700} style={{ flex: 1 }}>
                    {item.value as string}
                  </Text>
                )}
              />
            </Box>
          </Box>
        ))}

      </Box>
      <Box margin={{ top: 'l' }} direction="row" justify="end">
        <AppButton
          testId="select-coverage_cancel"
          buttonType="secondary"
          width="control"
          onClick={onClose}
        >Cancel
        </AppButton>
        <Box margin={{ left: 's' }}>
          <AppButton
            testId="select-coverage_add"
            disabled={loading || isEmptyValues}
            width="control"
            onClick={handleAdd}
          >
            Add
          </AppButton>
        </Box>
      </Box>
    </>
  );
};

export default SelectCoverageLevel;
