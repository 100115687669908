import React, { useMemo } from 'react';
import { AppButton, Box } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useCurrentPlan } from '@/modules/plan/PlanSetup/hooks';

import { PlanStatuses } from '../../plan.types';
import { useEditModeStore } from '../stores';

const EMPTY_BOX_WIDTH = '400px';

interface Props {
  isTemplateMode?: boolean;
}
const ConfigureButton: React.FC<Props> = ({ isTemplateMode }) => {
  const { planStatus } = useCurrentPlan();
  const { observingOrganization: { type } } = useCurrentOrganization();
  const setEditMode = useEditModeStore((state) => state.setEditMode);
  const isDraftMode = useMemo(() => planStatus === PlanStatuses.DRAFT, [planStatus]);

  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  if (planStatus === PlanStatuses.ACTIVE) {
    return null;
  }

  return (
    <Box direction="row" align="center" margin={{ bottom: 'spacing24' }}>
      <Box width={EMPTY_BOX_WIDTH} />
      <Box width="medium">
        {
          !isSubsidiary && (
            <Access
              accessRequirements={[
                {
                  permission: isTemplateMode
                    ? Permissions.PLAN_TEMPLATE_SETUP
                    : Permissions.EDIT_DRAFT_PLAN,
                },
              ]}
            >
              <AppButton
                onClick={() => setEditMode(true)}
                buttonType="secondary"
                width="100%"
              >
                {isDraftMode ? 'Edit' : 'Configure'}
              </AppButton>
            </Access>
          )
        }
      </Box>
    </Box>
  );
};

export default ConfigureButton;
