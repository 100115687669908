import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS, REGEXPS } from '@/common';
export const GET_SSN_QUERY_KEY = 'GEt_EMPLOYEE_SSN';
interface Response {
  ssn: string;
}
export default (employeeId?: string) => {
  const {data, isSuccess, isError} = useQuery([
    GET_SSN_QUERY_KEY, employeeId
  ], () => api.get<Response>(PATHS.GET_SSN_BY_EMPLOYEE_ID(employeeId || ''), {}), {
    enabled:!!employeeId,
    retry: false
  });
  
  const ssn = useMemo(() =>  {
    if(isError) {
      return '';
    }
    const currentSSN =  data?.data?.ssn;
    if(currentSSN) {
      return currentSSN.replace(REGEXPS.SSN, '$1-$2-$3');
    }
    return '';
  }, [data?.data?.ssn, isError]);
  return {
    isSuccess,
    ssn,
  };
};