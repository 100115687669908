import { useCallback } from 'react';
import _ from 'lodash';

import useCoverageLevelsFieldsStateStore,
{ CoverageLevelsFieldsStatePayload as CoverageLevelsFielPayload, DEFAULT_VALUES } from '../stores/useCoverageLevelsFieldsState.store';

export type CoverageLevelsFieldsStatePayload = CoverageLevelsFielPayload;

export default () => {
  const stateCoverageLevlesFields = useCoverageLevelsFieldsStateStore((state) => state.state);
  const setCoverageLevelsFieldsStateStore = useCoverageLevelsFieldsStateStore(
    (state) => state.setState,
  );
  const setSourceCoverageLevelsFieldsStateStore = useCoverageLevelsFieldsStateStore(
    (state) => state.setSourceState,
  );

  const handleResetCoverageLevelsFieldsStateStore = useCoverageLevelsFieldsStateStore(
    (state) => state.handleReset,
  );

  const handleSetState = useCallback(
    (values: CoverageLevelsFieldsStatePayload, coverageLevelId?: string) => {
      const foundItem = stateCoverageLevlesFields
        ? stateCoverageLevlesFields.find(((item) => item.coverageId === coverageLevelId))
        : null;
      const pickedValues = _.pick(values, Object.keys(DEFAULT_VALUES));
      if (!foundItem) {
        setCoverageLevelsFieldsStateStore([
          ...stateCoverageLevlesFields,
          {
            coverageId: coverageLevelId || '',
            ...pickedValues,
          },
        ]);
      } else {
        const mappedList = stateCoverageLevlesFields.map((item) => {
          if (item.coverageId === coverageLevelId) {
            return {
              ...foundItem,
              ...pickedValues,
            };
          }
          return item;
        });

        setCoverageLevelsFieldsStateStore(mappedList);
      }
    }, [setCoverageLevelsFieldsStateStore, stateCoverageLevlesFields],
  );

  const handleSetSourceState = useCallback(
    (values: CoverageLevelsFieldsStatePayload[]) => {
      setCoverageLevelsFieldsStateStore(values);
      setSourceCoverageLevelsFieldsStateStore(values);
    }, [setCoverageLevelsFieldsStateStore, setSourceCoverageLevelsFieldsStateStore],
  );

  const handleReset = useCallback(() => {
    handleResetCoverageLevelsFieldsStateStore();
  }, [handleResetCoverageLevelsFieldsStateStore]);

  return {
    // handleSetErrorFields,
    handleSetSourceState,
    handleReset,
    handleSetState,

  };
};
