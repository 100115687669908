import React, { useMemo } from 'react';
import { Field, FieldTypes, LabelWithSubfield } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { useCurrentPlan,usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { PLAN_SETUP_FIELD } from '@/modules/plan/PlanSetup/PlanSetup.constants';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import { usePlanTemplatesQuery, usePlanTypesQuery, usePriorPlansQuery } from '@/modules/plan/queries';
import { useFieldsWithDefaultValues } from '@/utils';

import { usePlanNameStore } from '../stores';

const VALIDATOR = yup.string().nullable();

const usePlanNameFields =  (
  disabled?: boolean, isTemplateMode?: boolean, isEditMode?: boolean,
) => {
  const { priorPlanYear } = useCurrentPlan();
  const errors = usePlanNameStore((state) => state.errors);
  const sourceState = usePlanNameStore((state) => state.sourceState);
  const currentState = usePlanNameStore((state) => state.state);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const planTypes = usePlanTypesQuery();
  const { data: priorPlans } = usePriorPlansQuery();
  const { formattedListTemplates } = usePlanTemplatesQuery();
  const fields = useMemo<Field[]>(() => {
    if (isTemplateMode) {
      return [
        {
          name: PLAN_SETUP_FIELD.PLAN_NAME,
          type: FieldTypes.Text,
          label: 'Plan template name',
          showRequireIcon: true,
          disabled,
          placeholder: 'Enter plan template name',
          validator: yup.string().trim().required(REQUIRED_TEXT),
        },
        {
          name: PLAN_SETUP_FIELD.PLAN_SUMMARY,
          type: FieldTypes.TextArea,
          label: (
            <LabelWithSubfield
              title="Summary"
              desc="Summary of the plan to display to employer admins who set up their organizations within your organization."
            />
          ),
          disabled,
          placeholder: 'Summary',
          maxLength: 500,
          style: { minHeight: '120px' },
        },
        {
          name: PLAN_SETUP_FIELD.PLAN_TYPE,
          type: FieldTypes.Dropdown,
          label: 'Plan type',
          showRequireIcon: true,
          disabled: true,
          options: planTypes,
          placeholder: 'Select plan type',
          validator: yup.string().trim().required(REQUIRED_TEXT),
        },
        {
          name: PLAN_SETUP_FIELD.PLAN_TEMPLATE,
          type: FieldTypes.Dropdown,
          label: 'Parent template',
          disabled: true,

          options: formattedListTemplates,
          placeholder: 'Select a plan template',
          validator: yup.string().trim(),
        },
      ];
    }
    return [
      {
        name: PLAN_SETUP_FIELD.PLAN_NAME,
        type: FieldTypes.Text,
        label: 'Plan name',
        showRequireIcon: true,
        disabled,
        placeholder: 'Enter Plan name',
        validator: yup.string().trim().required(REQUIRED_TEXT),
      },
      {
        name: PLAN_SETUP_FIELD.PLAN_TYPE,
        type: FieldTypes.Dropdown,
        label: 'Plan type',
        showRequireIcon: true,
        disabled: true,
        options: planTypes,
        placeholder: 'Select plan type',
        validator: yup.string().trim().required(REQUIRED_TEXT),
      },
      {
        name: PLAN_SETUP_FIELD.PLAN_TEMPLATE,
        type: FieldTypes.Dropdown,
        label: 'Parent template',
        showRequireIcon: true,
        disabled: true,
        options: formattedListTemplates,
        placeholder: 'Select a plan template',
        validator: yup.string().trim().required(REQUIRED_TEXT),
      },
      {
        name: PLAN_SETUP_FIELD.PLAN_CODE,
        type: FieldTypes.Text,
        label: 'Plan code',
        showRequireIcon: true,
        options: planTypes,
        disabled,
        placeholder: 'Enter plan code',
        validator: yup.string().trim().test({
          test: (val) => val !== errors?.planCode,
          message: 'This plan code is already used',
        }).required(REQUIRED_TEXT),
      },
      ...priorPlanYear?.id
        ? [{
          name: PLAN_SETUP_FIELD.PRIOR_PLAN_ID,
          type: FieldTypes.Dropdown,
          label: 'Prior plan',
          singleMode: true,
          options: priorPlans,
          placeholder: 'Select prior plan',
          emptyListMessage: 'No prior plans have been found',
          validator: VALIDATOR,
        }] as Field[] : [],
    ];
  }, [disabled, errors, formattedListTemplates, planTypes,
    priorPlans, isTemplateMode, priorPlanYear]);
  const filteredField = usePlanFieldsState(fields);

  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};

export default usePlanNameFields;
