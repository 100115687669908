import React, { useCallback } from 'react';
import {
  Box,
  Field,
  FlexControlledForm,
  OptionKey,
  Preloader,
} from '@common-fe/common-fe';

import { ManualContributionFormValues } from '../contributions.types';
import { useManualContributionFields } from '../hooks/useManualContributionFields';

interface Props {
  handleChangeValues: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,) => void;
  isSubmit: boolean;
  setInfoSetup: (value: boolean) => void;
  formValues: ManualContributionFormValues;
  setOptionValues?: (fieldName: string, values: OptionKey[]) => void;
  plans?: OptionKey[];
  payrollGroups?: OptionKey[];
  subsidiaries?: OptionKey[];
  contributionId?: string;
  isLoading?: boolean;
}

export const ManualContributionForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  setInfoSetup,
  formValues,
  setOptionValues,
  plans,
  payrollGroups,
  subsidiaries,
  contributionId,
  isLoading,
}) => {
  const infoFields = useManualContributionFields({
    formValues,
    setOptionValues,
    plans,
    payrollGroups,
    subsidiaries,
    isSubmit,
    contributionId,
  });
  
  const handleChangeContributionValues = useCallback((values: object) => {
    handleChangeValues(values, infoFields, setInfoSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="ManualContributionForm_container" round="container1Round" background="canvas" style={{ position: 'relative' }}>
      <FlexControlledForm
        key={`${isLoading}`}
        fields={infoFields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeContributionValues}
        isModalType
      />
      {isLoading ? (
        <Box justify="center" align="center" style={{ position: 'absolute', opacity: '0.2' }} height="100%" width="100%">
          <Preloader />
        </Box>
      ) : null}
    </Box>
  );
};
