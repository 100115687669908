import { useEffect } from 'react';

import { useBreadcrumbsStore } from '@/modules/core/store';
import { Breadcrumb, BreadcrumbOptions } from '@/modules/core/store/useBreadcrumbs.store';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useBreadcrumbs = (
  breadcrumbs: Omit<Breadcrumb, 'id'>[],
  deps: any[] = [],
  options?: Partial<BreadcrumbOptions>,
  isExpressSetupMode?: boolean,
) => {
  const { setControlledBreadcrumbs, handleSetOptions } = useBreadcrumbsStore(isExpressSetupMode);
  return useEffect(() => {
    setControlledBreadcrumbs(breadcrumbs);
    handleSetOptions(options);
    return () => {
      setControlledBreadcrumbs([]);
      handleSetOptions({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
