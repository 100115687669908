import { CIPReportResponse,ReportItemData } from '@/modules/employer/components/Reports/report.types';

export const generateCIPReport = (
  data?: CIPReportResponse,
): ReportItemData[] => (data ? [
  {
    id: 'total_processed_cip',
    title: 'Total Processed',
    value: data?.total_processed,
  },
  {
    id: 'total_pending_cip',
    title: 'Total Pending',
    value: data?.total_pending,
  },
  {
    id: 'total_waiting_on_response_cip',
    title: 'Total Waiting on Response',
    value: data?.total_waiting_on_response,
  },
  {
    id: 'total_passed_cip',
    title: 'Total Passed',
    value: data?.total_passed,
  },
  {
    id: 'total_additional_verification_required_cip',
    title: 'Total Additional Verification Required',
    value: data?.total_additional_verification_required,
  },
  {
    id: 'total_failed_cip',
    title: 'Total Failed',
    value: data?.total_failed,
  },
  {
    id: 'total_passed_prc_cip',
    title: '% Passed (based on total processed and total passed)',
    value: `${data?.passed_share || 0}%`,
  },
] : []);
