import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Box,
  Heading, ToggleButton, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { HealthPlansType } from '@/modules/HealthPlan/HealthPlan.types';

export const StyledHeading = styled(Heading)`
  transition: .2s;
`;

interface Props {
  onClick: (name: HealthPlansType) => void;
  name: HealthPlansType;
  currPlan: HealthPlansType;
  text: string;
  link?: string;
  testId?: string;
}

const SelectButton: React.FC<Props> = ({
  name, currPlan, text, onClick, link, testId = '',
}) => (
  <HashLink
    to={`${link || ''}#${name}`}
    style={{
      textDecoration: 'none',
    }}
  >
    <Box data-testid={`${testId}_link`} align="center" height={{ max: '40px' }}>
      <ToggleButton
        active={currPlan === name}
        onClick={() => onClick(name)}
      >

        {text}

      </ToggleButton>
    </Box>
  </HashLink>

);

export default SelectButton;
