import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ListItemBase } from '@/common/types';

const Delimiter = styled(Box)`
  width: ${({ theme }) => theme.spacings.spacing32};
`;

interface Props {
  items: ListItemBase[];
  hasOption?: boolean;
  style?: React.CSSProperties;
}

const TransactionsListHeader: React.FC<Props> = ({ items = [], hasOption = false, style }) => (
  <Box
    background="legend"
    round="checkboxRound"
    direction="row"
    margin={{ bottom: 'spacing8' }}
    pad={{ vertical: 'spacing8', right: 'spacing24', left: 'spacing16' }}
    style={style}
  >
    {items.map((item) => (
      <Box
        style={{ flex: item.flex }}
        key={item.key}
      >
        <Text size="small" weight="bold">{item.title}</Text>
      </Box>
    ))}
    {hasOption && <Delimiter />}
  </Box>
);

export default TransactionsListHeader;
