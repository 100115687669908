import React, { CSSProperties } from 'react';
import { ListItemField } from '@common-fe/common-fe';


export type OptionKey = string | number | undefined;
export interface Option<V = string | number | object | boolean> {
  key: OptionKey;
  name?: string,
  value?: V;
  displayedValue?: V;
  title?: string;
  additionalValue?: string | number | object | boolean;
  divide?: boolean;
  textStyle?: CSSProperties;
  node?: React.ReactNode;
}
export interface NodeOption extends Option {
  node?: React.ReactNode;
}
export interface ValuesMap<T> {
  [key: string]: T
}

export interface HierarchyOption extends Option {
  type?: string;
}

export interface File {
  path: string;
  preview: string;
  name: string;
  type: string;
}

export interface State {
  code?: string;
  id?: string;
  name?: string
}

export interface Pagination<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  total: number;
  size: number;
  number: number;
  totalElements: number;
  totalPages: number;
}
export interface TableRowOption {
  name: string,
  onClick?: (id: string, title?: string) => void | Promise<void>;
  isAvailable?: (fields: ListItemField[], id: string) => boolean;
  isWarning?: boolean;
  optionStyle?: React.CSSProperties;
  hasNewBlankIcon?: boolean;
}

export interface RadioOption {
  label: string | number;
  value?: string | number;
}

export interface ListItemBase {
  key?: string;
  title: string;
  titleLength?: number;
  flex?: number | string;
  onClick?: () => void;
  endAlign?: boolean;
  style?: React.CSSProperties;
  node?: React.ReactNode;
  sticky?: boolean;
  width?: number;
}

export enum ListItemType {
  BankLogo = 'bank-logo',
  ErLogo = 'er-logo',
  Contact = 'contact',
  Text = 'text',
  Status = 'status',
  Info = 'info',
  Link = 'link',
  Node = 'node',
  Avatar = 'avatar',
}


export enum DefaultSortTypesEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

// PaginationSortParam need to extend type of sort parameter
export type PaginationSortParam = DefaultSortTypesEnum;

export interface PaginationParams {
  perPage: number;
  page: number;
  searchString?: string;
  status?: OptionKey;
  sort?: string;
  sortBy?: PaginationSortParam;
}
export interface DefaultValues {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type FixedSizeArray<N extends number, T> = N extends 0 ? never[] : {
  0: T;
  length: N;
} & ReadonlyArray<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export interface DefaultFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface FileUrls {
  id: number;
  s3_url: string;
}
export interface DocumentResponse {
  document_id: number;
  files: FileUrls[];
}

export enum DocumentTypeEnum {
  PDF = 'pdf',
  JPG = 'jpg',
  PNG = 'png',
  JPEG = 'jpeg',
  TIF = 'tif',
  TXT = 'txt',
  CSV = 'csv',
}

export interface Document {
  blob: string;
  link: string;
  id: string;
  type: DocumentTypeEnum;
}

export interface LogoInfoPayload {
  favicon_url?: string;
  large_logo_url?: string;
  minimized_logo_url?: string;
  dark_large_logo_url?: string;
  dark_minimized_logo_url?: string;
}

export interface Country {
  alpha2: string;
  alpha3: string;
  name: string;
}

export interface Address {
  id?: string;
  city?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string; 
  zipcode?: string;
  state?: State;
  country?: Country;
  validated?: boolean;
}
