 
import React, {
  useCallback, useMemo,
} from 'react';
import {
  AppButton,
  Box,
  Inscription,
  WarningIcon,
} from '@common-fe/common-fe';

import { BlockWrapper } from '@/components';
import { useCancelEmployerTerminationModalStore, useEmployerTerminationModalStore } from '@/modules/employer/components/EmployerTermination/store';
import { EmployerStatus } from '@/modules/employer/employer.constants';
  
  interface Props {
    employerId?: string;
    employerName?: string;
    employerStatus?: EmployerStatus;
    terminationDate?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalAction?: (value?: any) => void;
  }

const DangerZone: React.FC<Props> = ({
  employerStatus,
  employerName,
  employerId,
  terminationDate,
  additionalAction,
}) => {
  const handleChangeVisibility = useEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const handleSetEmployerId = useEmployerTerminationModalStore((state) => state.handleSetEmployerId);
  const handleSetEmployerName = useEmployerTerminationModalStore((state) => state.handleSetEmployerName);
  const handleSetAdditionalAction = useEmployerTerminationModalStore((state) => state.handleSetAdditionalAction);
  const handleSetTerminationDate = useEmployerTerminationModalStore((state) => state.handleSetTerminationDate);
  const handleSetEditMode = useEmployerTerminationModalStore((state) => state.handleSetEditMode);

  const handleChangeVisibilityCancel = useCancelEmployerTerminationModalStore((state) => state.handleChangeVisibility);

  const currentTitle = useMemo(() => {
    if (employerStatus === EmployerStatus.PendingTermination || terminationDate) {
      return 'Pending Termination';
    }
    return `Terminate ${employerName || 'Employer'}`;
  }, [employerName, employerStatus, terminationDate]);

  const description = useMemo(() => {
    if (terminationDate || employerStatus === EmployerStatus.PendingTermination) {
      return `Terminate on ${terminationDate}`;
    }

    return undefined;
  }, [employerStatus, terminationDate]);


  const handleTerminateEmployer = useCallback(() => {
    handleSetEmployerName(employerName);
    handleSetEmployerId(employerId ? `${employerId}` : undefined);
    handleSetAdditionalAction(additionalAction);
    handleChangeVisibility(true);
    if (terminationDate) {
      handleSetTerminationDate(terminationDate);
      handleSetEditMode(true);
    }
  }, [
    handleSetEmployerName,
    employerName,
    handleSetEmployerId,
    employerId,
    handleSetAdditionalAction,
    additionalAction,
    handleChangeVisibility,
    terminationDate,
    handleSetTerminationDate,
    handleSetEditMode,
  ]);

  const handleCancelTermination = useCallback(() => {
    handleChangeVisibilityCancel(true);
    handleSetEmployerName(employerName);
    handleSetEmployerId(employerId ? `${employerId}` : undefined);
    handleSetAdditionalAction(additionalAction);
  }, [
    employerId,
    employerName,
    handleChangeVisibilityCancel,
    handleSetEmployerId,
    handleSetEmployerName,
    additionalAction,
    handleSetAdditionalAction,
  ]);

  return (
    <BlockWrapper
      moduleAnchorId="danger_zone"
      title="Danger Zone"
      icon={<WarningIcon />}
      blockIconColor="warning"
    >
      <Box direction="row" background={{ color: 'module' }}>
  
        <Box
          data-testid="EmployerTermination_DangerZone_container"
          fill
          align="center"
          pad="spacing24"
          round="container1Round"
          direction="column"
          elevation="default"
          background="canvas"
          margin={{ right: 'spacing8' }}
          style={{ position: 'relative' }}
        >

          <Inscription size="2xl" color="textBody" weight={700}>
            {currentTitle}
          </Inscription>
          {description ? (
            <Box margin={{ top: 'spacing12' }} direction="row">
              <Inscription color="textBody">
                {description}
              </Inscription>

              <Box
                margin={{ left: 'spacing8' }}
                onClick={handleTerminateEmployer}
                style={{ cursor: 'pointer' }}
              >
                <Inscription color="textAccent">
                  View Details
                </Inscription>
              </Box>
            </Box>
          ) : null}

          <Box
            width="100%"
            margin={{ top: 'spacing24' }}
            direction="row"
            gap="spacing12"
          >
  
            {employerStatus === EmployerStatus.PendingTermination || terminationDate ? (
              <Box fill>
                <AppButton
                  testId="cancel-termination"
                  // color="red"
                  buttonType="secondary"
                  size="L"
                  onClick={handleCancelTermination}
                  width="100%"
                >
                  Cancel Termination
                </AppButton>
              </Box>
            ) : null}
            <Box fill>
              <AppButton
                testId="start-termination"
                color={employerStatus === EmployerStatus.PendingTermination || terminationDate ? 'blue' : 'red'}
                size="L"
                onClick={handleTerminateEmployer}
                width="100%"
              >
                {employerStatus === EmployerStatus.PendingTermination || terminationDate ? 'Edit' : 'Terminate'}
              </AppButton>
            </Box>

          </Box>
        </Box>
        <Box fill margin={{ left: 'spacing8' }} />
      </Box>
  
    </BlockWrapper>
  );
};
  
export default DangerZone;
  