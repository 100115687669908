// /payout-definitions
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { formatSnakeCaseToGeneral } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option, Pagination, PaginationParams } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { ROOT_ORGANIZATION } from '@/modules/ProcessingDefinition/ProcessingDefinition.constants';
import { PayoutDefinition, PayoutDefinitionResponse } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

export const QUERY_KEY = 'GET_PAYOUT_DEFINITIONS';
export default (
  params?: PaginationParams,
  page?: number,
  name?: string,
  id?: string,
) => {
  const { observingOrganization } = useCurrentOrganization();
  const { data, isLoading } = useQuery(
    [QUERY_KEY, page, observingOrganization.path, params, name, id],
    () => api.get<Pagination<PayoutDefinitionResponse>>(
      PATHS.PAYOUT_DEFINITION, {
        params: {
          organization_path: observingOrganization.path || ROOT_ORGANIZATION,
          size: params?.perPage || 50,
          page: page || (params?.page ? params.page - 1 : 0),
          name: name || params?.searchString,
          sort: params?.sort,
          id,
        },
      },
    ),
  );
  const fieldOptions = useMemo<Option[]>(() => {
    const list = data?.data.content || [];
    return list.map((item) => ({
      key: `${item.id}`,
      value: `${item.id}`,
      title: item.name,
    }));
  }, [data]);
  const formatedData = useMemo<PayoutDefinition[]>(
    () => (data?.data.content || [])
      .map((item) => ({
        id: `${item.id}`,
        name: item.name,
        categoryTypesStrings: item?.categories
          ?.map((el) => formatSnakeCaseToGeneral(el?.category_type)),
      })),
    [data],
  );
  return {
    fieldOptions,
    formatedData,
    isLoading,
    page: data?.data?.number,
    total: data?.data.totalElements || 0,
    totalPages: data?.data.totalPages || 0,
  };
};
