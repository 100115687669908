import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ClaimsTeamConfig, ClaimsTeamConfigPayload, TeamPayload } from '@/modules/employer/components/Claims/ClaimsProcessors/ClaimsProcessors.types';

const formatDataToPayload = (data: ClaimsTeamConfig): ClaimsTeamConfigPayload => ({
  name: data.name,
  allowed_account_types: data.allowedAccountTypes,
  assigned_organizations_ids: data?.assignedOrganizationIds ? data.assignedOrganizationIds : [],
});

const useUpdateClaimsTeamQuery = (claimsTeamId?: string | number) => {

  const { mutateAsync, isLoading } = useMutation(
    (value: ClaimsTeamConfig) => api.put<TeamPayload>(
      `${PATHS.CLAIMS_TEAMS}/${claimsTeamId}`,
      formatDataToPayload(value),
    )
  );

  const handleUpdate = useCallback(async (value: ClaimsTeamConfig) => {
    const data = await mutateAsync(value as ClaimsTeamConfig);
    const updatedTeam = data?.data;
    return updatedTeam;
  }, [mutateAsync]);

  return {
    isLoading,
    update: handleUpdate,
  };
};

export default useUpdateClaimsTeamQuery;