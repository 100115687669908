import {  useQuery, useQueryClient } from 'react-query';

import { api, } from '@/api';
import { PATHS } from '@/common';
import { GET_CIP_QUERY_KEY } from '@/modules/employee/Employee/cip/queries/useCIPInfo.query';

export enum CipVerificationStatus{
  Success=  'PASS',
  Pending = 'PENDING',
  Error=  'FAIL'
}

interface CipResponse {
  status: CipVerificationStatus 
}


const QUERY_KEY = 'GET_CIP_VERIFICATION_STATUS';
export default (employeeId?: string) => {

  const queryClient = useQueryClient();
  const {
    refetch: handleCheck, isLoading,
    isError,
    
  } = useQuery(
    [QUERY_KEY], () => api.get<CipResponse>(PATHS.CIP_DOCUMENT_SCAN_CHECK(), {
      params: {
        employee_id: employeeId
      }
    }),
    {
    
      enabled: false,
      onSuccess:() => {
        queryClient.invalidateQueries(GET_CIP_QUERY_KEY);
      }
    }
  );

  return {
    handleCheck,
    isLoading,
    isError
  };
};