import { storeCreator } from '@/utils/store/store.utils';
export interface EmailNotificationTemplate {
  id?: string;
  notificationName?: string;
  templateName?: string;
  category?: string;
  destination?: string;
  notificationType?: string;
  propertyName?: string;
}

interface State {
  modalVisible: boolean;
  emailNotificationTemplate?: EmailNotificationTemplate;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalAction?: (value?: any) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleSetEmailNotificationTemplate: (value?: EmailNotificationTemplate) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSetAdditionalAction: (value?: (value?: any) => void) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  emailNotificationTemplate: undefined,
  additionalAction: undefined,

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetEmailNotificationTemplate: (value) => set((state) => ({
    ...state,
    emailNotificationTemplate: value,
  })),

  handleSetAdditionalAction: (value) => set((state) => ({
    ...state,
    additionalAction: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    modalVisible: false,
    emailNotificationTemplate: undefined,
    additionalAction: undefined,
  })),
}));