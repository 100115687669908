import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
  OptionKey,
  SelectDropdown,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';

import MultipleOrganizationSelector from '../../AddClaimProcessorAndTeamButton/MultipleOrganizationSelector';
import { ClaimsTeam } from '../../ClaimsProcessors.types';

const RequiredLabel: React.FC<{ title: string; }> = ({ title }) => (
  <Box margin={{ top: 'spacing8' }} direction="row">
    <Inscription
      color="danger"
      margin={{
        right: '3px',
        top: '1px',
      }}
    >
      *
    </Inscription>
    <Inscription>{title}</Inscription>
  </Box>
);

export const ALL_KEY = 'All';

export enum AccountTypes {
  HRA = 'HRA',
  DCAP = 'DCAP',
  PARKING = 'PARKING',
  TRANSIT = 'TRANSIT',
  ADOPTION = 'ADOPTION',
  // LIFESTYLE = 'LIFESTYLE',
  WELLNESS = 'WELLNESS',
  SPECIALTY = 'SPECIALTY',
  TRAVEL = 'TRAVEL',
  HSA = 'HSA',
  HCFSA = 'HCFSA',
}

export const ACCOUNT_TYPES_OPTIONS = [
  { key: AccountTypes.HSA, value: AccountTypes.HSA, title: 'Health Savings Account' },
  { key: AccountTypes.HCFSA, value: AccountTypes.HCFSA, title: 'Health Care Flexible Spending Account' },
  { key: AccountTypes.HRA, value: AccountTypes.HRA, title: 'Health Reimbursement Arrangement' },
  { key: AccountTypes.DCAP, value: AccountTypes.DCAP, title: 'Dependent Care Assistance Program' },
  { key: AccountTypes.PARKING, value: AccountTypes.PARKING, title: 'Parking' },
  { key: AccountTypes.TRANSIT, value: AccountTypes.TRANSIT, title: 'Transit' },
  { key: AccountTypes.ADOPTION, value: AccountTypes.ADOPTION, title: 'Adoption' },
  // { key: AccountTypes.LIFESTYLE, value: AccountTypes.LIFESTYLE, title: 'Lifestyle' },
  { key: AccountTypes.WELLNESS, value: AccountTypes.WELLNESS, title: 'Wellness' },
  { key: AccountTypes.SPECIALTY, value: AccountTypes.SPECIALTY, title: 'Specialty' },
  { key: AccountTypes.TRAVEL, value: AccountTypes.TRAVEL, title: 'Travel Reimbursement' }
];

const OPTIONS = [
  {
    key: ALL_KEY,
    value: ALL_KEY,
    title: `All types (${ACCOUNT_TYPES_OPTIONS.length})`,
    divide: true,
  },
  ...ACCOUNT_TYPES_OPTIONS,
];


interface Props {
  setOrganizationsIds: (value: string[]) => void;
  setAccountTypes: (value: OptionKey[]) => void;
  accountTypesValues: OptionKey[];
  editableTeam?: ClaimsTeam;
}

export const useCreateClaimsTeamFields = (props: Props) => {
  const {
    setOrganizationsIds,
    setAccountTypes,
    accountTypesValues,
    editableTeam,
  } = props;

  const fields: Field[] = useMemo(() => [
    {
      name: 'teamName',
      type: FieldTypes.Text,
      showRequireIcon: true,
      label: 'Team name',
      placeholder: 'Team name',
      defaultValue: editableTeam?.name || '',
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: 'assignmentToOrganization',
      placeholder: 'None',
      type: FieldTypes.Node,
      label: (
        <Box margin={{ top: 'spacing8' }} direction="row">
          Assignment to organization
        </Box>
      ),
      value: <MultipleOrganizationSelector currentTeamId={editableTeam?.id} onChange={setOrganizationsIds} currentOrganizations={editableTeam?.assignedOrganizations} />,
      // @ts-ignore
      isNarrowLabelMode: true,
    },
    {
      name: 'accountTypes',
      type: FieldTypes.Node,
      label: <RequiredLabel title="Account types" />,
      placeholder: 'None',
      value: (                
        <Box width="100%">
          <SelectDropdown
            id="account-types-dropdown"
            testId="account-types-dropdown"
            ellipsisMode
            name="Activity status"
            options={OPTIONS}
            values={accountTypesValues}
            onChangeValues={setAccountTypes}
            placeholder='None'
          />
        </Box>
      ),
    },
  ], [accountTypesValues, editableTeam, setAccountTypes, setOrganizationsIds]);

  return fields;
};
