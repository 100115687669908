import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  AppButton,
  Box,
  FlexControlledForm,
  Preloader,
  Text,
} from '@common-fe/common-fe';

import { usePreferredMailingAdressStore } from '@/modules/employee/Employee/PersonalInformation/PreferredMailingAddress/stores';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import theme from '@/styles/theme';

import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

import useCardAddressModalFields from './hooks/useCardAddressModalFields';
import useCardReplacementQuery, { ReplacementCardData } from './hooks/useCardReplacement.query';
import useCardReplacementReasonFields from './hooks/useCardReplacementReasonFields';

const EMPTY_VALUE = '-';

interface Props {
  onCancel?: (value: boolean) => void;
  setDoneMode?: () => void;
  loading?: boolean;
}

const CardReplaceFormModal: React.FC<Props> = ({ onCancel, setDoneMode, loading }) => {
  const [cardSetupReason, setCardSetupReason] = useState(false);
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { refetch } = useGetCardsQuery(employeeId);
  const [isSubmit, setSubmit] = useState(false);
  const { onSave, isLoading } = useCardReplacementQuery();
  const defaultFields = usePreferredMailingAdressStore((state) => state.sourceState);

  const currentAddressParams = useMemo(() => ({
    STREET_ADDRESS_1: defaultFields?.firstAddressLine || EMPTY_VALUE,
    STREET_ADDRESS_2: defaultFields?.secondAddressLine || EMPTY_VALUE,
    CITY: defaultFields?.city || EMPTY_VALUE,
    STATE: defaultFields?.state || EMPTY_VALUE,
    ZIP_CODE: defaultFields?.zipCode || EMPTY_VALUE,
  }), [defaultFields]);

  const addressFields = useCardAddressModalFields({
    defaultFields: currentAddressParams,
  });

  const {
    state:
      {
        cardID,
        cardStatus,
        mailedDate,
        activationDate,
      },
    handleReset,
  } = useCardReplaceModalStore();
  const replacementFields = useCardReplacementReasonFields({
    cardStatus,
    mailedDate,
    activationDate,
  });

  const showError = useMemo(
    () => isSubmit && (!cardSetupReason),
    [cardSetupReason, isSubmit],
  );

  const [formValues, setFormValues] = useState<ReplacementCardData>({
    reason: '',
  });

  const handleChangeReasonValues = useCallback((values: ReplacementCardData) => {
    const newValues = { ...values };
    const filledObj = Object.values(newValues).every(Boolean);
    setFormValues((prev) => ({ ...prev, ...values }));
    if (filledObj) {
      setCardSetupReason(true);
    } else setCardSetupReason(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    if (cardSetupReason && setDoneMode) {
      await onSave({
        cardID,
        data: formValues,
      });
      refetch();
      setDoneMode();
    }
  }, [cardSetupReason, setDoneMode, onSave, cardID, formValues, refetch]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
      handleReset();
    }
  }, [handleReset, onCancel]);

  return (
    <Box>
      <Box direction="column">
        <Box round="container1Round" pad="spacing24" background="module">
          <Box data-testid="ReplacementReasonForm" round="container1Round" background="canvas">
            <FlexControlledForm
              // formTitle="Why you need a new card?"
              fields={replacementFields}
              editMode
              onChangeValues={handleChangeReasonValues}
              showError={showError}
              isModalType
            />
          </Box>
          {cardSetupReason
            && (
              <Box
                data-testid="ReplacementAddressForm"
                round="container1Round"
                background="canvas"
                margin={{ top: 'spacing24' }}
              >
                <FlexControlledForm
                  formTitle="Your Card Will Be Sent To"
                  subTitle={(
                    <Box margin={{ top: 'spacing4', bottom: '20px' }}>
                      <Text
                        size="12px"
                        weight={500}
                        color="textSecondary"
                      >
                        If this address is incorrect, please update with your employer.
                      </Text>
                    </Box>
                  )}
                  fields={addressFields}
                  editMode
                  onChangeValues={() => null}
                  isModalType
                />
              </Box>
            )}
        </Box>
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: showError ? 'spacing12' : 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
      >
        <Box width="control" margin={{ right: 'spacing8' }}>
          <AppButton
            type="button"
            buttonType="secondary"
            onClick={handleCancel}
            width="100%"
            containerStyle={{ marginRight: theme.spacings.spacing8 }}
          >
            Cancel
          </AppButton>
        </Box>
        <Box width="control">
          <AppButton
            disabled={loading || isLoading}
            width="100%"
            onClick={handleSubmit}
          >
            {loading || isLoading ? <Preloader color="white" /> : 'Submit'}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CardReplaceFormModal;
