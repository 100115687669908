import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { baseStoreCreator } from '@/utils/store';

export interface HealthPlanNamePayload {
  healthPlanName: string;
  healthPlanStatus: HealthPlanStatuses;
  healthPlanTypes: string[];
  carrierName: string;
  carrierId: string;
  autocreateCdhEnrollment: string;
  parentTemplate: string;
  planYearId: string;
  planCode: string,
  plans: string[];
  planSummary: string;
}

export const DEFAULT_VALUES: HealthPlanNamePayload = {
  healthPlanName: '',
  healthPlanStatus: HealthPlanStatuses.DRAFT,
  healthPlanTypes: [],
  carrierName: '',
  carrierId: '',
  autocreateCdhEnrollment: '',
  parentTemplate: '',
  planYearId: '',
  planCode: '',
  plans: [],
  planSummary: '',
};

export const useHealthPlanNameStore = baseStoreCreator<HealthPlanNamePayload>(DEFAULT_VALUES);

export default useHealthPlanNameStore;
