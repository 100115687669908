import apprizeTheme from './apprize';
import ripplingTheme from './rippling';
import rmrTheme from './rmr';
import { WhiteLabelTheme } from './types';

const QUERY_KEY = 'whiteLabeling';

const { search } = window.location;
const envVariable =  import.meta.env.REACT_APP_WHITE_LABEL;
const query = new URLSearchParams(search).get(QUERY_KEY);
export const THEMES = { 
  rmr: 'admin-rmr',
  apprize: 'admin-apprize',
  rippling: 'admin-rippling',
};
const getTheme = () => {
  let currentTheme: WhiteLabelTheme | null = null;
  let  key = '';
  if (query === THEMES.rmr || envVariable === THEMES.rmr || sessionStorage.getItem('admin-rmr')) {
    currentTheme = rmrTheme;
    key = THEMES.rmr;
    sessionStorage.setItem('admin-rmr', 'true');
  }
  if (query === THEMES.apprize || envVariable === THEMES.apprize || sessionStorage.getItem('admin-apprize')) {
    currentTheme = apprizeTheme;
    key = THEMES.apprize;
    sessionStorage.setItem('admin-apprize', 'true');
  }
  if (query === THEMES.rippling || envVariable === THEMES.rippling || sessionStorage.getItem('admin-rippling')) {
    currentTheme = ripplingTheme;
    key = THEMES.rippling;
    sessionStorage.setItem('admin-rippling', 'true');
  }
  if (!currentTheme) {
    return {} as WhiteLabelTheme;
  }

  if (currentTheme.title) {
    document.title = currentTheme.title;
  }
  return {
    key: envVariable || query || key,
    title: currentTheme.title?.toLowerCase(),
    loginScreenTitle: currentTheme?.loginScreenTitle,
    colors: currentTheme.colors || {},
    fonts: currentTheme?.fonts || {},
    logo: currentTheme.logo,
    minifyLogo: currentTheme.minifyLogo,
    images: currentTheme.images || {},
    font: currentTheme.font, // Alse you need add font import to global.scss example: @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
    titleFont: currentTheme.titleFont,
    rounds: currentTheme.rounds || {},
    favicon: currentTheme.favicon || '', // Save this icon, for example 'fidelityFavicon.ico', in public folder, here put the path to this icon file.
    favicon16: currentTheme.favicon16 || '', // Save this icon, for example 'favicon-fidelity-16x16.png', in public folder, here put the path to this icon file.
    favicon32: currentTheme.favicon32 || '', // Save this icon, for example 'favicon-fidelity-32x32.png', in public folder, here put the path to this icon file.
    faviconAppleTouch: currentTheme.faviconAppleTouch || '', // Save this icon, for example 'apple-touch-icon-fidelity.png', in public folder, here put the path to this icon file.
    shadows: currentTheme.shadows || {},
  } as WhiteLabelTheme;
};

export default getTheme();
