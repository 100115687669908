import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  ErrorModal,
  usePaginationParams,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  OrganizationTypes,
  SERVER_ERROR_MODAL_TEXT,
  SERVER_ERROR_MODAL_TITLE,
} from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access, Topbar } from '@/modules/core/components';
import { useCurrentOrganization, useHistory } from '@/modules/core/hooks';
import { usePlanSectionsScroll } from '@/modules/plan/hooks';
import { PLANS_WRAPPER_UI_ID } from '@/modules/plan/hooks/usePlanSectionsScroll';
import {
  PlanCardAction,
  PlanStatuses, PlansType,
} from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import { useScrollStore } from '../stores';

import CreatePlanModal from './CreatePlanModal';
import CreateTemplateModal from './CreateTemplateModal';
import { usePlansList, useTemplatesList } from './hooks';
import PlansBlock from './PlansBlock';
import PlansToggle from './PlansToggle';
import { useForceOpenModalStore } from './stores';

export const Content = styled.div`
  position: relative;
`;

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 110;
  background-color: ${() => theme.colors.canvas};
`;

export const START_SCROLL_LOCIG_POINT = 100;
export const FINISH_SCROLL_LOCIG_POINT = 40;
export const HEIGHT_FIXED_HEADER = 56;

const PlansList: React.FC = () => {
  const {
    searchString: searchTemplateString,
    setSearchString: setSearchTemplateString,
    currentSearch: currentTemplateSearch,
  } = usePaginationParams();

  const {
    searchString: searchPlanString,
    setSearchString: setSearchPlanString,
    currentSearch: currentPlanSearch,
  } = usePaginationParams();
  const {
    count: plansCount,
    readyForSetupPlans,
    activePlans,
    draftPlans,
    listActivePlans,
    listDraftPlans,
    listReadyForSetupPlans,
    isServerError: isPlansServerError,
    setIsServerError: setIsPlansServerError,
    currentRefetch: currentRefetchPlans,
    isDraftPlansLoading,
    isActivePlansLoading,
    isReadyForSetupPlansLoading,
  } = usePlansList(currentPlanSearch);
  const { handleReset } = useScrollStore();
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();

  const { searchQuery, hash } = useHistory();

  const isSystem = useMemo(
    () => type === OrganizationTypes.system,
    [type],
  );
  const isPartner = useMemo(
    () => type === OrganizationTypes.partner,
    [type],
  );

  const isDistributor = useMemo(
    () => type === OrganizationTypes.distributor,
    [type],
  );

  const isEmployer = useMemo(
    () => type === OrganizationTypes.employer || type === OrganizationTypes.company,
    [type],
  );

  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  const [isScrolled, setIsScrolled] = useState(false);
  const isPlansHidden = useMemo(() => isSystem || isPartner || isDistributor || isSubsidiary, [isPartner, isSystem, isDistributor, isSubsidiary]);
  const planRedirectPath = useMemo(
    () => {
      if (isPlansHidden) {
        return PlansType.Template;
      }
      return (hash as PlansType || PlansType.Plan);
    },
    [hash, isPlansHidden],
  );

  const [currentTab, setCurrentTab] = useState(planRedirectPath);
  useEffect(() => {
    if (isPlansHidden && currentTab === PlansType.Plan) {
      setCurrentTab(PlansType.Template);
    }
  }, [currentTab, isPlansHidden]);
  useEffect(() => {
    setCurrentTab(planRedirectPath);
  }, [planRedirectPath]);
  useEffect(() => {
    const appContainer = document.getElementById(PLANS_WRAPPER_UI_ID);
    const updateScrolling = () => {
      if (appContainer && appContainer.scrollTop >= START_SCROLL_LOCIG_POINT) setIsScrolled(true);
      if (appContainer && appContainer.scrollTop < 40) setIsScrolled(false);
    };

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      handleReset();
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const {
    count: templatesCount,
    inactiveTemplates, activeTemplates, allDraftTemplates,
    listActiveTemplates, allListDraftTemplates, listInactiveTemplates,
    isActiveTemplatesLoading, isDraftTemplatesLoading, isInactiveTemplatesLoading,
  } = useTemplatesList(currentTemplateSearch);

  const { scrollToSection, onScrollHandler } = usePlanSectionsScroll(
    currentTab === PlansType.Template
      ? [PlanStatuses.DRAFT, PlanStatuses.ACTIVE, PlanStatuses.INACTIVE]
      : [PlanStatuses.READY_FOR_SETUP, PlanStatuses.DRAFT, PlanStatuses.ACTIVE],
  );

  const handleSetModalVisible = useForceOpenModalStore((state) => state.setState);
  useEffect(() => () => {
    handleReset();
  }, [handleReset]);
  const actionTypeState = useForceOpenModalStore((state) => state.state.actionType);
  useEffect(() => {
    if (actionTypeState) {
      if (actionTypeState === PlanCardAction.createPlanFromTemplate
        && currentTab !== PlansType.Plan) {
        setCurrentTab(PlansType.Plan);
      } else if (actionTypeState === PlanCardAction.createTemplateFromTemplate
        && currentTab !== PlansType.Template) {
        setCurrentTab(PlansType.Template);
      }
    }
  }, [actionTypeState, currentTab]);

  const handleSelectAction = useCallback((value: PlanCardAction, id: string) => {
    handleSetModalVisible(value, id);
  }, [handleSetModalVisible]);

  return (
    <Box
      width="100%"
      overflow="auto"
      onScroll={onScrollHandler}
      id={PLANS_WRAPPER_UI_ID}
      pad={{ bottom: 'xxxl' }}
    >
      <Box
        flex="grow"
      >
        <Topbar />
        <FixedWrapper>
          <Box
            width="100%"
            alignSelf="center"
            align="center"
            pad={{ horizontal: '40px' }}
            margin={{ vertical: isScrolled ? '0' : 'l' }}
            border={isScrolled && { size: 'small', color: 'border1', side: 'bottom' }}
          >
            <PlansToggle
              link={searchQuery}
              currentTab={currentTab}
              onSetCurrentTab={setCurrentTab}
              setSearchString={currentTab === PlansType.Plan
                ? setSearchPlanString : setSearchTemplateString}
              searchString={currentTab === PlansType.Plan ? searchPlanString : searchTemplateString}
              clearSearchValue={() => {
                setSearchPlanString('');
                setSearchTemplateString('');
              }}
              plansCount={plansCount}
              hidePlans={isPlansHidden}
              templatesCount={templatesCount}
              isScrolled={isScrolled}
              isEmployer={isEmployer}
            >
              {/* {(observingEmployer?.id || observingPartner?.id) && ( */}
              {!isSubsidiary && (
                <>
                  <Access
                    accessRequirements={[
                      {
                        permission: Permissions.PLAN_SETUP,
                      },
                    ]}
                  >
                    <CreatePlanModal hide={currentTab !== PlansType.Plan} />
                  </Access>
                  <Access
                    accessRequirements={[
                      {
                        permission: Permissions.PLAN_TEMPLATE_SETUP,
                      },
                    ]}
                  >
                    <CreateTemplateModal hide={currentTab !== PlansType.Template} />
                  </Access>
                </>
              )}
            </PlansToggle>
          </Box>
        </FixedWrapper>
        <Box>
          <Box
            data-testid="plans-list"
            style={{ position: 'relative' }}
          >
            <Box
              width={theme.defaultContentWidth}
              alignSelf="center"
            >
              <Content>
                {currentTab === PlansType.Template ? (
                  <>
                    <PlansBlock
                      isTemplateMode
                      title="Draft"
                      plans={allDraftTemplates}
                      plansList={allListDraftTemplates}
                      status={PlanStatuses.DRAFT}
                      placeholder={searchTemplateString
                        ? 'No results have been found in Draft Templates.'
                        : 'No draft templates. Create a new plan template.'}
                      isRestoreButtonShow={!!searchTemplateString && !allDraftTemplates.length}
                      scrollToSection={scrollToSection}
                      isLoading={isDraftTemplatesLoading}
                      clearSearchValue={() => setSearchTemplateString('')}
                      searchValue={searchTemplateString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                    <PlansBlock
                      isTemplateMode
                      title="Active"
                      onSelectAction={handleSelectAction}
                      plans={activeTemplates}
                      plansList={listActiveTemplates}
                      status={PlanStatuses.ACTIVE}
                      isPlansHidden={isPlansHidden}
                      placeholder={searchTemplateString
                        ? 'No results have been found in Active Templates.'
                        : 'No Active Templates. Activate a plan template.'}
                      isRestoreButtonShow={!!searchTemplateString && !activeTemplates.length}
                      scrollToSection={scrollToSection}
                      isLoading={isActiveTemplatesLoading}
                      clearSearchValue={() => setSearchTemplateString('')}
                      searchValue={searchTemplateString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                    <PlansBlock
                      isTemplateMode
                      title="Inactive"
                      plans={inactiveTemplates}
                      plansList={listInactiveTemplates}
                      status={PlanStatuses.INACTIVE}
                      placeholder={searchTemplateString
                        ? 'No results have been found in Inactive Templates.'
                        : 'No inactive templates.'}
                      isRestoreButtonShow={!!searchTemplateString && !inactiveTemplates.length}
                      scrollToSection={scrollToSection}
                      isLoading={isInactiveTemplatesLoading}
                      clearSearchValue={() => setSearchTemplateString('')}
                      searchValue={searchTemplateString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                  </>
                ) : (
                  <>
                    <PlansBlock
                      title="Ready for Setup"
                      plans={readyForSetupPlans}
                      plansList={listReadyForSetupPlans}
                      status={PlanStatuses.READY_FOR_SETUP}
                      placeholder={searchPlanString
                        ? 'No results have been found in Ready for Setup Plans.'
                        : 'No plans ready for setup. Create a new plan.'}
                      isRestoreButtonShow={!!searchPlanString && !readyForSetupPlans.length}
                      scrollToSection={scrollToSection}
                      isLoading={isReadyForSetupPlansLoading}
                      clearSearchValue={() => setSearchPlanString('')}
                      searchValue={searchPlanString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                    <PlansBlock
                      title="Drafts"
                      plans={draftPlans}
                      plansList={listDraftPlans}
                      status={PlanStatuses.DRAFT}
                      placeholder={searchPlanString
                        ? 'No results have been found in Draft Plans.'
                        : 'No drafts. Save a draft.'}
                      isRestoreButtonShow={!!searchPlanString && !draftPlans.length}
                      scrollToSection={scrollToSection}
                      isLoading={isDraftPlansLoading}
                      clearSearchValue={() => setSearchPlanString('')}
                      searchValue={searchPlanString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                    <PlansBlock
                      title="Active Plans"
                      plans={activePlans}
                      plansList={listActivePlans}
                      status={PlanStatuses.ACTIVE}
                      placeholder={searchPlanString
                        ? 'No results have been found in Active Plans.'
                        : 'No active plans. Activate a plan.'}
                      isRestoreButtonShow={!!searchPlanString && !activePlans.length}
                      scrollToSection={scrollToSection}
                      isLoading={isActivePlansLoading}
                      clearSearchValue={() => setSearchPlanString('')}
                      searchValue={searchPlanString}
                      currentTab={currentTab}
                      isScrolled={isScrolled}
                    />
                  </>
                )}
              </Content>
            </Box>
          </Box>
          <ErrorModal
            visible={isPlansServerError}
            header={SERVER_ERROR_MODAL_TITLE}
            helpText={SERVER_ERROR_MODAL_TEXT}
            buttonText="Close"
            buttonTextTryAgain="Try again"
            onSetVisible={setIsPlansServerError}
            onSubmit={currentRefetchPlans}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PlansList;
