import { CardTypesEnum } from '@common-fe/common-fe';

import { AccountFundingPayload } from './PlanSetup/AccountFunding/hooks/useAccountFundingSetter';
import { CoveragePayload } from './PlanSetup/Coverage/hooks/useCoverageSetter';
import { PlanDefinitionPayload } from './PlanSetup/PlanDefinition/hooks/usePlanDefinitionSetter';
import { PlanStatuses } from './PlanSetup/PlanDefinition/PlanName/stores/usePlanName.store';

export { PlanStatuses } from './PlanSetup/PlanDefinition/PlanName/stores/usePlanName.store';

export enum PlanTypesEnum {
  HSA = 'HSA',
  HRA = 'HRA',
  ADOPTION = 'ADOPTION',
  PARKING = 'PARKING',
  SPECIALTY = 'SPECIALTY',
  TRANSIT = 'TRANSIT',
  LIFESTYLE = 'LIFESTYLE',
  WELLNESS = 'WELLNESS',
  HCFSA = 'HCFSA',
  DCAP = 'DCAP',
  TRAVEL = 'TRAVEL',
}

export enum PlanTypesLabel {
  PARKING = 'Parking',
  TRANSIT = 'Transit',
  SPECIALTY = 'Specialty',
  LIFESTYLE = 'Lifestyle',
  WELLNESS = 'Wellness',
  ADOPTION = 'Adoption',
  TRAVEL = 'Travel',
  HSA = 'HSA',
  HCFSA = 'HCFSA',
  HRA = 'HRA',
  DCAP = 'DCAP',
}

export enum PlanCardAction {
  createPlanFromTemplate = 'createPlanFromTemplate',
  createTemplateFromTemplate = 'createTemplateFromTemplate',
  viewPlan = 'viewPlan',
}
export enum PlansType {
  Plan = 'Plan',
  Template = 'Template',
}

export enum PlanCardMode {
  Plan = 'Plan',
  HealthPlan = 'HealthPlan',
}

// export type Plan = PlanNotDefault | PlanDefault;

export type PlanInfoType = PlanDefinitionPayload &
AccountFundingPayload &
CoveragePayload;

export enum PlanFieldState {
  Locked = 'LOCKED',
  Hidden = 'HIDDEN',
  Modifiable = 'MODIFIABLE',
  RequiredAtPlan = 'REQUIRED_AT_PLAN',
  RequiredAtAllLevels = 'REQUIRED_AT_ALL_LEVELS',
  NA = 'NA',
}

export interface ContributionRaw {
  'is_pre_tax': boolean;
  'pre_tax_memo':string;
  'is_post_tax':boolean;
  'post_tax_memo': string;
}

export enum ContributionState {
  NONE = 'NONE',
  EMPLOYER = 'EMPLOYER',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER_AND_EMPLOYEE = 'EMPLOYER_AND_EMPLOYEE',

}
export const CONTRIBUTION_TYPES_KEY = {
  [ContributionState.NONE]: 'none',
  [ContributionState.EMPLOYER_AND_EMPLOYEE]: 'employer and employee',
  [ContributionState.EMPLOYEE]: 'employee',
  [ContributionState.EMPLOYER]: 'employer',
};

export enum aiAdjudicationType {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  ORG_SETTINGS = 'ORG_SETTINGS',
}

export interface PlanResponse {
  'id': number | string;
  summary?: string;
  deletable?: boolean;
  'parent_id': number;
  'plan_year_id': number;
  'is_plan': boolean;
  'plan_code': string;
  'prior_plan_id': number;
  'prior_plan': Partial<{
    'id': number;
    'name': string;
  }>;
  'prior_plan_id_state': PlanFieldState;
  'organization_id'?: number;
  'plan_code_state'?: PlanFieldState;
  'name': Partial<{
    'name': string;
    'name_state': PlanFieldState;
  }>;
  'plan_year': Partial<{
    'id': number;
    'name': string;
    'valid_from': string;
    'valid_to': string;
    'prior_plan_year_id': number;
    'prior_plan_year': Partial<{
      'id': number;
      'name': string;
      'valid_from': string;
      'valid_to': string;
    }>;
  }>;
  'account_type': Partial<{
    'account_type': string;
    'account_type_state': PlanFieldState;
  }>;
  'plan_omnibus_account_id': number;
  'notional_payroll_account_id': number;
  'notional_funding_account_id': number;
  'plan_primary_config': Partial<{
    is_orphaned: Partial<{

      'is_orphaned': boolean;

      'is_orphaned_state': PlanFieldState
    }>,
    'spend_limit_type': Partial<{
      'spend_limit': number,
      'spend_limit_type': string;
      'irs_limit_type': string;
      'spend_limit_type_state': PlanFieldState
    }>,
    'spend_period_type': Partial<{
      'spend_period_type': string;
      'spend_period_type_state': PlanFieldState
    }>,

    'available_balance_type': Partial<{
      'available_balance_type': string;
      'available_balance_type_state': PlanFieldState;
    }>;
    'max_election_amount_type': Partial<{
      'max_election_amount': number;
      'irs_limit_type': string;
      'max_election_amount_type': string;
      'max_election_amount_type_state': PlanFieldState;
    }>;
    'min_election_amount_type': Partial<{
      'min_election_amount': number;
      'min_election_amount_type': string;
      'min_election_amount_type_state': PlanFieldState;
    }>;
    'is_carded': Partial<{
      'is_carded': boolean;
      'is_carded_state': PlanFieldState;
    }>;
    'reimbursement_payment_option_type': Partial<{
      payment_options_types: string;
      reimbursement_payment_options_type: string;
      reimbursement_payment_options_type_state: PlanFieldState;
    }>;
    'proof_of_expenses_type': Partial<{
      'proof_of_expenses_type': string;
      'proof_of_expenses_type_state': PlanFieldState;
    }>;
    'is_bill_pay': Partial<{
      'is_bill_pay': boolean;
      'is_bill_pay_state': PlanFieldState;
    }>;
    'is_cip_required': Partial<{
      'is_cip_required': boolean;
      'is_cip_required_state': PlanFieldState;
    }>;
    'cms_reporting_required': Partial<{
      'cms_reporting_required': boolean | null;
      'cms_reporting_required_state': PlanFieldState
    }>,
    'pend_contributions_for_cip': Partial<{
      'pend_contributions_for_cip': boolean;
      'pend_contributions_for_cip_state': PlanFieldState;
    }>;

    'omnibus_type': Partial<{
      'omnibus_type': string;
      'omnibus_type_state': PlanFieldState;
    }>;
    'is_replenished': Partial<{
      'is_replenished': boolean;
      'is_replenished_state': PlanFieldState;
    }>;
    'fund_id': Partial<{
      'fund_id': string | null;
      'fund_state_id': PlanFieldState;
    }>

    'fund_id_formula': Partial<{
      'fund_id_formula': string | null;
      'fund_id_formula_state': PlanFieldState;
    }>;
    'allow_investments': Partial<{
      'allow_investments': boolean | null;
      'allow_investments_state': PlanFieldState;
    }>
    'catch_up_election': Partial<{
      'catch_up_election': number | null;
      'catch_up_election_state': PlanFieldState;
    }>
    'catch_up_age': Partial<{
      'catch_up_age': number | null;
      'catch_up_age_state': PlanFieldState;
    }>
    'investment_options_type': Partial<{
      'investment_options_types': string[] | null;
      'investment_options_type_state': PlanFieldState;
    }>
    'investment_threshold': Partial<{
      'investment_threshold': number | null;
      'investment_threshold_state': PlanFieldState;
    }>
    'minimum_investment_amount': Partial<{
      'minimum_investment_amount': number | null;
      'minimum_investment_amount_state': PlanFieldState;
    }>
    'generate_statements': Partial<{
      'generate_statements': boolean;
      'generate_statements_state_id': PlanFieldState;
    }>
  }>;

  'plan_coverage_config':Partial<{

    'end_of_coverage_type': Partial<{
      'end_of_coverage_days_amount': number,
      'end_of_coverage_type': string,
      'end_of_coverage_state': PlanFieldState
    }>,
    'grace_period_type': Partial<{
      'grace_period_days_amount': number,
      'grace_period_type': string,
      'grace_period_type_state': PlanFieldState
    }>,
    'claims_deadline_end_of_coverage_type': Partial<{
      'claims_deadline_end_of_coverage_days_amount': number,
      'claims_deadline_end_of_coverage_type': string,
      'claims_deadline_end_of_coverage_state': PlanFieldState
    }>,
    'run_out_type' :Partial<{
      'run_out_days_amount': number,
      'run_out_type': string,
      'run_out_type_state': PlanFieldState
    }>,

    'is_pay_claims_absence' :Partial<{
      'is_pay_claims_absence': boolean,
      'is_pay_claims_absence_state': PlanFieldState
    }>,
    'is_pay_claims_outside_coverage' :Partial<{
      'is_pay_claims_outside_coverage': boolean,
      'is_pay_claims_outside_coverage_state': PlanFieldState
    }>,
    'ai_adjudication_type': Partial<{
      'ai_adjudication_type': aiAdjudicationType,
      'ai_adjudication_type_state': PlanFieldState,
    }>,
  }>,
  'plan_account_funding_config': Partial<{
    'account_creation': Partial<{
      'account_creation_type': string;
      'account_creation_type_state': PlanFieldState;
    }>;
    'interest_template_id': Partial<{
      'interest_template_id': number,
      'interest_template_id_state_id': PlanFieldState,
    }>
    'allow_contribution_config': Partial<{
      'employee': Partial<ContributionRaw>,
      'employer': Partial<ContributionRaw>,
      'type': ContributionState,
      'state': PlanFieldState,
    }>,
    'allow_individual_contributions': Partial<{
      'allow_individual_contributions': boolean;
      'allow_individual_contributions_state_id': PlanFieldState
    }>,
    'minimum_contribution_amount': Partial<{
      'minimum_contribution_amount': number;
      'minimum_contribution_amount_state_id': PlanFieldState;
    }>,
    'contribution_monitoring_required': Partial<{
      'contribution_monitoring_required': boolean;
      'contribution_monitoring_required_state': PlanFieldState;
    }>;
    'is_allow_contributions_over_election_amount': Partial<{
      'is_allow_contributions_over_election_amount': boolean,
      'is_allow_contributions_over_election_amount_state': PlanFieldState,
    }>,
    'is_pend_contributions_over_irs': Partial<{
      'is_pend_contributions_over_the_irs_max': boolean,
      'is_pend_contributions_over_the_irs_max_state': PlanFieldState,
    }>,
    'is_rollover': Partial<{
      'is_rollover': boolean,
      'is_rollover_state': PlanFieldState
    }>,

    'is_plan_level_funding': Partial<{
      'is_plan_level_funding': boolean,
      'is_plan_level_funding_state': PlanFieldState
    }>,
    'replenishment_bank_account_id'?: number,
    'replenishment_method'?: string,
    'replenishment_frequency'?: string,
    'replenishment_day_number'?: number,
    'contribution_bank_account_id'?: number,
    'contribution_method'?: string,
    'contribution_hold_period'?: number,

    'max_rollover_amount': Partial<{
      'max_rollover_amount': number,
      'max_rollover_irs_limit_type': string;
      'max_rollover_amount_type': string,
      'max_rollover_amount_type_state': PlanFieldState
    }>,
    'min_rollover_amount': Partial<{
      'min_rollover_amount': number,
      'min_rollover_amount_type': string,
      'min_rollover_amount_type_state': PlanFieldState
    }>,
    'rollover_claims': Partial<{
      'rollover_claims_days_amount': number,
      'rollover_claims_type': string,
      'rollover_claims_type_state':PlanFieldState
    }>,
    'is_auto_enrollment': Partial<{
      'is_auto_enrollment': boolean,
      'is_auto_enrollment_state': PlanFieldState
    }>,
    'days_after_plan_end': Partial<{
      'days_after_plan_end': number,
      'days_after_plan_end_state': PlanFieldState
    }>,

    'is_custom_prefund_calc': Partial<{
      'is_custom_prefund_calc': boolean | null,
      'is_custom_prefund_calc_state': PlanFieldState,
    }>,
    'prefund_replenishment_percent': Partial<{
      'prefund_replenishment_percent': number | null,
      'prefund_replenishment_percent_state': PlanFieldState,
    }>,
    'prefund_replenishment_min_value': {
      'prefund_replenishment_min_value': string | null,
      'prefund_replenishment_min_value_state': PlanFieldState,
    },
    'prefund_replenishment_creep_min': {
      'prefund_replenishment_creep_min': string | null,
      'prefund_replenishment_creep_min_state': PlanFieldState,
    },
    'prefund_replenishment_creep_percent': {
      'prefund_replenishment_creep_percent': number | null,
      'prefund_replenishment_creep_percent_state': PlanFieldState,
    },
  }>,
  'valid_from': '2021-11-25T04:05:13.321Z';
  'valid_to': '2021-11-25T04:05:13.321Z';
  'valid_from_date': 'string';
  'valid_to_date': 'string';
  'plan_status': PlanStatuses;
}

export interface PlanListItemResponse {
  id: string;
  name: string;
  account_type: string;
  type_code?: string;
  plan_code?: string;
  account_type_state?: string;
  name_state?: string;
  plan_status?: string;
  plan_year?: {
    id: string;
    name: string;
  };
  is_linked?: boolean;
  is_removable?: boolean;
  allow_investments?: boolean;
  deletable?: boolean;
  grace_period_type?: string;
  is_carded?: boolean;
  is_custom_prefund_calc?: boolean;
  is_plan_level_funding?: boolean;
  is_replenished?: boolean;
  is_rollover?: boolean;
  run_out_type?: string;
  summary?: string;
}

export interface PlanGroupedListResponse {
  PARKING?: PlanListItemResponse[];
  TRANSIT?: PlanListItemResponse[];
  SPECIALTY?: PlanListItemResponse[];
  LIFESTYLE?: PlanListItemResponse[];
  WELLNESS?: PlanListItemResponse[];
  ADOPTION?: PlanListItemResponse[];
  TRAVEL?: PlanListItemResponse[];
  HSA?: PlanListItemResponse[];
  HCFSA?: PlanListItemResponse[];
  HRA?: PlanListItemResponse[];
  DCAP?: PlanListItemResponse[];
}

export type Plan = {
  id: string;
  name: string;
  typeState?: PlanFieldState;
  type: CardTypesEnum;
  status?: PlanStatuses;
  process?: { total: number, passed: number }
  info?: Partial<PlanInfoType>
  summary?: string;
  isCarded?: boolean;
  isCustomPrefundCalc?: boolean;
  isPlanLevelFunding?: boolean;
  isReplenished?: boolean;
};

export type GroupedPlan = {
  category: string;
  categoryEnum: CardTypesEnum;
  plans: Plan[];
};

export interface PlanTypes {
  planName: string;
  planType?: string;
  planTypes?: string[];
  id: string;
}

export interface PriorPlanYear {
  id?: number;
  name?: string;
  valid_from?: string;
  valid_to?: string;
}
