import React from 'react';
import { Box } from '@common-fe/common-fe';

import DocumentViewer, { NoFiles } from '@/components/elements/DocumentViewer';
import useGetDocumentReceipts from '@/components/elements/DocumentViewer/queries/useGetDocumentFiles.query';

interface Props {
  documentId?: string;
}

const LockboxDocumentPreview: React.FC<Props> = ({ documentId }) => {
  const { files, isFilesLoading } = useGetDocumentReceipts(documentId || '', false);

  const hasFiles = files.length > 0;

  return (
    <Box height="100%">
      {isFilesLoading || hasFiles ? (
        <DocumentViewer
          receipts={files}
          isLoading={isFilesLoading}
        />
      ) : (
        <NoFiles title="There are no files uploaded" />
      )}
    </Box>
  );
};

export default LockboxDocumentPreview;
