import { useCallback } from 'react';
import _ from 'lodash';

import { PlanStatuses } from '../plan.types';
import { useCreatePlanQuery } from '../PlansList/CreatePlanModal/queries';

export const useCreatePlan = (includeLogHeader?: boolean) => {
  const mutation = useCreatePlanQuery(undefined, includeLogHeader);
  const handleCreatePlan = useCallback(
    async (
      planTemplate?: string[],
      planYear?: string,
      organizationId?: string,
      status?: PlanStatuses,
    ) => {
      if (planTemplate) {
        await mutation({
          parentId: _.toNumber(planTemplate),
          planYearId: planYear ? _.toNumber(planYear) : undefined,
          organizationId,
          status,
        });
      }
      return null;
    }, [mutation],
  );
  return handleCreatePlan;
};

export default useCreatePlan;
