import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Preloader } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import { useRedirectHomeAccess } from '@/modules/core/hooks';
import LockboxDetails from '@/modules/employer/components/Lockbox/LockboxDetails/LockboxDetails';
import { useAuthStore } from '@/modules/user/stores';

const LockboxDetailsPage = () => {
  const { user } = useAuthStore();
  const { id: lockboxId } = useParams<{ id: string }>();

  useRedirectHomeAccess([
    { role: Roles.superUser },
    { role: Roles.finOps },
  ]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Box width="100%" height="100%">
      <LockboxDetails lockboxId={lockboxId} />
    </Box>
  );
};

export default LockboxDetailsPage;
