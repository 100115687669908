import { useCallback } from 'react';

import { api } from '@/api';
import { PATHS } from '@/common';

export default () => {
  const handleDownload = useCallback(async (id: string) => {
    const response = await api.get(PATHS.GET_FILE_DOWNLOAD_LINK(id));

    const url = response?.data?.url;
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return handleDownload;
};
