import React, { useState } from 'react';
import { AppButton, Box, Inscription, Preloader, Text, WarningCard, WarningIcon, WarnModal } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';
import { Copay, ExpressSetup } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import { Delimiter } from '@/modules/ExpressSetup/styles/ExpressSetupWizard.styles.ts';
import { GroupedPlan } from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import ExpressSetupActivateModal from '../../ExpressSetupActivateModal';
import { useActivationAllowed, useHandleExpressSetupSetUp } from '../../hooks';
import { EXPRESS_SETUP_WIZARD_CONTENT_WIDTH } from '../ExpressSetupWizard';

import CopaysForm from './Copays/CopaysForm';
import usePlansWizardActions from './hooks/usePlansWizardActions';
import PlansForm from './Plans/PlansForm';
import PlansTypeForm from './Plans/PlansTypeForm';
import PlanYearForm from './PlanYear/PlanYearForm';

export const ACTIVATE_ERROR_TEXT = 'Please correct the highlighted errors to update the Employer';
export const SAVE_ERROR_TEXT = 'Please correct the highlighted errors to save the Employer';

interface Props {
  isEditMode?: boolean;
  isPlansExist?: boolean;
  orgName?: string;
  serviceCategories?: ServiceCategoryTypes[],
  defaultCopays?: Copay[];
  expressSetupData?: ExpressSetup;
  isTemplatesLoading?: boolean;
  activeTemplates?: GroupedPlan[];
  onEditToggle: (value: boolean) => void;
}

const PlansWizard: React.FC<Props> = ({ isEditMode, isPlansExist, orgName, serviceCategories, defaultCopays, expressSetupData, activeTemplates, isTemplatesLoading, onEditToggle }) => {
  const [isShowedCopayError, setShowedCopayError] = useState<boolean>(false);
  const [isSaveErrorModalVisible, setSaveErrorModalVisible] = useState<boolean>(false);
  const [isPlanTypesSelected, setIsPlanTypesSelected] = useState<boolean>(false);
  const [isPlanYearExist, setPlanYearExist] = useState<boolean>(false);
  const [isVisibleExpressSetupPopup, setVisibleActivateExpressSetupPopup] = useState(false);
  const [planYearFormKey, setPlanYearFormKey] = useState('');
  const [errorModal, setErrorModal] = useState<string>('');
  const [copaysFormKey, setCopaysFormKey] = useState('');
  const isActivationAllowed = useActivationAllowed(expressSetupData);
  
  const {
    onSavePlanYearHandler,
    onSavePlansHandler,
    isPlanYearLoading,
    isPlansLoading,
    isPlanYearServerError,
    isPlansServerError,
  } = useHandleExpressSetupSetUp();

  const {
    handleTypeSelect,
    handleRemoveTypeCard,
    handleParentTemplateSelect,
    planYearState,
    plansState,
    isPlansErrorShows,
    planYearSourceState,
    plansStateSourceState,
    handleSetPlanYearState,
    showCopaysForm,
    PLANS_ERROR_STATE_NAME,
  } = usePlansWizardActions({
    setShowedCopayError,
    setIsPlanTypesSelected,
    setPlanYearExist,
    isPlansExist,
    setErrorModal,
    isPlansServerError,
    isPlanYearServerError,
    defaultCopays,
    setCopaysFormKey
  });

  return (
    <Box
      style={{ maxWidth: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH }}
      data-testId="plans-wizard"
    >
      <Text weight={700} size="32px" color="textTitle" margin={{ bottom: 'xs' }}>Plan Year</Text>
      <Text color="textBody" margin={{ bottom: 'l' }}>We allow setting up a plan year for 12 months or a shorter period. Contact your partner if you need over 12 months.</Text>
      <Box background="module" pad="medium" round="container1Round" data-testid="plan-year-form-wrapper">
        <PlanYearForm key={planYearFormKey} isEditMode={isEditMode || !isPlanYearExist} />
      </Box>
      {
        (isPlanYearExist && !isEditMode) || (!isPlanYearExist) ? (
          <AppButton
            onClick={isPlanYearExist ? () => onEditToggle(true) : () => onSavePlanYearHandler()}
            testId="edit-plan-year"
            disabled={isPlanYearLoading || (!isPlanYearExist && !planYearState?.planStartDate && !planYearState?.planEndDate)}
            style={{ marginTop: theme.spacings.l, width: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH, height: '54px' }}
          >
            {isPlanYearLoading ? <Preloader color="white" /> : isPlanYearExist ? 'Edit Plan Year Dates' : 'Next'}
          </AppButton>
        ) : (
          <Box direction="row" justify="between" margin={{ top: 'l' }}>
            <AppButton
              buttonType="secondary"
              color="blue"
              onClick={() => {
                onEditToggle(false);
                if (planYearSourceState) handleSetPlanYearState(planYearSourceState);
                setPlanYearFormKey(uniqueId());
              }}
              testId="cancel-plan-year"
              width="312px"
              style={{ marginRight: theme.spacings.s, height: '54px' }}
            >
              Cancel
            </AppButton>
            <AppButton
              onClick={() => onSavePlanYearHandler(() => onEditToggle(false))}
              testId="save-plan-year"
              disabled={isPlanYearLoading}
              style={{ width: '312px', height: '54px' }}
            >
              {isPlanYearLoading ? <Preloader color="white" /> : 'Save'}
            </AppButton>
          </Box>
        )
      }
      {planYearSourceState?.planEndDate && (
        <>
          <Delimiter margin={{ vertical: 'l' }} />
          <Box>
            <Text weight={700} size="32px" color="textTitle" margin={{ bottom: 'l' }}>Plans</Text>
            {
              activeTemplates?.length === 0 && !isTemplatesLoading ? (
                <WarningCard
                  icon={<WarningIcon />}
                  title={(
                    <Inscription color="textBody">
                      {`${orgName} does not offer any plans. This prevents you from completing the setup process. Please contact your partner to configure plans before proceeding with setup.`}
                    </Inscription>
                  )}
                  containerProps={{
                    pad: {
                      horizontal: 'l',
                      vertical: 'm',
                    },
                  }}
                />
              ) : (
                <>
                  <Text weight={700} size="24px" color="textTitle" margin={{ bottom: 'xs' }}>{`Which accounts would you like to offer in your ${planYearSourceState?.planStartDate} - ${planYearSourceState?.planEndDate} plan year?`}</Text>
                  <Text color="textBody" margin={{ bottom: 'l' }}>Select each account offered to employees during the plan year.</Text>
                  <Box background="module" pad="medium" round="container1Round" data-testid="plan-type-form-wrapper">
                    {isTemplatesLoading ? (
                      <Box>
                        <Preloader color='white'/>
                      </Box>
                    ) : (
                      <PlansTypeForm
                        selectedPlanTypes={plansState?.planTypes}
                        activeTemplates={activeTemplates}
                        onCardSelect={handleTypeSelect}
                        isShowedCopayError={isShowedCopayError}
                      />
                    )}
                  </Box>
                </>
              )
            }
          </Box>
          {!isPlanTypesSelected && !plansStateSourceState?.planTypes?.length && !!activeTemplates?.length && (
            <AppButton
              onClick={() => setIsPlanTypesSelected(true)}
              disabled={plansState?.planTypes?.length === 0}
              testId="select-plan-types"
              style={{ margin: `${theme.spacings.l} 0`, width: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH, height: '54px' }}
            >
              Next
            </AppButton>
          )}
        </>
      )}
      {planYearSourceState?.planEndDate && !!plansState?.planTypes?.length && isPlanTypesSelected && !!activeTemplates?.length &&  (
        <>
          <Delimiter margin={{ vertical: 'l' }} />
          <Text weight={700} size="24px" color="textTitle" margin={{ bottom: 'l' }}>Select a plan for each account</Text>
          <PlansForm
            selectedPlanTypes={plansState?.planTypes}
            onParentTemplateSelect={handleParentTemplateSelect}
            onRemoveTypeCard={handleRemoveTypeCard}
            isPlansErrorShows={isPlansErrorShows}
            activeTemplates={activeTemplates}
            selectedParentTemplates={plansState?.plans || []}
          />
          {
            showCopaysForm && (
              <Box margin={{ top: 'l' }} >
                <Text weight={700} size="24px" color="textTitle" margin={{ bottom: 'l' }}>Additional features</Text>
                <CopaysForm key={copaysFormKey} serviceCategories={serviceCategories} />
              </Box>
            )
          }
          <Box direction="row" margin={{ top: 'l' }} justify="between">
            <AppButton
              onClick={() => onSavePlansHandler(() => setSaveErrorModalVisible(true))}
              testId="save-plans-form"
              disabled={isPlansLoading || (plansState?.plans && plansState?.plans?.length === 0)}
              buttonType="secondary"
              style={{ width: '312px', height: '54px' }}
            >
              {isPlansLoading ? <Preloader color="white" /> : 'Save'}
            </AppButton>
            <AppButton
              onClick={() => setVisibleActivateExpressSetupPopup(true)}
              testId="activate-employer"
              disabled={!isActivationAllowed}
              style={{ width: '312px', height: '54px' }}
            >
              Activate Organization
            </AppButton>
          </Box>
        </>
      )}
      <ExpressSetupActivateModal
        isVisible={isVisibleExpressSetupPopup}
        expressSetup={expressSetupData}
        onSetVisible={setVisibleActivateExpressSetupPopup}
      />
      <WarnModal
        visible={isSaveErrorModalVisible}
        onSetVisible={setSaveErrorModalVisible}
        header={SAVE_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        testId="plans-plan_year-wizzard-error-modal"
        visible={!!errorModal}
        onSetVisible={() => setErrorModal('')}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot update the Plan Year or Plans with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={errorModal === PLANS_ERROR_STATE_NAME ? () => onSavePlansHandler() : () => onSavePlanYearHandler()}
        submitButtonText="Try again"
      />
    </Box>
  );
};

export default PlansWizard;
