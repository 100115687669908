import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import _, { toString } from 'lodash';

import { PaymentRule } from '../../Tiers/queries/useServiceConfigs.query';

import { ACCOUNT_TYPE_KEY, PaymentRuleField } from './usePaymentRuleFields';

const DEFAULT_SERVICE_PAYMENT_FIELD: PaymentRuleField = {
  id: '',
  account: '',
  amount: '',
};

export default (paymentRules?: PaymentRule[]) => {
  const [fieldsValues, setFieldsValues] = useState<PaymentRuleField[]>([]);

  useEffect(() => {
    let copiedTiers = [] as PaymentRuleField[];
    if (paymentRules) {
      copiedTiers = paymentRules.map((item: PaymentRule) => ({
        amount: item.maxAmount
          ? toString(item.maxAmount) : toString(item.percentage && item.percentage * 100),
        account: item.planId || '',
        id: _.uniqueId(),
      }));
    }
    const timeout = setTimeout(() => setFieldsValues(copiedTiers), 0);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldValuesById = useCallback(
    (id?: string) => {
      const defaultValues = fieldsValues.find((field) => field.id === id);
      return defaultValues;
    },
    [fieldsValues],
  );

  const handleChangeValues = useCallback(
    (id?: string) => (values: Partial<PaymentRuleField>) => {
      const newList = fieldsValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...values,
          };
        }
        return item;
      });
      if (!_.isEqual(newList, fieldsValues)) {
        setFieldsValues(newList);
      }
    },
    [fieldsValues],
  );

  const handleRemoveById = useCallback((id: string) => {
    setFieldsValues(fieldsValues.filter((item) => item.id !== id));
  }, [fieldsValues]);

  const handleClearValues = useCallback((type: string) => {
    let newList: PaymentRuleField[];
    if (type === ACCOUNT_TYPE_KEY) {
      newList = fieldsValues.map((item) => ({
        ...item,
        id: _.uniqueId(),
        account: '',
      }));
    } else {
      newList = fieldsValues.map((item) => ({
        ...item,
        id: _.uniqueId(),
        amount: '',
      }));
    }
    setFieldsValues(newList);
  }, [fieldsValues]);

  const handleAddNewServicePayment = useCallback(() => {
    setFieldsValues([
      ...fieldsValues,
      {
        ...DEFAULT_SERVICE_PAYMENT_FIELD,
        id: _.uniqueId(),
      },
    ]);
  }, [fieldsValues]);

  return {
    handleChangeValues,
    handleRemoveById,
    getFieldValuesById,
    fieldsValues,
    handleAddNewServicePayment,
    handleClearValues,
  };
};
