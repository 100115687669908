import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import REGEXP from '@/common/regexp';
import { Address } from '@/common/types';
import {
  PaymentMethod,
  PaymentMethodDtoPayload,
  PaymentMethodOwnerType,
  PaymentMethodType,
} from '@/modules/employee/employee.types';
import { getEncryptionByPubKey } from '@/utils/hooks/useEncryptData';
import { phoneNumberCreator } from '@/utils/modifiers/phoneNumberCreator';

import { Provider } from '../ClaimInfo/useProviders.query';
import { BankInfo } from '../ConfirmationStep/Immediately/DirectDeposit/BankForm/useBankFields';

export interface PaymentMethodRequestData {
  paymentType: PaymentMethodType;
  paymentOwnerType: PaymentMethodOwnerType;
  bankData?: BankInfo;
  provider?: Provider;
}

const getCurrentPaymentData = (value: PaymentMethodRequestData) => {
  switch (value.paymentType) {
  case PaymentMethodType.DIRECT_DEPOSIT:
    return {
      direct_deposit: {
        account_description: value?.bankData?.associatedName,
        account_number: value?.bankData?.accountNumber,
        account_routing_transit_number: value?.bankData?.routingNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
        account_category: value?.bankData?.accountType,
      },
    };

  default:
    return {};
  }
};

export const useSetDefaultPaymentMethodQuery = (onSuccess?: () => void) => {
  const { mutateAsync } = useMutation(
    (paymentMethodId: number | string) => api.put(PATHS.UPDATE_DEFAULT_PAYMENT_METHODS(paymentMethodId)),
    {
      onSuccess,
    },
  );

  return {
    updateDefaultPaymentMethod: mutateAsync,
  };
};

const formatDataToCreate = (
  value: PaymentMethodRequestData,
  employeeId?: string,
  providerId?: string,
  address?: Address,
) => {
  const paymentMethodData = getCurrentPaymentData(value);
  return {
    payment_method_type: value?.paymentType,
    payment_method_owner: {
      ...value?.paymentOwnerType === PaymentMethodOwnerType.EMPLOYEE && employeeId
        ? { employee_id: employeeId } : {},
      payment_method_owner_type: value?.paymentOwnerType,
      ...providerId && value?.paymentOwnerType === PaymentMethodOwnerType.PROVIDER
        ? { provider_id: +providerId } : {},
    },
    ...paymentMethodData,
    ...address ? {
      payment_address: {
        line1: address?.line1,
        line2: address?.line2,
        line3: address?.line3,
        line4: address?.line4,
        city: address?.city,
        state: address?.state?.code,
        zipcode: address?.zipcode,
        country_id: 0,
      },
    } : {},
  };
};

export const formatPaymentMethod = (paymentMethod: PaymentMethodDtoPayload): PaymentMethod => ({
  id: toString(paymentMethod?.id),
  type: paymentMethod?.payment_method_type,
  isDefault: paymentMethod?.is_default,
  ...paymentMethod?.is_default ? { isChecked: true } : {},
  accountId: toString(paymentMethod?.account_id),
  recipientId: toString(paymentMethod?.recipient_id),
  email: paymentMethod?.email,
  phoneNumber: paymentMethod?.phone_number,
  status: paymentMethod?.payment_method_status,
  accountNumber: paymentMethod?.account_number,
  accountNumberLast4: paymentMethod?.account_number_last4,
  accountDescription: paymentMethod?.account_description,
  address: {
    id: toString(paymentMethod?.payment_address?.id),
    line1: paymentMethod?.payment_address?.line1,
    line2: paymentMethod?.payment_address?.line2,
    line3: paymentMethod?.payment_address?.line3,
    line4: paymentMethod?.payment_address?.line4,
    city: paymentMethod?.payment_address?.city,
    state: {
      id: toString(paymentMethod?.payment_address?.state?.id),
      code: paymentMethod?.payment_address?.state?.code,
      name: paymentMethod?.payment_address?.state?.name,
    },
    zipcode: paymentMethod?.payment_address?.zipcode,
    validated: paymentMethod?.payment_address?.validated,
    country: {
      name: paymentMethod?.payment_address?.country?.name || '',
      alpha2: paymentMethod?.payment_address?.country?.alpha2 || '',
      alpha3: paymentMethod?.payment_address?.country?.alpha3 || '',
    },
  },
  paymentMethodOwner: {
    organizationId: toString(paymentMethod?.payment_method_owner?.organization_id),
    employeeId: toString(paymentMethod?.payment_method_owner?.employee_id),
    providerId: toString(paymentMethod?.payment_method_owner?.provider_id),
    ownerType: toString(
      paymentMethod?.payment_method_owner?.payment_method_owner_type,
    ) as PaymentMethodOwnerType,
  },
  requisites: {
    phoneNumber: paymentMethod?.requisites?.phone_number
      ? phoneNumberCreator(paymentMethod?.requisites?.phone_number)
      : '',
    email: paymentMethod?.requisites?.email,
    cardHolderName: paymentMethod?.requisites?.card_holder_name,
    cardExpirationDate: paymentMethod?.requisites?.card_expiration_date,
    cardLast4: paymentMethod?.requisites?.card_last4,
    cardType: paymentMethod?.requisites?.card_type,
  },
});

const useAddPaymentMethodQuery = (employeeId?: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(false);
  const { mutateAsync, isSuccess } = useMutation(
    (value: PaymentMethodRequestData) => api.post(
      PATHS.PAYMENT_METHODS,
      formatDataToCreate(
        value,
        employeeId,
        value?.provider?.id,
      ),
      {
        params: {
          employee_id: employeeId,
        },
      },
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.PAYMENT_METHODS, { refetchInactive: true });
        setLoading(false);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const save = useCallback(async (value: PaymentMethodRequestData) => {
    setLoading(true);
    let encryptData = {};
    if (value.paymentType === PaymentMethodType.DIRECT_DEPOSIT) {
      encryptData = await getEncryptionByPubKey({
        accountNumber: value.bankData?.accountNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
      });
    }
    const { data } = await mutateAsync({
      ...value,
      ...value.bankData
        ? {
          bankData: {
            ...value.bankData,
            ...encryptData,
          },
        }
        : {},
    });

    return data;
  }, [mutateAsync]);

  return { isLoading, save, isSuccess };
};

export default useAddPaymentMethodQuery;
