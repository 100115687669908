import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { formatClaimsProcessorPayload } from './ClaimsProcessors.types';

const useClaimsProcessorQuery = (id?: string, enabled?: boolean) => {
  const { isLoading, data } = useQuery([
    PATHS.CONTACT_INFO(id),
    enabled,
  ], () => api.get(PATHS.CONTACT_INFO(id)), { enabled: enabled && Boolean(id), });

  const claimsProcessor = useMemo(() => {
    if (!data?.data) return undefined;

    return formatClaimsProcessorPayload(data.data);
  }, [data]);

  return ({
    claimsProcessor,
    isLoading,
  });
};

export default useClaimsProcessorQuery;
