import React, { useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  EnrollmentStatus,
  Text,
} from '@common-fe/common-fe';

import globalTheme from '@/styles/theme';

import { getEnrollmentStatusColor } from '../../Employee/PersonalInformation/EmploymentStatusTimeline/utils';

interface Props {
  coverageDateStart?: string;
  coverageDateEnd?: string;
  submitClaimsDateEnd?: string;
  enrollmentStatus?: string;
}
  
export const EnrollmentDetailsEnrollmentStatus: React.FC<Props> = ({
  coverageDateStart,
  coverageDateEnd,
  submitClaimsDateEnd,
  enrollmentStatus,
}) => {
  const statusColor = useMemo(() => getEnrollmentStatusColor(enrollmentStatus as EnrollmentStatus),
    [enrollmentStatus]);
  return (
    <Box
      pad="spacing24"
      background="canvas"
      round="container1Round"
      border={{ color: 'border2' }}
      gap="spacing16"
      style={{
        boxShadow: globalTheme.shadows.default['box-shadow'],
      }}
    >
      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Enrollment
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text size="medium" weight={400} color={statusColor}>
            {capitalizeFirstLetter(enrollmentStatus || '-')}
          </Text>
        </Box>
      </Box>

      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Main coverage period
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text size="medium" weight={400}>
            {coverageDateStart ? `${coverageDateStart} - ` : ''}
            {coverageDateEnd || 'No deadline'}
          </Text>
        </Box>
      </Box>
      
      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Claim submit deadline
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text size="medium" weight={400}>
            {submitClaimsDateEnd || 'No deadline'}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
  