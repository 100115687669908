import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CIPStatus } from '@common-fe/common-fe';

import { api } from '@/api';
import { PATHS } from '@/common';

export const GET_CIP_QUERY_KEY = 'GET_CIP_INFO';

interface CIPInfoPayload {

  'id': number;
  'employee_id': number;
  'current_cip_status': {
    'id': number;
    'cip_info_id': number;
    'status': CIPStatus;
    'valid_from': string;
    'valid_to': string;
  }

}

export default (employeeId: string) => {
  const { data, isLoading, isSuccess } = useQuery([GET_CIP_QUERY_KEY, employeeId],
    () => api.get<CIPInfoPayload>(PATHS.CIP_INFO, {
      params: {
        employee_id: employeeId,
      },
    }),
    {
      enabled: !!employeeId,
    });
  const formattedData = useMemo(() => ({
    status: data?.data?.current_cip_status?.status,
  }), [data]);
  return {
    isSuccess,
    isLoading,
    data: formattedData,
  };
};
