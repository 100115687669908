import { useMemo } from 'react';

import { useContactStore } from '../AddEditContactForm/stores';
import { useAddressStore } from '../EditAddressModal/stores';
import { useBankAccountStore } from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/stores';
import { useCopayStore } from '../ExpressSetupWizard/PlansWizard/Copays/stores';
import { usePlansStore } from '../ExpressSetupWizard/PlansWizard/Plans/stores';
import { usePlanYearStore } from '../ExpressSetupWizard/PlansWizard/PlanYear/stores';

export default () => {
  const contactState = useContactStore((state) => state.state);
  const planYearState = usePlanYearStore((state) => state.state);
  const plansState = usePlansStore((state) => state.state);
  const bankAccountState = useBankAccountStore((state) => state.state);
  const copayState = useCopayStore((state) => state.state);
  const addressState = useAddressStore((state) => state.state);

  const state = useMemo(() => ({
    contact: contactState,
    planYear: planYearState,
    plans: plansState?.plans,
    bankAccountState: bankAccountState,
    copays: copayState.copays,
    address: addressState,
  }), [addressState, bankAccountState, contactState, copayState.copays, planYearState, plansState?.plans]);

  return state;
};
