import React from 'react';
import { Box } from '@common-fe/common-fe';

import {
  SETUP_CONTAINER_UI_ID,
} from '@/modules/core/hooks/useDetectActiveTOCModule';

import { PlanStatuses } from '../plan.types';

import { usePlanModules, useProgressScroll } from './Sidebar/hooks';
import Sidebar from './Sidebar';

interface Props {
  children: React.ReactNode;
  planStatus?: PlanStatuses;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
}
const PlanSetupWrapper: React.FC<Props> = ({
  children,
  planStatus,
  isTemplateMode,
  isPlanActive,
}) => {
  const config = usePlanModules();

  const { onScrollHandler, scrollToSection } = useProgressScroll(config);

  return (
    <Box
      direction="row"
      overflow="hidden"
      flex={{ grow: 1 }}
    >
      <Sidebar
        planStatus={planStatus}
        scrollToSection={scrollToSection}
        isTemplateMode={isTemplateMode}
        isPlanActive={isPlanActive}
      />
      <Box
        fill
        overflow="auto"
        onScroll={onScrollHandler}
        id={SETUP_CONTAINER_UI_ID}
        align="center"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PlanSetupWrapper;
