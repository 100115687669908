import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ArrowDownIcon, ArrowUpIcon,
  Box, FlatButton, OrderIcon,
  Text,   TextWithPointer, } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { ListItemType } from '@/common/types';
import { useOrgLabel } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import ExpandedItem from '../ExpandedItem';
import ExpandedItemFirstLevel from '../ExpandedItem/ExpandedItemFirstLevel';
import ExpandedItemSecondLevel from '../ExpandedItem/ExpandedItemSecondLevel';
import { EnrollmentMatrixItem } from '../ProcessingDefinition.types';
import { useTriggersQuery } from '../ProcessingRule/queries';

import { ChangePriorityPopup } from './ChangePriorityPopup';

export const StyledBox = styled(Box) <{ notClickable?: boolean }>`
  &:hover {
    cursor: ${({ notClickable }) => notClickable && 'not-allowed'};
    background-color: ${theme.colors.accentActive};
    svg {
      fill: ${theme.colors.iconOnColor};
    }
  }
  pointer-events: ${({ notClickable }) => notClickable && 'none'};
`;

const EMPTY_CHAR = '—';

interface Props {
  value: EnrollmentMatrixItem;
  isEmployer?: boolean;
  isExpired?: boolean;
  newItemId?: string;
  isPriorityMode?: boolean;
  currentOrg?: string;
  perPage?: number;
  priorityFirstEl?: string;
  priorityLastEl?: string;
  handleSave: (
    id?: string,
    priority?: string,
    perPage?: number,
    notHighlight?: boolean,
  ) => void;
  setUpdatedElement?: (value?: EnrollmentMatrixItem) => void;
  isCreateAccessible?: boolean;
  isLoading?: boolean;
  isLinkToHealthPlan?: boolean;
}
const MatrixListItem: React.FC<Props> = ({
  isEmployer,
  isExpired,
  value,
  newItemId,
  isPriorityMode,
  currentOrg,
  perPage,
  priorityFirstEl,
  priorityLastEl,
  handleSave,
  setUpdatedElement,
  isCreateAccessible,
  isLoading,
  isLinkToHealthPlan,
}) => {
  const [isExapnded, setExpanded] = useState(false);
  const [newPriority, setNewPriority] = useState('');
  const [selectedRaw, setSelectedRaw] = useState<string>();
  const { fieldsMap: triggersMap } = useTriggersQuery();

  const handleToggleExpanded = useCallback(() => {
    if (!isPriorityMode) setExpanded(!isExapnded);
  }, [isExapnded, isPriorityMode]);

  const isAllowedPriorityMode = useMemo(() => ((value?.organizationType === currentOrg)
    && !isLinkToHealthPlan), [value, currentOrg, isLinkToHealthPlan]);
  const orgLabel = useOrgLabel(value?.organizationType);
  useEffect(() => {
    if (newPriority) handleSave(value?.id, newPriority, perPage);
    setNewPriority('');
  }, [newPriority, value, perPage, handleSave]);

  useEffect(() => {
    if (isPriorityMode) setExpanded(false);
  }, [isPriorityMode, setExpanded]);

  return (
    <ExpandedItem
      testId={`matrix_item-${value?.id}`}
      isHighlight={value?.id === newItemId}
      isHighlightMoveTo={value?.id === (selectedRaw && selectedRaw)}
      isDisabled={isExpired || (!isAllowedPriorityMode && isPriorityMode)}
      isMatrix
      isExapnded={isExapnded}
       
      disabledOptionHint={!isAllowedPriorityMode ? isLinkToHealthPlan ? 'You can\'t edit items linked to health plans'
        : isExpired ? 'You can\'t edit expired enrollment matrixes' : 'You can\'t edit items of other organizations' : undefined}
      options={(!isPriorityMode && isCreateAccessible) || isExpired ? [
        {
          name: 'Edit',
          onClick: () => {
            if (setUpdatedElement) setUpdatedElement(value);
          },
        },
      ] : undefined}
      item={{
        id: '1',
        fields: [{
          key: 'org-level-name',
          type: ListItemType.Node,
          title: '',
          flex: 1.9,
          node: (
            <Box data-testid={`matrix_item-${value.id}_org-level-name`}>
              <FlatButton data-testid={`matrix_flat_button-${value?.id}_org-level-name`} onClick={handleToggleExpanded}>
                <Box direction="row" align="center">
                  <Box width={{ min: '24px' }}>
                    {
                      isAllowedPriorityMode && isPriorityMode && (
                        <OrderIcon color="iconSecondary" />
                      )
                    }
                    {
                      !isPriorityMode && isExapnded ? (
                        <ArrowUpIcon color="iconPrimary" />
                      ) : !isPriorityMode && (
                        <ArrowDownIcon color="iconPrimary" />
                      )
                    }
                  </Box>
                  <Box
                    height="18px"
                    margin={{ left: 's', right: 'xs' }}
                    pad={{ horizontal: '3px' }}
                    round="button2Round"
                    background="nonClaimBg"
                    align="center"
                    justify="center"
                    width={{ min: '25px', max: '25px' }}
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    <Text size="xsmall" weight={500}>{orgLabel}</Text>
                  </Box>
                  <TextWithPointer
                    title={value?.name}
                    size="medium"
                    color="textBody"
                    ellipsisMode
                    weight="bold"
                    style={{ paddingRight: theme.spacings.xs }}
                  >
                    {value?.name}
                  </TextWithPointer>
                </Box>
              </FlatButton>
            </Box>
          ),
        }, {
          key: 'plan-template',
          type: ListItemType.Text,
          ellipsisMode: true,
          title: value.planTemplate?.name || EMPTY_CHAR,
          flex: 1,
        }, ...isEmployer ? [{
          key: 'plan-year',
          type: ListItemType.Text,
          title: value.planYear?.name || EMPTY_CHAR,
          flex: 1,
        }] : [], {
          key: 'processing-rule',
          type: ListItemType.Text,
          ellipsisMode: true,
          title: value.processingRule?.name || EMPTY_CHAR,
          flex: 1,
        }, {
          key: 'valid-from',
          type: ListItemType.Text,
          title: value.validFrom || EMPTY_CHAR,
          flex: 1,
        }, {
          key: 'valid-to',
          type: ListItemType.Text,
          title: value.validTo || EMPTY_CHAR,
          flex: 1,
        },
        ...!isExpired ? [{
          key: 'priority',
          type: ListItemType.Text,
          title: value.priority,
          flex: 0.7,
        }] : [],
        ...isPriorityMode ? [{
          key: 'priorityButtons',
          type: ListItemType.Node,
          title: '',
          node: (
            <Box data-testid={`matrix_item-${value.id}_priority-buttons`}>
              {
                isAllowedPriorityMode && (
                  <Box direction="row" justify="end" align="center">
                    <StyledBox
                      width={{ min: '40px' }}
                      height="40px"
                      justify="center"
                      align="center"
                      margin={{ right: 'xs' }}
                      round="50%"
                      notClickable={priorityFirstEl === value.priority}
                      background={priorityFirstEl === value.priority ? 'border2' : 'accentContainer'}
                      onClick={() => {
                        if (!isLoading) setNewPriority(String(+value.priority - 1));
                      }}
                    >
                      <ArrowUpIcon color={priorityFirstEl === value.priority ? 'iconSecondary' : 'iconPrimary'} />
                    </StyledBox>
                    <StyledBox
                      width={{ min: '40px' }}
                      height="40px"
                      justify="center"
                      align="center"
                      margin={{ right: 'xs' }}
                      round="50%"
                      notClickable={priorityLastEl === value.priority}
                      background={priorityLastEl === value.priority ? 'border2' : 'accentContainer'}
                      onClick={() => {
                        if (!isLoading) setNewPriority(String(+value.priority + 1));
                      }}
                    >
                      <ArrowDownIcon color={priorityLastEl === value.priority ? 'iconSecondary' : 'iconPrimary'} />
                    </StyledBox>
                    <ChangePriorityPopup
                      testId="ChangePriority"
                      id={value?.id}
                      perPage={perPage}
                      onSelectRaw={() => setSelectedRaw(value.id)}
                      onClearSelectRow={() => setSelectedRaw('')}
                    />
                  </Box>
                )
              }
            </Box>
          ),
          flex: 1,
        }] : [],
        ],
      }}
    >
      {isExapnded && (
        <ExpandedItemFirstLevel
          testId={`matrix_item_${value.id}_first-level`}
          subTitle="Processing rule"
          title={value.processingRule?.name || EMPTY_CHAR}
        >
          {value.processingRule?.tiers.map((tier, index) => {
            const triggersTitle = tier?.triggers?.map((trigger) => triggersMap[trigger]) || [];
            return (
              <ExpandedItemSecondLevel
                key={tier.id}
                title={tier.name}
                isFirstItem={_.first(value.processingRule?.tiers) === tier}
                isLastItem={_.last(value.processingRule?.tiers) === tier}
                subTitle={`Tier ${index + 1}`}
                items={[{
                  id: tier.payoutDefinition.id,
                  blockTitle: 'Payout definition',
                  title: tier.payoutDefinition.name,

                  // subTitle: 'Enabled service categories',
                  // items: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
                }, {
                  id: '3',
                  blockTitle: 'Trigger',
                  triggers: tier?.triggers,
                  subTitle: tier.isSendTriggerNotification ? 'Send trigger notification' : undefined,
                  subSubTitle: tier.isSendTriggerNotification ? 'Yes' : undefined,
                  title: triggersTitle.length ? triggersTitle.join(', ') : 'N/A',
                },
                ...tier?.displayName ? [{
                  id: `${tier.payoutDefinition.id}-${tier.displayName}`,
                  blockTitle: 'Display name',
                  triggers: tier?.triggers,
                  title: 'Display name',
                  header: ['Display name', 'Plan'],
                  values: [tier?.displayName || '-', tier?.planName || '-'],
                }] : [],
                ]}
              />
            );
          })}
          {/* <ExpandedItemSecondLevel
          title="Limited Use"
          isFirstItem
          subTitle="Tier 1"
          items={[{
            id: '1',
            blockTitle: 'Payout definition',
            title: 'Elevate Standard',
            subTitle: 'Enabled service categories',
            items: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
          }, {
            id: '3',
            blockTitle: 'Trigger',
            title: 'Health Plan Deductible met – True',

          }]}
        />
        <ExpandedItemSecondLevel
          title="Limited Use"
          subTitle="Tier 2"
          isLastItem
          items={[{
            id: '1',
            blockTitle: 'Payout definition',
            title: 'Elevate Standard',
            subTitle: 'Enabled service categories',
            items: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
          }, {
            id: '3',
            blockTitle: 'Trigger',
            title: 'N/A',

          }]}
        /> */}

        </ExpandedItemFirstLevel>
      )}
    </ExpandedItem>
  );
};
export default MatrixListItem;
