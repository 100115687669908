export enum CardStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  PENDING_CLOSED = 'PENDING_CLOSED',
  PENDING_FROZEN = 'PENDING_FROZEN',
  PENDING_OPEN = 'PENDING_OPEN',
  PENDING_REPLACE = 'PENDING_REPLACE',
  PENDING_UNLOCK = 'PENDING_UNLOCK',
  FROZEN = 'FROZEN',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  NOT_SENT = 'NOT_SENT',
}
export interface SearchTransactionParams {
  categories?: string;
  statuses?: string;
  provider?: string;
  status_date_from?: string | null;
  status_date_to?: string | null;
  accountTypes?: string;
  years?: string;
  page?: number;
   
  size?: number;
  employee_id?: number;
}

export enum BankTypeEnum {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
}

export interface CardHolderDto {
  dependentId: string;
  name: string;
  firstName: string;
  lastName: string;
  email?: string;
  dateOfBirth: string;
  relationship: string;
}

export interface CardDto {
  status: CardStatusEnum,
  last4Number: string;
  description?: string;
  holder: CardHolderDto;
  id: string;
  bankType: BankTypeEnum,
  primary?: boolean;
  accountIds: number[];
  cardAccountId: number;
  expDate: Date | null;
  cardRequestDate: Date | null;
  mailedDate: Date | null;
  activationDate: Date | null;
  memberSequenceIdentifier: number;
  alternateAccountId: string;
}

export interface ChangeCardStatusMutationArgs {
  id: string,
  status: CardStatusEnum
}

export interface CardResponse {
  description: string;
  id: number;
  last4: string;
  opened_on: Date;
  mailed_on: Date;
  activated_on: Date;
  expires_on: Date;
  pin_set: boolean;
  created_at: Date;
  modified_at: Date;
  card_status: CardStatusEnum;
  card_type: BankTypeEnum;
  sequence_number: number | null;
  card_account_id: number;
  account_ids: number[];
  member_sequence_identifier: number;
  alternate_account_id: string;
}

export interface CardHoldersResponse {
  suffix: string;
  dependents: CardHoldersResponse[];
  id: number;
  preferred_name: string;
  middle_name: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  relationship: string;
  employee_id: number;
  person_id: number;
  dependent_id: null | number;
  card_holder_type: string;
  card_holder_status: string;
  grouped_cards?: {
    [cardStatus in CardStatusEnum]: CardResponse[]
  };
}

export enum CardActivationModalType {
  Activation = 'Activation',
  Processing = 'Processing',
  Error = 'Error',
}
