import React, { useCallback } from 'react';
import { Box, RadioButton, Text } from '@common-fe/common-fe';

import BrandingPreview from './BrandingPreview';
import CustomLogoUploader from './CustomLogoUploader';
import DefaultLogo from './DefaultLogo';

interface Props {
  value?: string;
  disabled?: boolean;
  showErrors?: boolean;
  onChange: (value: string) => void;
  customLogo: string;
  onChangeCustomLogo: (value: string) => void;
}

export enum EmailPickerOption {
  ELEVATE = 'ELEVATE',
  ORGANIZATION_BRANDING = 'ORGANIZATION_BRANDING',
  CUSTOM = 'CUSTOM',
}

const EmailLogoPicker: React.FC<Props> = ({
  customLogo, onChangeCustomLogo,
  showErrors,
  value, onChange, disabled,
}) => {
  const handleChange = useCallback((val: string) => {
    onChange(val);
  }, [onChange]);

  return (
    <Box direction="column" data-testid="email-logo-picker">
      <RadioButton
        name="emailLogoType"
        disabled={disabled}
        data-testid="email-logo-picker_default"
        checked={value === EmailPickerOption.ELEVATE}
        onChange={() => handleChange(EmailPickerOption.ELEVATE)}
        label={(
          <Text size="medium" weight={700}>
            Use Elevate logo
          </Text>
        )}
      />
      <Box margin={{ bottom: 'l' }}>
        <DefaultLogo />
      </Box>
      <RadioButton
        name="emailLogoType"
        disabled={disabled}
        data-testid="email-logo-picker_branding"
        checked={value === EmailPickerOption.ORGANIZATION_BRANDING}
        onChange={() => handleChange(EmailPickerOption.ORGANIZATION_BRANDING)}
        label={(

          <Text size="medium" weight={700}>
            Use logo from Branding section
          </Text>
        )}
      />
      <Text size="medium" wordBreak="keep-all" style={{ width: '405px' }}>
        Automatically your company logo from Branding section.
      </Text>
      <BrandingPreview />
      <Box margin={{ top: 'm' }}>
        <RadioButton
          data-testid="email-logo-picker_custom"
          name="emailLogoType"
          disabled={disabled}
          checked={value === EmailPickerOption.CUSTOM}
          onChange={() => handleChange(EmailPickerOption.CUSTOM)}
          label={(

            <Text size="medium" weight={700}>
              Custom logo
            </Text>
          )}
        />
        <Text size="medium" wordBreak="keep-all" style={{ width: '405px' }}>
          Can only be uploaded.
        </Text>

        <Box>
          <CustomLogoUploader
            disabled={value !== EmailPickerOption.CUSTOM || disabled}
            value={customLogo}
            showErrors={showErrors}
            onChangeValue={onChangeCustomLogo}
          />
        </Box>

      </Box>
    </Box>
  );
};
export default EmailLogoPicker;
