import React from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';

const MatrixesListDelimiter = () => (
  <Box data-testid="MatrixesListDelimiter" direction="row" align="center" justify="between" margin={{ top: 'xs' }}>
    <Box height={{ max: '1px', min: '1px' }} background="border" round="3px" style={{ flex: 1 }} />
    <Box pad={{ horizontal: 'm' }} style={{ flexWrap: 'nowrap', flexShrink: 0 }}>
      <Text color="textSecondary" size="small" style={{ whiteSpace: 'nowrap' }}>
        The displayed above Enrollment Matrices are
        linked to Health Plans and currently cannot be modified
      </Text>

    </Box>

    <Box height={{ max: '1px', min: '1px' }} background="border" round="3px" style={{ flex: 1 }} />
  </Box>
);

export default MatrixesListDelimiter;
