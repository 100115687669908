import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import ActivityLog from '@/modules/ActivityLog';
import theme from '@/styles/theme';

import ExpressSetupTopbar from '../ExpressSetupTopbar';
import { useCurrentExpressSetup } from '../hooks';

const ExpressSetupActivityLogs: React.FC = () => {
  const { data: expressSetupData, isLoading } = useCurrentExpressSetup();
  return (
    <Box
      width="100%"
      data-testid="express-setup-activity-logs-wrapper"
      justify="between"
      height="fit-content"
      margin={{ bottom: 'xxl' }}
    >
      <Box>
        <ExpressSetupTopbar
          logo={expressSetupData?.logo}
          orgId={expressSetupData?.orgId}
          isLoading={isLoading}
          isShimmerHide
        />
        <Box width={theme.defaultMinContentWidth} alignSelf="center" margin="l">
          <Text weight={700} size="32px" color="textTitle" margin={{ bottom: 'l' }} style={{ lineHeight: '44px' }}>Activity Log</Text>
          <Box
            data-testId="activity-log-exress-setup"
            pad="l"
            background="module"
            round="container1Round"
          >
            <ActivityLog />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ExpressSetupActivityLogs;
