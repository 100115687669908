import React from 'react';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useRedirectHomeAccess } from '@/modules/core/hooks';
import { PlansType } from '@/modules/plan/plan.types';
import PlanSetup from '@/modules/plan/PlanSetup';
import { usePlanNameStore } from '@/modules/plan/PlanSetup/PlanDefinition/PlanName/stores';

const TemplatePage = () => {
  const planName = usePlanNameStore((state) => state.state.planName);

  useBreadcrumbs([{
    route: ROUTES.TEMPLATES,
    title: 'Templates',
    hash: PlansType.Template,
  },
  ...planName ? [{
    route: ROUTES.PLANS_VIEW,
    title: planName,
  }] : [],
  ], [planName], { backBtn: true });
  useRedirectHomeAccess([{
    permission: Permissions.VIEW_PLAN_TEMPLATE,
  }]);
  return (
    <PlanSetup isTemplateMode />
  );
};

export default TemplatePage;
