import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface RolloverFieldsStatePayload {
  rollover: PlanFieldState;
  maximumRolloverAmount: PlanFieldState;
  minimumRolloverAmount: PlanFieldState;
  rolloverClaims: PlanFieldState;
  claimsPriorYear: PlanFieldState;
  autoEnrollment: PlanFieldState;
  daysAfterPlanEnd: PlanFieldState;
}

export const DEFAULT_VALUES: RolloverFieldsStatePayload = {
  rollover: PlanFieldState.Modifiable,
  maximumRolloverAmount: PlanFieldState.Modifiable,
  minimumRolloverAmount: PlanFieldState.Modifiable,
  rolloverClaims: PlanFieldState.Modifiable,
  claimsPriorYear: PlanFieldState.Modifiable,
  autoEnrollment: PlanFieldState.Modifiable,
  daysAfterPlanEnd: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<RolloverFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
