import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';

import { emailTemplatesCategoriesOptions, emailTemplatesUserTypesOptions } from '../../emailTemplates.types';
import { EmailNotificationTemplate } from '../store/useEditEmailNotificationModal.store';

export const useEditEmailNotificationForm = (
  templateValues?: EmailNotificationTemplate,
  options?: Option[],
) => {
  const fields = useMemo(() => [
    {
      name: 'notificationName',
      type: FieldTypes.Text,
      label: 'Notification name',
      placeholder: 'Select Notification name',
      disabled: true,
      defaultValue: templateValues?.notificationName,
    },
    {
      name: 'templateName',
      type: FieldTypes.Dropdown,
      label: 'Template name',
      placeholder: 'Select Template name',
      showRequireIcon: true,
      options,
      singleMode: true,
      defaultValue: templateValues?.id,
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: 'category',
      type: FieldTypes.Dropdown,
      label: 'Category',
      options: emailTemplatesCategoriesOptions,
      disabled: true,
      placeholder: 'Select Category',
      defaultValue: templateValues?.category,
    },
    {
      name: 'destination',
      type: FieldTypes.Dropdown,
      label: 'Destination',
      options: emailTemplatesUserTypesOptions,
      disabled: true,
      placeholder: 'Select Destination',
      defaultValue: templateValues?.destination,
    },
  ], [templateValues, options]);

  return fields as Field[];
};
