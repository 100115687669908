import { baseStoreCreator } from '@/utils/store';

export interface AdditionalPayload {
  availableBalance: string;
  maximumElectionAmount: string;
  maximumElectionAmountCustom?: string;
  minimumElectionAmount: string;
  minimumElectionAmountCustom?: string;
  maximumElectionAmountIRS: string;
  proofOfExpense: string;
  CIPRequired: boolean;
  pendContributionsForCIP: boolean;
  CMSReportingRequired: string;
  allowInvestments: string;
  catchUpElections: string;
  catchUpAge: string;
  investmentOptionsType: string[];
  investmentThreshold: string;
  minimumInvestmentAmount: string;
  aiAdjudicationType: string;

  spendLimit: string;
  spendLimitIRSAmount: string;
  spendLimitCustom: string;
  spendPeriod: string;
  isOrphaned?: string;
  generateStatements: string;
}

export const DEFAULT_VALUES: AdditionalPayload = {
  availableBalance: '',
  maximumElectionAmount: '',
  maximumElectionAmountCustom: '',
  minimumElectionAmount: '',
  maximumElectionAmountIRS: '',
  minimumElectionAmountCustom: '',
  proofOfExpense: '',
  CIPRequired: false,
  pendContributionsForCIP: false,
  CMSReportingRequired: '',
  allowInvestments: '',
  catchUpElections: '',
  catchUpAge: '',
  investmentOptionsType: [],
  investmentThreshold: '',
  aiAdjudicationType: '',
  spendLimit: '',
  minimumInvestmentAmount: '',
  spendLimitIRSAmount: '',
  spendLimitCustom: '',
  spendPeriod: '',
  isOrphaned: '',
  generateStatements: '',
};

export const useStore = baseStoreCreator<AdditionalPayload>(DEFAULT_VALUES);

export default useStore;
