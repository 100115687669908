import React, { useCallback, useEffect,useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  AppButton,
  Box,
  CrossIcon,
  Heading,
  Inscription,
  Modal,
  Preloader,
  SelectDropdown,
  SuccessModal,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import PATHS from '@/common/paths';
import { OptionKey } from '@/common/types';

import { VOID_REASON_OPTIONS } from './constants';
import useVoidCheckQuery from './useVoidCheck.query';

const RequiredLabel = styled(Inscription)`
  &:before {
    content: '*';
    color: ${({ theme }) => theme.colors.danger};
    padding-right: 2px;
  }
`;

interface Props {
  id?: string;
  isVisible: boolean;
  close: () => void;
  refetch?: () => void;
}

const VoidCheckPopup: React.FC<Props> = ({ id, isVisible, close, refetch }) => {
  const queryClient = useQueryClient();
  const [reason, setReason] = useState<OptionKey>('');
  const [isVoidedSuccessfully, setIsVoidedSuccessfully] = useState(false);
  const onSuccess = useCallback(() => {
    setIsVoidedSuccessfully(true);
    close();
  }, [close, setIsVoidedSuccessfully]);
  const { mutateAsync: voidCheck, isLoading } = useVoidCheckQuery(onSuccess, id);
  useEffect(() => setReason(''), [isVisible, setReason]);

  if (isVoidedSuccessfully) {
    return (
      <SuccessModal
        testId="success_void_check"
        visible
        header={`Check #${id} has been voided`}
        buttonText="Got It!"
        onSetVisible={() => {
          setIsVoidedSuccessfully(false);
          setReason('');
          if (refetch) {
            refetch();
          } else {
            queryClient.invalidateQueries(PATHS.GET_CHECKS_LIST);
          }
        }}
      />
    );
  }

  return (
    <Modal
      testId="void_check"
      visible={isVisible}
      wrapperStyle={{ zIndex: 2002 }}
      onSetVisible={close}
    >
      <Heading level="4" fill textAlign="center">Void Check</Heading>
      <Inscription textAlign="center" lineHeight="22px" size="large" margin={{ top: 'spacing8' }}>
        Remember, voiding is irreversible. Optionally, choose a void reason for record-keeping. Verify new check details before confirming.
      </Inscription>
      <Box
        margin={{ vertical: 'spacing24' }}
        round="medium"
        pad="spacing24"
        background="module"
      >
        <Box
          style={{ boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.03)' }}
          pad="spacing24"
          background="canvas"
          round="small"
          border={{ color: 'border2' }}
          direction="row"
          align="center"
          justify="between"
        >
          <RequiredLabel weight="bold" lineHeight="20px" color="textTitle">Void reason</RequiredLabel>
          <Box width="320px">
            <SelectDropdown
              name="void_reasons"
              testId="void_reasons"
              id="void_reasons"
              options={VOID_REASON_OPTIONS}
              singleMode
              onChange={setReason}
              value={reason}
              placeholder="Select void reason"
              {...reason ? { rightIcon: <CrossIcon size="small" color="iconPrimary" onClick={() => setReason('')}/> } : {}}
            />
          </Box>
        </Box>
      </Box>
      <Box direction="row" justify="end">
        <AppButton
          buttonType="primary"
          color="blue"
          onClick={close}
          width="133px"
        >
          Cancel
        </AppButton>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            buttonType="primary"
            color="red"
            width="133px"
            disabled={isLoading || !reason}
            {...isLoading ? {} : { onClick: () => voidCheck(reason as string) }}
          >
            {isLoading ? <Preloader /> : 'Void'}
          </AppButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default VoidCheckPopup;
