import { useMemo } from 'react';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { EmployerStatus } from '@/modules/employer/employer.constants';

const useEmployerTerminationBanner = () => {
  const { observingEmployer } = useCurrentOrganization();

  const status = useMemo(
    () => observingEmployer?.status,
    [observingEmployer]);

  const showBanner = useMemo(
    () => (observingEmployer?.type === OrganizationTypes.company
    || observingEmployer?.type === OrganizationTypes.employer)
    && (status === EmployerStatus.PendingTermination
    || status === EmployerStatus.TerminationInProgress),
    [observingEmployer, status]);

  const terminationDate = useMemo(
    () => observingEmployer?.terminationDate
      ? dayjs(observingEmployer.terminationDate).format(DEFAULT_DATE_FORMAT)
      : undefined,
    [observingEmployer]);

  const organizationName = useMemo(
    () => observingEmployer?.name,
    [observingEmployer]);
  
  const organizationId = useMemo(
    () => observingEmployer?.id,
    [observingEmployer]);

  const bannerHeight: string | undefined = useMemo(() => showBanner ? '56px' : undefined, [showBanner]);
    

  return {
    status,
    showBanner,
    terminationDate,
    bannerHeight,
    organizationName,
    organizationId,
  };
};

export default useEmployerTerminationBanner;
