import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { SpendTracker } from '@/modules/deductibles';
import { useGetEmployeeById } from '@/modules/employee/Employee/hooks';

import { SUB_MODULES } from './EmployeeSidebar/hooks/useEmployeeSubModules';

const SpendTrackerBlock = () => {
  const { isLoading, data: employeeData } = useGetEmployeeById();

  const employeeUser = useMemo(
    () => ({
      id: `${employeeData?.internalId || ''}`,
      fullName: `${employeeData?.firstName || ''} ${employeeData?.lastName || ''}`.trim(),
    }), [employeeData],
  );
  if (isLoading) {
    return null;
  }
  return (
    <Box data-testid="spend-tracker-block" id={SUB_MODULES.SPEND_TRACKER}>
      <SpendTracker employeeUser={employeeUser} />
    </Box>
  );
};

export default SpendTrackerBlock;
