import React, { useCallback } from 'react';
import {
  Box, Field,   FlexControlledForm, Preloader,
} from '@common-fe/common-fe';

export interface ReportSettingDto {
  selectAccounts: string[];
  dateForReports: string;
  selectDownloadFormats: string;
}

interface Props {
  onChangeValues: (value: ReportSettingDto) => void;
  setDirtyForm: (dirty: boolean) => void;
  fields: Field[];
  loading: boolean;
  showErrors: boolean;
}

export const ReconciliationReportDownloadForm = React.forwardRef<
HTMLFormElement,
Props
>(({
  onChangeValues,
  setDirtyForm,
  fields,
  loading,
  showErrors,
}, ref) => {
  const renderForm = useCallback((fieldArr: Field[]) => (
    <FlexControlledForm
      fields={fieldArr}
      editMode
      onDirty={setDirtyForm}
      isModalType
      onChangeValues={onChangeValues}
      showError={showErrors}
    />
  ), [onChangeValues, setDirtyForm, showErrors]);

  if (loading) {
    return (
      <Box
        background="canvas"
        height="310px"
        align="center"
        justify="center"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        data-testid="ReconciliationReportDownloadForm-loader"
      >
        <Preloader color="white" />
      </Box>
    );
  }
  return (
    <Box
      background="canvas"
      round="container1Round"
      data-testid="ReconciliationReportDownloadForm"
    >
      {renderForm(fields)}
    </Box>
  );
});
