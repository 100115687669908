import React from 'react';
import { Box, Preloader, Text } from '@common-fe/common-fe';

import { Status } from '@/modules/transaction/ActivityStatus/ActivityStatus';

import { InfoTableValue, PrefundStatus } from '../finops.types';

interface FieldProps {
  isTitle?: boolean;
  testId?: string;
  styles?: React.CSSProperties;
  children?: React.ReactNode;
}

const PrefundField: React.FC<FieldProps> = ({
  children,
  isTitle,
  testId,
  styles,
}) => (
  <Box
    height={{ min: '40px' }}
    justify="center"
    data-testid={isTitle ? `${testId}Title_wrapper` : `${testId}Field_wrapper`}
    style={styles}
  >
    <Text weight={isTitle ? 700 : 400} style={{ lineHeight: '20px' }}>
      {children}
    </Text>
  </Box>
);

interface Props {
  values: InfoTableValue[];
  tableTitle: string;
  isLoading?: boolean;
  testId?: string;
  containerStyles?: React.CSSProperties;
  additionalContent?: React.ReactNode;
  exportButton?: React.ReactNode;
}

export const InfoTable: React.FC<Props> = ({
  values,
  tableTitle,
  isLoading,
  testId = 'InfoTable',
  containerStyles,
  additionalContent,
  exportButton,
}) => (
  <Box
    background="module"
    pad="spacing24"
    round="small"
    data-testid={`${testId}_wrapper`}
    style={containerStyles}
  >
    <Box
      background="canvas"
      pad={{ vertical: 'spacing24', horizontal: 'spacing32' }}
      round="small"
      border={{ color: 'border2' }}
    >
      <Box direction="row" justify="between" align="center">
        <Text size="20px" margin={{ bottom: 'spacing16' }}>
          {tableTitle}
        </Text>
        {exportButton}
      </Box>

      {isLoading
        ? (
          <Box>
            <Preloader />
          </Box>
        )
        : (
          <Box direction="row" data-testid={`${testId}_table`}>
            {/* <Box width="310px">
              {values.map(({ title }) => (
                <PrefundField
                  isTitle
                  key={title}
                  testId={testId}
                >
                  {title}
                </PrefundField>
              ))}
            </Box> */}
            <Box>
              {values.map(({
                value,
                isStatus,
                title,
                styles,
              }) => {
                if (isStatus && value) {
                  return (
                    <Box key={value} direction="row">
                      <Box width="310px">
                        <PrefundField
                          isTitle
                          key={title}
                          testId={testId}
                        >
                          {title}
                        </PrefundField>
                      </Box>
                      <Box height="40px" justify="center">
                        <Status
                          complete={value === PrefundStatus.Paid}
                          pending={
                            value === PrefundStatus.PaymentInitiated
                            || value === PrefundStatus.PaymentRequested
                          }
                          declined={
                            value === PrefundStatus.PaymentRejected
                            || value === PrefundStatus.PaymentFailed
                          }
                          customHeight="24px"
                          data-testid={`${testId}_status`}
                          reopen={value === PrefundStatus.Reopen}
                        >
                          <Text weight={400}>
                            {value}
                          </Text>
                        </Status>
                      </Box>
                    </Box>
                  );
                }
                if (!value) {
                  return (
                    <Box key={value} direction="row">
                      <Box width="310px">
                        <PrefundField
                          isTitle
                          key={title}
                          testId={testId}
                        >
                          {title}
                        </PrefundField>
                      </Box>
                      <PrefundField key={`${title}_emptyValue`} testId={testId}>
                        -
                      </PrefundField>
                    </Box>
                  );
                }
                return (
                  <Box direction="row" key={`${title}_container`}>
                    <Box width={{ min: '310px' }}>
                      <PrefundField
                        isTitle
                        key={title}
                        testId={testId}
                      >
                        {title}
                      </PrefundField>
                    </Box>
                    <PrefundField
                      key={value}
                      testId={testId}
                      styles={styles}
                    >
                      {value}
                    </PrefundField>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      {additionalContent}
    </Box>
  </Box>
);
