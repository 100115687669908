 
import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { PermissionKey,REQUIRED_TEXT } from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

const useIndividualContributionFields = (
  formattedData: EmployerDto,
  activateMode?: boolean,
  isLoading?: boolean,
): Field[] => {
  const eeContributionsHoldPeriodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EE_CONTRIBUTIONS_HOLD_PERIOD,
  );
  const { viewMode } = useSetupEmployerMode();

  return useMemo(() => {
    if (isLoading) return [];

    return [
      {
        name: 'eeContributionsHoldPeriod',
        type: FieldTypes.Mask,
        mask: '99',
        disabled: viewMode,
        // showRequireIcon: true,
        validator: activateMode
          ? yup.string().trim().required(REQUIRED_TEXT)
          : yup.string(),
        placeholder: 'Enter # of days',
        fill: false,
        label: (
          <Box flex={{ grow: 1 }}>
            <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
              Hold period
            </Inscription>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small">
                Number of days contributions will pend on the account before becoming available
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.eeContributionsHoldPeriod,
        hasHorizontalLine: true,
        ...eeContributionsHoldPeriodPermProps,
      },
    ] as Field[];
  }, [
    isLoading,
    viewMode,
    formattedData,
    activateMode,
    eeContributionsHoldPeriodPermProps,
  ]);
};

export default useIndividualContributionFields;
