import React from 'react';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { PlansType } from '@/modules/plan/plan.types';
import PlanSetup from '@/modules/plan/PlanSetup';
import { usePlanNameStore } from '@/modules/plan/PlanSetup/PlanDefinition/PlanName/stores';

const NewPlanPage = () => {
  const planName = usePlanNameStore((state) => state.state.planName);

  useBreadcrumbs([{
    route: ROUTES.PLANS,
    title: 'Plans',
    hash: PlansType.Plan,
  },
  ...planName ? [{
    route: ROUTES.PLANS_VIEW,
    title: planName,
  }] : [],
  ], [planName], { backBtn: true });
  return (
    <PlanSetup />
  );
};

export default NewPlanPage;
