import { useCallback } from 'react';
import _ from 'lodash';

import { PlanResponse,PlanStatuses } from '@/modules/plan/plan.types';

import { useUpdatePlanByIdQuery } from '../queries';

export const YES_KEY = 'true';
export const NO_KEY = 'false';
export default (id: string) => {
  const mutation = useUpdatePlanByIdQuery(id);
  const handleSave = useCallback(async (
    planName: string,
    planStatus?: PlanStatuses,
    planYearID?: number,
    planTemplate?: string,
  ) => {
    const payload: Partial<PlanResponse> = {
      id,
      is_plan: true,
      plan_status: planStatus,
      plan_year_id: planYearID,
      parent_id: planTemplate ? _.toNumber(planTemplate) : undefined,
      name: {
        name: planName,
      },
    };

    await mutation.mutateAsync({
      dto: payload,
    });
  }, [id, mutation]);

  return handleSave;
};
