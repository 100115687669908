import { useCallback, useEffect } from 'react';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import { activationKey } from '@/modules/ExpressSetup/ExpressSetupActivateModal';
import { getIdsFromPath } from '@/utils';

import { ExpressSetupStatuses } from '../../ExpressSetup.types';
import { useUpdateExpressSetupStatusByIdQuery } from '../../queries';

interface Props {
  orgPath?: string;
  orgId?: string;
}

export const useProgressBarActions = ({ orgPath, orgId }: Props) => {
  const history = useHistory();
  const shouldActivate = localStorage.getItem(activationKey) === 'true';
  const {
    handleSave,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateExpressSetupStatusByIdQuery();

  const handleRedirectToObservingMode = useCallback(() => {
    const { partnerId, distributorId, employerId } = getIdsFromPath(orgPath);
    history.push(ROUTES.HOME, 
      {
        [QueryKeys.OBSERVING_PARTNER_QUERY_KEY]: partnerId,
        [QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]: distributorId,
        [QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]: employerId,
      });

  }, [orgPath, history]);
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  const handleActivate = useCallback(() => handleSave({ id: orgId || '', status: ExpressSetupStatuses.ACTIVE }), [handleSave, orgId]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(handleRedirectToObservingMode, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, handleRedirectToObservingMode]);

  useEffect(() => {
    if (!shouldActivate) {
      return goHome();
    }
    const timer = setTimeout(handleActivate, 5000);
    return () => {
      localStorage.removeItem(activationKey);
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSuccess,
    isError,
    isLoading,
    handleActivate,
    handleRedirectToObservingMode,
  };
};
