import React, { useMemo } from 'react';
import {
  BankRoutingAccountNumbers,
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import styled from 'styled-components';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import masks from '@/common/masks';
import regexp from '@/common/regexp';
import SecurityCardInfoPopup from '@/modules/ReimburseMe/CardInfoPopup';
import { PaymentMethodOwnerType, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';

const InfoIcon = styled(Box)`
  position: absolute;
  right: 12px;
`;

const renderInfoIcon = () => (
  <InfoIcon margin={{ right: 'spacing12' }}>
    <SecurityCardInfoPopup
      icon={<BankRoutingAccountNumbers />}
      title="The routing number and your personal account number can both be found on the bottom of the checks issued by your bank."
      description={(
        <Box direction="column">
          <Inscription size="small" lineHeight="18px">
            1. Bank routing number
          </Inscription>
          <Inscription size="small" lineHeight="18px">
            2. Bank account number
          </Inscription>
        </Box>
      )}
    />
  </InfoIcon>
);

export enum BankAccountTypes {
  checking = 'CHECKING',
  savings = 'SAVING',
}

export enum BankFields {
  associatedName = 'associatedName',
  routingNumber = 'routingNumber',
  accountNumber = 'accountNumber',
  accountType = 'accountType',
  confirmAccountNumber = 'confirmAccountNumber',
  organizationPath = 'organizationPath',
  createDate = 'createDate',
  paymentMethodType = 'paymentMethodType',
  paymentMethodOwnerType = 'paymentMethodOwnerType',
}

export interface BankInfo {
  id?: string;
  [BankFields.associatedName]?: string;
  [BankFields.routingNumber]?: string;
  [BankFields.accountNumber]?: string;
  [BankFields.confirmAccountNumber]?: string;
  [BankFields.organizationPath]?: string;
  [BankFields.createDate]?: Date;
  [BankFields.accountType]?: BankAccountTypes;
  [BankFields.paymentMethodType]?: PaymentMethodType;
  [BankFields.paymentMethodOwnerType]?: PaymentMethodOwnerType;
}

const useProviderFields = (defaultFields?: BankInfo): Field[] => {
  const bankFields = useMemo(() => [
    {
      name: BankFields.associatedName,
      type: FieldTypes.Text,
      label: 'Account description',
      labelStyle: { maxWidth: '278px' },
      placeholder: 'Account description',
      defaultValue: defaultFields?.associatedName || '',
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: BankFields.routingNumber,
      type: FieldTypes.Mask,
      label: 'Bank routing number',
      labelStyle: { maxWidth: '278px' },
      placeholder: 'Bank Routing Number',
      defaultValue: `${defaultFields?.routingNumber?.substring(0, 4)} ${defaultFields?.routingNumber?.substring(4)}` || '',
      mask: masks.ROUTING_NUMBER,
      rightIcon: renderInfoIcon(),
      validator: yup.string()
        .test('len', 'Must be exactly 9 characters.', (val) => !val || val.length === masks.ROUTING_NUMBER.length)
        .required(REQUIRED_TEXT),
    },
    {
      name: BankFields.accountNumber,
      type: FieldTypes.Mask,
      label: 'Bank account number',
      labelStyle: { maxWidth: '278px' },
      placeholder: 'Bank Account Number',
      defaultValue: defaultFields?.accountNumber || '',
      mask: masks.ACCOUNT_NUMBER,
      formatChars: { '9': regexp.ALPHANUMERIC },
      secure: true,
      rightIcon: renderInfoIcon(),
      validator: yup.string()
        .test(
          'len',
          'Must be between 4 and 17 characters.',
          (val) => !val || (val.length >= 4 && val.length <= masks.ACCOUNT_NUMBER.length),
        )
        .required(REQUIRED_TEXT),
    },
    {
      name: BankFields.confirmAccountNumber,
      type: FieldTypes.Mask,
      mask: masks.ACCOUNT_NUMBER,
      label: 'Confirm bank account number',
      labelStyle: { maxWidth: '278px' },
      placeholder: 'Confirm Bank Account Number',
      formatChars: { '9': regexp.ALPHANUMERIC },
      validator: yup.string()
        .test(
          'confirmation', 'Account numbers must match.',
          (val, { parent }) => val === parent?.[BankFields.accountNumber],
        )
        .required(REQUIRED_TEXT),
    },
    {
      testId: 'ReimburseMe_choose_bank_account_type',
      name: BankFields.accountType,
      type: FieldTypes.Radio,
      label: 'Bank account type',
      labelStyle: { maxWidth: '278px' },
      defaultValue: defaultFields?.accountType || '',
      options: [
        {
          label: 'Checking',
          value: BankAccountTypes.checking,
        },
        {
          label: 'Savings',
          value: BankAccountTypes.savings,
        },
      ],
      validator: yup.string().required(REQUIRED_TEXT),
    },
  ], [defaultFields]);

  return bankFields as Field[];
};

export default useProviderFields;
