import React from 'react';
import { Box, capitalizeFirstLetter, Circle, Inscription } from '@common-fe/common-fe';

import { LockboxStatus } from '@/modules/employer/components/Lockbox/lockbox.types';

import LockboxNavigationButtons from './LockboxNavigationButtons';

const LockboxStatusWithCircle: React.FC<{ lockboxStatus?: LockboxStatus }> = ({ lockboxStatus }) => (
  <Box direction="row" align="center">
    <Inscription margin={{ right: 'spacing8' }}>
      {capitalizeFirstLetter(lockboxStatus || '')}
    </Inscription>
    <Circle status={`${lockboxStatus}_lockbox`} />
  </Box>
);

const LockboxName: React.FC<{ lockboxName?: string }> = ({ lockboxName }) => (
  <Inscription
    margin={{ left: 'spacing16' }}
    size="2xl"
    weight={700}
    lineHeight="32px"
    color="textTitle"
  >
    {lockboxName}
  </Inscription>
);

interface Props {
  lockboxId: string;
  lockboxName?: string;
  lockboxStatus?: LockboxStatus;
}

const LockboxDetailsHeader: React.FC<Props> = ({ lockboxId, lockboxName, lockboxStatus }) => {
  return (
    <Box
      data-testId="lockbox-details-header"
      direction="row"
      align="center"
      justify="between"
      pad={{ vertical: 'spacing8', horizontal: '40px' }}
      border={{ color: 'border2', side: 'bottom', size: 'small' }}
      height={{ min: '48px' }}
    >
      <Box direction="row" align="center">
        <LockboxNavigationButtons lockboxId={lockboxId} />
        <LockboxName lockboxName={lockboxName} />
      </Box>
      <Box pad={{ right: 'spacing8' }}>
        <LockboxStatusWithCircle lockboxStatus={lockboxStatus} />
      </Box>
    </Box>
  );
};

export default LockboxDetailsHeader;
