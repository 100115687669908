import { baseStoreCreator } from '@/utils/store';

export interface PlanYearPickerPayload {
  planYear: string;

}

export const DEFAULT_VALUES: PlanYearPickerPayload = {
  planYear: '',
};

export const useStore = baseStoreCreator<PlanYearPickerPayload>(DEFAULT_VALUES);

export default useStore;
