import React from 'react';
import { Box } from '@common-fe/common-fe';

import {
  SETUP_CONTAINER_UI_ID,
} from '@/modules/core/hooks/useDetectActiveTOCModule';

import { HealthPlanStatuses } from '../HealthPlan.types';

import { useHealthPlanModules, useProgressScroll } from './Sidebar/hooks';
import Sidebar from './Sidebar';

interface Props {
  children: React.ReactNode;
  planStatus?: HealthPlanStatuses;
}
const PlanSetupWrapper: React.FC<Props> = ({ children, planStatus }) => {
  const config = useHealthPlanModules();

  const { onScrollHandler, scrollToSection } = useProgressScroll(config);

  return (
    <Box
      direction="row"
      overflow="hidden"
      flex={{ grow: 1 }}
    >
      <Sidebar planStatus={planStatus} scrollToSection={scrollToSection} />
      <Box
        fill
        overflow="auto"
        onScroll={onScrollHandler}
        id={SETUP_CONTAINER_UI_ID}
        align="center"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PlanSetupWrapper;
