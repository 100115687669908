import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  BasicPropertiesIcon,
  CardsIcon,
  ClockTickIcon,
  CommunicationIcon,
  ContactsSecurityIcon,
  FeaturesIcon,
  FilesImportsIcon,
  FinalSetupIcon,
  FinancialDetailsIcon,
  HandIcon,
  OrgLockIcon,
  ReportsTOCIcon,
  SetupOutlinedIcon,
  WarningIcon,
} from '@common-fe/common-fe';

import { FeatureFlagKeys, OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { TOCSidebarProps } from '@/modules/core/components/TOCSidebar/TOCSidebar.types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useHasAccess } from '@/modules/core/hooks';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';
import useAddressFields from '@/modules/employer/components/SetupEmployer/Address/useAddressFields';
import { useCardSetupForm } from '@/modules/employer/components/SetupEmployer/Cards/CardSetup/hooks/useCardSetupForm';
import { useEmployerIdFields } from '@/modules/employer/components/SetupEmployer/EmployeeId/useEmployerIdFields';
import useEmployerFields from '@/modules/employer/components/SetupEmployer/Employer/useEmployerFields';
import useAccountUsageFields from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/hooks/useAccountUsageFields';
import { useAccountUsageStore } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/stores';
import useBanksList from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BanksList/useBanksList';
import { useFundingModelFields } from '@/modules/employer/components/SetupEmployer/FinancialDetails/FundingModel/hooks';
import useGeneralInformationFields from '@/modules/employer/components/SetupEmployer/GeneralInformation/useGeneralInformationFields';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import useBusinessEntityTypesQuery from '@/modules/employer/components/SetupEmployer/queries/useBusinessEntityTypes.query';
import useСheckWritingsQuery from '@/modules/employer/components/SetupEmployer/queries/useCheckWritings.query';
import useElectronicFundingReplenishmentsQuery from '@/modules/employer/components/SetupEmployer/queries/useElectronicFundingReplenishments.query';
import useHsaCustodianQuery from '@/modules/employer/components/SetupEmployer/queries/useHsaCustodian.query';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { useBankMethodsQuery } from '@/modules/employer/hooks/index';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';
import { useEnvSpecificAvailable } from '@/utils';
import { fieldArrToMap } from '@/utils/handlers';
import { useCalculateFillProgress } from '@/utils/hooks/useCalculateFillProgress';
import useReversedFeatureFlag from '@/utils/hooks/useFeatureFlag';

import { useEmailConfigurationStore } from '../components/SetupEmployer/Communication/EmailConfiguration/stores';
import { useCommunicationChannelStore } from '../components/SetupEmployer/Communication/stores/useCommunicationChannelStore';
import { EmployerStatus } from '../employer.constants';
import useContributionsFeatureFlag from '../useContributionsFeatureFlag';

const REQUIRED_PERMISSIONS = [{ permission: Permissions.ORG_LOCK }];

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const useBasicPropertiesFillProgress = (formattedData: any) => {
  const isSubsidiary = useMemo(() => formattedData.type === OrganizationTypes.subsidiary, [formattedData]);
  const { address, employer, generalInfo, employeeId } = useSetupEmployerStore();
  const { data: businessTypes } = useBusinessEntityTypesQuery();
  const { data: hsaCustodians } = useHsaCustodianQuery();
  const {
    formattedData: { name },
  } = useGetOrganizationById(formattedData.grandparentId);
  const { pendingStatus } = useSaveTypeStore();
  const { viewMode } = useSetupEmployerMode();
  const addressFieldsDefaultValues = useMemo(
    () => ({
      addresses: [
        {
          line1: address?.street,
          line2: address?.street_2,
          city: address?.city,
          state: address?.state,
          zipcode: address?.zipCode,
        },
      ],
    }),
    [address]
  );
  const employerFields = useEmployerFields(
    !pendingStatus,
    {
      name: employer?.employerName,
      externalIdentifier: employer?.employerId,
    },
    name
  );

  const addressFields = useAddressFields(!pendingStatus, addressFieldsDefaultValues);
  const generalInformationFields = useGeneralInformationFields(
    [],
    businessTypes,
    hsaCustodians,
    !pendingStatus,
    {
      shortName: generalInfo?.dba,
      businessEntityType: generalInfo?.businessType,
      organizationPhone: {
        number: generalInfo?.phoneNumber,
        phoneExtension: generalInfo?.phoneNumberExtension,
      },
      overridableProperties: {
        themeId: generalInfo?.themeId,
        hsaCustodianId: generalInfo?.hsaCustodianId,
        url: generalInfo?.url,
        ssoOnly: generalInfo?.ssoOnly,
      },
      normalProperties: {
        accountManagerId: generalInfo?.accountManagerId,
        taxId: generalInfo?.taxID,
        implementationManagerId: generalInfo?.implementationManagerId,
      },
    }
  );

  const employerIdFields = useEmployerIdFields({
    viewMode,
    options: [],
    employerId: employeeId?.eeid,
    isSubsidiary,
  });

  return useCalculateFillProgress(
    fieldArrToMap(employerFields),
    fieldArrToMap(addressFields),
    fieldArrToMap(generalInformationFields),
    fieldArrToMap(employerIdFields)
  );
};

const useFundingModelFillProgress = () => {
  const { fundingModel, primaryContactId, hasOneContact } = useSetupEmployerStore();
  const accountUsageStore = useAccountUsageStore();
  const { data: checkWritings } = useСheckWritingsQuery();
  const { data: electronicFundingReplenishments } = useElectronicFundingReplenishmentsQuery();
  const { isEmpty: isEmptyBankList } = useBanksList();
  const { methods } = useBankMethodsQuery();
  const { list: banks } = useBanksList();

  const fundingModelFields = useFundingModelFields(checkWritings, electronicFundingReplenishments, {
    overridableProperties: {
      electronicFundingModeId: fundingModel.electronicFundingReplenishment,
      checkFundingModel: fundingModel.checkWriting,
    },
  });

  const accountUsageFields = useAccountUsageFields(banks, methods, {
    overridableProperties: {
      externalAdministratorFeesAccountMethod: accountUsageStore.state.administratorFeesMethod,
      externalPayrollContributionsAccountMethod: accountUsageStore.state.payrollContributionMethod,
      externalReplenishmentAccountMethod: accountUsageStore.state.fundingReplenishmentMethod,
      payrollContributionsHoldPeriod: accountUsageStore.state.holdPeriod,
      eeContributionsHoldPeriod: accountUsageStore.state.eeContributionsHoldPeriod,
    },
    normalProperties: {
      externalAdministratorFeesAccount: accountUsageStore.state.administratorFeesBank,
      externalPayrollContributionsAccount: accountUsageStore.state.payrollContributionBank,
      externalReplenishmentAccount: accountUsageStore.state.fundingReplenishmentBank,
    },
  });

  const progress = useCalculateFillProgress(
    fieldArrToMap(fundingModelFields),
    fieldArrToMap(accountUsageFields)
  );
  let total = progress.total + 1;
  let { passed } = progress;

  if (!isEmptyBankList) passed += 1;
  if (hasOneContact) total += 1;
  if (hasOneContact && primaryContactId) passed += 1;

  return useMemo(
    () => ({
      total,
      passed,
    }),
    [total, passed]
  );
};

const useCommunicationFillProgress = () => {
  const communicationChannelStoreState = useCommunicationChannelStore((state) => state.state);
  const emailConfigurationStore = useEmailConfigurationStore((state) => state.state);
  return useCalculateFillProgress(communicationChannelStoreState, emailConfigurationStore);
};

const useCardFillProgress = () => {
  const fields = useCardSetupForm();
  return useCalculateFillProgress(fieldArrToMap(fields));
};

interface Params {
  isPartner?: boolean;
}
export const useTOCConfiguration = (params?: Params): TOCSidebarProps['stepsMap'] => {
  const isOnlyForDevAndUAT = useEnvSpecificAvailable({
    dev: true,
    uat: true,
  });
  const isContributionsFeatureFlag = useContributionsFeatureFlag();
  const { hasOneContact } = useSetupEmployerStore();
  const routeMatch = useRouteMatch();
  const hasAccess = useHasAccess(REQUIRED_PERMISSIONS);

  const isExpressSetupLink = useReversedFeatureFlag(FeatureFlagKeys.IS_EXPRESS_SETUP_VIA_LINK, {
    dev: true,
  });
  const isSubsidiaryPage = useMemo(
    () =>
      routeMatch.path === ROUTES.SUBSIDIARY_CREATE ||
      routeMatch.path === ROUTES.SUBSIDIARY_EDIT() ||
      routeMatch.path === ROUTES.SUBSIDIARY_VIEW(),
    [routeMatch.path]
  );
  const { mode } = useSetupEmployerMode();
  const {
    observingOrganization: { type, status },
  } = useCurrentOrganization();
  const isSystemLevel = useMemo(() => type === OrganizationTypes.system, [type]);
  const isPartnerLevel = useMemo(() => type === OrganizationTypes.partner, [type]);
  const isDistributorLevel = useMemo(() => type === OrganizationTypes.distributor, [type]);
  const { formattedData } = useGetEmployer();
  const basicPropertiesFillProgress = useBasicPropertiesFillProgress(formattedData);
  const fundingModelFillProgress = useFundingModelFillProgress();
  const communicationFillProgress = useCommunicationFillProgress();
  const cardFillProgress = useCardFillProgress();
  const accountUsageStore = useAccountUsageStore();
  const showPreFund = useMemo(() => accountUsageStore.state.fundingReplenishmentBank, [accountUsageStore]);

  const basicConfig: TOCSidebarProps['stepsMap'] = useMemo(
    () => ({
      basic_properties: {
        title: 'Basic Properties',
        required: true,
        icon: <BasicPropertiesIcon color="iconAccent" />,
        sections: {
          address: 'Address',
          general_information: 'General Information',
          employee_ID: 'Employee ID',
          branding: 'Logo',
          ...(params?.isPartner ? { email_configuration: 'Email Theme Configuration' } : {}),
          email_template_configuration_list: 'Email Template Configuration',
          ...(params?.isPartner ? { mobile_app_customization: 'Mobile App Customization' } : {}),
          link_management_section: 'Link Management',
          help_section: 'Help',
        },
        process: basicPropertiesFillProgress,
      },
      contacts_security: {
        title: 'Contacts/Security',
        required: true,
        icon: <ContactsSecurityIcon color="iconAccent" />,
        sections: {
          contact_information: 'Contact Information',
          multi_factor_authentication: 'Multi-Factor Authentication',
        },
      },
      communication: {
        title: 'Communication',
        required: false,
        icon: <CommunicationIcon color="iconAccent" />,
        sections: {
          ...(params?.isPartner ? { communication_channels: 'Communication methods' } : {}),
          ...(params?.isPartner ? { email_sms_communications: 'Email/Text communications' } : {}),
          statements: 'Statements',
        },
        process: communicationFillProgress,
      },
    }),
    [basicPropertiesFillProgress, communicationFillProgress, params]
  );
  const employerSteps: TOCSidebarProps['stepsMap'] = useMemo(
    () => ({
      financial_details: {
        title: 'Financial Details',
        required: true,
        icon: <FinancialDetailsIcon color="iconAccent" />,
        sections: {
          funding_model: 'Funding Model',
          ...(hasOneContact ? { invoicing_details: 'Invoicing Details' } : {}),
          bank_information: 'Bank Information',
          administrator_fees: 'Administrator Fees',
          payroll_contributions: 'Payroll Contributions',
          individual_contributions: 'Individual Contributions',
          ...(isOnlyForDevAndUAT
            ? {
              admin_contributions: 'Admin Contributions',
            }
            : {}),
          funding_replenishment: 'Funding Replenishment',
          ...(showPreFund ? { pre_fund: 'Pre-Fund' } : {}),
        },
        process: fundingModelFillProgress,
      },
      features: {
        title: 'Features',
        required: false,
        icon: <FeaturesIcon color="iconAccent" />,
        sections: {
          plan_year_section: 'Plan Year',
          ...(!isSystemLevel ? { plan_offering: 'Plan Offering' } : {}),
          ...(!isSystemLevel ? { health_plan_offering: 'Health Plan Offering' } : {}),
          ...(isContributionsFeatureFlag && !isSystemLevel && !isPartnerLevel && !isDistributorLevel
            ? { payroll_group: 'Payroll Group' }
            : {}),
          ...(isContributionsFeatureFlag && !isSystemLevel && !isPartnerLevel && !isDistributorLevel)
            ? { payroll_calendar: 'Payroll Calendar' }
            : {},
          reimbursement_options: 'Reimbursement options',
          orphan_configuration: 'Orphan configuration',
          ...(isExpressSetupLink && (isPartnerLevel || isDistributorLevel)
            ? {
              express_setup_links: 'Express Employer Setup Via External Link',
            }
            : {}),
        },
      },
      cards: {
        title: 'Cards',
        required: false,
        icon: <CardsIcon color="iconAccent" />,
        sections: {
          cards_section: 'Cards',

          // copay_setup: 'Copay Setup',
        },
        process: cardFillProgress,
      },
      reports: {
        title: 'Reports',
        required: false,
        icon: <ReportsTOCIcon color="iconAccent" />,
      },
      files_imports: {
        title: 'Files/Imports',
        required: false,
        icon: <FilesImportsIcon color="iconAccent" />,
      },
      technical_settings: {
        title: 'Technical Settings',
        required: false,
        icon: <SetupOutlinedIcon color="iconAccent" />,
      },
      claims_settings: {
        title: 'Claims',
        required: false,
        icon: <HandIcon color="iconAccent" />,
      },
    }),
    [
      hasOneContact,
      isOnlyForDevAndUAT,
      showPreFund,
      fundingModelFillProgress,
      isSystemLevel,
      isPartnerLevel,
      isDistributorLevel,
      isExpressSetupLink,
      cardFillProgress,
      isContributionsFeatureFlag,
    ]
  );
  const partnerSteps: TOCSidebarProps['stepsMap'] = useMemo(
    () => ({
      financial_details: {
        title: 'Financial Details',
        required: true,
        icon: <FinancialDetailsIcon color="iconAccent" />,
        sections: {
          funding_model: 'Funding Model',
          ...(hasOneContact ? { invoicing_details: 'Invoicing Details' } : {}),
          bank_information: 'Bank Information',
          administrator_fees: 'Administrator Fees',
          payroll_contributions: 'Payroll Contributions',
          individual_contributions: 'Individual Contributions',
          ...(isOnlyForDevAndUAT
            ? {
              admin_contributions: 'Admin Contributions',
            }
            : {}),
          funding_replenishment: 'Funding Replenishment',
          ...(showPreFund ? { pre_fund: 'Pre-Fund' } : {}),
          // payroll: 'Payroll',
        },
        process: fundingModelFillProgress,
      },
      features: {
        title: 'Features',
        required: false,
        icon: <FeaturesIcon color="iconAccent" />,
        sections: {
          ...(isPartnerLevel ? { available_plan_templates: 'Available plan templates' } : {}),
          reimbursement_options: 'Reimbursement options',
          ...(isOnlyForDevAndUAT && !isSystemLevel && !isPartnerLevel && !isDistributorLevel
            ? { payroll_group: 'Payroll Group' }
            : {}),
          ...(isContributionsFeatureFlag && !isSystemLevel && !isPartnerLevel && !isDistributorLevel)
            ? { payroll_calendar: 'Payroll Calendar' }
            : {},
          ...(isExpressSetupLink && (isPartnerLevel || isDistributorLevel)
            ? {
              express_setup_links: 'Express Employer Setup Via External Link',
            }
            : {}),
        },
      },
      cards: {
        title: 'Cards',
        required: false,
        icon: <CardsIcon color="iconAccent" />,
        sections: {
          cards_section: 'Cards',
        },
        process: cardFillProgress,
      },
      reports: {
        title: 'Reports',
        required: false,
        icon: <ReportsTOCIcon color="iconAccent" />,
      },
      files_imports: {
        title: 'Files/Imports',
        required: false,
        icon: <FilesImportsIcon color="iconAccent" />,
        hide: isSubsidiaryPage,
      },
      technical_settings: {
        title: 'Technical Settings',
        required: false,
        icon: <SetupOutlinedIcon color="iconAccent" />,
        hide: isSubsidiaryPage,
      },
      claims_settings: {
        title: 'Claims',
        required: false,
        icon: <HandIcon color="iconAccent" />,
      },
    }),
    [
      hasOneContact,
      showPreFund,
      fundingModelFillProgress,
      isPartnerLevel,
      isExpressSetupLink,
      isDistributorLevel,
      cardFillProgress,
      isSubsidiaryPage,
      isOnlyForDevAndUAT,
      isSystemLevel,
      isContributionsFeatureFlag
    ]
  );
  const items = useMemo(
    () => ({
      ...basicConfig,
      ...(mode === EmployerSetupModes.employer ? employerSteps : partnerSteps),

      // status: {
      //   title: 'Status',
      //   required: false,
      // },
      activity_log: {
        title: 'Activity Log',
        required: false,
        icon: <ClockTickIcon style={{ width: '24px' }} color="iconAccent" />,
      },
      final: {
        title: 'Final Setup',
        required: true,
        icon: <FinalSetupIcon color="iconAccent" />,
        hide: mode === EmployerSetupModes.system,
      },
      ...(mode === EmployerSetupModes.employer && {
        organisation_lock: {
          title: 'Organization Lock',
          required: false,
          icon: <OrgLockIcon color="iconAccent" />,
          hide: !hasAccess,
        },
      }),
      ...(mode === EmployerSetupModes.employer && {
        danger_zone: {
          title: 'Danger Zone',
          required: false,
          icon: <WarningIcon />,
          hide: status !== EmployerStatus.Active && status !== EmployerStatus.PendingTermination,
        },
      }),
    }),
    [basicConfig, employerSteps, mode, partnerSteps, hasAccess, status]
  );

  return items;
};
