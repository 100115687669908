import { useEffect,useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { BEARER } from '@/api/api';
import { CONFIG } from '@/utils';
import Token from '@/utils/handlers/Token';

const GATEWAY_KEY = 'gateway';
const API_KEY = 'api';

const useGetStream = (path: string) => {
  const [stream, setStream] = useState<EventSourcePolyfill>();

  useEffect(() => {
    setStream(
      new EventSourcePolyfill(
        CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY) + path,
        {
          headers: {
            Authorization: `${BEARER} ${Token.getToken()}`,
          },
        },
      ),
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return stream;
};

export default useGetStream;
