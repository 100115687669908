import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';
import { usePlanTypesQuery } from '@/modules/plan/queries';

import { PlanTypes } from '../../PlanTypes.types';

export default (defaultValue?: PlanTypes) => {
  const planTypes = usePlanTypesQuery();
  const { list: planYearsList } = usePlanYearsQuery();
  const fields: Field[] = useMemo(() => [
    {
      name: 'planTemplate',
      type: FieldTypes.AutocompleteDropdown,
      label: 'Plan template',
      placeholder: 'Select a plan template',
      validator: yup.string().required(REQUIRED_TEXT),
      options: planTypes,
      defaultValue: defaultValue?.planType || '',
      disabled: !!defaultValue?.planType,
    },
    {
      name: 'planName',
      type: FieldTypes.Text,
      label: 'Plan name',
      placeholder: 'Enter Plan name',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: defaultValue?.planName || '',
    },
    {
      name: 'planYearId',
      type: FieldTypes.Dropdown,
      label: 'Plan year',
      singleMode: true,
      options: planYearsList,
      placeholder: 'Select plan year',
      validator: yup.string(),
      disabled: true,
      defaultValue: `${defaultValue?.planYearId || ''}`,
    },
  ], [defaultValue, planTypes, planYearsList]);

  return fields;
};
