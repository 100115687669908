import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import colors from './colors';
import fonts from './fonts';
import mixins from './mixins';
import rounds from './rounds';
import spacings from './spacings';

export const SM_QUERY = '@media (max-width: 1440px)';
export const MD_QUERY = '@media (min-width: 1441px) and (max-width: 1920px)';
export const LG_QUERY = '@media (min-width: 1921px)';

const theme = {
  colors,
  fonts,
  spacings,
  rounds,
  mixins,
  impairment: false,
  stepperSize: '340px',
  defaultContentWidth: '1088px',
  defaultMinContentWidth: '976px',
  controls: {
    size: 24,
    padding: 2,
    formWidth: {
      width: '320px',
    },
    mediumSize: '160px',
    height: '40px',
    maxHeight: '54px',
    sidebarSize: '272px',
    width: '200px',
    cardHeight: '220px',
    mediumWidth: '280px',
    minifiedSidebarSize: '90px',
    buttons: {
      height: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        height: '40px',
      },
      minWidth: {
        'min-width': '130px',
        'box-sizing': 'border-box',
      },
    },
  },
  shadows: {
    default: {
      'box-shadow': whiteLabelingConfig?.shadows?.default || '0px 6px 15px rgba(0, 0, 0, 0.03)',
    },
    hover: {
      'box-shadow': whiteLabelingConfig?.shadows?.hover || '0px 8px 15px rgba(0, 38, 102, 0.1)',
    },
    active: {
      'box-shadow': whiteLabelingConfig?.shadows?.active ||  '0px 8px 25px rgba(0, 38, 102, 0.2)',
    },
    highlighted: {
      'box-shadow': whiteLabelingConfig?.shadows?.highlighted || '0px 6px 16px rgba(23, 106, 246, 0.5)',
    },

    primary: {
      'box-shadow': '0px 6px 16px rgba(23, 106, 246, 0.5)',
    },
    error: {
      'box-shadow': '0px 6px 16px rgba(231, 38, 38, 0.5)',
    },
    warning: {
      'box-shadow': '0px 6px 16px rgba(249, 193, 52, 0.5)',
    },
    success: {
      'box-shadow': '0px 6px 16px rgba(25, 178, 105, 0.5)',
    },
  },
  padding: {
    regular: '16px',
  },
  border: {
    transparent: {
      border: '0px solid transparent',
    },
    primary: {
      border: `2px solid ${colors.accentActive}`,
    },
    secondary: {
      border: `2px solid ${colors.buttonSecondary}`,
    },
    primaryDashed: {
      border: `2px dashed ${colors.accentActive}`,
    },
    primaryTransparent: {
      border: '2px dashed transparent',
    },
    primaryBig: {
      border: `3px solid ${colors.accentActive}`,
    },
    grey: {
      border: `1px solid ${colors.border}`,
    },
    grey4: {
      border: `1.5px solid ${colors.border}`,
    },
    grey4bold: {
      border: `2px solid ${colors.border2}`,
    },
    grey5: {
      border: `1px solid ${colors.border1}`,
    },
    grey5bold: {
      outline: `2px solid ${colors.border1}`,
    },
    grey6: {
      border: `1px solid ${colors.border2}`,
    },
    blueGray2: {
      border: `1px solid ${colors.border1}`,
    },
    grey12: {
      border: `1px solid ${colors.border1}`,
    },

    bottomGrey: {
      'border-bottom': `1px solid ${colors.border2}`,
    },
    left: {
      grey: {
        'border-left': `1px solid ${colors.border2}`,
      },
    },
  },

  height: {
    input: {
      height: '40px',
    },
    activity: {
      'min-height': '64px',
    },
    card: {
      'min-height': '180px',
    },
  },
  animtaion: {
    fast: {
      transition: 'all 0.15s',
    },
  },
  dashedBox: {
    primary: {
      backgroundImage:
        'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'12\' ry=\'12\' stroke=\'%234588F8FF\' stroke-width=\'2\' stroke-dasharray=\'4%2c 4\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
      borderRadius: '12px',
    },
  },
  input: {
    common: {
      width: '100%',
      flex: 1,
      color: colors.textBody,
      'min-height': '40px',
      outline: 'none',
      'box-sizing': 'border-box',
    },
    hover: {
      'border-width': spacings.spacing2,
    },
    placeholder: {
      color: colors.textSecondary,
    },
    focus: {
      'border-color': colors.accentActive,
      'border-width': spacings.spacing2,
      'box-shadow': '0px 0px 0px 5px rgba(23, 106, 246, 0.12)',
    },
    disabled: {
      'border-color': colors.border1,
      'background-color': colors.border1,
      color: colors.textDisabled,
      cursor: 'not-allowed',
    },
    error: {
      'border-color': colors.danger,
      'border-width': spacings.spacing2,
    },
  },
  ellipsis: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  formFieldsEdging: {
    padding: spacings.l,
    outline: `${spacings.l} solid ${colors.module}`,
    borderRadius: rounds.container1Round,
    margin: spacings.l,
  },
};
export default theme;
