import React, { useMemo } from 'react';
import { Box, CardTypesEnum, CheckBox, CrossIcon, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { PlanError } from '@/modules/ExpressSetup/hooks/usePlansFormValidate.ts';
import PlanTypeIcon from '@/modules/plan/PlanSetup/Sidebar/PlanTypeIcon';
import colors from '@/styles/colors';

interface StyledBoxProps {
  isSelectedMode?: boolean;
  isChecked?: boolean;
  isPlansErrorShows?: boolean;
  error?: PlanError;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  border: ${({ isPlansErrorShows, error, isChecked, isSelectedMode, theme }) => isChecked ? `2px solid ${theme.colors.border1}` : !isSelectedMode && isPlansErrorShows && error ? `2px solid ${theme.colors.danger}` : '2px solid transparent'};
  box-shadow: ${({ isChecked, isSelectedMode, isPlansErrorShows, error, theme }) => {
    if ((!isChecked && isSelectedMode) || (!isSelectedMode && !error)) {
      return `0 0 0 1px ${theme.colors.border2}`;
    }
    if (!isSelectedMode && !isPlansErrorShows) {
      return `0 0 0 1px ${theme.colors.border2}`;
    }
    return 'none';
  }};
  ${({ isSelectedMode, theme }) => isSelectedMode && `
    &:hover {
      border: 2px solid ${theme.colors.border1};
      box-shadow: none;
    }
  `}
`;

export const PLAN_TYPE_CARDS = [
  { key: CardTypesEnum.HSA, title: 'Health Savings Account', description: '' },
  { key: CardTypesEnum.HCFSA, title: 'Health Care Flexible Spending Account', description: '' },
  { key: CardTypesEnum.HRA, title: 'Health Reimbursement Arrangement', description: '' },
  { key: CardTypesEnum.DCAP, title: 'Dependent Care Assistance Program', description: 'Dependent care FSAs allow employees to pay for child or elder care costs with pre-tax funds. These accounts have usage deadlines and do not rollover.' },
  { key: CardTypesEnum.PARKING, title: 'Parking', description: 'Commuter accounts allow employees to pay for their work commutes—transit and parking expenses—with pre-tax funds.' },
  { key: CardTypesEnum.TRANSIT, title: 'Transit', description: 'Commuter accounts allow employees to pay for their work commutes—transit and parking expenses—with pre-tax funds.' },
  { key: CardTypesEnum.ADOPTION, title: 'Adoption', description: '' },
  { key: CardTypesEnum.LIFESTYLE, title: 'Lifestyle', description: '' },
  { key: CardTypesEnum.WELLNESS, title: 'Wellness', description: '' },
  { key: CardTypesEnum.SPECIALTY, title: 'Specialty', description: '' },
  { key: CardTypesEnum.TRAVEL, title: 'Travel Reimbursement', description: '' },
];

export interface Props {
  type: CardTypesEnum;
  isLastItem: boolean;
  isChecked?: boolean;
  isSelectedMode? : boolean;
  isPlansErrorShows?: boolean;
  isShowedCopayError?: boolean;
  error?: PlanError;
  onSelect?: (type: CardTypesEnum) => void;
  onRemove?: (type: CardTypesEnum) => void;
}

const PlanTypeCard: React.FC<Props> = ({
  type,
  isLastItem,
  isChecked,
  isSelectedMode,
  isPlansErrorShows,
  isShowedCopayError,
  error,
  onSelect,
  onRemove,
}) => {
  const planType = useMemo(() => PLAN_TYPE_CARDS.find((plan) => plan.key === type), [type]);
  return (
    <StyledBox
      direction="row"
      data-testId="plan-type-card"
      background="canvas"
      pad="l"
      round="fieldRound"
      margin={{ bottom: !isSelectedMode || isLastItem ? 'none' : 'xs' }}
      height="fit-content"
      justify="between"
      onClick={() => onSelect && onSelect(type)}
      style={{ cursor: isSelectedMode ? 'pointer' : 'default' }}
      isSelectedMode={isSelectedMode}
      isChecked={isChecked}
      error={error}
      isPlansErrorShows={isPlansErrorShows}
    >
      <Box width={{ min: '32px' }} margin={{ right: 's' }} align="center" justify="center">
        <PlanTypeIcon
          type={type as CardTypesEnum}
          data-testid={`plan-type_small_icon_${type}`}
          size="large"
          color={colors.cardTypes[type]}
        />
      </Box>
      <Box width="100%">
        <Text weight={700} size="20px" color="textTitle" style={{ lineHeight: '28px' }}>
          {planType?.title || ''}
        </Text>
        {
          isSelectedMode ? (
            <Text color="textSecondary" style={{ lineHeight: '20px' }} margin={{ bottom: 'xxxs' }}>
              {planType?.description || ''}
            </Text>
          ) : (
            <Text
              title={planType?.description}
              color="textSecondary"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '20px',
              }}
            >
              {planType?.description || ''}
            </Text>
          )
        }
        {(type === CardTypesEnum.HCFSA || type === CardTypesEnum.HRA) && <Text color="success" style={{ lineHeight: '20px' }}>Supports Copays</Text>}
        {isSelectedMode && !isChecked && isShowedCopayError && (type === CardTypesEnum.HCFSA || type === CardTypesEnum.HRA) && <Text color="danger" style={{ lineHeight: '20px' }} margin={{ top: 'xxxs' }}>{`Copays are only available when an ${type} account is selected.`}</Text>}
        {isPlansErrorShows && error && <Text color="danger" style={{ lineHeight: '20px' }} margin={{ top: 'xxxs' }}>{error?.message}</Text>}
      </Box>
      <Box direction="row" margin={{ left: 'l' }} width={{ min: '24px' }} justify="center" align="center">
        {
          isSelectedMode ? (
            <CheckBox
              name="PlanTypeCard"
              onChange={() => onSelect && onSelect(type)}
              checked={isChecked}
            />
          ) : (
            <Box
              height="24px"
              width="24px"
              justify="center"
              align="center"
              onClick={() => onRemove && onRemove(type)}
              data-testId="remove-type-card"
            >
              <CrossIcon color="iconSecondary" size="small" />
            </Box>
          )
        }
      </Box>
    </StyledBox>
  );
};

export default PlanTypeCard;
