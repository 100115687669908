import { useCallback, useEffect, useState } from 'react';
import { getDuration } from '@common-fe/common-fe';

import useCheckScanQuery, { CipVerificationStatus } from '../queries/useCheckScan.query';

const TIMEOUT = getDuration({ seconds: 5});

export default (employeeId?: string) => {

  const { handleCheck } = useCheckScanQuery(employeeId);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError ] = useState(false);
  const handleCheckStatus = useCallback(async () => {
    try {
      const { data } = await handleCheck();
      const status = data?.data.status;
      if(status ===  CipVerificationStatus.Success) {
        setIsSuccess(true);
        setIsLoading(false);
      } else if(status === CipVerificationStatus.Error) {
        setIsError(true);
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
      setIsError(true);
    }
  }, [handleCheck]);
  useEffect(() => {
    if(isLoading && !isError && !isSuccess) {
      handleCheckStatus(); 
      const interval = setInterval(async () => {
        handleCheckStatus();
        
      }, TIMEOUT);
      return () =>{

        return clearInterval(interval);
      };
    }
    

  }, [handleCheck, handleCheckStatus, isError, isLoading, isSuccess]);

  return {
    isError,
    setIsLoading,
    isLoading,
    isSuccess,
  };
};