import { useMemo } from 'react';
import dayjs from 'dayjs';

import { RAW_DATE_FORMAT } from '@/common/constants';
import { ValuesMap } from '@/common/types';

import {
  useAccountActivityReportFilterStore,
  useCardReportFilterStore,
  useClaimReimbursementActivityReportFilterStore,
  useContributionInvoiceReportFilterStore,
  useReplenishmentInvoiceReportFilterStore,
} from '../components/ReportSection/ReportFilterPopup.tsx/store';
import { LAST_4_DIGIT_KEY, PlanYearReportFilterItems, ReportTypes } from '../report.types';

export const useDownloadReportParams = (type: ReportTypes | null) => {
  const {
    groupBy: groupByReplenishmentValues,
    dateRange: dateRangeReplenishmentValues,
    search: searchReplenishmentValues,
    SSNOption: SSNOptionReplenishment,
    invoiceId: invoiceIdReplenishmentValues,
  } = useReplenishmentInvoiceReportFilterStore();

  const {
    groupBy: groupByContributionValues,
    dateRange: dateRangeContributionValues,
    search: searchContributionValues,
    SSNOption: SSNOptionInvoice,
    invoiceId: invoiceIdContributionValues,
  } = useContributionInvoiceReportFilterStore();

  const {
    SSNOption: SSNOptionCard,
    groupBy: groupByCardsValues,
    dateRange: dateRangeCardsValues,
    search: searchCardsValues,
  } = useCardReportFilterStore();

  const {
    SSNOption: SSNOptionActivity,
    groupBy: groupByAccountActivityValue,
    planYear: planYearAccountActivityValues,
    search: searchAccountActivityValues,
  } = useAccountActivityReportFilterStore();

  const {
    SSNOption: SSNOptionClaimActivity,
    groupBy: groupByClaimReimbursementActivityValues,
    planYear: planYearClaimReimbursementActivityValues,
    search: searchClaimReimbursementActivityValues,
  } = useClaimReimbursementActivityReportFilterStore();

  const replenishmentInvoiceParams = useMemo(() => ({
    'group-type': groupByReplenishmentValues[1],
    'show-ssn': SSNOptionReplenishment !== LAST_4_DIGIT_KEY,
    'start-date': dateRangeReplenishmentValues[0] ? dayjs(dateRangeReplenishmentValues[0]).format(RAW_DATE_FORMAT) : '',
    'end-date': dateRangeReplenishmentValues[1] ? dayjs(dateRangeReplenishmentValues[1]).format(RAW_DATE_FORMAT) : '',
    'org-name-filter': searchReplenishmentValues || '',
    'invoice-id-filter': invoiceIdReplenishmentValues || '',
  }), [
    SSNOptionReplenishment,
    dateRangeReplenishmentValues,
    groupByReplenishmentValues,
    invoiceIdReplenishmentValues,
    searchReplenishmentValues,
  ]);

  const contributionInvoiceParams = useMemo(() => ({
    'group-type': groupByContributionValues[1],
    'show-ssn': SSNOptionInvoice !== LAST_4_DIGIT_KEY,
    'start-date': dateRangeContributionValues[0] ? dayjs(dateRangeContributionValues[0]).format(RAW_DATE_FORMAT) : '',
    'end-date': dateRangeContributionValues[1] ? dayjs(dateRangeContributionValues[1]).format(RAW_DATE_FORMAT) : '',
    'org-name-filter': searchContributionValues || '',
    'invoice-id-filter': invoiceIdContributionValues || '',
  }), [
    SSNOptionInvoice,
    dateRangeContributionValues,
    groupByContributionValues,
    invoiceIdContributionValues,
    searchContributionValues,
  ]);

  const cardParams = useMemo(() => ({
    'group-type': groupByCardsValues[1],
    'show-ssn': SSNOptionCard !== LAST_4_DIGIT_KEY,
    'start-date': dateRangeCardsValues[0] ? dayjs(dateRangeCardsValues[0]).format(RAW_DATE_FORMAT) : '',
    'end-date': dateRangeCardsValues[1] ? dayjs(dateRangeCardsValues[1]).format(RAW_DATE_FORMAT) : '',
    'org-name-filter': searchCardsValues || '',
  }), [
    SSNOptionCard,
    dateRangeCardsValues,
    groupByCardsValues,
    searchCardsValues,
  ]);

  const accountActivityParams = useMemo(() => ({
    'group-type': groupByAccountActivityValue,
    'org-name-filter': searchAccountActivityValues || '',
    'show-ssn': SSNOptionActivity !== LAST_4_DIGIT_KEY,
    ...planYearAccountActivityValues[0] === PlanYearReportFilterItems.NULL
      ? {} : { 'plan-year-period': planYearAccountActivityValues.join(',') || '' },
  }) as ValuesMap<string | number | boolean>, [
    SSNOptionActivity,
    planYearAccountActivityValues,
    groupByAccountActivityValue,
    searchAccountActivityValues,
  ]);

  const claimReimbursementActivityParams = useMemo(() => ({
    'group-type': groupByClaimReimbursementActivityValues[1],
    'org-name-filter': searchClaimReimbursementActivityValues || '',
    'show-ssn': SSNOptionClaimActivity !== LAST_4_DIGIT_KEY,
    ...planYearClaimReimbursementActivityValues[0] === PlanYearReportFilterItems.NULL
      ? {} : { 'plan-year-period': planYearClaimReimbursementActivityValues.join(',') || '' },
  }), [
    SSNOptionClaimActivity,
    planYearClaimReimbursementActivityValues,
    groupByClaimReimbursementActivityValues,
    searchClaimReimbursementActivityValues,
  ]);

  const params = useMemo(() => {
    switch (type) {
    case ReportTypes.Replenishment:
      return replenishmentInvoiceParams;
    case ReportTypes.Contribution:
      return contributionInvoiceParams;
    case ReportTypes.Card:
      return cardParams;
    case ReportTypes.ParticipantAccountActivity:
      return accountActivityParams;
    case ReportTypes.Claims:
      return claimReimbursementActivityParams;
    default:
      return {};
    }
  }, [
    contributionInvoiceParams,
    replenishmentInvoiceParams,
    cardParams,
    accountActivityParams,
    claimReimbursementActivityParams,
    type,
  ]);

  return params as ValuesMap<string>;
};
