import React from 'react';
import {
  Box, Modal,
  Text, } from '@common-fe/common-fe';

interface Props {
  visible: boolean;
  setOpenModal: (value: boolean) => void;
  title?: string;
  children: React.ReactNode;
  testId?: string;
}

const NotificationModalWrapper: React.FC<Props> = ({
  testId,
  children,
  setOpenModal,
  visible,
}) => (
  <Modal testId={testId} visible={visible} onSetVisible={setOpenModal}>
    <Box direction="column">
      <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
        <Text size="2xl" color="textTitle" weight="bold">Notification details</Text>
      </Box>
      <Box overflow="hidden">
        <Box>
          {children}
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default NotificationModalWrapper;
