import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { NO_KEY, YES_KEY } from '@/common/constants';
import { AllowAssumedContribution } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerDto } from '@/modules/employer/types';

interface Props {
  formattedData?: EmployerDto;
  isLoading?: boolean;
}

const useAdminContributionsFields = (props: Props): Field[] => {
  const {
    formattedData,
    isLoading,
  } = props;
  const { viewMode } = useSetupEmployerMode();

  return useMemo(() => {
    if (isLoading) return [];

    return [
      {
        name: 'allowAdminContribution',
        type: FieldTypes.Radio,
        disabled: viewMode,
        label: 'Allow manual contributions',
        hasHorizontalLine: true,
        options: [
          { label: 'Yes', value: YES_KEY },
          { label: 'No', value: NO_KEY },
        ],
        ...typeof formattedData?.overridableProperties?.allowAdminContribution === 'boolean' ? {
          defaultValue: formattedData?.overridableProperties?.allowAdminContribution ? YES_KEY : NO_KEY,
        } : {},
      },
      {
        name: 'allowAssumedContribution',
        type: FieldTypes.Radio,
        disabled: viewMode,
        label: 'Allow scheduled contributions',
        hasHorizontalLine: true,
        options: [
          { label: 'Allow with auto approval', value: AllowAssumedContribution.ALLOWED_WITH_AUTO_APPROVAL },
          { label: 'Allow with manual approval', value: AllowAssumedContribution.ALLOWED_WITH_MANUAL_APPROVAL },
          { label: 'Disable', value: AllowAssumedContribution.DISABLED },
        ],
        ...formattedData?.overridableProperties?.allowAssumedContribution ? {
          defaultValue: formattedData?.overridableProperties?.allowAssumedContribution,
        } : {},
      },
    ] as Field[];
   
  }, [
    viewMode,
    formattedData,
    isLoading,
  ]);
};

export default useAdminContributionsFields;
