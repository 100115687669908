import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option } from '@/common/types';

interface PaymentRuleAccountMap {
  [key: string]: string;
}
interface PaymentRuleAccountPayload {
  id: number;
  name: string;
}
export const QUERY_KEY = 'GET_PAYMENT_RULE_ACCOUNTS';

export default (
  visible: boolean,
  serviceType: string,
  payoutDefinitionId: string,
) => {
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY, serviceType, payoutDefinitionId],
    () => api.get<PaymentRuleAccountPayload[]>(
      PATHS.PAYMENT_RULE_ACCOUNTS(payoutDefinitionId, serviceType),
    ),
    {
      enabled: visible && !!serviceType && !!payoutDefinitionId,
    },
  );

  const fieldOptions = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => {
      const formatedItem: Option = {
        key: `${item.id || ''}`,
        value: item.name,
        name: item.name,
      };
      return formatedItem;
    });
  }, [data]);
  const fieldsMap = useMemo(() => {
    const fieldMap: PaymentRuleAccountMap = {};
    fieldOptions.forEach((item) => {
      fieldMap[`${item.key}`] = `${item.value}`;
    });
    return fieldMap;
  }, [fieldOptions]);
  return {
    isLoading,
    isSuccess,
    fieldOptions,
    fieldsMap,
  };
};
