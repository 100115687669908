import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { DefaultValues,Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';

const GET_ORGANIZATION_KEY = 'useOrganization';

interface OrganizationsResponse {
  id: string;
  name?: string;
  short_name?: string;
  external_identifier?: string;
  organization_status_type?: string;
  valid_from?: string | number,
  parent_id?: string | number;
}

const getOrganizations = (
  searchValue: string,
  type?: OrganizationTypes,
  statuses?: string,
  parentId?: string,
) => () => api.get(PATHS.ORGANIZATIONS, {
  params: {
    ...type ? { type } : {},
    ...searchValue ? { search_value: searchValue } : {},
    ...statuses ? { statuses } : {},
    ...parentId ? {
      parent_id: parentId,
    } : {},

  },
});

const useOrganizationQuery = (
  searchValue: string, additionalFetchParams?: DefaultValues,
) => {
  const { observingOrganization } = useCurrentOrganization();
  const { isLoading, data, isSuccess } = useQuery(
    [
      GET_ORGANIZATION_KEY,
      searchValue,
      additionalFetchParams?.type,
      observingOrganization?.path,
      additionalFetchParams?.parentId,
    ],
    getOrganizations(
      searchValue,
      additionalFetchParams?.type,
      additionalFetchParams?.statuses,
      additionalFetchParams?.parentId,
    ),
    {
      enabled: additionalFetchParams?.enabled,
    },
  );

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data.content || [];
    return list.map((item: OrganizationsResponse) => ({
      key: String(item.id),
      value: item.name,
      title: item.name,
    }));
  }, [data]);

  return {
    data: formatedData,
    isLoading,
    isSuccess,
  };
};

export default useOrganizationQuery;
