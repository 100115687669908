import React from 'react';
import { Box, CardTypesEnum, RadioButton, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Plan } from '@/modules/plan/plan.types.ts';

interface StyledBoxProps {
  isChecked?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  border: ${({ isChecked, theme }) => isChecked ? `2px solid ${theme.colors.border1}` : '2px solid transparent'};
  box-shadow: ${({ isChecked, theme }) => {
    if (!isChecked) {
      return `0 0 0 1px ${theme.colors.border1}`;
    }
    return 'none';
  }};
  ${({ theme }) => `
    &:hover {
      border: 2px solid ${theme.colors.border1};
      box-shadow: none;
    }
  `}
`;

export const PLAN_TYPE_CARDS = [
  { key: CardTypesEnum.HSA, title: 'Health Savings Account', description: '' },
  { key: CardTypesEnum.HCFSA, title: 'Health Care Flexible Spending Account', description: '' },
  { key: CardTypesEnum.HRA, title: 'Health Reimbursement Arrangement', description: '' },
  { key: CardTypesEnum.DCAP, title: 'Dependent Care Assistance Program', description: 'Dependent care FSAs allow employees to pay for child or elder care costs with pre-tax funds. These accounts have usage deadlines and do not rollover.' },
  { key: CardTypesEnum.PARKING, title: 'Parking', description: 'Commuter accounts allow employees to pay for their work commutes—transit and parking expenses—with pre-tax funds.' },
  { key: CardTypesEnum.TRANSIT, title: 'Transit', description: 'Commuter accounts allow employees to pay for their work commutes—transit and parking expenses—with pre-tax funds.' },
  { key: CardTypesEnum.ADOPTION, title: 'Adoption', description: '' },
  { key: CardTypesEnum.LIFESTYLE, title: 'Lifestyle', description: '' },
  { key: CardTypesEnum.WELLNESS, title: 'Wellness', description: '' },
  { key: CardTypesEnum.SPECIALTY, title: 'Specialty', description: '' },
  { key: CardTypesEnum.TRAVEL, title: 'Travel Reimbursement', description: '' },
];

export interface Props {
  plan: Plan;
  isLastItem: boolean;
  isChecked?: boolean;
  onClick: () => void;
}

const PlanRadioOptionCard: React.FC<Props> = ({
  plan,
  isLastItem,
  isChecked,
  onClick,
}) => {
  return (
    <StyledBox
      direction="row"
      data-testId="plan-radio-option-card"
      background="canvas"
      pad={{ vertical: 'm', horizontal: 'l' }}
      round="container1Round"
      margin={{ bottom: isLastItem ? 'none' : 'xs' }}
      height="fit-content"
      justify="between"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      isChecked={isChecked}
    >
      <Box width={{ min: '32px' }} margin={{ right: 's' }} align="center" justify="center">
        <RadioButton
          name="PlanRadioOptionCard"
          onChange={onClick}
          checked={isChecked}
        />
      </Box>
      <Box width="100%">
        <Text weight={700} size="16px" style={{ lineHeight: '22px'}} color="textTitle" margin={{ bottom: 'xs' }}>
          {plan?.name || ''}
        </Text>
        <Text color="textSecondary">
          {plan?.summary || ''}
        </Text>
      </Box>
    </StyledBox>
  );
};

export default PlanRadioOptionCard;
