import React from 'react';
import { Box } from '@common-fe/common-fe';

import globalTheme from '@/styles/theme';
interface Props {
  children?: React.ReactNode;
}
const EmailTemplateWrapper: React.FC<Props> = ({ children }) => (
  <Box
    align="center"
    justify="center"
    background="border1"
    pad={{ vertical: '30px' }}
  >
    <Box
      pad="spacing24"
      background="module"
      round="moduleRound"
      style={{ boxShadow: globalTheme.shadows.default['box-shadow'] }}
      width={{ max: '670px', width: '100%' }}
    >
      <Box
        background="canvas"
        round="container1Round"
        style={{ boxShadow: globalTheme.shadows.default['box-shadow'] }}
      >
        {children}
      </Box>
    </Box>
  </Box>
);

export default EmailTemplateWrapper;
