import { useEffect } from 'react';

import { useAuthStore } from '@/modules/user/stores';

import useHasAccess, { AccessRequirement } from '../useHasAccess';
import useHistory from '../useHistory';

export default (accessRequirements?: AccessRequirement[]) => {
  const history = useHistory();
  const { user } = useAuthStore();
  const hasAccess = useHasAccess(accessRequirements);

  useEffect(() => {
    if (!!user && !hasAccess) {
      history.push('/');
    }
  }, [hasAccess, history, user]);
};
