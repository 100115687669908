import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Anchor, AppButton,
  Box, Form, RawTextInput, } from '@common-fe/common-fe';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { REGEXPS } from '@/common';

import { LoginPayload } from './useSignIn.query';

interface SignInProps {
  children?: React.ReactNode;
  disabled?: boolean;
  isChangePasswordMode?: boolean;
  onForgotPassword?: () => void;
  onForgotUsername?: () => void;
  onLogin?: (value: LoginPayload) => void;
  isError?: boolean;
}

const REQUIRED_FIELD = 'This field is required';
const SignInForm: React.FC<SignInProps> = ({
  children,
  isChangePasswordMode,
  onForgotPassword,
  onForgotUsername,
  onLogin,
  disabled,
  isError,
}) => {
  const {
    formState: { errors }, handleSubmit, control,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      username: yup.string().required(REQUIRED_FIELD),
      password: yup.string().required(REQUIRED_FIELD),
      ...(isChangePasswordMode ? {
        newPassword: yup.string().test('len', 'Use a complex password', (val) => Boolean(val && REGEXPS.PASSWORD_COMPLEXITY.test(val))),
      } : {}),
    })),
  });

  const onSubmit = (data: LoginPayload) => {
    if (onLogin) {
      onLogin(data);
    }
  };
  const passwordLabel = isChangePasswordMode ? 'Old password' : 'Password';
  const submitTitle = isChangePasswordMode ? 'Change Password' : 'Sign In';
  return (

    <Form onSubmit={handleSubmit(onSubmit)}>
      <Box direction="column">
        <Controller
          // @ts-ignore
          name="username"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box direction="column" margin={{ bottom: 'spacing24' }}>
              <RawTextInput
                name="username"
                flex
                testId="auth_username"
                label="Username"
                isLarge
                placeholder="Username"
                value={value}
                onChange={onChange}
                errorText={(errors.username?.message || isError) ? errors.username?.message || 'Username or password is wrong' : undefined}
                errorWithoutText
                isLowerCaseMode
              />
            </Box>
          )}
        />

        <Controller
        // @ts-ignore
          name="password"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Box direction="column" margin={{ bottom: 'spacing24' }}>
              <RawTextInput
                label={passwordLabel}
                placeholder={passwordLabel}
                type="password"
                testId="auth_password"
                flex
                isLarge
                value={value}
                onChange={onChange}
                name="password"
                errorText={errors.password?.message || (isError ? 'Username or password is wrong' : undefined)}
              />
            </Box>
          )}
        />

        {isChangePasswordMode && (
          <Controller
          // @ts-ignore
            name="newPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Box direction="column" margin={{ bottom: 'spacing24' }}>
                <RawTextInput
                  label="New password"
                  placeholder="New password"
                  type="password"
                  testId="auth_change-password"
                  flex
                  value={value}
                  onChange={onChange}
                  name="newPassword"
                  errorText={errors.newPassword?.message || (isError ? 'Username or password is wrong' : undefined)}
                />
              </Box>
            )}
          />
        )}
        {children}
        <AppButton disabled={disabled} testId="auth_login" type="submit" width="100%" size="L">
          {submitTitle}
        </AppButton>
        {/* <Box margin={{ top: 'spacing24' }}>
          <Text size="small">
            By clicking Sign In, you agree to
            {' '}
            <Anchor color="brand" size="small" weight="normal">
              Elevate's Terms and Conditions
            </Anchor>
            {' '}
            and have read and acknowledged our
            {' '}
            <Anchor color="brand" size="small" weight="normal">
              Privacy Policy.
            </Anchor>
          </Text>
        </Box> */}
        <Box direction="row" margin={{ top: 'spacing24' }} align="center" justify="center">
          <Anchor
            color="brand"
            size="large"
            weight="bold"
            disabled={disabled}
            data-testid="auth_forgot-username_button"
            onClick={onForgotUsername}
            margin={{ horizontal: 'spacing12' }}
          >
            Forgot Username?
          </Anchor>
          <Anchor
            color="brand"
            size="large"
            data-testid="auth_forgot-password_button"
            weight="bold"
            disabled={disabled}
            onClick={onForgotPassword}
            margin={{ horizontal: 'spacing12' }}
            style={{
              lineHeight: '22xp',
            }}
          >
            Forgot Password?
          </Anchor>
          {/* <Box width="4px" height="4px" background="border1" style={{ borderRadius: 4 }} /> */}
        </Box>
        {/* <Text size="large" margin={{ top: 'spacing12' }} textAlign="center">
          New to Elevate?
          {' '}

          <Anchor
            href="/user/sign-up"
            color="brand"
            size="large"
            weight="bold"
          >
            Register
          </Anchor>
        </Text> */}
      </Box>
    </Form>

  );
};

export default SignInForm;
