import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacings.spacing24};
  // padding: 40px;
`;

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.text32Bold};
  color: ${({ theme }) => theme.colors.textTitle || ''};
`;

export const PlanLink = styled.span`

  ${({ theme }) => theme.fonts.text16Regular};
  margin-left: ${({ theme }) => theme.spacings.spacing16};
  color: ${({ theme }) => theme.colors.textAccent};
`;
