import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useSearchParams = (searchParams: string) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search).get(searchParams),
    [location.search, searchParams]);

  const removeParams = (params: string[]) => {
    const urlParams = new URLSearchParams(location.search);
    params.forEach((param) => urlParams.delete(param));
    return history.replace({
      search: urlParams.toString(),
    });
  };

  return {
    query,
    removeParams,
  };
};
