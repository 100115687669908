import React, { useMemo } from 'react';
import {
  Box,
  RawAvatar,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { UserLetterIcon } from '@/components/elements/LetterIcon/UserLetterIcon';
import { Topbar } from '@/modules/core/components';
import { useProgressScrollState } from '@/modules/core/hooks/useScrollSidebar';
import { AddEnrollmentModal } from '@/modules/employee/Employee/AddEnrollmentModal/AddEnrollmentModal';
import theme from '@/styles/theme';

import { useEmployeeToolbarStore } from './stores';

const AnimatedAvatar = styled(RawAvatar)`
  transition: all 0.35s;
`;

interface Props {
  hasSubmitClaimButton?: boolean;
  isEditMode?: boolean;
  children?: React.ReactNode;
}

const EmployeeTopbar: React.FC<Props> = ({
  hasSubmitClaimButton,
  isEditMode,
  children,
}) => {
  const { hideHeader } = useProgressScrollState();
  const currentState = useEmployeeToolbarStore((state) => state.sourceState);

  const employeeName = useMemo(() => `${currentState?.fullName || ''}`, [currentState]);
  return (
    <>
      <Box
        data-testid={hideHeader ? 'minified-topbar' : 'expanded-topbar'}
        height={{ min: 'max-content' }}
      >
        <Topbar hideHeader={hideHeader} />
      </Box>
      <Box alignSelf="center" pad={{ horizontal: '40px' }}>
        <Box
          width={theme.defaultContentWidth}
          direction="row"
          justify="between"
          margin={{ bottom: hideHeader ? '' : 'spacing12' }}
          border={{ side: 'horizontal', color: hideHeader ? 'border2' : 'transparent', size: '1px' }}
          alignSelf="center"
          align="center"
          height={theme.spacings.spacing72}
        >
          <Box
            direction="row"
            align="center"
            margin={{ horizontal: 'spacing8' }}
          >
            {
              currentState?.avatar ? (
                <AnimatedAvatar size={hideHeader ? '48px' : '54px'} src={currentState?.avatar} />
              ) : (
                <UserLetterIcon
                  size={LetterIconSizeEnum.LOGO}
                  text={employeeName}
                  type={OrganizationTypes.employee}
                  sizePx={hideHeader ? '48' : '54'}
                />
              )
            }
            <Text size="2xl" weight="bold" margin={{ left: 'spacing16' }}>
              {employeeName}
            </Text>
          </Box>
          <Box direction="row">
            {!isEditMode ? (
              <AddEnrollmentModal
                hasSubmitClaimButton={hasSubmitClaimButton}
              >
                {children}
              </AddEnrollmentModal>
            ) : (
              <>
                {children}
              </>
            )}

            {/* {children} */}
            {/* <Button size="medium" secondary>
          <Box justify="center" align="center" width="smallControl">
            Claims
          </Box>
        </Button>
        <Button size="medium" primary>

          <Box direction="row" justify="center" align="center" pad={{ left: 'spacing16', right: 'spacing24' }}>

            <Box margin={{ right: 'spacing4' }}>
              <SearchUserIcon color="iconOnColor" />
            </Box>
            Participant site
          </Box>
        </Button> */}

          </Box>

        </Box>
      </Box>
    </>
  );
};
export default EmployeeTopbar;
