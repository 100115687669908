import React, { useEffect, useState } from 'react';
import { Box,FlexControlledForm, useDebounce } from '@common-fe/common-fe';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerDto } from '@/modules/employer/types';

import { AddressPayload } from '../SetupEmployer.types';
import { useSaveTypeStore } from '../stores';

import useAddressFields from './useAddressFields';

interface Props {
  onSubmit?: (value: AddressPayload) => void;
  onChangeErrors?: (values: object) => void;
  data?: EmployerDto,
  onDirty?: (isDirty: boolean) => void;
  isLoading?: boolean;
}
const AddressForm: React.FC<Props> = ({
  onSubmit,
  data,
  onDirty,
  isLoading,
}) => {
  const isTerminated = data?.organizationStatusType === EmployerStatus.Terminated;
  const { viewMode } = useSetupEmployerMode();
  const { pendingStatus, activeStatus, handleResetType } = useSaveTypeStore();
  const fields = useAddressFields(!pendingStatus, isLoading ? undefined : data);
  const [currentValues, setCurrentValues] = useState<object>({});
  const debouncedValue = useDebounce(currentValues);

  useEffect(() => {
    if (onSubmit) {
      onSubmit(debouncedValue as AddressPayload);
    }
  }, [debouncedValue, onSubmit]);

  useEffect(() => () => {
    handleResetType();
  }, [handleResetType]);

  if (isLoading) return <FormLoader testId="AddressForm" />;

  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'spacing16' }} id="address">
      <FlexControlledForm
        fields={fields}
        showError={pendingStatus || activeStatus}
        editMode={!viewMode && !isTerminated}
        formTitle="Address"
        onChangeValues={setCurrentValues}
        onDirty={onDirty}
        isThinMode
      />
    </Box>
  );
};
export default AddressForm;
