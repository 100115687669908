import { useCallback } from 'react';

import { OrganizationTypes } from '@/common/constants';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys, QueryKeysUnionType } from '@/modules/core/hooks/useHistory';
import { BreadcrumbOptions } from '@/modules/core/store/useBreadcrumbs.store';

export const useRedirectToPrevObserving = (
  type?: OrganizationTypes,
  userOrganizationType?: OrganizationTypes,
) => {
  const history = useHistory();
  const redirectToPrevObserving = useCallback((
    path: string,
    breadcrumbsOptions: Partial<BreadcrumbOptions>,
    params?: { [ key in QueryKeysUnionType ]?: string | boolean },
    hash?: string,
  ) => {
    const currentType = breadcrumbsOptions?.orgType || type;
    const newQuery = history.query;

    if (currentType === OrganizationTypes.system) {
      newQuery.delete(QueryKeys.OBSERVING_PARTNER_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY);
    }

    if (currentType === OrganizationTypes.partner) {
      newQuery.delete(QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY);
    }
    if (userOrganizationType === OrganizationTypes.partner) {
      newQuery.delete(QueryKeys.OBSERVING_PARTNER_QUERY_KEY);
    }

    if (currentType === OrganizationTypes.distributor) {
      newQuery.delete(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY);
      newQuery.delete(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY);
    }
    if (userOrganizationType === OrganizationTypes.distributor) {
      newQuery.delete(QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY);
    }

    if (currentType === OrganizationTypes.company) {
      newQuery.delete(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY);
    }
    if (userOrganizationType === OrganizationTypes.company) {
      newQuery.delete(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY);
    }

    if (userOrganizationType === OrganizationTypes.subsidiary) {
      newQuery.delete(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY);
    }

    if (currentType) {
      if (breadcrumbsOptions.removeParamsByRedirecting) {
        breadcrumbsOptions.removeParamsByRedirecting.forEach((param) => {
          if (!params || !params[param]) newQuery.delete(param);
        });
      }

      if (params) {
        Object.keys(params).forEach((param) => {
          if (!newQuery.get(param) || newQuery.get(param) !== params[param as QueryKeysUnionType]) {
            newQuery.set(param, `${params[param as QueryKeysUnionType]}`);
          }
        });
      }

      history.originPush(`${path}?${newQuery.toString()}${hash ? `#${hash}` : ''}`);
    }
  }, [history, type, userOrganizationType]);
  return { redirectToPrevObserving };
};