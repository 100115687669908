import React from 'react';

import { OrganizationTypes } from '@/common/constants';

import FakeBrowserAddressLine from './FakeBrowserAddressLine';
import { WrapperWithRightShadow } from './FakeBrowserImage.styles';
import FakeBrowserTab from './FakeBrowserTab';

interface Props {
  logoSrc?: string;
  organizationType?: OrganizationTypes;
  organizationName?: string;
}

const FakeBrowserImage: React.FC<Props> = ({
  logoSrc,
  organizationType,
  organizationName,
}) => {
  return (
    <WrapperWithRightShadow
      data-testId="fake-browser-image"
      round="container2Round"
      border={{ color: 'border2', size: 'small' }}
      background="border1"
      overflow="hidden"
      width="300px"
    >
      <FakeBrowserTab
        logoSrc={logoSrc}
        organizationType={organizationType}
        organizationName={organizationName}
      />
      <FakeBrowserAddressLine
        organizationName={organizationName}
      />
    </WrapperWithRightShadow>
  );
};

export default FakeBrowserImage;
