import React, { useEffect, useMemo, useState } from 'react';
import { Anchor, Box, Field, FlexControlledForm, Text, useDebounce } from '@common-fe/common-fe';

import { NO_KEY, YES_KEY } from '@/common/constants';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { ExpressSetupLinksPayload } from '../../SetupEmployer.types';
import { useSaveTypeStore } from '../../stores';

import { useOrphanConfigurationFields } from './hooks';
import LinksSection from './LinksSection';
import { useExpressSetupLinksStore } from './stores';

interface Props {
  isDistributorMode?: boolean;
}
const ExpressSetupLinks: React.FC<Props> = ({ isDistributorMode = false }) => {
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  const [value, setValue] = useState<Partial<ExpressSetupLinksPayload>>();
  const debouncedValue = useDebounce(value);
  const { formattedData, isLoading } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const handleReset = useExpressSetupLinksStore((state) => state.handleReset);
  const fields = useOrphanConfigurationFields({
    disabled: viewMode,
    isDistributorMode
  });
  const handleSetState = useExpressSetupLinksStore((state) => state.setState);
  const isExternalLink = useExpressSetupLinksStore((state) => state.state.isExternalLink);
  const sourceOrphanEmployer = useExpressSetupLinksStore((state) => state.sourceState?.isExternalLink);
  const key = useMemo(() => {
    const { isEmployerOrphan } = formattedData?.overridableProperties || {};
    return `${isEmployerOrphan} ${sourceOrphanEmployer}`;
  }, [formattedData?.overridableProperties, sourceOrphanEmployer]);

  useEffect(() => {
    if (debouncedValue) {
      if (debouncedValue.isExternalLink === YES_KEY) {
        debouncedValue.isExternalLink = true;
      }
      if (debouncedValue.isExternalLink === NO_KEY) {
        debouncedValue.isExternalLink = false;
      }
      handleSetState(debouncedValue);
    }
  }, [debouncedValue, handleSetState]);
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) return <FormLoader testId="expressSetupLinks" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      id="express_setup_links"
      data-testId="express-setup-links"
      key={key}
    >
      <FlexControlledForm
        wrapperStyle={{ border: 'none', paddingBottom: '0px' }}
        formStyle={{ marginBottom: '0px' }}
        shouldControlValues
        formTitle="Express Employer Setup Via External Link"
        fields={fields as Field[]}
        onChangeValues={setValue}
        editMode={!viewMode}
        showError={pendingStatus || activeStatus}
        isThinMode
      />

      <Box margin={{ horizontal: 'xl', top: 'm' }}>
        {isExternalLink && !isDistributorMode &&  (
          <Text>
            Please note that in order to copy an external link, you need to go to the distributor level and
            its company profile.
          </Text>
          
          
        )}
      </Box>
      <Box border={{ side: 'bottom', color: 'border1' }} margin={{ horizontal: 'xl', vertical: 'm' }}></Box>
      <LinksSection />
    </Box>
  );
};

export default ExpressSetupLinks;
