import React, { useMemo } from 'react';
import {
  CarrierConfigIcon,
  ClaimsIcon,
  ContributionsMenuIcon,
  DistributorIcon,
  EmployeesIcon,
  EmployerIcon,
  FolderIcon,
  HomeIcon,
  NewPlansIcon,
  PartnerIcon,
  ProcessingDefinitionIcon,
  QuickLinksIcon,
  ReconciliationReportsIcon,
  SubsidiariesIcon,
} from '@common-fe/common-fe';

import { OrganizationTypes, Roles } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import {
  useCurrentOrganization,
  useHasAccess,
  useHistory,
} from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import useContributionsFeatureFlag from '@/modules/employer/useContributionsFeatureFlag';
import { useAuthStore } from '@/modules/user/stores';

import { SidebarItemProps } from '../SidebarItem';

export const useSidebarOptions = (): SidebarItemProps[] => {
  const {
    observingOrganization: {
      type,

    },
  } = useCurrentOrganization();
  const { user, auth } = useAuthStore();

  const { observingOrganizationData } = useOrganizationOverridableProperties();
  const { getPath } = useHistory();

  const isSystem = useMemo(() => type === OrganizationTypes.system, [type]);

  const isEmployer = useMemo(
    () => type === OrganizationTypes.employer || type === OrganizationTypes.company,
    [type]
  );

  const isFileManagerEnabled = useMemo(
    () => observingOrganizationData?.isFileManagerAccessEnabled,
    [observingOrganizationData]
  );

  const isEmployee = useMemo(() => type === OrganizationTypes.employee, [type]);
  const isSubsidiary = useMemo(() => type === OrganizationTypes.subsidiary, [type]);
  const isSubgroup = useMemo(() => type === OrganizationTypes.subgroup, [type]);
  const isDistributor = useMemo(() => type === OrganizationTypes.distributor, [type]);

  const isContributionsFeatureFlag = useContributionsFeatureFlag();
  const isContributionsAccessibleObserving = useMemo(
    () => isEmployer || isSubsidiary,
    [isEmployer, isSubsidiary]
  );

  const isContributionsAccessible = useHasAccess([{ permission: Permissions.VIEW_CONTRIBUTIONS }]);

  const isPartnersOptionAccessible = useHasAccess([
    { organizationType: OrganizationTypes.system },
    { organizationType: OrganizationTypes.distributor },
  ]);
  const isHealthPlanHubAccessible = useHasAccess([
    { permission: Permissions.VIEW_HEALTH_PLAN },
    { permission: Permissions.CREATE_CARRIER_CONFIG },
    { permission: Permissions.VIEW_COPAY },
    { permission: Permissions.DISABLE_CARRIER_CONFIG },
  ]);
  const isHealthPlansAccessible = useHasAccess([{ permission: Permissions.VIEW_HEALTH_PLAN }]);
  const isSetUpPartnerOptionAccessible = useHasAccess([
    {
      organizationType: OrganizationTypes.system,
      permission: Permissions.EDIT_ER_DATA,
    },
    {
      organizationType: OrganizationTypes.distributor,
      permission: Permissions.EDIT_ER_DATA,
    },
  ]);

  const isSetUpDistributorOptionAccessible = useHasAccess([
    {
      organizationType: OrganizationTypes.system,
      permission: Permissions.EDIT_ER_DATA,
    },
    {
      organizationType: OrganizationTypes.partner,
      permission: Permissions.EDIT_ER_DATA,
    },
  ]);

  const isPLansTabShowed = useHasAccess([
    {
      organizationType: OrganizationTypes.system,
      permission: Permissions.VIEW_ALL,
    },
    {
      organizationType: OrganizationTypes.system,
      permission: Permissions.VIEW_PLAN_TEMPLATE,
    },
    {
      organizationType: OrganizationTypes.partner,
      permission: Permissions.VIEW_ALL,
    },
    {
      organizationType: OrganizationTypes.partner,
      permission: Permissions.VIEW_PLAN_TEMPLATE,
    },
    {
      organizationType: OrganizationTypes.subsidiary,
      permission: Permissions.VIEW_ALL,
    },
    {
      organizationType: OrganizationTypes.subsidiary,
      permission: Permissions.VIEW_PLAN_TEMPLATE,
    },
    {
      organizationType: OrganizationTypes.company,
      permission: Permissions.VIEW_ALL,
    },
    {
      organizationType: OrganizationTypes.company,
      permission: Permissions.VIEW_PLAN_TEMPLATE,
    },
  ]);
  const isViewCopayGroups = useHasAccess([
    {
      permission: Permissions.VIEW_COPAY,
    },
    {
      permission: Permissions.VIEW_ALL,
    },
  ]);
  const isEmployersOptionAccessible = useHasAccess([
    { organizationType: OrganizationTypes.system },
    { organizationType: OrganizationTypes.distributor },
    { organizationType: OrganizationTypes.partner },
  ]);

  const isSetUpEmployerOptionAccessible = useHasAccess([
    {
      organizationType: OrganizationTypes.system,
      permission: Permissions.EDIT_ER_DATA,
    },
    {
      organizationType: OrganizationTypes.distributor,
      permission: Permissions.EDIT_ER_DATA,
    },
    {
      organizationType: OrganizationTypes.partner,
      permission: Permissions.EDIT_ER_DATA,
    },
  ]);
  const isFinOpsShowed = useHasAccess([
    { permission: Permissions.VIEW_LOCKBOX },
    { permission: Permissions.VIEW_FINOPS_CHECKS },
    { permission: Permissions.VIEW_INVOICES },
    { permission: Permissions.BANK_OPS },
  ]);
  const isClaimsButtonAvailable = useHasAccess([
    { role: Roles.superUser },
    { role: Roles.claimsProcessor1 },
    { role: Roles.claimsProcessorReview },
    { role: Roles.claimsProcessor2 },
    { role: Roles.claimsProcessorEdit },
    { role: Roles.admin3 },
    { role: Roles.claimsSupervisor },
  ]);
  const isPlansOptionAccessible = useHasAccess([
    { permission: Permissions.VIEW_PLAN },
    { permission: Permissions.VIEW_ALL },
  ]);
  const isFileManagerAccessible = useHasAccess([{ permission: Permissions.VIEW_FILE_REPORTS }]);
  const isProcessingDefinitionAccessible = useHasAccess([
    { permission: Permissions.VIEW_PROCESSING_DEFINITION },
  ]);

  const allOptions = useMemo<SidebarItemProps[]>(
    () => [
      {
        key: 'home',
        title: 'Home',
        icon: <HomeIcon color="navigationIconDefault" />,
        url: ROUTES.HOME,
      },
      {
        key: 'partners',
        title: 'Partners',
        icon: <PartnerIcon color="navigationIconDefault" />,
        url: ROUTES.PARTNERS,
        hide: !isPartnersOptionAccessible || !isSystem,
      },
      {
        key: 'distributors',
        title: 'Distributors',
        icon: <DistributorIcon color="navigationIconDefault" />,
        url: ROUTES.DISTRIBUTORS,
        hide: isDistributor || isEmployer || isEmployee || isSubsidiary || isSubgroup,
      },
      {
        key: 'employers',
        title: 'Employers',
        icon: <EmployerIcon color="navigationIconDefault" />,
        url: ROUTES.EMPLOYERS,
        hide: !isEmployersOptionAccessible || isEmployer || isSubsidiary || isSubgroup,
      },
      {
        key: 'subsidiaries',
        title: 'Subsidiaries',
        icon: <SubsidiariesIcon color="navigationIconDefault" />,
        url: ROUTES.SUBSIDIARIES,
        hide: isSubsidiary || isSubgroup || !isEmployer,
      },
      {
        key: 'employees',
        title: 'Employees',
        icon: <EmployeesIcon color="navigationIconDefault" />,
        url: ROUTES.EMPLOYEES,
        subUrls: [ROUTES.EMPLOYEE],
        hide: isSubgroup,
      },
      {
        key: 'plans',
        title: 'Plans',
        icon: <NewPlansIcon color="navigationIconDefault" />,
        url: ROUTES.PLANS,
        subUrls: [ROUTES.TEMPLATE_VIEW, ROUTES.TEMPLATES],
        hide: !isPlansOptionAccessible || isSubgroup || !isPLansTabShowed,
      },
      {
        key: 'processingDefinition',
        title: 'Processing Definition',
        icon: <ProcessingDefinitionIcon color="navigationIconDefault" />,
        url: ROUTES.PROCESSING_DEFINITION,
        options: [
          {
            key: 'enrollment_matrix',
            title: 'Enrollment Matrix',
            url: ROUTES.ENROLLMENT_MATRIX,
          },
          {
            key: 'processing_rules',
            title: 'Processing Rules',
            url: ROUTES.PROCESSING_RULES,
          },
          {
            key: 'payout_definitions',
            title: 'Payout Definitions',
            url: ROUTES.PAYOUT_DEFINITIONS,
          },
        ],
        hide: !isProcessingDefinitionAccessible || isSubsidiary,
      },
      ...(isHealthPlanHubAccessible
        ? [
          {
            key: 'healthPlanHub',
            title: 'Health Plan Hub',
            icon: <CarrierConfigIcon color="navigationIconDefault" />,
            url: ROUTES.HEALTH_PLAN_HUB,
            hide: !isPlansOptionAccessible || isSubsidiary || isSubgroup || !isPLansTabShowed,
            options: [
              ...(isHealthPlansAccessible
                ? [
                  {
                    key: 'health_plans',
                    title: 'Health Plans',
                    url: ROUTES.HEALTH_PLANS,
                  },
                ]
                : []),
              {
                key: 'carrier_config',
                title: 'Carrier Config',
                url: ROUTES.CARRIER_CONFIG,
              },
              ...(isViewCopayGroups
                ? [
                  {
                    key: 'copay_adjudication',
                    title: 'Copay Adjudication',
                    url: ROUTES.COPAY_ADJUDICATION,
                  },
                ]
                : []),
            ],
          },
        ]
        : []),
      {
        key: 'claims',
        title: 'Claims',
        icon: <ClaimsIcon color="navigationIconDefault" />,
        url: ROUTES.CLAIMS,
        subUrls: [ROUTES.CLAIM_PROCESS, ROUTES.MANAGE_CLAIMS_PROCESSORS],
        hide: !isClaimsButtonAvailable,
      },
      {
        key: 'folder',
        title: 'File Manager',
        icon: <FolderIcon color="navigationIconDefault" />,
        url: ROUTES.FILE_MANAGER,
        subUrls: [ROUTES.FILE_DETAILS],
        hide: isSubgroup || !isFileManagerAccessible || !isFileManagerEnabled,
      },

      {
        key: 'finOps',
        title: 'FinOps',
        icon: <ReconciliationReportsIcon color="navigationIconDefault" />,
        url: ROUTES.FINOPS,
        subUrls: [ROUTES.RECONCILIATION_REPORT],
        hide: !isFinOpsShowed,
      },
      {
        key: 'contributions',
        title: 'Contributions',
        icon: <ContributionsMenuIcon color="navigationIconDefault" />,
        url: ROUTES.CONTRIBUTIONS,
        hide:
          !isContributionsAccessible || !isContributionsAccessibleObserving || !isContributionsFeatureFlag,
      },
      {
        key: 'quickLinks',
        title: 'Quick Links',
        delimiter: true,
        icon: <QuickLinksIcon color="navigationIconDefault" />,
        // isDotsIcon: true,
        hide: isEmployer || isSubsidiary || isSubgroup,
      },
      {
        key: 'setupPartner',
        isLink: true,
        minify: true,
        title: 'Set up Partner',
        url: getPath(ROUTES.PARTNERS, { shouldOpenModal: true }),
        hide: !isSetUpPartnerOptionAccessible || !isSystem,
      },
      {
        key: 'setupDistributor',
        minify: true,
        isLink: true,
        title: 'Set up Distributor',
        url: getPath(ROUTES.DISTRIBUTORS, { shouldOpenModal: true }),
        hide: !isSetUpDistributorOptionAccessible || isDistributor || isEmployer || isSubsidiary,
      },
      {
        key: 'setupEmployer',
        minify: true,
        isLink: true,
        title: 'Set up Employer',
        url: getPath(ROUTES.EMPLOYERS, { shouldOpenModal: true }),
        hide: !isSetUpEmployerOptionAccessible || isEmployer || isSubgroup || isSubsidiary,
      },
    ],
    [
      isPartnersOptionAccessible,
      isSystem,
      isDistributor,
      isEmployer,
      isEmployee,
      isSubsidiary,
      isSubgroup,
      isEmployersOptionAccessible,
      isPlansOptionAccessible,
      isPLansTabShowed,
      isProcessingDefinitionAccessible,
      isHealthPlanHubAccessible,
      isHealthPlansAccessible,
      isViewCopayGroups,
      isClaimsButtonAvailable,
      isFileManagerAccessible,
      isFileManagerEnabled,
      isFinOpsShowed,
      isContributionsAccessible,
      isContributionsAccessibleObserving,
      isContributionsFeatureFlag,
      getPath,
      isSetUpPartnerOptionAccessible,
      isSetUpDistributorOptionAccessible,
      isSetUpEmployerOptionAccessible,
    ]
  );

  const filteredOptions = useMemo(() => allOptions.filter((item) => !item.hide), [allOptions]);
  if (!auth || !user) return [];

  return filteredOptions;
};
