import React from 'react';
import { AppButton, Box } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
}

const FixedWrapper = styled(Box)`
  ${({ theme }) => {
    return css`
      position: sticky;
      width: 100%;
      min-height: fit-content;
      top: 0;
      z-index: 12;
      background-color: ${theme.colors.canvas};
    `;
  }}
`;
const ScheduledContributionSummaryFooter: React.FC<Props> = ({ onCancel, onSubmit }) => {
  return (
    <FixedWrapper>
      <Box background="module" pad="l">
        <Box direction="row" justify="between">
          <AppButton buttonType="tertiary" onClick={onCancel}>
            Cancel
          </AppButton>

          <AppButton width="128px" onClick={onSubmit}>
            Submit
          </AppButton>
        </Box>
      </Box>
    </FixedWrapper>
  );
};

export default ScheduledContributionSummaryFooter;
