import React, { useMemo } from 'react';
import {
  Box, costFormater, FlexList, ListItem, Preloader,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ListItemBase, ListItemType } from '@/common/types';

import { NONE_KEY } from './hooks/useInterestAccrualsFields';
import { useInterestTiersQuery } from './queries';
import { useInterestAccrualsStore } from './stores';

const headers: ListItemBase[] = [
  {
    key: 'id',
    title: 'Balance tier',

  }, {
    key: 'balanceFrom',
    title: 'From',

  }, {
    key: 'balanceTo',
    title: 'To',

  }, {
    key: 'interestRate',
    title: 'Rate',
  }, {
    key: 'lowApy',
    title: 'Low(APY)',
  }, {
    key: 'highApy',
    title: 'High(APY)',
    flex: 4,
  },
];
const InterestsTable = () => {
  const interestTemplate = useInterestAccrualsStore((state) => state.state.interestTemplate);
  const currentId = useMemo(() => {
    if (interestTemplate === NONE_KEY) {
      return '';
    }
    return interestTemplate;
  }, [interestTemplate]);
  const { data, isLoading } = useInterestTiersQuery(currentId);
  const formatedRows = useMemo<ListItem[]>(() => data.map((item, index) => ({
    id: item.id,
    fields: [
      {
        key: 'id',
        type: ListItemType.Text,
        title: `${index + 1}.`,
      },
      {
        key: 'balanceFrom',
        type: ListItemType.Text,
        title: item.balanceFrom ? costFormater(item.balanceFrom, true) : '-',
      },
      {
        key: 'balanceTo',
        type: ListItemType.Text,
        title: item.balanceTo ? costFormater(item.balanceTo, true) : '-',
      },
      {
        key: 'interestRate',
        type: ListItemType.Text,
        title: `${(item.interestRate || 0).toFixed(2)}%`,
      },
      {
        key: 'lowApy',
        type: ListItemType.Text,
        title: `${(item.lowAPY || 0).toFixed(2)}%`,
      },
      {
        key: 'highApy',
        type: ListItemType.Text,
        title: `${(item.highAPY || 0).toFixed(2)}%`,
        flex: 4,
      },

    ],
  })),
  [data]);

  if (_.isEmpty(data)) {
    return null;
  }
  if (isLoading) {
    return (<Preloader />);
  }
  return (
    <Box pad="spacing12" border={{ side: 'top', color: 'border2' }}>
      
      <FlexList pad="spacing12"
        round="container1Round"
        rows={formatedRows}
        headers={headers} />
    </Box>
  );
};

export default InterestsTable;
