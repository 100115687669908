import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CalendarIcon,
  ClearAll,
  DateRangeDropdown,
  SearchInput, useDebounce,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import styled from 'styled-components';

import REGEXP from '@/common/regexp';
import { GroupByFilter } from '@/modules/employer/components/Reports/report.types';

import { NestedSelectDropdown } from '../NestedSelectDropdown';

import {
  CONTRIBUTION_DATE_RANGE_DEFAULT_VALUE,
  CONTRIBUTION_GROUP_BY_DEFAULT_VALUE,
  ContributionGroupByType,
  useContributionInvoiceReportFilterStore,
} from './store/useContributionInvoiceReportFilter.store';
import SelectSSNDropdown from './SelectSSNDropdown';

const INPUT_WIDTH = '180px';

const ClearAllButtonContainer = styled(Box)`
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translate(-50%);
`;

const StyledDateRangeDropdown = styled(DateRangeDropdown)`
    & > div {
    left: 0;
  }
`;

const groupByOptions = [
  {
    key: GroupByFilter.OrgField,
    title: 'Organization',
    value: 'Organization',
    subOptions: [
      {
        key: GroupByFilter.Partner,
        title: 'Partner',
        value: 'Partner',
      },
      {
        key: GroupByFilter.Employer,
        title: 'Employer',
        value: 'Employer',
      },
      {
        key: GroupByFilter.PartnerAndEmployer,
        title: 'Partner and Employer',
        value: 'Partner and Employer',
      },
    ],
  },
  {
    key: GroupByFilter.DateField,
    title: 'Date',
    value: 'Date',
    subOptions: [
      {
        key: GroupByFilter.DateOnly,
        title: 'Date only',
        value: 'Date only',
      },
      {
        key: GroupByFilter.DatePartner,
        title: 'Partner',
        value: 'Partner',
      },
      {
        key: GroupByFilter.DateEmployer,
        title: 'Employer',
        value: 'Employer',
      },
      {
        key: GroupByFilter.DatePartnerAndEmployer,
        title: 'Partner and Employer',
        value: 'Partner and Employer',
      },
    ],
  },
];

// const sortByOptions = [
//   {
//     key: SortByKeys.orgSortDirection,
//     title: SortByKeys.orgSortDirection,
//     value: SortByKeys.orgSortDirection,
//     subOptions: [
//       {
//         key: SortByFilter.OrgAsc,
//         title: 'A-Z',
//         value: 'A-Z',
//       },
//       {
//         key: SortByFilter.OrgDesc,
//         title: 'Z-A',
//         value: 'Z-A',
//       },
//     ],
//   },
//   {
//     key: SortByKeys.dateSortDirection,
//     title: SortByKeys.dateSortDirection,
//     value: SortByKeys.dateSortDirection,
//     subOptions: [
//       {
//         key: SortByFilter.DateDesc,
//         title: 'Descending',
//         value: 'Descending',
//       },
//       {
//         key: SortByFilter.DateAsc,
//         title: 'Ascending',
//         value: 'Ascending',
//       },
//     ],
//   },
// ];

export const ContributionInvoiceReportFilters = () => {
  
  const ssnOption = useContributionInvoiceReportFilterStore((state) => state.SSNOption);
  const setSSNOption = useContributionInvoiceReportFilterStore((state) => state.handleSetSSNOption);

  const dateRange = useContributionInvoiceReportFilterStore((state) => state.dateRange);
  const groupByValues = useContributionInvoiceReportFilterStore((state) => state.groupBy);
  const setInvoiceId = useContributionInvoiceReportFilterStore((state) => state.handleSetInvoiceId);
  const setDateRange = useContributionInvoiceReportFilterStore((state) => state.handleSetDateRange);
  const setSearchValue = useContributionInvoiceReportFilterStore((state) => state.handleSetSearch);
  const setGroupByValues = useContributionInvoiceReportFilterStore(
    (state) => state.handleSetGroupBy,
  );
  const handleClearAll = useContributionInvoiceReportFilterStore((state) => state.handleClear);
  const searchValue = useContributionInvoiceReportFilterStore((state) => state.search);
  const invoiceId = useContributionInvoiceReportFilterStore((state) => state.invoiceId);
  const [tempSearchValues, setSearchTempValues] = useState<string>(searchValue);
  const [tempInvoiceId, setTempInvoiceId] = useState<string>(invoiceId);
  const [isHovered, setIsHovered] = useState(false);
  const [initCleaning, setInitCleaning] = useState(false);
  const debouncedValue = useDebounce(tempSearchValues);
  const debouncedInvoiceId = useDebounce(tempInvoiceId);

  const isVisibleClearAll = useMemo(
    () => !_.isEqual(dateRange[0], null)
    || !_.isEqual(groupByValues, CONTRIBUTION_GROUP_BY_DEFAULT_VALUE)
    || tempSearchValues.length >= 3
    || tempInvoiceId.length >= 1,
    [dateRange, groupByValues, tempInvoiceId, tempSearchValues],
  );

  const handleDateRangeChange = useCallback(
    (range: [Date | null, Date | null]) => {
      if (range[0] && range[1]) {
        setDateRange([
          dayjs(range[0]).isBefore(range[1])
            ? range[0] : range[1],
          dayjs(range[0]).isBefore(range[1])
            ? range[1] : range[0],
        ]);
        return;
      }

      setDateRange(range);
    },
    [setDateRange],
  );

  useEffect(() => {
    const validValueLength = debouncedValue.length >= 3 || debouncedValue.length === 0;
    if (validValueLength) {
      setSearchValue(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    const validInvoiceLength = debouncedInvoiceId.length >= 1 || debouncedInvoiceId.length === 0;
    if (validInvoiceLength) {
      setInvoiceId(debouncedInvoiceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInvoiceId]);

  const handleClear = useCallback(() => {
    handleClearAll();
    setInitCleaning(!initCleaning);
    setTimeout(() => {
      setDateRange(CONTRIBUTION_DATE_RANGE_DEFAULT_VALUE);
    });
  }, [handleClearAll, initCleaning, setDateRange]);

  return (
    <>
      <ClearAllButtonContainer
        width="max-content"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ClearAll
          onClick={handleClear}
          isVisible={isVisibleClearAll}
          buttonStyles={{ backgroundColor: isHovered ? undefined : 'canvas' }}
        />
      </ClearAllButtonContainer>

      <Box gap="spacing12" direction="row" flex="grow">
        <Box width={INPUT_WIDTH} height={{ max: '40px' }}>
          <NestedSelectDropdown
            testId="ContributionInvoiceReport_GroupBy"
            title="Group By"
            options={groupByOptions}
            values={groupByValues}
            onChange={(values) => setGroupByValues(values as ContributionGroupByType)}
            customWidth={INPUT_WIDTH}
          />
        </Box>
        <Box width={INPUT_WIDTH}>
          <StyledDateRangeDropdown
            testId="ContributionInvoiceReport_filter"
            prefix="Date:"
            className="period-picker"
            onChange={handleDateRangeChange}
            ellipsisMode
            textNotation
            startDate={dateRange ? dateRange[0] : null}
            endDate={dateRange ? dateRange[1] : null}
            icon={<CalendarIcon color="iconPrimary" />}
          />
        </Box>
        <Box width={INPUT_WIDTH}>
          <SearchInput
            testId="ContributionInvoiceReport_filter-search"
            className="search-input"
            value={tempSearchValues}
            hasSearchingHistory
            placeholder="Organization"
            onChange={setSearchTempValues}
            resetValue={initCleaning}
          />
        </Box>
        <Box flex="grow">
          <SearchInput
            testId="ContributionInvoiceReport_filter-search"
            value={tempInvoiceId}
            hasSearchingHistory
            minHistoryItemsLength={1}
            placeholder="Invoice ID"
            onChange={(value) => setTempInvoiceId(value.replace(REGEXP.EXCLUDE_NUMBERS, ''))}
            resetValue={initCleaning}
          />
        </Box>
        <Box flex="grow">
          <SelectSSNDropdown width={INPUT_WIDTH} value={ssnOption} onChangeValue={setSSNOption}/>
        </Box>
      </Box>
    </>
  );
};
