import { useCoverageFields,useCoverageSourceState, useCoverageState } from '../../Coverage/hooks';

import { useChangesList } from './useChangesList';
import { useFieldsWithSubfields } from './useFieldsWithSubfields';
import { useMatchFields } from './useMatchFields';

const useCoverageSummary = () => {
  const state = useCoverageState();
  const sourceState = useCoverageSourceState();

  const fields = useCoverageFields();

  const { allFields: stateFields } = useFieldsWithSubfields(state, fields);

  const { allFields } = useFieldsWithSubfields(sourceState, fields);
  const list = useMatchFields(state, stateFields);

  const formatedBaseList = useMatchFields(sourceState, allFields);
  const changes = useChangesList(formatedBaseList, list);
  return {
    changes,
  };
};
export default useCoverageSummary;
