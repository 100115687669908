import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  DropdownButton,
  PopupWrapper,
  RadioButton,
  SiblingBoxesWithSpacing,
  Text,
  Tip,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Option, OptionKey } from '@/common/types';
import theme from '@/styles/theme';

const LONG_NAME_COUNT = 16;

export const IconWrapper = styled(Box)`
  font-size: 20px;
  box-shadow: none;
`;

interface Props {
  testId: string;
  title: string;
  onChange?: (value: OptionKey) => void;
  options: Option[];
  value: OptionKey;
  customWidth?: string;
  isWithControls?: boolean;
}

export const SingleRadioSelectDropdown: React.FC<Props> = ({
  testId,
  onChange,
  title,
  options,
  value,
  customWidth,
  isWithControls,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [currentValue, setCurrentValue] = useState<OptionKey>();
  const [currentSubTitle, setCurrentSubTitle] = useState('');

  const handleCheckOption = useCallback((optionKey: string) => {
    setCurrentValue(optionKey);
  }, []);

  const handleApply = useCallback(() => {
    if (onChange) onChange(currentValue);
    setExpanded(false);
  }, [currentValue, onChange]);

  const handleCancel = useCallback(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      setCurrentValue(undefined);
    }
    setExpanded(false);
  }, [value]);

  const handleToggleOpenClose = useCallback(() => {
    if (!expanded) {
      setExpanded(true);
      return;
    }

    setExpanded(false);
    handleCancel();
  }, [expanded, handleCancel]);

  const subTitleMaker = useCallback(() => {
    const currentOption = options.find((option) => option.key === value);

    const subTitle = `${currentOption?.title}` || '';

    setCurrentSubTitle(`${subTitle}`);
  }, [options, value]);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
    subTitleMaker();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PopupWrapper
      onVisible={setExpanded}
      controlledVisible={expanded}
      flat
      flex
      style={{
        marginTop: '2px',
        left: 0,
        width: '300px',
      }}
      buttonStyle={{
        padding: 0,
      }}
      testId={`${testId}_SingleRadioSelect_open-button`}
      content={(
        <Box
          width="300px"
          round={{ size: 'spacing8' }}
          background="canvas"
          data-testid={`${testId}_SingleRadioSelect_wrapper`}
        >

          <Box pad={{ horizontal: 'spacing16', top: 'spacing16', bottom: 'spacing4' }}>
            {options.map((option) => (
              <Box key={option.key}>
                <Box margin={{ bottom: 'spacing12' }}>
                  <RadioButton
                    name={`${option.value}`}
                    checked={currentValue === option.key}
                    label={(
                      <Text size="14px" color="textBody">
                        {option.title}
                      </Text>
                    )}
                    onChange={() => {
                      handleCheckOption(`${option.key}`);
                      if (!isWithControls && onChange) {
                        onChange(`${option.key}`);
                        setExpanded(false);
                      }
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          {isWithControls
          && (
            <>
              <Box
                width="100%"
                height="1px"
                background={{ color: 'border1' }}
              />

              <Box
                direction="row"
                align="center"
                pad="spacing16"
              >
                <SiblingBoxesWithSpacing width="control">
                  <AppButton
                    testId={`${testId}_SingleRadioSelect_cancel`}
                    buttonType="secondary"
                    type="button"
                    width="128px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </AppButton>
                </SiblingBoxesWithSpacing>
                <SiblingBoxesWithSpacing width="control">
                  <AppButton
                    testId={`${testId}_SingleRadioSelect_apply`}
                    type="button"
                    width="128px"
                    onClick={handleApply}
                  >
                    Apply
                  </AppButton>
                </SiblingBoxesWithSpacing>
              </Box>
            </>
          )}
        </Box>
      )}
    >
      <DropdownButton
        style={{
          width: customWidth || '220px',
          paddingBlock: 0,
        }}
        expanded={expanded}
        data-testid={`${testId}_SingleRadioSelect_dropdown`}
        onClick={handleToggleOpenClose}
      >
        <Box direction="row">
          <Text
            margin={{ right: 'spacing4' }}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {title}:
          </Text>
          <Tip
            plain
            dropProps={{ align: { left: 'right' } }}
            content={currentSubTitle.length > LONG_NAME_COUNT && !expanded
              ? (
                <Box
                  style={{
                    display: 'inline-block',
                    width: 'auto',
                  }}
                  background="background3"
                  border={{ size: 'small', color: 'border' }}
                  pad={{ horizontal: 'spacing6', bottom: 'spacing2' }}
                >
                  <Text size="small" color="textOnColor">{currentSubTitle}</Text>
                </Box>
              ) : null}
          >
            <Text
              color="textAccent"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {currentSubTitle}
            </Text>
          </Tip>
        </Box>
        <IconWrapper>
          {expanded ? (
            <ChevronUpIcon
              data-testid={`${testId}_SingleRadioSelect_ChevronDown`}
              color={theme.colors.iconPrimary}
            />
          ) : (
            <ChevronDownIcon
              data-testid={`${testId}_SingleRadioSelect_ChevronUp`}
              color={theme.colors.iconPrimary}
            />
          )}
        </IconWrapper>
      </DropdownButton>
    </PopupWrapper>
  );
};
