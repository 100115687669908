import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PLAN_LIST_QUERY_NAME } from '@/modules/plan/PlansList/queries/usePlansList.query';

export default (planId: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => api.delete(PATHS.PLAN_BY_ID(planId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PLAN_LIST_QUERY_NAME);
      },
    },
  );
  return {
    handleDelete: mutation.mutate,
  };
};
