import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text } from '@common-fe/common-fe';

import { useGetEnrollments } from '@/modules/employee/Employee/hooks/useGetEnrollment';

interface Props {
  isCurrentHSA?: boolean;
}

const DisclosureBankMessage: React.FC<Props> = ({
  isCurrentHSA,
}) => {
  const { id, enrollmentId } = useParams<{ id: string, enrollmentId: string }>();
  const { isHSAExists } = useGetEnrollments({
    accountId: enrollmentId,
    employeeId: id,
  });

  if (!isHSAExists || !isCurrentHSA) {
    return null;
  }
  return (
    <Box data-testId="disclosure-bank-message" direction="column">
      <Box margin={{ top: 'l', bottom: 'm' }} border={{ side: 'bottom', size: '1px', color: 'border1' }} />
      <Text color="textSecondary" size="medium">
        Funds in an HSA Deposit Account are held at UMB Bank, n.a., Member FDIC.
      </Text>
      {isCurrentHSA
        && (
          <Text color="textSecondary" size="medium" margin={{ top: 's' }}>
            Investment advisory services offered through Intellicents Investment Solutions,
            Inc., an SEC registered investment adviser.
          </Text>
        )}
    </Box>
  );
};
export default DisclosureBankMessage;
