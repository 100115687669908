import React from 'react';
import { Box, CardTypesEnum } from '@common-fe/common-fe';

import { GroupedPlan } from '@/modules/plan/plan.types';

import PlanTypeCard from './PlanTypeCard';

export interface Props {
  activeTemplates?: GroupedPlan[];
  selectedPlanTypes?: CardTypesEnum[];
  isShowedCopayError?: boolean;
  onCardSelect: (type: CardTypesEnum) => void;
}

const PlansTypeForm: React.FC<Props> = ({ activeTemplates, selectedPlanTypes, isShowedCopayError, onCardSelect }) => {  
  return (
    <>
      <Box
        data-testId="plan-type-form"
        round="container1Round"
      >
        {
          activeTemplates?.map((plan, index) => (
            <PlanTypeCard
              key={plan?.categoryEnum}
              type={plan?.categoryEnum}
              isLastItem={index === activeTemplates.length - 1}
              isChecked={selectedPlanTypes?.includes(plan?.categoryEnum)}
              onSelect={onCardSelect}
              isSelectedMode
              isShowedCopayError={isShowedCopayError}
            />
          ))
        }
      </Box>
    </>
  );
};

export default PlansTypeForm;
