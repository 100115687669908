import React from 'react';
import {
  Box, RawButton,
  Text, TextWithPointer, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useOrgLabel } from '@/modules/core/hooks';
import theme from '@/styles/theme';

const StyledTextWithPointer = styled(TextWithPointer)`
  &:hover {
    color: ${theme.colors.textAccent};
  }
`;

interface Props {
  organizationType: string;
  text: string;
  isEmployer?: boolean;
  onClick: () => void;
  testId?: string
}
const CopayName: React.FC<Props> = ({
  testId, isEmployer, onClick, organizationType = '', text,
}) => {
  const orgLabel = useOrgLabel(organizationType);
  return (
    <RawButton data-testid={`${testId}_button`} onClick={onClick}>
      <Box direction="row" align="center">
        {!isEmployer ? (
          <Box
            height="18px"
            margin={{ right: 'xs' }}
            pad={{ horizontal: '3px' }}
            round="button2Round"
            background="nonClaimBg"
            align="center"
            justify="center"
            width={{ min: '25px', max: '25px' }}
            style={{
              flexShrink: 0,
            }}
          >
            <Text size="xsmall" weight={500}>{orgLabel}</Text>
          </Box>
        ) : undefined }

        <StyledTextWithPointer
          title={text}
          size="medium"
          color="textBody"
          ellipsisMode
          weight="bold"
          style={{ paddingRight: theme.spacings.xs }}
        >
          {text}
        </StyledTextWithPointer>
      </Box>
    </RawButton>

  );
};

export default CopayName;
