import { useMemo } from 'react';

import { useGetCardReportQuery } from '@/modules/employer/components/Reports/queries/useGetCardsReport.query';

const useCardReports = () => {
  const {
    formattedCardReport,
    isLoading,
    isSubLoading,
    isEmpty,
  } = useGetCardReportQuery();

  const reports = useMemo(() => ([
    {
      ...formattedCardReport,
      isWithFilter: true,
      showHideButtonCenter: true,
      isLoading,
      isSubLoading,
      isEmpty,
    },
  ]),
  [
    formattedCardReport,
    isLoading,
    isSubLoading,
    isEmpty,
  ]);

  return reports;
};

export default useCardReports;
