import React from 'react';
import { Box } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import Topbar from './Topbar';

interface Props {
  children: React.ReactNode;
}
const TopbarWrapper: React.FC<Props> = ({ children }) => (

  <Box width="100%" data-testid="topbar-wrapper">
    <div>
      <Topbar />
    </div>
    <Box align="center">
      <Box width={theme.defaultContentWidth}>
        {children}
      </Box>
    </Box>

  </Box>

);

export default TopbarWrapper;
