import { useCallback } from 'react';

import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { GeneralInformationPayload } from '../SetupEmployer.types';

export default (id: string) => {
  const setGeneralInfo = useStore((state) => state.setGeneralInfo);

  const handleSave = useCallback((newValue: GeneralInformationPayload) => {
    setGeneralInfo(newValue);
  }, [setGeneralInfo]);

  return {
    saveGeneralInfo: handleSave,
  };
};
