import { ALL_OPTION } from '@/common/constants';
import { Address, Option } from '@/common/types';
import { PaymentMethodType } from '@/modules/employee/employee.types';
import theme from '@/styles/theme';

export enum CheckStatus {
  PENDING = 'PENDING',
  ISSUED = 'ISSUED',
  CLEARED = 'CLEARED',
  VOIDED = 'VOIDED',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  REISSUING = 'REISSUING',
  REISSUED = 'REISSUED',
  VOIDING = 'VOIDING',
  READY = 'READY',
  SENT = 'SENT',
}

export const checkStatusColor = {
  PENDING: theme.colors.checkStatusPending,
  ISSUED: theme.colors.checkStatusIssued,
  CLEARED: theme.colors.checkStatusCleared,
  VOIDED: theme.colors.checkStatusVoided,
  EXPIRED: theme.colors.checkStatusExpired,
  REISSUED: theme.colors.checkStatusReissued,
  VOIDING: theme.colors.checkStatusVoiding,
  EXPIRING: theme.colors.checkStatusExpiring,
  REISSUING: theme.colors.checkStatusReissuing,
  READY: theme.colors.checkStatusReady,
  SENT: theme.colors.checkStatusReady,
};

export enum VoidReason {
  NOT_RECEIVED = 'NOT_RECEIVED',
  WRONG_ADDRESS = 'WRONG_ADDRESS',
  LOST_CHECK = 'LOST_CHECK',
  NO_LONGER_NEEDED = 'NO_LONGER_NEEDED',
  RETURNED_CHECK = 'RETURNED_CHECK',
}

export interface CheckPayload {
  amount?: number;
  check_id?: number;
  claim_ids?: number[];
  current_status?: CheckStatus;
  employee_id?: number;
  employee_name?: string;
  employer_name?: string;
  issued_date?: string;
  pay_to?: string;
  status_date?: string;
  check_address?: Address;
  void_reason?: VoidReason;
  payment_method_id?: number;
  reissue_check_id?: number;
  reissue_payment_method_type?: PaymentMethodType;
}

export interface Check {
  amount?: number;
  id?: string;
  claimIds?: string[];
  status?: CheckStatus;
  employeeId?: string;
  employeeName?: string;
  employerName?: string;
  issuedDate?: string;
  payTo?: string;
  statusDate?: string;
  address?: Address;
  voidReason?: VoidReason;
  paymentMethodId?: string;
  reissueCheckId?: string;
  reissuePaymentMethodType?: PaymentMethodType;
}

export const CHECK_STATUSES_OPTIONS: Option[] = [
  ALL_OPTION,
  {
    key: CheckStatus.PENDING,
    value: CheckStatus.PENDING,
    title: 'Pending',
  },
  {
    key: CheckStatus.ISSUED,
    value: CheckStatus.ISSUED,
    title: 'Issued',
  },
  {
    key: CheckStatus.CLEARED,
    value: CheckStatus.CLEARED,
    title: 'Cleared',
  },
  {
    key: CheckStatus.VOIDED,
    value: CheckStatus.VOIDED,
    title: 'Voided',
  },
  {
    key: CheckStatus.EXPIRED,
    value: CheckStatus.EXPIRED,
    title: 'Expired',
  },
  {
    key: CheckStatus.EXPIRING,
    value: CheckStatus.EXPIRING,
    title: 'Expiring',
  },
  {
    key: CheckStatus.REISSUING,
    value: CheckStatus.REISSUING,
    title: 'Reissuing',
  },
  {
    key: CheckStatus.REISSUED,
    value: CheckStatus.REISSUED,
    title: 'Reissued',
  },
  {
    key: CheckStatus.VOIDING,
    value: CheckStatus.VOIDING,
    title: 'Voiding',
  },
];

export const VOID_REASON_OPTIONS: Option[] = [
  {
    key: VoidReason.NOT_RECEIVED,
    value: VoidReason.NOT_RECEIVED,
    title: 'Not received',
  },
  {
    key: VoidReason.WRONG_ADDRESS,
    value: VoidReason.WRONG_ADDRESS,
    title: 'Wrong address',
  },
  {
    key: VoidReason.LOST_CHECK,
    value: VoidReason.LOST_CHECK,
    title: 'Lost check',
  },
  {
    key: VoidReason.NO_LONGER_NEEDED,
    value: VoidReason.NO_LONGER_NEEDED,
    title: 'No longer needed',
  },
  {
    key: VoidReason.RETURNED_CHECK,
    value: VoidReason.RETURNED_CHECK,
    title: 'Returned check',
  },
];
