import React, {
  useCallback,
} from 'react';
import {
  Box,
  Enrollment,
  EnrollmentStatus,
  Grid, 
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import { Option } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { ENROLLMENT_ITEM_WIDTH_L } from '@/modules/employee/Employee/EnrollmentBalances/components/EnrollmentItem';
import { getEnrollmentStatusColor } from '@/modules/employee/Employee/PersonalInformation/EmploymentStatusTimeline/utils';

import { Investors } from '../queries/useGetInvestors.query';

import { EnrollmentItem } from './components/EnrollmentItem';

export const OPTIONS: Option[] = [
  {
    key: EnrollmentStatus.Null,
    value: 'All',
    title: 'All',
  }, {

    key: EnrollmentStatus.Active,
    value: 'Active',
    title: 'Active',
  }, {
    key: EnrollmentStatus.Canceled,
    value: 'Canceled',
    title: 'Canceled',
  }];

const EnrollmentsWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border1};

  &:last-child {
    border-bottom: none;
  }
`;
interface EnrollmentsProps {
  title?: string;
  employeeId?: string;

  status?: EnrollmentStatus;
  enrollments: Enrollment[];
  investorsData: Investors;
  employeeName?: string;
  isDisplayNameInTwoRaw?: boolean;
  hasEnrollmentWithDisplayName?: boolean;
}
export const EnrollmentsList: React.FC<EnrollmentsProps> = ({
  employeeId, enrollments, title, status, investorsData, employeeName, hasEnrollmentWithDisplayName,
}) => {
  const history = useHistory();
  const handleSelect = useCallback(
    (enrollmentId: string) => () => history.push(ROUTES.ENROLLMENT_DETAILS(
      employeeId,
      enrollmentId,
    )), [employeeId, history],
  );
  return (
    <EnrollmentsWrapper
      data-testid={`enrolment-list_${status}`}
      margin={{ top: 's' }}
    >
      {title && (
        <Box margin={{ bottom: 'm' }}>
          <Text size="xxlarge" weight={400}>
            {title}
          </Text>
        </Box>
      )}
      <Grid
        data-testid="MyEnrollmentList-id"
        columns={ENROLLMENT_ITEM_WIDTH_L}
        gap="spacing24"
      >
        {
          (enrollments || []).map((enrollment) => (
            <EnrollmentItem
              key={enrollment.id}
              enrollment={enrollment}
              onSelect={handleSelect(enrollment.id)}
              statusColor={getEnrollmentStatusColor(enrollment.enrollmentStatus)}
              clickMode
              investorsData={investorsData}
              employeeName={employeeName}
              hasEnrollmentWithDisplayName={hasEnrollmentWithDisplayName}
              width='100%'
              isListMode
            />
          ))
        }
      </Grid>
    </EnrollmentsWrapper>
  );
};
export interface Props {
  children?: React.ReactNode;
  employeeName?: string;
}

