import { useMemo } from 'react';

import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { PlanStatuses } from '../../plan.types';
import { useTemplatesListQuery } from '../queries';

export default (searchValue: string) => {
  const {
    data: draftTemplates,
    formattedArrayTemplates: listDraftTemplates,
    isLoading: isDraftTemplatesLoading,
    error: draftError,
    refetch: draftTemplatesRefetch,
  } = useTemplatesListQuery(null, PlanStatuses.DRAFT, searchValue);

  const {
    data: activeTemplates,
    formattedArrayTemplates: listActiveTemplates,
    isLoading: isActiveTemplatesLoading,
    error: activeError,
    refetch: activeTemplatesRefetch,
  } = useTemplatesListQuery(null, PlanStatuses.ACTIVE, searchValue);

  const {
    data: inactiveTemplates,
    formattedArrayTemplates: listInactiveTemplates,
    isLoading: isInactiveTemplatesLoading,
    error: inactiveError,
    refetch: inactiveTemplatesRefetch,
  } = useTemplatesListQuery(null, PlanStatuses.INACTIVE, searchValue);

  const allDraftTemplates = useMemo(
    () => [...draftTemplates],
    [draftTemplates],
  );

  const allListDraftTemplates = useMemo(
    () => [...listDraftTemplates],
    [listDraftTemplates],
  );
  const isLoading = useMemo(
    () => isDraftTemplatesLoading
    // || isReadyForReviewTemplatesLoading
    || isActiveTemplatesLoading
    || isInactiveTemplatesLoading,
    [isActiveTemplatesLoading, isDraftTemplatesLoading, isInactiveTemplatesLoading],
  );

  const count = useMemo(
    () => listActiveTemplates.length
    + allListDraftTemplates.length
    + listInactiveTemplates.length,
    [listActiveTemplates.length, allListDraftTemplates.length, listInactiveTemplates.length],
  );
  const currentError = useMemo(
    () => draftError
    // || readyForReviewError
    || activeError
    || inactiveError,
    [draftError, activeError, inactiveError],
  );
  const currentRefetch = useMemo(() => {
    if (draftError) return draftTemplatesRefetch;
    // if (readyForReviewError) return readyForReviewTemplatesRefetch;
    if (activeError) return activeTemplatesRefetch;
    return inactiveTemplatesRefetch;
  }, [
    activeError, activeTemplatesRefetch,
    draftError, draftTemplatesRefetch,
    inactiveTemplatesRefetch]);
  const { isServerError, setIsServerError } = useIsServerError(currentError);
  return {
    count,
    isLoading,
    isServerError,
    currentRefetch,
    setIsServerError,
    inactiveTemplates,
    activeTemplates,
    allDraftTemplates,
    listActiveTemplates,
    allListDraftTemplates,
    listInactiveTemplates,
    isDraftTemplatesLoading,
    isActiveTemplatesLoading,
    isInactiveTemplatesLoading,
  };
};
