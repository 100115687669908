import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { NO_KEY, YES_KEY } from '@/common/constants';
import { useFieldsWithDefaultValues } from '@/utils';

import LinkField from '../LinkField';
import { useExpressSetupLinksStore } from '../stores';

interface Params {
  disabled?: boolean;
  isDistributorMode?: boolean;
}
const useExpressSetupLinksFields = ({ disabled = false, isDistributorMode }: Params) => {
  const defaultValues = useExpressSetupLinksStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(
    () => [
      {
        name: 'isExternalLink',
        type: FieldTypes.Radio,
        label: 'External link',
        options: [
          {
            label: 'Inactive',
            value: NO_KEY,
          },
          {
            label: 'Active',
            value: YES_KEY,
          },
        ],
        subFields: isDistributorMode ? [{
          parentValue: YES_KEY,
          name: 'externalLink',
          label: ' ',
          type: FieldTypes.Node,
          value: (
            <LinkField  value={defaultValues?.externalLink} isCopyMode/>
          )
        }]: undefined,
        disabled: disabled,
      },
    ],
    [defaultValues?.externalLink, disabled, isDistributorMode]
  );
  const formattedFields = useFieldsWithDefaultValues(fields, defaultValues);

  return formattedFields;
};

export default useExpressSetupLinksFields;
