 
import React, {
  useCallback, useEffect,
  useRef, useState, } from 'react';
import { Box } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import AddModalWrapper from '@/modules/employer/components/SetupEmployer/AddModalWrapper';
import { ContactResponseModel } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurity.types';
import {
  useContactListQuery,
} from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/queries/useContacts.queries';
import { useSetupEmployerStore } from '@/modules/employer/store';

import AddEditContactsSecurityModal
  from './AddEditContactsSecurityModal/AddEditContactsSecurityModal';
import ContactsSecurityTable from './ContactsSecurityTable/ContactsSecurityTable';

const ContactsSecurity: React.FC<{ disabled?: boolean, organizationId?: string }> = ({ disabled, organizationId }) => {
  const { hasOneContact, setContactMap } = useSetupEmployerStore();
  const { contactList, refetch } = useContactListQuery({ organizationId, enabled: !hasOneContact, size: 1000 });
  const CONFIRM_CANCEL_TEXT = 'Changes that you made may not be saved.';
  const [triggerRefetching, setTriggerRefetching] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editContact, setEditContact] = useState<ContactResponseModel | undefined>();
  const [viewContactMode, setViewContactMode] = useState<boolean>(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);

  const [justCreatedLabel, setJustCreatedLabel] = useState(false);
  const justCreatedLabelTimer = useRef<NodeJS.Timeout>();

  const onSubmitHandler = useCallback(async () => {
    refetch();
    setJustCreatedLabel(false);
    if (justCreatedLabelTimer.current) { clearTimeout(justCreatedLabelTimer.current); }
    if (!editContact) {
      setJustCreatedLabel(true);
      justCreatedLabelTimer.current = setTimeout(() => {
        setJustCreatedLabel(false);
      }, 5000);
    }
    setEditContact(undefined);
    setOpenAddModal(false);
    setTriggerRefetching(uniqueId());
  }, [editContact, refetch]);

  const onSetVisibleHandler = useCallback((value: boolean) => {
    let confirm = true;
    if (!value && !viewContactMode && shouldConfirmOnClose) {
      confirm = window.confirm(CONFIRM_CANCEL_TEXT);
    }
    if (confirm) {
      setEditContact(undefined);
      setViewContactMode(false);
      setOpenAddModal(value);
    }
  }, [shouldConfirmOnClose, viewContactMode]);

  useEffect(() => {
    if (contactList.length) {
      setContactMap(contactList.reduce(
        (map, contact) => ({ ...map, [contact.id]: contact }), {},
      ));
    }
  }, [contactList, setContactMap]);

  return (
    <Box
      border={{ color: 'border2', size: 'small' }}
      background="canvas"
      round="container1Round"
    >
      <AddModalWrapper
        id="contact_information"
        title="Contact Information"
        visible={openAddModal}
        onSetVisible={onSetVisibleHandler}
        buttonText="Add New Contact"
        disabled={disabled}
        modalTitle={
          editContact
            ? `${editContact.first_name} ${editContact.last_name}`
            : 'Add New Contact'
        }
        successTextShow={justCreatedLabel}
        successText="Contact added."
      >
        <AddEditContactsSecurityModal
          data={editContact}
          open
          onDirty={setShouldConfirmOnClose}
          firstContact={!hasOneContact}
          onCancel={() => onSetVisibleHandler(false)}
          onSubmit={onSubmitHandler}
          viewMode={viewContactMode}
          setViewContactMode={setViewContactMode}
        />
      </AddModalWrapper>

      {hasOneContact ? (
        <ContactsSecurityTable
          disabled={disabled}
          organizationId={organizationId}
          onView={(contact) => {
            setViewContactMode(true);
            setEditContact(contact);
            setOpenAddModal(true);
          }}
          onEdit={(contact) => {
            setEditContact(contact);
            setOpenAddModal(true);
            setViewContactMode(false);
          }}
          triggerRefetching={triggerRefetching}
        />
      ) : null}

      {openAddModal && (
        <AddEditContactsSecurityModal
          data={editContact}
          open
          onDirty={setShouldConfirmOnClose}
          firstContact={!hasOneContact}
          onCancel={() => onSetVisibleHandler(false)}
          onSubmit={onSubmitHandler}
          viewMode={viewContactMode}
          setViewContactMode={setViewContactMode}
        />
      )}
    </Box>
  );
};

export default ContactsSecurity;
