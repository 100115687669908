import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { EXPIRED_QUERY_KEY } from '@/modules/ProcessingDefinition/EnrollmentMatrix/queries/useEnrollmentMatrixes.queries';
import { EnrollmentMatrixResponse } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import { useEnrollmentMatrixStore } from '../../stores/useEnrollmentMatrix.store';

interface EnrollmentMatrixPayload {
  name?: string;
  priority?: number;
  valid_from?: string;
  valid_to?: string;
  organization_id?: number;
  processing_rule_id?: number;
  plan_id?: number;
  plan_year_id?: number;
}

const getActivePageNumber = (
  elementIndex: number,
  pageSize: number,
) => Math.ceil(elementIndex / pageSize);

export const useCreateEnrollmentMatrixQuery = (
  perPage?: number,
) => {
  const {
    setNewAddedEnrollmentMatrixId,
    setActivePage,
  } = useEnrollmentMatrixStore();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (payload: EnrollmentMatrixPayload) => api.post<EnrollmentMatrixResponse>(
      PATHS.ENROLLMENT_MATRIXES,
      payload,
    ),
    {
      onSuccess: (data) => {
        if (data.data.id || data.data.id === 0) setNewAddedEnrollmentMatrixId(`${data.data.id}`);
        if (
          data.data.priority
          && perPage
        ) setActivePage(getActivePageNumber(data.data.priority, perPage));
        queryClient.invalidateQueries({ queryKey: [EXPIRED_QUERY_KEY] });
      },
    },
  );
  return mutateAsync;
};

export default useCreateEnrollmentMatrixQuery;
