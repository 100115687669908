import styled from 'styled-components';

export const Wrapper = styled.button<{ hasBacklight?: boolean }>`
  width: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.canvas};
  border-radius: ${({ theme }) => theme.rounds.container1Round};
  ${({ theme }) => theme.shadows.default};
  border: none;
  ${({ theme }) => theme.height.activity};
  ${({ theme }) => theme.fonts.text14Medium}
  padding: ${({ theme }) => theme.padding.regular};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }

  ${({ theme, hasBacklight }) => {
    if (hasBacklight) {
      return {
        ...theme.border.primary,
        ...theme.shadows.highlighted,
      };
    }

    return {};
  }};
`;

export const Text = styled.p`
  margin-left: 16px;
  ${({ theme }) => theme.fonts.text14Medium};
  text-align: left;
  ${({ theme }) => theme.ellipsis};
`;

export const Date = styled.p`
  ${({ theme }) => theme.fonts.text14Regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 75px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: left;
`;
