import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Anchor,
  Box,   EyeIcon,
  HideIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { useSnackbar } from '@/modules/core/hooks';
import globalTheme from '@/styles/theme';
import { getMaskedCardNumber, getMaskedRoutingNumber, useCopyToClipboard } from '@/utils';
  
const TextWithHover = styled(Text)`
    &:hover {
      text-decoration: underline;
    }
  `;
  
const ACCOUNT_NUMBER_MASK = '• •••• •••• •••• ••••';

interface Props{
  accountNumber: string | null;
  routingNumber: string | null;
}

export const EnrollmentDetailsAccountNumber: React.FC<Props> = ({
  accountNumber,
  routingNumber,
}) => {
  const { handleAddPermanentSnackbar, handleReset } = useSnackbar();
  const copyToClipboardAccountNumber = useCopyToClipboard(accountNumber || '');
  const copyToClipboardRoutingNumber = useCopyToClipboard(routingNumber || '');

  const handleCopyToClipboardAccountNumber = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      copyToClipboardAccountNumber();
      handleAddPermanentSnackbar({
        text: 'The Account Number is copied!',
        closeIcon: true,
      });
      setTimeout(() => {
        handleReset();
      }, 2500);
    }, [copyToClipboardAccountNumber, handleAddPermanentSnackbar, handleReset],
  );
  const handleCopyToClipboardRoutingNumber = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      copyToClipboardRoutingNumber();
      handleAddPermanentSnackbar({
        text: 'The Routing Number is copied!',
        closeIcon: true,
      });
      setTimeout(() => {
        handleReset();
      }, 2500);
    }, [copyToClipboardRoutingNumber, handleAddPermanentSnackbar, handleReset],
  );

  const [accountNumberVisible, setAccountNumberVisible] = useState(false);
  const [routingNumberVisible, setRoutingNumberVisible] = useState(false);

  const handleToggleAccountNumberVisible = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
      setAccountNumberVisible(!accountNumberVisible);
    }, [accountNumberVisible],
  );

  const handleToggleRoutingNumberVisible = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.stopPropagation();
      setRoutingNumberVisible(!routingNumberVisible);
    }, [routingNumberVisible],
  );

  useEffect(() => {
    setAccountNumberVisible(false);
    setRoutingNumberVisible(false);
  }, []);
  return (
    <Box
      data-testid="account-number_content"
      pad="spacing24"
      background="canvas"
      round="container1Round"
      border={{ color: 'border2' }}
      gap="spacing16"
      style={{
        boxShadow: globalTheme.shadows.default['box-shadow'],
      }}
    >
      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Account number
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          {accountNumberVisible ? (
            <Box onClick={handleCopyToClipboardAccountNumber} color="textOnColor">
              <TextWithHover data-testid="account-number_text" size="medium" weight={400}>
                {accountNumber
                  ? getMaskedCardNumber(accountNumber, !accountNumberVisible)
                  : ACCOUNT_NUMBER_MASK}
              </TextWithHover>
            </Box>
          ) : (
            <Text data-testid="account-number_text" size="medium" weight={400}>
              {accountNumber
                ? getMaskedCardNumber(accountNumber, !accountNumberVisible)
                : ACCOUNT_NUMBER_MASK}
            </Text>
          )}

          <Anchor
            data-testid="account-number_visible_button"
            onClick={handleToggleAccountNumberVisible}
            margin={{ left: 'spacing12' }}
          >
            {accountNumberVisible ? (
              <EyeIcon />)
              : (
                <HideIcon />
              )}

          </Anchor>
        </Box>
      </Box>
      {!!routingNumber && (
        <Box 
          direction="row"
          height={{ min: '40px', max: '40px' }}
          align="center"
        >
          <Box width={{ max: '256px', min: '256px' }}>
            <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
              Routing number
            </Text>
          </Box>
          <Box direction="row" justify="between" height={{ max: '24px' }}>
            {routingNumberVisible ? (
              <Box onClick={handleCopyToClipboardRoutingNumber} color="textOnColor">
                <TextWithHover data-testid="routing-number_text" size="medium" weight={400}>
                  {routingNumber
                    ? getMaskedRoutingNumber(routingNumber, !routingNumberVisible)
                    : ACCOUNT_NUMBER_MASK}
                </TextWithHover>
              </Box>
            ) : (
              <Text data-testid="routing-number_text" size="medium" weight={400}>
                {routingNumber
                  ? getMaskedRoutingNumber(routingNumber, !routingNumberVisible)
                  : ACCOUNT_NUMBER_MASK}
              </Text>
            )}

            <Anchor
              data-testid="routing-number_visible_button"
              onClick={handleToggleRoutingNumberVisible}
              margin={{ left: 'spacing12' }}
            >
              {routingNumberVisible ? (
                <EyeIcon />)
                : (
                  <HideIcon />
                )}

            </Anchor>
          </Box>
        </Box>
      ) }
    </Box>
  );
};
