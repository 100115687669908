 
import { toString } from 'lodash';

import { HealthPlanResponse } from '../../HealthPlan.types';

const getBooleanToString = (value?: boolean) => {
  if (value === true) {
    return 'true';
  }
  if (value === false) {
    return 'false';
  }
  return '';
};

export default (healthPlan?: HealthPlanResponse) => {
  if (!healthPlan) {
    return {};
  }

  let stringHealthPlans = [] as string[];
  if (healthPlan.plans) {
    stringHealthPlans = healthPlan.plans.map((item) => item.id.toString());
  }

  return {
    parentTemplate: toString(healthPlan.parent_id),
    ...healthPlan?.summary ? {
      planSummary: healthPlan?.summary,
    } : {},
    healthPlanName: healthPlan.name.name,
    healthPlanStatus: healthPlan.plan_status,
    carrierName: toString(healthPlan.carrier_id.carrier_id),
    healthPlanTypes: healthPlan.health_plan_types.health_plan_types,
    deductibleService: toString(healthPlan.deductibles_by_service_type.deductibles_by_service_type),
    autocreateCdhEnrollment: getBooleanToString(healthPlan.is_auto_enrollment.is_auto_enrollment),
    enrollmentMatrixName: healthPlan?.enrollment_matrix?.name || '',
    processingRuleName: toString(healthPlan?.enrollment_matrix?.processing_rule_id),
    planYearId: toString(healthPlan?.plan_year?.id),
    planYear: healthPlan?.plan_year?.name,
    planStartDate: healthPlan?.plan_year?.valid_from,
    planEndDate: healthPlan?.plan_year?.valid_to,
    planCode: healthPlan.plan_code,
    plans: stringHealthPlans,
  };
};
