import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Box, ChevronDownIcon, ChevronUpIcon, Inscription, PlaceholderIcon,
  RawButton, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import useAccordionStore from '@/components/elements/Accordion/useAccordion.store';
import { ValidatedRequiredField } from '@/components/elements/ValidatedRequiredField';
import { RequiredFieldItem } from '@/modules/employer/components/SetupEmployer/FinalSetUp/BoxOfRequiredFields';
import globalTheme from '@/styles/theme';

import { StyledHeader } from './BlockWrapper.styles';

const StyledPlaceholderIcon = styled(PlaceholderIcon)`
  fill: ${({ theme }) => theme.colors.button};
  margin: 0px ${({ theme }) => theme.spacings.spacing4};
`;

const StyledIcon = styled(Box)<{ expanded?: boolean, color?: string }>`
  svg {
/* ${({ expanded, theme, color }) => `fill: ${expanded ? color || theme.colors.button : color || theme.colors.iconPrimary};`} */
   ${({ theme, color }) => `fill: ${ color || theme.colors.button};`}
  }
`;
const StyledIconWrapper = styled(Box)`
  i:before {
    color: ${({ theme }) => theme.colors.iconPrimary};
    font-weight: bold;
    font-size: 20px;
  }
`;
const StyledDescriptionItem = styled(Box)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  }
`;

export interface Props {
  title?: string | React.ReactNode;
  iconColor?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  isPureChild?: boolean;
  isFirstChild?: boolean;
  defaultExpandedValue?: boolean;
  required?: boolean | string;
  optional?: boolean | string;
  expandless?: boolean;
  moduleAnchorId?: string;
  image?: boolean;
  wrapperStyle?: React.CSSProperties;
  flatMode?: boolean;
  blockIconColor?: string;
  description?: {
    note?: string,
    requiredFields?: RequiredFieldItem[];
  }[]
}
const BlockWrapper: React.FC<Props> = ({
  title,
  icon,
  required,
  children,
  isPureChild,
  defaultExpandedValue,
  moduleAnchorId,
  optional,
  flatMode,
  image,
  expandless,
  description,
  wrapperStyle,
  iconColor,
  blockIconColor,
}) => {
  const { handleAdd, list } = useAccordionStore();
  const [expanded, setExpanded] = useState(
    typeof defaultExpandedValue === 'boolean'
      ? defaultExpandedValue
      : true,
  );
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
    handleAdd({
      id: moduleAnchorId || '',
      isExpanded: !expanded,
    });
  }, [expanded, handleAdd, moduleAnchorId]);

  useEffect(() => {
    handleAdd({
      id: moduleAnchorId || '',
      isExpanded: expanded,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ok = list.find(({ id }) => id === moduleAnchorId) || null;
    if (ok) {
      setExpanded(ok.isExpanded);
    }
  }, [list, moduleAnchorId]);
  return (
    <div id={moduleAnchorId}>
      <Box direction="column" style={wrapperStyle}>
        <Box>
          <RawButton onClick={handleToggle}>
            <Box
              direction="row"
              align="center"
              justify="start"
              {...isPureChild || !description ? {} : { margin: { bottom: 's' } }}
            >
              <Box
                align="center"
                justify="center"
                direction="row"
              >
                {!expandless && (
                  <StyledIconWrapper width="24px" align="center" justify="center">
                    {expanded ? (
                      <ChevronUpIcon data-testid="BlockWrapper" size="20px" color={iconColor || 'iconPrimary'} />
                    ) : (
                      <ChevronDownIcon data-testid="BlockWrapper" size="20px" color={iconColor || 'iconPrimary'} />
                    )}
                    
                  </StyledIconWrapper>
                )}
                {icon
                  ? (
                    <StyledIcon margin={{ left: 'spacing18' }} expanded={expanded} color={blockIconColor}>
                      {icon}
                    </StyledIcon>
                  )
                  : null}
              </Box>
              {image && (
                <StyledPlaceholderIcon size="26px" />
              )}
              {typeof title === 'string' ? (
                <Box>
                  <StyledHeader
                    fill
                    level={4}
                    size="small"
                    margin={{ left: '14px' }}
                    color="textTitle"
                  >
                    {title}
                  </StyledHeader>
                </Box>
              ) : title}
              {required && (
                <Text
                  style={{ whiteSpace: 'nowrap' }}
                  size="small"
                  weight={500}
                  color="danger"
                >
                  {typeof required === 'string' ? required : 'Required'}
                </Text>
              )}
              {optional && (
                <Text
                  style={{ whiteSpace: 'nowrap' }}
                  size="small"
                  weight="normal"
                  color="textSecondary"
                  margin={{ left: 'spacing12' }}
                >
                  {optional === true ? 'Optional' : optional}
                </Text>
              )}
            </Box>
          </RawButton>
          {description && (
            <Box margin={{ left: '60px' }}>
              {description && Boolean(description.length) && (
                description.map(({ note, requiredFields }) => (
                  <StyledDescriptionItem key={note}>
                    <Inscription lineHeight="18px" size="small" weight={500} color="textSecondary">{note}</Inscription>
                    {requiredFields && requiredFields?.length > 0 && (
                      <Box margin={{ top: 'spacing4' }} direction="row" wrap>
                        {requiredFields.map((field) => (
                          <ValidatedRequiredField
                            key={field.title}
                            valid={field.isValid}
                            submitted={field.validate}
                          >
                            <Inscription
                              lineHeight="20px"
                              color={field.isValid
                                ? globalTheme.colors.success
                                : globalTheme.colors.danger}
                            >
                              {field.title}
                            </Inscription>
                          </ValidatedRequiredField>
                        ))}
                      </Box>
                    )}
                  </StyledDescriptionItem>
                ))
              )}
            </Box>
          )}
        </Box>
        {expanded && isPureChild && children}
        {expanded && !isPureChild && (
          <Box background="module"
            margin={{...isPureChild ? {} : {  top: 's' }}}
            pad="spacing24"
            round="moduleRound">
            {flatMode ? (
              children
            ) : (
              <Box background="canvas" round="container1Round" elevation="default">
                {children}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default BlockWrapper;
