import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ReconciliationReportDownloadModal } from '../ReconciliationReports/components/ReconciliationReportDownloadModal';
import { ReconciliationDownloadModal } from '../ReconciliationReports/components/ReconciliationReportDownloadModal/types';

import { ReconciliationReportActivityList } from './components/ReconciliationReportActivityList';
import { ReconciliationReportDetailsHeader } from './components/ReconciliationReportDetailsHeader';
import { ReconciliationReportsDetailsList } from './components/ReconciliationReportsDetailsList';

const StyledWrap = styled(Box)`
  display: block;
`;

const ReconciliationReportDetails: React.FC = () => (
  <StyledWrap pad={{ bottom: 'spacing72' }}>
    <ReconciliationReportDetailsHeader />
    <Box margin={{ bottom: 'spacing24' }}>
      <ReconciliationReportsDetailsList />
    </Box>
    <ReconciliationReportActivityList />
    <ReconciliationReportDownloadModal modalType={ReconciliationDownloadModal.ClosesByAccount} />
  </StyledWrap>
);

export default ReconciliationReportDetails;
