import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import colors from '@/styles/colors';
import spacings from '@/styles/spacings';

import { PaymentMethodLabel } from './PaymentMethodLabel';
import { usePaymentMethodsStore } from './usePaymentMethods.store';

interface Props {
  onAdd?: () => void;
}

const PaymentMethodField: React.FC<Props> = ({
  onAdd,
}) => {
  const paymentMethod = usePaymentMethodsStore((state) => state.activeMethod);

  return (
    <Box style={{ position: 'relative' }}>
      <Box margin={{ bottom: spacings.s }}>
        <PaymentMethodLabel paymentMethod={paymentMethod} />
      </Box>

      <Box
        style={{
          position: 'absolute',
          top: '100%',
        }}
        width="max-content"
      >
        <Box
          onClick={onAdd}
          style={{ fontSize: '14px' }}
          width="fit-content"
        >
          <Text color={colors.textAccent} style={{ whiteSpace: 'nowrap' }}>
            {paymentMethod ? 'Change Default Payment Method' : 'Add Payment Method'}
          </Text>
        </Box>
      </Box>

    </Box>
  );
};
export default PaymentMethodField;
