import React, { useMemo } from 'react';
import { Box, CheckBox,Inscription,TextWithPointer } from '@common-fe/common-fe';

import ManyItemsTooltip from '@/components/elements/ManyItemsTooltip/ManyItemsTooltip';

import InfoTileIcon from './InfoTileIcon';

interface Props {
  id?: string;
  title?: string | React.ReactNode;
  infoTitle?: string;
  descriptions?: string[];
  icon?: string;
  isIconHidden?: boolean;
  checkedIds?: string[];
  setCheckedIds?: (val: string[]) => void;
  isMe?: boolean;
  isAllMode?: boolean;
  InjectedElement?: React.ReactNode;
}

const InfoTile: React.FC<Props> = ({
  id,
  title,
  infoTitle,
  descriptions,
  icon,
  isIconHidden,
  checkedIds = [],
  setCheckedIds,
  isMe,
  isAllMode,
  InjectedElement,
}) => {
  const isChecked = useMemo(() => checkedIds?.some((checkedId) => id === checkedId), [id, checkedIds]);
  const hoverTitle = useMemo(() => `${title || ''} ${infoTitle || ''}`.trim(), [title, infoTitle]);

  return (
    <Box direction="row" align="center">
      {isIconHidden || typeof title !== 'string' ? null : <InfoTileIcon icon={icon} title={title} />}
      {setCheckedIds && id ? (
        <Box pad={{ horizontal: 'spacing8' }}>
          <CheckBox
            onChange={() => setCheckedIds(isChecked ? checkedIds.filter((checkedId) => checkedId !== id) : [...checkedIds, id])}
            checked={isChecked}
          />
        </Box>
      ) : null}
      <Box direction="row" align="center" width="100%">
        <Box pad={{ left: 'spacing8' }} width="100%">
          {title && typeof title === 'string' ? (
            <Box direction="row" align="center">
              <TextWithPointer
                color="textTitle"
                title={hoverTitle}
                weight="bold"
                ellipsisMode
                style={{ lineHeight: '20px' }}
              >
                {title}
              </TextWithPointer>
              {isMe ? <Inscription color="textDisabled" weight="bold">&nbsp;(you)</Inscription> : null}
              {!isMe && infoTitle ? <Inscription title={hoverTitle} color="textDisabled">&nbsp;({infoTitle})</Inscription> : null}
            </Box>
          ) : null}
          {typeof title === 'object' ? title : null}
          {descriptions?.length ? (
            <ManyItemsTooltip
              items={descriptions}
              isAllMode={isAllMode}
              showMoreLength={3}
            />
          ) : null}
        </Box>
        {InjectedElement ? InjectedElement : null}
      </Box>
    </Box>
  );
};

export default InfoTile;
