import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';

export const QUERY_KEY = 'getSourceIds';

interface RawSource {
  id: number;
  name: string;
  description: string;
}
const useSourceIdsQuery = () => {
  const {
    data,
    isLoading,
    isSuccess,
  } = useQuery(QUERY_KEY, () => api.get<RawSource[]>(PATHS.SOURCE_IDS), {
    retry: false,
  });
  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.name}`,
      value: item.description,
    }));
  }, [data]);
  return ({
    data: formatedData,
    isLoading,
    isSuccess,
  });
};

export default useSourceIdsQuery;
