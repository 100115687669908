import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
// import { useCurrentOrganisationStore } from '@/modules/core/store';
import { CardTypesEnum } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import { OrganizationTypes,ROOT_ORGANIZATION } from '@/common/constants';
import PATHS from '@/common/paths';
import { useCurrentOrganization } from '@/modules/core/hooks';
import {
  PlanFieldState,
  PlanGroupedListResponse,
  PlanListItemResponse,
  PlanStatuses,
  PlanTypesLabel,
} from '@/modules/plan/plan.types';
import { createPathFromIds,getIdsFromPath } from '@/utils/modifiers';

export const getTemplatesList = (
  status?: PlanStatuses,
  orgPath?: string,
  orgId?: string | null,
  name?: string,
) => () => api.get<PlanGroupedListResponse>(PATHS.TEMPLATES, {
  params: _.pickBy({
    organization_id: status !== PlanStatuses.ACTIVE ? orgId : undefined,
    organization_path: status === PlanStatuses.ACTIVE ? orgPath || ROOT_ORGANIZATION : undefined,
    plan_status: status || undefined,
    name,
    size: 1000,
  }),
});

export const TEMPLATES_LIST_QUERY_KEY = 'getTemplatesList';
export const useTemplatesListQuery = (
  id?: string | null,
  status?: PlanStatuses,
  searchValue?: string,
  orgPath?: string,
) => {
  const { observingOrganization } = useCurrentOrganization();

  const isSubsidiary = useMemo(
    () => observingOrganization?.type === OrganizationTypes.subsidiary,
    [observingOrganization],
  );

  const {
    systemId, partnerId, distributorId, employerId,
  } = getIdsFromPath(observingOrganization.path);

  const employerOrganizationPath = createPathFromIds({
    systemId,
    partnerId,
    distributorId,
    employerId,
  });

  const organizationId = useMemo(
    () => (isSubsidiary ? employerId : observingOrganization?.id),
    [isSubsidiary, employerId, observingOrganization],
  );

  const organizationPath = useMemo(
    () => (isSubsidiary ? employerOrganizationPath : observingOrganization?.path),
    [isSubsidiary, employerOrganizationPath, observingOrganization],
  );

  const currentId = useMemo(() => id || organizationId || '0', [id, organizationId]);
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    orgPath ? [TEMPLATES_LIST_QUERY_KEY, status, orgPath] : [TEMPLATES_LIST_QUERY_KEY, status, organizationPath, currentId, searchValue],
    getTemplatesList(status, orgPath || organizationPath, currentId, searchValue),
  );
  
  useEffect(() => {
    if (organizationId && !orgPath) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, orgPath]);

  const formattedTemplates = useMemo(() => {
    const list = data?.data || {};
    return Object.entries(list)?.map(([category, items]) => ({
      categoryEnum: category as CardTypesEnum,
      category: PlanTypesLabel[category as keyof typeof PlanTypesLabel],
      plans: items
        ?.map((item: PlanListItemResponse) => ({
          id: item?.id?.toString(),
          typeLabel: PlanTypesLabel[item?.account_type as keyof typeof PlanTypesLabel],
          type: item.account_type as CardTypesEnum,
          name: item?.name,
          typeState: item.account_type_state as PlanFieldState,
          status: item.plan_status as PlanStatuses,
          planYearId: item.plan_year?.id,
          planYearName: item.plan_year?.name,
          isCarded: item.is_carded,
          isCustomPrefundCalc: item.is_custom_prefund_calc,
          isPlanLevelFunding: item.is_plan_level_funding,
          isReplenished: item.is_replenished,
          summary: item.summary,
        })),
    }))
      .filter((category) => category?.plans?.length > 0)
      .sort((a, b) => a?.category?.localeCompare(b?.category));
  }, [data]);

  const formattedArrayTemplates = useMemo(() => {
    const list = data?.data || {};
    const mergedArray = Object.values(list).flatMap((array) => array);
    return mergedArray.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      typeState: item.account_type_state as PlanFieldState,
      status: item.plan_status as PlanStatuses,
      type: item.account_type as CardTypesEnum,
      planYearId: item.plan_year?.id,
      planYearName: item.plan_year?.name,
    }));
  }, [data]);

  return {
    data: formattedTemplates,
    formattedArrayTemplates,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
export default useTemplatesListQuery;