import React, { useCallback, useMemo } from 'react';
import { AutocompleteDropdown, Box } from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';
import { LOCKBOX_TYPES_OPTIONS, LockboxType, LockboxValidationError, lockboxValidationErrorTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

const LockboxDocumentTypeInput = () => {
  const { editableLockbox, validationErrors, setEditableLockbox } = useLockboxDetailsStore();

  const handleUpdateLockboxType = useCallback((lockboxType: OptionKey) => {
    setEditableLockbox({ lockboxType: lockboxType as LockboxType });
  }, [setEditableLockbox]);

  const errorType = useMemo(() => {
    return validationErrors?.find((error) => error === LockboxValidationError.TYPE_REQUIRED) as LockboxValidationError | undefined;
  }, [validationErrors]);

  return (
    <Box>
      <AutocompleteDropdown
        name="lockboxAmountInput"
        value={editableLockbox?.lockboxType}
        defaultValue={editableLockbox?.lockboxType}
        onChange={handleUpdateLockboxType}
        options={LOCKBOX_TYPES_OPTIONS}
        {...errorType ? { errorText: lockboxValidationErrorTitle?.[errorType] } : {}}
        placeholder="Document Type"
        disableFiltering
        ellipsisMode
        isSmall
        isBold
      />
    </Box>
  );
};

export default LockboxDocumentTypeInput;
