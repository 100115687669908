import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

interface Props {
  label: string;
  text: string;
  children: React.ReactNode;
}
const FormulaSubfield: React.FC<Props> = ({
  label,
  text,
  children,
}) => (
  <Box margin={{ bottom: 'm' }}>
    <Text size="medium" weight={700}>{label}</Text>
    <Text size="medium">{text}</Text>
    {children}
  </Box>
);

export default FormulaSubfield;
