import { toString } from 'lodash';

export interface TeamMemberPayload {
  id?: number;
  contact_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: string;
  icon?: string;
}
export interface TeamMember {
  id?: string;
  fullName?: string;
  email?: string;
  role?: string;
  icon?: string;
}

export interface TeamOrganization {
  id: string;
  name: string;
  children?: TeamOrganization[];
}

export interface TeamPayload {
  id?: number;
  name?: string;
  organization_id?: number;
  assigned_organizations: TeamOrganization[];
  org_path?: string;
  allowed_account_types?: string[];
  is_default?: boolean;
}
export interface Team {
  id?: string;
  name?: string;
  organizationId?: string;
  orgPath?: string;
  allowedAccountTypes?: string[];
  assignedOrganizations?: TeamOrganization[];
  isDefault?: boolean;
}

export interface ClaimsTeamPayload extends TeamPayload {
  assigned_contacts?: TeamMemberPayload[];
}

export interface ClaimsTeam extends Team {
  teamMembers?: TeamMember[];
}

export interface ClaimsProcessorPayload extends TeamMemberPayload {
  claims_teams?: TeamPayload[];
}

export interface ClaimsProcessor extends TeamMember {
  teams?: Team[];
}

export const formatTeamPayload = (item: ClaimsTeamPayload): ClaimsTeam => ({
  id: toString(item?.id),
  name: item?.name,
  organizationId: toString(item?.organization_id),
  orgPath: item?.org_path,
  allowedAccountTypes: item?.allowed_account_types,
  isDefault: item?.is_default,
  teamMembers: item?.assigned_contacts?.map((member) => ({
    id: toString(member?.contact_id),
    icon: member?.icon,
    fullName: `${member?.first_name} ${member?.last_name}`.trim(),
    role: member?.role,
  })),
  assignedOrganizations: item?.assigned_organizations?.map((org) => ({
    id: toString(org?.id),
    name: org?.name,
    children: org?.children,
  })),
});

export const formatClaimsProcessorPayload = (item: ClaimsProcessorPayload): ClaimsProcessor => ({
  id: toString(item?.id),
  fullName: `${item?.first_name} ${item?.last_name}`.trim(),
  email: item?.email,
  role: item?.role,
  icon: item?.icon,
  teams: item?.claims_teams?.map((team) => ({
    id: toString(team?.id),
    name: team?.name,
    allowedAccountTypes: team?.allowed_account_types,
  })),
});

export interface ClaimsTeamConfig {
  name: string;
  allowedAccountTypes: string[];
  assignedOrganizationIds?: number[];
}

export interface ClaimsTeamConfigPayload {
  name: string;
  allowed_account_types: string[];
  assigned_organizations_ids?: number[];
}
