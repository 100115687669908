import styled from 'styled-components';

interface WrapperProps {
  whiteMode?: boolean;
}
export const Wrapper = styled.span<WrapperProps>`
  ${({ theme }) => theme.fonts.text14Bold}
  background-color: ${(props) => (props.whiteMode ? props.theme.colors.canvas : '')};
  color: ${(props) => (props.whiteMode ? props.theme.colors.textAccent : '')};
  padding: 0 6px;
  border-radius: 50%;
`;
export default Wrapper;
