import React, { useCallback,useEffect } from 'react';
import {
  Box,
  FlexControlledForm,
  Text,
  useDebounceState,
  WarningLabel,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { PlanYearFormPayload,useStore } from './stores/usePlanYearForm.store';
import { usePlanYearFormFields } from './hooks';

interface Props {
  disabled?: boolean;
  isViewMode?: boolean;
  isModalType?: boolean;
  defaultValues?:  Partial<PlanYearFormPayload>;
  style?: React.CSSProperties;
  hideName?: boolean;
  isCreateMode?: boolean;
  isEditMode?: boolean;
  testId?: string;
  wrapperStyle?: React.CSSProperties;
  setActiveValues?: (value: boolean) => void;
  isEndDateFieldDisabled?: boolean;
}
// The component is here because the component is used in Employer/Partner Setup also.
const PlanYearForm: React.FC<Props> = ({
  disabled,
  defaultValues,
  style,
  hideName,
  isViewMode,
  isModalType,
  isCreateMode,
  testId = '',
  isEditMode,
  wrapperStyle,
  setActiveValues,
  isEndDateFieldDisabled,
}) => {
  const fields = usePlanYearFormFields(
    defaultValues,
    disabled,
    hideName,
    isCreateMode,
    isEditMode,
    isEndDateFieldDisabled,
  );
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const { setValues, values } = useDebounceState<Partial<PlanYearFormPayload>>();

  const handleChangeValues = useCallback((values: object) => {
    setValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      if (setActiveValues) setActiveValues(true);
    } catch {
      if (setActiveValues) setActiveValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const clearedValues = _.pickBy(values, _.identity);

    if (values && !_.isEmpty(clearedValues)) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box>
      <FlexControlledForm
        key={`${values}`}
        fields={fields}
        testId={`${testId}_plan-year_form`}
        // showFieldsError={true}
        formStyle={style}
        showError={showErrors}
        editMode={!isViewMode}
        isModalType={isModalType}
        onChangeValues={handleChangeValues}
        isThinMode
        wrapperStyle={wrapperStyle}
      >
        {
          isCreateMode ? (
            <Box
              pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
              background={{ color: 'warningContainer' }}
              border={{ size: '1px', color: 'warningBorder' }}
              round="fieldRound"
            >
              <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
                <WarningLabel
                  data-testId="CreatePlan_warning_message"
                >
                  Prior plan years list depends on the plan start date.
                </WarningLabel>
              </Text>
              <Box>
                <Text
                  data-testId="CreatePlan_confirm_message"
                  color="textBody"
                  size="14px"
                >
                  Each time you update the date,
                  a new list of previous plan years will be available.
                </Text>
              </Box>
            </Box>
          ) : null
        }
        {
          isEditMode ? (
            <Box
              pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
              background={{ color: 'warningContainer' }}
              border={{ size: '1px', color: 'warningBorder' }}
              round="fieldRound"
            >
              <Text weight="bold" color="textBody">
                <WarningLabel
                  data-testId="CreatePlan_warning_message"
                >
                  All coverage periods will be recaluclated with new dates
                </WarningLabel>
              </Text>
            </Box>
          ) : null
        }
      </FlexControlledForm>
    </Box>
  );
};
export default PlanYearForm;
