import React, { useMemo } from 'react';
import { AppButton, Box, ButtonDropdown } from '@common-fe/common-fe';
import _ from 'lodash';

import {
  useHandleEmployerSetUp,
  useSaveValidate,
} from '@/modules/employer/components/SetupEmployer/FinalSetUp/hooks';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

export const TopbarEmployerButtons = () => {
  const {
    mode, toEdit, editMode, viewMode,
  } = useSetupEmployerMode();
  const {
    handleSavePending,
    handleActivate,
    handleDiscard,
  } = useHandleEmployerSetUp();

  const {
    formatedPendingErrors,
    formatedActivateErrors,
  } = useSaveValidate();
  const { formattedData } = useGetEmployer();
  const status = formattedData.organizationStatusType;
  const { pendingSubmitted, activateSubmitted } = useSaveTypeStore();

  const pendingDisabled = useMemo(() => {
    if (viewMode) {
      return true;
    }
    if (pendingSubmitted) {
      return !_.isEmpty(formatedPendingErrors);
    }
    return false;
  }, [formatedPendingErrors, pendingSubmitted, viewMode]);

  const activateDisabled = useMemo(() => {
    if (viewMode) {
      return true;
    }
    if (activateSubmitted) {
      return !_.isEmpty(formatedActivateErrors);
    }
    return false;
  }, [activateSubmitted, formatedActivateErrors, viewMode]);

  const currentEmployerMode = useMemo(() => {
    const isTerminated = formattedData?.isTerminated;
    const isTerminationInProgress = formattedData.organizationStatusType === EmployerStatus.TerminationInProgress;

    if (viewMode) {
      return (
        <AppButton
          testId="edit"
          buttonType="secondary"
          onClick={toEdit}
          disabled={isTerminated || isTerminationInProgress}
        >
          {`Edit ${_.capitalize(mode)}`}
        </AppButton>
      );
    }

    if (editMode) {
      return (
        <Box data-testId="save-employer-actions" direction="row">
          {status === EmployerStatus.Active || formattedData.isActivationStarted ? (
            <>
              <AppButton
                testId="discard"
                onClick={handleDiscard}
                disabled={activateDisabled || isTerminated}
                color="red"
                width="100px"
                containerStyle={{ marginRight: '12px' }}
              >
                Discard
              </AppButton>

              <AppButton
                onClick={handleActivate}
                disabled={activateDisabled || isTerminated}
                width="100px"
              >
                Save
              </AppButton>
            </>
          )
            : (
              <ButtonDropdown
                testId="save_activate_organization"
                label="Save/Activate"
                disabled={isTerminated || (pendingDisabled && activateDisabled)}
                options={[
                  {
                    title: 'Save',
                    redirectFunc: handleSavePending,
                    disabled: pendingDisabled || isTerminated,
                  },
                  {
                    title: 'Activate',
                    redirectFunc: handleActivate,
                    disabled: activateDisabled || isTerminated,
                  },
                ]}
              />
            )}
        </Box>
      );
    } return null;
  }, [
    formattedData.isTerminated,
    formattedData.isActivationStarted,
    viewMode,
    editMode,
    toEdit,
    mode,
    status,
    handleDiscard,
    activateDisabled,
    handleActivate,
    handleSavePending,
    pendingDisabled,
    formattedData.organizationStatusType,
  ]);

  return currentEmployerMode;
};
