import React from 'react';
import {
  Box,
  Circle,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { EMPTY_FIELD_VALUE, MINIMIZE_DATE_FORMAT, TIME_FORMAT } from '@/common/constants';
import { getAvatar, HIDDEN_UPLOADING_WAY } from '@/modules/FileManager/hooks/useFilesList';

import { StyledIcon } from '../FileSummary.styles';
import { FileDetails } from '../queries/useGetFileDetails.query';


const getDateTime = (date?: string) => {
  if (!date) return EMPTY_FIELD_VALUE;

  return (
    <Box direction="row" align="center">
      <Inscription color="textBody" lineHeight="20px">{dayjs(date).format(MINIMIZE_DATE_FORMAT)}</Inscription>
      <Box width="4px" height="4px" round background="border" margin={{ horizontal: 'spacing8' }} />
      <Inscription color="textBody" lineHeight="20px">{dayjs(date).format(TIME_FORMAT)}</Inscription>
    </Box>
  );
};

const useGetInformationFields = (fileDetails?: FileDetails) => {
  if (!fileDetails) return [];

  return [
    {
      name: 'fileName',
      label: 'File name',
      type: FieldTypes.Node,
      value: (
        <Inscription lineHeight="20px" color="textBody">
          {fileDetails.fileName || EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },
    {
      name: 'recordType',
      label: 'Record type',
      type: FieldTypes.Node,
      value: (
        <Box direction="row" align="center">
          {fileDetails.fileType && (
            <StyledIcon margin={{ right: '10px' }}>{getAvatar(fileDetails.fileType)}</StyledIcon>
          )}
          <Inscription lineHeight="20px" color="textBody">
            {fileDetails.fileType || EMPTY_FIELD_VALUE}
          </Inscription>
        </Box>
      ),
    },
    {
      name: 'partner',
      label: 'Partner',
      type: FieldTypes.Node,
      value: (
        <Inscription lineHeight="20px" color="textBody">
          {fileDetails.partners.join(', ') || EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },
    {
      name: 'employer',
      label: 'Employer',
      type: FieldTypes.Node,
      value: (
        <Inscription lineHeight="20px" color="textBody">
          {fileDetails.employers.join(', ') || EMPTY_FIELD_VALUE}
        </Inscription>
      ),
    },
    {
      name: 'DateReceived',
      label: 'Date/Time uploaded',
      type: FieldTypes.Node,
      value: getDateTime(fileDetails.createdAt),
    },
    {
      name: 'uploadedBy',
      label: 'Uploaded by',
      type: FieldTypes.Node,
      value: (
        <Box direction="row" align="center">
          <Inscription lineHeight="20px" color="textBody">
            {fileDetails.uploadedBy || EMPTY_FIELD_VALUE}
          </Inscription>
          {fileDetails.uploadWay !== HIDDEN_UPLOADING_WAY && (
            <Box background="border1" round="4px" pad="3px" margin={{ left: 'spacing4' }}>
              <Inscription size="10px" lineHeight="18px" weight={500} color="textBody">{fileDetails.uploadWay || EMPTY_FIELD_VALUE}</Inscription>
            </Box>
          )}
        </Box>
      ),
    },
    {
      name: 'dateProcessed',
      label: 'Date/Time processed',
      type: FieldTypes.Node,
      value: getDateTime(fileDetails.processedAt || EMPTY_FIELD_VALUE)
    },
    {
      name: 'processedBy',
      label: 'Processed By',
      type: FieldTypes.Node,
      value: (
        <Box direction="row" align="center">
          <Inscription lineHeight="20px" color="textBody">
            {fileDetails.processedBy || EMPTY_FIELD_VALUE}
          </Inscription>
        </Box>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldTypes.Node,
      value: (
        <Box direction="row" align="center">
          <Circle status={fileDetails.fileProcessingStatusType} margin={{ right: 'spacing8' }} />
          <Inscription lineHeight="20px" color="textBody">
            {capitalize(fileDetails.fileProcessingStatusType) || EMPTY_FIELD_VALUE}
          </Inscription>
        </Box>
      ),
    },
  ] as Field[];
};

export default useGetInformationFields;
