import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { PermissionKey } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { useCommunicationChannelStore } from '@/modules/employer/components/SetupEmployer/Communication/stores';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

export const COMMUNICATION_CHANNEL = [
  { value: 'Notification center', hintText: 'Alerts within the portal', key: 'NOTIFICATION_CENTER' },
  { value: 'Email/Text message', hintText: 'Communications sent through participant\'s preferred method', key: 'EMAIL_SMS' },
  { value: 'Data export', hintText: 'Extraction of communication information in a file or API format​', key: 'DATA_EXPORT' },
];

// const VALIDATOR = yup.string().nullable();

export default (data?: EmployerDto, isLoading?: boolean) => {
  const defaultFields = useCommunicationChannelStore((state) => state.state);
  const communicationChannelsPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.COMMUNICATION_CHANNELS,
  );
  // const emailTemplateTypePermProps = useGetPermissionToEditOverridableField(
  //   PermissionKey.EMAIL_TEMPLATE_TYPE,
  // );
  const fields = useMemo(() => {
    if (isLoading) return [];

    if (communicationChannelsPermProps?.type === FieldTypes.Node) {
      return [
        {
          name: 'communicationChannel',
          type: FieldTypes.Node,
          label: 'Communication channels',
          ...communicationChannelsPermProps,
        },
      ];
    }

    return [
      {
        name: 'communicationChannel',
        type: FieldTypes.CheckBoxGroup,
        label: 'Communication channels',
        value: data?.overridableProperties?.communicationChannels
          || defaultFields?.communicationChannel || [],
        options: COMMUNICATION_CHANNEL,
        validator: yup.boolean(),
        ...communicationChannelsPermProps,
      },
      // defaultFields.communicationChannel?.includes('EMAIL_SMS') ? {
      //   name: 'emailTemplate',
      //   type: FieldTypes.Radio,
      //   label: 'Email Templates',
      //   value: data?.overridableProperties?.emailTemplate || '',
      //   options: [
      //     {
      //       label: 'Elevate\'s standard participant email communications',
      //       value: 'STANDARD',
      //     },
      //     {
      //       label: 'Custom templates',
      //       value: 'CUSTOM',
      //       isWrappedMode: true,
      //       hintText: 'Coordination is required to use Custom templates. Please reach out to your admin contact for set up',
      //     },
      //   ],
      //   validator: VALIDATOR,
      //   ...emailTemplateTypePermProps,
      // } : {
      //   name: 'empty',
      //   type: FieldTypes.Node,
      //   ...emailTemplateTypePermProps,
      // },
    ] as Field[];
  }, [
    defaultFields,
    data,
    isLoading,
    communicationChannelsPermProps,
    // emailTemplateTypePermProps,
  ]);

  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultValues;
      return fields.map((item) => ({
        ...item,
        value: item.value || defaultValues[item.name] || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
