import React, { useMemo } from 'react';

import { HealthPlanStatuses } from '../../HealthPlan.types';

import HealthPlanTemplateActivation from './HealthPlanTemplateActivation';
import HealthPlanTopbarActions from './HealthPlanTopbarActions';
import HealthPlanTopbarEditModeButton from './HealthPlanTopbarEditModeButton';
import TopbarWrapper from './TopbarWrapper';

interface Props {
  planStatus?: HealthPlanStatuses;
  viewMode?: boolean;
  isTemplateMode?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  activateButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  reviewButtonRef?:React.RefObject<HTMLButtonElement | undefined>;
}
const HealthPlanTopbar: React.FC<Props> = ({
  planStatus, viewMode, isTemplateMode, saveButtonRef,
  reviewButtonRef, activateButtonRef,
}) => {
  const node = useMemo(() => {
    if (planStatus !== HealthPlanStatuses.ACTIVE
      && planStatus !== HealthPlanStatuses.PENDING_ACTIVE) {
      if (viewMode) {
        return (
          <HealthPlanTopbarEditModeButton isDraftMode={planStatus === HealthPlanStatuses.DRAFT} />
        );
      }

      return (
        <HealthPlanTopbarActions
          saveButtonRef={saveButtonRef}
          activateButtonRef={activateButtonRef}
          reviewButtonRef={reviewButtonRef}
          viewMode={viewMode}
          isTemplateMode={isTemplateMode}
        />
      );
    }
    if (isTemplateMode) {
      return (<HealthPlanTemplateActivation />);
    }
    return null;
  }, [activateButtonRef, isTemplateMode, planStatus, reviewButtonRef, saveButtonRef, viewMode]);
  return (
    <TopbarWrapper>
      {node}
    </TopbarWrapper>
  );
};
export default HealthPlanTopbar;
