import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

export const QUERY_KEY = 'GET_EMAIL_THEMES';

interface ThemePayload {
  id: number;
  name: string;
  subject: string;
  text: string;
  active_link: string;
  button_background: string;
  button_text: string;
  code_text: string;
  code_background: string;
}

export interface Theme {
  id: string;
  name: string;
  subject: string;
  text: string;
  activeLink: string;
  buttonBackground: string;
  buttonText: string;
  codeText: string;
  codeBackground: string;
}

export default (orgPath?: string) => {
  const { formattedData: formattedEmployer } = useGetEmployer();
  const { data, isLoading, isSuccess } = useQuery([QUERY_KEY], () => api.get<ThemePayload[]>(
    PATHS.EMAIL_THEMES, {
      params: {
        organization_path: orgPath || formattedEmployer.organizationPath,
      },
    },
  ), {
    enabled: !!(orgPath || formattedEmployer?.organizationPath),
  });

  const formatted = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      title: item.name,
      color: item.button_background ? `${item.button_background}` : '',
    }));
  }, [data]);

  const formattedThemes = useMemo<Array<Theme>>(() => {
    const themes = data?.data || [];

    return themes.map((theme: ThemePayload) => ({
      id: `${theme?.id}`,
      name: theme?.name,
      activeLink: theme?.active_link,
      buttonBackground: theme?.button_background,
      buttonText: theme?.button_text,
      codeBackground: theme?.code_background,
      codeText: theme?.code_text,
      subject: theme?.subject,
      text: theme?.text,
    }));
  }, [data]);

  return {
    formattedThemes,
    data: formatted,
    isLoading,
    isSuccess,
  };
};
