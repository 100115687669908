import { baseStoreCreator } from '@/utils/store';

export interface LinkManagementPayload {
  domainAddress?: string;
  privacyPolicyLink?: string;
  termAndConditionsLink?: string;
  aboutUsLink?: string;
  pricingConsent?: string;
}

const DEFAULT_VALUES: LinkManagementPayload = {
  domainAddress: '',
  privacyPolicyLink: '',
  termAndConditionsLink: '',
  aboutUsLink: '',
  pricingConsent: '',
};

const useLinkManagementStore = baseStoreCreator<LinkManagementPayload>(
  DEFAULT_VALUES,
);

export default useLinkManagementStore;
