import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, Text } from '@common-fe/common-fe';

import DragAndDropItem from './DragAndDropItem';
import useOrderDragAndDrop from './useOrderDragAndDrop';

interface Props {
  defaultValue?: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
}
export const YEARS_OPTIONS = ['YYYY', 'YY'];
export const FORMULA_ALL_VALUES = ['DD', 'MM', ...YEARS_OPTIONS];

export const OPEN_SYMBOL = '{';
export const CLOSE_SYMBOL = '}';
const FundFormulaExpression: React.FC<Props> = ({ onChange, defaultValue, disabled }) => {
  const [list, setList] = useState<string[][]>([[
    FORMULA_ALL_VALUES[0], FORMULA_ALL_VALUES[1], YEARS_OPTIONS[0],
  ],
  defaultValue || [

  ]]);

  useEffect(() => {
    onChange(list[1]);
  }, [list, onChange]);

  const handleDeleteItem = useCallback((index: number) => {
    const newList = list[1].filter((item, currentIndex) => currentIndex !== index);
    setList([list[0], newList]);
  }, [list]);

  const formatedSourceList = useMemo(() => {
    const sourceList = list[0];
    const targetList = list[1];

    return sourceList.filter((item) => !targetList.includes(item));
  }, [list]);
  const {
    handleDragStart,
    handleDrop,
    handleDragEnter,
  } = useOrderDragAndDrop(
    list,
    setList,
  );

  const handleChangeOption = useCallback((newOptionValue: string) => {
    const isYearOption = YEARS_OPTIONS.includes(newOptionValue);

    if (isYearOption) {
      const newList = list.map((sublist) => sublist.map((item) => {
        if (YEARS_OPTIONS.includes(item)) {
          return newOptionValue;
        }
        return item;
      }));
      setList(newList);
    }
  }, [list]);

  const sourceItemList = useMemo(
    () => list[0] && list[0].map((item, index) => {
      const currentDisabled = !formatedSourceList.includes(item);
      let options: string[] = [];
      if (YEARS_OPTIONS.includes(item)) {
        options = YEARS_OPTIONS;
      }
      return (
        <Box
          draggable={!currentDisabled && !disabled}
          key={`${0}-${index}`}
          onDragStart={() => handleDragStart(index)}
          onDragEnter={() => handleDragEnter(index)}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          margin={{ right: 'xs' }}
        >
          <DragAndDropItem
            disabled={currentDisabled || disabled}
            options={options}
            onChangeOption={handleChangeOption}
          >
            {item}
          </DragAndDropItem>

        </Box>
      );
    }),
    [disabled, formatedSourceList, handleChangeOption,
      handleDragEnter, handleDragStart, handleDrop, list],
  );

  const targetItemsList = useMemo(() => list[1] && list[1].map((item, index) => {
    let options: string[] = [];
    if (YEARS_OPTIONS.includes(item)) {
      options = YEARS_OPTIONS;
    }
    return (
      <Box
        draggable={!disabled}
        key={`${1}-${index}`}
        onDragStart={() => handleDragStart(index, 1)}
        onDragEnter={() => handleDragEnter(index, 1)}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        margin={{ right: 'xs' }}
      >
        <DragAndDropItem
          onDelete={() => {
            handleDeleteItem(index);
          }}
          disabled={disabled}
          options={options}
          onChangeOption={handleChangeOption}
        >
          {item}
        </DragAndDropItem>
      </Box>
    );
  }), [disabled, handleChangeOption, handleDeleteItem,
    handleDragEnter, handleDragStart, handleDrop, list]);
  return (
    <Box>
      <Box direction="row">

        {sourceItemList}

      </Box>
      <Box
        margin={{ top: 's', right: 'xxl' }}
        direction="row"
        height="48px"
        align="center"
        round="xs"
        pad={{ horizontal: 'm' }}
        onDragEnter={() => handleDragEnter(undefined, 1)}
        border={{ size: '1px', color: 'border1' }}
      >
        <Text
          margin={{ right: 'xs' }}
          color="textDisabled"
        >
          {OPEN_SYMBOL}
        </Text>
        {
          targetItemsList

        }

        <Text margin={{ left: 'auto' }} color="textDisabled">
          {CLOSE_SYMBOL}
        </Text>

      </Box>
    </Box>
  );
};

export default FundFormulaExpression;
