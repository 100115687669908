import { HealthPlanFieldState } from '@/modules/HealthPlan/HealthPlan.types';

import { baseStoreCreator } from './useCoverageLevels.store';

export interface CoverageLevelsFieldsStatePayload {
  id?: string;
  coverageId: string;
  coverageCode?: HealthPlanFieldState;
  planDeductible?: HealthPlanFieldState;
  memberDeductible?: HealthPlanFieldState;
  embeddedAmount?: HealthPlanFieldState;
}

export const DEFAULT_VALUES: CoverageLevelsFieldsStatePayload = {
  id: '',
  coverageId: '',
  coverageCode: HealthPlanFieldState.Modifiable,
  planDeductible: HealthPlanFieldState.Modifiable,
  memberDeductible: HealthPlanFieldState.Modifiable,
  embeddedAmount: HealthPlanFieldState.Modifiable,

};

export const useStore = baseStoreCreator<CoverageLevelsFieldsStatePayload[]>([]);

export default useStore;
