import { useEffect } from 'react';

import { useGetInvestorsQuery } from '../queries';
import { useEmployeeEnrollmentsStore } from '../stores/useEmployeeEnrollments.store';

// import { useEnrollmentsStatisticsQuery } from '../queries/useEnrollmentStatistics.query';
import { useGetEnrollments } from './useGetEnrollment';

export const useEnrollmentsData = (employeeId: string, status?: string | null) => {
  const {
    data: enrollments,
    isLoading,
    hasEnrollmentWithDisplayName,
    // isLoading: enrollmentsLoading,
  } = useGetEnrollments({
    employeeId,
    status,
  });
  const { formattedData: investorsData } = useGetInvestorsQuery(employeeId);
  const setEnrollments = useEmployeeEnrollmentsStore((state) => state.handleSetEnrollments);
  const setIsLoading = useEmployeeEnrollmentsStore((state) => state.handleSetIsLoading);
  const resetEnrollments = useEmployeeEnrollmentsStore((state) => state.handleReset);

  // const {
  //   data: statistic,
  //   isLoading: statisticsLoading,
  // } = useEnrollmentsStatisticsQuery(employeeId);

  // const isLoading = useMemo(() => enrollmentsLoading,
  //   [enrollmentsLoading]);

  // const formattedStatistic = useMemo(() => {
  //   const totalExpenses = Math.abs(statistic?.total_amount || 0);

  //   const expenses = statistic?.plan_percentage
  //     ? statistic.plan_percentage.map((plan) => ({
  //       key: plan.account_type || '',
  //       title: plan.plan_name || '',
  //       value: Number(plan.percentage),
  //     })) : [];

  //   return {
  //     totalExpenses,
  //     expenses,
  //   };
  // }, [statistic]);

  useEffect(() => {
    setEnrollments(enrollments);
    setIsLoading(isLoading);
  }, [enrollments, setEnrollments, isLoading, setIsLoading]);

  useEffect(() => () => {
    resetEnrollments();
  }, [resetEnrollments]);

  return {
    enrollments,
    investorsData,
    hasEnrollmentWithDisplayName,
    isLoading,
  };
};
