import { useMemo } from 'react';

import { useCoverageLevelsFieldsState } from '../stores';

export default () => {
  const coverageLevels = useCoverageLevelsFieldsState((state) => state.sourceState);
  const state = useMemo(() => ({
    coverageLevels,
  }), [coverageLevels]);
  return state;
};
