import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  FlexList,
  PaginationBar,
  SearchInput,
  Text,
} from '@common-fe/common-fe';

import { ListItemBase } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { useListPagination } from '@/modules/employer/hooks';
import { useActivityTransactionList } from '@/modules/employer/queries/useAccountsTransactions.query';

const HEADERS: ListItemBase[] = [
  {
    key: 'elevate_memo',
    title: 'Elevate Memo',
    sticky: true,
  },
  {
    key: 'partner_memo',
    title: 'Partner Memo',
  },
  {
    key: 'account',
    title: 'Account',
  },
  {
    key: 'subAccount',
    title: 'Subaccount',
  },
  {
    key: 'otherAccount',
    title: 'Other Account',
  },
  // {
  //   key: 'otherSubaccount',
  //   title: 'Other Subaccount',
  // },
  {
    key: 'date',
    title: 'Date',
  },
  {
    key: 'transactionType',
    title: 'Transaction Type',
  },
  {
    key: 'ourAmount',
    title: 'Our Amount',
  },
  {
    key: 'bankAmount',
    title: 'Bank Amount',
  },
  {
    key: 'difference',
    title: 'Difference',
  },
];

export const CloseDateTransactionsList = () => {
  const { id } = useParams<{ id: string }>();
  const { queryMap } = useHistory();
  const closeDate = useMemo(
    () => queryMap.transactionsCloseDate,
    [queryMap],
  );
  const {
    page,
    perPage,
    searchString,
    setSearchString,
    setPage,
    setPerPage,
  } = useListPagination();

  const { formattedList, accountInfo, total } = useActivityTransactionList(
    id,
    {
      closeDate,
      page: page - 1,
      perPage,
    }, false,
  );

  if (!accountInfo.length) return null;
  return (
    <Box
      data-testid="ReconciliationListOfBankAccounts"
      background={{ color: 'module' }}
      round="container1Round"
    >
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ bottom: 'spacing12', top: 'spacing24', horizontal: 'spacing24' }}
      >
        <Text
          size="large"
          weight="bold"
          color="textBody"
        >
          Close Date Transactions
        </Text>
        <Box direction="row" justify="end">
          <Box margin={{ left: 'spacing8' }} width={{ min: '180px', max: '180px' }}>
            <SearchInput
              className="search-input"
              value={searchString}
              hasSearchingHistory
              placeholder="Search"
              onChange={setSearchString}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <FlexList
            headers={HEADERS}
            rows={formattedList}
            total={formattedList.length}
            footer={(
              <PaginationBar
                page={page}
                total={total}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
