import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton,
  Box, FlexControlledForm, Preloader, } from '@common-fe/common-fe';
import * as yup from 'yup';

import { StyledContent } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/ContactsSecurity.styles';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { useBankAccountCategoriesQuery } from '@/modules/employer/hooks';
import theme from '@/styles/theme';

import useBankInformationQuery from '../useBankInformation.query';

import useBankInformationFields, { BankInformationFields } from './useBankInformationFields';

interface Props {
  defaultValues?: BankInfo;
  onSubmit?: (value: BankInformationFields) => Promise<void>;
  editMode?: boolean;
  onSetEditMode?: (value: boolean) => void;
  onCancel?: () => void;
  viewMode?: boolean;
  loading?: boolean;
  onDirty: (isDirty: boolean) => void;
}

const BankInformationForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  defaultValues,
  viewMode,
  loading,
  onSetEditMode,
  editMode,
  onDirty,
}) => {
  const { isLoading, data: formatedBankAccountCategories } = useBankAccountCategoriesQuery();
  const { setBankAccountCategories } = useBankInformationQuery();
  const [values, setValues] = useState<BankInformationFields>();
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const fields = useBankInformationFields(defaultValues ? {
    ...defaultValues,
    employerBankingContactType: defaultValues?.contactId ? 'exist' : 'new',
  } : undefined);
  const handleSubmit = useCallback(async () => {
    setShowError(true);
    setSubmitting(true);
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      if (onSubmit && values) await onSubmit(values);
    } catch {
      setSubmitting(false);
    }
  }, [values, onSubmit, fields]);

  const handleCancel = useCallback(() => {
    if (editMode) {
      if (onSetEditMode) {
        onSetEditMode(false);
      }
      return;
    }
    if (onCancel) {
      onCancel();
    }
  }, [editMode, onCancel, onSetEditMode]);
  const handleShowEditMode = useCallback(() => {
    if (onSetEditMode) {
      onSetEditMode(true);
    }
  }, [onSetEditMode]);

  useEffect(() => {
    if (!isLoading) {
      setBankAccountCategories(formatedBankAccountCategories);
    }
  }, [isLoading, formatedBankAccountCategories, setBankAccountCategories]);

  return (
    <>
      <Box background="module" round="container1Round" pad="medium" overflow="hidden" data-testid="BankInformationForm-form-wrapper">
        <StyledContent
          background="canvas"

        >
          {isLoading ? <Box height="small" align="center" justify="center"><Preloader /></Box> : (
            <FlexControlledForm
              fields={fields}
              onDirty={onDirty}
              editMode={!viewMode || editMode}
              onChangeValues={setValues}
              showError={showError}
              isThinMode
            />
          )}
        </StyledContent>
      </Box>
      <div>
        <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }}>
          {(!viewMode || editMode) && (
            <>
              <AppButton
                testId="cancel"
                buttonType="secondary"
                onClick={handleCancel}
                containerStyle={{ marginRight: theme.spacings.spacing12 }}
              >
                Cancel
              </AppButton>

              <AppButton
                testId="submit"
                disabled={loading || submitting}
                onClick={handleSubmit}
              >
                {(loading || submitting) ? <Preloader color="white" /> : 'Save Account'}
              </AppButton>
            </>
          )}
          {(viewMode && !editMode) && (
            <AppButton
              testId="submit"
              disabled={loading || submitting}
              onClick={handleShowEditMode}
            >
              Edit
            </AppButton>
          )}
        </Box>
      </div>
    </>
  );
};

export default BankInformationForm;
