import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { ExpressSetupDtoResponse } from '../ExpressSetup.types';

interface Data {
  dto: Partial<ExpressSetupDtoResponse>;
  successCallback?: () => void
}

export const useUpdateExpressSetupByIdQuery = (id: string, errorCallback?: (error: unknown) => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ dto, successCallback }: Data) => api
      .patch<ExpressSetupDtoResponse>(PATHS.EXPRESS_SETUP_BY_ID(id), dto)
      .then((data) => {
        if (successCallback) {
          successCallback();
        }
        queryClient.invalidateQueries(PATHS.ACTIVITY_LOG);
        return data;
      }),
    {
      onError: (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      },

    },
  );
  return mutation;
};

export default useUpdateExpressSetupByIdQuery;
