import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  Box, CIPStatus,
  EnrollmentStatus, NullEnrollmentsIcon, Preloader,
  SelectDropdown, Text, } from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';

import { useEmployeeToolbarStore } from '../EmployeeTopbar/stores';
import { useEnrollmentsData } from '../hooks';

import { EnrollmentsList,OPTIONS, Props } from './EnrollmentBalances';
import { EnrollmentBalancesHeader } from './EnrollmentBalancesHeader';

export const EnrollmentBalances: React.FC<Props> = ({
  children,
  employeeName,
}) => {
  const [filterValues, setFilterValues] = useState<OptionKey[]>([EnrollmentStatus.Active]);
  const handleSetFilterValues = useCallback((values: OptionKey[]) => {
    if (filterValues.includes(EnrollmentStatus.Null) && values.length > 1) {
      const filtered = values.filter((item) => item !== EnrollmentStatus.Null);
      setFilterValues(filtered);
    } else if ((values.includes(EnrollmentStatus.Null)
      && !filterValues.includes(EnrollmentStatus.Null)) || !values.length) {
      setFilterValues([EnrollmentStatus.Null]);
    } else {
      setFilterValues(values);
    }
  }, [filterValues]);

  const isAllPicked = useMemo(
    () => filterValues.length === 0 || OPTIONS.length - 1 === filterValues.length
      || filterValues.includes(EnrollmentStatus.Null),
    [filterValues]
  );

  const firstName = useEmployeeToolbarStore((state) => state.sourceState?.firstName);
  const { params: { id: employeeId } } = useRouteMatch<{ id: string; }>();

  const {
    enrollments = [], investorsData, isLoading, hasEnrollmentWithDisplayName,
  } = useEnrollmentsData(employeeId);
  const canceledList = useMemo(() => {
    if (!filterValues.includes(EnrollmentStatus.Canceled) && !isAllPicked) {
      return [];
    }
    const filteredList = enrollments.filter(
      (item) => item.enrollmentStatus === EnrollmentStatus.Canceled
    );
    if (isAllPicked) {
      return filteredList;
    }

    return filteredList;
  }, [enrollments, filterValues, isAllPicked]);
  const activeList = useMemo(() => {
    if (!filterValues.includes(EnrollmentStatus.Active) && !isAllPicked) {
      return [];
    }
    const filteredList = enrollments.filter(
      (item) => item.enrollmentStatus === EnrollmentStatus.Active
        || (
          (item?.CIPStatus === CIPStatus.Question || item?.CIPStatus === CIPStatus.Fail)
           && item.isCIPRequired 
           && item.enrollmentStatus !== EnrollmentStatus.Canceled)
    );
    if (isAllPicked) {
      return filteredList;
    }

    return filteredList;
  }, [enrollments, filterValues, isAllPicked]);

  const isEmpty = useMemo(() => enrollments.length === 0, [enrollments.length]);
  const content = useMemo(() => {
    // if (isEmpty) {
    //   return (
    //     <Box data-testId="empty-state" align="center" justify="center" margin={{ top: 'l' }}>
    //       <NullEnrollmentsIcon color="iconSecondary" size="72px" />
    //       <Text size="medium" weight={700} margin={{ top: 'l' }}>
    //         There are no Enrollments yet
    //       </Text>
    //       <Text size="medium" margin={{ top: 's' }}>
    //         Enrollments will be added soon
    //       </Text>
    //     </Box>
    //   );
    // }
    if (activeList.length === 0 && canceledList.length === 0) {
      return (
        <Box data-testId="filter-empty-state" align="center" justify="center" margin={{ top: 'l' }}>
          <NullEnrollmentsIcon color="iconSecondary" size="72px" />

          <Text size="medium" weight={700} margin={{ top: 'l' }}>
            No enrollments were found by selected filter
          </Text>

        </Box>
      );
    }

    // This section will never be displayed
    if (isLoading) {
      return (
        <Box align="center" justify="center">
          <Preloader color="button" />
        </Box>
      );
    }
    return (
      <>
        {!!activeList.length && (
          <EnrollmentsList
            employeeId={employeeId}
            status={EnrollmentStatus.Active}
            title={canceledList.length ? 'Active' : ''}
            enrollments={activeList}
            investorsData={investorsData}
            employeeName={employeeName}
            hasEnrollmentWithDisplayName={hasEnrollmentWithDisplayName}
          />
        )}

        {!!canceledList.length && (
          <EnrollmentsList
            employeeId={employeeId}
            status={EnrollmentStatus.Canceled}
            title="Cancel"
            enrollments={canceledList}
            investorsData={investorsData}
            employeeName={employeeName}
            hasEnrollmentWithDisplayName={hasEnrollmentWithDisplayName}
          />
        )}
      </>
    );
  }, [activeList, canceledList, employeeId, isLoading, investorsData, employeeName, hasEnrollmentWithDisplayName]);
  return (
    <Box>
      <EnrollmentBalancesHeader />
      <Box
        direction="column"
        background="module"
        pad="spacing24"
        round="container1Round"
      >{isLoading
          ? (
            <Preloader testId="loading-spinner" />
          )
          : (
            <>
              {isEmpty ? (
                <Box align="center" justify="center">
                  <Text color="textSecondary" style={{ lineHeight: '21px' }}>
                    The employee doesn't have any Enrollment & Balances yet.
                  </Text>
                </Box>
              ) : (
                <Box>
                  <Box direction="row" align="center" justify="between">
                    <Box justify="center">
                      <Text size="20px" data-testid="EnrollmentBalances-holder-name">
                        {firstName}’s Enrollments
                      </Text>
                    </Box>
                    <Box width="control">
                      <SelectDropdown
                        testId="status"
                        disabled={isLoading}
                        id="enrollment-filter"
                        allMode
                        activeTitle
                        ellipsisMode
                        name="Status"
                        prefix="Status"
                        options={OPTIONS}
                        values={filterValues}
                        onChangeValues={handleSetFilterValues} />
                    </Box>
                  </Box>

                  {content}

                </Box>
              )}
            </>
          )}
      </Box>
      {children}
    </Box>
  );
};
