import { NO_KEY, YES_KEY } from '@/common/constants';

export default (value?: string | boolean) => {
  if(value === true) {
    return true;
  }
  if(value === false) {
    return false;
  }
  return value === YES_KEY ? true: value === NO_KEY ? false: undefined;
};