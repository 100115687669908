import React from 'react';
import {
  Box,
  // ButtonSwitcher,
} from '@common-fe/common-fe';

// import { HashLink } from 'react-router-hash-link';
// import { useCurrentOrganization } from '@/modules/core/hooks';
// import { useIsOrganizationType } from '@/modules/user/hooks';
// import { OrganizationTypes } from '@/common/constants';
// import { ReportProgram } from '@/modules/employer/components/Reports/report.types';
import { StyledHeading } from './PickReportHeader.styles';

// interface SelectProps {
//   onClick: (name: ReportProgram | null) => void;
//   name: ReportProgram | null;
//   currType: ReportProgram | null;
//   text: string
// }

// const SelectReportTypeButton: React.VFC<SelectProps> = ({
//   name, currType, text, onClick,
// }) => (
//   <HashLink
//     data-testid={`select-report-button-${name || 'all'}`}
//     smooth
//     to={`#${name || 'all'}`}
//     style={{
//       textDecoration: 'none',
//     }}
//   >
//     <Box height={{ max: '40px' }} width={{ min: '145px' }}>
//       <ButtonSwitcher
//         active={currType === name}
//         onClick={() => onClick(name)}
//       >
//         {text}
//       </ButtonSwitcher>
//     </Box>

//   </HashLink>
// );

interface Props {
  minimize?: boolean;
}

export const PickReportHeader: React.FC<Props> = ({ minimize }) => (
  <Box
    data-testid="PickReportHeader-wrapper"
    direction="row"
    justify="between"
    margin={{
      bottom: minimize ? 'spacing4' : 'spacing16',
      top: minimize ? 'spacing4' : 'medium',
    }}
    height={{ min: 'auto' }}
    style={{ transition: 'all 0.6s' }}
    align="center"
  >
    <StyledHeading level={4} size="large">Reports</StyledHeading>
    {/* <Box direction="row" align="center">
        <Box
          direction="row"
          background="module"
          round="container1Round"
          pad="spacing4"
          // margin={{ right: 'spacing16' }}
          gap="spacing4"
        >
          <SelectReportTypeButton
            onClick={setSelectedType}
            currType={selectedType}
            name={null}
            text="All Reports"
          />
          <SelectReportTypeButton
            onClick={setSelectedType}
            currType={selectedType}
            name={ReportProgram.SCHEDULED}
            text="Scheduled Reports"
          />
          <Box
            width="1px"
            height="26px"
            // margin={{ right: '22px' }}
            background={{ color: 'border1' }}
            alignSelf="center"
          />
          <SelectReportTypeButton
            onClick={setSelectedType}
            currType={selectedType}
            name={ReportProgram.KPI}
            text="KPI’s Reports"
          />
        </Box>
        <Box width={{ max: '140px', min: '140px' }}>
          <RawButton
            primary
            label="Build My Report"
            style={{ paddingInline: '12px', minHeight: '44px' }}
          />
        </Box>
      </Box> */}

  </Box>
);
