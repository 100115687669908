export interface FileRecordErrorDtoPayload {
  id: number;
  employee_id: number;
  employer_id: number;
  error_details: string;
  field_name: string;
  file_record_error_type: string;
  file_record_id: number;
  line_number: number;
  partner_id: number;
  last_name: string;
  first_name: string;
  error_code: string;
}

export interface FileRecordErrorDto {
  id: string;
  employeeId: string;
  employerId: string;
  errorDetails: string;
  fieldName: string;
  fileRecordErrorType: string;
  fileRecordId: string;
  lineNumber: number;
  partnerId: string;
  lastName: string;
  firstName: string;
  errorCode: string;
}

export enum FileErrorTypes {
  storeError = 'STORE_ERROR',
  validationError = 'VALIDATION_ERROR',
  parsingError = 'PARSING_ERROR',
  qualityGatesError = 'QUALITY_GATES_ERROR',
  encodingError = 'ENCODING_ERROR',
  error = 'ERROR',
}

export interface FileErrorDto {
  id: number;
  file_id: number;
  error_details: string;
  file_error_type: FileErrorTypes;
  created_at: string;
}

export interface FileError {
  id: string;
  fileId: string;
  errorDetails: string;
  fileErrorType: FileErrorTypes;
  createdAt: string;
}
