import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Box, CalendarIcon, CalendarPickerIcon,   DateRangeDropdown,   FlexListPlaceholder, 
  PaginationBar,
  Preloader, SearchInput, SelectDropdown,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { ALL_OPTION } from '@/common/constants';
import ROUTES from '@/common/routes';
import { ListItemBase, OptionKey } from '@/common/types';
import { useAllOptionsToggle } from '@/modules/core/hooks';
import useListPagination from '@/modules/transaction/hooks/useListPagination';
import {
  SearchTransactionParams,
  Transaction,
} from '@/modules/transaction/transaction.types';
import { EmptyTransactionsListPlaceholder } from '@/modules/transaction/TransactionsList/EmptyTransactionsListPlaceholder';
import {
  AllButton,
  CalendarWrapper,
  Content,
  DropdownInputWrapper,
  Header,
  HeaderContainer,
  HeaderWrapper,
  SearchInputWrapper,
  Wrapper,
} from '@/modules/transaction/TransactionsList/TransactionsList.styles';
import TransactionsListHeader from '@/modules/transaction/TransactionsList/TransactionsListHeader/TransactionsListHeader';

import { useServiceCategoriesQuery } from '../../EnrollmentDetails/ActivitiesList/queries';
import useGetClaimStatusesQuery from '../../EnrollmentDetails/queries/useGetClaimStatuses.query';

import { useExpensesList } from './queries/useExpensesList.query';
import { RecentExpenseItem } from './RecentExpenseItem';

const CUSTOM_DROPDOWN_HEIGHT = 500;
const BACKLIGHT_DELAY = 2000;
const QUERY_KEY = 'justAddedClaimId';
const headers: ListItemBase[] = [
  {
    key: 'provider',
    title: '',
    flex: 1.5,
  },
  {
    key: 'requestedAmount',
    title: 'Requested Amount',
    flex: 1,
  },
  {
    key: 'paidAmount',
    title: 'Paid Amount',
    flex: 0.8,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 1.2,
  },
  {
    key: 'statusDate',
    title: 'Status Date',
  },
];

interface Props {
  viewAll?: boolean;
  title?: string;
  onViewAll?: () => void;
  data?: Transaction[];
  total?: number;
  isLoading?: boolean;
  defaultParams?: SearchTransactionParams;
  onChangeParams?: (value: SearchTransactionParams) => void;
}
export const ExpensesList: React.FC<Props> = ({
  title,
  viewAll,
  onViewAll,
}) => {
  const [initialData, setInitialData] = useState(false);
  const [statuses, setStatuses] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [categories, setCategories] = useState<OptionKey[]>([ALL_OPTION.value]);
  const { id: employeeId } = useParams<{ id: string }>();
  const { statusesWithoutCanceled } = useGetClaimStatusesQuery();
  const { serviceCategories } = useServiceCategoriesQuery();
  const categoriesOptions = useMemo(() => {
    const preparedCategories = serviceCategories.map((category) => ({
      key: category.id,
      value: category.name,
      title: category.description,
    }));

    return [ALL_OPTION, ...preparedCategories];
  }, [serviceCategories]);
  const statusesOptions = useMemo(() => {
    const preparedStatuses = statusesWithoutCanceled.map((status) => ({
      key: status.name,
      value: status.name,
      title: status.description,
    }));

    return [ALL_OPTION, ...preparedStatuses];
  }, [statusesWithoutCanceled]);

  const statusValues = useAllOptionsToggle(statuses);

  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ALL_OPTION.value),
    [statusValues],
  );

  const categoryValues =useAllOptionsToggle(categories);

  const hasCategory = useMemo(
    () => categoryValues.length > 0
      && !categoryValues.some((status) => status === ALL_OPTION.value),
    [categoryValues],
  );

  const {
    providerSearchString,
    currentSearch,
    setSearchProvider,
    onChangeDates,
    startDate,
    endDate,
    perPage,
    setPerPage,
    page,
    setPage,
  } = useListPagination();
  const { push } = useHistory();

  const {
    data, total, isLoading, isSuccess,
  } = useExpensesList({
    employee_id: employeeId,
    page: page - 1,
    size: perPage,
    statuses: (hasStatus ? statusValues.join(', ') : ''),
    ...currentSearch ? { provider_name: currentSearch } : {},
    category_ids: (hasCategory ? categoryValues.join(', ') : ''),
    status_date_from: startDate && dayjs(startDate).startOf('day').format(),
    status_date_to: endDate && dayjs(endDate).endOf('day').format(),
  });

  const { search, pathname } = useLocation();
  const justAddedClaimId = useMemo(() => new URLSearchParams(search).get(QUERY_KEY), [search]);

  const isAnyInputFilled = useMemo(() => providerSearchString.length
    || (statuses.length >= 1 && statuses[statuses.length - 1] !== ALL_OPTION.value)
    || (categories.length >= 1 && categories[categories.length - 1] !== ALL_OPTION.value)
    || startDate
    || endDate,
  [categories, endDate, providerSearchString, startDate, statuses]);

  const isInitialData = useCallback(() => (
    (!data.length && !isAnyInputFilled) ? setInitialData(false) : setInitialData(true)),
  [data.length, isAnyInputFilled]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (justAddedClaimId && isSuccess) {
      timer = setTimeout(() => push(pathname), BACKLIGHT_DELAY);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pathname, justAddedClaimId, isSuccess, push]);

  useEffect(() => {
    if (!data.length && !isLoading) {
      setPage(1);
    }
  }, [data, isLoading, setPage]);

  useEffect(() => {
    isInitialData();
  }, [isInitialData]);
  return (
    <Wrapper data-testid="ExpensesList-wrapper">
      <HeaderWrapper>
        <HeaderContainer>
          <Header>{title || 'Expenses'}</Header>
          {viewAll && <AllButton onClick={onViewAll}>View All</AllButton>}
        </HeaderContainer>
        <SearchInputWrapper>
          <SearchInput
            disabled={!initialData}
            hasSearchingHistory
            value={providerSearchString}
            placeholder="Search"
            onChange={setSearchProvider}
          />
        </SearchInputWrapper>
        <DropdownInputWrapper>
          <SelectDropdown
            disabled={!initialData}
            id="status"
            allMode
            name="Activity status"
            prefix="Status"
            options={statusesOptions}
            values={statusValues}
            onChangeValues={setStatuses}
            customHeight={CUSTOM_DROPDOWN_HEIGHT}
          />
        </DropdownInputWrapper>
        <DropdownInputWrapper>
          <SelectDropdown
            disabled={!initialData}
            id="category"
            allMode
            name="Activity category"
            prefix="Category"
            options={categoriesOptions}
            values={categoryValues}
            onChangeValues={setCategories}
          />
        </DropdownInputWrapper>
        <DropdownInputWrapper>
          <DateRangeDropdown
            disabled={!initialData}
            prefix="Period:"
            onChange={onChangeDates}
            startDate={startDate}
            endDate={endDate}
            ellipsisMode
            icon={(
              <Box
                align="center"
                justify="center">
                <CalendarWrapper disabled={!initialData}>
                  {!initialData ? (
                    <CalendarPickerIcon />
                  ) : (
                    <CalendarIcon color="iconPrimary" />
                  )}
                </CalendarWrapper>
              </Box>
            )}
          />
        </DropdownInputWrapper>
      </HeaderWrapper>
      {isLoading && <Preloader />}
      {initialData && !isLoading && (
        <>
          <TransactionsListHeader items={headers} />
          <Content>
            {isLoading ? <Box align="center" justify="center"> <Preloader /></Box>
              : data?.length === 0 && (
                <FlexListPlaceholder />
              )}
            {data?.map((item: Transaction) => (
              <RecentExpenseItem
                key={item.claimId}
                data={item}
                onPick={() => push(ROUTES.TRANSACTION_DETAILS_CLAIM(employeeId, item?.claimId))}
              />
            ))}
          </Content>
        </>
      )}
      {!isLoading && !initialData && <EmptyTransactionsListPlaceholder title="Expenses" isDescriptionHidden />}

      {
        total === 0 ? null : (
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )
      }
    </Wrapper>
  );
};
