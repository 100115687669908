import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks';
import ContributionActivitiesList from '@/modules/employer/components/ContributionActivities/ContributionActivitiesList';
import { ContributionListItemType } from '@/modules/employer/components/Contributions/contributions.types';
import theme from '@/styles/theme';

export const goToContributionActivities = (contribution?: ContributionListItemType, isApprovalNeededStatus?: boolean) => window.open(
  `${ROUTES.CONTRIBUTION_ACTIVITIES(contribution?.contributionId, contribution?.submitDate, `${Boolean(isApprovalNeededStatus)}`)}${window.location.search}${window.location.hash}`,
  '_blank',
);

const Wrapper = styled.div`
  width: 100%;
  margin-top: 0;
`;

const ContributionActivitiesPage = () => {
  useBreadcrumbs([
    {
      route: ROUTES.CONTRIBUTIONS,
      title: 'Contributions',
    },
    {
      route: ROUTES.CONTRIBUTION_ACTIVITIES(),
      title: 'Contribution activity',
    },
  ]);
  return (
    <Wrapper>
      <Topbar />
      <Box align="center" pad={{ bottom: 'spacing72' }}>
        <Box width={theme.defaultContentWidth}>
          <ContributionActivitiesList />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default ContributionActivitiesPage;
