import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Box,
  Inscription,
  Preloader,
  RoundPendingIcon,
  WarnModal,
} from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';

import { useCancelEmployerTerminationModalStore, useCancelEmployerTerminationSuccessModalStore, useEmployerTerminationModalStore } from '../store';

import { useEmployerTerminationPlanList } from './hooks/useEmployerTerminationPlanList';
import { useDeleteEmployerTerminationConfigQuery } from './queries/useDeleteEmployerTerminationConfig.query';
import { useGetEmployerTerminationDataQuery } from './queries/useGetEmployerTerminationData.query';
import { EmployerTerminationDateForm } from './EmployerTerminationDateForm';
import { EmployerTerminationHsaTransferForm } from './EmployerTerminationHsaTransferForm';
import { EmployerTerminationParamsForm } from './EmployerTerminationParamsForm';
import { EmployerTerminationPlanList } from './EmployerTerminationPlanList';
import { EmployerTerminationPlansCoverageDatesList } from './EmployerTerminationPlansCoverageDatesList';
    
interface Props {
  onCancel?: (value: boolean) => void;
}
    
const CancelEmployerTerminationModalForm: React.FC<Props> = ({
  onCancel,
}) => {  
  const [isServerError, setIsServerError] = useState(false);

  const employerId = useEmployerTerminationModalStore((state) => state.employerId);
  const employerName = useEmployerTerminationModalStore((state) => state.employerName);

  const handleChangeVisibility = useEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const handleChangeCancelVisibility = useCancelEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const handleSetEditMode = useEmployerTerminationModalStore((state) => state.handleSetEditMode);

  const handleChangeSuccessVisibility = useCancelEmployerTerminationSuccessModalStore((state) => state.handleChangeVisibility);

  const { terminationData, isLoading: terminationDataLoading } = useGetEmployerTerminationDataQuery(employerId || '');
  const { cancelTermination, isLoading: cancelTerminationLoading } = useDeleteEmployerTerminationConfigQuery();
  
  const { 
    isLoading: planListLoading,
    data,
    coverageData,
    isHsaAccountExist,
    hsaStartDate,
  } = useEmployerTerminationPlanList(
    employerId,
    terminationData?.terminationDate,
    terminationData?.coverageEndDate,
    terminationData?.claimLastDate,
  );
  
  const modalDescription = useMemo(() => {
    return `Please note that you can edit or cancel termination configurations until ${terminationData?.terminationDate}.`;
  }, [terminationData]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);
    
  const handleSubmit = useCallback(async () => {
    try {
      await cancelTermination(employerId || '');
      handleCancel();
      handleChangeSuccessVisibility(true);
    } catch {
      setIsServerError(true);
    }
  }, [
    cancelTermination,
    employerId,
    handleCancel,
    handleChangeSuccessVisibility,
  ]);

  const handleEditTermination = useCallback(() => {
    handleChangeCancelVisibility(false);
    handleChangeVisibility(true);
    handleSetEditMode(true);
  }, [
    handleChangeVisibility,
    handleSetEditMode,
    handleChangeCancelVisibility,
  ]);
    
  return (
    <>
      <Box margin={{ bottom: 'spacing24' }} align="center">
        <RoundPendingIcon color="warning"/>
        <Box margin={{ vertical: 'spacing12' }}>
          <Inscription size="24px" weight={700} lineHeight="32px">
            Cancel {employerName || ''} Termination?
          </Inscription>
        </Box>
        <Inscription textAlign="center" size="14px">
          {modalDescription}
        </Inscription>
      </Box>
  
      <Box data-testid="CancelEmployerTerminationModalForm_container">
        <Box direction="column">
          <Box
            round="container1Round"
            pad="spacing24"
            background="module"
            gap='spacing24'
          >
            <EmployerTerminationDateForm
              terminationDate={terminationData?.terminationDate}
              minDate={hsaStartDate}
              viewMode
            />
            {planListLoading || terminationDataLoading ? (
              <Preloader />
            ) : (
              <Box gap="spacing24">
                <EmployerTerminationPlanList
                  planList={data}
                />
                {data.length ? (
                  <Box gap="spacing24">
                    <EmployerTerminationPlansCoverageDatesList
                      planList={coverageData}
                    />
                    <EmployerTerminationParamsForm
                      terminationDate={terminationData?.terminationDate}
                      defaultEndOfCoverage={terminationData?.coverageEndDate}
                      defaultLastDayToSubmitClaim={terminationData?.claimLastDate}
                      viewMode
                    />
                  </Box>
                ) : null}
  
                {isHsaAccountExist ? (
                  <EmployerTerminationHsaTransferForm
                    defaultHsaTransferOfAssets={terminationData?.bulkTransfer}
                    viewMode
                  />
                ) : null}
              </Box>
            )}
              
          </Box>
          <Box
            direction="row"
            fill="horizontal"
            margin={{ top: 'spacing24' }}
            align="end"
            justify="between"
            width="medium"
            gap="xs"
          >
            <Box width="160px">
              <AppButton
                testId="CancelEmployerTerminationModalForm_edit"
                buttonType="secondary"
                width="100%"
                type="button"
                onClick={handleEditTermination}
                disabled={cancelTerminationLoading}
              >
                {cancelTerminationLoading
                  ? (<Preloader />)
                  : 'Edit Termination'}
              </AppButton>
            </Box>

            <Box direction="row" gap="spacing12">
              <Box width="232px">
                <AppButton
                  testId="CancelEmployerTerminationModalForm_cancel"
                  buttonType="secondary"
                  width="100%"
                  type="button"
                  onClick={handleCancel}
                  disabled={cancelTerminationLoading}
                >
                  {cancelTerminationLoading
                    ? (<Preloader />)
                    : 'Continue With Termination'}
                </AppButton>
              </Box>
              <Box width="232px">
                <AppButton
                  testId="CancelEmployerTerminationModalForm_submit"
                  type="button"
                  disabled={planListLoading || cancelTerminationLoading}
                  onClick={handleSubmit}
                  width="100%"
                >
                  {planListLoading || cancelTerminationLoading
                    ? (<Preloader />)
                    : 'Cancel Termination'}
                </AppButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <WarnModal
        testId="employer-termination-server-error-modal"
        visible={isServerError}
        onSetVisible={setIsServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot cancel the Employer termination with the latest records.
          Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
    </>
  );
};
    
export default CancelEmployerTerminationModalForm;
    