import React, {  useMemo } from 'react';
import { ListItem, ListItemType } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT, EMPTY_FIELD_VALUE } from '@/common/constants';
import NotVisibleICount from '@/components/elements/NotVisibleICount';

import ContributionStatus from '../ContributionStatus';
import { useScheduledContributionListQuery } from '../queries';
import { ScheduledContributionStatus } from '../ScheduledContribution.types';
import ScheduledContributionName from '../ScheduledContributionName';
import { getFrequencyTitle } from '../utils';

interface ExtenderListItem extends ListItem {
  contributionStatus: ScheduledContributionStatus
}

const useScheduledContributionList = () => {
  const { formattedData, isSuccess, isLoading } = useScheduledContributionListQuery();

  const rows = useMemo(() => {
    const list: ExtenderListItem[] = formattedData.map((item) => ({
      id: item.id,
      contributionStatus: item.status, 
      fields: [
        {
          key: 'name',
          type: ListItemType.Node,
          title: '',
          flex: 2,
          node: (
            <ScheduledContributionName
              calendarStatus={item.calendarStatus}
              isDeactivated={item.status === ScheduledContributionStatus.Deactivated}
              value={item.name}
            />
          ),
        },
        {
          key: 'frequency',
          type: ListItemType.Text,
          title: getFrequencyTitle(item.frequency),
          flex: 2,
        },
        {
          key: 'nextScheduledDate',
          type: ListItemType.Text,
          title:item.nextScheduledDate ? dayjs(item.nextScheduledDate).format(DEFAULT_DATE_FORMAT): EMPTY_FIELD_VALUE,
          flex: 2,
        },

        {
          key: 'accounts',
          type: ListItemType.Node,
          title: '',
          node: <NotVisibleICount options={_.unionBy(item.accounts, (item) => item.value)} />,
          flex: 4,
        },
        {
          key: 'contributionStatus',
          type: ListItemType.Node,
          title: item.status,
          node: <ContributionStatus status={item.status}></ContributionStatus>,
          flex: 3,
        },
      ],
    }));
    return list;
  }, [formattedData]);
  const total = useMemo(() => formattedData.length, [formattedData.length]);

  return {
    rows,
    isLoading,
    isSuccess,
    formattedData,
    total,
  };
};

export default useScheduledContributionList;