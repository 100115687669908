import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useContactSetter from '../AddEditContactForm/hooks/useContactSetter.ts';
import { ContactPayload } from '../AddEditContactForm/stores/useContact.store.ts';
import { useAddressStore } from '../EditAddressModal/stores';
import useBankAccountSetter from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/hooks/useBankAccountSetter.ts';
import { BankAccountPayload } from '../ExpressSetupWizard/BankAccountWizard/BankAccountForm/stores/useBankAccount.store.ts';
import useCopaySetter from '../ExpressSetupWizard/PlansWizard/Copays/hooks/useCopaySetter.ts';
import { CopayPayload } from '../ExpressSetupWizard/PlansWizard/Copays/stores/useCopay.store.ts';
import usePlansSetter from '../ExpressSetupWizard/PlansWizard/Plans/hooks/usePlansSetter.ts';
import { PlansPayload } from '../ExpressSetupWizard/PlansWizard/Plans/stores/usePlans.store.ts';
import usePlanYearSetter from '../ExpressSetupWizard/PlansWizard/PlanYear/hooks/usePlanYearSetter.ts';
import { PlanYearPayload } from '../ExpressSetupWizard/PlansWizard/PlanYear/stores/usePlanYear.store.ts';


export type ExpressSetupPayload = ContactPayload & PlanYearPayload & PlansPayload & BankAccountPayload & CopayPayload;

export default () => {
  const {
    handleSetErrorFields: handleSetContactErrorFields,
    handleReset: handleResetContactStore,
  } = useContactSetter();

  const {
    handleSetSourceState: handleSetPlanYearSourceState,
    handleSetErrorFields: handleSetPlanYearErrorFields,
    handleReset: handleResetPlanYeartStore,
  } = usePlanYearSetter();

  const {
    handleSetSourceState: handleSetBankAccountSourceState,
    handleSetErrorFields: handleSetBankAccountErrorFields,
    handleReset: handleResetBankAccountStore,
  } = useBankAccountSetter();

  const {
    handleSetState: handleSetPlansState,
    handleSetSourceState: handleSetPlansSourceState,
    handleSetErrorFields: handleSetPlansErrorFields,
    handleReset: handleResetPlansStore,
  } = usePlansSetter();

  const {
    handleSetState: handleSetCopaysState,
    handleSetSourceState: handleSetCopaysSourceState,
    handleSetErrorFields: handleSetCopaysErrorFields,
    handleReset: handleResetCopaysStore,
  } = useCopaySetter();

  const handleResetAddressStore = useAddressStore((state) => state.handleReset);
  
  const handleSetState = useCallback((values: Partial<ExpressSetupPayload>) => {
    handleSetPlansState(values);
    handleSetCopaysState(values);
  }, [handleSetCopaysState, handleSetPlansState]);

  const handleSetSourceState = useCallback((values: Partial<ExpressSetupPayload>) => {
    // we set source state for contact separtely before open main contact or contact form
    handleSetPlanYearSourceState(values);
    handleSetPlansSourceState(values);
    handleSetCopaysSourceState(values);
    handleSetBankAccountSourceState(values);
  }, [handleSetBankAccountSourceState, handleSetCopaysSourceState, handleSetPlanYearSourceState, handleSetPlansSourceState]);

  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<ExpressSetupPayload>>) => {
    handleSetContactErrorFields(values);
    handleSetPlanYearErrorFields(values);
    handleSetPlansErrorFields(values);
    handleSetBankAccountErrorFields(values);
    handleSetCopaysErrorFields(values);
  }, [handleSetBankAccountErrorFields, handleSetContactErrorFields, handleSetCopaysErrorFields, handleSetPlanYearErrorFields, handleSetPlansErrorFields]);

  const handleReset = useCallback(() => {
    handleResetContactStore();
    handleResetPlanYeartStore();
    handleResetPlansStore();
    handleResetBankAccountStore();
    handleResetCopaysStore();
    handleResetCopaysStore();
    handleResetAddressStore();
  }, [handleResetAddressStore, handleResetBankAccountStore, handleResetContactStore, handleResetCopaysStore, handleResetPlanYeartStore, handleResetPlansStore]);

  return {
    handleSetState,
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
  };
};
