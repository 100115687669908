import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
// import { OrganizationTypes, } from '@/common/constants';
import PATHS from '@/common/paths';
import { Option} from '@/common/types';

export const GET_ORGANIZATIONS_KEY = 'useGetOrganizationsQueryKey';

// const SIZE_PER_PAGE = 50;
// const PAGE = 0;
// const ACTIVE_STATUS = 'ACTIVE';

export interface OrphanOrganizationsResponse {
  id: string;
  name?: string;

}

interface Props {
perPage?: number;
  page?: number;
  partnerId?: string | null;
  enable: boolean; 
}

export const useOrphanOrganizationsQuery = ({enable, perPage, page}: Props) => {

  const params = useMemo(() => ({
    // partner_id: partnerId ,
  }), []); 
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [GET_ORGANIZATIONS_KEY, params, enable],
    () => api.get<OrphanOrganizationsResponse[]>(PATHS.ORPHAN_ORGANIZATIONS, {
      params,
      
    }), {
      enabled: enable
    }
     
  );

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: String(item.id),
      value: item.name,
      title: item.name,
    }));
  }, [data]);

  const total = useMemo(() => data?.data?.length || 0, [data]);

  return {
    data: formatedData,
    total,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
export default useOrphanOrganizationsQuery;