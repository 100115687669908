import { lazy } from 'react';
import { RouteProps } from 'react-router';

import ROUTES from '@/common/routes';
import { pageWithTOC } from '@/components/hocs/pageWithTOC';
import EmployerTOCSidebar from '@/modules/employer/components/TOC/EmployerTOCSidebar';
import { AccountActivityDetailsPage } from '@/pages/AccountActivityDetails.page';
import ReconciliationReportDetailsPage from '@/pages/ReconciliationReportDetails.page';

import CarrierConfigPage from './CarrierConfig.page';
import CIPDocumentVerificationPage from './CIPDocumentVerification.page';
import ClaimsPage from './Claims.page';
import ContributionActivitiesPage from './ContributionActivities.page';
import ContributionsPage from './Contributions.page';
import CopayAdjudicationPage from './CopayAdjudication.page';
import { CreateEmployerPage } from './CreateEmployer.page';
import DeductiblesPage from './Deductibles.page';
import EmployeePage from './Employee.page';
import EmployeesPage from './Employees.page';
import EnrollmentDetailsPage from './EnrollmentDetails.page';
import EnrollmentMatrixPage from './EnrollmentMatrix.page';
import ErrorPage from './Error.page';
import ExpressSetupPage from './ExpressSetup.page';
import ExpressSetupActivatePage from './ExpressSetupActivate.page';
import ExpressSetupActivityLogsPage from './ExpressSetupActivityLogs.page';
import ExpressSetupWizardPage from './ExpressSetupWizard.page';
import FileManagerPage from './FileManager.page';
import FileSummaryPage from './FileSummary.page';
import { FinOpsPrefundPage } from './FinOpsPrefund.page';
import HealthPlanPage from './HealthPlan.page';
import HealthPlanHubPage from './HealthPlanHub.page';
import HealthPlansPage from './HealthPlans.page';
import HealthPlanSetupPage from './HealthPlanSetup.page';
import HealthPlanTemplatePage from './HealthPlanTemplate.page';
import HealthPlanTemplateSetupPage from './HealthPlanTemplateSetup.page';
import HelpPage from './Help.page';
import { Home } from './Home.page';
import InvoicesPage from './Invoices.page';
import LockboxDetailsPage from './LockboxDetails.page';
import LoginPage from './Login.page';
import ManageClaimsProcessors from './ManageClaimsProcessors.page';
import OrganizationsPage from './OrganizationsPage';
import PayoutDefinitionsPage from './PayoutDefinitions.page';
import PayoutDefinitionSetup from './PayoutDefinitionSetup.page';
import PlanPage from './Plan.page';
import PlansPage from './Plans.page';
import PlanSetupPage from './PlanSetup.page';
import ProcessingDefinitionPage from './ProcessingDefinition.page';
import ProcessingRulesPage from './ProcessingRules.page';
import ReconciliationReportsPage from './ReconciliationReports.page';
import RenewalsPage from './Renewals.page';
import ReportsPage from './Reports.page';
import ScheduledContributionEditSummaryPage from './ScheduledContributionEditSummary.page';
import ScheduledContributionSummaryPage from './ScheduledContributionSummary.page';
import SetUpContributionPage from './SetUpContribution.page';
import TemplatePage from './Template.page';
import TemplateSetupPage from './TemplateSetup.page';
const TransactionDetailsPage = lazy(() => import('./TransactionDetails.page'));

const ClaimsProcessorPage = lazy(() => import('./ClaimsProcessor.page'));
const EmployersPage = lazy(() => import('./EmployersPage'));
const ReimburseMePage = lazy(() => import('./ReimburseMePage'));
const EmployerSetupPage = lazy(() => import('./EmployerSetup.page'));

type Route = RouteProps & {
  authed?: boolean;
};

export const GLOBAL_ROUTES: Route[] = [{
  path: ROUTES.LOGIN,
  component: LoginPage,
}, {
  path: ROUTES.CHANGE_PASSWORD,
  component: LoginPage,
}, {
  path: ROUTES.HELP,
  component: HelpPage,
},
{
  path: ROUTES.ERROR,
  component: ErrorPage,
},
{
  path: ROUTES.EMPLOYERS_CREATE,
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYERS_VIEW,
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYERS_EDIT(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.PARTNERS_CREATE,
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.DISTRIBUTORS_CREATE,
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.SUBSIDIARY_CREATE,
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYEE_VIEW(),
  component: EmployeePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SCHEDULED_CONTRIBUTION_EDIT_SUMMARY(),
  component: ScheduledContributionEditSummaryPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SCHEDULED_CONTRIBUTION_SUMMARY,
  component: ScheduledContributionSummaryPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYEE_VIEW(),
  component: EmployeePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYEE_EDIT_VIEW(),
  component: EmployeePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EXPRESS_SETUP_VIEW(),
  component: ExpressSetupPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EXPRESS_SETUP_WIZARD,
  component: ExpressSetupWizardPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EXPRESS_SETUP_ACTIVATE,
  component: ExpressSetupActivatePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EXPRESS_SETUP_ACTIVITY_LOGS,
  component: ExpressSetupActivityLogsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PARTNERS_VIEW(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PARTNERS_EDIT(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.COPAY_ADJUDICATION,
  component: CopayAdjudicationPage,
  authed: true,
},
{
  path: ROUTES.PARTNERS,
  component: OrganizationsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.DISTRIBUTORS,
  component: OrganizationsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.DISTRIBUTORS_VIEW(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.DISTRIBUTORS_EDIT(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SUBSIDIARIES,
  component: OrganizationsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SUBSIDIARY_VIEW(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SUBSIDIARY_EDIT(),
  component: CreateEmployerPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYERS,
  component: EmployersPage,
  authed: true,
},
{
  path: ROUTES.EMPLOYER_SETUP,
  component: pageWithTOC(EmployerSetupPage),
  authed: true,
},

{
  path: ROUTES.PLAN_SETUP,
  component: PlanSetupPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PROCESSING_DEFINITION,
  component: ProcessingDefinitionPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.CARRIER_CONFIG,
  component: CarrierConfigPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.ENROLLMENT_MATRIX,
  component: EnrollmentMatrixPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PROCESSING_RULES,
  component: ProcessingRulesPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.SPENDS_BY_ID(),
  component: DeductiblesPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PAYOUT_DEFINITIONS,
  component: PayoutDefinitionsPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.ID_SCAN(),
  component: CIPDocumentVerificationPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PROCESSING_DEFINITION_DETAILS(),
  component: PayoutDefinitionSetup,
  authed: true,
  exact: true,
},
{
  path: ROUTES.TEMPLATE,
  component: TemplatePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.TEMPLATE_SETUP,
  component: TemplateSetupPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HEALTH_PLAN_HUB,
  component: HealthPlanHubPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HEALTH_PLAN_TEMPLATE,
  component: HealthPlanTemplatePage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HEALTH_PLAN_TEMPLATE_SETUP,
  component: HealthPlanTemplateSetupPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.HEALTH_PLAN,
  component: HealthPlanPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HEALTH_PLAN_SETUP,
  component: HealthPlanSetupPage,
  authed: true,
  exact: true,
},

{
  path: ROUTES.NEW_PLAN,
  component: PlanPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.PLANS,
  component: PlansPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HEALTH_PLANS,
  component: HealthPlansPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.EMPLOYEES,
  component: EmployeesPage,
  authed: true,
},
{
  path: ROUTES.TRANSACTION_DETAILS_CLAIM(),
  component: TransactionDetailsPage,
  authed: true,
},
{
  path: ROUTES.TRANSACTION_DETAILS_POSTING(),
  component: TransactionDetailsPage,
  authed: true,
},
{
  path: ROUTES.TRANSACTION_ENROLLMENT_DETAILS_POSTING(),
  component: TransactionDetailsPage,
  authed: true,
},
{
  path: ROUTES.TRANSACTION_ENROLLMENT_DETAILS_CLAIM(),
  component: TransactionDetailsPage,
  authed: true,
},
{
  path: ROUTES.ENROLLMENT_DETAILS(),
  component: EnrollmentDetailsPage,
  authed: true,
},
{
  path: ROUTES.REIMBURSE_ME(),
  component: ReimburseMePage,
  authed: true,
},
{
  path: ROUTES.SYSTEM_VIEW,
  component: CreateEmployerPage,
  authed: true,
},
{
  path: ROUTES.FILE_MANAGER,
  component: FileManagerPage,
  authed: true,
},
{
  path: ROUTES.FILE_SUMMARY,
  component: FileSummaryPage,
  authed: true,
},
{
  path: ROUTES.RENEWALS,
  component: RenewalsPage,
  authed: true,
},
{
  path: ROUTES.INVOICES,
  component: InvoicesPage,
  authed: true,
},
{
  path: ROUTES.CLAIMS,
  component: ClaimsPage,
  authed: true,
},
{
  path: ROUTES.MANAGE_CLAIMS_PROCESSORS,
  component: ManageClaimsProcessors,
  authed: true,
},
{
  path: ROUTES.CLAIMS_PROCESSOR,
  component: ClaimsProcessorPage,
  authed: true,
},
{
  path: ROUTES.REPORTS,
  component: ReportsPage,
  authed: true,
},
{
  path: ROUTES.RECONCILIATION_REPORT_VIEW,
  component: ReconciliationReportDetailsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.FINOPS,
  component: ReconciliationReportsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.LOCKBOX_DETAILS(),
  component: LockboxDetailsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.FINOPS_PREFUND_VIEW(),
  component: FinOpsPrefundPage,
  authed: true,
},
{
  path: ROUTES.CONTRIBUTIONS,
  component: ContributionsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.CONTRIBUTION_ACTIVITIES(),
  component: ContributionActivitiesPage,
  authed: true,
},
{
  path: ROUTES.SET_UP_CONTRIBUTION,
  component: SetUpContributionPage,
  authed: true,
},
{
  path: ROUTES.ACCOUNT_ACTIVITY_TRANSACTIONS_VIEW,
  component: AccountActivityDetailsPage,
  authed: true,
  exact: true,
},
{
  path: ROUTES.HOME,
  component: Home,
  authed: true,
},
];

export const HOME_ROUTES : Route[] = [{
  path: ROUTES.HOME,
  component: EmployerTOCSidebar,
}];
