import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { formatSnakeCaseToGeneral } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';
import {
  CategoryService,
  CategoryTypesResponse,
  PayoutDefinition,
  PayoutDefinitionResponse,
  PlanResponse,
} from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

export const QUERY_PAYOUT_DEFINITION_BY_ID = 'GET_PAYOUT_DEFINITION_BY_ID';

const useGetPayoutDefinitionById = (id?: string) => {
  const {
    data,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [QUERY_PAYOUT_DEFINITION_BY_ID, id],
    () => api.get<PayoutDefinitionResponse>(PATHS.GET_PAYOUT_DEFINITION_BY_ID(id)),
    {
      enabled: !!id,
    },
  );

  const formattedData = useMemo(() => {
    if (!data) return undefined;

    return {
      id: `${data?.data.id}`,
      name: data?.data.name,
      categoryTypes: data?.data?.categories?.map((category: CategoryTypesResponse) => ({
        categoryType: formatSnakeCaseToGeneral(category?.category_type),
        services: category?.services?.map((service: CategoryService) => ({
          id: service.id,
          name: service.name,
          plans: service?.plans?.sort((a, b) => a?.order - b?.order)?.map((plan: PlanResponse) => ({
            id: plan.id,
            name: plan.name,
            accountType: plan.account_type,
            order: plan.order,
          })),
        })),
      })),
    } as PayoutDefinition;
  }, [data]);

  return {
    formattedData,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetPayoutDefinitionById;
