import { baseStoreCreator } from '@/utils/store';

interface EmailIllustrations {
  brandingLogoLink?: string;
  emailLogoLink?: string;
}

export const DEFAULT_VALUES: EmailIllustrations = {
  brandingLogoLink: '',
  emailLogoLink: '',
};

export const useEmailIllustrationsStore = baseStoreCreator<EmailIllustrations>(
  DEFAULT_VALUES,
);

export default useEmailIllustrationsStore;
