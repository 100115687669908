import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ROUTES from '@/common/routes';
import { TopbarWrapper } from '@/modules/core/components/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks';
import ScanDocument from '@/modules/employee/Employee/cip/ScanDocument';
import { useGetEmployeeById } from '@/modules/employee/Employee/hooks';

const CIPDocumentVerification = () => {
  const { id: employeeId } = useParams<{ id: string }>();
  const { data: employeeData } = useGetEmployeeById();
  const fullName = useMemo(() => `${employeeData?.firstName} ${employeeData?.lastName}`.trim(), [employeeData]);
  useBreadcrumbs([{
    route: ROUTES.EMPLOYEES,
    title: 'Employees',
  }, {
    route: ROUTES.EMPLOYEE_VIEW(employeeId),
    title: fullName,
  }, {
    route: ROUTES.ID_SCAN(),
    title: 'Document Upload',
  }], [fullName], { backBtn: true });
  return (

    <TopbarWrapper>
      <ScanDocument employeeId={employeeId} />
    </TopbarWrapper>
  );
};
export default CIPDocumentVerification;
