import React, { useCallback,useState } from 'react';
import {
  AppButton,
  Box,
  Text,
  WarningLabel,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { INPUT_DATE_FORMAT } from '@/common/constants';
import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { EmployerLockUnlockModal } from '../EmployerLockUnlockModal';

interface Props {
  date: string;
  isLocked?: boolean;
  employerId?: string;
}

const REQUIRED_PERMISSIONS = [{ permission: Permissions.ORG_LOCK }];
const OrganizationLockNotification: React.FC<Props> = ({ date, isLocked, employerId }) => {
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const handleCloseLockModal = useCallback(() => setShowLockModal(false), [setShowLockModal]);
  const { employer } = useStore((state) => state);
  const hasAccess = useHasAccess(REQUIRED_PERMISSIONS);

  return (
    <>
      <Box
        margin={{ top: 'l', bottom: 'xxs' }}
        pad={{ horizontal: 'l', bottom: 'l' }}
        background={{ color: 'warningContainer' }}
        border={{ size: '1px', color: 'warningBorder' }}
        round="container1Round"
        style={{
          position: 'relative',
        }}
      >
        <Box
          round="small"
          height="2px"
          margin={{ top: '-1px' }}
          background="warning"
        />
        <Box direction="row" justify="between" align="center">
          <Box>
            <Text weight="bold" margin={{ bottom: 'spacing8', top: 'l' }} color="textBody">
              <WarningLabel>
                This organization is locked!
              </WarningLabel>
            </Text>
            <Box>
              <Text
                margin={{ bottom: 'spacing8', left: 'xl' }}
                color="textBody"
                size="14px"
              >
                {`This organization was locked on ${dayjs(date).format(INPUT_DATE_FORMAT)}.`}
              </Text>
              <Text
                color="textBody"
                size="14px"
                margin={{ left: 'xl' }}
              >
                While locked, payments from any employer funded accounts will not be processed.
              </Text>
            </Box>
          </Box>
          {
            hasAccess && (
              <Box>
                <AppButton
                  onClick={() => setShowLockModal(true)}
                  width="150px"
                >
                  {isLocked ? 'Unlock' : 'Lock'}
                </AppButton>
              </Box>
            )
          }
        </Box>
      </Box>
      <EmployerLockUnlockModal
        visible={showLockModal}
        employerId={employerId}
        employerName={employer?.employerName}
        isLockMode={!isLocked}
        onSetVisible={handleCloseLockModal}
      />
    </>
  );
};

export default OrganizationLockNotification;
