import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { StatusNames } from '@/modules/employee/employee.types';
import { Lockbox, LockboxStatus, LockboxType, MatchedEmployeePayload } from '@/modules/employer/components/Lockbox/lockbox.types';

const getFullStatusName = (firstLetter: keyof typeof StatusNames) => StatusNames[firstLetter];

interface LockboxOcrAddressPayload {
  tax_id?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface LockboxOcrPayload {
  account_number?: string;
  employee_name?: string;
  ssn?: string;
  birth_date?: string;
  amount?: number;
  date?: string;
  address?: LockboxOcrAddressPayload;
}

interface CheckDetailsPayload {
  id?: number;
  vendor_id?: number;
  vendor_name?: string;
  check_name?: string;
  benefit_of?: string;
  city?: string;
  state?: string;
  zip?: string;
  check_memo?: string;
  address_line_1?: string;
  address_line_2?: string;
}

interface LockboxPayload {
  id?: number;
  document_id?: number;
  name?: string;
  lockbox_type?: LockboxType;
  lockbox_status?: LockboxStatus;
  employee_name?: string;
  ssn?: string;
  birth_date?: string;
  account_number?: string;
  account_id?: number;
  amount?: number;
  contact_id?: number;
  employee_id?: number;
  address?: string;
  request_date?: string;
  match_employees?: MatchedEmployeePayload[];
  ocr?: LockboxOcrPayload;
  check_details?: CheckDetailsPayload;
}

interface Props {
  lockboxId: string;
}

const useGetLockboxQuery = ({ lockboxId }: Props) => {
  const {
    data,
    isLoading,
    isError,
    isFetching,
  } = useQuery(
    [PATHS.LOCKBOX_BY_ID(lockboxId)], 
    () => api.get<LockboxPayload>(PATHS.LOCKBOX_BY_ID(lockboxId))
  );

  const formattedData = useMemo((): Lockbox => ({
    id: toString(data?.data?.id),
    accountId: toString(data?.data?.account_id),
    address: data?.data?.address,
    amount: toString(data?.data?.amount),
    birthDate: data?.data?.birth_date,
    contactId: toString(data?.data?.contact_id),
    requestDate: data?.data?.request_date,
    documentId: toString(data?.data?.document_id),
    employeeId: toString(data?.data?.employee_id),
    employeeName: data?.data?.employee_name,
    accountNumber: data?.data?.account_number,
    lockboxStatus: data?.data?.lockbox_status,
    lockboxType: data?.data?.lockbox_type,
    name: data?.data?.name,
    ssn: data?.data?.ssn,
    ocr: data?.data?.ocr ? {
      accountNumber: data?.data?.ocr?.account_number,
      employeeName: data?.data?.ocr?.employee_name,
      ssn: data?.data?.ocr?.ssn,
      birthDate: data?.data?.ocr?.birth_date,
      amount: toString(data?.data?.ocr?.amount),
      date: data?.data?.ocr?.date,
      address: {
        taxId: data?.data?.ocr?.address?.tax_id,
        addressLine1: data?.data?.ocr?.address?.address_line_1,
        addressLine2: data?.data?.ocr?.address?.address_line_2,
        city: data?.data?.ocr?.address?.city,
        state: data?.data?.ocr?.address?.state,
        zip: data?.data?.ocr?.address?.zip,
      },
    } : undefined,
    checkDetails: data?.data?.check_details ? {
      addressLine1: data?.data?.check_details?.address_line_1,
      addressLine2: data?.data?.check_details?.address_line_2,
      benefitOf: data?.data?.check_details?.benefit_of,
      checkMemo: data?.data?.check_details?.check_memo,
      checkName: data?.data?.check_details?.check_name,
      city: data?.data?.check_details?.city,
      id: toString(data?.data?.check_details?.id),
      state: data?.data?.check_details?.state,
      vendorId: toString(data?.data?.check_details?.vendor_id),
      vendorName: data?.data?.check_details?.vendor_name,
      zip: data?.data?.check_details?.zip,
    } : undefined,
    matchEmployees: data?.data?.match_employees?.map((matchedEmployee) => ({
      employeeId: toString(matchedEmployee?.employee_id),
      employeeName: matchedEmployee?.employee_name,
      ssn: matchedEmployee?.ssn,
      birthDate: matchedEmployee?.birth_date,
      employmentStatus: getFullStatusName(matchedEmployee?.employment_status as keyof typeof StatusNames),
      organizationId: toString(matchedEmployee?.organization_id),
      organizationName: matchedEmployee?.organization_name,
      accountId: toString(matchedEmployee?.account_id),
      accountNumber: matchedEmployee?.account_number,
      availableAmount: matchedEmployee?.available_amount,
      accountStatus: matchedEmployee?.account_status,
      investmentStatus: matchedEmployee?.investment_status,
      labelTypes: matchedEmployee?.label_types,
    }))
  }), [data]);

  return {
    formattedData,
    data,
    isLoading,
    isFetching,
    isError,
  };
};

export default useGetLockboxQuery;
