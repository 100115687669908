import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { RadioOption } from '@/common/types';

interface CheckWritingsTypeDto {
  id: string | number;
  name: string;
  description: string;
}

export default () => {
  const {
    isLoading, isSuccess, isError, data, error,
  } = useQuery('getCheckWritings', () => api.get(`${PATHS.CHECK_WRITINGS}`));

  const formatedData = useMemo<RadioOption[]>(() => {
    const list = data?.data || [];
    return list.map((listItem: CheckWritingsTypeDto) => ({
      label: listItem?.description,
      value: listItem?.name,
    }));
  }, [data]);

  return ({
    data: formatedData || [],
    error,
    isError,
    isLoading,
    isSuccess,
  });
};
