import React from 'react';
import { Box } from '@common-fe/common-fe';

import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { HEALTH_PLAN_MODULES } from '../../HealthPlan.constants';

import Additional from './Additional';
import HealthPlanName from './HealthPlanName';
// import { usePreviewStore } from '../stores';
import PlanYear from './PlanYear';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}

const BasicProperties: React.FC<Props> = ({ isEditMode, isTemplateMode, onDirty }) => (
// const previewMode = usePreviewStore((state) => state.previewMode);
  <ModuleWrapper
    testId="basic-properties-wrapper"
    anchorId={HEALTH_PLAN_MODULES.BASIC_PROPERTIES}
    title="Basic Properties"
    expandless
  >
    <>
      <HealthPlanName
        isEditMode={isEditMode}
        isTemplateMode={isTemplateMode}
        onDirty={onDirty}
      />
      {isTemplateMode ? null : (
        <Box data-testId="basic-properties-year" margin={{ top: 'spacing24' }}>
          <PlanYear
            onDirty={onDirty}
          />
        </Box>
      )}
      {isTemplateMode ? null : (
        <Box data-testId="additional-section" margin={{ top: 'spacing24' }}>
          <Additional
            isEditMode={isEditMode}
            onDirty={onDirty}
            isTemplateMode={isTemplateMode}
          />
        </Box>
      )}
    </>
  </ModuleWrapper>
);

export default BasicProperties;
