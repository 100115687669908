import { storeCreator } from '@/utils/store/store.utils';

interface State {
  saveSuccess: string[];
  saveErrors: string[];
  savePendigns: string[];
  setSaveSuccess: (values: string[]) => void;
  setSaveErrors: (values: string[]) => void;
  setSavePendings: (values: string[]) => void;

  activeSuccess: string[];
  activeErrors: string[];
  activePendigns: string[];
  setActiveSuccess: (values: string[]) => void;
  setActiveErrors: (values: string[]) => void;
  setActivePendings: (values: string[]) => void;

}

export const useStore = storeCreator<State>((set) => ({
  saveSuccess: [],
  saveErrors: [],
  savePendigns: [],
  setSaveSuccess: (values: string[]) => set(() => ({
    saveSuccess: values,
  })),
  setSaveErrors: (values: string[]) => set(() => ({
    saveErrors: values,
  })),
  setSavePendings: (values: string[]) => set(() => ({
    savePendigns: values,
  })),

  activeSuccess: [],
  activeErrors: [],
  activePendigns: [],
  setActiveSuccess: (values: string[]) => set(() => ({
    activeSuccess: values,
  })),
  setActiveErrors: (values: string[]) => set(() => ({
    activeErrors: values,
  })),
  setActivePendings: (values: string[]) => set(() => ({
    activePendigns: values,
  })),
}));
export default useStore;
