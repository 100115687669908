import React, { useEffect } from 'react';
import {
  Box, FlexControlledForm, useDebounceState,
} from '@common-fe/common-fe';

import { PreferredMailingAddressPayload,useStore } from './stores/usePreferredMailingAddress.store';
import { usePreferredMailingAddressFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  isShowErrors?: boolean;
}
const PreferredMailingAddress: React.FC<Props> = ({ isEditMode, isShowErrors }) => {
  const handleSetState = useStore((state) => state.setState);
  const fields = usePreferredMailingAddressFields(isEditMode);
  const { setValues, values } = useDebounceState<Partial<PreferredMailingAddressPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        formTitle="Preferred Mailing Address"
        testId="preferred-mailing-address"
        fields={fields}
        showError={isShowErrors}
        editMode
        onChangeValues={setValues}
        // onDirty={onDirty}
        isDisabledMode={!isEditMode}
        isThinMode
      />

    </Box>

  );
};

export default PreferredMailingAddress;
