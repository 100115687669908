import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  ClockInIcon,
  ClockOutIcon,
  CONTENT_ID_SELECTOR,
  Inscription,
  Preloader,
  SetupOutlinedIcon,
  Tip,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Roles } from '@/common/constants';
import Permissions from '@/common/permissions';
import routes from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHasAccess,useHistory } from '@/modules/core/hooks';
import { useContactInfoQuery } from '@/modules/user/queries';
import { useAuthStore } from '@/modules/user/stores';
import { CLAIMS_PROCESSORS_MANAGEMENT_REQUIREMENTS, useHasAccessForObservingOrganization } from '@/pages/ManageClaimsProcessors.page';
import globalTheme from '@/styles/theme';
import theme from '@/styles/theme';

import { setExtraKey } from './queries/useClaimsList.query';
import useClockInQuery from './queries/useClockIn.query';
import ClaimsFilterSection, { PageMode } from './ClaimsFilterSection';
import ClaimsList, { EXTENDED_HEADER_CLAIMS_SPACING, HEADER_CLAIMS_SPACING } from './ClaimsList';

const FixedWrapper = styled(Box)<{ isScrolled?: boolean }>`
  position: fixed;
  z-index: 1001;
`;
const Wrapper = styled(Box)`
  > div:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacings.spacing12};
  }
`;

const Claims = () => {
  const hasAccessToAssignClaim = useHasAccess([
    { role: Roles.superUser },
    { role: Roles.claimsSupervisor },
  ]);
  const hasAccessForObservingOrganization = useHasAccessForObservingOrganization();
  const { contactId } = useAuthStore();
  const { formattedData } = useContactInfoQuery(contactId);
  const [isClockIn, setIsClockIn] = useState(false);
  const updateClockIn = useClockInQuery();
  const [isClockInLoading, setIsClockInLoading] = useState(false);
  const handleUpdateClockIn = useCallback(async () => {
    setIsClockInLoading(true);
    await updateClockIn(!isClockIn);
  }, [isClockIn, updateClockIn]);
  useEffect(() => {
    if (formattedData.clockIn !== isClockIn) setIsClockIn(formattedData.clockIn);
  }, [formattedData, isClockIn, setIsClockIn]);
  useEffect(() => setIsClockInLoading(false), [isClockIn]);

  const hasAccessToOtherPages = useHasAccess([
    { role: Roles.superUser },
    { role: Roles.claimsProcessorReview },
    { role: Roles.claimsProcessorEdit },
    { role: Roles.claimsSupervisor },
  ]);
  const hasAccessToClockIn = useHasAccess([
    { role: Roles.claimsProcessorReview },
    { role: Roles.claimsProcessorEdit },
    { role: Roles.claimsSupervisor },
    { permission: Permissions.CLOCK_IN },
  ]);
  const [pageMode, setPageMode] = useState(hasAccessToOtherPages && hasAccessToClockIn ? PageMode.MY : PageMode.ALL);

  const [totalAll, setTotalAll] = useState<number | undefined>();
  const [totalMy, setTotalMy] = useState<number | undefined>();
  const [totalEscalated, setTotalEscalated] = useState<number | undefined>();

  const [isOnlyMyAssignmets, isShowOnlyEscalated, isAllClaims] = useMemo(() => ([
    pageMode === PageMode.MY,
    pageMode === PageMode.ESCALATED,
    pageMode === PageMode.ALL,
  ]), [pageMode]);
  useEffect(() => setExtraKey({ isOnlyMyAssignmets, isShowOnlyEscalated }), [isOnlyMyAssignmets, isShowOnlyEscalated]);

  const hasAccessToManageClaimsProcessors = useHasAccess(CLAIMS_PROCESSORS_MANAGEMENT_REQUIREMENTS);
  const [isScrolled, setIsScrolled] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])?.get('emptyClaimList');
  const searchQuery = useMemo(() => new URLSearchParams(location.search), [location.search])?.get('search');

  useEffect(() => {
    if (!isAllClaims && (searchQuery || query)) {
      setPageMode(PageMode.ALL);
    }
  }, [searchQuery, isAllClaims, query]);

  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);
    const updateScrolling = () => setIsScrolled(!!appContainer
      && appContainer.scrollTop > (hasAccessToAssignClaim
        ? EXTENDED_HEADER_CLAIMS_SPACING : HEADER_CLAIMS_SPACING) / 2);

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
  }, [hasAccessToAssignClaim]);

  const goToClaimsProcessorsPage = useCallback(() => history.push(routes.MANAGE_CLAIMS_PROCESSORS), [history]);

  return (
    <Box margin={{ bottom: '72px' }} align="center" width="100%" height="fit-content">
      <FixedWrapper background="canvas" width="100%">
        <Topbar hideHeader={isScrolled} />
        <Box
          width={globalTheme.defaultContentWidth}
          alignSelf="center"
          direction="row"
          justify="between"
          align="center"
          wrap
          height="54px"
          {...isScrolled ? {} : {
            margin: { top: 'spacing12' },
          }}
        >
          <Box direction="row" width="100%" align="center">
            <Inscription
              size={isScrolled ? '2xl' : '3xl'}
              weight="bold"
              color="textBody"
              lineHeight="40px"
            >Claims
            </Inscription>

            <Box flex direction="row" justify="end" align="center">
              {hasAccessToOtherPages ? (
                <Box
                  align="center"
                  direction="row"
                >
                  <ClaimsFilterSection
                    totalAll={query ? 0 : totalAll}
                    totalMy={totalMy}
                    totalEscalated={totalEscalated}
                    setPageMode={setPageMode}
                    mode={pageMode}
                    hasAccessToMyAssignments={hasAccessToClockIn}
                  />
                </Box>
              ) : null}

              {hasAccessToManageClaimsProcessors || hasAccessToClockIn ? (
                <Box
                  direction="row"
                  justify="end"
                  align="center"
                  height="40px"
                  margin={{ left: 'spacing24' }}
                >
                  {(hasAccessToManageClaimsProcessors && hasAccessForObservingOrganization) || hasAccessToClockIn ? (
                    <Wrapper direction="row">
                      {hasAccessToManageClaimsProcessors && hasAccessForObservingOrganization ? (
                        <Box
                          width="40px"
                          height="40px"
                          round="8px"
                          justify="center"
                          align="center"
                          style={{
                            border: `2px solid ${theme.colors.border}`,
                          }}
                          onClick={goToClaimsProcessorsPage}
                        >
                          <SetupOutlinedIcon color="iconPrimary" />
                        </Box>
                      ) : null}

                      {hasAccessToClockIn ? (
                        <Tip
                          plain
                          dropProps={{ align: { top: 'bottom' } }}
                          content={(
                            <Box pad={{ left: 'spacing12' }}>
                              <Box
                                width="max-content"
                                height={{ max: 'l' }}
                                align="center"
                                justify="center"
                                background="background3"
                                border={{ size: 'small', color: 'popup' }}
                                pad={{ horizontal: 'spacing6' }}
                                margin={{ top: 'spacing16' }}
                              >
                                <Inscription size="small" color="textOnColor">
                                  {isClockIn ? 'Clock out' : 'Clock in'}
                                </Inscription>
                              </Box>
                            </Box>
                          )}
                        >
                          <Box
                            width="40px"
                            height="40px"
                            round="8px"
                            justify="center"
                            align="center"
                            background={isClockIn ? 'buttonActive' : 'info'}
                            onClick={handleUpdateClockIn}
                          >
                            <Box width="24px" direction="row" height="24px" round align="center" justify="center">
                              {isClockIn && !isClockInLoading ? (
                                <ClockOutIcon color="canvas" />
                              ) : null}
                              {!isClockIn && !isClockInLoading ?  (
                                <ClockInIcon  color="canvas" />
                              ) : null}
                              {isClockInLoading ? <Preloader size={12} /> : null}
                            </Box>
                          </Box>
                        </Tip>
                      ) : null}
                    </Wrapper>
                  ) : null}

                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {isScrolled ? (
          <Box width="100%" border={{ color: 'border1', side: 'bottom', size: 'small' }} />
        ) : null}
      </FixedWrapper>

      {hasAccessToOtherPages ? (
        <>
          {hasAccessToClockIn ? (
            <Box key="OnlyMyAssignmets" {...isOnlyMyAssignmets ? {} : { style: { display: 'none' } }}>
              <ClaimsList
                isOnlyMyAssignmets
                hasAccessToAssignClaim={hasAccessToAssignClaim}
                setTotal={setTotalMy}
                isClockIn={isClockIn}
                hasAccessToClockIn={hasAccessToClockIn}
                pageMode={pageMode}
              />
            </Box>
          ) : null}

          <Box key="ExtendedMode" {...isShowOnlyEscalated ? {} : { style: { display: 'none' } }}>
            <ClaimsList
              isShowOnlyEscalated
              hasAccessToAssignClaim={hasAccessToAssignClaim}
              setTotal={setTotalEscalated}
              pageMode={pageMode}
            />
          </Box>
        </>
      ) : null}

      <Box key="All" {...isAllClaims ? {} : { style: { display: 'none' } }}>
        <ClaimsList
          hasAccessToAssignClaim={hasAccessToAssignClaim}
          setTotal={setTotalAll}
          pageMode={pageMode}
        />
      </Box>
    </Box>
  );
};

export default Claims;
