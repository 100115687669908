import React from 'react';
import {
  AppButton,
  Box,
  PendingModal,
} from '@common-fe/common-fe';

interface Props {
  handleActive?: () => void;
  isVisible: boolean;
  onSetVisible: () => void;
  planName?: string;
}
const PlanUpdateModal: React.FC<Props> = ({
  handleActive,
  isVisible,
  onSetVisible,
  planName,
}) => {
  return (
    <PendingModal
      visible={isVisible}
      header={`Update ${planName}?`}
      helptext="Changes made to this plan will update the plan for current and future participants in this plan."
      onSetVisible={onSetVisible}
    >
      <Box direction="row">
        <Box margin={{ right: 'spacing12' }}>
          <AppButton
            onClick={onSetVisible}
            width="184px"
            buttonType="secondary"
          >
            Review Changes
          </AppButton>
        </Box>
        <AppButton onClick={handleActive} width="192px">
          Yes, Save Changes
        </AppButton>
      </Box>
    </PendingModal>
  );
};
export default PlanUpdateModal;
