import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AppButton,
  Box,
  Heading,
  Inscription,
  Modal,
  Preloader,
  ReceiptVaultIcon,
  RequiredIcon,
  SelectDropdown,
  SuccessModal,
} from '@common-fe/common-fe';

import PATHS from '@/common/paths';
import { Option,OptionKey } from '@/common/types';
import { PaymentMethod, PaymentMethodOwnerType } from '@/modules/employee/employee.types';
import { getFullAddress } from '@/utils';

import { PaymentMethodFields } from './Checks.styled';
import { Check } from './constants';
import PaymentMethodOption from './PaymentMethodOption';
import { useGetPaymentMethods } from './useGetPaymentMethods.query';
import useReissueCheckQuery from './useReissueCheck.query';

interface Props {
  isVisible: boolean;
  close: () => void;
  check: Check;
  refetch?: () => void;
  okButtonText?: string;
}

const ReissueCheckPopup: React.FC<Props> = ({
  check,
  isVisible,
  close,
  refetch,
  okButtonText,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const { formattedData: paymentMethods, isLoading: areMethodsLoading } = useGetPaymentMethods(isVisible ? check.employeeId : '');
  const ownerType = useMemo(() => paymentMethods.find((method) => method.id === check.paymentMethodId)?.paymentMethodOwner?.ownerType, [paymentMethods, check]);
  const isProvider = useMemo(() => ownerType === PaymentMethodOwnerType.PROVIDER, [ownerType]);
  const filteredPaymentMethods = useMemo(() => paymentMethods.filter((method) => method.paymentMethodOwner?.ownerType === ownerType), [paymentMethods, ownerType]);
  const paymentMethodsOptions = useMemo<Option[]>(() => filteredPaymentMethods.map((method) => ({
    key: method.id,
    value: method.id,
    node: <PaymentMethodOption method={method} />,
  } as Option)), [filteredPaymentMethods]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<OptionKey>('');
  useEffect(() => {
    if (!filteredPaymentMethods?.length) return;

    if (!selectedPaymentMethodId) {
      const checkedPaymentMethod = filteredPaymentMethods.find((method) => method.id === check.paymentMethodId);
      setPaymentMethod(checkedPaymentMethod);
      setSelectedPaymentMethodId(checkedPaymentMethod?.id);
    }

    if (selectedPaymentMethodId) {
      setPaymentMethod(filteredPaymentMethods.find((method) => method.id === selectedPaymentMethodId));
    }
  }, [filteredPaymentMethods, setPaymentMethod, selectedPaymentMethodId, setSelectedPaymentMethodId, check]);
  const paymentMethodFullAddress = useMemo(() => getFullAddress(paymentMethod?.address), [paymentMethod]);
  const queryClient = useQueryClient();
  const [isVoidedSuccessfully, setIsReissuedSuccessfully] = useState(false);
  const onSuccess = useCallback(() => {
    setIsReissuedSuccessfully(true);
    close();
  }, [close, setIsReissuedSuccessfully]);
  const { mutateAsync: reissueCheck, isLoading } = useReissueCheckQuery(onSuccess, check.id);
  useEffect(() => setSelectedPaymentMethodId(''), [isVisible, setSelectedPaymentMethodId]);

  if (isVoidedSuccessfully) {
    return (
      <SuccessModal
        testId="success_reissued_check"
        visible
        header="Check Was Successfully Reissued"
        buttonText={okButtonText || 'Go Back To Checks'}
        onSetVisible={() => {
          setIsReissuedSuccessfully(false);
          setSelectedPaymentMethodId('');
          if (refetch) {
            refetch();
          } else {
            queryClient.invalidateQueries(PATHS.GET_CHECKS_LIST);
          }
        }}
      />
    );
  }

  return (
    <Modal
      testId="Reissue_check"
      visible={isVisible}
      wrapperStyle={{ zIndex: 2002 }}
      onSetVisible={close}
    >
      <Heading level="4" fill textAlign="center">Reissue Payment</Heading>
      <Inscription textAlign="center" lineHeight="22px" size="large" margin={{ top: 'spacing8' }}>
        Reissuing a payment will void the original check prior to issuing the new payment. The new payment will be sent in 1-3 business days.
      </Inscription>
      <Box
        margin={{ vertical: 'spacing24' }}
        round="medium"
        pad="spacing24"
        background="module"
      >
        <PaymentMethodFields
          style={{ boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.03)' }}
          pad="spacing24"
          background="canvas"
          round="small"
          border={{ color: 'border2' }}
        >
          {isProvider ? null : (
            <Box
              direction="row"
              align="center"
              justify="between"
            >
              <Box width={{ min: '244px' }}>
                <Box direction="row" margin={{ bottom: 'spacing4' }}>
                  <RequiredIcon color="danger" size="8px" style={{ marginRight: '2px', marginTop: '4px' }} />
                  <Inscription weight="bold" lineHeight="20px" color="textTitle">Payment method</Inscription>
                </Box>
                <Inscription color="textSecondary" weight={500} lineHeight="18px" size="small">
                  Select an alternative payment method previously created by the employee.
                </Inscription>
              </Box>
              <Box fill pad={{ left: 'spacing12' }}>
                {areMethodsLoading ? <Preloader /> : (
                  <SelectDropdown
                    name="payment_method"
                    testId="payment_method"
                    id="payment_method"
                    options={paymentMethodsOptions}
                    singleMode
                    onChange={setSelectedPaymentMethodId}
                    value={selectedPaymentMethodId}
                    placeholder="Select payment method"
                  />
                )}
              </Box>
            </Box>
          )}

          {paymentMethodFullAddress ? (
            <Box
              direction="row"
              align="center"
              justify="between"
              height={{ min: '40px' }}
            >
              <Box width={{ min: '244px' }}>
                <Box direction="row" margin={{ bottom: 'spacing2' }}>
                  <Inscription weight="bold" lineHeight="20px" color="textTitle">Address</Inscription>
                </Box>
              </Box>
              <Box fill pad={{ left: 'spacing12' }} direction="row" align="center">
                <Box width={{ min: '40px' }} height={{ min: '40px' }} border={{ color: 'border1' }} round="8px" justify="center" align="center">
                  <ReceiptVaultIcon color="info" />
                </Box>
                <Box margin={{ left: 'spacing12' }}>
                  <Inscription color="textBody" lineHeight="22px" size="large">{paymentMethodFullAddress}</Inscription>
                </Box>
              </Box>
            </Box>
          ) : null}
        </PaymentMethodFields>
      </Box>
      <Box direction="row" justify="end">
        <AppButton
          buttonType="primary"
          color="blue"
          onClick={close}
          width="133px"
        >
          Cancel
        </AppButton>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            buttonType="primary"
            color="red"
            width="133px"
            disabled={isLoading || areMethodsLoading}
            {...isLoading || areMethodsLoading ? {} : {
              onClick: () => reissueCheck(selectedPaymentMethodId as string),
            }}
          >
            {isLoading || areMethodsLoading ? <Preloader /> : 'Reissue'}
          </AppButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReissueCheckPopup;
