import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { RoleModel } from '@/modules/employer/types/Contact.types';

export const PERMISSIONS_LIST_QUERY_KEY = 'permissionsList';

export const usePermissionsListQuery = () => useQuery(
  PERMISSIONS_LIST_QUERY_KEY,
  () => api.get<RoleModel[]>(PATHS.PERMISSIONS),
);
