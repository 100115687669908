import { baseStoreCreator } from '@/utils/store';

export interface CommonNotificationsPayload {
  notificationsMethod: string;
}

export const DEFAULT_VALUES: CommonNotificationsPayload = {
  notificationsMethod: '',
};

export const useStore = baseStoreCreator<CommonNotificationsPayload>(DEFAULT_VALUES);

export default useStore;
