// /payout-definitions
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';

interface PayoutDefinitionPlanResponse {
    id: number;
    name: string;
}

export const QUERY_KEY = 'GET_PAYOUT_DEFINITIONS_PLAN';
export default (
  id?: string,
) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEY, id],
    id
      ? () => api.get<PayoutDefinitionPlanResponse[]>(PATHS.PAYOUT_DEFINITION_PLANS(id))
      : () => null);
  const planOptions = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      value: `${item.id}`,
      title: item.name,
    }));
  }, [data]);

  return {
    isLoading,
    planOptions,
  };
};
