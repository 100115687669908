import _ from 'lodash';

// import { ONE_HUNDRED_PERCENT } from '@/common/constants';
import { ContributionState, PlanResponse } from '../../plan.types';
import { OPEN_SYMBOL } from '../PlanDefinition/CardOffered/FundFormula/FormulaDragAndDrop/FormulaDragAndDrop';
import { FUND_FORMULA_RADIO_OPTIONS } from '../PlanDefinition/CardOffered/FundFormula/hooks/useFundFormulaFields';

const getBooleanToString = (value?: boolean) => {
  if (value === true) {
    return 'yes';
  }
  if (value === false) {
    return 'no';
  }
  return '';
};

// const fromNumberToPercent = (value?: number | null) => value && value * ONE_HUNDRED_PERCENT;

const NONE_KEY = 'NONE';

const CLAIM_KEY = 'ALLOW_PRIOR_YEAR_CLAIMS';
export const PLAN_YEAR_END_DATE_KEY = 'PLAN_YEAR_END_DATE';
export const COVERAGE_END_DATE_KEY = 'COVERAGE_END_DATE';

const getValuePossibleZero = (value?: string | number) => {
  if (value === 0) {
    return '0';
  }
  return `${value || ''}`;
};

const getFormattedPlan = (plan?: PlanResponse) => {
  if (!plan) {
    return {};
  }
  const primary = plan?.plan_primary_config;
  const coverage = plan?.plan_coverage_config;
  const accountFunding = plan?.plan_account_funding_config;
  const isRollover = accountFunding?.is_rollover?.is_rollover;
  const autoEnrollment = accountFunding?.is_auto_enrollment?.is_auto_enrollment;
  const daysAfterPlanEnd = accountFunding?.days_after_plan_end?.days_after_plan_end;
  const maxElectionAmount = primary.max_election_amount_type?.max_election_amount;
  const minElectionAmount = primary.min_election_amount_type?.min_election_amount;
  const catchUpAge = primary.catch_up_age?.catch_up_age;
  const catchUpElections = primary.catch_up_election?.catch_up_election;
  const investmentThreshold = primary.investment_threshold?.investment_threshold;

  const minimumInvestmentAmount = primary.minimum_investment_amount?.minimum_investment_amount;
  const allowInvestments = primary.allow_investments?.allow_investments;
  const CMSReportingRequired = primary.cms_reporting_required?.cms_reporting_required;
  const fundFormulaId = primary.fund_id_formula?.fund_id_formula;

  const getContributionConfig = () => {
    let isDefaultApplied = false;
    const allowContributors = accountFunding
      ?.allow_contribution_config?.type as ContributionState;

    const employeeData = accountFunding
      ?.allow_contribution_config?.employee;
    const employerData = accountFunding
      ?.allow_contribution_config?.employer;
    // const config = accountFunding?.contribution_config?.config || {};

    if ((!allowContributors) && !plan.parent_id) {
      isDefaultApplied = true;
    }
    const result = {
      allowContributors: isDefaultApplied
        ? ContributionState.EMPLOYER_AND_EMPLOYEE
        : allowContributors,

      employeePostTax: employeeData?.is_post_tax,
      employeePostTaxValue: employeeData?.post_tax_memo,
      employeePreTax: isDefaultApplied ? true : employeeData?.is_pre_tax,
      employeePreTaxValue: employeeData?.pre_tax_memo,

      employerPreTax: isDefaultApplied ? true : employerData?.is_pre_tax,
      employerPreTaxValue: employerData?.pre_tax_memo,
      employerPostTax: employerData?.is_post_tax,
      employerPostTaxValue: employerData?.post_tax_memo,
    };
    return result;
  };

  const claimsDeadline = coverage
    .claims_deadline_end_of_coverage_type
    ?.claims_deadline_end_of_coverage_type;
  const claimsDeadlineAmount = coverage
    .claims_deadline_end_of_coverage_type
    ?.claims_deadline_end_of_coverage_days_amount;
  const isCarded = primary.is_carded?.is_carded;
  const isOrphaned = primary.is_orphaned?.is_orphaned;

  return {
    planSummary: plan?.summary || '',
    planTemplate: `${plan.parent_id || ''}`,
    planName: plan.name.name,
    planStatus: plan.plan_status,
    planType: plan.account_type?.account_type,
    planCode: plan?.plan_code,
    priorPlanId: `${plan?.prior_plan_id || ''}`,
    priorPlan: plan.prior_plan,
    planYear: `${plan.plan_year_id || ''}`,
    priorPlanYearId: `${plan?.plan_year?.prior_plan_year_id || ''}`,
    spendLimit: `${primary.spend_limit_type?.spend_limit_type || ''}`,
    spendPeriod: primary.spend_period_type?.spend_period_type,
    spendLimitIRSAmount: primary.spend_limit_type?.irs_limit_type,
    spendLimitCustom: `${primary.spend_limit_type?.spend_limit || ''}`,
    availableBalance:
      primary?.available_balance_type?.available_balance_type,
    maximumElectionAmount: primary.max_election_amount_type?.max_election_amount_type,
    maximumElectionAmountCustom: _.toString(maxElectionAmount),
    minimumElectionAmount: primary.min_election_amount_type?.min_election_amount_type,
    minimumElectionAmountCustom: _.toString(minElectionAmount),
    maximumElectionAmountIRS: primary.max_election_amount_type?.irs_limit_type,
    CIPRequired: primary.is_cip_required?.is_cip_required,
    CMSReportingRequired: `${CMSReportingRequired === false ? false : CMSReportingRequired || ''}`,
    pendContributionsForCIP: primary.pend_contributions_for_cip?.pend_contributions_for_cip,
    proofOfExpense: primary.proof_of_expenses_type?.proof_of_expenses_type,
    cardOffered: `${isCarded === undefined || isCarded === null ? '' : isCarded}`,

    isOrphaned: `${isOrphaned === true || isOrphaned === false ? isOrphaned : ''}`,
    generateStatements: getBooleanToString(primary
      .generate_statements?.generate_statements),
    isReplenished: primary.is_replenished?.is_replenished,
    omnibus: primary.omnibus_type?.omnibus_type,
    accountCreation: accountFunding?.account_creation?.account_creation_type,
    fundID: primary.fund_id?.fund_id,
    fundIDFormula: fundFormulaId,
    fundIdFormulaOptions: fundFormulaId?.includes(OPEN_SYMBOL)
      ? FUND_FORMULA_RADIO_OPTIONS.CUSTOM_FORMULA : fundFormulaId
        ? FUND_FORMULA_RADIO_OPTIONS.NO_FORMULA : '',
    gracePeriod: coverage?.grace_period_type?.grace_period_type,
    gracePeriodAmount: getValuePossibleZero(coverage?.grace_period_type?.grace_period_days_amount),

    runOut: coverage?.run_out_type?.run_out_type,
    runOutAmount: getValuePossibleZero(coverage?.run_out_type?.run_out_days_amount),

    aiAdjudicationType: coverage?.ai_adjudication_type?.ai_adjudication_type,
    aiAdjudicationTypeState: coverage?.ai_adjudication_type?.ai_adjudication_type_state,

    rollover: isRollover === undefined ? true : isRollover,

    maximumRolloverAmount: accountFunding?.max_rollover_amount?.max_rollover_amount_type,
    maximumRolloverAmountCustom: getValuePossibleZero(
      accountFunding?.max_rollover_amount?.max_rollover_amount,
    ),

    minimumRolloverAmount: accountFunding?.min_rollover_amount?.min_rollover_amount_type,
    minimumRolloverAmountCustom: getValuePossibleZero(
      accountFunding?.min_rollover_amount?.min_rollover_amount,
    ),
    // autoEnrollment === true ? 'yes' : autoEnrollment === false ? 'no' : ''
    autoEnrollment: getBooleanToString(autoEnrollment),
    daysAfterPlanEnd: `${daysAfterPlanEnd || ''}`,
    rolloverClaims: accountFunding?.rollover_claims?.rollover_claims_type === CLAIM_KEY,
    claimsPriorYear: getValuePossibleZero(
      accountFunding?.rollover_claims?.rollover_claims_days_amount,
    ),
    allowInvestments: `${allowInvestments === false ? false : allowInvestments || ''}`,
    catchUpElections: _.toString(catchUpElections),
    catchUpAge: _.toString(catchUpAge),
    investmentOptionsType: primary.investment_options_type?.investment_options_types || [],
    investmentThreshold: _.toString(investmentThreshold),
    minimumInvestmentAmount: _.toString(minimumInvestmentAmount),
    maximumRolloverAmountIRS: accountFunding.max_rollover_amount?.max_rollover_irs_limit_type,
    endOfCoverage: coverage?.end_of_coverage_type?.end_of_coverage_type,
    endOfCoverageAmount: getValuePossibleZero(
      coverage?.end_of_coverage_type?.end_of_coverage_days_amount,
    ),
    interestTemplate: `${accountFunding.interest_template_id?.interest_template_id || NONE_KEY}`,
    claimsDeadline,

    claimsDeadlinePlanYearAmount: claimsDeadline === PLAN_YEAR_END_DATE_KEY
      ? getValuePossibleZero(claimsDeadlineAmount)
      : '',
    claimsDeadlineCoverageDateAmount: claimsDeadline === COVERAGE_END_DATE_KEY
      ? getValuePossibleZero(claimsDeadlineAmount) : '',
    // isPayClaimsAbsence: getBooleanToString(
    //   coverage?.is_pay_claims_absence?.is_pay_claims_absence,
    // ),
    isPayClaimsOutsideCoverage: getBooleanToString(
      coverage?.is_pay_claims_outside_coverage?.is_pay_claims_outside_coverage,
    ),
    ...getContributionConfig(),

    allowContributionOverMaximum: accountFunding
      ?.is_allow_contributions_over_election_amount
      ?.is_allow_contributions_over_election_amount,
    pendContribution: accountFunding
      ?.is_pend_contributions_over_irs
      ?.is_pend_contributions_over_the_irs_max,

    planLevelFunding: accountFunding.is_plan_level_funding?.is_plan_level_funding,
    replenishmentBankAccount: `${accountFunding.replenishment_bank_account_id || ''}`,
    replenishmentMethod: accountFunding.replenishment_method,
    replenishmentFrequency: accountFunding.replenishment_frequency,
    replenishmentDayNumber: `${accountFunding.replenishment_day_number || ''}`,
    contributionBankAccount: `${accountFunding.contribution_bank_account_id || ''}`,
    contributionMethod: accountFunding.contribution_method,
    contributionHoldPeriod: `${accountFunding.contribution_hold_period || ''}`,

    isPreFundCalculated: accountFunding.is_custom_prefund_calc?.is_custom_prefund_calc,
    replenishmentCreepMin: `${accountFunding.prefund_replenishment_creep_min?.prefund_replenishment_creep_min || ''}`,
    replenishmentCreepPercent:
      accountFunding.prefund_replenishment_creep_percent?.prefund_replenishment_creep_percent,

    replenishmentMinValue: `${accountFunding.prefund_replenishment_min_value?.prefund_replenishment_min_value || ''}`,
    replenishmentPercent:
      accountFunding.prefund_replenishment_percent?.prefund_replenishment_percent,

    allowIndividualContributions: getBooleanToString(accountFunding
      .allow_individual_contributions?.allow_individual_contributions),
    сontributionMonitoringRequired: getBooleanToString(accountFunding
      .contribution_monitoring_required?.contribution_monitoring_required),
    minimumContributionAmount: getValuePossibleZero(
      accountFunding?.minimum_contribution_amount?.minimum_contribution_amount,
    ),
  };
};

export default getFormattedPlan;
