import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

// import { useCopayGroupsQuery } from '@/modules/employer/components/CopayGroup/queries';
import { VALIDATORS } from '@/common';
import {
  DefaultFields,
} from '@/common/types';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { usePlanYearsQuery, usePriorPlanYearQuery } from '@/modules/plan/PlanYearForm/queries';

import { usePlanYearStore } from '../stores';

const PRIOR_PLAN_YEAR_ID_KEY = 'priorPlanYearId';

const REQUIRED_TEXT = 'This field is required';
// const NONE_KEY = 'none';
export default (disabled?: boolean, isTemplateMode?: boolean) => {
  const { list: planYearsOptions } = usePlanYearsQuery();
  const defaultFields = usePlanYearStore((state) => state.sourceState);
  const { organizationId } = useCurrentOrganisationStore();
  const { data: priorPlanYearList } = usePriorPlanYearQuery(
    defaultFields?.planStartDate, _.toNumber(organizationId),
  );
  // const { data: copayGroups } = useCopayGroupsQuery();
  // const formatedCopayGroups = useMemo(() => {
  //   const result: Option[] = [{
  //     key: NONE_KEY,
  //     value: NONE_KEY,
  //     title: 'None',
  //     textStyle: {
  //       fontWeight: 'bold',
  //     },
  //   }];
  //   copayGroups.forEach((item) => {
  //     result.push({
  //       key: `${item.id}`,
  //       value: `${item.id}`,
  //       title: item.templateName,
  //     });
  //   });
  //   return result;
  // },
  // [copayGroups]);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'planYear',
      showRequireIcon: true,
      singleMode: true,
      type: FieldTypes.Dropdown,
      disabled,
      label: 'Plan year',
      defaultValue: '1',
      options: planYearsOptions,
      placeholder: 'Select a plan year',
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },
    {
      name: 'planStartDate',
      type: FieldTypes.Date,
      isManualDateInput: true,
      disabled: true,
      showRequireIcon: true,
      isWide: true,
      label: 'Plan start date',

      placeholder: 'Select Plan start date',
      validator: VALIDATORS.DATE_FORMAT.required(REQUIRED_TEXT),
    },
    {
      name: 'planEndDate',
      disabled: true,
      type: FieldTypes.Date,
      isManualDateInput: true,
      isWide: true,
      label: 'Plan end date',
      placeholder: 'Select Plan end date (optional)',
      validator: VALIDATORS.DATE_FORMAT,
    },
    // {
    //   name: 'copayGroup',
    //   type: FieldTypes.Dropdown,
    //   label: 'Copay group',
    //   singleMode: true,
    //   disabled,
    //   defaultValue: disabled && !defaultFields?.copayGroup ? NONE_KEY : undefined,
    //   options: formatedCopayGroups,
    //   placeholder: 'Select copay group',
    //   validator: yup.string().trim(),

    // },
    {
      name: PRIOR_PLAN_YEAR_ID_KEY,
      type: FieldTypes.Dropdown,
      label: 'Prior plan year',
      disabled,
      options: priorPlanYearList,
      singleMode: true,
      placeholder: 'Select prior plan year',
      validator: yup.string().trim(),
    },
  ], [disabled,
    planYearsOptions,
    priorPlanYearList,
    // formatedCopayGroups,
  ]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);

  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode);
  return stateFields;
};
