import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ClaimAutopayType, DefaultClaimPayeeType } from '@/modules/employee/employee.types';
import { EMPLOYEE_QUERY_KEY } from '@/modules/employee/Employee/queries/useGetEmployeeById.query';

import { EmployeeClaimAutopayPayload } from '../stores/useEmployeeClaimAutopay.store';

interface EmployeeClaimAutopayServerPayload {
  claim_autopay_type?: ClaimAutopayType;
  default_claim_payee_type?: DefaultClaimPayeeType;
}

const useUpdateEmployeeAutopayPropertiesQuery = (id: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (value: EmployeeClaimAutopayPayload) => api.patch<EmployeeClaimAutopayServerPayload>(
      PATHS.CLAIM_CONFIG_UPDATE(id),
      {
        ...value.claimAutopayType ? {
          claim_autopay_type: value.claimAutopayType,
        } : {},
        ...value.defaultClaimPayeeType ? {
          default_claim_payee_type: value.defaultClaimPayeeType,
        } : {},
      },
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EMPLOYEE_QUERY_KEY, id] });
      },
    },
  );
  return {
    updateEmployeeAutopayProperties: mutateAsync,
    isLoading,
  };
};

export default useUpdateEmployeeAutopayPropertiesQuery;
