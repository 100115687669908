import { useMemo } from 'react';

import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { PlanStatuses } from '../../plan.types';
import { usePlanListQuery } from '../queries';

export default (searchValue: string) => {
  const {
    data: draftPlans,
    formattedArrayPlans: listDraftPlans,
    isLoading: isDraftPlansLoading,
    error: draftError,
    refetch: draftPlansRefetch,
  } = usePlanListQuery(null, PlanStatuses.DRAFT, searchValue);
  const {
    data: activePlans,
    formattedArrayPlans: listActivePlans,
    isLoading: isActivePlansLoading,
    error: activeError,
    refetch: activePlansRefetch,
  } = usePlanListQuery(null, PlanStatuses.ACTIVE, searchValue);
  const {
    data: readyForSetupPlans,
    formattedArrayPlans: listReadyForSetupPlans,
    isLoading: isReadyForSetupPlansLoading,
    error: readyForSetupError,
    refetch: readyForSetupPlansRefetch,
  } = usePlanListQuery(null, PlanStatuses.READY_FOR_SETUP, searchValue);

  const isLoading = useMemo(
    () => isDraftPlansLoading
    || isActivePlansLoading
    || isReadyForSetupPlansLoading,
    [
      isActivePlansLoading,
      isDraftPlansLoading,
      isReadyForSetupPlansLoading],
  );

  const count = useMemo(
    () => listActivePlans.length
    + listDraftPlans.length + listReadyForSetupPlans.length,
    [listActivePlans.length, listDraftPlans.length, listReadyForSetupPlans.length],
  );
  const currentError = useMemo(
    () => draftError
    || activeError
    || readyForSetupError,
    [
      draftError,
      activeError,
      readyForSetupError,
    ],
  );
  const currentRefetch = useMemo(() => {
    if (draftError) return draftPlansRefetch;
    if (activeError) return activePlansRefetch;
    return readyForSetupPlansRefetch;
  }, [
    activeError,
    activePlansRefetch,
    draftError,
    draftPlansRefetch,
    readyForSetupPlansRefetch]);

  const { isServerError, setIsServerError } = useIsServerError(currentError);
  return {
    count,
    isLoading,
    isServerError,
    currentRefetch,
    setIsServerError,
    readyForSetupPlans,
    activePlans,
    draftPlans,
    listActivePlans,
    listDraftPlans,
    listReadyForSetupPlans,
    isDraftPlansLoading,
    isActivePlansLoading,
    isReadyForSetupPlansLoading,
  };
};
