const TRANSACTION = 'transaction';
const PROCESSING_DEFINITION = '/processing-definition';
const HEALTH_PLAN_TEMPLATES = '/health-plan-templates';
const HEALTH_PLANS = '/health-plans';
export const EXPRESS_SETUPS = '/express-setups';

export default {

  ACCOUNT_ACTIVITY_TRANSACTIONS_VIEW: '/reconciliation-reports/:id/transactions',
  ACCOUNT_ACTIVITY_TRANSACTIONS: (id: string) => `/reconciliation-reports/${id}/transactions`,
  BROKERS: '/brokers',
  CARD_DETAILS: '/card-details',
  CARDS: '/cards',
  CHANGE_PASSWORD: '/change-password',
  CLAIMS_PROCESSOR: '/claims-process/:id',
  CLAIM_PROCESS: '/claims-process',
  CLAIMS: '/claims',
  MANAGE_CLAIMS_PROCESSORS: '/manage-claims-processors',
  COPAY_ADJUDICATION: '/copay-adjudication',
  CARRIER_CONFIG: '/carrier-config',
  EMPLOYEE_VIEW: (id?: string) => `/employee/${id || ':id'}`,
  EMPLOYEE_EDIT_VIEW: (id?: string) => `/employee/${id || ':id'}/edit`,
  EMPLOYEE: '/employee',
  SPENDS_BY_ID: (employeeId?: string | number, userId?: string | number) => `/spends/${employeeId || ':employeeId'}/${userId || ':userId'}`,
  ID_SCAN: (employeeId?: string) => `/id-scan/${employeeId || ':id'}`,
  EMPLOYEES: '/employees',
  TRANSACTION_DETAILS_POSTING: (employeeId?: string, postingId?: string | number) => `/employee/${employeeId || ':id'}/${TRANSACTION}/${postingId || ':postingId'}/posting/`,
  TRANSACTION_DETAILS_CLAIM: (employeeId?: string, claimId?: string | number) => `/employee/${employeeId || ':id'}/${TRANSACTION}/${claimId || ':claimId'}/claim/`,
  TRANSACTION_ENROLLMENT_DETAILS_POSTING: (employeeId?: string, enrollmentId?: string, postingId?: string | number) => `/employee/${employeeId || ':id'}/enrollment/${enrollmentId || ':enrollmentId'}/${TRANSACTION}/${postingId || ':postingId'}/posting/`,
  TRANSACTION_ENROLLMENT_DETAILS_CLAIM: (employeeId?: string, enrollmentId?: string, claimId?: string | number) => `/employee/${employeeId || ':id'}/enrollment/${enrollmentId || ':enrollmentId'}/${TRANSACTION}/${claimId || ':claimId'}/claim/`,
  ENROLLMENT_DETAILS: (employeeId?: string, enrollmentId?: string | number) => `/employee/${employeeId || ':id'}/enrollment/${enrollmentId || ':enrollmentId'}`,
  REIMBURSE_ME: (employeeId?: string) => `/employee/${employeeId || ':id'}/reimburse-me`,
  EMPLOYER_SETUP_TEXT: '/employer-setup-test',
  EMPLOYER_SETUP: '/employer-setup',
  EMPLOYERS_CREATE: '/employers/create',
  EMPLOYERS_EDIT: (id?: string) => `/employers/${id || ':id'}/edit`,
  EMPLOYERS_VIEW: '/employers/:id',
  EXPRESS_SETUPS,
  EXPRESS_SETUP_VIEW: (id?: string) => `${EXPRESS_SETUPS}/${id || ':id'}`,
  EXPRESS_SETUP_WIZARD: `${EXPRESS_SETUPS}/wizard/:id`,
  EXPRESS_SETUP_ACTIVATE: `${EXPRESS_SETUPS}/activate/:id`,
  EXPRESS_SETUP_ACTIVITY_LOGS: `${EXPRESS_SETUPS}/activity-logs/:id`,
  EXPRESS_SETUP_BY_ID: (id?: string) => `${EXPRESS_SETUPS}/${id}`,
  EXPRESS_SETUPS_WIZARD_BY_ID: (id?: string) => `${EXPRESS_SETUPS}/wizard/${id}`,
  EXPRESS_SETUPS_ACTIVATE_BY_ID: (id?: string) => `${EXPRESS_SETUPS}/activate/${id}`,
  EXPRESS_SETUPS_ACTIVITY_LOGS_BY_ID: (id?: string) => `${EXPRESS_SETUPS}/activity-logs/${id}`,
  EMPLOYERS: '/employers',
  ERROR: '/error',
  FILE_MANAGER: '/file-manager',
  FILE_SUMMARY: '/file-summary/:id',
  FILE_DETAILS: '/file-summary',
  HELP: '/help',
  HOME: '/',
  INVOICES: '/invoices',
  LOGIN: '/login',
  PLAN_BY_ID: (id: string) => `/plans/${id}`,
  PLAN_SETUP_BY_ID: (id: string) => `/plans/${id}/setup`,
  PLAN_SETUP: '/plans/:id/setup',
  NEW_PLAN: '/plans/:id',
  PLANS: '/plans',

  TEMPLATE_BY_ID: (id: string) => `/templates/${id}`,
  TEMPLATE_SETUP_BY_ID: (id: string) => `/templates/${id}/setup`,
  TEMPLATE: '/templates/:id',
  TEMPLATE_SETUP: '/templates/:id/setup',
  TEMPLATES: '/plans',
  TEMPLATE_VIEW: '/templates',

  HEALTH_PLANS,
  HEALTH_PLAN_HUB: '/health-plan-hub',
  HEALTH_PLAN_TEMPLATES,

  HEALTH_PLAN_TEMPLATE_BY_ID: (id?: string) => `${HEALTH_PLAN_TEMPLATES}/${id || ':id'}`,
  HEALTH_PLAN_TEMPLATE_SETUP_BY_ID: (id?: string) => `${HEALTH_PLAN_TEMPLATES}/${id || ':id'}/setup`,
  HEALTH_PLAN_BY_ID: (id?: string) => `${HEALTH_PLANS}/${id || ':id'}`,
  HEALTH_PLAN_SETUP_BY_ID: (id?: string) => `${HEALTH_PLANS}/${id || ':id'}/setup`,

  HEALTH_PLAN_TEMPLATE: `${HEALTH_PLAN_TEMPLATES}/:id`,
  HEALTH_PLAN_TEMPLATE_SETUP: `${HEALTH_PLAN_TEMPLATES}/:id/setup`,
  HEALTH_PLAN: `${HEALTH_PLANS}/:id`,
  HEALTH_PLAN_SETUP: `${HEALTH_PLANS}/:id/setup`,
  HEALTH_PLAN_VIEW: `${HEALTH_PLAN_TEMPLATES}/:id`,

  NOT_FOUND: '/404',
  PARTNERS_CREATE: '/partners/create',
  PARTNERS_EDIT: (id?: string) => `/partners/${id || ':id'}/edit`,
  PARTNERS_VIEW: (id?: string) => `/partners/${id || ':id'}`,
  PARTNERS: '/partners',
  DISTRIBUTORS_CREATE: '/distributors/create',
  DISTRIBUTORS: '/distributors',
  DISTRIBUTORS_EDIT: (id?: string) => `/distributors/${id || ':id'}/edit`,
  DISTRIBUTORS_VIEW: (id?: string) => `/distributors/${id || ':id'}`,
  SUBSIDIARY_CREATE: '/subsidiaries/create',
  SUBSIDIARY_EDIT: (id?: string) => `/subsidiaries/${id || ':id'}/edit`,
  SUBSIDIARY_VIEW: (id?: string) => `/subsidiaries/${id || ':id'}`,
  PROCESSING_DEFINITION: `${PROCESSING_DEFINITION}`,
  PROCESSING_DEFINITION_DETAILS: (id?: string) => `${PROCESSING_DEFINITION}/${id || ':id'}`,
  ENROLLMENT_MATRIX: `${PROCESSING_DEFINITION}/enrollment-matrix`,
  PROCESSING_RULES: `${PROCESSING_DEFINITION}/processing-rules`,
  PAYOUT_DEFINITIONS: `${PROCESSING_DEFINITION}/payout-definitions`,
  PLANS_VIEW: '/plans/:id',

  RECONCILIATION_REPORT_DETAILS: (id: string) => `/reconciliation-reports/${id}`,
  RECONCILIATION_REPORT_VIEW: '/reconciliation-reports/:id',
  FINOPS: '/finops',
  FINOPS_LOCKBOXES: '/finops#lockbox',
  FINOPS_PREFUND_VIEW: (id?: string) => `/finops/${id || ':id'}`,
  RECONCILIATION_REPORT: '/reconciliation-reports',
  RENEWALS: '/renewals',
  LOCKBOX_DETAILS: (lockboxId?: string) => `/finops/lockbox/lockbox-details/${lockboxId || ':id'}`,
  REPORTS: '/reports',
  SETUP_BROKERS: '/setup-brokers',
  SETUP_DISTRIBUTIONS: '/setup-distributions',
  SETUP_HELTHPLANS: '/setup-healthplans',
  SETUP_SUBGROUPS: '/setup-subgroups',
  SETUP_SUBSIDIARIES: '/setup-subsidiaries',
  SUBGROUPS: '/subgroups',
  SUBSIDIARIES: '/subsidiaries',
  SYSTEM_EDIT: '/system/:id/edit',
  SYSTEM_VIEW: '/system/:id',
  SYSTEM: '/system',
  CONTRIBUTION_ACTIVITIES: (contributionId?: string, submitDate?: string, approveRejectButtons?: string) => `/contributions/${contributionId || ':contributionId'}/${submitDate || ':submitDate'}/${approveRejectButtons || ':approveRejectButtons'}`,
  SET_UP_CONTRIBUTION: '/set-up-contribution',
  CONTRIBUTIONS: '/contributions',
  SCHEDULED_CONTRIBUTION_SUMMARY: '/scheduled-contribution/summary',

  SCHEDULED_CONTRIBUTION_EDIT_SUMMARY: (id?: string) => `/scheduled-contribution/summary/edit/${id || ':id'}`
};
