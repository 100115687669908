import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Image } from '@common-fe/common-fe';

import { Document } from '@/common/types';
import {
  CALCULATE_ZOOM_PX,
  FULL_ROTATE_DEG,
  ROTATE_CORRELATION_INDEX,
  ROTATE_STEP_DEG,
  RotateStepEnum,
} from '@/components/elements/DocumentViewer/DocumentViewer.constants';
import { StyledImageWrap } from '@/components/elements/DocumentViewer/DocumentViewer.styles';

interface Props {
  zoom: number;
  rotate: number;
  receipt: Document;
}
const ImageViewer: React.FC<Props> = ({
  zoom,
  rotate,
  receipt,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });
  /**
   @returns number of side rotated to top (describes in RotateStepEnum).
   0 = top
   1 = left
   2 = bottom
   3 = right
   @see RotateStepEnum
   */
  const rotateStep = useMemo(() => {
    const angleRotate = rotate > 0
      ? rotate % FULL_ROTATE_DEG
      : FULL_ROTATE_DEG - (Math.abs(rotate) % FULL_ROTATE_DEG);
    return (angleRotate / FULL_ROTATE_DEG) / ROTATE_CORRELATION_INDEX as RotateStepEnum;
  }, [rotate]);

  const imgLoad = useMemo(() => Boolean(imageSize.width && imageSize.height), [imageSize]);
  const onImgLoad = useCallback((
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const { offsetHeight, offsetWidth } = event.currentTarget;
    setImageSize({
      height: offsetHeight,
      width: offsetWidth,
    });
  }, []);
  const imgSizeCorrelation = useMemo(() => (imageSize.width / imageSize.height), [imageSize]);
  const isZoomed = useMemo(() => (zoom !== 0), [zoom]);
  const rotateVertical = useMemo(() => (rotate / ROTATE_STEP_DEG) % 2 !== 0, [rotate]);
  const imgWrapWidth = useMemo(() => {
    if (isZoomed && imgLoad) {
      return imageSize.width + CALCULATE_ZOOM_PX(zoom);
    }
    return imageSize.width;
  }, [zoom, imageSize, isZoomed, imgLoad]);

  /**
   @returns Calculate translation an image to fit a wrapper
   */
  const translateY = useMemo(() => {
    // width > height
    if (imgSizeCorrelation >= 1) {
      if (rotateStep === RotateStepEnum.left) {
        return ((imgWrapWidth * imgSizeCorrelation) - imgWrapWidth) / 2;
      }
      if (rotateStep === RotateStepEnum.right) {
        return (imgWrapWidth - (imgWrapWidth * imgSizeCorrelation)) / 2;
      }
    }
    // height > width
    if (imgSizeCorrelation < 1) {
      if (rotateStep === RotateStepEnum.left) {
        return (imgWrapWidth - (imgWrapWidth / imgSizeCorrelation)) / 2;
      }
      if (rotateStep === RotateStepEnum.right) {
        return ((imgWrapWidth / imgSizeCorrelation) - imgWrapWidth) / 2;
      }
    }
    return (imageSize.width - imageSize.height) / 2;
  }, [imgSizeCorrelation, rotateStep, imageSize, imgWrapWidth]);

  return (
    <StyledImageWrap
      margin={{ bottom: 'spacing6' }}
      round="container1Round"
      pad="spacing2"
      overflow="hidden"
      background="canvas"
      border={{ color: 'border1', size: 'small' }}
      zoom={zoom}
      rotate={rotate}
      rotateStep={rotateStep}
      translateY={translateY}
      rotateVertical={rotateVertical}
      style={{
        height: rotateVertical ? imgWrapWidth * imgSizeCorrelation : 'auto',
        ...rotateVertical ? { width: imgWrapWidth } : { width: imgWrapWidth || 'auto' },
      }}
    >
      <Image
        ref={imgRef}
        style={{
          ...rotateVertical ? { width: imgWrapWidth * imgSizeCorrelation } : {},
        }}
        src={receipt.blob}
        alt="icon"
        onLoad={onImgLoad}
      />
    </StyledImageWrap>
  );
};

export default ImageViewer;
