import dayjs from 'dayjs';
import { toNumber } from 'lodash';

import formatTaxType from '../../Contributions/formatTaxType';
import { Recipient, Segment } from '../types';

const formatDataToPayload = (
  recipients: Recipient[],
  segment: Segment,
) => ({
  audit_action: 'CREATE',
  contribution_segment_dto: {
    ...segment?.segmentSubsidiaryIds?.length ? {
      segment_subsidiary_ids: segment?.segmentSubsidiaryIds?.map((item) => toNumber(item)),
    } : {},
    ...segment?.segmentPlanCodes?.length ? {
      segment_plan_codes: segment?.segmentPlanCodes,
    } : {},
    ...segment?.segmentPayrollGroupCodes?.length ? {
      segment_payroll_group_codes: segment?.segmentPayrollGroupCodes,
    } : {},
    ...segment?.segmentEmployeeIds?.length ? {
      segment_employee_ids: segment?.segmentEmployeeIds?.map((item) => toNumber(item)),
    } : {},
    tax_type: formatTaxType(segment?.taxType, true),
    tax_year: segment?.taxYear,
    ...segment?.contributionSegmentType ? {
      contribution_segment_type: segment?.contributionSegmentType,
    } : {},
    ...segment?.memo ? {
      memo: segment?.memo,
    } : {},
  },
  manual_contribution_record_dtos: recipients?.map((recipient) => recipient?.contributionAccounts?.filter((acc) => !acc?.isDisabled)?.map((acc) => ({
    contribution_date: dayjs(acc?.contributionDateOn).format(),
    tax_type:  formatTaxType(acc?.taxType, true),
    tax_year: acc?.taxYear,
    employee_contribution_amount: toNumber(acc?.employeeContributionAmount),
    employer_contribution_amount: toNumber(acc?.employerContributionAmount),
    plan_id: acc?.planId,
    plan_code: acc?.planCode,
    plan_name: acc?.planName,
    employee_id: toNumber(recipient?.employeeId),
    employee_full_name: recipient?.employeeFullName,
    memo: acc?.memo,
  }))).flat(),
  excluded_accounts: recipients?.map((recipient) => recipient?.contributionAccounts?.filter((acc) => acc?.isDisabled)?.map((acc) => ({
    employee_id: toNumber(recipient?.employeeId),
    plan_code: acc?.planCode,
  }))).flat(),
});

export default formatDataToPayload;
