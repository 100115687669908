import { ClaimAutopayType } from '@/modules/employee/employee.types';

export enum CarrierConfigPurpose {
  ClaimCreation = 'CLAIM_CREATION',
  Adjudication = 'ADJUDICATION',
}

export enum ConfigStatus {
  active = 'ACTIVE',
  disabled = 'DISABLED',
}

export interface CarrierConfigPayload {
  id: number;
  carrier: {
    id: number;
    name: string;
    external_id: string;
  };
  submitter_id: string;
  description: string;
  organization_id: number;
  organization_path: string;
  service_date_from?: string;
  service_date_to?:string;
  purpose_type: CarrierConfigPurpose;
  claim_payment_method: string;
  is_dependent_deidentified: boolean;
  is_dependent_autocreated: boolean;
  status_type: ConfigStatus;
  claim_payee_type: string;
  amount_types: string[];
  claim_autopay_type: ClaimAutopayType;
}

export interface CarrierConfig {
  id: string;
  carrier: {
    id: string;
    name: string;
    externalId: string;
  };
  submitterId: string;
  description: string;
  organizationId: string;
  organizationPath: string;
  serviceDateFrom?: string;
  serviceDateTo?: string;
  purposeType: CarrierConfigPurpose;
  claimPaymentMethod: string;
  isDependentDeidentified: boolean;
  isDependentAutocreated: boolean;
  statusType: ConfigStatus;
  claimPayeeType: string;
  claimAutopayType: ClaimAutopayType;
  amountTypes: string[];
}
