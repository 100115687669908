import { dateValidatorByFormat } from '@common-fe/common-fe';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

export const getDateIsoString = (dateValue?: string | null) => {
  // for now work only with date format 'MM/DD/YYYY'
  if (!dateValue) return null;
  if (!dateValidatorByFormat(dateValue, DEFAULT_DATE_FORMAT)) {
    return 'Invalid Date';
  }
  const [month, day, year] = dateValue.split('/');
  const date = new Date(`${year}-${month}-${day}T00:00:00Z`);
  const isoString = date.toISOString();
  
  return isoString;
};