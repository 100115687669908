import { storeCreator } from '@/utils/store/store.utils';

interface State {
  highlightedId: string;
  setHighlithedId: (id: string) => void;
}
const useStore = storeCreator<State>((set) => ({
  highlightedId: '',

  setHighlithedId: (id: string) => set(() => ({
    highlightedId: id,
  })),
}));
export default useStore;
