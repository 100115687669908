import { useEffect } from 'react';

import { useEditModeStore } from '../stores';

export default (defaultValue?: boolean, isActivePlan?: boolean, isTemplateMode?: boolean) => {
  const editMode = useEditModeStore((state) => state.editMode);
  const setEditMode = useEditModeStore((state) => state.setEditMode);
  useEffect(() => {
    if (defaultValue) {
      setEditMode(defaultValue);
    }
    return () => {
      setEditMode(false);
    };
  }, [defaultValue, setEditMode]);
  useEffect(() => {
    if (isActivePlan && editMode && isTemplateMode) {
      setEditMode(false);
    }
  }, [editMode, isActivePlan, setEditMode, isTemplateMode]);

  return editMode;
};
