export const HEALTH_PLAN_SETUP_FIELD = {
  HEALTH_PLAN_NAME: 'healthPlanName',
  HEALTH_PLAN_TEMPLATE_NAME: 'healthPlanTemplateName',
  HEALTH_PLAN_TEMPLATE: 'parentTemplate',
  PARENT_TEMPLATE: 'parentTemplate',
  CARRIER_NAME: 'carrierName',
  CARRIER_ID: 'carrierId',
  HEALTH_PLAN_TYPE: 'healthPlanTypes',
  AUTOCREATE_CDH_ENROLLMENT: 'autocreateCdhEnrollment',
  PLAN_CODE: 'planCode',
  PLANS: 'plans',
  PLAN_SUMMARY: 'planSummary',
};

export const COMPLEX_CONFIGURATION_SETUP_FIELD = {
  ENROLLMENT_MATRIX_NAME: 'enrollmentMatrixName',
  PROCESSING_RULE_NAME: 'processingRuleName',
  DEDUCTIBLE_SERVICE: 'deductibleService',
};

export const PLAN_YEAR_SETUP_FIELD = {
  PLAN_YEAR: 'planYear',
  PLAN_START_DATE: 'planStartDate',
  PLAN_END_DATE: 'planEndDate',
};
