import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import {
  EmployerDtoPayload,
  OverridablePropertiesDto,
  OverridablePropertiesDtoPayload,
} from '@/modules/employer/types';
import { booleanToYesOrNo } from '@/utils/handlers';

const formatOverridablePropertiesData = (data: OverridablePropertiesDtoPayload) => ({
  themeId: data?.theme_id,
  trueSsn: data?.true_ssn,
  cardOffered: data?.card_offered,
  allowDependentCard: data?.allow_dependent_card,
  bin: data?.bin_setup,
  prin: data?.prin_setup,
  ssoOnly: data?.sso_only,
  url: data?.url,
  electronicFundingModeId: data?.electronic_funding_mode_id,
  checkFundingModel: data?.check_funding_model,
  allowAdminContribution: data?.allow_manual_contribution,
  allowAssumedContribution: data?.allow_scheduled_contribution,
  hsaCustodianId: data?.hsa_custodian_id,
  externalAdministratorFeesAccountMethod: data?.external_administrator_fees_account_method,
  externalPayrollContributionsAccountMethod: data?.external_payroll_contributions_account_method,
  externalReplenishmentAccountMethod: data?.external_replenishment_account_method,
  ...data?.minimum_payment_reimbursement_amount ? {
    minimumPaymentReimbursementAmount: toString(data?.minimum_payment_reimbursement_amount || 0),
  } : {},
  ...data?.waiting_for_funds_continue_payment ? {
    waitingForFundsContinuePayment: data?.waiting_for_funds_continue_payment,
  } : {},
  ...data?.waiting_for_funds_expiration ? {
    waitingForFundsExpiration: data?.waiting_for_funds_expiration,
  } : {},
  cardUrl: data?.card_url,
  cardEmployerName: data?.card_employer_name,
  cardPhoneNumber: data?.card_phone_number,
  communicationChannels: data?.communication_channels,
  communicationCategory: data?.organization_communication_categories,
  ...(data?.payroll_contributions_hold_period === undefined
    ? {}
    : {
      payrollContributionsHoldPeriod: `${data.payroll_contributions_hold_period}`,
    }),
  idSourceId: data?.id_source_id,
  replenishmentFrequencyType: data?.replenishment_frequency_type,
  replenishmentDayNumber: data?.replenishment_day_number,
  isReimbursementAllowed: data?.is_reimbursement_allowed,
  allowedPaymentMethods: data?.allowed_payment_methods,
  isBillPayAllowed: data?.is_bill_pay_allowed,
  sessionTimeoutWarn: data?.session_timeout_warn,
  sessionTimeoutLogout: data?.session_timeout_logout,
  ...data?.claims_assignment_number ? { claimsAssignmentNumber: data?.claims_assignment_number } : {},
  isFileManagerAccessEnabled: data?.is_file_manager_access_enabled,
  isFileAutoPostEnabled: booleanToYesOrNo(data?.is_file_auto_post_enabled),
  allowedFileTypes: data?.allowed_file_types,

  customReportingField1Name: data?.custom_reporting_field_1_name,
  customReportingField2Name: data?.custom_reporting_field_2_name,
  customReportingField3Name: data?.custom_reporting_field_3_name,
  customReportingField4Name: data?.custom_reporting_field_4_name,
  customReportingField5Name: data?.custom_reporting_field_5_name,
 
  isCustomReportingField1Enabled: data?.is_custom_reporting_field_1_enabled,
  isCustomReportingField2Enabled: data?.is_custom_reporting_field_2_enabled,
  isCustomReportingField3Enabled: data?.is_custom_reporting_field_3_enabled,
  isCustomReportingField4Enabled: data?.is_custom_reporting_field_4_enabled,
  isCustomReportingField5Enabled: data?.is_custom_reporting_field_5_enabled,

  logoInfo: {
    faviconUrl: data?.logo_info?.favicon_url,
    largeLogoUrl: data?.logo_info?.large_logo_url,
    darkLargeLogoUrl: data?.logo_info?.dark_large_logo_url,
    minimizedLogoUrl: data?.logo_info?.minimized_logo_url,
    darkMinimizedLogoUrl: data?.logo_info?.dark_minimized_logo_url,
  },
  aiAdjudication: data?.ai_adjudication,
  aiConfidenceLevel: data?.ai_confidence_level,
});

export interface Organization {
  id?: string;
  path?: string;
  sessionTimeoutLogout?: number;
  sessionTimeoutWarn?: number;
  name?: string;
  type?: OrganizationTypes;
  status?: EmployerStatus;
  overridableProperties?: OverridablePropertiesDto;
}
// temporary solution for EL-10465
const formatData = (organization: EmployerDtoPayload): Organization => ({
  id: toString(organization?.id),
  path: organization?.organization_path,
  ...organization?.overridable_properties?.session_timeout_logout ? {
    sessionTimeoutLogout: organization?.overridable_properties?.session_timeout_logout,
  } : {},
  ...organization?.overridable_properties?.session_timeout_warn ? {
    sessionTimeoutWarn: organization?.overridable_properties?.session_timeout_warn,
  } : {},
  ...organization?.overridable_properties?.claims_assignment_number ? {
    claimsAssignmentNumber: organization?.overridable_properties?.claims_assignment_number,
  } : {},
  name: organization?.name,
  type: organization?.type,
  ...organization?.organization_status_type ? {
    status: organization?.organization_status_type,
  } : {},
  ...organization?.overridable_properties ? {
    overridableProperties: formatOverridablePropertiesData(organization?.overridable_properties),
  } : {},
});

const useGetOrganizationById = (
  id?: string | null,
  staleTime: number = 3000,
) => {
  const {
    data,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [PATHS.GET_ORGANIZATION_BY_ID(id)],
    () => api.get<EmployerDtoPayload>(
      PATHS.GET_ORGANIZATION_BY_ID(id),
      {
        headers: {
          'X-DETAILS-RESOLVERS': 'ADDRESSES, PHONE, STATUS, OVERRIDABLE_PROPERTIES, ORGANIZATION_PROPERTY',
        },
      },
    ),
    {
      enabled: Boolean(id),
      staleTime,
    },
  );

  const formattedData = useMemo(() => {
    if (!data) return {};

    return formatData(data?.data);
  }, [data]);
  return {
    data,
    formattedData,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetOrganizationById;
