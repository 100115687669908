import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

const useGetPermissionsQuery = (userId?: string) => {
  const { data } = useQuery([PATHS.GET_PERMISSIONS, userId], () => userId ? api.get(PATHS.GET_PERMISSIONS) : null);

  return { permissions: data?.data };
};

export default useGetPermissionsQuery;
