import React, { useMemo } from 'react';
import {
  Box,
  dateValidatorByFormat,
  Field,
  FieldTypes,
  Inscription,
  PlusIcon,
  RemoveIcon,
} from '@common-fe/common-fe';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

import { DATE_INVALID_TEXT, DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { isDateInRange } from '@/utils/modifiers/isDateInRange';

const MIN_DATE_ERROR_TEXT = 'Date should be greater than starting date and less than ending date';

const genericField = (
  count: number,
  customId: number,
  minDate?: string,
  maxDate?: string,
  dates?: string[],
  total?: number,
  onRemove?: (id: number) => void,
  customFormDates?: Dayjs[],
) => {
  return {
    name: `customDate${customId}`,
    type: FieldTypes.Date,
    label: `Custom date ${customId}`,
    placeholder: 'Select date',
    isManualDateInput: true,
    validator: yup.string()
      .test({
        test: (val) => val
          ? isDateInRange(val, minDate, maxDate)
          : true,
        message: MIN_DATE_ERROR_TEXT,
      })
      .test({
        test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
        message: DATE_INVALID_TEXT,
      })
      .test({
        test: (val) => {
          const length = customFormDates?.filter((date) => dayjs(date).format(RAW_DATE_FORMAT) === dayjs(val).format(RAW_DATE_FORMAT))?.length || 0;
          return (val && customFormDates)
            ? length < 2
            : true;
        },
        message: 'Date already selected. Please select a different one.',
      })
      .test({
        test: (val) => !dates?.includes(dayjs(val).format(RAW_DATE_FORMAT)),
        message: 'Already added',
      }).required(REQUIRED_TEXT),
    minDate,
    maxDate,
    ...(total && total > 1) ? {
      controlStyles: {
        position: 'relative',
        width: '280px',
      },
      childrenNode: (
        <Box
          style={{
            position: 'absolute',
            right: '74px',
          }}
          align="justify"
          onClick={() => onRemove && onRemove(customId)}
          height="40px"
          pad={{ vertical: 'spacing8' }}
        >
          <RemoveIcon color="danger" size="24px" />
        </Box>),
    } : {},
  };
};

interface Props {
  customDatesCount: number;
  onAddCustomDate: () => void;
  onRemoveCustomDate: (id: number) => void;
  minDate?: string;
  maxDate?: string;
  dates?: string[];
  customDatesIds: number[];
  customFormDates?: Dayjs[];
}

export const usePayrollCalendarCustomDatesForm = ({
  minDate,
  maxDate,
  customDatesCount = 1,
  onAddCustomDate,
  onRemoveCustomDate,
  dates,
  customDatesIds,
  customFormDates,
}: Props) => {
  const generatedFields = useMemo(() => {
    const fields = [];
    for (let i = 1; i <= customDatesCount; i++) {
      fields.push(
        genericField(
          i,
          customDatesIds[i - 1],
          minDate,
          maxDate,
          dates,
          customDatesCount,
          onRemoveCustomDate,
          customFormDates,
        )
      );
    }
    return fields;
  }, [customDatesCount, minDate, maxDate, dates, onRemoveCustomDate, customDatesIds, customFormDates]);
  const fields = useMemo(() => [
    ...generatedFields,
    {
      name: 'addCustomDate',
      type: FieldTypes.Node,
      label: '',
      fieldWrapperStyles: {
        marginBlock: '-8px',
      },
      value: (
        <Box
          direction="row"
          gap="spacing4"
          align="center"
          height={{ min: '24px', max: '24px' }}
          onClick={onAddCustomDate}
        >
          <Box
            width={{ min: '24px', max: '24px' }}
            height={{ min: '24px', max: '24px' }}
            align="center"
            justify="center"
          >
            <PlusIcon color="iconAccent" size="16px" />
          </Box>
          <Inscription lineHeight="20px" color="textAccent">
            Add Another Date
          </Inscription>
        </Box>
      ),
    },
  ], [generatedFields, onAddCustomDate]);

  return fields as Field[];
};
