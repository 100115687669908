import React from 'react';
import { Box,ChevronRightIcon, Preloader } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE } from '@/common/constants';

import { EllipsisText } from '../../../ActivityLog.styles';

import { CahngesetWrapper } from './Changeset.styles';
import useGetActivityLogChangesetQuery from './useGetActivityLogChangeset.query';

const Body: React.FC<{ id: string }> = ({ id }) => {
  const { isLoading, formattedData } = useGetActivityLogChangesetQuery(id);

  if (isLoading) return <Box margin={{ top: 'spacing24' }}><Preloader /></Box>;

  return (
    <CahngesetWrapper
      margin={{ top: 'spacing8' }}
      background="canvas"
      border={{ size: '1px', color: 'border2' }}
      round="xsmall"
    >
      {formattedData.map((item) => {
        return (
          <Box
            key={item.id}
            direction="row"
            height="40px"
            align="center"
            pad={{ left: 'spacing12', right: 'spacing16' }}
          >
            <Box width="26%">
              <EllipsisText title={item?.attribute} color="textSecondary">{item?.attribute}</EllipsisText>
            </Box>
            <Box width="37%" pad={{ left: 'spacing24' }}>
              <EllipsisText
                color="textBody"
                hasUppercase={false}
                {...item?.previous === EMPTY_FIELD_VALUE || !item?.previous ? {} : { title: item?.previous }}
              >
                {item?.previous || EMPTY_FIELD_VALUE}
              </EllipsisText>
            </Box>
            <Box width="37%" pad={{ left: '36px' }} justify="center" style={{ position: 'relative' }}>
              <ChevronRightIcon
                size="8px"
                color="iconSecondary"
                style={{ position: 'absolute', marginLeft: '-20px' }}
              />
              <EllipsisText
                color="textBody"
                weight="bold"
                hasUppercase={false}
                {...item?.updated === EMPTY_FIELD_VALUE || !item?.updated ? {} : { title: item?.updated }}
              >
                {item?.updated || EMPTY_FIELD_VALUE}
              </EllipsisText>
            </Box>
          </Box>
        );
      })}
    </CahngesetWrapper>
  );
};

export default Body;
