import { useMemo } from 'react';

import { useStore as useCoverageLevelsStore } from '../stores/useCoverageLevels.store';

export default () => {
  const coverageState = useCoverageLevelsStore((state) => state.state);

  const state = useMemo(() => ({
    coverageLevels: coverageState,
  }), [coverageState]);
  return state;
};
