import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  RedirectHeaderWrapper, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

const StylessLink = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  display: inline;
  max-width: 100%;
  &.disabledLink {
    cursor: not-allowed;
    pointer-events: none;
  }
  &:focus {
  outline: none;
  }
`;

interface Props {
  title: React.ReactNode;
  path: string;
}
const HeaderLink: React.FC<Props> = ({ title, path }) => (
  <StylessLink
    to={path}
  >
    <RedirectHeaderWrapper>
      <Text size="2xl" weight={700} color="textTitle">
        {title}
      </Text>
    </RedirectHeaderWrapper>
  </StylessLink>
);

export default HeaderLink;
