import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { Plan, PlanStatuses } from '@/modules/plan/plan.types';

import { PLAN_LIST_QUERY_NAME } from '../../queries/usePlansList.query';

interface Payload {
  planYearId: number;
  parentId: number;
  name: string;
  planType?: string;
  organizationId?: string;
  status?: PlanStatuses;
}
export const useCreatePlanMutations = (redirectMode?: boolean, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { organizationId } = useCurrentOrganisationStore();
  const mutation = useMutation(
    (data: Partial<Payload>) => api.post<Plan>(PATHS.PLANS, {
      name: {
        name: data.name,
      },
      account_type: {
        account_type: data.planType,
      },
      parent_id: data.parentId,
      is_plan: false,
      plan_primary_config: {},
      plan_status: data.status,
      organization_id: _.toNumber(data.organizationId || organizationId),
    }), {

      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess();
        }
        const id = _.get(response, 'data.id');
        queryClient.invalidateQueries(PLAN_LIST_QUERY_NAME);
        if (redirectMode) {
          history.push(ROUTES.TEMPLATE_SETUP_BY_ID(id));
        }
      },
    },
  );
  return mutation.mutateAsync;
};

export default useCreatePlanMutations;
