import React, { useCallback, useMemo } from 'react';
import { formatSnakeCaseToGeneral, ListItem, Text } from '@common-fe/common-fe';

import { LetterIconSizeEnum,OrganizationTypes } from '@/common/constants';
import { ListItemType } from '@/common/types';
import { Icon } from '@/components/elements/Icon/Icon';
import { Dependent } from '@/modules/employee/employee.types';
import { Patient,useGetPatientsQuery } from '@/modules/ReimburseMe/ClaimInfo/usePatients.query';
import { CardHolderDto } from '@/modules/transaction/Cards/Cards.types';

const HIDDEN_DEPENDENT = 'Hidden Dependent';

export default (personId?: string) => {
  const { patients: dependents, isLoading } = useGetPatientsQuery(personId);

  const dependentsWithoutHidden = useMemo(() => dependents
    .filter((dependent: Patient) => `${dependent.firstName} ${dependent.lastName}` !== HIDDEN_DEPENDENT
      && dependent.fullName !== HIDDEN_DEPENDENT), [dependents]);

  const formatedFields = useMemo(() => {
    const formated: ListItem[] = dependentsWithoutHidden.map((item: Dependent) => ({
      id: `${item.id}`,
      fields: [{
        key: 'fullName',
        type: ListItemType.ErLogo,
        hasTip: true,
        value: `${item.firstName} ${item.lastName}`,
        organizationType: OrganizationTypes.dependent,
        title: `${item.firstName} ${item.lastName}`,
        flex: 1,
        logo: (
          <Icon
            type={OrganizationTypes.dependent}
            text={`${item.firstName} ${item.lastName}`}
            size={LetterIconSizeEnum.LOGO}
          />
        ),
        node: (
          <Text
            title={`${item.firstName} ${item.lastName}`}
            size="medium"
            weight="bold"
            color="textBody"
            margin={{ left: 'spacing12' }}
          >
            {item.firstName} {item.lastName}
          </Text>
        ),
      }, {
        key: 'relationship',
        type: ListItemType.Text,
        value: item.relationshipType,
        title: formatSnakeCaseToGeneral(item.relationshipType),
        fieldStyle: { alignItems: 'flex-start', flex: '2.5' },
      }],
    }));
    return formated;
  }, [dependentsWithoutHidden]);

  const formatDependent = useCallback((dependent: Dependent) => ({
    dependentId: `${dependent.id}`,
    name: `${dependent.firstName} ${dependent.lastName}`,
    firstName: dependent.firstName,
    lastName: dependent.lastName,
    dateOfBirth: dependent.birthOn,
    relationship: dependent.relationshipType,
  }), []);

  const formattedDependents: CardHolderDto[] = useMemo(() => [
    ...(dependentsWithoutHidden || []).map(formatDependent),
  ], [dependentsWithoutHidden, formatDependent]);

  return {
    data: formatedFields,
    formattedDependents,
    isLoading,
  };
};
