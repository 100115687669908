import React, { useCallback } from 'react';

import { ModalWrapper } from '@/components/wrappers';

import CancelEmployerTerminationModalForm from './EmployerTerminationModalForms/CancelEmployerTerminationModalForm';
import { useCancelEmployerTerminationModalStore } from './store';

export const CancelEmployerTerminationModal = () => {
  const handleChangeVisibility = useCancelEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const modalVisible = useCancelEmployerTerminationModalStore((state) => state.modalVisible);

  const handleSetVisible = useCallback(() => {
    handleChangeVisibility(false);
  }, [
    handleChangeVisibility,
  ]);

  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={modalVisible}
      onSetVisible={handleSetVisible}
    >
      <CancelEmployerTerminationModalForm onCancel={handleSetVisible} />
    </ModalWrapper>
  );
};