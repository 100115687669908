import React from 'react';
import {
  Box,
  Inscription,
  NotFoundImagePlaceholderIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

const Wrapp = styled(Box)`
  ${({ theme }) => theme.shadows.default};
  outline: 1px solid ${({ theme }) => theme.colors.border1};
  outline-offset: -3px;
`;

interface Props {
  title: string;
}

const NoFiles: React.FC<Props> = ({ title }) => (
  <Wrapp
    height="440px"
    background="canvas"
    margin={{ horizontal: 'spacing12', vertical: 'spacing12' }}
    round="container1Round"
    justify="center"
    align="center"
  >
    <NotFoundImagePlaceholderIcon size="72px" color={globalTheme.colors.border} />
    {title && (
      <Inscription
        margin={{ top: 'spacing24' }}
        color="textDisabled"
        lineHeight="20px"
      >
        {title}
      </Inscription>
    )}
  </Wrapp>
);

export default NoFiles;
