import { useMemo } from 'react';

import { useAccountFundingFieldsState } from '../AccountFunding/hooks';
import { useCoverageFieldsState } from '../Coverage/hooks';
import { usePlanDefinitionFieldsState } from '../PlanDefinition/hooks';

const usePlanSetupFieldsState = () => {
  const planDefinitionState = usePlanDefinitionFieldsState();
  const coverageState = useCoverageFieldsState();
  const accountFundingState = useAccountFundingFieldsState();
  const state = useMemo(() => ({
    ...coverageState,
    ...planDefinitionState,
    ...accountFundingState,
  }), [accountFundingState, coverageState, planDefinitionState]);
  return state;
};

export default usePlanSetupFieldsState;
