import React from 'react';
import {
  Box, Text,
  Tip, WarningIcon,
} from '@common-fe/common-fe';
import _ from 'lodash';

interface WarningItemProps {
  orgLevel?: string;
}
const EmptyPlanYear: React.FC<WarningItemProps> = ({ orgLevel }) => (
  <Box style={{ position: 'relative' }}>
    <Tip
      plain
      dropProps={{
        align: {
          top: 'bottom',
        },
      }}
      content={(
        <Box pad={{ top: 'l' }}>
          <Box
            background="background3"
            round="dropdownItemRound"
            elevation="default"
            align="center"
            width="regular"
            pad={{ horizontal: 'm', vertical: 's' }}
          >
            <Text color="textOnColor" data-testId="filter-hint_text">
              This {_.lowerCase(orgLevel)} doesn’t have any plan years,
              so you cannot apply parameter filters.
            </Text>

          </Box>
        </Box>
      )}
    >
      <Box>
        <WarningIcon color="danger" />
      </Box>
    </Tip>
  </Box>
);

export default EmptyPlanYear;
