import { baseStoreCreator } from '@/utils/store';

export interface ContactPayload {
  id?: string;
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string | null;
  role?: string;
  isAccessToSetup?: string;
  contactName?: string;
  isPrimary?: boolean;
}


export const DEFAULT_VALUES: ContactPayload = {
  id: '',
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  role: '',
  isAccessToSetup: '',
  isPrimary: undefined,
};

export const useContactStore = baseStoreCreator<ContactPayload>(DEFAULT_VALUES);

export default useContactStore;
