import { Heading } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledHeader = styled(Heading)`
  &:hover {
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;

// export const RequiredField = styled(Box)`
//   &:before {
//     content: '*';
//     color: ${({ theme }) => theme.colors.danger};
//     height: 16px;
//     font-size: 20px;
//     font-weight: 600;
//     margin-right: 4px;
//   }
//   &:not(:last-child) {
//     padding-right: 9.5px;
//     position: relative;
//     &:after {
//       content: '';
//       width: 3px;
//       height: 3px;
//       background: black;
//       border-radius: 50%;
//       opacity: 0.15;
//       margin-left: 9px;
//     }
//   }
// `;
