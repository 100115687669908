import React from 'react';
import { Box, FlexListItem, ListItem } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import DashesPlaceholder from '@/assets/placeholders/dashes-placeholder.svg';
import { TableRowOption } from '@/common/types';
import theme from '@/styles/theme';

export interface ExpandedListItem extends ListItem {
  subfields?: ListItem[];
}
interface Props {
  item: ExpandedListItem;
  disabled?: boolean;
  isHighlight?: boolean;
  testId?: string;
  options?: TableRowOption[];
  children?: React.ReactNode;
  isDisabled?: boolean;
  isHighlightMoveTo?: boolean;
  isMatrix?: boolean;
  isExapnded: boolean;
  disabledOptionHint?: string;
}

interface StyledBoxProps {
  isDisabled?: boolean;
  isHighlight?: boolean;
  isHighlightMoveTo?: boolean;
  isExapnded: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  height: ${({ isExapnded }) => (!isExapnded ? '76px' : 'auto')};
  
  ${({ isHighlight: isHightlight }) => {
    if (isHightlight) {
      return css`
        animation-name: showHighlight;
        animation-duration: 3s;
        @keyframes showHighlight {
          from {
            border-color: ${theme.colors.accentActive};
            ${theme.shadows.highlighted};
          }
          to {
            border-color: transparent;
          }
        }
      `;
    }
    return css``;
  }}
  border-color: ${({ isHighlightMoveTo }) => (isHighlightMoveTo && theme.colors.border)};
  &::before {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    height: 10px;
    overflow: hidden;
    ${({ isDisabled }) => {
    if (isDisabled) {
      return {
        content: `url(${DashesPlaceholder})`,
      };
    }
    return {};
  }}
}
`;

const ExpandedItem: React.FC<Props> = ({
  testId = '', item, disabled, options, children, isDisabled, isHighlight,
  isHighlightMoveTo, isMatrix, isExapnded, disabledOptionHint,
}) => (
  <div style={{ position: 'relative' }}>
    <StyledBox
      key={item.id}
      direction="column"
      round="container2Round"
      background="canvas"
      elevation="default"
      border={{ side: 'all', size: 'small', color: theme.colors.border2 }}
      isHighlight={isHighlight}
      isHighlightMoveTo={isHighlightMoveTo}
      pad={{ horizontal: '12px' }}
      margin={{ top: isMatrix ? '0' : 'xs' }}
      isDisabled={isDisabled}
      isExapnded={isExapnded}

    >
      <FlexListItem
        testId={`${testId}`}
        key={item.id}
        hasNext
        stocksMode
        rowStyle={{
          background: 'none', border: 'none', height: '76px',
        }}
        rowWrapperStyle={{
          marginBottom: '0px',
        }}
        id={item.id}
        onRemoveRow={item?.onRemoveRow}
        fields={item.fields}
        onPick={disabled ? undefined : item.onPick}
        options={options}
        disabledOptionHint={disabledOptionHint}
      />
      {children}
    </StyledBox>
  </div>
);
export default ExpandedItem;
