import { useMemo } from 'react';
import { OptionKey } from '@common-fe/common-fe';

import { PaginationParams } from '@/common/types';
import {
  ContributionListItemType,
  RecordsInfo,
  RecordsInfoDto,
} from '@/modules/employer/components/Contributions/contributions.types';

import { useGetContributionsQuery } from '../queries/useGetContributions.query';

const FILE_STATUS = 'FPS';
const FILE_STATUS_TITLE = 'File';

const accountsParser = (records?: RecordsInfoDto[]) => {
  if (!records || !records.length) {
    return [];
  }

  const accounts = records.map((record) => record?.plan_code || '-');

  if (accounts.every((account) => account === '-')) {
    return [];
  }

  return accounts;
};


const formatRecordsInfo = (recordInfo?: RecordsInfoDto[]) => {
  if (!recordInfo || !recordInfo.length) {
    return [];
  }

  const formattedRecordsInfo: RecordsInfo[] = recordInfo.map((record) => ({
    eeContributionAmount: record?.ee_contribution_amount,
    erContributionAmount: record?.er_contribution_amount,
    planCode: record?.plan_code,
    planName: record?.plan_name,
    invoiceId: record?.invoice_id,
    ...record?.invoice_status_type ? { invoiceStatusType: record?.invoice_status_type } : {},
    ...record?.contribution_request_status ? {
      contributionRequestStatus: record?.contribution_request_status,
    } : {},
  }));

  return formattedRecordsInfo;
};

interface CurrentPaginationParams extends PaginationParams {
  startDate?: Date | null;
  endDate?: Date | null;
  accounts?: OptionKey[];
  sources?: OptionKey[];
}

export const useGetContributionsList = (
  {
    startDate,
    endDate,
    accounts,
    sources,
    page,
    perPage,
  }: CurrentPaginationParams
) => {
  const {
    formattedData,
    totalElements,
    totalPages,
    isLoading,
    data,
    refetch,
  } = useGetContributionsQuery({
    accounts,
    sources,
    page,
    perPage,
    startDate,
    endDate,
  });
  const formattedList = useMemo<ContributionListItemType[]>(() => formattedData ? formattedData.map((item) => ({
    contributionId: `${item.contribution_id}`,
    status: item?.status,
    contributionSource: item?.contribution_source,
    submitDate: item?.submit_date,
    recordsInfo: formatRecordsInfo(item?.records_info),
    recordCount: item?.record_count,
    accounts: item?.records_info?.length ? accountsParser(item?.records_info) : [],
  })) : [], [formattedData]);


  const isEmpty = useMemo(() => totalElements === 0 || formattedData.length === 0, [totalElements, formattedData]);
  return {
    isLoading,
    isEmpty,
    formattedList,
    totalPages,
    totalElements,
    data,
    refetch,
  };
};
