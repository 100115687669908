import React from 'react';
import { Inscription } from '@common-fe/common-fe';

import {
  Lockbox,
  LockboxType,
} from '@/modules/employer/components/Lockbox/lockbox.types';
import { LockboxForm } from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxForm/components';
import LockboxInfoContainer from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoContainer';
import { useLockboxInboundTOAFields } from '@/modules/employer/components/Lockbox/LockboxDetails/hooks';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

import FailedToScanDocumentWarning from './FailedToScanDocumentWarning';

interface Props {
  lockbox?: Lockbox;
}

const LockboxInboundTOAForm: React.FC<Props> = ({ lockbox }) => {
  const { lockboxInboundTOAFields } = useLockboxInboundTOAFields({ lockbox });
  return <LockboxForm fields={lockboxInboundTOAFields} />;
};

const LockboxOutboundTOAForm: React.FC<Props> = ({ lockbox }) => {
  const { lockboxInboundTOAFields } = useLockboxInboundTOAFields({ lockbox });
  return <LockboxForm fields={lockboxInboundTOAFields} />;
};

interface Props {
  lockbox?: Lockbox;
}

const LockboxInformationForm: React.FC<Props> = ({ lockbox }) => {
  const { editableLockbox } = useLockboxDetailsStore();

  const lockboxType = editableLockbox?.lockboxType;

  if (!lockboxType) {
    return null;
  }

  return (
    <LockboxInfoContainer data-testId="lockbox-information-form" margin={{ top: 'spacing8' }}>
      <Inscription color="textSecondary" margin={{ bottom: 'spacing12' }}>
        Information located 
      </Inscription>

      {lockbox?.ocr ? (
        <>
          {lockboxType === LockboxType.INBOUND_TOA && <LockboxInboundTOAForm lockbox={lockbox} />}
          {lockboxType === LockboxType.OUTBOUND_TOA && <LockboxOutboundTOAForm lockbox={lockbox} />}
        </>
      ) : (
        <FailedToScanDocumentWarning />
      )}
    </LockboxInfoContainer>
  );
};

export default LockboxInformationForm;
