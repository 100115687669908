import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import PlanCardTemplateActions from '@/components/elements/PlanCard/PlanCardTemplateActions.tsx';
import {
  useCurrentOrganization,
  useHistory,
} from '@/modules/core/hooks';

import { PlanCardAction, PlanCardMode } from '../../plan.types';
import { useForceOpenModalStore } from '../../PlansList/stores';

const PLanTemplateActivation = () => {
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const handleSetModalVisible = useForceOpenModalStore((state) => state.setState);

  const isSystem = useMemo(
    () => type === OrganizationTypes.system,
    [type],
  );
  const isPartner = useMemo(
    () => type === OrganizationTypes.partner,
    [type],
  );
  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  const isPlansHidden = useMemo(() => isSystem || isPartner, [isPartner, isSystem]);
  const handleSelectAction = useCallback((value: PlanCardAction) => {
    handleSetModalVisible(value, id);
    history.push(ROUTES.PLANS);
  }, [handleSetModalVisible, history, id]);

  if (isSubsidiary) return null;

  return (
    <Box direction="row" align="end" justify="end">
      <PlanCardTemplateActions
        isStandaloneMode
        isPlansHidden={isPlansHidden}
        onSelectAction={handleSelectAction}
        mode={PlanCardMode.Plan}
      />
    </Box>
  );
};

export default PLanTemplateActivation;
