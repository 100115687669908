import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, FlexControlledForm, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import { useBankMethodsQuery } from '@/modules/employer/hooks';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useContributionFeatureFlag from '@/modules/employer/useContributionsFeatureFlag';
import { useEnvSpecificAvailable } from '@/utils';

import useBanksList from '../BanksList/useBanksList';

import useAdminContributionsFields from './hooks/useAdminContributionsFields';
import useAdministratorFeesFields from './hooks/useAdministratorFeesFields';
import useFundingReplenishmentFields from './hooks/useFundingReplenishmentFields';
import useIndividualContributionFields from './hooks/useIndividualContributionFields';
import usePayrollContributionFields from './hooks/usePayrollContributionFields';
import usePreFundFields from './hooks/usePreFundFields';
import { AccountUsageValues } from './AccountUsage.types';
import { useAccountUsageStore } from './stores';

const StyledBox = styled(Box)`
  input {
    max-width: 180px;
  }
`;

interface Props {
  onDirty?: (isDirty: boolean) => void;
  disabled?: boolean;
}

const subtitle = (text: string) => (
  <Text size="14px" margin={{ top: 'spacing8', bottom: '18px' }}>
    {text}
  </Text>
);

const AccountUsage: React.FC<Props> = ({ onDirty, disabled }) => {
 
  const isContributionFeatureFlag = useContributionFeatureFlag();
  const { formattedData, isLoading } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const {
    handleSetValues,
    handleReset,
    state: { holdPeriod },
  } = useAccountUsageStore();
  const { methods, isLoading: areBankMethodsLoading } = useBankMethodsQuery();
  const { list: banks, isLoading: areBanksLoading } = useBanksList();
  const [currentValues, setCurrentValues] = useState<AccountUsageValues>({});
  const [adminFeesValues, setAdminFeesValues] = useState({});
  const [payrollContributionValues, setPayrollContributionValues] = useState({});
  const [individualContributionValues, setIndividualContributionValues] = useState({});
  const [fundingReplenishmentValues, setFundingReplenishmentValues] = useState({});
  const [adminContributions, setAdminContributions] = useState({});
  const [preFundValues, setPreFundValues] = useState({});
  const { activeStatus, pendingStatus } = useSaveTypeStore();
  const isDataLoading = useMemo(() => isLoading
    || areBankMethodsLoading
    || areBanksLoading,
  [isLoading, areBankMethodsLoading, areBanksLoading]);

  const administratorFeesFields = useAdministratorFeesFields(
    banks,
    methods,
    formattedData,
    currentValues,
    !pendingStatus,
    isDataLoading,
  );

  const payrollContributionFields = usePayrollContributionFields(
    banks,
    methods,
    formattedData,
    currentValues,
    !pendingStatus,
    isDataLoading,
  );
  const fundingReplenishmentFields = useFundingReplenishmentFields(
    banks,
    methods,
    formattedData,
    currentValues,
    !pendingStatus,
    isDataLoading,
  );
  const adminContributionsFields = useAdminContributionsFields({
    formattedData,
    isLoading: isDataLoading,
  });

  const individualContributionFields = useIndividualContributionFields(
    formattedData,
    !pendingStatus,
    isDataLoading,
  );

  const preFundFields = usePreFundFields(
    formattedData,
    !pendingStatus,
    isDataLoading,
  );

  useEffect(() => {
    setCurrentValues((prev) => ({
      ...prev,
      ...adminFeesValues,
      ...payrollContributionValues,
      ...individualContributionValues,
      ...adminContributions,
      ...fundingReplenishmentValues,
      ...preFundValues,
    }));
  }, [
    adminFeesValues,
    payrollContributionValues,
    adminContributions,
    fundingReplenishmentValues,
    preFundValues,
    individualContributionValues,
  ]);

  useEffect(() => {
    if (!areBankMethodsLoading && !areBanksLoading) {
      handleSetValues(currentValues);
    }

    return () => handleReset();
  }, [
    handleSetValues,
    handleReset,
    areBankMethodsLoading,
    areBanksLoading,
    currentValues,
  ]);

  useEffect(
    () => {
      if (formattedData.overridableProperties?.payrollContributionsHoldPeriod
        && !holdPeriod) {
        handleSetValues({
          holdPeriod: formattedData.overridableProperties?.payrollContributionsHoldPeriod,
        });
      }
    },
    [formattedData.overridableProperties, holdPeriod, handleSetValues],
  );

  if (isDataLoading) return <FormLoader testId="AccountUsage" />;

  return (
    <Box>
      <Box id="administrator_fees">
        <FlexControlledForm
          formTitle="Administrator Fees"
          editMode={!viewMode && !formattedData?.isTerminated && !disabled}
          fields={administratorFeesFields}
          onChangeValues={setAdminFeesValues}
          showError={activeStatus || pendingStatus}
          onDirty={onDirty}
          wrapperStyle={{ border: 'none', paddingBottom: 0 }}
          formStyle={{ marginBottom: 0 }}
          isThinMode
        />
      </Box>
      <Box id="payroll_contributions">
        <FlexControlledForm
          formTitle="Payroll Contribution"
          subTitle={subtitle('Elevate collects contributions made to employee-owned accounts, such as an HSA, prior to posting to the employee\'s account')}
          editMode={!viewMode && !formattedData?.isTerminated && !disabled}
          fields={payrollContributionFields}
          onChangeValues={setPayrollContributionValues}
          showError={activeStatus || pendingStatus}
          onDirty={onDirty}
          wrapperStyle={{ border: 'none', paddingBottom: 0 }}
          formStyle={{ marginBottom: 0, marginTop: '18px' }}
          isThinMode
        />
      </Box>
      <Box id="individual_contributions">
        <FlexControlledForm
          formTitle="Individual Contribution"
          subTitle={subtitle('The number of days to be hold during individual contribution')}
          editMode={!viewMode && !formattedData?.isTerminated && !disabled}
          fields={individualContributionFields}
          onChangeValues={setIndividualContributionValues}
          showError={activeStatus || pendingStatus}
          onDirty={onDirty}
          wrapperStyle={{ border: 'none', paddingBottom: 0 }}
          formStyle={{ marginBottom: 0, marginTop: '18px' }}
          isThinMode
        />
      </Box>
      {isContributionFeatureFlag ? (
        <Box id="admin_contributions">
          <FlexControlledForm
            formTitle="Admin Contributions"
            subTitle={subtitle('Create a method for employers to setup contributions to apply to employees accounts')}
            editMode={!viewMode && !formattedData?.isTerminated && !disabled}
            fields={adminContributionsFields}
            onChangeValues={setAdminContributions}
            showError={activeStatus || pendingStatus}
            onDirty={onDirty}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ marginTop: '18px', marginBottom: 0 }}
            isThinMode
          />
        </Box>
      ) : null}
      <Box id="funding_replenishment">
        <FlexControlledForm
          formTitle="Funding Replenishment"
          subTitle={subtitle('Funds collected to replenish the plans account after claim payment have been made')}
          editMode={!viewMode && !formattedData?.isTerminated && !disabled}
          fields={fundingReplenishmentFields}
          onChangeValues={setFundingReplenishmentValues}
          showError={activeStatus || pendingStatus}
          onDirty={onDirty}
          wrapperStyle={{ border: 'none', paddingBottom: currentValues?.fundingReplenishmentBank ? 0 : undefined }}
          formStyle={{ marginTop: '18px', marginBottom: currentValues?.fundingReplenishmentBank ? 0 : undefined }}
          isThinMode
        />
      </Box>
      {currentValues?.fundingReplenishmentBank && (
        <StyledBox id="pre_fund">
          <FlexControlledForm
            formTitle="Pre-Fund"
            subTitle={subtitle('Elevate holds a portion of member enrollments to support quick claim payments. Each month elections will be re-calculated and determine if additional funds will need to be collected')}
            editMode={!viewMode && !formattedData?.isTerminated && !disabled}
            fields={preFundFields}
            onChangeValues={setPreFundValues}
            showError={activeStatus || pendingStatus}
            onDirty={onDirty}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ marginTop: '18px' }}
            isThinMode
          />
        </StyledBox>
      )}
    </Box>
  );
};

export default AccountUsage;
