import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

export const WrapToError = styled(Box)<{ hasError?: boolean }>`
  input {
    ${({ theme, hasError }) => hasError ? `border: 2px solid ${theme.colors.danger}`: ''}
  }
`;

export const FixedWrapper = styled(Box)<{ isScrolled?: boolean }>`
  position: fixed;
  z-index: 1001;
`;

export const ContributionRecipientsWrapper = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
    :nth-child(5n) {
      position: relative;
      margin-bottom: 33px;
      :after {
        content: '';
        position: absolute;
        bottom: -17.5px;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.border1};
      }
    }
  }
`;

export const AccountWrapper = styled(Box)`
  > div:not(:last-child) {
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacings.spacing24};
    :after {
        content: '';
        position: absolute;
        bottom: -12.5px;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.border1};
      }
  }
`;

export const EllipsisText = styled(Inscription)`
  padding-right: 4px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const HoveredIcon = styled(Box)`
  :hover {
    svg {
      fill: ${({ theme }) => theme.colors.accentActive};
    }
  }
`;
