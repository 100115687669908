import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box, FlatTextInput } from '@common-fe/common-fe';

import { REGEXPS, VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';

import FormulaSubfield from './FormulaSubfield';

interface Props {
  value: string;
  disabled?: boolean;
  isErrorsShowed?: boolean;
  isPrefixMode?: boolean;
  onChange: (value: string) => void;
}
const PrefixSubfield: React.FC<Props> = ({
  value, onChange, isErrorsShowed, disabled, isPrefixMode,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const handleChange = useCallback((newValue: string) => {
    const formated = newValue
      .replace(REGEXPS.SPECIAL_CHARACTER, '')
      .replace(REGEXPS.SPACE_SYMBOL, '');
    setInputValue(formated);
  }, []);
  useEffect(() => {
    onChange(inputValue);
  }, [inputValue, onChange]);
  const error = useMemo(() => {
    if (isErrorsShowed && !inputValue.trim()) {
      return REQUIRED_TEXT;
    }
    try {
      VALIDATORS.ONLY_LATIN.validateSync(inputValue);
    } catch (e) {
      return 'Can only contain alphanumeric Latin letters';
    }
    return '';
  }, [inputValue, isErrorsShowed]);
  return (
    <FormulaSubfield
      label={isPrefixMode ? 'Prefix' : 'Value'}
      text={isPrefixMode ? 'The prefix can only accept alphanumeric with no spaces.' : 'Value can only accept alphanumeric with no spaces.'}
    >
      <Box width="180px">
        <FlatTextInput
          testId="prefix-subfield__input"
          placeholder={isPrefixMode ? 'Enter prefix manually' : 'Enter value manually'}
          value={inputValue}
          disabled={disabled}
          errorText={error}
          onChange={handleChange}
        />
      </Box>
    </FormulaSubfield>
  );
};

export default PrefixSubfield;
