import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';

import { CustomCoverageLevelPayload } from '../../CoverageLevels.constants';
import { CovarageCodeHint, HINT_RIGHT_ALIGN_KEY } from '../../hooks/useCoverageLevelsFields';

export default (errors: Partial<CustomCoverageLevelPayload>) => {
  const fields: Field[] = useMemo<Field[]>(() => [
    {
      name: 'coverageName',
      type: FieldTypes.Text,
      label: 'Coverage name',
      placeholder: 'Enter coverage name',
      showRequireIcon: true,
      validator: VALIDATORS.STRING.max(48, MAX_LENGTH_TEXT(48)).test({
        test: (val) => val !== errors.coverageName,
        message: 'Coverage name is already used',
      }).required(REQUIRED_TEXT),
      isNarrowLabelMode: true,
    },
    {
      name: 'coverageCode',
      type: FieldTypes.Text,
      label: 'Coverage code',
      placeholder: 'Enter coverage code',
      showRequireIcon: true,
      validator: VALIDATORS.STRING.max(16, MAX_LENGTH_TEXT(16)).test({
        test: (val) => val !== errors.coverageCode,
        message: 'Coverage code is already used',
      }).required(REQUIRED_TEXT),
      hintAlign: HINT_RIGHT_ALIGN_KEY,
      hint: <CovarageCodeHint />,
      isNarrowLabelMode: true,
    },
  ], [errors.coverageCode, errors.coverageName]);
  return fields;
};
