import React from 'react';
import { Box, Heading } from '@common-fe/common-fe';

import EnrollmentMatrix from './EnrollmentMatrix';
import { PayoutDefinitions } from './PayoutDefinition';
import { ProcessingRules } from './ProcessingRule';

interface Props {
  isEmployer?: boolean;
}
const ProcessingDefinition: React.FC<Props> = ({ isEmployer }) => (
  <Box pad={{ top: 'l' }} style={{ position: 'relative' }}>
    <Heading level={4} size="large" color="textTitle">Processing Definition</Heading>
    <EnrollmentMatrix isEmployer={isEmployer} />
    <ProcessingRules />
    <PayoutDefinitions />
  </Box>
);

export default ProcessingDefinition;
