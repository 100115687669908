import React, { useCallback,useState } from 'react';
import {
  AppButton,   Box, FlexControlledForm,
  Modal, Text, WarningLabel, } from '@common-fe/common-fe';

import { StatusWrapper } from '@/components';
import { useUpdateEnrollmentMatrix } from '@/modules/ProcessingDefinition/EnrollmentMatrix/hooks';
import { EnrollmentMatrixItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import EnrollmentMatrixEntity from './EnrollmentMatrixEntity';
import Footer from './Footer';
import { useCreateEnrollmentMatrix, useEnrollmentMatrixOrgFields } from './hooks';

interface Props {
  perPage?: number;
  updatedElement?: EnrollmentMatrixItem;
  title: string;
  setUpdatedElement: (value?: EnrollmentMatrixItem) => void;
}

const CreateTemplateModal: React.FC<Props> = ({
  perPage,
  updatedElement,
  title,
  setUpdatedElement,
}) => {
  const [visible, setVisible] = useState(false);
  const handleToggleVision = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const fields = useEnrollmentMatrixOrgFields();

  const handleCloseModal = useCallback(() => {
    setVisible(false);
    setUpdatedElement(undefined);
  }, [setUpdatedElement]);

  const {
    handleSave,
    loading,
    setLoading,
    handlePreSubmit,
    enrollmentMatrixCreationError,
  } = useCreateEnrollmentMatrix({
    onClose: handleCloseModal,
    perPage,
  });
  const {
    handlePreSubmit: handlePreUpdateSubmit,
    isLoading,
    setLoading: setUpdateLoading,
    enrollmentMatrixUpdatingError,
  } = useUpdateEnrollmentMatrix(handleCloseModal);

  const handleSubmit = useCallback(async () => {
    const isSuccess = handlePreSubmit();
    if (isSuccess && !updatedElement) {
      try {
        await handleSave();
      } catch {
        setLoading(false);
      }
    }
    if (updatedElement) {
      try {
        await handlePreUpdateSubmit(updatedElement?.id, undefined, perPage, false, true);
      } catch {
        setUpdateLoading(false);
      }
    }
  }, [handlePreSubmit, handlePreUpdateSubmit, updatedElement, perPage,
    handleSave, setLoading, setUpdateLoading]);

  return (
    <>
      <AppButton onClick={handleToggleVision}>
        Create an Enrollment Matrix
      </AppButton>
      <Modal
        visible={visible || !!updatedElement}
        onSetVisible={setVisible}
        onClear={() => {
          setUpdatedElement(undefined);
          setVisible(false);
        }}
      >
        <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
          <Text size="2xl" color="textTitle" weight="bold">{title}</Text>
        </Box>
        <Box>
          <Box dir="column" background="module" pad="spacing24" round="moduleRound">
            <Box elevation="default" margin={{ bottom: 'l' }} background="canvas" round="container1Round">
              <FlexControlledForm
                key={`${fields[0].value}${fields[1].value}`}
                fields={fields}
                testId="enrollment_matrix_org_form"
                // showFieldsError={true}
                editMode={false}
                isModalType
                onChangeValues={() => {}}
                isThinMode
              />
            </Box>
            <EnrollmentMatrixEntity
              testId="EnrollmentMatrix"
              enrollmentMatrixError={enrollmentMatrixCreationError || enrollmentMatrixUpdatingError}
              updatedElement={updatedElement}
            />
            {!updatedElement
              && (
                <Box margin={{ top: 'spacing24' }}>
                  <StatusWrapper status="pending">
                    <Box
                      margin="spacing12"
                      pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
                      background={{ color: 'warningContainer' }}
                      border={{ size: '1px', color: 'warningBorder' }}
                      round="container2Round"
                    >
                      <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
                        <WarningLabel
                          data-testId="EnrollmentMarix_warning_message"
                        >
                          Does everything look good? Please double check your selections.
                        </WarningLabel>
                      </Text>

                      <Box>
                        <Text
                          data-testId="EnrollmentMarix_confirm_message"
                          margin={{ bottom: 'spacing8' }}
                          color="textBody"
                          size="14px"
                        >
                          Once created, only the Name and Priority can be changed.
                        </Text>
                      </Box>
                    </Box>
                  </StatusWrapper>
                </Box>
              )}
          </Box>
          <Footer
            testId="EnrollmentMarix_footer"
            loading={loading || isLoading}
            onSubmit={handleSubmit}
            onCancel={handleCloseModal}
            submitButtonTitle={updatedElement ? 'Save' : 'Create'}
          />
        </Box>
      </Modal>
    </>
  );
};
export default CreateTemplateModal;
