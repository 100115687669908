import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import REGEXP from '@/common/regexp';
import { OptionKey, PaginationParams } from '@/common/types';
import { CardDto, CardStatusEnum } from '@/modules/transaction/Cards/Cards.types';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import { useSearchParams } from '@/modules/transaction/Cards/hooks/useSearchParams';
import { useGetCardTransactionList } from '@/modules/transaction/TransactionsList/query/useGetCardTransactionList.query';

interface CurrentPaginationParams extends PaginationParams{
  statuses?: OptionKey[]
  startDate?: Date | null;
  endDate?: Date | null;
}

interface CardTransaction {
  claimId: number;
  amount: number;
  title: string;
  date: string;
  status: string;
  category: string;
}

const NONE = 'none';

const isRefund = (swiped_amount?: number) => (swiped_amount ? swiped_amount > 0 : false);
const REFUND = 'REFUND';

const useCardTransactionsList = ({
  page,
  perPage,
  searchString,
  statuses,
  startDate,
  endDate,
}: CurrentPaginationParams) => {
  const { query: cardId } = useSearchParams('card_id');
  const { query: dependentId, removeParams } = useSearchParams('dependent_id');
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();

  const {
    cardMap, holderName, cardAccountId, holderKey,
  } = useGetCardsQuery(employeeId, dependentId);

  const { isLoading, data, total } = useGetCardTransactionList({
    cardId,
    perPage,
    page,
    employeeId,
    statuses: statuses as string[],
    startDate,
    endDate,
    searchString: searchString || '',
    ...(dependentId === NONE && _.isNumber(cardAccountId) ? { cardAccountId: `${cardAccountId}` } : {}),
  });
  const cards: CardDto[] = useMemo(() => Object.keys((cardMap[holderKey] || {}))
    .reduce((arr, status) => [
      ...arr,
      ...(cardMap[holderKey][status as CardStatusEnum] || [] as CardDto[]),
    ], [] as CardDto[]), [holderKey, cardMap]);

  const activeCard = useMemo(
    () => cards.find((card) => card.id === cardId),
    [cardId, cards],
  );

  const formattedData = useMemo<CardTransaction[]>(
    () => {
      const newList = data.map((item) => ({
        claimId: item.claim_id,
        amount: isRefund(item?.swiped_amount)
          ? item.swiped_amount : item?.approved_amount || item.swiped_amount,
        title: isRefund(item?.swiped_amount) ? `Return ${item.memo}` : item?.memo || '',
        date: item.card_swipe_date ? dayjs(item.card_swipe_date).format(DEFAULT_DATE_FORMAT) : '',
        status: item.claim_status,
        category: isRefund(item?.swiped_amount) ? REFUND : item?.category_type || '',
      }));
      return newList;
    }, [data],
  );









  const removeAllParams = () => {
    const params = ['card_id', 'dependent_id'];
    return removeParams(params);
  };

  return ({
    data: formattedData || [],
    isLoading,
    total,
    activeCard,
    holderName,
    removeAllParams,
  });
};

export default useCardTransactionsList;
