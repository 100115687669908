import { useMemo } from 'react';

import { useCoverageLevelsQuery } from '../queries';
import { useCoverageLevelsStore } from '../stores';

export default () => {
  const levels = useCoverageLevelsStore((state) => state.state);
  const excludeIds: string[] = useMemo(() => levels.map((item) => item!.coverageId), [levels]);
  const { isLoading, data } = useCoverageLevelsQuery();
  const filteredData = useMemo(() => {
    if (!excludeIds) {
      return data;
    }
    return data?.filter(
      (item) => !excludeIds.includes(item.id),
    );
  }, [data, excludeIds]);
  return {
    isLoading,
    data: filteredData || [],

  };
};
