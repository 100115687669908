import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AbsenceFilesPlaceholderIcon,
  AppButton,
  Box,
  Inscription,
  NoSearchItemsPlaceholder,
  OptionKey,
  Preloader,
  SearchInput,
  SearchPlaceholderIcon,
  SelectDropdown,
  SuccessModal,
} from '@common-fe/common-fe';
import { compact,toNumber, toString, uniqBy } from 'lodash';

import { ALL_OPTION } from '@/common/constants';
import routes from '@/common/routes';
import { DefaultSortTypesEnum } from '@/common/types';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHistory } from '@/modules/core/hooks';
import globalTheme from '@/styles/theme';

import useCreateManualContributionQuery from './hooks/useCreateManualContribution.query';
import useGetCoveragePeriodsQuery from './hooks/useGetCoveragePeriods.query';
import useGetLimitsQuery from './hooks/useGetLimits.query';
import useRecipientsStore from './stores/useRecipients.store';
import { findRecipientIndexWithError } from './utils/getErrorMessage';
import { ContributionRecipientsWrapper,FixedWrapper } from './ContributionEmployeesSettings.styles';
import ContributionRecipient from './ContributionRecipient';
import EditDataModalPopup from './EditDataModalPopup';
import HEADERS from './HEADERS';
import PER_PAGE_OPTIONS from './PER_PAGE_OPTIONS';
import { ContributionAccount, CreateManualContributionErrors } from './types';

const VALIDATED_ACCOUNT_TYPE = 'HSA';
const isValidatedAccountType = (acc?: ContributionAccount) => acc?.accountType === VALIDATED_ACCOUNT_TYPE;

const allValuesToggling = (values: OptionKey[], allLength: number) => {
  const isAllSelected = values[values.length - 1] === ALL_OPTION.value
    || (!values?.some((value) => value === ALL_OPTION.value) && values.length === allLength - 1);

  if (values.length === 0 || isAllSelected) {
    return [ALL_OPTION.value];
  }
  
  const isAllChangedToAnother = values.length === 2 && values[0] === ALL_OPTION.value;

  if (isAllChangedToAnother) {
    return values.filter((val) => val !== ALL_OPTION.value);
  }

  return values;
};

const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'A-Z',
  },
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Z-A',
  },
];
const DEFAULT_PER_PAGE = 10;
const DEFAULT_PAGE = 1;
const PAGE_STEP = 1;

interface Props {
  isScrolled?: boolean;
}

const ContributionEmployeesSettings: React.FC<Props> = ({ isScrolled }) => {
  const [sortBy, setSortBy] = useState(DefaultSortTypesEnum.ASC);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [hasMessageToCorrectErrors, setHasMessageToCorrectErrors] = useState(false);
  const [areErrorsShown, setAreErrorsShown] = useState(false);
  const [cleaningInitiator, setCleaningInitiator] = useState(false);
  const [isUpdateAllFormVisible, setIsUpdateAllFormVisible] = useState(false);
  const [backendErrors, setBackendErrors] = useState<CreateManualContributionErrors[]>();
  const { recipients: notSortedRecipients, segment, reset } = useRecipientsStore();
  const recipients = useMemo(() => [...notSortedRecipients]
    .sort((a, b) => {
      if (sortBy === DefaultSortTypesEnum.DESC) {
        return b.lastName?.localeCompare(a.lastName);
      }

      return a.lastName?.localeCompare(b.lastName);
    }),
  [notSortedRecipients, sortBy]);
  const notDisabledAccounts = useMemo(() => recipients
    ?.map((recipient) => recipient?.contributionAccounts
      ?.filter((acc) => !acc?.isDisabled)).flat(), [recipients]);
  const recipientsWithPlansHSA = useMemo(() => recipients
    ?.filter((recipient) => recipient?.contributionAccounts
      ?.some(isValidatedAccountType)),
  [recipients]);
  const plansHSA = useMemo(() => uniqBy(recipientsWithPlansHSA
    .map((recipient) => recipient.contributionAccounts
      ?.filter(isValidatedAccountType))
    .flat(), (item) => item?.planId),
  [recipientsWithPlansHSA]);
  const allUniquetaxYears = useMemo(() => ([...new Set(plansHSA.map((item) => item?.taxYear as string))]), [plansHSA]);
  const { areLimitsLoading } = useGetLimitsQuery({
    employeeIds: recipientsWithPlansHSA.map((item) => item.employeeId || ''),
    planIds: plansHSA.map((item) => item?.planId as string),
    taxYears: allUniquetaxYears,
  });

  const handleOnCreateSuccess = useCallback(() => {
    setIsSuccessModal(true);
    reset();
  }, [reset]);

  const { areCoveragePeriodaLoading } = useGetCoveragePeriodsQuery({ recipients });
  const { isLoading, createManualContribution } = useCreateManualContributionQuery();
  const [searchString, setSearchString] = useState('');
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE);

  const [selectedAccounts, setSelectedAccounts] = useState<OptionKey[]>([ALL_OPTION.value]);
  const plans = useMemo(() => {
    const plansFromContributions = recipients?.map((item) => item?.contributionAccounts?.map((acc) => ({
      planCode: acc?.planCode,
      planId: acc?.planId,
      taxYear: acc?.taxYear,
    })))?.flat();
    const uniquePlanCodes = [... new Set(plansFromContributions?.map((item) => item?.planCode))];
    const uniquePlans = uniquePlanCodes?.map((planCode) => plansFromContributions?.find((plan) => plan?.planCode === planCode));

    return uniquePlans;
  }, [recipients]);
  const taxYears = useMemo(() => compact([...new Set(plans?.map((plan) => plan?.taxYear))].sort((a, b) => toNumber(a) - toNumber(b))), [plans]);
  const accountsOptions = useMemo(() => {
    const options = plans.sort((a, b) => toNumber(a?.planId) - toNumber(b?.planId)).map((plan) => ({
      key: plan?.planCode,
      value: plan?.planCode,
      title: plan?.planCode,
    }));

    return [ALL_OPTION, ...options];
  }, [plans]);
  const accountsValues = useMemo(() => allValuesToggling(selectedAccounts, accountsOptions.length), [accountsOptions, selectedAccounts]);
  const hasAccountSelected = useMemo(
    () => accountsValues.length > 0
      && !accountsValues.some((val) => val === ALL_OPTION.value),
    [accountsValues],
  );
  const hasFilterParam = useMemo(() => Boolean(searchString || hasAccountSelected), [searchString, hasAccountSelected]);
  const filtredRecipients = useMemo(() => {
    if (hasFilterParam && recipients?.length) {
      return recipients
        .filter((recipient) => searchString ? recipient?.lastName?.toLowerCase().trim()?.includes(searchString.toLowerCase().trim()) : recipient)
        .filter((recipient) => hasAccountSelected ? recipient?.contributionAccounts?.some((acc) => accountsValues?.some((item) => item === acc?.planCode)) : recipient);
    }

    return recipients;
  }, [searchString, hasFilterParam, hasAccountSelected, accountsValues, recipients]);

  const maxPage = useMemo(() => Math.ceil(filtredRecipients?.length / perPage) || DEFAULT_PAGE, [perPage, filtredRecipients.length]);
  const nextPage = useMemo(() => page === maxPage ? page : page + PAGE_STEP, [page, maxPage]);
  const prevPage = useMemo(() => page === DEFAULT_PAGE ? page : page - PAGE_STEP, [page]);
  const validatedRecipients = useMemo(() => (page - PAGE_STEP) * perPage, [page, perPage]);
  const recipientsOnPage = useMemo(() => {
    if (perPage >= filtredRecipients.length) return filtredRecipients.length;
    if (page === maxPage) return (perPage - ((page * perPage) - filtredRecipients.length)) || perPage;
    return perPage;
  }, [filtredRecipients.length, page, perPage, maxPage]);
  const showedRecipientsOnCurrentPage = useMemo(() => filtredRecipients?.slice((page === DEFAULT_PAGE ? 0 : page * perPage - perPage), page * perPage),
    [filtredRecipients, page, perPage]);

  const onBack = useCallback(() => {
    if (prevPage < page) {
      setPage(prevPage);
    }
  }, [prevPage, page, setPage]);

  const history = useHistory();
  const goToContributionsPage = useCallback(() => history.push(routes.CONTRIBUTIONS), [history]);

  const resetFilters = useCallback(() => {
    setPage(DEFAULT_PAGE);
    setSelectedAccounts([ALL_OPTION.value]);
    setCleaningInitiator(!cleaningInitiator);
  }, [setPage, setSelectedAccounts, setCleaningInitiator, cleaningInitiator]);

  const showedRecipientIndexWithError = useMemo(() => findRecipientIndexWithError(showedRecipientsOnCurrentPage), [showedRecipientsOnCurrentPage]);

  useEffect(() => {
    setHasMessageToCorrectErrors(showedRecipientIndexWithError !== -1 && areErrorsShown);
  }, [showedRecipientIndexWithError, areErrorsShown]);

  const onSubmit = useCallback(async () => {
    let pageWithFirstError = 0;

    if (showedRecipientIndexWithError !== -1) {
      setAreErrorsShown(true);
      return setHasMessageToCorrectErrors(true);
    }

    const recipientIndexWithError = findRecipientIndexWithError(recipients);

    if (nextPage > page && !hasMessageToCorrectErrors) {
      setAreErrorsShown(false);
      return setPage(nextPage);
    }

    if (recipientIndexWithError !== -1) {
      pageWithFirstError = Math.ceil((recipientIndexWithError + 1) / perPage);
    }

    if ((pageWithFirstError && nextPage !== page && pageWithFirstError <= page)
      || (pageWithFirstError && nextPage === page)) {
      resetFilters();
      setAreErrorsShown(true);
      setHasMessageToCorrectErrors(true);
      setTimeout(() => setPage(pageWithFirstError), 300);
      return;
    }

    setHasMessageToCorrectErrors(false);

    if (nextPage > page) {
      setAreErrorsShown(false);
      return setPage(nextPage);
    }

    if (page === nextPage) {
      const creationErrors = await createManualContribution({ recipients, segment });
      if (creationErrors.data.errors) {
        setBackendErrors(creationErrors.data.errors);
        setHasMessageToCorrectErrors(true);
        return;
      }
      handleOnCreateSuccess();
    }
  }, [
    showedRecipientIndexWithError,
    nextPage,
    page,
    perPage,
    setPage,
    recipients,
    resetFilters,
    hasMessageToCorrectErrors,
    setHasMessageToCorrectErrors,
    createManualContribution,
    segment,
    handleOnCreateSuccess,
  ]);

  return (
    <>
      <FixedWrapper background="canvas" width="100%">
        <Topbar hideHeader={isScrolled} />
        <Box
          width={globalTheme.defaultContentWidth}
          alignSelf="center"
          direction="row"
          justify="between"
          wrap
          margin={{
            top: isScrolled ? 'spacing4' : 'spacing24',
          }}
        >
          <Box direction="row" width="100%" justify="between" align="center">
            <Box margin={{ bottom: isScrolled ? 'spacing8' : 'spacing24' }}>
              <Inscription
                size={isScrolled ? 'xl' : '3xl'}
                weight="bold"
                color="textBody"
                lineHeight="40px"
              >Contribution Employees Settings
              </Inscription>
            </Box>
            {/* <AppButton testId='save_as_draft' buttonType="secondary" {...isScrolled ? { style: { height: '35px' } } : {}}>
              <Inscription {...isScrolled ? { size: 'small' } : {}}>Save as Draft</Inscription>
            </AppButton> */}
          </Box>
          <Box background="module" round={{ corner: 'top' }} width="100%" pad={{ horizontal: 'spacing24', bottom: 'spacing8', top: isScrolled ? 'spacing8' : 'spacing24' }}>
            <Box height="40px" direction="row" justify='between' align="center" margin={{ bottom: isScrolled ? 'spacing8' : 'spacing12' }}>
              <Inscription weight="bold" lineHeight="22px" size={isScrolled ? '14px' : '16px'} color="textBody">
                Recipients: {filtredRecipients?.length
                  ? (filtredRecipients?.length > perPage ? `${validatedRecipients + recipientsOnPage}/${filtredRecipients?.length}` : filtredRecipients?.length)
                  : 0}
              </Inscription>
              <Box direction="row">
                <Box width="200px">
                  <SearchInput
                    hasSearchingHistory
                    testId="ContributionEmployeesSettings"
                    value={searchString}
                    placeholder="Search by name"
                    onChange={(val) => {
                      if (searchString !== val) {
                        setSearchString(val);
                        setPage(DEFAULT_PAGE);
                      }
                    }}
                    resetValue={cleaningInitiator}
                    disabled={hasMessageToCorrectErrors}
                  />
                </Box>
                <Box width="150px" margin={{ left: 'spacing12' }}>
                  <SelectDropdown
                    testId="show_items_per_page"
                    id="show_items_per_page"
                    activeTitle
                    ellipsisMode
                    name="Show"
                    prefix="Show"
                    options={PER_PAGE_OPTIONS}
                    onChange={(val) => {
                      setPerPage(toNumber(val));
                      setPage(DEFAULT_PAGE);
                    }}
                    defaultValue={toString(perPage)}
                    singleMode
                    value={toString(perPage)}
                    disabled={hasMessageToCorrectErrors}
                  />
                </Box>
                <Box width="150px" margin={{ left: 'spacing12' }}>
                  <SelectDropdown
                    id="accounts"
                    testId="accounts"
                    activeTitle
                    ellipsisMode
                    name="Accounts"
                    prefix="Accounts"
                    disabled={(!filtredRecipients?.length && !hasAccountSelected) || hasMessageToCorrectErrors}
                    options={accountsOptions}
                    values={accountsValues}
                    onChangeValues={(val: OptionKey[]) => {
                      setSelectedAccounts(val);
                      setPage(DEFAULT_PAGE);
                    }}
                  />
                </Box>
                <Box margin={{ horizontal: 'spacing12' }} width={{ min: '165px' }}>
                  <SelectDropdown
                    id="sort_by_last_name"
                    disabled={false}
                    name="Name"
                    prefix="Name"
                    singleMode
                    value={sortBy}
                    onChange={(val?: OptionKey) => {
                      setPage(DEFAULT_PAGE);
                      setSortBy(val as DefaultSortTypesEnum);
                    }}
                    activeTitle
                    options={SORTING_OPTIONS}
                  />
                </Box>
                <AppButton buttonType="secondary" onClick={() => setIsUpdateAllFormVisible(true)}>Update All Records</AppButton>
              </Box>
            </Box>
            <Box height="32px" background="legend" round="8px" justify="center" pad={{ horizontal: 'spacing24' }}>
              <Box direction="row">
                {HEADERS.map((item) => (
                  <Box width={item.width} key={item.title}>
                    <Inscription size="small" weight="bold" lineHeight="normal" color="textBody">{item.title}</Inscription>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </FixedWrapper>

      <Box
        flex
        direction="column"
        width={globalTheme.defaultContentWidth}
        height={{ min: 'max-content' }}
      >
        <Box
          background={{ color: 'module' }}
          round={{ corner: 'bottom', size: 'moduleRound' }}
          pad={{ horizontal: 'spacing24', bottom: 'spacing24', top: isScrolled ? '270px' : '300px' }}
        >
          {filtredRecipients?.length ? null : (
            <NoSearchItemsPlaceholder
              placeholderTitle={hasFilterParam ? 'No results have been found' : 'There are no activity records yet'}
              node={hasFilterParam ? (
                <SearchPlaceholderIcon size="xxlarge" color="iconSecondary" />
              ) : (
                <AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />
              )}
              description={hasFilterParam ? 'Try to adjust your search or filter to find what you\'re looking for.' : ''}
            />
          )}

          <Box>
            <ContributionRecipientsWrapper>
              {showedRecipientsOnCurrentPage?.map((recipient) => (
                <Box key={recipient?.employeeId}>
                  <ContributionRecipient
                    recipient={recipient}
                    taxYears={taxYears}
                    areErrorsShown={areErrorsShown}
                    backendErrors={backendErrors}
                  />
                </Box>
              ))}
            </ContributionRecipientsWrapper>
            {filtredRecipients?.length && filtredRecipients?.length > perPage ? (
              <Box justify="center" align="center" margin={{ top: 'spacing16' }}>
                <Inscription color="textSecondary">
                  {validatedRecipients + recipientsOnPage} records out of {filtredRecipients?.length}
                </Inscription>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box width="100%" height={{ min: '88px' }} background="module" margin={{ top: 'spacing24' }} justify="center">
        <Box
          width={globalTheme.defaultContentWidth}
          alignSelf="center"
          direction="row"
          justify="between"
          wrap
          pad={{ horizontal: 'spacing8' }}
        >
          <AppButton testId='cancel_button' buttonType="tertiary" onClick={goToContributionsPage}>Cancel</AppButton>
          <Box direction="row" align="center">
            {hasMessageToCorrectErrors ? (
              <Inscription lineHeight="20px" color="danger" margin={{ right: '24px' }}>Please correct the highlighted errors to submit the config</Inscription>
            ) : null}
            {page === DEFAULT_PAGE ? null : (
              <AppButton
                width="160px"
                buttonType="secondary"
                onClick={onBack}
                disabled={page === DEFAULT_PAGE || hasMessageToCorrectErrors}
              >
                Previous
              </AppButton>
            )}
            <AppButton
              width="160px"
              style={{ marginLeft: globalTheme.spacings.spacing12 }}
              onClick={onSubmit}
              disabled={!notDisabledAccounts?.length || hasMessageToCorrectErrors}
            >
              {isLoading || areLimitsLoading || areCoveragePeriodaLoading ? <Preloader color="white" /> : (nextPage === page ? 'Submit' : `Next (${page}/${maxPage})`)}
            </AppButton>
          </Box>
        </Box>
      </Box>

      {isUpdateAllFormVisible ? (
        <EditDataModalPopup
          isVisible
          close={() => setIsUpdateAllFormVisible(false)}
          taxYears={taxYears}
          onSave={() => {
            resetFilters();
            setHasMessageToCorrectErrors(false);
            setAreErrorsShown(false);
          }}
        />
      ) : null}

      {isSuccessModal ? (
        <SuccessModal
          testId="manual_contribution_is_created_successfully"
          visible={isSuccessModal}
          onSetVisible={() => {
            setIsSuccessModal(false);
            goToContributionsPage();
          }}
          header="Contributions have been submitted successfully"
          buttonText="Got It!"
          onSubmit={goToContributionsPage}
          buttonStyle={{
            width: '200px',
            marginTop: '-20px',
          }}
        />
      ) : null}
    </>
  );
};

export default ContributionEmployeesSettings;
