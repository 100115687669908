import React from 'react';
import {
  Box, Button, RadioButton,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { DropButton } from '@/components/controls/DropButton';
import { PaymentMethod } from '@/modules/employee/employee.types';
import spacings from '@/styles/spacings';

import { PaymentMethodLabel } from '../PaymentMethodLabel';
import { usePaymentMethodsStore } from '../usePaymentMethods.store';

interface StyledButtonProps {
  isWarning?: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  height: 21px !important;
  min-height: 21px !important;
  ${({ theme }) => theme.fonts.text14Regular};
  font-weight: 400 !important;
  margin-bottom: ${({ theme }) => theme.spacings.s};
  color: ${({ theme, isWarning }) => (isWarning ? `${theme.colors.danger} !important` : '')};
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.danger : theme.colors.textAccent)};
  }
`;

interface Props {
  method: PaymentMethod;
  disableOptions?: boolean;
  editPaymentMethod?: () => void;
  hasPaymentFreeTimeLabel?: boolean;
}

export const AddCardPaymentMethod: React.FC<Props> = ({
  method,
  disableOptions,
  editPaymentMethod,
  hasPaymentFreeTimeLabel = true,
}) => {
  const handleSetChecked = usePaymentMethodsStore((state) => state.handleSetChecked);
  const handleSetEditableMethodPaymentMethod = usePaymentMethodsStore(
    (state) => state.handleSetEditableMethodPaymentMethod,
  );
  return (
    <Box
      data-testid="AddCardPaymentMethod_container"
      key={method.id}
      border={{ side: 'top', color: 'border2', size: 'small' }}
      margin={{ top: 'small' }}
      pad={{ top: 'small' }}
      direction="row"
      align="center"
      justify="between"
    >
      <Box direction="row" align="center">
        <Box margin={{ right: spacings.l }}>
          <RadioButton
            name={`${method?.accountId || ''}`}
            data-testid={`radio-button-option-${method?.type}-${method?.id}`}
            key={method?.accountId}
            checked={method.isChecked || false}
            onChange={() => handleSetChecked(method?.id || '')}
          />
        </Box>

        <PaymentMethodLabel paymentMethod={method} hasPaymentFreeTimeLabel={hasPaymentFreeTimeLabel} />
      </Box>

      {
        !disableOptions
        && (
          <DropButton>
            <Box>
              <StyledButton
                type="button"
                onClick={() => {
                  if (editPaymentMethod) {
                    handleSetEditableMethodPaymentMethod(method);
                    editPaymentMethod();
                  }
                }}
              >
                Edit
              </StyledButton>
            </Box>
          </DropButton>
        )
      }

    </Box>
  );
};
