import { storeCreator } from '@/utils/store/store.utils';

interface State {
  newAddedEnrollmentMatrixId?: string;
  activePage?: number;
  setNewAddedEnrollmentMatrixId: (value: string) => void;
  setActivePage: (value: number) => void;
  handleResetNewAddedEnrollment: () => void;
  handleResetActivePage: () => void;
}

export const useEnrollmentMatrixStore = storeCreator<State>((set) => ({
  newAddedEnrollmentMatrixId: undefined,
  activePage: undefined,

  setNewAddedEnrollmentMatrixId: (value: string) => set(() => ({
    newAddedEnrollmentMatrixId: value,
  })),
  setActivePage: (value: number) => set(() => ({
    activePage: value,
  })),
  handleResetNewAddedEnrollment: () => set(() => ({
    newAddedEnrollmentMatrixId: undefined,
  })),
  handleResetActivePage: () => set(() => ({
    activePage: undefined,
  })),
}));
