import React from 'react';
import {
  Box,
  Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import HeaderLink from '@/components/elements/HeaderLink';

import CreateCarrierConfig from './CreateCarrierConfig';

interface Props {
  isPageMode?: boolean;
}

const TopSection: React.FC<Props> = ({
  isPageMode,
}) => (
  <Box direction="row" justify="between" align="center" wrap margin={{ top: isPageMode ? 'l' : '0', bottom: isPageMode ? 'l' : '0' }}>
    {
      isPageMode
        ? (
          <Text size="3xl" weight="bold" color="textBody" style={{ lineHeight: '44px' }}>
            Carrier Config
          </Text>
        ) : (
          <Box margin={{ top: 'l', bottom: 'm' }}>
            <HeaderLink path={ROUTES.CARRIER_CONFIG} title="Carrier Config" />
          </Box>
        )
    }
    <CreateCarrierConfig />
  </Box>
);

export default TopSection;
