import React, { useCallback } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useHistory } from '@/modules/core/hooks';
import { FileSummary } from '@/modules/FileManager/FileSummary';
import { useAuthStore } from '@/modules/user/stores';

const FileSummaryPage = () => {
  const { user } = useAuthStore();
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_ALL },
      ]}
      accessDeniedAction={goHome}
    >
      <Box align="center" width="100%" height="fit-content">

        <FileSummary />

      </Box>
    </Access>
  );
};

export default FileSummaryPage;
