import React from 'react';
import { Box } from '@common-fe/common-fe';

import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PLAN_MODULES } from '../../plan.constants';

import AccountFundingParams from './AccountFundingParams';
import Contribution from './Contribution';
import { useAccountFundingFields } from './hooks';
import InterestAccruals from './InterestAccruals';
import Rollover from './Rollover';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const AccountFunding: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const fields = useAccountFundingFields();
  if (fields.length === 0) {
    return null;
  }

  return (
    <ModuleWrapper
      testId="account-funding"
      anchorId={PLAN_MODULES.ACCOUNT_FOUNDING}
      title="Account Funding"
      expandless
    >
      <Box>
        <Contribution
          isTemplateMode={isTemplateMode}
          isEditMode={isEditMode}
          onDirty={onDirty}
        />

        <AccountFundingParams
          isTemplateMode={isTemplateMode}
          isEditMode={isEditMode}
          onDirty={onDirty}
        />
        
        <InterestAccruals
          isTemplateMode={isTemplateMode}
          isEditMode={isEditMode}
          onDirty={onDirty}
        />

        <Rollover
          isTemplateMode={isTemplateMode}
          isEditMode={isEditMode}
          onDirty={onDirty}
        />
      </Box>
    </ModuleWrapper>
  );
};
export default React.memo(AccountFunding);
