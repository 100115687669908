import { useMemo } from 'react';

import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';
import { useCurrentOrganisationStore } from '@/modules/core/store';

export const useOrganizationOverridableProperties = (id?: string) => {
  const {
    observingOrganizationId,
    defaultOrganizationId,
    observingEmployerId,
    observingPartnerId,
    observingDistributorId,
    observingSubsidiaryId,
    observingMode,
  } = useCurrentOrganisationStore();

  const {
    formattedData: { overridableProperties: observingDefaultData },
    isLoading: isObservingDefaultDataLoading,
  } = useGetOrganizationById(defaultOrganizationId);

  const {
    formattedData: { overridableProperties: observingOrganizationData },
    isLoading: isObservingOrganizationDataLoading,
  } = useGetOrganizationById(observingOrganizationId);

  const {
    formattedData: { overridableProperties: observingEmployerData },
    isLoading: isEmployerLoading,
  } = useGetOrganizationById(observingEmployerId);

  const {
    formattedData: { overridableProperties: observingPartnerData },
    isLoading: isPartnerLoading,
  } = useGetOrganizationById(observingPartnerId);

  const {
    formattedData: { overridableProperties: observingDistributorData },
    isLoading: isDistributorLoading,
  } = useGetOrganizationById(observingDistributorId);

  const {
    formattedData: { overridableProperties: observingSubsidiaryData },
    isLoading: isSubsidiaryLoading,
  } = useGetOrganizationById(observingSubsidiaryId);

  const {
    formattedData: { overridableProperties: organizationDataById },
    isLoading: isOrganizationByIdLoading,
  } = useGetOrganizationById(id);

  const isLoading = useMemo(() => isObservingDefaultDataLoading
  || isObservingOrganizationDataLoading
  || isEmployerLoading
  || isPartnerLoading
  || isDistributorLoading
  || isSubsidiaryLoading
  || isOrganizationByIdLoading,
  [
    isObservingDefaultDataLoading,
    isObservingOrganizationDataLoading,
    isEmployerLoading,
    isPartnerLoading,
    isDistributorLoading,
    isSubsidiaryLoading,
    isOrganizationByIdLoading,
  ]);

  return {
    observingDefaultData,
    observingOrganizationData,
    observingEmployerData,
    observingPartnerData,
    observingDistributorData,
    observingSubsidiaryData,
    organizationDataById,
    observingMode,
    isLoading,
  };
};
