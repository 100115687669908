import { TOCSidebarProps } from '@/modules/core/components/TOCSidebar/TOCSidebar.types';
import { HEALTH_PLAN_MODULES } from '@/modules/HealthPlan/HealthPlan.constants';

export const planConfigModuleNames = {
  [HEALTH_PLAN_MODULES.BASIC_PROPERTIES]: 'Basic Properties',
  [HEALTH_PLAN_MODULES.COVERAGE_LEVELS]: 'Coverage Levels',
  [HEALTH_PLAN_MODULES.COMPLEX_CONFIGURATION]: 'Payment Rule Configuration',
  [HEALTH_PLAN_MODULES.FINAL_SETUP]: 'Final Setup',
} as const;

export const useHealthPlanModules = (exluce?: string[]): TOCSidebarProps['stepsMap'] => Object
  .keys(planConfigModuleNames)
  .reduce((map, anchorId) => {
    if (exluce && exluce.includes(anchorId)) {
      return map;
    }
    return ({
      ...map,
      [anchorId]: {
        title: planConfigModuleNames[anchorId],
      },
    });
  }, {});
