import { useCallback, useEffect,useState } from 'react';
import _ from 'lodash';

import { useUpdateEnrollmentMatrix } from '@/modules/ProcessingDefinition/EnrollmentMatrix/hooks';

import { EnrollmentMatrixItem } from '../ProcessingDefinition.types';

export default (
  values: EnrollmentMatrixItem[],
) => {
  const [list, setList] = useState<EnrollmentMatrixItem[]>([]);
  const [draggedItemId, setDraggedItemId] = useState<string>();
  const [draggedEnterPriority, setDraggedPriority] = useState<string>();
  const [startUpdating, setStartUpdating] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  const {
    handlePreSubmit,
  } = useUpdateEnrollmentMatrix();

  useEffect(() => {
    if (!_.isEqual(list, values)) {
      setList([...values]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragStart = (event: any, id: string) => {
    setDraggedItemId(id);
    event.dataTransfer.setData('text/plain', id);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnter = useCallback((event: any, id: string, priority: string) => {
    event.preventDefault();
    if (draggedItemId !== id) {
      setStartUpdating(true);
      const hoveredItemIndex = list.findIndex((item) => item?.id === id);
      setHoveredIndex(hoveredItemIndex);
      setDraggedPriority(priority);
    }
  }, [draggedItemId, list]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDrop = (event: any) => {
    event.preventDefault();
    const newList = [...list];
    const draggedItemIndex = list.findIndex((item) => item?.id === draggedItemId);
    const draggedItem = list[draggedItemIndex];
    newList.splice(draggedItemIndex, 1);
    if (hoveredIndex || hoveredIndex === 0) newList.splice(hoveredIndex, 0, draggedItem);
    setList(newList);
  };

  const handleDragEnd = () => {
    setDraggedItemId(undefined);
    handlePreSubmit(draggedItemId, draggedEnterPriority, undefined, true);
    setTimeout(() => {
      setStartUpdating(false);
      setHoveredIndex(undefined);
    }, 1500);
  };

  return {
    list,
    draggedItemId,
    hoveredIndex,
    startUpdating,
    handleDragStart,
    handleDrop,
    handleDragEnd,
    handleDragEnter,
    handleDragOver,
  };
};
