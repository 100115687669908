import styled from 'styled-components';

export const Wrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `${theme.spacings.spacing32} ${theme.spacings.spacing24}`};
  .icon {
    font-size: ${({ theme }) => theme.spacings.spacing72};
    color: ${({ theme }) => theme.colors.success};
    &.warning-color {
      color: ${({ theme }) => theme.colors.warning};
    }
  }
`;
export const Header = styled.h3`
  ${({ theme }) => theme.fonts.text24Bold};
  color: ${({ theme }) => theme.colors.textBody};
  margin-top: ${({ theme }) => theme.spacings.spacing12};
  margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  text-align: center;
`;
export const HelpText = styled.p`
  margin: 0 0 ${({ theme }) => theme.spacings.spacing24};
  ${({ theme }) => theme.fonts.text16Regular};
  text-align: center;
`;
