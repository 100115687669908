import { useEffect, useMemo, useState } from 'react';
import {
  Field,
  FieldStateTypes,
  FieldTypes,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import Permissions from '@/common/permissions';
import { DefaultFields } from '@/common/types';
import { useHasAccess } from '@/modules/core/hooks';
import { usePlanTemplatesQuery, usePlanTypesQuery } from '@/modules/plan/queries';

import usePlanTemplateStore, { PlanTemplatePayload } from '../stores/usePlanTemplate.store';

const REQUIRED_TEXT = 'This field is required';
const TEMPLATE_NAME_KEY = 'templateName';
export default (defaultFields?: Partial<PlanTemplatePayload>) => {
  const isViewHidden = useHasAccess([
    {
      permission: Permissions.VIEW_HIDDEN_PLAN_FIELDS,
    },
  ]);
  const [defaultValues, setDefaultValues] = useState<Partial<PlanTemplatePayload>>({});
  const parentTemplate = usePlanTemplateStore((state) => state.state.parentTemplate);
  const handleSetState = usePlanTemplateStore((state) => state.setState);
  const [searchValue, setSearchValue] = useState('');
  const {
    formattedListTemplates,
    formattedTemplates,
    isLoading,
  } = usePlanTemplatesQuery(true, searchValue);
  const planTypes = usePlanTypesQuery();

  const currentTemplate = useMemo(() => {
    if (parentTemplate) {
      return formattedListTemplates?.find((item) => item.key === parentTemplate?.[0]);
    }
    return null;
  }, [parentTemplate, formattedListTemplates]);

  useEffect(() => {
    if (currentTemplate) {
      handleSetState({
        parentTemplate,
        planType: currentTemplate?.accountType,
        templateName: currentTemplate?.name,
      });
      setDefaultValues({
        planType: currentTemplate?.accountType,
        templateName: currentTemplate?.name,
      });
    }
  }, [currentTemplate, handleSetState, parentTemplate]);

  const isNameHidden = useMemo(
    () => currentTemplate?.nameState === FieldStateTypes.Hidden && !isViewHidden,
    [currentTemplate, isViewHidden],
  );

  const PLAN_TYPE_KEY = 'planType';
  let fields = useMemo<Field[]>(() => [
    {
      name: 'parentTemplate',
      type: FieldTypes.GroupedPlansDropdown,
      label: 'Parent template',
      onSearch: setSearchValue,
      searchValue,
      options: formattedTemplates,
      groupTitle: 'Categories',
      isSingleMode: true,
      isNarrowLabelMode: true,
      isLoading,
    },
    {
      name: TEMPLATE_NAME_KEY,
      type: FieldTypes.Text,
      label: 'Plan template name',
      disabled: currentTemplate?.nameState === FieldStateTypes.Locked
      || (isViewHidden && currentTemplate?.nameState === FieldStateTypes.Hidden),
      placeholder: 'Enter Plan name',
      defaultValue: defaultValues.templateName,
      showRequireIcon: true,
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },

    {
      name: PLAN_TYPE_KEY,
      type: FieldTypes.AutocompleteDropdown,
      label: 'Plan type',
      value: parentTemplate ? defaultFields?.planType : '',
      disabled: currentTemplate?.accountTypeState === FieldStateTypes.Locked
      || currentTemplate?.accountTypeState === FieldStateTypes.Hidden,
      options: planTypes,
      defaultValue: defaultValues.planType,
      placeholder: 'Select a plan type',
      showRequireIcon: true,
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },

  ], [currentTemplate, isViewHidden, formattedTemplates, isLoading,
    defaultValues, parentTemplate, defaultFields, planTypes, searchValue]);
  if (currentTemplate?.accountTypeState === FieldStateTypes.Hidden && !isViewHidden) {
    fields = fields.filter((field) => field.name !== PLAN_TYPE_KEY);
  }
  if (isNameHidden) {
    fields = fields.filter((field) => field.name !== TEMPLATE_NAME_KEY);
  }
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const currentDefaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: currentDefaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
