interface OrgMap {
  [key: string]: string;
}
export const ORG_LEVEL_MAP: OrgMap = {
  SYSTEM: 'SYS',
  PARTNER: 'PT',
  EMPLOYER: 'ER',
  DISTRIBUTOR: 'DST',
  SUBSIDIARY: 'SSD',
  SUBGROUP: 'SUBGROUP',
  COMPANY: 'ER',

};
const MAX_LABEL_LENGHT = 3;
export default (orgName: string) => {
  if (ORG_LEVEL_MAP[orgName]) {
    return ORG_LEVEL_MAP[orgName];
  }

  return orgName?.slice(0, MAX_LABEL_LENGHT);
};
