import React, { useMemo } from 'react';
import {
  Box,   Field, FieldTypes, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { PermissionKey } from '@/common/constants';
import { TechnicalSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

const Label: React.VFC<{ title: string, desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Text margin={{ bottom: '2px' }} weight="bold" color="textBody">{title}</Text>
    <Box>
      <Text weight="normal" color="textSecondary" style={{lineHeight: 1.2}}>
        {desc}
      </Text>
    </Box>
  </Box>
);

const VALIDATOR_MESSAGE = 'Timeout value should be from 1 to 99';
const VALIDATOR = yup.string().test({
  test: (val) => {
    if (!val) return true;
    if (_.toNumber(val) <= 0 || _.toNumber(val) > 99) return false;
    return true;
  },
  message: VALIDATOR_MESSAGE,
});

export const useTechnicalSettingsForm = (currentValues: TechnicalSettings) => {
  const sessionTimeoutWarnPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.SESSION_TIMEOUT_WARN,
  );
  const sessionTimeoutLogoutPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.SESSION_TIMEOUT_LOGOUT,
  );

  const { formattedData, isLoading } = useGetEmployer();

  const fields = useMemo(() => {
    if (isLoading) return [];
    const result = [
      {
        name: 'sessionTimeoutWarn',
        type: FieldTypes.Number,
        fill: false,
        label: (
          <Label
            title="Timeout warning (mins)"
            desc="Enter a number of minutes before showing a session timeout warning popup"
          />
        ),
        value: currentValues?.sessionTimeoutWarn,
        ...formattedData?.overridableProperties?.sessionTimeoutWarn ? {
          defaultValue: formattedData?.overridableProperties?.sessionTimeoutWarn,
        } : {},
        validator: VALIDATOR,
        ...sessionTimeoutWarnPermProps,
      },
      {
        name: 'sessionTimeoutLogout',
        type: FieldTypes.Number,
        fill: false,
        label: (
          <Label
            title="Timeout logout (mins)"
            desc="Enter the number of minutes before logout user if warning popup not closed"
          />
        ),
        value: currentValues?.sessionTimeoutLogout,
        ...formattedData?.overridableProperties?.sessionTimeoutLogout ? {
          defaultValue: formattedData?.overridableProperties?.sessionTimeoutLogout,
        } : {},
        validator: VALIDATOR,
        ...sessionTimeoutLogoutPermProps,
      },
    ];

    return result as Field[];
  }, [
    formattedData,
    currentValues,
    isLoading,
    sessionTimeoutWarnPermProps,
    sessionTimeoutLogoutPermProps,
  ]);

  return fields;
};
