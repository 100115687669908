import { storeCreator } from '@/utils/store/store.utils';

import { NotificationPriorityPayload } from '../Communications.types';

interface State {
  handleReset: () => void;
  handleSetValues: (values: NotificationPriorityPayload) => void;
  state: NotificationPriorityPayload
}

export const DEFAULT_VALUES: NotificationPriorityPayload = {
  notificationPriority: '',
  communicationConfigChannels: [],
  communicationChannels: [],
};

const useCommunicationChannelStore = storeCreator<State>((set) => ({
  state: DEFAULT_VALUES,
  handleReset: () => set(() => ({ state: DEFAULT_VALUES })),
  handleSetValues: (state: NotificationPriorityPayload) => set(() => ({ state })),
}));

export default useCommunicationChannelStore;
