import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { VALIDATORS } from '@/common';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import { COPAY_ITEM_KEY, CopayItem,GET_COPAY_ITEM_POSTFIX } from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';

interface Params {
  // index: number,
  isViewMode?: boolean;defaultValues: CopayItem;copayIds: string[]
}
export default ({
  copayIds, isViewMode, defaultValues,
}: Params): Field[] => {
  const { list: serviceCategories } = useServiceCategoriesQuery();
  const amountValidator = useMemo(() => {
    const range = copayIds
      .filter((item) => item !== defaultValues.id)
      .map((item) => [GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.CATEGORY, item),
        GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.AMOUNT, item)]);
    const validator = VALIDATORS.REQUIRED_POSITIVE_NUMBER_ONLY.test({
      exclusive: false,
      test(val, context) {
        const currentCategory = _.get(context, `parent[${GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.CATEGORY, defaultValues.id)}]`);

        const isMatched = range.some((pair) => {
          const [category, amount] = pair;
          const categoryValue = _.get(context, `parent[${category}]`);
          const amountValue = _.get(context, `parent[${amount}]`);
          if (currentCategory === categoryValue && _.toNumber(amountValue) === _.toNumber(val)) {
            return true;
          }
          return false;
        });

        return !isMatched;
      },
      message: 'A copay with this amount already exists. Please enter a unique amount.',
    });
    return validator;
  }, [copayIds, defaultValues.id]);
  const fields = useMemo<Field[]>(() => [
    {
      name: GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.CATEGORY, defaultValues.id),
      type: FieldTypes.Dropdown,
      label: 'Category',
      showRequireIcon: !isViewMode,
      singleMode: true,
      validator: VALIDATORS.REQUIRED_STRING,
      defaultValue: defaultValues.category,
      placeholder: 'Select category',
      options: serviceCategories || [],
    },
    {
      name: GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.AMOUNT, defaultValues.id),
      type: FieldTypes.Currency,
      label: 'Amount',
      showRequireIcon: !isViewMode,
      defaultValue: defaultValues.amount,
      validator: amountValidator,
      placeholder: 'Amount',

    },

  ], [amountValidator, defaultValues, isViewMode, serviceCategories]);
  return fields;
};
