import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import {
  EmployerDtoPayload,
} from '@/modules/employer/types';

const QUERY_KEY = 'getEmailTemplatesIdsQueryKey';

export const useGetEmailTemplatesIdsQuery = (id?: string | null) => {
  const {
    data,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    [QUERY_KEY, id],
    () => api.get<EmployerDtoPayload>(
      PATHS.GET_ORGANIZATION_BY_ID(id),
      {
        headers: {
          'X-DETAILS-RESOLVERS': 'NOTIFICATION_PROPERTIES',
        },
      },
    ),
    {
      enabled: Boolean(id),
    },
  );

  const notificationTemplatesIds = useMemo(() => {
    if (data?.data?.notification_properties) {
      return Object.values(data.data.notification_properties);
    }
    return [];
  }, [data]);

  return {
    data: data?.data,
    notificationProperties: data?.data?.notification_properties,
    notificationTemplatesIds,
    isFetched,
    refetch,
    isLoading,
    isSuccess,
    isError,
  };
};
