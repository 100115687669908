import { AllowedPaymentMethods, WaitingForFundsContinuePayment } from '@/modules/employer/types';
import { storeCreator } from '@/utils/store/store.utils';

const INIT_STATE = {};

interface ReimbursementState {
  isReimbursementAllowed?: boolean;
  isBillPayAllowed?: boolean;
  isClaimOffsetsAllowed?: boolean;
  allowedPaymentMethods?: AllowedPaymentMethods[];
  minimumPaymentReimbursementAmount?: number;
  waitingForFundsContinuePayment?: WaitingForFundsContinuePayment;
  waitingForFundsExpiration?: string;
}
interface State {
  state: ReimbursementState;
  setReimbursement: (value: ReimbursementState) => void;
}

const useStore = storeCreator<State>((set) => ({
  state: INIT_STATE,
  setReimbursement: (value: ReimbursementState) => set(() => ({
    state: value,
  })),
}));
const useReimbursementStore = () => ({ ...useStore() });

export default useReimbursementStore;
