import { useMemo } from 'react';

import { useAccountFundingParamsFieldsStateStore } from '../AccountFundingParams/stores';
import { useContributionFieldsStateStore } from '../Contribution/stores';
import { useInterestAccrualsStateStore } from '../InterestAccruals/stores';
import { useRolloverFieldsStateStore } from '../Rollover/stores';

export default () => {
  const rolloverStore = useRolloverFieldsStateStore((state) => state.state);
  const accountFundingParamsStore = useAccountFundingParamsFieldsStateStore((state) => state.state);
  const contributionConfigStore = useContributionFieldsStateStore((state) => state.state);
  const interestAccrualsStore = useInterestAccrualsStateStore((state) => state.state);
  const state = useMemo(() => ({
    ...accountFundingParamsStore,
    ...rolloverStore,
    ...contributionConfigStore,
    ...interestAccrualsStore,
  }), [accountFundingParamsStore, contributionConfigStore, interestAccrualsStore, rolloverStore]);
  return state;
};
