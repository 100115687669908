import React, { useMemo } from 'react';
import { Box, useImage } from '@common-fe/common-fe';

import { FAV_SIZE, LetterIconSizeEnum,LOGO_SIZE } from '@/common/constants';

import { LetterIcon, Props } from '../LetterIcon/LetterIcon';

import { ProportionalImage } from './Icon.styles';

interface IconProps extends Props {
  src?: string,
  height?: string | number;
  width?: string | number;
  testId?: string;
}

const LogoImage: React.FC<IconProps> = ({
  sizePx,
  size,
  src,
  text,
  height,
  testId,
  width,
}) => {
  const iconSize = useMemo(() => {
    if (sizePx) return `${sizePx}px`;
    return size === LetterIconSizeEnum.LOGO ? LOGO_SIZE : FAV_SIZE;
  }, [size, sizePx]);

  return (
    <Box data-testid={`${testId}-${iconSize}`}>
      <ProportionalImage
        data-testid={testId}
        data-testsize={iconSize}
        height={height ?? iconSize}
        width={width ?? iconSize}
        src={src}
        alt={text}
      />
    </Box>
  );
};

export const Icon: React.FC<IconProps> = ({
  src,
  type,
  size,
  text,
  sizePx,
  height,
  width,
  testId,
}) => {
  const { isLoading, isError } = useImage(src);

  if (isLoading) return null;

  if (src && !isError) {
    return (
      <LogoImage
        testId={testId}
        size={size}
        sizePx={sizePx}
        src={src}
        text={text}
        height={height}
        width={width}
      />
    );
  }

  return (
    <LetterIcon
      testId={testId}
      size={size}
      type={type}
      text={text}
      sizePx={sizePx}
    />
  );
};
