import { useMemo } from 'react';

import { useDependentsListQuery } from '../queries';

export interface DeductibleUser {
  id: string;
  fullName: string;
  isMe?: boolean;
}

interface Params {
  userId?: string;
  employeeUser?: DeductibleUser
  isIndividual?: boolean;
}
export default ({ userId, isIndividual, employeeUser }: Params) => {
  const { data: dependents, isLoading } = useDependentsListQuery(employeeUser?.id || '');

  const users = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const me = {
      id: employeeUser?.id || '',
      fullName: employeeUser?.fullName || '',
    };

    const list = [me, ...dependents] as DeductibleUser[];

    if (!isIndividual) {
      return list;
    }
    const found = list.find((currentUser) => currentUser.id === userId);

    if (found) {
      return [found];
    }
    return [me];
  }, [isLoading, employeeUser, dependents, isIndividual, userId]);
  return users;
};
