import { useMemo } from 'react';
import { costFormater } from '@common-fe/common-fe';

import { useGeneralInfoStore } from '@/modules/employee/Employee/PersonalInformation/GeneralInfo/stores';
import { useGetTaxYearFromIrsMaximumsQuery } from '@/modules/employer/components/Contributions/queries/useGetTaxYearFromIrsMaximums.query';

import { ContributionAchievementsName, useGetContributionsHistoryQuery } from '../queries/useGetContributionsHistory.query';

const MIN_CATCH_UP_ELECTION = 1000;

interface ContributionAchievementsTrackerProps {
  employeeId: string;
  taxYear?: string;
  accountId?: string;
  catchUpElection?: number;
}

export const useContributionAchievementsTracker = (props: ContributionAchievementsTrackerProps) => {
  const {
    employeeId,
    taxYear,
    accountId,
    catchUpElection,
  } = props;
  const { personId } = useGeneralInfoStore((state) => state.state);

  const {
    contributionAchievements,
    formattedData,
    isLoading: contributionHistoryLoading,
    refetch: refetchContributionHistory,
  } = useGetContributionsHistoryQuery(employeeId, `${personId}`, taxYear, accountId);

  const { lastDayForContribution } = useGetTaxYearFromIrsMaximumsQuery();

  const isWithCatchUp = useMemo(() => formattedData?.isWithCatchUp, [formattedData]);

  const preparedContributionAchievements = useMemo(() => {
    if (isWithCatchUp) {
      const catchUpItem = {
        id: ContributionAchievementsName.CATCH_UP,
        name: ContributionAchievementsName.CATCH_UP,
        maxValue: catchUpElection || MIN_CATCH_UP_ELECTION,
        maxInitialValue: catchUpElection || MIN_CATCH_UP_ELECTION,
        maxValueDescription: costFormater(catchUpElection || MIN_CATCH_UP_ELECTION, true),
      };
      return [...contributionAchievements, catchUpItem];
    }

    return contributionAchievements;
  }, [contributionAchievements, catchUpElection, isWithCatchUp]);

  const maxContributionValue = useMemo(() => contributionAchievements
    .find((item) => item.name === ContributionAchievementsName.FAMILY)?.maxInitialValue || 0,
  [contributionAchievements]);

  const currentContributionValue = useMemo(() => formattedData.currentValue, [formattedData]);

  return {
    preparedContributionAchievements,
    lastDayForContribution,
    formattedData,
    contributionHistoryLoading,
    isWithCatchUp,
    maxContributionValue,
    currentContributionValue,
    refetchContributionHistory,
  };
};
