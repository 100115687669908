import React, { useMemo, useState } from 'react';
import {
  AccountTypeTextes,
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  EmptyButton,
  InfoOutlineIcon,
  Text,
  Tip,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { CategoryType, Plan } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

const StyledButton = styled(EmptyButton)`
  ${({ theme }) => theme.fonts.text14Regular};
  & {
    padding: 0;
    span {
      display: flex;
    }
  }
`;

interface Props {
  data?: CategoryType;
  onDirty?: (isDirty: boolean) => void;
}

const MAX_ALLOWED_SERVICES = 10;

const ServiceItem: React.FC<Props> = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const serviceArray = useMemo(() => {
    if (!data?.services) return [];
    return data?.services.slice(0, showMore ? data?.services.length : MAX_ALLOWED_SERVICES);
  }, [showMore, data]);

  return (
    <>
      <Box margin={{ bottom: 'xs' }}>
        <Text size="medium" weight="bold">{`${data?.categoryType}: ${data?.services?.length}`}</Text>
      </Box>
      {
        serviceArray?.map((item) => (
          <Box
            key={item.id}
            justify="between"
            direction="row"
            margin={{ bottom: 'xs' }}
          >
            <Text margin={{ right: 'xs' }}>{item.name}</Text>
            <Box flex={{ grow: 1 }} border={{ side: 'bottom', size: 'small', color: 'border1' }} />
            <Box direction="row" margin={{ left: 'xs' }}>
              {
                item?.plans?.length ? item?.plans?.map((plan: Plan, index: number) => (
                  <Tip
                    key={plan.id}
                    plain
                    dropProps={{ align: { bottom: 'top' } }}
                    content={(
                      <Box
                        width="max-content"
                        height={{ max: 'l' }}
                        align="center"
                        justify="center"
                        background="background3"
                        border={{ size: 'small', color: 'popup' }}
                        pad={{ horizontal: 'spacing6' }}
                      >
                        <Text size="small" color="textOnColor">{plan.name}</Text>
                      </Box>
                    )}
                  >
                    <Box
                      width={{ max: '210px' }}
                      direction="row"
                      align="center"
                      style={{ cursor: 'pointer' }}
                      margin={{ right: item?.plans && item?.plans.length - 1 === index ? '0' : 'xs' }}
                    >
                      <InfoOutlineIcon color="iconAccent" size="14px" />
                      <Text
                        margin={{ left: 'xxs' }}
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {AccountTypeTextes[plan.accountType as keyof typeof AccountTypeTextes] || ''}
                      </Text>
                      {(item?.plans && item?.plans.length - 1) !== index && <Text size="14px">,</Text>}
                    </Box>
                  </Tip>
                )) : (
                  <Text>NaN</Text>
                )
              }
            </Box>
          </Box>
        ))
      }
      {
        data?.services && data?.services.length > MAX_ALLOWED_SERVICES && (
          <Box direction="row" align="start">
            <StyledButton
              leftIcon={
                showMore ? (
                  <ArrowUpIcon color="iconAccent" />
                ) : (
                  <ArrowDownIcon color="iconAccent" />
                )
              }
              onClick={() => setShowMore(!showMore)}
            >
              <Text color="iconAccent">Show More</Text>
            </StyledButton>
          </Box>
        )
      }
    </>
  );
};

export default ServiceItem;
