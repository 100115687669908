import React, { useEffect, useMemo, useRef } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Accordion } from '@/components/elements/Accordion';
import theme from '@/styles/theme';

import { HealthPlanStatuses } from '../HealthPlan.types';

import useEditMode from './hooks/useEditMode';
import BasicProperties from './BasicProperties';
import ComplexConfiguration from './ComplexConfiguration';
import CoverageLevels from './CoverageLevels';
import FinalSetup from './FinalSetup';
import PlanSetupWrapper from './HealthPlanSetupWrapper';
import PlanTopbar from './HealthPlanTopbar';
import {
  useCurrentHealthPlan,
  useHealthPlanSetupSetter,
} from './hooks';
import { usePlanLoadingStore, usePreviewStore, useSubmitFormStore } from './stores';

const StyledBox = styled(Box)`
  flex: 1 0;
`;

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 12;
`;

// const TIMEOUT = 1000;
interface Props {
  defaultEditMode?: boolean;
  isTemplateMode?: boolean;
}
const HealthPlan: React.FC<Props> = ({ defaultEditMode, isTemplateMode }) => {
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const activateButtonRef = useRef<HTMLButtonElement>(null);
  const reviewButtonRef = useRef<HTMLButtonElement>(null);

  const { healthPlanStatus } = useCurrentHealthPlan(isTemplateMode);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const isTemplateModeApplied = useMemo(
    () => isTemplateMode,
    [isTemplateMode],
  );

  const loading = usePlanLoadingStore((state) => state.loading);
  const isEditMode = useEditMode(
    defaultEditMode,
    healthPlanStatus === HealthPlanStatuses.ACTIVE
    || healthPlanStatus === HealthPlanStatuses.PENDING_ACTIVE,
  );
  const isEditModeApplied = useMemo(() => isEditMode && !previewMode, [isEditMode, previewMode]);

  const { handleReset } = useHealthPlanSetupSetter();
  const handleResetSubmit = useSubmitFormStore((state) => state.reset);
  useEffect(() => () => {
    handleResetSubmit();
    handleReset();
  }, [handleReset, handleResetSubmit]);
  if (loading) {
    return <Preloader testId="preloader" />;
  }

  return (
    <PlanSetupWrapper planStatus={healthPlanStatus}>
      <FixedWrapper height={{ min: 'fit-content' }} width="100%" background={{ color: 'canvas' }}>
        <PlanTopbar
          saveButtonRef={saveButtonRef}
          activateButtonRef={activateButtonRef}
          reviewButtonRef={reviewButtonRef}
          viewMode={!isEditMode}
          planStatus={healthPlanStatus}
          isTemplateMode={isTemplateMode}
        />
        <Accordion />
      </FixedWrapper>
      <StyledBox width={theme.defaultContentWidth}>
        <BasicProperties isEditMode={isEditModeApplied} isTemplateMode={isTemplateModeApplied} />
        <CoverageLevels isEditMode={isEditModeApplied} isTemplateMode={isTemplateModeApplied} />
        <ComplexConfiguration
          isEditMode={isEditModeApplied}
          isTemplateMode={isTemplateModeApplied}
        />
        {!previewMode && (
          <FinalSetup
            saveButtonRef={saveButtonRef}
            activateButtonRef={activateButtonRef}
            reviewButtonRef={reviewButtonRef}
            isTemplateMode={isTemplateMode}
            planStatus={healthPlanStatus}
            isEditMode={isEditModeApplied}
          />
        )}
      </StyledBox>
    </PlanSetupWrapper>
  );
};
export default HealthPlan;
