import React, { useCallback, useMemo,useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  AppButton,
  Box,
  Modal,
  Preloader,
  SiblingBoxesWithSpacing,
  Text,
  WarnModal,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { QUERY_KEY } from '@/modules/employer/hooks/useEmployersList.query';
import { GET_ORGANIZATION_LOCKS_KEY } from '@/modules/employer/queries/useGetOrgLocks.query';
import theme from '@/styles/theme';

import { useEmployerLockUnlockQuery } from './queries/useEmployerLockUnlock.query';
import LockAnimatedIcon, { LockAnimatedIconType } from './LockAnimatedIcon';

export const Wrapper = styled(Box)`
  .icon {
    font-size: ${({ theme }) => theme.spacings.spacing72};
    color: ${({ theme }) => theme.colors.warning};
  }
`;

interface Props {
  employerId?: string;
  employerName?: string;
  visible: boolean;
  onSetVisible: () => void;
  isLockMode?: boolean;
}
const EmployerLockUnlockModal: React.FC<Props> = ({
  visible, onSetVisible, employerId, employerName, isLockMode,
}) => {
  const queryClient = useQueryClient();
  const [showServerError, setShowServerError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { mutateAsync: handleLockUnlock, isLoading } = useEmployerLockUnlockQuery(employerId || '', !!isLockMode);

  const handleSubmit = useCallback(async () => {
    if (showSuccessModal) {
      queryClient.invalidateQueries(QUERY_KEY);
      queryClient.invalidateQueries(GET_ORGANIZATION_LOCKS_KEY);
      setShowSuccessModal(false);
      return onSetVisible();
    }
    try {
      await handleLockUnlock();
      setShowSuccessModal(true);
    }
    catch {
      onSetVisible();
      setShowServerError(true);
    }
  }, [
    onSetVisible,
    setShowServerError,
    showSuccessModal,
    handleLockUnlock,
    queryClient,
  ]);

  const title = useMemo(() => {
    if (isLockMode) {
      if (showSuccessModal) {
        return `${employerName} has been locked!`;
      } else {
        return `Lock ${employerName}?`;
      }
    } else {
      if (showSuccessModal) {
        return `${employerName} was unlocked!`;
      } else {
        return `Are you sure you want to unlock ${employerName}?`;
      }
    }
  }, [isLockMode, showSuccessModal, employerName]);

  const currentIcon = useMemo(() => {
    if (isLockMode) {
      if (showSuccessModal) {
        return (
          <LockAnimatedIcon
            play
            id="lock-play"
            type={LockAnimatedIconType.LOCK}
            size="48px"
            color={theme.colors.iconAccent}
          />
        );

      } else {
        return (
          <LockAnimatedIcon
            id="lock"
            type={LockAnimatedIconType.LOCK}
            size="48px"
            color={theme.colors.iconAccent}
          />
        );
      }
    } else {
      if (showSuccessModal) {
        return (
          <LockAnimatedIcon
            play
            id="unlock-play"
            size="48px"
            type={LockAnimatedIconType.UNLOCK}
            color={theme.colors.iconAccent}
          />
        );
      } else {
        return (
          <LockAnimatedIcon
            id="unlock"
            type={LockAnimatedIconType.UNLOCK}
            size="48px"
            color={theme.colors.iconAccent}
          />
        );
      }
    }
  }, [isLockMode, showSuccessModal]);

  return (
    <>
      <Modal
        visible={visible}
        onSetVisible={onSetVisible}
      >
        <Wrapper
          direction="column"
          align="center"
          pad={{ top: 's', bottom: 'l' }}
        >
          <Box 
            align="center"
            justify='center'
            width="72px"
            height="72px"
          >
            {currentIcon}
            {/* <LockAnimatedIcon size="48px" /> */}
          </Box>
          <Text
            weight="bold"
            wordBreak="break-word"
            textAlign="center"
            size="24px"
            color="textTitle"
            margin={{ top: 's' }}
          >
            {title}
          </Text>
          {!showSuccessModal && isLockMode && (
            <Text
              size="14px"
              weight="normal"
              textAlign="center"
              margin={{ top: 's' }}
              color="textBody"
            >
              By locking this employer, payments from any employer funded accounts will not be processed.
            </Text>
          )}
          <Box direction="row" pad={{ top: 'l' }}>
            {!showSuccessModal && (
              <SiblingBoxesWithSpacing>
                <AppButton
                  width="control"
                  buttonType="secondary"
                  onClick={onSetVisible}
                >
                  Cancel
                </AppButton>
              </SiblingBoxesWithSpacing>
            )}
            <AppButton
              width="control"
              onClick={handleSubmit}
            >
              {
                isLoading ? (
                  <Box width="100%" align="center">
                    <Preloader color="iconOnColor" />
                  </Box>
                ) : <Text weight="bold" color="textOnColor">{showSuccessModal ? 'Got It!' : isLockMode ? 'Yes, Lock' : 'Yes, Unlock'}</Text>
              }
            </AppButton>
          </Box>
        </Wrapper>
      </Modal>

      <WarnModal
        visible={showServerError}
        testId="CreateTemplate_payment_order"
        onSetVisible={setShowServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the plan template with the latest records.
      Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try Again"
      />
    </>
  );
};

export default EmployerLockUnlockModal;
