import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import { NO_KEY } from '@/common/constants';
import useContactStore, { ContactPayload } from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';

import { useRoleFields } from './hooks';
import { useAccessToSetupFields } from './hooks';
import { useContactFields } from './hooks';

export interface Props {
  isMainContactMode?: boolean;
  isEditMode?: boolean;
  currentContact?: ContactPayload;
}

const AddEditContactForm: React.FC<Props> = ({ isMainContactMode, isEditMode, currentContact }) => {
  const showErrors = useContactStore((state) => state.showErrors);
  const handleSetState = useContactStore((state) => state.setState);
  const { setValues, values } = useDebounceState<Partial<ContactPayload>>();
  const { setValues: setRoleValues, values: roleValues } = useDebounceState<Partial<ContactPayload>>();
  const { setValues: setAccessToSetupValues, values: accessToSetupValues } = useDebounceState<Partial<ContactPayload>>();

  const fields = useContactFields(isEditMode);
  const roleFiedls = useRoleFields(isMainContactMode);
  const accessToSetupFields = useAccessToSetupFields(isEditMode);

  useEffect(() => {
    if (values || roleValues || accessToSetupValues) {
      const updatedState = {
        ...values,
        ...roleValues,
        ...accessToSetupValues,
        isAccessToSetup: roleValues?.role === Roles.noAccess && !accessToSetupValues?.isAccessToSetup ? NO_KEY : accessToSetupValues?.isAccessToSetup,
        isPrimary: !!isMainContactMode,
        id: currentContact?.id,
      };
  
      handleSetState(updatedState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isMainContactMode, roleValues, accessToSetupValues]);

  return (
    <>
      <Box
        data-testId="contact-form"
        background="canvas"
        round="container1Round"
        margin={{ bottom: 'l' }}
      >
        <FlexControlledForm
          fields={fields}
          editMode={(currentContact && isEditMode) || !currentContact}
          shouldControlValues
          isModalType
          isThinMode
          showError={showErrors}
          onChangeValues={setValues}
        />
      </Box>
      <Box
        data-testId="role-form"
        background="canvas"
        round="container1Round"
        margin={{ bottom: 'l' }}
      >
        <FlexControlledForm
          fields={roleFiedls}
          editMode={(currentContact && isEditMode) || !currentContact}
          isModalType
          shouldControlValues
          isThinMode
          showError={showErrors}
          onChangeValues={setRoleValues}
        />
      </Box>
      {
        (isMainContactMode || (!isMainContactMode && roleValues?.role === Roles.admin1)) && (
          <Box
            background="canvas"
            round="container1Round"
            data-testId="access-setup-form"
          >
            <FlexControlledForm
              fields={accessToSetupFields}
              editMode={(currentContact && isEditMode) || !currentContact}
              shouldControlValues
              isModalType
              isThinMode
              showError={showErrors}
              onChangeValues={setAccessToSetupValues}
            />
          </Box>
        )
      }
    </>
  );
};

export default AddEditContactForm;
