import useReconciliationReportDownloadModalStore from '../store/useReconciliationReportDownloadModal.store';

const useReconciliationReportDownloadModal = () => {
  const {
    isDownloadReportModalVisible,
    setIsDownloadReportModalVisible,
    downloadReportId,
    setDownloadReportId,
  } = useReconciliationReportDownloadModalStore();

  return {
    isModalVisible: isDownloadReportModalVisible,
    setIsModalVisible: setIsDownloadReportModalVisible,
    downloadReportId,
    setDownloadReportId,
  };
};

export default useReconciliationReportDownloadModal;
