import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { Carrier, formatCarrier, GET_CARRIERS } from '../OrgLevelSelector/useGetCarriers.query';

const useCreateCarrierQuery = (
  onSuccess: (carrier: Carrier) => void,
  onError: (externalId: string) => void,
) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (value: Carrier) => api.post(
      PATHS.ADD_CARRIER,
      {
        name: value.name,
        external_id: value.externalId,
      },
    ),
    {
      onSuccess: ({ data }) => {
        onSuccess(formatCarrier(data));
        queryClient.invalidateQueries(GET_CARRIERS);
      },
      onError: ({ response: { data } }, config: Carrier) => {
        if (data?.elevate_error_message?.includes('already exists')) {
          onError(config.externalId);
        }
      },
    },
  );
  
  const handleSave = useCallback(async (value: Carrier) => {
    await mutateAsync(value);
  }, [mutateAsync]);

  return {
    isLoading,
    create: handleSave,
  };
};

export default useCreateCarrierQuery;
