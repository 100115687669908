import React, { useCallback, useMemo } from 'react';

import { ModalWrapper } from '@/components/wrappers';

import { ClaimsTeam } from '../ClaimsProcessors.types';

import CreateClaimsTeamModalForm from './CreateClaimsTeamModalForm';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSuccess?: (id?: string) => void;
  editableTeam?: ClaimsTeam;
}

export const CreateClaimsTeamModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSuccess,
  editableTeam,
}) => {
  const title = useMemo(() => `${editableTeam ? 'Edit' : 'Create'} Claims Team`, [editableTeam]);
  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
  }, [
    onSetVisible,
  ]);

  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={visible}
      onSetVisible={handleSetVisible}
      title={title}
    >
      <CreateClaimsTeamModalForm
        onCancel={onSetVisible}
        onSuccess={onSuccess}
        editableTeam={editableTeam}
      />
    </ModalWrapper>
  );
};
