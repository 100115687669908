import { useEffect, useState } from 'react';

import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';
import { usePlanYearStore } from '@/modules/plan/PlanYearForm/stores';

import usePlanYearPickerStore from '../stores/usePlanYearPicker.store';

export default () => {
  const [loading, setLoading] = useState(false);
  const currentPlanYear = usePlanYearPickerStore((state) => state.state.planYear);
  const planYearState = usePlanYearStore((state) => state.state);

  const setPlanYearState = usePlanYearStore((state) => state.setState);
  const handleResetPlanYear = usePlanYearStore((state) => state.handleReset);
  const { data } = usePlanYearsQuery();
  useEffect(() => {
    if (currentPlanYear) {
      const foundPlanYear = data.find((item) => item.id === currentPlanYear);
      if (foundPlanYear) {
        setPlanYearState({
          name: foundPlanYear.name,
          endDate: foundPlanYear.endDate,
          startDate: foundPlanYear.startDate,
          copayGroup: foundPlanYear.copayGroup,
          priorPlanYearId: foundPlanYear.priorPlanYearId,
          priorPlanYear: foundPlanYear.priorPlanYear,
        });
      } else {
        handleResetPlanYear();
      }
    }
  }, [currentPlanYear, data, handleResetPlanYear, setPlanYearState]);
  return {
    setLoading,
    currentPlanYear,
    planYearState,
    loading,
  };
};
