import React, { useMemo } from 'react';
import { Box, ShimmerBox } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { LARGE_SIZE_LOGO, LetterIconSizeEnum } from '@/common/constants';
import ROUTES from '@/common/routes';
import { Icon } from '@/components/elements/Icon/Icon';
import { Breadcrumbs } from '@/modules/core/components/Topbar/Breadcrumbs/Breadcrumbs';
import { useHistory } from '@/modules/core/hooks';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { useAuthorization } from '@/modules/user/hooks';
import { useAuthStore } from '@/modules/user/stores';
import baseTheme from '@/styles/theme';

import ExpressSetupLeaveDropdown from './ExpressSetupLeaveDropdown';

interface WrapperProps {
  isSticky?: boolean;
}

const FixedWrapper = styled(Box)<WrapperProps>`
  position: relative;
  ${({ isSticky }) => {
    if (isSticky) {
      return css`
        position: sticky;
        width: 100%auto;
        min-height: fit-content;
        top: 0;
        z-index: 12;
        background-color: ${({ theme }) => theme.colors.canvas} ;
      `;
    }
    return css``;
  }}
`;

export interface Props {
  logo?: string;
  orgId?: string;
  isLoading?: boolean;
  isSticky?: boolean;
  isShimmerHide?: boolean;
}

const ExpressSetupTopbar: React.FC<Props> = ({
  logo,
  orgId,
  isLoading,
  isSticky,
  isShimmerHide,
}) => {
  const history = useHistory();
  const { user } = useAuthStore();
  const { handleLogout } = useAuthorization();
  const isExpressSetupUser = useMemo(() => user?.status && user?.status === EmployerStatus.ExpressSetup, [user]);

  return (
    <FixedWrapper
      data-testId="express-setup-topbar"
      isSticky={isSticky}
    >
      <Box border={{ size: 'small', color: 'border1', side: 'bottom' }} data-testId="topbar">
        <Box
          style={{
            position: 'absolute',
            left: '24px',
            top: '24px',
            cursor: 'pointer',
          }}
        >
          <ExpressSetupLeaveDropdown
            options={
              [
                ...!isExpressSetupUser ? [{
                  title: 'Go back to admin portal',
                  redirectFunc: () => {
                    if (history) history.push(ROUTES.EMPLOYERS);
                  },
                }] : [],
                {
                  title: 'Log out',
                  warning: true,
                  redirectFunc: handleLogout,
                }
              ]
            }
          />
        </Box>
        <Box align="center" pad={{ horizontal: 'l' }}>
          <Box width={baseTheme.defaultMinContentWidth}>
            <Box
              style={{ transition: 'all 0.6s' }}
              height={{ min: '72px' }}
              direction="row"
              align="center"
              justify="between"
            >
              <Box direction="row">
                {
                  isLoading && !isShimmerHide ? <ShimmerBox width="119px" height="40px" round="button2Round" margin={{ right: 'l' }}/> : (
                    <Box
                      onClick={() => history.push(ROUTES.EXPRESS_SETUP_BY_ID(orgId))}
                      margin={{ right: 'l' }}
                      data-testId="express-setup-topbar-logo"
                    >
                      <Icon
                        size={LetterIconSizeEnum.LOGO}
                        text="name"
                        height={LARGE_SIZE_LOGO.height}
                        width="100%"
                        src={logo}
                      />
                    </Box>
                  )
                }
                <Breadcrumbs expressSetupMode />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </FixedWrapper>
  );
};

export default ExpressSetupTopbar;
