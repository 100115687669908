import { OrganizationTypes } from '@/common/constants';
import { EmployerStatus } from '@/modules/employer/employer.constants';

import { AllowAssumedContribution } from '../components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';

import {
  AllowedFileTypes,
  AllowedPaymentMethods,
  WaitingForFundsContinuePayment,
} from './CreateEmployer.dto';

export interface AddressesDtoPayload {
  id?: number;
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  preferred?: boolean;
  country_id?: string | number;
  address_type?: string;
}

export type FildState = 'MODIFIABLE' | 'LOCKED';

export interface LogoInfoPayload {
  favicon_url?: string;
  large_logo_url?: string;
  dark_large_logo_url?: string;
  minimized_logo_url?: string;
  dark_minimized_logo_url?: string;
}

export enum PartnerMobileApp {
  NONE = 'NONE',
  ELEVATE_APP = 'ELEVATE_APP',
  PARTNER_OWN_APP = 'PARTNER_OWN_APP',
}

export interface OverridablePropertiesDtoPayload {
  sso_only?: boolean;
  allow_employees_to_edit_address?: boolean;
  url?: string;
  account_manager_id?: string;
  implementation_manager_id?: string;
  hsa_custodian?: { hsa_custodian?: string };
  id_source_id?: string;
  check_funding_model?: string | null;
  allow_manual_contribution?: boolean;
  allow_scheduled_contribution?: AllowAssumedContribution;
  card_offered?: boolean;
  allow_dependent_card?: boolean;
  bin_setup?: string;
  prin_setup?: string;
  true_ssn?: boolean;
  is_external_link?: boolean;
  partner_communications?: boolean;
  invoice_communications?: boolean;
  report_communications?: boolean;
  standard_employee_notifications?: boolean;
  theme_id?: PersonThemeNamesEnum;
  customer_service_number?: string;
  customer_service_url_name?: string;
  customer_service_url_link?: string;
  customer_service_email?: string;
  customer_service_is_enabled?: boolean;

  is_custom_reporting_field_1_enabled?: boolean;
  is_custom_reporting_field_2_enabled?: boolean;
  is_custom_reporting_field_3_enabled?: boolean;
  is_custom_reporting_field_4_enabled?: boolean;
  is_custom_reporting_field_5_enabled?: boolean;

  custom_reporting_field_1_name?: string;
  custom_reporting_field_2_name?: string;
  custom_reporting_field_3_name?: string;
  custom_reporting_field_4_name?: string;
  custom_reporting_field_5_name?: string;

  electronic_funding_mode_id?: string | null;
  hsa_custodian_id?: string;
  external_link?: string;
  external_administrator_fees_account_method?: string;
  external_payroll_contributions_account_method?: string;
  external_replenishment_account_method?: string;
  minimum_payment_reimbursement_amount?: number;
  waiting_for_funds_continue_payment?: WaitingForFundsContinuePayment;
  waiting_for_funds_expiration?: string;
  payroll_contributions_hold_period?: number;
  communication_channels?: string[];
  email_template_type?: string;
  organization_communication_categories?: string[];
  card_phone_number?: string;
  card_url?: string;
  card_employer_name?: string;
  card_processing_start_date?: string | null;
  replenishment_frequency_type?: string;
  replenishment_day_number?: number;
  is_reimbursement_allowed?: boolean;
  allowed_payment_methods?: AllowedPaymentMethods[];
  is_bill_pay_allowed?: boolean;
  is_claim_offsets_allowed?: boolean;
  session_timeout_warn?: number;
  session_timeout_logout?: number;
  claims_assignment_number?: number;
  is_file_manager_access_enabled?: boolean;
  is_file_auto_post_enabled?: boolean;
  allowed_file_types?: AllowedFileTypes[];
  logo_info?: LogoInfoPayload;
  prefund_initial_delay_date?: string | null;
  prefund_replenishment_min_value: number;
  prefund_replenishment_percent: number;
  prefund_replenishment_creep_min: number;
  prefund_replenishment_creep_percent: number;
  ee_contributions_hold_period?: number;

  about_us_link?: string;
  pricing_consent?: string;
  email_domain?: string;
  email_logo_type?: string;
  email_illustration_id?: number;
  email_logo_path?: string;
  terms_and_conditions_link?: string;
  is_default_link?: boolean;
  privacy_policy_link?: string;
  email_theme?: number;
  statement_type?: string;
  statement_frequency_type?: string;
  number_of_days_before_plan_start?: number;
  ai_adjudication?: boolean;
  ai_confidence_level?: number;
  authentication_type_id?: string;
  account_lockout_time?: number;

  partner_app_android_link?: string;
  partner_app_ios_link?: string;
  partner_mobile_app?: PartnerMobileApp;
  mobile_app_theme_id?: number;

  orphan_organization_id?: number;
  orphan_start_timing_days?: number;
  is_organization_orphan?: boolean;

  file_location?: string;
  error_report_location?: string;
  participant_portal_link?: string | null;
  admin_portal_link?: string | null;
}

export interface NormalPropertiesDtoPayload {
  id?: number;
  organization_id?: number;
  tax_id?: string;
  incoming_clearing_account?: number;
  notional_clearing_account?: number;
  hsa_clearing_account?: number;
  administrator_fees_clearing_account?: number;
  unspecified_clearing_account?: number;
  notional_account?: number;
  hsa_account?: number;
  replenishment_account?: number;
  card_production_date?: string;
  card_url?: string;
  card_employer_name?: string;
  card_phone_number?: string;
  external_replenishment_account?: number;
  external_payroll_contributions_account?: number;
  external_administrator_fees_account?: number;
  account_manager_id?: number;
  implementation_manager_id?: number;
  invoicing_contact_id?: number;
}

export interface OrganizationPhoneDtoPayload {
  number?: string;
  phone_extension?: string;
}

enum PersonThemeNamesEnum {
  CLARITY = 'CLARITY',
  ELEVATE = 'ELEVATE',
}

export interface EmployerDtoPayload {
  id?: number;
  activation_started?: boolean;
  external_identifier?: string;
  name?: string;
  type?: OrganizationTypes;
  parent_id?: string | number;
  grandparent_id?: string | number;
  addresses?: AddressesDtoPayload[];
  normal_properties?: NormalPropertiesDtoPayload;
  short_name?: string;
  business_entity_type?: string | number;
  organization_phone?: OrganizationPhoneDtoPayload;
  organization_path?: string;
  organization_status_type?: EmployerStatus;
  created_at?: string;
  organization_status_valid_from?: string;
  themeId?: PersonThemeNamesEnum;
  overridable_properties?: OverridablePropertiesDtoPayload;
  notification_properties?: Record<string, number>;
  termination_date?: string;
}

export interface OrganizationLocksDtoPayload {
  is_locked: boolean;
  organization_id: number;
  updated_at: string;
  has_replenished_accounts?: boolean;
}
