import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import {
  HealthPlanFieldState,
  HealthPlanListItemResponse,
  HealthPlanStatuses,
} from '@/modules/HealthPlan/HealthPlan.types';
// import { useIsOrganizationType } from '@/modules/user/hooks';

export const getPlanList = (id: null | string, status?: HealthPlanStatuses) => () => api
  .get<Pagination<HealthPlanListItemResponse>>(
    PATHS.HEALTH_PLANS, {
      params: {
        organization_id: id || undefined,
        plan_status: status || undefined,
        size: 100,
      },
    },
  );

export const HEALTH_PLAN_LIST_QUERY_NAME = 'getHealthPlansList';

export const useHealthPlansListQuery = (id?: string | null, status?: HealthPlanStatuses) => {
  // const isSuperUser = useIsOrganizationType(OrganizationTypes.system);

  const { organizationId } = useCurrentOrganisationStore();
  const currentId = useMemo(() => id || organizationId || '0', [id, organizationId]);
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [HEALTH_PLAN_LIST_QUERY_NAME, currentId, status],
    getPlanList(currentId, status),
    {
      refetchOnMount: true,
    },
  );
  useEffect(() => {
    if (organizationId) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  const formatedPlans = useMemo(() => {
    const list = data?.data?.content || [];
    return list.map((item) => ({
      id: `${item.id}`,
      name: item.name.name,
      typeState: item?.health_plan_types?.health_plan_type_state as HealthPlanFieldState,
      status: item.plan_status as HealthPlanStatuses,
      types: item?.health_plan_types?.health_plan_types as CardTypesEnum[],
      planYearId: item.plan_year?.id,
      planYearName: item.plan_year?.name,
    }));
  }, [data]);

  return {
    data: formatedPlans,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
export default useHealthPlansListQuery;
