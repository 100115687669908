import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { PLAN_SETUP_FIELD } from '@/modules/plan/PlanSetup/PlanSetup.constants';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import useFundFormulaFields from '../FundFormula/hooks/useFundFormulaFields';
import { useCardOfferedStore } from '../stores';

const TRUE_KEY = 'true';
export default (isActivateMode?: boolean, isTemplateMode?: boolean,
  isEditMode?: boolean, showErrors?: boolean) => {
  const sourceState = useCardOfferedStore((state) => state.sourceState);
  const errorFields = useCardOfferedStore((state) => state.errorFields);
  const currentState = useCardOfferedStore((state) => state.state);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const isCardOffered = useMemo(
    () => currentState?.cardOffered === TRUE_KEY, [currentState.cardOffered],
  );

  const fundFormulaField = useFundFormulaFields(
    showErrors, isEditMode, isActivateMode,
    isCardOffered,
  );
  const fields = useMemo<Field[]>(() => [
    {
      
      name: PLAN_SETUP_FIELD.CARD_OFFERED,
      type: FieldTypes.Radio,
      label: 'Card offered',
      showRequireIcon: true,
      options: [
        {
          label: 'Yes',
          value: TRUE_KEY,
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
      placeholder: 'Enter plan name',
      validator: isActivateMode ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING,
      subFields: [
        {
          parentValue: TRUE_KEY,
          name: 'fundID',
          type: FieldTypes.Text,
          // showRequireIcon: true,
          label: 'Fund ID',
          placeholder: 'Select Fund ID',

          validator: yup
            .string()
            .test({
              test: (val) => !errorFields?.fundID?.value || val !== errorFields?.fundID?.value,
              message: errorFields?.fundID?.message || 'Invalid field',
            })
            .test({
              test: () => errorFields?.fundID?.value === '' && !!errorFields?.fundID?.message  ? false : true ,
              message: errorFields?.fundID?.message || 'Invalid field',
            }) 
            .when('cardOffered', {
              is: (val: string) => val === 'true',
              then: yup.string().trim().nullable().test({
                test: (val) => !val?.match(REGEXPS.SPACE_SYMBOL)?.length,
                message: 'Spaces aren\'t allowed for the field',
              })
                .test({
                  test: (val) => !val?.match(REGEXPS.SPECIAL_CHARACTER)?.length,
                  message: 'The field must be alphanumeric',
                })
                // .matches(REGEXPS.ALPHANUMERIC, 'The field must be alphanumeric')
                // .required(REQUIRED_TEXT)
                .test('len', 'The size must be between 1 and 8',
                  (val) => {
                    if (!val) {
                      return true;
                    }

                    return (!!val && (val.length === 0 || val.length <= 8));
                  }),
              otherwise: VALIDATORS.STRING,
            })
            .test({
              test: (val) => !errorFields?.fundID?.value || val !== errorFields?.fundID?.value,
              message: errorFields?.fundID?.message || 'Invalid field',
            }),
        },
        fundFormulaField
      
        
      ],
    },

  ], [isActivateMode, errorFields?.fundID?.message, errorFields?.fundID?.value, fundFormulaField]);
  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
