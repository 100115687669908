import React, { useMemo,useState } from 'react';
import {
  Box,   capitalizeFirstLetter, FlexListHeader, FlexListItem,   formatSnakeCaseToGeneral, ListItem,   OptionKey,PaginationBar,
  Preloader,
  SearchInput, SelectDropdown,
  Text, usePaginationParams,
  WarnModal,
} from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { ListItemBase, ListItemType, NodeOption } from '@/common/types';
import { ListPlaceholder } from '@/modules/CarrierConfig/components';
import { useContactDeactivateQuery } from '@/modules/core/hooks';
import { ContactResponseModel } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurity.types';
import { DEFAULT_PAGE_SIZE,useContactListQuery } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/queries/useContacts.queries';

import { usePermissionOptions } from '../AddEditContactsSecurityModal/hooks';

const getOptionTitle = (value?: string) => {
  if (value) return capitalizeFirstLetter(value.toString().split('_').join(' ').toLowerCase());
  return value;
};

export interface Props {
  disabled?: boolean;
  organizationId?: string;
  triggerRefetching?: string;
  onEdit: (contact: ContactResponseModel) => void;
  onView: (contact: ContactResponseModel) => void;
}

const headers: ListItemBase[] = [{
  key: 'fullName',
  title: 'Full Name and Email',
  flex: 2,
}, {
  key: 'tilte',
  title: 'Title/Role',
}, {
  key: 'department',
  title: 'Department',
}, {
  key: 'permissions',
  title: 'Permissions',
},
];

const ContactsSecurityTable: React.FC<Props> = ({
  disabled,
  onEdit,
  onView,
  organizationId,
  triggerRefetching,
}) => {
  const [permissions, setPermissions] = useState<OptionKey[]>([ALL_OPTION.value]);
  const permissionsList = usePermissionOptions();
  const permissionsIds = useMemo(() => {
    if (permissions.length === 1 && permissions[0] === ALL_OPTION.key) {
      return permissionsList.map((item) => item.id);
    }
    return permissions.map((item) => permissionsList.find((listItem) => listItem.value === item)?.id).filter((item) => item);
  }, [permissions, permissionsList]);
  const permissionsOptions = useMemo(() => ([
    ALL_OPTION,
    ...permissionsList.map((item) => {
      const title = getOptionTitle(item.value);
      return {
        ...item,
        key: item.value,
        id: item.id,
        value: title,
        title,
      };
    }),
  ]), [permissionsList]);
  const {
    page,
    setPage,
    setPerPage,
    perPage,
    searchString,
    setSearchString,
    currentSearch,
  } = usePaginationParams(DEFAULT_PAGE_SIZE);
  const { contactList, totalElements, isLoading } = useContactListQuery({
    ...currentSearch ? { search: currentSearch } : {},
    ...permissionsIds.length === permissionsList.length ? {} : { permissionsIds },
    organizationId,
    size: perPage,
    triggerRefetching,
    ...page ? { page: page - 1 } : {},
  });
  const [deactivatedItems, setDeactivatedItems] = useState<string[]>([]);
  const [deactivationItem, setDeactivationItem] = useState('');
  const contacts = useMemo(
    () => contactList.filter((item) => !deactivatedItems.some((id) => `${item.id}` === id)),
    [deactivatedItems, contactList],
  );

  const formatedList = useMemo<ListItem[]>(() => contacts.map((item) => ({
    id: `${item.id}` || '',
    fields: [
      {
        key: 'fullName',
        type: ListItemType.Contact,
        title: `${item.first_name} ${item.last_name}`,
        value: item.email,
        primary: item.primary,
        hover: !disabled,
        flex: 2,
      },
      {
        key: 'title',
        type: ListItemType.Text,
        title: item.title || '',
      },
      {
        key: 'department',
        type: ListItemType.Text,
        title: item.department || '',
      },
      {
        key: 'permissions',
        type: ListItemType.Text,
        title: formatSnakeCaseToGeneral(item.role || '-'),
      },
      ...disabled ? [{
        key: 'empty',
        type: ListItemType.Text,
        title: '',
        flex: 0.2,
      }] : [],
    ],
  }
  )), [contacts, disabled]);
  const onSuccessDeactivation = () => {
    setDeactivatedItems([
      ...deactivatedItems,
      deactivationItem,
    ]);
  };
  const deactivateItem = useContactDeactivateQuery(onSuccessDeactivation);
  const deactivationItemTitle = useMemo(
    () => formatedList
      .find((item) => item.id === deactivationItem)?.fields
      .find((field) => field.key === 'fullName')?.title,
    [formatedList, deactivationItem],
  );

  return (
    <Box background="canvas" round="container1Round" id="contact_information">
      <Box height="2px" width="100%" background="border2" />
      <Box margin="small" pad="small" background="module" round="container1Round">
        <Box direction="row" align="center" justify="between" margin={{ top: 'spacing8', bottom: 'spacing12' }}>
          <Text
            size="large"
            weight="bold"
            color="textBody"
          >{`Contacts: ${totalElements || 0}`}
          </Text>
          <Box direction="row">
            <Box width="240px">
              <SearchInput
                data-testId="ContactSecurityTable_search_input"
                hasSearchingHistory
                value={searchString}
                placeholder="Search"
                onChange={setSearchString}
              />
            </Box>
            <Box width="180px" margin={{ left: 'spacing12' }}>
              <SelectDropdown
                id="contacts_permissions"
                testId="contacts_permissions"
                allMode
                activeTitle
                ellipsisMode
                name="Permission"
                prefix="Permission"
                options={permissionsOptions as NodeOption[]}
                values={permissions}
                onChangeValues={(permissions: OptionKey[]) => {
                  setPage(1);
                  if (permissions?.length > 1 && permissions[0] === ALL_OPTION.key) {
                    return setPermissions(permissions?.filter((item) => item !== ALL_OPTION.key));
                  }
                  if (permissions?.length === permissionsList?.length
                    || !permissions?.length
                    || (permissions?.length > 1 && permissions[permissions.length - 1] === ALL_OPTION.key)) {
                    return setPermissions([ALL_OPTION.key as OptionKey]);
                  }
                  setPermissions(permissions);
                }}
                dropdownStyles={{
                  minWidth: '260px',
                  maxWidth: 'none',
                  left: 'auto',
                }}
              />
            </Box>
          </Box>
        </Box>
        {formatedList?.length ? (
          <Box>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formatedList.map((row: ListItem, index) => (
                <FlexListItem
                  onPick={disabled ? undefined : () => onView(contacts[index])}
                  key={row.id}
                  id={row.id}
                  fields={row.fields}
                  options={disabled ? undefined : [
                    {
                      name: 'Edit',
                      onClick: () => onEdit(contacts[index]),
                    },
                  ]}
                />
              ))}
            </Box>
            <PaginationBar
              page={page}
              total={totalElements || 0}
              pageSize={perPage}
              onChangePage={setPage}
              onChangePageSize={setPerPage}
            />
          </Box>
        ) : (
          isLoading ? <Box height="128px" justify="center"><Preloader color="iconOnColor" /></Box> : <ListPlaceholder>No results have been found</ListPlaceholder>
        )}
      </Box>

      <WarnModal
        visible={!!deactivationItem}
        onSetVisible={() => setDeactivationItem('')}
        header={`Are you sure you want to deactivate ${deactivationItemTitle}?`}
        buttonText="Cancel"
        submitButtonText="Yes, Deactivate"
        submitButtonMode="alert"
        onSubmit={() => deactivateItem(deactivationItem)}
      />
    </Box>
  );
};

export default ContactsSecurityTable;
