import React, { useMemo } from 'react';
import {
  ActionCard,   ActivateEmployerActiveIcon, ActivateEmployerInactiveIcon,
  ActivatePartnerActiveIcon,
  ActivatePartnerInactiveIcon, AppButton,
  Box,
  Inscription,   SiblingBoxesWithSpacing, } from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { useTOCConfiguration } from '@/modules/employer/hooks/useTOCConfiguration';

import BoxOfRequiredFields, { RequiredFieldPropertie } from './BoxOfRequiredFields';

interface Props {
  requiredFieldProperties?: RequiredFieldPropertie[];
  isEditMode?: boolean;
  activeMode?: boolean;
  employerName?: string;
  onSubmit: () => void;
  onDiscard?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  viewMode?: boolean;
  editMode?: boolean;
  submitted?: boolean;
  currentDate?: string;
}
const ActivateEmployerCard: React.FC<Props> = ({
  requiredFieldProperties,
  isEditMode,
  children,
  disabled,
  onSubmit,
  employerName,
  viewMode,
  onDiscard,
  activeMode,
  submitted,
}) => {
  const { mode } = useSetupEmployerMode();
  const config = useTOCConfiguration();

  const title = useMemo(() => capitalize(mode), [mode]);
  const iconEmployer = useMemo(() => (
    disabled ? <ActivateEmployerInactiveIcon size="xxlarge" /> : <ActivateEmployerActiveIcon size="xxlarge" />
  ), [disabled]);

  const iconPartner = useMemo(() => (
    disabled ? <ActivatePartnerInactiveIcon size="xxlarge" /> : <ActivatePartnerActiveIcon size="xxlarge" />
  ), [disabled]);

  return (
    <ActionCard
      title={activeMode ? `Update ${employerName}` : `Activate the ${title}`}
      submitted={submitted || activeMode}
      icon={mode === EmployerSetupModes.employer
        ? iconEmployer
        : iconPartner}
      {...(isEditMode ? {
        ...(activeMode
          ? {
            text: ' ',
          }
          : {
            text: `${mode === EmployerSetupModes.employer ? 'ER' : title} status is Pending until it is activated`,
          }),
        footer: requiredFieldProperties && requiredFieldProperties.length ? (
          <>
            <BoxOfRequiredFields
              requiredFieldProperties={requiredFieldProperties}
              tocConfig={config}
            />
            <Inscription
              lineHeight="18px"
              color="textSecondary"
              size="small"
              margin={{ top: 'spacing24' }}
              weight={500}
            >
              All other attributes for other modules are optional.
            </Inscription>
          </>
        ) : null,
      } : {})}
    >
      <Box direction="column" align="center" width="100%">
        <Box
          direction="row"
          justify="center"
          align="center"
          width="100%"
        >
          {activeMode
            ? !viewMode && (
              <>
                <SiblingBoxesWithSpacing width="small">
                  <AppButton
                    disabled={disabled}
                    onClick={onDiscard}
                    size="L"
                    color="red"
                    width="100%"
                  >
                    Discard updates
                  </AppButton>
                </SiblingBoxesWithSpacing>
                <SiblingBoxesWithSpacing width="small">
                  {/* <Button
                  disabled={disabled}
                  onClick={onSubmit}
                  primary
                  type="button"
                  size="medium"
                  large
                  label="Save Changes"
                /> */}
                  <AppButton
                    disabled={disabled}
                    onClick={onSubmit}
                    size="L"
                    width="100%"
                  >
                    Save Changes
                  </AppButton>
                </SiblingBoxesWithSpacing>
              </>
            )
            : (
              <Box width="100%">
                <AppButton
                  disabled={disabled}
                  onClick={onSubmit}
                  size="L"
                  width="100%"
                >
                  {`Activate the ${title}`}
                </AppButton>
              </Box>
            )}

        </Box>

        {children}
      </Box>
    </ActionCard>
  );
};
export default ActivateEmployerCard;
