import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access, Topbar } from '@/modules/core/components';
import {
  useBreadcrumbs, useCurrentOrganization, useHistory, useRedirectHomeAccess,
} from '@/modules/core/hooks';
import { HealthPlanHub } from '@/modules/HealthPlan';
import theme from '@/styles/theme';

const HealthPlanHubPage = () => {
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();

  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  useBreadcrumbs([{
    route: ROUTES.HEALTH_PLAN_HUB,
    title: 'Health Plans Hub',
  }]);
  useRedirectHomeAccess([{
    permission: Permissions.VIEW_HEALTH_PLAN,
  }, {
    permission: Permissions.CREATE_CARRIER_CONFIG,
  }, {
    permission: Permissions.VIEW_COPAY,
  }, {
    permission: Permissions.DISABLE_CARRIER_CONFIG,
  }]);

  if (!type) {
    return null;
  }
  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_PLAN },
      ]}
      accessDeniedAction={goHome}
    >
      <Box
        width="100%"
        margin={{ top: '0px' }}
      >
        <Box>

          <Topbar isSticky />

          <Box align="center">
            <Box
              width={theme.defaultContentWidth}
            >
              <HealthPlanHub />
            </Box>
          </Box>

        </Box>
      </Box>
    </Access>
  );
};
export default HealthPlanHubPage;
