import { useMemo } from 'react';

import AirtableIcon from '@/assets/employers/airtable.svg';
import ClarityIcon from '@/assets/employers/clarity.svg';
import ColaIcon from '@/assets/employers/cola.svg';
import DiscordIcon from '@/assets/employers/discord.svg';
import FidelityIcon from '@/assets/employers/fidelity.svg';
import GoogleIcon from '@/assets/employers/google.svg';
import RedditIcon from '@/assets/employers/reddit.svg';
import ZapierIcon from '@/assets/employers/zapier.svg';

const EMPLOYERS = [
  {
    id: 'clarity',
    name: 'Clarity',
    icon: ClarityIcon,
  },
  {
    id: 'cola',
    name: 'Cola',
    icon: ColaIcon,
  }, {
    id: 'google',
    name: 'Google',
    icon: GoogleIcon,
  }, {
    id: 'reddit',
    name: 'Reddit',
    icon: RedditIcon,
  }, {
    id: 'discord',
    name: 'Discord',
    icon: DiscordIcon,
  }, {
    id: 'artable',
    name: 'Artable',
    icon: AirtableIcon,
  }, {
    id: 'zapier',
    name: 'Zapier',
    icon: ZapierIcon,
  },
  {
    id: 'fidelity',
    name: 'Fidelity',
    icon: FidelityIcon,
  },
];

const useGetEmployerByName = (value: string) => {
  const icon = useMemo(() => {
    const item = EMPLOYERS.find(
      (employer) => employer?.name.toLowerCase().trim() === value?.toLowerCase().trim(),
    );
    if (item) {
      return item.icon;
    }
    return null;
  }, [value]);
  return icon;
};

export default useGetEmployerByName;
