import { useCallback, useEffect } from 'react';
import _ from 'lodash';

import { Copay } from '@/modules/ExpressSetup/ExpressSetup.types.ts';

import useCopayStore, { DEFAULT_VALUES } from '../stores/useCopay.store';

export const useCopayActions = () => {
  const copayState = useCopayStore((state) => state.state);
  const showErrors = useCopayStore((state) => state.showErrors);
  const setShowErrors = useCopayStore((state) => state.setShowErrors);

  const handleSetCopayState = useCopayStore((state) => state.setState);

  const handleAddCopay = useCallback(() => {
    const newCopay = {
      id: _.uniqueId(),
      categoryType: '', 
      amount: '',
    };
    handleSetCopayState({
      copays: [...(copayState.copays || []), newCopay],
    });
  }, [copayState, handleSetCopayState]);

  const handleRemoveCopay = useCallback((id: string) => {
    const updatedCopays = (copayState.copays || []).filter(copay => copay.id !== id);
    handleSetCopayState({
      copays: updatedCopays,
    });
  }, [copayState, handleSetCopayState]);

  const handleUpdateCopay = useCallback((updatedCopay: Copay) => {
    handleSetCopayState({
      copays: (copayState.copays || []).map((copay) =>
        copay.id === updatedCopay.id ? { ...copay, ...updatedCopay } : copay
      ),
    });
  }, [copayState, handleSetCopayState]);

  const handleToggleCopaysSelected = useCallback(() => {
    handleSetCopayState({ ...copayState, isCopaysSelected: !copayState.isCopaysSelected });
  }, [copayState, handleSetCopayState]);

  useEffect(() => {
    handleSetCopayState({
      ...copayState,
      copays: copayState.copays?.length ? copayState.copays : [DEFAULT_VALUES.copays?.[0]],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSetCopayState({
      isReadyToSaveCopays: copayState?.isCopaysSelected ? true : false,
    });
  }, [copayState.isCopaysSelected, handleSetCopayState]);

  useEffect(() => {
    return () => {
      handleSetCopayState({
        isReadyToSaveCopays: false,
      });
      setShowErrors(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    copayState,
    showErrors,
    handleAddCopay,
    handleRemoveCopay,
    handleUpdateCopay,
    handleToggleCopaysSelected,
  };
};