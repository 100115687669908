import React, { useMemo } from 'react';
import { FieldTypes, formatSnakeCaseToGeneral, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { MINIMIZE_DATE_FORMAT } from '@/common/constants';
import { Organization } from '@/modules/core/hooks/useGetOrganizationById';

import { CarrierConfig } from '../../types/carrier.types';

export const EllipsisText = styled(Inscription)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const YEAR_WITHOUT_DEADLINE = 9999;
const checkDeadline = (date?: string) => (date ? dayjs(date).year() === YEAR_WITHOUT_DEADLINE : true);
const formatDate = (date?: string) => (checkDeadline(date) ? null : dayjs(date).format(MINIMIZE_DATE_FORMAT));

export const useGeneralFields = (carrierInfo?: CarrierConfig, organization?: Organization) =>
  useMemo(() => {
    if (!carrierInfo) return [];

    return [
      {
        name: 'carrierConfigName',
        type: FieldTypes.Node,
        label: 'Carrier name',
        value: <EllipsisText title={carrierInfo.carrier.name}>{carrierInfo.carrier.name}</EllipsisText>,
      },
      {
        name: 'carrierConfigId',
        type: FieldTypes.Node,
        label: 'Carrier ID',
        value: (
          <EllipsisText title={carrierInfo.carrier.externalId}>{carrierInfo.carrier.externalId}</EllipsisText>
        ),
      },
      {
        name: 'carrierConfigSubmitterId',
        type: FieldTypes.Node,
        label: 'Submitter ID',
        value: <EllipsisText title={carrierInfo.submitterId}>{carrierInfo.submitterId}</EllipsisText>,
      },
      {
        name: 'carrierConfigOrganizationLevel',
        type: FieldTypes.Text,
        label: 'Organization level',
        value: formatSnakeCaseToGeneral(organization?.type || ''),
      },
      {
        name: 'carrierConfigOrganizationName',
        type: FieldTypes.Text,
        label: 'Organization name',
        value: organization?.name,
      },
      {
        name: 'carrierConfigValidFrom',
        type: FieldTypes.Text,
        label: 'Valid from',
        value: formatDate(carrierInfo.serviceDateFrom),
      },
      {
        name: 'carrierConfigValidTo',
        type: FieldTypes.Text,
        label: 'Valid to',
        value: formatDate(carrierInfo.serviceDateTo),
      },
      {
        name: 'carrierConfigDescription',
        type: FieldTypes.Node,
        label: 'Description',
        value: <EllipsisText title={carrierInfo.description}>{carrierInfo.description}</EllipsisText>,
      },
    ];
  }, [carrierInfo, organization]);

export default useGeneralFields;
