import React, { useEffect,useMemo,useState } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { DefaultSortTypesEnum, Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { SourceIds } from '@/modules/employee/employee.types';
import { OrganizationStatus } from '@/modules/employee/Employee/Employee.types';
import useSourceId from '@/modules/employer/components/EmployeeSetupModal/hooks/useSourceId';

import useGetEmployeesWithActivePlanQeury, { EmployeeWithActivePlan } from '../queries/useGetEmployeesWithActivePlan.query';

const SORT_BY_FIELD_NAME = 'person.lastName';
const MAX_ELLIPSIS_LENGTH = 15;

const returnCurrentSourceId = (employee: EmployeeWithActivePlan, sourceId?: string) => {
  switch (sourceId) {
  case SourceIds.EEID:
    return employee.employerEmployeeId;
  case SourceIds.PEID:
    return employee.partnerEmployeeId;
  case SourceIds.SSN:
    return employee.ssn;
  default:
    return '';
  }
};

export const useEmployeeSearch = (enabled?: boolean) => {
  const [search, setSearch] = useState('');
  const [employees, setEmployees] = useState<Option[]>([]);

  const {
    observingOrganization,
    observingMode,
  } = useCurrentOrganization();
  const sourceId = useSourceId(observingOrganization?.id);

  const params = useMemo(() => ({
    sortByField: SORT_BY_FIELD_NAME,
    perPage: 10,
    page: 0,
    searchString: search,
    sortBy: DefaultSortTypesEnum.ASC,
    ...(observingMode ? { observingOrganizationId: observingOrganization?.id } : {}),
  }), [observingMode, observingOrganization, search]);

  const { isLoading, formattedData } = useGetEmployeesWithActivePlanQeury(params);

  const duplicateNameEmployees = useMemo(() => {
    if (!formattedData || !formattedData.length) return [];
    const duplicates = formattedData.reduce((acc, employee) => {
      const isDuplicate = formattedData.filter((emp) => emp.firstName === employee.firstName && emp.lastName === employee.lastName).length > 1;
      if (isDuplicate) {
        acc.push(employee.employeeId);
      }
      return acc;
    }, [] as string[]);

    return duplicates;
  }, [formattedData]);

  const employeesOptions: Option[] = useMemo(() => {
    if (!formattedData || !formattedData.length) return [];
    return formattedData?.map((employee) => ({
      key: employee.employeeId,
      value: employee.employeeId,
      title: `${employee.firstName} ${employee.lastName}`,
      ...duplicateNameEmployees?.includes(employee.employeeId) || (employee?.status && employee?.status[0] ===  OrganizationStatus.terminated) ? { 
        ellipsisMode: `${employee?.firstName} ${employee?.lastName}`?.length > MAX_ELLIPSIS_LENGTH,
        isNodeInTheEnd: true,
        maxItemWidth: '320px',
        node: (
          <Box direction="row">
            {employee?.status ===  OrganizationStatus.terminated && (
              <Inscription color="textDisabled">
                Terminated
              </Inscription>
            )}
            {duplicateNameEmployees?.includes(employee.employeeId) && employee.status ===  OrganizationStatus.terminated && (
              <Box
                width="1px"
                height="16px"
                background="border1"
                margin={{ horizontal: 'spacing8'}}
                alignSelf="center"
              />
            )}
            {duplicateNameEmployees?.includes(employee.employeeId) && (
              <Inscription color="textSecondary">
                {returnCurrentSourceId(employee, sourceId)}
              </Inscription>
            )}
          </Box>
        )
      } : {},
    })) as Option[];
  }, [formattedData, duplicateNameEmployees, sourceId]);

  useEffect(() => {
    setEmployees(employeesOptions);
  }, [employeesOptions]);

  return {
    employees,
    isLoading,
    ...enabled ? {
      handleSetSearch: setSearch,
    } : {},
  };
};
