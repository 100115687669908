import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  CheckBox,
  PopupWrapper,
  SetupOutlinedIcon,
  Text,
} from '@common-fe/common-fe';

import { StyledPopupContent, WrapperWithBottomBorder } from '../CarrierConfig.styles';
import { ConfigStatus } from '../types/carrier.types';

interface ListInfo {
  type: ConfigStatus;
  title: string;
  isChecked: boolean;
}

interface Props {
  listsData: ListInfo[];
  setDisplayedList: (val: ConfigStatus[]) => void;
}

interface PopupProps {
  listsData: ListInfo[],
  setDisplayedList: (value: ConfigStatus[]) => void,
  setExpanded: (value: boolean) => void,
  currentListsData: ListInfo[],
  setCurrentListsData: (value: ListInfo[]) => void,
}

const PopupContent: React.FC<PopupProps> = ({
  listsData,
  setDisplayedList,
  setExpanded,
  currentListsData,
  setCurrentListsData,
}) => {
  const isSomeListIsSelected = useMemo(() => currentListsData
    .filter((list) => list.isChecked).length > 0, [currentListsData]);

  const isChecked = useCallback((listType: ConfigStatus) => !!currentListsData
    .find((list: ListInfo) => (list.type === listType) && list.isChecked), [currentListsData]);

  const handleSetChecked = useCallback((checkboxType: ConfigStatus) => {
    const updatedCheckboxes = currentListsData.map((checkbox: ListInfo) => {
      if (checkbox.type === checkboxType) {
        return { ...checkbox, isChecked: !checkbox.isChecked };
      }
      return checkbox;
    });

    setCurrentListsData(updatedCheckboxes);
  }, [currentListsData, setCurrentListsData]);

  const handleApply = useCallback(() => {
    const checkedListTypes = currentListsData
      .filter((list: ListInfo) => list.isChecked)
      .map((filteredList: ListInfo) => filteredList.type);

    setDisplayedList(checkedListTypes);
    setExpanded(false);
  }, [setDisplayedList, currentListsData, setExpanded]);

  return (
    <StyledPopupContent round="container1Round" data-testId="select-carrier-type-popup">
      <WrapperWithBottomBorder pad="spacing16">
        <Text weight={700}>Display</Text>
        {
          listsData.map((listData) => (
            <Box key={listData.type} margin={{ top: 'spacing12' }}>
              <CheckBox
                name="primary"
                role="checkbox"
                data-testId={`${listData.type.toLocaleLowerCase()}-carrier-checkbox`}
                aria-label="primary"
                checked={isChecked(listData.type)}
                onChange={() => handleSetChecked(listData.type)}
                label={<Text style={{ fontSize: 14 }}>{listData.title}</Text>}
              />
            </Box>
          ))
        }
      </WrapperWithBottomBorder>
      <Box pad="spacing16" direction="row" justify="between">
        <AppButton
          testId="close-carrier-popup"
          width="110px"
          buttonType="secondary"
          onClick={() => setExpanded(false)}
        >
          Cancel
        </AppButton>

        <AppButton
          testId="apply-carrier-popup"
          width="110px"
          onClick={handleApply}
          disabled={!isSomeListIsSelected}
        >
          Apply
        </AppButton>
      </Box>
    </StyledPopupContent>
  );
};

const SelectCarrierTypeButton: React.FC<Props> = ({ listsData, setDisplayedList }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentListsData, setCurrentListsData] = useState(listsData);

  useEffect(() => {
    if (!expanded) {
      setCurrentListsData(listsData);
    }
  }, [expanded, listsData]);

  return (
    <Box data-testId="select-carrier-type-wrapper" margin={{ left: 'spacing12' }} style={{ position: 'relative' }}>
      <PopupWrapper
        onVisible={setExpanded}
        controlledVisible={expanded}
        content={
          (
            <PopupContent
              listsData={listsData}
              setDisplayedList={setDisplayedList}
              setExpanded={setExpanded}
              currentListsData={currentListsData}
              setCurrentListsData={setCurrentListsData}
            />
          )
        }
        flat
        flex
        testId="select-carrier-type"
      >
        <AppButton
          testId="select-carrier-type"
          color="grey"
          style={{ width: '40px' }}
          buttonType="secondary"
          active={expanded}
          onlyIcon
        >
          <SetupOutlinedIcon />
        </AppButton>
      </PopupWrapper>
    </Box>
  );
};

export default SelectCarrierTypeButton;
