import React, { useCallback, useEffect, useState } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Box, getDuration,Preloader } from '@common-fe/common-fe';
import {  Hub } from 'aws-amplify/utils';

import Token from '@/utils/handlers/Token';
interface Props {
  children: React.ReactNode;
}

const AWS_STATUSES = {
  TOKEN_REFRESH: 'tokenRefresh',
  SIGN_IN: 'signIn',
  SIGN_OUT: 'signOut',
  TOKEN_REFRESH_FAIL: 'tokenRefresh_failure',
  TOKEN_REFRESH_SUCCESS: 'tokenRefresh_success',
};

const TIMEOUT = getDuration({seconds: 8});
const TokenRefreshWrapper: React.FC<Props> = ({ children }) => {
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);



  const handleRefreshToken = useCallback( async () => {
    try {
      
      const currentSession = await fetchAuthSession();
      const idToken = currentSession.tokens?.idToken?.toString() || '';
      await Token.storeToken(idToken);
    } catch (error) {
      setIsTokenRefreshing(false);
    }
  }, []);
  useEffect(() => {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
      // case AWS_STATUSES.TOKEN_REFRESH:
      case AWS_STATUSES.SIGN_IN:
      case AWS_STATUSES.TOKEN_REFRESH_SUCCESS:
        handleRefreshToken();
        setIsTokenRefreshing(false);
        break;
      default:
        break;
      }
    });
    setTimeout(() => {
      setIsTokenRefreshing(false);
    }, TIMEOUT);
  }, [handleRefreshToken]);

  return (
    <>{isTokenRefreshing ? (
      <Box height="100vh" align="center" justify="center">
        <Preloader />

      </Box>
    )  : children}
    </>
  );
};

export default TokenRefreshWrapper;