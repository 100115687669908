import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CONTENT_ID_SELECTOR,
  FlexList,
  FlexListPlaceholder,
  Inscription,
  PaginationBar,
  SearchInput,
  SelectDropdown,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import { DefaultSortTypesEnum, ListItemBase, OptionKey } from '@/common/types';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHasAccess } from '@/modules/core/hooks';
import { useListPagination } from '@/modules/employer/hooks';
import globalTheme from '@/styles/theme';

import { AddClaimProcessorAndTeamButton } from './AddClaimProcessorAndTeamButton/AddClaimProcessorAndTeamButton';
import { CreateClaimProcessorModal } from './CreateClaimProcessorModal/CreateClaimProcessorModal';
import { CreateClaimsTeamModal } from './CreateClaimsTeamModal/CreateClaimsTeamModal';
import { ClaimsTeam } from './ClaimsProcessors.types';
import FilterSection from './FilterSection';
import ManagePopUp from './ManagePopUp';
import useClaimsProcessorsList from './useClaimsProcessorsList';
import useTeamsList from './useTeamsList';

const MIN_SEARCH_LENGTH = 3;
const HEADER_HEIGHT = 146;
const HEADER_SPACING = 24;
const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'A-Z',
  },
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Z-A',
  },
];
const BACKLIGHT_DELAY = 2000;

const FixedWrapper = styled(Box)<{ isScrolled?: boolean }>`
  position: fixed;
  z-index: 1001;
`;
const PROCESSORS_HEADERS: ListItemBase[] = [
  {
    key: 'processors',
    title: 'Claims Processors',
    style: { maxWidth: '240px', marginRight: '24px' },
  },
  {
    key: 'role',
    title: 'Role',
    style: { maxWidth: '160px', marginRight: '24px' },
  },
  {
    key: 'Teams',
    title: 'Teams',
  },
];
const TEAMS_HEADERS: ListItemBase[] = [
  {
    key: 'teamName',
    title: 'Team name',
  },
  {
    key: 'accountType',
    title: 'Account type',
  },
  {
    key: 'teamMembers',
    title: 'Team members',
  },
];

const ClaimsProcessors = () => {
  const hasAccessToManageClaimTeam = useHasAccess([{ permission: Permissions.MANAGE_CLAIM_TEAMS }]);
  const [sortByProcessors, setSortByProcessors] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const [sortByTeams, setSortByTeams] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const [searchStringProcessors, setSearchStringProcessors] = useState('');
  const [searchStringTeams, setSearchStringTeams] = useState('');
  const [managedId, setManagedId] = useState<string | undefined>();
  const [managedTeamId, setManagedTeamId] = useState<string | undefined>();
  const [editableId, setEditableId] = useState<string | undefined>();
  const [isTeamsList, setIsTeamsList] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [justAddedTeamId, setJustAddedTeamId] = useState<string | undefined>();
  const [justAddedClaimProcessorId, setJustAddedClaimProcessorId] = useState<string | undefined>();
  const [isCreateClaimsTeamModalVisible, setIsCreateClaimsTeamModalVisible] = useState(false);
  const [isCreateClaimProcessorModalVisible, setIsCreateClaimProcessorModalVisible] = useState(false);
  const {
    page: pageProcessors,
    perPage: perPageProcessors,
    setPage: setPageProcessors,
    setPerPage: setPerPageProcessors,
  } = useListPagination();
  const {
    page: pageTeams,
    perPage: perPageTeams,
    setPage: setPageTeams,
    setPerPage: setPerPageTeams,
  } = useListPagination();

  useEffect(() => {
    setPageProcessors(0);
  }, [searchStringProcessors, sortByProcessors, setPageProcessors]);
  useEffect(() => {
    setPageTeams(0);
  }, [searchStringTeams, sortByTeams, setPageTeams]);

  const currentSearchProcessors = useMemo(() => {
    if (searchStringProcessors.length < MIN_SEARCH_LENGTH) return '';
    return searchStringProcessors;
  }, [searchStringProcessors]);
  const currentSearchTeams = useMemo(() => {
    if (searchStringTeams.length < MIN_SEARCH_LENGTH) return '';
    return searchStringTeams;
  }, [searchStringTeams]);

  const {
    list: claimsProcessorsList,
    total: totalProcessors,
    isLoading: isClaimsProcessorsListLoading,
    refetch: refetchClaimsProcessors,
  } = useClaimsProcessorsList({
    page: pageProcessors - 1,
    perPage: perPageProcessors,
    searchString: currentSearchProcessors,
    sortBy: sortByProcessors as DefaultSortTypesEnum,
    onTeamClickHandle: setManagedTeamId,
  }, justAddedClaimProcessorId);
  const {
    teams,
    list: teamsList,
    total: totalTeams,
    isLoading: isTeamsListLoading,
    refetch: refetchTeams,
  } = useTeamsList({
    page: pageTeams - 1,
    perPage: perPageTeams,
    searchString: currentSearchTeams,
    sortBy: sortByTeams as DefaultSortTypesEnum,
  }, justAddedTeamId);

  const getTeamById = useCallback((id?: string): ClaimsTeam | undefined => {
    if (!id) return undefined;
    return teams?.find((team) => team.id === id);
  }, [teams]);

  const currentTableTitle = useMemo(
    () => (isTeamsList ? `Claims Teams: ${totalTeams}` : `Claims processors: ${totalProcessors}`),
    [isTeamsList, totalProcessors, totalTeams],
  );

  const handleOnSuccess = useCallback((id?: string) => {
    refetchTeams();
    setJustAddedTeamId(id);
  }, [refetchTeams]);

  const handleOnClaimProcessorSuccess = useCallback((id?: string) => {
    refetchClaimsProcessors();
    setJustAddedClaimProcessorId(id);
  }, [refetchClaimsProcessors]);

  const handleEditTeam = useCallback((id: string) => {
    setEditableId(id);
    setIsCreateClaimsTeamModalVisible(true);
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (justAddedTeamId) {
      timer = setTimeout(() => setJustAddedTeamId(undefined), BACKLIGHT_DELAY);
    }

    if (justAddedClaimProcessorId) {
      timer = setTimeout(() => setJustAddedClaimProcessorId(undefined), BACKLIGHT_DELAY);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [justAddedTeamId, justAddedClaimProcessorId]);

  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);
    const updateScrolling = () => setIsScrolled(!!appContainer
      && appContainer.scrollTop > HEADER_SPACING / 2);

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
   
  }, []);

  return (
    <>
      <Box margin={{ bottom: '72px' }} align="center" width="100%" height="fit-content">
        {managedId || managedTeamId ? (
          <ManagePopUp
            isTeamMode={Boolean(isTeamsList || managedTeamId)}
            editableId={managedId || managedTeamId}
            clearEditableId={() => {
              setManagedId(undefined);
              setManagedTeamId(undefined);
            }}
          />
        ) : null}
        <FixedWrapper background="canvas" width="100%">
          <Topbar hideHeader={isScrolled} />
          <Box
            direction="row"
            width={globalTheme.defaultContentWidth}
            alignSelf="center"
            align="center"
            justify="between"
            wrap
            margin={{
              bottom: '3px',
              top: isScrolled ? 'spacing4' : 'spacing24',
            }}
            border={isScrolled
              ? { color: 'border2', side: 'bottom', size: 'small' }
              : undefined}
          >
            <Box margin={{ bottom: isScrolled ? undefined : 'spacing16' }}>
              <Inscription
                size="3xl"
                weight="bold"
                color="textBody"
                lineHeight="40px"
              >Manage Claims Team
              </Inscription>
            </Box>
            <Box
              pad={{ vertical: isScrolled ? '3px' : 'spacing4' }}
              border={isScrolled
                ? { color: 'border2', side: 'horizontal', size: 'small' }
                : undefined}
              align="center"
              direction="row"
            >
              <FilterSection isTeamsList={isTeamsList} setIsTeamsList={setIsTeamsList} totalProcessors={totalProcessors} totalTeams={totalTeams} />
              <AddClaimProcessorAndTeamButton
                createClaimsTeam={() => {
                  setIsTeamsList(true);
                  setIsCreateClaimsTeamModalVisible(true);
                }}
                addClaimsProcessor={() => {
                  setIsTeamsList(false);
                  setIsCreateClaimProcessorModalVisible(true);
                }}
              />
            </Box>
          </Box>
        </FixedWrapper>

        <Box
          width={globalTheme.defaultContentWidth}
          flex
          direction="column"
          margin={{ top: `${HEADER_HEIGHT + HEADER_SPACING}px` }}
          background="module"
          round="medium"
        >
          <Box align="center" pad={{ bottom: 'spacing16' }}>
            <Box
              width="100%"
              direction="row"
              justify="between"
              align="center"
              pad={{ horizontal: 'spacing24', top: 'spacing24' }}
            >
              <Inscription
                weight="bold"
                color="textBody"
                style={{ whiteSpace: 'nowrap' }}
              >{currentTableTitle}
              </Inscription>
              <Box direction="row" justify="end">
                <Box margin={{ left: 'spacing8' }} width={{ min: '340px' }} {...isTeamsList ? {} : { style: { display: 'none' } }}>
                  <SearchInput
                    value={searchStringTeams}
                    hasSearchingHistory
                    placeholder="Search for claims teams"
                    onChange={setSearchStringTeams}
                  />
                </Box>
                <Box margin={{ left: 'spacing8' }} width={{ min: '340px' }} {...isTeamsList ? { style: { display: 'none' } } : {}}>
                  <SearchInput
                    value={searchStringProcessors}
                    hasSearchingHistory
                    placeholder="Search for claims processors"
                    onChange={setSearchStringProcessors}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }} {...isTeamsList ? {} : { style: { display: 'none' } }}>
                  <SelectDropdown
                    id="sort_teams_by"
                    disabled={false}
                    name="Sort by"
                    prefix="Sort by"
                    singleMode
                    value={sortByTeams}
                    onChange={setSortByTeams}
                    activeTitle
                    options={SORTING_OPTIONS}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }} {...isTeamsList ? { style: { display: 'none' } } : {}}>
                  <SelectDropdown
                    id="sort_processors_by"
                    disabled={false}
                    name="Sort by"
                    prefix="Sort by"
                    singleMode
                    value={sortByProcessors}
                    onChange={setSortByProcessors}
                    activeTitle
                    options={SORTING_OPTIONS}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {isTeamsList ? (
            <FlexList
              testId="teams_list"
              headers={TEAMS_HEADERS}
              rows={teamsList || []}
              total={totalTeams || 0}
              pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
              moreCount={perPageTeams}
              loading={isTeamsListLoading}
              placeholder={<FlexListPlaceholder />}
              options={[
                {
                  name: 'Manage',
                  onClick: setManagedId,
                },
                ...hasAccessToManageClaimTeam ? [
                  {
                    name: 'Edit',
                    onClick: handleEditTeam,
                  },
                ] : []
              ]}
              footer={(
                <PaginationBar
                  page={pageTeams}
                  total={totalTeams}
                  pageSize={perPageTeams}
                  onChangePage={setPageTeams}
                  onChangePageSize={setPerPageTeams}
                />
              )}
            />
          ) : (
            <FlexList
              testId="claims_processors_list"
              headers={PROCESSORS_HEADERS}
              rows={claimsProcessorsList || []}
              total={totalProcessors || 0}
              pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
              moreCount={perPageProcessors}
              loading={isClaimsProcessorsListLoading}
              placeholder={<FlexListPlaceholder />}
              options={[
                {
                  name: 'Manage Team Assignment',
                  onClick: setManagedId,
                },
              ]}
              optionsStyle={{ width: '195px' }}
              footer={(
                <PaginationBar
                  page={pageProcessors}
                  total={totalProcessors}
                  pageSize={perPageProcessors}
                  onChangePage={setPageProcessors}
                  onChangePageSize={setPerPageProcessors}
                />
              )}
            />
          )}
        </Box>
      </Box>
      <CreateClaimsTeamModal
        visible={isCreateClaimsTeamModalVisible}
        onSetVisible={(value: boolean) => {
          setIsCreateClaimsTeamModalVisible(value);
          setEditableId(undefined);
        }}
        onSuccess={handleOnSuccess}
        editableTeam={getTeamById(editableId)}
      />
      <CreateClaimProcessorModal
        visible={isCreateClaimProcessorModalVisible}
        onSetVisible={setIsCreateClaimProcessorModalVisible}
        onSuccess={handleOnClaimProcessorSuccess}
      />
    </>
  );
};

export default ClaimsProcessors;
