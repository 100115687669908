import { Enrollment } from '@common-fe/common-fe';

import { storeCreator } from '@/utils/store/store.utils';

interface State {
  enrollments: Enrollment[] | null;
  isLoading: boolean;
  handleSetEnrollments: (enrollments: Enrollment[]) => void;
  handleSetIsLoading: (value: boolean) => void;
  handleReset: () => void;
}

export const useEmployeeEnrollmentsStore = storeCreator<State>((set) => ({
  enrollments: null,
  isLoading: true,

  handleSetEnrollments: (enrollments) => set((oldState) => ({
    ...oldState,
    enrollments,
  })),

  handleSetIsLoading: (isLoading) => set((oldState) => ({
    ...oldState,
    isLoading,
  })),

  handleReset: () => set((state) => ({
    ...state,
    enrollments: null,
  })),
}));
