import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
  validateTextInputLength,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';

import { PayrollGroupFormFields, PayrollGroupFormValues } from '../payrollGroup.types';
import { usePayrollGroupModalStore } from '../stores';

export const useAddPayrollGroupFields = (defaultValues?: PayrollGroupFormValues) => {
  const payrollGroupNames = usePayrollGroupModalStore((state) => state.payrollGroupNames);
  const payrollGroupCodes = usePayrollGroupModalStore((state) => state.payrollGroupCodes);
  const fields = useMemo(() => [
    {
      name: PayrollGroupFormFields.GROUP_NAME,
      type: FieldTypes.Text,
      showRequireIcon: true,
      label: 'Group name',
      placeholder: 'Enter group name',
      defaultValue: defaultValues?.[PayrollGroupFormFields.GROUP_NAME],
      validator: yup
        .string()
        .trim()
        .test(validateTextInputLength())
        .test({
          test: (val) => {
            if (defaultValues?.[PayrollGroupFormFields.GROUP_NAME] === val) {
              return true;
            }
            return !payrollGroupNames?.includes(val);
          },
          message: 'Payroll group with such name already exists',
        })
        .required(REQUIRED_TEXT),
    },
    {
      name: PayrollGroupFormFields.GROUP_CODE,
      type: FieldTypes.Text,
      showRequireIcon: true,
      label: 'Group code',
      placeholder: 'Enter group code',
      disabled: !!defaultValues?.[PayrollGroupFormFields.GROUP_CODE],
      defaultValue: defaultValues?.[PayrollGroupFormFields.GROUP_CODE],
      validator: yup
        .string()
        .trim()
        .test(validateTextInputLength(undefined, 40))
        .test({
          test: (val) => {
            if (defaultValues?.[PayrollGroupFormFields.GROUP_CODE] === val) {
              return true;
            }
            return !payrollGroupCodes?.includes(val);
          },
          message: 'Payroll group with such code already exists',
        })
        .required(REQUIRED_TEXT),
    },
  ] as Field[], [defaultValues, payrollGroupCodes, payrollGroupNames]);

  return fields;
};
