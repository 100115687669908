import React, {
  useCallback, useEffect,
  useMemo, useRef, useState } from 'react';
import { Box, ChevronUpIcon, Image } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Document } from '@/common/types';

import BorderWrapper from './BorderWrapper';

const OpenIcon = styled(ChevronUpIcon)<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '0deg' : '180deg')});
`;

const Wrap = styled(Box)`
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacings.spacing4};
  }
`;

const NUMBER_OF_SHOWN_ITEMS = 4;
const NUMBER_OF_HIDDEN_ITEMS = 6;
const SCROLLBAR_WIDTH = 16;
const ICON_SIZE = 40;
const PDF_TYPE = 'pdf';

const PdfIcon: React.FC<{ src: string }> = ({ src }) => (
  <>
    <Box
      style={{
        position: 'absolute',
        cursor: 'pointer',
      }}
      width="100%"
      height="100%"
    >
    </Box>
    <embed
      src={src}
      height="100%"
      width="54px"
    />
  </>
);

interface Props {
  documents: Document[];
  activeDocument?: number;
  activateDocument: (index: number) => void;
  activeDocumentId?: string;
}

const IconsPreview: React.FC<Props> = ({ documents, activeDocumentId, activateDocument }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shownIcons, hiddenIcons] = useMemo(() => ([
    documents.slice(0, NUMBER_OF_SHOWN_ITEMS),
    documents.slice(NUMBER_OF_SHOWN_ITEMS),
  ]), [documents]);
  const hasScrollbar = useMemo(() => hiddenIcons.length > NUMBER_OF_HIDDEN_ITEMS, [hiddenIcons]);
  const onClickHandle = useCallback(
    (id: string) => activateDocument(documents.findIndex((document) => document.id === id)),
    [activateDocument, documents],
  );

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleOutsideClick = useCallback((event: any) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    if (ref.current && !(ref.current as any)?.contains(event.target)) {
      setIsOpen(false);
    }
  }, [ref, setIsOpen]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [ref, handleOutsideClick]);

  if (!documents?.length) return null;

  return (
    <Box style={{ position: 'absolute', left: '7px' }} direction="row">
      {shownIcons?.length > 0 && (
        shownIcons.map((shownIcon) => (
          <Wrap key={shownIcon.id}>
            <BorderWrapper
              onClick={() => onClickHandle(shownIcon.id)}
              isActive={shownIcon.id === activeDocumentId}
              hasBoxShadow
              round="checkboxRound"
            >
              {shownIcon.type === PDF_TYPE
                ? <PdfIcon src={shownIcon.blob} />
                : <Image src={shownIcon.blob} alt="shown preview icon" />}
            </BorderWrapper>
          </Wrap>
        ))
      )}

      {hiddenIcons?.length > 0 && (
        <Box ref={ref}>
          <Box
            {...(hasScrollbar && isOpen ? {
              width: `${ICON_SIZE + SCROLLBAR_WIDTH}px`,
              background: 'canvas',
              round: { corner: 'bottom', size: 'xsmall' },
            } : {})}
          >
            <BorderWrapper
              onClick={() => setIsOpen(!isOpen)}
              round={isOpen ? { corner: 'bottom', size: 'xsmall' } : 'xsmall'}
            >
              <Box justify="center" align="center" height="100%">
                <OpenIcon
                  color={isOpen ? 'iconAccent' : 'iconPrimary'}
                  isOpen={isOpen}
                />
              </Box>
            </BorderWrapper>
          </Box>

          <Box
            overflow="overlay"
            height={{ max: `${NUMBER_OF_HIDDEN_ITEMS * ICON_SIZE}px` }}
            background="canvas"
            direction="column-reverse"
            round={{ corner: 'top', size: 'xsmall' }}
            style={{
              position: 'absolute',
              right: 0,
              top: hasScrollbar
                ? NUMBER_OF_HIDDEN_ITEMS * -ICON_SIZE
                : hiddenIcons.length * -ICON_SIZE,
              ...(isOpen ? {} : { display: 'none' }),
            }}
            {...(hasScrollbar ? {
              width: `${ICON_SIZE + SCROLLBAR_WIDTH}px`,
            } : {})}
          >
            {hiddenIcons.map((hiddenIcon) => (
              <BorderWrapper
                key={hiddenIcon.id}
                onClick={() => onClickHandle(hiddenIcon.id)}
                isActive={hiddenIcon.id === activeDocumentId}
              >
                {hiddenIcon.type === PDF_TYPE
                  ? <PdfIcon src={hiddenIcon.blob} />
                  : <Image src={hiddenIcon.blob} alt="hidden preview icon" />}
              </BorderWrapper>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default IconsPreview;
