import React from 'react';
import { Box,Image } from '@common-fe/common-fe';

import LogoIcon from '@/assets/logos/login-logo.svg';

const DefaultLogo = () => (
  <Box
    pad={{ horizontal: 'm', vertical: 'xs' }}
    round="container2Round"
    margin={{ top: 's' }}
    data-testid="default-logo"
    background="module"
    width="176px"
    height="60px"
    justify="center"
  >
    <Image src={LogoIcon} alt="logo" />
  </Box>
);

export default DefaultLogo;
