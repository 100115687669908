import {
  useCallback,
  useRef,
} from 'react';

import { PlanStatuses } from '@/modules/plan/plan.types';

import { useScrollStore } from '../stores';

export const PLANS_WRAPPER_UI_ID = 'plans-form-id';
const DEBOUNCE_DELAY = 200;
const NEXT_SECTION_SELECT_OFFSET = 195;

interface SectionList {
  sectionId: string,
  ref: () => HTMLElement | null,
}

export const usePlanSectionsScroll = (avaibleSections?: PlanStatuses[]) => {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const setActiveSection = useScrollStore((state) => state.setActiveSection);
  const sectionsWithRefs = avaibleSections?.map((sectionId) => ({
    sectionId,
    ref: () => document.getElementById(sectionId),
  })) as SectionList[];

  const onScrollHandler = useCallback((e: React.UIEvent<HTMLElement>) => {
    if (timer.current) return;

    timer.current = setTimeout(() => {
      const { scrollTop } = (e.target as HTMLElement);
      const setupWrapElem = document.getElementById(PLANS_WRAPPER_UI_ID);
      if (!setupWrapElem) return;

      const localActiveItem = sectionsWithRefs?.find((item) => {
        const moduleRef = item.ref();
        if (!moduleRef) return false;
        if (scrollTop === 0) return undefined;
        return (
          moduleRef.getBoundingClientRect().y
          + moduleRef.getBoundingClientRect().height
          - NEXT_SECTION_SELECT_OFFSET
        ) > 0;
      });
      setActiveSection(localActiveItem?.sectionId as PlanStatuses);

      if (timer.current) clearTimeout(timer.current);
      timer.current = null;
    }, DEBOUNCE_DELAY);
  }, [sectionsWithRefs, setActiveSection]);

  const scrollToSection = useCallback((data: { module?: string }) => {
    if (!data.module) return;

    const setupWrapElem = document.getElementById(PLANS_WRAPPER_UI_ID);
    if (!setupWrapElem) return;
    if (data.module) {
      const elem = document.getElementById(data.module);
      if (elem) {
        const tocItemPos = elem.offsetTop;
        setupWrapElem.scrollTo({ top: tocItemPos, behavior: 'smooth' });
      }
    }
  }, []);

  return {
    scrollToSection,
    onScrollHandler,
  };
};
