import Permissions from '@/common/permissions';
import { storeCreator } from '@/utils/store/store.utils';

import { ClaimEscalationNote } from '../../ClaimsDetails/claim.types';

export const CLAIMS_ESCALATION_REQUIREMENTS = [{ permission: Permissions.PROCESS_CLAIM }];

interface State {
  isOpenForm: boolean;
  isEscalated: boolean;
  isEditMode: boolean;
  claimEscalationValues?: ClaimEscalationNote;
  setClaimEscalationPopupFormState: (value: boolean) => void;
  setClaimEscalationFormValues: (value: ClaimEscalationNote) => void;
  setClaimIsEscalated: (value: boolean) => void;
  setClaimEscalationEditMode: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  isOpenForm: false,
  isEscalated: false,
  isEditMode: false,
  claimEscalationValues: undefined,
  setClaimEscalationPopupFormState: (value: boolean) => set(() => ({
    isOpenForm: value,
  })),
  setClaimEscalationFormValues: (value: ClaimEscalationNote) => set(() => ({
    claimEscalationValues: value,
  })),
  setClaimIsEscalated: (value: boolean) => set(() => ({
    isEscalated: value,
  })),
  setClaimEscalationEditMode: (value: boolean) => set(() => ({
    isEditMode: value,
  })),

  handleReset: () => set(() => ({
    isOpenForm: false,
    claimEscalationValues: undefined,
    isEscalated: false,
    isEditMode: false,
  })),
}));
