import { baseStoreCreator } from '@/utils/store';

export interface EmployerInfoPayload {
  employerName: string;
  employerEmployeeId?: string | null;
  partnerEmployeeId: string | null;
  sourceId?: string;
  employerStatus: string;
  ssn: string;
  uniqueId: string;
  internalId: string;
  payrollGroup: string;
  payrollGroupCode?: string | null;
  subsidiary: string;
  hireDate: string;
  terminationDate: string;
  statusEffectiveDate: string;
  organizationId: string,
  parentOrganizationId: string,
  payrollGroupId: string,
  validFrom: string,
  organizationPath: string,
}

export const DEFAULT_VALUES: EmployerInfoPayload = {
  employerName: '',
  employerEmployeeId: '',
  partnerEmployeeId: '',
  sourceId: '',
  employerStatus: '',
  internalId: '',
  ssn: '',
  uniqueId: '',
  payrollGroup: '',
  payrollGroupCode: '',
  subsidiary: '',
  hireDate: '',
  terminationDate: '',
  statusEffectiveDate: '',
  organizationId: '',
  parentOrganizationId: '',
  payrollGroupId: '',
  validFrom: '',
  organizationPath: '',
};

export const useStore = baseStoreCreator<EmployerInfoPayload>(DEFAULT_VALUES);

export default useStore;
