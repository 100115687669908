import React from 'react';
import {
  Box,
  Text,
} from '@common-fe/common-fe';

import { ActivateModalItem } from '../ExpressSetup.types';

import ContainerItem from './ContainerItem';

interface Props {
  title?: string;
  items: ActivateModalItem[];
  isPageMode?: boolean;
}

const ModalContainer: React.FC<Props> = ({
  title,
  items,
  isPageMode,
}) => {
  if (!items.length) return null;
  return (
    <Box
      data-testid="express-setup-modal-container"
      background={isPageMode ? 'border2' : 'canvas'}
      border={!isPageMode && { color: 'border2', size: 'small' }}
      pad={isPageMode ? '0' : 'l'}
      round={isPageMode ? 'none' : 'container1Round'}
      gap={isPageMode ? 'l' : '0'}
    >
      {title && <Text size="20px" color="textTitle" margin={{ bottom: 'm' }} style={{ lineHeight: '28px' }}>{title}</Text>}
      {items?.map((item, index) => (
        <ContainerItem
          key={index}
          title={item.title}
          accounts={item.accounts}
          description={item.description}
          isLastItem={index === items.length - 1}
          isPageMode={isPageMode}
        />
      ))}
    </Box>
  );
};

export default ModalContainer;
