import React, { useMemo } from 'react';
import { ListItem } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { ListItemType,PaginationParams } from '@/common/types';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import Link from '@/modules/core/components/Link';
import { usePayoutDefinitionQuery } from '@/modules/core/queries';
import { PayoutDefinition } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

export default (params?: PaginationParams) => {
  const {
    formatedData, isLoading, total,
  } = usePayoutDefinitionQuery(params);
  const formatedList = useMemo<ListItem[]>(() => formatedData?.map((item: PayoutDefinition) => ({
    id: item.id,
    fields: [
      {
        key: 'name',
        type: ListItemType.Info,
        title: item.name || '',
        link: ROUTES.PROCESSING_DEFINITION,
        flex: 1,
        node: (
          <Link to={ROUTES.PROCESSING_DEFINITION_DETAILS(item.id)}>
            <TextWithPointer
              title={item.name}
              size="medium"
              weight="bold"
              color="textBody"
            >
              {item.name || ''}
            </TextWithPointer>
          </Link>
        ),
      },
      {
        key: 'serviceCategories',
        type: ListItemType.Text,
        title: item?.categoryTypesStrings?.join(', ') || '',
        flex: 3,
        ellipsisMode: true,
      },
    ],
  })), [formatedData]);

  return {
    formatedList,
    isLoading,
    total,
  };
};
