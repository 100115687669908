import { useMutation, useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PLAN_TEMPLATES_KEY } from './useLinkedPlanTemplates.query';

const useUnlinkTemplateQuery = (onSuccess?:() => void) => {
  const queryClient = useQueryClient();
  const { params } = useRouteMatch<{ id: string }>();

  const {
    mutateAsync,
    isLoading,
  } = useMutation((id: string) => api.patch(PATHS.UNLINK_TEMPLATE(id, params.id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PLAN_TEMPLATES_KEY);
        if (onSuccess) onSuccess();
      },
    });

  return {
    handleDelete: mutateAsync,
    isLoading,
  };
};

export default useUnlinkTemplateQuery;
