import React, { useMemo } from 'react';
import { Box, costFormater } from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { RecordsInfo } from '@/modules/employer/components/Contributions/contributions.types';

import { ContributionInvoiceInfoBox } from './ContributionInvoiceInfoBox';

const STATIC_INVOICE_ID_VALUE = 'Notional contributions not invoiced';

interface Props {
  record?: RecordsInfo;
}

export const ContributionInvoiceListItem: React.FC<Props> = ({
  record
}) => {
  const isHsaRecord = useMemo(() => record?.planCode?.includes('HSA'), [record]);
  const invoiceAmount = useMemo(() => (record?.eeContributionAmount || 0) + (record?.erContributionAmount || 0), [record]);

  return (
    <Box
      data-testid="ContributionInvoiceListItem_container"
      background="canvas"
      direction="row"
      pad={{ vertical: 'spacing8', horizontal: 'spacing16' }}
      round="dropdownItemRound"
      width="100%"
      gap="spacing16"
    >
      <ContributionInvoiceInfoBox
        title="Plan"
        value={record?.planCode || '-'}
        width='25%'
      />
      <ContributionInvoiceInfoBox
        title="Amount"
        value={costFormater(invoiceAmount, true)}
        width='23%'
      />
      <ContributionInvoiceInfoBox
        title="Invoice ID"
        value={isHsaRecord ? record?.invoiceId || '-' : STATIC_INVOICE_ID_VALUE}
        width='27%'
      />
      <ContributionInvoiceInfoBox
        title="Status"
        value={capitalize(record?.contributionRequestStatus || record?.invoiceStatusType).replace(/_/g, ' ')}
        isStatus
        width='25%'
      />
    </Box>
  );
};
