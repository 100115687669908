import { storeCreator } from '@/utils/store/store.utils';
interface StateData {
  modalVisible: boolean;
  calendarId?: string;
  isPlansPresent?: boolean;
  isPayrollGroupsPresent?: boolean;
  calendarName?: string;
}
interface State extends StateData {

  handleReset: () => void;
  handleSetState: (payload: Partial<StateData>) => void;
}

const DEFAULT_VALUES: StateData = {
  modalVisible: false,
  calendarId: undefined,
  calendarName: undefined,
  isPlansPresent: false,
  isPayrollGroupsPresent: false
};

export const useStore = storeCreator<State>((set) => ({
  ...DEFAULT_VALUES,
  handleSetState: (value) => set((state) => ({
    ...state,
    ...value 
  })),


  handleReset: () => set((state) => ({
    ...state,
    ...DEFAULT_VALUES
  })),
}));