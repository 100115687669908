import React, { useMemo } from 'react';

import useGeneralInfoStore from '../GeneralInfo/stores/useGeneralInfo.store';

import CurrentStatus from './CurrentStatus';
import EmploymentStatuses from './EmploymentStatuses';
import Wrapper from './Wrapper';

const EmploymentStatusTimeline = () => {
  const { employmentStatuses } = useGeneralInfoStore((state) => state.state);
  const currentStatusType = useGeneralInfoStore((state) => state.sourceState?.status);
  const statuses = useMemo(() => {
    let hasActive = false;

    const statusesWithOnlyOneActive = employmentStatuses?.map((item) => {
      if (item.isActive && item.status !== currentStatusType) {
        return { ...item, isActive: false };
      }

      if (item.isActive && item.status === currentStatusType && !hasActive) {
        hasActive = true;
        return item;
      }

      if (hasActive && item.isActive) {
        return { ...item, isActive: false };
      }

      return item;
    });

    return statusesWithOnlyOneActive;
  }, [employmentStatuses, currentStatusType]);

  if (!statuses?.length) return null;

  return (
    <Wrapper
      currentStatus={<CurrentStatus type={currentStatusType} />}
    >
      <EmploymentStatuses statuses={statuses} />
    </Wrapper>
  );
};

export default EmploymentStatusTimeline;
