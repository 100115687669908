import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FlexList } from '@common-fe/common-fe';

import { ListItemBase } from '@/common/types';
import { useReconciliationReportDetails } from '@/modules/employer/queries/useReconciliationBankAccounts.query';

import useReconciliationReportDownloadModal from '../../../ReconciliationReports/components/ReconciliationReportDownloadModal/hooks/useReconciliationReportDownloadModal';

const HEADERS: ListItemBase[] = [
  {
    key: 'account',
    title: 'Account',
    flex: 1.9,
    sticky: true,
  },
  {
    key: 'accountID',
    title: 'Account ID',
    flex: 1.1,
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'ourBalance',
    title: 'Our Balance',
    flex: 1.2,
  },
  {
    key: 'bankBalance',
    title: 'Bank Balance',
    flex: 1.2,
  },
  {
    key: 'difference',
    title: 'Difference',
    flex: 1.2,
  },
  {
    key: 'prior',
    title: 'Prior',
    flex: 1.2,
  },
  {
    key: 'delta',
    title: 'Delta',
    flex: 1.2,
  },
  {
    key: 'credit',
    title: 'Credit',
    flex: 1.2,
  },
  {
    key: 'debit',
    title: 'Debit',
    flex: 1.2,
  },
  {
    key: 'closeDate',
    title: 'Close Date',
    flex: 0.8,
  },
];

export const ReconciliationReportsDetailsList = () => {
  const { id } = useParams<{ id: string }>();
  const { listFormat } = useReconciliationReportDetails(id);

  const {
    setIsModalVisible,
    setDownloadReportId,
  } = useReconciliationReportDownloadModal();

  const handleDownloadReport = useCallback(() => {
    setDownloadReportId(id);
    setIsModalVisible(true);
  }, [id, setDownloadReportId, setIsModalVisible]);

  return (
    <Box
      data-testid="ReconciliationListOfBankAccounts"
      background={{ color: 'module' }}
      round="container1Round"
    >

      <Box>
        <Box>
          <FlexList
            headers={HEADERS}
            rows={listFormat}
            total={listFormat.length}
            footer={null}
            options={[
              {
                name: 'Download Report',
                onClick: handleDownloadReport,
                optionStyle: {
                  fontSize: '14px',
                  fontWeight: 400,
                },
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};
