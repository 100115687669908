export default {
  FULL_ZIP_CODE: '99999-9999',
  DEFAULT_CARD_EXP_DATE: '99 / 99',
  FULL_DEBIT_CARD: '9999 9999 9999 9999',
  SHORT_ZIP_CODE: '99999',
  SECRET_CODE: '9999',
  ACCOUNT_NUMBER: '99999 99999 99999 99',
  ROUTING_NUMBER: '9999 99999',
  CARD_COUNT: 4,
  CARD_CHAR: '•',
};
