import dayjs from 'dayjs';
import { toNumber } from 'lodash';

import { ContributionAmountSideKey } from '../ContributionEmployeesSettings.types';
import { ContributionAccount, ContributionBackendErrors, Recipient } from '../types';

export const EMPTY_ERROR_MESSAGE = 'This field can’t be empty';



export const getAmountInputErrorMessage = (
  amountKey:ContributionAmountSideKey,
  contributionAccount: ContributionAccount,
  areErrorsShown?: boolean,
  backendError?: ContributionBackendErrors,
) => {
  if ((!areErrorsShown || contributionAccount?.isDisabled) && !backendError) return '';
  if ((!contributionAccount?.[ContributionAmountSideKey.EmployeeContributionAmount]
    && !contributionAccount?.[ContributionAmountSideKey.EmployerContributionAmount])
    || (backendError && backendError === ContributionBackendErrors.VALUE_IS_EMPTY)
  ) {
    return EMPTY_ERROR_MESSAGE;
  }
  if ((contributionAccount?.[amountKey]
    && contributionAccount?.contributionAmountMax
    && toNumber(contributionAccount?.[amountKey]) > toNumber(contributionAccount?.contributionAmountMax))
    || (backendError && backendError === ContributionBackendErrors.INCORRECT_VALUE)
  ) {
    return `This amount is more than available to contribute for this employee. To reach limit: $${contributionAccount?.contributionAmountMax}`;
  }
  return '';
};

export const getDateInputErrorMessage = (
  contributionAccount: ContributionAccount,
  areErrorsShown?: boolean,
  backendError?: ContributionBackendErrors,
) => {
  if ((!areErrorsShown || contributionAccount?.isDisabled) && !backendError) return '';
  if (!contributionAccount?.contributionDateOn || (backendError && backendError === ContributionBackendErrors.VALUE_IS_EMPTY)) return EMPTY_ERROR_MESSAGE;
  if (((contributionAccount?.contributionDateFrom
    && dayjs(contributionAccount.contributionDateFrom).valueOf() > dayjs(contributionAccount.contributionDateOn).valueOf())
    || (contributionAccount?.contributionDateTo
      && dayjs(contributionAccount.contributionDateTo).valueOf() < dayjs(contributionAccount.contributionDateOn).valueOf()))
    || (backendError && backendError === ContributionBackendErrors.INCORRECT_VALUE)) {
    return 'This plan is not active on the contribution date.';
  }
  return '';
};

export const findRecipientIndexWithError = (recipients: Recipient[]) => recipients
  .findIndex(
    (recipient) => recipient.contributionAccounts
      ?.some((contributionAccount) => getAmountInputErrorMessage(ContributionAmountSideKey.EmployeeContributionAmount, contributionAccount, true)
      || getAmountInputErrorMessage(ContributionAmountSideKey.EmployerContributionAmount, contributionAccount, true)
      || getDateInputErrorMessage(contributionAccount, true)),
  );
