export enum AllowAssumedContribution {
  ALLOWED_WITH_AUTO_APPROVAL = 'ALLOWED_WITH_AUTO_APPROVAL',
  ALLOWED_WITH_MANUAL_APPROVAL = 'ALLOWED_WITH_MANUAL_APPROVAL',
  DISABLED = 'DISABLED',
}

export enum FrequencyOptions {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const daysOfWeek: { [key: string]: number } = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
};

export interface AccountUsagePayload {
  allowAdminContribution?: string;
  allowAssumedContribution?: AllowAssumedContribution;
  administratorFeesBank?: string;
  administratorFeesMethod?: string;
  payrollContributionBank?: string;
  payrollContributionMethod?: string;
  fundingReplenishmentBank?: string;
  fundingReplenishmentMethod?: string;
  holdPeriod?: string;
  replenishmentFrequencyType?:
  | FrequencyOptions.DAILY
  | FrequencyOptions.WEEKLY
  | FrequencyOptions.MONTHLY;
  replenishmentDayOfWeek?: string;
  replenishmentCustomDay?: number;
  preFundElectionChangePercentage?: string;
  preFundMinimumThreshold?: string;
  preFundMinimumValue?: string;
  preFundPercent?: string;
  eeContributionsHoldPeriod?: string;
  migrationPreFundStartDate?: string;
}

export interface AccountUsageValues {
  administratorFeesBank?: string;
  payrollContributionBank?: string;
  fundingReplenishmentBank?: string;
  replenishmentFrequencyType?:
  | FrequencyOptions.DAILY
  | FrequencyOptions.WEEKLY
  | FrequencyOptions.MONTHLY;
  preFundElectionChangePercentage?: string;
  preFundMinimumThreshold?: string;
  preFundMinimumValue?: string;
  preFundPercent?: string;
  eeContributionsHoldPeriod?: string;
  migrationPreFundStartDate?: string;
  allowAdminContribution?: string;
  allowAssumedContribution?: AllowAssumedContribution;
}
