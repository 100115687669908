import { datadogRum } from '@datadog/browser-rum';

const currentConfig = {
  BASE_URL:  import.meta.env.REACT_APP_BASE_URL,
  RECAPTCHA_KEY:
     import.meta.env.RECAPTCHA_KEY || '6Ld1TLEaAAAAAAlr20gdHD5tnrWcTX8MT7abwglB',
};
const init = () => {
  const applicationId =import.meta.env.REACT_APP_DD_APPLICATION_ID;
  if(applicationId) {
    datadogRum.init({
      applicationId: import.meta.env.REACT_APP_DD_APPLICATION_ID,
      clientToken: import.meta.env.REACT_APP_DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: `${import.meta.env.REACT_APP_ENV}/${import.meta.env.REACT_APP_SIDE}`,
      env: import.meta.env.REACT_APP_ENV,
      version: import.meta.env.REACT_APP_VERSION, 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [import.meta.env.REACT_APP_BASE_URL, import.meta.env.REACT_APP_AMPLIFY_PUBLIC_BASE_URL]
    });
  }

};

init();
export default currentConfig;
