import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useAdditionalFieldStateStore, { AdditionalFieldsStatePayload } from '../Additional/stores/useAdditionalFieldState.store';
import useCardOfferedFieldsStateStore, { CardOfferedFieldsStatePayload } from '../CardOffered/stores/useCardOfferedFieldsState.store';
import usePlanNameFieldsStateStore, { PlanNameFieldsStatePayload } from '../PlanName/stores/usePlanNameFieldsState.store';

export type PlanDefinitionFieldsStatePayload = PlanNameFieldsStatePayload
& AdditionalFieldsStatePayload & CardOfferedFieldsStatePayload;

export default () => {
  const setPlanNameStore = usePlanNameFieldsStateStore((state) => state.setState);
  const setCardOfferedStore = useCardOfferedFieldsStateStore((state) => state.setState);
  const setAdditionalStore = useAdditionalFieldStateStore((state) => state.setState);

  const setSourcePlanNameStore = usePlanNameFieldsStateStore((state) => state.setSourceState);
  const setSourceCardOfferedStore = useCardOfferedFieldsStateStore((state) => state.setSourceState);
  const setSourceAdditionalStore = useAdditionalFieldStateStore((state) => state.setSourceState);

  const showErrorsPlanNameStore = usePlanNameFieldsStateStore((state) => state.setShowErrors);
  const showErrorsAdditionalStore = useAdditionalFieldStateStore((state) => state.setShowErrors);

  const showErrorsCardOffered = useCardOfferedFieldsStateStore((state) => state.setShowErrors);

  const handleResetPlanNameStore = usePlanNameFieldsStateStore((state) => state.handleReset);
  const handleResetAdditionalStore = useAdditionalFieldStateStore((state) => state.handleReset);
  const handleResetCardOfferedStore = useCardOfferedFieldsStateStore((state) => state.handleReset);

  const setPlanNameErrors = usePlanNameFieldsStateStore((state) => state.setErrors);
  const setCardOfferedErrors = useCardOfferedFieldsStateStore((state) => state.setErrors);
  const setAdditionalErrors = useAdditionalFieldStateStore((state) => state.setErrors);

  const setPlanNameErrorFields = usePlanNameFieldsStateStore((state) => state.setErrorFields);
  const setCardOfferedErrorFields = useCardOfferedFieldsStateStore((state) => state.setErrorFields);
  const setAdditionalErrorFields = useAdditionalFieldStateStore((state) => state.setErrorFields);

  const handleSetState = useCallback((values: Partial<PlanDefinitionFieldsStatePayload>) => {
    setPlanNameStore(values);
    setCardOfferedStore(values);
    setAdditionalStore(values);
  }, [setAdditionalStore, setCardOfferedStore, setPlanNameStore]);

  const handleSetSourceState = useCallback((values: Partial<PlanDefinitionFieldsStatePayload>) => {
    setSourcePlanNameStore(values);
    setSourceCardOfferedStore(values);
    setSourceAdditionalStore(values);
  }, [setSourceAdditionalStore, setSourceCardOfferedStore, setSourcePlanNameStore]);

  const handleReset = useCallback(() => {
    handleResetPlanNameStore();
    handleResetAdditionalStore();
    handleResetCardOfferedStore();
  }, [handleResetAdditionalStore, handleResetCardOfferedStore, handleResetPlanNameStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    // showErrorsPlanYearStore(value);
    showErrorsCardOffered(value);
    showErrorsAdditionalStore(value);
    showErrorsPlanNameStore(value);
  }, [showErrorsAdditionalStore, showErrorsCardOffered, showErrorsPlanNameStore]);
  const handleSetErrors = useCallback((values: Partial<PlanDefinitionFieldsStatePayload>) => {
    setPlanNameErrors(values);
    setCardOfferedErrors(values);
    setAdditionalErrors(values);
  }, [
    setAdditionalErrors,
    setCardOfferedErrors,
    setPlanNameErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<PlanDefinitionFieldsStatePayload>>) => {
      setPlanNameErrorFields(values);
      setCardOfferedErrorFields(values);
      setAdditionalErrorFields(values);
    }, [setAdditionalErrorFields, setCardOfferedErrorFields, setPlanNameErrorFields],
  );
  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
