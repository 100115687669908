import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useCopayStore, { CopayPayload } from '../stores/useCopay.store';

export default () => {
  const handleSetCopaysState = useCopayStore((state) => state.setState);
  const handleSetCopaysSourceState = useCopayStore((state) => state.setSourceState);
  const handleSetCopaysErrorFields = useCopayStore((state) => state.setErrorFields);
  const handleResetCopaysStore = useCopayStore((state) => state.handleReset);

  const handleSetState = useCallback((values: Partial<CopayPayload>) => {
    handleSetCopaysState(values);
  }, [handleSetCopaysState]);

  const handleSetSourceState = useCallback((values: Partial<CopayPayload>) => {
    handleSetCopaysSourceState(values);
  }, [handleSetCopaysSourceState]);

  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<CopayPayload>>) => {
    handleSetCopaysErrorFields(values);
  }, [handleSetCopaysErrorFields]);

  const handleReset = useCallback(() => {
    handleResetCopaysStore();
  }, [handleResetCopaysStore]);
  
  return {
    handleSetState,
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
  };
};
