import React from 'react';
import { Box } from '@common-fe/common-fe';

import { ReconciliationReportDownloadModal } from './components/ReconciliationReportDownloadModal';
import { ReconciliationDownloadModal } from './components/ReconciliationReportDownloadModal/types';
import { ReconciliationReportsList } from './components/ReconciliationReportsList';

const ReconciliationReports: React.FC = () => (
  <Box data-testid="ReconciliationReports">
    <ReconciliationReportsList />
    <ReconciliationReportDownloadModal modalType={ReconciliationDownloadModal.ListOfBankAccounts} />
  </Box>
);

export default ReconciliationReports;
