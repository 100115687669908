import React, { useEffect, useMemo } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import ToEditButton from '../../components/ToEditButton';
import { useSetupEmployerMode } from '../../hooks';
import { useSaveTypeStore } from '../../stores';

import { EmailConfigurationPayload } from './stores/useEmailConfiguration.store';
import { useEmailConfigurationFields } from './hooks';
import { PreviewSection } from './PreviewSection';
import { useEmailConfigurationStore } from './stores';

const EmailConfiguration = () => {
  const { formattedData } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  const { setValues, values } = useDebounceState<Partial<EmailConfigurationPayload>>();
  const isErrorShowed = useMemo(() => activeStatus || pendingStatus, [activeStatus, pendingStatus]);
  const handleSetState = useEmailConfigurationStore((state) => state.setState);

  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || formattedData?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, formattedData?.organizationStatusType]);

  const fields = useEmailConfigurationFields({
    disabled: viewMode,
    showErrors:
    isErrorShowed,
    selectedThemeId: values?.emailTheme,
  });

  useEffect(() => {
    if (values) {
      delete values.emailLogoType;
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (

    <Box
      key={`${fields[0].defaultValue}`}
      id="email_configuration"
      background="canvas"
      round="container1Round"
      margin={{ top: 'l' }}
      data-testid="email-configuration"
    >
      <Box>
        <FlexControlledForm
          formTitle="Email Theme Configuration"
          fields={fields}
          editMode={!viewMode}
          showError={isErrorShowed}
          onChangeValues={setValues}
          isThinMode
          wrapperStyle={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {isEditRestricted ? null : <ToEditButton />}
          {/* {formattedData.isTerminated ? null : <ToEditButton />} */}
        </FlexControlledForm>
      </Box>
      <PreviewSection />
    </Box>

  );
};

export default React.memo(EmailConfiguration);
