import { useMemo, useState } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { usePlanTemplatesQuery } from '@/modules/plan/queries';

import { PlanNamePayload } from '../stores/usePlanName.store';

export default (defaultValues?: Partial<PlanNamePayload>) => {
  const [searchValue, setSearchValue] = useState('');
  const {
    formattedTemplates,
    isLoading,
  } = usePlanTemplatesQuery(true, searchValue);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'planTemplate',
      type: FieldTypes.GroupedPlansDropdown,
      label: 'Plan template',
      onSearch: setSearchValue,
      showRequireIcon: true,
      searchValue,
      options: formattedTemplates,
      groupTitle: 'Categories',
      isSingleMode: true,
      isNarrowLabelMode: true,
      validator: VALIDATORS.REQUIRED_ARRAY,
      defaultValue: defaultValues?.planTemplate,
      isLoading,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [formattedTemplates, isLoading, searchValue]);

  return fields;
};
