import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.module};
`;

interface WrapperProps {
  imageSrc?: string;
}

export const BackgroundWrapper = styled(Box)<WrapperProps>`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background1};
  background-image: url("${({ imageSrc}) => imageSrc}");
`;

export const InfoTileIconWrapper = styled(Box)<{ hasBorder?: boolean }>`
  border-radius: 50%;
  ${({ hasBorder, theme }) => {
    if (hasBorder) return `outline: 1px solid ${theme.colors.navigationBackground};`;
    return '';
  }}
`;

export const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.canvas};
  opacity: 0.2;
  z-index: -1;
`;

export const StyledWrapper = styled(InfoTileIconWrapper)`
  &:hover div {
    opacity: 0.25;
  }
`;