import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, FlexControlledForm,
} from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import ToEditButton from '@/modules/employer/components/SetupEmployer/components/ToEditButton';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { PartnerMobileApp } from '@/modules/employer/types';

import { useSaveTypeStore } from '../stores';

import { useMobileThemesQuery } from './queries/useMobileThemes.query';
import useMobileAppCustomizationStore, { DEFAULT_VALUES, MobileAppCustomizationPayload } from './store/useMobileAppCustomization.store';
import { useMobileAppCustomizationFields } from './useMobileAppCustomizationFields';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const MobileAppCustomization: React.FC<Props> = ({ onDirty }) => {
  const { isLoading: isEmployerLoading, formattedData } = useGetEmployer();
  const { formattedList } = useMobileThemesQuery();
  const { viewMode } = useSetupEmployerMode();
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  const [values, setValues] = useState<MobileAppCustomizationPayload>({});
  const isErrorShowed = useMemo(() => (activeStatus
    || pendingStatus)
    && (!values?.googlePlayLink
    || !values?.appStoreLink),
  [activeStatus, pendingStatus, values]);

  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || formattedData?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, formattedData?.organizationStatusType]);

  const handleSetState = useMobileAppCustomizationStore((state) => state.setState);

  const fields = useMobileAppCustomizationFields({
    viewMode,
    isSubsidiary: formattedData.type === OrganizationTypes.subsidiary,
    appType: values?.participantMobileApp || formattedData.overridableProperties?.partnerMobileApp,
    googlePlayLink: values?.googlePlayLink || formattedData.overridableProperties?.partnerAppAndroidLink,
    appStoreLink: values?.appStoreLink || formattedData.overridableProperties?.partnerAppIosLink,
    theme: values?.mobileAppTheme || `${formattedData.overridableProperties?.mobileAppThemeId}`,
    themesOptions: formattedList,
  });

  const handleChangeValues = useCallback((newValues: MobileAppCustomizationPayload) => {
    setValues((prevValues) => ({
      ...prevValues,
      ...newValues,
    }));
  }, []);

  useEffect(() => {
    if (values && values?.participantMobileApp === PartnerMobileApp.NONE) {
      handleSetState({
        participantMobileApp: PartnerMobileApp.NONE,
        googlePlayLink: formattedData.overridableProperties?.partnerAppAndroidLink,
        appStoreLink: formattedData.overridableProperties?.partnerAppIosLink,
        mobileAppTheme: `${formattedData.overridableProperties?.mobileAppThemeId}`,
      });
    }

    if (values && values?.participantMobileApp === PartnerMobileApp.ELEVATE_APP) {
      handleSetState({
        participantMobileApp: PartnerMobileApp.ELEVATE_APP,
        googlePlayLink: formattedData.overridableProperties?.partnerAppAndroidLink,
        appStoreLink: formattedData.overridableProperties?.partnerAppIosLink,
        mobileAppTheme: values?.mobileAppTheme,
      });
    }

    if  (values && values?.participantMobileApp === PartnerMobileApp.PARTNER_OWN_APP) {
      handleSetState({
        participantMobileApp: PartnerMobileApp.PARTNER_OWN_APP,
        googlePlayLink: values?.googlePlayLink,
        appStoreLink: values?.appStoreLink,
        mobileAppTheme: `${formattedData.overridableProperties?.mobileAppThemeId}`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setValues({
      participantMobileApp: formattedData.overridableProperties?.partnerMobileApp,
      googlePlayLink: formattedData.overridableProperties?.partnerAppAndroidLink,
      appStoreLink: formattedData.overridableProperties?.partnerAppIosLink,
      mobileAppTheme: `${formattedData.overridableProperties?.mobileAppThemeId}`,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    handleSetState(DEFAULT_VALUES);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmployerLoading) return <FormLoader testId="MobileAppCustomizationForm" />;

  return (
    <Box
      direction="column"
      round="container1Round"
      background="canvas"
      id="mobile_app_customization"
      margin={{ top: 'spacing24' }}
    >
      <FlexControlledForm
        fields={fields}
        editMode={!viewMode && !isEditRestricted}
        formTitle="Mobile App Customization"
        onChangeValues={handleChangeValues}
        onDirty={onDirty}
        showError={isErrorShowed}
        isThinMode
      >
        {isEditRestricted ? null : <ToEditButton />}
      </FlexControlledForm>
    </Box>
  );
};
