import { OptionKey } from '@common-fe/common-fe';

import { GroupByFilter, LAST_4_DIGIT_KEY, SortByFilter, SortByKeys } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

export type ContributionGroupByType = [
  GroupByFilter | null,
  GroupByFilter | null,
];

export const CONTRIBUTION_DATE_RANGE_DEFAULT_VALUE: [Date | null, Date | null] = [null, null];
export const CONTRIBUTION_GROUP_BY_DEFAULT_VALUE: ContributionGroupByType = [
  GroupByFilter.OrgField,
  GroupByFilter.Partner,
];
export const CONTRIBUTION_SORT_BY_DEFAULT_VALUE = {
  [SortByKeys.orgSortDirection]: SortByFilter.OrgAsc,
  [SortByKeys.dateSortDirection]: SortByFilter.DateDesc,
};

interface State {
  search: string;
  dateRange: [Date | null, Date | null];
  groupBy: ContributionGroupByType;
  invoiceId: string;
  SSNOption: OptionKey;
  handleSetSSNOption: (value: OptionKey) => void;
  handleSetSearch: (value: string) => void;
  handleSetDateRange: (dateRange: [Date | null, Date | null]) => void;
  handleSetGroupBy: (groupBy: ContributionGroupByType) => void;
  handleSetInvoiceId: (value: string) => void;
  handleClear: () => void;
}

export const useContributionInvoiceReportFilterStore = storeCreator<State>((set) => ({
  search: '',
  invoiceId: '',
  SSNOption: LAST_4_DIGIT_KEY,
  dateRange: CONTRIBUTION_DATE_RANGE_DEFAULT_VALUE,
  groupBy: CONTRIBUTION_GROUP_BY_DEFAULT_VALUE,
  handleSetSSNOption: (value: OptionKey) => set(() => ({
    SSNOption: value,
  })),
  handleSetSearch: (value: string) => set(() => ({
    search: value,
  })),

  handleSetInvoiceId: (value: string) => set(() => ({
    invoiceId: value,
  })),

  handleSetDateRange: (dateRange: [Date | null, Date | null]) => set(() => ({
    dateRange,
  })),

  handleSetGroupBy: (groupBy: ContributionGroupByType) => set(() => ({
    groupBy,
  })),

  handleClear: () => set(() => ({
    search: '',
    invoiceId: '',
    SSNOption: LAST_4_DIGIT_KEY,
    dateRange: CONTRIBUTION_DATE_RANGE_DEFAULT_VALUE,
    groupBy: CONTRIBUTION_GROUP_BY_DEFAULT_VALUE,
  })),
}));
