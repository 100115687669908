import { useCallback } from 'react';

import useOrganizationPropertiesQuery from '@/modules/ReimburseMe/queries/useOrganizationProperties.query';
import { PaymentMethod, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';
import { useAuthStore } from '@/modules/user/stores';

const useOrganizationProperties = () => {
  const { user } = useAuthStore();
  const { organizationProperties, ...queryInfo } = useOrganizationPropertiesQuery(
    user?.organizationId,
  );

  const checkIsAvailablePaymentMethod = useCallback((paymentMethod?: PaymentMethod) => {
    if (!organizationProperties || !paymentMethod) return false;
    return organizationProperties.allowedPaymentMethods.some(
      (paymentMethodType: PaymentMethodType) => paymentMethod.type === paymentMethodType,
    );
  }, [organizationProperties]);

  return {
    organizationProperties,
    checkIsAvailablePaymentMethod,
    ...queryInfo,
  };
};

export default useOrganizationProperties;
