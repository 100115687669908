import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Box,
  Heading, ToggleButton, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { PlansType } from '@/modules/plan/plan.types';

export const StyledHeading = styled(Heading)`
  transition: .2s;
`;

interface Props {
  onClick: (name: PlansType) => void;
  clearSearchValue: (value: string) => void;
  name: PlansType;
  currPlan: PlansType;
  text: string;
  link?: string;
  testId?: string;
}

const SelectButton: React.FC<Props> = ({
  name, currPlan, text, onClick, link, testId = '', clearSearchValue,
}) => (
  <HashLink
    to={`${link || ''}#${name}`}
    style={{
      textDecoration: 'none',
    }}
  >
    <Box data-testId={`${testId}_select-button`} align="center" height={{ max: '40px' }}>
      <ToggleButton
        active={currPlan === name}
        onClick={() => {
          clearSearchValue('');
          onClick(name);
        }}
      >

        {text}

      </ToggleButton>
    </Box>
  </HashLink>

);

export default SelectButton;
