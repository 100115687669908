import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface LinkedPlanTemplatesPayload {
  template_ids: number[];
}

export const useAddPlanTemplatesQuery = (
  id?: string,
) => {
  const { mutateAsync } = useMutation(
    (payload: LinkedPlanTemplatesPayload) => api.patch(PATHS.LINKED_TEMPLATES(id), payload),
  );
  return mutateAsync;
};

export default useAddPlanTemplatesQuery;
