import React, { useMemo, useState } from 'react';

import { AccountWrapper } from './ContributionEmployeesSettings.styles';
import ContributionRecipientAccount from './ContributionRecipientAccount';
import EditDataModalPopup from './EditDataModalPopup';
import { ContributionAccount, CreateManualContributionErrors, Recipient } from './types';

interface Props {
  recipient?: Recipient;
  taxYears?: string[];
  areErrorsShown?: boolean;
  backendErrors?: CreateManualContributionErrors[];
}

const ContributionRecipient: React.FC<Props> = ({ recipient, taxYears, areErrorsShown, backendErrors }) => {
  const [editingAccount, setEditingAccount] = useState<ContributionAccount | null>(null);
  const areAllAccountsDisabled = useMemo(() => !recipient?.contributionAccounts?.some((item) => !item?.isDisabled), [recipient]);

  return (
    <>
      <AccountWrapper
        pad={{ horizontal: 'spacing24', vertical: 'spacing12' }}
        round="8px"
        background={areAllAccountsDisabled ? 'border2' : 'canvas'}
        border={{ size: 'small', color: 'border2' }}
      >
        {recipient?.contributionAccounts?.map((account, index) => (
          <ContributionRecipientAccount
            key={account?.planCode}
            recipient={recipient}
            account={account}
            hasName={!index}
            setEditingAccount={setEditingAccount}
            areErrorsShown={areErrorsShown}
            areAllAccountsDisabled={areAllAccountsDisabled}
            backendErrors={backendErrors}
          />
        ))}
      </AccountWrapper>

      {editingAccount ? (
        <EditDataModalPopup
          isVisible
          close={() => setEditingAccount(null)}
          taxYears={taxYears}
          recipient={recipient}
          contributionAccount={editingAccount}
        />
      ) : null}
    </>
  );
};

export default ContributionRecipient;
