import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum,FieldStateTypes } from '@common-fe/common-fe';

import { api } from '@/api';
import { OrganizationTypes,ROOT_ORGANIZATION } from '@/common/constants';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import {
  PlanFieldState,
  PlanGroupedListResponse,
  PlanListItemResponse,
  PlanStatuses,
  PlanTypesLabel,
} from '@/modules/plan/plan.types';
import { createPathFromIds,getIdsFromPath } from '@/utils/modifiers';

export const PLAN_TEMPLATES_KEY = 'getPlanTempaltes';

type ExpandedOption = Option & {
  accountType: string;
  accountTypeState: FieldStateTypes;
  name: string;
  nameState: FieldStateTypes;
};
export const usePlanTemplatesQuery = (refetchOnMount = false, searchName?: string) => {
  const { observingOrganization } = useCurrentOrganization();

  const {
    systemId, partnerId, distributorId, employerId,
  } = getIdsFromPath(observingOrganization.path);

  const employerOrganizationPath = createPathFromIds({
    systemId,
    partnerId,
    distributorId,
    employerId,
  });

  const isSubsidiary = useMemo(
    () => observingOrganization?.type === OrganizationTypes.subsidiary,
    [observingOrganization],
  );

  const organizationPath = useMemo(
    () => (isSubsidiary ? employerOrganizationPath : observingOrganization?.path),
    [isSubsidiary, employerOrganizationPath, observingOrganization],
  );

  const {
    data,
    isLoading,
    isSuccess,
  } = useQuery([PLAN_TEMPLATES_KEY, observingOrganization?.path, searchName],
    () => api.get<PlanGroupedListResponse>(PATHS.TEMPLATES, {
      params: {
        plan_status: PlanStatuses.ACTIVE,
        organization_path: organizationPath || ROOT_ORGANIZATION,
        name: searchName,
        size: 10000, // KISS
      },
    }), {
      refetchOnMount,
    });

  const formattedListTemplates = useMemo<ExpandedOption[]>(() => {
    const list = data?.data || {};
    const mergedArray = Object.values(list).flatMap((array) => array);
    return mergedArray.map((item) => ({
      key: `${item.id}`,
      value: item.name,
      title: item.name,
      accountType: item.account_type,
      accountTypeState: item.account_type_state as FieldStateTypes,
      name: item.name,
      nameState: item.name_state as FieldStateTypes,
    }));
  }, [data]);

  const formattedTemplates = useMemo(() => {
    const list = data?.data || {};
    return Object.entries(list)?.map(([category, items]) => ({
      category: PlanTypesLabel[category as keyof typeof PlanTypesLabel],
      plans: items
        ?.map((item: PlanListItemResponse) => ({
          id: item?.id?.toString(),
          typeLabel: PlanTypesLabel[item?.account_type as keyof typeof PlanTypesLabel],
          type: item.account_type as CardTypesEnum,
          name: item?.name,
          typeState: item.account_type_state as PlanFieldState,
          status: item.plan_status as PlanStatuses,
          planYearId: item.plan_year?.id,
          planYearName: item.plan_year?.name,
        })),
    }))
      .filter((category) => category?.plans?.length > 0)
      .sort((a, b) => a?.category?.localeCompare(b?.category));
  }, [data]);

  return {
    formattedListTemplates,
    formattedTemplates,
    isLoading,
    isSuccess,
  };
};
