import React from 'react';
import {
  AppButton,
  Box, FormField,   Notification,
  NotificationStatusColorType,
  NotificationStatusIcon, Text,
} from '@common-fe/common-fe';

import { Label, StyledContent, TextInputWrap } from './NotificationDetails.styles';

export interface Props {
  data?: Notification | null;
  dismissNotification: (id?: string) => void;
  onCancel: () => void;
}

const NEEDS_YOUR_HELP = 'Needs your help';
const FOR_YOUR_INFORMATION = 'For Your Information';
const IMPORTANT = 'Important';
const EMPTY_STATE_TEXT = '-';
const NotificationDetails: React.FC<Props> = ({ data, dismissNotification, onCancel }) => {
  let statusTip;
  if (data?.status === NotificationStatusColorType.WARNING) {
    statusTip = NEEDS_YOUR_HELP;
  } else if (data?.status === NotificationStatusColorType.SUCCESS) {
    statusTip = FOR_YOUR_INFORMATION;
    // maybe there was a bug here
  } else if (data?.status) {
    statusTip = IMPORTANT;
  }
  return (
    <>
      <Box background="module" pad="medium" round="container1Round" data-testid="ContactModal-view-wrapper">
        <StyledContent background="canvas" pad="medium" round="container1Round">
          <TextInputWrap viewMode label={<Label viewMode>Title</Label>}>
            <FormField>
              <Text weight="bold" data-testid="title-label">{data?.text || EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>Description</Label>}>
            <FormField>
              <Text data-testid="description-label">{data?.additionalText || EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>Priority</Label>}>
            <FormField>
              <Box direction="row">
                <NotificationStatusIcon status={data?.status} />
                <Text data-testid="priority-label">{statusTip || EMPTY_STATE_TEXT}</Text>
              </Box>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>Date created</Label>}>
            <FormField>
              <Text data-testid="date-label">{data?.time || EMPTY_STATE_TEXT}</Text>
            </FormField>
          </TextInputWrap>
          <TextInputWrap viewMode label={<Label viewMode>Employer / Partner</Label>}>
            <FormField>
              <Text data-testid="ContactModal-firstName-label">Elevate</Text>
            </FormField>
          </TextInputWrap>
        </StyledContent>
      </Box>
      <Box direction="row" justify="between" align="center" pad={{ top: 'medium' }}>
        <AppButton
          testId="dismiss"
          buttonType="secondary"
          onClick={() => {
            dismissNotification(data?.id);
            onCancel();
          }}
        >
          Dismiss
        </AppButton>
        <Box>
          <AppButton
            testId="go-to-page"
            onClick={() => {}}
          >
            Go To Page
          </AppButton>
          {/* <RawButton
            label={<Text color="textOnColor" weight="bold">Contact Support</Text>}
            primary
            onClick={() => {}}
          /> */}
        </Box>
      </Box>
    </>
  );
};
export default NotificationDetails;
