import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface BankAccountCategorie {
  id: string,
  name: string,
  description: string,
}

const EMPTY_ITEM_NAME = 'NONE';

const getBankAccountCategories = () => api.get<BankAccountCategorie[]>(
  PATHS.BANK_ACCOUNT_CATEGORIES,
);
const BANK_ACCOUNT_CATEGORIES_LIST_QUERY_NAME = 'getBankAccountCategories';
const useBankAccountCategoriesQuery = () => {
  const { data, isLoading } = useQuery(
    BANK_ACCOUNT_CATEGORIES_LIST_QUERY_NAME, getBankAccountCategories,
  );
  const formatedData = useMemo(
    () => data?.data.filter((item) => item.name !== EMPTY_ITEM_NAME).map((item) => ({
      id: `${item.id}`,
      name: item.name,
      description: item.description,
    })), [data],
  );

  return {
    data: formatedData || [],
    isLoading,
  };
};

export default useBankAccountCategoriesQuery;
