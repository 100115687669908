import {
  ActivityType,
  Box,
  CardTypesEnum,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.module};
`;

interface BarProps {
  width: number;
  typeKey?: ActivityType;
  planTypeKey?: CardTypesEnum;
}
export const Bar = styled(Text)<BarProps>`
  ${({ theme }) => theme.fonts.text16Bold};
  height: 48px;
  width: ${({ width = 5 }) => width}%;
  min-width: 25px;
  transition: all 0.15s;
  background-color: ${({ theme, typeKey, planTypeKey }) => {
    if (typeKey) {
      return theme.colors.activity[typeKey] || theme.colors.activity[ActivityType.OTHER];
    }
    if (planTypeKey) {
      return theme.colors.cardTypes[planTypeKey] || theme.colors.activity[ActivityType.OTHER];
    }
    return theme.colors.border2;
  }};
  color: ${({ theme, typeKey, planTypeKey }) => (typeKey || planTypeKey ? theme.colors.iconOnColor : theme.colors.textDisabled)};
  display: flex;
  align-items: center;
  justify-content: center;
 
`;

export const ChartWrapper = styled(Box)`
  position: relative;
`;

interface ContentWrapperProps {
  inlineMode?: boolean;
  isWithoutHeader?: boolean;
  isPortfolioMode?: boolean;
}
export const ContentWrapper = styled(Box)<ContentWrapperProps>`
  display: flex;
  ${({ theme, isPortfolioMode }) => {
    if (isPortfolioMode) {
      return {
        borderRadius: `${theme.rounds.container1Round} ${theme.rounds.container1Round} 0 0`,
      };
    }

    return {
      borderRadius: theme.rounds.container1Round,
    };
  }};
  flex-direction: column;
  ${({ inlineMode, theme }) => {
    if (inlineMode) {
      return {
        ...theme.shadows.default,
        padding: `${theme.spacings.spacing32} ${theme.spacings.spacing24} ${theme.spacings.spacing24} ${theme.spacings.spacing24}`,
        backgroundColor: theme.colors.canvas,
      };
    }
    return {};
  }}
`;

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.text16Bold};
  flex: 1;
  color: ${({ theme }) => theme.colors.textBody || ''};
`;
export const LabelsWrapper = styled(Box)`
  flex: 1;
  flex-wrap: wrap;
  position: relative;
`;

interface LabelProps {
  typeKey?: ActivityType;
  planTypeKey?: CardTypesEnum;
  inline?: boolean;
}
export const Label = styled(Text)<LabelProps>`
  width:  ${({ inline }) => (inline ? undefined : '33.33%')} ;
  ${({ theme }) => theme.fonts.text12Medium};
  padding-left: 20px;
  padding-right: ${({ inline, theme }) => (inline ? theme.spacings.spacing32 : undefined)};
  margin-right: ${({ theme, inline }) => (inline ? theme.spacings.spacing8 : undefined)};
  margin-top: ${({ theme, inline }) => (inline ? theme.spacings.spacing12 : undefined)};
  box-sizing: border-box;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacings.spacing4};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &::before {
    content: '';
    left: 0;
    top: 3px;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: ${({ theme }) => theme.rounds.smallRound};
    background-color: ${({ theme, typeKey, planTypeKey }) => {
    if (typeKey) {
      return theme.colors.activity[typeKey] || theme.colors.activity[ActivityType.OTHER];
    }
    if (planTypeKey) {
      return theme.colors.cardTypes[planTypeKey] || theme.colors.activity[ActivityType.OTHER];
    }
    return theme.colors.activity[ActivityType.OTHER];
  }}
  }
`;

export const TotalHeader = styled.h4`
  ${({ theme }) => theme.fonts.text16Bold};
`;

export const Cost = styled(Text)`
  ${({ theme }) => theme.fonts.text16Bold};
  /* color: ${({ theme }) => theme.colors.textAccent}; */
  margin-left: ${({ theme }) => theme.spacings.spacing8};
  // margin-right: ${({ theme }) => theme.spacings.spacing24};
`;

export const TipWrapper = styled(Box)`
  ${({ theme }) => theme.shadows.active};
  margin: 20px auto;
  position: relative;
`;
