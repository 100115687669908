import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { useCurrentOrganization } from '@/modules/core/hooks';

interface Response {
  logo_url: string;
}
export default () => {
  const { observingOrganization: { id } } = useCurrentOrganization();

  const mutate = useMutation(
    (file: object) => {
      const formData = new FormData();
      formData.append('file', file as Blob);

      return api.post<Response>(PATHS.ORGANIZATION_LOGO_EMAIL, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            'organization-id': id,
          },
        });
    },
  );
  return {
    handleSave: mutate.mutateAsync,
    isLoading: mutate.isLoading,
  };
};
