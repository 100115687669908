import React, { useMemo } from 'react';
import { Box, Field, FieldTypes, Inscription, Option, WarningIcon } from '@common-fe/common-fe';
import _ from 'lodash';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import {
  ConnectToOptions,
  PayrollCalendarUseFormFields,
  PayrollCalendarUseFormValues,
} from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/payrollCalendar.types';

interface Props {
  formValues?: PayrollCalendarUseFormValues;
  defaultValues?: PayrollCalendarUseFormValues;
  alreadyUsedValues?: PayrollCalendarUseFormValues;
  disabledPlansList?: string[];
  disabledPayrollGroupsList?: string[];
  payrollGroupsOptions?: Option[];
  plansOptions?: Option[];
  isAllOptionDisabled?: boolean;
  isPlanOptionDisabled?: boolean;
  isGroupOptionDisabled?: boolean;
  showMoreCount?: number;
  onShowMore?: () => void;
}

export const usePayrollCalendarUseFormFields = ({
  formValues,
  defaultValues,
  payrollGroupsOptions,
  alreadyUsedValues,
  plansOptions,
  isAllOptionDisabled,
  isPlanOptionDisabled,
  isGroupOptionDisabled,
  showMoreCount,
  disabledPlansList,
  disabledPayrollGroupsList,
  onShowMore,
}: Props) => {
  const fields = useMemo(
    () => [
      {
        name: PayrollCalendarUseFormFields.CONNECT_TO,
        type: FieldTypes.Radio,
        showRequireIcon: true,
        label: 'Connect to',
        defaultValue:
          alreadyUsedValues?.[PayrollCalendarUseFormFields.CONNECT_TO] ||
          defaultValues?.[PayrollCalendarUseFormFields.CONNECT_TO] ||
          '',
        options: [
          {
            label: 'All plans and groups without a calendar',
            hintText: isAllOptionDisabled ? (
              <Box pad={{ left: 'spacing32' }} direction="row" align="start" gap="spacing4">
                <WarningIcon size="16px" />
                <Inscription size="12px" weight={500} color="textSecondary" lineHeight="18px">
                  A default calendar already exists, you cannot create a second default calendar.
                </Inscription>
              </Box>
            ) : (
              <Box pad={{ left: 'spacing32' }}>
                <Inscription size="12px" weight={500} color="textSecondary" lineHeight="18px">
                  This will be applied to all plans and all payroll groups without a calendar.
                </Inscription>
              </Box>
            ),
            value: ConnectToOptions.ALL,
            isDisabled: isAllOptionDisabled,
          },
          {
            label: 'Specific plans',
            value: ConnectToOptions.PLAN,
            isDisabled: isPlanOptionDisabled || !plansOptions?.length,
          },
          {
            label: 'Specific payroll groups',
            value: ConnectToOptions.GROUP,
            isDisabled: isGroupOptionDisabled || !payrollGroupsOptions?.length,
          },
        ],
        validator: VALIDATORS.REQUIRED_STRING,
      },
      ...(formValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN
        ? [
          {
            name: PayrollCalendarUseFormFields.PLANS,
            type: FieldTypes.CheckBoxGroup,
            showRequireIcon: true,
            label: 'Plans',
            options: plansOptions,
            value:
                alreadyUsedValues?.[PayrollCalendarUseFormFields.PLANS] ||
                defaultValues?.[PayrollCalendarUseFormFields.PLANS] ||
                '',
            validator: VALIDATORS.ARRAY.min(1, REQUIRED_TEXT)
              .required(REQUIRED_TEXT)
              .test({
                test: (value) => {
                  const list = disabledPlansList || [];
                  const difference = _.difference(value as string[], list);
                  return !!difference.length;
                },
                message: REQUIRED_TEXT,
              }),
          },
        ]
        : []),
      ...(formValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP
        ? [
          {
            name: PayrollCalendarUseFormFields.PAYROLL_GROUPS,
            type: FieldTypes.CheckBoxGroup,
            showRequireIcon: true,
            label: 'Payroll groups',
            options: payrollGroupsOptions,
            value:
                alreadyUsedValues?.[PayrollCalendarUseFormFields.PAYROLL_GROUPS] ||
                defaultValues?.[PayrollCalendarUseFormFields.PAYROLL_GROUPS] ||
                [],
            validator: VALIDATORS.ARRAY.min(1, REQUIRED_TEXT)
              .required(REQUIRED_TEXT)
              .test({
                test: (value) => {
                  const list = disabledPayrollGroupsList || [];
                  const difference = _.difference(value as string[], list);
                  return !!difference.length;
                },
                message: REQUIRED_TEXT,
              }),
          },
        ]
        : []),
      ...((formValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP ||
        formValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN) &&
      showMoreCount
        ? [
          {
            name: 'showMore',
            type: FieldTypes.Node,
            label: '',
            style: { margin: 0 },
            fieldWrapperStyles: {
              marginBlock: '-12px',
            },
            node: (
              <Box align="center" justify="center" onClick={onShowMore}>
                <Inscription color="textAccent" lineHeight="20px" textAlign="center">
                  Show more ({showMoreCount})
                </Inscription>
              </Box>
            ),
          },
        ]
        : []),
    ],
    [
      alreadyUsedValues,
      defaultValues,
      isAllOptionDisabled,
      isPlanOptionDisabled,
      plansOptions,
      isGroupOptionDisabled,
      payrollGroupsOptions,
      formValues,
      showMoreCount,
      onShowMore,
      disabledPlansList,
      disabledPayrollGroupsList,
    ]
  );

  return fields as Field[];
};
