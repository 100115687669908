import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import useAccessToSetupFields from '../AddEditContactForm/hooks/useAccessToSetupFields';
import useContactFields from '../AddEditContactForm/hooks/useContactFields';
import useRoleFields from '../AddEditContactForm/hooks/useRoleFields';
import useContactStore from '../AddEditContactForm/stores/useContact.store';

const useContactFormValidate = () => {
  const { getValidateFields, getErrors } = useValidationUtils();

  const contactState = useContactStore((state) => state.state);
  const contactFields = useContactFields();
  const contactRoleFields = useRoleFields();
  const contactAccessToSetupFields = useAccessToSetupFields();

  const contactErrors = useMemo(() => {
    const contactFieldErrors: FieldError = getErrors(contactState, getValidateFields(contactFields));
    const roleFieldErrors: FieldError = getErrors(contactState, getValidateFields(contactRoleFields));
    const accessToSetupFieldsErrors: FieldError = getErrors(contactState, getValidateFields(contactAccessToSetupFields));

    const allErrors: FieldError = {
      ...contactFieldErrors,
      ...roleFieldErrors,
      ...(contactState.isPrimary !== false || contactState?.role !== Roles.noAccess ? accessToSetupFieldsErrors : {}),
    };
    return allErrors;
  }, [getErrors, contactState, getValidateFields, contactFields, contactRoleFields, contactAccessToSetupFields]);

  return contactErrors;
};

export default useContactFormValidate;
