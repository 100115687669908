import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { SourceIds } from '@/modules/employee/employee.types';

const FIELDS = {
  PARTNER_EMPLOYEE_ID: 'employmentPartnerEmployeeId',
  EMPLOYER_EMPLOYEE_ID: 'employmentEmployerEmployeeId',
  EMPLOYMENT_STATUS: 'employmentStatus',
  EMPLOYMENT_STATUS_EFFECTIVE_DATE: 'employmentStatusEffectiveDate',
};

const MAX_FIELD_LENGTH = 40;

export const EMPLOYMENT_TYPES = [
  {
    key: 'A',
    value: 'Active',
    title: 'Active',
  },
  {
    key: 'T',
    value: 'Terminated',
    title: 'Terminated',
  },
  {
    key: 'R',
    value: 'Retired',
    title: 'Retired',
  },
  {
    key: 'L',
    value: 'Leave of absence',
    title: 'Leave of absence',
  },
  {
    key: 'F',
    value: 'Future',
    title: 'Future',
  },
];

export const useEmployeeSetupEmploymentModalFields = (sourceId?: string, employment?: string) => {
  const fields = useMemo(() => ([
    ...sourceId === SourceIds.EEID
      ? [{
        name: FIELDS.PARTNER_EMPLOYEE_ID,
        type: FieldTypes.Text,
        label: 'Partner Employee ID',
        placeholder: 'Enter Partner Employee ID',
        validator: yup
          .string()
          .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
        defaultValue: '',
      }]
      : [],
    ...sourceId === SourceIds.PEID
      ? [{
        name: FIELDS.EMPLOYER_EMPLOYEE_ID,
        type: FieldTypes.Text,
        label: 'Employer Employee ID',
        placeholder: 'Enter Employer Employee ID',
        validator: yup
          .string()
          .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
        defaultValue: '',
      }]
      : [],
    ...sourceId === SourceIds.SSN
      ? [
        {
          name: FIELDS.EMPLOYER_EMPLOYEE_ID,
          type: FieldTypes.Text,
          label: 'Employer Employee ID',
          placeholder: 'Enter Employer Employee ID',
          validator: yup
            .string()
            .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
          defaultValue: '',
        },
        {
          name: FIELDS.PARTNER_EMPLOYEE_ID,
          type: FieldTypes.Text,
          label: 'Partner Employee ID',
          placeholder: 'Enter Partner Employee ID',
          validator: yup
            .string()
            .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
          defaultValue: '',
        },
      ]
      : [],
    {
      name: FIELDS.EMPLOYMENT_STATUS,
      type: FieldTypes.AutocompleteDropdown,
      showRequireIcon: true,
      label: 'Employment status',
      placeholder: 'Select employment status',
      validator: yup.string().required(REQUIRED_TEXT),
      options: EMPLOYMENT_TYPES,
      defaultValue: '',
      completeValue: employment,
    },
    {
      name: FIELDS.EMPLOYMENT_STATUS_EFFECTIVE_DATE,
      type: FieldTypes.Date,
      showRequireIcon: true,
      isManualDateInput: true,
      label: (
        <Box width={{ max: '230px' }}>
          <Text>
            Employment status effective date
          </Text>
        </Box>
      ),
      placeholder: 'Enter date',
      validator: VALIDATORS.DATE_FORMAT.required(REQUIRED_TEXT),
      defaultValue: '',
    },

  ]), [employment, sourceId]);

  return fields as Field[];
};
