import { useMemo } from 'react';
import { CardTypesEnum } from '@common-fe/common-fe';

import { usePlansStore } from '../ExpressSetupWizard/PlansWizard/Plans/stores';

export interface PlanError {
  type: CardTypesEnum;
  message: string;
}
const usePlansFormValidate = () => {
  const plansState = usePlansStore((state) => state.state);

  const plansErrors = useMemo(() => {
    const errors: PlanError[] = [];

    plansState?.planTypes?.forEach((type) => {
      const planExists = plansState?.plans?.some((plan) => plan.type === type);
      if (!planExists) {
        errors.push({ type, message: 'Choose any plan option or deselect this plan type.' });
      }
    });

    return errors;
  }, [plansState]);

  return plansErrors;
};

export default usePlansFormValidate;
