import React, { useMemo, useState } from 'react';
import {
  AppButton,
  Box,
  InteractiveCalendar,
  InteractiveListCalendar,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { RAW_DATE_FORMAT } from '@/common/constants';

import { ToggleOptions } from '../payrollCalendar.types';
import { usePayrollCalendarModalStore } from '../stores';
import { PayrollCalendarModalSteps } from '../stores/usePayrollCalendarModal.store';

import { usePayrollCalendarDates } from './hooks/usePayrollCalendarDates';
import { PayrollCalendarDatesHeader } from './PayrollCalendarDatesHeader';

const step = 6;
      
const PayrollCalendarDates = () => {
  const [toggleOption, setToggleOption] = useState(ToggleOptions.LIST);
  const {
    dates,
    onPickDate,
    onPickDates,
    page,
    setPage,
    initialMonthDate,
    minDate,
    maxStep,
    monthCount,
  } = usePayrollCalendarDates();
  const handleSetStep = usePayrollCalendarModalStore((state) => state.handleSetStep);
  const startDate = useMemo(
    () => dayjs(initialMonthDate).add((page * step), 'month').format(RAW_DATE_FORMAT),
    [initialMonthDate, page]
  );

  const monthRange: [string, string] = useMemo(() => {
    const start = dayjs(startDate).format('MMMM');
    const end = dayjs(startDate).add(5, 'month').format('MMMM');
    return [start, end];
  }, [startDate]);

  const maxDate = useMemo(() => {
    const computedMaxDate = dayjs(initialMonthDate).add(monthCount, 'month');
    if (dayjs(dates[dates.length - 1]).isAfter(computedMaxDate)) {
      return dayjs(dates[dates.length - 1]).format(RAW_DATE_FORMAT);
    }
    return computedMaxDate.format(RAW_DATE_FORMAT);
  }, [dates, initialMonthDate, monthCount]);

  return (
    <Box data-testid="PayrollCalendarDates_container">
      <Box
        round="container1Round"
        pad="spacing24"
        background="module"
      >
        <PayrollCalendarDatesHeader
          selectedOption={toggleOption}
          onSelectedOption={setToggleOption}
          onDatesPage={setPage}
          currentMonthPage={page}
          monthRange={monthRange}
          minDate={dayjs(minDate).format(RAW_DATE_FORMAT)}
          maxDate={maxDate}
          onPickDates={onPickDates}
          minPage={0}
          maxPage={maxStep}
        />

        {toggleOption === ToggleOptions.LIST ? (
          <InteractiveListCalendar
            onPickDate={onPickDate}
            dates={dates}
            minDate={dayjs(minDate)}
            testId="PayrollCalendarDates"
          />
        ) : (
          <InteractiveCalendar
            dates={dates}
            onPickDate={onPickDate}
            initialMonthDate={startDate}
            monthCount={step}
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate)}
            testId="PayrollCalendarDates"
          />
        )}
    
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
        gap="xs"
      >
        <Box direction="row" gap="xs">
          <AppButton
            testId="PayrollCalendarDates_cancel"
            buttonType="secondary"
            width="control"
            type="button"
            onClick={() => handleSetStep(PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_PARAMS)}
          >
            Back
          </AppButton>
          <AppButton
            testId="PayrollCalendarDates_submit"
            type="button"
            onClick={() => handleSetStep(PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_USE)}
            width="control"
          >
            Next (2/3)
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
      
export default PayrollCalendarDates;
      