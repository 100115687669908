import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppButton,
  Box,
  CIPStatus,
  Heading,
  PencilIcon,
} from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useHasAccess } from '@/modules/core/hooks';
import { useEnrollmentsData } from '@/modules/employee/Employee/hooks/useEnrollmentsData';
import { useGetEnrollments } from '@/modules/employee/Employee/hooks/useGetEnrollment';
import AccountDetails from '@/modules/employee/EnrollmentDetails/AccountDetails';
import ActivitiesList from '@/modules/employee/EnrollmentDetails/ActivitiesList';
import DisclosureBankMessage from '@/modules/employee/EnrollmentDetails/DisclosureBankMessage';
// import DisclosureButton from '@/modules/employee/EnrollmentDetails/DisclosureButton';
import { Wrapper } from '@/modules/employee/EnrollmentDetails/MyAccounts.styles';
import useGetPolicyStatusesQuery from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';

import { AddEnrollmentModal } from '../Employee/AddEnrollmentModal/AddEnrollmentModal';
import { EmployeeCIPVerificationBanner } from '../Employee/cip';
import useGetEmployeeByIdQuery from '../Employee/queries/useGetEmployeeById.query';

import { EnrollmentDetailsAccountInfo } from './EnrollmentDetailsAccountInfo/EnrollmentDetailsAccountInfo';
import { EnrollmentDetailsToggler } from './EnrollmentDetailsToggler';

const enum EnrollmentDetailsToggleOptions {
  ACTIVITIES = 'Activity & Processing',
  ACCOUNT_INFO = 'Account Information',
}

const TOGGLE_OPTIONS = [
  EnrollmentDetailsToggleOptions.ACTIVITIES,
  EnrollmentDetailsToggleOptions.ACCOUNT_INFO,
];

export const EnrollmentDetails: React.FC = () => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(EnrollmentDetailsToggleOptions.ACTIVITIES);
  const { id, enrollmentId } = useParams<{ id: string, enrollmentId: string }>();
  const { currentMyAccount, isCurrentHSA, hasEnrollmentWithDisplayName } = useGetEnrollments({
    accountId: enrollmentId,
    employeeId: id,
  });
  const { investorsData } = useEnrollmentsData(id);
  const { statuses } = useGetPolicyStatusesQuery(currentMyAccount?.policyId);
  const isNotVerified = useMemo(
    () => (currentMyAccount?.CIPStatus === CIPStatus.Fail
    || currentMyAccount?.CIPStatus === CIPStatus.Question) && currentMyAccount?.isCIPRequired,
    [currentMyAccount],
  );

  const lastCoverageEndDate = useMemo(() => {
    const coveragePeriods = currentMyAccount?.coveragePeriods;
    if (coveragePeriods?.length) {
      return coveragePeriods?.[0]?.endDate;
    }
    return null;
  }, [currentMyAccount?.coveragePeriods]);

  const isDisplayedCIPBanner = useMemo(
    () => currentMyAccount?.isCIPRequired,
    [currentMyAccount?.isCIPRequired]);
  const {
    data,
  } = useGetEmployeeByIdQuery(id);

  const isToggleAvailable = useHasAccess([
    { role: Roles.superUser },
    { role: Roles.admin3 },
  ]);

  useBreadcrumbs([
    {
      route: ROUTES.EMPLOYEES,
      title: '...',
    },
    {
      route: `${ROUTES.EMPLOYEE}/${id}`,
      title: data?.fullName || '',
    },
    {
      route: ROUTES.ENROLLMENT_DETAILS(id, enrollmentId),
      title: currentMyAccount?.name || '',
    },
  ], [
    data?.fullName || '',
    id,
    currentMyAccount?.name,
  ], {
    backBtn: true,
  });
  return (
    <>
      <Wrapper data-testid="MyAccounts-wrapper">
        {currentMyAccount && (
          <Box margin={{ bottom: 'spacing24' }}>
            <Box direction="row" align="center" justify="between">
              <Heading color="textTitle" level={1} size="regular">
                {currentMyAccount.name}
              </Heading>
              <AppButton
                buttonType="secondary"
                color="grey"
                rightIcon={<PencilIcon />}
                testId="EditEnrollmentModal-open-modal"
                onClick={() => setEditModalVisible(true)}
              >
                Edit Enrollment
              </AppButton>
            </Box>
            <AccountDetails
              id={enrollmentId}
              currentMyAccount={currentMyAccount}
              investorsData={investorsData}
              hasEnrollmentWithDisplayName={hasEnrollmentWithDisplayName}
            />
          </Box>
        )}
        {isDisplayedCIPBanner && (
          <EmployeeCIPVerificationBanner isStandaloneMode employeeId={id} />
        )}
        

        {isToggleAvailable && (
          <EnrollmentDetailsToggler
            toggleOptions={TOGGLE_OPTIONS}
            selectedOption={selectedOption}
            setSelectedOption={(value: string) => setSelectedOption(value as EnrollmentDetailsToggleOptions)}
          />
        )}
        {selectedOption === EnrollmentDetailsToggleOptions.ACTIVITIES
        && (
          <ActivitiesList disabled={isNotVerified} key={enrollmentId} id={enrollmentId} title="Activity" />
        )}
        {selectedOption === EnrollmentDetailsToggleOptions.ACCOUNT_INFO
        && (
          <EnrollmentDetailsAccountInfo
            id={enrollmentId}
            currentMyAccount={currentMyAccount}
            investorsData={investorsData}
          />
        )}
        <DisclosureBankMessage isCurrentHSA={isCurrentHSA} />
      </Wrapper>
      <AddEnrollmentModal
        isWithoutButton
        visible={editModalVisible}
        onSetVisible={setEditModalVisible}
        isEdit
        planId={currentMyAccount?.planId}
        perPayEmployee={currentMyAccount?.perPayEmployee}
        perPayEmployer={currentMyAccount?.perPayEmployer}
        enrollmentStatus={currentMyAccount?.enrollmentStatus}
        employeeName={data?.fullName || ''}
        enrollmentElection={currentMyAccount?.election}
        lastCoverageEndDate={lastCoverageEndDate}
        statuses={statuses}
      />
    </>
  );
};
