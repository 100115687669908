import React, { useCallback } from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';

import useBankAccountStore, { BankAccountPayload } from './stores/useBankAccount.store';
import { useBankAccountFields } from './hooks';

export interface Props {
  isEditMode?: boolean;
  isBankExist?: boolean;
}

const BankAccountForm: React.FC<Props> = ({ isEditMode, isBankExist }) => {
  const handleSetState = useBankAccountStore((state) => state.setState);
  const showErrors = useBankAccountStore((state) => state.showErrors);

  const fields = useBankAccountFields(isBankExist);

  const handleChangeValues = useCallback((values: Partial<BankAccountPayload>) => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState]);

  return (
    <Box
      data-testId="bank-account-form"
      background="canvas"
      round="container1Round"
    >
      <FlexControlledForm
        fields={fields}
        editMode={isEditMode}
        shouldControlValues
        isModalType
        isThinMode
        showError={showErrors}
        onChangeValues={handleChangeValues}
      />
    </Box>
  );
};

export default BankAccountForm;
