import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box, ElevateIcon, FilterIcon, 
  // ThreeDotIcon,
  GenerateIcon, OutlinedCompleteIcon,
  Preloader,
  Text, Tip, useClickOutside, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DATETIME_DOTS_FORMAT,DEFAULT_DATE_FORMAT, SECOND } from '@/common/constants';
import Permissions from '@/common/permissions.ts';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';
import { ReportItemInfoTable } from '@/modules/employer/components/Reports/components/ReportSection/ReportItemInfoTable';
import { Report, ReportTypes } from '@/modules/employer/components/Reports/report.types';
import downloadFileByUrl from '@/utils/handlers/downloadFileByUrl';

import { useDownloadReportQuery } from '../../queries/useDownloadReport.query';

import { useReportFilterPopupStore } from './ReportFilterPopup.tsx/store/useReportFilterPopup.store';
import { ReportFilterPopup } from './ReportFilterPopup.tsx';
import {
  ExpandButton,
  FilterButton,
  GenerateReportButton,
  LoadingBox,
  StyledBox,
  // StyledAnchor,
} from './ReportListItem.styles';

const PointeredText = styled(Text)`
  cursor: pointer;
`;

const FIFTEEN_SECONDS = 15 * SECOND;

export enum GenerateButtonTitle {
  Generate = 'Generate Report',
  Generated = 'Report Generated',
  Processing = 'Processing...',
}

interface Props {
  report: Report;
}

export const ReportListItem: React.FC<Props> = ({ report }) => {
  const {
    observingOrganization: { id: currentOrganizationId = '' },
  } = useCurrentOrganization();
  const currentRef = useRef(null);
  const timerId = useRef<NodeJS.Timeout>();
  const [expand, setExpand] = useState(false);
  const [isExpandFilter, setExpandFilter] = useState(false);
  const [isMockedLoading, setIsMockedLoading] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isStartDownload, setIsStartDownload] = useState(false);
  const [currentReportType, setCurrentReportType] = useState<ReportTypes | null>(null);
  const [buttonTitle, setButtonTitle] = useState(GenerateButtonTitle.Generate);
  const handleReportVisible = useReportFilterPopupStore((state) => state.handleSetCurrentPopup);
  const currentReportFilter = useReportFilterPopupStore((state) => state.currentPopup);
  const { data, fileName, remove } = useDownloadReportQuery(
    currentOrganizationId,
    isStartDownload,
    currentReportType,
  );
  const isFullWidth = useMemo(() => Boolean(report.isFullWidth), [report.isFullWidth]);
  const isFilterVisible = useMemo(
    () => currentReportFilter === report.reportType,
    [currentReportFilter, report.reportType],
  );

  const startReportDate = useMemo(
    () => dayjs(report.startReportDate).format(DEFAULT_DATE_FORMAT),
    [report],
  );
  const endReportDate = useMemo(
    () => dayjs(report.endReportDate).format(DEFAULT_DATE_FORMAT),
    [report],
  );
  const isHasReportDate = useMemo(
    () => !!(report.startReportDate && report.endReportDate),
    [report],
  );

  const handleClose = useCallback(() => {
    setTimeout(() => setExpand(false), 500);
    setExpandFilter(false);
    if (isFilterVisible) {
      handleReportVisible(undefined);
    }
  }, [handleReportVisible, isFilterVisible]);

  useClickOutside(currentRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (data && isStartDownload) {
      const currentDate = dayjs(Date.now()).format(DATETIME_DOTS_FORMAT);
      const fileExtension = 'csv';
      const currentFileName = `${fileName}_${currentDate}.${fileExtension}`;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      downloadFileByUrl([url], currentFileName);
      setIsFileLoaded(true);
      setIsStartDownload(false);
      setCurrentReportType(null);
      remove();
    }
  }, [data, isStartDownload, remove, report, fileName]);

  useEffect(() => {
    if (data && isStartDownload) {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = undefined;
      }

      timerId.current = setTimeout(() => {
        setIsFileLoaded(false);
      }, FIFTEEN_SECONDS);
    }
  }, [data, isStartDownload, isFileLoaded]);

  useEffect(() => () => {
    if (timerId.current) clearTimeout(timerId.current);
  }, []);

  useEffect(() => () => {
    setIsFileLoaded(false);
  }, []);

  const handleDownloadReport = useCallback(() => {
    if (report.reportType) {
      setCurrentReportType(report.reportType);
      setIsStartDownload(true);
    } else {
      setIsMockedLoading(true);
      setTimeout(() => {
        downloadFileByUrl([report.generateReportURL]);
        setIsMockedLoading(false);
        setIsFileLoaded(true);
      }, 1000);
    }
  }, [report]);

  const handleExpand = useCallback(() => {
    if (expand) {
      handleClose();
    } else {
      handleReportVisible(report.reportType);
      setExpand(true);
    }
  }, [expand, report, handleReportVisible, handleClose]);

  const generateReportIcon = useMemo(() => {
    if (isStartDownload || isMockedLoading) {
      setButtonTitle(GenerateButtonTitle.Processing);
      return (
        <LoadingBox>
          <Preloader size={18} />
        </LoadingBox>
      );
    }
    if (isFileLoaded) {
      setButtonTitle(GenerateButtonTitle.Generated);
      return (<OutlinedCompleteIcon />);
    }

    setButtonTitle(GenerateButtonTitle.Generate);
    
    return <GenerateIcon size="medium" color="iconAccent" />;
  }, [isFileLoaded, isMockedLoading, isStartDownload]);
  const isHasPermissionToGenerateReports = useHasAccess([
    {
      permission:Permissions.REPORTS
    }
  ]);
  
  return (
    <Box
      ref={currentRef}
      width={isFullWidth ? '100%' : { max: '512px', min: '512px' }}
      margin="spacing8"
    >
      <Box
        data-testid="report_list_item-container"
        height={
          expand ? {
            max: '380px',
            min: '214px',
          } : {}
        }
        style={{
          position: 'relative',
        }}
      >
        <StyledBox
          isFullWidth={isFullWidth}
          key={report.id}
          pad="spacing16"
          background="canvas"
          round="container1Round"
          expand={expand}
          border={expand && report.isWithFilter ? { color: 'border', size: '2px' } : undefined}
        >
          <Box background="canvas" width="100%">
            <Box direction="row" justify="between" align="center" margin={{ bottom: 'spacing8' }}>
              <Box style={{ flex: 1 }}>
                <Text
                  weight="bold"
                  size="xxlarge"
                  color="textBody"
                >
                  {report.title}
                </Text>
              </Box>
              <Box align="center" direction="row">
                { isHasPermissionToGenerateReports && (
                  <GenerateReportButton
                    data-testid="report_list_item-generate_report_button"
                    direction="row"
                    align="center"
                    height={{ max: '28px' }}
                    // margin={{ right: report.isWithFilter ? 'spacing4' : '22px' }}
                    round="button2Round"
                    pad={{ vertical: 'spacing4', horizontal: 'spacing6' }}
                    onClick={handleDownloadReport}
                  >

                    <Text color="textAccent" margin={{ right: 'spacing12' }}>{buttonTitle}</Text>
                    <Box>
                      {generateReportIcon}

                    </Box>

                  </GenerateReportButton>
                )}
                
                {report.isWithFilter
                  && (
                    <FilterButton
                      data-testId="filter-button"
                      round="button2Round"
                      justify="center"
                      align="center"
                      active={isFilterVisible && isExpandFilter}
                      onClick={() => {
                        if (isFilterVisible && isExpandFilter) {
                          handleClose();
                          return;
                        }
                        handleReportVisible(report.reportType);
                        setExpand(true);
                        setExpandFilter(true);
                      }}
                    >
                      <FilterIcon color="iconPrimary" />
                    </FilterButton>
                  )}
                {/* : (
                    <Box margin={{ top: '4px', right: '10px' }} width={{ max: '4px' }}>
                      <ThreeDotIcon />
                    </Box>
                  )} */}
              </Box>
            </Box>

            {/* <Box direction="row" align="center" margin={{ bottom: 'spacing8' }}> */}
            {/*   <ReloadIcon /> */}
            {/*   <Text margin={{ left: 'spacing4' }} color="textSecondary">30 Oct 2021</Text> */}
            {/* </Box> */}
            {
              isHasReportDate
            && (
              <Box
                direction="row"
                align="center"
                margin={{ bottom: isFullWidth ? 'spacing8' : 'spacing16' }}
              >
                <Tip
                  plain
                  content={(
                    <Box
                      style={{
                        display: 'inline-block',
                        width: 'auto',
                      }}
                      background="background3"
                      border={{ size: 'small', color: 'border' }}
                      pad={{ horizontal: 'spacing6', bottom: 'spacing2' }}
                    >
                      <Text size="xsmall" color="textOnColor">The date range that the report applies for </Text>
                    </Box>
                  )}
                >
                  <PointeredText margin={{ left: 'spacing4' }} color="textSecondary">
                    {startReportDate} - {endReportDate}
                  </PointeredText>
                </Tip>
              </Box>
            )
            }
            {report.isLoading ? (
              <Box
                direction="row"
                justify="center"
                align="center"
                pad={{ vertical: 'spacing8' }}
              >
                <Preloader color="iconAccent" />
              </Box>
            ) : (
              <ReportItemInfoTable
                isFullWidth={isFullWidth}
                expand={expand}
                report={report}
              />
            )}

            <Box
              direction="row"
              justify="center"
              align="center"
              pad={{ top: 'spacing8' }}
              border={{ color: 'border1', size: 'small', side: 'top' }}
            >
              <ExpandButton
                data-testid="report_list_item-expand_button"
                onClick={handleExpand}
                flex="grow"
                pad="spacing6"
              >
                <Text
                  textAlign="center"
                  color="textAccent"
                >
                  {expand ? 'Hide Details' : 'Show Details'}
                </Text>
              </ExpandButton>
              {/* {
              (!isFullWidth && !showHideButtonCenter) && (
                <Box direction="row" align="center">
                  <Box pad={{ horizontal: 'xsmall' }}>
                    <CalendarIcon color="iconSecondary" />
                  </Box>
                  <Box pad={{ horizontal: 'xsmall' }}>
                    <MailMessageIcon size="medium" color="iconSecondary" />
                  </Box>
                  <Box pad={{ horizontal: 'xsmall' }}>
                    <UploadIcon color="iconSecondary" />
                  </Box>
                </Box>
              )
            } */}
            </Box>
          </Box>
        </StyledBox>
      </Box>
      {report.isWithFilter && (
        <ReportFilterPopup
          reportType={report.reportType}
          onVisible={() => setExpand(false)}
        />
      )}
     
    </Box>
  );
};
