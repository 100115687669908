import { storeCreator } from '@/utils/store/store.utils';

interface State {
  mountedId: string;
  setMountedId: (mountedId: string) => void;
  resetMountedId: () => void;
}

const useStore = storeCreator<State>((set) => ({
  mountedId: '',
  setMountedId: (mountedId: string) => set(() => ({ mountedId })),
  resetMountedId: () => set(() => ({ mountedId: '' })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
