import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppButton, Box, Inscription, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useParseEmailTemplate } from './hooks/useParseEmailTemplate';

const OffsetPercent = 0.15;

interface StyledProps {
  expanded?: boolean;
  maxHeight?: string;
}

const BoxWithShadow = styled(Box)<StyledProps>`
  position: relative;
  max-height: ${({ expanded, maxHeight }) => (expanded ? maxHeight : '134px')};
    &:after {
    content: '';
    transition: 0.2s;
    position: absolute;
    display: block;
    height: ${({ expanded }) => (expanded ? '0px' : '40px')};
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
  }
`;

interface Props {
  templateId?: string;
}

export const EditEmailTemplatePreview: React.FC<Props> = ({
  templateId,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [maxHeight, setMaxHeight] = useState('auto');
  const contentRef = useRef<HTMLDivElement | null>(null);

  const {
    preparedTemplate,
    isLoading,
  } = useParseEmailTemplate(templateId, id);

  const togglePreview = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current?.scrollHeight;
      setMaxHeight(`${height - (height * OffsetPercent)}px`);
    }
  }, [isLoading, preparedTemplate]);

  return (
    <Box
      data-testid="EditEmailTemplatePreview_container"
      round="container1Round"
      background="canvas"
      border={{ color: 'border2' }}
      pad="spacing24"
    >
      <Box
        direction="row"
        justify="between"
        height="40px"
        margin={{ bottom: 'spacing16' }}
      >
        <Inscription size="20px" lineHeight="28px">
          Preview
        </Inscription>

        <Box width="170px">
          <AppButton
            testId="EditEmailTemplatePreview_expand"
            buttonType="secondary"
            width="control"
            type="button"
            onClick={togglePreview}
          >
            {expanded ? 'Hide Full Preview' : 'Show Full Preview'}
          </AppButton>
        </Box>

      </Box>

      <BoxWithShadow
        data-testid="EditEmailTemplatePreview_content"
        pad={{ horizontal: 'spacing24' }}
        background={{ color: 'border1' }}
        expanded={expanded}
        maxHeight={maxHeight}
        style={{
          overflow: expanded ? 'auto' : 'hidden',
        }}
      > 
        {isLoading ? (
          <Box pad="spacing24">
            <Preloader />
          </Box>
        ) : (
          <Box
            align="center"
            ref={contentRef}
            pad={{ top: 'spacing24' }}
            style={{
              transform: 'scale(0.80)',
              transformOrigin: 'top center',
            }}
          > 
            {preparedTemplate}
          </Box>
        )}
      </BoxWithShadow>

    </Box>
  );
};
