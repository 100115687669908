import { useMemo } from 'react';
import _ from 'lodash';

import { useAccountUsageStore } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/stores';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';

export const BLOCKS_VALIDATION_TEXT = {
  CONTACTS_AND_SECURITY: 'Contacts/Security',
  FINANCIAL_DETAILS: 'Financial Details',
  // STATUS: 'Status',
};

export default () => {
  const { hasOneContact } = useSetupEmployerStore();
  const { formattedData } = useGetEmployer();
  const { findUnselectedMethods } = useAccountUsageStore();
  const unselectedMethods = useMemo(() => findUnselectedMethods({
    administratorFeesMethod: formattedData?.overridableProperties?.externalAdministratorFeesAccountMethod,
    payrollContributionMethod: formattedData?.overridableProperties?.externalPayrollContributionsAccountMethod,
    fundingReplenishmentMethod: formattedData?.overridableProperties?.externalReplenishmentAccountMethod,
  }), [formattedData, findUnselectedMethods]);
  const errors = useMemo(() => {
    const currentErrors: string[] = [];
    if (!hasOneContact) {
      currentErrors.push(BLOCKS_VALIDATION_TEXT.CONTACTS_AND_SECURITY);
    }
    if (!_.isEmpty(unselectedMethods)) {
      currentErrors.push(BLOCKS_VALIDATION_TEXT.FINANCIAL_DETAILS);
    }
    // if (_.isEmpty(banksList)) {
    //   currentErrors.push(BLOCKS_VALIDATION_TEXT.FINANCIAL_DETAILS);
    // }
    return currentErrors;
  }, [hasOneContact, unselectedMethods]);
  return errors;
};
