import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

export const QUERY_KEY = 'GET_MOBILE_APP_THEMES';

interface ThemePayload {
  id: number;
  name: string;
  main_color: string;
  color_schema_link: string;
  logotype_link: string;
  organization_path: string;
  organization_id: number;
}

export interface Theme {
  id: string;
  name: string;
  mainColor: string;
  colorSchemaLink: string;
  logotypeLink: string;
  organizationPath: string;
  organizationId: number;
}

export interface ThemesListItem {
  key: string;
  title: string;
  color: string;
}

export const useMobileThemesQuery = () => {
  const { data, isLoading, isSuccess } = useQuery([QUERY_KEY], () => api.get<ThemePayload[]>(PATHS.MOBILE_APP_THEMES));

  const formattedList = useMemo<Array<ThemesListItem>>(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      title: item.name,
      color: item.main_color ? `${item.main_color}` : '',
    }));
  }, [data]);

  const formattedThemes = useMemo<Array<Theme>>(() => {
    const themes = data?.data || [];

    return themes.map((theme: ThemePayload) => ({
      id: `${theme?.id}`,
      name: theme?.name,
      mainColor: theme?.main_color,
      colorSchemaLink: theme?.color_schema_link,
      logotypeLink: theme?.logotype_link,
      organizationPath: theme?.organization_path,
      organizationId: theme?.organization_id,
    }));
  }, [data]);

  return {
    formattedThemes,
    formattedList,
    isLoading,
    isSuccess,
  };
};
