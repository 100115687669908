import { baseStoreCreator } from '@/utils/store';

export interface PlanNamePayload {
  planName: string;
  planTemplate: string[];

}

export const DEFAULT_VALUES: PlanNamePayload = {
  planName: '',
  planTemplate: [],
};

export const useStore = baseStoreCreator<PlanNamePayload>(DEFAULT_VALUES);

export default useStore;
