import React from 'react';
import {
  AppButton,
  Box,   FlexList, Modal, PaginationBar, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ListItemBase } from '@/common/types';
import { useListPagination } from '@/modules/employer/hooks';

import ErrorSnack from '../components/ErrorSnack';

import useFileErrorsList, { fieldStyle } from './hooks/useFileErrorsList';
import ExportErrorsButton from './ExportErrorsButton';

const HEADERS: ListItemBase[] = [
  {
    key: 'errorLineNumber',
    title: 'File Line #',
    style: fieldStyle,
  },
  {
    key: 'errorFieldName',
    title: 'Field Name',
    style: fieldStyle,
  },
  {
    key: 'errorPartnerId',
    title: 'Partner ID',
    style: fieldStyle,
  },
  {
    key: 'errorEmployerId',
    title: 'Employer ID',
    style: fieldStyle,
  },
  {
    key: 'errorLastName',
    title: 'Last Name',
    style: fieldStyle,
  },
  {
    key: 'errorFirstName',
    title: 'First Name',
    style: fieldStyle,
  },
  {
    key: 'errorEmployeeId',
    title: 'Employee ID',
    style: fieldStyle,
  },
  {
    key: 'errorCode',
    title: 'Error Code',
    style: fieldStyle,
  },
  {
    key: 'errorMessage',
    title: 'Error Message',
  },
];

interface Props {
  fileId: string;
  hide: () => void;
  totalErrors: number;
  fileName: string;
}

const AutoHeightBox = styled(Box)`
  min-height: auto;
`;

const CloseButton = styled(AppButton)`
  min-width: 150px;
`;

const DEFAULT_PAGE_SIZE = 50;

const FileReportPopup: React.FC<Props> = ({
  fileId, hide, totalErrors, fileName,
}) => {
  const {
    page,
    perPage,
    setPerPage,
    setPage,
  } = useListPagination(DEFAULT_PAGE_SIZE);
  const { data, total, isLoading } = useFileErrorsList({
    fileId,
    page: page - 1,
    perPage,
    sort: 'fileRecord.lineNumber',
  });

  return (
    <Modal
      visible
      onSetVisible={hide}
      maxWidth="960px"
      maxHeight="100%"
    >
      <Box direction="column">

        <AutoHeightBox direction="row" align="center" justify="center" pad={{ bottom: 'spacing12' }}>
          <Text size="2xl" color="textTitle" weight="bold">Error report ({total})</Text>
        </AutoHeightBox>

        <AutoHeightBox margin={{ bottom: 'spacing12' }}>
          <ErrorSnack />
        </AutoHeightBox>

        <Box>
          <FlexList
            testId="file_errors_list"
            headers={HEADERS}
            rows={data || []}
            total={total}
            moreCount={perPage}
            loading={isLoading}
            scrollMode
            footer={(
              <PaginationBar
                page={page}
                total={totalErrors}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          />
        </Box>

        <AutoHeightBox direction="row" justify="between" align="center" margin={{ top: 'spacing24' }} height="40px">
          <ExportErrorsButton fileId={fileId} fileName={fileName} />
          <CloseButton
            testId="home_setup-employer"
            type="button"
            buttonType="secondary"
            onClick={hide}
          >
            Close
          </CloseButton>
        </AutoHeightBox>

      </Box>
    </Modal>
  );
};

export default FileReportPopup;
