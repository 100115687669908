import React, { useMemo } from 'react';
import { Box, ErrorMessage, Inscription } from '@common-fe/common-fe';

import { Lockbox, LockboxValidationError, lockboxValidationErrorTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import LockboxInfoContainer from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoContainer';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

import EmployeesSearch from './EmployeesSearch';
import SuggestedEmployees from './SuggestedEmployees';

interface Props {
  lockbox?: Lockbox;
}

const LockboxEmployees: React.FC<Props> = ({ lockbox }) => {
  const { validationErrors } = useLockboxDetailsStore();
  const matchedEmployees = lockbox?.matchEmployees;

  const errorType = useMemo(() => {
    return validationErrors?.find((error) => error === LockboxValidationError.EMPLOYEE_REQUIRED
    || error === LockboxValidationError.HSA_ACCOUNT_REQUIRED
    || error === LockboxValidationError.HSA_ACCOUNT_CLOSED) as LockboxValidationError | undefined;
  }, [validationErrors]);

  return (
    <LockboxInfoContainer data-testId="lockbox-employees" margin={{ top: 'spacing8' }}>
      <Box direction="row" align="center">
        <Inscription color="textSecondary">Employees</Inscription>
        {errorType && (
          <Box margin={{ left: 'spacing12' }}>
            <ErrorMessage errorText={lockboxValidationErrorTitle?.[errorType]} />
          </Box>
        )}
      </Box>

      <Box
        background="module"
        round="container1Round"
        margin={{ top: 'spacing12' }}
        pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
      >
        <SuggestedEmployees
          matchedEmployees={matchedEmployees}
        />
        <Box margin={{ top: 'spacing24' }}>
          <EmployeesSearch />
        </Box>
      </Box>
    </LockboxInfoContainer>
  );
};

export default LockboxEmployees;
