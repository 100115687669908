import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  AppButton, Box, FlexControlledForm,
  Modal, Preloader,
  Text, WarnModal, 
} from '@common-fe/common-fe';
import _ from 'lodash';

import { SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE } from '@/common/constants';
import { BASED_ON_NONE_KEY } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { useProcessingRules } from '@/modules/ProcessingDefinition/ProcessingRule/hooks';

import AddAnotherTier from './AddAnotherTier';
import { MainInfo } from './CreateProcessingRule.types';
import { useCreateProcessingRule, useProcessingRuleMainFields, useTiers } from './hooks';
import TierItem from './TierItem';

const CONFIRM_TEXT = 'Changes that you made may not be saved.';

interface Props {
  updatedElement?: ProcessingRuleItem;
  visible?: boolean;
  onToggleVision?: (value: boolean) => void;
  onUpdatedElement?: (value?: ProcessingRuleItem) => void;
}

const CreateProcessingRuleModal: React.FC<Props> = ({
  updatedElement,
  visible,
  onToggleVision,
  onUpdatedElement,
}) => {
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);

  const [state, setState] = useState<MainInfo>({
    name: '',
    basedOn: BASED_ON_NONE_KEY,
  });
  const [errorName, setErrorName] = useState<string>('');
  const [allowedResetTriggers, setAllowedResetTriggers] = useState<boolean>();
  const { data, list: processingRulesList } = useProcessingRules({
    perPage: 100,
    page: 0,
  });
  const fields = useProcessingRuleMainFields(state, updatedElement, {
    name: errorName || '',
  },
  processingRulesList);

  const {
    fieldsValues: tiersValues, getFieldValuesById,
    handleChangeValues, handleRemoveById,
    handleAddNewTier, 
  } = useTiers({
    visible,
    data,
    state,
    allowedResetTriggers,
    updatedElement,
  });

  const handleClear = useCallback(() => {
    if (onUpdatedElement) onUpdatedElement(undefined);
    setAllowedResetTriggers(undefined);
    // setShowWarning(false);
    setErrorName('');
    setState({
      name: '',
      basedOn: BASED_ON_NONE_KEY,
    });
  }, [onUpdatedElement]);

  const handleCloseModal = useCallback((value: boolean) => {
    if (onToggleVision) onToggleVision(value);
    handleClear();
  }, [onToggleVision, handleClear]);

  const onCloseModalHandler = useCallback((isConfirmMode?: boolean) => {
    if (isConfirmMode && shouldConfirmOnClose) {
      return window.confirm(CONFIRM_TEXT) && handleCloseModal(false);
    }
    return handleCloseModal(false);
  }, [shouldConfirmOnClose, handleCloseModal]);

  const {
    isSubmitted,
    handleSubmit,
    isProcessingRuleNameError,
    setServerError,
    isServerError,
    isLoading,
  } = useCreateProcessingRule({
    mainValue: state,
    visible,
    tiers: tiersValues,
    onClose: handleCloseModal,
    updatedElement: updatedElement,
  });

  useEffect(() => {
    if (isProcessingRuleNameError) {
      setErrorName(state.name);
    } else {
      setErrorName('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessingRuleNameError]);

  return (
    <>
      <Modal
        visible={!!visible}
        onSetVisible={() => onCloseModalHandler(true)}
      >
        <Box direction="column">
          <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
            <Text size="2xl" color="textTitle" weight="bold">{updatedElement ? 'Edit a Processing Rule' : 'Create a Processing Rule'}</Text>
          </Box>
          <Box>
            <Box pad="l" background="module" round="moduleRound">
              <Box
                background="canvas"
                border={{ size: 'small', color: 'border2' }}
                pad={{ vertical: 'l' }}
                elevation="default"
                margin={{ bottom: 'l' }}
                round="container1Round"
              >
                <FlexControlledForm
                  isModalType
                  fields={fields}
                  editMode
                  onDirty={setShouldConfirmOnClose}
                  onChangeValues={setState}
                  showError={isSubmitted}
                  wrapperStyle={{ border: 'none' }}
                  formStyle={{ marginBottom: 0, marginTop: 0 }}
                />
              </Box>
              {
                tiersValues.map((item, index) => (
                  <TierItem
                    id={item.id}
                    isRemoveMode={tiersValues.length > 1}
                    index={index}
                    isErrorShowed={isSubmitted}
                    isLastItem={_.last(tiersValues) === item}
                    onRemove={handleRemoveById}
                    key={item.id}
                    defaultValues={getFieldValuesById(item.id)}
                    onChangeValues={handleChangeValues(item.id)}
                    // handleClearValues={handleClearValues}
                    tiersValues={tiersValues}
                    setShouldConfirmOnClose={setShouldConfirmOnClose}
                  />
                ))
              }
              <AddAnotherTier onClick={handleAddNewTier} />
            </Box>
            <Box
              direction="row"
              align="center"
              justify="end"
              pad={{ top: 'l' }}
            >
              <AppButton buttonType="secondary" width="control" onClick={() => onCloseModalHandler(true)}>Cancel</AppButton>
              <Box margin={{ left: 's' }}>
                <AppButton width="control" onClick={handleSubmit} disabled={isLoading}>
                  {
                    isLoading
                      ? <Preloader color="white" />
                      : <Text>{updatedElement ? 'Save' : 'Create'}</Text>
                  }
                </AppButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <WarnModal
        visible={isServerError}
        onSetVisible={() => {
          setServerError(false);
        }}
        header={SERVER_ERROR_MODAL_TITLE}
        helptext={SERVER_ERROR_MODAL_TEXT}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try Again"
      />
    </>
  );
};

export default CreateProcessingRuleModal;
