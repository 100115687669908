export default (urls: string[], fileName?: string) => {
  for (let i = 0; i < urls.length; i += 1) {
    const possibleFileName = urls[i].split('/').splice(-1).join();
    const link = document.createElement('a');
    link.href = urls[i];
    link.setAttribute('download', fileName || possibleFileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
};
