import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton, Box, Preloader } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { LockboxStatus } from '@/modules/employer/components/Lockbox/lockbox.types';
import useValidateLockbox from '@/modules/employer/components/Lockbox/LockboxDetails/hooks/useValidateLockbox';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import useUpdateLockboxQuery from '@/modules/employer/components/Lockbox/queries/useUpdateLockbox.query';
import theme from '@/styles/theme';

const BUTTON_WIDTH = '180px';

const LockboxInfoFooterWrapper = styled(Box)`
  position: sticky;
  margin-top: auto;
  bottom: 0;
`;

const LockboxInfoFooter = () => {
  const history = useHistory();
  const [hasFieldsValidation, setHasFieldsValidation] = useState(false);
  const {
    editableLockbox,
    originalLockbox,
    validationErrors,
    nextLockboxId,
    setNextLockboxId,
    setValidationErrors
  } = useLockboxDetailsStore();
  const { updateLockbox, isLoading } = useUpdateLockboxQuery({ lockboxId: originalLockbox?.id });
  const { validateLockboxStatus, validateLockboxFields } = useValidateLockbox();

  const isStatusValidationSuccessfully = useMemo(() => {
    return validateLockboxStatus(editableLockbox)?.length === 0;
  }, [editableLockbox, validateLockboxStatus]);
  
  const isFieldsValidationSuccessfully = useMemo(() => {
    return validationErrors?.length === 0;
  }, [validationErrors]);

  useEffect(() => {
    if (hasFieldsValidation) {
      setValidationErrors(validateLockboxFields(editableLockbox));
    }
  }, [hasFieldsValidation, editableLockbox, validateLockboxFields, setValidationErrors]);

  const isAbleToEdit = useMemo(() => originalLockbox
    && (originalLockbox?.lockboxStatus === LockboxStatus.NEW
    || originalLockbox?.lockboxStatus === LockboxStatus.PENDING),
  [originalLockbox]);

  const buttonColor = useMemo(() => {
    switch (editableLockbox?.lockboxStatus) {
    case LockboxStatus.APPROVED: {
      return theme.colors.success;
    }
    case LockboxStatus.DECLINED: {
      return theme.colors.danger;
    }
    case LockboxStatus.PENDING: {
      return theme.colors.warning;
    }
    default: {
      return theme.colors.button;
    }
    }
  }, [editableLockbox]);

  const buttonTitle = useMemo(() => {
    if (!isStatusValidationSuccessfully) {
      return 'Select an option';
    }

    switch (editableLockbox?.lockboxStatus) {
    case LockboxStatus.APPROVED: {
      return 'Approve';
    }
    case LockboxStatus.DECLINED: {
      return 'Decline';
    }
    case LockboxStatus.PENDING: {
      return 'Pend';
    }
    }
  }, [editableLockbox, isStatusValidationSuccessfully]);

  const handleUpdateLockbox = useCallback(() => {
    const fieldsErrors = validateLockboxFields(editableLockbox);

    if (fieldsErrors?.length === 0) {
      const updateLockboxData = {
        lockbox_type: editableLockbox?.lockboxType,
        amount: editableLockbox?.amount,
        employee_id: editableLockbox?.employeeId,
        account_id: editableLockbox?.accountId,
        lockbox_status: editableLockbox?.lockboxStatus,
        vendor_id: editableLockbox?.vendorId,
        reason: editableLockbox?.reason,
        notes: editableLockbox?.notes,
      };
      const cleanedUpdateLockboxData = _.omitBy(updateLockboxData, _.isEmpty);
      updateLockbox(cleanedUpdateLockboxData);
    } else {
      setHasFieldsValidation(true);
    }
  }, [updateLockbox, validateLockboxFields, editableLockbox]);

  const handleNextRecord = useCallback(() => {
    setNextLockboxId(undefined);
    history.push(ROUTES.LOCKBOX_DETAILS(nextLockboxId));
  }, [history, nextLockboxId, setNextLockboxId]);

  return (
    <LockboxInfoFooterWrapper
      background="canvas"
      justify="center"
      height={{ min: 'spacing54' }}
      pad={{ horizontal: 'spacing12' }}
      border={{ size: '1px', color: 'border2', side: 'top' }}
    >
      <Box margin={{ left: 'auto' }}>
        {isAbleToEdit ? (
          <AppButton
            onClick={handleUpdateLockbox}
            disabled={!isStatusValidationSuccessfully || !isFieldsValidationSuccessfully}
            {...isStatusValidationSuccessfully && isFieldsValidationSuccessfully
              ? { style: { backgroundColor: buttonColor }}
              : {}}
          >
            {isLoading ? <Preloader /> : buttonTitle}
          </AppButton>
        ) : (
          <AppButton
            onClick={handleNextRecord}
            width={BUTTON_WIDTH}
            disabled={!nextLockboxId}
          >
            Next record
          </AppButton>
        )}
      </Box>
    </LockboxInfoFooterWrapper>
  );
};

export default LockboxInfoFooter;
