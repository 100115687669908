import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { OrganizationLocksDtoPayload } from '@/modules/employer/types';

export const GET_ORGANIZATION_LOCKS_KEY = 'GET_ORGANIZATION_LOCKS';

const formatOrgLocks = (data: OrganizationLocksDtoPayload) => ({
  isLocked: data?.is_locked,
  organizationId: data?.organization_id,
  updatedDate: data?.updated_at,
  hasReplenishedAccounts: data?.has_replenished_accounts,
});

const useGetOrgLocksQuery = (id?: string) => {
  const {
    isLoading,
    isError,
    data,
  } = useQuery(
    [GET_ORGANIZATION_LOCKS_KEY, id],
    () => api.get<OrganizationLocksDtoPayload>(PATHS.GET_ORGANIZATION_LOCK_STATUS(id)),
    {
      enabled: !!id,
    },
  );

  return {
    formattedData: data?.data ? formatOrgLocks(data?.data) : undefined,
    isLoading,
    isError,
  };
};

export default useGetOrgLocksQuery;
