import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { ModalWrapper } from '@/components/wrappers';

import PayrollCalendarDates from './PayrollCalendarDates/PayrollCalendarDates';
import PayrollCalendarParamsModalForm from './PayrollCalendarParams/PayrollCalendarParamsModalForm';
import PayrollCalendarUseModalForm from './PayrollCalendarUse/PayrollCalendarUseModalForm';
import { PayrollCalendarModalSteps } from './stores/usePayrollCalendarModal.store';
import { useEditablePayrollCalendarStore, usePayrollCalendarDatesStore, usePayrollCalendarModalStore, usePayrollCalendarParamsStore } from './stores';

const MAX_MODAL_WIDTH = '1016px';
const DEFAULT_MONTHS = 17;

type Props = {
  onSuccess?: () => void;
};

export const PayrollCalendarModal: React.FC<Props> = ({ onSuccess }) => {
  const handleChangeVisibility = usePayrollCalendarModalStore((state) => state.handleChangeVisibility);
  const modalVisible = usePayrollCalendarModalStore((state) => state.modalVisible);
  const step = usePayrollCalendarModalStore((state) => state.step);
  const handleReset = usePayrollCalendarModalStore((state) => state.handleReset);
  const handleParamsReset = usePayrollCalendarParamsStore((state) => state.handleReset);
  const initialMonthDate = usePayrollCalendarDatesStore((state) => state.initialMonthDate);
  const dates = usePayrollCalendarDatesStore((state) => state.dates);
  const handleEditableReset = useEditablePayrollCalendarStore((state) => state.handleReset);

  const monthRange: [string, string] = useMemo(() => {
    const start = dayjs(initialMonthDate).format('MMMM YYYY');
    const endDate = dayjs(dates[dates.length - 1]).isBefore(dayjs().add(DEFAULT_MONTHS, 'month'))
      ? dayjs().add(DEFAULT_MONTHS, 'month')
      : dayjs(dates[dates.length - 1]);
    const end = endDate.format('MMMM YYYY');
    return [start, end];
  }, [initialMonthDate, dates]);

  const modalTitle = useMemo(() => {
    if (step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_PARAMS) return 'Add Calendar';
    if (step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_DATES) return `${monthRange[0]} - ${monthRange[1]}`;
    if (step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_USE) return 'Who will be Using This Calendar?';
    return 'Add Calendar';
  }, [monthRange, step]);

  const handleSetVisible = useCallback(() => {
    handleChangeVisibility(false);
  }, [
    handleChangeVisibility,
  ]);

  useEffect(() => {
    if (!modalVisible) {
      handleReset();
      handleEditableReset();
      handleParamsReset();
    }
  }, [
    handleEditableReset,
    handleReset,
    modalVisible,
    handleParamsReset,
  ]);

  return (
    <ModalWrapper
      testId="PayrollCalendarModal"
      visible={modalVisible}
      onSetVisible={handleSetVisible}
      title={modalTitle}
      titleBoxStyle={{ paddingBottom: '12px' }}
      maxWidth={step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_DATES ? MAX_MODAL_WIDTH : undefined}
    >
      {step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_PARAMS && <PayrollCalendarParamsModalForm onCancel={handleSetVisible} />}
      {step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_DATES && <PayrollCalendarDates />}
      {step === PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_USE && <PayrollCalendarUseModalForm onCancel={handleSetVisible} onSuccess={onSuccess}/>}
    </ModalWrapper>
  );
};