import { CardTypesEnum } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import { AddressesDtoPayload } from '@/modules/employer/types';
import { AddressPayload } from  '@/modules/ExpressSetup/EditAddressModal/stores/useAddress.store.ts';
import { Plan } from '@/modules/plan/plan.types.ts';

import { ContactPayload } from './AddEditContactForm/stores/useContact.store';

export enum ExpressSetupWizardStepType {
  MainContact = 'main_contact',
  BankAccount = 'bank_account',
  Plans = 'plans',
}

export enum ExpressSetupProperty {
  Contacts = 'contacts',
  BankAccount = 'bank_account',
  Plans = 'plans',
  PlanYear = 'plan_year',
  Address = 'address',
}

export enum ExpressSetupWizardStepTitle {
  main_contact = 'Main Contact',
  bank_account = 'Bank Account',
  plans = 'Plan Year & Plans',
}

export enum ExpressSetupStatuses {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  ABANDONED = 'ABANDONED',
}

export enum ReplenishmentFrequencyType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

interface PlanYearInfoDto {
  name: string;
  valid_from: string,
  valid_to: string,
}

export interface ContactDto {
  primary: boolean;
  id?: number;
  user_name: string;
  role: Roles;
  is_primary: boolean
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  is_access_to_setup: boolean;
}

export interface PlanDto {
  account_type: string;
  name: string;
  parent_id: number;
  summary?: string;
  is_custom_prefund_calc?: boolean;
  is_replenished?: boolean;
  is_plan_level_funding?: boolean;
  is_carded?: boolean;
}

export enum BankAccountTypes {
  CHECKING = 'CHECKING',
  SAVING = 'SAVING',
}

export interface BankAccountDto {
  name: string;
  encrypted_number: string;
  routing_transit_number: string;
  account_category: string;
}

export interface CopayDto {
  id?: number;
  category_type: string;
  amount: number;
}

export interface ExpressSetupDtoDetails {
  address?: AddressesDtoPayload;
  plan_year?: PlanYearInfoDto;
  plans?: PlanDto[];
  account?: BankAccountDto;
  copays?: CopayDto[];
}

export interface LinksDtoResponse {
  large_logo_path: string;
  privacy_policy_link: string;
  terms_and_conditions_link: string;
  pricing_consent_link?: string;
}

export interface ExpressSetupDtoResponse {
  id: number;
  organization_name: string;
  organization_short_name: string;
  org_id: number;
  org_path: string;
  status_type: string;
  json_record: ExpressSetupDtoDetails;
  links?: LinksDtoResponse;
  contacts?: ContactDto[];
  partner_name?: string;
  prefund_replenishment_percent?: number;
  replenishment_day_number?: number;
  replenishment_frequency_type?: string;
}

export interface Copay {
  id?: string;
  categoryType: string;
  amount: string;
  description?: string;
}

export interface ExpressSetup {
  id: string;
  name: string;
  shortName: string;
  address?: AddressPayload;
  contacts?: ContactPayload[];
  orgId: string;
  orgPath: string;
  statusType: string;
  logo?: string;
  privacyPolicyLink?: string;
  termsConditionsLink?: string;
  pricingConsentLink?: string;
  mainContact?: ContactPayload;
  planStartDate?: string;
  planEndDate?: string;
  planYearName: string;
  plans: Plan[];
  planTypes: CardTypesEnum[];
  bankAccountName: string;
  accountNumber: string;
  routingTransitNumber: string;
  accountCategory: string;
  encryptedNumber: string;
  copays: Copay[];
  isCopaysSelected: boolean;
  partnerName: string;
  prefundReplenishmentPercentage?: number;
  replenishmentDayNumber?: number;
  replenishmentFrequencyType?: string;
}

export interface ActivateModalItem {
  title: string;
  accounts?: string[];
  description: React.ReactNode | string;
}
