import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollCalendarRequest } from '../payrollCalendar.types';

const createPayrollCalendar = (request: PayrollCalendarRequest) => {
  return api.post(PATHS.PAYROLL_CALENDARS, request);
};

const useCreatePayrollCalendarQuery = () => {
  const { mutateAsync, isLoading } = useMutation(
    (request: PayrollCalendarRequest) => createPayrollCalendar(request),
  );

  const createCalendar = useCallback(async (params: PayrollCalendarRequest) => {
    await mutateAsync(params);
  }, [mutateAsync]);

  return {
    createCalendar,
    isLoading,
  };
};

export default useCreatePayrollCalendarQuery;