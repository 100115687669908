import React, { useCallback, useState } from 'react';
import { AppButton } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { ModalWrapper } from '@/components';
import { useHasAccess } from '@/modules/core/hooks';

import CreateCopayAdjudicationForm from './CreateCopayAdjudicationForm';

const CreateCopayAdjudication = () => {
  const [isVisible, setVisible] = useState(false);
  const hasEditCopayAccess = useHasAccess([{ permission: Permissions.EDIT_COPAY }]);
  const handleToggle = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);
  if (!hasEditCopayAccess) {
    return null;
  }
  return (
    <>

      <AppButton
        testId="create-copay-adjudication"
        onClick={handleToggle}
      >
        Add New Copay Group
      </AppButton>
      <ModalWrapper
        testId="create-copay-adjudication"
        visible={isVisible}
        title="Add New Copay Group"
        onSetVisible={handleToggle}
        // {...isOverflowHidden ? { wrapperStyle: { overflow: 'hidden' } } : {}}
      >
        {/* <Prompt
          when={isFormDirty}
          message={CONFIRM_TEXT}
        /> */}
        <CreateCopayAdjudicationForm
          onClose={handleToggle}
        />
      </ModalWrapper>
    </>
  );
};
export default CreateCopayAdjudication;
