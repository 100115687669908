import { TechnicalSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: TechnicalSettings;
  setTechnicalSettings: (value: TechnicalSettings) => void;
}

const useStore = storeCreator<State>((set) => ({
  state: {
    sessionTimeoutLogout: undefined,
    sessionTimeoutWarn: undefined,
  },
  setTechnicalSettings: (value: TechnicalSettings) => set(() => ({
    state: value,
  })),
}));

export const useTechnicalSettingsStore = () => ({ ...useStore() });
