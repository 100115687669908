import React, { useEffect, useMemo,useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Box,
  FlexControlledForm,
  Inscription,
  useDebounce,
} from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import ROUTES from '@/common/routes';
import { BlockWrapper } from '@/components/wrappers';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useHelpSectionFields } from '@/modules/employer/components/SetupEmployer/HelpSection/useHelpSectionFields';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { HelpInfoPayload } from '../SetupEmployer.types';

import HelpPreview from './HelpPreview';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const HelpSection: React.FC<Props> = ({ onDirty }) => {
  const [formKey, setFormKey] = useState('');
  const { isLoading, formattedData } = useGetEmployer();
  const { path } = useRouteMatch();
  const isEmployerPage = useMemo(
    () => path === ROUTES.EMPLOYERS_EDIT()
      || path === ROUTES.EMPLOYERS_VIEW,
    [path],
  );
  const setHelpInfo = useStore((state) => state.setHelpInfo);
  const { viewMode } = useSetupEmployerMode();
  const [currentValues, setCurrentValues] = useState<object>({});
  const debouncedValue: HelpInfoPayload = useDebounce(currentValues);
  const options = useMemo(
    () => ({
      isDisabledMode: viewMode || isEmployerPage,
      customerServiceNumber: formattedData?.overridableProperties?.customerServiceNumber,
      customerServiceUrlName: formattedData?.overridableProperties?.customerServiceUrlName,
      customerServiceUrlLink: formattedData?.overridableProperties?.customerServiceUrlLink,
      customerServiceEmail: formattedData?.overridableProperties?.customerServiceEmail,
      customerServiceIsEnabled: formattedData?.overridableProperties?.customerServiceIsEnabled,
    }),
    [formattedData, viewMode, isEmployerPage],
  );
  useEffect(() => {
    if (formattedData?.id) setFormKey(uniqueId());
  }, [formattedData]);
  const fields = useHelpSectionFields(options, formKey);

  useEffect(() => {
    if (debouncedValue) {
      setHelpInfo(debouncedValue);
    }
  }, [debouncedValue, setHelpInfo]);

  if (isLoading) return <FormLoader testId="HelpSection" />;

  return (
    <Box
      direction="column"
      round="container1Round"
      background="canvas"
      id="help_section"
      margin={{ top: 'spacing24' }}
      border={{ color: 'border2', size: 'small' }}
    >
      <FlexControlledForm
        key={formKey}
        fields={fields}
        editMode={!viewMode && !formattedData?.isTerminated}
        formTitle="Help"
        onChangeValues={setCurrentValues}
        onDirty={onDirty}
        isThinMode
        showError
        wrapperStyle={{ border: 'none' }}
      />

      <Box
        pad={{ horizontal: 'spacing32', vertical: '30px' }}
        border={{ side: 'top', color: 'border2', size: 'small' }}
      >
        <BlockWrapper
          moduleAnchorId="help_preview"
          isPureChild
          defaultExpandedValue={false}
          title={(
            <Box margin={{ left: '14px' }}>
              <Inscription size="20px" weight="normal" lineHeight="28px">Help Preview</Inscription>
            </Box>
          )}
        >
          <Box margin={{ top: '22px', left: '32px' }}>
            <HelpPreview
              urlName={debouncedValue?.customerServiceUrlName}
              url={debouncedValue?.customerServiceUrlLink}
              email={debouncedValue?.customerServiceEmail}
              phoneNumber={debouncedValue?.customerServiceNumber}
            />
          </Box>
        </BlockWrapper>
      </Box>
    </Box>
  );
};
