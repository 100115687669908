import { baseStoreCreator } from '@/utils/store';

export interface EnrollmentMatrixEntityPayload {
  name: string;
  planTemplate: string;
  planYear: string;
  processingRule: string;
  validFrom: string;
  validToOption: string;
  validTo: string;
  priority: number;
}

export const DEFAULT_VALUES: EnrollmentMatrixEntityPayload = {
  name: '',
  planTemplate: '',
  planYear: '',
  processingRule: '',
  validFrom: '',
  validToOption: '',
  validTo: '',
  priority: 0,
};

export const useStore = baseStoreCreator<EnrollmentMatrixEntityPayload>(DEFAULT_VALUES);

export default useStore;
