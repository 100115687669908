import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';

import { InterestAccrualsPayload,useStore } from './stores/useInterestAccruals.store';
import { useInterestAccrualsFields } from './hooks';
import InterestsTable from './InterestsTable';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const InterestAccruals: React.FC<Props> = ({ isEditMode, onDirty, isTemplateMode }) => {
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const fields = useInterestAccrualsFields(isTemplateMode, isEditMode);
  const handleSetState = useStore((state) => state.setState);

  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<InterestAccrualsPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  if (!fields.length) {
    return null;
  }

  return (
    <Box margin={{ top: 'spacing24' }}>
      <Box background="canvas" round="container1Round">
        <FlexControlledForm
          testId="plan_interest-tempalte"
          formTitle="Interest accruals"
          fields={fields}
          showError={showErrors || activateValidate || saveValidate}
          editMode={isEditMode}
          onChangeValues={setValues}
          onDirty={onDirty}
          isThinMode
          footer={(
            <InterestsTable />
          )}
        />

      </Box>

    </Box>
  );
};
export default InterestAccruals;
