import { PaginationParams } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';

import { useProcessingRulesQuery } from '../queries';

export default (params?: PaginationParams) => {
  const { observingOrganization } = useCurrentOrganization();

  const {
    data, isLoading, total, list,
  } = useProcessingRulesQuery(observingOrganization.path, params);
  return {
    total,
    data,
    list,
    isLoading,
  };
};
