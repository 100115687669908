import React from 'react';

import { Topbar } from '@/modules/core/components';

interface Props {
  children: React.ReactNode;
}
const TopbarWrapper: React.FC<Props> = ({ children }) => (
  <Topbar>
    {children}
  </Topbar>
);

export default TopbarWrapper;
