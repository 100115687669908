import React, { useCallback } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useBreadcrumbs, useHistory } from '@/modules/core/hooks';
import Claims from '@/modules/employer/components/Claims/Claims';
import { useAuthStore } from '@/modules/user/stores';

const ClaimsPage = () => {
  const { user } = useAuthStore();
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  useBreadcrumbs([{
    route: ROUTES.CLAIMS,
    title: 'Claims',
  }]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Access
      accessRequirements={[
        { role: Roles.superUser },
        { role: Roles.claimsProcessor1 },
        { role: Roles.claimsProcessorReview },
        { role: Roles.claimsProcessor2 },
        { role: Roles.claimsProcessorEdit },
        { role: Roles.admin3 },
        { role: Roles.claimsSupervisor },
      ]}
      accessDeniedAction={goHome}
    >
      <Claims />
    </Access>
  );
};

export default ClaimsPage;
