import { storeCreator } from '@/utils/store/store.utils';

import { PayrollGroupResponse } from '../payrollGroup.types';

interface State {
  currentPayrollGroups?: PayrollGroupResponse[];
  additionalAction?: () => void;
  handleSetCurrentPayrollGroups: (payrollGroups?: PayrollGroupResponse[]) => void;
  handleSetAdditionalAction: (action?: () => void) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  currentPayrollGroups: undefined,
  additionalAction: undefined,

  handleSetCurrentPayrollGroups: (value) => set((state) => ({
    ...state,
    currentPayrollGroups: value,
  })),

  handleSetAdditionalAction: (value) => set((state) => ({
    ...state,
    additionalAction: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    currentPayrollGroups: undefined,
    additionalAction: undefined,
  })),
}));