import React, { useEffect, useMemo, useState } from 'react';
import {
  AppButton,
  Box, Preloader, SiblingBoxesWithSpacing, Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import PlanYearForm from '@/modules/plan/PlanYearForm';
import { PlanYear, useSaveUpdatePlanYear } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';
import { useStore } from '@/modules/plan/PlanYearForm/stores/usePlanYearForm.store';

export interface Props {
  onCancel: (value?: boolean) => void;
  selectedPlan?: PlanYear;
  isEditMode?: boolean;
  isCreateMode?: boolean;
  includeLogHeader?: boolean;
}

const PlanYearModal: React.FC<Props> = ({
  onCancel,
  selectedPlan,
  isCreateMode,
  isEditMode,
  includeLogHeader,
}) => {
  const [isPending, setPending] = useState(false);
  const [activeValues, setActiveValues] = useState(false);
  const { handleReset } = useStore();
  const submitButtonText = isEditMode && selectedPlan?.id ? 'Save' : 'Create';
  const isEndDateFieldDisabled = useMemo(() => {
    const todayDate = dayjs().toDate().valueOf();
    if (isEditMode) {
      if (!selectedPlan?.endDate || (selectedPlan?.endDate && dayjs(selectedPlan?.endDate).valueOf() <= todayDate)) {
        return true;
      }
    }
    return false;
  }, [selectedPlan?.endDate, isEditMode]);
  const {
    handleSave, loading, isPlanYearAlreadySetError,
  } = useSaveUpdatePlanYear(setPending, onCancel, selectedPlan?.id, includeLogHeader, isEditMode, activeValues, isEndDateFieldDisabled);

  useEffect(() => handleReset, [handleReset]);

  return (
    <>
      <Box background="module" round="moduleRound" pad="medium" data-testid="PlanYearModal-view-wrapper">
        <Box background="canvas" round="container1Round">
          <PlanYearForm
            isCreateMode={isCreateMode}
            isEditMode={isEditMode}
            isViewMode={!isEditMode && !isCreateMode}
            disabled={loading}
            defaultValues={selectedPlan}
            isModalType
            setActiveValues={setActiveValues}
            isEndDateFieldDisabled={isEndDateFieldDisabled}
          />
        </Box>
      </Box>
      {
        isPlanYearAlreadySetError && (
          <Text color="danger" size="medium" textAlign="end" margin={{ top: 'xs'}}>
            This plan year is set as prior plan year for another plan year
          </Text>
        )
      }
      <Box direction="row" justify="end" align="center" pad={{ top: isPlanYearAlreadySetError ? 's' : 'medium'}}>
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="cancel"
            buttonType="secondary"
            width="100%"
            onClick={() => onCancel(false)}
          >
            Cancel
          </AppButton>
        </SiblingBoxesWithSpacing>
        {(isEditMode || isCreateMode) && (
          <SiblingBoxesWithSpacing width="control">
            <AppButton
              width="100%"
              onClick={() => handleSave()}
            >
              {
                (isPending || loading)
                  ? <Preloader color="white" />
                  : <Text>{submitButtonText}</Text>
              }
            </AppButton>
          </SiblingBoxesWithSpacing>
        )} 
      </Box>
    </>
  );
};

export default PlanYearModal;
