import { useQuery } from 'react-query';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';
import { SubsidiaryInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';

export const QUERY_KEY = 'useSubsidiaryList';
export const VALUES: SubsidiaryInfo[] = [];

mock.onGet(PATHS.GET_SUBSIDIARIES).reply(200, VALUES);

export default () => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(QUERY_KEY, () => api.get<SubsidiaryInfo[]>(PATHS.GET_SUBSIDIARIES), {});
  return ({
    data: data?.data || [],
    isError,
    isLoading,
    isSuccess,
  });
};
