import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';

import { useSetupEmployerMode } from '../hooks';
import RedirectSection from '../RedirectSection/RedirectSection';
import { useSaveTypeStore } from '../stores';

import CustomReports from './CustomReports';

interface Props {
  isDisabled: boolean;
  isReadMode?: boolean;
  isPartnerMode?: boolean;
}
const Reports: React.FC<Props> = ({ isDisabled, isReadMode, isPartnerMode }) => {
  const { viewMode } = useSetupEmployerMode();
  const { activateSubmitted } = useSaveTypeStore();

  if (isPartnerMode) {
    return (
      <RedirectSection readMode={isReadMode} to={ROUTES.REPORTS} linkText="Reports" affix="page.">
        Reports can be configured separately on the
      </RedirectSection>
    );
  }
  return (
    <Box data-testid="reports-section">
      <CustomReports isDisabled={isDisabled} isShowErrors={activateSubmitted} isEditMode={!viewMode} />
    </Box>
  );
};

export default Reports;
