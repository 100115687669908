import React, { useEffect, useMemo, useState } from 'react';
import { DateInput } from '@common-fe/common-fe';

import useRecipientsStore from './stores/useRecipients.store';
import { getDateInputErrorMessage } from './utils/getErrorMessage';
import { WrapToError } from './ContributionEmployeesSettings.styles';
import { ContributionAccount, ContributionBackendErrors, Recipient } from './types';
import WarnIconWithText from './WarnIconWithText';

interface Props {
  recipient: Recipient;
  contributionAccount: ContributionAccount;
  areErrorsShown?: boolean;
  backendError?: ContributionBackendErrors;
}

const ContributionDateInput: React.FC<Props> = ({ recipient, contributionAccount, areErrorsShown, backendError }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { recipients, setRecipients } = useRecipientsStore();

  const errorMessage = useMemo(() => getDateInputErrorMessage(contributionAccount, areErrorsShown, backendError),
    [areErrorsShown, contributionAccount, backendError]);

  useEffect(() => () => setIsFocused(false), []);

  return (
    <WrapToError hasError={Boolean(errorMessage)} width={{ min: '140px', max: '140px' }} onBlur={() => setIsFocused(false)} onFocus={() => setIsFocused(true)}>
      <DateInput
        disabled={contributionAccount?.isDisabled}
        name="contributionDateOn"
        isPicker
        value={contributionAccount?.isDisabled || !contributionAccount?.contributionDateOn ? '' : contributionAccount?.contributionDateOn}
        onChange={(val) => {
          if (isFocused) {
            setRecipients(recipients?.map((item) => {
              if (item?.employeeId === recipient?.employeeId) {
                return {
                  ...item,
                  contributionAccounts: item?.contributionAccounts?.map((acc) => {
                    if (acc?.planId === contributionAccount?.planId) {
                      return {
                        ...contributionAccount,
                        contributionDateOn: val,
                      };
                    }
                    return acc;
                  })
                };
              }
              return item;
            }));
          }
        }}
        placeholder="Set date"
        {...errorMessage ? {
          rightIcon: (
            <WarnIconWithText message={errorMessage} />
          ),
        } : {}}
      />
    </WrapToError>
  );
};

export default ContributionDateInput;
