import { DistributiveOmit } from '@/common/types';

export enum SnackbarState {
  positive = 'Positive',
  negative = 'Negative',
  neutral = 'Neutral',
}
interface DefaultSnackbarOptions {
  id: string;
  text: string;
  subtext?: string;
  state?: SnackbarState;
  permanent?: boolean;
  isActionLeftSide?: boolean;
  width?: string;
}

export interface SnackbarWithRedirect {
  closeIcon: true;
  redirect?: {
    path: string;
    label: string;
    hash?: string;
  }
}

interface SnackbarWithoutRedirect {
  closeIcon?: false;
}

export type SnackbarNotification =
  DefaultSnackbarOptions & (SnackbarWithoutRedirect | SnackbarWithRedirect);

export type AddSnackbarArgument = DistributiveOmit<SnackbarNotification, 'id'>;
