import React, { useMemo, useState } from 'react';
import {
  ActivityType,
  Box,
  CardTypesEnum,
  costFormater,
  getPercents,
  Text,
  Tip,
} from '@common-fe/common-fe';

import { ExpenseOption } from '@/modules/transaction/transaction.types';
import spacings from '@/styles/spacings';

import {
  Bar,
  ChartWrapper,
  ContentWrapper,
  Cost,
  Label,
  LabelsWrapper,
  TipWrapper,
  Title,
  TotalHeader,
} from './ExpensesBreakdown.styles';

interface Props {
  options: ExpenseOption[];
  inlineMode?: boolean;
  totalExpense: number;
  isWithoutHeader?: boolean;
  isPortfolioMode?: boolean;
  isPlans?: boolean;
}
const ExpensesBreakdown: React.FC<Props> = ({
  options,
  inlineMode,
  totalExpense,
  isWithoutHeader,
  isPortfolioMode,
  isPlans,
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const items = useMemo<ExpenseOption[]>(() => (
    options.sort((a, b) => (a.value < b.value ? 1 : -1))
  ), [options]);

  const total = useMemo(() => items.reduce((result, item) => result + item.value, 0), [items]);
  const costBlock = (
    <>
      <TotalHeader>Total:</TotalHeader>

      <Cost>{costFormater(totalExpense)}</Cost>
    </>
  );

  return (
    <ContentWrapper
      height={isWithoutHeader ? '100%' : undefined}
      inlineMode={inlineMode}
      isPortfolioMode={isPortfolioMode}
      isWithoutHeader={isWithoutHeader}
      justify="start"
      elevation="default"
      border={{ size: 'small', color: 'border2' }}
    >
      {items.length ? (
        <>
          <Box flex direction="row" align="center" height={{ max: spacings.spacing24 }}>
            <Title>{isPortfolioMode ? 'Recommended Portfolio' : 'Expense Breakdown'}</Title>
            <Box
              flex
              direction="row"
              align="center"
              justify="end"
            >
              {inlineMode && !isPortfolioMode && costBlock}
            </Box>
          </Box>
          <Tip
            dropProps={{
              align: { bottom: 'top' },
            }}
            plain
            content={(
              <TipWrapper
                flex
                direction="row"
                background="background3"
                round="container1Round"
                pad="spacing12"
                width="210px"
                color="textOnColor"
              >
                <Box
                  direction="column"
                  width="100%"
                >
                  <Text size="14px" weight={700}>Current Holdings</Text>
                  {items.map((item) => (
                    <Box
                      key={item.key}
                      direction="row"
                      justify="between"
                      align="end"
                      height={{ max: '24px' }}
                      pad={{ horizontal: 'spacing8' }}
                      background={{ color: hoveredItem === item.key ? 'border2' : undefined }}
                      round="xxsmall"
                    >
                      <Label
                        inline={inlineMode}
                        key={item.key}
                        typeKey={isPlans ? undefined : item.key as ActivityType}
                        planTypeKey={isPlans ? item.key as CardTypesEnum : undefined}
                      >
                        {item.title}
                      </Label>
                      <Text weight="bold">{costFormater(item?.amount, true)}</Text>
                    </Box>
                  ))}
                </Box>
                <Box
                  background="canvas"
                  width="10px"
                  height="10px"
                  style={{
                    transform: 'rotate(-135deg)',
                    position: 'absolute',
                    bottom: '-5px',
                    left: '48%',
                  }}
                />
              </TipWrapper>
            )}
          >
            <ChartWrapper
              flex
              direction="row"
              round="container1Round"
              overflow="hidden"
              height={{ min: spacings.spacing48, max: spacings.spacing48 }}
              margin={{ top: '21px' }}
            >
              {items.map((item) => {
                const percentsRaw = getPercents(item.value, total);
                const percents = getPercents(item.value, total).toFixed(0);
                return (
                  <Bar
                    key={item.key}
                    typeKey={isPlans ? undefined : item.key as ActivityType}
                    planTypeKey={isPlans ? item.key as CardTypesEnum : undefined}
                    width={percentsRaw}
                    onMouseEnter={() => setHoveredItem(item.key)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {percents}
                    %
                  </Bar>
                );
              })}
            </ChartWrapper>
          </Tip>
          <Box
            flex
            direction="row"
          >
            <LabelsWrapper
              flex
              direction="row"
            >
              {items.map((item) => (
                <Label
                  inline={inlineMode}
                  key={item.key}
                  typeKey={isPlans ? undefined : item.key as ActivityType}
                  planTypeKey={isPlans ? item.key as CardTypesEnum : undefined}
                >
                  {item.title}
                </Label>
              ))}
            </LabelsWrapper>
          </Box>
        </>
      ) : (
        <>
          <Box flex direction="row" align="center" height={{ max: spacings.spacing24 }}>
            <Title>{isPortfolioMode ? 'Recommended Portfolio' : 'Expense Breakdown'}</Title>
            <Box
              flex
              direction="row"
              align="center"
              justify="end"
            >
              {inlineMode && costBlock}
            </Box>
          </Box>
          <ChartWrapper
            flex
            direction="row"
            round="container1Round"
            overflow="hidden"
            height={{ min: spacings.spacing48, max: spacings.spacing48 }}
            margin={{ top: '21px' }}
          >
            <Bar width={100}>
              {0}
              %
            </Bar>
          </ChartWrapper>
        </>
      )}

    </ContentWrapper>
  );
};
export default ExpensesBreakdown;
