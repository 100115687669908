import React, { useMemo } from 'react';
import { Field, FieldTypes, Text } from '@common-fe/common-fe';
import * as yup from 'yup';

import { DefaultFields } from '@/common/types';

import { useSecurityStore } from '../stores';
// import HelpIcon from '../HelpIcon';

const REQUIRED_TEXT = 'This field is required';
export default (
  disabled?: boolean,
) => {
  const defaultFields = useSecurityStore((state) => state.sourceState);

  const fields = useMemo<Field[]>(() => [
    {
      name: 'username',
      type: FieldTypes.Text,
      label: 'Username',
      disabled: true,
      placeholder: '—',
      // showRequireIcon: true,
      className: 'username-input',
      isLowerCaseMode: true,
      validator: yup.string().trim().required(REQUIRED_TEXT),
      hint: defaultFields?.username ? undefined
        : (
          <Text size="small">Your username will be displayed after registration is complete.</Text>)
      ,
    },
    {
      name: 'password',
      type: FieldTypes.Password,
      label: 'Password',
      disabled: true,

      placeholder: 'Enter password',
      value: '************',
      // showRequireIcon: true,
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },

  ], [defaultFields]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
