import { useMutation } from 'react-query';
import {confirmSignIn,fetchAuthSession,signIn} from '@aws-amplify/auth';

import { Roles } from '@/common/constants';
import { useAuthorization } from '@/modules/user/hooks';
import { NEW_PASSWORD_TOKEN_KEY } from '@/modules/user/hooks/useAuthorization';
import configureAmplify from '@/utils/configureAmplify';

export interface LoginPayload {
  username: string;
  password: string;
  newPassword?: string;
}
configureAmplify();

const useSignin = () => {
  const { handleLogin, handleLogout } = useAuthorization();
  const mutation = useMutation(
    async (loginPayload: LoginPayload) => {
      try {

        const response = await signIn({
          username: loginPayload.username, 
          password: loginPayload.password
        });
        const isNewPasswordRequired = !response.isSignedIn && response.nextStep.signInStep === NEW_PASSWORD_TOKEN_KEY;

        let session = await fetchAuthSession();
        const role = session.tokens?.idToken?.payload?.['custom:role'];
        
        if (role === Roles.employee || role === Roles.limitedAccess || role === Roles.noAccess) {
   
          await handleLogout();
          throw new Error('');
        }
        // const isPasswordChangingRequired = user.challengeName === 'NEW_PASSWORD_REQUIRED';
  
        // if (isAuthorization && isPasswordChangingRequired) {
        //   return user.challengeName;
        // }
  
        if (loginPayload.newPassword) {
          await confirmSignIn({challengeResponse :loginPayload.newPassword});
          session = await fetchAuthSession();
          const token = session.tokens?.idToken?.toString();

          if(token) {
            handleLogin(token);
          }
        }

        if (isNewPasswordRequired) {
          return handleLogin(NEW_PASSWORD_TOKEN_KEY);
        }
        // Clear URL when jump between two users with different org paths
        window.location.href = '/';
        return session.tokens?.idToken?.toString();
      }
      catch (e) {
        await handleLogout(); 
        throw new Error('');
      }
       
    },
    {
      onSuccess: (token) => {
        if(token) {
          handleLogin(token);
        }
       
      },

    },
  );
  const mutationLogin = mutation.mutate;
  return { isError: mutation.isError, mutationLogin };
};
export default useSignin;
