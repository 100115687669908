import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollCalendarRequest } from '../payrollCalendar.types';

export const useUpdatePayrollCalendarQuery = (calendarId?: string) => {
  const { mutateAsync, isLoading } = useMutation(
    (request: PayrollCalendarRequest) => api.put(PATHS.UPDATE_OR_DELETE_PAYROLL_CALENDAR(calendarId), request),
  );

  const updateCalendar = useCallback(async (params: PayrollCalendarRequest) => {
    await mutateAsync(params);
  }, [mutateAsync]);

  return {
    updateCalendar,
    isLoading,
  };
};
