import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  notification?: boolean;
}

const Wrapper = styled.div<Props>`
  position: fixed;
  ${({ notification }) => (notification ? 'bottom: 20px; left: 20px; ' : 'bottom: 10px; left: 0; right: 0;')};
  z-index: 1003;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SnackbarBlock: React.FC<Props> = ({ children, notification }) => (
  <Wrapper notification={notification}>
    {children}
  </Wrapper>
);

export default SnackbarBlock;
