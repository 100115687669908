import { storeCreator } from '@/utils/store/store.utils';

interface State {
  dates: string[];
  initialMonthDate?: string;
  minDate?: string;
  handleSetMinDate: (value?: string) => void;
  handleSetDates: (value: string[]) => void;
  handleSetInitialMonthDate: (value: string) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  dates: [],
  initialMonthDate: undefined,
  minDate: undefined,

  handleSetDates: (value) => set((state) => ({
    ...state,
    dates: value,
  })),

  handleSetInitialMonthDate: (value) => set((state) => ({
    ...state,
    initialMonthDate: value,
  })),

  handleSetMinDate: (value) => set((state) => ({
    ...state,
    minDate: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    dates: [],
    initialMonthDate: undefined,
    minDate: undefined,
  })),
}));