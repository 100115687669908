import React, { useCallback, useMemo } from 'react';
import { Box, PriceInput } from '@common-fe/common-fe';

import { LockboxValidationError, lockboxValidationErrorTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

const LockboxAmountInput = () => {
  const { editableLockbox, validationErrors, setEditableLockbox } = useLockboxDetailsStore();

  const handleUpdateLockboxAmount = useCallback((amount: string) => {
    setEditableLockbox({ amount: parseFloat(amount).toFixed(2) });
  }, [setEditableLockbox]);

  const errorType = useMemo(() => {
    return validationErrors?.find((error) => error === LockboxValidationError.AMOUNT_REQUIRED) as LockboxValidationError | undefined;
  }, [validationErrors]);

  return (
    <Box>
      <PriceInput
        name="lockboxAmountInput"
        value={editableLockbox?.amount || ''}
        onChange={handleUpdateLockboxAmount}
        placeholder="Amount"
        {...errorType ? { errorText: lockboxValidationErrorTitle?.[errorType] } : {}}
        isSmall
        isBold
      />
    </Box>
  );
};

export default LockboxAmountInput;
