import React, { useMemo } from 'react';
import { Box, Text, Tip } from '@common-fe/common-fe';
import styled from 'styled-components';

import { LetterIconSizeEnum,OrganizationTypes } from '@/common/constants';
import { Icon } from '@/components/elements/Icon/Icon';
import { useRedirectToPrevObserving } from '@/modules/core/components/Topbar/Breadcrumbs/hooks/useRedirectToPrevObserving';
import { Breadcrumb, BreadcrumbOptions } from '@/modules/core/store/useBreadcrumbs.store';

import { BreadcrumbsPopup } from './BreadcrumbsPopup';

interface Props {
  userOrganizationType?: OrganizationTypes;
  groupIndex?: number;
  breadcrumbs: Breadcrumb[];
  hasMore: boolean;
  groupId: string;
  type: OrganizationTypes,
  options: Partial<BreadcrumbOptions>,
  isEllipsisMode?: boolean;
  hideFirstBreadcrumb?: boolean;
}

const StyledWrap = styled(Box)`
box-shadow: none;
  &.disable {
    cursor: not-allowed;
  }
  &.hover {
    &:hover {
      background: ${({ theme }) => theme.colors.border2};
    }
    &:focus {
      background: ${({ theme }) => theme.colors.border2};
    }
  }
`;

const LONG_NAME_COUNT = 25;

const Slash: React.VFC = () => (
  <Box
    background="canvas"
    margin={{ right: '1px' }}
    round="smallRound"
    pad={{ horizontal: '1px', vertical: 'spacing2' }}
  >
    <Text color="border">/</Text>
  </Box>
);

export const BreadcrumbGroup: React.FC<Props> = ({
  userOrganizationType,
  breadcrumbs,
  groupIndex = 0,
  hasMore,
  type,
  groupId,
  options,
  isEllipsisMode,
  hideFirstBreadcrumb,
}) => {
  const { redirectToPrevObserving } = useRedirectToPrevObserving(type, userOrganizationType);
  const renderedBreadcrumbs = useMemo(() => (
    breadcrumbs.map((breadcrumb, index) => {
    
      return (
        <React.Fragment key={breadcrumb.id}>
          <StyledWrap
            direction="row"
            round="button2Round"
            data-testid={`breadcrumbs_${groupIndex}_text_${index}`}
            align="center"
            margin={{ right: '1px' }}
            className={
              !hasMore && !breadcrumbs[index + 1]
                ? 'disable'
                : 'hover'
            }
            onClick={
              !hasMore && !breadcrumbs[index + 1]
                ? undefined
                : () => (breadcrumb.redirect
                  ? breadcrumb.redirect()
                  : redirectToPrevObserving(breadcrumb.route || '', options, breadcrumb.params, breadcrumb.hash))
            }
            {...isEllipsisMode && index !== 0
              ? { border: { size: 'xsmall', color: 'border1' } }
              : {}
            }
          >
            <Tip
              plain
              content={breadcrumb.tooltipText || breadcrumb.title.length > LONG_NAME_COUNT
                ? (
                  <Box elevation="default" background="background3" pad="small">
                    <Text color="textOnColor">{breadcrumb.tooltipText || breadcrumb.title}</Text>
                  </Box>
                ) : null}
            >
              <Box
                // background="canvas"
                round="smallRound"
                margin={{ left: 'spacing2' }}
                pad={{ horizontal: 'spacing4', vertical: 'spacing2' }}
              >
  
                <Text
                  data-testid={`breadcrumbs_${groupIndex}_title_${index}`}
                  color={!hasMore && !breadcrumbs[index + 1] ? 'textDisabled' : 'textBody'}
                >
                  {`${breadcrumb.title.substring(0, LONG_NAME_COUNT)}${breadcrumb.title.length > LONG_NAME_COUNT ? '...' : ''}`}
                </Text>
              </Box>
            </Tip>
          </StyledWrap>
          {
            breadcrumbs[index + 1]
              ? <Slash />
              : null
          }
        </React.Fragment>
      );
    } 
    )
  ), [breadcrumbs, groupIndex, hasMore, options, redirectToPrevObserving, isEllipsisMode]);
  const firstBreadcrumb = useMemo(() => renderedBreadcrumbs[0], [renderedBreadcrumbs]);
  const lastBreadcrumbs = useMemo(
    () => [...renderedBreadcrumbs].slice(1, renderedBreadcrumbs.length),
    [renderedBreadcrumbs],
  );

  return (
    <Box
      margin={{ vertical: 'spacing2' }}
      round="snackbarRound"
      data-testid={`breadcrumbs_wrapper_${groupIndex}`}
      pad={{ horizontal: 'spacing8', vertical: '3px' }}
      direction="row"
      align="center"
      border={{
        size: 'xsmall',
        color: 'border2',
      }}
    >
      {
        !hideFirstBreadcrumb && type && breadcrumbs[0]
          ? (
            <StyledWrap
              className={!hasMore && breadcrumbs.length === 1 ? 'disable' : ''}
              data-testid={`breadcrumbs_logo_${groupIndex}`}
              onClick={
                !hasMore && breadcrumbs.length === 1
                  ? undefined
                  : () => (breadcrumbs[0].redirect
                    ? breadcrumbs[0].redirect()
                    : redirectToPrevObserving(breadcrumbs[0].route || '', options, breadcrumbs[0].params, breadcrumbs[0].hash))
              }
            >
              <Icon
                size={LetterIconSizeEnum.FAV}
                type={type}
                text={breadcrumbs[0].title}
                src={breadcrumbs[0]?.faviconUrl}
              />
            </StyledWrap>
          )
          : null
      }
      {!hideFirstBreadcrumb ? firstBreadcrumb : null}

      {isEllipsisMode ? (
        <BreadcrumbsPopup
          content={lastBreadcrumbs}
          style={{ top: 40 }}
        >
          <Text size="14px" style={{ padding: '2px' }}>...</Text>
        </BreadcrumbsPopup>
      ) : lastBreadcrumbs}
    </Box>
  );
};
