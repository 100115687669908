import React, { useMemo } from 'react';
import {
  BankDepositIcon, BillingCheckIcon, DebitCardIcon, PayPalIcon, VenmoIcon,
} from '@common-fe/common-fe';

import { PaymentMethodType } from '@/modules/employee/employee.types';
import colors from '@/styles/colors';
import spacings from '@/styles/spacings';

interface Props {
  paymentType?: PaymentMethodType;
  wrap?: (icon: React.ReactNode) => React.ReactNode;
}

const PaymentTypeIcon: React.FC<Props> = ({ paymentType, wrap }) => {
  const icon = useMemo(() => {
    switch (paymentType) {
    case PaymentMethodType.DIRECT_DEPOSIT:
      return <BankDepositIcon size={spacings.xxl} color={colors.iconAccent} />;
    case PaymentMethodType.DEBIT:
      return <DebitCardIcon size={spacings.xl} color={colors.iconAccent} />;
    case PaymentMethodType.PAYPAL:
      return <PayPalIcon />;
    case PaymentMethodType.VENMO:
      return <VenmoIcon />;
    case PaymentMethodType.CHECK:
      return <BillingCheckIcon size={spacings.xl} color={colors.iconAccent} />;
    default:
      return null;
    }
  }, [paymentType]);
  return icon ? (
    <>
      {wrap ? wrap(icon) : icon}
    </>
  ) : null;
};

export default PaymentTypeIcon;
