import { useMemo } from 'react';
import {
  Field,
  FieldTypes,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import {
  DefaultFields,
} from '@/common/types';
import { useCurrentOrganisationStore } from '@/modules/core/store';

import { usePriorPlanYearQuery } from '../queries';
import { PlanYearFormPayload, useStore } from '../stores/usePlanYearForm.store';

const REQUIRED_TEXT = 'This field is required';
const PRIOR_PLAN_YEAR_KEY = 'priorPlanYear';
export default (
  defaultFields?: Partial<PlanYearFormPayload>,
  disabled?: boolean,
  hideName?: boolean,
  isCreateMode?: boolean,
  isEditMode?: boolean,
  isEndDateFieldDisabled?: boolean,
) => {
  const { organizationId } = useCurrentOrganisationStore();
  const errors = useStore((state) => state.errors);
  const date = useStore((state) => state.state?.startDate);
  const { data: priorPlanYearList } = usePriorPlanYearQuery(
    date,
    _.toNumber(organizationId),
    isCreateMode,
  );

  // const { organizationId } = useCurrentOrganisationStore();
  // const { data: copayGroups } = useCopayGroupsQuery(organizationId);

  // const formatedCopayGroups = useMemo(() => {
  //   const result: Option[] = [{
  //     key: NONE_KEY,
  //     value: NONE_KEY,
  //     title: 'None',
  //     textStyle: {
  //       fontWeight: 'bold',
  //     },
  //   }];
  //   copayGroups.forEach((item) => {
  //     result.push({
  //       key: `${item.id}`,
  //       value: `${item.id}`,
  //       title: item.templateName,
  //     });
  //   });
  //   return result;
  // },
  // [copayGroups]);

  const fields = useMemo<Field[]>(() => {
    const sourceFields = [
      ...hideName
        ? []
        : [{
          name: 'name',
          type: FieldTypes.Text,
          disabled: isCreateMode || isEditMode ? disabled : true,
          label: 'Plan year name',
          showRequireIcon: true,

          placeholder: 'Enter Plan year name',
          validator: yup.string().trim().required(REQUIRED_TEXT).test({
            test: (val) => val !== errors?.name,
            message: 'This plan name is already used',
          }),
        }], {
        name: 'startDate',
        type: FieldTypes.Date,
        isManualDateInput: true,
        disabled: isCreateMode || isEditMode ? disabled : true,
        isWide: true,
        label: 'Plan start date',
        // minDate: TODAY,
        showRequireIcon: true,
        maxDate: defaultFields?.endDate
          ? dayjs(defaultFields.endDate, DEFAULT_DATE_FORMAT) : undefined,
        placeholder: 'Select Plan start date',
        validator: isEditMode && defaultFields?.startDate ? VALIDATORS.DATE_FORMAT_BEFORE_OTHER_DATE(
          defaultFields?.startDate,
          'New start date must be earlier than current start date',
          true,
          true
        ).test({
          test: (val) => val !== errors?.startDate,
          message: 'New start date can’t overlap prior plan year',
        }).required(REQUIRED_TEXT) : VALIDATORS.DATE_FORMAT.required(REQUIRED_TEXT),
      },
      {
        name: 'endDate',
        disabled: isCreateMode || !isEndDateFieldDisabled ? disabled : true,
        type: FieldTypes.Date,
        isManualDateInput: true,
        isWide: true,
        fieldHintText: isEndDateFieldDisabled && defaultFields?.endDate ?
          'Plan year end date can\'t be changed as it is already passed' : isEndDateFieldDisabled ?
            'Plan year end date can\'t be set for unlimited plan year' : undefined,
        label: 'Plan end date',
        minDate: defaultFields?.startDate
          ? dayjs(defaultFields.startDate, DEFAULT_DATE_FORMAT) : undefined,
        placeholder: 'Select Plan end date (optional)',
        validator: isEditMode && defaultFields?.endDate ? VALIDATORS.DATE_FORMAT_AFTER_OTHER_DATE(
          defaultFields?.endDate,
          'New end date must be later than current end date',
          true,
          true
        ).test({
          test: (val) => val !== errors?.endDate,
          message: 'End Date cannot be null because this plan year is sued for rollover',
        }) : VALIDATORS.DATE_FORMAT_AFTER_OTHER_DATE(
          'startDate',
          'The end date couldn\'t be earlier than the start date',
        ),
      },
      {
        testId: 'prior_plan_year',
        name: isCreateMode ? 'priorPlanYearId' : PRIOR_PLAN_YEAR_KEY,
        type: FieldTypes.Dropdown,
        label: 'Prior plan year',
        disabled: isCreateMode ? (disabled || !date) : true,
        options: priorPlanYearList,
        singleMode: true,
        emptyListMessage: 'No prior plans have been found',
        placeholder: 'Select prior plan year',
        validator: yup.string().trim(),
      },
      // {
      //   name: 'copayGroup',
      //   type: FieldTypes.Dropdown,
      //   label: 'Copay group',
      //   disabled,
      //   defaultValue: disabled && !defaultFields?.copayGroup ? NONE_KEY : undefined,
      //   options: formatedCopayGroups,
      //   singleMode: true,
      //   placeholder: 'Select copay group',
      //   validator: yup.string().trim(),

      // },
    ];
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return sourceFields.map((item) => ({
        ...item,
        value: item.name === PRIOR_PLAN_YEAR_KEY
          ? defaultValues[item.name]?.name : defaultValues[item.name] || '',
      })) as Field[];
    }
    return sourceFields as Field[];
  }, [hideName,
    isCreateMode,
    disabled,
    defaultFields,
    priorPlanYearList,
    date,
    errors,
    isEditMode,
    isEndDateFieldDisabled
  ]);
  return fields;
};
