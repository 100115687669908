import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { LockboxStatus, LockboxType } from '@/modules/employer/components/Lockbox/lockbox.types';

interface LockboxPayload {
  employee_id?: string;
  employee_name?: string;
  organization_id?: number;
  organization_path?: string;
  account_id?: string;
  account_number?: string;
  amount?: string;
  lockbox_type?: LockboxType;
  lockbox_status?: LockboxStatus;
  reason?: string;
  notes?: string;
  vendor_id?: string;
}

interface Props {
  lockboxId?: string;
}

const useUpdateLockboxQuery = ({ lockboxId }: Props) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isError } = useMutation(
    (payload: LockboxPayload) => api.patch(PATHS.UPDATE_LOCKBOX(lockboxId), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.LOCKBOX_BY_ID(lockboxId));
      },
    }
  );

  return {
    updateLockbox: mutateAsync,
    isLoading,
    isError,
  };
};

export default useUpdateLockboxQuery;
