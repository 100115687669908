import { useCallback, useEffect } from 'react';
import _ from 'lodash';

import { OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { QueryKeysUnionType } from '@/modules/core/hooks/useHistory';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import { storeCreator } from '@/utils/store/store.utils';

export interface Breadcrumb {
  route?: string,
  title: string;
  faviconUrl?: string;
  params?: { [ key in QueryKeysUnionType ]?: string | boolean },
  hash?: string;
  id: string,
  tooltipText?: string;
  redirect?: () => void
}

export interface BreadcrumbOptions {
  backBtn: boolean;
  orgType?: OrganizationTypes;
  removeParamsByRedirecting?: QueryKeysUnionType[],
}

export interface BreadcrumbsGroup<B = Breadcrumb> {
  id: string,
  breadcrumbs: B[];
  iconType: OrganizationTypes,
}

interface State {
  breadcrumbsGroup: BreadcrumbsGroup[];
  options: Partial<BreadcrumbOptions>;
  handleReset: () => void;
  handleSetOptions: (options?: Partial<BreadcrumbOptions>) => void;
  handleAddGroup: (iconType: OrganizationTypes, item: Omit<Breadcrumb, 'id'>[]) => void;
  handleSetGroups: (item: Omit<BreadcrumbsGroup<Omit<Breadcrumb, 'id'>>, 'id'>[]) => void;
  handleAddBreadcrumbToGroup: (item: Omit<Breadcrumb, 'id'>, id: string) => void;
  handleAddBreadcrumb: (item: Omit<Breadcrumb, 'id'>) => void;
  handleSetBreadcrumbs: (item: Omit<Breadcrumb, 'id'>[]) => void;
  handleBreadcrumbRemove: (id?: string) => void;
  handleGroupRemove: (id: string) => void;

  controlledBreadcrumbs: Breadcrumb[],
  setControlledBreadcrumbs: (item: Omit<Breadcrumb, 'id'>[]) => void;
}

const useStore = storeCreator<State>((set) => ({
  breadcrumbsGroup: [],
  controlledBreadcrumbs: [],
  options: {},
  handleSetOptions: (options = {}) => set({
    options,
  }),
  setControlledBreadcrumbs: (breadcrumbs) => set(() => ({
    controlledBreadcrumbs: breadcrumbs.map((breadcrumb) => ({
      ...breadcrumb,
      id: _.uniqueId(),
    })),
  })),
  handleSetGroups: (groups) => set(() => ({
    breadcrumbsGroup: groups.map((group) => ({
      id: _.uniqueId(),
      ...group,
      breadcrumbs: group.breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        id: _.uniqueId(),
      })),
    })),
  })),
  handleAddGroup: (iconType, breadcrumbs) => set((state) => ({
    breadcrumbsGroup: [...state.breadcrumbsGroup, {
      id: _.uniqueId(),
      iconType,
      breadcrumbs: breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb, id: _.uniqueId(),
      })),
    }],
  })),
  handleAddBreadcrumb: (item) => set((state) => ({
    breadcrumbsGroup: state.breadcrumbsGroup.map((group, index) => {
      if (
        index === state.breadcrumbsGroup.length - 1
        && !group.breadcrumbs.some((breadcrumb) => breadcrumb.route === item.route)
      ) {
        return {
          ...group,
          breadcrumbs: [...group.breadcrumbs, { ...item, id: _.uniqueId() }],
        };
      }
      return group;
    }),
  })),
  handleSetBreadcrumbs: (items) => set((state) => ({
    breadcrumbsGroup: state.breadcrumbsGroup.map((group, index) => ({
      ...group,
      breadcrumbs: [
        group.breadcrumbs[0],
        ...items.map((item) => ({
          ...item, id: _.uniqueId(),
        })),
      ],
    })),
  })),
  handleAddBreadcrumbToGroup: (item, id) => set((state) => ({
    breadcrumbsGroup: state.breadcrumbsGroup.map((group) => {
      if (
        group.id === id
          && !group.breadcrumbs.some((breadcrumb) => breadcrumb.route === item.route)
      ) {
        return {
          ...group,
          breadcrumbs: [...group.breadcrumbs, { ...item, id: _.uniqueId() }],
        };
      }
      return group;
    }),
  })),
  handleBreadcrumbRemove: (id) => set((state) => ({
    breadcrumbsGroup: state.breadcrumbsGroup.map((group) => ({
      ...group,
      breadcrumbs: group.breadcrumbs.filter((breadcrumb) => breadcrumb.id !== id),
    })),
  })),
  handleGroupRemove: (id) => set((state) => ({
    breadcrumbsGroup: state.breadcrumbsGroup.filter((group) => group.id !== id),
  })),
  handleReset: () => set(() => ({
    breadcrumbsGroup: [],
  })),

}));

export const useBreadcrumbsStore = (expressSetupMode?: boolean) => {
  const {
    breadcrumbsGroup,
    handleAddGroup,
    handleReset,
    handleAddBreadcrumb,
    handleSetBreadcrumbs,
    handleSetGroups,
    controlledBreadcrumbs,
    handleSetOptions,
    ...store
  } = useStore();
  const {
    observingDefault,
    observingEmployer,
    observingPartner,
    observingDistributor,
    observingSubsidiary,
  } = useCurrentOrganization();
  const {
    observingDefaultData,
    observingEmployerData,
    observingPartnerData,
    observingDistributorData,
    observingSubsidiaryData,
  } = useOrganizationOverridableProperties();

  const resetToDefaultBreadcrumbs = useCallback(() => {
    handleReset();

    if (observingDefault?.type) {
      handleAddGroup(
        observingDefault.type, [
          {
            title: observingDefault.name || '',
            route: ROUTES.HOME,
            faviconUrl: observingDefaultData?.logoInfo?.faviconUrl
             || observingDefaultData?.logoInfo?.minimizedLogoUrl,
          },
        ],
      );
    }

    if (observingPartner?.type && !expressSetupMode) {
      handleAddBreadcrumb(
        {
          title: 'Partners',
          route: ROUTES.PARTNERS,
        },
      );
      handleAddGroup(
        observingPartner.type, [
          {
            title: observingPartner.name || '',
            route: ROUTES.HOME,
            faviconUrl: observingPartnerData?.logoInfo?.faviconUrl
             || observingPartnerData?.logoInfo?.minimizedLogoUrl,
          },
        ],
      );
    }

    if (observingDistributor?.type && !expressSetupMode) {
      handleAddBreadcrumb(
        {
          title: 'Distributors',
          route: ROUTES.DISTRIBUTORS,
        },
      );
      handleAddGroup(
        observingDistributor.type, [
          {
            title: observingDistributor.name || '',
            route: ROUTES.HOME,
            faviconUrl: observingDistributorData?.logoInfo?.faviconUrl
             || observingDistributorData?.logoInfo?.minimizedLogoUrl,
          },
        ],
      );
    }

    if (observingEmployer?.type && !expressSetupMode) {
      handleAddBreadcrumb(
        {
          title: 'Employers',
          route: ROUTES.EMPLOYERS,
        },
      );
      handleAddGroup(
        observingEmployer.type, [
          {
            title: observingEmployer.name || '',
            route: ROUTES.HOME,
            faviconUrl: observingEmployerData?.logoInfo?.faviconUrl
             || observingEmployerData?.logoInfo?.minimizedLogoUrl,
          },
        ],
      );
    }

    if (observingSubsidiary?.type && !expressSetupMode) {
      handleAddBreadcrumb(
        {
          title: 'Subsidiaries',
          route: ROUTES.SUBSIDIARIES,
        },
      );
      handleAddGroup(
        observingSubsidiary.type, [
          {
            title: observingSubsidiary.name || '',
            route: ROUTES.HOME,
            faviconUrl: observingSubsidiaryData?.logoInfo?.faviconUrl
             || observingSubsidiaryData?.logoInfo?.minimizedLogoUrl,
          },
        ],
      );
    }

    if (controlledBreadcrumbs.length) {
      controlledBreadcrumbs.forEach(handleAddBreadcrumb);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    observingDefaultData,
    observingDefault,
    observingEmployer,
    observingPartner,
    observingDistributor,
    observingSubsidiary,
    controlledBreadcrumbs,
  ]);

  useEffect(() => {
    resetToDefaultBreadcrumbs();
     
  }, [resetToDefaultBreadcrumbs]);

  return {
    ...store,
    breadcrumbsGroup,
    handleAddGroup,
    handleReset,
    handleSetBreadcrumbs,
    controlledBreadcrumbs,
    handleSetGroups,
    resetToDefaultBreadcrumbs,
    handleSetOptions,
    handleAddBreadcrumb,
  };
};
