import { storeCreator } from '@/utils/store/store.utils';

interface State {
  saveErrorModal: boolean;
  submitErrorModal: boolean;
  updateErrorModal: boolean;
  activateModal: boolean;
  activateErrorModal: boolean;
  isEmptySaveModal: boolean;
  isEmptySubmitModal: boolean;
  isPendingOrganizationModal: boolean;
  setSaveErrorModal: (value: boolean) => void;
  setSubmitErrorModal: (value: boolean) => void;
  setUpdateErrorModal: (value: boolean) => void;
  setActivateModal: (value: boolean) => void;
  setActivateErrorModal: (value: boolean) => void;
  setIsEmptySaveModal: (value: boolean) => void;
  setIsEmptySubmitModal: (value: boolean) => void;
  setIsPendingOrganizationModal: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isPendingOrganizationModal: false,
  saveErrorModal: false,
  submitErrorModal: false,
  updateErrorModal: false,
  activateModal: false,
  isEmptySaveModal: false,
  isEmptySubmitModal: false,
  activateErrorModal: false,

  setSaveErrorModal: (value: boolean) => set(() => ({
    saveErrorModal: value,
  })),
  setSubmitErrorModal: (value: boolean) => set(() => ({
    submitErrorModal: value,
  })),
  setUpdateErrorModal: (value: boolean) => set(() => ({
    updateErrorModal: value,
  })),
  setActivateModal: (value: boolean) => set(() => ({
    activateModal: value,
  })),
  setActivateErrorModal: (value: boolean) => set(() => ({
    activateErrorModal: value,
  })),
  setIsEmptySaveModal: (value: boolean) => set(() => ({
    isEmptySaveModal: value,
  })),
  setIsEmptySubmitModal: (value: boolean) => set(() => ({
    isEmptySubmitModal: value,
  })),
  setIsPendingOrganizationModal: (value: boolean) => set(() => ({
    isPendingOrganizationModal: value,
  })),
}));
const useModalType = () => {
  const store = useStore();

  return {
    ...store,
  };
};
export default useModalType;
