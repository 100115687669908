import { baseStoreCreator } from '@/utils/store';

export interface AccountFundingParamsPayload {
  isReplenished: boolean;
  planLevelFunding: boolean;
  omnibus: string;
  accountCreation: string;
  replenishmentBankAccount: string;
  replenishmentMethod: string;
  replenishmentFrequency: string;
  replenishmentDayNumber: string;

  contributionBankAccount: string;
  contributionMethod: string;
  contributionHoldPeriod: string;

  isPreFundCalculated?: boolean;

  replenishmentPercent?: number;
  replenishmentMinValue?: string;
  replenishmentCreepPercent?: number;
  replenishmentCreepMin?: string;
}

export const DEFAULT_VALUES: AccountFundingParamsPayload = {
  isReplenished: false,
  planLevelFunding: false,
  replenishmentBankAccount: '',
  replenishmentMethod: '',
  replenishmentFrequency: '',
  replenishmentDayNumber: '',

  contributionBankAccount: '',
  contributionMethod: '',
  contributionHoldPeriod: '',

  omnibus: '',
  accountCreation: '',

  isPreFundCalculated: false,
  replenishmentPercent: undefined,
  replenishmentMinValue: undefined,
  replenishmentCreepPercent: undefined,
  replenishmentCreepMin: undefined,
};

export const useStore = baseStoreCreator<AccountFundingParamsPayload>(DEFAULT_VALUES);

export default useStore;
