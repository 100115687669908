import React, { useCallback } from 'react';
import { AppButton, Box } from '@common-fe/common-fe';

import { useValidationEmployee } from '../hooks';

interface Props {
  isSubmitted?: boolean;
  isEditMode?: boolean;
  onToggleEdit: (value: boolean) => void;
  onToggleSubmit: (value: boolean) => void;
}
const EmployeeSave: React.FC<Props> = ({
  onToggleEdit, isEditMode,
  onToggleSubmit, isSubmitted,
}) => {
  const { handleValidate: handleSave, isErrors } = useValidationEmployee();
  const handleToggleEditMode = useCallback(() => {
    onToggleEdit(!isEditMode);
  }, [isEditMode, onToggleEdit]);

  const handleValidate = useCallback(async () => {
    onToggleSubmit(true);
    if (isErrors) {
      return;
    }
    try {
      const isSuccess = await handleSave();
      if (isSuccess) {
        onToggleEdit(false);
      }

    // eslint-disable-next-line no-empty
    } catch {}
  }, [handleSave, isErrors, onToggleEdit, onToggleSubmit]);
  return (
    <Box>
      {!isEditMode ? (
        <AppButton
          testId="edit-employee"
          width="140px"
          buttonType="secondary"
          onClick={handleToggleEditMode}
        >
          Edit
        </AppButton>
      ) : (
        <AppButton
          width="140px"
          disabled={isErrors && isSubmitted}
          onClick={handleValidate}
          testId="save-employee"
        >
          Save
        </AppButton>
      )}

    </Box>
  );
};

export default EmployeeSave;
