import { baseStoreCreator } from '@/utils/store';

export interface PayClaimsPayload {
  isPayClaimsAbsence: string;
  isPayClaimsOutsideCoverage: string;
}

export const DEFAULT_VALUES: PayClaimsPayload = {
  isPayClaimsAbsence: '',
  isPayClaimsOutsideCoverage: '',
};

export const useStore = baseStoreCreator<PayClaimsPayload>(DEFAULT_VALUES);

export default useStore;
