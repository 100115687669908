import React from 'react';
import {
  Box,
  Heading, RawButton,
} from '@common-fe/common-fe';

// import { CircleQuestion } from '@common-fe/common-fe';
// import { Button } from '@common-fe/common-fe';

interface Props {
  children?:React.ReactNode;
  title?: string;
  anchorId: string;
  borderLess?: boolean;
  actionLabel?: string;
}
const ModuleWrapper: React.FC<Props> = ({
  children, title, anchorId, borderLess, actionLabel,
}) => (
  <div id={anchorId}>
    <Box direction="column" margin={{ vertical: 'spacing8' }}>

      {
        (title || actionLabel)
          ? (
            <Box
              margin={{ bottom: 'spacing12' }}
              direction="row"
              justify="between"
            >
              {
                title
                && (
                  <Heading fill level={4} size="21px">
                    {title}
                  </Heading>
                )
              }
              {
                actionLabel
                && (
                  <RawButton
                    primary
                    label={actionLabel}
                  />
                )
              }
            </Box>
          )
          : null
      }

      <Box>
        <Box
          direction="column"
          pad={{ bottom: 'spacing12' }}
          border={borderLess ? undefined : { side: 'bottom', color: 'border1' }}
        >
          <Box>
            {/* <Box background="module" pad="spacing24" round="moduleRound"> */}
            <div>
              {children}
            </div>
          </Box>
        </Box>
        {/* </Box> */}
      </Box>

    </Box>
  </div>
);

export default ModuleWrapper;
