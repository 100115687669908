import React from 'react';
import { Box,Text } from '@common-fe/common-fe';

const EmailLogoPickerLabel = () => (
  <Box direction="column" data-testid="email-logo-picker">
    <Box flex={{ grow: 1 }}>
      <Text margin={{ bottom: 'xsmall' }} weight="bold" color="textBody">Email logo</Text>
      <Box>
        <Text weight="normal" size="small" color="textSecondary">
          Recommended size for:
          <br />
          <Text size="small" weight="bold">Rectangle logo format</Text> – 300×50 pixels.
          <br />
          We support: SVG, JPG, PNG, BMP.
        </Text>
      </Box>
    </Box>
  </Box>
);

export default EmailLogoPickerLabel;
