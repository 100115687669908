import React from 'react';
import {
  Box,
  CrossIcon,
  OptionKey,
  PriceInput,
  SelectDropdown,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';
import { Copay } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import { CopayError } from '@/modules/ExpressSetup/hooks/useCopaysFormValidate.ts';

const PriceControl = styled((props) => <PriceInput {...props} />)`
  width: 160px;
  max-height: 40px;
`;

interface CopayFieldProps {
  copayNumber: number;
  copay: Copay;
  currentError?: CopayError;
  total: number;
  onRemove: (id: string) => void;
  handleCategoryChange: (value: OptionKey) => void;
  handleAmountChange: (value: string) => void;
  serviceCategories?: ServiceCategoryTypes[];
  showErrors?: boolean;
}

const CopayField: React.FC<CopayFieldProps> = ({
  copayNumber,
  copay,
  currentError,
  total,
  onRemove,
  handleCategoryChange,
  handleAmountChange,
  serviceCategories,
  showErrors,
}) => {
  return (
    <Box direction="row">
      <SelectDropdown
        inputStyle={{ width: '240px' }}
        id="category-dropdown"
        testId="category-dropdown"
        ellipsisMode
        singleMode
        name="categoryType"
        options={serviceCategories || []}
        value={copay?.categoryType}
        onChange={handleCategoryChange}
        placeholder='Category'
        {...currentError && showErrors ? { errorText: currentError?.categoryType } : {}}
      />
      <Box width={{ max: '160px' }} margin={{ left: 's' }}>
        <PriceControl
          name="amount"
          testId="copay-field"
          value={copay?.amount}
          onChange={handleAmountChange}
          placeholder="$ Amount"
          {...currentError && showErrors ? { errorText: currentError?.amount } : {}}
        />
      </Box>
      <Box
        style={{
          cursor: total === 1 && copayNumber === 1 ? 'default' : 'pointer',
          pointerEvents: total === 1 && copayNumber === 1 ? 'none' : 'auto',
        }}
        align="center"
        onClick={() => onRemove && onRemove(copay?.id || '')}
        margin={{ left: 'm' }}
        pad={{ top: 's' }}
        data-testid="remove-copay-button"
      >
        <CrossIcon color={total === 1 ? 'iconSecondary' : 'iconPrimary'} size="15px" />
      </Box>
    </Box>
  );
};

export default CopayField;