import React, { useMemo } from 'react';
import {
  Box, CategoryIcon, costFormater,
  Text, Tip,  } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import ActivityStatus from '@/modules/transaction/ActivityStatus';
import { Transaction } from '@/modules/transaction/transaction.types';

import {
  Date,
  Wrapper, } from './RecentExpenseItem.styles';

const MAX_PROVIDER_LENGTH = 45;

interface Props {
  data: Transaction;
  onPick?: () => void;
}

export const RecentExpenseItem: React.FC<Props> = ({ data, onPick }) => {
  const provider = useMemo(() => data?.provider || '', [data]);

  return (
    <Wrapper data-testid="RecentExpenseItem-wrapper" onClick={onPick}>
      <Box
        style={{ flex: 1.5 }}
        direction="row"
      >
        <Box width={{ min: 'max-content' }}>
          <CategoryIcon
            size="s"
            category={data.categoryType}
          />
        </Box>
        <Tip
          plain
          dropProps={{ align: { left: 'right' } }}
          content={(provider.length > MAX_PROVIDER_LENGTH
            ? (
              <Box
                width="max-content"
                height={{ max: 'l' }}
                align="center"
                justify="center"
                background="background3"
                border={{ size: 'small', color: 'popup' }}
                pad={{ horizontal: 'spacing6' }}
              >
                <Text size="small" color="textOnColor">{provider}</Text>
              </Box>
            ) : undefined
          )}
        >
          <Text
            size="medium"
            alignSelf="start"
            margin={{ right: 'spacing24', left: 'spacing8', vertical: 'auto' }}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {provider}
          </Text>
        </Tip>
      </Box>

      <Box style={{ flex: 1 }}>
        <Text
          size="medium"
          alignSelf="start"
          margin={{ right: 'spacing24' }}
          wordBreak="break-word"
        >
          {costFormater(data?.requestedAmount, true)}
        </Text>
      </Box>

      <Box style={{ flex: 0.8 }}>
        <Text
          size="medium"
          alignSelf="start"
          margin={{ right: 'spacing24' }}
          wordBreak="break-word"
        >
          {costFormater(data.paidAmount, true)}
        </Text>
      </Box>
      <Box style={{ flex: 1.2, minWidth: '170px' }}>
        <ActivityStatus status={data?.status} />
      </Box>
      <Box data-testid="StatusDate-wrapper">
        <Date>
          {dayjs(data?.statusDate).format(DEFAULT_DATE_FORMAT)}
        </Date>
      </Box>
    </Wrapper>
  );
};
