import { useCallback, useEffect, useMemo, useState } from 'react';
import UTIF, { IFD } from 'utif';

import { Document, DocumentTypeEnum } from '@/common/types';

interface Props {
  url?: string;
}

const useConvertTiff = ({ url }: Props) => {
  const [pngUrls, setPngUrls] = useState<Array<string | undefined>>();
  const [isLoading, setIsLoading] = useState(!!url);

  const decodeTiffDocument = useCallback(async (url?: string) => {
    if (url) {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const decodedTIFFPages = UTIF.decode(arrayBuffer);
      return { arrayBuffer, decodedTIFFPages};
    }
  }, []);

  const convertTiffToPng = useCallback((tiffIndex: number, arrayBuffer?: ArrayBuffer, decodedTIFFPages?: IFD[]) => {
    const currentDecodedTIFFPage = decodedTIFFPages?.[tiffIndex];

    if (arrayBuffer && currentDecodedTIFFPage) {
      UTIF.decodeImage(arrayBuffer, currentDecodedTIFFPage);
      const rgba = UTIF.toRGBA8(currentDecodedTIFFPage);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = currentDecodedTIFFPage.width;
      canvas.height = currentDecodedTIFFPage.height;
      if (ctx) {
        const imageData = ctx.createImageData(currentDecodedTIFFPage.width, currentDecodedTIFFPage.height);
        imageData.data.set(rgba);
        ctx.putImageData(imageData, 0, 0);
        return canvas.toDataURL();
      }
    }
  }, []);

  const getPngUrls = useCallback(async(url?: string) => {
    setIsLoading(true);
    try {
      const decodedTiffDocument = await decodeTiffDocument(url);
      const arrayBuffer = decodedTiffDocument?.arrayBuffer;
      const decodedTIFFPages = decodedTiffDocument?.decodedTIFFPages;
      if (arrayBuffer && decodedTIFFPages) {
        const urls = decodedTIFFPages
          .map((tiff, tiffPageIndex) => convertTiffToPng(tiffPageIndex, arrayBuffer, decodedTIFFPages));
        setPngUrls(urls);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [decodeTiffDocument, convertTiffToPng]);

  useEffect(() => {
    if (url) {
      getPngUrls(url);
    }
  }, [url, getPngUrls]);

  const pngDocuments: Document[] = useMemo(() => {
    if (!pngUrls?.length) {
      return [];
    }

    return pngUrls?.map((pngUrl, index) => ({
      blob: pngUrl || '',
      link: pngUrl || '',
      id: `${index}`,
      type: DocumentTypeEnum.PNG,
    }));
  }, [pngUrls]);

  return {
    pngUrls,
    pngDocuments,
    isLoading,
  };
};

export default useConvertTiff;
