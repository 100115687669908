import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHistory } from '@/modules/core/hooks';
import { PayoutDefinitionSetup } from '@/modules/ProcessingDefinition/PayoutDefinition';
import theme from '@/styles/theme';

const StyledContent = styled(Box)`
  flex: 1;
  align-items: center;
`;

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 12;
`;

const PayoutDefinitionPage = () => {
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_PROCESSING_DEFINITION },
      ]}
      accessDeniedAction={goHome}
    >
      <Box direction="row" overflow="hidden" flex={{ grow: 1 }}>
        <Box
          overflow="auto"
          width="100%"
        >
          <FixedWrapper
            height={{ min: 'fit-content' }}
            background={{ color: 'canvas' }}
          >
            <Topbar />
          </FixedWrapper>
          <Box>
            <StyledContent>
              <Box width={theme.defaultContentWidth}>
                <PayoutDefinitionSetup />
              </Box>
            </StyledContent>
          </Box>
        </Box>
      </Box>
    </Access>
  );
};

export default PayoutDefinitionPage;
