import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import AccountUsage from './BankInformation/AccountUsage';
import BanksList from './BankInformation/BanksList';
import BankInformation from './BankInformation';
import FundingModel from './FundingModel';
import InvoicingDetails from './InvoicingDetails';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const FinancialDetails: React.FC<Props> = ({ onDirty }) => {
  const hasAccessToBankInfo = useHasAccess([
    { permission: Permissions.EDIT_ER_BANK_ACCOUNT_INFO },
  ]);
  const { formattedData: { isTerminated, organizationPath, organizationStatusType } } = useGetEmployer();

  const isEditRestricted = useMemo(() => {
    return isTerminated || organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [isTerminated, organizationStatusType]);

  return (
    <Box direction="column">
      <FundingModel onDirty={onDirty} />
      <InvoicingDetails disabled={isEditRestricted} />
      <Box
        background="canvas"
        round="container1Round"
        elevation="default"
        margin={{ bottom: 'spacing24' }}
        border={{ color: 'border2', size: 'small' }}
      >
        <BankInformation
          disabled={isEditRestricted || !hasAccessToBankInfo}
          organizationPath={organizationPath}
        />
        <Box height="2px" width="100%" background="border2" />
        <BanksList disabled={isEditRestricted || !hasAccessToBankInfo} />
      </Box>
      <Box
        background="canvas"
        round="container1Round"
        elevation="default"
        border={{ color: 'border2', size: 'small' }}
      >
        <AccountUsage onDirty={onDirty} disabled={isEditRestricted || !hasAccessToBankInfo} />
      </Box>
    </Box>
  );
};

export default FinancialDetails;
