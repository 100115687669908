import React from 'react';
import { AlertIcon, Box, Preloader, Text } from '@common-fe/common-fe';

interface Props {
  isAlertVisible: boolean;
  buttonText: string;
  description: string;
  onAction: () => void;
  actionLoading?: boolean;
  testId?: string;
}

export const InvoiceAlertAction: React.FC<Props> = ({
  isAlertVisible,
  buttonText,
  description,
  onAction,
  actionLoading,
  testId = '',
}) => {
  if (!isAlertVisible) {
    return null;
  }

  return (
    <Box
      data-testid={`InvoiceAlertAction_container_${testId}`}
      direction="row"
      height={{ min: '40px' }}
      width="100%"
      pad={{ horizontal: 'spacing12', vertical: 'spacing8' }}
      margin={{ top: 'spacing16' }}
      background="dangerContainer"
      border={{ color: 'dangerBorder' }}
      round="spacing4"
      align="center"
      justify="between"
    >
      <Box direction="row">
        <AlertIcon data-testid="alert-icon" />
        <Text margin={{ left: 'spacing8' }}>
          {description}
        </Text>
      </Box>
      <Box
        onClick={onAction}
        data-testid={`InvoiceAlertAction_button_${testId}`}
      >
        {actionLoading
          ? (
            <Box data-testid="preloader">
              <Preloader />
            </Box>
          ) : (
            <Text weight={700} color="textAccent">
              {buttonText}
            </Text>
          )}

      </Box>
    </Box>
  );
};
