import React, { useCallback } from 'react';

import { ModalWrapper } from '@/components/wrappers';

import { CreateClaimProcessorModalForm } from './CreateClaimProcessorModalForm';

const TITLE = 'Add Claims Processor';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSuccess?: (id?: string) => void;
}

export const CreateClaimProcessorModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSuccess,
}) => {
  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
  }, [
    onSetVisible,
  ]);

  return (
    <ModalWrapper
      testId="CreateClaimProcessorModal"
      visible={visible}
      onSetVisible={handleSetVisible}
      title={TITLE}
    >
      <CreateClaimProcessorModalForm
        onCancel={handleSetVisible}  
        onSuccess={onSuccess}     
      />
    </ModalWrapper>
  );
};
