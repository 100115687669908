import { useEffect, useState } from 'react';
import { useDebounce } from '@common-fe/common-fe';

import { CreateContactPayload } from '@/modules/contact/types/Contact.types';

export default () => {
  const [setup, setSetup] = useState(false);
  const [tempValues, setTempValues] = useState<CreateContactPayload | null>(null);
  const [values, setValues] = useState<CreateContactPayload | null>(null);

  const debouncedValue = useDebounce(tempValues);

  useEffect(() => {
    setValues(debouncedValue);
  }, [debouncedValue]);

  return {
    values,
    setValues: setTempValues,
    setup,
    setSetup,
  };
};
