import React, { useCallback, useMemo, useState } from 'react';
import {
  AppButton, Box, FlexControlledForm, Preloader,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { CustomCoverageLevelPayload } from '../CoverageLevels.constants';

import { useCustomCoverageLevelFields, useHandleCreateCoverageErrors } from './hooks';
import { useCreateCoverageLevelQuery } from './queries';

interface Props {
  onClose: () => void;
}
const CreateCustomCoverageLevel: React.FC<Props> = ({ onClose }) => {
  const { handleSave } = useCreateCoverageLevelQuery();
  const [isLoading, setIsLoading] = useState(false);
  const { handleParseError } = useHandleCreateCoverageErrors();
  const [errors, setErrors] = useState<Partial<CustomCoverageLevelPayload>>({});

  const [values, setValues] = useState<Partial<CustomCoverageLevelPayload>>({});
  const [isErrorShown, setErrorShown] = useState(false);
  const fields = useCustomCoverageLevelFields(errors);
  const validation = useMemo(() => {
    const shape = fields.reduce((result, item) => ({
      ...result,
      [item.name]: item.validator,
    }), {});
    return yup.object().shape(shape);
  }, [fields]);
  const handleSubmit = useCallback(async () => {
    setErrorShown(true);
    try {
      setIsLoading(true);
      await validation.validate(values);
      await handleSave({
        name: values.coverageName || '',
        code: values.coverageCode || '',
      });
      onClose();
    } catch (e) {
      const message = _.get(e, 'response.data.elevate_error_message', '') as string;
      const parsedErrors = handleParseError(message as string, values);
      setErrors(parsedErrors);
      setErrorShown(true);
    } finally {
      setIsLoading(false);
    }
  }, [handleParseError, handleSave, onClose, validation, values]);
  return (
    <Box data-testid="create-custom-coverage-level">
      <Box round="moduleRound" direction="column" background="module" pad="l">
        <Box background="canvas" round="container1Round" pad="l" elevation="default">
          <FlexControlledForm
            testId="create_carrier_config_part_1"
            editMode
            showError={isErrorShown}
            fields={fields}
            onChangeValues={setValues}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ margin: 0 }}
            isThinMode
          />
        </Box>

      </Box>
      <Box>
        <Box direction="row" justify="end">
          <AppButton
            testId="create-custom-coverage-level_cancel"
            buttonType="secondary"
            width="control"
            onClick={onClose}
          >Cancel
          </AppButton>
          <Box margin={{ left: 's' }}>
            <AppButton disabled={isLoading} width="control" onClick={handleSubmit}>
              {isLoading ? (
                <Preloader color="button" />
              ) : 'Create' }
            </AppButton>
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

export default CreateCustomCoverageLevel;
