import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface PlanYearPayload {
  planYear: PlanFieldState;
  planStartDate: PlanFieldState;
  planEndDate: PlanFieldState;
  payoutDefinition: PlanFieldState;
  copayGroup: PlanFieldState;
  priorPlanYear: PlanFieldState;
}

export const DEFAULT_VALUES: PlanYearPayload = {
  planYear: PlanFieldState.Locked,
  planStartDate: PlanFieldState.Locked,
  planEndDate: PlanFieldState.Locked,
  payoutDefinition: PlanFieldState.Locked,
  copayGroup: PlanFieldState.Locked,
  priorPlanYear: PlanFieldState.Locked,
};

export const useStore = baseStoreCreator<PlanYearPayload>(DEFAULT_VALUES);

export default useStore;
