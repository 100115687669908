import { useCallback, useMemo } from 'react';

import { ContactResponseModel } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurity.types';
import {
  AddressPayload,
  BankInfo,
  CardSetupPayload,
  EmployeeIdPayload,
  EmployerFeatures,
  EmployerInformation,
  EmployerPayload,
  FundingModelPayload,
  GeneralInformationPayload,
  HelpInfoPayload,
  SubsidiaryInfo,
} from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { BankAccountCategorie } from '@/modules/employer/hooks/useBankAccountCategories.query';
import { storeCreator } from '@/utils/store/store.utils';

import { EmployerStatus } from '../employer.constants';

export interface Store extends EmployerInformation {
  hasOneContact: boolean;
  loading: boolean;
  bankAccountCategories?: BankAccountCategorie[];
  contactMap: { [contactId: string]: ContactResponseModel };
  subsidiaryMap: { [subsidiaryId: string]: SubsidiaryInfo };
  bankInformationMap: { [id: string]: Partial<BankInfo> },
  clearLists: () => void;
  setLoading(loading: boolean): void;
  setStatus(status: EmployerStatus): void;
  setContact(contact: ContactResponseModel): void;
  setContactMap(map: { [contactId: string]: ContactResponseModel }): void;
  setBankInformation(info: Partial<BankInfo>): void;
  setBankAccountCategories(bankAccountCategories: BankAccountCategorie[]): void;
  setGeneralInfo(data: GeneralInformationPayload | null): void;
  setEmployer(data: EmployerPayload | null): void;
  setAddress(data: AddressPayload | null): void;
  setSubsidiary(data: SubsidiaryInfo): void;
  setCardSetup(setup: CardSetupPayload | null): void;
  setEmployeeId(setup: EmployeeIdPayload | null): void;
  setHelpInfo(setup: HelpInfoPayload | null): void;
  setPrimaryContactId(primaryContactId: string | number | null): void;
  setFeatures(data: Partial<EmployerFeatures> | null): void;
  setFundingModel(modal: Partial<FundingModelPayload>): void;
}

export const useStore = storeCreator<Store>((set) => ({
  hasOneContact: false,
  loading: false,
  contactMap: {},
  subsidiaryMap: {},
  bankInformationMap: {},
  bankAccountCategories: [],
  generalInfo: null,
  address: null,
  employer: null,
  primaryContactId: null,
  cardsSetup: null,
  employeeId: null,
  helpInfo: null,
  features: null,
  fundingModel: {
    checkWriting: '',
    electronicFundingReplenishment: '',
  },

  setCardSetup: (setup) => set((state) => ({
    cardsSetup: {
      ...state.cardsSetup,
      ...setup,
    },
  })),
  setFundingModel: (model) => set((state) => ({
    fundingModel: {
      ...state.fundingModel,
      ...model,
    },
  })),
  setLoading: (loading) => set({ loading }),
  setStatus: (status) => set(() => ({
    status,
  })),
  setEmployeeId: (setup) => set((state) => ({
    employeeId: {
      ...state.employeeId,
      ...setup,
    },
  })),
  setHelpInfo: (setup) => set((state) => ({
    helpInfo: {
      ...state.helpInfo,
      ...setup,
    },
  })),
  clearLists: () => set({
    cardsSetup: {
      cardOffered: true,
      allowDependentCard: false,
      numberOfDaysBeforePlanStart: '',
      bin: '',
      prin: '',
      types: [],
      fourthLine: '',
      cardProcessingStartDate: undefined,
    },
    employeeId: {},
    fundingModel: {},
    subsidiaryList: [],
    banksList: [],
    contactMap: {},
    hasOneContact: false,
    subsidiaryMap: {},
    bankInformationMap: {},
  }),
  setPrimaryContactId: (primaryContactId) => set({
    primaryContactId,
  }),
  setContact: (contact) => set((state) => ({
    contactMap: {
      ...state.contactMap,
      [contact.id]: contact,
    },
  })),
  setContactMap: (contactMap) => set(() => ({
    contactMap,
    hasOneContact: Boolean(Object.keys(contactMap).length),
  })),
  setBankInformation: (info) => set((state) => ({
    bankInformationMap: {
      ...state.bankInformationMap,
      ...info.id ? { [info.id]: info } : null,
    },
  })),
  setBankAccountCategories: (bankAccountCategories) => set((state) => ({
    ...state,
    bankAccountCategories,
  })),
  setGeneralInfo: (generalInfo) => set((state) => ({
    ...state,
    generalInfo,
  })),
  setAddress: (address) => set((state) => ({
    ...state,
    address,
  })),
  setEmployer: (employer) => set((state) => ({
    ...state,
    employer,
  })),
  setSubsidiary: (subsidiary) => set((state) => ({
    subsidiaryMap: {
      ...state.subsidiaryMap,
      ...subsidiary.id ? { [subsidiary.id]: subsidiary } : null,
    },
  })),
  setFeatures: (features) => set((state) => ({
    ...state,
    features: {
      options: features?.options,
      ...features,
    },
  })),
}));

export const useSetupEmployerStore = () => {
  const store = useStore();
  const handlesSetAll = useCallback((value: EmployerInformation) => {
    store.clearLists();
    store.setStatus(value.status || EmployerStatus.Null);
    store.setGeneralInfo(value.generalInfo);
    store.setAddress(value.address);
    store.setEmployer(value.employer);
    store.setPrimaryContactId(value.primaryContactId);

    store.setCardSetup(value.cardsSetup);
    store.setFundingModel(value.fundingModel || {
      checkWriting: undefined,
      electronicFundingReplenishment: undefined,
    });

    store.setEmployeeId(value.employeeId);
    store.setHelpInfo(value.helpInfo);
    store.setFeatures(value.features);

    value.banksList?.forEach((bank) => {
      store.setBankInformation(bank || {});
    });
    value.subsidiaryList?.forEach((subsidiary) => {
      store.setSubsidiary(subsidiary || {});
    });
  }, [store]);

  const setStatus = useCallback((status: EmployerStatus) => {
    store.setStatus(status);
  }, [store]);

  return useMemo(() => ({
    ...store,
    contactList: Object.values(store.contactMap),
    subsidiaryList: Object.values(store.subsidiaryMap),
    banksList: Object.values(store.bankInformationMap),
    handlesSetAll,
    setStatus,
  }), [handlesSetAll, store, setStatus]);
};
