import React, { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs, useHistory } from '@/modules/core/hooks';
import PartnersList from '@/modules/employer/components/Partners/PartnersList/PartnersList';
import TopSection from '@/modules/employer/components/Partners/TopSection';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 0;
`;

type PageMode = OrganizationTypes.partner
| OrganizationTypes.distributor
| OrganizationTypes.subsidiary;

const getPageMode = (path: string): PageMode => {
  switch (path) {
  case ROUTES.DISTRIBUTORS: return OrganizationTypes.distributor;
  case ROUTES.SUBSIDIARIES: return OrganizationTypes.subsidiary;
  default: return OrganizationTypes.partner;
  }
};

const TITLE = {
  [OrganizationTypes.partner]: 'Partners',
  [OrganizationTypes.distributor]: 'Distributors',
  [OrganizationTypes.subsidiary]: 'Subsidiaries',
};
const ACCESS_REQUIREMENTS = {
  [OrganizationTypes.partner]: [
    { organizationType: OrganizationTypes.partner, isException: true },
    { organizationType: OrganizationTypes.distributor, isException: true },
    { organizationType: OrganizationTypes.company, isException: true },
    { organizationType: OrganizationTypes.subsidiary, isException: true },
  ],
  [OrganizationTypes.distributor]: [
    { organizationType: OrganizationTypes.distributor, isException: true },
    { organizationType: OrganizationTypes.company, isException: true },
    { organizationType: OrganizationTypes.subsidiary, isException: true },
  ],
  [OrganizationTypes.subsidiary]: [
    { organizationType: OrganizationTypes.subsidiary, isException: true },
  ],
};

const OrganizationsPage = () => {
  const { path } = useRouteMatch();
  const pageMode = useMemo(() => getPageMode(path), [path]);
  const title = useMemo(() => TITLE[pageMode], [pageMode]);
  const accessRequirements = useMemo(() => ACCESS_REQUIREMENTS[pageMode], [pageMode]);
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);

  useBreadcrumbs([{ title }]);
  return (
    <Access
      accessRequirements={accessRequirements}
      accessDeniedAction={goHome}
    >
      <Wrapper>
        <Box align="center">
          <Box width="100%">

            <Topbar />

          </Box>
          <Box width={theme.defaultContentWidth} margin={{ top: 'medium' }} pad={{ bottom: 'spacing72' }}>
            <TopSection title={title} mode={pageMode} />

            <PartnersList mode={pageMode} />
          </Box>
        </Box>
      </Wrapper>
    </Access>
  );
};

export default OrganizationsPage;
