import React, { useMemo } from 'react';
import { Box, Field, FieldStateTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { HEALTH_PLAN_SETUP_FIELD } from '../HealthPlanSetup.constants';
import HealthTemplateStateField from '../TemplateStateField';

import useHealthPlanSetupFieldsSetter from './useHealthPlanSetupFieldsSetter';
import useHealthPlanSetupFieldsSourceState from './useHealthPlanSetupFieldsSourceState';
import useHealthPlanSetupFieldsState from './useHealthPlanSetupFieldsState';
import useParentId from './useParentId';

const BLOCKED_FIELDS = ['parentTemplate', 'carrierId'];

const useTemplateFieldState = (
  fields: Field[], isTemplateMode?: boolean, isEditMode?: boolean, coverageId?: string, uniqStateNameField?: string
) => {
  const parentId = useParentId();
  const isParentIdExists = useMemo(() => !!parentId, [parentId]);
  const sourceState = useHealthPlanSetupFieldsSourceState();
  const state = useHealthPlanSetupFieldsState();

  const { handleSetState } = useHealthPlanSetupFieldsSetter();

  const isMinimized = useMemo(() => !isTemplateMode || !isEditMode, [isEditMode, isTemplateMode]);

  const getModifiedFields = (currentFields: Field[]) : Field[] => currentFields.map((field) => {
    const currentState = coverageId
      ? state.coverageLevels.find((item) => item.coverageId === coverageId) || state : state;

    const currentSourceState = coverageId
      ? sourceState.coverageLevels.find((item) => item.coverageId === coverageId) || sourceState
      : sourceState;

    const currentValue = _.get(currentState, `${field.name}`) as FieldStateTypes;
    const sourceValue = _.get(currentSourceState, `${field.name}`) as FieldStateTypes;
    const sourceDisabled = sourceValue === FieldStateTypes.Locked
    || sourceValue === FieldStateTypes.Hidden;
    const appliedDisabling = field.name === HEALTH_PLAN_SETUP_FIELD.PLAN_SUMMARY || (isParentIdExists
      ? sourceDisabled
      : BLOCKED_FIELDS.includes(field.name) && sourceDisabled);

    if (uniqStateNameField && field.name === uniqStateNameField) {
      return (
        {
          ...field,

          preLabelNode: (
            <Box width={{ min: '50px' }}>
              <HealthTemplateStateField
                testId={`${field.name}_state`}
                minimized={isMinimized}
                value={currentValue}
                disabled={appliedDisabling}
                onChangeValue={(value) => handleSetState({
                  [field.name]: value,
                }, coverageId)}
              />
            </Box>),
        }
      );
    }
    if (field.subFields && field.label && !uniqStateNameField) {
      return (
        {
          ...field,
          subFields: getModifiedFields(field.subFields),
          preLabelNode: (
            <Box width={{ min: '50px' }}>
              <HealthTemplateStateField
                testId={`${field.name}_state`}
                minimized={isMinimized}
                disabled={appliedDisabling}
                value={currentValue}
                onChangeValue={(value) => handleSetState({
                  [field.name]: value,
                }, coverageId)}
              />
            </Box>)
          ,
        }
      );
    }
    if (field.label && !uniqStateNameField) {
      return (
        {
          ...field,

          preLabelNode: (
            <Box width={{ min: '50px' }}>
              <HealthTemplateStateField
                testId={`${field.name}_state`}
                minimized={isMinimized}
                value={currentValue}
                disabled={appliedDisabling}
                onChangeValue={(value) => handleSetState({
                  [field.name]: value,
                }, coverageId)}
              />
            </Box>),
        }
      );
    }
    return ({
      ...field,
      preLabelNode: (
        <Box width="50px" />
      ),
    });
  });
  return getModifiedFields(fields);
};

export default useTemplateFieldState;
