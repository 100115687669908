import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'useSubsidiaries';
const ACTIVE_STATUS = 'ACTIVE';

export interface SubsidiaryPayload {
  id: number,
  short_name: string,
  external_identifier: string,
  name: string,
  parent_id: number,
  organization_status_type: string,
  valid_from: string,
  path: string,
  logo_info: {
    large_logo_url: string,
    minimized_logo_url: string,
    favicon_url: string,
  }
}

export interface Subsidiary {
  id: string,
  shortName: string,
  externalIdentifier: string,
  name: string,
  parentId: string,
  organizationStatusType: string,
  validFrom: string,
  path: string,
  logoInfo: {
    largeLogoUrl: string,
    minimizedLogoUrl: string,
    faviconUrl: string,
  }
}

const useSubsidiariesQuery = (withAllStatuses?: boolean) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [QUERY_KEY],
    () => api.get(PATHS.ORGANIZATIONS, {
      params: {
        type: OrganizationTypes.subsidiary,
        ...withAllStatuses ? {} : { statuses: ACTIVE_STATUS },
        size: 100,
      },
    }),
  );

  const formattedData: Subsidiary[] = useMemo(() => data?.data?.content?.map(
    (subsidiary: SubsidiaryPayload): Subsidiary => ({
      id: toString(subsidiary.id),
      shortName: subsidiary.short_name,
      externalIdentifier: subsidiary.external_identifier,
      name: subsidiary.name,
      parentId: toString(subsidiary.parent_id),
      organizationStatusType: subsidiary.organization_status_type,
      validFrom: subsidiary.valid_from,
      path: subsidiary.path,
      logoInfo: {
        largeLogoUrl: subsidiary.logo_info.large_logo_url,
        minimizedLogoUrl: subsidiary.logo_info.minimized_logo_url,
        faviconUrl: subsidiary.logo_info.favicon_url,
      },
    }),
  ), [data]);

  return {
    formattedData,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useSubsidiariesQuery;
