import React, { useEffect, useMemo, useRef,useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Preloader } from '@common-fe/common-fe';

import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import useContactStore from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import { PlanStatuses } from '@/modules/plan/plan.types.ts';
import { useTemplatesListQuery } from '@/modules/plan/PlansList/queries/useTemplatesList.query.ts';
import theme from '@/styles/theme';

import { ExpressSetupWizardStepTitle, ExpressSetupWizardStepType } from '../ExpressSetup.types';
import ExpressSetupTopbar from '../ExpressSetupTopbar';
import { useCurrentExpressSetup } from '../hooks';
import useExpressSetupSetter from '../hooks/useExpressSetupSetter';
import ProgressBar from '../ProgressBar';

import BankAccountWizard from './BankAccountWizard';
import MainContactWizard from './MainContactWizard';
import NavigationButton from './NavigationButton';
import PlansWizard from './PlansWizard';

export const steps = [
  ExpressSetupWizardStepType.MainContact,
  ExpressSetupWizardStepType.BankAccount,
  ExpressSetupWizardStepType.Plans,
] as ExpressSetupWizardStepType[];

export const EXPRESS_SETUP_WIZARD_CONTENT_WIDTH = '648px'; 

const ExpressSetupWizard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { searchQuery, hash } = useHistory();
  const editModeRef = useRef(false);
  const query = useMemo(() => new URLSearchParams(location.search)?.get(QueryKeys?.EDIT_MODE),[location.search]);
  const handleSetSourceState = useContactStore((state) => state.setSourceState); 

  const { handleReset } = useExpressSetupSetter();

  const { data: expressSetupData, isLoading } = useCurrentExpressSetup();
  const { list: serviceCategories } = useServiceCategoriesQuery();
  const {
    data: activeTemplates,
    isLoading: isTemplatesLoading,
  } = useTemplatesListQuery(undefined, PlanStatuses.ACTIVE, undefined, expressSetupData?.orgPath);

  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [isEditMode, setIsEditMode] = useState(false);
  const setStepName = (stepName: ExpressSetupWizardStepType) => {
    setCurrentStep(stepName);
  };

  const stepNameTitle = useMemo(() => {
    switch(currentStep) {
    case ExpressSetupWizardStepType.MainContact:
      return ExpressSetupWizardStepTitle.main_contact;
    case ExpressSetupWizardStepType.BankAccount:
      return ExpressSetupWizardStepTitle.bank_account;
    default:
      return ExpressSetupWizardStepTitle.main_contact;
    }
  }, [currentStep]);

  const MainWizardComponent = useMemo(() => {
    if (currentStep === ExpressSetupWizardStepType.BankAccount) {
      return (
        <BankAccountWizard
          title={stepNameTitle}
          isEditMode={isEditMode}
          onEditToggle={setIsEditMode}
          accountNumber={expressSetupData?.accountNumber}
        />
      );
    } else if (currentStep === ExpressSetupWizardStepType.Plans) {
      return (
        <PlansWizard
          isEditMode={isEditMode}
          onEditToggle={setIsEditMode}
          activeTemplates={activeTemplates}
          isTemplatesLoading={isTemplatesLoading}
          orgName={expressSetupData?.name}
          isPlansExist={!!expressSetupData?.plans?.length}
          serviceCategories={serviceCategories}
          defaultCopays={expressSetupData?.copays}
          expressSetupData={expressSetupData}
        />
      );
    }
    return (
      <MainContactWizard
        isEditMode={isEditMode}
        title={stepNameTitle}
        contact={expressSetupData?.mainContact}
        onEditToggle={setIsEditMode}
        orgId={expressSetupData?.orgId}
      />
    );
  }, [currentStep, isEditMode, stepNameTitle, expressSetupData, serviceCategories, activeTemplates, isTemplatesLoading]);

  useEffect(() => {
    if (query) {
      setIsEditMode(true);
      editModeRef.current = true;
      history.deleteQueryParameter([QueryKeys?.EDIT_MODE]);
    } else {
      if (!editModeRef.current) {
        setIsEditMode(false);
      }
      editModeRef.current = false;
    }

    switch(hash) {
    case ExpressSetupWizardStepType.MainContact:
      setStepName(ExpressSetupWizardStepType.MainContact);
      break;
    case ExpressSetupWizardStepType.BankAccount:
      setStepName(ExpressSetupWizardStepType.BankAccount);
      break;
    case ExpressSetupWizardStepType.Plans:
      setStepName(ExpressSetupWizardStepType.Plans);
      break;
    default:
      setStepName(ExpressSetupWizardStepType.MainContact);
    }
  }, [hash, query, history]);

  useEffect(() => () => {
    handleReset();
  }, [handleReset]);

  useEffect(() => {
    if(expressSetupData?.mainContact) {
      handleSetSourceState(expressSetupData?.mainContact);
    }
  }, [expressSetupData?.mainContact, handleSetSourceState]);

  if (isLoading) {
    return <Preloader testId="preloader" />;
  }
  
  return (
    <Box
      width="100%"
      data-testid="express-setup-wizard-wrapper"
      margin={{ bottom: 'l' }}
      height="fit-content"
    >
      <ExpressSetupTopbar
        logo={expressSetupData?.logo}
        orgId={expressSetupData?.orgId}
      />
      <Box width={theme.defaultMinContentWidth} alignSelf="center" justify='between' direction="row" margin="l">
        <Box width={EXPRESS_SETUP_WIZARD_CONTENT_WIDTH} margin={{ right: 'l' }}>
          {MainWizardComponent}
          <Box direction="row" margin={{ top: 'l' }}>
            {currentStep !== steps[0] && (
              <NavigationButton
                searchQuery={searchQuery}
                currentStep={currentStep}
                isDisabled={isEditMode}
              />
            )}
            {currentStep !== steps[steps.length - 1] && (
              <NavigationButton
                isNextButton
                searchQuery={searchQuery}
                currentStep={currentStep}
                isDisabled={isEditMode}
              />
            )}
          </Box>
        </Box>
        <Box margin={{ top: 'l' }} width="304px">
          <ProgressBar
            expressSetupData={expressSetupData}
            stepName={currentStep}
            isDisabled={isEditMode}
          />
          {/* TODO: Add hint for progress saved when back will be ready */}
          {/* <Box>
            <Hint
              hintAlign="vertical"
              hintElement={(
                <Text textAlign="center" color="textDisabled">Progress saved</Text>
              )}
              marginContent="m"
            >
              <Text weight={500} size="12px" textAlign="center">We automatically save all your progress.</Text>
            </Hint>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ExpressSetupWizard;
