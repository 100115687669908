import { baseStoreCreator } from '@/utils/store';

export interface BankAccountPayload {
  bankAccountName: string;
  accountNumber: string;
  routingTransitNumber: string;
  accountCategory: string;
  encryptedNumber: string;
}


export const DEFAULT_VALUES: BankAccountPayload = {
  bankAccountName: '',
  accountNumber: '',
  routingTransitNumber: '',
  accountCategory: '',
  encryptedNumber: '',
};

export const useBankAccountStore = baseStoreCreator<BankAccountPayload>(DEFAULT_VALUES);

export default useBankAccountStore;
