// import { useCallback } from 'react';
import { useMemo } from 'react';

import { HealthPlanDeductibleConfig } from '@/modules/HealthPlan/HealthPlan.types';

import { CoverageLevelPayload } from '../CoverageLevels.constants';
import { useCoverageLevelsQuery } from '../queries';

export default (values?: HealthPlanDeductibleConfig[]) => {
  const { data: coverageLevels } = useCoverageLevelsQuery();

  const formatedData: CoverageLevelPayload[] = useMemo(() => {
    if (values) {
      const newValues = values.map((value) => {
        const currentItem = coverageLevels?.find((item) => item.id === `${value.coverage?.id}`);
        return ({
          id: `${value.id}`,
          name: `${value.coverage?.name}`,
          coverageId: `${value.coverage?.id}`,
          parentId: `${value.parent_id}`,
          title: currentItem?.name || '',
          coverageCode: `${value?.code?.code || ''}`,
          planDeductible: `${value?.plan_amount?.plan_amount || ''}`,
          memberDeductible: `${value?.member_amount?.member_amount || ''}`,
          embeddedAmount: `${value?.embedded_amount?.embedded_amount || ''}`,

          isDeletable: !value.coverage?.is_default,
          accountFundingConfigs: value.auto_enroll_account_funding_configs?.map((item) => ({
            id: `${item.id || ''}`,
            planId: `${item.plan_id || ''}`,
            planName: item.plan_name,
            autoEnrollmentAmount: `${item.auto_enrollment_amount || ''}`,
          })),
        });
      });

      return newValues;
    }
    return [];
  }, [coverageLevels, values]);
  return formatedData;
};
