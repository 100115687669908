import { css } from 'styled-components';

import themeColors from '@/styles/colors';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import theme from './theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepFreeze = (obj: any) => {
  Object.keys(obj).forEach(
    (key) => key && isObject(obj[key]) && Object.freeze(obj[key]),
  );
  return Object.freeze(obj);
};

export const colors = {
  border: themeColors.border1,
  brand: themeColors.button,
};

export const elevate = deepFreeze({
  global: {
    edgeSize: {
      none: '0px',
      ...theme.spacings,
      ...theme.rounds,
    },
    borderSize: {
      xsmall: '0.5px',
      small: '1px',
      medium: '1.5px',
      large: '2px',
      xlarge: '24px',
    },
    colors: {
      text: themeColors.textBody,
      background: themeColors.canvas,
      brand: colors.brand,
      control: {
        dark: colors.brand,
        light: colors.brand,
      },
      focus: 'transparent',
      ...themeColors,
    },
    size: {
      ...theme.spacings,
      xxxsmall: '40px',
      xxsmall: '48px',
      xsmall: '96px',
      small: '200px',
      xregular: '180px',
      regular: '250px',
      xmedium: '280px',
      medium: '320px',
      slarge: '420px',
      large: '640px',
      xlarge: '1152px',
      xxlarge: '1536px',
      full: '100%',
      smallItemHeight: '64px',
      itemHeight: '74px',
      control: '160px',
      dateControl: '140px',

      smallControl: '120px',
    },
    drop: {
      zIndex: 2000,
    },
    input: {
      font: {
        weight: 400,

      },
      extend: css`
        font-size: 14px;
        line-height: 21px;
        height: 40px;
        border: solid 1px ${colors.border};
        border-radius: 8px;
        &::placeholder {
          color: ${themeColors.textSecondary};
        }
      `,
    },
    font: {
      family: whiteLabelingConfig?.font || '\'Inter\', sans-serif',
 
    },
    elevation: {
      light: {
        default: theme.shadows.default['box-shadow'],
        hover: theme.shadows.hover['box-shadow'],
        active: theme.shadows.active['box-shadow'],
        highlighted: theme.shadows.highlighted['box-shadow'],
        warning: theme.shadows.warning['box-shadow'],
      },
    },
  },
  accordion: {
    border: undefined,
    icons: {
      collapse: null,
      expand: null,
      color: themeColors.iconPrimary,
    },
  },
  margin: {
    small: {
      size: '8px',
    },
  },
  radioButton: {
    container: {
      extend: css`
        input[type=radio]:not(checked) + div {
          border-color: ${themeColors.border};
        }
        &:hover input:not([disabled]) + div, 
        &:hover input:not([disabled]) + span {
          border-color: ${themeColors.accentBorder};
          background-color: ${themeColors.accentContainer};
        }
        align-items: start;
        position: relative;
        opacity: 1;
      `,
    },
    extend: css`
      input[type=radio]:disabled + div {
        border-color: ${themeColors.border};
        background: ${themeColors.border1};
        svg {
          fill: ${themeColors.canvas};
        }
      }
      input[type=radio]:disabled:checked + div {
        border-color: ${themeColors.border};
        background: ${themeColors.border};
      }

      input[type=radio]:checked:not([disabled]) + div {
        border-color: ${themeColors.accentActive};
        svg {
          fill: ${themeColors.accentActive};
        }
      }
    `,
  },
  checkBox: {
    color: themeColors.canvas,
    extend: css`
    border-radius: 6px;
    opacity: 1;
    input ~ div {
      box-shadow: none !important;
      background: ${themeColors.accentActive};
      border-radius: 6px;
      border-color: ${themeColors.danger} !important;
    }
    span {
      font-size: 12px;
    }
    input:not([disabled, checked]) + div,
    input + div {
      border-color: ${themeColors.border} !important;
    }
    input:checked + div {
      border-color: ${themeColors.accentActive} !important;
    }
    &:hover {
      input + div {
        border-color: ${themeColors.accentBorder} !important;
      }
      input[type=checkbox]:not(disabled):not(:checked) {
        & + div {
          background: ${themeColors.accentContainer};
        }
      }
      input:checked + div {
        border-color: ${themeColors.accentActive} !important;
      }
    }
    
    input[type=checkbox]:disabled + div,
    input + div {
        border-color: ${themeColors.border} !important;
    }
      input[type=checkbox]:disabled:not(:checked) + div,
      input + div {
        border-color: ${themeColors.border} !important;
      }
      
     input[type=checkbox]:disabled {
      & + div {
        background: ${themeColors.border};
      }
     }

     input[type=checkbox]:not(:checked) {
     input[type=checkbox]:not(:disabled) {
        &:hover + div {
          background: ${themeColors.accentContainer};
          border-color: ${themeColors.accentBorder} !important;
        }, 
     }
        &:focus + div,
        & + div {
          background: ${'#fff'};
          border-color: ${colors.brand};
      }
      }

      input[type=checkbox]:not(:checked):disabled {
          & + div {
            background: ${themeColors.border1} !important;
        }
      }
  `,
  },
  text: {
    font: {
      family: whiteLabelingConfig?.font || '\'Inter\', sans-serif',
    },
    xsmall: {
      size: '10px',
      height: '18px',
      maxWidth: '288px',
    },
    small: {
      size: '12px',
      height: '20px',
      maxWidth: '336px',
    },
    medium: {
      size: '14px',
      height: '24px',
      maxWidth: '432px',
    },
    large: {
      size: '16px',
      height: '28px',
      maxWidth: '528px',
    },
    xlarge: {
      size: '18px',
      height: '27px',
      maxWidth: '624px',
    },
    xxlarge: {
      size: '20px',
      height: '32px',
      maxWidth: '816px',
    },
    '2xl': {
      size: '24px',
      height: '36px',
      maxWidth: '816px',
    },
    '3xl': {
      size: '32px',
      height: '48px',
      maxWidth: '1008px',
    },
    '4xl': {
      size: '40px',
      height: '60px',
      maxWidth: '1296px',
    },
    '5xl': {
      size: '48px',
      height: '72px',
      maxWidth: '1680px',
    },
    '6xl': {
      size: '56px',
      height: '67px',
      maxWidth: '2160px',
    },
    '7xl': {
      size: '64px',
      height: '96px',
      maxWidth: '2160px',
    },
    '8xl': {
      size: '80px',
      height: '120px',
      maxWidth: '2160px',
    },
    ...whiteLabelingConfig?.fonts?.sizes,
  },
  heading: {
    2: {
      font: {
        weight: '500',
      },
      small: {
        size: '32px',
        height: '48px',
        maxWidth: '1008px',
      },
    },
    level: {
      1: {
        font: {
          family: whiteLabelingConfig?.titleFont || '',
          weight: '700',
        },
        regular: {
          size: '32px',
          height: '44px',
          ...whiteLabelingConfig?.fonts?.sizes?.xxlarge,
        },
      },
      3: {
        font: {
          weight: '500',
        },
        small: {
          size: '20px',
          height: '31.5px',
          maxWidth: '528px',
        },
      },
      4: {
        font: {
          weight: '700',
        },
        small: {
          size: '20px',
          height: '31.5px',
          maxWidth: '528px',
        },
        medium: {
          size: '24px',
          height: '31.5px',
          maxWidth: '528px',
          ...whiteLabelingConfig?.fonts?.sizes?.['2xl'],
        },
        large: {
          size: '32px',
          height: '40px',
          maxWidth: '528px',
          ...whiteLabelingConfig?.fonts?.sizes?.xxlarge,
        },
      },
      5: {
        font: {
          weight: '700',
        },
      },
    },
  },
  focus: {
    border: undefined,
  },
  formField: {
    border: null,
    label: {
      size: 'medium',
      weight: 'bold',
      margin: 'none',
    },

  },
  anchor: {
    color: {
      dark: whiteLabelingConfig?.colors?.ancor || themeColors.textAccent,
      light: whiteLabelingConfig?.colors?.ancor || themeColors.textAccent,
    },
    hover: {
      textDecoration: 'none',
      extend: `
        color: ${whiteLabelingConfig?.colors?.ancorHover || themeColors.textAccentHover};

        &:active {
          color:  ${whiteLabelingConfig?.colors?.ancorHover || themeColors.textAccentActive};
        }
      `,

    },
  },
  button: {
    secondary: {
      extend: css`
        min-height: 40px !important;
        height: 40px !important;
        &:hover {
          box-shadow: none !important;
        }
      `,
    },

    primary: {
      padding: {
        horizontal: 'none',
      },

      font: {
        weight: 600,
      },
      extend: css`
        text-align: center;
        height: 40px;
        font-weight: 600;
        &:hover {
          box-shadow: none !important;
        }
      `,

    },

    size: {
      small: {
        pad: {
          horizontal: '6px',
        },
      },
      medium: {

        border: {
          radius: '8px',
        },

      },
      large: {
        size: 'spacing24',
        weight: 'bold',
        border: {
          radius: '8px',
        },
        pad: {
          vertical: '0px',
        },
      },
    },
    extend: css<{ plain: boolean, primary?: boolean, secondary?: boolean, size?: string }>`
        /* text-align: center; */
        font-weight: 600;
        &:hover {
          box-shadow: none;
        }
        
        color: ${({ secondary }) => (secondary ? colors.brand : undefined)};
        ${(props) => !props.plain && 'border-radius: 8px;'}
        `,

  },
  maskedInput: {
    disabled: {
      opacity: 1,
      background: themeColors.danger,
    },
    extend: css`
      border-color:  ${colors.border} !important;
      background-color: ${themeColors.canvas};
        outline: none !important;
      &:disabled {
        background-color: ${themeColors.border1};
        border-color: ${themeColors.border1} !important;
        color: ${themeColors.textDisabled};
        cursor: not-allowed;
      }
      &:focus, &:active {
        outline: none !important;
        border-color: ${themeColors.button} !important;
      }
    `,
  },
  select: {
    control: {
      extend: css`
        border: solid 1px ${colors.border};
        border-radius: 8px;
        background-color: ${themeColors.canvas};
      `,
    },
  },
  table: {
    extend: css`
        thead {
         tr {
           background-color: ${themeColors.legend};
           border-radius: 8px;
           overflow: auto;
           margin-bottom: 10px;
           th {
             border: 1px solid ${themeColors.legend}

           }
         }
        }
      tbody {
        tr {
          th {
            border: 1px solid ${themeColors.legend}
          }
        }
      }
      `,
  },
  layer: {
    extend: css`
      z-index: 1000;
      backdrop-filter: blur(4px);
    `,
    border: {
      radius: '24px',
    },
  },
  icon: {
    size: {
      xsmall: '12px',
      small: '16px',
      medium: '24px',
      large: '32px',
      xlarge: '48px',
      xxlarge: '72px',
    },
  },
  pagination: {
    container: {
      extend: css`
        svg {
          width: 12px;
        }
      `,
    },
    button: {
      active: {
        color: themeColors.textOnColor,
        background: {
          color: whiteLabelingConfig?.colors?.paginationActiveItem || themeColors.accentActive,
        },
      },
      color: 'text-strong',
      extend: css`
        border: 1px solid ${colors.border};
      `,
      background: {
        color: themeColors.canvas,
      },
      hover: {
        background: null,
        extend: css`
          border: 1px solid ${colors.brand};
        `,
      },
      size: {
        small: {
          border: {
            radius: '3px',
            width: '2px',
          },
          pad: {
            vertical: '4px',
            horizontal: '4px',
          },
          font: {
            size: '14px',
            height: '20px',
            maxWidth: '336px',
          },
          height: '30px',
          width: '30px',
        },
        medium: {
          border: {
            radius: '8px',
            width: '1px',
          },
          pad: {
            vertical: '8px',
            horizontal: '8px',
          },
          font: {
            size: '14px',
            height: '24px',
            maxWidth: '432px',
          },
          height: '40px',
          width: '40px',
        },
        large: {
          border: {
            radius: '6px',
            width: '2px',
          },
          pad: {
            vertical: '4px',
            horizontal: '4px',
          },
          font: {
            size: '22px',
            height: '28px',
            maxWidth: '528px',
          },
          height: '48px',
          width: '48px',
        },
      },
    },
  },
});
