import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum } from '@common-fe/common-fe';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option } from '@/common/types';

interface ServiceTypeMap {
  [key: string]: string;
}
interface ServiceTypePayload {
  id: number;
  name: string;
}

export const QUERY_KEY = 'GET_SERVICE_TYPE';
const PHARMACY_TYPE = 'PHARMACY';

export default (
  visible: boolean,
  payoutDefinitionId: string,
  planTypes?: string[],
  deductibleServiceType?: string,
) => {
  const replaceRxWithPharmacyType = useCallback((planTypes: string[]): string[] => {
    return planTypes?.map(type => type === CardTypesEnum.RX ? PHARMACY_TYPE : type);
  }, []);

  const updatedPlanTypes = replaceRxWithPharmacyType(planTypes || []);
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY, payoutDefinitionId, deductibleServiceType, updatedPlanTypes],
    () => api.get<ServiceTypePayload[]>(
      PATHS.SERVICES_TYPE(payoutDefinitionId, deductibleServiceType, updatedPlanTypes),
    ),
    {
      enabled: visible && !!payoutDefinitionId && !!updatedPlanTypes && !!deductibleServiceType,
    },
  );

  const fieldOptions = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => {
      const formatedItem: Option = {
        key: `${item.id || ''}`,
        value: item.name,
        name: item.name,
      };
      return formatedItem;
    });
  }, [data]);
  const fieldsMap = useMemo(() => {
    const fieldMap: ServiceTypeMap = {};
    fieldOptions.forEach((item) => {
      fieldMap[`${item.key}`] = `${item.value}`;
    });
    return fieldMap;
  }, [fieldOptions]);
  return {
    isLoading,
    isSuccess,
    fieldOptions,
    fieldsMap,
  };
};
