import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';

import { useScheduledContributionStore } from '../../stores';
const QUERY_KEY = 'GET_SCHEDULED_CONTRIBUTION_EXPORT';
const useScheduledContributionExportQuery = () => {
  const {observingEmployer, observingSubsidiary} = useCurrentOrganization();
 
  const calendarId = useScheduledContributionStore((state) => state.state.calendarId);
  const taxType = useScheduledContributionStore((state) => state.state.taxType);
  const startDate = useScheduledContributionStore((state) => state.state.startDate);
  const fileName = useMemo(() => {
    const employerName = observingEmployer?.name || '';
    const subsidiaryName = observingSubsidiary?.name || '';
    let name = '';
    if(employerName) {
      name += `${employerName}_`;
    }
    if(subsidiaryName) {
      name += `${subsidiaryName}_`;  
    }
    name +='Scheduled_Contribution'; 
    if(startDate) {
      name += `_${startDate}`;
    }
    return `${name}.csv`.trim();
  }, [observingEmployer?.name, observingSubsidiary?.name, startDate]);
  const { isLoading,  refetch } = useQuery(
    [QUERY_KEY, calendarId, startDate],
    () =>
      api.get(PATHS.SCHEDULED_CONTRIBUTION_EXPORT(calendarId || ''), {
        params: {
          calendarId,
          tax_type: taxType,
          start_date: startDate ? dayjs(startDate, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT) : undefined,
        },
      }),
    {
      retry: 0,
      enabled: false,
    }
  );
  const handleDownload = useCallback(async () => {
    const response = await refetch();
    if (response?.data?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }, [refetch,fileName]);
  return {
    isLoading,
    handleDownload,
    fileName
  };
};

export default useScheduledContributionExportQuery;
