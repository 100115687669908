import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface AdditionalFieldsStatePayload {
  availableBalance: PlanFieldState;
  minimumElectionAmount: PlanFieldState;
  maximumElectionAmount: PlanFieldState;
  proofOfExpense: PlanFieldState;
  CIPRequired: PlanFieldState;
  pendContributionsForCIP: PlanFieldState;
  CMSReportingRequired: PlanFieldState;
  allowInvestments: PlanFieldState;
  catchUpElections: PlanFieldState;
  catchUpAge: PlanFieldState;
  investmentOptionsType: PlanFieldState;
  investmentThreshold: PlanFieldState;
  minimumInvestmentAmount: PlanFieldState;

  aiAdjudicationType: PlanFieldState;
  spendLimit: PlanFieldState;
  spendPeriod: PlanFieldState;
  isOrphaned: PlanFieldState;
  generateStatements: PlanFieldState;
}

export const DEFAULT_VALUES: AdditionalFieldsStatePayload = {
  isOrphaned: PlanFieldState.Modifiable,
  availableBalance: PlanFieldState.Modifiable,
  minimumElectionAmount: PlanFieldState.Modifiable,
  maximumElectionAmount: PlanFieldState.Modifiable,
  proofOfExpense: PlanFieldState.Modifiable,
  CIPRequired: PlanFieldState.Modifiable,
  pendContributionsForCIP: PlanFieldState.Modifiable,
  CMSReportingRequired: PlanFieldState.Modifiable,
  allowInvestments: PlanFieldState.Modifiable,
  catchUpElections: PlanFieldState.Modifiable,
  catchUpAge: PlanFieldState.Modifiable,
  investmentOptionsType: PlanFieldState.Modifiable,
  investmentThreshold: PlanFieldState.Modifiable,
  minimumInvestmentAmount: PlanFieldState.Modifiable,
  generateStatements: PlanFieldState.Modifiable,

  aiAdjudicationType: PlanFieldState.Modifiable,
  spendLimit: PlanFieldState.Modifiable,

  spendPeriod: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<AdditionalFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
