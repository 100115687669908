import React, { useCallback } from 'react';
import { Dayjs } from 'dayjs';

import { ModalWrapper } from '@/components/wrappers';
import { usePayrollCalendarCustomDateModalStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/stores';

import PayrollCalendarCustomDateModalForm from './PayrollCalendarCustomDateModalForm';

interface Props {
  minDate?: string;
  maxDate?: string;
  onPickDates?: (date: Dayjs[]) => void;
}

export const PayrollCalendarCustomDateModal: React.FC<Props> = ({
  minDate,
  maxDate,
  onPickDates,
}) => {
  const handleChangeVisibility = usePayrollCalendarCustomDateModalStore((state) => state.handleChangeVisibility);
  const modalVisible = usePayrollCalendarCustomDateModalStore((state) => state.modalVisible);

  const handleSetVisible = useCallback(() => {
    handleChangeVisibility(false);
  }, [
    handleChangeVisibility,
  ]);

  return (
    <ModalWrapper
      testId="PayrollCalendarCustomDateModal"
      visible={modalVisible}
      onSetVisible={handleSetVisible}
      title="Add Custom Date"
      titleBoxStyle={{ paddingBottom: '12px' }}
    >
      <PayrollCalendarCustomDateModalForm
        minDate={minDate}
        maxDate={maxDate}
        onCancel={handleSetVisible}
        onPickDates={onPickDates}
      />
    </ModalWrapper>
  );
};