import { ReportNestedItemGroup } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

const mergeReportNestedItemGroups = (
  data: ReportNestedItemGroup[],
  keyMap: { [key: string]: boolean } = {},
  depth: number = 0,
): ReportNestedItemGroup[] => data.map((item) => {
  const key = item.key ?? '';
  if (keyMap[key] && item.children && depth < 2) {
    return { ...item, title: '', children: mergeReportNestedItemGroups(item.children, keyMap) };
  }
  if (keyMap[key]) {
    return { ...item, title: '' };
  }
   
  keyMap[key] = true;
  if (item.children && depth < 2) {
    return { ...item, children: mergeReportNestedItemGroups(item.children, keyMap) };
  }
  return item;
});

interface State {
  groups: ReportNestedItemGroup[];
  currentPage: number;
  totalPages: number;
  reportDates: [string | undefined, string | undefined];
  isCurrentLoading: boolean;
  pages: number[];
  handleClear: () => void;
  setReportDates: (reportDates: [string | undefined, string | undefined]) => void;
  setGroups: (groups: ReportNestedItemGroup[]) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalPages: (totalPages: number) => void;
  setIsCurrentLoading: (isCurrentLoading: boolean) => void;
  setPages: (page: number) => void;
}

export const useCardReportStore = storeCreator<State>((set) => ({
  groups: [],
  pages: [],
  currentPage: 0,
  totalPages: 0,
  reportDates: [undefined, undefined],
  isCurrentLoading: false,

  setGroups: (groups: ReportNestedItemGroup[]) => set((state) => ({
    ...state,
    groups: mergeReportNestedItemGroups([...state.groups, ...groups]),
  })),
  setCurrentPage: (currentPage: number) => set((state) => ({
    ...state,
    currentPage,
  })),
  setTotalPages: (totalPages: number) => set((state) => ({
    ...state,
    totalPages,
  })),
  setReportDates: (reportDates: [string | undefined, string | undefined]) => set((state) => ({
    ...state,
    reportDates,
  })),
  setIsCurrentLoading: (isCurrentLoading: boolean) => set((state) => ({
    ...state,
    isCurrentLoading,
  })),
  setPages: (page: number) => set((state) => ({
    ...state,
    pages: [...state.pages, page],
  })),
  handleClear: () => set((state) => ({
    ...state,
    groups: [],
    pages: [],
    currentPage: 0,
    totalPages: 0,
    reportDates: [undefined, undefined],
    isCurrentLoading: false,
  })),
}));
