import React from 'react';
import { Box, CoveragePeriods,Text } from '@common-fe/common-fe';

import CoveragePeriodsTable from '../../Employee/EnrollmentBalances/components/CoveragePeriodsTable';
import EnrollmentStatusesTable from '../../Employee/EnrollmentBalances/components/EnrollmentStatusesTable';
import useGetPolicyStatusesQuery from '../queries/useGetPolicyStatuses.query';

interface Props {
  coveragePeriods: CoveragePeriods[];
  policyId?: string;
}

export const EnrollmentDetailsEnrollmentStatusHistory: React.FC<Props> = ({
  coveragePeriods,
  policyId,
}) => {
  const { statuses } = useGetPolicyStatusesQuery(policyId);
  return (
    <Box direction="row" width="100%" justify="between">
      {!!statuses?.length && (
        <Box
          background="canvas"
          pad={{ bottom: 'xs', horizontal: 'm', top: 'm' }}
          round="small"
          margin={{ right: coveragePeriods?.length ? 'm' : '0' }}
          width="100%"
          height="fit-content"
        >
          <Text
            size="small"
            weight="bold"
            color="textTitle"
            margin={{ bottom: 'xs' }}
          >
            Enrollment status history
          </Text>
          <EnrollmentStatusesTable statuses={statuses} />
        </Box>
      )}
      {!!coveragePeriods?.length && (
        <Box
          background="canvas"
          pad={{ bottom: 'xs', horizontal: 'm', top: 'm' }}
          round="small"
          width="100%"
          height="fit-content"
        >
          <Text
            size="small"
            weight="bold"
            color="textTitle"
            margin={{ bottom: 'xs' }}
          >
            All coverage periods
          </Text>
          <CoveragePeriodsTable coveragePeriods={coveragePeriods} />
        </Box>
      )}
    </Box>
  );
};
