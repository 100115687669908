import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import { Breadcrumbs } from '@/modules/core/components/Topbar/Breadcrumbs/Breadcrumbs';
import { ToolbarSmartSearch } from '@/modules/core/components/Topbar/ToolbarSmartSearch/ToolbarSmartSearch';
import { TopbarEmployerButtons } from '@/modules/core/components/Topbar/TopbarEmployerButtons';
import { useCurrentOrganization } from '@/modules/core/hooks';
import baseTheme from '@/styles/theme';

import ToolbarBlock from './ToolbarBlock';
import ToolbarButtons from './ToolbarButtons';

const StyledBox = styled(Box)`
flex: 1;
`;

interface WrapperProps {
  isSticky?: boolean;
}
const FixedWrapper = styled(Box)<WrapperProps>`
${({ isSticky }) => {
    if (isSticky) {
      return css`
     position: sticky;
     width: 100%auto;
     min-height: fit-content;
  top: 0;
  z-index: 12;
  background-color: ${({ theme }) => theme.colors.canvas} ;
  `;
    }
    return css``;
  }}
 
`;

export interface Props {
  isSticky?: boolean;
  isFull?: boolean;
  hideHeader?: boolean;
  hideBreadcrumbs?: boolean;
  showToolbarButtons?: boolean;
  withPadding?: boolean;
  children?: React.ReactNode;
  testId?: string;
}

const Topbar: React.FC<Props> = ({
  isFull,
  isSticky,
  hideHeader,
  hideBreadcrumbs,
  showToolbarButtons,
  withPadding,
  testId,
  children,
}) => {
  const { path } = useRouteMatch();
  const isPartnerPage = useMemo(() => path === ROUTES.PARTNERS_VIEW()
    || path === ROUTES.PARTNERS_CREATE
    || path === ROUTES.PARTNERS_EDIT(), [path]);
  const isDistributorPage = useMemo(() => path === ROUTES.DISTRIBUTORS_VIEW()
    || path === ROUTES.DISTRIBUTORS_CREATE
    || path === ROUTES.DISTRIBUTORS_EDIT(), [path]);
  const isEmployerPage = useMemo(() => path === ROUTES.EMPLOYERS_VIEW
    || path === ROUTES.EMPLOYERS_CREATE
    || path === ROUTES.EMPLOYERS_EDIT(), [path]);
  const isSubsidiaryPage = useMemo(() => path === ROUTES.SUBSIDIARY_VIEW()
    || path === ROUTES.SUBSIDIARY_CREATE
    || path === ROUTES.SUBSIDIARY_EDIT(), [path]);
  const {
    observingOrganization: { type },
  } = useCurrentOrganization();
  const isEmployer = useMemo(
    () => (type === OrganizationTypes.company), [type],
  );
  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  return (
    <FixedWrapper
      data-testId={isSticky
        ? 'topbar-sticky'
        : ''}
      isSticky={isSticky}
    >
      <Box border={{ size: 'small', color: 'border1', side: 'bottom' }} data-testId="topbar">
        <Box data-testid={testId} align="center" pad={{ horizontal: '40px' }}>
          <Box width={isFull ? '100%' : baseTheme.defaultContentWidth}>
            <Box
              style={{ transition: 'all 0.6s' }}
              height={{ min: (hideHeader) ? '64px' : '72px' }}
              direction="row"
              align="center"
              justify="between"
              pad={{ horizontal: withPadding ? '40px' : '0', vertical: 'spacing8' }}
            >
              <StyledBox direction="row" align="center">
                {hideBreadcrumbs ? null : <Breadcrumbs />}
                {/* {hideBreadcrumbs && (!(isEmployerPage || isPartnerPage || isDistributorPage)) && <ObservingCompanyLogo />} */}
                <Box
                  flex="grow"
                  justify="end"
                  align="center"
                  direction="row"
                  pad={{ right: '40px' }}
                >
                  {showToolbarButtons && !isEmployer && !isSubsidiary && <ToolbarSmartSearch />}
                  {(isEmployerPage || isPartnerPage || isDistributorPage || isSubsidiaryPage)
                    && TopbarEmployerButtons()}

                  {children}
                  {showToolbarButtons && <ToolbarButtons />}
                </Box>
              </StyledBox>
              <Box>
                <ToolbarBlock />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        id="topbar-nest"
        data-testId="topbar-nest"
        margin={{ horizontal: 'auto' }}
        width={baseTheme.defaultContentWidth}
        style={{ position: 'relative' }}
      />
    </FixedWrapper>
  );
};

export default React.memo(Topbar);
