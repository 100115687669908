import React, { useCallback, useEffect, useMemo,useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  FlexListHeader,
  FlexListItem,
  FlexListShowMore,
  ListItem,
  Text,
} from '@common-fe/common-fe';

import { useCurrentPayrollGroupsStore } from '../PayrollGroups/stores';

import { usePayrollCalendarTableItems } from './hooks/usePayrollCalendarTableItems';
import { ConnectToOptions, PayrollCalendarsResponse } from './payrollCalendar.types';
import { PayrollCalendarDeactivationModal } from './PayrollCalendarDeactivationModal';
import { PayrollCalendarModal } from './PayrollCalendarModal';
import { PayrollCalendarWarningModal } from './PayrollCalendarWarningModal';
import { usePayrollCalendarDeactivationModalStore, usePayrollCalendarModalStore, usePayrollCalendarNamesStore, usePayrollCalendarUseStore } from './stores';

const payrollCalendarUseTypesExtractor = (data?: PayrollCalendarsResponse[]) => {
  if (!data || !data.length) return undefined;

  const alreadyUseTypes = Array.from(new Set(data.map((item) => item.group_type)));
  const usedPlans = data.reduce<string[]>((acc, item) => {
    if (item.plans
      && item.plans.length
      && item.group_type === ConnectToOptions.PLAN) {
      const plansIds = item.plans.map((plan) => `${plan.id}`);
      return [...acc, ...plansIds];
    }
    return acc;
  }, []);
  const alreadyUsePlans = Array.from(new Set(usedPlans));
  const usedGroups = data.reduce<string[]>((acc, item) => {
    if (item.payroll_groups
      && item.payroll_groups.length
      && item.group_type === ConnectToOptions.GROUP) {
      const groupsIds = item.payroll_groups.map((group) => `${group.id}`);
      return [...acc, ...groupsIds];
    }
    return acc;
  }, []);
  const alreadyUseGroups = Array.from(new Set(usedGroups));
  return {
    alreadyUseTypes,
    alreadyUsePlans,
    alreadyUseGroups,
  };
};

const PER_PAGE = 10;
const PayrollCalendarTable = () => {
  const { id } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(false);
  const {
    headers,
    data,
    formattedList,
    refetch,
  } = usePayrollCalendarTableItems(id);
  
  const handleChangeVisibility = usePayrollCalendarModalStore((state) => state.handleChangeVisibility);
  const handleSetEditMode = usePayrollCalendarModalStore((state) => state.handleSetEditMode);
  const handleSetEditableId = usePayrollCalendarModalStore((state) => state.handleSetEditableId);
  const handleSetAlreadyUseTypes = usePayrollCalendarUseStore((state) => state.handleSetAlreadyUseTypes);
  const handleSetUsedPlans = usePayrollCalendarUseStore((state) => state.handleSetUsedPlans);
  const handleSetUsedGroups = usePayrollCalendarUseStore((state) => state.handleSetUsedGroups);
  const handleSetCalendarNames = usePayrollCalendarNamesStore((state) => state.handleSetCalendarNames);
  const handleReset = usePayrollCalendarUseStore((state) => state.handleReset);
  const handleSetAdditionalAction = useCurrentPayrollGroupsStore((state) => state.handleSetAdditionalAction);

  const handleSetState = usePayrollCalendarDeactivationModalStore((store) => store.handleSetState);

  const showedList = useMemo(() => {
    return showMore ? formattedList : formattedList.slice(0, PER_PAGE);
  }, [showMore, formattedList]);
  
  const moreItemsCount = useMemo(() => 
    formattedList.length - showedList.length, 
  [formattedList.length, showedList.length]);

  const handleDeactivate = useCallback((id: string) => {
    const foundCalendar = data?.find((item) => `${item.id}` === `${id}`); 
    const calendarName = foundCalendar?.name;


    handleSetState({
      calendarName: calendarName || '',
      calendarId: id,
      modalVisible: true,
      isPayrollGroupsPresent:!!foundCalendar?.payroll_groups?.length,
      isPlansPresent:!!foundCalendar?.plans?.length,
    });

  }, [data, handleSetState]);

  const handleEdit = useCallback((id: string) => {
    if (handleSetEditableId) handleSetEditableId(id);
    if (handleSetEditMode) handleSetEditMode(true);
    if (handleChangeVisibility) handleChangeVisibility(true);
  }, [
    handleChangeVisibility,
    handleSetEditMode,
    handleSetEditableId,
  ]);

  useEffect(() => {
    handleReset();
    handleSetAdditionalAction(refetch);
    const extracted = payrollCalendarUseTypesExtractor(data);
    if (extracted?.alreadyUseGroups) handleSetAlreadyUseTypes(extracted.alreadyUseTypes);
    if (extracted?.alreadyUsePlans) handleSetUsedPlans(extracted.alreadyUsePlans);
    if (extracted?.alreadyUseGroups) handleSetUsedGroups(extracted.alreadyUseGroups);
    if (handleSetCalendarNames) handleSetCalendarNames(data?.map((item) => item.name) || []);
  }, [
    data,
    handleSetAlreadyUseTypes,
    handleSetUsedPlans,
    handleSetUsedGroups,
    handleSetCalendarNames,
    handleReset,
    refetch,
    handleSetAdditionalAction,
  ]);
  
  return (
    <>
      <Box
        height="1px"
        width="100%"
        background={{ color: 'border2' }}
      />
      <Box
        background="canvas"
        round="container1Round"
        data-testid="PayrollCalendarTable_container"
      >
        {!!formattedList.length && (
          <Box margin="small" pad="small" background="module" round="container1Round">
            <Text
              margin={{ top: 'spacing8', bottom: 'spacing12', left: 'spacing16' }}
              size="large"
              weight="bold"
              color="textBody"
            >
              {`Payroll Calendar: ${formattedList.length}`}
            </Text>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formattedList.map((row: ListItem) => (
                <FlexListItem
                  key={row.id}
                  id={row.id}
                  fields={row.fields}
                  shifted
                  options={[
                    {
                      name: 'Edit',
                      onClick: handleEdit,
                    },
                    {
                      name: 'Deactivate',
                      onClick: handleDeactivate,
                    }
                  ]}
                />
              ))}
              {(!!moreItemsCount) && (
                <FlexListShowMore
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show More ({moreItemsCount})
                </FlexListShowMore>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <PayrollCalendarWarningModal />
      <PayrollCalendarDeactivationModal onSuccess={refetch}/>
      <PayrollCalendarModal onSuccess={refetch}/>
    </>
  );
};

export default PayrollCalendarTable;
