import React, { useMemo } from 'react';
import { Box, Inscription, SearchInput, SelectDropdown } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
import { OptionKey } from '@/common/types';
import { LOCKBOX_STATUSES_OPTIONS, LOCKBOX_TYPES_OPTIONS } from '@/modules/employer/components/Lockbox/lockbox.types';

interface Props {
  total: number;
  searchString: string;
  setSearchString: (value: string) => void,
  types: OptionKey[],
  setTypes: (options: OptionKey[]) => void,
  statuses: OptionKey[],
  setStatuses: (options: OptionKey[]) => void,
}

const LockboxListHeader: React.FC<Props> = ({
  total,
  searchString,
  setSearchString,
  types,
  setTypes,
  statuses,
  setStatuses,
}) => {
  const lockboxStatusesWithAllOptions = useMemo(() => [ALL_OPTION, ...LOCKBOX_STATUSES_OPTIONS], []);
  const lockboxTypesWithAllOption = useMemo(() => [ALL_OPTION, ...LOCKBOX_TYPES_OPTIONS], []);

  return (
    <Box direction="row" justify="between" align="center">
      <Inscription weight={700} color="textBody">
        Records: {total || 0}
      </Inscription>

      <Box direction="row">
        <Box
          margin={{ left: 'spacing12' }}
          width="330px"
        >
          <SearchInput
            testId="lockbox_search"
            value={searchString}
            onChange={setSearchString}
            placeholder="Search for name, SSN, account number.."
            hasSearchingHistory
            hideTip
          />
        </Box>

        <Box
          margin={{ left: 'spacing12' }}
          width="200px"
        >
          <SelectDropdown
            testId="lockbox_type"
            id="lockbox_type"
            name="lockbox type"
            prefix="Type"
            values={types}
            onChangeValues={setTypes}
            options={lockboxTypesWithAllOption}
            allMode
            activeTitle
            ellipsisMode
          />
        </Box>

        <Box
          margin={{ left: 'spacing12' }}
          width="200px"
        >
          <SelectDropdown
            testId="lockbox_status"
            id="lockbox_status"
            name="lockbox status"
            prefix="Status"
            values={statuses}
            onChangeValues={setStatuses}
            options={lockboxStatusesWithAllOptions}
            allMode
            activeTitle
            ellipsisMode
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LockboxListHeader;
