import colors from './colors';
import spacings from './spacings';

const mixins = {
  customLightScroll: `
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${colors.border1}; 
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.border1};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.border1}; 
    }
  `,
  customScroll: `
    // ::-webkit-scrollbar {
    //   width: 8px;
    // }
    // ::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 5px ${colors.border1}; 
    //   border-radius: 10px;
    // }
    // ::-webkit-scrollbar-thumb {
    //   background: ${colors.textDisabled};
    //   border-radius: 10px;
    // }
    // ::-webkit-scrollbar-thumb:hover {
    //   background: ${colors.textDisabled}; 
    // }
  `,
  betweenSiblingsSpacing: (spacing: string = spacings.spacing12) => `
    &:not(:last-child) {
      margin-right: ${spacing};
    }
  `,
};

export default mixins;
