import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';

export const MAX_SIZE = 50;

const QUERY_KEY = 'GET_ORGANIZATIONS_QUERY';

interface OrganizationPayload {
  id: number;
  name: string;
  path: string;
}

export interface Organization {
  id: string;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  children?: any;
  path?: string;
  type?: OrganizationTypes;
}

const formatData = (organizations: OrganizationPayload[]): Organization[] => organizations
  .map((organization) => ({
    id: toString(organization.id || ''),
    name: organization.name || '',
  }));

interface Props {
  searchValue?: string;
  type?: OrganizationTypes;
  enabled: boolean;
  parentId?: string;
}

const useGetOrganizationQuery = (props: Props) => {
  const {
    searchValue,
    type,
    enabled,
    parentId,
  } = props;
  const { isLoading, data } = useQuery(
    [
      QUERY_KEY,
      searchValue,
      type,
      parentId,
    ],
    () => api.get(
      PATHS.ORGANIZATIONS,
      {
        params: {
          statuses: 'ACTIVE',
          size: MAX_SIZE,
          type,
          ...searchValue ? { search_value: searchValue } : {},
          ...parentId ? { parent_id: parentId } : {},
        },
      },
    ),
    { enabled },
  );

  return {
    organizations: formatData(data?.data?.content || []),
    total: data?.data?.totalElements || 0,
    isLoading,
  };
};

export default useGetOrganizationQuery;
