import React, { useMemo,useState } from 'react';
import { Box } from '@common-fe/common-fe';

import { ConfigStatus } from './types/carrier.types';
import CarrierConfigList from './CarrierConfigList';
import { SelectCarrierTypeButton } from './components';

const FIRST_LIST_INDEX = 0;
const ALL_LISTS = [
  {
    type: ConfigStatus.active,
    title: 'Active',
  },
  {
    type: ConfigStatus.disabled,
    title: 'Disabled',
  },
];

const CarrierConfigLists = () => {
  const [displayedLists, setDisplayedList] = useState<Array<ConfigStatus>>([ConfigStatus.active]);

  const hideListTitle = useMemo(() => (displayedLists.length === 1)
   && displayedLists.includes(ConfigStatus.active), [displayedLists]);

  const renderList = useMemo(() => (
    ALL_LISTS
      .filter((listType) => displayedLists.includes(listType.type))
      .map((filteredList, index) => (
        <CarrierConfigList
          key={filteredList.type}
          statusType={filteredList.type}
          title={filteredList.title}
          hideListTitle={hideListTitle}
          node={index === FIRST_LIST_INDEX ? (
            <SelectCarrierTypeButton
              listsData={ALL_LISTS.map((list) => ({
                type: list.type,
                title: list.title,
                isChecked: displayedLists.includes(list.type),
              }))}
              setDisplayedList={setDisplayedList}
            />
          ) : undefined}
        />
      ))
  ), [displayedLists, hideListTitle]);

  return (
    <Box
      background={{ color: 'module' }}
      round="container1Round"
      pad="spacing24"
      margin={{ bottom: 'spacing24' }}
      data-testId="carrier-lists-wrapper"
    >
      {renderList}
    </Box>
  );
};

export default CarrierConfigLists;
