import { ChevronUpIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

export const NavigationButton = styled(ChevronUpIcon)<{ isAvailable?: boolean, isBack?: boolean }>`
  transform: rotate(${({ isBack }) => (isBack ? '-90deg' : '90deg')});
  font-size: 24px;
  border-radius: 4px;
  ${({ isAvailable, theme }) => (isAvailable
    ? `
      &:hover {
        cursor: pointer;
        background: ${theme.colors.accentContainer};

        svg {
          fill: ${theme.colors.iconAccentHover};
        }
      }
    `
    : '')};
`;
