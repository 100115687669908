import React from 'react';
import {
  Box,
  Inscription,
} from '@common-fe/common-fe';
import styled from 'styled-components';

const Header = styled(Box)`
  > div {
    align-items: end;
  }
  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
`;

const TableHeader = () => (
  <Header
    background="legend"
    height="32px"
    round="8px"
    direction="row"
    align="center"
  >
    <Box width="125px"><Inscription>Check #</Inscription></Box>
    <Box width="97px"><Inscription>Amount</Inscription></Box>
    <Box width="116px"><Inscription>Employee</Inscription></Box>
    <Box width="193px"><Inscription>Employer</Inscription></Box>
    <Box width="165px"><Inscription>Issued date</Inscription></Box>
    <Box width="97px"><Inscription>Status date</Inscription></Box>
    <Box width="99px"><Inscription>Status</Inscription></Box>
  </Header>
);

export default TableHeader;
