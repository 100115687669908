import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DocumentResponse, DocumentTypeEnum } from '@/common/types';

const getDocumentFiles = (documentId: string) => api
  .get<DocumentResponse>(PATHS.DOCUMENT_BY_ID(documentId));
export const getBlobType = (url: string) => {
  const formatStr = url?.split('?')?.[0]?.split('.')?.pop();
  const type = formatStr ? formatStr.trim().toLowerCase() : null;

  if (type === DocumentTypeEnum.PDF) return { format: 'application/pdf', type };
  return { format: `image/${type}`, type };
};
export const useGetDocumentFilesQuery = (documentId: string, isLoading: boolean) => {
  const {
    isLoading: isReceiptLoading,
    data,
  } = useQuery(
    ['documentReceipts', documentId, isLoading],
    () => (documentId ? getDocumentFiles(documentId) : null),
  );

  const [listOdDocuments, setListOdDocuments] = useState<string[]>([]);
  const [isFilesLoading, setIsFilesLoading] = useState(true);
  const documentsUrls = useMemo(() => data?.data?.files?.map((file) => file.s3_url) || [], [data]);
  const getFiles = useCallback(async () => (
    documentsUrls.length
      ? Promise.all(documentsUrls.map(((url) => fetch(url))))
        .then(async (responses) => Promise.all(responses.map(async (response, index) => {
          const blob = await response.arrayBuffer();
          return new Blob(
            [blob],
            { type: getBlobType(documentsUrls[index]).format },
          );
        })))
        .then((blobArr) => blobArr.map((blob) => URL.createObjectURL(blob)))
        .catch(() => [])
      : []
  ), [documentsUrls]);

  useEffect(() => {
    if (listOdDocuments.length === 0) {
      getFiles().then((files) => {
        setListOdDocuments(files);
        if (!isLoading && !isReceiptLoading) {
          setIsFilesLoading(false);
        }
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getFiles, isReceiptLoading, setIsFilesLoading, isLoading]);

  useEffect(() => {
    setIsFilesLoading(true);
    setListOdDocuments([]);
  }, [documentId]);

  return {
    files: listOdDocuments.map((blob, index) => ({
      blob,
      id: blob,
      link: documentsUrls[index],
      type: getBlobType(documentsUrls[index]).type as DocumentTypeEnum,
    })),
    isFilesLoading,
  };
};

export default useGetDocumentFilesQuery;
