import { useQuery } from 'react-query';
import { capitalize } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { FileRecordType, FileStatus } from '@/modules/FileManager/FileManager.constants';
import { FileStatusPayload,getStatus } from '@/modules/FileManager/queries/useFilesList.query';

export const GET_FILES_DETAILS_KEY = 'GET_FILES_DETAILS_KEY';

interface FileDetailsPayload {
  file_aggregator_dto_response: {
    created_at: string;
    employers: string[];
    file_name: string;
    file_processing_status_type: string;
    file_type: string;
    partners: string[];
    processed_at: string;
    processed_by: string;
    upload_way: string;
    uploaded_by: string;
  };
  records_details_dto: {
    created_records_count: number;
    error_records_count: number;
    no_change_records_count: number;
    processing_records_count: number;
    record_counts: number;
    successful_records_count: number;
    updated_records_count: number;
  };
}

export interface FileDetails {
  id: string;

  createdAt: string;
  employers: string[];
  fileName: string;
  fileProcessingStatusType: FileStatus;
  fileType: FileRecordType;
  partners: string[];
  processedAt: string;
  processedBy?: string;
  uploadWay: string;
  uploadedBy: string;

  createdRecordsCount: number;
  errorRecordsCount: number;
  noChangeRecordsCount: number;
  recordCounts: number;
  successfulRecordsCount: number;
  updatedRecordsCount: number;

  processingRecordsCount: number;
}

const formatData = (id: string, data: FileDetailsPayload): FileDetails => ({
  id,

  createdAt: data.file_aggregator_dto_response.created_at,
  employers: data.file_aggregator_dto_response.employers,
  fileName: data.file_aggregator_dto_response.file_name,
  fileProcessingStatusType: getStatus(
    data.file_aggregator_dto_response.file_processing_status_type as FileStatusPayload,
  ) as FileStatus,
  fileType: capitalize(data.file_aggregator_dto_response.file_type) as FileRecordType,
  partners: data.file_aggregator_dto_response.partners,
  processedAt: data.file_aggregator_dto_response.processed_at,
  processedBy: data.file_aggregator_dto_response.processed_by,
  uploadWay: data.file_aggregator_dto_response.upload_way,
  uploadedBy: data.file_aggregator_dto_response.uploaded_by,

  createdRecordsCount: data.records_details_dto.created_records_count,
  errorRecordsCount: data.records_details_dto.error_records_count,
  noChangeRecordsCount: data.records_details_dto.no_change_records_count,
  recordCounts: data.records_details_dto.record_counts,
  successfulRecordsCount: data.records_details_dto.successful_records_count,
  updatedRecordsCount: data.records_details_dto.updated_records_count,
  processingRecordsCount: data.records_details_dto.processing_records_count
});

const useGetFileDetailsQuery = (id: string) => {
  const { isLoading, data, isSuccess, refetch, isRefetching } = useQuery(
    [GET_FILES_DETAILS_KEY, id],
    () => api.get<FileDetailsPayload>(PATHS.GET_FILE_DETAILS(id)),
  );

  return {
    ...data?.data ? { fileDetails: formatData(id, data.data) } : {},
    isLoading,
    isSuccess,
    refetch,
    isRefetching,
  };
};

export default useGetFileDetailsQuery;
