import { storeCreator } from '@/utils/store/store.utils';

import { CommunicationCategoryPayload } from '../Communication.types';

interface State {
  handleReset: () => void;
  handleSetValues: (values: CommunicationCategoryPayload) => void;
  state: CommunicationCategoryPayload;
}

export const DEFAULT_VALUES: CommunicationCategoryPayload = {
  communicationCategory: [],
};

const useCommunicationStore = storeCreator<State>((set) => ({
  state: DEFAULT_VALUES,
  handleReset: () => set(() => ({ state: DEFAULT_VALUES })),
  handleSetValues: (state: CommunicationCategoryPayload) => set(() => ({ state })),
}));

export default useCommunicationStore;
