import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';

const GET_ORGANIZATION_PROPERTIES_QUERY_KEY = 'getOrganizationPropertiesQueryKey';

export interface OrganizationProperties {
  isReimbursementAllowed: boolean,
  isBillPayAllowed: boolean,
  allowedPaymentMethods: PaymentMethodType[],
  customerServiceIsEnabled?: boolean,
  customerServiceNumber?: string,
  customerServiceUrlName?: string,
  customerServiceUrlLink?: string,
  customerServiceEmail?: string,
}

export interface OrganizationPropertiesDto {
  is_reimbursement_allowed: boolean,
  is_bill_pay_allowed: boolean,
  allowed_payment_methods: PaymentMethodType[],
  customer_service_is_enabled?: boolean,
  customer_service_number?: string,
  customer_service_url_name?: string,
  customer_service_url_link?: string,
  customer_service_email?: string,
}

const useOrganizationPropertiesQuery = (organizationId?: string) => {
  const {
    isLoading, isSuccess, isError, data, refetch,
  } = useQuery(
    [GET_ORGANIZATION_PROPERTIES_QUERY_KEY, organizationId],
    () => api.get(PATHS.ORGANIZATION_PROPERTIES(organizationId)),
    {
      enabled: !!organizationId,
    },
  );

  useEffect(() => {
    if (organizationId) refetch();
  }, [organizationId, refetch]);

  const formattedData = useMemo(() => (data ? {
    isReimbursementAllowed: data?.data?.is_reimbursement_allowed,
    isBillPayAllowed: data?.data?.is_bill_pay_allowed,
    allowedPaymentMethods: data?.data?.allowed_payment_methods || [],
    customerServiceIsEnabled: !!data?.data?.customer_service_is_enabled,
    customerServiceNumber: data?.data?.customer_service_number,
    customerServiceUrlName: data?.data?.customer_service_url_name,
    customerServiceUrlLink: data?.data?.customer_service_url_link,
    customerServiceEmail: data?.data?.customer_service_email,
  } : undefined), [data]);

  return {
    organizationProperties: formattedData as OrganizationProperties,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useOrganizationPropertiesQuery;
