 
import React from 'react';

import PayrollGroupFormModal from './PayrollGroupFormModal';

interface Props {
  disabled?: boolean;
}
const PayrollGroupModal: React.FC<Props> = ({ disabled }) => (
  <PayrollGroupFormModal
    disabled={disabled}
  />
);

export default PayrollGroupModal;
