import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import { HealthPlanNamePayload,useHealthPlanNameStore } from '../HealthPlanName/stores/useHealthPlanName.store';
import { PlanYearPayload,useStore as usePlanYearStore } from '../PlanYear/stores/usePlanYear.store';

export type BasicPropertiesPayload = HealthPlanNamePayload & PlanYearPayload;

export default () => {
  const setHealthPlanNameStore = useHealthPlanNameStore((state) => state.setState);
  const setPlanYearStore = usePlanYearStore((state) => state.setState);

  const setSourceHealthPlanNameStore = useHealthPlanNameStore((state) => state.setSourceState);
  const setSourcePlanYearStore = usePlanYearStore((state) => state.setSourceState);

  const showErrorsHealthPlanNameStore = useHealthPlanNameStore((state) => state.setShowErrors);
  const showErrorsPlanYearStore = usePlanYearStore((state) => state.setShowErrors);

  const handleResetHealthPlanNameStore = useHealthPlanNameStore((state) => state.handleReset);
  const handleResetPlanYearStore = usePlanYearStore((state) => state.handleReset);

  const setHealthPlanNameErrors = useHealthPlanNameStore((state) => state.setErrors);
  const setPlanYearErrors = usePlanYearStore((state) => state.setErrors);

  const setHealthPlanNameErrorFields = useHealthPlanNameStore((state) => state.setErrorFields);
  const setPlanYearErrorFields = usePlanYearStore((state) => state.setErrorFields);

  const handleSetSourceState = useCallback((values: Partial<BasicPropertiesPayload>) => {
    setSourceHealthPlanNameStore(values);
    setSourcePlanYearStore(values);
  }, [
    setSourceHealthPlanNameStore,
    setSourcePlanYearStore,
  ]);

  const handleSetState = useCallback((values: Partial<BasicPropertiesPayload>) => {
    setHealthPlanNameStore(values);
    setPlanYearStore(values);
    handleSetSourceState(values);
  }, [handleSetSourceState,
    setHealthPlanNameStore,
    setPlanYearStore,
  ]);

  const handleReset = useCallback(() => {
    handleResetHealthPlanNameStore();
    handleResetPlanYearStore();
  }, [
    handleResetHealthPlanNameStore,
    handleResetPlanYearStore,
  ]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsHealthPlanNameStore(value);
    showErrorsPlanYearStore(value);
  }, [showErrorsHealthPlanNameStore, showErrorsPlanYearStore]);

  const handleSetErrors = useCallback((values: Partial<BasicPropertiesPayload>) => {
    setHealthPlanNameErrors(values);
    setPlanYearErrors(values);
  }, [
    setHealthPlanNameErrors,
    setPlanYearErrors,
  ]);

  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<BasicPropertiesPayload>>) => {
      setHealthPlanNameErrorFields(values);
      setPlanYearErrorFields(values);
    }, [
      setHealthPlanNameErrorFields, setPlanYearErrorFields,
    ],
  );

  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
