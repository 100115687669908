import { useCallback } from 'react';

import { CoverageLevelPayload as CoverageLevelStorePayload } from '../CoverageLevels.constants';
// import { ErrorsField } from '@/utils/store/baseStoreCreator';
import { useStore as useCoverateLevelsStore } from '../stores/useCoverageLevels.store';

export type CoverageLevelPayload = {
  coverageLevels:CoverageLevelStorePayload[];
};

export default () => {
  const setCoverageLevelsStore = useCoverateLevelsStore((state) => state.setState);

  const setSourceCoverageLevelsStore = useCoverateLevelsStore((state) => state.setSourceState);

  const showErrorsCoverageLevelsStore = useCoverateLevelsStore((state) => state.setShowErrors);

  const handleResetCoverageLevelsStore = useCoverateLevelsStore((state) => state.handleReset);

  const setCoveragesLevelsErrors = useCoverateLevelsStore((state) => state.setErrors);

  // const setHealthPlanNameErrorFields = useCoverateLevelsStore((state) => state.setErrorFields);

  const handleSetSourceState = useCallback((values: Partial<CoverageLevelPayload>) => {
    if (values.coverageLevels) {
      setSourceCoverageLevelsStore(values.coverageLevels);
    }
  }, [
    setSourceCoverageLevelsStore,
  ]);

  const handleSetState = useCallback((values: Partial<CoverageLevelPayload>) => {
    if (values.coverageLevels) {
      setCoverageLevelsStore(values.coverageLevels);

      handleSetSourceState(values);
    }
  }, [handleSetSourceState,
    setCoverageLevelsStore,
  ]);

  const handleReset = useCallback(() => {
    handleResetCoverageLevelsStore();
  }, [
    handleResetCoverageLevelsStore,
  ]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsCoverageLevelsStore(value);
  }, [showErrorsCoverageLevelsStore]);

  const handleSetErrors = useCallback((values: Partial<CoverageLevelPayload>) => {
    if (values.coverageLevels) {
      setCoveragesLevelsErrors(values.coverageLevels);
    }
  }, [
    setCoveragesLevelsErrors,
  ]);

  // const handleSetErrorFields = useCallback(
  //   (values: Partial<ErrorsField<CoverageLevelPayload>>) => {
  //     if (values.coverageLevels) {
  //       setHealthPlanNameErrorFields(values.coverageLevels);
  //     }
  //   }, [
  //     setHealthPlanNameErrorFields,
  //   ],
  // );

  return {
    // handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
