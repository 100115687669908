import { AppButton as CommonAppButton } from '@common-fe/common-fe';
import styled from 'styled-components';

import theme from '@/styles/theme';

const AppButton = styled(CommonAppButton)`
  ${({ buttonType }) => {
    if (buttonType === 'secondary') {
      return `
        &:hover {
          background-color: ${theme.colors.accentContainer};
          border-color: ${theme.colors.accentActive};
        }
        &:active {
          color:  ${theme.colors.textBody};
          border-color: ${theme.colors.accentActive};
          background-color: ${theme.colors.accentBorder};
        }
      `;
    }

    if (buttonType === 'tertiary') {
      return `
        color: ${theme.colors.textAccent};
        &:hover {
          color: ${theme.colors.textAccent};
          background-color: ${theme.colors.accentContainer};
          border-color: ${theme.colors.accentActive};
        }
        &:active {
          color:  ${theme.colors.textAccent};
          border-color: ${theme.colors.accentActive};
          background-color: ${theme.colors.accentBorder};
        }
      `;
    }

    if (buttonType === 'quaternary') {
      return `
        font-weight: 500;
        color: ${theme.colors.textAccent};
        &:hover {
          color: ${theme.colors.textAccent};
          background-color: ${theme.colors.accentContainer};
          border-color: ${theme.colors.accentActive};
        }
        &:active {
          color:  ${theme.colors.textAccent};
          border-color: ${theme.colors.accentActive};
          background-color: ${theme.colors.accentBorder};
        }
      `;
    }

    return '';
  }}
`;

export default AppButton;
