import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const ListItemsWrapper = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  }
`;

export const InfoTileIconWrapper = styled(Box)<{ hasBorder?: boolean }>`
  border-radius: 50%;
  ${({ hasBorder, theme }) => {
    if (hasBorder) return `outline: 2px solid ${theme.colors.canvas};`;
    return '';
  }}
`;
