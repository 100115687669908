import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { OptionKey } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';
import { RAW_DATE_FORMAT } from '@/common/constants';

import { ContributionListItemDto } from '../../contributions.types';

const QUERY_KEY = 'getContributionsQueryKey';

interface ResponseData {
  content: ContributionListItemDto[];
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
}

interface Props {
  startDate?: Date | null;
  endDate?: Date | null;
  accounts?: OptionKey[];
  sources?: OptionKey[];
  page?: number;
  perPage?: number;
}

export const useGetContributionsQuery = ({
  startDate,
  endDate,
  accounts,
  sources,
  page,
  perPage,
}: Props) => {
  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEY, page, perPage, accounts, sources, startDate, endDate],
    () => api.get<ResponseData>(PATHS.GET_CONTRIBUTIONS, {
      params: {
        ...perPage ? { size: perPage } : {},
        ...page || page === 0 ? { page } : {},
        ...accounts?.length ? { plan_codes: accounts.join(',') } : {},
        ...sources ? { contribution_source: sources.join(',') } : {},
        ...startDate ? { submit_date_from: dayjs(startDate).format(RAW_DATE_FORMAT) } : {},
        ...endDate ? { submit_date_to: dayjs(endDate).format(RAW_DATE_FORMAT) } : {},
      },
    }),
    {
      cacheTime: 0,
    },
  );

  const formattedData = useMemo(() => data?.data.content || [], [data]);

  return {
    data: data?.data.content,
    formattedData,
    totalPages: data?.data.totalPages,
    totalElements: data?.data.totalElements,
    isLoading,
    refetch,
  };
};
