import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { HealthPlansList } from '@/modules/HealthPlan';
import { HealthPlansType } from '@/modules/HealthPlan/HealthPlan.types';

const HealthPlansPage = () => {
  useBreadcrumbs([
    {
      route: ROUTES.HEALTH_PLAN_HUB,
      title: 'Health Plan Hub',
    },
    {
      route: ROUTES.HEALTH_PLANS,
      title: 'Health Plans',
      hash: HealthPlansType.Plan,
    },
  ]);

  return (
    <Box width="100%">
      <HealthPlansList isPageMode />
    </Box>
  );
};

export default HealthPlansPage;
