 
import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Text,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { REGEXPS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';
import { usePlanTemplatesQuery } from '@/modules/plan/queries';
import { EnrollmentMatrixItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { useProcessingRules } from '@/modules/ProcessingDefinition/ProcessingRule/hooks';

import { EnrollmentMatrixEntityPayload } from '../stores/useEnrollmentMatrixEntity.store';

const REQUIRED_TEXT = 'This field is required';
const EMPLOYER_ORG_LEVEL_KEY = 'COMPANY';
const VALID_TO_OPTION_KEY = 'validToOption';

const ANY_PLAN_TEMPLATE_KEY = 'anyPlanTemplate';
const ANY_PLAN_YEAR_KEY = 'anyPlanYear';

export const VALID_TO_OPTIONS = {
  NO_DEADLINE: 'noDeadline',
  CUSTOM_DATE: 'customDate',
};

const VALID_TO_ERROR_MESSAGE = 'Valid to cannot be earlier than Valid from';

const PriorityHint = () => (
  <Box>
    <Text textAlign="center" size="medium">
      Enter a number to occupy an already selected place,
      but this will lower the priority of other entries.
      You can also change the priority at any time by pressing 123 button.
    </Text>
  </Box>
);

export default (
  defaultFields?: Partial<EnrollmentMatrixItem>,
  errors?: Partial<EnrollmentMatrixEntityPayload>,
) => {
  const {
    observingOrganization,
  } = useCurrentOrganization();

  const currentOrgType = useMemo(() => observingOrganization.type, [observingOrganization]);

  const { formattedListTemplates } = usePlanTemplatesQuery(true);
  const { list: planYearsList } = usePlanYearsQuery();
  const { list: processingRulesList } = useProcessingRules({
    perPage: 100,
    page: 0,
  });

  const fields = useMemo<Field[]>(() => [
    {
      name: 'name',
      type: FieldTypes.Text,
      label: 'Name',
      options: formattedListTemplates,
      // isSearchMode: true,
      placeholder: 'Enter name',
      validator: yup.string().test({
        test: (val) => val !== errors?.name || !errors?.name,
        message: 'This name is already used by another enrollment matrix.',
      }).required(REQUIRED_TEXT),
      isNarrowLabelMode: true,
      defaultValue: defaultFields?.name || '',
    },
    {
      name: 'planTemplate',
      type: FieldTypes.Dropdown,
      label: 'Plan template',
      singleMode: true,
      disabled: !!defaultFields,
      options: [{
        key: ANY_PLAN_TEMPLATE_KEY,
        value: undefined,
        title: 'Any plan template',
        divide: true,
      }, ...formattedListTemplates],
      // defaultValue: defaultValues.templateName,
      placeholder: 'Select a plan template',
      validator: yup.string().trim().required(REQUIRED_TEXT),
      defaultValue: defaultFields ? (defaultFields?.planTemplate?.id || ANY_PLAN_TEMPLATE_KEY || '') : '',
      isNarrowLabelMode: true,
    },
    currentOrgType === EMPLOYER_ORG_LEVEL_KEY
      ? {
        name: 'planYear',
        type: FieldTypes.Dropdown,
        label: 'Plan year',
        singleMode: true,
        disabled: !!defaultFields,
        defaultValue: defaultFields ? (defaultFields?.planYear?.id || ANY_PLAN_YEAR_KEY || '') : '',
        options: [{
          key: ANY_PLAN_YEAR_KEY,
          value: undefined,
          title: 'Any plan year',
          divide: true,
        }, ...planYearsList],
        placeholder: 'Select a plan year',
        validator: yup.string().trim().required(REQUIRED_TEXT),
        isNarrowLabelMode: true,
      } : {
        name: 'empty',
        type: FieldTypes.Node,
      },
    {
      name: 'processingRule',
      type: FieldTypes.Dropdown,
      label: 'Processing rule',
      singleMode: true,
      disabled: !!defaultFields,
      options: processingRulesList,
      placeholder: 'Select processing rule',
      validator: yup.string().trim().required(REQUIRED_TEXT),
      isNarrowLabelMode: true,
      defaultValue: defaultFields?.processingRule?.id || '',
    },
    {
      name: 'validFrom',
      type: FieldTypes.Date,
      label: 'Valid from',
      placeholder: 'Select date',
      disabled: !!defaultFields,
      isManualDateInput: true,
      defaultValue: defaultFields?.validFrom
        ? dayjs(defaultFields?.validFrom).format(DEFAULT_DATE_FORMAT)
        : dayjs().format(DEFAULT_DATE_FORMAT),
      isModalType: false,
      inputWrapCssClass: 'small-input',
      isNarrowLabelMode: true,
      controlStyles: {
        width: '180px',
        marginRight: 'auto',
      },
      validator: yup.string().required(REQUIRED_TEXT),
    },
    {
      name: VALID_TO_OPTION_KEY,
      inputStyle: { maxWidth: '180px' },
      singleMode: true,
      type: FieldTypes.Dropdown,
      label: 'Valid to',
      defaultValue: defaultFields?.validTo
        ? VALID_TO_OPTIONS.CUSTOM_DATE : VALID_TO_OPTIONS.NO_DEADLINE,
      options: [{
        key: VALID_TO_OPTIONS.NO_DEADLINE,
        value: undefined,
        title: 'No deadline',
      }, {
        key: VALID_TO_OPTIONS.CUSTOM_DATE,
        value: VALID_TO_OPTIONS.CUSTOM_DATE,
        title: 'Custom date',
      }],
      placeholder: 'Select type',
      validator: yup.string().trim().required(REQUIRED_TEXT),
      inputWrapCssClass: 'small-input',
      isNarrowLabelMode: true,
      subFields: [
        {
          name: 'validTo',
          type: FieldTypes.Date,
          label: '',
          placeholder: 'Select date',
          isManualDateInput: true,
          parentValue: VALID_TO_OPTIONS.CUSTOM_DATE,
          inputWrapCssClass: 'small-input',
          isNarrowLabelMode: true,
          expandItems: true,
          defaultValue: dayjs(defaultFields?.validTo).format(DEFAULT_DATE_FORMAT) || '',
          validator: yup.string().trim()
            .when(VALID_TO_OPTION_KEY, {
              is: (val: string) => val === VALID_TO_OPTIONS.CUSTOM_DATE,
              then: yup.string()
                .test({
                  exclusive: false,
                  test(val) {
                    const { validFrom } = this.parent;
                    const validTo = val;
                    if (validFrom && validTo) {
                      return dayjs(validFrom, DEFAULT_DATE_FORMAT)
                      <= dayjs(validTo, DEFAULT_DATE_FORMAT);
                    }
                    return true;
                  },
                  message: VALID_TO_ERROR_MESSAGE,
                }).required(REQUIRED_TEXT),
              otherwise: yup.string(),
            }),
        },
      ],
    },
    {
      name: 'priority',
      type: FieldTypes.Number,
      label: 'Priority',
      isModalType: false,
      placeholder: 'Enter priority',
      defaultValue: defaultFields?.priority,
      regexp: REGEXPS.ONLY_NUMBERS_WITHOUT_ZERO,
      validator: yup.string().required(REQUIRED_TEXT),
      inputWrapCssClass: 'small-input',
      isNarrowLabelMode: true,
      hint: <PriorityHint />,
    },
  ], [
    formattedListTemplates,
    planYearsList,
    processingRulesList,
    currentOrgType,
    errors,
    defaultFields,
  ]);
  return fields;
};
