import React, { useEffect,useState } from 'react';
import { Box, Field, FlexControlledForm, useDebounce } from '@common-fe/common-fe';

import { YES_KEY } from '@/common/constants';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import useAIAdjudicationFields from './hooks/useAIAdjudicationFields';
import useAIAdjudicationStore from './stores/useAIAdjudication.store';

interface FormValue {
  aiAdjudication?: string;
  aiConfidenceLevel?: number;
}

const formatFormValue = (value: FormValue) => ({
  aiAdjudication: value.aiAdjudication === YES_KEY,
});

const AIAdjudication = () => {
  const [value, setValue] = useState();
  const debouncedValue = useDebounce(value);
  const { formattedData, isLoading } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const fields = useAIAdjudicationFields(viewMode, formattedData);
  const { setAIAdjudication } = useAIAdjudicationStore();

  useEffect(() => {
    setAIAdjudication(formatFormValue(debouncedValue || {}));
  }, [debouncedValue, setAIAdjudication]);

  if (isLoading) return <FormLoader testId="AIAdjudication" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      data-testId="ai-adjudication"
    >
      <FlexControlledForm
        formTitle="AI Adjudication"
        fields={fields as Field[]}
        onChangeValues={setValue}
        editMode={!viewMode}
        isThinMode
      />
    </Box>
  );
};

export default AIAdjudication;
