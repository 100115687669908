import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Inscription,
  // costFormater,
  Text,
} from '@common-fe/common-fe';

import { ExpandedThirdLevelObject } from '../ProcessingDefinition.types';

// import { useTriggersQuery } from '../ProcessingRule/queries';
import TreeIndicator, { TreeCircle, TreeIndicatorMode } from './TreeIndicator';

interface Props extends ExpandedThirdLevelObject {
  checkboxRef:React.RefObject<HTMLDivElement>;
  isArrowDownDisplayed?: boolean;
  isLastSubItem?: boolean;
  testId?: string;
}
const COLUMN_WIDTH = '225px';
const OFFSET = -25;
const OFFSET_VALUE = 60;
const ExpandedSubItem: React.FC<Props> = ({
  title,
  blockTitle,
  subTitle,
  checkboxRef,
  items,
  isArrowDownDisplayed,
  isLastSubItem,
  triggers,
  testId,
  subSubTitle,
  header,
  values,
}) => {
  // const { fieldsMap } = useTriggersQuery();
  const [blockHeight, setBlockHeight] = useState(0);

  const currentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const heightDiff = useMemo(() => {
    if (offsetTop) {
      const currentOffsetTop = offsetTop;
      if (checkboxRef.current) {
        const checkboxOffsetTop = checkboxRef.current.offsetTop;
        return currentOffsetTop - checkboxOffsetTop + OFFSET;
      }
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxRef.current, currentRef.current]);
  useEffect(() => {
    // After unmount a  ref of the component lose its state and don't work properly after mount
    const wrapperNode = wrapperRef.current;
    const node = currentRef.current;
    if (node?.offsetTop) {
      setOffsetTop(node?.offsetTop);
    }
    if (wrapperNode?.clientHeight) {
      setBlockHeight(wrapperNode.clientHeight);
    }
  }, []);
  // const triggersNode = useMemo(() => {
  //   if (triggres?.length && triggres[0]) {
  //     return (
  //       <Box direction="row" margin={{ top: 'xs' }}>
  //         <Box width={{ min: 'small' }} pad={{ right: 'm' }}>
  //           <Text color="textSecondary" size="small" weight={500}>Trigger</Text>
  //           {triggres.map((item) => (
  //             <Text key={item} size="medium">{fieldsMap[item]}</Text>
  //           ))}

  //         </Box>
  //         <Box>
  //           <Text color="textSecondary" size="small" weight={500}>
  //             Amount
  //           </Text>
  //           {triggres.map((item) => (
  //             <Text key={item} size="medium">{costFormater(item, { isPrecision: true })}</Text>
  //           ))}
  //         </Box>
  //       </Box>
  //     );
  //   }
  //   return null;
  // }, [fieldsMap, triggres]);

  // const currentTitle = useMemo(() => {
  //   if (isMultipleTriggres) {
  //     return 'Individual or family deductible met';
  //   }
  //   return title;
  // }, [isMultipleTriggres, title]);
  const currentArrowOffset = useMemo(() => {
    if (triggers?.length) {
      return blockHeight / 2 + OFFSET_VALUE;
    }
    return 0;
  }, [blockHeight, triggers]);
  return (
    <Box ref={wrapperRef} data-testid={`${testId}`} direction="column" margin={{ bottom: 'm' }}>
      <Inscription lineHeight="18px" size="small" weight={500} color="textSecondary" margin={{ bottom: 'spacing4' }}>
        {blockTitle}
      </Inscription>
      <Box direction="row" align="center">
        <Box ref={currentRef} style={{ position: 'relative' }}>
          <TreeIndicator
            isThirdLevel
            height={heightDiff}
          />
          {isArrowDownDisplayed && (
            <TreeIndicator
              isThirdLevel
              height={currentArrowOffset}
              mode={TreeIndicatorMode.DownLeft}
            />
          )}
          {isLastSubItem && (

            <TreeCircle background="iconSecondary" />

          )}
        </Box>
        <Box
          pad="m"
          fill
          border={{ size: 'small', color: 'border1' }}
          background="canvas"
          round="container2Round"
          direction="column"
        >
          <Text size="medium" color="textBody" weight={700} margin={{ bottom: 'xs' }}>{title}</Text>
          {/* {triggersNode} */}
          {subTitle && (
            <Text
              margin={{ top: 'xs' }}
              size="small"
              weight={500}
              color="textSecondary"
            >
              {subTitle}
            </Text>
          )}
          {subSubTitle && (
            <Text tag="span" size="medium" weight={400}>
              {subSubTitle}
            </Text>

          )}

          {items?.length && (
            <Box margin={{ top: 'xxs' }}>

              <Text tag="span" size="medium" weight={400}>
                {items.join(', ')}
              </Text>

            </Box>
          )}

          {header && (
            <Box direction="row">
              {header.map((item) => (
                <Box width={COLUMN_WIDTH} key={item}>
                  <Text
                    size="small"
                    color="textSecondary"
                    weight={500}
                  >
                    {item}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
          {values && (
            <Box margin={{ top: 'xxs' }}>
              <Box direction="row">
                {values.map((item, index) => (
                  <Box
                    width={values.length - 1 === index ? 'fit-content' : COLUMN_WIDTH}
                    key={item}
                  >
                    <Text
                      size="medium"
                      weight={400}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

        </Box>
      </Box>
    </Box>
  );
};

export default ExpandedSubItem;
