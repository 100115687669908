import { FormValues } from '../EditDataModalPopup';
import { ContributionAccount } from '../types';

import disableAccountIfZeroAmounts from './disableAccountIfZeroAmounts';

const getUpdatedContributionAccount = (acc?: ContributionAccount, formValues?: FormValues) => disableAccountIfZeroAmounts({
  ...acc,
  ...formValues?.contributionDateOn ? {
    contributionDateOn: formValues?.contributionDateOn,
  } : {},
  ...formValues?.employeeContributionAmount ? {
    employeeContributionAmount: formValues?.employeeContributionAmount,
  } : {},
  ...formValues?.employerContributionAmount ? {
    employerContributionAmount: formValues?.employerContributionAmount,
  } : {},
  ...formValues?.memo ? {
    memo: formValues?.memo,
  } : {},
  ...formValues?.taxYear ? {
    taxYear: formValues?.taxYear,
  } : {},
  ...formValues?.taxType ? {
    taxType: formValues?.taxType,
  } : {},
});

export default getUpdatedContributionAccount;
