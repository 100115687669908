import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ContributionSegment,ContributionSegmentType, ManualContributionFormFields, ManualContributionFormValues, NewContributionType, TaxType } from '../contributions.types';
import { useAllValuesForManualContributionsStore } from '../store';

interface ContributionSegmentPayload {
  id?: number;
  contribution_request_id?: number;
  contribution_id?: string;
  contribution_date?: string;
  tax_type?: TaxType;
  tax_year?: string;
  contribution_segment_type?: ContributionSegmentType;
  memo?: string;
  segment_subsidiary_ids?: number[];
  segment_payroll_group_codes?: number[];
  segment_plan_codes?: string[];
  segment_employee_ids?: number[];
}

const formatData = (data?: ContributionSegmentPayload) => {
  if (!data) return undefined;

  return {
    id: toString(data?.id),
    contributionRequestId: toString(data?.contribution_request_id),
    contributionId: data?.contribution_id,
    contributionDate: data?.contribution_date,
    taxType: data?.tax_type,
    taxYear: data?.tax_year,
    contributionSegmentType: data?.contribution_segment_type,
    memo: data?.memo,
    segmentSubsidiaryIds: data?.segment_subsidiary_ids?.map((id) => toString(id)),
    segmentPayrollGroupCodes: data?.segment_payroll_group_codes,
    segmentPlanCodes: data?.segment_plan_codes,
    segmentEmployeeIds: data?.segment_employee_ids?.map((id) => toString(id)),
  } as ContributionSegment;
};

const QUERY_KEY = 'GET_CONTRIBUTION_SEGMENT_BY_ID';

interface Props {
  contributionId?: string;
  setFormValues?: React.Dispatch<React.SetStateAction<ManualContributionFormValues>>;
}

const useGetContributionSegmentByIdQuery = (props: Props) => {
  const store = useAllValuesForManualContributionsStore();
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY],
    () => api.get<ContributionSegmentPayload>(PATHS.GET_CONTRIBUTION_SEGMENT_BY_ID(props?.contributionId)),
    {
      cacheTime: 500,
      enabled: Boolean(props?.contributionId),
      onSuccess: (response) => {
        if (!response?.data) return;

        const segment = formatData(response.data);

        if (segment?.segmentPlanCodes?.length) {
          store.handleSetPlanCodes(segment.segmentPlanCodes);
        }

        if (segment?.segmentSubsidiaryIds?.length) {
          store.handleSetSubsidiaries(segment.segmentSubsidiaryIds);
        }

        if (segment?.segmentPayrollGroupCodes?.length) {
          store.handleSetPayrolls(segment.segmentPayrollGroupCodes);
        }

        if (segment?.segmentEmployeeIds?.length) {
          store.handleSetEmployees(segment.segmentEmployeeIds);
        }

        if (props?.setFormValues) {
          props.setFormValues({
            ...segment?.contributionSegmentType === ContributionSegmentType.SINGLE ? {
              [ManualContributionFormFields.CONTRIBUTION_TYPE]: ContributionSegmentType.SINGLE,
              [ManualContributionFormFields.EMPLOYEE]: segment?.segmentEmployeeIds?.shift(),
            } : {
              [ManualContributionFormFields.CONTRIBUTION_TYPE]: NewContributionType.GROUP,
              [ManualContributionFormFields.GROUP_TYPE]: segment?.contributionSegmentType,
              [ManualContributionFormFields.PLANS]: segment?.segmentPlanCodes,
              [ManualContributionFormFields.SUBSIDIARY]: segment?.segmentSubsidiaryIds,
              [ManualContributionFormFields.PAYROLL_GROUP]: segment?.segmentPayrollGroupCodes,
            },
            // [ManualContributionFormFields.CONTRIBUTION_DATE]: dayjs(segment?.contributionDate).format(DEFAULT_DATE_FORMAT),
            [ManualContributionFormFields.TAX_YEAR]: segment?.taxYear,
            [ManualContributionFormFields.TAX_TYPE]: segment?.taxType,
            [ManualContributionFormFields.MEMO]: segment?.memo,
          } as ManualContributionFormValues);
        }
      },
    },
  );

  const formattedData = useMemo<ContributionSegment | undefined>(() => formatData(data?.data), [data]);

  return {
    isLoading,
    formattedData,
    isSuccess,
  };
};

export default useGetContributionSegmentByIdQuery;
