import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REGEXPS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import { useFieldsWithDefaultValues } from '@/utils';

import { useGracePeriodStore } from '../stores';

export const GRACE_PERIOD_KEYS = {
  END_OF_COVERAGE: 'endOfCoverage',
  END_OF_COVERAGE_AMOUNT: 'endOfCoverageAmount',
  RUN_OUT: 'runOut',
  RUN_OUT_AMOUNT: 'runOutAmount',
  CLAIMS_DEADLINE: 'claimsDeadline',
  CLAIMS_DEADLINE_PLAN_YEAR_AMOUNT: 'claimsDeadlinePlanYearAmount',
  CLAIMS_DEADLINE_COVERAGE_DATE_AMOUNT: 'claimsDeadlineCoverageDateAmount',
  GRACE_PERIOD: 'gracePeriod',
  GRACE_PERIOD_AMOUNT: 'gracePeriodAmount',
};

const CUSTOM_KEY = 'CUSTOM';
export const PLAN_YEAR_END_DATE_KEY = 'PLAN_YEAR_END_DATE';
export const COVERAGE_END_DATE_KEY = 'COVERAGE_END_DATE';
export default (
  disabled?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
) => {
  // const errors = useGracePeriodStore((state) => state.errors);
  const sourceState = useGracePeriodStore((state) => state.sourceState);
  const currentState = useGracePeriodStore((state) => state.state);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const fields = useMemo<Field[]>(() => [
    {
      name: GRACE_PERIOD_KEYS.END_OF_COVERAGE,
      type: FieldTypes.Radio,
      expandItems: true,
      validator: yup.string().nullable(),
      label: 'Termination (end coverage based on Employment termination)',

      options: [
        {
          label: 'Do nothing (enrollment cancellation will drive coverage end)',
          value: 'DO_NOTHING',
        },

        {
          label: 'Day of termination',
          value: 'DAY_OF_TERMINATION',
        },
        {
          label: 'First day of following month ',
          value: 'FIRST_DAY_FOLLOWING_MONTH',
        },
        {
          label: 'Date of termination following number of days',
          value: CUSTOM_KEY,
        },
      ],
      subFields: [
        {
          name: GRACE_PERIOD_KEYS.END_OF_COVERAGE_AMOUNT,
          type: FieldTypes.Number,
          label: '',
          placeholder: 'Enter # of days',
          parentValue: CUSTOM_KEY,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when('endOfCoverage', {
            is: (val: string) => val === CUSTOM_KEY,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: yup.string(),
          }),
        },
      ],
    },

    {
      name: GRACE_PERIOD_KEYS.RUN_OUT,
      type: FieldTypes.Radio,
      expandItems: true,
      validator: yup.string().nullable(),
      label: 'Claims deadline to submit claims based on end of plan (run out)',

      options: [
        {
          label: 'Last of day of plan year',
          value: 'LAST_DAY_OF_PLAN_YEAR',
        },
        {
          label: 'Following number of days from plan year end date',
          value: CUSTOM_KEY,
        },
        {
          label: 'No claims deadline',
          value: 'NO_CLAIMS_DEADLINE',
        },
      ],
      subFields: [
        {
          name: GRACE_PERIOD_KEYS.RUN_OUT_AMOUNT,
          type: FieldTypes.Number,
          label: '',
          placeholder: 'Enter # of days',
          parentValue: CUSTOM_KEY,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when('runOut', {
            is: (val: string) => val === CUSTOM_KEY,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: yup.string(),
          }),
        },
      ],
    },
    {
      name: GRACE_PERIOD_KEYS.CLAIMS_DEADLINE,
      type: FieldTypes.Radio,
      expandItems: true,
      validator: yup.string().nullable(),
      label: 'Claims deadline to submit claims based on end of coverage',

      options: [
        {
          label: 'Last day of month following coverage end date',
          value: 'LAST_DAY_OF_MONTH_END_DATE',
        },

        {
          label: 'Following number of days from plan year end date',
          value: PLAN_YEAR_END_DATE_KEY,
        },
        {
          label: 'Following number of days following coverage end date',
          value: COVERAGE_END_DATE_KEY,
        },
        {
          label: 'Pre-defined plan rules based on end of plan settings (run out)',
          value: 'PRE_DEFINED_END_DATE',
        },
      ],
      subFields: [
        {
          name: GRACE_PERIOD_KEYS.CLAIMS_DEADLINE_PLAN_YEAR_AMOUNT,
          type: FieldTypes.Number,
          label: '',
          placeholder: 'Enter # of days',
          parentValue: PLAN_YEAR_END_DATE_KEY,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when('claimsDeadline', {
            is: (val: string) => val === PLAN_YEAR_END_DATE_KEY,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: yup.string(),
          }),
        },
        {
          name: GRACE_PERIOD_KEYS.CLAIMS_DEADLINE_COVERAGE_DATE_AMOUNT,
          type: FieldTypes.Number,
          label: '',
          placeholder: 'Enter # of days',
          parentValue: COVERAGE_END_DATE_KEY,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when('claimsDeadline', {
            is: (val: string) => val === COVERAGE_END_DATE_KEY,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: yup.string(),
          }),
        },
      ],
    },

    {
      name: GRACE_PERIOD_KEYS.GRACE_PERIOD,
      type: FieldTypes.Radio,
      expandItems: true,
      validator: yup.string().nullable(),
      label: 'Grace period',

      options: [
        {
          label: '2 1/2  months after plan year',
          value: 'TWO_AND_HALF_MONTH',
        },
        {
          label: 'Following number of days from plan year end date',
          value: CUSTOM_KEY,
        },
        {
          label: 'Does not apply',
          value: 'DOES_NOT_APPLY',
        },
      ],
      subFields: [
        {
          name: GRACE_PERIOD_KEYS.GRACE_PERIOD_AMOUNT,
          type: FieldTypes.Number,
          label: '',
          placeholder: 'Enter # of days',
          parentValue: CUSTOM_KEY,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when('gracePeriod', {
            is: (val: string) => val === CUSTOM_KEY,
            then: yup.string().required(REQUIRED_TEXT),
            otherwise: yup.string(),
          }),
        },
      ],
    },
  ], []);
  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
