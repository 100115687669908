import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FieldError, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import { SERVER_ERRORS } from '@/common/constants';
import { PlanStatuses } from '@/modules/plan/plan.types';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { usePlanTemplateFields } from '../PlanTemplate/hooks';
import { usePlanTemplateStore } from '../PlanTemplate/stores';
import { useCreatePlanTemplateQuery } from '../queries';

export default (successCallback?: () => void) => {
  const [planCreationError, setPlanCreationError] = useState(false);

  const [loading, setLoading] = useState(false);
  const { getValidateFields, getErrors } = useValidationUtils();
  const [submitted, setSubmitted] = useState(false);

  const savePlanTemplate = useCreatePlanTemplateQuery(true);
  const planNameFields = usePlanTemplateFields();
  const planTemplate = usePlanTemplateStore((state) => state.state);
  const handlePlanTemplateShowError = usePlanTemplateStore((state) => state.setShowErrors);
  const handlePlanTemplateReset = usePlanTemplateStore((state) => state.handleReset);

  const planNameValidation = useMemo<FieldValues>(
    () => getValidateFields(planNameFields),
    [getValidateFields, planNameFields],
  );

  const planNameErrors: FieldError = useMemo(
    () => getErrors(planTemplate, planNameValidation),
    [getErrors, planNameValidation, planTemplate],
  );

  const handleReset = useCallback(() => {
    handlePlanTemplateReset();
  }, [handlePlanTemplateReset]);

  useEffect(() => () => {
    handleReset();
  }, [handleReset]);

  const handlePreSubmit = useCallback(() => {
    setPlanCreationError(false);
    handlePlanTemplateShowError(true);
    if (_.isEmpty(planNameErrors)) {
      setLoading(true);
      setPlanCreationError(false);
      return true;
    }

    return false;
  }, [handlePlanTemplateShowError, planNameErrors]);
  const handleSave = useCallback(async () => {
    setSubmitted(true);
    setPlanCreationError(false);
    setLoading(true);
    handlePlanTemplateShowError(true);
    try {
      if (_.isEmpty(planNameErrors)) {
        const parentId = _.toNumber(planTemplate.parentTemplate?.[0]);
        await savePlanTemplate({
          name: planTemplate.templateName,
          parentId: _.isNaN(parentId) || !planTemplate.parentTemplate
            ? undefined : parentId,
          status: PlanStatuses.DRAFT,
          planType: planTemplate.planType,
        });
      }
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      const code = _.get(e, 'response.status') as unknown as number;

      if (code === SERVER_ERRORS.UNAUTHORIZED || !code) {
        if (successCallback) {
          successCallback();
        }
        return;
      }
      setPlanCreationError(true);
    } finally {
      setLoading(false);
    }
  }, [
    handlePlanTemplateShowError,
    planNameErrors,
    planTemplate,
    successCallback,
    savePlanTemplate,
  ]);

  return {
    setPlanCreationError,
    planCreationError,
    loading,
    setLoading,
    submitted,
    handleReset,
    handleSave,
    handlePreSubmit,
  };
};
