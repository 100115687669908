import React from 'react';
import {
  Box, InfoOutlineIcon,
  Text, Tip, } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledInfoIcon = styled(InfoOutlineIcon)`
  opacity: 0.5 ;
  &:hover {
    opacity: 1 ;
  }
`;

const FilterHint = () => (
  <Tip
    plain
    dropProps={{
      align: {
        top: 'bottom',
      },
    }}
    content={(
      <Box pad={{ top: 'l' }}>
        <Box
          background="background3"
          round="dropdownItemRound"
          elevation="default"
          align="center"
          width="regular"
          pad={{ horizontal: 'm', vertical: 's' }}
        >
          <Text color="textOnColor" data-testId="filter-hint_text">Enter template, plan year and date of your employees'
            enrollments and check what processing rule
            would be applied to them.
          </Text>

        </Box>
      </Box>
    )}
  >
    <Box direction="row" align="center">

      <Box margin={{ left: 'xxs' }}>
        <StyledInfoIcon data-testid="filter-hint_icon" size="24px" />
      </Box>
    </Box>
  </Tip>
);
export default FilterHint;
