 
import { useCallback, useMemo } from 'react';
// import { useOnlyForDev } from '@/utils';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import {
  // FieldStateTypes,
  removeEmptyObjects,
} from '@common-fe/common-fe';
import _ from 'lodash';

// import { Response } from '@/api/api';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import {
  HealthPlanDeductibleConfig,
  HealthPlanResponse,   HealthPlanStatuses, } from '@/modules/HealthPlan/HealthPlan.types';

import { HEALTH_PLAN_LIST_QUERY_NAME } from '../../queries/useHealthPlansList.query';
import { CoverageLevelPayload } from '../CoverageLevels/CoverageLevels.constants';
import { useUpdateHealthPlanByIdQuery } from '../queries';
// import { getFormatedPlan } from '../utils';
// import usePlanSetupSetter from './usePlanSetupSetter';
import { QUERY_KEY } from '../queries/useHealthPlanById.query';
import { usePlanLoadingStore } from '../stores';

import useHealthPlanSetupFieldsState from './useHealthPlanSetupFieldsState';
import useHealthPlanSetupState from './useHealthPlanSetupState';

export const YES_KEY = 'true';
export const NO_KEY = 'false';
const DELAY_TIMEOUT = 1500;

interface Config {
  errorCallback?:(error: unknown) => void;
  isTemplateMode?: boolean;
  newCoverageIds?: number[];
  removeCoverageId?: number;
  isReloadlessMode?: boolean;
}
 
export default (config: Config) => {
  // const { handleSetSourceState } = usePlanSetupSetter();
  const setLoading = usePlanLoadingStore((state) => state.setLoading);
  const queryClient = useQueryClient();
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const fieldsState = useHealthPlanSetupFieldsState();
  const state = useHealthPlanSetupState();
  const mutation = useUpdateHealthPlanByIdQuery(id, config.errorCallback);
  // const isOnlyForDev = useOnlyForDev();
  const { observingOrganizationId } = useCurrentOrganisationStore();
  const formattedPlans = useMemo(() => {
    if (state.plans && state.plans?.length > 0) {
      return state.plans.filter((item) => typeof item === 'string');
    }
    return [];
  }, [state.plans]);

  // const activateValidate = useSubmitFormStore((activateState) => activateState.activateValidate);
  const formatedDeductibles = useMemo(() => {
    const list = state.coverageLevels.reduce((
      result: HealthPlanDeductibleConfig[],
      item:CoverageLevelPayload,
    ) => {
      if (item) {
        const currentCoverageFieldState = fieldsState.coverageLevels.find(
          (coverageField) => coverageField.coverageId === item.coverageId,
        );

        return [...result, {
          id: _.toNumber(item.id) || undefined,
          parent_id: _.toNumber(item.parentId) || undefined,
          // coverage_id: _.toNumber(item.coverageId) || undefined,
          coverage: {
            id: _.toNumber(item.coverageId) || undefined,
            name: item.name,
            is_default: !item.isDeletable,
          },
          code: _.pickBy({
            code: item.coverageCode,
            code_state: currentCoverageFieldState?.coverageCode,
          }),
          plan_amount: _.pickBy({
            plan_amount: _.toNumber(item.planDeductible) || undefined,
            plan_amount_state: currentCoverageFieldState?.planDeductible,
          }),
          member_amount: _.pickBy({
            member_amount: _.toNumber(item.memberDeductible) || undefined,
            member_amount_state: currentCoverageFieldState?.memberDeductible,
          }),
          embedded_amount: _.pickBy({
            embedded_amount: _.toNumber(item.embeddedAmount) || undefined,
            embedded_amount_state: currentCoverageFieldState?.embeddedAmount,
          }),

          auto_enroll_account_funding_configs: !config.isTemplateMode
            ? item?.accountFundingConfigs?.map((fundingConfig) => (_.pickBy({
              id: _.toNumber(fundingConfig.id),
              plan_id: _.toNumber(fundingConfig.planId),
              auto_enrollment_amount: _.toNumber(_.get(item, fundingConfig.planId))
              || fundingConfig.autoEnrollmentAmount,
            }))) : undefined,
        }];
      }
      return result;
    }, []);

    const filteredList = config.removeCoverageId
      ? list.filter((item) => !item.id
      || !!item.parent_id || item.id !== config.removeCoverageId) : list;

    const parentIdFilteredList = config.removeCoverageId
      ? filteredList.map((item) => {
        if (item.id === config.removeCoverageId && item.parent_id) {
          return {
            parent_id: item.parent_id,
          };
        }
        return item;
      })
      : filteredList;
    const expandedList = config.newCoverageIds
      ? [...parentIdFilteredList, ...config.newCoverageIds.map((coverageId) => ({
        coverage_id: coverageId,
      }))] : parentIdFilteredList;

    return expandedList;
  }, [state.coverageLevels, config.removeCoverageId, config.newCoverageIds,
    config.isTemplateMode, fieldsState.coverageLevels]);

  const formatedData = useMemo(() => {
    let isAutoCreateCdhEnrollment: boolean | undefined;
    // It needed because it's not a checkbox, it's radiobutton value with Yes/No answers
    if (state.autocreateCdhEnrollment === YES_KEY) {
      isAutoCreateCdhEnrollment = true;
    } else if (state.autocreateCdhEnrollment === NO_KEY) {
      isAutoCreateCdhEnrollment = false;
    }

    const payload: Partial<HealthPlanResponse> = removeEmptyObjects({
      id,
      is_plan: !config.isTemplateMode,
      ...state.planSummary ? {
        summary: state.planSummary,
      } : {},
      plan_status: HealthPlanStatuses.DRAFT,
      plan_code: state.planCode,
      plan_year_id: state.planYearId ? _.toNumber(state.planYearId) : undefined,
      ...observingOrganizationId ? {
        organization_id: observingOrganizationId,
      } : {},
      name: {
        name: state.healthPlanName,
        name_state: fieldsState.healthPlanName,
      },
      carrier_id: {
        carrier_id: state.carrierName ? _.toNumber(state.carrierName) : undefined,
        carrier_id_state: fieldsState.carrierName,
      },
      health_plan_types: {
        health_plan_types: state.healthPlanTypes,
        health_plan_type_state: fieldsState.healthPlanType,
      },
      is_auto_enrollment: {
        is_auto_enrollment: isAutoCreateCdhEnrollment,
        is_auto_enrollment_state: fieldsState.autocreateCdhEnrollment,
      },
      health_plan_deductible_configs: formatedDeductibles,
      plans: formattedPlans,
      deductibles_by_service_type: {
        deductibles_by_service_type: state.deductibleService || undefined,
        deductibles_by_service_type_state: fieldsState.deductibleService,
      },
    });

    return payload;
  }, [state, fieldsState, id, observingOrganizationId,
    config, formattedPlans, formatedDeductibles]);
  const handleSave = useCallback(async (planStatus: HealthPlanStatuses) => {
    await mutation.mutateAsync({
      dto: {
        ...formatedData,
        plan_status: planStatus,
        enrollment_matrix: {
          name: state.enrollmentMatrixName || undefined,
          processing_rule_id: state.processingRuleName
            ? _.toNumber(state.processingRuleName) : undefined,
        },
      },
    });
    queryClient.invalidateQueries([QUERY_KEY, id]);
    queryClient.invalidateQueries([HEALTH_PLAN_LIST_QUERY_NAME]);
    if (!config.isReloadlessMode) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, DELAY_TIMEOUT);
    }

    // queryClient.setQueryData([QUERY_KEY, id], () => ({
    //   ...oldData,
    //   data: {
    //     ...oldData?.data,
    //     ...plan,
    //     // plan_status: plan.plan_status,
    //   },
    // }));

    // handleSetSourceState(getFormatedPlan(plan));
  }, [config.isReloadlessMode, formatedData, id, mutation, queryClient, setLoading, state]);

  return handleSave;
};
