import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface AccountFundingParamsFieldsStatePayload {
  isReplenished: PlanFieldState;
  planLevelFunding: PlanFieldState;
  omnibus: PlanFieldState;
  accountCreation: PlanFieldState;
  isPreFundCalculated: PlanFieldState;
  replenishmentCreepMin: PlanFieldState;
  replenishmentCreepPercent: PlanFieldState;
  replenishmentMinValue: PlanFieldState;
  replenishmentPercent: PlanFieldState;
}

export const DEFAULT_VALUES: AccountFundingParamsFieldsStatePayload = {
  isReplenished: PlanFieldState.Modifiable,
  planLevelFunding: PlanFieldState.Modifiable,
  omnibus: PlanFieldState.Modifiable,
  accountCreation: PlanFieldState.Modifiable,
  isPreFundCalculated: PlanFieldState.Modifiable,
  replenishmentCreepMin: PlanFieldState.Modifiable,
  replenishmentCreepPercent: PlanFieldState.Modifiable,
  replenishmentMinValue: PlanFieldState.Modifiable,
  replenishmentPercent: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<AccountFundingParamsFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
