import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  AppButton,   Box, Field,
  FlexControlledForm,
  Preloader, SiblingBoxesWithSpacing,
  WarnModal,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { SourceIds } from '@/modules/employee/employee.types';

import { useEmployeeSetupAddressModalFields } from './hooks/useEmployeeSetupAddressModalFields';
import { useEmployeeSetupModalContactInfoFields } from './hooks/useEmployeeSetupContactInfoModalFields';
import { useEmployeeSetupEmploymentModalFields } from './hooks/useEmployeeSetupEmploymentModalFields';
import useEmployeeSetupIdentifiersModalFields, { MAX_FIELD_LENGTH } from './hooks/useEmployeeSetupIdentifiersModalFields';
import { useEmployeeSetupModalDemographicsFields } from './hooks/useEmployeeSetupModalDemographicsFields';
import { useEmployeeSetupModalMailingAddressFields } from './hooks/useEmployeeSetupModalMailingAddressFields';
import useSourceId from './hooks/useSourceId';
import { SERVER_ERROR,useCreateEmployee } from './query/useCreateEmployee.query';
import { EmployeeFields } from './employeeForm.types';

const ID_SCROLLING = 'add_employee_identifiers';

interface Props {
  modalId?: string;
  onCancel?: () => void;
  setIsFormDirty?: (isDirty: boolean) => void;
  showConfirmModal: (show: boolean) => void;
  setHasErrorModal: (value: boolean) => void;
}

export const EmployeeSetupFormModal: React.FC<Props> = ({
  modalId,
  onCancel,
  setIsFormDirty,
  showConfirmModal,
  setHasErrorModal,
}) => {
  const scrollToTop = () => {
    if (!modalId) return;
    const setupWrapElem = document.getElementById(modalId);
    const elem = document.getElementById(ID_SCROLLING);
    if (elem && setupWrapElem) {
      const offsetHeight = 0;
      const tocItemPos = elem.offsetTop - setupWrapElem.offsetTop - offsetHeight;
      setupWrapElem.scrollTo({ top: tocItemPos, behavior: 'auto' });
    }
  };
  const [hasInternalErrorValidation, setInternalErrorValidation] = useState(false);
  const [hasEmployeeIdExistsValidation, setHasEmployeeIdExistsValidation] = useState(false);
  const [hasInternalErrorModal, setHasInternalErrorModal] = useState(false);
  const [hasConflictErrorModal, setHasConflictErrorModal] = useState(false);
  useEffect(() => setHasErrorModal(hasInternalErrorModal || hasConflictErrorModal),
    [hasInternalErrorModal, hasConflictErrorModal, setHasErrorModal]);
  const [employeeIdentifiersSetup, setEmployeeIdentifiersSetup] = useState(false);
  const [employeeSetupAddress, setEmployeeSetupAddress] = useState(false);
  const [employeeDemographics, setEmployeeDemographics] = useState(false);
  const [employeeMailingAddress, setEmployeeMailingAddress] = useState(false);
  const [employeeContactInfo, setEmployeeContactInfo] = useState(false);
  const [employeeEmployment, setEmployeeEmployment] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const [isIdentifiersSetupDirty, setIsIdentifiersSetupDirty] = useState(false);
  const [isSetupAddressDirty, setIsSetupAddressDirty] = useState(false);
  const [isDemographicsDirty, setIsDemographicsDirty] = useState(false);
  const [isMailingAddressDirty, setIsMailingAddressDirty] = useState(false);
  const [isContactInfoDirty, setIsContactInfoDirty] = useState(false);
  const [isEmploymentDirty, setIsEmploymentDirty] = useState(false);

  const [lastEmploymentValue, setLastEmploymentValue] = useState('');

  const [formValues, setFormValues] = useState<Partial<EmployeeFields>>({ mailingAddress: true });

  const idSourceId = useSourceId(formValues?.subsidiary || formValues?.employer);

  const isSourceIdValid = useMemo(() => {
    switch (idSourceId) {
    case SourceIds.EEID:
      return (
        Boolean(formValues?.employerEmployeeId?.length
          && formValues?.employerEmployeeId?.length <= MAX_FIELD_LENGTH)
        || Boolean(formValues?.employmentEmployerEmployeeId?.length
          && formValues?.employmentEmployerEmployeeId?.length <= MAX_FIELD_LENGTH)
      );
    case SourceIds.PEID:
      return (
        Boolean(formValues?.partnerEmployeeId?.length
          && formValues?.partnerEmployeeId?.length <= MAX_FIELD_LENGTH)
        || Boolean(formValues?.employmentPartnerEmployeeId?.length
          && formValues?.employmentPartnerEmployeeId?.length <= MAX_FIELD_LENGTH)
      );
    default:
      // @ts-ignore
      return formValues?.ssn?.replaceAll('-', '').length === 9;
    }
  }, [idSourceId, formValues]);

  useEffect(() => {
    if (formValues?.employmentStatus) {
      setLastEmploymentValue(formValues?.employmentStatus);
    }
  }, [formValues.employmentStatus]);

  const {
    onSave,
    isLoading: submitLoading,
  } = useCreateEmployee(
    (serverError: SERVER_ERROR) => {
      setHasInternalErrorModal(false);
      setHasConflictErrorModal(false);
      setInternalErrorValidation(false);
      setHasEmployeeIdExistsValidation(false);
      if (serverError === SERVER_ERROR.INTERNAL_SERVER_ERROR) {
        showConfirmModal(true);
        setHasInternalErrorModal(true);
      }
      if (serverError === SERVER_ERROR.CONFLICT_SERVER_ERROR) {
        showConfirmModal(true);
        setHasConflictErrorModal(true);
        setInternalErrorValidation(true);
      }
      if (serverError === SERVER_ERROR.EMPLOYEE_ID_ALREADY_EXISTS_ERROR) {
        setHasEmployeeIdExistsValidation(true);
      }
    },
  );

  const fieldsEmployment = useEmployeeSetupEmploymentModalFields(idSourceId, lastEmploymentValue);
  const fieldsContactInfo = useEmployeeSetupModalContactInfoFields(
    !!formValues?.phoneNumber1,
    !!formValues?.phoneNumber2,
    formValues?.phone1Type,
    formValues?.phone2Type,
    isSubmit,
  );
  const fieldsMailingAddress = useEmployeeSetupModalMailingAddressFields(
    formValues?.mailingAddress,
  );
  const fieldsDemographics = useEmployeeSetupModalDemographicsFields({
    ssn: formValues?.ssn_demographic,
    sourceId: idSourceId,
    hasInternalErrorValidation,
    clearInternalError: () => setInternalErrorValidation(false),
  });
  const fieldsIdentifiers = useEmployeeSetupIdentifiersModalFields({
    chosenPartnerId: formValues?.partner,
    chosenDistributorId: formValues?.distributor,
    chosenEmployerId: formValues?.employer,
    chosenSubsidiaryId: formValues?.subsidiary,
    idSourceId,
    ssn: formValues?.ssn,
    hasInternalErrorValidation,
    clearInternalError: () => setInternalErrorValidation(false),
    clearEmployeeIdExistsValidation: () => setHasEmployeeIdExistsValidation(false),
    hasEmployeeIdExistsValidation,
  });
  const fieldsAddress = useEmployeeSetupAddressModalFields();

  const changeValues = useCallback((
    values: Partial<EmployeeFields>,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {
    setFormValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  }, []);
  
  const handleChangeAddressValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsAddress, setEmployeeSetupAddress);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeIdentifiersValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsIdentifiers, setEmployeeIdentifiersSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsIdentifiers]);
  const handleChangeDemographicsValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsDemographics, setEmployeeDemographics);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeMailingAddressValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsMailingAddress, setEmployeeMailingAddress);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangesetContactInfoValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsContactInfo, setEmployeeContactInfo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangesetEmploymentValues = useCallback((values: Partial<EmployeeFields>) => {
    changeValues(values, fieldsEmployment, setEmployeeEmployment);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const isAllFormsValid = useMemo(
    () => employeeSetupAddress
    && employeeIdentifiersSetup
    && employeeDemographics
    && employeeMailingAddress
    && employeeContactInfo
    && employeeEmployment,
    [
      employeeContactInfo,
      employeeDemographics,
      employeeEmployment,
      employeeIdentifiersSetup,
      employeeMailingAddress,
      employeeSetupAddress,
    ],
  );

  const isFormDirty = useMemo(() => isIdentifiersSetupDirty
  || isSetupAddressDirty
  || isDemographicsDirty
  || isMailingAddressDirty
  || isContactInfoDirty
  || isEmploymentDirty,
  [
    isContactInfoDirty,
    isDemographicsDirty,
    isEmploymentDirty,
    isIdentifiersSetupDirty,
    isMailingAddressDirty,
    isSetupAddressDirty,
  ]);

  const handleSubmit = useCallback(async () => {
    setSubmit(true);

    if (isAllFormsValid && isSourceIdValid) {
      showConfirmModal(false);
      await onSave(formValues);
    }
  }, [isAllFormsValid, showConfirmModal, onSave, formValues, isSourceIdValid]);

  useEffect(() => {
    if (setIsFormDirty) setIsFormDirty(isFormDirty);
  }, [isFormDirty, setIsFormDirty]);

  return (
    <Box data-testid="employee-create-modal">
      <WarnModal
        testId="new-employee"
        visible={hasInternalErrorModal}
        onSetVisible={setHasInternalErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Employer with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
      <WarnModal
        testId="new-employee-conflict-error-modal"
        visible={hasConflictErrorModal}
        onSetVisible={setHasConflictErrorModal}
        header="The SSN and DOB you provided are not unique, which means they are associated with another user"
        helptext={`Please double-check and ensure you enter personal and unique information for accurate
          identification.`}
        buttonText="Cancel"
        onSubmit={
          () => {
            setHasInternalErrorModal(false);
            scrollToTop();
          }
        }
        submitButtonText="Edit Information"
      />

      <Box direction="column">
        <Box round="container1Round" pad="spacing24" background="module">
          <Box id={ID_SCROLLING} round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              formTitle="Identifiers"
              testId="new-employee-identifiers"
              fields={fieldsIdentifiers}
              editMode
              onChangeValues={handleChangeIdentifiersValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsIdentifiersSetupDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              formTitle="Demographics"
              testId="new-employee-demographics"
              fields={fieldsDemographics}
              editMode
              onChangeValues={handleChangeDemographicsValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsDemographicsDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              formTitle="Address"
              testId="new-employee-address"
              fields={fieldsAddress}
              editMode
              onChangeValues={handleChangeAddressValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsSetupAddressDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              formTitle="Mailing Address"
              testId="new-employee-mailing_address"
              fields={fieldsMailingAddress}
              editMode
              onChangeValues={handleChangeMailingAddressValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsMailingAddressDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              formTitle="Contact info"
              testId="new-employee-contact_info"
              fields={fieldsContactInfo}
              editMode
              onChangeValues={handleChangesetContactInfoValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsContactInfoDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas">
            <FlexControlledForm
              formTitle="Employment"
              testId="new-employee-employment"
              fields={fieldsEmployment}
              editMode
              onChangeValues={handleChangesetEmploymentValues}
              showError={isSubmit}
              formStyle={{
                marginInline: '24px',
              }}
              isModalType
              onDirty={setIsEmploymentDirty}
            />
          </Box>
        </Box>
      </Box>

      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
      >
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="setup_employee_cancel"
            buttonType="secondary"
            width="100%"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
        </SiblingBoxesWithSpacing>
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="setup_employee_confirm"
            width="100%"
            disabled={submitLoading}
            onClick={handleSubmit}
          >
            {submitLoading ? <Preloader color="white" /> : 'Submit'}
          </AppButton>
        </SiblingBoxesWithSpacing>
      </Box>
    </Box>
  );
};
