import { useMemo } from 'react';

import { Option } from '@/common/types';

const useHealthPlanTypes = () => {
  const list: Option[] = useMemo(() => [{
    key: 'MEDICAL',
    value: 'Medical',
    title: 'Medical',
  }, {
    key: 'DENTAL',
    value: 'Dental',
    title: 'Dental',
  }, {
    key: 'VISION',
    value: 'Vision',
    title: 'Vision',
  }, {
    key: 'RX',
    value: 'Rx',
    title: 'Rx',
  }], []);
  return list;
};
export default useHealthPlanTypes;
