import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FieldError, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import { SERVER_ERRORS } from '@/common/constants';
import { usePlanYearFormFields } from '@/modules/plan/PlanYearForm/hooks';
import { usePlanYearStore } from '@/modules/plan/PlanYearForm/stores';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useConfirmStore } from '../ConfirmBlock/store';
import { NEW_PLAN_YEAR_KEY } from '../CreatePlanModal.constants';
import { usePlanNameFields } from '../PlanName/hooks';
import { usePlanNameStore } from '../PlanName/stores';
import { usePlanYearPickerFields } from '../PlanYearPicker/hooks';
import { usePlanYearPickerStore } from '../PlanYearPicker/stores';
import { useCreatePlanQuery, useCreatePlanYearQuery } from '../queries';

const PLAN_YEAR_DUPLICATION_MESSAGE = 'Details: Plan year with such name already exists.';
export default (successCallback?: () => void) => {
  const [planCreationError, setPlanCreationError] = useState(false);

  const [loading, setLoading] = useState(false);
  const { getValidateFields, getErrors } = useValidationUtils();
  const [submitted, setSubmitted] = useState(false);

  const savePlan = useCreatePlanQuery(true);
  const savePlanYear = useCreatePlanYearQuery(true);
  const planNameFields = usePlanNameFields();
  const planName = usePlanNameStore((state) => state.state);
  const handlePlanNameShowError = usePlanNameStore((state) => state.setShowErrors);
  const handlePlanNameReset = usePlanNameStore((state) => state.handleReset);

  const planYearPickerFields = usePlanYearPickerFields();
  const planYearPicker = usePlanYearPickerStore((state) => state.state);
  const handlePlanYearPickerShowError = usePlanYearPickerStore((state) => state.setShowErrors);
  const handlePlanYearPickerReset = usePlanYearPickerStore((state) => state.handleReset);

  const planYear = usePlanYearStore((state) => state.state);
  const planYearFields = usePlanYearFormFields();

  const handlePlanYearShowError = usePlanYearStore((state) => state.setShowErrors);
  const handlePlanYearSetErrors = usePlanYearStore((state) => state.setErrors);
  const handlePlanYearReset = usePlanYearStore((state) => state.handleReset);

  const confirm = useConfirmStore((state) => state.state.confirmed);
  const handleConfirmReset = useConfirmStore((state) => state.handleReset);

  const planNameValidation = useMemo<FieldValues>(
    () => getValidateFields(planNameFields),
    [getValidateFields, planNameFields],
  );
  const planYearPickerValidation = useMemo<FieldValues>(
    () => getValidateFields(planYearPickerFields),
    [getValidateFields, planYearPickerFields],
  );

  const planYearValidation = useMemo<FieldValues>(
    () => getValidateFields(planYearFields),
    [getValidateFields, planYearFields],
  );

  const planNameErrors: FieldError = useMemo(
    () => getErrors(planName, planNameValidation),
    [getErrors, planName, planNameValidation],
  );
  const planYearPickerErrors: FieldError = useMemo(
    () => getErrors(planYearPicker, planYearPickerValidation),
    [getErrors, planYearPicker, planYearPickerValidation],
  );
  const planYearErrors: FieldError = useMemo(
    () => getErrors(planYear, planYearValidation),
    [getErrors, planYear, planYearValidation],
  );
  const handleReset = useCallback(() => {
    handlePlanNameReset();
    handlePlanYearPickerReset();
    handlePlanYearReset();
    handleConfirmReset();
  }, [handleConfirmReset, handlePlanNameReset, handlePlanYearPickerReset, handlePlanYearReset]);

  useEffect(() => () => {
    handleReset();
  }, [
    handlePlanNameReset,
    handlePlanYearPickerReset,
    handlePlanYearReset,
    handleConfirmReset,
    handleReset]);

  const [savedPlanYearId, setSavedPlanYearId] = useState('');
  const handlePreSubmit = useCallback(() => {
    setPlanCreationError(false);
    handlePlanNameShowError(true);
    handlePlanYearPickerShowError(true);
    handlePlanYearShowError(true);
    if (_.isEmpty(planNameErrors)
      && _.isEmpty(planYearPickerErrors) && _.isEmpty(planYearErrors)) {
      setLoading(true);
      setPlanCreationError(false);
      return true;
    }

    return false;
  }, [
    handlePlanNameShowError,
    handlePlanYearPickerShowError,
    handlePlanYearShowError,
    planNameErrors,
    planYearErrors,
    planYearPickerErrors,
  ]);
  const handleSave = useCallback(async () => {
    if (!confirm) {
      setSubmitted(true);
      setLoading(false);
      return;
    }
    setPlanCreationError(false);
    setLoading(true);
    handlePlanNameShowError(true);
    handlePlanYearPickerShowError(true);
    handlePlanYearShowError(true);
    try {
      if (_.isEmpty(planNameErrors)
      && _.isEmpty(planYearPickerErrors) && _.isEmpty(planYearErrors)) {
        let newPlanYearId: string | null = null;
        if (planYearPicker.planYear === NEW_PLAN_YEAR_KEY && !savedPlanYearId) {
          if (planYear.name && planYear.startDate) {
            const response = await savePlanYear({
              name: planYear.name,
              validFrom: planYear.startDate,
              validTo: planYear.endDate,
              copayGroup: planYear.copayGroup,
              payoutDefinitionId: planYear.payoutDefinition
                ? _.toNumber(planYear.payoutDefinition) : undefined,
              priorPlanYearId: planYear.priorPlanYearId,
              priorPlanYear: planYear.priorPlanYear,
            });
            const id = _.get(response, 'data.id') as string;
            newPlanYearId = id;
            setSavedPlanYearId(id);
          }
        }
        await savePlan({
          planYearId: (newPlanYearId || savedPlanYearId)
            ? _.toNumber(newPlanYearId || savedPlanYearId)
            : _.toNumber(planYearPicker.planYear),
          name: planName.planName,
          parentId: _.toNumber(planName.planTemplate?.[0]),
        });
      }
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      const code = _.get(e, 'response.status', 0) as number;
      const errorMeesage = _.get(e, 'response.data.elevate_error_message', '') as string;

      if (code === SERVER_ERRORS.UNAUTHORIZED || !code) {
        if (successCallback) {
          successCallback();
        }
        return;
      }
      if (errorMeesage.includes(PLAN_YEAR_DUPLICATION_MESSAGE)
      ) {
        handlePlanYearSetErrors({
          name: planYear.name,
        });

        return;
      }

      setPlanCreationError(true);
    } finally {
      setLoading(false);
    }
  }, [confirm,
    handlePlanNameShowError,
    handlePlanYearPickerShowError,
    handlePlanYearSetErrors,
    handlePlanYearShowError,
    planNameErrors,
    planYearPickerErrors,
    planYearErrors,
    planYearPicker.planYear,
    savedPlanYearId,
    savePlan,
    planName.planName,
    planName.planTemplate,
    planYear.name,
    planYear.startDate,
    planYear.endDate,
    planYear.copayGroup,
    planYear.priorPlanYearId,
    planYear.priorPlanYear,
    planYear.payoutDefinition,
    savePlanYear,
    successCallback,
  ]);

  return {
    definitionId: planYear.payoutDefinition,
    planTemplate: planName.planTemplate,
    setPlanCreationError,
    planCreationError,
    loading,
    confirm,
    setLoading,
    submitted,
    handleReset,
    handleSave,
    handlePreSubmit,
  };
};
