import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.module};
  border-radius: ${({ theme }) => theme.rounds.moduleRound};
  padding: ${({ theme }) => theme.spacings.spacing24};
  margin-top: ${({ theme }) => theme.spacings.spacing24};
  .enrollment-card {
    flex: 1;
    margin-right: ${({ theme }) => theme.spacings.spacing16};
    ${({ theme }) => theme.shadows.default};
  }
  .expenses-breakdown {
    flex: 2;
    height: 240px;
    margin: ${({ theme }) => theme.spacings.spacing8} 
  }
`;

export const Header = styled.h3`
  ${({ theme }) => theme.fonts.text16Bold};
  color: ${({ theme }) => theme.colors.textBody || ''};
`;

export const HeaderLink = styled.a`
  ${({ theme }) => theme.fonts.text16Medium};
  color: ${({ theme }) => theme.colors.textAccent};
  margin-left: ${({ theme }) => theme.spacings.spacing8};
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacings.spacing16};
`;

export const IconWrapper = styled(Box)`
  .icon {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.iconAccent};
  }
`;
