import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollCalendarsResponse } from '../payrollCalendar.types';

export const QUERY_KEY = 'useGetPayrollCalendarsQuery';

export const useGetPayrollCalendarsQuery = (orgId?: string) => {
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [QUERY_KEY, orgId],
    () => api.get<PayrollCalendarsResponse[]>(PATHS.GET_PAYROLL_CALENDARS(orgId)),
    {
      enabled: !!orgId,
    },
  );

  const total = useMemo(() => data?.data?.length || 0, [data]);
  const updatedData = useMemo(() => data?.data, [data]);
  return {
    data: updatedData,
    total,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
