import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AccordionItem,
  AppButton,
  Box,
  Inscription,
  Preloader,
  RadioButton,
  SearchInput,
  useClickOutside,
} from '@common-fe/common-fe';
import { toString } from 'lodash';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import useGetOrgName from '@/modules/employer/queries/useGetOrgName.query';
import currentTheme from '@/styles/theme';

import BoldSubstring from './components/BoldSubstring';
import FakeSearchInput from './FakeSearchInput';
import useGetOrganizationQuery, { MAX_SIZE, Organization } from './useGetOrganization.query';
import useGetOrganizationHierarchyQuery from './useGetOrganizationHierarchy.query';

const getNumberOfSearchedOrganizations = (
  nestedArray?: Organization[],
  searchedValue?: string,
) => {
  if (!nestedArray?.length || !searchedValue) return 0;

  let counter = 0;

  for (let i = 0; i < nestedArray.length; i++) {
    if (nestedArray[i]?.name?.toLowerCase()?.includes(searchedValue.toLowerCase())) {
      counter += 1;
    }

    if (nestedArray[i]?.children?.length) {
      counter += getNumberOfSearchedOrganizations(nestedArray[i].children, searchedValue);
    }
  }

  return counter;
};

const getTotalString = (total: number, isSearhchUsed: boolean) => {
  if (isSearhchUsed) return total;

  if (total > MAX_SIZE) {
    return `${MAX_SIZE} / ${total}`;
  }

  return total;
};

const MIN_SEARCH_LENGTH = 3;

const AccordionItemWrapper = styled(Box)`
  min-height: 24px;

  svg[aria-label="Chevron Up"] {
    transform: rotate(180deg);
  }
  svg[aria-label="Chevron Down"] {
    transform: rotate(270deg);
  }
  svg[aria-label="Chevron Up"], svg[aria-label="Chevron Down"] {
    fill: ${({ theme }) => theme.colors.iconPrimary};
  }
`;
interface LoadingProps {
  testId?: string;
}
const SmallPreloader: React.FC<LoadingProps> = ({ testId }) => (
  <Box margin={{ left: 'spacing4' }}>
    <div>
      <Preloader testId={testId} size={12} />
    </div>
  </Box>
);
interface Props {
  onChange: (organizatino: Organization) => void;
}

const OrgLevelSelector: React.FC<Props> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedOrganizationName, setSelectedOrganizationName] = useState('');
  const isSearchUsed = useMemo(() => searchValue.length >= MIN_SEARCH_LENGTH, [searchValue]);

  const [partnerParentId, setPartnerParentId] = useState('');
  const [distributorParentId, setDistributorParentId] = useState('');

  const ref = useRef(null);
  const [isShown, setIsShown] = useState(false);
  useClickOutside(ref, () => {
    setIsShown(false);
  });

  const {
    observingOrganization: {
      id: observingOrganizationId, type, path, name,
    },
    observingMode,
  } = useCurrentOrganization();
  const id = toString(observingOrganizationId);

  const isSystemLevel = useMemo(() => type === OrganizationTypes.system,
    [type]);
  const isPartnerLevel = useMemo(() => type === OrganizationTypes.partner,
    [type]);
  const isDistributorLevel = useMemo(() => type === OrganizationTypes.distributor,
    [type]);
  const isCompanyLevel = useMemo(() => type === OrganizationTypes.company,
    [type]);

  const {
    searchedPartners,
    searchedDistributors,
    searchedCompanies,
    isLoading,
  } = useGetOrganizationHierarchyQuery({
    ...isSearchUsed ? { searchValue } : {},
    isCompanyLevel,
    partnerParentId: isPartnerLevel ? id : partnerParentId,
    distributorParentId: isDistributorLevel ? id : distributorParentId,
  });

  const [selectedSystemId, setSelectedSystemId] = useState('');
  const [selectedPartnerId, setSelectedPartnerId] = useState('');
  const [selectedDistributorId, setSelectedDistributorId] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  useEffect(() => {
    if (isSystemLevel && id) {
      setSelectedSystemId(id);
    }
  }, [isSystemLevel, id]);
  useEffect(() => {
    if (isPartnerLevel && id) {
      setSelectedPartnerId(id);
    }
  }, [isPartnerLevel, id]);
  useEffect(() => {
    if (isDistributorLevel && id) {
      setSelectedDistributorId(id);
    }
  }, [isDistributorLevel, id]);
  useEffect(() => {
    if (isCompanyLevel && id) {
      setSelectedCompanyId(id);
    }
  }, [isCompanyLevel, id]);

  const partnersData = useGetOrganizationQuery({
    type: OrganizationTypes.partner,
    ...isSystemLevel && isSearchUsed ? { searchValue } : {},
    enabled: isShown && isSystemLevel
      && !searchedPartners?.length && !isLoading,
  });
  const distributorsData = useGetOrganizationQuery({
    type: OrganizationTypes.distributor,
    ...isPartnerLevel && isSearchUsed ? { searchValue } : {},
    enabled: isShown
      && (Boolean(partnerParentId) || isPartnerLevel)
      && !searchedDistributors?.length && !isLoading,
    parentId: isPartnerLevel ? id : partnerParentId,
  });
  const companiesData = useGetOrganizationQuery({
    type: OrganizationTypes.company,
    ...isDistributorLevel && isSearchUsed ? { searchValue } : {},
    enabled: isShown && (Boolean(distributorParentId) || isDistributorLevel)
      && !searchedCompanies?.length && !isLoading,
    parentId: isDistributorLevel ? id : distributorParentId,
  });
  const total = useMemo(() => {
    if (searchedPartners?.length && isSystemLevel) {
      return getNumberOfSearchedOrganizations(searchedPartners, isSearchUsed ? searchValue : '');
    }

    if (searchedDistributors?.length && isPartnerLevel) {
      return getNumberOfSearchedOrganizations(searchedDistributors, isSearchUsed ? searchValue : '');
    }

    if (searchedCompanies?.length && isDistributorLevel) {
      return searchedCompanies?.length;
    }

    return partnersData.total + distributorsData.total + companiesData.total;
  }, [
    isSystemLevel,
    partnersData.total,
    distributorsData.total,
    companiesData.total,
    searchedPartners,
    searchedDistributors,
    searchedCompanies,
    isDistributorLevel,
    isPartnerLevel,
    isSearchUsed,
    searchValue,
  ]);
  const isDataLoading = useMemo(() => partnersData.isLoading
    || isLoading
    || distributorsData.isLoading
    || companiesData.isLoading, [
    partnersData.isLoading,
    distributorsData.isLoading,
    companiesData.isLoading,
    isLoading,
  ]);

  const [
    systemId,
    partnerId,
    distributorId,
  ] = useMemo(() => {
    if (isSystemLevel) {
      return [id];
    }

    return (path || '').split('\\').slice(1, 5);
  }, [path, isSystemLevel, id]);

  const systemNameData = useGetOrgName({
    id: systemId,
    enabled: !isSystemLevel,
  });
  const partnerNameData = useGetOrgName({
    id: partnerId,
    enabled: !isPartnerLevel && !isSystemLevel,
  });
  const distributorNameData = useGetOrgName({
    id: distributorId,
    enabled: isCompanyLevel,
  });

  useEffect(() => {
    setPartnerParentId('');
    setDistributorParentId('');
  }, [searchValue]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef && inputRef.current && isShown) {
      inputRef.current.focus();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inputRef.current, isShown]);

  const partners = useMemo(() => {
    if (isPartnerLevel) return [{ id, name }];

    if (isDistributorLevel || isCompanyLevel) {
      return [{
        id: partnerId,
        name: partnerNameData.name,
      }];
    }

    if (searchedPartners?.length) return searchedPartners;

    return partnersData.organizations;
  }, [
    searchedPartners,
    isPartnerLevel,
    isDistributorLevel,
    isCompanyLevel,
    partnersData.organizations,
    partnerNameData.name,
    id,
    name,
    partnerId,
  ]);
  const distributors = useMemo(() => {
    if (isDistributorLevel) return [{ id, name }];

    if (isCompanyLevel) {
      return [{
        id: distributorId,
        name: distributorNameData.name,
      }];
    }

    if (searchedDistributors?.length) return searchedDistributors;

    return distributorsData.organizations;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    isDistributorLevel,
    isCompanyLevel,
    id,
    path,
    name,
    distributorId,
    distributorNameData,
    distributorsData,
  ]);
  const companies = useMemo(() => {
    if (isCompanyLevel) return [{ id, name }];

    if (searchedCompanies?.length) return searchedCompanies;

    return companiesData.organizations;
  }, [
    isCompanyLevel,
    id,
    name,
    companiesData.organizations,
    searchedCompanies,
  ]);

  const apply = useCallback(() => {
    const selectedId = selectedSystemId
      || selectedPartnerId
      || selectedDistributorId
      || selectedCompanyId;
    const [selectedOrganizationType] = [
      selectedSystemId ? OrganizationTypes.system : undefined,
      selectedPartnerId ? OrganizationTypes.partner : undefined,
      selectedDistributorId ? OrganizationTypes.distributor : undefined,
      selectedCompanyId ? OrganizationTypes.company : undefined,
    ].filter((orgType) => orgType);
    const selectedOrganization = [...partners, ...distributors, ...companies]
      .find((organization) => organization.id === selectedId) as Organization;

    onChange({
      ...selectedOrganization ? { ...selectedOrganization } : {
        id: selectedId,
        name,
        path,
      },
      type: selectedOrganizationType,
    } as Organization );
    setSelectedOrganizationName(selectedOrganization?.name || name || '');
    setIsShown(false);
  }, [
    onChange,
    selectedSystemId,
    selectedPartnerId,
    selectedDistributorId,
    selectedCompanyId,
    name,
    path,
    partners,
    distributors,
    companies,
  ]);

  return (
    <Box ref={ref}>
      <Box onFocus={() => setIsShown(true)}>
        <FakeSearchInput isActive={isShown}>
          {selectedOrganizationName || name}
        </FakeSearchInput>
      </Box>

      <Box
        width={{ min: '340px' }}
        height="480px"
        background="canvas"
        justify="between"
        round="xs"
        pad={{ top: 'spacing12' }}
        style={{
          position: 'absolute',
          left: 'calc(100% + 2px)',
          boxShadow: currentTheme.shadows.active['box-shadow'],
          top: '-220px',
          ...isShown ? {} : { display: 'none' },
        }}
      >
        <Box>
          <Box
            pad={{ bottom: 'spacing12' }}
            margin={{ bottom: 'spacing16' }}
            border={{
              color: 'border1',
              side: 'bottom',
            }}
            height={{ min: 'fit-content' }}
          >
            <Box pad={{ horizontal: 'spacing16' }}>
              <SearchInput
                inputRef={inputRef}
                placeholder="Search"
                testId="org_level_selection"
                value={searchValue}
                onChange={setSearchValue}
              />
            </Box>
          </Box>

          {isSearchUsed ? (
            <Box pad={{ horizontal: 'spacing16' }} height={{ min: 'fit-content' }}>
              <Box
                margin={{ bottom: 'spacing8' }}
                pad={{ bottom: 'spacing12' }}
                border={{
                  color: 'border1',
                  side: 'bottom',
                }}
              >
                <Inscription
                  color="textSecondary"
                  size="12px"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Results: {isDataLoading ? (
                    <SmallPreloader />

                  ) : getTotalString(total, isSearchUsed)}
                </Inscription>
              </Box>
              {total || isDataLoading ? null : (
                <Inscription lineHeight="20px" color="textBody">No results have been found.</Inscription>
              )}
            </Box>
          ) : null}

          {isSearchUsed && !total ? null : (
            <Box margin={{ bottom: 'spacing8' }}>
              <Box style={{ overflowY: 'auto' }}>
                <AccordionItemWrapper pad={{ horizontal: 'spacing16' }}>
                  <AccordionItem
                    testId="system-level"
                    text={(
                      <Box margin={{ left: '10px' }}>
                        <RadioButton
                          name="system-level"
                          onChange={() => {
                            setSelectedSystemId(isSystemLevel ? id : systemId);
                            setSelectedPartnerId('');
                            setSelectedDistributorId('');
                            setSelectedCompanyId('');
                          }}
                          disabled={!isSystemLevel}
                          label={(
                            <Inscription color="textBody" weight="normal">
                              {isSystemLevel ? name : systemNameData?.name} (System)
                            </Inscription>
                          )}
                          checked={Boolean(selectedSystemId)}
                        />
                      </Box>
                    )}
                    isExpandedDefault
                    isLeftArrow
                  >
                    <Box height={{ min: 'fit-content' }}>
                      <Box margin={{ left: '34px', top: '9px' }}>
                        <Inscription
                          color="textSecondary"
                          size="12px"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Partners: {
                            partnersData.isLoading && !isPartnerLevel
                              ? (
                                <SmallPreloader testId="partners_loading" />
                              )
                              : `(${isPartnerLevel
                                || isDistributorLevel
                                || isCompanyLevel ? 1 : getTotalString(
                                  searchedPartners?.length || partnersData.total, isSearchUsed,
                                )})`
                          }
                        </Inscription>
                      </Box>
                      {partners.map((partner) => (
                        <AccordionItemWrapper
                          key={partner.id}
                          pad={{ left: 'spacing32' }}
                          margin={{ top: '9px' }}
                        >
                          <AccordionItem
                            testId={`partner-level-${partner.id}`}
                            onChange={(value) => {
                              if (value) setPartnerParentId(partner.id);
                            }}
                            text={(
                              <Box margin={{ left: '10px' }}>
                                <RadioButton
                                  name={`partner-level-${partner.id}`}
                                  onChange={() => {
                                    setSelectedSystemId('');
                                    setSelectedPartnerId(isPartnerLevel ? id : partner.id);
                                    setSelectedDistributorId('');
                                    setSelectedCompanyId('');
                                  }}
                                  disabled={isDistributorLevel || isCompanyLevel}
                                  checked={selectedPartnerId === partner.id}
                                  label={(
                                    <BoldSubstring
                                      text={isPartnerLevel ? name : partner?.name}
                                      substring={searchValue}
                                      title={isPartnerLevel ? name : partner?.name}
                                    />
                                  )}
                                />
                              </Box>
                            )}
                            isLeftArrow
                            isExpanded={partner.id === partnerParentId
                              || isDistributorLevel || isCompanyLevel}
                            isExpandedDefault={isPartnerLevel
                              || isDistributorLevel || isCompanyLevel}
                          >
                            <Box margin={{ left: '34px', top: '9px' }}>
                              <Inscription
                                color="textSecondary"
                                size="12px"
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                Distributors: {distributorsData.isLoading
                                  && !isDistributorLevel
                                  && partnerParentId === partner.id
                                  ? (
                                    <SmallPreloader />
                                  )
                                  : `(${isDistributorLevel || isCompanyLevel
                                    ? 1 : getTotalString(
                                      searchedDistributors?.length || distributorsData.total,
                                      isSearchUsed,
                                    )})`}
                              </Inscription>
                            </Box>
                            {distributors.map((distributor) => (
                              <AccordionItemWrapper
                                key={distributor.id}
                                pad={{ left: 'spacing32' }}
                                margin={{ top: '9px' }}
                              >
                                <AccordionItem
                                  testId={`distributor-level-${distributor.id}`}
                                  onChange={(value) => {
                                    if (value) setDistributorParentId(distributor.id);
                                  }}
                                  text={(
                                    <Box margin={{ left: '10px' }}>
                                      <RadioButton
                                        name={`distributor-level-${distributor.id}`}
                                        onChange={() => {
                                          setSelectedSystemId('');
                                          setSelectedPartnerId('');
                                          setSelectedDistributorId(isDistributorLevel
                                            ? id : distributor.id);
                                          setSelectedCompanyId('');
                                        }}
                                        disabled={isCompanyLevel}
                                        checked={selectedDistributorId === distributor.id}
                                        label={(
                                          <BoldSubstring
                                            text={isDistributorLevel ? name : distributor?.name}
                                            substring={searchValue}
                                            width="175px"
                                            title={isDistributorLevel ? name : distributor?.name}
                                          />
                                        )}
                                      />
                                    </Box>
                                  )}
                                  isLeftArrow
                                  isExpanded={distributor.id === distributorParentId
                                    || isCompanyLevel}
                                  isExpandedDefault={isCompanyLevel}
                                >
                                  <Box margin={{ left: '34px', top: '9px' }}>
                                    <Inscription
                                      color="textSecondary"
                                      size="12px"
                                      style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      Employers: {companiesData.isLoading
                                        && !isCompanyLevel
                                        && distributorParentId === distributor.id
                                        ? (
                                          <SmallPreloader />
                                        )
                                        : `(${isCompanyLevel ? 1 : getTotalString(
                                          searchedCompanies?.length || companiesData.total,
                                          isSearchUsed,
                                        )})`}
                                    </Inscription>
                                  </Box>
                                  {companies.map((company) => (
                                    <Box key={company.id} margin={{ left: '34px', top: '10px' }}>
                                      <RadioButton
                                        name={`company-level-${company.id}`}
                                        onChange={() => {
                                          setSelectedSystemId('');
                                          setSelectedPartnerId('');
                                          setSelectedDistributorId('');
                                          setSelectedCompanyId(isCompanyLevel
                                            ? id : company.id);
                                        }}
                                        checked={selectedCompanyId === company.id}
                                        label={(
                                          <BoldSubstring
                                            text={isCompanyLevel ? name : company?.name}
                                            substring={searchValue}
                                            width="170px"
                                            title={isCompanyLevel ? name : company?.name}
                                          />
                                        )}
                                      />
                                    </Box>
                                  ))}
                                </AccordionItem>
                              </AccordionItemWrapper>
                            ))}
                          </AccordionItem>
                        </AccordionItemWrapper>
                      ))}
                    </Box>
                  </AccordionItem>
                </AccordionItemWrapper>
              </Box>
            </Box>
          )}
        </Box>
        <Box height={{ min: 'fit-content' }}>
          <Box
            justify="between"
            align="center"
            direction="row"
            pad={{ horizontal: 'spacing16' }}
            height="70px"
            border={{
              color: 'border1',
              side: 'top',
            }}
          >
            <AppButton
              testId="cancel"
              buttonType="secondary"
              onClick={() => setIsShown(false)}
              width="145px"
            >
              Cancel
            </AppButton>
            <AppButton
              testId="apply"
              onClick={apply}
              width="145px"
            >
              Apply
            </AppButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrgLevelSelector;
