import { CardTypesEnum } from '@common-fe/common-fe';

import { Address, Country } from '@/common/types';
import { BankTypeEnum } from '@/modules/transaction/Cards/Cards.types';

export enum DefaultClaimPayeeType {
  participant = 'PARTICIPANT',
  provider = 'PROVIDER',
  employeeChoice = 'EMPLOYEE_CHOICE',
}

export enum PaymentMethodType {
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  DEBIT = 'DEBIT',
  VENMO = 'VENMO',
  PAYPAL = 'PAYPAL',
  CHECK = 'CHECK',
}

export enum StatusNames {
  A = 'Active',
  T = 'Terminated',
  L = 'Leave of absence',
  R = 'Retirement',
  F = 'Future',
  C = 'COBRA',
}

export interface TransferAccountPayload {
  id?: number;
  name?: string;
  amount?: number;
}
export interface TransferAccountsPayload {
  recommended?: TransferAccountPayload[];
  other?: TransferAccountPayload[];
}
export interface TransferAccount {
  id?: string;
  name?: string;
  amount?: number;
}
export interface TransferAccounts {
  recommended?: TransferAccount[];
  other?: TransferAccount[];
}

export enum ClaimAutopayType {
  auto = 'AUTO',
  click = 'CLICK',
  employeeChoice = 'EMPLOYEE_CHOICE',
}

export enum EmployeeGenderType {
  male = 'M',
  female = 'F',
  other = 'O',
}

export enum EmployeeEmailType {
  home = 'HOME',
  work = 'WORK',
  other = 'OTHER',
}

export const PAYMENT_METHOD_TITLE = {
  [PaymentMethodType.DIRECT_DEPOSIT]: 'Direct Deposit',
  [PaymentMethodType.DEBIT]: 'My Personal Debit Card',
  [PaymentMethodType.VENMO]: 'Venmo',
  [PaymentMethodType.PAYPAL]: 'PayPal',
  [PaymentMethodType.CHECK]: 'Check',
};

export enum PaymentMethodOwnerType {
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
  PROVIDER = 'PROVIDER',
}

export enum PaymentStatus {
  VERIFIED = 'VERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NEW = 'NEW',
}

export interface PaymentMethodDtoPayload {
  id: number;
  payment_method_type?: PaymentMethodType;
  is_default?: boolean;
  account_id?: number;
  recipient_id?: number;
  email?: string;
  phone_number?: string;
  payment_method_status?: PaymentStatus;
  account_number?: string;
  account_description?: string;
  account_number_last4?: string;
  payment_address?: Address;
  payment_method_owner?: {
    organization_id?: number;
    payment_method_owner_type?: string;
    employee_id?: number;
    provider_id?: number;
  };
  requisites?: {
    phone_number?: string;
    email?: string;
    card_holder_name?: string;
    card_expiration_date?: string;
    card_last4?: string;
    card_type?: BankTypeEnum;
  },
}

export interface PaymentMethod {
  isChecked?: boolean;
  id: string;
  type?: PaymentMethodType;
  isDefault?: boolean;
  accountId?: string;
  recipientId?: string;
  email?: string;
  phoneNumber?: string;
  status?: PaymentStatus;
  accountNumber?: string;
  accountDescription?: string;
  accountNumberLast4?: string;
  address?: Address,
  paymentMethodOwner?: {
    id?: string;
    ownerType?: PaymentMethodOwnerType;
    employeeId?: string;
    providerId?: string;
    organizationId?: string;
  };
  isVerified?: boolean;
  requisites?: {
    phoneNumber?: string;
    email?: string;
    cardHolderName?: string;
    cardExpirationDate?: string;
    cardLast4?: string;
    cardType?: BankTypeEnum;
  },
}

export enum EmployeeAddressType {
  poBox = 'PO_BOX',
  residential = 'RESIDENTIAL',
  business = 'BUSINESS',
  unknown = 'UNKNOWN',
}

export enum SourceIds {
  SSN = 'SSN',
  EEID = 'EMPLOYER_EMPLOYEE_ID',
  PEID = 'PARTNER_EMPLOYEE_ID',
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  status: string;
  ssn: string;
  last4Ssn?: string;
  employer: string;
  employerEmployeeId: string;
  partnerEmployeeId: string;
  isReimbursementAllowed?: boolean;
  employerName?: string;
  subsidiaryName?: string;
}

export interface EnrollmentBalance {
  id: string;
  type: CardTypesEnum;
  availableBalance: number;
  election: number;
  effectiveDate?: Date | null;
  spentItByDate?: Date | null;
  endDate?: Date | null;
  claimItByDate?: Date | null;
  contributions?: {
    employee: number;
    employer: number;
  }

}

export interface EmployeeEmailDto {
  id?: number;
  address: string;
  preferred: boolean;
  valid_from?: string;
  valid_to?: string;
  email_type?: EmployeeEmailType;
}

export interface EmployeeAddressDto {
  id: number;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  city: string;
  state: string;
  zipcode: string;
  country_id: number;
  address_type: EmployeeAddressType;
}

export interface EmployeePreferredAddressDto {
  address: EmployeeAddressDto;
  valid_from: string;
  valid_to: string;
  preferred_mailing: boolean;
  preferred_physical: boolean;
}

export interface EmployeeOrganizationDto {
  communication_channels: string[];
  organization_id: number;
  organization_path: string;
  organization_name: string;
}

export interface EmployeeCommunicationConfigurationDto {
  communication_config_channels: string[];
  notification_priority: string;
}

export interface EmployeePersonalInfo {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  email: string;
  phoneCell: string;
  phoneHome: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  userName: string;
  password: string;
  claimAutopayType?: ClaimAutopayType;
  defaultClaimPayeeType?: DefaultClaimPayeeType;
  employerEmployeeId?: string | null;
  partnerEmployeeId: string | null;
  employerName: string;
  communicationChannels: string[];
  communicationConfigChannels?: string[];
  notificationPriority?: string;
  employerStatus: string;
  ssn: string;
  personId: string;
  uniqueId: string;
  payrollGroup: string;
  subsidiary: string;
  hireDate: string;
  terminationDate: string;
  organizationId: string;
  organizationPath: string;
}

export interface Dependent {
  id: number;
  firstName: string;
  lastName: string;
  relationshipType: string;
  birthOn: string;
}

export enum InvestorStatus {
  PENDING_INVESTMENT_PATH = 'PENDING_INVESTMENT_PATH',
  PENDING_QUESTIONNAIRE = 'PENDING_QUESTIONNAIRE',
  PENDING_INVESTMENT_AMOUNT = 'PENDING_INVESTMENT_AMOUNT',
  PENDING_USER_APPROVE = 'PENDING_USER_APPROVE',
  PENDING_EXTERNAL_CREATION = 'PENDING_EXTERNAL_CREATION',
  ACTIVE = 'ACTIVE',
}