import React, { useCallback, useMemo } from 'react';
import { AppButton, Box, Hint, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { CardDto, CardStatusEnum } from '../Cards.types';
import { useShouldShowCardActions } from '../hooks/useShouldShowCardActions';
import useCardActivationModalStore from '../store/useCardActivationModal.store';
import useCardReplaceModalStore from '../store/useCardReplaceModal.store';
import { useSetCardStatusModal } from '../store/useSetCardStatusModal.store';

import { useRequestedActivationCards } from './CardActivationModals/hooks/useRequestedActivationCards';

const StyledBox = styled(Box)`
  flex: 1;
`;

interface Props {
  card: CardDto
}

export const CardItemActions: React.FC<Props> = ({ card }) => {
  const {
    status,
    holder,
    id,
    mailedDate,
  } = card;
  const { showActions, readyForActivation } = useShouldShowCardActions(status, !!mailedDate);

  const {
    handleChangeState: cardStatusChangeState,
  } = useSetCardStatusModal();
  const {
    handleChangeVisibility,
    handleChangeCardHolderName,
    handleChangeCardHolderID,
    handleChangeCardID,
    handleChangeCardStatus,
    handleChangeMailedDate,
    handleChangeActivationDate,
  } = useCardReplaceModalStore();

  const {
    handleChangeVisibility: handleChangeActivationVisibility,
    handleChangeCardID: handleChangeActivationCardID,
    handleChangeLast4Numbers,
    handleChangeAlternateAccountId,
    handleChangeMemberSequenceIdentifier,
  } = useCardActivationModalStore();

  const setCardInfo = useCallback(() => {
    handleChangeCardHolderName(holder.name);
    handleChangeCardHolderID(holder.dependentId);
    handleChangeCardID(id);
    handleChangeCardStatus(status);
    handleChangeMailedDate(card.mailedDate);
    handleChangeActivationDate(card.activationDate);
  },
  [
    handleChangeCardHolderID,
    handleChangeCardHolderName,
    handleChangeCardStatus,
    handleChangeCardID,
    holder.dependentId,
    holder.name,
    id,
    status,
    handleChangeMailedDate,
    handleChangeActivationDate,
    card,
  ]);

  const setCardActivationInfo = useCallback(() => {
    handleChangeActivationCardID(id);
    handleChangeLast4Numbers(card.last4Number);
    handleChangeAlternateAccountId(card.alternateAccountId);
    handleChangeMemberSequenceIdentifier(card.memberSequenceIdentifier);
  },
  [
    handleChangeActivationCardID,
    id,
    handleChangeLast4Numbers,
    card,
    handleChangeMemberSequenceIdentifier,
    handleChangeAlternateAccountId,
  ]);

  const onCardReplacementModal = useCallback(() => {
    setCardInfo();
    handleChangeVisibility();
  }, [handleChangeVisibility, setCardInfo]);

  const onCardActivationModal = useCallback(() => {
    setCardActivationInfo();
    handleChangeActivationVisibility(true);
  }, [handleChangeActivationVisibility, setCardActivationInfo]);
  const { isCardRequestedToActivate } = useRequestedActivationCards({
    cardId: id,
    cardStatus: status,
    last4: card.last4Number,
  });

  const isReplaceButtonDisabled = useMemo(() => {
    const today = dayjs().startOf('day');
    const sixDaysAgo = dayjs().subtract(6, 'day').startOf('day');
    const mailedDate = dayjs(card.mailedDate).startOf('day');
    if (!card.mailedDate) return true;
    return mailedDate >= sixDaysAgo && mailedDate <= today;
  }, [card.mailedDate]);

  if (!card) {
    return null;
  }
  return (
    <Box direction="row" justify="between">
      {
        readyForActivation && !isCardRequestedToActivate(id) && (
          <StyledBox margin={{ right: 'spacing12' }}>
            <AppButton
              width="100%"
              onClick={onCardActivationModal}
            >
              Activate Card
            </AppButton>
          </StyledBox>
        )
      }
      {
        showActions && (
          <StyledBox margin={{ right: 'spacing12' }}>
            <AppButton
              buttonType="secondary"
              width="100%"
              onClick={() => cardStatusChangeState({
                cardId: card.id,
              })}
            >
              {
                card.status === CardStatusEnum.FROZEN
                || card.status === CardStatusEnum.PENDING_CLOSED
                || card.status === CardStatusEnum.PENDING_OPEN
                  ? 'Unlock/Close Card'
                  : 'Lock/Close Card'
              }
            </AppButton>
          </StyledBox>
        )
      }
      {
        (showActions || status === CardStatusEnum.PENDING_OPEN) && (
          <StyledBox>
            <Hint
              hintAlign="right"
              hideHint={!isReplaceButtonDisabled}
              disabled={isReplaceButtonDisabled}
              hintElement={(
                <Box>
                  <AppButton
                    width="100%"
                    buttonType="secondary"
                    disabled={isReplaceButtonDisabled}
                    onClick={onCardReplacementModal}
                  >
                    Replace Card
                  </AppButton>
                </Box>
              )}
            >
              <Inscription size="12px">
                Your card is on its way and should arrive soon.
              </Inscription>
            </Hint>
          </StyledBox>
        )
      }
    </Box>
  );
};
