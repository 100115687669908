import { useMemo } from 'react';

import useCustomReportsFields from '../CustomReports/hooks/useCustomReportsFields';
import { useEmployeeSSN,useEmployerInfoFields } from '../EmployerInfo/hooks';
import { usePayrollGroupCodeField } from '../EmployerInfo/hooks/usePayrollGroupCodeField';
import { useGeneralInfoFields } from '../GeneralInfo/hooks';
import { usePreferredMailingAddressFields } from '../PreferredMailingAddress/hooks';
import { usePreferredPhysicalAddressFields } from '../PreferredPhysicalAddress/hooks';

export default () => {
  const generalFields = useGeneralInfoFields(true);
  const customReportsFields = useCustomReportsFields({
    isEditMode: true
  });
  const preferredMailingAddressFields = usePreferredMailingAddressFields(true);
  const preferredPhysicalAddressFields = usePreferredPhysicalAddressFields(true);
  const employerInfoFields = useEmployerInfoFields({ isEditMode: true });
  
  const {ssnField} = useEmployeeSSN({});
  const {payrollGroupField} = usePayrollGroupCodeField({});

  const fields = useMemo(() => [
    ...generalFields,
    ...customReportsFields,
    ...preferredMailingAddressFields,
    ...preferredPhysicalAddressFields,
    ...employerInfoFields,
    ...payrollGroupField ? [payrollGroupField] : [],
    ssnField

  ], [employerInfoFields, generalFields,
    customReportsFields,
    ssnField,
    payrollGroupField,
    preferredMailingAddressFields,
    preferredPhysicalAddressFields]);

  return fields;
};
