import React from 'react';
import { Anchor, Box, Text } from '@common-fe/common-fe';

interface Props {
  onClick?: () => void;
}
const EditModeSwitcher: React.FC<Props> = ({
  onClick,
}) => (
  <Box direction="row" margin={{ top: 'spacing24' }}>
    <Text>To apply any changes, please switch to
      <Anchor onClick={onClick} margin={{ left: 'spacing4' }}>
        Edit Mode
      </Anchor>.
    </Text>

  </Box>
);

export default EditModeSwitcher;
