import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';

import { FileError,FileErrorDto } from '../types/fileReport.constants';

export const QUERY_KEY = 'useFileErrors';

export interface FileErrorsParams {
  fileId: string,
  page?: number,
  perPage?: number,
  sort?: string,
  enabled?: boolean,
}

export interface ResponseData {
  content: FileErrorDto[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

const formatData = (data: FileErrorDto[]): FileError[] => data.map(
  (fileError: FileErrorDto) => ({
    id: toString(fileError.id),
    fileId: toString(fileError.file_id),
    errorDetails: fileError.error_details,
    fileErrorType: fileError.file_error_type,
    createdAt: fileError.created_at,
  }),
);

const useFileErrorsQuery = ({
  fileId, page, perPage, sort, enabled,
}: FileErrorsParams) => {
  const params = useMemo(() => ({
    page,
    size: perPage,
    sort,
  }), [page, perPage, sort]);

  const {
    data, isLoading, isSuccess, isError,
  } = useQuery(
    [QUERY_KEY, page, perPage],
    () => api.get<Pagination<FileErrorDto>>(
      PATHS.GET_FILE_ERRORS(fileId),
      { params },
    ),
    { enabled },
  );

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);
  const fileErrorType = useMemo(
    () => data?.data?.content?.[0]?.file_error_type || undefined,
    [data],
  );

  return ({
    formattedData: formatData(data?.data.content || []),
    data: data?.data?.content as FileErrorDto[] || [],
    fileErrorType,
    total,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useFileErrorsQuery;
