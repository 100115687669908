import { useCallback, useState } from 'react';
import _ from 'lodash';

import { CreateContactFormModel } from '@/modules/employer/types/Contact.types';
import getErrorsMap, { Result } from '@/utils/handlers/getErrorsMap';

export const USERNAME_ERROR_SUBSTRING = 'name already exists.';
export const UNIQUE_ID_ERROR_SUBSTRING = 'already exists for Organization';

export default () => {
  const [createErrorModal, seCreateErrorModal] = useState(false);
  const [updateErrorModal, setUpdateErrorModal] = useState(false);
  const [errors, setErrors] = useState<Result>({});
  const handleSetErrors = useCallback((errorsResponse: object, data: CreateContactFormModel) => {
    const result = getErrorsMap(errorsResponse);

    const errorMessage = _.get(errorsResponse, 'response.data.elevate_error_message', '') as string;
    if (result.phone) {
      result.phone = data.phoneNumber;
    }
    if (result.username) {
      result.username = data.userName;
    }
    if (errorMessage.includes(USERNAME_ERROR_SUBSTRING)) {
      result.usernameExists = data.userName;
    }
    if (errorMessage.includes(UNIQUE_ID_ERROR_SUBSTRING)) {
      result.uniqueId = data.externalIdentifier;
    }
    if (result.email) {
      result.email = data.email;
    }

    setErrors(result);
    return result;
  }, []);
  return {
    errors,
    handleSetErrors,
    createErrorModal,
    seCreateErrorModal,
    updateErrorModal,
    setUpdateErrorModal,
  };
};
