import { baseStoreCreator } from '@/utils/store';

export interface CardOfferedPayload {
  cardOffered: string;
  fundID: string | null;
  fundIDFormula: string;
  fundIdFormulaOptions?: string;
}

export const DEFAULT_VALUES: CardOfferedPayload = {
  cardOffered: '',
  fundIDFormula: '',
  fundID: '',
  fundIdFormulaOptions: '',
};

export const useStore = baseStoreCreator<CardOfferedPayload>(DEFAULT_VALUES);

export default useStore;
