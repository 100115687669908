import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { AddCardDependentModal } from '../AddCardDependentModal/AddCardDependentModal';

export const CardDetailsHeader = () => (
  <Box margin={{ vertical: 'spacing12' }} direction="row" justify="between" align="center" data-testid="CardDetailsHeader-wrapper">
    <Text size="20px" weight="bold" data-testid="CardDetailsHeader-holder-name">Cards</Text>
    <Box direction="row" justify="end" align="center">
      <AddCardDependentModal />
    </Box>
  </Box>
);
