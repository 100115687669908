import { useMemo } from 'react';

import { useStore as useAccountFundingParamsStore } from '../AccountFundingParams/stores/useAccountFundingParams.store';
import { useStore as useContributionStore } from '../Contribution/stores/useContribution.store';
import { useStore as useInterestAccrualsStore } from '../InterestAccruals/stores/useInterestAccruals.store';
import { useStore as useRolloverStore } from '../Rollover/stores/useRollover.store';

export default () => {
  const rolloverStore = useRolloverStore((state) => state.sourceState);
  const accountFundingParamsStore = useAccountFundingParamsStore((state) => state.sourceState);
  const contributionStore = useContributionStore((state) => state.sourceState);

  const interestAccrualsStore = useInterestAccrualsStore((state) => state.sourceState);

  const state = useMemo(() => ({
    ...accountFundingParamsStore,
    ...rolloverStore,
    ...contributionStore,
    ...interestAccrualsStore,
  }), [accountFundingParamsStore, contributionStore,
    interestAccrualsStore, rolloverStore]);
  return state;
};
