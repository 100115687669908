import React from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

interface Props {
  hasNext?: boolean;
  onClick: () => void;
}

const LoadNextEmployeesButton: React.FC<Props> = ({ hasNext, onClick }) => (
  <Box data-testId="employees-search_load-more-button" {...hasNext ? { onClick } : {}}>
    <Inscription
      weight={500}
      color={hasNext ? 'navigationAccent' : 'textDisabled'}
    >
      {hasNext ? 'Load More' : 'End of the list'}
    </Inscription>
  </Box>
);

export default LoadNextEmployeesButton;
