import { baseStoreCreator } from '@/utils/store';

export interface PreferredMailingAddressPayload {
  firstAddressLine: string;
  secondAddressLine: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;

}

export const DEFAULT_VALUES: PreferredMailingAddressPayload = {
  firstAddressLine: '',
  secondAddressLine: '',
  city: '',
  state: '',
  zipCode: '',
  countryCode: '',
};

export const useStore = baseStoreCreator<PreferredMailingAddressPayload>(DEFAULT_VALUES);

export default useStore;
