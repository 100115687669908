 
import React, {
  useCallback,
  useEffect,   useMemo, useState, } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { Box, Inscription } from '@common-fe/common-fe';

import { Roles } from '@/common/constants';
import { TipSide, TipWrapper } from '@/components/wrappers/TipWrapper';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization,useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import { EmployeeSetupFormModal } from '@/modules/employer/components/EmployeeSetupModal/EmployeeSetupFormModal';
import { EmployeeSetupModalWrapper } from '@/modules/employer/components/EmployeeSetupModal/EmployeeSetupModalWrapper';
import { EmployerStatus } from '@/modules/employer/employer.constants';

import { EmployeeStatus } from '../employee.constants';

const CONFIRM_TEXT = 'Changes that you made may not be saved.';
const MODAL_ID = 'add_employee_modal';

const TopSection = () => {
  const {
    observingOrganization: {
      status,
    },
  } = useCurrentOrganization();
  const [hasErrorModal, setHasErrorModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const query = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.SHOULD_OPEN_MODAL),
    [location.search],
  );
  const employerId = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.OBSERVING_EMPLOYER_QUERY_KEY),
    [location.search],
  );
  const partnerId = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.OBSERVING_PARTNER_QUERY_KEY),
    [location.search],
  );
  const distributorId = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.OBSERVING_DISTRIBUTOR_QUERY_KEY),
    [location.search],
  );
  const subsidiaryId = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.OBSERVING_SUBSIDIARY_QUERY_KEY),
    [location.search],
  );

  useEffect(() => {
    if (query) {
      setModalVisible(true);
      history.removeQueryParameter([QueryKeys?.SHOULD_OPEN_MODAL]);
      if (employerId) {
        history.replaceQueryParameter({ observingEmployerId: employerId as string });
      }
      if (partnerId) {
        history.replaceQueryParameter({ observingPartnerId: partnerId as string });
      }
      if (distributorId) {
        history.replaceQueryParameter({ observingDistributorId: distributorId as string });
      }
      if (subsidiaryId) {
        history.replaceQueryParameter({ observingSubsidiaryId: subsidiaryId as string });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, history]);

  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && isFormDirty && showConfirmModal) {
      return window.confirm(CONFIRM_TEXT) && setModalVisible(value);
    }
    return setModalVisible(value);
  }, [isFormDirty, showConfirmModal]);

  return (
    <>
      <Prompt
        when={isFormDirty && modalVisible && showConfirmModal}
        message={CONFIRM_TEXT}
      />
      <Box
        margin={{ bottom: 'spacing16', top: 'spacing24' }}
        direction="row"
        justify="between"
        align="center"
      >
        <Box>
          <Inscription
            size="3xl"
            weight="bold"
            color="textTitle"
            lineHeight="40px"
          >Employees
          </Inscription>
        </Box>
        <Box>
          <Access accessRequirements={[
            { role: Roles.superUser },
            { role: Roles.admin1 },
            { role: Roles.admin2 },
            { role: Roles.admin3 },
          ]}
          >
            <TipWrapper
              description="Once the organization is active, you will be able to add employees."
              side={TipSide.left}
              isActive={!status || status === EmployerStatus.Pending}
            >
              <EmployeeSetupModalWrapper
                id={MODAL_ID}
                visible={modalVisible}
                testId="AddNewEmployee"
                onSetVisible={onSetVisibleHandler}
                buttonText="Add Employee"
                modalTitle="Add Employee"
                disabled={status !== EmployeeStatus.Active}
                hasErrorModal={hasErrorModal}
              >
                <EmployeeSetupFormModal
                  modalId={MODAL_ID}
                  showConfirmModal={setShowConfirmModal}
                  onCancel={() => onSetVisibleHandler(false)}
                  setIsFormDirty={setIsFormDirty}
                  setHasErrorModal={setHasErrorModal}
                />
              </EmployeeSetupModalWrapper>
            </TipWrapper>
          </Access>
        </Box>
      </Box>
    </>
  );
};
export default TopSection;
