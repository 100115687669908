import { useMemo } from 'react';
import { ListItem, ListItemBase, ListItemType } from '@common-fe/common-fe';

import { useGetPayrollGroupsQuery } from '../queries/useGetPayrollGroups.query';

export const usePayrollGroupTableItems = (orgId?: string) => {

  const { data, refetch } = useGetPayrollGroupsQuery(orgId);

  const headers: ListItemBase[] = useMemo(() => [{
    key: 'groupName',
    title: 'Name',
  }, {
    key: 'groupCode',
    title: 'Code',
  },
  {
    key: 'options',
    title: '',
    flex: 0.05,
  }], []);

  const formattedList = useMemo<ListItem[]>(() => 
    data?.map((item) => {
      return {
        id: item?.id || '',
        fields: [
          {
            key: 'groupName',
            type: ListItemType.Text,
            title: item?.name || '-',
            style: { fontWeight: 'bold' },
          },
          {
            key: 'groupCode',
            type: ListItemType.Text,
            title: item?.code || '-',
          },
        ],
      };
    }) || [], [data]);

  const payrollGroupNames = useMemo(() => data?.map((item) => item?.name) || [], [data]);
  const payrollGroupCodes = useMemo(() => data?.map((item) => item?.code) || [], [data]);
  return {
    data,
    formattedList,
    headers,
    refetch,
    payrollGroupNames,
    payrollGroupCodes,
  };
};
