import { useMemo } from 'react';
import { Field, FieldError } from '@common-fe/common-fe';
import _ from 'lodash';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useCoverageLevelsFields, useCoverageLevelsState } from '../../CoverageLevels/hooks';
import { getAccountFundingItemsValidation } from '../../CoverageLevels/hooks/useCoverageLevelsFields';

interface Params {
  isTemplateMode?: boolean, isActiveMode?: boolean
}
interface CoverageMap{
  [key: string]: string | number | boolean;

}
export default (params: Params) => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const coverageLevelsState = useCoverageLevelsState();
  const coverageLevelsFields = useCoverageLevelsFields('', {
    isTemplateMode: params.isTemplateMode,
    isActiveMode: params.isActiveMode,
  });

  const coveragesErrors = useMemo(() => {
    const { coverageLevels } = coverageLevelsState;

    let allErrors: FieldError = {};

    coverageLevels.forEach((state) => {
      const planFields: Field[] = state?.accountFundingConfigs
        ? state?.accountFundingConfigs?.map(getAccountFundingItemsValidation) : [];
      const formatedState = {
        ...state,
      } as unknown as CoverageMap;
      if (state?.accountFundingConfigs) {
        state?.accountFundingConfigs.forEach((item) => {
          if (!formatedState[item.planId]) {
            formatedState[item.planId] = item.autoEnrollmentAmount || '';
          }
        });
      }

      const errors = getErrors(formatedState,
        getValidateFields([...coverageLevelsFields, ...planFields]));
      Object.keys(errors).forEach((key) => {
        const item = errors[key];
        if (!_.isNaN(+key)) {
          delete errors[key];
          errors.coverageAccountFunding = item;
        }
      });
      allErrors = {
        ...allErrors,
        ...errors,
      };
    });

    return allErrors;
  }, [coverageLevelsFields, coverageLevelsState, getErrors, getValidateFields]);
  return coveragesErrors;
};
