import React, { useEffect,useMemo, useState } from 'react';
import { AppButton, Box, Preloader, Text, WarnModal } from '@common-fe/common-fe';

import theme from '@/styles/theme';

import { ExpressSetup, ExpressSetupWizardStepTitle, ExpressSetupWizardStepType } from '../ExpressSetup.types';
import ExpressSetupActivateModal from '../ExpressSetupActivateModal';
import { usePlansStore } from '../ExpressSetupWizard/PlansWizard/Plans/stores';
import { SAVE_ERROR_TEXT } from '../ExpressSetupWizard/PlansWizard/PlansWizard'; 
import { useActivationAllowed, useHandleExpressSetupSetUp } from '../hooks';

import ProgressBarActivationContent from './ProgressBarActivationContent';
import ProgressBarItem from './ProgressBarItem'; 

interface Props {
  expressSetupData?: ExpressSetup;
  isBannerMode?: boolean;
  stepName?: ExpressSetupWizardStepType;
  isDisabled?: boolean;
  isActivateMode?: boolean;
}

const ProgressBar: React.FC<Props> = ({
  expressSetupData,
  isBannerMode,
  stepName,
  isDisabled,
  isActivateMode,
}) => {
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [isVisibleExpressSetupPopup, setVisibleActivateExpressSetupPopup] = useState(false);
  const plansState = usePlansStore((state) => state.state);
  const [isSaveErrorModalVisible, setSaveErrorModalVisible] = useState<boolean>(false);
  const isActivationAllowed = useActivationAllowed(expressSetupData);
  const { onSavePlansHandler, isPlansLoading, isPlansServerError } = useHandleExpressSetupSetUp();
  const progressBarOptions = useMemo(() => [
    { title: ExpressSetupWizardStepTitle[ExpressSetupWizardStepType.MainContact], isEntityExist: !!expressSetupData?.mainContact, url: `#${ExpressSetupWizardStepType.MainContact}`, isActive: stepName === ExpressSetupWizardStepType.MainContact },
    { title: ExpressSetupWizardStepTitle[ExpressSetupWizardStepType.BankAccount], isEntityExist: !!expressSetupData?.accountNumber, url: `#${ExpressSetupWizardStepType.BankAccount}`, isActive: stepName === ExpressSetupWizardStepType.BankAccount },
    { title: ExpressSetupWizardStepTitle[ExpressSetupWizardStepType.Plans], isEntityExist: !!expressSetupData?.planEndDate && !!expressSetupData?.plans?.length, isLastItem: true, url: `#${ExpressSetupWizardStepType.Plans}`, isActive: stepName === ExpressSetupWizardStepType.Plans },
  ], [stepName, expressSetupData]);

  useEffect(() => {
    if (isPlansServerError) setErrorModal(true);
  }, [isPlansServerError]);

  if (isActivateMode) {
    return (
      <ProgressBarActivationContent
        orgName={expressSetupData?.name}
        orgPath={expressSetupData?.orgPath}
        orgId={expressSetupData?.orgId}
      />
    );
  }
  
  return (
    <Box
      width="304px"
      round="container1Round"
      style={{ overflow: 'hidden' }}
      data-testId="express-setup-progress-bar"
      pad={{ horizontal: 's', bottom: 's', top: 'm' }}
      margin={{ bottom: 's' }}
      background={isBannerMode ? 'border1' : 'module'}
    >
      <Box direction="row" justify="between" pad={{ horizontal: 's' }}>
        <Text size="16px" weight={700} style={{ lineHeight: '22px' }} color="textTitle">Progress</Text>
        {/* <Text size="16px" style={{ lineHeight: '22px' }} color="textSecondary">Need Help?</Text> */}
      </Box>
      <Box
        width="100%"
        round="container1Round"
        background="canvas"
        pad={{ horizontal: 's', vertical: 'm' }}
        margin={{ vertical: 's' }}
      >
        {progressBarOptions.map((item, index) => (
          <ProgressBarItem
            key={index}
            title={item.title}
            isLastItem={item.isLastItem}
            isEntityExist={item.isEntityExist}
            expressSetupData={expressSetupData}
            url={item.url}
            isActive={item.isActive}
            isDisabled={isDisabled}
          />
        ))}
      </Box>
      {
        !!plansState?.plans?.length && stepName === ExpressSetupWizardStepType.Plans && (
          <AppButton
            onClick={() => onSavePlansHandler(() => setSaveErrorModalVisible(true))}
            testId="save-plans-form"
            disabled={isPlansLoading}
            buttonType="secondary"
            style={{ width: '280px', height: '54px', marginBottom: theme.spacings.s }}
          >
            {isPlansLoading ? <Preloader color="white" /> : 'Save'}
          </AppButton>
        )
      }
      <AppButton
        onClick={() => setVisibleActivateExpressSetupPopup(true)}
        width="280px"
        disabled={!isActivationAllowed}
        style={{ height: '54px' }}
      >
        Activate Organization
      </AppButton>
      <ExpressSetupActivateModal
        isVisible={isVisibleExpressSetupPopup}
        expressSetup={expressSetupData}
        onSetVisible={setVisibleActivateExpressSetupPopup}
      />
      <WarnModal
        visible={isSaveErrorModalVisible}
        onSetVisible={setSaveErrorModalVisible}
        header={SAVE_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        testId="plans-plan_year-wizzard-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot update the Plans with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={() => onSavePlansHandler()}
        submitButtonText="Try again"
      />
    </Box>
  );
};

export default ProgressBar;
