import React from 'react';
import {
  Box,
  ContactsIcon,
  Heading,
  HomeIcon,
  InfoOutlineIcon,
  PartnerIcon,
  Text,
} from '@common-fe/common-fe';

import { LARGE_SIZE_LOGO,LetterIconSizeEnum } from '@/common/constants';
import { FakeBrowserImage, HorizontalLine } from '@/components/elements';
import { Icon } from '@/components/elements/Icon/Icon';
import { useOrganizationSetupLogo } from '@/modules/employer/components/SetupEmployer/Branding/hooks/useOrganizationSetupLogo';

import { RowTitle } from '../Branding.styles';

interface Props {
  isLoading?: boolean;
}

export const PreviewBrandingSection: React.FC<Props> = ({ isLoading }) => {
  const {
    type,
    name,
    largeLightLogo,
    miniLightLogo,
    favicon,
  } = useOrganizationSetupLogo();

  if (isLoading) return null;

  return (
    <Box data-testid="PreviewBrandingSection_test-id">
      <Heading
        level={3}
        size="21px"
        style={{ lineHeight: '40px' }}
        margin={{ bottom: 'spacing16' }}
      >
        Preview
      </Heading>

      <Box direction="row" justify="between">
        <Box data-testid="PreviewBrandingSection_Large_logo_test-id">
          <Text weight={700} margin={{ bottom: 'spacing16' }} color="textTitle">
            Large logo
          </Text>

          <Box
            width={{ max: '272px' }}
            background="navigationBackground"
            pad={{ horizontal: '20px', top: 'spacing24', bottom: 'spacing12' }}
            round={{ corner: 'top', size: 'container2Round' }}
          >
            <Box
              direction="row"
              align="center"
              margin={{ right: 'spacing12' }}
            >
              <Box style={{ maxWidth: LARGE_SIZE_LOGO.width }}>
                <Icon
                  size={LetterIconSizeEnum.LOGO}
                  type={type}
                  text={name}
                  height={LARGE_SIZE_LOGO.height}
                  width="100%"
                  src={largeLightLogo}
                />
              </Box>
              <Box
                margin={{ left: 'spacing12' }}
                height={{ min: '40px' }}
                width={{ max: '175px' }}
                justify="center"
              >
                {largeLightLogo
                  ? (
                    <Box height="fit-content">
                      <InfoOutlineIcon color="iconSecondary" />
                    </Box>
                  )
                  : (
                    <Box
                      height={{ min: '40px' }}
                      width={{ max: '175px' }}
                    >
                      <RowTitle style={{ lineHeight: '20px', marginBottom: 0 }}>
                        {name}
                      </RowTitle>
                      <Text color="textDisabled" style={{ lineHeight: '20px' }}>
                        Company profile
                      </Text>
                    </Box>
                  )}
              </Box>
            </Box>

            <HorizontalLine
              width={{ max: 'none' }}
              background="navigationBorder"
              margin={{ top: 'spacing16', bottom: 'spacing12', horizontal: '-20px' }}
            />
            
            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <HomeIcon color="navigationIconDefault" />
              </Box>
              <Text color="navigationTextDefault">
                Home
              </Text>
            </Box>

            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <ContactsIcon color="navigationIconDefault" />
              </Box>
              <Text color="navigationTextDefault">
                Administrator Contacts
              </Text>
            </Box>

            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <PartnerIcon color="navigationIconDefault" />
              </Box>
              <Text color="navigationTextDefault">
                Partners
              </Text>
            </Box>
          </Box>
        </Box>

        <Box data-testid="PreviewBrandingSection_Mini_logo_test-id">
          <Text
            weight={700}
            margin={{ bottom: 'spacing16' }}
            color="textTitle"
          >
            Minimized logo
          </Text>

          <Box
            width={{ max: '88px' }}
            background="navigationBackground"
            pad={{ horizontal: '20px', top: 'spacing24', bottom: 'spacing12' }}
            round={{ corner: 'top', size: 'container2Round' }}
          >
            <Box
              direction="row"
              justify="center"
            >
              <Icon
                size={LetterIconSizeEnum.LOGO}
                type={type}
                text={name}
                sizePx="40"
                src={miniLightLogo}
              />
            </Box>

            <HorizontalLine
              width={{ max: 'none' }}
              background="navigationBorder"
              margin={{ top: 'spacing16', bottom: 'spacing12', horizontal: '-20px' }}
            />

            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <HomeIcon color="navigationIconDefault" />
              </Box>
            </Box>

            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <ContactsIcon color="navigationIconDefault" />
              </Box>
            </Box>

            <Box direction="row" align="center">
              <Box pad={{ horizontal: 'spacing12', vertical: 'spacing6' }}>
                <PartnerIcon color="navigationIconDefault" />
              </Box>
            </Box>

          </Box>

        </Box>

        <Box data-testid="PreviewBrandingSection_Favicon_test-id">
          <Text
            weight={700}
            margin={{ bottom: 'spacing16' }}
            color="textTitle"
          >
            Favicon
          </Text>

          <Box>
            <FakeBrowserImage
              logoSrc={favicon}
              organizationName={name}
              organizationType={type}
            />

            <Box
              margin={{ top: 'spacing16' }}
              pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
              border={{
                color: 'border2',
              }}
              round="dropdownItemRound"
              direction="row"
              align="center"
              gap="spacing6"
              width="fit-content"
            >
              <Box>
                <Icon
                  size={LetterIconSizeEnum.FAV}
                  type={type}
                  text={name}
                  src={favicon}
                />
              </Box>

              <Box width={{ max: '220px' }}>
                <Text
                  color="textBody"
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {name}
                </Text>
              </Box>
              <Text color="border">
                /
              </Text>
              <Text color="textDisabled">
                Employees
              </Text>
            </Box>

          </Box>

        </Box>

      </Box>
    </Box>
  );
};
