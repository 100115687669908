import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  CheckBox,
  PopupWrapper,
  SetupOutlinedIcon,
  SiblingBoxesWithSpacing,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { Option } from '@/common/types';
import theme from '@/styles/theme';

const ItemWrapper = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid;
    border-color: ${theme.colors.border1};
    margin-bottom: ${theme.spacings.spacing12};
  }
`;

export interface CurrentValues {
  active?: boolean;
  expired?: boolean;
}

export interface NestedOption extends Option {
  subOptions: Option[];
}

const ACTIVE_KEY: string = 'active';
const EXPIRED_KEY: string = 'expired';

const options: NestedOption[] = [
  {
    key: '1',
    title: 'Display',
    subOptions: [
      {
        key: ACTIVE_KEY,
        title: 'Active entities',
      },
      {
        key: EXPIRED_KEY,
        title: 'Expired entities',
      },
    ],
  },
];

interface Props {
  testId: string;
  onChange?: (values: CurrentValues) => void;
  values: CurrentValues;
  isDisabled?: boolean;
}

export const DisplayEntitiesPopup: React.FC<Props> = ({
  testId,
  onChange,
  values,
  isDisabled,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isApplyButtonClicked, setApplyButtonClicked] = useState(false);
  const [currentValues, setCurrentValues] = useState<CurrentValues>({});
  const isValueChecked = useCallback((value: string) => {
    if (currentValues[value as keyof typeof currentValues]) return true;

    return false;
  }, [currentValues]);

  const handleApply = useCallback(() => {
    if (currentValues?.active || currentValues?.expired) {
      if (onChange) onChange(currentValues);
      setExpanded(false);
    }
  }, [currentValues, onChange, setExpanded]);

  const handleCancel = useCallback(() => {
    setCurrentValues(values);
    setApplyButtonClicked(false);
    setExpanded(false);
  }, [values, setExpanded]);

  useEffect(() => {
    if (values) setCurrentValues(values);
  }, [values, setCurrentValues]);

  return (
    <PopupWrapper
      onVisible={setExpanded}
      controlledVisible={expanded}
      isDisabled={isDisabled}
      flat
      flex
      style={{
        marginTop: '2px',
        right: -60,
        width: '264px',
      }}
      buttonStyle={{
        padding: 0,
      }}
      testId={`${testId}_DisplayEntites_open-button`}
      content={(
        <Box
          width="264px"
          round={{ size: 'spacing8' }}
          background="canvas"
          data-testid={`${testId}_DisplayEntites_wrapper`}
          elevation="active"
        >
          <Box pad={{ horizontal: 'spacing16', top: 'spacing16', bottom: 'spacing4' }}>
            {options.map((option) => (
              <ItemWrapper key={option.key}>
                <Box margin={{ bottom: 'spacing12' }}>
                  <Text size="medium" weight={700} color="textBody">
                    {option.title}
                  </Text>
                </Box>
                <Box direction="column">
                  {option.subOptions.map((subOption) => (
                    <Box key={subOption.key} margin={{ bottom: 'spacing12' }}>
                      <CheckBox
                        name="primary"
                        role="checkbox"
                        aria-label="primary"
                        checked={isValueChecked(`${subOption.key}`)}
                        label={(
                          <Text style={{ fontSize: theme.fonts.text14Regular['font-size'] }} color="textBody">
                            {subOption.title}
                          </Text>
                        )}
                        onChange={() => {
                          const currentKey = `${subOption.key}`;
                          setCurrentValues((val) => ({
                            ...val,
                            [currentKey]: !val[currentKey as keyof typeof val],
                          }));
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </ItemWrapper>
            ))}
          </Box>
          {!currentValues?.active && !currentValues?.expired && isApplyButtonClicked && (
            <Text margin={{ left: 'm' }} size="small" color="danger">At least one selection is required.</Text>
          )}
          <Box
            width="100%"
            height="1px"
            background={{ color: 'border1' }}
          />
          <Box
            direction="row"
            align="center"
            pad="spacing16"
          >
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_DisplayEntites_cancel`}
                buttonType="secondary"
                type="button"
                width="110px"
                onClick={handleCancel}
              >
                Cancel
              </AppButton>
            </SiblingBoxesWithSpacing>
            <SiblingBoxesWithSpacing width="control">
              <AppButton
                testId={`${testId}_DisplayEntites_apply`}
                type="button"
                width="110px"
                onClick={() => {
                  handleApply();
                  setApplyButtonClicked(true);
                }}
              >
                Apply
              </AppButton>
            </SiblingBoxesWithSpacing>
          </Box>
        </Box>
      )}
    >
      <AppButton
        onlyIcon
        style={{
          width: '40px',
        }}
        color="grey"
        buttonType="secondary"
        active={expanded}
        disabled={isDisabled}
        onClick={() => {
          setExpanded(true);
          setCurrentValues(values);
          setApplyButtonClicked(false);
        }}
      >
        <SetupOutlinedIcon />
      </AppButton>
    </PopupWrapper>
  );
};
