import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter } from '@common-fe/common-fe';
import { snakeCase, toString } from 'lodash';

import { api } from '@/api';
import { ALL_OPTION } from '@/common/constants';
import PATHS from '@/common/paths';
import { OptionKey,PaginationParams } from '@/common/types';
import { AllowedFileTypes } from '@/modules/employer/types';

import {
  FileDto, FileDtoWithMediaSource,
  FileStatus, } from '../FileManager.constants';

export const QUERY_KEY = 'useFilesList';

export enum FileStatusPayload {
  initiated = 'INITIATED',
  uploaded = 'UPLOADED',
  approved = 'APPROVED',
  parsed = 'PARSED',
  validated = 'VALIDATED',
  processing = 'PROCESSING',
  completed = 'COMPLETED',
  error = 'ERROR',
  qualityGatesFailed = 'QUALITY_GATES_FAILED',
}

export interface FileDtoPayload {
  id: number;
  name?: string;
  partners_names?: string[];
  employers_names?: string[];
  uploaded_by?: string;
  upload_way?: string | null;
  file_path?: string | null;
  records_errors?: number;
  origin_file_path?: string | null;
  file_approval_type?: string | null;
  file_extension_type?: string | null;
  file_type?: string | null;
  file_processing_type?: string | null;
  file_processing_status_type: FileStatusPayload;
  created_at?: string | null;
  processed_at: string | null;
  file_types?: {
    file_approval_type?: string | null;
    file_extension_type?: string | null;
    file_type?: string | null;
    file_processing_type?: string | null;
    file_processing_status_type?: FileStatusPayload;
  };
}

const getStatusesToPayload = (statuses: string[]) => statuses
  .reduce((statusesToPayload: string[], status: string) => {
    const updatedStatuses = [...statusesToPayload];

    if (status === FileStatus.Uploading) {
      updatedStatuses.push(
        FileStatusPayload.initiated,
        FileStatusPayload.uploaded,
        FileStatusPayload.parsed,
      );
    }

    if (status === FileStatus.Uploaded) {
      updatedStatuses.push(FileStatusPayload.validated);
    }

    if (status === FileStatus.Processing) {
      updatedStatuses.push(
        FileStatusPayload.approved,
        FileStatusPayload.processing,
      );
    }

    if (status === FileStatus.Completed) {
      updatedStatuses.push(FileStatusPayload.completed);
    }

    if (status === FileStatus.Error) {
      updatedStatuses.push(
        FileStatusPayload.error,
        FileStatusPayload.qualityGatesFailed,
      );
    }

    return updatedStatuses;
  }, [])
  .map((status) => snakeCase(status).toUpperCase())
  .join(',');

export const getStatus = (statusPayload?: FileStatusPayload) => {
  if (statusPayload === FileStatusPayload.initiated
    || statusPayload === FileStatusPayload.uploaded
    || statusPayload === FileStatusPayload.parsed) {
    return FileStatus.Uploading;
  }

  if (statusPayload === FileStatusPayload.validated) {
    return FileStatus.Uploaded;
  }

  if (statusPayload === FileStatusPayload.approved
    || statusPayload === FileStatusPayload.processing) {
    return FileStatus.Processing;
  }

  if (statusPayload === FileStatusPayload.completed) {
    return FileStatus.Completed;
  }

  if (statusPayload === FileStatusPayload.error
    || statusPayload === FileStatusPayload.qualityGatesFailed) {
    return FileStatus.Error;
  }

  return statusPayload || ALL_OPTION.value;
};

export const formatFile = (file: FileDtoPayload) => ({
  id: toString(file.id),
  name: file.name || '',
  ...file?.partners_names ? {
    partnersNames: file?.partners_names.filter((name) => Boolean(name)),
  } : {},
  ...file?.employers_names ? {
    employersNames: file?.employers_names.filter((name) => Boolean(name)),
  } : {},
  ...file.uploaded_by ? {
    uploadedBy: file.uploaded_by,
  } : {},
  uploadWay: file.upload_way || '',
  path: file.file_path || '',
  errorsCount: file.records_errors,
  originPath: file.origin_file_path || '',
  approvalType: file.file_approval_type || file.file_types?.file_approval_type || '',
  extensionType: file.file_extension_type || file.file_types?.file_extension_type || '',
  type: capitalizeFirstLetter(file.file_type || file.file_types?.file_type || ''),
  processingType: file.file_processing_type || file.file_types?.file_processing_type || '',
  status: getStatus(
    file.file_processing_status_type
      || file.file_types?.file_processing_status_type,
  ) as FileStatus,
  createdAt: file.created_at || '',
  processedAt: file.processed_at || '',
});

const formatData = (data: FileDtoPayload[]): FileDto[] => data.map(formatFile);

export interface FilesQueryParams extends PaginationParams {
  dateRange?: Array<string | null>;
  statuses?: OptionKey[];
  uploadedById?: string | number | null;
  types?: OptionKey[];
  onRemoveRow?: (id: string) => void;
  uploadedFiles: FileDtoWithMediaSource[];
  reuploadingFile: (uploadingFile: FileDtoWithMediaSource) => void;
  allowedFileTypes?: AllowedFileTypes[];
  sendingToProcessFileId?: string;
}

// const DEFAULT_RECORD_TYPES = [
//   FileRecordType.Census,
//   FileRecordType.Enrollment,
//   FileRecordType.Contribution,
//   FileRecordType.Carrier,
// ];

const useFilesListQuery = ({
  page, perPage, searchString, statuses, sendingToProcessFileId,
  types, dateRange = [], uploadedById, allowedFileTypes,
}: FilesQueryParams) => {
  const fileTypes = types || allowedFileTypes || [];
  const [periodFrom, periodTo] = dateRange;
  const {
    isLoading,
    isSuccess,
    isError,
    data,
  } = useQuery([
    QUERY_KEY,
    page,
    perPage,
    statuses,
    fileTypes,
    searchString,
    periodFrom,
    periodTo,
    uploadedById,
  ], () => api.get(PATHS.GET_FILES, {
    params: {
      ...(uploadedById ? { uploaded_by: uploadedById } : {}),
      ...(periodFrom ? { created_date_from: periodFrom } : {}),
      ...(periodTo ? { created_date_to: periodTo } : {}),
      ...statuses?.length
        ? { file_processing_status_type: getStatusesToPayload(statuses as string[]) }
        : {},
      file_type: fileTypes.map((type) => `${type || ''}`.toUpperCase()).join(','),
      ...(searchString ? { search_value: searchString } : {}),
      size: perPage || 20,
      page,
    },
  }), {
    cacheTime: 0,
  });

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);

  return ({
    files: formatData(data?.data?.content || []),
    data: data?.data?.content || [],
    isError,
    total,
    isLoading,
    isSuccess,
  });
};

export default useFilesListQuery;
