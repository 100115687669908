import React, { useEffect, useMemo, useState } from 'react';
import {
  AmericanExpressIcon,
  Field,
  FieldTypes,
  MastercardIcon,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import VisaIcon from '@/assets/bank-icons/visa.svg';
import {
  DATE_MONTH_YEAR_FORMAT,
  DEFAULT_CARD_EXP_DATE_FORMAT,
  FIRST_DAY_NUMBER, INVALID_TEXT,
  REQUIRED_TEXT,
} from '@/common/constants';
import masks from '@/common/masks';
import regexp from '@/common/regexp';

import { usePaymentMethodsStore } from '../../usePaymentMethods.store';

const EXP_DATE_SECOND_PART_MONTH_REGEXP = '[0-2]';
const EXP_DATE_SECOND_PART_MONTH_ONE = 1;
const EXP_DATE_SECOND_PART_MONTH_TWO = 2;
const EXP_DATE_MASK_WITH_ZERO = '09 / 99';
const EXP_DATE_MASK_WITHOUT_ZERO = '92 / 99';
const EXP_DATE_INPUT_QUERY_SELECTOR = 'input[aria-label="expirationDate"]';

export enum DebitCardFields {
  nameOnCard = 'nameOnCard',
  cardNumber = 'cardNumber',
  expirationDate = 'expirationDate',
  cardType = 'cardType',
}

export interface DebitCardPayload {
  [DebitCardFields.nameOnCard]: string;
  [DebitCardFields.cardNumber]: string;
  [DebitCardFields.expirationDate]: string;
  [DebitCardFields.cardType]: string;
}

export const DEBIT_CARD_TEST_ID_PREFIX = 'DebitCard';
const smallInputStyles = {
  style: {
    justifyContent: 'flex-start',
  },
  inputWrapCssClass: 'small-input',
};

export const useDebitCardFields = (values?: DebitCardPayload) => {
  const [currentDateExpMask, setCurrentDateExpMask] = useState(masks.DEFAULT_CARD_EXP_DATE);
  const editableMethod = usePaymentMethodsStore((store) => store.editableMethod);

  const defaultValues = useMemo(
    () => ({
      [DebitCardFields.nameOnCard]: values?.nameOnCard || '',
      [DebitCardFields.cardNumber]: values?.cardNumber || '',
      [DebitCardFields.expirationDate]: values?.expirationDate || '',
    }),
    [values],
  );
  const bankCardType = useMemo(() => (
    Object.keys(regexp.BANK_CARD).reduce((adapter, type) => ({
      ...adapter,
      [type]: regexp.BANK_CARD[type as keyof typeof regexp.BANK_CARD]
        .test(
          defaultValues[DebitCardFields.cardNumber].replace(regexp.SPACE_SYMBOL, ''),
        ),
    }), {} as typeof regexp.BANK_CARD)
  ), [defaultValues]);

  useEffect(() => {
    const date = values?.expirationDate || '';
    const dateInput = document
      .querySelector(EXP_DATE_INPUT_QUERY_SELECTOR) as HTMLInputElement | null;

    if (date.length === 1 && Number(date.charAt(0)) === EXP_DATE_SECOND_PART_MONTH_ONE) {
      setCurrentDateExpMask(EXP_DATE_MASK_WITHOUT_ZERO);
      return;
    }
    if (date.length === 1 && Number(date.charAt(0)) >= EXP_DATE_SECOND_PART_MONTH_TWO) {
      setCurrentDateExpMask(EXP_DATE_MASK_WITH_ZERO);
      const selectionRange = 4;
      const timeout = setTimeout(() => {
        dateInput?.setSelectionRange(selectionRange, selectionRange);
        dateInput?.focus();
      }, 10);

      return () => clearTimeout(timeout);
    }
    setCurrentDateExpMask(masks.DEFAULT_CARD_EXP_DATE);
  }, [values]);

  const fields = useMemo<Field[]>(() => [
    {
      name: DebitCardFields.nameOnCard,
      type: FieldTypes.Text,
      label: 'Name on card',
      placeholder: 'Name on card',
      testId: `${DEBIT_CARD_TEST_ID_PREFIX}_${DebitCardFields.nameOnCard}`,
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: editableMethod?.requisites?.cardHolderName || '',
    },
    {
      name: DebitCardFields.cardNumber,
      type: FieldTypes.Mask,
      mask: masks.FULL_DEBIT_CARD,
      label: 'Card Number',
      placeholder: 'Card Number',
      testId: `${DEBIT_CARD_TEST_ID_PREFIX}_${DebitCardFields.cardNumber}`,
      defaultValue: editableMethod?.requisites?.cardLast4 ? `000000000000${editableMethod?.requisites?.cardLast4}` : '',
      validator: yup.string().test({
        test: (val) => (
          Object.values(regexp.BANK_CARD_FULL).some((exp) => (
            exp.test((val || '').replace(regexp.SPACE_SYMBOL, ''))
          ))
        ),
        message: INVALID_TEXT,
      }).required(REQUIRED_TEXT),
      rightIcon: (
        bankCardType.MASTERCARD
          ? <MastercardIcon width="23px" />
          : null
      ) || (
        bankCardType.VISA
          ? <img src={VisaIcon} alt="visa-icon" width="23px" />
          : null
      ) || (
        bankCardType.AMEX
          ? <AmericanExpressIcon width="23px" />
          : null
      ),
    },
    {
      name: DebitCardFields.expirationDate,
      type: FieldTypes.Mask,
      mask: currentDateExpMask,
      formatChars: {
        2: EXP_DATE_SECOND_PART_MONTH_REGEXP,
      },
      label: 'Expiration Date',
      placeholder: 'MM / YY',
      testId: `${DEBIT_CARD_TEST_ID_PREFIX}_${DebitCardFields.expirationDate}`,
      defaultValue: editableMethod?.requisites?.cardExpirationDate
        ? dayjs(editableMethod?.requisites?.cardExpirationDate).format(DATE_MONTH_YEAR_FORMAT).replace('/', ' / ')
        : '',
      validator: yup.string().test({
        test: (val) => {
          const month = parseInt((val || '').split(' / ')[0] || '0', 10);
          const year = parseInt((val || '').split(' / ')[1] || '0', 10);
          const valueDate = regexp.DEFAULT_CARD_EXP_DATE.test(val || '')
            ? dayjs(`${month}/${FIRST_DAY_NUMBER}/20${year}`, DEFAULT_CARD_EXP_DATE_FORMAT).toDate()
            : null;
          return valueDate ? dayjs().isBefore(valueDate.setMonth(valueDate?.getMonth() + 1), 'months') : false;
        },
        message: INVALID_TEXT,
      }).required(REQUIRED_TEXT),
      ...smallInputStyles,
    },
  ], [bankCardType, editableMethod, currentDateExpMask]);

  return { fields, bankCardType };
};
