import React, {
  useCallback,   useEffect, useMemo, useRef,
} from 'react';
import {
  AutocompleteInput,
  Box, HotKeys, PaginationBar, Text,
  useDebounce, useHotKeyPressIndicator, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import useHistory from '@/modules/core/hooks/useHistory';
import { Employer } from '@/modules/employer/employer.types';
import { useEmployersListQuery, useListPagination } from '@/modules/employer/hooks';
import { getIdsFromPath } from '@/utils';

import { useSmartSearchResentFinding } from './hooks/useSmartSearchResentFinding';
import { ToolbarSmartSearchOption } from './ToolbarSmartSearchOption';

const PER_PAGE = 20;

const BlockBox = styled(Box)`
display: block;
`;

export const ToolbarSmartSearch: React.FC = () => {
  const { redirectToObservingMode } = useHistory();
  const ref = useRef(null);
  // const [searchString, setSearchString] = useState<string>('');
  const { resentResults, handleAdd } = useSmartSearchResentFinding();
  const { observingOrganization: { type } } = useCurrentOrganization();
  const {
    page,
    currentSearch,
    setPage,
    searchString,
    setSearchString,
  } = useListPagination();
  const debouncedValue = useDebounce(searchString);

  const {
    data, refetch, isLoading, isFetched, total,
  } = useEmployersListQuery({
    searchString: currentSearch,
    page: page - 1,
    perPage: PER_PAGE,
    options: {
      enabled: !!currentSearch,
    },
  });

  const options = useMemo(() => {
    if (!searchString.length) {
      return resentResults;
    }
    if (searchString.length < 3) return [];
    return data.map((value) => ({
      title: value.name,
      key: value.id,
      value,
    }));
  }, [data, searchString.length, resentResults]);

  const isRecentFinding = useMemo(() => !!resentResults.length && !data.length,
    [resentResults, data]);

  useHotKeyPressIndicator(
    ref,
    () => {
      const smartSearch: HTMLInputElement | null = document.querySelector('[name=toolbar-smart-search]');
      smartSearch?.focus();
    },
    HotKeys.Slash,
  );

  useEffect(() => {
    if (searchString.length >= 3) refetch();
  }, [debouncedValue, refetch, searchString.length]);

  const handlerRedirectToObservingMode = useCallback((item: Option<Employer>) => {
    if (item.value?.path) {
      const { partnerId, distributorId, employerId } = getIdsFromPath(item.value.path);
      if (partnerId) {
        redirectToObservingMode(
          OrganizationTypes.partner,
          partnerId,
        );
      }
      if (distributorId) {
        redirectToObservingMode(
          OrganizationTypes.distributor,
          distributorId,
        );
      }
      if (employerId) {
        redirectToObservingMode(
          OrganizationTypes.company,
          employerId,
        );
      }
      handleAdd(item);
    }
  }, [redirectToObservingMode, handleAdd]);

  return (
    <Box ref={ref} margin={{ right: 'spacing12' }} width="240px" data-testid="smart-search_wrap">
      <AutocompleteInput
        name="toolbar-smart-search"
        label="label"
        testId="employer-search"
        placeholder="Employer Search"
        loading={isLoading}
        fetchMode
        noResults={isFetched && !options.length}
        startTypingMessage={!searchString.length && !options.length}
        keepTypingMessage={!!searchString.length && searchString.length < 3}
        onChangeSearchText={setSearchString}
        options={options}
        showAvailableOptions
        renderOptionsWrap={(props) => (
          <Box
            pad={{ vertical: 'spacing8' }}
            background="canvas"
            round="container1Round"
            width={{
              min: '480px',
            }}
          >
            <Text
              color="textSecondary"
              margin={{ bottom: 'spacing4', horizontal: 'spacing16' }}
              weight={500}
              size="12px"
            >
              {
                isRecentFinding
                  ? 'Recent findings'
                  : `Results: ${total}`
              }
            </Text>
            <Box overflow="hidden">
              <BlockBox height="400px" overflow="auto">
                {props.children}
              </BlockBox>
            </Box>
            {!!searchString.length && (
              <PaginationBar
                page={page}
                testId="ToolbarSmartSearch_pagination"
                total={total}
                pageSize={PER_PAGE}
                onChangePage={setPage}
                hideJumpToPage
                hidePageSize
                hideTotalResult
              />
            )}
          </Box>
        )}
        renderOption={(option, searchText, className) => (
          <ToolbarSmartSearchOption
            key={option.key}
            className={className}
            onSelect={handlerRedirectToObservingMode}
            option={option as Option<Employer>}
            searchText={searchText}
            isResentFinding={isRecentFinding}
          />
        )}
      />
    </Box>
  );
};
