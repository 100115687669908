import React from 'react';
import { Box,Text } from '@common-fe/common-fe';

const BrandingIllustrationLabel = () => (
  <Box direction="column">
    <Box flex={{ grow: 1 }}>
      <Text margin={{ bottom: 'xsmall' }} weight="bold" color="textBody">Branding illustration</Text>
      <Box>
        <Text weight="normal" size="small" color="textSecondary">
          Recommended size for:
          <br />
          <Text size="small" weight="bold">Rectangle logo format</Text> –  622×100 pixels.
          <br />
          We support: SVG, JPG, PNG.
        </Text>
      </Box>
    </Box>
  </Box>
);
export default BrandingIllustrationLabel;
