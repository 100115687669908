import { TierPayload } from '../ProcessingDefinition.types';

const useSortTiers = (tiers: TierPayload[]) => {
  if (!tiers?.length) {
    return [];
  }
  const sortedTiers = [...tiers];
  sortedTiers?.sort((a, b) => {
    if (a?.next_tier_id === null) {
      return 1;
    }
    if (b?.next_tier_id === null) {
      return -1;
    }
    return a?.next_tier_id - b?.next_tier_id;
  });

  return sortedTiers;
};

export default useSortTiers;
