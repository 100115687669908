import { ClaimsSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: ClaimsSettings;
  setClaimsSettings: (value: ClaimsSettings) => void;
}

const useStore = storeCreator<State>((set) => ({
  state: {
    claimsAssignmentNumber: undefined,
  },
  setClaimsSettings: (value: ClaimsSettings) => set(() => ({
    state: value,
  })),
}));

const useClaimsSettingsStore = () => ({ ...useStore() });

export default useClaimsSettingsStore;
