import { useCallback } from 'react';
import { FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import {
  COPAY_ITEM_KEY, CopayGroupPayload, CopayItem, GET_COPAY_ITEM_POSTFIX,
} from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';

export default (copays: CopayItem[], isEditMode?: boolean) => {
  const handleGetFormatedCopay = useCallback((value: FieldValues): CopayGroupPayload => {
    const currentValue = value;

    const formatedCopays = copays.map((copay) => {
      const { id, isSyntetic } = copay;

      const amount = _.get(currentValue, GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.AMOUNT, id), '');
      const category = _.get(currentValue, GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.CATEGORY, id), '');
      if (isEditMode && !isSyntetic && id) {
        return {
          id: _.toNumber(id),
          amount,
          category,
        };
      }
      return {
        amount,
        category,
      };
    });

    return {
      name: value.name,
      validFrom: value.validFrom,
      validTo: value.validTo,
      copays: formatedCopays,
    };
  }, [copays, isEditMode]);

  return handleGetFormatedCopay;
};
