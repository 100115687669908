import React, { useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  Contact,
  Field,
  FieldTypes,
  Inscription,
  RadioOption,
  Text,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import { Roles } from '@/common/constants';

interface Args {
  data?: Partial<Contact>;
  firstContact?: boolean;
  onlyOnePrimaryContact?: boolean;
  viewMode?: boolean;
  errors?: {
    [key: string]: string
  }
}

const USER_NAME_REQUIRED = 'User Name is a required field';
const EMAIL_REQUIRED = 'Email is a required field';
const EMAIL_VALID = 'Email must be valid.';
export default (args: Args, permissionsList: RadioOption[]) => {
  const { errors = {} } = args;
  const temporaryPasswordField: Field[] = useMemo(() => (!args.viewMode
    ? ([{
      name: 'temporaryPassword',
      type: FieldTypes.Node,
      label: 'Password',
      value: (
        <Text
          weight="normal"
          color="textSecondary"
        >
          After adding a user, a password will be generated automatically
        </Text>
      ),
    }]) : []), [args.viewMode]);
  const userNameValidation = useMemo(() => {
    if (errors.username) {
      return yup.string().test({
        test: (val) => val !== errors.username,
        message: 'Username format is invalid',
      }).required(USER_NAME_REQUIRED);
    }
    if (errors.usernameExists) {
      return yup.string().test({
        test: (val) => val !== errors.usernameExists,
        message: 'Username format is already used',
      }).required(USER_NAME_REQUIRED);
    }
    return yup.string().required(USER_NAME_REQUIRED);
  }, [errors]);
  const fields = useMemo((): Field[] => [
    {
      name: 'firstName',
      type: FieldTypes.Text,
      label: 'First name',
      placeholder: 'First name',
      showRequireIcon: true,
      validator: yup.string().required('First Name is a required field'),
      defaultValue: args?.data?.firstName || '',
    },
    {
      name: 'lastName',
      type: FieldTypes.Text,
      label: 'Last name',
      placeholder: 'Last name',
      showRequireIcon: true,
      validator: yup.string().required('Last Name is a required field'),
      defaultValue: args?.data?.lastName || '',
    },
    {
      name: 'email',
      type: FieldTypes.Text,
      label: 'Email',
      placeholder: 'Email',
      showRequireIcon: true,
      validator:
      errors.email ? yup.string().test({
        test: (val) => val !== errors.email,
        message: 'The email is invalid',
      }).required(EMAIL_REQUIRED)
        .email(EMAIL_VALID)
        : yup.string()
          .required(EMAIL_REQUIRED)
          .email(EMAIL_VALID),
      defaultValue: args?.data?.email || '',
    },
    {
      name: 'userName',
      type: FieldTypes.Text,
      label: !args.viewMode ? (
        <Box>
          <Text margin={{ bottom: 'xsmall' }} weight="bold">Username</Text>
          <Box>
            {!args?.data?.externalIdentifier
              && (
                <Text
                  weight="normal"
                  size="12px"
                  style={{ width: '230px' }}
                  color="textSecondary"
                >Username field can be set up only once and cannot be updated later.
                </Text>
              )}
          </Box>
        </Box>
      ) : 'Username',
      placeholder: 'Username',
      showRequireIcon: true,
      validator: userNameValidation,
      defaultValue: args?.data?.userName || '',
      disabled: !!args?.data?.id,
      isLowerCaseMode: true,
    },
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      placeholder: '+1 (555) 555-1234',
      validator:
        errors.phone
          ? yup.string().test({
            test: (val) => val !== errors.phone,
            message: 'Phone number is invalid',
          })
          : yup.string(),
      defaultValue: args?.data?.phone || '',
    },
    {
      name: 'title',
      type: FieldTypes.Text,
      label: 'Title/role',
      placeholder: 'Title/role',
      validator: yup.string(),
      defaultValue: args?.data?.title || '',
    },
    {
      name: 'department',
      type: FieldTypes.Text,
      label: 'Department',
      placeholder: 'Department',
      validator: yup.string(),
      defaultValue: args?.data?.department || '',
    },
    ...temporaryPasswordField,
    {
      name: 'primary',
      type: FieldTypes.Checkbox,
      label: 'Set as primary contact',
      placeholder: 'Doing Business As',
      validator: yup.boolean().required('Primary is a required field'),
      defaultValue: args?.firstContact ? true : (args?.data?.primary || false),
      disabled: args?.firstContact || Boolean(args?.onlyOnePrimaryContact && args?.data?.primary),
    },
    {
      name: 'permissions',
      type: FieldTypes.Dropdown,
      singleMode: true,
      isPositionSensitive: true,
      options: permissionsList
        .map((item) => ({
          key: item.value,
          value: item.value
            ? capitalizeFirstLetter(item.value.toString().split('_').join(' ').toLowerCase())
            : item.value,
        })),
      label: (
        <Box margin={{ top: 'spacing8' }} direction="row">
          <Inscription
            color="danger"
            margin={{
              right: '3px',
              top: '1px',
            }}
          >
            *
          </Inscription>
          <Text weight="bold">Permissions</Text>
        </Box>
      ),
      placeholder: 'Select permission',
      validator: yup.mixed<Roles>().oneOf(Object.values(Roles), 'Permissions is a required field').required('Permissions is a required field'),
      defaultValue: args?.data?.role,
      disabled: args?.firstContact || args?.data?.primary,
    },
  ], [
    args,
    errors,
    permissionsList,
    userNameValidation,
    temporaryPasswordField,
  ]);
  return fields;
};
