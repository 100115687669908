import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { ClaimAutopayType, DefaultClaimPayeeType } from '@/modules/employee/employee.types';
import useGetEmployeeById from '@/modules/employee/Employee/hooks/useGetEmployeeById';

const useHealthPlanClaimsFields = (isEditMode?: boolean) => {
  const { isLoading, data } = useGetEmployeeById();
  const fields = useMemo<Field[]>(() => {
    if (isLoading) return [];

    return [
      {
        name: 'defaultClaimPayeeType',
        type: FieldTypes.Radio,
        label: 'Claim payee',
        value: data?.defaultClaimPayeeType,
        options: [
          {
            label: 'Participant',
            value: DefaultClaimPayeeType.participant,
          },
          {
            label: 'Provider',
            value: DefaultClaimPayeeType.provider,
          },
          {
            label: 'Ask me',
            value: DefaultClaimPayeeType.employeeChoice,
          },
        ],
        editMode: isEditMode,
      },
      {
        name: 'claimAutopayType',
        type: FieldTypes.Radio,
        label: 'How to process Health plan claims',
        value: data?.claimAutopayType,
        options: [
          {
            label: 'Automatically',
            value: ClaimAutopayType.auto,
          },
          {
            label: 'Ask me',
            value: ClaimAutopayType.click,
          },
        ],
        editMode: isEditMode,
      },
    ];
  }, [isEditMode, isLoading, data]);

  return fields;
};

export default useHealthPlanClaimsFields;
