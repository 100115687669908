import React, { useMemo } from 'react';
import { Box, getFullnameAbbr,Text } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';

import { useDependents } from './SpendTracker/hooks';
import { DeductibleUser } from './SpendTracker/hooks/useDependents';
import SpendTracker from './SpendTracker/SpendTracker';

interface Props {
  userId?: string;
  employeeUser: DeductibleUser;
}
const Deductibles: React.FC<Props> = ({ userId, employeeUser }) => {
  const users = useDependents({
    userId, isIndividual: true, employeeUser,
  });

  const currentUser = useMemo(() => {
    if (users.length) {
      return users[0];
    }
    return null;
  }, [users]);

  const abbr = useMemo(() => getFullnameAbbr(currentUser?.fullName || ''), [currentUser]);
  useBreadcrumbs([{
    route: ROUTES.EMPLOYEES,
    title: `Deductible ${currentUser?.fullName}`,
  }]);

  // const currentDependent =
  return (
    <Box direction="column" fill>
      <Box
        margin={{ bottom: 'l' }}
        pad={{ vertical: 'xxs', horizontal: 'xs' }}
        direction="row"
        round="l"
        align="center"
      >
        <Box
          width="40px"
          height="40px"
          align="center"
          round="50%"
          justify="center"
          background="subgroup"
        >
          <Text color="iconOnColor" weight={700}>
            {abbr}
          </Text>
        </Box>
        <Text
          margin={{ left: 'xs' }}
          size="3xl"
          color="textBody"
          weight={700}
        >
          {currentUser?.fullName || ''}

        </Text>
      </Box>

      <SpendTracker isIndividual userId={userId} employeeUser={employeeUser} />
    </Box>
  );
};

export default Deductibles;
