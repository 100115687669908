import React, { useCallback,useMemo, useState } from 'react';
import {
  AutocompleteDropdown,
  Box,
  CalendarPickerIcon,
  DateRangeDropdown,
  FlexListPlaceholder,
  Inscription,
  PaginationBar,
  Preloader,
  SearchInput,
  SelectDropdown,
} from '@common-fe/common-fe';
import { toString } from 'lodash';

import { ALL_OPTION,OrganizationTypes } from '@/common/constants';
import { OptionKey } from '@/common/types';
import { useCurrentOrganization, useHasAccess } from '@/modules/core/hooks';
import useOrganizationQuery from '@/modules/core/queries/useOrganization.query';
import { checkIsAllOptionSelected, stateAllToggling } from '@/utils/handlers/dropdownStateAllToggling';
import { formatDateRange } from '@/utils/modifiers';

import useListPagination from './hooks/useListPagination';
import useGetChecks from './queries/useGetChecks.query';
import CheckRow from './CheckRow';
import { StyledAutocompleteDropdown } from './Checks.styled';
import { CHECK_STATUSES_OPTIONS } from './constants';
import TableHeader from './TableHeader';

const FIRST_PAGE = 0;
const employerAdditionalFetchParams = {
  type: OrganizationTypes.company,
};

const employerFilterRequirements = [
  { organizationType: OrganizationTypes.system },
  { organizationType: OrganizationTypes.partner },
  { organizationType: OrganizationTypes.distributor },

  { organizationType: OrganizationTypes.employer, isException: true },
  { organizationType: OrganizationTypes.subsidiary, isException: true },
  { organizationType: OrganizationTypes.subgroup, isException: true },
];

const ChecksList = () => {
  const [openCheckId, setOpenCheckId] = useState('');
  const [employerSearchText, setEmployerSearchText] = useState('');
  const [searchEmployerId, setSearchEmployerId] = useState('');
  const { observingEmployer } = useCurrentOrganization();
  const hasEmployerFilter = useHasAccess(employerFilterRequirements);
  const isEmployerObserved = useMemo(
    () => observingEmployer?.id !== undefined, [observingEmployer],
  );
  const {
    data: employerOptions = [],
    isLoading: isEmployerLoading,
  } = useOrganizationQuery(employerSearchText, employerAdditionalFetchParams);
  useMemo(() => {
    if (isEmployerObserved && observingEmployer?.id && !searchEmployerId) {
      setSearchEmployerId(toString(observingEmployer?.id));
    }
  }, [isEmployerObserved, observingEmployer, searchEmployerId]);
  const {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    setStatuses,
    dateRange,
    setDateRange,
    statuses,
  } = useListPagination();

  const hasDateRane = useMemo(() => dateRange.filter((date) => date).length > 0, [dateRange]);
  const statusesAfterToggling = useMemo(() => stateAllToggling(statuses), [statuses]);
  const hasStatusParam = useMemo(
    () => statusesAfterToggling.filter((status) => status !== ALL_OPTION.value).length > 0,
    [statusesAfterToggling],
  );
  const isOptionAllSelected = useMemo(() => checkIsAllOptionSelected(statuses), [statuses]);
  const handleChangeEmployer = useCallback(
    (id: OptionKey) => {
      setSearchEmployerId(id as string);
      setPage(FIRST_PAGE);
    }, [setPage],
  );

  const {
    checks, total, isLoading,
  } = useGetChecks({
    page: page - 1 < 0 ? FIRST_PAGE : page - 1,
    perPage,
    searchString: currentSearch,
    dateRange: formatDateRange(dateRange),
    statuses: isOptionAllSelected ? [] : statusesAfterToggling,
    ...searchEmployerId ? { organizationId: searchEmployerId } : {},
  });
  const isEmployerFieldDisabled = useMemo(() => (checks.length === 0 && !searchEmployerId)
    || !hasEmployerFilter || isEmployerObserved, [checks.length, searchEmployerId, hasEmployerFilter, isEmployerObserved]);

  return (
    <Box data-testid="checks-list" background={{ color: 'module' }} round="container1Round">
      <Box>
        <Box
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: 'spacing24', top: 'spacing24', bottom: 'spacing4' }}
        >
          <Inscription
            weight="bold"
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
          >
            Checks: {total}
          </Inscription>
          <Box direction="row">
            <Box width="320px">
              <SearchInput
                value={searchString}
                onChange={setSearchString}
                placeholder="Search for employee, check number"
                hasSearchingHistory
                disabled={checks.length === 0 && !searchString}
              />
            </Box>
            <Box margin={{ left: 'spacing12' }} width="170px">
              <StyledAutocompleteDropdown
                testId="EmployeesList"
                disabled={isEmployerFieldDisabled}
                name="Employer"
                placeholder="Employer"
                onSearch={setEmployerSearchText}
                options={employerOptions}
                isLoading={isEmployerLoading}
                value={searchEmployerId}
                onChange={handleChangeEmployer}
                className="ellipsis"
              />
            </Box>
            <Box width="220px" margin={{ left: 'spacing12' }}>
              <SelectDropdown
                id="status"
                prefix="Status"
                name="Check status"
                options={CHECK_STATUSES_OPTIONS}
                values={statusesAfterToggling}
                onChangeValues={setStatuses}
                allMode
                activeTitle
                ellipsisMode
                disabled={checks.length === 0 && !hasStatusParam}
              />
            </Box>
            <Box width="172px" margin={{ left: 'spacing12' }}>
              <DateRangeDropdown
                prefix="Issued date:"
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                onChange={setDateRange}
                icon={(
                  <CalendarPickerIcon color="iconPrimary" />
                )}
                ellipsisMode
                disabled={checks.length === 0 && !hasDateRane}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {isLoading ? (<Box pad={{ vertical: 'spacing24' }}><Preloader testId="enrollment-matrix_loader" /></Box>) : null}
      {(checks.length === 0 || total === 0) && !isLoading ? (<FlexListPlaceholder />) : null}
      {checks.length > 0 && !isLoading ? (
        <Box
          direction="column"
          style={{ position: 'relative' }}
          margin={{ top: 'spacing12' }}
          pad={{ horizontal: 'spacing24', top: 'spacing12', bottom: 'spacing24' }}
        >
          <TableHeader />
          <Box direction="column" style={{ position: 'relative' }} data-testId="CheckRowsWrapper">
            {checks.map((check) => {
              return (
                <CheckRow
                  key={check.id}
                  check={check}
                  setOpenCheckId={setOpenCheckId}
                  openCheckId={openCheckId}
                  setSearchCheckId={setSearchString}
                  clearFilters={() => {
                    setDateRange([null, null]);
                    setSearchEmployerId('');
                    setStatuses([]);
                  }}
                />
              );
            })}
          </Box>
          {
            total === 0 ? null : (
              <PaginationBar
                page={page}
                total={total}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )
          }
        </Box>
      ) : null}
    </Box>
  );
};

export default ChecksList;
