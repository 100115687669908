import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';

import { BusinessEntityTypeDto } from './BusinessEntityType.dto';

mock.onGet(`/api/person${PATHS.BUSINESS_ENTITY_TYPES}`).reply(200, [
  { key: 'C_CORPORATION', value: 'C-Corporation' },
  { key: 'S_CORPORATION', value: 'S-Corporation' },
  { key: 'LIMITED_LIABILITY_CORP', value: 'Limited Liability Corp.' },
  { key: 'GOVERNMENT_ENTITY', value: 'Government Entity' },
  { key: 'NON_PROFIT', value: 'Non-Profit' },
  { key: 'PARTNERSHIP', value: 'Partnership' },
  { key: 'SOLO_PROPRIETORSHIP', value: 'Sole Proprietorship' },
]);

const mapData = [
  { key: 'C_CORPORATION', value: 'C-Corporation' },
  { key: 'S_CORPORATION', value: 'S-Corporation' },
  { key: 'LIMITED_LIABILITY_CORP', value: 'Limited Liability Corp.' },
  { key: 'GOVERNMENT_ENTITY', value: 'Government Entity' },
  { key: 'NON_PROFIT', value: 'Non-Profit' },
  { key: 'PARTNERSHIP', value: 'Partnership' },
  { key: 'SOLO_PROPRIETORSHIP', value: 'Sole Proprietorship' },
];

export default () => {
  const {
    isLoading, isSuccess, isError, data, error,
  } = useQuery('getBusinessEntityTypes', () => api.get(`${PATHS.BUSINESS_ENTITY_TYPES}`));

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list.map((listItem: BusinessEntityTypeDto) => {
      const farmattedValue = mapData.find((mapItem) => mapItem.key === listItem.name);
      return ({
        key: listItem.name,
        value: farmattedValue?.value,
      });
    });
  }, [data]);

  return ({
    data: formatedData || [],
    error,
    isError,
    isLoading,
    isSuccess,
  });
};
