import { useMutation, useQueryClient } from 'react-query';
import { AxiosHeaders } from 'axios';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { Plan, PlanStatuses } from '@/modules/plan/plan.types';
import { QUERY_KEY as PLAN_YEAR_QUERY_KEY } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';

import { PLAN_LIST_QUERY_NAME } from '../../queries/usePlansList.query';

interface Payload {
  planYearId: number;
  parentId: number;
  name: string;
  planType?: string;
  organizationId?: string;
  status?: PlanStatuses;
}
export const useCreatePlanMutations = (redirectMode?: boolean, includeLogHeader?: boolean) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { organizationId } = useCurrentOrganisationStore();
  const mutation = useMutation(
    (data: Partial<Payload>) => api.post<Plan>(PATHS.PLANS, {
      plan_year_id: data.planYearId,

      // account_type: {
      //   account_type: data.planType,
      // },
      parent_id: data.parentId,
      is_plan: true,

      plan_primary_config: {},
      plan_status: data.status,
      organization_id: data.organizationId || organizationId,
    },
    {
      headers: includeLogHeader ? {
        'Audit-Log-Creation': true,
      } as unknown as AxiosHeaders : undefined,
    }), {
      onSuccess: (response) => {
        const id = _.get(response, 'data.id');
        queryClient.invalidateQueries(PLAN_LIST_QUERY_NAME);
        queryClient.invalidateQueries(PLAN_YEAR_QUERY_KEY);
        if (redirectMode) {
          history.push(ROUTES.PLAN_SETUP_BY_ID(id));
        }
      },
    },
  );
  return mutation.mutateAsync;
};

export default useCreatePlanMutations;
