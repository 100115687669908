import React, { useCallback, useMemo, useState } from 'react';
import {
  AppButton,   Box, Field, FlexControlledForm, Preloader, WarnModal,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ModalWrapper from '@/components/wrappers/ModalWrapper';
import useCarrierConfigStore from '@/modules/CarrierConfig/useCarrierConfig.store';
import { Access } from '@/modules/core/components';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';

import { ConfigStatus } from '../types/carrier.types';

import { useAdditionalFields } from './hooks/useAdditionalFields';
import { useGeneralFields } from './hooks/useGeneralFields';
import useCarrierConfigQuery from './queries/useCarrierConfig.query';
import useDisableCarrierConfigQuery from './queries/useDisableCarrierConfig.query';

interface Props {
  carrierId: string;
  setViewCarrierId: (value: string) => void;
}

const ViewCarrierConfigModal: React.FC<Props> = ({ carrierId, setViewCarrierId }) => {
  const [isDisableModalVisible, setDisableModalVisible] = useState(false);
  const { formattedData, isLoading: isCarrierConfigLoading } = useCarrierConfigQuery(carrierId);
  const organizationId = useMemo(() => formattedData?.organizationId, [formattedData]);
  const {
    formattedData: organization, isLoading: isOrganizationLoading,
  } = useGetOrganizationById(organizationId);
  const generalFields = useGeneralFields(formattedData, organization) as Field[];
  const additionalFields = useAdditionalFields(formattedData) as Field[];
  const carrierName = useMemo(() => formattedData?.carrier?.name, [formattedData]);
  const {
    resetCarrierConfigs: resetJustCreatedCarrierConfigs,
  } = useCarrierConfigStore();
  const { disableCarrierConfig } = useDisableCarrierConfigQuery(() => {
    resetJustCreatedCarrierConfigs();
  });
  const isLoading = useMemo(
    () => isCarrierConfigLoading || isOrganizationLoading,
    [isCarrierConfigLoading, isOrganizationLoading],
  );
  const isActive = useMemo(() => formattedData?.statusType
    && formattedData?.statusType === ConfigStatus.active, [formattedData]);

  const handleClose = useCallback(() => setViewCarrierId(''), [setViewCarrierId]);

  const handleDisableConfig = useCallback((disablingCarrierConfigId: string) => {
    disableCarrierConfig(disablingCarrierConfigId);
    handleClose();
  }, [disableCarrierConfig, handleClose]);

  const handleToggleDisableModal = useCallback(() => {
    setDisableModalVisible(!isDisableModalVisible);
  }, [isDisableModalVisible]);

  return (
    <ModalWrapper
      {...isDisableModalVisible ? { wrapperStyle: { overflow: 'hidden' } } : {}}
      testId="carrier-config"
      visible={!!carrierId}
      onSetVisible={handleClose}
      {...(isLoading ? {} : { title: carrierName })}
    >
      {isLoading ? (
        <Preloader testId="view-carrier-modal-spinner" />
      ) : (
        <Box data-testId="view-carrier-modal-content">
          <Box background="module" pad="spacing24" round="medium">
            <Box
              background="canvas"
              round="container1Round"
              border={{ color: 'border2', size: 'small' }}
              pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
              elevation="default"
            >
              <FlexControlledForm
                fields={generalFields}
                wrapperStyle={{ border: 'none', overflow: 'hidden' }}
                formStyle={{ margin: 0 }}
                isThinMode
              />
            </Box>
            <Box
              background="canvas"
              round="container1Round"
              border={{ color: 'border2', size: 'small' }}
              pad={{ horizontal: 'spacing32', vertical: 'spacing24' }}
              margin={{ top: 'spacing24' }}
              elevation="default"
            >
              <FlexControlledForm
                fields={additionalFields}
                wrapperStyle={{ border: 'none' }}
                formStyle={{ margin: 0 }}
                isThinMode
              />
            </Box>
          </Box>

          <Box direction="row" justify="between" margin={{ top: 'spacing24' }}>
            {isActive && (
              <Box>
                <Access
                  accessRequirements={[
                    {
                      permission: Permissions.DISABLE_CARRIER_CONFIG,
                    },
                  ]}
                >
                  <AppButton
                    testId="show-disable-carrier-modal"
                    width="160px"
                    buttonType="secondary"
                    color="red"
                    onClick={handleToggleDisableModal}
                  >
                    Disable
                  </AppButton>
                </Access>
              </Box>
            )}
            <Box margin={{ left: 'auto' }}>
              <AppButton
                testId="close-modal"
                width="160px"
                onClick={handleClose}
              >
                Close
              </AppButton>
            </Box>
          </Box>
        </Box>
      )}
      <WarnModal
        testId="carrier-config-list-disable"
        visible={isDisableModalVisible}
        onSetVisible={setDisableModalVisible}
        header={`Disable ${carrierName}?`}
        buttonText="Cancel"
        submitButtonText="Yes, Disable"
        submitButtonMode="alert"
        onSubmit={() => handleDisableConfig(carrierId)}
      />
    </ModalWrapper>
  );
};

export default ViewCarrierConfigModal;
