import React from 'react';
import { Box } from '@common-fe/common-fe';

import { LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { UserLetterIcon } from '@/components/elements/LetterIcon/UserLetterIcon';

import { InfoTileIconWrapper, RoundImage } from './InfoTile.styles';

interface Props {
  title?: string;
  icon?: string;
  hasBorder?: boolean;
}

const InfoTileIcon: React.FC<Props> = ({ icon, title, hasBorder }) => (
  <InfoTileIconWrapper round="50%" width={{ min: '40px' }} hasBorder={hasBorder}>
    {icon
      ? (
        <RoundImage
          height="40px"
          width="40px"
          src={icon}
          title={title}
        />
      )
      : (
        <Box title={title}>
          <UserLetterIcon
            type={OrganizationTypes.employer}
            size={LetterIconSizeEnum.LOGO}
            text={title}
          />
        </Box>
      )}
  </InfoTileIconWrapper>
);

export default InfoTileIcon;
