import { useMemo } from 'react';
import { FieldTypes, formatSnakeCaseToGeneral } from '@common-fe/common-fe';

import { CarrierConfig } from '../../types/carrier.types';

const getBooleanFromString = (value?: boolean) => (value ? 'Yes' : 'No');

export const useAdditionalFields = (carrierInfo?: CarrierConfig) => useMemo(() => {
  if (!carrierInfo) return [];

  return [
    {
      name: 'carrierConfigPurpose',
      type: FieldTypes.Text,
      label: 'Purpose',
      value: formatSnakeCaseToGeneral(carrierInfo.purposeType),
    },
    {
      name: 'carrierConfigPaymentMethod',
      type: FieldTypes.Text,
      label: 'Claim autopay',
      value: formatSnakeCaseToGeneral(carrierInfo.claimAutopayType),
    },
    {
      name: 'carrierConfigDependentDeidentified',
      type: FieldTypes.Text,
      label: 'De-identify dependent',
      value: getBooleanFromString(carrierInfo.isDependentDeidentified),
    },
    {
      name: 'carrierConfigDependentAutocreated',
      type: FieldTypes.Text,
      label: 'Automatic creation of dependent',
      value: getBooleanFromString(carrierInfo.isDependentAutocreated),
    },
    {
      name: 'carrierConfigClaimPayee',
      type: FieldTypes.Text,
      label: 'Claim payee',
      value: formatSnakeCaseToGeneral(carrierInfo.claimPayeeType),
    },
    {
      name: 'carrierConfigRequestedAmount',
      type: FieldTypes.Text,
      label: 'Fields for requested amount',
      value: carrierInfo.amountTypes.map((amountType) => formatSnakeCaseToGeneral(amountType)).join(', '),
    },
  ];
}, [carrierInfo]);

export default useAdditionalFields;
