import { useMemo } from 'react';

import { aiAdjudicationType, PlanFieldState, PlanResponse, PlanTypesEnum } from '../../plan.types';

export const getPlanFieldState = (plan: PlanResponse, isOnlyForDev?: boolean) => {
  const primary = plan?.plan_primary_config;
  const coverage = plan?.plan_coverage_config;
  const accountFunding = plan?.plan_account_funding_config;

  const getContributionPermissions = () => {
    const planType = plan.account_type.account_type;
    if (isOnlyForDev) {
      return {
        allowContributors: accountFunding?.allow_contribution_config?.state,
        pendContribution: accountFunding
          ?.is_pend_contributions_over_irs
          ?.is_pend_contributions_over_the_irs_max_state,
        allowContributionOverMaximum: accountFunding
          ?.is_allow_contributions_over_election_amount
          ?.is_allow_contributions_over_election_amount_state,
      };
    }
    if (planType === PlanTypesEnum.HSA) {
      return {
        allowContributors: PlanFieldState.Modifiable,
        allowContributionOverMaximum: PlanFieldState.Modifiable,
        pendContribution: PlanFieldState.Modifiable,
      };
    }
    if (planType === PlanTypesEnum.HRA) {
      return {
        allowContributors: PlanFieldState.Modifiable,
        allowContributionOverMaximum: PlanFieldState.Modifiable,
        pendContribution: PlanFieldState.Hidden,
      };
    }
    return {
      allowContributors: PlanFieldState.Modifiable,
      allowContributionOverMaximum: PlanFieldState.Locked,
      pendContribution: PlanFieldState.Hidden,
    };
  };
  return {
    planName: plan?.name.name_state,
    planType: plan?.account_type?.account_type_state,
    planCode: plan?.plan_code_state,
    priorPlanId: plan?.prior_plan_id_state,
    // planTemplate: plan?.parent_id ? PlanFieldState.Locked : PlanFieldState.Modifiable,
    planTemplate: PlanFieldState.Locked,
    availableBalance: primary?.available_balance_type?.available_balance_type_state,
    maximumElectionAmount: primary?.max_election_amount_type?.max_election_amount_type_state,
    minimumElectionAmount: primary?.min_election_amount_type?.min_election_amount_type_state,
    CIPRequired: primary?.is_cip_required?.is_cip_required_state,
    CMSReportingRequired: primary?.cms_reporting_required?.cms_reporting_required_state,
    pendContributionsForCIP: primary.pend_contributions_for_cip?.pend_contributions_for_cip_state,
    proofOfExpense: primary?.proof_of_expenses_type?.proof_of_expenses_type_state,
    cardOffered: primary?.is_carded?.is_carded_state,
    investmentThreshold: primary.investment_threshold?.investment_threshold_state,
    catchUpAge: primary.catch_up_age?.catch_up_age_state,
    catchUpElections: primary.catch_up_election?.catch_up_election_state,
    investmentOptionsType: primary.investment_options_type?.investment_options_type_state,
    isReplenished: primary?.is_replenished?.is_replenished_state,
    omnibus: primary?.omnibus_type?.omnibus_type_state,
    accountCreation: accountFunding?.account_creation?.account_creation_type_state,
    interestTemplate: accountFunding?.interest_template_id?.interest_template_id_state_id,
    fundID: primary?.fund_id?.fund_state_id,

    aiAdjudicationType: coverage?.ai_adjudication_type?.ai_adjudication_type_state,
    spendLimit: primary.spend_limit_type?.spend_limit_type_state,
    spendPeriod: primary.spend_period_type?.spend_period_type_state,

    fundIdFormulaOptions: primary?.fund_id_formula?.fund_id_formula_state,
    gracePeriod: coverage?.grace_period_type?.grace_period_type_state,
    runOut: coverage?.run_out_type?.run_out_type_state,
    rollover: accountFunding?.is_rollover?.is_rollover_state,
    maximumRolloverAmount: accountFunding?.max_rollover_amount?.max_rollover_amount_type_state,
    minimumRolloverAmount: accountFunding?.min_rollover_amount?.min_rollover_amount_type_state,
    autoEnrollment: accountFunding?.is_auto_enrollment?.is_auto_enrollment_state,
    daysAfterPlanEnd: accountFunding?.days_after_plan_end?.days_after_plan_end_state,
    rolloverClaims: accountFunding?.rollover_claims?.rollover_claims_type_state,
    allowInvestments: primary?.allow_investments?.allow_investments_state,
    minimumInvestmentAmount: primary.minimum_investment_amount?.minimum_investment_amount_state,
    isOrphaned: primary.is_orphaned?.is_orphaned_state,
    endOfCoverage: coverage?.end_of_coverage_type?.end_of_coverage_state,
    claimsDeadline:
     coverage?.claims_deadline_end_of_coverage_type?.claims_deadline_end_of_coverage_state,
    // isPayClaimsAbsence: coverage?.is_pay_claims_absence?.is_pay_claims_absence_state,
    isPayClaimsOutsideCoverage: coverage
      ?.is_pay_claims_outside_coverage?.is_pay_claims_outside_coverage_state,
    planLevelFunding: accountFunding.is_plan_level_funding?.is_plan_level_funding_state,
    generateStatements: primary.generate_statements?.generate_statements_state_id,

    ...getContributionPermissions(),

    isPreFundCalculated: accountFunding.is_custom_prefund_calc?.is_custom_prefund_calc_state,
    replenishmentCreepMin: accountFunding
      .prefund_replenishment_creep_min?.prefund_replenishment_creep_min_state,
    replenishmentCreepPercent: accountFunding
      .prefund_replenishment_creep_percent?.prefund_replenishment_creep_percent_state,
    replenishmentMinValue: accountFunding
      .prefund_replenishment_min_value?.prefund_replenishment_min_value_state,
    replenishmentPercent: accountFunding
      .prefund_replenishment_percent?.prefund_replenishment_percent_state,
    allowIndividualContributions: accountFunding
      ?.allow_individual_contributions
      ?.allow_individual_contributions_state_id,
    сontributionMonitoringRequired: accountFunding
      ?.contribution_monitoring_required
      ?.contribution_monitoring_required_state,
    minimumContributionAmount: accountFunding
      ?.minimum_contribution_amount
      ?.minimum_contribution_amount_state_id,
  };
};

const usePlanSetupPermissions = (plan: PlanResponse | undefined, isOnlyForDev?: boolean) => {
  const fieldsState = useMemo(() => {
    if (plan) {
      const planPermissions = getPlanFieldState(plan, isOnlyForDev);
      return planPermissions;
    }
    return {};
  }, [plan, isOnlyForDev]);
  return fieldsState;
};

export default usePlanSetupPermissions;
