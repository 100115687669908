import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import {
  REQUIRED_TEXT,
} from '@/common/constants';
import useGracePeriodStore from '@/modules/plan/PlanSetup/Coverage/GracePeriod/stores/useGracePeriod.store';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { useIRSMaximumQuery } from '@/modules/plan/PlanSetup/PlanDefinition/Additional/queries';
import { useIRSMaximumTemplateTypesQuery } from '@/modules/plan/PlanSetup/PlanDefinition/Additional/queries';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import { useFieldsWithDefaultValues } from '@/utils/hooks';

import { ROLLOVER_KEYS } from '../Rollover.constants';
import { useRolloverStore } from '../stores';

export const LABELS = {
  ROLLOVER: 'Rollover',
  MAXIMUM_ROLLOVER_AMOUNT: 'Maximum rollover amount',
  MINIMUM_ROLLOVER_AMOUNT: 'Minimum rollover amount',
  ROLLOVER_CLAIMS: 'Rollover claims',
  AUTOMATICALLY_CREATE_A_NEW_ENROLLEMNT: 'Automatically create a new enrollment',
  AUTOMATICALLY_HCFSA_LPFSA: 'Automatically update HCFSA to LPFSA',
  ELECTION_FOR_LIMITED_COVERAGE: 'Election for limited coverage',
  DAYS_AFTER_PLAN_END: 'Days after plan end',
};
const IRS_MAXIMUM_KEY = 'IRS_LIMIT';

const MAX_ROLLOVER_MESSAGE = `${LABELS.MAXIMUM_ROLLOVER_AMOUNT} should be greater that ${LABELS.MINIMUM_ROLLOVER_AMOUNT}`;
const CUSTOM_KEY = 'CUSTOM';
const TRUE_KEY = 'true';
const YES_KEY = 'yes';
const RUN_OUT_NO_CLAIMS_DEADLINE_VALUE = 'NO_CLAIMS_DEADLINE';
const RUN_OUT_LAST_DAY_OF_PLAN_YEAR_VALUE = 'LAST_DAY_OF_PLAN_YEAR';
const VALIDATOR = yup.string().nullable();
const REQUIRED_VALIDATOR = yup.string().trim().required(REQUIRED_TEXT);
const ROLLOVER_CANNOT_BE_SET_ERROR = 'Rollover cannot be set if run out is not defined';
const ROLLOVER_CANNOT_BE_ERLIER_ERROR = 'Rollover cannot be earlier than run out';

export default (
  disabled?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
) => {
  // const errors = useGracePeriodStore((state) => state.errors);
  const sourceState = useRolloverStore((state) => state.sourceState);
  const currentState = useRolloverStore((state) => state.state);

  const previewMode = usePreviewStore((state) => state.previewMode);
  const { data: planIRSOptions } = useIRSMaximumQuery(isTemplateMode, true);
  const { data: templateIRSOptions } = useIRSMaximumTemplateTypesQuery(isTemplateMode, true);

  const IRSOptions = useMemo(() => {
    if (isTemplateMode) {
      return templateIRSOptions;
    }
    return planIRSOptions;
  }, [isTemplateMode, planIRSOptions, templateIRSOptions]);

  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const { rollover, autoEnrollment, rolloverClaims } = currentState;

  const { runOut, runOutAmount } = useGracePeriodStore((state) => state.state);
  const validationSchema = yup.string().test({
    test: (val) => {
      if (!!rollover && !val) return false;
      return true;
    },
    message: REQUIRED_TEXT,
  });

  const fields = useMemo<Field[]>(() => [
    {
      name: ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT,
      type: FieldTypes.Radio,
      showRequireIcon: true,
      testId: 'maximumRolloverAmount-id',
      validator: validationSchema,
      label: LABELS.MAXIMUM_ROLLOVER_AMOUNT,
      expandItems: true,
      options: [
        {
          label: 'Unlimited',
          value: 'UNLIMITED',
        },
        {
          label: 'IRS limit',
          value: IRS_MAXIMUM_KEY,
        },
        {
          label: 'Custom',
          value: CUSTOM_KEY,
        },
      ],
      subFields: [
        {
          name: ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT_CUSTOM,
          type: FieldTypes.Currency,
          label: '',
          value: '',
          placeholder: 'Enter $ amount',
          validator: yup.string().when(ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT, {
            is: (val: string) => val === CUSTOM_KEY,
            then: REQUIRED_VALIDATOR,
            otherwise: VALIDATOR,
          }).test({
            exclusive: false,
            test(val) {
              const minimum = this.parent[ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT_CUSTOM];
              if (!val || !minimum) {
                return true;
              }
              return _.toNumber(minimum) <= _.toNumber(val);
            },
            message: MAX_ROLLOVER_MESSAGE,
          }),
          parentValue: CUSTOM_KEY,
        },
        {
          name: ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT_IRS,
          parentValue: IRS_MAXIMUM_KEY,
          type: FieldTypes.Dropdown,
          singleMode: true,
          placeholder: 'Select max value',
          options: IRSOptions,
          validator: yup.string().when(ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT, {
            is: (val: string) => val === IRS_MAXIMUM_KEY,
            then: REQUIRED_VALIDATOR,
            otherwise: yup.string().nullable(),
          }),
          label: '',
        },
      ],
    },
    {
      name: ROLLOVER_KEYS.ROLLOVER_CLAIMS,
      type: FieldTypes.Checkbox,
      testId: 'claims-id',
      label: LABELS.ROLLOVER_CLAIMS,
      // showRequireIcon: true,
      helpText: 'Allow prior year claims',
      validator: yup.string().nullable(),
      subFields: [
        {
          name: ROLLOVER_KEYS.ROLLOVER_CLAIMS_YEARS_VALUE,
          type: FieldTypes.Number,
          label: ' ',
          placeholder: 'Enter # of days',
          parentValue: true,
          regexp: REGEXPS.ONLY_NUMBERS,
          validator: yup.string().when(ROLLOVER_KEYS.ROLLOVER_CLAIMS, {
            is: (val: string) => val === 'true',
            then: REQUIRED_VALIDATOR,
            otherwise: VALIDATOR,
          }),
        },
      ],
    },
    {
      name: ROLLOVER_KEYS.AUTO_ENROLLMENT,
      type: FieldTypes.Radio,
      showRequireIcon: true,
      label: 'Auto enrollment',
      options: [{
        label: 'Yes',
        value: 'yes',
      }, {
        label: 'No',
        value: 'no',
      }],
      validator: validationSchema,
      subFields: [
        {
          name: ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT,
          type: FieldTypes.Radio,
          showRequireIcon: true,
          defaultValue: '',
          expandItems: true,
          testId: 'minimumRolloverAmount-id',
          label: LABELS.MINIMUM_ROLLOVER_AMOUNT,
          validator: autoEnrollment === YES_KEY ? yup
            .string()
            .test({
              exclusive: false,
              test(val) {
                if (!!rollover && !val) {
                  return false;
                }
                return true;
              },
              message: REQUIRED_TEXT,
            }) : undefined,
          parentValue: YES_KEY,
          options: [
            {
              label: 'No minimum',
              value: 'NO_MINIMUM',
            },
            {
              label: 'Custom',
              value: CUSTOM_KEY,
            },
          ],
          subFields: [
            {
              name: ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT_CUSTOM,
              type: FieldTypes.Currency,
              value: '',
              label: '',
              placeholder: 'Enter $ amount',
              validator: yup
                .string()
                .when(ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT, {
                  is: (val: string) => val === CUSTOM_KEY,
                  then: REQUIRED_VALIDATOR,
                  otherwise: VALIDATOR,
                })
                .test({
                  exclusive: false,
                  test(val) {
                    const maximum = this.parent[ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT_CUSTOM];
                    if (!val || !maximum) {
                      return true;
                    }
                    return _.toNumber(maximum) >= _.toNumber(val);
                  },
                  message: MAX_ROLLOVER_MESSAGE,
                }),
              parentValue: CUSTOM_KEY,
            },
          ],
        },
      ],
    },
    {
      name: ROLLOVER_KEYS.ROLLOVER_CLAIMS_DAYS_AFTER_PLAN_END,
      type: FieldTypes.Number,
      label: LABELS.DAYS_AFTER_PLAN_END,
      showRequireIcon: true,
      disabled: rolloverClaims,
      defaultValue: rolloverClaims ? '1' : undefined,
      placeholder: 'Enter # of days',
      regexp: REGEXPS.ONLY_NUMBERS,
      lowerHelpText: rolloverClaims ? 'When rollover claims functionality is supported, the system roll funds over on the next day after plan year end.' : '',
      children: (
        <p> When rollover claims functionality is supported,
          the system roll funds over on the next day after plan year end.
        </p>
      ),
      validator: rolloverClaims ? VALIDATORS.REQUIRED_STRING : VALIDATORS.REQUIRED_STRING.test({
        test: (val) => {
          if (_.toNumber(val) < 1 && runOut === RUN_OUT_LAST_DAY_OF_PLAN_YEAR_VALUE) return false;
          if (_.toNumber(val) <= _.toNumber(runOutAmount) && runOut === CUSTOM_KEY) return false;
          return true;
        },
        message: ROLLOVER_CANNOT_BE_ERLIER_ERROR,
      }),
    },
  ], [validationSchema, IRSOptions, autoEnrollment,
    rolloverClaims, rollover, runOut, runOutAmount]);
  const formatedFields = useMemo(() => {
    const currentFields: Field[] = [
      {
        name: ROLLOVER_KEYS.ROLLOVER,
        type: FieldTypes.Checkbox,
        testId: 'accountFunding-id',
        label: LABELS.ROLLOVER,
        validator: yup.string().test({
          test: (val) => {
            if (val === TRUE_KEY && runOut === RUN_OUT_NO_CLAIMS_DEADLINE_VALUE) return false;
            if (val === TRUE_KEY && !runOut) return false;
            return true;
          },
          message: ROLLOVER_CANNOT_BE_SET_ERROR,
        }),
      },
      ...rollover ? fields : [],
    ];

    return useFieldsWithDefaultValues(currentFields, appliedSourceState);
  }, [rollover, fields, appliedSourceState, runOut]);
  const filteredField = usePlanFieldsState(formatedFields);
  const stateFields = useTemplateFieldState(filteredField, isTemplateMode, isEditMode);

  return stateFields;
};
