import { QueryClient, useMutation } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { SERVER_ERRORS } from '@/common/constants';

import { EmployeePayload } from '../Employee.types';
import { GET_SSN_QUERY_KEY } from '../PersonalInformation/EmployerInfo/queries/useGetEmployeeSSN.query';
import { useHasSameSSNStore } from '../stores';

const useUpdateEmployeeQuery = () => {
  const queryClient = new QueryClient();
  const { setHasSameSSN } = useHasSameSSNStore();
  const { mutateAsync, isLoading } = useMutation(
    ({ id, ...value }: EmployeePayload) => api.put<EmployeePayload>(
      PATHS.EMPLOYEE_BY_ID(id || ''),
      value,
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SSN_QUERY_KEY]);
      },
      onError: ({ response: { data: { elevate_error_message }, status } }) => {
        if (status === SERVER_ERRORS.CONFLICT || elevate_error_message?.includes('Employee with the same SSN and active status already exists')) {
          setHasSameSSN();
        }
      },
    },
  );
  return {
    mutateAsync,
    isLoading,
  };
};

export default useUpdateEmployeeQuery;
