import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api } from '@/api';
import { PATHS } from '@/common';

export interface IrsMaximumsDto {
  id: number,
  tax_year: number,
  amount: number,
  tax_year_end: string,
}

export const QUERY_KEY = 'useGetIrsMaximumsQueryKey';
export const useGetTaxYearFromIrsMaximumsQuery = () => {
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY],
    () => api.get<IrsMaximumsDto[]>(PATHS.IRS_MAXIMUM),
  );

  const lastDayForContribution = useMemo(() => {
    if (!data?.data) return undefined;

    return data.data
      .find((item) => item?.tax_year === dayjs().year() - 1)?.tax_year_end;
  }, [data]);

  return {
    lastDayForContribution,
    isLoading,
    data: data?.data,
    isSuccess,
  };
};
