import React, { useEffect, useMemo, useState } from 'react';
import { ApprovalForm, useDebounce } from '@common-fe/common-fe';

import { Lockbox, LockboxStatus } from '@/modules/employer/components/Lockbox/lockbox.types';
import LockboxInfoContainer from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoContainer';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

import useLockboxProcessingOptions from './useLockboxProcessingOptions';

interface ProcessingValues {
  value?: string;
  subValue?: string;
  note?: string;
}

interface Props {
  lockbox?: Lockbox;
}

const LockboxProcessing: React.FC<Props> = ({ lockbox }) => {
  const [values, setValues] = useState<ProcessingValues>({});
  const debouncedValues = useDebounce<ProcessingValues>(values);
  const { editableLockbox, setEditableLockbox } = useLockboxDetailsStore();
  const { options } = useLockboxProcessingOptions({ lockboxType: editableLockbox?.lockboxType });

  const isAbleToEdit = useMemo(() => (lockbox?.lockboxStatus
    && lockbox?.lockboxStatus === LockboxStatus.NEW 
    || lockbox?.lockboxStatus === LockboxStatus.PENDING),
  [lockbox]);

  useEffect(() => {
    setEditableLockbox({
      lockboxStatus: debouncedValues?.value as LockboxStatus,
      reason: debouncedValues?.subValue,
      notes: debouncedValues?.note,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues]);

  useEffect(() => {
    setValues({});
  }, [editableLockbox?.lockboxType]);

  if (!editableLockbox?.lockboxType || !isAbleToEdit) {
    return null;
  }
  
  return (
    <LockboxInfoContainer margin={{ top: 'spacing8' }}>
      <ApprovalForm
        values={values}
        onChangeValues={setValues}
        options={options}
      />
    </LockboxInfoContainer>
  );
};

export default LockboxProcessing;
