import React from 'react';
import {
  Box, costFormater, GagueChart, Text,
} from '@common-fe/common-fe';

import { DeductibleUser } from './hooks/useDependents';
import { useDeductibles, useDependents } from './hooks';
import SpendTrackerUser from './SpendTrackerUser';

interface Props {
  employeeUser: DeductibleUser,
  isIndividual?: boolean;
  userId?: string;
}
const SpendTracker: React.FC<Props> = ({ userId, employeeUser, isIndividual }) => {
  
 


  const {
    chartValues, total, chartTiersValues, remaining, isLoading, individualTotal,
  } = useDeductibles({
    focusedUserId: '',
    isIndividual,
    employeeId: employeeUser?.id,
    userId,
  });
  const users = useDependents({
    userId: employeeUser?.id || userId,
    employeeUser,
    isIndividual,
  });

  if (isLoading || total <= 0) {
    return null;
  }
  return (
    <Box background="module" round="moduleRound" pad="l" margin={{ top: 'l', bottom: 'm' }}>
      <Text size="large" weight={700}>
        Spend Tracker
      </Text>
      <Box margin={{ top: 'l' }} direction="row">
        <Box elevation="default" fill width={{ max: '360px' }} round="container1Round" background="canvas">
          <GagueChart items={chartValues} tiers={chartTiersValues}>
            <Box direction="column" align="center" justify="end" margin={{ top: 'auto' }}>
              <Text weight={400} color="textSecondary">Remaining</Text>
              <Text size="2xl" weight={700}>{costFormater(remaining, { isPrecision: true })}</Text>
              <Text>of {costFormater(total, { isPrecision: true })}</Text>
            </Box>
          </GagueChart>

        </Box>
        <Box direction="column" elevation="default" round="container1Round" fill background="canvas" margin={{ left: 'm' }} pad="l">
          <Box background="module" round="container1Round" pad="s">
            <Box direction="column" round="container1Round" background="canvas" pad="s">
              <Box direction="row">
                <Text>
                  {isIndividual ? 'Individual Deductible:' : 'Deductible:'}
                </Text>
                <Text
                  margin={{ left: 'xs' }}
                  weight={700}
                >{costFormater(total, { isPrecision: true })}
                </Text>
              </Box>
              <Box direction="row">
                <Text>Remaining:</Text>
                <Text
                  margin={{ left: 'xs' }}
                  weight={700}
                >{costFormater(remaining, { isPrecision: true })}
                </Text>
              </Box>
            </Box>
          </Box>
          {!isIndividual && (
            <Box
              direction="row"
              justify="start"
              align="start"
              margin={{ top: 'xs' }}
              pad={{ top: 'xs' }}
              border={{ side: 'top', color: 'border1', size: '1px' }}
              style={{
                flexWrap: 'wrap',
              }}
            >
              {users.map((user, index) => (
                <SpendTrackerUser
                  key={user.id}
                  index={index}
                  disabled={!individualTotal}
                  employeeId={employeeUser?.id}
                  fullName={user.fullName}
                  id={user.id}
                />
              ))}
            </Box>
          )}
        </Box>

      </Box>

    </Box>
  );
};

export default React.memo(SpendTracker);
