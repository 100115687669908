import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  costFormater,
  FlexListItemOption,
  formatSnakeCaseToGeneral,
  Inscription,
  OptionsButton,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize, toNumber } from 'lodash';

import { DEFAULT_DATE_FORMAT,EMPTY_FIELD_VALUE } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { useHasAccess } from '@/modules/core/hooks';
import { PAYMENT_METHOD_TITLE, PaymentMethodType } from '@/modules/employee/employee.types';
import { getFullAddress } from '@/utils/modifiers';

import { EllipsisText, OptionsButtonWrapper } from './Checks.styled';
import { Check, CheckStatus,checkStatusColor } from './constants';
import ReissueCheckPopup from './ReissueCheckPopup';
import VoidCheckPopup from './VoidCheckPopup';

const DEFAULT_CLAIMS_SHOWED = 3;
const SEPARATOR = '-';
const CLAIM_MASK = `9${SEPARATOR}999${SEPARATOR}999`;
const formatClaimId = (claimId: string = '') => claimId.split('').map((symbol, index) => {
  if (index === CLAIM_MASK.indexOf(SEPARATOR)) return SEPARATOR.concat(symbol);
  if (index === CLAIM_MASK.indexOf(SEPARATOR, 2) - 1) return SEPARATOR.concat(symbol);
  return symbol;
}).join('');

interface Props {
  check: Check;
  setOpenCheckId: (id: string) => void;
  openCheckId?: string;
  setSearchCheckId: React.Dispatch<React.SetStateAction<string>>;
  clearFilters: () => void;
}

const CheckRow: React.FC<Props> = ({ check, setOpenCheckId, openCheckId, clearFilters, setSearchCheckId }) => {
  const [hasVoidCheckPopup, openVoidCheckPopup] = useState(false);
  const [hasReissueCheckPopup, openReissueCheckPopup] = useState(false);
  const hasAccessToVoidingReissue = useHasAccess([{ permission: Permissions.VOID_REISSUE_CHECK }]);
  const hasVoidOption = useMemo(
    () => hasAccessToVoidingReissue && (check.status === CheckStatus.PENDING || check.status === CheckStatus.ISSUED),
    [check, hasAccessToVoidingReissue],
  );
  const hasReissueOption = useMemo(() => check.status === CheckStatus.ISSUED && hasAccessToVoidingReissue,
    [check, hasAccessToVoidingReissue]);
  const [areAllClaimsShowed, showAllClaims] = useState((check?.claimIds?.length || 0) <= DEFAULT_CLAIMS_SHOWED);
  const expanded = useMemo(() => check?.id === openCheckId, [check, openCheckId]);
  useEffect(() => {
    if (areAllClaimsShowed && !expanded) {
      showAllClaims(false);
    }
  }, [expanded, areAllClaimsShowed, showAllClaims]);
  const amount = useMemo(() => check.amount ? costFormater(check.amount, true) : '', [check.amount]);
  const issuedDate = useMemo(() => check.issuedDate ? dayjs(check.issuedDate).format(DEFAULT_DATE_FORMAT) : '', [check.issuedDate]);
  const statusDate = useMemo(() => check.statusDate ? dayjs(check.statusDate).format(DEFAULT_DATE_FORMAT) : '', [check.statusDate]);
  const status = useMemo(() => formatSnakeCaseToGeneral(check.status || ''), [check.status]);
  const statusColor = useMemo(() => check?.status ? checkStatusColor[check.status] : '', [check.status]);
  const address = useMemo(
    () => getFullAddress(check?.address),
    [check.address],
  );
  const history = useHistory();
  const expand = useCallback(() => setOpenCheckId(expanded ? '' : check?.id || ''), [expanded, check, setOpenCheckId]);
  const reissuedText = useMemo(() => {
    if (!check?.reissuePaymentMethodType) return '';
    const formattedPaymentMethod = PAYMENT_METHOD_TITLE[check.reissuePaymentMethodType];
    if (check?.reissueCheckId) return `${formattedPaymentMethod} #${check?.reissueCheckId}`;
    return formattedPaymentMethod;
  }, [check]);

  return (
    <Box
      round="container2Round"
      background="canvas"
      border={{ side: 'all', size: 'small', color: 'border2' }}
      pad={{ horizontal: 'spacing24', vertical: '18px' }}
      margin={{ top: 'spacing8' }}
      style={{ boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.03)' }}
    >
      <VoidCheckPopup
        id={check.id}
        isVisible={hasVoidCheckPopup}
        close={() => openVoidCheckPopup(false)}
      />
      <ReissueCheckPopup
        isVisible={hasReissueCheckPopup}
        close={() => openReissueCheckPopup(false)}
        check={check}
      />
      <Box direction="row" align="center">
        <Box onClick={expand}>
          {expanded ? <ArrowUpIcon color="iconPrimary" /> : <ArrowDownIcon color="iconPrimary" />}
        </Box>
        <Box margin={{ left: '25px' }} width="100px" justify="center" onClick={expand}>
          <Inscription weight="bold" lineHeight="20px">{check.id || EMPTY_FIELD_VALUE}</Inscription>
        </Box>
        <Box width="104px" justify="center" onClick={expand}>
          <Inscription lineHeight="20px">
            {amount || EMPTY_FIELD_VALUE}
          </Inscription>
        </Box>
        <Box width="195px" justify="center">
          <Box direction="row">
            <EllipsisText
              lineHeight="20px"
              title={check.employeeName}
              cursor="pointer"
              {...check.employeeId && check.employeeName ? {
                onClick: () => window.open(`${ROUTES.EMPLOYEE_VIEW(check.employeeId)}${history.location.search}`, '_blank'),
                color: 'textAccent',
              } : { onClick: expand }}
            >
              {check.employeeName || EMPTY_FIELD_VALUE}
            </EllipsisText>
            <Box flex onClick={expand} />
          </Box>
        </Box>
        <Box width="154px" justify="center" onClick={expand}>
          <EllipsisText lineHeight="20px" {...check.employerName ? { title: check.employerName } : {}}>
            {check.employerName || EMPTY_FIELD_VALUE}
          </EllipsisText>
        </Box>
        <Box width="98px" justify="center" onClick={expand}>
          <EllipsisText lineHeight="20px" {...issuedDate ? { title: issuedDate } : {}}>
            {issuedDate || EMPTY_FIELD_VALUE}
          </EllipsisText>
        </Box>
        <Box width="130px" justify="center" onClick={expand}>
          <EllipsisText lineHeight="20px" {...statusDate ? { title: statusDate } : {}}>
            {statusDate || EMPTY_FIELD_VALUE}
          </EllipsisText>
        </Box>
        <Box width="138px" justify="between" direction="row" align="center" onClick={expand}>
          <EllipsisText lineHeight="20px" title={status}>
            {status || EMPTY_FIELD_VALUE}
          </EllipsisText>
          {statusColor ? (
            <Box
              width={{ width: '10px', min: '10px' }}
              height="10px"
              margin={{ left: 'spacing8', right: 'spacing24' }}
              round
              background={statusColor}
            />
          ) : null}
        </Box>
        <OptionsButtonWrapper>
          <OptionsButton disabled={!hasVoidOption && !hasReissueOption} {...hasVoidCheckPopup || hasReissueCheckPopup ? { isOpen: false } : {}}>
            {hasVoidOption ? (
              <FlexListItemOption
                name="Void"
                testId="Void"
                onClick={() => {
                  setOpenCheckId(check?.id || '');
                  openVoidCheckPopup(true);
                }}
                optionStyle={{ minWidth: '135.91px' }}
              >
                Void
              </FlexListItemOption>
            ) : null}
            {hasReissueOption ? (
              <FlexListItemOption
                name="Reissue"
                testId="Reissue"
                onClick={() => {
                  setOpenCheckId(check?.id || '');
                  openReissueCheckPopup(true);
                }}
                optionStyle={{ minWidth: '135.91px' }}
              >
                Reissue
              </FlexListItemOption>
            ) : null}
          </OptionsButton>
        </OptionsButtonWrapper>
      </Box>
      {expanded ? (
        <Box
          border={{ color: 'border1', side: 'top'}}
          pad={{ top: 'spacing16' }}
          margin={{ top: 'spacing16' }}
          align="baseline"
          direction="row"
        >
          <Box width="254px" height="38px" justify="center">
            <Box width="230px">
              <EllipsisText lineHeight="18px" size="12px" weight="bold">
                Check Sent to
              </EllipsisText>
              <EllipsisText lineHeight="20px" title={address}>
                {address || EMPTY_FIELD_VALUE}
              </EllipsisText>
            </Box>
          </Box>
          <Box width="254px" height="38px" justify="center">
            <Box width="230px">
              <EllipsisText lineHeight="18px" size="12px" weight="bold">
                Pay To
              </EllipsisText>
              <EllipsisText lineHeight="20px" title={check?.payTo}>
                {check?.payTo || EMPTY_FIELD_VALUE}
              </EllipsisText>
            </Box>
          </Box>
          <Box width="280px" justify="center">
            <Box width="256px">
              <EllipsisText lineHeight="18px" size="12px" weight="bold">
                Related Claims
              </EllipsisText>
              <Box>
                {check?.claimIds?.length ? check.claimIds.map((id, index) => {
                  const isShown = areAllClaimsShowed || index < DEFAULT_CLAIMS_SHOWED;
                  const isLastItem = index === toNumber(check?.claimIds?.length) - 1;
                  if (isLastItem && !isShown) return (
                    <EllipsisText
                      key={id}
                      lineHeight="20px"
                      cursor="pointer"
                      color="info"
                      onClick={() => showAllClaims(true)}
                    >
                      {`Show More (${toNumber(check?.claimIds?.length) - DEFAULT_CLAIMS_SHOWED})`}
                    </EllipsisText>
                  );
                  if (!isShown) return null;
                  const formattedClaimId = formatClaimId(id);
                  return (
                    <EllipsisText
                      key={id}
                      lineHeight="20px"
                      title={formattedClaimId}
                      {...id ? {
                        onClick: () => window.open(`${ROUTES.CLAIM_PROCESS}/${id}${history.location.search}`, '_blank'),
                        cursor: 'pointer',
                        color: 'info',
                      } : { color: 'textTitle' }}
                    >
                      {formattedClaimId || EMPTY_FIELD_VALUE}
                    </EllipsisText>
                  );
                }) : <EllipsisText color="textTitle" lineHeight="20px">{EMPTY_FIELD_VALUE}</EllipsisText>}
              </Box>
            </Box>
          </Box>
          {check?.voidReason ? (
            <Box width="280px" justify="center">
              <Box width="256px">
                <EllipsisText lineHeight="18px" size="12px" weight="bold">
                  Void Reason
                </EllipsisText>
                <EllipsisText color="textTitle" lineHeight="20px">{capitalize(check.voidReason.replace(/_/g, ' '))}</EllipsisText>
              </Box>
            </Box>
          ) : null}
          {check?.reissuePaymentMethodType ? (
            <Box width="280px" justify="center">
              <Box width="256px">
                <EllipsisText lineHeight="18px" size="12px" weight="bold">
                  Reissued with
                </EllipsisText>
                <Box>
                  <EllipsisText
                    key={reissuedText}
                    lineHeight="20px"
                    title={reissuedText}
                    {...check?.reissueCheckId && check.reissuePaymentMethodType === PaymentMethodType.CHECK ? {
                      onClick: () => {
                        clearFilters();
                        setSearchCheckId(check.reissueCheckId as React.SetStateAction<string>);
                      },
                      cursor: 'pointer',
                      color: 'info',
                    } : { color: 'textTitle' }}
                  >
                    {reissuedText}
                  </EllipsisText>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default CheckRow;
