import React, { useMemo } from 'react';
import {
  Box, ErrorModal,
  FlexList,
  PaginationBar,
  SearchInput,
  SelectDropdown,
  Text, } from '@common-fe/common-fe';

import { OrganizationTypes, SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE } from '@/common/constants';
import {
  ListItemBase,
  OptionKey,
  // DefaultSortTypesEnum,
} from '@/common/types';
import { EMPLOYER_STATUSE_OPTIONS,EmployerStatus } from '@/modules/employer/employer.constants';
import { useListPagination } from '@/modules/employer/hooks';
import { stateAllToggling } from '@/utils/handlers/dropdownStateAllToggling';
import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { usePartnersList } from './hooks';

const START_PAGE = 1;

interface Props {
  mode: OrganizationTypes;
}

const PartnersList: React.FC<Props> = ({ mode }) => {
  const title = useMemo(() => {
    switch (mode) {
    case OrganizationTypes.distributor: return 'Distributor';
    case OrganizationTypes.subsidiary: return 'Subsidiary';
    default: return 'Partner';
    }
  }, [mode]);
  const headers: ListItemBase[] = useMemo(() => ([
    {
      key: 'Name',
      title: 'Name',
      flex: 2,
    },
    {
      key: 'id',
      title: `${title} ID`,
    },
    {
      key: 'status',
      title: 'Status',
    },
    {
      key: 'statusDate',
      title: 'Status date',
    }]), [title]);
  // const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const {
    page,
    perPage,
    currentSearch,
    filterParams,
    setPage,
    setPerPage,
    searchString,
    setSearchString,
    setFilterParams,
  } = useListPagination();

  const filterParamsValues = useMemo(() => stateAllToggling(filterParams), [filterParams]);

  const hasFilterParams = useMemo(
    () => filterParamsValues.length > 0
      && !filterParamsValues.some((param) => param === EmployerStatus.Null),
    [filterParamsValues],
  );

  const {
    formatedList, total, isLoading, error, refetch,
  } = usePartnersList({
    page: page - 1,
    perPage,
    // sortBy: sortBy as DefaultSortTypesEnum,
    searchString: currentSearch || '',
    status: (hasFilterParams ? filterParamsValues.join(', ') : ''),
    mode,
  });
  const totalTitle = useMemo(() => {
    if (mode === OrganizationTypes.subsidiary) {
      return `Subsidiaries: ${total || 0}`;
    }

    return `${title}s: ${total || 0}`;
  }, [total, title, mode]);

  const { isServerError, setIsServerError } = useIsServerError(error);

  return (
    <Box>
      <FlexList
        testId={`${title}List`}
        headers={headers}
        rows={formatedList}
        total={total}
        pad="spacing24"
        moreCount={perPage}
        loading={isLoading}
        // options={deactivateOption ? [deactivateOption] : []}
        footer={(
          <PaginationBar
            page={page}
            testId={`${title}List_pagination`}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
      >
        <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing16' }}>
          <Text
            margin={{ top: 'spacing8', bottom: 'spacing12' }}
            weight="bold"
            color="textBody"
          >{totalTitle}
          </Text>
          <Box direction="row" justify="end">
            <SearchInput
              className="search-input"
              testId={`${title}List`}
              value={searchString}
              hasSearchingHistory
              placeholder="Search"
              onChange={setSearchString}
            />
            <Box margin={{ left: 'spacing12' }} width={{ min: 'control', max: 'control' }}>
              <SelectDropdown
                id="status"
                testId={`${title}sList`}
                allMode
                activeTitle
                ellipsisMode
                name="Activity status"
                prefix="Status"
                options={EMPLOYER_STATUSE_OPTIONS}
                values={filterParamsValues}
                onChangeValues={(value: OptionKey[]) => {
                  setPage(START_PAGE);
                  setFilterParams(value);
                }}
              />
            </Box>
            {/* <Box margin={{ left: 'spacing12' }} width={{ min: 'control', max: 'control' }}>
              <SelectDropdown
                id="sort_by"
                singleMode
                ellipsisMode={false}
                activeTitle
                name="Sort By"
                prefix="Sort By"
                options={[
                  {
                    key: DefaultSortTypesEnum.ASC,
                    value: DefaultSortTypesEnum.ASC,
                    title: 'A-Z',
                  },
                  {
                    key: DefaultSortTypesEnum.DESC,
                    value: DefaultSortTypesEnum.DESC,
                    title: 'Z-A',
                  },
                ]}
                value={sortBy}
                onChange={setSortBy}
              />
            </Box> */}
          </Box>
        </Box>

      </FlexList>

      {/* <WarnModal
        testId={`${title}sList_deactivate`}
        visible={!!deactivationItem}
        onSetVisible={() => handleResetDeactivationItem()}
        header={`Are you sure you want to deactivate ${deactivationItemTitle}?`}
        buttonText="Cancel"
        submitButtonText="Yes, Deactivate"
        submitButtonMode="alert"
        onSubmit={() => handleDeactivateItem(deactivationItem)}
      /> */}
      <ErrorModal
        testId={`${title}sList_server-error`}
        visible={isServerError}
        header={SERVER_ERROR_MODAL_TITLE}
        helpText={SERVER_ERROR_MODAL_TEXT}
        buttonText="Close"
        buttonTextTryAgain="Try again"
        onSetVisible={setIsServerError}
        onSubmit={refetch}
      />
    </Box>
  );
};

export default PartnersList;
