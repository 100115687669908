import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { Topbar } from '@/modules/core/components';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { Deductibles } from '@/modules/deductibles';
import { useDependents } from '@/modules/deductibles/SpendTracker/hooks';
import { useGetEmployeeById } from '@/modules/employee/Employee/hooks';
import theme from '@/styles/theme';

const DeductiblesPage = () => {
  const { userId, employeeId } = useParams<{ userId: string, employeeId: string }>();
  const { isLoading, data: employeeData } = useGetEmployeeById(employeeId);
  const employeeUser = useMemo(
    () => ({
      id: `${employeeData?.internalId || ''}`,
      fullName: `${employeeData?.firstName || ''} ${employeeData?.lastName || ''}`.trim(),
    }), [employeeData],
  );
  const users = useDependents({
    userId, isIndividual: true, employeeUser,
  });

  const user = useMemo(() => {
    if (users.length) {
      return users[0];
    }
    return null;
  }, [users]);

  useBreadcrumbs([{
    route: ROUTES.EMPLOYEES,
    title: 'Employees',
  },
  {
    route: ROUTES.EMPLOYEE_VIEW(employeeId),
    title: `${employeeUser?.fullName || ''}`,
  },
  {
    route: ROUTES.SPENDS_BY_ID(employeeId, userId),
    title: `Deductible ${user?.fullName || ''}`,
  }], [user, employeeUser]);

  if (isLoading) {
    return null;
  }

  return (
    <Box width="100%">
      <Topbar />
      <Box width={theme.defaultContentWidth} margin={{ horizontal: 'auto' }} pad={{ top: 'l' }}>
        <Deductibles userId={userId} employeeUser={employeeUser} />
      </Box>
    </Box>

  );
};

export default DeductiblesPage;
