import { BankTypeEnum } from '@/modules/transaction/Cards/Cards.types';

export default {
  ONLY_NUMBERS: /^[0-9]*$/,
  ONLY_NUMBERS_WITHOUT_ZERO: /^[1-9][0-9]*$|^$/,
  FIRST_LETTERS: /\b(\w)/g,
  ALPHANUMERIC: /^[a-z\d\s]+$/i,
  ONLY_LATIN_AND_NUMBER: /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s,0-9]*)$/gi,
  SPECIAL_CHARACTER: /[\^$*.[\]{}()?\-"!@#%&№;.'/,><’:;|_~`+=]/g,
  DASH_SYMBOL: /_/g,
  ALL_NON_NUMERIC: /\D/g,
  SPACE_CODE_SYMBOL: '\u00a0',
  SPACE_SYMBOL: / /g,
  START_SYMBOL_REG: /[*]/g,
  SSN: /(\d{3})(\d{2})(\d{4})/,
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  ONLY_SPACES: /^\s*$/,
  CSV: /\.[csv]{3}$/,
  EXCLUDE_NUMBERS: /\D+/g,
  PARENTHESES: /[()[\]{}]/g,
  NUMBER_AND_DASH: /[0-9, -]/g,
  DEFAULT_CARD_EXP_DATE: /^\d{2}\s\/\s\d{2}/,
  TWO_DIGITS: /^\d{0,2}$/,
  /* More than 5 symbols, letters in uppercase/lowercase, number, special character. */
  LOWERCASE_AND_UPPERCASE: /(?=.*[A-Z])(?=.*[a-z])/,
  NUMBERS_AND_LETTERS: /(?=.*[A-Z])(?=.*[0-9])/i,
  SPECIAL_CHARACTER_PASSWORD: /[~`! @#$%^&*()\-_+={}[\]|\\;:"<>,./?]/,
  PASSWORD_COMPLEXITY: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{6,}/,
  BANK_CARD: {
    [BankTypeEnum.MASTERCARD]: /^(5[1-5])/,
    [BankTypeEnum.VISA]: /^4/,
    [BankTypeEnum.AMEX]: /^3[47][0-9]{13}$/,
  },
  BANK_CARD_FULL: {
    AMEX: /^3[47][0-9]{13}$/,
     
    MASTERCARD_FULL: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    VISA_FULL: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/,
  },
  /* eslint-disable-next-line no-useless-escape */
  HEIF: /([a-zA-Z0-9\s_\\.\-\(\):])+(.heif|.heic)$/i,
  NON_ALPHANUMERIC: /[^a-zA-Z0-9 ]/g, 
};
