import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';
import { BASED_ON_NONE_KEY } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { ProcessingRuleItem } from '@/modules/ProcessingDefinition/ProcessingDefinition.types';
import { useFieldsWithDefaultValues } from '@/utils';

import { MainInfo } from '../CreateProcessingRule.types';

export default (
  defaultValues?: Partial<MainInfo>,
  updatedElement?: ProcessingRuleItem,
  errors?: Partial<MainInfo>,
  processingRulesList?: Option[],
) => {
  const fields = useMemo<Field[]>(() => [{
    name: 'name',
    type: FieldTypes.Text,
    label: 'Processing rule name',
    placeholder: 'Enter name',
    validator: yup.string().test({
      test: (val) => val !== errors?.name || !errors?.name,
      message: 'This name is already used by another processing rule',
    }).required(REQUIRED_TEXT),
    defaultValue: updatedElement?.name || '',
  },
  !updatedElement ? {
    name: 'basedOn',
    type: FieldTypes.Dropdown,
    label: 'Based on',
    singleMode: true,
    options: processingRulesList && [{
      key: BASED_ON_NONE_KEY,
      value: undefined,
      title: 'None',
      divide: true,
    }, ...processingRulesList],
    placeholder: 'Select based on',
    isNarrowLabelMode: true,
  } : {
    name: 'empty',
    type: FieldTypes.Node,
  }], [errors, processingRulesList, updatedElement]);

  return useFieldsWithDefaultValues(fields, defaultValues);
};
