// import _ from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { IrsLimitTypesLabel } from '@/modules/plan/PlanSetup/PlanSetup.constants';

import { usePlanYearStore } from '../../PlanYear/stores';

export interface IRSPayload {
  amount: number;
  frequency_type: 'ANNUALY' | 'MONTHLY';
  id: number;
  type: string;
  year: number;
}
export const IRS_MAXIMUM_QUERY_KEY = 'getIRSMaximum';

export const COVERAGE_LEVELS = {
  FAMILY: 'FAMILY',
  ROLLOVER: 'ROLLOVER',
};

export default (
  isTemplateMode?: boolean,
  isRolover?: boolean,
) => {
  // @ts-ignore
  const year = usePlanYearStore((state) => state.sourceState?.planStartDate);
  const currentYear = year && dayjs(year, DEFAULT_DATE_FORMAT).format('YYYY');
  const {
    isLoading, isSuccess, isError, data, error,
  } = useQuery([IRS_MAXIMUM_QUERY_KEY, currentYear, isRolover, isTemplateMode],
    () => api.get<IRSPayload[]>(PATHS.IRS_MAXIMUM, {
      params: _.pickBy({
        coverage_level: isRolover ? COVERAGE_LEVELS.ROLLOVER : COVERAGE_LEVELS.FAMILY,
        tax_year: currentYear,
      }),
    }), {
      enabled: !!currentYear && !isTemplateMode,
      refetchOnMount: false,
    });

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];
    return list?.map((item) => ({
      key: item.type,
      value: IrsLimitTypesLabel[item.type as keyof typeof IrsLimitTypesLabel],
      additionalValue: item.amount,
    }));
  }, [data]);

  return ({
    data: formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
  });
};
