import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT, OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { LogoInfoPayload, Pagination, PaginationParams } from '@/common/types';
import { Employer } from '@/modules/employer/employer.types';

export const QUERY_KEY = 'useEmployersList';

export interface EmployerDto {
  id: string;
  name?: string;
  short_name?: string;
  external_identifier?: string;
  organization_status_type?: string;
  valid_from?: string | number,
  parent_id?: string | number;
  path: string;
  logo_info?: LogoInfoPayload;
  is_locked: boolean | null;
  has_primary_contact?: boolean;
  is_bank_account_set?: boolean;
  is_plan_plan_year_set?: boolean;
}

interface Args extends PaginationParams{
  options?: Partial<{
    enabled: boolean
  }>
}

export default ({
  page, perPage, searchString, status, options, sortBy,
}: Args) => {
  const {
    isLoading, isSuccess, isError, data, refetch, isFetched, error,
  } = useQuery([QUERY_KEY, page, perPage, status, searchString, sortBy],
    () => api.get<Pagination<EmployerDto>>(PATHS.ORGANIZATIONS, {
      params: {
        search_value: searchString,
        statuses: status,
        type: OrganizationTypes.company,
        page,
        size: perPage,
        ...sortBy ? { sort: `name,${sortBy.toLowerCase()}` } : {},
      },
    }), {
      cacheTime: 0,
      ...options,
    });

  const formattedData = useMemo(() => data?.data.content?.map((item: EmployerDto): Employer => ({
    id: item.id,
    logo: item.short_name || item.name,
    name: item.name,
    logoUrl: item.logo_info?.large_logo_url,
    darkLargeLogoUrl: item?.logo_info?.dark_large_logo_url,
    partnerId: item.path.split('\\')[2],
    distributorId: item.path.split('\\')[3],
    employerID: item.external_identifier,
    status: item.organization_status_type && capitalizeFirstLetter(item.organization_status_type),
    date: dayjs(item.valid_from).format(DEFAULT_DATE_FORMAT),
    isLocked: !!item.is_locked,
    path: item.path,
    hasPrimaryContact: item?.has_primary_contact,
    isBankAccountSet: item?.is_bank_account_set,
    isPlanPlanYearSet: item?.is_plan_plan_year_set,
  })), [data]);

  const total = useMemo<number>(() => _.get(data, 'data.totalElements', 0) || 0, [data]);
  useEffect(() => {
    if (options?.enabled) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.enabled]);

  return ({
    data: formattedData || [],
    isError,
    isLoading,
    isSuccess,
    total,
    refetch,
    isFetched,
    error,
  });
};
