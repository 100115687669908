import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.colors.textTitle};
`;

export const StyledIcon = styled(Box)`
  svg { width: 20px; }
`;
