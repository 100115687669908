import React, { useEffect, useMemo,useState } from 'react';
import { Box, FlexControlledForm, useDebounce } from '@common-fe/common-fe';

import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';

import useFileManagerFields from './hooks/useFileManagerFields';
import useFileManagerSettingsStore, { FileManagerSettingsState } from './stores/useFileManagerSettings.store';


interface Props {
  isSubmitted?: boolean;
  isPartnerMode?: boolean;
}

const FileManagerSettings: React.FC<Props> = ({ isSubmitted,isPartnerMode }) => {
  const [value, setValue] = useState<Partial<FileManagerSettingsState>>();
  const debouncedValue = useDebounce(value);
  const { viewMode } = useSetupEmployerMode();
  const { pendingStatus, activeStatus } = useSaveTypeStore();
  

  const setFileManager= useFileManagerSettingsStore(state => state.setState);
  
  const isEditMode = useMemo(() => !viewMode, [viewMode]);
  const fields = useFileManagerFields({
    isEditMode,
    isPartnerMode,
    isSubmitted
  });

  useEffect(
    () => {
      delete debouncedValue?.isSameErrorReportLocation;
      setFileManager(debouncedValue || {});
    },
    [debouncedValue, setFileManager],
  );

  return (
    <Box
      background="canvas"
      round="container1Round"
      data-testId="file-manager-settings"
    >
      <FlexControlledForm
        formTitle="File Manager"
        fields={fields}
        showError={pendingStatus || activeStatus}
        editMode={isEditMode}
        onChangeValues={setValue}
        isThinMode
      />
    </Box>
  );
};

export default FileManagerSettings;
