import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { CopayGroupPayload } from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';
import { QUERY_KEY_COPAY_BY_ID } from '@/modules/HealthPlan/CopayAdjudication/queries/useCopayGroupById.query';
import { QUERY_KEY as QUERY_KEY_LIST } from '@/modules/HealthPlan/CopayAdjudication/queries/useCopayGroups.query';

export default () => {
  const queryClient = useQueryClient();
  const { observingOrganization: { id, path } } = useCurrentOrganization();
  const { mutateAsync, isLoading } = useMutation(
    (value: CopayGroupPayload) => api.post(
      PATHS.COPAY_GROUPS,
      {
        organization_id: _.toNumber(id),
        organization_path: path,
        name: value.name,
        valid_from: value.validFrom,
        valid_to: value.validTo,
        copays: value?.copays?.map((item) => ({
          category_type: item.category,
          amount: _.toNumber(item.amount),
        })) || [],
      },

    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY_LIST);
      },
    },
  );
  const { mutateAsync: mutateUpdateAsync, isLoading: isUpdateLoading } = useMutation(
    (value: CopayGroupPayload) => api.put(
      PATHS.COPAY_GROUPS_BY_ID(value.id),
      {
        id: value.id ? _.toNumber(value.id) : undefined,
        organization_id: _.toNumber(id),
        organization_path: path,
        name: value.name,
        valid_from: value.validFrom,
        valid_to: value.validTo,
        copays: value?.copays?.map((item) => ({
          id: item.id,
          category_type: item.category,
          amount: _.toNumber(item.amount),
        })) || [],
      },

    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY_LIST);
        queryClient.invalidateQueries([QUERY_KEY_COPAY_BY_ID]);
      },
    },
  );
  return {
    mutateAsync,
    mutateUpdateAsync,
    isLoading: isLoading && isUpdateLoading,
  };
};
