import { DocumentTypeEnum } from '@/common/types';

export const ROTATE_STEP_DEG = 90;
export const FULL_ROTATE_DEG = 360;
export const ROTATE_CORRELATION_INDEX = ROTATE_STEP_DEG / FULL_ROTATE_DEG;
export const CALCULATE_ZOOM_PX = (zoom: number) => (zoom - 1) * 1500;
export enum RotateStepEnum {
  top,
  left,
  bottom,
  right,
}

export const documentFiles = new Set([DocumentTypeEnum.PDF, DocumentTypeEnum.TIF]);
export const textFiles = new Set([DocumentTypeEnum.TXT, DocumentTypeEnum.CSV]);
