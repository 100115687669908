import React, { useCallback, useMemo, useState } from 'react';

import ModalWrapper from '@/components/wrappers/ModalWrapper';
import { useSnackbar } from '@/modules/core/hooks';

import { useGetCardsQuery } from '../../hooks/useGetCards.query';
import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

import { CardReplaceAcceptMessage } from './CardReplaceAcceptMessage';
import CardReplaceFormModal from './CardReplaceFormModal';

interface Props {
  visible: boolean;
  onSetVisible: () => void;
  cardholderName?: string;
  employeeId?: string;
}

export const CardReplaceModal: React.FC<Props> = ({
  visible,
  cardholderName,
  onSetVisible,
  employeeId,
}) => {
  const [mode, setMode] = useState(false);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { handleReset } = useCardReplaceModalStore();
  const { refetch } = useGetCardsQuery(employeeId);

  const modalTitleWithName = useMemo(() => `Request a Replacement Card for ${cardholderName}`, [cardholderName]);

  const setDoneMode = useCallback(() => {
    if (mode) setMode(false);
    else setMode(true);
  }, [mode]);

  const handleSetVisible = useCallback(() => {
    onSetVisible();
    setMode(false);
    handleReset();
  }, [onSetVisible, handleReset, setMode]);

  return (
    <>
      {!mode
        ? (
          <ModalWrapper testId="CardReplace" visible={visible} title={modalTitleWithName} onSetVisible={handleSetVisible}>
            <CardReplaceFormModal onCancel={onSetVisible} setDoneMode={setDoneMode} />
          </ModalWrapper>
        )
        : (
          <ModalWrapper visible={visible} onSetVisible={handleSetVisible}>
            <CardReplaceAcceptMessage
              onCancel={onSetVisible}
              cardholderName={cardholderName}
              setDoneMode={setDoneMode}
              onSnackbar={handleAddPermanentSnackbar as (value: unknown) => void}
              refreshCardList={refetch}
            />
          </ModalWrapper>
        )}
    </>
  );
};
