import React from 'react';
import {
  AddIcon,   Box, RawButton,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

const StyledButton = styled(RawButton)`
  color: ${({ theme }) => theme.colors.button};
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonSecondaryContainer};
  }
`;
interface Props {
  onClick: () => void;
}
const AddAnotherTier: React.FC<Props> = ({ onClick }) => (
  <Box
    pad="l"
    background="canvas"
    elevation="default"
    margin={{ bottom: 'l' }}
    border={{ size: 'small', color: 'border2' }}
    round="container1Round"
  >
    <StyledButton onClick={onClick}>
      <Box
        direction="row"
        align="center"
        data-testid="add-another-tier"
        pad={{ horizontal: 'xs', vertical: 'xxs' }}
      >
        <AddIcon size="16px" color="button" />
        <Text size="medium" margin={{ left: 'xxs' }}>
          Add Another Tier
        </Text>
      </Box>
    </StyledButton>

  </Box>
);

export default AddAnotherTier;
