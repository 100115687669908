import React, {
  useCallback,
  useEffect, useMemo,   useState, } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
  AbsenceFilesPlaceholderIcon,
  AppButton,
  AutocompleteDropdown,
  Box,   CalendarIcon,   capitalizeFirstLetter,
  ClearAll,
  CONTENT_ID_SELECTOR,
  DateRangeDropdown,
  FlexList,
  Hint,
  ListItemField,
  NoSearchItemsPlaceholder,
  PaginationBar,
  SearchInput,
  SearchPlaceholderIcon,
  SelectDropdown,
  Text, } from '@common-fe/common-fe';
import _ from 'lodash';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

import { ALL_OPTION, OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import routes from '@/common/routes';
import { ListItemBase, OptionKey } from '@/common/types';
import { Access } from '@/modules/core/components';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useCurrentOrganization,useHistory } from '@/modules/core/hooks';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import { useListPagination } from '@/modules/employer/hooks';
import { useAuthStore } from '@/modules/user/stores';
import globalTheme from '@/styles/theme';
import { formatDateRange } from '@/utils/modifiers';

import { EmployerStatus } from '../employer/employer.constants';
import { AllowedFileTypes } from '../employer/types';

import useFilesList from './hooks/useFilesList';
import useFilesUploadQuery, { addBacklightingFields,UNKNOWN_VALUE } from './queries/useFilesUploadQuery';
import useOrganizationQuery from './queries/useOrganization.query';
import {
  FILE_RECORD_TYPES_OPTIONS, FILE_STATUSE_OPTIONS, FileDtoWithMediaSource,
  FileStatus, } from './FileManager.constants';
import { useFileDownloadQuery } from './queries';

const AVAILABLE_CONTACT_TYPES = '6,5,3';
const HEADER_HEIGHT = 134;
const HEADER_SPACING = 16;

const StyledAutocompleteDropdown = styled(AutocompleteDropdown)`
  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: ${({ theme }) => theme.spacings.spacing24};
  }
`;

const FixedWrapper = styled(Box) <{ isScrolled?: boolean }>`
  position: fixed;
  z-index: 1001;
`;

const TableWithBacklighting = styled(Box)`
  .backlighting_ {
    &uploading {
      border: 2px solid ${({ theme }) => theme.colors.border};
    }
    &uploaded, &approved, &validated {
      border: 2px solid ${({ theme }) => theme.colors.accentActive};
      box-shadow: 0px 6px 16px rgba(23, 106, 246, 0.5);
      ${({ theme }) => theme.shadows.primary};
    }
    &error {
      border: 2px solid ${({ theme }) => theme.colors.danger};
      ${({ theme }) => theme.shadows.error};
    }
    &processing {
      border: 2px solid ${({ theme }) => theme.colors.warning};
      ${({ theme }) => theme.shadows.warning};
    }
    &completed {
      border: 2px solid ${({ theme }) => theme.colors.success};
      ${({ theme }) => theme.shadows.success};
    }
  }
  .warning_completed {
    border: 2px solid ${({ theme }) => theme.colors.warning};
    ${({ theme }) => theme.shadows.warning};
  }
`;

const HEADERS: ListItemBase[] = [
  {
    key: 'recordType',
    title: 'Record type / Name',
    flex: 1.45,
  },
  {
    key: 'partner',
    title: 'Partner',
    flex: 0.85,
  },
  {
    key: 'employer',
    title: 'Employer',
    flex: 0.9,
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    flex: 0.85,
  },
  {
    key: 'dateReceivedProcessed',
    title: 'Date Received / Processed',
    flex: 1.2,
  },
  {
    key: 'fileWarning',
    title: '',
    flex: 0.45,
  },
  {
    key: 'fileStatus',
    title: 'Status',
    flex: 0.8,
    style: {
      marginRight: '20px',
      marginLeft: '-20px',
      flexDirection: 'row-reverse',
    },
  },
];

const stateAllToggling = (statuses: OptionKey[]) => {
  const isStatusAllSelected = statuses[statuses.length - 1] === ALL_OPTION.value;

  if (statuses.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }
  
  const isStatusAllChangedToAnother = statuses.length === 2 && statuses[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return statuses.filter((status) => status !== ALL_OPTION.value);
  }

  return statuses;
};

const FilesList = () => {
  const history = useHistory();
  const { user } = useAuthStore();
  const { observingOrganization: { path }, observingOrganization } = useCurrentOrganization();
  const [uploadedById, setUploadedById] = useState<OptionKey>('');
  const [cleaningInitiator, runSearchTextCleaningForAutocomplete] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [recordTypes, setRecordType] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [statuses, setStatuses] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([]);
  const [hiddenRowsIds, setHiddenRowsIds] = useState<string[]>([]);

  
  const {
    page,
    perPage,
    currentSearch,
    searchString,
    setSearchString,
    setPerPage,
    setPage,
  } = useListPagination();

  const isTerminatedEmployer = useMemo(
    () => (observingOrganization.type === OrganizationTypes.company || observingOrganization.type === OrganizationTypes.employer)
     && observingOrganization.status === EmployerStatus.Terminated,
    [observingOrganization],
  );

  const isSystemLevel = useMemo(
    () => (observingOrganization.type === OrganizationTypes.system),
    [observingOrganization],
  );

  const handlePickOption = (id: string) => history.push(`${routes.FILE_DETAILS}/${id}`);
  const hasAccessToProcessFile = useHasAccess([{
    permission: Permissions.PROCESS_FILE,
  }]);
  const [uploadedFiles, setUploadedFiles] = useState<FileDtoWithMediaSource[]>([]);
  const [newUploadedFile, setNewUploadedFile] = useState<FileDtoWithMediaSource>();
  const { uploadFiles } = useFilesUploadQuery();
  const initUploadingFiles = useCallback(async (uploadingFiles: FileDtoWithMediaSource[]) => {
    setUploadedFiles([...uploadingFiles, ...uploadedFiles]);

    const newUploadedFilesOnServer = await uploadFiles(uploadingFiles) as FileDtoWithMediaSource[];

    setNewUploadedFile(newUploadedFilesOnServer[0]);
  }, [uploadFiles, uploadedFiles]);
  useEffect(() => {
    if (newUploadedFile) {
      setUploadedFiles([
        ...uploadedFiles.map((file) => {
          if (file.temporalId === newUploadedFile.temporalId) return newUploadedFile;

          return file;
        }),
      ]);
      setNewUploadedFile(undefined);
    }
  }, [newUploadedFile, uploadedFiles]);
  const reuploadingFile = useCallback(async (uploadingFile: FileDtoWithMediaSource) => {
    setUploadedFiles(
      uploadedFiles
        .map(
          (file) => {
            if (file.temporalId === uploadingFile.temporalId) {
              return {
                ...uploadingFile,
                hasReuploading: false,
                type: FileStatus.Uploading,
                status: FileStatus.Uploading,
                ...addBacklightingFields(FileStatus.Uploading),
              };
            }

            return file;
          },
        ),
    );

    const reuploadedFiles = await uploadFiles([uploadingFile]) as FileDtoWithMediaSource[];
    const reuploadedFile = reuploadedFiles[0];

    setUploadedFiles(
      uploadedFiles
        .map(
          (file) => {
            if (file.temporalId === reuploadedFile.temporalId) {
              return reuploadedFile;
            }

            return file;
          },
        ),
    );
  }, [uploadFiles, setUploadedFiles, uploadedFiles]);
  const { getInputProps, open } = useDropzone({
    accept: 'text/csv, text/plain, application/vnd.ms-excel' as unknown as Accept,
    multiple: false,
    onDrop: (acceptedFiles) => {
      initUploadingFiles(acceptedFiles.map((file) => {
        const temporalId = `temporalId_${uniqueId()}`;

        return {
          file,
          id: temporalId,
          temporalId,
          name: file.name,
          uploadedBy: `${user?.firstName} ${user?.lastName}`.trim(),
          partnerNames: [UNKNOWN_VALUE],
          employerNames: [UNKNOWN_VALUE],
          createdAt: UNKNOWN_VALUE,
          processedAt: UNKNOWN_VALUE,
          status: FileStatus.Uploading,
          ...addBacklightingFields(FileStatus.Uploading),
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any[]);
    },
  });
  const { observingOrganizationData } = useOrganizationOverridableProperties();
  const allowedFileTypes = observingOrganizationData?.allowedFileTypes;
  const statusValues = useMemo(() => stateAllToggling(statuses), [statuses]);
  const recordTypesValues = useMemo(() => stateAllToggling(recordTypes), [recordTypes]);
  const hasStatus = useMemo(
    () => statusValues.length > 0
      && !statusValues.some((status) => status === ALL_OPTION.value),
    [statusValues],
  );
  const hasRecordTypes = useMemo(
    () => recordTypesValues.length > 0
      && !recordTypesValues.some((status) => status === ALL_OPTION.value),
    [recordTypesValues],
  );
  const hasOnlyOneFileTypeAllowed = useMemo(() => allowedFileTypes?.length === 1,
    [allowedFileTypes]);
  const hasDateRange = useMemo(() => Boolean(dateRange[0] && dateRange[1]), [dateRange]);
  const areFiltersUsed = useMemo(() => Boolean(
    currentSearch || uploadedById || hasRecordTypes || hasStatus || hasDateRange,
  ), [currentSearch, uploadedById, hasRecordTypes, hasStatus, hasDateRange]);
  const hasClearAll = useMemo(
    () => Boolean(currentSearch || uploadedById || hasRecordTypes || hasStatus || hasDateRange),
    [currentSearch, uploadedById, hasStatus, hasRecordTypes, hasDateRange],
  );
  const clearAll = () => {
    setSearchString('');
    setUploadedById('');
    runSearchTextCleaningForAutocomplete(!cleaningInitiator);
    setRecordType([ALL_OPTION.value]);
    setStatuses([ALL_OPTION.value]);
    setDateRange([]);
  };
  const allowedFileRecordTypesOptions = useMemo(
    () => {
      
      const allowedFileTypesUpper = allowedFileTypes?.map((type) => type.toUpperCase()) || [];
      if(isSystemLevel) {
        return FILE_RECORD_TYPES_OPTIONS;
      }
      const filteredFileTypes =  FILE_RECORD_TYPES_OPTIONS.filter(
        (fileType) => allowedFileTypesUpper.includes(fileType.title.toUpperCase()),
      );
      const allOption = filteredFileTypes.length > 1 ? [ALL_OPTION] : [];
      return [...allOption, ...filteredFileTypes];
    }, [allowedFileTypes, isSystemLevel],
  );
  const currentAllowedFileTypes = useMemo(() => {
    const filtered = allowedFileRecordTypesOptions
      .filter((item) => item.key !== ALL_OPTION.key)
      .map((item) => _.toUpper(item.key)) as AllowedFileTypes[];

    return filtered;
    
  }, [allowedFileRecordTypesOptions] );
  
  const [sendingToProcessFileId, setSendingToProcessFileId] = useState('');
  const { rows, total, isLoading, updatedFiles, onFileProcess } = useFilesList({
    onRemoveRow: (id: string) => setHiddenRowsIds([...hiddenRowsIds, id]),
    uploadedFiles: uploadedFiles
      .filter(
        (uploadedFile) => !hiddenRowsIds
          .some((id) => id === uploadedFile.temporalId),
      ),
    page: page - 1,
    perPage,
    ...(currentSearch ? { searchString: currentSearch } : {}),
    ...(hasStatus ? { statuses: statusValues } : {}),
    ...(hasRecordTypes ? { types: recordTypesValues } : {}),
    ...(hasDateRange
      ? { dateRange: formatDateRange(dateRange) }
      : {}),
    ...(uploadedById ? { uploadedById } : {}),
    reuploadingFile,
    allowedFileTypes: currentAllowedFileTypes,
    sendingToProcessFileId,
  });
  const handleProcess = useCallback(async (id: string) => {
    setSendingToProcessFileId(id);
    await onFileProcess({ id } as FileDtoWithMediaSource);
    setSendingToProcessFileId('');
  }, [onFileProcess]);
  const hasType = useCallback((fields: ListItemField[], id: string) => {
    const type = updatedFiles?.find((item) => item?.id === id)?.type;

    return Boolean(type && type.toLowerCase() !== 'unknown');
  }, [updatedFiles]);
  const hasProcessButton = useCallback(
    (fields: ListItemField[], id: string) => updatedFiles
      ?.find((item) => item?.id === id)?.status === FileStatus.Uploaded,
    [updatedFiles],
  );
  const totalFiles = useMemo(
    () => uploadedFiles
      .filter((file) => file.temporalId !== file.id).length + total - hiddenRowsIds.length,
    [uploadedFiles, total, hiddenRowsIds],
  );

  useEffect(() => {
    if (allowedFileTypes && hasOnlyOneFileTypeAllowed) {
      setRecordType([capitalizeFirstLetter(allowedFileTypes[0])]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && uploadedFiles.length) {
      setUploadedFiles([]);
      setHiddenRowsIds([]);
    }
  }, [isLoading, uploadedFiles, setUploadedFiles]);

  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);
    const updateScrolling = () => setIsScrolled(!!appContainer
      && appContainer.scrollTop > (HEADER_SPACING / 2));

    appContainer?.addEventListener('scroll', updateScrolling);

    return () => {
      setIsScrolled(false);
      appContainer?.removeEventListener('scroll', updateScrolling);
    };
   
  }, []);
  const handleDownloadFileById = useFileDownloadQuery();
  const [fileSearchText, setFileSearchText] = useState('');
  const { data, isLoading: isLoadingSearch } = useOrganizationQuery(fileSearchText, {
    observingOrganizationPath: path,
    type: AVAILABLE_CONTACT_TYPES,
  });
  const handlePickFieldSearchOption = useCallback((id: OptionKey) => {
    setPage(1);
    setUploadedById(id);
  }, [setPage]);
  const handlePickRecord = useCallback((type: OptionKey[]) => {
    setRecordType(type);
    setPage(1);
  }, [setPage]);
  
 
  return (
    <>
      <FixedWrapper background="canvas" width="100%">
        <Topbar hideHeader={isScrolled} />
        <Box
          direction="row"
          justify="between"
          alignSelf="center"
          wrap
          width={globalTheme.defaultContentWidth}
          margin={{ top: 'medium' }}
        >
          <Box margin={{ bottom: 'spacing16' }}>
            <Text
              size="3xl"
              weight="bold"
              color="textBody"
              style={{ lineHeight: '44px' }}
            >File Manager
            </Text>
          </Box>
          <Access
            accessRequirements={[
              {
                permission: Permissions.PROCESS_FILE,
              },
            ]}
          >
            <Box
              flex
              direction="row"
              justify="end"
              align="center"
              height="40px"
              margin={{ bottom: isScrolled ? 'spacing4' : 'spacing16' }}
            >
              <input {...getInputProps()} />
              {/* <AppButton
                testId="FileManager-uploadFile"
                onClick={open}
                width="140px"
              >
                Upload a File
              </AppButton> */}
              <Hint
                hintAlign="left"
                hintWidth={220}
                hideHint={!isTerminatedEmployer}
                hintElement={(
                  <AppButton
                    testId="FileManager-uploadFile"
                    onClick={open}
                    width="140px"
                    disabled={isTerminatedEmployer}
                  >
                    Upload a File
                  </AppButton>
                )}
                marginContent="m"
              >
                <Box
                  direction="row"
                  justify="center"
                  align="center"
                >
                  <Text textAlign="center">
                    Terminated organizations cannot upload files.
                  </Text>
                </Box>
              </Hint>
            </Box>
          </Access>
        </Box>
      </FixedWrapper>

      <Box
        flex
        direction="column"
        margin={{ top: `${HEADER_HEIGHT + HEADER_SPACING}px` }}
        width={globalTheme.defaultContentWidth}
      >
        <ClearAll onClick={clearAll} isVisible={hasClearAll && !isScrolled} />

        <TableWithBacklighting
          background={{ color: 'module' }}
          round="container1Round"
        >
          <Box align="center" pad={{ bottom: 'spacing16' }}>
            <Box
              width="100%"
              direction="row"
              justify="between"
              align="center"
              pad={{ horizontal: 'spacing24', top: 'spacing24' }}
            >
              <Text
                weight="bold"
                color="textBody"
                style={{ whiteSpace: 'nowrap' }}
              >Files: {totalFiles || 0}
              </Text>
              <Box direction="row" justify="end">
                <Box margin={{ left: 'spacing12' }} width={{ min: '220px' }}>
                  <SearchInput
                    disabled={!totalFiles && !currentSearch}
                    className="search-input"
                    hasSearchingHistory
                    value={searchString}
                    placeholder="File, Partner, Employer"
                    onChange={setSearchString}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: 'control', max: '180px' }}>
                  <StyledAutocompleteDropdown
                    disabled={isLoading || (!totalFiles && !uploadedById)}
                    name="uploadedBy"
                    placeholder="Uploaded By"
                    completeValue={uploadedById as string}
                    onSearch={setFileSearchText}
                    options={data}
                    isLoading={isLoadingSearch}
                    onChange={handlePickFieldSearchOption}
                    value={uploadedById}
                    cleaningInitiator={cleaningInitiator}
                    // isSearchMode
                    className="ellipsis"
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: '170px', max: '170px' }}>
                  <SelectDropdown
                    disabled={isLoading
                      || (!totalFiles && !hasRecordTypes)
                      || (!totalFiles && hasOnlyOneFileTypeAllowed)}
                    id="type"
                    allMode
                    activeTitle
                    ellipsisMode
                    name="Record type"
                    prefix="Record type"
                    options={allowedFileRecordTypesOptions}
                    singleMode={allowedFileRecordTypesOptions.length === 1}
                    values={recordTypesValues}
                    onChangeValues={handlePickRecord}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: '150px', max: '150px' }}>
                  <SelectDropdown
                    disabled={isLoading || (!totalFiles && !hasStatus)}
                    id="status"
                    allMode
                    activeTitle
                    ellipsisMode
                    name="Activity status"
                    prefix="Status"
                    options={FILE_STATUSE_OPTIONS}
                    values={statusValues}
                    onChangeValues={(status: OptionKey[]) => {
                      setStatuses(status);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
                  <DateRangeDropdown
                    disabled={isLoading || (!totalFiles && !hasDateRange)}
                    prefix="Received:"
                    className="period-picker"
                    onChange={(range) => {
                      setDateRange(range);
                      setPage(1);
                    }}
                    ellipsisMode
                    startDate={dateRange[0]}
                    endDate={dateRange[1]}
                    icon={<CalendarIcon color="iconPrimary" />}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <FlexList
            headers={HEADERS}
            rows={rows}
            total={totalFiles}
            pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
            moreCount={perPage}
            loading={isLoading}
            placeholder={(
              <NoSearchItemsPlaceholder
                placeholderTitle={areFiltersUsed ? 'No results have been found' : 'There are no files yet'}
                node={areFiltersUsed ? (
                  <SearchPlaceholderIcon size="xxlarge" color="iconSecondary" />
                ) : (
                  <AbsenceFilesPlaceholderIcon size="xxlarge" color="iconSecondary" />
                )}
                description={areFiltersUsed ? 'Try to adjust your search or filter to find what you\'re looking for.' : ''}
              />
            )}
            options={[
              ...hasAccessToProcessFile ? [{
                name: 'Process',
                onClick: handleProcess,
                isAvailable: hasProcessButton,
              },] : [],
              {
                name: 'File summary',
                onClick: handlePickOption,
                isAvailable: hasType,
              },
              // EL-9332
              // {
              //   name: 'Preview',
              // },
              {
                onClick: handleDownloadFileById,
                name: 'Download',
                isAvailable: hasType,
              },
            ]}
            footer={(
              <PaginationBar
                page={page}
                total={totalFiles}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            )}
          />
        </TableWithBacklighting>
      </Box>
    </>
  );
};

export default FilesList;
