import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props {
  types: string[];
  activeSection: string | null;
  selectSection: (type: string) => void;
}

const StyledText = styled(Text)`
  line-height: 22px;
`;
const StyledTypeBox = styled(Box)`
  box-shadow: none;
`;

export const ReportTypeTopbar = React.forwardRef<HTMLDivElement, Props>(({
  types,
  activeSection,
  selectSection,
}, ref) => (
  <Box
    ref={ref}
    round="checkboxRound"
    border={{
      size: 'small',
      color: 'border1',
    }}
    gap="spacing16"
    height="32px"
    align="center"
    background="module"
    pad={{ horizontal: 'spacing12' }}
    wrap
    direction="row"
    margin={{ bottom: 'spacing4' }}
  >
    {
      types.map((type) => (
        <StyledTypeBox key={type} onClick={() => selectSection(type)}>
          <StyledText
            weight={type === activeSection ? 'bold' : 'normal'}
            color="textBody"
            style={{ lineHeight: '20px' }}
          >
            { type }
          </StyledText>
        </StyledTypeBox>
      ))
    }
  </Box>
));
