import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useBasicPropertiesSetter, { BasicPropertiesPayload } from '../BasicProperties/hooks/useBasicPropertiesSetter';
import useComplexConfigurationSetter, { ComplexConfigurationPayload } from '../ComplexConfiguration/hooks/useComplexConfigurationSetter';
import useCoverageLevelsSetter, { CoverageLevelPayload } from '../CoverageLevels/hooks/useCoverageLevelsSetter';

export type PlanPayload = BasicPropertiesPayload
& CoverageLevelPayload & ComplexConfigurationPayload;

export default () => {
  const {
    handleSetState: handleSetBasicProperties,
    handleReset: handleResetBasicProperties,
    handleSetErrors: handleSetBasicPropertiesErorrs,
    handleSetErrorFields: handleSetBasicPropertiesErrorFields,
    handleSetSourceState: handleSetSourceBasicProperties,
  } = useBasicPropertiesSetter();

  const {
    handleSetState: handleSetCoverageLevels,
    handleReset: handleResetCoverageLevels,
    handleSetErrors: handleSetCoverageLevelsErorrs,
    // handleSetErrorFields: handleSetCoverageLevelsErrorFields,
    handleSetSourceState: handleSetSourceCoverageLevels,
  } = useCoverageLevelsSetter();

  const {
    handleSetState: handleSetComplexConfiguration,
    handleReset: handleResetComplexConfiguration,
    handleSetErrors: handleSetComplexConfigurationErorrs,
    handleSetErrorFields: handleSetComplexConfigurationErrorFields,
    handleSetSourceState: handleSetSourceComplexConfiguration,
  } = useComplexConfigurationSetter();

  const handleReset = useCallback(() => {
    handleResetBasicProperties();
    handleResetCoverageLevels();
    handleResetComplexConfiguration();
  }, [handleResetBasicProperties,
    handleResetCoverageLevels,
    handleResetComplexConfiguration,
  ]);

  const handleSetSourceState = useCallback((values: Partial<PlanPayload>) => {
    handleSetSourceBasicProperties(values);
    handleSetSourceCoverageLevels(values);
    handleSetSourceComplexConfiguration(values);
  }, [handleSetSourceBasicProperties,
    handleSetSourceCoverageLevels,
    handleSetSourceComplexConfiguration,
  ]);

  const handleSetState = useCallback((values: Partial<PlanPayload>) => {
    handleSetBasicProperties(values);
    handleSetCoverageLevels(values);
    handleSetComplexConfiguration(values);
  }, [handleSetBasicProperties,
    handleSetCoverageLevels,
    handleSetComplexConfiguration,
  ]);

  const handleSetErrors = useCallback((values: Partial<PlanPayload>) => {
    handleSetBasicPropertiesErorrs(values);
    handleSetCoverageLevelsErorrs(values);
    handleSetComplexConfigurationErorrs(values);
  }, [handleSetBasicPropertiesErorrs,
    handleSetCoverageLevelsErorrs,
    handleSetComplexConfigurationErorrs,
  ]);
  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<PlanPayload>>) => {
    handleSetBasicPropertiesErrorFields(values);
    handleSetComplexConfigurationErrorFields(values);
  }, [
    handleSetBasicPropertiesErrorFields, handleSetComplexConfigurationErrorFields,
  ]);
  return {
    handleSetErrorFields,
    handleSetErrors,
    handleReset,
    handleSetState,
    handleSetSourceState,
  };
};
