import { useCallback } from 'react';
import { AccountStatus } from '@common-fe/common-fe';
import * as yup from 'yup';

import { LockboxStatus, LockboxType, LockboxValidationError } from '@/modules/employer/components/Lockbox/lockbox.types';
import { EditableLockbox } from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

const inboundStatusSchema = yup.object().shape({
  lockboxStatus: yup.mixed<LockboxStatus>().required(),
  reason: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val !== LockboxStatus.APPROVED,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
});

const outboundStatusSchema = yup.object().shape({
  lockboxStatus: yup.mixed<LockboxStatus>().required(),
  reason: yup.string().required(),
});

const inboundFieldsSchema = yup.object().shape({
  lockboxType: yup.mixed<LockboxType>().required(LockboxValidationError.TYPE_REQUIRED),
  amount: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED || val === LockboxStatus.DECLINED,
    then: yup.string().required(LockboxValidationError.AMOUNT_REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
  employeeId: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED,
    then: yup.string().required(LockboxValidationError.EMPLOYEE_REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
  employeeAccountStatus: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED,
    then: yup.string().required(LockboxValidationError.HSA_ACCOUNT_REQUIRED)
      .oneOf([AccountStatus.OPEN, AccountStatus.PENDING], LockboxValidationError.HSA_ACCOUNT_CLOSED),
    otherwise: yup.string().notRequired(),
  }),
  notes: yup.string().max(200).notRequired(),
  vendorId: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.DECLINED,
    then: yup.string().required(LockboxValidationError.VENDOR_REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
});

const outboundFieldsSchema = yup.object().shape({
  lockboxType: yup.mixed<LockboxType>().required(LockboxValidationError.TYPE_REQUIRED),
  amount: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED || val === LockboxStatus.DECLINED,
    then: yup.string().required(LockboxValidationError.AMOUNT_REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
  employeeId: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED,
    then: yup.string().required(LockboxValidationError.EMPLOYEE_REQUIRED),
    otherwise: yup.string().notRequired(),
  }),
  employeeAccountStatus: yup.string().when('lockboxStatus', {
    is: (val?: LockboxStatus) => val === LockboxStatus.APPROVED,
    then: yup.string().required(LockboxValidationError.HSA_ACCOUNT_REQUIRED)
      .oneOf([AccountStatus.OPEN, AccountStatus.PENDING], LockboxValidationError.HSA_ACCOUNT_CLOSED),
    otherwise: yup.string().notRequired(),
  }),
  notes: yup.string().max(200).notRequired(),
});

const useValidateLockbox = () => {
  // @ts-ignore
  const validateLockbox = useCallback((lockboxData: EditableLockbox, schema: yup.ObjectSchema) => {
    try {
      schema.validateSync(lockboxData, { abortEarly: false });
      return [] as LockboxValidationError[];
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return error.errors as LockboxValidationError[];
      }
      return [] as LockboxValidationError[];
    }
  }, []);

  const validateLockboxStatus = useCallback((editableLockbox?: EditableLockbox): LockboxValidationError[] => {
    switch (editableLockbox?.lockboxType) {
    case LockboxType.INBOUND_TOA:
      return validateLockbox(editableLockbox, inboundStatusSchema);
    case LockboxType.OUTBOUND_TOA:
      return validateLockbox(editableLockbox, outboundStatusSchema);
    default:
      return [LockboxValidationError.TYPE_REQUIRED];
    }
  }, [validateLockbox]);

  const validateLockboxFields = useCallback((editableLockbox?: EditableLockbox): LockboxValidationError[] => {
    switch (editableLockbox?.lockboxType) {
    case LockboxType.INBOUND_TOA:
      return validateLockbox(editableLockbox, inboundFieldsSchema);
    case LockboxType.OUTBOUND_TOA:
      return validateLockbox(editableLockbox, outboundFieldsSchema);
    default:
      return [LockboxValidationError.TYPE_REQUIRED];
    }
  }, [validateLockbox]);

  return {
    validateLockboxStatus,
    validateLockboxFields,
  };
};

export default useValidateLockbox;
