import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

export enum Actions {
  update = 'UPDATE',
  create = 'CREATE',
}

export interface ActivityLog {
  id: string;
  changedAt: string;
  changedByName: string;
  changesCount: number;
  entityName: string;
  actorType: string;
  updateSource: string;
  actionType: string;
  planName: string;
}

interface ActivityLogPayload {
  id?: number;
  changed_at?: string;
  changed_by_name?: string;
  changes_count?: number;
  entity_name?: string;
  actor_type?: string;
  update_source?: string;
  action_type?: string;
  plan_name?: string;
}

const formatData = (data: ActivityLogPayload[]) => data.map((item) => ({
  id: toString(item?.id || ''),
  changedAt: item?.changed_at || '',
  changedByName: item?.changed_by_name || '',
  changesCount: item?.changes_count || '',
  entityName: item?.entity_name || '',
  actorType: item?.actor_type || '',
  updateSource: item?.update_source || '',
  actionType: item?.action_type || '',
  planName: item?.plan_name || '',
}));

interface Props {
  employeeId?: string;
  lockboxId?: string;
  organizationId?: string;
  planId?: string;
  domainType?: string;
  search?: string;
  actions?: string[];
  fields?: string[];
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  size?: number;
  sort?: string;
  claimId?: string;
}
const useGetActivityLogsQuery = (
  {
    employeeId, lockboxId, planId, search, actions,
    fields, dateFrom, dateTo,
    page, size, sort, organizationId,
    domainType, claimId,
  }: Props,
) => {
  const { data, isLoading, isFetching, isSuccess } = useQuery(
    [PATHS.ACTIVITY_LOG, employeeId, search, actions, claimId,
      fields, dateFrom, dateTo, page, size, sort, organizationId],
    () => api.get<{
      content: ActivityLogPayload[];
      totalPages: number;
      totalElements: number;
    }>(
      PATHS.ACTIVITY_LOG,
      {
        params: {
          ...claimId ? { claim_id: claimId } : {},
          ...employeeId ? { employee_id: employeeId } : {},
          ...lockboxId ? { lockbox_id: lockboxId } : {},
          ...organizationId ? { organization_id: organizationId } : {},
          ...planId ? { plan_id: planId } : {},
          ...search ? { search_term: search } : {},
          ...actions?.length ? {
            action_types: actions.toString(),
          } : {},
          ...fields?.length ? {
            field_names: fields.toString(),
          } : {},
          ...dateFrom && dateTo ? {
            date_from: dateFrom,
            date_to: dateTo,
          } : {},
          ...page ? { page: page - 1 } : {},
          ...size ? { size } : {},
          ...sort ? { sort } : {},
          ...domainType ? { domain_types: domainType } : {},
        },
      },
    ),
  );

  const logs = useMemo(() => formatData(data?.data?.content || []) as ActivityLog[], [data?.data?.content]);
  const logsIds = useMemo(() => logs?.map((log) => log?.id), [logs]);

  return {
    logs,
    logsIds,
    total: data?.data?.totalElements || 0,
    isLoading,
    isFetching,
    isSuccess
  };
};

export default useGetActivityLogsQuery;
