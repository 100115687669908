import { Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const RowTitle = styled(Text)`
  ${({ theme }) => theme.fonts.text14Bold};
  margin-bottom: ${({ theme }) => theme.spacings.spacing4};
`;

interface RowDescriptionProp {
  isError?: boolean;
}

export const RowDescription = styled(Text)<RowDescriptionProp>`
  ${({ theme }) => theme.fonts.text12Medium};
  color: ${({ theme, isError }) => (isError ? theme.colors.danger : theme.colors.textSecondary)};
`;