import { useMemo } from 'react';
import React from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { NO_KEY, YES_KEY } from '@/common/constants';
import { useFieldsWithDefaultValues } from '@/utils';

import { useOrphanConfigurationStore } from '../stores';

import useEmployerOrganizations from './useEmployerOrganizations';





const useOrphanConfigurationFields = (disabled: boolean, ) => {
  const defaultValues = useOrphanConfigurationStore((state) =>state.sourceState); 
  const  {data: organizations}  = useEmployerOrganizations();
  const fields = useMemo<Field[]>(() => [
    {
      name: 'isEmployerOrphan',
      type: FieldTypes.Radio,
      label: 'Is this employer - orphan?',
      options: [
        {
          label: 'Yes',
          value: YES_KEY,
        },
        {
          label: 'No',
          value: NO_KEY,
        },
      ],
      disabled: false,
      subFields: [
        {
          name: 'startOrphanProcessDays',
          type: FieldTypes.Number,
          label: (
            <Box flex={{ grow: 1 }}>
              <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                Start orphan process (days)
              </Inscription>
              <Box>
                <Inscription weight={500} color="textSecondary" size="small" lineHeight='18px'>
                  Enter number of days after employment termination for orphan process to begin
                </Inscription>
              </Box>
            </Box>
          ),
          showRequireIcon: true,
          placeholder: 'Change date',
          parentValue: NO_KEY,
          validator: VALIDATORS.REQUIRED_POSITIVE_NUMBER_ONLY,
        },
        {
          name: 'orphanEmployer',
          type: FieldTypes.AutocompleteDropdown,
          label: 'Specify orphan employer',
          
          showRequireIcon: true,
          placeholder: 'Select employer',
          parentValue: NO_KEY,
          options: organizations,
          validator: VALIDATORS.REQUIRED_STRING,
        },
      ],
    },
   
  ], [organizations]);
  const formattedFields = useFieldsWithDefaultValues(fields, defaultValues);

  return formattedFields;
};

export default useOrphanConfigurationFields;
