import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT, OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { Pagination } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';

import { CopayGroupPayload, CopaysListPaginationParams } from '../CopayAdjudication.constant';

export const QUERY_KEY = 'GET_COPAY_GROUPS';

interface CopayResponsePayload {
  id: number;
  category_type: string;
  amount: number;
}
export interface CopayGroupResponsePayload {
  id: number;
  name: string;
  copays: CopayResponsePayload[];
  organization_type: OrganizationTypes;
  valid_from: string | null;
  valid_to: string | null;
}

const DEFAULT_SORT_KEY = 'name';

const useCopayGroupsQuery = (params?: CopaysListPaginationParams, isBasedOn?: boolean) => {
  const { observingOrganization: { id, path, type } } = useCurrentOrganization();
  const isEmployer = useMemo(
    () => type === OrganizationTypes.employer || type === OrganizationTypes.company,
    [type],
  );
  const { list: serviceCategories } = useServiceCategoriesQuery();
  const {
    isLoading,
    data,
  } = useQuery(
    [QUERY_KEY, id, params?.page, params?.perPage, params?.sortBy, params?.searchString, params?.statuses?.join(',')],
    () => api.get<Pagination<CopayGroupResponsePayload>>(PATHS.COPAY_GROUPS, {
      params: {
        page: params?.page,
        size: params?.perPage || 1000, // TEMPORAL
        // search_value: searchString,
        organization_id: isEmployer && !isBasedOn ? id : undefined,
        organization_path: isEmployer && !isBasedOn ? undefined : path,
        name: params?.searchString,
        sort: params?.sortBy ? `${DEFAULT_SORT_KEY},${params?.sortBy}` : undefined,
        copay_status: params?.statuses && params.statuses.length === 1 ? params?.statuses.join(',') : undefined, // active, expired
      },
    }),
    {
      enabled: Boolean(id),
    },
  );

  const formattedData = useMemo(
    () => {
      const list = data?.data?.content || [];
      return list.map((item) => ({
        id: `${item.id}`,
        name: item.name,
        copays: item?.copays?.map((copay) => ({
          id: `${copay.id}`,
          category: copay.category_type,
          amount: costFormater(copay.amount),
        })) || [],
        organizationType: item.organization_type,
        isExpired: dayjs(item.valid_to || '', DEFAULT_DATE_FORMAT) < dayjs(),
        validFrom: item.valid_from,
        validTo: item.valid_to,
      })) as CopayGroupPayload[];
    }, [data],
  );
  const options = useMemo(() => formattedData.map((option) => ({
    key: `${option.id}`,
    value: option.name,
    isExpired: option.isExpired,
    organizationType: option.organizationType,
    copays: option?.copays?.map((copay) => {
      const foundCategory = serviceCategories.find((item) => item.key === copay.category);
      return ({
        key: copay.id,
        value: `${foundCategory?.value || copay.category} ${copay.amount}`,
      });
    }) || [],
    validFrom: option.validFrom,
    validTo: option.validTo,
  })), [formattedData, serviceCategories]);
  const total = useMemo(() => data?.data.totalElements || 0, [data]);
  return {
    data: formattedData,
    isLoading,
    options,
    total,
  };
};

export default useCopayGroupsQuery;
