import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const useEmployerLockUnlockQuery = (employerId: string, isLockMode: boolean) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    () => isLockMode
      ? api.post(PATHS.LOCK_ORGANIZATION(employerId)) : api.patch(PATHS.UNLOCK_ORGANIZATION(employerId)));

  return {
    mutateAsync,
    isLoading,
    isSuccess,
  };
};
