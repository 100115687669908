 
import React, { useCallback, useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { NON_ASSIGNABLE_VALUE, REQUIRED_TEXT } from '@/common/constants';
import { daysOfWeek,FrequencyOptions } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { BankMethod } from '@/modules/employer/hooks/useBankMethods.query';
import { EmployerDto } from '@/modules/employer/types';

interface AccountUsageValues {
  administratorFeesBank?: string;
  payrollContributionBank?: string;
  fundingReplenishmentBank?: string;
  replenishmentFrequencyType?:
  | FrequencyOptions.DAILY
  | FrequencyOptions.WEEKLY
  | FrequencyOptions.MONTHLY;
}

const CUSTOM_DAY_FIELD_HINT = 'If a month has fewer days than the entered number, replenishment will be processed on the last day of this month.';
const CUSTOM_DAY_ERROR_TEXT = 'This number does not match the range of available numbers (1-31)';

const getSubFields = (
  name: string,
  viewMode: boolean,
  methods: BankMethod[],
  method?: string,
): Field[] => [{
  name: `${name}Method`,
  type: FieldTypes.Radio,
  disabled: viewMode,
  parentValueFn: (val) => !!val,
  label: 'Method',
  validator: yup.string().test({
    test: (val) => !!(val || method),
    message: REQUIRED_TEXT,
  }),
  options: methods.map((option) => ({
    label: option.description,
    value: option.name,
  })),
  value: method,
}];
const placeholder = 'Select bank account';
const weeklyOptions = Object.keys(daysOfWeek);

const useAccountUsageFields = (
  banks: BankInfo[],
  methods: BankMethod[],
  formattedData: EmployerDto,
  currentValues?: AccountUsageValues,
  activateMode?: boolean,
  isLoading?: boolean,
): Field[] => {
  const { viewMode } = useSetupEmployerMode();

  const getDefaultValue = useCallback((value?: string) => {
    const bankId = banks.find((bank) => bank.id === value)?.id;

    if (bankId) return bankId;

    if (formattedData?.normalProperties?.externalAccountEnable) return NON_ASSIGNABLE_VALUE;

    return '';
  }, [banks, formattedData]);

  const getDayOfWeek = useCallback((numberOfDay?: number) => (numberOfDay ? weeklyOptions[numberOfDay - 1] : ''), []);
  const replenishmentDayNumber = formattedData?.overridableProperties?.replenishmentDayNumber;

  const externalAdministratorFees = useMemo(
    () => currentValues?.administratorFeesBank,
    [currentValues],
  );

  const externalPayrollContributions = useMemo(
    () => currentValues?.payrollContributionBank,
    [currentValues],
  );

  const externalFundingReplenishment = useMemo(
    () => currentValues?.fundingReplenishmentBank,
    [currentValues],
  );

  const frequencyType = useMemo(
    () => currentValues?.replenishmentFrequencyType,
    [currentValues],
  );

  const frequencyTypeFromResponse = useMemo(
    () => formattedData?.overridableProperties?.replenishmentFrequencyType,
    [formattedData],
  );

  const renderFrequencyDayField = useMemo((): Field => (
    frequencyType === FrequencyOptions.WEEKLY ? ({
      name: 'replenishmentDayOfWeek',
      label: 'Day of the week',
      type: FieldTypes.Dropdown,
      singleMode: true,
      showRequireIcon: true,
      placeholder: 'Select a day',
      defaultValue: frequencyTypeFromResponse === FrequencyOptions.WEEKLY
        ? getDayOfWeek(formattedData?.overridableProperties?.replenishmentDayNumber) : weeklyOptions[3],
      value: frequencyTypeFromResponse === FrequencyOptions.WEEKLY
        ? getDayOfWeek(formattedData?.overridableProperties?.replenishmentDayNumber) : weeklyOptions[3],
      options: weeklyOptions.map((option: string) => ({
        key: option,
        value: capitalizeFirstLetter(option),
      })),
    }) : ({
      name: 'replenishmentCustomDay',
      label: 'Custom',
      type: FieldTypes.Number,
      lowerHelpText: CUSTOM_DAY_FIELD_HINT,
      showRequireIcon: true,
      placeholder: 'Enter custom day number',
      defaultValue: frequencyTypeFromResponse === FrequencyOptions.MONTHLY
        ? replenishmentDayNumber : '',
      value: frequencyTypeFromResponse === FrequencyOptions.MONTHLY
        ? replenishmentDayNumber : '',
      validator: yup.string().test('range', CUSTOM_DAY_ERROR_TEXT, (val) => {
        const customDayNumber = _.toNumber(val);
        return customDayNumber > 0 && customDayNumber <= 31;
      }).required(REQUIRED_TEXT),
    })
  ), [frequencyType, formattedData, getDayOfWeek, frequencyTypeFromResponse, replenishmentDayNumber]);

  const isNeedToShowFrequencyDayField = useMemo(() => frequencyType
  && frequencyType !== FrequencyOptions.DAILY
  && externalFundingReplenishment,
  [frequencyType, externalFundingReplenishment]);

  return useMemo(() => {
    if (isLoading) return [];

    const options = banks.map((bank) => ({
      key: bank.id,
      value: bank.description,
    }));

    return [
      {
        name: 'administratorFeesBank',
        type: FieldTypes.Dropdown,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        placeholder,
        label: 'Administrator fees',
        showRequireIcon: true,
        singleMode: true,
        areSubFieldsShouldHide: !externalAdministratorFees || externalAdministratorFees === NON_ASSIGNABLE_VALUE,
        options: [
          {
            key: NON_ASSIGNABLE_VALUE,
            value: NON_ASSIGNABLE_VALUE,
          },
          ...options,
        ],
        subFields: getSubFields(
          'administratorFees',
          viewMode,
          methods,
          formattedData?.overridableProperties?.externalAdministratorFeesAccountMethod,
        ),
        defaultValue: getDefaultValue(formattedData?.normalProperties?.externalAdministratorFeesAccount),
        value: getDefaultValue(formattedData?.normalProperties?.externalAdministratorFeesAccount),
        hasHorizontalLine: true,
      },
      {
        name: 'payrollContributionBank',
        type: FieldTypes.Dropdown,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        showRequireIcon: true,
        areSubFieldsShouldHide: !externalPayrollContributions || externalPayrollContributions === NON_ASSIGNABLE_VALUE,
        placeholder,
        singleMode: true,
        label: 'Payroll contribution',
        options: [
          {
            key: NON_ASSIGNABLE_VALUE,
            value: NON_ASSIGNABLE_VALUE,
          },
          ...options,
        ],
        subFields: [...getSubFields(
          'payrollContribution',
          viewMode,
          methods,
          formattedData?.overridableProperties?.externalPayrollContributionsAccountMethod,
        ), {
          name: 'holdPeriod',
          type: FieldTypes.Mask,
          mask: '99',
          label: 'Hold Period',
          placeholder: 'Enter # of days',
          value: formattedData?.overridableProperties?.payrollContributionsHoldPeriod,
          validator: yup.string().trim(),
          disabled: viewMode,
          fill: false,
        }],
        areSubFieldsIncluded: true,
        defaultValue: getDefaultValue(
          formattedData?.normalProperties?.externalPayrollContributionsAccount,
        ),
        value: getDefaultValue(
          formattedData?.normalProperties?.externalPayrollContributionsAccount,
        ),
        hasHorizontalLine: true,
      },
      {
        name: 'eeContributionsHoldPeriod',
        type: FieldTypes.Mask,
        mask: '99',
        disabled: viewMode,
        // showRequireIcon: true,
        validator: activateMode
          ? yup.string().trim().required(REQUIRED_TEXT)
          : yup.string(),
        placeholder: 'Enter # of days',
        fill: false,
        label: (
          <Box flex={{ grow: 1 }}>
            <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
              Hold period
            </Inscription>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small">
                Number of days contributions will pend on the account before becoming available
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.eeContributionsHoldPeriod,
        hasHorizontalLine: true,
      },
      {
        name: 'fundingReplenishmentBank',
        type: FieldTypes.Dropdown,
        showRequireIcon: true,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        placeholder,
        singleMode: true,
        label: 'Funding replenishment',
        options,
        subFields: [...getSubFields(
          'fundingReplenishment',
          viewMode,
          methods,
          formattedData?.overridableProperties?.externalReplenishmentAccountMethod,
        ), {
          name: 'replenishmentFrequencyType',
          label: 'Frequency',
          type: FieldTypes.Dropdown,
          showRequireIcon: true,
          singleMode: true,
          placeholder: 'Select option',
          defaultValue: formattedData?.overridableProperties?.replenishmentFrequencyType || '',
          value: formattedData?.overridableProperties?.replenishmentFrequencyType || '',
          options: Object.values(FrequencyOptions).map((option: string) => ({
            key: option,
            value: capitalizeFirstLetter(option),
          })),
        }],
        areSubFieldsIncluded: true,
        defaultValue: getDefaultValue(formattedData?.normalProperties?.externalReplenishmentAccount),
        value: getDefaultValue(formattedData?.normalProperties?.externalReplenishmentAccount),
      },
      ...(isNeedToShowFrequencyDayField ? [renderFrequencyDayField] : []),
    ];
  }, [banks, viewMode, methods, formattedData, externalAdministratorFees, externalPayrollContributions,
    getDefaultValue, isNeedToShowFrequencyDayField, renderFrequencyDayField, activateMode, isLoading]);
};

export default useAccountUsageFields;
