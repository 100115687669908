import React, { useMemo } from 'react';
import { AppButton,Box, LinkButton, Preloader, RoundCloseIcon, RoundDoneIcon, Text } from '@common-fe/common-fe';

import { useProgressBarActions } from './hooks';

interface Props {
  orgName?: string;
  orgPath?: string;
  orgId?: string;
}

const ProgressBarActivationContent: React.FC<Props> = ({
  orgName,
  orgPath,
  orgId,
}) => {
  const {
    handleRedirectToObservingMode,
    handleActivate,
    isSuccess,
    isError,
    isLoading,
  } = useProgressBarActions({ orgPath, orgId });

  const currentContent = useMemo(() => {
    if (isSuccess) {
      return (
        <>
          <Box height="48px" justify="center" align="center">
            <RoundDoneIcon />
          </Box>
          <Text textAlign="center" margin={{ top: 's', bottom: 'xs' }} color="textTitle" size="24px" weight={700} style={{ lineHeight: '32px' }}>All Set!</Text>
          <Text textAlign="center" margin={{ bottom: 'xs' }} color="textBody" style={{ lineHeight: '20px' }}>We've let Employer admins know that their org is active.</Text>
          <Text
            textAlign="center"
            margin={{ horizontal: 'xs' }}
            color="textSecondary"
            style={{ 
              lineHeight: '20px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Redirecting to {' '}
            <LinkButton
              onClick={handleRedirectToObservingMode}
              linkInlineMode
            >
              {orgName}
            </LinkButton>
          </Text>
        </>
      );
    }
    if (isError) {
      return (
        <>
          <Box height="48px" justify="center" align="center">
            <RoundCloseIcon color='danger' size="48px" />
          </Box>
          <Text textAlign="center" margin={{ top: 's', bottom: 'xs' }} color="textTitle" size="24px" weight={700} style={{ lineHeight: '32px' }}>We Are Unable to Set Up Your Plans...</Text>
          <Text textAlign="center" margin={{ bottom: 's' }} color="textBody" style={{ lineHeight: '20px' }}>Your data is safe! But we've encountered an issue that will take more time to resolve.</Text>
          <AppButton
            testId="try-again-button"
            disabled={isLoading}
            type="button"
            style={{ height: '40px' }}
            width='100%'
            onClick={handleActivate}
          >
            {isLoading ? <Preloader color="white" /> : 'Try Again'}
          </AppButton>
        </>
      );
    }
    return (
      <>
        <Box height="42px">
          <Preloader size={28} thinSize={7} color="accentActive" />
        </Box>
        <Text textAlign="center" margin={{ top: 'm', bottom: 'xs' }} color="textTitle" size="24px" weight={700} style={{ lineHeight: '32px' }}>Setting Up Your Plans</Text>
        <Text textAlign="center" color="textSecondary" style={{ lineHeight: '20px' }}>Activation may take about one minute...</Text>
      </>
    );
  }, [isError, isLoading, isSuccess, orgName, handleActivate, handleRedirectToObservingMode]);

  return (
    <Box
      width="304px"
      height="fit-content"
      round="container1Round"
      style={{ overflow: 'hidden' }}
      data-testId="express-setup-progress-bar"
      pad={{ horizontal: 'm', vertical: 'l' }}
      background="canvas"
    >
      {currentContent}
    </Box>
  );
};

export default ProgressBarActivationContent;
