import React, { useMemo } from 'react';
import { WarnModal } from '@common-fe/common-fe';
import _ from 'lodash';

import useModalType from './stores/useModalType.store';

interface Props {
  mode?: string;
  handleSave?: () => void;
  handleSubmit?: () => void;
  handleUpdate?: () => void;
  handleActivate?: () => void;
}

const FinalSetUpModal: React.FC<Props> = ({
  mode, handleSave, handleSubmit, handleUpdate, handleActivate,
}) => {
  const modeTitle = useMemo(() => _.startCase(mode), [mode]);
  const SERVER_ERROR_TITLE = 'The server encountered an error processing the request';
  const SERVER_ERROR_TEXT = `It doesn't appear to have affected your data, but we cannot save the ${modeTitle} with the latest records.
            Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`;
  const ACTIVATE_ERROR_TEXT = `Please correct the highlighted errors to activate the ${modeTitle}`;
  const SAVE_ERROR_TEXT = `Please correct the highlighted errors to save the ${modeTitle}`;
  const SUBMIT_ERROR_TEXT = `Please correct the highlighted errors to submit the ${modeTitle}`;
  const PENDING_ORGANIZATION_ERROR_TEXT = 'Please activate the organization \n before activating the plan';
  const {
    isPendingOrganizationModal,
    saveErrorModal,
    submitErrorModal,
    updateErrorModal,
    activateErrorModal,
    activateModal,
    isEmptySaveModal,
    isEmptySubmitModal,
    setSaveErrorModal,
    setSubmitErrorModal,
    setUpdateErrorModal,
    setActivateErrorModal,
    setActivateModal,
    setIsEmptySaveModal,
    setIsEmptySubmitModal,
    setIsPendingOrganizationModal,
  } = useModalType();

  return (
    <>
      <WarnModal
        visible={saveErrorModal}
        onSetVisible={setSaveErrorModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleSave}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={submitErrorModal}
        onSetVisible={setSubmitErrorModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={updateErrorModal}
        onSetVisible={setUpdateErrorModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleUpdate}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={activateErrorModal}
        onSetVisible={setActivateErrorModal}
        header={SERVER_ERROR_TITLE}
        helptext={SERVER_ERROR_TEXT}
        buttonText="Close"
        onSubmit={handleActivate}
        submitButtonText="Try Again"
      />
      <WarnModal
        visible={isEmptySaveModal}
        onSetVisible={setIsEmptySaveModal}
        header={SAVE_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        visible={isEmptySubmitModal}
        onSetVisible={setIsEmptySubmitModal}
        header={SUBMIT_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        visible={isPendingOrganizationModal}
        onSetVisible={setIsPendingOrganizationModal}
        header={PENDING_ORGANIZATION_ERROR_TEXT}
        buttonText="Got It"
      />
      <WarnModal
        visible={activateModal}
        onSetVisible={setActivateModal}
        header={ACTIVATE_ERROR_TEXT}
        buttonText="Got It"
      />
    </>
  );
};

export default FinalSetUpModal;
