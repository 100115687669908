import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';
import { getIdsFromPath } from '@/utils';

import EmployerForm from './EmployerForm';
import useEmployerQuery from './useEmployer.query';

interface Props {
  id: string;
  onDirty?: (isDirty: boolean) => void;
}

const Employer: React.FC<Props> = ({ id, onDirty }) => {
  const { formattedData, isLoading } = useGetEmployer();
  const {
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
  } = getIdsFromPath(formattedData.organizationPath);
  const {
    isPartner,
    isDistributor,
    isEmployer,
    isSubsidiary,
  } = useMemo(() => ({
    isPartner: formattedData?.type === OrganizationTypes.partner,
    isDistributor: formattedData?.type === OrganizationTypes.distributor,
    isEmployer: formattedData?.type === OrganizationTypes.employer,
    isSubsidiary: formattedData?.type === OrganizationTypes.subsidiary,
  }), [formattedData]);
  const {
    formattedData: { name: partnerName },
    isLoading: isPartnerNameLoading,
  } = useGetOrganizationById(isPartner ? undefined : partnerId);
  const {
    formattedData: { name: distributorName },
    isLoading: isDistributorNameLoading,
  } = useGetOrganizationById(isDistributor ? undefined : distributorId);
  const {
    formattedData: { name: employerName },
    isLoading: isEmployerNameLoading,
  } = useGetOrganizationById(isEmployer ? undefined : employerId);
  const {
    formattedData: { name: subsidiaryName },
    isLoading: isSubsidiaryNameLoading,
  } = useGetOrganizationById(isSubsidiary ? undefined : subsidiaryId);

  const { saveEmployerInfo } = useEmployerQuery();
  const { loading: isEmployerLoading } = useSetupEmployerStore();
  const isDataLoading = useMemo(() => isLoading
    || !formattedData.overridableProperties
    || isPartnerNameLoading
    || isDistributorNameLoading
    || isEmployerNameLoading
    || isSubsidiaryNameLoading
    || isEmployerLoading,
  [
    formattedData.overridableProperties,
    isLoading,
    isPartnerNameLoading,
    isDistributorNameLoading,
    isEmployerNameLoading,
    isSubsidiaryNameLoading,
    isEmployerLoading,
  ]);

  if (isDataLoading) return <FormLoader testId="EmployerForm" />;

  return (
    <Box background="canvas" round="container1Round" margin={{ bottom: 'spacing16' }} id="basic_properties">
      <EmployerForm
        data={formattedData}
        onSubmit={saveEmployerInfo}
        partnerName={partnerName}
        distributorName={distributorName}
        employerName={employerName}
        subsidiaryName={subsidiaryName}
        onDirty={onDirty}
      />
    </Box>
  );
};

export default Employer;
