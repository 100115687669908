import { Option } from '@/common/types';
import { PaymentType } from '@/modules/transaction/transaction.types';

export enum ClaimsStatus {
  Processing = 'Processing',
  Paid = 'Paid',
  NeedsHelp = 'Needs help',
  PartiallyPaid = 'Partially paid',
  Repaid = 'Repaid',
  Denied = 'Denied',
  Declined = 'Declined',
  Voided = 'Voided',
  WaitingForFunds = 'Waiting for funds',
  Pending = 'Pending',
  Reprocessing = 'Reprocessing',
  RepaymentRequired = 'Repayment Required',
  AppliedToDeductible = 'Applied to deductible',
  OutOfPocket = 'Out of pocket',
  Complete = 'Complete',
  Null = 'Null',
}

export interface ClaimDto {
  id: string,
  escalationNote?: string,
  employeeName: string,
  categoryType: string,
  paymentType?: PaymentType,
  age: string,
  status: ClaimsStatus,
  assignee?: string;
  createdAt: string;
  updatedAt: string;
  organizationId?: string;
  organizationName?: string;
  employerName?: string;
  subsidiaryName?: string;
  claimsTeams?: Array<{ id: string; name?: string; }>;
}

export const CLAIMS_STATUSE_OPTIONS: Option[] = [
  {
    key: ClaimsStatus.Null,
    value: ClaimsStatus.Null,
    title: 'All',
  },
  {
    key: ClaimsStatus.Complete,
    value: ClaimsStatus.Complete,
    title: ClaimsStatus.Complete,
  },
  {
    key: ClaimsStatus.Processing,
    value: ClaimsStatus.Processing,
    title: ClaimsStatus.Processing,
  },
  {
    key: ClaimsStatus.Paid,
    value: ClaimsStatus.Paid,
    title: ClaimsStatus.Paid,
  },
  {
    key: ClaimsStatus.NeedsHelp,
    value: ClaimsStatus.NeedsHelp,
    title: ClaimsStatus.NeedsHelp,
  },
  {
    key: ClaimsStatus.PartiallyPaid,
    value: ClaimsStatus.PartiallyPaid,
    title: ClaimsStatus.PartiallyPaid,
  },
  {
    key: ClaimsStatus.Repaid,
    value: ClaimsStatus.Repaid,
    title: ClaimsStatus.Repaid,
  },
  {
    key: ClaimsStatus.Denied,
    value: ClaimsStatus.Denied,
    title: ClaimsStatus.Denied,
  },
  {
    key: ClaimsStatus.Declined,
    value: ClaimsStatus.Declined,
    title: ClaimsStatus.Declined,
  },
  {
    key: ClaimsStatus.Voided,
    value: ClaimsStatus.Voided,
    title: ClaimsStatus.Voided,
  },
  {
    key: ClaimsStatus.WaitingForFunds,
    value: ClaimsStatus.WaitingForFunds,
    title: ClaimsStatus.WaitingForFunds,
  },
  {
    key: ClaimsStatus.Pending,
    value: ClaimsStatus.Pending,
    title: ClaimsStatus.Pending,
  },
  {
    key: ClaimsStatus.Reprocessing,
    value: ClaimsStatus.Reprocessing,
    title: ClaimsStatus.Reprocessing,
  },
  {
    key: ClaimsStatus.RepaymentRequired,
    value: ClaimsStatus.RepaymentRequired,
    title: ClaimsStatus.RepaymentRequired,
  },
  {
    key: ClaimsStatus.AppliedToDeductible,
    value: ClaimsStatus.AppliedToDeductible,
    title: ClaimsStatus.AppliedToDeductible,
  },
  {
    key: ClaimsStatus.OutOfPocket,
    value: ClaimsStatus.OutOfPocket,
    title: ClaimsStatus.OutOfPocket,
  },
];
