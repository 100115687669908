import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useEnrollmentMatrixFieldsStateStore, { EnrollmentMatrixFieldsStatePayload } from '../stores/useEnrollmentMatrixFieldsState.store';

export type ComplexConfigurationFieldsStatePayload = EnrollmentMatrixFieldsStatePayload;

export default () => {
  const setEnrollmentMatrixStore = useEnrollmentMatrixFieldsStateStore((state) => state.setState);

  const handleSetState = useCallback((values: Partial<ComplexConfigurationFieldsStatePayload>) => {
    setEnrollmentMatrixStore(values);
  }, [setEnrollmentMatrixStore]);

  const setSourceEnrollmentMatrixStore = useEnrollmentMatrixFieldsStateStore(
    (state) => state.setSourceState,
  );

  const handleResetEnrollmentMatrixStore = useEnrollmentMatrixFieldsStateStore(
    (state) => state.handleReset,
  );

  const handleSetSourceState = useCallback((values: Partial<ComplexConfigurationFieldsStatePayload>) => {
    setSourceEnrollmentMatrixStore(values);
  }, [setSourceEnrollmentMatrixStore]);

  const handleReset = useCallback(() => {
    handleResetEnrollmentMatrixStore();
  }, [handleResetEnrollmentMatrixStore]);

  const showErrorsEnrollmentMatrixStore = useEnrollmentMatrixFieldsStateStore(
    (state) => state.setShowErrors,
  );

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsEnrollmentMatrixStore(value);
  }, [showErrorsEnrollmentMatrixStore]);

  const setEnrollmentMatrixErrors = useEnrollmentMatrixFieldsStateStore((state) => state.setErrors);
  const setEnrollmentMatrixErrorFields = useEnrollmentMatrixFieldsStateStore(
    (state) => state.setErrorFields,
  );

  const handleSetErrors = useCallback((values: Partial<ComplexConfigurationFieldsStatePayload>) => {
    setEnrollmentMatrixErrors(values);
  }, [setEnrollmentMatrixErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<ComplexConfigurationFieldsStatePayload>>) => {
      setEnrollmentMatrixErrorFields(values);
    }, [setEnrollmentMatrixErrorFields],
  );
  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
