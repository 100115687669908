import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter,formatSnakeCaseToGeneral } from '@common-fe/common-fe';
import { snakeCase, toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { DefaultSortTypesEnum } from '@/common/types';

import { CarrierConfigPurpose } from '../CarrierConfig.constants';

export interface CarrierConfigPayload {
  carrier_name: string;
  carrier_external_id: number;
  carrier_config_id: number;
  submitter_id: string;
  organization: string;
  purpose_type: CarrierConfigPurpose;
  description: string;
}

export interface CarrierConfig {
  carrierName: string;
  carrierExternalId: string;
  carrierConfigId: string;
  submitterId: string;
  organization: string;
  purposeType: string;
  description: string;
  statusType?: string;
}

interface Props {
  searchString?: string,
  purposes?: CarrierConfigPurpose[],
  statusType?: string,
  page?: number,
  perPage?: number,
  sortBy?: DefaultSortTypesEnum,
  enabled?: boolean;
  onSettled?: () => void;
}

const formatCarrierConfig = (value: CarrierConfigPayload): CarrierConfig => ({
  carrierName: value.carrier_name,
  carrierExternalId: toString(value.carrier_external_id),
  carrierConfigId: toString(value.carrier_config_id),
  submitterId: value.submitter_id,
  organization: value.organization,
  purposeType: capitalizeFirstLetter(formatSnakeCaseToGeneral(value.purpose_type)),
  description: value.description,
});

const useCarrierConfigListQuery = ({
  searchString = '',
  purposes,
  statusType,
  page,
  perPage,
  sortBy,
  enabled,
  onSettled,
}: Props) => {
  const purposeSearchString = purposes ? purposes.join(',') : '';

  const params = {
    search_value: searchString,
    purpose_types: purposes?.length ? purposes.map((purpose) => snakeCase(`${purpose}`).toUpperCase()).join(',') : '',
    status_type: statusType,
    page,
    ...perPage ? { size: perPage } : {},
    sort: sortBy && `carrierName,${sortBy}`,
  };

  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [
      PATHS.GET_CARRIER_CONFIGS,
      purposeSearchString,
      page,
      perPage,
      statusType,
      searchString,
      sortBy,
      enabled,
    ],
    () => api.get(PATHS.GET_CARRIER_CONFIGS, { params }), {
      enabled,
      onSettled,
    },
  );

  const carrierConfigsList = useMemo(() => {
    const list = data?.data?.content || [];
    return list.map((value: CarrierConfigPayload) => formatCarrierConfig(value));
  }, [data]);

  const total = useMemo(() => data?.data?.totalElements || 0, [data]);

  return {
    formattedData: (carrierConfigsList || []) as CarrierConfig[],
    total,
    data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useCarrierConfigListQuery;
