 
import React, { useCallback } from 'react';
import {
  Box, Button,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import { useEditModeStore } from '../stores';

interface Props {
  isDraftMode?: boolean;
  isTemplateMode?: boolean;
}

const HealthPlanTopbarEditModeButton: React.FC<Props> = ({ isDraftMode, isTemplateMode }) => {
  const setEditMode = useEditModeStore((state) => state.setEditMode);

  const handleSetEditMode = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);
  return (
    <Box direction="row">
      <Box width="100px">
        <Access
          accessRequirements={[
            {
              permission: isTemplateMode
                ? Permissions.PLAN_TEMPLATE_SETUP
                : Permissions.EDIT_DRAFT_PLAN,
            },
          ]}
        >
          <Button
            secondary
            style={{ flex: 1, padding: 0 }}
            onClick={handleSetEditMode}
            type="button"
            size="medium"
            label={isDraftMode ? 'Edit' : 'Configure'}
          />
        </Access>
      </Box>
    </Box>
  );
};

export default HealthPlanTopbarEditModeButton;
