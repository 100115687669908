import React, { useCallback, useMemo } from 'react';
import { Box, FieldTypes, NumberRangeInput, Text } from '@common-fe/common-fe';

import { AuthenticationTypeId, MultiFactorAuthenticationValues } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { EmployerDto } from '@/modules/employer/types';

const RangeValueTitle = ({ title }: { title: string }) => (
  <Box>
    <Text
      color="textSecondary"
      textAlign="center"
      size="small"
      weight={500}
    >
      {title}
    </Text>
  </Box>
);

interface Props {
  disabled: boolean;
  organizationData?: EmployerDto;
  multiFactorAuthenticationValues?: MultiFactorAuthenticationValues | null;
  setValues: (value: MultiFactorAuthenticationValues) => void;
}

const useMultiFactorAuthenticationFields = ({ disabled, organizationData, multiFactorAuthenticationValues, setValues }: Props) => {
  const authenticationTypeId = useMemo(() => organizationData?.overridableProperties?.authenticationTypeId, [organizationData]);
  const accountLockoutTime = useMemo(() => organizationData?.overridableProperties?.accountLockoutTime, [organizationData]);

  const isMfaDisabled = useMemo(() => multiFactorAuthenticationValues?.authenticationTypeId === AuthenticationTypeId.NO_MFA, [multiFactorAuthenticationValues]);

  const handleChangeRange = useCallback((value: number) => {
    setValues({ accountLockoutTime: value });
  }, [setValues]);

  const fields = useMemo(() => [
    {
      name: 'authenticationTypeId',
      label: 'Authentication Type',
      type: FieldTypes.Dropdown,
      singleMode: true,
      placeholder: 'Authentication Type',
      defaultValue: authenticationTypeId,
      options: [
        {
          key: AuthenticationTypeId.NO_MFA,
          value: 'No MFA',
        },
        {
          key: AuthenticationTypeId.DURING_LOGIN,
          value: 'During Login',
        },
      ],
    },
    ...isMfaDisabled ? [] : [
      {
        name: 'accountLockoutTime',
        label: 'Account Lockout Time',
        type: FieldTypes.Node,
        node: (
          <NumberRangeInput
            onChange={handleChangeRange}
            value={accountLockoutTime || 30}
            step={1}
            min={1}
            max={1000}
            disabled={disabled}
            minValueTitle={(
              <RangeValueTitle title="1 min" />
            )}
            maxValueTitle={(
              <RangeValueTitle title="1000 max" />
            )}
          />
        ),
      }
    ]
  ], [authenticationTypeId, accountLockoutTime, disabled, isMfaDisabled, handleChangeRange]);

  return fields;
};

export default useMultiFactorAuthenticationFields;
