import { OptionKey } from '@/common/types';
import { GroupByFilter, PlanYearReportFilterItems } from '@/modules/employer/components/Reports/report.types';
import { LAST_4_DIGIT_KEY } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

export type AccountActivityGroupByType = [
  GroupByFilter | null,
  GroupByFilter | null,
];

export const ACCOUNT_ACTIVITY_GROUP_BY_PLAN_YEAR: OptionKey[] = [PlanYearReportFilterItems.NULL];

export const ACCOUNT_ACTIVITY_GROUP_BY_DEFAULT_VALUE: OptionKey = GroupByFilter.Partner;

interface State {
  search: string;
  planYear: OptionKey[];
  groupBy: OptionKey;
  SSNOption: OptionKey;
  handleSetSSNOption: (value: OptionKey) => void;
  handleSetSearch: (value: string) => void;
  handleSetPlanYear: (value: OptionKey[]) => void;
  handleSetGroupBy: (groupBy: OptionKey) => void;
  handleClear: () => void;
}

export const useAccountActivityReportFilterStore = storeCreator<State>((set) => ({
  search: '',
  SSNOption: LAST_4_DIGIT_KEY,
  planYear: ACCOUNT_ACTIVITY_GROUP_BY_PLAN_YEAR,
  groupBy: ACCOUNT_ACTIVITY_GROUP_BY_DEFAULT_VALUE,

  handleSetSSNOption: (value: OptionKey) => set(() => ({
    SSNOption: value,
  })),
  handleSetSearch: (value: string) => set(() => ({
    search: value,
  })),

  handleSetPlanYear: (value: OptionKey[]) => set(() => ({
    planYear: value,
  })),

  handleSetGroupBy: (groupBy: OptionKey) => set(() => ({
    groupBy,
  })),

  handleClear: () => set(() => ({
    search: '',
    SSNOption: LAST_4_DIGIT_KEY,
    planYear: ACCOUNT_ACTIVITY_GROUP_BY_PLAN_YEAR,
    groupBy: ACCOUNT_ACTIVITY_GROUP_BY_DEFAULT_VALUE,
  })),
}));
