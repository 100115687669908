import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import {
  AppButton, Box, FieldValues, useContextForm,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { CopayGroupPayload } from '../../CopayAdjudication.constant';
import { useHighlightItemStore } from '../../stores';

import useGetFormattedCopay from './hooks/useGetFormattedCopay';
import AddNewCopayButton from './AddNewCopayButton';
import CopayBasicPropertiesForm from './CopayBasicPropertiesForm';
import CopayItemForm from './CopayItemForm';
import { useCopayErrors, useCopaysState, useDefaultValues } from './hooks';
import { useCreateCopayQuery } from './queries';

interface Props {
  onClose: () => void;
  children?: React.ReactNode;
  isViewMode?: boolean;
  isEditMode?: boolean;
  onSetIsEditMode?: (value: boolean) => void;
  defaultValues?: CopayGroupPayload;
}

const CreateCopayAdjudicationForm: React.FC<Props> = ({
  onSetIsEditMode,
  onClose, defaultValues, isViewMode, isEditMode, children,
}) => {
  const {
    defaultValues: currentDefaultValues, handleChangeValues,
  } = useDefaultValues(defaultValues);
  const setHighlightedItem = useHighlightItemStore((state) => state.setHighlithedId);
  const {
    copays, handleAddCopay, handleRemoveCopay, defaultFormattedCopays, copayIds,
  } = useCopaysState(currentDefaultValues);

  const {
    handleSubmit, formContext, isSubmitted, reset,
  } = useContextForm({
    defaultValues: {
      ...currentDefaultValues,
      ...defaultFormattedCopays,
    },
  });

  useEffect(() => {
    if (currentDefaultValues) {
      const keys = Object.keys(currentDefaultValues);
      keys.forEach((key) => {
        const currentValue = _.get(currentDefaultValues, key);
        if (currentValue) {
          formContext.setValue(key, currentValue || '');
        }
      });
    }
  }, [currentDefaultValues, formContext, reset]);

  useEffect(() => {
    if (defaultFormattedCopays) {
      const keys = Object.keys(defaultFormattedCopays);
      keys.forEach((key) => {
        const currentValue = _.get(defaultFormattedCopays, key);
        if (currentValue) {
          formContext.setValue(key, currentValue || '');
        }
      });
    }
  }, [defaultFormattedCopays, formContext]);

  const handleGetFormatedCopay = useGetFormattedCopay(copays, isEditMode);
  const { mutateAsync, mutateUpdateAsync } = useCreateCopayQuery();
  const { currentErrors, handleParseError } = useCopayErrors();
  const handleSave = useCallback(async (values: FieldValues) => {
    try {
      const formattedValues = handleGetFormatedCopay(values);
      if (isEditMode) {
        const response = await mutateUpdateAsync({
          ...formattedValues,
          id: defaultValues?.id,
        });
        if (response.data.id) {
          setHighlightedItem(`${response.data.id}`);
        }
      } else {
        const response = await mutateAsync(formattedValues);
        if (response.data.id) {
          setHighlightedItem(`${response.data.id}`);
        }
      }

      if (isEditMode
        && onSetIsEditMode) {
        onSetIsEditMode(false);
      }
      onClose();
    } catch (e) {
      handleParseError(values, e);
    }
  }, [defaultValues, handleGetFormatedCopay, handleParseError,
    isEditMode, mutateAsync, mutateUpdateAsync, onClose, onSetIsEditMode, setHighlightedItem]);

  const isDeletable = useMemo(() => copays.length > 1, [copays.length]);

  return (

    <Box data-testid="create-copay-form">

      <form onSubmit={handleSubmit(handleSave)}>
        <Box background="module" pad={{ top: 'l', left: 'l', right: 'l' }} round="moduleRound">
          <CopayBasicPropertiesForm
            isViewMode={isViewMode && !isEditMode}
            isEditMode={isEditMode}
            currentErrors={currentErrors}
            isSubmitted={isSubmitted}
            formContext={formContext}
            defaultValues={currentDefaultValues}
            onChangeValues={handleChangeValues}
          />
          {copays.map((item, index) => (
            <CopayItemForm
              isViewMode={isViewMode && !isEditMode}
              key={item.id}
              copayIds={copayIds}
              isDeletable={isDeletable}
              isSubmitted={isSubmitted}
              formContext={formContext}
              defaultValues={item}
              index={index}
              onRemove={() => {
                handleRemoveCopay(item.id);
              }}
            />
          ))}
          {(!isViewMode || isEditMode) && (
            <AddNewCopayButton onClick={handleAddCopay} />
          )}
        </Box>

        <Box margin={{ top: 'spacing24' }}>
          <Box
            justify="end"
            align="center"
            direction="row"
          >
            <AppButton
              testId="cancel"
              buttonType="secondary"
              onClick={onClose}
              width="160px"
            >
              Cancel
            </AppButton>
            {!isViewMode && (
              <Box margin={{ left: 'spacing12' }}>
                <AppButton
                  testId="submit"
                  type="submit"
                  width="160px"
                >
                  Submit
                </AppButton>
              </Box>
            )}
            {children}

          </Box>

        </Box>
      </form>
    </Box>

  );
};
export default CreateCopayAdjudicationForm;
