import React, { useCallback, useState } from 'react';
import { Box, WarnModal } from '@common-fe/common-fe';

import AddModalWrapper from '@/modules/employer/components/SetupEmployer/AddModalWrapper';
import { BankInformationFields } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BankInformationForm/useBankInformationFields';

import BankInformationForm from './BankInformationForm';
import useBankInformationQuery from './useBankInformation.query';

const TEXT_TIMEOUT = 5000;
const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const BankInformation: React.FC<{ disabled?: boolean, organizationPath?: string, }> = ({
  disabled,
  organizationPath,
}) => {
  const [added, setAdded] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const hideModalForm = () => setAddModalVisible(false);
  const {
    save, isLoading, errorModal, setErrorModal, retry, errorModalProps,
  } = useBankInformationQuery(organizationPath, hideModalForm);
  const [buttonName, setButtonName] = useState('Add Bank Account Details');
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);
  const handleSubmit = useCallback(async (newValue: BankInformationFields) => {
    const val = { ...newValue };
    delete val.employerBankingContactType;
    await save(val);
    hideModalForm();
    setAdded(true);
    setButtonName('Add another bank account');
    setTimeout(() => {
      setAdded(false);
    }, TEXT_TIMEOUT);
  }, [save]);

  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && shouldConfirmOnClose) {
       
      return window.confirm(CONFIRM_TEXT) && setAddModalVisible(value);
    }
    return setAddModalVisible(value);
  }, [shouldConfirmOnClose]);

  return (
    <>
      <AddModalWrapper
        id="bank_information"
        title="Bank Information"
        visible={addModalVisible}
        isTransparent={errorModal}
        onSetVisible={onSetVisibleHandler}
        buttonText={buttonName}
        modalTitle="Add Bank Account Details"
        disabled={disabled}
        successTextShow={added}
        successText="Bank account added."
      >
        <Box style={errorModal ? { display: 'none' } : {}}>
          <BankInformationForm
            onDirty={setShouldConfirmOnClose}
            loading={isLoading}
            onSubmit={handleSubmit}
            onCancel={() => onSetVisibleHandler(false)}
          />
        </Box>
      </AddModalWrapper>

      {errorModal && addModalVisible
      && (
        <WarnModal
          onSetVisible={setErrorModal}
          {...errorModalProps}
          onSubmit={retry}
        />
      )}
    </>
  );
};

export default BankInformation;
