import { useMemo } from 'react';

import { useHealthPlanNameStore } from '../HealthPlanName/stores/useHealthPlanName.store';
import { useStore as usePlanYearStore } from '../PlanYear/stores/usePlanYear.store';

export default () => {
  const healthPlanNameStore = useHealthPlanNameStore((state) => state.state);
  const planYearStore = usePlanYearStore((state) => state.state);

  const state = useMemo(() => ({
    ...healthPlanNameStore,
    ...planYearStore,
  }), [
    healthPlanNameStore,
    planYearStore,
  ]);
  return state;
};
