import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { api } from '@/api';
import PATHS from '@/common/paths';
import ROUTES from '@/common/routes';
import { useMountedIdStore } from '@/modules/employee/Employee/stores';

import { EmployeePayload } from '../Employee.types';
import useEmployeeClaimAutopayStore from '../PersonalInformation/HealthPlanClaims/stores';
import { useOrganizationInfoStore } from '../PersonalInformation/OrganizationInfo/stores';
import { getFormatedEmployee } from '../utils';

export const EMPLOYEE_QUERY_KEY = 'EMPLOYEE_BY_ID';
const useGetEmployeeByIdQuery = (
  id: string,
  disabled?: boolean,
) => {
  const { mountedId } = useMountedIdStore();
  const { path } = useRouteMatch();
  const isEditMode = useMemo(() => path === ROUTES.EMPLOYEE_EDIT_VIEW(), [path]);
  const {
    data, isSuccess, isLoading, refetch,
  } = useQuery(
    [EMPLOYEE_QUERY_KEY, id, disabled, isEditMode, mountedId],
    () => api.get<EmployeePayload>(
      PATHS.EMPLOYEE_BY_ID(id),
    ), {
      enabled: !disabled,
      staleTime: 3000,
      cacheTime: 0,
    },
  );
  const {
    setState,
    handleReset,
  } = useOrganizationInfoStore();
  const {
    setSourceState: setEmployeeClaimAutopay,
    handleReset: resetEmployeeClaimAutopay,
  } = useEmployeeClaimAutopayStore();
  const formatedData = useMemo(() => {
    const currentData = data?.data;
    return getFormatedEmployee(currentData);
  }, [data]);
  useEffect(() => {
    if (data) {
      setState({
        organizationName: formatedData?.organizationName || '',
        organizationId: formatedData?.organizationId || '',
        organizationPath: formatedData?.organizationPath || '',
      });
      setEmployeeClaimAutopay({
        claimAutopayType: formatedData?.claimAutopayType,
        defaultClaimPayeeType: formatedData?.defaultClaimPayeeType,
      });
    }
    return () => {
      handleReset();
      resetEmployeeClaimAutopay();
    };
  }, [
    data,
    formatedData,
    handleReset,
    isSuccess,
    setState,
    setEmployeeClaimAutopay,
    resetEmployeeClaimAutopay,
  ]);

  return {
    isLoading,
    isSuccess,
    data: formatedData,
    refetch,
  };
};

export default useGetEmployeeByIdQuery;
