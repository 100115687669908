import React from 'react';
import { Box, Inscription, RoundCloseIcon } from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  children?: React.ReactNode;
}

const ErrorSnack: React.FC<Props> = ({ children }) => (
  <Box
    height={{ min: '40px' }}
    background="dangerContainer"
    round="4px"
    border={{ color: theme.colors.dangerBorder }}
    justify="between"
    align="center"
    direction="row"
    pad={{ vertical: '10px', horizontal: '14px' }}
  >
    <Box align="center" direction="row">
      <RoundCloseIcon size="20px" color={theme.colors.danger} />
      <Inscription margin={{ left: '10px' }}>
        {`Please review the errors and 
          submit a new file with the corrected records.`}
      </Inscription>
    </Box>
    {children}
  </Box>
);

export default ErrorSnack;
