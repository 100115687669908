import React from 'react';
import { Box, Inscription, PencilIcon, PlusIcon, ShimmerBox, Text } from '@common-fe/common-fe';

import ExpressSetupBannerImg from '@/assets/placeholders/express-setup-banner.svg';
import { ContactPayload } from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import theme from '@/styles/theme.ts';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

import { ExpressSetup } from '../ExpressSetup.types';
import ProgressBar from '../ProgressBar';
import { InfoTileIconWrapper } from '../styles/ExpressSetupBanner.styles';
import { BackgroundWrapper, StyledDiv, StyledWrapper } from '../styles/ExpressSetupBanner.styles';
import { useGetOrgFullAddress } from  '../utils/getOrgFullAddress.ts';

import ContactInfoIcon from './ContactInfoIcon.tsx';
import Tip from './Tip';


interface Props {
  expressSetupData?: ExpressSetup;
  isLoading?: boolean;
  isActivateMode?: boolean;
  onSetAddressModalVisible?: (value: boolean) => void;
  onClickMainContact?: () => void;
  onClickOpenContactModal?: () => void;
  onClickUpdateContact?: (value?: ContactPayload) => void;
}

const MAX_CONTACTS = 3;

const ExpressSetupBanner: React.FC<Props> = ({
  expressSetupData,
  isLoading,
  isActivateMode,
  onSetAddressModalVisible,
  onClickMainContact,
  onClickOpenContactModal,
  onClickUpdateContact,
}) => {
  const fullAddress = useGetOrgFullAddress(expressSetupData?.address, true);
  return (
    <Box
      height="400px"
      round="container1Round"
      style={{ overflow: 'hidden' }}
      data-testId="express-setup-banner"
      margin={{ bottom: 'l' }}
    >
      {
        isLoading ? <ShimmerBox /> : (
          <BackgroundWrapper
            direction="row"
            justify="between"
            imageSrc={whiteLabelingConfig?.images?.expressSetupBannerBackground || ExpressSetupBannerImg}
          >
            <Box justify="center" margin={{ left: 'xxxl', right: 'l' }}>
              <Text
                size="64px"
                color="textOnColor"
                weight="bold"
                style={{
                  lineHeight: '70px',
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                }}
              >
                {expressSetupData?.name}
              </Text>
              <Box margin={{ top: 'xs' }} direction="row">
                <Text
                  color="textOnColor"
                  weight={400}
                  style={{ lineHeight: '20px' }}
                >
                  {fullAddress}
                </Text>
                {
                  !isActivateMode && (
                    <Box
                      margin={{ left: 'xxxs' }}
                      onClick={() => onSetAddressModalVisible && onSetAddressModalVisible(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <PencilIcon size='18px' color="iconSecondary" />
                    </Box>
                  )
                }
              </Box>
              <Box direction="row" margin={{ top: 'l' }}>
                {expressSetupData?.contacts?.slice(0, MAX_CONTACTS)?.map((contact, index) => (
                  <Box onClick={contact?.isPrimary ? onClickMainContact : () => onClickUpdateContact && onClickUpdateContact(contact)} key={contact?.id} style={{ zIndex: 100 - index }} {...index ? { margin: { left: '-8px' } } : {}}>
                    <ContactInfoIcon title={contact?.contactName} isActivateMode={isActivateMode} />
                  </Box>
                ))}
                {typeof expressSetupData?.contacts?.length === 'number' && expressSetupData?.contacts?.length > MAX_CONTACTS ? (
                  <Tip items={expressSetupData?.contacts?.map((contact) => contact?.contactName || '').slice(MAX_CONTACTS)} space="42px">
                    <InfoTileIconWrapper
                      height="40px"
                      width={{ min: '40px' }}
                      margin={{ left: '-8px' }}
                      style={{ zIndex: 1 }}
                      justify="center"
                      align="center"
                      background="partner"
                      hasBorder
                    >
                      <Inscription
                        size="16px"
                        color="textOnColor"
                        weight={700}
                      >
                        +{expressSetupData?.contacts?.length - MAX_CONTACTS}
                      </Inscription>
                    </InfoTileIconWrapper>
                  </Tip>
                ) : null}
                {!isActivateMode && (
                  <StyledWrapper
                    height="40px"
                    width={{ min: '40px' }}
                    margin={{ left: '-8px' }}
                    justify="center"
                    align="center"
                    style={{ 
                      zIndex: 0, 
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    onClick={onClickOpenContactModal}
                  >
                    {/* Pseudo-element for opacity */}
                    <StyledDiv />
                    <PlusIcon size="16px" color={theme.colors.iconOnColor} />
                  </StyledWrapper>
                )}
              </Box>
            </Box>
            <Box width={{ min: '400px' }} justify="center">
              <ProgressBar expressSetupData={expressSetupData} isBannerMode isActivateMode={isActivateMode} />
            </Box>
          </BackgroundWrapper>
        )
      }
    </Box>
  );
};

export default ExpressSetupBanner;
