import { useCallback, useMemo, useState } from 'react';

interface Props<T> {
  items: T[];
  stepSize: number;
}

const usePagination = <T>({ items, stepSize }: Props<T>) => {
  const [step, setStep] = useState(1);

  const handleNext = useCallback(() => {
    setStep((currentStep) => currentStep + 1);
  }, [setStep]);

  const slicedItems = useMemo(() => {
    return items.slice(0, step * stepSize);
  }, [items, step, stepSize]);

  const reset = useCallback(() => setStep(1), []);

  const hasNextItems = useMemo(() => items?.length > slicedItems?.length, [items, slicedItems]);

  return {
    slicedItems,
    hasNextItems,
    handleNext,
    reset,
  };
};

export default usePagination;
