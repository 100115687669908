import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface CardOfferedFieldsStatePayload {
  cardOffered: PlanFieldState;
  fundID: PlanFieldState;
  fundIDFormula: PlanFieldState;
  fundIdFormulaOptions: PlanFieldState;
}

export const DEFAULT_VALUES: CardOfferedFieldsStatePayload = {
  cardOffered: PlanFieldState.Modifiable,
  fundID: PlanFieldState.Modifiable,
  fundIDFormula: PlanFieldState.Modifiable,
  fundIdFormulaOptions: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<CardOfferedFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
