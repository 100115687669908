import { Roles } from '@/common/constants';

export enum ContactStatuses {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum EmailCommunicationType {
  finance = 'FINANCE',
  planManagement = 'PLAN_MANAGEMENT',
  files = 'FILES',
}

export interface EmailCommunication {
  finance: boolean,
  planManagement: boolean,
  files: boolean,
}

export interface ContactDto {
  id: number;
  username: string;
  password?: string;
  role: Roles;
  title: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  department: string | null;
  primary: boolean;
  external_identifier: string | null;
  contact_type: string;
  contact_status_type: string;
  email_communication_type_list: EmailCommunicationType[];
}

export interface Contact {
  id: number | string;
  title: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  department: string | null;
  primary: boolean;
  externalIdentifier: string | null;
  contactType: string;
  contactStatusType: string;
  role: string;
  contactName: string;
  userName: string;
  emailCommunicationTypeList: EmailCommunicationType[];
}

export interface CreateContactPayload {
  firstName :string;
  lastName :string;
  email :string;
  userName :string;
  externalIdentifier :string;
  phoneNumber :string;
  title :string;
  department :string;
  primary :boolean;
  permissions :Roles;
}

export interface CreateContactDto {
  username: string;
  password?: string;
  role: Roles;
  title?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  // username: string;
  phone: string;
  department?: string | null;
  primary: boolean;
  external_identifier: string | null;
  contact_type: string;
  contact_status_type: string;
  email_communication_type_list?: EmailCommunicationType[];
  claims_teams_ids?: number[];
}

export interface RoleModel {
  description?: string;
  id: number;
  name: Roles;
}

export interface ContactInfo {
  id?: string,
  userName?: string,
  role?: Roles,
  title?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  clockIn: boolean,
  phone?: string,
  department?: string,
  primary?: true,
  externalIdentifier?: string,
  contactType?: Roles,
  contactStatusType?: ContactStatuses,
  emailCommunicationTypeList?: EmailCommunicationType[];
}
