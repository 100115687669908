import { OrganizationTypes } from '@/common/constants';
import { MAX_SIZE, Organization } from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetOrganization.query';

import { ClaimTeamsHierarchyResponseDto } from '../../queries/useClaimTeamsHierarchy.query';
import { TeamOrganization } from '../ClaimsProcessors.types';

export const getNumberOfSearchedOrganizations = (
  nestedArray?: TeamOrganization[],
  searchedValue?: string,
) => {
  if (!nestedArray?.length || !searchedValue) return 0;
    
  let counter = 0;
    
  for (let i = 0; i < nestedArray.length; i++) {
    if (nestedArray[i]?.name?.toLowerCase()?.includes(searchedValue.toLowerCase())) {
      counter += 1;
    }
    
    if (nestedArray[i]?.children?.length) {
      counter += getNumberOfSearchedOrganizations(nestedArray[i].children, searchedValue);
    }
  }
    
  return counter;
};
  
export interface TrimmedHierarchy {
  trimmedNode?: ClaimTeamsHierarchyResponseDto;
  selectedIds?: string[];
  childrenLevel?: OrganizationTypes;
}
  
export const trimClaimTeamsHierarchy = (
  node?: ClaimTeamsHierarchyResponseDto,
  isSystemLevel?: boolean,
  isPartnerLevel?: boolean,
  isDistributorLevel?: boolean
): TrimmedHierarchy | undefined => {
  const extractIds = (node: ClaimTeamsHierarchyResponseDto) => {
    if (!node) return [];
    const ids: string[] = [];
  
    for (const child of node.children) {
  
      if (!child.matchesQuery) {
        ids.push(String(child.id));
      }
  
      ids.push(...extractIds(child));
    }
    return ids;
  };
  
  if (!node) {
    return undefined;
  }
  
  if (isSystemLevel) {
    return {trimmedNode: node, selectedIds: extractIds(node), childrenLevel: OrganizationTypes.partner};
  }
  
  if (isPartnerLevel) {
    return {trimmedNode: node.children[0], selectedIds: extractIds(node.children[0]), childrenLevel: OrganizationTypes.distributor};
  }
  
  if (isDistributorLevel) {
    return {trimmedNode: node.children[0].children[0], selectedIds: extractIds(node.children[0].children[0]), childrenLevel: OrganizationTypes.company};
  }
  
  return undefined;
};

export const mergeOrgsArrays = ( 
  mainArray: Organization[],
  secondaryArray: ClaimTeamsHierarchyResponseDto[]
): Organization[] => {
  const mergedArray: Organization[] = [
    ...secondaryArray.map(obj => ({ ...obj, id: obj.id.toString() })),
    ...mainArray.filter(obj => !secondaryArray.some(org => org.id.toString() === obj.id))
  ];

  return mergedArray;
};

export const getTotalString = (total?: number, isSearhchUsed?: boolean) => {
  if (!total) return 0;
  if (isSearhchUsed) return total;
  
  if (total > MAX_SIZE) {
    return `${MAX_SIZE} / ${total}`;
  }
  
  return total;
};
