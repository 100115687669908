import { useMemo } from 'react';

import { ExpressSetup } from '../ExpressSetup.types';

const useActivationAllowed = (expressSetupData?: ExpressSetup) => {
  return useMemo(() => {
    return !!expressSetupData?.mainContact && !!expressSetupData?.accountNumber && !!expressSetupData?.plans?.length;
  }, [expressSetupData]);
};

export default useActivationAllowed;
