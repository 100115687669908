import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'useClaimTeamsHierarchyQueryKey';

export interface ClaimTeamsHierarchyResponseDto {
    id: number;
    name: string;
    matchesQuery: boolean;
    children: ClaimTeamsHierarchyResponseDto[];
}

const useClaimTeamsHierarchyQuery = (claimTeamId?: string) => {
  const {
    isLoading, isSuccess, isError, data, refetch, isRefetching,
  } = useQuery([
    QUERY_KEY, claimTeamId,
  ], claimTeamId
    ? () => api.get<ClaimTeamsHierarchyResponseDto>(PATHS.CLAIMS_TEAM_HIERARCHY(claimTeamId))
    : () => null
  );

  return ({
    data: data?.data,
    isError,
    isLoading,
    isSuccess,
    refetch,
    isRefetching,
  });
};

export default useClaimTeamsHierarchyQuery;
