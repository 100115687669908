import { useCallback, useState } from 'react';
import {confirmResetPassword } from '@aws-amplify/auth';

import { SECOND } from '@/common/constants';
import useSendForgotPasswordCodeQuery from '@/modules/user/components/SignIn/ForgotPassword/queries/useSendForgotPasswordCode.query';

const TIMEOUT = SECOND * 15;

export default () => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const { sendForgotPasswordCode } = useSendForgotPasswordCodeQuery();
  const handleResetError = useCallback(() => {
    setError(false);
  }, []);

  const handleRequestCode = useCallback(async (username: string) => {
    await sendForgotPasswordCode({
      username
    });
  }, [sendForgotPasswordCode]);

  const handleSetNewCode = useCallback(
    async (
      username: string,
      code: string,
      newPassword: string,
      callback: (value: object) => void,
    ) => {
      try {
        await confirmResetPassword({
          username,
          newPassword,
          confirmationCode: code
        });
        setError(false);
        callback({});
      } catch (e) {
        setError(true);
      }
    }, [],
  );
 
  const handleRequestCodeWithTimeout = useCallback(async (username: string) => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, TIMEOUT);

    try {
      await handleRequestCode(username);
    } catch {
      setError(true);
    }
  }, [handleRequestCode]);
  return {
    error,
    setError,
    handleResetError,
    disabled,
    handleRequestCodeWithTimeout,
    handleSetNewCode,
    handleRequestCode,
  };
};
