import React, { useEffect, useState } from 'react';
import { FlexControlledForm, useDebounce } from '@common-fe/common-fe';

import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerDto } from '@/modules/employer/types';

import { EmployerPayload } from '../SetupEmployer.types';
import { useSaveTypeStore } from '../stores';

import useEmployerFields from './useEmployerFields';

interface Props {
  onSubmit?: (value: EmployerPayload) => void;
  onChangeErrors?: (values: object) => void;
  data?: EmployerDto,
  partnerName?: string,
  distributorName?: string,
  employerName?: string,
  subsidiaryName?: string,
  onDirty?: (isDirty: boolean) => void;
}
const EmployerForm: React.FC<Props> = ({
  onSubmit,
  data,
  partnerName,
  distributorName,
  employerName,
  subsidiaryName,
  onDirty,
}) => {
  const isTerminated = data?.organizationStatusType === EmployerStatus.Terminated;
  const { viewMode } = useSetupEmployerMode();
  const { pendingStatus, activeStatus, handleResetType } = useSaveTypeStore();
  const fields = useEmployerFields(
    !pendingStatus,
    data,
    partnerName,
    distributorName,
    employerName,
    subsidiaryName,
  );
  const [currentValues, setCurrentValues] = useState<object>({});
  const debouncedValue = useDebounce(currentValues);

  useEffect(() => {
    if (onSubmit) {
      onSubmit(debouncedValue as EmployerPayload);
    }
  }, [debouncedValue, onSubmit]);

  useEffect(() => () => {
    handleResetType();
  }, [handleResetType]);
  return (
    <FlexControlledForm
      fields={fields}
      showError={pendingStatus || activeStatus}
      editMode={!viewMode && !isTerminated}
      onChangeValues={setCurrentValues}
      onDirty={onDirty}
      isThinMode
    />
  );
};
export default EmployerForm;
