import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import {
  ActivityLogChangesetPayload,
  Changeset,
  formatAttribute,
  formatData,
  formatValue,
} from './common';

const useGetActivityLogChangesetsQuery = (changesetIds?: string[]) => {
  const { data, isLoading } = useQuery(
    [PATHS.ACTIVITY_LOG_CHANGESETS, changesetIds?.join()],
    () => api.get(`${PATHS.ACTIVITY_LOG_CHANGESETS}?ids=${changesetIds?.join()}`),
    {
      enabled: Boolean(changesetIds?.length),
    },
  );

  const formattedData = useMemo(() => {
    if (!data?.data) return [];

    return Object.keys(data?.data)?.map((changesetId) => formatData(data?.data[changesetId]as ActivityLogChangesetPayload[])
      ?.map((item) => ({
        id: item?.id,
        changesetId,
        fieldName: item.fieldName,
        attribute: formatAttribute(item.fieldName),
        previous: formatValue(item.fieldName, item.oldValue),
        updated: formatValue(item.fieldName, item.newValue),
      } as Changeset)))?.flat();
  }, [data]);

  return {
    formattedData,
    isLoading,
  };
};

export default useGetActivityLogChangesetsQuery;
