import React, { useCallback, useEffect,useState } from 'react';
import {
  Box, Text, WarningLabel, WarnModal,
} from '@common-fe/common-fe';

import { StatusWrapper } from '@/components';

import { PlanCardAction } from '../../plan.types';
import PlanModalWrapper from '../PlanModalWrapper';
import { useForceOpenModalStore } from '../stores';

import Footer from './Footer';
import { useCreatePlanTemplate } from './hooks';
import PlanTemplate from './PlanTemplate';
// import { useCheckPaymentOrder } from '../../hooks';

interface Props {
  hide?: boolean;
}

const CreateTemplateModal: React.FC<Props> = ({ hide }) => {
  const modalStore = useForceOpenModalStore((state) => state.state);
  const handleClearModalStore = useForceOpenModalStore((state) => state.clear);
  const [modalVisible, setModalVisible] = useState(false);
  // const handleCheck = useCheckPaymentOrder();
  const handleCancel = useCallback(() => {
    setModalVisible(false);
    handleClearModalStore();
  }, [handleClearModalStore]);

  useEffect(() => {
    if (modalStore.actionType === PlanCardAction.createTemplateFromTemplate && modalStore.id) {
      setModalVisible(true);
    }
  }, [modalStore.actionType, modalStore.id]);

  const {
    handleSave,
    loading,
    setLoading,
    handlePreSubmit,
    handleReset,
    planCreationError,
    setPlanCreationError,
    // planTemplate,
  } = useCreatePlanTemplate(handleCancel);
  useEffect(() => {
    if (!modalVisible) {
      handleReset();
    }
  }, [handleReset, modalVisible]);
  const handleSubmit = useCallback(async () => {
    const isSuccess = handlePreSubmit();
    if (isSuccess) {
      try {
        await handleSave();
      } catch {
        setLoading(false);
        handleCancel();
      }
    }
  }, [handlePreSubmit, handleSave, setLoading, handleCancel]);
  const handleToggleModal = useCallback(() => {
    const newValue = !modalVisible;
    setModalVisible(newValue);
    handleReset();
    if (!newValue) {
      handleClearModalStore();
    }
  }, [handleClearModalStore, handleReset, modalVisible]);
  return (
    <>

      <PlanModalWrapper
        visible={modalVisible}
        testId="CreateTemplate"
        onSetVisible={handleToggleModal}
        hide={hide}
        buttonText="Create Plan Template"
        modalTitle="Create Plan Template"
      >
        <WarnModal
          visible={planCreationError}
          testId="CreateTemplate_payment_order"
          onSetVisible={setPlanCreationError}
          header="The server encountered an error processing the request"
          helptext={`It doesn't appear to have affected your data, but we cannot save the plan template with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
          buttonText="Close"
          onSubmit={handleSave}
          submitButtonText="Try Again"
        />
        <Box>
          <Box dir="column" background="module" pad="spacing24" round="moduleRound">
            <PlanTemplate
              testId="CreateTemplate"
              parentId={modalStore.id}
            />

            <Box margin={{ top: 'spacing24' }}>
              <StatusWrapper status="pending">
                <Box
                  margin="spacing12"
                  pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
                  background={{ color: 'warningContainer' }}
                  border={{ size: '1px', color: 'warningBorder' }}
                  round="container2Round"
                >
                  <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
                    <WarningLabel
                      data-testId="CreateTemplate_warning_message"
                    >
                      Does everything look good? Please double check your selections.
                    </WarningLabel>
                  </Text>

                  <Box>
                    <Text
                      data-testId="CreateTemplate_confirm_message"
                      margin={{ bottom: 'spacing8' }}
                      color="textBody"
                      size="14px"
                    >
                      Once created, you can’t change the plan template or plan year.
                    </Text>
                  </Box>
                </Box>
              </StatusWrapper>
            </Box>
          </Box>
          <Footer
            testId="CreateTemplate_footer"
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Box>

      </PlanModalWrapper>
    </>
  );
};
export default CreateTemplateModal;
