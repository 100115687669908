import React, { useCallback, useMemo } from 'react';
import {
  AccountStatus,
  AccountTypeTextes,
  AppButton,
  Box,
  CardTypesEnum,
  CIPStatus,
  CipStatusCard,
  Enrollment,
  InfoOutlineIcon,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ENROLLMENT_ITEM_WIDTH_L, EnrollmentItem } from '@/modules/employee/Employee/EnrollmentBalances/components/EnrollmentItem';
import ExpensesBreakdown from '@/modules/employee/Employee/EnrollmentBalances/components/ExpensesBreakdown';
import { useExpensesStatisticsQuery } from '@/modules/employee/Employee/hooks/useExpensesStatistics.query';
import { getEnrollmentStatusColor } from '@/modules/employee/Employee/PersonalInformation/EmploymentStatusTimeline/utils';
import { Investors } from '@/modules/employee/Employee/queries/useGetInvestors.query';
import theme from '@/styles/theme';

import { ContributionAchievementsTracker } from './ContributionAchievmentsTracker/ContributionAchievementsTracker';
import {
  Header, HeaderWrapper, IconWrapper,
  Wrapper, } from './AccountDetails.styles';

const expensesBreakdownHight = '248px';

const TYPES = [
  'medical',
  'dental',
  'pharmacy',
  'vision',
  'other'];
export const fsaExpenses = TYPES.map((item) => ({
  key: item,
  title: _.capitalize(item),
  value: Math.floor(Math.random() * 1000),
}));
interface Props {
  id: string;
  currentMyAccount: Enrollment;
  investorsData?: Investors;
  hasEnrollmentWithDisplayName?: boolean;
}
export const LINK_ZENDESK_HSA = 'https://elevateaccounts.zendesk.com/hc/en-us/articles/7091893204763-Adding-a-Beneficiary-to-my-Health-Savings-Account-HSA-';

const AccountDetails: React.FC<Props> = ({
  id,
  currentMyAccount,
  investorsData,
  hasEnrollmentWithDisplayName,
}) => {
  const handleClick = useCallback(() => {
    if (LINK_ZENDESK_HSA) {
      window.open(LINK_ZENDESK_HSA, '_blank');
    }
  }, []);
  const statusColor = useMemo(() => getEnrollmentStatusColor(currentMyAccount.enrollmentStatus),
    [currentMyAccount.enrollmentStatus]);

  const { formattedData } = useExpensesStatisticsQuery({
    account_id: Number(id),
  });

  const content = useMemo(() => {
    if (currentMyAccount) {
      const { CIPStatus: currentCIPStatus, accountStatus } = currentMyAccount;
      if (accountStatus === AccountStatus.PENDING) {
        if (currentCIPStatus === CIPStatus.Pending || currentCIPStatus === CIPStatus.None) {
          return (
            <Box data-testId="verifying-empty-state" flex="grow">
              <CipStatusCard
                status="NEED_VERIFY"
                text={(
                  <>
                    <Text
                      color="textBody"
                      size="large"
                      textAlign="center"
                    >
                      We are verifying your identity to establish your account.
                    </Text>
                    <Text
                      color="textBody"
                      size="large"
                      textAlign="center"
                    >
                      It might take up to a few days.
                    </Text>
                  </>
                )}
              />
            </Box>
          );
        }
      }
      if (currentMyAccount.contributionMonitoringRequired) {
        return (
          <Box flex="grow">
            <ContributionAchievementsTracker currentAccount={currentMyAccount} />
          </Box>
        );
      }
      return (
        <Box flex="grow" height={{ max: expensesBreakdownHight }}>
          <ExpensesBreakdown
            options={formattedData?.expenses || []}
            totalExpense={formattedData?.totalExpenses || 0}
            inlineMode
            isWithoutHeader
          />
        </Box>
      );
    }
    return null;
  }, [currentMyAccount, formattedData.expenses, formattedData.totalExpenses]);

  return (
    <Wrapper data-testid="AccountDetails-wrapper">
      <HeaderWrapper>
        <Header>Account Details</Header>
      </HeaderWrapper>
      <Box direction="row">

        {currentMyAccount && (
          <EnrollmentItem
            enrollment={currentMyAccount}
            isDetailsMode
            statusColor={statusColor}
            investorsData={investorsData}
            wrapperStyle={{ marginRight: theme.spacings.s }}
            hasEnrollmentWithDisplayName={hasEnrollmentWithDisplayName}
            width={ENROLLMENT_ITEM_WIDTH_L}
          />
        )}
        {content && (
          <Box style={{flex: 1}}>
            {content}
          </Box>
        )}
        
      </Box>
      {
        currentMyAccount?.accountType === CardTypesEnum.HSA
        && (
          <Box direction="row" background="canvas" round="container1Round" elevation="default" border={{ size: 'small', color: 'border2' }} justify="between" align="center" pad={{ horizontal: 'm', vertical: 's' }} margin={{ top: 'm' }}>
            <Box direction="row">
              <IconWrapper direction="row" align="center" justify="center">
                <InfoOutlineIcon color="iconPrimary" />
              </IconWrapper>
              <Text margin={{ left: 'xs' }} size="large" color="textBody">Establish or Change Beneficiary Information</Text>
            </Box>

            <AppButton
              testId="beneficiary-designation"
              buttonType="quaternary"
              onClick={handleClick}
            >
              Beneficiary Designation Form
            </AppButton>
          </Box>
        )
      }
    </Wrapper>
  );
};

export default AccountDetails;
