import { useMutation, useQueryClient } from 'react-query';
import { dateValidatorByFormat } from '@common-fe/common-fe';
import _ from 'lodash';

// import dayjs from 'dayjs';
import { api, mock } from '@/api';
import {
  DEFAULT_DATE_FORMAT,
  EMPTY_FIELD_VALUE,
  NON_ASSIGNABLE_VALUE,
  NON_ASSIGNABLE_VALUE_PAYLOAD,
} from '@/common/constants';
import PATHS from '@/common/paths';
import {
  CommunicationCategoryPayload,
  CommunicationChannelPayload,
  StatementSettings,
} from '@/modules/employer/components/SetupEmployer/Communication/Communication.types';
import {
  AccountUsagePayload,
  daysOfWeek,
  FrequencyOptions,
} from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { QUERY_KEY } from '@/modules/employer/hooks/useEmployersList.query';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { AllowedPaymentMethods, EmployerDto, PartnerMobileApp } from '@/modules/employer/types';
import { getYesNoBooleanValue } from '@/utils';
import { yesOrNoToBoolean } from '@/utils/handlers';

import { EmailConfigurationPayload } from '../Communication/EmailConfiguration/stores/useEmailConfiguration.store';
import { LinkManagementPayload } from '../LinkManagement/useLinkManagementStore.store';
import { MobileAppCustomizationPayload } from '../MobileAppCustomization/store/useMobileAppCustomization.store';
import { CustomReportsPayload } from '../Reports/CustomReports/stores/useCustomReports.store';
import {
  AddressPayload,
  AIAdjudication,
  BankInfo,
  CardSetupPayload,
  ClaimsSettings,
  EmployeeIdPayload,
  EmployerPayload,
  ExpressSetupLinksPayload,
  FileManagerSettings,
  FundingModelPayload,
  GeneralInformationPayload,
  HelpInfoPayload,
  MultiFactorAuthenticationValues,
  OrphanConfigurationPayload,
  TechnicalSettings,
} from '../SetupEmployer.types';

const getDateIsoString = (dateValue?: string | null) => {
  // for now work only with date format 'MM/DD/YYYY'
  if (!dateValue) return null;
  const [month, day, year] = dateValue.split('/');
  const date = new Date(`${year}-${month}-${day}T00:00:00Z`);
  const isoString = date.toISOString();

  return isoString;
};

const getBankId = (banks: BankInfo[], value: string) => banks.find((bank: BankInfo) => bank.id === value)?.id;

mock.onPost(PATHS.EMPLOYER).reply(200, {});

const COUNTRY_ID = 1;
const DAILY_FREQUENCY = 1;
const ADDRESS_TYPE = 'PO_BOX';

const getFormattedAccountUsage = (store: AccountUsagePayload, banksList: BankInfo[], data?: EmployerDto) => ({
  accounts: {
    ...(store.administratorFeesBank && store.administratorFeesBank !== NON_ASSIGNABLE_VALUE
      ? { external_administrator_fees_account: getBankId(banksList, store.administratorFeesBank) }
      : {}),
    ...(store.administratorFeesBank && store.administratorFeesBank === NON_ASSIGNABLE_VALUE
      ? { external_administrator_fees_account: NON_ASSIGNABLE_VALUE_PAYLOAD }
      : {}),
    ...(store.payrollContributionBank && store.payrollContributionBank !== NON_ASSIGNABLE_VALUE
      ? {
        external_payroll_contributions_account: getBankId(banksList, store.payrollContributionBank),
      }
      : {}),
    ...(store.payrollContributionBank && store.payrollContributionBank === NON_ASSIGNABLE_VALUE
      ? {
        external_payroll_contributions_account: NON_ASSIGNABLE_VALUE_PAYLOAD,
      }
      : {}),
    ...(store.fundingReplenishmentBank && store.fundingReplenishmentBank !== NON_ASSIGNABLE_VALUE
      ? { external_replenishment_account: getBankId(banksList, store.fundingReplenishmentBank) }
      : {}),
  },
  methods: {
    external_administrator_fees_account_method:
      store.administratorFeesMethod || data?.overridableProperties?.externalAdministratorFeesAccountMethod,
    external_payroll_contributions_account_method:
      store.payrollContributionMethod ||
      data?.overridableProperties?.externalPayrollContributionsAccountMethod,
    external_replenishment_account_method:
      store.fundingReplenishmentMethod || data?.overridableProperties?.externalReplenishmentAccountMethod,
  },
});
const URL_PREFIX = '.com/';
interface ReimbursementPayload {
  is_reimbursement_allowed?: boolean;
  allowed_payment_methods?: AllowedPaymentMethods[];
  is_bill_pay_allowed?: boolean;
}

interface Sections {
  reports?: Partial<CustomReportsPayload>;
}

export const generalInformationPayload = (
  banksList: BankInfo[],
  generalInfoData: GeneralInformationPayload | null,
  address: AddressPayload | null,
  employer: EmployerPayload | null,
  communicationChannelStore: Partial<CommunicationChannelPayload>,
  communicationCategoryStore: CommunicationCategoryPayload,
  accountUsageStore: AccountUsagePayload,
  data?: EmployerDto,
  employeeId?: EmployeeIdPayload | null,
  fundingModelPayload?: FundingModelPayload | null,
  cardsSetup?: CardSetupPayload | null,
  organizationStatusType?: EmployerStatus,
  hasAccessToBankInfo?: boolean,
  reimbursementPayload?: ReimbursementPayload,
  helpInfo?: HelpInfoPayload | null,
  primaryContactId?: string | number | null,
  technicalSettings?: TechnicalSettings,
  fileManagerSettings?: FileManagerSettings,
  emailConfiguration?: EmailConfigurationPayload,
  statementsSettingsStore?: StatementSettings,
  aiAdjudicationStore?: AIAdjudication,
  MultiFactorAuthenticationStore?: MultiFactorAuthenticationValues,
  mobileAppCustomizationState?: MobileAppCustomizationPayload,
  claimsSettings?: ClaimsSettings,
  orphanConfiguration?: Partial<OrphanConfigurationPayload>,
  linkManagement?: LinkManagementPayload,
  expressSetupLinks?: ExpressSetupLinksPayload,
  sections?: Sections
) => {
  const { reports } = sections || {};
  const allowAdminContribution = accountUsageStore.allowAdminContribution
    ? getYesNoBooleanValue(accountUsageStore.allowAdminContribution)
    : data?.overridableProperties?.allowAdminContribution;
  const allowAssumedContribution =
    accountUsageStore?.allowAssumedContribution || data?.overridableProperties?.allowAssumedContribution;
  const allowDependentCard =
    data?.overridableProperties?.allowDependentCard === null && !cardsSetup?.allowDependentCard
      ? null
      : cardsSetup?.allowDependentCard;

  const formatedURL = emailConfiguration?.customEmailLogo
    ? _.last(emailConfiguration?.customEmailLogo?.split(URL_PREFIX)) || ''
    : '';
  const emailConfigurationFields = _.pickBy({
    email_logo_type: emailConfiguration?.emailLogoType,
    email_illustration_id: emailConfiguration?.brandingIllustration
      ? _.toNumber(emailConfiguration?.brandingIllustration)
      : null,
    // is_default_link: emailConfiguration?.isDefaultLinks,
    email_theme: emailConfiguration?.emailTheme,
    email_logo_path: formatedURL,
  });
  const linkManagementFields = _.pickBy({
    about_us_link: linkManagement?.aboutUsLink,
    email_domain: linkManagement?.domainAddress,
    terms_and_conditions_link: linkManagement?.termAndConditionsLink,
    privacy_policy_link: linkManagement?.privacyPolicyLink,
    pricing_consent: linkManagement?.pricingConsent,
  });

  const accountUsageProperties = hasAccessToBankInfo
    ? getFormattedAccountUsage(accountUsageStore, banksList, data)
    : null;
  const isExternalAccountAnable =
    accountUsageStore.administratorFeesBank === NON_ASSIGNABLE_VALUE ||
    accountUsageStore.payrollContributionBank === NON_ASSIGNABLE_VALUE ||
    accountUsageStore.fundingReplenishmentBank === NON_ASSIGNABLE_VALUE;
  const isPrefundInitialDelayDateValid =
    !accountUsageStore?.migrationPreFundStartDate ||
    dateValidatorByFormat(accountUsageStore?.migrationPreFundStartDate, DEFAULT_DATE_FORMAT);
  const prefundInitialDelayDate = isPrefundInitialDelayDateValid
    ? getDateIsoString(accountUsageStore?.migrationPreFundStartDate)
    : null;

  const isCardProcessingStartDateValid =
    !cardsSetup?.cardProcessingStartDate ||
    dateValidatorByFormat(cardsSetup?.cardProcessingStartDate, DEFAULT_DATE_FORMAT);
  const cardProcessingStartDate = isCardProcessingStartDateValid
    ? getDateIsoString(cardsSetup?.cardProcessingStartDate)
    : null;

  return {
    organization_status_type: organizationStatusType,
    name: employer?.employerName,
    external_identifier: data?.externalIdentifier,
    grandparent_id: data?.grandparentId,
    type: data?.type,
    parent_id: data?.parentId,
    short_name: generalInfoData?.dba || null,

    addresses: [
      {
        id: data?.addresses?.[0]?.id,
        line1: address?.street,
        line2: address?.street_2 || null,
        city: address?.city,
        state: address?.state,
        zipcode: address?.zipCode,
        preferred: true,
        country_id: COUNTRY_ID, // constant value
        address_type: ADDRESS_TYPE, // constant value
      },
    ],

    normal_properties: {
      ...(isExternalAccountAnable
        ? {
          external_account_enable: true,
        }
        : {}),
      tax_id: generalInfoData?.taxID || null,
      ...(accountUsageProperties && Object.keys(accountUsageProperties?.accounts).length
        ? { ...accountUsageProperties?.accounts }
        : {}),
      account_manager_id: generalInfoData?.accountManagerId || null,
      implementation_manager_id: generalInfoData?.implementationManagerId || null,
      invoicing_contact_id: primaryContactId || null,
    },
    organization_phone: generalInfoData?.phoneNumber
      ? {
        number: generalInfoData?.phoneNumber,
        phone_extension: generalInfoData?.phoneNumberExtension || null,
      }
      : null,
    business_entity_type: generalInfoData?.businessType || null,
    overridable_properties: {
      allow_manual_contribution: allowAdminContribution,
      allow_scheduled_contribution: allowAssumedContribution,
      participant_portal_link: data?.overridableProperties?.participantPortalLink,
      admin_portal_link: data?.overridableProperties?.adminPortalLink,
      external_link: expressSetupLinks?.externalLink,
      is_external_link: expressSetupLinks?.isExternalLink,
      number_of_days_before_plan_start: cardsSetup?.numberOfDaysBeforePlanStart || null,
      card_offered: cardsSetup?.cardOffered,
      allow_dependent_card: allowDependentCard,
      bin_setup: cardsSetup?.bin || null,
      prin_setup: cardsSetup?.prin || data?.overridableProperties?.prin || null,
      hsa_custodian_id: generalInfoData?.hsaCustodianId || null,
      id_source_id: employeeId?.eeid || data?.overridableProperties?.idSourceId,

      is_custom_reporting_field_1_enabled: reports?.customReportDisplayedField1,
      is_custom_reporting_field_2_enabled: reports?.customReportDisplayedField2,
      is_custom_reporting_field_3_enabled: reports?.customReportDisplayedField3,
      is_custom_reporting_field_4_enabled: reports?.customReportDisplayedField4,
      is_custom_reporting_field_5_enabled: reports?.customReportDisplayedField5,

      custom_reporting_field_1_name: !reports?.customReportDisplayedField1 ? null : reports?.customReportField1 || null,
      custom_reporting_field_2_name: !reports?.customReportDisplayedField2 ? null : reports?.customReportField2 || null,
      custom_reporting_field_3_name: !reports?.customReportDisplayedField3 ? null : reports?.customReportField3 || null,
      custom_reporting_field_4_name: !reports?.customReportDisplayedField4 ? null : reports?.customReportField4 || null,
      custom_reporting_field_5_name: !reports?.customReportDisplayedField5 ? null : reports?.customReportField5 || null,

      customer_service_number: helpInfo?.customerServiceNumber || null,
      customer_service_url_name: helpInfo?.customerServiceUrlName || null,
      customer_service_url_link: helpInfo?.customerServiceUrlLink || null,
      customer_service_email: helpInfo?.customerServiceEmail || null,
      customer_service_is_enabled:
        typeof helpInfo?.customerServiceIsEnabled === 'boolean' ? helpInfo?.customerServiceIsEnabled : null,
      ...(cardsSetup?.cardURL === EMPTY_FIELD_VALUE
        ? {}
        : {
          card_url: cardsSetup?.cardURL || null,
        }),
      ...(cardsSetup?.cardEmployerName === EMPTY_FIELD_VALUE
        ? {}
        : {
          card_employer_name: cardsSetup?.cardEmployerName || null,
        }),
      ...(cardsSetup?.cardPhoneNumber === EMPTY_FIELD_VALUE
        ? {}
        : {
          card_phone_number: cardsSetup?.cardPhoneNumber || null,
        }),
      ...(isCardProcessingStartDateValid
        ? {
          card_processing_start_date: cardProcessingStartDate,
        }
        : {}),
      electronic_funding_mode_id:
        fundingModelPayload?.electronicFundingReplenishment === ''
          ? null
          : fundingModelPayload?.electronicFundingReplenishment ||
          data?.overridableProperties?.electronicFundingModeId ||
          null,
      check_funding_model:
        fundingModelPayload?.checkWriting === ''
          ? null
          : fundingModelPayload?.checkWriting || data?.overridableProperties?.checkFundingModel || null,
      ...(generalInfoData?.themeId
        ? {
          theme_id: generalInfoData?.themeId,
        }
        : {}),
      sso_only: Boolean(generalInfoData?.ssoOnly),
      allow_employees_to_edit_address: yesOrNoToBoolean(generalInfoData?.allowEditAddress),
      url: generalInfoData?.url || null,
      communication_channels: communicationChannelStore.communicationChannel || [],
      email_template_type:
        communicationChannelStore.emailTemplate !== '' ? communicationChannelStore.emailTemplate : null,
      organization_communication_categories: communicationCategoryStore.communicationCategory || [],
      ...(fileManagerSettings?.isFileManagerAccessEnabled
        ? {
          is_file_manager_access_enabled: fileManagerSettings?.isFileManagerAccessEnabled,
          is_file_auto_post_enabled: yesOrNoToBoolean(fileManagerSettings?.isFileAutoPostEnabled),
          allowed_file_types: fileManagerSettings?.allowedFileTypes,
        }
        : {
          is_file_manager_access_enabled: fileManagerSettings?.isFileManagerAccessEnabled,
          is_file_auto_post_enabled: false,
          allowed_file_types: [],
        }),

      file_location: !fileManagerSettings?.isSendFiles ? '' : fileManagerSettings?.fileLocation,
      error_report_location: fileManagerSettings?.isSameErrorReportLocation
        ? null
        : fileManagerSettings?.errorReportLocation,

      ...(accountUsageStore.holdPeriod
        ? {
          payroll_contributions_hold_period: _.toNumber(accountUsageStore.holdPeriod),
        }
        : {}),
      ...(technicalSettings?.sessionTimeoutWarn
        ? {
          session_timeout_warn: technicalSettings?.sessionTimeoutWarn,
        }
        : {}),
      ...(technicalSettings?.sessionTimeoutLogout
        ? {
          session_timeout_logout: technicalSettings?.sessionTimeoutLogout,
        }
        : {}),
      ...(claimsSettings?.claimsAssignmentNumber
        ? {
          claims_assignment_number: _.toNumber(claimsSettings.claimsAssignmentNumber),
        }
        : {}),
      ...(accountUsageProperties && Object.keys(accountUsageProperties.methods).length
        ? { ...accountUsageProperties.methods }
        : {}),
      replenishment_frequency_type: accountUsageStore.replenishmentFrequencyType,
      ...(accountUsageStore.replenishmentFrequencyType === FrequencyOptions.DAILY
        ? {
          replenishment_day_number: DAILY_FREQUENCY,
        }
        : {
          replenishment_day_number:
              accountUsageStore.replenishmentFrequencyType === FrequencyOptions.WEEKLY &&
              accountUsageStore.replenishmentDayOfWeek
                ? daysOfWeek[accountUsageStore.replenishmentDayOfWeek]
                : accountUsageStore.replenishmentCustomDay ||
                  data?.overridableProperties?.replenishmentDayNumber,
        }),
      ...(accountUsageStore.preFundPercent
        ? {
          prefund_replenishment_percent: _.toNumber(accountUsageStore.preFundPercent),
        }
        : {}),
      ...(accountUsageStore.preFundMinimumValue
        ? {
          prefund_replenishment_min_value: _.toNumber(accountUsageStore.preFundMinimumValue),
        }
        : {}),
      ...(accountUsageStore.preFundMinimumThreshold
        ? {
          prefund_replenishment_creep_min: _.toNumber(accountUsageStore.preFundMinimumThreshold),
        }
        : {}),
      ...(accountUsageStore.preFundElectionChangePercentage
        ? {
          prefund_replenishment_creep_percent: _.toNumber(
            accountUsageStore.preFundElectionChangePercentage
          ),
        }
        : {}),
      ...(accountUsageStore?.eeContributionsHoldPeriod
        ? {
          ee_contributions_hold_period: _.toNumber(accountUsageStore?.eeContributionsHoldPeriod),
        }
        : {}),
      ...(isPrefundInitialDelayDateValid
        ? {
          prefund_initial_delay_date: prefundInitialDelayDate,
        }
        : {}),
      ...reimbursementPayload,
      ...emailConfigurationFields,
      orphan_organization_id: orphanConfiguration?.orphanEmployer
        ? _.toNumber(orphanConfiguration?.orphanEmployer)
        : undefined,
      orphan_start_timing_days: orphanConfiguration?.startOrphanProcessDays
        ? _.toNumber(orphanConfiguration?.startOrphanProcessDays)
        : undefined,
      is_organization_orphan: orphanConfiguration?.isEmployerOrphan,
      ...linkManagementFields,
      statement_type: statementsSettingsStore?.statementType,
      statement_frequency_type: statementsSettingsStore?.statementFrequencyType,
      ai_adjudication: aiAdjudicationStore?.aiAdjudication,
      ai_confidence_level: aiAdjudicationStore?.aiConfidenceLevel,
      authentication_type_id:
        MultiFactorAuthenticationStore?.authenticationTypeId ||
        data?.overridableProperties?.authenticationTypeId,
      account_lockout_time:
        MultiFactorAuthenticationStore?.accountLockoutTime || data?.overridableProperties?.accountLockoutTime,
      ...(mobileAppCustomizationState?.participantMobileApp
        ? { partner_mobile_app: mobileAppCustomizationState?.participantMobileApp }
        : {}),
      ...(mobileAppCustomizationState?.googlePlayLink &&
        mobileAppCustomizationState?.participantMobileApp === PartnerMobileApp.PARTNER_OWN_APP
        ? { partner_app_android_link: mobileAppCustomizationState?.googlePlayLink }
        : {}),
      ...(mobileAppCustomizationState?.appStoreLink &&
        mobileAppCustomizationState?.participantMobileApp === PartnerMobileApp.PARTNER_OWN_APP
        ? { partner_app_ios_link: mobileAppCustomizationState?.appStoreLink }
        : {}),
      ...(mobileAppCustomizationState?.mobileAppTheme &&
        mobileAppCustomizationState?.participantMobileApp === PartnerMobileApp.ELEVATE_APP
        ? { mobile_app_theme_id: mobileAppCustomizationState?.mobileAppTheme }
        : {}),
    },
  };
};

export const SAVE_EMPLOYER = 'saveEmployer';

const useSaveEmployerQuery = (employerId: string) => {
  const queryClient = useQueryClient();
  const { refetch } = useGetEmployer();
  const { mutateAsync } = useMutation(
    [SAVE_EMPLOYER, employerId],
    (newValue: object) => api.put(`${PATHS.ORGANIZATIONS}/${employerId}`, newValue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
        queryClient.invalidateQueries(PATHS.GET_ORGANIZATION_BY_ID(employerId));
        refetch();
        // mock api
      },
    }
  );

  const save = async (value: object) => {
    await mutateAsync(value);
  };
  return {
    save,
  };
};

export default useSaveEmployerQuery;
