import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { DATETIME_DOTS_FORMAT } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useDownloadReportQuery } from '@/modules/employer/components/Reports/queries/useDownloadReport.query';
import { ReportTypes } from '@/modules/employer/components/Reports/report.types';
import downloadFileByUrl from '@/utils/handlers/downloadFileByUrl';

interface Props {
  invoiceId?: string;
  currentReportType: ReportTypes | null;
}

export const useDownloadInvoiceReport = ({
  invoiceId,
  currentReportType,
}: Props) => {
  const [isStartDownload, setIsStartDownload] = useState(false);
  const [isServerError, setIsServerError] = useState(false);

  const { observingOrganization: { id: orgId }} = useCurrentOrganization();
  const currentCustomExportParams = useMemo(() => ({
    'invoice-ids': invoiceId || '',
  }), [invoiceId]);

  const { data, fileName, remove, isLoading: reportLoading, isError } = useDownloadReportQuery(
    orgId as string,
    isStartDownload,
    currentReportType,
    currentCustomExportParams,
    invoiceId,
  );

  useEffect(() => {
    if (data && isStartDownload) {
      const currentDate = dayjs(Date.now()).format(DATETIME_DOTS_FORMAT);
      const fileExtension = 'csv';
      const currentFileName = `${fileName}_${currentDate}.${fileExtension}`;
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      downloadFileByUrl([url], currentFileName);
      setIsStartDownload(false);
      remove();
    }
  }, [data, fileName, isStartDownload, remove]);

  useEffect(() => {
    if (isError) {
      setIsServerError(true);
    }
  }, [isError]);

  return {
    setIsStartDownload,
    reportLoading,
    isServerError,
    setIsServerError,
  };
};
