import React, { useMemo, useState } from 'react';
import {
  ActionCard, AppButton,
  Box,   PlanPendingCheckedIcon, PlanPendingIcon,
  SiblingBoxesWithSpacing, SuccessModal,
  Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import { usePlanNameStore } from '../../PlanDefinition/PlanName/stores';
import { useSubmitFormStore } from '../../stores';
import SwitchToEditMode from '../SwitchToEditMode';

interface Props {
  onSave: () => void;
  viewMode?: boolean;
  isTemplateMode?:boolean;

  saveButtonRef?: React.RefObject<HTMLButtonElement>;
  reviewButtonRef?:React.RefObject<HTMLButtonElement>;

  errorMessages: string[];
  successMessages: string[];
  pendingMessages: string[];
}
const SavePlan: React.FC<Props> = ({
  onSave, viewMode, isTemplateMode,
  errorMessages,
  successMessages,
  pendingMessages,
  saveButtonRef,
}) => {

  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const [saveDraftModal, setSaveDraftModal] = useState(false);
  const planName = usePlanNameStore((state) => state.state.planName);

  // const pendingDisabled = useMemo(() => {
  //   if (saveValidate) {
  //     return !_.isEmpty(errorMessages);
  //   }
  //   return false;
  // }, [errorMessages, saveValidate]);
  const CardIcon = useMemo(() => {
    if (_.isEmpty(errorMessages) && !_.isEmpty(successMessages) && saveValidate) {
      return (
        <PlanPendingCheckedIcon size="xxlarge" color="iconAccent" />
      );
    }
    return (
      <PlanPendingIcon size="xxlarge" color="iconAccent" />
    );
  }, [errorMessages, saveValidate, successMessages]);
  return (
    <>
      <SuccessModal
        visible={saveDraftModal && _.isEmpty(errorMessages)}
        onSetVisible={setSaveDraftModal}
        header={`${planName} Draft Saved`}
        buttonText="Close Plan Config"
      />
      <Access
        accessRequirements={[
          {
            permission: Permissions.EDIT_DRAFT_PLAN,
          },
        ]}
      >
        <ActionCard
          footer={viewMode ? <SwitchToEditMode isTemplateMode={isTemplateMode} /> : null}
          submitted={saveValidate}
          title={isTemplateMode ? 'Save the Template' : 'Save the Plan'}
          modulesTitle="Required attributes"
          errorMessages={viewMode ? [] : errorMessages}
          successMessages={viewMode ? [] : successMessages}
          pendingMessages={viewMode ? [] : pendingMessages}
          icon={CardIcon}
        >
          <Box
            direction="row"
            align="center"
            flex="grow"
            pad={{
              left: 'spacing8',
              right: 'spacing8',
            }}
          >
            <Box
              flex="grow"
              direction="row"
              justify="stretch"
              align="stretch"

            >
              <SiblingBoxesWithSpacing fill>
                <AppButton
                  buttonType="secondary"
                  disabled={viewMode}
                  onClick={onSave}
                  ref={saveButtonRef}
                  size="L"
                  width="100%"
                >
                  <Text color={viewMode ? 'textDisabled' : 'textBody'}>Save to Draft</Text>
                </AppButton>
              </SiblingBoxesWithSpacing>
            </Box>
          </Box>
        </ActionCard>
      </Access>
    </>
  );
};
export default SavePlan;
