import React from 'react';
import { Box, Field, FieldTypes, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const FIELD_WIDTH = '218px';

export const LockboxFieldWrapper = styled(Box)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing12};
  }
`;

const LockboxForm: React.FC<{ fields: Field[] }> = ({ fields }) => (
  <Box>
    {fields?.map((field) => (
      <LockboxFieldWrapper key={field?.name} direction="row">
        <Box width={{ min: FIELD_WIDTH, max: FIELD_WIDTH }}>
          <Inscription
            color="textSecondary"
          >
            {field?.label}
          </Inscription>
        </Box>
        {
          field.type === FieldTypes.Text ? (
            <Inscription
              weight={700}
              color="textBody"
              margin={{ left: 'spacing8' }}
            >
              {field?.value}
            </Inscription>
          ) : (
            field?.value
          )
        }
      </LockboxFieldWrapper>
    ))}
  </Box>
);

export default LockboxForm;
