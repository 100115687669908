import { ALL_OPTION } from '@/common/constants';
import { OptionKey } from '@/common/types';

export const checkIsAllOptionSelected = (
  options: OptionKey[],
) => options.length === 0 || options[options.length - 1] === ALL_OPTION.value;

export const stateAllToggling = (options: OptionKey[]) => {
  const isStatusAllSelected = options[options.length - 1] === ALL_OPTION.value;

  if (options.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = options.length === 2 && options[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return options.filter((status) => status !== ALL_OPTION.value);
  }

  return options;
};

export default stateAllToggling;
