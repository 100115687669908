import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import useHealthPlanClaimsFields from './hooks/useHealthPlanClaimsFields';
import useEmployeeClaimAutopayStore, { EmployeeClaimAutopayPayload } from './stores/useEmployeeClaimAutopay.store';

interface Props {
  isEditMode?: boolean;
}

const HealthPlanClaims: React.FC<Props> = ({ isEditMode }) => {
  const fields = useHealthPlanClaimsFields(isEditMode);

  const handleSetState = useEmployeeClaimAutopayStore((state) => state.setState);

  const { setValues, values } = useDebounceState<EmployeeClaimAutopayPayload>();

  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        formTitle="Health Plan Claims"
        fields={fields}
        editMode
        onChangeValues={setValues}
        isDisabledMode={!isEditMode}
        isThinMode
      />
    </Box>
  );
};

export default HealthPlanClaims;
