import React from 'react';
import {
  Box, ContextFlexForm, FieldValues,
  FormContext, } from '@common-fe/common-fe';

import { CopayErrors, CopayGroupPayload } from '../../CopayAdjudication.constant';

import { useCopayBasicPropertiesFields } from './hooks';

interface Props {
  formContext: FormContext;
  isSubmitted?: boolean;
  isViewMode?: boolean;
  isEditMode?: boolean;
  currentErrors?:CopayErrors;
  defaultValues?: CopayGroupPayload;
  onChangeValues: (values: FieldValues) => void;
}
const CopayBasicPropertiesForm:React.FC<Props> = ({
  formContext, isSubmitted, isViewMode, onChangeValues, defaultValues, currentErrors, isEditMode,
}) => {
  const fields = useCopayBasicPropertiesFields({
    defaultValues, isViewMode: isViewMode && !isEditMode, currentErrors, isEditMode,
  });
  return (

    <Box
      background="canvas"
      key={currentErrors?.name}
      round="container1Round"
      border={{ color: 'border2', size: 'small' }}
      // pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
      elevation="default"
      margin={{ bottom: 'l' }}

    >
      <ContextFlexForm
        formTitle="Basic Properties"
        editMode={isEditMode || isViewMode === undefined}
        isModalType
        showError={isSubmitted}
        formContext={formContext}
        onChangeValues={onChangeValues}
        isThinMode
        fields={fields}
        contentStyles={{ margin: 0 }}

      />
    </Box>
  );
};

export default CopayBasicPropertiesForm;
