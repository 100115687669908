import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { useHealthPlanByIdQuery } from '../queries';

interface RouterParams {
  id: string;
}
export default () => {
  const { params: { id } } = useRouteMatch<RouterParams>();
  const { data } = useHealthPlanByIdQuery(id);
  const parentId = useMemo(() => _.get(data, 'parent_id'), [data]);
  return parentId;
};
