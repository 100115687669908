import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { DefaultFields } from '@/common/types';
import { SourceIds } from '@/modules/employee/employee.types';
// import { REGEXPS } from '@/common';
// import { useSSNMask } from '@/modules/core/hooks';
// import { SSN_INVALID_TEXT } from '@/common/constants';
import { EmployerStatuses } from '@/modules/employee/Employee/Employee.types';

import { Subsidiary } from '../queries/useSubsidiaries.query';
import useEmployerInfoStore, { EmployerInfoPayload } from '../stores/useEmployerInfo.store';

interface Props {
  isEditMode?: boolean,
  subsidiaries?: Subsidiary[],
  defaultFields?: Partial<EmployerInfoPayload>,
  isSubsidiary?: boolean,
  ssnField?: Field;
  payrollGroupField?: Field;
  hasAccessToEditPrimaryId?: boolean;
}

const useEmployerInfoFields = ({
  isEditMode,
  subsidiaries,
  defaultFields,
  isSubsidiary,
  ssnField,
  payrollGroupField,
  hasAccessToEditPrimaryId,
}: Props) => {
  // const ssn = useEmployerInfoStore((state) => state.state.ssn);
  // const currentSSNMask = useSSNMask(ssn);
  const { sourceState, state: { employerStatus } } = useEmployerInfoStore((state) => state);
  const isStatusChanged = useMemo(
    () => sourceState?.employerStatus !== employerStatus,
    [sourceState, employerStatus],
  );
  const hasActiveSubsidiaries = useMemo(() => !!subsidiaries?.length, [subsidiaries]);

  const isParentOrganizationSubsidiary = useMemo(
    () => !!subsidiaries?.find((subsidiary) => (subsidiary.id === defaultFields?.organizationId)),
    [subsidiaries, defaultFields],
  );
  const sourceId = useEmployerInfoStore((state) => state.state.sourceId);

  const isEEIDRequired = useMemo(() => sourceId === SourceIds.EEID, [sourceId]);
  const isPEIDRequired = useMemo(() => sourceId === SourceIds.PEID, [sourceId]);

  const fields = useMemo(() => [

    {
      name: 'employerName',
      type: FieldTypes.Text,
      label: 'Employer name',
      disabled: true,
      value: defaultFields?.employerName,
    },
    {
      name: 'partnerEmployeeId',
      type: FieldTypes.Text,
      label: 'Partner Employee ID',
      showRequireIcon: isPEIDRequired,
      validator: isPEIDRequired ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING,
      disabled: !hasAccessToEditPrimaryId || !isEditMode,
      value: defaultFields?.partnerEmployeeId,
    },
    {
      name: 'employerEmployeeId',
      type: FieldTypes.Text,
      label: 'Employer Employee ID',
      showRequireIcon: isEEIDRequired,
      validator: isEEIDRequired ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING,
      disabled: !hasAccessToEditPrimaryId || !isEditMode,
      value: defaultFields?.employerEmployeeId,
    },
    {
      name: 'internalId',
      type: FieldTypes.Text,
      label: 'System ID',
      disabled: true,
      placeholder: 'Enter Internal ID',
      value: defaultFields?.uniqueId,
    },
    ...ssnField? [ssnField] :[],
    ...payrollGroupField? [payrollGroupField] :[],
    // ...defaultFields?.payrollGroupId ? [{
    //   name: 'payrollGroupCode',
    //   type: FieldTypes.Text,
    //   label: 'Payroll Group Code',
    //   disabled: true,
    //   value: defaultFields?.payrollGroupId,
    // }] : [],
    ...defaultFields?.validFrom ? [{
      name: 'payrollGroupEffectiveDate',
      type: FieldTypes.Text,
      label: 'Payroll Group Effective Date',
      disabled: true,
      value: dayjs(defaultFields?.validFrom).format(DEFAULT_DATE_FORMAT),
    }] : [],
    isParentOrganizationSubsidiary ? {
      name: 'subsidiary',
      type: FieldTypes.Dropdown,
      label: 'Subsidiary',
      singleMode: true,
      disabled: !isEditMode || !hasActiveSubsidiaries || isSubsidiary,
      value: defaultFields?.organizationId,
      placeholder: !hasActiveSubsidiaries ? 'No active subsidiaries' : 'Select subsidiary',
      options: subsidiaries?.map((subsidiary) => ({
        key: subsidiary.id,
        value: subsidiary.name,
      })),
      validator: yup.string().trim(),
    } : {
      name: 'parentOrganizationId',
      type: FieldTypes.Dropdown,
      label: 'Subsidiary',
      singleMode: true,
      disabled: !isEditMode || !hasActiveSubsidiaries || isSubsidiary,
      placeholder: !hasActiveSubsidiaries ? 'No active subsidiaries' : 'Select subsidiary',
      options: subsidiaries?.map((subsidiary) => ({
        key: subsidiary.id,
        value: subsidiary.name,
      })),
      validator: yup.string().trim(),
    },
    {
      name: 'employerStatus',
      type: FieldTypes.Dropdown,
      label: 'Status',
      singleMode: true,
      disabled: !isEditMode,
      placeholder: 'Select status',
      options: EmployerStatuses,
      validator: yup.string().trim(),
    },
    {
      name: 'statusEffectiveDate',
      type: FieldTypes.Date,
      isManualDateInput: true,
      label: 'Status effective date',
      disabled: !isEditMode || !isStatusChanged,
      placeholder: 'Enter status effective date',
      validator: yup.string().trim(),
    },
    // {
    //   name: 'payrollGroup',
    //   type: FieldTypes.Dropdown,
    //   label: 'Payroll group',
    //   disabled,
    // singleMode: true,
    //   placeholder: 'Select payroll group',
    //   options: [{
    //     key: 'weekly',
    //     value: 'Weekly',
    //   }, {
    //     key: 'monthly',
    //     value: 'monthly',
    //   }],
    //   validator: yup.string().trim(),
    // },
    {
      name: 'hireDate',
      type: FieldTypes.Date,
      isManualDateInput: true,
      label: 'Hire date',
      disabled: !isEditMode,
      placeholder: 'Enter hire date',
      validator: yup.string().trim(),
    },
    // {
    //   name: 'terminationDate',
    //   type: FieldTypes.Date,
    //   isManualDateInput: true,
    //   label: 'Termination date',
    //   disabled: !isEditMode,
    //   placeholder: 'Enter termination date',
    //   validator: yup.string().trim(),
    // },

  ], [
    isPEIDRequired,
    isEEIDRequired,
    ssnField,
    defaultFields,
    isEditMode,
    subsidiaries,
    isStatusChanged,
    hasActiveSubsidiaries,
    isSubsidiary,
    isParentOrganizationSubsidiary,
    hasAccessToEditPrimaryId,
    payrollGroupField,
  ]);
  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: item?.value || defaultValues[item?.name || ''] || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);

  return formatedFields;
};

export default useEmployerInfoFields;
