import React, { useMemo } from 'react';
import { Box, capitalizeFirstLetter, Circle, Inscription, ListItemType } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, EMPTY_FIELD_VALUE } from '@/common/constants';
import { EmployeeCardsListItem, MatchedEmployee } from '@/modules/employer/components/Lockbox/lockbox.types';
import { formatCurrency } from '@/utils';

const Status = ({ status }: { status: string }) => (
  <Box direction="row" align="center">
    <Inscription margin={{ right: 'spacing8' }}>
      {capitalizeFirstLetter(status)}
    </Inscription>
    <Circle status={status} />
  </Box>
);

interface Props {
  matchedEmployees?: MatchedEmployee[];
}

const useEmployeeCardsList = ({ matchedEmployees }: Props) => {
  const fields: EmployeeCardsListItem[] = useMemo(() => {
    if (!matchedEmployees?.length) {
      return [];
    }

    return matchedEmployees?.map((matchedEmployee) => ({
      id: `${matchedEmployee?.employeeId}`,
      warnings: matchedEmployee?.labelTypes,
      fields: [
        {
          key: 'employee',
          type: ListItemType.Text,
          title: 'Employee',
          value: matchedEmployee?.employeeName || EMPTY_FIELD_VALUE,
        },
        {
          key: 'employer',
          type: ListItemType.Text,
          title: 'Employer',
          value: matchedEmployee?.organizationName || EMPTY_FIELD_VALUE,
        },
        {
          key: 'hsaAccountNumber',
          type: ListItemType.Text,
          title: 'HSA account number',
          value: matchedEmployee?.accountNumber || EMPTY_FIELD_VALUE,
        },
        {
          key: 'ssn',
          type: ListItemType.Text,
          title: 'SSN',
          value: matchedEmployee?.ssn || EMPTY_FIELD_VALUE,
        },
        {
          key: 'employmentStatus',
          type: ListItemType.Node,
          title: 'Employment status',
          node: matchedEmployee?.employmentStatus
            ? <Status status={matchedEmployee?.employmentStatus} />
            : EMPTY_FIELD_VALUE,
        },
        {
          key: 'hsaAccountStatus',
          type: ListItemType.Node,
          title: 'HSA account status',
          node: matchedEmployee?.accountStatus
            ? <Status status={matchedEmployee?.accountStatus} />
            : EMPTY_FIELD_VALUE,
        },
        {
          key: 'dateOfBirth',
          type: ListItemType.Text,
          title: 'Date of birth',
          value: matchedEmployee?.birthDate
            ? dayjs(matchedEmployee?.birthDate).format(DEFAULT_DATE_FORMAT)
            : EMPTY_FIELD_VALUE,
        },
        {
          key: 'investments',
          type: ListItemType.Node,
          title: 'Investments',
          node: matchedEmployee?.investmentStatus
            ? <Status status={matchedEmployee?.investmentStatus} />
            : EMPTY_FIELD_VALUE,
        },
        {
          key: 'hsaAccountBalance',
          type: ListItemType.Text,
          title: 'HSA account balance',
          value: formatCurrency(matchedEmployee?.availableAmount) || EMPTY_FIELD_VALUE,
        },
      ],
    }));
  }, [matchedEmployees]);

  return {
    fields,
  };
};

export default useEmployeeCardsList;
