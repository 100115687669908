import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AppButton, Box, Modal, Preloader, Text, WarnModal } from '@common-fe/common-fe';

import AddEditContactForm from '@/modules/ExpressSetup/AddEditContactForm/AddEditContactForm';
import { ContactPayload } from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';

import { useHandleExpressSetupSetUp } from '../hooks';
import useExpressSetupSetter from '../hooks/useExpressSetupSetter';

interface Props {
  updatedElement?: ContactPayload;
  visible?: boolean;
  onToggleVision: (value: boolean) => void;
  onSetUpdatedContact?: () => void;
}

const AddEditContactModal: React.FC<Props> = ({
  updatedElement,
  visible,
  onToggleVision,
  onSetUpdatedContact,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  // const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const { handleReset } = useExpressSetupSetter();
  const { onSaveContactsHandler, isLoading, isContactServerError } = useHandleExpressSetupSetUp();

  const handleOpenModal = useCallback((value: boolean) => {
    if (onToggleVision) onToggleVision(value);
    if (onSetUpdatedContact) onSetUpdatedContact();
    handleReset();
  }, [onToggleVision, onSetUpdatedContact, handleReset]);

  const handleSaveContact = useCallback(() => {
    onSaveContactsHandler(!!updatedElement, false, () => handleOpenModal(false));
  }, [onSaveContactsHandler, updatedElement, handleOpenModal]);

  useEffect(() => {
    if (isContactServerError) setErrorModal(true);
  }, [isContactServerError]);
  useEffect(() => () => {
    setErrorModal(false);
    setIsEditMode(false);
  }, [visible]);

  return (
    <>
      <Modal
        visible={!!visible}
        onSetVisible={() => handleOpenModal(false)}
        data-testid="contact-wizard-modal"
      >
        <Box direction="column">
          <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
            <Text size="2xl" color="textTitle" weight="bold">{updatedElement ? updatedElement?.contactName : 'Add Contact'}</Text>
          </Box>
          <Box>
            <Box pad="l" background="module" round="moduleRound">
              <AddEditContactForm currentContact={updatedElement} isEditMode={isEditMode} />
            </Box>
            {
              updatedElement && !isEditMode ? (
                <Box direction="row" justify="end" margin={{ top: 'l' }}>
                  <AppButton
                    onClick={() => setIsEditMode(true)}
                    testId="edit"
                    disabled={isLoading}
                    width="150px"
                  >
                    Edit
                  </AppButton>
                </Box>
              ) : (
                <Box
                  direction="row"
                  align="center"
                  justify="between"
                  pad={{ top: 'l' }}
                >
                  <AppButton buttonType="secondary" width="150px" onClick={() => handleOpenModal(false)}>Cancel</AppButton>
                  <Box margin={{ left: 's' }}>
                    <AppButton width="150px" onClick={handleSaveContact} disabled={isLoading}>
                      {
                        isLoading
                          ? <Preloader color="white" />
                          : <Text>{updatedElement ? 'Save' : 'Add Contact'}</Text>
                      }
                    </AppButton>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Box>
      </Modal>
      {/* <WarnModal
        testId="contact-wizard-delete-modal"
        visible={isDeleteModalVisible}
        header={`Remove ${updatedElement?.contactName || ''}?`}
        onSetVisible={setIsDeleteModalVisible}
        cancelButtonNode={(
          <Box direction="row">
            <Box width="150px" margin={{ right: 'spacing6' }}>
              <AppButton
                buttonType="secondary"
                onClick={() => setIsDeleteModalVisible(false)}
                width="100%"
              >
                Cancel
              </AppButton>
            </Box>
            <Box width="150px" margin={{ left: 'spacing6' }}>
              <AppButton
                color="red"
                onClick={handleRemoveContact}
                width="100%"
                disabled={isLoading}
              >
                {isLoading
                  ? <Preloader color="white" />
                  : 'Yes, Remove'}
              </AppButton>
            </Box>
          </Box>
        )}
      /> */}
      <WarnModal
        testId="contact-wizard-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot ${updatedElement ? 'update' : 'create'} the Contact with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSaveContact}
        submitButtonText="Try again"
      />
    </>
  );
};

export default AddEditContactModal;
