export interface CommunicationChannelPayload {
  communicationChannel: string[];
  emailTemplate: string;
}

export interface CommunicationCategoryPayload {
  communicationCategory: string[];
}

export enum StatementType {
  PAPER_AND_ELECTRONIC = 'PAPER_AND_ELECTRONIC',
  ELECTRONIC_ONLY = 'ELECTRONIC_ONLY',
}

export enum StatementFrequencyType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export interface StatementSettings {
  statementType?: StatementType,
  statementFrequencyType?: StatementFrequencyType,
}
