import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';
import { Option, Pagination } from '@/common/types';

mock.onGet(`${PATHS.ORGANIZATIONS}/5238/contacts`).reply(200, [{
  id: '5',
  first_name: 'John',
  last_name: 'Doe',
}, {
  id: '6',
  first_name: 'John1',
  last_name: 'Doe1',
}]);

interface ResponseOption {
  id: string;
  first_name: string;
  last_name: string;
}

export const USE_ACCOUNT_MANAGERS_QUERY_KEY = 'useAccountManagers';

const useAccountManagersQuery = (organizationId?: string) => {
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery([USE_ACCOUNT_MANAGERS_QUERY_KEY, organizationId],
    () => api.get<Pagination<ResponseOption>>(
      PATHS.PERSON_ORGANIZATIONS_HIERARCHY_BY_ID,
      {
        params: {
          size: 1000, // Hardcode for fixing pagination on dropdown. In the future will be improved (inifite loading, searching, etc)
          organization_id: organizationId,
        },
      },
    ), {
      enabled: organizationId !== undefined,
    });
  useEffect(() => {
    if (organizationId !== undefined) refetch();
  }, [refetch, organizationId]);

  const formatedData = useMemo<Option[]>(() => {
    const list: ResponseOption[] = data?.data?.content || [];
    return list.map((item) => ({
      key: `${item.id}`,
      value: `${item.first_name} ${item.last_name}`,
    }));
  }, [data]);
  return {
    data: formatedData,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useAccountManagersQuery;
