import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';
import {
  CopayErrors,
  CopayGroupPayload,
} from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';
import CopayOption, {
  NoneTitle,
} from '@/modules/HealthPlan/CopayAdjudication/CopayTopbar/CreateCopayAdjudication/CopayOption';
import { useCopayGroupsQuery } from '@/modules/HealthPlan/CopayAdjudication/queries';

const NONE_KEY = 'none';

interface Params {
  defaultValues?: CopayGroupPayload;
  currentBasedOn?: string;
  isViewMode?: boolean;
  isEditMode?: boolean;
  currentErrors?: CopayErrors;
}
export default ({
  defaultValues, isViewMode, currentErrors, isEditMode, currentBasedOn,
}: Params): Field[] => {
  const { options } = useCopayGroupsQuery(undefined, true);

  const foundItem = useMemo(() => {
    if (defaultValues?.name) {
      return options.find((item) => item.value === defaultValues?.name);
    }
    return null;
  }, [defaultValues, options]);
  const formatedOptions = useMemo(() => {
    const filteredOptions = options.filter((option) => {
      if (option.isExpired) {
        return false;
      }

      return true;
    });

    return [
      {
        key: NONE_KEY,
        value: <NoneTitle>None</NoneTitle>,
        displayedValue: 'None',
        divide: true,
      },
      ...filteredOptions.map((option) => ({
        key: option.key,
        node: <CopayOption title={option.value} options={option.copays} />,
        displayedValue: option.value || '',

        // node: (
        // <CopayOption
        //   title={option.value}
        //   options={
        //       option.copays
        //     }
        // />
        // ),
      })),
    ] as Option[];
  }, [options]);

  const fields = useMemo<Field[]>(
    () => [
      ...(isViewMode || isEditMode
        ? []
        : ([
          {
            name: 'basedOn',
            type: FieldTypes.Dropdown,
            label: 'Based on',
            singleMode: true,
            showRequireIcon: !isViewMode,
            defaultValue: foundItem?.key || NONE_KEY,
            validator: VALIDATORS.STRING,
            placeholder: 'Select an option',
            options: formatedOptions,
          },
        ] as Field[])),
      {
        name: 'name',
        type: FieldTypes.Text,
        label: 'Name',
        showRequireIcon: !isViewMode,
        placeholder: 'Enter name',
        validator: VALIDATORS.REQUIRED_STRING.test({
          test: (val) => val !== currentErrors?.name,
          message: 'The field name must be unique among all copay groups',
        }),
        defaultValue: defaultValues?.name,
        options: [],
      },
      {
        name: 'validFrom',
        type: FieldTypes.Date,
        defaultValue: defaultValues?.validFrom,
        placeholder: 'Select date',
        label: 'Valid from',
        isManualDateInput: false,
        showRequireIcon: !isViewMode,
        disabled: isEditMode,

        controlStyles: isViewMode
          ? {}
          : {
            width: '180px',
            marginRight: 'auto',
          },
        validator: VALIDATORS.DATE_FORMAT.required(REQUIRED_TEXT),
      },
      {
        name: 'validTo',
        type: FieldTypes.Date,
        isManualDateInput: false,
        defaultValue: defaultValues?.validTo,
        label: 'Valid to',
        placeholder: 'Select date',
        controlStyles: isViewMode
          ? {}
          : {
            width: '180px',
            marginRight: 'auto',
          },

        validator: VALIDATORS.DATE_FORMAT_AFTER_OTHER_DATE(
          'validFrom',
          'The valid from date couldn\'t be earlier than the valid to date',
        ),
      },
    ],
    [isViewMode, formatedOptions, defaultValues, currentErrors, isEditMode, foundItem],
  );
  return fields;
};
