import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { useClaimReimbursementActivityReportStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useClaimReimbursementActivityReport.store';
import { useClaimReimbursementActivityReportFilterStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useClaimReimbursementActivityReportFilter.store';
import { generateGroupClaimReimbursementActivityReport } from '../hooks/useGenerateClaimReimbursementActivityReport';
import {
  GroupClaimReimbursementActivityReportResponse,
  PlanYearReportFilterItems,
  Report,
  ReportTypes,
} from '../report.types';

const GET_CLAIM_REIMBURSEMENT_ACTIVITY_REPORT_QUERY_KEY = 'getClaimReimbursementActivityReport';
const PAGE_SIZE = 30;

export const useGetClaimReimbursementActivityReport = () => {
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();
  const { currentType: type } = useGetCurrentOrganizationType();
  const groups = useClaimReimbursementActivityReportStore((state) => state.groups);
  const setGroups = useClaimReimbursementActivityReportStore((state) => state.setGroups);

  const pages = useClaimReimbursementActivityReportStore((state) => state.pages);
  const setPages = useClaimReimbursementActivityReportStore((state) => state.setPages);

  const reportDates = useClaimReimbursementActivityReportStore((state) => state.reportDates);
  const setReportDates = useClaimReimbursementActivityReportStore((state) => state.setReportDates);

  const totalPages = useClaimReimbursementActivityReportStore((state) => state.totalPages);
  const setTotalPages = useClaimReimbursementActivityReportStore((state) => state.setTotalPages);

  const currentPage = useClaimReimbursementActivityReportStore((state) => state.currentPage);
  const setCurrentPage = useClaimReimbursementActivityReportStore((state) => state.setCurrentPage);

  const isCurrentLoading = useClaimReimbursementActivityReportStore(
    (state) => state.isCurrentLoading,
  );
  const setIsCurrentLoading = useClaimReimbursementActivityReportStore(
    (state) => state.setIsCurrentLoading,
  );

  const handleClear = useClaimReimbursementActivityReportStore((state) => state.handleClear);

  const {
    groupBy: groupByValues,
    planYear: planYearValues,
    search: searchValues,
  } = useClaimReimbursementActivityReportFilterStore();

  const {
    data, isLoading, refetch, remove,
  } = useQuery(
    [
      `${GET_CLAIM_REIMBURSEMENT_ACTIVITY_REPORT_QUERY_KEY}_${type}`,
      id,
      ...groupByValues,
      ...planYearValues,
      searchValues,
      currentPage,
    ],
    type
      ? () => api.get<GroupClaimReimbursementActivityReportResponse>(
        PATHS.CLAIM_REIMBURSEMENT_ACTIVITY_REPORT(id),
        {
          params: {
            'group-type': groupByValues[1],
            'org-name-filter': searchValues || '',
            ...planYearValues[0] === PlanYearReportFilterItems.NULL
              ? {} : { 'plan-year-period': planYearValues.join(',') || '' },
            size: PAGE_SIZE,
            page: currentPage,
          },
        },
      )
      : () => null,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const reportData = useMemo(() => data?.data, [data]);

  useEffect(() => {
    handleClear();
    setIsCurrentLoading(true);
    const timeout = setTimeout(() => {
      refetch();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupByValues,
    searchValues,
    planYearValues,
    type,
    id,
  ]);

  useEffect(() => {
    if (currentPage) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (type) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (data && !pages.includes(data.data?.page_number)) {
      const preparedGroups = generateGroupClaimReimbursementActivityReport({
        response: reportData?.groups || [],
        groupByType: groupByValues[1],
      });
      setGroups(preparedGroups);
      setReportDates([
        data?.data?.start_report_date,
        data?.data?.end_report_date,
      ]);
      setPages(data.data?.page_number);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groupByValues]);
  useEffect(() => {
    if (data?.data?.total_pages) {
      setTotalPages(data.data?.total_pages || 0);
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    if (isLoading) {
      setIsCurrentLoading(true);
    }
    const timeout = setTimeout(() => {
      if (!isLoading) {
        setIsCurrentLoading(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const formattedClaimReimbursementActivityReport = useMemo(() => ({
    title: 'Claim Reimbursement Activity',
    type: 'Claims',
    reportType: ReportTypes.Claims,
    generateReportURL: '',
    id: 'claim_reimbursement_activity_report_id',
    startReportDate: reportDates[0],
    endReportDate: reportDates[1],
    currentPage,
    totalPages,
    setCurrentPage,
    setIsCurrentLoading,
    tablesData: {
      groups,
    },
  } as Report),
  [
    currentPage,
    groups,
    reportDates,
    setCurrentPage,
    setIsCurrentLoading,
    totalPages,
  ]);

  return {
    formattedClaimReimbursementActivityReport,
    refetch,
    remove,
    isLoading: isCurrentLoading
      && !formattedClaimReimbursementActivityReport?.tablesData?.groups?.length,
    isSubLoading: isCurrentLoading && !!groups.length,
    isEmpty: !isLoading && !formattedClaimReimbursementActivityReport.tablesData?.groups.length,
  };
};
