 
import React, { useMemo } from 'react';
import {
  Box,
  dateValidatorByFormat,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import {
  DATE_INVALID_TEXT,
  DATE_ONLY_FUTURE_TEXT,
  DEFAULT_DATE_FORMAT,
  INTEGER_VALUE_TEXT,
  INVALID_RANGE_TEXT,
  PermissionKey,
  REQUIRED_TEXT,
} from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import { isDateInFuture } from '@/utils';

const usePreFundFields = (
  formattedData: EmployerDto,
  activateMode?: boolean,
  isLoading?: boolean,
) => {
  const replenishmentCreepMinPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_CREEP_MIN,
  );
  const replenishmentCreepPercentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_CREEP_PERCENT,
  );
  const replenishmentMinValuePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_MIN_VALUE,
  );
  const replenishmentPercentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_PERCENT,
  );
  const { viewMode } = useSetupEmployerMode();

  const currencyValueValidate = yup
    .string()
    .test({
      test: (val) => {
        if (val) {
          const value = Number(val);
          return value >= 1 && value <= 999999;
        }
        return true;
      },
      message: INVALID_RANGE_TEXT(1, 999999),
    })
    .test({
      test: (val) => {
        if (val) {
          const value = Number(val);
          return Number.isInteger(value);
        }
        return true;
      },
      message: INTEGER_VALUE_TEXT,
    });

  const percentValueValidate = yup
    .string()
    .test({
      test: (val) => {
        if (val) {
          const value = Number(val);
          return value >= 1 && value <= 100;
        }
        return true;
      },
      message: INVALID_RANGE_TEXT(1, 100),
    });

  return useMemo(() => {
    if (isLoading) return [];

    return [
      {
        name: 'migrationPreFundStartDate',
        type: FieldTypes.Date,
        disabled: viewMode,
        placeholder: 'Select date',
        controlStyles: { width: '180px' },
        inputWrapCssClass: 'small-input',
        validator: yup.string()
          .test({
            test: (val) => val
              ? isDateInFuture(val) || val === formattedData?.overridableProperties?.prefundInitialDelayDate
              : true,
            message: DATE_ONLY_FUTURE_TEXT,
          })
          .test({
            test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
            message: DATE_INVALID_TEXT, 
          }),
        minDate: dayjs(),
        label: (
          <Box flex={{ grow: 1 }}>
            <Box direction="row" height={{ max: '20px' }} margin={{ bottom: '4px' }}>
              <Inscription weight="bold" color="textBody" lineHeight="20px">
                Migration Pre-fund Start Date
              </Inscription>
            </Box>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small" lineHeight="18px">
                Date initial pre-fund will be calculated after migration
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.prefundInitialDelayDate || '',
      },
      {
        name: 'preFundPercent',
        type: FieldTypes.Number,
        disabled: viewMode,
        validator: activateMode
          ? percentValueValidate.required(REQUIRED_TEXT)
          : percentValueValidate,
        placeholder: 'Select Percent',
        label: (
          <Box flex={{ grow: 1 }}>
            <Box direction="row" height={{ max: '20px' }} margin={{ bottom: '4px' }}>
              <Inscription
                color="danger"
                margin={{ right: '3px', top: '-3px' }}
              >
                *
              </Inscription>
              <Inscription weight="bold" color="textBody" lineHeight="20px">
                Percent
              </Inscription>
            </Box>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small" lineHeight="18px">
                Date initial pre-fund will be calculated after migration
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.replenishmentPercent || 4,
        ...replenishmentPercentPermProps,
      },
      {
        name: 'preFundMinimumValue',
        type: FieldTypes.Currency,
        disabled: viewMode,
        validator: activateMode
          ? currencyValueValidate.required(REQUIRED_TEXT)
          : currencyValueValidate,
        placeholder: 'Select Minimum Val...',
        label: 'Minimum value',
        showRequireIcon: true,
        value: `${formattedData?.overridableProperties?.replenishmentMinValue || 500}`,
        ...replenishmentMinValuePermProps,
      },
      {
        name: 'preFundElectionChangePercentage',
        type: FieldTypes.Number,
        disabled: viewMode,
        validator: activateMode
          ? percentValueValidate.required(REQUIRED_TEXT)
          : percentValueValidate,
        placeholder: 'Select Election Cha...',
        label: (
          <Box flex={{ grow: 1 }}>
            <Box direction="row" height={{ max: '20px' }} margin={{ bottom: '4px' }}>
              <Inscription
                color="danger"
                margin={{ right: '3px', top: '-3px' }}
              >
                *
              </Inscription>
              <Inscription weight="bold" color="textBody" lineHeight="20px">
                Election change percentage
              </Inscription>
            </Box>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small" lineHeight="18px">
                Increased elections from the previous pre-fund where additional funds will be collected
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.replenishmentCreepPercent || 25,
        ...replenishmentCreepPercentPermProps,
      },
      {
        name: 'preFundMinimumThreshold',
        type: FieldTypes.Currency,
        disabled: viewMode,
        validator: activateMode
          ? currencyValueValidate.required(REQUIRED_TEXT)
          : currencyValueValidate,
        placeholder: 'Select Minimum Thr...',
        label: 'Minimum threshold',
        showRequireIcon: true,
        value: `${formattedData?.overridableProperties?.replenishmentCreepMin || 100}`,
        ...replenishmentCreepMinPermProps,
      },
    ] as Field[];
  }, [
    isLoading,
    viewMode,
    formattedData,
    activateMode,
    currencyValueValidate,
    percentValueValidate,
    replenishmentCreepMinPermProps,
    replenishmentCreepPercentPermProps,
    replenishmentMinValuePermProps,
    replenishmentPercentPermProps,
  ]);
};

export default usePreFundFields;
