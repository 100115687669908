import React, { useCallback, useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  EnrollmentStatus,
  FlexListHeader,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { ListItemBase } from '@/common/types';
import currentTheme from '@/styles/theme';

import { PolicyStatus } from '../../../EnrollmentDetails/queries/useGetPolicyStatuses.query';

export const DateFromTo: React.FC<{ date?: string, isDateTo?: boolean }> = ({ date, isDateTo }) => {
  const isForever = useMemo(() => {
    if (isDateTo) {
      return dayjs(date).isAfter(dayjs(`01/01/${dayjs().year() + 100}`));
    }

    return false;
  }, [isDateTo, date]);

  return (
    <Inscription size="12px" lineHeight="18px" style={{ fontWeight: 500 }}>
      {isForever ? 'Forever' : dayjs(date).format(DEFAULT_DATE_FORMAT)}
    </Inscription>
  );
};

const FOREVER_LABEL = 'Forever';

const Wrapper = styled(Box)`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }
`;

const EmploymentStatuses: React.FC<{
  statuses: PolicyStatus[],
}> = ({ statuses }) => {
  const headers: ListItemBase[] = useMemo(() => {
    const currentHeaders = [{
      key: 'status',
      title: 'Status',
    }, {
      key: 'period',
      title: 'Period',
      flex: 1.5,
    }];
    return currentHeaders;
  }, []);

  const checkIsInRange = useCallback((validFrom: string, validTo: string | null) => {
    const todayDate = dayjs();
    const formatValidFrom = dayjs(validFrom);
    const formatValidTo = dayjs(validTo || '');

    return validTo
      ? todayDate >= formatValidFrom && todayDate < formatValidTo
      : todayDate >= formatValidFrom;
  }, []);

  const getBorderColor = useCallback((el: PolicyStatus) => {
    if (el.status === EnrollmentStatus.Active
      && checkIsInRange(el.validFrom, el.validTo)) {
      return currentTheme.colors.successBorder;
    } else if (el.status === EnrollmentStatus.Canceled
      && checkIsInRange(el.validFrom, el.validTo)) {
      return currentTheme.colors.dangerBorder;
    }
    return 'canvas';
  }, [checkIsInRange]);

  return (
    <Box
      data-testId="enrollment-statuses-wrapper"
      background="module"
      pad="xs"
      round="small"
      margin={{ vertical: 'xs' }}
    >
      <FlexListHeader items={headers} thinMode />
      {statuses.map((item, index) => (
        <Wrapper
          key={item.id}
          height="42px"
          background="canvas"
          elevation="default"
          round={currentTheme.rounds.button1Round}
          margin={{ top: index === 0 ? 'xs' : '0px' }}
          pad={{ vertical: 's', horizontal: 'l' }}
          direction="row"
          align="center"
          border={{size: '2px', color: getBorderColor(item)}} 
        >
          <Box direction="row" align="center" width="40%">
            <Inscription
              size="small"
              lineHeight="18px"
              style={{ fontWeight: checkIsInRange(item.validFrom, item.validTo) ? 700 : 500 }}
            >
              {item?.status && capitalizeFirstLetter(item?.status)}
            </Inscription>
            {
              checkIsInRange(item.validFrom, item.validTo) && (
                <Box
                  height="18px"
                  background={currentTheme.colors.border1}
                  pad={{ horizontal: 'xxs' }}
                  margin={{ left: 'xs' }}
                  round={currentTheme.rounds.button2Round}
                >
                  <Inscription
                    size="10px"
                    weight={500}
                    lineHeight="18px"
                  >
                    Current
                  </Inscription>
                </Box>
              )
            }
          </Box>
          <Box height="24px" direction="row" align="center">
            <DateFromTo date={item.validFrom} />
            &nbsp;-&nbsp;
            {item.validTo
              ? <DateFromTo date={item.validTo} isDateTo />
              : (
                <Inscription
                  size="small"
                  lineHeight="18px"
                  style={{ fontWeight: 500 }}
                >
                  {FOREVER_LABEL}
                </Inscription>
              )}
          </Box>
        </Wrapper>
      ))}
    </Box>
  );
};

export default EmploymentStatuses;
