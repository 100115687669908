import React, { useCallback, useEffect, useMemo,useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppButton,
  Box,
  FlexControlledForm,
  Inscription,
  Preloader,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHasAccess } from '@/modules/core/hooks';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
import theme from '@/styles/theme';

import { FileDtoWithMediaSource, FileStatus } from '../FileManager.constants';
import { getStatus } from '../queries/useFilesList.query';
import useFilesProcess from '../queries/useFilesProcess.query';

import ErrorSnack from './components/ErrorSnack';
import FileErrorSnack from './components/FileErrorSnack';
import ExportErrorsButton from './FileReportPopup/ExportErrorsButton';
import useFileErrorsQuery from './FileReportPopup/queries/useFileErrors.query';
import useFileRecordErrorsQuery from './FileReportPopup/queries/useFileRecordErrors.query';
import useGetDetailsFields from './hooks/useGetDetailsFields';
import useGetInformationFields from './hooks/useGetInformationFields';
import useGetFileDetailsQuery from './queries/useGetFileDetails.query';
import { FileReportPopup } from './FileReportPopup';
import { Title } from './FileSummary.styles';

const FileSummary: React.FC = () => {
  const { onFileProcess, isSuccess, isLoading } = useFilesProcess([]);

  const { id: fileId } = useParams<{ id: string }>();
  const { fileDetails, refetch, isRefetching } = useGetFileDetailsQuery(fileId);
  useEffect(() => {
    if (isSuccess) refetch();
  }, [isSuccess, refetch]);
  const fileProcessingStatusType = fileDetails?.fileProcessingStatusType;
  const isError = useMemo(() => fileProcessingStatusType === FileStatus.Error,
    [fileProcessingStatusType]);
  const isCompleted = useMemo(() => fileProcessingStatusType === FileStatus.Completed,
    [fileProcessingStatusType]);
  const isProcessing= useMemo(() => fileProcessingStatusType === FileStatus.Processing,
    [fileProcessingStatusType]);
  const { total: totalErrors } = useFileRecordErrorsQuery({
    fileId, enabled: isCompleted || isError,
  });
  const { fileErrorType } = useFileErrorsQuery({ fileId, enabled: isError });
  const informationFields = useGetInformationFields(fileDetails);
  const detailsFields = useGetDetailsFields({
    fileDetails, 
    totalErrors, 
    isProcessing,
    isAvailable:  isCompleted || isError || isProcessing
  });
  const [isFileErrorPopupShown, setIsFileErrorPopupShown] = useState(false);
  const hasAccessToProcessFile = useHasAccess([{
    permission: Permissions.PROCESS_FILE,
  }]);
  const [isLoadingToProcess, setIsLoadingToProcess] = useState(false);
  const handleProcess = useCallback(async () => {
    setIsLoadingToProcess(true);
    await onFileProcess({ id: fileDetails?.id } as FileDtoWithMediaSource);
    setIsLoadingToProcess(false);
  }, [fileDetails, onFileProcess, setIsLoadingToProcess]);
  const hasProcessButton = useMemo(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    () => getStatus(fileDetails?.fileProcessingStatusType as any) === FileStatus.Uploaded
      && hasAccessToProcessFile && !isSuccess,
    [fileDetails, hasAccessToProcessFile, isSuccess],
  );

  useBreadcrumbs([
    {
      route: ROUTES.FILE_MANAGER,
      title: 'File Manager',
    },
    {
      route: ROUTES.FILE_SUMMARY,
      title: fileDetails?.fileName || fileId,
    },
  ], [fileId, fileDetails?.fileName], {
    backBtn: true,
  });

  return (
    <Box width="100%" pad={{ bottom: 'spacing72' }}>
      <Topbar />

      <Box width={theme.defaultContentWidth} margin={{ top: 'medium' }} alignSelf="center">
        {fileDetails ? (
          <>
            <Box direction="row" justify="between" align="center">
              <Title>{fileDetails.fileName}</Title>
              {hasProcessButton ? (
                <Box>
                  <AppButton
                    width="140px"
                    onClick={handleProcess}
                    disabled={isLoading || isLoadingToProcess || isRefetching}
                  >
                    {isLoading || isLoadingToProcess
                      ? <Preloader color="textDisabled" />
                      : <Inscription lineHeight="20px">Process</Inscription>}
                  </AppButton>
                </Box>
              ) : null}
            </Box>

            <Box
              background="module"
              pad="spacing24"
              round="medium"
              margin={{ top: '26px' }}
            >
              <Box
                background="canvas"
                round="container1Round"
                border={{ color: 'border2', size: 'small' }}
                pad={{ horizontal: 'spacing32', vertical: 'spacing24' }}
                elevation="default"
              >
                <FlexControlledForm
                  testId="FileSummary_information"
                  formTitle="Information"
                  fields={informationFields}
                  wrapperStyle={{ border: 'none' }}
                  formStyle={{ margin: 0 }}
                  isThinMode
                />
                {isError && <FileErrorSnack errorType={fileErrorType} />}
              </Box>

              {isError && !fileDetails.errorRecordsCount ? null : (
                <Box
                  background="canvas"
                  round="container1Round"
                  border={{ color: 'border2', size: 'small' }}
                  margin={{ top: 'spacing24' }}
                  elevation="default"
                >
                  <FlexControlledForm
                    testId="FileSummary_details"
                    formTitle="File Details"
                    fields={detailsFields}
                    wrapperStyle={{ border: 'none' }}
                    isThinMode
                  />
                </Box>
              )}

              {(isCompleted || isError) && totalErrors > 0 && (
                <Box
                  elevation="default"
                  background="canvas"
                  round="container1Round"
                  border={{ color: 'border2', size: 'small' }}
                  margin={{ top: 'spacing24' }}
                  pad="spacing24"
                >
                  <Box direction="row" justify="between">
                    <Inscription size="20px" lineHeight="28px">Errors of Records</Inscription>
                    <ExportErrorsButton fileId={fileId} fileName={fileDetails.fileName} />
                  </Box>
                  <Box margin={{ top: '22px' }}>
                    <ErrorSnack>
                      <Inscription
                        color="info"
                        weight="bold"
                        lineHeight="20px"
                        onClick={() => setIsFileErrorPopupShown(true)}
                        cursor="pointer"
                      >
                        View Error Report ({totalErrors})
                      </Inscription>
                    </ErrorSnack>
                  </Box>
                </Box>
              )}
            </Box>

            {isFileErrorPopupShown && (
              <FileReportPopup
                fileId={fileId}
                hide={() => setIsFileErrorPopupShown(false)}
                totalErrors={totalErrors}
                fileName={fileDetails.fileName}
              />
            )}
          </>
        ) : <Preloader />}
      </Box>
    </Box>
  );
};
export default FileSummary;
