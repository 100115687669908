import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { MAX_LENGTH_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { useStatesQuery } from '@/modules/core/hooks';

const FIELDS = {
  STREET: 'line1',
  STREET_2: 'line2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  COUNTRY_CODE: 'countryCode',
};

const MAX_FIELD_LENGTH = 40;

export const useEmployeeSetupAddressModalFields = () => {
  const { states } = useStatesQuery();

  const fields: Field[] = useMemo(() => ([
    {
      name: FIELDS.STREET,
      type: FieldTypes.Text,
      label: 'Address line 1',
      placeholder: 'Enter Address line 1',
      showRequireIcon: true,
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.STREET_2,
      type: FieldTypes.Text,
      label: 'Address line 2',
      placeholder: 'Enter Address line 2',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH)),
      defaultValue: '',
    },
    {
      name: FIELDS.CITY,
      type: FieldTypes.Text,
      label: 'City',
      showRequireIcon: true,
      placeholder: 'Enter City',
      validator: yup
        .string()
        .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
        .required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.STATE,
      type: FieldTypes.AutocompleteDropdown,
      label: 'State',
      showRequireIcon: true,
      placeholder: 'Select state',
      validator: yup.string().required(REQUIRED_TEXT),
      options: states.map((state) => ({ key: state.code, title: state.name })),
    },
    {
      name: FIELDS.ZIP_CODE,
      type: FieldTypes.ZipCode,
      label: 'ZIP code',
      placeholder: 'Enter ZIP code',
      showRequireIcon: true,
      validator: yup.string().trim().test('len', 'ZIP code isn\'t valid',
        (val) => (val && (val.length === 10 || val.length === 5))
            || (val === '')).required(REQUIRED_TEXT),
      defaultValue: '',
    },
    {
      name: FIELDS.COUNTRY_CODE,
      type: FieldTypes.Text,
      label: 'Country code',
      disabled: true,
      showRequireIcon: true,
      placeholder: 'Enter Country code',
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: 'USA',
    },
  ]), [states]);

  return fields;
};
