import React, { useState } from 'react';
import {
  Box,
  NavigationLeftIcon,
  PayPalIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { DuplicationErrorModal } from '@/modules/ReimburseMe/ConfirmationStep/Immediately/DublicationErrorModal/DuplicationErrorModal';

import { BackIcon } from './DirectDeposit/StyledComponents.styles';
import { AddPayPalForm } from './PayPal';

const DESCRIPTION = 'Enter your email or mobile number associated with your PayPal account to get started.';

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  hideNavigationButton?: boolean;
  allowSaveAsDefault?: boolean;
}

export const AddPayPalAccountModal: React.FC<Props> = ({ onClose, onBack, onSuccess, hideNavigationButton, allowSaveAsDefault }) => {
  const [serverErrorShow, setServerErrorShow] = useState(false);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
      testId="AddPayPalAccountModal_testId"
    >
      {onBack && !hideNavigationButton && (
        <BackIcon onClick={onBack} data-testid="back-icon-button">
          <NavigationLeftIcon color="iconPrimary"
            size="18px"
          />
        </BackIcon>
      )}

      <Box align="center">
        <Box width="xxsmall" alignSelf="center" data-testid="PayPal_icon">
          <PayPalIcon />
        </Box>

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small' }}
        >
          Get Reimbursed With PayPal
        </Text>

        <Text
          textAlign="center"
          color="textBody"
          margin={{ top: 'small', bottom: 'medium' }}
        >
          {DESCRIPTION}
        </Text>
      </Box>

      <AddPayPalForm
        onBack={onBack}
        onSuccess={onSuccess}
        onError={() => setServerErrorShow(true)}
        allowSaveAsDefault={allowSaveAsDefault}
      />
      <DuplicationErrorModal
        visible={serverErrorShow}
        close={() => setServerErrorShow(false)}
      />
    </ModalWrapper>
  );
};
