import { storeCreator } from '@/utils/store/store.utils';

interface State {
  planLoadedId: number | string;
  loading: boolean
  setLoading: (value: boolean) => void;
  setPlanLoadedId: (value: string | number) => void;
}

export const useStore = storeCreator<State>((set) => ({
  loading: true,
  planLoadedId: '',
  setPlanLoadedId: (value: number | string) => set(() => ({
    planLoadedId: value,
  })),
  setLoading: (value: boolean) => set(() => ({
    loading: value,
  })),
}));

export default useStore;
