import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { useAccountActivityReportStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useAccountActivityReport.store';
import { useAccountActivityReportFilterStore } from '../components/ReportSection/ReportFilterPopup.tsx/store/useAccountActivityReportFilter.store';
import { generateGroupAccountActivityReport } from '../hooks/useGenerateGroupAccountActivityReport';
import {
  GroupAccountActivityReportResponse,
  PlanYearReportFilterItems,
  Report,
  ReportTypes,
} from '../report.types';

const GET_ACCOUNT_ACTIVITY_REPORT_QUERY_KEY = 'getAccountActivityReportQueryKey';
const PAGE_SIZE = 30;

export const useGetParticipantAccountActivityReport = () => {
  const { currentType: type } = useGetCurrentOrganizationType();
  const {
    observingOrganization: { id },
  } = useCurrentOrganization();

  const groups = useAccountActivityReportStore((state) => state.groups);
  const setGroups = useAccountActivityReportStore((state) => state.setGroups);

  const pages = useAccountActivityReportStore((state) => state.pages);
  const setPages = useAccountActivityReportStore((state) => state.setPages);

  // const reportDates = useAccountActivityReportStore((state) => state.reportDates);
  const setReportDates = useAccountActivityReportStore((state) => state.setReportDates);

  const totalPages = useAccountActivityReportStore((state) => state.totalPages);
  const setTotalPages = useAccountActivityReportStore((state) => state.setTotalPages);

  const currentPage = useAccountActivityReportStore((state) => state.currentPage);
  const setCurrentPage = useAccountActivityReportStore((state) => state.setCurrentPage);

  const isCurrentLoading = useAccountActivityReportStore((state) => state.isCurrentLoading);
  const setIsCurrentLoading = useAccountActivityReportStore((state) => state.setIsCurrentLoading);

  const handleClear = useAccountActivityReportStore((state) => state.handleClear);

  const {
    groupBy: groupByValue,
    planYear: planYearValues,
    search: searchValues,
  } = useAccountActivityReportFilterStore();

  const {
    data, isLoading, refetch, remove, isSuccess,
  } = useQuery(
    [
      `${GET_ACCOUNT_ACTIVITY_REPORT_QUERY_KEY}_${type}`,
      id,
      groupByValue,
      ...planYearValues,
      searchValues,
      currentPage,
    ],
    type
      ? () => api.get<GroupAccountActivityReportResponse>(PATHS.ACCOUNT_ACTIVITY_REPORT(id),
        {
          params: {
            'group-type': groupByValue,
            'org-name-filter': searchValues || '',
            ...planYearValues[0] === PlanYearReportFilterItems.NULL
              ? {} : { 'plan-year-period': planYearValues.join(',') || '' },
            size: PAGE_SIZE,
            page: currentPage,
          },
        })
      : () => null,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  const reportData = useMemo(() => data?.data, [data]);

  useEffect(() => {
    if (type) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    handleClear();
    setIsCurrentLoading(true);
    const timeout = setTimeout(() => {
      refetch();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupByValue,
    planYearValues,
    searchValues,
    type,
    id,
  ]);

  useEffect(() => {
    if (currentPage) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
  ]);

  useEffect(() => {
    if (data && !pages.includes(data.data?.page_number)) {
      const preparedGroups = generateGroupAccountActivityReport({
        response: reportData?.groups || [],
        groupByType: groupByValue,
      });
      setGroups(preparedGroups);
      setReportDates([
        data?.data?.start_report_date,
        data?.data?.end_report_date,
      ]);
      setPages(data.data?.page_number);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, groupByValue]);
  useEffect(() => {
    if (data?.data?.total_pages) {
      setTotalPages(data.data?.total_pages || 0);
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    if (isLoading) {
      setIsCurrentLoading(true);
    }
    const timeout = setTimeout(() => {
      if (!isLoading) {
        setIsCurrentLoading(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const formattedAccountActivityReport = useMemo(() => ({
    title: 'Participant Account Activity',
    type: 'Enrollments',
    reportType: ReportTypes.ParticipantAccountActivity,
    generateReportURL: '',
    id: 'participant_account_activity_report_id',
    // startReportDate: reportDates[0],
    // endReportDate: reportDates[1],
    currentPage,
    totalPages,
    setCurrentPage,
    setIsCurrentLoading,
    tablesData: {
      groups,
    },
  } as Report),
  [
    // reportDates,
    currentPage,
    totalPages,
    setCurrentPage,
    setIsCurrentLoading,
    groups,
  ]);

  return {
    formattedAccountActivityReport,
    refetch,
    remove,
    isLoading: isCurrentLoading && !formattedAccountActivityReport?.tablesData?.groups?.length,
    isSubLoading: isCurrentLoading && !!groups.length,
    isSuccess,
    isEmpty: !isLoading && !formattedAccountActivityReport.tablesData?.groups.length,
  };
};
