import { useMemo } from 'react';
import _ from 'lodash';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { useStore } from '../store/useOrganizationSetupLogo.store';

export const useOrganizationSetupLogo = () => {
  const largeDarkLogo = useStore((store) => store.largeDarkLogo);
  const largeLightLogo = useStore((store) => store.largeLightLogo);
  const miniDarkLogo = useStore((store) => store.miniDarkLogo);
  const miniLightLogo = useStore((store) => store.miniLightLogo);

  const currentLargeDarkLogoPath = useMemo(() => largeDarkLogo?.preview, [largeDarkLogo]);
  const currentLargeLightLogoPath = useMemo(() => largeLightLogo?.preview, [largeLightLogo]);
  const currentMiniDarkLogoPath = useMemo(() => miniDarkLogo?.preview, [miniDarkLogo]);
  const currentMiniLightLogoPath = useMemo(() => miniLightLogo?.preview, [miniLightLogo]);

  const {
    observingOrganization: {
      name, type, id,
    },
  } = useCurrentOrganization();
  const { formattedData, isLoading: isEmployerLoading } = useGetEmployer();
  const currentUserId = formattedData.id;
  const currentUserType = formattedData.type;
  const currentUserName = formattedData.name;

  const areOverridablePropertiesRequired = useMemo(
    () => currentUserType === OrganizationTypes.partner
      || currentUserType === OrganizationTypes.distributor, [currentUserType],
  );

  const {
    observingOrganizationData, organizationDataById, isLoading,
  } = useOrganizationOverridableProperties(areOverridablePropertiesRequired
    ? currentUserId : undefined);

  const logoInfo = areOverridablePropertiesRequired
    ? organizationDataById?.logoInfo
    : observingOrganizationData?.logoInfo;

  const showLogoFromUrl = useMemo(
    () => (_.toNumber(currentUserId) === _.toNumber(id) || areOverridablePropertiesRequired)
      && !isEmployerLoading && !isLoading,
    [currentUserId, id, areOverridablePropertiesRequired, isEmployerLoading, isLoading],
  );

  const currentOrgType = useMemo(
    () => (showLogoFromUrl
      ? type
      : currentUserType), [currentUserType, showLogoFromUrl, type],
  );

  const currentOrgName = useMemo(
    () => (showLogoFromUrl
      ? name
      : currentUserName), [currentUserName, name, showLogoFromUrl],
  );

  const currentLargeDarkLogo = useMemo(
    () => (showLogoFromUrl || currentLargeDarkLogoPath
      ? currentLargeDarkLogoPath || logoInfo?.darkLargeLogoUrl
      : undefined),
    [currentLargeDarkLogoPath, logoInfo, showLogoFromUrl],
  );

  const currentLargeLightLogo = useMemo(
    () => (showLogoFromUrl || currentLargeLightLogoPath
      ? currentLargeLightLogoPath || logoInfo?.largeLogoUrl
      : undefined),
    [currentLargeLightLogoPath, logoInfo, showLogoFromUrl],
  );

  const currentMiniDarkLogo = useMemo(
    () => (showLogoFromUrl || currentMiniDarkLogoPath
      ? currentMiniDarkLogoPath || logoInfo?.darkMinimizedLogoUrl
      : undefined),
    [currentMiniDarkLogoPath, logoInfo, showLogoFromUrl],
  );

  const currentMiniLightLogo = useMemo(
    () => (showLogoFromUrl || currentMiniLightLogoPath
      ? currentMiniLightLogoPath || logoInfo?.minimizedLogoUrl
      : undefined),
    [currentMiniLightLogoPath, logoInfo, showLogoFromUrl],
  );

  const currentFavicon = useMemo(
    () => (showLogoFromUrl || currentMiniLightLogoPath
      ? currentMiniLightLogoPath || logoInfo?.faviconUrl
      : undefined),
    [currentMiniLightLogoPath, logoInfo, showLogoFromUrl],
  );

  return {
    type: currentOrgType,
    name: currentOrgName,
    largeDarkLogo: currentLargeDarkLogo,
    largeLightLogo: currentLargeLightLogo,
    miniDarkLogo: currentMiniDarkLogo,
    miniLightLogo: currentMiniLightLogo,
    favicon: currentFavicon,
  };
};
