import React, { useCallback, useMemo } from 'react';
import { AppButton,Box, ButtonDropdown } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import routes from '@/common/routes';
// import useHasSubsidiaryInEnv from '@/utils/hooks/useHasSubsidiaryInEnv';
import { useCurrentOrganization, useHasAccess,useHistory } from '@/modules/core/hooks';

const PERMISSIONS_ADD_PARTNER = [
  {
    organizationType: OrganizationTypes.system,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.distributor,
    permission: Permissions.EDIT_ER_DATA,
  },
];
const PERMISSIONS_ADD_DISTRIBUTOR = [
  {
    organizationType: OrganizationTypes.system,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.partner,
    permission: Permissions.EDIT_ER_DATA,
  },
];
const PERMISSIONS_ADD_EMPLOYER = [
  {
    organizationType: OrganizationTypes.system,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.distributor,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.partner,
    permission: Permissions.EDIT_ER_DATA,
  },
];
const PERMISSIONS_ADD_SUBSIDIARY = [
  {
    organizationType: OrganizationTypes.system,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.partner,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.distributor,
    permission: Permissions.EDIT_ER_DATA,
  },
  {
    organizationType: OrganizationTypes.company,
    permission: Permissions.EDIT_ER_DATA,
  },
];

const ToolbarButtons: React.FC = () => {
  // const hasSubsidiaryInEnv = useHasSubsidiaryInEnv();
  const { observingOrganization: { type } } = useCurrentOrganization();

  const history = useHistory();
  const isSystemLevel = useMemo(
    () => type === OrganizationTypes.system, [type],
  );
  const isPartnerObserving = useMemo(
    () => type === OrganizationTypes.partner, [type],
  );
  const isDistributorObserving = useMemo(
    () => type === OrganizationTypes.distributor, [type],
  );
  const isEmployerObserving = useMemo(
    () => type === OrganizationTypes.company, [type],
  );
  const isSubsidiaryObserving = useMemo(
    () => type === OrganizationTypes.subsidiary, [type],
  );
  const goToPage = useCallback((path: string) => {
    history.push(path, {
      shouldOpenModal: true,
      ...Object.fromEntries(new URLSearchParams(history.searchQuery)),
    });
  }, [history]);

  const hasAccessToAddPartner = useHasAccess(PERMISSIONS_ADD_PARTNER);
  const hasAccessToAddDistributor = useHasAccess(PERMISSIONS_ADD_DISTRIBUTOR);
  const hasAccessToAddEmployer = useHasAccess(PERMISSIONS_ADD_EMPLOYER);
  const hasAccessToAddSubsidiary = useHasAccess(PERMISSIONS_ADD_SUBSIDIARY);

  const hasSetupPartnerButton = useMemo(
    () => hasAccessToAddPartner
      && !isPartnerObserving
      && !isDistributorObserving
      && !isEmployerObserving
      && !isSubsidiaryObserving,
    [
      hasAccessToAddPartner,
      isPartnerObserving,
      isDistributorObserving,
      isEmployerObserving,
      isSubsidiaryObserving,
    ],
  );
  const hasSetupDistributorButton = useMemo(
    () => !isDistributorObserving
      && !isEmployerObserving
      && !isSubsidiaryObserving
      && hasAccessToAddDistributor,
    [
      isDistributorObserving,
      isEmployerObserving,
      isSubsidiaryObserving,
      hasAccessToAddDistributor,
    ],
  );
  const hasSetupEmployerButton = useMemo(
    () => !isEmployerObserving
      && !isSubsidiaryObserving
      && hasAccessToAddEmployer,
    [
      isSubsidiaryObserving,
      isEmployerObserving,
      hasAccessToAddEmployer,
    ],
  );
  const hasSetupSubsidiaryButton = useMemo(
    () => !isSystemLevel
      && !isPartnerObserving
      && !isDistributorObserving
      && !isSubsidiaryObserving
      && hasAccessToAddSubsidiary,
    [hasAccessToAddSubsidiary,
      isSystemLevel,
      isPartnerObserving,
      isDistributorObserving,
      isSubsidiaryObserving],
  );
  const buttons = useMemo(() => ([
    ...hasSetupPartnerButton ? [{
      title: 'Partner',
      redirectFunc: () => goToPage(routes.PARTNERS),
    }] : [],
    ...hasSetupDistributorButton ? [{
      title: 'Distributor',
      redirectFunc: () => goToPage(routes.DISTRIBUTORS),
    }] : [],
    ...hasSetupEmployerButton ? [{
      title: 'Employer',
      redirectFunc: () => goToPage(routes.EMPLOYERS),
    }] : [],
    ...hasSetupSubsidiaryButton ? [{
      title: 'Subsidiary',
      redirectFunc: () => goToPage(routes.SUBSIDIARIES),
    }] : [],
  ]), [
    hasSetupPartnerButton,
    hasSetupDistributorButton,
    hasSetupEmployerButton,
    hasSetupSubsidiaryButton,
    goToPage,
  ]);

  return (
    <Box data-testId="toolbar-buttons-wrapper" direction="row" align="center">
      {buttons.length > 1 ? (
        <ButtonDropdown
          testId="set_up_new_organization"
          label="Set up new..."
          options={buttons}
        />
      ) : null}
      {buttons.length === 1 ? (
        <AppButton
          testId={`setup-${buttons[0].title}`}
          type="button"
          buttonType="primary"
          onClick={buttons[0].redirectFunc}
        >
          {`Set up ${buttons[0].title}`}
        </AppButton>
      ) : null}
    </Box>
  );
};

export default ToolbarButtons;
