import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface UpdatePayload {
  [key: string]: string;
}

export const useUpdateEmailNotificationTemplateQuery = (orgId?: string) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (payload: UpdatePayload) => api.patch(
      PATHS.UPDATE_EXTERNAL_EMAIL_TEMPLATE(orgId),
      payload,
    ),
  );

  return {
    updateEmailNotificationTemplate: mutateAsync,
    isLoading,
    isSuccess,
  };
};
