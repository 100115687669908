import { storeCreator } from '@/utils/store/store.utils';

interface State {
  isDownloadReportModalVisible: boolean;
  downloadReportId: string;
  setDownloadReportId: (value: string) => void;
  setIsDownloadReportModalVisible: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  isDownloadReportModalVisible: false,
  downloadReportId: '',
  setIsDownloadReportModalVisible: (value: boolean) => set(() => ({
    isDownloadReportModalVisible: value,
  })),
  setDownloadReportId: (value: string) => set(() => ({
    downloadReportId: value,
  })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
