import { storeCreator } from '@/utils/store/store.utils';

export interface AccordionItem {
  id: string;
  isExpanded: boolean;
}
interface State {
  list: AccordionItem[];
  originPath: string;
  setOriginPath: (originPath: string) => void;
  handleAdd: (item: AccordionItem) => void;
  handleToggleExpand: (isExpanded: boolean) => void;
  resetExpanders: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  list: [],
  originPath: '',
  setOriginPath: (originPath) => set(() => ({ originPath })),
  handleAdd: (item) => set((state) => ({
    list: [
      ...state.list.filter(({ id }) => id !== item.id),
      item,
    ],
  })),
  handleToggleExpand: (isExpanded) => set((state) => ({
    list: [
      ...state.list.map((item) => ({
        ...item,
        isExpanded,
      })),
    ],
  })),
  resetExpanders: () => set(() => ({ list: [] })),
}));

export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
