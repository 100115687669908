import { useCallback } from 'react';
import _ from 'lodash';

import { usePlanSetupSetter, usePlanSetupState } from '../../hooks';

export const ERROR_MESSAGES = {
  INVALID_PLAN_CODE: 'Details: Plan with plan_code',
  ELECTION_AMOUNT: 'invalid election amount range.',
  PENDING_ORGANIZATION: 'Plan organization is not Active.',
  FUND_ID_PRESENT: 'Plan with Fund ID',
  REPLENISHMENT_CAN_BE_FALSE:
    'Details: Setting of replenishment to false and Custom Prefund Calc to true is not valid account funding config.',
};

const ERROR_FIELDS_NAME = {
  FUND_ID: 'planPrimaryConfig.fundId.value',
  FUND_ID_FORMULA: 'planPrimaryConfig.fund_id_formula.value',
};

export const getStringBetweenTwoMarkers = (value: string, startMarker: string, endMarker: string) => {

  const startIndex = value.indexOf(startMarker);
  const endIndex = value.indexOf(endMarker);
  if(startIndex !== -1 && endIndex !== -1) {
    const parsedMessage =  value.substring(startIndex, endIndex)
      .replace(startMarker, '')
      .trim();
    return parsedMessage;
  }  
  return null;
};

export default () => {
  const { handleSetErrors, handleSetErrorFields } = usePlanSetupSetter();
  const currentState = usePlanSetupState();

  const handleParseError = useCallback(
    (errors: unknown) => {
      const message = _.get(errors, 'response.data.elevate_error_message', '') as string;

      const invalidFields = _.get(
        errors,
        'response.data.elevate_error_meta_info.invalid_fields',
        []
      ) as object[];
      if (message.includes(ERROR_MESSAGES.INVALID_PLAN_CODE)) {
        handleSetErrors({
          planCode: currentState.planCode,
        });
      }
      if (message.includes(ERROR_MESSAGES.FUND_ID_PRESENT)) {
        const startMarker = 'Details:';
        const endMarker = 'for organization';
        const parsedMessage = getStringBetweenTwoMarkers(message, startMarker, endMarker);
        handleSetErrorFields({
          fundID: {
            value: currentState.fundID || '',
            message: parsedMessage || 'The Fund ID already exists',
          },
        });
        
      
      }

      if (message.includes(ERROR_MESSAGES.ELECTION_AMOUNT)) {
        handleSetErrorFields({
          minimumElectionAmountCustom: {
            value: currentState.minimumElectionAmountCustom,
            message: 'Minimum election amount cannot be less that IRS maximum  amount',
          },
        });
      }

      if (message.includes(ERROR_MESSAGES.REPLENISHMENT_CAN_BE_FALSE)) {
        handleSetErrorFields({
          isReplenished: {
            value: currentState.isReplenished,
            message: 'Can\'t be false if prefund is turned on',
          },
        });
      }
      if (invalidFields && invalidFields.length) {
        invalidFields.forEach((field) => {
          const fieldName = _.get(field, 'field_name');
          const fieldMessage = _.get(field, 'elevate_error_message', '');
          if (fieldName === ERROR_FIELDS_NAME.FUND_ID) {
            handleSetErrorFields({
              fundID: {
                value: currentState.fundID,
                message: fieldMessage,
              },
            });
          }
          if (fieldName === ERROR_FIELDS_NAME.FUND_ID_FORMULA) {
            handleSetErrorFields({
              fundIDFormula: {
                value: currentState.fundIDFormula,
                message: fieldMessage,
              },
            });
          }
        });
      }
      return message;
    },
    [
      currentState.fundID,
      currentState.fundIDFormula,
      currentState.minimumElectionAmountCustom,
      currentState.planCode,
      handleSetErrorFields,
      handleSetErrors,
      currentState.isReplenished,
    ]
  );
  return handleParseError;
};
