import React, { useCallback, useEffect,useState } from 'react';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import AddModalWrapper from '../AddModalWrapper';

import PlanYearModalWrapper from './PlanYearTable/PlanYearModal/PlanYearModalWrapper';
import PlanYearTable from './PlanYearTable/PlanYearTable';

interface Props {
  confirmText: string;
  disabled?: boolean;
}

const PlanYearWrapper: React.FC<Props> = ({
  confirmText,
  disabled,
}) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value) {
       
      return window.confirm(confirmText) && setAddModalVisible(value);
    }
    return setAddModalVisible(value);
  }, [confirmText]);

  return (
    <>
      <Access
        accessRequirements={[
          {
            permission: Permissions.PLAN_YEAR_SETUP,
          },
        ]}
      >
        <AddModalWrapper
          id="plan_year"
          title="Plan Year"
          visible={addModalVisible}
          disabled={disabled}
          onSetVisible={onSetVisibleHandler}
          buttonText="Add New Plan Year"
        >
          <PlanYearModalWrapper
            visible
            isCreateMode
            onSetVisible={() => setAddModalVisible(false)}
            modalTitle="Create New Plan Year"
          />
        </AddModalWrapper>
      </Access>
      <Access
        accessRequirements={[
          { permission: Permissions.VIEW_PLAN },
          { permission: Permissions.VIEW_ALL },
        ]}
      >
        <PlanYearTable />
      </Access>
    </>
  );
};

export default PlanYearWrapper;
