import React from 'react';
import { Box } from '@common-fe/common-fe';

import ReportsWrap from '@/modules/employer/components/Reports/components/ReportSection/ReportsWrap';
import theme from '@/styles/theme';

export const HomeReports: React.FC = () => (
  <Box
    pad={{ top: '0' }}
    data-testid="HomeReports-wrapper"
  >
    <Box align="center">
      <Box width={theme.defaultContentWidth}>
        <Box flex="grow" style={{ position: 'relative' }}>
          <Box pad={{ bottom: 'spacing72' }}>
            <ReportsWrap />
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);
