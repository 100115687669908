import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ActivityType } from '@common-fe/common-fe';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import {
  SearchTransactionParams,
  Transaction,
  TransactionDto,
} from '@/modules/transaction/transaction.types';

const QUERY_KEY = 'transactionsList';

export const useExpensesList = (searchParams: SearchTransactionParams) => {
  const { list } = useServiceCategoriesQuery();
  const {
    isLoading, error, data, isSuccess, refetch,
  } = useQuery([QUERY_KEY, searchParams], () => api.get(PATHS.EXPENSES, {
    params: { ...searchParams },
  }));

  const formattedData = useMemo(() => data?.data.content.map(
    (item: TransactionDto): Transaction => ({
      categoryType: list
        .find(
          (category) => category.id === toString(item.category_id),
        )?.key as ActivityType,
      paidAmount: item.paid_amount,
      provider: item.provider_name,
      requestedAmount: item.requested_amount,
      status: item.status,
      statusDate: item.status_date,
      claimId: toString(item.claim_id),
    }),
  ), [data, list]);

  return {
    data: formattedData || [],
    total: data?.data?.totalElements || 0,
    error,
    isLoading,
    isSuccess,
    refetch,
  };
};
