import React from 'react';

import { ReportSection } from '@/modules/employer/components/Reports/components/ReportSection/ReportSection';

import useCardReports from './useCardReports';

const Card = () => {
  const reports = useCardReports();

  return (
    <ReportSection
      title="Card"
      reports={reports}
    />
  );
};

export default Card;
