import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface LockboxCheckDetailsPayload {
  vendor_id?: string;
  vendor_name?: string;
  benefit_of?: string;
  city?: string;
  state?: string;
  zip?: string;
  address_line_1?: string;
  address_line_2?: string;
}

interface Props {
  lockboxId?: string;
}

const useUpdateLockboxCheckDetailsQuery = ({ lockboxId }: Props) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync,
    isLoading,
    isError,
  } = useMutation((checkDetails: LockboxCheckDetailsPayload) => api
    .put(PATHS.UPDATE_LOCKBOX_CHECK_DETAILS(lockboxId), checkDetails), {
    onSuccess: () => {
      queryClient.invalidateQueries(PATHS.LOCKBOX_BY_ID(lockboxId));
    },
  });

  return {
    updateLockboxCheckDetails: mutateAsync,
    isLoading,
    isError,
  };
};

export default useUpdateLockboxCheckDetailsQuery;
