export const EMPTY_FIELD_VALUE = '—';
export const TITLE = 'Admin Portal';
export enum PlanStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT'
}
export enum PermissionState {
  VIEW = 'VIEW',
  HIDE = 'HIDE',
  EDIT = 'EDIT',
}
export enum PermissionKey {
  ABOUT_US_LINK = 'ABOUT_US_LINK',
  AI_CONFIDENCE_LEVEL = 'AI_CONFIDENCE_LEVEL',
  BIN_SETUP = 'BIN_SETUP',
  CARD_EMPLOYER_NAME = 'CARD_EMPLOYER_NAME',
  CARD_PHONE_NUMBER = 'CARD_PHONE_NUMBER',
  CARD_PROCESSOR = 'CARD_PROCESSOR',
  CARD_SETTLEMENT_CLEARING_ACCOUNT_ID = 'CARD_SETTLEMENT_CLEARING_ACCOUNT_ID',
  CARD_URL = 'CARD_URL',
  CHECK_FUNDING_MODEL = 'CHECK_FUNDING_MODEL',
  COMMUNICATION_CATEGORIES = 'COMMUNICATION_CATEGORIES',
  COMMUNICATION_CHANNELS = 'COMMUNICATION_CHANNELS',
  CUSTOMER_SERVICE_EMAIL = 'CUSTOMER_SERVICE_EMAIL',
  CUSTOMER_SERVICE_NUMBER = 'CUSTOMER_SERVICE_NUMBER',
  CUSTOMER_SERVICE_URL_LINK = 'CUSTOMER_SERVICE_URL_LINK',
  CUSTOMER_SERVICE_URL_NAME = 'CUSTOMER_SERVICE_URL_NAME',
  EE_CONTRIBUTIONS_HOLD_PERIOD = 'EE_CONTRIBUTIONS_HOLD_PERIOD',
  ELECTRONIC_FUNDING_MODE = 'ELECTRONIC_FUNDING_MODE',
  ELEVATE_INTEREST_SOURCE_ACCOUNT_ID = 'ELEVATE_INTEREST_SOURCE_ACCOUNT_ID',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  EMAIL_ILLUSTRATION_ID = 'EMAIL_ILLUSTRATION_ID',
  EMAIL_LOGO_PATH = 'EMAIL_LOGO_PATH',
  EMAIL_LOGO_TYPE = 'EMAIL_LOGO_TYPE',
  EMAIL_TEMPLATE_TYPE = 'EMAIL_TEMPLATE_TYPE',
  EMAIL_THEME_ID = 'EMAIL_THEME_ID',
  EXTERNAL_ADMINISTRATOR_FEES_ACCOUNT_METHOD = 'EXTERNAL_ADMINISTRATOR_FEES_ACCOUNT_METHOD',
  EXTERNAL_PAYROLL_CONTRIBUTIONS_ACCOUNT_METHOD = 'EXTERNAL_PAYROLL_CONTRIBUTIONS_ACCOUNT_METHOD',
  EXTERNAL_REPLENISHMENT_ACCOUNT_METHOD = 'EXTERNAL_REPLENISHMENT_ACCOUNT_METHOD',
  FAVICON_LOGO_PATH = 'FAVICON_LOGO_PATH',
  HSA_CUSTODIAN_TYPE = 'HSA_CUSTODIAN_TYPE',
  ID_SOURCE_TYPE = 'ID_SOURCE_TYPE',
  INTEREST_CLEARING_ACCOUNT_ID = 'INTEREST_CLEARING_ACCOUNT_ID',
  IS_AI_ADJUDICATION = 'IS_AI_ADJUDICATION',
  IS_BILL_PAY_ALLOWED = 'IS_BILL_PAY_ALLOWED',
  IS_CARD_OFFERED = 'IS_CARD_OFFERED',
  IS_CUSTOMER_SERVICE_ENABLED = 'IS_CUSTOMER_SERVICE_ENABLED',
  IS_DEPENDENT_CARD_ALLOWED = 'IS_DEPENDENT_CARD_ALLOWED',
  IS_FILE_AUTO_POST_ENABLED = 'IS_FILE_AUTO_POST_ENABLED',
  IS_FILE_MANAGER_ACCESS_ENABLED = 'IS_FILE_MANAGER_ACCESS_ENABLED',
  IS_REIMBURSEMENT_ALLOWED = 'IS_REIMBURSEMENT_ALLOWED',
  LARGE_LOGO_PATH = 'LARGE_LOGO_PATH',
  LOGO = 'LOGO',
  MINIMIZED_LOGO_PATH = 'MINIMIZED_LOGO_PATH',
  MINIMUM_PAYMENT_REIMBURSEMENT_AMOUNT = 'MINIMUM_PAYMENT_REIMBURSEMENT_AMOUNT',
  NUMBER_DAYS_BEFORE_PLAN_START = 'NUMBER_DAYS_BEFORE_PLAN_START',
  OUTGOING_CLEARING_ACCOUNT_ID = 'OUTGOING_CLEARING_ACCOUNT_ID',
  PAYROLL_CONTRIBUTIONS_HOLD_PERIOD = 'PAYROLL_CONTRIBUTIONS_HOLD_PERIOD',
  PREFUND_REPLENISHMENT_CREEP_MIN = 'PREFUND_REPLENISHMENT_CREEP_MIN',
  PREFUND_REPLENISHMENT_CREEP_PERCENT = 'PREFUND_REPLENISHMENT_CREEP_PERCENT',
  PREFUND_REPLENISHMENT_MIN_VALUE = 'PREFUND_REPLENISHMENT_MIN_VALUE',
  PREFUND_REPLENISHMENT_PERCENT = 'PREFUND_REPLENISHMENT_PERCENT',
  PRIN_SETUP = 'PRIN_SETUP',
  PRIVACY_POLICY_LINK = 'PRIVACY_POLICY_LINK',
  REPLENISHMENT_DAY_NUMBER = 'REPLENISHMENT_DAY_NUMBER',
  REPLENISHMENT_FREQUENCY_TYPE = 'REPLENISHMENT_FREQUENCY_TYPE',
  ROOT_EXTERNAL_ACCOUNT_ID = 'ROOT_EXTERNAL_ACCOUNT_ID',
  SESSION_TIMEOUT_LOGOUT = 'SESSION_TIMEOUT_LOGOUT',
  SESSION_TIMEOUT_WARN = 'SESSION_TIMEOUT_WARN',
  SSO_ONLY = 'SSO_ONLY',
  STATEMENT_FREQUENCY_TYPE = 'STATEMENT_FREQUENCY_TYPE',
  STATEMENT_TYPE = 'STATEMENT_TYPE',
  TERMS_AND_CONDITIONS_LINK = 'TERMS_AND_CONDITIONS_LINK',
  THEME_TYPE = 'THEME_TYPE',
  TRUE_SSN = 'TRUE_SSN',
  URL = 'URL',
  WAITING_FOR_FUNDS_CONTINUE_PAYMENT = 'WAITING_FOR_FUNDS_CONTINUE_PAYMENT',
  CLAIMS_ASSIGNMENT_NUMBER = 'CLAIMS_ASSIGNMENT_NUMBER',
  IS_CLAIM_OFFSETS_ALLOWED = 'IS_CLAIM_OFFSETS_ALLOWED',
}
export type OverridablePropertiesPermissions = {
  [key in PermissionKey]: PermissionState;
};

export const ALL_OPTION = {
  key: 'Null',
  value: 'Null',
  title: 'All',
};

export const COUNTRY_MAP: { [key: number]: string } = {
  1: 'USA',
};

export interface FileImage {
  id: string;
  temporalId: string;
  path: string;
  preview: string;
  name: string;
  type: string;
  blob?: Blob | MediaSource;
  url?: string;
}

export enum Roles {
  agent = 'AGENT',
  admin1 = 'ADMIN_1',
  admin2 = 'ADMIN_2',
  admin3 = 'ADMIN_3',
  claimsProcessor1 = 'CLAIMS_PROCESSOR_1',
  claimsProcessor2 = 'CLAIMS_PROCESSOR_2',
  claimsProcessorReview = 'CLAIMS_PROCESSOR_REVIEW',
  claimsProcessorEdit = 'CLAIMS_PROCESSOR_EDIT',
  claimsSupervisor = 'CLAIMS_SUPERVISOR',
  finOps = 'FIN_OPS',
  employee = 'EMPLOYEE',
  superUser = 'SUPER_USER',
  elevateSuperUser = 'ELEVATE_SUPER_USER',

  limitedAccess = 'EMPLOYEE_LIMITED_ACCESS',
  noAccess = 'NO_ACCESS',
}

export const NON_ASSIGNABLE_VALUE = 'N/A';
export const NON_ASSIGNABLE_VALUE_PAYLOAD = -1;

export const SERVER_ERRORS = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  SERVER_ERROR: 500,
};

export enum OrganizationTypes {
  company = 'COMPANY',
  partner = 'PARTNER',
  system = 'SYSTEM',
  employee = 'EMPLOYEE',
  employer = 'EMPLOYER',
  distributor = 'DISTRIBUTOR',

  dependent = 'DEPENDENT',

  subsidiary = 'SUBSIDIARY',
  subgroup = 'SUBGROUP',
}

export const CLAIM_AGREEMENT_ERROR_TEXT = 'Please confirm the claim information submitted is accurate and complete.';

export const FIRST_DAY_NUMBER = '01';
export const INVALID_TEXT = 'The field is invalid';
export const REQUIRED_TEXT = 'This field is required';
export const CUSTOM_DAY_ERROR_TEXT = 'This number does not match the range of available numbers (1-31)';
export const MAX_LENGTH_TEXT = (count: number) => `This field must be at most ${count} characters`;
export const EMAIL_INVALID_TEXT = 'Email must be valid';
export const DATE_INVALID_TEXT = 'Date must be valid';
export const DATE_INVALID_ALTERNATIVE_TEXT = 'Invalid date';
export const DATE_ONLY_FUTURE_TEXT = 'Must be a future date';
export const PHONE_NUMBER_INVALID_TEXT = 'Not a valid 10-digit US phone number';
export const SSN_INVALID_TEXT = 'SSN should be 9-digit number';
export const INVALID_RANGE_TEXT = (min: number, max: number) => `The field value must be between ${min} - ${max}.`;
export const INTEGER_VALUE_TEXT = 'The entered value must be an integer.';

export const ACTIVITY_TYPE = {
  DENTAL: 'dental',
  VISION: 'vision',
  MEDICAL: 'medical',
  PHARMACY: 'pharmacy',
  OTHER: 'other',
  TUITION: 'tuition',
  FEES: 'fees',
  INTEREST: 'interest',
  ADJUSTMENTS: 'adjustments',
  CONTRIBUTIONS: 'contributions',
  INVESTMENTS: 'investments',
  TRANSIT: 'transit',
  PARKING: 'parking',
  ADOPTION: 'adoption',
  FITTNESS: 'fittnes',
  TRAVEL: 'travel',
};

export const ACCOUNTS_TYPES = {
  HSA: 'hsa',
  FSA: 'fsa',
};

export const MIN_DATE = '1910-01-01';
export const DATE_DOTS_FORMAT = 'MM.DD.YYYY';
export const DATETIME_DOTS_FORMAT = 'MM.DD.YYYY_HH.mm.ss';
export const DATE_MONTH_YEAR_FORMAT = 'MM/YY';
export const DEFAULT_CARD_EXP_DATE_FORMAT = 'MM/DD/YY';
export const DEBIT_EXP_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm a';
export const YTD_DATE_FORMAT = 'DD-MMM-YYYY';
export const REPORT_DATE_FORMAT = 'MMM DD YYYY';
export const DATE_MASK_TIME = 'DD MMM YYYY _ h:mm A';
export const INPUT_DATE_FORMAT = 'DD MMM YYYY';
export const DATE_MASK_TIME_WITHOUT_YEAR = 'DD MMM _ h:mm a';
export const DATE_MASK_TIME_WITHOUT_YEAR_WITH_AT = 'DD MMM [at] h:mm a';
export const TIME_FORMAT = 'h:mm a';
export const MINIMIZE_DATE_FORMAT = 'MM/DD/YY';
export const DEFAULT_DATE_MASK = '99/99/9999';
export const DEFAULT_INPUT_DATE_FORMAT = 'DD/MM/YYYY';
export const RAW_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TOOLTIP_FORMAT = 'D MMMM YYYY';

export const SSN_MASK_WITH_DASHES = '999-99-9999';
export const SSN_MASK_WITHOUT_DASHES = '999999999';

export const TWENTIETH_CENTURY = 20;
export const MINUTE = 60000;
export const SECOND = 1000;
export const DEFAULT_EMPTY_CHARACTER = '—';
export const EMPTY_LABEL_SIGN = ' ';
export const LOGO_SIZE = '40px';
export const FAV_SIZE = '16px';

export enum LetterIconSizeEnum {
  LOGO = 'logo',
  FAV = 'fav',
}

export const LARGE_SIZE_LOGO = {
  width: 120,
  height: 40,
};

export const BOOLEAN_STRINGS = {
  TRUE: 'true',
  FALSE: 'false',
};

export const NOT_NUMBERS_REGEXP = new RegExp(/[^0-9]/, 'g');

export const ERRORS_CODE = {
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  CONFLICT: 409,
  SERVER: 500,
};

export enum CommunicationType {
  IMPORTANT_ONLY = 'IMPORTANT_ONLY',
  INTERESTING = 'INTERESTING',
  ALL_NOTIFICATIONS = 'ALL_NOTIFICATIONS',
}

export enum CommunicationChannelsType {
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER',
  EMAIL_SMS = 'EMAIL_SMS',
}

export const US_STATES = [
  { key: 'Alabama', value: 'Alabama' },
  { key: 'Alaska', value: 'Alaska' },
  { key: 'Arizona', value: 'Arizona' },
  { key: 'Arkansas', value: 'Arkansas' },
  { key: 'California', value: 'California' },
  { key: 'Colorado', value: 'Colorado' },
  { key: 'Connecticut', value: 'Connecticut' },
  { key: 'Delaware', value: 'Delaware' },
  { key: 'District of Columbia', value: 'District of Columbia' },
  { key: 'Florida', value: 'Florida' },
  { key: 'Georgia', value: 'Georgia' },
  { key: 'Hawaii', value: 'Hawaii' },
  { key: 'Idaho', value: 'Idaho' },
  { key: 'Illinois', value: 'Illinois' },
  { key: 'Indiana', value: 'Indiana' },
  { key: 'Iowa', value: 'Iowa' },
  { key: 'Kansas', value: 'Kansas' },
  { key: 'Kentucky', value: 'Kentucky' },
  { key: 'Louisiana', value: 'Louisiana' },
  { key: 'Maine', value: 'Maine' },
  { key: 'Maryland', value: 'Maryland' },
  { key: 'Massachusetts', value: 'Massachusetts' },
  { key: 'Michigan', value: 'Michigan' },
  { key: 'Minnesota', value: 'Minnesota' },
  { key: 'Mississippi', value: 'Mississippi' },
  { key: 'Missouri', value: 'Missouri' },
  { key: 'Montana', value: 'Montana' },
  { key: 'Nebraska', value: 'Nebraska' },
  { key: 'Nevada', value: 'Nevada' },
  { key: 'New Hampshire', value: 'New Hampshire' },
  { key: 'New Jersey', value: 'New Jersey' },
  { key: 'New Mexico', value: 'New Mexico' },
  { key: 'New York', value: 'New York' },
  { key: 'North Carolina', value: 'North Carolina' },
  { key: 'North Dakota', value: 'North Dakota' },
  { key: 'Ohio', value: 'Ohio' },
  { key: 'Oklahoma', value: 'Oklahoma' },
  { key: 'Oregon', value: 'Oregon' },
  { key: 'Pennsylvania', value: 'Pennsylvania' },
  { key: 'Rhode Island', value: 'Rhode Island' },
  { key: 'South Carolina', value: 'South Carolina' },
  { key: 'South Dakota', value: 'South Dakota' },
  { key: 'Tennessee', value: 'Tennessee' },
  { key: 'Texas', value: 'Texas' },
  { key: 'Utah', value: 'Utah' },
  { key: 'Vermont', value: 'Vermont' },
  { key: 'Virginia', value: 'Virginia' },
  { key: 'Washington', value: 'Washington' },
  { key: 'West Virginia', value: 'West Virginia' },
];

export const SERVER_ERROR_MODAL_TITLE = 'The Server Encountered an Error Processing the Request';
export const SERVER_ERROR_MODAL_TEXT = 'It doesn\'t appear to have affected your data, but we cannot proceed with your request. Our technical staff have been automatically notified and will be looking into this with the utmost urgency.';
export const CLAIM_OCR_ERROR_TEXT = 'Please correct the highlighted errors to submit the claim. ';

export enum FileType {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif',
  SVG = 'image/svg+xml',
  ICO = 'image/x-icon',
  BMP = 'image/bmp',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  TIFF = 'image/tiff',
}

export const ACCEPTED_FILE_TYPES = `${FileType.JPEG}, ${FileType.JPG}, ${FileType.PNG}, ${FileType.PDF}, ${FileType.HEIC}, ${FileType.HEIF}, ${FileType.TIFF}`;
export const MAX_SIZE_OF_FILE = 10000000;
export const ACCEPTED_LOGO_FILE_TYPES = `${FileType.JPEG}, ${FileType.PNG}, ${FileType.GIF}, ${FileType.SVG}, ${FileType.BMP}, ${FileType.JPG}`;
export const ACCEPTED_EMAIL_LOGO_FILE_TYPES = `${FileType.JPEG}, ${FileType.PNG}, ${FileType.SVG}, ${FileType.JPG}`;
export const MAX_LOGO_FILE_SIZE = 4194303;

export enum RequestHeaders {
  observedOrganizationPath = 'Observed-Organization-Path',
}

export const ROOT_ORGANIZATION = '\\0\\';

export const ONE_HUNDRED_PERCENT = 100;

export const YES_KEY = 'yes';
export const NO_KEY = 'no';
export const TRUE_KEY = 'true';
export const FALSE_KEY = 'false';

export enum FeatureFlagKeys {
  IS_EXPRESS_SETUP_VIA_LINK = 'IS_EXPRESS_SETUP_VIA_LINK',
  TRANSACTION_OCR_MATCHING = 'TRANSACTION_OCR_MATCHING',
  LOCKBOX = 'LOCKBOX'
}
