import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box, dateValidatorByFormat,   Field,
  FieldTypes,
  Inscription,
  Text,   validateTextInputLength, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';
import * as yup from 'yup';

import { REGEXPS } from '@/common';
import {
  DATE_INVALID_TEXT,
  DATE_ONLY_FUTURE_TEXT,
  DEFAULT_DATE_FORMAT,
  EMPTY_FIELD_VALUE,
  OrganizationTypes,
  PermissionKey,
  REQUIRED_TEXT,
} from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useGetPropertiesAccessibility from '@/modules/employer/hooks/useGetPropertiesAccessibility';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import spacings from '@/styles/spacings';
import { isDateInFuture } from '@/utils';

import { CardSetupPayload } from '../../../SetupEmployer.types';

const Label: React.VFC<{ title: string, desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Text margin={{ bottom: 'spacing4' }} weight="bold" color="textBody">{title}</Text>
    <Box>
      <Text weight={500} size="small" color="textSecondary" style={{ lineHeight: spacings.spacing18 }}>
        {desc}
      </Text>
    </Box>
  </Box>
);

export const useCardSetupForm = (
  activateSubmitted?: boolean,
  triggeredId?: string,
) => {
  const {
    isCardPhoneNumberHidden,
    isCardURLHidden,
    isCardEmployerNameHidden,
  } = useGetPropertiesAccessibility();
  const cardsSetup = useStore((state) => state.cardsSetup);
  const setCardSetup = useStore((state) => state.setCardSetup);
  const { mode } = useSetupEmployerMode();
  const currentMode = useMemo(() => mode?.charAt(0)?.toUpperCase() + mode?.slice(1), [mode]);
  const { formattedData } = useGetEmployer();
  useEffect(() => {
    setCardSetup({
      cardOffered: typeof formattedData?.overridableProperties?.cardOffered === 'boolean'
        ? formattedData?.overridableProperties?.cardOffered : true,
      allowDependentCard: formattedData?.overridableProperties?.allowDependentCard,
      numberOfDaysBeforePlanStart: toString(
        formattedData.overridableProperties?.numberOfDaysBeforePlanStart,
      ),
      cardPhoneNumber: isCardPhoneNumberHidden
        ? EMPTY_FIELD_VALUE
        : formattedData.overridableProperties?.cardPhoneNumber,
      cardURL: isCardURLHidden
        ? EMPTY_FIELD_VALUE
        : formattedData.overridableProperties?.cardUrl,
      cardEmployerName: isCardURLHidden
        ? EMPTY_FIELD_VALUE
        : formattedData.overridableProperties?.cardEmployerName,
      bin: formattedData?.overridableProperties?.bin,
      prin: formattedData?.overridableProperties?.prin,
      cardProcessingStartDate: formattedData?.overridableProperties?.cardProcessingStartDate,
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    formattedData,
    isCardPhoneNumberHidden,
    isCardURLHidden,
    isCardURLHidden,
  ]);
  const binPermProps = useGetPermissionToEditOverridableField(PermissionKey.BIN_SETUP);
  const cardEmployerNamePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CARD_EMPLOYER_NAME,
  );
  const cardPhoneNumberPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CARD_PHONE_NUMBER,
  );
  const cardURLPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CARD_URL,
  );
  const cardOfferedPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_CARD_OFFERED,
  );
  const allowDependentCardPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_DEPENDENT_CARD_ALLOWED,
  );
  const numberOfDaysBeforePlanStartPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.NUMBER_DAYS_BEFORE_PLAN_START,
  );
  const isBinPrinDisabled = useMemo(() => formattedData.type !== OrganizationTypes.partner
  && formattedData.type !== OrganizationTypes.distributor, [formattedData.type]);
  const prinPermProps = useGetPermissionToEditOverridableField(PermissionKey.PRIN_SETUP);
  const onChangeValues = useCallback((val: CardSetupPayload) => setCardSetup(val), [setCardSetup]);

  const fields = useMemo(() => ([
    {
      name: 'cardOffered',
      type: FieldTypes.Checkbox,
      label: 'Card',
      defaultValue: typeof formattedData?.overridableProperties?.cardOffered === 'boolean'
        ? formattedData?.overridableProperties?.cardOffered : true,
      value: cardsSetup?.cardOffered,
      onChangeValues,
      ...cardOfferedPermProps,
    },
    ...cardsSetup?.cardOffered ? [
      {
        name: 'allowDependentCard',
        type: FieldTypes.Checkbox,
        label: 'Allow dependent card',
        disabled: false,
        defaultValue: !!formattedData.overridableProperties?.allowDependentCard,
        value: !!cardsSetup?.allowDependentCard,
        onChangeValues,
        ...allowDependentCardPermProps,
      },
      {
        name: 'numberOfDaysBeforePlanStart',
        type: FieldTypes.Number,
        fill: false,
        placeholder: 'Enter # of days',
        label: (
          <Label
            title="Card Production"
            desc="Enter the number of days prior to plan start that cards should start issuing"
          />
        ),
        defaultValue: formattedData.overridableProperties?.numberOfDaysBeforePlanStart,
        value: cardsSetup?.numberOfDaysBeforePlanStart,
        showRequireIcon: true,
        validator: yup.string().required(REQUIRED_TEXT),
        onChangeValues,
        ...numberOfDaysBeforePlanStartPermProps,
      },
      {
        name: 'cardProcessingStartDate',
        type: FieldTypes.Date,
        placeholder: 'Select date',
        inputWrapCssClass: 'small-input',
        onChangeValues,
        validator: yup.string()
          .test({
            test: (val) => val
              ? isDateInFuture(val) || val === formattedData?.overridableProperties?.cardProcessingStartDate
              : true,
            message: DATE_ONLY_FUTURE_TEXT,
          })
          .test({
            test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
            message: DATE_INVALID_TEXT,
          }),
        minDate: dayjs(),
        label: (
          <Box flex={{ grow: 1 }}>
            <Box direction="row" height={{ max: '20px' }} margin={{ bottom: '4px' }}>
              <Inscription weight="bold" color="textBody" lineHeight="20px">
                Card processing start date
              </Inscription>
            </Box>
            <Box>
              <Inscription weight={500} color="textSecondary" size="small" lineHeight="18px">
                When this is set the card production field will not be used
              </Inscription>
            </Box>
          </Box>
        ),
        value: formattedData?.overridableProperties?.cardProcessingStartDate || '',
      },
      {
        name: 'cardPhoneNumber',
        type: FieldTypes.Text,
        label: 'Card phone number',
        placeholder: 'Enter Card phone number',
        ...(activateSubmitted && !cardPhoneNumberPermProps?.disabled ? {
          validator: yup.string().when('cardOffered',
            {
              is: (val: boolean) => val,
              then: yup.string()
                .trim().test(validateTextInputLength('Card phone number isn\'t valid', 26))
                .required(REQUIRED_TEXT),
            }),
        } : {}),
        showRequireIcon: true,
        defaultValue: formattedData.overridableProperties?.cardPhoneNumber,
        value: cardsSetup?.cardPhoneNumber,
        ...isCardPhoneNumberHidden ? {
          disabled: true,
          showRequireIcon: false,
          defaultValue: EMPTY_FIELD_VALUE,
          value: EMPTY_FIELD_VALUE,
        } : {},
        onChangeValues,
        ...cardPhoneNumberPermProps,
      },
      {
        name: 'cardURL',
        type: FieldTypes.Text,
        label: 'Card URL',
        placeholder: 'Enter Card URL',
        ...(activateSubmitted && !cardURLPermProps?.disabled ? {
          validator: yup.string().when('cardOffered', {
            is: (val: boolean) => val,
            then: yup.string()
              .trim().test(validateTextInputLength('Card URL must have 50 characters or less', 50))
              .test('No parentheses', 'Card URL isn\'t valid',
                (val) => (!!val && !REGEXPS.PARENTHESES.test(val)))
              .required(REQUIRED_TEXT),
          }),
        } : {}),
        showRequireIcon: true,
        defaultValue: formattedData.overridableProperties?.cardUrl,
        value: cardsSetup?.cardURL,
        ...isCardURLHidden ? {
          disabled: true,
          showRequireIcon: false,
          defaultValue: EMPTY_FIELD_VALUE,
          value: EMPTY_FIELD_VALUE,
        } : {},
        onChangeValues,
        ...cardURLPermProps,
      },
      {
        name: 'cardEmployerName',
        type: FieldTypes.Text,
        label: `Card ${currentMode} name`,
        placeholder: `Enter Card ${currentMode} name`,
        ...(activateSubmitted && !cardEmployerNamePermProps?.disabled ? {
          validator: yup.string().when('cardOffered', {
            is: (val: boolean) => val,
            then: yup.string()
              .trim().test(validateTextInputLength(`Card ${currentMode} name must have 26 characters or less`, 26))
              .test('No parentheses', `Card ${currentMode} name isn't valid`,
                (val) => (!!val && !REGEXPS.PARENTHESES.test(val)))
              .required(REQUIRED_TEXT),
          }),
        } : {}),
        showRequireIcon: true,
        defaultValue: formattedData.overridableProperties?.cardEmployerName,
        value: cardsSetup?.cardEmployerName,
        ...isCardEmployerNameHidden ? {
          disabled: true,
          showRequireIcon: false,
          defaultValue: EMPTY_FIELD_VALUE,
          value: EMPTY_FIELD_VALUE,
        } : {},
        onChangeValues,
        ...cardEmployerNamePermProps,
      },
      {
        name: 'bin',
        type: FieldTypes.Text,
        label: 'BIN setup',
        placeholder: EMPTY_FIELD_VALUE,
        disabled: isBinPrinDisabled,
        defaultValue: formattedData?.overridableProperties?.bin,
        value: cardsSetup?.bin,
        onChangeValues,
        ...binPermProps,
      },
      {
        name: 'prin',
        type: FieldTypes.Text,
        label: 'PRIN setup',
        placeholder: EMPTY_FIELD_VALUE,
        disabled: isBinPrinDisabled,
        defaultValue: formattedData?.overridableProperties?.prin,
        value: cardsSetup?.prin,
        onChangeValues,
        ...prinPermProps,
      },
      {
        name: 'fourthLine',
        type: FieldTypes.Text,
        label: 'Fourth line on card',
        placeholder: 'Fourth line on card  (Optional)',
        validator: yup.string(),
        defaultValue: cardsSetup?.fourthLine,
        value: cardsSetup?.fourthLine,
        onChangeValues,
      },
    ] : [],
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ]), [
    onChangeValues,
    isBinPrinDisabled,
    activateSubmitted,
    currentMode,
    cardsSetup,
    formattedData,
    isCardPhoneNumberHidden,
    isCardURLHidden,
    isCardEmployerNameHidden,
    binPermProps,
    cardEmployerNamePermProps,
    cardPhoneNumberPermProps,
    cardURLPermProps,
    cardOfferedPermProps,
    allowDependentCardPermProps,
    numberOfDaysBeforePlanStartPermProps,
    prinPermProps,
    triggeredId,
  ]);

  return fields as Field[];
};
