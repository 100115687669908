import { CSSProperties } from 'react';
import { costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT, REPORT_DATE_FORMAT } from '@/common/constants';
import {
  GroupByFilter,
  InvoiceReportTableHeaderEnum,
  ReplenishmentInvoiceReportGroup,
  ReplenishmentInvoiceReportIndicator,
  ReportItemNestedDataGroup,
  ReportItemNestedDataRowClassNames,
  ReportTableItem,
} from '@/modules/employer/components/Reports/report.types';
import colors from '@/styles/colors';
import spacings from '@/styles/spacings';

interface DateRangeParam {
  startDate: Date | null,
  endDate: Date | null,
}

const generateFields = (
  date: string,
  amount: number,
  invoiceId?: number,
  isWithBorder?: boolean,
): ReportTableItem[] => [
  {
    key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
    value: date,
    ...(isWithBorder && {
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
      },
    }),
  },
  {
    key: InvoiceReportTableHeaderEnum.INVOICE_ID,
    value: invoiceId || ' - ',
    style: {
      alignItems: 'center',
    },
    ...(!invoiceId && {
      textStyle: {
        color: 'transparent',
      },
    }),
    ...(isWithBorder && {
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
        marginLeft: '0px',
      },
    }),
  },
  {
    key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
    value: costFormater(amount, { isPrecision: true }),
    style: {
      alignItems: 'flex-end',
    },
    ...(isWithBorder && {
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
      },
    }),
  },
];

const generateTotalFields = (
  data: {
    amount: number,
    summaryTotal?: boolean,
    style?: CSSProperties,
    totalName?: string,
    dateRange?: DateRangeParam,
  } = {
    totalName: 'Total',
    amount: 0,
    summaryTotal: false,
  },
): ReportTableItem[] => {
  const {
    amount,
    summaryTotal,
    style,
    totalName,
    dateRange,
  } = data;
  return [
    {
      key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
      value: totalName || `Total${summaryTotal ? ` ${
        dateRange?.startDate && dateRange?.endDate
          ? `(${dayjs(dateRange.startDate).format(DEFAULT_DATE_FORMAT)}—${dayjs(dateRange.endDate).format(DEFAULT_DATE_FORMAT)})`
          : ''
      }` : ''}`,
      // value: totalName || `Total (${
      //   dateRange?.startDate && dateRange?.endDate
      //     ? `${dayjs(dateRange.startDate).format(DEFAULT_DATE_FORMAT)}-${dayjs(dateRange.endDate).format(DEFAULT_DATE_FORMAT)}`
      //     : summaryTotal
      // })`,
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
      },
      style: {
        backgroundColor: colors.warningBorder,
        padding: summaryTotal ? `${spacings.spacing6} ${spacings.spacing8}` : spacings.spacing4,
        borderRadius: `${spacings.spacing4} 0px 0px ${spacings.spacing4}`,
        marginBottom: summaryTotal ? spacings.spacing8 : spacings.spacing4,
        marginTop: spacings.spacing4,
        ...style,
      },
      textStyle: {
        fontWeight: 'bold',
        ...summaryTotal
          ? {
            fontSize: '14px',
          }
          : {},
      },
    },
    {
      key: InvoiceReportTableHeaderEnum.INVOICE_ID,
      value: ' - ',
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
        marginLeft: '0px',
      },
      style: {
        backgroundColor: colors.warningBorder,
        padding: summaryTotal ? `${spacings.spacing6} ${spacings.spacing8}` : spacings.spacing4,
        marginBottom: summaryTotal ? spacings.spacing8 : spacings.spacing4,
        marginTop: spacings.spacing4,
        ...style,
      },
      textStyle: {
        color: 'transparent',
        ...summaryTotal
          ? {
            fontSize: '14px',
          }
          : {},
      },
    },
    {
      key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
      value: costFormater(amount, { isPrecision: true }),
      wrapStyle: {
        borderBottom: `1px solid ${colors.border1}`,
      },
      style: {
        alignItems: 'flex-end',
        backgroundColor: colors.warningBorder,
        padding: summaryTotal ? `${spacings.spacing6} ${spacings.spacing8}` : spacings.spacing4,
        borderRadius: `0px ${spacings.spacing4} ${spacings.spacing4} 0px`,
        marginBottom: summaryTotal ? spacings.spacing8 : spacings.spacing4,
        marginTop: spacings.spacing4,
        ...style,
      },
      textStyle: {
        fontWeight: 'bold',
        ...summaryTotal
          ? {
            fontSize: '14px',
          }
          : {},
      },
    },
  ];
};

const generateGroupedTotalFields = (data: ReplenishmentInvoiceReportGroup): ReportTableItem[] => [
  {
    key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
    value: `Total (${data.group_name})`,
    style: {
      backgroundColor: colors.warningBorder,
      paddingInline: spacings.spacing4,
      borderRadius: `${spacings.spacing4} 0 0 ${spacings.spacing4}`,
      marginBottom: spacings.spacing8,
      marginLeft: spacings.spacing8,
      marginTop: spacings.spacing2,
    },
    textStyle: {
      fontWeight: 'bold',
    },
  },
  {
    key: InvoiceReportTableHeaderEnum.INVOICE_ID,
    value: ' - ',
    wrapStyle: {
      marginLeft: '0px',
    },
    style: {
      backgroundColor: colors.warningBorder,
      padding: spacings.spacing4,
      marginBottom: spacings.spacing8,
      marginTop: spacings.spacing2,
    },
    textStyle: {
      color: 'transparent',
    },
  },
  {
    key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
    value: costFormater(data.total_amount || 0, { isPrecision: true }),
    style: {
      alignItems: 'flex-end',
      backgroundColor: colors.warningBorder,
      padding: spacings.spacing4,
      borderRadius: `0 ${spacings.spacing4} ${spacings.spacing4} 0`,
      marginBottom: spacings.spacing8,
      marginTop: spacings.spacing2,
    },
    textStyle: {
      fontWeight: 'bold',
    },
  },
];

const formattingVariantGroupedByPartner = (
  response: ReplenishmentInvoiceReportGroup[],
  dateRange: DateRangeParam,
): ReportItemNestedDataGroup[] => ([
  ...response.map((group) => ({
    title: group.group_name,
    id: _.uniqueId('group_'),
    key: group.group_name,
    rows: [
      ...group.indicators.map((indicator) => {
        const local = indicator as ReplenishmentInvoiceReportIndicator;
        return {
          id: `${local.invoice_id}-${local.invoice_close_date}-${group.group_name}`,
          classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
          fields: generateFields(
            dayjs(local?.invoice_close_date).format(REPORT_DATE_FORMAT),
            local.total_amount_due,
            local.invoice_id,
          ),
        };
      }),
      ...group.total_amount
        ? [{
          id: `total_${group.group_name}`,
          key: 'total',
          classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
          fields: generateTotalFields({
            amount: group.total_amount,
            totalName: `Total (${group.group_name})`,
          }),
        }] : [],
    ],
  })),
  {
    key: 'total',
    id: _.uniqueId('group_'),
    rows: [
      {
        id: 'total_summary',
        classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
        fields: generateTotalFields({
          dateRange,
          amount: response.reduce((total, value) => (value.total_amount || 0) + total, 0),
          summaryTotal: true,
        }),
      },
    ],
  },
]);

const formattingVariantGroupedByDateOnly = (
  response: ReplenishmentInvoiceReportGroup[],
  dateRange: DateRangeParam,
): ReportItemNestedDataGroup[] => [
  ...response.map((group) => {
    const localIndicators = group.indicators as ReplenishmentInvoiceReportIndicator[];
    return {
      id: _.uniqueId('group_'),
      title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
      key: group.group_name,
      rows: [
        ...group.indicators.map((indicator) => {
          const local = indicator as ReplenishmentInvoiceReportIndicator;
          return {
            id: `${local.invoice_id}-${local.invoice_close_date}`,
            classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
            fields: generateFields(
              '',
              local.total_amount_due,
              local.invoice_id,
            ),
          };
        }),
        {
          id: `total_${group.group_name}`,
          key: 'total',
          classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
          // style: {
          //   borderBottom: `1px solid ${colors.border1}`,
          // },
          fields: generateTotalFields({
            amount: localIndicators
              .reduce(
                (total, indicator) => total + indicator.total_amount_due,
                0,
              ),
            style: {
              marginLeft: '0px',
            },
            totalName: `Total (${dayjs(group.group_name).format(REPORT_DATE_FORMAT)})`,
          }),
        },
      ],
    };
  }),
  {
    key: 'total',
    rows: [
      {
        id: 'total_summary',
        classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
        fields: generateTotalFields({
          dateRange,
          amount: response.reduce((total, value) => (value.total_amount || 0) + total, 0),
          summaryTotal: true,
        }),
      },
    ],
  },
];

const formattingVariantGroupedByPartnerAndEmployer = (
  response: ReplenishmentInvoiceReportGroup[],
  dateRange: DateRangeParam,
): ReportItemNestedDataGroup[] => ([
  ...response.map((group) => {
    const localIndicators = group.indicators as ReplenishmentInvoiceReportGroup[];
    return {
      title: group.group_name,
      id: _.uniqueId('group_'),
      key: group.group_name,
      rows: [
        {
          id: `total_${group.group_name}`,
          key: `total_${group.group_name}`,
          classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
          lastRow: true,
          wrapStyle: {
            borderBottom: `1px solid ${colors.border1}`,
          },
          fields: generateGroupedTotalFields(group),
        },
      ],
      children: localIndicators.map((employer, index) => ({
        title: employer.group_name,
        id: _.uniqueId('group_'),
        key: `${group.group_name}_${employer.group_name}`,
        rows: [
          ...employer.indicators.map((indicator) => {
            const local = indicator as ReplenishmentInvoiceReportIndicator;
            return {
              id: `${local.invoice_id}`,
              classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
              fields: generateFields(
                dayjs(local?.invoice_close_date).format(REPORT_DATE_FORMAT),
                local.total_amount_due,
                local.invoice_id,
              ),
            };
          }),
          {
            id: `total_${group.group_name}_${employer.group_name}`,
            key: `total_${group.group_name}_${employer.group_name}`,
            classNames: [ReportItemNestedDataRowClassNames.TOTAL_HOVER],
            fields: [
              {
                key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
                value: `Total (${employer.group_name})`,
                wrapStyle: localIndicators.length - 1 === index ? {
                  borderBottom: `1px solid ${colors.border1}`,
                } : {},
              },
              {
                key: InvoiceReportTableHeaderEnum.INVOICE_ID,
                value: ' - ',
                wrapStyle: localIndicators.length - 1 === index ? {
                  borderBottom: `1px solid ${colors.border1}`,
                  marginLeft: '0px',
                } : {},
                textStyle: {
                  color: 'transparent',
                },
              },
              {
                key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
                value: costFormater(employer.total_amount, { isPrecision: true }),
                wrapStyle: localIndicators.length - 1 === index ? {
                  borderBottom: `1px solid ${colors.border1}`,
                } : {},
                style: {
                  alignItems: 'flex-end',
                },
              },
            ],
          },
        ],
      })),
    };
  }),
  {
    key: 'total',
    id: _.uniqueId('group_'),
    rows: [
      {
        id: 'total_summary',
        classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
        style: {
          borderBottom: `1px solid ${colors.border1}`,
        },
        fields: generateTotalFields({
          dateRange,
          amount: response.reduce((total, value) => (value.total_amount || 0) + total, 0),
          summaryTotal: true,
        }),
      },
    ],
  },
]);

const formattingVariantGroupedByDatePartner = (
  response: ReplenishmentInvoiceReportGroup[],
  dateRange: DateRangeParam,
): ReportItemNestedDataGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId('group_'),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: group.group_name,
    rows: [
      ...group.indicators.reduce((acc, indicator) => {
        const localGroup = indicator as ReplenishmentInvoiceReportGroup;

        const innerLocalGroup = localGroup.indicators as ReplenishmentInvoiceReportIndicator[];
        const innerInvoices = innerLocalGroup.map((innerIndicator) => {
          const local = innerIndicator as ReplenishmentInvoiceReportIndicator;
          return {
            id: `${local.invoice_id}-${local.invoice_close_date}-${_.uniqueId()}`,
            classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
            fields: generateFields(
              '',
              local.total_amount_due,
              local.invoice_id,
            ),
          };
        });

        return [
          ...acc,
          ...innerInvoices,
          {
            id: _.uniqueId('group_'),
            classNames: [ReportItemNestedDataRowClassNames.TOTAL_HOVER],
            fields: generateFields(
              localGroup.group_name,
              localGroup.total_amount || 0,
              undefined,
              true,
            ),
          },
        ];
      }, [] as {
        id: string;
        fields: ReportTableItem[];
      }[]),
      ...group.total_amount
        ? [{
          id: _.uniqueId('group_'),
          key: `total_${group.group_name}`,
          classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
          fields: generateTotalFields({
            amount: group.total_amount,
            totalName: `Total (${dayjs(group.group_name).format(REPORT_DATE_FORMAT)})`,
          }),
        }] : [],
    ],
  })),
  {
    rows: [
      {
        id: 'total_summary',
        classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
        fields: generateTotalFields({
          dateRange,
          amount: response.reduce((total, value) => (value.total_amount || 0) + total, 0),
          summaryTotal: true,
        }),
      },
    ],
  },
]);

const formattingVariantGroupedByDatePartnerAndEmployer = (
  response: ReplenishmentInvoiceReportGroup[],
  dateRange: DateRangeParam,
): ReportItemNestedDataGroup[] => ([
  ...response.map((group) => {
    const localIndicators = group.indicators as ReplenishmentInvoiceReportGroup[];
    return {
      title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
      key: group.group_name,
      id: _.uniqueId('group_'),
      rows: [
        {
          id: _.uniqueId('group_'),
          classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
          lastRow: true,
          style: {
            borderBottom: `1px solid ${colors.border1}`,
          },
          fields: generateGroupedTotalFields({
            ...group,
            group_name: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
          }),
        },
      ],
      children: localIndicators.map((employer) => ({
        title: employer.group_name,
        id: _.uniqueId('group_'),
        key: `${group.group_name}-${employer.group_name}`,
        rows: [
          ...employer.indicators.reduce((acc, indicator) => {
            const localGroup = indicator as ReplenishmentInvoiceReportGroup;

            const innerLocalGroup = localGroup.indicators as ReplenishmentInvoiceReportIndicator[];
            const innerInvoices = innerLocalGroup.map((innerIndicator) => {
              const local = innerIndicator as ReplenishmentInvoiceReportIndicator;
              return {
                id: `${local.invoice_id}-${local.invoice_close_date}-${_.uniqueId()}`,
                classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
                fields: generateFields(
                  '',
                  local.total_amount_due,
                  local.invoice_id,
                ),
              };
            });

            return [
              ...acc,
              ...innerInvoices,
              {
                id: _.uniqueId('group_'),
                classNames: [ReportItemNestedDataRowClassNames.REGULAR_HOVER],
                fields: generateFields(
                  localGroup.group_name,
                  localGroup.total_amount || 0,
                  undefined,
                  true,
                ),
              },
            ];
          }, [] as {
            id: string;
            fields: ReportTableItem[];
          }[]),
          {
            id: _.uniqueId('group_'),
            key: `Total (${employer.group_name})_${group.group_name}`,
            classNames: [ReportItemNestedDataRowClassNames.TOTAL_HOVER],
            fields: [
              {
                key: InvoiceReportTableHeaderEnum.INVOICE_DATE,
                value: `Total (${employer.group_name})`,
                style: {
                  borderBottom: `1px solid ${colors.border1}`,
                },
              },
              {
                key: InvoiceReportTableHeaderEnum.INVOICE_ID,
                value: ' - ',
                wrapStyle: {
                  borderBottom: `1px solid ${colors.border1}`,
                  marginLeft: '0px',
                },
                textStyle: {
                  color: 'transparent',
                },
              },
              {
                key: InvoiceReportTableHeaderEnum.TOTAL_AMOUNT_DUE,
                value: costFormater(employer.total_amount, { isPrecision: true }),
                style: {
                  alignItems: 'flex-end',
                  borderBottom: `1px solid ${colors.border1}`,
                },
              },
            ],
          },
        ],
      })),
    };
  }),
  {
    rows: [
      {
        id: 'total_summary',
        classNames: [ReportItemNestedDataRowClassNames.NO_PAD],
        fields: generateTotalFields({
          amount: response.reduce((total, value) => (value.total_amount || 0) + total, 0),
          summaryTotal: true,
          dateRange,
        }),
      },
    ],
  },
]);

export const generateGroupReplenishmentInvoiceReport = (
  data: {
    response: ReplenishmentInvoiceReportGroup[],
    groupByType: GroupByFilter | null,
    dateRange: [Date | null, Date | null],
  },
): ReportItemNestedDataGroup[] => {
  const {
    response,
    groupByType,
    dateRange,
  } = data;
  const range = {
    startDate: dateRange[0],
    endDate: dateRange[1],
  };
  switch (groupByType) {
  case GroupByFilter.Partner: {
    return formattingVariantGroupedByPartner(response, range);
  }
  case GroupByFilter.Employer: {
    return formattingVariantGroupedByPartner(response, range);
  }
  case GroupByFilter.DateOnly: {
    return formattingVariantGroupedByDateOnly(response, range);
  }
  case GroupByFilter.PartnerAndEmployer: {
    return formattingVariantGroupedByPartnerAndEmployer(response, range);
  }
  case GroupByFilter.DatePartner: {
    return formattingVariantGroupedByDatePartner(response, range);
  }
  case GroupByFilter.DateEmployer: {
    return formattingVariantGroupedByDatePartner(response, range);
  }
  case GroupByFilter.DatePartnerAndEmployer: {
    return formattingVariantGroupedByDatePartnerAndEmployer(response, range);
  }
  default:
    return [] as ReportItemNestedDataGroup[];
  }
};
