import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { OptionKey } from '@common-fe/common-fe';

import { api } from '@/api';
import { PATHS } from '@/common';

const QUERY_KEY = 'getEmailTemplatesQueryKey';

interface EmailTemplateDto {
  category_type: string;
  id: number;
  notification_description: string;
  notification_type: string;
  template_description: string;
  user_type: string;
}

interface ResponseData {
  content: EmailTemplateDto[];
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
}

interface Props {
  templatesIds?: number[];
  categories?: OptionKey[]
  userRole?: string;
  searchString?: string,
  page?: number,
  perPage?: number,
}

export const useGetEmailTemplatesQuery = ({
  templatesIds,
  categories,
  userRole,
  searchString,
  page,
  perPage,
}: Props) => {
  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEY, templatesIds, categories, userRole, searchString, page, perPage],
    templatesIds
      ? () => api.get<ResponseData>(PATHS.GET_EXTERNAL_EMAIL_TEMPLATES, {
        params: {
          template_ids: templatesIds.join(','),
          ...perPage ? { size: perPage } : {},
          ...page ? { page } : {},
          ...searchString ? { name: searchString } : {},
          ...categories?.length ? { category_types: categories.join(',') } : {},
          ...userRole ? { user_role: userRole } : {},

        },
      })
      : () => null,
    {
      cacheTime: 0,
    },
  );

  const formattedData = useMemo(() => data?.data.content, [data]);

  return {
    data: data?.data.content,
    formattedData,
    totalPages: data?.data.totalPages,
    totalElements: data?.data.totalElements,
    isLoading,
    refetch,
  };
};
