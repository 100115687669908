import React, { useEffect, useRef } from 'react';
import { Box } from '@common-fe/common-fe';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import lockAnimationData from './lock-animation.json';
import unlockAnimationData from './unlock-animation.json';

export enum LockAnimatedIconType {
  LOCK = 'lock',
  UNLOCK = 'unlock',
}

interface Props {
  id: string;
  type: LockAnimatedIconType;
  size?: string | number;
  play?: boolean;
  color?: string;
}
const LockAnimatedIcon: React.FC<Props> = ({
  size,
  play,
  id,
  type,
  color,
}) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const applyColor = () => {
      if (containerRef.current && color) {
        const elements = containerRef.current.querySelectorAll('path, circle, rect, polygon');
        elements.forEach((element) => {
          (element as HTMLElement).style.fill = color;
        });
      }
    };

    const observer = new MutationObserver(() => {
      applyColor();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, { childList: true, subtree: true });
    }

    applyColor(); // Apply color initially

    return () => {
      observer.disconnect();
    };
  }, [color]);

  useEffect(() => {
    if (lottieRef.current && type === LockAnimatedIconType.LOCK) {
      lottieRef.current.setSpeed(1.5);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="lock-animated-icon" key={id} ref={containerRef}>
      <Lottie
        lottieRef={lottieRef}
        animationData={
          type === LockAnimatedIconType.LOCK
            ? lockAnimationData
            : unlockAnimationData
        }
        loop={false}
        autoplay={play}
        style={{ width: size, height: size }}
      />
    </Box>
  );
};

export default LockAnimatedIcon;
