import styled from 'styled-components';

export const Button = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
`;
export const HoverWrapper = styled.div`
  position: relative;
  border: none;
  background-color: transparent;
`;
interface PopupProps {
  visible: boolean;
  flex?: boolean;
  flat?: boolean;
}
export const Popup = styled.div<PopupProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: ${({ flex }) => (flex ? undefined : 0)};
  right: 0;
  z-index: 100;
  padding: ${({ flat }) => (flat ? '' : '5px 16px')};
  background-color: ${({ theme }) => theme.colors.canvas};
  ${({ theme }) => theme.shadows.default};
  border-radius: ${({ theme }) => theme.rounds.fieldRound};
`;

interface WrapperProps {
  hover?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  ${Popup} {
    ${({ hover }) => {
    if (hover) {
      return { display: 'none' };
    }
    return null;
  }}
  }
  &:hover {
    ${Popup} {
      ${({ hover }) => {
    if (hover) {
      return { display: 'block' };
    }
    return null;
  }}
    }
  }
`;
