import React, { useState } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { ExpandedItemLevel } from '../ProcessingDefinition.types';

import ExpandButton from './ExpandButton';
import TreeIndicator, { TreeIndicatorMode } from './TreeIndicator';

interface Props extends ExpandedItemLevel {
  children?: React.ReactNode;
  testId?: string;
}
const ExpandedItemFirstLevel: React.FC<Props> = ({
  children,
  testId,
  title,
  subTitle,
}) => {
  const [expanded, setExpanded] = useState(true);
  return (

    <Box data-testid={testId} direction="column" pad={{ horizontal: 's' }}>
      <Box direction="row" align="center" margin={{ bottom: 's' }}>
        <Box style={{ position: 'relative' }}>
          <TreeIndicator
            mode={TreeIndicatorMode.Up}
          />
          <ExpandButton testId={testId} onChange={setExpanded} value={expanded} />
        </Box>
        <Box
          border={{ side: 'top', color: 'border1', size: '1px' }}
          direction="column"
          fill
          margin={{ left: 'xs' }}
        >
          <Text
            size="small"
            color="textSecondary"
            margin={{
              top: 's',
              bottom: 'xxs',
            }}
          >
            {subTitle}
          </Text>

          <Text
            size="medium"
            color="textBody"
            weight={700}
            margin={{
              top: 'xxxs',

            }}
          >
            {title}
          </Text>

        </Box>

      </Box>
      {expanded && (
        <Box
          direction="column"
          margin={{ left: 'xl' }}
          data-testid={`${testId}_content`}
        >
          {children}
        </Box>
      )}

    </Box>
  );
};
export default ExpandedItemFirstLevel;
