import { useMemo, useState } from 'react';
import { OptionKey, usePaginationParams } from '@common-fe/common-fe';

import { ALL_OPTION, PlanStatus } from '@/common/constants';
import { DefaultSortTypesEnum } from '@/common/types';
import { useAllOptionsToggle, useCurrentOrganization } from '@/modules/core/hooks';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';

import useScheduledContributionStore from '../../stores/useScheduledContribution.store';
import useScheduledContributionRecordsQuery from '../queries/useScheduledContributionRecords.query';
const DEFAULT_PER_PAGE = 10;


export default () => {
  const {
    observingOrganization: { id: orgId },
  } = useCurrentOrganization();
  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.ASC);
  const { options: accountsOptions } = useGetOrganizationPlansQuery(orgId, PlanStatus.Active);
  const [currentAccounts, setCurrentAccounts] = useState<OptionKey[]>([ALL_OPTION.value]);
  const computedSelectedAccounts = useAllOptionsToggle(currentAccounts);
  const filteredOptions = useMemo(
    () => computedSelectedAccounts.filter((item) => item !== ALL_OPTION.value),
    [computedSelectedAccounts]
  );
  const { page, setPage, setPerPage, perPage, currentSearch, searchString, setSearchString } =
    usePaginationParams(DEFAULT_PER_PAGE);

  const calendarId = useScheduledContributionStore((state) => state.state.calendarId);
  const startDate = useScheduledContributionStore((state) => state.state.startDate);
  
  const { formattedData, total, isLoading } = useScheduledContributionRecordsQuery({
    calendarId: calendarId || '',
    startDate,
    currentAccounts: filteredOptions,
    searchString: currentSearch,
    page: page - 1 || 0,
    perPage,
    sortBy
  });

  return {
    isLoading,
    total,
    data: formattedData,
    accountsOptions,
    currentAccounts: computedSelectedAccounts,
    setCurrentAccounts,
    page,
    setPage,
    perPage,
    searchString,
    setSearchString,
    setPerPage,

    sortBy,
    setSortBy,

  };
};
