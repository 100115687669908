import { storeCreator } from '@/utils/store/store.utils';

export interface State {
  activeSection?: string;
  activeModule?: string;
  hideHeader?: boolean;
  handleReset: () => void;
  setActiveSection: (value?: string) => void;
  setActiveModule: (value?: string) => void;
  setHideHeader: (value?: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  activeSection: undefined,
  activeModule: undefined,
  hideHeader: false,
  handleReset: () => set(() => ({
    activeSection: undefined,
    activeModule: undefined,
    hideHeader: false,
  })),
  setActiveSection: (value?: string) => set(() => ({
    activeSection: value,
  })),
  setActiveModule: (value?: string) => set(() => ({
    activeModule: value,
  })),
  setHideHeader: (value?: boolean) => set(() => ({
    hideHeader: value,
  })),
}));

export default useStore;
