import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Preloader } from '@common-fe/common-fe';

import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useAuthStore } from '@/modules/user/stores';

import EmployerSetupPage from './EmployerSetup.page';

export const CreateEmployerPage = () => {
  const { user } = useAuthStore();
  const { isExact } = useRouteMatch();
  const { isLoading } = useGetEmployer();

  if (user && isExact && !isLoading) return <EmployerSetupPage />;

  return <Preloader testId="no_user" />;
};
