import { baseStoreCreator } from '@/utils/store';

export interface GracePeriodPayload {

  endOfCoverage: string;
  endOfCoverageAmount: string;

  gracePeriod: string;
  gracePeriodAmount: string;

  claimsDeadline: string;
  claimsDeadlinePlanYearAmount: string;
  claimsDeadlineCoverageDateAmount: string;

  runOut: string;
  runOutAmount: string;
}

export const DEFAULT_VALUES: GracePeriodPayload = {
  endOfCoverage: '',
  endOfCoverageAmount: '',

  gracePeriod: '',
  gracePeriodAmount: '',

  claimsDeadline: '',
  claimsDeadlinePlanYearAmount: '',
  claimsDeadlineCoverageDateAmount: '',

  runOut: '',
  runOutAmount: '',
};

export const useStore = baseStoreCreator<GracePeriodPayload>(DEFAULT_VALUES);

export default useStore;
