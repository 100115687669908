import React from 'react';
import {
  Box, Text,
} from '@common-fe/common-fe';

import { PaymentRule } from '../queries/useServiceConfigs.query';

import PaymentRuleDetails from './PaymentRuleDetails';

interface Props {
  value: PaymentRule;
  isFirstItem: boolean;
  ruleNumber: number;
}

const PaymentRuleItem: React.FC<Props> = ({
  value,
  isFirstItem,
  ruleNumber,
}) => (
  <Box
    key={value.planId}
    pad="m"
    background="module"
    round="fieldRound"
    margin={{ top: isFirstItem ? 'm' : 'xs' }}
    data-testId="payment-rule-item"
    height={{ min: '150px' }}
  >
    <Text
      size="large"
      color="textBody"
      weight={700}
      margin={{
        bottom: 's',
      }}
    >
      {`Payment Rule ${ruleNumber}`}
    </Text>
    <Box direction="row" justify="stretch">
      <PaymentRuleDetails
        plan={value.planName}
        isMarginApplied
      />
      <PaymentRuleDetails
        maxAmount={value.maxAmount}
        percentage={value.percentage}
      />
    </Box>
  </Box>
);

export default PaymentRuleItem;
