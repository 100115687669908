import { baseStoreCreator } from '@/utils/store';

export enum PlanStatuses {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PENDING_ACTIVE = 'PENDING_ACTIVE',
  READY_FOR_SETUP = 'READY_FOR_SETUP',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}
export interface PlanNamePayload {
  planStatus: PlanStatuses;
  planName: string;
  planType: string;
  planCode: string;
  planTemplate: string;
  priorPlanId: string;
  planSummary: string;
}

export const DEFAULT_VALUES: PlanNamePayload = {
  planName: '',
  planStatus: PlanStatuses.DRAFT,
  planType: '',
  planCode: '',
  planTemplate: '',
  priorPlanId: '',
  planSummary: '',
};

const usePlanNameStore = baseStoreCreator<PlanNamePayload>(DEFAULT_VALUES);

export default usePlanNameStore;
