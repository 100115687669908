import React, { CSSProperties, useCallback } from 'react';
import {
  Box,
  Table, TableBody, TableCell, TableHeader, TableRow,   Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { INPUT_DATE_FORMAT } from '@/common/constants';
import { ReportTableItem, ReportTableRowItem } from '@/modules/employer/components/Reports/report.types';

interface Props {
  header: ReportTableItem[];
  rows: ReportTableRowItem[];
  wrapStyle?: CSSProperties;
  expand?: boolean;
  isFullWidth?: boolean;
}

const StyledTable = styled(Table)`
  thead tr {
    background-color: ${({ theme }) => theme.colors.canvas};
    position: sticky;
    top: 0;
    border-top: solid 1px ${({ theme }) => theme.colors.border1};
    border-bottom: solid 1px ${({ theme }) => theme.colors.border1};
  }
  td {
    padding: ${({ theme }) => theme.spacings.spacing8} 0;
  }
`;

interface RowProps {
  fields: ReportTableItem[];
  isFullWidth?: boolean;
}

export const ReportItemTableRow: React.FC<RowProps> = ({ fields, isFullWidth }) => {
  const fieldValue = useCallback((field: ReportTableItem) => (field.key === 'invoice_date' && !field.isHeader
    ? dayjs(field.value).format(INPUT_DATE_FORMAT)
    : field.value), []);
  return (
    <TableRow>
      {
        fields.map((field) => (
          <TableCell
            key={field.key}
            style={{ ...field.flex ? { flex: field.flex } : {}, ...field.style }}
          >
            <Text
              style={{
                whiteSpace: 'nowrap',
                ...field.textStyle,
              }}
              size="12px"
              weight={500}
              color={field.isHeader ? 'textSecondary' : 'textBody'}
            >
              {fieldValue(field)}
            </Text>
          </TableCell>
        ))
      }
    </TableRow>
  );
};

export const ReportItemTableContent: React.FC<Props> = ({
  header,
  rows,
  isFullWidth,
  wrapStyle,
}) => (
  <Box style={{ ...wrapStyle }}>
    <StyledTable>
      <TableHeader>
        <ReportItemTableRow isFullWidth={isFullWidth} fields={header} />
      </TableHeader>
      <TableBody>
        {
          rows.map((row) => (
            <ReportItemTableRow key={row.id} fields={row.fields} />
          ))
        }
      </TableBody>
    </StyledTable>
  </Box>
);
