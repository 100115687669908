import {
  useCallback,
  useEffect,
  useState,
  // useMemo,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import _, { toString } from 'lodash';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

import { useCurrentOrganisationStore } from '@/modules/core/store';

import { MainInfo } from '../CreateProcessingRule.types';
import { useCreateServicePaymentQuery } from '../queries';

import usePaymentRuleFields, { PaymentRuleField } from './usePaymentRuleFields';
import useProcessingRuleMainFields, { PERCENTAGE_KEY } from './useServicePaymentFields';

interface Params {
  mainValue: MainInfo;
  paymentRules: PaymentRuleField[];
  visible?: boolean;
  onClose?: () => void;
  tierId: string;
  isUpdateMode?: boolean;
  updatedConfigId?: string;
}

 
const getBooleanFromString = (value?: string) => (value === 'yes' ? true : value === 'no' ? false : undefined);

const SERVICE_TYPE_ERROR = 'Details: Service config for service';
export default ({
  mainValue, paymentRules, onClose, visible, tierId, isUpdateMode, updatedConfigId,
}: Params) => {
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const { organizationId } = useCurrentOrganisationStore();
  const [isServiceTypeError, setServiceTypeError] = useState(false);
  const { handleCreate, isLoading } = useCreateServicePaymentQuery(isUpdateMode, updatedConfigId);
  const [isSubmitted, setSubmited] = useState(false);
  const mainFields = useProcessingRuleMainFields();
  const paymentRuleFields = usePaymentRuleFields({});
  useEffect(() => {
    if (!visible) {
      setSubmited(false);
    }
  }, [visible]);

  const handleCheckValidity = useCallback(async () => {
    const mainShape = mainFields.reduce((result, item) => {
      if (item.subFields) {
        return {
          ...result,
          [item.name]: item.validator,
          [item?.subFields?.[0]?.name]: item?.subFields?.[0]?.validator,
        };
      }
      return {
        ...result,
        [item.name]: item.validator,
      };
    }, {}) as ObjectShape;

    const mainShcema = yup.object({}).shape(mainShape);
    try {
      await mainShcema.validate(mainValue);
      await Promise.all(paymentRules.map(async (item) => {
        const paymentRulesShape = paymentRuleFields.reduce((result, currentField) => {
          if (currentField.name === 'key') {
            return ({
              ...result,
              [currentField.name]: _.last(paymentRules),
            });
          }
          return ({
            ...result,
            [currentField.name]: currentField.validator,
          });
        }, {}) as ObjectShape;

        const paymentRulesSchema = yup.object({}).shape(paymentRulesShape);
        await paymentRulesSchema.validate(item);
      }, {}));
      return true;
    } catch (e) {
      return false;
    }
  }, [mainFields, mainValue, paymentRuleFields, paymentRules]);

  const handleSubmit = useCallback(async () => {
    setSubmited(true);
    const isValid = await handleCheckValidity();

    if (isValid) {
      try {
        await handleCreate({
          organization_id: _.toNumber(organizationId),
          service_id: _.toNumber(mainValue.serviceType),
          processing_tier_id: toString(tierId),
          health_plan_id: id,
          description: mainValue.description || '',
          claim_value: getBooleanFromString(mainValue?.paymentValue)
            ? _.toNumber(mainValue?.claimValue) : undefined,
          is_applied_deductible: getBooleanFromString(mainValue.applyToDeductible),
          payment_type: mainValue.paymentType,
          payment_rules: paymentRules.map((item) => {
            if (mainValue.paymentType === PERCENTAGE_KEY) {
              return {
                plan_id: _.toNumber(item.account),
                percentage: _.toNumber(item.amount) / 100,
              };
            }
            return {
              plan_id: _.toNumber(item.account),
              max_amount: _.toNumber(item.amount),
            };
          }),
        });
        if (onClose) {
          onClose();
        }
      } catch (e) {
        const message = _.get(e, 'response.data.elevate_error_message', '') as string;
        if (message.includes(SERVICE_TYPE_ERROR)) {
          setServiceTypeError(true);
        }
      }
    }
  }, [handleCheckValidity,
    mainValue,
    handleCreate,
    tierId,
    id,
    onClose,
    paymentRules,
    organizationId,
  ]);

  return {
    isServiceTypeError,
    isSubmitted,
    isLoading,
    handleSubmit,
  };
};
