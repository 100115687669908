import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,   Box, ErrorModal,FlexList, Heading,
  PaginationBar, Preloader,
  SearchInput, SelectDropdown, Text } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { ListItemBase } from '@/common/types';
import HeaderLink from '@/components/elements/HeaderLink';
import { useHasAccess } from '@/modules/core/hooks';

import ListPlaceholder from '../ListPlaceholder';
import { ProcessingRuleItem } from '../ProcessingDefinition.types';

import CreateProcessingRuleModal from './CreateProcessingRuleModal';
import { useProcessingRules, useSearchParams } from './hooks';
import ProcessingRulesList from './ProcessingRulesList';

const headers: ListItemBase[] = [
  {
    key: 'Org level & name',
    title: 'Org level & name',
    flex: 1,
  },
  {
    key: 'tiers',
    title: 'Tier(s)',
    flex: 2,
  },

];

interface Props {
  isPageMode?: boolean;
}

const ProcessingRules: React.FC<Props> = ({ isPageMode }) => {
  const {
    page, setPage, setPerPage, perPage,
    searchString, setSearchString,
    sortOptions,
    sortParams,
    setSortParams,
    params,
  } = useSearchParams();
  const { data, isLoading, total } = useProcessingRules(params);
  const [updatedElement, setUpdatedElement] = useState<ProcessingRuleItem>();
  const [visible, setVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const isCreateAccessible = useHasAccess([
    {
      permission: Permissions.SET_UP_PROCESSING_DEFINITION,
    },
  ]);

  useEffect(() => {
    if (updatedElement) {
      if (updatedElement.editable) {
        setVisible(true);
      } else {
        setErrorModalVisible(true);
      }
    }
  }, [updatedElement]);

  const handleUpdatedElement = useCallback((value?: ProcessingRuleItem) => {
    setUpdatedElement(value);
  },[setUpdatedElement]);

  const currentNode = useMemo(() => {
    if (isLoading) {
      return (
        <Box pad={{ top: 'spacing24' }}>
          <Preloader testId="processing-rules_loading" />
        </Box>
      );
    }
    if (data.length === 0) {
      return (
        <ListPlaceholder testId="processing-rules">
          There are no Processing rules
        </ListPlaceholder>
      );
    }
    return (
      <Box direction="column" style={{ position: 'relative' }}>
        <ProcessingRulesList
          values={data}
          handleUpdatedElement={handleUpdatedElement}
        />
        {total === 0 ? null : (
          <PaginationBar
            page={page}
            total={total}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        )}
      </Box>
    );
  }, [data, isLoading, page, perPage, setPage, setPerPage, total, handleUpdatedElement]);
  return (
    <div>
      <Box
        direction="column"
        data-testid="processing-rules"
        pad={{ bottom: 'l' }}
        border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
      >
        <Box direction="row" align="center" justify="between">
          {
            isPageMode
              ? (
                <Box pad={{ vertical: 'l' }}>
                  <Heading level={4} size="large" color="textTitle">Processing Rules</Heading>
                </Box>
              ) : (
                <Box margin={{ top: 'l', bottom: 'm' }}>
                  <HeaderLink path={ROUTES.PROCESSING_RULES} title="Processing Rules" />
                </Box>
              )
          }
          {
            isCreateAccessible && (
              <AppButton onClick={() => setVisible(true)}>
                Create a Processing Rule
              </AppButton>
            )
          }
        </Box>
        <Box>
          <FlexList
            testId="EmployersList"
            headers={headers}
            rows={[]}
            footer={
              currentNode
            }
          >
            <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
              <Text
                size="large"
                weight="bold"
                color="textBody"
              >Rules: {data.length || 0}
              </Text>
              <Box direction="row">
                <SearchInput
                  hasSearchingHistory
                  className="search-input"
                  testId="processing-rules-search"
                  value={searchString}
                  placeholder="Search"
                  onChange={setSearchString}
                />
                <Box direction="row" margin={{ left: 'spacing8' }}>
                  <Box width="small">

                    <SelectDropdown
                      id="status"
                      singleMode
                      testId="processing-rules-sort-by"
                      ellipsisMode
                      activeTitle
                      name="Sort by"
                      prefix="Sort by"
                      options={sortOptions}
                      value={sortParams}
                      onChange={setSortParams}
                    />
                  </Box>

                </Box>

              </Box>
            </Box>
          </FlexList>
        </Box>
      </Box>
      {visible && (
        <CreateProcessingRuleModal
          updatedElement={updatedElement}
          visible={visible}
          onToggleVision={setVisible}
          onUpdatedElement={handleUpdatedElement}
        />
      )}
      <ErrorModal
        testId="edit_processing_rule_type-error"
        visible={errorModalVisible}
        header="Unable To Edit"
        helpText="This processing rule is actively being used and cannot be edited."
        buttonText="Close"
        onSetVisible={() => {
          setErrorModalVisible(false);
          handleUpdatedElement(undefined);
        }}
      />
    </div>
  );
};

export default ProcessingRules;
