import { useMemo } from 'react';

import { useBasicPropertiesFieldsState } from '../BasicProperties/hooks';
import { useEnrollmentMatrixFieldsState } from '../ComplexConfiguration/EnrollmentMatrix/hooks';
import { useCoverageLevelsFieldsState } from '../CoverageLevels/hooks';

export default () => {
  const basicPropertiesState = useBasicPropertiesFieldsState();
  const coverageLevelsFieldsState = useCoverageLevelsFieldsState();
  const enrollmentMatrixFieldsState = useEnrollmentMatrixFieldsState();
  const state = useMemo(() => ({
    ...basicPropertiesState,
    ...coverageLevelsFieldsState,
    ...enrollmentMatrixFieldsState,
  }), [basicPropertiesState, coverageLevelsFieldsState, enrollmentMatrixFieldsState]);
  return state;
};
