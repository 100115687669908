import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { FinOpsPrefund } from '@/modules/employer/components/ReconciliationReports/components/FinOpsPrefund';
import theme from '@/styles/theme';

import Topbar from '../modules/core/components/Topbar';

export const FinOpsPrefundPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  useBreadcrumbs([
    {
      route: ROUTES.FINOPS,
      title: 'FinOps',
    },
    {
      route: ROUTES.FINOPS_PREFUND_VIEW(id),
      title: id || '',
    },
  ], [id],
  {
    backBtn: true,
  });

  return (
    <Box width="100%" height="fit-content">
      <Topbar />
      <Box align="center" margin={{ top: 'medium' }} pad={{ bottom: 'spacing72' }}>
        <Box width={theme.defaultContentWidth}>
          <FinOpsPrefund />
        </Box>
      </Box>
    </Box>
  );
};
