import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { EMAIL_INVALID_TEXT, REQUIRED_TEXT } from '@/common/constants';
import useContactStore from '@/modules/ExpressSetup/AddEditContactForm/stores/useContact.store';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

export default (isEditMode?: boolean) => {
  const errorFields = useContactStore((state) => state.errorFields);
  const defaultFields = useContactStore((state) => state.sourceState);

  const fields = useMemo((): Field[] => [
    {
      name: 'firstName',
      type: FieldTypes.Text,
      label: 'First name',
      showRequireIcon: true,
      placeholder: 'First name',
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },
    {
      name: 'lastName',
      type: FieldTypes.Text,
      label: 'Last name',
      showRequireIcon: true,
      placeholder: 'Last name',
      validator: yup.string().trim().required(REQUIRED_TEXT),
    },
    {
      name: 'email',
      type: FieldTypes.Text,
      label: 'Email',
      showRequireIcon: true,
      placeholder: 'Email',
      validator: yup.string().trim().test({
        test: (val) => !errorFields?.email?.value || val !== errorFields?.email?.value,
        message: errorFields?.email?.message,
      }).required(REQUIRED_TEXT).email(EMAIL_INVALID_TEXT),
    },
    {
      name: 'userName',
      type: FieldTypes.Text,
      label: 'Username',
      showRequireIcon: true,
      placeholder: 'Username',
      validator: yup.string().trim().test({
        test: (val) => !errorFields?.userName?.value || val !== errorFields?.userName?.value,
        message: errorFields?.userName?.message,
      }).required(REQUIRED_TEXT),
      isLowerCaseMode: true,
      disabled: !!isEditMode,
    },
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      showRequireIcon: true,
      placeholder: '+1 (999) 999-9999',
      validator: yup.string().trim().test({
        test: (val) => !errorFields?.phoneNumber?.value || val !== errorFields?.phoneNumber?.value,
        message: errorFields?.phoneNumber?.message,
      }).required(REQUIRED_TEXT),
    },
  ], [isEditMode, errorFields]);
  const formatedFields = useFieldsWithDefaultValues(fields, defaultFields);
  return formatedFields;
};
