import { useEffect, useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { DefaultSortTypesEnum, OptionKey } from '@/common/types';

import { CarrierConfigPurpose } from '../CarrierConfig.constants';

const RESET_PAGE_VALUE = 1;

const useListPagination = () => {
  const {
    page, setPage, setPerPage, perPage,
    currentSearch, searchString, setSearchString,
  } = usePaginationParams();

  const [purposes, setPurposes] = useState<OptionKey[]>([CarrierConfigPurpose.Null]);
  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.ASC);

  useEffect(() => {
    setPage(RESET_PAGE_VALUE);
  }, [setPage, currentSearch, purposes]);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    purposes,
    setPurposes,
    sortBy,
    setSortBy,
  };
};

export default useListPagination;
