import { useMemo } from 'react';
import _ from 'lodash';
import { AnySchema } from 'yup';

import useLinkManagementFields from './useLinkManagementFields';
import useLinkManagementStore from './useLinkManagementStore.store';

interface Validator {
  [key: string]: AnySchema | undefined;
}
interface Result {
  [key: string]: boolean;
}
const useLinkManagementValidations =  () => {
  const { fields } = useLinkManagementFields();
  const linkManagementState = useLinkManagementStore((state) => state.state);
  const validationsMap = useMemo(
    () => fields.reduce((result: Validator, item) => ({
      ...result,
      [item.name || '']: item.validator,
    }), {}), [fields],
  );
  const currentResult = useMemo(() => {
    const result: Result = {};
    Object.keys(validationsMap).forEach((key) => {
      const currentValidator = validationsMap[key];
      if (currentValidator) {
        const currentValue = _.get(linkManagementState, `[${key}]`);
        try {
          currentValidator.validateSync(currentValue);
          result[key] = true;
        } catch (e) {
          result[key] = false;
        }
      }
    });

    return result;
  }, [validationsMap, linkManagementState]);

  return currentResult;
};

export default useLinkManagementValidations;
