 
import { useCallback, useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { NON_ASSIGNABLE_VALUE, PermissionKey,REQUIRED_TEXT } from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { BankMethod } from '@/modules/employer/hooks/useBankMethods.query';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import { AccountUsageValues } from '../AccountUsage.types';

const placeholder = 'Select bank account';

const useAdministratorFeesFields = (
  banks: BankInfo[],
  methods: BankMethod[],
  formattedData: EmployerDto,
  currentValues?: AccountUsageValues,
  activateMode?: boolean,
  isLoading?: boolean,
) => {
  const externalAdministratorFeesAccountMethodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EXTERNAL_ADMINISTRATOR_FEES_ACCOUNT_METHOD,
  );
  const { viewMode } = useSetupEmployerMode();

  const getDefaultValue = useCallback((value?: string) => {
    const bankId = banks.find((bank) => bank.id === value)?.id;

    if (bankId) return bankId;

    if (formattedData?.normalProperties?.externalAccountEnable
      || value === NON_ASSIGNABLE_VALUE) return NON_ASSIGNABLE_VALUE;

    return '';
  }, [banks, formattedData]);

  const externalAdministratorFees = useMemo(
    () => currentValues?.administratorFeesBank,
    [currentValues],
  );

  return useMemo(() => {
    if (isLoading) return [];

    const options = banks.map((bank) => ({
      key: bank.id,
      value: bank.description,
    }));

    return [
      {
        name: 'administratorFeesBank',
        type: FieldTypes.Dropdown,
        disabled: viewMode,
        validator: activateMode ? yup.string().required(REQUIRED_TEXT) : yup.string(),
        placeholder,
        label: 'Bank Account',
        singleMode: true,
        areSubFieldsShouldHide: !externalAdministratorFees || externalAdministratorFees === NON_ASSIGNABLE_VALUE,
        options: [
          {
            key: NON_ASSIGNABLE_VALUE,
            value: NON_ASSIGNABLE_VALUE,
          },
          ...options,
        ],
        subFields: [{
          name: 'administratorFeesMethod',
          type: FieldTypes.Radio,
          disabled: viewMode,
          parentValueFn: (val) => !!val,
          label: 'Method',
          validator: yup.string().test({
            test: (val) => !!(val || formattedData?.overridableProperties?.externalAdministratorFeesAccountMethod),
            message: REQUIRED_TEXT,
          }),
          options: methods.map((option) => ({
            label: option.description,
            value: option.name,
          })),
          value: formattedData?.overridableProperties?.externalAdministratorFeesAccountMethod,
          showRequireIcon: true,
          ...externalAdministratorFeesAccountMethodPermProps,
        }],
        defaultValue: getDefaultValue(formattedData?.normalProperties?.externalAdministratorFeesAccount),
        value: getDefaultValue(formattedData?.normalProperties?.externalAdministratorFeesAccount),
        hasHorizontalLine: true,
      },
    ] as Field[];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentValues,
    activateMode,
    banks,
    externalAdministratorFees,
    formattedData,
    getDefaultValue,
    isLoading,
    methods,
    viewMode,
  ]);
};

export default useAdministratorFeesFields;
