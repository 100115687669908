import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaginationParams } from '@/common/types';

import { ClaimDto } from '../types/claims.constants';

import { ClaimsProcessor, ClaimsProcessorPayload, formatClaimsProcessorPayload } from './ClaimsProcessors.types';

const formatData = (data: ClaimsProcessorPayload[]): ClaimsProcessor[] => data.map(formatClaimsProcessorPayload);

interface Props extends PaginationParams {
  enabled?: boolean;
  claimsTeamId?: string;
  selectedClaims?: ClaimDto[];
}

const useGetTeamsProcessorsQuery = ({
  sortBy,
  page,
  perPage,
  searchString,
  claimsTeamId,
  enabled = true,
  selectedClaims,
}: Props) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const claimToClaimTeamIds: any = {};
  selectedClaims?.forEach((claim) => {
    const teamsIds = claim?.claimsTeams?.map((team) => team?.id);

    if (teamsIds?.length) {
      claimToClaimTeamIds[claim?.id] = teamsIds;
    }
  });
  const { isLoading, data, refetch, isSuccess } = useQuery([
    PATHS.CONTACTS_TEAMS,
    page,
    perPage,
    enabled,
    searchString,
    sortBy,
    claimsTeamId,
    selectedClaims,
  ], () => api.post(
    `${PATHS.CONTACTS_TEAMS}?page=${page}&size=${perPage}`,
    {
      ...searchString ? { name_search_value: searchString } : {},
      ...Object.keys(claimToClaimTeamIds)?.length ? {
        claim_to_claim_team_ids: claimToClaimTeamIds,
      } : {},
    },
  ), { enabled });

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);
  const claimsProcessors = useMemo(() => {
    if (!data?.data?.content) return undefined;

    return formatData(data.data.content || []);
  }, [data]);

  return ({
    claimsProcessors,
    total,
    isLoading,
    refetch,
    isSuccess,
  });
};

export default useGetTeamsProcessorsQuery;
