/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { Field,FieldTypes, validateTextInputLength } from '@common-fe/common-fe';
import * as yup from 'yup';

import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import { EmployerDto } from '@/modules/employer/types';

import { useSetupEmployerMode } from '../hooks';

const REQUIRED_FILED = 'This field is required.';
const FIELDS = {
  EMPLOYER_NAME: 'employerName',
  EMPLOYER_ID: 'employerId',
  TPA: 'tpa',
  DISTRIBUTOR: 'distributor',
  EMPLOYER: 'employer',
  SUBSIDIARY: 'subsidiary',
};

export const SAVE_VALIDATORS = {
  [FIELDS.EMPLOYER_NAME]: yup.string().trim()
    .test(validateTextInputLength()).required(REQUIRED_FILED),
  [FIELDS.EMPLOYER_ID]: yup.string().trim(),
  [FIELDS.TPA]: yup.string(),
};

const useEmployerFields = (
  activateMode?: boolean,
  data?: EmployerDto,
  partnerName?: string,
  distributorName?: string,
  employerName?: string,
  subsidiaryName?: string,
) => {
  const { employerID, mode } = useSetupEmployerMode();
  const entityTitle = useMemo(() => {
    switch (mode) {
    case EmployerSetupModes.employer: {
      return 'Employer';
    }
    case EmployerSetupModes.partner: {
      return 'Partner';
    }
    case EmployerSetupModes.distributor: {
      return 'Distributor';
    }
    case EmployerSetupModes.subsidiary: {
      return 'Subsidiary';
    }
    default: {
      return '';
    }
    }
  }, [mode]);
  const fields: Field[] = useMemo(() => {
    const employersFields: Field[] = [
      ...partnerName ? [{
        name: FIELDS.TPA,
        type: FieldTypes.Text,
        label: 'Partner',
        validator: yup.string().trim(),
        showRequireIcon: true,
        defaultValue: partnerName,
        value: partnerName,
        disabled: true,
      } as Field] : [],
      ...distributorName ? [{
        name: FIELDS.DISTRIBUTOR,
        type: FieldTypes.Text,
        label: 'Distributor',
        validator: yup.string().trim(),
        showRequireIcon: true,
        defaultValue: distributorName,
        value: distributorName,
        disabled: true,
      } as Field] : [],
      ...employerName ? [{
        name: FIELDS.EMPLOYER,
        type: FieldTypes.Text,
        label: 'Employer',
        validator: yup.string().trim(),
        showRequireIcon: true,
        defaultValue: employerName,
        value: employerName,
        disabled: true,
      } as Field] : [],
      ...subsidiaryName ? [{
        name: FIELDS.SUBSIDIARY,
        type: FieldTypes.Text,
        label: 'Subsidiary',
        validator: yup.string().trim(),
        showRequireIcon: true,
        defaultValue: subsidiaryName,
        value: subsidiaryName,
        disabled: true,
      } as Field] : [],
    ];
    return ([
      {
        name: FIELDS.EMPLOYER_NAME,
        type: FieldTypes.Text,
        label: entityTitle ? `${entityTitle} name` : 'Name',
        placeholder: `Enter ${entityTitle} name`,
        validator: yup.string().trim()
          .test(validateTextInputLength()).required(REQUIRED_FILED),
        showRequireIcon: true,
        value: data?.name,
        defaultValue: data?.name, // employerData?.employerName, // generalInfo?.employerName,
      },
      {
        name: FIELDS.EMPLOYER_ID,
        type: FieldTypes.Text,
        label: `${entityTitle} ID`,
        placeholder: `Enter ${entityTitle} ID`,
        validator: yup.string().trim(),
        showRequireIcon: true,
        value: data?.externalIdentifier,
        defaultValue: data?.externalIdentifier, // employerData?.EmployeeIdField || employerID,
        disabled: true,
      },
      ...mode === EmployerSetupModes.employer
        || mode === EmployerSetupModes.subsidiary
        || mode === EmployerSetupModes.distributor ? employersFields : [],
    ]);
  },
  [
    employerID,
    partnerName,
    distributorName,
    employerName,
    subsidiaryName,
  ]);
  const formatedFields = useMemo(() => {
    const list = [...fields];
    if (!activateMode) {
      return list.map((field) => {
        const validator = SAVE_VALIDATORS[field.name];
        return {
          ...field,
          validator,
        };
      });
    }
    return list;
  }, [activateMode, fields]);
  return formatedFields;
};

export default useEmployerFields;
