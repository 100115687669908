import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Field,
  FlexControlledForm,
  OptionKey,
  Preloader,
  WarnModal,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import AppButton from '@/components/controls/AppButton';

import { ClaimsTeam } from '../ClaimsProcessors.types';

import { ACCOUNT_TYPES_OPTIONS, ALL_KEY, useCreateClaimsTeamFields } from './hooks/useCreateClaimsTeamFields';
import useCreateClaimsTeamQuery from './queries/useCreateClaimsTeam.query';
import useUpdateClaimsTeamQuery from './queries/useUpdateClaimsTeam.query';

const accountsAllToggling = (types: OptionKey[]) => {
  const isAllSelected = types[types.length - 1] === ALL_KEY;

  if (types.length === 0 || isAllSelected) {
    return [ALL_KEY];
  }

  if (types.length === ACCOUNT_TYPES_OPTIONS.length) {
    return [ALL_KEY];
  }

  const isTypeAllChangedToAnother = types.length === 2 && types[0] === ALL_KEY;

  if (isTypeAllChangedToAnother) {
    return types.filter((type) => type !== ALL_KEY);
  }

  return types;
};

interface Props {
  onCancel?: (value: boolean) => void;
  editableTeam?: ClaimsTeam;
  onSuccess?: (id?: string) => void;
}
  
const CreateClaimsTeamModalForm: React.FC<Props> = ({
  onCancel,
  editableTeam,
  onSuccess,
}) => {
  const [formValues, setFormValues] = useState<{teamName: string}>();
  const [errorModal, setErrorModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [createTeamSetup, setCreateTeamSetup] = useState(false);
  const [organizationsIds, setOrganizationsIds] = useState<string[] | undefined>();
  const [accountTypes, setAccountTypes] = useState<OptionKey[]>([ALL_KEY]);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const accountTypesValues = useMemo(() => accountsAllToggling(accountTypes), [accountTypes]);

  const { create, isLoading } = useCreateClaimsTeamQuery();
  const { update, isLoading: isUpdateLoading } = useUpdateClaimsTeamQuery(editableTeam?.id);

  const createTeamFields = useCreateClaimsTeamFields({
    setOrganizationsIds,
    setAccountTypes,
    accountTypesValues,
    editableTeam,
  });

  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled: (val: boolean) => void,
  ) => {

    setFormValues((
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prev: any,
    ) => ({ ...prev, ...values }));

    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setFieldsAreFilled(true);
    } catch {
      setFieldsAreFilled(false);
    }
  }, []);

  const handleChangeFormValues = useCallback((values: object) => {
    changeValues(values, createTeamFields, setCreateTeamSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTeamFields]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);

  const allowedTypesMatcher = (types: OptionKey[]) => {
    const allTypes = ACCOUNT_TYPES_OPTIONS.map((type) => type.key);
    if (types.length === 1 && types[0] === ALL_KEY) {
      return allTypes as string[];
    }
    return types as string[];
  };

  const assignedOrganizationIdsFormatter = (ids?: string[]) => {
    if (ids) {
      return ids.map((id) => +id);
    }
    return [];
  };
  
  const handleSubmit = useCallback(async () => {
    setIsSubmit(true);

    if (createTeamSetup) {
      try {
        if (editableTeam) {
          const justUpdatedTeam = await update({
            name: formValues?.teamName as string,
            allowedAccountTypes: allowedTypesMatcher(accountTypesValues),
            assignedOrganizationIds: assignedOrganizationIdsFormatter(organizationsIds),
          });
          const preparedTeamId = justUpdatedTeam?.id ? `${justUpdatedTeam.id}` : undefined;
          onSuccess && onSuccess(preparedTeamId);
          handleCancel();
          return;
        }

        const justCreatedTeam = await create({
          name: formValues?.teamName as string,
          allowedAccountTypes: allowedTypesMatcher(accountTypesValues),
          assignedOrganizationIds: assignedOrganizationIdsFormatter(organizationsIds),
        });
        const preparedTeamId = justCreatedTeam?.id ? `${justCreatedTeam.id}` : undefined;
        onSuccess && onSuccess(preparedTeamId);
        handleCancel();
      }
      catch {
        setErrorModal(true);
      }
    }
  }, [
    accountTypesValues,
    create,
    createTeamSetup,
    formValues,
    handleCancel,
    organizationsIds,
    onSuccess,
    update,
    editableTeam,
  ]);

  useEffect(() => {
    if (editableTeam) {
      const allowedTypes = editableTeam.allowedAccountTypes?.length === ACCOUNT_TYPES_OPTIONS.length
        ? [ALL_KEY]
        : editableTeam.allowedAccountTypes as OptionKey[];
      setAccountTypes(allowedTypes);
    }
  }, [editableTeam]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (editableTeam) {
      setIsEditLoading(true);
      timer = setTimeout(() => {
        setIsEditLoading(false);
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [editableTeam]);
  
  return (
    <Box data-testid="Create_claims_team-modal-form">
      <WarnModal
        testId="new-claims-team-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Claims team with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
      <Box direction="column">
        <Box round="container1Round" pad="spacing24" background="module">
          {isEditLoading
            ? <Preloader />
            : (
              <Box data-testid="Create_claims_team-form" round="container1Round" background="canvas">
                <FlexControlledForm
                  fields={createTeamFields}
                  editMode
                  showError={isSubmit}
                  onChangeValues={handleChangeFormValues}
                  isModalType
                />
              </Box>
            )}
  
        </Box>
        <Box
          direction="row"
          fill="horizontal"
          margin={{ top: 'spacing24' }}
          align="end"
          justify="end"
          width="medium"
          gap="xs"
        >
          <AppButton
            testId="Create_claims_team-cancel"
            buttonType="secondary"
            width="control"
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
          <AppButton
            testId="Create_claims_team-add"
            type="button"
            disabled={isLoading || isUpdateLoading}
            onClick={handleSubmit}
            width="control"
          >
            {isLoading || isUpdateLoading
              ? (<Preloader />)
              : 'Confirm'}
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
  
export default CreateClaimsTeamModalForm;
  