import { storeCreator } from '@/utils/store/store.utils';

interface State {
  saveValidate: boolean;
  activateValidate: boolean;

  reset: () => void;
  setSaveValidate: (value: boolean) => void;
  setActivateValidate: (valie: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  saveValidate: false,
  activateValidate: false,

  setSaveValidate: (saveValidate) => set(() => ({
    saveValidate,
    activateValidate: false,
  })),
  setActivateValidate: (activateValidate) => set(() => ({
    activateValidate,
    saveValidate: false,
  })),

  reset: () => set(() => ({
    saveValidate: false,
    activateValidate: false,

  })),

}));
export default useStore;
