import { storeCreator } from '@/utils/store/store.utils';

interface State {
  hasSameSSN?: boolean;
  setHasSameSSN: () => void;
  resetHasSameSSN: () => void;
}

const useStore = storeCreator<State>((set) => ({
  hasSameSSN: undefined,
  setHasSameSSN: () => set(() => ({ hasSameSSN: true })),
  resetHasSameSSN: () => set(() => ({ hasSameSSN: undefined })),
}));

const useHasSameSSNStore = () => {
  const store = useStore();
  return {
    ...store,
  };
};

export default useHasSameSSNStore;
