import React, { useEffect, useMemo,useState } from 'react';
import { Box } from '@common-fe/common-fe';

import { CLOSE_SYMBOL, OPEN_SYMBOL } from './FormulaDragAndDrop/FormulaDragAndDrop';
import FundIDFOrmulaResult from './FundIDFormulaResult';
import PrefixSubfield from './PrefixSubfield';

interface Props {
  isErrorsShowed?: boolean;
  onChange?:(value: string) => void;
  value?: string;
  disabled?: boolean;
}

const NoFormula: React.FC<Props> = ({
  isErrorsShowed, value, onChange, disabled,
}) => {
  const formatedValue = useMemo(() => {
    if (value) {
      return value.replace(OPEN_SYMBOL, '').replace(CLOSE_SYMBOL, '');
    }
    return '';
  }, [value]);
  const [prefix, setPrefix] = useState(formatedValue || '');

  useEffect(() => {
    if (onChange) {
      onChange(prefix);
    }
  }, [prefix, onChange]);
  return (
    <Box direction="column">
      <PrefixSubfield
        value={prefix}
        disabled={disabled}
        isErrorsShowed={isErrorsShowed}
        onChange={setPrefix}
      />

      <FundIDFOrmulaResult
        text="The total Fund ID formula must not exceed 8 digits."
        value={prefix}
        placeholder="Prefix"
      />
    </Box>
  );
};

export default NoFormula;
