import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CommunicationCard,
  Heading,
  useDebounce,
} from '@common-fe/common-fe';

import { CommunicationType } from '@/common/constants';

import { useNotificationPriorityStore } from '../stores';

interface Props {
  viewMode?: boolean;
  notificationPriority?: string;
}

const NotificationPriority: React.FC<Props> = ({ viewMode, notificationPriority }) => {
  const { handleSetValues } = useNotificationPriorityStore();
  const [notificationValue, setNotificationPriority] = useState(notificationPriority);

  const debouncedValue = useDebounce(notificationValue);
  useEffect(() => {
    if (debouncedValue) handleSetValues({ notificationPriority: debouncedValue });
  }, [debouncedValue, handleSetValues]);

  const options = useMemo(() => [{
    type: CommunicationType.IMPORTANT_ONLY,
    disabled: viewMode,
  },
  {
    type: CommunicationType.INTERESTING,
    disabled: viewMode,
  },
  {
    type: CommunicationType.ALL_NOTIFICATIONS,
    disabled: viewMode,
  },
  ], [viewMode]);

  return (
    <Box background="canvas" round="container1Round" pad="spacing32" data-testid="Notification-priority-form-wrapper">
      <Heading
        level={3}
        size="small"
        margin={{ bottom: 'spacing32' }}
      >
        What Should We Tell You About?
      </Heading>
      <Box direction="row" justify="between" background="module" round="container1Round" pad="spacing12">
        {options.map((option) => (
          <CommunicationCard
            key={option?.type}
            disabled={option?.disabled}
            type={option?.type}
            checked={notificationValue === option?.type}
            onClick={() => setNotificationPriority(option.type)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default NotificationPriority;
