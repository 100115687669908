import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import CommunicationChannels from './CommunicationChannels/CommunicationChannels';
import NotificationPriority from './NotificationPriority/NotificationPriority';
import YourContacts from './YourContacts/YourContacts';

interface Props {
  onDirty?: (isDirty: boolean) => void;
  isContainEmail: boolean;
  isContainNotificationCenter: boolean;
  communicationConfigChannels?: string[];
  communicationChannels?: string[];
  phoneNumber?: string;
  email?: string;
  notificationPriority?: string;
  isLoading?: boolean;
}

export const Communications: React.FC<Props> = ({
  isLoading,
  isContainEmail,
  isContainNotificationCenter,
  communicationConfigChannels,
  communicationChannels,
  phoneNumber,
  email,
  notificationPriority,
  onDirty,
}) => {
  if (isLoading) {
    return (
      <Box
        background="canvas"
        round="container1Round"
        elevation="default"
        pad="spacing24"
        align="center"
        justify="center"
        height={{ min: 'medium' }}
        margin={{ bottom: 'spacing16' }}
        data-testid="Communication-form-wrapper"
      >
        <Preloader />
      </Box>
    );
  }
  return (
    <Box direction="column" background="module" pad="spacing24" round="container1Round" data-testid="Communications-form-wrapper">
      {((isContainEmail && isContainNotificationCenter) || isContainEmail)
        && (
          <CommunicationChannels
            communicationConfigChannels={communicationConfigChannels}
            communicationChannels={communicationChannels}
            onDirty={onDirty}
            viewMode
          />
        )}
      {((isContainEmail && isContainNotificationCenter) || isContainEmail)
        && (
          <Box margin={{ vertical: 'spacing24' }}>
            <YourContacts
              onDirty={onDirty}
              phoneNumber={phoneNumber}
              email={email}
              viewMode
            />
          </Box>
        )}
      <NotificationPriority
        notificationPriority={notificationPriority}
        viewMode
      />
    </Box>
  );
};
