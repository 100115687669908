import React from 'react';
import { Box } from '@common-fe/common-fe';

import FinOpsInvoicesList from './FinOpsInvoicesList';

const FinOpsInvoicing = () => (
  <Box>
    <FinOpsInvoicesList />
  </Box>
);

export default FinOpsInvoicing;
