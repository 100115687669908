 
import React, {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import {
  Box, FlexList,
  Text, WarnModal, } from '@common-fe/common-fe';

import { ListItemBase } from '@/common/types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import ModalWrapper from '@/modules/employer/components/SetupEmployer/ModalWrapper';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';

import BankInformationForm from '../BankInformationForm';
import useBankInformationQuery from '../useBankInformation.query';

import useBanksList from './useBanksList';

const headers: ListItemBase[] = [{
  key: 'description',
  title: 'Description',
}, {
  key: 'type',
  title: 'Bank Account Type',
  flex: 1,
}];
const CONFIRM_CANCEL_TEXT = 'Changes that you made may not be saved.';

const BanksList: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { editMode: isEditPageMode } = useSetupEmployerMode();
  const [editMode, setEditMode] = useState(false);
  const {
    save, isLoading, errorModal, errorModalProps, retry, setErrorModal,
  } = useBankInformationQuery();
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentBank, setCurrentBank] = useState<BankInfo | null | undefined>(null);
  const { formatedList, findBankById, isEmpty } = useBanksList(disabled);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);
  const handlePickOption = useCallback(
    (id: string) => {
      const bank = findBankById(id);

      if (bank && !disabled) {
        setCurrentBank(bank);

        if (isEditPageMode) {
          setEditModalVisible(true);
        } else {
          setViewModalVisible(true);
        }
      }
    }, [findBankById, disabled, isEditPageMode],
  );

  const rows = useMemo(() => formatedList.map((row) => {
    if (!disabled) {
      return {
        ...row,
        onPick: () => {
          setCurrentBank(findBankById(row.id));
          setViewModalVisible(true);
        },
      };
    }

    return row;
  }), [formatedList, disabled, findBankById]);

  useEffect(() => {
    if (!viewModalVisible) {
      setEditMode(false);
    }
  }, [viewModalVisible]);
  const handleSubmit = async (newValue: BankInfo) => {
    await save({ ...newValue, id: currentBank?.id || '' });

    setEditModalVisible(false);
    setViewModalVisible(false);
  };
  const isErrorModalVisible = errorModal && (editModalVisible || viewModalVisible);

  if (isEmpty) {
    return null;
  }

  return (
    <Box background="canvas" round="container1Round" margin="spacing12">
      <FlexList
        headers={headers}
        rows={rows}
        pad="spacing12"
        options={disabled ? [] : [{ name: isEditPageMode ? 'Edit' : 'View', onClick: handlePickOption }]}
      >
        <Box style={errorModal ? { display: 'none' } : {}}>
          <ModalWrapper
            visible={viewModalVisible}
            title={editMode ? 'Edit Bank Account' : 'View Bank Account '}
            onSetVisible={(value) => {
              if (!value && editMode && shouldConfirmOnClose) {
                return window.confirm(CONFIRM_CANCEL_TEXT) && setViewModalVisible(value);
              }
              return setViewModalVisible(value);
            }}
          >
            {currentBank && (
              <BankInformationForm
                editMode={editMode}
                onSetEditMode={setEditMode}
                defaultValues={currentBank}
                viewMode
                onDirty={setShouldConfirmOnClose}
                onSubmit={handleSubmit}
                loading={isLoading}
              />
            )}
          </ModalWrapper>

          <ModalWrapper
            visible={editModalVisible}
            title="Edit Bank Account"
            onSetVisible={(value) => {
              if (!value && shouldConfirmOnClose) {
                return window.confirm(CONFIRM_CANCEL_TEXT) && setEditModalVisible(value);
              }
              return setEditModalVisible(value);
            }}
          >
            {currentBank && (
              <BankInformationForm
                loading={isLoading}
                defaultValues={currentBank}
                onDirty={setShouldConfirmOnClose}
                onSubmit={handleSubmit}
                onCancel={() => {
                  if (shouldConfirmOnClose) {
                    return window.confirm(CONFIRM_CANCEL_TEXT) && setEditModalVisible(false);
                  }
                  return setEditModalVisible(false);
                }}
              />
            )}
          </ModalWrapper>
        </Box>

        {isErrorModalVisible && (
          <WarnModal
            onSetVisible={setErrorModal}
            {...errorModalProps}
            onSubmit={retry}
          />
        )}
        <Text
          margin={{ top: 'spacing8', bottom: 'spacing20', horizontal: 'spacing24' }}
          size="large"
          weight="bold"
          color="textBody"
        >Bank accounts: {formatedList.length || 0}
        </Text>
      </FlexList>
    </Box>
  );
};
export default BanksList;
