import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
import Invoices from '@/modules/employer/components/Invoices/Invioces';
import theme from '@/styles/theme';

const InvoicesPage = () => {
  useBreadcrumbs([{
    route: ROUTES.INVOICES,
    title: 'Invoices',
  }]);
  return (
    <Box pad={{ top: '0', bottom: '40px' }} width="100%">
      <Topbar />
      <Box align="center" margin={{ top: 'medium' }}>
        <Box width={theme.defaultContentWidth}>
          <Invoices />
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicesPage;
