import { Amplify } from 'aws-amplify';
export default function configureAmplify() {
  const baseUrl = import.meta.env.REACT_APP_AMPLIFY_PUBLIC_BASE_URL;
  const userPoolId = import.meta.env.REACT_APP_USER_POOL_ID;
  const userPoolClientId = import.meta.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
  // const region = import.meta.env.REACT_APP_REGION;
  Amplify.configure({
    Auth: {
      Cognito: {
        // mandatorySignIn: true,
        userPoolId,
        userPoolClientId,
        // region,

        // OPTIONAL - Configuration for cookie storage
        // cookieStorage: {
        //   // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //   // domain: '.yourdomain.com',
        //   // // OPTIONAL - Cookie path
        //   // path: '/',
        //   // // OPTIONAL - Cookie expiration in days
        //   // expires: 365,
        //   // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //   // sameSite: 'strict' | 'lax',
        //   // OPTIONAL - Cookie secure flag
        //   // Either true or false,
        //   // indicating if the cookie transmission requires a secure protocol (https).
        //   secure: false,
        // },

        loginWith: {
          // authenticationFlowType: 'USER_PASSWORD_AUTH',
          oauth: {
            domain: 'your_cognito_domain',
            scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: baseUrl,
            redirectSignOut: baseUrl,
            responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },

      },
    },
  });

}
