import { useCallback, useEffect, useMemo } from 'react';
import { CardTypesEnum } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import { Copay } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import { Plan } from '@/modules/plan/plan.types.ts';

import { useCopayStore } from '../Copays/stores';
import { usePlansStore } from '../Plans/stores';
import { usePlanYearStore } from '../PlanYear/stores';

interface PlansWizardActionsParams {
  setShowedCopayError: (value: boolean) => void;
  setIsPlanTypesSelected: (value: boolean) => void;
  setPlanYearExist: (value: boolean) => void;
  isPlansExist?: boolean;
  setErrorModal: (value: string) => void;
  isPlansServerError: boolean;
  isPlanYearServerError: boolean;
  defaultCopays?: Copay[];
  setCopaysFormKey: (key: string) => void;
}

const PLANS_ERROR_STATE_NAME = 'plans';
const PLAN_YEAR_ERROR_STATE_NAME = 'planYear';

const usePlansWizardActions = ({
  setShowedCopayError,
  setIsPlanTypesSelected,
  setPlanYearExist,
  isPlansExist,
  setErrorModal,
  isPlansServerError,
  isPlanYearServerError,
  defaultCopays,
  setCopaysFormKey
}: PlansWizardActionsParams) => {
  const planYearState = usePlanYearStore((state) => state.state);
  const plansState = usePlansStore((state) => state.state);
  const isPlansErrorShows = usePlansStore((state) => state.showErrors);
  const handleSetShowErrors = usePlansStore((state) => state.setShowErrors);

  const planYearSourceState = usePlanYearStore((state) => state.sourceState);
  const plansStateSourceState = usePlansStore((state) => state.sourceState);
  const handleSetPlanYearState = usePlanYearStore((state) => state.setState);
  const handleSetPlansState = usePlansStore((state) => state.setState);

  const isCopaysSelected = useCopayStore((state) => state.state.isCopaysSelected);

  const showCopaysForm = useMemo(() => {
    return plansState?.planTypes?.includes(CardTypesEnum.HCFSA) || plansState?.planTypes?.includes(CardTypesEnum.HRA);
  }, [plansState?.planTypes]);

  const handleTypeSelect = useCallback((type: CardTypesEnum) => {
    const newPlanTypes = plansState?.planTypes?.includes(type)
      ? plansState?.planTypes?.filter((planType) => planType !== type)
      : plansState?.planTypes ? [...plansState.planTypes, type] : [type];
    const newPlans = plansState.plans?.filter((plan) => plan.type !== type);

    if (isCopaysSelected && !newPlanTypes.includes(CardTypesEnum.HRA) && !newPlanTypes.includes(CardTypesEnum.HCFSA)) {
      setShowedCopayError(true);
    } else {
      setShowedCopayError(false);
    }

    handleSetPlansState({
      ...plansState,
      planTypes: newPlanTypes,
      plans: newPlans,
    });
  }, [plansState, isCopaysSelected, handleSetPlansState, setShowedCopayError]);

  const handleRemoveTypeCard = useCallback((type: CardTypesEnum) => {
    const newPlanTypes = plansState?.planTypes?.filter((planType) => planType !== type);
    const newPlans = plansState.plans?.filter((plan) => plan.type !== type);

    handleSetPlansState({
      ...plansState,
      planTypes: newPlanTypes,
      plans: newPlans,
    });
  }, [handleSetPlansState, plansState]);

  const handleParentTemplateSelect = useCallback((type: CardTypesEnum, plan: Plan) => {
    const newPlans = plansState?.plans?.filter((el) => el?.type !== type) || [];
    handleSetPlansState({
      ...plansState,
      plans: [...newPlans, plan],
    });
  }, [handleSetPlansState, plansState]);

  useEffect(() => {
    if (plansState?.plans?.length || isPlansExist) {
      setIsPlanTypesSelected(true);
    }
  }, [plansState, planYearSourceState, isPlansExist, setIsPlanTypesSelected]);

  useEffect(() => {
    if (planYearSourceState?.planEndDate && planYearSourceState?.planStartDate) {
      setPlanYearExist(true);
    }
  }, [planYearSourceState?.planEndDate, planYearSourceState?.planStartDate, setPlanYearExist]);

  useEffect(() => {
    if (isPlansServerError) setErrorModal(PLANS_ERROR_STATE_NAME);
    if (isPlanYearServerError) setErrorModal(PLAN_YEAR_ERROR_STATE_NAME);
  }, [isPlanYearServerError, isPlansServerError, setErrorModal]);

  useEffect(() => {
    setCopaysFormKey(uniqueId());
    setShowedCopayError(false);
  }, [defaultCopays, setCopaysFormKey, setShowedCopayError]);

  useEffect(() => () => handleSetShowErrors(false), [handleSetShowErrors]);

  return {
    handleTypeSelect,
    handleRemoveTypeCard,
    handleParentTemplateSelect,
    planYearState,
    plansState,
    isPlansErrorShows,
    planYearSourceState,
    plansStateSourceState,
    handleSetPlanYearState,
    showCopaysForm,
    PLANS_ERROR_STATE_NAME,
  };
};

export default usePlansWizardActions;