import React, { useCallback } from 'react';
import { AppButton, Box, Heading } from '@common-fe/common-fe';

import { usePayrollCalendarUse } from './PayrollCalendarUse/hooks/usePayrollCalendarUse';
import { usePayrollCalendarModalStore, usePayrollCalendarWarningModalStore } from './stores';

interface Props {
  disabled?: boolean;
  isButtonHidden?: boolean;
}

export const PayrollCalendarSection: React.FC<Props> = ({
  disabled,
  isButtonHidden,
}) => {
  const handleChangeVisibility = usePayrollCalendarModalStore((state) => state.handleChangeVisibility);
  const handleChangeWarningVisibility = usePayrollCalendarWarningModalStore((store) => store.handleChangeVisibility);
  const { isAlreadyUseAllOptions } = usePayrollCalendarUse();
  const handleNewCalendar = useCallback(() => {
    if (isAlreadyUseAllOptions) {
      handleChangeWarningVisibility(true);
      return;
    }
    handleChangeVisibility(true);
  }, [handleChangeVisibility, handleChangeWarningVisibility, isAlreadyUseAllOptions]);
  return (
    <>
      <Box
        data-testid="PayrollCalendarSection_container"
        direction="row"
        align="center"
        pad={{ vertical: 'spacing24', horizontal: 'spacing32' }}
      >
        <Box width="medium">
          <Heading level={3} size="small" style={{ fontWeight: 400 }}>
            Payroll Calendar
          </Heading>
        </Box>
        {
          !isButtonHidden
          && (
            <Box width="medium">
              <AppButton
                disabled={disabled}
                onClick={handleNewCalendar}
                width="100%"
              >
                Add New Calendar
              </AppButton>
            </Box>
          )
        }
      </Box>
    </>
  );
};
