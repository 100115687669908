import { baseStoreCreator } from '@/utils/store';

export interface EmployeeToolbarPayload {

  fullName: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export const DEFAULT_VALUES: EmployeeToolbarPayload = {
  fullName: '',
  avatar: '',
  firstName: '',
  lastName: '',
};

export const useStore = baseStoreCreator<EmployeeToolbarPayload>(DEFAULT_VALUES);

export default useStore;
