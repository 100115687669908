import React, { useCallback } from 'react';
import {
  Box, Field,   FlexControlledForm, Preloader,
} from '@common-fe/common-fe';

import { CardHolderDto } from '@/modules/transaction/Cards/Cards.types';

interface Props {
  type: string | null;
  onChangeValues: (value: CardHolderDto) => void;
  setDirtyForm: (dirty: boolean) => void;
  value: CardHolderDto | null
  fields: Field[];
  loading: boolean;
  showErrors: boolean;
}

export const AddCardDependentForm = React.forwardRef<
HTMLFormElement,
Props
>(({
  type,
  onChangeValues,
  setDirtyForm,
  fields,
  loading,
  showErrors,
}) => {
  const renderForm = useCallback((fieldArr: Field[]) => (
    <FlexControlledForm
      fields={fieldArr}
      editMode={type === 'new'}
      onDirty={setDirtyForm}
      isModalType
      onChangeValues={onChangeValues}
      showError={showErrors}
      isThinMode
    />
  ), [type, onChangeValues, setDirtyForm, showErrors]);

  if (loading) {
    return (
      <Box
        background="canvas"
        height="310px"
        align="center"
        justify="center"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        data-testid="AddCardDependentModal-form-loader"
      >
        <Preloader color="white" />
      </Box>
    );
  }
  return type
    ? (
      <Box
        background="canvas"
        round="container1Round"
        margin={{ top: 'spacing24' }}
        pad={{ bottom: 'spacing16' }}
        data-testid="AddCardDependentModal-form"
      >
        {type ? renderForm(fields) : null}
      </Box>
    ) : null;
});
