import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { HealthPlanResponse } from '@/modules/HealthPlan/HealthPlan.types';

interface Data {
  dto: Partial<HealthPlanResponse>;
  successCallback?: () => void
}

export const useUpdateHealthPlanByIdQuery = (id: string,
  errorCallback?: (error: unknown) => void) => {
  const mutation = useMutation(
    ({ dto, successCallback }: Data) => api
      .put<HealthPlanResponse>(PATHS.HEALTH_PLAN_BY_ID(id), dto)
      .then((data) => {
        if (successCallback) {
          successCallback();
        }
        return data;
      }),
    {
      onError: (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      },

    },
  );
  return mutation;
};
export default useUpdateHealthPlanByIdQuery;
