import { useMemo } from 'react';
import { ListItem } from '@common-fe/common-fe';

// import _ from 'lodash';
import { ALL_OPTION } from '@/common/constants';
import { OptionKey, PaginationParams } from '@/common/types';
import { ReconciliationReportsTableFieldKeyEnum } from '@/modules/employer/queries/useReconciliationBankAccounts.query';

interface Args {
  data: ListItem[];
  pagination: PaginationParams,
  categories: OptionKey[];
}

export const useFilteringTableData = (args: Args) => {
  const { data, pagination: { searchString, perPage, page }, categories } = args;
  const preparedCategories = useMemo(() => categories.map((category) => `${category}`.toLowerCase()), [categories]);

  const filteredBySearchData = useMemo(() => (
    data.filter((el) => el.fields.some((field) => {
      if (
        field.key === ReconciliationReportsTableFieldKeyEnum.account
        || field.key === ReconciliationReportsTableFieldKeyEnum.accountID
      ) {
        return `${field.title}`.toLowerCase().includes((searchString || '').toLowerCase());
      }
      return !searchString;
    }))
  ), [data, searchString]);

  const filteredByCategoriesData = useMemo(() => (
    filteredBySearchData.filter((el) => el.fields.some((field) => {
      if (
        field.key === ReconciliationReportsTableFieldKeyEnum.type
        && categories[0] !== ALL_OPTION.value
      ) {
        return preparedCategories.includes((`${field.title}` || '').toLowerCase());
      }
      return categories[0] === ALL_OPTION.value;
    }))
  ), [filteredBySearchData, categories, preparedCategories]);

  const paginatedData = useMemo(() => (
    filteredByCategoriesData.slice((page - 1) * perPage, perPage * page)
  ), [filteredByCategoriesData, perPage, page]);

  return {
    paginatedData,
    filteredBySearchData,
    filteredByCategoriesData,
  };
};
