import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { PaymentMethodOwnerType } from '@/modules/employee/employee.types';

import { usePaymentMethodsStore } from '../../usePaymentMethods.store';

export enum VenmoFields {
  phoneNumber = 'phoneNumber',
  paymentMethodOwnerType = 'paymentMethodOwnerType',
}

export interface VenmoInfo {
  [VenmoFields.phoneNumber]?: string;
  [VenmoFields.paymentMethodOwnerType]?: PaymentMethodOwnerType;
}

export const useVenmoFields = () => {
  const editableMethod = usePaymentMethodsStore((store) => store.editableMethod);
  const fields = useMemo<Field[]>(() => [
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      placeholder: '+1',
      labelStyle: { maxWidth: '276px' },
      testId: 'Venmo',
      defaultValue: editableMethod?.requisites?.phoneNumber || '',
      validator: yup.string()
        .min(17, PHONE_NUMBER_INVALID_TEXT)
        .required(REQUIRED_TEXT),
    },
  ], [editableMethod]);

  return fields;
};
