import React from 'react';
import { FieldStateTypes,FlexFieldState } from '@common-fe/common-fe';

interface Props {
  onChangeValue: (value: FieldStateTypes) => void;
  value?: FieldStateTypes;
  disabled?: boolean;
  testId?: string;
  minimized?: boolean;
  options?: FieldStateTypes[];
}

export const DEFAULT_OPTIONS: FieldStateTypes[] = [
  FieldStateTypes.Modifiable,
  FieldStateTypes.Hidden,
  FieldStateTypes.Locked,
];
const TempalteStateField :React.FC<Props> = (props) => (
  <FlexFieldState {...props} options={DEFAULT_OPTIONS} />
);

export default TempalteStateField;
