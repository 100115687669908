import { useCallback, useState } from 'react';
import { FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import { CopayErrors } from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';

export const UNIQUE_NAME_MESSAGE = 'Copay group name must';
export default () => {
  const [currentErrors, setCurrentErrors] = useState<CopayErrors>({});
  const handleParseError = useCallback((values: FieldValues, e: unknown) => {
    const message = _.get(e, 'response.data.elevate_error_message', '');

    if (message.includes(UNIQUE_NAME_MESSAGE)) {
      setCurrentErrors({
        name: values.name,
      });
    }
  }, []);
  return {
    currentErrors,
    handleParseError,
  };
};
