import { DefaultValues } from '@/common/types';
import { storeCreator } from '@/utils/store/store.utils';

import { AccountUsagePayload } from '../AccountUsage.types';

interface State {
  handleReset: () => void;
  handleSetValues: (values: AccountUsagePayload) => void;
  state: AccountUsagePayload,
}

const DEFAULT_VALUES: AccountUsagePayload = {
  administratorFeesBank: '',
  administratorFeesMethod: '',
  payrollContributionBank: '',
  payrollContributionMethod: '',
  fundingReplenishmentBank: '',
  fundingReplenishmentMethod: '',
  holdPeriod: undefined,
};

interface DefaultMethods {
  administratorFeesMethod?: string,
  payrollContributionMethod?: string,
  fundingReplenishmentMethod?: string,
}

const getUnselectedMethods = (
  accountUsage: DefaultValues,
  defaultMethods: DefaultMethods,
) => Object
  .keys(accountUsage)
  .reduce((acc: string[], key: string) => {
    const rootPropName = (key.endsWith('Bank') && key.replace('Bank', '')) || '';
    const methodPropName = (rootPropName && `${rootPropName}Method`) as keyof DefaultMethods;

    if (
      methodPropName
        && accountUsage[key]
        && !accountUsage[methodPropName]
        && !defaultMethods[methodPropName]
    ) {
      acc.push(methodPropName);
    }

    return acc;
  }, []);

const useStore = storeCreator<State>((set) => ({
  state: DEFAULT_VALUES,
  handleReset: () => set(() => ({
    state: DEFAULT_VALUES,
  })),
  handleSetValues: (state: AccountUsagePayload) => set((prevState) => ({
    ...prevState,
    state: {
      ...prevState.state,
      ...state,
    },
  })),
}));

const useAccountUsageStore = () => {
  const store = useStore();
  const findUnselectedMethods = (defaultMethods: DefaultMethods) => getUnselectedMethods(
    store.state, defaultMethods,
  );
  return { ...store, findUnselectedMethods };
};

export default useAccountUsageStore;
