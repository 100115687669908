import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
import Reports from '@/modules/employer/components/Reports/Reports';

const ReportsPage = () => {
  useBreadcrumbs([{
    route: ROUTES.REPORTS,
    title: 'Reports',
  }]);

  return (
    <Box width="100%">
      <Box align="center">
        <Box width="100%">
          <Reports />
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsPage;
