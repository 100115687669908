import React from 'react';
import { Box, InfoOutlineIcon, Inscription } from '@common-fe/common-fe';

const InfoBlock = () => (
  <Box border={{ color: 'border2', size: 'xsmall' }} align="center" round="container1Round" pad="spacing12" style={{ position: 'relative' }}>
    <Box round="2px" height="2px" background="info" width="calc(100% - 24px)" style={{ top: '-1px', position: 'absolute' }} />
    <Box border={{ color: 'infoBorder', size: 'small' }} round="8px" background="infoContainer" pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}>
      <Box direction="row" align="center">
        <Box margin={{ right: 'spacing8' }} width={{ min: '24px' }}>
          <InfoOutlineIcon color="info" />
        </Box>
        <Inscription color="textBody" weight="bold" lineHeight="141%">Here you can apply details to all contribution record without having to update each record individually.</Inscription>
      </Box>
      <Box margin={{ top: 'spacing8' }}>
        <Inscription color="textBody" lineHeight="20px">You can change any of these values for specific employees and accounts in the next step.</Inscription>
      </Box>
    </Box>
  </Box>
);

export default InfoBlock;
