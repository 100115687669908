import { CardStatusEnum } from '@/modules/transaction/Cards/Cards.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: {
    modalVisible: boolean;
    cardholderName: string;
    cardholderID: string;
    cardID: string;
    mailedDate?: Date | null;
    activationDate?: Date | null;
    cardStatus?: CardStatusEnum;
  };
  handleChangeVisibility: () => void;
  handleChangeCardHolderName: (cardholderName: string) => void;
  handleChangeCardHolderID: (cardholderID: string) => void;
  handleChangeCardID: (cardID: string) => void;
  handleChangeCardStatus: (cardStatus: CardStatusEnum) => void;
  handleChangeMailedDate: (mailedDate: Date | null) => void;
  handleChangeActivationDate: (mailedDate: Date | null) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  state: {
    modalVisible: false,
    cardholderName: '',
    cardholderID: '',
    cardID: '',
    cardStatus: undefined,
    mailedDate: undefined,
    activationDate: undefined,
  },

  handleChangeVisibility: () => set((state) => ({
    ...state,
    state: {
      ...state.state,
      modalVisible: !state.state.modalVisible,
    },
  })),

  handleChangeCardHolderName: (cardholderName) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      cardholderName,
    },
  })),

  handleChangeCardHolderID: (cardholderID) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      cardholderID,
    },

  })),

  handleChangeMailedDate: (mailedDate) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      mailedDate,
    },
  })),

  handleChangeActivationDate: (activationDate) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      activationDate,
    },
  })),

  handleChangeCardID: (cardID) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      cardID,
    },
  })),
  handleChangeCardStatus: (cardStatus) => set((state) => ({
    ...state,
    state: {
      ...state.state,
      cardStatus,
    },
  })),

  handleReset: () => set((state) => ({
    ...state,
    state: {
      ...state.state,
      // cardholderName: '',
      cardholderID: '',
      cardID: '',
    },
  })),
}));
export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
