import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

interface Props {
  testId: string,
}

const FormLoader: React.FC<Props> = ({ testId }) => (
  <Box
    data-testId={`${testId}_spinner`}
    background="canvas"
    round="container1Round"
    elevation="default"
    pad="spacing24"
    align="center"
    justify="center"
    height="medium"
    margin={{ bottom: 'spacing16' }}
  >
    <Preloader />
  </Box>
);

export default FormLoader;
