import React, { useMemo } from 'react';
import {
  Box,
  Field,
  FieldTypes,
  Inscription,
  OptionKey,
  PlusIcon,
  PriceInput,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';
import { Copay } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import useCopaysFormValidate, { CopayError } from '@/modules/ExpressSetup/hooks/useCopaysFormValidate.ts';

import CopayField from '../CopayField';
import CopayLabelWithNumber from '../CopayLabelWithNumber';

export const PriceControl = styled((props) => <PriceInput {...props} />)`
  width: 160px;
  max-height: 40px;
`;

interface GenericFieldProps {
  copayNumber: number;
  copay: Copay;
  copayErrors: CopayError[];
  total: number;
  onRemove: (id: string) => void;
  onUpdateCopayState?: (copay: Copay) => void;
  serviceCategories?: ServiceCategoryTypes[];
  showErrors?: boolean;
}

const genericField = ({
  copayNumber,
  copay,
  copayErrors,
  total,
  onRemove,
  onUpdateCopayState,
  serviceCategories,
  showErrors,
}: GenericFieldProps) => {
  const currentError = copayErrors?.find((error) => error.id === copay?.id);
  const handleCategoryChange = (value: OptionKey) => {
    if (onUpdateCopayState) {
      onUpdateCopayState({ id: copay?.id, categoryType: value as string, amount: copay?.amount });
    }
  };

  const handleAmountChange = (value: string) => {
    if (onUpdateCopayState) {
      onUpdateCopayState({ id: copay?.id, categoryType: copay?.categoryType, amount: value });
    }
  };

  return {
    name: `copay${copayNumber}`,
    type: FieldTypes.Node,
    label: <CopayLabelWithNumber copayNumber={copayNumber} />,
    fill: true,
    value: (
      <CopayField
        copayNumber={copayNumber}
        copay={copay}
        currentError={currentError}
        total={total}
        onRemove={onRemove}
        handleCategoryChange={handleCategoryChange}
        handleAmountChange={handleAmountChange}
        serviceCategories={serviceCategories}
        showErrors={showErrors}
      />
    ),
  };
};

interface Props {
  copaysDefault: Copay[];
  showErrors: boolean;
  serviceCategories?: ServiceCategoryTypes[],
  onAddCopay: () => void;
  onRemoveCopay: (id: string) => void;
  onUpdateCopayState: (copay: Copay) => void;
}

export const useCopayFields = ({
  copaysDefault,
  showErrors,
  serviceCategories,
  onAddCopay,
  onRemoveCopay,
  onUpdateCopayState,
}: Props) => {
  const copayErrors = useCopaysFormValidate();
  const generatedFields = useMemo(() => {
    const fields = [];
    for (let i = 1; i <= copaysDefault?.length; i++) {
      fields.push(
        genericField({
          copayNumber: i,
          copay: copaysDefault?.[i - 1],
          copayErrors,
          total: copaysDefault?.length,
          onRemove: onRemoveCopay,
          onUpdateCopayState,
          serviceCategories,
          showErrors,
        })
      );
    }
    return fields;
  }, [copayErrors, copaysDefault, onRemoveCopay, onUpdateCopayState, serviceCategories, showErrors]);

  const fields = useMemo(() => [
    ...generatedFields,
    {
      name: 'addCustomCopay',
      type: FieldTypes.Node,
      fill: true,
      value: (
        <Box
          direction="row"
          align="center"
          height={{ min: '24px', max: '24px' }}
          onClick={onAddCopay}
          pad={{ horizontal: 'xs', vertical: 'xxs' }}
        >
          <Box
            width={{ min: '24px', max: '24px' }}
            height={{ min: '24px', max: '24px' }}
            align="center"
            justify="center"
          >
            <PlusIcon color="iconAccent" size="12px" />
          </Box>
          <Inscription lineHeight="20px" color="textAccent" weight={700}>
            Add new Copay
          </Inscription>
        </Box>
      ),
    },
  ], [generatedFields, onAddCopay]);

  return fields as Field[];
};
