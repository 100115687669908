import React, { useEffect,useState } from 'react';
import {
  Box,
  FlexControlledForm,
  useDebounce,
} from '@common-fe/common-fe';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSetupEmployerStore } from '@/modules/employer/store/useSetupEmployer.store';

import useClaimsSectionForm from './hooks/useClaimsSettingsForm';
import useClaimsSettingsStore from './store/useClaimsSettings.store';
import ResetButton from './ResetButton';

const ClaimsSettings = () => {
  const { loading } = useSetupEmployerStore();
  const { viewMode } = useSetupEmployerMode();
  const [currentValues, setCurrentValues] = useState({});
  const debouncedValue = useDebounce(currentValues);
  const fields = useClaimsSectionForm(currentValues);
  const { setClaimsSettings } = useClaimsSettingsStore();

  useEffect(() => {
    setClaimsSettings(debouncedValue);
  }, [debouncedValue, setClaimsSettings]);

  if (loading) return <FormLoader testId="claims-section-loader" />;

  return (
    <Box
      data-testId="claims-section-wrapper"
      direction="row"
      justify="between"
      align="center"
      pad={{ right: 'spacing32' }}
      background="canvas"
      round="container1Round"
      border={{ color: 'border2', size: 'small' }}
    >
      <FlexControlledForm
        editMode={!viewMode}
        showError
        fields={fields}
        onChangeValues={setCurrentValues}
        isThinMode
        {...!viewMode ? { shouldControlValues: true } : {}}
        wrapperStyle={{ border: 'none', paddingBottom: 0 }}
      />
      <ResetButton
        viewMode={viewMode}
        currentValues={currentValues}
        setCurrentValues={setCurrentValues}
      />
    </Box>
  );
};

export default ClaimsSettings;
