import { toString } from 'lodash';

import { Roles } from '@/common/constants';
import { DefaultValues } from '@/common/types';
import { ContactInfo, ContactStatuses, EmailCommunicationType } from '@/modules/contact/types/Contact.types';

const getEntrie = (entrie: DefaultValues): DefaultValues => {
  const [key, value] = Object.entries(entrie).flat();

  if (value || typeof value === 'boolean'
    || typeof value === 'number') {
    return { [key]: value };
  }

  return {};
};

export interface ContactInfoPayload {
  id?: number,
  username?: string,
  role?: Roles,
  title?: string,
  first_name?: string,
  last_name?: string,
  email?: string,
  phone?: string,
  department?: string,
  primary?: true,
  external_identifier?: string,
  contact_type?: Roles,
  contact_status_type?: ContactStatuses,
  email_communication_type_list?: EmailCommunicationType[];
  clock_in?: boolean;
}

export const formatContactInfoData = (data?: ContactInfoPayload): ContactInfo => ({
  ...getEntrie({ id: toString(data?.id) }),
  ...getEntrie({ userName: data?.username }),
  ...getEntrie({ role: data?.role }),
  ...getEntrie({ title: data?.title }),
  ...getEntrie({ firstName: data?.first_name }),
  ...getEntrie({ lastName: data?.last_name }),
  ...getEntrie({ email: data?.email }),
  ...getEntrie({ phone: data?.phone }),
  ...getEntrie({ department: data?.department }),
  ...getEntrie({ primary: data?.primary }),
  ...getEntrie({ externalIdentifier: data?.external_identifier }),
  ...getEntrie({ contactType: data?.contact_type }),
  ...getEntrie({ emailCommunicationTypeList: data?.email_communication_type_list }),
  clockIn: data?.clock_in || false,
});
