import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { LogoFile, LogoType } from '../branding.types';

const UPLOAD_ORG_LOGOS_QUERY_KEY = 'uploadOrganizationLogosQueryKey';

const UPLOAD_LOGO_KEYS = {
  orgId: 'organization-id',
  logoType: 'logo-type',
};

export const useUploadOrganizationLogos = (
  organizationId: string,
  logoType: LogoType,
  onError?: (error: unknown, logoType: LogoType) => void,
) => {
  const params = useMemo(() => {
    const queryGenerator = new URLSearchParams();

    queryGenerator.set(UPLOAD_LOGO_KEYS.orgId, organizationId);
    queryGenerator.set(UPLOAD_LOGO_KEYS.logoType, logoType);

    return queryGenerator;
  }, [logoType, organizationId]);

  const formatFile = (file: LogoFile) => {
    const formData = new FormData();
    formData.append('file', file as Blob, file.name);

    return formData;
  };

  const { mutateAsync, isLoading } = useMutation(
    [UPLOAD_ORG_LOGOS_QUERY_KEY, organizationId, logoType],
    async (file: LogoFile) => api.post(
      PATHS.UPLOAD_ORGANIZATION_LOGO,
      formatFile(file),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      },
    ),
    {
      onError: (error) => {
        if (onError) {
          onError(error, logoType);
        }
      },
    },
  );

  return {
    uploadLogo: mutateAsync,
    isLoading,
  };
};
