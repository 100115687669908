import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaymentMethodDtoPayload } from '@/modules/employee/employee.types';
import { formatPaymentMethod } from '@/modules/ReimburseMe/queries/useAddPaymentMethod.query';

export const useGetPaymentMethods = (
  employeeId?: string,
) => {
  const {
    data, isLoading,
  } = useQuery(
    [PATHS.PAYMENT_METHODS, employeeId],
    employeeId ? () => api.get<PaymentMethodDtoPayload[]>(
      PATHS.PAYMENT_METHODS,
      {
        params: { employee_id: employeeId },
      },
    ) : () => null,
  );

  const formattedData = useMemo(
    () => (data?.data || [])
      .map((paymentMethod) => formatPaymentMethod(paymentMethod)),
    [data],
  );

  return {
    isLoading,
    formattedData,
  };
};
