import React from 'react';
import {
  Box,
  FlexList,
  ListItemBase,
  OptionKey,
  PaginationBar,
  Preloader,
  SearchInput,
  SelectDropdown,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { DefaultSortTypesEnum } from '@/common/types';
import ListPlaceholder from '@/modules/ProcessingDefinition/ListPlaceholder';

import { useScheduledContributionStore } from '../../stores';
import { useScheduledContributionRecords } from '../hooks';

import ScheduledContributionRecordsItem from './ScheduledContributionRecordsItem';
const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'name',
    title: 'Name',
    flex: 3,
  },
  {
    key: 'account',
    title: 'Account',
    flex: 2,
  },
  {
    key: 'employeeAmount',
    title: 'Employee amount',
    flex: 2,
  },
  {
    key: 'employerAmount',
    title: 'Employer amount',
    flex: 2,
  },
  {
    key: 'taxType',
    title: 'Tax Type',
    flex: 7,
  },
];
const PER_PAGE_OPTIONS = [
  {
    key: '10',
    value: '10',
    title: '10',
  },

  {
    key: '20',
    value: '20',
    title: '20',
  },
  {
    key: '50',
    value: '50',
    title: '50',
  },
  {
    key: '100',
    value: '100',
    title: '100',
  },
];
const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'A-Z',
  },
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Z-A',
  },
];

const ScheduledContributionRecords = () => {
  const {
    isLoading,
    data,
    total,
    page,
    setPage,
    accountsOptions,
    setCurrentAccounts,
    currentAccounts,
    searchString,
    setSearchString,
    perPage,
    setPerPage,

    sortBy,
    setSortBy
  } = useScheduledContributionRecords();

  const taxType = useScheduledContributionStore((state)=> state.state.taxType);
  return (
    <Box pad="l" round="moduleRound" background="module">
      <Box margin={{ bottom: 's' }} direction="row" align="center">
        <Box style={{ flex: 1 }}>
          <Text weight="bold" size="large">
            Records: {total}
          </Text>
        </Box>
        <Box width="260px">
          <SearchInput
            hasSearchingHistory
            testId="ContributionEmployeesSettings"
            value={searchString}
            placeholder="Search by name"
            onChange={(val) => {
              setSearchString(val);
            }}
            // resetValue={cleaningInitiator}
            // disabled={hasMessageToCorrectErrors}
          />
        </Box>
        <Box width="150px" margin={{ left: 'spacing12' }}>
          <SelectDropdown
            testId="show_items_per_page"
            id="show_items_per_page"
            activeTitle
            ellipsisMode
            name="Show"
            prefix="Show"
            options={PER_PAGE_OPTIONS}
            onChange={(val) => {
              setPerPage(_.toNumber(val));
            }}
            defaultValue={perPage}
            singleMode
            value={perPage}
          />
        </Box>

        <Box width="150px" margin={{ left: 'spacing12' }}>
          <SelectDropdown
            id="accounts"
            testId="accounts"
            activeTitle
            ellipsisMode
            name="Accounts"
            prefix="Accounts"
            disabled={!accountsOptions.length}
            options={accountsOptions}
            values={currentAccounts}
            onChangeValues={(val: OptionKey[]) => {
              setCurrentAccounts(val);
              setPerPage(0);
            }}
          />
        </Box>

        <Box width="150px" margin={{ left: 'spacing12' }}>
          <SelectDropdown
            id="sortBy"
            testId="sortBy"
            activeTitle
            singleMode
            ellipsisMode
            name="sortBy"
            prefix="Name"
            value={sortBy}
            onChange={setSortBy}
            // disabled={(!filtredRecipients?.length && !hasAccountSelected) || hasMessageToCorrectErrors}
            options={SORTING_OPTIONS}
          />
        </Box>
      </Box>

      <FlexList
        total={total}
        headers={LIST_HEADERS}
        rows={[]}
        loading={isLoading}
        moreCount={perPage}
        pad={{ horizontal: '0px', vertical: '0px' }}
        // options={options}
        placeholder={<ListPlaceholder>There are no records</ListPlaceholder>}
        footer={(
          <>
            <Box
              margin={{ top: 'xs' }}
              pad={{
                vertical: 's',
                horizontal: 'l',
              }}
              border={{ size: 'small', color: 'border2' }}
              round="container2Round"
              background="canvas"
            >
              {isLoading && <Preloader />}
              {data.map((item, index) => (
                <ScheduledContributionRecordsItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  taxType={taxType}
                  accounts={item.accounts}
                  isLastItem={index === data.length - 1}
                />
              ))}
            </Box>
            {total ? (
              <PaginationBar
                page={page}
                total={total}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            ) : undefined}
          </>
        )}
      />
    </Box>
  );
};
export default ScheduledContributionRecords;
