import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

const Wrapper = styled(Box)`
&:hover {
    color: ${({ theme }) => theme.colors.textAccent};
    background-color: ${({ theme }) => theme.colors.accentContainer};
  }
`;

interface Props {
  isNewPlanYearOptionExist?: boolean;
}

const AddNewPlanTitle: React.FC<Props> = ({
  isNewPlanYearOptionExist,
}) => (
  <Wrapper
    fill
    align="start"
    pad={{ bottom: 'spacing4', top: 'spacing4', horizontal: 'spacing8' }}
    border={isNewPlanYearOptionExist && { side: 'bottom', size: '2px', color: 'border1' }}
    round="dropdownItemRound"
  >
    <Text size="medium" color="textAccent">Add New Plan Year</Text>
  </Wrapper>

);
export default AddNewPlanTitle;
