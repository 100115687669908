import { Field, FieldTypes } from '@common-fe/common-fe';

 
const getValue = (field: Field) => {
  if (field.type === FieldTypes.Checkbox) return `${!!field.value}`;
  return field.value?.toString ? field.value.toString() : field.value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSubFieldsValueMap = (field: Field): { [name: string]: any } => {
  const value = getValue(field);
  const subFields = value ? field.subFields || [] : [];
  return subFields.reduce((subFieldsMap, subField) => {
    if (field.type === FieldTypes.Checkbox && `${field.value}` !== 'true') return subFieldsMap;
    if (field.type !== FieldTypes.Checkbox
      && !field.areSubFieldsIncluded
      && field.value !== subField.parentValue) return subFieldsMap;

    return {
      ...subFieldsMap,
      [subField.name]: getValue(subField),
      ...getSubFieldsValueMap(subField),
    };
  }, {});
};

export const fieldArrToMap = (fields: Field[], shouldToBeRequired: boolean = false) => fields
  .filter((field) => {
    if (shouldToBeRequired) {
      return (field.validator
        ? field.validator.describe().tests.some((test) => test.name === 'required')
        : true);
    }
    return true;
  })
  .reduce((fieldMap, field) => ({
    ...fieldMap,
    ...field.noIncludeToProgressCalculation
      ? {}
      : { [field.name]: getValue(field) },
    ...getSubFieldsValueMap(field),
  }), {});
