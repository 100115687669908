 
import React from 'react';
import { Box, FlexControlledForm, Text } from '@common-fe/common-fe';
import { CheckBox } from 'grommet';

import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';

import { useCopayFields } from './hooks/useCopayFields';
import { useCopayActions } from './hooks';

interface Props {
  serviceCategories?: ServiceCategoryTypes[],
}

const CopaysForm: React.FC<Props> = ({
  serviceCategories,
}) => {
  const {
    copayState,
    showErrors,
    handleAddCopay,
    handleRemoveCopay,
    handleUpdateCopay,
    handleToggleCopaysSelected,
  } = useCopayActions();

  const fields = useCopayFields({
    copaysDefault: copayState.copays || [],
    showErrors: showErrors,
    onAddCopay: handleAddCopay,
    onRemoveCopay: handleRemoveCopay,
    onUpdateCopayState: handleUpdateCopay,
    serviceCategories: serviceCategories,
  });

  return (
    <Box
      data-testId="copays-form"
      round="container1Round"
    >
      <Box background="module" round="container1Round">
        <Box
          direction="row"
          data-testId="copay-form-card"
          background="canvas"
          pad="l"
          border={{ color: 'border2', size: 'small' }}
          round="fieldRound"
          height="fit-content"
          justify="between"
          onClick={handleToggleCopaysSelected}
          style={{ cursor: 'pointer' }}
        >
          <Box width={{ min: '32px' }} margin={{ right: 's' }} align="center" justify="center">
            <CheckBox
              name=""
              checked={copayState?.isCopaysSelected}
            />
          </Box>
          <Box width="100%">
            <Text weight={700} size="20px" color="textTitle" style={{ lineHeight: '28px' }} margin={{ bottom: 'xxxs' }}>
              Copays
            </Text>
            <Text
              color="textSecondary"
              style={{ lineHeight: '20px' }}
              weight={400}
            >
              Enter all copay amounts set by your health plan for covered services. Adding plan copays assists with claims auto-adjudication and reduces the amount of documentation that your employees are required to submit.
            </Text>
          </Box>
        </Box>
        {
          copayState?.isCopaysSelected && (
            <Box
              background="canvas"
              round="container1Round"
              margin={{ top: 'm', bottom: 'l', horizontal: 'l' }}
            >
              <FlexControlledForm
                fields={fields}
                editMode
                isModalType
              />
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default CopaysForm;
