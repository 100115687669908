import React, { useCallback, useMemo } from 'react';
import { Text } from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import { TechnicalSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import { StyledResetButton } from '../TechnicalSettings.styles';

export const DEFAULT_VALUES = {
  sessionTimeoutLogout: 1,
  sessionTimeoutWarn: 10,
};

interface Props {
  viewMode: boolean;
  currentValues: TechnicalSettings;
  setCurrentValues: (defaultValue: TechnicalSettings) => void;
}

const ResetButton: React.FC<Props> = ({ viewMode, setCurrentValues, currentValues }) => {
  const sessionTimeoutWarnPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.SESSION_TIMEOUT_WARN,
  );
  const sessionTimeoutLogoutPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.SESSION_TIMEOUT_LOGOUT,
  );
  const isDisabled = useMemo(() => sessionTimeoutWarnPermProps.disabled
    || sessionTimeoutLogoutPermProps.disabled,
  [sessionTimeoutWarnPermProps, sessionTimeoutLogoutPermProps]);
  const isDefaultValuesEstablished = useMemo(() => (
    currentValues.sessionTimeoutLogout === DEFAULT_VALUES.sessionTimeoutLogout) && (
    currentValues.sessionTimeoutWarn === DEFAULT_VALUES.sessionTimeoutWarn
  ), [currentValues]);

  const onReset = useCallback(() => {
    if (isDefaultValuesEstablished) return;
    setCurrentValues(DEFAULT_VALUES);
  }, [setCurrentValues, isDefaultValuesEstablished]);

  if (viewMode) return null;

  return (
    <StyledResetButton
      data-testId="reset-button"
      isDefaultValuesEstablished={isDefaultValuesEstablished}
      isDisabled={isDisabled}
      margin={{
        top: '22px',
        left: 'auto',
        right: 'spacing32',
        bottom: 'auto',
      }}
      {...isDisabled ? {} : { onClick: onReset }}
    >
      <Text>Reset to Default</Text>
    </StyledResetButton>
  );
};

export default ResetButton;
