import React, { useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FlatTextInput, Hint, Text } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE } from '@/common/constants';

import FileLocationTitle from './FileLocationTitle';

interface Props {
  checked?: boolean;
  onChangeCheck: (value: boolean) => void;
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
  isViewMode?: boolean;
  disabled?: boolean;
}
const FileLocationField: React.FC<Props> = ({
  checked,
  onChangeCheck,
  value,
  onChange,
  isViewMode,
  disabled,
  isError,
}) => {
  const [currentChecked, setCurrentChecked] = useState(checked || false);
  const [currentValue, setCurrentValue] = useState(value || '');
  useEffect(() => {
    if (currentChecked !== checked) {
      onChangeCheck(currentChecked);
    }
  }, [checked, currentChecked, onChangeCheck]);

  useEffect(() => {
    if (currentValue !== value) {
      onChange(currentValue);
    }
  }, [currentValue, onChange, value]);
  const errorMessage = useMemo(() => {
    if (!isError) {
      return '';
    }
    if (currentValue.trim().length > 0) {
      return '';
    }
    return 'This field is required';
  }, [currentValue, isError]);
  if (isViewMode && !checked) {
    return EMPTY_FIELD_VALUE;
  }

  return (
    <Box direction="column">
      <Checkbox
        testId="isSendFiles"
        disabled={disabled}
        checked={currentChecked}
        onChange={setCurrentChecked}
      />
      {currentChecked && (
        <Box width="320px" direction="column">
          <FileLocationTitle value={isViewMode ? value || '' : ''} />
          {!isViewMode && (
            <Box direction="row" margin={{top: '8px'}}>
           
              <FlatTextInput
                testId="file-location_input"
                disabled={disabled}
                // isError={true}
                errorText={errorMessage}
                value={currentValue}
                placeholder="File location"
                onChange={setCurrentValue}
              />
              <Hint>
                <Text size="small">Enter the exact name you gave the folder in your S3 bucket.</Text>
              </Hint>
            </Box>
          )}
         
        </Box>
      )}
    </Box>
  );
};

export default FileLocationField;
