import React from 'react';
import {
  Box,
  capitalizeFirstLetter,
  CoveragePeriods,
  EnrollmentStatus,
  Inscription,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { PolicyStatus } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';
import theme from '@/styles/theme';

import CoveragePeriodsTable from './CoveragePeriodsTable';
import EnrollmentStatusesTable from './EnrollmentStatusesTable';

interface StatusProps {
  iconColor?: string;
}

export const Status = styled(Inscription)<StatusProps>`
  position: relative;
  padding-left: 14px;
  margin-left: ${theme.spacings.xs};
  display: flex;
  color: ${theme.colors.textBody};
  ${theme.fonts.text12Bold};
  flex-direction: row;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 10px;
    height: 10px;
    background: ${({ iconColor }) => iconColor};
    border-radius: 100%;
  }
`;

interface Props {
  statusColor?: string;
  isDetailsMode?: boolean,
  enrollmentStatus?: EnrollmentStatus,
  statuses?: PolicyStatus[],
  coveragePeriods: CoveragePeriods[];
}

const EnrollmentExpandedPeriodCard: React.FC<Props> = ({
  statusColor,
  isDetailsMode,
  enrollmentStatus,
  statuses = [],
  coveragePeriods = [],
}) => {
  if (isDetailsMode) {
    return (
      <>
        <Box height="1px" background="border1" margin={{ vertical: 'm' }} />
        <Box direction="row" width="100%" justify="between">
          {!!statuses?.length && (
            <Box
              background="canvas"
              pad={{ bottom: 'xs', horizontal: 'm', top: 'm' }}
              round="small"
              margin={{ right: coveragePeriods?.length ? 'm' : '0' }}
              width="100%"
              height="fit-content"
            >
              <Text
                size="small"
                weight="bold"
                color="textTitle"
                margin={{ bottom: 'xs' }}
              >
                Enrollment status history
              </Text>
              <EnrollmentStatusesTable statuses={statuses} />
            </Box>
          )}
          {!!coveragePeriods?.length && (
            <Box
              background="canvas"
              pad={{ bottom: 'xs', horizontal: 'm', top: 'm' }}
              round="small"
              width="100%"
              height="fit-content"
            >
              <Text
                size="small"
                weight="bold"
                color="textTitle"
                margin={{ bottom: 'xs' }}
              >
                All coverage periods
              </Text>
              <CoveragePeriodsTable coveragePeriods={coveragePeriods} />
            </Box>
          )}
        </Box>
      </>
    );
  }
  return (
    <Box margin={{ left: '34.5px' }}>
      {
        !!coveragePeriods.length && (
          <>
            <Inscription
              color="textSecondary"
              size="small"
              style={{ fontWeight: 500 }}
            >
              All coverage periods
            </Inscription>
            <CoveragePeriodsTable coveragePeriods={coveragePeriods}/>
            <Box height="1px" background="border1" margin={{ bottom: 'xs' }} />
          </>
        )
      }
      {
        enrollmentStatus && (
          <>
            <Box
              direction="row"
              align="center"
            >
              <Inscription
                color="textSecondary"
                size="small"
                style={{ fontWeight: 500 }}
              >
                Enrollment status:
              </Inscription>
              <Status lineHeight="24px" iconColor={statusColor}>
                {enrollmentStatus && capitalizeFirstLetter(enrollmentStatus)}
              </Status>
            </Box>
          </>
        )
      }
      {!!statuses?.length && <EnrollmentStatusesTable statuses={statuses} />}
    </Box>
  );
};

export default EnrollmentExpandedPeriodCard;
