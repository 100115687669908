 
import { useMemo } from 'react';

import { HealthPlanFieldState,HealthPlanResponse } from '../../HealthPlan.types';

export const getHealthPlanFieldState = (
  healthPlan: HealthPlanResponse,
  isTempalteMode?: boolean,
) => ({
  healthPlanName: healthPlan?.name.name_state,
  parentTemplate: HealthPlanFieldState.Locked,
  carrierName: !isTempalteMode
    ? HealthPlanFieldState.Locked
    : healthPlan.carrier_id.carrier_id_state,
  healthPlanType: healthPlan.health_plan_types.health_plan_type_state,
  autocreateCdhEnrollment: healthPlan.is_auto_enrollment.is_auto_enrollment_state,
  planCode: HealthPlanFieldState.Modifiable,
  plans: HealthPlanFieldState.Modifiable,
  deductibleService: healthPlan.deductibles_by_service_type.deductibles_by_service_type_state,
  coverageLevels: healthPlan.health_plan_deductible_configs?.map((item) => ({
    coverageId: `${item.coverage?.id}`,
    coverageCode: item.code?.code_state,
    planDeductible: item.plan_amount?.plan_amount_state,
    embeddedAmount: item.embedded_amount?.embedded_amount_state,
    memberDeductible: item.member_amount?.member_amount_state,
  })),
});

export default (healthPlan: HealthPlanResponse | undefined, isTempalteMode?: boolean) => {
  const fieldsState = useMemo(() => {
    if (healthPlan) {
      const healthPlanPermissions = getHealthPlanFieldState(healthPlan, isTempalteMode);
      return healthPlanPermissions;
    }
    return {};
  }, [healthPlan, isTempalteMode]);
  return fieldsState;
};
