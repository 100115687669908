import { useMemo } from 'react';

import { useStore as useAccountFundingParamsStore } from '../AccountFundingParams/stores/useAccountFundingParams.store';
import { useStore as useContributionStore } from '../Contribution/stores/useContribution.store';
import { useStore as useInterestAccrualsStore } from '../InterestAccruals/stores/useInterestAccruals.store';
import { useStore as useRolloverStore } from '../Rollover/stores/useRollover.store';

export default () => {
  const rolloverStore = useRolloverStore((state) => state.state);
  const accountFundingParamsStore = useAccountFundingParamsStore((state) => state.state);
  const contributuonStore = useContributionStore((state) => state.state);
  const interestAccrualsStore = useInterestAccrualsStore((state) => state.state);

  const state = useMemo(() => ({
    ...accountFundingParamsStore,
    ...rolloverStore,
    ...contributuonStore,
    ...interestAccrualsStore,
  }), [accountFundingParamsStore, contributuonStore, interestAccrualsStore, rolloverStore]);
  return state;
};
