import { DefaultClaimPayeeType, SourceIds } from '../employee.types';

export enum EmployeePhoneNumber {
  Work = 'WORK',
  Home = 'HOME',
  Unkknown = 'UNKNOWN',
  Mobile = 'MOBILE',
}

export enum EmployeeAddressType {
  PO_BOX = 'PO_BOX',
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  UNKNOWN = 'UNKNOWN',
}

export const EmployerStatuses = [
  {
    key: 'A',
    value: 'Active',
    title: 'Active',
  },
  {
    key: 'T',
    value: 'Terminated',
    title: 'Terminated',
  },
  {
    key: 'R',
    value: 'Retired',
    title: 'Retired',
  },
  {
    key: 'L',
    value: 'Leave of absence',
    title: 'Leave of absence',
  },
  {
    key: 'F',
    value: 'Future',
    title: 'Future',
  },
  {
    key: 'C',
    value: 'COBRA',
    title: 'COBRA',
  },
];

export enum ClaimAutopayType {
  auto = 'AUTO',
  click = 'CLICK',
  employeeChoice = 'EMPLOYEE_CHOICE',
}

export interface EmployeeCommunicationConfigurationDto {
  communication_config_channels: string[];
  notification_priority: string;
}

export enum OrganizationStatus {
  active = 'A',
  terminated = 'T',
  retired = 'R',
  leaveOfAbsence = 'L',
  future = 'F',
  cobra = 'C',
}

export interface EmploymentStatus {
  isActive?: boolean;
  status?: OrganizationStatus;
  validFrom?: string;
  validTo?: string;
}
export interface EmploymentStatusPayload {
  is_active?: boolean;
  status?: OrganizationStatus;
  valid_from?: string;
  valid_to?: string;
}

export interface EmployeePayload {
  id?: string;
  person_id?: string;
  first_name?: string;
  middle_name?: string | null;
  last_name?: string;
  suffix?: string | null;
  full_name?: string;
  birth_on?: string;
  ssn?: string | null;
  employer_provided_email?: string | null;
  employee_provided_email?: string | null;
  home_phone_number?: string;
  'phone1'?: {
    number?: string;
    phone_type?: EmployeePhoneNumber
  };
  'phone2'?: {
    number?: string;
    phone_type?: EmployeePhoneNumber;
  };
  'mailing_address'?: {
    'line1'?: string;
    'line2'?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?: EmployeeAddressType;
  };
  'physical_address'?: {

    'line1'?: string;
    'line2'?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country_code?: string;
    address_type?:EmployeeAddressType;
  };
  use_same_address_for_mailing?: boolean;
  employer_name?: string;
  is_employee_email_preferred?: boolean;
  partner_employee_id?: string | null;
  employer_employee_id?: string | null;
  organization_id_source_type?: SourceIds;
  employee_id?: string;
  status?: string;
  hire_on?:string;
  termination_on?: string;
  employment_status_effective_on?: string;
  gender?: string;
  user_name?: string;

  user_communication_configuration?: EmployeeCommunicationConfigurationDto;
  organization_id?: number;
  organization_name?: string;
  organization_path?: string;

  claim_autopay_type?: ClaimAutopayType;
  default_claim_payee_type?: DefaultClaimPayeeType;

  employment_statuses?: EmploymentStatusPayload[];
  payroll_group_id?: string;
  payroll_group_code?: string;
  valid_from?: string;
  custom_field1?: string;
  custom_field2?: string;
  custom_field3?: string;
  custom_field4?: string;
  custom_field5?: string;
}
