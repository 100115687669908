import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ServiceCategoryTypes,ServiceCategoryTypesPayload } from '@/modules/employer/types/ServiceCategories.types';

export const getCategories = () => api
  .get<ServiceCategoryTypesPayload[]>(PATHS.SERVICE_CATEGORIES);

export const QUERY_KEY = 'getServiceCategories';
const useServiceCategoriesQuery = () => {
  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY, getCategories], getCategories,
  );

  const list = useMemo(() => data?.data.map((item): ServiceCategoryTypes => ({
    id: toString(item.id),
    key: item.name,
    value: item.description,
  })), [data]);

  return { isLoading, list: list || [] };
};

export default useServiceCategoriesQuery;
