import { baseStoreCreator } from '@/utils/store';

export interface RolloverPayload {

  rollover: boolean;
  maximumRolloverAmount: string;
  maximumRolloverAmountCustom: string;
  minimumRolloverAmount: string;
  minimumRolloverAmountCustom: string;
  rolloverClaims: boolean;
  claimsPriorYear: string;
  autoEnrollment: string;
  daysAfterPlanEnd: string;
  maximumRolloverAmountIRS: string;
}

export const DEFAULT_VALUES: RolloverPayload = {
  rollover: true,
  maximumRolloverAmount: '',
  maximumRolloverAmountCustom: '',
  minimumRolloverAmount: '',
  minimumRolloverAmountCustom: '',
  maximumRolloverAmountIRS: '',
  rolloverClaims: false,
  claimsPriorYear: '',
  autoEnrollment: '',
  daysAfterPlanEnd: '',
};

export const useStore = baseStoreCreator<RolloverPayload>(DEFAULT_VALUES);

export default useStore;
