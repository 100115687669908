import React, { useCallback, useState } from 'react';
import {
  Box, Checkbox, ChevronDownIcon,
  ChevronRightIcon, Inscription,
} from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { unCamelCase } from '@/utils';

import { EllipsisText } from '../ActivityLog.styles';

import { formatAttribute } from './LogItem/Changeset/common';
import { Attribute } from './useGetAttributesList.query';

export type EntityAttributes = {
  [entityName: string]: string[];
};

interface Props {
  data: Attribute;
  selectedAttributes: EntityAttributes;
  setSelectedAttributes: (attributes: EntityAttributes) => void;
}

const Entity: React.FC<Props> = ({ data, selectedAttributes, setSelectedAttributes }) => {
  const [isShown, setIsShown] = useState(false);
  const onChangeEntity = useCallback((val: boolean) => {
    if (val) {
      setSelectedAttributes(
        {
          ...selectedAttributes,
          [data.entityName]: [...data.attributes],
        },
      );
    } else {
      setSelectedAttributes(
        {
          ...selectedAttributes,
          [data.entityName]: [],
        },
      );
    }
  }, [setSelectedAttributes, selectedAttributes, data]);
  const onChangeEntityItem = useCallback((val: boolean, attribute: string) => {
    const withoutThisAttribute = selectedAttributes[data.entityName]?.filter(
      (item) => item !== attribute,
    ) || [];
    setSelectedAttributes(
      val
        ? {
          ...selectedAttributes,
          [data.entityName]: [...withoutThisAttribute, attribute],
        }
        : {
          ...selectedAttributes,
          [data.entityName]: [...withoutThisAttribute],
        },
    );
  }, [selectedAttributes, setSelectedAttributes, data]);

  return (
    <Box>
      <Box direction="row" align="center">
        <Box align="center" onClick={() => setIsShown(!isShown)} pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}>
          <Box width="24px" height="24px" justify="center" align="center">
            {isShown ? (
              <ChevronDownIcon color="textTitle" />
            ) : (
              <ChevronRightIcon color="textTitle" />
            )}
          </Box>
        </Box>
        <Checkbox
          testId={`entity_name_${data.entityName}`}
          checked={data.attributes?.length === selectedAttributes[data.entityName]?.length}
          onChange={onChangeEntity}
        >
          <Inscription
            lineHeight="20px"
            onClick={() => onChangeEntity(
              data.attributes?.length !== selectedAttributes[data.entityName]?.length,
            )}
          >
            {capitalize(unCamelCase(data.entityName))} ({data.attributes?.length})
          </Inscription>
        </Checkbox>
      </Box>

      {isShown ? (
        <Box margin={{ left: '42px' }}>
          {data.attributes?.map((attribute) => (
            <Box
              pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
              key={attribute}
              direction="row"
              align="center"
            >
              <Checkbox
                testId={`attribute_${attribute}`}
                checked={selectedAttributes[data.entityName]?.some((item) => item === attribute)}
                onChange={(val) => onChangeEntityItem(val, attribute)}
              >
                <Box
                  width={{ max: '194px' }}
                  height="24px"
                  direction="row"
                  align="center"
                  onClick={() => {
                    const isChecked = selectedAttributes[data.entityName]?.some(
                      (item) => item === attribute,
                    );
                    onChangeEntityItem(
                      !isChecked,
                      attribute,
                    );
                  }}
                >
                  <EllipsisText
                    lineHeight="20px"
                    title={formatAttribute(attribute)}
                  >
                    {formatAttribute(attribute)}
                  </EllipsisText>
                </Box>
              </Checkbox>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default Entity;
