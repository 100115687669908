import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box, FilledDownArrow, 
  Inscription, Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ValidatedRequiredField } from '@/components/elements/ValidatedRequiredField';
import { TOCSidebarProps } from '@/modules/core/components/TOCSidebar/TOCSidebar.types';
import { useDetectActiveTOCModule } from '@/modules/core/hooks/useDetectActiveTOCModule';
import colors from '@/styles/colors';

const StyledCollapseAll = styled(Box)`
  box-shadow: none;
`;

const StyledCollapseIconWrap = styled(Box)<{ expand: boolean }>`
  box-shadow: none;
  font-size: 21px;
  transform: rotate(${({ expand }) => (expand ? '0' : '-90')}deg);
`;

export interface RequiredFieldItem {
  anchorId: string;
  title: string;
  isValid: boolean;
  validate: boolean;
  isValidForActivation?: boolean;
}

export interface RequiredFieldPropertie {
  title?: string;
  groups: {
    description?: string;
    fields: RequiredFieldItem[];
    firstLineFields?: number;
  }[];
}

const divideToRows = (fields: RequiredFieldItem[], firstLineFields: number) => ([
  fields.slice(0, firstLineFields),
  ...(fields.length - firstLineFields
    ? [fields.slice(-(fields.length - firstLineFields))]
    : []),
]);

interface Props {
  requiredFieldProperties: RequiredFieldPropertie[],
  tocConfig: TOCSidebarProps['stepsMap'];
}
type RequireElemFieldProps = RequiredFieldPropertie
& Pick<Props, 'tocConfig'>
& {
  expand: boolean,
  setExpand: (state: boolean) => void,
};

const RequireElemField: React.FC<RequireElemFieldProps> = ({
  title,
  groups,
  tocConfig,
  expand,
  setExpand,
}) => {
  const status = useMemo(() => (
    groups.reduce((stat, group) => ({
      passed: stat.passed + group.fields.filter((field) => field.isValid).length,
      total: stat.total + group.fields.length,
    }), { passed: 0, total: 0 } as { passed: number, total: number })
  ), [groups]);
  const { scrollToSection } = useDetectActiveTOCModule(tocConfig);

  return (
    <>
      {title && (
        <Box align="center" direction="row" style={{ boxShadow: 'none' }} onClick={() => setExpand(!expand)}>
          <StyledCollapseIconWrap expand={expand} margin={{ right: 'spacings12' }}>
            
            <FilledDownArrow  size="18px" style={{transform: expand ?'rotate(0deg)' : 'rotate(270deg)'}} color="iconPrimary" />
          </StyledCollapseIconWrap>
          <Inscription
            lineHeight="20px"
            weight={700}
            margin={{left: 'xxs'}}
            color="textBody"
          >
            {title} ({status.passed}/{status.total})
          </Inscription>
        </Box>
      )}

      {
        expand
          ? groups.map(({ description, fields, firstLineFields = 3 }) => (
            <>
              {description && (
                <Inscription
                  lineHeight="18px"
                  color="textSecondary"
                  size="small"
                  margin={{ top: 'spacing8' }}
                  weight={500}
                >
                  {description}
                </Inscription>
              )}

              {fields && fields.length > 0 && (
                <Box margin={{ top: 'spacing8' }}>
                  {divideToRows(fields, firstLineFields).map((fieldsOfLine) => (
                    <Box key={JSON.stringify(fieldsOfLine)} direction="row" margin={{ top: 'spacing4' }} wrap>
                      {fieldsOfLine.map((field) => (
                        <ValidatedRequiredField
                          key={field.title}
                          valid={field.isValid}
                          submitted={field.validate}
                        >
                          <Inscription
                            cursor="pointer"
                            onClick={() => scrollToSection({ module: field.anchorId })}
                            {...(field.validate ? { color: field.isValid ? 'success' : 'danger' } : {})}
                          >
                            {field.title}
                          </Inscription>
                        </ValidatedRequiredField>
                      ))}
                    </Box>
                  ))}
                </Box>
              )}
            </>
          ))
          : null
      }
    </>
  );
};

const BoxOfRequiredFields: React.FC<Props> = ({
  requiredFieldProperties,
  tocConfig,
}) => {
  const [expandMap, setExpandMap] = useState(
    requiredFieldProperties.reduce(
      (map, property) => ({
        ...map,
        [`${property.title}`]: true,
      }),
      {} as { [key: string]: boolean },
    ),
  );
  const expandAll = useMemo(
    () => Object.values(expandMap).every((expand) => expand),
    [expandMap],
  );
  const setExpandAll = useCallback((expand: boolean) => {
    setExpandMap(
      (prevState) => Object
        .keys(prevState)
        .reduce(
          (map, key) => ({
            ...map,
            [key]: expand,
          }),
          {} as { [key: string]: boolean },
        ),
    );
  }, []);

  useEffect(() => {
    const newExpandMapItem = requiredFieldProperties.reduce(
      (map, property) => {
        if (!expandMap[`${property.title}`]) {
          return {
            ...map,
            [`${property.title}`]: true,
          };
        }
        return {
          ...expandMap,
          ...map,
        };
      },
      {} as { [key: string]: boolean },
    );
    setExpandMap(newExpandMapItem);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFieldProperties]);

  return (
    <Box>
      <Box
        margin={{ top: 'spacing24' }}
        direction="row"
        justify="center"
        border={{ color: 'border1', side: 'top', size: 'small' }}
      >
        <StyledCollapseAll
          margin={{ top: '-11px' }}
          data-testid="BoxOfRequiredFields_expand-button"
          onClick={() => setExpandAll(!expandAll)}
          background="canvas"
          pad={{ horizontal: 'spacing8' }}
        >
          <Text weight={500} color={colors.textSecondary} size="small">
            {expandAll ? 'Collapse all' : 'Expand all'}
          </Text>
        </StyledCollapseAll>
      </Box>
      {
        requiredFieldProperties.map((requiredFieldPropertie) => (
          <Box
            pad={{ top: 'spacing24' }}
            key={requiredFieldPropertie.title}
            // border={{ color: 'border1', side: 'top' }}
          >
            <RequireElemField
              {...requiredFieldPropertie}
              tocConfig={tocConfig}
              expand={expandMap[`${requiredFieldPropertie.title}`]}
              setExpand={(state) => setExpandMap((prevState) => ({
                ...prevState,
                [`${requiredFieldPropertie.title}`]: state,
              }))}
            />
          </Box>
        ))
      }
    </Box>
  );
};

export default BoxOfRequiredFields;
