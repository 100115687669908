import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { MAX_LENGTH_TEXT } from '@/common/constants';
import { useFieldsWithDefaultValues } from '@/utils';

import { useCustomReportsStore } from '../stores';

interface Params {
  isEditMode?: boolean;

  customReportingField1Name?: string;
  customReportingField2Name?: string;
  customReportingField3Name?: string;
  customReportingField4Name?: string;
  customReportingField5Name?: string;

  isCustomReportingField1Enabled?: boolean;
  isCustomReportingField2Enabled?: boolean;
  isCustomReportingField3Enabled?: boolean;
  isCustomReportingField4Enabled?: boolean;
  isCustomReportingField5Enabled?: boolean;
}
const EMPTY_PLACEHOLDER = '—';
const MAX_FIELD_LENGTH = 40;
const CUSTOM_FIELD_VALIDATOR = VALIDATORS.STRING.max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH));
export default ({
  isEditMode,
  customReportingField1Name,
  customReportingField2Name,
  customReportingField3Name,
  customReportingField4Name,
  customReportingField5Name,
  isCustomReportingField1Enabled,
  isCustomReportingField2Enabled,
  isCustomReportingField3Enabled,
  isCustomReportingField4Enabled,
  isCustomReportingField5Enabled,
}: Params) => {
  const defaultFields = useCustomReportsStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(
    () => [
      ...(isCustomReportingField1Enabled
        ? ([
          {
            name: 'customReportField1',
            type: FieldTypes.Text,
            parentValue: true,
            label: customReportingField1Name || 'Custom reporting field 1',

            placeholder: isEditMode ? 'Custom reporting field 1' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ] as Field[])
        : []),
      ...(isCustomReportingField2Enabled
        ? ([
          {
            name: 'customReportField2',
            type: FieldTypes.Text,
            parentValue: true,
            label: customReportingField2Name || 'Custom reporting field 2',

            placeholder: isEditMode ? 'Custom reporting field 2' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ] as Field[])
        : []),
      ...(isCustomReportingField3Enabled
        ? ([
          {
            name: 'customReportField3',
            type: FieldTypes.Text,
            parentValue: true,
            label: customReportingField3Name || 'Custom reporting field 3',

            placeholder: isEditMode ? 'Custom reporting field 3' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ] as Field[])
        : []),
      ...(isCustomReportingField4Enabled
        ? ([
          {
            name: 'customReportField4',
            type: FieldTypes.Text,
            parentValue: true,
            label: customReportingField4Name || 'Custom reporting field 4',

            placeholder: isEditMode ? 'Custom reporting field 4' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ] as Field[])
        : []),
      ...(isCustomReportingField5Enabled
        ? ([
          {
            name: 'customReportField5',
            type: FieldTypes.Text,
            parentValue: true,
            label: customReportingField5Name || 'Custom reporting field 5',

            placeholder: isEditMode ? 'Custom reporting field 5' : EMPTY_PLACEHOLDER,
            disabled: !isEditMode,
            validator: CUSTOM_FIELD_VALIDATOR,
          },
        ] as Field[])
        : []),
    ],
    [
      isCustomReportingField1Enabled,
      customReportingField1Name,
      isEditMode,
      isCustomReportingField2Enabled,
      customReportingField2Name,
      isCustomReportingField3Enabled,
      customReportingField3Name,
      isCustomReportingField4Enabled,
      customReportingField4Name,
      isCustomReportingField5Enabled,
      customReportingField5Name,
    ]
  );
  const formattedFields = useFieldsWithDefaultValues(fields, defaultFields);

  return formattedFields;
};
