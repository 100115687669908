import { useMemo } from 'react';

import { State } from '@/common/types';
import { useStatesQuery } from '@/modules/core/hooks';
import { AddressesDto } from  '@/modules/employer/types/CreateEmployer.dto.ts';
import { getFullAddress } from '@/utils/modifiers';

const DEFAULT_COUNTRY_NAME = 'US';

export const useGetOrgFullAddress = (address?: AddressesDto, isExpressSetupMode?: boolean) => {
  const { states } = useStatesQuery();
  const modifiedAddress = useMemo(() => {
    return {
      city: address?.city,
      line1: address?.line1,
      line2: address?.line2,
      zipcode: address?.zipcode,
      state: states?.find((state: State) => state.code === address?.state),
      country: {
        name: DEFAULT_COUNTRY_NAME,
        alpha2: '',
        alpha3: '',
      },
    };
  }, [address, states]);
  
  const fullAddress = useMemo(() => getFullAddress(modifiedAddress, isExpressSetupMode), [isExpressSetupMode, modifiedAddress]);
  return fullAddress;
};
