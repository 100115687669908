import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { ALL_OPTION } from '@/common/constants';
import { Option } from '@/common/types';
import { PlanGroupedListResponse } from '@/modules/plan/plan.types';

interface PlanYearInfoDto {
  id: number;
  name: string;
  valid_from: string,
  valid_to: string,
}

export interface PlanInfoDto {
  id: number;
  plan_year: PlanYearInfoDto;
  name: string;
  name_state: string;
  account_type: string;
  account_type_state: string;
  plan_status: string;
  organization_id: number;
  deletable: boolean;
}

const GETORG_PLANS_QUERY_KEY = 'useGetOrganizationPlansQueryKey';

export const useGetOrganizationPlansQuery = (orgId?: string, planStatus?: string) => {
  const params = {
    organization_id: orgId,
    plan_status: planStatus,
    page: 0,
    size: 100,
  };
  const {
    data, isSuccess, refetch, isFetching, isLoading,
  } = useQuery(
    [GETORG_PLANS_QUERY_KEY, orgId, planStatus],
    orgId
      ? () => api.get<PlanGroupedListResponse>(PATHS.PLANS,
        { params })
      : () => null,
  );

  const formattedData = useMemo(() => {
    const list = data?.data || {};
    const mergedArray = Object.values(list).flatMap((array) => array);
    return mergedArray.map((item) => ({
      id: `${item.id}`,
      planYear: {
        id: `${item.plan_year.id}`,
        name: item.plan_year?.name,
        validFrom: item.plan_year?.valid_from,
        validTo: item.plan_year?.valid_to,
      },
      name: item?.name,
      nameState: item?.name_state,
      accountType: item?.account_type,
      accountTypeState: item?.account_type_state,
      planStatus: item?.plan_status,
      organization_id: `${item.organization_id}`,
      deletable: item?.deletable,
      planCode: item?.plan_code,
    }));
  }, [data]);
  const actualOptions = useMemo<Option[]>(() => {
    const options = formattedData?.map((item) => ({
      key: item.planCode,
      value: item.planCode,
      title: item.planCode,
    }));

    return [ALL_OPTION, ...options];
  }, [formattedData]);
  return ({
    isFetching,
    options: actualOptions,
    isSuccess,
    isLoading,
    data: data?.data,
    formattedData,
    refetch,
  });
};
