import React from 'react';

import { Lockbox } from '@/modules/employer/components/Lockbox/lockbox.types';
import LockboxInfoContainer from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxInfoContainer';
import { useLockboxRecordFields } from '@/modules/employer/components/Lockbox/LockboxDetails/hooks';
import useGetVendorsQuery from '@/modules/employer/components/Lockbox/queries/useGetVendors.query';

import LockboxForm from './LockboxForm';

interface Props {
  lockbox?: Lockbox;
}

const LockboxRecordForm: React.FC<Props> = ({ lockbox }) => {
  const { vendors } = useGetVendorsQuery();
  const { lockboxRecordFields } = useLockboxRecordFields({ lockbox, vendors });

  return (
    <LockboxInfoContainer data-testId="lockbox-record-form">
      <LockboxForm fields={lockboxRecordFields} />
    </LockboxInfoContainer>
  );
};

export default LockboxRecordForm;
