import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PlanResponse } from '@/modules/plan/plan.types';
// import { useGlobalLoaderStore } from '@/components/elements/FullLoader/store/useGlobalLoader.store';
// import { PLAN_LIST_QUERY_NAME } from '../../PlansList/queries/usePlansList.query';

interface Data {
  dto: Partial<PlanResponse>;
  successCallback?: () => void
}

export const useUpdatePlanByIdQuery = (id: string,
  errorCallback?: (error: unknown) => void) => {
  // const { removeLoading } = useGlobalLoaderStore();
  const mutation = useMutation(
    ({ dto, successCallback }: Data) => api
      .put<PlanResponse>(PATHS.PLAN_BY_ID(id), dto)
      .then((data) => {
        if (successCallback) {
          successCallback();
        }
        return data;
      }),
    {
      onError: (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      },

    },
  );
  return mutation;
};
export default useUpdatePlanByIdQuery;
