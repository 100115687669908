import React, { CSSProperties } from 'react';
import { CardTypesEnum } from '@common-fe/common-fe';

import { Option } from '@/common/types';
import { PaymentType } from '@/modules/transaction/transaction.types';
export const LAST_4_DIGIT_KEY = 'last4Digits';
export enum ReportProgram {
  SCHEDULED = 'SCHEDULED',
  KPI = 'KPI',
}

export enum ReportTypes {
  Card = 'Card',
  Invoice = 'Invoice',
  Enrollment = 'Enrollment',
  ParticipantAccountActivity = 'ParticipantAccountActivity',
  Claims = 'Claims',
  CIP = 'CIP',
  Replenishment = 'Replenishment',
  Contribution = 'Contribution',
  Prefund = 'Prefund',
}

export enum GroupByFilter {
  OrgField = 'ORG_FIELD',
  DateField = 'DATE_FIELD',
  Partner = 'PARTNER',
  Employer = 'EMPLOYER',
  DateOnly = 'DATE',
  PartnerAndEmployer = 'PARTNER_EMPLOYER',
  DatePartner = 'DATE_PARTNER',
  DateEmployer = 'DATE_EMPLOYER',
  DatePartnerAndEmployer = 'DATE_PARTNER_EMPLOYER',
}

export enum SortByFilter {
  OrgAsc = 'Org_ASC',
  OrgDesc = 'Org_DESC',
  DateAsc = 'Date_ASC',
  DateDesc = 'Date_DESC',
}

export enum SortByKeys {
  orgSortDirection = 'Partner/Employer',
  dateSortDirection = 'Date',
}

// interface will be changes after API integration
export interface ReportItemData {
  id: string;
  title: string;
  value?: number | string;
  prefix?: boolean;
  isTitle?: boolean;
}

export interface ReportTableItem {
  key: string;
  value: string | number;
  isHeader?: boolean;
  className?: string;
  style?: React.CSSProperties;
  wrapStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  flex?: number;
}

export interface ReportTableRowItem {
  id: string | number;
  fields: ReportTableItem[];
}

export interface ReportItemTableData {
  header: ReportTableItem[];
  rows: ReportTableRowItem[];
  wrapStyle?: CSSProperties;
}

export enum ReportItemNestedDataRowClassNames {
  NO_PAD = 'no-pad',
  REGULAR_HOVER = 'regular-hover',
  TOTAL_HOVER = 'total-hover',
}

export interface ReportItemNestedDataRow {
  id: string;
  key?: string;
  fields: ReportTableItem[];
  style?: CSSProperties;
  lastRow?: boolean;
  classNames?: ReportItemNestedDataRowClassNames[];
}

export interface ReportItemNestedDataGroup {
  title?: string;
  id?: string;
  key?: string;
  rows?: ReportItemNestedDataRow[];
  children?: ReportItemNestedDataGroup[];
}

export interface ReportNestedItemGroup {
  title?: string;
  id?: string;
  key?: string;
  data?: ReportItemData[];
  tableData?: ReportItemTableData;
  wrapStyle?: CSSProperties;
  hierarchy?: boolean;
  children?: ReportNestedItemGroup[];
}

export interface ReportItemNestedTableData {
  groups: ReportItemNestedDataGroup[];
  wrapStyle?: CSSProperties;
}

export interface ReportItemNestedData {
  groups: ReportNestedItemGroup[];
}

export interface Report {
  title: string;
  type: string;
  isFullWidth?: boolean;
  showHideButtonCenter?: boolean;
  reportType?: ReportTypes;
  data?: ReportItemData[];
  nestedData?: ReportItemNestedData;
  tablesData?: ReportItemNestedData;
  tableData?: ReportItemTableData;
  nestedTableData?: ReportItemNestedTableData;
  nestedTableDataHeader?: ReportTableItem[];
  generateReportURL: string;
  id: string;
  startReportDate?: string;
  endReportDate?: string;
  isWithFilter?: boolean;
  isLoading?: boolean;
  isSubLoading?: boolean;
  isEmpty?: boolean;
  currentPage?: number;
  totalPages?: number;
  setCurrentPage?: (page: number) => void;
  setIsCurrentLoading?: (isLoading: boolean) => void;
}

export interface ReplenishmentInvoiceReportIndicator {
  employer_external_identifier: string;
  invoice_date: string;
  invoice_id: number;
  partner_external_identifier: string;
  total_amount_due: number;
  invoice_close_date?: string;
  employer_name?: string;
  partner_name?: string;
}

export interface CardsReportIndicator {
  active_cards_share: number;
  average_tranx: number;
  dependent_active: number;
  dependent_active_cards_share: number;
  dependent_average_tranx: number;
  dependent_issued: number;
  primary_active: number;
  primary_active_cards_share: number;
  primary_average_tranx: number;
  primary_issued: number;
  total_active: number;
  total_issued: number;
}

export interface AccountActivityReportIndicator {
  number_of_employees: number;
  avg_balance: number;
  avg_claim_spend: number;
  avg_contributions: number;
}

export interface AccountActivityReport {
  [key: string]: AccountActivityReportIndicator;
}
export interface AccountActivityReportDto {
  account_type_summary: AccountActivityReport,
}

export interface CIPReportResponse {
  total_processed: number;
  total_passed: number;
  total_failed: number;
  passed_share: number;
  total_additional_verification_required: number;
  total_pending: number;
  total_waiting_on_response: number;
  end_report_date: string;
  start_report_date: string;
}

export interface ClaimReimbursementActivityReport {
  payment_type: PaymentType,
  plan_type: CardTypesEnum,
  count: number;
}
export interface ClaimReimbursementActivityReportDto {
  claim_reimbursement_indicators: ClaimReimbursementActivityReport[],
  total_reimbursement: number;
  total_card_swipe: number;
  total_bill_pay: number;
  end_report_date: string;
  start_report_date: string;
}

export interface ReplenishmentInvoiceReportGroup {
  group_name: string;
  indicators: (ReplenishmentInvoiceReportGroup | ReplenishmentInvoiceReportIndicator)[];
  total_amount?: number;
}

export interface CardsReportGroup {
  group_name: string;
  indicators: (CardsReportGroup | CardsReportIndicator)[];
}

export interface AccountActivityReportGroup {
  group_name: string;
  indicators: (AccountActivityReportGroup | AccountActivityReportDto)[];
}
export interface ClaimReimbursementActivityReportGroup {
  group_name: string;
  indicators: (ClaimReimbursementActivityReportGroup | ClaimReimbursementActivityReportDto)[];
}

interface GroupReportResponse {
  end_report_date: string;
  start_report_date: string;
}

interface GroupReportResponseWithPagination extends GroupReportResponse {
  page_number: number;
  page_size: number;
  total_pages: number;
}

export interface GroupInvoiceReportResponse extends GroupReportResponseWithPagination {
  groups: ReplenishmentInvoiceReportGroup[];
}

export interface GroupCardReportResponse extends GroupReportResponseWithPagination {
  groups: CardsReportGroup[];
}

export interface GroupAccountActivityReportResponse extends GroupReportResponseWithPagination {
  groups: AccountActivityReportGroup[];
}
export interface GroupClaimReimbursementActivityReportResponse
  extends GroupReportResponseWithPagination {
  groups: ClaimReimbursementActivityReportGroup[];
}

export enum InvoiceReportTableHeaderEnum {
  INVOICE_DATE = 'Invoice close date',
  INVOICE_ID = 'Invoice Id',
  TOTAL_AMOUNT_DUE = 'Total Amount Due',
}

export enum PlanYearReportFilterItems {
  NULL = 'NULL',
  PRIOR = 'PRIOR',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
}

export const PLAN_YEAR_FILTER_OPTIONS: Option[] = [
  {
    key: PlanYearReportFilterItems.NULL,
    value: 'All',
    title: 'All',
  },
  {
    key: PlanYearReportFilterItems.PRIOR,
    value: PlanYearReportFilterItems.PRIOR,
    title: 'Prior',
  },
  {
    key: PlanYearReportFilterItems.CURRENT,
    value: PlanYearReportFilterItems.CURRENT,
    title: 'Current',
  },
  {
    key: PlanYearReportFilterItems.FUTURE,
    value: PlanYearReportFilterItems.FUTURE,
    title: 'Future',
  },
];

export type ReportMapType = { [type: string]: Report[] };

// fields of <ReportResponse> interface will be changes after API integration
export interface ReportResponse {
  title: string;
  type: string;
  data: ReportItemData[];
  generateReportURL?: string;
  id: string;
}
