import { useMutation, useQueryClient } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import REGEXP from '@/common/regexp';
import { PaymentMethod, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';
import { getEncryptionByPubKey } from '@/utils/hooks/useEncryptData';

import { DebitCardPayload } from './DebitCart/hooks';
import { BankInfo } from './DirectDeposit/BankForm/useBankFields';
import { PayPalInfo } from './PayPal/hooks';
import { VenmoInfo } from './Venmo/hooks';

const DEFAULT_COUNTRY_ID = 1;

interface DebitCardUpdatePayload extends DebitCardPayload {
  cardToken: string;
}

export interface PaymentMethodUpdateRequestData {
  paymentMethodId: number | string;
  paymentType: PaymentMethodType;
  payPalData?: PayPalInfo;
  venmoData?: VenmoInfo;
  debitCard?: DebitCardUpdatePayload,
  address?: PaymentMethod['address'],
  bankData?: BankInfo;
  fiservTokenId?: string;
}

const getCurrentPaymentData = async (value: PaymentMethodUpdateRequestData) => {
  switch (value.paymentType) {
  case PaymentMethodType.PAYPAL:
    return {
      ...value?.payPalData?.email
        ? {
          email: value?.payPalData?.email,
        }
        : {},
      ...value?.payPalData?.phoneNumber
        ? {
          phone_number: `+${value?.payPalData?.phoneNumber?.replace(REGEXP.ALL_NON_NUMERIC, '')}`,
        }
        : {},
    };
  case PaymentMethodType.VENMO:
    return {
      phone_number: `+${value?.venmoData?.phoneNumber?.replace(REGEXP.ALL_NON_NUMERIC, '')}`,
    };
  case PaymentMethodType.DEBIT:
    return {
      card_holder_name: value.debitCard?.nameOnCard,
      card_expiration_date: value.debitCard?.expirationDate,
      card_type: value.debitCard?.cardType,
      card_last4: value.debitCard?.cardNumber
        ?.slice(value.debitCard?.cardNumber.length - 4, value.debitCard?.cardNumber.length),
      card_token: value.debitCard?.cardToken,
    };
  case PaymentMethodType.DIRECT_DEPOSIT: {
    const encryptData = await getEncryptionByPubKey({
      account_number: value.bankData?.accountNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
    });
    return {
      account_description: value.bankData?.associatedName,
      account_routing_transit_number: value.bankData?.routingNumber?.replace(REGEXP.SPACE_SYMBOL, ''),
      account_category: value.bankData?.accountType,
      ...encryptData,
    };
  }
  case PaymentMethodType.CHECK:
    return {
      ...value?.address ? {
        address: {
          city: value?.address?.city,
          line1: value?.address?.line1,
          line2: value?.address?.line2,
          line3: value?.address?.line3,
          line4: value?.address?.line4,
          state: value?.address?.state?.code,
          country_id: value?.address?.countryId || DEFAULT_COUNTRY_ID,
          zipcode: value?.address?.zipcode,
        },
      } : {},
    };

  default:
    return {};
  }
};

export const useSetDefaultPaymentMethodQuery = () => {
  const { mutateAsync } = useMutation(
    (paymentMethodId: number | string) => api.put(PATHS.UPDATE_DEFAULT_PAYMENT_METHODS(paymentMethodId)),
  );

  return {
    updateDefaultPaymentMethod: mutateAsync,
  };
};

export const useUpdatePaymentMethodQuery = (onSuccess?: (paymentMethodId?: string) => void) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (paymentMethod: PaymentMethodUpdateRequestData) => api.patch(
      PATHS.UPDATE_DEFAULT_PAYMENT_METHODS(paymentMethod.paymentMethodId),
      await getCurrentPaymentData(paymentMethod),
      {
        headers: {
          'Fiserv-Access-Token': paymentMethod.fiservTokenId,
        },
      },
    ),
    {
      onSuccess: (value) => {
        const paymentMethodId = value?.data?.id ? toString(value?.data?.id) : undefined;
        queryClient.invalidateQueries(PATHS.PAYMENT_METHODS, { refetchInactive: true });
        if (onSuccess) {
          onSuccess(paymentMethodId);
        }
      },
    },
  );

  return {
    updatePaymentMethod: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export const useUpdateDirectDepositMethodQuery = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (paymentMethod: PaymentMethodUpdateRequestData) => api.put(
      PATHS.BANC_ACCOUNT_BY_ID(paymentMethod?.paymentMethodId),
      await getCurrentPaymentData(paymentMethod),
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.PAYMENT_METHODS, { refetchInactive: true });
      },
    },
  );

  return {
    updateDirectDeposit: mutateAsync,
    isLoading,
    isSuccess,
  };
};
