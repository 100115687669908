import React, {
  useCallback, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Field,
  Preloader,
  WarnModal,
} from '@common-fe/common-fe';
import * as yup from 'yup';

import AppButton from '@/components/controls/AppButton';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { EditEmailNotificationForm } from './EditEmailNotificationForm';
import { EditEmailTemplatePreview } from './EditEmailTemplatePreview';
import { useGetAvailableEmailNotificationTemplatesQuery, useUpdateEmailNotificationTemplateQuery } from './queries';
import { useEditEmailNotificationModalStore } from './store';

interface FormValues {
  notificationName?: string;
  templateName?: string;
  category?: string;
  destination?: string;
}
  
interface Props {
  onCancel?: (value: boolean) => void;
}
    
const EditEmailNotificationModalForm: React.FC<Props> = ({
  onCancel,
}) => {
  const { id } = useParams<{ id: string }>();
  const [isSubmit, setSubmit] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [valuesIsFilled, setValuesIsFilled] = useState(false);
  
  const { handleAddPermanentSnackbar } = useSnackbar();

  const handleChangeVisibility = useEditEmailNotificationModalStore((state) => state.handleChangeVisibility);
  const emailNotificationTemplate = useEditEmailNotificationModalStore((state) => state.emailNotificationTemplate);
  const additionalAction = useEditEmailNotificationModalStore((state) => state.additionalAction);
  const handleReset = useEditEmailNotificationModalStore((state) => state.handleReset);

  const { formattedOptions, isLoading } = useGetAvailableEmailNotificationTemplatesQuery(
    emailNotificationTemplate?.notificationType,
    emailNotificationTemplate?.destination,
  );

  const { updateEmailNotificationTemplate, isLoading: updateLoading} = useUpdateEmailNotificationTemplateQuery(id);
    
  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled?: (val: boolean) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((prev: any) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      if (setFieldsAreFilled) setFieldsAreFilled(true);
    } catch {
      if (setFieldsAreFilled) setFieldsAreFilled(false);
    }
  }, [setFormValues]);
    
  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    
    if (!valuesIsFilled || !emailNotificationTemplate?.propertyName) {
      return;
    }

    try {
      const payload = {
        [emailNotificationTemplate.propertyName]: formValues.templateName as string,
      };

      await updateEmailNotificationTemplate(payload);
      handleChangeVisibility(false);
      additionalAction?.();
      handleReset();
      handleAddPermanentSnackbar({
        text: 'Email notification was successfully saved',
        closeIcon: true,
        state: SnackbarState.positive,
      });
    } catch {
      setIsServerError(true);
    }
  }, [
    handleChangeVisibility,
    valuesIsFilled,
    formValues,
    emailNotificationTemplate,
    handleAddPermanentSnackbar,
    updateEmailNotificationTemplate,
    additionalAction,
    handleReset,
  ]);
    
  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);
    
  return (
    <>
      <Box data-testid="EditEmailNotificationModalForm_container">
        <Box direction="column">
          <Box
            round="container1Round"
            pad="spacing24"
            background="module"
            gap='spacing24'
          >
            {isLoading ? (
              <Preloader />
            ) : (
              <Box gap="spacing24">
                <EditEmailNotificationForm
                  handleChangeValues={changeValues}
                  isSubmit={isSubmit}
                  setSetup={setValuesIsFilled}
                  templateValues={emailNotificationTemplate}
                  options={formattedOptions}
                />

                <EditEmailTemplatePreview templateId={emailNotificationTemplate?.id} />
              </Box>
            )}
           
          </Box>
          <Box
            direction="row"
            fill="horizontal"
            margin={{ top: 'spacing24' }}
            align="end"
            justify="end"
            width="medium"
            gap="xs"
          >
            <Box direction="row" gap="xs">
              <AppButton
                testId="EditEmailNotificationModalForm_cancel"
                buttonType="secondary"
                width="control"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </AppButton>
              <AppButton
                testId="EditEmailNotificationModalForm_submit"
                type="button"
                disabled={updateLoading}
                onClick={handleSubmit}
                width="control"
              >
                {updateLoading
                  ? (<Preloader />)
                  : 'Save'}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <WarnModal
        testId="employer-termination-server-error-modal"
        visible={isServerError}
        onSetVisible={setIsServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Employer termination with the latest records.
          Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
    </>
  );
};
    
export default EditEmailNotificationModalForm;
    