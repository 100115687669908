import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ServicePaymentPayloadDto } from '../../AddServicePaymentModal/queries/useCreateServicePayment.query';

export interface PaymentRule {
  planId?: string;
  planName?: string;
  maxAmount?: number;
  percentage?: number;
}

export interface ServiceConfig {
  id: string;
  serviceId: string;
  serviceName?: string;
  processingTierId: string;
  healthPlanId: string;
  description?: string;
  claimValue?: number;
  isAppliedDeductible?: boolean;
  paymentType: string;
  paymentRules?: PaymentRule[];
}
export const QUERY_KEY = 'GET_SERVICE_CONFIGS';

export default () => {
  const { params: { id } } = useRouteMatch<{ id: string }>();

  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY, id],
    () => api.get<ServicePaymentPayloadDto[]>(
      PATHS.SERVICE_CONFIGS, {
        params: {
          health_plan_id: id,
        },
      },
    ),
  );

  const formatedData = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => {
      const formatedItem: ServiceConfig = {
        id: toString(item.id),
        serviceId: toString(item.service?.id),
        serviceName: item.service?.name,
        processingTierId: toString(item.processing_tier_id),
        healthPlanId: toString(item.health_plan_id),
        description: item.description,
        claimValue: item.claim_value,
        isAppliedDeductible: item.is_applied_deductible,
        paymentType: item.payment_type,
        paymentRules: item?.payment_rules?.map((rule) => ({
          planId: toString(rule.plan?.id),
          planName: rule.plan?.name,
          maxAmount: rule.max_amount,
          percentage: rule.percentage,
        })),
      };
      return formatedItem;
    });
  }, [data]);

  return {
    isLoading,
    data: formatedData,
  };
};
