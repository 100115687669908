import { useMemo } from 'react';

import { Roles } from '@/common/constants';
import { useAuthStore } from '@/modules/user/stores';

const useGetPropertiesAccessibility = () => {
  const { user } = useAuthStore();

  return useMemo(() => {
    if (user?.role === Roles.admin1
      || user?.role === Roles.finOps
      || user?.role === Roles.agent
      || user?.role === Roles.claimsProcessor1
      || user?.role === Roles.claimsProcessorReview
      || user?.role === Roles.claimsProcessor2
      || user?.role === Roles.claimsProcessorEdit) {
      return {
        isCardPhoneNumberHidden: true,
        isCardURLHidden: true,
        isCardEmployerNameHidden: true,
      };
    }

    return {};
  }, [user]);
};

export default useGetPropertiesAccessibility;
