import { useCallback } from 'react';

import useRecipientsStore from '../stores/useRecipients.store';

const useDisableAccount = () => {
  const { recipients, setRecipients } = useRecipientsStore();

  return useCallback((employeeId?: string, planId?: string) => {
    setRecipients(recipients?.map((item) => {
      if (item?.employeeId === employeeId) {
        return {
          ...item,
          contributionAccounts: item?.contributionAccounts?.map((acc) => {
            if (acc?.planId === planId) {
              return {
                ...acc,
                isDisabled: !acc?.isDisabled,
              };
            }
            return acc;
          })
        };
      }
      return item;
    }));
  }, [recipients, setRecipients]);
};

export default useDisableAccount;
