import React, { useEffect } from 'react';

import {
  useAccountActivityReportFilterStore,
  useCardReportFilterStore,
  useClaimReimbursementActivityReportFilterStore,
  useContributionInvoiceReportFilterStore,
  useReplenishmentInvoiceReportFilterStore,
} from '@/modules/employer/components/Reports/components/ReportSection/ReportFilterPopup.tsx/store';
import { useReportFilterPopupStore } from '@/modules/employer/components/Reports/components/ReportSection/ReportFilterPopup.tsx/store/useReportFilterPopup.store';

import Card from './Card';
import Claims from './Claims';
import Enrollments from './Enrollments';
import HSA from './HSA';
import Invoice from './Invoice';

const ReportsWrap = () => {
  const replenishmentFilterClear = useReplenishmentInvoiceReportFilterStore(
    (state) => state.handleClear,
  );
  const contributionFilterClear = useContributionInvoiceReportFilterStore(
    (state) => state.handleClear,
  );
  const cardFilterClear = useCardReportFilterStore((state) => state.handleClear);
  const accountActivityFilterClear = useAccountActivityReportFilterStore(
    (state) => state.handleClear,
  );
  const claimReimbursementActivityFilterClear = useClaimReimbursementActivityReportFilterStore(
    (state) => state.handleClear,
  );
  const handleClearPopups = useReportFilterPopupStore((state) => state.handleClear);

  useEffect(() => () => {
    replenishmentFilterClear();
    contributionFilterClear();
    cardFilterClear();
    accountActivityFilterClear();
    handleClearPopups();
    claimReimbursementActivityFilterClear();
  }, [
    replenishmentFilterClear,
    contributionFilterClear,
    cardFilterClear,
    accountActivityFilterClear,
    claimReimbursementActivityFilterClear,
    handleClearPopups,
  ]);

  return (
    <>
      <Enrollments />
      <Card />
      <Claims />
      <Invoice />
      <HSA />
    </>
  );
};

export default ReportsWrap;
