import React, { useEffect, useMemo,useState } from 'react';
import {
  Box,
  // Text, WarningIcon, AppButton,
} from '@common-fe/common-fe';
import { toString } from 'lodash';
import styled from 'styled-components';

import { useUpdateEnrollmentMatrix } from '@/modules/ProcessingDefinition/EnrollmentMatrix/hooks';

// import { BOOLEAN_STRINGS } from '@/common/constants';
import { EnrollmentMatrixItem } from '../ProcessingDefinition.types';

import { useEnrollmentMatrixStore } from './stores/useEnrollmentMatrix.store';
import MatrixesListDelimiter from './MatrixesListDelimiter';
import MatrixListItem from './MatrixListItem';
import useOrderDragAndDropMatrix from './useOrderDragAndDropMatrix';

export const Wrapper = styled(Box) <{
  allowHover?: boolean;
}>`
  &:hover {
    ${({ theme, allowHover }) => allowHover && theme.shadows.hover};
    cursor: ${({ allowHover }) => allowHover && 'pointer'};
  }
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
`;

interface Props {
  isEmployer?: boolean;
  isExpired?: boolean;
  isPriorityMode?: boolean;
  searchString?: string;
  values: EnrollmentMatrixItem[];
  matrixes?: EnrollmentMatrixItem[];
  matrixesHealthPlan?: EnrollmentMatrixItem[];
  currentOrg?: string;
  perPage?: number;
  setUpdatedElement?: (value?: EnrollmentMatrixItem) => void;
  isCreateAccessible?: boolean;
}

// const MATRIX_DISMISS_BUTTON_KEY = 'MATRIX_DISMISS_BUTTON_CLICKED';
const MatrixesList: React.FC<Props> = ({
  values = [],
  matrixes = [],
  matrixesHealthPlan = [],
  isEmployer,
  isExpired,
  isPriorityMode,
  currentOrg,
  perPage,
  setUpdatedElement,
  isCreateAccessible,
}) => {
  const [newItemId, setNewItemId] = useState<string>();
  // const [isMessageHidden, setIsMessageHidden] = useState<boolean>();
  const {
    newAddedEnrollmentMatrixId,
    handleResetNewAddedEnrollment,
  } = useEnrollmentMatrixStore();
  const {
    handlePreSubmit,
    isLoading,
  } = useUpdateEnrollmentMatrix();

  useEffect(() => {
    if (!isExpired && !isLoading) {
      setNewItemId(newAddedEnrollmentMatrixId);
    }
  }, [newAddedEnrollmentMatrixId, isLoading, isExpired, setNewItemId]);

  useEffect(() => () => {
    handleResetNewAddedEnrollment();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // useEffect(() => {
  //   const dismissedValue = sessionStorage.getItem(MATRIX_DISMISS_BUTTON_KEY);
  //   if (dismissedValue === BOOLEAN_STRINGS.TRUE) {
  //     setIsMessageHidden(true);
  //   }
  // }, []);

  const {
    handleDragStart,
    handleDrop,
    handleDragEnter,
    handleDragEnd,
    handleDragOver,
    list,
    hoveredIndex,
    draggedItemId,
    startUpdating,
  } = useOrderDragAndDropMatrix(
    matrixes,
  );

  const listMatrix = useMemo(() => {
    if (isPriorityMode) return list;
    return values;
  }, [isPriorityMode, list, values]);

  return (
    <Box
      direction="column"
      data-testId="enrollemnt-matrix_wrapper"
    >
      {/* {
        isPriorityMode && !isMessageHidden && (
          <Box
            height={{ min: '96px' }}
            direction="row"
            justify="between"
            align="center"
            round="container2Round"
            background="warningContainer"
            elevation="default"
            border={{ side: 'all', size: 'small', color: 'warningBorder' }}
            margin={{ top: 's' }}
          >
            <Box
              direction="column"
              pad={{ horizontal: 'l', vertical: 's' }}
            >
              <Box direction="row" margin={{ bottom: 'xs' }}>
                <WarningIcon />
                <Text size="medium" weight="bold" margin={{ left: 'xs' }}>
                  You cannot change the priority for entities
                  that do not belong to your organization.
                </Text>
              </Box>
              <Text size="medium">Note, if you have other items created for your child organizations, they can affect the priorities setup here.
                Check them also to make sure they are correct.
              </Text>
            </Box>
            <Box width="130px" direction="row" justify="end" margin={{ right: 'l' }}>
              <AppButton onClick={() => {
                setIsMessageHidden(true);
                sessionStorage.setItem(MATRIX_DISMISS_BUTTON_KEY, 'true');
              }}
              >
                Dismiss
              </AppButton>
            </Box>
          </Box>
        )
      } */}
      {isPriorityMode && matrixesHealthPlan?.map((item) => {
        if (!item) return null;
        return (
          <>
            <Wrapper
              key={item?.id}
              margin={{ top: 'xs' }}
              round="container2Round"
              elevation="default"
            >
              <MatrixListItem
                isEmployer={isEmployer}
                key={toString(item?.id)}
                value={item}
                isExpired={isExpired}
                newItemId={newItemId}
                isPriorityMode={isPriorityMode}
                currentOrg={currentOrg}
                perPage={perPage}
                priorityFirstEl={list[0]?.priority}
                priorityLastEl={list[list?.length - 1]?.priority}
                handleSave={handlePreSubmit}
                isLoading={isLoading}
                setUpdatedElement={setUpdatedElement}
                isCreateAccessible={isCreateAccessible}
                isLinkToHealthPlan={item.isLinkToHealthPlan}
              />
            </Wrapper>
            {item.isHealthPlanDelimiter && (
              <MatrixesListDelimiter />
            )}
          </>
        );
      })}
      {listMatrix?.map((item, index) => {
        if (!item) return null;
        return (
          <Wrapper
            draggable={!startUpdating
              && isPriorityMode
              && listMatrix?.length > 1
              && (currentOrg === item?.organizationType)}
            key={item?.id}
             
            onDragStart={(event) => handleDragStart(event, item?.id)}
            onDragEnter={(event) => handleDragEnter(event, item?.id, item?.priority)}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnd={handleDragEnd}
            margin={{ top: 'xs' }}
            round="container2Round"
            elevation="default"
            border={(item?.id === draggedItemId) || (index === hoveredIndex) ? {
              side: 'all',
              size: 'large',
              color: 'accentActive',
            } : undefined}
            allowHover={isPriorityMode && (currentOrg === item?.organizationType)}
          >
            <MatrixListItem
              isEmployer={isEmployer}
              key={toString(item?.id)}
              value={item}
              isExpired={isExpired}
              newItemId={newItemId}
              isPriorityMode={isPriorityMode}
              currentOrg={currentOrg}
              perPage={perPage}
              priorityFirstEl={list[0]?.priority}
              priorityLastEl={list[list?.length - 1]?.priority}
              handleSave={handlePreSubmit}
              isLoading={isLoading}
              setUpdatedElement={setUpdatedElement}
              isCreateAccessible={isCreateAccessible}
              isLinkToHealthPlan={item.isLinkToHealthPlan}
            />
          </Wrapper>
        );
      })}
    </Box>
  );
};

export default MatrixesList;
