import React, { useCallback, useMemo } from 'react';
import { Box, Field, FieldTypes, Inscription, Text } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { NO_KEY, PermissionKey, YES_KEY } from '@/common/constants';
import { AllowedFileTypes } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import { useFieldsWithDefaultValues } from '@/utils';

import FileLocationField from '../FileLocationField';
// import ErrorReportLocationField from '../ErrorReportLocationField';
import { useStore as useFileManagerSettingsStore } from '../stores/useFileManagerSettings.store';

const REQUIRED_TEXT = 'This field is required';

const Label: React.VFC<{ title: string; desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Inscription margin={{ bottom: '2px' }} weight="bold" color="textBody">
      {title}
    </Inscription>
    <Box>
      <Inscription weight={500} color="textSecondary" size="12px" lineHeight="18px">
        {desc}
      </Inscription>
    </Box>
  </Box>
);

interface Props {
  isEditMode?: boolean;
  isSubmitted?: boolean;
  isPartnerMode?: boolean;
}

const useFileManagerFields = ({ isEditMode, isSubmitted, isPartnerMode }: Props) => {
  const isFileManagerAccessEnabledPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_FILE_MANAGER_ACCESS_ENABLED
  );

  const isFileAutoPostEnabledPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_FILE_AUTO_POST_ENABLED
  );
  const sourceState = useFileManagerSettingsStore((state) => state.sourceState);
  const isSendFiles = useFileManagerSettingsStore((state) => state.state.isSendFiles);
  const fileLocation = useFileManagerSettingsStore((state) => state.state.fileLocation);

  const setState = useFileManagerSettingsStore((state) => state.setState);
  const handleCheck = useCallback(
    (value: boolean) => {
      setState({
        isSendFiles: value,
      });
    },
    [setState]
  );
  const handleChangeFileLocation = useCallback(
    (value: string) => {
      setState({
        fileLocation: value,
      });
    },
    [setState]
  );
  // const handleChangeStore = useFileManagerSettingsStore((state) => state.setState);
  // const isSameErrorReportLocation = useFileManagerSettingsStore(
  //   (state) => state.state.isSameErrorReportLocation
  // );
  // const errorReportLocation = useFileManagerSettingsStore((state) => state.state.errorReportLocation);
  // const handleChangeIsSameFileLocation = useCallback(
  //   (value: boolean) => {
  //     handleChangeStore({
  //       isSameErrorReportLocation: value,
  //     });
  //   },
  //   [handleChangeStore]
  // );
  // const handleChangeErrorFileLocation = useCallback(
  //   (value: string) => {
  //     handleChangeStore({
  //       errorReportLocation: value,
  //     });
  //   },
  //   [handleChangeStore]
  // );
  const fields = useMemo(() => {
    return [
      {
        name: 'isFileManagerAccessEnabled',
        label: 'File manager access',
        type: FieldTypes.Checkbox,
        disabled: !isEditMode,
        subFields: [
          {
            name: 'allowedFileTypes',
            label: <Label title="Allowed file types" desc="At least one option needs to be selected" />,
            type: FieldTypes.CheckBoxGroup,
            showRequireIcon: true,
            disabled: !isEditMode,
            validator: VALIDATORS.ARRAY_REQUIRED.min(1, REQUIRED_TEXT),
            options: [
              {
                value: 'Census',
                key: AllowedFileTypes.CENSUS,
              },
              {
                value: 'Enrollment',
                key: AllowedFileTypes.ENROLLMENT,
              },
              {
                value: 'Contribution',
                key: AllowedFileTypes.CONTRIBUTION,
              },
              {
                value: 'Carrier claim',
                key: AllowedFileTypes.CARRIER,
              },
              {
                value: 'Dependent',
                key: AllowedFileTypes.DEPENDENT,
              },
            ],
            ...isFileManagerAccessEnabledPermProps,
          },
          {
            name: 'isFileAutoPostEnabled',
            label: (
              <Label
                title="Autopost"
                desc="Allows files to be processed immediately after submission. Otherwise, files must be manually approved for processing"
              />
            ),
            showRequireIcon: true,
            disabled: !isEditMode,
            type: FieldTypes.Radio,
            validator: VALIDATORS.REQUIRED_STRING,

            options: [
              {
                label: 'Yes',
                value: YES_KEY,
              },
              {
                label: 'No',
                value: NO_KEY,
              },
            ],
            ...isFileAutoPostEnabledPermProps,
          },

          {
            name: 'fileLocationCheckbox',
            isDisabled: !isPartnerMode || !isEditMode,
            label: (
              <Label
                title="Send Files (SFTP)"
                desc="Delivery of census, enrollment, contribution, and dependent files. Error reports folder will be created within the same location"
              />
            ),
            value: (
              <FileLocationField
                onChangeCheck={handleCheck}
                onChange={handleChangeFileLocation}
                checked={isSendFiles}
                isViewMode={!isEditMode}
                disabled={!isPartnerMode || !isEditMode}
                value={fileLocation}
                isError={isSubmitted}
              />
            ),
            // showRequireIcon: true,
            disabled: !isEditMode,
            type: FieldTypes.Node,
            placeholder: 'Send Files (SFTP)',

            // subFields: [
            //   {
            //     name: 'fileLocationValue',
            //     label: ' ',
            //     parentValue: true,
            //     // showRequireIcon: true,
            //     disabled: !isEditMode,
            //     type: FieldTypes.Text,
            //     fieldControlStyle: {
            //       flexDirection: 'column-reverse',
            //     },
            //     childrenNode: <FileLocationTitle />,
            //     validator: VALIDATORS.REQUIRED_STRING,
            //     placeholder: 'File location',
            //     hint: <Text size="small">Enter the exact name you gave the folder in your S3 bucket.</Text>,
            //   },
            // ],
          },

          // {
          //   name: 'isSameErrorReportLocation',
          //   label: (
          //     <Label
          //       title="Error report location"
          //       desc="Census, Enrollment, and Contributions error reports"
          //     />
          //   ),
          //   type: FieldTypes.Node,
          //   placeholder: 'File location',
          //   value: (
          //     <ErrorReportLocationField
          //       disabled={!isEditMode}
          //       onChangeCheck={handleChangeIsSameFileLocation}
          //       onChangeValue={handleChangeErrorFileLocation}
          //       checked={isSameErrorReportLocation}
          //       value={errorReportLocation || ''}
          //     />
          //   ),
          //   // value: getBoolOption(overridableProperties?.isFileAutoPostEnabled),
          // },
        ],
        ...isFileManagerAccessEnabledPermProps,
      },
    ];
  }, [
    isEditMode,
    isFileManagerAccessEnabledPermProps,
    isFileAutoPostEnabledPermProps,
    isPartnerMode,
    handleCheck,
    handleChangeFileLocation,
    isSendFiles,
    fileLocation,
    isSubmitted,
  ]);
  const formattedFields = useFieldsWithDefaultValues(fields as Field[], sourceState);
  return formattedFields;
};

export default useFileManagerFields;
