import { useMemo } from 'react';
import _ from 'lodash';

import { Option } from '@/common/types';

const DEFAULT_EMPTY_VALUE = '-';
export const useChangesList = (sourceFields: Option[], compareFields: Option[]) => {
  const difference = _.uniqBy(useMemo(
    () => _.xorWith(sourceFields, compareFields, _.isEqual),
    [sourceFields, compareFields],
  ), 'key');

  const formatedDifference = useMemo(() => difference.reduce(
    (result: Option[], item) => {
      const sourceItem = sourceFields.find((field) => field.key === item.key);
      const compareItem = compareFields.find((field) => field.key === item.key);
      return [...result, {
        ...item,
        value: compareItem?.value === false
          ? false
          : compareItem?.value || DEFAULT_EMPTY_VALUE,
        additionalValue: sourceItem?.value === false
          ? false
          : sourceItem?.value || DEFAULT_EMPTY_VALUE,
      }];
    }, [],
  ), [compareFields, difference, sourceFields]);
  return formatedDifference;
};
