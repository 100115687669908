import React, { useEffect, useMemo } from 'react';
import { Box, FlexControlledForm,Heading } from '@common-fe/common-fe';

import { useFundingModelFields } from '@/modules/employer/components/SetupEmployer/FinancialDetails/FundingModel/hooks/useFundingModelFields';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useСheckWritingsQuery from '@/modules/employer/components/SetupEmployer/queries/useCheckWritings.query';
import useElectronicFundingReplenishmentsQuery from '@/modules/employer/components/SetupEmployer/queries/useElectronicFundingReplenishments.query';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { useSaveTypeStore } from '../../stores';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const FundingModel: React.FC<Props> = ({
  onDirty,
}) => {
  const { data, isLoading } = useСheckWritingsQuery();
  const { formattedData, isLoading: isEmployerLoading } = useGetEmployer();
  const { viewMode } = useSetupEmployerMode();
  const { activeStatus, handleResetType } = useSaveTypeStore();
  const setFundingModel = useStore((state) => state.setFundingModel);
  const {
    data: electronicFundingReplenishments,
    isLoading: areREplenishmentsLoading,
  } = useElectronicFundingReplenishmentsQuery();
  const isDataLoading = useMemo(() => isLoading
    || isEmployerLoading
    || areREplenishmentsLoading,
  [isLoading, isEmployerLoading, areREplenishmentsLoading]);
  const fields = useFundingModelFields(data, electronicFundingReplenishments, formattedData);
  useEffect(() => () => {
    handleResetType();
  }, [handleResetType]);

  if (isDataLoading) return <FormLoader testId="FundingModel" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      margin={{ bottom: 'spacing24' }}
      border={{ color: 'border2', size: 'small' }}
      id="funding_model"
    >
      <Box direction="column" pad={{ top: 'spacing24' }}>
        <Box direction="row" pad={{ horizontal: 'spacing24' }}>
          <Box width="medium">
            <Heading level={3} size="small">
              Funding Model
            </Heading>
          </Box>
        </Box>
        <Box>
          <FlexControlledForm
            editMode={!viewMode && !formattedData?.isTerminated}
            showError={activeStatus}
            fields={fields}
            onChangeValues={setFundingModel}
            onDirty={onDirty}
            isThinMode
            wrapperStyle={{ border: 'none' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FundingModel;
