import {
  useCallback, useEffect,
  useState, } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import { OptionKey } from '@/common/types';

import { useAddPlanTemplatesQuery } from '../queries/useAddPlanTemplates.query';
import { PLAN_TEMPLATES_KEY,useLinkedPlanTemplatesQuery } from '../queries/useLinkedPlanTemplates.query';

export default (isVisibleModal?: boolean, onClose?: (value: boolean) =>void) => {
  const [values, setValues] = useState<OptionKey[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSubmit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { params } = useRouteMatch<{ id: string }>();

  const { formattedData, isLoading } = useLinkedPlanTemplatesQuery(
    false,
    params.id,
    isVisibleModal,
    searchValue,
  );

  const { formattedLinkedTemplates, linkedTemplateIds } = useLinkedPlanTemplatesQuery(
    true,
    params.id,
    true,
  );

  const linkPlanTemplates = useAddPlanTemplatesQuery(
    params.id,
  );

  const handleAddPlanTemplates = useCallback(async () => {
    const numberIds = _.map(values, (numStr) => parseInt(String(numStr), 10));
    if (isVisibleModal) {
      setSubmit(true);
    }
    if (numberIds.length > 0) {
      setLoading(true);
      try {
        await linkPlanTemplates({
          template_ids: [...linkedTemplateIds, ...numberIds],
        });
      } finally {
        queryClient.invalidateQueries(PLAN_TEMPLATES_KEY);
        setLoading(false);
        if (onClose) {
          onClose(true);
        }
      }
    }
  }, [isVisibleModal, values, linkPlanTemplates, onClose, queryClient, linkedTemplateIds]);

  useEffect(() => {
    if (!isVisibleModal) {
      setSubmit(false);
      setSearchValue('');
      setValues([]);
    }
  }, [isVisibleModal]);

  return {
    handleAddPlanTemplates,
    handleChangeValues: setValues,
    handleSearchValue: setSearchValue,
    values,
    searchValue,
    isSubmit,
    isLoading,
    isAddTemplateLoading: loading,
    formattedData,
    formattedLinkedTemplates,
  };
};
