import React from 'react';
import { FlatTextInput, ThinSearchIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

const Search = styled(FlatTextInput)<{ isActive: boolean }>`
  ${({ isActive, theme }) => {
    if (isActive) {
      return theme.input.focus;
    }

    return '';
  }}
`;

interface Props {
  children: string | React.ReactNode;
  isActive: boolean;
  placeholder?: string;
}

const FakeSearchInput: React.FC<Props> = ({ children, isActive, placeholder }) => (
  <Search
    testId="init_org_level_selection"
    value={children as string}
    style={{ width: '320px' }}
    placeholder={placeholder}
    rightIcon={(
      <ThinSearchIcon
        color="iconPrimary"
        style={{ background: globalTheme.colors.canvas }}
      />
    )}
    isActive={isActive}
  />
);

export default FakeSearchInput;
