import { useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { api, mock } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';

import {
  CalendarPayload,
  CalendarPlanPayload,
  ScheduledContributionFrequency,
} from '../../ScheduledContribution.types';

export const QUERY_KEY = 'GET_CONTRIBUTION_SUMMARY_CALENDARS';
interface ResponseAccountPayload {
  plan_code: string;
  recipients_count: number;
  forecasted_amount: number;
}

interface ResponsePayload {
  calendar_id: number;
  calendar_name: string;
  frequency_type: ScheduledContributionFrequency;
  next_scheduled_date?: string;
  plan_summaries: ResponseAccountPayload[];
}



interface Params {
  calendarId: string;
  startDate?: string | null;
}
export default ({ calendarId, startDate }: Params) => {
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, calendarId],
    () =>
      api.get<ResponsePayload>(PATHS.SCHEDULED_CONTRIBUTION_CALENDAR_SUMMARY(calendarId), {
        params: {
          start_date: startDate ? dayjs(startDate, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT) : undefined,
        },
      }),
    {
      retry: 0,
      enabled: !!calendarId,
    }
  );

  const formattedData = useMemo<CalendarPayload[]>(() => {
    const calendar = data?.data;
    const list = calendar ? [calendar] : [];
    return list.map((item) => {
      let totalrecipients = 0;
      const formattedAccounts = item.plan_summaries.map((plan) => {
        if (plan.recipients_count) {
          totalrecipients += plan.recipients_count;
        }

        const currentPlan: CalendarPlanPayload = {
          id: `${item.calendar_id} ${plan.plan_code}`,
          name: plan.plan_code,
          recipients: plan.recipients_count || 0,
          forecastedAmount: plan.forecasted_amount,
        };
        return currentPlan;
      });

      return {
        id: `${item.calendar_id}`,
        name: item.calendar_name,
        frequency: item.frequency_type,
        scheduledTime: dayjs(item.next_scheduled_date).format(DEFAULT_DATE_FORMAT),
        recipients: totalrecipients,
        accounts: formattedAccounts.map((item) => ({
          key: item.id,
          value: item.name,
          title: item.name,
        })),
        plans: formattedAccounts,
      };
    });
  }, [data?.data]);

  return {
    formattedData,

    data,
    isLoading,
    isSuccess,
  };
};
