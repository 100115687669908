import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface PlanNameFieldsStatePayload {

  planName: PlanFieldState;
  planType: PlanFieldState;
  planCode: PlanFieldState;
  planTemplate: PlanFieldState;
}

export const DEFAULT_VALUES: PlanNameFieldsStatePayload = {
  planName: PlanFieldState.Modifiable,
  planType: PlanFieldState.Modifiable,
  planCode: PlanFieldState.Modifiable,
  planTemplate: PlanFieldState.Locked,
};

export const useStore = baseStoreCreator<PlanNameFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
