import { useMemo } from 'react';
import {
  Field,
  FieldTypes,   validateTextInputLength, } from '@common-fe/common-fe';
import { toString } from 'lodash';
import * as yup from 'yup';

import { OrganizationTypes, REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';

import useGetParentOrganizations from './useGetParentOrganizations';

const getParentFieldLabel = (mode: EmployerSetupModes) => {
  switch (mode) {
  case EmployerSetupModes.distributor: return 'Partner';
  case EmployerSetupModes.subsidiary: return 'Employer';
  default: return 'Distributor';
  }
};

interface DefaultFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const FIELDS = {
  EMPLOYER_NAME: 'employerName',
  EMPLOYER_ID: 'employerId',
  CUSTOM_EMPLOYER_ID: 'EmployeeIdField',
  PARTNER: 'partner',
};

const GENERATE_ID_LABEL = (title: string) => `Generate ${title} ID automatically`;
export const GENERATE_ID = 'Generate';
const CUSTOM_ID_LABEL = (title: string) => `Custom ${title} ID`;
export const CUSTOM_ID = 'Custom';

const EMPLOYER_ID_OPTIONS = (title: string) => [
  {
    label: GENERATE_ID_LABEL(title),
    value: GENERATE_ID,
  },
  {
    label: CUSTOM_ID_LABEL(title),
    value: CUSTOM_ID,
  },
];
interface Params {
  defaultFields?: object;
  mode: EmployerSetupModes;
  errors: {
    [key: string]: string;
  }
}
const useOrganizationSetupModalFields = ({
  defaultFields, mode, errors,
}: Params) => {
  const { observingOrganization } = useCurrentOrganization();
  const isDistributorMode = useMemo(() => mode === EmployerSetupModes.distributor, [mode]);
  const isEmployerMode = useMemo(() => mode === EmployerSetupModes.employer, [mode]);
  const isSubsidiaryMode = useMemo(() => mode === EmployerSetupModes.subsidiary, [mode]);
  const observedPartnerForNewDistributor = useMemo(
    () => {
      if (isDistributorMode
        && observingOrganization?.type === OrganizationTypes.partner) {
        return {
          key: toString(observingOrganization.id),
          value: observingOrganization.name,
          title: observingOrganization.name,
        } as Option;
      }

      return null;
    },
    [isDistributorMode, observingOrganization],
  );
  const observedDistributorForNewEmployer = useMemo(
    () => {
      if (isEmployerMode
        && observingOrganization?.type === OrganizationTypes.distributor) {
        return {
          key: toString(observingOrganization.id),
          value: observingOrganization.name,
          title: observingOrganization.name,
        } as Option;
      }

      return null;
    },
    [isEmployerMode, observingOrganization],
  );
  const observedEmployerForNewSubsidiary = useMemo(
    () => {
      if (isSubsidiaryMode
        && observingOrganization?.type === OrganizationTypes.company) {
        return {
          key: toString(observingOrganization.id),
          value: observingOrganization.name,
          title: observingOrganization.name,
        } as Option;
      }

      return null;
    },
    [isSubsidiaryMode, observingOrganization],
  );
  const {
    organizations: partnersForNewDistributor,
    isLoading: arePartnersLoading,
    handleSetSearch: searchPartners,
  } = useGetParentOrganizations(
    OrganizationTypes.partner,
    isDistributorMode && !observedPartnerForNewDistributor,
  );
  const {
    organizations: distributorsForNewEmployer,
    isLoading: areDistributorsLoading,
    handleSetSearch: searchDistributors,
  } = useGetParentOrganizations(
    OrganizationTypes.distributor,
    isEmployerMode && !observedDistributorForNewEmployer,
  );
  const {
    organizations: employersForNewSubsidiary,
    isLoading: areEmployersLoading,
    handleSetSearch: searchEmployers,
  } = useGetParentOrganizations(
    OrganizationTypes.company,
    isSubsidiaryMode && !observedEmployerForNewSubsidiary,
  );
  const parentOrganizations = useMemo(() => ([
    ...partnersForNewDistributor,
    ...distributorsForNewEmployer,
    ...employersForNewSubsidiary,
  ]), [partnersForNewDistributor, distributorsForNewEmployer, employersForNewSubsidiary]);
  const isLoading = useMemo(
    () => arePartnersLoading
      || areDistributorsLoading
      || areEmployersLoading,
    [arePartnersLoading, areDistributorsLoading, areEmployersLoading],
  );
  const customIdValidator = useMemo(() => {
    if (errors.EmployeeIdField) {
      return yup.string().test({
        test: (val) => val !== errors.EmployeeIdField,
        message: 'Unique ID already exists in the system',
      }).trim().required(REQUIRED_TEXT);
    }
    return yup.string().trim().required(REQUIRED_TEXT);
  }, [errors]);

  const title = useMemo(() => {
    if (isDistributorMode) return 'Distributor';
    if (isEmployerMode) return 'Employer';
    if (isSubsidiaryMode) return 'Subsidiary';

    return 'Partner';
  }, [isDistributorMode, isEmployerMode, isSubsidiaryMode]);

  const employerFieldsOptions = useMemo(() => {
    if (observedPartnerForNewDistributor) {
      return [observedPartnerForNewDistributor];
    }

    if (observedDistributorForNewEmployer) {
      return [observedDistributorForNewEmployer];
    }

    if (observedEmployerForNewSubsidiary) {
      return [observedEmployerForNewSubsidiary];
    }

    return parentOrganizations;
  }, [
    observedPartnerForNewDistributor,
    parentOrganizations,
    observedDistributorForNewEmployer,
    observedEmployerForNewSubsidiary,
  ]);
  const parentFieldsDefaultValue = useMemo(() => {
    if (observedPartnerForNewDistributor) return observedPartnerForNewDistributor.key;
    if (observedDistributorForNewEmployer) return observedDistributorForNewEmployer.key;
    if (observedEmployerForNewSubsidiary) return observedEmployerForNewSubsidiary.key;
    return '';
  }, [
    observedPartnerForNewDistributor,
    observedDistributorForNewEmployer,
    observedEmployerForNewSubsidiary,
  ]);
  const parentFieldLabel = useMemo(() => getParentFieldLabel(mode), [mode]);

  const fields: Field[] = useMemo(() => {
    const parentField: Field = {
      options: employerFieldsOptions,
      name: FIELDS.PARTNER,
      type: FieldTypes.AutocompleteDropdown,
      label: parentFieldLabel,
      placeholder: `Select ${parentFieldLabel}`,
      showRequireIcon: true,
      validator: yup.string().required(REQUIRED_TEXT),
      defaultValue: parentFieldsDefaultValue,
      disabled: Boolean(observedPartnerForNewDistributor
        || observedDistributorForNewEmployer
        || observedEmployerForNewSubsidiary),
      onSearch: searchPartners || searchDistributors || searchEmployers,
      isLoading,
    };
    const source: Field[] = [
      {
        name: FIELDS.EMPLOYER_NAME,
        type: FieldTypes.Text,
        label: `${title} name`,
        placeholder: `${title} name`,
        validator: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_TEXT),
        defaultValue: '',
        showRequireIcon: true,
      },
      {
        name: FIELDS.EMPLOYER_ID,
        type: FieldTypes.Radio,
        expandItems: true,
        label: `${title} ID`,
        validator: yup.string().required(REQUIRED_TEXT),
        options: EMPLOYER_ID_OPTIONS(title),
        testId: `${FIELDS.EMPLOYER_ID}-id`,
        defaultValue: '',
        showRequireIcon: true,
        subFields: [
          {
            name: FIELDS.CUSTOM_EMPLOYER_ID,
            type: FieldTypes.Text,
            label: '',
            placeholder: `Enter ${title} ID`,
            showRequireIcon: true,
            parentValue: 'Custom',
            validator: customIdValidator,
            defaultValue: '', // generalInfo?.employerId || employerID,
          },
        ],
      },
      ...isEmployerMode || isDistributorMode || isSubsidiaryMode
        ? [parentField] : [],
    ];
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return source.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      }));
    }
    return source;
  }, [
    searchPartners,
    searchDistributors,
    searchEmployers,
    parentFieldLabel,
    parentFieldsDefaultValue,
    observedDistributorForNewEmployer,
    observedEmployerForNewSubsidiary,
    observedPartnerForNewDistributor,
    employerFieldsOptions,
    isLoading,
    isEmployerMode,
    customIdValidator,
    defaultFields,
    isDistributorMode,
    isSubsidiaryMode,
    title,
  ]);

  return fields;
};

export default useOrganizationSetupModalFields;
