import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';

import { AdditionalPayload,useStore } from './stores/useAdditional.store';
import { useAdditionalFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  children?: React.ReactNode;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
  footer?: React.ReactNode;
  onDirty?: (isDirty: boolean) => void;
}
const Additional: React.FC<Props> = ({
  isEditMode, children, onDirty, isTemplateMode, footer, isPlanActive,
}) => {
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const fields = useAdditionalFields(
    activateValidate,
    isTemplateMode,
    isEditMode,
    isPlanActive,
  );
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<AdditionalPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  if (!fields.length) {
    return null;
  }
  return (
    <Box margin={{ top: 'spacing24' }}>
      <Box background="canvas" round="container1Round">
        <FlexControlledForm
          testId="plan_primary-definition"
          fields={fields}
          showError={showErrors || activateValidate || saveValidate}
          editMode={isEditMode}
          onChangeValues={setValues}
          onDirty={onDirty}
          // isDisabledMode
          isThinMode
          footer={footer}
        />
        {children}
      </Box>
    </Box>
  );
};
export default Additional;
