export enum DeductibleType {
  External = 'EXTERNAL_DEDUCTIBLE_MET',
  Individual = 'INDIVIDUAL_DEDUCTIBLE_MET',
  Family = 'FAMILY_DEDUCTIBLE_MET',
  Member = 'MEMBER_DEDUCTIBLE_MET',
  Embedded = 'EMBEDDED_DEDUCTIBLE_MET',
  Plan = 'PLAN_DEDUCTIBLE_MET',
}

export enum DeductibleTypeLabel {
  EXTERNAL_DEDUCTIBLE_MET = 'External deductible met',
  INDIVIDUAL_DEDUCTIBLE_MET = 'Individual deductible met',
  FAMILY_DEDUCTIBLE_MET = 'Family deductible met',
  MEMBER_DEDUCTIBLE_MET = 'Member deductible met',
  EMBEDDED_DEDUCTIBLE_MET = 'Embedded deductible met',
  PLAN_DEDUCTIBLE_MET = 'Plan deductible met',
}

export const MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE = 'MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE';
export const PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE = 'PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE';
