import React, { useMemo } from 'react';
import { Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationStatus } from '@/modules/employee/Employee/Employee.types';

import { getColor,getTitle } from './utils';

interface Props {
  type?: OrganizationStatus;
  iconColor: string;
}
const Status = styled(Inscription)<Props>`
  position: relative;
  padding-left: ${({ theme }) => theme.spacings.spacing16};
  margin-left: ${({ theme }) => theme.spacings.spacing8};
  display: flex;
  flex-direction: row;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 8px;
    height: 8px;
    background: ${({ iconColor }) => iconColor};
    border-radius: 100%;
  }
`;

const CurrentStatus: React.FC<{ type?: OrganizationStatus }> = ({ type }) => {
  const [title, color] = useMemo(() => [getTitle(type), getColor(type)], [type]);

  return (
    <Inscription
      data-testId={`current-status-${title}`}
      color="textBody"
      lineHeight="22px"
      style={{ fontWeight: 400, display: 'flex' }}
    >
      Current status:
      <Status iconColor={color} type={type}>{title}</Status>
    </Inscription>
  );
};

export default CurrentStatus;
