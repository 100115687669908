import React from 'react';
import { Box } from '@common-fe/common-fe';

import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PLAN_MODULES } from '../../plan.constants';
import ConfigureButton from '../ConfigureButton';
import { usePreviewStore } from '../stores';

import Additional from './Additional';
import CardOffered from './CardOffered';
import PlanName from './PlanName';
import PlanYear from './PlanYear';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const PlanDefinition: React.FC<Props> = ({
  isEditMode,
  isTemplateMode,
  isPlanActive,
  onDirty,
}) => {
  const previewMode = usePreviewStore((state) => state.previewMode);
  return (
    <ModuleWrapper
      testId="plan-definition-wrapper"
      anchorId={PLAN_MODULES.PRIMARY_DEFINITION}
      title="Primary Plan Definition"
      expandless
    >
      <Box>
        <PlanName
          isEditMode={isEditMode}
          isTemplateMode={isTemplateMode}
          onDirty={onDirty}
        />
        {isTemplateMode ? null : (
          <>
            <Box data-testId="plan-definition-year" margin={{ top: 'spacing24' }}>
              <PlanYear
                onDirty={onDirty}
              />
            </Box>

          </>
        )}

        <CardOffered
          isEditMode={isEditMode}
          onDirty={onDirty}
          isTemplateMode={isTemplateMode}
        />
        <Additional
          isEditMode={isEditMode}
          onDirty={onDirty}
          isTemplateMode={isTemplateMode}
          isPlanActive={isPlanActive}
          footer={!isEditMode && !previewMode && (
            <ConfigureButton isTemplateMode={isTemplateMode} />
          )}
        />

      </Box>
    </ModuleWrapper>
  );
};
export default React.memo(PlanDefinition);
