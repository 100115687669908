import { useMemo } from 'react';

import { useAccountFundingState } from '../AccountFunding/hooks';
import { useCoverageState } from '../Coverage/hooks';
import { usePlanDefinitionState } from '../PlanDefinition/hooks';

export default () => {
  const planDefinitionState = usePlanDefinitionState();
  const coverageState = useCoverageState();
  const accountFundingState = useAccountFundingState();

  const state = useMemo(() => ({
    ...coverageState,
    ...planDefinitionState,
    ...accountFundingState,
  }), [accountFundingState, coverageState, planDefinitionState]);
  return state;
};
