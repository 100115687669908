import { useCallback } from 'react';
import _ from 'lodash';

import { HealthPlanStatuses } from '../HealthPlan.types';
import { useCreateHealthPlanQuery } from '../HealthPlansList/CreateHealthPlanModal/queries';

export const useCreateHealthPlan = () => {
  const mutation = useCreateHealthPlanQuery();
  const handleCreatePlan = useCallback(
    async (
      planTemplate?: string[],
      planYear?: string,
      organizationId?: string,
      status?: HealthPlanStatuses,
    ) => {
      if (planTemplate) {
        await mutation({
          parentId: _.toNumber(planTemplate),
          planYearId: planYear ? _.toNumber(planYear) : undefined,
          organizationId,
          status,
        });
      }
      return null;
    }, [mutation],
  );
  return handleCreatePlan;
};

export default useCreateHealthPlan;
