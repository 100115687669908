import React, { useCallback, useState } from 'react';
import {
  Box,
} from '@common-fe/common-fe';

import ModalWrapper from '@/modules/employer/components/SetupEmployer/ModalWrapper';
import { PlanTypes } from '@/modules/plan/plan.types';

import PlanEditFormModal from './PlanEditFormModal';
import PlanViewModal from './PlanViewModal';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  refetchList: () => void;
  modalTitle?: string;
  viewMode?: boolean;
  editMode?: boolean;
  data?: PlanTypes;
  isHealthPlanMode?: boolean;
}
const CONFIRM_TEXT = 'Changes that you made may not be saved.';
const PlanEditViewModalWrapper: React.FC<Props> = ({
  modalTitle,
  onSetVisible,
  refetchList,
  visible,
  viewMode,
  editMode,
  data,
  isHealthPlanMode,
}) => {
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);
  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && shouldConfirmOnClose) {
       
      return window.confirm(CONFIRM_TEXT) && onSetVisible(value);
    }
    return onSetVisible(value);
  }, [onSetVisible, shouldConfirmOnClose]);

  return (
    <Box direction="row" align="center">
      <ModalWrapper
        visible={visible}
        title={modalTitle}
        onSetVisible={onSetVisibleHandler}
      >
        {
          viewMode && !editMode && data
            ? (
              <PlanViewModal
                selectedPlan={data}
                isHealthPlanMode={isHealthPlanMode}
              />
            )
            : (
              <PlanEditFormModal
                selectedPlan={data}
                toCancel={() => onSetVisibleHandler(true)}
                onRefetchList={refetchList}
                onDirty={setShouldConfirmOnClose}
              />
            )
        }
      </ModalWrapper>
    </Box>
  );
};

export default PlanEditViewModalWrapper;
