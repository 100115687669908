import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { DefaultSortTypesEnum } from '@/common/types';

import { ContributionActiviti,ContributionActivitiPayload,ContributionActivityStatus } from '../types';

const QUERY_KEY = 'useGetContributionActivitiesQuery';
const SORT_KEY = 'recordDetails.employeeFullName';

interface ResponseData {
  content?: ContributionActivitiPayload[];
  totalPages?: number;
  totalElements?: number;
  size?: number;
  number?: number;
  empty?: boolean;
}

interface Props {
  contributionId?: string;
  page?: number;
  size?: number;
  statuses?: ContributionActivityStatus[];
  taxYears?: string[];
  sortBy?: DefaultSortTypesEnum;
  planCodes?: string[];
}

const useGetContributionActivitiesQuery = ({
  contributionId, statuses, page, size, sortBy, taxYears, planCodes,
}: Props) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEY, contributionId, statuses, page, size, sortBy, taxYears, planCodes],
    () => api.get<ResponseData>(PATHS.GET_CONTRIBUTION_ACTIVITIES, {
      params: {
        ...contributionId ? { contribution_id: contributionId } : {},
        ...statuses?.length ? { statuses: statuses.join(',') } : {},
        ...taxYears?.length ? { tax_years: taxYears.join(',') } : {},
        ...page && page > 1 ? { page: page - 1 } : {},
        ...sortBy ? { sort: `${SORT_KEY},${sortBy}` } : {},
        ...planCodes?.length ? { plan_codes: planCodes.join(',') } : {},
        size,
      },
    }),
    {
      enabled: Boolean(contributionId),
    },
  );

  const formattedData = useMemo(() => (data?.data?.content || [])?.map((activity) => ({
    processingStatus: activity?.processing_status,
    planCode: activity?.plan_code,
    planName: activity?.plan_name,
    taxType: activity?.tax_type,
    taxYear: activity?.tax_year,
    employeeId: toString(activity?.employee_id),
    employeeFullName: activity?.employee_full_name,
    contributionDateOn: activity?.contribution_date_on,
    employeeContributionAmount: toString(activity?.ee_contribution_amount),
    employerContributionAmount: toString(activity?.er_contribution_amount),
    memo: activity?.memo,
    subsidiaryName: activity?.subsidiary_name,
  } as ContributionActiviti)), [data]);

  return {
    formattedData,
    totalPages: data?.data?.totalPages,
    totalElements: data?.data?.totalElements,
    isLoading,
    isEmpty: data?.data?.empty,
  };
};

export default useGetContributionActivitiesQuery;
