import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  AppButton,
  Box,
  Preloader,
  WarnModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';

import useValidatePayrollCalendarForm from '../hooks/useValidatePayrollCalendarForm';
import { PayrollCalendarFormFields, PayrollCalendarFormValues, PayrollCalendarFrequency, PayrollCalendarRequestParams } from '../payrollCalendar.types';
import useCalculatePayrollCalendarScheduleQuery from '../queries/useCalculatePayrollCalendarSchedule.query';
import { useGetPayrollCalendarByIdQuery } from '../queries/useGetPayrollCalendarById.query';
import { useEditablePayrollCalendarStore, usePayrollCalendarDatesStore, usePayrollCalendarModalStore, usePayrollCalendarParamsStore } from '../stores';
import { PayrollCalendarModalSteps } from '../stores/usePayrollCalendarModal.store';

import { usePayrollCalendarParamsForm } from './hooks/usePayrollCalendarParamsForm';
import { PayrollCalendarParamsForm } from './PayrollCalendarParamsForm';

export const paramsBuilder = (values: PayrollCalendarFormValues, isWithName?: boolean): PayrollCalendarRequestParams => {
  const dateFormatter = (date?: string) => dayjs(date).format(RAW_DATE_FORMAT);
  const reverseFrequencyMapping = Object.entries(PayrollCalendarFrequency).reduce((acc, [key, value]) => {
    acc[value as string] = key;
    return acc;
  }, {} as { [key: string]: string });
  const mandatoryParams = {
    start_date: dateFormatter(values[PayrollCalendarFormFields.START_DATE]),
    frequency: reverseFrequencyMapping[values[PayrollCalendarFormFields.FREQUENCY] as string],
    ...isWithName ? { name: values[PayrollCalendarFormFields.CALENDAR_NAME] } : {},
  };
  if (values[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.MONTHLY) {
    return {
      ...mandatoryParams,
      first_day_of_month: values[PayrollCalendarFormFields.DAY_OF_MONTH],
    };
  }

  if (values[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.WEEKLY) {
    return {
      ...mandatoryParams,
      day_of_week: values[PayrollCalendarFormFields.DAY_OF_WEEK],
    };
  }

  if (values[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.BI_WEEKLY) {
    return {
      ...mandatoryParams,
      day_of_week: values[PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK],
    };
  }

  if (values[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.SEMI_MONTHLY) {
    return {
      ...mandatoryParams,
      first_day_of_month: values[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1],
      second_day_of_month: values[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2],
    };
  }

  if (values[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.ANNUALLY) {
    return {
      ...mandatoryParams,
      month: dayjs(values[PayrollCalendarFormFields.DATE_OF_YEAR]).format('MMMM').toLocaleUpperCase(),
      day_of_month: dayjs(values[PayrollCalendarFormFields.DATE_OF_YEAR]).get('date').toString(),
    };
  }

  return mandatoryParams;
};

export const comparePayrollCalendarParams = (
  params1: PayrollCalendarRequestParams,
  params2: PayrollCalendarRequestParams
): boolean => {
  const keys1 = Object.keys(params1) as (keyof PayrollCalendarRequestParams)[];
  const keys2 = Object.keys(params2) as (keyof PayrollCalendarRequestParams)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (params1[key] !== params2[key]) {
      return false;
    }
  }

  return true;
};


interface Props {
  onCancel?: (value: boolean) => void;
}
    
const PayrollCalendarParamsModalForm: React.FC<Props> = ({
  onCancel,
}) => {  
  const [isServerError, setIsServerError] = useState(false);
  const [formValues, setFormValues] = useState<PayrollCalendarFormValues>({});

  const editMode = usePayrollCalendarModalStore((state) => state.editMode);
  const editableId = usePayrollCalendarModalStore((state) => state.editableId);
  const handleSetStep = usePayrollCalendarModalStore((state) => state.handleSetStep);
  const handleSetDates = usePayrollCalendarDatesStore((state) => state.handleSetDates);
  const handleSetInitialMonthDate = usePayrollCalendarDatesStore((state) => state.handleSetInitialMonthDate);
  const handleSetMinDate = usePayrollCalendarDatesStore((state) => state.handleSetMinDate);
  const handleSetCalendarParams = usePayrollCalendarParamsStore((state) => state.handleSetCalendarParams);
  const calendarParams = usePayrollCalendarParamsStore((state) => state.calendarParams);
  const handleReset = usePayrollCalendarParamsStore((state) => state.handleReset);
  const handleSetEditableCalendar = useEditablePayrollCalendarStore((state) => state.handleSetEditableCalendar);

  const { data, isLoading: calendarLoading } = useGetPayrollCalendarByIdQuery(editMode && editableId ? editableId : undefined);

  const {
    isLoading,
    calculateSchedule,
  } = useCalculatePayrollCalendarScheduleQuery();

  const fields = usePayrollCalendarParamsForm({
    formValues,
    defaultValues: data?.config,
    alreadyUsedValues: calendarParams,
  });
    
  const handleSubmit = useCallback(async () => {
    if(formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.ANNUALLY) {
      if(!formValues[PayrollCalendarFormFields.DATE_OF_YEAR]) {
        return;
      }
      const currentDate = dayjs(formValues[PayrollCalendarFormFields.DATE_OF_YEAR], DEFAULT_DATE_FORMAT);
      const startDate = dayjs(formValues[PayrollCalendarFormFields.START_DATE], DEFAULT_DATE_FORMAT);
      const isCurrentDateIsDefault = currentDate.format(DEFAULT_DATE_FORMAT) === data?.config[PayrollCalendarFormFields.DATE_OF_YEAR];
      const today = dayjs();
      if(!currentDate.isValid() || !startDate.isValid()) {
        return;
      } 

      if(startDate.isAfter(currentDate) && !isCurrentDateIsDefault) {
        return;
      }
      
      if(!currentDate.isAfter(today) && !currentDate.isBefore(startDate) && !isCurrentDateIsDefault) {
        return;
      }
    }
  
    try {
      const currentParams = paramsBuilder(formValues);
      
      const isEdited = editMode && !!data && comparePayrollCalendarParams(currentParams, paramsBuilder(data?.config));
      const dates = isEdited ? data?.schedule : await calculateSchedule(currentParams);
      const sortedDates = dates.sort((a, b) => dayjs(a).diff(dayjs(b)));

      handleSetDates(sortedDates);
      handleSetStep(PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_DATES);
      handleSetInitialMonthDate(dayjs(formValues[PayrollCalendarFormFields.START_DATE]).format(RAW_DATE_FORMAT));

      if (dayjs(formValues[PayrollCalendarFormFields.START_DATE]).isAfter(dayjs())) {
        handleSetMinDate(formValues[PayrollCalendarFormFields.START_DATE]);
      } else {
        handleSetMinDate(dayjs().add(1, 'day').format(RAW_DATE_FORMAT));
      }
      handleSetCalendarParams(formValues);
    } catch {
      setIsServerError(true);
    }
  }, [
    formValues,
    calculateSchedule,
    handleSetDates,
    handleSetStep,
    handleSetInitialMonthDate,
    handleSetCalendarParams,
    data,
    editMode,
    handleSetMinDate,
  ]);

  const { isSubmitted, handleValidate } = useValidatePayrollCalendarForm({
    fields,
    state: formValues,
    onSuccess: handleSubmit,
  });
  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
    handleReset();
  }, [onCancel, handleReset]);

  useEffect(() => {
    if (data) {
      handleSetEditableCalendar(data);
    }
  }, [data, handleSetEditableCalendar]);

  const isSetFormValuesCalledRef = useRef(false);

  useEffect(() => {
    if (editMode && data && fields.length > 3 && !isSetFormValuesCalledRef.current) {
      setFormValues(data.config);
      isSetFormValuesCalledRef.current = true;
    }
  }, [editMode, data, fields.length]);

  return (
    <>
      <Box data-testid="PayrollCalendarParamsModalForm_container">
        <Box direction="column">
          <Box
            round="container1Round"
            pad="spacing24"
            background="module"
          >
            
            {calendarLoading
              ? (<Preloader />)
              : (
                <PayrollCalendarParamsForm
                  handleChangeValues={setFormValues}
                  isSubmit={isSubmitted}
                  fields={fields}
                />
              )}
  
          </Box>

        </Box>
        <Box
          direction="row"
          fill="horizontal"
          margin={{ top: 'spacing24' }}
          align="end"
          justify="end"
          width="medium"
          gap="xs"
        >
          <Box direction="row" gap="xs">
            <AppButton
              testId="PayrollCalendarParamsModalForm_cancel"
              buttonType="secondary"
              width="control"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </AppButton>
            <AppButton
              testId="PayrollCalendarParamsModalForm_submit"
              type="button"
              disabled={isLoading}
              onClick={handleValidate}
              width="control"
            >
              {isLoading
                ? (<Preloader />)
                : 'Next (1/3)'}
            </AppButton>
          </Box>
        </Box>
      </Box>

      <WarnModal
        testId="payroll-calendar-params-server-error-modal"
        visible={isServerError}
        onSetVisible={setIsServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot proceed with the latest records.
          Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
    </>
  );
};
    
export default PayrollCalendarParamsModalForm;
    