import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const useActivateEmployerMutation = () => {
  const { mutateAsync } = useMutation(
    (organizationId: string) => api.patch(
      PATHS.ACTIVATE_ORGANIZATION(organizationId),
    ),
  );
  return {
    activateEmployerAsync: mutateAsync,
  };
};
