import { baseStoreCreator } from '@/utils/store';

import { CommunicationChannelPayload } from '../Communication.types';

export const DEFAULT_VALUES: CommunicationChannelPayload = {
  communicationChannel: [],
  emailTemplate: '',
};

export const useCommunicationChannelStore = baseStoreCreator<
CommunicationChannelPayload>(DEFAULT_VALUES);

export default useCommunicationChannelStore;
