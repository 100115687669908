import { BankTypeEnum } from '@/modules/transaction/Cards/Cards.types';

export enum PaymentStatus {
  VERIFIED = 'VERIFIED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  NEW = 'NEW',
}

export enum PaymentMethodType {
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  DEBIT = 'DEBIT',
  VENMO = 'VENMO',
  PAYPAL = 'PAYPAL',
  CHECK = 'CHECK',
}

export enum PaymentMethodOwnerType {
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
  PROVIDER = 'PROVIDER',
}

export interface PaymentMethodOwnerDto {
  id: number;
  payment_method_owner_type: PaymentMethodOwnerType;
  employee_id: number;
  organization_id: number;
  provider_id: number;
}

export interface PaymentMethod {
  isChecked?: boolean;
  id: string;
  type?: PaymentMethodType;
  isDefault?: boolean;
  accountId?: string;
  recipientId?: string;
  email?: string;
  phoneNumber?: string;
  status?: PaymentStatus;
  accountNumber?: string;
  accountDescription?: string;
  address?: {
    id?: string;
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    city?: string;
    state?: {
      id?: string;
      code?: string;
      name?: string;
    };
    countryId?: string;
    zipcode?: string;
    validated?: boolean;
  };
  paymentMethodOwner?: {
    id?: string;
    ownerType?: PaymentMethodOwnerType;
    employeeId?: string;
    providerId?: string;
    organizationId?: string;
  };
  isVerified?: boolean;
  requisites?: {
    phoneNumber?: string;
    email?: string;
    cardHolderName?: string;
    cardExpirationDate?: string;
    cardLast4?: string;
    cardType?: BankTypeEnum;
  },
}
