import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaginationParams } from '@/common/types';

import { ClaimsProcessor, ClaimsProcessorPayload, formatClaimsProcessorPayload } from './ClaimsProcessors.types';

// const ADMIN_3 = 6;
const CLAIMS_PROCESSOR_REVIEW =  7;
const CLAIMS_PROCESSOR_EDIT = 8;
const CLAIMS_SUPERVISOR = 15;
// const SUPER_USER = 3;
const ALLOWED_TYPES = [
  // ADMIN_3,
  CLAIMS_PROCESSOR_REVIEW,
  CLAIMS_PROCESSOR_EDIT,
  CLAIMS_SUPERVISOR,
  // SUPER_USER,
];

const formatData = (data: ClaimsProcessorPayload[]): ClaimsProcessor[] => data.map(formatClaimsProcessorPayload);

interface Props extends PaginationParams {
  enabled?: boolean;
  claimsTeamId?: string;
  assignedClaimsTeamIds?: string[];
}

const useClaimsProcessorsListQuery = ({
  sortBy,
  page,
  perPage,
  searchString,
  claimsTeamId,
  enabled = true,
  assignedClaimsTeamIds,
}: Props) => {
  const { isLoading, data, refetch } = useQuery([
    PATHS.CONTACTS_LIST,
    page,
    perPage,
    enabled,
    searchString,
    sortBy,
    claimsTeamId,
    assignedClaimsTeamIds,
  ], () => api.get(
    `${PATHS.CONTACTS_LIST}${sortBy ? `?sort=firstName,${sortBy}&sort=lastName,${sortBy}` : ''}`,
    {
      params: {
        page,
        size: perPage,
        type: ALLOWED_TYPES.join(','),
        ...searchString ? { search_value: searchString } : {},
        ...claimsTeamId ? { claims_team_id: claimsTeamId} : {},
        assigned_claims_team_ids: assignedClaimsTeamIds?.join(','),
      },
    },
  ), { enabled });

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);
  const claimsProcessors = useMemo(() => {
    if (!data?.data?.content) return undefined;

    return formatData(data.data.content || []);
  }, [data]);

  return ({
    claimsProcessors,
    total,
    isLoading,
    refetch,
  });
};

export default useClaimsProcessorsListQuery;
