import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { Plan, PriorPlanYear } from '@/modules/plan/plan.types';
import { QUERY_KEY } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';

interface Payload {
  name: string;
  validFrom: string;
  validTo?: string;
  payoutDefinitionId?: number;
  copayGroup?: string | null;
  priorPlanYearId?: string;
  priorPlanYear?: PriorPlanYear;
}

// mock.onPost(PATHS.PLAN_YEAR()).reply(503);
export const useCreateHealthPlanYearQuery = (preventReset?: boolean) => {
  const { organizationId } = useCurrentOrganisationStore();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: Payload) => {
      const copayId = _.toNumber(data.copayGroup);
      return api.post<Plan>(PATHS.PLAN_YEAR(), {
        organization_id: organizationId ? +organizationId : null,
        name: data.name,
        valid_from: data.validFrom,
        ...data.validTo ? { valid_to: data.validTo } : {},
        copay_group_id: copayId && !_.isNaN(copayId) ? copayId : null,
        payout_definition_id: _.toNumber(data.payoutDefinitionId),
        prior_plan_year_id: data.priorPlanYearId
          ? _.toNumber(data.priorPlanYearId) : undefined,
      });
    },
    {
      onSuccess: () => {
        if (!preventReset) {
          queryClient.invalidateQueries(QUERY_KEY);
        }
      },
    },
  );
  return mutation.mutateAsync;
};

export default useCreateHealthPlanYearQuery;
