import React, { useState } from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { OptionKey } from '@/common/types';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useHasAccess } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import { CopayStatuses } from './CopayAdjudication.constant';
import CopaysList from './CopaysList';
import CopayTopbar from './CopayTopbar';

interface Props {
  isPageMode?: boolean;
}

const CopayAdjudication: React.FC<Props> = ({ isPageMode }) => {
  const [statuses, setStatuses] = useState<OptionKey[]>([CopayStatuses.Active]);

  const hasViewCopayAccess = useHasAccess([{ permission: Permissions.VIEW_ALL },
    { permission: Permissions.VIEW_COPAY }]);
  if (!hasViewCopayAccess) {
    return null;
  }
  return (
    <Box
      flex="grow"
      align="center"
      data-testid="copay-adjudication"
      width="100%"
      pad={{ bottom: 'xxxl' }}
      height="fit-content"
      border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
    >
      <Box width="100%">
        {isPageMode && <Topbar testId="copay-adjudication_topbar" />}
      </Box>
      <Box width={theme.defaultContentWidth}>
        <CopayTopbar isPageMode={isPageMode} />
        {
          statuses.includes(CopayStatuses.Active) && (
            <CopaysList
              isShowTitle={statuses.length > 1}
              isShowSettings
              statuses={statuses}
              onStatusChange={setStatuses}
            />

          )
        }
        {
          statuses.includes(CopayStatuses.Expired) && (
            <CopaysList
              isExpired
              isShowTitle={statuses.length > 1}
              isShowSettings={statuses.length === 1}
              statuses={statuses}
              onStatusChange={setStatuses}
            />
          )
        }
      </Box>
    </Box>
  );
};

export default CopayAdjudication;
