import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box, ChevronDownIcon, ChevronUpIcon, DotsMenuIcon,
  Text, Tip, } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { TipSide, TipWrapper } from '@/components/wrappers/TipWrapper';

import Link from '../Link/Link';

interface SubOption {
  key: string;
  title: string;
  url?: string;
}

export interface SidebarItemProps {
  title?: string;
  key: string;
  isLink?: boolean;
  minify?: boolean;
  icon?: React.ReactNode;
  url?: string;
  subUrls?: string[];
  delimiter?: boolean;
  isDotsIcon?: boolean;
  currentPath?: string;
  onClick?: () => void;
  hide?: boolean;
  isLogOut?: boolean;
  disabled?: boolean;
  withTip?: boolean;
  tipText?: string;
  options?: SubOption[];
}

interface StyledProps {
  activePath?: boolean;
  minify?: boolean;
  isLink?: boolean;
  isDisabled?: boolean;
}

const DelimiterText = styled(Text)`
  ${({ theme }) => theme.fonts.text12Regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  white-space: nowrap;
`;

const Delimiter = styled(Box)`
  border: none;
  background-color: ${({ theme }) => theme.colors.border1};
  height: 1px;
  width: 65%;
`;

const CustomText = styled(Text) <StyledProps>`
  ${({ theme }) => theme.fonts.text14Medium};
  ${({ theme }) =>  theme.colors.textBody};
  color: ${({ theme }) =>{
    return theme.colors.navigationTextDefault; 
  }};
  margin-left: ${({ isLink }) => (isLink ? '0px' : '13px')} ;
  white-space: nowrap;
  vertical-align: top;
  color: ${({ theme, activePath }) => activePath && theme.colors.navigationTextAccent};
  opacity: ${({ minify }) => (minify ? '0' : '1')};
  transition: opacity 0.1s;
`;

const CustomButton = styled(Box) <StyledProps>`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-left: 10px;
  align-items: center;
  width: ${({ minify }) => (!minify ? '232px' : '66px')};
  height:${({ isLink }) => (isLink ? '20px' : '36px')} ;
  /* margin-bottom: ${({ isLink }) => (isLink ? '6px' : '')}; */
  padding-left:${({ isLink, theme }) => (isLink ? theme.spacings.spacing12 : '10px')};
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  box-shadow: none !important;
  background-color: ${({ theme, activePath}) =>  activePath && theme.colors.navigationAccentActive};
  .icon {
    color: ${({ theme, activePath }) => activePath && theme.colors.navigationIconAccent};
  }
  & svg {
    fill: ${({ theme, activePath }) => activePath && theme.colors.navigationIconAccent};
  }
  &:focus {
    background-color: ${({ theme, minify, isDisabled }) => (!minify && !isDisabled ? theme.colors.accentContainer : undefined)};
    ${CustomText} {
      color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.textAccent};
    }
    svg {
      fill: ${({ theme }) => theme.colors.navigationIconAccent};
    }
  }
  &:hover {
    background-color: ${({ theme, activePath }) => activePath ? theme.colors.navigationAccentActive : theme.colors.navigationAccentHover};
    ${CustomText} {
      color: ${({ theme, isDisabled }) => isDisabled && theme.colors.textDisabled};
      ${({ isDisabled }) => isDisabled && 'cursor: not-allowed'};
    }
  }
  ${({ minify}) => {
    if(minify) {
      return css`
        margin-left: 0;
        padding-left: 20px;
      `;
    }
    return css``;
  }}
`;

interface Props {
  active: boolean;
  minify?: boolean;
  activeBackgroundOnHover?: boolean;
}

const SubItem = styled(Box) <Props>`
  ${({ theme }) => theme.fonts.text16Bold};
  width: 184px;
  border: none;
  font-weight:  700;
  color:  ${({ active, theme }) => (active
    ? theme.colors.navigationTextAccent
    : theme.colors.navigationTextDefault)} ;
  box-shadow: none;
  border-radius: ${({ theme }) => theme.rounds.button2Round} ;
  background-color: ${({ theme, active }) => active 
    ?  `${theme.colors.navigationAccentActive} ` 
    : '' };
  &:hover {
    background-color: ${({ theme }) => theme.colors.navigationAccentHover};
    transition: .3s all;
  }
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MAX_TITLE_LENGTH = 18;

type SidebarProps = SidebarItemProps & {
  currentKey: string;
  onPick: (value: string, url?: string) => void;
  path: string;
};
const SidebarItem: React.FC<SidebarProps> = ({
  delimiter,
  minify,
  title,
  isDotsIcon,
  icon,
  url,
  isLink,
  onClick,
  onPick,
  currentKey,
  disabled,
  withTip = false,
  tipText,
  options,
  path,
  subUrls,
}) => {
  const router = useLocation();
  const [expand, setExpanded] = useState(false);
  const isOptions = useMemo(() => options && options.length, [options]);
  const handleClick = useCallback(() => {
    if (url && onClick) {
      onClick();
    }
    setExpanded(!expand);
  }, [onClick, url, expand]);

  const subItemsList = useMemo(() => (options ? options.map((item) => item.url) : []), [options]);

  useEffect(() => {
    if (options?.length) {
      if (expand) {
        if (!subItemsList.includes(path) && url !== path) {
          setExpanded(false);
        }
      }
      if (!expand) {
        if (subItemsList.includes(path)) {
          setExpanded(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, path]);

  const activePath = useMemo(() => {
    // if (url !== '/') return router.pathname.includes(url || ' ');
    if (subUrls && subUrls.length && url !== router.pathname) {
      return subUrls.some((subUrl) => router.pathname.includes(subUrl));
    }
    return url === router.pathname;
  }, [router.pathname, url, subUrls]);

  if (delimiter) {
    if (minify) {
      if (isDotsIcon) {
        return (
          <Box
            direction="column"
          
          >
            <Box
              width="32px"
              margin={{ left: 'spacing20', top: 'spacing8' }}
            >

              <Box
                align="center"
                justify="center"
                pad={{ top: 'spacing12' }}
                border={{ color: 'border1', side: 'top', size: 'small' }}
              >
                <DotsMenuIcon color="iconPrimary" />

              </Box>
              <CustomText minify />
            </Box>

          </Box>
        );
      }
      if (icon) {
        return (
          <Box height="36px" justify="center" pad={{ left: '20px' }} >
            {icon}
          </Box>
        );
      }
      return (
        <Delimiter
          data-testid="quickLinks"
          margin={{ top: '20px', bottom: '20px', left: '2px' }}
        />
      );
    }
    return (
      <DelimiterText data-testId={`sidebar-${currentKey}-delimiter-text`} margin={{ top: '12px', bottom: '2px', left: '12px' }}>
        {title}
      </DelimiterText>
    );
  }
  return (
    <Box margin={{ bottom: '1px' }}>
      <Link
        testId={`sidebar_${currentKey}_button`}
        to={url || ''}
        disabled={disabled}
        onClick={handleClick}
      >
        <CustomButton
          activePath={activePath}
          minify={minify}
          isLink={isLink}
          hoverIndicator={disabled ? undefined : 'accentContainer'}
          // onClick={disabled ? undefined : handleClick}
          isDisabled={disabled}
        >
          {icon}
          <TipWrapper
            isActive={withTip}
            description={tipText}
            side={TipSide.right}
            customPosition={{
              bottom: '-139%',
            }}
          >
            <CustomText
              data-testId={`sidebar-${currentKey}-title`}
              isLink={isLink}
              minify={minify}
              activePath={activePath}
            >{title}
            </CustomText>
          </TipWrapper>
          {!!isOptions && !minify && 
            (
              <>
                {
                  expand ? (
                    <ChevronUpIcon color="navigationIconDefault" size="18px" />
                  ): (
                    <ChevronDownIcon color="navigationIconDefault" size="18px" />
                  )
                }
              </>
            )}
        </CustomButton>
      </Link>
      {!!isOptions && !!expand && !minify && options
        && options.map((item) => (
          <Tip
            key={item.key}
            plain
            dropProps={{ align: { left: 'right' } }}
            content={(item.title.length > MAX_TITLE_LENGTH
              ? (
                <Box
                  width="max-content"
                  height={{ max: 'l' }}
                  align="center"
                  justify="center"
                  background="background3"
                  border={{ size: 'small', color: 'popup' }}
                  pad={{ horizontal: 'spacing6' }}
                >
                  <Text size="small" color="textOnColor">{item.title}</Text>
                </Box>
              ) : undefined
            )}
          >
            <Link
              testId={`sidebar_${currentKey}_button_${item.key}`}
              to={item.url || ''}
              onClick={() => {
                onPick(item.key, item.url);
              }}
            >
              <SubItem
                margin={{ vertical: 'spacing6', left: 'xxl' }}
                key={item.key}
                active={path === item.url}
                data-testid="layno"
              >
                <Box pad={{ right: 'l', left: 'xs' }}>
                  <StyledText>
                    {item.title}
                  </StyledText>
                </Box>

              </SubItem>
            </Link>
          </Tip>
        ))}
    </Box>
  );
};

export default SidebarItem;
