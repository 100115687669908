import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
// import { useCurrentOrganization } from '@/modules/core/hooks';
// import useServiceCategoriesQuery from '@/modules/employer/queries/useServiceCategories.query';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';

import { CopayGroupPayload } from '../CopayAdjudication.constant';

export const QUERY_KEY_COPAY_BY_ID = 'GET_COPAY_GROUPS_BY_ID';

interface CopayResponsePayload {
  id: number;
  category_type: string;
  amount: number;
}
export interface CopayGroupResponsePayload {
  id: number;
  name: string;
  organization_type: OrganizationTypes;
  copays: CopayResponsePayload[];
  valid_from: string | null;
  valid_to: string | null;
}

const useCopayGroupByIdQuery = (copayId?: string) => {
  const {
    isLoading,
    data,
  } = useQuery(
    [QUERY_KEY_COPAY_BY_ID, copayId],
    () => api.get<CopayGroupResponsePayload>(PATHS.COPAY_GROUPS_BY_ID(copayId), {
      params: {

        // search_value: searchString,
        // organization_id: id,
        // organization_path: path,
        // name: params?.searchString,
        // copay_status: params?.statuses ? params?.statuses.join(',') : undefined, // active, expired
      },
    }),
    {
      enabled: Boolean(copayId),
    },
  );

  const formattedData = useMemo(
    () => {
      const item = data?.data;
      if (!item) {
        return null;
      }
      return {
        id: `${item.id}`,
        name: item.name,
        organizationType: item.organization_type,
        copays: item?.copays?.map((copay) => ({
          id: `${copay.id}`,
          category: copay.category_type,
          amount: `${copay.amount}`,
        })) || [],
        validFrom: item.valid_from,
        validTo: item.valid_to,
      } as CopayGroupPayload;
    }, [data],
  );

  return {
    data: formattedData,
    isLoading,

  };
};

export default useCopayGroupByIdQuery;
