import React from 'react';
import {
  AutocompleteDropdown,
  Box,
  Inscription,
} from '@common-fe/common-fe';
import styled from 'styled-components';

export const EllipsisText = styled(Inscription)`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledAutocompleteDropdown = styled(AutocompleteDropdown)`
  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding-right: ${({ theme }) => theme.spacings.spacing24};
  }
`;

export const PaymentMethodFields = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const OptionsButtonWrapper = styled(Box)`
  button:not([disabled]):hover {
    svg {
      fill: ${({ theme }) => theme.colors.iconAccent};
    }
  }
`;
