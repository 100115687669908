import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Heading } from '@common-fe/common-fe';
import { DirectionType } from 'grommet/utils';

import { Report } from '@/modules/employer/components/Reports/report.types';

interface Props {
  title: string;
  anchorId: Report['type'];
  direction?: DirectionType;
  children?: React.ReactNode;
}

export const ReportSectionWrapper: React.FC<Props> = ({
  anchorId,
  title,
  children,
  direction,
}) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search).get('type'), [location.search]);

  const isChosenBlock = useMemo(() => params === anchorId, [anchorId, params]);

  useEffect(() => {
    if (isChosenBlock) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
      }, 5000);
    } else {
      setIsHighlighted(false);
    }
  }, [isChosenBlock]);

  return (
    <Box
      data-testid={`report_section_wrapper-${anchorId}`}
      id={anchorId}
      pad={{ bottom: 'small', top: 'small' }}
      style={{ position: 'relative' }}
    >
      <Box margin={{ bottom: 'spacing16' }}>
        <Heading
          data-testid={isHighlighted ? 'report_section_wrapper_heading-highlighted' : 'report_section_wrapper_heading'}
          level={4}
          size="small"
          color={isHighlighted ? 'textAccent' : 'textBody'}
        >
          {title}
        </Heading>
      </Box>
      <Box
        pad="spacing16"
        background="module"
        round="moduleRound"
        wrap
        direction={direction || 'row'}
      >
        {children}
      </Box>
    </Box>
  );
};
