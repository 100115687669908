import { useCallback } from 'react';
import _ from 'lodash';

import ROUTES from '@/common/routes';
import { GlobalLoaderMessages, useGlobalLoaderStore } from '@/components/elements/FullLoader/store/useGlobalLoader.store';
import { useSnackbar } from '@/modules/core/hooks';
import { PlanStatuses, PlansType } from '@/modules/plan/plan.types';
import { useSubmitForm,useUpdatePlan } from '@/modules/plan/PlanSetup/hooks';

import { usePlanNameStore } from '../../PlanDefinition/PlanName/stores';
import { useSkipConfirmMode } from '../../stores';
import { usePlanErrorsStore } from '../stores';
import useModalType from '../stores/useModalType.store';

import useParseErrors, { ERROR_MESSAGES } from './useParseErrors';

const SNACKBAR_EXIT_TEXT = 'Close plan config';

const ERRORS = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  SERVER_ERROR: 500,
};
export const useHandlePlanSetUp = (isTemplateMode?: boolean) => {
  const {
    setSaveErrorModal,
    setUpdateErrorModal,
    setActivateErrorModal,
    setActivateModal,
    setIsEmptySaveModal,
    setIsPendingOrganizationModal,
  } = useModalType();

  const setSkipConfirmMode = useSkipConfirmMode((state) => state.setSkipConfirm);

  const errorMessages = usePlanErrorsStore((state) => state.saveErrors);
  const successMessages = usePlanErrorsStore((state) => state.saveSuccess);
  const pendingMessages = usePlanErrorsStore((state) => state.savePendigns);

  const activateErrorMessages = usePlanErrorsStore((state) => state.activeErrors);
  const activateSuccessMessages = usePlanErrorsStore((state) => state.activeSuccess);
  const activatePedingMessages = usePlanErrorsStore((state) => state.activePendigns);

  const planName = usePlanNameStore((state) => state.state.planName);
  const { setLoader, removeLoading } = useGlobalLoaderStore();
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { setActivateValidate, setSaveValidate } = useSubmitForm();
  const handleParseErors = useParseErrors();
  const handleShowError = useCallback((error: unknown) => {
    removeLoading();
    const message = handleParseErors(error);
    if (message) {
      if (message.includes(ERROR_MESSAGES.PENDING_ORGANIZATION)) {
        setIsPendingOrganizationModal(true);
        return;
      }
    }
    const code = _.get(error, 'response.status', 0) as number;

    if (code >= ERRORS.SERVER_ERROR) {
      setSaveErrorModal(true);
    } else if (code === ERRORS.UNAUTHORIZED || !code) {
       
      return;
    } else {
      setIsEmptySaveModal(true);
    }
  }, [handleParseErors, removeLoading, setIsEmptySaveModal,
    setIsPendingOrganizationModal, setSaveErrorModal]);
  const handleSavePlan = useUpdatePlan(handleShowError, isTemplateMode);
  const onSaveDraftHandler = useCallback(async () => {
    setSaveValidate(true);
    if (_.isEmpty(errorMessages)) {
      setLoader({ message: GlobalLoaderMessages.SAVE_PLAN_TO_DRAFT });

      await handleSavePlan(PlanStatuses.DRAFT);
      handleAddPermanentSnackbar({
        text: `${planName} draft saved`,
        closeIcon: true,
        redirect: {
          path: ROUTES.PLANS,
          label: SNACKBAR_EXIT_TEXT,
          hash: isTemplateMode ? PlansType.Template : '',
        },
      });
      removeLoading();
    } else if (_.isEmpty(successMessages) || !_.isEmpty(errorMessages)) {
      setIsEmptySaveModal(true);
    } else {
      setSaveErrorModal(true);
    }
  }, [setSaveValidate, errorMessages,
    isTemplateMode,
    successMessages, setLoader, handleSavePlan,
    removeLoading, handleAddPermanentSnackbar,
    planName, setSaveErrorModal, setIsEmptySaveModal]);

  const onUpdateHandler = useCallback(() => {
    setActivateValidate(true);
    if (_.isEmpty(activateErrorMessages)) {
      setLoader({ message: GlobalLoaderMessages.UPDATE_ACTIVE_PLAN });
      // await handleSavePlan();
      removeLoading();
      handleAddPermanentSnackbar({
        text: `${planName} submitted for review`,
        closeIcon: true,
        redirect: {
          path: ROUTES.PLANS,
          label: SNACKBAR_EXIT_TEXT,
          hash: isTemplateMode ? PlansType.Template : '',
        },
      });
    } else if (!_.isEmpty(activateErrorMessages)) {
      setIsEmptySaveModal(true);
    } else {
      setUpdateErrorModal(true);
    }
  }, [isTemplateMode, activateErrorMessages, handleAddPermanentSnackbar,
    planName, removeLoading, setActivateValidate,
    setIsEmptySaveModal, setLoader, setUpdateErrorModal]);

  const onActivateHandler = useCallback(async () => {
    setActivateValidate(true);
    if (_.isEmpty(activateErrorMessages)) {
      setLoader({ message: GlobalLoaderMessages.ACTIVATE_PLAN });
      await handleSavePlan(PlanStatuses.ACTIVE);
      removeLoading();
      setSkipConfirmMode(true);
      handleAddPermanentSnackbar({
        text: isTemplateMode ? `${planName} activated` : `${planName} plan has been updated!`,
        closeIcon: true,
        redirect: {
          path: ROUTES.PLANS,
          label: SNACKBAR_EXIT_TEXT,
          hash: isTemplateMode ? PlansType.Template : '',
        },
      });
    } else if (!_.isEmpty(activateErrorMessages)) {
      setActivateModal(true);
    } else {
      setActivateErrorModal(true);
    }
  }, [
    isTemplateMode, setActivateValidate, activateErrorMessages, setLoader,
    handleSavePlan, removeLoading, setSkipConfirmMode,
    handleAddPermanentSnackbar, planName, setActivateErrorModal,
    setActivateModal]);

  return {
    onSaveDraftHandler,
    onUpdateHandler,
    onActivateHandler,
    errorMessages,
    successMessages,
    pendingMessages,
    activateErrorMessages,
    activateSuccessMessages,
    activatePedingMessages,
  };
};
