import { TOCSidebarProps } from '@/modules/core/components/TOCSidebar/TOCSidebar.types';
import { PLAN_MODULES } from '@/modules/plan/plan.constants';

export const planConfigModuleNames = {
  [PLAN_MODULES.PRIMARY_DEFINITION]: 'Primary Plan Definition',
  [PLAN_MODULES.COVERAGE]: 'Coverage',
  [PLAN_MODULES.ACCOUNT_FOUNDING]: 'Account Funding',
  [PLAN_MODULES.ACTIVITY_LOG]: 'Activity Log',
  // [PLAN_MODULES.PAYMENT_RULES]: 'Payment Rules',
  [PLAN_MODULES.FINAL_SETUP]: 'Final Setup',
} as const;

export const usePlanModules = (exluce?: string[]): TOCSidebarProps['stepsMap'] => Object
  .keys(planConfigModuleNames)
  .reduce((map, anchorId) => {
    if (exluce && exluce.includes(anchorId)) {
      return map;
    }
    return ({
      ...map,
      [anchorId]: {
        title: planConfigModuleNames[anchorId],
      },
    });
  }, {});
