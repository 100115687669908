import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  ClearAll,
  SearchInput, SelectDropdown, useDebounce,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { OptionKey } from '@/common/types';
import { GroupByFilter, PLAN_YEAR_FILTER_OPTIONS,PlanYearReportFilterItems } from '@/modules/employer/components/Reports/report.types';

import { SingleRadioSelectDropdown } from '../SingleRadioSelectDropdown';

import {
  ACCOUNT_ACTIVITY_GROUP_BY_DEFAULT_VALUE,
  useAccountActivityReportFilterStore,
} from './store/useAccountActivityReportFilter.store';
import SelectSSNDropdown from './SelectSSNDropdown';

const INPUT_WIDTH = '237px';

const ClearAllButtonContainer = styled(Box)`
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translate(-50%);
`;

const groupByOptions = [
  {
    key: GroupByFilter.Partner,
    title: 'Partner',
    value: 'Partner',
  },
  {
    key: GroupByFilter.Employer,
    title: 'Employer',
    value: 'Employer',
  },
  {
    key: GroupByFilter.PartnerAndEmployer,
    title: 'Partner and Employer',
    value: 'Partner and Employer',
  },
];

export const AccountActivityReportFilters = () => {

  const ssnOption = useAccountActivityReportFilterStore((state) => state.SSNOption);
  const setSSNOption = useAccountActivityReportFilterStore((state) => state.handleSetSSNOption);

  const planYear = useAccountActivityReportFilterStore((state) => state.planYear);
  const groupByValue = useAccountActivityReportFilterStore((state) => state.groupBy);
  const setPlanYear = useAccountActivityReportFilterStore((state) => state.handleSetPlanYear);
  const setSearchValue = useAccountActivityReportFilterStore((state) => state.handleSetSearch);
  const searchValue = useAccountActivityReportFilterStore((state) => state.search);
  const setGroupByValues = useAccountActivityReportFilterStore((state) => state.handleSetGroupBy);
  const handleClearAll = useAccountActivityReportFilterStore((state) => state.handleClear);
  const [tempSearchValues, setSearchTempValues] = useState<string>(searchValue);
  const [isHovered, setIsHovered] = useState(false);
  const [initCleaning, setInitCleaning] = useState(false);
  const debouncedValue = useDebounce(tempSearchValues);

  const isVisibleClearAll = useMemo(
    () => !_.isEqual(groupByValue, ACCOUNT_ACTIVITY_GROUP_BY_DEFAULT_VALUE)
    || tempSearchValues.length >= 3
    || !planYear.includes(PlanYearReportFilterItems.NULL),
    [groupByValue, tempSearchValues, planYear],
  );

  useEffect(() => {
    const validValueLength = debouncedValue.length >= 3 || debouncedValue.length === 0;
    if (validValueLength) {
      setSearchValue(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSetFilterValues = useCallback((values: OptionKey[]) => {
    if (planYear.includes(PlanYearReportFilterItems.NULL) && values.length > 1) {
      const filtered = values.filter((item) => item !== PlanYearReportFilterItems.NULL);
      setPlanYear(filtered);
    } else if ((values.includes(PlanYearReportFilterItems.NULL)
      && !planYear.includes(PlanYearReportFilterItems.NULL)) || !values.length) {
      setPlanYear([PlanYearReportFilterItems.NULL]);
    } else if (values.length === 3) {
      setPlanYear([PlanYearReportFilterItems.NULL]);
    } else {
      setPlanYear(values);
    }
  }, [planYear, setPlanYear]);

  const handleClear = useCallback(() => {
    handleClearAll();
    setInitCleaning(!initCleaning);
  }, [handleClearAll, initCleaning]);

  return (
    <>
      <ClearAllButtonContainer
        width="max-content"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ClearAll
          onClick={handleClear}
          isVisible={isVisibleClearAll}
          buttonStyles={{ backgroundColor: isHovered ? undefined : 'canvas' }}
        />
      </ClearAllButtonContainer>

      <Box gap="spacing12" direction="row" flex="grow">
        <Box width={INPUT_WIDTH} height={{ max: '40px' }}>
          {/* <NestedSelectDropdown
            testId="AccountActivityReport_GroupBy"
            title="Group By"
            options={groupByOptions}
            values={groupByValues}
            onChange={(values) => setGroupByValues(values as AccountActivityGroupByType)}
            customWidth={INPUT_WIDTH}
          /> */}
          {/* <SelectDropdown
            id="status"
            testId="AccountActivityReport_GroupBy"
            singleMode
            activeTitle
            ellipsisMode
            name="AccountActivityReport_GroupBy"
            prefix="Group By"
            options={groupByOptions}
            values={groupByValues}
            onChangeValues={setGroupByValues}
          /> */}
          <SingleRadioSelectDropdown
            testId="AccountActivityReport_GroupBy"
            title="Group By"
            options={groupByOptions}
            value={groupByValue}
            customWidth={INPUT_WIDTH}
            onChange={setGroupByValues}
          />
        </Box>
        <Box width={INPUT_WIDTH}>
          <SelectDropdown
            testId="AccountActivityReport_plan-year"
            id="AccountActivityReport_plan-year-filter"
            activeTitle
            ellipsisMode
            name="Plan Year"
            prefix="Plan Year"
            options={PLAN_YEAR_FILTER_OPTIONS}
            values={planYear}
            onChangeValues={handleSetFilterValues}
            isOnlyManualExpand
          />
        </Box>
        <Box flex="grow" width={INPUT_WIDTH}>
          <SearchInput
            testId="AccountActivityReport_filter-search"
            className="search-input"
            value={tempSearchValues}
            placeholder="Organization"
            onChange={setSearchTempValues}
            resetValue={initCleaning}
            hasSearchingHistory
          />
        </Box>
        <Box flex="grow">
          <SelectSSNDropdown value={ssnOption} onChangeValue={setSSNOption}/>
        </Box>
      </Box>
    </>
  );
};
