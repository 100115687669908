import React, { useCallback, useMemo } from 'react';
import {
  AppButton,
  Box,
  Preloader,
  RoundPendingIcon,
  Text,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';

import { useDeactivatePayrollCalendarQuery } from './queries/useDeactivatePayrollCalendar.query';
import { usePayrollCalendarDeactivationModalStore } from './stores';

type Props = {
  onSuccess?: () => void;
};

export const PayrollCalendarDeactivationModal: React.FC<Props> = ({ onSuccess }) => {
  const visible = usePayrollCalendarDeactivationModalStore((store) => store.modalVisible);
  const handleResetStore =usePayrollCalendarDeactivationModalStore((store) => store.handleReset);
  const calendarName = usePayrollCalendarDeactivationModalStore((store) => store.calendarName);
  const calendarId = usePayrollCalendarDeactivationModalStore((store) => store.calendarId);
  const isPlansPresent = usePayrollCalendarDeactivationModalStore((store) => store.isPlansPresent);
  
  const isPayrollGroupsPresent = usePayrollCalendarDeactivationModalStore((store) => store.isPayrollGroupsPresent);
  const { deactivateCalendar, isLoading } = useDeactivatePayrollCalendarQuery();
  const { handleAddPermanentSnackbar } = useSnackbar();

  const handleDeactivateCalendar = useCallback(async () => {
    if (calendarId) {
      await deactivateCalendar(calendarId);
      if (onSuccess) {
        onSuccess();
      }
      handleAddPermanentSnackbar({
        text: `${calendarName} was deactivated!`,
        closeIcon: true,
        state: SnackbarState.negative,
      });
    }
    handleResetStore();
  }, [calendarId, handleResetStore, deactivateCalendar, onSuccess, handleAddPermanentSnackbar, calendarName]);
  const subText = useMemo(() => {
    const affectedEntities: string[] = [];

    if(isPlansPresent) {
      affectedEntities.push('plans');
    }
    if(isPayrollGroupsPresent) {
      affectedEntities.push('payroll groups'); 
    }
    const affectedText = affectedEntities.join(', ');
    return `You have ${affectedText} using this calendar. Any scheduled contributions for these accounts will be deactivated until the ${affectedText} connected to another calendar.`;
  }, [isPayrollGroupsPresent, isPlansPresent]);
  const headerText = useMemo(() => `Deactivate ${calendarName} payroll calendar?`, [calendarName]);
  return (
    <ModalWrapper testId="PayrollCalendarDeactivationModal_modal_wrapper" visible={visible} onSetVisible={handleResetStore}>
      <Box align="center" pad={{  vertical: 'spacing24' }}>
        <RoundPendingIcon size="48px" color="warning" />
        <Text
          data-testid="PayrollCalendarDeactivationModal_modal_header"
          margin={{ vertical: 's', bottom: 's' }}
          textAlign="center"
          size="24px"
          weight={700}
          color="textBody"
        >
          {headerText}
        </Text>
        <Box margin={{ bottom: 'l', horizontal: 'xxl'}} >
          <Text weight={400} textAlign="center" size="medium">
            {subText}
          </Text>
        </Box>
        <Box
          direction="row"
          gap="spacing12"
        >
          <AppButton
            buttonType="secondary"
            testId="PayrollCalendarDeactivationModal_modal_cancel"
            onClick={handleResetStore}
            width="150px"
          >
            Cancel
          </AppButton>
          <AppButton
            color="red"
            testId="PayrollCalendarDeactivationModal_modal_deactivate"
            onClick={handleDeactivateCalendar}
            width="150px"
          >
            {
              isLoading
                ? <Preloader />
                : 'Yes, Deactivate'
            }
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
