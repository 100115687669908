// import { useMemo } from 'react';
import { matchPath } from 'react-router';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import { useAuthStore } from '@/modules/user/stores';

export interface Params {
  id: string;
}
export interface MatchObj {
  params: Params;
}

const useIsAllowToRedirect = (route: string): boolean => {
  const { user } = useAuthStore();
  const isPlansPageAccessible = useHasAccess([
    { permission: Permissions.VIEW_PLAN },
    { permission: Permissions.VIEW_ALL },
  ]);
  const isPlanSetupPageAccessible = useHasAccess([
    { permission: Permissions.PLAN_SETUP },
  ]);
  const matchObj: MatchObj | null = matchPath(route, {
    path: ROUTES.PLAN_SETUP,
    exact: true,
    strict: false,
  });

  if (ROUTES.PLAN_SETUP_BY_ID(matchObj?.params?.id || '') === route
    && !isPlanSetupPageAccessible && !!user) {
    return false;
  }
  if (ROUTES.PLANS === route && !isPlansPageAccessible && !!user) {
    return false;
  }
  return true;
};

export default useIsAllowToRedirect;
