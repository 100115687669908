import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, DateInput, FlexList,
  PaginationBar, Preloader, SearchInput, SelectDropdown, Text, } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { ALL_OPTION } from '@/common/constants';
import {
  ListItemBase, OptionKey,
} from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { QueryKeys } from '@/modules/core/hooks/useHistory';
import { useFilteringTableData } from '@/modules/employer/components/ReconciliationReports/components/ReconciliationReportsList/hooks/useFilteringTableData';
import { ReconciliationsAccountTypes } from '@/modules/employer/components/ReconciliationReports/components/ReconcilliationReport.constants';
import { useListPagination } from '@/modules/employer/hooks';
import {
  useReconciliationBankAccountList,
} from '@/modules/employer/queries/useReconciliationBankAccounts.query';
import { stateAllToggling } from '@/utils/handlers/dropdownStateAllToggling';

import useReconciliationReportDownloadModal from '../ReconciliationReportDownloadModal/hooks/useReconciliationReportDownloadModal';

const HEADERS: ListItemBase[] = [
  {
    key: 'account',
    title: 'Account',
    width: 35,
    sticky: true,
  },
  {
    key: 'accountID',
    title: 'Account ID',
    width: 10,
  },
  {
    key: 'type',
    title: 'Type',
  },
  {
    key: 'ourBalance',
    title: 'Our Balance',
    width: 10,
  },
  {
    key: 'bankBalance',
    title: 'Bank Balance',
    width: 10,
  },
  {
    key: 'difference',
    title: 'Difference',
    width: 10,
  },
  {
    key: 'prior',
    title: 'Prior',
    width: 10,
  },
  {
    key: 'delta',
    title: 'Delta',
    width: 10,
  },
  {
    key: 'credit',
    title: 'Credit',
    width: 10,
  },
  {
    key: 'debit',
    title: 'Debit',
    width: 10,
  },
  {
    key: 'closeDate',
    title: 'Close Date',
    width: 10,
  },
];

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const ReconciliationReportsList = () => {
  const {
    push,
    location,
    queryMap,
  } = useHistory();
  const yesterday = dayjs().subtract(1, 'day').format(DEFAULT_DATE_FORMAT);
  const today = dayjs().format(DEFAULT_DATE_FORMAT);
  const [date, setDateRange] = useState(queryMap.reconciliationReportsDate || yesterday);
  const [categories, setCategories] = useState<OptionKey[]>([ALL_OPTION.value]);
  const {
    page,
    searchString,
    setPerPage,
    perPage,
    setPage,
    setSearchString,
  } = useListPagination();
  const {
    data,
    refetch,
    isLoading,
    total,
  } = useReconciliationBankAccountList(
    date, false,
  );

  const filterCategories = useMemo(() => stateAllToggling(categories), [categories]);

  const hasCategories = useMemo(
    () => filterCategories.length > 0
      && !filterCategories.some((param) => param === ALL_OPTION.value),
    [filterCategories],
  );

  const {
    paginatedData,
    filteredByCategoriesData,
  } = useFilteringTableData({
    data,
    pagination: { searchString, page, perPage },
    categories: (hasCategories ? filterCategories : [ALL_OPTION.value]),
  });
  const {
    setIsModalVisible,
    setDownloadReportId,
  } = useReconciliationReportDownloadModal();

  const accountsTypesOptions = useMemo(() => {
    const listOfTypes = Object.keys(ReconciliationsAccountTypes).map((key) => ({
      key: ReconciliationsAccountTypes[key as keyof typeof ReconciliationsAccountTypes],
      value: key,
      title: ReconciliationsAccountTypes[key as keyof typeof ReconciliationsAccountTypes],
    })) || [];

    const preparedListOfTypes = [
      ALL_OPTION,
      ...listOfTypes,
    ];

    return preparedListOfTypes;
  }, []);

  useEffect(() => {
    refetch();
  }, [date, refetch, searchString]);

  useEffect(() => {
    setDateRange(queryMap.reconciliationReportsDate || yesterday);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDownloadReport = useCallback((id: string) => {
    setDownloadReportId(id);
    setIsModalVisible(true);
  }, [setDownloadReportId, setIsModalVisible]);

  return (
    <Box
      data-testid="ReconciliationListOfBankAccounts"
      background={{ color: 'module' }}
      round="container1Round"
    >
      <Box
        direction="row"
        justify="between"
        align="center"
        pad={{ bottom: 'spacing16', top: 'spacing24', horizontal: 'spacing24' }}
      >
        <Text
          weight="bold"
          color="textBody"
        >
          List of bank accounts: {filteredByCategoriesData.length}
        </Text>
        <Box direction="row" justify="end">
          <Box margin={{ left: 'spacing12' }} width={{ min: '180px', max: '180px' }}>
            <SearchInput
              className="search-input"
              value={searchString}
              placeholder="Search"
              onChange={setSearchString}
            />
          </Box>
          <Box margin={{ left: 'spacing12' }} width={{ min: '180px', max: '180px' }}>
            <SelectDropdown
              id="category"
              allMode
              activeTitle
              ellipsisMode
              name="Reconciliation types"
              prefix="Type"
              options={accountsTypesOptions}
              values={filterCategories}
              onChangeValues={setCategories}
            />
          </Box>
          <Box margin={{ left: 'spacing12' }} width={{ min: '180px', max: '180px' }}>
            <DateInput
              isPicker
              className="period-picker"
              onChange={(range) => {
                push(location.pathname, {
                  [QueryKeys.RECONCILIATION_REPORTS_DATE]: range,
                });
                setDateRange(range);
              }}
              value={date}
              maxDate={dayjs(today)}
              levelling={{
                top: '100%',
                left: '0',
              }}
            />
          </Box>
        </Box>
      </Box>
      {isLoading
        ? (
          <Box data-testid="ReconciliationListOfBankAccounts-loader" pad="spacing24">
            <Preloader />
          </Box>
        )
        : (
          <>
            <FlexList
              headers={HEADERS}
              rows={paginatedData}
              total={total}
              pad={{ top: '0', bottom: 'spacing24', horizontal: 'spacing24' }}
              options={[
                {
                  name: 'Download Report',
                  onClick: handleDownloadReport,
                  optionStyle: {
                    fontSize: '14px',
                    fontWeight: 400,
                  },
                },
              ]}
              padBottom="0"
            />
            <Box pad={{ horizontal: 'spacing24', bottom: 'spacing24' }}>
              <PaginationBar
                page={page}
                total={total}
                pageSize={perPage}
                onChangePage={setPage}
                onChangePageSize={setPerPage}
              />
            </Box>
          </>
        )}
    </Box>
  );
};
