import React, { useCallback } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { ReportItemData } from '@/modules/employer/components/Reports/report.types';

interface Props {
  reportData: ReportItemData[];
  expand?: boolean;
  styles?: React.CSSProperties;
}

export const ReportItemContent: React.FC<Props> = ({ reportData, expand, styles }) => {
  const preparedItem = useCallback((item: ReportItemData) => {
    if (!item.value && item.value !== 0) {
      return '';
    }
    if (item.prefix) {
      return `$${item.value.toLocaleString()}`;
    }
    return item.value || 0;
  }, []);

  return (
    <Box height="fit-content" style={{ ...styles }}>
      {reportData.map((item, index) => (
        <Box
          data-testid={`report_item_info_table-item-${item.id}`}
          key={item.id}
          height={{ min: '18px', max: '18px' }}
          direction="row"
          margin={{ bottom: reportData.length === (index + 1) ? undefined : 'spacing8' }}
          justify="between"
          align="center"
          pad={{
            bottom: 'spacing4',
            top: (item.isTitle && expand) ? 'spacing8' : undefined,
          }}
          border={!item.isTitle && {
            side: 'bottom',
            size: 'small',
            color: 'border1',
          }}
        >
          <Text
            weight={item.isTitle ? 700 : 500}
            size="small"
            color="textBody"
          >
            {item.title}
          </Text>

          <Text color="textBody" weight={700} size="small">{preparedItem(item)}</Text>
        </Box>
      ))}
    </Box>
  );
};
