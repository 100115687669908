import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';
import _ from 'lodash';

import { REGEXPS, VALIDATORS } from '@/common';
import { useOnlyForDev } from '@/utils';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useAccountFundingFields, useAccountFundingState } from '../../AccountFunding/hooks';
import { ROLLOVER_KEYS } from '../../AccountFunding/Rollover/Rollover.constants';
import { useCoverageFields, useCoverageState } from '../../Coverage/hooks';
import { usePlanDefinitionFields, usePlanDefinitionState } from '../../PlanDefinition/hooks';
import { PLAN_SETUP_FIELD, REQUIRED_TYPE } from '../../PlanSetup.constants';

const VALIDATION_FIELDS = [
  PLAN_SETUP_FIELD.PLAN_NAME,
  PLAN_SETUP_FIELD.PLAN_TYPE,
  PLAN_SETUP_FIELD.PARENT_TEMPLATE,
  PLAN_SETUP_FIELD.PLAN_CODE,
  PLAN_SETUP_FIELD.PLAN_YEAR,
];

const TEMPLATE_VALIDATION_FIELDS = [
  PLAN_SETUP_FIELD.PLAN_NAME,
  PLAN_SETUP_FIELD.PLAN_TYPE,
];
const IGNORE_FIELDS = [
  PLAN_SETUP_FIELD.PRIOR_PLAN_ID,
  PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT,
  PLAN_SETUP_FIELD.MINIMUM_ELECTION_AMOUNT,
  PLAN_SETUP_FIELD.CARD_OFFERED];

const VALIDATION_FIELDS_MAP = {
  maximumElectionAmountCustom: PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT,
  maximumElectionAmountIRS: PLAN_SETUP_FIELD.MAXIMUM_ELECTION_AMOUNT,
  minimumElectionAmountCustom: PLAN_SETUP_FIELD.MINIMUM_ELECTION_AMOUNT,

  gracePeriodAmount: PLAN_SETUP_FIELD.GRACE_PERIOD,
  [ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT_CUSTOM]: ROLLOVER_KEYS.MAXIMUM_ROLLOVER_AMOUNT,
  [ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT_CUSTOM]: ROLLOVER_KEYS.MINIMUM_ROLLOVER_AMOUNT,
  [ROLLOVER_KEYS.ROLLOVER_CLAIMS_YEARS_VALUE]: ROLLOVER_KEYS.ROLLOVER_CLAIMS,
};

const getFormatedString = (value: string) => _.capitalize(_.startCase(value));

export const usePlanSaveValidate = (isTemplateMode?: boolean) => {
  const isOnlyForDev = useOnlyForDev();

  const { getValidateFields, getErrors } = useValidationUtils();

  const planDefinitionState = usePlanDefinitionState();
  const planDefinitionFields = usePlanDefinitionFields(false, isTemplateMode);

  const coverageState = useCoverageState();
  const coverageFields = useCoverageFields();

  const accountFundingState = useAccountFundingState();
  const accountFundingFields = useAccountFundingFields(false, !isOnlyForDev, isTemplateMode);
  const planDefinitionErrors: FieldError = useMemo(
    () => {
      const errors = getErrors(planDefinitionState, getValidateFields(planDefinitionFields));
      if (planDefinitionState?.fundIDFormula) {
        // @ts-ignore
        const fundIDFormula = planDefinitionState?.fundIDFormula?.replaceAll(REGEXPS.PARENTHESES, '');
        if (fundIDFormula) {
          try {
            VALIDATORS.ONLY_LATIN.validateSync(fundIDFormula);
          } catch {
            return {
              ...errors,
              fundIDFormula: {
                message: '',
                type: REQUIRED_TYPE,
              },
            };
          }
        }
      }

      return errors;
    },
    [getErrors, getValidateFields, planDefinitionFields, planDefinitionState],
  );
  const coverageErrors: FieldError = useMemo(
    () => getErrors(coverageState, getValidateFields(coverageFields)),
    [coverageFields, coverageState, getErrors, getValidateFields],
  );

  const accountFundingErrors: FieldError = useMemo(
    () => getErrors(accountFundingState, getValidateFields(accountFundingFields)),
    [accountFundingFields, accountFundingState, getErrors, getValidateFields],
  );

  const filteredErrors = useMemo(() => {
    const allErrors: FieldError = {
      ...planDefinitionErrors,
      ...coverageErrors,
      ...accountFundingErrors,
    };

    const filtered: FieldError = {};
    _.forEach(allErrors, (item, key) => {
      if (IGNORE_FIELDS.includes(key)) {
        return;
      }
      const newKey = _.get(VALIDATION_FIELDS_MAP, `[${key}]`) || key;
      filtered[newKey] = item;
    });

    return filtered;
  }, [accountFundingErrors, coverageErrors, planDefinitionErrors]);
  const errorsKeys = useMemo(
    () => _.map(filteredErrors, (item, key) => key), [filteredErrors],
  );

  const successes = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    return _.difference(messages, errorsKeys);
  }, [errorsKeys, isTemplateMode]);
  const errorMessages = useMemo(() => {
    const list = errorsKeys.map((item) => {
      if (item === PLAN_SETUP_FIELD.PLAN_NAME && isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE_NAME);
      }
      if (item === PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });

    return list;
  }, [errorsKeys, isTemplateMode]);

  const successMessages = useMemo(() => {
    const list = successes.map((item) => {
      if (item === PLAN_SETUP_FIELD.PLAN_NAME && isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE_NAME);
      }
      if (item === PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });

    return list;
  }, [isTemplateMode, successes]);

  const pendingMessages = useMemo(() => {
    const messages = isTemplateMode ? TEMPLATE_VALIDATION_FIELDS : VALIDATION_FIELDS;
    const list = messages.map((item) => {
      if (item === PLAN_SETUP_FIELD.PLAN_NAME && isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE_NAME);
      }
      if (item === PLAN_SETUP_FIELD.PARENT_TEMPLATE && !isTemplateMode) {
        return getFormatedString(PLAN_SETUP_FIELD.PLAN_TEMPLATE);
      }
      return getFormatedString(item);
    });
    return list;
  }, [isTemplateMode]);
  return {
    pendingMessages,
    errorMessages,
    successMessages,
  };
};
