import { capitalizeFirstLetter,Field, FieldTypes } from '@common-fe/common-fe';

import { useCurrentOrganization } from '@/modules/core/hooks';

const useCurrentUserFields = () => {
  const { observingOrganization } = useCurrentOrganization();

  return [
    {
      name: 'orgLevel',
      type: FieldTypes.Text,
      label: 'Organization level',
      value: capitalizeFirstLetter(observingOrganization?.type || ''),
    },
    {
      name: 'orgName',
      type: FieldTypes.Text,
      label: 'Organization name',
      value: observingOrganization?.name,
    },
  ] as Field[];
};

export default useCurrentUserFields;
