import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import usePlanYearStore, { PlanYearPayload } from '../stores/usePlanYear.store';

export default () => {
  const setPlanYearState = usePlanYearStore((state) => state.setState);
  const setPlanYearSourceState = usePlanYearStore((state) => state.setSourceState);
  const setPlanYearErrorFields = usePlanYearStore((state) => state.setErrorFields);
  const handleResetPlanYearStore = usePlanYearStore((state) => state.handleReset);

  const handleSetState = useCallback((values: Partial<PlanYearPayload>) => {
    setPlanYearState(values);
  }, [setPlanYearState]);

  const handleSetSourceState = useCallback((values: Partial<PlanYearPayload>) => {
    setPlanYearSourceState(values);
  }, [setPlanYearSourceState]);


  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<PlanYearPayload>>) => {
    setPlanYearErrorFields(values);
  }, [setPlanYearErrorFields]);

  const handleReset = useCallback(() => {
    handleResetPlanYearStore();
  }, [handleResetPlanYearStore]);
  
  return {
    handleSetState,
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
  };
};
