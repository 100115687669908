import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useHealthPlanNameStore } from '../BasicProperties/HealthPlanName/stores';
import useGetFormattedCoverageLevels from '../CoverageLevels/hooks/useGetFormattedCoverageLevels';
import { useHealthPlanByIdQuery } from '../queries';
import { usePlanLoadingStore } from '../stores';
import { getFormatedHealthPlan } from '../utils';

import useHealthPlanSetupSetupFieldsSetter from './useHealthPlanSetupFieldsSetter';
import usePlanSetupPermissions, {
  getHealthPlanFieldState,
} from './useHealthPlanSetupPermissions';
import usePlanSetupSetter from './useHealthPlanSetupSetter';

export const useCurrentHealthPlan = (isTemplateMode?: boolean) => {
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const setLoading = usePlanLoadingStore((state) => state.setLoading);
  const {
    handleSetState: handleSetFieldsState,
    handleSetSourceState: handleSetSourceFieldsState,
  } = useHealthPlanSetupSetupFieldsSetter();

  const { data, isLoading, isSuccess } = useHealthPlanByIdQuery(id,
    (healthPlanResponse) => {
      const persmissions = getHealthPlanFieldState(healthPlanResponse, isTemplateMode);
      handleSetSourceFieldsState(persmissions);
      handleSetFieldsState(persmissions);
    });

  const healthPlan = useMemo(() => data, [data]);
  const { handleSetState } = usePlanSetupSetter();
  useEffect(() => {
    setLoading(true);
  }, [isSuccess, setLoading]);
  const healthPlanStatus = useHealthPlanNameStore((state) => state.sourceState?.healthPlanStatus);

  const fieldsState = usePlanSetupPermissions(healthPlan);
  const coverageLevels = useGetFormattedCoverageLevels(healthPlan
    ? healthPlan.health_plan_deductible_configs : []);

  useEffect(() => {
    if (healthPlan) {
      const formatedPlan = getFormatedHealthPlan(healthPlan);
      handleSetState({
        ...formatedPlan,
        coverageLevels,
      });
      setLoading(false);
    }
  }, [healthPlan, handleSetState, setLoading, coverageLevels]);

  return {
    fieldsState,
    isLoading: id ? isLoading : false,
    data: healthPlan,
    planName: healthPlan?.name?.name,
    planType: healthPlan?.health_plan_types?.health_plan_types,
    deductibleServiceType: healthPlan?.deductibles_by_service_type?.deductibles_by_service_type,
    healthPlanStatus,
  };
};
