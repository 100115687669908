import React, { useCallback, useEffect, useState } from 'react';
import {
  AppButton,
  Box,
  FlexControlledForm,
  Inscription,
  Preloader,
  useDebounceState,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components/wrappers';
import { CheckDetails } from '@/modules/employer/components/Lockbox/lockbox.types';
import useVendorAddressFields, { FormValues } from '@/modules/employer/components/Lockbox/LockboxDetails/hooks/useVendorAddressFields';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import useUpdateLockboxCheckDetailsQuery from '@/modules/employer/components/Lockbox/queries/useUpdateLockboxCheckDetails.query';
import { useValidateForm } from '@/utils/hooks';

interface Props {
  onSetVisible: (value: boolean) => void;
}

interface Props {
  checkDetails?: CheckDetails;
}

const EditCheckAddressModal: React.FC<Props> = ({ checkDetails, onSetVisible }) => {
  const [formValues, setFormValues] = useState<FormValues>();
  const { values, setValues } = useDebounceState<FormValues>();
  const { editableLockbox, originalLockbox } = useLockboxDetailsStore();
  const { vendorAddressFields } = useVendorAddressFields({ checkDetails, employeeName: originalLockbox?.ocr?.employeeName });
  const { handlePreSubmit, hasErrors } = useValidateForm(vendorAddressFields);
  const {
    updateLockboxCheckDetails,
    isLoading,
  } = useUpdateLockboxCheckDetailsQuery({ lockboxId: editableLockbox?.lockboxId });

  const handleClose = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  const handleUpdateVendorAddress = useCallback(async () => {
    const isSuccessValidation = handlePreSubmit(formValues);

    if (isSuccessValidation) {
      await updateLockboxCheckDetails({
        vendor_id: checkDetails?.vendorId,
        vendor_name: formValues?.vendorName,
        benefit_of: formValues?.forBenefitOf,
        address_line_1: formValues?.line1,
        address_line_2: formValues?.line2,
        city: formValues?.city,
        state: formValues?.state,
        zip: formValues?.zipCode,
      });

      onSetVisible(false);
    }
  }, [formValues, checkDetails, handlePreSubmit, updateLockboxCheckDetails, onSetVisible]);

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  return (
    <ModalWrapper
      testId="edit-check-address"
      visible
      onSetVisible={onSetVisible}
      title="Edit Check Address"
      titleBoxStyle={{ paddingBottom: '12px' }}
    >
      <Box align="center">
        <Inscription>
          We will send declined documents to this address
        </Inscription>
      </Box>

      <Box
        margin={{ top: 'spacing24' }}
        background="module"
        pad="spacing24"
        round="moduleRound"
      >
        <Box
          elevation='default'
          background="canvas"
          round="container1Round"
        >
          <FlexControlledForm
            fields={vendorAddressFields}
            onChangeValues={setValues}
            showError={hasErrors}
            isThinMode
            isModalType
            editMode
          />
        </Box>
      </Box>

      <Box margin={{ top: 'spacing24' }} direction="row" justify="end">
        <Box>
          <AppButton
            onClick={handleClose}
            width="160px"
            buttonType="secondary"
          >
            Cancel
          </AppButton>
        </Box>
        <Box margin={{ left: 'spacing12' }}>
          <AppButton
            onClick={handleUpdateVendorAddress}
            width="160px"
          >
            {isLoading ? <Preloader color="white" /> : 'Save'}
          </AppButton>
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default EditCheckAddressModal;
