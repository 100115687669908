import { useCallback, useEffect, useState } from 'react';

import useEmployerSetupAddressModalFields from '@/modules/employer/components/EmployerSetupModal/hooks/useEmployerSetupAddressModalFields.ts';

import { useHandleExpressSetupSetUp } from '../../hooks';
import useExpressSetupSetter from '../../hooks/useExpressSetupSetter';
import useAddressStore, { AddressPayload } from '../stores/useAddress.store';

export const useEditAddressActions = (address?: AddressPayload, onToggleVision?: (value: boolean) => void, visible?: boolean) => {
  const [errorModal, setErrorModal] = useState(false);
  const handleSetState = useAddressStore((state) => state.setState);
  const showErrors = useAddressStore((state) => state.showErrors);
  const { handleReset } = useExpressSetupSetter();

  const fieldsAddress = useEmployerSetupAddressModalFields({
    defaultFields: address,
  });
  const { onSaveAddressHandler, isLoading, isAddressServerError } = useHandleExpressSetupSetUp();

  const handleOpenModal = useCallback((value: boolean) => {
    if (onToggleVision) onToggleVision(value);
    handleReset();
  }, [handleReset, onToggleVision]);

  const handleSaveAddress = useCallback(() => {
    onSaveAddressHandler(() => handleOpenModal(false));
  }, [onSaveAddressHandler, handleOpenModal]);
  
  const handleChangeValues = useCallback((values: Partial<AddressPayload>) => {
    if (values) {
      handleSetState({id: address?.id, ...values});
    }
  }, [address?.id, handleSetState]);

  useEffect(() => {
    if (isAddressServerError) setErrorModal(true);
  }, [isAddressServerError]);

  useEffect(() => () => {
    setErrorModal(false);
  }, [visible]);

  return {
    errorModal,
    setErrorModal,
    showErrors,
    fieldsAddress,
    isLoading,
    handleOpenModal,
    handleSaveAddress,
    handleChangeValues,
  };
};