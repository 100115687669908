import React from 'react';
import {
  Box,   Button, CloseIcon,
  FlexControlledForm, Text, } from '@common-fe/common-fe';

import { Option } from '@/common/types';

import usePaymentRuleFields, { PaymentRuleField } from './hooks/usePaymentRuleFields';

interface Props {
  onRemove: (id: string) => void;
  isLastItem?: boolean;
  id: string;
  index: number;
  defaultValues?: Partial<PaymentRuleField>;
  isErrorShowed?: boolean;
  onChangeValues: (value: PaymentRuleField) => void;
  paymentType?: string;
  paymentAccounts?: Option[],
  accountArray?: string[],
}
const PaymentRuleItem: React.FC<Props> = ({
  onRemove, id, index, isErrorShowed, onChangeValues, defaultValues, paymentType,
  paymentAccounts, accountArray,
}) => {
  const fields = usePaymentRuleFields({
    defaultValues,
    paymentType,
    paymentAccounts,
    accountArray,
  });
  return (
    <Box
      key={id}
      round="container1Round"
      background="canvas"
      margin={{ bottom: 's' }}
      pad={{ vertical: 'spacing24' }}
      elevation="default"
      border={{ color: 'border2', size: 'small' }}
    >
      <Box direction="row" align="center" justify="between" margin={{ horizontal: 'spacing24', bottom: 'spacing16' }}>
        <Text size="xxlarge" weight={400}>{`Payment Rule ${index + 1}`}</Text>
        <Button
          data-testid={`payment-rule-item_${id}_remove-button`}
          onClick={() => onRemove(id)}
        >
          <Box direction="row" align="center">
            <Text color="danger" size="medium" weight="normal" margin={{ right: 'spacing4' }}>
              Remove
            </Text>
            <CloseIcon size="16px" color="danger" />
          </Box>
        </Button>
      </Box>

      <FlexControlledForm
        key={id}
        isModalType
        testId={`payment-rule-item_${id}`}
        fields={fields}
        editMode
        // onDirty={setShouldConfirmOnClose}
        onChangeValues={onChangeValues}
        showError={isErrorShowed}
        wrapperStyle={{ border: 'none' }}
        formStyle={{ marginBottom: 0, marginTop: 0 }}
      />
    </Box>
  );
};
export default PaymentRuleItem;
