import React, { useCallback, useMemo } from 'react';
import {
  Box, ExpandIcon,   InfoOutlineIcon, MinimizeIcon,
  RawButton, Text, useImage,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  LARGE_SIZE_LOGO,
  LetterIconSizeEnum,
  OrganizationTypes,
} from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Icon } from '@/components/elements/Icon/Icon';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization, useHistory } from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';
import { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

const Wrapper = styled(Box)`
  border: none;
  background-color: transparent;
`;

const RightIcon = styled(ExpandIcon)``;
const LeftIcon = styled(MinimizeIcon)``;
const CompanyProfileIcon = styled(InfoOutlineIcon)``;
const LinedText = styled(Text)`
  line-height: 20px;
`;
const StyledTitleWrap = styled(Box) <{ hide: boolean }>`
  flex: 1;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  transition: opacity 0.1s;
`;
const StyledAnchor = styled(Box)`
  color: ${({ theme }) => theme.colors.textDisabled};
  line-height: 20px;
  box-shadow: none;
  ${CompanyProfileIcon} {
    fill: ${({ theme }) => theme.colors.iconSecondary};
    stroke: ${({ theme }) => theme.colors.iconSecondary};
    &:hover {
      fill: ${({ theme }) => theme.colors.button};
      stroke: ${({ theme }) => theme.colors.button};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;
export const StyledButton = styled(RawButton)`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  padding-top: 2px;
  padding-left: 2px;
  box-shadow: none !important;
  ${RightIcon}, ${LeftIcon} {
    fill: ${({ theme }) => theme.colors.iconSecondary};
    stroke: ${({ theme }) => theme.colors.iconSecondary};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentActive};
  }

  &:hover {
    ${RightIcon}, ${LeftIcon} {
      fill: ${({ theme }) => theme.colors.iconOnColor};
    }
  }
`;

interface Props {
  minify: boolean;
  onPin: (value: boolean) => void;
  onMinify: (value: boolean) => void;
  pinned: boolean;
}

const SidebarHeader: React.FC<Props> = ({
  minify, onPin, onMinify, pinned,
}) => {
  const {
    observingOrganization: {
      name, type, id,
    },
  } = useCurrentOrganization();
  const { observingOrganizationData } = useOrganizationOverridableProperties();
  const logoInfo = observingOrganizationData?.logoInfo;
  const { push } = useHistory();
  const companyProfileRedirect = useCallback(() => {
    switch (type) {
    case OrganizationTypes.subsidiary:
      push(ROUTES.SUBSIDIARY_VIEW(id));
      break;
    case OrganizationTypes.company:
      push(`${ROUTES.EMPLOYERS}/${id}`);
      break;
    case OrganizationTypes.distributor:
      push(ROUTES.DISTRIBUTORS_VIEW(id));
      break;
    case OrganizationTypes.partner:
      push(`${ROUTES.PARTNERS}/${id}`);
      break;
    case OrganizationTypes.system:
      push(`${ROUTES.SYSTEM}/${id}`);
      break;
    default:
      push(`${ROUTES.HOME}`);
      break;
    }
  }, [type, id, push]);

  const logoAction = useCallback(() => push(`${ROUTES.HOME}`), [push]);

  const onPinToggler = useCallback(() => {
    onPin(!pinned);
    if (pinned) {
      onMinify(!minify);
    }
  }, [minify, onMinify, onPin, pinned]);

  const minimizedLogoUrl = useMemo(() => {
    if (whiteLabelingConfig?.key === THEMES.apprize || whiteLabelingConfig?.key === THEMES.rmr) {
      return logoInfo?.darkMinimizedLogoUrl;
    }

    return logoInfo?.minimizedLogoUrl;
  }, [logoInfo]);

  const largeLogoUrl = useMemo(() => {
    if (whiteLabelingConfig?.key === THEMES.apprize || whiteLabelingConfig?.key === THEMES.rmr) {
      return logoInfo?.darkLargeLogoUrl;
    }

    return logoInfo?.largeLogoUrl;
  }, [logoInfo]);

  const { isError: isLogoError } = useImage(largeLogoUrl);

  const logoIsDisplayed = useMemo(() => (!isLogoError && largeLogoUrl),
    [isLogoError, largeLogoUrl]);

  return (
    <Wrapper
      data-testId="sidebar-header"
      direction="row"
      pad={{ left: minify ? '22px' : '20px', vertical: 'spacing16', right: 'spacing8' }}
      align="center"
      justify="between"
    >
      <Box
        direction="row"
        align="center"
        data-testid="large-logo"
      >
        <Box style={{ maxWidth: LARGE_SIZE_LOGO.width }} onClick={logoAction}>
          {
            minify ? (
              <Icon
                testId="sidebar-header-minify-icon"
                size={LetterIconSizeEnum.LOGO}
                type={type}
                text={name}
                sizePx="40"
                src={minimizedLogoUrl}
              />
            ) : (
              <Icon
                testId="sidebar-header-large-icon"
                size={LetterIconSizeEnum.LOGO}
                type={type}
                text={name}
                height={LARGE_SIZE_LOGO.height}
                width="100%"
                src={largeLogoUrl}
              />
            )
          }
        </Box>
        <StyledTitleWrap
          data-testid="logo-actions"
          hide={minify}
          margin={{ left: 'spacing12' }}
        >
          {!logoIsDisplayed && <LinedText wordBreak="break-word" weight="bold" color="navigationTextDefault">{name}</LinedText>}
          <Access
            accessRequirements={[
              { permission: Permissions.VIEW_ER },
            ]}
          >
            <StyledAnchor data-testId="company-profile-link-wrapper" onClick={companyProfileRedirect}>
              {
                logoIsDisplayed ? (
                  <CompanyProfileIcon />
                ) : (
                  <LinedText data-testId="company-profile-link" wordBreak="break-word" weight={500} size="small">Company Profile</LinedText>
                )
              }
            </StyledAnchor>
          </Access>
        </StyledTitleWrap>
      </Box>
      {
        !minify
        && (
          <Box direction="row">
            <StyledButton
              onClick={onPinToggler}
              data-testid="sidebar_pin_button"
            >
              {pinned ? <LeftIcon /> : <RightIcon />}
            </StyledButton>
          </Box>
        )
      }
    </Wrapper>
  );
};

export default SidebarHeader;
