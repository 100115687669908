import { useMemo } from 'react';
// import { HEALTH_PLAN_MODULES } from '@/modules/HealthPlan/HealthPlan.constants';
// import { useBasicPropertiesFields } from '../BasicProperties/hooks';

export default () => {
  // const basicPropertiesFields = useBasicPropertiesFields();
  const fields = useMemo(() => {
    const modules: string[] = [];
    // if (!basicPropertiesFields.length) {
    //   modules.push(HEALTH_PLAN_MODULES.BASIC_PROPERTIES);
    // }

    return modules;
  }, []);
  return fields;
};
