import React, { useState } from 'react';
import { Box, ThreeDotIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import { DropButton } from '@/components/elements/DropButton';

import { MakeContributionModal } from '../ContributionModals/MakeContributionModal';

interface StyledButtonProps {
    isWarning?: boolean;
  }

export const StyledButton = styled(Box)<StyledButtonProps>`
  cursor: pointer;
  min-height: 32px;
  justify-content: center;
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  ${({ theme }) => theme.fonts.text14Regular};
  padding-inline: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xxxs};
  color: ${({ theme, isWarning }) => (isWarning ? `${theme.colors.danger} !important` : '')};
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: ${({ theme, isWarning }) => (isWarning ? theme.colors.danger : theme.colors.textAccent)};
    background-color: ${({ theme, isWarning }) => (isWarning ? theme.colors.statusContainerColor.deniedErrorExpiredContainer : theme.colors.accentContainer)};
    transition: all 0.2s ease-in-out;
  }
`;

interface Props {
  contributionId: string;
}

export const ContributionListItemButton:React.FC<Props> = ({ contributionId }) => {
  const [isMakeContributionModalVisible, setIsMakeContributionModalVisible] = useState(false);

  return (
    <>
      <Box
        justify="center"
        data-testid="ContributionListItemButton_container"
      >
        <DropButton
          contentStyle={{
            top: '30px',
            left: '-180px',
            minWidth: '184px',
            maxWidth: '184px',
            padding: '12px',
            zIndex: 100,
            maxHeight: 'fit-content',
            minHeight: 'fit-content',
            ...isMakeContributionModalVisible ? { display: 'none' } : {},
          }}
          buttonView={(
            <ThreeDotIcon color="textBody" />
          )}
        >
          <Box>
            <StyledButton
              onClick={() => setIsMakeContributionModalVisible(true)}
            >
              Create from template
            </StyledButton>
          </Box>
        </DropButton>
      </Box>

      {isMakeContributionModalVisible ? (
        <MakeContributionModal
          contributionId={contributionId}
          visible={isMakeContributionModalVisible}
          onSetVisible={setIsMakeContributionModalVisible}
        />
      ) : null}
    </>
  );
};
