import React, { useCallback, useMemo } from 'react';
import { Box,Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { PermissionKey } from '@/common/constants';
import { ClaimsSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

import { DEFAULT_VALUES } from './hooks/useClaimsSettingsForm';

interface ResetButtonProps {
  isDefaultValuesEstablished: boolean;
  isDisabled?: boolean;
}

export const StyledResetButton = styled(Box)<ResetButtonProps>`
  color: ${({ theme }) => theme.colors.textDisabled};
  &:hover {
  ${({ isDefaultValuesEstablished, theme, isDisabled }) => {
    if (!isDefaultValuesEstablished && !isDisabled) return { color: theme.colors.button };
    return null;
  }}
  }
`;

interface Props {
  viewMode: boolean;
  currentValues: ClaimsSettings;
  setCurrentValues: (defaultValue: ClaimsSettings) => void;
}

const ResetButton: React.FC<Props> = ({ viewMode, setCurrentValues, currentValues }) => {
  const claimsAssignmentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CLAIMS_ASSIGNMENT_NUMBER,
  );
  const isDisabled = useMemo(() => claimsAssignmentPermProps.disabled, [claimsAssignmentPermProps]);
  const isDefaultValuesEstablished = useMemo(() => currentValues.claimsAssignmentNumber === DEFAULT_VALUES.claimsAssignmentNumber, [currentValues]);

  const onReset = useCallback(() => {
    if (isDefaultValuesEstablished) return;
    setCurrentValues(DEFAULT_VALUES);
  }, [setCurrentValues, isDefaultValuesEstablished]);

  if (viewMode) return null;

  return (
    <StyledResetButton
      data-testId="reset-button"
      isDefaultValuesEstablished={isDefaultValuesEstablished}
      isDisabled={isDisabled}
      {...isDisabled ? {} : { onClick: onReset }}
    >
      <Text>Reset to Default</Text>
    </StyledResetButton>
  );
};

export default ResetButton;
