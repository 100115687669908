import React, { useMemo, useRef } from 'react';
import { useParams,useRouteMatch } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import ROUTES from '@/common/routes';
import { Accordion } from '@/components/elements/Accordion';
import { TOCSidebar } from '@/modules/core/components/TOCSidebar/TOCSidebar';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import {
  SETUP_CONTAINER_UI_ID,
  useDetectActiveTOCModule,
} from '@/modules/core/hooks/useDetectActiveTOCModule';
import { SetupEmployer } from '@/modules/employer/components';
import OrganizationLockNotification from '@/modules/employer/components/SetupEmployer/OrganizationLockNotification';
import { useTOCConfiguration } from '@/modules/employer/hooks/useTOCConfiguration';
import useGetOrgLocksQuery from '@/modules/employer/queries/useGetOrgLocks.query';
import theme from '@/styles/theme';

const StyledContent = styled(Box)`
  flex: 1;
  align-items: center;
`;

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1001;
`;

const EmployerSetupPage = () => {
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const isPartnerPage = useMemo(() => path === ROUTES.PARTNERS_CREATE
  || path === ROUTES.PARTNERS_EDIT()
  || path === ROUTES.PARTNERS_VIEW(), [path]);
  const config = useTOCConfiguration({
    isPartner: isPartnerPage,
  });
  const ref = useRef<HTMLDivElement>(null);
  const topbarHeight = useMemo(() => ref.current?.getBoundingClientRect().height,
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref?.current]);

  const {
    scrollToSection, onScrollHandler, activeSection, activeModule, hideHeader,
  } = useDetectActiveTOCModule(config, topbarHeight);
  const { formattedData: orgLocksData } = useGetOrgLocksQuery(id);
  return (
    <Box direction="row" overflow="hidden" flex={{ grow: 1 }}>
      <TOCSidebar
        stepsMap={config}
        scrollToSection={scrollToSection}
        activeSection={activeSection}
        activeModule={activeModule}
        isPartnerMode={isPartnerPage}
      />
      <Box
        overflow="auto"
        width="100%"
        onScroll={onScrollHandler}
        id={SETUP_CONTAINER_UI_ID}
      >

        <FixedWrapper
          ref={ref}
          height={{ min: 'fit-content' }}
          background={{ color: 'canvas' }}
        >
          <Topbar hideHeader={hideHeader} />
          <Box pad={{ horizontal: '40px' }}>
            <Box width={theme.defaultContentWidth} alignSelf="center">
              {orgLocksData?.isLocked && (
                <OrganizationLockNotification
                  date={orgLocksData.updatedDate}
                  isLocked={orgLocksData.isLocked}
                  employerId={id}
                />
              )}
              <Accordion />
            </Box>
          </Box>
        </FixedWrapper>

        <Box pad={{ horizontal: '40px' }}>
          <StyledContent>
            <Box width={theme.defaultContentWidth}>
              <SetupEmployer isLocked={orgLocksData?.isLocked} />
            </Box>
          </StyledContent>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployerSetupPage;
