import React, { useMemo } from 'react';
import {
  Box,
  dateValidatorByFormat,
  Field,
  FieldTypes,
  Inscription,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DATE_INVALID_TEXT, DATE_ONLY_FUTURE_TEXT, DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { isDateInFuture } from '@/utils';

export enum CancelationRules {
  NO_CHANGES = 'NO_CHANGES',
  CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS = 'CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS',
}

const viewModeNodes = {
  [CancelationRules.NO_CHANGES]: (
    <Box>
      <Box width={{ max: '282px' }}>
        <Inscription
          color="textBody"
          weight={700}
          style={{ display: 'block', whiteSpace: 'normal'}}
        >
          No changes
        </Inscription>
      </Box>
      <Box>
        <Inscription color="textBody">
          Use the current end of coverage and last day to submit claims of each plan for the termination date provided.
        </Inscription>
      </Box>
    </Box>
  ),
  [CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS]: (
    <Box>
      <Box width={{ max: '282px' }}>
        <Inscription
          color="textBody"
          weight={700}
          style={{ display: 'block', whiteSpace: 'normal'}}
        >
          Change end of coverage and last day to submit claims
        </Inscription>
      </Box>
      <Box>
        <Inscription color="textBody">
          Update the end of coverage and last day to submit claims for all plans. Each plan will have the same selections applied.
        </Inscription>
      </Box>
    </Box>
  ),
};

const options = [{
  value: CancelationRules.NO_CHANGES,
  label: (
    <Box margin={{ left: 'spacing4' }} width={{ max: '282px' }}>
      <Inscription
        color="textBody"
        weight={700}
        style={{ display: 'block', whiteSpace: 'normal'}}
      >
        No changes
      </Inscription>
    </Box>
  
  ),
  hintText: (
    <Box margin={{ left: '36px' }}>
      <Inscription color="textBody">
        Use the current end of coverage and last day to submit claims of each plan for the termination date provided.
      </Inscription>
    </Box>
  ),
}, {
  value: CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS,
  label: (
    <Box margin={{ left: 'spacing4' }} width={{ max: '282px' }}>
      <Inscription
        color="textBody"
        weight={700}
        style={{ display: 'block', whiteSpace: 'normal'}}
      >
        Change end of coverage and last day to submit claims
      </Inscription>
    </Box>
  
  ),
  hintText: (
    <Box margin={{ left: '36px' }}>
      <Inscription color="textBody">
        Update the end of coverage and last day to submit claims for all plans. Each plan will have the same selections applied.
      </Inscription>
    </Box>
  ),
}];

export const useEmployerTerminationParamsFields = (
  cancelationRules?: string,
  terminationDate?: string,
  defaultEndOfCoverage?: string | null,
  defaultLastDayToSubmitClaim?: string | null,
  viewMode?: boolean,
) => {
  const cancelationRulesDefaultValue = useMemo(() => {
    if (defaultEndOfCoverage || defaultLastDayToSubmitClaim) {
      return CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS;
    }
    return CancelationRules.NO_CHANGES;
  }, [defaultEndOfCoverage, defaultLastDayToSubmitClaim]);

  const fields = useMemo(() => [
    {
      name: 'cancelationRules',
      type: viewMode ? FieldTypes.Node : FieldTypes.Radio,
      defaultValue: cancelationRulesDefaultValue,
      value: viewMode ? viewModeNodes[cancelationRulesDefaultValue] : cancelationRulesDefaultValue,
      options,
      label: viewMode ? (
        <Inscription weight={400} color="textBody">
          Cancellation rules
        </Inscription>
      ) :
        'Cancellation rules',
      expandItems: true,
      showRequireIcon: !viewMode,
      style: { marginBottom: '-4px' },
    },
    ...cancelationRules === CancelationRules.NO_CHANGES
      || (viewMode && cancelationRulesDefaultValue === CancelationRules.NO_CHANGES)  ? [] : [
        {
          name: 'endOfCoverage',
          type: FieldTypes.Date,
          defaultValue: defaultEndOfCoverage || terminationDate || '',
          label: (
            <Box
              style={{
                position: 'relative',
                bottom: '52px',
                left: '256px',
              }}
            >
              <Inscription
                color="textBody"
                weight={400}
                lineHeight='20px'
              >
                End of coverage
              </Inscription>
            </Box>
        
          ),
          placeholder: 'Select date',
          isManualDateInput: true,
          controlStyles: { width: '160px' },
          style: { height: '72px', alignItems: 'flex-end'},
          inputWrapCssClass: 'small-input',
          disabled: viewMode,
          minDate: dayjs(terminationDate),
          validator: yup.string()
            .test({
              test: (val) => val
                ? isDateInFuture(val) || val === terminationDate
                : true,
              message: DATE_ONLY_FUTURE_TEXT,
            })
            .test({
              test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
              message: DATE_INVALID_TEXT,
            }).required(REQUIRED_TEXT),
        },
        {
          name: 'lastDayToSubmitClaim',
          type: FieldTypes.Date,
          disabled: viewMode,
          defaultValue: defaultLastDayToSubmitClaim || terminationDate || '',
          label: (
            <Box
              style={{
                position: 'relative',
                bottom: '52px',
                left: '256px',
              }}
            >
              <Inscription
                color="textBody"
                weight={400}
                lineHeight='20px'
              >
                Last day to submit claims
              </Inscription>
            </Box>
        
          ),
          placeholder: 'Select date',
          isManualDateInput: true,
          // showRequireIcon: true,
          controlStyles: { width: '160px' },
          style: { height: '72px', alignItems: 'flex-end'},
          inputWrapCssClass: 'small-input',
          minDate: dayjs(terminationDate),
          validator: yup.string()
            .test({
              test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
              message: DATE_INVALID_TEXT,
            }).required(REQUIRED_TEXT),
        },
      ],
  ], [
    cancelationRules,
    terminationDate,
    cancelationRulesDefaultValue,
    defaultEndOfCoverage,
    defaultLastDayToSubmitClaim,
    viewMode,
  ]);

  return fields as Field[];
};
