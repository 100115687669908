import { api } from '@/api';

export default (path: string, name: string) => {
  const handleDownload = async () => {
    await api.get(path, {
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    });
  };
  return handleDownload;
};
