import { storeCreator } from '@/utils/store/store.utils';

export interface CurrentValues {
  active: boolean;
  expired?: boolean;
}

interface State {
  currentValues: CurrentValues;
  setCurrentValues: (value: CurrentValues) => void;
}

export const useDisplayEntitiesPopupStore = storeCreator<State>((set) => ({
  currentValues: {
    active: true,
    expired: false,
  },

  setCurrentValues: (value: CurrentValues) => set((state) => ({
    ...state,
    currentValues: value,
  })),
}));
