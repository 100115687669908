import React from '@common-fe/common-fe';
import { Inscription, WarningCard, WarningIcon } from '@common-fe/common-fe';

const FailedToScanDocumentWarning = () => (
  <WarningCard
    icon={<WarningIcon color="danger" />}
    backgroundColor="dangerContainer"
    borderColor="dangerBorder"
    topBorderColor="transparent"
    title={(
      <Inscription color="textBody" weight={700}>
        Failed to scan document
      </Inscription>
    )}
    containerProps={{
      pad: {
        horizontal: 'spacing24',
        vertical: 'spacing16',
      },
    }}
    border={false}
  />
);

export default FailedToScanDocumentWarning;
