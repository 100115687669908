import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { StatementSettings } from '@/modules/employer/components/SetupEmployer/Communication/Communication.types';
import { useStatementsSettingsStore } from '@/modules/employer/components/SetupEmployer/Communication/stores';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import { useStatementsSettingsFields } from '../hooks';

interface Props {
  onDirty?: (isDirty: boolean) => void;
  isPartnerPage?: boolean;
}

const StatementsSettings: React.FC<Props> = ({ onDirty, isPartnerPage }) => {
  const { viewMode } = useSetupEmployerMode();
  const { formattedData, isLoading } = useGetEmployer();
  const { values, setValues } = useDebounceState<StatementSettings>();
  const fields = useStatementsSettingsFields(formattedData);
  const handleSetValues = useStatementsSettingsStore((state) => state.handleSetValues);

  useEffect(() => {
    if (values) {
      handleSetValues(values);
    }
  }, [values, handleSetValues]);

  if (isLoading) return <FormLoader testId="statements-form" />;

  return (
    <Box
      background="canvas"
      round="container1Round"
      margin={{ top: isPartnerPage ? 'spacing24' : '0' }}
      id="statements"
      data-testId="statements-settings"
    >
      <Box>
        <FlexControlledForm
          formTitle="Statements"
          fields={fields}
          onDirty={onDirty}
          onChangeValues={setValues}
          editMode={!viewMode}
          isThinMode
        />
      </Box>
    </Box>
  );
};

export default StatementsSettings;
