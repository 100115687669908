import React, { useMemo } from 'react';
import { combineClassNames,ListItem, ListItemField, } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import {
  ListItemType,
} from '@/common/types';
import NotVisibleICount from '@/components/elements/NotVisibleICount';
import { useCurrentOrganization } from '@/modules/core/hooks';

import { CopaysListPaginationParams } from '../../CopayAdjudication.constant';
import { useCopayGroupsQuery } from '../../queries';
import { useHighlightItemStore } from '../../stores';
import CopayName from '../CopayName';

const EMPTY_FIELD_SIGN = '—';

export default (params: CopaysListPaginationParams, onOpenCopay: (id: string) => void) => {
  const {
    options, isLoading, total,
  } = useCopayGroupsQuery(params);
  const highlightedId = useHighlightItemStore((state) => state.highlightedId);
  const {
    observingOrganization: {
      type,
    },

  } = useCurrentOrganization();
  // const isSystemLevel = useMemo(() => type === OrganizationTypes.system,
  // [type]);
  const formattedList = useMemo<ListItem[]>(
    () => options.map(
      (item) => {
        let isDisabledOptions = false;

        if (type === OrganizationTypes.partner
           && item.organizationType === OrganizationTypes.system) {
          isDisabledOptions = true;
        }
        if (type === OrganizationTypes.distributor
          && (item.organizationType === OrganizationTypes.system
            || item.organizationType === OrganizationTypes.partner)) {
          isDisabledOptions = true;
        }

        let disabledOptionHint: string | undefined;
        if (isDisabledOptions) {
          disabledOptionHint = 'You can’t edit or delete items linked to other organizations';
        }
        if (item.isExpired) {
          disabledOptionHint = 'You can\'t edit or delete expired copay groups';
        }
        const handleOpenCopay = () => {
          onOpenCopay(`${item.key}`);
        };
        return ({
          id: item.key,
          className: combineClassNames([
            item.key === highlightedId ? 'highlight-item' : '',
            item.isExpired ? 'is-expired' : '']),
          disabledOptionHint,

          fields: [
            {
              key: 'name',
              type: ListItemType.Node,
              flex: 2,
              onClick: handleOpenCopay,
              node: (
                <CopayName
                  testId={`copay_${item.key}`}
                  isEmployer={item.organizationType === OrganizationTypes.employer}
                  onClick={handleOpenCopay}
                  text={item.value || EMPTY_FIELD_SIGN}
                  organizationType={item.organizationType || ''}
                />
              ),
            },
            {
              key: 'copay',
              flex: 6,
              type: ListItemType.Node,
              title: `${item.validFrom || EMPTY_FIELD_SIGN}`,
              node: (
                <NotVisibleICount
                  onClick={handleOpenCopay}
                  key={item.copays.length}
                  options={item.copays}
                />
              ),
            },
            {
              key: 'validFrom',
              flex: 1.25,
              type: ListItemType.Text,
              title: `${item.validFrom || EMPTY_FIELD_SIGN}`,
            },
            {
              key: 'validTo',
              flex: 1.25,
              type: ListItemType.Text,
              title: `${item.validTo || EMPTY_FIELD_SIGN}`,
            },

          ] as ListItemField[],
        });
      },
    ), [highlightedId, onOpenCopay, options, type],
  );

  const isEmpty = useMemo(() => !total && !isLoading, [isLoading, total]);

  return {
    rows: formattedList,
    isLoading,
    total,
    isEmpty,
  };
  // const formatedList = useMemo<ListItem[]>(() => d)
};
