import { api } from '@/api';
import { PATHS } from '@/common';

import { ContributionActivityStatus } from '../types';

interface Props {
  contributionId?: string;
  statuses?: ContributionActivityStatus[];
  taxYears?: string[];
  planCodes?: string[];
}

const useExportContributionActivityReportQuery = () => (props: Props) => api.get(PATHS.GET_CONTRIBUTION_ACTIVITIES_CSV_REPORT, {
  params: {
    ...props?.contributionId ? { contribution_id: props?.contributionId } : {},
    ...props?.statuses?.length ? { statuses: props?.statuses.join(',') } : {},
    ...props?.taxYears?.length ? { tax_years: props?.taxYears.join(',') } : {},
    ...props?.planCodes?.length ? { plan_codes: props?.planCodes.join(',') } : {},
  },
});

export default useExportContributionActivityReportQuery;
