import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';

import { PermissionKey } from '@/common/constants';
import { StatementFrequencyType,StatementType } from '@/modules/employer/components/SetupEmployer/Communication/Communication.types';
import { EmployerDto } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

const useStatementsSettingsFields = (data?: EmployerDto) => {
  const statementFrequencyTypePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.STATEMENT_FREQUENCY_TYPE,
  );
  const statementTypePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.STATEMENT_TYPE,
  );
  const statementType = useMemo(
    () => data?.overridableProperties?.statementType,
    [data],
  );
  const statementFrequencyType = useMemo(
    () => data?.overridableProperties?.statementFrequencyType,
    [data],
  );

  const fields = useMemo(() => [
    {
      name: 'statementType',
      type: FieldTypes.Radio,
      label: 'Create Statements',
      options: [
        { label: 'Paper and Electronic', value: StatementType.PAPER_AND_ELECTRONIC },
        { label: 'Electronic only', value: StatementType.ELECTRONIC_ONLY },
      ],
      defaultValue: statementType,
      ...statementTypePermProps,
    },
    {
      name: 'statementFrequencyType',
      type: FieldTypes.Radio,
      label: 'Frequency',
      options: [
        { label: 'Monthly', value: StatementFrequencyType.MONTHLY },
        { label: 'Quarterly', value: StatementFrequencyType.QUARTERLY },
      ],
      defaultValue: statementFrequencyType,
      ...statementFrequencyTypePermProps,
    },
  ], [
    statementType,
    statementFrequencyType,
    statementFrequencyTypePermProps,
    statementTypePermProps,
  ]);

  return fields as Field[];
};

export default useStatementsSettingsFields;
