import { useMemo } from 'react';

import { useEnrollmentMatrixFields } from '../EnrollmentMatrix/hooks';

export default (isActiveMode?: boolean, isTemplateMode?: boolean) => {
  const enrollmentMatrixFields = useEnrollmentMatrixFields(
    isActiveMode,
    isTemplateMode,
  );

  const fields = useMemo(() => ([
    ...enrollmentMatrixFields,
  ]), [enrollmentMatrixFields]);
  return fields;
};
