import { HealthPlanFieldState } from '@/modules/HealthPlan/HealthPlan.types';
import { baseStoreCreator } from '@/utils/store';

export interface HealthPlanNameFieldsStatePayload {
  healthPlanName: HealthPlanFieldState;
  healthPlanStatus: HealthPlanFieldState;
  healthPlanType: HealthPlanFieldState;
  carrierName: HealthPlanFieldState;
  carrierId: HealthPlanFieldState;
  autocreateCdhEnrollment: HealthPlanFieldState;
  parentTemplate: HealthPlanFieldState;
  planCode: HealthPlanFieldState;
  plans: HealthPlanFieldState;
}

export const DEFAULT_VALUES: HealthPlanNameFieldsStatePayload = {
  healthPlanName: HealthPlanFieldState.Modifiable,
  healthPlanStatus: HealthPlanFieldState.Modifiable,
  healthPlanType: HealthPlanFieldState.Modifiable,
  carrierName: HealthPlanFieldState.Modifiable,
  carrierId: HealthPlanFieldState.Locked,
  autocreateCdhEnrollment: HealthPlanFieldState.Modifiable,
  parentTemplate: HealthPlanFieldState.Locked,
  planCode: HealthPlanFieldState.Modifiable,
  plans: HealthPlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<HealthPlanNameFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
