import { useCallback } from 'react';

import { storeCreator } from '@/utils/store/store.utils';

export enum GlobalLoaderMessages {
  PARTNER_INITIAL_CREATION = 'Please wait while we process Partner information…',
  DISTRIBUTOR_INITIAL_CREATION = 'Please wait while we process Distributor information…',
  EMPLOYER_INITIAL_CREATION = 'Please wait while we process Employer information…',
  SUBSIDIARY_INITIAL_CREATION = 'Please wait while we process Subsidiary information…',

  PARTNER_UPDATING = 'Please wait while we update Partner information…',
  DISTRIBUTOR_UPDATING = 'Please wait while we update Distributor information…',
  EMPLOYER_UPDATING = 'Please wait while we update Employer information…',
  SUBSIDIARY_UPDATING = 'Please wait while we update Subsidiary information…',
  EMPLOYEE_UPDATING = 'Please wait while we update Employee information…',

  PARTNER_ACTIVATING = 'Please wait while we activate Partner…',
  DISTRIBUTOR_ACTIVATING = 'Please wait while we activate Distributor…',
  EMPLOYER_ACTIVATING = 'Please wait while we activate Employer…',
  SUBSIDIARY_ACTIVATING = 'Please wait while we activate Subsidiary…',

  PARTNER_UPDATING_ACTIVATED_ORGANIZATION = 'Please wait while we update the active Partner organization…',
  DISTRIBUTOR_UPDATING_ACTIVATED_ORGANIZATION = 'Please wait while we update the active Distributor organization…',
  EMPLOYER_UPDATING_ACTIVATED_ORGANIZATION = 'Please wait while we update the active Employer organization…',
  SUBSIDIARY_UPDATING_ACTIVATED_ORGANIZATION = 'Please wait while we update the active Subsidiary organization…',

  CREATE_PLAN = 'Please wait while we create Plan…',
  SAVE_PLAN_TO_DRAFT = 'Please wait while we save Plan to draft…',
  SUBMIT_PLAN_TO_REVIEW = 'Please wait while we submit Plan to review…',
  ACTIVATE_PLAN = 'Please wait while we update Plan…',
  UPDATE_ACTIVE_PLAN = 'Please wait while we update active Plan…',

}

export interface LoaderPayload {
  message: GlobalLoaderMessages;
}

interface State {
  loader: LoaderPayload | null;
  setLoader: (loader: LoaderPayload) => void;
  removeLoading: () => void;
}

const useStore = storeCreator<State>((set) => ({
  loader: null,
  setLoader: (loader: LoaderPayload) => set(() => ({ loader })),
  removeLoading: () => set(() => ({
    loader: null,
  })),
}));

export const useGlobalLoaderStore = () => {
  const handleRemoveLoader = useStore((state) => state.removeLoading);
  const setLoader = useStore((state) => state.setLoader);
  const loader = useStore((state) => state.loader);

  const removeLoading = useCallback(async (delay?: number) => {
    if (delay) {
      return new Promise((resolve) => {
        setTimeout(() => {
          handleRemoveLoader();
          resolve(true);
        }, delay);
      });
    }
    return handleRemoveLoader();
  }, [handleRemoveLoader]);

  return {
    loader,
    setLoader,
    removeLoading,
  };
};
