import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { OptionKey } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { api, mock } from '@/api';
import { PATHS } from '@/common';
import { DEFAULT_DATE_FORMAT, RAW_DATE_FORMAT } from '@/common/constants';
import { Pagination } from '@/common/types';

// import { TaxType } from '@/modules/employer/components/Contributions/contributions.types';
import { RecordPayload } from '../../ScheduledContribution.types';

export const QUERY_KEY = 'GET_CONTRIBUTION_RECORDS';
interface ResponsePlanPayload {
  plan_id: number;
  plan_code: string;
  employee_id?: number;
  per_pay_employee: number;
  per_pay_employer: number;
  // tax_type: string;
}

interface ResponsePayload {
  employee_id: number;

  first_name: string;
  last_name: string;
  plans: ResponsePlanPayload[];
}

interface Params {
  calendarId: string;
  searchString?: string;
  currentAccounts?: OptionKey[];
  page?: number;
  perPage?: number;
  startDate?: string | null;
  sortBy?: OptionKey;
}
export default ({ calendarId, startDate, sortBy, searchString, page, perPage, currentAccounts }: Params) => {
  const customParams = useMemo(() => {
    const params = new URLSearchParams();
    currentAccounts?.forEach((item) => {
      params.append('plan_codes', item as string);
    });

    params.append('page', `${page || 0}`);
    if (startDate) {
      params.append('start_date', dayjs(startDate, DEFAULT_DATE_FORMAT).format(RAW_DATE_FORMAT));
    }
    if (perPage) {
      params.append('perPage', `${perPage}`);
    }
    if (searchString) {
      params.append('name', `${searchString}`);
    }
    if (sortBy) {
      params.append('sort', `person.firstName,${sortBy}`);
    }
    return params;
  }, [currentAccounts, page, perPage, searchString, sortBy, startDate]);
  
  const { data, isLoading, isSuccess } = useQuery(
    [QUERY_KEY, calendarId, searchString, sortBy, page, perPage, currentAccounts],
    () =>
      api.get<Pagination<ResponsePayload>>(PATHS.SCHEDULED_CONTRIBUTION_RECORDS_SUMMARY(calendarId), {
        params: customParams,
      
      }),
    {
      retry: 0,
      enabled: !!calendarId,
    }
  );

  const formattedData = useMemo<RecordPayload[]>(() => {
    const list = data?.data.content || [];
    return list.map((item) => ({
      id: `${item.employee_id}`,
      name: `${item.first_name} ${item.last_name}`,
      accounts: item.plans.map((plan) => ({
        id: `${plan.plan_id}`,
        name: plan.plan_code,
        employerAmount: plan.per_pay_employer,
        employeeAmount: plan.per_pay_employee,
        // taxType: plan,
      })),
    }));
  }, [data?.data]);

  const total = useMemo(() => {
    return data?.data?.totalElements || 0;
  }, [data?.data?.totalElements]);
  return {
    formattedData,
    total,
    data,
    isLoading,
    isSuccess,
  };
};
