import { useCallback,useMemo, useState } from 'react';
import { capitalizeFirstLetter,ListItem, ListItemField } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { useHasAccess, useOrganizationDeactivateQuery } from '@/modules/core/hooks';
import { EmployerStatus } from '@/modules/employer/employer.constants';

const REQUIRED_PERMISSIONS = [{ permission: Permissions.EDIT_ER_DATA }];
const DEACTIVATED_STATUS_TITLE = capitalizeFirstLetter(EmployerStatus.Terminated);
const changeFildsStatus = (fields: ListItemField[]) => fields.map((field) => {
  if (field.key === 'status') {
    return {
      ...field,
      title: DEACTIVATED_STATUS_TITLE,
    };
  }
  return field;
});

export default (currentFields: ListItem[]) => {
  const [deactivatedItems, setDeactivatedItems] = useState<string[]>([]);
  const [deactivationItem, setDeactivationItem] = useState('');
  const formatedRows = useMemo(
    () => currentFields
      .map((item) => {
        if (deactivatedItems.some((id) => item.id === id)) {
          return {
            ...item,
            fields: changeFildsStatus(item.fields),
          };
        }

        return item;
      }),
    [currentFields, deactivatedItems],
  );

  const deactivationItemTitle = useMemo(
    () => formatedRows
      .find((row) => row.id === deactivationItem)
      ?.fields.find((field) => field.key === 'logo')?.value,
    [formatedRows, deactivationItem],
  );
  const onSuccessDeactivation = useCallback(() => {
    setDeactivatedItems([
      ...deactivatedItems,
      deactivationItem,
    ]);
  }, [deactivatedItems, deactivationItem]);
  const handleDeactivateItem = useOrganizationDeactivateQuery(onSuccessDeactivation);
  const hasAccess = useHasAccess(REQUIRED_PERMISSIONS);
  const deactivateOption = useMemo(() => {
    if (!hasAccess) {
      return null;
    }

    return {
      name: 'Deactivate',
      isWarning: true,
      onClick: (id: string) => setDeactivationItem(id),
      isAvailable: (fields: ListItemField[]) => fields
        .find((field) => field.key === 'status')?.title.toUpperCase() === EmployerStatus.Pending,
    };
  }, [hasAccess]);
  const handleResetDeactivationItem = useCallback(() => {
    setDeactivationItem('');
  }, []);
  return {
    onSuccessDeactivation,
    formatedRows,
    setDeactivationItem,
    deactivatedItems,
    deactivateOption,
    handleDeactivateItem,
    deactivationItem,
    deactivationItemTitle,
    handleResetDeactivationItem,
  };
};
