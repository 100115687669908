import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { EmployeeContributionView, EmployeeContributionViewPayload } from '../contributions.types';

interface ResponseData {
  content?: EmployeeContributionViewPayload[];
  totalPages?: number;
  totalElements?: number;
  size?: number;
  number?: number;
  empty?: boolean;
}

interface Props {
  payrolls?: string[];
  contributionRequestId?: string;
  plansIds?: string[];
  organizationIds?: string[];
  employeeIds?: string[];
  enabled?: boolean;
  size?: number;
}

const getEmployeeContributionView = async ({
  contributionRequestId,
  plansIds,
  payrolls,
  organizationIds,
  employeeIds,
  size,
}: Props) => {
  return api.get<ResponseData>(PATHS.GET_CONTRIBUTIONS_EMPLOYEE_VIEW, {
    params: {
      ...contributionRequestId ? {
        template_contribution_id: contributionRequestId,
      } : {},
      ...payrolls && payrolls.length ? { payroll_group_codes: payrolls.join(',') } : {},
      ...plansIds && plansIds.length ? { plan_ids: plansIds.join(',') } : {},
      ...organizationIds && organizationIds.length ? { organization_ids: organizationIds.join(',') } : {},
      ...employeeIds && employeeIds.length ? { employee_ids: employeeIds.join(',') } : {},
      ...size ? { size } : {},
    },
  });
};

export const QUERY_KEY = 'useGetEmployeesContributionViewQueryKey';
export const useGetEmployeesContributionViewQuery = ({
  plansIds,
  payrolls,
  organizationIds,
  employeeIds,
  enabled = true,
  size,
}: Props) => {
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY, size],
    () => getEmployeeContributionView({ plansIds, payrolls, organizationIds, employeeIds, size }),
    {
      enabled,
    },
  );

  const formattedData: EmployeeContributionView[] = useMemo(() => {
    if (!data?.data?.content) return [];

    return data.data.content.map((item) => ({
      employeeId: _.toString(item?.employee_id),
      isNewEmployee: item?.is_new_employee,
      employeeTerminationDate: item?.employee_termination_date,
      firstName: item?.first_name,
      lastName: item?.last_name,
      birthOn: item?.birth_on ? dayjs(item?.birth_on).format('MM/DD/YYYY') : '',
      isSubsidiary: item?.is_subsidiary,
      organizationName: item?.organization_name,
      plans: item?.plans?.map((plan) => ({
        accountType: plan?.account_type,
        planId: _.toString(plan?.plan_id),
        planCode: plan?.plan_code,
        isPlanDisabled: plan?.is_plan_disabled,
        coveragePeriodEnd: plan?.coverage_period_end,
        coveragePeriodStart: plan?.coverage_period_start,
      })) || [],
    }));
  }, [data]);

  const fetchDataManually = useCallback(async (contributionRequestId?: string) => {
    return await getEmployeeContributionView({ plansIds, payrolls, organizationIds, employeeIds, size, contributionRequestId });
  }, [employeeIds, organizationIds, plansIds, payrolls, size]);

  return {
    isLoading,
    data: data?.data,
    formattedData,
    isSuccess,
    fetchDataManually,
  };
};
