import { PlanStatuses } from '@/modules/plan/plan.types';
import { storeCreator } from '@/utils/store/store.utils';

export interface State {
  activeSection?: PlanStatuses;
  setActiveSection: (value?: PlanStatuses) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  activeSection: undefined,
  setActiveSection: (value?: PlanStatuses) => set(() => ({
    activeSection: value,
  })),
  handleReset: () => set(() => ({
    activeSection: undefined,
  })),
}));

export default useStore;
