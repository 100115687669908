import React, { useCallback, useEffect, useMemo, useRef,useState } from 'react';
import {
  Box,
  CrossIcon,
  formatSnakeCaseToGeneral,
  Hint,
  Inscription,
  PlusIcon,
  Preloader,
  SearchInput,
} from '@common-fe/common-fe';
import { toNumber } from 'lodash';
import styled from 'styled-components';

import InfoTile from '@/components/elements/InfoTile/InfoTile';
import { useSnackbar } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user/stores';

import { ClaimsProcessor } from './ClaimsProcessors/ClaimsProcessors.types';
import useGetTeamsProcessorsQuery from './ClaimsProcessors/useGetTeamsProcessors.query';
import { formatClaimId } from './hooks/useClaimsList';
import useAssignProcessorQuery from './queries/useAssignProcessor.query';
import useUnassignProcessorQuery from './queries/useUnassignProcessor.query';
import { ClaimDto } from './types/claims.constants';
import MoovingWrapper from './MoovingWrapper';

const MIN_SEARCH_LENGTH = 3;
const HINT_CLASS_NAME = 'limited-claims-hint';
const ASSIGN_CLASS_NAME = 'info-tile-assign';

const CancelAssignmentWrapper = styled(Box)`
  :hover span {
    color: ${({ theme }) => theme.colors.danger};
  }
`;

const getSelectedTeams = (selectedTeams?: Array<string[]>) => {
  if (!selectedTeams?.length) return { assignedClaimsTeamIds: [] };
  if (selectedTeams.length === 1) return { assignedClaimsTeamIds: selectedTeams?.flat() };

  const uniqueIds = [...new Set(selectedTeams.flat())];
  const idsPresentedInAllArrays = uniqueIds
    .filter(
      (uniqueId) => selectedTeams
        .filter(
          (ids) => ids.some((id) => id === uniqueId),
        )?.length === selectedTeams.length,
    );

  return {
    assignedClaimsTeamIds: idsPresentedInAllArrays,
    isLimited: uniqueIds.length !== idsPresentedInAllArrays.length,
  };
};

const InfoTilesWrapper = styled(Box)`
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
  > div:hover {
    cursor: pointer;
    outline: 2px solid ${({ theme }) => theme.colors.border};
  }
`;
const InfoTileWrapper = styled(Box)`
  .${ASSIGN_CLASS_NAME} {
    display: none;
  }
  &:hover {
    .${ASSIGN_CLASS_NAME} {
      display: flex;
    }
  }
`;
const HintWrapper = styled(Box)`
  width: 12px;
  height: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const HintMessage = () => {
  useEffect(() => {
    const style = document?.querySelector(`span[class*=${HINT_CLASS_NAME}]`)?.parentElement?.parentElement?.style;
    if (style) {
      style.zIndex = '2001';
    }
  }, []);
  return (
    <Inscription size="12px" className={HINT_CLASS_NAME} weight={500} lineHeight="18px">
      You’ve selected multiple claims that belong to different claims teams. The list of claims processors is limited to those who are members of selected claims teams.
    </Inscription>
  );
};

const DEFAULT_PAGINATION = { page: 0, perPage: 20 };

interface Props {
  selectedClaims: ClaimDto[];
  onClose: () => void;
}

const AssignClaimsProcessorsPopup: React.FC<Props> = ({ selectedClaims, onClose }) => {
  const areAllClaimsAssigned = useMemo(() => !selectedClaims.some((item) => !item?.assignee), [selectedClaims]);
  const [isAssigning, setIsAssigning] = useState(false);
  const { isLimited } = useMemo(() => getSelectedTeams(selectedClaims?.map((claim) => claim?.claimsTeams?.map((team) => team?.id || '') || [])), [selectedClaims]);
  const { contactId } = useAuthStore();
  const [search, setSearch] = useState('');
  const searchString = useMemo(() => search.length < MIN_SEARCH_LENGTH ? '' : search, [search]);
  const { claimsProcessors, isLoading } = useGetTeamsProcessorsQuery({
    ...DEFAULT_PAGINATION,
    searchString,
    selectedClaims,
  });
  const [assignedProcessor, setAssignedProcessor] = useState<ClaimsProcessor | undefined>();

  const searchRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (searchRef?.current) {
      searchRef.current.focus();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchRef.current, selectedClaims]);

  const { handleAddPermanentSnackbar } = useSnackbar();
  const showSnackbar = useCallback(() => {
    handleAddPermanentSnackbar({
      text: `${selectedClaims?.length === 1 ? `Claim #${formatClaimId(selectedClaims[0].id)} is` : 'Multiple claims are'} assigned to ${assignedProcessor?.fullName}.`,
      closeIcon: true,
    });
  }, [selectedClaims, assignedProcessor, handleAddPermanentSnackbar]);
  const assignProcessorToClaims = useAssignProcessorQuery(showSnackbar);
  const unassignProcessorToClaims = useUnassignProcessorQuery();
  const assign = useCallback(async () => {
    setIsAssigning(true);
    await assignProcessorToClaims({
      processorId: assignedProcessor?.id,
      selectedClaims: selectedClaims?.map((claim) => toNumber(claim?.id)),
    });
    onClose();
  }, [assignProcessorToClaims, selectedClaims, onClose, assignedProcessor]);
  const unassign = useCallback(async () => {
    setIsAssigning(true);
    await unassignProcessorToClaims({
      selectedClaims: selectedClaims?.map((item) => toNumber(item?.id)),
    });
    onClose();
  }, [selectedClaims, onClose, unassignProcessorToClaims]);
  useEffect(() => {
    if (assignedProcessor) {
      assign();
      setAssignedProcessor(undefined);
    }
  }, [assignedProcessor, assign]);

  return (
    <MoovingWrapper>
      <Box>
        <Box flex="grow" border={{ side: 'bottom', color: 'border2' }}>
          <Box direction="row" align="center" justify="between" pad={{ horizontal: 'spacing12' }}>
            <Inscription color="textBody" weight="bold" lineHeight="20px">Selected claims: {selectedClaims.length}</Inscription>
            <Box data-testId="close_assign_popup" onClick={onClose} margin={{ right: 'spacing4' }}>
              <CrossIcon size="12px" color="textBody" />
            </Box>
          </Box>

          <Box pad="spacing12">
            <SearchInput inputRef={searchRef} onChange={setSearch} value={search} placeholder="Search for claims processor" />
          </Box>
          {isLimited ? (
            <Box
              height="34px"
              round="4px"
              border={{ size: '1px', color: 'warningBorder' }}
              margin={{ bottom: 'spacing16', horizontal: 'spacing12' }}
              pad={{ horizontal: 'spacing12' }}
              background="warningContainer"
            >
              <Box
                direction="row"
                justify="between"
                align="center"
                border={{ side: 'top', size: '2px', color: 'warning' }}
                height="100%"
              >
                <Inscription
                  size="small"
                  lineHeight="18px"
                  weight={500}
                >
                  The list of claims processors is limited.
                </Inscription>
                <HintWrapper>
                  <Hint
                    hintAlign="vertical"
                  >
                    <HintMessage />
                  </Hint>
                </HintWrapper>
              </Box>
            </Box>
          ) : null}
        </Box>

        <Box overflow={{ vertical: 'auto' }} background="module" round={{ size: '8px', corner: 'bottom' }} height={{ min: '200px' }}>
          <Box direction="row" justify="between" height={{ min: '20px' }} margin={{ horizontal: 'spacing12', top: 'spacing16', bottom: 'spacing12' }}>
            <Inscription size="small" weight={500} color="textSecondary">Claims processors: {claimsProcessors?.length || 0}</Inscription>
            {areAllClaimsAssigned ? (
              <CancelAssignmentWrapper onClick={unassign}>
                <Inscription size="small" weight={500} color="textSecondary">Cancel Assignment</Inscription>
              </CancelAssignmentWrapper>
            ) : null}
          </Box>
          {isLoading || isAssigning ? <Box justify="center" align="center" height="100%"><Preloader /></Box> : (
            <InfoTilesWrapper flex="grow" direction="column" pad={{ bottom: 'spacing12', horizontal: 'spacing12' }}>
              {claimsProcessors?.map((item) => (
                <InfoTileWrapper
                  key={item.id}
                  background="canvas"
                  pad={{ horizontal: 'spacing16' }}
                  round="xsmall"
                  border={{ color: 'border2' }}
                  style={{ textTransform: 'capitalize' }}
                  height={{ min: '64px' }}
                  justify="center"
                  onClick={() => setAssignedProcessor(item)}
                >
                  <InfoTile
                    id={item.id}
                    title={item.fullName}
                    descriptions={[formatSnakeCaseToGeneral(item?.role || '')]}
                    isMe={contactId === item.id}
                    InjectedElement={(
                      <Box
                        className={ASSIGN_CLASS_NAME}
                        background="accentContainer"
                        align="center"
                        justify="center"
                        round="50%"
                        width={{ min: '28px' }}
                        height={{ min: '28px' }}
                        margin="spacing4"
                      >
                        <PlusIcon size="12px" color="info" />
                      </Box>
                    )}
                  />
                </InfoTileWrapper>
              ))}
            </InfoTilesWrapper>
          )}
        </Box>
      </Box>
    </MoovingWrapper>
  );
};

export default AssignClaimsProcessorsPopup;
