import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FlexList } from '@common-fe/common-fe';

import { ListItemBase } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import { useListPagination } from '@/modules/employer/hooks';
import { useActivityTransactionList } from '@/modules/employer/queries/useAccountsTransactions.query';

const HEADERS: ListItemBase[] = [
  {
    key: 'closeDate',
    title: 'Close Date',
    width: 30,
  },
  {
    key: 'ourBalance',
    title: 'Our Balance',
    width: 10,
  },
  {
    key: 'bankBalance',
    title: 'Bank Balance',
  },
  {
    key: 'difference',
    title: 'Difference',
    width: 10,
  },
  {
    key: 'prior',
    title: 'Prior',
    width: 10,
  },
  {
    key: 'delta',
    title: 'Delta',
    width: 10,
  },
  {
    key: 'credit',
    title: 'Credit',
    width: 10,
  },
  {
    key: 'debit',
    title: 'Debit',
    width: 10,
  },
];

export const AccountActivityDetailsList = () => {
  const { id } = useParams<{ id: string }>();
  const { queryMap } = useHistory();
  const closeDate = useMemo(
    () => queryMap.transactionsCloseDate,
    [queryMap],
  );
  const {
    page,
    perPage,
  } = useListPagination();
  const { accountInfo } = useActivityTransactionList(
    id,
    {
      closeDate,
      page: page - 1,
      perPage,
    }, false,
  );
  return (
    <Box
      data-testid="ReconciliationListOfBankAccounts"
      background={{ color: 'module' }}
      round="container1Round"
    >
      <Box overflow={{ horizontal: 'auto' }}>
        <Box>
          <FlexList
            headers={HEADERS}
            rows={accountInfo}
            total={accountInfo.length}
            footer={null}
          />
        </Box>
      </Box>
    </Box>
  );
};
