import React, { useEffect, useMemo, useState } from 'react';
import { Box, DateInput } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const DATE_LENGTH = 10;
const EnrollmentFilter: React.FC<Props> = ({ value, onChange }) => {
  const [date, setDate] = useState(value || '');
  useEffect(() => {
    if (date.length === DATE_LENGTH) {
      const isValid = dayjs(date, DEFAULT_DATE_FORMAT).isValid();
      if (isValid) {
        onChange(date);
      } else {
        onChange('');
      }
    } else {
      onChange('');
    }
  }, [date, onChange]);
  const yesterday = useMemo(() => dayjs().add(-1, 'day'), []);
  return (
    <Box margin={{ left: 's' }}>
      <DateInput
        isPicker
        minDate={yesterday}
        testId="enrollment-filter"
        placeholder="Enrollment date"
        onChange={setDate}
        value={date}
        // maxDate={maxDate}
        levelling={{
          top: '100%',
          left: '0',
        }}
      />
    </Box>
  );
};
export default EnrollmentFilter;
