import React, { useCallback, useState } from 'react';
import {
  AppButton,
  Box,
  Text,
} from '@common-fe/common-fe';

import { EmployerLockUnlockModal } from '../../EmployerLockUnlockModal';

interface Props {
  isLocked?: boolean;
  employerName?: string;
  employerId?: string;
}

const OrganisationLock: React.FC<Props> = ({ isLocked, employerName, employerId }) => {
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const handleCloseLockModal = useCallback(() => setShowLockModal(false), [setShowLockModal]);
  return (
    <>
      <Box
        data-testId="technical-settings-wrapper"
        direction="row"
        background="canvas"
        round="container1Round"
        border={{ color: 'border2', size: 'small' }}
        pad="l"
        justify="between"
      >
        <Box>
          <Text weight="bold">Lock Organization</Text>
          <Text size='small' color="textSecondary">By locking this employer, payments from any employer funded accounts will not be processed.</Text>
        </Box>
        <AppButton
          width='113px'
          buttonType="secondary"
          onClick={() => setShowLockModal(true)}
        >
          {isLocked ? 'Unlock' : 'Lock'}
        </AppButton>
      </Box>
      <EmployerLockUnlockModal
        visible={showLockModal}
        employerId={employerId}
        employerName={employerName}
        isLockMode={!isLocked}
        onSetVisible={handleCloseLockModal}
      />
    </>
  );
};

export default OrganisationLock;
