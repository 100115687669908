import { useState } from 'react';

import { ManualContributionFormValues } from '../contributions.types';

export const useManualContribution = () => {
  const [formValues, setFormValues] = useState<ManualContributionFormValues>({});
  const [infoSetup, setInfoSetup] = useState(false);

  return {
    formValues,
    setFormValues,
    infoSetup,
    setInfoSetup,
  };
};
