import { baseStoreCreator } from '@/utils/store';

export interface PlanYearPayload {
  planStartDate: string;
  planEndDate: string;
}


export const DEFAULT_VALUES: PlanYearPayload = {
  planStartDate: '',
  planEndDate: '',
};

export const usePlanYearStore = baseStoreCreator<PlanYearPayload>(DEFAULT_VALUES);

export default usePlanYearStore;
