import { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { usePayrollCalendarDatesStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/stores';
const STEP = 6;
const MIN_MONTHS = 18;
export const groupDatesByMonth = (dates: string[], step: number, startDate?: string) => {
  const uniqueMonths = new Set<string>();

  dates.forEach(date => {
    const monthYear = dayjs(date).startOf('month').format('YYYY-MM');
    uniqueMonths.add(monthYear);
  });

  let months = uniqueMonths.size;

  if (startDate
    && dayjs(startDate).isBefore(dayjs(dates[0]), 'month')) {
    months += 1;
  }

  const monthCount = (months < MIN_MONTHS ? MIN_MONTHS : months) - 1;
  const maxStep = Math.floor(monthCount / step);

  const today = dayjs().startOf('month');
  let currentMonthStep = 0;

  for (let i = 0; i <= maxStep; i++) {
    const startMonth = dayjs(dates[0]).startOf('month').add(i * step, 'month');
    const endMonth = startMonth.add(step, 'month');

    if (today.isSame(startMonth) || (today.isAfter(startMonth) && today.isBefore(endMonth))) {
      currentMonthStep = i + 1;
      break;
    }
  }

  return {
    monthCount,
    maxStep,
    currentMonthPage: currentMonthStep - 1,
  };
};

export const usePayrollCalendarDates = () => {
  const [page, setPage] = useState(0);
  const dates = usePayrollCalendarDatesStore((state) => state.dates);
  const handleSetDates = usePayrollCalendarDatesStore((state) => state.handleSetDates);
  const initialMonthDate = usePayrollCalendarDatesStore((state) => state.initialMonthDate);
  const minDate = usePayrollCalendarDatesStore((state) => state.minDate);

  const { monthCount, maxStep, currentMonthPage } = groupDatesByMonth(dates, STEP, initialMonthDate);

  const onPickDate = useCallback((date: Dayjs) => {
    const preparedDate = date.format('YYYY-MM-DD') as string;
    if (dates.includes(preparedDate)) {
      handleSetDates(dates.filter((d) => d !== preparedDate));
    } else {
      const preparedSortedDates = [...dates, preparedDate].sort((a, b) => dayjs(a).diff(dayjs(b)));
      handleSetDates(preparedSortedDates);
    }
  }, [dates, handleSetDates]);

  const onPickDates = useCallback((newDates: Dayjs[]) => {
    const filteredDates = newDates.filter((date) => date.isValid());
    const preparedDates = filteredDates.map((date) => date.format('YYYY-MM-DD') as string);
    const datesToAdd = dates.filter((date) => !preparedDates.includes(date));
    const preparedSortedDates = [...datesToAdd, ...preparedDates].sort((a, b) => dayjs(a).diff(dayjs(b)));
    handleSetDates(preparedSortedDates);
    
  }, [dates, handleSetDates]);

  useEffect(() => {
    if (currentMonthPage !== 0) {
      setPage(currentMonthPage);
    }
  }, [currentMonthPage]);

  return {
    dates,
    onPickDate,
    onPickDates,
    page,
    setPage,
    initialMonthDate,
    minDate,
    maxStep,
    monthCount,
  };
};
