import React from 'react';
import { AccordionItem, Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const AccordionItemWrapper = styled(Box)`
  min-height: 24px;

  svg[aria-label="Chevron Up"], svg[aria-label="Chevron Down"] {
    fill: ${({ theme }) => theme.colors.iconPrimary};
  }
`;

const Wrapper: React.FC<{
  children: React.ReactNode,
  currentStatus: React.ReactNode,
}> = ({ children, currentStatus }) => (
  <Box
    data-testId="employment-status-timeline-wrapper"
    background="canvas"
    round="container1Round"
    margin={{ top: 'spacing24' }}
    pad="spacing24"
  >
    <AccordionItemWrapper>
      <AccordionItem
        testId="employment-status-timeline"
        text={(
          <Box
            margin={{ left: 'xs' }}
            direction="row"
            justify="between"
            align="center"
            width="100%"
          >
            <Inscription
              color="textBody"
              size="20px"
              lineHeight="28px"
              style={{ fontWeight: 400 }}
            >
              Employment Status Timeline
            </Inscription>
            {currentStatus}
          </Box>
        )}
        isLeftArrow
      >
        <Box margin={{ top: '22px' }}>{children}</Box>
      </AccordionItem>
    </AccordionItemWrapper>
  </Box>
);

export default Wrapper;
