/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { usePlanErrorsStore } from '../stores';

import { useHealthPlanActivateValidate } from './useHealthPlanActivateValidate';
import { useHealthPlanSaveValidate } from './useHealthPlanSaveValidate';

export default (isTemplateMode?: boolean) => {
  const {
    errorMessages, successMessages,
    pendingMessages,
  } = useHealthPlanSaveValidate(isTemplateMode);

  const {
    errorMessages: activateErrorMessages,
    successMessages: activeSuccessMessages,
    pendingMessages: activePendingMessages,
  } = useHealthPlanActivateValidate(isTemplateMode);
  const handleSetSaveSuccess = usePlanErrorsStore((state) => state.setSaveSuccess);
  const handleSetSaveErrors = usePlanErrorsStore((state) => state.setSaveErrors);
  const handleSetSavePendings = usePlanErrorsStore((state) => state.setSavePendings);

  const handleSetActiveSuccess = usePlanErrorsStore((state) => state.setActiveSuccess);
  const handleSetActiveErrors = usePlanErrorsStore((state) => state.setActiveErrors);
  const handleSetActivePendings = usePlanErrorsStore((state) => state.setActivePendings);

  useEffect(() => {
    handleSetSaveErrors(errorMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(errorMessages)]);
  useEffect(() => {
    handleSetSaveSuccess(successMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(successMessages)]);
  useEffect(() => {
    handleSetSavePendings(pendingMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(pendingMessages)]);

  useEffect(() => {
    handleSetActiveSuccess(activeSuccessMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(activeSuccessMessages)]);
  useEffect(() => {
    handleSetActiveErrors(activateErrorMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(activateErrorMessages)]);
  useEffect(() => {
    handleSetActivePendings(activePendingMessages);
    // JSON.stringify is used as an optimization
  }, [JSON.stringify(activePendingMessages)]);
};
