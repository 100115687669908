import React, { useMemo } from 'react';
import {
  Box,
  CalendarPickerIcon,
  Inscription,
  RoundCloseIcon,
  RoundDoneIcon,
  RoundPendingIcon,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { EmploymentStatus, OrganizationStatus } from '@/modules/employee/Employee/Employee.types';
import currentTheme from '@/styles/theme';

import { getBorderColor,getColor, getTitle } from './utils';

const DateFromTo: React.FC<{ date?: string, isDateTo?: boolean }> = ({ date, isDateTo }) => {
  const isForever = useMemo(() => {
    if (isDateTo) {
      return dayjs(date).isAfter(dayjs(`01/01/${dayjs().year() + 100}`));
    }

    return false;
  }, [isDateTo, date]);

  return (
    <Inscription size="16px" lineHeight="22px" style={{ fontWeight: 400 }}>
      {isForever ? 'Forever' : dayjs(date).format(DEFAULT_DATE_FORMAT)}
    </Inscription>
  );
};

const Wrapper = styled(Box)<{ borderColor?: string }>`
  border: 2px solid ${({ theme }) => theme.colors.canvas};
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.03);
  ${({ borderColor }) => {
    if (borderColor) return `border: 2px solid ${borderColor}`;
    return '';
  }};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacings.spacing8};
  }
`;

const Icon: React.FC<{ type?: OrganizationStatus }> = ({ type }) => {
  const color = useMemo(() => getColor(type), [type]);

  if (type === OrganizationStatus.terminated) {
    return (<RoundCloseIcon color={color} />);
  }

  if (type === OrganizationStatus.leaveOfAbsence) {
    return (<RoundPendingIcon size="24px" color={color} />);
  }

  if (type === OrganizationStatus.active) {
    return (<RoundDoneIcon size="24px" color={color} />);
  }

  if (type === OrganizationStatus.retired) {
    return (<RoundCloseIcon color={color} />);
  }

  if (type === OrganizationStatus.future) {
    return (<RoundPendingIcon size="24px" color={color} />);
  }

  if (type === OrganizationStatus.cobra) {
    return (<RoundPendingIcon size="24px" color={color} />);
  }

  return null;
};

const EmploymentStatuses: React.FC<{
  statuses: EmploymentStatus[],
}> = ({ statuses }) => (
  <Box
    data-testId="employment-statuses"
    background="module"
    pad="spacing24"
    round="small"
  >
    {statuses.map((status) => (
      <Wrapper
        key={status.validFrom}
        height="70px"
        background="canvas"
        round="small"
        pad="22px"
        direction="row"
        {...status.isActive ? { borderColor: getBorderColor(status.status) } : {}}
      >
        <Box height="24px" direction="row" align="center">
          <Box width="180px" direction="row">
            {status.isActive ? <Box margin={{ right: 'spacing8' }}><Icon type={status.status} /></Box> : null}
            <Inscription
              size="16px"
              lineHeight="22px"
              style={{ fontWeight: status.isActive ? 700 : 400 }}
            >
              {getTitle(status.status)}
            </Inscription>
          </Box>
          <Box direction="row" align="center" height="24px">
            <CalendarPickerIcon style={{ marginRight: currentTheme.spacings.spacing8 }} />
            <DateFromTo date={status.validFrom} />
            &nbsp;-&nbsp;
            <DateFromTo date={status.validTo} isDateTo />
          </Box>
        </Box>
      </Wrapper>
    ))}
  </Box>
);

export default EmploymentStatuses;
