import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { BOOLEAN_STRINGS, REQUIRED_TEXT } from '@/common/constants';
import { aiAdjudicationType } from '@/modules/plan/plan.types';
import { useAccountFundingParamsStore } from '@/modules/plan/PlanSetup/AccountFunding/AccountFundingParams';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { OmnibusKeys } from '@/modules/plan/PlanSetup/PlanSetup.constants';
import { PLAN_SETUP_FIELD } from '@/modules/plan/PlanSetup/PlanSetup.constants';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import { useIRSMaximumQuery } from '../queries';
import { useIRSMaximumTemplateTypesQuery } from '../queries';
import { useAdditionalStore } from '../stores';

const VALIDATOR = yup.string().nullable();
const REQUIRED_VALIDATOR = yup.string().trim().nullable().required(REQUIRED_TEXT);
const ELECTION_AMOUNT_TEXT = 'Maximum election amount should be bigger than Minimum election amount';

const CENTLESS_KEY = '.00';
const IRS_MAXIMUM_KEY = 'IRS_LIMIT';
const CUSTOM_KEY = 'CUSTOM';
const ALLOW_INTESTMENTS_KEY = 'allowInvestments';
const TRUE_KEY = 'true';
const ELECTION_BASED_KEY = 'ELECTION_BASED';
const GREATER_TEXT = 'The value must be greater than 0';

const CURRENCY_KEY = '$';
const parseCost = (value = '') => {
  const cost = parseFloat(value.replace(CURRENCY_KEY, ''));
  if (_.isNaN(cost)) {
    return 0;
  }
  return cost;
};

const SPEND_LIMIT_IRS = 'IRS_LIMIT';
export default (
  isActivationMode?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
  isPlanActive?: boolean,
) => {
  const errorFields = useAdditionalStore((state) => state.errorFields);
  const sourceState = useAdditionalStore((state) => state.sourceState);
  const currentState = useAdditionalStore((state) => state.state);

  const isDisplayedPendContribution = useAdditionalStore((state) => state.state.CIPRequired);

  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);

  const isReplenished = useAccountFundingParamsStore((state) => state.state.isReplenished);
  const omnibus = useAccountFundingParamsStore((state) => state.state.omnibus);

  const isHSAOmnibus = useMemo(() => omnibus === OmnibusKeys.HSA, [omnibus]);
  // const allowInvestments = useAdditionalStore((state) => state.state.allowInvestments);
  // It's needed for sync checkboxes
  // @ts-ignore

  const { data: planIRSOptions } = useIRSMaximumQuery(isTemplateMode);
  const { data: templateIRSOptions } = useIRSMaximumTemplateTypesQuery(isTemplateMode);

  const IRSOptions = useMemo(() => {
    if (isTemplateMode) {
      return templateIRSOptions;
    }
    return planIRSOptions;
  }, [isTemplateMode, planIRSOptions, templateIRSOptions]);

  const onlyActivationRequired = useMemo(
    () => (isActivationMode ? REQUIRED_VALIDATOR : VALIDATOR), [isActivationMode],
  );
  const fields: Field[] = useMemo(() => [

    {
      name: 'availableBalance',
      type: FieldTypes.Radio,
      showRequireIcon: true,
      label: 'Available balance',
      options: [
        {
          label: 'Actual balance',
          value: 'ACTUAL_BALANCE',
        },
        {
          label: 'Election based',
          value: ELECTION_BASED_KEY,
        },
        {
          label: 'Max eligible amount based on plan rules',
          value: 'MAX_ELIGIBLE_AMOUNT',
        },
      ],
      validator:

        isReplenished
          ? onlyActivationRequired
          : onlyActivationRequired.test({
            test: (val) => val !== ELECTION_BASED_KEY,
            message: 'Election based option cannot be set if replenishment is not allowed',
          }).nullable(),
    },

    {
      name: 'maximumElectionAmount',
      type: FieldTypes.Radio,
      expandItems: true,
      showRequireIcon: true,
      label: 'Maximum election amount',
      options: [
        {
          label: 'Unlimited',
          value: 'UNLIMITED',
        },
        {
          label: 'IRS limit',
          value: IRS_MAXIMUM_KEY,
        },
        {
          label: 'Custom',
          value: CUSTOM_KEY,
        },
        // {
        //   label: 'Comute',
        //   value: 'maximumElectionAmount.commute',
        // },
      ],
      validator: onlyActivationRequired,
      subFields: [
        {
          name: 'maximumElectionAmountCustom',
          parentValue: CUSTOM_KEY,
          type: FieldTypes.Currency,
          value: '',
          label: '',
          placeholder: 'Enter $ amount',
          validator: yup.string().when('maximumElectionAmount', {
            is: (val: string) => val === CUSTOM_KEY,
            then: REQUIRED_VALIDATOR,
            otherwise: VALIDATOR,
          }).test({
            exclusive: false,
            test(val) {
              const { minimumElectionAmountCustom, maximumElectionAmount } = this.parent;
              if (maximumElectionAmount !== CUSTOM_KEY) {
                return true;
              }
              if (!val || !_.toNumber(minimumElectionAmountCustom)) {
                if (_.toNumber(minimumElectionAmountCustom) !== 0 && _.toNumber(val) !== 0) {
                  return true;
                }
              }
              return _.toNumber(minimumElectionAmountCustom) < _.toNumber(val);
            },
            message: ELECTION_AMOUNT_TEXT,
          }),
        },
        {
          name: 'maximumElectionAmountIRS',
          parentValue: IRS_MAXIMUM_KEY,
          type: FieldTypes.Dropdown,
          singleMode: true,
          placeholder: 'Select max value',
          options: IRSOptions,
          validator: yup.string().when('maximumElectionAmount', {
            is: (val: string) => val === IRS_MAXIMUM_KEY,
            then: REQUIRED_VALIDATOR,
            otherwise: yup.string().nullable(),
          }),
          label: '',
        },
      ],
    },
    {
      name: 'minimumElectionAmount',
      type: FieldTypes.Radio,
      showRequireIcon: true,
      label: 'Minimum election amount',
      options: [
        {
          label: 'Zero',
          value: 'ZERO',
        },
        {
          label: 'Custom',
          value: CUSTOM_KEY,
        },
      ],
      validator: isActivationMode ? REQUIRED_VALIDATOR : VALIDATOR,
      subFields: [
        {
          name: 'minimumElectionAmountCustom',
          parentValue: CUSTOM_KEY,
          type: FieldTypes.Currency,
          value: '',
          label: '',
          placeholder: 'Enter $ amount',
          validator:
            yup
              .string()
              .when('minimumElectionAmount', {
                is: (val: string) => val === CUSTOM_KEY,
                then: yup.string().required(REQUIRED_TEXT),
                otherwise: yup.string().nullable(),
              })
              .test({
                exclusive: false,
                test(val) {
                  const {
                    maximumElectionAmountCustom,
                    maximumElectionAmount,
                    minimumElectionAmount,
                    maximumElectionAmountIRS,
                  } = this.parent;

                  if (minimumElectionAmount !== CUSTOM_KEY) {
                    return true;
                  }
                  if (maximumElectionAmount === IRS_MAXIMUM_KEY) {
                    const foundMax = IRSOptions.find(
                      (option) => option.key === maximumElectionAmountIRS,
                    );
                    if (foundMax && foundMax.additionalValue) {
                      return (foundMax.additionalValue as number) > _.toNumber(val);
                    }
                  }
                  if (!_.toNumber(maximumElectionAmountCustom) || !val) {
                    if (_.toNumber(maximumElectionAmountCustom) !== 0 && _.toNumber(val) !== 0) {
                      return true;
                    }
                  }
                  return _.toNumber(maximumElectionAmountCustom) > _.toNumber(val);
                },
                message: ELECTION_AMOUNT_TEXT,
              })
              .test({
                test:
                  (val) => {
                    const isErrorLess = !errorFields?.minimumElectionAmountCustom?.value;
                    if (val) {
                      const formatedValue = val.replace(CENTLESS_KEY, '');
                      const errorValue = `${errorFields?.minimumElectionAmountCustom?.value || ''}`.replace(CENTLESS_KEY, '');
                      return isErrorLess || formatedValue !== errorValue;
                    }
                    return true;
                  },
                message: errorFields?.minimumElectionAmountCustom?.message || 'Invalid field',
              })
              .nullable(),
        },
      ],
    },
    {
      name: 'proofOfExpense',
      type: FieldTypes.Dropdown,
      singleMode: true,
      placeholder: 'Select Proof of expense',
      options: [
        { key: 'RECEIPT_REQUIRED', value: 'Merchant/Provider receipt required' },
        { key: 'NO_RECEIPT_REQUIRED', value: 'No receipt required' },
      ],
      validator: VALIDATOR,
      label: 'Proof of expense',
    },
    {
      name: 'aiAdjudicationType',
      type: FieldTypes.Radio,
      label: 'AI adjudication',
      options: [
        {
          label: 'Enabled for this plan',
          value: aiAdjudicationType.ENABLED,
        },
        {
          label: 'Disabled for this plan',
          value: aiAdjudicationType.DISABLED,
        },
        {
          label: 'Use organization settings',
          value: aiAdjudicationType.ORG_SETTINGS,
        },
      ],
    },

    // new field

    {
      name: PLAN_SETUP_FIELD.SPEND_LIMIT,
      type: FieldTypes.Radio,
      expandItems: true,
      disabled: isPlanActive && appliedSourceState?.spendLimit === CUSTOM_KEY,
      label: 'Spend limit',
      options: [
        {
          label: 'Unlimited',
          value: 'UNLIMITED',
        },
        {
          label: 'IRS limit',
          value: SPEND_LIMIT_IRS,
        },
        {
          label: 'Custom',
          value: CUSTOM_KEY,
          isDisabled: isPlanActive,
        },
      ],
      validator: VALIDATOR,
      subFields: [
        {
          name: PLAN_SETUP_FIELD.SPEND_LIMIT_IRS_AMOUNT,
          parentValue: SPEND_LIMIT_IRS,
          type: FieldTypes.Dropdown,
          singleMode: true,
          placeholder: 'Select IRS limit',
          options: IRSOptions,
          validator: yup.string().when(PLAN_SETUP_FIELD.SPEND_LIMIT, {
            is: (val: string) => val === SPEND_LIMIT_IRS,
            then: VALIDATORS.REQUIRED_STRING,
            otherwise: VALIDATORS.STRING,
          }),
          label: '',
        },
        {
          name: PLAN_SETUP_FIELD.SPEND_LIMIT_CUSTOM,
          parentValue: CUSTOM_KEY,
          type: FieldTypes.Currency,
          value: '',
          label: '',
          placeholder: 'Enter $ amount',
          disabled: isPlanActive,
          validator: yup.string().when(PLAN_SETUP_FIELD.SPEND_LIMIT, {
            is: (val: string) => val === CUSTOM_KEY,
            then: VALIDATORS.REQUIRED_STRING,
            otherwise: VALIDATORS.STRING,
          }),
        },
      ],
    },

    {
      name: PLAN_SETUP_FIELD.SPEND_PERIOD,
      type: FieldTypes.Radio,
      label: 'Spend period',
      options: [
        {
          label: 'Unlimited',
          value: 'UNLIMITED',
        },
        {
          label: 'Monthly',
          value: 'MONTHLY',
        },
        {
          label: 'Plan year',
          value: 'PLAN_YEAR',
        },
      ],
      validator: VALIDATOR,
    },
    {
      name: 'isOrphaned',
      type: FieldTypes.Radio,
      label: 'Is orphaned',
      showRequireIcon: true,
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },

      ],
      validator: onlyActivationRequired,
    },
    //
    {
      name: ALLOW_INTESTMENTS_KEY,
      type: FieldTypes.Radio,
      label: 'Allow investments',
      options: [
        {
          label: 'Yes',
          value: TRUE_KEY,
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
      validator: yup.string().test({
        test(val) {
          if (val === BOOLEAN_STRINGS.TRUE && !isHSAOmnibus) {
            return false;
          }
          return true;
        },
        message: 'Make omnibus HSA to support investments',
      }),
      subFields: [
        {
          name: 'catchUpElections',
          showRequireIcon: true,
          parentValue: 'true',
          type: FieldTypes.Currency,
          label: 'Catch-Up elections',
          placeholder: 'Enter $ amount',
          validator: yup.string().trim().when(ALLOW_INTESTMENTS_KEY, {
            is: (val: string) => val === TRUE_KEY,
            then: yup.string().required(REQUIRED_TEXT).test({
              test: (val) => parseCost(val) !== 0,
              message: GREATER_TEXT,
            }),
            otherwise: VALIDATOR,
          }),
        },
        {
          name: 'catchUpAge',
          showRequireIcon: true,
          parentValue: 'true',
          type: FieldTypes.Number,
          label: 'Catch-Up age',
          placeholder: 'Enter age',
          validator: yup.string().trim().when(ALLOW_INTESTMENTS_KEY, {
            is: (val: string) => val === TRUE_KEY,
            then: yup.string().required(REQUIRED_TEXT).test({
              test: (val) => _.toNumber(val) !== 0,
              message: GREATER_TEXT,
            }),
            otherwise: VALIDATOR,
          }),
        },
        {
          name: 'investmentOptionsType',
          showRequireIcon: true,
          parentValue: 'true',
          type: FieldTypes.CheckBoxGroup,
          label: 'Investment options',
          placeholder: ' ',
          defaultValue: [],
          options: [
            { key: 'MANAGED', value: 'Managed portfolio' },
            { key: 'SELF_DIRECTED', value: 'Self directed' },
            { key: 'BROKERAGE', value: 'Brokerage' },
          ],
          validator: yup.array().nullable().when(ALLOW_INTESTMENTS_KEY, {
            is: (val: string) => val === TRUE_KEY,
            then: yup.array().nullable().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
            otherwise: yup.array().nullable(),
          }),
        },
        {
          name: 'investmentThreshold',
          showRequireIcon: true,
          parentValue: 'true',
          type: FieldTypes.Currency,
          label: 'Investment threshold',
          placeholder: 'Enter $ amount',
          validator: yup.string().trim().when(ALLOW_INTESTMENTS_KEY, {
            is: (val: string) => val === TRUE_KEY,
            then: yup.string().required(REQUIRED_TEXT).test({
              test: (val) => parseCost(val) !== 0,
              message: GREATER_TEXT,
            }),
            otherwise: VALIDATOR,
          }),
        },
        {
          name: 'minimumInvestmentAmount',
          showRequireIcon: true,
          parentValue: 'true',
          type: FieldTypes.Currency,
          label: 'Minimum investment amount',
          placeholder: 'Enter $ amount',
          validator: yup.string().trim().when(ALLOW_INTESTMENTS_KEY, {
            is: (val: string) => val === TRUE_KEY,
            then: yup.string().required(REQUIRED_TEXT).test({
              test: (val) => parseCost(val) !== 0,
              message: GREATER_TEXT,
            }),
            otherwise: VALIDATOR,
          }),
        },
      ],
    },

    {
      name: 'CIPRequired',
      type: FieldTypes.Checkbox,
      label: 'CIP required',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
      validator: VALIDATOR,
    },
    isDisplayedPendContribution ? {
      name: 'pendContributionsForCIP',
      type: FieldTypes.Checkbox,
      label: 'Pend contributions for CIP',
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
      helpText: 'Yes',
      validator: VALIDATOR,
    } : {
      name: 'empty',
      type: FieldTypes.Node,

    },
    {
      name: 'CMSReportingRequired',
      type: FieldTypes.Radio,
      label: 'CMS reporting required',
      showRequireIcon: true,
      options: [
        {
          label: 'Yes',
          value: 'true',
        },
        {
          label: 'No',
          value: 'false',
        },
      ],
      validator: onlyActivationRequired,
    },
    {
      name: 'generateStatements',
      type: FieldTypes.Radio,
      label: 'Generate statements',
      showRequireIcon: true,
      options: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validator: onlyActivationRequired,
    },
  ], [IRSOptions,
    errorFields,
    isDisplayedPendContribution,
    isActivationMode, isHSAOmnibus, isReplenished,
    onlyActivationRequired, appliedSourceState?.spendLimit, isPlanActive]);

  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
