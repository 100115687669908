import React, {
  useMemo,
  useState,
} from 'react';
import {
  Field,
  FieldTypes,
  Inscription,
  LabelWithSubfield,
  WarnModal,
} from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { ModalWrapper } from '@/components/wrappers';
import CreateCarrierForm from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/CreateCarrierForm';
import { useGetCarrierByIdQuery } from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetCarrierById.query';
import useGetCarriersQuery, { Carrier } from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetCarriers.query';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { HEALTH_PLAN_SETUP_FIELD } from '@/modules/HealthPlan/HealthPlanSetup/HealthPlanSetup.constants';
import { useHealthPlanFieldsState, useTemplateFieldState } from '@/modules/HealthPlan/HealthPlanSetup/hooks';
import { usePreviewStore } from '@/modules/HealthPlan/HealthPlanSetup/stores';
import { useHealthPlanTypes } from '@/modules/HealthPlan/hooks';
import { useHealthTemplatesListQuery,usePlansByPlanYearQuery } from '@/modules/HealthPlan/queries';
import { useFieldsWithDefaultValues } from '@/utils';

import { useHealthPlanNameStore } from '../stores';

const MAX_SEARCH_VALUE = 3;
const TRUE_KEY = 'true';

const useHealthPlanNameFields = (
  isActivationMode?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
  isAdditionalSection?: boolean,
) => {
  const [searchedCarrierName, setSearchedCarrierName] = useState('');
  const [createdCarrier, setCreatedCarrier] = useState<Carrier>();

  const [isVisible, setIsVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const sourceState = useHealthPlanNameStore((state) => state.sourceState);
  const currentState = useHealthPlanNameStore((state) => state.state);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);

  const { carriers, isLoading } = useGetCarriersQuery(
    searchedCarrierName?.length >= MAX_SEARCH_VALUE ? searchedCarrierName : undefined,
  );

  const currentCarrier = useMemo(() => {
    if (currentState?.carrierName) {
      return carriers.find((carrierEl) => carrierEl.id === currentState.carrierName);
    }
    return null;
  }, [carriers, currentState]);

  const { data } = useGetCarrierByIdQuery(currentState.carrierName);
  const receivedCarrier = useMemo(() => {
    if (data) {
      return {
        id: `${data.id || ''}`,
        name: data.name,
        externalId: data.external_id,
      } as Carrier;
    }
    return undefined;
  }, [data]);

  const options = useMemo(() => ([...createdCarrier
    ? [createdCarrier, ...carriers]
    : receivedCarrier && !carriers.find((carrierEl) => carrierEl.id === currentState.carrierName)
      ? [receivedCarrier, ...carriers] : carriers]
    .map((carrierEl) => ({ key: carrierEl.id, title: carrierEl.name }))),
  [carriers, receivedCarrier, createdCarrier, currentState.carrierName]);

  const carrierExternalId = useMemo(() => createdCarrier?.externalId
    || currentCarrier?.externalId
    || receivedCarrier?.externalId, [
    createdCarrier,
    receivedCarrier,
    currentCarrier,
  ]);

  const healthPlanTypes = useHealthPlanTypes();
  const { options: formattedTemplatesOptions } = useHealthTemplatesListQuery({
    status: HealthPlanStatuses.ACTIVE,
  });

  const { formatedOptions } = usePlansByPlanYearQuery({
    id: sourceState?.planYearId,
  });
  const onlyActivationRequired = useMemo(
    () => (isActivationMode ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING), [isActivationMode],
  );

  const createCarrierModals = useMemo(() => (
    <>
      <ModalWrapper
        testId="create_carrier"
        visible={isVisible}
        title="Create Carrier"
        onSetVisible={setIsVisible}
      >
        <CreateCarrierForm
          onClose={() => setIsVisible(false)}
          onSuccess={(carrierEl: Carrier) => {
            setCreatedCarrier(carrierEl);
            setIsVisible(false);
            setIsSuccessModalVisible(true);
          }}
        />
      </ModalWrapper>
      <WarnModal
        testId="carrier_created"
        visible={isSuccessModalVisible}
        onSetVisible={setIsSuccessModalVisible}
        header={`The ${createdCarrier?.name} carrier has been created!`}
        helptext="You can now use this new carrier for the Health Plan template configuration in the previous window."
        isSuccessIcon
        submitButtonText="Close"
        onSubmit={() => setIsSuccessModalVisible(false)}
      />
    </>
  ), [createdCarrier, isSuccessModalVisible, isVisible]);

  const fields = useMemo<Field[]>(() => {
    const additionalFields = [
      {
        name: HEALTH_PLAN_SETUP_FIELD.CARRIER_NAME,
        type: FieldTypes.AutocompleteDropdown,
        singleMode: true,
        label: 'Carrier name',
        showRequireIcon: isTemplateMode,
        disabled: !isTemplateMode,
        options,
        placeholder: 'Select carrier name',
        validator: onlyActivationRequired,
        completeValue: createdCarrier?.id,
        resetCompleteValue: () => setCreatedCarrier(undefined),
        add: isTemplateMode ? () => setIsVisible(true) : undefined,
        isLoading,
        onSearch: setSearchedCarrierName,
        onClear: () => setCreatedCarrier(undefined),
      },
      {
        placeholder: 'Select carrier ID',
        name: HEALTH_PLAN_SETUP_FIELD.CARRIER_ID,
        showRequireIcon: isTemplateMode,
        type: FieldTypes.Node,
        label: 'Carrier ID',
        value: <Inscription>{carrierExternalId || 'Carrier ID'}</Inscription>,
      },
      {
        name: HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TYPE,
        type: FieldTypes.MultipleDropdown,
        label: 'Health plan type',
        showRequireIcon: true,
        options: healthPlanTypes,
        placeholder: 'Select health plan type',
        validator: VALIDATORS.REQUIRED_ARRAY,
      },
      {
        name: HEALTH_PLAN_SETUP_FIELD.AUTOCREATE_CDH_ENROLLMENT,
        type: FieldTypes.Radio,
        label: 'Autocreate CDH enrollment',
        showRequireIcon: true,
        options: [
          {
            label: 'Yes',
            value: TRUE_KEY,
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        subFields: [
          !isTemplateMode
            ? {
              name: HEALTH_PLAN_SETUP_FIELD.PLANS,
              parentValue: TRUE_KEY,
              type: FieldTypes.CheckboxDropdown,
              showRequireIcon: true,
              placeholder: 'Select a plan',
              options: formatedOptions,
              label: 'Plans',
              emptyListMessage: 'No plans have been found',
              validator: isActivationMode && currentState.autocreateCdhEnrollment === TRUE_KEY
                ? VALIDATORS.REQUIRED_ARRAY : VALIDATORS.ARRAY,
            } : {
              name: 'empty',
              type: FieldTypes.Node,
            },
        ],
        validator: onlyActivationRequired,
      },
    ] as Field[];
    if (isTemplateMode) {
      return [
        {
          name: HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
          type: FieldTypes.Text,
          label: 'Health plan template name',
          showRequireIcon: true,
          placeholder: 'Enter health plan template name',
          validator: VALIDATORS.REQUIRED_STRING,
        },
        {
          name: HEALTH_PLAN_SETUP_FIELD.PLAN_SUMMARY,
          type: FieldTypes.TextArea,
          label: (
            <LabelWithSubfield
              title="Summary"
              desc="Summary of the plan to display to employer admins who set up their organizations within your organization."
            />
          ),
          placeholder: 'Summary',
          maxLength: 500,
          style: { minHeight: '120px' },
        },
        {
          name: HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE,
          type: FieldTypes.Dropdown,
          label: 'Parent template',
          disabled: true,
          options: formattedTemplatesOptions,
          placeholder: 'Select a health plan template',
          validator: VALIDATORS.STRING,
        },
        ...additionalFields,
      ];
    }
    if (isAdditionalSection) {
      return additionalFields;
    }
    return [
      {
        name: HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_NAME,
        type: FieldTypes.Text,
        label: 'Health plan name',
        showRequireIcon: true,
        placeholder: 'Enter health plan template name',
        validator: VALIDATORS.REQUIRED_STRING,
      },
      {
        name: HEALTH_PLAN_SETUP_FIELD.PLAN_CODE,
        type: FieldTypes.Text,
        label: 'Health plan code',
        showRequireIcon: true,
        placeholder: 'Enter plan code',
        // validator: yup.string().trim().test({
        //   test: (val) => val !== errors?.planCode,
        //   message: 'This plan code is already used',
        // }).required(REQUIRED_TEXT),
        validator: !isTemplateMode && onlyActivationRequired,
      },
      {
        name: HEALTH_PLAN_SETUP_FIELD.HEALTH_PLAN_TEMPLATE,
        type: FieldTypes.Dropdown,
        label: 'Parent template',
        disabled: true,
        options: formattedTemplatesOptions,
        showRequireIcon: true,
        placeholder: 'Select a health plan template',
        validator: VALIDATORS.STRING,
      },
    ];
  }, [
    formattedTemplatesOptions,
    options,
    healthPlanTypes,
    isTemplateMode,
    onlyActivationRequired,
    createdCarrier,
    isLoading,
    carrierExternalId,
    isAdditionalSection,
    formatedOptions,
    isActivationMode,
    currentState.autocreateCdhEnrollment,
    // currentState.plans,
  ]);
  const filteredField = useHealthPlanFieldsState(fields);

  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);

  return { stateFields, createCarrierModals };
};

export default useHealthPlanNameFields;
