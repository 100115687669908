import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

export const QUERY_KEY = 'GET_EMAIL_ILLUSTRATION';
interface Illustration {
  id: number;
  name: string;
  image_path: string;
}
// const HTTPS_PREFIX = 'https://';

export default () => {
  const { formattedData } = useGetEmployer();
  const { data, isLoading } = useQuery([QUERY_KEY], () => api.get<Illustration[]>(
    PATHS.EMAIL_ILLUSTRATION, {
      params: {
        org_path: formattedData.organizationPath,
      },
    },
  ), {
    enabled: !!formattedData?.organizationPath,
  });
  const formatted = useMemo(() => {
    const list = data?.data || [];
    return list.map((item) => ({
      key: `${item.id}`,
      title: item.name,
      link: item.image_path ? `${item.image_path}` : '',
    }));
  }, [data]);
  return {
    data: formatted,
    isLoading,
  };
};
