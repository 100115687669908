import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { GET_CIP_QUERY_KEY } from './useCIPInfo.query';

interface Params {
  employeeId?: string;
}
export default ({ employeeId }: Params) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(() => api.post(PATHS.CIP_COMPLETE_VERIFICATION, {
    // status: 'PASS',
  }, {
    params: {
      employee_id: employeeId,
    },
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_CIP_QUERY_KEY);
    },
  });
  return {
    update: mutateAsync,
    isLoading,
  };
};
