/* eslint-disable react-hooks/exhaustive-deps */

import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FileDtoWithMediaSource } from '../FileManager.constants';

const BACKLIGHTING_DELAY = 2000;

const useBacklightStop = (files: FileDtoWithMediaSource[]) => {
  const timerId = useRef<NodeJS.Timeout>();
  const [initing, setIniting] = useState(false);

  const updatedFiles = useMemo(
    () => {
      const currentTime = Date.now();

      return files
        .map(
          (file) => {
            const isOutOfDate = file.backlightingTime
              && file.backlighting
              && file.backlightingTime + BACKLIGHTING_DELAY <= currentTime;

            if (isOutOfDate) {
              return {
                ...file,
                backlightingTime: undefined,
                backlighting: undefined,
              };
            }

            return file;
          },
        );
    },
    [files, initing],
  );

  const hasBacklighting = useMemo(
    () => updatedFiles.some((file) => file.backlighting),
    [updatedFiles],
  );

  useEffect(
    () => {
      if (hasBacklighting) {
        if (timerId.current) clearTimeout(timerId.current);

        timerId.current = setTimeout(
          () => setIniting(!initing),
          BACKLIGHTING_DELAY,
        );
      }
    },
    [hasBacklighting, initing],
  );

  useEffect(() => () => {
    if (timerId.current) clearTimeout(timerId.current);
  }, []);

  return updatedFiles;
};

export default useBacklightStop;
