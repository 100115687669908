import _ from 'lodash';

const getIdsFromPath = (path: string = '') => {
  const [systemId, partnerId, distributorId, employerId, subsidiaryId, subgroupId] = path.split('\\').slice(1);

  return _.pickBy({
    systemId,
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
    subgroupId,
  });
};

export default getIdsFromPath;
