import React from 'react';
import { Box } from '@common-fe/common-fe';

import { Lockbox } from '@/modules/employer/components/Lockbox/lockbox.types';
import LockboxEmployees from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxEmployees/LockboxEmployees';
import { LockboxInformationForm, LockboxRecordForm } from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxForm/components';
import LockboxLogChanges from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxLogChanges/LockboxLogChanges';
import LockboxProcessing from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxProcessing/LockboxProcessing';

interface Props {
  lockbox?: Lockbox;
}

const LockboxInfo = ({ lockbox }: Props) => {

  return (
    <Box pad="spacing12">
      <LockboxRecordForm lockbox={lockbox} />
      <LockboxInformationForm lockbox={lockbox} />
      <LockboxEmployees lockbox={lockbox} />
      <LockboxProcessing lockbox={lockbox} />
      <LockboxLogChanges />
    </Box>
  );
};

export default LockboxInfo;
