import React from 'react';
import { Box, Hint, Inscription } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import theme from '@/styles/theme';

const PaymentFreeTimeLabel: React.FC<{ date?: string }> = ({ date }) => (
  <Hint
    hintElement={(
      <Box data-testId="free_for_a_limited_time" background="successContainer" round="4px" pad={{ horizontal: '6px', vertical: '1px' }} direction="row">
        <Inscription size="small" weight="bold" lineHeight="18px" color="success">Free</Inscription>
        <Inscription size="small" lineHeight="18px" weight={500} color="textBody">&nbsp;for a limited time</Inscription>
      </Box>
    )}
    hideHint={!date}
    backgroundColor={theme.colors.background3}
  >
    <Inscription size="small" weight={500} lineHeight="18px">
      Your employer offers a fee-free instant payment for a limited time, until {dayjs(date).format(DEFAULT_DATE_FORMAT)}.
    </Inscription>
  </Hint>
);

export default PaymentFreeTimeLabel;
