import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { HealthPlanResponse } from '@/modules/HealthPlan/HealthPlan.types';

export const QUERY_KEY = 'HEALTH_PLAN_BY_ID';
export const useHealthPlanByIdQuery = (
  id?: string,
  successCallback?: (plan: HealthPlanResponse) => void,
) => {
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [QUERY_KEY, id],
    id
      ? () => api.get<HealthPlanResponse>(
        PATHS.HEALTH_PLAN_BY_ID(id),
      )
      : () => null,
    {
      onSuccess: (response) => {
        if (successCallback && response) {
          successCallback(response.data);
        }
        return response;
      },
      cacheTime: 10,
      refetchOnMount: false,
    },
  );

  return {
    data: data?.data,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useHealthPlanByIdQuery;
