import React, { useCallback, useMemo } from 'react';
import { AutocompleteDropdown, Box } from '@common-fe/common-fe';

import { Option, OptionKey } from '@/common/types';
import { LockboxValidationError, lockboxValidationErrorTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import { Vendor } from '@/modules/employer/components/Lockbox/queries/useGetVendors.query';

interface Props {
  vendors?: Vendor[];
}

const LockboxVendorDropdown: React.FC<Props> = ({ vendors }) => {
  const { editableLockbox, validationErrors, setEditableLockbox } = useLockboxDetailsStore();

  const vendorsOptions: Option[] = useMemo(() => vendors?.map((vendor) => ({
    key: vendor?.vendorId || '',
    value: vendor?.vendorId || '',
    title: vendor?.vendorName || '',
  })) || [], [vendors]);

  const handleUpdateLockboxVendor = useCallback((vendorId: OptionKey) => {
    setEditableLockbox({ vendorId: vendorId as string });
  }, [setEditableLockbox]);

  const errorType = useMemo(() => {
    return validationErrors?.find((error) => error === LockboxValidationError.VENDOR_REQUIRED) as LockboxValidationError | undefined;
  }, [validationErrors]);

  return (
    <Box>
      <AutocompleteDropdown
        name="lockboxVendorInput"
        value={editableLockbox?.vendorId}
        defaultValue={editableLockbox?.vendorId}
        onChange={handleUpdateLockboxVendor}
        options={vendorsOptions}
        placeholder="Select vendor"
        {...errorType ? { errorText: lockboxValidationErrorTitle?.[errorType] } : {}}
        disableFiltering
        ellipsisMode
        isSmall
        isBold
      />
    </Box>
  );
};

export default LockboxVendorDropdown;
