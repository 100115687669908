import { useCallback, useState } from 'react';

export default (
  list: string[][],
  setList: React.Dispatch<React.SetStateAction<string[][]>>,
) => {
  const [sublistIndex, setSublistIndex] = useState<number>();
  const [dragItemIndex, setDragItemIndex] = useState<number>();
  const handleDragStart = (index: number, listIndex = 0) => {
    setDragItemIndex(index);
    setSublistIndex(listIndex);
  };

  const handleDragEnter = useCallback((
    index?: number,
    listIndex = 0,
  ) => {
    let newList = [...list[listIndex]];
    if (index === undefined) {
      if (!!listIndex && !sublistIndex
        && dragItemIndex !== undefined
        && sublistIndex !== undefined) {
        const item = list[sublistIndex][dragItemIndex];
        if (newList.includes(item)) {
          return;
        }
        newList.push(item);
        const formatedList = list.map((oldList, currentIndex) => {
          if (currentIndex === listIndex) {
            return newList;
          }
          return oldList;
        });
        setList(formatedList);

        return;
      }

      return;
    }
    if (sublistIndex === listIndex) {
      if (listIndex === 0 && sublistIndex === 0) {
        return;
      }
      if (dragItemIndex !== undefined) {
        const item = newList[dragItemIndex];
        newList.splice(dragItemIndex, 1);
        newList.splice(index, 0, item);
        setDragItemIndex(index);
        const formatedList = list.map((oldList, currentIndex) => {
          if (currentIndex === listIndex) {
            return newList;
          }
          return oldList;
        });
        setList(formatedList);
      }
    } else if (dragItemIndex !== undefined && sublistIndex !== undefined) {
      if (listIndex === 0) {
        return;
      }
      const item = list[sublistIndex][dragItemIndex];
      newList = newList.filter((currentItem) => currentItem !== item);
      newList.splice(index, 0, item);
      const formatedList = list.map((oldList, currentIndex) => {
        if (currentIndex === listIndex) {
          return newList;
        }
        return oldList;
      });
      setList(formatedList);
    }
  }, [dragItemIndex, list, setList, sublistIndex]);

  // const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
  //   // e.target.style.backgroundColor = 'black';
  // };

  const handleDrop = () => {
    // e.target.style.backgroundColor = 'black';
    setDragItemIndex(undefined);
  };

  return {
    list,
    handleDragStart,
    handleDrop,
    // handleDragLeave,
    handleDragEnter,
  };
};
