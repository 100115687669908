import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useRouteMatch } from 'react-router';
import {
  AppButton,
  Box, ErrorModal,   FlexControlledForm, PendingModal, Preloader, SuccessModal,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { SERVER_ERROR_MODAL_TEXT, SERVER_ERROR_MODAL_TITLE, SERVER_ERRORS } from '@/common/constants';
import ModalWrapper from '@/components/wrappers/ModalWrapper';
import { useSnackbar } from '@/modules/core/hooks';
import { useDependentsList } from '@/modules/employee/Employee/Dependents/hooks';
import { useGeneralInfoStore } from '@/modules/employee/Employee/PersonalInformation/GeneralInfo/stores';
import { AddCardDependentForm } from '@/modules/transaction/CardDetails/AddCardDependentModal/AddCardDependentForm';
import { useCardDependentForm } from '@/modules/transaction/CardDetails/AddCardDependentModal/hooks/useCardDependentForm';
import { useAddCardDependentMutation, useUpdateCardDependentMutation } from '@/modules/transaction/CardDetails/AddCardDependentModal/queries/useAddDependentCard.query';
import { useGetRelationshipListQuery } from '@/modules/transaction/CardDetails/AddCardDependentModal/queries/useGetRelationshipList.query';
import { CardHolderDto } from '@/modules/transaction/Cards/Cards.types';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { useSetDependent } from './hooks/useSetDependent';
import { useTypeForm } from './hooks/useTypeForm';

interface DiscardConfirmationState {
  visible: boolean,
  type: string | null,
}

const CREATE_DEPENDENT_TYPE = 'new';

export const AddCardDependentModal: React.FC = () => {
  const [dependentModalVisible, setDependentModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [newDependent, setNewDependent] = useState<CardHolderDto | null>(null);
  const [oldDependent, setOldDependent] = useState<CardHolderDto | null>(null);
  const [discardCreationConfirmation, setDiscardCreationConfirmation] = useState<
  DiscardConfirmationState
  >({
    visible: false, type: '',
  });
  const [showTypeError, setShowTypeError] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [typeForm, setTypeForm] = useState<string | null>(null);
  const { relationshipList, isLoading: relationshipListLoading } = useGetRelationshipListQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { isLoading: mutateAsyncLoading, mutateAsync } = useAddCardDependentMutation();
  const { mutateAsync: updateDependentCard } = useUpdateCardDependentMutation();
  const createMode = useMemo(() => typeForm === CREATE_DEPENDENT_TYPE, [typeForm]);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [addDependentError, setAddDependentError] = useState();
  const { isServerError, setIsServerError } = useIsServerError(addDependentError);
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { personId } = useGeneralInfoStore((state) => state.state);
  const {
    formattedDependents,
    isLoading: dependentsLoading,
  } = useDependentsList(personId);
  const {
    refetch: getCardHolderByIdRefetch,
    isLoading: getCardHolderByIdLoading,
    isFetching: getCardHolderByIdFetching,
  } = useGetCardsQuery(employeeId);

  const holder = useMemo(
    () => formattedDependents.find((item) => item.dependentId === typeForm),
    [formattedDependents, typeForm],
  );
  const dependedFields = useCardDependentForm({
    createMode,
    data: createMode ? newDependent : holder,
    relationshipList,
  });

  useEffect(() => () => {
    setTypeForm(null);
    setIsSubmitting(false);
  }, [dependentModalVisible]);

  useEffect(() => {
    if (!successModalVisible && dependentModalVisible) {
      setNewDependent(null);
    }
  }, [successModalVisible, dependentModalVisible]);

  useEffect(() => {
    if (holder && !createMode) {
      return setOldDependent(holder);
    }
    return setOldDependent(null);
  }, [setOldDependent, holder, createMode]);

  useEffect(() => {
    setIsLoading(
      getCardHolderByIdLoading
      || getCardHolderByIdFetching
      || relationshipListLoading
      || dependentsLoading,
    );
  }, [isLoading,
    getCardHolderByIdLoading,
    getCardHolderByIdFetching,
    relationshipListLoading,
    dependentsLoading]);

  useEffect(() => {
    if (typeForm && typeForm !== 'new') getCardHolderByIdRefetch();

    if (typeForm === 'new') {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [getCardHolderByIdRefetch, typeForm]);

  const typeFormFields = useTypeForm({
    holderList: formattedDependents,
    defaultType: typeForm,
  });

  const submitButtonText = useMemo(() => (typeForm !== CREATE_DEPENDENT_TYPE
    ? 'Order a card'
    : 'Add Dependent & Order Card'),
  [typeForm]);

  const closeModal = useCallback(() => {
    setTypeForm(null);
    setDependentModalVisible(false);
    setShowTypeError(false);
  }, []);

  const preparedDependent = useSetDependent(oldDependent || newDependent);

  const addCardDependentSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      if (!createMode) {
        await updateDependentCard(preparedDependent);
        closeModal();
        setSuccessModalVisible(true);
        return;
      }

      setShowErrors(false);
      const validatorMap = dependedFields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      await schema.validate(newDependent, { abortEarly: false });
      if (newDependent) {
        await mutateAsync(preparedDependent);
        // Due to BE implementation of the creation a new dependent FE has to use such approach
        await new Promise((resolve) => {
          _.delay(resolve, 1500);
        });
        await getCardHolderByIdRefetch();
        setSuccessModalVisible(true);
        closeModal();
      }
    } catch (e) {
      if (_.get(e, 'response.status', 0) === SERVER_ERRORS.SERVER_ERROR) {
        // @ts-ignore
        setAddDependentError(e);
      }
      setShowErrors(true);
    }
    setIsSubmitting(false);
  }, [
    setIsSubmitting,
    mutateAsync,
    closeModal,
    setSuccessModalVisible,
    newDependent,
    dependedFields,
    preparedDependent,
    createMode,
    getCardHolderByIdRefetch,
    updateDependentCard,
  ]);
  return (
    <>
      <Box>
        <AppButton
          buttonType="secondary"
          type="submit"
          testId="AddCardDependentModal-open-modal"
          onClick={() => setDependentModalVisible(true)}
        >
          Add a Card
        </AppButton>
      </Box>
      <ModalWrapper
        {
          ...discardCreationConfirmation.visible
            ? {
              style: { display: 'none' },
            }
            : {}
        }
        visible={dependentModalVisible}
        title="Select a Dependent"
        onSetVisible={closeModal}
      >
        <ErrorModal
          testId="EmployersList_server-error"
          visible={isServerError}
          header={SERVER_ERROR_MODAL_TITLE}
          helpText={SERVER_ERROR_MODAL_TEXT}
          buttonText="Close"
          buttonTextTryAgain="Try again"
          onSetVisible={setIsServerError}
          onSubmit={addCardDependentSubmit}
        />
        <Box
          background="module"
          pad="medium"
          data-testid="AddCardDependentModal-form-wrapper"
          round="container1Round"
        >
          <Box width="100%" height="fit-content" background="canvas" round="container1Round">
            {
              !discardCreationConfirmation.visible && (
                <FlexControlledForm
                  fields={typeFormFields}
                  formTitle="Dependent"
                  editMode
                  isModalType
                  showError={showTypeError}
                  onChangeValues={({ type }) => {
                    if (
                      typeForm
                      && type !== typeForm
                      && type !== CREATE_DEPENDENT_TYPE
                      && dirtyForm
                    ) {
                      setDiscardCreationConfirmation((prevState) => ({
                        ...prevState, visible: true, type,
                      }));
                    }
                    if (type !== typeForm) {
                      setNewDependent(null);
                      setShowErrors(false);
                    }
                    setTypeForm(type);
                  }}
                />
              )
            }
          </Box>

          <AddCardDependentForm
            onChangeValues={setNewDependent}
            value={newDependent}
            type={discardCreationConfirmation.visible ? CREATE_DEPENDENT_TYPE : typeForm}
            ref={formRef}
            setDirtyForm={setDirtyForm}
            loading={isLoading}
            fields={dependedFields}
            showErrors={showErrors}
          />
        </Box>
        <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
          <Box margin={{ right: 'spacing24' }}>
            <AppButton
              buttonType="secondary"
              onClick={closeModal}
            >
              Cancel
            </AppButton>
          </Box>
          <AppButton
            disabled={isLoading || mutateAsyncLoading || isSubmitting}
            onClick={addCardDependentSubmit}
          >
            {
              isLoading || mutateAsyncLoading || isSubmitting
                ? <Preloader color="white" />
                : submitButtonText
            }
          </AppButton>
        </Box>
      </ModalWrapper>
      <SuccessModal
        visible={successModalVisible}
        onSetVisible={setSuccessModalVisible}
        header="We’ve Received Your Request for a New Card!"
        helptext="Card will be mailed in 3-5 days"
        buttonText="Got It!"
        onSubmit={() => handleAddPermanentSnackbar({
          text: `${newDependent?.name || `${newDependent?.firstName} ${newDependent?.lastName}`}’s card has been requested and will be mailed in 3-5 days`,
          closeIcon: true,
        })}
      />
      <PendingModal
        header={`Are You Sure You Want to Discard Adding ${newDependent?.firstName || ''} ${newDependent?.lastName || ''}?`}
        helptext="All inserted data will be deleted."
        buttonText="Confirm"
        visible={discardCreationConfirmation.visible}
        onCancel={() => {
          setTypeForm(CREATE_DEPENDENT_TYPE);
          setDiscardCreationConfirmation((prevState) => ({
            ...prevState,
            visible: false,
            type: '',
          }));
        }}
        onSubmit={() => {
          setTypeForm(discardCreationConfirmation.type);
          setDiscardCreationConfirmation({
            visible: false, type: '',
          });
        }}
      />
    </>
  );
};
