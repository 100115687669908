import React, { useEffect } from 'react';
import {
  Box, FlexControlledForm, useDebounceState,
} from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

import { Plan } from '@/modules/HealthPlan/HealthPlan.types';
import theme from '@/styles/theme';

import useCoverageLevelsFields from './hooks/useCoverageLevelsFields';
import { useStore } from './stores/useCoverageLevels.store';
import { CoverageLevelPayload } from './CoverageLevels.constants';
import CoverageLevelsItemHeader from './CoverageLevelsItemHeader';
import { useDeleteCoverageLevelById } from './hooks';

interface StyledBoxProps {
  isHighlight?: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  ${({ isHighlight: isHightlight }) => {
    if (isHightlight) {
      return css`
        animation-name: showHighlight;
        animation-duration: 3s;
        @keyframes showHighlight {
          from {
            border-color: ${theme.colors.accentActive};
            ${theme.shadows.highlighted};
          }
          to {
            border-color: transparent;
          }
        }
      `;
    }
    return css``;
  }}
`;

interface Props extends CoverageLevelPayload {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  isActiveMode?: boolean;
  plans?: Plan[];
}
const CoverageLevelsItem: React.FC<Props> = ({
  title, isEditMode, isTemplateMode, isActiveMode, isDeletable, isHighlighted, coverageId, plans,
}) => {
  const handleDelete = useDeleteCoverageLevelById(coverageId);
  const fields = useCoverageLevelsFields(coverageId, {
    isEditMode, isTemplateMode, isActiveMode,
  });
  const { setValues, values } = useDebounceState<Partial<CoverageLevelPayload>>();

  const handleSetState = useStore((state) => state.setState);
  const currentState = useStore((state) => state.state);
  const showErrors = useStore((state) => state.showErrors);

  useEffect(() => {
    if (values) {
      const list = currentState || [];
      const formated = list.map((item) => {
        if (item?.coverageId !== coverageId) {
          return {
            ...item,
            accountFundingConfigs: !isTemplateMode
              ? plans?.map((plan: Plan) => {
                const receivedPlan = item.accountFundingConfigs
                  ?.find((el) => el.planId === plan.id);
                return {
                  id: `${receivedPlan?.id || ''}`,
                  planId: `${plan.id || ''}`,
                  planName: plan.name,
                  autoEnrollmentAmount: `${receivedPlan?.autoEnrollmentAmount || ''}`,
                };
              }) : undefined,
          };
        }

        const newValues:Partial<CoverageLevelPayload> = {};
        Object.keys(values).forEach((key) => {
          // @ts-ignore
          newValues[key] = values[key] || '';
        });
        return {
          ...item,
          ...newValues,
          accountFundingConfigs: !isTemplateMode
            ? plans?.map((plan: Plan) => {
              const receivedPlan = item.accountFundingConfigs?.find((el) => el.planId === plan.id);
              return {
                id: `${receivedPlan?.id || ''}`,
                planId: `${plan.id || ''}`,
                planName: plan.name,
                autoEnrollmentAmount: `${receivedPlan?.autoEnrollmentAmount || ''}`,
              };
            }) : undefined,
        };
      });

      handleSetState(formated);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetState, coverageId, values, plans]);
  return (
    <StyledBox
      direction="column"
      round="container1Round"
      background="canvas"
      elevation="default"
      isHighlight={isHighlighted}
      border={{ color: 'border2', size: 'small' }}
    >
      <FlexControlledForm
        testId={`coverage-level-${coverageId}`}
        fields={fields}
        subTitle={(
          <CoverageLevelsItemHeader
            title={title}
            isEditMode={isEditMode}
            isDeletable={isDeletable}
            onDelete={handleDelete}
          />
        )}
        showError={showErrors}
        shouldControlValues
        editMode={isEditMode}
        onChangeValues={setValues}
        isThinMode
      />

    </StyledBox>
  );
};
export default CoverageLevelsItem;
