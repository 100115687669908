import { useCallback } from 'react';
import { signOut }  from '@aws-amplify/auth';

import ROUTES from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';
import { removeStoredClaimsResponses } from '@/modules/employer/components/Claims/queries/useClaimsList.query';
import Token from '@/utils/handlers/Token';

import { useAuthStore } from '../stores';

// const NEW_PASSWORD_TOKEN_KEY = 'NEW_PASSWORD_REQUIRED';
export const NEW_PASSWORD_TOKEN_KEY = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED';

const useAuthorization = () => {
  const history = useHistory();
  
  const store = useAuthStore();
  const handleLogout = useCallback(() => {
    removeStoredClaimsResponses();
    signOut();
    Token.clear();
    store.setAuth(false);
    store.setIds();
    store.setUser(null);
    location.reload();
  }, [store]);
  const handleLogin = useCallback((token: string) => {
    if (token === NEW_PASSWORD_TOKEN_KEY) {
      history.push(ROUTES.CHANGE_PASSWORD);
    } else {
      Token.storeToken(token);
      store.setAuth(true);
    }
  }, [history, store]);

  return {
    handleLogout,
    handleLogin,
  };
};

export default useAuthorization;
