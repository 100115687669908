import React from 'react';
import { Box } from '@common-fe/common-fe';

import ClearButton from './ClearButton';
import FilterHinter from './FilterHint';

interface Props {
  onClear: () => void;
}
const ClearBlock: React.FC<Props> = ({ onClear }) => (
  <Box direction="row" justify="center">
    <FilterHinter />
    <Box width="1px" background="border1" margin={{ right: 's', left: 's' }} />
    <ClearButton onClick={onClear} />
  </Box>
);
export default ClearBlock;
