import { useMemo } from 'react';
import { FieldError, FieldValues } from '@common-fe/common-fe';

import { useStore } from '@/modules/employer/store/useSetupEmployer.store';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import useGeneralInformationFields from './useGeneralInformationFields';

export default () => {
  const generalInfo = useStore((state) => state.generalInfo);

  const pendingFields = useGeneralInformationFields([], [], [], false);
  const activeFields = useGeneralInformationFields([], [], [], true);
  const { getValidateFields, getErrors } = useValidationUtils();
  const pendingValidateFields = useMemo<FieldValues>(
    () => getValidateFields(pendingFields),
    [getValidateFields, pendingFields],
  );

  const activateValidateFields = useMemo<FieldValues>(
    () => getValidateFields(activeFields),
    [activeFields, getValidateFields],
  );

  const pendingErrors: FieldError = useMemo(
    () => getErrors(generalInfo, pendingValidateFields),
    [generalInfo, getErrors, pendingValidateFields],
  );
  const activateErrors: FieldError = useMemo(
    () => getErrors(generalInfo, activateValidateFields),
    [activateValidateFields, generalInfo, getErrors],
  );

  return {
    state: generalInfo,
    pendingErrors,
    activateErrors,
  };
};
