import React, { useState } from 'react';
import { Box } from '@common-fe/common-fe';

import LogList from './LogList';
import Title from './Title';

interface Props {
  title?: string;
}

const ActivityLog: React.FC<Props> = ({ title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (title) {
    return (
      <>
        <Title title={title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        {isExpanded ? (
          <Box pad="spacing24" background="module" round="small" margin={{ top: 'spacing12' }}>
            <LogList />
          </Box>
        ) : null}
      </>
    );
  }

  return <LogList />;
};

export default ActivityLog;
