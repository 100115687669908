import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { QueryKeys, useCurrentOrganization } from '@/modules/core/hooks';
import { getIdsFromPath } from '@/utils';

import useOrphanOrganizationsQuery from '../queries/usOrphanOrganizations.query';

export default () => {
  const location = useLocation();
  const { observingOrganization } = useCurrentOrganization();
  const { partnerId } = getIdsFromPath(observingOrganization.path);

  const urlPartnerId = useMemo(
    () => new URLSearchParams(location.search)?.get(QueryKeys?.OBSERVING_PARTNER_QUERY_KEY),
    [location.search]
  );
  const currentPartnerId = useMemo(() => urlPartnerId || partnerId, [partnerId, urlPartnerId]);

  const { data } = useOrphanOrganizationsQuery({
    perPage: 10000,
    enable: true,
    partnerId: currentPartnerId,
  });

  return {
    data: data,
  };
};
