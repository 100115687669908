import React from 'react';
import { Text } from '@common-fe/common-fe';
import dayjs from 'dayjs';

const formatAge = (createdAt: string) => {
  const isValid = dayjs(createdAt).isValid();

  if (!isValid) {
    return { age: createdAt };
  }

  const title = `Submitted: ${dayjs(createdAt).format('D MMM YYYY _ h:mm a')}`.replace('_', 'at');
  const age = dayjs().from(dayjs(createdAt), true)
    .replace('a few seconds', '1 min')
    .replace('a ', '1 ')
    .replace('an ', '1 ')
    .replace('minutes', 'min')
    .replace('minute', 'min');
  const isOld = age.includes('days') || age.includes('month') || age.includes('year');

  return { isOld, title, age };
};

const Age: React.FC<{ createdAt: string }> = ({ createdAt }) => {
  const formattedAge = formatAge(createdAt);

  return (
    <Text
      data-testid={formattedAge.isOld ? 'old-claim' : 'new-claim'}
      color={formattedAge.isOld ? 'danger' : ''}
      title={formattedAge.title}
    >
      {formattedAge.age}
    </Text>
  );
};

export default Age;
