import React, { useCallback, useMemo, useState } from 'react';
import {
  AppButton,
  Box,
  FlexControlledForm,
} from '@common-fe/common-fe';
import dayjs, { Dayjs } from 'dayjs';

import { RAW_DATE_FORMAT } from '@/common/constants';
import { usePayrollCalendarDatesStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/stores';

import useValidatePayrollCalendarForm from '../../hooks/useValidatePayrollCalendarForm';

import { usePayrollCalendarCustomDatesForm } from './hooks/usePayrollCalendarCustomDatesForm';

const INITIAL_CUSTOM_DATE_ID = 1;

interface CustomDatesFormValues {
  [key: string]: string;
}

interface Props {
  onCancel?: () => void;
  minDate?: string;
  maxDate?: string;
  onPickDates?: (dates: Dayjs[]) => void;
}
      
const PayrollCalendarCustomDateModalForm: React.FC<Props> = ({
  onCancel,
  minDate,
  maxDate,
  onPickDates,
}) => {
  const [customDatesCount, setCustomDatesCount] = useState(1);
  const [customDatesIds, setCustomDatesIds] = useState([INITIAL_CUSTOM_DATE_ID]);
  const [lastCustomId, setLastCustomId] = useState(INITIAL_CUSTOM_DATE_ID);
  const [formValues, setFormValues] = useState<CustomDatesFormValues>({});
  
  const dates = usePayrollCalendarDatesStore((state) => state.dates);
  const customFormDates: Dayjs[] = useMemo(() => {
    const dates = customDatesIds.map((id) => formValues[`customDate${id}`]);

    const datesWithoutDuplicates = dates
      .filter((date) => !!date)
      .map((date) => dayjs(date, RAW_DATE_FORMAT));

    return datesWithoutDuplicates;
  },
  [customDatesIds, formValues]);

  const handleRemoveCustomDate = useCallback((id: number) => {
    const newFormValues = { ...formValues };
    const filteredIds = customDatesIds.filter((customDateId) => customDateId !== id);
    setCustomDatesIds(filteredIds);
    newFormValues[`customDate${id}`] = '';
    setFormValues(newFormValues);
    setCustomDatesCount(customDatesCount - 1);
  }, [customDatesCount, customDatesIds, formValues]);

  const handleAddCustomDate = useCallback(() => {
    setCustomDatesCount(customDatesCount + 1);
    const newCustomId = lastCustomId + 1;
    setLastCustomId(newCustomId);
    setCustomDatesIds([...customDatesIds, newCustomId]);
  }, [customDatesCount, customDatesIds, lastCustomId]);

  const fields = usePayrollCalendarCustomDatesForm({
    customDatesCount,
    onAddCustomDate: handleAddCustomDate,
    onRemoveCustomDate: handleRemoveCustomDate,
    minDate,
    maxDate,
    dates,
    customDatesIds,
    customFormDates,
  });

  const handleSubmit = useCallback(async () => {
    const allValuesValid = customDatesIds.every((id) => !!formValues[`customDate${id}`].length);
    const hasDuplicates = customFormDates.length !== new Set(customFormDates.map((date) => date.format(RAW_DATE_FORMAT))).size;

    if (!allValuesValid || hasDuplicates) {
      return;
    }

    if (onPickDates) {
      onPickDates(customFormDates);
    }

    if (onCancel) onCancel();
  }, [
    onPickDates,
    onCancel,
    customFormDates,
    formValues,
    customDatesIds,
  ]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const { isSubmitted, handleValidate } = useValidatePayrollCalendarForm({
    fields,
    state: formValues,
    onSuccess: handleSubmit,
  });

  return (
    <Box data-testid="PayrollCalendarCustomDateModalForm_container">
      <Box
        round="container1Round"
        pad="spacing24"
        background="module"
      >
        <Box data-testid="PayrollCalendarCustomDateModalForm" round="container1Round" background="canvas">
          <FlexControlledForm
            fields={fields}
            editMode
            showError={isSubmitted}
            onChangeValues={setFormValues}
            isModalType
          />
        </Box>
    
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
        gap="xs"
      >
        <Box direction="row" gap="xs">
          <AppButton
            testId="PayrollCalendarCustomDateModalForm_cancel"
            buttonType="secondary"
            width="control"
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
          <AppButton
            testId="PayrollCalendarCustomDateModalForm_submit"
            type="button"
            onClick={handleValidate}
            width="control"
          >
            Submit
          </AppButton>
        </Box>
      </Box>
    </Box>
  );
};
      
export default PayrollCalendarCustomDateModalForm;
      