import React, { useMemo } from 'react';
import { Box, Image } from '@common-fe/common-fe';

import PreviewImage from '@/assets/placeholders/branding-preview.svg';

interface Props {
  link?: string;
}
export const PREFIX = 'https://';
const BrandingPreview: React.FC<Props> = ({ link }) => {
  const formattedLink = useMemo(() => {
    if (!link) {
      return '';
    }
    if (link?.includes(PREFIX)) {
      return link;
    }
    return `${PREFIX}${link}`;
  }, [link]);

  return (
    <Box
      fill
      data-testid="branding-preview"
      margin={{ top: 's' }}
      pad={{ horizontal: 'l', vertical: 's' }}
      background="canvas"
      round="container2Round"
      border={{ color: 'border1', size: 'large' }}
    >
      <Image
        src={formattedLink || PreviewImage}
        alt="preview"
        data-testid="branding-preview_image"
        // height="68px"
      />
    </Box>
  );
};
export default BrandingPreview;
