import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { EmployerDtoPayload } from '@/modules/employer/types';

export const GET_ORGANIZATION_NAME = 'GET_ORGANIZATION_NAME';

interface Props {
  id?: string;
  enabled?: boolean;
}

const useGetOrgName = (props: Props) => {
  const { id, enabled } = props;
  const {
    isLoading,
    data,
  } = useQuery(
    [GET_ORGANIZATION_NAME, id],
    () => api.get<EmployerDtoPayload>(PATHS.GET_ORGANIZATION_NAME(id)),
    {
      enabled: Boolean(id && enabled),
    },
  );

  return {
    name: data?.data?.name,
    data: data?.data,
    isLoading,
  };
};

export default useGetOrgName;
