export const DEV_ENV_KEY = 'dev';
export const UAT_ENV_KEY = 'uat';
export const PROD_ENV_KEY = 'prod';
export interface Params {
  uat?: boolean;
  prod?: boolean;
  dev?: boolean;
}
export default (params: Params) => {
  const currentEnv =  import.meta.env.REACT_APP_ENV;
  if (currentEnv === DEV_ENV_KEY && params?.dev === true) {
    return true;
  }
  if (currentEnv === UAT_ENV_KEY && params?.uat === true) {
    return true;
  }
  if (currentEnv === PROD_ENV_KEY && params?.prod === true) {
    return true;
  }
  return false;
};
