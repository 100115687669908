import { useMemo } from 'react';

const NEW_LINE = '\n';
const EMPTY_LINE = /\S/;

const useConvertTextToCsv = (text?: string) => {
  const csvData = useMemo(() => {
    if (!text) {
      return [];
    }

    const data: string[][] = [];
    const rows = text.split(NEW_LINE).map((row) => row.trim());

    rows.filter((row) => EMPTY_LINE.test(row)).forEach((row) => {
      const cells: string[] = [];
      let inQuotes = false;
      let value = '';

      for (let rowSymbol = 0; rowSymbol < row.length; rowSymbol++) {
        const char = row[rowSymbol];
        const nextChar = row[rowSymbol + 1];

        if (char === '"') {
          if (inQuotes && nextChar === '"') {
            value += char;
            rowSymbol++;
          } else {
            inQuotes = !inQuotes;
          }
        } else if (char === ',' && !inQuotes) {
          cells.push(value.trim());
          value = '';
        } else {
          value += char;
        }
      }

      cells.push(value.trim());
      data.push(cells);
    });

    return data;
  }, [text]);

  return {
    csvData,
  };
};

export default useConvertTextToCsv;
