import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  // CalendarIcon,
  ClearAll,
  // DateRangeDropdown,
  SearchInput, useDebounce,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

// import dayjs from 'dayjs';
import { GroupByFilter } from '@/modules/employer/components/Reports/report.types';

import { NestedSelectDropdown } from '../NestedSelectDropdown';

import {
  CARD_DATE_RANGE_DEFAULT_VALUE,
  CARD_GROUP_BY_DEFAULT_VALUE,
  CardGroupByType,
  useCardReportFilterStore,
} from './store/useCardReportFilter.store';
import SelectSSNDropdown from './SelectSSNDropdown';

const INPUT_WIDTH = '322px';

const ClearAllButtonContainer = styled(Box)`
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translate(-50%);
`;

const groupByOptions = [
  {
    key: GroupByFilter.OrgField,
    title: 'Organization',
    value: 'Organization',
    subOptions: [
      {
        key: GroupByFilter.Partner,
        title: 'Partner',
        value: 'Partner',
      },
      {
        key: GroupByFilter.Employer,
        title: 'Employer',
        value: 'Employer',
      },
      {
        key: GroupByFilter.PartnerAndEmployer,
        title: 'Partner and Employer',
        value: 'Partner and Employer',
      },
    ],
  },
  {
    key: GroupByFilter.DateField,
    title: 'Date',
    value: 'Date',
    subOptions: [
      {
        key: GroupByFilter.DateOnly,
        title: 'Date only',
        value: 'Date only',
      },
      {
        key: GroupByFilter.DatePartner,
        title: 'Partner',
        value: 'Partner',
      },
      {
        key: GroupByFilter.DateEmployer,
        title: 'Employer',
        value: 'Employer',
      },
      {
        key: GroupByFilter.DatePartnerAndEmployer,
        title: 'Partner and Employer',
        value: 'Partner and Employer',
      },
    ],
  },
];

export const CardReportFilters = () => {

  const ssnOption = useCardReportFilterStore((state) => state.SSNOption);
  const setSSNOption = useCardReportFilterStore((state) => state.handleSetSSNOption);


  const dateRange = useCardReportFilterStore((state) => state.dateRange);
  const groupByValues = useCardReportFilterStore((state) => state.groupBy);
  const searchValue = useCardReportFilterStore((state) => state.search);
  const setDateRange = useCardReportFilterStore((state) => state.handleSetDateRange);
  const setSearchValue = useCardReportFilterStore((state) => state.handleSetSearch);
  const setGroupByValues = useCardReportFilterStore((state) => state.handleSetGroupBy);
  const handleClearAll = useCardReportFilterStore((state) => state.handleClear);
  const [tempSearchValues, setSearchTempValues] = useState<string>(searchValue);
  const [isHovered, setIsHovered] = useState(false);
  const [initCleaning, setInitCleaning] = useState(false);
  const debouncedValue = useDebounce(tempSearchValues);

  const isVisibleClearAll = useMemo(
    () => !_.isEqual(dateRange[0], null)
    || !_.isEqual(groupByValues, CARD_GROUP_BY_DEFAULT_VALUE)
    || tempSearchValues.length >= 3,
    [dateRange, groupByValues, tempSearchValues],
  );

  // const handleDateRangeChange = useCallback(
  //   (range) => {
  //     if (range[0] && range[1]) {
  //       setDateRange([
  //         dayjs(range[0]).isBefore(range[1])
  //           ? range[0] : range[1],
  //         dayjs(range[0]).isBefore(range[1])
  //           ? range[1] : range[0],
  //       ]);
  //       return;
  //     }

  //     setDateRange(range);
  //   },
  //   [setDateRange],
  // );

  useEffect(() => {
    const validValueLength = debouncedValue.length >= 3 || debouncedValue.length === 0;
    if (validValueLength) {
      setSearchValue(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleClear = useCallback(() => {
    handleClearAll();
    setInitCleaning(!initCleaning);
    setTimeout(() => {
      setDateRange(CARD_DATE_RANGE_DEFAULT_VALUE);
    });
  }, [handleClearAll, initCleaning, setDateRange]);

  return (
    <>
      <ClearAllButtonContainer
        width="max-content"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ClearAll
          onClick={handleClear}
          isVisible={isVisibleClearAll}
          buttonStyles={{ backgroundColor: isHovered ? undefined : 'canvas' }}
        />
      </ClearAllButtonContainer>

      <Box gap="spacing12" direction="row" flex="grow">
        <Box width={INPUT_WIDTH} height={{ max: '40px' }}>
          <NestedSelectDropdown
            testId="CardReport_GroupBy"
            title="Group By"
            options={groupByOptions}
            values={groupByValues}
            onChange={(values) => setGroupByValues(values as CardGroupByType)}
            customWidth={INPUT_WIDTH}
          />
        </Box>
        {/* <Box width={INPUT_WIDTH}>
          <DateRangeDropdown
            testId="CardReport_filter"
            prefix="Date:"
            className="period-picker"
            onChange={handleDateRangeChange}
            ellipsisMode
            textNotation
            startDate={dateRange ? dateRange[0] : null}
            endDate={dateRange ? dateRange[1] : null}
            icon={<CalendarIcon color="iconPrimary" />}
          />
        </Box> */}
        <Box width={INPUT_WIDTH}>
          <SearchInput
            testId="CardReport_filter-search"
            className="search-input"
            value={tempSearchValues}
            placeholder="Organization"
            onChange={setSearchTempValues}
            resetValue={initCleaning}
            hasSearchingHistory
          />
        </Box>
        <Box flex="grow">
          <SelectSSNDropdown value={ssnOption} onChangeValue={setSSNOption}/>
        </Box>
        <Box flex="grow" />
      </Box>
    </>
  );
};
