import { useFavicon,useImage } from '@common-fe/common-fe';

import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';

const useOrganizationFavicon = () => {
  const { observingDefaultData } = useOrganizationOverridableProperties();
  const logoInfo = observingDefaultData?.logoInfo;
  const faviconUrl = whiteLabelingConfig.favicon || logoInfo?.faviconUrl
    || logoInfo?.minimizedLogoUrl;
  const { isLoading, isError } = useImage(faviconUrl);
  useFavicon(faviconUrl, isLoading, isError);
};

export default useOrganizationFavicon;
