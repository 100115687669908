import { baseStoreCreator } from '@/utils/store';

export interface AddressPayload {
  id: string;
  street: string;
  street_2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const DEFAULT_VALUES: AddressPayload = {
  id: '',
  street: '',
  street_2: '',
  city: '',
  state: '',
  zipCode: '',
};

export const useAddressStore = baseStoreCreator<AddressPayload>(DEFAULT_VALUES);

export default useAddressStore;
