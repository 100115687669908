import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
// import ReconciliationReports from '@/modules/employer/components/ReconciliationReports/ReconciliationReports';
import { FinOps } from '@/modules/employer/components/ReconciliationReports/FinOps';
import theme from '@/styles/theme';

const ReconciliationReportsPage = () => {
  useBreadcrumbs([{
    route: ROUTES.FINOPS,
    title: 'FinOps',
  }]);
  return (
    <Box width="100%" height="fit-content">
      <Topbar />
      <Box align="center" margin={{ top: 'medium' }}>
        <Box width={theme.defaultContentWidth}>
          <FinOps />
        </Box>
      </Box>
    </Box>
  );
};

export default ReconciliationReportsPage;
