import React from 'react';
import {
  Box, Text, WarningLabel,
} from '@common-fe/common-fe';

import { StatusWrapper } from '@/components';

interface Props {
  isHealthPlanMode?: boolean;
  error?: boolean;
}
const ConfirmBlock: React.FC<Props> = ({ isHealthPlanMode, error }) => (
  <Box margin={{ top: 'spacing24' }}>
    <StatusWrapper status="pending">
      <Box
        margin="spacing12"
        pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
        background={{ color: 'warningContainer' }}
        border={{ size: '1px', color: 'warningBorder' }}
        round="container2Round"
      >
        <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
          <WarningLabel>
            {isHealthPlanMode ? 'Important! You can\'t change the health plan template and plan year after you create your health plan.' : 'Important! You can\'t change the plan template and plan year after you create your plan.'}
          </WarningLabel>
        </Text>

        <Box>
          <Text margin={{ bottom: 'spacing8' }} color="textBody" size="14px">
            Does everything look good? Please double-check your selections.
          </Text>
        </Box>
      </Box>

    </StatusWrapper>
  </Box>
);
export default ConfirmBlock;
