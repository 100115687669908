import { useEffect, useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { VALIDATORS } from '@/common';
import { DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';

import { taxTypeOptions } from '../../contributions.types';
import { usePayrollCalendarsQuery } from '../queries';
import { ScheduledContributionPayload } from '../ScheduledContribution.types';
import useScheduledContributionStore from '../stores/useScheduledContribution.store';

import useScheduledContributionList from './useScheduledContributionList';
interface Params {
  defaultValues?: Partial<ScheduledContributionPayload>;
  isEditMode?: boolean;
}
const ALREADY_USED_SUB_OPTION = [
  {
    value: 'Already used',
    style: {
      color: 'textDisabled',
      fontSize: '12px',
    },
  },
];
const useScheduledContributionFields = ({ defaultValues, isEditMode }: Params) => {
  const { formattedData } = useScheduledContributionList();
  const calendarId = useScheduledContributionStore((state) => state.state.calendarId);
  const startDate = useScheduledContributionStore((state) => state.state.startDate);
  const taxType = useScheduledContributionStore((state) => state.state.taxType);
  const setState = useScheduledContributionStore((state) => state.setState);
  const { options } = usePayrollCalendarsQuery();

  const currentCalendar = useMemo(() => {
    return options.find((item) => item.key === calendarId);
  }, [calendarId, options]);
  const occupiedIds = useMemo(() => formattedData.map((item) => item.calendarId), [formattedData]);

  const formattedOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      ...(occupiedIds.includes(option.key as string)
        ? {
          disabled: true,
          subValues: ALREADY_USED_SUB_OPTION,
        }
        : {}),
    }));
  }, [occupiedIds, options]);

  const isDisabledCalendarEdition = useMemo(() => {
    if (isEditMode && currentCalendar) {
      const today = dayjs();

      const currentDate = dayjs(defaultValues?.startDate, DEFAULT_DATE_FORMAT);

      return (
        currentDate?.isBefore(today) ||
        currentDate?.format(DEFAULT_DATE_FORMAT) === today?.format(DEFAULT_DATE_FORMAT)
      );
    }
    return !currentCalendar || isEditMode;
  }, [currentCalendar, defaultValues?.startDate, isEditMode]);

  useEffect(() => {
    if (defaultValues?.calendarId && defaultValues.calendarId !== calendarId && calendarId) {
      if (currentCalendar) {
        setState({
          startDate: currentCalendar.startDate?.format(DEFAULT_DATE_FORMAT),
        });
      }
    }
  }, [calendarId, defaultValues, currentCalendar, setState]);
  const minDate = useMemo(() => {
    const date = dayjs(currentCalendar?.startDate, DEFAULT_DATE_FORMAT);
    const today = dayjs();
    const currentMinDate = date > today ? date : today;
    return currentMinDate;
  }, [currentCalendar?.startDate]);
  const fields = useMemo<Field[]>(() => {
    const fields: Field[] = [
      {
        name: 'calendarId',
        label: 'Calendar',
        type: FieldTypes.Dropdown,
        placeholder: 'Select calendar',
        options: formattedOptions,
        singleMode: true,
        showRequireIcon: true,
        value: calendarId || '',
        validator: VALIDATORS.REQUIRED_STRING,
        defaultValue: defaultValues?.calendarId,
      },
      {
        name: 'startDate',
        label: 'Start Date',
        type: FieldTypes.Date,

        disabled: isDisabledCalendarEdition,
        minDate: minDate,
        showRequireIcon: true,
        value: startDate || '',
        defaultValue: defaultValues?.startDate || '',
        validator:isDisabledCalendarEdition ? VALIDATORS.DATE_FORMAT : VALIDATORS.DATE_FORMAT_AFTER_OTHER_DATE(
          minDate.add(1, 'day').format(DEFAULT_DATE_FORMAT),
          'The start date of the scheduled contribution cannot be earlier than the payroll calendar start date',
          true,
          true
        ).required(REQUIRED_TEXT),
      },
      {
        name: 'taxType',
        label: 'Tax Type',
        type: FieldTypes.Dropdown,
        singleMode: true,
        options: taxTypeOptions,
        value: taxType || '',
        placeholder: 'Select tax type',
        showRequireIcon: true,
        defaultValue: defaultValues?.taxType,
        validator: VALIDATORS.REQUIRED_STRING,
      },
    ];
    return fields;
  }, [
    formattedOptions,
    calendarId,
    defaultValues?.calendarId,
    defaultValues?.startDate,
    defaultValues?.taxType,
    isDisabledCalendarEdition,
    minDate,
    startDate,
    taxType,
  ]);
  return fields;
};
export default useScheduledContributionFields;
