import React from 'react';
import {
  Box, FormField, Text,
} from '@common-fe/common-fe';

import { PlanTypes } from '../PlanTypes.types';

import { Label, StyledContent, TextInputWrap } from './PlanViewModal.styles';

export interface Props {
  selectedPlan?: PlanTypes;
  isHealthPlanMode?: boolean;
}

const PlanViewModal: React.FC<Props> = (props) => (
  <>
    <Box background="module" round="moduleRound" pad="medium" data-testid="ContactsSecurityModal-view-wrapper">
      <StyledContent background="canvas" pad="medium" overflow="auto">
        <TextInputWrap viewMode label={<Label>{props?.isHealthPlanMode ? 'Plan types' : 'Plan type'}:</Label>}>
          <FormField>
            <Text weight="normal" data-testid="ContactsSecurityModal-email-label">{props?.isHealthPlanMode ? (props?.selectedPlan?.planTypes?.join(', ') || '-') : (props?.selectedPlan?.planType)}</Text>
          </FormField>
        </TextInputWrap>
        {
          
        }
        <TextInputWrap viewMode label={<Label>Plan name:</Label>}>
          <FormField>
            <Text weight="normal" data-testid="ContactsSecurityModal-userName-label">{ props?.selectedPlan?.planName || '-'}</Text>
          </FormField>
        </TextInputWrap>
      </StyledContent>
    </Box>
  </>
);

export default PlanViewModal;
