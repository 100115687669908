import { storeCreator } from '@/utils/store/store.utils';

export interface State {
  newItemId: string;
  setNewItemId: (value: string) => void;
  resetNewItemId: () => void;
}

const useStore = storeCreator<State>((set) => ({
  newItemId: '',
  setNewItemId: (value) => set(() => ({
    newItemId: value,
  })),
  resetNewItemId: () => set(() => ({
    newItemId: '',
  })),
}));

export default useStore;
