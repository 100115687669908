 
import React from 'react';
import {
  Box, costFormater,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import theme from '@/styles/theme';

interface Props {
  plan?: string;
  maxAmount?: number;
  percentage?: number;
  isMarginApplied?: boolean;
}

export const TextWrapper = styled(Text)`
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PaymentRuleDetails: React.FC<Props> = ({
  plan, maxAmount, percentage, isMarginApplied,
}) => (
  <Box
    round="container1Round"
    background="canvas"
    pad="m"
    width="100%"
    border={{ side: 'all', size: 'small', color: theme.colors.border1 }}
    margin={{
      right: isMarginApplied ? 'xs' : '0',
    }}
    height="80px"
  >
    <TextWrapper
      size="medium"
      color="textBody"
      weight={700}
      margin={{
        bottom: 'xs',
      }}
    >
      {plan ? 'Account to pay from' : maxAmount ? 'Amount to pay' : 'Percentage to pay'}
    </TextWrapper>
    <TextWrapper
      size="medium"
      color="textBody"
      weight={400}
    >
      {plan || (maxAmount && costFormater(maxAmount, true))
      || (percentage && `${Math.floor(percentage * 100)}%`)}
    </TextWrapper>
  </Box>
);

export default PaymentRuleDetails;
