import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { EMAIL_INVALID_TEXT,PHONE_NUMBER_INVALID_TEXT, REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { PaymentMethodOwnerType } from '@/modules/employee/employee.types';

import { usePaymentMethodsStore } from '../../usePaymentMethods.store';

export enum PayPalFields {
  email = 'email',
  phoneNumber = 'phoneNumber',
  paymentMethodOwnerType = 'paymentMethodOwnerType',
}
export interface PayPalInfo {
  [PayPalFields.email]?: string;
  [PayPalFields.phoneNumber]?: string;
  [PayPalFields.paymentMethodOwnerType]?: PaymentMethodOwnerType;
}

export const usePayPalFields = (values: PayPalInfo) => {
  const editableMethod = usePaymentMethodsStore((store) => store.editableMethod);

  const validatePhoneNumber = useMemo(
    () => !values.email || (values.phoneNumber && values.phoneNumber.length > 4),
    [values.email, values.phoneNumber],
  );

  const fields = useMemo<Field[]>(() => [
    {
      name: PayPalFields.email,
      type: FieldTypes.Text,
      label: 'Email',
      placeholder: 'Email',
      testId: 'PayPal_email',
      labelStyle: { maxWidth: '276px' },
      value: values.email || '',
      showRequireIcon: true,
      defaultValue: editableMethod?.requisites?.email || '',
      validator: yup.string().test({
        test: (val) => regexp.EMAIL.test(val || ''),
        message: EMAIL_INVALID_TEXT,
      }).required(REQUIRED_TEXT),
    },
    {
      name: 'phoneNumber',
      type: FieldTypes.PhoneNumber,
      label: 'Phone number',
      labelStyle: { maxWidth: '276px' },
      placeholder: '+1',
      testId: 'PayPal',
      value: values.phoneNumber || '',
      defaultValue: editableMethod?.requisites?.phoneNumber || '',
      validator: validatePhoneNumber
        ? yup.string()
          .min(17, PHONE_NUMBER_INVALID_TEXT)
          .required(REQUIRED_TEXT)
        : undefined,
    },
  ], [validatePhoneNumber, values, editableMethod]);

  return fields;
};
