import { storeCreator } from '@/utils/store/store.utils';

interface State {
  editMode: boolean;
  setEditMode: (value: boolean) => void;
}

export const useStore = storeCreator<State>((set) => ({
  editMode: false,
  setEditMode: (value: boolean) => set(() => ({
    editMode: value,
  })),
}));
export default useStore;
