import { useMemo } from 'react';

import { useBasicPropertiesFieldsSourceState } from '../BasicProperties/hooks';
import { useCoverageLevelsFieldsSourceState } from '../CoverageLevels/hooks';

export default () => {
  const basicPropertiesState = useBasicPropertiesFieldsSourceState();
  const coverageLevelsFieldsSourceState = useCoverageLevelsFieldsSourceState();
  const state = useMemo(() => ({
    ...basicPropertiesState,
    ...coverageLevelsFieldsSourceState,
  }), [basicPropertiesState, coverageLevelsFieldsSourceState]);
  return state;
};
