import React, { useCallback, useState } from 'react';
import {
  AppButton, Box, CheckBox, PopupWrapper, SetupOutlinedIcon, Text,
} from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';

import { CopayStatuses } from '../CopayAdjudication.constant';

interface Props {
  optionKeys: OptionKey[];
  onSetOptionsKeys: (values: OptionKey[]) => void;
}

const OPTIONS = [
  {
    type: CopayStatuses.Active,
    title: 'Active copay groups',
  },
  {
    type: CopayStatuses.Expired,
    title: 'Expired copay groups',
  },
];
const CopayStatusPicker: React.FC<Props> = ({ optionKeys, onSetOptionsKeys }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentOptionKeys, setCurrentOptionKeys] = useState<OptionKey[]>(
    optionKeys,
  );
  const handlePickOptionKey = useCallback((optionKey: OptionKey) => {
    if (currentOptionKeys.includes(optionKey)) {
      setCurrentOptionKeys(currentOptionKeys.filter((key) => key !== optionKey));
    } else {
      setCurrentOptionKeys([...currentOptionKeys, optionKey]);
    }
  }, [currentOptionKeys]);
  const handleApply = useCallback(() => {
    onSetOptionsKeys(currentOptionKeys);
    setExpanded(false);
  }, [currentOptionKeys, onSetOptionsKeys]);

  return (
    <Box data-testId="select-carrier-type-wrapper" margin={{ left: 'spacing6' }} style={{ position: 'relative' }}>
      <PopupWrapper
        onVisible={setExpanded}
        controlledVisible={expanded}
        content={
          (
            <Box round="container1Round" elevation="active" width={{ min: '264px' }} data-testId="select-carrier-type-popup">
              <Box pad="spacing16" border={{ side: 'bottom', color: 'border1' }}>
                <Text weight={700}>Display</Text>
                {
                  OPTIONS.map((option) => (
                    <Box key={option.type} margin={{ top: 'spacing12' }}>
                      <CheckBox
                        name="primary"
                        role="checkbox"
                        data-testId={`copay-checkbox_${option.type}`}
                        aria-label="primary"
                        checked={currentOptionKeys.includes(option.type)}
                        onChange={() => handlePickOptionKey(option.type)}
                        label={
                          <Text style={{ fontSize: 14 }}>{option.title}</Text>
                        }
                      />
                    </Box>
                  ))
                }
              </Box>
              <Box pad="spacing16" direction="row" justify="between">
                <AppButton
                  testId="close-carrier-popup"
                  width="110px"
                  buttonType="secondary"
                  onClick={() => setExpanded(false)}
                >
                  Cancel
                </AppButton>

                <AppButton
                  testId="apply-copay"
                  width="110px"
                  disabled={!currentOptionKeys.length}
                  onClick={handleApply}
                >
                  Apply
                </AppButton>
              </Box>
            </Box>
          )
        }
        flat
        flex
        testId="select-copay-status"
      >
        <AppButton
          testId="select-copay-status"
          color="grey"
          style={{ width: '40px' }}
          buttonType="secondary"
          active={expanded}
          onlyIcon
        >
          <SetupOutlinedIcon />
        </AppButton>
      </PopupWrapper>
    </Box>
  );
};

export default CopayStatusPicker;
