import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { Pagination, PaginationParams } from '@/common/types';
import { Employee, StatusNames } from '@/modules/employee/employee.types';

export const QUERY_KEY = 'useEmployeesList';

const getFullStatusName = (firstLetter: keyof typeof StatusNames) => StatusNames[firstLetter];

export interface EmployeeWithActivePlanPayload {
  employeeId?: number;
  employerEmployeeId?: number;
  firstName?: string;
  lastName?: string;
  partnerEmployeeId?: number;
  ssn?: string;
  status?: string;
}

export interface EmployeeWithActivePlan {
  employeeId: string;
  employerEmployeeId: string;
  firstName?: string;
  lastName?: string;
  partnerEmployeeId: string;
  ssn?: string;
  status?: string;
}

export interface EmployeeWithActivePlanParams extends PaginationParams {
  observingOrganizationId?: string;
  searchEmployerId?: string;
  searchSubsidiaryId?: string;
  observingOrganizationType?: OrganizationTypes;
  sortByField: string;
}

const useGetEmployeesWithActivePlanQeury = ({
  sortByField,
  page,
  perPage,
  searchString,
  sortBy,
  observingOrganizationId,
}: EmployeeWithActivePlanParams) => {
  const sortByUpperCase = `${sortBy}`.toUpperCase();
  const { isLoading, data, isSuccess } = useQuery({
    queryKey: [
      QUERY_KEY,
      page,
      perPage,
      searchString,
      sortBy,
      observingOrganizationId,
    ],
    queryFn: () => api.get<Pagination<EmployeeWithActivePlanPayload>>(PATHS.GET_EMPLOYEES_WITH_ACTIVE_PLANS, {
      params: {
        page,
        size: perPage,
        sort: `${sortByField},${sortByUpperCase}`,
        ...searchString ? { search_value: searchString } : {},
      },
    }),
  });
  const formattedData = useMemo(() => data?.data.content?.map(
    (item: EmployeeWithActivePlanPayload): EmployeeWithActivePlan => ({
      employeeId: _.toString(item?.employeeId),
      employerEmployeeId: _.toString(item.employerEmployeeId),
      firstName: item?.firstName,
      lastName: item?.lastName,
      partnerEmployeeId: _.toString(item?.partnerEmployeeId),
      ssn: item?.ssn,
      status: getFullStatusName(item.status as keyof typeof StatusNames),
    }),
  ), [data]);
  const total = useMemo<number>(() => _.get(data, 'data.totalElements', 0) || 0, [data]);

  return ({
    formattedData,
    isLoading,
    total,
    isSuccess,
  });
};

export default useGetEmployeesWithActivePlanQeury;
