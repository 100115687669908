import React from 'react';

// import { Box } from '@common-fe/common-fe';
// import { useRouteMatch } from 'react-router-dom';
import { CardDetails } from '@/modules/transaction/CardDetails/CardDetails';

const CardsBlock = () => (
  // <Box direction="column" background="module" pad={{ top: 'spacing12' }} round="container1Round">

  <CardDetails />
  // </Box>
);
export default CardsBlock;
