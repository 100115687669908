 

import React, { useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  CarrierConfigOutlineIcon,
  CensusIcon,
  ContributionIcon,
  EnrollmentIcon,
  ErrorIcon,
  Inscription,
  ListItem,
  LoadingIcon,
  MixIcon,
  NullIcon,
  Unknown,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DATE_MASK_TIME,MINIMIZE_DATE_FORMAT } from '@/common/constants';
import routes from '@/common/routes';
import { ListItemType } from '@/common/types';
import { useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import {
  FileDtoWithMediaSource,
  FileRecordType, FileStatus, } from '../FileManager.constants';
import FileWarning from '../FileWarning';
import useFilesListQuery, { FilesQueryParams } from '../queries/useFilesList.query';
import useFilesProcess from '../queries/useFilesProcess.query';

import ProcessLink from './ProcessLink';
import useBacklightStop from './useBacklightStop';
import useUpdatingFiles from './useUpdatingFiles';

export const HIDDEN_UPLOADING_WAY = 'UI';

const StyledEnrollmentIcon = styled(EnrollmentIcon)`
  width: 24px;
  height: 24px;
`;
const StyledErrorMesage = styled(Inscription)`
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
`;
const EMPTY_FIELD = '–';

const isDate = (date: string) => dayjs(date).isValid();

const getDate = (date: string) => {
  if (!isDate(date)) {
    return date;
  }

  return dayjs(date).format(MINIMIZE_DATE_FORMAT);
};
const getTime = (date: string) => {
  if (!isDate(date)) {
    return date;
  }

  return dayjs(date).format(DATE_MASK_TIME).replace(/_/, 'at');
};

export const getAvatar = (type: string, status?: string) => {
  if (status === FileStatus.Processing) {
    return <LoadingIcon />;
  }

  if (type === FileRecordType.Census) {
    return <CensusIcon color={theme.colors.fileManagerTypes.cencusType} size="24px" />;
  }

  if (type === FileRecordType.Enrollment) {
    return <StyledEnrollmentIcon color={theme.colors.fileManagerTypes.enrollmentType} />;
  }

  if (type === FileRecordType.Contribution) {
    return <ContributionIcon color={theme.colors.fileManagerTypes.contributionType} size="24px" />;
  }

  if (type === FileRecordType.Error) {
    return <ErrorIcon color={theme.colors.fileManagerTypes.unknownType} size="24px" />;
  }

  if (type === FileRecordType.Unknown) {
    return <Unknown color={theme.colors.fileManagerTypes.unknownType} size="24px" />;
  }
  if (type === FileRecordType.Dependent) {
    return <CensusIcon color={theme.colors.fileManagerTypes.cencusType} size="24px" />;
  }
  if (type === FileRecordType.Mixed) {
    return <MixIcon color={theme.colors.fileManagerTypes.mixType} size="24px" />;
  }

  if (type === FileRecordType.Carrier) {
    return <CarrierConfigOutlineIcon color={theme.colors.fileManagerTypes.carrierType} />;
  }

  return <LoadingIcon />;
};

const AVATAR_STYLE = {
  width: '34px',
  height: '34px',
  padding: '3px',
  border: `1px solid ${theme.colors.border1}`,
  borderRadius: '4px',
};

interface Props {
  names: string[],
}

const ManyNames: React.FC<Props> = ({ names }) => {
  if (!names.length) {
    return null;
  }

  const joinedNames = names.length > 1 ? names.length - 1 : '';

  return (
    <Inscription style={{ display: 'flex' }} title={names.join(', ')} margin={{ right: 'spacing12' }}>
      <Inscription hasEllipsisMode>{names[0]}</Inscription>
      {joinedNames && ', '}
      {joinedNames && <Inscription color="textAccent">+{joinedNames}</Inscription>}
    </Inscription>
  );
};

const FormattedDate: React.FC<{ from: string, to: string }> = ({ from, to }) => {
  if (!from && !to) {
    return null;
  }

  return (
    <Inscription>
      {from ? <Inscription title={getTime(from)}>{getDate(from)}</Inscription> : null}
      {Boolean(from && to) && <Inscription color="textSecondary"> / </Inscription>}
      {to ? <Inscription title={getTime(to)}>{getDate(to)}</Inscription> : null}
    </Inscription>
  );
};

// const Marker = styled(Inscription)`
//   padding-inline: 3px;
//   background: #E5E9F0;
//   border-radius: 4px;
//   font-weight: 500;
//   min-height: 18px;
//   min-width: 24px;
// `;

const UploadedBy: React.FC<{ name: string, way: string }> = ({ name, way }) => {
  const hasWay = way && way !== HIDDEN_UPLOADING_WAY;

  return (
    <Box direction="row" align="center" justify="between">
      <Inscription
        title={name}
        hasEllipsisMode
        {...hasWay ? {} : { margin: { right: 'xxs' } }}
      >
        {name}
      </Inscription>

      {hasWay && (
        <Box
          background={{ color: 'border1' }}
          width={{ min: '24px' }}
          height={{ min: '18px' }}
          round="spacing4"
          margin={{ left: 'spacing4', right: '17px' }}
          align="center"
        >
          <Inscription
            size="xsmall"
            color="textBody"
            weight={500}
          >
            {way}
          </Inscription>
        </Box>
      )}
    </Box>
  );
};

const useFilesList = (params: FilesQueryParams) => {
  const history = useHistory();
  const { uploadedFiles, onRemoveRow, reuploadingFile } = params;
  
  const {
    files, isLoading, total,
  } = useFilesListQuery(params);

  const allFiles = useMemo(() => ([
    ...uploadedFiles,
    ...files,
  ]), [uploadedFiles, files]);
  const {
    onFileProcess, processedFiles,
  } = useFilesProcess(allFiles as FileDtoWithMediaSource[], isLoading);
  const refetchedFiles = useUpdatingFiles(processedFiles, isLoading);

  const updatedFiles = useBacklightStop(refetchedFiles);

  const formattedList = useMemo(() => updatedFiles
    .map((file: FileDtoWithMediaSource) => {
      const isWarrning = (file.status === FileStatus.Completed)
        && !!file.errorsCount;

      return ({
        id: file.id || file.temporalId,
        fields: [
          {
            key: 'avatar',
            type: ListItemType.Avatar,
            node: (
              <Box
                justify="center"
                align="center"
                style={AVATAR_STYLE}
              >
                {getAvatar(file.type, file.status)}
              </Box>
            ),
            flex: '0 1 44px',
          },
          {
            key: 'recordTypeAndName',
            type: ListItemType.Text,
            title: file.name || EMPTY_FIELD,
            flex: 1.15,
            ellipsisMode: true,
            ...file?.type && file.type.toLowerCase() !== 'unknown' ? {
              style: {
                cursor: 'pointer',
              },
              onClick: () => history.push(`${routes.FILE_DETAILS}/${file.id || file.temporalId}`),
              hover: true,
            } : {},
          },
          {
            key: 'partner',
            type: ListItemType.Node,
            flex: 0.8,
            node: file.partnersNames?.length
              ? <ManyNames names={file.partnersNames} />
              : file.hasReuploading ? '' : EMPTY_FIELD,
          },
          {
            key: 'employer',
            type: ListItemType.Node,
            flex: 0.9,
            node: file.employersNames?.length
              ? <ManyNames names={file.employersNames} />
              : file.hasReuploading ? '' : EMPTY_FIELD,
          },
          {
            key: 'uploadedBy',
            type: ListItemType.Node,
            node: file.hasReuploading ? '' : file.uploadedBy
              ? <UploadedBy name={file.uploadedBy} way={file.uploadWay} />
              : EMPTY_FIELD,
            flex: 0.85,
            style: {
              color: theme.colors.textSecondary,
            },
          },
          ...file.hasReuploading ? [
            {
              key: 'errorMesage',
              type: ListItemType.Node,
              node: <StyledErrorMesage color="danger">Oops! We couldn't upload the file.</StyledErrorMesage>,
              flex: 1.1,
            },
          ] : [
            {
              key: 'dateReceivedProcessed',
              type: ListItemType.Node,
              node: file.createdAt || file.processedAt
                ? <FormattedDate from={file.createdAt} to={file.processedAt} />
                : EMPTY_FIELD,
              flex: 1.1,
            },
          ],
          ...isWarrning ? [{
            key: 'fileWarning',
            type: ListItemType.Node,
            node: <FileWarning />,
            flex: 0.45,
          }] : [ {
            key: 'dateReceivedProcessed',
            type: ListItemType.Node,
            
            flex: 0.45,
          },],
          ...file.hasReuploading
            ? [{
              key: 'reupload',
              type: ListItemType.Node,
              node: <ProcessLink onFileProcess={reuploadingFile} file={file} title="Reupload" />,
              flex: 0.6,
            }]
            : [{
              key: 'fileStatus',
              type: ListItemType.Status,
              title: capitalizeFirstLetter(file.status),
              flex: 0.9,
              style: {
                flexDirection: 'row-reverse',
                gap: theme.spacings.spacing8,
                marginRight: '20px',
              },
            }],
        ],
        ...(file.status === FileStatus.Uploading || file.hasReuploading ? {
          onRemoveRow,
        } : {}),
        ...(file.backlighting ? { className: `backlighting_${file.backlighting.toLowerCase()}` } : {}),
        ...file.id === params.sendingToProcessFileId ? {
          className: `backlighting_${FileStatus.Uploading.toLowerCase()}`,
        } : {},
      });
    }),
  [params, updatedFiles, reuploadingFile, onRemoveRow, history]);

  const isEmpty = useMemo(() => updatedFiles.length === 0, [updatedFiles.length]);

  return {
    isEmpty,
    list: files,
    rows: formattedList as ListItem[] || [],
    isLoading,
    total,
    updatedFiles,
    onFileProcess,
  };
};

export default useFilesList;
