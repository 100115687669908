import React, { useMemo } from 'react';
import {
  Box,
  capitalizeFirstLetter,
  Text,
} from '@common-fe/common-fe';

import regexp from '@/common/regexp';
import globalTheme from '@/styles/theme';

import { InvestorStatus } from '../../employee.types';
import { Investors } from '../../Employee/queries/useGetInvestors.query';

interface Props {
  investorData: Investors;
}
  
export const EnrollmentDetailsInvestmentStatus: React.FC<Props> = ({
  investorData,
}) => {
  const preparedRiskModel = useMemo(() => investorData?.toleranceModel
    ? investorData.toleranceModel.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' ')
    : undefined,
  [investorData]);
  const preparedType = useMemo(() => investorData?.type
    ? investorData.type.split(regexp.DASH_SYMBOL).map((item) => capitalizeFirstLetter(item)).join(' ')
    : undefined,
  [investorData]);

  const investorStatus = useMemo(() => {
    if (!investorData?.status
      || investorData?.status === InvestorStatus.PENDING_INVESTMENT_PATH) {
      return {
        color: 'textDisabled',
        text: 'Not Activated',
      };
    }
    if (investorData?.status === InvestorStatus.ACTIVE) {
      return {
        color: 'success',
        text: 'Active',
      };
    }
    return {
      color: 'textBody',
      text: 'Pending',
    };
  }, [investorData]);

  return (
    <Box
      pad="spacing24"
      background="canvas"
      round="container1Round"
      border={{ color: 'border2' }}
      gap="spacing16"
      style={{
        boxShadow: globalTheme.shadows.default['box-shadow'],
      }}
    >
      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Investments
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text
            size="medium"
            weight={400}
            color={investorStatus?.color}
          >
            {investorStatus?.text}
          </Text>
        </Box>
      </Box>

      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Investments model
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text size="medium" weight={400}>
            {preparedType ? preparedType : '-'}
          </Text>
        </Box>
      </Box>
      
      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Risk model
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text size="medium" weight={400}>
            {preparedRiskModel ? preparedRiskModel : '-'}
          </Text>
        </Box>
      </Box>

      <Box 
        direction="row"
        height={{ min: '40px', max: '40px' }}
        align="center"
      >
        <Box width={{ max: '256px', min: '256px' }}>
          <Text size="medium" weight={700} style={{ lineHeight: '20px' }}>
            Automatic Investments 
          </Text>
        </Box>
        <Box direction="row" justify="between" height={{ max: '24px' }}>
          <Text
            size="medium"
            weight={400}
            color={investorData?.automaticInvestmentEnabled ? 'success' : 'textDisabled'}
          >
            {investorData?.automaticInvestmentEnabled ? 'Activated' : 'Disabled'}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
  