import React, {
  useCallback, 
  useMemo, useState, } from 'react';
import {
  AppButton,
  Box,   FlexControlledForm,
  Preloader, SiblingBoxesWithSpacing,
  WarnModal, } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { ERRORS_CODE } from '@/common/constants';
import {
  GlobalLoaderMessages,
  useGlobalLoaderStore,
} from '@/components/elements/FullLoader/store/useGlobalLoader.store';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';

import usePlanModalAddressFields from './hooks/useEmployerSetupAddressModalFields';
import useOrganizationSetupModalFields, {
  CUSTOM_ID,
  FIELDS,
  GENERATE_ID,
} from './hooks/useEmployerSetupModalFields';
import { useCreateEmployerQuery } from './queries';

interface FormValues {
  EmployeeIdField: string;
  employerName: string;
  partner: string;
  employerId?: string;
  street: string;
  street_2: string;
  city: string;
  state: string;
  zipCode: string;
}
const DEFAULT_FORM_VALUES: FormValues = {
  EmployeeIdField: '',
  employerName: '',
  partner: '',
  street: '',
  street_2: '',
  city: '',
  state: '',
  zipCode: '',
};

interface Props {
  onCancel?: () => void;
  loading?: boolean;
  mode: EmployerSetupModes;
  setIsBasicFormDirty: (isDirty: boolean) => void;
  setIsAddressFormDirty: (isDirty: boolean) => void;
  showConfirmModal: (show: boolean) => void;
}
interface Errors {
  [key: string]: string;
}

const EmployerSetupFormModal: React.FC<Props> = ({
  onCancel,
  loading,
  mode,
  setIsAddressFormDirty,
  setIsBasicFormDirty,
  showConfirmModal,
}) => {
  const [errorModal, setErrorModal] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const { onSave } = useCreateEmployerQuery();
  const [activeEmployerSetup, setActiveEmployerSetup] = useState(false);
  const [activeEmployerSetupAddress,
    setActiveEmployerSetupAddress,
  ] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const fields = useOrganizationSetupModalFields({ mode, errors });

  const fieldsAddress = usePlanModalAddressFields({});
  const { setLoader, removeLoading } = useGlobalLoaderStore();

  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);

  const handleChangeEmployerValues = useCallback((values: FormValues) => {
    const newValues = { ...values };
    const customId = values.employerId === CUSTOM_ID && !!values.EmployeeIdField;
    const autoId = values.employerId === GENERATE_ID;
    if (autoId) {
      // @ts-ignore
      delete newValues.EmployeeIdField;
    }
    const filledObj = Object.values(newValues).every(Boolean);
    setFormValues((prev) => ({ ...prev, ...values }));
    if (filledObj) {
      if (customId) {
        setActiveEmployerSetup(true);
      }
      if (autoId) {
        setActiveEmployerSetup(true);
      }
    } else setActiveEmployerSetup(false);
  }, []);

  const handleChangeAddressValues = useCallback((values: FormValues) => {
    // const newValues = { ...values };
    // const filledObj = Object.values(newValues).every(Boolean);
    setFormValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap = fieldsAddress.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setActiveEmployerSetupAddress(true);
    } catch {
      setActiveEmployerSetupAddress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const loaderMessage = useMemo(() => {
    switch (mode) {
    case EmployerSetupModes.distributor: {
      return GlobalLoaderMessages.DISTRIBUTOR_INITIAL_CREATION;
    }
    case EmployerSetupModes.employer: {
      return GlobalLoaderMessages.EMPLOYER_INITIAL_CREATION;
    }
    case EmployerSetupModes.subsidiary: {
      return GlobalLoaderMessages.SUBSIDIARY_INITIAL_CREATION;
    }
    default: {
      return GlobalLoaderMessages.PARTNER_INITIAL_CREATION;
    }
    }
  }, [mode]);
  const handleSubmit = useCallback(async () => {
    setSubmit(true);
    if (activeEmployerSetup && activeEmployerSetupAddress) {
      try {
        setLoader({ message: loaderMessage });
        showConfirmModal(false);
        await onSave(formValues, mode);
      } catch (e) {
        showConfirmModal(true);
        const code = _.get(e, 'response.status', 0) as number;
        const message = _.get(e, 'response.data.elevate_error_message', '') as string;
        if (code === ERRORS_CODE.BAD_REQUEST || code === ERRORS_CODE.CONFLICT) {
          if (message.includes('Organization with external identifier')) {
            setErrors({
              [FIELDS.CUSTOM_EMPLOYER_ID]: formValues.EmployeeIdField,
            });
          }
        } else {
          setErrorModal(true);
          await removeLoading();
        }
      } finally {
        await removeLoading(2000);
      }
    }
  }, [
    loaderMessage,
    activeEmployerSetup,
    activeEmployerSetupAddress,
    setLoader,
    mode,
    formValues,
    onSave,
    removeLoading,
    showConfirmModal,
  ]);

  return (
    <Box data-testid="employer-create-modal">
      <WarnModal
        testId="new-employer"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Employer with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
      <Box direction="column">
        <Box round="moduleRound" pad="spacing24" background="module">
          <Box round="container1Round" background="canvas" margin={{ bottom: 'spacing24' }}>
            <FlexControlledForm
              testId="new-organization-basic"
              fields={fields}
              editMode
              onChangeValues={handleChangeEmployerValues}
              showError={isSubmit}
              isModalType
              onDirty={setIsBasicFormDirty}
            />
          </Box>
          <Box round="container1Round" background="canvas">
            <FlexControlledForm
              formTitle="Address"
              testId="new-organization-address"
              fields={fieldsAddress}
              editMode
              onChangeValues={handleChangeAddressValues}
              showError={isSubmit}
              isModalType
              onDirty={setIsAddressFormDirty}
            />
          </Box>
        </Box>
      </Box>
      <Box
        direction="row"
        fill="horizontal"
        margin={{ top: 'spacing24' }}
        align="end"
        justify="end"
        width="medium"
      >
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="setup_employer_cancel"
            buttonType="secondary"
            width="100%"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
        </SiblingBoxesWithSpacing>
        <SiblingBoxesWithSpacing width="control">
          <AppButton
            testId="setup_employer_confirm"
            width="100%"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? <Preloader color="white" /> : 'Next'}
          </AppButton>
        </SiblingBoxesWithSpacing>
      </Box>
    </Box>
  );
};

export default EmployerSetupFormModal;
