import React, {} from 'react';
import {
  Box, ErrorModal,
  SuccessModal, Text, } from '@common-fe/common-fe';

interface Props {
  children: React.ReactNode;
  isSuccessModal: boolean;
  onCloseModal: () => void;

  isErrorModal: boolean;
}
const QuestionaireWrapper: React.FC<Props> = ({
  children, isSuccessModal, onCloseModal, isErrorModal,
}) => (
  <Box data-testid="questionnaire-wrapper">

    <SuccessModal
      testId="questionnaire-wrapper_success"
      visible={isSuccessModal}
      onSetVisible={onCloseModal}
      header="Your HSA is now active!"
      buttonText="Got It!"
      onSubmit={onCloseModal}
    />
    <ErrorModal
      testId="questionnaire-wrapper_error"
      visible={isErrorModal}
      onSetVisible={onCloseModal}
      header={'We\'re Sorry, we\'re unable to open your HSA'}
      helpText={`We cannot verify your details with the information provided.
        Please contact your administrator for next steps.`}
      buttonText="Got It!"
      onSubmit={onCloseModal}
    />

    <Text size="3xl" weight={700}>
      We need a little more information
    </Text>
    <Text size="large" margin={{ top: 's' }}>
      In order to establish your HSA, we are required to verify your identity.
    </Text>

    {children}

  </Box>
);
export default QuestionaireWrapper;
