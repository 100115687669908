import React from 'react';
import { useParams } from 'react-router-dom';
import {
  AppButton,
  Box,
  NewTabIcon,
  Text,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';

interface Props {
  hasSubmitClaimButton?: boolean;
}

export const ExpenseDetailsHeader: React.FC<Props> = ({ hasSubmitClaimButton }) => {
  const { id: employeeId } = useParams<{ id: string }>();
  return (
    <Box margin={{ bottom: 'spacing12' }} direction="row" justify="between" align="center" data-testid="ExpenseDetailsHeader-wrapper">
      <Text size="21px" weight="bold" data-testid="ExpenseDetailsHeader-title">Expense Details</Text>
      {hasSubmitClaimButton && (
        <Box direction="row" justify="end" align="center">
          <AppButton
            testId="Redirect_to_reimburse_me_page"
            buttonType="secondary"
            onClick={() => window.open(
              `${ROUTES.REIMBURSE_ME(employeeId)}${window.location.search}`,
              '_blank',
            )}
            rightIcon={<NewTabIcon />}
          >
            Submit Claim
          </AppButton>
        </Box>
      )}
    </Box>
  );
};
