import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

import { ScheduledContributionStatus } from '../ScheduledContribution.types';

import { SCHEDULED_CONTRIBUTION_QUERY_KEY } from './useScheduledContributionList.query';

interface Payload {
  id: string;
  status: ScheduledContributionStatus;
}
interface Params {
  onSuccess: () => void;
}

const useUpdateStatusScheduledContributionQuery=  ({ onSuccess }: Params) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    (payload: Payload) =>
      api.patch(
        `${PATHS.SCHEDULED_CONTRIBUTION}/${payload.id}/status`,
        undefined,
        {
          params: {
            status: payload.status,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SCHEDULED_CONTRIBUTION_QUERY_KEY]);
        onSuccess();
      }
    }
  );

  return {
    isLoading: isLoading,
    isSuccess: isSuccess,
    handleSave: mutateAsync,
  };
};
export default useUpdateStatusScheduledContributionQuery;