import { useMemo } from 'react';

import { useHealthPlanNameFieldsStateStore } from '../HealthPlanName/stores';
import { usePlanYearFieldsStateStore } from '../PlanYear/stores';

export default () => {
  const healthPlanNameStore = useHealthPlanNameFieldsStateStore((state) => state.sourceState);
  const planYearStore = usePlanYearFieldsStateStore((state) => state.sourceState);
  const state = useMemo(() => ({
    ...healthPlanNameStore,
    ...planYearStore,
  }), [healthPlanNameStore, planYearStore]);
  return state;
};
