import React, {
  useState,
} from 'react';
import { Prompt,useParams } from 'react-router-dom';
import {
  BasicPropertiesIcon,
  Box,
  FeaturesIcon,
  Preloader,
} from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { BlockWrapper } from '@/components/wrappers';
import { useBreadcrumbs } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import { useGetPayoutDefinition } from './hooks';
import PayoutDefinitionName from './PayoutDefinitionName';
import { Services } from './Services';

const PayoutDefinitionSetup: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { formattedData, isLoading } = useGetPayoutDefinition(id);
  const [editMode] = useState<boolean>(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = React.useState(false);
  useBreadcrumbs([{
    route: ROUTES.PROCESSING_DEFINITION,
    title: 'Processing Definition',
  }, {
    route: ROUTES.PROCESSING_DEFINITION_DETAILS(id),
    title: formattedData?.name || '',
  }], [formattedData, id]);

  return (
    <Box>
      <Prompt
        when={
          editMode && shouldConfirmOnClose
        }
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <BlockWrapper
        flatMode
        title="Basic Properties"
        icon={<BasicPropertiesIcon color="iconAccent" />}
        wrapperStyle={{ marginTop: theme.spacings.l }}
      >
        {
          !formattedData && isLoading
            ? (
              <Box width="100" justify="center" align="center">
                <Preloader />
              </Box>
            ) : (
              <PayoutDefinitionName
                data={formattedData}
                onDirty={setShouldConfirmOnClose}
              />
            )
        }
      </BlockWrapper>
      <Box border={{ side: 'bottom', size: 'small', color: 'border1' }} margin={{ top: 's' }} />

      <BlockWrapper
        flatMode
        title="Allowed Services and Payment Sequence"
        icon={<FeaturesIcon color="iconAccent" />}
        wrapperStyle={{ marginTop: theme.spacings.s }}
      >
        {
          !formattedData && isLoading
            ? (
              <Box width="100" justify="center" align="center">
                <Preloader />
              </Box>
            ) : (
              <Services
                data={formattedData}
              />
            )
        }
      </BlockWrapper>
      <Box border={{ side: 'bottom', size: 'small', color: 'border1' }} />
      {/* <FinalSetUp
        employerName={employer?.employerName}
        status={status}
      /> */}
    </Box>
  );
};

export default PayoutDefinitionSetup;
