export const PLAN_SETUP_FIELD = {
  PLAN_TEMPLATE_NAME: 'planTemplateName',
  PLAN_NAME: 'planName',
  PLAN_SUMMARY: 'planSummary',
  PLAN_TEMPLATE: 'planTemplate',
  PLAN_TYPE: 'planType',
  PARENT_TEMPLATE: 'parentTemplate',
  PLAN_CODE: 'planCode',
  PRIOR_PLAN_ID: 'priorPlanId',
  PLAN_YEAR: 'planYear',
  MAXIMUM_ELECTION_AMOUNT_CUSTOM: 'maximumElectionAmountCustom',
  MAXIMUM_ELECTION_AMOUNT_IRS: 'maximumElectionAmountIRS',
  MINIMUM_ELECTION_AMOUNT_CUSTOM: 'minimumElectionAmountCustom',
  PROOF_OF_EXPENSE: 'proofOfExpense',
  GENERATE_STATEMENTS: 'generateStatements',
  PLAN_LEVEL_FUNDING: 'planLevelFunding',
  REPLENISHMENT_BANK_ACCOUNT: 'replenishmentBankAccount',
  REPLENISHMENT_FREQUENCY: 'replenishmentFrequency',
  REPLENISHMENT_METHOD: 'replenishmentMethod',
  REPLENISHMENT_DAY_NUMBER: 'replenishmentDayNumber',
  AI_ADJUDICATION_TYPE: 'aiAdjudicationType',
  SPEND_LIMIT: 'spendLimit',
  SPEND_PERIOD: 'spendPeriod',
  SPEND_LIMIT_IRS_AMOUNT: 'spendLimitIRSAmount',
  SPEND_LIMIT_CUSTOM: 'spendLimitCustom',
  IS_PRE_FUND_CALCULATED:'isPreFundCalculated',
  REPLENISHMENT_PERCENT: 'replenishmentPercent',
  REPLENISHMENT_MIN_VALUE: 'replenishmentMinValue',
  REPLENISHMENT_CREEP_PERCENT: 'replenishmentCreepPercent',
  REPLENISHMENT_CREEP_MIN: 'replenishmentCreepMin',
  AVAILABLE_BALANCE: 'availableBalance',
  MAXIMUM_ELECTION_AMOUNT: 'maximumElectionAmount',
  MINIMUM_ELECTION_AMOUNT: 'minimumElectionAmount',

  CARD_OFFERED: 'cardOffered',

  FUND_ID_FOMRULA: 'fundIDFormula',

  OMNIBUS: 'omnibus',
  ACCOUNT_CREATION: 'accountCreation',
  CMS_REPORTING_REQUIRED: 'CMSReportingRequired',

  GRACE_PERIOD: 'gracePeriod',
  ALLOW_INDIVIDUAL_CONTRIBUTIONS: 'allowIndividualContributions',
  СONTRIBUTION_MONITORING_REQUIRED: 'сontributionMonitoringRequired',
  MINIMUM_CONTRIBUTION_AMOUNT: 'minimumContributionAmount',
};

export enum OmnibusKeys {
  HSA = 'HSA',
  NOTIONAL = 'NOTIONAL',
}

export enum IrsLimitTypesLabel {
  HCFSA_MAX = 'HCFSA max',
  HSA_MAX = 'HSA max',
  DCAP_MAX = 'DCAP max',
  TRANSIT_MAX = 'Transit max',
  PARKING_MAX = 'Parking max',
}

export const REQUIRED_TYPE = 'required';
