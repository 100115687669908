import React from 'react';
import {
  Box,   Button, CloseIcon,
  FlexControlledForm, Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import useTierFields from './hooks/useTierFields';
import { TierField } from './hooks/useTiers';

interface Props {
  isRemoveMode?: boolean;
  onRemove: (id: string) => void;
  isLastItem?: boolean;
  id?: string;
  index: number;
  defaultValues?: Partial<TierField>;
  isErrorShowed?: boolean;
  onChangeValues: (value: TierField) => void;
  handleClearValues?: (id: string) => void;
  tiersValues?: TierField[];
  setShouldConfirmOnClose?: (value: boolean) => void;
}
const TierItem: React.FC<Props> = ({
  isRemoveMode, onRemove, id = '', index, isErrorShowed, onChangeValues,
  defaultValues, isLastItem, handleClearValues, tiersValues, setShouldConfirmOnClose,
}) => {
  const fields = useTierFields({
    tiersValues,
    defaultValues,
    isLastItem,
    handleClearValues,
  });

  return (
    <Box
      key={`${_.toString(defaultValues?.triggers)} ${id}`}
      round="container1Round"
      background="canvas"
      border={{ size: 'small', color: 'border2' }}
      elevation="default"
      margin={{ bottom: 'spacing24' }}
      pad={{ vertical: 'spacing24' }}
    >
      <Box direction="row" align="center" justify="between" margin={{ horizontal: 'spacing24', bottom: 'spacing16' }}>
        <Text size="xxlarge" weight={500}>{`Tier ${index + 1}`}</Text>
        {isRemoveMode && (
          <Button
            data-testid={`tier-item_${id}_remove-button`}
            onClick={() => onRemove(id)}
          >
            <Box direction="row" align="center">

              <Text color="danger" size="medium" weight="normal" margin={{ right: 'spacing4' }}>
                Remove
              </Text>
              <CloseIcon size="16px" color="danger" />
            </Box>
          </Button>
        )}
      </Box>
      <FlexControlledForm
        key={id}
        isModalType
        testId={`tier-item_${id}`}
        fields={fields}
        editMode
        onDirty={setShouldConfirmOnClose}
        onChangeValues={onChangeValues}
        showError={isErrorShowed}
        wrapperStyle={{ border: 'none' }}
        formStyle={{ marginBottom: 0, marginTop: 0 }}
      />
    </Box>
  );
};
export default TierItem;
