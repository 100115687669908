import React, { useState } from 'react';

import AddEditContactsSecurityForm, { Props as AddEditContactsSecurityFormProps } from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/AddEditContactsSecurityForm';
// import ContactsSecurityView
//   from '@/modules/employer/components/SetupEmployer/ContactsSecurity/AddEditContactsSecurityModal/ContactSecurityView';
import PermissionsTableModal
  from '@/modules/employer/components/SetupEmployer/ContactsSecurity/PermissionsTableModal/PermissionsTableModal';

export type Props = Omit<AddEditContactsSecurityFormProps, 'openPermissionTable'> & {
  open: boolean;
  viewMode: boolean;
  onDirty: (isDirty: boolean) => void;
  setViewContactMode: (viewMode: boolean) => void;
};

const AddEditContactsSecurityModal: React.FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  data,
  onDirty,
  viewMode,
  setViewContactMode,
  ...otherProps
}) => {
  const [permissionTable, setPermissionTable] = useState(false);
  return (
    <>
      <AddEditContactsSecurityForm
        onCancel={onCancel}
        onSubmit={onSubmit}
        viewMode={viewMode}
        data={data}
        onDirty={onDirty}
        toEdit={() => setViewContactMode(false)}
        openPermissionTable={() => setPermissionTable(true)}
        firstContact={otherProps.firstContact}
      />
      <PermissionsTableModal
        visible={permissionTable}
        onSetVisible={setPermissionTable}
      />
    </>
  );
};

export default AddEditContactsSecurityModal;
