import React from 'react';
import { Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

interface LabelProps {
  disabled?: boolean;
}

const StyledLabel = styled.label<LabelProps>`
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.border1 : theme.colors.button)};
  padding: ${({ theme }) => `${theme.spacings.spacing8} ${theme.spacings.spacing16}`};
  border-radius: ${({ theme }) => theme.rounds.button1Round};
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .3s;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.border1 : theme.colors.textAccentHover)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.border1 : theme.colors.textAccentActive)};
  }
`;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
  title: string;
  id: string;
  disabled?: boolean;
  format?: string;
  testId?: string;
  children?: React.ReactNode;
}

export const FileUploadButton: React.FC<Props> = ({
  onChange,
  title,
  id,
  children,
  disabled,
  format,
  testId,
}) => (
  <StyledLabel
    htmlFor={`file-upload-for-${id}`}
    disabled={disabled}
  >
    <Inscription
      size="14px"
      weight={700}
      color={disabled ? 'textDisabled' : 'canvas'}
      lineHeight="20px"
    >
      {children}
      {title}
    </Inscription>
    {!disabled && (
      <input
        id={`file-upload-for-${id}`}
        type="file"
        accept={format}
        onChange={onChange}
        style={{ display: 'none' }}
        data-testid={`${testId}_upload-button`}
      />
    )}
  </StyledLabel>
);
