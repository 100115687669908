import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { PayrollGroupRequest } from '../payrollGroup.types';

const useUpdatePayrollGroupQuery = (onSuccess?: () => void) => {
  const { mutateAsync, isLoading } = useMutation(
    (group?: PayrollGroupRequest) => api.patch(
      PATHS.UPDATE_PAYROLL_GROUP(group?.id as string),
      {
        name: group?.name,
      },
    ),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
    },
  );

  const update = useCallback(async (newGroup?: PayrollGroupRequest) => {
    await mutateAsync(newGroup);
  }, [mutateAsync]);

  return {
    update,
    isLoading,
  };
};

export default useUpdatePayrollGroupQuery;