 
 
import React, { useCallback,useState } from 'react';
import {
  AppButton,
  Box,
  Button, CloseIcon,
  FlexControlledForm,   Preloader, SiblingBoxesWithSpacing, Text,   WarnModal, } from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';
// import { useAllPlanListQuery } from '@/modules/plan/queries/usePlan.query';
import ConfirmBlock from '@/modules/plan/PlansList/CreatePlanModal/ConfirmBlock';
import theme from '@/styles/theme';

import AddModalWrapper from '../../AddModalWrapper';

import usePlansActions from './hooks/usePlansActions';

const StyledButton = styled(Button)`
  flex: 1;
  &:hover {
    background-color: ${() => theme.colors.buttonSecondaryContainer};
  }
`;
interface Props {
  defaultValues?: {
    planType: string;
    planYear: string;
    template: string;
  };
  disabled?: boolean;
  isHealthPlanMode?: boolean;
  loading?: boolean;
}

export const mockId = 1462;

export const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const PlanFormModal: React.FC<Props> = ({ disabled, isHealthPlanMode, loading }) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);

  const isPlanSetupAccessible = useHasAccess([
    {
      permission: Permissions.PLAN_SETUP,
    },
  ]);
  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && shouldConfirmOnClose) {
      return window.confirm(CONFIRM_TEXT) && setAddModalVisible(value);
    }
    return setAddModalVisible(value);
  }, [shouldConfirmOnClose]);

  const handleClose = useCallback((needConfirm: boolean) => {
    if (needConfirm) {
      onSetVisibleHandler(false);
    } else {
      setAddModalVisible(false);
    }
  }, [onSetVisibleHandler]);
  const {
    fieldsValues,
    handleRemoveById,
    handlePresubmit,
    handleAddPlan,
    getFieldsById,
    handleConfirmPlans,
    handleChangeValues,
    handleCancelProcess,
    processIdsQueue,
    processModal,
    removeMode,
    isSubmit,
  } = usePlansActions(addModalVisible, handleClose, isHealthPlanMode);
  return (
    <>
      {processModal && (
        <WarnModal
          isPendingIcon
          visible
          paddingless
          onSetVisible={() => handleCancelProcess()}
          onCancel={handleCancelProcess}
          header="Plan template you selected is not included in any payment order or processing definition"
          helptext={`In that case, manual claims will not be available for your employees enrolled in this plan.
 \nDo you want to proceed?`}
          buttonText="Back"
          onSubmit={handleConfirmPlans}
          submitButtonStyle={{
            padding: 0,
          }}
          submitButtonText="Confirm"
        >
          <Box
            background="module"
            pad={{
              top: 'spacing24',
              horizontal: 'spacing24',
            }}
            margin={{ top: 'spacing24' }}
            round="moduleRound"
          >
            {processIdsQueue.map((item) => (
              <Box key={item} background="canvas" margin={{ bottom: 'spacing24' }} round="container1Round" elevation="default">
                <FlexControlledForm
                  isModalType
                  isThinMode
                  key={item}
                  fields={getFieldsById(item, true)}
                  onDirty={setShouldConfirmOnClose}
                  onChangeValues={handleChangeValues(item)}
                  showError={isSubmit}
                />

              </Box>
            ))}
          </Box>
        </WarnModal>
      )}
      <AddModalWrapper
        id={isHealthPlanMode ? 'health_plan_offering' : 'plan_offering'}
        title={isHealthPlanMode ? 'Health Plan Offering' : 'Plan Offering'}
        visible={addModalVisible}
        disabled={disabled}
        onSetVisible={onSetVisibleHandler}
        buttonText={isHealthPlanMode ? 'Select Health Plan' : 'Select Plan'}
        isHidden={processModal}
        modalTitle={isHealthPlanMode ? 'Select Health Plan' : 'Select Plan'}
        // successTextShow={added}
        // required={isEmpty}
        successText="Plan added."
        isButtonHidden={!isPlanSetupAccessible}
      >
        <Box>
          <Box direction="column">
            <Box round="container1Round" pad="spacing24" background="module">

              {fieldsValues.map((item, index) => (
                <Box
                  key={item.id}
                  round="container1Round"
                  background="canvas"
                  margin={{ bottom: 'spacing24' }}
                  pad={{ vertical: 'spacing24' }}
                >
                  <Box direction="row" align="center" justify="between" margin={{ horizontal: 'spacing24', bottom: 'spacing16' }}>
                    <Text size="xxlarge" weight={500}>{`${isHealthPlanMode ? 'Health Plan' : 'Plan'} ${index + 1}`}</Text>
                    {removeMode && (
                      <Button onClick={handleRemoveById(item.id)}>
                        <Box direction="row" align="center">

                          <Text color="danger" size="medium" weight="normal" margin={{ right: 'spacing4' }}>
                            Remove
                          </Text>
                          <CloseIcon size="12px" color="danger" />
                        </Box>
                      </Button>
                    )}
                  </Box>

                  <FlexControlledForm
                    key={`${item.id}_${item.planTemplate?.[0]}`}
                    isModalType
                    fields={getFieldsById(item.id)}
                    editMode
                    onDirty={setShouldConfirmOnClose}
                    onChangeValues={handleChangeValues(item.id)}
                    showError={isSubmit}
                    wrapperStyle={{ border: 'none' }}
                    formStyle={{ marginBottom: 0, marginTop: 0 }}
                  />
                </Box>
              ))}

              <Box
                direction="row"
                round="container1Round"
                background="canvas"
                pad="spacing16"
              >
                <StyledButton
                  margin={{ bottom: 'spacing24' }}
                  data-testid="addPlan-button"
                  size="small"
                  style={{ margin: '0', height: '32px' }}
                  borderless
                  type="button"
                  onClick={handleAddPlan}
                  alignSelf="center"
                >
                  <Box pad="spacing4">
                    <Text weight={600}>{isHealthPlanMode ? '+ Add New Health Plan' : '+ Add New Plan'}</Text>
                  </Box>
                </StyledButton>

              </Box>

              <ConfirmBlock isHealthPlanMode={isHealthPlanMode} />
            </Box>
          </Box>
          <Box direction="column">

            <Box
              direction="row"
              fill="horizontal"
              margin={{ top: 'spacing24' }}
              align="end"
              justify="end"
              width="medium"
            >

              <SiblingBoxesWithSpacing width="control">
                <AppButton
                  testId="cancel"
                  buttonType="secondary"
                  width="100%"
                  onClick={() => handleClose(true)}
                >
                  Cancel
                </AppButton>
              </SiblingBoxesWithSpacing>
              <SiblingBoxesWithSpacing width="control">
                <AppButton
                  testId="confirm"
                  width="100%"
                  disabled={loading}
                  onClick={() => {
                    handlePresubmit();
                  }}
                >
                  {loading ? <Preloader color="white" /> : 'Confirm'}
                </AppButton>
              </SiblingBoxesWithSpacing>
            </Box>
          </Box>
        </Box>
      </AddModalWrapper>
    </>
  );
};

export default PlanFormModal;
