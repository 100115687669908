import React, { useState } from 'react';
import {
  AccordionItem,
  Box,
  CoveragePeriods,
  EnrollmentStatus,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { PolicyStatus } from '@/modules/employee/EnrollmentDetails/queries/useGetPolicyStatuses.query';
import globalTheme from '@/styles/theme';

import EnrollmentExpandedPeriodCard from './EnrollmentExpandedPeriodCard';
import { EnrollmentPeriodCard } from './EnrollmentPeriodCard';

interface Props {
  coverageDateStart: string;
  coverageDateEnd: string;
  submitClaimsDateEnd: string;
  statusColor?: string;
  statuses?: PolicyStatus[];
  isDetailsMode?: boolean;
  enrollmentStatus?: EnrollmentStatus;
  coveragePeriods: CoveragePeriods[];
  employeeName?: string;
}

const AccordionItemWrapper = styled(Box)`
  a {
    color: ${globalTheme.colors.textSecondary};
  }
`;

export const EnrollmentDates: React.FC<Props> = ({
  coverageDateStart,
  coverageDateEnd,
  submitClaimsDateEnd,
  statusColor,
  statuses = [],
  isDetailsMode,
  enrollmentStatus,
  coveragePeriods = [],
  employeeName,
}) => {
  const [expanded, setExpanded] = useState(false);
  if (coveragePeriods.length > 1 || statuses.length > 1) {
    return (
      <Box
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
        margin={{ top: isDetailsMode ? 'm' : '0' }}
        height={{ min: !isDetailsMode ? '104px' : 'auto'}}
      >
        <AccordionItemWrapper 
          width="100%"
          background={!isDetailsMode ? 'canvas' : ''}
          round="container1Round"
          border={!isDetailsMode && { color: 'border2', size: 'small' }}
          pad={{ vertical: !isDetailsMode ? 'spacing8' : '0', horizontal: !isDetailsMode ? 'spacing16' : '0' }}
          style={{
            position: isDetailsMode ? 'relative' : 'absolute',
            zIndex: 110,
            boxShadow: !isDetailsMode ? globalTheme.shadows.default['box-shadow'] : 'none',
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <AccordionItem
            isExpanded={expanded}
            iconSize='20px'
            text={(
              <EnrollmentPeriodCard
                coverageDateStart={coverageDateStart}
                coverageDateEnd={coverageDateEnd}
                submitClaimsDateEnd={submitClaimsDateEnd}
                statusColor={statusColor}
                enrollmentStatus={enrollmentStatus}
                coveragePeriods={coveragePeriods}
                isExpanded={expanded}
                isDetailsMode={isDetailsMode}
              />
            )}
            isLeftArrow
          >    
            <EnrollmentExpandedPeriodCard
              statusColor={statusColor}
              isDetailsMode={isDetailsMode}
              statuses={statuses}
              enrollmentStatus={enrollmentStatus}
              coveragePeriods={coveragePeriods}
            />
          </AccordionItem>
        </AccordionItemWrapper>
      </Box>
    );
  }
  return (
    <Box
      background={!isDetailsMode ? 'canvas' : ''}
      round="container1Round"
      border={!isDetailsMode && { color: 'border2', size: 'small' }}
      pad={{ vertical: !isDetailsMode ? 'spacing8' : '0', horizontal: !isDetailsMode ? 'spacing16' : '0' }}
      style={{
        boxShadow: !isDetailsMode ? globalTheme.shadows.default['box-shadow'] : 'none',
      }}
      margin={{ top: isDetailsMode ? 'm' : '0' }}
    >
      <EnrollmentPeriodCard
        coverageDateStart={coverageDateStart}
        coverageDateEnd={coverageDateEnd}
        submitClaimsDateEnd={submitClaimsDateEnd}
        statusColor={statusColor}
        enrollmentStatus={enrollmentStatus}
        coveragePeriods={coveragePeriods}
        isDetailsMode={isDetailsMode}
        employeeName={employeeName}
      />
    </Box>
  );
};
