import { useQuery } from 'react-query';

import { api } from '@/api';
import { OverridablePropertiesPermissions } from '@/common/constants';
import PATHS from '@/common/paths';

const useGetOverridablePropertiesPermissions = (enabled: boolean, userId?: string) => {
  const {
    data,
    isLoading,
    isSuccess,
  } = useQuery([PATHS.OVERRIDABLE_PROPERTIES_PERMISSIONS, userId],
    () => userId ? api.get<OverridablePropertiesPermissions>(PATHS.OVERRIDABLE_PROPERTIES_PERMISSIONS) : null,
    { enabled },
  );

  return {
    overridablePropertiesPermissions: data?.data || null,
    isLoading,
    isSuccess,
  };
};

export default useGetOverridablePropertiesPermissions;
