import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';

import { HealthPlanNamePayload, useHealthPlanNameStore } from './stores/useHealthPlanName.store';
import { useHealthPlanNameFields } from './hooks';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const PlanName: React.FC<Props> = ({ isEditMode, isTemplateMode, onDirty }) => {
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const { stateFields: fields, createCarrierModals } = useHealthPlanNameFields(
    activateValidate,
    isTemplateMode,
    isEditMode,
  );

  const handleSetState = useHealthPlanNameStore((state) => state.setState);
  const showErrors = useHealthPlanNameStore((state) => state.showErrors);
  const { setValues, values } = useDebounceState<Partial<HealthPlanNamePayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        testId="health_plan-definition"
        fields={fields}
        showError={
          showErrors
          || saveValidate
          || activateValidate
        }
        shouldControlValues
        editMode={isEditMode}
        onChangeValues={setValues}
        onDirty={onDirty}
        // isDisabledMode
        isThinMode
      />
      {createCarrierModals}
    </Box>
  );
};
export default PlanName;
