import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const SidebarBody = styled(Box)`
  padding-left: 10px;
`;

interface WrapperProps {
  isLockBannerMode?: boolean;
  customTopValue?: string;
}

export const Wrapper = styled(Box)<WrapperProps>`
  position: fixed;
  left: 0;
  top: ${({ isLockBannerMode, customTopValue }) => (isLockBannerMode ? '62px' : customTopValue || '0')};
  min-height: 100%;
  z-index: 1002;
  background-color: ${({ theme }) => theme.colors.navigationBackground || theme.colors.module};
  transition: all 0.4s;
  border-right: 1px solid ${({ theme }) => theme.colors.border1};

  // &:hover {
  //   ${SidebarBody} {
  //     height: auto;
  //     overflow-x: hidden;
  //     overflow-y: auto;
  //   }
  // }
`;

export const BackgroundOverlay = styled(Box)`
  position: fixed;
  z-index: 1002;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.popup2};
`;

export const SidebarContent = styled(Box)`
  max-width: initial;
`;
