import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

interface CarrierPayload {
  id: number;
  name: string;
  external_id: string;
}

export const QUERY_KEY = 'GET_CARRIERS_BY_ID';
export const useGetCarrierByIdQuery = (id?: string) => {
  const {
    isLoading, error, data, isSuccess, isError,
  } = useQuery(
    [QUERY_KEY, id], () => api.get(PATHS.GET_CARRIER_BY_ID(id)),
    {
      enabled: !!id,
    },
  );

  return {
    data: data?.data as CarrierPayload,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
