import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { ContributionRequestStatus } from '../../Contributions/contributions.types';

const useChangeContributionStatusQuery = (contributionId?: string, onSuccess?:() => void) => {
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (status: ContributionRequestStatus) => api.patch(
      PATHS.CHANGE_CONTRIBUTION_REQUEST_STATUS(contributionId, status)),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
    },
  );

  return {
    changeContributionStatus: mutateAsync,
    isLoading,
    isSuccess,
  };
};

export default useChangeContributionStatusQuery;
