import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useBankAccountStore, { BankAccountPayload } from '../stores/useBankAccount.store';

export default () => {
  const setBankAccountState = useBankAccountStore((state) => state.setState);
  const setBankAccountSourceState = useBankAccountStore((state) => state.setSourceState);
  const setBankAccountErrorFields = useBankAccountStore((state) => state.setErrorFields);
  const handleResetBankAccountStore = useBankAccountStore((state) => state.handleReset);

  const handleSetState = useCallback((values: Partial<BankAccountPayload>) => {
    setBankAccountState(values);
  }, [setBankAccountState]);

  const handleSetSourceState = useCallback((values: Partial<BankAccountPayload>) => {
    setBankAccountSourceState(values);
  }, [setBankAccountSourceState]);

  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<BankAccountPayload>>) => {
    setBankAccountErrorFields(values);
  }, [setBankAccountErrorFields]);

  const handleReset = useCallback(() => {
    handleResetBankAccountStore();
  }, [handleResetBankAccountStore]);
  
  return {
    handleSetState,
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
  };
};
