import React, { useCallback,useEffect, useMemo } from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';
import _ from 'lodash';

import { PlanNamePayload,useStore } from './stores/useHealthPlanName.store';
import { useHealthPlanNameFields } from './hooks';

interface Props {
  planTemplateId?: string;
  testId?: string;
  footer?: React.ReactNode;
}

const PlanName: React.FC<Props> = ({ planTemplateId, testId = '', footer }) => {
  const setState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);
  const currentState = useStore((state) => state.state);

  const fields = useHealthPlanNameFields(currentState);

  useEffect(() => {
    if (planTemplateId) {
      setState({
        planTemplate: planTemplateId,
      });
    }
  }, [setState, planTemplateId]);

  const handleSetState = useCallback((values: Partial<PlanNamePayload>) => {
    setState(values);
  }, [setState]);

  const key = useMemo(() => _.get(fields, '[1].defaultValue', '') || _.get(fields, '[0].defaultValue', ''), [fields]) as string;
  return (
    <Box key={key} background="canvas" round="container1Round">
      <FlexControlledForm
        testId={`${testId}_plan-name`}
        fields={fields}
        showError={showErrors}
        editMode
        onChangeValues={handleSetState}
        isModalType
        footer={footer}
      />
    </Box>
  );
};
export default PlanName;
