import React from 'react';

import { ReportSection } from '@/modules/employer/components/Reports/components/ReportSection/ReportSection';

import useEnrollmentsReports from './useEnrollmentsReports';

const Enrollments = () => {
  const reports = useEnrollmentsReports();

  return (
    <ReportSection
      title="Enrollments"
      reports={reports}
    />
  );
};

export default Enrollments;
