import React, { useState } from 'react';
import {
  Box,
  LogOutIcon,
  PopupWrapper,
  RawButton,
  // DropContent,
  // Menu,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { LetterIconSizeEnum, Roles } from '@/common/constants';
import { UserLetterIcon } from '@/components/elements/LetterIcon/UserLetterIcon';
import { useAuthorization } from '@/modules/user/hooks';
import { useAuthStore } from '@/modules/user/stores';
import currentTheme from '@/styles/theme';

import { useSmartSearchResentFinding } from './ToolbarSmartSearch/hooks';

export const LogOutButton = styled(RawButton)`
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  transition: all 0.2s;
  height:${({ theme }) => theme.spacings.xl};
  min-height:${({ theme }) => theme.spacings.xl};
  color: ${({ theme }) => theme.colors.textBody};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentContainer};
    color: ${({ theme }) => theme.colors.textAccent};
  }
  &:active {
    svg {
      fill: ${({ theme }) => theme.colors.textOnColor};
    }

    background-color: ${({ theme }) => theme.colors.buttonSecondaryActive};
    color: ${({ theme }) => theme.colors.textOnColor};
  }
`;
const StyledPopupContent = styled(Box)`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 21px;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.canvas};
    transform: rotate(45deg);
    border-top-left-radius: ${({ theme }) => theme.spacings.spacing4};
  }
`;
const AnimatedPopupWrapper = styled(PopupWrapper)`
  @keyframes entrance {
    0% {
      opacity: 0;
      transform: translateY(-14px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const POPUP_SHADOW = currentTheme.shadows.active['box-shadow'];

const UserDropdown = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useAuthStore();
  const { handleLogout } = useAuthorization();
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  const { clear } = useSmartSearchResentFinding();
  return (
    <Box>
      <AnimatedPopupWrapper
        flat
        flex
        testId="user-dropdown_popup"
        style={{
          top: '58px',
          boxShadow: POPUP_SHADOW,
          animation: 'entrance 0.3s ease-in-out',
          zIndex: 1000,
        }}
        onVisible={setIsVisible}
        content={(
          <StyledPopupContent direction="column" pad="spacing12" width="220px">
            <Box
              direction="row"
              pad={{ bottom: 'spacing8' }}
              margin={{ bottom: 'spacing8' }}
              border={{ color: 'border2', side: 'bottom', size: 'small' }}
            >
              <UserLetterIcon
                size={LetterIconSizeEnum.LOGO}
                text={fullName}
                role={user?.role as Roles}
                sizePx="40"
              />
              <Box
                direction="column"
                margin={{
                  left: 'spacing12',
                }}
              >
                <Text size="medium" weight={700} color="textBody">
                  {fullName}
                </Text>
                <Text size="small" weight={500} color="textDisabled">
                  Manage Account
                </Text>
              </Box>
            </Box>

            <LogOutButton
              data-testid="logout_button"
              onClick={() => {
                handleLogout();
                clear();
              }}
            >
              <Box direction="row" pad={{ horizontal: 'xs', vertical: 'xxs' }}>
                <LogOutIcon color="iconPrimary" />
                <Box margin={{ left: 'spacing12' }}>
                  <Text size="medium" weight="normal">
                    Log Out
                  </Text>
                </Box>
              </Box>
            </LogOutButton>
          </StyledPopupContent>
        )}
      >
        <UserLetterIcon
          size={LetterIconSizeEnum.LOGO}
          text={fullName}
          role={user?.role as Roles}
          sizePx="40"
          isWithShadow={isVisible}
        />
      </AnimatedPopupWrapper>
    </Box>
  );
};

export default UserDropdown;
