import { ListItem } from '@common-fe/common-fe';

import { Option } from '@/common/types';

export enum LockboxStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

export enum LockboxType {
  INBOUND_TOA = 'INBOUND_TOA',
  OUTBOUND_TOA = 'OUTBOUND_TOA',
  RETURNED_CHECK = 'RETURNED_CHECK',
  UNKNOWN = 'UNKNOWN',
}

export enum EmployeeMatchingType {
  FULL_MATCH = 'FULL_MATCH',
  ACCOUNT_NOT_MATCHED = 'ACCOUNT_NOT_MATCHED',
  SSN_NOT_MATCHED = 'SSN_NOT_MATCHED',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  EMPLOYEE_NAME_NOT_MATCHED = 'EMPLOYEE_NAME_NOT_MATCHED',
}

interface LockboxOcrAddress {
  taxId?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface LockboxOcr {
  accountNumber?: string;
  employeeName?: string;
  ssn?: string;
  birthDate?: string;
  amount?: string;
  date?: string;
  address?: LockboxOcrAddress;
}

export interface MatchedEmployee {
  employeeId?: string;
  employeeName?: string;
  ssn?: string;
  birthDate?: string;
  employmentStatus?: string;
  organizationId?: string;
  organizationName?: string;
  accountId?: string;
  accountNumber?: string;
  availableAmount?: number;
  accountStatus?: string;
  investmentStatus?: string;
  labelTypes?: EmployeeMatchingType[];
}

export interface MatchedEmployeePayload {
  employee_id?: number;
  employee_name?: string;
  ssn?: string;
  birth_date?: string;
  employment_status?: string;
  organization_id?: number;
  organization_name?: string
  account_id?: number;
  account_number?: string;
  available_amount?: number;
  account_status?: string;
  investment_status?: string;
  label_types?: EmployeeMatchingType[];
}

export interface CheckDetails {
  id?: string;
  vendorId?: string;
  vendorName?: string;
  checkName?: string;
  benefitOf?: string;
  city?: string;
  state?: string;
  zip?: string;
  checkMemo?: string;
  addressLine1?: string;
  addressLine2?: string;
}

export interface Lockbox {
  id?: string;
  documentId?: string;
  name?: string;
  lockboxType?: LockboxType;
  lockboxStatus?: LockboxStatus;
  employeeName?: string;
  ssn?: string;
  birthDate?: string;
  accountNumber?: string;
  accountId?: string;
  amount?: string;
  contactId?: string;
  employeeId?: string;
  address?: string;
  requestDate?: string;
  matchEmployees?: MatchedEmployee[];
  ocr?: LockboxOcr;
  checkDetails?: CheckDetails;
}

export interface LockboxListItem {
  id?: string;
  employeeName?: string;
  amount?: number;
  accountNumber?: number;
  requestDate?: string;
  lockboxType?: LockboxType;
  lockboxStatus?: LockboxStatus;
}

export const LOCKBOX_STATUSES_OPTIONS: Option[] = [
  {
    key: LockboxStatus.NEW,
    value: LockboxStatus.NEW,
    title: 'New',
  },
  {
    key: LockboxStatus.PENDING,
    value: LockboxStatus.PENDING,
    title: 'Pending',
  },
  {
    key: LockboxStatus.DECLINED,
    value: LockboxStatus.DECLINED,
    title: 'Declined',
  },
  {
    key: LockboxStatus.APPROVED,
    value: LockboxStatus.APPROVED,
    title: 'Approved',
  },
  {
    key: LockboxStatus.PROCESSING,
    value: LockboxStatus.PROCESSING,
    title: 'Processing',
  },
  {
    key: LockboxStatus.COMPLETED,
    value: LockboxStatus.COMPLETED,
    title: 'Completed',
  },
];

export const LOCKBOX_TYPES_OPTIONS: Option[] = [
  {
    key: LockboxType.INBOUND_TOA,
    value: LockboxType.INBOUND_TOA,
    title: 'Inbound TOA',
  },
  {
    key: LockboxType.OUTBOUND_TOA,
    value: LockboxType.OUTBOUND_TOA,
    title: 'Outbound TOA',
  },
  {
    key: LockboxType.RETURNED_CHECK,
    value: LockboxType.RETURNED_CHECK,
    title: 'Returned Check',
  },
  {
    key: LockboxType.UNKNOWN,
    value: LockboxType.UNKNOWN,
    title: 'Unknown',
  },
];

export enum LockboxValidationError {
  AMOUNT_REQUIRED = 'AMOUNT_REQUIRED',
  EMPLOYEE_REQUIRED = 'EMPLOYEE_REQUIRED',
  VENDOR_REQUIRED = 'VENDOR_REQUIRED',
  HSA_ACCOUNT_REQUIRED = 'HSA_ACCOUNT_REQUIRED',
  HSA_ACCOUNT_CLOSED = 'HSA_ACCOUNT_CLOSED',
  TYPE_REQUIRED = 'TYPE_REQUIRED',
}

export const lockboxValidationErrorTitle = {
  [LockboxValidationError.AMOUNT_REQUIRED]: 'Amount is required',
  [LockboxValidationError.EMPLOYEE_REQUIRED]: 'Need to select an employee',
  [LockboxValidationError.HSA_ACCOUNT_REQUIRED]: 'HSA account must be present',
  [LockboxValidationError.VENDOR_REQUIRED]: 'Vendor is required',
  [LockboxValidationError.HSA_ACCOUNT_CLOSED]: 'HSA account can’t be closed',
  [LockboxValidationError.TYPE_REQUIRED]: 'Type is required',
} as const;

export const lockboxTypeTitle = {
  [LockboxType.INBOUND_TOA]: 'Inbound TOA',
  [LockboxType.OUTBOUND_TOA]: 'Outbound TOA',
  [LockboxType.RETURNED_CHECK]: 'Returned Check',
  [LockboxType.UNKNOWN]: 'Unknown',
} as const;

export enum LockboxApproveStatusSubOption {
  CHECK = 'CHECK',
  WIRE = 'WIRE',
}

export enum LockboxDeclineStatusSubOption {
  PARTICIPANT_NOT_FOUND = 'PARTICIPANT_NOT_FOUND',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  INVESTMENTS_LIQUIDATION_REQUIRED = 'INVESTMENTS_LIQUIDATION_REQUIRED',
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  OTHER = 'OTHER',
}

export interface EmployeeCardsListItem extends ListItem {
  warnings?: EmployeeMatchingType[];
}
