import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { PlanYearPayload,useStore } from './stores/usePlanYear.store';
import { usePlanYear, usePlanYearFields } from './hooks';
// import { usePreviewStore } from '../../stores';

interface Props {
  isEditMode?: boolean;
  children?: React.ReactNode;
  onDirty?: (isDirty: boolean) => void;
}
const PlanYear: React.FC<Props> = ({
  children, isEditMode, onDirty,
}) => {
  // const previewMode = usePreviewStore((state) => state.previewMode);
  usePlanYear();
  const fields = usePlanYearFields(true);
  const handleSetState = useStore((state) => state.setState);

  const showErrors = useStore((state) => state.showErrors);
  const { setValues, values } = useDebounceState<Partial<PlanYearPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box
      background="canvas"
      round="container1Round"
    >
      <FlexControlledForm
      // it's needed to avoid shouldControlValues and to reduce rerenders
        key={`${fields[0].value}${fields[1].value}`}
        fields={fields}
        testId="plan_primary-definition"
        // shouldControlValues={!previewMode}
        showError={showErrors}
        editMode={isEditMode}
        onChangeValues={setValues}
        onDirty={onDirty}
        // isDisabledMode
        isThinMode
      >
        {children}
      </FlexControlledForm>
    </Box>
  );
};
export default PlanYear;
