import { useMemo } from 'react';

import { useStore as useEnrollmentMatrixStore } from '../EnrollmentMatrix/stores/useEnrollmentMatrix.store';

export default () => {
  const enrollmentMatrixStore = useEnrollmentMatrixStore((state) => state.state);
  const state = useMemo(() => ({
    ...enrollmentMatrixStore,
  }), [
    enrollmentMatrixStore,
  ]);
  return state;
};
