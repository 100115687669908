import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Anchor, AppButton, Box, Preloader, Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import ModalWrapper from '@/components/wrappers/ModalWrapper';
import { useHistory } from '@/modules/core/hooks';
import {
  useReconciliationBankAccountList,
  useReconciliationReportDetails,
} from '@/modules/employer/queries/useReconciliationBankAccounts.query';
import downloadFileByUrl from '@/utils/handlers/downloadFileByUrl';

import { useDownloadReconciliationReportForm } from './hooks/useDownloadReconciliationReportForm';
import useReconciliationReportDownloadModal from './hooks/useReconciliationReportDownloadModal';
import { ReconciliationReportDownloadForm, ReportSettingDto } from './ReconciliationReportDownloadForm';
import { ReconciliationDownloadModal } from './types';

const LIST_OF_BANK_ACCOUNT = {
  xlsx: '/demo-files/List_of_bank_accounts.xlsx',
  pdf: '/demo-files/List_of_bank_accounts.pdf',
};

const CLOSES_BY_ACCOUNT = {
  xlsx: '/demo-files/Closes_By_Account.xlsx',
  pdf: '/demo-files/Closes_By_Account.pdf',
};

const ACCOUNT_CLOSE_DETAILS = {
  xlsx: '/demo-files/Account_Close_Details.xlsx',
  pdf: '/demo-files/Account_Close_Details.pdf',
};

export const StyledAnchor = styled(Anchor)`
  box-shadow: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

interface Props {
  modalType: ReconciliationDownloadModal;
}

export const ReconciliationReportDownloadModal: React.FC<Props> = ({ modalType }) => {
  const { response } = useReconciliationBankAccountList();
  const {
    isModalVisible,
    setIsModalVisible,
    downloadReportId,
    setDownloadReportId,
  } = useReconciliationReportDownloadModal();
  const { formatedData, isLoading } = useReconciliationReportDetails(downloadReportId);
  const [reportSetting, setReportSetting] = useState<ReportSettingDto>({} as ReportSettingDto);
  const [isError, setIsError] = useState(false);

  // const minCloseDate = useMemo(() => {
  //   const dates = response.map((item) => dayjs(item.close_date).toDate());

  //   return _.min(dates);
  // }, [response]);

  const { queryMap } = useHistory();
  const closeDate = useMemo(
    () => queryMap.transactionsCloseDate,
    [queryMap],
  );

  const currentAccountDescription = useMemo(
    () => (modalType === ReconciliationDownloadModal.ListOfBankAccounts
      ? undefined
      : formatedData?.account_description), [formatedData, modalType],
  );

  const fields = useDownloadReconciliationReportForm({
    accountsInfo: response,
    selectedAccount: currentAccountDescription,
    selectedDate: closeDate || formatedData?.close_date,
  });

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    if (modalType === ReconciliationDownloadModal.ListOfBankAccounts) setDownloadReportId('');
  }, [modalType, setDownloadReportId, setIsModalVisible]);

  const isEmptyFormat = useMemo(() => _.isEmpty(reportSetting?.selectDownloadFormats),
    [reportSetting.selectDownloadFormats]);

  useEffect(() => {
    if (!isEmptyFormat) {
      setIsError(false);
    }
  }, [isEmptyFormat]);

  // for mocked data
  const currentSection = useMemo(() => {
    if (modalType === ReconciliationDownloadModal.ListOfBankAccounts) {
      return LIST_OF_BANK_ACCOUNT;
    }
    if (modalType === ReconciliationDownloadModal.AccountCloseDetails) {
      return ACCOUNT_CLOSE_DETAILS;
    }
    return CLOSES_BY_ACCOUNT;
  }, [modalType]);

  const handleDownloadSubmit = useCallback((urls: string[]) => {
    if (isEmptyFormat) {
      setIsError(true);
      return;
    }
    downloadFileByUrl(urls);
    closeModal();
  }, [closeModal, isEmptyFormat]);

  const currentLink = useMemo(() => {
    if (reportSetting.selectDownloadFormats && reportSetting.selectDownloadFormats.length === 1) {
      return reportSetting.selectDownloadFormats[0] === 'PDF' ? [currentSection.pdf] : [currentSection.xlsx];
    }

    return [currentSection.xlsx, currentSection.pdf];
  }, [currentSection, reportSetting.selectDownloadFormats]);

  return (
    <ModalWrapper
      visible={isModalVisible}
      title="Download Report"
      onSetVisible={closeModal}
    >
      <Box
        background="module"
        pad="medium"
        data-testid="ReconciliationReportDownloadModal-wrapper"
        round="container1Round"
      >
        <Box width="100%" height="fit-content" background="canvas" round="container1Round">
          <ReconciliationReportDownloadForm
            onChangeValues={setReportSetting}
            setDirtyForm={() => null}
            fields={fields}
            loading={isLoading}
            showErrors={false}
          />
        </Box>

      </Box>
      {isError
        ? (
          <Box flex align="end" justify="end">
            <Text color="danger" size="medium" margin={{ top: 'spacing24' }}>
              Please, select file format.
            </Text>
          </Box>
        ) : null}
      <Box direction="row" justify="end" margin={{ top: 'spacing24' }}>
        <Box margin={{ right: 'spacing24' }}>
          <AppButton
            buttonType="secondary"
            width="140px"
            onClick={closeModal}
          >
            Cancel
          </AppButton>
        </Box>
        <AppButton
          testId="cancel"
          width="140px"
          onClick={() => handleDownloadSubmit(currentLink)}
        >
          {
            isLoading
              ? <Preloader color="white" />
              : 'Download'
          }
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
