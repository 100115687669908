import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';

import useEmployerSetupAddressModalFields from '@/modules/employer/components/EmployerSetupModal/hooks/useEmployerSetupAddressModalFields.ts';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useAddressStore } from '../EditAddressModal/stores';

const useAddressFormValidate = () => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const addressState = useAddressStore((state) => state.state);
  const addressFields = useEmployerSetupAddressModalFields({});

  const addressErrors: FieldError = useMemo(() =>  getErrors(addressState, getValidateFields(addressFields)), [getErrors, addressState, getValidateFields, addressFields]);

  return addressErrors;
};

export default useAddressFormValidate;
