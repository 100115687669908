import React from 'react';
import { Box, Text } from '@common-fe/common-fe';
// import styled from 'styled-components';

// const Wrapper = styled(Box)`
// &:hover {
//     color: ${({ theme }) => theme.colors.textAccent};
//     background-color: ${({ theme }) => theme.colors.accentContainer};
//   }
// `;
const AddNewPlanTitle = () => (
  <Box
    fill
    // align="start"
    // height={{ min: '32px', max: '32px' }}
    // pad={{ bottom: 'spacing8', top: 'spacing4', horizontal: 'spacing8' }}
    // border={{ side: 'bottom', size: '2px', color: 'border1' }}
  >
    <Text size="medium" color="textAccent">Add New Plan Year</Text>
  </Box>

);
export default AddNewPlanTitle;
