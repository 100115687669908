import React from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import Label from '@/components/elements/Label';
import { EmailCommunication,EmailCommunicationType } from '@/modules/employer/types/Contact.types';

import { ContactResponseModel } from '../ContactSecurity.types';

interface Props {
  contact?: ContactResponseModel;
  values?: EmailCommunication;
  isEditMode?: boolean;
}

const useEmailCommunicationsFields = ({
  contact,
  values,
  isEditMode,
}: Props) => {
  const emailCommunicationTypeList = contact?.email_communication_type_list;
  const fields: Field[] = [
    {
      name: 'finance',
      type: FieldTypes.Toggle,
      label: (
        <Label
          title="Finance"
          desc="Contributions, replenishment, and other financial operations"
          {...isEditMode ? {} : { width: '250px' }}
        />
      ),
      value: values?.finance,
      defaultValue: emailCommunicationTypeList?.includes(EmailCommunicationType.finance),
    },
    {
      name: 'files',
      type: FieldTypes.Toggle,
      label: <Label title="Files" desc="File confirmation and error reports" />,
      value: values?.files,
      defaultValue: emailCommunicationTypeList?.includes(EmailCommunicationType.files),
    },
    {
      name: 'planManagement',
      type: FieldTypes.Toggle,
      label: <Label title="Plan Management" desc="Employer and plan status updates" />,
      value: values?.planManagement,
      defaultValue: emailCommunicationTypeList?.includes(EmailCommunicationType.planManagement),
    },
  ];

  return { fields };
};

export default useEmailCommunicationsFields;
