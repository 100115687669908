import React, {
  useCallback, useEffect, useMemo, useRef,
  useState, } from 'react';
import {
  AppButton,
  Box, Button,
  ChevronDownIcon,
  ChevronUpIcon,
  DropContent,
  Text,   useClickOutside,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import theme from '@/styles/theme';

import {
  usePreviewStore,
} from '../stores';

interface Props {
  viewMode?: boolean;
  isTemplateMode?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  activateButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  reviewButtonRef?:React.RefObject<HTMLButtonElement | undefined>;
}
const HealthPlanTopbarActions: React.FC<Props> = ({
  viewMode, isTemplateMode,
  saveButtonRef,
  activateButtonRef,
  reviewButtonRef,
}) => {
  const handleSetPreviewMode = usePreviewStore((state) => state.setPreviewMode);
  const previewMode = usePreviewStore((state) => state.previewMode);
  useEffect(() => () => {
    handleSetPreviewMode(false);
  }, [handleSetPreviewMode]);

  const [visiblePopup, setVisiblePopup] = useState(false);

  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const handleToggleVisiblePopup = useCallback(() => {
    setShowActivatePopup(false);
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);
  const handleSaveDraft = useCallback(() => {
    setVisiblePopup(false);
    if (saveButtonRef?.current) {
      saveButtonRef?.current.click();
    }
  }, [saveButtonRef]);

  const handleActive = useCallback(() => {
    handleToggleVisiblePopup();
    if (activateButtonRef?.current) {
      activateButtonRef?.current.click();
    }
  }, [activateButtonRef, handleToggleVisiblePopup]);

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    setVisiblePopup(false);
  });
  const contentWidth = useMemo(() => {
    if (isTemplateMode || showActivatePopup) {
      return 'medium';
    }
    return 'small';
  }, [isTemplateMode, showActivatePopup]);
  return (
    <Box direction="row">

      <Access
        accessRequirements={[
          {
            permission: Permissions.ACTIVATE_PLAN,
          },
          {
            permission: Permissions.EDIT_DRAFT_PLAN,
          },
        ]}
      >
        {/* {isTemplateMode && (
          previewMode ? (
            <AppButton
              buttonType="secondary"
              onClick={() => handleSetPreviewMode(false)}
            >
              Exit Preview Mode
            </AppButton>
          )

            : (
              <AppButton
                buttonType="secondary"
                onClick={() => handleSetPreviewMode(true)}
                containerStyle={{ marginRight: theme.spacings.spacing12 }}
              >
                Preview Plan
              </AppButton>
            )

        )} */}
        { !previewMode && (
          <Box pad={{ left: 'spacing12' }}>
            <DropContent
              bottomMode
              contentWidth={contentWidth}
              visible={visiblePopup}
              content={
                showActivatePopup ? (
                  <Box direction="column" pad="s" width="300px">
                    <Text size="small" margin={{ bottom: 'xs' }}>
                      {isTemplateMode
                        ? 'Ready to activate this health plan template? Take a look at your selections. Once activated, making changes to this health plan template will be limited.'
                        : 'Ready to activate this health plan? Take a look at your selections. Once activated, making changes to this health plan will be limited.'}
                    </Text>
                    <Text size="medium" weight="bold">
                      Would you like to activate?
                    </Text>
                    <Box direction="row" margin={{ top: 'spacing12' }}>
                      <AppButton
                        buttonType="secondary"
                        onClick={handleToggleVisiblePopup}
                        containerStyle={{ marginRight: theme.spacings.spacing12 }}
                        width="130px"
                      >
                        Cancel
                      </AppButton>
                      <AppButton
                        onClick={handleActive}
                        containerStyle={{ marginRight: theme.spacings.spacing12 }}
                        width="130px"
                      >
                        Activate
                      </AppButton>
                    </Box>
                  </Box>
                )
                  : (
                    <Box direction="column" ref={popupRef}>
                      <Access
                        accessRequirements={[
                          {
                            permission: Permissions.EDIT_DRAFT_PLAN,
                          },
                        ]}
                      >

                        <Button
                          type="button"
                          onClick={handleSaveDraft}
                        >
                          <Text size="medium" weight={400}>
                            Save to Draft
                          </Text>
                        </Button>
                      </Access>

                      <Access
                        accessRequirements={[
                          {
                            permission: Permissions.ACTIVATE_PLAN,
                          },
                          {
                            permission: Permissions.EDIT_DRAFT_PLAN,
                          },
                        ]}
                      >
                        <Button
                          type="button"
                          onClick={() => setShowActivatePopup(!showActivatePopup)}
                        >
                          <Text size="medium" weight={400}>
                            {isTemplateMode ? 'Activate the Health Plan Template' : 'Activate the Health Plan'}
                          </Text>
                        </Button>
                      </Access>
                    </Box>
                  )
              }
            >
              <Box width="170px">
                <AppButton
                  disabled={viewMode}
                  onClick={handleToggleVisiblePopup}
                  containerStyle={{ marginRight: theme.spacings.spacing12 }}
                  rightIcon={visiblePopup ? (
                    <ChevronUpIcon
                      color="iconOnColor"
                    />
                  ) : <ChevronDownIcon color="canvas" />}
                >
                  Save/Activate
                </AppButton>
              </Box>
            </DropContent>
          </Box>
        )}

      </Access>
    </Box>
  );
};

export default HealthPlanTopbarActions;
