import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  AppButton, Box, FlexControlledForm, Modal, Preloader,
  Text, } from '@common-fe/common-fe';
import { toString } from 'lodash';
import styled from 'styled-components';

import { NO_KEY,YES_KEY } from '@/common/constants';

import { ServiceConfig } from '../Tiers/queries/useServiceConfigs.query';

import { ACCOUNT_TYPE_KEY,AMOUNT_TYPE_KEY } from './hooks/usePaymentRuleFields';
import AddPaymentRule from './AddPaymentRule';
import { MainInfo } from './CreateProcessingRule.types';
import { useCreateServicePayment, usePaymentRules, useServicePaymentFields } from './hooks';
import PaymentRuleItem from './PaymentRuleItem';
import { usePaymentAccount,useServiceType } from './queries';

const Delimiter = styled(Box)`
  border: none;
  background-color: ${({ theme }) => theme.colors.border1};
  height: 1px;
`;

interface Props {
  visible: boolean;
  setVisible: () => void;
  tierId: string;
  payoutDefinitionId: string;
  updatedElement?: ServiceConfig;
  setUpdatedElement: (value?: ServiceConfig) => void;
  deductibleType?: string;
  healthPlanTypes?: string[];
}

const AddServicePaymentModal: React.FC<Props> = ({
  visible,
  setVisible,
  tierId,
  payoutDefinitionId,
  updatedElement,
  setUpdatedElement,
  deductibleType,
  healthPlanTypes,
}) => {
  const [state, setState] = useState<MainInfo>({
    serviceType: '',
    description: '',
    paymentValue: '',
    applyToDeductible: '',
    paymentType: '',
    claimValue: '',
  });
  const [errorName, setErrorName] = useState<string>('');
  const [accountArray, setAccountArray] = useState<string[]>([]);
  const {
    fieldsValues: paymentRulesValues, getFieldValuesById,
    handleChangeValues, handleRemoveById,
    handleAddNewServicePayment, handleClearValues,
  } = usePaymentRules(updatedElement?.paymentRules);

  const { fieldOptions: serviceTypeOptions } = useServiceType(
    visible,
    payoutDefinitionId,
    healthPlanTypes,
    deductibleType,
  );
  const { fieldOptions: paymentAccounts } = usePaymentAccount(
    visible,
    state.serviceType,
    payoutDefinitionId,
  );

  const fields = useServicePaymentFields(
    state,
    serviceTypeOptions,
    {
      serviceType: errorName || '',
    },
  );

  const handleCloseModal = useCallback(() => {
    setVisible();
    setErrorName('');
    setUpdatedElement(undefined);
    setState({
      serviceType: '',
      description: '',
      paymentValue: '',
      applyToDeductible: '',
      paymentType: '',
      claimValue: '',
    });
  }, [setVisible, setUpdatedElement]);

  const {
    isSubmitted,
    isLoading,
    handleSubmit,
    isServiceTypeError,
  } = useCreateServicePayment({
    mainValue: state,
    tierId,
    visible,
    paymentRules: paymentRulesValues,
    onClose: handleCloseModal,
    isUpdateMode: !!updatedElement,
    updatedConfigId: updatedElement?.id,
  });

  const getAccountValues = useCallback(() => {
    const newArray = paymentRulesValues?.map((item) => item?.account);
    setAccountArray(newArray);
  }, [paymentRulesValues]);

  useEffect(() => {
    getAccountValues();
  }, [paymentRulesValues, getAccountValues]);

  useEffect(() => {
    handleClearValues(AMOUNT_TYPE_KEY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.paymentType]);

  useEffect(() => {
    handleClearValues(ACCOUNT_TYPE_KEY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.serviceType]);

  useEffect(() => {
    if (isServiceTypeError) {
      setErrorName(state.serviceType);
    } else {
      setErrorName('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceTypeError]);

  useEffect(() => {
    if (updatedElement) {
      setState({
        serviceType: updatedElement.serviceId,
        description: updatedElement.description,
        paymentValue: updatedElement.claimValue ? YES_KEY : NO_KEY,
        applyToDeductible: updatedElement.isAppliedDeductible ? YES_KEY : NO_KEY,
        paymentType: updatedElement.paymentType,
        claimValue: toString(updatedElement.claimValue),
      });
    }
  }, [updatedElement]);

  return (
    <Modal
      visible={visible}
      onSetVisible={setVisible}
      onClear={() => handleCloseModal()}
    >
      <Box direction="column" data-testId="add-service-payment-modal-wrapper">
        <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
          <Text size="2xl" color="textTitle" weight="bold">
            {updatedElement ? 'Edit Service Payment' : 'Service Payment'}
          </Text>
        </Box>
        <Box>
          <Box
            pad="l"
            background="module"
            round="moduleRound"
          >
            <Box
              background="canvas"
              pad={{ vertical: 'l' }}
              elevation="default"
              margin={{ bottom: 's' }}
              round="container1Round"
              border={{ color: 'border2', size: 'small' }}
            >
              <FlexControlledForm
                isModalType
                fields={fields}
                editMode
                // onDirty={setShouldConfirmOnClose}
                onChangeValues={setState}
                showError={isSubmitted}
                wrapperStyle={{ border: 'none' }}
                formStyle={{ marginBottom: 0, marginTop: 0 }}
                shouldControlValues
              />
            </Box>
            {
              paymentRulesValues.map((item, index) => (
                <PaymentRuleItem
                  id={item.id}
                  index={index}
                  isErrorShowed={isSubmitted}
                  onRemove={handleRemoveById}
                  key={item.id}
                  defaultValues={getFieldValuesById(item.id)}
                  onChangeValues={handleChangeValues(item.id)}
                  paymentType={state.paymentType}
                  paymentAccounts={paymentAccounts}
                  accountArray={accountArray}
                />
              ))
            }
            <Delimiter />
            <Text color="textSecondary" size="medium" weight={400} margin={{ vertical: 's' }}>
              Claims will use the configured processing rules unless
              a specific payment rule is added.
            </Text>
            <AddPaymentRule onClick={handleAddNewServicePayment} />
          </Box>
          <Box direction="row" align="center" justify="end" pad={{ top: 'l' }}>
            <AppButton buttonType="secondary" width="control" onClick={handleCloseModal}>Cancel</AppButton>
            <Box margin={{ left: 's' }}>
              <AppButton width="control" onClick={handleSubmit} disabled={isLoading}>
                {isLoading && <Preloader color="white" />}
                {!isLoading && updatedElement ? 'Save' : 'Add'}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddServicePaymentModal;
