import { baseStoreCreator } from '@/utils/store';

export interface CustomReportsPayload {
  customReportField1: string;
  customReportField2: string;
  customReportField3: string;
  customReportField4: string;
  customReportField5: string;
}
export const DEFAULT_VALUES: CustomReportsPayload = {
  customReportField1: '',
  customReportField2: '',
  customReportField3: '',
  customReportField4: '',
  customReportField5: '',
};

export const useStore = baseStoreCreator<CustomReportsPayload>(DEFAULT_VALUES);

export default useStore;
