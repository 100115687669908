import { formatSnakeCaseToGeneral } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { REPORT_DATE_FORMAT } from '@/common/constants';
import {
  ClaimReimbursementActivityReport,
  ClaimReimbursementActivityReportDto,
  ClaimReimbursementActivityReportGroup,
  GroupByFilter,
  ReportNestedItemGroup,
  ReportTableItem,
  ReportTableRowItem,
} from '@/modules/employer/components/Reports/report.types';
import { PaymentType } from '@/modules/transaction/transaction.types';

const generateFieldRow = (indicator: ClaimReimbursementActivityReport) => ({
  id: _.uniqueId(),
  fields: [
    {
      key: 'plan_type',
      value: formatSnakeCaseToGeneral(indicator.plan_type),
      flex: 1,
    },
    {
      key: 'payment_type',
      value: formatSnakeCaseToGeneral(indicator.payment_type),
      flex: 1,
    },
    {
      key: 'count',
      value: indicator.count,
      flex: 1,
    },
  ],
});

const generateTotalFieldRow = (value?: number | string) => ({
  id: _.uniqueId(),
  fields: [
    {
      key: 'plan_type',
      value: 'Total',
      flex: 1,
      textStyle: {
        fontWeight: 900,
      },
    },
    {
      key: 'payment_type',
      value: '',
      flex: 1,
    },
    {
      key: 'count',
      value: value || '-',
      flex: 1,
      textStyle: {
        fontWeight: 900,
      },
    },
  ],
});

const generateTableHeader = (id: string): ReportTableItem[] => ([
  {
    key: `plan_type_${id}`,
    value: 'Plan Type',
    isHeader: true,
    flex: 1,
  },
  {
    key: `payment_type_${id}`,
    value: 'Claim Submission Type',
    isHeader: true,
    flex: 1,
  },
  {
    key: `count_${id}`,
    value: 'Total',
    isHeader: true,
    flex: 1,
  },
]);

const generateTableRows = (reportData: ClaimReimbursementActivityReportDto) => {
  const tableCardSwipeRow = [
    ...(reportData?.claim_reimbursement_indicators || [])
      .filter((indicator) => (indicator.payment_type) === PaymentType.CARD_SWIPE)
      .map(generateFieldRow) as ReportTableRowItem[],
    ...reportData?.total_card_swipe
      ? [generateTotalFieldRow(reportData?.total_card_swipe)]
      : [],
  ];

  const tableBillPayRow = [
    ...(reportData?.claim_reimbursement_indicators || [])
      .filter((indicator) => (indicator.payment_type) === PaymentType.BILL_PAY)
      .map(generateFieldRow) as ReportTableRowItem[],
    ...reportData?.total_bill_pay
      ? [generateTotalFieldRow(reportData?.total_bill_pay)]
      : [],
  ];

  const tableReimbursementRow = [
    ...(reportData?.claim_reimbursement_indicators || [])
      .filter((indicator) => (indicator.payment_type) === PaymentType.REIMBURSEMENT)
      .map(generateFieldRow) as ReportTableRowItem[],
    ...reportData?.total_reimbursement
      ? [generateTotalFieldRow(reportData?.total_reimbursement)]
      : [],
  ];

  return [
    ...tableReimbursementRow,
    ...tableCardSwipeRow,
    ...tableBillPayRow,
  ];
};

const formattingVariantGroupedByPartner = ( // PARTNER EMPLOYER
  response: ClaimReimbursementActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    tableData: {
      header: generateTableHeader(group.group_name),
      rows: generateTableRows(group.indicators[0] as ClaimReimbursementActivityReportDto),
    },
  })),
]);

const formattingVariantGroupedByDateOnly = ( // DATE ONLY
  response: ClaimReimbursementActivityReportGroup[],
): ReportNestedItemGroup[] => [
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    tableData: {
      header: generateTableHeader(group.group_name),
      rows: generateTableRows(group.indicators[0] as ClaimReimbursementActivityReportDto),
    },
  })),
];

const formattingVariantGroupedByPartnerAndEmployer = ( // PARTNER AND EMPLOYER
  response: ClaimReimbursementActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as ClaimReimbursementActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          hierarchy: true,
          tableData: {
            header: generateTableHeader(data.group_name),
            rows: generateTableRows(data.indicators[0] as ClaimReimbursementActivityReportDto),
          },
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartner = ( // DATE PARTNER EMPLOYER
  response: ClaimReimbursementActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as ClaimReimbursementActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          tableData: {
            header: generateTableHeader(data.group_name),
            rows: generateTableRows(data.indicators[0] as ClaimReimbursementActivityReportDto),
          },
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartnerAndEmployer = (
  response: ClaimReimbursementActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((subGroup) => {
        const subData = subGroup as ClaimReimbursementActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: subData.group_name,
          key: `${group.group_name}${subData.group_name}`,
          hierarchy: true,
          wrapStyle: {
            marginLeft: '8px',
          },
          children: [
            ...subData.indicators.map((indicator) => {
              const data = indicator as ClaimReimbursementActivityReportGroup;
              return {
                id: _.uniqueId(),
                title: data.group_name,
                key: `${group.group_name}${subData.group_name}${data.group_name}`,
                tableData: {
                  header: generateTableHeader(data.group_name),
                  rows: generateTableRows(
                    data.indicators[0] as ClaimReimbursementActivityReportDto,
                  ),
                },
              };
            }),
          ],
        };
      }),
    ],
  })),
]);

export const generateGroupClaimReimbursementActivityReport = (
  data: {
    response: ClaimReimbursementActivityReportGroup[],
    groupByType: GroupByFilter | null,
  },
): ReportNestedItemGroup[] => {
  const {
    response,
    groupByType,
  } = data;
  switch (groupByType) {
  case GroupByFilter.Partner: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.Employer: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.DateOnly: {
    return formattingVariantGroupedByDateOnly(response);
  }
  case GroupByFilter.PartnerAndEmployer: {
    return formattingVariantGroupedByPartnerAndEmployer(response);
  }
  case GroupByFilter.DatePartner: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DateEmployer: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DatePartnerAndEmployer: {
    return formattingVariantGroupedByDatePartnerAndEmployer(response);
  }
  default:
    return [] as ReportNestedItemGroup[];
  }
};
