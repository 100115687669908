import { Accordion, Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

export const AccordionWrap = styled(Box)`
  display: block;
  overflow: auto;
`;

export const StyledAccordion = styled(Accordion)`
 & > div {
   box-shadow: none ;
 }
  ul,
  ul:focus {
    outline: none;
  }
`;

export const AnimatedText = styled(Inscription)`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\\2026";
    width: 0px;
  }
  
  @keyframes ellipsis {
    to {
      width: 10px;    
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 10px;    
    }
  }
`;
