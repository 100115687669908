import { useCallback, useMemo } from 'react';
import _ from 'lodash';

import { SERVER_ERRORS } from '@/common/constants';
import routes from '@/common/routes';
import {
  GlobalLoaderMessages,
  useGlobalLoaderStore,
} from '@/components/elements/FullLoader/store/useGlobalLoader.store';
import useTOCSidebar from '@/modules/core/components/TOCSidebar/TOCSidebar.store';
import { SnackbarWithRedirect } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';

import { LogoType } from '../../Branding/branding.types';
import { useUploadOrganizationLogos } from '../../Branding/query/useUploadOrganizationLogos';
import { useStore } from '../../Branding/store/useOrganizationSetupLogo.store';
import { useSaveEmployer, useServerErrors, useSetupEmployerMode } from '../../hooks';
import { useModalType, useSaveTypeStore } from '../../stores';

import useSaveValidate from './useSaveValidate';

const LOGO_SECURITY_ERROR = 400;
let timerId: NodeJS.Timeout;

export default () => {
  const {
    setActivateModal,
    setPendingModal,
    setActivateSaveModal,
    setIsEmptySaveModal,
    setIsActivatingModal,
    setSuccessfullyActivatedModal,
    setUnableToActivateModal,
  } = useModalType();
  const {
    redirectToHome, mode,
  } = useSetupEmployerMode();
  const { employerID } = useSetupEmployerMode();
  const { formattedData, refetch } = useGetEmployer();
  const status = formattedData.organizationStatusType;
  const { handleSave, handleActivateEmployerAsync } = useSaveEmployer();
  const {
    formatedPendingErrors,
    formatedPendingSuccesses,
    formatedActivateErrors,
  } = useSaveValidate();
  const {
    setIsUnableToActivate,
  } = useTOCSidebar();
  const {
    handleSetPendingStatusType, handleSetActiveStatusType,
    pendingSubmitted,
    activateSubmitted,
  } = useSaveTypeStore();
  const { setLoader, removeLoading } = useGlobalLoaderStore();
  const { handleAddPermanentSnackbar, handleReset: handleResetSnackbar } = useSnackbar();
  const { handleServerErrors, handleResetErrors } = useServerErrors();

  const largeDarkLogo = useStore((store) => store.largeDarkLogo);
  const largeLightLogo = useStore((store) => store.largeLightLogo);
  const miniDarkLogo = useStore((store) => store.miniDarkLogo);
  const miniLightLogo = useStore((store) => store.miniLightLogo);
  const securityError = useStore((store) => store.securityError);
  const setSecurityError = useStore((store) => store.setSecurityError);
  const handleResetLogosErrors = useStore((store) => store.handleResetErrors);
  // @ts-ignore
  const setLogoSecurityError = useCallback((error, logoType: LogoType) => {
    if (error?.response?.status !== LOGO_SECURITY_ERROR) return;

    if (logoType === LogoType.largeDark) {
      setSecurityError({
        ...securityError,
        largeDarkLogo: true,
      });
    } else if (logoType === LogoType.largeLight) {
      setSecurityError({
        ...securityError,
        largeLightLogo: true,
      });
    } else if (logoType === LogoType.minimizedDark) {
      setSecurityError({
        ...securityError,
        miniDarkLogo: true,
      });
    } else if (logoType === LogoType.minimizedLight) {
      setSecurityError({
        ...securityError,
        miniLightLogo: true,
      });
    }
  }, [securityError, setSecurityError]);

  const {
    uploadLogo: uploadLargeDarkLogo,
  } = useUploadOrganizationLogos(formattedData.id, LogoType.largeDark, setLogoSecurityError);
  const {
    uploadLogo: uploadLargeLightLogo,
  } = useUploadOrganizationLogos(formattedData.id, LogoType.largeLight, setLogoSecurityError);
  const {
    uploadLogo: uploadMiniDarkLogo,
  } = useUploadOrganizationLogos(formattedData.id, LogoType.minimizedDark, setLogoSecurityError);
  const {
    uploadLogo: uploadMiniLightLogo,
  } = useUploadOrganizationLogos(formattedData.id, LogoType.minimizedLight, setLogoSecurityError);

  const { employer } = useSetupEmployerStore();
  const employerName = useMemo(() => employer?.employerName, [employer]);
  const snackbarRedirectOption: SnackbarWithRedirect['redirect'] = useMemo(() => ({
    path: routes.HOME,
    label: `Exit ${mode} setup`,
  }), [mode]);
  const message = useMemo(() => {
    switch (mode) {
    case EmployerSetupModes.partner: {
      return GlobalLoaderMessages.PARTNER_UPDATING;
    }
    case EmployerSetupModes.distributor: {
      return GlobalLoaderMessages.DISTRIBUTOR_UPDATING;
    }
    case EmployerSetupModes.employer: {
      return GlobalLoaderMessages.EMPLOYER_UPDATING;
    }
    case EmployerSetupModes.subsidiary: {
      return GlobalLoaderMessages.SUBSIDIARY_UPDATING;
    }
    default: {
      return GlobalLoaderMessages.PARTNER_UPDATING;
    }
    }
  }, [mode]);

  const handleUploadLogos = useCallback(async () => {
    if (largeDarkLogo) {
      await uploadLargeDarkLogo(largeDarkLogo);
    }
    if (largeLightLogo) {
      await uploadLargeLightLogo(largeLightLogo);
    }
    if (miniDarkLogo) {
      await uploadMiniDarkLogo(miniDarkLogo);
    }
    if (miniLightLogo) {
      await uploadMiniLightLogo(miniLightLogo);
    }
  }, [
    uploadLargeDarkLogo,
    uploadLargeLightLogo,
    uploadMiniDarkLogo,
    uploadMiniLightLogo,
    largeDarkLogo,
    largeLightLogo,
    miniDarkLogo,
    miniLightLogo,
  ]);

  const handleSavePending = useCallback(async () => {
    handleResetErrors();
    await handleSetPendingStatusType(true);
    if (_.isEmpty(formatedPendingErrors)) {
      try {
        setLoader({ message });
        await handleUploadLogos();
        await handleSave();
        await removeLoading(2000);
        handleResetLogosErrors();
        handleAddPermanentSnackbar({
          text: `${employerName} saved!`,
          closeIcon: true,
          redirect: snackbarRedirectOption,
        });
      } catch (e) {
       
        const code = _.get(e, 'response.status', 0) as number;

        if (code !== SERVER_ERRORS.BAD_REQUEST) {
          setPendingModal(true);
        } else if (code === SERVER_ERRORS.UNAUTHORIZED || !code) {
          return;
        } else {
          setIsEmptySaveModal(true);
        }
        handleServerErrors(e as object);

        await removeLoading(1000);
      }
    } else if (_.isEmpty(formatedPendingSuccesses)) {
      setIsEmptySaveModal(true);
    } else if (!pendingSubmitted) {
      setPendingModal(true);
    }
  }, [
    handleResetErrors,
    handleSetPendingStatusType,
    formatedPendingErrors,
    formatedPendingSuccesses,
    pendingSubmitted,
    setLoader,
    message,
    handleSave,
    removeLoading,
    handleAddPermanentSnackbar,
    employerName,
    snackbarRedirectOption,
    handleServerErrors,
    setPendingModal,
    setIsEmptySaveModal,
    handleResetLogosErrors,
    handleUploadLogos,
  ]);
  // const activatingMessage = useMemo(() => {
  //   switch (mode) {
  //     case EmployerSetupModes.employer: {
  //       return GlobalLoaderMessages.EMPLOYER_ACTIVATING;
  //     }
  //     case EmployerSetupModes.partner: {
  //       return GlobalLoaderMessages.PARTNER_ACTIVATING;
  //     }
  //     case EmployerSetupModes.distributor: {
  //       return GlobalLoaderMessages.DISTRIBUTOR_ACTIVATING;
  //     }
  //     default: {
  //       return GlobalLoaderMessages.PARTNER_ACTIVATING;
  //     }
  //   }
  // }, [mode]);
  const updatingMessage = useMemo(() => {
    switch (mode) {
    case EmployerSetupModes.partner: {
      return GlobalLoaderMessages.PARTNER_UPDATING_ACTIVATED_ORGANIZATION;
    }
    case EmployerSetupModes.distributor: {
      return GlobalLoaderMessages.DISTRIBUTOR_UPDATING_ACTIVATED_ORGANIZATION;
    }
    case EmployerSetupModes.employer: {
      return GlobalLoaderMessages.EMPLOYER_UPDATING_ACTIVATED_ORGANIZATION;
    }
    case EmployerSetupModes.subsidiary: {
      return GlobalLoaderMessages.SUBSIDIARY_UPDATING_ACTIVATED_ORGANIZATION;
    }
    default: {
      return GlobalLoaderMessages.PARTNER_UPDATING_ACTIVATED_ORGANIZATION;
    }
    }
  }, [mode]);

  const handleActivate = useCallback(async () => {
    await handleSetActiveStatusType(true);
    if (_.isEmpty(formatedActivateErrors) && employerID) {
      let isActivatingRunnign = false;
      const activatingIsRunning = () => {
        setIsActivatingModal(false);
        setUnableToActivateModal(true);
        setIsUnableToActivate(true);
        handleAddPermanentSnackbar({
          text: `${employerName} is activating…`,
          closeIcon: true,
          redirect: snackbarRedirectOption,
        });
      };
      try {
        if (status !== EmployerStatus.Active && !formattedData.isActivationStarted) {
          setIsActivatingModal(true);

          const activateAsync = async () => {
            await handleUploadLogos();
            await handleSave();
            await handleActivateEmployerAsync(employerID);
            setTimeout(() => refetch(), 2000);
            handleResetLogosErrors();
            setIsUnableToActivate(false);
            handleAddPermanentSnackbar({
              text: `${employerName} is activated!`,
              closeIcon: true,
              redirect: snackbarRedirectOption,
            });
          };
          const successfullyActivated = () => {
            if (isActivatingRunnign) return;
            setIsActivatingModal(false);
            setSuccessfullyActivatedModal(true);
          };

          timerId = setTimeout(() => {
            isActivatingRunnign = true;
            activatingIsRunning();
          }, 5000);
          await activateAsync();
          clearTimeout(timerId);
          successfullyActivated();
        } else {
          setLoader({ message: updatingMessage });
          await handleUploadLogos();
          await handleSave();
          await removeLoading(2000);
          handleResetLogosErrors();
          handleAddPermanentSnackbar({
            text: `${employerName} saved!`,
            closeIcon: true,
            redirect: snackbarRedirectOption,
          });
        }
      } catch (e) {
        if (timerId) clearTimeout(timerId);
        if (isActivatingRunnign) return;
        handleResetSnackbar();
        setUnableToActivateModal(false);
        setIsUnableToActivate(false);
        setIsActivatingModal(false);
     
        const code = _.get(e, 'response.status', 0) as number;
        if (code !== SERVER_ERRORS.BAD_REQUEST) {
          setActivateSaveModal(true);
        } else if (code === SERVER_ERRORS.UNAUTHORIZED || !code) {
          return;
        } else {
          setActivateModal(true);
        }

        handleServerErrors(e as object);
        await removeLoading(2000);
      }
    } else if (!activateSubmitted) {
      setActivateModal(true);
    }
  }, [
    handleResetSnackbar,
    updatingMessage,
    formattedData.isActivationStarted,
    handleSetActiveStatusType,
    formatedActivateErrors,
    employerID,
    status,
    setLoader,
    handleSave,
    handleActivateEmployerAsync,
    removeLoading,
    snackbarRedirectOption,
    handleAddPermanentSnackbar,
    employerName,
    handleServerErrors,
    setActivateSaveModal,
    setActivateModal,
    activateSubmitted,
    refetch,
    handleResetLogosErrors,
    setIsActivatingModal,
    setSuccessfullyActivatedModal,
    setUnableToActivateModal,
    setIsUnableToActivate,
    handleUploadLogos,
  ]);

  const handleDiscard = () => {
    redirectToHome();
  };

  return {
    handleSavePending,
    handleActivate,
    handleDiscard,
  };
};
