import { baseStoreCreator } from '@/utils/store';

export interface PreferredPhysicalAddressPayload {
  sameAsMailing: boolean;
  physicalFirstAddressLine: string;
  physicalSecondAddressLine: string;
  physicalCity: string;
  physicalState: string;
  physicalZipCode: string;
  physicalCountryCode: string;

}

export const DEFAULT_VALUES: PreferredPhysicalAddressPayload = {
  sameAsMailing: false,
  physicalFirstAddressLine: '',
  physicalSecondAddressLine: '',
  physicalCity: '',
  physicalState: '',
  physicalZipCode: '',
  physicalCountryCode: '',
};

export const useStore = baseStoreCreator<PreferredPhysicalAddressPayload>(DEFAULT_VALUES);

export default useStore;
