import React, {
  useCallback, useMemo, useState,
} from 'react';
import { AppButton, Box, Text } from '@common-fe/common-fe';

import useSubmitFormStore from '@/modules/HealthPlan/HealthPlanSetup/stores/useSubmitForm.store';
import { usePlansByPlanYearQuery } from '@/modules/HealthPlan/queries';
import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { HEALTH_PLAN_MODULES } from '../../HealthPlan.constants';
import { useHealthPlanNameStore } from '../BasicProperties/HealthPlanName/stores';

import AddCoverageLevel from './AddCoverageLevel';
import CoverageLevelsItem from './CoverageLevelsItem';
import { useHealthPlanCoverageLevels } from './hooks';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
}
const CoverageLevels: React.FC<Props> = ({ isEditMode, isTemplateMode }) => {
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);

  const handleToggleSelectModalVisible = useCallback(() => {
    setSelectModalVisible(!selectModalVisible);
  }, [selectModalVisible]);

  const handleShowButton = useCallback((e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleSelectModalVisible();
  }, [handleToggleSelectModalVisible]);
  const { levels, excludeIds } = useHealthPlanCoverageLevels();

  const currentHealthPlanNameState = useHealthPlanNameStore((state) => state.state);

  const { data } = usePlansByPlanYearQuery({
    id: currentHealthPlanNameState?.planYearId,
  });

  const plans = useMemo(() => {
    if (currentHealthPlanNameState.plans && currentHealthPlanNameState.plans?.length > 0) {
      return currentHealthPlanNameState.plans.filter((item) => typeof item === 'string');
    }
    return [];
  }, [currentHealthPlanNameState.plans]);

  const filteredPlans = useMemo(() => data
    .filter((plan) => plans.includes(plan.id)), [plans, data]);

  return (
    <ModuleWrapper
      testId="coverage-levels-wrapper"
      anchorId={HEALTH_PLAN_MODULES.COVERAGE_LEVELS}
      title={(
        <Box direction="row" fill align="center" justify="between">
          <Text size="xxlarge" weight={700}>
            Coverage Levels
          </Text>
          {isEditMode && (
            <AppButton
              buttonType="secondary"
              onClick={handleShowButton}
            >
              Add Coverage Level
            </AppButton>
          )}

        </Box>
      )}
      expandless
    >

      {!isEditMode && !levels.length && (
        <Box background="module" round="moduleRound" pad={{ vertical: 'spacing32' }}>
          <Box align="center">
            <Text weight={700} size="medium">
              There are no coverage levels
            </Text>
          </Box>
        </Box>
      )}
      {levels.map((item, ) => {
        if (item) {
          return (
            <Box key={item.id} pad={{ bottom: 'l' }}>
              <CoverageLevelsItem
                id={item.id}
                coverageId={item.coverageId}
                key={item.id}
                isEditMode={isEditMode}
                isTemplateMode={isTemplateMode}
                isActiveMode={activateValidate}
                isDeletable={item.isDeletable}
                title={item.name || item.title}
                plans={filteredPlans}
                isHighlighted={item.isHighlighted}
              />
            </Box>

          );
        }
        return null;
      })}
      {isEditMode && (
        <AddCoverageLevel
          selectModalVisible={selectModalVisible}
          onToggleModalVisible={handleToggleSelectModalVisible}
          excludeIds={excludeIds}
        />
      )}

    </ModuleWrapper>
  );
};

export default CoverageLevels;
