import React, { useCallback,useMemo, useState } from 'react';
import {
  Box,
  FlexListHeader,
  FlexListItem,
  FlexListShowMore,
  ListItem,
  Text,
} from '@common-fe/common-fe';

import {
  ListItemBase,
  ListItemType,
} from '@/common/types';
import usePlanYearsQuery, { PlanYear } from '@/modules/plan/PlanYearForm/queries/usePlanYears.query';

import PlanYearModalWrapper from './PlanYearModal/PlanYearModalWrapper';

const headers: ListItemBase[] = [
  {
    key: 'planYearName',
    title: 'Plan Year Name',
  },
  {
    key: 'planYearStartEndDate',
    title: 'Start - End date',
  },
  // {
  //   key: 'copayGroup',
  //   title: 'Copay Group',
  //   flex: 1.5,
  // },
  {
    key: 'priorPlanYearId',
    title: 'Prior plan year',
    flex: 1.5,
  },
];

const PER_PAGE = 10;
const PlanYearTable = () => {
  const [showMore, setShowMore] = useState(false);
  const { data: planYearList } = usePlanYearsQuery();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [selectedPlan, setEditPlan] = useState<PlanYear | undefined>();
  const showedList = useMemo(
    () => (showMore ? planYearList : planYearList.slice(0, PER_PAGE)), [planYearList, showMore],
  );
  const moreItemsCount = planYearList.length - showedList.length;

  const onPick = useCallback((planYear: PlanYear, isViewMode?: boolean) => {
    setModalVisible(true);
    setEditPlan(planYear);
    if (isViewMode) {
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  }, []);
  const formatedList = useMemo(() => showedList.map((item) => ({
    id: item.id,
    fields: [
      {
        key: 'planYearName',
        type: ListItemType.Contact,
        title: item.name,
        ellipsisMode: true,
        hasTip: true,
      },
      {
        key: 'planYearStartEndDate',
        type: ListItemType.Text,
        title: `${item.startDate}${item.endDate ? ` - ${item.endDate}` : ''}`,
      },
  
      {
        key: 'priorPlanYear',
        type: ListItemType.Text,
        flex: 1.5,
        title: item.priorPlanYear?.name || '',
      },
    ],
  })), [
    showedList,
    // copayGroups,
  ]);

  return (
    <>
      <Box background="canvas" round="moduleRound">
        {Boolean(planYearList.length) && (
          <Box margin="small" pad="small" background="module" round="container1Round">
            <Text
              margin={{ top: 'spacing8', bottom: 'spacing12' }}
              size="large"
              weight="bold"
              color="textBody"
            >{`Plan year: ${planYearList.length}`}
            </Text>
            <FlexListHeader items={headers} hasOption />
            <Box style={{ position: 'relative' }}>
              {formatedList.map((row: ListItem, index) => (
                <FlexListItem
                  key={row.id}
                  id={row.id}
                  fields={row.fields}
                  onPick={() => onPick(showedList[index], true)}
                  options={[{
                    name: 'Edit',
                    onClick: () => {
                      onPick(showedList[index]);
                    },
                  }]}
                />
              ))}
              {Boolean(moreItemsCount)
            && (
              <FlexListShowMore onClick={() => {
                setShowMore(true);
              }}
              >
                Show More ({moreItemsCount})
              </FlexListShowMore>
            )}
            </Box>
          </Box>
        )}
        <PlanYearModalWrapper
          visible={modalVisible}
          onSetVisible={() => {
            setModalVisible(!modalVisible);
            setEditMode(true);
          }}
          modalTitle={isEditMode ? `Edit ${selectedPlan?.name}` : selectedPlan?.name}
          isEditMode={isEditMode}
          selectedPlan={selectedPlan}
        />
      </Box>
    </>
  );
};

export default PlanYearTable;
