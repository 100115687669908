import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ROUTES from '@/common/routes';

import { useGetEmployeeByIdQuery } from '../queries';
import { useEmployeeStore } from '../stores';

const useGetEmployeeById = (employeeId?: string, disabled?: boolean) => {

  const { setSourceState } = useEmployeeStore();
  const [isEmployeeSet, setIsEmployeeSet] = useState(false);
  const {path, params: { id } } = useRouteMatch<{ id: string }>();
  const isEditMode = useMemo(() => path === ROUTES.EMPLOYEE_EDIT_VIEW(), [path]);
  const {
    data, isSuccess, refetch, isLoading,
  } = useGetEmployeeByIdQuery(
    employeeId || id,
    disabled,
  );

  useEffect(() => {
    if (data) {
      
      if(isEditMode) {
        delete data?.ssn;
      }
      
      setSourceState(data);
      setIsEmployeeSet(true);
    }
  }, [data, isEditMode, isSuccess, setSourceState]);

  return {
    fullName: data?.fullName || '',
    isSuccess,
    data,
    isLoading,
    refetch,
    isEmployeeSet,
  };
};

export default useGetEmployeeById;
