import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';
import { SubsidiaryInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { QUERY_KEY,VALUES } from './SubsidiesList/useSubsidiaryList.query';

mock.onPost(PATHS.GET_SUBSIDIARIES).reply(200, {});

export default () => {
  const [loading, setLoading] = useState(false);
  const setSubsidiary = useStore((state) => state.setSubsidiary);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(
    (newValue: SubsidiaryInfo) => api.post(PATHS.GET_SUBSIDIARIES, newValue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const handleSave = async (newValue: SubsidiaryInfo) => {
    setLoading(true);
    const found = VALUES.find((item) => item.id === newValue.id);
    if (found) {
      Object.keys(found).forEach((key) => {
        // @ts-ignore
        found[key] = newValue[key];
      });
      await mutateAsync(found);
      setSubsidiary(found);
      return;
    }
    const formatedData: SubsidiaryInfo = {
      ...newValue,
      phoneNumber: newValue.phoneNumber.replace(/[^0-9]/g, ''),
      phoneNumberExtension: newValue.phoneNumberExtension.replace(/[^0-9]/g, ''),
    };
    await mutateAsync(formatedData);

    const newSubsidiary = {
      ...formatedData,
      id: _.uniqueId(),
      createDate: Date.now(),
    };
    // For the mock api
    VALUES.push(newSubsidiary);
    setSubsidiary(newSubsidiary);
    await mutateAsync(formatedData);
  };

  return {
    loading,
    save: handleSave,
  };
};
