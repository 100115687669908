import React, { useRef, useState } from 'react';
import { Box, ThreeDotIcon, useClickOutside } from '@common-fe/common-fe';
import styled from 'styled-components';

import spacings from '@/styles/spacings';

const StyledBox = styled(Box)`
  ${({ theme }) => theme.border.blueGray2}
  ${({ theme }) => theme.shadows.active}
`;

interface Props {
  children?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  buttonView?: React.ReactNode;
}
export const DropButton: React.FC<Props> = ({ children, contentStyle, buttonView }) => {
  const ref = useRef(null);
  const [expanded, setExpanded] = useState(false);
  useClickOutside(ref, () => {
    setExpanded(false);
  });
  return (
    <Box
      style={{
        position: 'relative',
      }}
      ref={ref}
    >
      {expanded && (
        <StyledBox
          data-testid="DropButton_content_container"
          pad={{ horizontal: 'm', vertical: 'm' }}
          background="canvas"
          round={spacings.xs}
          width={{ min: '200px', max: '200px' }}
          height={{ min: '90px', max: '90px' }}
          style={{
            position: 'absolute',
            top: '-100px',
            left: '-100px',
            ...contentStyle,
          }}
        >
          {children}
        </StyledBox>
      )}

      <Box data-testid="DropButton_testid" onClick={() => setExpanded(!expanded)}>
        {buttonView || <ThreeDotIcon />}
      </Box>

    </Box>
  );
};
