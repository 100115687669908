import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton, Box, Inscription, Preloader, WarnModal } from '@common-fe/common-fe';
import _ from 'lodash';

import { SnackbarState } from '@/modules/core/core.types';
import { useCurrentOrganization, useSnackbar } from '@/modules/core/hooks';

import useValidatePayrollCalendarForm from '../hooks/useValidatePayrollCalendarForm';
import {
  ConnectToOptions,
  PayrollCalendarFormFields,
  PayrollCalendarFormValues,
  PayrollCalendarFrequency,
  PayrollCalendarRequest,
  PayrollCalendarUseFormFields,
  PayrollCalendarUseFormValues,
  PlanCodeId,
} from '../payrollCalendar.types';
import { paramsBuilder } from '../PayrollCalendarParams/PayrollCalendarParamsModalForm';
import useCreatePayrollCalendarQuery from '../queries/useCreatePayrollCalendar.query';
import { useUpdatePayrollCalendarQuery } from '../queries/useUpdatePayrollCalendar.query';
import {
  useEditablePayrollCalendarStore,
  usePayrollCalendarDatesStore,
  usePayrollCalendarModalStore,
  usePayrollCalendarParamsStore,
} from '../stores';
import { PayrollCalendarModalSteps } from '../stores/usePayrollCalendarModal.store';

import { usePayrollCalendarUse } from './hooks/usePayrollCalendarUse';
import { usePayrollCalendarUseFormFields } from './hooks/usePayrollCalendarUseFormFields';
import { PayrollCalendarUseForm } from './PayrollCalendarUseForm';

interface ShortPlan {
  id: string;
  planCode: string;
}

const requestBuilder = (
  paramsValues: PayrollCalendarFormValues,
  dates: string[],
  useValues: PayrollCalendarUseFormValues,
  plans: ShortPlan[],
  groups: string[],
  organizationId: string,
  organizationPath: string,
  defaultValues?: PayrollCalendarUseFormValues
): PayrollCalendarRequest => {
  const calculateSchedule = paramsBuilder(paramsValues);
  const reverseFrequencyMapping = Object.entries(PayrollCalendarFrequency).reduce((acc, [key, value]) => {
    acc[value as string] = key;
    return acc;
  }, {} as { [key: string]: string });
  const filteredPlans = useValues[PayrollCalendarUseFormFields.PLANS]?.length
    ? useValues[PayrollCalendarUseFormFields.PLANS]?.filter(
      (plan) => !defaultValues?.[PayrollCalendarUseFormFields.PLANS]?.includes(plan)
    )
    : [];
  const filteredAllPlans = plans?.filter(
    (plan) => !defaultValues?.[PayrollCalendarUseFormFields.PLANS]?.includes(plan.id)
  );
  const filteredGroups = useValues[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.length
    ? useValues[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.filter(
      (group) => !defaultValues?.[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.includes(group)
    )
    : [];
  const filteredAllGroups = groups?.filter(
    (group) => !defaultValues?.[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.includes(group)
  );
  return {
    calendar_name: paramsValues[PayrollCalendarFormFields.CALENDAR_NAME],
    frequency_type: reverseFrequencyMapping[paramsValues[PayrollCalendarFormFields.FREQUENCY] as string],
    group_type: useValues[PayrollCalendarUseFormFields.CONNECT_TO] as string,
    calculate_schedule_request_dto: calculateSchedule,
    scheduled_dates: dates,
    ...(useValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN &&
    useValues[PayrollCalendarUseFormFields.PLANS]?.length
      ? {
        plan_code_ids: filteredPlans?.map((plan) => {
          const foundPlan = plans.find((item) => item.id === plan);
          return {
            plan_code: foundPlan?.planCode,
            plan_id: plan,
          };
        }) as PlanCodeId[],
      }
      : {}),
    ...(useValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP &&
    useValues[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.length
      ? { payroll_group_ids: filteredGroups }
      : {}),
    ...(useValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.ALL
      ? {
        plan_code_ids: filteredAllPlans?.map((plan) => ({
          plan_code: plan.planCode,
          plan_id: plan.id,
        })) as PlanCodeId[],

        payroll_group_ids: filteredAllGroups,
      }
      : {}),
    organization_id: organizationId,
    organization_path: organizationPath,
  };
};

const MODAL_SUBTITLE = `Select the plan(s) or payroll group(s) that will contribute on this calendar.
 If you only have one calendar, this calendar can be applied to everyone. You can always skip and connect the calendar later.`;

interface Props {
  onCancel: () => void;
  onSuccess?: () => void;
}

const PayrollCalendarUseModalForm: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const {
    observingOrganization: { id, path },
  } = useCurrentOrganization();
  const [isServerError, setIsServerError] = useState(false);
  const [formValues, setFormValues] = useState<PayrollCalendarUseFormValues>({});
  const [isNotEdited, setIsNotEdited] = useState(true);

  const editMode = usePayrollCalendarModalStore((state) => state.editMode);
  const editableCalendar = useEditablePayrollCalendarStore((state) => state.editableCalendar);
  const handleSetStep = usePayrollCalendarModalStore((state) => state.handleSetStep);
  const dates = usePayrollCalendarDatesStore((state) => state.dates);
  const calendarParams = usePayrollCalendarParamsStore((state) => state.calendarParams);

  const {
    payrollGroupsOptions,
    plansOptions,
    isAllOptionDisabled,
    isPlanOptionDisabled,
    isGroupOptionDisabled,
    plans,
    payrollGroups,
    usedPlans,
    usedGroups,
    plansRemainLength,
    groupsRemainLength,
    handleShowMore,
    currentDisabledUsedGroups,
    currentDisabledUsedPlans,
  } = usePayrollCalendarUse(formValues);

  const disabledPlansList = useMemo(() => {
    const filtered = plansOptions?.filter((item) => item.disabled);
    return filtered?.map((item) => item.key) || [];
  }, [plansOptions]);

  const disabledPayrollGroupsList = useMemo(() => {
    const filtered = payrollGroupsOptions?.filter((item) => item.disabled);
    return filtered?.map((item) => item.key) || [];
  }, [payrollGroupsOptions]);

  const alreadyUsedValues = useMemo(
    () => ({
      [PayrollCalendarUseFormFields.PLANS]: usedPlans,
      [PayrollCalendarUseFormFields.PAYROLL_GROUPS]: usedGroups,
    }),
    [usedPlans, usedGroups]
  );

  const alreadyUsedValuesEdit = useMemo(
    () => ({
      [PayrollCalendarUseFormFields.PLANS]: currentDisabledUsedPlans,
      [PayrollCalendarUseFormFields.PAYROLL_GROUPS]: currentDisabledUsedGroups,
    }),
    [currentDisabledUsedPlans, currentDisabledUsedGroups]
  );

  const defaultValues = useMemo(
    () => ({
      [PayrollCalendarUseFormFields.CONNECT_TO]: editableCalendar?.groupType,
    }),
    [editableCalendar]
  );

  const { createCalendar, isLoading: isCreateLoading } = useCreatePayrollCalendarQuery();
  const { updateCalendar, isLoading: isUpdateLoading } = useUpdatePayrollCalendarQuery(editableCalendar?.id);
  const { handleAddPermanentSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN) {
      const valuesList = formValues[PayrollCalendarUseFormFields.PLANS] || [];
      const difference = _.difference(valuesList, disabledPlansList || []);
      if (!difference.length) {
        return;
      }
    }
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP) {
      const valuesList = formValues[PayrollCalendarUseFormFields.PAYROLL_GROUPS] || [];
      const difference = _.difference(valuesList, disabledPayrollGroupsList || []);
      if (!difference.length) {
        return;
      }
    }

    try {
      if (!calendarParams || !dates.length) {
        return;
      }
      const request = requestBuilder(
        calendarParams,
        dates,
        formValues,
        plans as ShortPlan[],
        payrollGroups?.map((group) => `${group.id}`) as string[],
        id as string,
        path as string,
        editMode ? alreadyUsedValuesEdit : alreadyUsedValues
      );

      if (editMode) {
        await updateCalendar(request);
      } else {
        await createCalendar(request);
      }
      onCancel();
      handleAddPermanentSnackbar({
        text: `${calendarParams[PayrollCalendarFormFields.CALENDAR_NAME]} was ${
          editMode ? 'updated' : 'created'
        }!`,
        closeIcon: true,
        state: SnackbarState.positive,
      });
      onSuccess?.();
    } catch {
      setIsServerError(true);
    }
  }, [
    formValues,
    disabledPlansList,
    disabledPayrollGroupsList,
    calendarParams,
    dates,
    plans,
    payrollGroups,
    id,
    path,
    editMode,
    alreadyUsedValuesEdit,
    alreadyUsedValues,
    onCancel,
    handleAddPermanentSnackbar,
    onSuccess,
    updateCalendar,
    createCalendar,
  ]);

  const currentHandleShowMore = useCallback(() => {
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN) {
      handleShowMore(ConnectToOptions.PLAN);
    }
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP) {
      handleShowMore(ConnectToOptions.GROUP);
    }
  }, [formValues, handleShowMore]);

  const currentRemainLength = useMemo(() => {
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN) {
      return plansRemainLength;
    }
    if (formValues[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP) {
      return groupsRemainLength;
    }
    return undefined;
  }, [formValues, plansRemainLength, groupsRemainLength]);

  const fields = usePayrollCalendarUseFormFields({
    formValues,
    defaultValues,
    alreadyUsedValues,
    payrollGroupsOptions,
    plansOptions,
    disabledPlansList,
    isAllOptionDisabled,
    isPlanOptionDisabled,
    isGroupOptionDisabled,
    showMoreCount: currentRemainLength,
    disabledPayrollGroupsList,
    onShowMore: currentHandleShowMore,
  });

  const { isSubmitted, handleValidate } = useValidatePayrollCalendarForm({
    fields,
    state: formValues,
    onSuccess: handleSubmit,
  });

  useEffect(() => {
    if (editMode && editableCalendar) {
      const values = {
        [PayrollCalendarUseFormFields.CONNECT_TO]: editableCalendar.groupType,
        [PayrollCalendarUseFormFields.PLANS]: editableCalendar.calendarPlans?.map((plan) => `${plan.id}`),
        [PayrollCalendarUseFormFields.PAYROLL_GROUPS]: editableCalendar.calendarPayrollGroups?.map(
          (group) => `${group.id}`
        ),
      };
      setFormValues(values);
    }
  }, [editMode, editableCalendar]);

  useEffect(() => {
    if (editMode && editableCalendar) {
      const values = {
        [PayrollCalendarUseFormFields.CONNECT_TO]: editableCalendar.groupType,
        [PayrollCalendarUseFormFields.PLANS]: editableCalendar.calendarPlans?.map((plan) => `${plan.id}`),
        [PayrollCalendarUseFormFields.PAYROLL_GROUPS]: editableCalendar.calendarPayrollGroups?.map(
          (group) => `${group.id}`
        ),
      };
      setIsNotEdited(_.isMatch(values, formValues));
    }
  }, [editMode, editableCalendar, formValues]);

  return (
    <>
      <Box align="center" justify="center" pad={{ horizontal: 'spacing24' }} margin={{ bottom: 'spacing16' }}>
        <Inscription textAlign="center" color="textTitle" lineHeight="20px">
          {MODAL_SUBTITLE}
        </Inscription>
      </Box>
      <Box data-testid="PayrollCalendarUseModalForm_container">
        <Box direction="column">
          <Box round="container1Round" pad="spacing24" background="module">
            <PayrollCalendarUseForm
              fields={fields}
              isSubmit={editMode ? (isSubmitted && !isNotEdited) : isSubmitted}
              handleChangeValues={setFormValues}
            />
          </Box>
        </Box>
        <Box
          direction="row"
          fill="horizontal"
          margin={{ top: 'spacing24' }}
          align="end"
          justify="end"
          width="medium"
          gap="xs"
        >
          <Box direction="row" gap="xs">
            <AppButton
              testId="PayrollCalendarUseModalForm_cancel"
              buttonType="secondary"
              width="control"
              type="button"
              onClick={() => handleSetStep(PayrollCalendarModalSteps.SET_PAYROLL_CALENDAR_DATES)}
            >
              Back
            </AppButton>
            <AppButton
              testId="PayrollCalendarUseModalForm_submit"
              type="button"
              disabled={isCreateLoading || isUpdateLoading}
              onClick={handleValidate}
              width="control"
            >
              {isCreateLoading || isUpdateLoading ? <Preloader /> : 'Submit'}
            </AppButton>
          </Box>
        </Box>
      </Box>

      <WarnModal
        testId="add-payroll-calendar-server-error-modal"
        visible={isServerError}
        onSetVisible={setIsServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Payroll calendar with the latest records.
            Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
    </>
  );
};

export default PayrollCalendarUseModalForm;
