import { Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

const TextWithPointer = styled(Text)<{ ellipsisMode?: boolean, disableHovering?: boolean }>`
  cursor: pointer;
  display: block;
  &:hover {
  ${({ disableHovering }) => {
    if (disableHovering) return null;

    return {
      color: globalTheme.colors.textAccent,
    };
  }}
  };
  ${({ ellipsisMode }) => {
    if (ellipsisMode) {
      return {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
      };
    }

    return null;
  }};
`;

export default TextWithPointer;
