import React, { useMemo } from 'react';
import {
  Box, ElevateIcon, ElevateLogoIcon,
  Text, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { REGEXPS } from '@/common';
import {
  FAV_SIZE,
  LetterIconSizeEnum,
  LOGO_SIZE,
  OrganizationTypes,
} from '@/common/constants';
import colors from '@/styles/colors';

export interface Props {
  type?: OrganizationTypes,
  size: LetterIconSizeEnum,
  text?: string;
  sizePx?: string;
  testId?: string;
}

interface StyledWrapProps {
  type?: OrganizationTypes,
}

const StyledWrap = styled(Box) <StyledWrapProps>`
  ${({ type }) => {
    if (type === OrganizationTypes.partner
      || type === OrganizationTypes.distributor) {
      return {
        'border-radius': '20%',
      };
    }
    return {
      'border-radius': '50%',
    };
  }}
`;

export const LetterIcon: React.FC<Props> = ({
  type,
  size,
  text,
  sizePx,
  testId,
}) => {
  const firstLetter = useMemo(() => {
    if (type === OrganizationTypes.dependent && text) {
      return text.match(REGEXPS.FIRST_LETTERS)?.join('') || '';
    }
    return (text ? (text[0] || '') : '');
  }, [text, type]);
  const background = useMemo(() => {
    switch (type) {
    case OrganizationTypes.company: {
      return colors.employer;
    }
    case OrganizationTypes.partner: {
      return colors.partner;
    }
    case OrganizationTypes.distributor: {
      return colors.distributor;
    }
    case OrganizationTypes.subsidiary: {
      return colors.subsidiary;
    }
    case OrganizationTypes.subgroup: {
      return colors.subgroup;
    }
    case OrganizationTypes.dependent: {
      return colors.dependent;
    }
    default: {
      return colors.noAssignee;
    }
    }
  }, [type]);

  const iconSize = useMemo(() => {
    if (sizePx) return `${sizePx}px`;
    return size === LetterIconSizeEnum.LOGO ? LOGO_SIZE : FAV_SIZE;
  }, [size, sizePx]);

  if (type === OrganizationTypes.system && size === LetterIconSizeEnum.FAV) {
    return <ElevateLogoIcon data-testid="LetterIcon-elevate-fav" />;
  }

  if (type === OrganizationTypes.system && size === LetterIconSizeEnum.LOGO) {
    return (
      <ElevateIcon
        data-testid={testId}
        width={sizePx || '34'}
        height={sizePx || '34'}
        color="iconAccent"
      />
    );
  }
  return (
    <StyledWrap
      background={background}
      justify="center"
      align="center"
      width={iconSize}
      data-testid={testId || 'LetterIcon-generate'}
      height={iconSize}
      style={{
        minWidth: iconSize,
      }}
      type={type}
    >
      {
        size === LetterIconSizeEnum.LOGO
          ? (
            <Text
              weight="bold"
              size={firstLetter.length > 1 ? 'regular' : '2xl'}
              data-testid="LetterIcon-generate-logo-size"
              color="textOnColor"
            >{firstLetter}
            </Text>
          )
          : (
            <Text
              weight="bold"
              size="small"
              data-testid="LetterIcon-generate-fav-size"
              color="textOnColor"
            >{firstLetter}
            </Text>
          )
      }
    </StyledWrap>
  );
};
