import React, { useCallback, useEffect,useState } from 'react';
import {
  Box, Text, WarningLabel, WarnModal,
} from '@common-fe/common-fe';

import { StatusWrapper } from '@/components';

import { PlanCardAction } from '../../plan.types';
import PlanModalWrapper from '../PlanModalWrapper';
import { useForceOpenModalStore } from '../stores';

import Footer from './Footer';
import { useCreatePlan } from './hooks';
import PlanName from './PlanName';
import PlanYear from './PlanYearPicker';
// import { useCheckPaymentOrderQuery } from '../../queries';

interface Props {
  hide?: boolean;
}

const CreatePlanModal: React.FC<Props> = ({ hide }) => {
  const modalStore = useForceOpenModalStore((state) => state.state);
  const handleClearModalStore = useForceOpenModalStore((state) => state.clear);
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  // const handleCheck = useCheckPaymentOrderQuery();
  const handleCancel = useCallback(() => {
    setModalVisible(false);
    handleClearModalStore();
  }, [handleClearModalStore]);

  useEffect(() => {
    if (modalStore.actionType === PlanCardAction.createPlanFromTemplate && modalStore.id) {
      setModalVisible(true);
    }
  }, [modalStore.actionType, modalStore.id]);
  const {
    handleSave,
    submitted,
    loading,
    setLoading,
    confirm,
    handlePreSubmit,
    handleReset,
    planCreationError,
    setPlanCreationError,
    // planTemplate,
    // definitionId,

  } = useCreatePlan(handleCancel);
  useEffect(() => {
    if (!modalVisible && !paymentModalVisible) {
      handleReset();
    }
  }, [handleReset, modalVisible, paymentModalVisible]);
  const handleSubmit = useCallback(async () => {
    const isSuccess = handlePreSubmit();
    if (isSuccess) {
      try {
        await handleSave();
      } catch {
        setLoading(false);
        setPaymentModalVisible(false);
        setModalVisible(true);
      }
      // try {
      //   await handleCheck({
      //     templateId: planTemplate || '',
      //     definitionId: definitionId || '',
      //   });
      //   try {
      //     await handleSave();
      //   } catch {
      //     setLoading(false);
      //     setPaymentModalVisible(false);
      //     setModalVisible(true);
      //   }
      // } catch {
      //   setLoading(false);
      //   setPaymentModalVisible(true);
      //   // setModalVisible(false);
      // }
    }
  }, [handlePreSubmit, handleSave, setLoading]);

  const handleTogglePaymentVisible = useCallback((value: boolean) => {
    if (!value) {
      setModalVisible(true);
    }
    setPaymentModalVisible(value);
  }, []);
  const handleToggleModal = useCallback(() => {
    const newValue = !modalVisible;
    setModalVisible(newValue);
    handleReset();
    if (!newValue) {
      handleClearModalStore();
    }
  }, [handleClearModalStore, handleReset, modalVisible]);
  return (
    <>

      <PlanModalWrapper
        visible={modalVisible}
        testId="CreatePlan"
        onSetVisible={handleToggleModal}
        hide={hide}
        buttonText="Create a New Plan"
        modalTitle="Create a New Plan"
      >
        <WarnModal
          testId="CreatePlan_payment_order"
          isPendingIcon
          visible={paymentModalVisible}
          paddingless
          onSetVisible={handleTogglePaymentVisible}
          header="Plan template you selected is not included in any payment order or processing definition"
          helptext={`In that case, manual claims will not be available for your employees enrolled in this plan.
        Do you want to proceed?`}
          buttonText="Close"
          onSubmit={handleSave}
          submitButtonText="Proceed"
        />
        <WarnModal
          visible={planCreationError}
          testId="CreatePlan_server_error"
          onSetVisible={setPlanCreationError}
          header="The server encountered an error processing the request"
          helptext={`It doesn't appear to have affected your data, but we cannot save the plan with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
          buttonText="Close"
          onSubmit={handleSave}
          submitButtonText="Try Again"
        />

        <Box>
          <Box dir="column" background="module" pad="spacing24" round="moduleRound">
            <PlanName
              testId="CreatePlan"
              planTemplateId={modalStore.id}
            />
            <PlanYear testId="CreatePlan" />

            <Box margin={{ top: 'spacing24' }}>
              <StatusWrapper status="pending">
                <Box
                  margin="spacing12"
                  pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
                  background={{ color: 'warningContainer' }}
                  border={{ size: '1px', color: 'warningBorder' }}
                  round="fieldRound"
                >
                  <Text weight="bold" margin={{ bottom: 'spacing8' }} color="textBody">
                    <WarningLabel
                      data-testId="CreatePlan_warning_message"
                    >
                      Does everything look good? Please double check your selections.
                    </WarningLabel>
                  </Text>

                  <Box>
                    <Text
                      data-testId="CreatePlan_confirm_message"
                      margin={{ bottom: 'spacing8' }}
                      color="textBody"
                      size="14px"
                    >
                      Once created, you can’t change the plan template or plan year.
                    </Text>
                  </Box>
                </Box>
              </StatusWrapper>
            </Box>
          </Box>
          <Footer
            testId="CreatePlan_footer"
            unconfirmed={submitted && !confirm}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </Box>

      </PlanModalWrapper>
    </>
  );
};
export default CreatePlanModal;
