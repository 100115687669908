import React, { useMemo } from 'react';
import {
  Box, FlexListItemStatus, Image, PlaceholderNameIcon,
  Text, ThinSearchIcon, TimerIcon, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { REGEXPS } from '@/common';
import { Option } from '@/common/types';
import { Employer } from '@/modules/employer/employer.types';
import useGetEmployerByName from '@/modules/employer/useGetEmployerByName';

import { BoxWithFocus, EmployerIdText, StyledItem } from './ToolbarSmartSearchOption.styles';

const StyledPlaceholderNameIcon = styled(PlaceholderNameIcon)`
  width: 40px;
  height: 32px;
  fill: ${({ theme }) => theme.colors.border};
`;

interface Props {
  option: Option<Employer>;
  onSelect: (option: Option<Employer>) => void;
  searchText: string;
  className?: string;
  isResentFinding?: boolean;
}

export const LONG_TEXT_COUNT = 20;

export const ToolbarSmartSearchOption: React.FC<Props> = ({
  option,
  onSelect,
  searchText,
  className,
  isResentFinding,
}) => {
  const title = useMemo(() => {
    const result = option.title ? option.title : '';
    return result.length > LONG_TEXT_COUNT ? `${result.substring(0, LONG_TEXT_COUNT)}...` : result;
  }, [option]);
  const employerId = useMemo(() => (option.value?.employerID ? option.value.employerID : ''), [option]);
  const titleIndex = useMemo(() => title.toLocaleLowerCase()
    .indexOf(searchText.toLocaleLowerCase()),
  [searchText, title]);

  const employerIdIndex = useMemo(() => employerId.toLocaleLowerCase()
    .indexOf(searchText.toLocaleLowerCase()), [employerId, searchText]);

  const highlightTitleText = useMemo(() => (
    titleIndex >= 0
      ? title.substring(titleIndex, titleIndex + searchText.length)
      : ''
  ), [titleIndex, searchText.length, title]);
  const highlightEmployerIdText = useMemo(() => (
    employerIdIndex >= 0
      ? employerId.substring(employerIdIndex, employerIdIndex + searchText.length)
      : ''
  ), [employerId, employerIdIndex, searchText.length]);
  const icon = useGetEmployerByName(option.value?.logo || '');
  const titlePart1 = useMemo(() => (titleIndex >= 0
    ? title.substring(0, titleIndex)
    : title),
  [title, titleIndex]);
  const titlePart2 = useMemo(() => (
    titleIndex >= 0
      ? title.substring(titleIndex + searchText.length)
      : ''
  ), [searchText.length, title, titleIndex]);

  const employerIdPart1 = useMemo(() => (employerIdIndex >= 0
    ? employerId.substring(0, employerIdIndex)
    : employerId),
  [employerId, employerIdIndex]);
  const employerIdPart2 = useMemo(() => (
    employerIdIndex >= 0
      ? employerId.substring(employerIdIndex + searchText.length)
      : ''
  ), [searchText.length, employerId, employerIdIndex]);

  const id = useMemo(() => option.value?.id, [option]);
  return (
    <BoxWithFocus
      type="button"
      className={className}
      onClick={() => onSelect(option)}
      data-testid={`smart-search_button_${id}`}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect(option);
        }
      }}
    >
      <Box
        data-testid={`smart-search_wrap_${id}`}
        direction="row"
        align="center"
        pad={{ horizontal: 'spacing16', vertical: 'spacing4' }}
      >
        {isResentFinding
          ? (
            <TimerIcon size="12px" color="iconSecondary" />
          )
          : (
            <ThinSearchIcon color="iconSecondary" size="spacing16" />
          )}
        <StyledItem
          data-testid={`smart-search_wrap_${id}_name`}
          margin={{ left: 'spacing12', right: 'spacing54' }}
          direction="row"
        >
          <Text
            data-testid={`smart-search_title_part-1_${id}`}
            weight="bold"
          >{titlePart1.replace(REGEXPS.SPACE_SYMBOL, REGEXPS.SPACE_CODE_SYMBOL)}
          </Text>
          <Text
            weight="bold"
            color="textAccent"
            data-testid={`smart-search_title_highlight_${id}`}
          >{highlightTitleText.replace(REGEXPS.SPACE_SYMBOL, REGEXPS.SPACE_CODE_SYMBOL)}
          </Text>
          <Text
            weight="bold"
            data-testid={`smart-search_title_part-2_${id}`}
          >{titlePart2.replace(REGEXPS.SPACE_SYMBOL, REGEXPS.SPACE_CODE_SYMBOL)}
          </Text>
        </StyledItem>
        <Box direction="row" data-testid={`smart-search_wrap_${id}_id`} margin={{ right: 'spacing16' }}>
          <EmployerIdText data-testid={`smart-search_employerId_part-1_${id}`} title={employerIdPart1}>
            {employerIdPart1}
          </EmployerIdText>
          <EmployerIdText data-testid={`smart-search_employerId_highlight_${id}`} isPrimary title={highlightEmployerIdText}>
            {highlightEmployerIdText}
          </EmployerIdText>
          <EmployerIdText data-testid={`smart-search_employerId_part-2_${id}`} title={employerIdPart2}>
            {employerIdPart2}
          </EmployerIdText>
        </Box>
        <Box direction="row" align="center" data-testid={`smart-search_wrap_${id}_logo`}>
          {icon ? (
            <Image data-testid="employer-logo" src={icon} />
          ) : (
            <StyledPlaceholderNameIcon data-testid="employer-logo" />
          )}
          <Box margin={{ left: 'spacing12' }}>
            <FlexListItemStatus circleOnly status={option.value?.status || ''} />
          </Box>
        </Box>
      </Box>
    </BoxWithFocus>
  );
};
