import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option } from '@/common/types';
import { DeductibleType } from '@/modules/deductibles/deductibles.types';

interface TiersMap {
  [key: string]: string;
}
interface TriggerPayload {
  id: number;
  name: string;
  description: string;
}

export const MET_OPTION_END = ' met';
export const QUERY_KEY = 'GET_TRIGGERS_LIST';

export default (includeEmbedded?: boolean) => {
  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY],
    () => api.get<TriggerPayload[]>(
      PATHS.TRIGGERS,
    ),

  );

  const filteredTriggers = useMemo(() => [!includeEmbedded
    ? DeductibleType.Embedded : undefined], [includeEmbedded]) as string[];
  const isEmbeddedDeductibleMet = useMemo(() => data?.data
    ?.some((item) => item?.name === DeductibleType.Embedded), [data]);

  const isMemberDeductibleMet = useMemo(() => data?.data
    ?.some((item) => item?.name === DeductibleType.Member), [data]);

  const isPlanDeductibleMet = useMemo(() => data?.data
    ?.some((item) => item?.name === DeductibleType.Plan), [data]);

  const fieldOptions = useMemo(() => {
    const list = data?.data || [];
    const filteredList = list.filter((item) => !filteredTriggers.includes(item.name));
    const formattedList = filteredList.map((item) => {
      const formatedItem: Option = {
        key: item.name,
        value: item.description,
        title: `${item.description}${MET_OPTION_END}`,
      };
      return formatedItem;
    });

    if (isMemberDeductibleMet && isEmbeddedDeductibleMet) {
      formattedList.push({
        key: 'MEMBER_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE',
        value: 'Member deductible met or Embedded deductible met',
        title: 'Member deductible met or Embedded deductible met',
      });
    }
    if (isPlanDeductibleMet && isEmbeddedDeductibleMet) {
      formattedList.push({
        key: 'PLAN_DEDUCTIBLE_OR_EMBEDDED_DEDUCTIBLE',
        value: 'Plan deductible met or Embedded deductible met',
        title: 'Plan deductible met or Embedded deductible met',
      });
    }
    return formattedList;
  }, [data, isEmbeddedDeductibleMet, isMemberDeductibleMet, isPlanDeductibleMet, filteredTriggers]);
  const fieldsMap = useMemo(() => {
    const list = data?.data || [];

    const fieldMap:TiersMap = {};
    list.forEach((item) => {
      fieldMap[item.name] = item.description;
    });
    return fieldMap;
  }, [data]);
  return {
    isLoading,
    fieldOptions,
    fieldsMap,
  };
};
