import { Box, Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

export const CahngesetWrapper = styled(Box)`
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const SourceItemWrapper = styled(Box)`
  padding: ${({ theme }) => theme.spacings.spacing16} ${({ theme }) => theme.spacings.spacing24};
  background: ${({ theme }) => theme.colors.canvas};
  border: ${({ theme }) => theme.border.grey6.border};
  border-radius: 12px;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.03);
  flex-grow: 1;
  width: 100%;
  flex-direction: row;
  > span:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacings.spacing4};
  }
`;

export const SourceWrapper = styled(Box)`
  > div:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacings.spacing12};
  }
`;

export const CapitalizedInscription = styled(Inscription)`
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;
