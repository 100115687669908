import { storeCreator } from '@/utils/store/store.utils';

interface State {
  alreadyUseTypes: string[];
  usedPlans?: string[];
  usedGroups?: string[];
  handleSetAlreadyUseTypes: (value: string[]) => void;
  handleSetUsedPlans: (value: string[]) => void;
  handleSetUsedGroups: (value: string[]) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  alreadyUseTypes: [],
  usedPlans: undefined,
  usedGroups: undefined,

  handleSetAlreadyUseTypes: (value) => set((state) => ({
    ...state,
    alreadyUseTypes: value,
  })),

  handleSetUsedPlans: (value) => set((state) => ({
    ...state,
    usedPlans: value,
  })),

  handleSetUsedGroups: (value) => set((state) => ({
    ...state,
    usedGroups: value,
  })),

  handleReset: () => set((state) => ({
    ...state,
    alreadyUseTypes: [],
    usedPlans: undefined,
    usedGroups: undefined,
  })),
}));