import { storeCreator } from '@/utils/store/store.utils';

import { LogoFile } from '../branding.types';

export interface LogosErrors {
  largeLightLogo: boolean;
  largeDarkLogo: boolean;
  miniLightLogo: boolean;
  miniDarkLogo: boolean;
}

export const DEFAULT_ERROR_STATE = {
  largeLightLogo: false,
  largeDarkLogo: false,
  miniLightLogo: false,
  miniDarkLogo: false,
};

interface State {
  largeLightLogo?: LogoFile;
  largeDarkLogo?: LogoFile;
  miniLightLogo?: LogoFile;
  miniDarkLogo?: LogoFile;
  sizeError: LogosErrors;
  formatError: LogosErrors;
  securityError: LogosErrors;
  setLargeLightLogo: (value: LogoFile) => void;
  setLargeDarkLogo: (value: LogoFile) => void;
  setMiniLightLogo: (value: LogoFile) => void;
  setMiniDarkLogo: (value: LogoFile) => void;
  setSizeError: (error: LogosErrors) => void;
  setFormatError: (error: LogosErrors) => void;
  setSecurityError: (error: LogosErrors) => void;
  handleResetErrors: () => void;
  handleClear: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  largeLightLogo: undefined,
  largeDarkLogo: undefined,
  miniLightLogo: undefined,
  miniDarkLogo: undefined,
  sizeError: DEFAULT_ERROR_STATE,
  formatError: DEFAULT_ERROR_STATE,
  securityError: DEFAULT_ERROR_STATE,

  setLargeLightLogo: (value: LogoFile) => set((store) => ({
    ...store,
    largeLightLogo: value,
  })),

  setLargeDarkLogo: (value: LogoFile) => set((store) => ({
    ...store,
    largeDarkLogo: value,
  })),

  setMiniLightLogo: (value: LogoFile) => set((store) => ({
    ...store,
    miniLightLogo: value,
  })),

  setMiniDarkLogo: (value: LogoFile) => set((store) => ({
    ...store,
    miniDarkLogo: value,
  })),

  setSizeError: (value: LogosErrors) => set((store) => ({
    ...store,
    sizeError: value,
  })),

  setFormatError: (value: LogosErrors) => set((store) => ({
    ...store,
    formatError: value,
  })),

  setSecurityError: (value: LogosErrors) => set((store) => ({
    ...store,
    securityError: value,
  })),

  handleClear: () => set((store) => ({
    ...store,
    largeLightLogo: undefined,
    largeDarkLogo: undefined,
    miniLightLogo: undefined,
    miniDarkLogo: undefined,
  })),

  handleResetErrors: () => set((store) => ({
    ...store,
    sizeError: DEFAULT_ERROR_STATE,
    formatError: DEFAULT_ERROR_STATE,
    securityError: DEFAULT_ERROR_STATE,
  })),
}));
