import React from 'react';
import { Box, FlexListItem, ListItem } from '@common-fe/common-fe';
import styled from 'styled-components';

import { TableRowOption } from '@/common/types';
import theme from '@/styles/theme';

export interface ExpandedListItem extends ListItem {
  subfields?: ListItem[];
}
interface Props {
  item: ExpandedListItem;
  disabled?: boolean;
  isHighlight?: boolean;
  testId?: string;
  options?: TableRowOption[];
  children?: React.ReactNode;
  isDisabled?: boolean;
  isHighlightMoveTo?: boolean;
  isMatrix?: boolean;
  isExpanded: boolean;
  disabledOptionHint?: string;
  key: string;
}

interface StyledBoxProps {
  isDisabled?: boolean;
  isHighlight?: boolean;
  isHighlightMoveTo?: boolean;
  isExpanded: boolean;
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  height: ${({ isExpanded }) => (!isExpanded ? '84px' : 'auto')};
`;

const ExpandedItem: React.FC<Props> = ({
  testId = '', item, disabled, options, children, isDisabled, isExpanded, disabledOptionHint, key,
}) => (
  <div style={{ position: 'relative' }}>
    <StyledBox
      key={key}
      direction="column"
      round="container2Round"
      background="canvas"
      elevation="default"
      border={{ side: 'all', size: 'small', color: theme.colors.border2 }}
      pad={{ horizontal: '12px' }}
      margin={{ top: 'm' }}
      isDisabled={isDisabled}
      isExpanded={isExpanded}
      justify="center"
    >
      <FlexListItem
        testId={`${testId}`}
        key={item.id}
        hasNext
        stocksMode
        rowStyle={{ background: 'none', border: 'none', height: '79px' }}
        id={item.id}
        onRemoveRow={item?.onRemoveRow}
        fields={item.fields}
        onPick={disabled ? undefined : item.onPick}
        options={options}
        disabledOptionHint={disabledOptionHint}
      />
      {children}
    </StyledBox>
  </div>
);
export default ExpandedItem;
