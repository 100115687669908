import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';

export const PLAN_TYPES_KEY = 'getAllPlanTypesList';

export interface PlanTypeResponse {
  id: number,
  type_code: string,
  description: string,
}

export const getPlanTypes = () => () => api.get<PlanTypeResponse[]>(PATHS.ACCOUNT_TYPES);

export const usePlanTypesQuery = () => {
  const { data } = useQuery(PLAN_TYPES_KEY, getPlanTypes());

  const formatedPlanTypes = useMemo<Option[]>(() => {
    const list = data?.data || [];
    const exception = [
      // 'HSA',/
      'EXTERNAL',
      'CLEARING',
      'SUB_ACCOUNT',
      'OMNIBUS',
      // 'ADOPTION',
      // 'WELLNESS',
      // 'SPECIALTY',
      'WAGEPARITY',
      'NOTIONAL_PAYROLL',
      'PLAN_OMNIBUS',
      'NOTIONAL_FUNDING',
    ];
    const cutList = list.filter((item) => !exception.includes(item.type_code));
    return cutList.map((item) => ({
      key: `${item.type_code}`,
      value: item.description,
      title: item.description,
    }));
  }, [data]);

  return formatedPlanTypes;
};

export default usePlanTypesQuery;
