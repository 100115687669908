import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Contact } from '@common-fe/common-fe';
import _ from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Pagination, PaginationParams } from '@/common/types';
import {
  ContactDto,
  CreateContactDto,
} from '@/modules/contact/types/Contact.types';

export enum ContactQueryNames {
  CONTACT_LIST = 'contactList',
}

export const useContactListQuery = (params: PaginationParams) => {
  const query = useQuery({
    queryKey: [
      ContactQueryNames.CONTACT_LIST,
      params.perPage,
      params.page,
      params.searchString,
      params.sortBy,
    ],
    queryFn: () => api.get<Pagination<ContactDto>>(
      `${PATHS.CONTACTS_LIST}?sort=firstName,${params.sortBy}&sort=lastName,${params.sortBy}`,
      {
        params: {
          search_value: params.searchString,
          page: params.page - 1,
          size: params.perPage,
        },
      },
    ),
  });

  const contactList = useMemo(() => {
    const list = (query.data?.data?.content || query.data?.data || []) as ContactDto[];
    return list.map((item: ContactDto): Contact => ({
      id: item?.id,
      contactStatusType: item?.contact_status_type,
      contactType: item?.contact_type,
      department: item?.department,
      email: item?.email,
      externalIdentifier: item?.external_identifier,
      firstName: item?.first_name,
      lastName: item?.last_name,
      phone: item?.phone || null,
      primary: item?.primary,
      role: item?.role,
      title: item?.title,
      contactName: `${item?.first_name} ${item?.last_name}`,
      userName: item?.username,
      emailCommunicationTypeList: item?.email_communication_type_list,
    }));
  }, [query]);

  const total = useMemo<number>(
    () => _.get(query.data, 'data.totalElements', 0) || 0,
    [query.data],
  );

  return {
    ...query,
    contactList: contactList || [],
    total,
  };
};

const formatContact = (contact: Partial<ContactDto>) => ({
  id: contact?.id,
  title: contact?.title,
  firstName: contact?.first_name,
  lastName: contact?.last_name,
  role: contact?.role,
});

export const useGetContactByIdQuery = (contactId: string) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [PATHS.CONTACTS, contactId],
    () => api.get(`${PATHS.CONTACTS}?ids=${contactId}`),
    {},
  );
  return ({
    contact: formatContact(data?.data[0]),
    isError,
    isLoading,
    isSuccess,
  });
};

export const useAddContactMutation = () => useMutation(
  (value: CreateContactDto) => api.post(PATHS.CONTACTS, value),
);

export const useUpdateContactMutation = (contactId?: string) => useMutation(
  (value: ContactDto) => api.put(PATHS.UPDATE_CONTACT(contactId), value),
);
