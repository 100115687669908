import React, { useMemo } from 'react';
import { capitalizeFirstLetter, costFormater,ListItem } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import {
  DEFAULT_DATE_FORMAT,
  // LARGE_SIZE_LOGO,
  // LetterIconSizeEnum,
  OrganizationTypes,
} from '@/common/constants';
import regexp from '@/common/regexp';
import ROUTES from '@/common/routes';
import { ListItemType } from '@/common/types';
// import { Icon } from '@/components/elements/Icon/Icon';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import { useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

// import { InvoiceStatus } from '../types/invoicing.constants';
import { Invoice } from '../types/invoicing.types';

const SPACE_SYMBOL = ' ';

const titleCreator = (
  value: string,
) => capitalizeFirstLetter(value).replace(regexp.DASH_SYMBOL, SPACE_SYMBOL);

interface Params {
  invoices: Invoice[],
}

const useInvoicesList = ({ invoices }: Params) => {
  const { push } = useHistory();

  // const groupedInvoices = useMemo(() => {
  //   if (!invoices) return [];

  //   const invoicesGroups: { [key: string]: Invoice[] } = {
  //     [InvoiceStatus.PAYMENT_REJECTED]: [],
  //     [InvoiceStatus.PAYMENT_REQUESTED]: [],
  //     [InvoiceStatus.PAYMENT_INITIATED]: [],
  //     [InvoiceStatus.PAID]: [],
  //     [InvoiceStatus.PAYMENT_FAILED]: [],
  //     [InvoiceStatus.REOPEN]: [],
  //   };

  //   invoices.forEach((invoice) => invoicesGroups[invoice.invoiceStatusType].push(invoice));

  //   return [
  //     ...invoicesGroups[InvoiceStatus.PAYMENT_REJECTED],
  //     ...invoicesGroups[InvoiceStatus.PAYMENT_REQUESTED],
  //     ...invoicesGroups[InvoiceStatus.PAYMENT_INITIATED],
  //     ...invoicesGroups[InvoiceStatus.PAID],
  //     ...invoicesGroups[InvoiceStatus.PAYMENT_FAILED],
  //     ...invoicesGroups[InvoiceStatus.REOPEN],
  //   ];
  // }, [invoices]);

  const formattedList = useMemo(() => invoices?.map((invoice: Invoice) => ({
    id: invoice.invoiceId,
    onPick: () => push(ROUTES.FINOPS_PREFUND_VIEW(invoice.invoiceId)),
    fields: [
      {
        key: 'invoiceOrganization',
        type: ListItemType.Node,
        title: invoice?.organizationName,
        value: invoice?.organizationName,
        organizationType: OrganizationTypes.partner,
        ellipsisMode: true,
        hover: true,
        flex: 1.5,
        logoUrl: invoice?.largeOrganizationLogo,
        isLogoInList: true,
        // logo: (
        //   <Icon
        //     type={OrganizationTypes.partner}
        //     text={invoice.organizationName}
        //     src={invoice.largeOrganizationLogo}
        //     size={LetterIconSizeEnum.LOGO}
        //     height={LARGE_SIZE_LOGO.height}
        //     width="auto"
        //   />
        // ),
        node: (
          <TextWithPointer
            weight="bold"
            // margin={{ left: 'spacing32' }}
            title={invoice.organizationName}
          >
            {invoice.organizationName}
          </TextWithPointer>
        ),
      },
      {
        key: 'invoiceNumber',
        type: ListItemType.Text,
        title: invoice?.invoiceId,
        flex: 0.7,
      },
      {
        key: 'invoiceType',
        type: ListItemType.Text,
        title: invoice?.invoiceType,
        ellipsisMode: true,
        hover: true,
        flex: 1.2,
      },
      {
        key: 'invoiceDate',
        type: ListItemType.Text,
        title: invoice?.endedAt ? dayjs(invoice.endedAt).format(DEFAULT_DATE_FORMAT) : '-',
        flex: 0.8,
      },
      {
        key: 'invoiceAmount',
        type: ListItemType.Text,
        title: costFormater(Number(invoice?.amount), true),
        flex: 0.7,
      },
      {
        key: 'invoiceStatus',
        type: ListItemType.Status,
        title: titleCreator(invoice?.invoiceStatusType || ''),
        flex: 1.2,
        style: {
          flexDirection: 'row-reverse',
          gap: theme.spacings.spacing8,
          marginRight: '20px',
        },
      },
    ],
  })), [invoices, push]);

  return formattedList as ListItem[];
};

export default useInvoicesList;
