import { toString } from 'lodash';

import { EMPTY_FIELD_VALUE, NON_ASSIGNABLE_VALUE } from '@/common/constants';
import REGEXP from '@/common/regexp';
import { unCamelCase } from '@/utils';
import { phoneNumberCreator } from '@/utils/modifiers/phoneNumberCreator';

export interface ActivityLogChangeset {
  id: string;
  fieldName: string;
  newValue: string;
  oldValue: string;
}

export interface ActivityLogChangesetPayload {
  id?: number;
  field_name?: string;
  new_value?: string;
  old_value?: string;
}

export interface Changeset {
  id?: string;
  fieldName?: string;
  changesetId?: string;
  attribute?: string;
  previous?: string;
  updated?: string;
}

export interface Exception {
  [key: string]: string;
}
const VALUE_EXCEPTIONS: Exception = {
  'externalPayrollContributionsAccount-1': NON_ASSIGNABLE_VALUE,
  'externalAdministratorFeesAccount-1': NON_ASSIGNABLE_VALUE,
  'externalReplenishmentAccount-1': NON_ASSIGNABLE_VALUE,
  EMPLOYEE: 'Employee',
  EMPLOYER: 'Employer',
  PARTNER: 'Partner',
  'Partner employee id': 'Partner employee ID',
  'Employer employee id': 'Employer employee ID',
  quarterly: 'Quarterly',
  monthly: 'Monthly',
};
const PAYMENT_METHODS_EXCEPTIONS: Exception = {
  venmo: 'Venmo',
  paypal: 'PayPal',
  debit: 'My Personal Debit Card',
  'direct deposit': 'Direct Deposit',
  check: 'Check',
};
const COMMUNICATION_CATEGORIES_EXCEPTIONS: Exception = {
  welcome: 'Welcome',
  enrollments: 'Enrollments',
  reminders: 'Reminders',
  hsa: 'HSA',
  investments: 'Investments',
  'account activity': 'Account activity',
  'profile updates': 'Profile updates',
  'enrollment updates': 'Enrollment updates',
  campaigns: 'Campaigns',
  'card status': 'Card status',
};
const COMMUNICATION_CHANNELS_EXCEPTIONS: Exception = {
  'email sms': 'Email/Text message',
  'notification center': 'Notification center',
  'data export': 'Data export',
};
const FILE_TYPES_EXCEPTIONS: Exception = {
  contribution: 'Contribution',
  carrier: 'Carrier',
  enrollment: 'Enrollment',
  census: 'Census',
};

interface ObjectAttr {
  [key: string]: boolean;
}
export const OBJECT_ATTRIBUTES: ObjectAttr = {
  communicationChannels: true,
  communicationCategories: true,
  allowedPaymentMethods: true,
  allowedFileTypes: true,
};
interface PhoneAttr {
  [key: string]: boolean;
}
const PHONE_ATTRIBUTES: PhoneAttr = {
  cardPhoneNumber: true,
};
const PHONE_NUMBER_LENGTH = 11;
const COUNTRY_PHONE_CODE = '1';
const getCleanPhone = (value: string) => {
  const onlyNumbers = value.replace(REGEXP.ALL_NON_NUMERIC, '');

  if (onlyNumbers.length === PHONE_NUMBER_LENGTH - 1
    && onlyNumbers.charAt(0) !== COUNTRY_PHONE_CODE) {
    return `${COUNTRY_PHONE_CODE}${onlyNumbers}`;
  }

  return onlyNumbers;
};
const isPhone = (value: string) => value.length === PHONE_NUMBER_LENGTH
  && value.charAt(0) === COUNTRY_PHONE_CODE;
const formatPhoneNumber = (attribute: string, value: string) => {
  if (!value) return value;
  const cleanPhone = getCleanPhone(value);
  if ((cleanPhone && PHONE_ATTRIBUTES[attribute])
    || isPhone(cleanPhone)) {
    return phoneNumberCreator(cleanPhone.slice(1));
  }
  return '';
};
enum ObjectValueAttributes {
  communicationCategories = 'communicationCategories',
  communicationChannels = 'communicationChannels',
  allowedPaymentMethods = 'allowedPaymentMethods',
  allowedFileTypes = 'allowedFileTypes',
}
const JOIN_SYMBOL = ', ';
const isJSON = (value: string) => value.startsWith('[') && value.endsWith(']');
const formatObjectValue = (attribute: string, value: string) => {
  if (!value) return value;
  const values = value.split(', ');

  if (attribute === ObjectValueAttributes.allowedPaymentMethods) {
    return values
      .map((paymentMethod) => PAYMENT_METHODS_EXCEPTIONS[paymentMethod] || paymentMethod)
      .join(JOIN_SYMBOL);
  }

  if (attribute === ObjectValueAttributes.communicationCategories) {
    return values
      .map((category) => COMMUNICATION_CATEGORIES_EXCEPTIONS[category] || category)
      .join(JOIN_SYMBOL);
  }

  if (attribute === ObjectValueAttributes.communicationChannels) {
    return values
      .map((channel) => COMMUNICATION_CHANNELS_EXCEPTIONS[channel] || channel)
      .join(JOIN_SYMBOL);
  }

  if (attribute === ObjectValueAttributes.allowedFileTypes) {
    return values
      .map((type) => FILE_TYPES_EXCEPTIONS[type] || type)
      .join(JOIN_SYMBOL);
  }

  return value;
};
const formatObjectValues = (attribute: string, value: string) => {
  if (!value) return value;
  if (OBJECT_ATTRIBUTES[attribute] && isJSON(value)) {
    return formatObjectValue(attribute, value.slice(1, value.length - 1)) || EMPTY_FIELD_VALUE;
  }
  return '';
};
export const formatValue = (
  attribute: string, value: string,
) => VALUE_EXCEPTIONS[`${attribute}${value}`]
  || VALUE_EXCEPTIONS[value]
  || formatPhoneNumber(attribute, value)
  || formatObjectValues(attribute, value)
  || value;

const EXCEPTIONS: Exception = {
  alpha1: 'Alpha 1',
  alpha2: 'Alpha 2',
  alpha3: 'Alpha 3',
  usPostal: 'US Postal',
  hsaCustodianType: 'HSA custodian type',
  line1: 'Address line 1',
  line2: 'Address line 2',
  zipcode: 'ZIP code',
  cardUrl: 'Card URL',
  binSetup: 'BIN setup',
  aiConfidenceLevel: 'AI confidence level',
  ssoOnly: 'SSO only',
  sessionTimeoutWarn: 'Timeout warning (mins)',
  sessionTimeoutLogout: 'Timeout logout (mins)',
  isAiAdjudication: 'Is AI adjudication',
  customerServiceUrlLink: 'Customer service URL link',
  customerServiceUrlName: 'Customer service URL name',
  prinSetup: 'PRIN setup',
  url: 'URL',
  taxId: 'Tax ID',
  idSourceType: 'ID source type',
  accountManagerId: 'Account manager ID',
  implementationManagerId: 'Implementation manager ID',
  invoicingContactId: 'Invoicing contact ID',
  outgoingClearingAccountId: 'Outgoing clearing account ID',
  emailThemeId: 'Email theme ID',
  emailIllustrationId: 'Email illustration ID',
  rootExternalAccountId: 'Root external account ID',
  interestClearingAccountId: 'Interest clearing account ID',
  elevateInterestSourceAccountId: 'Elevate interest source account ID',
  eeContributionsHoldPeriod: 'EE contributions hold period',
  'country.id': 'Country ID',
  'country.usPostal': 'Country US postal',
  'country.alpha1': 'Country alpha 1',
  'country.alpha2': 'Country alpha 2',
  'country.alpha3': 'Country alpha 3',
  'country.name': 'Country name',
  id: 'ID',
  personId: 'Person ID',
  organizationId: 'Organization ID',
  orgPath: 'Organization path',
  addressId: 'Address ID',
  isBillPayAllowed: 'Is Bill pay allowed',
  isCardOffered: 'Is Card offered',
  isCustomerServiceEnabled: 'Is Customer service enabled',
  isDependentCardAllowed: 'Is Dependent card allowed',
  isFileAutoPostEnabled: 'Is File auto post enabled',
  isFileManagerAccessEnabled: 'Is File manager access enabled',
  isReimbursementAllowed: 'Is Reimbursement allowed',
  cardSettlementClearingAccountId: 'Card settlement clearing account ID',
  'additionalPhone.number': 'Additional phone number',
  'additionalPhone.type': 'Additional phone type',
  'preferredPhone.number': 'Preferred phone number',
  'preferredPhone.type': 'Preferred phone type',
  allowInvestments: 'Allow investments',
  availableBalanceType: 'Available balance',
  catchUpAge: 'Catch-up age',
  catchUpElection: 'Catch-up election',
  cmsReportingRequired: 'CMS reporting required',
  fundId: 'Fund ID',
  fundIdFormula: 'Fund ID formula',
  generateStatements: 'Generate statements',
  investmentOptionsType: 'Investment options',
  isCarded: 'Card offered',
  isCipRequired: 'CIP required',
  isOrphaned: 'Is orphaned',
  maxElectionAmountType: 'Maximum election amount',
  'maxElectionAmountType.fieldState': 'Maximum election amount (state)',
  'maxElectionAmountType.type': 'Maximum election amount (type)',
  'maxElectionAmountType.value': 'Maximum election amount (value)',
  maxElectionIrsLimitType: 'Maximum election amount (IRS limit type)',
  minElectionAmountType: 'Minimum election amount',
  'minElectionAmountType.fieldState': 'Minimum election amount (state)',
  'minElectionAmountType.type': 'Minimum election amount (type)',
  'minElectionAmountType.value': 'Minimum election amount (value)',
  minimumInvestmentAmount: 'Minimum investment amount',
  pendContributionsForCip: 'Pend contributions for CIP',
  proofOfExpensesType: 'Proof of expense',
  spendLimitIrsLimitType: '',
  spendLimitType: 'Spend limit',
  'spendLimitType.fieldState': 'Spend limit (state)',
  'spendLimitType.type': 'Spend limit (type)',
  'spendLimitType.value': 'Spend limit (value)',
  spendPeriodType: 'Spend period',
  accountCreation: 'Account creation',
  allowContributionType: 'Allow payroll contribution',
  allowIndividualContributions: 'Allow individual contribution',
  contributionMonitoringRequired: 'Contribution monitoring required',
  daysAfterPlanEnd: 'Days after plan end',
  'employeeTaxConfig.isPostTax': 'Employee contributions config (Post-tax)',
  'employeeTaxConfig.isPreTax': 'Employee contributions config (Pre-tax)',
  'employeeTaxConfig.postTaxMemo': 'Employee contributions config (Post-tax memo)',
  'employeeTaxConfig.preTaxMemo': 'Employee contributions config (Pre-tax memo)',
  'employerTaxConfig.isPostTax': 'Employer contributions config (Post-tax)',
  'employerTaxConfig.isPreTax': 'Employer contributions config (Pre-tax)',
  'employerTaxConfig.postTaxMemo': 'Employer contributions config (Post-tax memo)',
  'employerTaxConfig.preTaxMemo': 'Employer contributions config (Pre-tax memo)',
  interestTemplateId: 'Interest template ID',
  isAllowContributionsOverElectionAmount: 'Allow contributions to go over maximum election amount',
  isAutoEnrollment: 'Auto enrollment',
  isCustomPrefundCalc: 'Custom prefund claculation',
  isPendContributionsOverIrs: 'Pend contributions over the IRS max',
  isPlanLevelFunding: 'Plan level funding',
  isReplenished: 'Is replenished',
  isRollover: 'Rollover',
  maxRolloverAmountType: 'Maximum rollover amount',
  'maxRolloverAmountType.fieldState': 'Maximum rollover amount (state)',
  'maxRolloverAmountType.type': 'Maximum rollover amount (type)',
  'maxRolloverAmountType.value': 'Maximum rollover amount (value)',
  maxRolloverIrsLimitType: 'Maximum rollover amount (IRS limit type)',
  minimumContributionAmount: 'Minimum contribution amount',
  minRolloverAmount: 'Minimum rollover amount',
  'minRolloverAmount.fieldState': 'Minimum rollover amount (state)',
  'minRolloverAmount.type': 'Minimum rollover amount (type)',
  'minRolloverAmount.value': 'Minimum rollover amount (value)',
  omnibusType: 'Omnibus',
  prefundReplenishmentCreepMin: 'Minimum threshold (prefund)',
  prefundReplenishmentCreepPercent: 'Election change percentage (prefund) ',
  prefundReplenishmentMinValue: 'Minimum value (prefund)',
  prefundReplenishmentPercent: 'Percent (prefund)',
  replenishmentBankAccountId: 'Bank account (replenishment)',
  replenishmentDayNumber: 'Day number (replenishment)',
  replenishmentFrequency: 'Frequency (replenishment)',
  replenishmentMethod: 'Method (replenishment)',
  rolloverClaims: 'Rollover claims',
  'rolloverClaims.fieldState': 'Rollover claims (state)',
  'rolloverClaims.type': 'Rollover claims (type)',
  'rolloverClaims.value': 'Rollover claims (value)',
  claimsDeadlineEndOfCoverageType: 'Claims deadline to submit claims based on end of coverage',
  'claimsDeadlineEndOfCoverageType.fieldState': 'Claims deadline to submit claims based on end of coverage (state)',
  'claimsDeadlineEndOfCoverageType.type': 'Claims deadline to submit claims based on end of coverage (type)',
  'claimsDeadlineEndOfCoverageType.value': 'Claims deadline to submit claims based on end of coverage (value)',
  endOfCoverageType: 'Termination (end coverage based on Employment termination)',
  'endOfCoverageType.fieldState': 'Termination (end coverage based on Employment termination) (state)',
  'endOfCoverageType.type': 'Termination (end coverage based on Employment termination) (type)',
  'endOfCoverageType.value': 'Termination (end coverage based on Employment termination) (value)',
  gracePeriodType: 'Grace period',
  'gracePeriodType.fieldState': 'Grace period (state)',
  'gracePeriodType.type': 'Grace period (type)',
  'gracePeriodType.value': 'Grace period (value)',
  isPayClaimsAbsence: 'Pay claims during leave of absence',
  isPayClaimsOutsideCoverage: 'Pay claims outside of coverage period',
  runOutType: 'Claims deadline to submit claims based on end of plan (run out)',
  'runOutType.fieldState': 'Claims deadline to submit claims based on end of plan (run out) (state)',
  'runOutType.type': 'Claims deadline to submit claims based on end of plan (run out) (type)',
  'runOutType.value': 'Claims deadline to submit claims based on end of plan (run out) (value)',
  planYearName : 'Plan year name',
  planYearValidFrom: 'Valid from (plan year)',
  planYearValidTo: 'Valid to (plan year)',
  priorPlanYear: 'Prior plan year',
  'phone.number': 'Phone number',
  'phone.type': 'Phone type',
  'employerProvidedPhone.number': 'Employer provided phone number',
  'employerProvidedPhone.type': 'Employer provided phone type',
  ssn: 'SSN',
  'provider.id': 'Provider ID',
  'provider.facilityName': 'Provider Facility Name',
  'serviceFor.dependentId': 'Service For Dependent Id',
  'serviceFor.claimActorType': 'Service For Claim Actor Type',
  'serviceFor.employeeId': 'Service For Employee Id',
  'serviceFor.employeeName': 'Service For Employee Name',
  'serviceFor.dependentName': 'Service For Dependent Name',
  'lockboxType': 'Document type',
  'lockboxStatus': 'Status',
  'liquidateAllFunds': 'Liquidate and close account',
  'checkDetails/vendorInfo.vendorName': 'Vendor',
  'checkDetails/vendorInfo.checkName': 'Vendor check name',
  'checkDetails.benefitOf': 'For benefit of',
  'checkDetails/vendorInfo.addressLine1': 'Address line 1',
  'checkDetails/vendorInfo.addressLine2': 'Address line 2',
  'checkDetails/vendorInfo.city': 'City',
  'checkDetails/vendorInfo.state': 'State',
  'checkDetails/vendorInfo.zip': 'ZIP code',
};
export const formatAttribute = (attribute: string) => {
  const apdatedAttr = EXCEPTIONS[attribute]
    || unCamelCase(attribute);
  return apdatedAttr.charAt(0).toUpperCase().concat(apdatedAttr.slice(1));
};

export const formatData = (
  data: ActivityLogChangesetPayload[],
): ActivityLogChangeset[] => data.map((item) => ({
  id: toString(item.id || ''),
  fieldName: item.field_name || '',
  newValue: item.new_value || '',
  oldValue: item.old_value || '',
}));
