import React, {
  useCallback, useMemo,
} from 'react';
import {
  AppButton,
  Box,   FlexControlledForm,
  Preloader,   Text,
  WarnModal,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { OrganizationTypes } from '@/common/constants';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { useSetupEmployerStore } from '@/modules/employer/store';
import { EmailCommunicationType } from '@/modules/employer/types/Contact.types';
import theme from '@/styles/theme';

import { StyledContent } from '../ContactsSecurity.styles';

import {
  useAddContactMutation,
  useUpdateContactMutation,
} from './queries/useContacts.queries';
import { ContactResponseModel } from './ContactSecurity.types';
import {
  useContactModalState,
  useContactSecurityForm,
  useEmailCommunicationsFields,
  useHandleErrors,
  usePermissionOptions,
} from './hooks';

export interface Props {
  onCancel(): void;
  firstContact?: boolean;
  onSubmit?: (data: ContactResponseModel) => void;
  data?: ContactResponseModel;
  openPermissionTable: () => void;
  onDirty: (isDirty: boolean) => void;
  toEdit?: () => void;
  viewMode?: boolean;
}
const ERROR_MODAL_TITLE = 'The server encountered an error processing the request';
const ERROR_MODAL_TEXT = `It doesn't appear to have affected your data, but we cannot save the Employer with the latest records.
                    Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`;
const SERVER_ERROR_CODE = 400;
const AddEditContactsSecurityForm: React.FC<Props> = (props) => {
  const { contactList } = useSetupEmployerStore();
  const {
    values, setValues, showErrors, setShowErrors,
    emailCommunicationsValues, setEmailCommunicationsValues,
    handleChangeAllEmailCommunications,
  } = useContactModalState();
  const { employerID } = useSetupEmployerMode();
  const {
    handleSetErrors,
    errors,
    updateErrorModal,
    setUpdateErrorModal,
    seCreateErrorModal,
    createErrorModal,
  } = useHandleErrors();
  const updateMutation = useUpdateContactMutation(_.toString(props.data?.id));
  const addContactMutation = useAddContactMutation();
  const permissionsList = usePermissionOptions(false);
  const onlyOnePrimaryContact = useMemo(() => Boolean(
    contactList.filter((el) => el.primary).length === 1,
  ), [contactList]);

  const hasPrimary = useMemo(
    () => contactList.some((el) => el.primary), [contactList],
  );

  const fields = useContactSecurityForm({
    data: props.data || {},
    firstContact: props.firstContact,
    hasPrimary,
    onlyOnePrimaryContact,
    onOpenPermissionTable: props.openPermissionTable,
    viewMode: props.viewMode,
    errors,
  }, permissionsList);

  const { fields: emailCommunicationsFields } = useEmailCommunicationsFields({
    contact: props.data,
    values: emailCommunicationsValues,
    isEditMode: !props?.viewMode,
  });

  const emailCommunicationsValuesArray = useMemo(() => ([
    ...emailCommunicationsValues?.files ? [EmailCommunicationType.files] : [],
    ...emailCommunicationsValues?.finance ? [EmailCommunicationType.finance] : [],
    ...emailCommunicationsValues?.planManagement ? [EmailCommunicationType.planManagement] : [],
  ]), [emailCommunicationsValues]);

  const isAllEmailCommunicationsSelected = useMemo(
    () => emailCommunicationsValuesArray.length === 3,
    [emailCommunicationsValuesArray],
  );

  const handleCreateContact = useCallback(() => {
    if (!values) {
      return;
    }
    setShowErrors(true);
    addContactMutation.mutate({
      organization_id: _.toNumber(employerID),
      username: values.userName,
      // password: 'gdk;sfdsk;242njfdssdfsd31j9!$klkfe;AAs',
      // role: contact.permissions,
      role: values.permissions,
      title: values.title || null,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      // username: contact.userName,
      ...(values.phoneNumber ? {
        phone: values.phoneNumber,
      } : {}),
      department: values.department || null,
      primary: Boolean(values.primary),
      external_identifier: values.externalIdentifier || null,
      contact_type: OrganizationTypes.employer,
      contact_status_type: 'ACTIVE',
      email_communication_type_list: emailCommunicationsValuesArray,
    }, {
      onSuccess: (resp) => {
        if (props.onSubmit) props.onSubmit(resp.data);
      },
      onError: (error) => {
        const code = _.get(error, 'response.status', 0) as number;
        handleSetErrors(error as object, values);
        if (code !== SERVER_ERROR_CODE) {
          seCreateErrorModal(true);
        }
      },
    });
  }, [values, setShowErrors, addContactMutation, props,
    handleSetErrors, seCreateErrorModal,
    emailCommunicationsValuesArray, employerID]);

  const handleUpdateContact = useCallback(() => {
    if (!props.data?.id || !values) return;
    setShowErrors(true);
    updateMutation.mutate({
      id: props.data?.id,
      organization_id: _.toNumber(employerID),
      title: values.title || null,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phoneNumber || null,
      department: values.department || null,
      primary: Boolean(values.primary),
      external_identifier: values.externalIdentifier || null,
      contact_type: OrganizationTypes.employer,
      contact_status_type: 'ACTIVE',
      username: values.userName,
      // role: contact.permissions,
      role: values.permissions,
      email_communication_type_list: emailCommunicationsValuesArray,
    }, {
      onSuccess: (resp) => {
        if (props.onSubmit) props.onSubmit(resp.data);
      },
      onError: (error) => {
     
        const code = _.get(error, 'response.status', 0) as number;

        handleSetErrors(error as object, values);
        if (code !== SERVER_ERROR_CODE) {
          setUpdateErrorModal(true);
        }
      },
    });
  }, [handleSetErrors, props, setShowErrors,
    setUpdateErrorModal, updateMutation, values,
    emailCommunicationsValuesArray, employerID]);

  return (
    <>
      <Box background="module" pad="medium" round="moduleRound" data-testid="ContactsSecurityModal-form-wrapper">

        <WarnModal
          visible={createErrorModal}
          onSetVisible={seCreateErrorModal}
          header={ERROR_MODAL_TITLE}
          helptext={ERROR_MODAL_TEXT}
          buttonText="Close"
          onSubmit={handleCreateContact}
          submitButtonText="Try again"
        />
        <WarnModal
          visible={updateErrorModal}
          onSetVisible={setUpdateErrorModal}
          header={ERROR_MODAL_TITLE}
          helptext={ERROR_MODAL_TEXT}
          buttonText="Close"
          onSubmit={handleUpdateContact}
          submitButtonText="Try again"
        />
        <StyledContent
          background="canvas"
        >
          <FlexControlledForm
            fields={fields}
            showError={showErrors}
            editMode={!props.viewMode}
            onDirty={props.onDirty}
            onChangeValues={setValues}
            isThinMode
          />
          {/* <FlexForm
            fields={fields}
            editMode
            onSubmit={props.data ? handleUpdateContact : handleCreateContact}
          /> */}
        </StyledContent>
        <StyledContent
          background="canvas"
          margin={{ top: 'spacing16' }}
        >
          <FlexControlledForm
            formTitle="Email Communications"
            fields={emailCommunicationsFields}
            showError={showErrors}
            editMode={!props.viewMode}
            onDirty={props.onDirty}
            onChangeValues={setEmailCommunicationsValues}
            shouldControlValues
            subTitle={!props.viewMode && (
              <Box margin={{ top: 'spacing24', bottom: 'spacing16' }}>
                <Box onClick={
                  () => handleChangeAllEmailCommunications(!isAllEmailCommunicationsSelected)
                }
                >
                  <Text color="textAccent">
                    {isAllEmailCommunicationsSelected ? 'Opt-out all' : 'Opt-in all'}
                  </Text>
                </Box>
              </Box>
            )}
            isThinMode
          />
        </StyledContent>
      </Box>
      <div>
        <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }}>
          {props.data && !props.viewMode
            ? (
              <>
                <AppButton
                  testId="ContactsSecurityModal-cancel-edit-mode"
                  buttonType="secondary"
                  onClick={props.onCancel}
                  containerStyle={{ marginRight: theme.spacings.spacing12 }}
                >
                  Cancel
                </AppButton>

                <AppButton
                  testId="ContactsSecurityModal-save-edit-mode"
                  onClick={handleUpdateContact}
                  disabled={updateMutation.isLoading}
                  type="submit"
                >
                  {addContactMutation.isLoading ? <Preloader color="white" />
                    : 'Save Edits'}
                </AppButton>
              </>
            )
            : props.data && (
              <AppButton
                testId="ContactsSecurityModal-to-edit"
                onClick={props.toEdit}
                type="submit"
              >
                Edit
              </AppButton>
            )}

          {
            !props.data
            && (
              <>
                <AppButton
                  testId="ContactsSecurityModal-cancel-create-mode"
                  buttonType="secondary"
                  onClick={props.onCancel}
                  containerStyle={{ marginRight: theme.spacings.spacing12 }}
                >
                  Cancel
                </AppButton>

                <AppButton
                  testId="ContactsSecurityModal-save-create-mode"
                  onClick={handleCreateContact}
                  disabled={addContactMutation.isLoading}
                  type="submit"
                >
                  {addContactMutation.isLoading ? <Preloader color="white" />
                    : 'Save Contact'}
                </AppButton>
              </>
            )
          }
        </Box>
      </div>

    </>
  );
};

AddEditContactsSecurityForm.defaultProps = {
  firstContact: true,
};

export default AddEditContactsSecurityForm;
