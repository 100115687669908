import {
  useMemo,
} from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { Option } from '@/common/types';
import { useTriggersQuery } from '@/modules/ProcessingDefinition/ProcessingRule/queries';

import { TierField } from './useTiers';

export const NA_KEY = 'null';
const LAST_TRIGGER: Option = {
  key: NA_KEY,
  value: 'N/A',
  divide: true,
};
export const TRIGGER_KEY = 'triggers';

export default (
  defaultValues?: Partial<TierField>,
) => {
  const { fieldOptions } = useTriggersQuery(true);
  const formattedTriggers = useMemo(() => [LAST_TRIGGER, ...fieldOptions], [fieldOptions]);
  const fields = useMemo<Field[]>(() => [
    {
      name: 'name',
      type: FieldTypes.Text,
      label: 'Name',
      placeholder: 'Enter name',
      value: defaultValues?.name || '',
    },
    {
      name: 'payoutDefinition',
      type: FieldTypes.Text,
      label: 'Payout definition',
      placeholder: 'Select payout definition',
      value: defaultValues?.payoutDefinitionName || '',
    }, {
      name: TRIGGER_KEY,
      type: FieldTypes.MultipleDropdown,
      label: 'Trigger',
      singleMode: true,
      options: formattedTriggers,
      placeholder: 'Select trigger',
      defaultValue: defaultValues?.triggers?.length ? defaultValues?.triggers : [NA_KEY],

    },
    ...defaultValues?.isSendTriggerNotification ? [{
      name: 'isSendTriggerNotification',
      type: FieldTypes.Text,
      label: 'Trigger Notification',
      value: 'Send notifications',

    },
    ] as Field[] : [],
    ...defaultValues?.displayName ? [{
      name: 'displayName',
      type: FieldTypes.Text,
      label: 'Display name',
      value: defaultValues?.displayName || '',
    },
    ] as Field[] : [],
    ...defaultValues?.planName ? [{
      name: 'planName',
      type: FieldTypes.Text,
      label: 'Plan',
      value: defaultValues?.planName || '',
    }] as Field[] : [],
  ], [defaultValues, formattedTriggers]);
  return fields;
};
