import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import { useFieldsWithDefaultValues } from '@/utils';

import { usePayClaimsStore } from '../stores';

export default (
  disabled?: boolean,
  isTemplateMode?: boolean,
  isEditMode?: boolean,
) => {
  // const errors = useGracePeriodStore((state) => state.errors);
  const sourceState = usePayClaimsStore((state) => state.sourceState);
  const currentState = usePayClaimsStore((state) => state.state);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);
  const fields = useMemo<Field[]>(() => [
    // {
    //   name: 'isPayClaimsAbsence',
    //   type: FieldTypes.Radio,
    //   label: 'Pay claims during leave of absence ',
    //   options: [
    //     {
    //       label: 'Yes',
    //       value: 'yes',
    //     },
    //     {
    //       label: 'No',
    //       value: 'no',
    //     },
    //   ],
    //   validator: yup.string().nullable(),
    // },
    {
      name: 'isPayClaimsOutsideCoverage',
      type: FieldTypes.Radio,
      label: 'Pay claims outside of coverage period',
      options: [
        {
          label: 'Yes',
          value: 'yes',
        },
        {
          label: 'No',
          value: 'no',
        },
      ],
      validator: yup.string().nullable(),
    },

  ], []);
  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);
  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
