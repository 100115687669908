 
import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

const usePayoutDefinitionNameFields = (
  name?: string,
) => {
  const fields: Field[] = useMemo(() => [
    {
      name: 'payoutDefinitionName',
      type: FieldTypes.Text,
      label: 'Payout definition name',
      validator: yup.string().trim(),
      showRequireIcon: true,
      defaultValue: name,
    }], [name]);

  return fields;
};

export default usePayoutDefinitionNameFields;
