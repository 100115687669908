import { useMemo } from 'react';
import { useQueries } from 'react-query';
import { isNumber } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import { MAX_ALLOWED_SIZE } from '../../Contributions/ContributionModals/MakeContributionModalForm';
import useRecipientsStore from '../stores/useRecipients.store';
import { LimitsIRS, TaxYearsLimitsIRS } from '../types';

interface PlanIdLimitAmountPayload {
  [planId: string]: number; 
}
interface LimitPayload {
  employee_id: number;
  plan_id_to_contribution_amount: PlanIdLimitAmountPayload;
}

const DEFAULT_PAGE = 0;
const TAX_YEAR_PARAM_KEY = 'tax_year';

interface Props {
  employeeIds: string[];
  taxYears: string[];
  planIds: string[];
  size?: number;
  page?: number;
}

const formatData = (data?: LimitPayload[]) => {
  const limits: LimitsIRS = {};
  data?.forEach((employeeLimit) => {
    if (isNumber(employeeLimit.employee_id)
      && Object.keys(employeeLimit.plan_id_to_contribution_amount || {}).length) {
      limits[employeeLimit.employee_id] = employeeLimit.plan_id_to_contribution_amount;
    }
  });
  return limits;
};

const useGetLimitsQuery = (props: Props) => {
  const { setLimits } = useRecipientsStore();
  const { employeeIds, taxYears, planIds } = props;
  const results = useQueries(taxYears.map((taxYear) => ({
    queryKey: [PATHS.GET_IRS_LIMITS, employeeIds, planIds, taxYear],
    queryFn: () => api.get<LimitPayload[]>(PATHS.GET_IRS_LIMITS, {
      params: {
        [TAX_YEAR_PARAM_KEY]: taxYear,
        employee_ids: employeeIds?.join(','),
        plan_ids: planIds?.join(','),
        size: props?.size || MAX_ALLOWED_SIZE,
        page: props?.page || DEFAULT_PAGE,
      },
    }),
    enabled: Boolean(planIds?.length && employeeIds?.length && taxYears?.length),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      const limits: TaxYearsLimitsIRS = {
        [data?.config?.params[TAX_YEAR_PARAM_KEY]]: formatData(data?.data),
      };
      setLimits(limits);
    },
  })));

  const areLimitsLoading = useMemo(() => results.some((result) => result?.isLoading), [results]);

  return {
    areLimitsLoading,
  };
};

export default useGetLimitsQuery;
