import React from 'react';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks';
import { PlansList } from '@/modules/plan';

const PlansPage = () => {
  useBreadcrumbs([{
    route: ROUTES.PLANS,
    title: 'Plans',
  }]);

  return (
    <Box width="100%">
      <PlansList />
    </Box>
  );
};

export default PlansPage;
