import React, { useCallback, useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access, Topbar } from '@/modules/core/components';
import { useBreadcrumbs, useCurrentOrganization, useHistory } from '@/modules/core/hooks';
import ProcessingDefinition from '@/modules/ProcessingDefinition';
import theme from '@/styles/theme';

const ProcessingDefinitionPage = () => {
  const {
    observingOrganization: {
      type,
    },
  } = useCurrentOrganization();

  const isEmployer = useMemo(
    () => type === OrganizationTypes.employer || type === OrganizationTypes.company,
    [type],
  );
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  useBreadcrumbs([{
    route: ROUTES.PROCESSING_DEFINITION,
    title: 'Processing Definition',
  }]);
  if (!type) {
    return null;
  }
  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_PROCESSING_DEFINITION },
      ]}
      accessDeniedAction={goHome}
    >
      <Box
        width="100%"
        margin={{ top: '0px' }}
      >
        <Box>

          <Topbar isSticky />

          <Box align="center">
            <Box
              width={theme.defaultContentWidth}
            >

              <ProcessingDefinition isEmployer={isEmployer} />
            </Box>
          </Box>

        </Box>
      </Box>
    </Access>
  );
};
export default ProcessingDefinitionPage;
