import { useEffect,useMemo, useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { Option, OptionKey,PaginationParams } from '@/common/types';

const SORT_OPTIONS: Option[] = [
  {
    key: 'ASC',
    value: 'A-Z',
  },
  {
    key: 'DESC',
    value: 'Z-A',
  },

];

export default () => {
  const [sortParams, setSortParams] = useState<OptionKey | null>(SORT_OPTIONS[0].key);
  const {
    page, setPage, setPerPage, perPage, searchString, setSearchString, currentSearch,
  } = usePaginationParams(10);
  const params = useMemo<PaginationParams>(() => ({
    searchString: currentSearch,
    page: currentSearch ? 0 : page,
    perPage,
    sort: sortParams ? `name,${sortParams}` : '',
  }), [currentSearch, page, perPage, sortParams]);

  useEffect(() => {
    setPage(1);
  }, [sortParams, setPage, currentSearch]);

  return {
    page,
    setPage,
    setPerPage,
    perPage,
    setSearchString,
    searchString,
    currentSearch,
    sortOptions: SORT_OPTIONS,
    params,
    setSortParams,
    sortParams,

  };
};
