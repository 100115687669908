import { useCallback, useState } from 'react';
import { Field } from '@common-fe/common-fe';
import _ from 'lodash';

import useValidationUtils from '@/utils/hooks/useValidationUtils';

interface Params<T> {
  fields: Field[];
  state: Partial<T> | null;
  onSuccess: () => void;
}
const useValidatePayrollCalendarForm =  <T>({ fields, state, onSuccess }: Params<T>) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { getValidateFields, getErrors } = useValidationUtils();
  const validations = getValidateFields(fields);
  const handleValidate = useCallback(() => {
    setSubmitted(true);
    const isError = !_.isEmpty(getErrors(state, validations));
    
    if(isError) {
      return;
    }
    onSuccess();
  }, [getErrors, onSuccess, state, validations]);


  return {
    handleValidate,
    isSubmitted,
    setSubmitted,
  };
};

export default useValidatePayrollCalendarForm;