import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { EnrollmentMatrixResponse } from '../../ProcessingDefinition.types';
import { useEnrollmentMatrixStore } from '../stores/useEnrollmentMatrix.store';

interface EnrollmentMatrixPayload {
  name?: string;
  priority?: number;
  valid_to?: string | null;
}

const getActivePageNumber = (
  elementIndex: number,
  pageSize: number,
) => Math.ceil(elementIndex / pageSize);

export const useUpdateEnrollmentMatrixQuery = (
  id?: string,
  perPage?: number,
  notHighlight?: boolean,
) => {
  const {
    setNewAddedEnrollmentMatrixId,
    setActivePage,
  } = useEnrollmentMatrixStore();
  const { mutateAsync } = useMutation(
    (payload: EnrollmentMatrixPayload) => api.patch<EnrollmentMatrixResponse>(
      `${PATHS.ENROLLMENT_MATRIXES}/${id}`,
      payload,
    ),
    {
      onSuccess: (data) => {
        if (
          (data.data.id || data.data.id === 0)
          && !notHighlight) setNewAddedEnrollmentMatrixId(`${data.data.id}`);
        if (
          data.data.priority
          && perPage
        ) setActivePage(getActivePageNumber(data.data.priority, perPage));
      },
    },
  );
  return mutateAsync;
};

export default useUpdateEnrollmentMatrixQuery;
