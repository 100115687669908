import React, { useMemo } from 'react';
import { Box, formatSnakeCaseToGeneral } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DATE_MASK_TIME_WITHOUT_YEAR_WITH_AT } from '@/common/constants';
import InfoTile from '@/components/elements/InfoTile/InfoTile';
import { ActivityLog } from '@/modules/ActivityLog/LogList/useGetActivityLogs.query';

interface LogProps {
  log?: ActivityLog;
  children?: React.ReactNode;
}

const Log: React.FC<LogProps> = ({ log, children }) => {
  const descriptions = useMemo(
    () => [dayjs(log?.changedAt).format(DATE_MASK_TIME_WITHOUT_YEAR_WITH_AT)],
    [log]
  );

  return (
    <Box key={log?.id} border={{ side: 'top', color: 'border1', size: '1px' }}>
      <Box pad={{ vertical: 'spacing8' }}>
        <InfoTile
          id={log?.id}
          title={log?.changedByName}
          infoTitle={formatSnakeCaseToGeneral(log?.actorType || '')}
          descriptions={descriptions}
        />
      </Box>
      {children}
    </Box>
  );
};

export default Log;
