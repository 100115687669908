import React from 'react';
import { Box, Field, FlexControlledForm } from '@common-fe/common-fe';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeValues?: (values: any,) => void;
  fields: Field[];
  isSubmit?: boolean;
}

export const PayrollCalendarParamsForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  fields,
}) => {

  return (
    <Box data-testid="PayrollCalendarParamsForm_container" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={fields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeValues}
        isModalType
      />
    </Box>
  );
};
