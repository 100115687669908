import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

interface Props {
  toggleOptions: string[];
  selectedOption: string;
  setSelectedOption: (value: string) => void;
}

export const EnrollmentDetailsToggler: React.FC<Props> = ({
  toggleOptions,
  selectedOption,
  setSelectedOption,
}) => {
  const isSelectedOption = (option: string) => option === selectedOption;
  return (
    <Box
      data-testid="MakeContributionModalToggler_container"
      round="container1Round"
      pad="spacing4"
      background="module"
      direction="row"
      margin={{ bottom: 'spacing24' }}
    >
      {toggleOptions.map((option) => (
        <Box
          data-testid="MakeContributionModalToggler_item"
          key={option}
          width={{ min: '214px', max: 'fit-content' }}
          height={{ min: '40px', max: 'fit-content' }}
          align="center"
          justify="center"
          background={{ color: isSelectedOption(option) ? 'canvas' : 'transparent' }}
          border={{ color: isSelectedOption(option) ? 'border2' : 'transparent' }}
          round="container2Round"
          onClick={() => setSelectedOption(option)}
        >
          <Text
            weight={isSelectedOption(option) ? 700 : 400}
            color={isSelectedOption(option) ? 'textBody' : 'textAccent'}
          >
            {option}
          </Text>
        </Box>
      ))}
    </Box>
  );
};
