import React from 'react';
import {
  Box, PlaceholderIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { StyledHeader } from './BlockWrapper.styles';

const StyledPlaceholderIcon = styled(PlaceholderIcon)`
  fill: ${({ theme }) => theme.colors.button};
  margin: 0px ${({ theme }) => theme.spacings.spacing4};
`;

export interface Props {
  title?: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  moduleAnchorId?: string;
  image?: boolean;
}

const FlatBlockWrapper: React.FC<Props> = ({
  title, children, moduleAnchorId, image, icon,
}) => (
  <div id={moduleAnchorId}>
    <Box direction="column">

      <Box direction="row" align="center" margin={{ bottom: 'spacing16' }}>
        {image && <StyledPlaceholderIcon size="26px" />}
        <Box flex={{ grow: 1 }} direction="row" align="center">
          <Box>{icon}</Box>
          <StyledHeader color="textTitle" fill level={4} size="small" margin={{ left: 'spacing16' }}>{title}</StyledHeader>
        </Box>
      </Box>
      <Box background="module" pad="spacing24" round="moduleRound">
        <Box round="container1Round">
          {children}
        </Box>
      </Box>
    </Box>
  </div>
);

export default FlatBlockWrapper;
