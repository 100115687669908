import { capitalize } from 'lodash';

import { TaxType } from './contributions.types';

const formatTaxType = (taxType?: TaxType, isReversed?: boolean) => {
  if (isReversed) return taxType?.toUpperCase().replace('-', '_');
  return capitalize(taxType?.replace('_', '-'));
};

export default formatTaxType;
