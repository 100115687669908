import { useMemo } from 'react';

import { useGetParticipantAccountActivityReport } from '@/modules/employer/components/Reports/queries/useGetParticipantAccountActivityReport.query';

const useEnrollmentsReports = () => {
  const {
    formattedAccountActivityReport,
    isLoading,
    isSubLoading,
    isEmpty,
  } = useGetParticipantAccountActivityReport();

  const reports = useMemo(() => ([{
    ...formattedAccountActivityReport,
    isWithFilter: true,
    showHideButtonCenter: true,
    isLoading,
    isSubLoading,
    isEmpty,
    isFullWidth: true,
  }]),
  [
    formattedAccountActivityReport,
    isLoading,
    isSubLoading,
    isEmpty,
  ]);

  return reports;
};

export default useEnrollmentsReports;
