import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PaginationParams } from '@/common/types';

import { ClaimsTeam, ClaimsTeamPayload, formatTeamPayload } from './ClaimsProcessors.types';

const formatData = (data: ClaimsTeamPayload[]): ClaimsTeam[] => data.map(formatTeamPayload);

interface Props extends PaginationParams {
  enabled?: boolean;
  contactId?: string;
}

const useTeamsListQuery = ({ sortBy, page, perPage, enabled = true, searchString, contactId }: Props) => {
  const { isLoading, data, refetch } = useQuery([
    PATHS.CLAIMS_TEAMS,
    searchString,
    page,
    perPage,
    enabled,
    sortBy,
    contactId,
  ], () => enabled ? api.get(PATHS.CLAIMS_TEAMS, {
    params: {
      page,
      size: perPage,
      ...searchString ? { claims_team_name: searchString } : {},
      ...sortBy ? {
        sort: `name,${sortBy}`,
      } : {},
      ...contactId ? { contact_id: contactId } : {},
    },
  }) : null);

  const total = useMemo<number>(() => data?.data?.totalElements || 0, [data]);
  const teams = useMemo(() => {
    if (!data?.data?.content) return undefined;

    return formatData(data?.data?.content || []);
  }, [data]);

  return ({
    teams,
    total,
    isLoading,
    refetch,
  });
};

export default useTeamsListQuery;
