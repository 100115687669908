import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { PermissionKey } from '@/common/constants';
import { ClaimsSettings } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';

export const DEFAULT_VALUES = {
  claimsAssignmentNumber: 10,
};
const MAX_CLAIMS_ASSIGNMENT_NUMBER = 100;
const VALIDATOR_MESSAGE = `Claims assignment number should be from 1 to ${MAX_CLAIMS_ASSIGNMENT_NUMBER}`;
const VALIDATOR = yup.string().test({
  test: (val) => {
    if (!val) return true;
    if (_.toNumber(val) <= 0 || _.toNumber(val) > MAX_CLAIMS_ASSIGNMENT_NUMBER) return false;
    return true;
  },
  message: VALIDATOR_MESSAGE,
});

const useClaimsSettingsForm = (currentValues: ClaimsSettings) => {
  const claimsAssignmentNumberPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.CLAIMS_ASSIGNMENT_NUMBER,
  );

  const { formattedData, isLoading } = useGetEmployer();

  const fields = useMemo(() => {
    if (isLoading) return [];

    return [
      {
        name: 'claimsAssignmentNumber',
        type: FieldTypes.Number,
        fill: true,
        label: 'Claims assignment number',
        value: currentValues?.claimsAssignmentNumber,
        defaultValue: formattedData?.overridableProperties?.claimsAssignmentNumber || DEFAULT_VALUES.claimsAssignmentNumber,
        validator: VALIDATOR,
        ...claimsAssignmentNumberPermProps,
      },
    ] as Field[];
  }, [
    formattedData,
    currentValues,
    isLoading,
    claimsAssignmentNumberPermProps,
  ]);

  return fields;
};

export default useClaimsSettingsForm;
