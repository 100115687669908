import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import { AccountFundingParamsPayload,useStore as useAccountFundingParamsStore } from '../AccountFundingParams/stores/useAccountFundingParams.store';
import { ContributionPayload,useStore as useContributionStore } from '../Contribution/stores/useContribution.store';
import { InterestAccrualsPayload,useStore as useInterestAccrualsStore } from '../InterestAccruals/stores/useInterestAccruals.store';
import { RolloverPayload,useStore as useRolloverStore } from '../Rollover/stores/useRollover.store';

export type AccountFundingPayload = RolloverPayload
& AccountFundingParamsPayload & ContributionPayload & InterestAccrualsPayload;

export default () => {
  const handleSetRolloverStore = useRolloverStore((state) => state.setState);
  const handleSetSourceRolloverStore = useRolloverStore((state) => state.setSourceState);
  const handleShowErrorsRolloverStore = useRolloverStore((state) => state.setShowErrors);
  const handleResetRolloverStore = useRolloverStore((state) => state.handleReset);
  const handleSetRolloverErrors = useRolloverStore((state) => state.setErrors);
  const handleSetRolloverErrorFields = useRolloverStore((state) => state.setErrorFields);

  const handleSetAccountFundingParamsStore = useAccountFundingParamsStore(
    (state) => state.setState,
  );
  const handleSetSourceAccountFundingParamsStore = useAccountFundingParamsStore(
    (state) => state.setSourceState,
  );
  const handleShowErrorsAccountFundingParamsStore = useAccountFundingParamsStore(
    (state) => state.setShowErrors,
  );
  const handleResetAccountFundingParamsStore = useAccountFundingParamsStore(
    (state) => state.handleReset,
  );
  const handleSetAccountFundingParamsErrors = useAccountFundingParamsStore(
    (state) => state.setErrors,
  );
  const handleSetAccountFundingParamsErrorFields = useAccountFundingParamsStore(
    (state) => state.setErrorFields,
  );

  const handleSetContributionStore = useContributionStore((state) => state.setState);
  const handleSetSourceContributionStore = useContributionStore((state) => state.setSourceState);
  const handleShowErrorsContributionStore = useContributionStore((state) => state.setShowErrors);
  const handleResetContributionStore = useContributionStore((state) => state.handleReset);
  const handleSetContributionErrors = useContributionStore((state) => state.setErrors);
  const handleSetContributionErrorFields = useContributionStore((state) => state.setErrorFields);

  const handleSetInterestAccrualsStore = useInterestAccrualsStore((state) => state.setState);
  const handleSetSourceInterestAccrualsStore = useInterestAccrualsStore(
    (state) => state.setSourceState,
  );
  const handleShowErrorsInterestAccrualsStore = useInterestAccrualsStore(
    (state) => state.setShowErrors,
  );
  const handleResetInterestAccrualsStore = useInterestAccrualsStore((state) => state.handleReset);
  const handleSetInterestAccrualsErrors = useInterestAccrualsStore((state) => state.setErrors);
  const handleSetInterestAccrualsErrorFields = useInterestAccrualsStore(
    (state) => state.setErrorFields,
  );
  const handleSetSourceState = useCallback((values: Partial<AccountFundingPayload>) => {
    handleSetAccountFundingParamsStore(values);
    handleSetSourceAccountFundingParamsStore(values);
    handleSetSourceContributionStore(values);
    handleSetSourceInterestAccrualsStore(values);
  }, [handleSetAccountFundingParamsStore,
    handleSetSourceAccountFundingParamsStore,
    handleSetSourceContributionStore,
    handleSetSourceInterestAccrualsStore]);

  const handleSetState = useCallback((values: Partial<AccountFundingPayload>) => {
    handleSetRolloverStore(values);
    handleSetSourceRolloverStore(values);
    handleSetContributionStore(values);
    handleSetInterestAccrualsStore(values);

    handleSetSourceState(values);
  }, [handleSetContributionStore,
    handleSetInterestAccrualsStore,
    handleSetRolloverStore,
    handleSetSourceRolloverStore,
    handleSetSourceState,
  ]);

  const handleReset = useCallback(() => {
    handleResetRolloverStore();
    handleResetAccountFundingParamsStore();
    handleResetContributionStore();
    handleResetInterestAccrualsStore();
  }, [handleResetAccountFundingParamsStore,
    handleResetInterestAccrualsStore,
    handleResetContributionStore, handleResetRolloverStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    handleShowErrorsRolloverStore(value);
    handleShowErrorsAccountFundingParamsStore(value);
    handleShowErrorsContributionStore(value);
    handleShowErrorsInterestAccrualsStore(value);
  }, [handleShowErrorsAccountFundingParamsStore, handleShowErrorsContributionStore,
    handleShowErrorsInterestAccrualsStore, handleShowErrorsRolloverStore]);
  const handleSetErrors = useCallback((values: Partial<AccountFundingPayload>) => {
    handleSetRolloverErrors(values);
    handleSetAccountFundingParamsErrors(values);
    handleSetContributionErrors(values);
    handleSetInterestAccrualsErrors(values);
  }, [handleSetAccountFundingParamsErrors, handleSetContributionErrors,
    handleSetInterestAccrualsErrors, handleSetRolloverErrors]);
  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<AccountFundingPayload>>) => {
      handleSetRolloverErrorFields(values);
      handleSetAccountFundingParamsErrorFields(values);
      handleSetContributionErrorFields(values);
      handleSetInterestAccrualsErrorFields(values);
    }, [handleSetAccountFundingParamsErrorFields,
      handleSetInterestAccrualsErrorFields,
      handleSetContributionErrorFields,
      handleSetRolloverErrorFields],
  );
  return {
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
  };
};
