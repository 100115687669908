import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

import { Accordion } from '@/components/elements/Accordion';
import useModalType from '@/modules/plan/PlanSetup/FinalSetup/stores/useModalType.store';

import { PlanStatuses } from '../../plan.types';
import PlanUpdateModal from '../PlanUpdateModal';

import PlanTemplateActivation from './PlanTemplateActivation';
import PlanTopbarActions from './PlanTopbarActions';
import PlanTopbarEditModeButton from './PlanTopbarEditModeButton';
import TopbarWrapper from './TopbarWrapper';

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 12;
`;

interface Props {
  planStatus?: PlanStatuses;
  planName?: string;
  viewMode?: boolean;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  activateButtonRef?: React.RefObject<HTMLButtonElement | undefined>;
  reviewButtonRef?:React.RefObject<HTMLButtonElement | undefined>;
}
const PlanTopbar: React.FC<Props> = ({
  planStatus, viewMode, isTemplateMode, saveButtonRef,
  reviewButtonRef, activateButtonRef, planName, isPlanActive,
}) => {
  const [isVisibleUpdatePlanPopup, setVisibleUpdatePlanPopup] = useState(false);
  const handleToggleUpdatePlanPopup = useCallback(() => {
    setVisibleUpdatePlanPopup(!isVisibleUpdatePlanPopup);
  }, [isVisibleUpdatePlanPopup]);
  
  const handleActive = useCallback(() => {
    if (activateButtonRef?.current) {
      activateButtonRef?.current.click();
    }
  }, [activateButtonRef]);

  const {
    saveErrorModal,
    activateModal,
    activateErrorModal,
  } = useModalType();

  useEffect(() => {
    if ((saveErrorModal || activateModal || activateErrorModal) && setVisibleUpdatePlanPopup) {
      setVisibleUpdatePlanPopup(false);
    }
  }, [saveErrorModal, activateModal, activateErrorModal, setVisibleUpdatePlanPopup]);
  
  const node = useMemo(() => {
    if (isTemplateMode) {
      if (planStatus !== PlanStatuses.ACTIVE && planStatus !== PlanStatuses.PENDING_ACTIVE) {
        if (viewMode) {
          return (
            <PlanTopbarEditModeButton isDraftMode={planStatus === PlanStatuses.DRAFT} />
          );
        }
  
        return (
          <PlanTopbarActions
            saveButtonRef={saveButtonRef}
            activateButtonRef={activateButtonRef}
            reviewButtonRef={reviewButtonRef}
            viewMode={viewMode}
            isTemplateMode={isTemplateMode}
          />
        );
      }
      return (<PlanTemplateActivation />);
    }

    if (viewMode) {
      return (
        <PlanTopbarEditModeButton isDraftMode={planStatus === PlanStatuses.DRAFT} isPlanActive={isPlanActive} />
      );
    }
    return (
      <PlanTopbarActions
        saveButtonRef={saveButtonRef}
        activateButtonRef={activateButtonRef}
        reviewButtonRef={reviewButtonRef}
        viewMode={viewMode}
        isTemplateMode={isTemplateMode}
        planStatus={planStatus}
        handleToggleUpdatePlanPopup={handleToggleUpdatePlanPopup}
      />
    );
  }, [activateButtonRef, isTemplateMode, planStatus, reviewButtonRef, saveButtonRef, viewMode, handleToggleUpdatePlanPopup, isPlanActive]);

  return (
    <> 
      <FixedWrapper height={{ min: 'fit-content' }} width="100%" background={{ color: 'canvas' }}>
        <TopbarWrapper>
          {node}
        </TopbarWrapper>
        <Accordion />
      </FixedWrapper>
      <PlanUpdateModal
        handleActive={handleActive}
        isVisible={isVisibleUpdatePlanPopup}
        onSetVisible={handleToggleUpdatePlanPopup}
        planName={planName}
      />
    </>
  );
};
export default React.memo(PlanTopbar);
