import { useMemo } from 'react';

import { useGracePeriodFieldsStateStore } from '../GracePeriod/stores';
import { usePayClaimsFieldsStateStore } from '../PayClaims/stores';

export default () => {
  const gracePeriodStore = useGracePeriodFieldsStateStore((state) => state.sourceState);
  const payClaimsStore = usePayClaimsFieldsStateStore((state) => state.sourceState);

  const state = useMemo(() => ({
    ...gracePeriodStore,
    ...payClaimsStore,
  }), [gracePeriodStore, payClaimsStore]);
  return state;
};
