import { useCallback } from 'react';
import {
  Field, FieldError, FieldValues, InnerError,
} from '@common-fe/common-fe';
import * as yup from 'yup';

export const useValidationUtils = () => {
  const getValidateFields = useCallback((fields: Field[]) => {
    const value: FieldValues = {};
    const getErrors = (currentFields: Field<string>[]) => {
      currentFields.forEach((field) => {
        if (field.subFields) {
          getErrors(field.subFields);
        }
        value[field.name] = field.validator;
      });
    };
    getErrors(fields);

    return value;
  }, []);

  const getErrors = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field: { [key: string]: any } | null, validators: FieldValues) => {
      try {
        const schema = yup.object().shape(validators);
        schema.validateSync(field, { abortEarly: false });
        return {};
      } catch (e) {
        const errorWithInner = e as { inner?: InnerError[] };

        const { inner = [] } = errorWithInner;

        const formatedErrors = inner.reduce((res: FieldError, error: InnerError) => ({
          ...res,
          [error.path || '']: {
            message: error.message,
            type: error.type,
          },
        }), {});
        return formatedErrors;
      }
    }, [],
  );
  return {
    getValidateFields,
    getErrors,
  };
};
export default useValidationUtils;
