import React from 'react';
import { AppButton, Box, PlusThinIcon } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { DropButton } from '@/components/elements/DropButton';
import { useHasAccess } from '@/modules/core/hooks';

import { StyledButton } from './AddClaimProcessorAndTeamButton.style';

interface Props {
  createClaimsTeam: () => void;
  addClaimsProcessor?: () => void;
}

export const AddClaimProcessorAndTeamButton:React.FC<Props> = ({
  createClaimsTeam,
  addClaimsProcessor,
}) => {
  const hasTeamCreationAccess = useHasAccess([{ permission: Permissions.MANAGE_CLAIM_TEAMS }]);

  if (!addClaimsProcessor && !hasTeamCreationAccess) return null;

  return (
    <Box justify="center">
      <DropButton
        contentStyle={{
          top: '44px',
          left: '-140px',
          minWidth: '181px',
          maxWidth: '181px',
          padding: '12px',
          zIndex: 100,
          maxHeight: 'fit-content',
          minHeight: 'fit-content',
        }}
        buttonView={(
          <AppButton
            testId="add_claims_team_button"
            buttonType="primary"
            onlyIcon
            type="button"
          >
            <PlusThinIcon />
          </AppButton>
        )}
      >
        <Box>
          {addClaimsProcessor ? (
            <StyledButton
              onClick={addClaimsProcessor}
            >
              Add Claims Processor
            </StyledButton>
          ) : null}

          {hasTeamCreationAccess ? (
            <StyledButton
              onClick={createClaimsTeam}
            >
              Create Claims Team
            </StyledButton>
          ) : null}
        </Box>
      </DropButton>
    </Box>
  );
};
