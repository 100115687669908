import { useCallback, useMemo, useState } from 'react';

import { OrganizationTypes } from '@/common/constants';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';
import { ConnectToOptions, PayrollCalendarUseFormFields, PayrollCalendarUseFormValues } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/payrollCalendar.types';
import { useEditablePayrollCalendarStore, usePayrollCalendarModalStore, usePayrollCalendarUseStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/stores';
import { useCurrentPayrollGroupsStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollGroups/stores';
import { getIdsFromPath } from '@/utils';

const ACTIVE_PLAN_STATUS = 'ACTIVE';
const MAX_INITIAL_COUNT = 3;

export const usePayrollCalendarUse = (
  currentFormValues?: PayrollCalendarUseFormValues,
) => {
  const { observingOrganization: { id, type, path }} = useCurrentOrganization();
  const { employerId } = getIdsFromPath(path);
  const currentOrgIdForPlans = useMemo(
    () => type && type === OrganizationTypes.subsidiary ? employerId : id,
    [id, employerId, type]
  );
  const [plansLength, setPlansLength] = useState(MAX_INITIAL_COUNT);
  const [groupsLength, setGroupsLength] = useState(MAX_INITIAL_COUNT);

  // const { data: payrollGroups } = useGetPayrollGroupsQuery(id);
  const { formattedData: plans } = useGetOrganizationPlansQuery(currentOrgIdForPlans, ACTIVE_PLAN_STATUS);

  const editMode = usePayrollCalendarModalStore((state) => state.editMode);
  const editableCalendar = useEditablePayrollCalendarStore((state) => state.editableCalendar);
  const alreadyUseTypes = usePayrollCalendarUseStore((state) => state.alreadyUseTypes);
  const usedPlans = usePayrollCalendarUseStore((state) => state.usedPlans);
  const usedGroups = usePayrollCalendarUseStore((state) => state.usedGroups);
  const payrollGroups = useCurrentPayrollGroupsStore((state) => state.currentPayrollGroups);

  const currentUsedPlans = useMemo(() => editableCalendar
    ? editableCalendar.calendarPlans.map((plan) => plan.id)
    : usedPlans,
  [editableCalendar, usedPlans]);
  const currentUsedGroups = useMemo(() => editableCalendar
    ? editableCalendar.calendarPayrollGroups.map((group) => group.id)
    : usedGroups,
  [editableCalendar, usedGroups]);
  const isAllOptionDisabled = useMemo(() => {
    if (editMode && editableCalendar?.groupType === ConnectToOptions.ALL) return false;
    return alreadyUseTypes?.includes(ConnectToOptions.ALL);
  }, [alreadyUseTypes, editMode, editableCalendar]);

  const isPlanOptionDisabled = useMemo(
    () => {
      if (editMode && editableCalendar?.groupType === ConnectToOptions.PLAN) return false;
      if (editMode
        && currentFormValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP
        && !currentFormValues?.[PayrollCalendarUseFormFields.PAYROLL_GROUPS]?.length) return false;

      if (editMode
        && currentFormValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN) return false;

      return (alreadyUseTypes.includes(ConnectToOptions.PLAN) && currentUsedPlans?.length === plans?.length)
      || (isAllOptionDisabled && alreadyUseTypes.includes(ConnectToOptions.GROUP))
      || alreadyUseTypes.includes(ConnectToOptions.GROUP);
    },
    [
      alreadyUseTypes,
      isAllOptionDisabled,
      plans,
      currentUsedPlans,
      editMode,
      editableCalendar,
      currentFormValues,
    ]
  );

  const isGroupOptionDisabled = useMemo(
    () => {
      if (editMode && editableCalendar?.groupType === ConnectToOptions.GROUP) return false;
      if (editMode
        && currentFormValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.PLAN
        && !currentFormValues?.[PayrollCalendarUseFormFields.PLANS]?.length) return false;
      
      if (editMode
        && currentFormValues?.[PayrollCalendarUseFormFields.CONNECT_TO] === ConnectToOptions.GROUP) return false;

      return (alreadyUseTypes.includes(ConnectToOptions.GROUP) && currentUsedGroups?.length === payrollGroups?.length)
      || (isAllOptionDisabled && alreadyUseTypes.includes(ConnectToOptions.PLAN))
      || alreadyUseTypes.includes(ConnectToOptions.PLAN);
    },
    [
      alreadyUseTypes,
      isAllOptionDisabled,
      payrollGroups,
      currentUsedGroups,
      editMode,
      editableCalendar,
      currentFormValues,
    ]
  );

  const isAlreadyUseAllOptions = useMemo(() => isAllOptionDisabled
  && (payrollGroups?.length === 0 || isGroupOptionDisabled)
  && (plans?.length === 0 || isPlanOptionDisabled)
  , [isAllOptionDisabled, isGroupOptionDisabled, isPlanOptionDisabled, payrollGroups, plans]);

  const currentDisabledUsedPlans = useMemo(() => {
    if (editableCalendar) {
      const calendarPlans = editableCalendar.calendarPlans?.map((item) => item.id);
      return usedPlans?.filter((plan) => !calendarPlans.includes(plan));
    }
    return usedPlans;
  }, [editableCalendar, usedPlans]);

  const currentDisabledUsedGroups = useMemo(() => {
    if (editableCalendar) {
      const calendarPayrollGroups = editableCalendar.calendarPayrollGroups?.map((item) => `${item.id}`);
      return usedGroups?.filter((group) => !calendarPayrollGroups.includes(group));
    }
    return usedGroups;
  }, [editableCalendar, usedGroups]);

  const plansOptions = useMemo(() => {
    const currentPlans = plans?.map((item) => ({
      key: `${item.id}`,
      value: item.planCode,
      title: item.planCode,
      textStyle: {
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
      } as React.CSSProperties,
      disabled: editMode ? currentDisabledUsedPlans?.includes(item.id) : usedPlans?.includes(item.id),
    }));
    return currentPlans?.slice(0, plansLength) || [];
  }, [plans, plansLength, usedPlans, editMode, currentDisabledUsedPlans]);
  
  const payrollGroupsOptions = useMemo(() => {
    if (!payrollGroups) return [];
    const currentGroups = payrollGroups.map((group) => ({
      key: `${group.id}`,
      value: group.name,
      label: group.name,
      textStyle: {
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
      } as React.CSSProperties,
      disabled: editMode ? currentDisabledUsedGroups?.includes(`${group.id}`) : usedGroups?.includes(`${group.id}`),
    }));
    return currentGroups?.slice(0, groupsLength) || [];
  }, [groupsLength, payrollGroups, usedGroups, editMode, currentDisabledUsedGroups]);

  const plansRemainLength = useMemo(() => {
    if (!plans) return undefined;
    if (plans?.length <= MAX_INITIAL_COUNT) return undefined;

    return plans?.length - plansLength;
  }, [plans, plansLength]);

  const groupsRemainLength = useMemo(() => {
    if (!payrollGroups) return undefined;
    if (payrollGroups?.length <= MAX_INITIAL_COUNT) return undefined;

    return payrollGroups?.length - groupsLength;
  }, [groupsLength, payrollGroups]);

  const handleShowMore = useCallback((type: ConnectToOptions) => {
    if (type === ConnectToOptions.PLAN) {
      setPlansLength(plans?.length || 0);
    } else {
      setGroupsLength(payrollGroups?.length || 0);
    }
  }, [payrollGroups, plans]);

  return {
    payrollGroupsOptions,
    plansOptions,
    isAllOptionDisabled,
    isPlanOptionDisabled,
    isGroupOptionDisabled,
    isAlreadyUseAllOptions,
    plans,
    payrollGroups,
    usedPlans,
    usedGroups,
    currentDisabledUsedGroups,
    currentDisabledUsedPlans,
    plansRemainLength,
    groupsRemainLength,
    handleShowMore,
  };
};
