import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { DefaultValues, Option } from '@/common/types';

const GET_ORGANIZATION_KEY = 'useOrganizationsContact';

interface OrganizationsResponse {
  id: string;
  name: string;

}

const getOrganizations = (
  searchValue: string,
  roleIds?: string,
) => () => api.get(PATHS.GET_FILE_ACTORS, {
  params: {
    ...(searchValue ? { search_value: searchValue } : {}),
    ...(roleIds ? { role_ids: roleIds } : {}),
  },
});

const useOrganizationQuery = (
  searchValue: string, additionalFetchParams?: DefaultValues,
) => {
  const { isLoading, data, isSuccess } = useQuery(
    [
      GET_ORGANIZATION_KEY,
      searchValue,
      additionalFetchParams?.observingOrganizationPath,
      additionalFetchParams?.type,
    ],
    getOrganizations(
      searchValue,
      additionalFetchParams?.type,
    ),
    {
      enabled: additionalFetchParams?.enabled,
    },
  );

  const formatedData = useMemo<Option[]>(() => {
    const list = data?.data || [];

    return list.map((item: OrganizationsResponse) => {
      const fullName = `${item.name}`.trim();

      return {
        key: toString(item.id),
        value: fullName,
        title: fullName,
      };
    });
  }, [data]);

  return {
    data: formatedData,
    isLoading,
    isSuccess,
  };
};

export default useOrganizationQuery;
