import React, { useMemo, useRef } from 'react';
import {
  Box, Hint, RawButton, Text, useCountHiddenElements,
} from '@common-fe/common-fe';

import { Option } from '@/common/types';

interface Props {
  options: Option[];
  testId?: string;
  onClick?: () => void;
}

const NotVisibleICount: React.FC<Props> = ({ onClick, options, testId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hiddenCount, visibleCount } = useCountHiddenElements(ref);
  const hiddenItems = useMemo(() => {
    if (!hiddenCount) {
      return [];
    }
    const list = options.slice(visibleCount).map((item) => item.value);
    return list.join(', ');
  }, [hiddenCount, options, visibleCount]);
  return (
    <Box
      fill
      data-testid={testId}
      direction="column"
      align="start"
      justify="start"
      round="dropdownItemRound"
      margin={{ top: 'xxxs' }}
      style={{ position: 'relative' }}
      pad={{ vertical: 'spacing6', left:'0px', horizontal: 'xs', right: 'l' }}
    >

      <Box direction="row">
        <Box
          ref={ref}
          direction="row"
          style={{ overflow: 'hidden', position: 'relative' }}
          pad={{ right: 'l' }}
        >
          {options.map((item, index) => (
            <>
              <Text
                weight={400}
                key={item.name}
                style={{
                  flexShrink: 0,
                  position: 'relative',
                  visibility: index >= visibleCount ? 'hidden' : undefined,
                }}
                margin={{ right: 'xxs' }}
              >
                {item.value as string}
                {options.length - 1 !== index ? ', ' : ''}

              </Text>

              {!!hiddenCount && visibleCount - 1 === index && (
                <Box
                  key={item.name}
                  style={{
                  }}
                >
                  <Hint
                    hintAlign="vertical"
                    hintElement={(
                      <RawButton onClick={onClick}>
                        <Text color="textAccent" weight={400}>+{hiddenCount}</Text>
                      </RawButton>
                    )}
                    marginContent="m"
                  >
                    <Text>{hiddenItems}.
                    </Text>
                  </Hint>
                </Box>

              )}
            </>
          ))}

        </Box>

      </Box>
    </Box>
  );
};

export default NotVisibleICount;
