 
import React, { useCallback,useState } from 'react';
import {
  AppButton, Box, FieldTypes,
  FlexControlledForm, GroupedPlansDropdown,
  Inscription,
  Preloader,   SiblingBoxesWithSpacing, } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import { useHasAccess } from '@/modules/core/hooks';

import AddModalWrapper from '../../AddModalWrapper';

import usePlansActions from './hooks/usePlanTemplateActions';
import PlanTemplateTable from './PlanTemplateTable';

interface Props {
  defaultValues?: {
    planType: string;
    planYear: string;
    template: string;
  };
  disabled?: boolean;
  loading?: boolean;
}

export const RequiredLabel: React.FC<{ title: string; }> = ({ title }) => (
  <Box margin={{ top: 'spacing8' }} direction="row">
    <Inscription
      color="danger"
      margin={{
        right: '3px',
        top: '1px',
      }}
    >
      *
    </Inscription>
    <Inscription>{title}</Inscription>
  </Box>
);

export const CONFIRM_TEXT = 'Changes that you made may not be saved.';

const PlanTemplateFormModal: React.FC<Props> = ({ disabled, loading }) => {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [shouldConfirmOnClose, setShouldConfirmOnClose] = useState(false);

  const isPlanSetupAccessible = useHasAccess([
    {
      permission: Permissions.PLAN_SETUP,
    },
  ]);
  const onSetVisibleHandler = useCallback((value: boolean) => {
    if (!value && shouldConfirmOnClose) {
      return window.confirm(CONFIRM_TEXT) && setAddModalVisible(value);
    }
    return setAddModalVisible(value);
  }, [shouldConfirmOnClose]);

  const handleClose = useCallback((needConfirm: boolean) => {
    if (needConfirm) {
      onSetVisibleHandler(false);
    } else {
      setAddModalVisible(false);
    }
  }, [onSetVisibleHandler]);
  const {
    formattedData,
    formattedLinkedTemplates,
    handleSearchValue,
    handleChangeValues,
    handleAddPlanTemplates,
    values,
    searchValue,
    isLoading,
    isAddTemplateLoading,
    isSubmit,
  } = usePlansActions(addModalVisible, handleClose);
  return (
    <>
      <AddModalWrapper
        id="available_plan_templates"
        title="Available plan templates"
        visible={addModalVisible}
        disabled={disabled}
        onSetVisible={onSetVisibleHandler}
        buttonText="Add Plan Templates"
        modalTitle="Select Plan Templates"
        successText="Plan Templates added."
        isButtonHidden={!isPlanSetupAccessible}
      >
        <Box>
          <Box round="container1Round" pad="spacing24" background="module">
            <Box
              round="container1Round"
              background="canvas"
              border={{ size: 'small', color: 'border2' }}
              elevation="default"
              pad={{ vertical: 'spacing24' }}
            >
              <FlexControlledForm
                isModalType
                fields={[
                  {
                    name: 'planTemplates',
                    type: FieldTypes.Node,
                    label: <RequiredLabel title="Plan templates" />,
                    value: (
                      <GroupedPlansDropdown
                        options={formattedData}
                        onChange={handleChangeValues}
                        defaultValues={values}
                        onSearch={handleSearchValue}
                        searchValue={searchValue}
                        isLoading={isLoading}
                        showError={isSubmit}
                        groupTitle="Categories"
                      />
                    ),
                    // @ts-ignore
                    isNarrowLabelMode: true,
                  },
                ]}
                editMode
                onDirty={setShouldConfirmOnClose}
                onChangeValues={() => {}}
                showError={isSubmit}
                wrapperStyle={{ border: 'none' }}
                formStyle={{ marginBottom: 0, marginTop: 0 }}
              />
            </Box>
          </Box>
          <Box direction="column">
            <Box
              direction="row"
              fill="horizontal"
              margin={{ top: 'spacing24' }}
              align="end"
              justify="end"
              width="medium"
            >
              <SiblingBoxesWithSpacing width="control">
                <AppButton
                  testId="cancel"
                  buttonType="secondary"
                  width="100%"
                  onClick={() => handleClose(true)}
                >
                  Cancel
                </AppButton>
              </SiblingBoxesWithSpacing>
              <SiblingBoxesWithSpacing width="control">
                <AppButton
                  testId="confirm"
                  width="100%"
                  disabled={isAddTemplateLoading}
                  onClick={handleAddPlanTemplates}
                >
                  {isAddTemplateLoading ? <Preloader color="white" /> : 'Add'}
                </AppButton>
              </SiblingBoxesWithSpacing>
            </Box>
          </Box>
        </Box>
      </AddModalWrapper>
      <Access
        accessRequirements={[
          { permission: Permissions.VIEW_PLAN },
          { permission: Permissions.VIEW_ALL },
        ]}
      >
        <PlanTemplateTable formatedLinkedTemplates={formattedLinkedTemplates} />
      </Access>
    </>
  );
};

export default PlanTemplateFormModal;
