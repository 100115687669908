import { useCallback } from 'react';

import { CustomCoverageLevelPayload } from '../../CoverageLevels.constants';

export const POSTFIX_ALREADY_TEXT = 'already exists.';
export const NAME_PREFIX = 'Details: Coverage with name';
export const CODE_PREFIX = 'Details: Coverage with code';

export default () => {
  // Details: Coverage with name
  const handleParseError = useCallback((
    errorString: string, value:Partial<CustomCoverageLevelPayload>,
  ) => {
    if (errorString.includes(NAME_PREFIX) && errorString.includes(POSTFIX_ALREADY_TEXT)) {
      return {
        coverageName: value.coverageName,
      };
    }
    if (errorString.includes(CODE_PREFIX) && errorString.includes(POSTFIX_ALREADY_TEXT)) {
      return {
        coverageCode: value.coverageCode,
      };
    }
    return {};
  }, []);
  return {
    handleParseError,
  };
};
