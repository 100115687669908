import React from 'react';
import { Notification } from '@common-fe/common-fe';

import NotificationDetails from './NotificationDetails';
import NotificationModalWrapper from './NotificationModalWrapper';

interface Props {
  notification?: Notification;
  setOpenModal: (value: boolean) => void;
  dismissNotification: (id?: string) => void;
  openModal: boolean;
}

const NotificationModal: React.FC<Props> = ({
  notification,
  setOpenModal,
  dismissNotification,
  openModal,
}) => (
  <NotificationModalWrapper
    testId="notification"
    visible={openModal}
    setOpenModal={() => setOpenModal(false)}
  >
    <NotificationDetails
      data={notification}
      onCancel={() => {
        setOpenModal(false);
      }}
      dismissNotification={dismissNotification}
    />
  </NotificationModalWrapper>
);

export default NotificationModal;
