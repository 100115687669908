import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option, Pagination } from '@/common/types';

interface BalanceTierPayload {
  id: number;
  apy_high: number;
  apy_low: number;
  balance_from: number;
  balance_to: number;
  interest_rate: number;
  version_id: number;
}
interface InterestRateVersionPayload {
  id: number;
  natural_id: string;
  balance_tier: BalanceTierPayload[];
}
interface InterestRatePayload {
  id: number;
  name: string;
  versions: InterestRateVersionPayload[];
}
export default () => {
  // const { data: plan } = useCurrentPlan();
  const {
    isLoading, data, isSuccess,
  } = useQuery(
    PATHS.INTEREST_RATE_ACTIVE,
    () => api.get<Pagination<InterestRatePayload>>(PATHS.INTEREST_RATE_ACTIVE),
    {},
  );

  const formatedOptions = useMemo(() => {
    const list = data?.data?.content;

    return list?.map((item) => ({
      key: _.toString(item?.id),
      value: item?.name,
      title: item?.name,
    } as Option));
  }, [data]);

  return {
    isSuccess,
    formatedOptions,
    isLoading,
  };
};
