import _ from 'lodash';

import { storeCreator } from '@/utils/store/store.utils';

import { AddSnackbarArgument, SnackbarNotification } from '../core.types';

interface State {
  list: SnackbarNotification[];
  handleReset: () => void;
  handleAdd: (item: AddSnackbarArgument) => void;
  handleRemoveById: (id: string) => void;
}

export const useStore = storeCreator<State>((set) => ({
  list: [],
  handleAdd: (item) => set((state) => ({
    list: [
      ...state.list,
      { ...item, id: _.uniqueId() } as SnackbarNotification,
    ],
  })),
  handleRemoveById: (id) => set((state) => ({
    list: state.list.filter((item) => item.id !== id),
  })),
  handleReset: () => set((state) => ({
    list: [],
  })),

}));
export default () => {
  const store = useStore();
  return {
    ...store,
  };
};
