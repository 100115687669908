import React from 'react';
import { Box } from '@common-fe/common-fe';

import { RecordsInfo } from '@/modules/employer/components/Contributions/contributions.types';

import { ContributionInvoiceListItem } from './ContributionInvoiceListItem';

interface Props {
  data?: RecordsInfo[];
}

export const ContributionInvoiceList: React.FC<Props> = ({ data }) => (
  <Box
    background={{ color: 'module' }}
    round="container1Round"
    pad="spacing16"
    margin={{ top: 'spacing16' }}
    gap="spacing8"
    data-testid="ContributionInvoiceList_container"
  >
    {
      data?.map((item) => (
        <ContributionInvoiceListItem key={item.planCode} record={item} />
      ))
    }
  </Box>
);
