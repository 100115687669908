import { useMutation } from 'react-query';

import { api, mock } from '@/api';
import PATHS from '@/common/paths';

const useContactDeactivateQuery = (onSuccess?: () => void) => {
  mock.onPut(/\/contacts\/\d+\/statuses\/DISABLED/).reply(() => [200, {}]);
  const { mutateAsync } = useMutation(
    (id: string) => api.put(PATHS.DEACTIVATE_CONTACT(id)),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );

  return mutateAsync;
};

export default useContactDeactivateQuery;
