 
import React, { useRef, useState } from 'react';
import { Box, ButtonDropdownContent, DotsMenuIcon, useClickOutside } from '@common-fe/common-fe';

interface IconDropdownOption {
  redirectFunc: () => void;
  title: string;
  disabledHint?: string;
  disabled?: boolean;
  warning?: boolean;
}

interface Props {
  options: IconDropdownOption[];
}

const ExpressSetupLeaveDropdown: React.FC<Props> = ({
  options = [],
}) => {
  const currentRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  
  useClickOutside(currentRef, () => {
    setExpanded(false);
  });

  return (
    <Box ref={currentRef}>
      <Box
        align="center"
        justify="center"
        data-testId="dots-menu-icon-wrapper"
        onClick={() => setExpanded(!expanded)}
      >
        <DotsMenuIcon color={expanded ? 'navigationAccent' : 'iconSecondary'} />
      </Box>
      {expanded && (
        <ButtonDropdownContent
          options={options}
          close={() => setExpanded(false)}
        />
      )}
    </Box>
  );
};

export default ExpressSetupLeaveDropdown;
