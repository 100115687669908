import { usePlanDefinitionFields, usePlanDefinitionSourceState,usePlanDefinitionState } from '../../PlanDefinition/hooks';

import { useChangesList } from './useChangesList';
import { useFieldsWithSubfields } from './useFieldsWithSubfields';
import { useMatchFields } from './useMatchFields';

const usePlanDefinitionSummary = (isTemplateMode?: boolean) => {
  const state = usePlanDefinitionState();
  const sourceState = usePlanDefinitionSourceState();

  const fields = usePlanDefinitionFields(false, isTemplateMode);

  const { allFields: stateFields } = useFieldsWithSubfields(state, fields);

  const { allFields } = useFieldsWithSubfields(sourceState, fields);
  const list = useMatchFields(state, stateFields);
  const formatedBaseList = useMatchFields(sourceState, allFields);
  const changes = useChangesList(formatedBaseList, list);
  return {
    changes,
  };
};
export default usePlanDefinitionSummary;
