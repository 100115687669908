import React from 'react';
import { Box, WarningIcon } from '@common-fe/common-fe';

const FileWarning = () => (
  <Box align="end">
    <WarningIcon data-testid="file-warning" />
  </Box>
);

export default FileWarning;
