import { useMemo } from 'react';
import { FieldError } from '@common-fe/common-fe';

import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { usePlanYearFields } from '../ExpressSetupWizard/PlansWizard/PlanYear/hooks';
import { usePlanYearStore } from '../ExpressSetupWizard/PlansWizard/PlanYear/stores';

const usePlanYearFormValidate = () => {
  const { getValidateFields, getErrors } = useValidationUtils();
  const planYearState = usePlanYearStore((state) => state.state);
  const contactFields = usePlanYearFields();

  const planYearErrors: FieldError = useMemo(() =>  getErrors(planYearState, getValidateFields(contactFields)), [getErrors, planYearState, getValidateFields, contactFields]);

  return planYearErrors;
};

export default usePlanYearFormValidate;
