import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { PlanResponse } from '@/modules/plan/plan.types';

export const QUERY_KEY = 'PLAN_BY_ID';
export const usePlanByIdQuery = (id?: string, successCallback?: (plan: PlanResponse) => void) => {
  const {
    error, data, isSuccess, isError, isFetching, isLoading,
  } = useQuery(
    [QUERY_KEY, id],
    id
      ? () => api.get<PlanResponse>(
        PATHS.PLAN_BY_ID(id),
      )
      : () => null,
    {
      onSuccess: (response) => {
        if (successCallback && response) {
          successCallback(response.data);
        }
        return response;
      },
      cacheTime: 10,
      refetchOnMount: false,
    },
  );

  return {
    data: data?.data,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  };
};

export default usePlanByIdQuery;
