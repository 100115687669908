import React from 'react';
import { Box } from '@common-fe/common-fe';

import {
  PayoutDefinition,
} from '@/modules/ProcessingDefinition/ProcessingDefinition.types';

import PayoutDefinitionNameForm from './PayoutDefinitionNameForm';

interface Props {
  data?: PayoutDefinition;
  onDirty?: (isDirty: boolean) => void;
}

const PayoutDefinitionName: React.FC<Props> = ({ data, onDirty }) => (
  <Box background="canvas" round="container1Round" elevation="default" id="basic_properties" data-testId="payout-definition-name-form">
    <PayoutDefinitionNameForm
      data={data}
      onDirty={onDirty}
    />
  </Box>
);

export default PayoutDefinitionName;
