import React from 'react';
import { Box, NullClaimsIcon,Text } from '@common-fe/common-fe';

const EmptyClaimsPlaceholder: React.FC = () => (
  <Box background="module" round="moduleRound" pad={{ vertical: 'spacing32', top: '18px' }}>
    <Box align="center">
      <Box margin={{ bottom: 'spacing24' }}>
        <NullClaimsIcon />
      </Box>
      <Text weight={700} size="14px">
        There are no claims yet
      </Text>
    </Box>
  </Box>
);

export default EmptyClaimsPlaceholder;
