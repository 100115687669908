import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Grid,
  Inscription,
  ListItemField,
  RadioButton,
} from '@common-fe/common-fe';

const CardItem = ({ title, value, node }: ListItemField) => {
  return (
    <Box>
      <Inscription
        size="small"
        color="textBody"
        weight={700}
      >
        {title}
      </Inscription>
      <Inscription
        title={value}
        color="textBody"
        style={{ 
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value || node}
      </Inscription>
    </Box>
  );
};

interface Props {
  testId?: string;
  itemKey?: string;
  itemFields?: ListItemField[];
  isSelected?: boolean;
  onSelect?: (value?: string) => void;
  children?: React.ReactNode;
}

const RadioButtonCard: React.FC<Props> = ({
  testId, itemKey, isSelected, onSelect, itemFields, children
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const slicedList = useMemo(() => itemFields?.slice(0, 3) || [], [itemFields]);
  const list = useMemo(() => isExpanded ? itemFields : slicedList, [isExpanded, itemFields, slicedList]);

  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleSelect = useCallback(() => {
    if (onSelect && itemKey) {
      onSelect(itemKey);
      setIsExpanded(true);
    }
  }, [itemKey, onSelect]);

  return (
    <Box
      data-testId={`${testId}_radio-button-card`}
      elevation="default"
      background="canvas"
      round="container1Round"
      pad={{ vertical: 'spacing16', horizontal: 'spacing24' }}
      border={{ size: '1px', color: 'border2' }}
    >
      <Box direction="row">
        <Box direction="row" margin={{ vertical: 'spacing8' }}>
          <RadioButton
            name={`${testId}_radio-button`}
            checked={isSelected}
            onChange={handleSelect}
          />

          <Box
            width={{ min: 'spacing24' }}
            height={{ min: 'spacing24' }}
            margin={{ left: 'spacing12' }}
            onClick={handleExpand}
          >
            {isExpanded ? (
              <ChevronUpIcon size="spacing24" color="iconPrimary" />
            ) : (
              <ChevronDownIcon size="spacing24" color="iconPrimary" />
            )}
          </Box>
        </Box>

        <Grid
          margin={{ left: 'spacing12' }}
          width="100%"
          columns="1/3"
          gap={{
            column: 'spacing12',
            row: 'spacing8'
          }}
        >
          {list?.map((cardItem) => (
            <CardItem
              key={cardItem.key}
              type={cardItem.type}
              title={cardItem.title}
              value={cardItem.value}
              node={cardItem?.node}
            />
          ))}
        </Grid>
      </Box>
      {children}
    </Box>
  );
};

export default RadioButtonCard;
