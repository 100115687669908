import { useMemo } from 'react';
import { OptionKey } from '@common-fe/common-fe';

import { ALL_OPTION } from '@/common/constants';
export const handleAllToggle = (options: OptionKey[]) => {
  const minStatusesLength = 2;
  const isStatusAllSelected = options[options.length - 1] === ALL_OPTION.value;

  if (options.length === 0 || isStatusAllSelected) {
    return [ALL_OPTION.value];
  }

  const isStatusAllChangedToAnother = options.length === minStatusesLength && options[0] === ALL_OPTION.value;

  if (isStatusAllChangedToAnother) {
    return options.filter((option) => option !== ALL_OPTION.value);
  }
  return options;
};

const useAllOptionsToggle = (options: OptionKey[]) => {
  return useMemo(() => {
    return handleAllToggle(options);
  }, [options]);
};

export default useAllOptionsToggle;
