import React from 'react';

import { useHealthPlanErrorsHandler } from './hooks';

interface Props {
  isTemplateMode?: boolean;
}
const ValidationNode: React.FC<Props> = ({ isTemplateMode }) => {
  useHealthPlanErrorsHandler(isTemplateMode);
  return null;
};

export default ValidationNode;
