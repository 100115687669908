import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

const QUERY_KEY = 'getEmailTemplateSkeletonQueryKey';

export const useGetEmailTemplateSkeletonQuery = (emailTemplateId?: string) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEY, emailTemplateId],
    emailTemplateId
      ? () => api.get(PATHS.GET_HTML_PREVIEW_EMAIL_TEMPLATES(emailTemplateId))
      : () => null,
    {
      cacheTime: 0,
    },
  );

  return {
    data: data?.data,
    isLoading,
  };
};
