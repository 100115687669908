import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

export interface StatePayload {
  id: number;
  code: string;
  name: string;
}

const formatData = (states: StatePayload[]) => states.map((state: StatePayload) => ({
  id: toString(state.id),
  code: state.code,
  name: state.name,
}));

const useStatesQuery = () => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    PATHS.STATES,
    () => api.get(PATHS.STATES),
  );

  const states = useMemo(() => formatData(data?.data || []), [data]);
  const statesOptions = useMemo(() => states.map(
    (state) => ({ key: state.code, title: state.name }),
  ), [states]);
  return ({
    states,
    statesOptions,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useStatesQuery;
