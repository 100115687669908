import React, { useCallback } from 'react';
import { Box, Field, FlexControlledForm } from '@common-fe/common-fe';

import { Option } from '@/common/types';

import { useEditEmailNotificationForm } from './hooks/useEditEmailNotificationForm';
import { EmailNotificationTemplate } from './store/useEditEmailNotificationModal.store';

interface Props {
    handleChangeValues?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled?: (val: boolean) => void,) => void;
  isSubmit?: boolean;
  setSetup?: (value: boolean) => void;
  templateValues?: EmailNotificationTemplate;
  options?: Option[];
}

export const EditEmailNotificationForm: React.FC<Props> = ({
  handleChangeValues,
  isSubmit,
  setSetup,
  templateValues,
  options,
}) => {
  const fields = useEditEmailNotificationForm(templateValues, options);

  const handleChangeDateValue = useCallback((values: object) => {
    if (handleChangeValues) handleChangeValues(values, fields, setSetup);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="EditEmailNotificationForm_container" round="container1Round" background="canvas">
      <FlexControlledForm
        fields={fields}
        editMode
        showError={isSubmit}
        onChangeValues={handleChangeDateValue}
        isModalType
      />
    </Box>
  );
};
