import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CONTENT_ID_SELECTOR,
  OrderIcon,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import globalTheme from '@/styles/theme';

export const CHECKED_CLASS_NAME = 'checked-claim';
const SELECTOR = `.${CHECKED_CLASS_NAME}`;

const POPUP_WIDTH = 324;
const POPUP_HEIGHT = 480;
const MIN_SPACE = 12;
const zIndex = 2000;
const MOOVE_ICON_PADDING = 24;

const Wrapper = styled(Box)<{ hasArrow: boolean, topShift: number, bottomShift: number }>`
  &:before,&:after {
    display: ${({ hasArrow }) => hasArrow ? 'block' : 'none'};
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    top: calc(50% - 8px - ${({ topShift }) => topShift}px - ${({ bottomShift }) => bottomShift}px);
    left: -6px;
    border-radius: 4px;
    background: ${globalTheme.colors.module};
    z-index: 1;
    ${globalTheme.shadows.active}
  };
  &:after {
    z-index: ${zIndex + 1};
    box-shadow: none;
  }
`;

interface Coordinates {
  top: number,
  right: number,
  bottom: number,
  left: number,
  height: number,
  width: number,
}
const INIT_COORDINATES: Coordinates = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 98,
  height: 0,
  width: 0,
};

interface Props {
  children: React.ReactNode;
}

const MoovingWrapper: React.FC<Props> = ({ children }) => {
  const [isMooved, setIsMooved] = useState(false);
  const [topShift, setTopShift] = useState(0);
  const [bottomShift, setBottomShift] = useState(0);
  const [coordinates, setCoordinates] = useState(INIT_COORDINATES);
  const handleScrollingResizing = useCallback(() => {
    if (!isMooved) setIsMooved(true);
  }, [isMooved, setIsMooved]);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onMouseMove = useCallback((e: any) => {
    setCoordinates({
      ...coordinates,
      top: e.clientY - MOOVE_ICON_PADDING,
      left: e.clientX - POPUP_WIDTH/2,
    });

    if (!isMooved) setIsMooved(true);
  }, [isMooved, setIsMooved, coordinates]);
  const stopMooving = useCallback(() => {
    document.body.removeEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);
  const handleMouseMooving = useCallback(() => {
    document.body.addEventListener('mousemove', onMouseMove);
  }, [onMouseMove]);
  const handleMouseUp = useCallback(() => {
    document.body.addEventListener('mouseup', stopMooving);
  }, [stopMooving]);
  const startMooving = useCallback(() => {
    handleMouseUp();
    handleMouseMooving();
  }, [handleMouseUp, handleMouseMooving]);

  useEffect(() => {
    const appContainer = document.getElementById(CONTENT_ID_SELECTOR);

    appContainer?.addEventListener('scroll', handleScrollingResizing);
    window.addEventListener('resize', handleScrollingResizing);

    return () => {
      setIsMooved(false);
      appContainer?.removeEventListener('scroll', handleScrollingResizing);
      window?.removeEventListener('resize', handleScrollingResizing);
      document.body.removeEventListener('mouseup', stopMooving);
      document.body.removeEventListener('mousemove', onMouseMove);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  useEffect(() => {
    const boundingClientRect = document.querySelector(SELECTOR)?.getBoundingClientRect();
    const bodyHeight = document.body.getBoundingClientRect().height;

    if (!boundingClientRect) return;

    const { top, right, bottom, width, height } = boundingClientRect;
    const topSpacing = top - POPUP_HEIGHT/2 + height/2;
    const bottomSpacing = boundingClientRect.bottom + POPUP_HEIGHT/2;
    const hasBottomShift = bottomSpacing > bodyHeight - MIN_SPACE;
    const hasTopShift = topSpacing < MIN_SPACE;
    const canNotShowArrow = bodyHeight - boundingClientRect.bottom < MIN_SPACE + 40;
    let currentTop = topSpacing;

    if (canNotShowArrow) setIsMooved(true);
    if (hasTopShift) setTopShift(Math.abs(topSpacing - MIN_SPACE));
    if (hasBottomShift && !canNotShowArrow) setBottomShift(bodyHeight - bottomSpacing);
    if (hasBottomShift) {
      currentTop = bodyHeight - POPUP_HEIGHT - MIN_SPACE;
    }

    setCoordinates({
      top: hasTopShift ? MIN_SPACE : currentTop,
      right,
      bottom,
      left: boundingClientRect.left + INIT_COORDINATES.left,
      width,
      height,
    });
  }, [setIsMooved, setBottomShift]);

  return (
    <Wrapper
      hasArrow={!isMooved}
      topShift={topShift}
      bottomShift={bottomShift}
      width={`${POPUP_WIDTH}px`}
      height={`${POPUP_HEIGHT}px`}
      style={{
        position: 'fixed',
        ...!coordinates.top && !coordinates.bottom ? { display: 'none' } : {},
        zIndex,
        left: `${coordinates.left}px`,
        top: `${coordinates.top}px`
      }}
    >
      <Box
        background="canvas"
        round="8px"
        border={{ size: '1px', color: 'border2' }}
        style={{ zIndex, ...globalTheme.shadows.active, }}
      >
        <Box
          align="center"
          margin={{ top: 'spacing12', bottom: 'spacing4' }}
          flex="grow"
        >
          <OrderIcon
            style={{ transform: 'rotate(90deg)', cursor: 'grab' }}
            onMouseDown={startMooving}
          />
        </Box>

        {children}
      </Box>
    </Wrapper>
  );
};

export default MoovingWrapper;
