import { Option } from '@/common/types';

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Terminated = 'TERMINATED',
  LeaveOfAbsence = 'LEAVE OF ABSENCE',
  Retired = 'RETIRED',
  Future = 'FUTURE',
  COBRA = 'COBRA',
  Null = 'Null',
}

export const EMPLOYEE_STATUSE_OPTIONS: Option[] = [
  {
    key: EmployeeStatus.Null,
    value: 'All',
    title: 'All',
  },
  {
    key: EmployeeStatus.Active,
    value: 'Active',
    title: 'Active',
  },
  {
    key: EmployeeStatus.Terminated,
    value: 'Terminated',
    title: 'Terminated',
  },
  {
    key: EmployeeStatus.LeaveOfAbsence,
    value: 'Leave of absence',
    title: 'Leave of absence',
  },
  {
    key: EmployeeStatus.Retired,
    value: 'Retired',
    title: 'Retired',
  },
  {
    key: EmployeeStatus.Future,
    value: 'Future',
    title: 'Future',
  },
  {
    key: EmployeeStatus.COBRA,
    value: 'COBRA',
    title: 'COBRA',
  },
];
