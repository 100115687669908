import { useMemo } from 'react';
import { ListItem } from '@common-fe/common-fe';

import { ListItemType } from '@/common/types';

import { CarrierConfig } from '../queries/useCarrierConfigList.query';

interface Props {
  carrierConfigs: CarrierConfig[],
  setOpenModal: (id: string) => void,
  hasBacklighting?: boolean;
}

const useCarrierConfigList = ({ carrierConfigs, setOpenModal, hasBacklighting }: Props) => {
  const formattedFields = useMemo(() => {
    if (!carrierConfigs.length) return [];

    const formatted: ListItem[] = carrierConfigs.map((config, index) => ({
      id: `${config.carrierConfigId}`,
      fields: [{
        key: 'carrierName',
        type: ListItemType.Text,
        title: config.carrierName,
        flex: 1,
        ellipsisMode: true,
        hover: true,
        onClick: () => setOpenModal(config.carrierConfigId),
        style: {
          fontWeight: 'bold',
        },
      },
      {
        key: 'carrierID',
        type: ListItemType.Text,
        title: config.carrierExternalId,
        flex: 1,
        ellipsisMode: true,
      },
      {
        key: 'submitterID',
        type: ListItemType.Text,
        title: config.submitterId,
        flex: 1,
        ellipsisMode: true,
      },
      {
        key: 'organization',
        type: ListItemType.Text,
        title: config.organization,
        ellipsisMode: true,
        flex: 1,
      },
      {
        key: 'purpose',
        type: ListItemType.Text,
        title: config.purposeType,
        ellipsisMode: true,
        flex: 1,
      },
      {
        key: 'description',
        type: ListItemType.Text,
        title: config.description,
        ellipsisMode: true,
        flex: 2,
      }],
      ...hasBacklighting && index === 0 ? { className: 'backlighting' } : {},
    }));

    return formatted;
  }, [carrierConfigs, setOpenModal, hasBacklighting]);

  return {
    fields: formattedFields,
  };
};

export default useCarrierConfigList;
