import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
import ReconciliationReportDetails
  from '@/modules/employer/components/ReconciliationReportDetails/ReconciliationReportDetails';
import { useReconciliationReportDetails } from '@/modules/employer/queries/useReconciliationBankAccounts.query';
import theme from '@/styles/theme';

import Topbar from '../modules/core/components/Topbar';

const ReconciliationReportDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { formatedData } = useReconciliationReportDetails(id);
  useBreadcrumbs([
    {
      route: ROUTES.FINOPS,
      title: 'FinOps',
    },
    {
      route: ROUTES.RECONCILIATION_REPORT_DETAILS(id),
      title: formatedData?.account_description || '',
    },
  ], [formatedData], { backBtn: true });

  return (
    <Box width="100%" height="fit-content">
      <Topbar />
      <Box align="center" margin={{ top: 'medium' }}>
        <Box width={theme.defaultContentWidth}>
          <ReconciliationReportDetails />
        </Box>
      </Box>
    </Box>
  );
};

export default ReconciliationReportDetailsPage;
