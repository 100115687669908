import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'GET_POLICIES_STATUSES';

export interface PolicyStatusDto {
  id: number;
  policy_id?: number;
  status: string;
  valid_from: string;
  valid_to: string | null;
}

export interface PolicyStatus {
  id: string;
  policyId?: string;
  status: string;
  validFrom: string;
  validTo: string | null;
}

const formatData = (statuses: PolicyStatusDto[]) => statuses?.map((item: PolicyStatusDto) => ({
  id: toString(item.id),
  policyId: toString(item.policy_id),
  status: item.status,
  validFrom: item.valid_from,
  validTo: item.valid_to,
})) as PolicyStatus[];

const useGetPolicyStatusesQuery = (id?: string) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    [QUERY_KEY, id],
    () => api.get<PolicyStatusDto[]>(PATHS.GET_POLICIES_STATUSES(id)),
    {
      enabled: !!id,
    },
  );
  const statuses = useMemo(() => formatData(data?.data || []) as PolicyStatus[], [data]);

  return ({
    statuses,
    isError,
    isLoading,
    isSuccess,
  });
};

export default useGetPolicyStatusesQuery;
