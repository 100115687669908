import React, { useCallback, useEffect, useState } from 'react';
import { AppButton, Box, Preloader, Text, WarnModal } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import theme from '@/styles/theme';

import { useHandleExpressSetupSetUp } from '../../hooks';
import { EXPRESS_SETUP_WIZARD_CONTENT_WIDTH } from '../ExpressSetupWizard';

import useBankAccountStore from './BankAccountForm/stores/useBankAccount.store';
import BankAccountForm from './BankAccountForm';

interface Props {
  isEditMode?: boolean;
  title?: string;
  accountNumber?: string;
  onEditToggle: (value: boolean) => void;
}

const BankAccountWizard: React.FC<Props> = ({
  isEditMode,
  title,
  accountNumber,
  onEditToggle,
}) => {
  const [errorModal, setErrorModal] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [formKey, setFormKey] = useState('');

  const bankAccountState = useBankAccountStore((state) => state.state); 
  const bankAccountSourceState = useBankAccountStore((state) => state.sourceState);
  const handleSetSourceState = useBankAccountStore((state) => state.setSourceState);  
  const handleSetState = useBankAccountStore((state) => state.setState); 
  const handleSetShowErrors = useBankAccountStore((state) => state.setShowErrors); 

  const { onSaveBankAccountHandler, isBankAccountLoading, isBankAccountServerError } = useHandleExpressSetupSetUp();

  const handleSaveBankAccount = useCallback((isRemoveMode?: boolean) => {
    if (accountNumber) {
      onSaveBankAccountHandler(
        true, 
        () => {
          onEditToggle(false);
          if (isRemoveMode) {
            setFormKey(uniqueId());
            setDeleteModalVisible(false);
          }
        },
        isRemoveMode
      );
    } else {
      onSaveBankAccountHandler(false, () => onEditToggle(false));
    }
  }, [accountNumber, onEditToggle, onSaveBankAccountHandler]);

  useEffect(() => {
    if (bankAccountState && !accountNumber) {
      handleSetSourceState(bankAccountState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => handleSetShowErrors(false), [handleSetShowErrors]);
  useEffect(() => {
    if (isBankAccountServerError) setErrorModal(true);
  }, [isBankAccountServerError]);

  return (
    <Box
      width="100%"
      data-testId="bank-account-wizard" 
    >
      <Text weight={700} size="32px" color="textTitle" margin={{ bottom: 'l' }}>{title}</Text>
      <Box background="module" pad="medium" round="container1Round" data-testid="Contact-form-wrapper">
        <BankAccountForm key={formKey} isEditMode={isEditMode || !accountNumber} isBankExist={!!accountNumber} />
      </Box>
      {
        accountNumber ? (
          <Box direction="row" justify="between" margin={{ top: 'l' }}>
            {
              isEditMode ? (
                <>
                  <AppButton
                    buttonType="secondary"
                    color="blue"
                    onClick={() => {
                      onEditToggle(false);
                      if (accountNumber && bankAccountSourceState) handleSetState(bankAccountSourceState);
                      setFormKey(uniqueId());
                    }}
                    testId="cancel"
                    width="312px"
                    style={{ marginRight: theme.spacings.s, height: '54px' }}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    onClick={() => handleSaveBankAccount()}
                    testId="save"
                    disabled={isBankAccountLoading}
                    width='312px'
                    style={{ height: '54px' }}
                  >
                    {isBankAccountLoading ? <Preloader color="white" /> : 'Save'}
                  </AppButton>
                </>
              ) : (
                <>
                  <AppButton
                    buttonType="secondary"
                    color="red"
                    onClick={() => setDeleteModalVisible(true)}
                    testId="remove"
                    width="312px"
                    style={{ marginRight: theme.spacings.s, height: '54px' }}
                  >
                    Remove
                  </AppButton>
                  <AppButton
                    onClick={() => {
                      onEditToggle(true);
                      setFormKey(uniqueId());
                    }}
                    testId="edit"
                    disabled={isBankAccountLoading}
                    width="312px"
                    style={{ height: '54px' }}
                  >
                    Edit
                  </AppButton>
                </>
              )
            }
          </Box>
        ) : (
          <AppButton
            onClick={() => handleSaveBankAccount()}
            testId="submit"
            disabled={isBankAccountLoading}
            style={{ width: EXPRESS_SETUP_WIZARD_CONTENT_WIDTH, height: '54px' }}
          >
            {isBankAccountLoading ? <Preloader color="white" /> : 'Submit'}
          </AppButton>
        )
      }
      <WarnModal
        testId="main-contact-wizard-delete-modal"
        visible={isDeleteModalVisible}
        isPendingIcon
        header={`Remove bank account (ending in ${accountNumber || ''})?`}
        onSetVisible={setDeleteModalVisible}
        cancelButtonNode={(
          <Box direction="row">
            <Box width="150px" margin={{ right: 'spacing6' }}>
              <AppButton
                buttonType="secondary"
                onClick={() => setDeleteModalVisible(false)}
                width="100%"
              >
                Cancel
              </AppButton>
            </Box>
            <Box width="150px" margin={{ left: 'spacing6' }}>
              <AppButton
                color="red"
                onClick={() => handleSaveBankAccount(true)}
                width="100%"
                disabled={isBankAccountLoading}
              >
                {isBankAccountLoading
                  ? <Preloader color="white" />
                  : 'Yes, Remove'}
              </AppButton>
            </Box>
          </Box>
        )}
      />
      <WarnModal
        testId="main-contact-wizzard-error-modal"
        visible={errorModal}
        onSetVisible={setErrorModal}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot ${accountNumber ? 'update' : 'create'} the Bank Account with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSaveBankAccount}
        submitButtonText="Try again"
      />
    </Box>
  );
};

export default BankAccountWizard;
