import React, { useCallback, useEffect } from 'react';

import routes from '@/common/routes';
import { useHistory } from '@/modules/core/hooks';

import { ScheduledContributionPayload } from './ScheduledContribution.types';
import ScheduledContributionCreateModal from './ScheduledContributionCreateModal';
import { useScheduledContributionStore } from './stores';

interface Props {
  currentScheduledContribution?: ScheduledContributionPayload | null;
  onSetVisible: () => void;
}
const ScheduledContributionEditModal: React.FC<Props> = ({onSetVisible, currentScheduledContribution }) => {

  const handleSetState = useScheduledContributionStore((state) => state.setState);
  useEffect(() => {
    if(currentScheduledContribution?.id) {
      handleSetState({
        contributionId: currentScheduledContribution.id
      });
    }
  }, [currentScheduledContribution, handleSetState]);
  const history = useHistory();
  const handleNext = useCallback(() => {
    history.push(routes.SCHEDULED_CONTRIBUTION_EDIT_SUMMARY(currentScheduledContribution?.id));
  }, [currentScheduledContribution?.id, history]);
  return( 
    <ScheduledContributionCreateModal
      modalTitle="Edit Payroll Contribution Schedule"
      visible={!!currentScheduledContribution}
      defaultValues={currentScheduledContribution || {}}
      onSetVisible={onSetVisible}
      isEditMode
      onNext={handleNext}
    />
  );
};
export default ScheduledContributionEditModal;
