import React from 'react';
import { Box } from '@common-fe/common-fe';

import { WrapperWithBottomShadow } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/PreviewSection/PreviewSection.styles';

import {
  EmailTemplateContent,
  EmailTemplateFooter,
  EmailTemplateHeader,
  EmailTemplateWrapper,
} from './components';

interface Props {
  isFullPreview?: boolean;
  onSetFullPreview?: () => void;
}

const EmailTemplate: React.FC<Props> = ({
  isFullPreview, onSetFullPreview,
}) => {
  if (isFullPreview) {
    return (
      <EmailTemplateWrapper>
        <EmailTemplateHeader />
        <EmailTemplateContent />
        <EmailTemplateFooter />
      </EmailTemplateWrapper>
    );
  }

  return (
    <WrapperWithBottomShadow onClick={onSetFullPreview}>
      <Box height={{ min: '250px' }}>
        
        <EmailTemplateWrapper>
          <EmailTemplateHeader />
          <Box height="100px" />
        </EmailTemplateWrapper>
      </Box>
    </WrapperWithBottomShadow>
  );
};

export default EmailTemplate;
