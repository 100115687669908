import { useCallback } from 'react';

import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { AddressPayload } from '../SetupEmployer.types';

export default (id: string) => {
  const setAddress = useStore((state) => state.setAddress);

  const handleSave = useCallback((newValue: AddressPayload) => {
    setAddress(newValue);
  }, [setAddress]);

  return {
    saveAddressInfo: handleSave,
  };
};
