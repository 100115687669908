import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,   Box,   Heading, RawButton, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import useAccordionStore from '@/components/elements/Accordion/useAccordion.store';

interface HeaderProps {
  expandless?: boolean;
}
export const StyledHeader = styled(Heading)<HeaderProps>`
  &:hover {
    color: ${({ theme, expandless }) => (!expandless ? '' : theme.colors.textAccentHover)};
  };
  
`;

interface Props {
  title: React.ReactNode;
  anchorId: string;
  required?: boolean;
  requiredText?: string;
  expandless?: boolean;
  subTitle?: string;
  testId?: string;
  children?: React.ReactNode;
  defaultExpandedValue?: boolean;
}
const ModuleWrapper: React.FC<Props> = ({
  children, title, anchorId, required, expandless,
  requiredText, subTitle, testId, defaultExpandedValue,
}) => {
  const [expanded, setExpanded] = useState(
    typeof defaultExpandedValue === 'boolean'
      ? defaultExpandedValue
      : true,
  );
  const { handleAdd, list } = useAccordionStore();
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
    handleAdd({
      id: anchorId,
      isExpanded: !expanded,
    });
  }, [expanded, handleAdd, anchorId]);

  useEffect(() => {
    if (expandless) {
      handleAdd({
        id: anchorId,
        isExpanded: expanded,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ok = list.find(({ id }) => id === anchorId);
    if (ok) {
      setExpanded(ok.isExpanded);
    }
  }, [list, anchorId]);
  return (
    <div id={anchorId}>
      <Box
        data-testid={testId}
        direction="column"
        margin={{ bottom: 'spacing18' }}
      >
        {expandless ? (
          <RawButton
            data-testid={testId
              ? `${testId}_module-wrapper` : 'module-wrapper'}
            onClick={handleToggle}
          >
            <Box
              direction="row"
              align="center"
              justify="start"
              margin={{ bottom: 'm' }}
            >
              <Box
                align="center"
                justify="center"
              >
                {expandless && (
                  <>
                    {expanded ? (
                      <ArrowUpIcon
                        color="textBody"
                      />
                    ) : (
                      <ArrowDownIcon
                        color="textBody"
                      />
                    )}

                  </>

                )}
              </Box>
              <StyledHeader
                expandless={expandless}
                fill
                level={4}
                data-testid={`${testId}_expandless-header`}
                size="small"
                color="textTitle"
                style={{ flex: 1 }}
                margin={expandless ? { horizontal: 'm' } : undefined}
              >
                {title}
              </StyledHeader>
              <Box direction="row" align="center">
                {required && (
                  <Text color="danger" size="small" weight={500}>
                    {requiredText || 'Required'}

                  </Text>
                )}

              </Box>
            </Box>
          </RawButton>
        ) : (
          <StyledHeader
            fill
            level={4}
            size="small"
            color="textTitle"
            data-testid={`${testId}_header`}
            expandless={expandless}
            style={{ flex: 1 }}
            margin={{ bottom: 'spacing24' }}
          >
            {title}
          </StyledHeader>
        )}

        {subTitle ? (
          <Box margin={{ left: 'spacing24', bottom: 'spacing16' }}>
            <Text size="medium">
              {subTitle}
            </Text>
          </Box>
        ) : null}
        <Box
          style={{ display: expanded ? undefined : 'none' }}
          pad={{ bottom: 'spacing12' }}
          border={{ side: 'bottom' }}
        >
          <Box background="module" pad="spacing24" round="moduleRound">
            {expanded && children}
          </Box>
        </Box>

      </Box>
    </div>
  );
};

export default ModuleWrapper;
