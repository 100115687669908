import _ from 'lodash';

import { ReportItemNestedDataGroup, ReportItemNestedDataRow } from '@/modules/employer/components/Reports/report.types';
import { storeCreator } from '@/utils/store/store.utils';

const mergeReportNestedItemRows = (
  data: ReportItemNestedDataRow[],
  keyMap: { [key: string]: boolean } = {},
): ReportItemNestedDataRow[] => data.map((item) => {
  const key = item?.key;
  if (key && keyMap[key]) {
    return { id: _.uniqueId(), fields: [] } as ReportItemNestedDataRow;
  }
   
  if (key) keyMap[key] = true;
  return item;
});

const mergeReportNestedItemGroups = (
  oldData: ReportItemNestedDataGroup[],
  newData: ReportItemNestedDataGroup[],
  keyMap: { [key: string]: boolean } = {},
): ReportItemNestedDataGroup[] => {
  const filteredOldArray = oldData.filter(({ key }) => key && key !== 'total');
  const data = [...filteredOldArray, ...newData];

  const result = data.map((item) => {
    const key = item.key ?? '';
    if (keyMap[key] && item.children) {
      return {
        ...item,
        title: '',
        children: mergeReportNestedItemGroups(item.children, [], keyMap),
        ...item.rows ? { rows: mergeReportNestedItemRows(item.rows, keyMap) } : {},
      };
    }
    if (keyMap[key]) {
      return { ...item, title: '' };
    }
     
    keyMap[key] = true;
    if (item.children) {
      return {
        ...item,
        children: mergeReportNestedItemGroups(item.children, [], keyMap),
        ...item.rows ? { rows: mergeReportNestedItemRows(item.rows, keyMap) } : {},
      };
    }
    return item;
  });

  const innerKeyMap: { [key: string]: boolean } = {};
  const preparedResult = result.reverse().map((item) => {
    const key = item.key ?? '';
    if (innerKeyMap[key]
      && item.children) {
      return { ...item, rows: [] };
    }
     
    innerKeyMap[key] = true;
    return item;
  }).reverse();

  return preparedResult;
};

interface State {
  groups: ReportItemNestedDataGroup[];
  currentPage: number;
  totalPages: number;
  reportDates: [string | undefined, string | undefined];
  isCurrentLoading: boolean;
  pages: number[];
  handleClear: () => void;
  setReportDates: (reportDates: [string | undefined, string | undefined]) => void;
  setGroups: (groups: ReportItemNestedDataGroup[]) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalPages: (totalPages: number) => void;
  setIsCurrentLoading: (isCurrentLoading: boolean) => void;
  setPages: (page: number) => void;
}

export const useReplenishmentInvoiceReportStore = storeCreator<State>((set) => ({
  groups: [],
  pages: [],
  currentPage: 0,
  totalPages: 0,
  reportDates: [undefined, undefined],
  isCurrentLoading: false,

  setGroups: (groups: ReportItemNestedDataGroup[]) => set((state) => ({
    ...state,
    groups: mergeReportNestedItemGroups(state.groups, groups),
  })),
  setCurrentPage: (currentPage: number) => set((state) => ({
    ...state,
    currentPage,
  })),
  setTotalPages: (totalPages: number) => set((state) => ({
    ...state,
    totalPages,
  })),
  setReportDates: (reportDates: [string | undefined, string | undefined]) => set((state) => ({
    ...state,
    reportDates,
  })),
  setIsCurrentLoading: (isCurrentLoading: boolean) => set((state) => ({
    ...state,
    isCurrentLoading,
  })),
  setPages: (page: number) => set((state) => ({
    ...state,
    pages: [...state.pages, page],
  })),
  handleClear: () => set((state) => ({
    ...state,
    groups: [],
    pages: [],
    currentPage: 0,
    totalPages: 0,
    reportDates: [undefined, undefined],
    isCurrentLoading: false,
  })),
}));
