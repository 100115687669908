import React, { useMemo } from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { ScheduledContributionStatus } from './ScheduledContribution.types';

interface Props {
  status: ScheduledContributionStatus;
}

const ContributionStatus: React.FC<Props> = ({ status }) => {
  const color = useMemo(() => {
    if(status === ScheduledContributionStatus.Active) {
      return 'success';
    }
    if(status === ScheduledContributionStatus.Deactivated) {
      return 'danger';
    }
  
    return 'warning';
  }, [status]);

  const title = useMemo(() => {
    if(status === ScheduledContributionStatus.Active) {
      return 'Active';
    }
    if(status === ScheduledContributionStatus.Suspend) {
      return 'Suspend';
    }
    if(status === ScheduledContributionStatus.Deactivated) {
      return 'Deactivated';
    }
    if(status === ScheduledContributionStatus.Removed) {
      return 'Removed';
    }
    return '';
  }, [status]);
  return (
    <Box data-testId="contribution-status" direction="row" align="center">
      <Box
        data-testId={`contribution-status_${status}`}
        margin={{right: 'xs'}} 
        background={color}
        width="10px"
        height="10px"
        style={{ borderRadius: '50%' }}>
      </Box>
      <Text>{title}</Text>
    </Box>
  );
};

export default ContributionStatus;
