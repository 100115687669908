import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import Permissions from '@/common/permissions';
import { DefaultFields } from '@/common/types';
import { useHasAccess } from '@/modules/core/hooks';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';

import { NEW_PLAN_YEAR_KEY } from '../../CreateHealthPlanModal.constants';
import AddNewPlanTitle from '../AddNewPlanTitle';
import { PlanYearPickerPayload } from '../stores/useHealthPlanYearPicker.store';

const REQUIRED_TEXT = 'This field is required';
export default (defaultFields?: Partial<PlanYearPickerPayload>, disabled?: boolean) => {
  const { list: planYearsList } = usePlanYearsQuery();
  const isNewPlanYearOptionAccessible = useHasAccess([
    { permission: Permissions.PLAN_YEAR_SETUP },
  ]);
  const fields = useMemo<Field[]>(() => {
    const sourceFields = [
      {
        name: 'planYear',
        type: FieldTypes.Dropdown,
        disabled,
        style: { alignItems: 'center' },
        label: 'Plan year',
        singleMode: true,
        showRequireIcon: true,
        options: [...(isNewPlanYearOptionAccessible ? [{
          key: NEW_PLAN_YEAR_KEY,
          primary: true,
          value: 'Add new plan years',
          node: (
            <AddNewPlanTitle
              isNewPlanYearOptionExist={!!planYearsList.length}
            />
          ),
        }] : []), ...planYearsList],
        placeholder: 'Select a plan year',
        validator: yup.string().trim().required(REQUIRED_TEXT),
      },

    ];

    return sourceFields as Field[];
  }, [disabled, planYearsList, isNewPlanYearOptionAccessible]);

  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || item.value || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);
  return formatedFields;
};
