import {
  useCallback, useEffect, useMemo,
  useState, } from 'react';
import { useQueryClient } from 'react-query';
import { FieldError, FieldValues } from '@common-fe/common-fe';
import _ from 'lodash';

import { QUERY_KEY } from '@/modules/ProcessingDefinition/EnrollmentMatrix/queries/useEnrollmentMatrixes.queries';
import { useValidationUtils } from '@/utils/hooks/useValidationUtils';

import { useEnrollmentMatrixFields } from '../CreateEnrollmentMatrixModal/EnrollmentMatrixEntity/hooks';
import { useEnrollmentMatrixEntityStore } from '../CreateEnrollmentMatrixModal/EnrollmentMatrixEntity/stores';
import { useUpdateEnrollmentMatrixQuery } from '../queries';
import { useEnrollmentMatrixStore } from '../stores/useEnrollmentMatrix.store';

interface Params {
  onClose?: () => void;
  id?: string;
  priority?: string;
  perPage?: number;
  notHighlight?: boolean;
}

export default (
  onClose?: () => void,
) => {
  const queryClient = useQueryClient();
  const [enrollmentMatrixUpdatingError, setEnrollmentMatrixUpdatingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [matrixObj, setMatrixObj] = useState<Params>({});
  const { getValidateFields, getErrors } = useValidationUtils();
  const { handleResetNewAddedEnrollment } = useEnrollmentMatrixStore();

  const updateEnrollmentMatrix = useUpdateEnrollmentMatrixQuery(
    matrixObj?.id,
    matrixObj?.perPage,
    matrixObj?.notHighlight,
  );

  const enrollmentMatrixFields = useEnrollmentMatrixFields();
  const enrollmentMatrixEntity = useEnrollmentMatrixEntityStore((state) => state.state);
  const handlePlanTemplateShowError = useEnrollmentMatrixEntityStore((state) => state
    .setShowErrors);

  const enrollmentMatrixValidation = useMemo<FieldValues>(
    () => getValidateFields(enrollmentMatrixFields),
    [getValidateFields, enrollmentMatrixFields],
  );
  const enrollmentMatrixErrors: FieldError = useMemo(
    () => getErrors(enrollmentMatrixEntity, enrollmentMatrixValidation),
    [getErrors, enrollmentMatrixValidation, enrollmentMatrixEntity],
  );

  const handleSave = useCallback(async () => {
    setEnrollmentMatrixUpdatingError(false);
    setLoading(true);
    try {
      await updateEnrollmentMatrix({
        name: enrollmentMatrixEntity.name || undefined,
        priority: matrixObj?.priority
          ? _.toNumber(matrixObj?.priority) : _.toNumber(enrollmentMatrixEntity.priority),
        valid_to: enrollmentMatrixEntity.validTo || null,
      });
      if (onClose) {
        onClose();
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    } catch (e) {
      const message = _.get(e, 'response.data.elevate_error_message', '') as string;
      if (message.includes('Details: EnrollmentMatrix with name')) {
        setEnrollmentMatrixUpdatingError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [
    updateEnrollmentMatrix,
    onClose,
    queryClient,
    matrixObj.priority,
    enrollmentMatrixEntity,
  ]);

  const handlePreSubmit = useCallback((
    id?: string,
    priority?: string,
    perPage?: number,
    notHighlight?: boolean,
    isModalMode?: boolean,
  ) => {
    handleResetNewAddedEnrollment();
    setEnrollmentMatrixUpdatingError(false);
    if (isModalMode) handlePlanTemplateShowError(true);
    if (priority) {
      setMatrixObj({
        id,
        priority,
        perPage,
        notHighlight,
      });
    }
    if (_.isEmpty(enrollmentMatrixErrors)) {
      setEnrollmentMatrixUpdatingError(false);
      setMatrixObj({
        id,
        priority,
        perPage,
        notHighlight,
      });
    }
  }, [enrollmentMatrixErrors, handleResetNewAddedEnrollment, handlePlanTemplateShowError]);

  useEffect(() => {
    if (matrixObj?.id) handleSave();
    setMatrixObj({});
  }, [matrixObj.id, handleSave]);

  return {
    setEnrollmentMatrixUpdatingError,
    enrollmentMatrixUpdatingError,
    isLoading: loading,
    setLoading,
    handleSave,
    handlePreSubmit,
  };
};
