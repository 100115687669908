import React from 'react';
import { Anchor, Box, FlexControlledForm, Inscription } from '@common-fe/common-fe';

import { useDetectActiveTOCModule } from '@/modules/core/hooks';

import { useLinksFields } from './hooks';

const LinksSection = () => {
  const { fields } = useLinksFields();
  const { scrollToSection } = useDetectActiveTOCModule({});
  return (
   
    <FlexControlledForm
      fields={fields}
      editMode={false}
   
      subTitle={(
        <Box direction="row" align="center" margin={{ top: 'spacing8' }}>
          <Inscription color="textBody" lineHeight="20px">These links are configured in</Inscription>
          <Anchor
            margin={{left: 'xxs'}}
            size="medium"
            onClick={() => scrollToSection({module: 'basic_properties', section: 'link_management_section'})} >
            Link Management
          </Anchor>
        </Box>
      )}
      isThinMode
      showError
      wrapperStyle={{ border: 'none' }}
    />

  );
};
export default React.memo(LinksSection);
