import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { api } from '@/api';
import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import PATHS from '@/common/paths';

import { InvoiceStatus } from '../../FinOpsInvoicing/types/invoicing.constants';
import { InfoTableValue, PrefundStatus } from '../finops.types';

export interface PrefundData {
  calculatedAmount?: number;
  currentPrefundHolding?: number;
  dateLastFundReceived?: string;
  retriesExceeded?: boolean;
  prefundHistoryId?: number | null;
}

export interface PrefundResponseDto {
  calculated_amount: number;
  current_prefund_holding: number;
  date_last_fund_received: string;
  retries_exceeded: boolean;
  prefund_history_id?: number | null;
}

export const currentStatus = (status?: InvoiceStatus) => {
  if (status === InvoiceStatus.PAYMENT_REQUESTED) {
    return PrefundStatus.PaymentRequested;
  }
  if (status === InvoiceStatus.PAYMENT_REJECTED) {
    return PrefundStatus.PaymentRejected;
  }
  if (status === InvoiceStatus.PAYMENT_INITIATED) {
    return PrefundStatus.PaymentInitiated;
  }
  if (status === InvoiceStatus.PAID) {
    return PrefundStatus.Paid;
  }
  if (status === InvoiceStatus.PAYMENT_FAILED) {
    return PrefundStatus.PaymentFailed;
  }

  return undefined;
};

const GET_PREFUND_QUERY_KEY = 'GET_PREFUND_QUERY_KEY';

export const useGetPrefundInfo = (invoiceId: string, enabled?: boolean) => {
  const {
    isLoading, isSuccess, data, refetch,
  } = useQuery(
    [GET_PREFUND_QUERY_KEY, invoiceId],
    () => api.get<PrefundResponseDto>(PATHS.ORG_PREFUND_HISTORIES(invoiceId)),
    {
      retry: 0,
      enabled,
    },
  );

  const formattedData: PrefundData = useMemo(() => ({
    calculatedAmount: data?.data.calculated_amount,
    currentPrefundHolding: data?.data.current_prefund_holding,
    dateLastFundReceived: data?.data.date_last_fund_received,
    retriesExceeded: data?.data.retries_exceeded,
    prefundHistoryId: data?.data.prefund_history_id,
  }), [data]);

  const formattedPrefundTable: InfoTableValue[] = useMemo(() => {
    const {
      calculatedAmount,
      currentPrefundHolding,
      dateLastFundReceived,
      prefundHistoryId,
    } = formattedData;

    return [
      ...prefundHistoryId ? [{
        title: 'Calculated amount',
        value: calculatedAmount ? costFormater(calculatedAmount, true) : '-',
      }] : [],
      {
        title: 'Current pre-fund holding',
        value: isNumber(currentPrefundHolding) ? costFormater(currentPrefundHolding, true) : '-',
      },
      ...prefundHistoryId ? [{
        title: 'Date funds received',
        value: dateLastFundReceived ? dayjs(dateLastFundReceived).format(DEFAULT_DATE_FORMAT) : '-',
      }] : [],
    ];
  }, [formattedData]);

  const isRetriesExceeded = useMemo(() => formattedData.retriesExceeded, [formattedData]);
  const isPrefundExist = useMemo(() => data?.data, [data]);

  return ({
    data,
    formattedData,
    formattedPrefundTable,
    isRetriesExceeded,
    isLoading,
    isSuccess,
    isPrefundExist,
    refetch,
  });
};
