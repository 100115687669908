import React, { useCallback, useEffect, useState } from 'react';
import { Box, DoubleChevroneUpIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useHistory } from '@/modules/core/hooks';
import globalTheme from '@/styles/theme';

import useAccordionStore from './useAccordion.store';

const ExpandCollapseButton = styled(Box)`
  min-height: 20px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary};
  gap: 5px;
  box-shadow: none;
  svg {
    width: 22px;
  }
`;

export const Accordion: React.FC = () => {
  const [expandedAll, setExpandedAll] = useState(true);
  const {
    list, handleToggleExpand, setOriginPath, originPath, resetExpanders,
  } = useAccordionStore();
  const history = useHistory();

  const handleExpandCollapse = useCallback(() => {
    setExpandedAll(!expandedAll);
    handleToggleExpand(!expandedAll);
  }, [expandedAll, handleToggleExpand]);

  useEffect(() => {
    if (originPath !== history.location.pathname) {
      resetExpanders();
      setOriginPath(history.location.pathname);
    }
  }, [history.location.pathname, originPath, resetExpanders, setOriginPath]);

  useEffect(() => {
    if (list.some(({ isExpanded }) => isExpanded === false)) {
      setExpandedAll(false);
    } else if (list.every(({ isExpanded }) => isExpanded === true)) {
      setExpandedAll(true);
    }
  }, [list]);

  return (
    <Box
      data-testid="Accordion"
      margin={{ vertical: 's' }}
      justify="start"
      direction="row"
      style={{ minHeight: '20px' }}
      alignSelf="center"
      width={globalTheme.defaultContentWidth}
    >
      <ExpandCollapseButton
        data-testid="Accordion-button"
        direction="row"
        align="center"
        onClick={handleExpandCollapse}
      >
        <DoubleChevroneUpIcon
          size="12px"
          color="textSecondary"
          {...expandedAll ? { style: { transform: 'rotate(180deg)' } } : {}}
        />
        {!expandedAll ? 'Expand All Modules' : 'Collapse All Modules'}
      </ExpandCollapseButton>
    </Box>
  );
};
