import { useOrganizationFeatureFlag } from '../core/hooks';

const MOCKED_IDS = [3, 8530, 8556, 8893, 8767, 10020];
const useContributionFeatureFlag = () => {
  const isOnlyForDevAndUATContributions = useOrganizationFeatureFlag({
    dev: true,
    uat: true
    // ID = 3 It's a mocked ID
  }, MOCKED_IDS);
  
  return isOnlyForDevAndUATContributions;
};

export default useContributionFeatureFlag;