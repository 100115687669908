import React from 'react';
import { Box } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { PendingModal } from '@/components/elements';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  onSubmit: () => void;
  header?: string;
  submitButtonTitle?: string;
}
const ConfirmCancelModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  onSubmit,
  header,
  submitButtonTitle,
}) => {
  const handleSubmit = () => {
    onSubmit();
    onSetVisible(false);
  };
  return (
    <PendingModal
      header="Cancel reimbursement?"
      visible={visible}
      onSetVisible={onSetVisible}
      onSubmit={onSubmit}
    >
      <Box direction="row" margin={{ top: 'spacing24' }} gap="spacing8">
        <AppButton
          onClick={() => onSetVisible(false)}
          buttonType="secondary"
          width="130px"
        >
          Cancel
        </AppButton>
        <AppButton
          onClick={handleSubmit}
          color="red"
        >
          {submitButtonTitle || 'Yes, Cancel Reimbursement'}
        </AppButton>
      </Box>
    </PendingModal>
  );
};

export default ConfirmCancelModal;
