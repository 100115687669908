import React, { useMemo, useState } from 'react';
import {
  Box,   CalendarPickerIcon, DateRangeDropdown,
  Inscription, PaginationBar,
  Preloader,
  SelectDropdown, } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ALL_OPTION } from '@/common/constants';
import { OptionKey } from '@/common/types';
import { useAllOptionsToggle, useCurrentOrganization } from '@/modules/core/hooks';
import { useGetOrganizationPlansQuery } from '@/modules/employee/Employee/queries/useGetOrganizationPlans.query';
import { useListPagination } from '@/modules/employer/hooks';
import theme from '@/styles/theme';

import { ContributionSource } from '../contributions.types';

import { useGetContributionsList } from './hooks/useGetContributionsList';
import ContributionListItem from './ContributionListItem';
import ContributionsListEmptyPlaceholder from './ContributionsListEmptyPlaceholder';

export const CONTRIBUTIONS_TABLE_HEADERS = [
  {
    title: 'Submit date',
    width: '18%',
    style: { paddingLeft: '36px' },
  },
  {
    title: 'Source',
    width: '14%',
  },
  {
    title: 'Accounts',
    width: '23%',
  },
  {
    title: 'Status',
    width: '20%',
  },
  {
    title: 'Contribution activity',
    width: '27%',
  },
];

const initPage = 1;
const defaultPerPage = 10;



interface CalendarWrapperProps {
  disabled?: boolean;
}

const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  background: ${({ theme, disabled }) => disabled ? undefined : theme.colors.canvas};
  top: 8px;
  right: 13px;
  font-size: 18px;
  margin-bottom: ${({ disabled }) => (disabled ? undefined : '3px')};
  svg {
    fill: ${({ theme, disabled }) => disabled ? theme.colors.iconSecondary : theme.colors.background1};
  }
`;

const InputWrapper = styled(Box)<CalendarWrapperProps>`
  svg {
    fill: ${({ theme, disabled }) => disabled && theme.colors.iconSecondary};
  }
`;

const FileTitle = 'File';
const SOURCE_OPTIONS = [
  ALL_OPTION,
  {
    key: ContributionSource.UI,
    value: ContributionSource.UI,
    title: ContributionSource.UI,
  },
  {
    key: ContributionSource.API,
    value: ContributionSource.API,
    title: ContributionSource.API,
  },
  {
    key: ContributionSource.FILE,
    value: FileTitle,
    title: FileTitle,
  },
  {
    key: ContributionSource.SCHEDULED,
    value: 'Scheduled',
    title: 'Scheduled'
  },
];

const ACTIVE_PLAN_STATUS = 'ACTIVE';

const ContributionsList = () => {
  const [selectedAccounts, setSelectedAccounts] = useState<OptionKey[]>([ALL_OPTION.value]);
  const [selectedSource, setSelectedSource] = useState<OptionKey[]>([ALL_OPTION.value]);
  const accountsValues = useAllOptionsToggle(selectedAccounts);
  const sourceValues = useAllOptionsToggle(selectedSource);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([]);
  const { observingOrganization: { id: orgId }} = useCurrentOrganization();
  const {
    page,
    perPage,
    setPerPage,
    setPage,
  } = useListPagination(defaultPerPage);

  const {
    isLoading,
    totalElements,
    formattedList,
    isEmpty,
  } = useGetContributionsList(
    {
      page: page - 1,
      perPage,
      startDate: dateRange[0],
      endDate: dateRange[1],
      accounts: accountsValues[0] === ALL_OPTION.value ? undefined : selectedAccounts.filter((account) => account !== ALL_OPTION.value) as OptionKey[],
      sources: sourceValues[0] === ALL_OPTION.value ? undefined : selectedSource.filter((source) => source !== ALL_OPTION.value) as OptionKey[],
    },
  );

  const { options: accountsOptions } = useGetOrganizationPlansQuery(orgId, ACTIVE_PLAN_STATUS);



  const isFiltersEmpty = useMemo(() => {
    const isDateRangeEmpty = !dateRange[0] && !dateRange[1];
    const isAccountsEmpty = accountsValues.length === 1 && accountsValues[0] === ALL_OPTION.value;
    const isSourceEmpty = sourceValues.length === 1 && sourceValues[0] === ALL_OPTION.value;

    return isDateRangeEmpty && isAccountsEmpty && isSourceEmpty;
  }, [dateRange, accountsValues, sourceValues]);

  return (
    <Box
      background={{ color: 'module' }}
      round="container1Round"
      pad="spacing24"
      data-testid="ContributionsList_container"
    >
      <Box align="center">
        <Box
          width="100%"
          direction="row"
          justify="between"
          align="center"
        >
          <Inscription
            weight="bold"
            color="textBody"
            style={{ whiteSpace: 'nowrap' }}
          >
            Contribution records: {totalElements || 0}
          </Inscription>
          <Box direction="row" align="center" height="40px">
            <InputWrapper
              margin={{ left: 'spacing12' }}
              width={{ min: '160px', max: '160px' }}
              disabled={isEmpty && isFiltersEmpty}
            >
              <SelectDropdown
                id="accounts"
                testId="accounts"
                activeTitle
                ellipsisMode
                name="Accounts"
                prefix="Accounts"
                disabled={isEmpty && isFiltersEmpty}
                options={accountsOptions}
                values={accountsValues}
                onChangeValues={(val: OptionKey[]) => {
                  setSelectedAccounts(val);
                  setPage(initPage);
                }}
              />
            </InputWrapper>
            <Box margin={{ left: 'spacing12' }} width={{ min: 'control' }}>
              <DateRangeDropdown
                prefix="Period:"
                disabled={isEmpty && isFiltersEmpty}
                onChange={(range) => {
                  setDateRange(range);
                  setPage(initPage);
                }}
                ellipsisMode
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                icon={(
                  <CalendarWrapper disabled={isEmpty && isFiltersEmpty}>
                    <CalendarPickerIcon />
                  </CalendarWrapper>
                )}
              />
            </Box>
            <InputWrapper
              margin={{ left: 'spacing12' }}
              width={{ min: '160px', max: '160px' }}
              disabled={isEmpty && isFiltersEmpty}
            >
              <SelectDropdown
                id="source"
                testId="source"
                disabled={isEmpty && isFiltersEmpty}
                activeTitle
                ellipsisMode
                name="Source"
                prefix="Source"
                options={SOURCE_OPTIONS}
                values={sourceValues}
                onChangeValues={(val: OptionKey[]) => {
                  setSelectedSource(val);
                  setPage(initPage);
                }}
              />
            </InputWrapper>
          </Box>
        </Box>
        <Box
          margin={{ top: 'spacing12', bottom: 'spacing8' }}
          pad={{ horizontal: 'spacing24', vertical: '7px' }}
          background="legend"
          round="8px"
          width="100%"
          height="36px"
          direction="column"
          justify="center"
        >
          <Box
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              lineHeight: '18px',
              color: theme.colors.textBody,
            }}
            direction="row"
            height="18px"
            margin={{ right: 'spacing24' }}
          >
            {CONTRIBUTIONS_TABLE_HEADERS.map((item) => <Box key={item.title} width={item.width} {...item.style ? { style: item.style } : {}}>{item.title}</Box>)}
          </Box>
        </Box>
        {isLoading ? (
          <Box height="62px" justify="center">
            <Preloader />
          </Box>
        ) : (
          <Box width="100%" gap="spacing8">
            {formattedList.map((item) => (
              <ContributionListItem key={item.contributionId} data={item} />
            ))}
          </Box>
        )}
        {!isEmpty ? (
          <PaginationBar
            page={page}
            total={totalElements || 0}
            pageSize={perPage}
            onChangePage={setPage}
            onChangePageSize={setPerPage}
          />
        ) : (
          <Box height="fit-content">
            {
              isLoading ? null : <ContributionsListEmptyPlaceholder />
            }
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContributionsList;
