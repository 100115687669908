import { PriorPlanYear } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface PlanYearFormPayload {
  name: string;
  startDate: string;
  endDate: string;
  payoutDefinition: string;
  copayGroup?: string | null;
  priorPlanYearId?: string;
  priorPlanYear?: PriorPlanYear;
}

export const DEFAULT_VALUES: PlanYearFormPayload = {
  name: '',
  startDate: '',
  endDate: '',
  payoutDefinition: '',
  copayGroup: '',
  priorPlanYearId: '',
  priorPlanYear: undefined,
};

export const useStore = baseStoreCreator<PlanYearFormPayload>(DEFAULT_VALUES);

export default useStore;
