import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum } from '@common-fe/common-fe';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import {
  HealthPlan,
  HealthPlanFieldState,
  HealthPlanResponse,
  HealthPlanStatuses,
} from '@/modules/HealthPlan/HealthPlan.types';

// import { useIsOrganizationType } from '@/modules/user/hooks';

export const getTemplatesList = (
  status?: HealthPlanStatuses,
  path?: string,
  orgId?: string,
  page?: number,
  name?: string,
  id?: string,
) => () => api.get<HealthPlanResponse[]>(
  `${PATHS.HEALTH_PLAN_TEMPLATES}`, {
    params: {
      organization_id: status !== HealthPlanStatuses.ACTIVE ? orgId : undefined,
      organization_path: status === HealthPlanStatuses.ACTIVE && path ? path : undefined,
      plan_status: status,
      name,
      id,
      size: 10000,
    },
  },
);

export const HEALTH_TEMPLATES_LIST_QUERY_KEY = 'getHealthTemplatesList';
type ExpandedOption = Option & {
  accountTypes?: string[];
  accountTypeState?: HealthPlanFieldState;
  name?: string;
  nameState?: HealthPlanFieldState;
};

interface Params {
  status?: HealthPlanStatuses;
  refetchOnMount?: boolean;
  activePage?: number;
  searchTemplateString?: string;
  id?: string;
}
export const useHealthTemplatesListQuery = (
  params: Params,
) => {
  const { observingOrganization: { path } } = useCurrentOrganization();
  const { organizationId } = useCurrentOrganisationStore();
  const currentId = useMemo(() => organizationId || '0', [organizationId]);
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [HEALTH_TEMPLATES_LIST_QUERY_KEY, params.status, path,
      params.activePage, params.searchTemplateString, params.id],
    getTemplatesList(
      params.status || HealthPlanStatuses.DRAFT,
      path,
      currentId,
      params.activePage,
      params.searchTemplateString,
      params.id,
    ),
    {
      refetchOnMount: params.refetchOnMount,
      enabled: params.status === HealthPlanStatuses.ACTIVE ? !!path : true,
    },
  );

  const formattedTemplatesOptions: ExpandedOption[] = useMemo(() => {
    const list = data?.data || [];

    return list?.map((item) => ({
      key: `${item.id}`,
      value: item.name.name,
      title: item.name.name,
      accountTypes: item?.health_plan_types?.health_plan_types,
      accountTypeState: item?.health_plan_types?.health_plan_type_state,
      nameState: item?.name?.name_state,
    }));
  }, [data]);

  const formattedTemplates: HealthPlan[] = useMemo(() => {
    const list = data?.data || [];

    return list.map((item) => ({
      id: `${item.id}`,
      name: item.name.name,
      nameState: item.name.name_state as HealthPlanFieldState,
      types: item?.health_plan_types?.health_plan_types as CardTypesEnum[],
      typeState: item?.health_plan_types?.health_plan_type_state as HealthPlanFieldState,
      status: item.plan_status as HealthPlanStatuses,
      planYearId: item.plan_year?.id || '',
      planYearName: item.plan_year?.name || '',
    }));
  }, [data]);


  return {
    data: formattedTemplates,
    error,
    isError,
    isLoading,
    isSuccess,
    options: formattedTemplatesOptions,
    refetch,
  };
};
export default useHealthTemplatesListQuery;
