import React from 'react';
import { Box, Preloader, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  ReportItemNestedData,
} from '@/modules/employer/components/Reports/report.types';

import { ReportItemContent } from './ReportItemContent';

interface StyledBoxProps {
  withoutUnderline?: boolean;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  .deep-nested-title {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.border};
      left: -10px;
      top: 9px;
      z-index: 1;
    }
  }
  .deep-nested-row {
    position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: calc(100% + 12px);
        background-color: ${({ theme }) => theme.colors.border};
        left: 0;
        top: 0;
      }
      &:last-child {
        ${({ withoutUnderline, theme }) => !withoutUnderline && `
        &:before {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% + 12px);;
        height: 2px;
        background-color: ${theme.colors.border};
        left: -12px;
        bottom: -12px;
      }`}
    }
  }
`;

interface Props {
  data: ReportItemNestedData;
  expand?: boolean;
  subItem?: boolean;
  isEmpty?: boolean;
  isSubLoading?: boolean;
  hasPages?: boolean;
  isLastPage?: boolean;
}

export const ReportItemNestedContent: React.FC<Props> = ({
  data,
  expand,
  subItem,
  isEmpty,
  isSubLoading,
  hasPages,
  isLastPage,
}) => (
  <Box height="fit-content" style={{ display: 'block' }}>
    {isEmpty ? (
      <Box>
        <Text size="12px" weight={500}>
          No results have been found.
        </Text>
      </Box>
    ) : (
      data.groups.map((group, index) => (
        <StyledBox
          key={group.id || group.title}
          height="fit-content"
          margin={{ bottom: data.groups[index + 1] && !data.groups[index + 1].title ? undefined : 'spacing8' }}
          pad={{ left: subItem ? 'spacing8' : '0' }}
          className={group.hierarchy ? 'deep-nested-row' : ''}
          withoutUnderline={data.groups[index + 1] && !data.groups[index + 1].title}
        >
          {group.title
          && (
            <StyledBox
              height={{ min: '20px' }}
              margin={{ bottom: 'spacing12' }}
              className={group.hierarchy ? 'deep-nested-title' : ''}
            >
              <Text size="14px" weight="bold" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {group.title}
              </Text>
            </StyledBox>
          )}
          {group.data && group.data.length ? (
            <StyledBox>
              <ReportItemContent
                reportData={group.data && group.data.length ? group.data : []}
                expand={expand}
                styles={group.wrapStyle}
              />
            </StyledBox>
          ) : null}
          {
            group.children && group.children.length ? (
              <ReportItemNestedContent
                data={{
                  groups: group.children,
                }}
                expand={expand}
                subItem
              />
            ) : null
          }
        </StyledBox>
      )))}
    {hasPages && (
      <>
        {isSubLoading ? (
          <Box
            direction="row"
            justify="center"
            align="center"
            pad={{ vertical: 'spacing8' }}
          >
            <Preloader />
          </Box>
        ) : (
          <>
            {!isLastPage && (
              <Box pad="spacing12" justify="center" align="center">
                <Text size="12px" color="textDisabled">
                  Scroll to load more
                </Text>
              </Box>
            )}
          </>
        )}
      </>
    )}

  </Box>
);
