import { useCallback } from 'react';

import { useSnackbar } from '@/modules/core/hooks';

import { CoverageLevelPayload } from '../CoverageLevels.constants';
import useCoverageLevelsQuery, { FormatedCoverage } from '../queries/useCoverageLevels.query';
import { useCoverageLevelsStore } from '../stores';

export default () => {
  const coverageState = useCoverageLevelsStore((state) => state.state);
  const coverageSourceState = useCoverageLevelsStore((state) => state.sourceState);
  const coverageSetState = useCoverageLevelsStore((state) => state.setState);
  const coverageSetSourceState = useCoverageLevelsStore((state) => state.setSourceState);
  const { handleAddPermanentSnackbar } = useSnackbar();
  const { data: coverages } = useCoverageLevelsQuery();
  const handleSave = useCallback((ids: number[]) => {
    const filtered = ids.reduce(
      (result: CoverageLevelPayload[], id) => {
        if (coverages) {
          const foundItem: FormatedCoverage | undefined = coverages
            .find((coverage) => coverage.id === `${id}`);
          if (foundItem) {
            return [...result, {
              id: '',
              coverageId: foundItem.id,
              title: foundItem.name,
              coverageCode: foundItem.code,
              isDeletable: true,
              isHighlighted: true,
            }];
          }
        }
        return result;
      }, [],
    );

    coverageSetState([...coverageState, ...filtered]);
    coverageSetSourceState([...coverageSourceState, ...filtered]);
    handleAddPermanentSnackbar({
      text: 'Coverage Level is added',
      closeIcon: true,
    });
  }, [coverageSetSourceState, coverageSetState, handleAddPermanentSnackbar,
    coverageSourceState, coverageState, coverages]);
  return handleSave;
};
