import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, EMPTY_FIELD_VALUE } from '@/common/constants';
import { Lockbox } from '@/modules/employer/components/Lockbox/lockbox.types';
import { LockboxAmountInput } from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxForm/components';

interface Props {
  lockbox?: Lockbox;
}

const useLockboxInboundTOAFields = ({ lockbox }: Props) => {
  const lockboxInboundTOAFields: Field[] = useMemo(() => [
    {
      type: FieldTypes.Text,
      name: 'employeeName',
      label: 'Employee name',
      value: lockbox?.ocr?.employeeName || EMPTY_FIELD_VALUE,
    },
    {
      type: FieldTypes.Text,
      name: 'ssn',
      label: 'SSN',
      value: lockbox?.ocr?.ssn || EMPTY_FIELD_VALUE,
    },
    {
      type: FieldTypes.Text,
      name: 'hsaAccountNumber',
      label: 'HSA account number',
      value: lockbox?.ocr?.accountNumber || EMPTY_FIELD_VALUE,
    },
    {
      type: FieldTypes.Text,
      name: 'dateOfBirth',
      label: 'Date of birth',
      value: lockbox?.ocr?.birthDate
        ? dayjs(lockbox?.ocr?.birthDate).format(DEFAULT_DATE_FORMAT) 
        : EMPTY_FIELD_VALUE,
    },
    {
      type: FieldTypes.Node,
      name: 'amount',
      label: 'Amount',
      value: <LockboxAmountInput />,
    },
  ], [lockbox]);

  return {
    lockboxInboundTOAFields,
  };
};

export default useLockboxInboundTOAFields;
