import React from 'react';
import { WarnModal } from '@common-fe/common-fe';

const ErrorBlock = () => (
  <WarnModal
    visible
    onSetVisible={() => {
      window.history.back();
      window.history.back();
    }}
    header="Something went wrong"
    helptext="Some technical issues occur during login. Check your credentials and try again."
    buttonText="Back"

  />
);

export default ErrorBlock;
