import React, { useCallback, useState } from 'react';
import {
  AppButton,
  Box,
  FlexControlledForm,
  Preloader,
} from '@common-fe/common-fe';
import styled from 'styled-components';
import * as yup from 'yup';

import { useGetPatientsQuery } from '@/modules/ReimburseMe/ClaimInfo/usePatients.query';
import colors from '@/styles/colors';

import { Dependent } from '../../employee.types';
import { useGeneralInfoStore } from '../PersonalInformation/GeneralInfo/stores';
import { useCreateDependentQuery } from '../queries/useCreateDependent.query';

import { useAddDependentFields } from './hooks';

const StyledBox = styled(Box)`
  ${({ theme }) => theme.border.blueGray2}
  ${({ theme }) => theme.shadows.default}
`;

const BUTTON_WIDTH = '140px';

interface Props {
  onCancel: () => void;
}

export const AddDependentForm: React.FC<Props> = ({ onCancel }) => {
  const { personId } = useGeneralInfoStore((state) => state.state);
  const { refetch: refetchDependents } = useGetPatientsQuery(personId);
  const [formValues, setFormValues] = useState({});
  const [activeValues, setActiveValues] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const handleSuccess = useCallback(() => {
    onCancel();
    refetchDependents();
  }, [onCancel, refetchDependents]);

  const { save, isLoading } = useCreateDependentQuery(personId as string, handleSuccess);
  const fields = useAddDependentFields();

  const handleChangeValues = useCallback((values: object) => {
    setFormValues((prev) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      setActiveValues(true);
    } catch {
      setActiveValues(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = useCallback(async () => {
    setSubmit(true);
    if (activeValues) {
      await save(formValues as Dependent);
    }
  }, [activeValues, save, formValues]);

  return (
    <>
      <Box
        pad="spacing24"
        round="moduleRound"
        background={{ color: colors.module }}
      >
        <StyledBox
          pad={{ bottom: 'spacing16' }}
          round="container1Round"
          background={{ color: colors.canvas }}
        >
          <FlexControlledForm
            fields={fields}
            editMode
            testId="add-dependent-form"
            showError={isSubmit}
            onChangeValues={handleChangeValues}
          />
        </StyledBox>
      </Box>
      <Box direction="row" justify="end" align="center" pad={{ top: 'medium' }} gap="spacing12">
        <AppButton
          testId="add-dependent-form_cancel"
          buttonType="secondary"
          onClick={onCancel}
          width={BUTTON_WIDTH}
        >
          Cancel
        </AppButton>
        <AppButton
          testId="add-dependent-form_submit"
          onClick={handleSave}
          width={BUTTON_WIDTH}
        >
          {isLoading ? <Preloader testId="add-dependent-form_submit_loading" color={colors.iconOnColor} /> : 'Save Dependent'}
        </AppButton>
      </Box>
    </>
  );
};
