import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { OrganizationTypes } from '@/common/constants';
import ROUTES from '@/common/routes';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import { getTokenData } from '@/modules/user/hooks/useAuth';
import { createPathFromIds, getIdsFromPath } from '@/utils';

export const QueryKeys = {
  OBSERVING_EMPLOYER_QUERY_KEY: 'observingEmployerId',
  OBSERVING_PARTNER_QUERY_KEY: 'observingPartnerId',
  OBSERVING_DISTRIBUTOR_QUERY_KEY: 'observingDistributorId',
  OBSERVING_SUBSIDIARY_QUERY_KEY: 'observingSubsidiaryId',
  SHOULD_OPEN_MODAL: 'shouldOpenModal',
  EDIT_MODE: 'editMode',
  EMPTY_CLAIMS_LIST: 'emptyClaimList',
  TRANSACTIONS_CLOSE_DATE: 'transactionsCloseDate',
  RECONCILIATION_REPORTS_DATE: 'reconciliationReportsDate',
  RECONCILIATION_REPORTS_ACTIVITY_DATE: 'reconciliationReportActivityDate',
  SEARCH: 'search',
  HASH: 'hash',
} as const;
export type QueryKeysUnionType = typeof QueryKeys[keyof typeof QueryKeys];
export const QUERY_KEY = '?';

export const getObservedOrganizationIdsFromURLSearchParams = () => {
  const { organizationPath } = getTokenData();
  const { systemId } = getIdsFromPath(organizationPath);
  const query = new URLSearchParams(location.search);

  const partnerId = query.get(QueryKeys.OBSERVING_PARTNER_QUERY_KEY) || undefined;
  const distributorId = query.get(QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY) || undefined;
  const employerId = query.get(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY) || undefined;
  const subsidiaryId = query.get(QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY) || undefined;
  
  const observedOrganizationPath = partnerId ? createPathFromIds({
    systemId,
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
  }) : undefined;

  return {
    systemId,
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
    observedOrganizationPath,
  };
};

export default () => {
  const {
    handleSetObservingEmployerId,
    handleSetObservingPartnerId,
    handleSetObservingDistributorId,
    handleSetObservingSubsidiaryId,
  } = useCurrentOrganisationStore();
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryMap = useMemo(() => ({
    [QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]: query.get(QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY),
    [QueryKeys.OBSERVING_PARTNER_QUERY_KEY]: query.get(QueryKeys.OBSERVING_PARTNER_QUERY_KEY),
    [QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]: query.get(
      QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY,
    ),
    [QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY]: query.get(
      QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY,
    ),
    [QueryKeys.SHOULD_OPEN_MODAL]: query.get(QueryKeys.SHOULD_OPEN_MODAL),
    [QueryKeys.EMPTY_CLAIMS_LIST]: query.get(QueryKeys.EMPTY_CLAIMS_LIST),
    [QueryKeys.TRANSACTIONS_CLOSE_DATE]: query.get(QueryKeys.TRANSACTIONS_CLOSE_DATE),
    [QueryKeys.RECONCILIATION_REPORTS_DATE]: query.get(QueryKeys.RECONCILIATION_REPORTS_DATE),
    [QueryKeys.RECONCILIATION_REPORTS_ACTIVITY_DATE]: query.get(
      QueryKeys.RECONCILIATION_REPORTS_ACTIVITY_DATE,
    ),
  }), [query]);

  const searchQuery = useMemo(
    () => `${history.location.search || ''}`,
    [history.location.search],
  );
  const hash = useMemo(() => `${history.location.hash || ''}`.replace('#', ''), [history.location.hash]);

  useEffect(() => {
    if (queryMap[QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]) {
      handleSetObservingEmployerId(queryMap[QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]);
    } else {
      handleSetObservingEmployerId(null);
    }
    if (queryMap[QueryKeys.OBSERVING_PARTNER_QUERY_KEY]) {
      handleSetObservingPartnerId(queryMap[QueryKeys.OBSERVING_PARTNER_QUERY_KEY]);
    } else {
      handleSetObservingPartnerId(null);
    }
    if (queryMap[QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]) {
      handleSetObservingDistributorId(queryMap[QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]);
    } else {
      handleSetObservingDistributorId(null);
    }
    if (queryMap[QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY]) {
      handleSetObservingSubsidiaryId(queryMap[QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY]);
    } else {
      handleSetObservingSubsidiaryId(null);
    }
  }, [
    handleSetObservingEmployerId,
    handleSetObservingPartnerId,
    handleSetObservingDistributorId,
    handleSetObservingSubsidiaryId,
    queryMap,
  ]);

  const getPath = useCallback((
    path: string,
    additionalQueries?: { [ key in QueryKeysUnionType ]?: string | boolean },
  ) => {
    if (additionalQueries) {
      Object.keys(additionalQueries).forEach((additionalQueryKey) => {
        if (additionalQueryKey === QueryKeys.HASH) {
          return;
        }
        const queryValue = additionalQueries[additionalQueryKey as QueryKeysUnionType];
        if (
          !query.get(additionalQueryKey)
          && queryValue
        ) {
          query.append(additionalQueryKey, `${queryValue}`);
        }

        if (
          query.get(additionalQueryKey)
          && queryValue === undefined
        ) {
          query.delete(additionalQueryKey);
        }

        if (
          query.get(additionalQueryKey)
          && query.get(additionalQueryKey) !== queryValue
        ) {
          query.set(additionalQueryKey, `${queryValue}`);
        }
      });
    }
    const currenthash = additionalQueries?.[QueryKeys.HASH];

    const queryStr = query.toString();
    return `${path}${queryStr ? `?${queryStr}` : ''}${currenthash ? `#${currenthash}` : ''}`;
  }, [query]);

  const push = useCallback((
    path: string,
    additionalQueries?: { [ key in QueryKeysUnionType ]?: string | boolean },
  ) => history.push(getPath(path, additionalQueries)), [getPath, history]);

  const getObservingMode = useCallback((type: OrganizationTypes, id: string) => {
    switch (type) {
    case OrganizationTypes.company: {
      return `${ROUTES.HOME}?${QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY}=${id}`;
    }
    case OrganizationTypes.partner: {
      return `${ROUTES.HOME}?${QueryKeys.OBSERVING_PARTNER_QUERY_KEY}=${id}`;
    }
    case OrganizationTypes.distributor: {
      return `${ROUTES.HOME}?${QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY}=${id}`;
    }
    case OrganizationTypes.subsidiary: {
      return `${ROUTES.HOME}?${QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY}=${id}`;
    }
    default: {
      return '';
    }
    }
  }, []);
  const redirectToObservingMode = useCallback((type: OrganizationTypes, id: string) => {
    switch (type) {
    case OrganizationTypes.company: {
      push(ROUTES.HOME, { [QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]: id });
      break;
    }
    case OrganizationTypes.partner: {
      push(ROUTES.HOME, { [QueryKeys.OBSERVING_PARTNER_QUERY_KEY]: id });
      break;
    }
    case OrganizationTypes.distributor: {
      push(ROUTES.HOME, { [QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]: id });
      break;
    }
    case OrganizationTypes.subsidiary: {
      push(ROUTES.HOME, { [QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY]: id });
      break;
    }
    default: {
      break;
    }
    }
  }, [push]);

  const removeQueryParameter = useCallback((keys: QueryKeysUnionType[]) => {
    keys.forEach((key) => {
      query.delete(key);
    });
    history.push(`${location.pathname}?${query.toString()}`);
  }, [history, location.pathname, query]);

  const deleteQueryParameter = useCallback((keys: QueryKeysUnionType[]) => {
    keys.forEach((key) => {
      query.delete(key);
    });
    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  const replaceQueryParameter = useCallback((
    queries: { [ key in QueryKeysUnionType ]?: string | boolean },
  ) => {
    Object.keys(queries).forEach((key) => {
      const value = `${queries[key as QueryKeysUnionType]}`;
      if (query.get(key) && value) query.set(key, value);
      if (!query.get(key) && value) query.append(key, value);
      if (query.get(key) && !value) query.delete(key);
    });
    history.push(`${location.pathname}?${query.toString()}`);
  }, [history, location.pathname, query]);

  const observingSearchQuery = useMemo(() => {
    const observingSearchQueryMap = {
      [QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY]:
          queryMap[QueryKeys.OBSERVING_EMPLOYER_QUERY_KEY],
      [QueryKeys.OBSERVING_PARTNER_QUERY_KEY]:
          queryMap[QueryKeys.OBSERVING_PARTNER_QUERY_KEY],
      [QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY]:
          queryMap[QueryKeys.OBSERVING_DISTRIBUTOR_QUERY_KEY],
      [QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY]:
          queryMap[QueryKeys.OBSERVING_SUBSIDIARY_QUERY_KEY],
    };

    const params = new URLSearchParams();
    Object.entries(observingSearchQueryMap).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      }
    });

    return params.toString();
  }, [queryMap]);

  return useMemo(() => ({
    ...history,
    push,
    originPush: history.push,
    redirectToObservingMode,
    queryMap,
    query,
    removeQueryParameter,
    deleteQueryParameter,
    getObservingMode,
    getPath,
    replaceQueryParameter,
    searchQuery,
    observingSearchQuery,
    hash,
  }), [
    history,
    push,
    getObservingMode,
    redirectToObservingMode,
    queryMap,
    query,
    hash,
    removeQueryParameter,
    deleteQueryParameter,
    getPath,
    replaceQueryParameter,
    searchQuery,
    observingSearchQuery,
  ]);
};
