import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dayjs from 'dayjs';

import { CardHolderDto } from '@/modules/transaction/Cards/Cards.types';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';

import { DependentFormValue, DependentInfoDto } from '../queries/useAddDependentCard.query';

export const useSetDependent = (dependentInfo: DependentFormValue | CardHolderDto | null) => {
  const { params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { response } = useGetCardsQuery(employeeId);

  const preparedDependent = useMemo(() => ({
    id: dependentInfo?.dependentId,
    first_name: dependentInfo?.firstName,
    last_name: dependentInfo?.lastName,
    birth_on: dayjs(dependentInfo?.dateOfBirth || '').toDate(),
    person: {
      id: response?.person_id,
      preferred_name: response?.preferred_name,
      first_name: response?.first_name,
      last_name: response?.last_name,
    },
    relationship_type: dependentInfo?.relationship?.toUpperCase() || 'OTHER',
  } as DependentInfoDto), [dependentInfo, response]);

  return preparedDependent;
};
