import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { CardHolderDto } from '@/modules/transaction/Cards/Cards.types';
import { Relationship } from '@/modules/transaction/transaction.types';

interface Params {
  data?: CardHolderDto | null;
  createMode: boolean;
  relationshipList: Relationship[]
}

export const useCardDependentForm = (params: Params) => useMemo((): Field[] => [
  {
    name: 'firstName',
    type: FieldTypes.Text,
    label: 'First name',
    placeholder: 'First name',
    validator: yup.string().required(REQUIRED_TEXT),
    defaultValue: params.data?.firstName || '',
    value: params.data?.firstName || '',
  },
  {
    name: 'lastName',
    type: FieldTypes.Text,
    label: 'Last name',
    placeholder: 'Last name',
    validator: yup.string().required(REQUIRED_TEXT),
    defaultValue: params.data?.lastName || '',
    value: params.data?.lastName || '',
  },
  ...params.createMode ? [
    // {
    //   name: 'email',
    //   type: FieldTypes.Text,
    //   label: 'Email',
    //   placeholder: 'Email',
    //   validator: yup.string().required(REQUIRED_TEXT).email('Email must be valid.'),
    //   defaultValue: params.data?.email,
    // },
    {
      name: 'dateOfBirth',
      type: FieldTypes.Date,
      isManualDateInput: true,
      label: 'Date of birth',
      placeholder: 'Select birth date',
      validator: VALIDATORS.DATE_FORMAT_MAX_TODAY.required(REQUIRED_TEXT),
      defaultValue: params.data?.dateOfBirth || '',
      value: params.data?.dateOfBirth || '',
      maxDate: dayjs(dayjs().add(0, 'day')),
    },
  ] as Field[] : [
    {
      name: 'dateOfBirth',
      type: FieldTypes.Text,
      label: 'Date of birth',
      defaultValue: `
      ${dayjs(params.data?.dateOfBirth).format(DEFAULT_DATE_FORMAT)}
       (${dayjs(params.data?.dateOfBirth).toNow(true)})
       ` || '',
      value: `
      ${dayjs(params.data?.dateOfBirth).format(DEFAULT_DATE_FORMAT)}
       (${dayjs(params.data?.dateOfBirth).toNow(true)})
       ` || '',
    },
  ] as Field[],
  {
    name: 'relationship',
    type: FieldTypes.Dropdown,
    label: 'Relationship',
    singleMode: true,
    placeholder: 'Select relationship',
    validator: yup.string().required(REQUIRED_TEXT),
    defaultValue: params.data?.relationship || '',
    value: params.data?.relationship || '',
    options: params.relationshipList.map((relationship) => ({
      key: `${relationship.value}`,
      value: `${relationship.value}`,
      title: `${relationship.name.charAt(0).toUpperCase()}${relationship.name.slice(1).toLowerCase()}`,
    })),
  },
], [params]);
