import React, { useCallback, useMemo } from 'react';
import {
  AppButton,
  Box, Heading, RoundDoneIcon, Text, } from '@common-fe/common-fe';

import useCardReplaceModalStore from '../../store/useCardReplaceModal.store';

interface Props {
  onCancel?: (value: boolean) => void;
  setDoneMode?: () => void;
  onSnackbar?: (props: unknown) => void;
  cardholderName?: string;
  refreshCardList?: () => void;
}

export const CardReplaceAcceptMessage: React.FC<Props> = ({
  onCancel, setDoneMode, onSnackbar, cardholderName, refreshCardList,
}) => {
  const { handleReset } = useCardReplaceModalStore();
  const messageText = useMemo(() => `${cardholderName}'s card has been requested and will be mailed in 3-5 days`, [cardholderName]);

  const onResetModalData = useCallback(() => {
    if (setDoneMode && onSnackbar) {
      setDoneMode();
      onSnackbar({ text: messageText, closeIcon: true });
      setTimeout(() => {
        handleReset();
      }, 5000);
    }
  }, [handleReset, onSnackbar, setDoneMode, messageText]);

  const handleCancel = useCallback(() => {
    if (onCancel && refreshCardList) {
      refreshCardList();
      onCancel(false);
      onResetModalData();
    }
  }, [onCancel, onResetModalData, refreshCardList]);

  return (
    <Box align="center" pad={{ top: 'spacing12', bottom: 'spacing32' }}>
      <Box margin={{ bottom: 'spacing32' }}>
        <RoundDoneIcon />
      </Box>
      <Heading margin={{ bottom: 'spacing8' }} size="24px" style={{ fontWeight: 700 }}>
        We’ve received your request for a new card!
      </Heading>
      <Text data-testid="CardReplaceAcceptMessage" margin={{ bottom: 'spacing32' }} size="16px">
        Card will be mailed in 3-5 days
      </Text>
      <Box width="control">
        <AppButton
          type="button"
          onClick={handleCancel}
          width="100%"
        >
          Got It!
        </AppButton>
      </Box>
    </Box>
  );
};
