import React from 'react';
import {
  Box,
  Checkbox,
  InfoOutlineIcon,
  Text,   Tip, } from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props {
  value: boolean;
  disabled?: boolean;
  onChangeValue: (value: boolean) => void;

}

const StyledInfoIcon = styled(InfoOutlineIcon)`
  opacity: 0.5 ;
  &:hover {
    opacity: 1 ;
    fill: ${({ theme }) => theme.colors.iconAccent};
  }
`;

const PreferEmailPicker :React.FC<Props> = ({ value, onChangeValue, disabled }) => (
  <Box direction="row">
    <Checkbox
      name="primary"
      checked={value}
      onChange={() => onChangeValue(!value)}
      disabled={disabled}
    >
      <Text
        size="medium"
        {...disabled ? {} : {
          onClick: () => onChangeValue(!value),
        }}
      >
        Set as preferred email
      </Text>
    </Checkbox>
    <Tip
      plain
      dropProps={{
        align: {
          left: 'right',
        },
      }}
      content={(
        <Box pad={{ top: 'l' }}>
          <Box
            background="background3"
            round="dropdownItemRound"
            elevation="default"
            align="center"
            width="regular"
            pad={{ horizontal: 'm', vertical: 's' }}
          >
            <Text color="textOnColor" data-testId="prefer-info-icon">
              We will use this email address to send notifications regarding your claims requests,
              investments if any, and in case you lose access to your account,
              you can restore it using this email.
            </Text>

          </Box>
        </Box>
      )}
    >
      <Box direction="row" align="center">

        <Box margin={{ left: 'xxs' }}>
          <StyledInfoIcon data-testid="filter-hint_icon" size="24px" />
        </Box>
      </Box>
    </Tip>
  </Box>
);

export default PreferEmailPicker;
