import { useCallback, useEffect } from 'react';

import useSmartSearchResentResultsStore from '../store/useSmartSearchResentResults.store';

const RESENT_SMART_SEARCH_FINDING_RESULTS = 'RecentSmartSearchFindingResults';

export const useSmartSearchResentFinding = () => {
  const {
    resentResults,
    handleAdd,
    handleSetResentResults,
  } = useSmartSearchResentResultsStore();

  useEffect(() => {
    const results = localStorage.getItem(RESENT_SMART_SEARCH_FINDING_RESULTS);
    if (results) {
      handleSetResentResults(JSON.parse(results));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resentResults.length) {
      localStorage.setItem(RESENT_SMART_SEARCH_FINDING_RESULTS, JSON.stringify(resentResults));
    }
  }, [resentResults]);

  const clear = useCallback(() => {
    localStorage.removeItem(RESENT_SMART_SEARCH_FINDING_RESULTS);
    handleSetResentResults([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resentResults,
    handleAdd,
    clear,
  };
};
