import React, { useCallback, useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import { VALIDATORS } from '@/common';
import { TRUE_KEY } from '@/common/constants';
import { PlanFieldState } from '@/modules/plan/plan.types';
import { PLAN_SETUP_FIELD } from '@/modules/plan/PlanSetup/PlanSetup.constants';

import { useCardOfferedFieldsStateStore, useCardOfferedStore } from '../../stores';
import { CLOSE_SYMBOL, OPEN_SYMBOL } from '../FormulaDragAndDrop/FormulaDragAndDrop';
import FundFormula from '../FundFormula';
import { MAX_FORMULA_COUNT } from '../FundIDFormulaResult';
import NoFormula from '../NoFormula';

export const FUND_FORMULA_RADIO_OPTIONS = {
  NO_FORMULA: 'noFormula',
  CUSTOM_FORMULA: 'customFormula',
};

export default (
  isShowErrors?: boolean,
  isEditMode?: boolean,
  isActivateMode?: boolean,
  isCardOffered?: boolean,
) => {
  const sourcefundIDFormulaState = useCardOfferedStore(
    (state) => state.sourceState?.fundIDFormula,
  );
  const fundIdFormulaOptions = useCardOfferedStore(
    (state) => state.state?.fundIdFormulaOptions,
  );

  const currentState = useCardOfferedStore((state) => state.state);

  const fieldState = useCardOfferedFieldsStateStore((state) => state.state.fundIdFormulaOptions);

  const setState = useCardOfferedStore((state) => state.setState);
  const handleSetFormulaFundId = useCallback((newValue: string) => {
    setState({
      fundIDFormula: newValue,
    });
  }, [setState]);
  const fieldDisabled = useMemo(() => {
    if (fieldState === PlanFieldState.Hidden || fieldState === PlanFieldState.Locked) {
      return true;
    }
    return false;
  }, [fieldState]);
  const defaultFormulaFundOptions = useMemo(() => {
    const currentValue = currentState.fundIDFormula;
    if (!currentValue) {
      return '';
    }
    if (currentValue.includes(OPEN_SYMBOL)) {
      return FUND_FORMULA_RADIO_OPTIONS.CUSTOM_FORMULA;
    }
    return FUND_FORMULA_RADIO_OPTIONS.NO_FORMULA;
  }, [currentState]);
  const field = useMemo(() => ({
    parentValue: TRUE_KEY,
    name: 'fundIdFormulaOptions',
    type: FieldTypes.Radio,
    defaultValue: defaultFormulaFundOptions,
    options: [{
      label: 'No formula',
      value: FUND_FORMULA_RADIO_OPTIONS.NO_FORMULA,
    }, {
      label: 'Custom formula',
      value: FUND_FORMULA_RADIO_OPTIONS.CUSTOM_FORMULA,
    }],
    fill: true,
    label: 'Fund ID formula ',
    expandItems: true,
    showRequireIcon: true,
    controlStyles: {
      flexShrink: 250,
    },
    validator: isActivateMode && isCardOffered ? VALIDATORS.REQUIRED_STRING : VALIDATORS.STRING,
    subFields: [
      {
        name: PLAN_SETUP_FIELD.FUND_ID_FOMRULA,
        type: FieldTypes.Node,
        label: '',
        testId: 'no-formula-field',
        parentValue: FUND_FORMULA_RADIO_OPTIONS.NO_FORMULA,
        fill: true,
        value: (
          <NoFormula
            disabled={!isEditMode || fieldDisabled}
            value={sourcefundIDFormulaState}
            onChange={handleSetFormulaFundId}
            isErrorsShowed={isShowErrors}
          />
        ),
        validator: (isActivateMode && isCardOffered
          ? VALIDATORS.REQUIRED_STRING
          : VALIDATORS.STRING).test({
          test: (val) => {
            if (!fundIdFormulaOptions) {
              return true;
            }
            if (!val) {
              return false;
            }
            if (val.length <= MAX_FORMULA_COUNT) {
              return true;
            }
            return false;
          },
          message: ' ',
        }),
      },
      {
        name: PLAN_SETUP_FIELD.FUND_ID_FOMRULA,
        type: FieldTypes.Node,
        testId: 'custom-formula-field',
        label: '',
        parentValue: FUND_FORMULA_RADIO_OPTIONS.CUSTOM_FORMULA,
        fill: true,
        value: (
          <FundFormula
            disabled={!isEditMode || fieldDisabled}
            value={sourcefundIDFormulaState}
            onChange={handleSetFormulaFundId}
            isErrorsShowed={isShowErrors}
          />
        ),
        validator: (isActivateMode && isCardOffered
          ? VALIDATORS.REQUIRED_STRING
          : VALIDATORS.STRING)
          .test({
            test: (val) => {
              if (!fundIdFormulaOptions) {
                return true;
              }
              if (!val) {
                return false;
              }
              const splitedValue = val.split(OPEN_SYMBOL)[0];

              if (splitedValue) {
                const symbolessValue = val.replace(OPEN_SYMBOL, '').replace(CLOSE_SYMBOL, '');
                if (symbolessValue.length <= MAX_FORMULA_COUNT) {
                  return true;
                }
              }

              return false;
            },
            message: ' ',
          }),
      },
    ],
  } as Field), [defaultFormulaFundOptions, isActivateMode,
    isCardOffered, isEditMode, fieldDisabled,
    sourcefundIDFormulaState, handleSetFormulaFundId, isShowErrors, fundIdFormulaOptions]);
  return field;
};
