import React from 'react';
import { Box, Text } from '@common-fe/common-fe';

import { AddEnrollmentModal } from '@/modules/employee/Employee/AddEnrollmentModal/AddEnrollmentModal';

export const EnrollmentBalancesHeader: React.FC = () => (
  <Box margin={{ bottom: 'spacing12' }} direction="row" justify="between" align="center" data-testid="EnrollmentBalancesHeader-wrapper">
    <Text size="21px" weight="bold" data-testid="CardDetailsHeader-holder-name">Enrollment & Balances</Text>
    <Box direction="row" justify="end" align="center">
      <AddEnrollmentModal />
    </Box>
  </Box>
);
