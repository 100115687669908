import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { CarrierConfig, CarrierConfigPayload } from '../../types/carrier.types';

const formatCarrierConfig = (carrierConfig: CarrierConfigPayload): CarrierConfig => ({
  id: toString(carrierConfig.id),
  carrier: {
    id: toString(carrierConfig.carrier.id),
    name: carrierConfig.carrier.name,
    externalId: carrierConfig.carrier.external_id,
  },
  submitterId: carrierConfig.submitter_id,
  description: carrierConfig.description,
  organizationId: toString(carrierConfig.organization_id),
  organizationPath: carrierConfig.organization_path,
  serviceDateFrom: carrierConfig?.service_date_from,
  serviceDateTo: carrierConfig?.service_date_to,
  purposeType: carrierConfig.purpose_type,
  claimPaymentMethod: carrierConfig.claim_payment_method,
  isDependentDeidentified: carrierConfig.is_dependent_deidentified,
  isDependentAutocreated: carrierConfig.is_dependent_autocreated,
  statusType: carrierConfig.status_type,
  claimPayeeType: carrierConfig.claim_payee_type,
  amountTypes: carrierConfig.amount_types,
  claimAutopayType: carrierConfig.claim_autopay_type,
});

const useCarrierConfigQuery = (carrierId: string) => {
  const {
    isLoading, isSuccess, isError, data,
  } = useQuery(
    PATHS.GET_CARRIER_CONFIG(carrierId),
    () => api.get(PATHS.GET_CARRIER_CONFIG(carrierId)),
    { enabled: !!carrierId },
  );

  return {
    formattedData: data?.data ? formatCarrierConfig(data?.data) : undefined,
    data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useCarrierConfigQuery;
