import React from 'react';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useRedirectHomeAccess } from '@/modules/core/hooks';
import { HealthPlansType } from '@/modules/HealthPlan/HealthPlan.types';
import HealthPlanSetup from '@/modules/HealthPlan/HealthPlanSetup';
import { useHealthPlanNameStore } from '@/modules/HealthPlan/HealthPlanSetup/BasicProperties/HealthPlanName/stores';

const HealthPlanTemplateSetupPage = () => {
  const healthPlanName = useHealthPlanNameStore((state) => state.state.healthPlanName);
  useBreadcrumbs([{
    route: ROUTES.HEALTH_PLAN_HUB,
    title: 'Health Plans Hub',
    hash: HealthPlansType.Plan,
  },
  {
    route: ROUTES.HEALTH_PLANS,
    title: 'Health Plan Templates',
    hash: HealthPlansType.Template,
  },
  ...healthPlanName ? [{
    route: ROUTES.HEALTH_PLAN_VIEW,
    title: healthPlanName,
  }] : [],
  ], [healthPlanName], { backBtn: true });
  useRedirectHomeAccess([{
    permission: Permissions.VIEW_PLAN_TEMPLATE,
  }]);
  return (
    <HealthPlanSetup isTemplateMode defaultEditMode />
  );
};

export default HealthPlanTemplateSetupPage;
