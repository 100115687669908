import React from 'react';
import {
  Box, Heading, Text, TransactionsNotFoundIcon,
} from '@common-fe/common-fe';

import theme from '@/styles/theme';

interface Props {
  title: string;
  isDescriptionHidden?: boolean;
}

export const EmptyTransactionsListPlaceholder: React.FC<Props> = ({
  title, isDescriptionHidden,
}) => (
  <Box
    data-testid="TransactionsNotFound"
    pad={{ top: 'spacing32' }}
    align="center"
  >
    <Box margin={{ bottom: 'spacing32' }}>
      <TransactionsNotFoundIcon />
    </Box>
    <Heading
      data-testid="TransactionsNotFound-title"
      margin={{ bottom: 'spacing12' }}
      size="21px"
      style={{ fontWeight: 500 }}
      color={theme.colors.textBody}
    >
      There are no {title.toLocaleLowerCase()} yet
    </Heading>
    {isDescriptionHidden ? null : (
      <Text color={theme.colors.textBody}>
        {title} will be added to this list as soon as you make your first spending.
      </Text>
    )}
  </Box>
);
