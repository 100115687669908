import { useScrollStore } from '../stores';

export default () => {
  const activeModule = useScrollStore((state) => state.activeModule);
  const activeSection = useScrollStore((state) => state.activeSection);
  const hideHeader = useScrollStore((state) => state.hideHeader);
  return {
    activeModule,
    activeSection,
    hideHeader,
  };
};
