export enum PrefundStatus {
  PaymentInitiated = 'Payment initiated',
  PaymentRequested = 'Payment requested',
  PaymentRejected = 'Payment rejected',
  Paid = 'Paid',
  PaymentFailed = 'Payment failed',
  Reopen = 'Reopen',
}

export type InfoTableValue = {
  title: string;
  value?: string;
  isStatus?: boolean;
  styles?: React.CSSProperties;
};
