import { useMemo } from 'react';

import { useGetClaimReimbursementActivityReport } from '@/modules/employer/components/Reports/queries/useGetClaimReimbursementActivityReport.query';

const useClaimsReports = () => {
  const {
    formattedClaimReimbursementActivityReport,
    isLoading,
    isSubLoading,
    isEmpty,
  } = useGetClaimReimbursementActivityReport();

  const reports = useMemo(() => ([
    {
      ...formattedClaimReimbursementActivityReport,
      isWithFilter: true,
      showHideButtonCenter: true,
      isLoading,
      isSubLoading,
      isEmpty,
    },
  ]),
  [
    formattedClaimReimbursementActivityReport,
    isLoading,
    isSubLoading,
    isEmpty,
  ]);

  return reports;
};

export default useClaimsReports;
