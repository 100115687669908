import React from 'react';
import {
  AddIcon,   Box, RawButton, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
}

const StyledButton = styled(RawButton)`
  color: ${({ theme }) => theme.colors.button};
  border-radius: ${({ theme }) => theme.rounds.button2Round};
  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonSecondaryContainer};
  }
`;

const AddNewCopayButton: React.FC<Props> = ({ onClick }) => (
  <Box
    pad="l"
    background="canvas"
    elevation="default"
    margin={{ bottom: 'l' }}
    round="container1Round"
  >
    <StyledButton data-testid="add-new-copay_button" onClick={onClick}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'xs', vertical: 'xxs' }}
      >
        <AddIcon size="16px" color="button" />
        <Text size="medium" margin={{ left: 'xxs' }}>
          Add New Copay
        </Text>
      </Box>
    </StyledButton>

  </Box>
);
export default AddNewCopayButton;
