import { baseStoreCreator } from '@/utils/store';

export interface PlanTemplatePayload {
  templateName: string;
  parentTemplate: string[];
  planType: string;

}

export const DEFAULT_VALUES: PlanTemplatePayload = {
  templateName: '',
  parentTemplate: [],
  planType: '',
};

export const useStore = baseStoreCreator<PlanTemplatePayload>(DEFAULT_VALUES);

export default useStore;
