import { toNumber } from 'lodash';

import { ContributionAccount } from '../types';

export const hasZeroAmounts = (acc: ContributionAccount) => Boolean(acc?.employeeContributionAmount
  && acc?.employerContributionAmount
  && toNumber(acc?.employeeContributionAmount) === 0
  && toNumber(acc?.employerContributionAmount) === 0);

const disableAccountIfZeroAmounts = (acc: ContributionAccount) => {
  if (hasZeroAmounts(acc)) {
    return {
      ...acc,
      isDisabled: true,
    } as ContributionAccount;
  }

  return acc;
};

export default disableAccountIfZeroAmounts;
