import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrowDownIcon, ArrowUpIcon,   Box, FlatButton, Text, TextWithPointer,
} from '@common-fe/common-fe';
import _ from 'lodash';

import { ListItemType } from '@/common/types';
import { useOrgLabel } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import ExpandedItem from '../ExpandedItem';
// import ExpandedItemFirstLevel from '../ExpandedItem/ExpandedItemFirstLevel';
import ExpandedItemSecondLevel from '../ExpandedItem/ExpandedItemSecondLevel';
import { ProcessingRuleItem } from '../ProcessingDefinition.types';

import { useTriggersQuery } from './queries';

// const EMPRY_CHAR = '—';
interface Props {
  value:ProcessingRuleItem;
  newItemId?: string;
  onResetNewId?: () => void;
  handleUpdatedElement?: (value: ProcessingRuleItem) => void;
}
const TIMEOUT_TIME = 5000;
const ProcessingRulesListItem: React.FC<Props> = ({
  value,
  newItemId,
  onResetNewId,
  handleUpdatedElement,
}) => {
  const [isExapnded, setExpanded] = useState(false);
  const { fieldsMap: triggersMap } = useTriggersQuery();

  const handleToggleExpanded = useCallback(() => {
    setExpanded(!isExapnded);
  }, [isExapnded]);
  const currentOrgLabel = useOrgLabel(value.organizationType || '');

  useEffect(() => {
    if (value.id === newItemId && onResetNewId) {
      setTimeout(onResetNewId, TIMEOUT_TIME);
    }
  }, [newItemId, onResetNewId, value.id]);
  return (
    <ExpandedItem
      testId={`rule_item-${value.id}`}
      isHighlight={value.id === newItemId}
      isExapnded={isExapnded}
      options={[{
        name: 'Edit',
        onClick: () => {
          if (handleUpdatedElement) handleUpdatedElement(value);
        },
      }]}
      item={{
        id: '1',
        fields: [{
          key: 'org-level-name',
          type: ListItemType.Node,
          title: '',
          flex: 1,
          node: (
            <Box data-testid={`rule_item-${value.id}_org-level-name`}>
              <FlatButton data-testid={`rule_flat_button-${value.id}_org-level-name`} onClick={handleToggleExpanded}>
                <Box direction="row" align="center">
                  {isExapnded ? (
                    <ArrowUpIcon color="iconPrimary" />
                  ) : (
                    <ArrowDownIcon color="iconPrimary" />
                  )}
                  {value.organizationType && (
                    <Box
                      height="18px"
                      margin={{ left: 's', right: 'xs' }}
                      pad={{ horizontal: '3px' }}
                      round="button2Round"
                      background="nonClaimBg"
                      align="center"
                      justify="center"
                    >
                      <Text size="xsmall" weight={500}>{ currentOrgLabel }</Text>
                    </Box>
                  )}

                  <TextWithPointer
                    title={value.name}
                    size="medium"
                    color="textBody"
                    ellipsisMode
                    weight="bold"
                    style={{ paddingRight: theme.spacings.xs }}
                  >
                    {value.name}
                  </TextWithPointer>

                </Box>
              </FlatButton>
            </Box>
          ),
        }, {
          key: 'tiers',
          type: ListItemType.Text,
          title: value.tiers.map((tier) => tier.name).join(', '),
          flex: 2,
        }],
      }}
    >
      {isExapnded && (
        <Box pad={{ horizontal: 'xl' }} margin={{ horizontal: 's' }}>
          {value.tiers.map((tier, index) => {
            const triggersTitle = tier?.triggers?.map((trigger) => triggersMap[trigger]) || [];
            return (
              <ExpandedItemSecondLevel
                key={tier.id}
                title={tier.name}
                testId={`rule-tier-${tier.id}`}
                isFirstItem={_.first(value.tiers) === tier}
                isLastItem={_.last(value.tiers) === tier}
                subTitle={`Tier ${index + 1}`}
                items={[{
                  id: tier.payoutDefinition.id,
                  blockTitle: 'Payout definition',
                  title: tier.payoutDefinition.name,

                },
                {
                  id: '3',
                  blockTitle: 'Trigger',
                  triggers: tier?.triggers,
                  title: triggersTitle.length ? triggersTitle.join(', ') : 'N/A',
                  subTitle: tier.isSendTriggerNotification ? 'Send trigger notification' : undefined,
                  subSubTitle: tier.isSendTriggerNotification ? 'Yes' : undefined,
                },
                ...tier?.displayName ? [{
                  id: `${tier.payoutDefinition.id}-${tier.displayName}`,
                  blockTitle: 'Display name',
                  triggers: tier?.triggers,
                  title: 'Display name',
                  header: ['Display name', 'Plan'],
                  values: [tier?.displayName || '-', tier?.planName || '-'],
                }] : [],
                ]}
              />
            );
          })}
        </Box>
      )}
    </ExpandedItem>
  );
};
export default ProcessingRulesListItem;
