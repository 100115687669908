import { costFormater } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { REPORT_DATE_FORMAT } from '@/common/constants';
import { OptionKey } from '@/common/types';
import {
  AccountActivityReportDto,
  AccountActivityReportGroup,
  GroupByFilter,
  ReportNestedItemGroup,
  ReportTableItem,
  ReportTableRowItem,
} from '@/modules/employer/components/Reports/report.types';

const generateTableHeader = (id: string): ReportTableItem[] => ([
  {
    key: `account_type_${id}`,
    value: 'Account type',
    isHeader: true,
    flex: 1,
  },
  {
    key: `number_of_employees_${id}`,
    value: 'Number of employees',
    isHeader: true,
    flex: 1.5,
  },
  {
    key: `average_balance_${id}`,
    value: 'Average balance',
    isHeader: true,
    flex: 1,
  },
  {
    key: `average_claim_spend_${id}`,
    value: 'Average claim spend',
    isHeader: true,
    flex: 1.3,
  },
  {
    key: `average_contributions_${id}`,
    value: 'Average payroll contribution',
    isHeader: true,
    flex: 1,
  },
]);

const generateTableRows = (
  data: AccountActivityReportDto,
): ReportTableRowItem[] => (
  Object.entries(data.account_type_summary).map(([key, value]) => ({
    id: _.uniqueId(),
    fields: [
      {
        key: 'account_type',
        value: key,
        flex: 1,
      },
      {
        key: 'number_of_employees',
        value: value.number_of_employees,
        flex: 1.5,
      },
      {
        key: 'average_balance',
        value: costFormater(value.avg_balance, { isPrecision: true }),
        flex: 1,
      },
      {
        key: 'average_claim_spend',
        value: costFormater(value.avg_claim_spend, { isPrecision: true }),
        flex: 1.3,
      },
      {
        key: 'average_contributions',
        value: costFormater(value.avg_contributions, { isPrecision: true }),
        flex: 1,
      },
    ],
  }))
);

const formattingVariantGroupedByPartner = ( // PARTNER EMPLOYER
  response: AccountActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    tableData: {
      header: generateTableHeader(group.group_name),
      rows: generateTableRows(group.indicators[0] as AccountActivityReportDto),
    },
  })),
]);

const formattingVariantGroupedByDateOnly = ( // DATE ONLY
  response: AccountActivityReportGroup[],
): ReportNestedItemGroup[] => [
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    tableData: {
      header: generateTableHeader(group.group_name),
      rows: generateTableRows(group.indicators[0] as AccountActivityReportDto),
    },
  })),
];

const formattingVariantGroupedByPartnerAndEmployer = ( // PARTNER AND EMPLOYER
  response: AccountActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as AccountActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          hierarchy: true,
          tableData: {
            header: generateTableHeader(data.group_name),
            rows: generateTableRows(data.indicators[0] as AccountActivityReportDto),
          },
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartner = ( // DATE PARTNER EMPLOYER
  response: AccountActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as AccountActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          tableData: {
            header: generateTableHeader(data.group_name),
            rows: generateTableRows(data.indicators[0] as AccountActivityReportDto),
          },
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartnerAndEmployer = (
  response: AccountActivityReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((subGroup) => {
        const subData = subGroup as AccountActivityReportGroup;
        return {
          id: _.uniqueId(),
          title: subData.group_name,
          key: `${group.group_name}${subData.group_name}`,
          hierarchy: true,
          wrapStyle: {
            marginLeft: '8px',
          },
          children: [
            ...subData.indicators.map((indicator) => {
              const data = indicator as AccountActivityReportGroup;
              return {
                id: _.uniqueId(),
                title: data.group_name,
                key: `${group.group_name}${subData.group_name}${data.group_name}`,
                tableData: {
                  header: generateTableHeader(data.group_name),
                  rows: generateTableRows(data.indicators[0] as AccountActivityReportDto),
                },
              };
            }),
          ],
        };
      }),
    ],
  })),
]);

export const generateGroupAccountActivityReport = (
  data: {
    response: AccountActivityReportGroup[],
    groupByType: OptionKey,
  },
): ReportNestedItemGroup[] => {
  const {
    response,
    groupByType,
  } = data;
  switch (groupByType) {
  case GroupByFilter.Partner: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.Employer: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.DateOnly: {
    return formattingVariantGroupedByDateOnly(response);
  }
  case GroupByFilter.PartnerAndEmployer: {
    return formattingVariantGroupedByPartnerAndEmployer(response);
  }
  case GroupByFilter.DatePartner: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DateEmployer: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DatePartnerAndEmployer: {
    return formattingVariantGroupedByDatePartnerAndEmployer(response);
  }
  default:
    return [] as ReportNestedItemGroup[];
  }
};
