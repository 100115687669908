import { useMemo } from 'react';

import { useAccountFundingParamsFields } from '../AccountFundingParams/hooks';
import useContributionFields from '../Contribution/hooks/useContributionFields';
import useInterestAccrualsFields from '../InterestAccruals/hooks/useInterestAccrualsFields';
import { useRolloverFields } from '../Rollover/hooks';
// import { usePlanYearFields } from '../PlanYear/hooks';

export default (isActiveMode?: boolean,
  isWithoutContribution?: boolean,
  isTempalteMode?: boolean) => {
  const rolloverFields = useRolloverFields();
  const accountFundingFields = useAccountFundingParamsFields(isActiveMode, isTempalteMode);
  const contributionFields = useContributionFields(isActiveMode);
  const interestAccruals = useInterestAccrualsFields();

  const allowIndividualContributionsField = contributionFields.find((field) => field.name === 'allowIndividualContributions');

  const fields = useMemo(() => ([
    ...!isWithoutContribution ? contributionFields : [],
    ...allowIndividualContributionsField ? [allowIndividualContributionsField] : [],
    ...rolloverFields,
    ...accountFundingFields,
    ...interestAccruals,

  ]), [accountFundingFields, contributionFields,
    interestAccruals, isWithoutContribution, rolloverFields, allowIndividualContributionsField]);

  return fields;
};
