import { useMemo } from 'react';

import { CardDto,CardStatusEnum } from '../Cards.types';

const checkStatus = (status: CardStatusEnum) => status === CardStatusEnum.ACTIVE
  || status === CardStatusEnum.FROZEN;

export const useShouldShowCardActions = (status: CardStatusEnum, isMailed?: boolean) => {
  const showActions = useMemo(
    () => checkStatus(status),
    [status],
  );
  const readyForActivation = useMemo(
    () => status === CardStatusEnum.PENDING_OPEN
    && isMailed,
    [status, isMailed],
  );
  return {
    showActions,
    readyForActivation,
  };
};

export const useShouldShowAllCardsActions = (cards: Array<CardDto>) => useMemo(
  () => cards.some((card: CardDto) => checkStatus(card.status)),
  [cards],
);
