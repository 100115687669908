import { useCallback } from 'react';

import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { EmployerPayload } from '../SetupEmployer.types';

export default () => {
  const setEmployer = useStore((state) => state.setEmployer);

  const handleSave = useCallback((newValue: EmployerPayload) => {
    setEmployer(newValue);
  }, [setEmployer]);

  return {
    saveEmployerInfo: handleSave,
  };
};
