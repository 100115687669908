import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActionCard,
  AppButton,
  Box,   DropContent,
  PlanActiveCheckedIcon, PlanActiveIcon, SiblingBoxesWithSpacing,Text } from '@common-fe/common-fe';
import _ from 'lodash';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import PlanUpdateModal from '../../PlanUpdateModal';
import { useSubmitFormStore } from '../../stores';
import useModalType from '../stores/useModalType.store';
import SwitchToEditMode from '../SwitchToEditMode';

interface Props {
  onActivate: () => void;
  viewMode?: boolean;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
  planName?: string;
  activateButtonRef?: React.RefObject<HTMLButtonElement>;
  errorMessages: string[];
  successMessages: string[];
  pendingMessages: string[];
}

const ActivatePlan: React.FC<Props> = React.memo(({
  onActivate,
  viewMode,
  isTemplateMode,
  isPlanActive,
  planName,
  errorMessages,
  successMessages,
  pendingMessages,
  activateButtonRef,
}) => {
  const history = useHistory();
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [isVisibleUpdatePlanPopup, setVisibleUpdatePlanPopup] = useState(false);
  const handleToggleUpdatePlanPopup = useCallback(() => {
    setVisibleUpdatePlanPopup(!isVisibleUpdatePlanPopup);
  }, [isVisibleUpdatePlanPopup]);

  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const handleToggleVisiblePopup = useCallback(() => {
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);
  const {
    saveErrorModal,
    activateModal,
    activateErrorModal,
  } = useModalType();

  useEffect(() => {
    if (saveErrorModal || activateModal || activateErrorModal) {
      setVisibleUpdatePlanPopup(false);
    }
  }, [saveErrorModal, activateModal, activateErrorModal, setVisibleUpdatePlanPopup]);
  
  const handleActivate = useCallback(() => {
    onActivate();
    handleToggleVisiblePopup();
  }, [handleToggleVisiblePopup, onActivate]);
  const CardIcon = useMemo(() => {
    if (_.isEmpty(errorMessages) && !_.isEmpty(successMessages) && activateValidate) {
      return (
        <PlanActiveCheckedIcon size="xxlarge" color="iconAccent" />
      );
    }
    return (
      <PlanActiveIcon size="xxlarge" color="iconAccent" />
    );
  }, [activateValidate, errorMessages, successMessages]);

  return (
    <>
      <Access
        accessRequirements={[
          {
            permission: Permissions.ACTIVATE_PLAN,
          },
        ]}
      >
        {
        // It's invisible button to handle ref approach
        }
        <button
          type="button"
          ref={activateButtonRef}
          onClick={onActivate}
          style={{ display: 'none' }}
        >
          <span />
        </button>
        <ActionCard
          modulesTitle="Required attributes"
          title={isTemplateMode ? 'Activate the Plan Template' : isPlanActive ? `Update ${planName}` : 'Activate the Plan'}
          footer={viewMode ? <SwitchToEditMode isTemplateMode={isTemplateMode} /> : null}
          submitted={activateValidate}
          errorMessages={viewMode ? [] : errorMessages}
          successMessages={viewMode ? [] : successMessages}
          pendingMessages={viewMode ? [] : pendingMessages}
          icon={
            CardIcon
          }
        >
          <Box
            direction="column"
            align="center"
            flex="grow"
            pad={{
              left: 'spacing8',
              right: 'spacing8',
            }}
          >
            {isPlanActive ? (
              <Box
                direction="row"
                justify="center"
                align="center"
                width="100%"
              >
                <SiblingBoxesWithSpacing width="small">
                  <AppButton
                    disabled={viewMode}
                    onClick={() => history.goBack()}
                    size="L"
                    color="red"
                    width="100%"
                  >
                    Discard
                  </AppButton>
                </SiblingBoxesWithSpacing>
                <SiblingBoxesWithSpacing width="small">
                  <AppButton
                    disabled={viewMode}
                    onClick={handleToggleUpdatePlanPopup}
                    size="L"
                    width="100%"
                  >
                    Save Changes
                  </AppButton>
                </SiblingBoxesWithSpacing>
              </Box>
            ) : (
              <DropContent
                visible={visiblePopup}
                content={
                  (
                    <Box direction="column">
                      <Text size="medium">Pay attention, that plan cannot be edited after the activation!</Text>
                      <Text size="medium" weight="bold">
                        Would you like to continue?
                      </Text>
                      <Box direction="row" margin={{ top: 'spacing12' }} gap="spacing12">
                        <Box style={{ flex: 1 }}>
                          <AppButton
                            buttonType="secondary"
                            onClick={handleToggleVisiblePopup}
                            width="100%"
                          >
                            Cancel
                          </AppButton>
                        </Box>
                        <Box style={{ flex: 1 }}>
                          <AppButton
                            onClick={handleActivate}
                            width="100%"
                          >
                            Continue
                          </AppButton>
                        </Box>
                      </Box>
                    </Box>
                  )
                }
              >
                <Box fill>
                  <AppButton
                    disabled={viewMode}
                    onClick={handleToggleVisiblePopup}
                    size="L"
                    width="100%"
                  >
                    {isTemplateMode ? 'Activate the Plan Template' : 'Activate the Plan'}
                  </AppButton>
                </Box>
              </DropContent>
            )}
          </Box>
        </ActionCard>
      </Access>
      <PlanUpdateModal
        handleActive={handleActivate}
        isVisible={isVisibleUpdatePlanPopup}
        onSetVisible={handleToggleUpdatePlanPopup}
        planName={planName}
      />
    </>
  );
});

export default ActivatePlan;
