import { capitalizeFirstLetter } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';

import { api } from '@/api';
import { API_KEY, GATEWAY_KEY } from '@/api/api';
import PATHS from '@/common/paths';
import { CONFIG } from '@/utils';

import { FileDtoWithMediaSource,FileStatus, InputFile } from '../FileManager.constants';

import { FileDtoPayload,getStatus } from './useFilesList.query';

export const UNKNOWN_VALUE = '–';

export const addBacklightingFields = (status: FileStatus) => ({
  backlighting: status,
  backlightingTime: Date.now(),
});

interface FileDtoPayloadWithInputFile extends FileDtoPayload {
  file: InputFile;
}

const formatData = (file: FileDtoWithMediaSource, data?: FileDtoPayloadWithInputFile) => {
  const status = getStatus(data?.file_processing_status_type) as FileStatus;

  const partnersNames = [...(data?.partners_names || [])].filter((name) => Boolean(name));
  const employersNames = [...(data?.employers_names || [])].filter((name) => Boolean(name));

  return {
    id: toString(data?.id),
    temporalId: file.temporalId,
    name: data?.name || file.name,
    partnersNames: partnersNames.length ? partnersNames : file.partnersNames,
    employersNames: employersNames.length ? employersNames : file.employersNames,
    uploadedBy: file.uploadedBy || '',
    path: data?.file_path || '',
    extensionType: file.extensionType || '',
    type: capitalizeFirstLetter(data?.file_type || ''),
    status,
    ...addBacklightingFields(status),
    createdAt: data?.created_at || file.createdAt === UNKNOWN_VALUE ? dayjs().format() : '',
    processedAt: data?.processed_at || file.processedAt || '',
    hasReuploading: false,
  };
};

const getFileInBlob = (url: string) => new Promise((resolve) => {
  const request = new XMLHttpRequest();
  request.open('GET', url);
  request.responseType = 'blob';
  request.onload = () => {
    if (request.status === 200) {
      resolve(request.response);
    }
  };
  request.send();
});

const useFilesUploadQuery = () => {
  const uploadFile = async (file: FileDtoWithMediaSource) => {
    try {
      const blob = await getFileInBlob(URL.createObjectURL(file.file));
      const formData = new FormData();

      formData.append('file', blob as Blob, file.name);

      const url = `${CONFIG.BASE_URL?.replace(GATEWAY_KEY, API_KEY)}${PATHS.UPLOAD_CSV_FILE}`;
      const response = await api.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            dry_run: false,
            upload_way: 'UI',
          },
        },
      );

      return {
        ...formatData(file, response?.data),
        file: file.file,
      } as FileDtoWithMediaSource;
    } catch {
      return {
        ...formatData(file, undefined),
        status: FileStatus.Uploading,
        backlighting: FileStatus.Uploading,
        backlightingTime: Date.now(),
        type: FileStatus.Unknown,
        file: file.file,
        hasReuploading: true,
      } as FileDtoWithMediaSource;
    }
  };

  return {
    uploadFiles: (files: FileDtoWithMediaSource[]) => Promise.all(
      files.map((file) => uploadFile(file)),
    ),
  };
};

export default useFilesUploadQuery;
