import React, { useCallback, useMemo, useState } from 'react';
import { Box, Inscription, PencilIcon } from '@common-fe/common-fe';

import { EMPTY_FIELD_VALUE } from '@/common/constants';
import { Address } from '@/common/types';
import { CheckDetails } from '@/modules/employer/components/Lockbox/lockbox.types';
import { Vendor } from '@/modules/employer/components/Lockbox/queries/useGetVendors.query';
import { getFullAddress } from '@/utils';

import EditCheckAddressModal from './EditCheckAddressModal';

interface Props {
  checkDetails?: CheckDetails;
  activeVendor?: Vendor;
}

const LockboxVendorAddress: React.FC<Props> = ({ activeVendor, checkDetails }) => {
  const [isEditCheckAddressModalVisible, setIsEditCheckAddressModalVisible] = useState(false);

  const checkDetailsAddress: Address = useMemo(() => ({
    line1: checkDetails?.addressLine1,
    line2: checkDetails?.addressLine2,
    city: checkDetails?.city,
    state: {
      name: checkDetails?.state,
    },
    zipcode: checkDetails?.zip,
  }), [checkDetails]);

  const vendorAddress: Address = useMemo(() => ({
    line1: activeVendor?.addressLine1,
    line2: activeVendor?.addressLine2,
    city: activeVendor?.city,
    state: {
      name: activeVendor?.state,
    },
    zipcode: activeVendor?.zip,
  }), [activeVendor]);

  const isVendorFromCheckDetails = useMemo(() => activeVendor?.vendorId && activeVendor?.vendorId === checkDetails?.vendorId,
    [checkDetails, activeVendor]);

  const checkDetailsFullAddress = useMemo(() => getFullAddress(checkDetailsAddress, true),
    [checkDetailsAddress]);

  const vendorFullAddress = useMemo(() => getFullAddress(vendorAddress, true),
    [vendorAddress]);

  const address = useMemo(() => isVendorFromCheckDetails ? checkDetailsFullAddress : vendorFullAddress,
    [isVendorFromCheckDetails, checkDetailsFullAddress, vendorFullAddress]);

  const handleOpenEditAddressModal = useCallback(() => {
    setIsEditCheckAddressModalVisible(!isEditCheckAddressModalVisible);
  }, [isEditCheckAddressModalVisible]);

  return (
    <>
      <Box margin={{ left: 'spacing8' }} direction="row">
        <Inscription color="textBody" weight={700}>
          {address || EMPTY_FIELD_VALUE}
          <PencilIcon
            size="spacing16"
            color="iconSecondary"
            onClick={handleOpenEditAddressModal}
            style={{
              marginLeft: '8px',
              marginBottom: '4px',
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
          />
        </Inscription>
      </Box>
    
      {isEditCheckAddressModalVisible && (
        <EditCheckAddressModal
          checkDetails={isVendorFromCheckDetails ? checkDetails : activeVendor}
          onSetVisible={handleOpenEditAddressModal}
        />
      )}
    </>
  );
};

export default LockboxVendorAddress;
