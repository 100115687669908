import { OptionKey } from '@common-fe/common-fe';

import formatTaxType from './formatTaxType';

export enum ContributionRequestStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  ERROR = 'ERROR',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  APPROVAL_NEEDED = 'APPROVAL_NEEDED',
  REJECTED = 'REJECTED',
}
export enum InvoiceStatusType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PAID = 'PAID',
  VOID = 'VOID',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  REOPEN = 'REOPEN',
}

export interface RecordsInfoDto {
  ee_contribution_amount: number;
  er_contribution_amount: number;
  plan_code: string;
  plan_name: string;
  invoice_id: number;
  invoice_status_type?: InvoiceStatusType;
  contribution_request_status?: ContributionRequestStatus;
}

export interface RecordsInfo {
  eeContributionAmount?: number;
  erContributionAmount?: number;
  planCode: string;
  planName: string;
  invoiceId: number;
  invoiceStatusType?: InvoiceStatusType;
  contributionRequestStatus?: ContributionRequestStatus;
}

export interface ContributionListItemDto {
  contribution_id: string;
  status: ContributionRequestStatus;
  contribution_source: string;
  submit_date: string;
  records_info: RecordsInfoDto[];
  record_count: number;
}

export interface ContributionListItemType {
  contributionId: string;
  status: ContributionRequestStatus;
  contributionSource: string;
  submitDate: string;
  recordsInfo: RecordsInfo[];
  recordCount: number;
  accounts: string[];
}

export enum ContributionSource {
  FILE = 'FPS',
  UI = 'UI',
  API = 'API',
  RECURRING = 'RECURRING',
  SCHEDULED = 'SCHEDULED'
}

export enum ContributionSegmentType {
  SINGLE = 'SINGLE',
  ALL_EMPLOYEES = 'ALL_EMPLOYEES',
  PLANS = 'PLANS',
  SUBSIDIARY = 'SUBSIDIARY',
  PAYROLL_GROUP = 'PAYROLL_GROUP',
}

export enum NewContributionType {
  SINGLE = 'SINGLE',
  GROUP = 'GROUP',
}

export enum ContributionGroups {
  ALL_EMPLOYEES = 'ALL_EMPLOYEES',
  PLANS = 'PLANS',
  SUBSIDIARY = 'SUBSIDIARY',
  PAYROLL_GROUP = 'PAYROLL_GROUP',
}

export enum TaxType {
  POST_TAX = 'POST_TAX',
  PRE_TAX = 'PRE_TAX',
}

export interface ContributionSegment {
  id?: string;
  contributionRequestId?: string;
  contributionId?: string;
  contributionDate?: string;
  taxType?: TaxType;
  taxYear?: string;
  contributionSegmentType?: ContributionSegmentType;
  memo?: string;
  segmentSubsidiaryIds?: string[];
  segmentPayrollGroupCodes?: string[];
  segmentPlanCodes?: string[];
  segmentEmployeeIds?: string[];
}

export enum ManualContributionFormFields {
  CONTRIBUTION_TYPE = 'contributionType',
  EMPLOYEE = 'employee',
  GROUP_TYPE = 'groupType',
  PLANS = 'plans',
  SUBSIDIARY = 'subsidiary',
  PAYROLL_GROUP = 'payrollGroup',
  CONTRIBUTION_DATE = 'contributionDate',
  TAX_YEAR = 'taxYear',
  TAX_TYPE = 'taxType',
  MEMO = 'memo',
}

export interface ManualContributionFormValues {
  [ManualContributionFormFields.CONTRIBUTION_TYPE]?: NewContributionType;
  [ManualContributionFormFields.EMPLOYEE]?: string;
  [ManualContributionFormFields.GROUP_TYPE]?: ContributionGroups;
  [ManualContributionFormFields.PLANS]?: OptionKey[];
  [ManualContributionFormFields.SUBSIDIARY]?: string;
  [ManualContributionFormFields.PAYROLL_GROUP]?: string;
  [ManualContributionFormFields.CONTRIBUTION_DATE]?: string;
  [ManualContributionFormFields.TAX_YEAR]?: string;
  [ManualContributionFormFields.TAX_TYPE]?: TaxType;
  [ManualContributionFormFields.MEMO]?: string;
}

export interface EmployeeContributionViewPlanPayload {
  plan_id: number;
  plan_code: string;
  account_type?: string;
  is_plan_disabled?: boolean;
  coverage_period_end?: string;
  coverage_period_start?: string;
  per_pay_employee?: number;
  per_pay_employer?: number;
}

export interface EmployeeContributionViewPlan {
  planId: string;
  planCode: string;
  isPlanDisabled?: boolean;
  coveragePeriodEnd?: string;
  coveragePeriodStart?: string;
  perPayEmployee?: string;
  perPayEmployer?: string;
}

export interface EmployeeContributionViewPayload {
  employee_id: number;
  is_new_employee?: boolean;
  employee_termination_date?: string;
  first_name: string;
  last_name: string;
  birth_on: string;
  is_subsidiary: boolean;
  organization_name: string;
  plans: EmployeeContributionViewPlanPayload[];
}

export interface EmployeeContributionView {
  employeeId: string;
  employeeTerminationDate?: string;
  isNewEmployee?: boolean;
  firstName: string;
  lastName: string;
  birthOn: string;
  isSubsidiary: boolean;
  organizationName: string;
  plans: EmployeeContributionViewPlan[];
}

export interface ScheduledContributionPayload {
  calendar: string;
  startDate: string;
  taxType: string;
}

export const taxTypeOptions = [
  {
    key: TaxType.POST_TAX,
    value: TaxType.POST_TAX,
    title: formatTaxType(TaxType.POST_TAX),
  },
  {
    key: TaxType.PRE_TAX,
    value: TaxType.PRE_TAX,
    title: formatTaxType(TaxType.PRE_TAX),
  },
];