import { useMemo } from 'react';

import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { HealthPlanStatuses } from '../../HealthPlan.types';
import { useHealthTemplatesListQuery } from '../../queries';

export default () => {
  const {
    data: draftTemplates,
    isLoading: isDraftTemplatesLoading,
    error: draftError,
    refetch: draftTemplatesRefetch,
  } = useHealthTemplatesListQuery({
    status: HealthPlanStatuses.DRAFT,
  });
  const {
    data: activeTemplates,
    isLoading: isActiveTemplatesLoading,
    error: activeError,
    refetch: activeTemplatesRefetch,
  } = useHealthTemplatesListQuery({
    status: HealthPlanStatuses.ACTIVE,
  });
  const allDraftTemplates = useMemo(
    () => draftTemplates,
    [draftTemplates],
  );
  const isLoading = useMemo(
    () => isDraftTemplatesLoading
    // || isReadyForReviewTemplatesLoading
    || isActiveTemplatesLoading,
    [isActiveTemplatesLoading, isDraftTemplatesLoading],
  );

  const count = useMemo(
    () => activeTemplates?.length
    + allDraftTemplates?.length,
    [activeTemplates?.length, allDraftTemplates?.length],
  );
  const currentError = useMemo(
    () => draftError
    // || readyForReviewError
    || activeError,
    [draftError, activeError],
  );
  const currentRefetch = useMemo(() => {
    if (draftError) return draftTemplatesRefetch;
    if (activeError) return activeTemplatesRefetch;
    return draftTemplatesRefetch;
  }, [activeError, activeTemplatesRefetch, draftError, draftTemplatesRefetch]);

  const { isServerError, setIsServerError } = useIsServerError(currentError);
  return {
    count,
    isLoading,
    isServerError,
    currentRefetch,
    setIsServerError,
    activeTemplates,
    allDraftTemplates,
  };
};
