import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

interface ResetButtonProps {
  isDefaultValuesEstablished: boolean;
  isDisabled?: boolean;
}

export const StyledResetButton = styled(Box)<ResetButtonProps>`
  color: ${({ theme }) => theme.colors.textDisabled};
  &:hover {
    ${({ isDefaultValuesEstablished, theme, isDisabled }) => {
    if (!isDefaultValuesEstablished && !isDisabled) return { color: theme.colors.button };
    return null;
  }}
  }
  }
`;
