import { useEffect } from 'react';

import useGetStream from '@/utils/hooks/useGetStream';

interface Props {
  path: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleEvent: (event: any) => void;
  eventName: string
}

const useGetStreamedData = (props: Props) => {
  const { path, handleEvent, eventName } = props;
  const stream = useGetStream(path);

  useEffect(() => {
    if (stream) stream.addEventListener(eventName, handleEvent, true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [stream]);

  useEffect(() => () => {
    if (stream) {
      stream.removeEventListener(eventName, handleEvent, true);
      stream.close();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [stream]);
};

export default useGetStreamedData;
