import React, { useCallback, useMemo } from 'react';
import { Box, NavigationLeftIcon } from '@common-fe/common-fe';
import styled from 'styled-components';

import { ModalWrapper } from '@/components/wrappers';

import EmployerTerminationModalForm from './EmployerTerminationModalForms/EmployerTerminationModalForm';
import { TerminationSteps } from './store/useEmployerTerminationModal.store';
import { useEmployerTerminationModalStore } from './store';

const OneStepBackButton = styled(Box)`
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
`;

const EMPLOYER_CUSTOM_NAME = 'Employer';

export const EmployerTerminationModal = () => {
  const handleChangeVisibility = useEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const modalVisible = useEmployerTerminationModalStore((state) => state.modalVisible);
  const employerName = useEmployerTerminationModalStore((state) => state.employerName);
  const handleReset = useEmployerTerminationModalStore((state) => state.handleReset);
  const step = useEmployerTerminationModalStore((state) => state.step);
  const handleSetStep = useEmployerTerminationModalStore((state) => state.handleSetStep);

  const modalTitle = useMemo(() => {
    return `Terminate ${employerName || EMPLOYER_CUSTOM_NAME}`;
  }, [employerName]);

  const handleSetVisible = useCallback(() => {
    handleChangeVisibility(false);
    handleReset();
  }, [
    handleChangeVisibility,
    handleReset,
  ]);

  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={modalVisible}
      onSetVisible={handleSetVisible}
      title={modalTitle}
      titleBoxStyle={{ paddingBottom: '12px' }}
    >
      {step === TerminationSteps.SET_TERMINATION_PARAMS ?  (
        <OneStepBackButton
          width="24px"
          height="24px"
          onClick={() => handleSetStep(TerminationSteps.SET_TERMINATION_DATE)}
        >
          <NavigationLeftIcon color="iconPrimary" />
        </OneStepBackButton>
      ) : null}
      <EmployerTerminationModalForm onCancel={handleSetVisible} />
    </ModalWrapper>
  );
};