import React, { useCallback, useState } from 'react';

import { ModalWrapper } from '@/components/wrappers';

import MakeContributionModalForm from './MakeContributionModalForm';

const MODAL_TITLE = 'Add Contribution';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  contributionId?: string;
}

export const MakeContributionModal: React.FC<Props> = ({
  visible,
  onSetVisible,
  modalTitle = MODAL_TITLE,
  contributionId,
}) => {
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);
  const handleSetVisible = useCallback(() => {
    onSetVisible(false);
  }, [onSetVisible]);

  return (
    <ModalWrapper
      testId="ModalWrapper"
      visible={visible}
      onSetVisible={handleSetVisible}
      title={modalTitle}
      titleBoxStyle={{ paddingBottom: '16px' }}
      {...(isOverflowHidden ? { wrapperStyle: { overflow: 'hidden' } } : {})}
    >
      <MakeContributionModalForm
        contributionId={contributionId}
        onCancel={handleSetVisible}
        setIsOverflowHidden={setIsOverflowHidden}
      />
    </ModalWrapper>
  );
};

export default MakeContributionModal;
