import React, { useCallback, useMemo } from 'react';
import {
  Box, Field,
  FieldTypes, Text, } from '@common-fe/common-fe';

const Label: React.VFC<{ title: string, desc: string }> = ({ title, desc }) => (
  <Box flex={{ grow: 1 }}>
    <Text weight="bold" color="textBody">{title}</Text>
    <Box>
      <Text weight="normal" color="textSecondary">
        {desc}
      </Text>
    </Box>
  </Box>
);

const KEYS = {
  WELCOME: 'WELCOME',
  ENROLLMENTS: 'ENROLLMENTS',
  ENROLLMENT_UPDATES: 'ENROLLMENT_UPDATES',
  HSA: 'HSA',
  INVESTMENTS: 'INVESTMENTS',
  REMINDERS: 'REMINDERS',
  CAMPAIGNS: 'CAMPAIGNS',
  CARD_STATUS: 'CARD_STATUS',
  PROFILE_UPDATES: 'PROFILE_UPDATES',
  ACCOUNT_ACTIVITY: 'ACCOUNT_ACTIVITY',
} as const;

const useCommunicationCategoryForm = (
  handleSetValues: (categories: string[]) => void,
  categories: string[],
  permissionProps?: Partial<Field>,
) => {
  const onChangeValues = useCallback((obj: object) => {
    const value = Object.values(obj)[0];
    const key = Object.keys(obj)[0];
    if (value) {
      handleSetValues([...categories, key]);
    } else {
      handleSetValues([...categories.filter((category) => category !== key)]);
    }
  }, [handleSetValues, categories]);
  const fields = useMemo(() => ([
    {
      name: KEYS.WELCOME,
      testId: 'welcome-communications-id',
      label: (
        <Label
          title="Welcome"
          desc="Employee welcome and how to get started"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.WELCOME),
      ...permissionProps,
    },
    {
      name: KEYS.ENROLLMENTS,
      testId: 'enrollments-communications-id',
      label: (
        <Label
          title="Enrollments"
          desc="New enrollments and terminations"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.ENROLLMENTS),
      ...permissionProps,
    },
    {
      name: KEYS.ENROLLMENT_UPDATES,
      testId: 'enrollments-updates-communications-id',
      label: (
        <Label
          title="Enrollment updates"
          desc="Changes to election, coverage status, etc."
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.ENROLLMENT_UPDATES),
      ...permissionProps,
    },
    {
      name: KEYS.PROFILE_UPDATES,
      testId: 'profile-update-communications-id',
      label: (
        <Label
          title="Profile updates"
          desc="Changes to contact information, password, payment methods"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.PROFILE_UPDATES),
      ...permissionProps,
    },
    {
      name: KEYS.CARD_STATUS,
      testId: 'card-status-communications-id',
      label: (
        <Label
          title="Card status"
          desc="Card mailed and status changes"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.CARD_STATUS),
      ...permissionProps,
    },
    {
      name: KEYS.ACCOUNT_ACTIVITY,
      testId: 'account-activity-communications-id',
      label: (
        <Label
          title="Account activity"
          desc="Updates about claims, card transactions, deposits"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.ACCOUNT_ACTIVITY),
      ...permissionProps,
    },
    {
      name: KEYS.HSA,
      testId: 'hsa-communications-id',
      label: (
        <Label
          title="HSA"
          desc="Account updates and important documents available"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.HSA),
      ...permissionProps,
    },
    {
      name: KEYS.INVESTMENTS,
      testId: 'investments-communications-id',
      label: (
        <Label
          title="Investments"
          desc="Investment transaction updates"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.INVESTMENTS),
      ...permissionProps,
    },
    {
      name: KEYS.REMINDERS,
      testId: 'remiders-communications-id',
      label: (
        <Label
          title="Reminders"
          desc="Helpful reminders for spending and claim deadlines"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.REMINDERS),
      ...permissionProps,
    },
    {
      name: KEYS.CAMPAIGNS,
      testId: 'campaigns-communications-id',
      label: (
        <Label
          title="Campaigns"
          desc="Helpful information to improve the employee experience"
        />
      ),
      onChangeValues,
      type: FieldTypes.Toggle,
      value: categories.some((category) => category === KEYS.CAMPAIGNS),
      ...permissionProps,
    },
  ]), [categories, permissionProps, onChangeValues]);

  return fields as Field[];
};

export default useCommunicationCategoryForm;
