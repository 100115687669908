import { Box } from '@common-fe/common-fe';
import styled from 'styled-components';

export const WrapperWithRightShadow = styled(Box)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 58px;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.464931) 27.92%, #FFFFFF 100%);
  }
`;

export const AddressLine = styled(Box)`
  border-radius: 28px 0px 0px 28px;
`;
