import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter } from '@common-fe/common-fe';
import { snakeCase, toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';
import { OptionKey, PaginationParams } from '@/common/types';
import { getObservedOrganizationIdsFromURLSearchParams } from '@/modules/core/hooks/useHistory';
import { PaymentType } from '@/modules/transaction/transaction.types';

import { ClaimDto, ClaimsStatus } from '../types/claims.constants';

export const QUERY_KEY = 'useClaimsList';

export interface ClaimDtoPayload {
  claim_id: number;
  employee_name: string;
  escalation_note?: string;
  category_type: string;
  payment_type: PaymentType;
  age: string;
  claim_status: string;
  contact_first_name?: string;
  contact_last_name?: string;
  created_at: string;
  updated_at: string;
  organization_id?: string;
  organization_name?: string;
  employer_name?: string;
  subsidiary_name?: string;
  claims_teams?: Array<{ id: number; name?: string; }>;
}

interface ResponseData {
  content: ClaimDtoPayload[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

const RESPONSE_KEY = 'claimsListPageResponse';
const PARAMS_KEY = 'claimsListPageRequestParams';
const EXTRA_KEY = 'extraKey';
const createExtraKey = (props: { isOnlyMyAssignmets?: boolean, isShowOnlyEscalated?: boolean }) => `${props?.isOnlyMyAssignmets ? '_isOnlyMyAssignmets' : ''}${props?.isShowOnlyEscalated ? '_isShowOnlyEscalated' : ''}`;
export const setExtraKey = (props: { isOnlyMyAssignmets?: boolean, isShowOnlyEscalated?: boolean }) => sessionStorage.setItem(EXTRA_KEY, createExtraKey(props));
const getClaimsData = (key: string): ResponseData => {
  const storedExtraKey = sessionStorage.getItem(EXTRA_KEY) || '';
  const { observedOrganizationPath } = getObservedOrganizationIdsFromURLSearchParams();
  const storageKey = `${key}${storedExtraKey}`;

  if (observedOrganizationPath) {
    return JSON.parse(JSON.parse(sessionStorage.getItem(storageKey) || 'null')?.[observedOrganizationPath] || 'null');
  }

  return JSON.parse(sessionStorage.getItem(storageKey) || 'null');
};
const store = (params?: string, response?: string, extraKey?: string) => {
  const { observedOrganizationPath } = getObservedOrganizationIdsFromURLSearchParams();
  if (response) sessionStorage.setItem(`${RESPONSE_KEY}${extraKey || ''}`, observedOrganizationPath ? JSON.stringify({[observedOrganizationPath]: response }) : response);
  if (params) sessionStorage.setItem(`${PARAMS_KEY}${extraKey || ''}`, observedOrganizationPath ? JSON.stringify({[observedOrganizationPath]: params }) : params);
};
export const removeStoredClaimsResponses = () => {
  sessionStorage.removeItem(RESPONSE_KEY);
  sessionStorage.removeItem(PARAMS_KEY);
  sessionStorage.removeItem(EXTRA_KEY);
};

export const getFollowingClaimId = (id?: string, isNext?: boolean) => {
  if (!id) {
    return '';
  }

  const responseData = getClaimsData(RESPONSE_KEY);

  if (!responseData?.content?.length) return '';

  const currentIndex = responseData?.content
    ?.findIndex((claim) => claim.claim_id === parseFloat(id));

  if (currentIndex === -1) return '';

  if (!isNext) return toString(responseData?.content[currentIndex - 1]?.claim_id);

  const nextClaimId = toString(responseData?.content[currentIndex + 1]?.claim_id);

  if (nextClaimId) {
    return nextClaimId;
  }

  return '';
};

const getPageIndex = (isNext?: boolean) => {
  const responseData = getClaimsData(RESPONSE_KEY);

  if (!responseData) return '';

  const { totalPages, number } = responseData;

  if (isNext) {
    return number === totalPages - 1 ? '' : toString(number + 1);
  }

  return number === 0 ? '' : toString(number - 1);
};

export const isFollowingClaimAvailable = (id?: string, isNext?: boolean) => {
  if (!id) {
    return false;
  }

  const claimId = getFollowingClaimId(id, isNext);

  if (claimId) {
    return Boolean(claimId);
  }

  return Boolean(getPageIndex(isNext));
};

const formatData = (data: ClaimDtoPayload[]) => data.map((claim) => ({
  id: `${claim.claim_id}`,
  employeeName: claim.employee_name,
  ...claim?.escalation_note ? { escalationNote: claim?.escalation_note } : {},
  categoryType: toString(claim.category_type),
  ...claim?.payment_type ? {
    paymentType: claim?.payment_type,
  } : {},
  age: claim.age,
  status: capitalizeFirstLetter(claim.claim_status) as ClaimsStatus,
  ...(claim?.contact_first_name || claim?.contact_last_name ? {
    assignee: `${claim?.contact_first_name} ${claim?.contact_last_name}`.trim(),
  } : {}),
  createdAt: claim.created_at,
  updatedAt: claim.updated_at,
  organizationId: `${claim.organization_id}`,
  organizationName: claim.organization_name,
  employerName: claim.employer_name,
  subsidiaryName: claim?.subsidiary_name,
  ...claim?.claims_teams?.length ? {
    claimsTeams: claim.claims_teams.map((item) => ({ id: toString(item.id), name: item.name })),
  } : {},
})) as ClaimDto[];

export interface ClaimsQueryParams extends Partial<PaginationParams> {
  hasAvatar?: boolean;
  paymentType?: PaymentType;
  dateRange?: Array<string | null>;
  statuses?: OptionKey[];
  enabled?: boolean;
  isNext?: boolean;
  isOnlyMyAssignmets?: boolean;
  isShowOnlyEscalated?: boolean;
}

const removeClaimIdFormat = (searchString: string) => {
  const isClaimId = searchString.length === 9
    && searchString[1] === '-'
    && searchString[5] === '-';
  const claimId = Number(searchString.replace(/-/g, ''));
  if (isClaimId && !Number.isNaN(claimId)) return claimId.toString();
  return searchString;
};

const useClaimsListQuery = ({
  page,
  perPage,
  searchString,
  statuses,
  paymentType,
  dateRange = [],
  isNext,
  enabled = true,
  isOnlyMyAssignmets,
  isShowOnlyEscalated,
}: ClaimsQueryParams) => {
  const [periodFrom, periodTo] = dateRange;
  const storedParams = useMemo(() => {
    if (typeof isNext !== 'boolean') {
      return null;
    }

    const paramsData = getClaimsData(PARAMS_KEY);

    if (!paramsData) {
      return null;
    }

    const pageIndex = getPageIndex(isNext);

    if (!pageIndex) {
      return null;
    }

    return {
      ...paramsData,
      page: pageIndex,
    };
  }, [isNext]);
  const params = storedParams || {
    ...(searchString ? { search_value: removeClaimIdFormat(searchString) } : {}),
    page_size: perPage || 20,
    page,
    ...(statuses?.length ? { claim_status: statuses.map((status) => snakeCase(`${status}`).toUpperCase()).join(',') } : {}),
    ...(paymentType ? { payment_type: paymentType } : {}),
    ...(periodFrom ? { period_from: periodFrom } : {}),
    ...(periodTo ? { period_to: periodTo } : {}),
    ...isOnlyMyAssignmets ? { only_my_assignments: true } : {},
    ...isShowOnlyEscalated ? { show_only_escalated: true } : {},
  };
  const {
    isLoading, isSuccess, isError, data, refetch, isRefetching,
  } = useQuery([
    QUERY_KEY,
    page,
    perPage,
    statuses,
    searchString,
    paymentType,
    isNext,
    enabled,
    periodFrom,
    periodTo,
    isOnlyMyAssignmets,
    isShowOnlyEscalated,
  ], () => api.get(PATHS.CLAIMS, {
    params,
  }), {
    cacheTime: 0,
    enabled,
  });

  if (enabled) {
    const extraKey = createExtraKey({ isOnlyMyAssignmets, isShowOnlyEscalated });
    store(JSON.stringify(params), JSON.stringify(data?.data), extraKey);
  }

  const total = useMemo<number>(() => isLoading ? undefined : data?.data?.totalElements, [data, isLoading]);

  return ({
    formatedData: formatData(data?.data?.content || []),
    data: data?.data?.content || [],
    isError,
    total,
    isLoading,
    isSuccess,
    refetch,
    isRefetching,
  });
};

export default useClaimsListQuery;
