import React, { useMemo } from 'react';
import {
  Box, SelectDropdown,
} from '@common-fe/common-fe';

import { OptionKey } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';

import EmptyPlanYear from './EmptyPlanYear';

interface Props {
  value: OptionKey;
  onChange: (value: OptionKey) => void;
}

const PlanYearFilter: React.FC<Props> = ({ value, onChange }) => {
  const { list: options } = usePlanYearsQuery();
  const { observingOrganization } = useCurrentOrganization();

  const icon = useMemo(() => {
    if (!options.length) {
      return <EmptyPlanYear orgLevel={observingOrganization.type || ''} />;
    }
    return null;
  }, [observingOrganization.type, options.length]);
  return (
    <Box
      direction="row"
      width="regular"
      margin={{ left: 's' }}
    >
      <SelectDropdown
        id="purpose"
        name="Purpose"
        disabled={!options.length}
        placeholder="Select plan year"
        value={value}
        onChange={onChange}
        singleMode
        activeTitle
        rightIcon={icon}
        options={options}

      />

    </Box>
  );
};

export default PlanYearFilter;
