import Empty from '@/assets/placeholders/empty.png';

import { WhiteLabelTheme } from '../types';

import CardBackground from './assets/card-background.svg';
import CardLogo from './assets/card-logo.svg';
import ExpressSetupBanner from './assets/expressSetupBanner.svg';
import LogoIcon from './assets/logo.svg';
import SmallLogoIcon from './assets/minify-logo.svg';
import QrCodeLogo from './assets/qrCodeLogo.svg';
import SidebarLogoIcon from './assets/sidebar-logo.svg';
import SidebarSmallLogoIcon from './assets/sidebar-minify-logo.svg';

const buttonColors = {
  button: '#FDB71C',
  buttonHover: '#D9A126',
  buttonActive: '#AA7A11',

  buttonSecondary: '#FDB71C',
  buttonSecondaryContainer: '#FFE6AE',
  buttonSecondaryActive: '#AA7A11',


};
const navigationColors = {
  navigationBackground:  '#502D3C',
  navigationBorder:  '#4E596B',
  navigationAccent:  '#FDB71C',
  navigationAccent2:  '#FDB71C',
  navigationTextDefault:  '#FFFFFF',
  navigationTextAccent:  '#502D3C',
  navigationIconDefault:  '#FFFFFF',
  navigationIconAccent:  '#502D3C',
  navigationAccentHover:  '#FDB71C',
  navigationAccentActive:  '#FDB71C',
};
const backgroundColors = {
  border: '#999B9E',
  border1: '#E5E9F0',
  border2: '#EEF1F4',
  module: '#F7F3F1',
  canvas: '#FFFFFF',
  stressContainer: '#4F5557',
  accentActive: '#FDB71C',
  accentBorder: '#999B9E',
  accentContainer: '#FFD881',
  background1: '#502D3C',
  background2: '#502D3C',
  background3: '#502D3C',
  popupBg2: '#0F16210D',
  legend:'#F5D07F',
  
};

const baseColors = {
  ...buttonColors,
  ...navigationColors, 
  ...backgroundColors,
  textTitle: '#000000',
  textBody: '#000000',
  textActive: '#502D3C',
  textActiveHover: '#422230',

  ancor: '#502D3C',
  ancorHover: '#422230',
  activeButtonText: '#422230',
  paginationActiveItem: '#502D3C',
  buttonText: '#502D3C',
  sidebarDelimiter: '#D9A126',
 
  tableHeader: '#502D3C',

  textSecondary: '#6B7485',
  textDisabled: '#9BA3B3',
  textOnColor: '#FFFFFF',
  textAccent: '#422230',
  textAccentHover: '#D9A126',
  textAccentActive: '#AA7A11',

  iconPrimary: '#000000',
  iconActive: '#502D3C',
  iconActiveHover: '#422230',

  iconSecondary: '#6B7485',
  iconOnColor: '#FFFFFF',
  iconAccent: '#FDB71C',
  iconAccentHover: '#D9A126',
  iconAccentActive: '#AA7A11',


  success: '#19B269',
  successBorder: '#B8E7D1',
  successContainer: '#E8F7F0',

  warning: '#F9C134',
  warningBorder: '#FDECC0',
  warningContainer: '#FEF9EB',

  danger: '#E72626',
  dangerBorder: '#F8BCBC',
  dangerContainer: '#FDE9E9',
  dangerHover: '#A61C18',
  dangerActive: '#66120A',

  info: '#176AF6',
  infoBorder: '#B7D1FC',
  infoContainer: '#E8F0FE',

  transactionDental: '#176AF6',
  transactionVision: '#481A54',
  transactionMedical: '#00C3B3',
  transactionOther: '#FF9D0A',
  transactionParking: '#323D9B',
  transactionTransit: '#CE8258',
  transactionAdoption: '#0F7970',
  transactionDependentCare: '#CC3381',
  transactionPharmacy: '#D83B00',
  transactionLifestyle: '#D3375C',
  transactionWellness: '#D3375C',
  transactionPremium: '#3628D1',
  transactionTravel: '#F26B31',
  refund: '#588C0E',
  transactionDefault: '#5E697E',
  claimTransferInternal: '#E5E9F0',
  nonClaimIcon: '#151F6D',
  nonClaimBg: '#E5E9F0',

  planTypeHSA: '#2EA12B',
  planTypeHCFSA: '#FA802A',
  planTypeDCAP: '#CC3381',
  planTypeHRA: '#134E8F',
  planTypeCommuterTransit: '#CE8258',
  planTypeCommuterParking: '#323D9B',
  planTypeAdoption: '#0F7970',
  planTypeLifestyle: '#D3375C',
  planTypeWellness: '#D3375C',
  planTypeSpecialty: '#00B5EE',
  planTypeTravel: '#F26B31',
  planTypeRx: '#7909AD',
  planTypeDental: '#176AF6',
  planTypeVision: '#481A54',
  planTypeMedical: '#00C3B3',
  
  lockedNull: '#000000',
  processingPending: '#F9C134',
  needsHelp: '#FF9D0A',
  paidActiveComplete: '#19B269',
  partiallyPaid: '#B9CC29',
  // waitingForFunds
  waitingForFunds: '#8EB2A1',
  //
  deniedErrorExpired: '#E72626',
  uploading: '#9BA3B3',
  uploadedApproved: '#176AF6',
  loa: '#00B5EE',
  retirement: '#CE8258',
  future: '#7909AD',
  investmentsfuture: '#7909AD',
  investmentsFund1: '#00C3B3',
  investmentsFund2: '#481A54',
  investmentsFund3: '#D83B00',
  investmentsFund4: '#176AF6',
  investmentsFund5: '#FF9D0A',
  investmentsFund6: '#078F84',
  investmentsFund7: '#323D9B',
  investmentsFund8: '#F3C626',
  investmentsFund9: '#A528C6',
  investmentsFund10: '#0C95D0',
  investmentsFund11: '#619C00',
  investmentsFund12: '#CC3381',

  // new
  partner: '#D83B00',
  distributor: '#904D27',
  employer: '#174FBC',
  subsidiary: '#0F7970',
  subgroup: '#481A54',
  claimProcessor: '#176AF6',
  employee: '#0CAD09',
  dependent: '#5E1696',
  noAssignee: '#B8BFCC',

  claimParticipant: '#CC3381',
  claimSystem: '#18A0FB',
  claimCardProcessor: '#19B269',
};
export default {
  title: 'Admin Rippling',
  colors: {
    
    ...baseColors,
    loginTitleColor: baseColors.textBody,
    //
    popup: 'rgba(0, 0, 0, 0.5)',
    popup2: 'rgba(15, 22, 33, 0.05)',
    fade1: 'linear-gradient(360deg, #FAFAFA 0%, rgba(255, 255, 255, 0) 100%)',
    fade2: 'linear-gradient(180deg, #FAFAFA 0%, #F4F4F4 100%)',
  },
  logo: LogoIcon,
  minifyLogo: SmallLogoIcon,
  font: '\'Red Hat Text\'', // Also you need add font import to global.scss example: @import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  titleFont: '\'Red Hat Text\'',
  tetriaryAccentButton: true,
  images: {
    banner: Empty,
    bannerCloud: Empty,
    cardBackground: CardBackground,
    lgLoginBackground: Empty,
    loginBackgroundClouds: Empty,
    cardLogo: CardLogo,
    sidebarLogo: SidebarLogoIcon,
    sidebarMinifyLogo: SidebarSmallLogoIcon,
    qrCodeLogo: QrCodeLogo,
    investmentsBannerRight: Empty,
    reimburseMeSidebar: '',
    verificationBackground: Empty,
    expressSetupBannerBackground: ExpressSetupBanner,
  },
  fonts: {
    sidebarWeight: 500,
    sidebarFontSize: '14px',
    reimbursementWeight: 500,
    sizes: {
      small: {
        size: '12px',
        height: '16px',
        maxWidth: '336px',
      },
      '3xl': {
        size: '30px',
        height: '40px',
        maxWidth: '1008px',
      },
      '2xl': {
        size: '24px',
        height: '30px',
        maxWidth: '816px',
      },
    },
  },
  rounds: {
    largeRound: '0px',
    moduleRound: '0px',
    container1Round: '0px',
    container2Round: '0px',
    checkboxRound: '0px',
    fieldRound: '0px',
    button1Round: '0px',
    button2Round: '0px',
    dropdownItemRound: '0px',
    snackbarRound: '0px',
    smallRound: '0px',
  },

  shadows: {
    default: '0px 1px 7px rgba(0, 0, 0, 0.04);',
    hover: '0px 2px 13px rgba(0, 0, 0, 0.10);',
    active: '0px 8px 25px rgba(253, 183, 28, 0.2);',
    highlighted: '0px 6px 16px rgba(253, 183, 28, 0.5);',
  },
  favicon: '/rippling/favicon.ico',
  favicon16: '/rippling/favicon-16x16.png',
  favicon32: '/rippling/favicon-32x32.png',
  faviconAppleTouch: '/rippling/apple-touch-icon-fidelity.png',
} as WhiteLabelTheme;
