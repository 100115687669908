import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { Organization } from './useGetOrganization.query';

const QUERY_KEY = 'GET_ORGANIZATIONS_HIERARCHY_QUERY';

const formatData = (organizations: Organization[]) => organizations?.map(
  (organization: Organization) => ({
    ...organization,
    id: toString(organization.id),
  }),
);

interface Props {
  searchValue?: string;
  isSystemLevel?: boolean;
  isCompanyLevel?: boolean;
  partnerParentId?: string;
  distributorParentId?: string;
}

const useGetOrganizationHierarchyQuery = (props: Props) => {
  const {
    searchValue,
    isCompanyLevel,
    partnerParentId,
    distributorParentId,
  } = props;
  const { isLoading, data } = useQuery(
    [
      QUERY_KEY,
      searchValue,
    ],
    () => api.get(PATHS.GET_ORGANIZATIONS_HIERARCHY, ...searchValue ? [{ params: { search_value: searchValue } }] : []),
    { enabled: !isCompanyLevel && !!searchValue },
  );

  const searchedPartners = useMemo(() => {
    if (data?.data?.children?.length) {
      return formatData(data?.data?.children) as Organization[];
    }

    return undefined;
  }, [data]);
  const searchedDistributors = useMemo(() => {
    if (searchedPartners && partnerParentId) {
      return formatData(searchedPartners.find(
        (partner: Organization) => toString(partner.id) === partnerParentId,
      )?.children) as Organization[];
    }

    return undefined;
  }, [
    partnerParentId,
    searchedPartners,
  ]);
  const searchedCompanies = useMemo(() => {
    if (searchedDistributors && distributorParentId) {
      return formatData(searchedDistributors.find(
        (distributor: Organization) => toString(distributor.id) === distributorParentId,
      )?.children) as Organization[];
    }

    return undefined;
  }, [
    distributorParentId,
    searchedDistributors,
  ]);

  return {
    searchedPartners,
    searchedDistributors,
    searchedCompanies,
    isLoading,
    data: data?.data,
  };
};

export default useGetOrganizationHierarchyQuery;
