import { StatementSettings } from '@/modules/employer/components/SetupEmployer/Communication/Communication.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  state: StatementSettings;
  handleSetValues: (values: StatementSettings) => void;
  handleReset: () => void;
}

export const DEFAULT_VALUES: StatementSettings = {
  statementType: undefined,
  statementFrequencyType: undefined,
};

const useStatementsSettingsStore = storeCreator<State>((set) => ({
  state: DEFAULT_VALUES,
  handleReset: () => set(() => ({ state: DEFAULT_VALUES })),
  handleSetValues: (state: StatementSettings) => set(() => ({ state })),
}));

export default useStatementsSettingsStore;
