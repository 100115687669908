import React from 'react';
import { Box } from '@common-fe/common-fe';
import styled, { css } from 'styled-components';

export enum TreeIndicatorMode {
  Up = 'up',
  Down = 'down',
  DownLeft = 'downLeft',
  UpLeft = 'upLeft',
  // DownLeft = ' downLeft',
}
interface TreeIndicatorProps {
  mode?:TreeIndicatorMode
}
const TreeIndicatorBlock = styled(Box)<TreeIndicatorProps>`
  width: 100%;
  height: 100%;
  
  border-left: ${({ theme }) => `2px solid ${theme.colors.border}`};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.border}`};
  ${({ mode, theme }) => {
    if (mode === TreeIndicatorMode.Up) {
      return css`
        border-radius: none;
      `;
    }
    if (mode === TreeIndicatorMode.Down) {
      return css`
        border-radius: none;
        border-bottom: none ;
      `;
    }
    if (mode === TreeIndicatorMode.DownLeft) {
      return css`
         border-radius: ${theme.rounds.container1Round} 0 0 0;
         border-bottom: none;
         border-top:  2px solid ${theme.colors.border};
      `;
    }
    return css`
      border-radius: 0 0 0 ${theme.rounds.container1Round};
    `;
  }}
`;
interface DelimiterProps {
  height?: number | null;
  isThirdLevel?: boolean;
}

export const TreeCircle = styled(Box)`
  position: absolute ;
  top: -6px;
  right:-5px;
  width: 10px;
  height: 10px;
  min-height:10px ;
  min-width: 10px ;
  border-radius: 50%;
`;
const TreeArrow = styled(Box)`
  position: absolute ;
  bottom: 5px;
  left: 0;
  &::before {
    content: '';
    position: absolute;
    border-radius: 4px;
    transform: rotate(-45deg);
    width: 12px;
    height: 2px;
    left: -1px;
    background:${({ theme }) => theme.colors.border};
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px;
    transform: rotate(-135deg);
    width: 12px;
    height: 2px;
    right: -3px;
    background:${({ theme }) => theme.colors.border};
  }
  
`;

const UpDelimiterWrapper = styled(Box)<DelimiterProps>`
  position: absolute ;
  top: ${({ height }) => (height ? `-${height}px` : '-45px')} ;
  right: 43%;
  bottom:${({ height }) => (height ? '0' : '23px;')} ; 
`;
const LeftDelimiterWrapper = styled(Box)<DelimiterProps>`
  position: absolute ;
  top: ${({ height }) => (height ? `-${height}px` : '-58px')} ;
  right:-10px;
  display: inline-block;
  min-width: ${({ isThirdLevel }) => (isThirdLevel ? '21px' : '37px')} ;
  left: ${({ isThirdLevel }) => (isThirdLevel ? '-21px' : '-36px')} ;
  bottom:${({ height }) => (height ? '0' : '11px;')} ; 
`;
const DownLeftDelimiterWrapper = styled(Box)<DelimiterProps>`
  position: absolute ;
  top: -2px;
  bottom: ${({ height }) => (height ? `-${height}px` : '-100px')};
  right:-10px;
  display: inline-block;
  min-width: ${({ isThirdLevel }) => (isThirdLevel ? '21px' : '37px')} ;
  left: ${({ isThirdLevel }) => (isThirdLevel ? '-21px' : '-36px')} ;
`;
const DownDelimiterWrapper = styled(Box)<DelimiterProps>`
  position: absolute ;
  top: 23px;
  bottom: -68px;
  right:-10px;


  left: 11px;
`;

type Props = TreeIndicatorProps & DelimiterProps ;
const TreeIndicator: React.FC<Props> = ({
  mode, height, isThirdLevel,
}) => {
  if (mode === TreeIndicatorMode.Up) {
    return (
      <UpDelimiterWrapper isThirdLevel={isThirdLevel} height={height || 0}>
        <TreeIndicatorBlock mode={mode} />

      </UpDelimiterWrapper>
    );
  }
  if (mode === TreeIndicatorMode.Down) {
    return (
      <DownDelimiterWrapper isThirdLevel={isThirdLevel} height={height || 0}>
        <TreeIndicatorBlock mode={mode} />
        <TreeArrow />

      </DownDelimiterWrapper>
    );
  }
  if (mode === TreeIndicatorMode.DownLeft) {
    return (
      <DownLeftDelimiterWrapper isThirdLevel={isThirdLevel} height={height || 0}>
        <TreeIndicatorBlock mode={mode} />
        <TreeArrow />

      </DownLeftDelimiterWrapper>
    );
  }
  return (
    <LeftDelimiterWrapper isThirdLevel={isThirdLevel} height={height || 0}>
      <TreeIndicatorBlock />

    </LeftDelimiterWrapper>
  );
};

export default TreeIndicator;
