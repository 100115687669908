import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import { EnrollmentMatrixPayload,useStore as useEnrollmentMatrixStore } from '../EnrollmentMatrix/stores/useEnrollmentMatrix.store';

export type ComplexConfigurationPayload = EnrollmentMatrixPayload;

export default () => {
  const setEnrollmentMatrixStore = useEnrollmentMatrixStore((state) => state.setState);

  const setSourceEnrollmentMatrixStore = useEnrollmentMatrixStore((state) => state.setSourceState);

  const showErrorsEnrollmentMatrixStore = useEnrollmentMatrixStore((state) => state.setShowErrors);

  const handleResetEnrollmentMatrixStore = useEnrollmentMatrixStore((state) => state.handleReset);

  const setEnrollmentMatrixErrors = useEnrollmentMatrixStore((state) => state.setErrors);

  const setEnrollmentMatrixErrorFields = useEnrollmentMatrixStore((state) => state.setErrorFields);

  const handleSetSourceState = useCallback((values: Partial<ComplexConfigurationPayload>) => {
    setSourceEnrollmentMatrixStore(values);
  }, [
    setSourceEnrollmentMatrixStore,
  ]);

  const handleSetState = useCallback((values: Partial<ComplexConfigurationPayload>) => {
    setEnrollmentMatrixStore(values);
    handleSetSourceState(values);
  }, [handleSetSourceState,
    setEnrollmentMatrixStore,
  ]);

  const handleReset = useCallback(() => {
    handleResetEnrollmentMatrixStore();
  }, [handleResetEnrollmentMatrixStore]);

  const handleShowErrors = useCallback((value: boolean) => {
    showErrorsEnrollmentMatrixStore(value);
  }, [showErrorsEnrollmentMatrixStore]);

  const handleSetErrors = useCallback((values: Partial<ComplexConfigurationPayload>) => {
    setEnrollmentMatrixErrors(values);
  }, [
    setEnrollmentMatrixErrors,
  ]);

  const handleSetErrorFields = useCallback(
    (values: Partial<ErrorsField<ComplexConfigurationPayload>>) => {
      setEnrollmentMatrixErrorFields(values);
    }, [
      setEnrollmentMatrixErrorFields,
    ],
  );

  return {
    handleSetErrorFields,
    handleReset,
    handleSetState,
    handleSetErrors,
    handleShowErrors,
    handleSetSourceState,
  };
};
