import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import ROUTES from '@/common/routes';
import Topbar from '@/modules/core/components/Topbar/Topbar';
import { useBreadcrumbs } from '@/modules/core/hooks/useBreadcrumbs';
import EmployeesList from '@/modules/employee/Employees/EmployeesList/EmployeesList';
import TopSection from '@/modules/employee/Employees/TopSection';
import { useAuthStore } from '@/modules/user/stores';
import theme from '@/styles/theme';

const EmployeesPage = () => {
  useBreadcrumbs([{
    route: ROUTES.EMPLOYEES,
    title: 'Employees',
  }]);
  const { user } = useAuthStore();

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Box width="100%" margin={{ top: '0' }} height="fit-content">
      <Topbar />
      <Box align="center" pad={{ bottom: 'spacing72' }}>
        <Box width={theme.defaultContentWidth}>
          <TopSection />
          <EmployeesList />
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeesPage;
