import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';

interface Props {
  plansIds?: string[];
  organizationIds?: string[];
  employeeIds?: string[];
  enabled?: boolean;
  payrolls?: string[];
}

const getTotalEmployeeContributionView = async ({
  plansIds,
  payrolls,
  organizationIds,
  employeeIds,
}: Props) => {
  return api.get<number>(PATHS.GET_TOTAL_CONTRIBUTIONS_EMPLOYEE_VIEW, {
    params: {
      ...payrolls && payrolls.length ? { payroll_group_codes: payrolls.join(',') } : {},
      ...plansIds && plansIds.length ? { plan_ids: plansIds.join(',') } : {},
      ...organizationIds && organizationIds.length ? { organization_ids: organizationIds.join(',') } : {},
      ...employeeIds && employeeIds.length ? { employee_ids: employeeIds.join(',') } : {},
    },
  });
};

export const QUERY_KEY = 'useGetTotalEmployeesContributionViewQueryKey';

export const useGetTotalEmployeesContributionViewQuery = ({
  payrolls,
  plansIds,
  organizationIds,
  employeeIds,
  enabled = false,
}: Props) => {
  const {
    data, isLoading, isSuccess,
  } = useQuery(
    [QUERY_KEY],
    () => getTotalEmployeeContributionView({ plansIds, payrolls, organizationIds, employeeIds }),
    {
      enabled,
    },
  );

  const fetchTotalManually = useCallback(async () => {
    return await getTotalEmployeeContributionView({ plansIds, payrolls, organizationIds, employeeIds });
  }, [employeeIds, organizationIds, plansIds, payrolls]);

  return {
    isLoading,
    data: data?.data,
    isSuccess,
    fetchTotalManually,
  };
};
