import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import PaymentMethodField from './PaymentMethodField';

const useDefaultPaymentForm = (addPaymentMethod: () => void) => useMemo<Field[]>(() => [
  {
    name: 'paymentMethod',
    type: FieldTypes.Node,
    label: 'Default payment method',
    isThinMode: true,
    placeholder: 'Default payment method',
    value: <PaymentMethodField onAdd={addPaymentMethod} />,
  },
], [addPaymentMethod]);

export default useDefaultPaymentForm;
