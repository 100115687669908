import React, { useCallback } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { MatchedEmployee } from '@/modules/employer/components/Lockbox/lockbox.types';
import { useEmployeeCardsList } from '@/modules/employer/components/Lockbox/LockboxDetails/hooks';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';

import EmployeesList from './components/EmployeesList';
import LockboxEmployeesEmptyState from './components/LockboxEmployeesEmptyState';

interface Props {
  matchedEmployees?: MatchedEmployee[];
}

const SuggestedEmployees: React.FC<Props> = ({ matchedEmployees }) => {
  const { editableLockbox, setEditableLockbox } = useLockboxDetailsStore();
  const { fields } = useEmployeeCardsList({ matchedEmployees });

  const handleSelectEmployeeId = useCallback((employeeId?: string) => {
    const matchedEmployee = matchedEmployees
      ?.find((matchedEmployee) => matchedEmployee?.employeeId === employeeId);

    if (matchedEmployee) {
      setEditableLockbox({
        employeeId,
        accountId: matchedEmployee?.accountId,
        employeeAccountStatus: matchedEmployee?.accountStatus,
      });
    }
  }, [setEditableLockbox, matchedEmployees]);

  return (
    <Box>
      <Inscription color="textSecondary">Suggested:</Inscription>

      <EmployeesList
        fields={fields}
        selectedEmployeeId={editableLockbox?.employeeId}
        onSelectEmployee={handleSelectEmployeeId}
        emptyState={(
          <Box margin={{ top: 'spacing8' }}>
            <LockboxEmployeesEmptyState title="No Suggestions" />
          </Box>
        )}
      />
    </Box>
  );
};

export default SuggestedEmployees;
