import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import CarrierConfig from '@/modules/CarrierConfig/CarrierConfig';
import { useBreadcrumbs, useRedirectHomeAccess } from '@/modules/core/hooks';
import { useAuthStore } from '@/modules/user/stores';

const CarrierConfigPage = () => {
  const { user } = useAuthStore();

  useBreadcrumbs([
    {
      route: ROUTES.HEALTH_PLAN_HUB,
      title: 'Health Plan Hub',
    },
    {
      route: ROUTES.CARRIER_CONFIG,
      title: 'Carrier Config',
    },
  ]);

  useRedirectHomeAccess([{
    permission: Permissions.VIEW_ALL,
  }]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return <CarrierConfig isPageMode />;
};

export default CarrierConfigPage;
