import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

interface IdentifiersProps {
  SSN?: string;
  EEID?: string | null;
  PEID?: string | null;
  lastName?: string;
  employerName: string;
  partnerName: string;
}

export const useIdentifiersFormFields = (props: IdentifiersProps) => {
  const {
    SSN,
    EEID,
    PEID,
    lastName,
    employerName,
    partnerName,
  } = props;

  const fields = useMemo(() => [
    {
      name: 'tpa_partner',
      type: FieldTypes.Text,
      label: 'TPA/Partner',
      placeholder: 'TPA/Partner',
      defaultValue: partnerName || '',
      disabled: true,
    },
    {
      name: 'employer',
      type: FieldTypes.Text,
      label: 'Employer',
      placeholder: 'Employer',
      defaultValue: employerName || '',
      disabled: true,
    },
    ...EEID
      ? [{
        name: 'employer_employee_id',
        type: FieldTypes.Text,
        label: 'Employer Employee ID',
        placeholder: 'Employer Employee ID',
        defaultValue: EEID,
        disabled: true,
      }]
      : [],
    ...PEID
      ? [{
        name: 'partner_employee_id',
        type: FieldTypes.Text,
        label: 'Partner Employee ID',
        placeholder: 'Partner Employee ID',
        defaultValue: PEID,
        disabled: true,
      }] : [],
    ...SSN
      ? [{
        name: 'ssn',
        type: FieldTypes.Text,
        label: 'SSN',
        placeholder: 'SSN',
        defaultValue: `***-**-${SSN}`,
        disabled: true,
      }] : [],
    {
      name: 'last_name',
      type: FieldTypes.Text,
      label: 'Last name',
      placeholder: 'Last name',
      defaultValue: lastName || '',
      value: '',
      disabled: true,
    },
  ], [EEID, PEID, SSN, employerName, lastName, partnerName]);

  return fields as Field[];
};
