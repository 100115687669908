import React, { useMemo } from 'react';
import {
  Box, Heading, Image,
  Inscription,
  NavigationLeftIcon, RawButton, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

// import { VisualImpairment } from '@/modules/core/components';
import LogoIcon from '@/assets/logos/login-logo.svg';
import MDCityIcon from '@/assets/placeholders/city-hd.svg';
import SMCityIcon from '@/assets/placeholders/city-laptop.svg';
import LGCityIcon from '@/assets/placeholders/city-uhd.svg';
// import CloudsIcon from '@/assets/placeholders/clouds.svg';
import theme, { LG_QUERY, MD_QUERY, SM_QUERY } from '@/styles/theme';
import { whiteLabelingConfig } from '@/utils/whiteLabeling';
import { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import {
  BackButtonWrapper,
  BackgroundWrapper, FormWrapper,   Wrapper, } from './SignInWrapper.styles';

const isRmrTheme = whiteLabelingConfig.key === THEMES.rmr;
const logoWidth = isRmrTheme ? 330 : 240;
const logoHeight = isRmrTheme ? 159 : 65;


interface Props {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string;
  isBackButton?: boolean;
  onBack?: () => void;
  flat?: boolean;
}

interface ImageProps {
  size?: typeof SM_QUERY | typeof MD_QUERY | typeof LG_QUERY;
}

const ImageWrapper = styled(Image)<ImageProps>`
  display: none;
  ${({ size }) => size} {
    display: block;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const SignIn: React.FC<Props> = ({
  flat, children, title, subtitle, isBackButton: backButton, onBack,
}) => {

  const BackgroundNode = useMemo(() => {
    if (isRmrTheme) {
      return (
        <BackgroundWrapper >
          <>
            <ImageWrapper src={whiteLabelingConfig?.images?.smLoginBackground || SMCityIcon}
              alt=""
              size={SM_QUERY}
              width="100%"
            />
            <ImageWrapper src={whiteLabelingConfig?.images?.mdLoginBackground || MDCityIcon}
              alt=""
              size={MD_QUERY}
              width="100%"
      
            />
            <ImageWrapper
              src={whiteLabelingConfig?.images?.lgLoginBackground || LGCityIcon}
              alt=""
              size={LG_QUERY}
              width="100%" />
          </>
    
        </BackgroundWrapper>
      );  
    }
    return (
      <BackgroundWrapper imageSrc={whiteLabelingConfig?.images?.lgLoginBackground || LGCityIcon} /> 
    );
  }, []);
  return (
    <Wrapper>
      {BackgroundNode}
      <Box
        flex
        height={{ min: '100px', max: '100px' }}
      />
      <Box
        direction="row"
        style={{
          zIndex: 1,
        }}
        pad={{ horizontal: '100px' }}
      >
        <Box
          direction="column"
          flex
          margin={{
            right: 'spacing96',
            top: '80px',
          }}
          height="large"
        >
          <Image
            src={whiteLabelingConfig?.images?.signInLogo || whiteLabelingConfig?.logo || LogoIcon}
            width={logoWidth}
            alt="logo"
            height={logoHeight}
          />
          <Heading
            size="20px"
            level={3}
            margin={{ top: 'spacing24' }}
            color="loginTitleColor"
            style={{
              maxWidth: isRmrTheme ? '400px' : '600px',
              fontWeight: 400,
            }}
          >
            {whiteLabelingConfig?.loginScreenTitle || 'Radically transforming the consumer benefit experience with an ultra-reliable, automated, and AI-powered solution. '}
          </Heading>
        </Box>
        <Box direction="column">
          <FormWrapper flat={flat} isBackButton={backButton}>
            {backButton && (
              <BackButtonWrapper>
                <Box
                  elevation="default"
                  width="xxxsmall"
                  height="xxxsmall"
                  round="checkboxRound"
                  align="center"
                  justify="center"
                  background="canvas"
                >
                  <RawButton onClick={onBack} data-testid="back-button" type="button">
                    <NavigationLeftIcon size="18px" color="iconPrimary" />
                  </RawButton>
                </Box>
              </BackButtonWrapper>
            )}
            {title && typeof title === 'string' ? (
              <Heading size={theme.spacings.spacing32} color="textTitle" textAlign="center" level={1}>
                {title}
              </Heading>
            ) : title}
            {subtitle && (
              <Inscription size="large" lineHeight="22px" textAlign="center" margin={{ top: 'spacing8' }}>
                {subtitle}
              </Inscription>
            )}
            <Box direction="column" margin={{ top: 'spacing32' }}>
              {children}
            </Box>
          </FormWrapper>
        </Box>
      </Box>
  
      <Box
        flex
        align="end"
        style={{ zIndex: 1 }}
        direction="row"
        margin={{
          bottom: 'spacing32',
          left: 'spacing32',
        }}
      >
        {/* <VisualImpairment /> */}
      </Box>
    </Wrapper>
  );
  
}; 
export default SignIn;
