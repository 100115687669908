import {
  useMutation,
  useQueryClient,
} from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const QUERY_KEY = 'CREATE_SERVICE_PAYMENT';
export const SERVICE_CONFIGS_QUERY_KEY = 'GET_SERVICE_CONFIGS';

interface PaymentRulesDto {
  plan_id: number;
  plan?: Partial<{
    id: number;
    name: string;
  }>;
  max_amount?: number;
  percentage?: number;
}

export interface ServicePaymentPayloadDto {
  id?: string;
  service?: Partial<{
    id: number;
    name: string;
  }>;
  service_id: number;
  processing_tier_id: string;
  health_plan_id: string;
  description?: string;
  claim_value?: number;
  is_applied_deductible?: boolean;
  payment_type: string;
  payment_rules?: PaymentRulesDto[];
  organization_id: number,
}
const useCreateServicePaymentQuery = (isUpdateMode?: boolean, id?: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (payload: ServicePaymentPayloadDto) => (isUpdateMode
      ? api.put(`${PATHS.SERVICE_CONFIGS}/${id}`, payload)
      : api.post(PATHS.SERVICE_CONFIGS, payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SERVICE_CONFIGS_QUERY_KEY);
      },
    },
  );

  return { handleCreate: mutateAsync, isLoading };
};

export default useCreateServicePaymentQuery;
