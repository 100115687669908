import React from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { useBreadcrumbs, useRedirectHomeAccess } from '@/modules/core/hooks';
import CopayAdjudication from '@/modules/HealthPlan/CopayAdjudication';
import { useAuthStore } from '@/modules/user/stores';

const CarrierConfigPage = () => {
  const { user } = useAuthStore();

  useBreadcrumbs([
    {
      route: ROUTES.HEALTH_PLAN_HUB,
      title: 'Health Plan Hub',
    },
    {
      route: ROUTES.COPAY_ADJUDICATION,
      title: 'Copay Adjudication',
    },
  ]);

  useRedirectHomeAccess([{
    permission: Permissions.VIEW_ALL,
  }, {
    permission: Permissions.VIEW_COPAY,
  }]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return <CopayAdjudication isPageMode />;
};

export default CarrierConfigPage;
