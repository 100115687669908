import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  ActionCard,
  AppButton,
  Box,   DropContent,
  PlanActiveCheckedIcon, PlanActiveIcon, Text, } from '@common-fe/common-fe';
import _ from 'lodash';

import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';

import { useSubmitFormStore } from '../../stores';
import SwitchToEditMode from '../SwitchToEditMode';

interface Props {
  onActivate: () => void;
  viewMode?: boolean;
  isTemplateMode?: boolean;
  activateButtonRef?: React.RefObject<HTMLButtonElement>;
  errorMessages: string[];
  successMessages: string[];
  pendingMessages: string[];
}

const ActivateHealthPlan: React.FC<Props> = React.memo(({
  onActivate, viewMode, isTemplateMode,
  errorMessages,
  successMessages,
  pendingMessages,
  activateButtonRef,
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);

  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const handleToggleVisiblePopup = useCallback(() => {
    setVisiblePopup(!visiblePopup);
  }, [visiblePopup]);
  const handleActivate = useCallback(() => {
    onActivate();
    handleToggleVisiblePopup();
  }, [handleToggleVisiblePopup, onActivate]);
  const CardIcon = useMemo(() => {
    if (_.isEmpty(errorMessages) && !_.isEmpty(successMessages) && activateValidate) {
      return (
        <PlanActiveCheckedIcon size="xxlarge" color="iconAccent" />
      );
    }
    return (
      <PlanActiveIcon size="xxlarge" color="iconAccent" />
    );
  }, [activateValidate, errorMessages, successMessages]);

  return (
    <>
      <Access
        accessRequirements={[
          {
            permission: Permissions.ACTIVATE_PLAN,
          },
        ]}
      >
        {
        // It's invisible button to handle ref approach
        }
        <button
          type="button"
          ref={activateButtonRef}
          onClick={onActivate}
          style={{ display: 'none' }}
        >
          <span />
        </button>
        <ActionCard
          modulesTitle="Required attributes"
          title={isTemplateMode ? 'Activate the Health Plan Template' : 'Activate the Health Plan'}
          footer={viewMode ? <SwitchToEditMode isTemplateMode={isTemplateMode} /> : null}
          submitted={activateValidate}
          errorMessages={viewMode ? [] : errorMessages}
          successMessages={viewMode ? [] : successMessages}
          pendingMessages={viewMode ? [] : pendingMessages}
          titleWidth={isTemplateMode ? '350px' : 'auto'}
          icon={
            CardIcon
          }
          minHeight="auto"
        >
          <Box
            direction="column"
            align="center"
            flex="grow"
            pad={{
              left: 'spacing8',
              right: 'spacing8',
            }}
          >

            <DropContent
              visible={visiblePopup}
              content={
                (
                  <Box direction="column" pad="s" width="300px">
                    <Text size="small" margin={{ bottom: 'xs' }}>
                      {isTemplateMode
                        ? 'Ready to activate this health plan template? Take a look at your selections. Once activated, making changes to this health plan template will be limited.'
                        : 'Ready to activate this health plan? Take a look at your selections. Once activated, making changes to this health plan will be limited.'}
                    </Text>
                    <Text size="medium" weight="bold">
                      Would you like to activate?
                    </Text>
                    <Box direction="row" margin={{ top: 'spacing12' }} gap="spacing12">
                      <Box style={{ flex: 1 }}>
                        <AppButton
                          buttonType="secondary"
                          onClick={handleToggleVisiblePopup}
                          width="100%"
                        >
                          Cancel
                        </AppButton>
                      </Box>
                      <Box style={{ flex: 1 }}>
                        <AppButton
                          onClick={handleActivate}
                          width="100%"
                        >
                          Activate
                        </AppButton>
                      </Box>
                    </Box>
                  </Box>
                )
              }
            >
              <Box fill>
                <AppButton
                  disabled={viewMode}
                  onClick={handleToggleVisiblePopup}
                  size="L"
                  width="100%"
                >
                  {isTemplateMode ? 'Activate the Health Plan Template' : 'Activate the Health Plan'}
                </AppButton>
              </Box>
            </DropContent>

          </Box>
        </ActionCard>
      </Access>
    </>
  );
});

export default ActivateHealthPlan;
