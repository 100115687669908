import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface PayClaimsFieldsStatePayload {
  isPayClaimsAbsence: PlanFieldState;
  isPayClaimsOutsideCoverage: PlanFieldState;
}

export const DEFAULT_VALUES: PayClaimsFieldsStatePayload = {
  isPayClaimsAbsence: PlanFieldState.Modifiable,
  isPayClaimsOutsideCoverage: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<PayClaimsFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
