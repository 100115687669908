import React from 'react';
import {
  AppButton,
  Box,
  Text,
  WarningIcon,
} from '@common-fe/common-fe';

import { ModalWrapper } from '@/components';

import { usePayrollCalendarWarningModalStore } from './stores';

const headerText = 'Calendars have been created for all existing plans and payroll groups';
const description = 'Please update existing calendars to make any changes.';

export const PayrollCalendarWarningModal = () => {
  const visible = usePayrollCalendarWarningModalStore((store) => store.modalVisible);
  const onSetVisible = usePayrollCalendarWarningModalStore((store) => store.handleChangeVisibility);
  return (
    <ModalWrapper testId="PayrollCalendarWarningModal_modal_wrapper" visible={visible} onSetVisible={onSetVisible}>
      <Box align="center" pad={{ horizontal: 'spacing72', vertical: 'spacing24' }}>
        <WarningIcon size="48px" />
        <Text
          data-testid="PayrollCalendarWarningModal_modal_header"
          margin={{ vertical: 'spacing12' }}
          textAlign="center"
          size="24px"
          weight={700}
          color="textBody"
        >
          {headerText}
        </Text>
        <Box pad={{ horizontal: 'spacing72' }}>
          <Text
            data-testid="PayrollCalendarWarningModal_modal_help-text1"
            textAlign="center"
            color="textBody"
            margin={{ bottom: 'spacing24' }}
          >
            {description}
          </Text>

        </Box>
        <AppButton
          buttonType="secondary"
          testId="PayrollCalendarWarningModal_modal"
          onClick={() => onSetVisible(false)}
          width="150px"
        >
          Got It!
        </AppButton>
      </Box>
    </ModalWrapper>
  );
};
