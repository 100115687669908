import { ExpressSetupLinksPayload } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { baseStoreCreator } from '@/utils/store';

export const DEFAULT_VALUES: ExpressSetupLinksPayload = {
  isExternalLink: '',
  externalLink: '',
};

export const useStore = baseStoreCreator<ExpressSetupLinksPayload>(DEFAULT_VALUES);

export default useStore;
