import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppButton, Box, SelectDropdown, Text,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import routes from '@/common/routes';
import { useHasAccess, useHistory } from '@/modules/core/hooks';
import {
  useReconciliationBankAccountList,
  useReconciliationReportDetails,
} from '@/modules/employer/queries/useReconciliationBankAccounts.query';

import useReconciliationReportDownloadModal from '../../../ReconciliationReports/components/ReconciliationReportDownloadModal/hooks/useReconciliationReportDownloadModal';

const DROPDOWN_WIDTH = '265px';

export const ReconciliationReportDetailsHeader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { setDownloadReportId } = useReconciliationReportDownloadModal();
  const { formatedData } = useReconciliationReportDetails(id);
  const { response } = useReconciliationBankAccountList();
  const history = useHistory();
  const { setIsModalVisible } = useReconciliationReportDownloadModal();

  useEffect(() => {
    setDownloadReportId(id);
  }, [id, setDownloadReportId]);
  const isHasAccessToDownload = useHasAccess([{
    permission: Permissions.REPORTS
  }]);
  return (
    <Box margin={{ bottom: 'spacing16' }}>
      <Box
        direction="row"
        justify="between"
        align="center"
      >
        <Box flex="grow">
          <Text
            size="xxlarge"
            weight="bold"
            color="textBody"
          >
            {formatedData?.account_description || ''}
          </Text>
        </Box>
        <Box
          flex
          direction="row"
          justify="end"
          align="center"
          height="40px"
        >
          <Box margin={{ right: 'spacing24' }} width={{ min: DROPDOWN_WIDTH, max: DROPDOWN_WIDTH }}>
            <SelectDropdown
              id="category"
              singleMode
              // activeTitle
              ellipsisMode
              name="Reconciliation Report"
              options={response.map((item) => ({
                key: `${item.account_id}`,
                value: `${item.account_id}`,
                title: item.account_description,
              }))}
              value={id}
              onChange={(value) => history.push(routes.RECONCILIATION_REPORT_DETAILS(`${value}`))}
            />
          </Box>
          {isHasAccessToDownload && (
            <AppButton
              width="166px"
              onClick={() => setIsModalVisible(true)}
            >
              Download Report
            </AppButton>
          )}
        
        </Box>
      </Box>
    </Box>
  );
};
