import React, { useCallback } from 'react';
import {
  Box, FlatButton, MinusIcon, PlusIcon,
} from '@common-fe/common-fe';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  testId?: string;
}
const ExpandButton: React.FC<Props> = ({
  onChange, value, testId, disabled,
}) => {
  const handleToggle = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);
  return (
    <Box direction="row">
      <FlatButton
        data-testid={`${testId}_expand-button`}
        onClick={handleToggle}
        disabled={disabled}
      >
        <Box
          width="24px"
          height="24px"
          align="center"
          justify="center"
          round="button2Round"
          border={{ size: 'small', color: 'border1' }}
        >
          {value ? (
            <MinusIcon
              data-testid={`${testId}_expand-button_minus-icon`}
              size="14px"
              color={disabled ? 'iconSecondary' : 'iconPrimary'}
            />
          ) : (
            <PlusIcon
              data-testid={`${testId}_expand-button_plus-icon`}
              size="14px"
              color={disabled ? 'iconSecondary' : 'iconPrimary'}
            />
          )}

        </Box>
      </FlatButton>
    </Box>
  );
};

export default ExpandButton;
