import { Lockbox, LockboxStatus, LockboxType } from '@/modules/employer/components/Lockbox/lockbox.types';
import { storeCreator } from '@/utils/store/store.utils';

export interface EditableLockbox {
  lockboxId?: string;
  employeeId?: string;
  employeeName?: string;
  employeeAccountStatus?: string;
  organizationId?: number;
  organizationPath?: string;
  vendorId?: string;
  accountId?: string;
  accountNumber?: string;
  amount?: string;
  lockboxType?: LockboxType;
  lockboxStatus?: LockboxStatus;
  reason?: string;
  notes?: string;
}

interface State {
  editableLockbox?: EditableLockbox;
  setEditableLockbox: (value: Partial<EditableLockbox>) => void;
  originalLockbox?: Lockbox;
  setOriginalLockbox: (value: Partial<Lockbox>) => void;
  nextLockboxId?: string;
  setNextLockboxId: (value?: string) => void;
  validationErrors: string[];
  setValidationErrors: (errors: string[]) => void;
  reset: () => void;
}

export const useLockboxDetailsStore = storeCreator<State>((set) => ({
  editableLockbox: undefined,
  originalLockbox: undefined,
  nextLockboxId: undefined,
  validationErrors: [],

  setEditableLockbox: (value: Partial<EditableLockbox>) => set((state) => ({
    ...state,
    editableLockbox: {
      ...state.editableLockbox,
      ...value,
    }
  })),

  setOriginalLockbox: (value: Partial<Lockbox>) => set((state) => ({
    ...state,
    originalLockbox: {
      ...state.originalLockbox,
      ...value,
    }
  })),

  setNextLockboxId: (value?: string) => set((state) => ({
    ...state,
    nextLockboxId: value,
  })),

  setValidationErrors: (errors: string[]) => set((state) => ({
    ...state,
    validationErrors: errors,
  })),

  reset: () => set(() => ({
    editableLockbox: undefined,
    originalLockbox: undefined,
    validationErrors: [],
  })),
}));

export default useLockboxDetailsStore;
