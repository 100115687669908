import React from 'react';
import { Box } from '@common-fe/common-fe';

import CustomReports from './CustomReports';
import EmployerInfo from './EmployerInfo';
import EmploymentStatusTimeline from './EmploymentStatusTimeline';
import GeneralInfo from './GeneralInfo';
import HealthPlanClaims from './HealthPlanClaims';
import PaymentMethodInfo from './PaymentMethodInfo';
import PreferredMailingAddress from './PreferredMailingAddress';
import PreferredPhysicalAddress from './PreferredPhysicalAddress';
import Security from './Security';

interface Props {
  isEditMode?: boolean;
  isShowErrors?: boolean;
  children?: React.ReactNode;
}
const PersonalInformation: React.FC<Props> = React.memo(({ isEditMode, children, isShowErrors }) => (
  <Box direction="column" background="module" pad="spacing24" round="container1Round">
    {children}
    <GeneralInfo isShowErrors={isShowErrors} isEditMode={isEditMode} />

    <Box margin={{ top: 'spacing24' }}>
      <CustomReports
        isShowErrors={isShowErrors} 

        isEditMode={isEditMode} 
      /> 
    </Box>
    <Box margin={{ top: 'spacing24' }}>
      <PreferredMailingAddress isShowErrors={isShowErrors} isEditMode={isEditMode} />
    </Box>
    <Box margin={{ top: 'spacing24' }}>
      <PreferredPhysicalAddress isShowErrors={isShowErrors} isEditMode={isEditMode} />
    </Box>
    <Box margin={{ top: 'spacing24' }}>
      <PaymentMethodInfo />
    </Box>
    <Box margin={{ top: 'spacing24' }}>
      <Security viewMode />
    </Box>
    <Box margin={{ top: 'spacing24' }}>
      <EmployerInfo isShowErrors={isShowErrors} isEditMode={isEditMode} />
    </Box>
    <EmploymentStatusTimeline />
    <Box margin={{ top: 'spacing24' }}>
      <HealthPlanClaims isEditMode={isEditMode} />
    </Box>
  </Box>
));

export default PersonalInformation;
