import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { formatSnakeCaseToCamelCase } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { over, toString } from 'lodash';

import {
  DEFAULT_DATE_FORMAT,
  NO_KEY,
  NON_ASSIGNABLE_VALUE,
  NON_ASSIGNABLE_VALUE_PAYLOAD,
  OrganizationTypes,
  YES_KEY,
} from '@/common/constants';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';
import { useCommunicationChannelStore } from '@/modules/employer/components/SetupEmployer/Communication/stores';
import useAIAdjudicationStore from '@/modules/employer/components/SetupEmployer/Features/AIAdjudication/stores/useAIAdjudication.store';
import useLinkManagementStore from '@/modules/employer/components/SetupEmployer/LinkManagement/useLinkManagementStore.store';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import {
  AddressesDtoPayload,
  EmployerDtoPayload,
  NormalPropertiesDtoPayload,
  OverridablePropertiesDtoPayload,
} from '@/modules/employer/types';
import { booleanToYesOrNo } from '@/utils/handlers';

import { useEmailConfigurationStore } from '../components/SetupEmployer/Communication/EmailConfiguration/stores';
import { useExpressSetupLinksStore } from '../components/SetupEmployer/Features/ExpressSetupLinks/stores';
import { useOrphanConfigurationStore } from '../components/SetupEmployer/Features/OrphanConfiguration/stores';
import { useFileManagerSettingsStore } from '../components/SetupEmployer/FilesAndImports/FileManagerSettings/stores';
import { useCustomReportsStore } from '../components/SetupEmployer/Reports/CustomReports';
import { useStore as useSetupEmployerStore } from '../store/useSetupEmployer.store';

const PAYROLL_KEY = 'external_payroll_contributions_account';
const ADMIN_FEES_ACC_KEY = 'external_administrator_fees_account';

const getAddresses = (addresses: AddressesDtoPayload[] = []) =>
  addresses.map((addresse: AddressesDtoPayload) => ({
    id: `${addresse.id || ''}`,
    line1: addresse?.line1,
    line2: addresse?.line2,
    city: addresse?.city,
    state: addresse?.state,
    zipcode: addresse?.zipcode,
    preferred: addresse?.preferred,
    countryId: addresse?.country_id,
    addressType: addresse?.address_type,
  }));
const getOverridableProperties = (overridableProperties?: OverridablePropertiesDtoPayload) => ({
  themeId: overridableProperties?.theme_id,
  externalLink: overridableProperties?.external_link,
  isExternalLink: booleanToYesOrNo(overridableProperties?.is_external_link),
  trueSsn: overridableProperties?.true_ssn,
  cardOffered: overridableProperties?.card_offered,
  allowDependentCard: overridableProperties?.allow_dependent_card,
  bin: overridableProperties?.bin_setup,
  prin: overridableProperties?.prin_setup,
  customerServiceNumber: overridableProperties?.customer_service_number || '',
  customerServiceUrlName: overridableProperties?.customer_service_url_name || '',
  customerServiceUrlLink: overridableProperties?.customer_service_url_link || '',
  customerServiceEmail: overridableProperties?.customer_service_email || '',
  ...(typeof overridableProperties?.customer_service_is_enabled === 'boolean'
    ? {
      customerServiceIsEnabled: overridableProperties?.customer_service_is_enabled,
    }
    : {}),

  customReportField1: overridableProperties?.custom_reporting_field_1_name || '',
  customReportDisplayedField1: overridableProperties?.is_custom_reporting_field_1_enabled,

  customReportField2: overridableProperties?.custom_reporting_field_2_name || '',
  customReportDisplayedField2: overridableProperties?.is_custom_reporting_field_2_enabled,

  customReportField3: overridableProperties?.custom_reporting_field_3_name || '',
  customReportDisplayedField3: overridableProperties?.is_custom_reporting_field_3_enabled,

  customReportField4: overridableProperties?.custom_reporting_field_4_name || '',
  customReportDisplayedField4: overridableProperties?.is_custom_reporting_field_4_enabled,

  customReportField5: overridableProperties?.custom_reporting_field_5_name || '',
  customReportDisplayedField5: overridableProperties?.is_custom_reporting_field_5_enabled,

  ssoOnly: overridableProperties?.sso_only,
  allowEmployeesEditAddress: overridableProperties?.allow_employees_to_edit_address,
  url: overridableProperties?.url,
  electronicFundingModeId: overridableProperties?.electronic_funding_mode_id,
  checkFundingModel: overridableProperties?.check_funding_model,
  hsaCustodianId: overridableProperties?.hsa_custodian_id,
  allowAdminContribution: overridableProperties?.allow_manual_contribution,
  allowAssumedContribution: overridableProperties?.allow_scheduled_contribution,
  externalAdministratorFeesAccountMethod: overridableProperties?.external_administrator_fees_account_method,
  externalPayrollContributionsAccountMethod:
    overridableProperties?.external_payroll_contributions_account_method,
  externalReplenishmentAccountMethod: overridableProperties?.external_replenishment_account_method,
  cardUrl: overridableProperties?.card_url,
  cardEmployerName: overridableProperties?.card_employer_name,
  cardPhoneNumber: overridableProperties?.card_phone_number,
  communicationChannels: overridableProperties?.communication_channels,
  emailTemplate: overridableProperties?.email_template_type,
  communicationCategory: overridableProperties?.organization_communication_categories,
  ...(overridableProperties?.payroll_contributions_hold_period === undefined
    ? {}
    : {
      payrollContributionsHoldPeriod: `${overridableProperties.payroll_contributions_hold_period}`,
    }),
  emailConfiguration: {
    emailLogoType: overridableProperties?.email_logo_type,
    brandingIllustration: `${overridableProperties?.email_illustration_id || overridableProperties?.email_illustration_id === 0
      ? overridableProperties?.email_illustration_id
      : ''
    }`,
    isDefaultLinks: overridableProperties?.is_default_link,
    customEmailLogo: overridableProperties?.email_logo_path,
    emailTheme: `${overridableProperties?.email_theme}`,
  },
  linkManagement: {
    domainAddress: overridableProperties?.email_domain || '',
    privacyPolicyLink: overridableProperties?.privacy_policy_link || '',
    termAndConditionsLink: overridableProperties?.terms_and_conditions_link || '',
    aboutUsLink: overridableProperties?.about_us_link || '',
    pricingConsent: overridableProperties?.pricing_consent || '',
  },
  idSourceId: overridableProperties?.id_source_id,
  replenishmentFrequencyType: overridableProperties?.replenishment_frequency_type,
  replenishmentDayNumber: overridableProperties?.replenishment_day_number,
  isReimbursementAllowed: overridableProperties?.is_reimbursement_allowed,
  allowedPaymentMethods: overridableProperties?.allowed_payment_methods,
  isBillPayAllowed: overridableProperties?.is_bill_pay_allowed,
  isClaimOffsetsAllowed: overridableProperties?.is_claim_offsets_allowed,
  sessionTimeoutWarn: overridableProperties?.session_timeout_warn,
  sessionTimeoutLogout: overridableProperties?.session_timeout_logout,
  claimsAssignmentNumber: overridableProperties?.claims_assignment_number,
  isFileManagerAccessEnabled: overridableProperties?.is_file_manager_access_enabled,
  isFileAutoPostEnabled: booleanToYesOrNo(overridableProperties?.is_file_auto_post_enabled),
  allowedFileTypes: overridableProperties?.allowed_file_types,
  isSendFiles: !!overridableProperties?.file_location,
  fileLocation: overridableProperties?.file_location,
  isSameErrorReportLocation: !overridableProperties?.error_report_location,
  errorReportLocation: overridableProperties?.error_report_location,

  replenishmentMinValue: overridableProperties?.prefund_replenishment_min_value,
  replenishmentPercent: overridableProperties?.prefund_replenishment_percent,
  replenishmentCreepMin: overridableProperties?.prefund_replenishment_creep_min,
  replenishmentCreepPercent: overridableProperties?.prefund_replenishment_creep_percent,
  statementType: overridableProperties?.statement_type,
  statementFrequencyType: overridableProperties?.statement_frequency_type,
  ...(overridableProperties?.ee_contributions_hold_period === undefined
    ? {}
    : {
      eeContributionsHoldPeriod: `${overridableProperties.ee_contributions_hold_period}`,
    }),
  ...(overridableProperties?.minimum_payment_reimbursement_amount
    ? {
      minimumPaymentReimbursementAmount: toString(
        overridableProperties?.minimum_payment_reimbursement_amount
      ),
    }
    : {}),
  ...(overridableProperties?.waiting_for_funds_continue_payment
    ? {
      waitingForFundsContinuePayment: overridableProperties?.waiting_for_funds_continue_payment,
    }
    : {}),
  ...(overridableProperties?.waiting_for_funds_expiration
    ? {
      waitingForFundsExpiration: overridableProperties?.waiting_for_funds_expiration,
    }
    : {}),
  numberOfDaysBeforePlanStart: overridableProperties?.number_of_days_before_plan_start || '14',
  aiAdjudication: overridableProperties?.ai_adjudication,
  aiConfidenceLevel: overridableProperties?.ai_confidence_level,
  authenticationTypeId: overridableProperties?.authentication_type_id,
  accountLockoutTime: overridableProperties?.account_lockout_time,

  partnerAppAndroidLink: overridableProperties?.partner_app_android_link,
  partnerAppIosLink: overridableProperties?.partner_app_ios_link,
  partnerMobileApp: overridableProperties?.partner_mobile_app,
  mobileAppThemeId: overridableProperties?.mobile_app_theme_id,
  prefundInitialDelayDate: overridableProperties?.prefund_initial_delay_date
    ? dayjs(overridableProperties?.prefund_initial_delay_date).format(DEFAULT_DATE_FORMAT)
    : null,
  cardProcessingStartDate: overridableProperties?.card_processing_start_date
    ? dayjs(overridableProperties?.card_processing_start_date).format(DEFAULT_DATE_FORMAT)
    : null,

  isEmployerOrphan: booleanToYesOrNo(overridableProperties?.is_organization_orphan),
  startOrphanProcessDays: overridableProperties?.orphan_start_timing_days
    ? toString(overridableProperties?.orphan_start_timing_days)
    : undefined,
  orphanEmployer: overridableProperties?.orphan_organization_id
    ? toString(overridableProperties?.orphan_organization_id)
    : undefined,
  participantPortalLink: overridableProperties?.participant_portal_link,
  adminPortalLink: overridableProperties?.admin_portal_link,
});
const getNormalProperties = (properties: NormalPropertiesDtoPayload) => {
  const normalProperties = (Object.keys(properties) as Array<keyof NormalPropertiesDtoPayload>)
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    .reduce((acc: any, key) => {
      let value = properties[key];

      if (value === NON_ASSIGNABLE_VALUE_PAYLOAD && (key === PAYROLL_KEY || key === ADMIN_FEES_ACC_KEY)) {
        value = NON_ASSIGNABLE_VALUE;
      }

      if (value) {
        acc[formatSnakeCaseToCamelCase(key)] = typeof value === 'boolean' ? value : `${value}`;
      }

      return acc;
    }, {});

  return Object.keys(normalProperties).length ? { ...normalProperties } : {};
};

const formatData = (data?: EmployerDtoPayload) => ({
  id: toString(data?.id),
  isActivationStarted: Boolean(data?.activation_started),
  externalIdentifier: data?.external_identifier,
  name: data?.name,
  type: data?.type,
  primaryContactId: data?.normal_properties?.invoicing_contact_id,
  parentId: data?.parent_id,
  grandparentId: `${data?.grandparent_id || data?.organization_path?.split('\\')[2] || ''}`,
  ...(data?.type === OrganizationTypes.distributor
    ? {}
    : {
      distributorId: data?.organization_path?.split('\\')[3],
    }),
  addresses: getAddresses(data?.addresses),
  ...(data?.overridable_properties
    ? {
      overridableProperties: getOverridableProperties(data?.overridable_properties),
    }
    : {}),
  normalProperties: {
    ...getNormalProperties(data?.normal_properties || {}),
  },
  shortName: data?.short_name || '',
  businessEntityType: `${data?.business_entity_type || ''}`,
  organizationPath: data?.organization_path || '',
  organizationPhone: {
    number: data?.organization_phone?.number || '',
    phoneExtension: data?.organization_phone?.phone_extension || '',
  },
  organizationStatusType: data?.organization_status_type,
  isTerminated: data?.organization_status_type === EmployerStatus.Terminated,
  createdAt: data?.created_at,
  validFrom: data?.organization_status_valid_from,
  terminationDate: data?.termination_date ? dayjs(data.termination_date).format(DEFAULT_DATE_FORMAT) : null,
});

const useGetEmployer = (employerId?: string) => {
  const { id } = useParams<{ id: string }>();
  const fetchedOrgId = useMemo(() => employerId || id, [employerId, id]);
  const { setAIAdjudication } = useAIAdjudicationStore();
  const handleSetCommunicationChannelStore = useCommunicationChannelStore((state) => state.setState);
  const handleSetEmailConfigurationState = useEmailConfigurationStore((state) => state.setState);
  const handleSetEmailConfigurationSourceState = useEmailConfigurationStore((state) => state.setSourceState);
  const setOrphanConfigurationSourceState = useOrphanConfigurationStore((state) => state.setSourceState);
  const setExpressSetupLinksSourceState = useExpressSetupLinksStore((state) => state.setSourceState);
  const handleSetLinkManagementState = useLinkManagementStore((state) => state.setState);
  const handleSetLinkManagementSourceState = useLinkManagementStore((state) => state.setSourceState);

  const handleSetReportsState = useCustomReportsStore((state) => state.setState);
  const handleSetReportsSourceState = useCustomReportsStore((state) => state.setSourceState);

  const handleSetFileManagerSettingsState = useFileManagerSettingsStore((state) => state.setState);
  const handleSetFileManagerSettingsSourceState = useFileManagerSettingsStore(
    (state) => state.setSourceState
  );

  const setPrimaryContactId = useSetupEmployerStore((state) => state.setPrimaryContactId);

  const { isLoading, isSuccess, isError, data, refetch } = useGetOrganizationById(fetchedOrgId);

  const formattedData = useMemo(() => formatData(data?.data), [data]);

  const newEmployer = {
    EmployeeIdField: formattedData.externalIdentifier,
    employerName: formattedData.name,
    partner: formattedData.grandparentId,
    street: formattedData.addresses[0]?.line1,
    street_2: formattedData.addresses[0]?.line2,
    city: formattedData.addresses[0]?.city,
    state: formattedData.addresses[0]?.state,
    zipCode: formattedData.addresses[0]?.zipcode,
  };

  useEffect(() => {
    if (formattedData) {
      setPrimaryContactId(`${formattedData.primaryContactId || ''}`);
      handleSetCommunicationChannelStore({
        communicationChannel: formattedData?.overridableProperties?.communicationChannels,
        emailTemplate: formattedData?.overridableProperties?.emailTemplate,
      });
      setAIAdjudication({
        aiAdjudication: formattedData?.overridableProperties?.aiAdjudication,
        aiConfidenceLevel: formattedData?.overridableProperties?.aiConfidenceLevel,
      });
      setExpressSetupLinksSourceState({
        externalLink: formattedData?.overridableProperties?.externalLink,
        isExternalLink: formattedData?.overridableProperties?.isExternalLink,
      });
      setOrphanConfigurationSourceState({
        orphanEmployer: formattedData?.overridableProperties?.orphanEmployer,
        isEmployerOrphan: formattedData?.overridableProperties?.isEmployerOrphan,
        startOrphanProcessDays: formattedData?.overridableProperties?.startOrphanProcessDays,
      });
      if (formattedData.overridableProperties?.emailConfiguration) {
        handleSetEmailConfigurationSourceState(formattedData.overridableProperties?.emailConfiguration);
        handleSetEmailConfigurationState(formattedData.overridableProperties?.emailConfiguration);
      }
      if (formattedData.overridableProperties?.linkManagement) {
        handleSetLinkManagementSourceState(formattedData.overridableProperties?.linkManagement);
        handleSetLinkManagementState(formattedData.overridableProperties?.linkManagement);
      }
      handleSetReportsState({
        customReportField1: formattedData.overridableProperties?.customReportField1,
        customReportDisplayedField1: formattedData.overridableProperties?.customReportDisplayedField1,
        customReportField2: formattedData.overridableProperties?.customReportField2,
        customReportDisplayedField2: formattedData.overridableProperties?.customReportDisplayedField2,
        customReportField3: formattedData.overridableProperties?.customReportField3,
        customReportDisplayedField3: formattedData.overridableProperties?.customReportDisplayedField3,
        customReportField4: formattedData.overridableProperties?.customReportField4,
        customReportDisplayedField4: formattedData.overridableProperties?.customReportDisplayedField4,
        customReportField5: formattedData.overridableProperties?.customReportField5,
        customReportDisplayedField5: formattedData.overridableProperties?.customReportDisplayedField5,
      });
      handleSetReportsSourceState({
        customReportField1: formattedData.overridableProperties?.customReportField1,
        customReportDisplayedField1: formattedData.overridableProperties?.customReportDisplayedField1,
        customReportField2: formattedData.overridableProperties?.customReportField2,
        customReportDisplayedField2: formattedData.overridableProperties?.customReportDisplayedField2,
        customReportField3: formattedData.overridableProperties?.customReportField3,
        customReportDisplayedField3: formattedData.overridableProperties?.customReportDisplayedField3,
        customReportField4: formattedData.overridableProperties?.customReportField4,
        customReportDisplayedField4: formattedData.overridableProperties?.customReportDisplayedField4,
        customReportField5: formattedData.overridableProperties?.customReportField5,
        customReportDisplayedField5: formattedData.overridableProperties?.customReportDisplayedField5,
      });

      handleSetFileManagerSettingsState({
        ...formattedData.overridableProperties,
      });
      handleSetFileManagerSettingsSourceState({
        ...formattedData.overridableProperties,
      });
    }
  }, [
    handleSetCommunicationChannelStore,
    formattedData,
    setPrimaryContactId,
    handleSetLinkManagementSourceState,
    handleSetEmailConfigurationSourceState,
    handleSetEmailConfigurationState,
    setAIAdjudication,
    handleSetLinkManagementState,
    setOrphanConfigurationSourceState,
    setExpressSetupLinksSourceState,
    handleSetFileManagerSettingsState,
    handleSetFileManagerSettingsSourceState,
    handleSetReportsState,
    handleSetReportsSourceState,
  ]);

  const employerStatus = useMemo(() => formattedData?.organizationStatusType, [formattedData]);

  return {
    data,
    employer: newEmployer,
    isLoading,
    isSuccess,
    isError,
    formattedData,
    employerStatus,
    refetch,
  };
};

export default useGetEmployer;
