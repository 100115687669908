import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

// import styled from 'styled-components';
import { SecurityPayload,useStore } from './stores/useSecurity.store';
import { useSecurityFields } from './hooks';
// import { useSubmitFormStore } from '../../stores';

// const Wrapper = styled(Box)`

//     .hint {
//       position: absolute !important ;
//       right: -42px;
//       top:8px;
//     }

// `;
interface Props {
  viewMode?: boolean;
}
const PlanName: React.FC<Props> = ({ viewMode }) => {
  // const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const fields = useSecurityFields(true);
  const handleSetState = useStore((state) => state.setState);
  const showErrors = useStore((state) => state.showErrors);

  const { setValues, values } = useDebounceState<Partial<SecurityPayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);
  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        formTitle="Security"
        fields={fields}
        showError={showErrors}
        editMode
        onChangeValues={setValues}
        isDisabledMode
        isThinMode
      />
    </Box>
  );
};
export default PlanName;
