
import _ from 'lodash';

import { Copay } from '@/modules/ExpressSetup/ExpressSetup.types.ts';
import { baseStoreCreator } from '@/utils/store';

export interface CopayPayload {
  copays: Copay[];
  isCopaysSelected: boolean;
  isReadyToSaveCopays: boolean;
}

export const DEFAULT_VALUES: CopayPayload = {
  copays: [{
    id: _.uniqueId(),
    categoryType: '',
    amount: '',
  }],
  isCopaysSelected: false,
  isReadyToSaveCopays: false,

};

export const useCopayStore = baseStoreCreator<CopayPayload>(DEFAULT_VALUES);

export default useCopayStore;
