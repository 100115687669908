import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import regexp from '@/common/regexp';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';

import useBankInformationQuery from '../useBankInformation.query';

const REQUIRED_FILED = 'This field is required.';
const last4length = 4;

export type BankInformationFields = BankInfo & {
  employerBankingContactType?: 'new' | 'exist',
};

type Fields = Field<keyof BankInformationFields>[];

export default (defaultFields?: BankInformationFields) => {
  const { bankAccountCategories = [] } = useBankInformationQuery();
  const secureMask = useMemo(() => {
    if (defaultFields?.number) {
      return `${Array(defaultFields.number.length - last4length).fill('*').join('')}9999`;
    }
    return '';
  }, [defaultFields]);

  const fields: Fields = useMemo((): Fields => {
    const options = bankAccountCategories.map((option) => ({
      label: option.description,
      value: option.name,
    }));
    const source: Fields = [
      {
        name: 'description',
        type: FieldTypes.Text,
        label: 'Name on account',
        placeholder: 'Name on account',
        validator: yup.string().trim().required(REQUIRED_FILED),
        defaultValue: defaultFields?.description || '' || '',
      },
      {
        name: 'routingTransitNumber',
        type: FieldTypes.Mask,
        label: 'Routing number',
        mask: '999999999',
        placeholder: 'Routing number',
        validator: yup.string()
          .test('len', 'Must be exactly 9 characters', (val) => !val || val.length === 9)
          .required(REQUIRED_FILED),
        defaultValue: defaultFields?.routingTransitNumber || '',
      },
      {
        name: 'number',
        type: FieldTypes.Mask,
        label: 'Account number',
        mask: '99999999999999999',
        formatChars: { '9': regexp.ALPHANUMERIC },
        secure: true,
        secureMask,
        placeholder: 'Account number',
        validator: yup.string()
          .test('len', 'Must be between 4 and 17 characters', (val) => !val || (val.length >= 4 && val.length <= 17))
          .required(REQUIRED_FILED),
        defaultValue: defaultFields?.number || '',
      } as unknown as Field<keyof BankInformationFields>,
      {
        name: 'accountCategory',
        type: FieldTypes.Radio,
        label: 'Bank account type',
        validator: yup.string().required(REQUIRED_TEXT),
        defaultValue: defaultFields?.accountCategory || '',
        options,
      },
    ];
    return source;
  }, [defaultFields, bankAccountCategories, secureMask]);

  return fields;
};
