import React, { useMemo } from 'react';
import { AppButton, Box } from '@common-fe/common-fe';
import { capitalize } from 'lodash';

import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';

const ToEditButton = () => {
  const { viewMode, toEdit, mode } = useSetupEmployerMode();
  const isShown = useMemo(() => mode !== EmployerSetupModes.system
    && viewMode,
  [mode, viewMode]);

  return isShown ? (
    <Box direction="row" align="center">
      <Box width="medium" />
      <Box width="medium">
        <AppButton
          buttonType="secondary"
          onClick={toEdit}
          width="100%"
        >
          {`Edit ${capitalize(mode)}`}
        </AppButton>
      </Box>
    </Box>
  ) : null;
};

export default ToEditButton;
