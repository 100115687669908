import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';

import {
  COPAY_ITEM_KEY, CopayGroupPayload, CopayItem, GET_COPAY_ITEM_POSTFIX,
} from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';

interface CopayParams {
  [key: string]: string;
}
const CURRENCY_KEY = '$';
export default (defaultValues?: CopayGroupPayload | null) => {
  const [copays, setCopays] = useState<CopayItem[]>([{
    id: _.uniqueId(),
    category: '',
    isSyntetic: true,
    amount: '',
  }]);
  const defaultFormattedCopays = useMemo(() => {
    if (!defaultValues) {
      return {};
    }
    const formattedCopays = copays.reduce((result: CopayParams, item, index) => {
      const newResult = {
        ...result,
        [GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.CATEGORY, item.id)]: item.category,
        // @ts-ignore
        [GET_COPAY_ITEM_POSTFIX(COPAY_ITEM_KEY.AMOUNT, item.id)]: item.amount.replace(CURRENCY_KEY, '').replaceAll(',', ''),
      };
      return newResult;
    }, {});
    return formattedCopays;
  }, [copays, defaultValues]);

  const defaultCopays = useMemo(() => defaultValues?.copays.map((item) => ({
    id: item.id || '',
    category: item.category,
    // @ts-ignore
    amount: item.amount.replace(CURRENCY_KEY, '').replaceAll(',', ''),
  })) as CopayItem[], [defaultValues]);

  useEffect(() => {
    if (defaultCopays && defaultCopays.length) {
      setCopays(defaultCopays);
    }
  }, [defaultCopays]);

  const handleAddCopay = useCallback(() => {
    setCopays([...copays, {
      id: _.uniqueId(),
      category: '',
      isSyntetic: true,
      amount: '',
    }]);
  }, [copays]);

  const handleRemoveCopay = useCallback((id: string) => {
    setCopays(copays.filter((item) => item.id !== id));
  }, [copays]);
  const copayIds = useMemo(() => copays.map((item) => item.id), [copays]);
  return {
    copayIds,
    handleRemoveCopay,
    copays,
    handleAddCopay,

    defaultFormattedCopays,
  };
};
