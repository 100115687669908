import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Permissions from '@/common/permissions';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import {
  useCommunicationCategoryStore,
  useCommunicationChannelStore,
  useStatementsSettingsStore,
} from '@/modules/employer/components/SetupEmployer/Communication/stores';
import useMultiFactorAuthenticationStore from '@/modules/employer/components/SetupEmployer/ContactsSecurity/MultiFactorAuthentication/stores/useMultiFactorAuthentication.store';
import useAIAdjudicationStore from '@/modules/employer/components/SetupEmployer/Features/AIAdjudication/stores/useAIAdjudication.store';
import useReimbursementStore from '@/modules/employer/components/SetupEmployer/Features/Reimbursement/stores/useReimbursement.store';
import useFileManagerSettingsStore from '@/modules/employer/components/SetupEmployer/FilesAndImports/FileManagerSettings/stores/useFileManagerSettings.store';
import { useAccountUsageStore } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/stores';
import useBanksListQuery from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BanksList/useBanksList.query';
import useLinkManagementStore from '@/modules/employer/components/SetupEmployer/LinkManagement/useLinkManagementStore.store';
import { useActivateEmployerMutation } from '@/modules/employer/components/SetupEmployer/queries/useActivateEmployer.query';
import { useTechnicalSettingsStore } from '@/modules/employer/components/SetupEmployer/TechnicalSettings/stores/useTechnicalSettings.store';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore } from '@/modules/employer/store';

import useClaimsSettingsStore from '../ClaimsSettings/store/useClaimsSettings.store';
import { useEmailConfigurationStore } from '../Communication/EmailConfiguration/stores';
import { useExpressSetupLinksStore } from '../Features/ExpressSetupLinks/stores';
import { useOrphanConfigurationStore } from '../Features/OrphanConfiguration/stores';
import useMobileAppCustomizationStore from '../MobileAppCustomization/store/useMobileAppCustomization.store';
import { useSaveEmployerQuery } from '../queries';
import { generalInformationPayload } from '../queries/useSaveEmployer.query';
import { useCustomReportsStore } from '../Reports/CustomReports';

export default () => {
  const claimsSettings = useClaimsSettingsStore();
  const { state: reimbursementState } = useReimbursementStore();
  const store = useSetupEmployerStore();
  const orphanConfigurationStore = useOrphanConfigurationStore((state) => state.state);
  const expressSetupLinksStore = useExpressSetupLinksStore((state) => state.state);

  const accountUsageStore = useAccountUsageStore();
  const technicalSettingsStore = useTechnicalSettingsStore();
  const fileManagerSettingsState = useFileManagerSettingsStore((state) => state.state);
  const communicationChannelStore = useCommunicationChannelStore((state) => state.state);
  const communicationCategoryStore = useCommunicationCategoryStore();
  const statementsSettingsStore = useStatementsSettingsStore((state) => state.state);
  const emailConfigurationState = useEmailConfigurationStore((state) => state.state);
  const linkManagementStore = useLinkManagementStore((state) => state.state);
  const { state: aiAdjudicationStore } = useAIAdjudicationStore();
  const { state: multiFactorAuthenticationStore } = useMultiFactorAuthenticationStore();
  const mobileAppCustomizationState = useMobileAppCustomizationStore((state) => state.state);
  const { id } = useParams<{ id: string }>();
  const { save } = useSaveEmployerQuery(id);
  const { formattedData: employerData } = useGetEmployer();
  const { activateEmployerAsync } = useActivateEmployerMutation();
  const { banks } = useBanksListQuery(id);
  const hasAccessToBankInfo = useHasAccess([{ permission: Permissions.EDIT_ER_BANK_ACCOUNT_INFO }]);
  const allowedPaymentMethods = useMemo(
    () =>
      reimbursementState.allowedPaymentMethods || employerData?.overridableProperties?.allowedPaymentMethods,
    [employerData.overridableProperties, reimbursementState.allowedPaymentMethods]
  );
  const reimbursementPayload = useMemo(() => {
    if (allowedPaymentMethods) {
      return {
        is_reimbursement_allowed:
          allowedPaymentMethods.length === 0 ? false : reimbursementState.isReimbursementAllowed,
        ...(reimbursementState.isReimbursementAllowed
          ? {
            allowed_payment_methods: allowedPaymentMethods,
          }
          : {}),
        is_bill_pay_allowed: reimbursementState.isBillPayAllowed,
        is_claim_offsets_allowed: reimbursementState.isClaimOffsetsAllowed,
        ...(reimbursementState?.minimumPaymentReimbursementAmount
          ? {
            minimum_payment_reimbursement_amount: reimbursementState?.minimumPaymentReimbursementAmount,
          }
          : {}),
        ...(reimbursementState?.waitingForFundsContinuePayment
          ? {
            waiting_for_funds_continue_payment: reimbursementState?.waitingForFundsContinuePayment,
          }
          : {}),
        ...(reimbursementState?.waitingForFundsExpiration
          ? {
            waiting_for_funds_expiration: reimbursementState?.waitingForFundsExpiration,
          }
          : {}),
      };
    }

    return {
      is_reimbursement_allowed:
        employerData.overridableProperties?.allowedPaymentMethods?.length === 0
          ? false
          : employerData.overridableProperties?.isReimbursementAllowed,
      ...(employerData.overridableProperties?.isReimbursementAllowed
        ? {
          allowed_payment_methods: employerData.overridableProperties?.allowedPaymentMethods,
        }
        : {}),
      is_bill_pay_allowed: employerData.overridableProperties?.isBillPayAllowed,
    };
  }, [reimbursementState, employerData.overridableProperties, allowedPaymentMethods]);
  const reportsState = useCustomReportsStore((state) => state.state);

  const formatedPayload = useMemo(() => {
    const payload = generalInformationPayload(
      banks,
      store.generalInfo,
      store.address,
      store.employer,
      communicationChannelStore,
      communicationCategoryStore.state,
      accountUsageStore.state,
      employerData,
      store.employeeId,
      store.fundingModel,
      store.cardsSetup,
      EmployerStatus.Pending,
      hasAccessToBankInfo,
      reimbursementPayload,
      store.helpInfo,
      store.primaryContactId,
      technicalSettingsStore.state,
      fileManagerSettingsState,
      emailConfigurationState,
      statementsSettingsStore,
      aiAdjudicationStore,
      multiFactorAuthenticationStore,
      mobileAppCustomizationState,
      claimsSettings.state,
      orphanConfigurationStore,
      linkManagementStore,
      expressSetupLinksStore,
      {
        reports: reportsState,
      }
    );
    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    banks,
    communicationChannelStore,
    communicationCategoryStore,
    accountUsageStore,
    employerData,
    store.address,
    store.employer,
    store.primaryContactId,
    store.generalInfo,
    store.employeeId,
    store.helpInfo,
    store.fundingModel,
    store.cardsSetup,
    hasAccessToBankInfo,
    reimbursementPayload,
    technicalSettingsStore,
    fileManagerSettingsState,
    emailConfigurationState,
    statementsSettingsStore,
    aiAdjudicationStore,
    mobileAppCustomizationState,
    linkManagementStore,
  ]);
  const handleActivateEmployerAsync = useCallback(
    async (organizationId: string) => {
      await activateEmployerAsync(organizationId);
    },
    [activateEmployerAsync]
  );

  const handleSave = useCallback(async () => {
    await save(formatedPayload);
  }, [formatedPayload, save]);
  return {
    handleSave,
    handleActivateEmployerAsync,
  };
};
