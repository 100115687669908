import { Notification, NotificationStatusColorType } from '@common-fe/common-fe';
import dayjs from 'dayjs';

const useNotifications = () => {
  const list : Notification[] = [
    {
      id: '1',
      text: 'Claim needs your approval.',
      time: '2 mins ago',
      additionalText: 'New claim was submitted 2 days ago. It is waiting for your approval.',
      status: NotificationStatusColorType.ERROR,
      createdAt: dayjs().toDate(),
    },
    {
      id: '2',
      text: 'Appeal waits for your review.',
      time: '19 mins ago',
      additionalText: 'New appeal is submitted and waiting for review.',
      status: NotificationStatusColorType.ERROR,
      createdAt: dayjs().toDate(),
    },
    {
      id: '3',
      text: 'File processing is completed with failure - some errors in header. It needs your attention.',
      time: '1 hour ago',
      status: NotificationStatusColorType.WARNING,
      createdAt: dayjs().toDate(),
    },
    {
      id: '4',
      text: 'Participant added new dependent.',
      time: `5:12 pm ${dayjs().add(-1, 'days').format('MMMM DD YYYY')}`,
      additionalText: 'Participant added new dependent and added a card for them.',
      status: NotificationStatusColorType.WARNING,
      createdAt: dayjs().add(-1, 'days').toDate(),
    },
    {
      id: '5',
      text: 'Reports are generated and ready for review.',
      time: `7:00 pm ${dayjs().add(-1, 'days').format('MMMM DD YYYY')}`,
      additionalText: 'Scheduled reports are generated and available for viewing.',
      status: NotificationStatusColorType.WARNING,
      createdAt: dayjs().add(-1, 'days').toDate(),
    },
    {
      id: '6',
      text: 'Employee updated personal information.',
      additionalText: 'Your employee updated their address.',
      time: `8:00 pm ${dayjs().add(-2, 'days').format('MMMM DD YYYY')}`,
      status: NotificationStatusColorType.SUCCESS,
      createdAt: dayjs().add(-2, 'days').toDate(),
    },
  ];
  return list;
};

export default useNotifications;
