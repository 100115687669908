import React from 'react';
import { Box, Preloader, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import { useGlobalLoaderStore } from '@/components/elements/FullLoader/store/useGlobalLoader.store';

const Wrapper = styled(Box)`
  position: fixed;
  z-index: 10000;
  text-align: center;
  backdrop-filter: blur(10px);
`;

export const FullLoader = () => {
  const { loader } = useGlobalLoaderStore();
  return loader ? (
    <Wrapper
      data-testid="full-loader"
      align="center"
      justify="center"
      background="popup"
      width="100%"
      height="100%"
      pad={{ bottom: '20%' }}
    >
      <Box
        background="canvas"
        pad={{ vertical: 'spacing32', horizontal: 'spacing24' }}
        round="container1Round"
        width="medium"
        direction="column"
      >
        <Box margin={{ bottom: 'spacing16' }} align="center">
          <Preloader />
        </Box>
        <Text color="textBody">{loader.message}</Text>
      </Box>
    </Wrapper>
  ) : null;
};
