import React, { useCallback, useMemo, useState } from 'react';
import {
  AbsenceFilesPlaceholderIcon,
  AppButton,
  Box,
  FlexList,
  FlexListItem,
  Inscription,
  ListItemBase,
  PendingModal,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import { TableRowOption } from '@/common/types';
import { SnackbarState } from '@/modules/core/core.types';
import { useHasAccess, useSnackbar } from '@/modules/core/hooks';

import { useScheduledContributionList } from './hooks';
import { useUpdateStatusScheduledContributionQuery } from './queries';
import { ScheduledContributionStatus } from './ScheduledContribution.types';
import ScheduledContributionEditModal from './ScheduledContributionEditModal';
const StyledSearchPlaceholderIcon = styled(AbsenceFilesPlaceholderIcon)`
  width: ${({ theme }) => theme.spacings.spacing72};
  height: ${({ theme }) => theme.spacings.spacing72};
  fill: ${({ theme }) => theme.colors.border};
`;

const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'calendar',
    title: 'Calendar',
    flex: 2,
  },
  {
    key: 'frequencyHeader',
    title: 'Frequency',
    flex: 2,
  },
  {
    key: 'nextScheduledTime',
    title: 'Next scheduled date',
    flex: 2,
  },

  {
    key: 'accounts',
    title: 'Accounts',
    flex: 4,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 3,
    style: {
      marginRight: '24px'
    }
  },
];

const ScheduledContributionList = () => {
  const [suspendContributionId, setSuspendContributionId] = useState('');
  const [editContributionId, setEditContributionId] = useState('');
  const [resumeContributionId, setResumeContributionId] = useState('');
  const [deactivateContributionId, setDeactivateContributionId] = useState('');
  const [removeContributionId, setRemoveContributionId] = useState('');

  const { rows, total, formattedData } = useScheduledContributionList();
  const handleCloseModals = useCallback(() => {
    setSuspendContributionId('');
    setResumeContributionId('');
    setEditContributionId('');
    setDeactivateContributionId('');
    setRemoveContributionId('');
  }, []);
  const { handleSave: handleUpdateStatus, isLoading: isLoadingChangeStatus } =
    useUpdateStatusScheduledContributionQuery({
      onSuccess: () => {
        handleCloseModals();
      },
    });

  const { handleAddPermanentSnackbar } = useSnackbar();
  const getOptions = useCallback((itemStatus: ScheduledContributionStatus) => {
    const currentOptions: TableRowOption[] =
      itemStatus === ScheduledContributionStatus.Deactivated
        ? [
          {
            name: 'Remove',
            onClick: setRemoveContributionId,
          },
        ]
        : [
          itemStatus === ScheduledContributionStatus.Suspend
            ? {
              name: 'Resume',
              onClick: setResumeContributionId,
            }
            : {
              name: 'Suspend',
              onClick: setSuspendContributionId,
            },
          {
            name: 'Edit',
            onClick: setEditContributionId,
          },
          {
            name: 'Deactivate',
            onClick: setDeactivateContributionId,
          },
        ];
    return currentOptions;
  }, []);

  const editedScheduledContribution = useMemo(() => {
    if (!editContributionId) {
      return null;
    }
    const foundItem = formattedData.find((item) => item.id === editContributionId);
    return foundItem || null;
  }, [editContributionId, formattedData]);
  const deletedScheduledContribution = useMemo(() => {
    if (!deactivateContributionId || !removeContributionId) {
      return null;
    }
    if(deactivateContributionId) {

      return formattedData.find((item) => item.id === deactivateContributionId);
    }
   
    return formattedData.find((item) => item.id === removeContributionId);
  }, [deactivateContributionId, formattedData, removeContributionId]);

  const handleSuspend = useCallback(() => {
    if (suspendContributionId) {
      handleUpdateStatus({
        id: suspendContributionId,
        status: ScheduledContributionStatus.Suspend,
      });
      handleAddPermanentSnackbar({
        state: SnackbarState.positive,
        text: 'Contribution was suspend',
        closeIcon: true,
      });
    }
  }, [handleAddPermanentSnackbar, handleUpdateStatus, suspendContributionId]);
  const handleResume = useCallback(async () => {
    if (resumeContributionId) {
      await handleUpdateStatus({
        id: resumeContributionId,
        status: ScheduledContributionStatus.Active,
      });
      handleAddPermanentSnackbar({
        state: SnackbarState.positive,
        text: 'Contribution was resumed',
        closeIcon: true,
      });
    }
  }, [handleAddPermanentSnackbar, handleUpdateStatus, resumeContributionId]);
  const handleDeactivate = useCallback(async () => {
    if (deactivateContributionId) {
      await handleUpdateStatus({
        id: deactivateContributionId,
        status: ScheduledContributionStatus.Deactivated,
      });
      
      handleAddPermanentSnackbar({
        state: SnackbarState.negative,
        text: `${deletedScheduledContribution?.name || ''} Calendar was deactivated`.trim(),
        closeIcon: true,
      });
    }
  }, [deactivateContributionId, deletedScheduledContribution?.name, handleAddPermanentSnackbar, handleUpdateStatus]);

  const handleRemove = useCallback(async () => {
    if (removeContributionId) {
      await handleUpdateStatus({
        id: removeContributionId,
        status: ScheduledContributionStatus.Removed,
      });
      handleAddPermanentSnackbar({
        state: SnackbarState.negative,
        text: `${deletedScheduledContribution?.name || ''} Calendar was removed`.trim(),
        closeIcon: true,
      });
    }
  }, [deletedScheduledContribution?.name, handleAddPermanentSnackbar, handleUpdateStatus, removeContributionId]);

  const isManageScheduledContributionAllowed = useHasAccess([
    {  permission: Permissions.MANAGE_SCHEDULED_CONTRIBUTIONS },
  ]);


  if (!total) {
    return null;
  }

  return (
    <Box pad="l" round="moduleRound" background="module" margin={{ bottom: 'l' }}>
      <ScheduledContributionEditModal
        onSetVisible={handleCloseModals}
        currentScheduledContribution={editedScheduledContribution}
      />

      <FlexList
        total={total}
        headers={LIST_HEADERS}
        rows={[]}
        loading={false}
        moreCount={10}
        pad={{ horizontal: '0px', vertical: '0px' }}
        footer={(
          <>
            {rows.map((row) => (
              <FlexListItem
                key={row.id}
                id={row.id}
                fields={row.fields}
                options={isManageScheduledContributionAllowed ? getOptions(row.contributionStatus): undefined}
              />
            ))}
          </>
        )}
        placeholder={(
          <Box
            data-testid="ContributionsListEmptyPlaceholder_container"
            direction="column"
            justify="center"
            align="center"
            margin={{ top: 'spacing12' }}
          >
            <Box margin={{ bottom: 's' }}>
              <StyledSearchPlaceholderIcon />
            </Box>
            <Inscription size="medium" weight="bold" color="textBody">
              There are no Contribution records yet
            </Inscription>
          </Box>
        )}
      />
      <PendingModal
        visible={!!resumeContributionId}
        onSetVisible={handleCloseModals}
        header="Resume contribution schedule?"
        helptext="Reactivated payroll schedules will not automatically be create any payroll dates skipped by the suspended payroll calendar."
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton onClick={handleCloseModals} width="240px" buttonType="secondary">
              Cancel
            </AppButton>
          </Box>
          <AppButton disabled={isLoadingChangeStatus} onClick={handleResume} width="240px">
            Yes, Resume
          </AppButton>
        </Box>
      </PendingModal>
      <PendingModal
        visible={!!suspendContributionId}
        onSetVisible={handleCloseModals}
        header="Suspend contribution schedule?"
        helptext="Payroll dates will be skipped while contribution schedules are suspended. Once reactivated, any payroll dates skipped will not automatically be created by the reactivated payroll calendar."
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton onClick={handleCloseModals} width="240px" buttonType="secondary">
              Cancel
            </AppButton>
          </Box>
          <AppButton disabled={isLoadingChangeStatus} onClick={handleSuspend} width="240px">
            Yes, Suspend
          </AppButton>
        </Box>
      </PendingModal>

      <PendingModal
        visible={!!deactivateContributionId}
        onSetVisible={handleCloseModals}
        header="Deactivate contribution schedule?"
        helptext="Payroll dates will be skipped while contribution schedules are deactivated."
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton onClick={handleCloseModals} width="240px" buttonType="secondary">
              Cancel
            </AppButton>
          </Box>
          <AppButton
            buttonType="primary"
            color="red"
            disabled={isLoadingChangeStatus}
            onClick={handleDeactivate}
            width="240px"
          >
            Yes, Deactivate
          </AppButton>
        </Box>
      </PendingModal>

      <PendingModal
        visible={!!removeContributionId}
        onSetVisible={handleCloseModals}
        header="Remove contribution schedule?"
        helptext=""
      >
        <Box direction="row">
          <Box margin={{ right: 'spacing12' }}>
            <AppButton onClick={handleCloseModals} width="240px" buttonType="secondary">
              Cancel
            </AppButton>
          </Box>
          <AppButton
            buttonType="primary"
            color="red"
            disabled={isLoadingChangeStatus}
            onClick={handleRemove}
            width="240px"
          >
            Yes, Remove
          </AppButton>
        </Box>
      </PendingModal>
    </Box>
  );
};
export default ScheduledContributionList;
