import React from 'react';
import { Box } from '@common-fe/common-fe';

import Topbar from '@/modules/core/components/Topbar';
import { EnrollmentDetails } from '@/modules/employee/EnrollmentDetails/EnrollmentDetails';
import theme from '@/styles/theme';

const EnrollmentDetailsPage = () => (
  <Box width="100%" margin={{ top: '0' }} height="fit-content">
    <Topbar />
    <Box align="center" pad={{ horizontal: '40px' }} margin={{ top: 'medium' }}>
      <Box width={theme.defaultContentWidth}>
        <EnrollmentDetails />
      </Box>
    </Box>
  </Box>
);
export default EnrollmentDetailsPage;
