import React from 'react';
import {
  AppButton,
  Box, Heading, Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import ModalWrapper from './ModalWrapper';

interface AnimatedTextProps {
  showed?: boolean;
}
const AnimatedText = styled(Text)<AnimatedTextProps>`
  opacity: ${({ showed }) => (showed ? 1 : 0)};
  transition: all 0.25s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.58, 1);
  margin-left: ${({ theme }) => theme.spacings.spacing12};
`;

// const SmallButton = styled(Button)`
//   ${({ theme }) => theme.fonts.text14Bold};
//   color: ${({ theme }) => theme.colors.textOnColor};
//   &:hover {
//     opacity: 0.9;
//   }
// `;
interface Props {
  title: string;
  visible: boolean;
  isTransparent?: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  children: React.ReactNode;
  buttonText: string;
  required?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  successTextShow?: boolean;
  successText?: string;
  id?: string;
  isButtonHidden?: boolean;
}

const AddModalWrapper: React.FC<Props> = ({
  modalTitle,
  successText,
  children,
  title,
  buttonText,
  required,
  isHidden,
  onSetVisible,
  visible,
  isTransparent,
  successTextShow,
  disabled,
  id,
  isButtonHidden,

}) => (

  <Box direction="row" align="center" pad={{ vertical: 'spacing24', horizontal: 'spacing32' }} id={id}>
    <Box
      style={{
        display: isTransparent ? 'none' : undefined,
        visibility: isHidden ? 'hidden' : undefined,
      }}
    >
      <ModalWrapper
        visible={visible}
        title={modalTitle}
        onSetVisible={onSetVisible}
      >
        {children}
      </ModalWrapper>
    </Box>
    <Box width="medium">
      <Heading level={3} size="small" style={{ fontWeight: 400 }}>
        {title}
      </Heading>
    </Box>
    {
      !isButtonHidden
        && (
          <Box width="medium">
            <AppButton
              disabled={disabled}
              onClick={() => onSetVisible(true)}
              width="100%"
            >
              {buttonText}
            </AppButton>
          </Box>
        )
    }
    {required && (
      <Box margin={{ left: 'spacing12' }} direction="row" style={{ flex: 1 }}>
        <Text size="small" weight={500} color="danger">Required</Text>
      </Box>
    )}
    {successText
    && (
      <AnimatedText showed={successTextShow} size="medium" color="success">{successText}</AnimatedText>
    )}

  </Box>
);

export default AddModalWrapper;
