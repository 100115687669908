/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { Field,FieldTypes, validateTextInputLength } from '@common-fe/common-fe';
import * as yup from 'yup';

import { useStatesQuery } from '@/modules/core/hooks';
import { EmployerDto } from '@/modules/employer/types';

import { useSetupEmployerMode } from '../hooks';

const REQUIRED_FILED = 'This field is required.';
const FIELDS = {
  STREET: 'street',
  STREET_2: 'street_2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
};

export const SAVE_VALIDATORS = {
  [FIELDS.STREET]: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_FILED),
  [FIELDS.STREET_2]: yup.string().trim().test(validateTextInputLength()),
  [FIELDS.CITY]: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_FILED),
  [FIELDS.STATE]: yup.string().required(REQUIRED_FILED),
  [FIELDS.ZIP_CODE]: yup
    .string()
    .trim()
    .test(
      'len',
      'ZIP code isn\'t valid',
      (val) => (val && (val.length === 10 || val.length === 5)) || val === '',
    )
    .required(REQUIRED_FILED),
};
export default (
  activateMode?: boolean,
  data?: EmployerDto,
) => {
  const { states } = useStatesQuery();
  const { employerID } = useSetupEmployerMode();
  const fields: Field[] = useMemo(() => ([
    {
      name: FIELDS.STREET,
      type: FieldTypes.Text,
      label: 'Address line 1',
      placeholder: 'Enter Address line 1',
      validator: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_FILED),
      showRequireIcon: true,
      defaultValue: data?.addresses?.[0]?.line1, // generalInfo?.street,
      value: data?.addresses?.[0]?.line1, // generalInfo?.street,
    },
    {
      name: FIELDS.STREET_2,
      type: FieldTypes.Text,
      label: 'Address line 2',
      placeholder: 'Enter Address line 2',
      validator: yup.string().trim().test(validateTextInputLength()),
      defaultValue: data?.addresses?.[0]?.line2, // generalInfo?.street,
      value: data?.addresses?.[0]?.line2, // generalInfo?.street,
    },
    {
      name: FIELDS.CITY,
      type: FieldTypes.Text,
      label: 'City',
      placeholder: 'Enter City',
      validator: yup.string().trim().test(validateTextInputLength()).required(REQUIRED_FILED),
      showRequireIcon: true,
      defaultValue: data?.addresses?.[0]?.city, // generalInfo?.city,
      value: data?.addresses?.[0]?.city, // generalInfo?.city,
    },
    {
      name: FIELDS.STATE,
      type: FieldTypes.AutocompleteDropdown,
      label: 'State',
      showRequireIcon: true,

      placeholder: 'Select state',
      validator: yup.string().required(),
      options: states.map((state) => ({ key: state.code, title: state.name })),
      defaultValue: (
        states.find((state) => state.code === data?.addresses?.[0]?.state)?.code
        || ''
      ).toString(),
      value: (
        states.find((state) => state.code === data?.addresses?.[0]?.state)?.code
        || ''
      ).toString(),
    },
    {
      name: FIELDS.ZIP_CODE,
      type: FieldTypes.ZipCode,
      label: 'ZIP code',
      placeholder: 'Enter ZIP code',
      validator: yup.string()
        .trim()
        .test('len', 'ZIP code isn\'t valid',
          (val) => (!!val && (val.length === 10 || val.length === 5))
          || (val === '')).required(REQUIRED_FILED),
      showRequireIcon: true,
      defaultValue: data?.addresses?.[0]?.zipcode, // generalInfo?.zipCode,
      value: data?.addresses?.[0]?.zipcode, // generalInfo?.zipCode,
    },
  ]),
  [
    employerID,
    data,
  ]);
  const formatedFields = useMemo(() => {
    const list = [...fields];
    if (!activateMode) {
      return list.map((field) => {
        const validator = SAVE_VALIDATORS[field.name];
        return {
          ...field,
          validator,
        };
      });
    }
    return list;
  }, [activateMode, fields, data]);
  return formatedFields;
};
