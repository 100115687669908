import { useMemo } from 'react';

import { REQUIRED_TEXT } from '@/common/constants';

import { useCopayStore } from '../ExpressSetupWizard/PlansWizard/Copays/stores';

export interface CopayError {
  id: string;
  amount?: string;
  categoryType?: string;
}
const useCopaysFormValidate = () => {
  const copayState = useCopayStore((state) => state.state.copays);

  const copayErrors = useMemo(() => {
    const errors: CopayError[] = [];

    copayState?.forEach((copay) => {
      const amountError = copay?.amount === '';
      const categoryTypeError = copay?.categoryType === '';

      if (amountError || categoryTypeError) {
        errors.push({
          id: copay?.id || '',
          amount: amountError ? REQUIRED_TEXT : undefined,
          categoryType: categoryTypeError ? REQUIRED_TEXT : undefined,
        });
      }
    });

    return errors;
  }, [copayState]);

  return copayErrors;
};

export default useCopaysFormValidate;
