import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,
  Box,
  FieldTypes,
  FlexControlledForm,
  Inscription,
  WarnModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { toString } from 'lodash';
import styled from 'styled-components';
import * as yup from 'yup';

import { REQUIRED_TEXT } from '@/common/constants';
import { ModalWrapper } from '@/components/wrappers';
import { EllipsisText } from '@/modules/CarrierConfig/ViewCarrierConfigModal/hooks/useGeneralFields';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { ClaimAutopayType } from '@/modules/employee/employee.types';
import { ROOT_ORGANIZATION } from '@/modules/ProcessingDefinition/ProcessingDefinition.constants';

import useGetCarriersQuery, { Carrier } from './OrgLevelSelector/useGetCarriers.query';
import { Organization } from './OrgLevelSelector/useGetOrganization.query';
import CreateCarrierForm from './CreateCarrierForm';
import OrgLevelSelector from './OrgLevelSelector';
import useCreateCarrierConfigQuery, {
  AmountTypes,
  CarrierConfig,
  ClaimPayeeType,
  PorposeType,
} from './useCreateCarrierConfig.query';

const ALLOWABLE_SUBMITTER_ID_RANGE = [3, 40];

const RequiredLabel: React.FC<{ title: string; }> = ({ title }) => (
  <Box margin={{ top: 'spacing8' }} direction="row">
    <Inscription
      color="danger"
      margin={{
        right: '3px',
        top: '1px',
      }}
    >
      *
    </Inscription>
    <Inscription>{title}</Inscription>
  </Box>
);

const CLAIM_PAYEE_ERROR_MESSAGE = `Claim autopay (Yes) and Claim payee (Employee choice)
are mutually exclusive settings. Please change your configurations.`;
const DATE_OVERLAPPED_ERROR_MESSAGE = `Service date periods should not overlap
for the combination of Carrier/Submitter ID/Organization`;
const MAX_SEARCH_VALUE = 3;

const FormWrapper = styled(Box)`
  button.autocomplete-dropdown__add-button:not(:hover) {
    border-color: ${({ theme }) => theme.colors.border};
  }
`;
const WarnModalWrapper = styled(Box)`
  color: ${({ theme }) => theme.colors.textBody};
`;

interface FirstPartConfig {
  carrierId?: string;
  submitterId?: CarrierConfig['submitterId'];
  serviceDateFrom?: CarrierConfig['serviceDateFrom'];
  serviceDateTo?: CarrierConfig['serviceDateTo'];
  description?: CarrierConfig['description'];
}

interface SecondPartConfig {
  purposeType?: CarrierConfig['purposeType'];
  isDependentDeidentified?: CarrierConfig['isDependentDeidentified'];
  claimAutopayType?: CarrierConfig['claimAutopayType'];
  isDependentAutocreated?: CarrierConfig['isDependentAutocreated'];
  claimPayeeType?: CarrierConfig['claimPayeeType'];
  amountTypes?: CarrierConfig['amountTypes'];
}

const CreateCarrierConfigForm: React.FC<{
  setIsOverflowHidden: (value: boolean) => void;
  onClose: (isSuccess?: boolean) => void;
  onDirty: (value: boolean) => void;
}> = ({ setIsOverflowHidden, onClose, onDirty }) => {
  const [isDateOverlapped, setIsDateOverlapped] = useState(false);
  const [isErrorShown, showError] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const {
    observingOrganization: {
      id, name = '', path, type,
    },
  } = useCurrentOrganization();
  useEffect(() => {
    setOrganization({
      id: toString(id), name, path, type,
    });
  }, [id, type, path, name]);
  const [firstPartConfig, setFirstPartConfig] = useState<FirstPartConfig>({});
  useEffect(() => setIsDateOverlapped(false), [
    firstPartConfig.serviceDateFrom,
    firstPartConfig.serviceDateTo,
    firstPartConfig.submitterId,
    firstPartConfig.carrierId,
    organization,
  ]);
  const [secondPartConfig, setsecondPartConfig] = useState<SecondPartConfig>({});
  useEffect(() => {
    if (secondPartConfig.isDependentDeidentified
      && secondPartConfig.claimPayeeType !== ClaimPayeeType.participant) {
      setsecondPartConfig({
        ...secondPartConfig,
        claimPayeeType: ClaimPayeeType.participant,
      });
    }
  }, [secondPartConfig]);
  const isAdjudication = useMemo(
    () => secondPartConfig?.purposeType === PorposeType.adjudication,
    [secondPartConfig],
  );
  const [searchedCarrierName, setSearchedCarrierName] = useState('');
  const [createdCarrier, setCreatedCarrier] = useState<Carrier>();
  const { carriers, isLoading } = useGetCarriersQuery(
    searchedCarrierName?.length >= MAX_SEARCH_VALUE ? searchedCarrierName : undefined,
  );
  const [isVisible, setIsVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const options = useMemo(() => ([...createdCarrier
    ? [createdCarrier, ...carriers]
    : carriers]
    .map((carrier) => ({ key: carrier.id, title: carrier.name }))),
  [carriers, createdCarrier]);
  const carrierExternalId = useMemo(() => createdCarrier?.externalId || carriers
    .find((carrier) => carrier.id === firstPartConfig.carrierId)?.externalId, [
    createdCarrier,
    carriers,
    firstPartConfig.carrierId,
  ]);

  useEffect(() => {
    setIsOverflowHidden(isVisible || isSuccessModalVisible);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isVisible, isSuccessModalVisible]);

  const { create, isLoading: isCreating } = useCreateCarrierConfigQuery(
    () => setIsDateOverlapped(true),
    () => onClose(true),
  );
  const isDataLoading = useMemo(() => isCreating || isLoading, [isCreating, isLoading]);
  const isValidClaimPayeeVsClaimAutopay = useMemo(
    () => secondPartConfig.claimPayeeType !== ClaimPayeeType.employeeChoice
    || secondPartConfig.claimAutopayType !== ClaimAutopayType.auto,
    [secondPartConfig],
  );
  const isDataValid = useMemo(() => {
    if (!isValidClaimPayeeVsClaimAutopay
      || isDateOverlapped) return false;

    if (!firstPartConfig.carrierId
      || !firstPartConfig.submitterId
      || !firstPartConfig.serviceDateFrom
      || !firstPartConfig.description
      || !secondPartConfig.purposeType
      || !secondPartConfig.amountTypes?.length
    ) {
      return false;
    }

    if (!isAdjudication
      && (!secondPartConfig.claimAutopayType
      || (!secondPartConfig.isDependentDeidentified && !secondPartConfig.claimPayeeType))) {
      return false;
    }

    return true;
  }, [
    isAdjudication,
    firstPartConfig,
    secondPartConfig,
    isValidClaimPayeeVsClaimAutopay,
    isDateOverlapped,
  ]);
  const handleSubmit = useCallback(async () => {
    showError(true);

    if (isDataValid) {
      create({
        carrierExternalId,
        organizationId: organization?.id,
        organizationPath: organization?.path || ROOT_ORGANIZATION,
        organizationName: organization?.name,
        submitterId: firstPartConfig.submitterId,
        description: firstPartConfig.description,
        serviceDateFrom: dayjs(firstPartConfig.serviceDateFrom).startOf('day').format(),
        ...firstPartConfig.serviceDateTo ? {
          serviceDateTo: dayjs(firstPartConfig.serviceDateTo).endOf('day').format(),
        } : {},
        purposeType: secondPartConfig.purposeType,
        ...isAdjudication ? {} : {
          claimAutopayType: secondPartConfig.claimAutopayType,
          isDependentDeidentified: secondPartConfig.isDependentDeidentified,
          isDependentAutocreated: secondPartConfig.isDependentAutocreated,
          claimPayeeType: secondPartConfig.isDependentDeidentified
            ? ClaimPayeeType.participant
            : secondPartConfig.claimPayeeType,
        },
        amountTypes: secondPartConfig.amountTypes,
      });
    }
  }, [
    showError,
    create,
    carrierExternalId,
    organization,
    firstPartConfig,
    secondPartConfig,
    isAdjudication,
    isDataValid,
  ]);

  return (
    <>
      <ModalWrapper
        testId="create_carrier"
        visible={isVisible}
        title="Create Carrier"
        onSetVisible={setIsVisible}
      >
        <CreateCarrierForm
          onClose={() => setIsVisible(false)}
          onSuccess={(carrier: Carrier) => {
            setCreatedCarrier(carrier);
            setIsVisible(false);
            setIsSuccessModalVisible(true);
          }}
        />
      </ModalWrapper>

      <WarnModalWrapper>
        <WarnModal
          testId="carrier_created"
          visible={isSuccessModalVisible}
          onSetVisible={setIsSuccessModalVisible}
          header={`The ${createdCarrier?.name} carrier has been created!`}
          helptext="You can now use this new carrier to create a carrier config in the previous window."
          isSuccessIcon
          submitButtonText="Close"
          onSubmit={() => setIsSuccessModalVisible(false)}
        />
      </WarnModalWrapper>

      <Box background="module" pad="spacing24" round="medium">
        <FormWrapper
          background="canvas"
          round="container1Round"
          border={{ color: 'border2', size: 'small' }}
          pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
          elevation="default"
        >
          <FlexControlledForm
            testId="create_carrier_config_part_1"
            editMode
            showError={isErrorShown}
            fields={[
              {
                testId: 'carrier_id',
                showRequireIcon: true,
                placeholder: 'Select carrier',
                name: 'carrierId',
                type: FieldTypes.AutocompleteDropdown,
                label: 'Carrier name',
                options,
                isSearchMode: true,
                completeValue: createdCarrier?.id,
                // @ts-ignore
                onChange: (val?: string) => {
                  if (!val) setCreatedCarrier(undefined);
                },
                resetCompleteValue: () => setCreatedCarrier(undefined),
                add: () => setIsVisible(true),
                isNarrowLabelMode: true,
                isLoading,
                onSearch: setSearchedCarrierName,
                validator: yup.string().required(REQUIRED_TEXT),
              },
              {
                placeholder: 'Carrier ID',
                name: 'carrierExternalId',
                type: FieldTypes.Node,
                label: 'Carrier ID',
                value: (
                  <EllipsisText
                    title={carrierExternalId || 'Carrier ID'}
                  >
                    {carrierExternalId || 'Carrier ID'}
                  </EllipsisText>
                ),
                // @ts-ignore 
                isNarrowLabelMode: true,
              },
              {
                placeholder: 'Enter submitter ID',
                showRequireIcon: true,
                name: 'submitterId',
                type: FieldTypes.Text,
                label: 'Submitter ID',
                // @ts-ignore
                isNarrowLabelMode: true,
                validator: yup.string().test({
                  test: (val = '') => val?.length <= ALLOWABLE_SUBMITTER_ID_RANGE[1],
                  message: 'The value is too long.',
                }).test({
                  test: (val = '') => val?.length >= ALLOWABLE_SUBMITTER_ID_RANGE[0],
                  message: 'The value is short.',
                })
                  .required(REQUIRED_TEXT),
              },
              {
                placeholder: 'Select organization level',
                name: 'organization',
                type: FieldTypes.Node,
                label: <RequiredLabel title="Organization" />,
                value: <OrgLevelSelector onChange={setOrganization} />,
                // @ts-ignore
                isNarrowLabelMode: true,
              },
              {
                showRequireIcon: true,
                placeholder: 'Select date',
                name: 'serviceDateFrom',
                type: FieldTypes.Date,
                label: 'Valid from',
                // @ts-ignore
                isNarrowLabelMode: true,
                validator: yup.string().test({
                  test: () => !isDateOverlapped,
                  message: DATE_OVERLAPPED_ERROR_MESSAGE,
                }).required(REQUIRED_TEXT),
                ...firstPartConfig.serviceDateTo ? {
                  maxDate: dayjs(firstPartConfig.serviceDateTo).add(0, 'day'),
                } : {},
              },
              {
                placeholder: 'Select date',
                name: 'serviceDateTo',
                type: FieldTypes.Date,
                label: 'Valid to',
                // @ts-ignore
                isNarrowLabelMode: true,
                ...firstPartConfig.serviceDateFrom ? {
                  minDate: dayjs(firstPartConfig.serviceDateFrom).add(0, 'day'),
                } : {},
              },
              {
                placeholder: 'Enter description',
                name: 'description',
                type: FieldTypes.Text,
                label: <RequiredLabel title="Description" />,
                // @ts-ignore
                isNarrowLabelMode: true,
                validator: yup.string().required(REQUIRED_TEXT),
                maxLength: 200,
              },
            ]}
            onChangeValues={setFirstPartConfig}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ margin: 0 }}
            isThinMode
            onDirty={onDirty}
          />
        </FormWrapper>
        <Box
          background="canvas"
          round="container1Round"
          border={{ color: 'border2', size: 'small' }}
          pad={{ horizontal: 'spacing32', vertical: 'spacing24' }}
          margin={{ top: 'spacing24' }}
          elevation="default"
        >
          <FlexControlledForm
            testId="create_carrier_config_part_2"
            editMode
            showError={isErrorShown}
            fields={[
              {
                name: 'purposeType',
                showRequireIcon: true,
                type: FieldTypes.Radio,
                label: 'Purpose',
                // @ts-ignore
                isNarrowLabelMode: true,
                options: [
                  { label: 'Claim creation', value: PorposeType.claimCreation },
                  { label: 'Adjudication', value: PorposeType.adjudication },
                ],
                validator: yup.string().required(REQUIRED_TEXT),
              },
              ...isAdjudication ? [] : [
                {
                  name: 'isDependentDeidentified',
                  type: FieldTypes.Checkbox,
                  label: (
                    <Box flex={{ grow: 1 }}>
                      <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                        De-identify dependent
                      </Inscription>
                      <Box>
                        <Inscription weight="normal" color="textSecondary" size="12px" lineHeight="18px">
                          Choosing De-identify dependent limits some of the following options.
                        </Inscription>
                      </Box>
                    </Box>
                  ),
                  // @ts-ignore
                  isNarrowLabelMode: true,
                },
                {
                  name: 'claimAutopayType',
                  showRequireIcon: true,
                  type: FieldTypes.Radio,
                  label: 'Claim autopay',
                  isNarrowLabelMode: true,
                  options: [
                    { label: 'Yes', value: ClaimAutopayType.auto },
                    { label: 'No', value: ClaimAutopayType.click },
                    { label: 'Employee choice', value: ClaimAutopayType.employeeChoice },
                  ],
                  validator: yup.string().required(REQUIRED_TEXT),
                },
                ...secondPartConfig?.isDependentDeidentified ? [] : [
                  {
                    name: 'isDependentAutocreated',
                    type: FieldTypes.Checkbox,
                    label: 'Automatic creation of dependent',
                    isNarrowLabelMode: true,
                    defaultValue: true,
                  },
                ],
                {
                  name: 'claimPayeeType',
                  showRequireIcon: true,
                  type: FieldTypes.Radio,
                  label: 'Claim payee',
                  isNarrowLabelMode: true,
                  options: [
                    {
                      label: 'Participant',
                      value: ClaimPayeeType.participant,
                      ...secondPartConfig.isDependentDeidentified ? { checked: true } : {},
                    },
                    { label: 'Provider', value: ClaimPayeeType.provider },
                    { label: 'Employee choice', value: ClaimPayeeType.employeeChoice },
                  ],
                  ...secondPartConfig.isDependentDeidentified ? {
                    disabled: true,
                  } : {
                    validator: yup.string().test({
                      test: () => isValidClaimPayeeVsClaimAutopay,
                      message: CLAIM_PAYEE_ERROR_MESSAGE,
                    }).required(REQUIRED_TEXT),
                  },
                },
              ],
              {
                name: 'amountTypes',
                showRequireIcon: true,
                type: FieldTypes.CheckBoxGroup,
                label: (
                  <Box flex={{ grow: 1 }}>
                    <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                      Fields for requested amount
                    </Inscription>
                    <Box>
                      <Inscription weight="normal" color="textSecondary" size="12px" lineHeight="18px">
                        At least one option needs to be selected
                      </Inscription>
                    </Box>
                  </Box>
                ),
                isNarrowLabelMode: true,
                
                options: [
                  // @ts-ignore
                  { value: 'Coinsurance', key: AmountTypes.coinsurance },
                  // @ts-ignore
                  { value: 'Copay', key: AmountTypes.copay },
                  // @ts-ignore
                  { value: 'Patient responsibility', key: AmountTypes.patientResponsibility },
                  // @ts-ignore
                  { value: 'Deductible', key: AmountTypes.deductible },
                ],
                validator: yup.array().min(1, REQUIRED_TEXT).required(REQUIRED_TEXT),
                defaultValue: [],
              },
            ]}
            onChangeValues={setsecondPartConfig}
            wrapperStyle={{ border: 'none' }}
            formStyle={{ margin: 0 }}
            isThinMode
            onDirty={onDirty}
          />
        </Box>
      </Box>

      <Box margin={{ top: 'spacing24' }}>
        <Box
          justify="end"
          align="center"
          direction="row"
        >
          <AppButton
            testId="cancel"
            buttonType="secondary"
            onClick={() => onClose()}
            width="160px"
          >
            Cancel
          </AppButton>
          <Box margin={{ left: 'spacing12' }}>
            <AppButton
              testId="submit"
              onClick={handleSubmit}
              width="160px"
              disabled={isDataLoading || (isErrorShown && !isDataValid)}
            >
              Submit
            </AppButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateCarrierConfigForm;
