import { useCurrentOrganization } from '@/modules/core/hooks';

import { SearchEnrollmentMatrixParams } from '../../ProcessingDefinition.types';
import { useEnrollmentMatrixesQuery, useExpiredEnrollmentMatrixesQuery } from '../queries';

export default (
  searchParams: SearchEnrollmentMatrixParams,
  searchExpiredParams?: SearchEnrollmentMatrixParams,
) => {
  const { observingOrganization } = useCurrentOrganization();
  const {
    data,
    matrixesHealthPlan,
    matrixes,
    total,
    isLoading,
  } = useEnrollmentMatrixesQuery(
    searchParams,
    observingOrganization.path,
  );

  const {
    data: expiredData,
    total: expiredTotal,
    isLoading: isExpiredLoading,
  } = useExpiredEnrollmentMatrixesQuery(
    searchExpiredParams,
    observingOrganization.path,
  );

  return {
    data,
    matrixesHealthPlan,
    matrixes,
    total,
    isLoading,
    expiredData,
    expiredTotal,
    isExpiredLoading,
    currentOrg: observingOrganization.type,
  };
};
