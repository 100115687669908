import React from 'react';
import { Inscription, Table, TableBody, TableCell, TableRow } from '@common-fe/common-fe';

import { Document } from '@/common/types';
import useConvertTextToCsv from '@/components/elements/DocumentViewer/hooks/useConvertTextToCsv';
import useTextFileReader from '@/components/elements/DocumentViewer/hooks/useReadTextFromFile';

import ZoomContainer from './ZoomContainer';

interface Props {
  receipt?: Document;
  zoom: number;
}

const CsvViewer: React.FC<Props> = ({ receipt, zoom }) => {
  const { text, isLoading } = useTextFileReader(receipt?.link);
  const { csvData } = useConvertTextToCsv(text);

  if (isLoading) {
    return null;
  }

  return (
    <ZoomContainer zoom={zoom}>
      <Table style={{ minWidth: '100%' }}>
        <TableBody>
          {csvData?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell) => (
                <TableCell
                  key={cell}
                  border={{ color: 'textTitle' }}
                >
                  <Inscription style={{ whiteSpace: 'nowrap' }}>
                    {cell}
                  </Inscription>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ZoomContainer>
  );
};

export default CsvViewer;
