import React from 'react';
import { Box, Modal,Text } from '@common-fe/common-fe';

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  title?: string;
  testId?: string;
  children: React.ReactNode;
  id?: string;
  wrapperStyle?: React.CSSProperties;
}

const ModalWrapper: React.FC<Props> = ({
  title,
  children,
  onSetVisible,
  visible,
  testId,
  id,
  wrapperStyle,
}) => (
  <Modal id={id} testId={testId} visible={visible} onSetVisible={onSetVisible} wrapperStyle={wrapperStyle}>
    <Box direction="column" data-testid={`${testId}_modal`}>
      <Box direction="row" align="center" justify="center" pad={{ bottom: 'spacing24' }}>
        {title && <Text size="2xl" color="textTitle" weight="bold">{title}</Text>}
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  </Modal>
);

export default ModalWrapper;
