import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { ExpressSetupDtoResponse } from '../ExpressSetup.types';

export const EXPRESS_SETUP_QUERY_KEY = 'EXPRESS_SETUP_BY_ID';
const useGetExpressSetupByIdQuery = (
  id: string,
) => {
  const {
    data, isSuccess, isLoading, refetch,
  } = useQuery(
    [EXPRESS_SETUP_QUERY_KEY, id],
    () => api.get<ExpressSetupDtoResponse>(
      PATHS.EXPRESS_SETUP_BY_ID(id),
    ), {
      enabled: !!id,
    },
  );

  return {
    isLoading,
    isSuccess,
    data: data?.data,
    refetch,
  };
};

export default useGetExpressSetupByIdQuery;
