import { DEV_ENV_KEY } from '@/utils/hooks/useOnlyForDev';

export const USER = '/user/';
export const USERS = '/users/';
export const AUTH = `${USER}auth/`;

const ORGANIZATIONS = '/organizations';
const PLAN_YEARS = '/plan-years';
const CLAIMS = '/claims';
const CARRIERS = '/carriers';
const CARRIER_CONFIGS = '/carrier-configs';
const PLANS = '/plans';
const HEALTH_PLANS = '/health-plans';
const ENUM = '/enums';
const FILES = '/files';
const EMPLOYEES = '/employees';
const CONTACTS = '/contacts';
const CARD_HOLDERS = '/card-holders';
const UI = '/ui';
const FILE = '/file';
const EXPRESS_SETUPS = '/express-setups';

const GATEWAYLESS_REPORTS = `https://api.${import.meta.env.REACT_APP_ENV || DEV_ENV_KEY}.elevateaccounts.com/api/report/v1/reports`;
// const REPORTS = '/reports';
const REPORTS = GATEWAYLESS_REPORTS;
const GATEWAY_REPORTS = '/reports';


const CLAIM_REPORT = `${GATEWAYLESS_REPORTS}/claim`;
const INTEREST_RATE = '/interest-rate-templates';
const PRIORS = '/priors';
const PAYOUT_DEFINITION = '/payout-definitions';
const TEMPLATES = '/templates';
const LINKED_TEMPLATES = '/linked-templates';
const IDOLOGY_PREFIX = '/idology';

export default {
  ADD_VAULTED_ACCOUNT_TO_RECIPIENT: (merchantCustomerId?: string) => `/recipients/${merchantCustomerId}/accounts`,
  ACCOUNT_ACTIVITIES_BY_ID: (id?: string | number, employeeId?: number | string) => `/activities/accounts/${id}?employee_id=${employeeId}`,
  ACCOUNT_ACTIVITY_REPORT: (id?: string) => `${REPORTS}/participant-account-activity/${id}`,
  ACCOUNT_MANAGERS: '/account-managers',
  ACCOUNT_TYPES: `${ENUM}/account-types`,
  ACCOUNT_TOKEN: '/account-tokens',
  ACCOUNT_ORDERS: '/account-orders',
  ACTIVITY_CLAIMS_BY_ID: (claimId: string) => `/activities/claims/${claimId}`,
  ACTIVITY_POSTINGS_BY_ID: (postingId: string) => `/activities/postings/${postingId}`,
  ADD_EMPLOYEE: `${UI}/employees`,
  ADD_PROVIDER: '/providers',
  ADD_CARRIER: CARRIERS,
  ADD_CARRIER_CONFIG: CARRIER_CONFIGS,
  UPDATE_PROVIDER: (id?: string) => `/providers/${id}`,
  BANK_ACCOUNT_CATEGORIES: `${ENUM}/account-categories`,
  BANK_INFORMATION_BY_ID: (id: string) => `${ORGANIZATIONS}/${id}/accounts`,
  BANK_INFORMATION: '/accounts',
  BANC_ACCOUNT_BY_ID: (accountId: string | number) => `/accounts/${accountId}`,
  BANK_METHODS: `${ENUM}/bank-methods`,
  BUSINESS_ENTITY_TYPES: `${ENUM}/business-entity-types`,
  CIP_STATUS: '/cip-statuses',
  CIP_COMPLETE_VERIFICATION: '/complete-cip-verification',
  CIP_INFO: '/cip-infos/employee-id',
  CIP_IDENTIFICATION: `${IDOLOGY_PREFIX}/identification`,
  CARD_ADD_DEPENDENT: '/card-add-dependent',
  CARD_HOLDER_MOCK: '/card-holder',
  CARD_HOLDERS_MOCK: CARD_HOLDERS,
  CARD_HOLDERS: (query: string) => `${CARD_HOLDERS}?${query}`,
  CARD_REPORT: (id?: string) => `${REPORTS}/card/${id}`,
  CARD_TRANSACTIONS: '/transaction/card/',
  CARDS: '/cards',
  GET_CARRIERS: CARRIERS,
  GET_IRS_LIMITS: '/contribution-requests/irs-limits',
  CHANGE_CONTRIBUTION_REQUEST_STATUS: (contributionId?: string, status?: string) => `/contributions/contribution-requests/${contributionId}/status?status=${status}`,
  GET_CONTRIBUTIONS_COVERAGE_PERIODS: '/manual-contribution/coverage-periods',
  GET_PERMISSIONS: '/permissions',
  GET_CARRIER_BY_ID: (id?: string) => `${CARRIERS}/${id}`,
  GET_CARRIER_CONFIGS: CARRIER_CONFIGS,
  GET_CARRIER_CONFIG: (id: string) => `${CARRIER_CONFIGS}/${id}`,
  GET_ORGANIZATIONS_HIERARCHY: `${ORGANIZATIONS}/hierarchy`,
  GET_CHECKS_LIST: '/checks/search',
  VOID_CHECK: (id?: string) => `/checks/${id}/void`,
  REISSUE_CHECK: (id?: string) => `/checks/${id}/reissue`,
  DISABLE_CARRIER_CONFIG: (id: string) => `${CARRIER_CONFIGS}/disable/${id}`,
  PROCESS_CLAIM: (id?: string) => `${CLAIMS}/${id}/process`,
  CLOCK_IN: (id?: string, clockIn?: boolean) => `${CONTACTS}/${id}/clock-in?clockIn=${clockIn}`,
  TRANSFER_CLAIM: (claimId?: string) => `${CLAIMS}/${claimId}/transfer`,
  CHECK_ACCOUNT_NUMBER: '/check-account-number',
  CHECK_ROUTING_NUMBER: '/check-routing-number',
  CHECK_WRITINGS: `${ENUM}/check-writings`,
  CIP_REPORT: (id?: string) => `${REPORTS}/cip/status/${id}`,
  CLAIM_DETAILS_BY_ID: (claimId?: string) => `${CLAIMS}/${claimId}`,
  CLAIM_REIMBURSEMENT_ACTIVITY_REPORT: (id?: string) => `${REPORTS}/reimbursement/${id}`,
  CLAIM_STATUSES: `${ENUM}/claim-status`,
  CLAIMS_PAYMENT_DETAILS: `${CLAIMS}/payment-details`,
  CLAIMS: `${CLAIMS}/search`,
  CLAIMS_TEAMS: '/claims-teams',
  CLAIMS_TEAM: (id?: string) => `/claims-teams/${id}`,
  CLAIMS_TEAM_HIERARCHY: (id?: string) => `/organizations/claims-team/${id}/hierarchy`,
  CLAIMS_PROCESSOR: (id?: string) => `/claims-processor/${id}`,
  ASSIGN_TEAMS_TO_CLAIMS_PROCESSOR: (id?:string) => `${CONTACTS}/${id}/claims-teams`,
  ASSIGN_PROCESSORS_TO_TEAM: (id?:string) => `/claims-teams/${id}/contacts`,
  ASSIGN_CLAIMS_TO_PROCESSOR: `${CLAIMS}/assign-contact`,
  UNASSIGN_CLAIMS_TO_PROCESSOR: `${CLAIMS}/unassign-contact`,
  CLAIM_CONFIG_UPDATE: (employeeId: string) => `/employees/${employeeId}/claim-config`,
  CREATE_CLAIM: CLAIMS,
  COVERAGES: '/coverages',
  CONTACTS,
  CONTACT_INFO: (contactId?: string) => `${CONTACTS}/${contactId}`,
  TRANSFER_ACCOUNTS: (claimId?: string) => `${CLAIMS}/${claimId}/transfer-accounts`,
  CONTACTS_LIST: `${CONTACTS}/all`,
  CONTACTS_TEAMS: `${CONTACTS}/teams`,
  UPDATE_CONTACT: (contactId?: string) => `${CONTACTS}/${contactId}`,
  CONTRIBUTION_INVOICE_REPORT: (id?: string) => `${REPORTS}/contribution/${id}`,
  COPAY_GROUPS: '/copay-groups',
  COPAY_GROUPS_BY_ID: (id?: string) => `/copay-groups/${id || ''}`,
  CREATE_INTERNAL_NOTE: `${CLAIMS}/internal-notes`,
  CREATE_DOCUMENT_ID: '/documents',
  DEDUCTIBLES_SERVICES: (employeeId?: string) => `/deductibles-services/?employee_id=${employeeId}&service_type=MANUAL_CLAIM&page=0&size=1000`,
  DEPENDENTS_NAMES: '/dependents/names',
  DEDUCTIBLES: '/tiers/deductibles',
  GET_INDIVIDUAL_DEDUCTIBLES_TIERS: (employeeId?: string) => `/deductibles/available-tiers/individual/${employeeId}`,
  GET_FAMILY_DEDUCTIBLES_TIERS: (employeeId?: string) => `/deductibles/available-tiers/family/${employeeId}`,
  UPDATE_DEDUCTIBLE_TIER: (id?: string) => `/deductibles/tiers/${id}`,
  DEACTIVATE_CONTACT: (id: string) => `${CONTACTS}/${id}/statuses/DISABLED`,
  DEACTIVATE_ORGANIZATION: (id: string) => `${ORGANIZATIONS}/${id}/statuses/TERMINATED`,
  ACTIVATE_ORGANIZATION: (id: string) => `${ORGANIZATIONS}/${id}/activate`,
  TERMINATE_ORGANIZATION: (id: string) => `${ORGANIZATIONS}/${id}/termination`,
  DEPENDENTS_LIST_BY_EMPLOYEE_ID: (id: string) => `/employee/${id}/dependents`,
  DELETE_DOCUMENT: (id: string) => `/documents/${id}`,
  DELETE_FILE: (id: string) => `/documents/files/${id}`,
  DEPENDENTS: '/dependents',
  CIP_DOCUMENT_SCAN_CHECK: () => '/idology/document-process-status',
  DISTRIBUTORS: '/distributors',
  DOCUMENT_BY_ID: (documentId: string) => `/documents/${documentId}/files`,
  ELECTRONIC_FUNDING_REPLENISHMENTS: `${ENUM}/electronic-funding-replenishments`,
  ELIGIBLE_DEPENDETS_TYPES: `${ENUM}/eligible-dependents`,

  GET_PROVIDERS: '/providers',
  GET_PROVIDER: (id: string) => `/providers/${id}`,
  EMPLOYEE_PERSONAL_INFO_BY_ID: (id: string) => `${EMPLOYEES}/personal-info/${id}`,
  EMPLOYEES_SEARCH: `${EMPLOYEES}/admin/search`,
  GET_EMPLOYEES_WITH_ACTIVE_PLANS: '/employee-search/active-plan',
  EMPLOYER: '/employer',
  EMPLOYERS: '/employers',
  ENCRYPTION_PUBLIC_KEY: 'encryption/public-key',
  ENROLLMENT_REPORT: (userType: string, id: string | number) => `${REPORTS}/enrollment/${userType}/${id}`,
  ENROLLMENTS: 'enrollments/',
  ENROLLMENT_MATRIXES: '/enrollment-matrixes',
  ENROLLMENT_MATRIXES_CLOSEST: '/enrollment-matrixes/closest',
  EMPLOYEE_BY_ID: (id: string) => `${UI}/employees/${id}`,
  GET_SSN_BY_EMPLOYEE_ID: (id: string) => `${UI}/employees/${id}/ssn`,
  EXPIRED_ENROLLMENT_MATRIXES: '/enrollment-matrixes/expired',
  EXPENSES_STATISTICS: '/expenses/statistics',
  ENROLLMENTS_EXPENSES_STATISTICS: '/enrollment/balance/expense',
  EXPENSES: '/expenses',
  EXPORT_ACCOUNT_ACTIVITY_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/participant-account-activity/export/${id}`,
  EXPORT_CARD_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/card/export/${id}`,
  EXPORT_CIP_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/cip/status/export/${id}`,
  EXPORT_CLAIM_REIMBURSEMENT_ACTIVITY_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/reimbursement/export/${id}`,
  EXPORT_CONTRIBUTION_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/contribution/export/${id}`,
  EXPORT_ENROLLMENT_REPORT: (userType: string, id: string | number) => `${GATEWAYLESS_REPORTS}/enrollment/export/${userType}/${id}`,
  EXPORT_REPLENISHMENT_REPORT: (id: string) => `${GATEWAYLESS_REPORTS}/replenishment/export/${id}`,
  EXPORT_PREFUND_REPORT: (invoiceId?: string) => `${GATEWAYLESS_REPORTS}/prefund/export/${invoiceId}`,
  EXPRESS_SETUPS,
  EXPRESS_SETUP_BY_ID: (id: string) => `${EXPRESS_SETUPS}/${id}`,
  EXPRESS_SETUP_STATUS_BY_ID: (id: string) => `${EXPRESS_SETUPS}/${id}/status`,
  FILE_PROCESSOR_STREAM: '/file-status-event/stream',
  FISERV_TOKEN: '/tokens',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_USERNAME: `${CONTACTS}/usernames/forgot`,
  GET_BANK_BY_NAME: '/get-bank-by-name',
  GET_CLAIM_APPROVAL_STATUS_CODES: `${ENUM}/claim-approval-status-code`,
  GET_CLAIM_ACCOUNTS_INFO: (employeeId?: string, serviceId?: string) => `/account-orders?employee_id=${employeeId}&service_id=${serviceId}`,
  GET_CLAIM_BY_CATEGORY: (userType: string, id: string | number) => `${CLAIM_REPORT}/category/${userType}/${id}`,
  GET_CLAIM_BY_STATUS: (userType: string, id: string | number) => `${CLAIM_REPORT}/status/${userType}/${id}`,
  GET_ORGANIZATION_BY_ID: (id?: string | null) => `${ORGANIZATIONS}/${id}`,
  OVERRIDABLE_PROPERTIES_PERMISSIONS: `${ENUM}/overridable-properties-permissions`,
  GET_FILE_DETAILS_BY_ID: (id: string) => `${FILE}/${id}`,
  GET_FILE_ACTORS: `${FILES}/file-actors`,
  GET_FILE_DETAILS: (id: string) => `${FILE}/files/${id}/details`,
  GET_FILE_DOWNLOAD_LINK: (id: string) => `${FILE}/file-link/${id}`,
  GET_FILE_ERRORS_CSV: (fileId: string) => `${FILE}/files/${fileId}/errors-csv`,
  GET_FILE_RECORD_ERRORS: (fileId: string) => `${FILE}/files/${fileId}/records/errors`,
  GET_FILE_ERRORS: (fileId: string) => `${FILE}/files/${fileId}/errors`,
  GET_FILES_BY_DOCUMENT_ID: (documentId?: string) => `/documents/${documentId}/files`,
  GET_FILES: `${FILE}/files`,
  GET_ORGANIZATION_NAME: (id?: string) => `${ORGANIZATIONS}/${id}/name`,
  GET_ORGANIZATION_LOCK_STATUS: (id?: string) => `/organization-locks/${id}`,
  GET_PERSON_ORGANIZATION_SHORT_INFO: (id: string) => `${ORGANIZATIONS}/${id}/short`,
  GET_PLAN_YEARS: (id: string | null) => `${PLAN_YEARS}?organization_id=${id}`,
  GET_POLICIES_STATUSES: (id?: string) => `/policies/${id}/policy-statuses`,
  GET_STATE: (name: string) => `${ENUM}/states&name=${name}`,
  GET_SUBSIDIARIES: '/subsidiary',
  GET_PAYOUT_DEFINITION_BY_ID: (id?: string) => `${PAYOUT_DEFINITION}/${id}`,
  GET_INVESTORS: '/investors',
  HELP: '/help',
  HOME: '/',
  HSA_CUSTODIAN: `${ENUM}/hsa-custodians`,
  INTEREST_RATE_ACTIVE: `${INTEREST_RATE}/active`,
  INTEREST_RATE_LATEST_VERSION: (id: string) => `${INTEREST_RATE}/${id}/versions/latest`,
  INTEREST_RATE,
  IRS_MAXIMUM: '/irs-maximums',
  IRS_MAXIMUM_TYPES: '/irs-maximums/types',
  GET_INVOICES_DASHBOARD: '/invoices/dashboard',
  GET_PREFUND_INVOICE: (id: string) => `/invoices/${id}/details-view`,
  REPULL_PREFUND_BY_INVOICE_ID: (id: string) => `/prefund-histories/invoices/${id}/resume`,
  REPULL_REPLENISHMENT_BY_INVOICE_ID: (id: string) => `/prefund-histories/invoices/${id}/resume/replenishment/reject`,
  LOGIN: `${AUTH}login`,
  SEND_FORGOT_PASSWORD_CODE: '/persons/forgot-password',
  LOCK_ORGANIZATION: (id: string) => `/organization-locks/lock/${id}`,
  UNLOCK_ORGANIZATION: (id: string) => `/organization-locks/unlock/${id}`,
  ORGANIZATION_HIERARCHY: (organizationId: string) => `/hierarchy/${organizationId}`,
  ORGANIZATIONS,
  ORPHAN_ORGANIZATIONS: `${ORGANIZATIONS}/orphan`,
  ORGANIZATION_LOGO_EMAIL: '/organizations/logo/email-templates',
  ORGANIZATION_INFO: (organizationId?: string) => `${ORGANIZATIONS}/${organizationId}/employee-view`,
  ORGANIZATION_PROPERTIES: (organizationId?: string) => `${ORGANIZATIONS}/${organizationId}/overridable-properties/employee`,
  PARTNERS: '/partners',
  PAYMENT_METHODS: '/payment-methods',
  PAYOUT_DEFINITION,
  PAYOUT_DEFINITION_PLANS: (id: string) => `${PAYOUT_DEFINITION}/${id}/plans`,
  PAYMENT_METHODS_ATTACH_CARD: (paymentId: string) => `/payment-methods/${paymentId}/card`,
  PAYMENT_RULE_ACCOUNTS: (id: string, serviceId?: string) => `/payout-definitions/${id}/plans?service_id=${serviceId}`,
  PERMISSIONS: '/roles',
  PROCESSING_RULES: '/processing-rules',
  PERSON_COPAY_GROUPS: '/copay-groups',
  PERSON_DEPENDENT_WORKFLOWS: '/dependents',
  PERSON_ORGANIZATIONS_CONTACTS: `${CONTACTS}/all`,
  PERSON_ORGANIZATIONS_HIERARCHY_BY_ID: `${CONTACTS}/up-hierarchy`,
  PERSONS_BY_ID: (id?: string | number) => `/persons/${id}`,
  PLAN_BY_ID: (id: string) => `${PLANS}/${id}`,
  PLAN_YEAR: (id?: string) => `${PLAN_YEARS}/${id || ''}`,
  PLANS_BY_PLAN_YEAR: (id?: string) => `${PLAN_YEARS}/${id || ''}${PLANS}`,
  PRIOR_PLAN_YEAR: `${PLAN_YEARS}${PRIORS}`,
  PLAN_PRIOR: `${PLANS}${PRIORS}`,
  PLANS,
  HEALTH_PLANS,
  HEALTH_PLAN_TEMPLATES: `${HEALTH_PLANS}/templates`,
  HEALTH_PLAN_BY_ID: (id: string) => `${HEALTH_PLANS}/${id}`,
  PROCESS_FILE: (fileId: string) => `${FILE}/approve/${fileId}`,
  PROOF_EXPENSES_TYPES: `${ENUM}/proof-of-expenses`,
  RECONCILIATION_ACCOUNT_CLOSE_DETAILS: `${GATEWAY_REPORTS}/account-close-details`,
  RECONCILIATION_CLOSES_BY_ACCOUNT: `${GATEWAY_REPORTS}/closes-by-account`,
  RECONCILIATION_LIST_OF_BANK_ACCOUNTS: `${GATEWAY_REPORTS}/list-of-accounts`,
  RELATIONSHIP_LIST: `${ENUM}/relationship-types`,
  REPLACEMENT_CARD_REQUEST: (cardId: string) => `/cards/${cardId}/replacement`,
  REPLENISHMENT_INVOICE_REPORT: (id?: string) => `${REPORTS}/replenishment/${id}`,
  REPORTS,
  REIMBURSE_RECEIPT_UPLOAD: (documentId: string) => `/documents/${documentId}/import`,
  REIMBURSE_SCANNED_FILE: (fileId: string) => `/documents/files/${fileId}/ocr-reader`,
  CLAIM_SCANNED_FILE: (fileId: string) => `/documents/files/${fileId}/openai/ocr/matching`,
  SEND_CODE: '/send-code',
  SERVICES_TYPE: (id: string, type?: string, categories?: string[]) => `/payout-definitions/${id}/services?type=${type || ''}&categories=${categories || ''}`,
  SERVICE_CATEGORIES: `${ENUM}/service-categories`,
  SERVICE_CONFIGS: '/service-configs',
  SOURCE_IDS: `${ENUM}/source-ids`,
  STATES: `${ENUM}/states`,
  ATTRIBUTES_LIST: '/attributes-list',
  ACTIVITY_LOG: '/activity-logs',
  ACTIVITY_LOG_CHANGESET: (changesetId?: string) => `/activity-logs/${changesetId}`,
  ACTIVITY_LOG_CHANGESETS: '/activity-logs-map',
  SERVICES: '/services',
  ACCOUNT_ORDERS_SERVICES: '/account-orders/services',
  ACCOUNT_ORDERS_MATRIXES: '/account-orders/matrixes',
  TEMPLATES,
  LINKED_TEMPLATES: (orgId?: string) => `${TEMPLATES}${ORGANIZATIONS}/${orgId}${LINKED_TEMPLATES}`,
  EMAIL_ILLUSTRATION: '/email-illustrations',
  EMAIL_THEMES: '/email-template-themes',
  MOBILE_APP_THEMES: '/mobile-themes',
  TEMPLATE_ASSIGNED: `${PLANS}/template-assigned`,
  EMPLOYER_TERMINATION_PLANS: (orgId: string) => `${PLANS}${ORGANIZATIONS}/${orgId}/active-grace-period`,
  TRIGGERS: `${ENUM}/triggers`,
  TRANSACTOR_DETAILS_BY_ID: (id?: string) => `/transactors/${id}`,
  UPDATE_INTERNAL_NOTE: (claimId: number, noteId: number) => `${CLAIMS}/${claimId}/internal-notes/${noteId}`,
  UPDATE_CLAIM_NOTE: (claimId?: string) => `${CLAIMS}/${claimId}/escalation-note`,
  UPLOAD_CSV_FILE: '/files/csv/import',
  UPLOAD_ORGANIZATION_LOGO: `${ORGANIZATIONS}/logo`,
  UPDATE_DEFAULT_PAYMENT_METHODS: (paymentMethodId: number | string) => `/payment-methods/${paymentMethodId}`,
  UNLINK_TEMPLATE: (id?: string, orgId?: string) => `/templates/${id}/unlinking?organization_id=${orgId}`,
  GET_EXTERNAL_EMAIL_TEMPLATES: '/external-templates',
  GET_AVAILABLE_EXTERNAL_EMAIL_TEMPLATES: '/external-templates/available',
  GET_HTML_PREVIEW_EMAIL_TEMPLATES: (id?: string) => `/external-templates/${id}/html-preview`,
  GET_HTML_PREVIEW_VARIABLES_EMAIL_TEMPLATES: (id?: string) => `/external-templates/${id}/variables`,
  UPDATE_EXTERNAL_EMAIL_TEMPLATE: (id?: string) => `/organizations/${id}/notification-properties`,
  CREATE_ENROLLMENT: '/enrollments',
  FISERV_CARD_ACTIVATION: '/maintenance/card',
  ORG_PREFUND_HISTORIES: (invoiceId: string) => `/prefund-histories/invoices/${invoiceId}/summary`,
  CARD_ACTIVITY: '/activities/cards',
  CLAIMS_ACTIVITIES: `${CLAIMS}/search/activities`,
  PROCESS_WAITING_FOR_FUNDS_CLAIM: (id?: string) => `${CLAIMS}/${id}/process-waiting-for-funds`,
  ACCOUNT_NUMBER: (accountId: string) => `/accounts/hsa/${accountId}/number`,
  UPDATE_CLAIM_DOCUMENT_ID: (id?: string) => `${CLAIMS}/${id}/document`,
  ESCALATE_CLAIM: (id?: string) => `/claims/claim/${id}/escalate`,
  LOCKBOXES: '/lockboxes',
  LOCKBOX_BY_ID: (lockboxId?: string) => `/lockboxes/${lockboxId}`,
  UPDATE_LOCKBOX: (lockboxId?: string) => `/lockboxes/${lockboxId}`,
  UPDATE_LOCKBOX_CHECK_DETAILS: (lockboxId?: string) => `/lockboxes/${lockboxId}/check-details`,
  GET_MATCHED_EMPLOYEES: `${EMPLOYEES}/matches`,
  GET_CONTRIBUTION_SEGMENT_BY_ID: (contributionId?: string) => `/segments?contribution_id=${contributionId}`,
  CREATE_MANUAL_CONTRIBUTION: '/manual-contribution',
  GET_CONTRIBUTION_ACTIVITIES: '/contributions/contribution-requests/activity',
  GET_CONTRIBUTION_ACTIVITIES_CSV_REPORT: '/contributions/contribution-requests/activity/csv-report',
  GET_CONTRIBUTIONS: '/contribution-requests',
  GET_CONTRIBUTIONS_EMPLOYEE_VIEW: '/manual-contribution/employees',
  GET_TOTAL_CONTRIBUTIONS_EMPLOYEE_VIEW: '/manual-contribution/employees/count',
  GET_PAYROLL_GROUPS: (orgId?: string) => `/payroll-groups/organizations/${orgId}`,
  GET_PAYROLL_CALENDARS: (orgId?: string) => `/ui/payroll-calendars/organizations/${orgId}`,
  CREATE_PAYROLL_GROUP: '/payroll-groups',
  UPDATE_PAYROLL_GROUP: (id?: string) => `/payroll-groups/${id}`,

  ORGANIZATION_PAYROLL_CALENDAR: (id?: string) => `/payroll-calendars/organizations/${id}`,
  CONTRIBUTION_RECORDS: '/contribution/records',
  SCHEDULED_CONTRIBUTION_CALENDAR_SUMMARY: (id: string) => `/scheduled-contribution-search/calendar/${id}/summary`,
  SCHEDULED_CONTRIBUTION_RECORDS_SUMMARY: (id: string) => `/scheduled-contribution-search/calendar/${id}/employees`,
  SCHEDULED_CONTRIBUTION_EXPORT: (id: string) => `/scheduled-contribution-search/calendar/${id}/csv-report`,

  SCHEDULED_CONTRIBUTION: '/scheduled-contributions',
  SCHEDULED_CONTRIBUTION_ORGANIZATION: '/scheduled-contribution-search/organization',
  CONTRIBUTION_HISTORY: '/monitor-contribution/info',
  CALCULATE_PAYROLL_CALENDAR_SCHEDULE: '/payroll-calendars/calculate-schedule',
  PAYROLL_CALENDARS: '/payroll-calendars',
  UPDATE_OR_DELETE_PAYROLL_CALENDAR: (id?: string) => `/payroll-calendars/${id}`,
  GET_PAYROLL_CALENDAR_BY_ID: (id?: string) => `/ui/payroll-calendars/${id}`,
  GET_VENDORS: '/vendors',
};
