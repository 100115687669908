import dayjs from 'dayjs';
import { toString } from 'lodash';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { ServiceCategoryTypes } from '@/modules/employer/types/ServiceCategories.types';
import { Plan } from '@/modules/plan/plan.types.ts';

import { ContactPayload } from '../AddEditContactForm/stores/useContact.store';
import { ContactDto } from '../ExpressSetup.types';
import { Copay, CopayDto, ExpressSetup, ExpressSetupDtoResponse, PlanDto } from '../ExpressSetup.types';

const getStringFromBoolean = (value?: boolean) => (value === true ? 'yes' : value === false ? 'no' : undefined);

export default (currentData?: ExpressSetupDtoResponse, serviceCategories?: ServiceCategoryTypes[]) => {
  if (!currentData) {
    return undefined;
  }

  const formatContact = (contact: ContactDto) => {
    return {
      id: toString(contact.id),
      firstName: contact.first_name,
      lastName: contact.last_name,
      email: contact.email,
      phoneNumber: contact.phone,
      isPrimary: contact.is_primary,
      isAccessToSetup: getStringFromBoolean(contact.is_access_to_setup),
      role: contact.role,
      contactName: `${contact.first_name || ''} ${contact.last_name || ''}`.trim(),
      userName: contact.user_name,
    } as ContactPayload;
  };

  const formatPlans = (plan: PlanDto) => {
    return {
      id: toString(plan.parent_id),
      name: plan.name || '',
      type: plan.account_type,
      summary: plan.summary || '',
      isCarded: plan.is_carded,
      isCustomPrefundCalc: plan.is_custom_prefund_calc,
      isPlanLevelFunding: plan.is_plan_level_funding,
      isReplenished: plan.is_replenished,
    } as Plan;
  };

  const formatCopays = (copay: CopayDto) => {
    const foundCategory = serviceCategories?.find((item) => item.key === copay.category_type);
    return {
      id: _.uniqueId(),
      amount: _.toString(copay.amount),
      categoryType: copay.category_type,
      description: foundCategory?.value || '',
    } as Copay;
  };

  const sortedContacts = currentData?.contacts?.sort((a, b) => {
    if (b?.is_primary !== a?.is_primary) {
      return (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0);
    }
    return (a?.id ?? 0) - (b?.id ?? 0);
  });
  const formattedContacts = sortedContacts?.map(formatContact);
  const formattedPlans = currentData?.json_record?.plans?.map(formatPlans) || [];
  const formattedPlanTypes = formattedPlans?.map((plan) => plan.type) || [];
  const formattedCopays = currentData?.json_record?.copays?.length ? currentData?.json_record?.copays?.map(formatCopays) : [];

  const formattedData: ExpressSetup = {
    id: toString(currentData.id),
    statusType: currentData.status_type,
    orgId: toString(currentData.org_id),
    orgPath: currentData.org_path,
    name: currentData.organization_name,
    shortName: currentData.organization_short_name,
    address: currentData.json_record?.address ? {
      id: toString(currentData.json_record?.address?.id),
      street: currentData.json_record?.address?.line1 || '',
      street_2: currentData.json_record?.address?.line2 || '',
      city: currentData.json_record?.address?.city || '',
      state: currentData.json_record?.address?.state || '',
      zipCode: currentData.json_record?.address?.zipcode || '',
    } : undefined,
    logo: currentData?.links?.large_logo_path,
    privacyPolicyLink: currentData?.links?.privacy_policy_link,
    termsConditionsLink: currentData?.links?.terms_and_conditions_link,
    ...currentData?.links?.pricing_consent_link ? {
      pricingConsentLink: currentData?.links?.pricing_consent_link,
    } : {},
    contacts: formattedContacts || [],
    mainContact: formattedContacts?.find((item) => item.isPrimary),
    planStartDate: currentData.json_record?.plan_year?.valid_from ? dayjs(currentData.json_record?.plan_year?.valid_from).format(DEFAULT_DATE_FORMAT) : '',
    planEndDate: currentData.json_record?.plan_year?.valid_from ? dayjs(currentData.json_record?.plan_year?.valid_to).format(DEFAULT_DATE_FORMAT) : '',
    planYearName: currentData.json_record?.plan_year?.name || '',
    plans: formattedPlans,
    planTypes: formattedPlanTypes,
    bankAccountName: currentData.json_record?.account?.name || '',
    accountNumber: currentData.json_record?.account?.encrypted_number ? '****' : '',
    routingTransitNumber: currentData.json_record?.account?.routing_transit_number || '',
    accountCategory: currentData.json_record?.account?.account_category || '',
    encryptedNumber: currentData.json_record?.account?.encrypted_number || '',
    copays: formattedCopays,
    isCopaysSelected: !!currentData?.json_record?.copays?.length,
    partnerName: currentData?.partner_name || '',
    prefundReplenishmentPercentage: currentData?.prefund_replenishment_percent,
    replenishmentDayNumber: currentData?.replenishment_day_number,
    replenishmentFrequencyType: currentData?.replenishment_frequency_type,
  };
    
  return formattedData;
};
