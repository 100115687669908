import React, { useEffect, useRef, useState } from 'react';
import {
  Anchor,
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Heading,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import {
  GroupedPlan,
  PlanStatuses,
  PlansType,
} from '@/modules/plan/plan.types';
import theme from '@/styles/theme';

import { HEIGHT_FIXED_HEADER } from './PlansList';

const Wrapper = styled(Box)`
  &:last-child {
    border-bottom: none;
  }
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  margin-right: ${theme.spacings.s};
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${theme.colors.textAccent};
  }
`;

interface Props {
  title: string;
  status: PlanStatuses;
  children?: React.ReactNode;
  anchorId: string;
  testId?: string;
  plans: GroupedPlan[];
  activeSection?: PlanStatuses;
  scrollToSection: (data: { module?: string; section?: string }) => void;
  searchValue: string;
  isTemplateMode?: boolean;
  isScrolled?: boolean;
}

const PlanTypeWrapper: React.FC<Props> = ({
  anchorId,
  title,
  children,
  status,
  testId = '',
  plans,
  scrollToSection,
  activeSection,
  searchValue,
  isTemplateMode,
  isScrolled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);
  const [expanded, setExpanded] = useState(
    localStorage.getItem(
      `${isTemplateMode ? PlansType.Template : PlansType.Plan}-${status}`
    ) !== 'false'
  );

  useEffect(() => {
    localStorage.setItem(
      `${isTemplateMode ? PlansType.Template : PlansType.Plan}-${status}`,
      `${expanded}`
    );
  }, [expanded, status, isTemplateMode]);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If the ref is not in the viewport, make the header sticky
        if (!entry.isIntersecting) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      },
      {
        root: null,
        rootMargin: `-${HEIGHT_FIXED_HEADER + 1}px 0px 0px 0px`,
        threshold: 0,
      }
    );
  
    if (currentRef) {
      observer.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <>
      <div ref={ref} />
      <Box
        style={{
          position: 'sticky',
          top: `${HEIGHT_FIXED_HEADER + 1}px`,
          zIndex: 110,
        }}
        background="canvas"
        direction="row"
        align="center"
        height="40px"
        border={isSticky && expanded && { side: 'bottom', size: '1px', color: 'border1' }}
        margin={{ bottom: expanded ? 's' : '0' }}
      >
        <Button
          data-testId={`${testId}_wrapper_title`}
          onClick={() => setExpanded(!expanded)}
        >
          <Box direction="row" align="center">
            <Box margin={{ right: 'xxxs' }}>
              {!activeSection && expanded ? (
                <ChevronUpIcon
                  color="iconPrimary"
                  data-testId={`${testId}_chevron-up`}
                />
              ) : (
                <ChevronDownIcon
                  color="iconPrimary"
                  data-testId={`${testId}_chevron-down`}
                />
              )}
            </Box>
            <Heading
              level={4}
              color={searchValue && !plans.length ? 'textSecondary' : 'textTitle'}
              size={isScrolled ? '16px' : 'small'}
              data-testId={`${testId}_title`}
              style={{ transition: '0.2s' }}
            >
              {title}
            </Heading>
          </Box>
        </Button>
        {plans.map((item) => (
          <StyledAnchor
            key={item.category}
            size="medium"
            color="textSecondary"
            weight={400}
            onClick={() => scrollToSection({ module: `${item.category}_${status}` })}
          >
            {item.category} ({item?.plans?.length})
          </StyledAnchor>
        ))}
      </Box>
      <Wrapper
        id={!activeSection ? anchorId : undefined}
        margin={{ bottom: expanded ? 'm' : '0' }}
        data-testId={`${testId}_wrapper`}
      >
        {!activeSection && expanded && (
          <Box
            pad={{ vertical: 'l', horizontal: 'm' }}
            data-testId={`${testId}_content`}
            background="module"
            round="moduleRound"
          >
            {children}
          </Box>
        )}
      </Wrapper>
    </>
  );
};

export default PlanTypeWrapper;
