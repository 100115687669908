import React, { useMemo } from 'react';
import { Box } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import { useHasAccess } from '@/modules/core/hooks';
import ModuleWrapper from '@/modules/plan/ModuleWrapper';

import { PLAN_MODULES } from '../../plan.constants';

import ActivatePlan from './ActivatePlan';
import FinalSetUpModal from './FinalSetupModal';
import { useHandlePlanSetUp } from './hooks';
import SavePlan from './SavePlan';
import ValidationNode from './ValidationNode';

interface Props {
  isEditMode?: boolean;
  planName?: string;
  isPlanActive?: boolean;
  isTemplateMode?: boolean;
  saveButtonRef?: React.RefObject<HTMLButtonElement>;
  activateButtonRef?: React.RefObject<HTMLButtonElement>;
  reviewButtonRef?: React.RefObject<HTMLButtonElement>;
}
const FinalSetup: React.FC<Props> = ({
  isEditMode,
  planName,
  isTemplateMode,
  isPlanActive,
  saveButtonRef,
  activateButtonRef,
  reviewButtonRef,
}) => {
  const {
    onSaveDraftHandler,
    onUpdateHandler,
    onActivateHandler,

    errorMessages,
    successMessages,
    pendingMessages,

    activateErrorMessages,
    activateSuccessMessages,
    activatePedingMessages,
  } = useHandlePlanSetUp(isTemplateMode);
  const isHasAccess = useHasAccess([
    {
      permission: Permissions.EDIT_DRAFT_PLAN,
    },
    {

      permission: Permissions.ACTIVATE_PLAN,
    },
  ]);

  const activatePlanProps = useMemo(() => ({
    activateButtonRef,
    errorMessages: activateErrorMessages,
    successMessages: activateSuccessMessages,
    pendingMessages: activatePedingMessages,
    isTemplateMode,
    viewMode: !isEditMode,
    onActivate: onActivateHandler,
    isPlanActive,
    planName,
  }), [activateButtonRef, activateErrorMessages, activateSuccessMessages, activatePedingMessages, isTemplateMode, isEditMode, onActivateHandler, isPlanActive, planName]);

  if (!isHasAccess || (isTemplateMode && isPlanActive)) {
    return null;
  }
  return (
    <ModuleWrapper anchorId={PLAN_MODULES.FINAL_SETUP} title="Final Setup" expandless={false}>
      <ValidationNode isTemplateMode={isTemplateMode} />
      <Box
        pad="spacing8"
        round="container1Round"
        direction="row"
        align="stretch"
        flex="grow"
      >
        <FinalSetUpModal
          mode={isTemplateMode ? 'Plan Template' : 'Plan'}
          handleSave={onSaveDraftHandler}
          handleUpdate={onUpdateHandler}
          handleActivate={onActivateHandler}
        />

        {
          !isPlanActive && (
            <SavePlan
              saveButtonRef={saveButtonRef}
              reviewButtonRef={reviewButtonRef}
              errorMessages={errorMessages}
              successMessages={successMessages}
              pendingMessages={pendingMessages}
              isTemplateMode={isTemplateMode}
              viewMode={!isEditMode}
              onSave={onSaveDraftHandler}
            />
          )
        }
        {!isPlanActive && <Box pad="spacing8" />}
        {
          isPlanActive ? (
            <Box width="464px">
              <ActivatePlan
                {...activatePlanProps}
              />
            </Box>
          ) : (
            <ActivatePlan
              {...activatePlanProps}
            />
          )
        }
      </Box>
    </ModuleWrapper>
  );
};

export default React.memo(FinalSetup);
