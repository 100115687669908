export interface EnrollmentIdentifiersForm {
  tpa_partner: string;
  employer: string;
  employer_employee_id?: string;
  partner_employee_id?: string;
  ssn?: string;
  last_name: string;
}

export interface EnrollmentPlanForm {
  plan: string;
  plan_year: string;
  plan_start_date: string;
  plan_end_date: string;
  enrollment_status: string;
  election_amount: string;
  enrollment_date: string;
  deductible_met?: string;
  per_pay_employee?: string;
  per_pay_employer?: string;
  limited_use?: LimitedUses;
}

export interface AddEnrollmentForm
  extends EnrollmentIdentifiersForm,
  EnrollmentPlanForm {
  deductibleMetDate?: string;

}

export enum LimitedUses {
  LIMITED_USE = 'Limited Use',
  GENERALE_PURPOSE = 'General Purpose',
  GENERALE_PURPOSE_DEDUCTIBLE_MET = 'General Purpose - Deductible Met',
}