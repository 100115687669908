import { useQuery } from 'react-query';
import { toString } from 'lodash';

import { api } from '@/api';
import PATHS from '@/common/paths';

export const GET_CARRIERS = 'GET_CARRIERS';

interface CarrierPayload {
  id: number;
  name: string;
  external_id: string;
}

export interface Carrier {
  id: string;
  name: string;
  externalId: string;
}

export const formatCarrier = (carrier: CarrierPayload): Carrier => ({
  id: toString(carrier.id),
  name: carrier.name,
  externalId: carrier.external_id,
});

const useGetCarriersQuery = (searchValue?: string) => {
  const {
    isLoading,
    data,
  } = useQuery(
    [GET_CARRIERS, searchValue],
    () => api.get(
      PATHS.GET_CARRIERS,
      {
        params: {
          ...searchValue ? {
            search_value: searchValue,
          } : {},
        },
      },
    ),
  );

  return {
    carriers: (data?.data?.content || [])
      .map((carrier: CarrierPayload) => formatCarrier(carrier)) as Carrier[],
    isLoading,
  };
};

export default useGetCarriersQuery;
