enum Permissions {
  EDIT_EE_PRIMARY_ID = 'EDIT_EE_PRIMARY_ID',
  CLAIM_TRANSFER = 'CLAIM_TRANSFER',
  VIEW_ALL = 'VIEW_ALL',
  EDIT_EE_DATA = 'EDIT_EE_DATA',
  EDIT_PROFILE = 'EDIT_PROFILE',
  VIEW_PROFILE = 'VIEW_PROFILE',
  EDIT_DEP_DATA = 'EDIT_DEP_DATA',
  EDIT_ER_DATA = 'EDIT_ER_DATA',
  VIEW_ER = 'VIEW_ER',
  EDIT_ER_BANK_ACCOUNT_INFO = 'EDIT_ER_BANK_ACCOUNT_INFO',
  EDIT_EE_PAYMENT_METHOD = 'EDIT_EE_PAYMENT_METHOD',
  DELETE_EE_DATA = 'DELETE_EE_DATA',
  DELETE_ER_DATA = 'DELETE_ER_DATA',
  ORDER_CARD = 'ORDER_CARD',
  REISSUE_CARD = 'REISSUE_CARD',
  CREATE_CLAIM = 'CREATE_CLAIM',
  PROCESS_CLAIM = 'PROCESS_CLAIM',
  MANAGE_CLAIM_QUEUE = 'MANAGE_CLAIM_QUEUE',
  EDIT_CLAIM_CLAIM_OVERRIDE = 'EDIT_CLAIM_CLAIM_OVERRIDE',
  ACTIVATE_ER = 'ACTIVATE_ER',
  INITIATE_PAYMENT_ADJUSTMENT = 'INITIATE_PAYMENT_ADJUSTMENT',
  REPORTS = 'REPORTS',
  SECURITY_AUTHENTICATION = 'SECURITY_AUTHENTICATION',
  ADD_CONTACTS = 'ADD_CONTACTS',
  EDIT_CONTACT = 'EDIT_CONTACT',
  VIEW_CONTACT = 'VIEW_CONTACT',
  EDIT_TRANSACTOR = 'EDIT_TRANSACTOR',
  VIEW_ACTIVITIES = 'VIEW_ACTIVITIES',
  VIEW_EXPENSE = 'VIEW_EXPENSE',
  VIEW_SERVICE = 'VIEW_SERVICE',
  VIEW_TRANSACTOR = 'VIEW_TRANSACTOR',
  PROCESS_FILE = 'PROCESS_FILE',
  VIEW_FILE_REPORTS = 'VIEW_FILE_REPORTS',

  VIEW_INVOICES='VIEW_INVOICES',
  VIEW_FINOPS_CHECKS='VIEW_FINOPS_CHECKS',
  VIEW_LOCKBOX='VIEW_LOCKBOX',
  
  DELETE_ER_BANK = 'DELETE_ER_BANK',
  BANK_OPS = 'BANK_OPS',
  VIEW_PLAN = 'VIEW_PLAN',
  PLAN_SETUP = 'PLAN_SETUP',
  PLAN_YEAR_SETUP = 'PLAN_YEAR_SETUP',
  EDIT_DRAFT_PLAN = 'EDIT_DRAFT_PLAN',
  ACTIVATE_PLAN = 'ACTIVATE_PLAN',
  VIEW_COPAY = 'VIEW_COPAY',
  EDIT_COPAY = 'EDIT_COPAY',
  VIEW_PLAN_TEMPLATE = 'VIEW_PLAN_TEMPLATE',
  PLAN_TEMPLATE_SETUP = 'PLAN_TEMPLATE_SETUP',
  VIEW_HIDDEN_PLAN_FIELDS = 'VIEW_HIDDEN_PLAN_FIELDS',
  VIEW_CLAIM = 'VIEW_CLAIM',
  VIEW_EE_AVAILABLE_ORG_PROPERTIES = 'VIEW_EE_AVAILABLE_ORG_PROPERTIES',
  EDIT_CARD = 'EDIT_CARD',
  CREATE_CARRIER_CONFIG = 'CREATE_CARRIER_CONFIG',
  SET_UP_PROCESSING_DEFINITION = 'SET_UP_PROCESSING_DEFINITION',
  VIEW_PROCESSING_DEFINITION = 'VIEW_PROCESSING_DEFINITION',
  DISABLE_CARRIER_CONFIG = 'DISABLE_CARRIER_CONFIG',
  SET_UP_HEALTH_PLAN = 'SET_UP_HEALTH_PLAN',
  VIEW_HEALTH_PLAN = 'VIEW_HEALTH_PLAN',
  ORG_LOCK = 'ORG_LOCK',
  VOID_REISSUE_CHECK = 'VOID_REISSUE_CHECK',
  MANAGE_CLAIM_PROCESSORS = 'MANAGE_CLAIM_PROCESSORS',
  MANAGE_CLAIM_TEAMS = 'MANAGE_CLAIM_TEAMS',
  CLOCK_IN = 'CLOCK_IN',
  VIEW_CONTRIBUTIONS= 'VIEW_CONTRIBUTIONS',
  SUBMIT_MANUAL_CONTRIBUTIONS = 'SUBMIT_MANUAL_CONTRIBUTIONS',
  MANAGE_SCHEDULED_CONTRIBUTIONS = 'MANAGE_SCHEDULED_CONTRIBUTIONS'
}

export default Permissions;
