import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  dateValidatorByFormat,
  Field,
  Inscription,
  Preloader,
  WarnModal,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import AppButton from '@/components/controls/AppButton';
import { getDateIsoString } from '@/utils/modifiers/getDateIsoString';

import { useCancelEmployerTerminationModalStore, useEmployerTerminationModalStore, useEmployerTerminationSuccessModalStore } from '../store';
import { TerminationSteps } from '../store/useEmployerTerminationModal.store';

import { CancelationHsaRules } from './hooks/useEmployerTerminationHsaTransferFields';
import { CancelationRules } from './hooks/useEmployerTerminationParamsFields';
import { useEmployerTerminationPlanList } from './hooks/useEmployerTerminationPlanList';
import { useGetEmployerTerminationDataQuery } from './queries/useGetEmployerTerminationData.query';
import { useTerminateEmployerQuery } from './queries/useTerminateEmployer.query';
import { EmployerTerminationDateForm } from './EmployerTerminationDateForm';
import { EmployerTerminationHsaTransferForm } from './EmployerTerminationHsaTransferForm';
import { EmployerTerminationParamsForm } from './EmployerTerminationParamsForm';
import { EmployerTerminationPlanList } from './EmployerTerminationPlanList';
import { EmployerTerminationPlansCoverageDatesList } from './EmployerTerminationPlansCoverageDatesList';

const today = dayjs().format(DEFAULT_DATE_FORMAT);

interface FormValues {
  terminationDate?: string;
  cancelationRules?: string;
  endOfCoverage?: string;
  lastDayToSubmitClaim?: string;
  hsaTransferOfAssets?: string;
}
  
interface Props {
  onCancel?: (value: boolean) => void;
}
  
const EmployerTerminationModalForm: React.FC<Props> = ({
  onCancel,
}) => {  
  const [isSubmit, setSubmit] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});

  const [dateIsFilled, setDateIsFilled] = useState(false);
  const [paramsIsFilled, setParamsIsFilled] = useState(false);
  const [hsaIsFilled, setHsaIsFilled] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);
  const [permanentLastDate, setPermanentLastDate] = useState<string | undefined>();

  const terminationDate = useEmployerTerminationModalStore((state) => state.terminationDate);
  const employerId = useEmployerTerminationModalStore((state) => state.employerId);
  const handleSetTerminationDate = useEmployerTerminationModalStore((state) => state.handleSetTerminationDate);
  const handleSetStep = useEmployerTerminationModalStore((state) => state.handleSetStep);
  const step = useEmployerTerminationModalStore((state) => state.step);
  const handleChangeSuccessVisibility = useEmployerTerminationSuccessModalStore((state) => state.handleChangeVisibility);
  const handleChangeVisibility = useEmployerTerminationModalStore((state) => state.handleChangeVisibility);
  const editMode = useEmployerTerminationModalStore((state) => state.editMode);

  const handleChangeCancelVisibility = useCancelEmployerTerminationModalStore((state) => state.handleChangeVisibility);

  const isThereTerminationDate = useMemo(() => 
    terminationDate || dateValidatorByFormat(formValues.terminationDate, DEFAULT_DATE_FORMAT),
  [formValues.terminationDate, terminationDate]);

  const { terminateEmployer, isLoading: terminationLoading } = useTerminateEmployerQuery(employerId || '');
  const { terminationData, isLoading: terminationDataLoading } = useGetEmployerTerminationDataQuery(editMode ? employerId || '' : undefined);

  const currentTerminationDate = useMemo(() => {
    if (!!permanentLastDate && dayjs(formValues.terminationDate).isBefore(dayjs(permanentLastDate))) return undefined;
    return terminationDate || formValues.terminationDate || terminationData?.terminationDate;
  }, [formValues, terminationData, terminationDate, permanentLastDate]);

  const { 
    isLoading: planListLoading,
    data,
    coverageData,
    isHsaAccountExist,
    hsaStartDate,
  } = useEmployerTerminationPlanList(
    employerId,
    isThereTerminationDate
      ? currentTerminationDate
      : today,
    formValues.cancelationRules === CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS
    && dateValidatorByFormat(formValues.endOfCoverage, DEFAULT_DATE_FORMAT)
      ? formValues.endOfCoverage
      : undefined,
    formValues.cancelationRules === CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS
    && dateValidatorByFormat(formValues.lastDayToSubmitClaim, DEFAULT_DATE_FORMAT)
      ? formValues.lastDayToSubmitClaim
      : undefined,
  );

  const modalDescription = useMemo(() => {
    return `Please note that you can edit or cancel termination configurations until ${terminationDate || formValues.terminationDate}.`;
  }, [formValues.terminationDate, terminationDate]);
  
  
  const changeValues = useCallback((
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    fields: Field[],
    setFieldsAreFilled?: (val: boolean) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((prev: any) => ({ ...prev, ...values }));
    try {
      const validatorMap = fields.reduce((map, field) => ({
        ...map,
        [field.name]: field.validator,
      }), {});
      const schema = yup.object()
        .shape(validatorMap);
      schema.validateSync(values, { abortEarly: false });
      if (setFieldsAreFilled) setFieldsAreFilled(true);
    } catch {
      if (setFieldsAreFilled) setFieldsAreFilled(false);
    }
  }, [setFormValues]);

  const handleConfirmFirstStep = useCallback(() => {
    handleSetTerminationDate(formValues.terminationDate);
    handleSetStep(TerminationSteps.SET_TERMINATION_PARAMS);
  }, [formValues.terminationDate, handleSetStep, handleSetTerminationDate]);
  
  const handleSubmit = useCallback(async () => {
    setSubmit(true);

    if (!dateIsFilled) {
      return;
    }

    if (step === TerminationSteps.SET_TERMINATION_DATE && data.length !== 0) {
      handleConfirmFirstStep();
      setSubmit(false);
      return;
    }

    if (!paramsIsFilled
        && formValues.cancelationRules !== CancelationRules.NO_CHANGES
        && step === TerminationSteps.SET_TERMINATION_PARAMS) {
      return;
    }

    if (isHsaAccountExist && !hsaIsFilled && step === TerminationSteps.SET_TERMINATION_PARAMS) {
      return;
    }

    try {
      handleSetTerminationDate(formValues.terminationDate);
      const payload = {
        termination_date: getDateIsoString(formValues.terminationDate) as string,
        bulk_transfer: formValues.hsaTransferOfAssets === CancelationHsaRules.BULK_TRANSFER,
        ...formValues.cancelationRules === CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS
          ? { coverage_end_date: getDateIsoString(formValues.endOfCoverage) as string } : {},
        ...formValues.cancelationRules === CancelationRules.CHANGE_END_OF_COVERAGE_AND_LAST_DAY_TO_SUBMIT_CLAIMS
          ? { claim_last_date: getDateIsoString(formValues.lastDayToSubmitClaim) as string } : {},
      };
      await terminateEmployer(payload);
      handleChangeVisibility(false);
      handleChangeSuccessVisibility(true);
    } catch {
      setIsServerError(true);
    }
  }, [
    dateIsFilled,
    formValues,
    handleConfirmFirstStep,
    paramsIsFilled,
    step,
    isHsaAccountExist,
    hsaIsFilled,
    handleChangeSuccessVisibility,
    handleChangeVisibility,
    terminateEmployer,
    data,
    handleSetTerminationDate,
  ]);
  
  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);

  const handleCancelTermination = useCallback(() => {
    handleChangeVisibility(false);
    handleChangeCancelVisibility(true);
    handleSetStep(TerminationSteps.SET_TERMINATION_DATE);

  }, [handleChangeVisibility, handleChangeCancelVisibility, handleSetStep]);

  const currentButtonTitle = useMemo(() => {
    if (data.length === 0) {
      return 'Confirm';
    }
    if (step === TerminationSteps.SET_TERMINATION_DATE) {
      return 'Next (1/2)';
    }
    return 'Confirm (2/2)';
  }, [step, data]);

  useEffect(() => {
    if (!planListLoading) {
      setInitialLoading(false);
    }
  }, [planListLoading]);

  useEffect(() => {
    if (hsaStartDate && !permanentLastDate) {
      setPermanentLastDate(hsaStartDate);
    }
  }, [hsaStartDate, permanentLastDate]);
  
  return (
    <>
      {isThereTerminationDate ? (
        <Box margin={{ bottom: 'spacing24' }}>
          <Inscription textAlign="center" size="14px">
            {modalDescription}
          </Inscription>
        </Box>
      ) : null}

      <Box data-testid="EmployerTerminationModalForm_container">
        <Box direction="column">
          <Box
            round="container1Round"
            pad="spacing24"
            background="module"
          >
            {terminationDataLoading || initialLoading ? (
              <Preloader />
            ) : (
              <Box gap='spacing24'>
                {step === TerminationSteps.SET_TERMINATION_DATE ? (
                  <EmployerTerminationDateForm
                    handleChangeValues={changeValues}
                    isSubmit={isSubmit}
                    setSetup={setDateIsFilled}
                    terminationDate={terminationDate || terminationData?.terminationDate}
                    minDate={permanentLastDate}
                  />
                ) : null}
                {planListLoading ? (
                  <Preloader />
                ) : null}
                {isThereTerminationDate && !planListLoading && step === TerminationSteps.SET_TERMINATION_DATE
                  ? (
                    <EmployerTerminationPlanList
                      planList={data}
                    />
                  ) : null}
                {step === TerminationSteps.SET_TERMINATION_PARAMS ? (
                  <EmployerTerminationPlansCoverageDatesList
                    planList={coverageData}
                  />
                ) : null}
                {step === TerminationSteps.SET_TERMINATION_PARAMS ? (
                  <EmployerTerminationParamsForm
                    handleChangeValues={changeValues}
                    isSubmit={isSubmit}
                    setSetup={setParamsIsFilled}
                    cancelationRules={formValues.cancelationRules}
                    terminationDate={terminationDate}
                    defaultEndOfCoverage={terminationData?.coverageEndDate}
                    defaultLastDayToSubmitClaim={terminationData?.claimLastDate}
                  />
                ) : null}

                {step === TerminationSteps.SET_TERMINATION_PARAMS && isHsaAccountExist ? (
                  <EmployerTerminationHsaTransferForm
                    handleChangeValues={changeValues}
                    isSubmit={isSubmit}
                    setSetup={setHsaIsFilled}
                    defaultHsaTransferOfAssets={terminationData?.bulkTransfer}
                  />
                ) : null}
              </Box>
            )}
          </Box>
          <Box
            direction="row"
            fill="horizontal"
            margin={{ top: 'spacing24' }}
            align="end"
            justify={editMode ? 'between' : 'end'}
            width="medium"
            gap="xs"
          >
            {editMode ? (
              <Box>
                <AppButton
                  testId="EmployerTerminationModalForm_cancel_termination"
                  buttonType="secondary"
                  width="control"
                  type="button"
                  onClick={handleCancelTermination}
                  color="red"
                >
                  Cancel Termination
                </AppButton>
              </Box>
            ) : null}
            <Box direction="row" gap="xs">
              <AppButton
                testId="EmployerTerminationModalForm_cancel"
                buttonType="secondary"
                width="control"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </AppButton>
              <AppButton
                testId="EmployerTerminationModalForm_submit"
                type="button"
                disabled={planListLoading || terminationLoading}
                onClick={handleSubmit}
                width="control"
              >
                {planListLoading || terminationLoading || terminationDataLoading
                  ? (<Preloader />)
                  : currentButtonTitle}
              </AppButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <WarnModal
        testId="employer-termination-server-error-modal"
        visible={isServerError}
        onSetVisible={setIsServerError}
        header="The server encountered an error processing the request"
        helptext={`It doesn't appear to have affected your data, but we cannot save the Employer termination with the latest records.
        Our technical staff have been automatically notified and will be looking into this with the utmost urgency.`}
        buttonText="Close"
        onSubmit={handleSubmit}
        submitButtonText="Try again"
      />
    </>
  );
};
  
export default EmployerTerminationModalForm;
  