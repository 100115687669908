import React, { useMemo } from 'react';
import { Box, Inscription, RoundCloseIcon } from '@common-fe/common-fe';

import { FileErrorTypes } from '@/modules/FileManager/FileSummary/FileReportPopup/types/fileReport.constants';
import theme from '@/styles/theme';

interface Props {
  errorType?: FileErrorTypes;
}

const FileErrorSnack: React.FC<Props> = ({ errorType }) => {
  const errorLabels = useMemo(() => {
    switch (errorType) {
    case FileErrorTypes.storeError: {
      return {
        title: 'There was a problem uploading your file',
        subTitle:
            'Please double check your formatting and resubmit a corrected file. If the issue continues, please reach out to your Admin contact.',
      };
    }
    case FileErrorTypes.validationError: {
      return {
        title: 'Your file has invalid data',
        subTitle: 'Please review formatting and data elements and resubmit a corrected file.',
      };
    }
    case FileErrorTypes.parsingError: {
      return {
        title: 'Your file could not be parsed',
        subTitle: 'Please make sure all records match our comma separated specification.',
      };
    }
    case FileErrorTypes.qualityGatesError: {
      return {
        title: 'More than 25% of your records have errored',
        subTitle: 'Please review and resubmit a corrected file.',
      };
    }
    case FileErrorTypes.encodingError: {
      return {
        title: 'Your file encoding is invalid',
        subTitle: 'Files must have UTF-8 encoding. Please review your file format and resubmit a corrected file.',
      };
    }
    case FileErrorTypes.error: {
      return {
        title: 'Oops! There was an unexpected error',
        subTitle:
            'Please submit your file again. If the issue continues, please reach out to your Admin contact.',
      };
    }
    default: {
      return null;
    }
    }
  }, [errorType]);

  if (!errorLabels) return null;

  return (
    <Box
      data-testid="file-error-snack"
      height={{ min: '40px' }}
      background="dangerContainer"
      round="4px"
      border={{ color: theme.colors.dangerBorder }}
      justify="between"
      align="center"
      direction="row"
      pad={{ vertical: '18px', horizontal: '26px' }}
    >
      <Box>
        <Box align="center" direction="row">
          <RoundCloseIcon size="20px" color={theme.colors.danger} />
          <Inscription weight="bold" margin={{ left: '10px' }}>
            {errorLabels.title}
          </Inscription>
        </Box>
        <Box margin={{ top: '10px' }}>
          <Inscription lineHeight="20px">{errorLabels.subTitle}</Inscription>
        </Box>
      </Box>
    </Box>
  );
};

export default FileErrorSnack;
