import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import {
  QuestionnaireFormattedPayload, ResponsePayload,
} from '../cip.constants';

const QUERY_KEY = 'GET_CIP_IDENTIFICATION';

export const getFormattedQuestionnaireData = (data: ResponsePayload) => {
  const id = _.get(data, 'id_number', '') as number;
  const idScan = _.get(data, 'id_scan', '') as string;
  const idScanUrl = _.get(data, 'id_scan_url', '') as string;
  // const questions = _.get(data, 'response.questions.question', []) as QuestionPayloadOption[];
  // const formattedOptions: QuestionnaireField[] = questions.map(
  //   (item) => getFormattedQuestion(item),
  // );
  return {
    id,
    idScan,
    idScanUrl,
    // questions: formattedOptions,
  } as QuestionnaireFormattedPayload;
};

interface Params {
  enabled?: boolean;
  employeeId?: string;
  onError?: () => void;
}
const ERROR_RESPONSE = 'error';
let cachedEmployeeId : undefined | string = undefined;
export default ({ enabled, employeeId, onError }: Params) => {
  const {
    data, isLoading, isSuccess, refetch,
  } = useQuery([QUERY_KEY, employeeId],
    () => api.post<ResponsePayload>(PATHS.CIP_IDENTIFICATION, null, {
      params: {
        employee_id: employeeId,
      },
    }), {
      enabled: enabled && !!employeeId && (cachedEmployeeId !== employeeId),
      onError: () => {
        if (onError) {
          onError();
        }
      },
    });

  const handleRefetch = useCallback(async () => {
    
    const response = await refetch();
    if (response?.status === ERROR_RESPONSE) {
      throw new Error();
    }
    cachedEmployeeId = employeeId; 
    return getFormattedQuestionnaireData(_.get(response, 'data.data', {} as ResponsePayload));
  }, [employeeId, refetch]);
  const formattedData = useMemo(
    () => getFormattedQuestionnaireData(_.get(data, 'data') as ResponsePayload),
    [data],
  );
  return {
    handleRefetch,
    isSuccess,
    isLoading,
    data: formattedData,
  };
};
