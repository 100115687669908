
import { useCallback } from 'react';
import _ from 'lodash';

import useEnvSpecificAvailable, {Params} from '@/utils/hooks/useEnvSpecificAvailable';

import useCurrentOrganization from '../useCurrentOrganization';

const useOrganizationFeatureFlag = (params: Params, excludeIds?: number[]) => {
  const { defaultOrganizationData: { id, }, observingOrganization: {id: observingId} } = useCurrentOrganization();
  
  const isOnlyForDevAndUAT = useEnvSpecificAvailable(params);

  const handleCheckId = useCallback((currentId?: string) => {
    return excludeIds && currentId && excludeIds.includes(_.toNumber(currentId));
  },[excludeIds]);
  return handleCheckId(id) || handleCheckId(observingId)  ? true : isOnlyForDevAndUAT;
};

export default useOrganizationFeatureFlag;