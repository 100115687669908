import React, { useEffect,useState } from 'react';
import {
  Box,
  FlexControlledForm,
  Inscription,
  useDebounce,
} from '@common-fe/common-fe';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

import useLinkManagementFields from './useLinkManagementFields';
import useLinkManagementStore from './useLinkManagementStore.store';
import { LinkManagementPayload } from './useLinkManagementStore.store';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

const LinkManagement: React.FC<Props> = ({ onDirty }) => {
  const { viewMode } = useSetupEmployerMode();
  const [currentValues, setCurrentValues] = useState<object>({});
  const setLinkManagementInfo = useLinkManagementStore((state) => state.setState);
  const debouncedValue: LinkManagementPayload = useDebounce(currentValues);
  const { isLoading, formattedData } = useGetEmployer();
  const { fields } = useLinkManagementFields();

  useEffect(() => {
    if (debouncedValue) {
      setLinkManagementInfo(debouncedValue);
    }
  }, [debouncedValue, setLinkManagementInfo]);

  if (isLoading) return <FormLoader testId="link_management" />;

  return (
    <Box
      direction="column"
      round="container1Round"
      background="canvas"
      id="link_management_section"
      margin={{ top: 'spacing24' }}
      border={{ color: 'border2', size: 'small' }}
    >
      <FlexControlledForm
        fields={fields}
        editMode={!viewMode && !formattedData?.isTerminated}
        formTitle="Link Management"
        subTitle={(
          <Box margin={{ top: 'spacing8' }}>
            <Inscription lineHeight="20px">
              Selected links will be applied to all email templates
            </Inscription>
          </Box>
        )}
        onChangeValues={setCurrentValues}
        onDirty={onDirty}
        isThinMode
        showError
        wrapperStyle={{ border: 'none' }}
      />
    </Box>
  );
};

export default LinkManagement;
