import {
  useMemo,
} from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import { toString } from 'lodash';

import { VALIDATORS } from '@/common';
import { Option } from '@/common/types';
import { useFieldsWithDefaultValues } from '@/utils';

import { PERCENTAGE_KEY } from './useServicePaymentFields';

interface Params {
  defaultValues?: Partial<PaymentRuleField>;
  paymentType?: string;
  paymentAccounts?: Option[],
  accountArray?: string[],
}

export interface PaymentRuleField {
  id: string;
  account: string;
  amount: string;
}

export const ACCOUNT_TYPE_KEY = 'account';
export const AMOUNT_TYPE_KEY = 'amount';

export default (
  {
    defaultValues,
    paymentType,
    paymentAccounts,
    accountArray,
  }: Params,
) => {
  const filteredArray = useMemo(() => paymentAccounts
    ?.filter((item) => !accountArray?.includes(toString(item?.key))
    || item.key === defaultValues?.account),
  [paymentAccounts, accountArray, defaultValues]);

  const isPercentageType = useMemo(() => paymentType
    && (paymentType === PERCENTAGE_KEY), [paymentType]);
  const fields = useMemo<Field[]>(() => {
    const currentFields: Field<string>[] = [{
      name: ACCOUNT_TYPE_KEY,
      type: FieldTypes.Dropdown,
      singleMode: true,
      options: filteredArray,
      label: 'Account to pay',
      placeholder: 'Select account to pay from',
      validator: VALIDATORS.REQUIRED_STRING,
      showRequireIcon: true,
      emptyListMessage: 'Oops, you have already added payment rules for all accounts available to pay.',
    },
    {
      name: AMOUNT_TYPE_KEY,
      type: isPercentageType ? FieldTypes.Percent : FieldTypes.Currency,
      label: isPercentageType ? 'Percentage to pay' : 'Amount to pay',
      placeholder: isPercentageType ? 'Enter percentage to pay from' : 'Enter amount to pay from',
      validator: VALIDATORS.REQUIRED_STRING,
      showRequireIcon: true,
    }];
    const formatedFields = useFieldsWithDefaultValues(currentFields, defaultValues);
    return formatedFields;
  }, [isPercentageType, filteredArray, defaultValues]);
  return fields;
};
