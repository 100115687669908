import { datadogRum } from '@datadog/browser-rum';

import { OverridablePropertiesPermissions } from '@/common/constants';
import { UserModel } from '@/modules/user/stores/auth.types';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  organizationId: string;
  contactId: string;
  auth: boolean;
  inited: boolean;
  user: UserModel | null;
  overridablePropertiesPermissions: OverridablePropertiesPermissions | null;
  observedOrganizationPath?: string;
  setAuth: (value: boolean) => void;
  setUser: (user: UserModel | null) => void;
  setOverridablePropertiesPermissions: (
    overridablePropertiesPermissions: OverridablePropertiesPermissions | null
  ) => void;
  setIds: (organizationId?: string, contactId?: string) => void;
  setObservedOrganizationPath: (observedOrganizationPath?: string) => void;
}

export const useAuthStore = storeCreator<State>((set) => ({
  organizationId: '',
  contactId: '',
  auth: false,
  inited: false,
  user: null,
  observedOrganizationPath: undefined,
  overridablePropertiesPermissions: null,
  setAuth: (value: boolean) =>
    set(() => ({
      auth: value,
      inited: true,
    })),
  setObservedOrganizationPath: (observedOrganizationPath?: string) =>
    set(() => ({
      observedOrganizationPath,
    })),
  setUser: (user: State['user']) =>
    set(() => {
      if(user) {
        datadogRum.setUser({
          id: user.id
        });
      }
      return  ({
        user,
      });
    }),
  setOverridablePropertiesPermissions: (
    overridablePropertiesPermissions: OverridablePropertiesPermissions | null
  ) =>
    set(() => ({
      overridablePropertiesPermissions,
    })),
  setIds: (organizationId: string = '', contactId: string = '') =>
    set(() => ({
      organizationId,
      contactId,
    })),
}));

export default useAuthStore;
