import React from 'react';
import { Box, BoxProps, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

export const StyledContent = styled(Box)`
  display: block;
`;

export const Wrapper = styled(Box)`
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex: 1;
  }
  width: 700px;


`;

export const TextInputWrap: React.FC<{ children?: React.ReactNode, label: React.ReactNode, align?: BoxProps['align'], margin?: BoxProps['margin'], viewMode?: boolean }> = (props) => (
  <Box direction="row" align={props.align || 'start'} margin={props.margin}>
    <Box width="medium" pad={props.viewMode ? {} : { top: '10px' }}>
      {props.label}
    </Box>
    <Box width="medium">
      {props.children}
    </Box>
  </Box>
);

export const Label: React.FC<{ viewMode?: boolean, children?: React.ReactNode }> = ({
  viewMode,
  children,
}) => (
  <Text weight={viewMode ? 'normal' : 'bold'}>{children}</Text>
);
