import { PlanFieldState } from '@/modules/plan/plan.types';
import { baseStoreCreator } from '@/utils/store';

export interface ContributionFieldsStatePayload {
  allowContributors: PlanFieldState;
  allowContributionOverMaximum: PlanFieldState;
  pendContribution: PlanFieldState;
  allowIndividualContributions: PlanFieldState;
  сontributionMonitoringRequired: PlanFieldState;
  minimumContributionAmount: PlanFieldState;
}

export const DEFAULT_VALUES: ContributionFieldsStatePayload = {
  allowContributors: PlanFieldState.Modifiable,
  allowContributionOverMaximum: PlanFieldState.Modifiable,
  pendContribution: PlanFieldState.Modifiable,
  allowIndividualContributions: PlanFieldState.Modifiable,
  сontributionMonitoringRequired: PlanFieldState.Modifiable,
  minimumContributionAmount: PlanFieldState.Modifiable,
};

export const useStore = baseStoreCreator<ContributionFieldsStatePayload>(DEFAULT_VALUES);

export default useStore;
