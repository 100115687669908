import React, { useCallback } from 'react';
import { Box } from '@common-fe/common-fe';

import AppButton from '@/components/controls/AppButton';
import { SnackbarState } from '@/modules/core/core.types';
import { useSnackbar } from '@/modules/core/hooks';
import { useSetDefaultPaymentMethodQuery } from '@/modules/ReimburseMe/queries/useAddPaymentMethod.query';
import spacings from '@/styles/spacings';

import { usePaymentMethodsStore } from '../usePaymentMethods.store';

interface Props {
  onClose: () => void;
}

const Footer: React.FC<Props> = ({ onClose }) => {
  const { handleAddPermanentSnackbar } = useSnackbar();
  const showSnackbar = useCallback(() => handleAddPermanentSnackbar({
    text: 'Default payment method was changed.',
    closeIcon: true,
    state: SnackbarState.positive,
  }), [handleAddPermanentSnackbar]);
  const selectedMethod = usePaymentMethodsStore((state) => state.selectedMethod);
  const handleSetActivePaymentMethod = usePaymentMethodsStore((state) => state.handleSetActivePaymentMethod);
  const { updateDefaultPaymentMethod } = useSetDefaultPaymentMethodQuery(showSnackbar);

  const handleSavePaymentMethod = useCallback(() => {
    handleSetActivePaymentMethod();
    if (selectedMethod) {
      updateDefaultPaymentMethod(Number(selectedMethod.id));
    }
    onClose();
  }, [
    handleSetActivePaymentMethod,
    onClose,
    selectedMethod,
    updateDefaultPaymentMethod,
  ]);

  return (
    <Box direction="row" justify="end" margin={{ top: spacings.l }}>
      <AppButton width="130px" onClick={handleSavePaymentMethod}>
        Save
      </AppButton>
    </Box>
  );
};

export default Footer;
