import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { toNumber, toString } from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';

import useRecipientsStore from '../stores/useRecipients.store';
import { EmployeePlanCodeCoverages,Recipient } from '../types';

interface PlanCodePayload {
  employee_id?: number;
  plan_code?: string;
}
export const getPlanCodesPayload = (recipients?: Recipient[]) => {
  if (!recipients?.length) return [] as PlanCodePayload[];
  const planCodes: PlanCodePayload[] = [];

  recipients?.forEach((recipient) => {
    if (!recipient?.employeeId) return;
    recipient?.contributionAccounts?.forEach((acc) => {
      if (!acc?.contributionDateFrom && !acc?.contributionDateTo && acc?.planCode) {
        planCodes.push({
          employee_id: toNumber(recipient.employeeId),
          plan_code: acc.planCode,
        });
      }
    });
  });

  return planCodes;
};

interface ContributionPeriodPayload {
  coverage_date_start?: string;
  coverage_date_end?: string;
}
interface ContributionPeriodsPayload {
  [employeeIdAndPlanCode: string]: ContributionPeriodPayload[];
}

export const parseEmployeesPlanCodes = (planCodes: ContributionPeriodsPayload = {}) => {
  const employeesPlanCodes: EmployeePlanCodeCoverages = {};

  Object.keys(planCodes).forEach((key) => {
    const [employeeId, planCode] = key.split(',');
    if (!employeeId || !planCode) return;

    const contributionDateTo = planCodes[key]?.[0]?.coverage_date_end || '';
    const contributionDateFrom = planCodes[key]?.[0]?.coverage_date_start || '';

    if (!contributionDateTo && !contributionDateFrom) return;

    employeesPlanCodes[employeeId] = {
      ...employeesPlanCodes[employeeId],
      [planCode]: { contributionDateFrom, contributionDateTo },
    };
  });

  return employeesPlanCodes;
};

interface Props {
  recipients?: Recipient[];
}

const useGetCoveragePeriodsQuery = (props: Props) => {
  const { setCoverageDates } = useRecipientsStore();
  const { recipients } = props;
  const planCodes = useMemo(() => getPlanCodesPayload(recipients), [recipients]);
  const { isLoading } = useQuery({
    queryKey: [recipients?.length],
    queryFn: () => api.post<ContributionPeriodsPayload[]>(
      PATHS.GET_CONTRIBUTIONS_COVERAGE_PERIODS,
      planCodes,
    ),
    enabled: Boolean(recipients?.length && planCodes?.length),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      const employeesPlanCodes = parseEmployeesPlanCodes(data?.data as ContributionPeriodsPayload);
      if (Object.keys(employeesPlanCodes)?.length) {
        setCoverageDates(employeesPlanCodes);
      }
    },
  });

  return {
    areCoveragePeriodaLoading: isLoading,
  };
};

export default useGetCoveragePeriodsQuery;
