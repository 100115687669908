import React from 'react';
import { AppButton, Box, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import ModalWrapper from '@/modules/employer/components/SetupEmployer/ModalWrapper';

interface AnimatedTextProps {
  showed?: boolean;
}
const AnimatedText = styled(Text)<AnimatedTextProps>`
  opacity: ${({ showed }) => (showed ? 1 : 0)};
  transition: all 0.25s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.58, 1);
  margin-left: ${({ theme }) => theme.spacings.spacing24};
`;

interface Props {
  visible: boolean;
  onSetVisible: (value: boolean) => void;
  modalTitle?: string;
  children: React.ReactNode;
  buttonText: string;
  required?: boolean;
  disabled?: boolean;
  successTextShow?: boolean;
  successText?: string;
  testId?: string;
}

const EmployerSetupModalWrapper: React.FC<Props> = ({
  modalTitle,
  successText,
  children,
  buttonText,
  testId = '',
  required,
  onSetVisible,
  visible,
  successTextShow,
  disabled,
}) => (
  <Box direction="row" align="center">
    <ModalWrapper
      testId={testId}
      visible={visible}
      title={modalTitle}
      onSetVisible={onSetVisible}
    >
      {children}
    </ModalWrapper>
    <AppButton
      disabled={disabled}
      testId={testId}
      onClick={() => onSetVisible(true)}
    >
      {buttonText}
    </AppButton>
    {successText && (
      <AnimatedText showed={successTextShow} size="medium" color="success">
        {successText}
      </AnimatedText>
    )}

    {required && (
      <Box direction="row" justify="end" style={{ flex: 1 }}>
        <Text size="small" weight={500} color="danger">
          Required
        </Text>
      </Box>
    )}
  </Box>
);

export default EmployerSetupModalWrapper;
