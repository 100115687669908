import React from 'react';
import { AppButton,Box, Text } from '@common-fe/common-fe';

interface Props {
  addDependent?: () => void;
}

export const DependentsHeader: React.FC<Props> = ({ addDependent }) => (
  <Box margin={{ bottom: 'spacing12' }} direction="row" justify="between" align="center" data-testid="DependentsHeader-wrapper">
    <Text size="21px" weight="bold" data-testid="DependentsHeader-title">Dependents</Text>
    {addDependent && (
      <Box direction="row" justify="end" align="center">
        <AppButton
          testId="DependentsHeader-add_dependent"
          onClick={addDependent}
          width="150px"
        >
          Add Dependent
        </AppButton>
      </Box>
    )}
  </Box>
);
