import React, { useRef,useState } from 'react';
import { Box,Text } from '@common-fe/common-fe';

import { ExpandedItemLevel, ExpandedThirdLevelObject, Triggertem } from '../ProcessingDefinition.types';

import ExpandButton from './ExpandButton';
import ExpandedSubItem from './ExpandedItemThirdLevel';
import TreeIndicator, { TreeIndicatorMode } from './TreeIndicator';

interface Props extends ExpandedItemLevel {
  items: ExpandedThirdLevelObject[];
  testId?: string;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  triggers?: Triggertem[];
}
const ExpandedItemSecondLevel: React.FC<Props> = ({
  isLastItem, title, subTitle, items, isFirstItem, testId,
}) => {
  const [expanded, setExpanded] = useState(true);
  const checkboxRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      direction="column"
      pad="m"
      data-testId={testId}
      background="module"
      round="container2Round"
      margin={{ bottom: 'm' }}
    >
      <Box direction="row" align="center">
        <Box ref={checkboxRef} style={{ position: 'relative' }}>
          {isFirstItem && (
            <TreeIndicator />
          )}
          {!isLastItem && !expanded && (
            <TreeIndicator mode={TreeIndicatorMode.Down} />
          )}

          <ExpandButton testId={testId} onChange={setExpanded} value={expanded} />
        </Box>
        <Box
          direction="column"
          fill
          margin={{ left: 'xs' }}
        >
          <Text
            size="small"
            color="textSecondary"
          >
            {subTitle}
          </Text>

          <Text
            size="medium"
            color="textBody"
            weight={700}
            margin={{
              top: 'xxxs',
            }}
          >
            {title}
          </Text>

        </Box>

      </Box>
      {expanded && (
        <Box
          direction="column"
          margin={{ left: 'xl' }}
          pad={{ top: 'm' }}
          data-testid={`${testId}_content`}
          background="module"
          round="container2Round"
        >
          {items.map((item, index) => (
            <ExpandedSubItem
              checkboxRef={checkboxRef}
              triggers={item.triggers}
              testId={`${testId}_${item.id}`}
              isLastSubItem={items.length - 1 === index}
              isArrowDownDisplayed={items.length - 1 === index && !isLastItem}
              key={item.id}
              items={item.items}
              subSubTitle={item.subSubTitle}
              blockTitle={item.blockTitle}
              title={item.title}
              subTitle={item.subTitle}
              header={item.header}
              values={item.values}
            />
          ))}

        </Box>
      )}

    </Box>

  );
};
export default ExpandedItemSecondLevel;
