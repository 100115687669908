import { useMutation, useQueryClient } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { CopayGroupPayload } from '@/modules/HealthPlan/CopayAdjudication/CopayAdjudication.constant';
// import { useCurrentOrganization } from '@/modules/core/hooks';
import { QUERY_KEY } from '@/modules/HealthPlan/CopayAdjudication/queries/useCopayGroups.query';

export default () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    (value: CopayGroupPayload) => api.delete(
      PATHS.COPAY_GROUPS_BY_ID(value.id),
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    },
  );
  return {
    isLoading,
    handleDelete: mutateAsync,
  };
};
