import React, { useMemo } from 'react';

import { Changeset } from '@/modules/ActivityLog/LogList/LogItem/Changeset/common';
import { ActivityLog } from '@/modules/ActivityLog/LogList/useGetActivityLogs.query';

import Log from './Log';
import LogChange from './LogChange';

interface LogWithChangesets {
  log?: ActivityLog;
  changesets?: Changeset[];
}

interface Props {
  logs?: ActivityLog[];
  changesets?: Changeset[];
}

const LogChangesList: React.FC<Props> = ({ logs, changesets }) => {
  const logsWithChangesets: LogWithChangesets[] | undefined = useMemo(() => logs?.map((log) => ({
    log,
    changesets: changesets?.filter((change) => change?.changesetId === log?.id),
  })), [logs, changesets]);

  const filteredLogsWithChangesets = useMemo(() => logsWithChangesets
    ?.filter((log) => log?.changesets?.length), [logsWithChangesets]);

  return (
    <>
      {filteredLogsWithChangesets?.map((logWithChangesets) => (
        <Log key={logWithChangesets.log?.id} log={logWithChangesets?.log}>
          {logWithChangesets?.changesets?.map((changeset) => <LogChange key={changeset?.id} logChange={changeset} />)}
        </Log>
      ))}
    </>
  );
};


export default LogChangesList;
