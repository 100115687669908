import React, {
  useCallback, useMemo,
  useState, } from 'react';
import { createPortal } from 'react-dom';
import {
  AppButton, Box, FilterIcon, RawButton,
  Text,
} from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { OptionKey } from '@/common/types';
import { usePlanYearsQuery } from '@/modules/plan/PlanYearForm/queries';

import { EnrollmentFilterParams } from '../../ProcessingDefinition.types';

import ClearBlock from './ClearBlock';
import EnrollmentFilter from './EnrollmentFilter';
import FilterApply from './FilterApply';
import PlanYearFilter from './PlanYearFilter';
import SelectPlanFilter from './SelectPlanFilter';

const Wrapper = styled.div`
  position: absolute ;
  left: 0;
  right: 0;
  height: 10px ;
  top: 0;
`;
const ClearText = styled(Text)`
  line-height: 1 ;
`;
const ClearAllButton = styled(RawButton)`
  position: absolute;
  top: -24px;
  left: 50%;
  height: 24px;
  z-index: 0 ;
  background-color: ${({ theme }) => theme.colors.canvas};
  padding: ${({ theme }) => `0px ${theme.spacings.xs}`};
  border-radius: ${({ theme }) => `${theme.rounds.snackbarRound} ${theme.rounds.snackbarRound} 0px 0px`} ;
  &:hover {
    background-color: ${({ theme }) => theme.colors.danger};
    ${ClearText} {
      color: ${({ theme }) => theme.colors.textOnColor};
    }
  }  
`;

const Indicator = styled(Box)`
  position: absolute;
  right:${({ theme }) => theme.spacings.xxs};
  top:${({ theme }) => theme.spacings.xxs};
  width: ${({ theme }) => theme.spacings.spacing6};
  height: ${({ theme }) => theme.spacings.spacing6};
  border-radius: 50%;

`;

const MAX_FILLED_COUNT = 3;
interface Props {
  isEmployer?: boolean;
  onChangeValues: (value: EnrollmentFilterParams) => void;
  isDisabled?: boolean;
}
const Filter: React.FC<Props> = ({ onChangeValues, isEmployer, isDisabled }) => {
  const [visible, setVisible] = useState(false);
  const [savedParams, setSavedParams] = useState<EnrollmentFilterParams>({});
  const [planTemplate, setPlanTemplate] = useState<OptionKey>(savedParams.planTemplate || '');
  const [planYear, setPlanYear] = useState<OptionKey>(savedParams.planYear || '');
  const [enrollmentDate, setEnrollmentDate] = useState(savedParams.enrollmentDate || '');
  // const wrapperRef = useRef();
  const { list: planYearsOptions } = usePlanYearsQuery();

  const searchParams = useMemo<EnrollmentFilterParams>(() => ({
    planTemplate,
    planYear,
    enrollmentDate,
  }), [enrollmentDate, planTemplate, planYear]);

  const isEmpty = useMemo(() => _.isEmpty(_.pickBy(searchParams)), [searchParams]);
  const handleApply = useCallback(() => {
    setSavedParams(searchParams);
    onChangeValues(searchParams);
    setVisible(false);
  }, [onChangeValues, searchParams]);
  const isAppliedFilter = useMemo(() => !_.isEmpty(savedParams), [savedParams]);
  const filledParams = useMemo(() => {
    const isEmployerApplied = isEmployer && planYearsOptions.length;
    const maxCount = isEmployerApplied ? MAX_FILLED_COUNT : MAX_FILLED_COUNT - 1;
    let count = 0;
    if (planTemplate) {
      count += 1;
    }
    if (isEmployerApplied && planYear) {
      count += 1;
    }
    if (enrollmentDate) {
      count += 1;
    }
    return {
      count,
      maxCount,
    };
  }, [enrollmentDate, isEmployer, planTemplate, planYear, planYearsOptions.length]);
  const handleClear = useCallback(() => {
    setSavedParams({});
    setVisible(false);
    onChangeValues({});
    setPlanTemplate('');
    setPlanYear('');
    setEnrollmentDate('');
  }, [onChangeValues]);
  const handleHide = useCallback(() => {
    setVisible(false);
    setPlanTemplate(savedParams.planTemplate || '');
    setPlanYear(savedParams.planYear || '');
    setEnrollmentDate(savedParams.enrollmentDate || '');
  }, [savedParams.enrollmentDate, savedParams.planTemplate, savedParams.planYear]);
  const handleToggle = useCallback(() => {
    if (visible) {
      handleHide();
    } else {
      setVisible(true);
    }
  }, [handleHide, visible]);
  const portalRoot = document.getElementById('topbar-nest');
  return (
    <>
      <Box
        style={{ position: 'relative' }}
      >
        <AppButton
          onlyIcon
          color="grey"
          testId="enrollment-filter"
          onClick={handleToggle}
          active={visible}
          style={{
            width: '40px',
          }}
          buttonType="secondary"
          disabled={isDisabled}
        >
          {isAppliedFilter && (
            <Indicator background="danger" />
          )}
          <FilterIcon />
        </AppButton>
      </Box>
      {visible && portalRoot && (
        createPortal(
          <Wrapper
            data-testid="enrollment-filter"
          >

            <Box
              pad={{ vertical: 's', horizontal: 'm' }}
              round="container1Round"
              elevation="active"
              direction="row"
              wrap
              background="canvas"
              border={{ size: 'small', color: 'border2' }}
              style={{
                position: 'relative',
              }}
            >
              {!isEmpty && (
                <ClearAllButton
                  data-testid="clear-all_button"
                  onClick={handleClear}
                >

                  <ClearText weight="normal" size="medium">
                    Clear All
                  </ClearText>

                </ClearAllButton>
              )}

              <SelectPlanFilter
                value={planTemplate}
                onChange={setPlanTemplate}
              />
              {isEmployer && (
                <PlanYearFilter
                  value={planYear}
                  onChange={setPlanYear}
                />
              )}

              <EnrollmentFilter value={enrollmentDate} onChange={setEnrollmentDate} />
              <FilterApply
                onApply={handleApply}
                count={filledParams.count}
                maxCount={filledParams.maxCount}
              />
              <ClearBlock onClear={handleHide} />
            </Box>

          </Wrapper>,
          portalRoot,
        )

      )}

    </>
  );
};

export default Filter;
