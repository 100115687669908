import { ClaimAutopayType,DefaultClaimPayeeType } from '@/modules/employee/employee.types';
import { baseStoreCreator } from '@/utils/store';

export interface EmployeeClaimAutopayPayload {
  defaultClaimPayeeType?: DefaultClaimPayeeType;
  claimAutopayType?: ClaimAutopayType;
}

const DEFAULT_STATE = {
  defaultClaimPayeeType: undefined,
  claimAutopayType: undefined,
};

const useEmployeeClaimAutopayStore = baseStoreCreator<EmployeeClaimAutopayPayload>(DEFAULT_STATE);

export default useEmployeeClaimAutopayStore;
