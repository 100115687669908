import React, { useMemo } from 'react';
import { Box, Hint, InfoOutlineIcon, Inscription, ListItem, ListItemBase, ListItemType } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import ManyItemsTooltip from '@/components/elements/ManyItemsTooltip/ManyItemsTooltip';
import theme from '@/styles/theme';

import { PayrollCalendarFrequency } from '../payrollCalendar.types';
import { useGetPayrollCalendarsQuery } from '../queries/useGetPayrollCalendars.query';

const StyledInfoIcon = styled(InfoOutlineIcon)`
  &:hover {
    fill: ${({ theme }) => theme.colors.iconAccent};
  }
`;

const groupFieldBuilder = (
  groupType: string,
  plans?: string[],
  payrollGroups?: string[],
) => {
  if (groupType === 'PLAN') {
    return (
      <Box direction="row" align="center" width={{ max: '265px' }}>
        <ManyItemsTooltip
          items={plans || []}
          showMoreSymbolsLength={20}
          itemsTextStyles={{
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.colors.textBody,
            fontWeight: 400,
          }}
        />
      </Box>
    );
  }
  if (groupType === 'GROUP') {
    return (
      <Box direction="row" align="center" width={{ max: '265px' }}>
        <ManyItemsTooltip
          items={payrollGroups || []}
          showMoreSymbolsLength={20}
          itemsTextStyles={{
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.colors.textBody,
            fontWeight: 400,
          }}
        />
      </Box>
    );
  }
  return (
    <Box
      direction="row"
      height="100%"
      align="center"
      gap="spacing4"
    >
      <Inscription size="14px" color="textBody" lineHeight="20px">
        Default Calendar
      </Inscription>
      <Hint
        hintAlign="vertical"
        hintHeight={78}
        hintWidth={194}
        hintElement={(
          <StyledInfoIcon
            color="iconSecondary"
            size="small"
          />
        )}
      >
        <Box
          align="center"
          justify="center"
        >
          <Inscription
            size="12px"
            weight={500}
            color="textOnColor"
            lineHeight="18px"
            textAlign="center"
          >
            All plans and payroll groups without a specific calendar assigned.
          </Inscription>
        </Box>
      </Hint>
    </Box>
  );
};

export const usePayrollCalendarTableItems = (orgId?: string) => {

  const { data, refetch } = useGetPayrollCalendarsQuery(orgId);

  const headers: ListItemBase[] = useMemo(() => [
    {
      key: 'calendarName',
      title: 'Calendar name',
      flex: 0.6,
    },
    {
      key: 'calendarFrequency',
      title: 'Frequency',
      flex: 0.6,
    },
    {
      key: 'startingDate',
      title: 'Starting date',
      flex: 0.6,
    },
    {
      key: 'group',
      title: 'Group',
      flex: 1.4,
    },
    {
      key: 'options',
      title: '',
      flex: 0.1,
    }
  ], []);

  const sortedByNameCalendars = useMemo(() => {
    return data?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }, [data]);

  const formattedList = useMemo(() => 
    sortedByNameCalendars?.map((item) => {
      return {
        id: item?.id || '',
        fields: [
          {
            key: 'calendarName',
            type: ListItemType.Text,
            title: item?.name || '-',
            style: { fontWeight: 'bold' },
            flex: 0.6,
          },
          {
            key: 'calendarFrequency',
            type: ListItemType.Text,
            title: item?.frequency_type
              ? PayrollCalendarFrequency[item.frequency_type as keyof typeof PayrollCalendarFrequency]
              : '-',
            flex: 0.6,
          },
          {
            key: 'startingDate',
            type: ListItemType.Text,
            title: item?.start_date
              ? dayjs(item.start_date).format(DEFAULT_DATE_FORMAT)
              : '-',
            flex: 0.6,
          },
          {
            key: 'calendarGroup',
            type: ListItemType.Node,
            node: groupFieldBuilder(
              item?.group_type,
              item?.plans?.map((plan) => plan.plan_code),
              item?.payroll_groups?.map((group) => group.name),
            ),
            flex: 1.4,
          },
        ],
      };
    }) || [], [sortedByNameCalendars]);

  return {
    data,
    formattedList: formattedList as ListItem[],
    headers,
    refetch,
  };
};
