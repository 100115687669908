import React, { useEffect } from 'react';
import { Box, FlexControlledForm, useDebounceState } from '@common-fe/common-fe';

import { useSubmitFormStore } from '../../stores';
import { useHealthPlanNameFields } from '../HealthPlanName/hooks';
import { HealthPlanNamePayload,useHealthPlanNameStore } from '../HealthPlanName/stores/useHealthPlanName.store';

interface Props {
  isEditMode?: boolean;
  isTemplateMode?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const Additional: React.FC<Props> = ({ isEditMode, isTemplateMode, onDirty }) => {
  const saveValidate = useSubmitFormStore((state) => state.saveValidate);
  const activateValidate = useSubmitFormStore((state) => state.activateValidate);
  const { stateFields: fields, createCarrierModals } = useHealthPlanNameFields(
    activateValidate,
    isTemplateMode,
    isEditMode,
    true,
  );

  const handleSetState = useHealthPlanNameStore((state) => state.setState);
  const showErrors = useHealthPlanNameStore((state) => state.showErrors);
  const { setValues, values } = useDebounceState<Partial<HealthPlanNamePayload>>();
  useEffect(() => {
    if (values) {
      handleSetState(values);
    }
  }, [handleSetState, values]);

  return (
    <Box background="canvas" round="container1Round">
      <FlexControlledForm
        testId="health_plan_additional"
        fields={fields}
        showError={
          showErrors
          || saveValidate
          || activateValidate
        }
        shouldControlValues
        editMode={isEditMode}
        onChangeValues={setValues}
        onDirty={onDirty}
        // isDisabledMode
        isThinMode
      />
      {createCarrierModals}
    </Box>
  );
};
export default Additional;
