import React, { useEffect, useMemo,useState } from 'react';
import {
  Box, FlexControlledForm, useDebounce,
} from '@common-fe/common-fe';

import { OrganizationTypes } from '@/common/constants';
import ToEditButton from '@/modules/employer/components/SetupEmployer/components/ToEditButton';
import { useEmployerIdFields } from '@/modules/employer/components/SetupEmployer/EmployeeId/useEmployerIdFields';
import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

import { EmployeeIdPayload } from '../SetupEmployer.types';

import useSourceIdsQuery from './queries/useSourceIds.query';

interface Props {
  onDirty?: (isDirty: boolean) => void;
}

export const EmployeeId: React.FC<Props> = ({ onDirty }) => {
  const { data: employerData, isLoading: isEmployerLoading, formattedData } = useGetEmployer();
  const setEmployeeId = useStore((state) => state.setEmployeeId);
  const { viewMode } = useSetupEmployerMode();
  const { data: sourceData, isLoading: isSourceIdsLoading } = useSourceIdsQuery();
  const [currentValues, setCurrentValues] = useState<object>({});
  const debouncedValue: EmployeeIdPayload = useDebounce(currentValues);
  const eeidItem = sourceData.find(
    (item) => item.key === employerData?.data.overridable_properties?.id_source_id,
  );
  const isDataLoading = useMemo(() => isSourceIdsLoading
    || isEmployerLoading
    || !formattedData?.overridableProperties,
  [isSourceIdsLoading, isEmployerLoading, formattedData]);

  const isEditRestricted = useMemo(() => {
    return formattedData?.isTerminated || formattedData?.organizationStatusType === EmployerStatus.TerminationInProgress;
  }, [formattedData?.isTerminated, formattedData?.organizationStatusType]);

  useEffect(() => {
    if (debouncedValue.eeid) {
      setEmployeeId(debouncedValue);
    }
  }, [debouncedValue, setEmployeeId]);
  const fields = useEmployerIdFields({
    viewMode: viewMode || formattedData?.organizationStatusType === EmployerStatus.Active
      || formattedData.isActivationStarted,
    options: sourceData,
    employerId: eeidItem?.key,
    isSubsidiary: formattedData.type === OrganizationTypes.subsidiary,
  });

  if (isDataLoading) return <FormLoader testId="EmploeeIdForm" />;

  return (
    <Box
      direction="column"
      round="container1Round"
      background="canvas"
      id="employee_ID"
      margin={{ bottom: 'spacing16' }}
    >
      <FlexControlledForm
        fields={fields}
        editMode={!viewMode && !isEditRestricted}
        formTitle="Employee ID"
        onChangeValues={setCurrentValues}
        onDirty={onDirty}
        isThinMode
      >
        {isEditRestricted ? null : <ToEditButton />}
      </FlexControlledForm>
    </Box>
  );
};
