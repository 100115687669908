import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation,useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Preloader } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';
import styled from 'styled-components';

import { CommunicationChannelsType } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import ActivityLog from '@/modules/ActivityLog';
import { useBreadcrumbs } from '@/modules/core/hooks';
import useGetOrganizationById from '@/modules/core/hooks/useGetOrganizationById';
import useHasAccess from '@/modules/core/hooks/useHasAccess';
import { useProgressScroll, useScrollStore } from '@/modules/core/hooks/useScrollSidebar';
import { SETUP_CONTAINER_UI_ID } from '@/modules/core/hooks/useScrollSidebar/useProgressScroll';
import { useMountedIdStore } from '@/modules/employee/Employee/stores';
import { useGetCardsQuery } from '@/modules/transaction/Cards/hooks/useGetCards.query';
import { useSearchParams } from '@/modules/transaction/Cards/hooks/useSearchParams';
import theme from '@/styles/theme';

import { Communications } from './Communications/Communications';
import { employeeConfigModules,MODULES, useEmployeeModules } from './EmployeeSidebar/hooks/useEmployeeModules';
import useEmployeeSubModules from './EmployeeSidebar/hooks/useEmployeeSubModules';
import { EnrollmentBalances } from './EnrollmentBalances/EnrollmentBalances.1';
import { ExpenseDetails } from './ExpenseDetails/ExpenseDetails';
import CardsBlock from './CardsBlock';
import { EmployeeCIPVerificationBanner } from './cip';
import Dependents from './Dependents';
import EmployeeSave from './EmployeeSave';
import EmployeeSidebar from './EmployeeSidebar';
import EmployeeTopbar from './EmployeeTopbar';
import { useGetEmployeeById } from './hooks';
import ModuleWrapper from './ModuleWrapper';
import PersonalInformation from './PersonalInformation';
import SpendTrackerBlock from './SpendTrackerBlock';

const FixedWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: ${() => theme.colors.canvas};
  height: max-content;
`;
const StyledWrapper = styled(Box)`
  > div:last-child {
    padding-bottom: 72px;
  }
`;

interface Props {
  isDefaultEditMode?: boolean;
}
const Employee: React.FC<Props> = () => {
  const { hash } = useLocation();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isExternalScrolled, setIsExternalScrolled] = useState(false);
  const { setMountedId, resetMountedId } = useMountedIdStore();
  const { path, params: { id: employeeId } } = useRouteMatch<{ id: string }>();
  const { query: externalScroll } = useSearchParams('scrollToModule');
  const isEditMode = useMemo(() => path === ROUTES.EMPLOYEE_EDIT_VIEW(), [path]);
  const history = useHistory();
  const goToEditEmployeePage = useCallback(
    (isEdit: boolean) => {
      if (isEdit) {
        history.push(`${ROUTES.EMPLOYEE_EDIT_VIEW(employeeId)}${history.location.search}`);
      } else {
        history.push(`${ROUTES.EMPLOYEE_VIEW(employeeId)}${history.location.search}`);
      }
    },
    [employeeId, history],
  );
  const { isLoading, data: employeeData, isEmployeeSet } = useGetEmployeeById();

  const {
    formattedData,
    isLoading: isOverridablePropertiesLoading,
  } = useGetOrganizationById(employeeData?.organizationId);
  const hasAccessToCreateClaim = useHasAccess([{
    permission: Permissions.CREATE_CLAIM,
  }]);
  const hasSubmitClaimButton = useMemo(
    () => formattedData?.overridableProperties?.isReimbursementAllowed && hasAccessToCreateClaim,
    [formattedData, hasAccessToCreateClaim],
  );
  const { hasCards } = useGetCardsQuery(employeeId);

  const isContainEmail: boolean = useMemo(
    () => !!formattedData?.overridableProperties?.communicationChannels
      ?.find((elem) => elem === CommunicationChannelsType.EMAIL_SMS),
    [formattedData],
  );
  const isContainNotificationCenter = useMemo(
    () => !!formattedData?.overridableProperties?.communicationChannels
      ?.find((elem) => elem === CommunicationChannelsType.NOTIFICATION_CENTER),
    [formattedData],
  );

  const employeeModules = useEmployeeModules(
    {
      hasCards, isContainNotificationCenter, isContainEmail,
    },
  );
  const employeeSubModules = useEmployeeSubModules();
  const { onScrollHandler, scrollToSection } = useProgressScroll({...employeeModules, ...employeeSubModules});

  const scrollToModule = useScrollStore((state) => state.scrollToModule);
  const handleReset = useScrollStore((state) => state.handleReset);

  const fullName = useMemo(() => `${employeeData?.firstName} ${employeeData?.lastName}`.trim(), [employeeData]);
  useBreadcrumbs([{
    route: ROUTES.EMPLOYEES,
    title: 'Employees',
  }, {
    route: ROUTES.EMPLOYER_SETUP,
    title: fullName,
  }], [fullName], { backBtn: true });

  const isHasEditAccess = useHasAccess([
    {
      permission: Permissions.EDIT_EE_DATA,
    },
    {
      permission: Permissions.EDIT_PROFILE,
    },

  ]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (scrollToModule || externalScroll) {
      timer = setTimeout(() => {
        scrollToSection({ module: scrollToModule || externalScroll as string });
        setIsExternalScrolled(true);
      }, 3000);
    }
    return () => {
      if (isExternalScrolled) {
        clearTimeout(timer);
        handleReset();
      }
    };
  }, [scrollToModule, scrollToSection, handleReset, isExternalScrolled, externalScroll]);

  useEffect(() => {
    setMountedId(uniqueId());
    return () => resetMountedId();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (hasCards && !isLoading && hash === `#${MODULES.CARDS}`) {
      scrollToSection({ module: MODULES.CARDS });
      timer = setTimeout(() => {
        scrollToSection({ module: MODULES.CARDS });
        window.history.replaceState(null, window.document.title, window.location.href.replace(`#${MODULES.CARDS}`, ''));
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
      handleReset();
    };
  }, [hasCards, scrollToSection, isLoading, hash, handleReset]);

  if (!isEmployeeSet || isLoading || isOverridablePropertiesLoading) {
    return <Preloader />;
  }

  return (
    <Box direction="row" width="100%">
      <EmployeeSidebar
        hasCards={hasCards}
        isContainNotificationCenter={isContainNotificationCenter}
        isContainEmail={isContainEmail}
        scrollToSection={scrollToSection}
      />
      <Box
        direction="column"
        style={{ position: 'relative' }}
        fill
        overflow="auto"
        onScroll={onScrollHandler}
        id={SETUP_CONTAINER_UI_ID}
        align="center"
      >
        <FixedWrapper width="100%" height={{ min: 'max-content' }}>
          <EmployeeTopbar
            isEditMode={isEditMode}
            hasSubmitClaimButton={hasSubmitClaimButton}
          >
            {isHasEditAccess && (
              <EmployeeSave
                isEditMode={isEditMode}
                onToggleEdit={goToEditEmployeePage}
                isSubmitted={isSubmitted}
                onToggleSubmit={setSubmitted}
              />
            )}

          </EmployeeTopbar>
        </FixedWrapper>
        <Box pad={{ horizontal: '40px' }}>

          <StyledWrapper width={theme.defaultContentWidth} data-c="ss">
            <ModuleWrapper title="Personal Information" anchorId={MODULES.PERSONAL_INFO}>
              <PersonalInformation isShowErrors={isSubmitted} isEditMode={isEditMode}>
                <EmployeeCIPVerificationBanner employeeId={employeeId} />
              </PersonalInformation>
            </ModuleWrapper>

            <ModuleWrapper anchorId={MODULES.ENROLLMENT_AND_BALANCES}>
              <EnrollmentBalances employeeName={employeeData?.fullName}>
                <SpendTrackerBlock />
              </EnrollmentBalances>
            </ModuleWrapper>
            <ModuleWrapper anchorId={MODULES.EXPENSE_DETAILS}>
              <ExpenseDetails hasSubmitClaimButton={hasSubmitClaimButton} />
            </ModuleWrapper>

            {
              hasCards && (
                <ModuleWrapper anchorId={MODULES.CARDS}>
                  <CardsBlock />
                </ModuleWrapper>
              )
            }

            <ModuleWrapper anchorId={MODULES.DEPENDENTS}>
              <Dependents />
            </ModuleWrapper>

            {
              ((isContainNotificationCenter || isContainEmail)) && (
                <ModuleWrapper title="Communications" anchorId={MODULES.COMMUNICATIONS}>
                  <Communications
                    isContainEmail={isContainEmail}
                    isContainNotificationCenter={isContainNotificationCenter}
                    communicationConfigChannels={employeeData?.communicationConfigChannels}
                    communicationChannels={formattedData?.overridableProperties?.communicationChannels}
                    phoneNumber={employeeData?.homePhoneNumber}
                    email={employeeData?.employeeEmail}
                    notificationPriority={employeeData?.notificationPriority}
                    isLoading={isLoading}
                  />
                </ModuleWrapper>
              )
            }

            <ModuleWrapper anchorId={MODULES.ACTIVITY_LOG}>
              <ActivityLog title={employeeConfigModules[MODULES.ACTIVITY_LOG]} />
            </ModuleWrapper>
          </StyledWrapper>
        </Box>
      </Box>
    </Box>
  );
};
export default Employee;
