import { useMemo } from 'react';
import { ListItem } from '@common-fe/common-fe';

import { ListItemType } from '@/common/types';
import theme from '@/styles/theme';

import useFileErrorsQuery, { FileErrorsParams } from '../queries/useFileRecordErrors.query';
import { FileRecordErrorDto } from '../types/fileReport.constants';

export const fieldStyle = {
  minWidth: 120,
  maxWidth: 120,
  flex: 'none',
};

const getFieldTitle = (title?: string | number) => title || '-';

const useFileErrorsList = (params: FileErrorsParams) => {
  const { formattedData: fileErrors, total, isLoading } = useFileErrorsQuery(params);

  const formattedList = useMemo(() => fileErrors
    .map((item: FileRecordErrorDto) => ({
      id: item.id,
      fields: [
        {
          key: 'errorLineNumber',
          type: ListItemType.Text,
          title: item?.lineNumber,
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorFieldName',
          type: ListItemType.Text,
          title: getFieldTitle(item?.fieldName),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorPartnerId',
          type: ListItemType.Text,
          title: getFieldTitle(item?.partnerId),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorEmployerId',
          type: ListItemType.Text,
          title: getFieldTitle(item?.employerId),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorLastName',
          type: ListItemType.Text,
          title: getFieldTitle(item?.lastName),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorFirstName',
          type: ListItemType.Text,
          title: getFieldTitle(item?.firstName),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorEmployeeId',
          type: ListItemType.Text,
          title: getFieldTitle(item?.employeeId),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorCode',
          type: ListItemType.Text,
          title: getFieldTitle(item?.errorCode),
          fieldStyle,
          ellipsisMode: true,
        },
        {
          key: 'errorMessage',
          type: ListItemType.Text,
          title: item?.errorDetails,
          ellipsisMode: true,
          style: {
            color: theme.colors.danger,
            whiteSpace: 'nowrap',
          },
        },
      ],
    })),
  [fileErrors]);

  const isEmpty = useMemo(() => fileErrors.length === 0, [fileErrors.length]);

  return {
    list: fileErrors,
    data: formattedList as ListItem[] || [],
    isLoading,
    isEmpty,
    total,
  };
};

export default useFileErrorsList;
