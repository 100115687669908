import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import usePlansStore, { PlansPayload } from '../stores/usePlans.store';

export default () => {
  const handleSetPlansState = usePlansStore((state) => state.setState);
  const handleSetPlansSourceState = usePlansStore((state) => state.setSourceState);
  const handleSetPlansErrorFields = usePlansStore((state) => state.setErrorFields);
  const handleResetPlansStore = usePlansStore((state) => state.handleReset);

  const handleSetState = useCallback((values: Partial<PlansPayload>) => {
    handleSetPlansState(values);
  }, [handleSetPlansState]);

  const handleSetSourceState = useCallback((values: Partial<PlansPayload>) => {
    handleSetPlansSourceState(values);
  }, [handleSetPlansSourceState]);

  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<PlansPayload>>) => {
    handleSetPlansErrorFields(values);
  }, [handleSetPlansErrorFields]);

  const handleReset = useCallback(() => {
    handleResetPlansStore();
  }, [handleResetPlansStore]);
  
  return {
    handleSetState,
    handleSetSourceState,
    handleSetErrorFields,
    handleReset,
  };
};
