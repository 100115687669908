import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { AddSnackbarArgument, SnackbarNotification } from '@/modules/core/core.types';
import { useSnackbarStore } from '@/modules/core/store';

export default () => {
  const {
    list,
    handleAdd,
    handleRemoveById,
    handleReset,
  } = useSnackbarStore();
  const location = useLocation();
  const [pathKey, setPathKey] = useState(location.pathname);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialPathKey = useMemo(() => pathKey, []);

  useEffect(() => {
    setPathKey(location.pathname);
  }, [location.pathname]);

  const handleAddPermanentSnackbar = useCallback((props: AddSnackbarArgument) => {
    handleReset();
    handleAdd({ ...props, id: _.uniqueId() } as SnackbarNotification);
  }, [handleAdd, handleReset]);
  const handleRemoveSnackbarById = useCallback((id: string) => {
    handleRemoveById(id);
  }, [handleRemoveById]);

  const shouldSnackbarDisappear = useCallback(() => {
    if (list.length) {
      list.forEach((item) => {
        if (initialPathKey !== pathKey) {
          setTimeout(() => {
            handleRemoveById(item.id);
          }, 2000);
        }
      });
    }
  }, [handleRemoveById, initialPathKey, list, pathKey]);

  return {
    snackbars: list,
    handleRemoveSnackbarById,
    handleAddPermanentSnackbar,
    handleReset,
    shouldSnackbarDisappear,
  };
};
