import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CardTypesEnum } from '@common-fe/common-fe';

import { api } from '@/api';
import { OrganizationTypes } from '@/common/constants';
import PATHS from '@/common/paths';
import { useCurrentOrganization } from '@/modules/core/hooks';
import {
  PlanFieldState,
  PlanGroupedListResponse,
  PlanListItemResponse,
  PlanStatuses,
  PlanTypesLabel,
} from '@/modules/plan/plan.types';
import { getIdsFromPath } from '@/utils/modifiers';

export const getPlanList = (id: null | string, status?: PlanStatuses, name?: string) => () => api
  .get<PlanGroupedListResponse>(
    PATHS.PLANS, {
      params: {
        organization_id: id || undefined,
        plan_status: status || undefined,
        name,
        size: 100,
      },
    },
  );

export const PLAN_LIST_QUERY_NAME = 'getPlansList';

export const usePlanListQuery = (
  id?: string | null,
  status?: PlanStatuses,
  searchValue?: string,
) => {
  // const isSuperUser = useIsOrganizationType(OrganizationTypes.system);
  const {
    observingOrganization: { type, id: observingOrganizationId, path },
  } = useCurrentOrganization();

  const { employerId } = getIdsFromPath(path);

  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );

  const organizationId = useMemo(
    () => (isSubsidiary ? employerId : observingOrganizationId),
    [isSubsidiary, employerId, observingOrganizationId],
  );

  const currentId = useMemo(() => id || organizationId || '0', [id, organizationId]);
  const {
    isLoading, error, data, isSuccess, isError, refetch,
  } = useQuery(
    [PLAN_LIST_QUERY_NAME, currentId, status, searchValue],
    getPlanList(currentId, status, searchValue),
    {
      refetchOnMount: true,
    },
  );
  useEffect(() => {
    if (organizationId) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const formatedPlans = useMemo(() => {
    const list = data?.data || [];
    return Object.entries(list)?.map(([category, items]) => ({
      category: PlanTypesLabel[category as keyof typeof PlanTypesLabel],
      categoryEnum: category as CardTypesEnum,
      plans: items
        ?.map((item: PlanListItemResponse) => ({
          id: item?.id?.toString(),
          typeLabel: PlanTypesLabel[item?.account_type as keyof typeof PlanTypesLabel],
          type: item.account_type as CardTypesEnum,
          name: item?.name,
          typeState: item.account_type_state as PlanFieldState,
          status: item.plan_status as PlanStatuses,
          planYearId: item.plan_year?.id,
          planYearName: item.plan_year?.name,
        })),
    }))
      .filter((category) => category?.plans?.length > 0)
      .sort((a, b) => a?.category?.localeCompare(b?.category));
  }, [data]);

  const formattedArrayPlans = useMemo(() => {
    const list = data?.data || {};
    const mergedArray = Object.values(list).flatMap((array) => array);
    return mergedArray.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      typeState: item.account_type_state as PlanFieldState,
      status: item.plan_status as PlanStatuses,
      type: item.account_type as CardTypesEnum,
      planYearId: item.plan_year?.id,
      planYearName: item.plan_year?.name,
    }));
  }, [data]);

  return {
    data: formatedPlans,
    formattedArrayPlans,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
};
export default usePlanListQuery;
