import { useMemo } from 'react';
import {
  dateValidatorByFormat,
  Field,
  FieldTypes,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { CUSTOM_DAY_ERROR_TEXT, DATE_INVALID_TEXT, DEFAULT_DATE_FORMAT, REQUIRED_TEXT } from '@/common/constants';
import { PayrollCalendarFormFields, PayrollCalendarFormValues, PayrollCalendarFrequency, PayrollCalendarFrequencyOptions } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/payrollCalendar.types';
import { usePayrollCalendarNamesStore } from '@/modules/employer/components/SetupEmployer/Features/PayrollCalendar/stores';
import { isDateInRange } from '@/utils/modifiers/isDateInRange';

const tomorrow = dayjs().add(1, 'day').format(DEFAULT_DATE_FORMAT);
const SEMI_MONTH_ERROR_TEXT = 'You can not select the same date for semi-month';
const DAY_OF_YEAR_ERROR_TEXT = 'Date of year couldn`t be less than starting date';
const STARTING_DATE_ERROR_TEXT = 'Start date must be in the future';

const weeklyOptions = [
  { key: 'MONDAY', value: 'Monday' },
  { key: 'TUESDAY', value: 'Tuesday' },
  { key: 'WEDNESDAY', value: 'Wednesday' },
  { key: 'THURSDAY', value: 'Thursday' },
  { key: 'FRIDAY', value: 'Friday' },
  { key: 'SATURDAY', value: 'Saturday' },
  { key: 'SUNDAY', value: 'Sunday' },
];

interface Props {
  formValues?: PayrollCalendarFormValues;
  defaultValues?: PayrollCalendarFormValues;
  alreadyUsedValues?: PayrollCalendarFormValues;
}

export const usePayrollCalendarParamsForm = ({
  formValues,
  defaultValues,
  alreadyUsedValues,
}: Props) => {
  const isStartDateDisabled = useMemo(() => {
    return defaultValues?.[PayrollCalendarFormFields.START_DATE]
      && dayjs(defaultValues?.[PayrollCalendarFormFields.START_DATE]).isBefore(tomorrow);
  }, [defaultValues]);
  const calendarNames = usePayrollCalendarNamesStore((state) => state.calendarNames);

  const fields = useMemo(() => [
    {
      name: PayrollCalendarFormFields.CALENDAR_NAME,
      type: FieldTypes.Text,
      showRequireIcon: true,
      label: 'Calendar name',
      placeholder: 'Enter calendar name',
      defaultValue: alreadyUsedValues?.[PayrollCalendarFormFields.CALENDAR_NAME]
        || defaultValues?.[PayrollCalendarFormFields.CALENDAR_NAME] || '',
      validator: yup
        .string()
        .trim()
        .test({
          test: (val) => {
            if (defaultValues?.[PayrollCalendarFormFields.CALENDAR_NAME] === val) {
              return true;
            }
            if (val) return !calendarNames?.includes(val);
            return true;
          },
          message: 'Payroll calendar with such name already exists',
        })
        .required(REQUIRED_TEXT),
    },
    {
      name: PayrollCalendarFormFields.FREQUENCY,
      type: FieldTypes.Dropdown,
      showRequireIcon: true,
      label: 'Frequency',
      singleMode: true,
      placeholder: 'Select frequency',
      options: PayrollCalendarFrequencyOptions,
      defaultValue: alreadyUsedValues?.[PayrollCalendarFormFields.FREQUENCY]
        || defaultValues?.[PayrollCalendarFormFields.FREQUENCY] || '',
      validator: yup
        .string()
        .trim()
        .required(REQUIRED_TEXT),
    },
    ...formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.MONTHLY
      ? [
        {
          name: PayrollCalendarFormFields.DAY_OF_MONTH,
          type: FieldTypes.Number,
          showRequireIcon: true,
          label: 'Day of month',
          placeholder: 'Enter day of month',
          value: (alreadyUsedValues?.[PayrollCalendarFormFields.DAY_OF_MONTH] ? +alreadyUsedValues?.[PayrollCalendarFormFields.DAY_OF_MONTH] : '')
            || (defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] ? +defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] : ''),
          validator: VALIDATORS.REQUIRED_STRING.test({
            test: (val) => {
              const customDayNumber = _.toNumber(val);
              return customDayNumber > 0 && customDayNumber <= 31;
            },
            message: CUSTOM_DAY_ERROR_TEXT,
  
          }).required(REQUIRED_TEXT),
        },
      ]
      : [],
    ...formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.WEEKLY
      ? [
        {
          name: PayrollCalendarFormFields.DAY_OF_WEEK,
          type: FieldTypes.Dropdown,
          showRequireIcon: true,
          label: 'Day of week',
          placeholder: 'Select day of week',
          singleMode: true,
          options: weeklyOptions,
          value: alreadyUsedValues?.[PayrollCalendarFormFields.DAY_OF_WEEK]
            || defaultValues?.[PayrollCalendarFormFields.DAY_OF_WEEK] || '',
          validator: yup
            .string()
            .trim()
            .required(REQUIRED_TEXT),
        },
      ]
      : [],
    ...formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.BI_WEEKLY
      ? [
        {
          name: PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK,
          type: FieldTypes.Dropdown,
          showRequireIcon: true,
          label: 'Day of week',
          placeholder: 'Select day of week',
          singleMode: true,
          options: weeklyOptions,
          value: alreadyUsedValues?.[PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK]
            || defaultValues?.[PayrollCalendarFormFields.BI_WEEKLY_DAY_OF_WEEK] || '',
          validator: yup
            .string()
            .trim()
            .required(REQUIRED_TEXT),
        },
      ]
      : [],
    ...formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.SEMI_MONTHLY
      ? [
        {
          name: PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1,
          type: FieldTypes.Number,
          showRequireIcon: true,
          label: 'Day of month 1',
          placeholder: 'Enter day of month',
          value: (alreadyUsedValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] ? +alreadyUsedValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] : '')
            || (defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] ? +defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1] : ''),
          validator: VALIDATORS.REQUIRED_STRING.test({
            test: (val) => {
              const customDayNumber = _.toNumber(val);
              return customDayNumber > 0 && customDayNumber <= 31;
            },
            message: CUSTOM_DAY_ERROR_TEXT,
          }).test({
            test: (val) => val !== formValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2],
            message: SEMI_MONTH_ERROR_TEXT,
          }).required(REQUIRED_TEXT),
        },
        {
          name: PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2,
          type: FieldTypes.Number,
          showRequireIcon: true,
          label: 'Day of month 2',
          placeholder: 'Enter day of month',
          value: (alreadyUsedValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2] ? +alreadyUsedValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2] : '')
            || (defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2] ? +defaultValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_2] : ''),
          validator: VALIDATORS.REQUIRED_STRING.test({
            test: (val) => {
              const customDayNumber = _.toNumber(val);
              return customDayNumber > 0 && customDayNumber <= 31;
            },
            message: CUSTOM_DAY_ERROR_TEXT,
          }).test({
            test: (val) => val !== formValues?.[PayrollCalendarFormFields.SEMI_MONTHLY_DAY_OF_MONTH_1],
            message: SEMI_MONTH_ERROR_TEXT,
          }).required(REQUIRED_TEXT),
        },
      ]
      : [],
    ...formValues?.[PayrollCalendarFormFields.FREQUENCY] === PayrollCalendarFrequency.ANNUALLY
      ? [
        {
          name: PayrollCalendarFormFields.DATE_OF_YEAR,
          type: FieldTypes.Date,
          showRequireIcon: true,
          label: 'Date of year',
          placeholder: 'Select date',
          isManualDateInput: true,
          value: alreadyUsedValues?.[PayrollCalendarFormFields.DATE_OF_YEAR]
            || defaultValues?.[PayrollCalendarFormFields.DATE_OF_YEAR] || '',
          defaultValue: alreadyUsedValues?.[PayrollCalendarFormFields.DATE_OF_YEAR]
            || defaultValues?.[PayrollCalendarFormFields.DATE_OF_YEAR] || '',
          validator: yup.string()
            .test({
              test: (val) => val && (defaultValues?.[PayrollCalendarFormFields.DATE_OF_YEAR] !== val)
                ? isDateInRange(val, formValues?.[PayrollCalendarFormFields.START_DATE])
                : true,
              message: DAY_OF_YEAR_ERROR_TEXT,
            })
            .test({
              test: (val) => val && (defaultValues?.[PayrollCalendarFormFields.DATE_OF_YEAR] !== val)
                ? isDateInRange(val, tomorrow)
                : true,
              message: DAY_OF_YEAR_ERROR_TEXT,
            })
            .test({
              test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
              message: DATE_INVALID_TEXT,
            }).required(REQUIRED_TEXT),
          minDate: dayjs(formValues?.[PayrollCalendarFormFields.START_DATE], DEFAULT_DATE_FORMAT) > dayjs(tomorrow, DEFAULT_DATE_FORMAT) ? formValues?.[PayrollCalendarFormFields.START_DATE] || tomorrow : tomorrow,
        },
      ]
      : [],
    {
      name: PayrollCalendarFormFields.START_DATE,
      type: FieldTypes.Date,
      label: 'Starting date',
      placeholder: 'Select date',
      isManualDateInput: true,
      showRequireIcon: true,
      disabled: isStartDateDisabled,
      validator: !isStartDateDisabled ?  yup.string()
        .test({
          test: (val) => val
            ? isDateInRange(val, tomorrow)
            : true,
          message: STARTING_DATE_ERROR_TEXT,
        })
        .test({
          test: (val) => val ? dateValidatorByFormat(val, DEFAULT_DATE_FORMAT) : true,
          message: DATE_INVALID_TEXT,
        }).required(REQUIRED_TEXT) : undefined,
      defaultValue: alreadyUsedValues?.[PayrollCalendarFormFields.START_DATE]
        || defaultValues?.[PayrollCalendarFormFields.START_DATE] || '',
      minDate: defaultValues?.[PayrollCalendarFormFields.START_DATE] && dayjs(defaultValues?.[PayrollCalendarFormFields.START_DATE]).isAfter(tomorrow)
        ? tomorrow
        : defaultValues?.[PayrollCalendarFormFields.START_DATE] || tomorrow,
    },
  ], [
    alreadyUsedValues,
    defaultValues,
    formValues,
    isStartDateDisabled,
    calendarNames,
  ]);

  return fields as Field[];
};
