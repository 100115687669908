import React, { useMemo } from 'react';
import { Box, Inscription } from '@common-fe/common-fe';

import { PaymentMethod, PaymentMethodType } from '@/modules/ReimburseMe/types/payment.types';
import whiteLabelingConfig, { THEMES } from '@/utils/whiteLabeling/whiteLabelingConfig';

import PaymentFreeTimeLabel from '../../PaymentFreeTimeLabel';

const isRmrTheme = THEMES.rmr === whiteLabelingConfig.title;
const DEPOSIT_CARD_DESCRIPTION = 'We will deposit your reimbursement into the personal checking account you designate. This can take 2-3 business days.';
const DEBIT_CARD_DESCRIPTION = `For faster access to your money, we will
  deposit your reimbursement on to your personal debit card.`;
const PAY_PAL_DESCRIPTION = `Get reimbursed faster and safer!
  We will deposit your reimbursement into your PayPal account where you can then transfer funds or make purchases.`;
const VENMO_DESCRIPTION = `Manage your reimbursement your way!
  We will deposit your reimbursement into your Venmo account so that you can choose what to do with it.`;
const CHECK_DESCRIPTION = 'We will send a check to your Mailing address.';

interface PaymentMethodCard {
  title: string | React.ReactNode;
  paymentType: PaymentMethodType;
  description: string;
  add?: () => void;
  addTitle: string;
  paymentMethods?: PaymentMethod[];
  setActiveMethod?: (id: number) => void;
}

interface Props {
  onClose: () => void;
  chooseDeposit?: () => void;
  chooseDebitCard?: () => void;
  choosePayPal?: () => void;
  chooseVenmo?: () => void;
  chooseCheck?: () => void;
  methods: PaymentMethod[];
  allowedPaymentMethods: PaymentMethodType[];
}

const TitleWithLabel: React.FC<{ title?: string }> = ({ title }) => (
  <Box direction="row">
    <Inscription size="large" weight="bold" color="textBody">{title}</Inscription>
    {isRmrTheme ? <Box margin={{ left: 'spacing8' }}><PaymentFreeTimeLabel /></Box> : null}
  </Box>
);

export const PAYMENT_METHOD_TITLE = {
  [PaymentMethodType.DIRECT_DEPOSIT]: 'Direct Deposit',
  [PaymentMethodType.DEBIT]: 'My Personal Debit Card',
  [PaymentMethodType.VENMO]: 'Venmo',
  [PaymentMethodType.PAYPAL]: 'PayPal',
  [PaymentMethodType.CHECK]: 'Check',
};

export const usePaymentMethodsCards = (props: Props) => {
  const { allowedPaymentMethods, methods } = props;
  const isOnlyDirectPaymentMethodShown = useMemo(() => methods?.find((item) => item.type !== PaymentMethodType.DIRECT_DEPOSIT) === undefined, [methods]);

  const cards: PaymentMethodCard[] = useMemo(() => {
    const allPaymentMethodCard = [
      {
        title: PAYMENT_METHOD_TITLE[PaymentMethodType.DIRECT_DEPOSIT],
        paymentType: PaymentMethodType.DIRECT_DEPOSIT,
        description: DEPOSIT_CARD_DESCRIPTION,
        add: props.chooseDeposit,
        addTitle: 'Add a new Direct Deposit',
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.DIRECT_DEPOSIT,
        ),
      },

      {
        title: <TitleWithLabel title={PAYMENT_METHOD_TITLE[PaymentMethodType.DEBIT]} />,
        paymentType: PaymentMethodType.DEBIT,
        description: DEBIT_CARD_DESCRIPTION,
        addTitle: 'Add new Debit Card',
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.DEBIT,
        ),
      },
      {
        title: <TitleWithLabel title={PAYMENT_METHOD_TITLE[PaymentMethodType.PAYPAL]} />,
        paymentType: PaymentMethodType.PAYPAL,
        description: PAY_PAL_DESCRIPTION,
        addTitle: 'Add PayPal account',
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.PAYPAL,
        ),
      },
      {
        title: <TitleWithLabel title={PAYMENT_METHOD_TITLE[PaymentMethodType.VENMO]} />,
        paymentType: PaymentMethodType.VENMO,
        description: VENMO_DESCRIPTION,
        addTitle: 'Add Venmo account',
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.VENMO,
        ),
      },
      {
        title: PAYMENT_METHOD_TITLE[PaymentMethodType.CHECK],
        paymentType: PaymentMethodType.CHECK,
        description: CHECK_DESCRIPTION,
        addTitle: 'Add Check Payment Method',
        paymentMethods: props.methods.filter(
          (item) => item?.type === PaymentMethodType.CHECK,
        ),
      },
    ];

    const allowedPaymentMethodsCards = allPaymentMethodCard
      .filter((paymentMethod: PaymentMethodCard) => allowedPaymentMethods
        .filter((allowedPaymentMethod) => !isOnlyDirectPaymentMethodShown || allowedPaymentMethod === PaymentMethodType.DIRECT_DEPOSIT)
        .some(
          (allowedPaymentMethod: string) => allowedPaymentMethod === paymentMethod.paymentType,
        ));
    return allowedPaymentMethodsCards;
  }, [props, allowedPaymentMethods, isOnlyDirectPaymentMethodShown]);

  return cards;
};
