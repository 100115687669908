import { CarrierConfig } from '@/modules/CarrierConfig/queries/useCarrierConfigList.query';
import { storeCreator } from '@/utils/store/store.utils';

interface State {
  setCarrierConfigs: (carrierConfig: CarrierConfig[]) => void;
  resetCarrierConfigs: () => void;
  carrierConfigs: CarrierConfig[];
}

export const useStore = storeCreator<State>((set) => ({
  carrierConfigs: [],
  setCarrierConfigs: (carrierConfigs: CarrierConfig[]) => set((state) => ({
    ...state,
    carrierConfigs,
  })),
  resetCarrierConfigs: () => set((state) => ({ ...state, carrierConfigs: [] })),
}));

const useCarrierConfigStore = () => {
  const {
    setCarrierConfigs,
    resetCarrierConfigs,
    carrierConfigs,
  } = useStore();
  return {
    setCarrierConfigs,
    resetCarrierConfigs,
    carrierConfigs,
  };
};

export default useCarrierConfigStore;
