import { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';

import { Roles } from '@/common/constants';

interface RefreshToken {
  'custom:permissions': string,
  'custom:role': Roles,
  'custom:contact_id': string;
  'custom:organization_path': string;
  'custom:organization_id': string;
}

export const LOCAL_STORAGE_TOKEN_KEY = 'token';
export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
export const AUTH_HEADER_PREFIX = 'Bearer';
export const ACCESS_TOKEN_KEY = 'id_token';
export default class Token {
  public static isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public static getAuthentication(): AxiosRequestConfig {
    const token = this.getToken();
    if (!token) {
      return {};
    }
    return {
      headers: { Authorization: `${AUTH_HEADER_PREFIX} ${token}` },
    };
  }

  public static storeToken(token: string): void {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      LOCAL_STORAGE_REFRESH_TOKEN_KEY,
      refreshToken,
    );
  }

  public static clear(): void {
    localStorage.clear();
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  }

  public static getToken() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let accessToken = urlParams.get(ACCESS_TOKEN_KEY);
    const { hash, search, pathname } = window.location;
    if (!accessToken) {
      const accessTokenPart = hash.split('&').find((part) => (part.startsWith(`#${ACCESS_TOKEN_KEY}`) || part.startsWith(ACCESS_TOKEN_KEY))) || '';
       
      accessToken = accessTokenPart.split('=')[1];
    }
    if (accessToken) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, accessToken);
      window.location.replace(pathname + search);
      return accessToken;
    }
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  }

  public static getDecodedToken(): RefreshToken | null {
    const token = Token.getToken();
    if (!token) {
      return null;
    }
    return jwt_decode(token);
  }
}
