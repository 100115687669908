import { useCurrentOrganization } from '@/modules/core/hooks';
import useBanksListQuery from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BanksList/useBanksList.query';

const useBanksList = () => {
  const { observingOrganization } = useCurrentOrganization();
  const { options } = useBanksListQuery(observingOrganization.id || '');
  return options;
};

export default useBanksList;
