import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  capitalizeFirstLetter,
  FlexListItemStatus,
  Heading,
  Sidebar,
} from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';
import { useSidebarStore } from '@/modules/core/components/Sidebar/store/useSidebarStore';
import { TOC_CONTAINER_UI_ID } from '@/modules/core/hooks/useDetectActiveTOCModule';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import theme from '@/styles/theme';

import { SetupProgressItem } from './SetupProgressItem/SetupProgressItem';
import useTOCSidebar from './TOCSidebar.store';
import { AccordionWrap, AnimatedText,StyledAccordion } from './TOCSidebar.styles';
import { TOCSidebarProps } from './TOCSidebar.types';

type Props = TOCSidebarProps & {
  scrollToSection?: (data: { module?: string, section?: string }) => void;
  activeSection?: string;
  activeModule?: string;
  isPartnerMode?: boolean;
};

export const TOCSidebar: React.FC<Props> = ({
  stepsMap, scrollToSection, activeSection, activeModule,
}) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const { isUnableToActivate, setIsUnableToActivate } = useTOCSidebar();
  const { mode } = useSetupEmployerMode();
  const { expandSidebar } = useSidebarStore();
  const { formattedData, isLoading } = useGetEmployer();
  const stepsList = useMemo(() => Object.keys(stepsMap), [stepsMap]);
  const { name } = formattedData;
  const statusType = formattedData.organizationStatusType
  && capitalizeFirstLetter(formattedData.organizationStatusType);
  const validFromDate = dayjs(formattedData.validFrom).format(DEFAULT_DATE_FORMAT);
  const [expandItems, setExpandItems] = useState<number[]>([]);

  useEffect(() => {
    expandSidebar(false);
    return () => {
      expandSidebar(true);
    };
  }, [expandSidebar]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setIsUnableToActivate(false), []);

  useEffect(() => {
    if (activeModule && stepsList.length) {
      const index = stepsList.findIndex((step) => activeModule === step);
      if (!activeIndexes.includes(index)) {
        setActiveIndexes([index]);
        setExpandItems((prevState) => [...prevState, index]);
      }
    }
  }, [setActiveIndexes, stepsList, activeIndexes, activeModule, expandItems]);

  const setActiveIndexHandler = useCallback((indexes: number[]) => {
    setActiveIndexes(indexes);
    setExpandItems(indexes);
  }, []);

  const expandCallbackHandler = useCallback((index: number) => {
    setExpandItems((indexes) => (indexes.includes(index)
      ? indexes.filter((el) => el !== index)
      : [...indexes, index]));
  }, []);

  return (
    <Sidebar
      background="module"
      flex={{ grow: 1 }}
      height={{ max: '100vh' }}
      width={{ min: '280px', max: '280px' }}
      pad={{ top: '28px' }}
      gap={theme.spacings.spacing16}
      header={(
        <Box pad={{ left: 'spacing12', right: 'spacing12' }}>
          <Heading level={4} size="medium" margin="none" color="textTitle" truncate>
            {mode === EmployerSetupModes.partner && (!isLoading && (name || 'Set up Partner'))}
            {mode === EmployerSetupModes.distributor && (!isLoading && (name || 'Set up Distributor'))}
            {mode === EmployerSetupModes.employer && (!isLoading && (name || 'Set up Employer'))}
            {mode === EmployerSetupModes.subsidiary && (!isLoading && (name || 'Set up Subsidiary'))}
            {mode === EmployerSetupModes.system && (!isLoading && name)}
          </Heading>
          {!isLoading && (statusType && (
            <Box direction="row" align="center" margin={{ top: 'spacing16' }}>
              <FlexListItemStatus status={statusType}>
                {` from ${validFromDate}`}
              </FlexListItemStatus>
            </Box>
          ))}
          {
            isUnableToActivate && (
              <Box margin={{ top: 'spacing12', right: 'spacing12' }}>
                <AnimatedText color="danger">
                  Activation will be completed within an hour
                </AnimatedText>
              </Box>
            )
          }
        </Box>
      )}
    >
      <AccordionWrap
        id={TOC_CONTAINER_UI_ID}
        pad={{ left: 'spacing8', right: 'spacing8' }}
      >
        <StyledAccordion
          activeIndex={expandItems}
          multiple
          onActive={setActiveIndexHandler}
        >
          {stepsList.map((module, index) => (
            !stepsMap[module].hide
            && (
              <SetupProgressItem
                expanded={expandItems.includes(index)}
                expandCallback={() => expandCallbackHandler(index)}
                onModuleSelect={() => (scrollToSection ? scrollToSection({ module }) : null)}
                onSectionSelect={(section) => (scrollToSection
                  ? scrollToSection({ module, section }) : null)}
                key={stepsMap[module].title}
                details={stepsMap[module]}
                moduleId={module}
                activeSection={activeSection}
                activeModule={activeModule}
                active={activeIndexes.includes(index)}
              />
            )
          ))}
        </StyledAccordion>
      </AccordionWrap>
    </Sidebar>
  );
};
