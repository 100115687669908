import { toString } from 'lodash';

import { storeCreator } from '@/utils/store/store.utils';

import { EmployeePlanCodeCoverages, Recipient, Segment, TaxYearsLimitsIRS } from '../types';

interface State {
  setCoverageDates: (employeePlanCodeCoverages: EmployeePlanCodeCoverages) => void;
  setLimits: (limits: TaxYearsLimitsIRS) => void;
  setSegment: (segment: Segment) => void;
  setRecipients: (recipient: Recipient[]) => void;
  reset: () => void;
  recipients: Recipient[];
  segment: Segment;
}

export const useStore = storeCreator<State>((set) => ({
  recipients: [],
  segment: {},
  setRecipients: (recipients: Recipient[]) => set((state) => ({
    ...state,
    recipients,
  })),
  setSegment: (segment: Segment) => set((state) => ({
    ...state,
    segment,
  })),
  setLimits: (limits: TaxYearsLimitsIRS) => set((state) => ({
    ...state,
    recipients: state.recipients.map((recipient) => ({
      ...recipient,
      contributionAccounts: recipient.contributionAccounts?.map((acc) => {
        if (!acc?.taxYear || !recipient.employeeId || !acc.planId) return acc;

        const contributionAmountMax = toString(limits[acc.taxYear]?.[recipient.employeeId]?.[acc.planId]);

        if (contributionAmountMax) {
          return {
            ...acc,
            contributionAmountMax,
          };
        }

        return acc;
      })
    })),
  })),
  setCoverageDates: (employeePlanCodeCoverages: EmployeePlanCodeCoverages) => set((state) => ({
    ...state,
    recipients: state.recipients.map((recipient) => ({
      ...recipient,
      contributionAccounts: recipient.contributionAccounts?.map((acc) => {
        if (!recipient.employeeId || !acc.planCode) return acc;

        const { contributionDateTo, contributionDateFrom } = employeePlanCodeCoverages?.[recipient?.employeeId]?.[acc.planCode] || {};

        if (contributionDateTo || contributionDateFrom) {
          return {
            ...acc,
            ...contributionDateFrom ? { contributionDateFrom } : {},
            ...contributionDateTo ? { contributionDateTo } : {},
          };
        }

        return acc;
      })
    })),
  })),
  reset: () => set((state) => ({ ...state, recipients: [], segment: {} })),
}));

const useRecipientsStore = () => {
  const {
    setRecipients,
    setLimits,
    setCoverageDates,
    reset,
    recipients,
    segment,
    setSegment,
  } = useStore();
  return {
    setRecipients,
    setLimits,
    setCoverageDates,
    reset,
    recipients,
    segment,
    setSegment,
  };
};

export default useRecipientsStore;
