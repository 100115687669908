import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import { PATHS } from '@/common';
import { OrganizationTypes } from '@/common/constants';
import { ValuesMap } from '@/common/types';
import { useGetCurrentOrganizationType } from '@/utils/hooks/useGetCurrentOrganizationType';

import { useDownloadReportParams } from '../hooks/useDownloadReportParams';
import { ReportTypes } from '../report.types';



const downloadReportQuery = (
  id: string,
  organizationType: OrganizationTypes | null,
  type: ReportTypes | null,
  params?: ValuesMap<string>,
  invoiceId?: string,
) => {
  switch (type) {
  case ReportTypes.Card:
    return api.get(PATHS.EXPORT_CARD_REPORT(id), { params });
  case ReportTypes.Enrollment:
    return api.get(PATHS.EXPORT_ENROLLMENT_REPORT(organizationType as string, id));
  case ReportTypes.CIP:
    return api.get(PATHS.EXPORT_CIP_REPORT(id));
  case ReportTypes.Replenishment:
    return api.get(PATHS.EXPORT_REPLENISHMENT_REPORT(id), { params });
  case ReportTypes.Claims:
    return api.get(
      PATHS.EXPORT_CLAIM_REIMBURSEMENT_ACTIVITY_REPORT(id),
      { params },
    );
  case ReportTypes.ParticipantAccountActivity:
    return api.get(
      PATHS.EXPORT_ACCOUNT_ACTIVITY_REPORT(id), { params },
    );
  case ReportTypes.Contribution:
    return api.get(PATHS.EXPORT_CONTRIBUTION_REPORT(id), { params });
  case ReportTypes.Prefund:
    return invoiceId ? api.get(PATHS.EXPORT_PREFUND_REPORT(invoiceId)) : null;
  default:
    return null;
  }
};

export const useDownloadReportQuery = (
  id: string,
  startDownload: boolean,
  type: ReportTypes | null,
  customParams?: ValuesMap<string>,
  invoiceId?: string,
) => {
  const { currentType } = useGetCurrentOrganizationType();

  const params = useDownloadReportParams(type);

  const {
    data,
    isLoading,
    refetch,
    remove,
    isError,
  } = useQuery(
    [`${type}QueryKey`],
    () => downloadReportQuery(id, currentType, type, customParams || params, invoiceId),
    {
      enabled: startDownload,
    },
  );

  const fileName = useMemo(() => {
    const fileNameHeaderValue = data?.headers?.['content-disposition'];
    if (!fileNameHeaderValue) return null;

    const startIndex = fileNameHeaderValue?.indexOf('filename=');
    if (startIndex === -1) return null;

    const endIndex = fileNameHeaderValue.indexOf(';', startIndex);
    const [fileNameFromHeader] = fileNameHeaderValue
      .substring(startIndex + 9, endIndex !== -1 ? endIndex : undefined)
      .trim()
      .split('.');

    return fileNameFromHeader;
  }, [data]);

  return {
    data: data?.data,
    fileName,
    isLoading,
    refetch,
    remove,
    isError,
  };
};
