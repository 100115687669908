import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';

import {
  DefaultFields,
} from '@/common/types';
import { PLAN_YEAR_SETUP_FIELD } from '@/modules/HealthPlan/HealthPlanSetup/HealthPlanSetup.constants';
import { useTemplateFieldState } from '@/modules/HealthPlan/HealthPlanSetup/hooks';

import { usePlanYearStore } from '../stores';

export default (disabled?: boolean, isTemplateMode?: boolean) => {
  const defaultFields = usePlanYearStore((state) => state.sourceState);
  const fields = useMemo<Field[]>(() => [
    {
      name: PLAN_YEAR_SETUP_FIELD.PLAN_YEAR,
      showRequireIcon: true,
      singleMode: true,
      type: FieldTypes.Text,
      // options: planYearsOptions,
      disabled,
      label: 'Plan year',
      defaultValue: '1',
      placeholder: 'Select a plan year',
    },
    {
      name: PLAN_YEAR_SETUP_FIELD.PLAN_START_DATE,
      type: FieldTypes.Date,
      isManualDateInput: true,
      disabled: true,
      isWide: true,
      label: 'Plan start date',
      placeholder: 'Select Plan start date',
    },
    {
      name: PLAN_YEAR_SETUP_FIELD.PLAN_END_DATE,
      disabled: true,
      type: FieldTypes.Date,
      isManualDateInput: true,
      isWide: true,
      label: 'Plan end date',
      placeholder: 'Select Plan end date (optional)',
    },
    // {
    //   name: 'copayGroup',
    //   type: FieldTypes.Dropdown,
    //   label: 'Copay group',
    //   singleMode: true,
    //   disabled,
    //   defaultValue: disabled && !defaultFields?.copayGroup ? NONE_KEY : undefined,
    //   options: formatedCopayGroups,
    //   placeholder: 'Select copay group',
    //   validator: yup.string().trim(),

    // },
    // {
    //   name: PRIOR_PLAN_YEAR_ID_KEY,
    //   type: FieldTypes.Dropdown,
    //   label: 'Prior plan year',
    //   disabled,
    //   options: priorPlanYearList,
    //   singleMode: true,
    //   placeholder: 'Select prior plan year',
    //   validator: yup.string().trim(),
    // },
  ], [
    disabled,
    // formatedCopayGroups,
  ]);

  const formatedFields = useMemo(() => {
    if (defaultFields) {
      const defaultValues = defaultFields as DefaultFields;
      return fields.map((item) => ({
        ...item,
        value: defaultValues[item.name] || '',
      })) as Field[];
    }
    return fields as Field[];
  }, [defaultFields, fields]);

  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode);
  return stateFields;
};
