import React, { useCallback, useMemo } from 'react';
import { Box, FlexControlledForm } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from '@/common/constants';

import usePlanYearStore, { PlanYearPayload } from './stores/usePlanYear.store';
import { usePlanYearFields } from './hooks';

const COUNT_MOUNTH_ONE_YEAR = 12;

export interface Props {
  isEditMode?: boolean;
}

const PlanYearForm: React.FC<Props> = ({ isEditMode }) => {
  const showErrors = usePlanYearStore((state) => state.showErrors);
  const planYearSourceState = usePlanYearStore((state) => state.sourceState);
  const planYearState = usePlanYearStore((state) => state.state);
  const handleSetState = usePlanYearStore((state) => state.setState);

  const fields = usePlanYearFields(planYearSourceState, isEditMode);

  const handleChangeValues = useCallback((values: Partial<PlanYearPayload>) => {
    if (values) {
      if (values?.planStartDate && !planYearSourceState?.planEndDate && !planYearState.planEndDate) {
        const currentDate = new Date(values?.planStartDate);
        const futureDate = new Date(currentDate.setMonth(currentDate.getMonth() + COUNT_MOUNTH_ONE_YEAR));
        futureDate.setDate(futureDate.getDate() - 1);
        const formattedDate = dayjs(futureDate).format(DEFAULT_DATE_FORMAT);
        handleSetState({ planStartDate: values?.planStartDate, planEndDate: formattedDate});
      } else {
        handleSetState(values);
      }
    }
  }, [planYearState?.planEndDate, planYearSourceState?.planEndDate, handleSetState]);

  const key = useMemo(() => _.get(fields, '[1].defaultValue', ''), [fields]) as string;
  return (
    <Box
      data-testId="plan-year-form"
      background="canvas"
      round="container1Round"
      key={key}
    >
      <FlexControlledForm
        fields={fields}
        editMode={isEditMode}
        isModalType
        isThinMode
        showError={showErrors}
        onChangeValues={handleChangeValues}
      />
    </Box>
  );
};

export default PlanYearForm;
