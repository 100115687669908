import { useCallback } from 'react';

import { ErrorsField } from '@/utils/store/baseStoreCreator';

import useContactStore, { ContactPayload } from '../stores/useContact.store';

export default () => {
  const setContactState = useContactStore((state) => state.setState);
  const setContactErrorFields = useContactStore((state) => state.setErrorFields);
  const handleResetContactStore = useContactStore((state) => state.handleReset);

  const handleSetState = useCallback((values: Partial<ContactPayload>) => {
    setContactState(values);
  }, [setContactState]);

  const handleSetErrorFields = useCallback((values: Partial<ErrorsField<ContactPayload>>) => {
    setContactErrorFields(values);
  }, [setContactErrorFields]);

  const handleReset = useCallback(() => {
    handleResetContactStore();
  }, [handleResetContactStore]);
  

  return {
    handleSetState,
    handleSetErrorFields,
    handleReset,
  };
};
