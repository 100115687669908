import { useMemo } from 'react';
import { Field,FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { VALIDATORS } from '@/common';
import { REQUIRED_TEXT } from '@/common/constants';
import { Option } from '@/common/types';
import { HealthPlanStatuses } from '@/modules/HealthPlan/HealthPlan.types';
import { useHealthTemplatesListQuery } from '@/modules/HealthPlan/queries';
import { usePlanTemplatesQuery } from '@/modules/plan/queries';

export default (
  planYearList: Option[],
  handleSetSearchTemplateString: (value: string) => void,
  searchValue: string,
  isHealthPlanMode?: boolean,
) => {
  const {
    formattedTemplates,
    isLoading,
  } = usePlanTemplatesQuery(true, searchValue);

  const {
    options: healthPlanTemplates,
    isLoading: isHealthLoading,
  } = useHealthTemplatesListQuery({
    refetchOnMount: true,
    status: HealthPlanStatuses.ACTIVE,
    searchTemplateString: searchValue,
  });

  const fields = useMemo<Field[]>(() => {
    const commonFields: Field[] = [{
      name: 'planYear',
      type: FieldTypes.Dropdown,
      label: 'Plan year',
      singleMode: true,
      options: planYearList,
      placeholder: 'Plan year',
      validator: yup.string().trim().required(REQUIRED_TEXT),
      defaultValue: '',
    }];

    const planTemplateField: Field = isHealthPlanMode ? {
      name: 'planTemplate',
      type: FieldTypes.AutocompleteDropdown,
      label: 'Health plan template',
      placeholder: 'Select health plan template',
      validator: VALIDATORS.REQUIRED_STRING,
      onSearch: handleSetSearchTemplateString,
      options: healthPlanTemplates,
      singleMode: true,
      isLoading: isLoading || isHealthLoading,
    } : {
      name: 'planTemplate',
      type: FieldTypes.GroupedPlansDropdown,
      label: 'Plan template',
      placeholder: 'Select plan template',
      validator: VALIDATORS.REQUIRED_ARRAY,
      onSearch: handleSetSearchTemplateString,
      searchValue,
      options: formattedTemplates,
      groupTitle: 'Categories',
      isSingleMode: true,
      isLoading: isLoading || isHealthLoading,
    };

    return [planTemplateField, ...commonFields];
  }, [planYearList, formattedTemplates, isLoading, handleSetSearchTemplateString, searchValue, healthPlanTemplates, isHealthLoading, isHealthPlanMode]);
  return fields;
};
