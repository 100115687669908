import React, { useMemo } from 'react';
import { capitalizeFirstLetter, ListItem } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { isEmpty, toString, trim } from 'lodash';

import { DEFAULT_DATE_FORMAT, EMPTY_FIELD_VALUE } from '@/common/constants';
import ROUTES from '@/common/routes';
import { ListItemBase, ListItemType } from '@/common/types';
import { TextWithPointer } from '@/components/elements/TextWithPointer';
import { useHistory } from '@/modules/core/hooks';
import { LockboxListItem, lockboxTypeTitle } from '@/modules/employer/components/Lockbox/lockbox.types';
import theme from '@/styles/theme';
import { formatCurrency } from '@/utils';

export const LIST_HEADERS: ListItemBase[] = [
  {
    key: 'employee',
    title: 'Employee',
    flex: 1,
  },
  {
    key: 'amount',
    title: 'Amount',
    flex: 1,
  },
  {
    key: 'accountNumber',
    title: 'Account Number',
    flex: 1,
  },
  {
    key: 'type',
    title: 'Type',
    flex: 1,
  },
  {
    key: 'requestDate',
    title: 'Request Date',
    flex: 1,
  },
  {
    key: 'status',
    title: 'Status',
    flex: 0.7,
    style: {
      marginRight: theme.spacings.spacing24,
    }
  },
];

interface Props {
  lockboxes: LockboxListItem[],
}

const useLockboxList = ({ lockboxes }: Props) => {
  const history = useHistory();

  const fields: ListItem[] = useMemo(() => {
    if (!lockboxes?.length) {
      return [];
    }

    return lockboxes.map((lockbox) => ({
      id: `${lockbox?.id}`,
      fields: [
        {
          key: 'employee',
          type: ListItemType.Node,
          title: lockbox?.employeeName || EMPTY_FIELD_VALUE,
          flex: 1,
          node: (
            <TextWithPointer
              className="hover-field"
              size="medium"
              weight="bold"
              ellipsisMode
              color="textBody"
              truncate
              onClick={() => history.push(ROUTES.LOCKBOX_DETAILS(lockbox?.id))}
            >
              {isEmpty(trim(lockbox?.employeeName))
                ? EMPTY_FIELD_VALUE
                : lockbox?.employeeName}
            </TextWithPointer>
          ),
        },
        {
          key: 'amount',
          type: ListItemType.Text,
          title: formatCurrency(lockbox?.amount) || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1,
        },
        {
          key: 'accountNumber',
          type: ListItemType.Text,
          title: toString(lockbox?.accountNumber) || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1,
        },
        {
          key: 'type',
          type: ListItemType.Text,
          title: lockbox?.lockboxType ? lockboxTypeTitle[lockbox?.lockboxType] : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1,
        },
        {
          key: 'requestDate',
          type: ListItemType.Text,
          title: lockbox?.requestDate ? dayjs(lockbox?.requestDate).format(DEFAULT_DATE_FORMAT) : EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 1,
        },
        {
          key: 'status',
          type: ListItemType.Status,
          title: capitalizeFirstLetter(`${lockbox?.lockboxStatus}_lockbox`) || EMPTY_FIELD_VALUE,
          ellipsisMode: true,
          flex: 0.7,
          titleLength: lockbox?.lockboxStatus?.length,
          isReverse: true,
          style: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            marginRight: theme.spacings.spacing8,
          },
        },
      ],
    }));
  }, [lockboxes, history]);

  return {
    fields,
  };
};

export default useLockboxList;
