import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { FieldStateTypes, removeEmptyObjects } from '@common-fe/common-fe';
import _ from 'lodash';

import {
  FALSE_KEY, NO_KEY,
  // ONE_HUNDRED_PERCENT,
  TRUE_KEY, YES_KEY,
} from '@/common/constants';
// import { Response } from '@/api/api';
import { useCurrentOrganisationStore } from '@/modules/core/store';
import {
  ContributionState,
  PlanResponse,   PlanStatuses, 
  // PlanTypesEnum,
} from '@/modules/plan/plan.types';
import { useOnlyForDev } from '@/utils';

import { CLOSE_SYMBOL, OPEN_SYMBOL } from '../PlanDefinition/CardOffered/FundFormula/FormulaDragAndDrop/FormulaDragAndDrop';
import { useUpdatePlanByIdQuery } from '../queries';
// import { getFormatedPlan } from '../utils';
// import usePlanSetupSetter from './usePlanSetupSetter';
import { QUERY_KEY } from '../queries/usePlanById.query';
import { usePlanLoadingStore } from '../stores';

import usePlanSetupFieldsState from './usePlanSetupFieldsState';
import usePlanSetupState from './usePlanSetupState';

const CLAIM_KEY = 'ALLOW_PRIOR_YEAR_CLAIMS';
const DAILY_KEY = 'DAILY';
const DELAY_TIMEOUT = 1500;
 
const getBooleanFromString = (value?: string | boolean) => {
  if (value === true || value === YES_KEY || value === TRUE_KEY) {
    return true;
  }
  if (value === false || value === NO_KEY || value === FALSE_KEY) {
    return false;
  }
  return undefined;
};
 
const getBooleanFromYesNo = (value?: string) => (value === 'yes' ? true : value === 'no' ? false : undefined);
const getValuesWithZero = (value?: string | number) => value !== undefined && value !== null && value !== '';
const getNumberWithZero = (value?: string | number) => (value === '' || value === undefined || value === null
  ? undefined : _.toNumber(value));
// const getNumberFromPercent = (value?: number) => value && value / ONE_HUNDRED_PERCENT;
export default (errorCallback:(error: unknown) => void, isTemplateMode?: boolean) => {
  // const { handleSetSourceState } = usePlanSetupSetter();
  const setLoading = usePlanLoadingStore((state) => state.setLoading);
  const queryClient = useQueryClient();
  const { params: { id } } = useRouteMatch<{ id: string }>();
  const fieldsState = usePlanSetupFieldsState();
  const state = usePlanSetupState();
  const mutation = useUpdatePlanByIdQuery(id, errorCallback);
  const isOnlyForDev = useOnlyForDev();
  const { observingOrganizationId } = useCurrentOrganisationStore();

  // const activateValidate = useSubmitFormStore((activateState) => activateState.activateValidate);
  const contributions = useMemo(() => {
    if (isOnlyForDev) {
      return {
        allow_contribution_config: _.pickBy({
          employee: _.pickBy(state.allowContributors === ContributionState.EMPLOYEE
          || state.allowContributors === ContributionState.EMPLOYER_AND_EMPLOYEE ? {
              is_pre_tax: state.employeePreTax,
              pre_tax_memo: state.employeePreTaxValue,
              is_post_tax: state.employeePostTax,
              post_tax_memo: state.employeePostTaxValue,
            } : {}),
          employer: _.pickBy(state.allowContributors === ContributionState.EMPLOYER
          || state.allowContributors === ContributionState.EMPLOYER_AND_EMPLOYEE ? {
              is_pre_tax: state.employerPreTax,
              pre_tax_memo: state.employerPreTaxValue,
              is_post_tax: state.employerPostTax,
              post_tax_memo: state.employerPostTaxValue,
            } : {}),
          type: state.allowContributors,
          state: fieldsState.allowContributors,
        }),
        is_allow_contributions_over_election_amount:
         state.allowContributors === ContributionState.NONE ? undefined : {
           is_allow_contributions_over_election_amount: !!state.allowContributionOverMaximum,
           is_allow_contributions_over_election_amount_state:
          fieldsState.allowContributionOverMaximum,
         },
        is_pend_contributions_over_irs:
         state.allowContributors === ContributionState.NONE ? undefined : {
           is_pend_contributions_over_the_irs_max: !!state.pendContribution,
           is_pend_contributions_over_the_irs_max_state:
          fieldsState.pendContribution,
         },
      };
    }
    return {
      allow_contribution_config: {
        employer: {
          is_pre_tax: true,
        },
        employee: {
          is_pre_tax: true,
        },

        type: ContributionState.EMPLOYER_AND_EMPLOYEE,
        state: FieldStateTypes.Modifiable,
      },
      is_allow_contributions_over_election_amount: {
        is_allow_contributions_over_election_amount: !!state.allowContributionOverMaximum,
      },
      is_pend_contributions_over_irs: {
        is_pend_contributions_over_the_irs_max: !!state.pendContribution,
      },
    };
  }, [fieldsState, isOnlyForDev, state]);

  const formatedData = useMemo(() => {
    const allowInvestments = getBooleanFromString(state.allowInvestments);
    const CMSReportingRequired = getBooleanFromString(state.CMSReportingRequired);
    const payload: Partial<PlanResponse> = removeEmptyObjects({
      id,
      ...state?.planSummary ? {
        summary: state?.planSummary,
      } : {},
      is_plan: !isTemplateMode,
      plan_status: PlanStatuses.DRAFT,
      ...observingOrganizationId ? {
        organization_id: observingOrganizationId,
      } : {},
      name: {
        name: state.planName,

        name_state: fieldsState.planName, // *
      },
      account_type: {
        account_type: state.planType,
        account_type_state: fieldsState.planType, // *
      },
      plan_code: state.planCode,
      prior_plan_id: state.priorPlanId ? _.toNumber(state.priorPlanId) : undefined,
      plan_code_state: fieldsState.planCode, // *
      plan_year_id: state.planYear ? _.toNumber(state.planYear) : undefined,
      plan_primary_config: {
        is_orphaned: {
          is_orphaned: getBooleanFromString(state.isOrphaned),
          is_orphaned_state: fieldsState.isOrphaned,
        },
        generate_statements: {
          generate_statements: getBooleanFromString(state.generateStatements),
          generate_statements_state_id: fieldsState.generateStatements,
        },
        spend_limit_type: _.pickBy({
          spend_limit: getNumberWithZero(state.spendLimitCustom),
          spend_limit_type: state.spendLimit,
          irs_limit_type: state.spendLimitIRSAmount,
          spend_limit_type_state: fieldsState.spendLimit,
        }, getValuesWithZero),
        spend_period_type: _.pickBy({
          spend_period_type: state.spendPeriod,
          spend_period_type_state: fieldsState.spendPeriod,
        }, getValuesWithZero),
        available_balance_type: {
          available_balance_type_state: fieldsState.availableBalance, // *
          available_balance_type: state.availableBalance || undefined,
          // isActivateMode
          //   ? state.availableBalance || ELECTION_BASE_KEY
          //   : state.availableBalance || undefined,
        },
        omnibus_type: {
          omnibus_type: state.omnibus || undefined,
          omnibus_type_state: fieldsState.omnibus, // *
        },
        max_election_amount_type: {
          max_election_amount_type_state: fieldsState.maximumElectionAmount, // *
          max_election_amount: getNumberWithZero(state.maximumElectionAmountCustom),
          irs_limit_type: state.maximumElectionAmountIRS
            ? state.maximumElectionAmountIRS : undefined,
          max_election_amount_type: state.maximumElectionAmount
            ? state.maximumElectionAmount : undefined,
        },
        min_election_amount_type: {
          min_election_amount_type_state: fieldsState.minimumElectionAmount, // *

          min_election_amount: getNumberWithZero(state.minimumElectionAmountCustom),
          min_election_amount_type: state.minimumElectionAmount
            ? state.minimumElectionAmount : undefined,
        },
        proof_of_expenses_type: {
          proof_of_expenses_type_state: fieldsState.proofOfExpense, // *
          proof_of_expenses_type: state.proofOfExpense || undefined,
        },
        is_cip_required: {
          is_cip_required_state: fieldsState.CIPRequired, // *
          is_cip_required: state.CIPRequired || false,
        },
        pend_contributions_for_cip: {
          pend_contributions_for_cip_state: fieldsState.pendContributionsForCIP, // *
          pend_contributions_for_cip: state.pendContributionsForCIP || false,
        },
        is_replenished: {
          is_replenished_state: fieldsState.isReplenished, // *
          is_replenished: state.isReplenished || false,
        },
        is_carded: {
          is_carded_state: fieldsState.cardOffered, // *
          is_carded: getBooleanFromString(state.cardOffered),
        },
        fund_id: {
          fund_id: getBooleanFromString(state.cardOffered) && state.fundID
            ? state.fundID
            : null,
          fund_state_id: fieldsState.fundID, // *
        },
        fund_id_formula: {
          fund_id_formula: state.fundIDFormula?.replace(`${OPEN_SYMBOL}${CLOSE_SYMBOL}`, '') || '',
          fund_id_formula_state: fieldsState.fundIdFormulaOptions,
        },
        ..._.pickBy({
          allow_investments: allowInvestments === undefined ? null : {
            allow_investments: allowInvestments,
            allow_investments_state: fieldsState.allowInvestments, // *
          },
          cms_reporting_required: CMSReportingRequired === undefined ? null : {
            cms_reporting_required: CMSReportingRequired,
            cms_reporting_required_state: fieldsState.CMSReportingRequired,
          },
          catch_up_election: _.pickBy({
            catch_up_election: getNumberWithZero(state.catchUpElections),
            catch_up_election_state: fieldsState.catchUpElections,
          }, getValuesWithZero),
          catch_up_age: _.pickBy({
            catch_up_age: getNumberWithZero(state.catchUpAge),
            catch_up_age_state: fieldsState.catchUpAge,
          }, getValuesWithZero),
          investment_options_type: _.pickBy({
            investment_options_types: state.investmentOptionsType,
            investment_options_type_state: fieldsState.investmentOptionsType,
          }),
          investment_threshold: _.pickBy({
            investment_threshold: getNumberWithZero(state.investmentThreshold),
            investment_threshold_state: fieldsState.investmentThreshold,
          }, getValuesWithZero),

          minimum_investment_amount: _.pickBy({
            minimum_investment_amount_state: fieldsState.minimumInvestmentAmount,
            minimum_investment_amount: getNumberWithZero(state.minimumInvestmentAmount),
          }, getValuesWithZero),
        }),

      },
      plan_coverage_config: _.pickBy({
        end_of_coverage_type: _.pickBy({
          end_of_coverage_type: state.endOfCoverage,
          end_of_coverage_state: fieldsState.endOfCoverage, // *
          end_of_coverage_days_amount: getNumberWithZero(state.endOfCoverageAmount),
        }, getValuesWithZero),
        grace_period_type: _.pickBy({
          grace_period_type_state: fieldsState.gracePeriod, // *
          grace_period_type: state.gracePeriod,
          grace_period_days_amount: getNumberWithZero(state.gracePeriodAmount),
        }, getValuesWithZero),

        claims_deadline_end_of_coverage_type: _.pickBy({
          claims_deadline_end_of_coverage_type: state.claimsDeadline,
          claims_deadline_end_of_coverage_state: fieldsState.claimsDeadline, // *,
          claims_deadline_end_of_coverage_days_amount:
          getNumberWithZero(state.claimsDeadlinePlanYearAmount) !== undefined
            ? getNumberWithZero(state.claimsDeadlinePlanYearAmount)
            : getNumberWithZero(state.claimsDeadlineCoverageDateAmount),
        }, getValuesWithZero),
        run_out_type: _.pickBy({
          run_out_type_state: fieldsState.runOut, // *
          run_out_type: state.runOut,
          run_out_days_amount: getNumberWithZero(state.runOutAmount),
        }, getValuesWithZero),
        ai_adjudication_type: _.pickBy({
          ai_adjudication_type: state.aiAdjudicationType,
          ai_adjudication_type_state: fieldsState.aiAdjudicationType,
        }, getValuesWithZero),
        // is_pay_claims_absence: {
        //   is_pay_claims_absence_state: fieldsState.isPayClaimsAbsence, // *
        //   is_pay_claims_absence: getBooleanFromString(state.isPayClaimsAbsence),
        // },
        is_pay_claims_outside_coverage: {
          is_pay_claims_outside_coverage_state: fieldsState.isPayClaimsOutsideCoverage, // *
          is_pay_claims_outside_coverage: getBooleanFromString(state.isPayClaimsOutsideCoverage),

        },
      }),
      plan_account_funding_config: {

        ...contributions,
        contribution_monitoring_required: {
          contribution_monitoring_required:
            getBooleanFromYesNo(state.сontributionMonitoringRequired),
          contribution_monitoring_required_state: fieldsState.сontributionMonitoringRequired,
        },
        account_creation: {
          account_creation_type: state.accountCreation || undefined,
          account_creation_type_state: fieldsState.accountCreation,
        },
        interest_template_id: {
          interest_template_id: getNumberWithZero(state.interestTemplate),
          interest_template_id_state_id: fieldsState.interestTemplate,
        },
        allow_individual_contributions: {
          allow_individual_contributions: getBooleanFromYesNo(state.allowIndividualContributions),
          allow_individual_contributions_state_id: fieldsState.allowIndividualContributions,
        },
        minimum_contribution_amount: _.pickBy({
          minimum_contribution_amount: getNumberWithZero(state.minimumContributionAmount),
          minimum_contribution_amount_state_id: fieldsState.minimumContributionAmount,
        }, getValuesWithZero),
        is_rollover: {
          is_rollover_state: fieldsState.rollover, // *
          is_rollover: state.rollover || false,
        },

        is_custom_prefund_calc: {
          is_custom_prefund_calc: state.isReplenished ? state.isPreFundCalculated || false: false,
          is_custom_prefund_calc_state: fieldsState.isPreFundCalculated,
        },

        prefund_replenishment_percent: {
          prefund_replenishment_percent: getNumberWithZero(state.replenishmentPercent),
          prefund_replenishment_percent_state: fieldsState.replenishmentPercent,
        },
        prefund_replenishment_min_value: {
          prefund_replenishment_min_value: getNumberWithZero(state.replenishmentMinValue),
          prefund_replenishment_min_value_state: fieldsState.replenishmentMinValue,
        },
        prefund_replenishment_creep_min: {
          prefund_replenishment_creep_min: getNumberWithZero(state.replenishmentCreepMin),
          prefund_replenishment_creep_min_state: fieldsState.replenishmentCreepMin,
        },
        prefund_replenishment_creep_percent: {
          prefund_replenishment_creep_percent:
          getNumberWithZero(state.replenishmentCreepPercent),
          prefund_replenishment_creep_percent_state: fieldsState.replenishmentCreepPercent,
        },

        is_plan_level_funding: _.pickBy({
          is_plan_level_funding: state.planLevelFunding,
          is_plan_level_funding_state: fieldsState.planLevelFunding,
        }),
        ..._.pickBy({
          ...state.planLevelFunding && state.isReplenished ? {
            replenishment_bank_account_id: getNumberWithZero(state.replenishmentBankAccount),
            replenishment_method: state.replenishmentMethod,
            replenishment_frequency: state.replenishmentFrequency,
            replenishment_day_number: state.replenishmentFrequency === DAILY_KEY
              ? 1 : getNumberWithZero(state.replenishmentDayNumber),
          } : {},
          ...state.planLevelFunding && !state.isReplenished ? {
            contribution_bank_account_id: getNumberWithZero(state.contributionBankAccount),
            contribution_method: state.contributionMethod,
            contribution_hold_period: getNumberWithZero(state.contributionHoldPeriod),
          } : {},

        }, getValuesWithZero),

        ...state.rollover ? {
          max_rollover_amount: _.pickBy({
            max_rollover_amount_type_state: fieldsState.maximumRolloverAmount, // *
            max_rollover_amount:
            getNumberWithZero(state.maximumRolloverAmountCustom),
            max_rollover_irs_limit_type: state.maximumRolloverAmountIRS,
            max_rollover_amount_type: state.maximumRolloverAmount,
          }, getValuesWithZero),
          min_rollover_amount: _.pickBy({
            min_rollover_amount_type_state: fieldsState.minimumRolloverAmount, // *
            min_rollover_amount_type:
            state.minimumRolloverAmount,
            min_rollover_amount: getNumberWithZero(state.minimumRolloverAmountCustom),
          }, getValuesWithZero),
          rollover_claims: _.pickBy({
            rollover_claims_type_state: fieldsState.rolloverClaims, // *
            rollover_claims_type: state.rolloverClaims ? CLAIM_KEY : '',
            rollover_claims_days_amount: getNumberWithZero(state.claimsPriorYear),
          }, getValuesWithZero),
          is_auto_enrollment: {
            is_auto_enrollment_state: fieldsState.autoEnrollment, // *
            is_auto_enrollment: getBooleanFromString(state.autoEnrollment),
          },
          days_after_plan_end: _.pickBy({
            days_after_plan_end_state: fieldsState.daysAfterPlanEnd, // *
            days_after_plan_end: getNumberWithZero(state.daysAfterPlanEnd),
          }, getValuesWithZero),
        } : {},

      },
    });

    return payload;
  }, [state, fieldsState, id, contributions, observingOrganizationId, isTemplateMode]);
  const setPlanLoadedId =  usePlanLoadingStore((state) => state.setPlanLoadedId);
  const handleSave = useCallback(async (planStatus: PlanStatuses) => {
    // const isActivateMode = planStatus === PlanStatuses.ACTIVE;
    await mutation.mutateAsync({
      dto: {
        ...formatedData,
        plan_status: planStatus,
      },
    });
    // const plan = response.data;
    // TODO: bugfix/EL-16639 Verify if the bug is not reproduced
    setPlanLoadedId('');
    queryClient.invalidateQueries([QUERY_KEY, id]);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, DELAY_TIMEOUT);
    // queryClient.setQueryData([QUERY_KEY, id], () => ({
    //   ...oldData,
    //   data: {
    //     ...oldData?.data,
    //     ...plan,
    //     // plan_status: plan.plan_status,
    //   },
    // }));

    // handleSetSourceState(getFormatedPlan(plan));
  }, [formatedData, id, mutation, queryClient, setLoading, setPlanLoadedId]);

  return handleSave;
};
