import React from 'react';
import { Box } from '@common-fe/common-fe';

import { CommunicationCategory } from './CommunicationCategory';
import { CommunicationChannel } from './CommunicationChannel';
import { StatementsSettings } from './StatementsSettings';

interface Props {
  onDirty?: (isDirty: boolean) => void;
  isPartnerPage?: boolean;
}

export const Communication: React.FC<Props> = ({ onDirty, isPartnerPage }) => (
  <Box data-testid="Communication-form-wrapper">
    {
      isPartnerPage && (
        <>
          <CommunicationChannel
            onDirty={onDirty}
          />
          <CommunicationCategory
            onDirty={onDirty}
          />
        </>
      )
    }
    <StatementsSettings
      onDirty={onDirty}
      isPartnerPage={isPartnerPage}
    />
  </Box>
);
