import { baseStoreCreator } from '@/utils/store';

export interface CustomReportsPayload {
  customReportDisplayedField1?: boolean;
  customReportDisplayedField2?: boolean;
  customReportDisplayedField3?: boolean;
  customReportDisplayedField4?: boolean;
  customReportDisplayedField5?: boolean;

  customReportField1: string;
  customReportField2: string;
  customReportField3: string;
  customReportField4: string;
  customReportField5: string;
}
export const DEFAULT_VALUES: CustomReportsPayload = {
  customReportDisplayedField1: false,
  customReportDisplayedField2: false,
  customReportDisplayedField3: false,
  customReportDisplayedField4: false,
  customReportDisplayedField5: false,

  customReportField1: '',
  customReportField2: '',
  customReportField3: '',
  customReportField4: '',
  customReportField5: '',
};

export const useStore = baseStoreCreator<CustomReportsPayload>(DEFAULT_VALUES);

export default useStore;
