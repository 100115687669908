import { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import * as yup from 'yup';

import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { useFieldsWithDefaultValues } from '@/utils/hooks';

import { useInterestTemplateQuery } from '../queries';
import { useInterestAccrualsStore } from '../stores';

// const VALIDATOR = yup.string().nullable();
// const REQUIRED_VALIDATOR = yup.string().trim().required(REQUIRED_TEXT);
export const NONE_KEY = 'NONE';
export default (
  isTemplateMode?: boolean,
  isEditMode?: boolean,
) => {
  const sourceState = useInterestAccrualsStore((state) => state.sourceState);

  const {
    formatedOptions,
  } = useInterestTemplateQuery();

  const fields = useMemo<Field[]>(() => [
    {
      name: 'interestTemplate',
      type: FieldTypes.Dropdown,
      validator: yup.string(),
      label: 'Interest template',
      singleMode: true,
      defaultValue: NONE_KEY,
      showRequireIcon: true,
      options: [
        {
          key: NONE_KEY,
          value: 'None',
        },
        ...(formatedOptions || []),

      ],

    },

  ], [formatedOptions]);
  const formatedFields = useMemo(
    () => useFieldsWithDefaultValues(fields, sourceState), [fields, sourceState],
  );
  const filteredField = usePlanFieldsState(formatedFields);
  const stateFields = useTemplateFieldState(filteredField, isTemplateMode, isEditMode);
  return stateFields;
};
