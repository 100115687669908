import React, { useMemo } from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT, EMPTY_FIELD_VALUE } from '@/common/constants';
import { Lockbox, LockboxType } from '@/modules/employer/components/Lockbox/lockbox.types';
import { 
  LockboxAmountInput,
  LockboxDocumentTypeInput,
  LockboxVendorAddress,
  LockboxVendorDropdown
} from '@/modules/employer/components/Lockbox/LockboxDetails/components/LockboxInfo/LockboxForm/components';
import useLockboxDetailsStore from '@/modules/employer/components/Lockbox/LockboxDetails/stores/useLockboxDetails.store';
import { Vendor } from '@/modules/employer/components/Lockbox/queries/useGetVendors.query';

interface Props {
  lockbox?: Lockbox;
  vendors?: Vendor[];
}

const useLockboxRecordFields = ({ lockbox, vendors }: Props) => {
  const { editableLockbox } = useLockboxDetailsStore();
  const vendorId = editableLockbox?.vendorId;

  const activeVendor = useMemo(() => vendors
    ?.find((vendor) => vendor?.vendorId === vendorId),
  [vendorId, vendors]);

  const lockboxRecordFields = useMemo(() => [
    {
      type: FieldTypes.Node,
      name: 'documentType',
      label: 'Document type',
      value: <LockboxDocumentTypeInput />,
    },
    {
      type: FieldTypes.Text,
      name: 'dateReceived',
      label: 'Date received',
      value: lockbox?.requestDate
        ? dayjs(lockbox?.requestDate).format(DEFAULT_DATE_FORMAT)
        : EMPTY_FIELD_VALUE,
    },
    ...!lockbox?.ocr ? [{
      type: FieldTypes.Node,
      name: 'amount',
      label: 'Amount',
      value: <LockboxAmountInput />,
    }] : [],
    ...editableLockbox?.lockboxType === LockboxType.INBOUND_TOA || editableLockbox?.lockboxType === LockboxType.OUTBOUND_TOA ? [
      {
        type: FieldTypes.Node,
        name: 'vendor',
        label: 'Vendor',
        value: <LockboxVendorDropdown vendors={vendors} />,
      },
      ...activeVendor ? [
        {
          type: FieldTypes.Node,
          name: 'vendorAddress',
          label: 'Vendor address',
          value: <LockboxVendorAddress activeVendor={activeVendor} checkDetails={lockbox?.checkDetails} />,
        }
      ] : [],
    ] : [],
  ], [lockbox, vendors, activeVendor, editableLockbox]);

  return {
    lockboxRecordFields: lockboxRecordFields as Field[],
  };
};

export default useLockboxRecordFields;
