import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Field, FieldTypes } from '@common-fe/common-fe';
import { toString } from 'lodash';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import {
  MAX_LENGTH_TEXT,
  OrganizationTypes,
  REQUIRED_TEXT,
  SSN_INVALID_TEXT,
  SSN_MASK_WITH_DASHES,
  SSN_MASK_WITHOUT_DASHES,
} from '@/common/constants';
import { Option } from '@/common/types';
import { useCurrentOrganization } from '@/modules/core/hooks';
import { SourceIds } from '@/modules/employee/employee.types';
import useGetOrgName from '@/modules/employer/queries/useGetOrgName.query';
import { createPathFromIds, getIdsFromPath } from '@/utils/modifiers';

import { useGetPayrollGroupsQuery } from '../../SetupEmployer/Features/PayrollGroups/queries/useGetPayrollGroups.query';
import { useOrganizationsQuery } from '../query/useOrganizations.query';

const FIELDS = {
  PARTNER: 'partner',
  DISTRIBUTOR: 'distributor',
  EMPLOYER: 'employer',
  SUBSIDIARY: 'subsidiary',
  ID_SOURCE: 'idSource',
  EMPLOYER_EMPLOYEE_ID: 'employerEmployeeId',
  PARTNER_EMPLOYEE_ID: 'partnerEmployeeId',
  SSN: 'ssn',
};

export const MAX_FIELD_LENGTH = 40;
const SSN_DELIMITER_SIGN = '-';

interface Props {
  chosenPartnerId?: string;
  chosenDistributorId?: string;
  chosenEmployerId?: string;
  chosenSubsidiaryId?: string;
  idSourceId?: string;
  ssn?: string;
  hasInternalErrorValidation?: boolean;
  clearInternalError?: () => void;
  clearEmployeeIdExistsValidation?: () => void;
  hasEmployeeIdExistsValidation?: boolean;
}

const useEmployeeSetupIdentifiersModalFields = (props: Props) => {
  const {
    chosenPartnerId,
    chosenDistributorId,
    chosenEmployerId,
    chosenSubsidiaryId,
    idSourceId,
    ssn,
    hasInternalErrorValidation,
    clearInternalError,
    clearEmployeeIdExistsValidation,
    hasEmployeeIdExistsValidation,
  } = props;
  // const hasSubsidiaryInEnv = useHasSubsidiaryInEnv();

  const [cleaningInitiatorDistributor, setCleaningInitiatorDistributor] = useState(false);
  const [cleaningInitiatorEmployer, setCleaningInitiatorEmployer] = useState(false);
  const [cleaningInitiatorSubsidiary, setCleaningInitiatorSubsidiary] = useState(false);

  const [currentSsnMask, setCurrentSsnMask] = useState(SSN_MASK_WITH_DASHES);
  const ssnValidator = useMemo(() => {
    if (!ssn) return yup.string().required(REQUIRED_TEXT);

    if (hasInternalErrorValidation) {
      return yup.string().trim().test({
        test() {
          return false;
        },
        message: 'SSN number must be unique.',
      });
    }

    return yup.string().min(currentSsnMask.length, SSN_INVALID_TEXT);
  }, [ssn, hasInternalErrorValidation, currentSsnMask]);
  const employeeIdValidator = useCallback((title: string) => {
    if (hasEmployeeIdExistsValidation) {
      return yup.string().trim().test({
        test() {
          return false;
        },
        message: `This ${title} Employee ID already exists.`,
      });
    }

    return yup
      .string()
      .max(MAX_FIELD_LENGTH, MAX_LENGTH_TEXT(MAX_FIELD_LENGTH))
      .required(REQUIRED_TEXT);
  }, [hasEmployeeIdExistsValidation]);

  const [searchPartner, setSearchPartner] = useState('');
  const [searchDistributor, setSearchDistributor] = useState('');
  const [searchEmployer, setSearchEmployer] = useState('');
  const [searchSubsidiary, setSearchSubsidiary] = useState('');

  const [currentPartners, setCurrentPartners] = useState<Option[]>([]);
  const [currentDistributors, setCurrentDistributors] = useState<Option[]>([]);
  const [currentEmployers, setCurrentEmployers] = useState<Option[]>([]);
  const [currentSubsidiaries, setCurrentSubsidiaries] = useState<Option[]>([]);

  const { observingOrganization } = useCurrentOrganization();
  const {
    systemId,
    partnerId,
    distributorId,
    employerId,
    subsidiaryId,
  } = useMemo(
    () => getIdsFromPath(observingOrganization.path),
    [observingOrganization.path],
  );
  const isSystem = useMemo(() => observingOrganization.type === OrganizationTypes.system,
    [observingOrganization.type]);
  const isPartner = useMemo(() => observingOrganization.type === OrganizationTypes.partner,
    [observingOrganization.type]);
  const isDistributor = useMemo(() => observingOrganization.type === OrganizationTypes.distributor,
    [observingOrganization.type]);
  const isEmployer = useMemo(() => observingOrganization.type === OrganizationTypes.company,
    [observingOrganization.type]);
  const isSubsidiary = useMemo(() => observingOrganization.type === OrganizationTypes.subsidiary,
    [observingOrganization.type]);

  const orgPathForDistributors = useMemo(
    () => createPathFromIds({
      systemId,
      partnerId: partnerId || chosenPartnerId,
    }),
    [
      systemId,
      partnerId,
      chosenPartnerId,
    ],
  );
  const orgPathForEmployers = useMemo(
    () => createPathFromIds({
      systemId,
      partnerId: partnerId || chosenPartnerId,
      distributorId: distributorId || chosenDistributorId,
    }),
    [
      systemId,
      partnerId,
      chosenPartnerId,
      distributorId,
      chosenDistributorId,
    ],
  );
  const orgPathForSubsidiaries = useMemo(
    () => createPathFromIds({
      systemId,
      partnerId: partnerId || chosenPartnerId,
      distributorId: distributorId || chosenDistributorId,
      employerId: employerId || chosenEmployerId,
    }),
    [
      systemId,
      partnerId,
      distributorId,
      employerId,
      chosenPartnerId,
      chosenDistributorId,
      chosenEmployerId,
    ],
  );

  const { name: downloadedPartnerName } = useGetOrgName({
    id: isDistributor
      || isEmployer
      || isSubsidiary
      || chosenSubsidiaryId
      || chosenEmployerId
      || chosenDistributorId
      ? partnerId
      : undefined,
    enabled: true,
  });
  const selectedPartnerName = useMemo(
    () => {
      if (isPartner) return observingOrganization.name;

      return downloadedPartnerName;
    },
    [downloadedPartnerName, isPartner, observingOrganization.name],
  );
  const autoSelectedPartner = useMemo(() => {
    if (isPartner) {
      return {
        key: toString(observingOrganization.id),
        value: observingOrganization.name,
        title: observingOrganization.name,
      };
    }
    if (selectedPartnerName && (isDistributor
        || isEmployer
        || isSubsidiary
        || chosenDistributorId
        || chosenEmployerId
        || chosenSubsidiaryId)) {
      return {
        key: chosenPartnerId || partnerId,
        value: selectedPartnerName,
        title: selectedPartnerName,
      };
    }
    return undefined;
  }, [
    selectedPartnerName,
    isDistributor,
    isEmployer,
    isSubsidiary,
    observingOrganization,
    isPartner,
    chosenPartnerId,
    chosenDistributorId,
    chosenEmployerId,
    chosenSubsidiaryId,
    partnerId,
  ]);
  const {
    data: partnersData,
    isLoading: isLoadingPartners,
  } = useOrganizationsQuery({
    searchValue: searchPartner,
    enable: isSystem,
  });

  const { name: downloadedDistributorName } = useGetOrgName({
    id: isEmployer || isSubsidiary || chosenEmployerId || chosenSubsidiaryId
      ? distributorId
      : undefined,
    enabled: true,
  });
  const selectedDistributorName = useMemo(
    () => {
      if (isDistributor) return observingOrganization.name;

      return downloadedDistributorName;
    },
    [downloadedDistributorName, observingOrganization.name, isDistributor],
  );
  const autoSelectedDistributor = useMemo(() => {
    if (isDistributor) {
      return {
        key: toString(observingOrganization.id),
        value: observingOrganization.name,
        title: observingOrganization.name,
      };
    }
    if (selectedDistributorName
        && (chosenEmployerId
          || chosenSubsidiaryId
          || isEmployer
          || isSubsidiary)) {
      return {
        key: chosenDistributorId
          || distributorId,
        value: selectedDistributorName,
        title: selectedDistributorName,
      };
    }
    return undefined;
  }, [
    selectedDistributorName,
    chosenDistributorId,
    distributorId,
    isDistributor,
    isEmployer,
    isSubsidiary,
    observingOrganization,
    chosenEmployerId,
    chosenSubsidiaryId,
  ]);
  const {
    data: distributorsData,
    isLoading: isLoadingDistributors,
  } = useOrganizationsQuery({
    searchValue: searchDistributor,
    type: OrganizationTypes.distributor,
    enable: !!chosenPartnerId && (isSystem || isPartner),
    orgPath: orgPathForDistributors,
  });

  const { name: downloadedEmployerName } = useGetOrgName({
    id: isSubsidiary || chosenSubsidiaryId
      ? chosenEmployerId || employerId
      : undefined,
    enabled: true,
  });
  const selectedEmployerName = useMemo(
    () => {
      if (isEmployer) return observingOrganization.name;

      return downloadedEmployerName;
    },
    [downloadedEmployerName, isEmployer, observingOrganization.name],
  );
  const autoSelectedEmployer = useMemo(() => {
    if (isEmployer) {
      return {
        key: toString(observingOrganization.id),
        value: observingOrganization.name,
        title: observingOrganization.name,
      };
    }
    if (selectedEmployerName
      && (chosenSubsidiaryId
        || isSubsidiary)) {
      return {
        key: chosenEmployerId || employerId,
        value: selectedEmployerName,
        title: selectedEmployerName,
      };
    }
    return undefined;
  }, [
    selectedEmployerName,
    isSubsidiary,
    observingOrganization,
    isEmployer,
    chosenSubsidiaryId,
    employerId,
    chosenEmployerId,
  ]);
  const {
    data: employersData,
    isLoading: isLoadingEmployers,
  } = useOrganizationsQuery({
    searchValue: searchEmployer,
    type: OrganizationTypes.company,
    enable: !!chosenDistributorId
      && (isSystem || isPartner || isDistributor),
    orgPath: orgPathForEmployers,
  });

  const autoSelectedSubsidiary = useMemo(
    () => {
      if (isSubsidiary) {
        return {
          key: subsidiaryId,
          value: observingOrganization.name,
          title: observingOrganization.name,
        };
      }
      return undefined;
    },
    [observingOrganization.name, subsidiaryId, isSubsidiary],
  );
  const {
    data: subsidiariesData,
    total: totalSubsidiaries,
    isLoading: isLoadingSubsidiaries,
  } = useOrganizationsQuery({
    searchValue: searchSubsidiary,
    type: OrganizationTypes.subsidiary,
    enable: !!chosenEmployerId
      && (isSystem || isPartner || isDistributor || isEmployer),
    orgPath: orgPathForSubsidiaries,
  });

  const idForPayrollGroups = useMemo(() => {
    if (chosenSubsidiaryId) return chosenSubsidiaryId || subsidiaryId;
    if (chosenEmployerId && totalSubsidiaries > 0) return chosenSubsidiaryId || subsidiaryId;
    return chosenEmployerId || employerId;
  }, [totalSubsidiaries, chosenSubsidiaryId, subsidiaryId, chosenEmployerId, employerId]);
  const { data } = useGetPayrollGroupsQuery(idForPayrollGroups);

  const payrollGroupCodeOptions = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        key: item?.code,
        value: item?.code,
        title: item?.code,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (ssn && ssn.length >= 4 && ssn.charAt(3) !== SSN_DELIMITER_SIGN) {
      setCurrentSsnMask(SSN_MASK_WITHOUT_DASHES);
    }
    if (ssn && ssn.length <= 3) {
      setCurrentSsnMask(SSN_MASK_WITH_DASHES);
    }
  }, [ssn]);

  useEffect(() => {
    if (isSystem) {
      setCurrentPartners(partnersData);
    } else if (autoSelectedPartner) {
      setCurrentPartners([autoSelectedPartner as Option]);
    }
  }, [partnersData, isSystem, autoSelectedPartner]);

  useEffect(() => {
    if (isSystem || isPartner) {
      setCurrentDistributors(distributorsData);
    } else if (autoSelectedDistributor) {
      setCurrentDistributors([autoSelectedDistributor as Option]);
    }
  }, [
    isSystem,
    isPartner,
    distributorsData,
    autoSelectedDistributor,
  ]);

  useEffect(() => {
    if (isSystem || isPartner || isDistributor) {
      setCurrentEmployers(employersData);
    } else if (autoSelectedEmployer) {
      setCurrentEmployers([autoSelectedEmployer as Option]);
    }
  }, [
    isSystem,
    isPartner,
    isDistributor,
    employersData,
    autoSelectedEmployer,
  ]);

  useEffect(() => {
    if (isSystem || isPartner || isDistributor || isEmployer) {
      setCurrentSubsidiaries(subsidiariesData as Option[]);
    } else if (autoSelectedSubsidiary) {
      setCurrentSubsidiaries([autoSelectedSubsidiary as Option]);
    }
  }, [
    isSystem,
    isPartner,
    isDistributor,
    isEmployer,
    subsidiariesData,
    autoSelectedSubsidiary,
  ]);

  const onPartnerChanging = useCallback(() => {
    if (isPartner || isDistributor || isEmployer || isSubsidiary) return;
    if (chosenDistributorId) setCleaningInitiatorDistributor(!cleaningInitiatorDistributor);
    if (chosenEmployerId) setCleaningInitiatorEmployer(!cleaningInitiatorEmployer);
    if (chosenSubsidiaryId) setCleaningInitiatorSubsidiary(!cleaningInitiatorSubsidiary);
  }, [
    cleaningInitiatorDistributor,
    cleaningInitiatorEmployer,
    cleaningInitiatorSubsidiary,
    chosenDistributorId,
    chosenEmployerId,
    chosenSubsidiaryId,
    setCleaningInitiatorDistributor,
    setCleaningInitiatorEmployer,
    setCleaningInitiatorSubsidiary,
    isPartner,
    isDistributor,
    isEmployer,
    isSubsidiary,
  ]);
  useEffect(() => {
    if (!chosenPartnerId) onPartnerChanging();
  }, [chosenPartnerId, onPartnerChanging]);

  const onDistributorChanging = useCallback(() => {
    if (isDistributor || isEmployer || isSubsidiary) return;
    if (chosenEmployerId) setCleaningInitiatorEmployer(!cleaningInitiatorEmployer);
    if (chosenSubsidiaryId) setCleaningInitiatorSubsidiary(!cleaningInitiatorSubsidiary);
  }, [
    cleaningInitiatorEmployer,
    cleaningInitiatorSubsidiary,
    chosenEmployerId,
    chosenSubsidiaryId,
    setCleaningInitiatorEmployer,
    setCleaningInitiatorSubsidiary,
    isDistributor,
    isEmployer,
    isSubsidiary,
  ]);
  useEffect(() => {
    if (!chosenDistributorId) onDistributorChanging();
  }, [chosenDistributorId, onDistributorChanging]);

  const onEmployerChanging = useCallback(() => {
    if (isEmployer || isSubsidiary) return;
    if (chosenSubsidiaryId) setCleaningInitiatorSubsidiary(!cleaningInitiatorSubsidiary);
  }, [
    chosenSubsidiaryId,
    cleaningInitiatorSubsidiary,
    setCleaningInitiatorSubsidiary,
    isEmployer,
    isSubsidiary,
  ]);
  useEffect(() => {
    if (!chosenEmployerId) onEmployerChanging();
  }, [chosenEmployerId, onEmployerChanging]);

  const handleSetPartnerSearch = useCallback((value: string) => {
    setSearchPartner(value);
  }, []);

  const handleSetDistributorSearch = useCallback((value: string) => {
    setSearchDistributor(value);
  }, []);

  const handleSetEmployerSearch = useCallback((value: string) => {
    setSearchEmployer(value);
  }, []);
  const handleSetSubsidiarySearch = useCallback((value: string) => {
    setSearchSubsidiary(value);
  }, []);

  const hasActiveSubsidiaries = useMemo(
    () => totalSubsidiaries > 0 && chosenEmployerId,
    [totalSubsidiaries, chosenEmployerId],
  );

  useEffect(() => {
    handleSetSubsidiarySearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenEmployerId]);

  const fields = useMemo(() => ([
    {
      name: FIELDS.PARTNER,
      type: FieldTypes.AutocompleteDropdown,
      label: 'TPA/Partner',
      placeholder: 'Enter Partner',
      ...isPartner || isDistributor || isEmployer || isSubsidiary ? {
        disabled: true,
        ...autoSelectedPartner ? { completeValue: autoSelectedPartner.key } : {},
      } : {},
      validator: yup.string().required(REQUIRED_TEXT),
      options: currentPartners,
      showRequireIcon: !isPartner && !isEmployer,
      onSearch: handleSetPartnerSearch,
      isLoading: isLoadingPartners,
      onChange: (val?: string) => {
        if (val) onPartnerChanging();
      },
    },
    {
      name: FIELDS.DISTRIBUTOR,
      type: FieldTypes.AutocompleteDropdown,
      label: 'Distributor',
      placeholder: 'Enter Distributor',
      ...isDistributor || isEmployer || isSubsidiary || !chosenPartnerId ? {
        disabled: true,
        ...autoSelectedDistributor ? { completeValue: autoSelectedDistributor.key } : {},
      } : {},
      validator: yup.string().required(REQUIRED_TEXT),
      options: currentDistributors,
      showRequireIcon: !isDistributor && !isEmployer && !isSubsidiary,
      onSearch: handleSetDistributorSearch,
      isLoading: isLoadingDistributors,
      cleaningInitiator: cleaningInitiatorDistributor,
      onChange: (val: string) => {
        if (val) onDistributorChanging();
      },
    },
    {
      name: FIELDS.EMPLOYER,
      type: FieldTypes.AutocompleteDropdown,
      label: 'Employer',
      placeholder: 'Enter Employer',
      ...isEmployer || isSubsidiary || !chosenDistributorId ? {
        disabled: true,
        ...autoSelectedEmployer ? { completeValue: autoSelectedEmployer.key } : {},
      } : {},
      validator: yup.string().required(REQUIRED_TEXT),
      options: currentEmployers,
      showRequireIcon: !isEmployer && !isSubsidiary,
      onSearch: handleSetEmployerSearch,
      isLoading: isLoadingEmployers,
      cleaningInitiator: cleaningInitiatorEmployer,
      onChange: (val: string) => {
        if (val) onEmployerChanging();
      },
    },
    {
      name: FIELDS.SUBSIDIARY,
      type: FieldTypes.AutocompleteDropdown,
      label: 'Subsidiary',
      placeholder: hasActiveSubsidiaries ? 'Enter Subsidiary' : 'No active subsidiaries',
      ...hasActiveSubsidiaries ? {
        showRequireIcon: true,
      } : {},
      ...hasActiveSubsidiaries ? {
        validator: yup.string().required(REQUIRED_TEXT),
      } : {},
      ...isSubsidiary || !chosenEmployerId ? {
        disabled: true,
        ...autoSelectedSubsidiary ? { completeValue: autoSelectedSubsidiary.key } : {},
      } : {},
      ...!hasActiveSubsidiaries ? {
        disabled: true,
      } : {},
      options: currentSubsidiaries,
      onSearch: handleSetSubsidiarySearch,
      isLoading: isLoadingSubsidiaries,
      cleaningInitiator: cleaningInitiatorSubsidiary,
    },
    {
      name: FIELDS.ID_SOURCE,
      type: FieldTypes.Dropdown,
      label: 'ID source',
      disabled: true,
      placeholder: 'Enter ID source',
      defaultValue: idSourceId || '',
      options: [
        {
          key: idSourceId,
          title: idSourceId,
          value: idSourceId,
        },
      ],
    },
    ...idSourceId === SourceIds.EEID
      ? [{
        name: FIELDS.EMPLOYER_EMPLOYEE_ID,
        type: FieldTypes.Text,
        label: 'Employer Employee ID',
        showRequireIcon: true,
        placeholder: 'Enter Employer Employee ID',
        validator: employeeIdValidator('Employer'),
        defaultValue: '',
        onChangeValues: clearEmployeeIdExistsValidation,
      }]
      : [],
    ...idSourceId === SourceIds.PEID
      ? [{
        name: FIELDS.PARTNER_EMPLOYEE_ID,
        type: FieldTypes.Text,
        label: 'Partner Employee ID',
        showRequireIcon: true,
        placeholder: 'Enter Partner Employee ID',
        validator: employeeIdValidator('Partner'),
        defaultValue: '',
        onChangeValues: clearEmployeeIdExistsValidation,
      }]
      : [],
    ...idSourceId === SourceIds.SSN
      ? [{
        name: FIELDS.SSN,
        type: FieldTypes.Mask,
        showRequireIcon: true,
        label: 'SSN',
        formatChars: {
          8: REGEXPS.NUMBER_AND_DASH,
        },
        mask: currentSsnMask,
        placeholder: 'Enter SSN',
        validator: ssnValidator,
        onChangeValues: clearInternalError,
        defaultValue: '',
      }]
      : [],
    ...payrollGroupCodeOptions.length ? [{
      name: 'payrollGroupCode',
      showRequireIcon: true,
      type: FieldTypes.Dropdown,
      label: 'Payroll Group Code',
      options: payrollGroupCodeOptions,
      placeholder: 'Select Payroll Group Code',
      singleMode: true,
      validator: VALIDATORS.REQUIRED_STRING,
    }] : [],
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  ]), [
    isEmployer,
    isPartner,
    currentPartners,
    currentEmployers,
    handleSetPartnerSearch,
    isLoadingPartners,
    handleSetEmployerSearch,
    handleSetSubsidiarySearch,
    isLoadingEmployers,
    idSourceId,
    currentSsnMask,
    currentSubsidiaries,
    isLoadingSubsidiaries,
    isSubsidiary,
    currentDistributors,
    handleSetDistributorSearch,
    isDistributor,
    isLoadingDistributors,
    autoSelectedSubsidiary,
    autoSelectedDistributor,
    autoSelectedEmployer,
    autoSelectedPartner,
    chosenDistributorId,
    chosenEmployerId,
    chosenPartnerId,
    // hasSubsidiaryInEnv,
    hasActiveSubsidiaries,
    cleaningInitiatorDistributor,
    cleaningInitiatorEmployer,
    cleaningInitiatorSubsidiary,
    onPartnerChanging,
    onDistributorChanging,
    onEmployerChanging,
    ssnValidator,
    employeeIdValidator,
    payrollGroupCodeOptions,
  ]);

  return fields as Field[];
};

export default useEmployeeSetupIdentifiersModalFields;
