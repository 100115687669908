import { useEffect,useState } from 'react';
import { usePaginationParams } from '@common-fe/common-fe';

import { DefaultSortTypesEnum, OptionKey } from '@/common/types';

const useListPagination = () => {
  const {
    page, setPage, setPerPage,
    perPage, searchString, setSearchString,
    currentSearch,
  } = usePaginationParams();

  const [statuses, setStatuses] = useState<OptionKey[]>([]);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const [sortBy, setSortBy] = useState<OptionKey>(DefaultSortTypesEnum.DESC);

  useEffect(() => {
    setPage(1);
  }, [setPage, currentSearch, dateRange, statuses]);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    searchString,
    currentSearch,
    setSearchString,
    statuses,
    setStatuses,
    dateRange,
    setDateRange,
    sortBy,
    setSortBy,
  };
};

export default useListPagination;
