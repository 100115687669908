import React, { useState } from 'react';
import { PopupWrapper } from '@common-fe/common-fe';

import globalTheme from '@/styles/theme';

interface Props {
  content: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const BreadcrumbsPopup: React.FC<Props> = ({
  content, children, style,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <PopupWrapper
      controlledVisible={isVisible}
      onVisible={setIsVisible}
      style={{
        minWidth: 'max-content',
        padding: globalTheme.spacings.spacing12,
        boxShadow: globalTheme.shadows.active['box-shadow'],
        transform: `translate(calc(-50% + ${globalTheme.spacings.spacing16}))`,
        zIndex: 1000,
        ...style,
      }}
      buttonStyle={{ fontFamily: 'inherit' }}
      content={content}
    >
      {children}
    </PopupWrapper>
  );
};
