import React, { useMemo } from 'react';
import {
  Box, ChevronDownIcon,
  ChevronUpIcon, formatSnakeCaseToGeneral,
  Inscription, } from '@common-fe/common-fe';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { DEFAULT_DATE_TIME_FORMAT } from '@/common/constants';
import { unCamelCase } from '@/utils';

import { EllipsisText } from '../../ActivityLog.styles';
import { ActivityLog } from '../useGetActivityLogs.query';

import Changeset from './Changeset';

const unformattedFields = ['API'];

const Wrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border2};
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.03);
`;

interface Props {
  data: ActivityLog;
  isExpanded: boolean;
  setExpandedLogId: (val: string) => void;
}

const LogItem: React.FC<Props> = ({ data, isExpanded, setExpandedLogId }) => {
  const source = useMemo(() => {
    if (unformattedFields.some((field) => data.updateSource === field)) {
      return data.updateSource;
    }

    return formatSnakeCaseToGeneral(data.updateSource);
  }, [data.updateSource]);

  return (
    <Wrapper
      background="canvas"
      pad={{ vertical: 'spacing18', horizontal: 'spacing24' }}
      round="8px"
    >
      <Box direction="row" align="center" onClick={() => setExpandedLogId(isExpanded ? '' : data.id)}>
        <Box
          width="3%"
          justify="center"
          align="center"
        >
          { isExpanded ? (
            <ChevronUpIcon color="textTitle" />
          ) : (
            <ChevronDownIcon color="textTitle" />
          )}
        </Box>
        <Box width="22%" pad={{ left: 'spacing16' }}>
          <Inscription lineHeight="22px" size="16px" color="textBody">
            {dayjs(data.changedAt).format(DEFAULT_DATE_TIME_FORMAT)}
          </Inscription>
        </Box>
        <Box width="22%" pad={{ left: 'spacing16' }}>
          <Inscription weight="bold" lineHeight="20px" color="textBody">
            {capitalize(unCamelCase(data.entityName))}
          </Inscription>
        </Box>
        <Box width="27%" pad={{ left: 'spacing16' }} direction="row" align="center">
          <EllipsisText color="textBody" lineHeight="20px" title={data.changedByName} style={{ maxWidth: '170px' }}>
            {data.changedByName}
          </EllipsisText>
          <Box
            round="xxsmall"
            background="nonClaimBg"
            height="18px"
            justify="center"
            align="center"
            pad={{ horizontal: '3px' }}
            margin={{ left: '7px' }}
          >
            <Inscription
              size="10px"
              lineHeight="18px"
              weight={500}
              color="textBody"
              style={{ letterSpacing: '0.4px' }}
            >
              {capitalize(formatSnakeCaseToGeneral(data.actorType))}
            </Inscription>
          </Box>
        </Box>
        <Box width="18%" pad={{ left: 'spacing16' }}>
          <Inscription color="textBody" lineHeight="20px">{source}</Inscription>
        </Box>
        <Box width="8%" pad={{ left: 'spacing16' }}>
          <Inscription lineHeight="20px" color="textSecondary">
            {data.changesCount}
          </Inscription>
        </Box>
      </Box>

      {isExpanded ? (
        <Box margin={{ top: 'spacing8', bottom: 'spacing6' }}>
          <Changeset
            id={data.id}
            type={data.actionType}
            source={source}
            planName={data.planName}
          />
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default LogItem;
