import { useMemo,useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { api } from '@/api';
import PATHS from '@/common/paths';
import regexp from '@/common/regexp';
import { formatBankDetails } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BanksList/useBanksList.query';
import { BankInfo } from '@/modules/employer/components/SetupEmployer/SetupEmployer.types';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';
import { getEncryptionByPubKey } from '@/utils/hooks/useEncryptData';

let bankAccountValue: BankInfo;
interface MutateAsynFunc {
  (props: BankInfo): Promise<AxiosResponse>
}
const DEFAULT_ERROR_MODAL_PROPS = {
  header: 'The server encountered an error processing the request',
  helptext: `It doesn't appear to have affected your data,
    but we cannot save the Employer with the latest records.
    Our technical staff have been automatically notified and
    will be looking into this with the utmost urgency.`,
  buttonText: 'Close',
  buttonTextTryAgain: 'Try again',
  visible: true,
};

export const formatBankDetailsToCreateAccount = async (
  newValue: BankInfo,
  organizationId: string,
  organizationPath?: string,
  isCreating: boolean = false,
) => {
  let encryptData = {};
  if (!regexp.START_SYMBOL_REG.test(newValue.number)) {
    encryptData = await getEncryptionByPubKey({
      number: newValue.number,
    });
  }
  return {
    description: newValue.description,
    account_category: newValue.accountCategory,
    routing_transit_number: newValue.routingTransitNumber,
    ...(
      regexp.START_SYMBOL_REG.test(newValue.number)
        ? {}
        : encryptData
    ),
    ...(isCreating ? {
      organization_path: organizationPath || '',
      organization_id: organizationId,
    } : {}),
  };
};

const useBanksListQuery = (organizationPath?: string, onSuccess?: () => void) => {
  const [errorModal, setErrorModal] = useState(false);

  const { params } = useRouteMatch<{ id: string }>();
  const organizationId = useMemo(() => params.id, [params.id]);
  const { bankAccountCategories } = useStore();
  const setBankInformation = useStore((state) => state.setBankInformation);
  const setBankAccountCategories = useStore((state) => state.setBankAccountCategories);
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleSave = (mutateAsync: MutateAsynFunc) => async (newValue: BankInfo) => {
    setLoading(true);

    const isUpdating = Boolean(newValue.id);

    if (isUpdating) {
      await mutateAsync(newValue);

      return;
    }

    const { data: newBankInfo = {} } = await mutateAsync(newValue);
    const formatedBankInfo = formatBankDetails(newBankInfo);

    setBankInformation(formatedBankInfo);
  };
  const { mutateAsync } = useMutation(
    async (newValue: BankInfo) => {
      const { id } = newValue;

      bankAccountValue = newValue;

      if (id) {
        return api.put(`${PATHS.BANK_INFORMATION}/${id}`,
          await formatBankDetailsToCreateAccount(newValue, organizationId, organizationPath));
      }
      return api.post(
        PATHS.BANK_INFORMATION,
        await formatBankDetailsToCreateAccount(newValue, organizationId, organizationPath, true),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.BANK_INFORMATION_BY_ID(organizationId));
        setLoading(false);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError: ({ response }) => {
        if (response?.status && response?.status !== 400 && response?.status !== 401) {
          setErrorModal(true);
        } else {
          setErrorModal(false);
        }

        setLoading(false);
      },
    },
  );

  return {
    errorModal,
    setErrorModal,
    errorModalProps: DEFAULT_ERROR_MODAL_PROPS,
    bankAccountCategories,
    isLoading,
    retry: () => handleSave(mutateAsync)(bankAccountValue),
    setBankAccountCategories,
    save: handleSave(mutateAsync),
  };
};

export default useBanksListQuery;
