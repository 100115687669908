import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import {
  ActivityLogChangesetPayload,
  Changeset,
  formatAttribute,
  formatData,
  formatValue,
} from './common';

const useGetActivityLogChangesetQuery = (changesetId?: string) => {
  const { data, isLoading } = useQuery(
    PATHS.ACTIVITY_LOG_CHANGESET(changesetId),
    () => api.get<ActivityLogChangesetPayload[]>(PATHS.ACTIVITY_LOG_CHANGESET(changesetId)),
    {
      enabled: Boolean(changesetId),
      onError: () => {}
    }
  );
  const formattedData = useMemo(() => formatData(data?.data || [])?.map((item) => ({
    id: item?.id,
    changesetId,
    attribute: formatAttribute(item.fieldName),
    previous: formatValue(item.fieldName, item.oldValue),
    updated: formatValue(item.fieldName, item.newValue),
  } as Changeset)), [data, changesetId]);
  return {
    formattedData,
    isLoading,
  };
};

export default useGetActivityLogChangesetQuery;
