import { useMemo } from 'react';

import { useAdditionalFields } from '../Additional/hooks';
// import { usePlanYearFields } from '../PlanYear/hooks';
import { useCardOfferedFields } from '../CardOffered/hooks';
import { usePlanNameFields } from '../PlanName/hooks';

export default (isActiveMode?: boolean, isTemplateMode?: boolean) => {
  const planNameFields = usePlanNameFields(isActiveMode, isTemplateMode);

  const cardOfferedFields = useCardOfferedFields(isActiveMode, isTemplateMode);
  const additionalFields = useAdditionalFields(isActiveMode, isTemplateMode);
  const fields = useMemo(() => ([
    ...planNameFields,
    ...planNameFields,
    // ...planYearFields,
    ...cardOfferedFields,
    ...additionalFields,
  ]), [additionalFields, cardOfferedFields, planNameFields]);
  return fields;
};
