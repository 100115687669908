import { useMemo } from 'react';

import { useEmailConfigurationStore } from '@/modules/employer/components/SetupEmployer/Communication/EmailConfiguration/stores';
import useLinkManagementStore from '@/modules/employer/components/SetupEmployer/LinkManagement/useLinkManagementStore.store';
import useMobileAppCustomizationStore, { MobileAppCustomizationPayload } from '@/modules/employer/components/SetupEmployer/MobileAppCustomization/store/useMobileAppCustomization.store';

import { NotificationPriorityPayload } from '../../Communications/Communications.types';
import useCommunicationChannelStore from '../../Communications/stores/useNotificationPriority.store';
import useCustomReports, { CustomReportsPayload } from '../CustomReports/stores/useCustomReports.store';
import useEmployerInfoStore, { EmployerInfoPayload } from '../EmployerInfo/stores/useEmployerInfo.store';
import useGeneralInfoStore, { GeneralInfoPayload } from '../GeneralInfo/stores/useGeneralInfo.store';
import useEmployeeClaimAutopayStore, { EmployeeClaimAutopayPayload } from '../HealthPlanClaims/stores/useEmployeeClaimAutopay.store';
import { useOrganizationInfoStore } from '../OrganizationInfo/stores';
import { OrganizationInfo } from '../OrganizationInfo/stores/useOrganizationInfo.store';
import usePreferredMailStore, { PreferredMailingAddressPayload } from '../PreferredMailingAddress/stores/usePreferredMailingAddress.store';
import usePreferredPhysicalStore, { PreferredPhysicalAddressPayload } from '../PreferredPhysicalAddress/stores/usePreferredPhysicalAddress.store';
import useSecurityStore, { SecurityPayload } from '../Security/stores/useSecurity.store';

export type PersonalInformationPayload = GeneralInfoPayload & PreferredMailingAddressPayload
& PreferredPhysicalAddressPayload & EmployerInfoPayload & SecurityPayload & CustomReportsPayload 
& NotificationPriorityPayload & OrganizationInfo & EmployeeClaimAutopayPayload & MobileAppCustomizationPayload;

export default () => {
  const generalInfoState = useGeneralInfoStore((state) => state.state);
  const preferredMailState = usePreferredMailStore((state) => state.state);
  const customReports = useCustomReports((state) => state.state);
  const preferredPhysicalState = usePreferredPhysicalStore((state) => state.state);
  const employerInfoState = useEmployerInfoStore((state) => state.state);
  const securityState = useSecurityStore((state) => state.state);
  const communicationState = useCommunicationChannelStore((state) => state.state);
  const emailConfigurationState = useEmailConfigurationStore((state) => state.state);
  const linkManagementStore = useLinkManagementStore((state) => state.state);
  const organizationState = useOrganizationInfoStore((state) => state.state);
  const employeeClaimAutopayState = useEmployeeClaimAutopayStore((state) => state.state);
  const mobileAppCustomizationState = useMobileAppCustomizationStore((state) => state.state);

  const formattedState = useMemo(() => ({
    ...generalInfoState,
    ...customReports,
    ...preferredMailState,
    ...emailConfigurationState,
    ...linkManagementStore,
    ...preferredPhysicalState,
    ...employerInfoState,
    ...securityState,
    ...communicationState,
    ...organizationState,
    ...employeeClaimAutopayState,
    ...mobileAppCustomizationState
  }), [employerInfoState, generalInfoState, preferredPhysicalState, linkManagementStore,
    preferredMailState, securityState, communicationState, organizationState,customReports,
    employeeClaimAutopayState, emailConfigurationState, mobileAppCustomizationState]);
  return formattedState;
};
