import React, { useEffect, useMemo } from 'react';
import {
  Box, Heading, Sidebar, Text,
} from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
// import { CardTypesEnum } from '@common-fe/common-fe';
import { useSidebarStore } from '@/modules/core/components/Sidebar/store/useSidebarStore';
import { useHasAccess } from '@/modules/core/hooks';
import {
  TOC_CONTAINER_UI_ID, TOC_MODULE_UI_ID,
} from '@/modules/core/hooks/useDetectActiveTOCModule';
import { PLAN_MODULES } from '@/modules/plan/plan.constants';
import { usePlanTypesQuery } from '@/modules/plan/queries';

import { PlanStatuses } from '../../plan.types';
import { usePlanSetupHiddenModules } from '../hooks';
// import PlanTypeIcon from './PlanTypeIcon';
import { usePlanNameStore } from '../PlanDefinition/PlanName/stores';

import { usePlanModules, usePlanProgress, useProgressScrollState } from './hooks';
import {
  ItemWrapper,
  ListContainer,
  StyledButton,
  StyledText,
} from './Sidebar.styles';

interface Props {
  planStatus?: PlanStatuses;
  scrollToSection?: (data: { module?: string, section?: string }) => void;
  isTemplateMode?: boolean;
  isPlanActive?: boolean;
}

const ProgressSidebar: React.FC<Props> = ({
  scrollToSection,
  isTemplateMode,
  isPlanActive,
}) => {
  const { total, percent, passed } = usePlanProgress();
  const hiddenModules = usePlanSetupHiddenModules();
  const isHasAccess = useHasAccess([
    { permission: Permissions.EDIT_DRAFT_PLAN },
    { permission: Permissions.ACTIVATE_PLAN },
  ]);
  const planType = usePlanNameStore((state) => state.state.planType);
  const planTypes = usePlanTypesQuery();
  const currentPlanType = useMemo(() => planTypes.find(
    (type) => type.key === planType,
  ), [planType, planTypes]);

  const config = usePlanModules((isPlanActive && isTemplateMode) || !isHasAccess ?
    [PLAN_MODULES.FINAL_SETUP, ...hiddenModules] : hiddenModules,
  );

  const { expandSidebar } = useSidebarStore();
  const { activeModule } = useProgressScrollState();

  useEffect(() => {
    expandSidebar(false);
    return () => {
      expandSidebar(true);
    };
  }, [expandSidebar]);

  return (
    <Sidebar
      width={{
        width: 'xmedium',
        min: 'xmedium',
      }}
      pad={{
        top: 'spacing24',
        left: 'spacing12',
      }}
      height="100vh"
      background="module"
      gap=""
      header={(
        <Heading level={4} size="medium" margin="none" color="textTitle">
          {currentPlanType?.title || planType}
        </Heading>
      )}
    >
      <Box margin={{ top: 'spacing12', bottom: 'spacing16' }}>
        <Text
          data-testid="plan-configuration-progress"
          weight={500}
          size="small"
          color={percent === 100 ? 'textAccent' : 'textSecondary'}
        >
          {passed}/{total} ({percent}%)
        </Text>
      </Box>
      <Box
        background="border2"
        pad="spacing12"
        margin={{ right: 'spacing12' }}
        round="checkboxRound"
      >
        <ListContainer>
          <Box id={TOC_CONTAINER_UI_ID}>
            {Object.keys(config).map((anchorId) => (
              <ItemWrapper
                key={anchorId}
                id={TOC_MODULE_UI_ID(anchorId)}
                data-testid={`select-plan-module-${anchorId}`}
                style={{
                  boxShadow: 'none',
                }}
                // align="center"
                justify="center"
                pad={{ bottom: 'spacing8' }}
                onClick={() => (scrollToSection ? scrollToSection({ module: anchorId }) : null)}
              >
                <StyledButton
                  active={activeModule === anchorId}
                >
                  <StyledText
                    size="medium"
                    weight={activeModule === anchorId ? 'bold' : 'normal'}
                  >
                    {config[anchorId].title}
                  </StyledText>
                </StyledButton>
              </ItemWrapper>
            ))}
          </Box>
        </ListContainer>
      </Box>
    </Sidebar>
  );
};

export default React.memo(ProgressSidebar);
