import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AppButton,
  Box, FlexList, Heading,
  Hint, InfoOutlineIcon,   ListOrderIcon,
  PaginationBar,   Preloader,
  SearchInput,   Text, } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { DefaultSortTypesEnum,ListItemBase } from '@/common/types';
import HeaderLink from '@/components/elements/HeaderLink';
import { useHasAccess } from '@/modules/core/hooks';
import { useListPagination } from '@/modules/employer/hooks';

import ListPlaceholder from '../ListPlaceholder';
import { EnrollmentFilterParams, EnrollmentMatrixItem } from '../ProcessingDefinition.types';

import CreateEnrollmentMatrixModal from './CreateEnrollmentMatrixModal/CreateEnrollmentMatrixModal';
import { DisplayEntitiesPopup } from './DisplayEntitiesPopup/DisplayEntitiesPopup';
import { useDisplayEntitiesPopupStore } from './DisplayEntitiesPopup/useDisplayEntitiesPopup.store';
import { useEnrollmentMatrixStore } from './stores/useEnrollmentMatrix.store';
import Filter from './Filter';
import { useEnrollmentMatrixes } from './hooks';
import MatrixesList from './MatrixesList';
import { SortByDropdown } from './SortByDropdown';

const StyledInfoIcon = styled(InfoOutlineIcon)`
  opacity: 0.5 ;
  &:hover {
    opacity: 1 ;
  }
`;

interface Props {
  isEmployer?: boolean;
  isPageMode?: boolean;
}

export interface GroupedSelectValues {
  active: boolean;
  expired: boolean;
}

const SORT_BY_PRIORITY_KEY = 'priority';
const SORT_BY_PLAN_TEMPLATE_KEY = 'plan.name';
const SORT_BY_PLAN_YEAR_KEY = 'planYear.name';
const SORT_BY_PROCESSING_RULES_KEY = 'processingRule.name';
const ALLOWED_ELEM_PER_PAGE_KEY = 20;

const options = [
  {
    key: SORT_BY_PRIORITY_KEY,
    title: 'Priority',
    value: SORT_BY_PRIORITY_KEY,
    subOptions: [
      {
        key: `${SORT_BY_PRIORITY_KEY},${DefaultSortTypesEnum.ASC}`,
        title: 'Ascending (1-2-3)',
        value: DefaultSortTypesEnum.ASC,
      },
      {
        key: `${SORT_BY_PRIORITY_KEY},${DefaultSortTypesEnum.DESC}`,
        title: 'Descending (3-2-1)',
        value: DefaultSortTypesEnum.DESC,
      },
    ],
  },
  {
    key: SORT_BY_PLAN_TEMPLATE_KEY,
    title: 'Plan template',
    value: SORT_BY_PLAN_TEMPLATE_KEY,
    subOptions: [
      {
        key: `${SORT_BY_PLAN_TEMPLATE_KEY},${DefaultSortTypesEnum.ASC}`,
        title: 'A-Z',
        value: DefaultSortTypesEnum.ASC,
      },
      {
        key: `${SORT_BY_PLAN_TEMPLATE_KEY},${DefaultSortTypesEnum.DESC}`,
        title: 'Z-A',
        value: DefaultSortTypesEnum.DESC,
      },
    ],
  },
  {
    key: SORT_BY_PLAN_YEAR_KEY,
    title: 'Plan year',
    value: SORT_BY_PLAN_YEAR_KEY,
    subOptions: [
      {
        key: `${SORT_BY_PLAN_YEAR_KEY},${DefaultSortTypesEnum.ASC}`,
        title: 'A-Z',
        value: DefaultSortTypesEnum.ASC,
      },
      {
        key: `${SORT_BY_PLAN_YEAR_KEY},${DefaultSortTypesEnum.DESC}`,
        title: 'Z-A',
        value: DefaultSortTypesEnum.DESC,
      },
    ],
  },
  {
    key: SORT_BY_PROCESSING_RULES_KEY,
    title: 'Processing rule',
    value: SORT_BY_PROCESSING_RULES_KEY,
    subOptions: [
      {
        key: `${SORT_BY_PROCESSING_RULES_KEY},${DefaultSortTypesEnum.ASC}`,
        title: 'A-Z',
        value: DefaultSortTypesEnum.ASC,
      },
      {
        key: `${SORT_BY_PROCESSING_RULES_KEY},${DefaultSortTypesEnum.DESC}`,
        title: 'Z-A',
        value: DefaultSortTypesEnum.DESC,
      },
    ],
  },
];

const EnrollmentMatrix: React.FC<Props> = ({ isEmployer, isPageMode }) => {
  const [filterParams, setFilterParams] = useState<EnrollmentFilterParams>({});
  const [sortByValues, setSortByValues] = useState<[string | null, string | null]>(
    [SORT_BY_PRIORITY_KEY, `${SORT_BY_PRIORITY_KEY},${DefaultSortTypesEnum.ASC}`],
  );
  const [sortByExpiredValues, setSortByExpiredValues] = useState<[string | null, string | null]>(
    [SORT_BY_PRIORITY_KEY, `${SORT_BY_PRIORITY_KEY},${DefaultSortTypesEnum.ASC}`],
  );
  const isFilterApplied = useMemo(() => !_.isEmpty(filterParams), [filterParams]);

  const isCreateAccessible = useHasAccess([
    {
      permission: Permissions.SET_UP_PROCESSING_DEFINITION,
    },
  ]);

  const [isPriorityMode, setPriorityMode] = useState(false);
  const [updatedElement, setUpdatedElement] = useState<EnrollmentMatrixItem>();

  const [cleaningInitiator, runSerchTextCleaning] = useState(false);

  const {
    activePage,
    handleResetActivePage,
  } = useEnrollmentMatrixStore();
  const currentValues = useDisplayEntitiesPopupStore((state) => state.currentValues);
  const setCurrentValues = useDisplayEntitiesPopupStore((state) => state.setCurrentValues);
  const {
    page,
    perPage,
    currentSearch,
    setPage,
    setPerPage,
    searchString,
    setSearchString,
  } = useListPagination(ALLOWED_ELEM_PER_PAGE_KEY);
  const {
    page: expiredPage,
    perPage: expiredPerPage,
    currentSearch: expiredCurrentSearch,
    setPage: setExpiredPage,
    setPerPage: setExpiredSetPerPage,
    searchString: searchExpiredString,
    setSearchString: setExpiredSearchString,
  } = useListPagination(ALLOWED_ELEM_PER_PAGE_KEY);
  const {
    data,
    matrixes,
    matrixesHealthPlan,
    total,
    isLoading,
    expiredData,
    expiredTotal,
    isExpiredLoading,
    currentOrg,
  } = useEnrollmentMatrixes(
    {
      page: page - 1,
      size: perPage,
      name: currentSearch || '',
      sort: sortByValues[1] || '',
      filterParams,
    },
    {
      page: expiredPage - 1,
      size: expiredPerPage,
      name: expiredCurrentSearch || '',
      sort: sortByExpiredValues[1] || '',
    },
  );

  const handleTogglePriorityMode = useCallback(() => {
    setPriorityMode(!isPriorityMode);
    setSortByValues([SORT_BY_PRIORITY_KEY, `${SORT_BY_PRIORITY_KEY},${DefaultSortTypesEnum.ASC}`]);
    setFilterParams({});
    runSerchTextCleaning(true);
  }, [isPriorityMode]);

  useEffect(() => {
    if (activePage) setPage(activePage);
    setTimeout(() => handleResetActivePage(), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const currentNode = useMemo(() => {
    if (isLoading) {
      return (
        <Box pad={{ top: 'spacing24' }}>
          <Preloader testId="enrollment-matrix_loader" />
        </Box>
      );
    }
    if (data?.length === 0) {
      if (isFilterApplied) {
        return (
          <ListPlaceholder testId="enrollment-matrix">
            <Box>
              <Text size="medium" weight="bold" textAlign="center" margin={{ bottom: 'xs' }}>There are no enrollment matrix entities matching your filter results.</Text>
              <Text size="medium" weight="normal" textAlign="center">Please adjust your filtering parameters.</Text>
            </Box>
          </ListPlaceholder>
        );
      }
      return (
        <ListPlaceholder testId="enrollment-matrix">
          There are no Enrollment Matrix entities
        </ListPlaceholder>
      );
    }
    return (
      <Box direction="column" style={{ position: 'relative' }}>
        <MatrixesList
          isEmployer={isEmployer}
          isPriorityMode={isPriorityMode}
          currentOrg={currentOrg}
          values={data || []}
          matrixes={matrixes}
          matrixesHealthPlan={matrixesHealthPlan}
          perPage={perPage}
          setUpdatedElement={setUpdatedElement}
          isCreateAccessible={isCreateAccessible}
        />
        {
          total === 0 ? null : (
            <PaginationBar
              page={page}
              total={total || 0}
              pageSize={perPage}
              onChangePage={setPage}
              onChangePageSize={setPerPage}
            />
          )
        }
      </Box>
    );
  }, [data, isEmployer, isLoading, total, page, isCreateAccessible, matrixesHealthPlan,
    setPerPage, setPage, perPage, isPriorityMode, currentOrg, isFilterApplied, matrixes]);

  const expiredCurrentNode = useMemo(() => {
    if (isExpiredLoading) {
      return (
        <Preloader testId="enrollment-matrix" />
      );
    }
    if (expiredData?.length === 0) {
      return (
        <ListPlaceholder testId="enrollment-matrix">
          There are no Expired Enrollment Matrix entities
        </ListPlaceholder>
      );
    }
    return (
      <Box direction="column" style={{ position: 'relative' }}>
        <MatrixesList
          isEmployer={isEmployer}
          values={expiredData || []}
          isExpired
        />
        {
          expiredTotal === 0 ? null : (
            <PaginationBar
              page={expiredPage}
              total={expiredTotal}
              pageSize={expiredPerPage}
              onChangePage={setExpiredPage}
              onChangePageSize={setExpiredSetPerPage}
            />
          )
        }
      </Box>
    );
  }, [expiredData, expiredPage, expiredPerPage, isEmployer,
    isExpiredLoading, expiredTotal, setExpiredPage, setExpiredSetPerPage]);

  const getHeaders = useCallback((isExired: boolean) => [
    {
      key: 'Org level & name',
      title: 'Org level & name',
      flex: 1.9,
    },
    {
      key: 'Plan template',
      title: 'Plan template',
      flex: 1,
    },
    ...isEmployer ? [{
      key: 'Plan year',
      title: 'Plan year',

      flex: 1,
    }] : [],
    {
      key: 'Processing rule',
      title: 'Processing rule',
      flex: 1,
    },
    {
      key: 'Valid from',
      title: 'Valid from',
      flex: 1,
    },
    {
      key: 'Valid to',
      title: 'Valid to',
      flex: 1,
    },
    ...!isExired ? [{
      key: 'Priority',
      title: 'Priority',
      node: (
        <Hint
          hintAlign="vertical"
          hintElement={(
            <Box direction="row" align="center" height="20px">
              <Text size="small" weight={700}>Priority</Text>
              <Box margin={{ left: 'xxs' }}>
                <StyledInfoIcon size="16px" />
              </Box>
            </Box>
          )}
          marginContent="m"
        >
          <Text>Lowest number has highest priority
            (1 - highest priority, 10 - lowest priority).
            Use the “123” button to change the priority.
          </Text>
        </Hint>
      ),
      flex: 0.7,
    }] : [],
    ...isPriorityMode && !isExired ? [{
      key: 'PriorityButtons',
      title: '',
      flex: 1,
    }] : [],
    ...!isPriorityMode || isExired ? [
      {
        key: 'OptionsButtons',
        title: '',
        flex: isExired ? 0.15 : 0.2,
      },
    ] : [],
  ] as ListItemBase[], [isEmployer, isPriorityMode]);

  return (
    <div style={isPageMode ? { position: 'relative' } : {}}>
      <Box
        direction="column"
        data-testid="enrollemnt-matrix"
        pad={{ bottom: 'l' }}
        border={!isPageMode && { side: 'bottom', size: 'small', color: 'border1' }}
      >
        <Box direction="row" justify="between" align="center">
          {
            isPageMode
              ? (
                <Box pad={{ vertical: 'l' }}>
                  <Heading level={4} size="large" color="textTitle">Enrollment Matrix</Heading>
                </Box>
              ) : (
                <Box margin={{ top: 'l', bottom: 'm' }}>
                  <HeaderLink path={ROUTES.ENROLLMENT_MATRIX} title="Enrollment Matrix" />
                </Box>
              )
          }
          {
            isCreateAccessible && (
              <CreateEnrollmentMatrixModal
                perPage={perPage}
                updatedElement={updatedElement}
                setUpdatedElement={setUpdatedElement}
                title={updatedElement ? 'Update an Enrollment Matrix Entity' : 'Create an Enrollment Matrix Entity'}
              />
            )
          }
        </Box>
        <Box background="module" round="medium">
          {currentValues?.active
            && (
              <FlexList
                testId="enrollment-matrix"
                headers={getHeaders(false)}
                rows={[]}
                footer={currentNode}
                options={!isPriorityMode && isCreateAccessible ? [
                  {
                    name: 'Edit',
                    onClick: () => { },
                  },
                ] : undefined}
              >
                <Box direction="column">
                  {currentValues?.expired && <Text size="xxlarge" color="textTitle">Active</Text>}
                  <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
                    <Text
                      size="large"
                      weight="bold"
                      color="textBody"
                    >Entities: {total || 0}
                    </Text>
                    <Box direction="row">
                      <Box width={{ min: '240px' }}>
                        <SearchInput
                          className="search-input"
                          value={searchString}
                          placeholder="Search"
                          onChange={setSearchString}
                          disabled={isPriorityMode}
                          resetValue={cleaningInitiator}
                        />
                      </Box>
                      <Box direction="row" margin={{ left: 'spacing8' }}>
                        <SortByDropdown
                          testId="enrollmen-matrix-sort-by"
                          title="Sort by"
                          options={options}
                          values={sortByValues}
                          onChange={(values) => setSortByValues(values)}
                          isDisabled={isPriorityMode}
                        />
                        <Box margin={{ left: 's' }}>
                          <Filter
                            isEmployer={isEmployer}
                            isDisabled={isPriorityMode}
                            onChangeValues={setFilterParams}
                          />
                        </Box>
                        <Box margin={{ left: 's' }}>
                          <DisplayEntitiesPopup
                            testId="display_entities_popup"
                            values={currentValues}
                            onChange={(values) => setCurrentValues(values as GroupedSelectValues)}
                            isDisabled={isPriorityMode}
                          />
                        </Box>
                        <Box margin={{ left: 's' }}>
                          <AppButton
                            onlyIcon
                            style={{
                              width: '40px',
                            }}
                            active={isPriorityMode}
                            color="grey"
                            buttonType="secondary"
                            onClick={handleTogglePriorityMode}
                          >
                            <ListOrderIcon />
                          </AppButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </FlexList>
            )}
          {currentValues?.expired
            && (
              <FlexList
                testId="enrollment-matrix"
                headers={getHeaders(true)}
                rows={[]}
                footer={
                  expiredCurrentNode
                }
              >
                <Box>
                  <Text size="xxlarge" color="textTitle">Expired</Text>
                  <Box direction="row" justify="between" align="center" pad={{ bottom: 'spacing12' }}>
                    <Text
                      margin={{ top: 'spacing8', bottom: 'spacing12' }}
                      size="large"
                      weight="bold"
                      color="textBody"
                    >Entities: {expiredData?.length || 0}
                    </Text>
                    <Box direction="row">
                      <Box width={{ min: '240px' }}>
                        <SearchInput
                          className="search-input"
                          value={searchExpiredString}
                          placeholder="Search"
                          onChange={setExpiredSearchString}
                        />
                      </Box>
                      <Box direction="row" margin={{ left: 'spacing8' }}>
                        <SortByDropdown
                          testId="enrollment-expired-matrix-sort-by"
                          title="Sort by"
                          options={options}
                          values={sortByExpiredValues}
                          onChange={(values) => setSortByExpiredValues(values)}
                        />
                        {!currentValues?.active
                          && (
                            <Box margin={{ left: 's' }}>
                              <DisplayEntitiesPopup
                                testId="display_entities_popup"
                                values={currentValues}
                                onChange={
                                  (values) => setCurrentValues(values as GroupedSelectValues)
                                }
                              />
                            </Box>
                          )}
                        {/* <Box margin={{ left: 's' }}>
                          <AppButton
                            onlyIcon
                            width="40px"
                            buttonType="secondary"
                          >
                            <ListOrderIcon />
                          </AppButton>
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </FlexList>
            )}
        </Box>
      </Box>
    </div>
  );
};

export default EnrollmentMatrix;
