import { PartnerMobileApp } from '@/modules/employer/types';
import { baseStoreCreator } from '@/utils/store';

export interface MobileAppCustomizationPayload {
  participantMobileApp?: PartnerMobileApp;
  googlePlayLink?: string;
  appStoreLink?: string;
  mobileAppTheme?: string;
}

export const DEFAULT_VALUES: MobileAppCustomizationPayload = {
  participantMobileApp: undefined,
  googlePlayLink: '',
  appStoreLink: '',
  mobileAppTheme: '',
};

export const useMobileAppCustomizationStore = baseStoreCreator<MobileAppCustomizationPayload>(
  DEFAULT_VALUES,

);

export default useMobileAppCustomizationStore;
