import React from 'react';
import {
  Box, CloseIcon,
  ContextFlexForm, FormContext, RawButton, Text, } from '@common-fe/common-fe';

import { CopayItem } from '../../CopayAdjudication.constant';

import { useCopayItemFields } from './hooks';

interface Props {
  formContext: FormContext;
  index: number;

  isSubmitted?: boolean;
  isViewMode?: boolean;
  isDeletable?: boolean;
  copayIds: string[];
  onRemove?: () => void;
  defaultValues: CopayItem;
}
const CopayItemForm:React.FC<Props> = ({
  formContext, isSubmitted, index = 0, isViewMode, isDeletable, onRemove,
  defaultValues, copayIds,
}) => {
  const fields = useCopayItemFields({
    isViewMode, defaultValues, copayIds,
  });
  return (

    <Box
      background="canvas"
      round="container1Round"
      border={{ color: 'border2', size: 'small' }}
      // pad={{ horizontal: 'spacing24', vertical: 'spacing32' }}
      elevation="default"
      margin={{ bottom: 'l' }}
    >
      <ContextFlexForm
        formTitle={(
          <Box direction="row" justify="between" margin={{ bottom: 'xl' }}>
            <Text size="xxlarge">
              {`Copay ${index + 1}`}
            </Text>
            {
              isDeletable && !isViewMode && (
                <RawButton onClick={onRemove}>
                  <Box direction="row" align="center">

                    <Text color="danger" size="medium" weight="normal" margin={{ right: 'spacing4' }}>
                      Remove
                    </Text>
                    <CloseIcon size="12px" color="danger" />
                  </Box>
                </RawButton>
              )
            }

          </Box>
        )}
        editMode={!isViewMode}
        isModalType
        formContext={formContext}
        isThinMode
        showError={isSubmitted}
        fields={fields}
        contentStyles={{ margin: 0 }}
      />
    </Box>
  );
};

export default CopayItemForm;
