import React, { useCallback } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';

import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useBreadcrumbs, useHistory, useRedirectToHome } from '@/modules/core/hooks';
import { useOrganizationOverridableProperties } from '@/modules/core/hooks/useOrganizationOverridableProperties';
import FileManager from '@/modules/FileManager/FileManager';
import { useAuthStore } from '@/modules/user/stores';

const FileManagerPage = () => {
  const { user } = useAuthStore();
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTES.HOME), [history]);
  const { observingOrganizationData } = useOrganizationOverridableProperties();
  const isFileManagerAccessEnabled = observingOrganizationData?.isFileManagerAccessEnabled;
  useRedirectToHome(Boolean(observingOrganizationData) && !isFileManagerAccessEnabled);

  useBreadcrumbs([{
    route: ROUTES.FILE_MANAGER,
    title: 'File Manager',
  }]);

  if (!user) {
    return (
      <Box width="100%" justify="center" align="center">
        <Preloader />
      </Box>
    );
  }

  return (
    <Access
      accessRequirements={[
        { permission: Permissions.VIEW_FILE_REPORTS },
      ]}
      accessDeniedAction={goHome}
    >
      <FileManager />
    </Access>
  );
};

export default FileManagerPage;
