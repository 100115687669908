import React, { useState } from 'react';
import {
  BankIcon,
  Box,
  NavigationLeftIcon,
  Text,
} from '@common-fe/common-fe';
import styled from 'styled-components';

import { ModalWrapper } from '@/components/wrappers';
import { DuplicationErrorModal } from '@/modules/ReimburseMe/ConfirmationStep/Immediately/DublicationErrorModal/DuplicationErrorModal';

import BankForm from './DirectDeposit/BankForm';
import { BackIcon, StyledButton } from './DirectDeposit/StyledComponents.styles';

const DESCRIPTION = `We will deposit your reimbursement into the personal
  checking account you designate. This can take 2-3 business days.`;

enum Mode {
  instantVerification = 'instantVerification',
  addBankManually = 'addBankManually',
}

const StyledBankIcon = styled(BankIcon)`
  width: ${({ theme }) => theme.spacings.spacing48};
  height: ${({ theme }) => theme.spacings.spacing48};
  fill: ${({ theme }) => theme.colors.button};
`;

interface Props {
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  hideNavigationButton?: boolean;
}

const DirectDeposit: React.FC<Props> = ({ onClose, onBack, onSuccess, hideNavigationButton }) => {
  const [mode, setMode] = useState(Mode.addBankManually);
  const [serverErrorShow, setServerErrorShow] = useState(false);

  return (
    <ModalWrapper
      visible
      onSetVisible={onClose}
    >
      {onBack && !hideNavigationButton && (
        <BackIcon onClick={onBack} data-testid="back-icon-button">
          <NavigationLeftIcon color="iconPrimary"
            size="18px"
          />
        </BackIcon>
      )}

      <Box align="center">
        <Box width="xxsmall" alignSelf="center">
          <StyledBankIcon />
        </Box>

        <Text
          size="2xl"
          weight="bold"
          color="textBody"
          margin={{ top: 'small' }}
        >
          Direct deposit
        </Text>

        <Text
          size="large"
          textAlign="center"
          color="textBody"
          margin={{ top: 'small', bottom: 'medium' }}
        >
          {DESCRIPTION}
        </Text>
      </Box>

      <Box margin={{ bottom: 'spacing24' }}>
        <Box
          background="module"
          direction="row"
          alignSelf="center"
          pad="spacing4"
          round="container1Round"
        >
          <StyledButton
            disabled
            color="module"
            isActive={mode === Mode.instantVerification}
            label="Instant Verification"
            onClick={() => setMode(Mode.instantVerification)}
          />

          <StyledButton
            isActive={mode === Mode.addBankManually}
            label="Add Bank manually"
            onClick={() => setMode(Mode.addBankManually)}
          />
        </Box>
      </Box>

      {mode === Mode.instantVerification && (
        <Text>We can link your bank account instantly</Text>
      )}

      {
        mode === Mode.addBankManually && (
          <BankForm
            back={onBack}
            onSuccess={onSuccess}
            onError={() => setServerErrorShow(true)}
          />
        )
      }
      <DuplicationErrorModal
        visible={serverErrorShow}
        close={() => setServerErrorShow(false)}
      />
    </ModalWrapper>
  );
};

export default DirectDeposit;
