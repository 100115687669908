import React, { useMemo } from 'react';
import { Field, FieldTypes, LabelWithSubfield } from '@common-fe/common-fe';
import * as yup from 'yup';

export const COMMUNICATION_CHANNEL = [
  { value: 'Email', key: 'EMAIL' },
  { value: 'Text message', key: 'TEXT' },
];

export default (communicationConfigChannels?: string[]) => {
  const fields = useMemo<Field[]>(() => [
    {
      name: 'communicationChannel',
      type: FieldTypes.CheckBoxGroup,
      label: (
        <LabelWithSubfield
          title="How would you like to be notified?"
          desc="At least one is required"
        />
      ),
      value: communicationConfigChannels || [],
      showRequireIcon: true,
      options: COMMUNICATION_CHANNEL,
      validator: yup.boolean(),
    },
  ], [communicationConfigChannels]);
  return fields;
};
