import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AccordionItem,
  AppButton,
  Box,
  Checkbox,
  Inscription,
  SearchInput,
  useClickOutside,
  useDebounce,
} from '@common-fe/common-fe';
import { toString } from 'lodash';

import { OrganizationTypes } from '@/common/constants';
import FakeSearchInput from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/FakeSearchInput';
import { Organization } from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetOrganization.query';
import useGetOrganizationHierarchyQuery from '@/modules/CarrierConfig/components/CreateCarrierConfig/CreateCarrierConfigForm/OrgLevelSelector/useGetOrganizationHierarchy.query';
import { useCurrentOrganization } from '@/modules/core/hooks';
import useGetOrgName from '@/modules/employer/queries/useGetOrgName.query';
import currentTheme from '@/styles/theme';

import useClaimTeamsHierarchyQuery from '../../queries/useClaimTeamsHierarchy.query';
import { TeamOrganization } from '../ClaimsProcessors.types';

import { getNumberOfSearchedOrganizations, getTotalString, trimClaimTeamsHierarchy } from './MultipleOrganizationSelector.utils';
import {
  AccordionItemWrapper,
  CheckedChildren,
  NestedOrganizationSelectorLevel,
  SmallPreloader,
  StyledItem,
} from './NestedOrganizationSelectorLevel';
  
const MIN_SEARCH_LENGTH = 3;

  interface Props {
    onChange: (orgs: string[]) => void;
    currentOrganizations?: TeamOrganization[];
    currentTeamId?: string;
  }
  
const MultipleOrganizationSelector: React.FC<Props> = ({ onChange, currentOrganizations, currentTeamId }) => {
  const [searchString, setSearchString] = useState('');
  const search = useDebounce(searchString);
  const searchValue = useMemo(() => {
    if (search.length >= MIN_SEARCH_LENGTH) return search;
    return '';
  }, [search]);
  const [selectedOrgs, setSelectedOrgs] = useState<TeamOrganization[]>([]);
  const [lastAppliedOrgs, setLastAppliedOrgs] = useState<TeamOrganization[]>([]);
  const [checkedChildren, setCheckedChildren] = useState<CheckedChildren[] | undefined>();
  const [lastAppliedCheckedChildren, setLastAppliedCheckedChildren] = useState<CheckedChildren[] | undefined>();
  const [isExternalLoading, setIsExternalLoading] = useState(false);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState<string | undefined>();

  const isSearchUsed = useMemo(() => !!searchValue, [searchValue]);
  
  const [partnerParentId, setPartnerParentId] = useState('');
  const [distributorParentId, setDistributorParentId] = useState('');
  
  const ref = useRef(null);
  const [isShown, setIsShown] = useState(false);
  useClickOutside(ref, () => {
    setIsShown(false);
  });
  
  const {
    observingOrganization: {
      id: observingOrganizationId, type, path, name,
    },
  } = useCurrentOrganization();
  const id = toString(observingOrganizationId);
  
  const isSystemLevel = useMemo(() => type === OrganizationTypes.system,
    [type]);
  const isPartnerLevel = useMemo(() => type === OrganizationTypes.partner,
    [type]);
  const isDistributorLevel = useMemo(() => type === OrganizationTypes.distributor,
    [type]);
  const isCompanyLevel = useMemo(() => type === OrganizationTypes.company,
    [type]);

  const currentOrgLevels = useMemo(() => {
    if (isSystemLevel) return [OrganizationTypes.partner, OrganizationTypes.distributor, OrganizationTypes.company];
    if (isPartnerLevel) return [OrganizationTypes.partner, OrganizationTypes.distributor, OrganizationTypes.company];
    if (isDistributorLevel) return [OrganizationTypes.distributor, OrganizationTypes.company];
    if (isCompanyLevel) return [OrganizationTypes.company];
    return [];
  }, [isSystemLevel, isPartnerLevel, isDistributorLevel, isCompanyLevel]);

  const {
    searchedPartners,
    searchedDistributors,
    isLoading,
  } = useGetOrganizationHierarchyQuery({
    ...isSearchUsed ? { searchValue } : {},
    isCompanyLevel,
    partnerParentId: isPartnerLevel ? id : partnerParentId,
    distributorParentId: isDistributorLevel ? id : distributorParentId,
  });

  const { data } = useClaimTeamsHierarchyQuery(currentTeamId);
  const currentPickedOrgs = useMemo(() => {
    return trimClaimTeamsHierarchy(data, isSystemLevel, isPartnerLevel, isDistributorLevel);
  }, [data, isSystemLevel, isPartnerLevel, isDistributorLevel]);

  const findOrganizationTree = (parentId: number | string, data: Organization[] ): Organization[] | undefined => {
    const findOrganization = (node: Organization): Organization | undefined => {
      if (+node.id === +parentId) {
        return node;
      } else if (node.children) {
        for (const child of node.children) {
          const result = findOrganization(child);
          if (result) {
            return result;
          }
        }
      }
      return undefined;
    };

    for (const org of data) {
      const parentOrganization = findOrganization(org);
      if (parentOrganization) {
        return [parentOrganization];
      }
    }
    return undefined;
  };

  const currentSearchedOrgs = useMemo(() => {
    if (isPartnerLevel) return searchedPartners;
    return findOrganizationTree(id, searchedPartners || []);
  }, [id, isPartnerLevel, searchedPartners]);

  const total = useMemo(() => {
    if (searchedPartners?.length && isSystemLevel) {
      return getNumberOfSearchedOrganizations(searchedPartners, isSearchUsed ? searchValue : '');
    }
  
    if (searchedDistributors?.length && isPartnerLevel) {
      return getNumberOfSearchedOrganizations(searchedDistributors, isSearchUsed ? searchValue : '');
    }
  
    if (currentSearchedOrgs?.length && isDistributorLevel) {
      return getNumberOfSearchedOrganizations(currentSearchedOrgs, isSearchUsed ? searchValue : '');
    }
  }, [
    isSystemLevel,
    searchedPartners,
    searchedDistributors,
    isDistributorLevel,
    isPartnerLevel,
    isSearchUsed,
    searchValue,
    currentSearchedOrgs,
  ]);
  const isDataLoading = useMemo(() => isExternalLoading || isLoading, [isLoading, isExternalLoading]);

  const [
    systemId,
  ] = useMemo(() => {
    if (isSystemLevel) {
      return [id];
    }
  
    return (path || '').split('\\').slice(1, 5);
  }, [path, isSystemLevel, id]);
  
  const systemNameData = useGetOrgName({
    id: systemId,
    enabled: isSystemLevel,
  });
  
  useEffect(() => {
    setPartnerParentId('');
    setDistributorParentId('');
  }, [searchValue]);
  
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef && inputRef.current && isShown) {
      inputRef.current.focus();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inputRef.current, isShown]);

  const isOrgChecked = useCallback((id: string) => selectedOrgs.some((org) => org.id === id), [selectedOrgs]);

  const isChildrenChecked = (parentId: string) => {
    if (!checkedChildren) return false;
  
    return checkedChildren.some(child => child.parentId === parentId);
  };

  const handleCheckChild = useCallback((id: string, parentId?: string) => {
    if (checkedChildren === undefined) {
      setCheckedChildren([{ parentId: parentId || '', children: [id] }]);
    } else {
      const isNewParent = !checkedChildren.some(child => child.parentId === parentId);
      if (isNewParent) {
        setCheckedChildren([...checkedChildren, { parentId: parentId || '', children: [id] }]);
      } else {
        const newCheckedChildren = checkedChildren
          .map(child => {
            if (child.parentId === parentId) {
              const children = child.children.includes(id)
                ? child.children.filter(childId => childId !== id)
                : [...child.children, id];
              return { ...child, children };
            }
            return child;
          })
          .filter(child => child.children.length > 0);
        setCheckedChildren(newCheckedChildren.length > 0 ? newCheckedChildren : undefined);
      }
    }
  }, [checkedChildren]);

  const handleOnChangeOrgs = useCallback((
    org: TeamOrganization,
    childCheck?: (id: string, parentId?: string)=> void,
    parentId?: string,
    checkedChildren?: CheckedChildren[],
  ) => {
    const isOrgChecked = selectedOrgs.some(item => item.id === org.id);

    if (isOrgChecked) {
      const newOrgs = selectedOrgs.filter(item => item.id !== org.id);
      childCheck && childCheck(org.id, parentId);
      return setSelectedOrgs(newOrgs);
    } else {
      const checkedParent = checkedChildren?.find(child => child.parentId === org.id);
      if (checkedParent) {
        const filteredOrgs = selectedOrgs.filter(item => !checkedParent?.children?.includes(item.id));
        const newOrgs = [...filteredOrgs, org];
        childCheck && childCheck(org.id, parentId);
        return setSelectedOrgs(newOrgs);
      }
      const newOrgs = [...selectedOrgs, org];
      childCheck && childCheck(org.id, parentId);
      return setSelectedOrgs(newOrgs);
    }
  }, [selectedOrgs]);

  const handleApply = useCallback(() => {
    const selectedOrgsIds = selectedOrgs.map(org => org.id);
    onChange(selectedOrgsIds);
    setSelectedOrganizationName(selectedOrgs.map((item => item.name)).join(', '));
    setLastAppliedOrgs(selectedOrgs);
    setLastAppliedCheckedChildren(checkedChildren);
    setIsShown(false);
  }, [checkedChildren, onChange, selectedOrgs]);

  const handleCancel = useCallback(() => {
    setIsShown(false);
    setSelectedOrgs(lastAppliedOrgs);
    setCheckedChildren(lastAppliedCheckedChildren);
  }, [lastAppliedCheckedChildren, lastAppliedOrgs]);

  useEffect(() => {
    if (!isShown) {
      setSelectedOrgs(lastAppliedOrgs);
      setCheckedChildren(lastAppliedCheckedChildren);
    }
  }, [isShown, lastAppliedCheckedChildren, lastAppliedOrgs]);

  useEffect(() => {
    const selectedOrgsIds = selectedOrgs.map(org => org.id);
    const checkedParentsIds = checkedChildren?.map(child => child.parentId);

    const commonIds = selectedOrgsIds.filter(id => checkedParentsIds?.includes(id));

    if (commonIds.length) {
      const newCheckedChildren = checkedChildren?.filter(child => !commonIds.includes(child.parentId));

      setCheckedChildren(newCheckedChildren?.length ? newCheckedChildren : undefined);
    }
  }, [selectedOrgs, checkedChildren]);

  useEffect(() => {
    if (currentOrganizations) {
      setSelectedOrgs(currentOrganizations);
      onChange(currentOrganizations.map(org => org.id));
      setLastAppliedOrgs(currentOrganizations);
      setSelectedOrganizationName(currentOrganizations.map((item => item.name)).join(', '));
    }
  }, [currentOrganizations, onChange]);

  return (
    <Box ref={ref} key={currentTeamId || 'newTeamKey'}>
      <Box onFocus={() => setIsShown(true)}>
        <FakeSearchInput isActive={isShown} placeholder="None">
          {selectedOrganizationName}
        </FakeSearchInput>
      </Box>
  
      <Box
        height="480px"
        width={{ min: '340px' }}
        background="canvas"
        justify="between"
        round="xs"
        pad={{ top: 'spacing12' }}
        style={{
          position: 'absolute',
          left: 'calc(100% + 2px)',
          boxShadow: currentTheme.shadows.active['box-shadow'],
          top: '-220px',
          ...isShown ? {} : { display: 'none' },
        }}
      >
        <Box>
          <Box
            pad={{ bottom: 'spacing12' }}
            // margin={{ bottom: 'spacing16' }}
            border={{
              color: 'border1',
              side: 'bottom',
            }}
            height={{ min: 'fit-content' }}
          >
            <Box pad={{ horizontal: 'spacing16' }}>
              <SearchInput
                inputRef={inputRef}
                placeholder="Search"
                testId="org_level_selection"
                value={search}
                onChange={setSearchString}
              />
            </Box>
          </Box>
  
          {isSearchUsed ? (
            <Box
              pad={{ horizontal: 'spacing16' }}
              height={{ min: 'fit-content' }}
              margin={{ top: 'spacing12' }}
            >
              <Box
                margin={{ bottom: 'spacing8' }}
                pad={{ bottom: 'spacing12' }}
                border={{
                  color: 'border1',
                  side: 'bottom',
                }}
              >
                <Inscription
                  color="textSecondary"
                  size="12px"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Results: {isDataLoading ? (
                    <SmallPreloader />
  
                  ) : getTotalString(total, isSearchUsed)}
                </Inscription>
              </Box>
              {total || isDataLoading ? null : (
                <Inscription lineHeight="20px" color="textBody">No results have been found.</Inscription>
              )}
            </Box>
          ) : null}
  
          {isSearchUsed && !total ? null : (
            <Box margin={{ bottom: 'spacing8' }}>
              <Box style={{ overflowY: 'auto' }}>
                <AccordionItemWrapper pad={{ horizontal: 'spacing16', top: isSearchUsed ? undefined : 'spacing16' }}>
                  {isSystemLevel
                    ? (
                      <AccordionItem
                        testId="system-level"
                        text={(
                          <StyledItem
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isSystemLevel) {
                                handleOnChangeOrgs({
                                  id: systemId,
                                  name: systemNameData?.name as string,
                                }, undefined, undefined, checkedChildren);
                              }
                            }}
                            pad={{ horizontal: 'spacing8', vertical: 'spacing4' }}
                            margin={{ left: 'spacing2' }}
                            round="xxs"
                            direction="row"
                            width="100%"
                            isChecked={isOrgChecked(systemId)}
                          >
                            <Checkbox
                              name="system-level"
                              onChange={() => handleOnChangeOrgs({
                                id: systemId,
                                name: systemNameData?.name as string,
                              }, undefined, undefined, checkedChildren)}
                              disabled={!isSystemLevel}
                              checked={isOrgChecked(systemId)}
                              manualIndeterminate={isChildrenChecked(systemId) && !isOrgChecked(systemId)}
                            />
                            <Inscription color="textBody" weight="normal">
                              {isSystemLevel ? name : systemNameData?.name} (System)
                            </Inscription>
                          </StyledItem>
                        )}
                        isExpandedDefault
                        isLeftArrow
                      >
                        <NestedOrganizationSelectorLevel
                          orgLevels={currentOrgLevels}
                          parentId={systemId}
                          onChange={handleOnChangeOrgs}
                          selectedOrgs={selectedOrgs}
                          isSearchUsed={isSearchUsed}
                          searchValue={searchValue}
                          isShown={isShown}
                          isLoading={isLoading}
                          onExternalLoading={setIsExternalLoading}
                          currentHightLevel={type}
                          searchedOrgs={searchedPartners}
                          isLevelUnpicked={isOrgChecked(systemId)}
                          onChildChecked={handleCheckChild}
                          checkedChildren={checkedChildren}
                          pickedTreeIds={currentPickedOrgs?.selectedIds}
                          pickedParentOrgs={currentPickedOrgs?.trimmedNode?.children}
                          pickedChildrenLevel={currentPickedOrgs?.childrenLevel}
                        />
                      </AccordionItem>
                    )
                    : (
                      <NestedOrganizationSelectorLevel
                        orgLevels={currentOrgLevels}
                        onChange={handleOnChangeOrgs}
                        selectedOrgs={selectedOrgs}
                        isSearchUsed={isSearchUsed}
                        searchValue={searchValue}
                        isShown={isShown}
                        isLoading={isLoading}
                        onExternalLoading={setIsExternalLoading}
                        currentHightLevel={type}
                        searchedOrgs={currentSearchedOrgs}
                        isLevelUnpicked={isOrgChecked(systemId)}
                        onChildChecked={handleCheckChild}
                        checkedChildren={checkedChildren}
                        pickedTreeIds={currentPickedOrgs?.selectedIds}
                        pickedParentOrgs={currentPickedOrgs?.trimmedNode?.children}
                        pickedChildrenLevel={currentPickedOrgs?.childrenLevel}
                      />
                    )}
                  
                </AccordionItemWrapper>
              </Box>
            </Box>
          )}
        </Box>
        <Box height={{ min: 'fit-content' }}>
          <Box
            justify="between"
            align="center"
            direction="row"
            pad={{ horizontal: 'spacing16' }}
            height="70px"
            border={{
              color: 'border1',
              side: 'top',
            }}
          >
            <AppButton
              testId="cancel"
              buttonType="secondary"
              onClick={handleCancel}
              width="145px"
            >
              Cancel
            </AppButton>
            <AppButton
              testId="apply"
              onClick={handleApply}
              width="145px"
            >
              Apply
            </AppButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
  
export default MultipleOrganizationSelector;
  