import { useMemo } from 'react';

import { useIsServerError } from '@/utils/hooks/useIsServerError';

import { HealthPlanStatuses } from '../../HealthPlan.types';
import { useHealthPlansListQuery } from '../../queries';

export default () => {
  const {
    data: draftPlans,
    isLoading: isDraftPlansLoading,
    error: draftError,
    refetch: draftPlansRefetch,
  } = useHealthPlansListQuery(null, HealthPlanStatuses.DRAFT);

  const {
    data: activePlans,
    isLoading: isActivePlansLoading,
    error: activeError,
    refetch: activePlansRefetch,
  } = useHealthPlansListQuery(null, HealthPlanStatuses.ACTIVE);

  const allDraftPlans = useMemo(
    () => draftPlans,
    [draftPlans],
  );
  const isLoading = useMemo(
    () => isDraftPlansLoading
    || isActivePlansLoading,

    [
      isActivePlansLoading,
      isDraftPlansLoading],
  );

  const count = useMemo(
    () => activePlans.length
    + allDraftPlans.length,
    [
      activePlans.length, allDraftPlans.length],
  );
  const currentError = useMemo(
    () => draftError

    || activeError,
    [draftError, activeError],
  );
  const currentRefetch = useMemo(() => {
    if (draftError) return draftPlansRefetch;
    if (activeError) return activePlansRefetch;
    return draftPlansRefetch;
  }, [activeError, activePlansRefetch, draftError, draftPlansRefetch]);

  const { isServerError, setIsServerError } = useIsServerError(currentError);
  return {
    count,
    isLoading,
    isServerError,
    currentRefetch,
    setIsServerError,
    activePlans,
    allDraftPlans,
  };
};
