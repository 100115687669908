import { ALL_OPTION } from '@/common/constants';
import { DefaultSortTypesEnum, Option } from '@/common/types';

export enum InvoiceStatus {
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAID = 'PAID',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  REOPEN = 'REOPEN',
  OPEN = 'OPEN',
  CLOSET = 'CLOSET',
  VOID = 'VOID',
}

export enum InvoiceType {
  ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT = 'ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT',
  PLAN_LEVEL_PREFUND_REPLENISHMENT = 'PLAN_LEVEL_PREFUND_REPLENISHMENT',
  REPLENISHMENT = 'REPLENISHMENT',
  PLAN_LEVEL_REPLENISHMENT = 'PLAN_LEVEL_REPLENISHMENT',
  ORGANIZATION_LEVEL_CONTRIBUTIONS = 'ORGANIZATION_LEVEL_CONTRIBUTIONS',
}

export const InvoiceTypeView: Record<InvoiceType, string> = {
  [InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT]: 'Org Prefund',
  [InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT]: 'Plan Prefund',
  [InvoiceType.REPLENISHMENT]: 'Org Replenishment',
  [InvoiceType.PLAN_LEVEL_REPLENISHMENT]: 'Plan Replenishment',
  [InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS]: 'Org Contributions',
};

export const INVOICES_STATUSES_OPTIONS: Option[] = [
  ALL_OPTION,
  {
    key: InvoiceStatus.PAYMENT_INITIATED,
    value: InvoiceStatus.PAYMENT_INITIATED,
    title: 'Payment initiated',
  },
  {
    key: InvoiceStatus.PAYMENT_REQUESTED,
    value: InvoiceStatus.PAYMENT_REQUESTED,
    title: 'Payment requested',
  },
  {
    key: InvoiceStatus.PAYMENT_REJECTED,
    value: InvoiceStatus.PAYMENT_REJECTED,
    title: 'Payment rejected',
  },
  {
    key: InvoiceStatus.PAID,
    value: InvoiceStatus.PAID,
    title: 'Paid',
  },
  {
    key: InvoiceStatus.PAYMENT_FAILED,
    value: InvoiceStatus.PAYMENT_FAILED,
    title: 'Payment failed',
  },
  {
    key: InvoiceStatus.REOPEN,
    value: InvoiceStatus.REOPEN,
    title: 'Reopen',
  },
];

export const INVOICES_TYPES_OPTIONS: Option[] = [
  {
    ...ALL_OPTION,
  },
  {
    key: InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT,
    value: InvoiceType.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT,
    title: InvoiceTypeView.ORGANIZATION_LEVEL_PREFUND_REPLENISHMENT,
  },
  {
    key: InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT,
    value: InvoiceType.PLAN_LEVEL_PREFUND_REPLENISHMENT,
    title: InvoiceTypeView.PLAN_LEVEL_PREFUND_REPLENISHMENT,
  },
  {
    key: InvoiceType.REPLENISHMENT,
    value: InvoiceType.REPLENISHMENT,
    title: InvoiceTypeView.REPLENISHMENT,
  },
  {
    key: InvoiceType.PLAN_LEVEL_REPLENISHMENT,
    value: InvoiceType.PLAN_LEVEL_REPLENISHMENT,
    title: InvoiceTypeView.PLAN_LEVEL_REPLENISHMENT,
  },
  {
    key: InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS,
    value: InvoiceType.ORGANIZATION_LEVEL_CONTRIBUTIONS,
    title: InvoiceTypeView.ORGANIZATION_LEVEL_CONTRIBUTIONS,
  },
];

export const SORTING_OPTIONS = [
  {
    key: DefaultSortTypesEnum.DESC,
    value: DefaultSortTypesEnum.DESC,
    title: 'Newest first',
  },
  {
    key: DefaultSortTypesEnum.ASC,
    value: DefaultSortTypesEnum.ASC,
    title: 'Oldest first',
  },
];
