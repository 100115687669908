import { Roles } from '@/common/constants';

export interface RoleModel {
  description?: string;
  id: number;
  name: Roles;
}

export enum ContactStatuses {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum EmailCommunicationType {
  finance = 'FINANCE',
  planManagement = 'PLAN_MANAGEMENT',
  files = 'FILES',
}

export interface EmailCommunication {
  finance: boolean,
  planManagement: boolean,
  files: boolean,
}

export interface CreateContactPayload {
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  externalIdentifier: string;
  phoneNumber: string;
  title: string;
  department: string;
  primary: boolean;
  permissions: Roles;
}

export type CreateContactFormModel = CreateContactPayload;
export type ContactEntity = CreateContactFormModel & {
  status?: ContactStatuses,
  createDate: number;
  id: number;
};

export interface CreateContactDto {
  username: string;
  password?: string;
  role: Roles;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  // username: string;
  phone: string;
  department: string;
  primary: boolean;
  external_identifier: string | null;
  contact_type: string;
  contact_status_type: string;
}

export interface ContactResponseModel {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  phone: string;
  department: string;
  primary: boolean;
  external_identifier: string | null;
  contact_type: string;
  contact_status_type: string;
  role: Roles;
}
