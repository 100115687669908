import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@common-fe/common-fe';

import { FeatureFlagKeys, OrganizationTypes, PermissionKey } from '@/common/constants';
import Permissions from '@/common/permissions';
import ROUTES from '@/common/routes';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization, useOrganizationFeatureFlag } from '@/modules/core/hooks';
import useContributionsFeatureFlag from '@/modules/employer/useContributionsFeatureFlag';
import useGetPermissionState from '@/modules/user/hooks/useGetPermissionState';
import useReversedFeatureFlag from '@/utils/hooks/useFeatureFlag';

import ExpressSetupLinks from './ExpressSetupLinks/ExpressSetupLinks';
import { PayrollCalendarSection } from './PayrollCalendar/PayrollCalendarSection';
import PayrollCalendarTable from './PayrollCalendar/PayrollCalendarTable';
import PayrollGroupModal from './PayrollGroups/PayrollGroupModal';
import PayrollGroupTable from './PayrollGroups/PayrollGroupTable';
import { CONFIRM_TEXT } from './PlanModal/PlanFormModal';
import PlanTable from './PlanTable/PlanTable';
import { AIAdjudication } from './AIAdjudication';
import OrphanConfiguration from './OrphanConfiguration';
import PlanModal from './PlanModal';
import PlanTemplates from './PlanTemplates';
import PlanYearWrapper from './PlanYearWrapper';
import { Reimbursement } from './Reimbursement';

interface Props {
  disabled?: boolean;
  payrollGroupsDisabled?: boolean;
}

export const Features: React.FC<Props> = ({ disabled, payrollGroupsDisabled }) => {
  const { path } = useRouteMatch();
  const { isHide: isAiAdjudicationHide } = useGetPermissionState(PermissionKey.IS_AI_ADJUDICATION);
  const { isHide: isAiConfidenceLevelHide } = useGetPermissionState(PermissionKey.AI_CONFIDENCE_LEVEL);

  const isPartnerPage = useMemo(
    () => path === ROUTES.PARTNERS_EDIT() || path === ROUTES.PARTNERS_VIEW(),
    [path]
  );
  const isDistributorPage = useMemo(
    () => path === ROUTES.DISTRIBUTORS_EDIT() || path === ROUTES.DISTRIBUTORS_VIEW(),
    [path]
  );
  const {
    observingOrganization: { type },
  } = useCurrentOrganization();
  const isSystemLevel = useMemo(() => type === OrganizationTypes.system, [type]);
  const hasPlanFunctionality = useMemo(
    () => !isPartnerPage && !isDistributorPage,
    [isPartnerPage, isDistributorPage]
  );

  const hasAiAdjudication = useMemo(
    () => !(isAiAdjudicationHide && isAiConfidenceLevelHide),
    [isAiAdjudicationHide, isAiConfidenceLevelHide]
  );
  const isExpressSetupLink = useReversedFeatureFlag(FeatureFlagKeys.IS_EXPRESS_SETUP_VIA_LINK,{
    dev: true
  });

  const isContributionsFeatureFlag = useContributionsFeatureFlag();
  const isPayrollGroupsAvailable = useMemo(
    () => !isPartnerPage && !isDistributorPage && !isSystemLevel && isContributionsFeatureFlag,
    [isPartnerPage, isDistributorPage, isSystemLevel, isContributionsFeatureFlag]);
  return (
    <Box direction="column" background="module">
      {isPartnerPage && (
        <Box background="canvas" round="container1Round" margin={{ bottom: 'spacing24' }}>
          <PlanTemplates disabled={disabled} />
        </Box>
      )}
      <Box
        background="canvas"
        margin={{ bottom: hasPlanFunctionality ? 'spacing24' : '' }}
        round="container1Round"
        id="plan_year_section"
      >
        {!isSystemLevel && hasPlanFunctionality && (
          <PlanYearWrapper disabled={disabled} confirmText={CONFIRM_TEXT} />
        )}
      </Box>
      {!isSystemLevel && hasPlanFunctionality && (
        <Box background="canvas" round="container1Round" margin={{ bottom: 'l' }}>
          <PlanModal disabled={disabled} />
          <Access
            accessRequirements={[{ permission: Permissions.VIEW_PLAN }, { permission: Permissions.VIEW_ALL }]}
          >
            <PlanTable />
          </Access>
        </Box>
      )}
      {!isSystemLevel && hasPlanFunctionality && (
        <Box background="canvas" round="container1Round">
          <PlanModal disabled={disabled} isHealthPlanMode />
          <Access
            accessRequirements={[{ permission: Permissions.VIEW_PLAN }, { permission: Permissions.VIEW_ALL }]}
          >
            <PlanTable isHealthPlanMode />
          </Access>
        </Box>
      )}
      {isPayrollGroupsAvailable && (
        <Box
          id="payroll_group"
          background="canvas"
          round="container1Round"
          margin={{ top: 'l' }}
        >
          <PayrollGroupModal disabled={payrollGroupsDisabled} />
          <Access
            accessRequirements={[{ permission: Permissions.VIEW_ER }, { permission: Permissions.EDIT_ER_DATA }]}
          >
            <PayrollGroupTable />
          </Access>
        </Box>
      )}
      {isPayrollGroupsAvailable && (
        <Box
          id="payroll_calendar"
          background="canvas"
          round="container1Round"
          margin={{ top: 'l' }}
        >
          <PayrollCalendarSection disabled={payrollGroupsDisabled} />
          <Access
            accessRequirements={[{ permission: Permissions.VIEW_ER }, { permission: Permissions.EDIT_ER_DATA }]}
          >
            <PayrollCalendarTable />
          </Access>
        </Box>
      )}
      <Box {...(hasPlanFunctionality ? { margin: { top: 'spacing24' } } : {})}>
        <Reimbursement disabled={disabled} />
      </Box>
      {hasAiAdjudication && (
        <Box margin={{ top: 'spacing24' }}>
          <AIAdjudication />
        </Box>
      )}
      {!isDistributorPage && !isPartnerPage && (
        <Box margin={{ top: 'spacing24' }}>
          <OrphanConfiguration />
        </Box>
      )}
      {isExpressSetupLink && (isDistributorPage || isPartnerPage) && (
        <Box margin={{ top: 'spacing24' }}>
          <ExpressSetupLinks isDistributorMode={isDistributorPage} />
        </Box>
      )}
    </Box>
  );
};
