import React, { useMemo } from 'react';
import {
  Box, Heading, SelectDropdown,
  Text, } from '@common-fe/common-fe';

import { DEFAULT_EMPTY_CHARACTER } from '@/common/constants';
import { Option } from '@/common/types';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { useSetupEmployerStore } from '@/modules/employer/store';
import { useStore } from '@/modules/employer/store/useSetupEmployer.store';

interface Props {
  disabled?: boolean;
}

const InvoicingDetails: React.FC<Props> = ({ disabled }) => {
  const { contactList } = useSetupEmployerStore();
  const setPrimaryContactId = useStore((state) => state.setPrimaryContactId);
  const primaryContactId = useStore((state) => state.primaryContactId);
  const { editMode } = useSetupEmployerMode();

  const options = useMemo<Option[]>(() => {
    if (!contactList) {
      return [];
    }
    return contactList.map((item) => ({
      key: `${item.id}`,
      value: `${item.first_name} ${item.last_name}`,
    }));
  }, [contactList]);
  const currentContact = useMemo(
    () => options.find((item) => item.key === primaryContactId), [options, primaryContactId],
  );
  if (!options.length) {
    return null;
  }

  return (
    <Box
      background="canvas"
      round="container1Round"
      margin={{ bottom: 'spacing24' }}
      border={{ color: 'border2', size: 'small' }}
      id="invoicing_details"
    >
      <Box direction="column" pad={{ vertical: 'spacing24', horizontal: 'spacing32' }}>
        <Heading level={3} size="small">
          Invoicing details
        </Heading>
        <Box direction="row" align="center" height={{ min: '40px' }}>
          <Box width="medium">
            <Text size="medium" weight="bold">Primary contact:</Text>
          </Box>
          <Box width="medium">
            {!editMode ? (
              <Text>{currentContact?.value as string || DEFAULT_EMPTY_CHARACTER}</Text>
            ) : (
              <SelectDropdown
                id="invoicing"
                singleMode
                disabled={disabled || !editMode}
                name="invoicing"
                options={options}
                placeholder="None"
                value={primaryContactId}
                onChange={(value) => value && setPrimaryContactId(value)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicingDetails;
