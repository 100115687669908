import { useMemo } from 'react';

import { useAdditionalFieldStateStore } from '../Additional/stores';
import { useCardOfferedFieldsStateStore } from '../CardOffered/stores';
import { usePlanNameFieldsStateStore } from '../PlanName/stores';
import { usePlanYearFieldsStateStore } from '../PlanYear/stores';

export default () => {
  const planNameStore = usePlanNameFieldsStateStore((state) => state.sourceState);

  const cardOfferedStore = useCardOfferedFieldsStateStore((state) => state.sourceState);
  const additionalStore = useAdditionalFieldStateStore((state) => state.sourceState);
  const planYearFieldsStateStore = usePlanYearFieldsStateStore((state) => state.sourceState);

  const state = useMemo(() => ({
    ...planNameStore,

    ...cardOfferedStore,
    ...additionalStore,
    ...planYearFieldsStateStore,
  }), [additionalStore, cardOfferedStore, planNameStore, planYearFieldsStateStore]);
  return state;
};
