import { useMemo } from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';

import { api } from '@/api';
import { PATHS } from '@/common';
import { Option, Pagination, PaginationParams } from '@/common/types';

import { useSortTiers as sortTiers } from '../../hooks';
import { ROOT_ORGANIZATION } from '../../ProcessingDefinition.constants';
import { ProcessingRuleItem, TierPayload } from '../../ProcessingDefinition.types';

export interface ProcessingRulePayload {
  id: 0;
  name: string;
  organization_type?: string;
  tiers: TierPayload[];
  isNew?: boolean;
  editable?: boolean;
}

export const QUERY_KEY = 'GET_PROCESSING_RULES';
export default (organizationPath?: string, params?: PaginationParams) => {
  const orgPath = organizationPath || ROOT_ORGANIZATION;

  const {
    data, isLoading,
  } = useQuery(
    [QUERY_KEY, orgPath, params],
    () => api.get<Pagination<ProcessingRulePayload>>(
      PATHS.PROCESSING_RULES, {
        params: _.pickBy({
          organization_path: organizationPath || ROOT_ORGANIZATION,
          name: params?.searchString || undefined,
          page: params?.page ? params.page - 1 : 0,
          size: params?.perPage,
          sort: params?.sort,
        }),
      },
    ),

  );

  const total = useMemo(() => data?.data.totalElements || 0, [data]);

  const formatedData = useMemo(() => {
    const list = data?.data.content || [];
    return list.map((item) => {
      const formatedItem: ProcessingRuleItem = {
        id: `${item.id}`,
        name: item.name,
        isNew: item.isNew,
        organizationType: item.organization_type,
        editable: item.editable,
        tiers: sortTiers(item.tiers)?.map((tier) => ({
          id: `${tier.id}`,
          name: tier.name,
          payoutDefinition: {
            id: `${tier.payout_definition.id}`,
            name: tier.payout_definition.name,
          },
          isSendTriggerNotification: tier.is_send_trigger_notification,
          triggers: tier.triggers,
          trigger: tier.triggers[0],
          nextTierId: tier.next_tier_id,
          displayName: tier?.display_name,
          planId: `${tier?.plan_id || ''}`,
          planName: tier?.plan_name,
        })),
      };
      return formatedItem;
    });
  }, [data]);

  const formatedList = useMemo<Option[]>(() => {
    const list = data?.data?.content || [];
    return list.map((item) => ({
      key: `${item.id}`,
      value: `${item.name}`,
      name: item.name,
    }));
  }, [data]);

  return {
    total,
    isLoading,
    data: formatedData,
    list: formatedList,
  };
};
