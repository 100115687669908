import { useEffect, useMemo,useState } from 'react';
import { useMutation } from 'react-query';

import { api } from '@/api';
import PATHS from '@/common/paths';

import { FileDtoWithMediaSource, FileStatus } from '../FileManager.constants';

import { FileDtoPayload,formatFile } from './useFilesList.query';
import { addBacklightingFields } from './useFilesUploadQuery';

const PROCESS_FILE_QUERY_KEY = 'approveFile';

const changeTemporarily = (data: FileDtoPayload) => {
  const changedData = { ...data };

  delete changedData.uploaded_by;
  delete changedData.partners_names;
  delete changedData.employers_names;

  return changedData;
};

const useFilesProcess = (
  allFiles: FileDtoWithMediaSource[], isReset?: boolean,
) => {
  const [processedFiles, setProcessedFiles] = useState<FileDtoWithMediaSource[]>([]);
  const [
    successfullyProcessedFile, setSuccessfullyProcessedFile,
  ] = useState<FileDtoWithMediaSource>();

  useEffect(() => {
    if (successfullyProcessedFile) {
      setProcessedFiles([
        ...processedFiles.filter((file) => file.id !== successfullyProcessedFile.id),
        successfullyProcessedFile,
      ] as FileDtoWithMediaSource[]);
      setSuccessfullyProcessedFile(undefined);
    }
  }, [successfullyProcessedFile, processedFiles]);

  useEffect(
    () => {
      if (isReset && processedFiles.length) {
        setProcessedFiles([]);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [isReset],
  );

  const { mutateAsync, isSuccess, isLoading } = useMutation(
    PROCESS_FILE_QUERY_KEY,
    (file: FileDtoWithMediaSource) => api.patch(
      PATHS.PROCESS_FILE(file.id),
      undefined,
    ),
    {
      onSuccess: ({ data }) => {
        const changedPayload = changeTemporarily(data);
        const formattedFile = formatFile(changedPayload);

        setSuccessfullyProcessedFile({
          ...formattedFile,
          ...addBacklightingFields(formattedFile.status),
        } as FileDtoWithMediaSource);
      },
    },
  );

  const files = useMemo(
    () => allFiles
      .map(
        (file) => {
          if (file.status === FileStatus.Uploaded) {
            const processedFile = processedFiles
              .find(
                (processFile) => processFile.id === file.id,
              );

            return processedFile || file;
          }

          return file;
        },
      ),
    [allFiles, processedFiles],
  );

  return {
    onFileProcess: mutateAsync,
    processedFiles: files,
    isSuccess,
    isLoading,
  };
};

export default useFilesProcess;
