import React, { useMemo } from 'react';
import { Box, ShimmerBox } from '@common-fe/common-fe';
import styled from 'styled-components';

import { LARGE_SIZE_LOGO, LetterIconSizeEnum, OrganizationTypes } from '@/common/constants';
import { Icon } from '@/components/elements/Icon';
import theme from '@/styles/theme';

import { ExpressSetup } from './ExpressSetup.types';

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.textBody};
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  &:not(:first-child) {
    margin-left: 32px;
  }
`;

const ExpressSetupFooter: React.FC<{ expressSetupData?: ExpressSetup, isLoading?: boolean }> = ({ expressSetupData, isLoading }) => {
  const lins = useMemo(() => ([
    {
      link: expressSetupData?.pricingConsentLink,
      name: 'Pricing consent',
    },
    {
      link: expressSetupData?.privacyPolicyLink,
      name: 'Privacy Policy',
    },
    {
      link: expressSetupData?.termsConditionsLink,
      name: 'Terms of Use',
    },
  ]), [expressSetupData]);

  if (isLoading) {
    return (
      <ShimmerBox
        alignSelf="center"
        round="60px"
        height="72px"
        width={theme.defaultMinContentWidth}
      />
    );
  }

  return (
    <Box
      width={theme.defaultMinContentWidth}
      alignSelf="center"
      round="60px"
      height="72px"
      background="border1"
      pad={{ vertical: 'spacing24', horizontal: 'spacing32' }}
      direction="row"
      justify="between"
      align="center"
    >
      <Box>
        <Icon
          testId="express-setup-footer-logo"
          size={LetterIconSizeEnum.LOGO}
          type={OrganizationTypes.company}
          text={expressSetupData?.name}
          height={LARGE_SIZE_LOGO.height}
          width="100%"
          src={expressSetupData?.logo}
        />
      </Box>
      <Box direction="row">
        {lins.map((item) => item.link ? (
          <StyledLink key={item.name} href={item.link} target="_blank" rel="noreferrer">
            {item.name}
          </StyledLink>
        ) : null)}
      </Box>
    </Box>
  );
};

export default ExpressSetupFooter;
