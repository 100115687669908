import React, { useMemo } from 'react';
import { Box, Field, FieldStateTypes } from '@common-fe/common-fe';
import _ from 'lodash';

import { EMPTY_LABEL_SIGN } from '@/common/constants';

import { PLAN_SETUP_FIELD } from '../PlanSetup.constants';
import TemplateStateField from '../TemplateStateField';

import useParentId from './useParentId';
import usePlanSetupFieldsSetter from './usePlanSetupFieldsSetter';
import usePlanSetupFieldsSourceState from './usePlanSetupFieldsSourceState';
import usePlanSetupFieldsState from './usePlanSetupFieldsState';

const BLOCKED_FIELDS = [PLAN_SETUP_FIELD.PLAN_TEMPLATE, PLAN_SETUP_FIELD.PLAN_TYPE];

const useTemplateFieldState =  (fields: Field[], isTemplateMode?: boolean, isEditMode?: boolean) => {
  const parentId = useParentId();
  const isParentIdExists = useMemo(() => !!parentId, [parentId]);
  const sourceState = usePlanSetupFieldsSourceState();
  const state = usePlanSetupFieldsState();

  const { handleSetState } = usePlanSetupFieldsSetter();

  const isMinimized = useMemo(() => !isTemplateMode || !isEditMode, [isEditMode, isTemplateMode]);

  const getModifiedFields = (currentFields: Field[]) : Field[] => currentFields.map((field) => {
    const currentValue = _.get(state, `${field.name}`) as FieldStateTypes;
    const sourceValue = _.get(sourceState, `${field.name}`) as FieldStateTypes;
    const sourceDisabled = sourceValue === FieldStateTypes.Locked
    || sourceValue === FieldStateTypes.Hidden;
    const appliedDisabling = field.name === PLAN_SETUP_FIELD.PLAN_SUMMARY || (isParentIdExists
      ? sourceDisabled
      : BLOCKED_FIELDS.includes(field.name) && sourceDisabled);
    if (field.label === EMPTY_LABEL_SIGN) {
      return (
        {
          ...field,
          subFields: field.subFields
            ? getModifiedFields(field.subFields) : undefined,
          preLabelNode: (
            <Box width={{ min: '50px' }} />)
          ,
        }
      );
    }
    if (field.subFields && field.label) {
      return (
        {
          ...field,
          subFields: getModifiedFields(field.subFields),
          preLabelNode: (
            <Box width={{ min: '50px' }}>
              <TemplateStateField
                testId={`${field.name}_state`}
                minimized={isMinimized}
                disabled={appliedDisabling}
                value={currentValue}
                onChangeValue={(value) => handleSetState({
                  [field.name]: value,
                })}
              />
            </Box>)
          ,
        }
      );
    }

    if (field.label) {
      return (
        {
          ...field,

          preLabelNode: (
            <Box width={{ min: '50px' }}>
              <TemplateStateField
                testId={`${field.name}_state`}
                minimized={isMinimized}
                value={currentValue}
                disabled={appliedDisabling}
                onChangeValue={(value) => handleSetState({
                  [field.name]: value,
                })}
              />
            </Box>),
        }
      );
    }
    return ({
      ...field,
      preLabelNode: (
        <Box width="50px" />
      ),
    });
  });

  return getModifiedFields(fields);
};

export default useTemplateFieldState;
