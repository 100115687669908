import { storeCreator } from '@/utils/store/store.utils';

interface State {
  pendingModal: boolean;
  activateModal: boolean;
  activateSaveModal: boolean;
  isEmptySaveModal: boolean;
  isActivatingModal: boolean;
  successfullyActivatedModal: boolean;
  unableToActivateModal: boolean;
  setPendingModal: (value: boolean) => void;
  setActivateModal: (value: boolean) => void;
  setActivateSaveModal: (value: boolean) => void;
  setIsEmptySaveModal: (value: boolean) => void;
  setIsActivatingModal: (value: boolean) => void;
  setSuccessfullyActivatedModal: (value: boolean) => void;
  setUnableToActivateModal: (value: boolean) => void;
}

const useStore = storeCreator<State>((set) => ({
  pendingModal: false,
  activateModal: false,
  isEmptySaveModal: false,
  activateSaveModal: false,
  isActivatingModal: false,
  successfullyActivatedModal: false,
  unableToActivateModal: false,

  setPendingModal: (value: boolean) => set(() => ({
    pendingModal: value,
  })),
  setActivateModal: (value: boolean) => set(() => ({
    activateModal: value,
  })),
  setActivateSaveModal: (value: boolean) => set(() => ({
    activateSaveModal: value,
  })),
  setIsEmptySaveModal: (value: boolean) => set(() => ({
    isEmptySaveModal: value,
  })),
  setIsActivatingModal: (value: boolean) => set(() => ({
    isActivatingModal: value,
  })),
  setSuccessfullyActivatedModal: (value: boolean) => set(() => ({
    successfullyActivatedModal: value,
  })),
  setUnableToActivateModal: (value: boolean) => set(() => ({
    unableToActivateModal: value,
  })),
}));
const useModalType = () => {
  const store = useStore();

  return {
    ...store,
  };
};
export default useModalType;
