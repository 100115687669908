interface OrgMap {
  [key: string]: string;
}
export const ORG_LEVEL_MAP: OrgMap = {
  SYSTEM: 'System',
  PARTNER: 'Partner',
  EMPLOYER: 'Employer',
  DISTRIBUTOR: 'Distributor',
  SUBSIDIARY: 'Subsidiary',
  SUBGROUP: 'Subgroup',
  COMPANY: 'Employer',

};
export default (orgName: string) => {
  if (ORG_LEVEL_MAP[orgName]) {
    return ORG_LEVEL_MAP[orgName];
  }
  return '';
};
