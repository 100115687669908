import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { dateValidatorByFormat, validateUrl } from '@common-fe/common-fe';
import _ from 'lodash';

import { REGEXPS } from '@/common';
import { DEFAULT_DATE_FORMAT, NO_KEY, NON_ASSIGNABLE_VALUE, PermissionKey } from '@/common/constants';
import ROUTES from '@/common/routes';
import useReimbursementStore from '@/modules/employer/components/SetupEmployer/Features/Reimbursement/stores/useReimbursement.store';
import useFileManagerSettingsStore from '@/modules/employer/components/SetupEmployer/FilesAndImports/FileManagerSettings/stores/useFileManagerSettings.store';
import {
  RequiredFieldItem,
  RequiredFieldPropertie,
} from '@/modules/employer/components/SetupEmployer/FinalSetUp/BoxOfRequiredFields';
import { FrequencyOptions } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/AccountUsage.types';
import { useAccountUsageStore } from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/AccountUsage/stores';
import useBanksList from '@/modules/employer/components/SetupEmployer/FinancialDetails/BankInformation/BanksList/useBanksList';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import { useTechnicalSettingsStore } from '@/modules/employer/components/SetupEmployer/TechnicalSettings/stores/useTechnicalSettings.store';
import { EmployerStatus } from '@/modules/employer/employer.constants';
import { EmployerSetupModes } from '@/modules/employer/employerSetupModes.types';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';
import { useSetupEmployerStore, useStore } from '@/modules/employer/store/useSetupEmployer.store';
import { PartnerMobileApp } from '@/modules/employer/types';
import useGetPermissionToEditOverridableField from '@/modules/user/hooks/useGetPermissionToEditOverridableField';
import { isDateInFuture } from '@/utils';
import { yesOrNoToBoolean } from '@/utils/handlers';

import { useOrphanConfigurationStore } from '../../Features/OrphanConfiguration/stores';
import { WAITING_FOR_FUNDS_EXPIRATION_RANGE } from '../../Features/Reimbursement/hooks/useGetReimbursementFields';
import { useGeneralInformationValid } from '../../GeneralInformation';
import useLinkManagementValidations from '../../LinkManagement/useLinkManagementValidations';
import useMobileAppCustomizationStore from '../../MobileAppCustomization/store/useMobileAppCustomization.store';
import { useCustomReportsStore } from '../../Reports/CustomReports';
import { useCustomReportsValidator } from '../../Reports/CustomReports/hooks';

import useBlocksErrors, { BLOCKS_VALIDATION_TEXT } from './useBlocksErrors';

const MIN_PREFUND_AMOUNT = 1;
const MAX_PREFUND_AMOUNT = 999999;

const currencyValidate = (value: number) =>
  Number.isInteger(value) && value >= MIN_PREFUND_AMOUNT && value <= MAX_PREFUND_AMOUNT;
const percentValidate = (value: number) => value > 0 && value <= 100;

const REIMB_ERROR = 'Fill in the reimbursement options';
const PREFUND_ERROR = 'Fill in the pre-fund options';
const FINANCE_ERROR = 'Fill in the financial details';
const CUSTOM_REPORTS_ERROR = 'Fill in the custom reports details';
const MOBILE_APP_CUSTOMIZATION_ERROR = 'Fill in the mobile app customization';
const MAX_INPUT_LENGTH = 200;
const VALIDATION_TEXT = {
  BASIC_INFO: 'Basic Properties',
  EMPLOYER_NAME: (mode: EmployerSetupModes) => `${mode} name`,
  ADDRESS: 'address',
  GENERAL_INFORMATION: 'General information',
  CUSTOM_DAY: 'custom day',
  TECHNICAL_SETTINGS: 'Technical Settings',
  FILE_MANAGER: 'File manager',
};

const useSaveValidate = () => {
  const eeContributionsHoldPeriodPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.EE_CONTRIBUTIONS_HOLD_PERIOD
  );
  const replenishmentPercentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_PERCENT
  );
  const replenishmentMinValuePermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_MIN_VALUE
  );
  const replenishmentCreepPercentPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_CREEP_PERCENT
  );
  const replenishmentCreepMinPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.PREFUND_REPLENISHMENT_CREEP_MIN
  );
  const isReimbursementAllowedPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.IS_REIMBURSEMENT_ALLOWED
  );
  const isBillPayAllowedPermProps = useGetPermissionToEditOverridableField(PermissionKey.IS_BILL_PAY_ALLOWED);
  const isClaimOffsetsAllowedPermProps = useGetPermissionToEditOverridableField(PermissionKey.IS_CLAIM_OFFSETS_ALLOWED);
  const numberOfDaysBeforePlanStartPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.NUMBER_DAYS_BEFORE_PLAN_START
  );
  const cardPhoneNumberPermProps = useGetPermissionToEditOverridableField(PermissionKey.CARD_PHONE_NUMBER);
  const cardURLPermProps = useGetPermissionToEditOverridableField(PermissionKey.CARD_URL);
  const cardEmployerNamePermProps = useGetPermissionToEditOverridableField(PermissionKey.CARD_EMPLOYER_NAME);
  const minimumPaymentReimbursementAmountPermProps = useGetPermissionToEditOverridableField(
    PermissionKey.MINIMUM_PAYMENT_REIMBURSEMENT_AMOUNT
  );
  const { path: routePath } = useRouteMatch();
  const isSubsidiaryPage = useMemo(
    () =>
      routePath === ROUTES.SUBSIDIARY_CREATE ||
      routePath === ROUTES.SUBSIDIARY_EDIT() ||
      routePath === ROUTES.SUBSIDIARY_VIEW(),
    [routePath]
  );
  const isPartnerPage = useMemo(
    () =>
      routePath === ROUTES.PARTNERS_CREATE ||
      routePath === ROUTES.PARTNERS_EDIT() ||
      routePath === ROUTES.PARTNERS_VIEW(),
    [routePath]
  );
  const { state: reimbursementStore } = useReimbursementStore();

  const orphanConfigurationStore = useOrphanConfigurationStore((state) => state.state);
  const fileManagerSettingsStore = useFileManagerSettingsStore((state) => state.state);
  const employerState = useStore((state) => state);
  const linkManagementValidators = useLinkManagementValidations();
  const mobileAppCustomizations = useMobileAppCustomizationStore();
  const reportsState = useCustomReportsStore((state) => state.state);

  const { employer, address, cardsSetup } = employerState;
  const blocksErrors = useBlocksErrors();
  const { list: bankList } = useBanksList();
  const { mode } = useSetupEmployerMode();
  const {
    formattedData: { overridableProperties },
  } = useGetEmployer();
  const { state: accountUsage } = useAccountUsageStore();
  const { state: technicalSettings } = useTechnicalSettingsStore();
  const { generalInfo, status, hasOneContact } = useSetupEmployerStore();
  const { state, activateErrors } = useGeneralInformationValid();
  const { activateSubmitted, pendingSubmitted } = useSaveTypeStore();

  const isActiveOrg = useMemo(() => status === EmployerStatus.Active, [status]);

  const showPreFund = useMemo(() => accountUsage.fundingReplenishmentBank, [accountUsage]);

  const basicProperties: RequiredFieldPropertie = useMemo(() => {
    const title = 'Basic Properties';
    const description = 'Required to activate or save the organization:';
    const isValidMap = {
      name: Boolean(employer?.employerName && employer?.employerName.length < MAX_INPUT_LENGTH + 1),
      id: Boolean(employer?.employerId),
      addressLine1: Boolean(address?.street && address.street.length < MAX_INPUT_LENGTH + 1),
      city: Boolean(address?.city && address.city.length < MAX_INPUT_LENGTH + 1),
      adressState: Boolean(address?.state && address.state.length === 2),
      zipCode: Boolean(address?.zipCode && (address.zipCode.length === 5 || address.zipCode.length === 10)),
      partner: Boolean(employer?.tpa),
      distributor: Boolean(employer?.distributor),
    };

    const name: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.name,
      anchorId: 'basic_properties',
      title: `${mode} name`,
      isValid: isValidMap.name,
    };

    const id: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.id,
      anchorId: 'basic_properties',
      title: `${mode} ID`,
      isValid: isValidMap.id,
    };

    const addressLine1: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.addressLine1,
      anchorId: 'address',
      title: 'Address line 1',
      isValid: isValidMap.addressLine1,
    };

    const city: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.city,
      anchorId: 'address',
      title: 'City',
      isValid: isValidMap.city,
    };

    const adressState: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.adressState,
      anchorId: 'address',
      title: 'State',
      isValid: isValidMap.adressState,
    };

    const zipCode: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.zipCode,
      anchorId: 'address',
      title: 'ZIP code',
      isValid: isValidMap.zipCode,
    };

    const partner: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.partner,
      anchorId: 'basic_properties',
      title: 'Partner',
      isValid: isValidMap.partner,
    };

    const distributor: RequiredFieldItem = {
      validate: pendingSubmitted || isValidMap.distributor,
      anchorId: 'basic_properties',
      title: 'Distributor',
      isValid: isValidMap.distributor,
    };

    return {
      title,
      groups: [
        {
          description,
          fields: [
            name,
            id,
            ...(mode === 'employer' ? [partner, distributor] : []),
            addressLine1,
            city,
            adressState,
            zipCode,
          ],
        },
      ],
    };
  }, [employer, address, pendingSubmitted, mode]);

  const contactsSecurity: RequiredFieldPropertie = useMemo(
    () => ({
      title: 'Contacts/Security',
      groups: [
        {
          description: 'Add at least 1 contact to activate the organization:',
          fields: [
            {
              validate: activateSubmitted || hasOneContact,
              anchorId: 'contacts_security',
              title: 'Add at least 1 contact',
              isValid: hasOneContact,
            },
          ],
        },
      ],
    }),
    [activateSubmitted, hasOneContact]
  );
  const linkManagement: RequiredFieldPropertie = useMemo(
    () => ({
      title: 'Link Management',
      groups: [
        {
          description: 'Required to activate or save the organization',
          fields: [
            {
              validate: pendingSubmitted || _.get(linkManagementValidators, 'domainAddress'),
              anchorId: 'link_management_section',
              title: 'Domain',
              isValid: _.get(linkManagementValidators, 'domainAddress'),
            },
            {
              validate: pendingSubmitted || _.get(linkManagementValidators, 'privacyPolicyLink'),
              anchorId: 'link_management_section',
              title: 'Privacy Policy',
              isValid: _.get(linkManagementValidators, 'privacyPolicyLink'),
            },
            {
              validate: pendingSubmitted || _.get(linkManagementValidators, 'termAndConditionsLink'),
              anchorId: 'link_management_section',
              title: 'Terms and conditions',
              isValid: _.get(linkManagementValidators, 'termAndConditionsLink'),
            },
            {
              validate: pendingSubmitted || _.get(linkManagementValidators, 'aboutUsLink'),
              anchorId: 'link_management_section',
              title: 'About us',
              isValid: _.get(linkManagementValidators, 'aboutUsLink'),
            },
          ],
        },
      ],
    }),
    [linkManagementValidators, pendingSubmitted]
  );

  const mobileAppCustomization: RequiredFieldPropertie = useMemo(() => {
    const googlePlayLink: RequiredFieldItem = {
      anchorId: 'mobile_app_customization',
      title: 'Google Play link',
      isValid: !!mobileAppCustomizations?.state?.googlePlayLink?.length,
      validate: true,
    };
    const appStoreLink: RequiredFieldItem = {
      anchorId: 'mobile_app_customization',
      title: 'App Store link',
      isValid: !!mobileAppCustomizations?.state?.appStoreLink?.length,
      validate: true,
    };

    return {
      title: 'Mobile App Customization',
      groups: [
        {
          description: 'Following links are required:',
          fields: [googlePlayLink, appStoreLink],
          firstLineFields: 2,
        },
      ],
    };
  }, [mobileAppCustomizations]);
  const hasMobileAppCustomizationError = useMemo(() => {
    if (
      isPartnerPage &&
      mobileAppCustomizations?.state?.participantMobileApp === PartnerMobileApp.PARTNER_OWN_APP
    ) {
      return mobileAppCustomization.groups.some((group) => group.fields.some((field) => !field.isValid));
    }
  }, [mobileAppCustomization, isPartnerPage, mobileAppCustomizations]);
  const financialDetails: RequiredFieldPropertie = useMemo(() => {
    const administratorFees: RequiredFieldItem = {
      anchorId: 'bank_information',
      title: 'Administrator fees',
      validate: activateSubmitted || Boolean(accountUsage?.administratorFeesBank),
      isValid: Boolean(accountUsage?.administratorFeesBank),
    };
    const payrollContribution: RequiredFieldItem = {
      anchorId: 'bank_information',
      title: 'Payroll contribution',
      validate: activateSubmitted || Boolean(accountUsage?.payrollContributionBank),
      isValid: Boolean(accountUsage?.payrollContributionBank),
    };
    const individualContribution: RequiredFieldItem = {
      anchorId: 'bank_information',
      title: 'Individual contribution',
      validate: true,
      ...(eeContributionsHoldPeriodPermProps?.disabled
        ? {
          isValid: true,
          isValidForActivation: true,
        }
        : {
          isValid:
              activateSubmitted || isActiveOrg
                ? _.isFinite(parseInt(accountUsage?.eeContributionsHoldPeriod || '', 10))
                : !!accountUsage?.eeContributionsHoldPeriod,
          isValidForActivation: _.isFinite(parseInt(accountUsage?.eeContributionsHoldPeriod || '', 10)),
        }),
    };

    const fundingReplenishment: RequiredFieldItem = {
      anchorId: 'bank_information',
      title: 'Select a bank account for "Funding replenishment"',
      validate: activateSubmitted || Boolean(accountUsage?.fundingReplenishmentBank),
      isValid: Boolean(accountUsage?.fundingReplenishmentBank),
    };
    const bankAccount: RequiredFieldItem = {
      anchorId: 'bank_information',
      title: 'Add at least 1 bank account',
      isValid: bankList.length > 0,
      validate: activateSubmitted || bankList.length > 0,
    };
    const electronicFundingReplenishment: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Electronic funding replenishment',
      isValid: true,
      validate: true,
    };
    const checkWriting: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Check writing',
      isValid: true,
      validate: true,
    };
    const preFundPercent: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Percent',
      validate: true,
      ...(replenishmentPercentPermProps?.disabled
        ? {
          isValid: true,
          isValidForActivation: true,
        }
        : {
          isValid: isActiveOrg
            ? !!accountUsage.preFundPercent && percentValidate(Number(accountUsage?.preFundPercent))
            : percentValidate(Number(accountUsage?.preFundPercent || 1)),
          isValidForActivation:
              !!accountUsage.preFundPercent && percentValidate(Number(accountUsage?.preFundPercent)),
        }),
    };
    const preFundMinValue: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Minimum value',
      validate: true,
      ...(replenishmentMinValuePermProps?.disabled
        ? {
          isValid: true,
          isValidForActivation: true,
        }
        : {
          isValid: isActiveOrg
            ? !!accountUsage?.preFundMinimumValue &&
                currencyValidate(Number(accountUsage?.preFundMinimumValue))
            : currencyValidate(Number(accountUsage?.preFundMinimumValue || 1)),
          isValidForActivation:
              !!accountUsage?.preFundMinimumValue &&
              currencyValidate(Number(accountUsage?.preFundMinimumValue)),
        }),
    };
    const preFundElectionChangePercentage: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Election change percentage',
      validate: true,
      ...(replenishmentCreepPercentPermProps?.disabled
        ? {
          isValid: true,
          isValidForActivation: true,
        }
        : {
          isValid:
              activateSubmitted || isActiveOrg
                ? !!accountUsage?.preFundElectionChangePercentage &&
                  percentValidate(Number(accountUsage?.preFundElectionChangePercentage))
                : percentValidate(Number(accountUsage?.preFundElectionChangePercentage || 1)),
          isValidForActivation:
              !!accountUsage?.preFundElectionChangePercentage &&
              percentValidate(Number(accountUsage?.preFundElectionChangePercentage)),
        }),
    };
    const preFundMinimumThreshold: RequiredFieldItem = {
      anchorId: 'funding_model',
      title: 'Minimum threshold',
      validate: true,
      ...(replenishmentCreepMinPermProps?.disabled
        ? {
          isValid: true,
          isValidForActivation: true,
        }
        : {
          isValid:
              activateSubmitted || isActiveOrg
                ? !!accountUsage?.preFundMinimumThreshold &&
                  currencyValidate(Number(accountUsage?.preFundMinimumThreshold))
                : currencyValidate(Number(accountUsage?.preFundMinimumThreshold || 1)),
          isValidForActivation:
              !!accountUsage?.preFundMinimumThreshold &&
              currencyValidate(Number(accountUsage?.preFundMinimumThreshold)),
        }),
    };
    return {
      title: 'Financial Details',
      groups: [
        {
          description: 'Select an option for:',
          fields: [
            electronicFundingReplenishment,
            checkWriting,
            administratorFees,
            payrollContribution,
            individualContribution,
          ],
          firstLineFields: 2,
        },
        {
          description:
            'Add at least 1 bank account and select a bank account for "Funding replenishment" to activate the organization:',
          fields: [
            bankAccount,
            fundingReplenishment,
            ...(showPreFund
              ? [preFundPercent, preFundMinValue, preFundElectionChangePercentage, preFundMinimumThreshold]
              : []),
          ],
          firstLineFields: 1,
        },
      ],
    };
  }, [
    activateSubmitted,
    bankList,
    accountUsage,
    // fundingModel,
    // overridableProperties,
    // normalProperties,
    showPreFund,
    isActiveOrg,
    eeContributionsHoldPeriodPermProps,
    replenishmentPercentPermProps,
    replenishmentMinValuePermProps,
    replenishmentCreepPercentPermProps,
    replenishmentCreepMinPermProps,
  ]);
  const hasPreFundError = useMemo(
    () =>
      (showPreFund && financialDetails.groups[1].fields.some((field) => !field.isValid)) ||
      (accountUsage.migrationPreFundStartDate &&
        !dateValidatorByFormat(accountUsage.migrationPreFundStartDate, DEFAULT_DATE_FORMAT)),
    [financialDetails, showPreFund, accountUsage]
  );

  const isMigrationPreFundStartDateValid = useMemo(
    () =>
      accountUsage.migrationPreFundStartDate &&
      (!dateValidatorByFormat(accountUsage.migrationPreFundStartDate, DEFAULT_DATE_FORMAT) ||
        (!isDateInFuture(accountUsage.migrationPreFundStartDate) &&
          accountUsage.migrationPreFundStartDate !== overridableProperties?.prefundInitialDelayDate)),
    [accountUsage.migrationPreFundStartDate, overridableProperties?.prefundInitialDelayDate]
  );

  const hasPreFundErrorForActivation = useMemo(
    () =>
      (showPreFund &&
        financialDetails.groups[1].fields.some(
          (field) => _.isBoolean(field.isValidForActivation) && !field.isValidForActivation
        )) ||
      isMigrationPreFundStartDateValid,
    [showPreFund, financialDetails, isMigrationPreFundStartDateValid]
  );

  const hasFinancialDetailsErrorForActivation = useMemo(
    () =>
      financialDetails.groups[0].fields.some(
        (field) => _.isBoolean(field.isValidForActivation) && !field.isValidForActivation
      ),
    [financialDetails]
  );

  const features: RequiredFieldPropertie = useMemo(() => {
    const allowReimbursement: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Allow Reimbursement',
      isValid:
        isReimbursementAllowedPermProps?.disabled ||
        typeof reimbursementStore?.isReimbursementAllowed === 'boolean',
      validate: true,
    };
    const allowedPaymentMethods: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Allowed payment methods',
      isValid: reimbursementStore?.allowedPaymentMethods
        ? Boolean(reimbursementStore?.allowedPaymentMethods?.length)
        : Boolean(overridableProperties?.allowedPaymentMethods?.length),
      validate: true,
    };
    const allowBillPay: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Allow Bill Pay',
      isValid:
        isBillPayAllowedPermProps?.disabled || typeof reimbursementStore?.isBillPayAllowed === 'boolean',
      validate: true,
    };
    const allowClaimOffsets: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Allow Claim Offsets',
      isValid:
        isClaimOffsetsAllowedPermProps?.disabled || typeof reimbursementStore?.isClaimOffsetsAllowed === 'boolean',
      validate: true,
    };
    const minimumPaymentReimbursementAmount: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Minimum Payment/Reimbursement amount',
      ...(minimumPaymentReimbursementAmountPermProps?.disabled
        ? {
          isValid: true,
        }
        : {
          isValid: activateSubmitted
            ? !!reimbursementStore?.minimumPaymentReimbursementAmount &&
                Number.isInteger(reimbursementStore?.minimumPaymentReimbursementAmount)
            : !reimbursementStore?.minimumPaymentReimbursementAmount ||
                Number.isInteger(reimbursementStore?.minimumPaymentReimbursementAmount),
        }),
      validate: true,
    };
    const waitingForFundsExpirationValue = Number(reimbursementStore?.waitingForFundsExpiration || '');
    const waitingForFundsExpiration: RequiredFieldItem = {
      anchorId: 'reimbursement_options',
      title: 'Days to process claims waiting for funds',
      isValid:
        waitingForFundsExpirationValue >= WAITING_FOR_FUNDS_EXPIRATION_RANGE[0] &&
        waitingForFundsExpirationValue <= WAITING_FOR_FUNDS_EXPIRATION_RANGE[1],
      validate: true,
    };

    const orphanEmployer: RequiredFieldItem = {
      anchorId: 'orphan_configuration',
      title: 'Specify orphan employer',
      isValid: !!orphanConfigurationStore?.orphanEmployer,
      validate: true,
    };
    return {
      title: 'Features',
      groups: [
        {
          description: 'Reimbursement options are required:',
          fields: [
            allowReimbursement,
            ...(reimbursementStore?.isReimbursementAllowed ? [allowedPaymentMethods] : []),
            allowBillPay,
            allowClaimOffsets,
            minimumPaymentReimbursementAmount,
            waitingForFundsExpiration,
          ],
          firstLineFields: 2,
        },
        ...(orphanConfigurationStore.isEmployerOrphan === NO_KEY
          ? [
            {
              description: 'Orphan Configuration options are required:',
              fields: [orphanEmployer],
              firstLineFields: 2,
            },
          ]
          : []),
      ],
    };
  }, [
    orphanConfigurationStore,
    reimbursementStore,
    overridableProperties,
    activateSubmitted,
    isReimbursementAllowedPermProps,
    isBillPayAllowedPermProps,
    minimumPaymentReimbursementAmountPermProps,
    isClaimOffsetsAllowedPermProps,
  ]);
  const hasFeaturesError = useMemo(() => {
    if (isSubsidiaryPage) return false;
    return features.groups.some((group) => group.fields.some((field) => !field.isValid));
  }, [features, isSubsidiaryPage]);

  const fileManagerSettings = useMemo(() => {
    const types = {
      anchorId: 'file_types',
      title: 'File types',
      isValid: fileManagerSettingsStore?.allowedFileTypes
        ? Boolean(fileManagerSettingsStore?.allowedFileTypes?.length)
        : Boolean(overridableProperties?.allowedFileTypes?.length),
      validate: true,
    };
    const autopost = {
      anchorId: 'autopost',
      title: 'Autopost',
      isValid: yesOrNoToBoolean(fileManagerSettingsStore?.isFileAutoPostEnabled) !== undefined,
      validate: true,
    };
    const fileLocation = {
      anchorId: 'file_location',
      title: 'File location',
      isValid:
        !fileManagerSettingsStore?.isSendFiles ||
        (fileManagerSettingsStore?.isSendFiles && !!fileManagerSettingsStore?.fileLocation?.trim()?.length),
      validate: true,
    };

    return {
      title: 'Files/Imports',
      groups: [
        {
          description: 'Required to activate the organization:',
          fields: fileManagerSettingsStore.isFileManagerAccessEnabled ? [types, autopost, fileLocation] : [],
          firstLineFields: 2,
        },
      ],
    };
  }, [fileManagerSettingsStore, overridableProperties]);
  const hasFileManagerError = useMemo(
    () => fileManagerSettings.groups.some((group) => group.fields.some((field) => !field.isValid)),
    [fileManagerSettings]
  );


  const cards: RequiredFieldPropertie = useMemo(() => {
    const numberOfDaysBeforePlanStart: RequiredFieldItem = {
      anchorId: 'cards_section',
      title: 'Card Production',
      ...(numberOfDaysBeforePlanStartPermProps?.disabled
        ? {
          validate: true,
          isValid: true,
        }
        : {
          validate: activateSubmitted || Boolean(overridableProperties?.numberOfDaysBeforePlanStart),
          isValid: Boolean(cardsSetup?.numberOfDaysBeforePlanStart),
        }),
    };
    const phoneNumber = {
      anchorId: 'cards_section',
      title: 'Card phone number',
      ...(cardPhoneNumberPermProps?.disabled
        ? {
          validate: true,
          isValid: true,
        }
        : {
          validate: activateSubmitted || Boolean(overridableProperties?.cardPhoneNumber),
          isValid: Boolean(!cardsSetup?.cardOffered || cardsSetup?.cardPhoneNumber),
        }),
    };
    const url = {
      anchorId: 'cards_section',
      title: 'Card URL',
      ...(cardURLPermProps?.disabled
        ? {
          validate: true,
          isValid: true,
        }
        : {
          validate: activateSubmitted || Boolean(overridableProperties?.cardUrl),
          isValid: Boolean(!cardsSetup?.cardOffered || cardsSetup?.cardURL),
        }),
    };
    const name = {
      anchorId: 'cards_section',
      title: `Card ${mode} name`,
      ...(cardEmployerNamePermProps?.disabled
        ? {
          validate: true,
          isValid: true,
        }
        : {
          validate: activateSubmitted || Boolean(overridableProperties?.cardEmployerName),
          isValid: Boolean(!cardsSetup?.cardOffered || cardsSetup?.cardEmployerName),
        }),
    };

    return {
      title: 'Cards',
      groups: [
        {
          description: 'Required to activate the organization (applies only if the "Cards" is selected):',
          fields: [numberOfDaysBeforePlanStart, phoneNumber, url, name],
          firstLineFields: 2,
        },
      ],
    };
  }, [
    cardsSetup,
    mode,
    activateSubmitted,
    overridableProperties,
    numberOfDaysBeforePlanStartPermProps,
    cardPhoneNumberPermProps,
    cardURLPermProps,
    cardEmployerNamePermProps,
  ]);
  const isReportsValid = useCustomReportsValidator();
  const customReports: RequiredFieldPropertie = useMemo(() => {
    
    const reports: RequiredFieldItem = {
      anchorId: 'reports',
      title: 'Reports',
      validate: true,
      isValid: isReportsValid,
    };

    return {
      title: 'Reports',
      groups: [
        {
          description: 'Required to activate the organization:',
          fields: [reports],
    
        },
      ],
    };
  }, [isReportsValid]);
  const hasCustomReportsErrors = useMemo(
    () => customReports.groups.some((group) => group.fields.some((field) => !field.isValid)),
    [customReports]
  );
  
  const isAccountUsageFilled = useMemo(() => {
    if (
      !accountUsage?.administratorFeesBank ||
      !accountUsage?.fundingReplenishmentBank ||
      !accountUsage?.payrollContributionBank
    ) {
      return false;
    }

    if (
      !accountUsage?.fundingReplenishmentMethod &&
      !overridableProperties?.externalReplenishmentAccountMethod
    ) {
      return false;
    }

    if (
      accountUsage?.administratorFeesBank !== NON_ASSIGNABLE_VALUE &&
      !accountUsage?.administratorFeesMethod &&
      !overridableProperties?.externalAdministratorFeesAccountMethod
    ) {
      return false;
    }

    if (
      accountUsage?.payrollContributionBank !== NON_ASSIGNABLE_VALUE &&
      !accountUsage?.payrollContributionMethod &&
      !overridableProperties?.externalPayrollContributionsAccountMethod
    ) {
      return false;
    }

    return true;
  }, [accountUsage, overridableProperties]);

  const isAccountUsageFrequencyMonthlyValid = useMemo(() => {
    if (
      !accountUsage?.fundingReplenishmentBank ||
      accountUsage?.replenishmentFrequencyType !== FrequencyOptions.MONTHLY
    ) {
      return true;
    }
    const replenishmentCustomDay = _.toNumber(accountUsage.replenishmentCustomDay);
    return replenishmentCustomDay > 0 && replenishmentCustomDay <= 31;
  }, [accountUsage]);

  const isTechnicalSettingsValid = useMemo(() => {
    const sessionTimeoutLogout = technicalSettings?.sessionTimeoutLogout;
    const sessionTimeoutWarn = technicalSettings?.sessionTimeoutWarn;

    if (!!sessionTimeoutWarn && (sessionTimeoutWarn <= 0 || sessionTimeoutWarn > 99)) {
      return false;
    }
    if (!!sessionTimeoutLogout && (sessionTimeoutLogout <= 0 || sessionTimeoutLogout > 99)) {
      return false;
    }

    return true;
  }, [technicalSettings]);
  const formatedPendingBasicInfoError = useMemo(() => {
    const isUrlValid = validateUrl().test(generalInfo?.url);
    const validationBlocks: string[] = [];
    if (!employer?.employerName || employer?.employerName?.length > MAX_INPUT_LENGTH) {
      validationBlocks.push(VALIDATION_TEXT.EMPLOYER_NAME(mode));
    }
    if (
      !address?.city ||
      address?.city?.length > MAX_INPUT_LENGTH ||
      !address.state ||
      !address.street ||
      address?.street?.length > MAX_INPUT_LENGTH ||
      address?.street_2?.length > MAX_INPUT_LENGTH ||
      (address?.zipCode?.length !== 5 && address?.zipCode?.length !== 10)
    ) {
      validationBlocks.push(VALIDATION_TEXT.ADDRESS);
    }
    if (!_.isEmpty(activateErrors) || !isUrlValid) {
      validationBlocks.push(VALIDATION_TEXT.GENERAL_INFORMATION);
    }
    if (!isAccountUsageFrequencyMonthlyValid) {
      validationBlocks.push(VALIDATION_TEXT.CUSTOM_DAY);
    }
    if (!isTechnicalSettingsValid) {
      validationBlocks.push(VALIDATION_TEXT.TECHNICAL_SETTINGS);
    }
    if (hasFileManagerError) {
      validationBlocks.push(VALIDATION_TEXT.FILE_MANAGER);
    }
    if (_.isEmpty(validationBlocks)) {
      return '';
    }
    return `${VALIDATION_TEXT.BASIC_INFO} (${validationBlocks.join(', ')})`;
  }, [
    mode,
    employer,
    address,
    activateErrors,
    generalInfo,
    isAccountUsageFrequencyMonthlyValid,
    isTechnicalSettingsValid,
    hasFileManagerError,
  ]);

  const formatedPendingCardsInfoError = useMemo(() => {
    if (isSubsidiaryPage) return [];
    const validationBlocks: string[] = [];
    if (
      cardsSetup?.cardOffered &&
      (!cardsSetup?.cardPhoneNumber || cardsSetup.cardPhoneNumber.length > 26) &&
      !cardPhoneNumberPermProps?.disabled
    ) {
      validationBlocks.push('Card phone number');
    }
    if (
      cardsSetup?.cardOffered &&
      (!cardsSetup?.cardURL ||
        cardsSetup?.cardURL?.length > 50 ||
        REGEXPS.PARENTHESES.test(cardsSetup?.cardURL)) &&
      !cardURLPermProps?.disabled
    ) {
      validationBlocks.push('Card URL');
    }
    if (
      cardsSetup?.cardOffered &&
      (!cardsSetup?.cardEmployerName ||
        cardsSetup?.cardEmployerName?.length > 26 ||
        REGEXPS.PARENTHESES.test(cardsSetup?.cardEmployerName)) &&
      !cardEmployerNamePermProps?.disabled
    ) {
      validationBlocks.push(`Card ${mode.charAt(0).toUpperCase() + mode.slice(1)} name`);
    }
    if (
      cardsSetup?.cardProcessingStartDate &&
      (!dateValidatorByFormat(cardsSetup?.cardProcessingStartDate, DEFAULT_DATE_FORMAT) ||
        (!isDateInFuture(cardsSetup?.cardProcessingStartDate) &&
          cardsSetup?.cardProcessingStartDate !== overridableProperties?.cardProcessingStartDate))
    ) {
      validationBlocks.push('cardProcessingStartDate');
    }
    return validationBlocks;
  }, [
    cardsSetup,
    mode,
    isSubsidiaryPage,
    cardPhoneNumberPermProps,
    cardURLPermProps,
    cardEmployerNamePermProps,
    overridableProperties?.cardProcessingStartDate,
  ]);

  const linkManagementErrors = useMemo(() => {
    if (!isPartnerPage) {
      return [];
    }
    const result: string[] = [];
    Object.keys(linkManagementValidators).forEach((key) => {
      if (linkManagementValidators[key] === false) {
        result.push(key);
      }
    });

    return result;
  }, [linkManagementValidators, isPartnerPage]);
  
  const formatedPendingErrors = useMemo(
    () =>
      _.compact([
        formatedPendingBasicInfoError,
        ...linkManagementErrors,
        ...(hasCustomReportsErrors ? [CUSTOM_REPORTS_ERROR]: []),
        ...(hasFeaturesError ? [REIMB_ERROR] : []),
        ...(hasPreFundError ? [PREFUND_ERROR] : []),
      ]),
    [linkManagementErrors, hasCustomReportsErrors,formatedPendingBasicInfoError, hasFeaturesError, hasPreFundError]
  );

  const formatedPendingSuccesses = useMemo(() => {
    if (!formatedPendingErrors.length && !_.isEmpty(state)) {
      return [
        `${VALIDATION_TEXT.BASIC_INFO} (${VALIDATION_TEXT.EMPLOYER_NAME(mode)}, ${VALIDATION_TEXT.ADDRESS})`,
      ];
    }
    return [];
  }, [mode, state, formatedPendingErrors]);

  const formatedActivateErrors = useMemo(() => {
    const currentErrors = [];
    if (_.isEmpty(bankList) || !isAccountUsageFilled) {
      currentErrors.push(BLOCKS_VALIDATION_TEXT.FINANCIAL_DETAILS);
    }
    if (!_.isEmpty(formatedPendingCardsInfoError)) {
      currentErrors.push(...formatedPendingCardsInfoError);
    }
    return _.compact([
      formatedPendingBasicInfoError ? VALIDATION_TEXT.BASIC_INFO : null,
      ...linkManagementErrors,
      ...blocksErrors,
      ...currentErrors,
      ...(hasCustomReportsErrors ? [CUSTOM_REPORTS_ERROR]: []),
      ...(hasFeaturesError ? [REIMB_ERROR] : []),
      ...(hasPreFundErrorForActivation ? [PREFUND_ERROR] : []),
      ...(hasFinancialDetailsErrorForActivation ? [FINANCE_ERROR] : []),
      ...(hasMobileAppCustomizationError ? [MOBILE_APP_CUSTOMIZATION_ERROR] : []),
    ]);
  }, [
    bankList,
    hasCustomReportsErrors,
    isAccountUsageFilled,
    formatedPendingCardsInfoError,
    formatedPendingBasicInfoError,
    linkManagementErrors,
    blocksErrors,
    hasFeaturesError,
    hasPreFundErrorForActivation,
    hasFinancialDetailsErrorForActivation,
    hasMobileAppCustomizationError,
  ]);
  const formatedActivateSuccesses = useMemo(() => {
    if (!_.isEmpty(state)) {
      const messages = [
        VALIDATION_TEXT.BASIC_INFO,
        BLOCKS_VALIDATION_TEXT.CONTACTS_AND_SECURITY,
        BLOCKS_VALIDATION_TEXT.FINANCIAL_DETAILS,
        // BLOCKS_VALIDATION_TEXT.STATUS,
      ];
      return _.difference(messages, formatedActivateErrors);
    }
    return [];
  }, [formatedActivateErrors, state]);
  
  return {
    basicProperties,
    contactsSecurity,
    linkManagement,
    ...(mobileAppCustomizations?.state?.participantMobileApp === PartnerMobileApp.PARTNER_OWN_APP
      ? { mobileAppCustomization }
      : {}),
    financialDetails,
    features,
    cards,
    fileManagerSettings,
    customReports,
    formatedPendingSuccesses,
    formatedPendingErrors,
    formatedActivateErrors,
    formatedActivateSuccesses,
  };
};

export default useSaveValidate;
