import React, { useCallback, useMemo,useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Inscription, RoundDoneIcon } from '@common-fe/common-fe';
import _ from 'lodash';

import ROUTES from '@/common/routes';

import { EMAIL_FIELD_KEY } from '../ForgotUsername/ForgotUsername';

export enum STEPS {
  SIGN_IN = 'SignIn',
  FORGOT_USERNAME = 'forgotUsername',
  FORGOT_USERNAME_SUCCESS = 'forgotUsernameSuccess',
  FORGOT = 'Forgot',
  NEW_PASSWORD = 'New password',
}
const FORGOT_USERNAME_FIELD_KEY = 'forgotUsername';
export const FORGOT_SUBTITLE = 'Please enter the username associated with your account and a code will be emailed to you.';
const useSignInStepper =  () => {
  const [step, setStep] = useState<STEPS>(STEPS.SIGN_IN);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  const isChangePasswordMode = useRouteMatch().path === ROUTES.CHANGE_PASSWORD;
  const title = useMemo(() => {
    if (isChangePasswordMode) {
      return 'Change Password';
    }
    if (step === STEPS.FORGOT) {
      return 'Forgot Password?';
    }
    if (step === STEPS.FORGOT_USERNAME) {
      return 'Forgot Username?';
    }
    if (step === STEPS.FORGOT_USERNAME_SUCCESS) {
      return (
        <Box align="center">
          <RoundDoneIcon />
          <Box margin={{ top: 'spacing24', bottom: 'spacing4' }}>
            <Inscription weight="bold" size="2xl" lineHeight="32px">{email}</Inscription>
          </Box>
        </Box>
      );
    }
    if (step === STEPS.NEW_PASSWORD) {
      return '';
    }
    return 'Welcome!';
  }, [isChangePasswordMode, step, email]);
  const handlePreviousStep = useCallback(() => {
    if (step === STEPS.FORGOT) {
      setStep(STEPS.SIGN_IN);
    } else if (step === STEPS.FORGOT_USERNAME) {
      setStep(STEPS.SIGN_IN);
    } else if (step === STEPS.NEW_PASSWORD) {
      setStep(STEPS.FORGOT);
    }
  }, [step]);
  const isBackButton = useMemo(() => step === STEPS.FORGOT || step === STEPS.FORGOT_USERNAME || step === STEPS.NEW_PASSWORD, [step]);
  const subtitle = useMemo(() => {
    if (step === STEPS.FORGOT) {
      return 'Please enter the username associated with your account and a code will be emailed to you.';
    }
    if (step === STEPS.FORGOT_USERNAME) {
      return 'Please enter the email address associated with your account and a code will be emailed to you.';
    }
    if (step === STEPS.FORGOT_USERNAME_SUCCESS) {
      return 'If the email is on file, you will receive an email with your username';
    }
    if (step === STEPS.NEW_PASSWORD) {
      return '';
    }
    return '';
  }, [step]);

  const handleNextStep = useCallback(async (value?: object) => {
    switch (step) {
    case STEPS.FORGOT: {
      const currentUsername: string = _.get(value, FORGOT_USERNAME_FIELD_KEY, '');
      setUsername(currentUsername);
      setStep(STEPS.NEW_PASSWORD);
      break;
    }
    case STEPS.NEW_PASSWORD:
      setStep(STEPS.SIGN_IN);
      break;
    case STEPS.FORGOT_USERNAME_SUCCESS:
      setStep(STEPS.SIGN_IN);
      break;
    case STEPS.FORGOT_USERNAME: {
      const userEmail: string = _.get(value, EMAIL_FIELD_KEY, '');
      setStep(STEPS.FORGOT_USERNAME_SUCCESS);
      setEmail(userEmail);
      break;
    }
    default:
      setStep(STEPS.FORGOT);
    }
  }, [step]);
  return {
    step,
    username,
    title,
    subtitle,
    isBackButton,
    isChangePasswordMode,
    handlePreviousStep,
    handleNextStep,
    handleForgotUsername: () => setStep(STEPS.FORGOT_USERNAME),
  };
};

export default useSignInStepper;
