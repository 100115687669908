import React, { useEffect,useState } from 'react';
import {
  Box,
  FlexControlledForm,
  useDebounce,
} from '@common-fe/common-fe';

import FormLoader from '@/modules/employer/components/SetupEmployer/FormLoader';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSetupEmployerStore } from '@/modules/employer/store/useSetupEmployer.store';

import ResetButton from './components/ResetButton';
import { useTechnicalSettingsForm } from './hooks/useTechnicalSettingsForm';
import { useTechnicalSettingsStore } from './stores/useTechnicalSettings.store';

const TechnicalSettings = () => {
  const { loading } = useSetupEmployerStore();
  const { viewMode } = useSetupEmployerMode();
  const [currentValues, setCurrentValues] = useState({});
  const debouncedValue = useDebounce(currentValues);
  const fields = useTechnicalSettingsForm(currentValues);
  const { setTechnicalSettings } = useTechnicalSettingsStore();

  useEffect(() => {
    setTechnicalSettings(debouncedValue);
  }, [debouncedValue, setTechnicalSettings]);

  if (loading) return <FormLoader testId="technical-settings-loader" />;

  return (
    <Box
      data-testId="technical-settings-wrapper"
      direction="row"
      background="canvas"
      round="container1Round"
      border={{ color: 'border2', size: 'small' }}
    >
      <FlexControlledForm
        editMode={!viewMode}
        showError
        fields={fields}
        onChangeValues={setCurrentValues}
        isThinMode
        {...!viewMode ? { shouldControlValues: true } : {}}
        wrapperStyle={{ border: 'none', paddingBottom: 0 }}
      />
      <ResetButton
        viewMode={viewMode}
        currentValues={currentValues}
        setCurrentValues={setCurrentValues}
      />
    </Box>
  );
};

export default TechnicalSettings;
