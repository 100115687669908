import React from 'react';
import { Inscription } from '@common-fe/common-fe';
import styled from 'styled-components';

const EllipsisText = styled(Inscription)<{ width?: string }>`
  text-overflow: ellipsis;
  max-width: ${({ width }) => width || '200px'};
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  text?: string,
  substring?: string,
  width?: string,
  title?: string,
}

const BoldSubstring: React.FC<Props> = ({ text = '', substring = '', ...props }) => {
  const startIndex = text.toLocaleLowerCase().indexOf(substring.toLocaleLowerCase());
  const endIndex = startIndex + substring.length;

  const startText = text.substring(0, startIndex);
  const boldText = text.substring(startIndex, endIndex);
  const endText = text.substring(endIndex);

  if (startIndex === -1 || !substring) {
    return (
      <EllipsisText
        data-testId="normal-text"
        color="textBody"
        weight="normal"
        {...props}
      >
        {text}
      </EllipsisText>
    );
  }

  return (
    <EllipsisText
      data-testId="width-bold-text"
      color="textBody"
      weight="normal"
      {...props}
    >
      <Inscription
        data-testId="start-text"
      >
        {startText}
      </Inscription>

      <Inscription
        data-testId="bold-text"
        color="textBody"
        weight="bold"
      >
        {boldText}
      </Inscription>

      <Inscription
        data-testId="end-part"
      >
        {endText}
      </Inscription>
    </EllipsisText>
  );
};

export default BoldSubstring;
