import React, {  useMemo } from 'react';
import { Box, FlexControlledForm, Text, } from '@common-fe/common-fe';

import useCustomReportsFields from './hooks/useCustomReportsFields';
import useStore from './stores/useCustomReports.store';

interface Props {
  isShowErrors?: boolean;
  isEditMode?: boolean;
  isDisabled?: boolean;
  onDirty?: (isDirty: boolean) => void;
}
const CustomReports: React.FC<Props> = ({ isEditMode, isShowErrors, onDirty }) => {
  const fields = useCustomReportsFields({
    isEditMode,
  });
  const sourceState = useStore((state) => state.sourceState);
  const handleSetState = useStore((state) => state.setState);

 
  const key = useMemo(() => {
    return `
    ${sourceState?.customReportDisplayedField1}
    ${sourceState?.customReportDisplayedField2}
    ${sourceState?.customReportDisplayedField3}
    ${sourceState?.customReportDisplayedField4}
    ${sourceState?.customReportDisplayedField5}
`;
  }, [
    sourceState?.customReportDisplayedField1,
    sourceState?.customReportDisplayedField2,
    sourceState?.customReportDisplayedField3,
    sourceState?.customReportDisplayedField4,
    sourceState?.customReportDisplayedField5,
  ]);
  return (
    <Box background="canvas" key={key} round="container1Round">
      <FlexControlledForm
        isResetSubfieldsDisabled
        subTitle={(
          <Box direction="column" margin={{ bottom: 'xs' }}>
            <Text size="xlarge" color="textTitle">
              Custom Reporting
            </Text>
            <Text size="medium" color="textTitle">
              Enable custom fields for reports, then define custom fields names that populate employees’
              profiles and become report columns
            </Text>
          </Box>
        )}
        testId="custom-reporting"
        fields={fields}
        showError={isShowErrors}
        editMode={isEditMode}
        onChangeValues={handleSetState}
        onDirty={onDirty}
        isThinMode
        // onDirty={onDirty}
      />
    </Box>
  );
};

export default CustomReports;
