import React, { useMemo } from 'react';
import {
  Box, Field, FieldTypes, Inscription,
} from '@common-fe/common-fe';
import _ from 'lodash';
import * as yup from 'yup';

import { REGEXPS, VALIDATORS } from '@/common';
import { INTEGER_VALUE_TEXT, INVALID_RANGE_TEXT, REQUIRED_TEXT } from '@/common/constants';
import { usePlanFieldsState, useTemplateFieldState } from '@/modules/plan/PlanSetup/hooks';
import { PLAN_SETUP_FIELD } from '@/modules/plan/PlanSetup/PlanSetup.constants';
import { usePreviewStore } from '@/modules/plan/PlanSetup/stores';
import theme from '@/styles/theme';
import useFieldsWithDefaultValues from '@/utils/hooks/useFieldsWithDefaultValues';

import FinancialHeader from '../FinancialHeader';
import { useAccountFundingParamsStore } from '../stores';

import useBanksList from './useBanksList';

const DAILY_KEY = 'DAILY';
const WEEKLY_KEY = 'WEEKLY';
const MONTHLY_KEY = 'MONTHLY';
const CUSTOM_DAY_FIELD_HINT = 'If a month has fewer days than the entered number, replenishment will be processed on the last day of this month';
const CUSTOM_DAY_ERROR_TEXT = 'This number does not match the range of available numbers (1-31)';
const PRE_FUND_SUBTITLE = 'Elevate holds a portion of member enrollments to support quick claim payments. Each month elections will be re-calculated and determine if additional funds will need to be collected';
const PRE_FUND_SUBFIELDS = {
  PERCENT: 'The percentage of prefund depends on replenishment frequency',
  ELECTION_CHANGE_PERCENTAGE: 'Increased elections from the previous pre-fund where additional funds will be collected',
};

const currencyValueValidate = yup
  .string()
  .test({
    test: (val) => {
      if (val) {
        const value = Number(val);
        return value >= 1 && value <= 999999;
      }
      return true;
    },
    message: INVALID_RANGE_TEXT(1, 999999),
  })
  .test({
    test: (val) => {
      if (val) {
        const value = Number(val);
        return Number.isInteger(value);
      }
      return true;
    },
    message: INTEGER_VALUE_TEXT,
  });

const percentValueValidate = yup
  .string()
  .test({
    test: (val) => {
      if (val) {
        const value = Number(val);
        return value >= 1 && value <= 100;
      }
      return true;
    },
    message: INVALID_RANGE_TEXT(1, 100),
  });

const FIELD_WRAPPER_STYLES = {
  borderTop: `1px solid ${theme.colors.border1}`,
  paddingTop: theme.spacings.m,
};
const ALTERNATIVE_FIELD_WRAPPER_STYLES = {
  borderBottom: `1px solid ${theme.colors.border1}`,
  paddingBottom: theme.spacings.m,
};

export default (isActivationMode?: boolean, isTemplateMode?: boolean, isEditMode?: boolean) => {
  const banksList = useBanksList();
  const sourceState = useAccountFundingParamsStore((state) => state.sourceState);
  const currentState = useAccountFundingParamsStore((state) => state.state);
  const errorFields = useAccountFundingParamsStore((state) => state.errorFields);
  const isPlanLevelFunding = useAccountFundingParamsStore((state) => state.state.planLevelFunding);
  const isPreFundCalc = useAccountFundingParamsStore((state) => state.state.isPreFundCalculated);
  const replenishmentFrequency = useAccountFundingParamsStore(
    (state) => state.state.replenishmentFrequency,
  );

  const isReplenished = useAccountFundingParamsStore((state) => state.state.isReplenished);
  const previewMode = usePreviewStore((state) => state.previewMode);
  const appliedSourceState = useMemo(() => {
    if (previewMode) {
      return currentState;
    }
    return sourceState;
  }, [currentState, previewMode, sourceState]);

  const fundingReplenishmentFields: Field[] = useMemo(() => {
    if (isPlanLevelFunding && isReplenished && !isTemplateMode) {
      let replinshmentFields: Field[] = [];
      if (replenishmentFrequency === WEEKLY_KEY) {
        replinshmentFields = [{
          name: 'replenishmentDayNumber',
          type: FieldTypes.Dropdown,
          label: 'Day of the week',
          singleMode: true,
          options: [
            { key: '1', value: 'Monday' },
            { key: '2', value: 'Tuesday' },
            { key: '3', value: 'Wednesday' },
            { key: '4', value: 'Thursday' },
            { key: '5', value: 'Friday' },
            { key: '6', value: 'Saturday' },
            { key: '7', value: 'Sunday' },
          ],
          placeholder: 'Select frequency',
          showRequireIcon: true,
          fieldWrapperStyles: FIELD_WRAPPER_STYLES,
          validator: VALIDATORS.REQUIRED_STRING,
        }];
      }
      if (replenishmentFrequency === MONTHLY_KEY) {
        replinshmentFields = [{
          name: 'replenishmentDayNumber',
          label: 'Custom',
          type: FieldTypes.Number,
          lowerHelpText: CUSTOM_DAY_FIELD_HINT,
          showRequireIcon: true,
          fieldWrapperStyles: FIELD_WRAPPER_STYLES,
          placeholder: 'Enter custom day number',
          validator: VALIDATORS.REQUIRED_STRING.test({
            exclusive: false,
            test: (val) => {
              const customDayNumber = _.toNumber(val);
              return customDayNumber > 0 && customDayNumber <= 31;
            },
            message: CUSTOM_DAY_ERROR_TEXT,

          }).required(REQUIRED_TEXT),
        }];
      }
      return [
        {
          name: 'financeTitle',
          type: FieldTypes.Node,
          fieldWrapperStyles: FIELD_WRAPPER_STYLES,
          value: (
            <FinancialHeader
              title="Funding Replenishment"
              subTitle="Funds collected to replenish the plans account after claim payment have been made."
            />
          ),
        },
        {
          name: 'replenishmentBankAccount',
          type: FieldTypes.Dropdown,
          label: 'Bank account',
          singleMode: true,
          options: banksList,
          placeholder: 'Select bank account',
          showRequireIcon: true,
          validator: VALIDATORS.REQUIRED_STRING,
        }, {
          name: 'replenishmentMethod',
          type: FieldTypes.Radio,
          label: 'Method',
          options: [
            { label: 'Push funds', value: 'PUSH_FUNDS' },
            { label: 'Pull funds', value: 'PULL_FUNDS' },
          ],
          showRequireIcon: true,
          validator: VALIDATORS.REQUIRED_STRING,
        }, {
          name: 'replenishmentFrequency',
          type: FieldTypes.Radio,
          label: 'Frequency',
          options: [
            { label: 'Daily', value: DAILY_KEY },
            { label: 'Weekly', value: WEEKLY_KEY },
            { label: 'Monthly', value: MONTHLY_KEY },
          ],
          showRequireIcon: true,
          // fieldWrapperStyles: replenishmentFrequency === DAILY_KEY ? FIELD_WRAPPER_STYLES : {},
          validator: VALIDATORS.REQUIRED_STRING,
        },

        ...replinshmentFields,
      ];
    }
    return [];
  }, [banksList, isPlanLevelFunding, isReplenished, isTemplateMode, replenishmentFrequency]);

  const preFundFields = useMemo(() => {
    if (isReplenished) {
      return [
        {
          name: PLAN_SETUP_FIELD.IS_PRE_FUND_CALCULATED,
          type: FieldTypes.Checkbox,
          label: 'Custom prefund calculation',
          fieldWrapperStyles: FIELD_WRAPPER_STYLES,
        },
        ...isPreFundCalc ? [{
          name: 'preFundTitle',
          type: FieldTypes.Node,
          fieldWrapperStyles: FIELD_WRAPPER_STYLES,
          value: (
            <FinancialHeader
              title="Pre-Fund"
              subTitle={PRE_FUND_SUBTITLE}
            />
          ),
        },
        {
          name: PLAN_SETUP_FIELD.REPLENISHMENT_PERCENT,
          type: FieldTypes.Number,
          disabled: !isEditMode,
          showRequireIcon: true,
          validator: isActivationMode
            ? percentValueValidate.required(REQUIRED_TEXT)
            : percentValueValidate,
          placeholder: 'Select Percent',
          label: (
            <Box flex={{ grow: 1 }}>
              <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                Percent
              </Inscription>
              <Box>
                <Inscription weight={500} color="textSecondary" size="small">
                  {PRE_FUND_SUBFIELDS.PERCENT}
                </Inscription>
              </Box>
            </Box>
          ),
        },
        {
          name: PLAN_SETUP_FIELD.REPLENISHMENT_MIN_VALUE,
          type: FieldTypes.Currency,
          disabled: !isEditMode,
          validator: isActivationMode
            ? currencyValueValidate.required(REQUIRED_TEXT)
            : currencyValueValidate,
          placeholder: 'Select Minimum Value',
          label: 'Minimum value',
          showRequireIcon: true,
        },
        {
          name: PLAN_SETUP_FIELD.REPLENISHMENT_CREEP_PERCENT,
          type: FieldTypes.Number,
          disabled: !isEditMode,
          showRequireIcon: true,
          validator: isActivationMode
            ? percentValueValidate.required(REQUIRED_TEXT)
            : percentValueValidate,
          placeholder: 'Select Election Change Percentage',
          label: (
            <Box flex={{ grow: 1 }}>
              <Inscription margin={{ bottom: '4px' }} weight="bold" color="textBody">
                Election change percentage
              </Inscription>
              <Box>
                <Inscription weight={500} color="textSecondary" size="small">
                  {PRE_FUND_SUBFIELDS.ELECTION_CHANGE_PERCENTAGE}
                </Inscription>
              </Box>
            </Box>
          ),
        },
        {
          name: PLAN_SETUP_FIELD.REPLENISHMENT_CREEP_MIN,
          type: FieldTypes.Currency,
          disabled: !isEditMode,
          validator: isActivationMode
            ? currencyValueValidate.required(REQUIRED_TEXT)
            : currencyValueValidate,
          placeholder: 'Select Minimum Threshold',
          label: 'Minimum threshold',
          showRequireIcon: true,
          fieldWrapperStyles: ALTERNATIVE_FIELD_WRAPPER_STYLES,
        }] : [],
      ] as Field[];
    }
    return [];
  }, [isReplenished, isPreFundCalc, isEditMode, isActivationMode]);

  const payrollContributionFields: Field[] = useMemo(() => {
    if (isPlanLevelFunding && !isReplenished && !isTemplateMode) {
      return [
        {
          name: 'financeTitle',
          type: FieldTypes.Node,
          fieldWrapperStyles: {
            borderTop: `1px solid ${theme.colors.border1}`,
            paddingTop: theme.spacings.m,
          },
          value: (
            <FinancialHeader
              title="Payroll Contribution"
              subTitle="Elevate collects contributions made to employee-owned accounts, such as an HSA, prior to posting to the employee's account"
            />
          ),
        },
        {
          name: 'contributionBankAccount',
          type: FieldTypes.Dropdown,
          label: 'Bank account',
          singleMode: true,
          options: banksList,
          placeholder: 'Select bank account',
          showRequireIcon: true,
          validator: VALIDATORS.REQUIRED_STRING,
        }, {
          name: 'contributionMethod',
          type: FieldTypes.Radio,
          label: 'Method',
          options: [
            { label: 'Push funds', value: 'PULL_FUNDS' },
            { label: 'Pull funds', value: 'PUSH_FUNDS' },
          ],
          showRequireIcon: true,
          validator: VALIDATORS.REQUIRED_STRING,
        }, {
          name: 'contributionHoldPeriod',
          type: FieldTypes.Number,
          label: 'Hold period',
          regexp: REGEXPS.ONLY_NUMBERS,
          placeholder: 'Enter hold period',
          showRequireIcon: true,
          fieldWrapperStyles: {
            borderBottom: `1px solid ${theme.colors.border1}`,
            paddingBottom: theme.spacings.m,
          },
          validator: VALIDATORS.REQUIRED_POSITIVE_NUMBER_ONLY,
        }];
    }
    return [];
  }, [banksList, isPlanLevelFunding, isReplenished, isTemplateMode]);

  const fields: Field[] = useMemo(() => [
    {
      name: 'isReplenished',
      type: FieldTypes.Checkbox,
      label: 'Is replenished',
      validator: yup.string().test({
        test: (val) => val !== `${errorFields?.isReplenished?.value}`,
        message: errorFields?.isReplenished?.message,
      }),
    },
    {
      name: 'planLevelFunding',
      type: FieldTypes.Checkbox,
      label: 'Plan level funding',
      validator: VALIDATORS.STRING,
    },

    ...payrollContributionFields,

    ...fundingReplenishmentFields,
    ...preFundFields,

    {
      name: 'omnibus',
      type: FieldTypes.Dropdown,
      label: 'Omnibus',
      singleMode: true,
      options: [{ key: 'NOTIONAL', value: 'Notional' }, { key: 'HSA', value: 'HSA' }],
      placeholder: 'Select option',
      showRequireIcon: true,
      validator: isActivationMode
        ? VALIDATORS.REQUIRED_STRING
        : VALIDATORS.STRING,
    },
    {
      name: 'accountCreation',
      type: FieldTypes.Dropdown,
      label: 'Account creation',
      singleMode: true,
      options: [{ key: 'REUSE_WHEN_SAME_ACCOUNT', value: 'Re-use when same account type' }, { key: 'CREATE_NEW', value: 'Always create new' }],
      placeholder: 'Select option',
      showRequireIcon: true,
      validator: isActivationMode
        ? VALIDATORS.REQUIRED_STRING
        : VALIDATORS.STRING,
    },
  ], [fundingReplenishmentFields, isActivationMode, payrollContributionFields, preFundFields, errorFields]);
  const filteredField = usePlanFieldsState(fields);
  const formatedFields = useFieldsWithDefaultValues(filteredField, appliedSourceState);

  const stateFields = useTemplateFieldState(formatedFields, isTemplateMode, isEditMode);
  return stateFields;
};
