import { useCallback, useMemo, useState } from 'react';
// import { useGetReportList } from '@/modules/employer/components/Reports/queries/reports.queries';
import _ from 'lodash';

export const REPORT_SECTIONS_WRAPPER_ID = 'report-sections-wrapper-id';

const DETECT_SECTION_BUFFER = 100;

export const useReportActiveSection = (possibleOffset: number, reportTypes: string[]) => {
  // const history = useHistory();
  // const query = useMemo(
  //   () => new URLSearchParams(history.location.search),
  //   [history.location.search],
  // );
  // const queriedSection = useMemo(() => query.get(QueryKeys.REPORT_TYPE_QUERY), [query]);
  const [highlightedSection, setHighlightedSection] = useState(
    '',
    // queriedSection,
  );
  // const { data } = useGetReportList();
  const getSectionElemMap = useCallback(
    (): { [key: string]: HTMLDivElement } => reportTypes.reduce((map, type) => ({
      ...map,
      [type]: document.getElementById(type),
    }), {}),
    [reportTypes],
  );

  const selectSection = useCallback((type: string) => {
    // history.replaceQueryParameter({ [QueryKeys.REPORT_TYPE_QUERY]: type });
    setHighlightedSection(type);
    const wrapper = document.getElementById(REPORT_SECTIONS_WRAPPER_ID);
    const sectionElemMap = getSectionElemMap();
    const activeSectionWrapElem = sectionElemMap[type];
    if (wrapper && activeSectionWrapElem) {
      wrapper.scrollTo({
        top: activeSectionWrapElem.offsetTop - possibleOffset,
        behavior: 'smooth',
      });
    }
  }, [getSectionElemMap, possibleOffset]);

  const detectActiveSection = useMemo(() => _.debounce(() => {
    const wrapper = document.getElementById(REPORT_SECTIONS_WRAPPER_ID);
    const sectionElemMap = getSectionElemMap();
    if (!wrapper) return;

    const passedScrollTop = wrapper.scrollTop;
    const passedSections = Object.keys(sectionElemMap)
      .filter((key) => sectionElemMap[key]
        && passedScrollTop > (
          sectionElemMap[key].offsetTop - possibleOffset - DETECT_SECTION_BUFFER
        ));
    const currSection = passedSections[passedSections.length - 1];
    const lastSection = Object.keys(sectionElemMap)[Object.keys(sectionElemMap).length - 1];

    if (highlightedSection !== currSection && currSection) setHighlightedSection(currSection);

    if (highlightedSection === lastSection) setHighlightedSection(lastSection);

    if (!currSection) setHighlightedSection(reportTypes[0]);
  }, 100), [getSectionElemMap, highlightedSection, possibleOffset, reportTypes]);

  return {
    activeSection: highlightedSection,
    selectSection,
    detectActiveSection,
  };
};
