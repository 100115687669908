import React, { useEffect, useMemo, useRef } from 'react';
import { Box, Preloader } from '@common-fe/common-fe';
import styled from 'styled-components';

import { OrganizationTypes } from '@/common/constants';
import Permissions from '@/common/permissions';
import { Access } from '@/modules/core/components';
import { useCurrentOrganization,useHistory } from '@/modules/core/hooks';
import theme from '@/styles/theme';

import { PlanStatuses } from '../plan.types';

import useEditMode from './hooks/useEditMode';
import AccountFunding from './AccountFunding';
import ActivityLogSection from './ActivityLogSection';
import Coverage from './Coverage';
import DangerZone from './DangerZone';
import FinalSetup from './FinalSetup';
import {
  useCurrentPlan,
  usePlanSetupSetter,
} from './hooks';
import PlanDefinition from './PlanDefinition';
import PlanSetupPrompt from './PlanSetupPrompt';
import PlanSetupWrapper from './PlanSetupWrapper';
import PlanTopbar from './PlanTopbar';
import { usePlanLoadingStore, usePreviewStore, useSubmitFormStore } from './stores';

const StyledBox = styled(Box)`
  flex: 1 0;
`;
// const TIMEOUT = 1000;
interface Props {
  defaultEditMode?: boolean;
  isTemplateMode?: boolean;
}
const Plans: React.FC<Props> = ({ defaultEditMode, isTemplateMode }) => {
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const activateButtonRef = useRef<HTMLButtonElement>(null);
  const reviewButtonRef = useRef<HTMLButtonElement>(null);
  const { observingOrganization: { type } } = useCurrentOrganization();
  const { planStatus, planName } = useCurrentPlan();
  const isPlanActive = useMemo(() => planStatus === PlanStatuses.ACTIVE || planStatus === PlanStatuses.PENDING_ACTIVE, [planStatus]);
  const previewMode = usePreviewStore((state) => state.previewMode);

  const loading = usePlanLoadingStore((state) => state.loading);
  const isEditMode = useEditMode(
    defaultEditMode,
    isPlanActive,
    isTemplateMode
  );
  const isEditModeApplied = useMemo(() => isEditMode && !previewMode, [isEditMode, previewMode]);

  const isSubsidiary = useMemo(
    () => type === OrganizationTypes.subsidiary,
    [type],
  );
  const setPlanLoadedId = usePlanLoadingStore((state) => state.setPlanLoadedId);
  useEffect(() => {
    return () => {
      if (setPlanLoadedId) setPlanLoadedId('');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { handleReset } = usePlanSetupSetter();
  const handleResetSubmit = useSubmitFormStore((state) => state.reset);
  useEffect(() => () => {
    handleResetSubmit();
    handleReset();
  }, [handleReset, handleResetSubmit]);
  const history = useHistory();
  useEffect(() => {
    if (planStatus === PlanStatuses.DELETED) {
      history.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planStatus]);
  if (loading) {
    return <Preloader testId="preloader" />;
  }

  return (
    <PlanSetupWrapper planStatus={planStatus} isTemplateMode={isTemplateMode} isPlanActive={isPlanActive}>
      <PlanSetupPrompt isTemplateMode={isTemplateMode} />
      <PlanTopbar
        saveButtonRef={saveButtonRef}
        activateButtonRef={activateButtonRef}
        reviewButtonRef={reviewButtonRef}
        viewMode={!isEditMode}
        planStatus={planStatus}
        planName={planName}
        isTemplateMode={isTemplateMode}
        isPlanActive={isPlanActive}
      />
      <StyledBox width={theme.defaultContentWidth}>
        <PlanDefinition
          isEditMode={isEditModeApplied}
          isTemplateMode={isTemplateMode}
          isPlanActive={isPlanActive}
        />
        <Coverage
          isTemplateMode={isTemplateMode}
          isEditMode={isEditModeApplied}
        />
        <AccountFunding
          isEditMode={isEditModeApplied}
          isTemplateMode={isTemplateMode}
        />
        <ActivityLogSection />
        {!previewMode && (
          <FinalSetup
            saveButtonRef={saveButtonRef}
            activateButtonRef={activateButtonRef}
            reviewButtonRef={reviewButtonRef}
            isTemplateMode={isTemplateMode}
            isEditMode={isEditModeApplied}
            isPlanActive={isPlanActive}
            planName={planName}
          />
        )}
        {!previewMode && !isSubsidiary && (
          <Access
            accessRequirements={[
              {
                permission: isTemplateMode
                  ? Permissions.PLAN_TEMPLATE_SETUP
                  : Permissions.EDIT_DRAFT_PLAN,
              },
            ]}
          >
            <DangerZone viewMode={isEditModeApplied} isTemplateMode={isTemplateMode} />
          </Access>
        )}

      </StyledBox>
    </PlanSetupWrapper>
  );
};
export default Plans;
