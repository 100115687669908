import { useMemo } from 'react';

import { useStore as useGracePeriodStore } from '../GracePeriod/stores/useGracePeriod.store';
import { useStore as usePayClaimsStore } from '../PayClaims/stores/usePayClaims.store';

export default () => {
  const gracePeriodStore = useGracePeriodStore((state) => state.state);
  const payClaimsStore = usePayClaimsStore((state) => state.state);

  const state = useMemo(() => ({
    ...gracePeriodStore,
    ...payClaimsStore,
  }), [gracePeriodStore, payClaimsStore]);
  return state;
};
