import React, { useCallback, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, Box, RawButton, Text } from '@common-fe/common-fe';
import styled from 'styled-components';

import NotVisibleICount from '@/components/elements/NotVisibleICount';

import { CalendarPayload } from '../../ScheduledContribution.types';
import { getFrequencyTitle } from '../../utils';

import ScheduledContributionCalendarPlanItem from './ScheduledContributionCalendarPlanItem';

const Wrapper = styled(Box)`
  transition: all 0.35s;
  &:hover {
    ${({ theme }) => theme.shadows.hover}
  }
`;

interface Props extends CalendarPayload {}
const ScheduledContributionCalendarItem: React.FC<Props> = ({
  name,
  frequency,
  scheduledTime,
  recipients,
  accounts,
  plans = [],
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <Wrapper
      direction="column"
      border={{ color: 'border2', size: '1px' }}
      pad={{ horizontal: 'l', vertical: 'm' }}
      round="container2Round"
      margin={{ top: 'xs' }}
      background="canvas"
    >
      <Box direction="row" align="center" height="40px">
        <RawButton onClick={handleToggle} style={{ height: '24px' }}>
          {expanded ? <ArrowUpIcon color="iconPrimary" /> : <ArrowDownIcon color="iconPrimary" />}
        </RawButton>
        <Box style={{ flex: 2 }}>
          <Text
            size="medium"
            weight={700}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {name}
          </Text>
        </Box>
        <Box style={{ flex: 2 }}>
          <Text size="medium">{getFrequencyTitle(frequency)}</Text>
        </Box>
        <Box style={{ flex: 2 }}>
          <Text size="medium">{scheduledTime}</Text>
        </Box>
        <Box style={{ flex: 2 }}>
          <Text size="medium">{recipients}</Text>
        </Box>
        <Box style={{ flex: 6 }}>
          <NotVisibleICount options={accounts} />
        </Box>
      </Box>
      {expanded && (
        <Box
          margin={{ top: 'm' }}
          pad={{ top: 'm', left: 'm', right: 'm' }}
          direction="column"
          round="container2Round"
          background="module"
        >
          {plans.map((item) => (
            <ScheduledContributionCalendarPlanItem
              key={`${item.id}`}
              id={item.id}
              name={item.name}
              recipients={item.recipients}
              forecastedAmount={item.forecastedAmount}
            />
          ))}
        </Box>
      )}
    </Wrapper>
  );
};
export default ScheduledContributionCalendarItem;
