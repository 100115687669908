import React, { useEffect,useState } from 'react';
import { FlexControlledForm } from '@common-fe/common-fe';
import { uniqueId } from 'lodash';

import { useCardSetupForm } from '@/modules/employer/components/SetupEmployer/Cards/CardSetup/hooks/useCardSetupForm';
import ToEditButton from '@/modules/employer/components/SetupEmployer/components/ToEditButton';
import { useSetupEmployerMode } from '@/modules/employer/components/SetupEmployer/hooks/useSetupEmployerMode';
import { useSaveTypeStore } from '@/modules/employer/components/SetupEmployer/stores';
import useGetEmployer from '@/modules/employer/hooks/useGetEmployer.query';

interface Props {
  disabled?: boolean;
  onDirty?: (isDirty: boolean) => void;
}

const CardSetup: React.FC<Props> = ({ disabled, onDirty }) => {
  const [formKey, setFormKey] = useState('');
  const { viewMode } = useSetupEmployerMode();
  const { activeStatus, activateSubmitted } = useSaveTypeStore();
  const fields = useCardSetupForm(activateSubmitted, formKey);
  const { formattedData } = useGetEmployer();
  useEffect(() => {
    setFormKey(uniqueId());
  }, [formattedData]);

  return (
    <div id="cards_section">
      <FlexControlledForm
        key={formKey}
        editMode={!viewMode && !disabled}
        formTitle="Cards"
        showError={activeStatus}
        fields={fields}
        onChangeValues={() => {}}
        onDirty={onDirty}
        isThinMode
      >
        {disabled ? null : <ToEditButton />}
      </FlexControlledForm>
    </div>
  );
};

export default CardSetup;
