import { baseStoreCreator } from '@/utils/store';

export interface ConfirmPayload {
  confirmed: boolean;

}

export const DEFAULT_VALUES: ConfirmPayload = {
  confirmed: true,
};

export const useStore = baseStoreCreator<ConfirmPayload>(DEFAULT_VALUES);

export default useStore;
