import { storeCreator } from '@/utils/store/store.utils';

import { PayrollGroupResponse } from '../payrollGroup.types';

interface State {
  modalVisible: boolean;
  editablePayrollGroup?: PayrollGroupResponse;
  payrollGroupNames?: (string | undefined)[];
  payrollGroupCodes?: (string | undefined)[];
  additionalAction?: () => void;
  handleSetEditablePayrollGroup: (payrollGroup?: PayrollGroupResponse) => void;
  handleSetPayrollGroupNames: (names?: (string | undefined)[]) => void
  handleSetPayrollGroupCodes: (codes?: (string | undefined)[]) => void;
  handleSetAdditionalAction: (action: () => void) => void;
  handleChangeVisibility: (value: boolean) => void;
  handleReset: () => void;
}

export const useStore = storeCreator<State>((set) => ({
  modalVisible: false,
  editablePayrollGroup: undefined,
  additionalAction: undefined,
  payrollGroupNames: undefined,
  payrollGroupCodes: undefined,

  handleSetEditablePayrollGroup: (value) => set((state) => ({
    ...state,
    editablePayrollGroup: value,
  })),

  handleSetAdditionalAction: (action) => set((state) => ({
    ...state,
    additionalAction: action,
  })),

  handleChangeVisibility: (value) => set((state) => ({
    ...state,
    modalVisible: value,
  })),

  handleSetPayrollGroupNames: (names) => set((state) => ({
    ...state,
    payrollGroupNames: names,
  })),

  handleSetPayrollGroupCodes: (codes) => set((state) => ({
    ...state,
    payrollGroupCodes: codes,
  })),

  handleReset: () => set((state) => ({
    ...state,
    modalVisible: false,
    editablePayrollGroup: undefined,
    additionalAction: undefined,
    payrollGroupCodes: undefined,
    payrollGroupNames: undefined,
  })),
}));