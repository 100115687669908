import React, { useMemo } from 'react';
import { Box, Button,Text } from '@common-fe/common-fe';
import _ from 'lodash';
import styled from 'styled-components';

import { useProgressScrollState } from '@/modules/core/hooks/useScrollSidebar';

import { useEmployeeToolbarStore } from '../EmployeeTopbar/stores';

import { useEmployeeModules } from './hooks/useEmployeeModules';

export const StyledText = styled(Text)`
  ${({ theme }) => theme.fonts.text14Regular};


`;
export const StyledButton = styled(Button)`
  background-color: transparent;
  height: 21px !important;
  min-height: 21px !important;
  margin-bottom: ${({ theme }) => theme.spacings.spacing8} ;
  
  &:last-child {
    margin-bottom: 0;
  }
  ${StyledText} {
    color: ${({ active, theme }) => (active ? theme.colors.textAccent : theme.colors.textBody)};
    font-weight: ${({ active }) => (active ? '700' : '400')};
  }
  &:hover {
    ${StyledText} {
      color: ${({ theme }) => theme.colors.textAccent}
    }
  }
`;

interface Props {
  scrollToSection: (data: { module?: string, section?: string }) => void;
  hasCards: boolean;
  isContainNotificationCenter: boolean;
  isContainEmail: boolean;
}
const EmployeeSidebar: React.FC<Props> = ({
  scrollToSection,
  hasCards,
  isContainNotificationCenter,
  isContainEmail,
}) => {
  const employeeModules = useEmployeeModules({
    exluce: [],
    hasCards,
    isContainNotificationCenter,
    isContainEmail,
  });
  const { activeModule } = useProgressScrollState();
  const currentState = useEmployeeToolbarStore((state) => state.sourceState);

  const employeeName = useMemo(() => `${currentState?.firstName} ${currentState?.lastName}`.trim(), [currentState]);
  return (
    <Box
      width={{ min: 'xmedium' }}
      background="module"
      pad={{
        vertical: 'spacing12',
        horizontal: 'spacing12',
      }}
    >
      <Text size="2xl" weight="bold" margin={{ bottom: 'spacing16' }}>
        {employeeName}
      </Text>
      <Box pad="spacing12" background="border2" round="checkboxRound">
        {Object.keys(employeeModules).map((key) => (
          <StyledButton
            key={key}
            data-testid={`sidebar-${key}`}
            active={activeModule === key}
            onClick={() => scrollToSection({ module: key })}

          >
            <StyledText
              size="large"
              weight={activeModule === key ? 'bold' : 'normal'}
            >
              {_.get(employeeModules, `${key}.title`)}
              {/* {employeeModules && employeeModules[anchorId]} */}
            </StyledText>

          </StyledButton>
        ))}
      </Box>

    </Box>

  );
};
export default EmployeeSidebar;
