import React from 'react';
import { Prompt } from 'react-router-dom';

import { useAccountFundingSummary, useCoverageSummary, usePlanDefinitionSummary } from './PlanSummary/hooks';
import { usePreviewStore,useSkipConfirmMode } from './stores';

interface Props {
  isTemplateMode?: boolean;
}
const PlanSetupPrompt: React.FC<Props> = ({ isTemplateMode }) => {
  const previewMode = usePreviewStore((state) => state.previewMode);
  const { changes: planDefinitionChanges } = usePlanDefinitionSummary(isTemplateMode);
  const { changes: coverageChanges } = useCoverageSummary();
  const { changes: accountFundingChanges } = useAccountFundingSummary();
  const skipConfirmMode = useSkipConfirmMode((state) => state.skipConfirm);
  return (
    <Prompt
      when={skipConfirmMode ? false : (
        previewMode
        || !!planDefinitionChanges.length
        || !!coverageChanges.length
        || !!accountFundingChanges.length
      )}
      message="You have unsaved changes, are you sure you want to leave?"
    />
  );
};
export default PlanSetupPrompt;
