/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback,useEffect, useState } from 'react';

import { PATHS } from '@/common';
import { DefaultValues } from '@/common/types';
import useGetStreamedData from '@/utils/hooks/useGetStreamedData';

import { FileDtoWithMediaSource, FileStatus } from '../FileManager.constants';
import { FileDtoPayload,formatFile } from '../queries/useFilesList.query';
import { addBacklightingFields } from '../queries/useFilesUploadQuery';

const path = PATHS.FILE_PROCESSOR_STREAM;
const eventName = 'File status updated event';

interface FileDataFromSteam {
  fileId: FileDtoPayload['id'];
  employersNames?: FileDtoPayload['employers_names'];
  partnersNames?: FileDtoPayload['partners_names'];
  fileStatus: FileDtoPayload['file_processing_status_type'];
  fileType: FileDtoPayload['file_type'];
  processedAt: FileDtoPayload['processed_at'];
  recordsErrors: FileDtoPayload['records_errors'];
  uploadedBy: FileDtoPayload['uploaded_by'];
}

const structureData = (data: FileDataFromSteam): FileDtoPayload => ({
  id: data.fileId,
  employers_names: data.employersNames,
  partners_names: data.partnersNames,
  file_processing_status_type: data.fileStatus,
  file_type: data.fileType,
  processed_at: data.processedAt,
  records_errors: data.recordsErrors,
  uploaded_by: data.uploadedBy,
});
const removeEmptyFields = (data?: DefaultValues) => {
  if (!data) return data;

  const cleanedData: DefaultValues = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (value || typeof value === 'boolean' || typeof value === 'number') {
      cleanedData[key] = value;
    }
  });

  return cleanedData;
};

const useUpdatingFiles = (files: FileDtoWithMediaSource[], isReset?: boolean) => {
  const [streamedFile, setStreabedFile] = useState<FileDtoWithMediaSource>();
  const [streamedFiles, setStreamedFiles] = useState<FileDtoWithMediaSource[]>([]);

  const handleEvent = useCallback((event: MessageEvent) => {
    if (event?.data) {
      const receivedFile = removeEmptyFields(formatFile(
        structureData(JSON.parse(event.data)),
      )) as FileDtoWithMediaSource;

      setStreabedFile(receivedFile);
    }
  }, []);
  useGetStreamedData({ path, handleEvent, eventName });

  useEffect(() => {
    const fileToUpdate = files.find((file) => file.id === streamedFile?.id);

    if (fileToUpdate) {
      const isStatusChanged = streamedFile
        && streamedFile?.status !== fileToUpdate.status
        && !streamedFiles
          .some(
            (file) => file.id === streamedFile.id
              && file.status === streamedFile.status,
          );

      const isWarningBacklighting = isStatusChanged
        && streamedFile?.errorsCount
        && streamedFile.status === FileStatus.Completed;
      const status = isWarningBacklighting
        ? FileStatus.Processing
        : streamedFile?.status;

      const updatedFile = {
        ...fileToUpdate,
        ...streamedFile,
        ...isStatusChanged && status ? { ...addBacklightingFields(status) } : {},
      };

      if (streamedFiles.some((file) => file.id === updatedFile.id)) {
        const updatedStreamedFiles = streamedFiles.map((file) => {
          if (file.id === updatedFile.id) return updatedFile;

          return file;
        });

        setStreamedFiles(updatedStreamedFiles);

        return;
      }

      setStreamedFiles([...streamedFiles, updatedFile]);
    }
     
  }, [streamedFile]);

  useEffect(() => {
    if (isReset) {
      setStreamedFiles([]);
      setStreabedFile(undefined);
    }
     
  }, [isReset]);

  if (!streamedFiles.length) return files;

  return files
    .map(
      (file) => {
        const updatedFile = streamedFiles
          .find((fileFromStream) => fileFromStream.id === file.id);

        if (updatedFile) return updatedFile;

        return file;
      },
    );
};

export default useUpdatingFiles;
