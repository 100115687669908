import { useCallback } from 'react';

import useEmployeeTopbarStore, { EmployeeToolbarPayload } from '../EmployeeTopbar/stores/useEmployeeTopbar.store';
import useOrganizationInfoStore, { OrganizationInfo } from '../PersonalInformation/OrganizationInfo/stores/useOrganizationInfo.store';
import usePersonalInformationStore, { PersonalInformationPayload } from '../PersonalInformation/stores/usePersonalInformation.store';

export type EmployeePayload = PersonalInformationPayload
& EmployeeToolbarPayload & OrganizationInfo;

export default () => {
  const {
    handleSetSourceState: setPersonalSourceState,
    handleReset: handleResetGeneralInfo,

  } = usePersonalInformationStore();

  const {
    setSourceState: setEmployeeTopbarSourceState,
    handleReset: handleResetEmployeeTopbar,
  } = useEmployeeTopbarStore();

  const {
    setSourceState: setOrganizationInfoSourceState,
    handleReset: handleResetOrganizationInfo,
  } = useOrganizationInfoStore();

  const setSourceState = useCallback((values: Partial<EmployeePayload>) => {
    setPersonalSourceState(values);
    setEmployeeTopbarSourceState(values);
    setOrganizationInfoSourceState(values);
  }, [setEmployeeTopbarSourceState, setOrganizationInfoSourceState, setPersonalSourceState]);
  const handleReset = useCallback(() => {
    handleResetGeneralInfo();
    handleResetEmployeeTopbar();
    handleResetOrganizationInfo();
  }, [handleResetEmployeeTopbar, handleResetGeneralInfo, handleResetOrganizationInfo]);
  return {

    setSourceState,
    handleResets: handleReset,
  };
};
