import { baseStoreCreator } from '@/utils/store';

export interface InterestAccrualsPayload {
  interestTemplate: string;
}

export const DEFAULT_VALUES: InterestAccrualsPayload = {
  interestTemplate: 'NONE',
};

export const useStore = baseStoreCreator<InterestAccrualsPayload>(DEFAULT_VALUES);

export default useStore;
