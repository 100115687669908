import dayjs from 'dayjs';
import _ from 'lodash';

import { REPORT_DATE_FORMAT } from '@/common/constants';
import {
  CardsReportGroup,
  CardsReportIndicator,
  GroupByFilter,
  ReportItemData,
  ReportNestedItemGroup,
} from '@/modules/employer/components/Reports/report.types';

const generateTable = (
  data: CardsReportIndicator,
): ReportItemData[] => [
  {
    id: 'total_issued',
    title: 'Total issued',
    value: data?.total_issued,
  },
  {
    id: 'total_active',
    title: 'Total active',
    value: data?.total_active,
  },
  {
    id: 'active_cards_share',
    title: 'Active cards share',
    value: `${data?.active_cards_share.toFixed(2) || 0}%`,
  },
  {
    id: 'primary_cards',
    title: 'Primary cards',
    value: data?.primary_issued || 0,
  },
  {
    id: 'dependent_cards',
    title: 'Dependent cards',
    value: data?.dependent_issued || 0,
  },
  {
    id: 'average_transaction',
    title: 'Average transaction',
    value: data?.average_tranx,
    prefix: true,
  },
];

const formattingVariantGroupedByPartner = ( // PARTNER EMPLOYER
  response: CardsReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    data: generateTable(group.indicators[0] as CardsReportIndicator),
  })),
]);

const formattingVariantGroupedByDateOnly = ( // DATE ONLY
  response: CardsReportGroup[],
): ReportNestedItemGroup[] => [
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    data: generateTable(group.indicators[0] as CardsReportIndicator),
  })),
];

const formattingVariantGroupedByPartnerAndEmployer = ( // PARTNER AND EMPLOYER
  response: CardsReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: group.group_name,
    key: group.group_name,
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as CardsReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          hierarchy: true,
          data: generateTable(data.indicators[0] as CardsReportIndicator),
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartner = ( // DATE PARTNER EMPLOYER
  response: CardsReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((indicator) => {
        const data = indicator as CardsReportGroup;
        return {
          id: _.uniqueId(),
          title: data.group_name,
          key: `${group.group_name}${data.group_name}`,
          data: generateTable(data.indicators[0] as CardsReportIndicator),
        };
      }),
    ],
  })),
]);

const formattingVariantGroupedByDatePartnerAndEmployer = (
  response: CardsReportGroup[],
): ReportNestedItemGroup[] => ([
  ...response.map((group) => ({
    id: _.uniqueId(),
    title: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    key: dayjs(group.group_name).format(REPORT_DATE_FORMAT),
    wrapStyle: {
      marginLeft: '8px',
    },
    children: [
      ...group.indicators.map((subGroup) => {
        const subData = subGroup as CardsReportGroup;
        return {
          id: _.uniqueId(),
          title: subData.group_name,
          key: `${subData.group_name}${group.group_name}`,
          hierarchy: true,
          wrapStyle: {
            marginLeft: '8px',
          },
          children: [
            ...subData.indicators.map((indicator) => {
              const data = indicator as CardsReportGroup;
              return {
                id: _.uniqueId(),
                title: data.group_name,
                key: `${data.group_name}${subData.group_name}${group.group_name}`,
                data: generateTable(data.indicators[0] as CardsReportIndicator),
              };
            }),
          ],
        };
      }),
    ],
  })),
]);

export const generateGroupCardsReport = (
  data: {
    response: CardsReportGroup[],
    groupByType: GroupByFilter | null,
  },
): ReportNestedItemGroup[] => {
  const {
    response,
    groupByType,
  } = data;
  switch (groupByType) {
  case GroupByFilter.Partner: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.Employer: {
    return formattingVariantGroupedByPartner(response);
  }
  case GroupByFilter.DateOnly: {
    return formattingVariantGroupedByDateOnly(response);
  }
  case GroupByFilter.PartnerAndEmployer: {
    return formattingVariantGroupedByPartnerAndEmployer(response);
  }
  case GroupByFilter.DatePartner: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DateEmployer: {
    return formattingVariantGroupedByDatePartner(response);
  }
  case GroupByFilter.DatePartnerAndEmployer: {
    return formattingVariantGroupedByDatePartnerAndEmployer(response);
  }
  default:
    return [] as ReportNestedItemGroup[];
  }
};
